import { FC } from 'react';
import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Container,
} from '@mui/material';
import { NoiseControlOff } from '@mui/icons-material';
import { Button, Icons } from 'components';
import { styleScrollBar } from 'styles/toolkit';
import { config } from 'configs';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { LandingLayout } from 'containers';

const NotePage: FC = () => {
  const navigate = useNavigate();

  return (
    <LandingLayout
      hasFooter={false}
      header={{ title: '注意事項をよくお読みください' }}
    >
      <Stack justifyContent="center" bgcolor="bg_pale_gray">
        <Container sx={{ mt: 7, mb: 12, px: { mobile: 4, tablet: 10 } }}>
          <Stack
            direction="row"
            alignItems={{
              mobile: 'flex-start',
              tablet: 'center',
              desktop: 'center',
            }}
            spacing={1}
          >
            <Icons.Error sx={{ mt: { mobile: '5px', tablet: 0 } }} />
            <Typography variant="title_warning" color="attention">
              住宅ローン 事前審査お申込みにあたっての注意点
            </Typography>
          </Stack>
          <List sx={{ width: '100%' }}>
            {precautions.map((item, index) => (
              <ListItem key={index} dense alignItems="flex-start">
                <ListItemIcon
                  sx={{
                    minWidth: { mobile: 19, desktop: 38 },
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <NoiseControlOff sx={{ color: 'normal_text' }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    color={item.color}
                    fontWeight={item.fontWeight}
                    variant="liststyle"
                    sx={{ py: 2 }}
                  >
                    {item.title}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Container>
      </Stack>

      <Stack alignItems="center" justifyContent="center" bgcolor="bg_pale_gray">
        <Container sx={{ px: { mobile: 4, tablet: 10 } }}>
          <Stack sx={{ mb: 7 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: {
                  mobile: 'flex-start',
                  tablet: 'center',
                  desktop: 'center',
                },
                justifyContent: 'left',
              }}
            >
              <Icons.Error sx={{ mt: { mobile: '5px', tablet: 0 } }} />
              <Typography
                variant="title_warning"
                color="attention"
                sx={{ pl: 1 }}
              >
                お申込みの際の注意事項をお読みください
              </Typography>
            </Box>
            <List sx={{ width: '100%' }}>
              {notes.map((item, index) => (
                <ListItem key={index} dense alignItems="flex-start">
                  <ListItemIcon
                    sx={{
                      minWidth: { mobile: 19, desktop: 38 },
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <NoiseControlOff sx={{ color: 'normal_text' }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="liststyle" color="normal_text">
                      {item}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Stack>
          <Stack sx={{ mb: 7 }}>
            <Box
              sx={{
                mb: 7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
              }}
            >
              <Icons.Error sx={{ mt: { mobile: '5px', tablet: 0 } }} />
              <Typography
                variant="title_warning"
                color="attention"
                sx={{ pl: 1 }}
              >
                同意条項をご確認ください
              </Typography>
            </Box>
            <Box
              sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'line',
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: 227,
                  overflowY: 'scroll',
                  ...styleScrollBar,
                }}
              >
                <Stack spacing={3} sx={{ p: '28px 34px 35px 30px' }}>
                  <Typography
                    sx={{ whiteSpace: 'pre-wrap' }}
                    variant="liststyle"
                    color="normal_text"
                  >
                    {compromises.dk}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Stack>
          <Stack alignItems="center" mb={14}>
            <Stack direction="row" spacing={3} mb={8}>
              <Link
                href={config.termConditionUrl}
                target="_blank"
                component="a"
                variant="title_sub_button"
                color="attention"
                underline="none"
              >
                条項全文を見る
              </Link>
            </Stack>
            <Stack justifyContent="center">
              <Stack sx={{ minWidth: { mobile: 300, tablet: 428 } }}>
                <Button
                  name="agree"
                  fullWidth
                  onClick={() => navigate(routeNames.Consent.path)}
                  sx={{ bgcolor: 'sh_red', minHeight: 50 }}
                >
                  <Typography
                    variant="note_page_button_title"
                    color="main_white"
                  >
                    同意する
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </LandingLayout>
  );
};

const precautions = [
  {
    title:
      'このサービスはインターネットで「住宅ローン事前審査」をお申込みいただけるサービスです。',
    color: 'normal_text',
  },
  {
    title: ' 商品説明ならびに下記をご確認のうえお申込みください。',
    color: 'normal_text',
  },
  {
    title: '必ずお申込人ご本人さまがご入力ください。',
    color: 'attention',
    fontWeight: '700',
  },
];

const notes: string[] = [
  '本サービスは「住宅ローン」の事前審査申込みサービスです。住宅ローンのご利用をいただくためには、別途正式なお手続きが必要となります。',
  'お申込内容確認のため、ご勤務先やご自宅にお電話をさせていただく場合がございます。',
  '当日以降（最短15分程度）に、電子メール、電話または郵送で審査結果をお知らせします。但し、ご入力いただいたメールアドレスが相違している場合やお客さまのメール設定状況によっては通知されない場合がございます。',
  'お借入れの対象となる物件が当サービス営業エリア外の場合や、所在地や面積の確認ができない場合は、ご利用いただけない場合がございます。',
  'ご入力いただきました内容に事実との相違が認められる場合にはご希望に沿いかねる場合がございますので、ご了承ください。',
  '当サービスおよび当サービスの指定する保証会社の審査によっては、ご希望に沿いかねる場合がございますので、ご了承ください。',
  '住宅ローンのご利用にあたっては、団体信用生命保険へのご加入が必要となります。',
  'お申込画面でご入力いただく内容と、正式審査申込ならびに確認資料の内容が相違している場合、担保物件等によっては、ご連絡した審査結果のいかんにかかわらず、ご希望に沿いかねる場合もございますので、ご了承ください。',
  'お借り換えの場合、現在のお借入れのご返済状況によっては、ご連絡した審査結果のいかんにかかわらずご希望に沿いかねる場合がございますので、ご了承ください。',
  '本サービスは、現在みらいバンクでお借入中の住宅ローンのお借り換えや金融機関以外の住宅ローンのお借り換えにはご利用いただけません。',
  '住宅ローンのお借入れについては、みらいバンクに総合口座普通預金をお持ちの方に限らせていただきます。口座をお持ちでない方は、ご契約までに総合口座をご開設ください。',
];

const compromises = {
  dk:
    '株式会社みらいバンクあて\n\n' +
    '全国保証株式会社あて\n\n' +
    '・私は別途送信する内容のとおり、積水化学工業株式会社（以下「みらいバンク」という）、全国保証株式会社（以下「保証会社」という）にローンの事前審査を申込みます。なお、借入金額その他条件はみらいバンクならびに保証会社へ提出する消費者ローン契約書ならびに保証委託契約書によって確定し、その条項にしたがいます。',
  dk_2: '私、連帯保証人予定者は、条件付承諾となった場合は、他の借入の解約または返済等の条件についても持込会社に通知することに同意します。',
};

export default NotePage;
