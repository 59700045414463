/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import {
  useBorrowingDetail,
  useCurrentBorrowing,
  useFinancingPlan,
  useGuaranteeProvider,
  usePersonalInformation,
  usePersonalOccupation,
  usePersonalResidents,
} from './hooks';

type ResetDataProps = {
  formik: any;
  dataIncome?: any;
  dataJoinGuarantor?: any;
  selectSubTab: number;
};

export const ResetData: FC<ResetDataProps> = ({
  formik,
  dataIncome,
  dataJoinGuarantor,
  selectSubTab,
}) => {
  useBorrowingDetail(
    formik.values,
    formik.setFieldValue,
    formik.touched,
    dataIncome,
    dataJoinGuarantor
  );
  const hasJoinGuarantor =
    formik.values.p_application_header.p_applicant_people_attributes[0]
      .has_join_guarantor !== '0';
  usePersonalInformation(formik.values, formik.setFieldValue, formik.touched);
  usePersonalOccupation(formik.values, formik.setFieldValue, formik.touched);
  usePersonalResidents(formik.values, formik.setFieldValue, formik.touched);
  useFinancingPlan(
    formik.values,
    formik.setFieldValue,
    formik.touched,
    (!hasJoinGuarantor && selectSubTab === 5) ||
      (hasJoinGuarantor && selectSubTab === 6)
  );
  useCurrentBorrowing(formik.values, formik.setFieldValue, formik.touched);
  useGuaranteeProvider(formik.values, formik.setFieldValue, formik.touched);
  return null;
};
