import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PasswordEye: FC<SvgIconProps> = (props) => {
  const ids = useMemo(
    () => [Math.random().toString(), Math.random().toString()],
    []
  );
  return (
    <SvgIcon
      sx={{
        width: 20,
        height: 20,
      }}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath={`url(#${ids[0]})`}>
          <g clipPath={`url(#${ids[1]})`} fill="#CCC">
            <path d="M10.023 12.01a2.46 2.46 0 0 0 2.467-2.451 2.46 2.46 0 0 0-2.467-2.451 2.46 2.46 0 0 0-2.467 2.45 2.46 2.46 0 0 0 2.467 2.451Z" />
            <path d="M10.023 3.4C3.127 3.4.85 9.559.85 9.559s2.277 6.158 9.173 6.158S19.15 9.56 19.15 9.56s-2.231-6.16-9.127-6.16Zm0 10.275c-.82 0-1.62-.242-2.302-.694a4.122 4.122 0 0 1-1.526-1.847 4.09 4.09 0 0 1 .898-4.486 4.17 4.17 0 0 1 4.516-.892c.757.311 1.404.839 1.86 1.516a4.097 4.097 0 0 1-.516 5.197 4.159 4.159 0 0 1-2.93 1.206Z" />
          </g>
        </g>
        <defs>
          <clipPath id={ids[0]}>
            <path fill="#fff" d="M0 0h20v20H0z" />
          </clipPath>
          <clipPath id={ids[1]}>
            <path
              fill="#fff"
              transform="translate(.85 3.4)"
              d="M0 0h18.3v12.317H0z"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
