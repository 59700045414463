import { Stack, SxProps, Typography } from '@mui/material';
import { Button } from 'components';
import { FC, ReactNode } from 'react';

interface AdminMessageButtonProps {
  sx?: SxProps;
  title: string;
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export const AdminMessageButton: FC<AdminMessageButtonProps> = (props) => {
  return (
    <Button
      sx={{
        width: 148,
        height: 36,
        border: '1px solid',
        borderColor: 'sp_primary_100_main',
        bgcolor: 'main_white',
        ...props.sx,
      }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {props.icon}
        <Typography
          variant="headline_input"
          color="sp_primary_100_main"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {props.title}
        </Typography>
      </Stack>
    </Button>
  );
};
