import { FC, useCallback, useState } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

export type Summary = {
  status: string;
  description?: string;
  note?: string;
  steps?: {
    status: string;
    title?: string;
    description: string;
    note?: string;
    bgColor?: string;
  }[];
  stepNumber?: number;
  bgColor?: string;
  align?: string;
};

export type AccordionsProps = {
  summaries: Summary[];
  defaultActive: number;
};

export const Accordions: FC<AccordionsProps> = ({
  summaries,
  defaultActive = 0,
}) => {
  const [expanded] = useState<number | null>(defaultActive);

  const getBgColor = useCallback(
    (bgColor: string | undefined, index: number) => {
      if (expanded === index) return 'h_blugreen';
      if (bgColor) return bgColor;
      return 'main_white';
    },
    [expanded]
  );

  return (
    <Box>
      {summaries.map((summary, index) => (
        <Accordion key={index} expanded={expanded === index} sx={{ mb: 8 }}>
          <AccordionSummary
            sx={{
              bgcolor: getBgColor(summary.bgColor, index),
              border: 1,
              borderColor: 'h_blugreen',
              borderRadius: '5px',
              p: summary.stepNumber ? 6 : 0,
              py: '7px',
            }}
          >
            {summary.stepNumber ? (
              <Stack
                direction={{ mobile: 'column', tablet: 'row' }}
                spacing={{ mobile: 0, tablet: 10 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  alignSelf="flex-start"
                  spacing={1}
                  sx={{ width: '13%' }}
                >
                  <Typography variant="text_step_name" color="h_blugreen">
                    STEP
                  </Typography>
                  <Typography variant="text_step_number" color="h_blugreen">
                    {summary.stepNumber}
                  </Typography>
                </Stack>
                <Stack direction="column" justifyContent="center">
                  <Stack>
                    <Typography
                      variant="text_accordion_descreption"
                      color="normal_text"
                    >
                      {summary.status}
                    </Typography>
                  </Stack>
                  {summary.description && (
                    <Stack sx={{ mt: 7 }}>
                      <Typography
                        variant="text_step_descreption"
                        color="normal_text"
                        sx={{
                          whiteSpace: { tablet: 'pre' },
                          wordBreak: { tablet: 'break-all' },
                        }}
                      >
                        {summary.description}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            ) : (
              <Stack flex={1} direction="row">
                <Stack
                  justifyContent="center"
                  sx={{
                    width: { mobile: '50%', tablet: '25%' },
                    pl: { mobile: 5, tablet: 7, desktop: 12 },
                  }}
                >
                  <Typography
                    variant="text_accordion_status"
                    sx={{
                      textAlign: 'left',
                      color: expanded === index ? 'main_white' : 'h_blugreen',
                    }}
                  >
                    {summary.status}
                  </Typography>
                </Stack>
                <Stack justifyContent="center" sx={{ width: '40%' }}>
                  <Typography
                    variant="text_accordion_descreption"
                    sx={{
                      textAlign: 'left',
                      color: expanded === index ? 'main_white' : 'normal_text',
                    }}
                  >
                    {summary.description}
                  </Typography>
                </Stack>
                {summary.note ? (
                  <Stack
                    display={{ mobile: 'none', tablet: 'flex' }}
                    sx={{
                      bgcolor: 'bg_off',
                      width: 196,
                      height: 40,
                      borderRadius: 2,
                      borderWidth: 3,
                      borderStyle: 'solid',
                      borderColor: 'h_blugreen',
                      position: 'absolute',
                      top: 6,
                      right: 23,
                    }}
                  >
                    <ArrowStyled />
                    <Typography
                      sx={{
                        p: '10px',
                        textAlign: 'center',
                        color: 'normal_text',
                      }}
                      variant="popup_text"
                      color="normal_text"
                    >
                      {summary.note}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack />
                )}
              </Stack>
            )}
          </AccordionSummary>
          <Stack direction="row" sx={{ width: '100%', bgcolor: 'bg_off' }}>
            <Stack sx={{ position: 'relative', width: '15%' }}>
              <Divider
                sx={{
                  width: 2,
                  height: { mobile: '97%', tablet: '95%' },
                  mt: 8,
                  ml: { mobile: '11px', tablet: 20, desktop: 25 },
                  backgroundImage: (theme) =>
                    `linear-gradient(to bottom, ${theme?.palette?.h_blugreen} 0%, ${theme?.palette?.h_blugreen} 50%, transparent 50%)`,
                  backgroundSize: '10px 15px',
                  backgroundRepeat: 'repeat-y',
                }}
              />
              <ArrowForwardIos
                sx={{
                  position: 'absolute',
                  color: 'h_blugreen',
                  transform: 'rotate(90deg)',
                  bottom: 14,
                  left: { tablet: 69, desktop: 89 },
                }}
              />
            </Stack>
            <Stack sx={{ width: '85%', pt: 8 }}>
              {summary.steps?.map((step, index) => (
                <AccordionDetails
                  key={index}
                  sx={{
                    p: 0,
                    pb:
                      summary.steps && index === summary.steps?.length - 1
                        ? '18px'
                        : 10,
                  }}
                >
                  <Stack
                    direction={{ mobile: 'column', tablet: 'row' }}
                    alignItems="center"
                    spacing={3}
                    sx={{
                      backgroundColor: step.bgColor
                        ? step.bgColor
                        : 'main_white',
                      border: 1,
                      borderColor: 'h_blugreen',
                      borderRadius: '5px',
                      p: 6,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      alignSelf="flex-start"
                      spacing={1}
                      sx={{ mr: 15 }}
                    >
                      <Typography variant="text_step_name" color="h_blugreen">
                        STEP
                      </Typography>
                      <Typography variant="text_step_number" color="h_blugreen">
                        {index + 1}
                      </Typography>
                    </Stack>
                    {step.note ? (
                      <Stack direction="column">
                        <Stack sx={{ mb: 7 }}>
                          <Typography
                            variant="text_accordion_descreption"
                            color="normal_text"
                          >
                            {step.status}
                          </Typography>
                        </Stack>
                        <Stack spacing={3}>
                          <Typography
                            variant="text_step_descreption"
                            color="normal_text"
                          >
                            {step.title}
                          </Typography>
                          <Typography
                            variant="text_step_descreption"
                            color="normal_text"
                            sx={{
                              whiteSpace: 'break-spaces',
                              wordBreak: 'break-all',
                            }}
                          >
                            {step.description}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="position_text"
                          color="normal_text"
                          sx={{
                            whiteSpace: 'break-spaces',
                            wordBreak: 'break-all',
                            lineHeight: '22px',
                            mt: 3,
                          }}
                        >
                          {step.note}
                        </Typography>
                      </Stack>
                    ) : (
                      <Stack
                        direction={{ mobile: 'column', tablet: 'row' }}
                        spacing={{ mobile: 1, tablet: 15 }}
                      >
                        <Stack sx={{ width: '25%' }}>
                          <Typography
                            variant="text_accordion_descreption"
                            color="normal_text"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            {step.status}
                          </Typography>
                        </Stack>
                        <Stack sx={{ width: '55%' }}>
                          <Typography
                            variant="text_step_descreption"
                            color="normal_text"
                          >
                            {step.description}
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </AccordionDetails>
              ))}
            </Stack>
          </Stack>
        </Accordion>
      ))}
    </Box>
  );
};

const ArrowStyled = styled(Box)(({ theme }) => ({
  left: -1,
  marginLeft: '-0.83em',
  height: '2em',
  width: '0.90em',
  marginTop: -11,
  marginBottom: 4,
  top: 16,
  overflow: 'hidden',
  position: 'absolute',
  boxSizing: 'border-box',
  color: theme?.palette?.background?.paper,
  '&::before': {
    transformOrigin: '130% 90%',
    content: '""',
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '90%',
    backgroundColor: theme?.palette?.bg_off,
    transform: 'rotate(45deg)',
    border: `3px solid ${theme?.palette?.h_blugreen}`,
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
