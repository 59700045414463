import { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { AuthPopover, Icons } from 'components';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { PulldownBtn } from '../PulldownBtn';

export type PulldownLoanTargetProps = {
  value: string | number | boolean;
  options: { option_code: number; option_name: string }[];
  onChange: (value: string | number | boolean) => void;
};

export const PulldownLoanTarget: FC<PulldownLoanTargetProps> = ({
  value,
  options,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <AuthPopover
      arrow={false}
      sx={{
        width: 146,
        border: (theme) => `1px solid ${theme?.palette?.line}`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        overflow: 'hidden',
      }}
      content={
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Stack sx={{ textAlign: 'left', maxHeight: 300, overflow: 'auto' }}>
            {options.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  px: 1,
                  py: 1,
                  borderBottom:
                    options.length - 1 !== index ? '1px solid #DBD9D9' : 'none',
                  cursor: 'pointer',
                  backgroundColor: 'main_white',
                }}
                onClick={() => {
                  onChange(item.option_code.toString());
                  setOpen(false);
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {String(item.option_code) === String(value) ? (
                    <Icons.PulldownChecked sx={{ width: 16, height: 16 }} />
                  ) : (
                    <Icons.PulldownCheck sx={{ width: 16, height: 16 }} />
                  )}
                  <Typography
                    variant="text_dashboard"
                    color="#444"
                    sx={{ pl: 2 }}
                  >
                    {item.option_name}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </ClickAwayListener>
      }
      open={open}
      placement="bottom-start"
      contentProps={{
        height: 'auto',
        padding: 0,
        color: 'required_chip_label',
      }}
    >
      <Stack direction="row" alignItems="center">
        <PulldownBtn
          open={open}
          onClick={() => setOpen((prevState) => !prevState)}
        />
      </Stack>
    </AuthPopover>
  );
};
