import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from 'services';

export const deleteUser = createAsyncThunk<void>(
  'spAuth/deleteUser',
  async (data, { rejectWithValue }) => {
    try {
      return await authService.deleteUser();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
