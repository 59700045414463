import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SecondApartment: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 62 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="62"
      height="68"
      viewBox="0 0 62 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0709 1H17.4922V66.9916H31.0709H44.6574V1H31.0709Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M38.3248 45.377H33.4492V50.2526H38.3248V45.377Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M28.6842 45.377H23.8086V50.2526H28.6842V45.377Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M38.3248 35.4785H33.4492V40.3541H38.3248V35.4785Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M28.6842 35.4785H23.8086V40.3541H28.6842V35.4785Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M38.3248 25.5723H33.4492V30.4479H38.3248V25.5723Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M28.6842 25.5723H23.8086V30.4479H28.6842V25.5723Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M38.3248 15.7588H33.4492V20.6344H38.3248V15.7588Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M28.6842 15.7588H23.8086V20.6344H28.6842V15.7588Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M38.3248 5.93848H33.4492V10.8141H38.3248V5.93848Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M28.6842 5.93848H23.8086V10.8141H28.6842V5.93848Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M38.3293 55.2764H32.3047V66.9918H38.3293V55.2764Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M29.8372 55.2764H23.8125V66.9918H29.8372V55.2764Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M61.0415 10.8213H44.6523V66.9995H61.0415V10.8213Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M55.2819 45.3779H50.4062V50.2535H55.2819V45.3779Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M55.2819 35.4785H50.4062V40.3541H55.2819V35.4785Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M55.2819 25.5723H50.4062V30.4479H55.2819V25.5723Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M55.2819 15.666H50.4062V20.5416H55.2819V15.666Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M1.09127 66.9912H17.4805L17.4805 10.813H1.09127L1.09127 66.9912Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M11.731 45.3789H6.85547V50.2545H11.731V45.3789Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M11.731 35.4785H6.85547V40.3541H11.731V35.4785Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M11.731 25.5732H6.85547V30.4489H11.731V25.5732Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M11.731 15.6748H6.85547V20.5504H11.731V15.6748Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
