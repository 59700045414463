import { z } from 'zod';

export const branchSchema = z.object({
  value: z.string(),
  label: z.string(),
});

export const branchResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(branchSchema),
});

export const affiliationSchema = z.object({
  value: z.string(),
  label: z.string(),
});

export const affiliationResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(affiliationSchema),
});

export type Branch = z.infer<typeof branchSchema>;
export type GetBranchResponse = z.infer<typeof branchResponseSchema>;
export type GetBranchError = {};

export type Affiliation = z.infer<typeof affiliationSchema>;
export type GetAffiliationResponse = z.infer<typeof affiliationResponseSchema>;
export type GetAffiliationError = {};
