import { MESSAGE_REGEX } from 'constant';
import { regex, yup } from 'libs';

export const emailValidationSchema = yup.object({
  email: yup
    .string()
    .matches(regex.email, MESSAGE_REGEX.EMAIL_SP)
    .required()
    .max(128, '128文字以内でご入力ください。')
    .label('登録メールアドレス'),
});

export const passwordValidationSchema = yup.object({
  password: yup
    .string()
    .required()
    .matches(regex.password, '有効なパスワードを入力してください。')
    .label('パスワード'),
  password_confirmation: yup
    .string()
    .required()
    .when('password', {
      is: (val: string) => !!val?.length,
      then: yup
        .string()
        .oneOf(
          [yup.ref('password')],
          'パスワードとパスワード確認用が一致していません'
        ),
    })
    .label('パスワード（確認用）'),
});
