import { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { Icons, SPFormItem } from 'components';
import { routeNames } from 'navigations/routes';
import { useSelector } from 'react-redux';
import { spAccountInformationSelector } from './selectors';

const SPAccountInformationPage: FC = () => {
  const userEmail = useSelector(spAccountInformationSelector);

  return (
    <SPStepLayout
      hasStepBar={false}
      hasFooter={false}
      footerLogo
      hasModalSaveDraft={false}
      sx={{
        minHeight: 'calc(100vh - 158px)',
      }}
    >
      <Stack py={7} alignItems="center">
        <Typography
          variant="sp_change_password_title"
          color="sp_primary_100_main"
        >
          アカウント情報
        </Typography>
      </Stack>
      <SPFormItem
        required
        label="メールアドレス"
        labelSx={{ py: 1 }}
        childrenSx={{ pb: 6 }}
      >
        <Stack spacing={3}>
          <Stack
            justifyContent="center"
            sx={{ height: 48, bgcolor: 'sp_gray_100', borderRadius: 1, pl: 4 }}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="b_333"
              sx={{
                fontWeight: '300',
              }}
            >
              {userEmail}
            </Typography>
          </Stack>
          <Link
            href={routeNames.ChangeEmail.path}
            alignSelf="end"
            variant="SP_multiple_checkbox_label"
            color="sp_primary_100_main"
            sx={{ textDecoration: 'none' }}
          >
            変更する
            <Icons.SPArrowForwardCircle
              sx={{
                verticalAlign: 'middle',
                marginTop: '-2px',
              }}
            />
          </Link>
        </Stack>
      </SPFormItem>
      <SPFormItem
        required
        label="パスワード"
        labelSx={{ py: 1 }}
        childrenSx={{ pb: 6 }}
      >
        <Stack spacing={3}>
          <Stack
            justifyContent="center"
            sx={{ height: 48, bgcolor: 'sp_gray_100', borderRadius: 1, pl: 4 }}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="b_333"
              sx={{ fontWeight: '300' }}
            >
              ＊＊＊＊＊＊＊＊＊＊＊
            </Typography>
          </Stack>
          <Link
            href={routeNames.UpdatePassword.path}
            alignSelf="end"
            variant="SP_multiple_checkbox_label"
            color="sp_primary_100_main"
            sx={{ textDecoration: 'none' }}
          >
            変更する
            <Icons.SPArrowForwardCircle
              sx={{
                verticalAlign: 'middle',
                marginTop: '-2px',
              }}
            />
          </Link>
        </Stack>
      </SPFormItem>
      <SPFormItem
        required
        label="退会をご希望の方へ"
        labelSx={{ py: 1 }}
        childrenSx={{ pb: 6, px: 6 }}
      >
        <Stack spacing={3}>
          <Typography
            alignSelf="center"
            variant="sp_change_password_note"
            color="b_333"
          >
            退会すると、ご登録いただいた全てのデータを閲覧できなくなります。一度退会するとデータを復元することはできませんのでご注意ください。
          </Typography>
          <Link
            href={routeNames.SPUnsubcribed.path}
            alignSelf="end"
            variant="SP_multiple_checkbox_label"
            color="sp_primary_100_main"
            sx={{ textDecoration: 'none' }}
          >
            退会する
            <Icons.SPArrowForwardCircle
              sx={{
                verticalAlign: 'middle',
                marginTop: '-2px',
              }}
            />
          </Link>
        </Stack>
      </SPFormItem>
    </SPStepLayout>
  );
};

export default SPAccountInformationPage;
