import { createSlice } from '@reduxjs/toolkit';
import { DynamicOptions } from 'services';
import { getDynamicOptions } from './thunk';

export type InitialState = {
  form: DynamicOptions;
};

export const initialState: InitialState = {
  form: {},
};

const dynamicOptionsSlice = createSlice({
  name: 'dynamicOptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDynamicOptions.fulfilled, (state, action) => {
      state.form = action.payload.data;
    });
  },
});

export default dynamicOptionsSlice.reducer;
