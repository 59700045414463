import { FC } from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

export type ButtonProps = MuiButtonProps & {
  startIconStyle?: {
    left: number;
    bottom: number;
  };
};

export const Button: FC<ButtonProps> = ({
  children,
  startIconStyle,
  ...props
}) => (
  <MuiButton
    {...props}
    sx={{
      borderRadius: 1,
      minHeight: 32,
      position: 'relative',
      ...props.sx,
      '&:hover': {
        // @ts-ignore
        color: props?.sx?.color,
        // @ts-ignore
        bgcolor: props?.sx?.bgcolor,
        opacity: { mobile: 1, desktop: 0.8 },
      },
      '.MuiButton-startIcon': {
        position: 'absolute',
        left: 47,
        bottom: 13,
        ...startIconStyle,
      },
      '&.Mui-disabled .MuiButton-endIcon': {
        opacity: 0.26,
      },
    }}
  >
    {children}
  </MuiButton>
);
