import { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import {
  Button,
  FormItem,
  InputDateTime,
  InputField,
  InputSelectProps,
  RadioGroupButton,
  UlStyle,
} from 'components';
import { Placeholder } from 'constant';
import { convertDynamicOptions, zeroPad } from 'utils';
import { dayjs } from 'libs';
import { StepSynthesisForm } from 'types';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { useSelector } from 'react-redux';

const StepThreeDPage: FC = () => {
  const { values } = useFormikContext<StepSynthesisForm>();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);

  const age = useMemo(() => {
    const monthDiff = dayjs().diff(
      values.main_p_applicant_person.birthday,
      'month'
    );
    const [year] = values?.main_p_applicant_person.birthday?.split('/');
    if (!values.main_p_applicant_person.birthday || monthDiff < 0 || !year) {
      return '';
    }
    return Math.floor(monthDiff / 12);
  }, [values.main_p_applicant_person.birthday]);

  const nationalityOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nationality),
    [dynamicOptions.nationality]
  );

  return (
    <Stack justifyContent="center">
      <Stack sx={{ mt: 5, mb: 12 }}>
        {guides.map((guide, index) => (
          <Stack
            key={index}
            direction="row"
            spacing={3}
            alignItems="start"
            sx={{ mb: '10px' }}
          >
            <Chip
              label={
                <Typography variant="required_chip_label" color="main_white">
                  {guide.textRequired}
                </Typography>
              }
              sx={{
                bgcolor:
                  guide.textRequired === '必須' ? 'sh_red' : 'Icon_color',
                borderRadius: 1,
                height: 20,
                width: 36,
                mt: '3px',
                '.MuiChip-label': { px: 1 },
              }}
            />
            <Typography variant="h3" color="normal_text">
              {guide.label}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <FormItem
        sx={{ mt: 8 }}
        divider
        readOnly
        label="お名前"
        required
        note={
          <UlStyle>
            {notes.fullName.map((note, index) => (
              <li key={index}>
                <Typography variant="text_note" color="normal_text">
                  {note}
                </Typography>
              </li>
            ))}
          </UlStyle>
        }
      >
        <Grid
          container
          spacing={7}
          direction={{
            mobile: 'column',
            tablet: 'row',
          }}
        >
          <Grid item {...{ mobile: 6, md: 4 }}>
            <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
              姓
            </Typography>
            <InputField
              readOnly
              name="main_p_applicant_person.last_name_kanji"
              placeholder="ー"
            />
          </Grid>
          <Grid item {...{ mobile: 6, md: 4 }}>
            <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
              名
            </Typography>
            <InputField
              readOnly
              name="main_p_applicant_person.first_name_kanji"
              placeholder="ー"
            />
          </Grid>
        </Grid>
      </FormItem>
      <FormItem readOnly divider label="お名前（フリガナ）" required>
        <Grid
          container
          spacing={7}
          direction={{
            mobile: 'column',
            tablet: 'row',
            desktop: 'row',
          }}
        >
          <Grid item {...{ mobile: 6, md: 4 }}>
            <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
              姓
            </Typography>
            <InputField
              readOnly
              convertKatakana
              name="main_p_applicant_person.last_name_kana"
              placeholder="ー"
              convertFullWidth
            />
          </Grid>
          <Grid item {...{ mobile: 6, md: 4 }}>
            <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
              名
            </Typography>
            <InputField
              readOnly
              convertKatakana
              name="main_p_applicant_person.first_name_kana"
              placeholder="ー"
              convertFullWidth
            />
          </Grid>
        </Grid>
      </FormItem>
      <Stack
        direction={{ mobile: 'column', tablet: 'row' }}
        justifyContent="center"
        alignItems={{ mobile: 'baseline', tablet: 'center' }}
        spacing={8}
        sx={{ mb: { mobile: 8, tablet: 0 } }}
      >
        <Stack flex={3} maxWidth={{ mobile: 300, tablet: 500 }}>
          <FormItem readOnly label="生年月日" required>
            <Stack pr={{ md: 14 }}>
              <InputDateTime
                readOnly
                yearOptions={yearOptions}
                monthOptions={monthOptions}
                name="main_p_applicant_person.birthday"
                checkRequired
              />
            </Stack>
          </FormItem>
        </Stack>

        <Stack flex={2}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: { mobile: 0, tablet: 15 }, ml: { mobile: 0, tablet: 0 } }}
          >
            <Typography variant="text_age" color="normal_text">
              （ {age + ' '}
              <Typography
                variant="landing_submit_button_title"
                color="normal_text"
              >
                歳
              </Typography>
              ）
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider
        sx={{
          width: '100%',
          height: 2,
          backgroundImage: (theme) =>
            `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
          backgroundSize: '6px 9px',
          backgroundRepeat: 'repeat-x',
          borderBottomWidth: 'inherit',
        }}
      />
      <FormItem divider label="現在の国籍" required note={notes.nationality}>
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="main_p_applicant_person.nationality"
          options={nationalityOptions}
        />
      </FormItem>

      <FormItem
        readOnly
        divider
        label="電話番号"
        required
        note={notes.phoneNumber}
      >
        <Typography sx={{ mb: 8 }} variant="h3" color="normal_text">
          携帯電話番号
        </Typography>
        <Grid container spacing={7}>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              readOnly
              name="main_p_applicant_person.mobile_phone_number"
              placeholder="ー"
              numeric
            />
          </Grid>
        </Grid>
        <Typography sx={{ mt: 10, mb: 8 }} variant="h3" color="normal_text">
          固定電話番号
        </Typography>
        <Grid container spacing={7}>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              readOnly
              name="main_p_applicant_person.home_phone_number"
              placeholder="ー"
              numeric
            />
          </Grid>
        </Grid>
      </FormItem>

      <FormItem
        readOnly
        label="郵便番号"
        divider
        note={notes.postCode}
        required
      >
        <Grid container spacing={7}>
          <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
            <InputField
              readOnly
              name="main_p_applicant_person.postal_code"
              placeholder={Placeholder.POSTAL_CODE}
              numeric
            />
          </Grid>
          <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
            <Button
              sx={{
                width: { mobile: '100%', tablet: 187 },
                minHeight: 50,
                height: 50,
                bgcolor: 'h_blugreen',
                borderRadius: '4px',
              }}
            >
              <Typography
                variant="text_button_step_required"
                color="main_white"
              >
                郵便番号で検索
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </FormItem>
      <FormItem
        readOnly
        divider
        label="住所（漢字）"
        required
        note={notes.address_kanji}
      >
        <Stack spacing={4}>
          {values.main_p_applicant_person.prefecture_kanji && (
            <Typography
              variant="text_input_code"
              color="normal_text"
              sx={{ mt: '-10px' }}
            >
              {values.main_p_applicant_person.prefecture_kanji +
                values.main_p_applicant_person.city_kanji +
                values.main_p_applicant_person.district_kanji}
            </Typography>
          )}
          <InputField
            readOnly
            name="main_p_applicant_person.other_address_kanji"
            placeholder={Placeholder.ADDRESS_KANJI}
          />
        </Stack>
      </FormItem>
      <FormItem
        readOnly
        divider
        label="住所（フリガナ）"
        required
        note={notes.address_kana}
      >
        <Stack spacing={4}>
          {values.main_p_applicant_person.prefecture_kana && (
            <Typography
              variant="text_input_code"
              color="normal_text"
              sx={{ mt: '-10px' }}
            >
              {values.main_p_applicant_person.prefecture_kana +
                values.main_p_applicant_person.city_kana +
                values.main_p_applicant_person.district_kana}
            </Typography>
          )}
          <InputField
            readOnly
            name="main_p_applicant_person.other_address_kana"
            placeholder={Placeholder.ADDRESS_KANA}
            convertKatakana
            convertFullWidth
          />
        </Stack>
      </FormItem>
      <FormItem
        readOnly
        divider
        label="メールアドレス"
        note={
          <UlStyle>
            <li>
              <Typography variant="text_note" color="normal_text">
                {notes.email}
              </Typography>
            </li>
          </UlStyle>
        }
        required
      >
        <Grid container>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              readOnly
              name="main_p_applicant_person.owner_email"
              placeholder="ー"
            />
          </Grid>
        </Grid>
      </FormItem>
      <FormItem readOnly label="メールアドレス（確認用）" required>
        <Grid container>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              readOnly
              name="main_p_applicant_person.confirmation_email"
              placeholder="ー"
            />
          </Grid>
        </Grid>
      </FormItem>
    </Stack>
  );
};

export default StepThreeDPage;

const notes = {
  nationality:
    '※外国籍に該当する場合は詳細な内容を確認するため、担当者よりご連絡させていただきます。\n' +
    '※外国籍を選択の場合は永住権証明書をご提出ください。\n' +
    '※永住権がない方は原則お取り組みできません。',
  fullName: [
    '外国籍のかたは、在留カード通りに記入ください。本入力欄にフルネームが入力しきれない場合は、本欄にはお名前を途中までご入力いただき、下記の特記事項欄にフルネームをご入力ください。',
    'お名前の漢字が外字等で変換できない場合は常用漢字でご入力ください。',
  ],
  birthday:
    '原則として購入する物件の売買代金や請負工事代金の支払日がお借入れ希望日となります。',
  phoneNumber:
    '※携帯電話番号か固定電話番号のどちらかに必ず入力してください。\n※ハイフンを抜かしてご入力ください。',
  postCode: '※ハイフンを抜かしてご入力ください。',
  family: '※対象物件に入居される人数を入力してください。',
  applyYourselfChecked:
    '本項目に該当する場合は詳細な内容を確認するため、担当者よりご連絡させていただきます。',
  email:
    'お申込み完了や事前審査結果等のメールを送信させていただきます。ご本人さま以外の方がご覧になる可能性のある　\n' +
    '　メールアドレスは入力しないでください。\n' +
    '　webmaster@milibank.co.jpからのメールを受信できるよう事前に設定してください。',
  has_borrowing: [
    '事前完済借入がある場合は、クリックし、お借入れ内容をご入力ください。',
    '入力のあったお借入れについては、住宅ローンお借入れまでに完済の確認資料をご提出いただきます。',
  ],
  has_borrowings:
    '※現在ご利用中の住宅ローン・車のローン・教育ローン・奨学金・カードローン・キャッシング等がある場合は「有」を選択してください。',
  loanAmount: [
    '車のローン、奨学金などの他、カードローン・キャッシングもご記入ください。',
    '仮審査申込日から遡って3ヵ月以内に完済した借入もご記入ください。',
    '現在ご利用中のローンの返済予定表・利用明細等をアップロードいただければ以下の項目は入力不要です。',
  ],
  address_kanji:
    '※自動入力された住所の続き（番地・建物名・部屋番号など）を入力してください。\n' +
    '（例:６－１泉マンション１０８）',
  address_kana:
    '※自動入力された住所の続き（番地・建物名・部屋番号など）を入力してください。\n' +
    '（例:６－１泉マンション１０８）',
};

const guides = [
  { textRequired: '必須', label: '・・・入力必須項目です。' },
  {
    textRequired: '任意',
    label:
      '・・・後ほどアップロードしていただく資料に記載のある項目ですので入力は任意です。',
  },
];

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(63), (_, index) => {
    const year = zeroPad(dayjs().year() - 18 - index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);
