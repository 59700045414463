import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPPencil: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 21 21" {...props}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9297 2.86171C20.6602 3.59374 20.6602 4.78124 19.9297 5.51171L7.375 18.0664C6.89453 18.5117 6.30078 18.8984 5.64844 19.0898L0.90078 20.4883C0.790975 20.5195 0.672381 20.4883 0.591522 20.4101C0.510674 20.3281 0.480436 20.2109 0.512709 20.0976L1.90937 15.3515C2.10117 14.6992 2.4539 14.1055 2.93437 13.5898L15.4883 1.07147C16.2188 0.33905 17.4062 0.33905 18.1367 1.07147L19.9297 2.86171ZM13.3164 4.12499L16.875 7.68358L19.4883 5.0703C19.9766 4.58202 19.9766 3.79178 19.4883 3.3035L17.6953 1.51327C17.207 1.02499 16.418 1.02499 15.8945 1.51327L13.3164 4.12499ZM12.875 4.56639L3.37617 14.0664C2.96953 14.4726 2.67148 14.9726 2.50898 15.5273L1.27383 19.7265L5.47265 18.4922C6.02734 18.3281 6.52734 18.0312 6.93359 17.625L16.4336 8.12499L12.875 4.56639Z"
          fill="url(#paint0_linear_9580_13426)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_9580_13426"
            x1="3.57639"
            y1="16.3915"
            x2="18.8419"
            y2="0.972342"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
