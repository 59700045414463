import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  ChangePasswordError,
  ChangePasswordRequest,
  ChangePasswordResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const changePassword = createAsyncThunk<
  ChangePasswordResponse,
  ChangePasswordRequest,
  ThunkAPI<ChangePasswordError>
>('authModal/changePassword', async (user, { rejectWithValue }) => {
  try {
    return await authService.changePassword(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});
