import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WatchLater: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#6671E1" />
      <rect
        x="17.5928"
        y="4.44434"
        width="2.96296"
        height="17.7778"
        rx="1"
        fill="white"
      />
      <rect
        x="29.4446"
        y="19.259"
        width="2.96296"
        height="11.8519"
        rx="1"
        transform="rotate(90 29.4446 19.259)"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
