import { Fade, Popper } from '@material-ui/core';
import { ReactElement, useState, FC, cloneElement } from 'react';
import { Stack, styled, Typography, Box, TypographyProps } from '@mui/material';
import { BasePlacement } from '@popperjs/core/lib/enums';
import { SxProps, Theme } from '@mui/material/styles';

type PopoverProps = {
  content: ReactElement | string;
  children: ReactElement;
  open: boolean;
  arrow?: boolean;
  placement?: BasePlacement | 'top-end' | 'top-start' | 'bottom-start';
  sx?: SxProps<Theme>;
  arrowSx?: SxProps<Theme>;
  contentProps?: TypographyProps;
};

export const Popover: FC<PopoverProps> = ({
  placement = 'top',
  arrow = true,
  open,
  content,
  children,
  sx,
  arrowSx,
  contentProps,
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = useState<HTMLElement | null>(null);

  return (
    <>
      {cloneElement(children, { ...children?.props, ref: setChildNode })}
      <PopperStyled
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
          arrow: {
            enabled: arrow,
            element: arrowRef,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            {open ? (
              <Stack
                sx={{
                  backgroundColor: 'bg_off',
                  maxWidth: 1000,
                  borderRadius: '6px',
                  border: (theme) => `3px solid ${theme?.palette?.blue08}`,
                  position: 'relative',
                  ...sx,
                }}
              >
                {arrow && (
                  <ArrowStyled
                    ref={setArrowRef}
                    data-arrow="true"
                    sx={{
                      '&::before': arrowSx,
                      display: { mobile: 'none', tablet: ' block' },
                    }}
                  />
                )}
                <Typography
                  sx={{ p: '17px 16px 18px 16px' }}
                  variant={'popup_text'}
                  {...contentProps}
                >
                  {content}
                </Typography>
              </Stack>
            ) : (
              <Stack />
            )}
          </Fade>
        )}
      </PopperStyled>
    </>
  );
};

const PopperStyled = styled(Popper)(() => ({
  '&[x-placement*="bottom"] [data-arrow="true"]': {
    top: -16,
    left: 0,
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 60%',
    },
  },
  '&[x-placement*="bottom-end"] [data-arrow="true"]': {
    top: -16,
    left: 'calc(100% - 70px) !important',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 60%',
    },
  },
  '&[x-placement*="bottom-start"] [data-arrow="true"]': {
    top: -16,
    left: '68px !important',
    marginLeft: 4,
    marginRight: 4,
    '&::before': {
      transformOrigin: '0 60%',
    },
  },
  '&[x-placement*="top"] [data-arrow="true"]': {
    bottom: -3,
    left: 0,
    marginBottom: -12,
    marginLeft: 0,
    marginRight: 4,
    '&::before': {
      transformOrigin: '100% -7%',
    },
  },
  '&[x-placement*="top-end"] [data-arrow="true"]': {
    bottom: -3,
    left: 'calc(100% - 70px) !important',
    marginBottom: -12,
    marginLeft: 0,
    marginRight: 4,
    '&::before': {
      transformOrigin: '100% -7%',
    },
  },
  '&[x-placement*="top-start"] [data-arrow="true"]': {
    bottom: -3,
    left: '70px !important',
    marginBottom: -12,
    marginRight: 0,
    marginLeft: -6,
    '&::before': {
      transformOrigin: '100% -7%',
    },
  },
  '&[x-placement*="right"] [data-arrow="true"]': {
    left: 0,
    marginLeft: '-0.69em',
    height: '2em',
    width: '0.71em',
    marginTop: -11,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '118% 76%',
    },
  },
  '&[x-placement*="left"] [data-arrow="true"]': {
    right: 0,
    marginRight: '-0.71em',
    height: '1em',
    width: '0.71em',
    marginTop: 0,
    marginBottom: 4,
    '&::before': {
      transformOrigin: '30% -20%',
    },
  },
})) as typeof Popper;

const ArrowStyled = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'absolute',
  width: '1.35em',
  height: '1em',
  boxSizing: 'border-box',
  color: theme?.palette?.background?.paper,
  '&::before': {
    border: `3px solid ${theme?.palette?.blue08}`,
    content: '""',
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: theme?.palette?.bg_off,
    transform: 'rotate(45deg)',
  },
}));
