import { createAsyncThunk } from '@reduxjs/toolkit';
import { applicationsService, GetPostalCodeResponse } from 'services';

export const getZipCloudAddress = createAsyncThunk<
  GetPostalCodeResponse,
  string
>(
  'StepRequiredInformation/getZipCodeAddress',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } = await applicationsService.getZipCloudAddress(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
