import { SPImage } from 'components';
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';

export type ImageContextType = {
  businessCard: (File | SPImage)[];
  setBusinessCard: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residenceFile: (File | SPImage)[];
  setResidenceFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residenceFileBackImage: (File | SPImage)[];
  setResidenceFileBackImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residenceIncomeFile: (File | SPImage)[];
  setResidenceIncomeFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residenceIncomeFileBackImage: (File | SPImage)[];
  setResidenceIncomeFileBackImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  otherDocumentFile: (File | SPImage)[];
  setOtherDocumentFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  otherDocumentFile2: (File | SPImage)[];
  setOtherDocumentFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  repaymentScheduleImage: (File | SPImage)[][];
  setRepaymentScheduleImage: Dispatch<SetStateAction<(File | SPImage)[][]>>;
  propertyInformationFile: (File | SPImage)[];
  setPropertyInformationFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  insuranceFile: (File | SPImage)[];
  setInsuranceFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  insuranceFileBackImage: (File | SPImage)[];
  setInsuranceFileBackImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  insuranceFile2: (File | SPImage)[];
  setInsuranceFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  insuranceFileBackImage2: (File | SPImage)[];
  setInsuranceFileBackImage2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  financialStatement1File: (File | SPImage)[];
  setFinancialStatement1File: Dispatch<SetStateAction<(File | SPImage)[]>>;
  financialStatement1File2: (File | SPImage)[];
  setFinancialStatement1File2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  financialStatement2File: (File | SPImage)[];
  setFinancialStatement2File: Dispatch<SetStateAction<(File | SPImage)[]>>;
  financialStatement2File2: (File | SPImage)[];
  setFinancialStatement2File2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  financialStatement3File: (File | SPImage)[];
  setFinancialStatement3File: Dispatch<SetStateAction<(File | SPImage)[]>>;
  financialStatement3File2: (File | SPImage)[];
  setFinancialStatement3File2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  firstWithholdingSlipFile: (File | SPImage)[];
  setFirstWithholdingSlipFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  firstWithholdingSlipFile2: (File | SPImage)[];
  setFirstWithholdingSlipFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  secondWithholdingSlipFile: (File | SPImage)[];
  setSecondWithholdingSlipFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  secondWithholdingSlipFile2: (File | SPImage)[];
  setSecondWithholdingSlipFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  employmentAgreementFile: (File | SPImage)[];
  setEmploymentAgreementFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  employmentAgreementFile2: (File | SPImage)[];
  setEmploymentAgreementFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  businessTaxReturn1File: (File | SPImage)[];
  setBusinessTaxReturn1File: Dispatch<SetStateAction<(File | SPImage)[]>>;
  businessTaxReturn1File2: (File | SPImage)[];
  setBusinessTaxReturn1File2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  businessTaxReturn2File: (File | SPImage)[];
  setBusinessTaxReturn2File: Dispatch<SetStateAction<(File | SPImage)[]>>;
  businessTaxReturn2File2: (File | SPImage)[];
  setBusinessTaxReturn2File2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  businessTaxReturn3File: (File | SPImage)[];
  setBusinessTaxReturn3File: Dispatch<SetStateAction<(File | SPImage)[]>>;
  businessTaxReturn3File2: (File | SPImage)[];
  setBusinessTaxReturn3File2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  firstIncomeFile: (File | SPImage)[];
  setFirstIncomeFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  firstIncomeFile2: (File | SPImage)[];
  setFirstIncomeFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  secondIncomeFile: (File | SPImage)[];
  setSecondIncomeFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  secondIncomeFile2: (File | SPImage)[];
  setSecondIncomeFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  thirdIncomeFile: (File | SPImage)[];
  setThirdIncomeFile: Dispatch<SetStateAction<(File | SPImage)[]>>;
  thirdIncomeFile2: (File | SPImage)[];
  setThirdIncomeFile2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  driverLicenseFrontImage: (File | SPImage)[];
  setDriverLicenseFrontImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  driverLicenseFrontImage2: (File | SPImage)[];
  setDriverLicenseFrontImage2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  driverLicenseBackImage: (File | SPImage)[];
  setDriverLicenseBackImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  driverLicenseBackImage2: (File | SPImage)[];
  setDriverLicenseBackImage2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  cardNumberFrontImage: (File | SPImage)[];
  setCardNumberFrontImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  cardNumberFrontImage2: (File | SPImage)[];
  setCardNumberFrontImage2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  cardNumberBackImage: (File | SPImage)[];
  setCardNumberBackImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  cardNumberBackImage2: (File | SPImage)[];
  setCardNumberBackImage2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residentRegisterFrontImage: (File | SPImage)[];
  setResidentRegisterFrontImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residentRegisterFrontImage2: (File | SPImage)[];
  setResidentRegisterFrontImage2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residentRegisterBackImage: (File | SPImage)[];
  setResidentRegisterBackImage: Dispatch<SetStateAction<(File | SPImage)[]>>;
  residentRegisterBackImage2: (File | SPImage)[];
  setResidentRegisterBackImage2: Dispatch<SetStateAction<(File | SPImage)[]>>;
  resetImage: () => void;
};

type ImageProviderProps = PropsWithChildren<{}>;

export const ImageContext = createContext<ImageContextType>({
  businessCard: [],
  setBusinessCard() {},
  residenceFile: [],
  setResidenceFile() {},
  residenceFileBackImage: [],
  setResidenceFileBackImage() {},
  residenceIncomeFile: [],
  setResidenceIncomeFile() {},
  residenceIncomeFileBackImage: [],
  setResidenceIncomeFileBackImage() {},
  otherDocumentFile: [],
  setOtherDocumentFile() {},
  otherDocumentFile2: [],
  setOtherDocumentFile2() {},
  repaymentScheduleImage: [],
  setRepaymentScheduleImage() {},
  propertyInformationFile: [],
  setPropertyInformationFile() {},
  insuranceFile: [],
  setInsuranceFile() {},
  insuranceFileBackImage: [],
  setInsuranceFileBackImage() {},
  insuranceFile2: [],
  setInsuranceFile2() {},
  insuranceFileBackImage2: [],
  setInsuranceFileBackImage2() {},
  financialStatement1File: [],
  setFinancialStatement1File() {},
  financialStatement1File2: [],
  setFinancialStatement1File2() {},
  financialStatement2File: [],
  setFinancialStatement2File() {},
  financialStatement2File2: [],
  setFinancialStatement2File2() {},
  financialStatement3File: [],
  setFinancialStatement3File() {},
  financialStatement3File2: [],
  setFinancialStatement3File2() {},
  firstWithholdingSlipFile: [],
  setFirstWithholdingSlipFile() {},
  firstWithholdingSlipFile2: [],
  setFirstWithholdingSlipFile2() {},
  secondWithholdingSlipFile: [],
  setSecondWithholdingSlipFile() {},
  secondWithholdingSlipFile2: [],
  setSecondWithholdingSlipFile2() {},
  employmentAgreementFile: [],
  setEmploymentAgreementFile() {},
  employmentAgreementFile2: [],
  setEmploymentAgreementFile2() {},
  businessTaxReturn1File: [],
  setBusinessTaxReturn1File() {},
  businessTaxReturn1File2: [],
  setBusinessTaxReturn1File2() {},
  businessTaxReturn2File: [],
  setBusinessTaxReturn2File() {},
  businessTaxReturn2File2: [],
  setBusinessTaxReturn2File2() {},
  businessTaxReturn3File: [],
  setBusinessTaxReturn3File() {},
  businessTaxReturn3File2: [],
  setBusinessTaxReturn3File2() {},
  firstIncomeFile: [],
  setFirstIncomeFile() {},
  firstIncomeFile2: [],
  setFirstIncomeFile2() {},
  secondIncomeFile: [],
  setSecondIncomeFile() {},
  secondIncomeFile2: [],
  setSecondIncomeFile2() {},
  thirdIncomeFile: [],
  setThirdIncomeFile() {},
  thirdIncomeFile2: [],
  setThirdIncomeFile2() {},
  driverLicenseFrontImage: [],
  setDriverLicenseFrontImage() {},
  driverLicenseFrontImage2: [],
  setDriverLicenseFrontImage2() {},
  driverLicenseBackImage: [],
  setDriverLicenseBackImage() {},
  driverLicenseBackImage2: [],
  setDriverLicenseBackImage2() {},
  cardNumberFrontImage: [],
  setCardNumberFrontImage() {},
  cardNumberFrontImage2: [],
  setCardNumberFrontImage2() {},
  cardNumberBackImage: [],
  setCardNumberBackImage() {},
  cardNumberBackImage2: [],
  setCardNumberBackImage2() {},
  residentRegisterFrontImage: [],
  setResidentRegisterFrontImage() {},
  residentRegisterFrontImage2: [],
  setResidentRegisterFrontImage2() {},
  residentRegisterBackImage: [],
  setResidentRegisterBackImage() {},
  residentRegisterBackImage2: [],
  setResidentRegisterBackImage2() {},
  resetImage() {},
});

export const ImageProvider: FC<ImageProviderProps> = ({ children }) => {
  const [businessCard, setBusinessCard] = useState<(File | SPImage)[]>([]);
  const [residenceFile, setResidenceFile] = useState<(File | SPImage)[]>([]);
  const [residenceFileBackImage, setResidenceFileBackImage] = useState<
    (File | SPImage)[]
  >([]);
  const [residenceIncomeFile, setResidenceIncomeFile] = useState<
    (File | SPImage)[]
  >([]);
  const [residenceIncomeFileBackImage, setResidenceIncomeFileBackImage] =
    useState<(File | SPImage)[]>([]);
  const [otherDocumentFile, setOtherDocumentFile] = useState<
    (File | SPImage)[]
  >([]);
  const [otherDocumentFile2, setOtherDocumentFile2] = useState<
    (File | SPImage)[]
  >([]);
  const [repaymentScheduleImage, setRepaymentScheduleImage] = useState<
    (File | SPImage)[][]
  >([[]]);

  const [propertyInformationFile, setPropertyInformationFile] = useState<
    (File | SPImage)[]
  >([]);

  const [insuranceFile, setInsuranceFile] = useState<(File | SPImage)[]>([]);
  const [insuranceFileBackImage, setInsuranceFileBackImage] = useState<
    (File | SPImage)[]
  >([]);
  const [insuranceFile2, setInsuranceFile2] = useState<(File | SPImage)[]>([]);
  const [insuranceFileBackImage2, setInsuranceFileBackImage2] = useState<
    (File | SPImage)[]
  >([]);

  const [financialStatement1File, setFinancialStatement1File] = useState<
    (File | SPImage)[]
  >([]);
  const [financialStatement1File2, setFinancialStatement1File2] = useState<
    (File | SPImage)[]
  >([]);

  const [financialStatement2File, setFinancialStatement2File] = useState<
    (File | SPImage)[]
  >([]);
  const [financialStatement2File2, setFinancialStatement2File2] = useState<
    (File | SPImage)[]
  >([]);

  const [financialStatement3File, setFinancialStatement3File] = useState<
    (File | SPImage)[]
  >([]);
  const [financialStatement3File2, setFinancialStatement3File2] = useState<
    (File | SPImage)[]
  >([]);

  const [firstWithholdingSlipFile, setFirstWithholdingSlipFile] = useState<
    (File | SPImage)[]
  >([]);
  const [firstWithholdingSlipFile2, setFirstWithholdingSlipFile2] = useState<
    (File | SPImage)[]
  >([]);

  const [secondWithholdingSlipFile, setSecondWithholdingSlipFile] = useState<
    (File | SPImage)[]
  >([]);
  const [secondWithholdingSlipFile2, setSecondWithholdingSlipFile2] = useState<
    (File | SPImage)[]
  >([]);

  const [employmentAgreementFile, setEmploymentAgreementFile] = useState<
    (File | SPImage)[]
  >([]);
  const [employmentAgreementFile2, setEmploymentAgreementFile2] = useState<
    (File | SPImage)[]
  >([]);

  const [businessTaxReturn1File, setBusinessTaxReturn1File] = useState<
    (File | SPImage)[]
  >([]);
  const [businessTaxReturn1File2, setBusinessTaxReturn1File2] = useState<
    (File | SPImage)[]
  >([]);

  const [businessTaxReturn2File, setBusinessTaxReturn2File] = useState<
    (File | SPImage)[]
  >([]);
  const [businessTaxReturn2File2, setBusinessTaxReturn2File2] = useState<
    (File | SPImage)[]
  >([]);

  const [businessTaxReturn3File, setBusinessTaxReturn3File] = useState<
    (File | SPImage)[]
  >([]);
  const [businessTaxReturn3File2, setBusinessTaxReturn3File2] = useState<
    (File | SPImage)[]
  >([]);

  const [firstIncomeFile, setFirstIncomeFile] = useState<(File | SPImage)[]>(
    []
  );
  const [firstIncomeFile2, setFirstIncomeFile2] = useState<(File | SPImage)[]>(
    []
  );

  const [secondIncomeFile, setSecondIncomeFile] = useState<(File | SPImage)[]>(
    []
  );
  const [secondIncomeFile2, setSecondIncomeFile2] = useState<
    (File | SPImage)[]
  >([]);

  const [thirdIncomeFile, setThirdIncomeFile] = useState<(File | SPImage)[]>(
    []
  );
  const [thirdIncomeFile2, setThirdIncomeFile2] = useState<(File | SPImage)[]>(
    []
  );

  const [driverLicenseFrontImage, setDriverLicenseFrontImage] = useState<
    (File | SPImage)[]
  >([]);
  const [driverLicenseFrontImage2, setDriverLicenseFrontImage2] = useState<
    (File | SPImage)[]
  >([]);

  const [driverLicenseBackImage, setDriverLicenseBackImage] = useState<
    (File | SPImage)[]
  >([]);
  const [driverLicenseBackImage2, setDriverLicenseBackImage2] = useState<
    (File | SPImage)[]
  >([]);

  const [cardNumberFrontImage, setCardNumberFrontImage] = useState<
    (File | SPImage)[]
  >([]);
  const [cardNumberFrontImage2, setCardNumberFrontImage2] = useState<
    (File | SPImage)[]
  >([]);

  const [cardNumberBackImage, setCardNumberBackImage] = useState<
    (File | SPImage)[]
  >([]);
  const [cardNumberBackImage2, setCardNumberBackImage2] = useState<
    (File | SPImage)[]
  >([]);

  const [residentRegisterFrontImage, setResidentRegisterFrontImage] = useState<
    (File | SPImage)[]
  >([]);
  const [residentRegisterFrontImage2, setResidentRegisterFrontImage2] =
    useState<(File | SPImage)[]>([]);

  const [residentRegisterBackImage, setResidentRegisterBackImage] = useState<
    (File | SPImage)[]
  >([]);
  const [residentRegisterBackImage2, setResidentRegisterBackImage2] = useState<
    (File | SPImage)[]
  >([]);

  const resetImage = () => {
    setBusinessCard([]);
    setResidenceFile([]);
    setResidenceFileBackImage([]);
    setResidenceIncomeFile([]);
    setResidenceIncomeFileBackImage([]);
    setOtherDocumentFile([]);
    setOtherDocumentFile2([]);
    setRepaymentScheduleImage([[]]);
    setPropertyInformationFile([]);
    setInsuranceFile([]);
    setInsuranceFileBackImage([]);
    setInsuranceFile2([]);
    setInsuranceFileBackImage2([]);
    setFinancialStatement1File([]);
    setFinancialStatement1File2([]);
    setFinancialStatement2File([]);
    setFinancialStatement2File2([]);
    setFinancialStatement3File([]);
    setFinancialStatement3File2([]);
    setFirstWithholdingSlipFile([]);
    setFirstWithholdingSlipFile2([]);
    setSecondWithholdingSlipFile([]);
    setSecondWithholdingSlipFile2([]);
    setEmploymentAgreementFile([]);
    setEmploymentAgreementFile2([]);
    setBusinessTaxReturn1File([]);
    setBusinessTaxReturn1File2([]);
    setBusinessTaxReturn2File([]);
    setBusinessTaxReturn2File2([]);
    setBusinessTaxReturn3File([]);
    setBusinessTaxReturn3File2([]);
    setFirstIncomeFile([]);
    setFirstIncomeFile2([]);
    setSecondIncomeFile([]);
    setSecondIncomeFile2([]);
    setThirdIncomeFile([]);
    setThirdIncomeFile2([]);
    setDriverLicenseFrontImage([]);
    setDriverLicenseFrontImage2([]);
    setDriverLicenseBackImage([]);
    setDriverLicenseBackImage2([]);
    setCardNumberFrontImage([]);
    setCardNumberFrontImage2([]);
    setCardNumberBackImage([]);
    setCardNumberBackImage2([]);
    setResidentRegisterFrontImage([]);
    setResidentRegisterFrontImage2([]);
    setResidentRegisterBackImage([]);
    setResidentRegisterBackImage2([]);
  };

  return (
    <ImageContext.Provider
      value={{
        businessCard,
        setBusinessCard,
        residenceFile,
        setResidenceFile,
        residenceFileBackImage,
        setResidenceFileBackImage,
        residenceIncomeFile,
        setResidenceIncomeFile,
        residenceIncomeFileBackImage,
        setResidenceIncomeFileBackImage,
        otherDocumentFile,
        setOtherDocumentFile,
        otherDocumentFile2,
        setOtherDocumentFile2,
        repaymentScheduleImage,
        setRepaymentScheduleImage,
        propertyInformationFile,
        setPropertyInformationFile,
        insuranceFile,
        setInsuranceFile,
        insuranceFileBackImage,
        setInsuranceFileBackImage,
        insuranceFile2,
        setInsuranceFile2,
        insuranceFileBackImage2,
        setInsuranceFileBackImage2,
        financialStatement1File,
        setFinancialStatement1File,
        financialStatement1File2,
        setFinancialStatement1File2,
        financialStatement2File,
        setFinancialStatement2File,
        financialStatement2File2,
        setFinancialStatement2File2,
        financialStatement3File,
        setFinancialStatement3File,
        financialStatement3File2,
        setFinancialStatement3File2,
        firstWithholdingSlipFile,
        setFirstWithholdingSlipFile,
        firstWithholdingSlipFile2,
        setFirstWithholdingSlipFile2,
        secondWithholdingSlipFile,
        setSecondWithholdingSlipFile,
        secondWithholdingSlipFile2,
        setSecondWithholdingSlipFile2,
        employmentAgreementFile,
        setEmploymentAgreementFile,
        employmentAgreementFile2,
        setEmploymentAgreementFile2,
        businessTaxReturn1File,
        setBusinessTaxReturn1File,
        businessTaxReturn1File2,
        setBusinessTaxReturn1File2,
        businessTaxReturn2File,
        setBusinessTaxReturn2File,
        businessTaxReturn2File2,
        setBusinessTaxReturn2File2,
        businessTaxReturn3File,
        setBusinessTaxReturn3File,
        businessTaxReturn3File2,
        setBusinessTaxReturn3File2,
        firstIncomeFile,
        setFirstIncomeFile,
        firstIncomeFile2,
        setFirstIncomeFile2,
        secondIncomeFile,
        setSecondIncomeFile,
        secondIncomeFile2,
        setSecondIncomeFile2,
        thirdIncomeFile,
        setThirdIncomeFile,
        thirdIncomeFile2,
        setThirdIncomeFile2,
        driverLicenseFrontImage,
        setDriverLicenseFrontImage,
        driverLicenseFrontImage2,
        setDriverLicenseFrontImage2,
        driverLicenseBackImage,
        setDriverLicenseBackImage,
        driverLicenseBackImage2,
        setDriverLicenseBackImage2,
        cardNumberFrontImage,
        setCardNumberFrontImage,
        cardNumberFrontImage2,
        setCardNumberFrontImage2,
        cardNumberBackImage,
        setCardNumberBackImage,
        cardNumberBackImage2,
        setCardNumberBackImage2,
        residentRegisterFrontImage,
        setResidentRegisterFrontImage,
        residentRegisterFrontImage2,
        setResidentRegisterFrontImage2,
        residentRegisterBackImage,
        setResidentRegisterBackImage,
        residentRegisterBackImage2,
        setResidentRegisterBackImage2,
        resetImage,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};
