import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetPropertyInfoRequest, GetPropertyInfoResponse } from 'types';
import {
  masterBankService,
  applicationsService,
  GetMasterBankResponse,
  GetDynamicOptionsResponse,
  dynamicOptionsService,
} from 'services';

export const getPropertyInfo = createAsyncThunk<
  GetPropertyInfoResponse,
  GetPropertyInfoRequest
>('StartScreen/getPropertyInfo', async (data, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.getPropertyInfo(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMasterBanks = createAsyncThunk<GetMasterBankResponse>(
  'MasterBank/getMasterBank',
  async (data, { rejectWithValue }) => {
    try {
      const response = await masterBankService.getMasterBanks();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDynamicOptions = createAsyncThunk<GetDynamicOptionsResponse>(
  'DynamicOptions/getDynamicOptions',
  async (data, { rejectWithValue }) => {
    try {
      const response = await dynamicOptionsService.getDynamicOptions();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
