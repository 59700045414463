import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPCollateralProviderModal: FC<ModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="担保提供者とは？"
      disableBackDropClick
      sxPadding="2px"
      footer={
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: 359 }}
        >
          <Stack
            sx={{ overflowY: 'scroll', maxHeight: '37vh', px: 6 }}
            spacing={2}
          >
            <Typography variant="sp_change_password_note" color="b_333">
              {`購入物件を共有される場合、共有者のかたには担保提供者となっていただき、共有物件を担保として提供していただく必要があります。`}
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              {`また土地所有者と建物所有者が違う場合なども、担保提供者が必要な場合があります。\nたとえば、親族の土地に住宅を新築する場合、住宅ローンのお申込みにあたっては建物だけでなく土地にも第一順位の抵当権を設定させていただきますので、土地所有者である親族のかたに担保提供者になっていただく必要があります。`}
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              {`担保提供者は親族（除く未成年）の方である必要がありますが、婚約者が担保提供者となる場合は原則借入実行前に入籍が必要です。外国人の場合は、上記条件に加えて、日本で永住許可を取得している必要があります。`}
            </Typography>
          </Stack>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};
