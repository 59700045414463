import { SPImage } from 'components';
import { ImageContext } from 'context';
import { get } from 'lodash';
import { useCallback, useContext } from 'react';
import { SPGetPreliminaryData } from 'types';

export const useSubmittedImages = () => {
  const {
    setResidenceFile,
    setResidenceFileBackImage,
    setResidenceIncomeFile,
    setResidenceIncomeFileBackImage,
    setPropertyInformationFile,
    setRepaymentScheduleImage,
    setDriverLicenseBackImage,
    setDriverLicenseFrontImage,
    setCardNumberBackImage,
    setCardNumberFrontImage,
    setInsuranceFile,
    setFinancialStatement1File,
    setFinancialStatement2File,
    setFinancialStatement3File,
    setFirstWithholdingSlipFile,
    setSecondWithholdingSlipFile,
    setOtherDocumentFile,
    setEmploymentAgreementFile,
    setBusinessTaxReturn1File,
    setBusinessTaxReturn2File,
    setBusinessTaxReturn3File,
    setFirstIncomeFile,
    setSecondIncomeFile,
    setThirdIncomeFile,
    setResidentRegisterFrontImage,
    setResidentRegisterBackImage,
    setDriverLicenseBackImage2,
    setDriverLicenseFrontImage2,
    setCardNumberBackImage2,
    setCardNumberFrontImage2,
    setInsuranceFile2,
    setInsuranceFileBackImage,
    setFinancialStatement1File2,
    setFinancialStatement2File2,
    setFinancialStatement3File2,
    setFirstWithholdingSlipFile2,
    setSecondWithholdingSlipFile2,
    setOtherDocumentFile2,
    setEmploymentAgreementFile2,
    setBusinessTaxReturn1File2,
    setBusinessTaxReturn2File2,
    setBusinessTaxReturn3File2,
    setFirstIncomeFile2,
    setSecondIncomeFile2,
    setThirdIncomeFile2,
    setResidentRegisterFrontImage2,
    setResidentRegisterBackImage2,
    setBusinessCard,
  } = useContext(ImageContext);

  const handleSubmittedImages = useCallback(
    (data: SPGetPreliminaryData) => {
      const { header_file_upload } = data;
      setBusinessCard(
        header_file_upload.business_card.map((file) => ({
          id: file.id,
          url: file.url,
          name: file.filename,
          hiden: file.hiden,
        }))
      );

      setPropertyInformationFile(
        header_file_upload.property_information_file.map((file) => ({
          id: file.id,
          url: file.url,
          name: file.filename,
          hiden: file.hiden,
        }))
      );

      let repaymentScheduleImage: (SPImage | File)[][] = [];

      data.p_borrowings_attributes.forEach((borrowing) => {
        if (
          !!borrowing.repayment_schedule_image &&
          !!borrowing.repayment_schedule_image?.length
        ) {
          repaymentScheduleImage.push(
            borrowing.repayment_schedule_image.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        } else {
          repaymentScheduleImage.push([]);
        }
      });

      setRepaymentScheduleImage(repaymentScheduleImage);
      const personalFileUpload =
        data.p_applicant_people_attributes[0].personal_file_upload;
      Object.keys(personalFileUpload).forEach((key) => {
        const images: {
          filename: string;
          id: string;
          int_id: number;
          url: string;
          hiden: boolean;
        }[] = get(personalFileUpload, key, []);
        switch (key) {
          case 'residence_file':
            setResidenceFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'residence_file_back_image':
            setResidenceFileBackImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'driver_license_front_image':
            setDriverLicenseFrontImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'driver_license_back_image':
            setDriverLicenseBackImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'card_number_front_image':
            setCardNumberFrontImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'card_number_back_image':
            setCardNumberBackImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'insurance_file':
            setInsuranceFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'insurance_file_back_image':
            setInsuranceFileBackImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'financial_statement_1_file':
            setFinancialStatement1File(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'financial_statement_2_file':
            setFinancialStatement2File(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'financial_statement_3_file':
            setFinancialStatement3File(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'first_withholding_slip_file':
            setFirstWithholdingSlipFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'second_withholding_slip_file':
            setSecondWithholdingSlipFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'other_document_file':
            setOtherDocumentFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'employment_agreement_file':
            setEmploymentAgreementFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'business_tax_return_1_file':
            setBusinessTaxReturn1File(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'business_tax_return_2_file':
            setBusinessTaxReturn2File(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'business_tax_return_3_file':
            setBusinessTaxReturn3File(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'first_income_file':
            setFirstIncomeFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'second_income_file':
            setSecondIncomeFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'third_income_file':
            setThirdIncomeFile(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'resident_register_front_image':
            setResidentRegisterFrontImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          case 'resident_register_back_image':
            setResidentRegisterBackImage(
              images.map((file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              }))
            );
            break;
          default:
            break;
        }
      });
      if (!!data.p_applicant_people_attributes[1]) {
        const personalFileUpload2 =
          data.p_applicant_people_attributes[1].personal_file_upload;
        Object.keys(personalFileUpload2).forEach((key) => {
          const images: {
            filename: string;
            id: string;
            int_id: number;
            url: string;
            hiden: boolean;
          }[] = get(personalFileUpload2, key, []);
          switch (key) {
            case 'residence_file':
              setResidenceIncomeFile(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'residence_file_back_image':
              setResidenceIncomeFileBackImage(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'driver_license_front_image':
              setDriverLicenseFrontImage2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'driver_license_back_image':
              setDriverLicenseBackImage2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'card_number_front_image':
              setCardNumberFrontImage2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'card_number_back_image':
              setCardNumberBackImage2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'insurance_file':
              setInsuranceFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'financial_statement_1_file':
              setFinancialStatement1File2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'financial_statement_2_file':
              setFinancialStatement2File2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'financial_statement_3_file':
              setFinancialStatement3File2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'first_withholding_slip_file':
              setFirstWithholdingSlipFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'second_withholding_slip_file':
              setSecondWithholdingSlipFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'other_document_file':
              setOtherDocumentFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'employment_agreement_file':
              setEmploymentAgreementFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'business_tax_return_1_file':
              setBusinessTaxReturn1File2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'business_tax_return_2_file':
              setBusinessTaxReturn2File2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'business_tax_return_3_file':
              setBusinessTaxReturn3File2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'first_income_file':
              setFirstIncomeFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'second_income_file':
              setSecondIncomeFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'third_income_file':
              setThirdIncomeFile2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'resident_register_front_image':
              setResidentRegisterFrontImage2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            case 'resident_register_back_image':
              setResidentRegisterBackImage2(
                images.map((file) => ({
                  id: file.id,
                  url: file.url,
                  name: file.filename,
                  hiden: file.hiden,
                }))
              );
              break;
            default:
              break;
          }
        });
      }
    },
    [
      setBusinessCard,
      setRepaymentScheduleImage,
      setResidenceFile,
      setPropertyInformationFile,
      setDriverLicenseFrontImage,
      setDriverLicenseBackImage,
      setCardNumberFrontImage,
      setCardNumberBackImage,
      setInsuranceFile,
      setFinancialStatement1File,
      setFinancialStatement2File,
      setFinancialStatement3File,
      setFirstWithholdingSlipFile,
      setSecondWithholdingSlipFile,
      setOtherDocumentFile,
      setEmploymentAgreementFile,
      setBusinessTaxReturn1File,
      setBusinessTaxReturn2File,
      setBusinessTaxReturn3File,
      setFirstIncomeFile,
      setSecondIncomeFile,
      setThirdIncomeFile,
      setResidentRegisterFrontImage,
      setResidentRegisterBackImage,
      setResidenceIncomeFile,
      setDriverLicenseFrontImage2,
      setDriverLicenseBackImage2,
      setCardNumberFrontImage2,
      setCardNumberBackImage2,
      setInsuranceFile2,
      setFinancialStatement1File2,
      setFinancialStatement2File2,
      setFinancialStatement3File2,
      setFirstWithholdingSlipFile2,
      setSecondWithholdingSlipFile2,
      setOtherDocumentFile2,
      setEmploymentAgreementFile2,
      setBusinessTaxReturn1File2,
      setBusinessTaxReturn2File2,
      setBusinessTaxReturn3File2,
      setFirstIncomeFile2,
      setSecondIncomeFile2,
      setThirdIncomeFile2,
      setResidentRegisterFrontImage2,
      setResidentRegisterBackImage2,
    ]
  );

  return handleSubmittedImages;
};
