import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SPStepLayout } from 'containers';
import { IconButton, Stack, Typography } from '@mui/material';
import {
  Icons,
  InputSelectProps,
  SPButton,
  SPFormItem,
  SPImage,
  SPInputDateTime,
  SPInputField,
  SPInputPhoneNumber,
  SPInputSelect,
  SPInputZipCode,
  SPModal,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPSaveImageModal,
} from 'components';
import {
  BorrowerIncomeType,
  GeneralIncomeConfirmation,
  LoanType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PREFECTURES,
  SexType,
  sex,
} from 'constant';
import {
  isIncomeTotalizerInitSelector,
  spCurrentStepSelector,
} from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import kanjidate from 'kanjidate';
import { dayjs } from 'libs';
import { get, isEmpty, update } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spStepEightSelector } from 'pages/SPStepEight/selectors';
import { spStepFiveSelector } from 'pages/SPStepFive/selectors';
import { spStepFourSelector } from 'pages/SPStepFour/selectors';
import { spStepSevenSelector } from 'pages/SPStepSeven/selectors';
import { spStepSixSelector } from 'pages/SPStepSix/selectors';
import { spStepThreeSelector } from 'pages/SPStepThree/selectors';
import { spStepTwoSelector } from 'pages/SPStepTwo/selectors';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostalCode } from 'services';
import {
  checkingExistFile,
  convertToFullWidth,
  convertToHalfWidth,
  flattenToLodashFormat,
  kanaMap1,
  supportDvh,
  zeroPad,
} from 'utils';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneSelector,
} from '../SPStepOne/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepTenSelector } from '../SPStepTen/selectors';
import { getZipCloudAddress } from '../StepRequiredInformation/thunk';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { spStepCollateralProviderSelector } from './selectors';
import { saveForm } from './slice';
import { validationSchema } from './validationSchema';

const SPStepFourCollateralProviderPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const spStepOneValues = useSelector(spStepOneSelector);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const initialValues = useSelector(spStepCollateralProviderSelector);
  const currentStep = useSelector(spCurrentStepSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const { application_number } = useSelector(spStepTenSelector);
  const isIncomeTotalizerInit = useSelector(isIncomeTotalizerInitSelector);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const {
    businessCard,
    residenceFile,
    residenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    repaymentScheduleImage,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [open, setOpen] = useState(false);
  const [openDraft, setOpenDraft] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [firstPostalCodeError, setFirstPostalCodeError] = useState<string>('');
  const [secondPostalCodeError, setSecondPostalCodeError] =
    useState<string>('');
  const [thirdPostalCodeError, setThirdPostalCodeError] = useState<string>('');
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const { updateFormOverride } = useUpdateForm();
  const location = useLocation();

  // @ts-ignore
  const previousPathName = !!location.state ? location.state.pathName : '';

  const formik = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values, { setSubmitting }) {
      //reset other_address_kana
      initialValues.p_join_guarantors.forEach((joinGuarantors, index) => {
        if (
          joinGuarantors.postal_code &&
          values.p_join_guarantors?.[index]?.postal_code !==
            joinGuarantors.postal_code
        ) {
          values.p_join_guarantors[index].other_address_kana = '';
        }
      });

      await dispatch(saveForm({ p_application_header: values }));
      if (incomeTotalizer && +currentStep < 7) {
        dispatch(setCurrentStep('7'));
      } else if (+currentStep < 5) {
        dispatch(setCurrentStep('5'));
      }
      setSubmitting(false);
      if (isSubmit) {
        setIsDisabledButton(true);
        updateFormOverride(
          {
            stepCollateralProvider: { p_application_header: values },
            imageContext: imageContext,
          },
          setIsDisabledButton,
          setOpenImageModal
        );
        return;
      }
      if (!!application_number && incomeTotalizer && hasJoinGuarantor) {
        navigate(routeNames.SPStepSevenIncomeTotalizer.path);
        return;
      }
      if (!application_number) {
        navigate(routeNames.SPStepFour.path);
      }
    },
  });

  const isSubmit = useMemo(() => {
    return (
      !!application_number &&
      (previousPathName === routeNames.Top.path ||
        previousPathName === routeNames.SPStepNine.path ||
        (hasJoinGuarantor &&
          (isIncomeTotalizerInit === incomeTotalizer || !incomeTotalizer)))
    );
  }, [
    application_number,
    hasJoinGuarantor,
    incomeTotalizer,
    isIncomeTotalizerInit,
    previousPathName,
  ]);

  useEffect(() => {
    if (!!application_number && !hasJoinGuarantor) {
      navigate(routeNames.SPStepOne.path);
    }
  }, [application_number, hasJoinGuarantor, navigate]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  const setHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      postal_code: postal.zipcode.slice(0, 3) + '-' + postal.zipcode.slice(3),
      prefecture_kanji: postal.address1,
      district_kanji: postal.address3,
      city_kanji: postal.address2,
      prefecture_kana: convertToFullWidth(postal.kana1),
      district_kana: convertToFullWidth(postal.kana3),
      city_kana: convertToFullWidth(postal.kana2),
      other_address_kanji: '',
    });
    await formik.setFieldTouched(`${name}.other_address_kanji`, false, false);
  };

  const handleZipCodeChange = async (
    value: string,
    index: number,
    currentIndex: number
  ) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        if (currentIndex === 0) {
          setFirstPostalCodeError(
            '※住所が取得できませんでした。再度入力してください。'
          );
        }
        if (currentIndex === 1) {
          setSecondPostalCodeError(
            '※住所が取得できませんでした。再度入力してください。'
          );
        }
        if (currentIndex === 2) {
          setThirdPostalCodeError(
            '※住所が取得できませんでした。再度入力してください。'
          );
        }
        await formik.setFieldValue(
          `p_join_guarantors[${index}].prefecture_kanji`,
          ''
        );
        await formik.setFieldValue(
          `p_join_guarantors[${index}].city_kanji`,
          ''
        );
        await formik.setFieldValue(
          `p_join_guarantors[${index}].district_kanji`,
          ''
        );
        await formik.setFieldValue(
          `p_join_guarantors[${index}].prefecture_kana`,
          ''
        );
        await formik.setFieldValue(`p_join_guarantors[${index}].city_kana`, '');
        await formik.setFieldValue(
          `p_join_guarantors[${index}].district_kana`,
          ''
        );
        return;
      }
      if (currentIndex === 0) {
        setFirstPostalCodeError('');
      }
      if (currentIndex === 1) {
        setSecondPostalCodeError('');
      }
      if (currentIndex === 2) {
        setThirdPostalCodeError('');
      }
      await setHomeAddress(
        result.payload.results[0],
        `p_join_guarantors[${index}]`
      );
    }
  };

  const getErrorMessage = (index: number) => {
    if (index === 0) {
      return firstPostalCodeError;
    }
    if (index === 1) {
      return secondPostalCodeError;
    }
    if (index === 2) {
      return thirdPostalCodeError;
    }
    return '';
  };

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);
    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });
    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });

    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(formik.values.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/

    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    Object.keys(spStepSevenValues.p_applicant_people).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepSevenValues.p_applicant_people, key) ?? ''
      );
    });

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people]${key}`, file)
            : data.append(`draft[p_applicant_people]${key}[]`, file);
        } else {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people]${key}`, file.id.toString())
            : data.append(
                `draft[p_applicant_people]${key}[]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpenDraft(true);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement;
    let transformedValue = value;
    Object.entries(kanaMap1).forEach(([key, replacement]) => {
      transformedValue = transformedValue.replaceAll(key, replacement);
    });
    formik.setFieldValue(name, transformedValue);
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={() =>
          dispatch(saveForm({ p_application_header: formik.values }))
        }
        footer={{
          left: {
            onClick: async () => {
              await dispatch(saveForm({ p_application_header: formik.values }));
              if (
                !!previousPathName &&
                previousPathName === routeNames.SPStepOne.path
              ) {
                navigate(routeNames.SPStepOne.path, {
                  state: { pathName: window.location.pathname },
                });
              } else {
                if (!!application_number) {
                  navigate(-1);
                } else {
                  if (hasJoinGuarantor && incomeTotalizer) {
                    navigate(routeNames.SPStepFiveIncomeTotalizer.path);
                  } else {
                    navigate(routeNames.SPStepThree.path);
                  }
                }
              }
            },
          },
          right: {
            children: isSubmit ? '保存' : '次へ',
            onClick: () => {
              formik.handleSubmit();
            },
            disabled: isDisabledButton,
          },
        }}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)'), pb: 22 }}
        onSaveDraft={onSaveDraft}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%', pb: 8 }}>
          <Stack
            sx={{ pt: 9, py: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >{`担保提供者について\n教えてください。`}</Typography>
          </Stack>
          <IconButton
            sx={{
              color: 'sp_primary_100_main',
              p: '0 2px',
              direction: 'row',
            }}
            onClick={() => setOpen(true)}
          >
            <Icons.SPQuestion sx={{ width: 16, height: 16 }} />
            <Typography
              variant="SP_form_item_help"
              color="sp_primary_100_main"
              sx={{
                textDecoration: 'underline',
              }}
            >
              担保提供者とは？
            </Typography>
          </IconButton>
        </Stack>

        <FieldArray
          name="p_join_guarantors"
          render={(arrayHelpers) => (
            <Fragment>
              <Stack px={4} spacing={8}>
                {spStepOneValues.p_application_header.loan_type ===
                  LoanType.TWO && (
                  <Stack justifyContent="center" direction="row" spacing={1}>
                    <Typography variant="annotation01" color="b_333">
                      ※
                    </Typography>
                    <Typography
                      variant="annotation01"
                      color="b_333"
                      sx={{
                        whiteSpace: 'break-spaces',
                        textAlign: 'left',
                      }}
                    >
                      ペアローンの場合、どちらかの申込者の方に記入いただければ結構です。
                    </Typography>
                  </Stack>
                )}
                {formik.values.p_join_guarantors.map((guarantor, index) => {
                  const joinGuarantors = formik.values.p_join_guarantors.filter(
                    (guarantor) => !guarantor._destroy
                  );
                  const currentIndex = joinGuarantors.findIndex(
                    (e) => e === guarantor
                  );
                  return (
                    !guarantor._destroy && (
                      <Stack
                        key={index}
                        sx={{
                          borderRadius: 2,
                          border: (theme) =>
                            `1px solid ${theme?.palette?.sp_primary_100_main}`,
                        }}
                      >
                        {currentIndex > 0 && (
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{
                              py: 1,
                              px: 3,
                              bgcolor: 'sp_primary_100_main',
                              borderRadius: '6px 6px 0 0',
                            }}
                          >
                            <Typography
                              variant="sp_step_06_form_title"
                              color="main_white"
                            >
                              {`${currentIndex + 1}人目`}
                            </Typography>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                              onClick={() => {
                                if (!guarantor.id) {
                                  arrayHelpers.remove(index);
                                } else {
                                  arrayHelpers.replace(index, {
                                    ...guarantor,
                                    _destroy: true,
                                  });
                                }
                              }}
                            >
                              <Icons.SPDelete sx={{ width: 16, height: 19 }} />
                              <Typography
                                variant="SP_label_field"
                                color="main_white"
                              >
                                削除する
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                        <SPFormItem
                          label="担保提供者の氏名"
                          childrenSx={{ p: '8px 12px' }}
                          labelSx={{
                            p: '4px 12px',
                            borderRadius:
                              currentIndex === 0 ? '8px 8px 0 0' : 0,
                          }}
                          required
                        >
                          <Stack spacing={`10px`}>
                            <SPInputField
                              name={`p_join_guarantors[${index}].last_name_kanji`}
                              placeholder="姓"
                              convertFullWidth
                            />
                            <SPInputField
                              name={`p_join_guarantors[${index}].first_name_kanji`}
                              placeholder="名"
                              convertFullWidth
                            />
                          </Stack>
                        </SPFormItem>
                        <SPFormItem
                          label="担保提供者の氏名（フリガナ）"
                          childrenSx={{ p: '8px 12px' }}
                          labelSx={{ p: '4px 12px' }}
                          required
                        >
                          <Stack spacing={`10px`}>
                            <SPInputField
                              name={`p_join_guarantors[${index}].last_name_kana`}
                              placeholder="セイ"
                              onBlur={(event) => handleBlur(event)}
                            />
                            <SPInputField
                              name={`p_join_guarantors[${index}].first_name_kana`}
                              placeholder="メイ"
                              onBlur={(event) => handleBlur(event)}
                            />
                          </Stack>
                        </SPFormItem>
                        <SPFormItem
                          label="性別"
                          childrenSx={{ p: '8px 12px' }}
                          labelSx={{ p: '4px 12px' }}
                          required
                        >
                          <SPRadioGroupButton
                            name={`p_join_guarantors[${index}].sex`}
                            options={sexOptions}
                            display="row"
                            required
                          />
                        </SPFormItem>
                        <SPFormItem
                          label="続柄"
                          childrenSx={{ p: '8px 12px' }}
                          labelSx={{ p: '4px 12px' }}
                          required
                        >
                          <SPInputField
                            name={`p_join_guarantors[${index}].guarantor_relationship_name`}
                            placeholder="例：父"
                            convertFullWidth
                          />
                        </SPFormItem>
                        <SPFormItem
                          label="生年月日"
                          childrenSx={{ p: '8px 12px' }}
                          labelSx={{ p: '4px 12px' }}
                          required
                        >
                          <SPInputDateTime
                            name={`p_join_guarantors[${index}].birthday`}
                            yearOptions={yearOptions}
                            checkRequired
                          />
                        </SPFormItem>
                        <SPFormItem
                          label="電話番号"
                          childrenSx={{ p: '8px 12px' }}
                          labelSx={{ p: '4px 12px' }}
                          required
                        >
                          <Stack spacing={3}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="sp_unit_select_text"
                                color="b_333"
                              >
                                携帯
                              </Typography>
                              <SPInputPhoneNumber
                                name={`p_join_guarantors[${index}].mobile_phone_number`}
                                type="mobilePhone"
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                variant="sp_unit_select_text"
                                color="b_333"
                              >
                                自宅
                              </Typography>
                              <SPInputPhoneNumber
                                name={`p_join_guarantors[${index}].home_phone_number`}
                                type="homePhone"
                              />
                            </Stack>
                            <Typography
                              variant="SP_form_item_note"
                              color="b_333"
                            >
                              ※半角数字でご入力ください。
                            </Typography>
                          </Stack>
                        </SPFormItem>
                        <SPFormItem
                          label="担保提供者の住所"
                          childrenSx={{
                            p: '8px 12px',
                            borderRadius: '0 0 8px 8px',
                          }}
                          labelSx={{ p: '4px 12px' }}
                          required
                        >
                          <Stack spacing={6}>
                            <Stack spacing={`6px`}>
                              <SPInputZipCode
                                name={`p_join_guarantors[${index}].postal_code`}
                                label="郵便番号"
                                handleInputZipcode={(values) => {
                                  if (
                                    values?.length === 8 &&
                                    values === convertToHalfWidth(values)
                                  ) {
                                    return handleZipCodeChange(
                                      values,
                                      index,
                                      currentIndex
                                    );
                                  }
                                }}
                                handleFocusZipCode={() => {
                                  if (currentIndex === 0) {
                                    return setFirstPostalCodeError('');
                                  }
                                  if (currentIndex === 1) {
                                    return setSecondPostalCodeError('');
                                  }
                                  if (currentIndex === 2) {
                                    return setThirdPostalCodeError('');
                                  }
                                }}
                              />
                              {((currentIndex === 0 && firstPostalCodeError) ||
                                (currentIndex === 1 && secondPostalCodeError) ||
                                (currentIndex === 2 &&
                                  thirdPostalCodeError)) && (
                                <Typography
                                  variant="text_error"
                                  color="sp_secondary_01"
                                >
                                  {getErrorMessage(currentIndex)}
                                </Typography>
                              )}
                              <Typography
                                variant="SP_form_item_note"
                                color="b_333"
                              >
                                ※入力すると自動的に住所が表示されます。
                              </Typography>
                            </Stack>
                            <SPInputSelect
                              name={`p_join_guarantors[${index}].prefecture_kanji`}
                              placeholder="----"
                              label="都道府県"
                              options={PREFECTURES}
                              sx={{ maxWidth: '110px', textAlign: 'center' }}
                              onChange={() => {
                                if (
                                  formik.values.p_join_guarantors[index]
                                    ?.prefecture_kana
                                ) {
                                  formik.setFieldValue(
                                    `p_join_guarantors[${index}].prefecture_kana`,
                                    ''
                                  );
                                }
                              }}
                            />
                            <SPInputField
                              name={`p_join_guarantors[${index}].city_kanji`}
                              placeholder="例：港区"
                              label="市区郡　（例：港区）"
                              convertFullWidth
                              onChange={() => {
                                if (
                                  formik.values.p_join_guarantors[index]
                                    ?.city_kana
                                ) {
                                  formik.setFieldValue(
                                    `p_join_guarantors[${index}].city_kana`,
                                    ''
                                  );
                                }
                              }}
                            />
                            <SPInputField
                              name={`p_join_guarantors[${index}].district_kanji`}
                              placeholder="例：芝浦４丁目"
                              label="町村丁目（例：芝浦４丁目）"
                              convertFullWidth
                              onChange={() => {
                                if (
                                  formik.values.p_join_guarantors[index]
                                    ?.district_kana
                                ) {
                                  formik.setFieldValue(
                                    `p_join_guarantors[${index}].district_kana`,
                                    ''
                                  );
                                }
                              }}
                            />
                            <SPInputField
                              name={`p_join_guarantors[${index}].other_address_kanji`}
                              placeholder="例：12-38　キャナルゲート芝浦605号室"
                              label="丁目以下・建物名・部屋番号（例：12-38　キャナルゲート芝浦605号室）"
                              convertFullWidth
                              labelStyle={{ lineHeight: '20px' }}
                              onChange={() => {
                                if (
                                  formik.values.p_join_guarantors[index]
                                    ?.other_address_kana
                                ) {
                                  formik.setFieldValue(
                                    `p_join_guarantors[${index}].other_address_kana`,
                                    ''
                                  );
                                }
                              }}
                            />
                          </Stack>
                        </SPFormItem>
                      </Stack>
                    )
                  );
                })}
                {formik.values.p_join_guarantors.filter(
                  (guarantor) => !guarantor._destroy
                ).length < 3 && (
                  <Stack mt={8} px={6} spacing={2}>
                    <Stack direction="row" spacing={1} justifyContent="center">
                      <Typography variant="SP_form_item_note" color="b_333">
                        ※
                      </Typography>
                      <Typography variant="SP_form_item_note" color="b_333">
                        担保提供者が複数いる場合は、追加してください。
                      </Typography>
                    </Stack>
                    <SPButton
                      sx={{ bgcolor: 'sp_primary_100_main' }}
                      onClick={() => {
                        arrayHelpers.push({
                          last_name_kanji: '',
                          first_name_kanji: '',
                          last_name_kana: '',
                          first_name_kana: '',
                          sex: '',
                          guarantor_relationship_name: '',
                          birthday: '',
                          mobile_phone_number: '',
                          home_phone_number: '',
                          postal_code: '',
                          prefecture_kanji: '',
                          city_kanji: '',
                          district_kanji: '',
                          other_address_kana: '',
                          other_address_kanji: '',
                        });
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Icons.SPCircle
                          sx={{
                            width: '32px',
                            height: '32px',
                            color: 'main_white',
                          }}
                        />
                        <Typography variant="SP_multiple_checkbox_label">
                          担保提供者を追加
                        </Typography>
                      </Stack>
                    </SPButton>
                  </Stack>
                )}
              </Stack>
            </Fragment>
          )}
        />

        <SPSaveDraftModal
          open={openDraft}
          onClose={() => setOpenDraft(false)}
        />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
        />

        <SPModal
          open={open}
          onClose={() => setOpen(false)}
          icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
          title="担保提供者とは？"
          disableBackDropClick
          sxPadding="2px"
          footer={
            <Stack spacing={6} justifyContent="center" alignItems="center">
              <Stack
                sx={{ overflowY: 'scroll', maxHeight: '37vh', px: 6 }}
                spacing={2}
              >
                <Typography variant="sp_change_password_note" color="b_333">
                  {`購入物件を共有される場合、共有者のかたには担保提供者となっていただき、共有物件を担保として提供していただく必要があります。\n\nまた土地所有者と建物所有者が違う場合なども、担保提供者が必要な場合があります。\nたとえば、親族の土地に住宅を新築する場合、住宅ローンのお申込みにあたっては建物だけでなく土地にも第一順位の抵当権を設定させていただきますので、土地所有者である親族のかたに担保提供者になっていただく必要があります。\n\n担保提供者は親族（除く未成年）の方である必要がありますが、婚約者が担保提供者となる場合は原則借入実行前に入籍が必要です。外国人の場合は、上記条件に加えて、日本で永住許可を取得している必要があります。`}
                </Typography>
              </Stack>
              <SPButton
                sx={{
                  width: '160px',
                  height: '40px',
                  bgcolor: 'sp_primary_40',
                }}
                endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
                onClick={() => setOpen(false)}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="sp_primary_100_main"
                >
                  とじる
                </Typography>
              </SPButton>
            </Stack>
          }
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepFourCollateralProviderPage;

type RadioItems = {
  value: string;
  label: string;
};
export const sexOptions: Array<RadioItems> = [
  {
    value: SexType.MAN,
    label: sex[SexType.MAN],
  },
  {
    value: SexType.WOMAN,
    label: sex[SexType.WOMAN],
  },
];

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(83), (_, index) => {
    const year = zeroPad(dayjs().year() - 18 - index);
    const startDay = year === '1989' ? 8 : 1;
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, startDay);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, startDay);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);
