import { regex, yup } from 'libs';
import {
  DispatchType,
  FileATaxReturnType,
  FinalTaxReturnType,
  LeaveType,
  MESSAGE_REGEX,
  OccupationDetailType,
  OccupationType,
  SP_FIELD_ERROR_MESSAGE,
  VALIDATE_MAX,
  WorkIndustryType,
} from 'constant';
import {
  convertManYenToYen,
  messageOverMaxManYen,
  messageOverMaxNumber,
} from 'utils';

export const validationSchema = yup.object().shape({
  p_applicant_people: yup.object().shape({
    occupation: yup.string(),
    other_occupation: yup.string().when('occupation', {
      is: (occupation: OccupationType) => occupation === OccupationType.OTHERS,
      then: yup.string().required(MESSAGE_REGEX.REQUIRED).max(40),
      // .matches(
      //   regex.kanjiFullwidthHaveNumber,
      //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
      // ),
      otherwise: yup.string(),
    }),
    industry: yup.string(),
    other_industry: yup.string().when('industry', {
      is: (industry: WorkIndustryType) => industry === WorkIndustryType.OTHERS,
      then: yup.string().required(MESSAGE_REGEX.REQUIRED).max(40),
      // .matches(
      //   regex.kanjiFullwidthHaveNumber,
      //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
      // ),
      otherwise: yup.string(),
    }),
    occupation_detail: yup.string(),
    other_occupation_detail: yup.string().when('occupation_detail', {
      is: (occupation_detail: OccupationDetailType) =>
        occupation_detail === OccupationDetailType.OTHERS,
      then: yup.string().required(MESSAGE_REGEX.REQUIRED).max(40),
      // .matches(
      //   regex.kanjiFullwidthHaveNumber,
      //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
      // ),
      otherwise: yup.string(),
    }),
    office_name_kanji: yup
      .string()
      .max(48)
      .matches(
        regex.kanjiFullwidthHaveNumber,
        MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
      ),
    department: yup
      .string()
      .max(46)
      .matches(
        regex.kanjiFullwidthHaveNumber,
        MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
      ),
    office_phone_number: yup
      .string()
      // .noZeroPhoneNumber()
      // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_SP),
      .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER),
    office_postal_code: yup
      .string()
      .min(7, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
      .matches(regex.zipCode, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS),
    office_prefecture_kanji: yup
      .string()
      .max(20)
      .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
    office_city_kanji: yup
      .string()
      .max(20)
      .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
    office_district_kanji: yup
      .string()
      .max(40)
      .matches(regex.kanjiFullwidthHaveNumber, MESSAGE_REGEX.ADDRESS_KANJI),
    other_office_address_kanji: yup
      .string()
      .max(99)
      .matches(regex.kanjiFullwidthHaveNumber, MESSAGE_REGEX.ADDRESS_KANJI),
    number_of_employee: yup
      .number()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(1)
      .max(9999999, messageOverMaxNumber(9999999)),
    employment_started_date: yup
      .string()
      // .test('not-pass-current', MESSAGE_REGEX.NOT_PASS_CURRENT, (value) => {
      //   if (!value) return true;
      //   const [year, month] = value.split('/');
      //   const currentMonth = dayjs().month() + 1;
      //   const currentYear = dayjs().year();
      //   return !(+year === currentYear && +month > currentMonth);
      // })
      .test(
        'one-field-missing',
        MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
        (value) => {
          const [year, month] = value ? value.split('/') : ['', ''];
          return (!!year && !!month) || (!year && !month);
        }
      )
      .label('入社年月'),
    last_year_income: yup
      .number()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    bonus_income: yup
      .number()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    two_years_ago_income: yup
      .number()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    income_source: yup.array(),
    tax_return: yup.string(),
    type_tax_return: yup.array().when('tax_return', {
      is: (tax_return: FinalTaxReturnType) =>
        tax_return === FinalTaxReturnType.YES,
      then: yup
        .array()
        .min(1, MESSAGE_REGEX.DROPDOWN_REQUIRED)
        .required(MESSAGE_REGEX.RADIO_REQUIRED),
      otherwise: yup.array(),
    }),
    other_type_tax_return: yup.string().when('type_tax_return', {
      is: (type_tax_return: FileATaxReturnType[]) =>
        type_tax_return.includes(FileATaxReturnType.OTHERS),
      then: yup
        .string()
        .required(MESSAGE_REGEX.REQUIRED)
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH
        // )
        .max(40, MESSAGE_REGEX.OTHER_TYPE_TAX_RETURN_LIMIT),
      otherwise: yup.string(),
    }),
    transfer_office: yup.string(),
    transfer_office_name_kanji: yup
      .string()
      .max(48)
      .matches(
        regex.kanjiFullwidthHaveNumber,
        MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
      )
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string(),
      }),
    transfer_office_name_kana: yup
      .string()
      .max(48)
      .matches(regex.kanaHalfwidthHaveNumber, MESSAGE_REGEX.SP_KANA_FULL_WIDTH)
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string(),
      }),
    transfer_office_phone_number: yup
      .string()
      // .noZeroPhoneNumber()
      // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_SP)
      .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER)
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup.string().required(MESSAGE_REGEX.REQUIRED),
      }),
    transfer_office_postal_code: yup
      .string()
      .min(7, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
      .matches(regex.zipCode, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string(),
      }),
    transfer_office_prefecture_kanji: yup
      .string()
      .max(20)
      .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH)
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup
          .string()
          .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
          .label(
            SP_FIELD_ERROR_MESSAGE.applicant_people_transfer_office_prefecture_kanji
          ),
        otherwise: yup.string(),
      }),
    transfer_office_city_kanji: yup
      .string()
      .max(20)
      .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH)
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string(),
      }),
    transfer_office_district_kanji: yup
      .string()
      .max(40)
      .matches(regex.kanjiFullwidthHaveNumber, MESSAGE_REGEX.ADDRESS_KANJI)
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string(),
      }),
    transfer_office_other_address_kanji: yup
      .string()
      .max(99)
      .matches(regex.kanjiFullwidthHaveNumber, MESSAGE_REGEX.ADDRESS_KANJI)
      .when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string(),
      }),
    maternity_paternity_leave_status: yup.string(),
    maternity_paternity_leave_start_time: yup
      .string()
      .when('maternity_paternity_leave_status', {
        is: (leave_status: LeaveType) => !!leave_status,
        then: yup.string().required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
      })
      .test(
        'one-field-missing',
        MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
        (value) => {
          const [year, month] = value ? value.split('/') : ['', ''];
          return (!!year && !!month) || (!year && !month);
        }
      )
      .label(
        SP_FIELD_ERROR_MESSAGE.applicant_people_maternity_paternity_leave_start_time
      ),
    maternity_paternity_leave_end_time: yup
      .string()
      // .test({
      //   message:
      //     MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_ACQUISITION_START_TIME,
      //   test(value) {
      //     if (!value && !this.parent.maternity_paternity_leave_start_time)
      //       return true;
      //     if (!value || !this.parent.maternity_paternity_leave_start_time)
      //       return false;
      //     const [year, month] = value.split('/');
      //     const endTime = dayjs(`${year}-${month}`);
      //     return endTime.isSameOrAfter(
      //       dayjs(this.parent.maternity_paternity_leave_start_time),
      //       'date'
      //     );
      //   },
      // })
      .when('maternity_paternity_leave_status', {
        is: (leave_status: LeaveType) => !!leave_status,
        then: yup.string().required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
      })
      .test(
        'one-field-missing',
        MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
        (value) => {
          const [year, month] = value ? value.split('/') : ['', ''];
          return (!!year && !!month) || (!year && !month);
        }
      )
      .label(
        SP_FIELD_ERROR_MESSAGE.applicant_people_maternity_paternity_leave_end_time
      ),
    nursing_leave_status: yup.string(),
  }),
});
