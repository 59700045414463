import { Stack } from '@mui/material';
import { MANAGER_ROLE } from 'constant';
import { managerRoleSelector } from 'pages/Login/selectors';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { hiddenScrollBar } from 'styles';
import { ModalConfirm } from '..';
import { Button, Icons } from '../../atoms';
import { ConfirmValidateFormModal } from '../../../pages/AdminEditPreliminary/components';

export interface MainStatus {
  key: String;
  value: Number;
  active: boolean;
  disable: boolean;
}

export type ChangeStatus = {
  id: string;
  status_result: string;
};

export type ResultProps = {
  editable: boolean;
  mainStatuses: MainStatus[];
  isInvalid: boolean;
  isChangeFiles: boolean;
  onUpdate: (
    indexUpdate: number,
    statusUpdate: string,
    updateReset?: boolean
  ) => void;
  checkIsEdit: () => boolean;
};

export const ResultsStatus: FC<ResultProps> = ({
  editable,
  mainStatuses,
  onUpdate,
  isInvalid,
  isChangeFiles,
  checkIsEdit,
}) => {
  const managerRole = useSelector(managerRoleSelector);
  const isEdited = useMemo(() => checkIsEdit(), [checkIsEdit]);
  const [indexActivated, setIndexActivated] = useState<number>(-1);
  const [open, setOpen] = useState<boolean>(false);
  const [openValidate, setOpenValidate] = useState<boolean>(false);
  const [indexUpdate, setIndexUpdate] = useState<number>(0);
  const [statusUpdate, setStatusUpdate] = useState<string>('');

  const onClick = (index: number, statusResult: string) => {
    if (isInvalid) {
      setOpenValidate(true);
      return;
    }
    if (managerRole !== MANAGER_ROLE.ADMIN && index === 3) return;
    if (indexActivated === +statusResult && mainStatuses[3].active === true) {
      setOpen(true);
      setIndexUpdate(index);
      setStatusUpdate(statusResult);
    } else {
      if (index > 4) return;
      if (index === indexActivated + 1) {
        setIndexUpdate(index);
        setStatusUpdate(statusResult);
        setOpen(true);
      }
    }
  };

  const updateStatus = useCallback(() => {
    if (
      indexActivated === 3 &&
      !!mainStatuses[indexActivated].active &&
      indexActivated === +statusUpdate
    ) {
      onUpdate(indexUpdate, statusUpdate, true);
    } else {
      onUpdate(indexUpdate, statusUpdate);
    }
    setOpen(false);
  }, [indexActivated, indexUpdate, mainStatuses, onUpdate, statusUpdate]);

  useEffect(() => {
    mainStatuses.forEach((status, index) => {
      if (status.active) {
        setIndexActivated(index);
        return;
      }
    });
  }, [mainStatuses]);

  const isDisabledButton = (index: number) => {
    return (
      index < indexActivated ||
      (index === 4 && managerRole !== MANAGER_ROLE.ADMIN)
    );
  };

  const renderColorButton = (index: number, active: boolean) => {
    if (index === 4 && (!active || managerRole !== MANAGER_ROLE.ADMIN))
      return 'sh_red';
    if (index === 4 && active) return 'main_white';
    if (index > 4 && active) return 'main_white';
    if (index > 4 && !active) return 'b_333';
    return active ? 'main_white' : 'sp_primary_100_main';
  };

  const renderBolderColor = (index: number, active: boolean) => {
    if (index === 4 && (!active || managerRole !== MANAGER_ROLE.ADMIN))
      return 'sh_red';
    if (index === 4 && active) return 'sp_secondary_01';
    if (index > 4 && active) return 'sp_primary_100_main';
    if (index > 4 && !active) return 'b_333';
    return 'sp_primary_100_main';
  };

  const renderBgColor = (active: boolean, index: number) => {
    if (index === 4 && active) {
      return 'sp_secondary_01';
    } else {
      return active ? 'sp_primary_100_main' : 'main_white';
    }
  };

  const pointerEvents = (index: number) => {
    if (!editable) return 'none';
    if (
      index >= 5 ||
      ((index === 3 || index === 4) && managerRole !== MANAGER_ROLE.ADMIN)
    ) {
      return 'none';
    }
    if (index === indexActivated + 1) {
      return 'auto';
    }
    if (
      index === 3 &&
      indexActivated === 3 &&
      managerRole === MANAGER_ROLE.ADMIN
    ) {
      return 'auto';
    }
    return 'none';
  };

  const renderIcon = (index: number) => {
    const paddingTop = '4px';
    const stroke = '#333333';
    const strokeWidth = '1';

    if (index === mainStatuses.length - 1) return;
    if (index === 4) {
      return (
        <>
          <Icons.ArrowForward
            sx={{
              pt: `${paddingTop}`,
              ml: -2,
            }}
            stroke={stroke}
            strokeWidth={strokeWidth}
          />
          <Icons.ArrowForward
            sx={{
              pt: `${paddingTop}`,
              ml: -5,
            }}
            stroke={stroke}
            strokeWidth={strokeWidth}
          />
          <Icons.ArrowForward
            sx={{
              pt: `${paddingTop}`,
              ml: -5,
            }}
            stroke={stroke}
            strokeWidth={strokeWidth}
          />
        </>
      );
    }
    if (index > 0) {
      return (
        <Icons.ArrowForward
          sx={{
            pt: `${paddingTop}`,
            ml: -2,
          }}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      );
    }

    return (
      <Icons.ArrowForward
        stroke={stroke}
        strokeWidth={strokeWidth}
        sx={{
          pt: `${paddingTop}`,
          ml: 1,
        }}
      />
    );
  };

  return (
    <>
      <Stack
        id="steps"
        direction="row"
        spacing={1}
        sx={{
          flex: 1,
          overflowX: { mobile: 'scroll', desktop: 'hidden' },
          overflowY: 'hidden',
          ...hiddenScrollBar,
        }}
      >
        {mainStatuses.map((status, index) => (
          <Stack
            key={index}
            direction="row"
            sx={{
              flex: 1,
              display: 'content',
            }}
          >
            <Button
              disabled={isDisabledButton(index)}
              disableRipple
              disableFocusRipple
              sx={{
                color: `${renderColorButton(index, status.active)}`,
                bgcolor: `${renderBgColor(status.active, index)}`,
                border: '1px solid',
                borderColor: `${renderBolderColor(index, status.active)}`,
                pointerEvents: `${pointerEvents(index)}`,
                left: `${index > 0 ? '-14px' : '0'}`,
                right: '45px',
                height: '28px',
                whiteSpace: 'nowrap',
                '&.Mui-disabled': {
                  bgcolor: 'gray',
                  color: 'main_white',
                  border: 'none',
                },
                '&:hover': {
                  background: 'none',
                },
              }}
              onClick={() => onClick(index, status.value.toString() ?? '')}
            >
              {status.key}
            </Button>
            {renderIcon(index)}
          </Stack>
        ))}
        {indexActivated === -1 && (
          <ModalConfirm
            open={open}
            confirmText={`本申し込みのステータスを「書類確認」に更新しますが、よろしいでしょうか。\n行った変更が保存されなくなります。`}
            onClose={() => setOpen(false)}
            onConfirm={updateStatus}
          />
        )}
        {
          indexActivated > -1 && indexActivated !== 2 && (
            <ModalConfirm
              open={open}
              confirmText={
                indexActivated === +statusUpdate
                  ? '承認を解除しますか。\n行った変更が保存されなくなります。'
                  : `本申し込みのステータスは「${
                      mainStatuses[indexActivated]?.key
                    }」を「${
                      mainStatuses[indexActivated + 1] &&
                      mainStatuses[indexActivated + 1]?.key
                    }」に更新しますが、よろしいでしょうか。\n行った変更が保存されなくなります。`
              }
              onClose={() => setOpen(false)}
              onConfirm={updateStatus}
            />
          )
          // )}
        }
        {indexActivated === 2 && (
          <ModalConfirm
            open={open}
            confirmText={
              isEdited || isChangeFiles
                ? `本申し込みのステータスは「${
                    mainStatuses[indexActivated].key
                  }」を「${
                    mainStatuses[indexActivated + 1] &&
                    mainStatuses[indexActivated + 1].key
                  }」に更新します。修正データは自動的に保存されますが、よろしいでしょうか。\n行った変更が保存されなくなります。`
                : `本申し込みのステータスは「${
                    mainStatuses[indexActivated].key
                  }」を「${
                    mainStatuses[indexActivated + 1] &&
                    mainStatuses[indexActivated + 1].key
                  }」に更新しますが、よろしいでしょうか。\n行った変更が保存されなくなります。`
            }
            onClose={() => setOpen(false)}
            onConfirm={updateStatus}
          />
        )}
        <ConfirmValidateFormModal
          open={openValidate}
          onClose={() => setOpenValidate(false)}
        />
      </Stack>
    </>
  );
};
