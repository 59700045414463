import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';

export type NumpadContextType = {
  numPadOpen: boolean;
  setNumPadOpen: Dispatch<SetStateAction<boolean>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

type NumpadProviderProps = PropsWithChildren<{}>;

export const NumpadContext = createContext<NumpadContextType>({
  numPadOpen: false,
  setNumPadOpen() {},
  name: '',
  setName() {},
  value: '',
  setValue() {},
});

export const NumpadProvider: FC<NumpadProviderProps> = ({ children }) => {
  const [numPadOpen, setNumPadOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [value, setValue] = useState<string>('');

  return (
    <NumpadContext.Provider
      value={{
        numPadOpen,
        setNumPadOpen,
        name,
        setName,
        value,
        setValue,
      }}
    >
      {children}
    </NumpadContext.Provider>
  );
};
