import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getReferralAgencyOptionError,
  getReferralAgencyOptionRequest,
  getReferralAgencyOptionResponse,
} from 'services';
import { ThunkAPI } from 'types';
import { getReferralAgencyOptions } from 'services';

export const getReferralAgencyOptionsAction = createAsyncThunk<
  getReferralAgencyOptionResponse,
  getReferralAgencyOptionRequest,
  ThunkAPI<getReferralAgencyOptionError>
>(
  'spStepEight/getReferralAgencyOptionsAction',
  async (data, { rejectWithValue }) => {
    try {
      return await getReferralAgencyOptions(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
