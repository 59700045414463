import { FC, useState } from 'react';
import { SPStepLayout } from 'containers';
import { Checkbox, Stack, Typography } from '@mui/material';
import { regex } from 'libs';
import SPUpdatePasswordTemplate from 'components/template/SPUpdatePasswordTemplate';
import { FormikProvider, useFormik } from 'formik';
import { Icons, SPButton, SPModal } from 'components';
import { routeNames } from 'navigations/routes';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { supportDvh } from 'utils';
import { validationSchema } from './validationSchema';
import { changePasswordAction } from './thunk';

const SPUpdatePasswordPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>(``);
  const [openModal, setOpenModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    async onSubmit(values, { resetForm }) {
      const result = await dispatch(
        changePasswordAction({
          user: {
            current_password: values.current_password,
            password: values.password,
            password_confirmation: values.password_confirmation,
          },
        })
      );
      if (changePasswordAction.fulfilled.match(result)) {
        setOpenModal(true);
      }
      if (changePasswordAction.rejected.match(result)) {
        setErrorMessage(result.payload as string);
      }
    },
  });

  // const onCloseModal = () => setOpenModal(false);
  const onModalButtonClick = () => {
    formik.resetForm();
    navigate(routeNames.Login.path);
  };

  return (
    <SPStepLayout
      hasStepBar={false}
      hasFooter={false}
      footerLogo
      hasModalSaveDraft={false}
      sx={{ minHeight: supportDvh('calc(100dvh - 158px)') }}
    >
      <FormikProvider value={formik}>
        <SPUpdatePasswordTemplate
          title="パスワード変更"
          formOption={[
            {
              formTitle: '現在のパスワード',
              formName: 'current_password',
              hasPassword: true,
              autoTrim: false,
              showError: true,
            },
            {
              formTitle: '新しいパスワード',
              formName: 'password',
              formChildren: (
                <Stack spacing={3} mt={1}>
                  <Typography variant="SP_password_note" color="b_333">
                    パスワードの条件
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox
                      checked={
                        formik.values.password.length >= 8 &&
                        formik.values.password.length <= 20
                      }
                      sx={{
                        m: 0,
                        width: 12,
                        height: 12,
                        color: 'sp_primary_40',
                        '&.Mui-checked': {
                          color: 'sp_secondary_01',
                        },
                      }}
                    />

                    <Typography
                      variant="table_header_text"
                      color={
                        (formik.values.password.length > 0 &&
                          formik.values.password.length < 8) ||
                        formik.values.password.length > 20
                          ? 'sp_secondary_01'
                          : 'b_333'
                      }
                    >
                      8文字以上20文字以下
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox
                      checked={!!formik.values.password.match(regex.password)}
                      sx={{
                        width: 12,
                        height: 12,
                        color: 'sp_primary_40',
                        '&.Mui-checked': {
                          color: 'sp_secondary_01',
                        },
                      }}
                    />
                    <Typography
                      variant="table_header_text"
                      color={
                        !formik.values.password.match(regex.password) &&
                        formik.values.password
                          ? 'sp_secondary_01'
                          : 'b_333'
                      }
                    >
                      大文字英字・小文字英字・数字の3種混在
                    </Typography>
                  </Stack>
                </Stack>
              ),
              showError: true,
              hasPassword: true,
              autoTrim: false,
            },
            {
              formTitle: '新しいパスワード（確認用）',
              formName: 'password_confirmation',
              hasPassword: true,
              autoTrim: false,
            },
          ]}
          onSubmit={formik.handleSubmit}
          errorMessage={errorMessage}
          disabledButton={
            formik.isSubmitting || !(formik.isValid && formik.dirty)
          }
          buttonTitle="変更する"
          backButtonTitle="キャンセル"
        />

        <SPModal
          open={openModal}
          onClose={onModalButtonClick}
          disableBackDropClick
          icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
          title="パスワード変更完了"
          footer={
            <Stack textAlign="center" spacing={6}>
              <Typography variant="sp_modal_note" color="b_333">
                {`パスワードの変更が完了しました。\n変更後のパスワードで再度ログインして引き続きご利用ください。`}
              </Typography>
              <SPButton
                sx={{
                  width: '100%',
                  bgcolor: 'sp_primary_100_main',
                }}
                endIcon={<Icons.ArrowForward stroke="white" />}
                onClick={onModalButtonClick}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="main_white"
                >
                  ログイン
                </Typography>
              </SPButton>
            </Stack>
          }
        />
      </FormikProvider>
    </SPStepLayout>
  );
};

export default SPUpdatePasswordPage;
