import { AxiosRequestConfig } from 'axios';
import { externalInstance } from 'libs';
import {
  UpdateStatusSBIRequest,
  UpdateStatusSBIResponse,
  updateStatusSBIResponseSchema,
} from './schemas';

export const updateStatusSBI = (
  data: UpdateStatusSBIRequest
): Promise<UpdateStatusSBIResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: `/sbi/application/${data.application_header_id}`,
  };

  return externalInstance(request).then(({ data }) =>
    updateStatusSBIResponseSchema.parse(data)
  );
};
