import { FC, useCallback, useEffect } from 'react';
import {
  Box,
  Stack,
  SxProps,
  tableCellClasses,
  TableContainer,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import { Button, Modal } from 'components';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import { getSavedApplications } from './thunk';
import { applicationsSelectors } from './selectors';

type SavedApplicationListProps = {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
};

export const SavedApplicationModal: FC<SavedApplicationListProps> = (props) => {
  const dispatch = useAppDispatch();
  const applications = useSelector(applicationsSelectors.selectAll);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(getSavedApplications({}));
      if (getSavedApplications.fulfilled.match(result)) {
        // TODO: action for success
        // onClose();
      }
    };

    fetchData().catch();
  }, [dispatch]);

  const onResume = useCallback(
    (key: number | undefined) => {
      //TODO: action resume
      props.onClose();
    },
    [props]
  );

  return (
    <Modal {...props} sx={{ minWidth: 771 }}>
      <Stack sx={{ textAlign: 'center', mb: 11 }}>
        <Typography variant="h2" color="normal_text">
          保存しているお申込み
        </Typography>
      </Stack>
      <Stack sx={{ mb: 9 }}>
        <Typography variant="text_note_modal" color="normal_text">
          こちらのリストに保存されているお申込みを再開することがで
          きます。（仮）
        </Typography>
      </Stack>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="text_label_modal" color="normal_text">
                  保存日時
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="text_label_modal" color="normal_text">
                  お借入れ目的
                </Typography>
              </TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map((savedApplication, index) => (
              <TableRow
                key={index}
                sx={{
                  height: 100,
                  '&:nth-of-type(odd)': {
                    bgcolor: 'bg_off',
                  },
                  '&:nth-of-type(even)': {
                    bgcolor: 'main_white',
                  },
                }}
              >
                <TableCell align="center" sx={{ width: '30%' }}>
                  <Typography variant="h4">
                    {savedApplication?.date_create}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: '35%' }}>
                  <Typography variant="textstyle_L">
                    {savedApplication?.purpose_of_borrowing}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ width: '35%' }}>
                  <Button
                    variant="outlined"
                    sx={{
                      width: 120,
                      height: 50,
                      bgcolor: 'h_blugreen',
                      borderRadius: '4px',
                      border: (theme) =>
                        `3px solid ${theme?.palette?.h_blugreen}`,
                    }}
                    onClick={() => onResume(savedApplication?.id)}
                  >
                    <Typography variant="button_title" color="main_white">
                      再開
                    </Typography>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>

      <Box sx={{ textAlign: 'center', mt: 14, mb: 26 }}>
        <Button
          variant="outlined"
          onClick={props.onClose}
          sx={{
            width: '80%',
            height: 64,
          }}
        >
          確認
        </Button>
      </Box>
    </Modal>
  );
};

const StyledTable = styled(Table)(() => ({
  [`& .${tableCellClasses.root}`]: {
    borderBottom: 'none',
  },
}));
