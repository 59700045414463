import { FC, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { AuthPopover, Icons } from 'components';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { PulldownBtn } from '../PulldownBtn';

export type PulldownNumberProps = {
  value: string | number | boolean;
  selectYear?: boolean;
  onChange: (value: string | number | boolean) => void;
  dynamicOptionHidden?: (index: number) => boolean;
};

export const PulldownNumber: FC<PulldownNumberProps> = ({
  value,
  selectYear,
  onChange,
  dynamicOptionHidden = () => false,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <AuthPopover
      arrow={false}
      sx={{
        width: 146,
        border: (theme) => `1px solid ${theme?.palette?.line}`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        overflow: 'hidden',
      }}
      content={
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Stack sx={{ textAlign: 'left', maxHeight: 300, overflow: 'auto' }}>
            {selectYear ? (
              <>
                {Array.from({ length: 50 }, (_, i) => i).map((item, index) => {
                  if (dynamicOptionHidden(index)) {
                    return null;
                  }
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        px: 1,
                        py: 1,
                        borderBottom:
                          item - 1 !== index ? '1px solid #DBD9D9' : 'none',
                        cursor: 'pointer',
                        backgroundColor: 'main_white',
                      }}
                      onClick={() => {
                        onChange((item + 1).toString());
                        setOpen(false);
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {String(item + 1) === String(value) ? (
                          <Icons.PulldownChecked
                            sx={{ width: 16, height: 16 }}
                          />
                        ) : (
                          <Icons.PulldownCheck sx={{ width: 16, height: 16 }} />
                        )}
                        <Typography
                          variant="text_dashboard"
                          color="#444"
                          sx={{ pl: 2 }}
                        >
                          {item + 1}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </>
            ) : (
              <>
                {Array.from({ length: 12 }, (_, i) => i).map((item, index) => {
                  if (dynamicOptionHidden(index)) {
                    return null;
                  }
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        px: 1,
                        py: 1,
                        borderBottom:
                          item - 1 !== index ? '1px solid #DBD9D9' : 'none',
                        cursor: 'pointer',
                        backgroundColor: 'main_white',
                      }}
                      onClick={() => {
                        onChange(item.toString());
                        setOpen(false);
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {String(item) === String(value) ? (
                          <Icons.PulldownChecked
                            sx={{ width: 16, height: 16 }}
                          />
                        ) : (
                          <Icons.PulldownCheck sx={{ width: 16, height: 16 }} />
                        )}
                        <Typography
                          variant="text_dashboard"
                          color="b_333"
                          sx={{ pl: 2 }}
                        >
                          {item}
                        </Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </>
            )}
          </Stack>
        </ClickAwayListener>
      }
      open={open}
      placement="bottom-start"
      contentProps={{
        height: 'auto',
        padding: 0,
        color: 'required_chip_label',
      }}
    >
      <Stack direction="row" alignItems="center">
        <PulldownBtn
          open={open}
          onClick={() => setOpen((prevState) => !prevState)}
        />
      </Stack>
    </AuthPopover>
  );
};
