import { createSelector } from '@reduxjs/toolkit';
import { LoanType } from 'constant';
import { plannedCohabitantSelectors } from 'pages/SPStartScreen/selectors';
import { SalePersonPreliminaries } from 'services';
import { RootState } from 'types';
import { buildResidentsAttributesSalePerson } from './utils';

export const loadingSelector = (state: RootState) =>
  state.salePersonPreliminaryReducer.fetching;
export const saveButtonSelector = (state: RootState) =>
  state.salePersonPreliminaryReducer.isDisableSaveButton;

export const salePersonPreliminariesSelector = (state: RootState) =>
  state.salePersonPreliminaryReducer.salePersonPreliminaries;

export const dataSalePersonAsyncSelector = (state: RootState) => ({
  timeGetEdit: state.salePersonPreliminaryReducer.timeGetEdit,
  sbiId: state.salePersonPreliminaryReducer.sbiId,
});

export const residentsAttributesOriginalSelector = createSelector(
  salePersonPreliminariesSelector,
  (state) => state.p_application_header.p_residents_attributes
);
export const joinGuarantorsAttributesOriginalSelector = createSelector(
  salePersonPreliminariesSelector,
  (state) => state.p_application_header.p_join_guarantors_attributes
);

export const borrowingsAttributesOriginalSelector = createSelector(
  salePersonPreliminariesSelector,
  (state) => state.p_application_header.p_borrowings_attributes
);

export const peopleAttributesOriginalSelector = createSelector(
  salePersonPreliminariesSelector,
  (state) => state.p_application_header.p_applicant_people_attributes
);

export const salePersonLinkPreliminariesSelector = (state: RootState) =>
  state.salePersonPreliminaryReducer.salePersonLinkPreliminaries;

export const publicStatus = (state: RootState) =>
  state.salePersonPreliminaryReducer.salePersonPreliminaries
    .p_application_header.public_status;

export const salePersonAdminOptionsForSalePersonSelector = (
  state: RootState
) => ({
  agenciesAdminOptions: state.salePersonPreliminaryReducer.agenciesOptions,
  storeAdminOptions: state.salePersonPreliminaryReducer.storesOptions,
  exhibitionsAdminOptions:
    state.salePersonPreliminaryReducer.exhibitionsOptions,
  salePersonAdminOptions: state.salePersonPreliminaryReducer.salePersonsOptions,
});
export const salePersonAdminOptionsForSalePersonLinkSelector = (
  state: RootState
) => ({
  agenciesAdminOptions: state.salePersonPreliminaryReducer.agenciesOptionsLink,
  storeAdminOptions: state.salePersonPreliminaryReducer.storesOptionsLink,
  exhibitionsAdminOptions:
    state.salePersonPreliminaryReducer.exhibitionsOptionsLink,
  salePersonAdminOptions:
    state.salePersonPreliminaryReducer.salePersonsOptionsLink,
});

export const filesSelectors = (state: RootState) => ({
  files: state.salePersonPreliminaryReducer.files,
  filesIncomeAdder: state.salePersonPreliminaryReducer.filesIncomeAdder,
  filesLink: state.salePersonPreliminaryReducer.filesLink,
  filesBorrowing: state.salePersonPreliminaryReducer.filesBorrowing,
  filesHeader: state.salePersonPreliminaryReducer.filesHeader,
  filesLinkHeader: state.salePersonPreliminaryReducer.filesLinkHeader,
  filesLinkBorrowing: state.salePersonPreliminaryReducer.filesLinkBorrowing,
  filesRemove: state.salePersonPreliminaryReducer.filesRemove,
  filesIncomeAdderRemove:
    state.salePersonPreliminaryReducer.filesIncomeAdderRemove,
  filesBorrowingRemove: state.salePersonPreliminaryReducer.filesBorrowingRemove,
  filesHeaderRemove: state.salePersonPreliminaryReducer.filesHeaderRemove,
  isLoadingFiles: state.salePersonPreliminaryReducer.isLoadingFiles,
});

export const rebuildResidentsAttributesSelector = createSelector(
  salePersonPreliminariesSelector,
  plannedCohabitantSelectors,
  ({ p_application_header }, plannedCohabitantDynamic) => {
    return {
      p_residents_attributes: buildResidentsAttributesSalePerson(
        p_application_header,
        plannedCohabitantDynamic
      ),
    };
  }
);

export const borrowingDetailsSalePersonOriginalSelector = createSelector(
  salePersonPreliminariesSelector,
  (state) => state.p_application_header.p_borrowing_details_attributes
);

export const rebuildResidentsAttributesLinkSelector = createSelector(
  salePersonLinkPreliminariesSelector,
  plannedCohabitantSelectors,
  ({ p_application_header }, plannedCohabitantDynamic) => {
    return {
      p_residents_attributes: buildResidentsAttributesSalePerson(
        p_application_header,
        plannedCohabitantDynamic
      ),
    };
  }
);

export const initialValuesSelector = createSelector(
  salePersonPreliminariesSelector,
  rebuildResidentsAttributesSelector,
  (state: SalePersonPreliminaries, { p_residents_attributes }) => ({
    p_application_header: {
      ...state.p_application_header,
      p_residents_attributes,
    },
  })
);

export const initialLinkValuesSelector = createSelector(
  salePersonLinkPreliminariesSelector,
  rebuildResidentsAttributesLinkSelector,
  (state: SalePersonPreliminaries, { p_residents_attributes }) => ({
    p_application_header: {
      ...state.p_application_header,
      p_residents_attributes,
    },
  })
);

export const checkIncomeTotalizer = createSelector(
  salePersonPreliminariesSelector,
  (input) => {
    const selectedIncome = input.p_application_header.loan_type;
    return (
      selectedIncome === LoanType.THREE || selectedIncome === LoanType.FOUR
    );
  }
);

export const checkHasJoinGuarantor = createSelector(
  salePersonPreliminariesSelector,
  (input) => {
    return input.p_application_header.p_applicant_people_attributes[0]
      ?.has_join_guarantor;
  }
);
