import { ReactElement } from 'react';

export type DataValue = {
  value: string;
  formattedValue: string;
  suffix?: string | ReactElement;
  prefix?: string;
  isPrice?: boolean;
  widthComma?: boolean;
};

export function buildDataValue(
  data: Record<string, string> | string,
  options?: Omit<DataValue, 'formattedValue' | 'value'>
): Record<string, DataValue> | DataValue {
  if (typeof data === 'string') {
    return {
      value: data,
      formattedValue: data
        ? `${options?.prefix ?? ''}${data}${options?.suffix ?? ''}`
        : '',
      ...options,
    };
  }

  const object: Record<string, { value: string; formattedValue: string }> = {};

  Object.keys(data).forEach((key) => {
    object[key] = {
      value: data[key],
      formattedValue: data[key]
        ? `${options?.prefix ?? ''}${data[key]}${options?.suffix ?? ''}`
        : '',
      ...options,
    };
  });

  return object;
}
