import {
  BuyingandSellingScheduleType,
  ChildType,
  CurrentHomeLoanType,
  CurrentResidenceType,
  FinanceAgencyType,
  Flat35ApplicablePlanType,
  Flat35ApplicationType,
  LandOwnershipType,
  MaintenanceType,
  NationalityType,
  PersonOccupancyType,
  PlanningAreaType,
  PurchasePurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
  RegionType,
} from 'constant';
import { SPStepFourForm } from 'types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'containers/AuthModal/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { convertImageResponse, zeroPad } from 'utils';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { deleteUser } from '../SPUnsubcribed/thunk';
import { resetLivedLength } from '../SPStepTwo/thunk';

export type ResidentsAttributeType = {
  id?: string;
  _destroy?: boolean;
  last_name_kanji: string;
  first_name_kanji: string;
  last_name_kana: string;
  first_name_kana: string;
  relationship_name: string;
  relationship: string;
  nationality: NationalityType;
  birthday: string;
  loan_from_japan_housing_finance_agency: FinanceAgencyType;
  postal_code: string;
  prefecture_kanji: string;
  city_kanji: string;
  district_kanji: string;
  prefecture_kana: string;
  city_kana: string;
  district_kana: string;
  other_address_kanji: string;
  home_phone_number: string;
};

export type InitialState = {
  form: SPStepFourForm;
  p_residents_attributes: ResidentsAttributeType[];
  same_address_as_applicant: boolean;
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      person_occupancy: PersonOccupancyType.DEFAULT,
      non_resident_reason: '',
      planned_cohabitant: [],
      children_number: ChildType.DEFAULT,
      siblings_number: ChildType.DEFAULT,
      other_people_number: ChildType.DEFAULT,
      other_relationship: '',
      housemate_number: '',
      business_ability: [],
      collateral_prefecture: '',
      collateral_city: '',
      collateral_lot_number: '',
      condominium_name: '',
      collateral_land_area: '',
      collateral_total_floor_area: '',
      occupied_area: '',
      land_ownership: LandOwnershipType.DEFAULT,
      purchase_purpose: PurchasePurposeType.DEFAULT,
      planning_area: PlanningAreaType.DEFAULT,
      other_planning_area: '',
      rebuilding_reason: RebuildingReasonType.DEFAULT,
      other_rebuilding_reason: '',
      flat_35_applicable_plan: Flat35ApplicablePlanType.DEFAULT,
      maintenance_type: MaintenanceType.DEFAULT,
      region_type: RegionType.DEFAULT,
      flat_35_application: Flat35ApplicationType.DEFAULT,
      property_information_file: [],
      property_information_url: '',
      p_applicant_people_attributes: [
        {
          lived_length_year_num: '',
          lived_length_month_num: '',
          current_residence: CurrentResidenceType.DEFAULT,
          reason_acquire_home: ReasonsForNeedingHousingType.DEFAULT,
          other_reason_acquire_home: '',
          buyingand_selling_schedule_type: BuyingandSellingScheduleType.DEFAULT,
          expected_house_selling_price: '',
          current_residence_floor_area: '',
          owner_full_name: '',
          owner_relationship: '',
          other_buyingand_selling_schedule_type: '',
          scheduled_time_sell_house: '',
          current_home_loan: CurrentHomeLoanType.DEFAULT,
        },
      ],
      p_residents_attributes: [],
    },
  },
  p_residents_attributes: [],
  same_address_as_applicant: false,
};

const stepFourSlice = createSlice({
  name: 'stepFour',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<SPStepFourForm>) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    saveResident: (state, action: PayloadAction<ResidentsAttributeType[]>) => {
      state.p_residents_attributes = action.payload;
    },
    saveSameAddress: (state, action: PayloadAction<boolean>) => {
      state.same_address_as_applicant = action.payload;
    },
    resetReasonAcquireHome: (state) => {
      state.form.p_application_header.p_applicant_people_attributes[0].reason_acquire_home =
        ReasonsForNeedingHousingType.DEFAULT;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_application_header, p_applicant_people, p_residents } =
        action.payload.data;

      const {
        person_occupancy,
        non_resident_reason,
        planned_cohabitant,
        children_number,
        siblings_number,
        other_people_number,
        other_relationship,
        housemate_number,
        business_ability,
        collateral_prefecture,
        collateral_city,
        collateral_lot_number,
        condominium_name,
        collateral_land_area,
        collateral_total_floor_area,
        occupied_area,
        land_ownership,
        purchase_purpose,
        planning_area,
        other_planning_area,
        rebuilding_reason,
        other_rebuilding_reason,
        flat_35_applicable_plan,
        property_information_file,
        maintenance_type,
        region_type,
        flat_35_application,
        property_information_url,
      } = p_application_header;

      const stepFourApplicantPeopleFields = p_applicant_people.map((data) => ({
        lived_length_year_num: data.lived_length_year_num,
        lived_length_month_num: data.lived_length_month_num,
        current_residence: data.current_residence,
        reason_acquire_home: data.reason_acquire_home,
        other_reason_acquire_home: data.other_reason_acquire_home,
        buyingand_selling_schedule_type: data.buyingand_selling_schedule_type,
        expected_house_selling_price: data.expected_house_selling_price,
        current_residence_floor_area: data.current_residence_floor_area,
        owner_full_name: data.owner_full_name,
        owner_relationship: data.owner_relationship,
        other_buyingand_selling_schedule_type:
          data.other_buyingand_selling_schedule_type,
        scheduled_time_sell_house: data.scheduled_time_sell_house,
        current_home_loan: data.current_home_loan,
      }));

      const stepFourResidentFields = p_residents.map((data) => ({
        last_name_kanji: data.last_name_kanji,
        first_name_kanji: data.first_name_kanji,
        last_name_kana: data.last_name_kana,
        first_name_kana: data.first_name_kana,
        relationship_name: data.relationship_name,
        relationship: data.relationship,
        nationality: data.nationality,
        birthday: data.birthday,
        loan_from_japan_housing_finance_agency:
          data.loan_from_japan_housing_finance_agency,
        postal_code: data.postal_code,
        prefecture_kanji: data.prefecture_kanji,
        city_kanji: data.city_kanji,
        district_kanji: data.district_kanji,
        prefecture_kana: data.prefecture_kana,
        city_kana: data.city_kana,
        district_kana: data.district_kana,
        other_address_kanji: data.other_address_kanji,
        home_phone_number: data.home_phone_number,
      }));
      state.p_residents_attributes = stepFourResidentFields;
      state.form.p_application_header = {
        ...state.form.p_application_header,
        person_occupancy,
        non_resident_reason,
        ...(!!planned_cohabitant && { planned_cohabitant }),
        children_number,
        siblings_number,
        other_people_number,
        other_relationship,
        housemate_number,
        ...(!!business_ability && { business_ability }),
        collateral_prefecture,
        collateral_city,
        collateral_lot_number,
        condominium_name,
        collateral_land_area,
        collateral_total_floor_area,
        occupied_area,
        land_ownership,
        purchase_purpose,
        planning_area,
        other_planning_area,
        rebuilding_reason,
        other_rebuilding_reason,
        flat_35_applicable_plan,
        ...(!!property_information_file
          ? convertImageResponse(property_information_file)
          : []),
        maintenance_type,
        region_type,
        flat_35_application,
        property_information_url,
        p_applicant_people_attributes: stepFourApplicantPeopleFields,
        p_residents_attributes: stepFourResidentFields,
      };
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const {
        person_occupancy,
        non_resident_reason,
        planned_cohabitant,
        children_number,
        siblings_number,
        other_people_number,
        other_relationship,
        housemate_number,
        business_ability,
        collateral_prefecture,
        collateral_city,
        collateral_lot_number,
        condominium_name,
        collateral_land_area,
        collateral_total_floor_area,
        occupied_area,
        land_ownership,
        purchase_purpose,
        planning_area,
        other_planning_area,
        rebuilding_reason,
        other_rebuilding_reason,
        flat_35_applicable_plan,
        maintenance_type,
        region_type,
        flat_35_application,
        property_information_url,
        p_applicant_people_attributes,
        p_residents_attributes,
        header_file_upload,
      } = action.payload.data;

      const plannedCohabitant = planned_cohabitant.filter(
        (item) => item !== ''
      );

      const stepFourApplicantPeopleFields = p_applicant_people_attributes.map(
        (data) => ({
          id: data.id,
          lived_length_year_num: !!data.lived_length_year_num
            ? zeroPad(+data.lived_length_year_num)
            : data.lived_length_year_num,
          lived_length_month_num: !!data.lived_length_month_num
            ? zeroPad(+data.lived_length_month_num)
            : data.lived_length_month_num,
          current_residence: data.current_residence,
          reason_acquire_home: data.reason_acquire_home,
          other_reason_acquire_home: data.other_reason_acquire_home,
          buyingand_selling_schedule_type: data.buyingand_selling_schedule_type,
          expected_house_selling_price: data.expected_house_selling_price,
          current_residence_floor_area: data.current_residence_floor_area,
          owner_full_name: data.owner_full_name,
          owner_relationship: data.owner_relationship,
          other_buyingand_selling_schedule_type:
            data.other_buyingand_selling_schedule_type,
          scheduled_time_sell_house: data.scheduled_time_sell_house,
          current_home_loan: data.current_home_loan,
        })
      );

      const stepFourResidentFields = p_residents_attributes.map((data) => ({
        id: data.id,
        _destroy: data._destroy,
        last_name_kanji: data.last_name_kanji,
        first_name_kanji: data.first_name_kanji,
        last_name_kana: data.last_name_kana,
        first_name_kana: data.first_name_kana,
        relationship_name: data.relationship_name,
        relationship: data.relationship,
        nationality: data.nationality,
        birthday: data.birthday,
        loan_from_japan_housing_finance_agency:
          data.loan_from_japan_housing_finance_agency,
        postal_code: data.postal_code,
        prefecture_kanji: data.prefecture_kanji,
        city_kanji: data.city_kanji,
        district_kanji: data.district_kanji,
        prefecture_kana: data.prefecture_kana,
        city_kana: data.city_kana,
        district_kana: data.district_kana,
        other_address_kanji: data.other_address_kanji,
        home_phone_number: data.home_phone_number,
      }));
      state.p_residents_attributes = stepFourResidentFields;
      state.form.p_application_header = {
        ...state.form.p_application_header,
        person_occupancy,
        non_resident_reason,
        ...(!!planned_cohabitant && { planned_cohabitant: plannedCohabitant }),
        children_number,
        siblings_number,
        other_people_number,
        other_relationship,
        housemate_number,
        ...(!!business_ability && { business_ability }),
        collateral_prefecture,
        collateral_city,
        collateral_lot_number,
        condominium_name,
        collateral_land_area,
        collateral_total_floor_area,
        occupied_area,
        land_ownership,
        purchase_purpose,
        planning_area,
        other_planning_area,
        rebuilding_reason,
        other_rebuilding_reason,
        flat_35_applicable_plan,
        maintenance_type,
        region_type,
        flat_35_application,
        property_information_file: convertImageResponse(
          header_file_upload.property_information_file
        ),
        property_information_url,
        p_applicant_people_attributes: stepFourApplicantPeopleFields,
        p_residents_attributes:
          p_residents_attributes.length > 0
            ? stepFourResidentFields
            : [
                {
                  last_name_kanji: '',
                  first_name_kanji: '',
                  last_name_kana: '',
                  first_name_kana: '',
                  relationship_name: '',
                  relationship: '',
                  nationality: NationalityType.DEFAULT,
                  birthday: '',
                  loan_from_japan_housing_finance_agency:
                    FinanceAgencyType.DEFAULT,
                  postal_code: '',
                  prefecture_kanji: '',
                  city_kanji: '',
                  district_kanji: '',
                  other_address_kanji: '',
                  home_phone_number: '',
                },
              ],
      };
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const {
        person_occupancy,
        non_resident_reason,
        planned_cohabitant,
        children_number,
        siblings_number,
        other_people_number,
        other_relationship,
        housemate_number,
        business_ability,
        collateral_prefecture,
        collateral_city,
        collateral_lot_number,
        condominium_name,
        collateral_land_area,
        collateral_total_floor_area,
        occupied_area,
        land_ownership,
        purchase_purpose,
        planning_area,
        other_planning_area,
        rebuilding_reason,
        other_rebuilding_reason,
        flat_35_applicable_plan,
        maintenance_type,
        region_type,
        flat_35_application,
        property_information_url,
        p_applicant_people_attributes,
        p_residents_attributes,
        header_file_upload,
      } = action.payload.data;
      const plannedCohabitant = planned_cohabitant.filter(
        (item) => item !== ''
      );

      const stepFourApplicantPeopleFields = p_applicant_people_attributes.map(
        (data) => ({
          id: data.id,
          lived_length_year_num: !!data.lived_length_year_num
            ? zeroPad(+data.lived_length_year_num)
            : data.lived_length_year_num,
          lived_length_month_num: !!data.lived_length_month_num
            ? zeroPad(+data.lived_length_month_num)
            : data.lived_length_month_num,
          current_residence: data.current_residence,
          reason_acquire_home: data.reason_acquire_home,
          other_reason_acquire_home: data.other_reason_acquire_home,
          buyingand_selling_schedule_type: data.buyingand_selling_schedule_type,
          expected_house_selling_price: data.expected_house_selling_price,
          current_residence_floor_area: data.current_residence_floor_area,
          owner_full_name: data.owner_full_name,
          owner_relationship: data.owner_relationship,
          other_buyingand_selling_schedule_type:
            data.other_buyingand_selling_schedule_type,
          scheduled_time_sell_house: data.scheduled_time_sell_house,
          current_home_loan: data.current_home_loan,
        })
      );

      const stepFourResidentFields = p_residents_attributes.map((data) => ({
        id: data.id,
        _destroy: data._destroy,
        last_name_kanji: data.last_name_kanji,
        first_name_kanji: data.first_name_kanji,
        last_name_kana: data.last_name_kana,
        first_name_kana: data.first_name_kana,
        relationship_name: data.relationship_name,
        relationship: data.relationship,
        nationality: data.nationality,
        birthday: data.birthday,
        loan_from_japan_housing_finance_agency:
          data.loan_from_japan_housing_finance_agency,
        postal_code: data.postal_code,
        prefecture_kanji: data.prefecture_kanji,
        city_kanji: data.city_kanji,
        district_kanji: data.district_kanji,
        prefecture_kana: data.prefecture_kana,
        city_kana: data.city_kana,
        district_kana: data.district_kana,
        other_address_kanji: data.other_address_kanji,
        home_phone_number: data.home_phone_number,
      }));
      state.p_residents_attributes = stepFourResidentFields;
      state.form.p_application_header = {
        ...state.form.p_application_header,
        person_occupancy,
        non_resident_reason,
        ...(!!planned_cohabitant && { planned_cohabitant: plannedCohabitant }),
        children_number,
        siblings_number,
        other_people_number,
        other_relationship,
        housemate_number,
        ...(!!business_ability && { business_ability }),
        collateral_prefecture,
        collateral_city,
        collateral_lot_number,
        condominium_name,
        collateral_land_area,
        collateral_total_floor_area,
        occupied_area,
        land_ownership,
        purchase_purpose,
        planning_area,
        other_planning_area,
        rebuilding_reason,
        other_rebuilding_reason,
        flat_35_applicable_plan,
        maintenance_type,
        region_type,
        flat_35_application,
        property_information_file: convertImageResponse(
          header_file_upload.property_information_file
        ),
        property_information_url,
        p_applicant_people_attributes: stepFourApplicantPeopleFields,
        p_residents_attributes:
          p_residents_attributes.length > 0
            ? stepFourResidentFields
            : [
                {
                  last_name_kanji: '',
                  first_name_kanji: '',
                  last_name_kana: '',
                  first_name_kana: '',
                  relationship_name: '',
                  relationship: '',
                  nationality: NationalityType.DEFAULT,
                  birthday: '',
                  loan_from_japan_housing_finance_agency:
                    FinanceAgencyType.DEFAULT,
                  postal_code: '',
                  prefecture_kanji: '',
                  city_kanji: '',
                  district_kanji: '',
                  other_address_kanji: '',
                  home_phone_number: '',
                },
              ],
      };
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(resetLivedLength.fulfilled, (state, action) => {
      if (
        action.payload.reset &&
        state.form.p_application_header.p_applicant_people_attributes?.[0]
      ) {
        state.form.p_application_header.p_applicant_people_attributes[0].lived_length_year_num =
          '';
        state.form.p_application_header.p_applicant_people_attributes[0].lived_length_month_num =
          '';
      }
    });
  },
});

export const {
  saveForm,
  saveResident,
  saveSameAddress,
  resetReasonAcquireHome,
} = stepFourSlice.actions;

export default stepFourSlice.reducer;
