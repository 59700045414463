import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'hooks';
import {
  changeStatusPreliminary,
  getPersonalInformations,
  updateAdminExamination,
  updateApproverConfirmation,
} from 'pages/AdminEditPreliminary/thunk';
import { toast } from 'react-toastify';
import { MANAGER_ROLE, STATUS_LINKED_INTERFACE } from 'constant';
import { useSelector } from 'react-redux';
import { managerRoleSelector } from 'pages/Login/selectors';

export type ProvisionalResults = {
  key: string;
  value: number;
  active: boolean;
};

export type PublicStatus = {
  text: string;
  active: boolean;
};

export type ExaminationResultButtonProps = {
  preliminaryId: string;
  isPdfExisted: boolean;
  filePdf?: File | undefined;
  statusResult: number;
  provisionalResults: ProvisionalResults[];
  isLinked: boolean;
  publicStatuses: PublicStatus[];
};

export const ExaminationResultButtons: FC<ExaminationResultButtonProps> = ({
  preliminaryId,
  isPdfExisted,
  filePdf,
  statusResult,
  provisionalResults,
  isLinked,
  publicStatuses,
}) => {
  const dispatch = useAppDispatch();
  const [provisionalActive, setProvisionalActive] = useState<number>(-1);
  const [isApproved, setIsApproved] = useState<boolean>(false);

  const managerRole = useSelector(managerRoleSelector);

  useEffect(() => {
    if (!isPdfExisted) setProvisionalActive(-1);
    else {
      provisionalResults.forEach((provisional) => {
        if (provisional.active) {
          setProvisionalActive(provisional.value);
          setIsApproved(true);
          return;
        }
      });
    }
  }, [isPdfExisted, provisionalResults]);

  const isNotEnoughCondition = useMemo(() => {
    return !isPdfExisted || statusResult < STATUS_LINKED_INTERFACE;
  }, [isPdfExisted, statusResult]);

  const isDisabledProvisional = useMemo(() => {
    return isNotEnoughCondition || isApproved ? true : false;
  }, [isApproved, isNotEnoughCondition]);

  // buttons: 承認, 条件付き承認, 否決
  const renderBgColorProvisioners = useCallback(
    (index: number) => {
      if (!isPdfExisted || statusResult < STATUS_LINKED_INTERFACE)
        return 'gray_09';
      return index === provisionalActive ? 'sp_secondary_01' : 'main_white';
    },
    [isPdfExisted, provisionalActive, statusResult]
  );

  const renderColorProvisioners = useCallback(
    (index: number) => {
      if (isDisabledProvisional) return 'main_white';
      return index === provisionalActive ? 'main_white' : 'sp_secondary_01';
    },
    [isDisabledProvisional, provisionalActive]
  );

  const isDisabledStatuses = useCallback(
    (index: number) => {
      if (managerRole === MANAGER_ROLE.GENERAL_ACCOUNT) return true;
      if (
        (index === 0 &&
          (provisionalActive === -1 || publicStatuses[index].active)) ||
        (index > 0 &&
          (!publicStatuses[index - 1].active || publicStatuses[index].active))
      )
        return true;
      return false;
    },
    [managerRole, provisionalActive, publicStatuses]
  );
  const renderBgColorStatuses = useCallback(
    (index: number) => {
      if (isDisabledStatuses(index)) return 'gray_09';
      return publicStatuses[index].active ? 'sp_secondary_01' : 'main_white';
    },
    [isDisabledStatuses, publicStatuses]
  );

  const renderColorStatuses = useCallback(
    (index: number) => {
      if (isDisabledStatuses(index) || publicStatuses[index].active)
        return 'main_white';
      return 'sp_secondary_01';
    },
    [isDisabledStatuses, publicStatuses]
  );

  const onClickProvisional = useCallback(
    async (index: number) => {
      setProvisionalActive(index);
      setIsApproved(false);
      if (!isNotEnoughCondition && isPdfExisted) {
        const dataFile = new FormData();
        if (filePdf) dataFile.append('examination_file', filePdf);
        dataFile.append('provisional_result', String(index));
        const resultUpdatePdf = await dispatch(
          updateAdminExamination({
            id: preliminaryId,
            data: dataFile,
          })
        );
        if (updateAdminExamination.fulfilled.match(resultUpdatePdf)) {
          await dispatch(
            getPersonalInformations({
              id: preliminaryId,
            })
          );
        } else {
          toast.error('エラーが発生しました。');
        }
      }
    },
    [dispatch, filePdf, isNotEnoughCondition, isPdfExisted, preliminaryId]
  );

  const onClickStatuses = useCallback(
    async (index: number) => {
      if (index === 0) {
        // click button 承認者確認
        const resultUpdateApproverConfirmation = await dispatch(
          updateApproverConfirmation({
            id: preliminaryId ? preliminaryId : '',
            approver_confirmation: 1,
          })
        );
        if (
          updateApproverConfirmation.fulfilled.match(
            resultUpdateApproverConfirmation
          )
        ) {
          await dispatch(
            getPersonalInformations({
              id: preliminaryId,
            })
          );
        }
      } else {
        // click buttons 提携会社へ公開, 申込人へ公開, update status
        const resultUpdateStatus = await dispatch(
          changeStatusPreliminary({
            id: preliminaryId ? preliminaryId : '',
            status_result: index === 1 ? '5' : '6',
          })
        );
        if (changeStatusPreliminary.fulfilled.match(resultUpdateStatus)) {
          await dispatch(
            getPersonalInformations({
              id: preliminaryId,
            })
          );
        }
      }
    },
    [dispatch, preliminaryId]
  );

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      sx={{ mt: 5 }}
    >
      <Stack direction="row" alignItems="center">
        <Typography
          variant="drawerButtonText"
          sx={{ mr: '10px', color: 'b_333' }}
        >
          銀行仮審査結果
        </Typography>
        {provisionalResults.map((item, index) => (
          <Stack direction="row" alignItems="center" key={index}>
            <Button
              disabled={isDisabledProvisional || isLinked}
              sx={{
                ml: 5,
                bgcolor: () => renderBgColorProvisioners(index),
                px: 14,
                py: '9px',
                boxShadow: 'none',
                border: (theme) =>
                  isNotEnoughCondition
                    ? 'none'
                    : `1px solid ${theme?.palette?.sp_secondary_01}`,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'sp_secondary_01',
                  color: 'main_white',
                  '& .MuiTypography-root': {
                    color: 'main_white',
                  },
                },
                '&:disabled': {
                  color: 'main_white',
                  border: 'none',
                  bgcolor: `${
                    index === provisionalActive ? 'sp_secondary_01' : 'gray_09'
                  }`,
                },
              }}
              onClick={() => {
                onClickProvisional(index);
              }}
            >
              <Typography
                variant="pdf_title"
                color={renderColorProvisioners(index)}
              >
                {item.key}
              </Typography>
            </Button>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" alignItems="center">
        {publicStatuses.map((item, index) => (
          <Stack direction="row" alignItems="center" key={index}>
            <Button
              disabled={isDisabledStatuses(index) || isLinked}
              sx={{
                bgcolor: () => renderBgColorStatuses(index),
                cursor: `renderCursorStatuses(index)`,
                px: 14,
                py: '9px',
                boxShadow: 'none',
                border: (theme) =>
                  isDisabledStatuses(index)
                    ? 'none'
                    : `1px solid ${theme?.palette?.sp_secondary_01}`,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'sp_secondary_01',
                  color: 'main_white',
                  '& .MuiTypography-root': {
                    color: 'main_white',
                  },
                },
                '&:disabled': {
                  bgcolor: item.active ? 'sp_secondary_01' : 'gray_09',
                },
              }}
              onClick={() => onClickStatuses(index)}
            >
              <Typography
                variant="pdf_title"
                color={renderColorStatuses(index)}
              >
                {item.text}
              </Typography>
            </Button>
            {index !== provisionalResults.length - 1 && <Icons.ArrowRight />}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
