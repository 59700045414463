import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPCloudSave: FC<SvgIconProps> = (props) => {
  const id = useMemo<string>(
    () => Math.round(Math.random() * 10000000).toString(),
    []
  );
  return (
    <SvgIcon viewBox="0 0 35 25" {...props}>
      <svg
        width="35"
        height="25"
        viewBox="0 0 35 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1883 8.93828C17.3633 8.76875 17.6367 8.76875 17.8117 8.93828L22.6242 13.7508C22.7938 13.9258 22.7938 14.1992 22.6242 14.3742C22.4492 14.5438 22.1758 14.5438 22.0008 14.3742L17.9375 10.3055V21.0625C17.9375 21.3031 17.7406 21.5 17.5 21.5C17.2594 21.5 17.0625 21.3031 17.0625 21.0625V10.3055L12.9992 14.3742C12.8242 14.5438 12.5508 14.5438 12.3758 14.3742C12.2063 14.1992 12.2063 13.9258 12.3758 13.7508L17.1883 8.93828ZM14 0.5C16.9312 0.5 19.4742 1.93883 21.1094 4.1482C21.2789 4.38281 21.4375 4.62891 21.5852 4.88594C21.7766 4.75469 21.9789 4.63984 22.1867 4.53594C22.8867 4.1925 23.6688 4 24.5 4C27.3984 4 29.75 6.30234 29.75 9.25C29.75 9.61094 29.7117 9.91172 29.6461 10.3C29.5859 10.5898 29.5039 10.8688 29.4 11.1422C29.6898 11.1969 29.9742 11.2734 30.2477 11.3664C33.0094 12.307 35 14.9211 35 18C35 21.8664 31.8664 25 28 25H7.875C3.5257 25 0 21.4727 0 17.125C0 13.6906 2.1968 10.7703 5.26094 9.69297C5.25383 9.54531 5.25 9.39766 5.25 9.25C5.25 4.41563 9.16562 0.5 14 0.5ZM28.875 9.25C28.875 6.83281 26.9172 4.875 24.5 4.875C23.5977 4.875 22.7664 5.09922 22.0719 5.61328C21.8695 5.74453 21.6234 5.78828 21.3883 5.73359C21.1477 5.67891 20.9453 5.48203 20.825 5.32344C19.4633 2.96039 16.9148 1.375 14 1.375C9.65234 1.375 6.125 4.90234 6.125 9.25C6.125 9.38672 6.13047 9.51797 6.13594 9.64922C6.15234 10.0375 5.91719 10.393 5.55078 10.5188C2.82625 11.4758 0.875 14.0734 0.875 17.125C0.875 20.9914 4.00914 24.125 7.875 24.125H28C31.3852 24.125 34.125 21.3852 34.125 18C34.125 15.0359 32.0195 12.5641 29.225 11.9953C28.9734 11.9461 28.7547 11.7875 28.6344 11.5578C28.5086 11.3336 28.443 11.0656 28.5852 10.825C28.7711 10.3383 28.875 9.80781 28.875 9.25Z"
          fill={`url(#${id})`}
        />
        <defs>
          <linearGradient
            id={id}
            x1="5.38973"
            y1="19.9613"
            x2="22.9195"
            y2="-5.33395"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
