import { Stack, Typography } from '@mui/material';
import { Icons, SPButton } from 'components/atoms';
import { routeNames } from 'navigations/routes';
import { FC, createRef, useImperativeHandle, useState } from 'react';
import { SPModal } from '../SPModal';

type SPModalKeyNotFoundRef = {
  open: () => void;
  close: () => void;
};

export const spModalKeyNotFoundRef = createRef<SPModalKeyNotFoundRef>();

export const SPModalKeyNotFound: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(spModalKeyNotFoundRef, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    },
  }));

  return (
    <SPModal
      open={open}
      onClose={() => {}}
      disableBackDropClick
      title="通知"
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      footer={
        <Stack alignItems="center">
          <SPButton
            sx={{
              height: 40,
              width: 160,
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={() => {
              setOpen(false);
              window.open(routeNames.Top.path, '_self');
            }}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    >
      <Typography
        variant="liststyle"
        color="b_333"
        sx={{
          textAlign: 'center',
          whiteSpace: 'break-spaces',
          letterSpacing: '0.6px',
          lineHeight: '27.2px',
        }}
      >
        {`データ更新がありました。\n最新データを確認した上で修正してください。`}
      </Typography>
    </SPModal>
  );
};
