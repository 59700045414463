import { z } from 'zod';

export const createInquiryResponseSchema = z.object({
  success: z.boolean(),
});

export const createInquiryRequestSchema = z.object({
  applicant_id: z.string(),
  applicant_name_kanji: z.string(),
  inquiry: z.object({
    inquiry_category: z.string(),
    content: z.string(),
  }),
});

export type CreateInquiryRequest = z.infer<typeof createInquiryRequestSchema>;
export type CreateInquiryResponse = z.infer<typeof createInquiryResponseSchema>;
export type CreateInquiryError = {};
