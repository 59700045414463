import { FC, PropsWithChildren } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';
import { landAdvancePlan } from 'assets';
import { theme } from 'styles';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPAboutLandAdvancePlanModal: FC<ModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="土地先行プランとは"
      disableBackDropClick
      sx={{ px: 0, ...theme.typography.SP_title_modal }}
      footer={
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: 359 }}
        >
          <Stack sx={{ overflowY: 'scroll', maxHeight: '53vh' }} spacing={2}>
            <Stack sx={{ mb: 6, mx: 6 }} spacing={6}>
              <Typography variant="sp_change_password_note" color="b_333">
                {`注文住宅を建てるお客さまが、「土地を購入するとき」「建物が完成したとき」の2回に分けてご融資を実行できる住宅ローンです。`}
              </Typography>

              <Stack alignItems="center">
                <Avatar
                  variant="square"
                  src={landAdvancePlan}
                  sx={{
                    width: 312,
                    height: 205,
                    '.MuiAvatar-img': {
                      objectFit: 'contain',
                    },
                  }}
                />
              </Stack>
            </Stack>

            {landAdvancePlans.map((item, index) => (
              <Stack key={index} spacing={3} sx={{ mb: 6 }}>
                <Stack sx={{ bgcolor: 'sp_primary_40', py: 1, pl: 4 }}>
                  <Typography variant="SP_form_item_label" color="b_333">
                    {item.title}
                  </Typography>
                </Stack>
                <Stack sx={{ px: 6, pb: 6 }}>
                  <Typography variant="sp_change_password_note" color="b_333">
                    {item.content}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};

const landAdvancePlans = [
  {
    title: '特徴1：魅力的な金利をご提供！',
    content:
      '土地の購入代金も、ネット銀行ならではの魅力的な金利でお借入れいただけます。',
  },
  {
    title: '特徴2：正式審査は1回だけ！',
    content:
      'ご融資は土地購入時、建物完成時の2回ですが、正式審査は土地購入時の1回だけ。',
  },
  {
    title: '特徴3：初期の費用負担を軽減！',
    content:
      '1回目のご融資開始から2回目のご融資開始までは、お利息のみ毎月返済となります。',
  },
];
