import { FC, PropsWithChildren, useState } from 'react';
import {
  Modal,
  Stack,
  SxProps,
  Theme,
  Typography,
  GlobalStyles,
} from '@mui/material';
import { Icons } from 'components';
import 'react-html5-camera-photo/build/css/index.css';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { ACCEPT_TYPE, MAX_FILES, MAX_SIZE_FILE } from 'constant';
import WebCamera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { convertToMB } from 'utils';
import { Button } from '../../atoms';

export type SPUploadImageModalProps = Pick<
  DropzoneOptions,
  'accept' | 'disabled' | 'multiple' | 'maxSize' | 'maxFiles'
> &
  PropsWithChildren<{
    open: boolean;
    onClose: () => void;
    disabled?: boolean;
    onChange: (acceptedFiles: File[]) => void;
    sx?: SxProps<Theme>;
    onTakePhoto: (dataUri: string) => void;
  }>;

export const SPUploadImageModal: FC<SPUploadImageModalProps> = ({
  onChange,
  accept = ACCEPT_TYPE,
  maxSize = MAX_SIZE_FILE,
  maxFiles = MAX_FILES,
  sx,
  onTakePhoto,
  ...props
}) => {
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const { getRootProps, getInputProps } = useDropzone({
    ...props,
    accept,
    maxSize,
    onDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
      if (fileRejections.length > 0) {
        toast.error(
          `許容容量 (${convertToMB(MAX_SIZE_FILE)}MB) を超えています`
        );
      }
      onChange(acceptedFiles);
    },
  });

  return (
    <Modal
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
    >
      {showCamera ? (
        <>
          <WebCamera
            onTakePhoto={(dataUri: string) => {
              onTakePhoto(dataUri);
              setShowCamera(false);
            }}
            onCameraError={() => setShowCamera(false)}
            isFullscreen
            isMaxResolution
            imageType={IMAGE_TYPES.JPG}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
          />
          <Stack
            sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}
            onClick={() => setShowCamera(false)}
          >
            <Icons.Close sx={{ color: 'main_white' }} />
          </Stack>
          <GlobalStyles
            styles={{
              '.react-html5-camera-photo-fullscreen>video': {
                objectFit: 'cover',
              },
              '#container-circles': {
                bottom: '15%',
              },
            }}
          />
        </>
      ) : (
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'main_white',
            px: 8,
            py: 8,
          }}
          spacing={8}
        >
          <Stack>
            <Button
              sx={{
                backgroundColor: 'sp_primary_100_main',
                boxSizing: 'border-box',
                border: (theme) =>
                  `1px solid ${theme?.palette?.sp_primary_100_main}`,
                borderRadius: '14px',
                boxShadow: '0px 2px 15px rgba(60, 72, 196, 0.1)',
                width: 195,
                minHeight: 48,
                height: 48,
              }}
              onClick={() => setShowCamera(!showCamera)}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ p: '8px 73px 8px 16px' }}
                spacing={3}
              >
                <Icons.Camera />
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="main_white"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  撮影する
                </Typography>
              </Stack>
            </Button>
          </Stack>
          <Stack {...getRootProps()}>
            <input {...getInputProps()} type="file" />
            {props.children ? (
              props.children
            ) : (
              <Button
                sx={{
                  backgroundColor: 'sp_primary_100_main',
                  boxSizing: 'border-box',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                  borderRadius: '14px',
                  boxShadow: '0px 2px 15px rgba(60, 72, 196, 0.1)',
                  width: 195,
                  minHeight: 48,
                  height: 48,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                >
                  <Icons.Folder />
                  <Typography
                    variant="SP_multiple_checkbox_label"
                    color="main_white"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    ファイルを選択
                  </Typography>
                </Stack>
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    </Modal>
  );
};
