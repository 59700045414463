import { z } from 'zod';

export const optionSchema = z.object({
  option_name: z.string(),
  option_code: z.number(),
  sort_order: z.number(),
});

export const SPOptionSchema = z.object({
  option_name: z.string(),
  option_code: z.string(),
  sort_order: z.number(),
});

export const SPDynamicOptionSchema = z.object({
  field_name_ja: z.string(),
  value: z.array(SPOptionSchema),
});

export const dynamicOptionSchema = z.object({
  field_name_ja: z.string(),
  value: z.array(optionSchema),
});

export const dynamicOptionsSchema = z.record(dynamicOptionSchema);

export const dynamicOptionsResponseSchema = z.object({
  success: z.boolean(),
  data: dynamicOptionsSchema,
});

export type Option = z.infer<typeof optionSchema>;
export type DynamicOption = z.infer<typeof dynamicOptionSchema>;
export type SPDynamicOption = z.infer<typeof SPDynamicOptionSchema>;
export type DynamicOptions = z.infer<typeof dynamicOptionsSchema>;
export type GetDynamicOptionsResponse = z.infer<
  typeof dynamicOptionsResponseSchema
>;
export type GetDynamicOptionsError = {};
