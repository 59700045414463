import { Box, Stack, Typography } from '@mui/material';
import { Button, ButtonProps, Icons } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { isReactNode } from 'utils';

export type FooterProps = PropsWithChildren<{
  left?: ReactNode | ButtonProps | false;
  right?: ReactNode | ButtonProps | false;
  bgcolor?: string;
}>;

export const Footer: FC<FooterProps> = ({ left, right, bgcolor }) => (
  <Box zIndex={2}>
    <Stack
      direction="row"
      sx={{
        backgroundColor: bgcolor ?? 'main_white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: '1px solid',
        borderColor: 'bg_gray',
      }}
    >
      <Stack flex={1}>
        {isReactNode(left) && left}
        {left !== false && !isReactNode(left) && (
          <Icons.LogoHeader
            sx={{
              width: 80,
              height: 12,
              color: 'h_blugreen',
              m: '9px 0 8px 18px',
            }}
          />
        )}
      </Stack>

      <Stack flex={1} alignItems="flex-end">
        {isReactNode(right) && right}
        {right !== false && !isReactNode(right) && (
          <Button
            {...right}
            sx={{
              m: '5px 16px 11px 0',
              py: 2,
              px: 7,
              minHeight: 44,
              height: 44,
              bgcolor: 'blue08',
              ...right?.sx,
              '&.MuiButton-root .MuiTypography-root': {
                color: 'main_white',
              },
            }}
          >
            <Typography variant="button_title">{right.children}</Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  </Box>
);
