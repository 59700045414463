import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPPdf: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 25" {...props}>
      <svg
        width="18"
        height="25"
        viewBox="0 0 18 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 0.5C1.34531 0.5 0 1.84531 0 3.5V21.5C0 23.1547 1.34531 24.5 3 24.5H15C16.6547 24.5 18 23.1547 18 21.5V8H12C11.1703 8 10.5 7.32969 10.5 6.5V0.5H3ZM12 0.5V6.5H18L12 0.5ZM3 11H4.125C5.57344 11 6.75 12.1766 6.75 13.625C6.75 15.0734 5.57344 16.25 4.125 16.25H3.75V17.75C3.75 18.1625 3.4125 18.5 3 18.5C2.5875 18.5 2.25 18.1625 2.25 17.75V15.5V11.75C2.25 11.3375 2.5875 11 3 11ZM4.125 14.75C4.74844 14.75 5.25 14.2484 5.25 13.625C5.25 13.0016 4.74844 12.5 4.125 12.5H3.75V14.75H4.125ZM7.5 11.75C7.5 11.3375 7.8375 11 8.25 11H9.375C10.6172 11 11.625 12.0078 11.625 13.25V16.25C11.625 17.4922 10.6172 18.5 9.375 18.5H8.25C7.8375 18.5 7.5 18.1625 7.5 17.75V11.75ZM9 17H9.375C9.7875 17 10.125 16.6625 10.125 16.25V13.25C10.125 12.8375 9.7875 12.5 9.375 12.5H9V17ZM13.5 11H15.75C16.1625 11 16.5 11.3375 16.5 11.75C16.5 12.1625 16.1625 12.5 15.75 12.5H14.25V14H15.75C16.1625 14 16.5 14.3375 16.5 14.75C16.5 15.1625 16.1625 15.5 15.75 15.5H14.25V17.75C14.25 18.1625 13.9125 18.5 13.5 18.5C13.0875 18.5 12.75 18.1625 12.75 17.75V14.75V11.75C12.75 11.3375 13.0875 11 13.5 11Z"
          fill="url(#paint0_linear_8106_26172)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8106_26172"
            x1="2.77186"
            y1="19.5642"
            x2="20.4267"
            y2="6.18938"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
