/* eslint-disable no-unused-vars */
// noinspection JSUnusedGlobalSymbols

import { ProvisionalResults } from 'components';
import { Step } from 'components/atoms';
import dayjs from 'dayjs';
import { Accept } from 'react-dropzone';
import { AlphabetLine } from 'types';

const steps: Step[] = [
  {
    label: '確認項目',
  },
  {
    label: 'お申込項目',
  },
  {
    label: '内容ご確認',
  },
  {
    label: '資料アップロード',
  },
  {
    label: '受付完了',
  },
];

const alphabetList: AlphabetLine[] = [
  {
    value: 'あ行',
    label: 'あ行',
  },
  {
    value: 'か行',
    label: 'か行',
  },
  {
    value: 'さ行',
    label: 'さ行',
  },
  {
    value: 'た行',
    label: 'た行',
  },
  {
    value: 'な行',
    label: 'な行',
  },
  {
    value: 'は行',
    label: 'は行',
  },
  {
    value: 'ま行',
    label: 'ま行',
  },
];

export { alphabetList, steps };

export const PREFECTURES = [
  { value: '北海道', label: '北海道' },
  { value: '青森県', label: '青森県' },
  { value: '岩手県', label: '岩手県' },
  { value: '宮城県', label: '宮城県' },
  { value: '秋田県', label: '秋田県' },
  { value: '山形県', label: '山形県' },
  { value: '福島県', label: '福島県' },
  { value: '茨城県', label: '茨城県' },
  { value: '栃木県', label: '栃木県' },
  { value: '群馬県', label: '群馬県' },
  { value: '埼玉県', label: '埼玉県' },
  { value: '千葉県', label: '千葉県' },
  { value: '東京都', label: '東京都' },
  { value: '神奈川県', label: '神奈川県' },
  { value: '新潟県', label: '新潟県' },
  { value: '富山県', label: '富山県' },
  { value: '石川県', label: '石川県' },
  { value: '福井県', label: '福井県' },
  { value: '山梨県', label: '山梨県' },
  { value: '長野県', label: '長野県' },
  { value: '岐阜県', label: '岐阜県' },
  { value: '静岡県', label: '静岡県' },
  { value: '愛知県', label: '愛知県' },
  { value: '三重県', label: '三重県' },
  { value: '滋賀県', label: '滋賀県' },
  { value: '京都府', label: '京都府' },
  { value: '大阪府', label: '大阪府' },
  { value: '兵庫県', label: '兵庫県' },
  { value: '奈良県', label: '奈良県' },
  { value: '和歌山県', label: '和歌山県' },
  { value: '鳥取県', label: '鳥取県' },
  { value: '島根県', label: '島根県' },
  { value: '岡山県', label: '岡山県' },
  { value: '広島県', label: '広島県' },
  { value: '山口県', label: '山口県' },
  { value: '徳島県', label: '徳島県' },
  { value: '香川県', label: '香川県' },
  { value: '愛媛県', label: '愛媛県' },
  { value: '高知県', label: '高知県' },
  { value: '福岡県', label: '福岡県' },
  { value: '佐賀県', label: '佐賀県' },
  { value: '長崎県', label: '長崎県' },
  { value: '熊本県', label: '熊本県' },
  { value: '大分県', label: '大分県' },
  { value: '宮崎県', label: '宮崎県' },
  { value: '鹿児島県', label: '鹿児島県' },
  { value: '沖縄県', label: '沖縄県' },
];

//BEGIN STEP 1 CONST
export enum LoanType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  THREE_CONFIRM = '5',
  FOUR = '4',
}
export enum PurposeType {
  DEFAULT = '',
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
}

export enum RepaymentType {
  DEFAULT = '',
  EQUAL_PRINCIPAL_AND_INTEREST = '1',
  EQUAL_PRINCIPAL = '2',
}

export enum RepaymentBorrowingType {
  DEFAULT = '',
  OTHER_THAN_INSTALLMENT_REPAYMENT = '1',
  INSTALLMENT_REPAYMENT = '2',
}

export enum ConsentType {
  DEFAULT = '',
  DISAGREE = 'disagree',
  AGREE = 'agree',
}

export enum LandAdvanceType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}

export enum LoanTargetZeroType {
  DEFAULT = '',
  ZERO = '0',
  SEVEN = '7',
  EIGHT = '8',
}

export enum PersonOccupancyType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}

export enum CurrentHomeLoanType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}

export enum BuyingandSellingScheduleType {
  DEFAULT = '',
  SALE = '1',
  RENT = '2',
  OTHERS = '99',
}

export enum IdentityVerificationType {
  DEFAULT = '',
  DRIVER_LICENSE = '0',
  MY_NUMBER_CARD = '1',
  BASIC_RESIDENT_REGISTER_CARD = '2',
}

export enum BusinessAbilityType {
  DEFAULT = '',
  RENTAL = '0',
  OFFICE_SHOP = '1',
  SELLING_SOLAR_POWER = '2',
}

export enum DesiredMonthlyBonusType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
}

export const repayment: Record<RepaymentType, string> = {
  [RepaymentType.DEFAULT]: '',
  [RepaymentType.EQUAL_PRINCIPAL_AND_INTEREST]: '元利均等返済',
  [RepaymentType.EQUAL_PRINCIPAL]: '元金均等返済',
};

export const consent: Record<ConsentType, string> = {
  [ConsentType.DEFAULT]: '',
  [ConsentType.DISAGREE]: '同意しない',
  [ConsentType.AGREE]: '同意する',
};

export const landAdvance: Record<LandAdvanceType, string> = {
  [LandAdvanceType.DEFAULT]: '',
  [LandAdvanceType.YES]: '希望する',
  [LandAdvanceType.NO]: '希望しない',
};

export const loanTargetZero: Record<LoanTargetZeroType, string> = {
  [LoanTargetZeroType.DEFAULT]: '',
  [LoanTargetZeroType.ZERO]: '物件の購入・建築',
  [LoanTargetZeroType.SEVEN]: 'お借り換え',
  [LoanTargetZeroType.EIGHT]: '増改築（借り換え有）',
};

export enum LoanTargetZeroAdminType {
  DEFAULT = '',
  ZERO = '0',
  SEVEN = '7',
  EIGHT = '8',
}

export const loanTargetZeroAdmin: Record<LoanTargetZeroAdminType, string> = {
  [LoanTargetZeroAdminType.DEFAULT]: '',
  [LoanTargetZeroAdminType.ZERO]: '物件の購入・建築',
  [LoanTargetZeroAdminType.SEVEN]: 'お借り換え',
  [LoanTargetZeroAdminType.EIGHT]: '増改築（借り換え有）',
};

export enum LoanTargetAdminType {
  DEFAULT = '',
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
}

export const loanTargetAdmin: Record<LoanTargetAdminType, string> = {
  [LoanTargetAdminType.DEFAULT]: '',
  [LoanTargetAdminType.ZERO]: '物件の購入・建築',
  [LoanTargetAdminType.ONE]: '建売住宅の購入',
  [LoanTargetAdminType.TWO]: '中古住宅の購入',
  [LoanTargetAdminType.THREE]: '新築マンションの購入',
  [LoanTargetAdminType.FOUR]: '中古マンションの購入',
  [LoanTargetAdminType.FIVE]: '建物だけ新築(既に土地をお持ちの方)',
  [LoanTargetAdminType.SIX]: '土地を購入後に建物新築',
  [LoanTargetAdminType.SEVEN]: 'お借り換え',
  [LoanTargetAdminType.EIGHT]: '増改築（借り換え有）',
};

export const personOccupancy: Record<PersonOccupancyType, string> = {
  [PersonOccupancyType.DEFAULT]: '',
  [PersonOccupancyType.YES]: 'はい',
  [PersonOccupancyType.NO]: 'いいえ',
};

export enum IncludeInExaminationType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}

export const includeInExamination: Record<IncludeInExaminationType, string> = {
  [IncludeInExaminationType.DEFAULT]: '',
  [IncludeInExaminationType.YES]: '含める',
  [IncludeInExaminationType.NO]: '含めない',
};

export const currentHomeLoan: Record<CurrentHomeLoanType, string> = {
  [CurrentHomeLoanType.DEFAULT]: '',
  [CurrentHomeLoanType.YES]: '有',
  [CurrentHomeLoanType.NO]: '無',
};

export const buyingandSellingScheduleType: Record<
  BuyingandSellingScheduleType,
  string
> = {
  [BuyingandSellingScheduleType.DEFAULT]: '',
  [BuyingandSellingScheduleType.SALE]: '売却',
  [BuyingandSellingScheduleType.RENT]: '賃貸',
  [BuyingandSellingScheduleType.OTHERS]: 'その他',
};

export const identityVerification: Record<IdentityVerificationType, string> = {
  [IdentityVerificationType.DEFAULT]: '',
  [IdentityVerificationType.DRIVER_LICENSE]: '運転免許証',
  [IdentityVerificationType.MY_NUMBER_CARD]: 'マイナンバーカード',
  [IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD]:
    '住民基本台帳カード（顔写真付き）',
};

export const businessAbility: Record<BusinessAbilityType, string> = {
  [BusinessAbilityType.DEFAULT]: '',
  [BusinessAbilityType.RENTAL]: '賃貸',
  [BusinessAbilityType.OFFICE_SHOP]: '事務所・店舗',
  [BusinessAbilityType.SELLING_SOLAR_POWER]: '太陽光発電による売電',
};
//END STEP 1 CONST
export type KingName = '昭和' | '平成';

export const kingNames: Record<KingName, { from: number; to: number }> = {
  昭和: {
    from: 1926,
    to: 1988,
  },
  平成: {
    from: 1989,
    to: 2019,
  },
};

export const epochYear: Record<number, string> = {
  1942: '昭和17',
  1943: '昭和18',
  1944: '昭和19',
  1945: '昭和20',
  1946: '昭和21',
  1947: '昭和22',
  1948: '昭和23',
  1949: '昭和24',
  1950: '昭和25',
  1951: '昭和26',
  1952: '昭和27',
  1953: '昭和28',
  1954: '昭和29',
  1955: '昭和30',
  1956: '昭和31',
  1957: '昭和32',
  1958: '昭和33',
  1959: '昭和34',
  1960: '昭和35',
  1961: '昭和36',
  1962: '昭和37',
  1963: '昭和38',
  1964: '昭和39',
  1965: '昭和40',
  1966: '昭和41',
  1967: '昭和42',
  1968: '昭和43',
  1969: '昭和44',
  1970: '昭和45',
  1971: '昭和46',
  1972: '昭和47',
  1973: '昭和48',
  1974: '昭和49',
  1975: '昭和50',
  1976: '昭和51',
  1977: '昭和52',
  1978: '昭和53',
  1979: '昭和54',
  1980: '昭和55',
  1981: '昭和56',
  1982: '昭和57',
  1983: '昭和58',
  1984: '昭和59',
  1985: '昭和60',
  1986: '昭和61',
  1987: '昭和62',
  1988: '昭和63',
  1989: '平成元',
  1990: '平成2',
  1991: '平成3',
  1992: '平成4',
  1993: '平成5',
  1994: '平成6',
  1995: '平成7',
  1996: '平成8',
  1997: '平成9',
  1998: '平成10',
  1999: '平成11',
  2000: '平成12',
  2001: '平成13',
  2002: '平成14',
  2003: '平成15',
  2004: '平成16',
  2005: '平成17',
  2006: '平成18',
  2007: '平成19',
  2008: '平成20',
  2009: '平成21',
  2010: '平成22',
  2011: '平成23',
  2012: '平成24',
  2013: '平成25',
  2014: '平成26',
  2015: '平成27',
  2016: '平成28',
  2017: '平成29',
  2018: '平成30',
  2019: '令和1',
  2020: '令和2',
  2021: '令和3',
  2022: '令和4',
  2023: '令和5',
  2024: '令和6',
  2025: '令和7',
  2026: '令和8',
  2027: '令和9',
  2028: '令和10',
  2029: '令和11',
  2030: '令和12',
  2031: '令和13',
  2032: '令和14',
  2033: '令和15',
};

//BEGIN STEP 2 CONST
export enum LandOwnershipType {
  DEFAULT = '',
  OWNERSHIP = '1',
  LEASE = '2',
  SUPERFICIES = '3',
  RENT_FOR_USE = '4',
}

export enum PurchasePurposeType {
  DEFAULT = '',
  NA = '1',
  REPURCHASE_CLAUSE = '2',
  RESERVATION_LAND = '3',
  TEMPORARY_REPLOT = '4',
}

export enum PlanningAreaType {
  DEFAULT = '',
  URBANIZED_AREA = '1',
  URBANIZATION_CONTROL_AREA = '2',
  OUTSIDE_CITY_PLANNING_AREA = '3',
  OTHERS = '99',
}

export enum RebuildingReasonType {
  DEFAULT = '',
  EXISTING_HOUSE = '1',
  OTHERS = '99',
}

export enum Flat35ApplicablePlanType {
  DEFAULT = '',
  ZEH = '1',
  PLAN_A = '2',
  PLAN_B = '3',
  NOT_APPLICABLE = '4',
}

export enum MaintenanceType {
  DEFAULT = '',
  LONGTERM_SUPERIOR_HOUSING = '1',
  PRELIMINARY_CERTIFIED_APARTMENT = '2',
  NOT_APPLICABLE = '3',
}

export enum Flat35ApplicationType {
  DEFAULT = '',
  ENERGY_SAVING = '1',
  EARTHQUAKE_RESISTANCE = '2',
  BARRIER_FREE = '3',
  DURABILITY = '4',
}

export enum RegionType {
  DEFAULT = '',
  REGIONAL_COOPERATION = '1',
  COMMUNITY_COOPERATION = '2',
  LOCAL_MIGRATION = '3',
  NOT_APPLICABLE = '4',
}
//END STEP 2 CONST

//BEGIN STEP 3 CONST
export enum CoOwnerType {
  DEFAULT = '',
  CANBE = '1',
  NONE = '0',
}

export enum SupplierType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
}

export enum SexType {
  DEFAULT = '',
  MAN = '1',
  WOMAN = '2',
}

export enum NationalityType {
  DEFAULT = '1',
  // JAPANESE_NATIONALITY = '1',
  FOREIGN_NATIONALITY = '2',
}

export enum FinanceAgencyType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}

export enum FamilyType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
}

export enum LoanBalanceType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}

export enum FundsPairOffType {
  DEFAULT = '',
  SAVINGS = '1',
  GIFT_MONEY = '2',
  HOUSE_SALE_PRICE = '3',
  OTHERS = '99',
}

export enum CurrentLoanType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}

export enum FinalTaxReturnType {
  DEFAULT = '',
  YES = '1',
  NOTHING = '0',
}

export enum SourceOfIncomeType {
  DEFAULT = '',
  SALARY_FIXED_SALARY = '1',
  SALARY_COMMISSION_PAY = '2',
  SALARY_ANNUAL_SALARY_SYSTEM = '3',
  BUSINESS_INCOME = '4',
  REAL_ESTATE_INCOME = '5',
}

export enum FileATaxReturnType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  OTHERS = '99',
}

export enum RelationshipType {
  DEFAULT = '',
  SPOUSE = '1',
  CHILD = '2',
  FATHER = '3',
  MOTHER = '4',
  SIBLINGS = '5',
  FIANCE = '6',
  OTHERS = '99',
}

export enum RelationshipToApplicantType {
  DEFAULT = '',
  SPOUSE = '1',
  FIANCE = '2',
  PARENT = '3',
  CHILD = '4',
  OTHERS = '99',
}

export enum GuarantorRelationshipType {
  DEFAULT = '',
  SPOUSE = '1',
  FIANCE = '2',
  PARENT = '3',
  CHILD = '4',
  CORPORATION = '5',
  CORPORATE_REPRESENTATIVE = '6',
  OTHERS = '99',
}

export enum ScheduledCompletionOfBorrowingType {
  DEFAULT = '',
  YES = '1',
  NOTHING = '0',
}

export enum ScheduledLoanPayoffType {
  DEFAULT = '',
  SCHEDULED_TO_BE_FULLY_REPAID = '1',
  SCHEDULED_TO_BE_PAID_OFF = '2',
  PAID_OFF = '3',
  NO_PLANS_TO_PAY_OFF = '4',
  NO_PLAN_TO_REPAY_RENTED = '5',
  NO_PLAN_TO_REPAY_PLAN = '6',
}

export enum CommonHousingType {
  DEFAULT = '',
  APPLICABLE = '1',
  NOT_APPLICABLE = '0',
}

export enum BorrowingType {
  DEFAULT = '',
  MONTHLY_PAYMENT = '0',
  CARD_LOAN = '1',
  RENTED_HOUSE = '2',
  BUSINESS_LOAN = '3',
  SBI_LOAN = '4',
}

export enum BorrowerIncomeType {
  DEFAULT = '',
  APPLICANT_SELF = '1',
  INCOME_TOTAL = '2',
}

export enum EstateMortgageType {
  DEFAULT = '',
  SECURED = '1',
  UNSECURED = '0',
}

export enum LoanPurposeType {
  DEFAULT = '',
  CAR_LOAN = '1',
  EDUCATION_LOAN_SCHOLARSHIP = '2',
  LOAN_FOR_LIVING_EXPENSES = '3',
  CARD_LOAN = '4',
  HOUSING_LOAN = '5',
  RENOVATION_LOAN = '6',
  HOME_APPLIANCE_PURCHASE = '7',
  OTHERS = '99',
}

export enum BorrowerType {
  DEFAULT = '',
  APPLICANT_HIMSELF_HERSELF = '1',
  PAIR_LOAN_APPLICANT_PROSPECTIVE_JOINT_GUARANTOR = '2',
  INCOME_ADDER = '3',
}

export enum BusinessBorrowingType {
  DEFAULT = '',
  WORKING_CAPITAL = '1',
  CAPITAL_FUND = '2',
  LEASE = '3',
  OTHERS = '99',
}

export enum BorrowingCategoryType {
  DEFAULT = '',
  SHOPPING = '1',
  CASH_CACHING = '2',
  CARD_LOAN = '3',
}

export enum BorrowingFromHousingFinanceAgencyType {
  DEFAULT = '',
  APPLICABLE = '1',
  NOT_APPLICABLE = '0',
}

export enum NationalGuaranteeType {
  DEFAULT = '',
  RECEIVE = '1',
  NOTACCEPT = '0',
}

export enum CurrentResidenceType {
  DEFAULT = '',
  LIVE_IN_A_RELATIVES_HOUSE = '1',
  OWNED_HOUSE = '2',
  PUBLIC_HOUSING = '3',
  RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS = '4',
  PRIVATE_APARTMENT = '5',
  PRIVATE_APARTMENT_EXCLUDING_WOODEN_APARTMENTS = '6',
  BOARDING_HOUSE = '7',
  COMPANY_HOUSING_OFFICIAL_RESIDENCE = '8',
}

export enum ReasonsForNeedingHousingType {
  DEFAULT = '',
  OLD_HOUSE = '1',
  HOUSING_IS_SMALL = '2',
  MARRIAGE = '3',
  SEPARATE_HOUSEHOLD = '4',
  BAD_ENVIRONMENT = '5',
  RENT_IS_EXPENSIVE = '6',
  EVICTION_REQUEST = '7',
  INCONVENIENT_FOR_COMMUTING_TO_WORK_OR_SCHOOL = '8',
  HOPE_TO_OWN_A_HOUSE = '9',
  OTHERS = '99',
}

export enum LoanTargetPropertyType {
  DEFAULT = '',
  RENT = '1',
  OFFICE_STORE = '2',
  ELECTRICITY_SALE_FROM_SOLAR_POWER_GENERATION = '3',
  NO_BUSINESS = '4',
}

export enum ResidentStatusType {
  DEFAULT = '',
  RESIDENT = '1',
  SECOND_HOUSE = '2',
  THE_PERSON_DOES_NOT_LIVE_PARENTS_LIVE = '3',
  THE_PERSON_DOES_NOT_LIVE_CHILDREN_LIVE = '4',
  THE_PERSON_DOES_NOT_RESIDE_OTHER = '99',
}

export enum PlannedCohabitantType {
  DEFAULT = '',
  SPOUSE = '1',
  CHILD = '2',
  FATHER = '3',
  MOTHER = '4',
  BROTHERS_AND_SISTERS = '5',
  FIANCE = '6',
  OTHERS = '99',
}

export enum GeneralIncomeConfirmation {
  CONFIRM = '1',
  NOT_CONFIRM = '0',
}

export enum LeaveType {
  DEFAULT = '',
  SCHEDULED_ACQUISITION = '1',
  ACQUIRING = '2',
  IT_HAS_BEEN_ACQUIRED = '3',
  NOT_APPLICABLE = '4',
}

export enum MasterBankIds {
  SUMISHIN_SBI = '10',
  HOUSING_LOAN = '1207',
}

export enum DispatchType {
  DEFAULT = '',
  YES = '1',
  NOTHING = '0',
}

export enum NursingCareType {
  DEFAULT = '',
  SCHEDULED_ACQUISITION = '1',
  ACQUIRING = '2',
  OBTAINED_FEFORE_APPLICATION_YEAR = '3',
}
export enum JobChangeType {
  DEFAULT = '',
  YES = '1',
  NOTHING = '0',
}

export enum ChildType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
}

export enum WorkIndustryType {
  DEFAULT = '',
  MANUFACTURING_INDUSTRY = '1',
  AGRICULTURE = '2',
  FORESTRY = '3',
  FISHERY = '4',
  MINING = '5',
  CONSTRUCTION_INDUSTRY = '6',
  WHOLE_SALE_RETAIL = '7',
  FINANCE_INSURANCE = '8',
  INSURANCE_BUSINESS = '9',
  REAL_ESTATE_BUSINESS = '10',
  TRANSPORTATION_INDUSTRY = '11',
  ELECTRICITY_GAS_HEAT_SUPPLY_WATER = '12',
  DINING_ACCOMODATION = '13',
  MEDICAL_CARE_AND_WELFARE = '14',
  EDUCATION_AND_LEARNING_SUPPORT = '15',
  OTHER_SERVICE_INDUSTRY = '16',
  PUBLIC_AFFAIRS = '17',
  INFORMATION_AND_COMMUNICATION_INDUSTRY = '18',
  COMPLEX_SERVICE_NDUSTRY = '19',
  OTHERS = '99',
}

export enum ResidentType {
  DEFAULT = '',
  USER = '0',
  ADMIN = '1',
}

export enum OccupationType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9',
  TEN = '10',
  ELEVEN = '11',
  TWELVE = '12',
  OTHERS = '99',
}
export enum OccupationDetailType {
  DEFAULT = '',
  DOCTOR = '1',
  DENTIST = '2',
  LAWYER = '3',
  PATENT_ATTORNEY = '4',
  CPA = '5',
  TAX_ACCOUNTANT = '6',
  JUDICIAL_SCRIVENER_ADMINISTRATIVE_SCRIVENER = '7',
  TEACHING_CIVIL_SERVANT = '8',
  SALES_SALES = '9',
  CLERICAL_WORK = '10',
  TECHNICAL_JOB = '11',
  DRIVER = '12',
  OTHERS = '99',
}

export enum SituationType {
  APPLICATION = 'application',
  APPLICATION_COMPLETED = 'applicationComplete',
  DOCUMENT_CHECK = 'documentCheck',
  EXAMINATION_START = 'examinationStart',
  EXAMINATION_COMPLETED = 'examinationComplete',
  CONTACT_ALREADY = 'contactAlready',
}

export enum StatusType {
  DOCUMENT_CHECK = '書類確認中',
  DOCUMENT_DEFICIENCIES_IN_PROGRESS = '書類不備対応中',
  UNDER_REVIEW = '審査中',
  NOTIFICATION_EXAMINATION_RESULTS = '審査結果ご連絡',
}

export enum ApplicantDetailType {
  DEFAULT = '',
  MAIN = '0',
  PAIR = '1',
  JOINTDEBT = '2',
  JOINTGUARANTOR = '3',
}

export const joinGuarantor: Record<CoOwnerType, string> = {
  [CoOwnerType.DEFAULT]: '',
  [CoOwnerType.CANBE]: 'いる',
  [CoOwnerType.NONE]: 'いない',
};

export const joinGuarantorCount: Record<SupplierType, string> = {
  [SupplierType.DEFAULT]: '',
  [SupplierType.ONE]: '1',
  [SupplierType.TWO]: '2',
  [SupplierType.THREE]: '3',
};

export const sex: Record<SexType, string> = {
  [SexType.DEFAULT]: '',
  [SexType.WOMAN]: '女性',
  [SexType.MAN]: '男性',
};

export const nationality: Record<NationalityType, string> = {
  [NationalityType.DEFAULT]: '日本国籍',
  // [NationalityType.JAPANESE_NATIONALITY]: '日本国籍',
  [NationalityType.FOREIGN_NATIONALITY]: '外国籍',
};

export const financeAgency: Record<FinanceAgencyType, string> = {
  [FinanceAgencyType.DEFAULT]: '',
  [FinanceAgencyType.YES]: '有',
  [FinanceAgencyType.NO]: '無',
};

export const family: Record<FamilyType, string> = {
  [FamilyType.DEFAULT]: '',
  [FamilyType.ONE]: '1',
  [FamilyType.TWO]: '2',
  [FamilyType.THREE]: '3',
  [FamilyType.FOUR]: '4',
  [FamilyType.FIVE]: '5',
  [FamilyType.SIX]: '6',
  [FamilyType.SEVEN]: '7',
  [FamilyType.EIGHT]: '8',
  [FamilyType.NINE]: '9',
};

export enum PositionType {
  DEFAULT = '',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
}

export const positionType: Record<PositionType, string> = {
  [PositionType.DEFAULT]: '',
  [PositionType.ONE]: '無職',
  [PositionType.TWO]: '代表・社長',
  [PositionType.THREE]: '役員・執行役員',
  [PositionType.FOUR]: '管理職',
  [PositionType.FIVE]: 'その他役職',
  [PositionType.SIX]: '一般職',
  [PositionType.SEVEN]: '学生・主婦',
};

export const loanbalance: Record<LoanBalanceType, string> = {
  [LoanBalanceType.DEFAULT]: '',
  [LoanBalanceType.YES]: '有',
  [LoanBalanceType.NO]: '無',
};

export const currentLoan: Record<CurrentLoanType, string> = {
  [CurrentLoanType.DEFAULT]: '',
  [CurrentLoanType.YES]: '有',
  [CurrentLoanType.NO]: '無',
};

export const finalTaxReturn: Record<FinalTaxReturnType, string> = {
  [FinalTaxReturnType.DEFAULT]: '',
  [FinalTaxReturnType.YES]: 'はい',
  [FinalTaxReturnType.NOTHING]: 'いいえ',
};

export const scheduledCompletionOfBorrowing: Record<
  ScheduledCompletionOfBorrowingType,
  string
> = {
  [ScheduledCompletionOfBorrowingType.DEFAULT]: '',
  [ScheduledCompletionOfBorrowingType.YES]: '有',
  [ScheduledCompletionOfBorrowingType.NOTHING]: '無',
};

export const borrowerIncome: Record<BorrowerIncomeType, string> = {
  [BorrowerIncomeType.DEFAULT]: '',
  [BorrowerIncomeType.APPLICANT_SELF]: '申込人本人',
  [BorrowerIncomeType.INCOME_TOTAL]: '収入合算者（連帯保証人予定者）',
};

export const commonHousing: Record<CommonHousingType, string> = {
  [CommonHousingType.DEFAULT]: '',
  [CommonHousingType.APPLICABLE]: '該当する',
  [CommonHousingType.NOT_APPLICABLE]: '該当しない',
};

export const estateMortgage: Record<EstateMortgageType, string> = {
  [EstateMortgageType.DEFAULT]: '',
  [EstateMortgageType.SECURED]: '有担保',
  [EstateMortgageType.UNSECURED]: '無担保',
};

export const borrowingCategory: Record<BorrowingCategoryType, string> = {
  [BorrowingCategoryType.DEFAULT]: '',
  [BorrowingCategoryType.SHOPPING]: 'ショッピング',
  [BorrowingCategoryType.CASH_CACHING]: 'キャッシング',
  [BorrowingCategoryType.CARD_LOAN]: 'カードローン',
};

export const borrowingFromHousingFinanceAgency: Record<
  BorrowingFromHousingFinanceAgencyType,
  string
> = {
  [BorrowingFromHousingFinanceAgencyType.DEFAULT]: '',
  [BorrowingFromHousingFinanceAgencyType.APPLICABLE]: 'はい',
  [BorrowingFromHousingFinanceAgencyType.NOT_APPLICABLE]: 'いいえ',
};

export const nationalGuarantee: Record<NationalGuaranteeType, string> = {
  [NationalGuaranteeType.DEFAULT]: '',
  [NationalGuaranteeType.RECEIVE]: '受け取る',
  [NationalGuaranteeType.NOTACCEPT]: '受け取らない',
};

export const dispatch: Record<DispatchType, string> = {
  [DispatchType.DEFAULT]: '',
  [DispatchType.YES]: '有',
  [DispatchType.NOTHING]: '無',
};

export const jobChange: Record<JobChangeType, string> = {
  [DispatchType.DEFAULT]: '',
  [DispatchType.YES]: '有',
  [DispatchType.NOTHING]: '無',
};

export const child: Record<ChildType, string> = {
  [ChildType.DEFAULT]: '',
  [ChildType.ONE]: '1',
  [ChildType.TWO]: '2',
  [ChildType.THREE]: '3',
  [ChildType.FOUR]: '4',
  [ChildType.FIVE]: '5',
  [ChildType.SIX]: '6',
  [ChildType.SEVEN]: '7',
  [ChildType.EIGHT]: '8',
  [ChildType.NINE]: '9',
};

export const situation: Record<SituationType, string> = {
  [SituationType.APPLICATION]: 'お申込み',
  [SituationType.APPLICATION_COMPLETED]: '受付完了',
  [SituationType.DOCUMENT_CHECK]: '資料チェック',
  [SituationType.EXAMINATION_START]: '審査開始',
  [SituationType.EXAMINATION_COMPLETED]: '審査終了',
  [SituationType.CONTACT_ALREADY]: '連絡済',
};

export const statusNote: Record<StatusType, string> = {
  [StatusType.DOCUMENT_CHECK]: 'みらいバンク支店',
  [StatusType.DOCUMENT_DEFICIENCIES_IN_PROGRESS]: '申込人様',
  [StatusType.UNDER_REVIEW]: '住信SBIネット銀行',
  [StatusType.NOTIFICATION_EXAMINATION_RESULTS]: 'みらいバンク',
};
//END STEP 3 CONST

export enum ConditionType {
  DEFAULT = '',
  CAN_BE = 'canBe',
  CAN_NOT = 'canNot',
}

export const condition: Record<ConditionType, string> = {
  [ConditionType.DEFAULT]: 'ー',
  [ConditionType.CAN_BE]: 'あり',
  [ConditionType.CAN_NOT]: 'できない',
};
//END APPLICATION ITEM

export const EXPIRATION_DATE = 3;
export const ZIPCLOUDURL = 'https://zipcloud.ibsnet.co.jp/api/search';

export const ID = ['1', '2', '3'];

export enum ApplicantType {
  MAIN = 0,
  PAIR = 1,
}

export enum CODEBANK {
  MCJ = '6670',
  SBI = '0038',
}

export enum ID_BANK {
  SBI = '10',
}

export const MAX_FILES = 3;
export const MAX_SIZE_FILE = 35 * 1000 * 1000; //35MB
export const MAX_SIZE_FILE_ADMIN = 35 * 1000 * 1000; //35MB

export enum Placeholder {
  PHONE_NUMBER = '08012345678',
  POSTAL_CODE = '1080023',
  ADDRESS_KANJI = '４−１２−３８CANAL GATE SHIBAURAビル６階',
  ADDRESS_KANA = '４−１３−３８キャナルゲートシバウラ　ビル６カイ',
  LAST_NAME_KANJI = '未来',
  FIRST_NAME_KANJI = '太郎',
  LAST_NAME_KANA = 'ミライ',
  FIRST_NAME_KANA = 'タロウ',
  OFFICE_NAME_KANJI = '株式会社ABC商事',
  OFFICE_NAME_KANA = '株式会社ABC商事',
  TRANSFER_OFFFICE_ADDRESS_KANJI = '１−１−１MILIZEビル２０２',
  TRANSFER_OFFFICE_ADDRESS_KANA = '１−１−１ミライズビル２０２',
}

export enum Title {
  REQUIRED = '申込人ご本人様に入力していただく項目',
  INFORMATION = '申込人様について',
  EMPLOYMENT = 'ご職業について',
  RESIDENCE = 'お住まいについて',
  BORROWING = 'お借入れについて',
  PROPERTY = '物件について',
  FUNDS_BREAKDOWN = '必要資金内訳（税込）',
  PROCUREMENT_BREAKDOWN = '調達資金内訳',
  PARTNER_COMPANIES = '提携会社（不動産会社・住宅メーカー等）について',
  CURRENT_LOAN = '現在ご利用中のローン等について',
}

export enum SPTitle {
  LOAN_APPLY_DATE = 'はじめに',
  STEP_ONE = '01：お借入のご希望',
  STEP_TWO = '02：あなたの情報',
  STEP_THREE = '03：あなたのご職業',

  // STEP DEFAULT
  STEP_FOUR = '04：お住まい',
  STEP_FIVE = '05：現在の借入状況',
  STEP_SIX = '06：資金計画',
  STEP_SEVEN = '07：書類添付',
  STEP_EIGHT = '08：担当者情報',

  // STEP INCOME TOTAL
  STEP_FOUR_INCOME = '04：収入合算者',
  STEP_FIVE_INCOME = '05：収入合算者の職業',
  STEP_SIX_INCOME = '06：お住まい',
  STEP_SEVEN_INCOME = '07：現在の借入状況',
  STEP_EIGHT_INCOME = '08：資金計画',
  STEP_NINE_INCOME = '09: 書類添付',
  STEP_TEN_INCOME = '10: 収入合算者の書類',
  STEP_ELEVEN_INCOME = '11: 担当者情報',

  // STEP JOIN GUARANTORS
  STEP_FOUR_GUARANTORS = '04：担保提供者',
  STEP_FIVE_GUARANTORS = '05：お住まい',
  STEP_SIX_GUARANTORS = '06：現在の借入状況',
  STEP_SEVEN_GUARANTORS = '07：資金計画',
  STEP_EIGHT_GUARANTORS = '08：書類添付',
  STEP_NINE_GUARANTORS = '09: 担当者情報',

  // STEP INCOME + JOIN GUARANTORS
  STEP_FOUR_INCOME_GUARANTORS = '04：収入合算者',
  STEP_FIVE_INCOME_GUARANTORS = '05：収入合算者の職業',
  STEP_SIX_INCOME_GUARANTORS = '06：担保提供者',
  STEP_SEVEN_INCOME_GUARANTORS = '07：お住まい',
  STEP_EIGHT_INCOME_GUARANTORS = '08：現在の借入状況',
  STEP_NINE_INCOME_GUARANTORS = '09: 資金計画',
  STEP_TEN_INCOME_GUARANTORS = '10: 書類添付',
  STEP_ELEVEN_INCOME_GUARANTORS = '11: 収入合算者の書類',
  STEP_TWELVE_INCOME_GUARANTORS = '12: 担当者情報',
}

export enum CollateralType {
  DEFAULT = '',
  NEW_MANSION = '1',
  PREOWNED_MANSION = '2',
  OLD_HOUSE = '3',
  HOME_FOR_SALE = '4',
  NEW_HOUSE = '5',
  RETAIL_HOUSE = '6',
  OTHER = '99',
}

export enum JointOwnershipDivision {
  DEFAULT = '',
  BUILDING_ONLY = '1',
  LAND_ONLY = '2',
  BUILDING_AND_LAND = '3',
  UNDECIDED = '4',
}

export enum Error {
  TOKEN_INVALID = 'token_invalid',
  TOKEN_EXPIRED = 'token_expired',
  MUST_LOGIN = 'must_login',
  DUPLICATE = 'duplicate_email',
  TOKEN_REVOKED = 'token_revoked',
  LOAN_TYPE_INVALID = 'any_loan_type_invalid',
}

export enum INTERFACE_ERROR_STATUS {
  DEFAULT = '',
  NOT_HAVE_ERROR = '0',
  HAVE_ERROR = '1',
}

export const errorMsg: Record<Error, string> = {
  [Error.TOKEN_EXPIRED]: 'トークンの有効期限が切れました。',
  [Error.TOKEN_INVALID]:
    'ログイン用のトークンが不正です。再度ログインする必要があります。',
  [Error.MUST_LOGIN]: 'この機能を使用するために、ログインしてください。',
  [Error.DUPLICATE]:
    'このメールアドレスは既に存在しています。別のメールアドレスで登録してください。',
  [Error.TOKEN_REVOKED]: 'この機能を使用するために、ログインしてください。',
  [Error.LOAN_TYPE_INVALID]:
    'ペアローン紐付する時、１つの仮審査申し込みの借入状態がペアローンではない状態に変更されました。再度ご確認ください。',
};

export const VALIDATE_MAX = {
  CAPITAL_STOCK: 9999999999, // MAN YEN
  // MOBILE_PHONE: 13,
  // HOME_PHONE: 13,
  MOBILE_PHONE: 14,
  HOME_PHONE: 14,
  POSTAL_CODE: 8,
  MAN_YEN: 99999, // MAN YEN
  MAN_YEN_4: 9999,
  YEN: 99999999,
};

export const VALIDATE_MIN = {
  HOME_PHONE: 12,
};

export type AdminPreliminaryItem = {
  condition?: 'require' | 'require_condition' | 'require_sp';
  name_ja: string;
  name_en: string;
  value?: string;
  required?: boolean;
  isConvertFullWith?: boolean;
  max?: number;
  dynamicType:
    | 'date'
    | 'dateTime'
    | 'input'
    | 'inputNumber'
    | 'select'
    | 'selectMulti'
    | 'selectDistrict'
    | 'selectNumber'
    | 'selectPermanent'
    | 'selectBank'
    | 'selectHasPlan'
    | 'selectSBI'
    | 'selectLoanTarget'
    | 'selectTaxReturn'
    | 'selectSalePerson'
    | 'selectBorrowingStatus'
    | 'selectLivedYear'
    | 'inputDate'
    | null;
  dynamicOptionField?: string;
  requireSelect?: boolean;
  dynamicOptionHidden?: (index: number) => boolean;
  options?: {
    views?: ('month' | 'day' | 'year')[];
    format?: string;
    minDate?: string;
    maxDate?: string;
  };
  defaultValue?: string | number | boolean;
  subFieldName?: string;
  subField?: {
    name_en: string;
    dynamicType: 'select' | 'input' | null;
    dynamicOptionField: string;
    subFieldName?: string;
  };
};

/**
 * STEP 2
 */
export const PERSONAL_INFORMATION: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'PApplicantPerson',
  },
  {
    name_ja: 'お名前（姓）',
    name_en: 'p_applicant_people_attributes[0].last_name_kanji',
    condition: 'require',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: 'お名前（名）',
    dynamicType: 'input',
    name_en: 'p_applicant_people_attributes[0].first_name_kanji',
    condition: 'require',
    isConvertFullWith: true,
  },
  {
    name_ja: 'お名前（姓）（フリガナ）',
    dynamicType: 'input',
    name_en: 'p_applicant_people_attributes[0].last_name_kana',
    condition: 'require',
    isConvertFullWith: true,
  },
  {
    name_ja: 'お名前（名）（フリガナ）',
    dynamicType: 'input',
    name_en: 'p_applicant_people_attributes[0].first_name_kana',
    condition: 'require',
    isConvertFullWith: true,
  },
  {
    name_ja: '性別',
    name_en: 'p_applicant_people_attributes[0].sex',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'sex',
  },
  {
    name_ja: '生年月日',
    name_en: 'p_applicant_people_attributes[0].birthday',
    condition: 'require',
    dynamicType: 'date',
    options: {
      minDate: dayjs().subtract(65, 'y').toString(),
      maxDate: dayjs().subtract(18, 'y').toString(),
    },
  },
  {
    name_ja: '現在の国籍',
    name_en: 'p_applicant_people_attributes[0].nationality',
    dynamicType: 'select',
    dynamicOptionField: 'nationality',
  },
  {
    name_ja: '電話番号携帯',
    name_en: 'p_applicant_people_attributes[0].mobile_phone_number',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.MOBILE_PHONE,
  },
  {
    name_ja: '電話番号自宅',
    name_en: 'p_applicant_people_attributes[0].home_phone_number',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.HOME_PHONE,
  },
  {
    name_ja: '緊急連絡先',
    name_en: 'p_applicant_people_attributes[0].emergency_contact_number',
    dynamicType: 'inputNumber',
    required: true,
    max: VALIDATE_MAX.MOBILE_PHONE,
  },
  {
    name_ja: '郵便番号',
    name_en: 'p_applicant_people_attributes[0].postal_code',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.POSTAL_CODE,
  },
  {
    name_ja: '都道府県',
    name_en: 'p_applicant_people_attributes[0].prefecture_kanji',
    condition: 'require',
    dynamicType: 'selectDistrict',
    dynamicOptionField: 'prefecture_kanji',
    isConvertFullWith: true,
  },
  {
    name_ja: '市区郡',
    name_en: 'p_applicant_people_attributes[0].city_kanji',
    condition: 'require',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '町村丁目',
    name_en: 'p_applicant_people_attributes[0].district_kanji',
    condition: 'require',
    dynamicType: 'input',
    dynamicOptionField: 'district_kanji',
    isConvertFullWith: true,
  },
  {
    name_ja: '丁目以下・建物名・部屋番号',
    name_en: 'p_applicant_people_attributes[0].other_address_kanji',
    condition: 'require',
    dynamicType: 'input',
    dynamicOptionField: 'other_address_kanji',
    isConvertFullWith: true,
  },
  {
    name_ja: '都道府県（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].prefecture_kana',
    condition: 'require',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: '市区郡（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].city_kana',
    condition: 'require',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: '町村丁目（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].district_kana',
    condition: 'require',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: '丁目以下・建物名・部屋番号（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].other_address_kana',
    condition: 'require',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: 'ご連絡先用メールアドレス',
    name_en: 'p_applicant_people_attributes[0].owner_email',
    condition: 'require',
    dynamicType: 'input',
  },
];

/**
 * Step 1
 */
export const INCOME_TOTALIZER: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'PApplicantPerson',
  },
  {
    name_ja: 'お名前（姓）',
    name_en: 'p_applicant_people_attributes[0].last_name_kanji',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: 'お名前（名）',
    dynamicType: 'input',
    name_en: 'p_applicant_people_attributes[0].first_name_kanji',
    condition: 'require_sp',
    isConvertFullWith: true,
  },
  {
    name_ja: 'お名前（姓）（フリガナ）',
    dynamicType: 'input',
    name_en: 'p_applicant_people_attributes[0].last_name_kana',
    condition: 'require',
    isConvertFullWith: true,
  },
  {
    name_ja: 'お名前（名）（フリガナ）',
    dynamicType: 'input',
    name_en: 'p_applicant_people_attributes[0].first_name_kana',
    condition: 'require',
    isConvertFullWith: true,
  },
  {
    name_ja: '性別',
    name_en: 'p_applicant_people_attributes[0].sex',
    dynamicType: 'select',
    dynamicOptionField: 'sex',
  },
  {
    name_ja: '続柄',
    name_en: 'p_applicant_people_attributes[1].name_relationship_to_applicant',
    condition: 'require_condition',
    dynamicType: 'input',
    isConvertFullWith: true,
    subField: {
      name_en: 'p_applicant_people_attributes[1].relationship_to_applicant',
      dynamicType: 'select',
      dynamicOptionField: 'relationship_to_applicant',
    },
  },
  {
    name_ja: '現在の国籍',
    name_en: 'p_applicant_people_attributes[0].nationality',
    dynamicType: 'select',
    dynamicOptionField: 'nationality',
  },
  {
    name_ja: '電話番号携帯',
    name_en: 'p_applicant_people_attributes[0].mobile_phone_number',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.MOBILE_PHONE,
  },
  {
    name_ja: '電話番号自宅',
    name_en: 'p_applicant_people_attributes[0].home_phone_number',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.HOME_PHONE,
  },
  {
    name_ja: '緊急連絡先',
    name_en: 'p_applicant_people_attributes[0].emergency_contact_number',
    dynamicType: 'inputNumber',
    required: true,
    max: VALIDATE_MAX.MOBILE_PHONE,
  },
  {
    name_ja: '生年月日',
    name_en: 'p_applicant_people_attributes[0].birthday',
    condition: 'require_sp',
    dynamicType: 'date',
    options: {
      minDate: dayjs().subtract(65, 'y').toString(),
      maxDate: dayjs().subtract(18, 'y').toString(),
    },
  },
  {
    name_ja: '郵便番号',
    name_en: 'p_applicant_people_attributes[1].postal_code',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.POSTAL_CODE,
  },
  {
    name_ja: '都道府県',
    name_en: 'p_applicant_people_attributes[0].prefecture_kanji',
    condition: 'require_sp',
    dynamicType: 'selectDistrict',
    dynamicOptionField: 'prefecture_kanji',
    isConvertFullWith: true,
  },
  {
    name_ja: '市区郡',
    name_en: 'p_applicant_people_attributes[0].city_kanji',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '町村丁目',
    name_en: 'p_applicant_people_attributes[0].district_kanji',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
    dynamicOptionField: 'district_kanji',
  },
  {
    name_ja: '丁目以下・建物名・部屋番号',
    name_en: 'p_applicant_people_attributes[0].other_address_kanji',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
    dynamicOptionField: 'other_address_kanji',
  },
  {
    name_ja: '都道府県（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].prefecture_kana',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: '市区郡（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].city_kana',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: '町村丁目（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].district_kana',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: '丁目以下・建物名・部屋番号（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].other_address_kana',
    dynamicType: 'input',
    required: true,
    isConvertFullWith: true,
  },
  {
    name_ja: 'ご連絡先用メールアドレス',
    name_en: 'p_applicant_people_attributes[0].owner_email',
    dynamicType: 'input',
    required: true,
  },
];

/**
 * STEP 3
 */
export const PERSONAL_OCCUPATION: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'PApplicantPerson',
  },
  {
    name_ja: 'ご職業',
    name_en: 'p_applicant_people_attributes[0].occupation',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'occupation',
  },
  {
    name_ja: '勤務先　職業（その他）',
    name_en: 'p_applicant_people_attributes[0].other_occupation',
    condition: 'require_condition',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '業種',
    name_en: 'p_applicant_people_attributes[0].industry',
    dynamicType: 'select',
    dynamicOptionField: 'industry',
  },
  {
    name_ja: '勤務先　業種（その他）',
    name_en: 'p_applicant_people_attributes[0].other_industry',
    condition: 'require_condition',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '職種',
    name_en: 'p_applicant_people_attributes[0].occupation_detail',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'occupation_detail',
  },
  {
    name_ja: '勤務先　職種（その他）',
    name_en: 'p_applicant_people_attributes[0].other_occupation_detail',
    condition: 'require_condition',
    dynamicType: 'input',
    dynamicOptionField: 'other_occupation_detail',
    isConvertFullWith: true,
  },
  {
    name_ja: '雇用形態',
    name_en: 'p_applicant_people_attributes[0].emplmt_form_code',
    condition: 'require',
    required: true,
    dynamicType: 'select',
    dynamicOptionField: 'emplmt_form_code',
  },
  {
    name_ja: '勤務先名',
    name_en: 'p_applicant_people_attributes[0].office_name_kanji',
    condition: 'require',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '勤務先名（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].office_name_kana',
    condition: 'require',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '所属部課',
    name_en: 'p_applicant_people_attributes[0].department',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '役職',
    name_en: 'p_applicant_people_attributes[0].position',
    required: true,
    dynamicType: 'select',
    dynamicOptionField: 'position',
  },
  {
    name_ja: '勤務先の電話番号',
    name_en: 'p_applicant_people_attributes[0].office_phone_number',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.HOME_PHONE,
  },
  {
    name_ja: '勤務先本社所在地',
    name_en: 'p_applicant_people_attributes[0].headquarters_location',
    condition: 'require',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '勤務先上場区分',
    name_en: 'p_applicant_people_attributes[0].listed_division',
    required: true,
    dynamicType: 'select',
    dynamicOptionField: 'listed_division',
  },
  {
    name_ja: '勤務先設立年月日',
    name_en: 'p_applicant_people_attributes[0].office_establishment_date',
    required: true,
    dynamicType: 'inputDate',
  },
  {
    name_ja: '勤務先資本金',
    name_en: 'p_applicant_people_attributes[0].capital_stock',
    required: true,
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.CAPITAL_STOCK).length,
  },
  {
    name_ja: '郵便番号',
    name_en: 'p_applicant_people_attributes[0].office_postal_code',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.POSTAL_CODE,
  },
  {
    name_ja: '都道府県',
    name_en: 'p_applicant_people_attributes[0].office_prefecture_kanji',
    condition: 'require',
    dynamicType: 'selectDistrict',
    dynamicOptionField: 'office_prefecture_kanji',
    isConvertFullWith: true,
  },
  {
    name_ja: '市区郡',
    name_en: 'p_applicant_people_attributes[0].office_city_kanji',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '町村丁目',
    name_en: 'p_applicant_people_attributes[0].office_district_kanji',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '丁目以下・建物名・部屋番号',
    name_en: 'p_applicant_people_attributes[0].other_office_address_kanji',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '都道府県（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].office_prefecture_kana',
    condition: 'require',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '市区郡（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].office_city_kana',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '町村丁目（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].office_district_kana',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '丁目以下・建物名・部屋番号（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].other_office_address_kana',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '従業員数',
    name_en: 'p_applicant_people_attributes[0].number_of_employee',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: String(9999999).length,
  },
  {
    name_ja: '入社年月',
    name_en: 'p_applicant_people_attributes[0].employment_started_date',
    dynamicType: 'date',
    options: {
      views: ['year', 'month'],
      format: 'YYYY/MM',
      minDate: dayjs().subtract(49, 'y').toString(),
      maxDate: dayjs().toString(),
    },
  },
  {
    name_ja: '前年度年収',
    name_en: 'p_applicant_people_attributes[0].last_year_income',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: 'うち、ボーナス',
    name_en: 'p_applicant_people_attributes[0].bonus_income',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '前々年度年収 （MCJ固有項目）',
    name_en: 'p_applicant_people_attributes[0].two_years_ago_income',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '収入源',
    name_en: 'p_applicant_people_attributes[0].income_source',
    dynamicType: 'selectMulti',
    dynamicOptionField: 'income_source',
  },
  {
    name_ja: '収入源（銀行送信用）',
    name_en: 'p_applicant_people_attributes[0].main_income_source',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'main_income_source',
  },
  {
    name_ja: '確定申告をしていますか？',
    name_en: 'p_applicant_people_attributes[0].tax_return',
    dynamicType: 'selectPermanent',
    dynamicOptionField: 'tax_return',
  },
  {
    name_ja: '確定申告の理由',
    name_en: 'p_applicant_people_attributes[0].type_tax_return',
    condition: 'require_condition',
    dynamicType: 'selectMulti',
    dynamicOptionField: 'type_tax_return',
  },
  {
    name_ja: '確定申告の理由　その他',
    name_en: 'p_applicant_people_attributes[0].other_type_tax_return',
    condition: 'require_condition',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '現在、出向（派遣）していますか？',
    name_en: 'p_applicant_people_attributes[0].transfer_office',
    dynamicType: 'selectPermanent',
    dynamicOptionField: 'transfer_office',
  },
  {
    name_ja: '出向（派遣）勤務先名',
    name_en: 'p_applicant_people_attributes[0].transfer_office_name_kanji',
    condition: 'require_condition',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '出向（派遣）勤務先名（フリガナ）',
    name_en: 'p_applicant_people_attributes[0].transfer_office_name_kana',
    condition: 'require_condition',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '出向（派遣）先 電話番号',
    name_en: 'p_applicant_people_attributes[0].transfer_office_phone_number',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.HOME_PHONE,
  },
  {
    name_ja: '出向（派遣）先 郵便番号',
    name_en: 'p_applicant_people_attributes[0].transfer_office_postal_code',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.POSTAL_CODE,
  },
  {
    name_ja: '出向（派遣）先 都道府県',
    name_en:
      'p_applicant_people_attributes[0].transfer_office_prefecture_kanji',
    condition: 'require_condition',
    dynamicType: 'selectDistrict',
    dynamicOptionField: 'transfer_office_prefecture_kanji',
  },
  {
    name_ja: '出向（派遣）先 市区郡',
    name_en: 'p_applicant_people_attributes[0].transfer_office_city_kanji',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '出向（派遣）先 町村丁目',
    name_en: 'p_applicant_people_attributes[0].transfer_office_district_kanji',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '出向（派遣）先 丁目以下・建物名・部屋番号',
    name_en:
      'p_applicant_people_attributes[0].transfer_office_other_address_kanji',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '産休・育休の取得状況',
    name_en:
      'p_applicant_people_attributes[0].maternity_paternity_leave_status',
    dynamicType: 'select',
    dynamicOptionField: 'maternity_paternity_leave_status',
  },
  {
    name_ja: '取得開始時期',
    name_en:
      'p_applicant_people_attributes[0].maternity_paternity_leave_start_time',
    condition: 'require_condition',
    dynamicType: 'date',
    options: {
      views: ['year', 'month'],
      // minDate: dayjs().toString(),
      // maxDate: dayjs().add(4, 'y').toString(),
      format: 'YYYY/MM/DD',
    },
  },
  {
    name_ja: '取得終了時期',
    name_en:
      'p_applicant_people_attributes[0].maternity_paternity_leave_end_time',
    condition: 'require_condition',
    dynamicType: 'date',
    options: {
      views: ['year', 'month'],
      // minDate: dayjs().toString(),
      // maxDate: dayjs().add(4, 'y').toString(),
      format: 'YYYY/MM/DD',
    },
  },
  {
    name_ja: '介護休暇の取得状況',
    name_en: 'p_applicant_people_attributes[0].nursing_leave_status',
    dynamicType: 'select',
    dynamicOptionField: 'nursing_leave_status',
  },
];

/**
 * STEP 5
 */
export const PERSONAL_RESIDENCE: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'PApplicantPerson',
  },
  {
    name_ja: '現在居住　居住年数（年）',
    name_en: 'p_applicant_people_attributes[0].lived_length_year_num',
    condition: 'require',
    dynamicType: 'selectLivedYear',
  },
  {
    name_ja: '現在居住　居住年数（ヶ月）',
    name_en: 'p_applicant_people_attributes[0].lived_length_month_num',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'lived_length_month_num',
  },
  {
    name_ja: '現在のお住まいの種類',
    name_en: 'p_applicant_people_attributes[0].current_residence',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'current_residence',
  },
  {
    name_ja: '現在のお住まいの床面積 (MCJ固有項目)',
    name_en: 'p_applicant_people_attributes[0].current_residence_floor_area',
    dynamicType: 'inputNumber',
    max: String(9999999.99).length,
  },
  {
    name_ja: '所有者の氏名',
    name_en: 'p_applicant_people_attributes[0].owner_full_name',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '続柄',
    name_en: 'p_applicant_people_attributes[0].owner_relationship',
    dynamicType: 'input',
  },
  {
    name_ja: '持家　処分方法',
    name_en: 'p_applicant_people_attributes[0].buyingand_selling_schedule_type',
    condition: 'require_condition',
    dynamicType: 'select',
    dynamicOptionField: 'buyingand_selling_schedule_type',
  },
  {
    name_ja: '持家　処分方法（その他）',
    name_en:
      'p_applicant_people_attributes[0].other_buyingand_selling_schedule_type',
    condition: 'require_condition',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '持家　売却予定価格',
    name_en: 'p_applicant_people_attributes[0].expected_house_selling_price',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN_4).length,
  },
  {
    name_ja: '持家　売却予定時期',
    name_en: 'p_applicant_people_attributes[0].scheduled_time_sell_house',
    dynamicType: 'date',
    options: {
      views: ['year', 'month'],
      format: 'YYYY/MM',
    },
  },
  {
    name_ja: '持家　ローン残高',
    name_en: 'p_applicant_people_attributes[0].current_home_loan',
    dynamicType: 'select',
    dynamicOptionField: 'current_home_loan',
  },
  {
    name_ja: '物件情報が掲載されたURL',
    name_en: 'property_information_url',
    dynamicType: 'input',
  },
  {
    name_ja: '新しい住居を必要とする理由',
    name_en: 'p_applicant_people_attributes[0].reason_acquire_home',
    dynamicType: 'select',
    dynamicOptionField: 'reason_acquire_home',
  },
  {
    name_ja: '新しい住居を必要とする理由（その他）',
    name_en: 'p_applicant_people_attributes[0].other_reason_acquire_home',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '新しい住居に、あなたは居住しますか？',
    name_en: 'person_occupancy',
    condition: 'require',
    dynamicType: 'selectPermanent',
    dynamicOptionField: 'person_occupancy',
  },
  {
    name_ja: '新しい住居に、居住しない理由',
    name_en: 'non_resident_reason',
    condition: 'require_condition',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: 'あなた以外の入居予定者',
    name_en: 'planned_cohabitant',
    dynamicType: 'selectMulti',
    dynamicOptionField: 'planned_cohabitant',
    subField: {
      name_en: 'other_relationship',
      dynamicType: 'input',
      dynamicOptionField: 'other_relationship',
    },
  },
  {
    name_ja: '新しい住居（融資対象物件）の事業性',
    name_en: 'business_ability',
    dynamicType: 'selectMulti',
    dynamicOptionField: 'business_ability',
  },
  {
    name_ja: '融資対象物件　郵便番号',
    name_en: 'property_postal_code',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.POSTAL_CODE,
  },
  {
    name_ja: '融資対象物件　都道府県',
    name_en: 'collateral_prefecture',
    condition: 'require',
    dynamicType: 'selectDistrict',
    dynamicOptionField: 'collateral_prefecture',
  },
  {
    name_ja: '融資対象物件　市区町村郡',
    name_en: 'collateral_city',
    condition: 'require',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '融資対象物件　以下地番',
    name_en: 'collateral_lot_number',
    condition: 'require',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '融資対象物件　マンション名・部屋番号',
    name_en: 'condominium_name',
    condition: 'require_condition',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '融資対象物件　物件所在地住所（フリガナ）',
    name_en: 'collateral_address_kana',
    isConvertFullWith: true,
    required: true,
    dynamicType: 'input',
  },
  {
    name_ja: '融資対象物件　土地の敷地面積',
    name_en: 'collateral_land_area',
    dynamicType: 'inputNumber',
    max: String(9999999.995).length,
  },
  {
    name_ja: '融資対象物件　専有面積',
    name_en: 'occupied_area',
    dynamicType: 'inputNumber',
    max: String(9999999.995).length,
  },
  {
    name_ja: '融資対象物件　建物の延べ床面積',
    name_en: 'collateral_total_floor_area',
    dynamicType: 'inputNumber',
    max: String(9999999.995).length,
  },
  {
    name_ja: '担保物件種類',
    name_en: 'collateral_type',
    condition: 'require',
    required: true,
    dynamicType: 'select',
    dynamicOptionField: 'collateral_type',
  },
  {
    name_ja: '土地取得時期',
    name_en: 'acquisition_time_of_the_land',
    required: true,
    dynamicType: 'date',
  },
  {
    name_ja: '共有区分',
    name_en: 'joint_ownership_division',
    required: true,
    dynamicType: 'select',
    dynamicOptionField: 'joint_ownership_division',
  },
  {
    name_ja: '建物割合分子',
    name_en: 'building_ratio_numerator',
    required: true,
    dynamicType: 'inputNumber',
    max: String(100).length,
  },
  {
    name_ja: '建物割合分母',
    name_en: 'building_ratio_denominator',
    required: true,
    dynamicType: 'inputNumber',
    max: String(100).length,
  },
  {
    name_ja: '土地割合分子',
    name_en: 'land_ratio_numerator',
    required: true,
    dynamicType: 'inputNumber',
    max: String(100).length,
  },
  {
    name_ja: '土地割合分母',
    name_en: 'land_ratio_denominator',
    required: true,
    dynamicType: 'inputNumber',
    max: String(100).length,
  },
  {
    name_ja: '購入物件の土地権利',
    name_en: 'land_ownership',
    dynamicType: 'select',
    dynamicOptionField: 'land_ownership',
  },
  {
    name_ja: '買戻・保留地・仮換地',
    name_en: 'purchase_purpose',
    dynamicType: 'select',
    dynamicOptionField: 'purchase_purpose',
  },
  {
    name_ja: '都市計画区域等',
    name_en: 'planning_area',
    dynamicType: 'select',
    dynamicOptionField: 'planning_area',
  },
  {
    name_ja: '都市計画区域（その他）',
    name_en: 'other_planning_area',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '再建築理由',
    name_en: 'rebuilding_reason',
    dynamicType: 'select',
    dynamicOptionField: 'rebuilding_reason',
  },
  {
    name_ja: '再建築理由（その他）',
    name_en: 'other_rebuilding_reason',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: 'フラット35S適用プラン',
    name_en: 'flat_35_applicable_plan',
    dynamicType: 'select',
    dynamicOptionField: 'flat_35_applicable_plan',
  },
  {
    name_ja: '維持保全型',
    name_en: 'maintenance_type',
    dynamicType: 'select',
    dynamicOptionField: 'maintenance_type',
  },
  {
    name_ja: 'フラット35S（優良住宅取得支援制度）',
    name_en: 'flat_35_application',
    condition: 'require_condition',
    dynamicType: 'select',
    dynamicOptionField: 'flat_35_application',
  },
  {
    name_ja: '地域連携型・地方移住支援型',
    name_en: 'region_type',
    dynamicType: 'select',
    dynamicOptionField: 'region_type',
  },
];

/**
 * step 1
 */
export const BORROWING_DETAIL: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'PApplicantPerson',
  },
  {
    name_ja: '申込日時',
    name_en: 'created_at',
    dynamicType: null,
    options: {
      format: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
      // minDate: dayjs().toString(),
      // maxDate: dayjs().add(4, 'y').toString(),
    },
  },
  {
    name_ja: '同意日',
    name_en: 'loan_apply_date',
    dynamicType: null,
    options: {
      // minDate: dayjs().toString(),
      // maxDate: dayjs().add(4, 'y').toString(),
    },
  },
  {
    name_ja: '入居予定年月',
    name_en: 'scheduled_date_moving',
    dynamicType: 'dateTime',
    options: {
      views: ['year', 'month'],
      format: 'YYYY/MM',
      minDate: dayjs().toString(),
      maxDate: dayjs().add(4, 'y').toString(),
    },
    dynamicOptionField: 'scheduled_date_moving',
  },
  {
    name_ja: '仮審査を申し込む金融機関の選択',
    name_en: 'master_bank_ids',
    condition: 'require',
    dynamicType: 'selectMulti',
    dynamicOptionField: 'master_bank_ids',
  },
  {
    name_ja: 'お借入の目的',
    name_en: 'loan_target_zero',
    condition: 'require',
    dynamicType: 'selectLoanTarget',
    dynamicOptionField: 'loan_target_zero',
  },
  {
    name_ja: '資金の使いみち',
    name_en: 'loan_target',
    condition: 'require_condition',
    dynamicType: 'select',
    dynamicOptionField: 'loan_target',
  },
  {
    name_ja: '「土地先行プラン」を希望ですか？',
    name_en: 'has_land_advance_plan',
    condition: 'require_condition',
    dynamicType: 'selectPermanent',
    dynamicOptionField: 'has_land_advance_plan',
  },
  {
    name_ja: 'お借入形態',
    name_en: 'loan_type',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'loan_type',
  },
  {
    name_ja: 'ペアローン　お名前（姓）',
    name_en: 'pair_loan_applicant_last_name',
    condition: 'require_condition',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: 'ペアローン　お名前（名）',
    name_en: 'pair_loan_applicant_first_name',
    condition: 'require_condition',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: 'ペアローン　続柄',
    name_en: 'pair_loan_relationship_name',
    condition: 'require_condition',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
    subField: {
      name_en: 'pair_loan_relationship',
      dynamicType: 'select',
      dynamicOptionField: 'relationship_to_applicant',
    },
  },
  {
    name_ja: 'お借入希望日',
    name_en: 'p_borrowing_details_attributes[0].loan_desired_borrowing_date',
    condition: 'require',
    dynamicType: 'date',
    options: {
      minDate: dayjs().toString(),
      maxDate: dayjs().add(4, 'y').toString(),
    },
  },
  {
    name_ja: 'お借入希望額',
    name_en: 'p_borrowing_details_attributes[0].temporary_desired_loan_amount',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: String(20000).length,
  },
  {
    name_ja: 'お借入内容　うち、ボーナス返済分',
    name_en: 'p_borrowing_details_attributes[0].halfyear_bonus',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: String(20000).length,
  },
  {
    name_ja: 'ボーナス返済月',
    name_en: 'p_borrowing_details_attributes[0].desired_monthly_bonus',
    condition: 'require_condition',
    dynamicType: 'select',
    dynamicOptionField: 'desired_monthly_bonus',
  },
  {
    name_ja: 'お借入内容　お借入期間（年）',
    name_en: 'p_borrowing_details_attributes[0].loan_term_year_num',
    condition: 'require',
    dynamicType: 'selectNumber',
    dynamicOptionField: 'loan_term_year_num',
  },
  // {
  //   name_ja: 'お借入内容　お借入期間（月）',
  //   name_en: 'p_borrowing_details_attributes[0].loan_term_month_num',
  //   dynamicType: 'selectNumber',
  //   dynamicOptionField: 'loan_term_month_num',
  // },
  {
    name_ja: 'お借入内容　返済方法',
    name_en: 'p_borrowing_details_attributes[0].repayment_method',
    condition: 'require',
    dynamicType: 'select',
    dynamicOptionField: 'repayment_method',
  },
  {
    name_ja: 'お借入希望日（2回目融資）',
    name_en: 'p_borrowing_details_attributes[1].loan_desired_borrowing_date',
    condition: 'require',
    dynamicType: 'date',
    options: {
      minDate: dayjs().toString(),
      maxDate: dayjs().add(4, 'y').toString(),
    },
  },
  {
    name_ja: 'お借入希望額（2回目融資）',
    name_en: 'p_borrowing_details_attributes[1].temporary_desired_loan_amount',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: String(20000).length,
  },
  {
    name_ja: 'うち、ボーナス返済分（2回目融資）',
    name_en: 'p_borrowing_details_attributes[1].halfyear_bonus',
    dynamicType: 'inputNumber',
    max: String(20000).length,
  },
  {
    name_ja: '担保提供者がいる方のみ、チェックをつけてください。',
    name_en: 'p_applicant_people_attributes[0].has_join_guarantor',
    dynamicType: 'selectPermanent',
    condition: 'require',
    requireSelect: true,
    dynamicOptionField: 'has_join_guarantor',
  },
  {
    name_ja: '住信SBIネット銀行の「住宅ローンプラス」の申し込みの有無',
    name_en: 'is_home_loan_plus',
    dynamicType: 'selectSBI',
    dynamicOptionField: 'is_home_loan_plus',
  },
];

export const PROPERTY_DETAIL: Omit<AdminPreliminaryItem, 'dynamicType'>[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
  },
  {
    name_ja: '完済原資（複数選択可）',
    name_en: 'completely_repayment_type',
  },
  {
    name_ja: '完済原資（その他）を選択した場合は具体的な内容を記入してください',
    name_en: 'other_complete_repayment_type',
  },
  {
    name_ja: '完済原資の内容（金融機関・貯金種類など）',
    name_en: 'refund_content',
  },
  {
    name_ja: '完済原資の金額合計',
    name_en: 'refund_amount',
  },
  {
    name_ja: '支払地代（今回の住宅取得後も継続するもの）',
    name_en: 'house_rent_to_be_paid',
  },
  {
    name_ja: '支払家賃（今回の住宅取得後も継続するもの）',
    name_en: 'land_rent_to_be_paid',
  },
  {
    name_ja: '物件の所在地',
    name_en: 'collateral_address',
  },
  {
    name_ja: 'マンション名（物件名）',
    name_en: 'mansion_name_kanji',
  },
  {
    name_ja: '土地 (面積（戸建ての場合）)',
    name_en: 'collateral_land_area',
  },
  {
    name_ja: '都市計画区域等（その他）',
    name_en: 'other_planning_area',
  },
  {
    name_ja: '再建築理由',
    name_en: 'rebuilding_reason',
  },
  {
    name_ja: '再建築理由（その他）',
    name_en: 'other_rebuilding_reason',
  },
  {
    name_ja: '建物（延べ床面積）(面積（戸建ての場合）)',
    name_en: 'occupied_area',
  },
];

export const REQUIRED_FUNDS_BREAKDOWN: Omit<
  AdminPreliminaryItem,
  'dynamicType'
>[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
  },
  {
    name_ja: '土地（戸建）',
    name_en: 'land_purchase_price',
  },
  {
    name_ja: '建物（戸建）または建売住宅 ',
    name_en: 'house_purchase_price',
  },
  {
    name_ja: '付帯設備',
    name_en: 'accessory_cost',
  },
  {
    name_ja: '諸経費等',
    name_en: 'additional_cost',
  },
  {
    name_ja: '借換対象ローン残債',
    name_en: 'refinancing_loan_balance',
  },
  {
    name_ja: '増改築',
    name_en: 'house_upgrade_cost',
  },
  {
    name_ja: '調達資金内訳 その他額',
    name_en: 'other_procurement_breakdown',
  },
  {
    name_ja: '調達資金内訳　その他名',
    name_en: 'other_procurement_breakdown_content',
  },
];

export const PROCUREMENT_BREAKDOWN: Omit<
  AdminPreliminaryItem,
  'dynamicType'
>[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
  },
  {
    name_ja: '自己資金合計',
    name_en: 'saving_amount',
  },
  {
    name_ja: '親族からの贈与',
    name_en: 'relative_donation_amount',
  },
  {
    name_ja: '本件ローン',
    name_en: 'loan_amount',
  },
  {
    name_ja: 'ペアローン',
    name_en: 'pair_loan_amount',
  },
  {
    name_ja: '調達資金内訳 その他額',
    name_en: 'other_procurement_breakdown',
  },
  {
    name_ja: '調達資金内訳　その他名',
    name_en: 'other_procurement_breakdown_content',
  },
];

export const REFERRAL_AGENCY: Omit<AdminPreliminaryItem, 'dynamicType'>[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
  },
  {
    name_ja: '提携会社（不動産会社・住宅メーカー等）',
    name_en: 'name_kanji',
  },
  {
    name_ja: '支店名',
    name_en: 'store_name_kanji',
  },
  {
    name_ja: '担当者名',
    name_en: 'manager_name_kanji',
  },
  {
    name_ja: '販売代理会社（該当する場合）',
    name_en: 'sale_agent_name_kanji',
  },
  {
    name_ja: '担当者直通番号',
    name_en: 'phone_number_2',
  },
  {
    name_ja: '担当者携帯番号',
    name_en: 'phone_number',
  },
  {
    name_ja: '担当者メールアドレス',
    name_en: 'manager_email',
  },
];

/**
 * step 4
 */
export const GUARANTEE_PROVIDER: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'p_applicant_persons',
  },
  {
    name_ja: '担保提供者の氏名（姓）',
    name_en: 'last_name_kanji',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '担保提供者の氏名（名）',
    name_en: 'first_name_kanji',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '担保提供者の氏名（姓）（フリガナ）',
    name_en: 'last_name_kana',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '担保提供者の氏名（名）（フリガナ）',
    name_en: 'first_name_kana',
    condition: 'require_sp',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '性別',
    name_en: 'sex',
    dynamicType: 'select',
    dynamicOptionField: 'sex',
  },
  {
    name_ja: '続柄',
    name_en: 'guarantor_relationship_name',
    isConvertFullWith: true,
    dynamicType: 'input',
    subField: {
      name_en: 'guarantor_relationship_to_applicant',
      dynamicType: 'select',
      dynamicOptionField: 'guarantor_relationship_to_applicant',
      subFieldName: 'other_relationship_to_applicant',
    },
  },
  {
    name_ja: '生年月日',
    name_en: 'birthday',
    condition: 'require_sp',
    dynamicType: 'date',
    options: {
      minDate: dayjs().subtract(100, 'y').toString(),
      maxDate: dayjs().subtract(18, 'y').toString(),
    },
  },
  {
    name_ja: '電話番号携帯',
    name_en: 'mobile_phone_number',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.MOBILE_PHONE,
  },
  {
    name_ja: '電話番号自宅',
    name_en: 'home_phone_number',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.HOME_PHONE,
  },
  {
    name_ja: '緊急連絡先',
    name_en: 'emergency_contact_number',
    required: true,
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.MOBILE_PHONE,
  },
  {
    name_ja: '郵便番号',
    name_en: 'postal_code',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.POSTAL_CODE,
  },
  {
    name_ja: '都道府県',
    name_en: 'prefecture_kanji',
    condition: 'require_sp',
    dynamicType: 'selectDistrict',
    dynamicOptionField: 'prefecture_kanji',
  },
  {
    name_ja: '市区郡',
    name_en: 'city_kanji',
    condition: 'require_sp',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '町村丁目',
    name_en: 'district_kanji',
    condition: 'require_sp',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '丁目以下・建物名・部屋番号',
    name_en: 'other_address_kanji',
    condition: 'require',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '都道府県（フリガナ）',
    name_en: 'prefecture_kana',
    isConvertFullWith: true,
    required: true,
    dynamicType: 'input',
  },
  {
    name_ja: '市区郡（フリガナ）',
    name_en: 'city_kana',
    required: true,
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '町村丁目（フリガナ）',
    name_en: 'district_kana',
    required: true,
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '丁目以下・建物名・部屋番号（フリガナ）',
    name_en: 'other_address_kana',
    required: true,
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: 'メールアドレス',
    name_en: 'owner_email',
    required: true,
    dynamicType: 'input',
  },
];

export const HEAD_CURRENT_BORROWING: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'head_current_borrowing',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
  },
  {
    name_ja: 'あなたや連帯保証人予定者に、現在お借入はありますか？',
    name_en: 'borrowing_status_local',
    dynamicType: 'selectBorrowingStatus',
    dynamicOptionField: 'borrowing_status',
  },
];

export const CURRENT_BORROWING: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
  },
  {
    name_ja: '借入名義人',
    name_en: 'borrower',
    dynamicType: 'select',
    dynamicOptionField: 'borrower',
  },
  {
    name_ja: 'お借入の種類は？',
    name_en: 'borrowing_type',
    dynamicType: 'select',
    dynamicOptionField: 'borrowing_type',
  },
  {
    name_ja: '借入先（金融機関）',
    name_en: 'lender',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '住宅金融支援機構からの借入',
    name_en: 'borrowing_from_housing_finance_agency',
    dynamicType: 'select',
    dynamicOptionField: 'borrowing_from_housing_finance_agency',
  },
  {
    name_ja: 'お借入の目的',
    name_en: 'loan_purpose',
    dynamicType: 'select',
    dynamicOptionField: 'loan_purpose',
  },
  {
    name_ja: 'お借入の目的',
    name_en: 'business_borrowing_type',
    dynamicType: 'select',
    dynamicOptionField: 'business_borrowing_type',
  },
  {
    name_ja: 'お借入の目的（その他）',
    name_en: 'specific_loan_purpose',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: 'お借入の目的（その他）',
    name_en: 'other_purpose',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '借入区分',
    name_en: 'borrowing_category',
    dynamicType: 'select',
    dynamicOptionField: 'borrowing_category',
  },
  {
    name_ja: '当初借入年月',
    name_en: 'loan_start_date',
    condition: 'require_condition',
    dynamicType: 'date',
    options: {
      views: ['year', 'month'],
      format: 'YYYY/MM',
      maxDate: dayjs().toString(),
      minDate: dayjs().subtract(50, 'y').toString(),
    },
  },
  {
    name_ja: '借入限度額',
    name_en: 'loan_amount',
    condition: 'require_condition',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '現在の残高',
    name_en: 'current_balance_amount',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '年間返済額',
    name_en: 'annual_repayment_amount',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: 'カード有効期限',
    name_en: 'card_expiry_date',
    dynamicType: 'date',
    options: {
      views: ['year', 'month'],
      format: 'YYYY/MM',
      minDate: dayjs().toString(),
      maxDate: dayjs().add(50, 'y').toString(),
    },
  },
  {
    name_ja: '契約期限、最終期限／最終返済年月',
    name_en: 'loan_deadline_date',
    dynamicType: 'date',
    options: {
      views: ['month', 'year'],
      format: 'YYYY/MM',
      minDate: dayjs().toString(),
      maxDate: dayjs().add(50, 'y').toString(),
    },
  },
  {
    name_ja: '賃貸戸（室）数',
    name_en: 'rental_room_number',
    dynamicType: 'inputNumber',
    max: 2,
  },
  {
    name_ja: '共同住宅',
    name_en: 'common_housing',
    dynamicType: 'select',
    dynamicOptionField: 'common_housing',
  },
  {
    name_ja: '不動産担保設定',
    name_en: 'estate_mortgage',
    dynamicType: 'select',
    dynamicOptionField: 'estate_mortgage',
  },
  {
    name_ja: '今回のお借入までに完済の予定はありますか？',
    name_en: 'scheduled_loan_payoff',
    dynamicType: 'select',
    dynamicOptionField: 'scheduled_loan_payoff',
  },
  {
    name_ja: '完済（予定）年月',
    name_en: 'expected_repayment_date',
    dynamicType: 'date',
    options: {
      views: ['month', 'year'],
      format: 'YYYY/MM',
      minDate: dayjs().toString(),
      maxDate: dayjs().add(4, 'y').toString(),
    },
  },
  {
    name_ja: '審査に含める/含めない',
    name_en: 'include_in_examination',
    required: true,
    dynamicType: 'selectPermanent',
    dynamicOptionField: 'include_in_examination',
  },
];

export const SUB_CURRENT_BORROWING: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'sub_current_borrowing',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
  },
  {
    name_ja: '完済原資の種類',
    name_en: 'completely_repayment_type',
    condition: 'require_condition',
    dynamicType: 'select',
    dynamicOptionField: 'completely_repayment_type',
  },
  {
    name_ja: '完済原資の種類（その他）',
    name_en: 'other_complete_repayment_type',
    dynamicType: 'input',
    isConvertFullWith: true,
  },
  {
    name_ja: '完済原資の内容',
    name_en: 'refund_content',
    isConvertFullWith: true,
    dynamicType: 'input',
  },
  {
    name_ja: '完済原資の金額',
    name_en: 'refund_amount',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN_4).length,
  },
  {
    name_ja: '今回の住宅取得後も継続する支払地代',
    name_en: 'p_applicant_people_attributes[0].land_rent_to_be_paid',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.YEN).length,
  },
  {
    name_ja: '今回の住宅取得後も継続する支払家賃',
    name_en: 'p_applicant_people_attributes[0].house_rent_to_be_paid',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.YEN).length,
  },
];

export const FINANCING_PLAN: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'PApplicationHeaders',
  },
  {
    name_ja: '土地',
    name_en: 'land_purchase_price',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '物件価格／マンション価格',
    name_en: 'house_purchase_price',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '付帯設備',
    name_en: 'accessory_cost',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '諸費用等',
    name_en: 'additional_cost',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '住宅ローンプラス利用',
    name_en: 'require_funds_breakdown_mortgage',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '借換対象ローン残債',
    name_en: 'refinancing_loan_balance',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '増改築費',
    name_en: 'house_upgrade_cost',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '土地価格',
    name_en: 'land_price',
    required: true,
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '建物価格',
    name_en: 'building_price',
    required: true,
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '合計価格',
    name_en: 'land_and_building_price',
    required: true,
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '必要資金　合計',
    name_en: 'total_price_1',
    dynamicType: null,
  },
  {
    name_ja: '預貯金',
    name_en: 'deposit_savings_1',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '不動産売却代金',
    name_en: 'real_estate_sale_price',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '有価証券売却など',
    name_en: 'other_saving_amount',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '自己資金',
    name_en: 'saving_amount',
    condition: 'require',
    required: true,
    dynamicType: null,
  },
  {
    name_ja: 'その他の借り入れ',
    name_en: 'amount_any_loans',
    condition: 'require',
    required: true,
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '親族からの贈与',
    name_en: 'relative_donation_amount',
    condition: 'require',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '本件ローン',
    name_en: 'loan_amount',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: 'ペアローン',
    name_en: 'pair_loan_amount',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: 'その他',
    name_en: 'other_procurement_breakdown',
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '※詳細を入力ください。',
    name_en: 'other_procurement_breakdown_content',
    condition: 'require_condition',
    dynamicType: 'input',
  },
  {
    name_ja: 'その他（借換）',
    name_en: 'amount_others',
    required: true,
    dynamicType: 'inputNumber',
    max: String(VALIDATE_MAX.MAN_YEN).length,
  },
  {
    name_ja: '調達資金　合計',
    name_en: 'total_price_2',
    dynamicType: null,
  },
];

export const CONTACT_INFORMATION: AdminPreliminaryItem[] = [
  {
    name_ja: 'ID',
    name_en: 'id',
    dynamicType: null,
  },
  {
    name_ja: 'テーブル名',
    name_en: 'table_name',
    dynamicType: null,
    value: 'PApplicationHeaders',
  },
  {
    name_ja: '提携会社',
    name_en: 'sale_person_information.active_agency_id',
    dynamicType: 'selectSalePerson',
    dynamicOptionField: 'sale_agent_name_kanji',
  },
  {
    name_ja: 'エリア',
    name_en: 'sale_person_information.active_store_id',
    dynamicType: 'selectSalePerson',
    dynamicOptionField: 'store_name_kanji',
  },
  {
    name_ja: '営業所・展示場',
    name_en: 'sale_person_information.active_exhibition_id',
    dynamicType: 'selectSalePerson',
    dynamicOptionField: 'exhibition_hall',
  },
  {
    name_ja: '担当者名 (情報共有者)',
    name_en: 's_sale_person_id',
    dynamicType: 'selectSalePerson',
    dynamicOptionField: 's_sale_person_id',
  },
  {
    name_ja: '担当者名',
    name_en: 'sale_person_name_input',
    dynamicType: 'input',
  },
  {
    name_ja: '携帯電話番号',
    name_en: 'sale_person_phone_number',
    dynamicType: 'inputNumber',
    max: VALIDATE_MAX.MOBILE_PHONE,
  },
  {
    name_ja: '携帯電話番号（マスターデータ）',
    name_en: 'sale_person_information.sale_person_phone',
    dynamicType: null,
  },
  {
    name_ja: 'メールアドレス',
    name_en: 'sale_person_information.sale_person_email',
    dynamicType: null,
  },
];

export const BANK_NAMES = [
  'みらいバンク支店',
  '申込人',
  '住信SBIネット銀行',
  'みらいバンク支店',
];

export const RESULT_STATUS = [
  {
    name: '書類確認中',
  },
  {
    name: '書類不備対応中',
  },
  {
    name: '仮審査中',
  },
  {
    name: '提携会社へ仮審査結果公開',
  },
];

export enum PreliminariesStatus {
  CURRENT = 'current',
  INPROGRESS = 'inProgress',
  PAST = 'past',
}

export enum FILTER_MODAL_TYPE {
  PREVIEW = 'project_under_provisional_review',
  PAST = 'past_project',
}

export enum SenderType {
  USER = 'User',
  MANAGER = 'Manager',
  SALEPERSON = 'SSalePerson',
}

export enum StatusResult {
  DOCUMENT_CONFIRMATION = '0',
  DOCUMENT_DEFICIENCIES_IN_PROGRESS = '1',
  CONTENT_CONFIRMATION = '2',
  APPROVAL = '3',
  DATA_LINKAGE_TO_BANKS = '4',
  DISCLOSURE_RESULTS_TO_PARTNER_COMPANIES = '5',
  DISCLOSURE_RESULTS_TO_APPLICANTS = '6',
}

export const DisplayPdfStatus = {
  DEFAULT: null || '',
  NO: '0',
  YES: '1',
};

export const statusResult: Record<StatusResult, string> = {
  [StatusResult.DOCUMENT_CONFIRMATION]: '書類確認',
  [StatusResult.DOCUMENT_DEFICIENCIES_IN_PROGRESS]: '書類不備対応中',
  [StatusResult.CONTENT_CONFIRMATION]: '内容確認',
  [StatusResult.APPROVAL]: '承認',
  [StatusResult.DATA_LINKAGE_TO_BANKS]: '銀行へデータ連携',
  [StatusResult.DISCLOSURE_RESULTS_TO_PARTNER_COMPANIES]:
    '提携会社へ審査結果公開',
  [StatusResult.DISCLOSURE_RESULTS_TO_APPLICANTS]: '申込人へ審査結果公開',
};

export enum ChangeEmailErrorCode {
  UNKNOWN = 0,
  CURRENT_INCORRECT = 1,
  EMAIL_CONFIRM_NOT_MATCH = 2,
  TAKEN = 3,
  USER_NOT_FOUND = 4,
  TOKEN_EXPIRED = 5,
  NEW_EMAIL_IS_SAME = 6,
}

export enum ModalName {
  ABOUT_PAIR_LOAN = 'about_pair_loan',
  ABOUT_COMBINED_INCOME = '収入合算とは',
  DIFFERENCE = 'difference',
  ABOUT_COLLATERAL_PROVIDER = 'about_collateral_provider',
  ABOUT_MORTGAGE_PLUS = 'about_mortgage_plus',
  ABOUT_LAND_ADVANCE_PLAN = 'about_land_advance_plan',
  JOINT_GUARANTOR = 'joint_guarantor',
  CALCULATOR = 'calculator',
}

export const modalName: Record<ModalName, string> = {
  [ModalName.ABOUT_PAIR_LOAN]: 'ペアローンとは？',
  [ModalName.ABOUT_COMBINED_INCOME]: '収入合算とは？',
  [ModalName.DIFFERENCE]: '元利均等と元金均等の違い',
  [ModalName.ABOUT_COLLATERAL_PROVIDER]: '担保提供者とは？',
  [ModalName.ABOUT_MORTGAGE_PLUS]: '住宅ローンプラスとは？',
  [ModalName.ABOUT_LAND_ADVANCE_PLAN]: '土地先行プランとは？',
  [ModalName.JOINT_GUARANTOR]: '',
  [ModalName.CALCULATOR]: 'ボーナス返済分を試算する',
};

export enum ChangePasswordErrorType {
  UNKNOWN = 'unknown',
  CURRENT_INCORRECT = 'incorrect', //use in 3 roles (manager, saleperson, SP)
  // CURRENT_INCORRECT_MANAGER = 'incorrect',
  PASSWORD_TOO_SHORT = 'too_short',
  WRONG_FORMAT_PASSWORD = 'wrong_format_pw',
  BLANK = 'blank',
  CONFIRMATION = 'confirmation',
}

export const changePasswordErrorMessage: Record<
  ChangePasswordErrorType,
  string
> = {
  [ChangePasswordErrorType.UNKNOWN]:
    'サーバー内部でエラーが発生しました。 再度お試し下さい。',
  [ChangePasswordErrorType.CURRENT_INCORRECT]:
    '現在のパスワードが正しくありません。再度ご確認ください。',
  [ChangePasswordErrorType.PASSWORD_TOO_SHORT]:
    'パスワードは8桁以上入力してください。',
  [ChangePasswordErrorType.WRONG_FORMAT_PASSWORD]:
    'パスワードはパスワードポリシーの要件を満たしていません。',
  [ChangePasswordErrorType.BLANK]: '新しいパスワードを入力してください。',
  [ChangePasswordErrorType.CONFIRMATION]:
    '新しいパスワードとパスワード（確認用）が一致していません。',
  // [ChangePasswordErrorType.CURRENT_INCORRECT_MANAGER]:
  //   '有効なパスワードを入力してください。再度ご確認ください。',
};

export enum PreliminaryStatus {
  PROGRESS_PRELIMINARY = 'progress_preliminary',
  PAST_PRELIMINARY = 'past_preliminary',
  PROVISIONAL_PRELIMINARY = 'provisional_preliminary',
}

export enum SPTaxReturnType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}
export const spFinalTaxReturn: Record<SPTaxReturnType, string> = {
  [SPTaxReturnType.DEFAULT]: '',
  [SPTaxReturnType.YES]: 'はい',
  [SPTaxReturnType.NO]: 'いいえ',
};

export enum BorrowingStatusType {
  DEFAULT = '',
  NO = '0',
  YES = '1',
}

export const borrowingStatus: Record<BorrowingStatusType, string> = {
  [LoanBalanceType.DEFAULT]: '',
  [LoanBalanceType.NO]: '無',
  [LoanBalanceType.YES]: '有',
};

export enum BorrowingStatusAdminType {
  DEFAULT = '',
  NO = '0',
  YES = '1',
}

export const borrowingStatusAdmin: Record<BorrowingStatusAdminType, string> = {
  [BorrowingStatusAdminType.DEFAULT]: '',
  [BorrowingStatusAdminType.NO]: '無し',
  [BorrowingStatusAdminType.YES]: '有り',
};

export const mainIncomeSource: Record<SourceOfIncomeType, string> = {
  [SourceOfIncomeType.DEFAULT]: '',
  [SourceOfIncomeType.SALARY_FIXED_SALARY]: '給与（固定給）',
  [SourceOfIncomeType.SALARY_COMMISSION_PAY]: '給与（歩合給）',
  [SourceOfIncomeType.SALARY_ANNUAL_SALARY_SYSTEM]: '給与（年俸制）',
  [SourceOfIncomeType.BUSINESS_INCOME]: '事業収入',
  [SourceOfIncomeType.REAL_ESTATE_INCOME]: '不動産収入',
};

export enum HasLandAdvancePlanType {
  DEFAULT = '',
  NO = '0',
  YES = '1',
}

export enum PreliminaryStatusType {
  DEFAULT = '',
  PROGRESS_PRELIMINARY = 'progress_preliminary',
  PAST_PRELIMINARY = 'past_preliminary',
  PROVISIONAL_PRELIMINARY = 'provisional_preliminary',
}

export enum IsHomeLoanPlus {
  DEFAULT = '',
  NO = 0,
  YES = 1,
}

export const isHomeLoanPlus: Record<IsHomeLoanPlus, string> = {
  [IsHomeLoanPlus.DEFAULT]: '',
  [IsHomeLoanPlus.YES]: '申し込む',
  [IsHomeLoanPlus.NO]: '申し込まない',
};

export const hasLandAdvancePlan: Record<HasLandAdvancePlanType, string> = {
  [HasLandAdvancePlanType.DEFAULT]: '',
  [HasLandAdvancePlanType.NO]: '希望しない',
  [HasLandAdvancePlanType.YES]: '希望する',
};

export enum SPBorrowingType {
  DEFAULT = '',
  HOUSING_LOAN = '1',
  CARD_LOAN = '2',
  APARTMENT_LOAN = '3',
  BUSINESS_LOAN = '4',
  CAR_LOAN = '5',
  EDUCATION_LOAN = '6',
  LOAN_FOR_LIVING_EXPENSES = '7',
  OTHERS = '99',
}

export enum SPScheduledLoanPayoffType {
  DEFAULT = '',
  SCHEDULED_TO_REPAY = '1',
  NOT_SCHEDULED_TO_REPAY = '2',
  REPAID_BY_USING_MORTGAGE_PLUS = '3',
}

export enum SPLoanPurposeType {
  DEFAULT = '',
  WORKING_CAPITAL = '1',
  EQUIPMENT_CAPITAL = '2',
  LEASING = '3',
  OTHERS = '99',
}

export enum SPBorrowingCategoryType {
  DEFAULT = '',
  SHOPPING = '1',
  CASH_CACHING = '2',
  CARD_LOAN = '3',
}

export enum OneRoofType {
  DEFAULT = '',
  NO = '0',
  YES = '1',
}

export const STATUS_PHASE_ID = [
  'PP003',
  'PP060',
  'PP061',
  'PP062',
  'PP063',
  'PP004',
  'PP069',
  'PP006',
  'PP043',
  'PP005',
  'PP035',
  'PP036',
  'PP007',
  'PP008',
  'PP009',
  'PP010',
  'PP011',
  'PP091',
  'PP092',
  'PP093',
  'PP094',
  'PP095',
  'PP096',
  'PP103',
  'PP012',
  'PP013',
];

export const PROVISIONAL_RESULT = ['承認', '条件付き承認', '否決'];

export enum SPBusinessCardType {
  DEFAULT = '',
  YES = '1',
  NO = '0',
}
export const spBusinessCard: Record<SPTaxReturnType, string> = {
  [SPBusinessCardType.DEFAULT]: '',
  [SPBusinessCardType.YES]: 'はい',
  [SPBusinessCardType.NO]: 'いいえ\n（自分で入力）',
};

export const oneRoof: Record<OneRoofType, string> = {
  [OneRoofType.DEFAULT]: '',
  [OneRoofType.NO]: '無し',
  [OneRoofType.YES]: '有り',
};

// @ts-ignore
export const occupationDetail: Record<OccupationDetailType, string> = {
  [OccupationDetailType.DEFAULT]: '',
  [OccupationDetailType.DOCTOR]: '',
  [OccupationDetailType.DENTIST]: '',
  [OccupationDetailType.LAWYER]: '',
};

// @ts-ignore
export const transferOffice: Record<DispatchType, string> = {
  [DispatchType.NOTHING]: 'いいえ',
  [DispatchType.YES]: 'はい',
};

// @ts-ignore
export const coOwner: Record<CoOwnerType, string> = {
  [CoOwnerType.CANBE]: 'いる',
  [CoOwnerType.NONE]: '無し',
};

export const occupation: Record<OccupationType, string> = {
  [OccupationType.DEFAULT]: '',
  [OccupationType.ONE]: '会社員（経営者）',
  [OccupationType.TWO]: '会社員（管理職）',
  [OccupationType.THREE]: '会社員（一般職）',
  [OccupationType.FOUR]: '教職員',
  [OccupationType.FIVE]: '自営業',
  [OccupationType.SIX]: '契約社員',
  [OccupationType.SEVEN]: '派遣社員・嘱託（１年以上）',
  [OccupationType.EIGHT]: '派遣社員・嘱託（契約期間1年未満）',
  [OccupationType.NINE]: '公務員・団体職員',
  [OccupationType.TEN]: '農業漁業主',
  [OccupationType.ELEVEN]: 'パートアルバイト',
  [OccupationType.TWELVE]: '年金受給者',
  [OccupationType.OTHERS]: 'その他',
};

export const workIndustry: Record<WorkIndustryType, string> = {
  [WorkIndustryType.DEFAULT]: '',
  [WorkIndustryType.MANUFACTURING_INDUSTRY]: '製造業',
  [WorkIndustryType.AGRICULTURE]: '農業',
  [WorkIndustryType.FORESTRY]: '林業',
  [WorkIndustryType.FISHERY]: '漁業',
  [WorkIndustryType.MINING]: '鉱業',
  [WorkIndustryType.CONSTRUCTION_INDUSTRY]: '建設業',
  [WorkIndustryType.WHOLE_SALE_RETAIL]: '卸売・小売業',
  [WorkIndustryType.FINANCE_INSURANCE]: '金融業',
  [WorkIndustryType.INSURANCE_BUSINESS]: '保険業',
  [WorkIndustryType.REAL_ESTATE_BUSINESS]: '不動産業',
  [WorkIndustryType.TRANSPORTATION_INDUSTRY]: '運輸業',
  [WorkIndustryType.ELECTRICITY_GAS_HEAT_SUPPLY_WATER]:
    '電気・ガス・熱供給・水道',
  [WorkIndustryType.DINING_ACCOMODATION]: '飲食・宿泊',
  [WorkIndustryType.MEDICAL_CARE_AND_WELFARE]: '医療・福祉',
  [WorkIndustryType.EDUCATION_AND_LEARNING_SUPPORT]: '教育・学習支援',
  [WorkIndustryType.OTHER_SERVICE_INDUSTRY]: 'その他サービス業',
  [WorkIndustryType.PUBLIC_AFFAIRS]: '公務',
  [WorkIndustryType.INFORMATION_AND_COMMUNICATION_INDUSTRY]: '情報通信業',
  [WorkIndustryType.COMPLEX_SERVICE_NDUSTRY]: '複合サービス業',
  [WorkIndustryType.OTHERS]: 'その他',
};

export const KEYS_FOR_PREVIEW_FILES = [
  {
    title: '本人確認書類',
    key: [
      'driver_license_front_image',
      'driver_license_back_image',
      'card_number_front_image',
      'resident_register_front_image',
      'resident_register_back_image',
    ],
    subTitle: [
      {
        key: 'driver_license_front_image',
        title: '運転免許書〈表面〉',
      },
      { key: 'driver_license_back_image', title: '運転免許書〈裏面〉' },
      { key: 'card_number_front_image', title: 'マイナンバーカード〈表面〉' },
      {
        key: 'resident_register_front_image',
        title: '住民基本台帳カード〈表面〉',
      },
      {
        key: 'resident_register_back_image',
        title: '住民基本台帳カード〈裏面〉',
      },
    ],
  },
  {
    title: '健康保険証',
    key: ['insurance_file', 'insurance_file_back_image'],
    subTitle: [
      { key: 'insurance_file', title: '健康保険証〈表面〉' },
      { key: 'insurance_file_back_image', title: '健康保険証〈裏面〉' },
    ],
  },
  {
    title: '収入に関する書類',
    key: [
      'first_withholding_slip_file',
      'second_withholding_slip_file',
      'first_income_file',
      'second_income_file',
      'third_income_file',
    ],
    subTitle: [
      { key: 'first_withholding_slip_file', title: '源泉徴収票（前年度分）' },
      {
        key: 'second_withholding_slip_file',
        title: '源泉徴収票（前々年度分）',
      },
      { key: 'first_income_file', title: '確定申告書（1期前）' },
      { key: 'second_income_file', title: '確定申告書（2期前）' },
      { key: 'third_income_file', title: '確定申告書（3期前）' },
    ],
  },
  {
    title: '非上場企業の役員の方の書類',
    key: [
      'financial_statement_1_file',
      'financial_statement_2_file',
      'financial_statement_3_file',
    ],
    subTitle: [
      { key: 'financial_statement_1_file', title: '会社の決算報告書（1期前）' },
      {
        key: 'financial_statement_2_file',
        title: '会社の決算報告書（2期前）',
      },
      { key: 'financial_statement_3_file', title: '会社の決算報告書（3期前）' },
    ],
  },
  {
    title: '雇用契約に関する書類',
    key: ['employment_agreement_file'],
    subTitle: [{ key: 'employment_agreement_file', title: '雇用契約書' }],
  },
  {
    title: '親族経営の会社等にご勤務の方に関する書類',
    key: [
      'business_tax_return_1_file',
      'business_tax_return_2_file',
      'business_tax_return_3_file',
    ],
    subTitle: [
      {
        key: 'business_tax_return_1_file',
        title: '会社の決算報告書または経営する親族の確定申告書（1期前）',
      },
      {
        key: 'business_tax_return_2_file',
        title: '会社の決算報告書または経営する親族の確定申告書（2期前）',
      },
      {
        key: 'business_tax_return_3_file',
        title: '会社の決算報告書または経営する親族の確定申告書（3期前）',
      },
    ],
  },
  {
    title: '物件についての書類',
    key: ['property_information_file'],
    subTitle: [
      {
        key: 'property_information_file',
        title: 'チラシ・パンフレット',
      },
      // {
      //   key: 'manager_property_information_file',
      //   title: '物件書類（登記簿謄本等）',
      // },
    ],
  },
  {
    title: '在留カード',
    key: ['residence_file', 'residence_file_back_image'],
    subTitle: [
      {
        key: 'residence_file',
        title: '在留カード 〈表面〉',
      },
      {
        key: 'residence_file_back_image',
        title: '在留カード 〈裏面〉',
      },
    ],
  },
  {
    title: '返済予定表・利用明細書',
    key: ['repayment_schedule_image'],
  },
  {
    title: '提携会社の担当者名刺',
    key: ['business_card'],
  },
  {
    title: 'その他',
    key: ['other_document_file'],
  },
];

export const PARAMS_FILE_UPLOAD_ONE_FILE = [
  'card_number_back_image',
  'card_number_front_image',
  'driver_license_front_image',
  'driver_license_back_image',
  'resident_register_back_image',
  'resident_register_front_image',
];

export const FIELDS_VALIDATE: Record<string, string> = {
  loan_target_zero: 'お借入の目的',
  collateral_prefecture: '融資対象物件　都道府県',
  person_occupancy: '新しい住居に、あなたは居住しますか？',
  business_ability: '新しい住居（融資対象物件）の事業性',
  collateral_lot_number: '融資対象物件　以下地番',
  collateral_city: '融資対象物件　市区町村郡',
  property_postal_code: '融資対象物件　郵便番号',
  collateral_type: '担保物件種類',
  acquisition_time_of_the_land: '土地取得時期',
  joint_ownership_division: '共有区分',
  building_ratio_numerator: '建物割合分子',
  building_ratio_denominator: '建物割合分母',
  land_ratio_numerator: '土地割合分子',
  land_ratio_denominator: '土地割合分母',
  planned_cohabitant: 'あなた以外の入居予定者',
  collateral_land_area: '土地 (面積（戸建ての場合）)',
  occupied_area: '専有面積',
  loan_apply_date: '申込日時',
  scheduled_date_moving: '入居予定年月',
  loan_target: 'お借入の目的',
  has_land_advance_plan: '土地先行プラン希望',
  loan_type: 'お借入形態',
  pair_loan_applicant_last_name: 'ペアローン　お名前（姓）',
  pair_loan_applicant_first_name: 'ペアローン　お名前（名）',
  pair_loan_relationship_name: 'ペアローン　続柄（入力）',
  pair_loan_relationship: 'ペアローン　続柄（プルダウン）',
  loan_desired_borrowing_date: 'お借入希望日',
  temporary_desired_loan_amount: 'お借入希望額',
  halfyear_bonus: 'うち、ボーナス返済分',
  desired_monthly_bonus: 'ボーナス返済月',
  loan_term_year_num: 'お借入期間 年',
  debt_payment_age_over_80_years_old:
    'お借入期間 借入時満18歳以上満65歳以下・完済時満80歳未満の方がご利用いただけます。',
  second_loan_desired_borrowing_date:
    '２回目の融資のお借入希望日は１回目の融資のお借入希望日以降で選択してください。',
  borrowing_homeloan: '借換対象のデータがありあません。',
  loan_term_month_num: 'お借入期間 ヶ月',
  repayment_method: '返済方法',
  last_name_kanji: 'お名前（姓）',
  first_name_kanji: 'お名前（名）',
  last_name_kana: 'お名前（姓）（フリガナ）',
  first_name_kana: 'お名前（名）（フリガナ）',
  sex: '性別',
  name_relationship_to_applicant: '続柄',
  birthday: '生年月日',
  nationality: '現在の国籍',
  mobile_phone_number: '電話番号携帯',
  home_phone_number: '電話番号自宅',
  postal_code: '郵便番号',
  prefecture_kanji: '都道府県',
  city_kanji: '市区郡',
  district_kanji: '町村丁目',
  other_address_kanji: '丁目以下・建物名・部屋番号',
  owner_email: 'ご連絡先用メールアドレス',
  emergency_contact_number: '緊急連絡先',
  prefecture_kana: '都道府県（フリガナ）',
  city_kana: '市区郡（フリガナ）',
  district_kana: '町村丁目（フリガナ）',
  other_address_kana: '丁目以下・建物名・部屋番号（フリガナ）',
  occupation: 'ご職業',
  other_occupation: '勤務先　職業（その他）',
  industry: '業種',
  other_industry: '勤務先　業種（その他）',
  occupation_detail: '職種',
  office_name_kana: '勤務先名（フリガナ）',
  other_occupation_detail: '勤務先　職種（その他）',
  office_name_kanji: '勤務先名',
  department: '所属部課',
  office_phone_number: '勤務先の電話番号',
  office_postal_code: '郵便番号',
  office_prefecture_kanji: '都道府県',
  office_city_kanji: '市区郡',
  office_district_kanji: '町村丁目',
  number_of_employee: '従業員数',
  employment_started_date: '入社年月',
  last_year_income: '前年度年収',
  bonus_income: 'うち、ボーナス',
  two_years_ago_income: '前々年度年収 （MCJ固有項目）',
  income_source: '収入源',
  main_income_source: '収入源（銀行送信用）',
  tax_return: '確定申告をしていますか？',
  type_tax_return: '確定申告の理由',
  other_type_tax_return: '確定申告の理由　その他',
  transfer_office: '現在、出向（派遣）していますか？',
  transfer_office_name_kanji: '出向（派遣）勤務先名',
  transfer_office_name_kana: '出向（派遣）勤務先名（フリガナ）',
  transfer_office_phone_number: '出向（派遣）先 電話番号',
  transfer_office_postal_code: '出向（派遣）先 郵便番号',
  transfer_office_prefecture_kanji: '出向（派遣）先 都道府県',
  transfer_office_city_kanji: '出向（派遣）先 市区町村郡',
  transfer_office_district_kanji: '出向（派遣）先 以下住所',
  transfer_office_other_address_kanji:
    '出向（派遣）先 丁目以下・建物名・部屋番号',
  maternity_paternity_leave_status: '産休・育休の取得状況',
  maternity_paternity_leave_start_time: '産休・育休の取得開始時期',
  maternity_paternity_leave_end_time: '産休・育休の取得終了時期',
  nursing_leave_status: '介護休暇の取得状況',
  emplmt_form_code: '雇用形態',
  position: '役職',
  headquarters_location: '勤務先本社所在地',
  listed_division: '勤務先上場区分',
  office_establishment_date: '勤務先設立年月日',
  capital_stock: '勤務先資本金',
  other_office_address_kanji: '丁目以下・建物名・部屋番号',
  office_prefecture_kana: '都道府県（フリガナ）',
  office_city_kana: '市区郡（フリガナ）',
  office_district_kana: '町村丁目（フリガナ）',
  other_office_address_kana: '丁目以下・建物名・部屋番号（フリガナ）',
  'p_resident.last_name_kanji': '入居家族 (姓)　漢字',
  'p_resident.first_name_kanji': '入居家族 (名)　漢字',
  'p_resident.last_name_kana': '入居家族 (姓)　カナ',
  'p_resident.first_name_kana': '入居家族 (名)　カナ',
  'p_resident.sex': '性別',
  'p_resident.relationship_name': '性別',
  'p_resident.birthday': '生年月日',
  // name_relationship_to_applicant:
  relationship_to_applicant: '収入合算者続柄（プルダウン）',
  other_relationship_to_applicant: '収入合算者続柄 (その他の場合)',
  guarantor_relationship_to_applicant: '担保提供者の続柄',
  expected_house_selling_price: '持家　売却予定価格',
  scheduled_time_sell_house: '持家　売却予定時期',
  current_home_loan: '持家　ローン残高',
  property_information_url: '物件情報が掲載されたURL',
  reason_acquire_home: '新しい住居を必要とする理由',
  // birthday: '生年月日',
  // nationality: '国籍',
  // mobile_phone_number: '携帯電話番号',
  // home_phone_number: '自宅電話番号',
  // postal_code: '現住所 郵便番号',
  // prefecture_kanji: '現住所　都道府県　漢字',
  // city_kanji: '現住所 市区郡／市区町村　漢字',
  // district_kanji: '現住所　町村字丁目／丁目･番地･号　漢字',
  // other_address_kanji: '現住所　補足／建物名･部屋番号等　漢字',
  // emergency_contact_number: '緊急連絡先',
  // prefecture_kana: '現住所　都道府県　カナ',
  // city_kana: '現住所　市区郡／市区町村　カナ',
  // district_kana: '現住所　町村字丁目／丁目･番地･号　カナ',
  // other_address_kana: '現住所　補足／建物名･部屋番号等　カナ',
  // owner_email: 'メールアドレス',
  // occupation: '勤務先　職業',
  land_ownership: '土地の情報　土地権利',
  purchase_purpose: '土地の情報　買戻・保留地・仮換地',
  planning_area: '土地の情報　都市計画区域',
  other_planning_area: '土地の情報　都市計画区域（その他）',
  rebuilding_reason: '土地の情報　再建築理由',
  other_rebuilding_reason: '土地の情報　再建築理由（その他）',
  flat_35_applicable_plan: 'その他物件情報　フラット35S適用プラン',
  maintenance_type: 'その他物件情報　維持保全型',
  flat_35_application: 'その他物件情報　フラット35S満たす技術基準',
  region_type: 'その他物件情報　地域連携型・地方移住支援型',
  borrowing_type: '借入分類',
  borrower: '借入名義人',
  lender: '借入先／借入先（金融機関名）',
  borrowing_from_housing_finance_agency: '住宅金融支援機構からの借入',
  loan_purpose: '資金使途（目的）／借入金用途',
  other_purpose: '資金使途（その他）',
  borrowing_category: '借入区分',
  loan_start_date: '当初カード契約年月/当初借入年月',
  loan_amount: '借入限度額/当初借入額',
  current_balance_amount: '借入残高／現在残高',
  annual_repayment_amount: '年間返済額',
  card_expiry_date: 'カード有効期限',
  scheduled_loan_payoff:
    '借入（契約手続き前）までの完済（予定）有無／完済（解約）継続予定',
  loan_deadline_date: '契約期限、最終期限／最終返済年月',
  expected_repayment_date: '完済（予定）年月',
  rental_room_number: '賃貸戸（室）数',
  common_housing: '共同住宅',
  estate_mortgage: '不動産担保設定',
  completely_repayment_type: '完済原資',
  other_complete_repayment_type: '完済原資（その他）',
  refund_content: '完済原資　内容',
  refund_amount: '完済原資　金額',
  land_rent_to_be_paid: '今回の住宅取得後も継続する支払地代',
  house_rent_to_be_paid: '今回の住宅取得後も継続する支払家賃',
  include_in_examination: '審査に含める/含めない',
  house_purchase_price: '物件価格／マンション価格',
  additional_cost: '必要資金内訳　諸費用等・その他額／物件価格　諸費用',
  require_funds_breakdown_mortgage: '必要資金内訳　住宅ローンプラス利用',
  land_purchase_price: '必要資金内訳　土地（戸建）／物件価格　土地取得費',
  accessory_cost: '必要資金内訳　付帯設備',
  refinancing_loan_balance: '必要資金内訳　借換対象ローン残債',
  house_upgrade_cost: '必要資金内訳　増改築',
  land_price: '融資対象物件　土地価格',
  building_price: '融資対象物件　建物価格',
  land_and_building_price: '融資対象物件　合計価格',
  deposit_savings_1: '調達資金内訳　自己資金　預貯金(金融機関1)',
  real_estate_sale_price: '調達資金内訳　自己資金　不動産売却代金',
  other_saving_amount: '調達資金内訳　自己資金　その他（有価証券等）',
  relative_donation_amount: '調達資金内訳　親族からの贈与',
  // loan_amount: '調達資金内訳　本件ローンA／本件ローン',
  pair_loan_amount: 'ペアローン',
  other_procurement_breakdown: '調達資金内訳　その他額',
  saving_amount: '自己資金',
  amount_any_loans: 'その他の借り入れ',
  amount_others: 'その他（借換）',
  borrowing_status: 'あなたや連帯保証人予定者に、現在お借入はありますか？',
  buyingand_selling_schedule_type: '持家　処分方法',
  other_buyingand_selling_schedule_type: '持家　処分方法（その他）',
  // borrower: '借入名義人',
  // borrowing_type: '借入分類',
  // lender: '借入先／借入先（金融機関名）',
  // borrowing_from_housing_finance_agency: '住宅金融支援機構からの借入',
  // loan_purpose: '資金使途（目的）／借入金用途',
  // other_purpose: '資金使途（その他）',
  // borrowing_category: '借入区分',
  // loan_start_date: '当初借入年月、当初借入日／カード契約年月',
  // loan_amount: '当初借入額／利用限度額',
  // current_balance_amount: '借入残高／現在残高',
  // annual_repayment_amount: '年間返済額 カード有効期限',
  // scheduled_loan_payoff:
  //   '借入（契約手続き前）までの完済（予定）有無／完済（解約）継続予定',
  // loan_deadline_date: '契約期限、最終期限／最終返済年月',
  // rental_room_number: '賃貸戸（室）数',
  // common_housing: '共同住宅',
  // estate_mortgage: '不動産担保設定',
  // completely_repayment_type: '完済原資',
  // other_complete_repayment_type: '完済原資（その他）',
  // refund_content: '完済原資　内容',
  // refund_amount: '完済原資　金額',
  // land_rent_to_be_paid: '今回の住宅・土地取得以外の借入　地代',
  // house_rent_to_be_paid: '今回の住宅・土地取得以外の借入　家賃',
  // include_in_examination: '審査に含める/含めない',
  s_sale_person_id: '担当者（営業担当者）ID',
  sale_person_phone_number: '携帯電話番号',
  phone_number: '電話番号／連絡先',
  application_number: '申込番号（受付番号）',
  condominium_name: '融資対象物件　マンション名・部屋番号',
  first_income_file: '確定申告書（1期前）',
  second_income_file: '確定申 告書（2期前）',
  third_income_file: '確定申告書（3期前）',
  relationship: '続柄',
  one_roof: '現在申込人との同居有無',
  financial_statement_1_file: '会社の決算報告書（1期前）',
  financial_statement_2_file: '会社の決算報告書（2期前）',
  financial_statement_3_file: '会社の決算報告書（3期前）',
  other_document_file: 'その他の書類',
  business_tax_return_1_file:
    '会社の決算報告書または経営する親族の確定申告書（1期前）',
  business_tax_return_2_file:
    '会社の決算報告書または経営する親族の確定申告書（2期前）',
  business_tax_return_3_file:
    '会社の決算報告書または経営する親族の確定申告書（3期前）',
  insurance_file: '健康保険証',
  first_withholding_slip_file: '源泉徴収票（前年度分）',
  second_withholding_slip_file: '源泉徴収票（前々年度分）',
  employment_agreement_file: '雇用契約書',
  referral_agency_file:
    '担当者の名刺はありますか？※名刺添付で入力を省略できます',
  loan_from_japan_housing_finance_agency:
    '住宅金融支援機構（旧：公庫）からの融資の有無',
  relationship_name: '続柄',
  lived_length_year_num: '現在居住　居住年数（年）',
  lived_length_month_num: '現在居住　居住年数（ヶ月）',
  current_residence: '現在のお住まいの種類',
  current_residence_floor_area: '現在のお住まいの床面積 (MCJ固有項目)',
  residence_file: '在留カード 〈表面〉',
  residence_file_back_image: '在留カード 〈裏面〉',
  non_resident_reason: '新しい住居に、居住しない理由',
  identity_verification: '運転免許書・マイナンバーカード・ 住民基本台帳カード',
  driver_license_front_image:
    '運転免許書・マイナンバーカード・住民基本台帳カード＜表面＞',
  driver_license_back_image:
    '運転免許書・マイナンバーカード・住民基本台帳カード＜裏面＞',
  card_number_front_image:
    '運転免許書・マイナンバーカード・住民基本台帳カード＜表面＞',
  resident_register_front_image:
    '運転免許書・マイナンバーカード・住民基本台帳カード＜表面＞',
  resident_register_back_image:
    '運転免許書・マイナンバーカード・住民基本台帳カード＜裏面＞',
  ...GUARANTEE_PROVIDER.reduce(
    (a, v) => ({ ...a, [`p_join_guarantor.${v.name_en}`]: v.name_ja }),
    {}
  ),
};

export const FIELDS_OBJECT_VALIDATE = [
  'p_applicant_people',
  'p_applicant_people_attributes',
  'personal_file_upload',
  'p_borrowing_details_attributes',
  'p_join_guarantors_attributes',
  'p_borrowings_attributes',
  'p_residents_attributes',
  'p_residents',
  'sale_person_information',
  'provisional_results',
  'p_borrowings',
  'p_join_guarantors',
  'p_borrowing_details',
];

export const OBJECTS_TABLE = {
  p_application_header: 'p_application_header',
  p_applicant_people_attributes: 'p_applicant_people_attributes',
  p_borrowing_details_attributes: 'p_borrowing_details_attributes',
  p_borrowings_attributes: 'p_borrowings_attributes',
  p_join_guarantors_attributes: 'p_join_guarantors_attributes',
  p_residents_attributes: 'p_residents_attributes',
  sale_person_information: 'sale_person_information',
};

export const TRACKABLE_TYPE = {
  p_application_header: 'PApplicationHeader',
  p_applicant_people_attributes: 'PApplicantPerson',
  p_borrowing_details_attributes: 'PBorrowingDetail',
  p_borrowings_attributes: 'PBorrowing',
  p_join_guarantors_attributes: 'PJoinGuarantor',
  p_residents_attributes: 'PResident',
  // sale_person_information: 'sale_person_information',
  // PApplicationBank
};

export const ACCEPT_TYPE: Accept = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'application/pdf': [],
};

export const SP_FIELDS_TABLE: String[] = [
  'p_join_guarantor',
  'p_borrowing',
  'p_resident',
  'p_applicant_person',
  'p_application_bank',
  'p_borrowing_detail',
  'active_storage_blob',
];

export const SP_FIELDS_TITLE: Record<string, string> = {
  /* <-- SP TABLE --> */
  p_join_guarantors: '担保提供者',
  p_borrowings: '借入状態',
  p_residents: '入居予定者の明細',
  p_applicant_people: '申込者と収入合算者',
  p_join_guarantor:
    '担保提供者にご入力いただいた情報は管理画面から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  p_borrowing:
    '現在の借入状態にご入力いただいた情報は管理画面から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  p_resident:
    '入居予定者にご入力いただいた情報は管理画面から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  p_applicant_person:
    '収入合算者にご入力いただいた情報は管理画面から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  p_application_bank:
    '申し込む金融機関は削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  p_borrowing_detail:
    '2回目の融資は管理画面から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  s_master_bank: '',
  active_storage_blob:
    'アップロード書類に関して管理画面から削除したファイルが有ります。最新のデータを取得するために、ページをリフレッシュしてください。',

  bank_is_conducting_a_review:
    '住信SBIネット銀行での審査フェーズ（「仮審査中」以降）に入りますと申込内容の修正はできません。',

  /* <-- SP STEP ONE --> */
  loan_apply_date: '申込日時',
  is_home_loan_plus: '住信SBIネット銀行の「住宅ローンプラス」を申し込みますか',
  scheduled_date_moving: '入居予定年月',
  loan_type: 'お借入形態',
  loan_target: '資金の使いみち',
  loan_target_zero: 'お借入の目的',
  master_bank_ids: '仮審査を申し込む金融機関を選択してください',
  has_land_advance_plan: '土地先行プランをご希望ですか',
  loan_desired_borrowing_date: 'お借入希望日',
  temporary_desired_loan_amount: 'お借入希望額',
  halfyear_bonus: ' うち、ボーナス返済分',
  desired_monthly_bonus: 'ボーナス返済月',
  loan_term_year_num: 'お借入期間',
  loan_term_month_num: 'お借入期間',
  repayment_method: '返済方法',
  has_join_guarantor: '担保提供者がいる方のみ、チェックをつけてください',
  pair_loan_applicant_last_name: 'お名前',
  pair_loan_applicant_first_name: 'お名前',
  pair_loan_relationship_name: '続柄',

  /* <-- SP STEP TWO --> */
  last_name_kanji: 'お名前',
  first_name_kanji: 'お名前',
  last_name_kana: 'お名前（フリガナ）',
  first_name_kana: 'お名前（フリガナ）',
  sex: '性別',
  birthday: '生年月日',
  nationality: '現在の国籍',
  mobile_phone_number: '〈電話番号〉携帯',
  home_phone_number: '〈電話番号〉自宅',
  postal_code: '〈現住所〉郵便番号',
  prefecture_kanji: '〈現住所〉都道府県',
  city_kanji: '〈現住所〉市区郡',
  district_kanji: '〈現住所〉町村丁目',
  other_address_kanji: '〈現住所〉丁目以下・建物名・部屋番号',
  owner_email: 'ご連絡先用メールアドレス',
  residence_file: '〈現在の国籍〉在留カードを添付してください',
  residence_file_back_image: '〈現在の国籍〉在留カードを添付してください',

  /* <-- SP STEP THREE --> */
  occupation: 'ご職業',
  other_occupation: '〈ご職業〉その他',
  industry: '業種',
  other_industry: '〈業種〉その他',
  occupation_detail: '職種',
  other_occupation_detail: '〈職種〉その他',
  office_name_kanji: '勤務先名',
  department: '所属部課',
  office_phone_number: '勤務先の電話番号',
  office_postal_code: '〈勤務先の住所〉郵便番号',
  office_prefecture_kanji: '〈勤務先の住所〉都道府県',
  office_city_kanji: '〈勤務先の住所〉市区郡',
  office_district_kanji: '〈勤務先の住所〉町村丁目',
  number_of_employee: '従業員数',
  employment_started_date: '入社年月',
  last_year_income: '〈ご年収〉前年度年収',
  bonus_income: '〈ご年収〉うち、ボーナス',
  two_years_ago_income: '〈ご年収〉前々年度年収',
  income_source: '〈ご年収〉収入源',
  tax_return: '〈ご年収〉確定申告をしていますか',
  type_tax_return: '〈ご年収〉確定申告の理由',
  other_type_tax_return: '〈ご年収〉その他 確定申告の理由',
  transfer_office: '現在、出向（派遣）していますか',
  transfer_office_name_kanji: '出向（派遣）勤務先名',
  transfer_office_name_kana: '出向（派遣）勤務先名（フリガナ）',
  transfer_office_phone_number: '出向（派遣）先　電話番号',
  transfer_office_postal_code: '〈出向（派遣）〉郵便番号',
  transfer_office_prefecture_kanji: '〈出向（派遣）〉都道府県',
  transfer_office_city_kanji: '〈出向（派遣）〉市区郡',
  transfer_office_district_kanji: '〈出向（派遣）〉町村丁目',
  maternity_paternity_leave_status: '産休・育休の取得状況',
  maternity_paternity_leave_start_time: '〈産休・育休の取得状況〉取得開始時期',
  maternity_paternity_leave_end_time: '〈産休・育休の取得状況〉取得終了時期',
  nursing_leave_status: '月まで',

  /* <-- SP STEP FOUR --> */
  person_occupancy: '新しい住居に、あなたは居住しますか？',
  non_resident_reason: '「いいえ」の方は理由を入力ください',
  planned_cohabitant: 'あなた以外の入居予定者',
  children_number: '〈あなた以外の入居予定者〉',
  siblings_number: '〈あなた以外の入居予定者〉',
  other_people_number: '〈あなた以外の入居予定者〉',
  other_relationship: '〈あなた以外の入居予定者〉その他',
  housemate_number: '〈あなた以外の入居予定者〉ご本人を除き、合計',
  business_ability: '新しい住居（融資対象物件）の事業性',
  collateral_prefecture: '〈ご購入物件の所在地〉都道府県',
  collateral_city: '〈ご購入物件の所在地〉市区町村郡',
  collateral_lot_number: '〈ご購入物件の所在地〉以下地番',
  condominium_name: '〈ご購入物件の所在地〉マンション名・部屋番号',
  collateral_land_area: '〈ご購入物件の面積〉土地の敷地面積',
  collateral_total_floor_area: '〈ご購入物件の面積〉建物の延べ床面積',
  occupied_area: '〈ご購入物件の面積〉専有面積',
  land_ownership: 'ご購入物件の土地権利',
  purchase_purpose: '買戻・保留地・仮換地',
  planning_area: '都市計画区域等',
  other_planning_area: '〈都市計画区域等〉その他',
  rebuilding_reason: '再建築理由',
  other_rebuilding_reason: '〈再建築理由〉その他',
  flat_35_applicable_plan: 'フラット35S（優良住宅取得支援制度）対象項目',
  maintenance_type: '維持保全型',
  region_type: '地域連携型・地方移住支援型',
  flat_35_application: 'フラット35S（優良住宅取得支援制度）対象項目②',
  property_information_file: '物件情報の画像添付',
  property_information_url: '物件情報が掲載されたURL添付',
  lived_length_year_num: '現在のお住まいの居住年数 （年）',
  lived_length_month_num: '現在のお住まいの居住年数 （ヶ月）',
  current_residence: '現在のお住まいの種類',
  reason_acquire_home: '新しい住居を必要とする理由',
  other_reason_acquire_home: '（新しい住居を必要とする理由〉その他',
  buyingand_selling_schedule_type: '持ち家の処分方法',
  expected_house_selling_price: '売却予定価格',
  current_residence_floor_area: '現在のお住まいの床面積',
  owner_full_name: '所有者の氏名',
  owner_relationship: '続柄',
  other_buyingand_selling_schedule_type: '〈持ち家の処分方法〉その他',
  scheduled_time_sell_house: '売却予定時期',
  current_home_loan: 'ローン残高',
  relationship_name: '続柄',
  loan_from_japan_housing_finance_agency:
    '住宅金融支援機構（旧：公庫）からの融資の有無',

  /* <-- SP STEP FIVE --> */
  borrowing_status: 'あなたや連帯保証人予定者に、現在お借入はありますか',
  land_rent_to_be_paid: '月間の支払金額',
  house_rent_to_be_paid: '月間の支払金額',
  land_rent_to_be_paid_borrower: '支払いをしている方',
  house_rent_to_be_paid_borrower: '支払いをしている方',
  borrower: '借入名義人',
  borrowing_type: 'お借入の種類は',
  self_input: '',
  lender: '借入先（金融機関）',
  borrowing_from_housing_finance_agency: '住宅金融支援機構からの借入ですか',
  loan_start_date: '当初カード契約年月 / 当初借入年月',
  loan_amount: '借入限度額 / 当初借入額',
  current_balance_amount: '現在の残高',
  annual_repayment_amount: '年間返済額',
  loan_deadline_date: '最終期限 / 最終返済年月',
  scheduled_loan_payoff: '今回のお借入までに完済の予定はありますか',
  loan_purpose: 'お借入の目的',
  other_purpose: '〈お借入の目的〉その他',
  card_expiry_date: 'カード有効期限',
  rental_room_number: '賃貸戸（室）数',
  common_housing: '共同住宅',
  estate_mortgage: '不動産担保設定',
  expected_repayment_date: '完済（予定）年月',
  completely_repayment_type: '完済原資の種類',
  other_complete_repayment_type: '〈完済原資の種類〉その他',
  refund_content: '完済原資の内容',
  refund_amount: '完済原資の金額',
  borrowing_detail_type: '土地先行プランに借入詳細に情報が足りないです。',

  /* <-- SP STEP SIX --> */
  house_purchase_price: '物件価格 / マンション価格 / 建物',
  additional_cost: '諸費用等',
  require_funds_breakdown_mortgage: '住宅ローンプラス利用',
  land_purchase_price: '土地',
  accessory_cost: '付帯設備',
  refinancing_loan_balance: '借換対象ローン残債',
  house_upgrade_cost: '増改築費',
  deposit_savings_1: '預貯金',
  real_estate_sale_price: '不動産売却代金',
  other_saving_amount: 'その他（有価証券等） / 有価証券売却など',
  relative_donation_amount: '親族からの贈与',
  pair_loan_amount: 'ペアローン',
  other_procurement_breakdown: 'その他',
  other_procurement_breakdown_content: '詳細を入力ください',

  /* <-- SP STEP SEVEN --> */
  identity_verification: '本人確認書類',
  insurance_file: '健康保険証',
  financial_statement_1_file: '会社の決算報告書（1期前）',
  financial_statement_2_file: '会社の決算報告書（2期前）',
  financial_statement_3_file: '会社の決算報告書（3期前）',
  first_withholding_slip_file: '収入に関する書類',
  second_withholding_slip_file: 'second_withholding_slip_file',
  other_document_file: 'その他の書類',
  employment_agreement_file: '雇用契約に関する書類',
  business_tax_return_1_file:
    '会社の決算報告書または経営する親族の確定申告書（1期前）',
  business_tax_return_2_file:
    '会社の決算報告書または経営する親族の確定申告書（2期前）',
  business_tax_return_3_file:
    '会社の決算報告書または経営する親族の確定申告書（3期前）',
  first_income_file: '確定申告書（1期前）',
  second_income_file: '確定申告書（2期前）',
  third_income_file: '確定申告書（3期前）',
  driver_license_front_image: '〈本人確認書類〉表面',
  driver_license_back_image: '〈本人確認書類〉裏面',
  card_number_front_image: '〈本人確認書類〉表面',
  card_number_back_image: '〈本人確認書類〉裏面',
  resident_register_front_image: '〈本人確認書類〉表面',
  resident_register_back_image: '〈本人確認書類〉裏面',

  /* <-- SP STEP EIGHT --> */
  has_business_card: '担当者の名刺はありますか',
  business_card: '',
  sale_agent_id: '提携会社',
  store_id: 'エリア',
  exhibition_id: '展示場',
  s_sale_person_id: '担当者名',
  phone_number: '電話番号',

  emergency_contact_number: '緊急連絡先',
  prefecture_kana: '都道府県（フリガナ）',
  city_kana: '市区郡（フリガナ）',
  district_kana: '町村丁目（フリガナ）',
  other_address_kana: '丁目以下・建物名・部屋番号（フリガナ）',
  name_relationship_to_applicant: '続柄',
  other_relationship_to_applicant: '続柄 (その他の場合)',
  emplmt_form_code: '雇用形態',
  office_name_kana: '勤務先名（フリガナ）',
  position: '役職',
  headquarters_location: '勤務先本社所在地',
  listed_division: '勤務先上場区分',
  office_establishment_date: '勤務先設立年月日',
  capital_stock: '勤務先資本金',
  other_office_address_kanji: '建物名・部屋番号',
  office_prefecture_kana: '都道府県（フリガナ）',
  office_city_kana: '市区郡（フリガナ）',
  office_district_kana: '町村丁目（フリガナ）',
  other_office_address_kana: '丁目以下・建物名・部屋番号（フリガナ）',
  property_postal_code: '融資対象物件　郵便番号',
  collateral_address_kana: '融資対象物件　物件所在地住所（フリガナ）',
  collateral_type: '担保物件種類',
  acquisition_time_of_the_land: '土地取得時期',
  joint_ownership_division: '共有区分',
  building_ratio_numerator: '建物割合分子',
  building_ratio_denominator: '建物割合分母',
  land_ratio_numerator: '土地割合分子',
  land_ratio_denominator: '土地割合分母',
};

export const FIELD_VALIDATE_SP = [
  {
    label: 'step_1',
    fields: [
      {
        name: '申込日時',
        key: 'loan_apply_date',
        source: 'PApplicationHeader',
      },
      {
        name: '住信SBIネット銀行の「住宅ローンプラス」を申し込みますか',
        key: 'is_home_loan_plus',
        source: 'PApplicationHeader',
      },
      {
        name: '入居予定年月',
        key: 'scheduled_date_moving',
        source: 'PApplicationHeader',
      },
      {
        name: 'お借入形態',
        key: 'loan_type',
        source: 'PApplicationHeader',
      },
      {
        name: '資金の使いみち',
        key: 'loan_target',
        source: 'PApplicationHeader',
      },
      {
        name: '仮審査を申し込む金融機関を選択してください',
        key: 'master_bank_ids',
        source: 'PApplicationHeader',
      },
      {
        name: '土地先行プランをご希望ですか',
        key: 'has_land_advance_plan',
        source: 'PApplicationHeader',
      },
      {
        name: 'お借入希望日',
        key: 'loan_desired_borrowing_date',
        source: 'PBorrowingDetail',
      },
      {
        name: 'お借入希望額',
        key: 'temporary_desired_loan_amount',
        source: 'PBorrowingDetail',
      },
      {
        name: 'うち、ボーナス返済分',
        key: 'halfyear_bonus',
        source: 'PBorrowingDetail',
      },
      {
        name: 'ボーナス返済月',
        key: 'desired_monthly_bonus',
        source: 'PBorrowingDetail',
      },
      {
        name: 'お借入期間',
        key: 'loan_term_year_num',
        source: 'PBorrowingDetail',
      },
      {
        name: 'お借入期間',
        key: 'loan_term_month_num',
        source: 'PBorrowingDetail',
      },
      {
        name: '返済方法',
        key: 'repayment_method',
        source: 'PBorrowingDetail',
      },
      {
        name: '担保提供者がいる方のみ、チェックをつけてください',
        key: 'has_join_guarantor',
        source: 'PApplicantPerson',
      },

      {
        name: 'お名前',
        key: 'pair_loan_applicant_last_name',
        source: 'PApplicationHeader',
      },
      {
        name: 'お名前',
        key: 'pair_loan_applicant_first_name',
        source: 'PApplicationHeader',
      },
      {
        name: '続柄',
        key: 'pair_loan_relationship_name',
        source: 'PApplicationHeader',
      },
    ],
  },

  {
    label: 'step_2',
    fields: [
      {
        name: 'お名前',
        key: 'last_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: 'お名前',
        key: 'first_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: 'お名前（フリガナ）',
        key: 'last_name_kana',
        source: 'PApplicantPerson',
      },
      {
        name: 'お名前（フリガナ）',
        key: 'first_name_kana',
        source: 'PApplicantPerson',
      },
      {
        name: '性別',
        key: 'sex',
        source: 'PApplicantPerson',
      },
      {
        name: '生年月日',
        key: 'birthday',
        source: 'PApplicantPerson',
      },
      {
        name: '現在の国籍',
        key: 'nationality',
        source: 'PApplicantPerson',
      },
      {
        name: '〈電話番号〉携帯',
        key: 'mobile_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈電話番号〉自宅',
        key: 'home_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉郵便番号',
        key: 'postal_code',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉都道府県',
        key: 'prefecture_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉市区郡',
        key: 'city_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉町村丁目',
        key: 'district_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉丁目以下・建物名・部屋番号',
        key: 'other_address_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: 'ご連絡先用メールアドレス',
        key: 'owner_email',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現在の国籍〉在留カードを添付してください',
        key: 'residence_file',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現在の国籍〉在留カードを添付してください',
        key: 'residence_file_back_image',
        source: 'PApplicantPerson',
      },
    ],
  },

  {
    label: 'step_3',
    fields: [
      {
        name: 'ご職業',
        key: 'occupation',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご職業〉その他',
        key: 'other_occupation',
        source: 'PApplicantPerson',
      },
      {
        name: '業種',
        key: 'industry',
        source: 'PApplicantPerson',
      },
      {
        name: '〈業種〉その他',
        key: 'other_industry',
        source: 'PApplicantPerson',
      },
      {
        name: '職種',
        key: 'occupation_detail',
        source: 'PApplicantPerson',
      },
      {
        name: '〈職種〉その他',
        key: 'other_occupation_detail',
        source: 'PApplicantPerson',
      },
      {
        name: '勤務先名',
        key: 'office_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '所属部課',
        key: 'department',
        source: 'PApplicantPerson',
      },
      {
        name: '勤務先の電話番号',
        key: 'office_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉郵便番号',
        key: 'office_postal_code',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉都道府県',
        key: 'office_prefecture_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉市区郡',
        key: 'office_city_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉町村丁目',
        key: 'office_district_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '従業員数',
        key: 'number_of_employee',
        source: 'PApplicantPerson',
      },
      {
        name: '入社年月',
        key: 'employment_started_date',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉前年度年収',
        key: 'last_year_income',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉うち、ボーナス',
        key: 'bonus_income',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉前々年度年収',
        key: 'two_years_ago_income',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉収入源',
        key: 'income_source',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉確定申告をしていますか',
        key: 'tax_return',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉確定申告の理由',
        key: 'type_tax_return',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉その他 確定申告の理由',
        key: 'other_type_tax_return',
        source: 'PApplicantPerson',
      },
      {
        name: '現在、出向（派遣）していますか',
        key: 'transfer_office',
        source: 'PApplicantPerson',
      },
      {
        name: '出向（派遣）勤務先名',
        key: 'transfer_office_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '出向（派遣）勤務先名（フリガナ）',
        key: 'transfer_office_name_kana',
        source: 'PApplicantPerson',
      },
      {
        name: '出向（派遣）先　電話番号',
        key: 'transfer_office_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉郵便番号',
        key: 'transfer_office_postal_code',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉都道府県',
        key: 'transfer_office_prefecture_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉市区郡',
        key: 'transfer_office_city_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉町村丁目',
        key: 'transfer_office_district_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '産休・育休の取得状況',
        key: 'maternity_paternity_leave_status',
        source: 'PApplicantPerson',
      },
      {
        name: '〈産休・育休の取得状況〉取得開始時期',
        key: 'maternity_paternity_leave_start_time',
        source: 'PApplicantPerson',
      },
      {
        name: '〈産休・育休の取得状況〉取得終了時期',
        key: 'maternity_paternity_leave_end_time',
        source: 'PApplicantPerson',
      },
      {
        name: '月まで',
        key: 'nursing_leave_status',
        source: 'PApplicantPerson',
      },
    ],
  },

  {
    label: 'step_2_income',
    fields: [
      {
        name: '収入合算者のお名前',
        key: 'last_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '収入合算者のお名前',
        key: 'first_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '収入合算者のお名前（フリガナ）',
        key: 'last_name_kana',
        source: 'PApplicantPerson',
      },
      {
        name: '収入合算者のお名前（フリガナ）',
        key: 'first_name_kana',
        source: 'PApplicantPerson',
      },
      {
        name: '性別',
        key: 'sex',
        source: 'PApplicantPerson',
      },
      {
        name: '続柄',
        key: 'name_relationship_to_applicant',
        source: 'PApplicantPerson',
      },
      {
        name: '生年月日',
        key: 'birthday',
        source: 'PApplicantPerson',
      },
      {
        name: '現在の国籍',
        key: 'nationality',
        source: 'PApplicantPerson',
      },
      {
        name: '〈電話番号〉携帯',
        key: 'mobile_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈電話番号〉自宅',
        key: 'home_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉郵便番号',
        key: 'postal_code',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉都道府県',
        key: 'prefecture_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉市区郡',
        key: 'city_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉町村丁目',
        key: 'district_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現住所〉丁目以下・建物名・部屋番号',
        key: 'other_address_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現在の国籍〉在留カードを添付してください',
        key: 'residence_file',
        source: 'PApplicantPerson',
      },
      {
        name: '〈現在の国籍〉在留カードを添付してください',
        key: 'residence_file_back_image',
        source: 'PApplicantPerson',
      },
    ],
  },

  {
    label: 'step_3_income',
    fields: [
      {
        name: 'ご職業',
        key: 'occupation',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご職業〉その他',
        key: 'other_occupation',
        source: 'PApplicantPerson',
      },
      {
        name: '業種',
        key: 'industry',
        source: 'PApplicantPerson',
      },
      {
        name: '〈業種〉その他',
        key: 'other_industry',
        source: 'PApplicantPerson',
      },
      {
        name: '職種',
        key: 'occupation_detail',
        source: 'PApplicantPerson',
      },
      {
        name: '〈職種〉その他',
        key: 'other_occupation_detail',
        source: 'PApplicantPerson',
      },
      {
        name: '勤務先名',
        key: 'office_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '所属部課',
        key: 'department',
        source: 'PApplicantPerson',
      },
      {
        name: '勤務先の電話番号',
        key: 'office_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉郵便番号',
        key: 'office_postal_code',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉都道府県',
        key: 'office_prefecture_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉市区郡',
        key: 'office_city_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈勤務先の住所〉町村丁目',
        key: 'office_district_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '従業員数',
        key: 'number_of_employee',
        source: 'PApplicantPerson',
      },
      {
        name: '入社年月',
        key: 'employment_started_date',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉前年度年収',
        key: 'last_year_income',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉うち、ボーナス',
        key: 'bonus_income',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉前々年度年収',
        key: 'two_years_ago_income',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉収入源',
        key: 'income_source',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉確定申告をしていますか',
        key: 'tax_return',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉確定申告の理由',
        key: 'type_tax_return',
        source: 'PApplicantPerson',
      },
      {
        name: '〈ご年収〉その他 確定申告の理由',
        key: 'other_type_tax_return',
        source: 'PApplicantPerson',
      },
      {
        name: '現在、出向（派遣）していますか',
        key: 'transfer_office',
        source: 'PApplicantPerson',
      },
      {
        name: '出向（派遣）勤務先名',
        key: 'transfer_office_name_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '出向（派遣）勤務先名（フリガナ）',
        key: 'transfer_office_name_kana',
        source: 'PApplicantPerson',
      },
      {
        name: '出向（派遣）先　電話番号',
        key: 'transfer_office_phone_number',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉郵便番号',
        key: 'transfer_office_postal_code',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉都道府県',
        key: 'transfer_office_prefecture_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉市区郡',
        key: 'transfer_office_city_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '〈出向（派遣）〉町村丁目',
        key: 'transfer_office_district_kanji',
        source: 'PApplicantPerson',
      },
      {
        name: '産休・育休の取得状況',
        key: 'maternity_paternity_leave_status',
        source: 'PApplicantPerson',
      },
      {
        name: '〈産休・育休の取得状況〉取得開始時期',
        key: 'maternity_paternity_leave_start_time',
        source: 'PApplicantPerson',
      },
      {
        name: '〈産休・育休の取得状況〉取得終了時期',
        key: 'maternity_paternity_leave_end_time',
        source: 'PApplicantPerson',
      },
      {
        name: '月まで',
        key: 'nursing_leave_status',
        source: 'PApplicantPerson',
      },
    ],
  },

  {
    label: 'step_4',
    fields: [
      {
        name: '新しい住居に、あなたは居住しますか？',
        key: 'person_occupancy',
        source: 'PApplicationHeader',
      },
      {
        name: '「いいえ」の方は理由を入力ください',
        key: 'non_resident_reason',
        source: 'PApplicationHeader',
      },
      {
        name: 'あなた以外の入居予定者',
        key: 'planned_cohabitant',
        source: 'PApplicationHeader',
      },
      {
        name: '〈あなた以外の入居予定者〉',
        key: 'children_number',
        source: 'PApplicationHeader',
      },
      {
        name: '〈あなた以外の入居予定者〉',
        key: 'siblings_number',
        source: 'PApplicationHeader',
      },
      {
        name: '〈あなた以外の入居予定者〉',
        key: 'other_people_number',
        source: 'PApplicationHeader',
      },
      {
        name: '〈あなた以外の入居予定者〉その他',
        key: 'other_relationship',
        source: 'PApplicationHeader',
      },
      {
        name: '〈あなた以外の入居予定者〉ご本人を除き、合計',
        key: 'housemate_number',
        source: 'PApplicationHeader',
      },
      {
        name: '新しい住居（融資対象物件）の事業性',
        key: 'business_ability',
        source: 'PApplicationHeader',
      },
      {
        name: '〈ご購入物件の所在地〉都道府県',
        key: 'collateral_prefecture',
        source: 'PApplicationHeader',
      },
      {
        name: '〈ご購入物件の所在地〉市区町村郡',
        key: 'collateral_city',
        source: 'PApplicationHeader',
      },
      {
        name: '〈ご購入物件の所在地〉以下地番',
        key: 'collateral_lot_number',
        source: 'PApplicationHeader',
      },
      {
        name: '〈ご購入物件の所在地〉マンション名・部屋番号',
        key: 'condominium_name',
        source: 'PApplicationHeader',
      },
      {
        name: '〈ご購入物件の面積〉土地の敷地面積',
        key: 'collateral_land_area',
        source: 'PApplicationHeader',
      },
      {
        name: '〈ご購入物件の面積〉建物の延べ床面積',
        key: 'collateral_total_floor_area',
        source: 'PApplicationHeader',
      },
      {
        name: '〈ご購入物件の面積〉専有面積',
        key: 'occupied_area',
        source: 'PApplicationHeader',
      },
      {
        name: 'ご購入物件の土地権利',
        key: 'land_ownership',
        source: 'PApplicationHeader',
      },
      {
        name: '買戻・保留地・仮換地',
        key: 'purchase_purpose',
        source: 'PApplicationHeader',
      },
      {
        name: '都市計画区域等',
        key: 'planning_area',
        source: 'PApplicationHeader',
      },
      {
        name: '〈都市計画区域等〉その他',
        key: 'other_planning_area',
        source: 'PApplicationHeader',
      },
      {
        name: '再建築理由',
        key: 'rebuilding_reason',
        source: 'PApplicationHeader',
      },
      {
        name: '〈再建築理由〉その他',
        key: 'other_rebuilding_reason',
        source: 'PApplicationHeader',
      },
      {
        name: 'フラット35S（優良住宅取得支援制度）対象項目',
        key: 'flat_35_applicable_plan',
        source: 'PApplicationHeader',
      },
      {
        name: '維持保全型',
        key: 'maintenance_type',
        source: 'PApplicationHeader',
      },
      {
        name: '地域連携型・地方移住支援型',
        key: 'region_type',
        source: 'PApplicationHeader',
      },
      {
        name: 'フラット35S（優良住宅取得支援制度）対象項目②',
        key: 'flat_35_application',
        source: 'PApplicationHeader',
      },
      {
        name: '物件情報の画像添付',
        key: 'property_information_file',
        source: 'PApplicationHeader',
      },
      {
        name: '物件情報が掲載されたURL添付',
        key: 'property_information_url',
        source: 'PApplicationHeader',
      },
      {
        name: '現在のお住まいの居住年数 （年）',
        key: 'lived_length_year_num',
        source: 'PApplicantPerson',
      },
      {
        name: '現在のお住まいの居住年数 （ヶ月）',
        key: 'lived_length_month_num',
        source: 'PApplicantPerson',
      },
      {
        name: '現在のお住まいの種類',
        key: 'current_residence',
        source: 'PApplicantPerson',
      },
      {
        name: '新しい住居を必要とする理由',
        key: 'reason_acquire_home',
        source: 'PApplicantPerson',
      },
      {
        name: '（新しい住居を必要とする理由〉その他',
        key: 'other_reason_acquire_home',
        source: 'PApplicantPerson',
      },
      {
        name: '持ち家の処分方法',
        key: 'buyingand_selling_schedule_type',
        source: 'PApplicantPerson',
      },
      {
        name: '売却予定価格',
        key: 'expected_house_selling_price',
        source: 'PApplicantPerson',
      },
      {
        name: '現在のお住まいの床面積',
        key: 'current_residence_floor_area',
        source: 'PApplicantPerson',
      },
      {
        name: '所有者の氏名',
        key: 'owner_full_name',
        source: 'PApplicantPerson',
      },
      {
        name: '続柄',
        key: 'owner_relationship',
        source: 'PApplicantPerson',
      },
      {
        name: '〈持ち家の処分方法〉その他',
        key: 'other_buyingand_selling_schedule_type',
        source: 'PApplicantPerson',
      },
      {
        name: '売却予定時期',
        key: 'scheduled_time_sell_house',
        source: 'PApplicantPerson',
      },
      {
        name: 'ローン残高',
        key: 'current_home_loan',
        source: 'PApplicantPerson',
      },
      {
        name: '続柄',
        key: 'relationship_name',
        source: 'PApplicantPerson',
      },
      {
        name: '住宅金融支援機構（旧：公庫）からの融資の有無',
        key: 'loan_from_japan_housing_finance_agency',
        source: '',
      },
    ],
  },

  {
    label: 'step_4_join_guarantor',
    fields: [
      {
        name: '担保提供者の氏名',
        key: 'last_name_kanji',
        source: 'PJoinGuarantor',
      },
      {
        name: '担保提供者の氏名',
        key: 'first_name_kanji',
        source: 'PJoinGuarantor',
      },
      {
        name: '担保提供者の氏名（フリガナ）',
        key: 'last_name_kana',
        source: 'PJoinGuarantor',
      },
      {
        name: '担保提供者の氏名（フリガナ）',
        key: 'first_name_kana',
        source: 'PJoinGuarantor',
      },
      {
        name: '性別',
        key: 'sex',
        source: 'PJoinGuarantor',
      },
      {
        name: '続柄',
        key: 'guarantor_relationship_name',
        source: 'PJoinGuarantor',
      },
      {
        name: '生年月日',
        key: 'birthday',
        source: 'PJoinGuarantor',
      },
      {
        name: '現在の国籍',
        key: 'nationality',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈電話番号〉携帯',
        key: 'mobile_phone_number',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈電話番号〉自宅',
        key: 'home_phone_number',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈現住所〉郵便番号',
        key: 'postal_code',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈現住所〉都道府県',
        key: 'prefecture_kanji',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈現住所〉市区郡',
        key: 'city_kanji',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈現住所〉町村丁目',
        key: 'district_kanji',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈現住所〉丁目以下・建物名・部屋番号',
        key: 'other_address_kanji',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈現在の国籍〉在留カードを添付してください',
        key: 'residence_file',
        source: 'PJoinGuarantor',
      },
      {
        name: '〈現在の国籍〉在留カードを添付してください',
        key: 'residence_file_back_image',
        source: 'PJoinGuarantor',
      },
    ],
  },

  {
    label: 'step_5',
    fields: [
      {
        name: 'あなたや連帯保証人予定者に、現在お借入はありますか',
        key: 'borrowing_status',
        source: 'PApplicantPerson',
      },
      {
        name: '月間の支払金額',
        key: 'land_rent_to_be_paid',
        source: 'PApplicantPerson',
      },
      {
        name: '月間の支払金額',
        key: 'house_rent_to_be_paid',
        source: 'PApplicantPerson',
      },
      {
        name: '月間の支払金額',
        key: 'land_rent_to_be_paid',
        source: 'PApplicationHeader',
      },
      {
        name: '月間の支払金額',
        key: 'house_rent_to_be_paid',
        source: 'PApplicationHeader',
      },
      {
        name: '支払いをしている方',
        key: 'land_rent_to_be_paid_borrower',
        source: 'PApplicationHeader',
      },
      {
        name: '支払いをしている方',
        key: 'house_rent_to_be_paid_borrower',
        source: 'PApplicationHeader',
      },
      {
        name: '完済原資の内容',
        key: 'refund_content',
        source: 'PApplicationHeader',
      },
      {
        name: '完済原資の金額',
        key: 'refund_amount',
        source: 'PApplicationHeader',
      },
      {
        name: '完済原資の種類',
        key: 'completely_repayment_type',
        source: 'PApplicationHeader',
      },
      {
        name: '〈完済原資の種類〉その他',
        key: 'other_complete_repayment_type',
        source: 'PApplicationHeader',
      },
      {
        name: '借入名義人',
        key: 'borrower',
        source: 'PBorrowing',
      },
      {
        name: 'お借入の種類は',
        key: 'borrowing_type',
        source: 'PBorrowing',
      },
      {
        name: '借入先（金融機関）',
        key: 'lender',
        source: 'PBorrowing',
      },
      {
        name: '住宅金融支援機構からの借入ですか',
        key: 'borrowing_from_housing_finance_agency',
        source: 'PBorrowing',
      },
      {
        name: '住宅金融支援機構からの借入ですか',
        key: 'borrowing_from_housing_finance_agency',
        source: 'PBorrowing',
      },
      {
        name: '当初カード契約年月 / 当初借入年月',
        key: 'loan_start_date',
        source: 'PBorrowing',
      },
      {
        name: '借入限度額 / 当初借入額',
        key: 'loan_amount',
        source: 'PBorrowing',
      },
      {
        name: '現在の残高',
        key: 'current_balance_amount',
        source: 'PBorrowing',
      },
      {
        name: '年間返済額',
        key: 'annual_repayment_amount',
        source: 'PBorrowing',
      },
      {
        name: '最終期限 / 最終返済年月',
        key: 'loan_deadline_date',
        source: 'PBorrowing',
      },
      {
        name: '今回のお借入までに完済の予定はありますか',
        key: 'scheduled_loan_payoff',
        source: 'PBorrowing',
      },
      {
        name: 'お借入の目的',
        key: 'loan_purpose',
        source: 'PBorrowing',
      },
      {
        name: '〈お借入の目的〉その他',
        key: 'other_purpose',
        source: 'PBorrowing',
      },
      {
        name: 'カード有効期限',
        key: 'card_expiry_date',
        source: 'PBorrowing',
      },
      {
        name: '賃貸戸（室）数',
        key: 'rental_room_number',
        source: 'PBorrowing',
      },
      {
        name: '共同住宅',
        key: 'common_housing',
        source: 'PBorrowing',
      },
      {
        name: '不動産担保設定',
        key: 'estate_mortgage',
        source: 'PBorrowing',
      },
      {
        name: '完済（予定）年月',
        key: 'expected_repayment_date',
        source: 'PBorrowing',
      },
      {
        name: '土地先行プランに借入詳細に情報が足りないです',
        key: 'borrowing_detail_type',
        source: 'PBorrowing',
      },
    ],
  },

  {
    label: 'step_6',
    fields: [
      {
        name: '物件価格 / マンション価格 / 建物',
        key: 'house_purchase_price',
        source: 'PApplicationHeader',
      },
      {
        name: '諸費用等',
        key: 'additional_cost',
        source: 'PApplicationHeader',
      },
      {
        name: '住宅ローンプラス利用',
        key: 'require_funds_breakdown_mortgage',
        source: 'PApplicationHeader',
      },
      {
        name: '土地',
        key: 'land_purchase_price',
        source: 'PApplicationHeader',
      },
      {
        name: '付帯設備',
        key: 'accessory_cost',
        source: 'PApplicationHeader',
      },
      {
        name: '借換対象ローン残債',
        key: 'refinancing_loan_balance',
        source: 'PApplicationHeader',
      },
      {
        name: '増改築費',
        key: 'house_upgrade_cost',
        source: 'PApplicationHeader',
      },
      {
        name: '預貯金',
        key: 'deposit_savings_1',
        source: 'PApplicationHeader',
      },
      {
        name: '不動産売却代金',
        key: 'real_estate_sale_price',
        source: 'PApplicationHeader',
      },
      {
        name: 'その他（有価証券等） / 有価証券売却など',
        key: 'other_saving_amount',
        source: 'PApplicationHeader',
      },
      {
        name: '親族からの贈与',
        key: 'relative_donation_amount',
        source: 'PApplicationHeader',
      },
      {
        name: 'ペアローン',
        key: 'pair_loan_amount',
        source: 'PApplicationHeader',
      },
      {
        name: 'その他',
        key: 'other_procurement_breakdown',
        source: 'PApplicationHeader',
      },
      {
        name: '詳細を入力ください',
        key: 'other_procurement_breakdown_content',
        source: 'PApplicationHeader',
      },
    ],
  },

  {
    label: 'step_7',
    fields: [
      {
        name: '本人確認書類',
        key: 'identity_verification',
        source: 'PApplicantPerson',
      },
      {
        name: '健康保険証',
        key: 'insurance_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書（1期前）',
        key: 'financial_statement_1_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書（2期前）',
        key: 'financial_statement_2_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書（3期前）',
        key: 'financial_statement_3_file',
        source: 'PApplicantPerson',
      },
      {
        name: '収入に関する書類（1期前）',
        key: 'first_withholding_slip_file',
        source: 'PApplicantPerson',
      },
      {
        name: '収入に関する書類（2期前）',
        key: 'second_withholding_slip_file',
        source: 'PApplicantPerson',
      },
      {
        name: 'その他の書類',
        key: 'other_document_file',
        source: 'PApplicantPerson',
      },
      {
        name: '雇用契約に関する書類',
        key: 'employment_agreement_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書または経営する親族の確定申告書（1期前）',
        key: 'business_tax_return_1_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書または経営する親族の確定申告書（2期前）',
        key: 'business_tax_return_2_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書または経営する親族の確定申告書（3期前）',
        key: 'business_tax_return_3_file',
        source: 'PApplicantPerson',
      },
      {
        name: '確定申告書（1期前）',
        key: 'first_income_file',
        source: 'PApplicantPerson',
      },
      {
        name: '確定申告書（2期前）',
        key: 'second_income_file',
        source: 'PApplicantPerson',
      },
      {
        name: '確定申告書（3期前）',
        key: 'third_income_file',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉表面',
        key: 'driver_license_front_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉裏面',
        key: 'driver_license_back_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉表面',
        key: 'card_number_front_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉裏面',
        key: 'card_number_back_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉表面',
        key: 'resident_register_front_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉裏面',
        key: 'resident_register_back_image',
        source: 'PApplicantPerson',
      },
    ],
  },

  {
    label: 'step_7_income',
    fields: [
      {
        name: '本人確認書類',
        key: 'identity_verification',
        source: 'PApplicantPerson',
      },
      {
        name: '健康保険証',
        key: 'insurance_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書（1期前）',
        key: 'financial_statement_1_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書（2期前）',
        key: 'financial_statement_2_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書（3期前）',
        key: 'financial_statement_3_file',
        source: 'PApplicantPerson',
      },
      {
        name: '収入に関する書類（1期前）',
        key: 'first_withholding_slip_file',
        source: 'PApplicantPerson',
      },
      {
        name: '収入に関する書類（2期前）',
        key: 'second_withholding_slip_file',
        source: 'PApplicantPerson',
      },
      {
        name: 'その他の書類',
        key: 'other_document_file',
        source: 'PApplicantPerson',
      },
      {
        name: '雇用契約に関する書類',
        key: 'employment_agreement_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書または経営する親族の確定申告書（1期前）',
        key: 'business_tax_return_1_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書または経営する親族の確定申告書（2期前）',
        key: 'business_tax_return_2_file',
        source: 'PApplicantPerson',
      },
      {
        name: '会社の決算報告書または経営する親族の確定申告書（3期前）',
        key: 'business_tax_return_3_file',
        source: 'PApplicantPerson',
      },
      {
        name: '確定申告書（1期前）',
        key: 'first_income_file',
        source: 'PApplicantPerson',
      },
      {
        name: '確定申告書（2期前）',
        key: 'second_income_file',
        source: 'PApplicantPerson',
      },
      {
        name: '確定申告書（3期前）',
        key: 'third_income_file',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉表面',
        key: 'driver_license_front_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉裏面',
        key: 'driver_license_back_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉表面',
        key: 'card_number_front_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉裏面',
        key: 'card_number_back_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉表面',
        key: 'resident_register_front_image',
        source: 'PApplicantPerson',
      },
      {
        name: '〈本人確認書類〉裏面',
        key: 'resident_register_back_image',
        source: 'PApplicantPerson',
      },
    ],
  },

  {
    label: 'step_8',
    fields: [
      {
        name: '担当者名',
        key: 's_sale_person_id',
        source: 'PApplicationHeader',
      },
      {
        name: '担当者の名刺はありますか',
        key: 'has_business_card',
        source: '',
      },
      {
        name: '提携会社',
        key: 'sale_agent_id',
        source: '',
      },
      {
        name: 'エリア',
        key: 'store_id',
        source: '',
      },
      {
        name: '展示場',
        key: 'exhibition_id',
        source: '',
      },
      {
        name: '電話番号',
        key: 'phone_number',
        source: '',
      },
    ],
  },
];

export const ERROR_MESSAGE_KEY: Record<string, string> = {
  wrong_format: '{{field}}に入力されたデータのフォーマットは正しくありません。',
  too_long: '{{field}}に{{max_length}}字以内で入力してください。',
  blank: '{{field}}は必須項目なので、入力してください。',
  taken: '{{field}}は既に登録されていますので、別の値を指定してください。',
  must_exist_mobile_or_phone_number:
    '電話番号または携帯電話番号をいずれか入力してください。',
  not_a_number: '{{field}}に数字のみ入力してください。',
  greater_than_or_equal_to:
    '{{field}}に{{max_length}}以上の値を入力してください。',
  less_than_or_equal_to:
    '{{field}}に{{max_length}}以下の値を入力してください。',
  greater_than: '{{field}}に{{max_length}}より大きい値を入力してください。',
  less_than: '{{field}}に{{max_length}}より小さい値を入力してください。',
  invalid_date_format:
    '{{field}}に入力されたデータは日付のフォーマットではありません。',
  must_be_divisible_by_ten: '{{field}}に金額は10万円単位で入力してください。',
  invalid_selection: '{{field}}に選択された選択肢は正しくありません。',
  housemate_number_must_equal_to_planned_cohabitant_people_number:
    '入居予定者の合計は正しくありません。',
  must_exist_url_or_property_information_file:
    '新しい住居の物件情報について資料、またはURLのいずれか添付してください。',
  must_exist_mobile_or_home_phone_number:
    '自宅電話番号または携帯電話番号をいずれか入力してください。',
  invalid: '入力されたデータは正しくありません。',
  // not_permission: 'あなたはこの申し込みの内容を編集する権限を持っていません。',
  not_permission: 'あなたはこの申し込みを閲覧する権限を持っていません。',
  assignSalePerson_not_permission:
    'あなたはこの申し込みに営業担当者をアサインする権限がありません。',
};

export const STATUS_LINKED_INTERFACE = 4;

export const PROVISIONAL_RESULTS_DEFAULT: ProvisionalResults[] = [
  {
    key: '承認',
    value: 0,
    active: false,
  },
  {
    key: '条件付承認',
    value: 1,
    active: false,
  },
  {
    key: '否決',
    value: 2,
    active: false,
  },
];

export const MESSAGE_REGEX = {
  NAME_KANA: '全角カタカナが入力可能です。',
  SP_KANA_FULL_WIDTH: '全角カタカナ・英文字が入力可能です。',
  SP_KANJI_FULL_WIDTH: '漢字・ひらがな・カタカナ・英文字が入力可能です。',
  SP_KANJI_FULL_WIDTH_HAVE_NUMBER:
    '漢字・ひらがな・カタカナ・英数字が入力可能です。',
  ADDRESS_KANJI: '漢字・ひらがな・カタカナ・英数字が入力可能です。',
  KANJI_FULL_WIDTH:
    'この項目は漢字、全角のひらがな・カタカナ・英文字が入力可能です。',
  KANJI_FULL_WIDTH_HAVE_NUMBER:
    'この項目は漢字、全角のひらがな・カタカナ・英文字が入力可能です。',
  KANA_HALF_WIDTH_HAVE_SPACE: 'この項目は全角カタカナが入力可能です。',
  KANA_HALF_WIDTH_HAVE_NUMBER: 'この項目は全角カタカナ・英数字が入力可能です。',
  TYPE_NUMBER: '半角数字でご入力ください。',
  ENTER_CORRECTLY: '正しくご入力ください。',
  EMERGENCY_PHONE_ADMIN:
    '緊急電話番号に携帯電話番号か電話番号で入力してください。',
  MOBILE_PHONE_ADMIN: '「090」「080」「070」から始まる番号で入力してください。',
  FIXED_PHONE_ADMIN: '電話番号は正しく入力してください。',
  MOBILE_PHONE_SP:
    '携帯電話は「090」「080」「070」から始まる番号で入力してください。',
  MAX_LENGTH_MOBILE_PHONE_ADMIN: '携帯電話番号は正しく入力してください。',
  MAX_LENGTH_FIXED_PHONE_ADMIN: '電話番号は正しく入力してください。',
  FIXED_PHONE_SP: '電話番号を正しく入力ください。',
  MAX_LENGTH_MOBILE_PHONE_SP: '携帯電話に数字11桁で入力してください。',
  MAX_LENGTH_FIXED_PHONE_SP: '電話番号に数字10桁で入力してください。',
  PLEASE_SELECT_A_DATE_AFTER_TODAY: '本日以降の日を選択してください。',
  PLEASE_SELECT_MONTH_AFTER_TODAY: '申込日兼同意日以降の月を指定してください。',
  PLEASE_SELECT_MONTH_AFTER_LOAN_DESIRED_BORROWING_DATE:
    '入居予定年月は借入希意日以降の年月を指定してください。',
  PLEASE_SELECT_ESTABLISHMENT_DATE_BEFORE_TODAY:
    '勤務先設立年月日は申込日以前の日を指定してください。',
  EMPLOYMENT_STARTED_DATE_BEFORE_TODAY:
    '入社年月は申込日以前の年月を指定してください。',
  PLEASE_SPECIFY_IN_THE_PAST_PERIOD:
    '${path}は借入希望日以降の日を指定してください。',
  PLEASE_SPECIFY_IN_THE_FUTURE_PERIOD:
    '${path}は借入希望日以前の日を指定してください。',
  PLEASE_SPECIFY_AFTER_CREATED_AT:
    '${path}は申込日以降の年月を指定してください。',
  PLEASE_SPECIFY_BEFORE_CREATED_AT:
    '${path}は申込日以前の年月を指定してください。',
  REQUIRED: 'この項目を入力してください。',
  DROPDOWN_REQUIRED: 'お客様に該当する項目を選択してください。',
  DROPDOWN_SELECT_REQUIRED: '${path}を選択してください。',
  DROPDOWN_LOAN_PURPOSE: 'お借入の目的を選択してください。',
  RADIO_REQUIRED: 'どれか1つを選択してください。',
  ZIP_CODE: '郵便番号は〇〇〇-〇〇〇〇の形式で入力してください。',
  EMAIL: 'メールアドレスの形式が正しくありません。',
  EMAIL_SP: '有効なメールアドレスを入力してください。',
  ENTER_UNITS_100000YEN: '金額は10万円単位で入力してください',
  PLEASE_SELECT_A_DATE_AFTER_ACQUISITION_START_TIME:
    '取得開始時期以降の日を指定してください。',
  LOAN_TERM_LIMIT:
    '借入時満18歳以上満65歳以下・完済時満80歳未満の方がご利用いただけます。',
  ERROR_AREA:
    '建物の延べ床面積は土地の敷地面積より小さい数字を入力してください。',
  ERROR_AREA_SECOND:
    'マンション全体の延べ床面積は専有面積より小さい数字を入力してください。',
  APPLICATION_DATE_MUST_BE_LESS_THAN_DESIRED_LOAN_DATE:
    '申込日はお借入希望日以前の日を指定してください。',
  DESIRED_LOAN_DATE_MUST_BE_MORE_THAN_APPLICATION_DATE:
    'お借入希望日は申込日以降の日を指定してください。',
  PLEASE_SELECT_BANK_BUSINESS_DAY: '銀行営業日を選んでください。',
  NO_ALL_ZEROS_PHONE_NUMBER: '電話番号に全て０で入力しないでください。',
  LOAN_DESIRED_BORROWRING_DATE:
    '２回目融資のお借入希望日は１回目融資のお借入希望日以降の日を指定してください。',
  TEMPORARY_DESIRED_LOAN_AMOUNT:
    '1回目と2回目のお借入希望額の合計が500万円〜2億円になるようにご入力ください。',
  ABOUT_TEMPORARY_DESIRED_LOAN_AMOUNT:
    '500万円〜2億円の範囲内でご入力ください。',
  SPECIFY_A_PAST_TIME_TO_START_COLLECT_MATERNITY_LEAVE:
    '産休・育休の取得開始時期は借入希望日以前の年月を指定してください。',
  SPECIFY_A_FUTURE_TIME_TO_START_COLLECT_MATERNITY_LEAVE:
    '産休・育休の取得開始時期は借入希望日以降の年月を指定してください。',
  SPECIFY_A_PAST_TIME_TO_END_COLLECT_MATERNITY_LEAVE:
    '産休・育休の取得終了時期は借入希望日以前の年月を指定してください。',
  SPECIFY_A_FUTURE_TIME_TO_END_COLLECT_MATERNITY_LEAVE:
    '産休・育休の取得終了時期は借入希望日以降の年月を指定してください。',
  SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_LOAN_DATE:
    '最終期限年月は当初借入年月以降の年月を指定してください。',
  SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_CARD_CONTRACT_DATE:
    'カード有効期限年月は当初カード契約年月以降の年月を指定してください。',
  ENTER_ZIPCODE_SEVEN_DIGITS: '郵便番号は数字7桁で入力してください。',
  NOT_PASS_CURRENT: '入社年月は過去の年月を指定してください。',
  URL_INVALID: 'URLの形式が正しくありません。',
  DATE_INVALID: '日付の形式が正しくありません。',
  GREATER_THAN_ZERO: '0より大きい数値でご入力ください。',
  OTHER_TYPE_TAX_RETURN_LIMIT:
    '確定申告の理由 その他に40字以内で入力してください。',
  OTHER_SELLING_SCHEDULE_TYPE_LIMIT:
    '持家 処分方法（その他）に40字以内で入力してください。',
  BUSINESS_ABILITY: '賃貸のみの目的は利用できません。',
  OTHER_PROCUREMENT_LIMIT:
    '調達資金内訳 その他名に128字以内で入力してください。',
  MOBILE_PHONE_ADMIN_WITH_DASH: '電話番号は正しく入力してください。',
  NAME_RELATIONSHIP_INCOME: '漢字・ひらがな・カタカナ・英数字が入力可能です。',
  OTHER_NAME_RELATIONSHIP_INCOME:
    '漢字・ひらがな・カタカナ・英数字が入力可能です。',
  MOBILE_PHONE_ADMIN_NEW: '電話番号は正しく入力してください。',
};

export const MANAGER_ROLE = {
  DEFAULT: '',
  ADMIN: 'admin',
  GENERAL_ACCOUNT: 'general_account',
};

export const OTHER_ERROR: Record<string, string> = {
  PApplicantPerson:
    '収入合算者にご入力いただいた情報は申込者から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  PJoinGuarantor:
    '担保提供者にご入力いただいた情報は申込者から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  PResident:
    '入居予定者にご入力いただいた情報は申込者から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  PBorrowingDetail:
    '2回目の融資は申込者から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  PBorrowing:
    '現在の借入状態にご入力いただいた情報は申込者から削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  PApplicationHeader:
    '申し込む金融機関は削除されましたので、保存できません。最新のデータを取得するために、ページをリフレッシュしてください。',
  //'見つかりません。',
};

export const BANK_NOT_VALID_DAYS = ['01/01', '01/02', '01/03', '12/31'];

export const APPLICATION_STATUS_LIST_DEFAULT = [
  {
    key: '仮審査否決',
    value: 0,
    active: false,
    enable: false,
  },
  {
    key: '本審査',
    value: 1,
    active: false,
    enable: false,
  },
  {
    key: '本審査否決',
    value: 2,
    active: false,
    enable: false,
  },
  {
    key: '融資実行済み',
    value: 3,
    active: false,
    enable: false,
  },
  {
    key: '他行借入',
    value: 4,
    active: false,
    enable: false,
  },
  {
    key: '自宅購入取止め',
    value: 5,
    active: false,
    enable: false,
  },
];

export const ROWS_PER_PAGE = 20;
export const SBI_NAME = '住信ＳＢＩネット銀行';

export const SP_FIELD_ERROR_MESSAGE = {
  // Step 1
  header_scheduled_date_moving: '入居予定年月',
  borrowing_detail_loan_desired_borrowing_date: 'お借入希望日',
  borrowing_detail_desired_monthly_bonus: 'ボーナス返済月',
  borrowing_detail_loan_term_year_num: 'お借入期間 ',

  // Step 2
  applicant_people_prefecture_kanji: '現住所',
  applicant_people_birthday: '生年月日',

  // Step 3
  applicant_people_occupation: 'ご職業',
  applicant_people_industry: '業種',
  applicant_people_occupation_detail: '職種',
  applicant_people_office_prefecture_kanji: '勤務先の住所',
  applicant_people_employment_started_date: '入社年月（事業を開始した年月）',
  applicant_people_transfer_office_prefecture_kanji: '出向（派遣）先　住所',
  applicant_people_maternity_paternity_leave_start_time: '取得開始時期',
  applicant_people_maternity_paternity_leave_end_time: '取得終了時期',

  // Step 4 join_guarantor
  join_guarantor_birthday: '生年月日',
  join_guarantor_prefecture_kanji: '担保提供者の住所',

  // Step 4
  applicant_people_lived_length_year_num: '現在のお住まいの居住年数',
  applicant_people_current_residence: '現在のお住まいの種類',
  applicant_people_reason_acquire_home: '新しい住居を必要とする理由',
  header_collateral_prefecture: 'ご購入物件の所在地',

  // Step 5
  borrowing_type: 'お借入の種類は',
  borrowing_loan_purpose: 'お借入の目的',
  borrowing_loan_start_date_card_loan: '当初カード契約年月',
  borrowing_card_expiry_date: 'カード有効期限',
  borrowing_loan_start_date: '当初借入年月',
  borrowing_loan_deadline_date_housing_loan: '最終返済年月',
  borrowing_loan_deadline_date: '最終期限',
  borrowing_expected_repayment_date: '完済（予定）年月',

  // manager + sale-person
  birthday: '生年月日',
  prefecture_kanji: '都道府県',
  transfer_office_prefecture_kanji: '出向（派遣）先 都道府県',
};

export const ERROR_KEY: Record<string, string> = {
  greater_than_created_at: 'は申込日以前の日を指定してください。',
  less_than_created_at: 'は申込日以降の日を指定してください。',

  greater_than_loan_desired_borrowing_date:
    'は借入希望日以前の日を指定してください。',
  less_than_loan_desired_borrowing_date:
    'は借入希望日以降の日を指定してください。',

  greater_than_card_expiry_date: 'は当初借入年月以前の年月を指定してください。',
  greater_than_loan_deadline_date: 'は借入希望日以前の日を指定してください。',

  less_than_or_equal_to_loan_desired_borrowing_date:
    'は借入希望日以降の日を指定してください。',
  less_than_or_equal_to_loan_start_date:
    'は当初カード契約年月/当初借入年月以降の日を指定してください。',
};

export const TEXT_DOCUMENT_EMPTY = '〈 書類はまだ添付されません。〉';
export const FILE_NOT_EXIST =
  'アップロードした書類にエラーがあります。再度アップロードしてください。';
