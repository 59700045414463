import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeNames } from '../navigations/routes';
import { useCheckCorrectable } from './useCheckCorrectable';

export const useGoBackTop = () => {
  const navigation = useNavigate();
  const correctableStatus = useCheckCorrectable();

  useEffect(() => {
    if (!correctableStatus) {
      navigation(routeNames.Top.path, { replace: true });
    }
  }, [correctableStatus]);
};
