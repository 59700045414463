import { AxiosRequestConfig } from 'axios';
import { internalInstance } from 'libs';
import {
  dynamicOptionsResponseSchema,
  GetDynamicOptionsResponse,
} from './schemas';

export const getDynamicOptions = (): Promise<GetDynamicOptionsResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/dynamic_options',
  };
  return internalInstance(request).then(({ data }) =>
    dynamicOptionsResponseSchema.parse(data)
  );
};
