import { FC, useCallback, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { AdminLayout } from 'containers';
import { Icons } from 'components';
import { useAppDispatch } from 'hooks';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { StatusTable } from './components/StatusTable';
import { getListStatus, updateStatus } from './thunk';
import { StatusItemWithPhaseId } from './components/StatusItems';
import {
  fullNameKanjiSelectors,
  underReviewStatusSelectors,
} from './selectors';

const StatusScreenPage: FC = () => {
  const underReviewStatus = useSelector(underReviewStatusSelectors);
  const fullNameKanji = useSelector(fullNameKanjiSelectors);
  const dispatch = useAppDispatch();

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const preliminaryId = query.get('preliminary_id');

  useEffect(() => {
    if (preliminaryId) {
      dispatch(
        getListStatus({
          id: preliminaryId,
        })
      );
    }
  }, [dispatch, preliminaryId]);

  const onUpdateStatus = useCallback(
    async (value: number) => {
      if (preliminaryId) {
        const result = await dispatch(
          updateStatus({
            id: preliminaryId,
            under_review_status: value.toString(),
          })
        );
        if (updateStatus.fulfilled.match(result)) {
          toast.success('更新しました。');
        } else {
          toast.error('エラーが発生しました。');
        }
        await dispatch(
          getListStatus({
            id: preliminaryId,
          })
        );
      }
    },
    [dispatch, preliminaryId]
  );

  return (
    <AdminLayout
      hasDrawer={false}
      header={{
        left: (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              px: 5,
              py: 2,
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="text_header_memo"
                color="normal_text"
                sx={{ whiteSpace: 'nowrap' }}
              >
                審査状況　［ {fullNameKanji} 様 ］
              </Typography>
            </Stack>
          </Stack>
        ),
        right: {
          contentPopover: null,
        },
      }}
      footer={{
        left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
        right: <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />,
        bgcolor: 'footer_bg',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <StatusTable
          rows={statuses}
          onClick={onUpdateStatus}
          underReviewStatus={underReviewStatus}
        />
      </Box>
    </AdminLayout>
  );
};

const statuses: { data: StatusItemWithPhaseId[] }[] = [
  {
    data: [
      {
        value: 1,
        key: '個信照会待ち（仮審査：債務者）',
        phaseId: 'PP060',
        active: false,
      },
      {
        value: 2,
        key: '個信照会待ち（仮審査：連保人）',
        phaseId: 'PP061',
        active: false,
      },
      {
        value: 3,
        key: '個信照会結果（仮審査）',
        phaseId: 'PP062',
        active: false,
      },
      {
        value: 4,
        key: '個信エラー（仮審査）',
        phaseId: 'PP063',
        active: false,
      },
      { value: 5, key: '仮審査前反社照会', phaseId: 'PP004', active: false },
    ],
  },
  {
    data: [
      {
        value: 6,
        key: '個信サマリー再計算（仮審査）',
        phaseId: 'PP069',
        active: false,
      },
      {
        value: 7,
        key: '仮審査②（自動審査情報設定）',
        phaseId: 'PP006',
        active: false,
      },
      {
        value: 8,
        key: '仮審査①（EUC審査データ作成）',
        phaseId: 'PP043',
        active: false,
      },
      { value: 9, key: '仮審査①', phaseId: 'PP005', active: false },
      {
        value: 10,
        key: '仮審査②（自動審査依頼）',
        phaseId: 'PP035',
        active: false,
      },
      {
        value: 11,
        key: '仮審査②（自動審査結果取得）',
        phaseId: 'PP036',
        active: false,
      },
      { value: 12, key: 'P仮審査_申請', phaseId: 'PP007', active: false },
    ],
  },
  {
    data: [
      { value: 13, key: '仮審査（審査役）', phaseId: 'PP008', active: false },
      { value: 14, key: '仮審査（審査部長）', phaseId: 'PP009', active: false },
      { value: 15, key: '仮審査（審査役員）', phaseId: 'PP010', active: false },
      { value: 16, key: '仮審査（経営会議）', phaseId: 'PP011', active: false },
    ],
  },
  {
    data: [
      {
        value: 17,
        key: '通知先・保証判定（仮審査）',
        phaseId: 'PP091',
        active: false,
      },
      {
        value: 18,
        key: '保証会社審査依頼（仮審査）',
        phaseId: 'PP092',
        active: false,
      },
      {
        value: 19,
        key: '保証会社審査依頼エラー（仮審査）',
        phaseId: 'PP093',
        active: false,
      },
      {
        value: 20,
        key: '保証会社審査結果待ち（仮審査）',
        phaseId: 'PP094',
        active: false,
      },
    ],
  },
  {
    data: [
      {
        value: 21,
        key: '保証決裁（業務）（仮審査）',
        phaseId: 'PP095',
        active: false,
      },
      {
        value: 22,
        key: '保証決裁（審査）（仮審査）',
        phaseId: 'PP096',
        active: false,
      },
      { value: 23, key: 'P仮審査_終了案内', phaseId: 'PP012', active: false },
    ],
  },
];

export default StatusScreenPage;
