import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRight: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="-5 0 17 10" {...props}>
    <svg
      width="5"
      height="9"
      viewBox="0 0 5 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.916504 0.722229L4.24984 4.33334L0.916504 7.94445"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
