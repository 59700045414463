import {
  FC,
  useContext,
  useEffect,
  useMemo,
  FocusEvent,
  ChangeEvent,
  useCallback,
  useState,
  useRef,
} from 'react';
import { TextField } from 'formik-mui';
import { FastField, FastFieldProps } from 'formik';
import { Stack, SxProps, TextFieldProps, Typography } from '@mui/material';
import { isHiragana, toKatakana } from 'wanakana';
import { NumpadContext } from 'context';
import { BasePlacement } from '@popperjs/core/lib/enums';
import { convertToFullWidth, convertToHalfWidth } from 'utils';
import { debounce } from 'lodash';
import get from 'lodash/get';
import { NumberFormat, NumberFormatDecimal } from '../NumberFormat';
import { Popover } from '../../Popover';

export type InputFieldProps = TextFieldProps & {
  name: string;
  placeholder?: string;
  unit?: string;
  align?: 'left' | 'right';
  useNumberFormat?: boolean;
  convertKatakana?: boolean;
  toFixedNumber?: boolean;
  labelSpacing?: number;
  numeric?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  placement?: BasePlacement | 'top-end' | 'top-start' | 'bottom-start';
  maxLength?: number;
  convertHalfWidth?: boolean;
  showPassword?: boolean;
  convertFullWidth?: boolean;
  decimalScale?: number;
  sxPopover?: SxProps;
  autoTrim?: boolean;
};

export const InputFieldTemp: FC<InputFieldProps & FastFieldProps> = ({
  field,
  form,
  meta,
  label,
  InputProps,
  unit,
  useNumberFormat,
  convertKatakana,
  toFixedNumber,
  labelSpacing = 1,
  numeric,
  align = 'left',
  errorMessage,
  readOnly,
  onChange,
  placement = 'top-end',
  maxLength,
  convertHalfWidth,
  convertFullWidth,
  decimalScale,
  autoTrim = true,
  ...props
}) => {
  const { numPadOpen, name, setName, value, setValue } =
    useContext(NumpadContext);
  const [open, setOpen] = useState(false);
  const isSuccess = useMemo(
    () => !meta.error && meta.value,
    [meta.error, meta.value]
  );

  useEffect(() => {
    if (name === props.name && numPadOpen && !readOnly) {
      form.setFieldValue(props.name, value);
    }
  }, [form, name, numPadOpen, props.name, readOnly, value]);

  useEffect(() => {
    if (name === props.name) {
      setValue(meta.value);
    }
  }, [meta.value, name, props.name, setValue]);

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setName(e.target.name);
      setValue(e.target.value);
    },
    [setName, setValue]
  );

  const formatNumber = useCallback(
    (number: string) => {
      if (number === '') return number;
      if (/^0*$/.test(number)) return '0';
      if (toFixedNumber) return Number(number).toFixed(2);
      return Number(number);
    },
    [toFixedNumber]
  );

  const handleBlur = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      await field.onBlur(e);
      const value = autoTrim ? meta.value.trim() : meta.value;
      form.setFieldValue(props.name, value);
      if (convertHalfWidth) {
        if (isHiragana(value) && convertKatakana) {
          const katakana = toKatakana(value);
          form.setFieldValue(props.name, convertToHalfWidth(katakana));
        } else {
          form.setFieldValue(props.name, convertToHalfWidth(value));
        }
      }
      if (convertFullWidth) {
        form.setFieldValue(props.name, convertToFullWidth(value));
      }
      if (useNumberFormat) {
        form.setFieldValue(props.name, formatNumber(value));
      }
      props.onBlur && props.onBlur(e);
    },
    [
      field,
      autoTrim,
      meta.value,
      form,
      props,
      convertHalfWidth,
      convertFullWidth,
      useNumberFormat,
      convertKatakana,
      formatNumber,
    ]
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      await field.onChange(event);
      onChange && onChange(event);
    },
    [field, onChange]
  );

  const debouncedFunction = useRef(
    debounce((error, touched, value) => {
      // @ts-ignore
      if (!!error && !!value) {
        return setOpen(!!error && !!value);
      }
      return setOpen(!!error && !!touched);
    }, 250)
  );

  const debounceError = useCallback(
    // @ts-ignore
    (error, touched, value) => {
      return debouncedFunction.current(error, touched, value);
    },
    []
  );

  useEffect(() => {
    debounceError(meta.error, meta.touched, meta.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.error, meta.touched, meta.value]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debouncedFunction.current?.cancel();
    };
  }, []);

  const firstTouchField = useMemo(() => {
    return !meta.touched && !meta.value;
  }, [meta.touched, meta.value]);

  return (
    <Stack spacing={labelSpacing}>
      {!!label && (
        <Typography variant="h3" color="normal_text">
          {label}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" spacing={3}>
        <Popover
          content={meta.error ?? errorMessage ?? ''}
          contentProps={{ color: 'main_white' }}
          open={firstTouchField ? false : meta.error ? open : false}
          placement={placement}
          arrowSx={{
            borderColor: 'sh_red',
            bgcolor: 'sh_red',
          }}
          sx={{
            top: -5,
            right: 0,
            maxWidth: 250,
            borderColor: 'sh_red',
            bgcolor: 'sh_red',
            ...props.sxPopover,
          }}
        >
          <Stack flex={1}>
            <TextField
              form={form}
              field={field}
              meta={meta}
              sx={{ width: '100%', ...props.sx }}
              {...props}
              InputProps={{
                ...(useNumberFormat && {
                  inputComponent: (decimalScale
                    ? NumberFormatDecimal
                    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      NumberFormat) as any,
                }),
                ...InputProps,
                autoComplete: 'off',
                sx: {
                  ...InputProps?.sx,
                  '.MuiInputBase-input': {
                    textAlign: align,
                    boxShadow: !readOnly
                      ? 'inset 0px 2px 8px rgba(0, 0, 0, 0.25)'
                      : undefined,
                    backgroundColor: !readOnly ? 'main_white' : undefined,
                    borderRadius: 1,
                  },
                  '&&&& fieldset': {
                    borderWidth: readOnly ? '1px' : undefined,
                  },
                },
                className: `${isSuccess && !readOnly ? 'Mui-success' : ''} ${
                  name === props.name && numPadOpen ? 'Mui-focused' : ''
                }`,
                onBlur: handleBlur,
                onFocus: handleFocus,
                onChange: handleChange,
              }}
              inputProps={{
                ...(numeric && {
                  inputMode: 'numeric',
                }),
                readOnly: readOnly ?? numPadOpen,
                maxLength: maxLength,
              }}
            />
          </Stack>
        </Popover>
        {!!unit && (
          <Typography
            variant="unit_text"
            color="normal_text"
            whiteSpace="nowrap"
          >
            {unit}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const InputField: FC<InputFieldProps> = ({ showPassword, ...props }) => {
  const shouldUpdate = useCallback(
    (
      nextProps: { showPassword: boolean },
      prevProps: { showPassword: boolean }
    ) =>
      nextProps.showPassword !== prevProps.showPassword ||
      get(nextProps, `formik.values.${props.name}`) !==
        get(prevProps, `formik.values.${props.name}`) ||
      get(nextProps, `formik.errors.${props.name}`) !==
        get(prevProps, `formik.errors.${props.name}`) ||
      get(nextProps, `formik.touched.${props.name}`) !==
        get(prevProps, `formik.touched.${props.name}`),
    [props.name]
  );
  return (
    <FastField
      name={props.name}
      showPassword={showPassword}
      shouldUpdate={showPassword !== undefined ? shouldUpdate : undefined}
    >
      {(ffProps: FastFieldProps) => {
        return <InputFieldTemp {...props} {...ffProps} />;
      }}
    </FastField>
  );
};
