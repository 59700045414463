import { FC, useEffect, useRef, useCallback } from 'react';
import { Stack } from '@mui/material';
import { InputField, FormItem } from 'components';
import { useFormikContext } from 'formik';
import { StepSynthesisForm } from 'types';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { checkMCJ } from '../selectors';

type InputProcurementType = {
  saving_amount: string;
  relative_donation_amount: string;
  loan_amount: string;
  pair_loan_amount: string;
  other_procurement_breakdown: string;
};

type InputMCJType = {
  deposit_savings_1: string;
  deposit_savings_2: string;
  real_estate_sale_price: string;
  other_saving_amount: string;
};

const StepOnePageF: FC = () => {
  const isMCJ = useSelector(checkMCJ);
  const { values, setFieldValue } = useFormikContext<StepSynthesisForm>();
  const {
    saving_amount,
    relative_donation_amount,
    loan_amount,
    pair_loan_amount,
    other_procurement_breakdown,
    deposit_savings_1,
    deposit_savings_2,
    real_estate_sale_price,
    other_saving_amount,
  } = values.p_application_header;

  const debouncedTotalProcurementFunction = useRef(
    debounce((total) => {
      setFieldValue('p_application_header.total_procurement', total);
    }, 250)
  );

  const debouncedCalculateTotalProcurement = useCallback(
    // @ts-ignore
    (total) => {
      return debouncedTotalProcurementFunction.current(total);
    },
    []
  );

  const debouncedSavingAmountFunction = useRef(
    debounce((total) => {
      setFieldValue('p_application_header.saving_amount', total);
    }, 250)
  );

  const debouncedCalculateSavingAmount = useCallback(
    // @ts-ignore
    (total) => {
      return debouncedSavingAmountFunction.current(total);
    },
    []
  );

  useEffect(() => {
    const params: InputProcurementType = {
      saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
    };
    debouncedCalculateTotalProcurement(calculateTotal(params));
  }, [
    saving_amount,
    relative_donation_amount,
    loan_amount,
    pair_loan_amount,
    other_procurement_breakdown,
    debouncedCalculateTotalProcurement,
  ]);

  useEffect(() => {
    const params: InputMCJType = {
      deposit_savings_1,
      deposit_savings_2,
      real_estate_sale_price,
      other_saving_amount,
    };
    if (isMCJ) {
      debouncedCalculateSavingAmount(calculateTotalMCJ(params));
    }
  }, [
    deposit_savings_1,
    deposit_savings_2,
    real_estate_sale_price,
    other_saving_amount,
    debouncedCalculateSavingAmount,
    isMCJ,
  ]);

  return (
    <Stack justifyContent="center">
      {isMCJ ? (
        <FormItem label="自己資金" divider required>
          <Stack spacing="34px">
            <Stack direction="row">
              <InputField
                label="預貯金（金融機関1）"
                labelSpacing={5}
                sx={{ width: { mobile: '100%', tablet: 300 } }}
                name="p_application_header.deposit_savings_1"
                align="right"
                placeholder="0"
                unit="万円"
                numeric
                useNumberFormat
              />
            </Stack>
            <Stack direction="row">
              <InputField
                label="預貯金（金融機関2）"
                labelSpacing={5}
                sx={{ width: { mobile: '100%', tablet: 300 } }}
                name="p_application_header.deposit_savings_2"
                align="right"
                placeholder="0"
                unit="万円"
                numeric
                useNumberFormat
              />
            </Stack>
            <Stack direction="row">
              <InputField
                label="不動産売却代金"
                labelSpacing={5}
                sx={{ width: { mobile: '100%', tablet: 300 } }}
                name="p_application_header.real_estate_sale_price"
                align="right"
                placeholder="0"
                unit="万円"
                numeric
                useNumberFormat
              />
            </Stack>
            <Stack direction="row">
              <InputField
                label="その他（有価証券等）"
                labelSpacing={5}
                sx={{ width: { mobile: '100%', tablet: 300 } }}
                name="p_application_header.other_saving_amount"
                align="right"
                placeholder="0"
                unit="万円"
                numeric
                useNumberFormat
              />
            </Stack>
            <Stack direction="row">
              <InputField
                label="自己資金合計"
                labelSpacing={5}
                sx={{ width: { mobile: '100%', tablet: 300 } }}
                name="p_application_header.saving_amount"
                align="right"
                placeholder="0"
                unit="万円"
                numeric
                useNumberFormat
                readOnly
              />
            </Stack>
          </Stack>
        </FormItem>
      ) : (
        <FormItem divider label="自己資金" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.saving_amount"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      <FormItem divider label="親族からの贈与" required>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="p_application_header.relative_donation_amount"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            useNumberFormat
          />
        </Stack>
      </FormItem>

      <FormItem divider label="本件ローン" required>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="p_application_header.loan_amount"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            useNumberFormat
          />
        </Stack>
      </FormItem>

      <FormItem divider label="ペアローン" required>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="p_application_header.pair_loan_amount"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            useNumberFormat
          />
        </Stack>
      </FormItem>

      <FormItem label="その他" required>
        <Stack spacing="35px" sx={{ mb: '57px' }}>
          <InputField
            name={'p_application_header.other_procurement_breakdown_content'}
            placeholder="ー"
          />
          <Stack width={{ mobile: '100%', tablet: 300 }}>
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.other_procurement_breakdown"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </Stack>
      </FormItem>

      <FormItem label="合計" required>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="p_application_header.total_procurement"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            useNumberFormat
            readOnly
          />
        </Stack>
      </FormItem>
    </Stack>
  );
};

export default StepOnePageF;

const calculateTotal = ({
  saving_amount,
  relative_donation_amount,
  loan_amount,
  pair_loan_amount,
  other_procurement_breakdown,
}: InputProcurementType) => {
  return (
    +saving_amount +
      +relative_donation_amount +
      +loan_amount +
      +pair_loan_amount +
      +other_procurement_breakdown || 0
  );
};

const calculateTotalMCJ = ({
  deposit_savings_1,
  deposit_savings_2,
  real_estate_sale_price,
  other_saving_amount,
}: InputMCJType) => {
  return (
    +deposit_savings_1 +
      +deposit_savings_2 +
      +real_estate_sale_price +
      +other_saving_amount || 0
  );
};
