import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Error: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33975 18L13 3L21.6603 18H4.33975Z"
        fill="#DB2828"
        stroke="#DB2828"
        strokeWidth="3"
      />
      <path
        d="M13.5956 14.2329C13.583 13.9939 13.5704 13.6919 13.5704 13.5535C13.5704 13.5032 13.5704 13.4403 13.5704 13.3397C13.583 13.239 13.583 13.0629 13.583 12.8364L13.734 8.47096C13.7466 7.96773 13.7717 7.47709 13.822 6.99902H12.1237C12.174 7.38902 12.1991 7.94257 12.2243 8.47096L12.3627 12.8364C12.3879 13.4026 12.3879 13.4026 12.3879 13.6039C12.3879 13.8429 12.3753 13.9687 12.3501 14.2329H13.5956ZM13.7717 16.8371V15.3274H12.1991V16.8371H13.7717Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
