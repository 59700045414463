import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasterData } from 'services';
import { handleShowError } from 'utils';
import { PreliminariesStatus } from 'constant';
import {
  assignManager,
  getCurrentPreliminariesItems,
  assignSalePersons,
  assignStoreName,
} from './thunk';

export type Selected = {
  id: string;
  applicant_id: string;
  user_id: string;
  applicant_name: string;
  application_number: string;
  bank_name: string;
  created_at: string;
  loan_desired_borrowing_date: string;
  manager_name_kanji: string;
  sale_person_name_kanji: string;
  store_name_kanji: string;
  temporary_desired_loan_amount: string;
  status_result: string;
  provisional_result: string;
  list_managers: { id: string; name_kanji: string; active: boolean }[];
  loan_type: string;
  loan_apply_date: string;
};

type ManagerPreliminary = {
  id: string;
  applicant_id: string;
  user_id: string;
  applicant_name: string;
  application_number: string;
  bank_name: string;
  created_at: string;
  loan_desired_borrowing_date: string;
  manager_name_kanji: string;
  sale_person_name_kanji: string;
  store_name_kanji: string;
  exhibition_hall: string;
  temporary_desired_loan_amount: string;
  status_result: string;
  provisional_result: string;
  list_managers: { id: string; name_kanji: string; active: boolean }[];
  loan_type: string;
  loan_apply_date: string;
  application_status_lists: {
    key: string;
    value: number;
    active: boolean;
    enable: boolean;
  }[];
  list_sale_people: { id: string; name_kanji: string; active: boolean }[];
  p_referral_agency_path: string;
  list_store: { id: string; store_name_kanji: string; path: string }[];
  unread_messages?: string;
  linking: string;
};
export interface InitialState {
  fetching: boolean;
  currentPreliminaries: ManagerPreliminary[];
  selected: Selected;
  masterData: MasterData;
  total: number;
  activeTab: PreliminariesStatus;
}

const defaultSelected = {
  id: '',
  applicant_id: '',
  user_id: '',
  applicant_name: '',
  application_number: '',
  bank_name: '',
  created_at: '',
  loan_desired_borrowing_date: '',
  manager_name_kanji: '',
  sale_person_name_kanji: '',
  store_name_kanji: '',
  temporary_desired_loan_amount: '',
  status_result: '',
  provisional_result: '',
  list_managers: [{ id: '', name_kanji: '', active: false }],
  list_sale_people: [],
  p_referral_agency_path: '',
  list_store: [],
  loan_type: '',
  loan_apply_date: '',
};

const initialState: InitialState = {
  fetching: false,
  currentPreliminaries: [],
  selected: defaultSelected,
  masterData: {
    branch_names: [],
    exhibition_hall_names: [],
    office_names: [],
    sale_person_names: [],
    sale_agent_names: [],
  },
  total: 0,
  activeTab: PreliminariesStatus.CURRENT,
};

const managerPreliminariesSlice = createSlice({
  name: 'managerPreliminaries',
  initialState,
  reducers: {
    setPremilinary: (state, action: PayloadAction<Selected>) => {
      state.selected = action.payload;
    },
    unsetPremilinary: (state) => {
      state.selected = defaultSelected;
    },
    revertPreliminary: (state) => {
      state.currentPreliminaries = [];
    },
    setActiveTab: (state, action: PayloadAction<PreliminariesStatus>) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentPreliminariesItems.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(getCurrentPreliminariesItems.fulfilled, (state, action) => {
      state.fetching = false;
      if (action.payload.total === 0) {
        state.currentPreliminaries = action.payload.data;
      } else {
        state.currentPreliminaries = state.currentPreliminaries.concat(
          action.payload.data
        );
      }
      state.masterData = action.payload.master_data;
      state.total = action.payload.total;
    });
    builder.addCase(getCurrentPreliminariesItems.rejected, (state, action) => {
      handleShowError(action);
    });
    builder.addCase(assignManager.fulfilled, (state, action) => {
      const index = state.currentPreliminaries.findIndex(
        (pre) => +pre.id === +action.meta.arg.id
      );
      if (index >= 0) {
        state.currentPreliminaries[index].list_managers =
          state.currentPreliminaries[index].list_managers.map((manager) => ({
            ...manager,
            active: +manager.id === +action.meta.arg.manager_id,
          }));
      }
    });
    builder.addCase(assignSalePersons.rejected, (state, action) => {
      handleShowError(action);
    });
    builder.addCase(assignSalePersons.fulfilled, (state, action) => {
      const index = state.currentPreliminaries.findIndex(
        (pre) => +pre.id === +action.meta.arg.id
      );
      if (index >= 0 && action.payload.data.length) {
        state.currentPreliminaries[index] = action.payload.data[0];
      }
    });
    builder.addCase(assignStoreName.fulfilled, (state, action) => {
      const index = state.currentPreliminaries.findIndex(
        (pre) => +pre.id === +action.meta.arg.id
      );
      if (index >= 0 && action.payload.data.length) {
        state.currentPreliminaries[index] = action.payload.data[0];
      }
    });
    builder.addCase(assignStoreName.rejected, (state, action) => {
      handleShowError(action);
    });
  },
});

export const {
  setPremilinary,
  unsetPremilinary,
  revertPreliminary,
  setActiveTab,
} = managerPreliminariesSlice.actions;

export default managerPreliminariesSlice.reducer;
