import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPArrowDownCircle: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="9.5"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 20 20)"
          fill="#E4E7FF"
          stroke="#6B70F0"
        />
        <path
          d="M13.2002 8.66406L9.86686 11.9974L6.53352 8.66406"
          stroke="#6B70F0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
