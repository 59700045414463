import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FastField, FastFieldProps } from 'formik';
import {
  MenuItem,
  Stack,
  SelectProps,
  Typography,
  Select,
  SelectChangeEvent,
  SxProps,
} from '@mui/material';
import { Icons } from 'components';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { debounce } from 'lodash';
import { Popover } from '../../Popover';

export type InputSelectProps = SelectProps & {
  name: string;
  readOnly?: boolean;
  options: { value: string | number; label: string }[];
  unit?: string;
  unitSx?: SxProps;
  errorMessage?: string;
  showError?: boolean;
  useFastField?: boolean;
  checkRequired?: boolean;
  stroke?: string;
};

export const InputSelectTemp: FC<InputSelectProps & FastFieldProps> = ({
  field,
  meta,
  label,
  options,
  placeholder,
  readOnly,
  unitSx,
  unit,
  onChange,
  errorMessage,
  showError = true,
  checkRequired,
  stroke,
  ...props
}) => {
  const isSuccess = useMemo(
    () => !meta.error && !props.error && meta.value,
    [meta.error, meta.value, props.error]
  );
  const [open, setOpen] = useState(false);

  const renderIconComponent = useCallback(
    () => (
      <Icons.ArrowDown
        stroke={stroke}
        sx={{
          width: 16,
          height: 16,
          position: 'absolute',
          right: 12,
          top: 'calc(50% - 8px)',
          pointerEvents: 'none',
        }}
      />
    ),
    [stroke]
  );

  const renderValue = useMemo(
    () =>
      !!field.value
        ? undefined
        : () => (
            <Typography variant="placeHolder_style" color="off_text">
              {placeholder}
            </Typography>
          ),
    [field.value, placeholder]
  );

  const handleChange: SelectInputProps['onChange'] = useCallback(
    async (event: SelectChangeEvent<unknown>, child: ReactNode) => {
      await field.onChange(event);
      onChange && onChange(event, child);
    },
    [field, onChange]
  );

  const debouncedFunction = useRef(
    debounce((error, value) => {
      // @ts-ignore
      if (!!error && !!value) {
        return setOpen(!!error && !!value);
      }
      return setOpen(!!error);
    }, 550)
  );

  const debounceError = useCallback(
    // @ts-ignore
    (error, value) => {
      return debouncedFunction.current(error, value);
    },
    []
  );

  useEffect(() => {
    if (!!meta.error && meta.touched) {
      debounceError(meta.error, meta.value);
    } else {
      debounceError(props.error, meta.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error, meta.error, meta.value, meta.touched, checkRequired]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debouncedFunction.current?.cancel();
    };
  }, []);

  return (
    <Stack flex={1} spacing={1}>
      {!!label && (
        <Typography variant="h3" color="normal_text">
          {label}
        </Typography>
      )}
      <Stack flex={1}>
        <Popover
          content={errorMessage ?? meta.error ?? ''}
          contentProps={{ color: 'main_white' }}
          open={(!!props.error || !!meta.error ? open : false) && showError}
          placement="top-end"
          arrowSx={{
            borderColor: 'sh_red',
            bgcolor: 'sh_red',
          }}
          sx={{
            top: -5,
            right: 0,
            maxWidth: 250,
            borderColor: 'sh_red',
            bgcolor: 'sh_red',
          }}
        >
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            spacing={unitSx ? 1 : 3}
          >
            <Select
              {...props}
              {...field}
              onChange={handleChange}
              sx={{
                width: '100%',
                '&&&& fieldset': {
                  borderWidth: readOnly ? '1px' : '3px',
                },
                backgroundColor: readOnly ? 'transparent' : 'bg_off',
                filter: readOnly
                  ? 'none'
                  : 'drop-shadow(0px 2px 8px rgba(59, 118, 129, 0.15))',
                '&&& .Mui-disabled': (theme) => ({
                  WebkitTextFillColor: !!field.value
                    ? theme.palette.normal_text
                    : undefined,
                }),
                ...props.sx,
              }}
              className={isSuccess && !readOnly ? 'Mui-success' : ''}
              displayEmpty
              error={
                (meta.touched && !!meta.error) ||
                (props.error && checkRequired) ||
                (props.error && !checkRequired && !!meta.value)
              }
              renderValue={
                !!props.renderValue ? props.renderValue : renderValue
              }
              IconComponent={renderIconComponent}
              disabled={readOnly}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography variant="input_style" color="normal_text">
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            {!!unit && (
              <Typography
                variant="unit_text"
                color="normal_text"
                sx={{ ...unitSx }}
              >
                {unit}
              </Typography>
            )}
          </Stack>
        </Popover>
      </Stack>
    </Stack>
  );
};

export const InputSelect: FC<InputSelectProps> = ({
  useFastField = true,
  ...props
}) => {
  const selectProps: {
    shouldUpdate?: (nextProps: {}, props: {}) => boolean;
  } = {};
  if (!useFastField) selectProps.shouldUpdate = () => true;
  return (
    <FastField name={props.name} {...selectProps}>
      {(ffProps: FastFieldProps) => <InputSelectTemp {...props} {...ffProps} />}
    </FastField>
  );
};
