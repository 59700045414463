import React, { FC, useMemo, useState } from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { AuthPopover, Icons } from 'components/atoms';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import SearchIcon from '@mui/icons-material/Search';

type AutocompleteSalePersonProps = {
  value: string;
  options: { label: string; id: string }[];
  onChange: (value: string) => void;
  hiddenWhenOne?: boolean;
};
const containsText = (value: string, searchText: string) =>
  value.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export const AutocompleteSalePerson: FC<AutocompleteSalePersonProps> = ({
  value,
  options,
  onChange,
  hiddenWhenOne,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const displayedOptions = useMemo(
    () =>
      options.filter((option) => containsText(option.label ?? '', searchText)),
    [options, searchText]
  );
  const option = useMemo(
    () => options.find((o) => +o.id === +value) || null,
    [options, value]
  );

  if (hiddenWhenOne && options.length === 1) {
    return <>{option?.label || 'ー'}</>;
  }

  return (
    <>
      <AuthPopover
        arrow={false}
        sx={{
          width: 150,
          border: (theme) => `1px solid ${theme?.palette?.line}`,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          overflow: 'hidden',
        }}
        content={
          <ClickAwayListener
            onClickAway={() => {
              setSearchText('');
              setOpen(false);
            }}
          >
            <Stack
              sx={{
                textAlign: 'left',
                maxHeight: 300,
                overflow: 'auto',
              }}
              bgcolor="main_white"
            >
              <Stack
                py="2px"
                borderBottom="1px solid"
                borderColor="gray_09"
                direction="row"
              >
                <SearchIcon
                  sx={{ color: 'sp_primary_100_main', fontSize: 22 }}
                />
                <Input
                  size="small"
                  autoFocus
                  fullWidth
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setSearchText(e.target.value)}
                  onKeyDown={(e: {
                    key: string;
                    stopPropagation: () => void;
                  }) => {
                    if (e.key !== 'Escape') {
                      e.stopPropagation();
                    }
                  }}
                />
              </Stack>
              {displayedOptions.map((item, index) => (
                <Stack
                  key={index}
                  onClick={() => {
                    if (value !== item.id) {
                      onChange(item.id ?? '');
                      setSearchText('');
                      setOpen((prevState) => !prevState);
                    }
                  }}
                  borderBottom="1px solid"
                  borderColor="gray_09"
                  sx={{
                    minHeight: 46,
                    px: 2,
                    justifyContent: 'center',
                    bgcolor: value === item.id ? 'sp_primary_40' : 'main_white',
                    ':hover': {
                      bgcolor: 'new_bg_grey',
                    },
                  }}
                >
                  <Typography
                    variant="input_style"
                    sx={{
                      color: 'b_333',
                      fontSize: '13px',
                      fontFamily: 'Noto Sans JP',
                      fontWeight: 400,
                      lineHeight: '19px',
                    }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </ClickAwayListener>
        }
        open={open}
        placement="bottom-start"
        contentProps={{
          height: 'auto',
          padding: 0,
          color: 'required_chip_label',
        }}
      >
        <Stack
          direction="row"
          spacing={3}
          mx={2}
          alignItems="center"
          justifyContent="space-between"
          onClick={() => {
            setSearchText('');
            setOpen((prevState) => !prevState);
          }}
          sx={{ borderBottom: '1px solid', borderColor: 'b_333' }}
        >
          <Stack py={1}>
            <Typography
              variant="input_style"
              sx={{
                height: 19,
                color: 'sp_primary_100_main',
                fontSize: '13px',
                fontFamily: 'Noto Sans JP',
                fontWeight: 400,
                lineHeight: '19px',
                whiteSpace: 'break-spaces',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {option?.label}
            </Typography>
          </Stack>
          {open ? (
            <Icons.ArrowUp sx={{ width: 16, height: 16 }} />
          ) : (
            <Icons.ArrowDown sx={{ width: 14, height: 14 }} />
          )}
        </Stack>
      </AuthPopover>
    </>
  );
};

const Input = styled('input')(() => ({
  width: '100%',
  outline: 'none',
  border: 'none',
  fontSize: '13px',
  fontFamily: 'Noto Sans JP',
  fontWeight: 400,
  lineHeight: '19px',
}));
