export const config: Config = {
  appName: process.env.REACT_APP_APP_NAME ?? '',
  serverUrl: process.env.REACT_APP_SERVER_URL ?? '',
  termConditionUrl: process.env.REACT_APP_TERM_CONDITION_URL ?? '',
  dummyPdfUrl: process.env.REACT_APP_DUMMY_PDF_URL ?? '',
  externalServerUrl: process.env.REACT_APP_EXTERNAL_SERVER_URL ?? '',
  azureLoginUrl: process.env.REACT_APP_AZURE_LOGIN_URL ?? '',
  termServiceUrl: process.env.REACT_APP_TERM_OF_SERVICE_URL ?? '',
};

type Config = {
  appName: string;
  serverUrl: string;
  termConditionUrl: string;
  dummyPdfUrl: string;
  externalServerUrl: string;
  azureLoginUrl: string;
  termServiceUrl: string;
};
