import { useCallback, useEffect, useRef } from 'react';
import { FormikConfig, FormikValues } from 'formik';
import { debounce } from 'lodash';

export const useDebouncedValidate = <T extends FormikValues>({
  values,
  validate,
  debounceTime = 200,
}: {
  values: T;
  validate: FormikConfig<T>['validate'];
  debounceTime?: number;
}) => {
  const debouncedFunction = useRef(
    debounce((validateFunc: FormikConfig<T>['validate'], data: T) => {
      // @ts-ignore
      return validateFunc(data);
    }, debounceTime)
  );

  const debounceValidate = useCallback(
    // @ts-ignore
    (data) => {
      return debouncedFunction.current(validate, data);
    },
    [validate]
  );

  useEffect(() => {
    debounceValidate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    return () => {
      debouncedFunction.current?.cancel();
    };
  }, []);
};
