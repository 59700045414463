import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button } from 'components';
import { Data } from '../index';

type DashboardItemProps = {
  data: Data;
};

export const DashboardItem: FC<DashboardItemProps> = ({ data }) => {
  return (
    <Button
      sx={{
        bgcolor: 'white',
        width: '100%',
        height: '150px',
        p: 0,
        borderRadius: '5px',
      }}
    >
      <Stack flex={1}>
        <Stack
          bgcolor="bg_off"
          sx={{
            height: '81px',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
          }}
          justifyContent="center"
        >
          <Typography variant="text_dashboard" color="normal_text">
            アイコン
          </Typography>
        </Stack>
        <Stack
          bgcolor="main_white"
          sx={{
            height: '69px',
            borderBottomRightRadius: '5px',
            borderBottomLeftRadius: '5px',
          }}
          justifyContent="center"
        >
          <Typography
            variant="text_applicant_item"
            color="normal_text"
            whiteSpace="pre-wrap"
          >
            {data.title}
          </Typography>
        </Stack>
      </Stack>
    </Button>
  );
};
