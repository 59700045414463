import { regex, yup } from 'libs';

export const validateSchema = yup.object({
  currentPassword: yup
    .string()
    .required()
    .min(8)
    .max(20, 'パスワードは20桁以内で設定ください。'),
  password: yup
    .string()
    .required()
    .min(8)
    .max(20, 'パスワードは20桁以内で設定ください。')
    .matches(
      regex.password,
      'パスワードは大文字、小文字、数字をそれぞれ1つ以上使用し、半角8文字以上で設定してください。'
    ),
  passwordConfirmation: yup
    .string()
    .required()
    .matches(
      regex.password,
      'パスワードは大文字、小文字、数字をそれぞれ1つ以上使用し、半角8文字以上で設定してください。'
    )
    .when('password', {
      is: (val: string) => !!val?.length,
      then: yup
        .string()
        .oneOf(
          [yup.ref('password')],
          'パスワードと確認用パスワードが一致しません。'
        ),
    })
    .min(8),
});
