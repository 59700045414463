import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  ChangePasswordError,
  ChangePasswordRequest,
  ChangePasswordResponse,
} from 'services';
import { ThunkAPI } from 'types';
import { changePasswordErrorMessage, ChangePasswordErrorType } from 'constant';
import get from 'lodash/get';

export const changePasswordAction = createAsyncThunk<
  ChangePasswordResponse,
  ChangePasswordRequest,
  ThunkAPI<ChangePasswordError>
>('authModal/changePasswordAction', async (user, { rejectWithValue }) => {
  try {
    return await authService.changePassword(user);
  } catch (error) {
    const errorKey: ChangePasswordErrorType = get(error, 'errors[0].key');
    if (changePasswordErrorMessage[errorKey]) {
      return rejectWithValue(changePasswordErrorMessage[errorKey]);
    }
    return rejectWithValue(changePasswordErrorMessage.unknown);
  }
});
