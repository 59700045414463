import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import landingReducer from 'pages/Landing/slice';
import authReducer from 'containers/AuthModal/slice';
import accountInformationReducer from 'pages/SPAccountInformation/slice';
import applicationReducer from 'containers/SavedApplicationsModal/slice';
import stepFourReducer from 'pages/StepFour/slice';
import stepSynthesisReducer from 'pages/StepSynthesisInformation/slice';
import completeReducer from 'pages/Complete/slice';
import masterBankReducer from 'pages/StepSynthesisInformation/StepOneD/slice';
import dynamicOptionsReducer from 'pages/SPStartScreen/slice';
import contactReducer from 'pages/Dashboards/ContactInformations/slice';
import managerContactReducer from 'pages/ManagerContactInformations/slice';
import preliminaryReducer from 'pages/Preliminaries/slice';
import managerPreliminariesReducer from 'pages/ManagerPreliminaries/slice';
import listStatusReducer from 'pages/StatusScreen/slice';
import adminPreliminaryReducer from 'pages/AdminEditPreliminary/slice';
import salePersonPreliminaryReducer from 'pages/SalePersonEditPreliminary/slice';
import salePersonPreliminariesReducer from 'pages/SalePersonPreliminaries/slice';
import memoReducer from 'pages/Memo/slice';
import messagesAdminReducer from 'pages/ScreenMessages/slice';
import messageReducer from 'containers/SPChat/slice';
import spStepThreeReducer from 'pages/SPStepThree/slice';
import stepOneReducer from 'pages/SPStepOne/slice';
import spStepTwoReducer from 'pages/SPStepTwo/slice';
import spStepFourReducer from 'pages/SPStepFour/slice';
import spStepFiveReducer from 'pages/SPStepFive/slice';
import spStepSixReducer from 'pages/SPStepSix/slice';
import managerDocumentUploadReducer from 'pages/ManagerDocumentUpload/slice';
import spStepSevenReducer from 'pages/SPStepSeven/slice';
import spStepEightReducer from 'pages/SPStepEight/slice';
import spStepCollateralProviderReducer from 'pages/SPStepFourCollateralProvider/slice';
import spStepTenReducer from 'pages/SPStepTen/slice';
import spStepSevenIncomeTotalizerReducer from 'pages/SPStepSevenIncomeTotalizer/slice';
import spStepFourIncomeTotalizerReducer from 'pages/SPStepFourIncomeTotalizer/slice';
import spStepFiveIncomeTotalizerReducer from 'pages/SPStepFiveIncomeTotalizer/slice';
import { config } from 'configs';

const persistConfig = {
  key: config.appName,
  storage: storage,
};

const appReducer = combineReducers({
  landing: landingReducer,
  auth: authReducer,
  accountInformation: accountInformationReducer,
  application: applicationReducer,
  step_four: stepFourReducer,
  step_synthesis: stepSynthesisReducer,
  complete: completeReducer,
  masterBank: masterBankReducer,
  dynamic_options: dynamicOptionsReducer,
  preliminary: preliminaryReducer,
  contacts: contactReducer,
  managerPreliminaries: managerPreliminariesReducer,
  managerContact: managerContactReducer,
  listStatus: listStatusReducer,
  adminPreliminaryReducer: adminPreliminaryReducer,
  memos: memoReducer,
  messages: messageReducer,
  messagesAdmin: messagesAdminReducer,
  salePersonPreliminaries: salePersonPreliminariesReducer,
  sp_step_one: stepOneReducer,
  sp_step_three: spStepThreeReducer,
  sp_step_two: spStepTwoReducer,
  sp_step_four: spStepFourReducer,
  sp_step_five: spStepFiveReducer,
  sp_step_six: spStepSixReducer,
  managerDocumentUpload: managerDocumentUploadReducer,
  sp_step_seven: spStepSevenReducer,
  sp_step_seven_income_totalizer: spStepSevenIncomeTotalizerReducer,
  salePersonPreliminaryReducer: salePersonPreliminaryReducer,
  sp_step_eight: spStepEightReducer,
  sp_step_collateral_provider: spStepCollateralProviderReducer,
  sp_step_ten: spStepTenReducer,
  sp_step_four_income_total: spStepFourIncomeTotalizerReducer,
  sp_step_five_income_total: spStepFiveIncomeTotalizerReducer,
});

const rootReducer: Reducer<RootState, AnyAction> = (
  state: RootState | undefined,
  action: AnyAction
) => {
  if (action.type === 'auth/resetAuth') {
    storage.removeItem(`persist:${config.appName}`);

    state = {} as RootState;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'authModal/logout/fulfilled',
        ],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };

type RootState = ReturnType<typeof appReducer>;
