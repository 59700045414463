import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPHeaderLogo: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 171 17">
      <svg
        width="171"
        height="17"
        viewBox="0 0 171 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.2865 0.00784093H5.20955L3.09736 4.96523H3.93278V16.6927H2.15159V7.14049L1.92305 7.62913H0L3.2865 0V0.00784093ZM12.2949 14.2652H17.3704V15.9518H5.42234V14.2652H10.4979V10.6082H6.95921V8.92163H10.4979V5.28835H5.99769V3.60173H16.7872V5.28835H12.287V8.92163H15.8257V10.6082H12.287V14.2652H12.2949ZM12.5865 2.49048H10.5767C10.4428 1.62353 9.52853 0.457083 9.52853 0.457083H11.5383C11.5383 0.457083 12.5865 1.72599 12.5865 2.49048Z"
          fill="url(#paint0_linear_10699_14528)"
        />
        <path
          d="M145.11 2.62446V0.370407H143.022V2.62446H136.953V12.0427H143.022V16.7321H145.11V12.0427H151.179V2.62446H145.11ZM138.876 6.20262V4.27168H143.022V6.20262H138.876ZM138.876 10.1906V8.22024H143.022V10.1906H138.876ZM149.24 4.27168V6.20262H145.102V4.27168H149.24ZM149.24 8.22812V10.1985H145.102V8.22812H149.24Z"
          fill="url(#paint1_linear_10699_14528)"
        />
        <path
          d="M164.577 8.53546L167.596 12.8466H170.094L166.65 7.97591C166.65 7.97591 165.563 6.69124 165.563 5.65091V2.39591C165.563 1.63142 164.94 1.00879 164.175 1.00879H162.623V2.86088H163.513V5.64302C163.513 6.54149 162.623 7.77885 162.615 7.8025L159.297 12.8387H161.701L164.585 8.52757L164.577 8.53546Z"
          fill="url(#paint2_linear_10699_14528)"
        />
        <path
          d="M155.632 3.92489L155.617 4.08253H157.8L157.816 3.9564C157.926 3.1131 157.067 1.71812 157.035 1.66295L156.996 1.592H154.781L154.892 1.80481C155.12 2.22252 155.688 3.40473 155.632 3.93278V3.92489Z"
          fill="url(#paint3_linear_10699_14528)"
        />
        <path
          d="M160.125 14.3834C160.023 14.3834 158.123 14.3125 158.123 12.7047V5.6982H154.514V7.8025H156.311V13.0357L154.522 14.3125V16.5508L156.957 15.0376C157.532 15.6444 158.415 16.3065 159.747 16.3065H170.221V14.3756H160.125V14.3834Z"
          fill="url(#paint4_linear_10699_14528)"
        />
        <path
          d="M38.541 0.993013V15.6996H51.0408V0.993013H38.541ZM40.4404 13.9736V2.71119H49.1178V13.9736H40.4404Z"
          fill="url(#paint5_linear_10699_14528)"
        />
        <path
          d="M65.0604 7.41631H53.9004V9.34725H65.0604V7.41631Z"
          fill="url(#paint6_linear_10699_14528)"
        />
        <path
          d="M77.3559 1.62355L77.3401 1.86787C77.151 5.11498 76.5599 7.66854 75.5905 9.4576C74.3531 11.7905 71.6814 13.3194 68.8362 13.9972L68.584 14.0603L69.0805 15.9518L69.3248 15.8966C72.6429 15.1085 75.7087 13.3352 77.2377 10.6398C78.4278 8.53548 79.145 5.61935 79.3578 1.97817L79.3735 1.73385L77.3401 1.63139H77.348L77.3559 1.62355Z"
          fill="url(#paint7_linear_10699_14528)"
        />
        <path
          d="M70.9169 4.59478L71.6657 2.81359L71.4292 2.71902C70.1445 2.2225 68.8284 1.85208 67.4097 1.59199L67.1575 1.54471L66.7871 3.42836L67.0393 3.47565C68.3003 3.7042 69.4904 4.04309 70.6805 4.50021L70.9169 4.59478Z"
          fill="url(#paint8_linear_10699_14528)"
        />
        <path
          d="M130.689 8.18078H121.712V14.9982H119.758V16.7399H132.604V14.9982H130.689V8.18078ZM128.877 14.2573V14.9982H123.533V14.2573H128.877ZM128.877 12.0899V12.8072H123.533V12.0899H128.877ZM128.877 9.92257V10.6398H123.533V9.92257H128.877Z"
          fill="url(#paint9_linear_10699_14528)"
        />
        <path
          d="M133.628 7.28235C130.893 6.04498 129.049 4.56327 128.159 2.87666H133.069V1.32406H127.087V0.141859H125.274V1.32406H119.308V2.87666H124.155C123.178 4.52386 120.782 6.3366 118.945 7.24295L118.717 7.35325L119.521 8.8665L119.749 8.75615C121.601 7.84192 124.052 6.06862 125.266 4.42142V7.44782H127.079V4.46082C128.608 6.69911 131.587 8.236 132.84 8.81922L133.069 8.92952L133.849 7.39265L133.62 7.28235H133.628Z"
          fill="url(#paint10_linear_10699_14528)"
        />
        <path
          d="M21.7912 3.0264H32.9984L33.9363 4.19282L35.1815 2.58505L34.2121 1.30039H28.0332C27.8755 0.882676 27.6628 0.464952 27.4027 0.0551224L25.2826 0.149743L25.4323 0.338887C25.6609 0.622615 25.8737 0.953616 26.0628 1.30828H19.9863V5.20166H21.799V3.05007L21.7912 3.0264Z"
          fill="url(#paint11_linear_10699_14528)"
        />
        <path
          d="M33.0231 12.5865C32.9443 13.3982 32.8654 13.9026 32.7787 14.1076C32.6054 14.5568 32.2664 14.7617 31.7305 14.7617H27.8371C27.4352 14.7617 27.183 14.6514 27.0411 14.4228C26.9702 14.3125 26.8835 14.0682 26.8835 13.4928V10.7422L33.4565 10.4743L33.3777 8.67731L26.8914 8.92163V6.74642C28.2233 6.47846 31.9197 5.83215 31.9512 5.81638L32.0852 5.79276L31.9275 4.06675L21.8473 5.7297L22.0917 7.49511L22.2414 7.47938C22.3439 7.4715 24.2117 7.21139 25.0156 7.10893V9.00836L19.6484 9.17384L19.7273 10.9708L25.0077 10.8053V13.5007C25.0077 15.5025 25.985 16.5586 27.8293 16.5586H31.7227C32.8654 16.5586 33.7087 16.141 34.2131 15.3213C34.5599 14.7696 34.7806 13.9106 34.8831 12.7599L34.9067 12.5155L33.0388 12.3343L33.0152 12.5786L33.0231 12.5865Z"
          fill="url(#paint12_linear_10699_14528)"
        />
        <path
          d="M97.7516 15.2267C97.1763 14.9824 96.3724 14.076 95.9941 13.5638L94.071 11.0339L97.3418 6.73854H97.3654V6.71487L97.4285 6.63608L97.3654 6.59668V4.76032H89.7757V2.36441H97.263V0.591106H87.8842V6.61241C87.8842 6.61241 87.7502 8.10986 87.4428 9.17384C87.167 10.1354 86.4577 11.688 86.4498 11.7038L86.3867 11.8299L88.1679 12.7441L88.2388 12.6023C88.2704 12.5313 89.09 10.8841 89.3343 9.89107C89.4762 9.27633 89.5787 8.70888 89.6338 8.36998L91.6593 11.0339L89.7599 13.5322C89.6496 13.6505 88.696 14.7144 87.7423 15.2977L87.6162 15.3765L88.6565 16.9606L88.7827 16.8818C90.0831 16.0937 91.2653 14.7223 91.281 14.7066L92.8652 12.6259L94.402 14.6435C94.5281 14.8248 95.7025 16.4089 96.9792 16.9449L97.1211 17L97.9014 15.2819L97.7595 15.2267H97.7516ZM94.9064 6.75427L92.8573 9.44972L90.8239 6.77005L94.9064 6.74643V6.75427Z"
          fill="url(#paint13_linear_10699_14528)"
        />
        <path
          d="M102.804 2.4038H113.94V3.71997H115.839V0.85115H109.337V0H107.406V0.85115H100.912V3.71997H102.804V2.4038Z"
          fill="url(#paint14_linear_10699_14528)"
        />
        <path
          d="M115.824 9.60735C114.784 9.25269 113.546 8.83496 112.735 8.35419H115.225V6.573H112.388L113.318 5.10709H111.111L110.481 6.573H109.023V4.52388L112.396 4.27956V2.71118L103.569 3.20766V4.7682L107.241 4.57905V6.56511H105.791L105.153 5.26472H102.915L103.852 6.56511H100.897V8.3463H103.632C102.82 8.82707 101.583 9.2448 100.542 9.59946L100.4 9.64674L101.007 11.2782L101.149 11.2309C104.254 10.1748 105.791 8.81919 106.248 8.3463H107.234V10.7028H102.639V16.5744H113.61V10.7028H109.015V8.3463H110.11C110.567 8.8113 112.104 10.1748 115.209 11.2309L115.351 11.2782L115.958 9.64674L115.816 9.59946L115.824 9.60735ZM107.218 14.2337V15.2031H104.546V14.2337H107.218ZM107.218 12.2239V13.0199H104.546V12.2239H107.218ZM109.07 13.0199V12.2239H111.742V13.0199H109.07ZM109.07 15.211V14.2415H111.742V15.211H109.07Z"
          fill="url(#paint15_linear_10699_14528)"
        />
        <path
          d="M84.7631 0.00784093H86.6782L84.5739 4.96523H85.4094V16.6927H83.6203V7.14049L83.3996 7.62913H81.4766L84.7631 0V0.00784093Z"
          fill="url(#paint16_linear_10699_14528)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_10699_14528"
            x1="-3.64117"
            y1="-30.8554"
            x2="27.6005"
            y2="66.7864"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_10699_14528"
            x1="119.26"
            y1="-70.1912"
            x2="150.509"
            y2="27.4507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_10699_14528"
            x1="136.362"
            y1="-75.661"
            x2="167.612"
            y2="21.981"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_10699_14528"
            x1="131.752"
            y1="-74.18"
            x2="162.994"
            y2="23.4549"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_10699_14528"
            x1="133.502"
            y1="-74.7467"
            x2="164.744"
            y2="22.8953"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_10699_14528"
            x1="28.91"
            y1="-41.2747"
            x2="60.1518"
            y2="56.3673"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_10699_14528"
            x1="42.2281"
            y1="-45.5387"
            x2="73.47"
            y2="52.1036"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_10699_14528"
            x1="55.4694"
            y1="-49.7706"
            x2="86.711"
            y2="47.8712"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_10699_14528"
            x1="52.5928"
            y1="-48.8564"
            x2="83.8344"
            y2="48.7854"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_10699_14528"
            x1="100.677"
            y1="-64.2411"
            x2="131.927"
            y2="33.4011"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_10699_14528"
            x1="103.648"
            y1="-65.1863"
            x2="134.889"
            y2="32.4474"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_10699_14528"
            x1="14.5955"
            y1="-36.6957"
            x2="45.8374"
            y2="60.9465"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_10699_14528"
            x1="12.0902"
            y1="-35.8916"
            x2="43.3318"
            y2="61.7502"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_10699_14528"
            x1="72.2082"
            y1="-55.1298"
            x2="103.45"
            y2="42.5119"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_10699_14528"
            x1="88.3493"
            y1="-60.2918"
            x2="119.591"
            y2="37.3417"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_10699_14528"
            x1="86.1588"
            y1="-59.5985"
            x2="117.408"
            y2="38.0432"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_10699_14528"
            x1="65.5247"
            y1="-52.9941"
            x2="96.7742"
            y2="44.6478"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
