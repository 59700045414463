import {
  ChangeEvent,
  FC,
  Fragment,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { Stack, Typography } from '@mui/material';
import { SPInputField } from 'components';
import { convertToHalfWidth, onlyNumbers } from 'utils';
import { validationSchema } from './validationSchema';

type PhoneType = 'homePhone' | 'mobilePhone';

export type SPInputPhoneNumberProps = {
  name: string;
  type: PhoneType;
};

export const SPInputPhoneNumber: FC<SPInputPhoneNumberProps> = ({
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const initialValues = useMemo(() => {
    const [phoneOne = '', phoneTwo = '', phoneThree = ''] = field.value
      ? field.value.split('-')
      : ['', '', ''];
    return { phoneOne, phoneTwo, phoneThree };
  }, [field.value]);

  const refOne = useRef<HTMLInputElement>(null);
  const refTwo = useRef<HTMLInputElement>(null);
  const refThree = useRef<HTMLInputElement>(null);
  const currentIndex = useRef<number>(0);
  const [firstTouchField, setFirstTouchField] = useState(false);

  const phoneInputs = useMemo(
    () => [
      {
        name: 'phoneOne',
        ref: refOne,
        // maxLength: props.type === 'homePhone' ? 4 : 3,
        maxLength: 4,
      },
      {
        name: 'phoneTwo',
        ref: refTwo,
        maxLength: 4,
      },
      {
        name: 'phoneThree',
        ref: refThree,
        maxLength: 4,
      },
    ],
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit() {},
  });

  const handleBackInput = useCallback(() => {
    const prevIndex = currentIndex.current - 1;

    if (prevIndex !== -1) {
      const prevInput = phoneInputs?.[prevIndex]?.ref.current;
      prevInput?.focus();

      currentIndex.current = prevIndex;
    }
  }, [phoneInputs]);

  const handleNextInput = useCallback(() => {
    const nextIndex = currentIndex.current + 1;

    if (nextIndex === phoneInputs.length) {
      return phoneInputs?.[currentIndex.current]?.ref.current?.blur();
    }
    const nextInput = phoneInputs?.[nextIndex]?.ref.current;
    nextInput?.focus();

    currentIndex.current = nextIndex;
  }, [phoneInputs]);

  const handleKeyPress = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (
        e.target.value.length === 4
        // e.target.value.length === 4 ||
        // (e.target.value.length === 4 &&
        //   e.target.name === 'phoneOne' &&
        //   props.type === 'homePhone') ||
        // (e.target.value.length === 3 &&
        //   e.target.name === 'phoneOne' &&
        //   props.type === 'mobilePhone')
      )
        handleNextInput();

      if (e.target.value.length === 0) handleBackInput();

      if (
        refOne.current?.value ||
        refTwo.current?.value ||
        refThree.current?.value
      ) {
        return setFieldValue(
          field.name,
          `${refOne.current!.value}-${refTwo.current!.value}-${
            refThree.current!.value
          }`
        );
      }
      return setFieldValue(field.name, '');
    },
    [field.name, handleBackInput, handleNextInput, setFieldValue]
  );

  const handleBlur = useCallback(() => {
    if (refOne.current && refTwo.current && refThree.current) {
      let subPhoneNumber: string[] = [
        refOne.current.value,
        refTwo.current.value,
        refThree.current.value,
      ];

      if (subPhoneNumber.length) {
        phoneInputs?.forEach((input, index) =>
          formik.setFieldValue(
            input.name,
            convertToHalfWidth(subPhoneNumber?.[index])
          )
        );

        if (!subPhoneNumber.every((sub) => !sub)) {
          setFieldValue(
            field.name,
            subPhoneNumber.map((sub) => convertToHalfWidth(sub)).join('-')
          );
        }
      }
    }
    setFirstTouchField(false);
  }, [setFieldValue, field.name, formik, phoneInputs]);

  const handleFocusInput = useCallback(
    (e: KeyboardEvent<HTMLDivElement>, name: string) => {
      if (e.key !== 'Backspace') {
        switch (props.type) {
          case 'homePhone':
            if (
              (name === 'phoneOne' && refOne.current?.value.length === 4) ||
              (name === 'phoneTwo' && refTwo.current?.value.length === 4)
            )
              handleNextInput();
            break;
          case 'mobilePhone':
            if (
              (name === 'phoneOne' && refOne.current?.value.length === 4) ||
              (name === 'phoneTwo' && refTwo.current?.value.length === 4)
            )
              handleNextInput();
            break;
          default:
            break;
        }
      }
      if (
        e.key === 'Backspace' &&
        ((refTwo.current?.value === '' && currentIndex.current === 1) ||
          (refThree.current?.value === '' && currentIndex.current === 2))
      )
        handleBackInput();
    },
    [handleBackInput, handleNextInput, props.type]
  );

  return (
    <FormikProvider value={formik}>
      <input name={field.name} type="hidden" />
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {phoneInputs.map((input, index) => (
            <Fragment key={index}>
              <Stack direction="row" alignItems="center">
                <SPInputField
                  inputRef={input.ref}
                  name={input.name}
                  sx={{ width: 76 }}
                  placeholder={'0'.repeat(input.maxLength).toString()}
                  onChange={handleKeyPress}
                  onKeyDown={(e) => handleFocusInput(e, input.name)}
                  onKeyPress={(event) => onlyNumbers(event)}
                  onFocus={() => {
                    setFieldTouched(field.name);
                    setFirstTouchField(true);
                    currentIndex.current = index;
                  }}
                  onBlur={handleBlur}
                  maxLength={input.maxLength}
                  numeric
                  error={!firstTouchField && !!meta.error && meta.touched}
                />
              </Stack>
              {index !== phoneInputs.length - 1 && (
                <Typography variant="text_note" color="b_333">
                  -
                </Typography>
              )}
            </Fragment>
          ))}
        </Stack>
        {!firstTouchField && !!meta.error && meta.touched && (
          <Typography variant="text_error" color="sp_secondary_01">
            ※{meta.error}
          </Typography>
        )}
      </Stack>
    </FormikProvider>
  );
};
