import {
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  Badge,
} from '@mui/material';
import { Button, Icons, PreliminaryStatuses } from 'components';
import { BANK_NAMES, PROVISIONAL_RESULT } from 'constant';
import { useAppDispatch } from 'hooks';
import { routeNames } from 'navigations/routes';
import { ListStatusChart } from 'pages/SalePersonPreliminaries/components/ListStatusChart';
import { ResultStatusChart } from 'pages/SalePersonPreliminaries/components/ResultStatusChart';
import { setConversationDetail } from 'pages/ScreenMessages/slice';
import { getMessageDetailSalePerson } from 'pages/ScreenMessages/thunk';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssignSalePersonRequest,
  AssignStoreNameRequest,
  SalePersonPreliminariesItem,
} from 'services';
import { convertDateTime, formatJapanDateTine, numberWithCommas } from 'utils';
import { toNumber } from 'lodash';
import { AutocompleteSalePerson } from './AutocompleteSalePerson';

export type SalePersonPreliminariesItemProps = {
  data: SalePersonPreliminariesItem[];
  onAssignSalesPerson: (val: AssignSalePersonRequest) => void;
  onAssignStoreName: (val: AssignStoreNameRequest) => void;
};

export const SalePersonPreliminariesItems: FC<
  SalePersonPreliminariesItemProps
> = ({ data, onAssignSalesPerson, onAssignStoreName }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formatTemporaryAmount = (amount: string) => {
    return numberWithCommas(Number(amount) / 10000);
  };

  const goToMessageDetail = useCallback(
    async (
      conversationId: string,
      conversationName: string,
      preliminaryId: string
    ) => {
      const result = await dispatch(getMessageDetailSalePerson(conversationId));
      if (getMessageDetailSalePerson.fulfilled.match(result)) {
        dispatch(
          setConversationDetail({
            conversationId,
            conversationName,
          })
        );
        navigate({
          pathname: routeNames.SalePersonMessageDetail.path,
          search: `?user_id=${conversationId}&preliminary_id=${preliminaryId}`,
        });
      }
    },
    [dispatch, navigate]
  );

  const goToEditPreliminary = useCallback(
    (data: SalePersonPreliminariesItem) => {
      navigate({
        pathname: routeNames.SalePersonEditPreliminary.path,
        search: `?preliminary_id=${data.id}`,
      });
    },
    [navigate]
  );

  return (
    <Stack sx={{ mb: '13px' }}>
      {data.map((item, index) => (
        <Stack
          key={index}
          sx={{
            bgcolor: `${item.linking ? 'linking_loan' : 'main_white'}`,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            p: 2,
            borderRadius: '2px',
            mt: `${
              index > 0 &&
              item.linking &&
              data[index - 1].linking === item.linking
                ? '0px'
                : '13px'
            }`,
            borderTop: (theme) =>
              index > 0 &&
              item.linking &&
              data[index - 1].linking === item.linking
                ? `4px solid ${theme?.palette?.green_spagebar_88C095}`
                : 'none',
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              bgcolor: `${item.linking ? 'linking_loan' : 'main_white'}`,
              borderBottom: '1px solid',
              borderColor: 'bg_gray',
              alignItems: 'center',
            }}
          >
            <TableCellStyled sx={{ width: 153 }}>
              <Typography variant="text_preliminarie_item" color="b_333">
                {item.application_number}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 180,
                '@media (min-width: 1441px)': {
                  flex: 1,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {item.bank_name}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 200,
                '@media (min-width: 1441px)': {
                  flex: 1,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {item.applicant_name?.[0]}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 115,
                '@media (min-width: 1441px)': {
                  width: 130,
                },
              }}
            >
              <Typography variant="priliminary_number" color="b_333">
                {formatJapanDateTine(item.created_at, 'YY/MM/DD HH時')}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 115,
                '@media (min-width: 1441px)': {
                  width: 130,
                },
              }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <Typography variant="priliminary_number">
                  {convertDateTime(
                    item.loan_desired_borrowing_date,
                    'YY/MM/DD'
                  )}
                </Typography>
              </Stack>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                textAlign: 'right',
                width: 115,
                '@media (min-width: 1441px)': {
                  width: 130,
                },
              }}
            >
              <Typography variant="priliminary_number" color="b_333">
                {formatTemporaryAmount(item.temporary_desired_loan_amount)}
              </Typography>
              <Typography
                variant="priliminary_text"
                color="normal_text"
                fontSize={7}
              >
                （万円）
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 115,
                '@media (min-width: 1441px)': {
                  width: 130,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {item.provisional_result
                  ? PROVISIONAL_RESULT[Number(item.provisional_result)]
                  : 'ー'}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 135,
                '@media (min-width: 1441px)': {
                  width: 160,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {!!item.list_store && (
                  <AutocompleteSalePerson
                    value={
                      item.list_store.find((people) => {
                        if (
                          item.p_referral_agency_path.split('/').length === 2
                        ) {
                          return people.path === item.p_referral_agency_path;
                        }
                        return (
                          item.p_referral_agency_path.startsWith(people.path) &&
                          people.path.split('/').length > 2
                        );
                      })?.id ?? ''
                    }
                    onChange={(value) => {
                      if (value) {
                        onAssignStoreName({
                          id: item.id,
                          p_referral_agency_id: value,
                        });
                      }
                    }}
                    options={item.list_store.map((val) => ({
                      label: val.store_name_kanji,
                      id: val.id,
                    }))}
                  />
                )}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 135,
                '@media (min-width: 1441px)': {
                  width: 160,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {!!item.list_sale_people && (
                  <AutocompleteSalePerson
                    value={
                      item.list_sale_people.find((people) => people.active)
                        ?.id ?? ''
                    }
                    onChange={(value) => {
                      onAssignSalesPerson({
                        id: item.id,
                        s_sale_person_id: value,
                      });
                    }}
                    options={item.list_sale_people.map((val) => ({
                      label: val.name_kanji,
                      id: val.id,
                    }))}
                  />
                )}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 120,
                '@media (min-width: 1441px)': {
                  width: 160,
                },
              }}
            >
              {item.manager_name_kanji || 'ー'}
            </TableCellStyled>
          </TableRow>
          <Stack direction="column">
            <Stack direction="row">
              {BANK_NAMES.map((item, index) => (
                <Stack flex={index === 2 ? 1.5 : 1}>
                  <Typography
                    key={index}
                    variant="text_sale_person_bank_name"
                    color="close_icon"
                    align="center"
                    sx={{
                      '&.MuiTypography-root:nth-child(2)': {
                        ml: 5,
                      },
                      '&.MuiTypography-root:nth-child(3)': {
                        ml: '29px',
                      },
                      '&.MuiTypography-root:nth-child(4)': {
                        mr: '10px',
                      },
                    }}
                  >
                    {item}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <ResultStatusChart data={item} />
            <ListStatusChart data={item} />
          </Stack>
          <Stack sx={{ mt: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center">
                <Button
                  variant="text"
                  sx={{
                    mr: '10px',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'main_white',
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => goToEditPreliminary(item)}
                >
                  <Typography
                    sx={{
                      '&.MuiTypography-root:hover': {
                        color: 'blue_08',
                      },
                    }}
                    variant="dashboard_text"
                    color="sp_primary_100_main"
                  >
                    申込内容の修正・確認
                  </Typography>
                </Button>
                <Icons.Slash sx={{ color: 'gray' }} />
                <Button
                  variant="text"
                  sx={{
                    mr: '10px',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'main_white',
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() =>
                    goToMessageDetail(
                      item.user_id,
                      item.applicant_name[0],
                      item.id
                    )
                  }
                >
                  <Badge
                    badgeContent={toNumber(item?.unread_messages) || 0}
                    color="error"
                    sx={{
                      '& .BaseBadge-badge': {
                        height: 16,
                        fontSize: 10,
                        lineHeight: 12,
                        minWidth: 16,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        '&.MuiTypography-root:hover': {
                          color: 'blue_08',
                        },
                      }}
                      variant="dashboard_text"
                      color="sp_primary_100_main"
                    >
                      メッセージ確認
                    </Typography>
                  </Badge>
                </Button>
              </Stack>
              <PreliminaryStatuses
                applicationStatusList={item.application_status_lists}
                isLinking={!!item.linking}
              />
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

const TableCellStyled = styled(TableCell)(() => ({
  padding: '10px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));
