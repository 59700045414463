import { ClipboardEvent, FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPFormItem, SPInputField } from 'components';
import { theme } from 'styles';
import { useNavigate } from 'react-router-dom';

interface Props {
  open?: boolean;
  close?: () => void;
  title: string;
  formOption: {
    formTitle: string;
    formName: string;
    formPlaceholder?: string;
    formChildren?: ReactNode;
    showError?: boolean;
    hasPassword?: boolean;
    autoTrim?: boolean;
    type?: string;
  }[];
  buttonTitle: string;
  backButtonTitle?: string;
  onSubmit: () => void;
  disabledButton?: boolean;
  errorMessage?: string;
}

const UpdatePasswordTemplate: FC<Props> = ({
  open,
  close,
  title,
  formOption,
  onSubmit,
  disabledButton,
  buttonTitle,
  backButtonTitle,
  errorMessage,
}) => {
  const navigate = useNavigate();
  const onPreventPassword = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  return (
    <Stack sx={{ bgcolor: 'bg_off' }}>
      <Stack textAlign="center" sx={{ mt: 7 }}>
        <Typography variant="SP_title_email_screen" color="sp_primary_100_main">
          {title}
        </Typography>
      </Stack>
      {open && (
        <Stack
          direction="row"
          alignItems="center"
          textAlign="center"
          sx={{
            mt: 7,
            bgcolor: 'sp_secondary_pink_light',
            border: (theme) => `1px solid ${theme?.palette?.sp_secondary_01}`,
            borderRadius: '8px',
            mx: 4,
            px: 4,
            py: 2,
          }}
          spacing={3}
        >
          <Icons.SPWarning />
          <Typography variant="drawerText" color="sp_secondary_01">
            サーバーとの通信に失敗しました。再度お試しください。
          </Typography>
        </Stack>
      )}
      {errorMessage && (
        <Stack sx={{ mt: 7, px: 4 }}>
          <Stack
            direction="row"
            sx={{
              px: 4,
              py: 2,
              border: '1px solid',
              borderColor: 'sp_secondary_01',
              borderRadius: 2,
              bgcolor: 'sp_secondary_pink_light',
              color: 'sp_secondary_01',
              alignItems: 'center',
            }}
            spacing={3}
          >
            <Icons.SPWarningModal
              sx={{
                width: 28,
                height: 25,
              }}
              stopColor="#E54E75"
            />
            <Typography variant="sp_error_message" color="sp_secondary_01">
              {errorMessage}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Stack justifyContent="center" sx={{ mt: 6, flex: 1 }}>
        {formOption.map((option, index) => (
          <SPFormItem key={index} required label={option.formTitle}>
            <Stack sx={{ mt: 3, position: 'relative' }} spacing={3}>
              <SPInputField
                onFocus={close}
                name={option.formName}
                type={option.type}
                InputProps={{
                  sx: {
                    '&& .MuiInputBase-input': {
                      borderRadius: '5px',
                      ...theme.typography.text_placeholder,
                    },
                    '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                      {
                        borderWidth: '1px',
                        borderColor: 'black',
                      },
                  },
                }}
                hasPassword={option.hasPassword}
                placeholder={option.formPlaceholder}
                autoTrim={option.autoTrim}
                showError={option.showError ? true : !!index}
                onCut={onPreventPassword}
                onCopy={onPreventPassword}
                onPaste={onPreventPassword}
              />
            </Stack>
            {option.formChildren}
          </SPFormItem>
        ))}

        <Stack alignItems="center" px={6} spacing={6}>
          <SPButton
            disabled={disabledButton}
            sx={{
              width: '100%',
              bgcolor: 'sp_primary_100_main',
              minHeight: 54,
            }}
            endIcon={<Icons.ArrowForward stroke="white" />}
            onClick={onSubmit}
          >
            <Typography variant="SP_multiple_checkbox_label" color="main_white">
              {buttonTitle}
            </Typography>
          </SPButton>
          {!!backButtonTitle && (
            <Stack direction="row" onClick={() => navigate(-1)}>
              <Icons.ArrowBack
                sx={{
                  verticalAlign: 'middle',
                  marginTop: '-2px',
                }}
              />
              <Typography
                variant="SP_multiple_checkbox_label"
                color="sp_primary_100_main"
              >
                {backButtonTitle}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UpdatePasswordTemplate;
