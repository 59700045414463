import { FC, ReactNode, PropsWithChildren } from 'react';
import {
  Chip,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Icons } from 'components';

export type SPFormItemProps = PropsWithChildren<{
  required?: boolean;
  label: string | ReactNode;
  note?: string | ReactNode;
  noteType?: 'short' | 'long' | 'under';
  readOnly?: boolean;
  help?: {
    name: string;
    helpTitle: string;
  }[];
  explain?: ReactNode;
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  childrenSx?: SxProps<Theme>;
  onOpenHelpModal?: (modalName: string) => void;
}>;

export const SPFormItem: FC<SPFormItemProps> = ({
  required,
  label,
  note,
  noteType = 'long',
  onOpenHelpModal,
  help,
  children,
  explain,
  sx,
  labelSx,
  childrenSx,
}) => (
  <Stack sx={{ ...sx }}>
    <Stack>
      <Label
        required={required}
        label={label}
        help={help}
        note={note}
        noteType={noteType}
        onOpenHelpModal={onOpenHelpModal}
        sx={{ ...labelSx }}
      />
    </Stack>
    <Stack
      sx={{
        bgcolor: 'sp_bg_gray',
        pt: 3,
        pb: 8,
        px: 4,
        ...childrenSx,
      }}
    >
      {children}
      {explain}
    </Stack>
  </Stack>
);
type LabelProps = TypographyProps & {
  label: string | ReactNode;
  required?: boolean;
  readOnly?: boolean;
  help?: {
    name: string;
    helpTitle: string;
  }[];
  note?: string | ReactNode;
  noteType?: 'short' | 'long' | 'under';
  onOpenHelpModal?: (modalName: string) => void;
};

const Label: FC<LabelProps> = ({
  required,
  label,
  help,
  note,
  noteType,
  onOpenHelpModal,
  ...props
}) => (
  <Stack
    px={4}
    bgcolor="sp_primary_40"
    direction="row"
    spacing={1}
    alignItems="center"
    sx={{
      position: 'relative',
      py: 2,
      ...props.sx,
    }}
  >
    <Stack direction="row" alignItems="center" spacing={2}>
      {!required && (
        <Chip
          label={
            <Typography variant="SP_required_chip_label" color="main_white">
              任意
            </Typography>
          }
          sx={{
            bgcolor: 'sp_gray_250',
            borderRadius: 1,
            height: 14,
            width: 29,
            p: 0,
            texAlign: 'center',
            '.MuiChip-label': { p: '1px 3px', mt: '-2px' },
          }}
        />
      )}
      <Grid
        container
        direction={noteType === 'under' ? 'column' : 'row'}
        alignItems={noteType === 'under' ? 'flex-start' : 'center'}
      >
        <Grid item>
          <Grid container>
            <Grid item>
              {typeof label === 'string' ? (
                <Typography
                  justifyContent="center"
                  variant="SP_form_item_label"
                  color="b_333"
                >
                  {label}
                </Typography>
              ) : (
                label
              )}
              {!!note && noteType === 'short' && (
                <Typography pl={1} variant="SP_form_item_note" color="b_333">
                  {note}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!!note && noteType !== 'short' && (
          <Grid item justifyContent="center">
            {typeof note === 'string' ? (
              <Typography variant="SP_form_item_note" color="b_333">
                {note}
              </Typography>
            ) : (
              note
            )}
          </Grid>
        )}

        {!!help && !!onOpenHelpModal && (
          <Grid item>
            {help.map((item, index) => (
              <IconButton
                key={index}
                sx={{
                  color: 'sp_primary_40',
                  p: '0 2px',
                  height: 0,
                  direction: 'row',
                }}
              >
                <SvgIcon viewBox="0 0 17 17" sx={{ width: 16, height: 16 }} />
                <Typography
                  variant="SP_form_item_help"
                  sx={{
                    textDecoration: 'underline',
                    color: 'transparent',
                  }}
                >
                  {item.name}
                </Typography>
              </IconButton>
            ))}
            <Stack
              direction="row"
              sx={{ position: 'absolute', bottom: 10, right: 16 }}
            >
              {help.map((item, index) => (
                <IconButton
                  key={index}
                  sx={{
                    color: 'sp_primary_100_main',
                    p: '0 2px',
                    direction: 'row',
                  }}
                  onClick={() => onOpenHelpModal(item.name)}
                >
                  <Icons.SPQuestion sx={{ width: 16, height: 16 }} />
                  <Typography
                    variant="SP_form_item_help"
                    color="sp_primary_100_main"
                    sx={{
                      textDecoration: 'underline',
                    }}
                  >
                    {item.helpTitle}
                  </Typography>
                </IconButton>
              ))}
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  </Stack>
);
