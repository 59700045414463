import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { useCallback, useEffect, useMemo } from 'react';
import { FormikContextType } from 'formik/dist/types';
import {
  ApplicantDetailType,
  CODEBANK,
  DispatchType,
  FileATaxReturnType,
  FinalTaxReturnType,
  LeaveType,
  OccupationDetailType,
  OccupationType,
  WorkIndustryType,
} from 'constant';
import { FormikState } from 'formik';
import get from 'lodash/get';

type Values = AdminPreliminaries | SalePersonPreliminaries;

export const usePersonalOccupation = (
  values: Values,
  setFieldValue: FormikContextType<Values>['setFieldValue'],
  touched: FormikState<Values>['touched']
) => {
  const isMCJ = useMemo(
    () => values.p_application_header.name_master_banks?.includes(CODEBANK.MCJ),
    [values.p_application_header.name_master_banks]
  );

  const handleResetValue = useCallback(
    (key: string, defaultValue: string | number | []) => {
      if (get(values, key) === undefined) return;
      return setFieldValue(key, defaultValue);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes[0].occupation &&
      values.p_application_header.p_applicant_people_attributes[0]
        .occupation !== OccupationType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].other_occupation',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.occupation &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.occupation !== OccupationType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].other_occupation',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes[0].occupation &&
      values.p_application_header.p_applicant_people_attributes[0]
        .occupation === OccupationType.TWELVE
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].office_phone_number',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.occupation &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.occupation === OccupationType.TWELVE
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].office_phone_number',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes[0].occupation,
    values.p_application_header.p_applicant_people_attributes?.[1]?.occupation,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes[0].industry &&
      values.p_application_header.p_applicant_people_attributes[0].industry !==
        WorkIndustryType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].other_industry',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.industry &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.industry !== WorkIndustryType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].other_industry',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes[0].industry,
    values.p_application_header.p_applicant_people_attributes?.[1]?.industry,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes[0]
        .occupation_detail &&
      values.p_application_header.p_applicant_people_attributes[0]
        .occupation_detail !== OccupationDetailType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].other_occupation_detail',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.occupation_detail &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.occupation_detail !== OccupationDetailType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].other_occupation_detail',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes[0]
      .occupation_detail,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.occupation_detail,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes[0].tax_return &&
      values.p_application_header.p_applicant_people_attributes[0]
        .tax_return !== FinalTaxReturnType.YES
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].type_tax_return',
        []
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.tax_return &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.tax_return !== FinalTaxReturnType.YES
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].type_tax_return',
        []
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes[0].tax_return,
    values.p_application_header.p_applicant_people_attributes?.[1]?.tax_return,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes[0]
        .type_tax_return &&
      !values.p_application_header.p_applicant_people_attributes[0].type_tax_return.includes(
        FileATaxReturnType.OTHERS
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].other_type_tax_return',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.type_tax_return &&
      !values.p_application_header.p_applicant_people_attributes?.[1]?.type_tax_return?.includes(
        FileATaxReturnType.OTHERS
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].other_type_tax_return',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes[0]
      .type_tax_return,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.type_tax_return,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes[0]
        .applicant_detail_type &&
      values.p_application_header.p_applicant_people_attributes[0]
        .applicant_detail_type !== ApplicantDetailType.MAIN
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].current_home_loan',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.applicant_detail_type &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.applicant_detail_type !== ApplicantDetailType.MAIN
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].current_home_loan',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes[0]
      .applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes[0]
        .transfer_office &&
      values.p_application_header.p_applicant_people_attributes[0]
        .transfer_office !== DispatchType.YES
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_name_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_name_kana',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_phone_number',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_postal_code',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_prefecture_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_city_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_district_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].transfer_office_other_address_kanji',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.transfer_office &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.transfer_office !== DispatchType.YES
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_name_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_name_kana',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_phone_number',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_postal_code',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_prefecture_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_city_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_district_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].transfer_office_other_address_kanji',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes[0]
      .transfer_office,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.transfer_office,
  ]);

  useEffect(() => {
    if (!isMCJ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].two_years_ago_income',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].nursing_leave_status',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].bonus_income',
        ''
      );
      if (values.p_application_header.p_applicant_people_attributes?.[1]) {
        handleResetValue(
          'p_application_header.p_applicant_people_attributes[1].two_years_ago_income',
          ''
        );
        handleResetValue(
          'p_application_header.p_applicant_people_attributes[1].nursing_leave_status',
          ''
        );
        handleResetValue(
          'p_application_header.p_applicant_people_attributes[1].bonus_income',
          ''
        );
      }
    }
  }, [isMCJ]);

  useEffect(() => {
    if (
      ![
        LeaveType.ACQUIRING,
        LeaveType.IT_HAS_BEEN_ACQUIRED,
        LeaveType.SCHEDULED_ACQUISITION,
      ].includes(
        values.p_application_header.p_applicant_people_attributes[0]
          .maternity_paternity_leave_status as LeaveType
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].maternity_paternity_leave_start_time',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].maternity_paternity_leave_end_time',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1] &&
      ![
        LeaveType.ACQUIRING,
        LeaveType.IT_HAS_BEEN_ACQUIRED,
        LeaveType.SCHEDULED_ACQUISITION,
      ].includes(
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.maternity_paternity_leave_status as LeaveType
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].maternity_paternity_leave_start_time',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].maternity_paternity_leave_end_time',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.maternity_paternity_leave_status,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.maternity_paternity_leave_status,
  ]);
};
