import {
  ClipboardEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Avatar, Box, Checkbox, Stack, Typography } from '@mui/material';
import { AdminInput, Button } from 'components';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'hooks';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { adminBackground, logoCompany } from 'assets';
import { regex } from 'libs';
import { get } from 'lodash';
import {
  nullSchema,
  resetPasswordValidationSchema,
  updatePasswordValidationSchema,
} from './validationSchema';
import {
  checkResetTokenManager,
  resetPasswordManager,
  updatePasswordManager,
} from './thunk';

const ResetPasswordManagerPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const reset_password_token = searchParams.get('reset_password_token');
  const [loading, setLoading] = useState<boolean>(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [tokenValid, setTokenValid] = useState<boolean | undefined>(
    !!reset_password_token ? undefined : false
  );
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: reset_password_token
      ? tokenValid
        ? updatePasswordValidationSchema
        : nullSchema
      : resetPasswordValidationSchema,
    async onSubmit(manager) {
      if (!reset_password_token) {
        const result = await dispatch(resetPasswordManager({ manager }));
        if (resetPasswordManager.fulfilled.match(result)) {
          setSentEmail(true);
        }
        if (resetPasswordManager.rejected.match(result)) {
          if (
            get(result, 'payload.errors', []).some(
              ({ key }: { key: string }) => key === 'invalid_email'
            )
          ) {
            toast.error('このメールアドレスは登録されていません。');
          } else {
            toast.error('エラーが発生しました。');
          }
        }
        return;
      }
      if (reset_password_token && !tokenValid && !updateSuccess) {
        const token = searchParams.get('reset_password_token');
        if (token) {
          searchParams.delete('reset_password_token');
          setSearchParams(searchParams);
        }
        return;
      }
      if (updateSuccess) {
        return navigate(routeNames.LoginManager.path);
      }
      const result = await dispatch(
        updatePasswordManager({
          manager: {
            reset_password_token,
            password: manager.password,
            password_confirmation: manager.password_confirmation,
          },
        })
      );
      if (updatePasswordManager.fulfilled.match(result)) {
        setUpdateSuccess(true);
      } else {
        toast.error('エラーが発生しました。');
      }
    },
  });

  const checkResetPasswordToken = useCallback(async () => {
    if (reset_password_token) {
      setLoading(true);
      const result = await dispatch(
        checkResetTokenManager({ reset_password_token })
      );
      if (checkResetTokenManager.fulfilled.match(result)) {
        setTokenValid(true);
      } else {
        setTokenValid(false);
      }
      setLoading(false);
    }
  }, [dispatch, reset_password_token]);

  useLayoutEffect(() => {
    checkResetPasswordToken();
  }, [checkResetPasswordToken]);

  const titleText = useMemo(() => {
    if (updateSuccess) {
      return 'パスワード再設定完了';
    }
    if (sentEmail && !reset_password_token && !tokenValid) {
      return 'メールを送信しました';
    }
    if (!sentEmail && reset_password_token && !tokenValid) {
      return 'メールの有効期限が切れています';
    }
    if (
      !sentEmail &&
      ((!reset_password_token && !tokenValid) ||
        (reset_password_token && tokenValid))
    ) {
      return 'パスワード再設定';
    }
  }, [reset_password_token, sentEmail, tokenValid, updateSuccess]);

  const buttonText = useMemo(() => {
    if (!reset_password_token) {
      return '再設定メールを送信';
    }
    if (reset_password_token && !tokenValid) {
      return 'パスワードの再設定はこちら';
    }
    if (reset_password_token && updateSuccess) {
      return 'ログイン';
    }
    return '変更する';
  }, [reset_password_token, tokenValid, updateSuccess]);

  const onEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const onPreventPassword = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  if (loading) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: `url(${adminBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          sx={{
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            bgcolor: 'main_white',
            borderRadius: '5px',
            width: '430px',
          }}
        >
          <Box m={10}>
            <Stack display="flex" justifyContent="center" alignItems="center">
              <Avatar
                src={logoCompany}
                variant="square"
                sx={{ height: '64px', width: '272px' }}
              />
              <Typography variant="heading01" mt={5} color="b_333">
                {titleText}
              </Typography>
            </Stack>
            {((!reset_password_token && sentEmail) ||
              (reset_password_token && tokenValid === undefined)) && (
              <Stack mt={5}>
                <Typography variant="annotation01" color="b_333">
                  メールに記載されているURLにアクセスし
                </Typography>
                <Typography variant="annotation01" color="b_333">
                  パスワード再設定を完了してください。
                </Typography>
              </Stack>
            )}
            {!reset_password_token && !sentEmail && (
              <Stack onKeyPress={onEnter}>
                <Typography variant="annotation01" mt={5} color="b_333">
                  ご登録のメールアドレスを入力してください。
                </Typography>
                <Typography variant="annotation01" color="b_333">
                  パスワード再設定用のメールをお送りします。
                </Typography>
                <Stack sx={{ mt: '45px' }}>
                  <Typography variant="headline_input" color="b_333">
                    メールアドレス
                  </Typography>
                </Stack>
                <Stack sx={{ mt: '4px', width: 350 }}>
                  <AdminInput
                    name="email"
                    type="email"
                    placeholder="入力してください"
                    InputProps={{
                      sx: {
                        '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                          {
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderColor: 'sp_secondary_01',
                          },
                      },
                    }}
                    error={formik.touched.email && !!formik.errors.email}
                  />
                </Stack>
              </Stack>
            )}

            {reset_password_token && tokenValid && !updateSuccess && (
              <Stack>
                <Stack sx={{ mt: '15px' }}>
                  <Typography variant="headline_input" color="b_333">
                    新しいパスワード
                  </Typography>
                  <Stack sx={{ mt: 1, width: 350 }}>
                    <AdminInput
                      name="password"
                      placeholder="入力してください"
                      InputProps={{
                        sx: {
                          '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                            {
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderColor: 'sp_secondary_01',
                            },
                        },
                      }}
                      disabled={false}
                      hasPassword
                      error={
                        formik.touched.password && !!formik.errors.password
                      }
                      onCut={onPreventPassword}
                      onCopy={onPreventPassword}
                      onPaste={onPreventPassword}
                    />
                  </Stack>
                </Stack>
                <Stack sx={{ mt: '15px' }}>
                  <Typography variant="SP_password_note" color="b_333">
                    パスワードの条件
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    mt: 3,
                  }}
                >
                  <Checkbox
                    checked={
                      formik.values.password.length >= 8 &&
                      formik.values.password.length <= 20
                    }
                    sx={{
                      width: 16,
                      height: 16,
                      color: 'sp_primary_40',
                      '&.Mui-checked': {
                        color: 'sp_secondary_01',
                      },
                      mr: 1,
                      '& .MuiSvgIcon-root': {
                        width: 16,
                        height: 16,
                      },
                    }}
                  />

                  <Typography
                    variant="SP_password_note"
                    color={
                      formik.values.password.length > 0 &&
                      (formik.values.password.length < 8 ||
                        formik.values.password.length > 20)
                        ? 'sp_secondary_01'
                        : 'b_333'
                    }
                  >
                    8文字以上20文字以下
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{
                    mt: 3,
                  }}
                >
                  <Checkbox
                    checked={!!formik.values.password.match(regex.password)}
                    sx={{
                      width: 16,
                      height: 16,
                      color: 'sp_primary_40',
                      '&.Mui-checked': {
                        color: 'sp_secondary_01',
                      },
                      mr: 1,
                      '& .MuiSvgIcon-root': {
                        width: 16,
                        height: 16,
                      },
                    }}
                  />

                  <Typography
                    variant="SP_password_note"
                    color={
                      formik.values.password.length > 0 &&
                      formik.values.password.length < 8
                        ? 'sp_secondary_01'
                        : 'b_333'
                    }
                  >
                    大文字英字・小文字英字・数字の3種混在
                  </Typography>
                </Stack>
                <Stack sx={{ mt: 4 }}>
                  <Typography variant="headline_input" color="b_333">
                    新しいパスワード（確認用）
                  </Typography>
                  <Stack sx={{ mt: 1, width: 350 }}>
                    <AdminInput
                      name="password_confirmation"
                      placeholder="入力してください"
                      InputProps={{
                        sx: {
                          '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                            {
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderColor: 'sp_secondary_01',
                            },
                        },
                      }}
                      disabled={false}
                      hasPassword
                      error={
                        formik.touched.password_confirmation &&
                        !!formik.errors.password_confirmation
                      }
                      onCut={onPreventPassword}
                      onCopy={onPreventPassword}
                      onPaste={onPreventPassword}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}

            {reset_password_token && tokenValid === false && (
              <Stack sx={{ mt: 5, color: 'b_333' }}>
                <Typography variant="annotation02">
                  メール受信後、一定時間内での
                </Typography>
                <Typography variant="annotation02">
                  操作が確認できなかったため
                </Typography>
                <Typography variant="annotation02">
                  パスワードの再設定に失敗しました。
                </Typography>
                <Typography variant="annotation02" sx={{ mt: 5 }}>
                  恐れ入りますが、
                </Typography>
                <Typography variant="annotation02">
                  再度パスワード再設定の手続きを
                </Typography>
                <Typography variant="annotation02">
                  お願いいたします。
                </Typography>
              </Stack>
            )}

            {updateSuccess && (
              <Stack sx={{ mt: 5 }}>
                <Typography variant="annotation02" color="b_333">
                  パスワード再設定が完了しました。
                </Typography>
                <Typography variant="annotation02" color="b_333">
                  ログイン画面に戻りログインをしてください。
                </Typography>
              </Stack>
            )}

            {!sentEmail && tokenValid !== undefined && (
              <Stack
                sx={{ mt: '40px' }}
                direction="row"
                justifyContent="center"
              >
                <Button
                  disabled={formik.isSubmitting}
                  sx={{
                    bgcolor: 'white',
                    boxShadow: 'none',
                    width: '200px',
                    height: '36px',
                    borderRadius: '2px',
                    minHeight: '36px',
                    border: '1px solid #6B70F0',
                  }}
                  onClick={() => formik.handleSubmit()}
                >
                  <Typography variant="button01" color="sp_primary_100_main">
                    {buttonText}
                  </Typography>
                </Button>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
    </FormikProvider>
  );
};

export default ResetPasswordManagerPage;
