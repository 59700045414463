import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ClickAwayListener, Stack, Typography } from '@mui/material';
import { isReactNode } from 'utils';
import { AuthPopover, Button, Icons } from 'components';
import { useAppDispatch, useMatchedRoute } from 'hooks';
import { useSelector } from 'react-redux';
import { managerName } from 'pages/Login/selectors';
import { salePersonNameSelector } from 'pages/SalePersonLogin/selectors';
import { useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { routeNames } from 'navigations/routes';
import { useNavigate } from 'react-router-dom';
import { selectedPreliminarySelectors } from 'pages/ManagerPreliminaries/selectors';
import { adminPreliminariesSelector } from 'pages/AdminEditPreliminary/selectors';
import { salePersonPreliminariesSelector } from 'pages/SalePersonEditPreliminary/selectors';
import {
  getMessageDetailManager,
  getMessageDetailSalePerson,
} from 'pages/ScreenMessages/thunk';
import { setConversationDetail } from 'pages/ScreenMessages/slice';
import { isAzureLoginSelector } from '../../../AuthModal/selectors';

export type HeaderProps = PropsWithChildren<{
  left?: ReactNode | LeftHeaderProps | false;
  right?: ReactNode | RightHeaderProps | false;
  showIconHome?: boolean;
  showIconMail?: boolean;
  setOpenModal?: (open: boolean) => void;
  setOpenModalLogout?: (logout: boolean) => void;
  setOpenModalNewMessage?: (open: boolean) => void;
  onDownloadLog?: () => void;
}>;

type LeftHeaderProps = {
  mainTitle?: string | ReactNode;
  colorTitle?: string;
  buttonText?: string;
  positionTitle?: string;
};

type RightHeaderProps = {
  userName?: string;
  Icons?: boolean;
  contentPopover?: ReactNode;
};

export const Header: FC<HeaderProps> = ({
  left,
  right,
  children,
  showIconHome = true,
  showIconMail = true,
  setOpenModal,
  setOpenModalLogout,
  setOpenModalNewMessage,
  onDownloadLog,
}) => {
  const currentRoute = useMatchedRoute();
  const location = useLocation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const preliminaryId = useMemo(() => {
    const preliminaryIdParam = query.get('preliminary_id');
    return preliminaryIdParam ? preliminaryIdParam : '';
  }, [query]);

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const nameManager = useSelector(managerName);
  const nameSalePerson = useSelector(salePersonNameSelector);
  const admin = useSelector(adminPreliminariesSelector);
  const salePerson = useSelector(salePersonPreliminariesSelector);
  const { id, applicant_name } = useSelector(selectedPreliminarySelectors);
  const navigate = useNavigate();
  const isAzureLogin = useSelector(isAzureLoginSelector);

  const goToPreliminariesPage = useCallback(() => {
    if (location.pathname.startsWith('/manager/', 0)) {
      navigate(routeNames.ManagerPreliminaries.path);
    } else {
      navigate(routeNames.SalePersonPreliminaries.path);
    }
  }, [location.pathname, navigate]);

  const goToMessagesPage = useCallback(() => {
    if (location.pathname.startsWith('/manager/', 0)) {
      navigate(routeNames.ManagerMessages.path);
    } else {
      navigate(routeNames.SalePersonMessages.path);
    }
  }, [location.pathname, navigate]);

  const onClickButton = () => {
    if (location.pathname === routeNames.ManagerMessages.path) {
      setOpenModalNewMessage && setOpenModalNewMessage(true);
    }
  };

  const goToMessageDetail = useCallback(
    async (pathname: string) => {
      if (pathname.startsWith('/manager')) {
        const result = await dispatch(
          getMessageDetailManager(admin.p_application_header.user_id ?? '')
        );
        if (getMessageDetailManager.fulfilled.match(result)) {
          dispatch(
            setConversationDetail({
              conversationId: admin.p_application_header.user_id ?? '',
              conversationName:
                admin.p_application_header?.p_applicant_people_attributes?.[0]
                  ?.full_name_kanji ?? '',
            })
          );
          navigate({
            pathname: routeNames.ManagerMessageDetail.path,
            search: `?preliminary_id=${preliminaryId}`,
          });
        }
      } else {
        const result = await dispatch(
          getMessageDetailSalePerson(
            salePerson.p_application_header.user_id ?? ''
          )
        );
        if (getMessageDetailSalePerson.fulfilled.match(result)) {
          dispatch(
            setConversationDetail({
              conversationId: salePerson.p_application_header.user_id ?? '',
              conversationName:
                salePerson.p_application_header
                  ?.p_applicant_people_attributes?.[0]?.full_name_kanji ?? '',
            })
          );
          navigate({
            pathname: routeNames.SalePersonMessageDetail.path,
            search: `?preliminary_id=${preliminaryId}`,
          });
        }
      }
    },
    [
      admin.p_application_header?.p_applicant_people_attributes,
      admin.p_application_header.user_id,
      dispatch,
      navigate,
      preliminaryId,
      salePerson.p_application_header?.p_applicant_people_attributes,
      salePerson.p_application_header.user_id,
    ]
  );

  if (!currentRoute) return null;

  return (
    <Stack
      direction="row"
      bgcolor="bg_off"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'main_white',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderTop: '1px solid',
        borderColor: 'gray',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
      }}
    >
      <Stack flex={1}>
        {isReactNode(left) && left}
        {left !== false && !isReactNode(left) && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              px: 5,
              py: 2,
            }}
          >
            <Stack direction="row" spacing={8} alignItems="center">
              {isReactNode(left.mainTitle) ? (
                left.mainTitle
              ) : (
                <Typography
                  variant="text_steps"
                  color={left.colorTitle ? left.colorTitle : 'normal_text'}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {left.mainTitle ?? '事前審査結果'}
                </Typography>
              )}
              {left.buttonText && (
                <Button
                  sx={{
                    ml: 5,
                    bgcolor: 'main_white',
                    width: '93px',
                    minHeight: '25px',
                    height: '25px',
                    borderRadius: '4px',
                    border: (theme) =>
                      `1px solid ${theme?.palette?.h_blugreen}`,
                  }}
                  startIcon={
                    <Add
                      sx={{
                        color: 'sp_primary_100_main',
                        width: 16,
                        height: 16,
                      }}
                    />
                  }
                  startIconStyle={{ left: 7, bottom: 3 }}
                  onClick={onClickButton}
                >
                  <Typography
                    variant="dashboard_text"
                    color="sp_primary_100_main"
                    fontSize={12}
                  >
                    {left.buttonText}
                  </Typography>
                </Button>
              )}
            </Stack>
            {!!left.positionTitle && (
              <Stack sx={{ pl: 4, pr: 2 }}>
                <Icons.Slash sx={{ width: 14, height: 14 }} />
              </Stack>
            )}
            <Typography
              variant="position_text"
              color="normal_text"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {left.positionTitle}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Stack flex={2} alignItems="center">
        {children}
      </Stack>
      <Stack flex={1} direction="row" justifyContent="flex-end" sx={{ pr: 4 }}>
        {isReactNode(right) && right}
        {right !== false && !isReactNode(right) && (
          <AuthPopover
            sx={{
              top: 14,
              left: 4,
            }}
            content={
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Stack>
                  {!!nameManager && (
                    <Button
                      sx={{
                        py: 0,
                        px: '10px',
                        flex: 1,
                        width: '100%',
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        minHeight: 42,
                        height: 42,
                        boxShadow: 'none',
                        bgcolor: 'main_white',
                        borderBottom: (theme) =>
                          `1px solid ${theme?.palette?.line}`,
                        justifyContent: 'flex-start',
                        '&.MuiButton-root:hover': {
                          bgcolor: 'sp_primary_100_main',
                          '& .MuiTypography-root': {
                            color: 'main_white',
                          },
                        },
                      }}
                      onClick={() => {
                        onDownloadLog && onDownloadLog();
                        setOpen(false);
                      }}
                    >
                      <Typography variant="auth_popover_text" color="b_333">
                        監視ログダウンロード
                      </Typography>
                      <Icons.Excel sx={{ color: 'attention', ml: '9px' }} />
                    </Button>
                  )}
                  {!isAzureLogin && (
                    <Button
                      sx={{
                        py: 0,
                        px: '10px',
                        flex: 1,
                        width: '100%',
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        minHeight: 42,
                        height: 42,
                        boxShadow: 'none',
                        bgcolor: 'main_white',
                        borderBottom: (theme) =>
                          `1px solid ${theme?.palette?.line}`,
                        justifyContent: 'flex-start',
                        '&.MuiButton-root:hover': {
                          bgcolor: 'sp_primary_100_main',
                          '& .MuiTypography-root': {
                            color: 'main_white',
                          },
                        },
                      }}
                      onClick={() => {
                        setOpenModal && setOpenModal(true);
                        setOpen(false);
                      }}
                    >
                      <Typography variant="auth_popover_text" color="b_333">
                        パスワード変更
                      </Typography>
                    </Button>
                  )}
                  {right.contentPopover}
                  <Button
                    sx={{
                      py: 0,
                      px: '10px',
                      flex: 1,
                      width: '100%',
                      borderRadius: 0,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      minHeight: 42,
                      height: 42,
                      boxShadow: 'none',
                      bgcolor: 'main_white',
                      borderTop: (theme) => `1px solid ${theme?.palette?.line}`,
                      justifyContent: 'flex-start',
                      '&.MuiButton-root:hover': {
                        bgcolor: 'sp_primary_100_main',
                        '& .MuiTypography-root': {
                          color: 'main_white',
                        },
                      },
                    }}
                    onClick={() => {
                      setOpenModalLogout && setOpenModalLogout(true);
                      setOpen(false);
                    }}
                  >
                    <Typography variant="auth_popover_text" color="b_333">
                      ログアウト
                    </Typography>
                  </Button>
                </Stack>
              </ClickAwayListener>
            }
            open={open}
            placement="bottom-end"
            contentProps={{
              width: 220,
              height: 'auto',
              padding: 0,
              color: 'normal_text',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              {showIconHome && (
                <Icons.Home
                  sx={{ mr: 5, cursor: 'pointer', width: 16, height: 18 }}
                  onClick={goToPreliminariesPage}
                />
              )}
              {location.pathname.includes('/message-detail') &&
                query.has('preliminary_id') && (
                  <Button
                    sx={{
                      width: 108,
                      minHeight: 22,
                      height: 22,
                      border: '1px solid',
                      marginRight: '10px',
                      borderColor: 'sp_primary_100_main',
                      bgcolor: 'main_white',
                      boxShadow: 'none',
                    }}
                    onClick={() => {
                      navigate({
                        pathname: location.pathname.startsWith('/manager', 0)
                          ? routeNames.AdminEditPreliminary.path
                          : routeNames.SalePersonEditPreliminary.path,
                        search: `?preliminary_id=${preliminaryId}`,
                      });
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography
                        variant="SP_form_item_note"
                        color="sp_primary_100_main"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        申し込み内容確認
                      </Typography>
                    </Stack>
                  </Button>
                )}
              {showIconMail && (
                <Icons.Mail
                  sx={{ mr: 6, cursor: 'pointer', width: 18, height: 14 }}
                  onClick={goToMessagesPage}
                />
              )}
              {location.pathname.includes('/manager/memo') &&
                query.has('preliminary_id') && (
                  <Button
                    sx={{
                      width: 140,
                      minWidth: 140,
                      minHeight: 22,
                      height: 22,
                      border: '1px solid',
                      marginRight: '10px',
                      borderColor: 'sp_primary_100_main',
                      bgcolor: 'main_white',
                      boxShadow: 'none',
                    }}
                    onClick={() => {
                      navigate({
                        pathname: routeNames.AdminEditPreliminary.path,
                        search: `?preliminary_id=${preliminaryId}`,
                      });
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography
                        variant="SP_form_item_note"
                        color="sp_primary_100_main"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        申込内容の修正・確認
                      </Typography>
                    </Stack>
                  </Button>
                )}
              {!!id && location.pathname.includes('/manager/edit-preliminary') && (
                <Button
                  sx={{
                    width: 65,
                    minHeight: 22,
                    height: 22,
                    border: '1px solid',
                    marginRight: '10px',
                    borderColor: 'sp_primary_100_main',
                    bgcolor: 'main_white',
                    boxShadow: 'none',
                  }}
                  onClick={() => {
                    navigate(
                      {
                        pathname: routeNames.Memo.path,
                        search: `?preliminary_id=${preliminaryId}`,
                      },
                      { state: { label: applicant_name?.[0] } }
                    );
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography
                      variant="SP_form_item_note"
                      color="sp_primary_100_main"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      メモ
                    </Typography>
                  </Stack>
                </Button>
              )}
              {location.pathname.includes('/edit-preliminary') && (
                <Button
                  sx={{
                    width: 115,
                    minWidth: 115,
                    px: 4,
                    minHeight: 22,
                    height: 22,
                    border: '1px solid',
                    marginRight: '10px',
                    borderColor: 'sp_primary_100_main',
                    bgcolor: 'main_white',
                    boxShadow: 'none',
                  }}
                  onClick={() => {
                    goToMessageDetail(location.pathname);
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography
                      variant="SP_form_item_note"
                      color="sp_primary_100_main"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      メッセージ画面へ
                    </Typography>
                  </Stack>
                </Button>
              )}
              <Typography
                color="b_333"
                sx={{
                  mr: 3,
                  borderLeft: '1px solid #CCCCCC',
                  pl: '17px',
                  py: 1,
                  width: 'max-content',
                }}
                variant="text_manager_name"
              >
                {location.pathname.startsWith('/manager/', 0)
                  ? nameManager
                  : nameSalePerson}
              </Typography>
              <Icons.Gear
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpen((prevState) => !prevState)}
              />
            </Stack>
          </AuthPopover>
        )}
      </Stack>
    </Stack>
  );
};
