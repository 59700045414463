import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdminLoginRequest,
  AdminLoginResponse,
  authService,
  LoginError,
  LoginRequest,
  LoginResponse,
} from 'services';
import { ThunkAPI } from 'types';
import { AxiosError } from 'axios';

export const login = createAsyncThunk<
  LoginResponse,
  LoginRequest,
  ThunkAPI<LoginError>
>('authModal/login', async (user, { rejectWithValue }) => {
  try {
    return await authService.login(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const managerLogin = createAsyncThunk<
  AdminLoginResponse,
  AdminLoginRequest,
  ThunkAPI<LoginError>
>('manager/login', async (manager, { rejectWithValue }) => {
  try {
    return await authService.managerLogin(manager);
  } catch (error) {
    const err = (error as AxiosError<LoginError>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});

export const managerLogout = createAsyncThunk(
  'manager/logout',
  async (thunkAPI, { rejectWithValue }) => {
    try {
      return await authService.managerLogout();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const managerTokenInvalidLogout = createAsyncThunk(
  'manager/logout',
  async (thunkAPI, { rejectWithValue }) => {
    try {
      return await authService.managerTokenInvalidLogout();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
