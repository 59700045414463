import { CircularProgress, Stack } from '@mui/material';
import { Icons } from 'components';
import { ROWS_PER_PAGE } from 'constant';
import { AdminLayout } from 'containers';
import { useAppDispatch } from 'hooks';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { routeNames } from 'navigations/routes';
import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MessageItemAdmin } from 'services';
import { OrderType } from 'types';
import { MessagesTable } from './components/MessagesTable';
import { messagesAdminSelectors, totalMessageSelectors } from './selectors';
import { revertMessage } from './slice';
import {
  getListUserManager,
  getListUserSalePerson,
  getMessagesManager,
  getMessagesSalePerson,
} from './thunk';

const MessagesPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const messagesAdmin = useSelector(messagesAdminSelectors.selectAll);
  const totalMessage = useSelector(totalMessageSelectors);
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: Math.ceil(totalMessage / ROWS_PER_PAGE),
    fetching: false,
  });
  const [orderBy, setOrderBy] =
    useState<keyof MessageItemAdmin>('time_send_message');
  const [order, setOrder] = useState<OrderType>('desc');

  const handleGetMessage = useCallback(async () => {
    dispatch(revertMessage());
    setPagination((prev) => ({ ...prev, fetching: true }));
    if (location.pathname === routeNames.ManagerMessages.path) {
      dispatch(getListUserManager());
      await dispatch(
        getMessagesManager({
          order_key: 'time_send_message',
          order_sort: 'desc',
        })
      );
      setPagination((prev) => ({ ...prev, fetching: false }));
    } else {
      dispatch(getListUserSalePerson());
      await dispatch(
        getMessagesSalePerson({
          order_key: 'time_send_message',
          order_sort: 'desc',
        })
      );
      setPagination((prev) => ({ ...prev, fetching: false }));
    }
  }, [dispatch, location.pathname]);

  const handleRequestSort = async (
    event: MouseEvent<unknown>,
    property: keyof MessageItemAdmin
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination((prevState) => ({ ...prevState, page: 1 }));
    await dispatch(revertMessage());
    if (location.pathname === routeNames.ManagerMessages.path) {
      dispatch(
        getMessagesManager({
          order_key: property,
          order_sort: isAsc ? 'desc' : 'asc',
        })
      );
    } else {
      dispatch(
        getMessagesSalePerson({
          order_key: orderBy,
          order_sort: isAsc ? 'desc' : 'asc',
        })
      );
    }
  };

  const handleChangePage = useCallback(
    async (page: number) => {
      setPagination((prev) => ({
        ...prev,
        page: page,
        fetching: true,
      }));
      const isAsc = order === 'asc';
      if (location.pathname === routeNames.ManagerMessages.path) {
        await dispatch(
          getMessagesManager({
            order_key: orderBy,
            order_sort: isAsc ? 'asc' : 'desc',
            page: page,
          })
        );
        setPagination((prev) => ({ ...prev, fetching: false }));
      } else {
        await dispatch(
          getMessagesSalePerson({
            order_key: orderBy,
            order_sort: isAsc ? 'asc' : 'desc',
            page: page,
          })
        );
        setPagination((prev) => ({ ...prev, fetching: false }));
      }
    },
    [dispatch, location.pathname, order, orderBy]
  );

  useEffect(() => {
    handleGetMessage();
  }, [dispatch, handleGetMessage]);

  useInfiniteScroll({
    callback: (page) => handleChangePage(page),
    pagination: pagination,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isAsc = order === 'asc';
      if (location.pathname === routeNames.ManagerMessages.path) {
        dispatch(
          getMessagesManager({
            order_key: orderBy,
            order_sort: isAsc ? 'asc' : 'desc',
          })
        );
      } else {
        dispatch(
          getMessagesSalePerson({
            order_key: orderBy,
            order_sort: isAsc ? 'asc' : 'desc',
          })
        );
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [dispatch, location.pathname, order, orderBy]);

  return (
    <AdminLayout
      hasDrawer={false}
      header={{
        left: {
          mainTitle: 'お客様とのメッセージ一覧',
          colorTitle: 'b_333',
          buttonText: `${
            location.pathname === routeNames.ManagerMessages.path
              ? '新規作成'
              : ''
          }`,
        },
        right: { Icons: true, userName: '' },
        showIconMail: false,
      }}
      footer={{
        left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
        right: <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />,
        bgcolor: 'footer_bg',
      }}
    >
      <Stack>
        <MessagesTable
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          rows={messagesAdmin.filter((message) => !!message)}
        />
        {pagination.fetching && (
          <Stack alignItems={'center'} sx={{ marginBottom: 10 }}>
            <CircularProgress />
          </Stack>
        )}
      </Stack>
    </AdminLayout>
  );
};
export default MessagesPage;
