import {
  AdminPreliminaries,
  SalePersonPreliminaries,
  UploadedFileKeys,
} from 'services';
import { useCallback, useEffect } from 'react';
import { FormikContextType, FormikState } from 'formik';
import {
  ApplicantDetailType,
  CoOwnerType,
  DesiredMonthlyBonusType,
  HasLandAdvancePlanType,
  LoanType,
  PurposeType,
} from 'constant';
import { cloneDeep, get } from 'lodash';
import {
  APPLICANT_PEOPLE_ATTRIBUTE,
  JOIN_GUARANTORS_ATTRIBUTE,
} from '../constant';

type Values = (AdminPreliminaries | SalePersonPreliminaries) & UploadedFileKeys;

export const useBorrowingDetail = (
  values: Values,
  setFieldValue: FormikContextType<Values>['setFieldValue'],
  touched: FormikState<Values>['touched'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataIncome: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataJoinGuarantor: any
) => {
  const handleResetValue = useCallback(
    (key: string, defaultValue: string | number | unknown | undefined[]) => {
      if (get(values, key) === undefined) return;
      return setFieldValue(key, defaultValue, true);
    },
    [setFieldValue]
  );
  useEffect(() => {
    // 2 case reset loan amount 2:
    // reset has_land_advance_plan
    // reset loan_target
    if (
      values.p_application_header.loan_target !== PurposeType.SIX &&
      values.p_application_header.has_land_advance_plan
    ) {
      const [newValue] =
        values.p_application_header.p_borrowing_details_attributes;
      const { has_land_advance_plan, ...newApplicantHeader } =
        values.p_application_header;
      handleResetValue('p_application_header', newApplicantHeader);
      setTimeout(() => {
        handleResetValue(
          'p_application_header.p_borrowing_details_attributes',
          [newValue]
        );
      }, 0);
    }
  }, [
    values.p_application_header.loan_target,
    values.p_application_header.loan_target_zero,
  ]);

  useEffect(() => {
    //default has guarantors
    // if (
    //   values.p_application_header.p_applicant_people_attributes?.[0]
    //     ?.has_join_guarantor === CoOwnerType.CANBE &&
    //   values.p_application_header.p_join_guarantors_attributes.length > 0
    // ) {
    //   return;
    // }

    //add new join_guarantors or get previous join_guarantors
    if (
      values.p_application_header.p_applicant_people_attributes?.[0]
        ?.has_join_guarantor === CoOwnerType.CANBE
    ) {
      if (!!dataJoinGuarantor && !!dataJoinGuarantor[0]?.id) {
        handleResetValue(
          'p_application_header.p_join_guarantors_attributes',
          dataJoinGuarantor
        );
      } else {
        handleResetValue('p_application_header.p_join_guarantors_attributes', [
          JOIN_GUARANTORS_ATTRIBUTE,
        ]);
      }
    }

    // remove join_guarantors
    if (
      values.p_application_header.p_applicant_people_attributes?.[0]
        ?.has_join_guarantor !== undefined &&
      [CoOwnerType.NONE, CoOwnerType.DEFAULT].includes(
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.has_join_guarantor ?? ''
      ) &&
      values.p_application_header.p_join_guarantors_attributes.length > 0
    ) {
      handleResetValue('p_application_header.p_join_guarantors_attributes', []);
    }
  }, [
    dataJoinGuarantor,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.has_join_guarantor,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_borrowing_details_attributes[0]
        .halfyear_bonus &&
      values.p_application_header.p_borrowing_details_attributes[0]
        .desired_monthly_bonus === DesiredMonthlyBonusType.ONE
    ) {
      handleResetValue(
        'p_application_header.p_borrowing_details_attributes[0].desired_monthly_bonus',
        DesiredMonthlyBonusType.TWO
      );
    }
  }, [
    values.p_application_header.p_borrowing_details_attributes[0]
      .halfyear_bonus,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_borrowing_details_attributes[0]
        .halfyear_bonus === '' ||
      values.p_application_header.p_borrowing_details_attributes[0]
        .halfyear_bonus === '0'
    ) {
      handleResetValue(
        'p_application_header.p_borrowing_details_attributes[0].desired_monthly_bonus',
        DesiredMonthlyBonusType.ONE
      );
    }
  }, [
    values.p_application_header.p_borrowing_details_attributes[0]
      .halfyear_bonus,
  ]);

  useEffect(() => {
    if (
      (values.p_application_header.has_land_advance_plan ===
        HasLandAdvancePlanType.NO ||
        values.p_application_header.has_land_advance_plan === '') &&
      values.p_application_header.p_borrowing_details_attributes.length > 1
    ) {
      const [newValue] =
        values.p_application_header.p_borrowing_details_attributes;
      setTimeout(() => {
        handleResetValue(
          'p_application_header.p_borrowing_details_attributes',
          [newValue]
        );
      }, 0);
    }
  }, [values.p_application_header.has_land_advance_plan]);

  useEffect(() => {
    const currentBorrowingDetail =
      values.p_application_header.p_borrowing_details_attributes;
    if (
      values.p_application_header.has_land_advance_plan ===
        HasLandAdvancePlanType.YES &&
      currentBorrowingDetail.length === 1
    ) {
      setTimeout(() => {
        handleResetValue(
          'p_application_header.p_borrowing_details_attributes',
          [
            ...currentBorrowingDetail,
            {
              loan_desired_borrowing_date: '',
              temporary_desired_loan_amount: '',
              halfyear_bonus: '',
              desired_monthly_bonus:
                currentBorrowingDetail[0].desired_monthly_bonus,
              loan_term_year_num: currentBorrowingDetail[0].loan_term_year_num,
              loan_term_month_num:
                currentBorrowingDetail[0].loan_term_month_num,
              repayment_method: currentBorrowingDetail[0].repayment_method,
              borrowing_detail_type: '2',
              _destroy: false,
              has_log_fields: [],
            },
          ]
        );
      }, 0);
    }
  }, [values.p_application_header.has_land_advance_plan]);

  //loan_type
  useEffect(() => {
    if (values.p_application_header.loan_type === undefined) {
      return;
    }

    // remove when LoanType = ''
    if (
      values.p_application_header.loan_type === LoanType.DEFAULT &&
      values.p_application_header.p_applicant_people_attributes.length > 1
    ) {
      handleResetValue('p_application_header.p_applicant_people_attributes', [
        values.p_application_header.p_applicant_people_attributes[0],
      ]);
      return;
    }

    // remove when LoanType = 1, 3, 4
    if (
      [LoanType.ONE, LoanType.THREE, LoanType.FOUR].includes(
        values.p_application_header.loan_type
      )
    ) {
      handleResetValue(
        'p_application_header.pair_loan_applicant_last_name',
        undefined
      );
      handleResetValue(
        'p_application_header.pair_loan_applicant_first_name',
        undefined
      );
      handleResetValue(
        'p_application_header.pair_loan_relationship_name',
        undefined
      );
      handleResetValue(
        'p_application_header.pair_loan_relationship',
        undefined
      );
      handleResetValue('p_application_header.pair_loan_amount', undefined);
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].other_relationship_to_applicant',
        ''
      );
    }

    if (
      values.p_application_header.loan_type === LoanType.TWO &&
      values.p_application_header.pair_loan_applicant_last_name === undefined
    ) {
      handleResetValue(
        'p_application_header.pair_loan_applicant_last_name',
        ''
      );
    }

    if (
      values.p_application_header.loan_type === LoanType.TWO &&
      values.p_application_header.pair_loan_applicant_first_name === undefined
    ) {
      handleResetValue(
        'p_application_header.pair_loan_applicant_first_name',
        ''
      );
    }

    if (
      values.p_application_header.loan_type === LoanType.TWO &&
      values.p_application_header.pair_loan_relationship_name === undefined
    ) {
      handleResetValue('p_application_header.pair_loan_relationship_name', '');
    }

    if (
      values.p_application_header.loan_type === LoanType.TWO &&
      values.p_application_header.pair_loan_relationship === undefined
    ) {
      handleResetValue('p_application_header.pair_loan_relationship', '');
    }

    if (
      values.p_application_header.loan_type === LoanType.TWO &&
      values.p_application_header.pair_loan_amount === undefined
    ) {
      handleResetValue('p_application_header.pair_loan_amount', '0');
    }

    // add people
    if (
      [LoanType.THREE, LoanType.FOUR].includes(
        values.p_application_header.loan_type
      ) &&
      values.p_application_header.p_applicant_people_attributes.length === 1
    ) {
      if (!!dataIncome && !!dataIncome.id) {
        handleResetValue('p_application_header.p_applicant_people_attributes', [
          ...values.p_application_header.p_applicant_people_attributes,
          {
            ...cloneDeep(dataIncome),
          },
        ]);
        handleResetValue('filesIncomeAdder', dataIncome.personal_file_upload);
      } else {
        handleResetValue('p_application_header.p_applicant_people_attributes', [
          ...values.p_application_header.p_applicant_people_attributes,
          {
            ...cloneDeep(APPLICANT_PEOPLE_ATTRIBUTE),
            applicant_detail_type: ApplicantDetailType.PAIR,
            identity_verification: '',
          },
        ]);
      }
    }

    //remove people
    if (
      [LoanType.ONE, LoanType.TWO].includes(
        values.p_application_header.loan_type
      ) &&
      values.p_application_header.p_applicant_people_attributes.length > 1
    ) {
      handleResetValue('p_application_header.p_applicant_people_attributes', [
        values.p_application_header.p_applicant_people_attributes[0],
      ]);
    }
  }, [values.p_application_header.loan_type, dataIncome]);
};
