import { Link, Stack, Typography } from '@mui/material';
import { Icons, SPButton } from 'components';
import { config } from 'configs';
import { SPStepLayout } from 'containers';
import { resetAuth } from 'containers/AuthModal/slice';
import { logout } from 'containers/AuthModal/thunk';
import { ImageContext } from 'context';
import { useAppDispatch } from 'hooks';
import { routeNames } from 'navigations/routes';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { supportDvh } from 'utils';
import { clearSalePerson, yup } from '../../libs';
import { spStepOneSelector } from '../SPStepOne/selectors';
import { addStatus } from '../SPStepOne/slice';
import { Consent } from './Consent';

const SPAgreementPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const refConsent = useRef<HTMLDivElement>(null);
  const initialValues = useSelector(spStepOneSelector);
  const refConfirmation = useRef<HTMLDivElement>(null);
  const { resetImage } = useContext(ImageContext);
  const [consentRef, setConsentRef] = useState<boolean>(false);
  const [confirmationRef, setConfirmationRef] = useState<boolean>(false);
  const [consents, setConsents] = useState<(boolean | undefined)[]>([
    undefined,
    undefined,
  ]);
  const urls = [config.dummyPdfUrl, config.termConditionUrl];

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({}),
    onSubmit(values, { setSubmitting }) {
      dispatch(
        addStatus({
          firstElement: true,
          secondElement: true,
          isReseted: initialValues.status?.isReseted,
        })
      );
      setSubmitting(false);
      navigate(routeNames.Top.path);
    },
  });

  const handleBrowserBackButton = async () => {
    await dispatch(logout());
    clearSalePerson();
    dispatch(resetAuth());
    resetImage();
  };
  useEffect(() => {
    window.history.pushState('fake-route', document.title, document.URL);
    window.history.pushState('fake-route', document.title, document.URL);

    window.addEventListener('popstate', handleBrowserBackButton);

    return () => {
      window.removeEventListener('popstate', handleBrowserBackButton);
      window.onpopstate = () => {};
    };
  }, [handleBrowserBackButton]);

  const handleScrollConsent = () => {
    if (refConsent.current) {
      const isScrolledToBottom =
        Math.abs(
          refConsent.current.scrollHeight -
            refConsent.current.scrollTop -
            refConsent.current.clientHeight
        ) <= 10;
      if (isScrolledToBottom) {
        setConsentRef(true);
      }
    }
  };

  const handleScrollConfirmation = () => {
    if (refConfirmation.current) {
      const isScrolledToBottom =
        Math.abs(
          refConfirmation.current.scrollHeight -
            refConfirmation.current.scrollTop -
            refConfirmation.current.clientHeight
        ) <= 10;
      if (isScrolledToBottom) {
        setConfirmationRef(true);
      }
    }
  };

  return (
    <SPStepLayout
      isAgreement
      hasStepBar={false}
      hasMenu={false}
      hasModalSaveDraft={false}
      footer={{
        left: false,
        right: {
          children: '次へ',
          onClick: () => formik.handleSubmit(),
          disabled: consents.some((consent) => !consent),
        },
      }}
      sx={{ minHeight: supportDvh('calc(100dvh - 114px)') }}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={7}
          sx={{ mt: 7, mb: 6, mx: 4 }}
        >
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
          >
            はじめに
          </Typography>
          {consents.some((consent) => consent === false) && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                p: '12px 18px 12px 18px',
                bgcolor: 'sp_secondary_pink_light',
                border: (theme) =>
                  `1px solid ${theme?.palette?.sp_secondary_01}`,
                borderRadius: '8px',
              }}
            >
              <Icons.SPWarning />
              <Typography variant="drawerText" color="sp_secondary_01">
                同意いただけない場合、本サービスをご利用いただけません。
              </Typography>
            </Stack>
          )}
          <Stack alignItems="center">
            <Typography variant="sp_change_password_note" color="b_333">
              下記2つの同意事項をよくお読みいただき
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              必ずお申込人が選択してください。
            </Typography>
          </Stack>
        </Stack>

        {consents.map((consent, index) => (
          <Stack spacing={4} sx={{ mb: 10 }} key={index}>
            <Stack
              direction="row"
              alignItems="center"
              bgcolor="sp_primary_40"
              spacing={2}
              sx={{ p: '5px 16px 5px 16px' }}
            >
              <Typography
                sx={{ textDecorationColor: 'sp_primary_100_main' }}
                color="b_333"
                variant="sp_label_form_text"
              >
                {!index
                  ? '個人情報の取扱いに関する同意書 兼 表明および確約書'
                  : '銀行代理業にかかる確認書　兼　個人情報の取扱い等に関する同意書'}
              </Typography>
            </Stack>
            <Stack sx={{ px: 4 }}>
              <Stack
                sx={{
                  border: '1px solid #EEF0FF',
                  bgcolor: 'main_white',
                  borderRadius: '4px',
                  height: '272px',
                  gap: '10px',
                  overflow: 'auto',
                }}
                onScroll={
                  !index ? handleScrollConsent : handleScrollConfirmation
                }
                ref={!index ? refConsent : refConfirmation}
              >
                <Consent url={urls[index]} />
              </Stack>
            </Stack>
            <Stack sx={{ pr: 4 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <Icons.SPPdfLite />
                <Link
                  href={urls[index]}
                  target="_blank"
                  component="a"
                  sx={{ textDecorationColor: 'sp_primary_100_main' }}
                  color="sp_primary_100_main"
                  variant="sp_agreement_text"
                  onClick={() =>
                    !index ? setConsentRef(true) : setConfirmationRef(true)
                  }
                >
                  全文を見る
                </Link>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ px: 4 }}
            >
              <Stack spacing={1}>
                <SPButton
                  sx={{
                    ':hover': {
                      boxShadow: 'none',
                    },
                    width: '168px',
                    height: '48px',
                    bgcolor: consent ? 'sp_primary_20' : 'main_white',
                    border: (theme) =>
                      consent
                        ? `1px solid ${theme?.palette?.sp_primary_100_main}`
                        : `1px solid ${theme?.palette?.sp_primary_40}`,
                    boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                    borderRadius: '14px',
                    '&.Mui-disabled': { opacity: 0.5 },
                  }}
                  onClick={() =>
                    setConsents((prevState) => {
                      const cloneState = [...prevState].slice(0);
                      cloneState[index] = true;
                      return cloneState;
                    })
                  }
                  disabled={!index ? !consentRef : !confirmationRef}
                >
                  <Typography
                    variant="SP_multiple_checkbox_label"
                    color="sp_primary_100_main"
                  >
                    同意する
                  </Typography>
                </SPButton>
                {consent === false && (
                  <Typography variant="drawerText" color="sp_secondary_01">
                    ※ご確認ください
                  </Typography>
                )}
              </Stack>
              <SPButton
                sx={{
                  ':hover': { boxShadow: 'none' },
                  width: '168px',
                  height: '48px',
                  bgcolor: consent === false ? 'sp_primary_20' : 'main_white',
                  border: (theme) =>
                    consent === false
                      ? `1px solid ${theme?.palette?.sp_primary_100_main}`
                      : `1px solid ${theme?.palette?.sp_primary_40}`,
                  boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                  borderRadius: '14px',
                  '&.Mui-disabled': { opacity: 0.5 },
                }}
                onClick={() =>
                  setConsents((prevState) => {
                    const cloneState = [...prevState].slice(0);
                    cloneState[index] = false;
                    return cloneState;
                  })
                }
                disabled={!index ? !consentRef : !confirmationRef}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="sp_primary_100_main"
                >
                  同意しない
                </Typography>
              </SPButton>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </SPStepLayout>
  );
};

export default SPAgreementPage;
