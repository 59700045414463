import { Grid, Stack, Typography } from '@mui/material';
import {
  InputField,
  FormItem,
  UlStyle,
  RadioGroupButton,
  InputDateTime,
  InputSelectProps,
  CheckboxMultipleButton,
} from 'components';
import {
  Flat35ApplicablePlanType,
  Flat35ApplicationType,
  MaintenanceType,
  PlanningAreaType,
  PurposeType,
  RebuildingReasonType,
} from 'constant';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StepSynthesisForm } from 'types';
import {
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  zeroPad,
} from 'utils';
import { checkMCJ } from '../selectors';

const StepTwoPageB: FC = () => {
  const isMCJ = useSelector(checkMCJ);
  const { values, setFieldValue } = useFormikContext<StepSynthesisForm>();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const {
    planning_area,
    rebuilding_reason,
    flat_35_applicable_plan,
    maintenance_type,
  } = values.p_application_header;

  useEffect(() => {
    if (
      planning_area !== PlanningAreaType.URBANIZATION_CONTROL_AREA &&
      planning_area !== PlanningAreaType.OUTSIDE_CITY_PLANNING_AREA
    ) {
      setFieldValue(
        'p_application_header.rebuilding_reason',
        RebuildingReasonType.DEFAULT
      );
      setFieldValue('p_application_header.other_rebuilding_reason', '');
    }
    if (planning_area !== PlanningAreaType.OTHERS) {
      setFieldValue('p_application_header.other_planning_area', '');
    }
  }, [planning_area, setFieldValue]);

  useEffect(() => {
    if (
      flat_35_applicable_plan === Flat35ApplicablePlanType.PLAN_A &&
      maintenance_type === MaintenanceType.LONGTERM_SUPERIOR_HOUSING
    ) {
      setFieldValue('p_application_header.flat_35_application', [
        Flat35ApplicationType.DURABILITY,
      ]);
    }
  }, [flat_35_applicable_plan, maintenance_type, setFieldValue]);

  const landOwnershipOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.land_ownership),
    [dynamicOptions.land_ownership]
  );

  const purchasePurposeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.purchase_purpose),
    [dynamicOptions.purchase_purpose]
  );

  const planningAreaOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.planning_area),
    [dynamicOptions.planning_area]
  );

  const rebuildingReasonOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.rebuilding_reason),
    [dynamicOptions.rebuilding_reason]
  );

  const flat35ApplicablePlanOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.flat_35_applicable_plan),
    [dynamicOptions.flat_35_applicable_plan]
  );

  const maintenanceOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.maintenance_type),
    [dynamicOptions.maintenance_type]
  );

  const flat35ApplicationOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.flat_35_application),
    [dynamicOptions.flat_35_application]
  );

  const regionOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.region_type),
    [dynamicOptions.region_type]
  );

  return (
    <Stack>
      <FormItem
        sx={{ mt: 8 }}
        label="物件の所在地"
        divider
        note={
          <UlStyle>
            <li>
              <Typography variant="textstyle" fontSize={16}>
                マンションの場合はマンション名と部屋番号までご記入ください。
              </Typography>
            </li>
          </UlStyle>
        }
      >
        <Stack spacing={6}>
          <InputField
            label="登記簿上の所在地（必ず番地まで正確にご入力ください）"
            name="p_application_header.collateral_address"
            placeholder="都道府県よりご選択ください"
          />
          <InputField
            label="マンション名（物件名）"
            name="p_application_header.mansion_name_kanji"
            placeholder="ー"
          />
        </Stack>
      </FormItem>

      {values.p_application_header.loan_target !== PurposeType.THREE &&
      values.p_application_header.loan_target !== PurposeType.FOUR ? (
        <FormItem
          label="面積（戸建ての場合）"
          note="※小数点第二位まで入力してください。"
          divider
        >
          <Stack spacing={6}>
            <Stack width={{ mobile: '100%', tablet: 240 }}>
              <InputField
                sx={{ width: { mobile: '100%', tablet: 240 } }}
                label="土地"
                name="p_application_header.collateral_land_area"
                align="right"
                placeholder="00.00"
                unit="㎡"
                numeric
                toFixedNumber
                useNumberFormat
              />
            </Stack>
            <Stack width={{ mobile: '100%', tablet: 240 }}>
              <InputField
                sx={{ width: { mobile: '100%', tablet: 240 } }}
                label="建物（延べ床面積）"
                name="p_application_header.occupied_area"
                align="right"
                placeholder="00.00"
                unit="㎡"
                numeric
                toFixedNumber
                useNumberFormat
              />
            </Stack>
          </Stack>
        </FormItem>
      ) : (
        <FormItem
          label="面積（マンションの場合）"
          note="※小数点第二位まで入力してください。"
          divider={isMCJ}
        >
          <Stack spacing={6}>
            <Stack width={{ mobile: '100%', tablet: 240 }}>
              <InputField
                sx={{ width: { mobile: '100%', tablet: 240 } }}
                label="専有面積"
                name="p_application_header.collateral_floor_area"
                align="right"
                placeholder="00.00"
                unit="㎡"
                numeric
                toFixedNumber
                useNumberFormat
              />
            </Stack>
            <Stack width={{ mobile: '100%', tablet: 245 }}>
              <InputField
                sx={{ width: { mobile: '100%', tablet: 240 } }}
                label="マンション全体の延床面積"
                name="p_application_header.collateral_total_floor_area"
                align="right"
                placeholder="00.00"
                unit="㎡"
                numeric
                toFixedNumber
                useNumberFormat
              />
            </Stack>
          </Stack>
        </FormItem>
      )}

      {isMCJ && (
        <>
          <FormItem required divider label="土地権利">
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="p_application_header.land_ownership"
              options={landOwnershipOptions}
            />
          </FormItem>

          <FormItem required divider label="買戻・保留地・仮換地">
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="p_application_header.purchase_purpose"
              options={purchasePurposeOptions}
            />
          </FormItem>

          <FormItem required divider label="都市計画区域等">
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="p_application_header.planning_area"
              options={planningAreaOptions}
            />
          </FormItem>

          {planning_area === PlanningAreaType.OTHERS && (
            <FormItem label="都市計画区域等（その他）" required divider>
              <InputField
                name="p_application_header.other_planning_area"
                placeholder="ー"
              />
            </FormItem>
          )}

          {(planning_area === PlanningAreaType.URBANIZATION_CONTROL_AREA ||
            planning_area === PlanningAreaType.OUTSIDE_CITY_PLANNING_AREA) && (
            <FormItem required divider label="再建築理由">
              <RadioGroupButton
                itemPerRow={3}
                type="noImage"
                name="p_application_header.rebuilding_reason"
                options={rebuildingReasonOptions}
              />
            </FormItem>
          )}

          {rebuilding_reason === RebuildingReasonType.OTHERS && (
            <FormItem label="再建築理由（その他）" required divider>
              <InputField
                name="p_application_header.other_rebuilding_reason"
                placeholder="ー"
              />
            </FormItem>
          )}

          <FormItem divider label="売買契約（予定）日" required>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 4 }}>
                <InputDateTime
                  yearOptions={secondYearOptions}
                  monthOptions={monthOptions}
                  name="p_application_header.purchase_contract_date"
                  checkRequired={true}
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem
            required
            divider
            label="フラット35S（優良住宅取得支援制度）対象項目１"
            help={`ZEH\n2022年10月融資実行分より、金利引き下げの対象となる可能性がございます。該当する場合はご選択ください。`}
          >
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="p_application_header.flat_35_applicable_plan"
              options={flat35ApplicablePlanOptions}
            />
          </FormItem>

          <FormItem required divider label="維持保全型">
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="p_application_header.maintenance_type"
              options={maintenanceOptions}
            />
          </FormItem>

          {!(
            flat_35_applicable_plan === Flat35ApplicablePlanType.PLAN_A &&
            maintenance_type === MaintenanceType.LONGTERM_SUPERIOR_HOUSING
          ) && (
            <FormItem
              required
              divider
              label="フラット35S（優良住宅取得支援制度）対象項目2"
            >
              <CheckboxMultipleButton
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                }}
                itemPerRow={3}
                name="p_application_header.flat_35_application"
                options={flat35ApplicationOptions}
              />
            </FormItem>
          )}

          <FormItem required divider label="地域連携型・地方移住支援型">
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="p_application_header.region_type"
              options={regionOptions}
            />
          </FormItem>
        </>
      )}
    </Stack>
  );
};

export default StepTwoPageB;

const secondYearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(6), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);
