import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPChatBubbleUser: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 12" {...props}>
      <svg
        width="25"
        height="12"
        viewBox="0 0 25 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 0L0 12C0 12 0 12 25 12C17.723 8.80825 14.9434 6.20774 12.5 0Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
