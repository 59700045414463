import { FC, useEffect, useRef } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QrScanner from 'qr-scanner';

export type QrReaderProps = {
  onClose: () => void;
  onResult: (text: string) => void;
};

export const QrReader: FC<QrReaderProps> = ({ onResult, onClose }) => {
  const hasResultRef = useRef(false);
  const qrScannerRef = useRef<QrScanner>();
  const videoStyledRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    if (!videoStyledRef.current) {
      return;
    }
    qrScannerRef.current = new QrScanner(
      videoStyledRef.current,
      (result) => {
        if (result?.data && !hasResultRef.current) {
          hasResultRef.current = true;
          onResult(result.data);
          onClose();
        }
      },
      {
        onDecodeError() {},
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScannerRef.current?.start();
    return () => {
      qrScannerRef.current?.stop();
    };
  }, [onClose, onResult]);

  return (
    <Box sx={{ position: 'relative', bgcolor: 'bg_blue' }}>
      <IconButton
        onClick={onClose}
        sx={{ position: 'fixed', top: 16, right: 16, zIndex: 10001 }}
      >
        <CloseIcon sx={{ color: 'h_blugreen', fontSize: '3.5rem' }} />
      </IconButton>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 10000,
          bgcolor: 'bg_blue',
          '& .scan-region-highlight-svg, & .code-outline-highlight': {
            stroke: (theme) => `${theme.palette?.h_blugreen} !important`,
          },
        }}
      >
        <VideoStyled ref={videoStyledRef} />
      </Box>
    </Box>
  );
};

const VideoStyled = styled('video')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
