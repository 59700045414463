import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPPdfConsent: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.625 12.375C8.86719 12.375 9.875 13.3828 9.875 14.625C9.875 15.8672 8.86719 16.875 7.625 16.875H6.875V18.75C6.875 18.9562 6.70719 19.125 6.5 19.125C6.29281 19.125 6.125 18.9562 6.125 18.75V12.75C6.125 12.5437 6.29281 12.375 6.5 12.375H7.625ZM9.125 14.625C9.125 13.7953 8.45469 13.125 7.625 13.125H6.875V16.125H7.625C8.45469 16.125 9.125 15.4547 9.125 14.625ZM11.375 12.75C11.375 12.5437 11.5437 12.375 11.75 12.375H12.875C13.9109 12.375 14.75 13.2141 14.75 14.25V17.25C14.75 18.2859 13.9109 19.125 12.875 19.125H11.75C11.5437 19.125 11.375 18.9562 11.375 18.75V12.75ZM12.125 13.125V18.375H12.875C13.4984 18.375 14 17.8734 14 17.25V14.25C14 13.6266 13.4984 13.125 12.875 13.125H12.125ZM19.25 12.375C19.4563 12.375 19.625 12.5437 19.625 12.75C19.625 12.9563 19.4563 13.125 19.25 13.125H17.375V15.375H19.25C19.4563 15.375 19.625 15.5437 19.625 15.75C19.625 15.9563 19.4563 16.125 19.25 16.125H17.375V18.75C17.375 18.9562 17.2063 19.125 17 19.125C16.7937 19.125 16.625 18.9562 16.625 18.75V12.75C16.625 12.5437 16.7937 12.375 17 12.375H19.25ZM13.0672 0C13.6625 0 14.2391 0.237047 14.6609 0.659063L20.8391 6.83906C21.2609 7.26094 21.5 7.8375 21.5 8.43281V21C21.5 22.6547 20.1547 24 18.5 24H6.5C4.84297 24 3.5 22.6547 3.5 21V3C3.5 1.34297 4.84297 0 6.5 0H13.0672ZM6.5 23.25H18.5C19.7422 23.25 20.75 22.2422 20.75 21V8.43281C20.75 8.37187 20.7453 8.31094 20.7406 8.25H15.125C14.0891 8.25 13.25 7.41094 13.25 6.375V0.76125C13.1891 0.75375 13.1281 0.75 13.0672 0.75H6.5C5.25734 0.75 4.25 1.75734 4.25 3V21C4.25 22.2422 5.25734 23.25 6.5 23.25ZM14.1266 1.18922C14.0891 1.14844 14.0047 1.11 14 1.07484V6.375C14 6.99844 14.5016 7.5 15.125 7.5H20.4266C20.3891 7.45312 20.3516 7.41094 20.3094 7.37344L14.1266 1.18922Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
