import { createTheme, Theme } from '@mui/material/styles';
import { experimental_sx } from '@mui/material';
import { Link } from 'components';
import { LinkProps } from '@mui/material/Link';
import { futura } from 'assets';

export const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 32,
      lineHeight: '48px',
      letterSpacing: '0.05em',
      color: '#000000',
      overflow: 'hidden',
      '@media (max-width:480px)': {
        fontSize: 24,
        lineHeight: '48px',
      },
    },
    h2: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '34px',
      letterSpacing: 1,
      color: '#000000',
      overflow: 'hidden',
      '@media (max-width:480px)': {
        fontSize: 18,
      },
    },
    h3: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: 0.2,
      color: '#000000',
      overflow: 'hidden',
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
      '@media (max-width:480px)': {
        fontSize: 16,
      },
    },
    h4: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '28px',
      letterSpacing: 0.2,
      color: '#000000',
      '@media (max-width:480px)': {
        fontSize: 12,
        lineHeight: '22px',
      },
    },
    text_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '22px',
      letterSpacing: 0.2,
      color: '#000000',
    },
    text_age: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '36px',
      color: '#000000',
    },
    SP_title_email_screen: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '31px',
    },
    examination_date: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '18px',
      letterSpacing: 0.15,
    },
    text_input_code: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '36px',
      letterSpacing: 0.4,
      color: '#000000',
    },
    text_guide_change_password: {
      fontFamily: 'Hiragino Kaku Gothic Pro',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '19px',
      letterSpacing: -0.333333,
    },
    text_label_modal: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '28px',
      letterSpacing: 0.2,
      color: '#000000',
    },
    text_button_step_required: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '27px',
      letterSpacing: 0.4,
      color: '#000000',
      '@media (max-width:480px)': {
        fontSize: 13,
        lineHeight: '22px',
      },
    },
    liststyle: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '29px',
      letterSpacing: 0.2,
      color: '#000000',
    },
    textstyle: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '29px',
      letterSpacing: 0.1,
      color: '#000000',
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
    },
    total_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 36,
      lineHeight: '24px',
      letterSpacing: 1,
      color: '#000000',
    },
    style_guard: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 0.1,
      color: '#000000',
    },
    style_btn_header: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '24px',
      letterSpacing: 2,
    },
    text_step_descreption: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      letterSpacing: 0.2,
      color: '#000000',
    },
    textstyle_date_time: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: '#000000',
    },
    textstyle_L: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '34px',
      letterSpacing: 0.1,
      color: '#000000',
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
      '@media (max-width:480px)': {
        fontSize: 12,
        lineHeight: '22px',
      },
    },
    text_input_select: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 22,
      lineHeight: '33px',
    },
    text_complete: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '24px',
      letterSpacing: 1,
      color: '#000000',
    },
    textstyle_XL: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '29px',
      letterSpacing: 0.1,
      color: '#000000',
    },
    input_style: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '30px',
      letterSpacing: 0.4,
      color: '#444444',
      '@media (max-width:480px)': {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    placeHolder_style: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '36px',
      letterSpacing: 0.4,
      color: '#000000',
    },
    datetime_content: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '27px',
      color: '#000000',
    },
    button_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.4,
      color: '#000000',
    },
    button_title_review: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '13px',
      color: '#000000',
    },
    checkbox_button_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '30px',
      letterSpacing: 0.4,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
    },
    radio_group_button_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '24px',
    },
    checkbox_button_R: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.4,
    },
    text_steps: {
      fontFamily: 'Hiragino Sans',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: 1,
      textAlign: 'center',
    },
    text_header_memo: {
      fontFamily: 'Hiragino Sans',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: 1,
    },
    text_tabs: {
      fontFamily: 'Hiragino Sans',
      fontSize: 13,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: 1,
    },
    text_step: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 1,
      textAlign: 'center',
    },
    required_chip_label: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 10,
      lineHeight: '24px',
      letterSpacing: '1px',
    },
    text_dashboard: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '15px',
    },
    text_examination_date: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 11,
      lineHeight: '12px',
      letterSpacing: 0.15,
    },
    sp_calcuator_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '12px',
      letterSpacing: 0.6,
    },
    text_button_new_sales: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 17,
      lineHeight: '18px',
      color: '#000000',
    },
    text_button: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '29px',
      letterSpacing: 0.1,
      color: '#000000',
    },
    text_note: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: 0.1,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
    },
    title_card_preview: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '22px',
      letterSpacing: 0.15,
    },
    text_applicant_item_button: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 15,
      lineHeight: '24px',
    },
    text_manager_name: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '12px',
      letterSpacing: '5%',
    },
    text_contact_inquiry: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '15px',
      letterSpacing: 0.2,
    },
    text_contact_inquiry_name_user: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: 0.2,
    },
    text_contact_inquiry_gender: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      line: '30px',
      letterSpacing: 0.4,
    },
    popup_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.2,
    },
    dashboard_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '20px',
    },
    text_placeholder: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '23px',
      letterSpacing: 0.15,
    },
    text_placeholder_QR: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '30px',
      letterSpacing: 0.4,
    },
    input_placeholder_admin: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 17,
      lineHeight: '26px',
      letterSpacing: 0.4,
    },
    name_text: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 0.15,
    },
    quick_text: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 1,
    },
    title_warning: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '34px',
      letterSpacing: 0.4,
      color: '#000000',
    },
    title_sub_button: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: 0.1,
      color: '#000000',
      '@media (max-width:480px)': {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
    title_upload_file: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: 0.6,
    },
    option_upload_file: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 13,
      lineHeight: '18px',
      letterSpacing: 0.15,
      width: 211,
    },
    title_step_number: {
      fontFamily: "'M PLUS 1', sans-serif",
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 36,
      lineHeight: '36px',
      letterSpacing: 5,
      color: '#000000',
    },
    title_avatar_card: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '36px',
      letterSpacing: 5,
      color: '#000000',
    },
    text_avatar_card: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: 0.2,
      color: '#000000',
    },
    text_step_number: {
      fontFamily: 'Futura',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '36px',
      color: '#000000',
    },
    text_accordion_status: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '22px',
      letterSpacing: 0.2,
      color: '#000000',
      '@media (max-width:480px)': {
        fontSize: 20,
        lineHeight: '20px',
      },
    },
    text_note_landing: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '34px',
      letterSpacing: 0.2,
      color: '#444444',
    },
    text_accordion_descreption: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '22px',
      letterSpacing: 0.2,
      color: '#000000',
    },
    text_avatar: {
      fontFamily: "'M PLUS 1', sans-serif",
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '36px',
      color: '#000000',
    },
    text_step_name: {
      fontFamily: 'Futura',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
      color: '#000000',
    },
    text_modal: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 20,
      lineHeight: '29px',
      letterSpacing: 0.1,
      color: '#444444',
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
      '@media (max-width:480px)': {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    text_note_modal: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: 0.1,
      color: '#000000',
      '@media (max-width:480px)': {
        fontSize: 13,
        lineHeight: '23px',
      },
    },
    prefecture_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 20,
      lineHeight: '60px',
      letterSpacing: 1,
      color: '#444444',
    },
    number_total: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 36,
      lineHeight: '24px',
      letterSpacing: 1,
      color: '#000000',
      '@media (max-width:480px)': {
        fontSize: 26,
        lineHeight: '24px',
      },
    },
    text_total: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '36px',
      letterSpacing: 1,
      color: '#000000',
    },
    register_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '15px',
    },
    title_header: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '32px',
    },
    text_applican_item_content: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 11,
      lineHeight: '12px',
    },
    text_applican_item_label: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '12px',
    },
    text_upload_file: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '29px',
    },
    modalSaveDataText: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '22px',
      '@media (max-width:480px)': {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
    drawerText: {
      fontFamily: 'Hiragino Sans',
      color: '#444444',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15.6px',
      letterSpacing: 0.6,
    },
    drawerButtonText: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 0.1,
    },
    buttonAddImage: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '14px',
      letterSpacing: 0.6,
    },
    upload_image_text: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.6,
    },
    sp_agreement_text: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '21px',
      letterSpacing: 0.6,
    },
    sp_contentButton_text: {
      fontFamily: 'Barlow',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '130%',
      letterSpacing: 0.6,
    },
    sp_step_text: {
      fontFamily: 'Barlow',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '130%',
      letterSpacing: 0.6,
    },
    sp_label_text: {
      fontFamily: 'Barlow',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '23.4px',
      letterSpacing: 0.6,
    },
    buttonDrawer: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '20px',
      letterSpacing: 0,
    },
    titleNumPad: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '24px',
      letterSpacing: 0,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
    },
    titleQR: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '24px',
      color: '#000000',
    },
    modalTitle: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 30,
      lineHeight: '33.6px',
      letterSpacing: 1,
      '@media (max-width:480px)': {
        fontSize: 18,
        lineHeight: '23px',
      },
    },
    workplaceName: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '28px',
      letterSpacing: 0.2,
    },
    leafletOption: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 30,
      lineHeight: '45px',
      letterSpacing: 0.4,
      '@media (max-width:480px)': {
        fontSize: 20,
        lineHeight: '23px',
      },
    },
    text_applicant_item: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '29px',
    },
    text_status_item: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 9,
      lineHeight: '24px',
      letterSpacing: 0.5,
    },
    text_status_sale_person_item: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 15,
      lineHeight: '24px',
      letterSpacing: 0.5,
    },
    text_preliminarie_item: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 17,
      lineHeight: '21.45px',
      letterSpacing: 1,
    },
    number_applicant_item: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '23px',
      letterSpacing: 1,
    },
    date_applicant_item: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '23px',
      letterSpacing: 0.15,
    },

    textTitleTopPage: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 22,
      lineHeight: '33px',
    },
    textButtonTopPage: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '28px',
      '@media (max-width:480px)': {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
    reason_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 17,
      fontWeight: 500,
      lineHeight: '29px',
      letterSpacing: '2px',
      textAlign: 'center',
    },
    position_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '12px',
    },
    title_application_item: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20.02px',
    },
    title_show_log: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '5%',
    },
    priliminary_text: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '18.59px',
    },
    priliminary_number: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '18.59px',
    },
    document_upload_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 12,
      lineHeight: '16.8px',
    },
    title_text_contact_detail: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '14.3px',
      letterSpacing: 0.15,
    },
    text_content_contact_detail: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '17,16px',
      letterSpacing: 0.15,
    },
    text_step_admin: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '24px',
      letterSpacing: 1,
      textAlign: 'center',
    },
    text_sale_person_bank_name: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 400,
      fontSize: 9,
      letterSpacing: 1,
      lineHeight: '24px',
    },
    table_small_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 8,
      fontWeight: 300,
    },
    table_header_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '16.8px',
    },
    popover_footer_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 0.1,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
      color: '#1925A2',
    },
    auth_popover_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 0.1,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
      color: '#1925A2',
    },
    upload_image_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.6,
    },
    landing_submit_button_title: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '30px',
    },
    text_title_admin_businesses: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 300,
      fontSize: 12,
      lineHeight: '16.8px',
    },
    note_page_button_title: {
      fontFamily: 'Hiragino Sans',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    top_page_avatar_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '42px',
    },
    text_content_admin_businesses: {
      fontFamily: 'Barlow',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22.88px',
      letterSpacing: 1,
    },
    text_memo_list: {
      fontFamily: 'Barlow',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 14,
      letterSpacing: 1,
    },
    unit_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '27px',
    },
    sp_unit_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '21.6px',
      letterSpacing: 0.6,
    },
    sp_note_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '18px',
      letterSpacing: 0.6,
    },
    sp_unit_select_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 16,
      fontWeight: 300,
      lineHeight: '16px',
      letterSpacing: 0.6,
    },
    password_rule: {
      fontFamily: 'Hiragino Kaku Gothic Pro',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '19px',
      letterSpacing: -0.333333,
    },
    pdf_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '18px',
    },
    pdf_description: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '21px',
    },
    password_label: {
      fontFamily: 'Hiragino Kaku Gothic Pro',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '12px',
      letterSpacing: -0.33,
    },
    status_note: {
      fontFamily: 'Hiragino Sans',
      fontSize: 8,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 1,
    },
    SP_multiple_checkbox_label: {
      fontFamily: 'Hiragino Sans',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: 0.6,
    },
    SP_label_field: {
      fontFamily: 'Hiragino Sans',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '14px',
      letterSpacing: 0.6,
    },
    SP_title_modal: {
      fontFamily: 'Hiragino Sans',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '26px',
      letterSpacing: 0.6,
    },
    SP_form_item_label: {
      fontFamily: 'Hiragino Sans',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '20.8px',
      letterSpacing: 0.6,
    },
    SP_form_item_note: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '18px',
      letterSpacing: 0.6,
    },
    SP_password_note: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '12px',
      letterSpacing: 0.6,
    },
    SP_form_item_help: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '12px',
      letterSpacing: 0.6,
    },
    SP_required_chip_label: {
      fontFamily: 'Hiragino Sans',
      fontSize: 10,
      fontWeight: 500,
      lineHeight: '10px',
      letterSpacing: 0.6,
    },
    SP_input_error_text: {
      fontFamily: 'Hiragino Sans',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: 0.6,
    },
    text_error: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      letterSpacing: 0.6,
    },
    sp_step: {
      fontFamily: 'Barlow',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '100%',
      letterSpacing: 0.6,
    },
    sp_name_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18.2px',
      letterSpacing: 0.6,
    },
    sp_value_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '16px',
      letterSpacing: 0.6,
    },
    sp_modal_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 20,
      lineHeight: '130%',
      letterSpacing: 0.6,
    },
    sp_save_draft_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '16px',
      letterSpacing: 0.6,
    },
    sp_primary_button: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '16px',
      letterSpacing: 0.6,
      alignSelf: 'center',
    },
    sp_label_form_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20.8px',
      letterSpacing: 0.6,
    },
    sp_change_password_title: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '31.2px',
      letterSpacing: 0.6,
    },
    sp_change_password_note: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 300,
      fontSize: 14,
      lineHeight: '23.8px',
      letterSpacing: 0.6,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
    },
    sp_footer_bank_name: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 300,
      fontSize: 12,
      lineHeight: '25px',
    },
    headline_input: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '21px',
    },
    heading01: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '27px',
      letterSpacing: 0.05,
    },
    button01: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0.05,
    },
    sp_error_message: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15.6px',
      letterSpacing: 0.6,
    },
    sp_modal_note: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '27.2px',
      letterSpacing: 0.6,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-all',
    },
    sp_12_bold_100: {
      fontFamily: 'Noto Sans JP',
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '12px',
      letterSpacing: 0.6,
    },
    annotation01: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 300,
      fontSize: 12,
      lineHeight: '18px',
      textAlign: 'center',
    },
    annotation02: {
      fontFamily: 'Hiragino Sans',
      fontWeight: 250,
      fontSize: 14,
      lineHeight: '21px',
      textAlign: 'center',
    },
    input_modal_filter: {
      fontFamily: 'Noto Sans JP',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '34px',
      p: '0px 15px',
      boxShadow: 'none',
      height: '44px',
      color: '#333333',
    },
    text_priliminary_pulldown: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 13,
      lineHeight: '19.5px',
      color: '#333333',
    },
    sp_step_06_form_title: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.6px',
    },
    sp_step_09_form_title: {
      fontFamily: 'Noto Sans JP',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '21.6px',
      letterSpacing: '0.6px',
      textAlign: 'right',
    },
    term_service_text: {
      fontFamily: 'Hiragino Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 12,
    },
  },
  palette: {
    normal_text: '#444444',
    blue_text: '#0C75C2',
    line: '#C8CDCF',
    off_text: '#BBBBBB',
    line_off: '#E8EAEB',
    bg_error: '#FFEAEA',
    bg_off: '#F8F8F8',
    Icon_color: '#7B809A',
    attention: '#DB2828',
    main_white: '#FFFFFF',
    bg_pale_gray: '#F8F8F8',
    sh_red: '#D66050',
    h_blugreen: '#6671E1',
    sh_navy: '#253E5F',
    pale_blue: '#ECEDF8',
    pale_gray: '#C4C4C4',
    pale_grey: '#D9D9D9',
    required_chip: '#F24451',
    bg_radio_avatar: '#BDBDBD',
    register_text: '#FF0404',
    title_header: '#454545',
    bg_title: '#F7F7F7',
    m_blugreen: '#3896A7',
    l_blugreen: '#EAF7FA',
    black: '#000000',
    gray: '#CCCCCC',
    footer_bg: '#EEEEEE',
    blue03: '#3C48C4',
    blue08: '#4E517A',
    bg_blue: '#979CCF',
    line_gray: '#AAAAAA',
    bg_gray: '#EFEFEF',
    blue07: '#EBEAFA',
    line_green: '#00B900',
    red_005: '#F9E8E8',
    green_003: '#26AB2B',
    green_005: '#DFF7E0',
    green_gray: '#B5D4B6',
    red_gray: '#DBBABA',
    footer_popup_bg: '#C6CAF0',
    border_table: '#C3C8CA',
    scroll_gray: '#C9C9C9',
    bg_disabled: '#E0E0E0',
    b_333: '#333333',
    miruho_normal_text: '#0B1D31',
    miruho_border: '#A7A7A7',
    miruho_bg: '#F5F6F7',
    miruho_placeholder: '#9FA8B2',
    close_icon: '#666666',
    clear_button_blue: '#359EFF',
    contact_title_text: '#949494',
    a5: '#A5A5A5',
    link_text: '#359EFF',
    phone_number: '#2871FF',
    text_normal: '#222222',
    blue_step: '#D9E2F3',
    red_step: '#FBE4D5',
    yellow_step: '#FEF2CB',
    green_step: '#E2EFD9',
    border_gray: '#E7E7E7',
    sp_bg_gray: '#FAFAFA',
    sp_primary_100_main: '#6B70F0',
    sp_primary_10: '#F9FCFF',
    sp_primary_20: '#EEF0FF',
    sp_primary_40: '#E4E7FF',
    sp_primary_60: '#A9AFEA',
    sp_secondary_01: '#E54E75',
    sp_secondary_02: '#F27C9B',
    sp_bg_yellow: '#FFF9C5',
    sp_gray_250: '#9F9F9F',
    sp_gray_100: '#F2F2F2',
    sp_gray_2: '#4F4F4F',
    sp_icon_01: '#6A75DE',
    sp_icon_02: '#AD92D0',
    sp_blue_50: '#E7EDFF',
    sp_logo_icon: '#0B1F65',
    sp_menu_bg_01: '#F9F1F7',
    sp_menu_bg_02: '#B8D3F3',
    sp_secondary_pink_light: '#FFF4F7',
    blue_08: '#3A47A8',
    gray_09: '#DBD9D9',
    green_arrow: '#D5F5D5',
    orange_arrow: '#FFC9A9',
    blue_arrow: '#C5D5FF',
    current_blue: '#EEF0F5',
    blue_bg_e8eefe: '#E8EEFE',
    orange_bg_ffece0: '#FFECE0',
    arrow_icon: '#747A84',
    linking_loan: '#DEFFE5',
    green_spagebar_88C095: '#88C095',
    green_bg_6b70f01a: '#6B70F01A',
    tooltip_hover: '#DEE1FF',
    pink_text: '#E54E75',
    light_blue: '#9497de',
    new_bg_grey: '#EAEAEA',
    arrow_blue_lite: '#C5D5FF',
    arrow_green_lite: '#ACEAB1',
    arrow_orange_lite: '#DF8550',
  },
  spacing: Array.from({ length: 60 }, (_, i) => i * 4),
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 480,
      md: 940,
      desktop: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [
          {
            fontFamily: futura,
            fontWeight: 500,
            fontSize: 24,
            lineHeight: '36px',
            letterSpacing: 5,
            src: `url(${futura}) format('ttf')`,
          },
        ],
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 0,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: experimental_sx<Theme>({
          '& .MuiBackdrop-root': {
            backgroundColor: 'bg_blue',
            opacity: '0.6 !important',
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          inputProps: { sx: { px: 3, py: 4 } },
        },
      },
      styleOverrides: {
        root: experimental_sx<Theme>({
          '.MuiInputBase-input': (theme) => ({
            ...theme.typography.input_style,
            p: '8px 16px',
            fontSize: 24,
          }),
          '.MuiFormHelperText-root': {
            display: 'none',
          },
          '&&&& .Mui-disabled': {
            fieldset: {
              borderWidth: 3,
              borderColor: 'line_off',
            },
          },
          '&&&& .Mui-error': {
            '.MuiInputBase-input': {
              backgroundColor: 'bg_error',
            },
            fieldset: {
              borderWidth: 3,
              borderColor: 'sh_red',
            },
          },
          '&&&& .Mui-success': {
            fieldset: {
              borderWidth: 3,
              borderColor: 'h_blugreen',
            },
          },
          '&&&& .Mui-focused': {
            fieldset: {
              borderWidth: 3,
              borderColor: 'sh_navy',
            },
          },
          '&&& fieldset': {
            borderWidth: 3,
            borderColor: 'line',
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        // @ts-ignore
        root: experimental_sx<Theme>({
          '& .MuiSelect-select': {
            textOverflow: 'clip',
          },
          '& + .Mui-error': {
            display: 'none',
          },
          '&&& fieldset, &&& fieldset:hover': {
            borderWidth: 3,
            borderColor: 'line',
          },
          '&&& .Mui-disabled': {
            borderColor: 'line_off',
            fieldset: {
              borderWidth: 3,
              borderColor: 'line_off',
            },
          },
          '&&&.Mui-error': {
            backgroundColor: 'bg_error',
          },
          '&&&.Mui-error fieldset': {
            borderWidth: 3,
            borderColor: 'sh_red',
          },
          '&&&.Mui-success fieldset': {
            borderWidth: 3,
            borderColor: 'h_blugreen',
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        sx: { borderRadius: 1, minHeight: 64 },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: experimental_sx<Theme>({
            color: 'normal_text',
            borderWidth: 3,
            borderStyle: 'solid',
            borderColor: 'normal_text',
            '&:hover': {
              borderWidth: 3,
            },
          }),
        },
        {
          props: { variant: 'contained' },
          style: experimental_sx<Theme>({
            color: 'main_white',
            bgcolor: 'sh_navy',
          }),
        },
        {
          props: { variant: 'back' },
          style: experimental_sx<Theme>({
            color: 'black',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'gray',
            pl: 10,
            pr: 6,
            py: 2,
            height: 44,
            bgcolor: 'transparent',
            width: 'fit-content',
          }),
        },
      ],
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          boxShadow: 'none',
          backgroundColor: 'inherit',
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: experimental_sx<Theme>({
          '.MuiTabScrollButton-root': {
            display: 'none',
          },
          fontFamily: 'Noto Sans',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 20,
          lineHeight: '27px',
          color: 'normal_text',
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: Link,
      } as LinkProps,
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: experimental_sx<Theme>({
          '&&& fieldset, &&& fieldset:hover': {
            borderWidth: 3,
            borderColor: 'line',
          },
          '&&&.Mui-success fieldset': {
            borderWidth: 3,
            borderColor: 'h_blugreen',
          },
          '.MuiAutocomplete-endAdornment': {
            top: 'auto',
          },
        }),
      },
    },
    MuiDrawer: {
      defaultProps: { transitionDuration: 200, SlideProps: { timeout: 200 } },
    },
  },
});

theme.typography.checkbox_button_title = {
  ...theme.typography.checkbox_button_title,
  [theme.breakpoints.down('tablet')]: {
    fontSize: 16,
  },
};

theme.typography.leafletOption = {
  ...theme.typography.leafletOption,
  [theme.breakpoints.down('tablet')]: {
    fontSize: 24,
  },
};

theme.typography.radio_group_button_title = {
  ...theme.typography.checkbox_button_title,
  [theme.breakpoints.down('tablet')]: {
    fontSize: 16,
  },
};
