import {
  AdminPreliminaryItem,
  INCOME_TOTALIZER,
  RelationshipToApplicantType,
} from 'constant';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { dayjs } from 'libs/dayjs';

export const buildIncomeTotalizer = (
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  return INCOME_TOTALIZER.map((row) => ({
    ...row,
    name_en: row.name_en.replace('[0]', '[1]'),
  }))
    .filter((item) => {
      if (
        item.name_en ===
        'p_applicant_people_attributes[1].other_relationship_to_applicant'
      ) {
        return (
          values?.p_applicant_people_attributes[1]
            ?.relationship_to_applicant === RelationshipToApplicantType.OTHERS
        );
      }
      return true;
    })
    .map((row) => {
      const { loan_desired_borrowing_date } =
        values.p_borrowing_details_attributes[0];
      if (row.name_en === `p_applicant_people_attributes[1].birthday`) {
        return {
          ...row,
          options: {
            ...row.options,
            minDate: dayjs(loan_desired_borrowing_date || undefined)
              .subtract(65, 'y')
              .toString(),
            maxDate: dayjs(loan_desired_borrowing_date || undefined)
              .subtract(18, 'y')
              .toString(),
          },
        };
      }
      return { ...row };
    });
};
