import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SPStepCollateralProviderForm } from 'types';
import { logout } from 'containers/AuthModal/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { SexType } from 'constant';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { deleteUser } from '../SPUnsubcribed/thunk';
import { spGetSubmittedPreliminary } from '../SPLogin/thunk';

export type InitialState = {
  form: SPStepCollateralProviderForm;
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      p_join_guarantors: [
        {
          last_name_kanji: '',
          first_name_kanji: '',
          last_name_kana: '',
          first_name_kana: '',
          sex: SexType.DEFAULT,
          guarantor_relationship_name: '',
          birthday: '',
          mobile_phone_number: '',
          home_phone_number: '',
          postal_code: '',
          prefecture_kanji: '',
          city_kanji: '',
          district_kanji: '',
          prefecture_kana: '',
          city_kana: '',
          district_kana: '',
          other_address_kanji: '',
          other_address_kana: '',
        },
      ],
    },
  },
};

const spStepCollateralProviderSlice = createSlice({
  name: 'spStepCollateralProvider',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<SPStepCollateralProviderForm>) => {
      state.form = {
        ...action.payload,
      };
    },
    resetStepFourCollateralProviderForm: (state) => {
      const filterGuarantors =
        state.form.p_application_header.p_join_guarantors.filter(
          (guarantor) => !!guarantor.id
        );
      state.form.p_application_header.p_join_guarantors = filterGuarantors.map(
        (guarantor) => {
          const { _destroy, ...restGuarantor } = guarantor;
          return {
            ...restGuarantor,
            _destroy: true,
          };
        }
      );
    },
    addInitialCollateralProviderState: (state) => {
      const filterGuarantors =
        state.form.p_application_header.p_join_guarantors.filter(
          (guarantor) => !guarantor._destroy
        );

      state.form.p_application_header.p_join_guarantors =
        !!filterGuarantors.length
          ? state.form.p_application_header.p_join_guarantors
          : [
              ...state.form.p_application_header.p_join_guarantors,
              initialState.form.p_application_header.p_join_guarantors[0],
            ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_join_guarantors } = action.payload.data;
      if (!!p_join_guarantors.length) {
        state.form.p_application_header.p_join_guarantors = p_join_guarantors;
      }
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const { p_join_guarantors_attributes, p_applicant_people_attributes } =
        action.payload.data;

      const hasJoinGuarantor = p_applicant_people_attributes.find(
        (data) => data.has_join_guarantor === '1'
      );
      if (!!p_join_guarantors_attributes.length) {
        state.form.p_application_header.p_join_guarantors =
          p_join_guarantors_attributes;
      } else if (!p_join_guarantors_attributes.length && hasJoinGuarantor) {
        state.form = initialState.form;
      } else {
        state.form.p_application_header.p_join_guarantors = [];
      }
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const { p_join_guarantors_attributes, p_applicant_people_attributes } =
        action.payload.data;

      const hasJoinGuarantor = p_applicant_people_attributes.find(
        (data) => data.has_join_guarantor === '1'
      );
      if (!!p_join_guarantors_attributes.length) {
        state.form.p_application_header.p_join_guarantors =
          p_join_guarantors_attributes;
      } else if (!p_join_guarantors_attributes.length && hasJoinGuarantor) {
        state.form = initialState.form;
      } else {
        state.form.p_application_header.p_join_guarantors = [];
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const {
  saveForm,
  resetStepFourCollateralProviderForm,
  addInitialCollateralProviderState,
} = spStepCollateralProviderSlice.actions;
export default spStepCollateralProviderSlice.reducer;
