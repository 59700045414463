import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Plus: FC<SvgIconProps> = (props) => (
  <SvgIcon
    sx={{ width: 13, height: 13 }}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="7.9707" x2="16" y2="7.9707" stroke="#333333" />
      <line
        x1="8.03125"
        y1="2.18557e-08"
        x2="8.03125"
        y2="16"
        stroke="#333333"
      />
    </svg>
  </SvgIcon>
);
