import { useMatchedRoute } from 'hooks';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import {
  isFirstTimeLoginSelector,
  isLoggedInSelector,
  managerIdSelector,
  salePersonIdSelector,
} from 'containers/AuthModal/selectors';
import {
  managerRoutes,
  publicRoutes,
  routeNames,
  salepersonRoutes,
  stepRoutes,
} from './routes';

type StepNavigationProps = {
  role: 'user' | 'manager' | 'saleperson' | 'none';
};

export const StepsNavigation: FC<StepNavigationProps> = ({ role }) => {
  const currentRoute = useMatchedRoute();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const managerId = useSelector(managerIdSelector);
  const salePersonId = useSelector(salePersonIdSelector);
  const firstTimeLogin = useSelector(isFirstTimeLoginSelector);
  if (!currentRoute) return null;

  if (!isLoggedIn && role === 'user') {
    return <Navigate to={routeNames.StartPage.path} replace />;
  }

  if (!isLoggedIn && role === 'manager') {
    const query = new URLSearchParams(window.location.search);
    if (query.get('preliminary_id')) {
      sessionStorage.setItem(
        'preliminary_id',
        query.get('preliminary_id') ?? ''
      );
    }
    return <Navigate to={routeNames.LoginManager.path} replace />;
  }

  if (!isLoggedIn && role === 'saleperson') {
    return <Navigate to={routeNames.LoginSalePerson.path} replace />;
  }

  if (!isLoggedIn && role === 'none') {
    if (!publicRoutes.find(({ path }) => path === currentRoute.path)) {
      return <Navigate to={routeNames.StartPage.path} replace />;
    }
  }

  if (isLoggedIn && !managerId && !salePersonId) {
    if (
      currentRoute.path !== routeNames.Login.path &&
      currentRoute.path !== routeNames.SPScreenEmail.path &&
      !stepRoutes.find(({ path }) => path === currentRoute.path)
    ) {
      return <Navigate to={routeNames.Top.path} replace />;
    }
  }
  if (isLoggedIn && !!managerId) {
    if (!managerRoutes.find(({ path }) => path === currentRoute.path)) {
      return <Navigate to={routeNames.ManagerPreliminaries.path} replace />;
    }
  }
  if (isLoggedIn && !!salePersonId) {
    if (
      !salepersonRoutes.find(({ path }) => path === currentRoute.path) &&
      !window.location.href.includes('/sale-person/login?code=')
    ) {
      return <Navigate to={routeNames.SalePersonPreliminaries.path} replace />;
    }
  }

  return <Outlet />;
};
