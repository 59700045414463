import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPAboutPairLoanModal: FC<ModalProps> = ({ open, onClose }) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="ペアローンとは？"
      disableBackDropClick
      sxPadding="2px"
      footer={
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: 359 }}
        >
          <Stack
            sx={{ overflowY: 'scroll', maxHeight: '37vh', px: 6 }}
            spacing={2}
          >
            <Typography variant="sp_change_password_note" color="b_333">
              {`ペアローンとは、一定の収入のある、原則として同居している親族のかたと一緒に、それぞれが主たる債務者として住宅ローンを組むことです。（ご夫婦や親子で一緒に住宅ローンをお申込みできます）。\nお互いが相手の債務に対する 担保提供者となります。\n物件の持分がある場合、それぞれが住宅ローン控除（住宅借入金等特別控除）の対象となります。尚、フラット３５はペアローンのお取扱いはございません。`}
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              {`●具体例：夫婦で3000万円のお借入れのお申込みをする場合\n・お借入額：夫1500万円\n・妻1500万円\n・持分：夫1/2\n・妻1/2\n・団体信用生命保険：夫も妻も加入\n・住宅ローン控除：夫も妻も対象`}
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              {`●ご注意点\n・「借入金額の割合」と「物件の持分割合」が等しくない場合、贈与税が課税される可能性があります\n・住宅ローンの借入金額は、1契約あたり500万円以上ですが、ペアローンをご利用になる場合は1,000万円以上となります。（契約者それぞれに対して500万円以上となるため）\n・婚約者とのペアローンは、原則借入実行前に入籍が必要です。\n・お二人が別々に仮審査をお申込みいただきます。「お借入希望額」の項目には、それぞれのお借入希望額をご入力ください（お二人の借入希望額の合計ではありません）。`}
            </Typography>
          </Stack>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};
