import { SalePersonPreliminaries, DynamicOption } from 'services';
import { buildResidentsAttributes } from '../../AdminEditPreliminary/utils';

type ResidentsAttributes =
  SalePersonPreliminaries['p_application_header']['p_residents_attributes'];

export function buildResidentsAttributesSalePerson(
  applicationHeader: SalePersonPreliminaries['p_application_header'],
  plannedCohabitantDynamic: DynamicOption
): ResidentsAttributes {
  return buildResidentsAttributes(applicationHeader, plannedCohabitantDynamic);
}
