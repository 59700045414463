import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPAirPlane: FC<SvgIconProps> = (sx, props) => {
  const id = useMemo<string>(
    () => Math.round(Math.random() * 10000000).toString(),
    []
  );
  return (
    <SvgIcon {...sx} viewBox="0 0 36 37" {...props}>
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.3848 34.5739L20.3551 31.227L17.7044 35.8606C17.1981 36.7465 16.1575 37.1825 15.1731 36.9223C14.1887 36.6622 13.4997 35.7692 13.4997 34.7497V28.3723L1.38482 23.3239C0.590292 22.9934 0.0559166 22.2481 0.00669786 21.3903C-0.0425209 20.5325 0.393417 19.7239 1.13873 19.295L1.6942 20.2723C1.32154 20.4833 1.10357 20.8911 1.1317 21.32C1.15982 21.7489 1.42701 22.1215 1.82076 22.2903L13.6965 27.2403L33.7215 2.12466C33.5387 2.13169 33.3559 2.18091 33.1872 2.27232L1.68717 20.2723L1.1317 19.295L32.6317 1.29497C33.2153 0.964506 33.8973 0.908256 34.509 1.13326C34.6848 1.19654 34.8606 1.28794 35.0223 1.39341C35.1137 1.45669 35.1981 1.51997 35.2754 1.59029C35.3387 1.64654 35.395 1.70279 35.4442 1.76607C35.8731 2.25826 36.077 2.92622 35.9715 3.58716L31.4715 32.8372C31.3661 33.5192 30.9512 34.1168 30.3465 34.4543C29.7419 34.7919 29.0176 34.834 28.3778 34.5668L28.3848 34.5739ZM14.7723 27.6833L20.784 30.1864C20.784 30.1864 20.784 30.1864 20.7911 30.1864L28.8208 33.5333C29.1372 33.6669 29.5028 33.6458 29.8051 33.477C30.1075 33.3083 30.3114 33.0059 30.3676 32.6684L34.8676 3.41841C34.9098 3.15826 34.8536 2.8981 34.727 2.68013L14.7723 27.6833ZM14.6247 28.8434V34.7497C14.6247 35.2629 14.9692 35.7059 15.4614 35.8395C15.9536 35.9731 16.4739 35.7551 16.727 35.3122L19.3075 30.7981L14.6247 28.8434Z"
          fill={`url(#${id})`}
        />
        <defs>
          <linearGradient
            id={id}
            x1="5.54604"
            y1="29.5933"
            x2="33.0535"
            y2="1.81064"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
