import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useField } from 'formik';
import {
  MenuItem,
  Stack,
  SelectProps,
  Typography,
  Select,
  SelectChangeEvent,
  SxProps,
} from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { debounce } from 'lodash';
import { KeyboardArrowDown } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

export type SPInputSelectProps = Omit<SelectProps, 'onClose'> & {
  name: string;
  readOnly?: boolean;
  options: { value: string | number; label: string; className?: string }[];
  disableOptions?: {
    value: string | number;
    label: string;
    className?: string;
  }[];
  unit?: string;
  unitSx?: SxProps;
  errorMessage?: string;
  showError?: boolean;
  useFastField?: boolean;
  checkRequired?: boolean;
  iconSx?: SxProps;
  onClose?: (val: string) => void;
};

export const SPInputSelectTemp: FC<SPInputSelectProps> = ({
  label,
  options,
  disableOptions,
  placeholder,
  readOnly,
  unitSx,
  unit,
  onChange,
  onBlur,
  onFocus,
  errorMessage,
  showError = true,
  checkRequired,
  iconSx,
  onClose,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const isSuccess = useMemo(
    () => !meta.error && !props.error && meta.value,
    [meta.error, meta.value, props.error]
  );
  const [open, setOpen] = useState(false);
  const currentValueRef = useRef<string>('');

  const renderIconComponent = useCallback(
    () => (
      <KeyboardArrowDown
        sx={{
          width: 24,
          height: 24,
          position: 'absolute',
          right: 5,
          top: 'calc(50% - 12px)',
          pointerEvents: 'none',
          color: 'sp_primary_100_main',
          ...iconSx,
        }}
      />
    ),
    [iconSx]
  );

  const renderValue = useMemo(
    () =>
      !!field.value
        ? undefined
        : () => (
            <Typography variant="placeHolder_style" color="off_text">
              {placeholder}
            </Typography>
          ),
    [field.value, placeholder]
  );

  const handleChange: SelectInputProps['onChange'] = useCallback(
    async (event: SelectChangeEvent<unknown>, child: ReactNode) => {
      currentValueRef.current = event.target.value as string;
      await field.onChange(event);
      onChange && onChange(event, child);
    },
    [field, onChange]
  );

  const debouncedFunction = useRef(
    debounce((error, value) => {
      // @ts-ignore
      if (!!error && !!value) {
        return setOpen(!!error && !!value);
      }
      return setOpen(!!error);
    }, 550)
  );

  const debounceError = useCallback(
    // @ts-ignore
    (error, value) => {
      return debouncedFunction.current(error, value);
    },
    []
  );

  useEffect(() => {
    if (!!meta.error && meta.touched) {
      debounceError(meta.error, meta.value);
    } else {
      debounceError(props.error, meta.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error, meta.error, meta.value, meta.touched, checkRequired]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debouncedFunction.current?.cancel();
    };
  }, []);

  return (
    <Stack flex={1} spacing={1}>
      {!!label && (
        <Typography variant="buttonAddImage" color="b_333">
          {label}
        </Typography>
      )}
      <Stack flex={1} spacing={1}>
        <Stack flex={1} direction="row" alignItems="center" spacing={1}>
          <Select
            {...props}
            {...field}
            onChange={handleChange}
            onBlur={(event) => {
              field.onBlur(event);
              onBlur && onBlur(event);
            }}
            onClose={() => {
              onClose && onClose(currentValueRef.current);
            }}
            onFocus={onFocus}
            sx={{
              '@media (orientation: landscape)': {
                width: isMobile ? '100%' : 'auto',
              },
              width: 480,
              minHeight: '48px',
              height: '48px',
              '&&&& fieldset': {
                border: '1px solid',
                boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
                borderColor:
                  props.error || (!!meta.error && meta.touched)
                    ? 'sp_secondary_01'
                    : 'sp_primary_40',
              },
              '& .MuiInputBase-input': {
                p: 3,
                '&:focus': {
                  backgroundColor: 'sp_bg_yellow',
                  boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                },
              },
              filter: readOnly
                ? 'none'
                : 'drop-shadow(0px 2px 8px rgba(59, 118, 129, 0.15))',
              '&&& .Mui-disabled': (theme) => ({
                WebkitTextFillColor: !!field.value
                  ? theme.palette.normal_text
                  : undefined,
              }),
              '& .MuiSelect-select': {
                pt: '13px',
              },
              '&&&&& .MuiTypography-input_style': {
                fontFamily: 'Hiragino Sans',
                fontWeight: 300,
                fontSize: 16,
                lineHeight: '16px',
                letterSpacing: 0.6,
              },
              '& .MuiTypography-placeHolder_style': {
                color: 'bg_radio_avatar',
                fontFamily: 'Hiragino Sans',
                fontSize: 16,
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: 0.6,
              },
              '&&&.Mui-success': {
                '.MuiInputBase-input': {
                  backgroundColor: 'sp_gray_100',
                  boxShadow: 'none',
                },
                fieldset: {
                  border: 'none',
                  boxShadow: 'none',
                },
                filter: 'none',
              },
              '&&&.Mui-focused': {
                '.MuiInputBase-input': {
                  backgroundColor: readOnly ? 'sp_primary_60' : 'sp_bg_yellow',
                },
                '&&&& fieldset': {
                  borderWidth: readOnly ? 0 : 1,
                  borderColor: 'sp_primary_100_main',
                },
              },
              '&&&.Mui-focused.Mui-success': {
                '&&&& fieldset': {
                  border: '1px solid',
                  borderColor: 'sp_primary_100_main',
                  boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                },
              },
              ...props.sx,
            }}
            className={isSuccess && !readOnly ? 'Mui-success' : ''}
            displayEmpty
            error={
              (meta.touched && !!meta.error) ||
              (props.error && checkRequired) ||
              (props.error && !checkRequired && !!meta.value)
            }
            renderValue={!!props.renderValue ? props.renderValue : renderValue}
            IconComponent={renderIconComponent}
            disabled={readOnly}
          >
            {options.map((option) => {
              if (!!disableOptions) {
                const checkValue = disableOptions.find(
                  (item) => item.value === option.value
                );
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    className={option.className}
                    disabled={!!checkValue}
                  >
                    <Typography variant="input_style" color="normal_text">
                      {option.label}
                    </Typography>
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    className={option.className}
                  >
                    <Typography variant="input_style" color="normal_text">
                      {option.label}
                    </Typography>
                  </MenuItem>
                );
              }
            })}
          </Select>
          {!!unit && (
            <Typography
              variant="sp_unit_select_text"
              color="b_333"
              sx={{ ...unitSx }}
            >
              {unit}
            </Typography>
          )}
        </Stack>
        {(props.error || !!meta.error ? open : false) && showError && (
          <Typography variant="text_error" color="sp_secondary_01">
            ※{meta.error ?? errorMessage ?? ''}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export const SPInputSelect: FC<SPInputSelectProps> = ({
  useFastField = true,
  ...props
}) => {
  return <SPInputSelectTemp {...props} />;
};
