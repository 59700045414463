import { MESSAGE_REGEX } from 'constant';
import { regex, yup } from 'libs';

export const validationSchema = yup.object({
  email: yup
    .string()
    .matches(regex.email, MESSAGE_REGEX.EMAIL)
    .required()
    .label('登録メールアドレス'),
  password: yup
    .string()
    .required()
    .min(8)
    .max(20, 'パスワードは20桁以内で設定ください。')
    .matches(
      regex.password,
      'パスワードは大文字、小文字、数字をそれぞれ1つ以上で設定してください。'
    )
    .label('パスワード'),
});
