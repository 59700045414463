import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { SPBusinessCardType, SPTitle, TEXT_DOCUMENT_EMPTY } from 'constant';
import { Icons, SPButton, SPFormItem } from 'components';
import { routeNames } from 'navigations/routes';
import { useSelector } from 'react-redux';
import { spStepEightSelector } from 'pages/SPStepEight/selectors';
import { ImageContext } from 'context';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
} from 'pages/SPStepOne/selectors';
import { getFileExtFromUrl } from 'utils';
import { ImageItem } from '../ImageItems';
import { getReferralAgencyOptionsAction } from '../../../SPStepEight/thunk';
import { useAppDispatch } from '../../../../hooks';

type SPStepEightInfoProps = {
  showEditButton?: boolean;
  reload?: number;
};
export const SPStepEightInfo: FC<SPStepEightInfoProps> = ({
  showEditButton = true,
  reload,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    agenciesOptions,
    storesOptions,
    exhibitionsOptions,
    salePersonsOptions,
    ...spStepEight
  } = useSelector(spStepEightSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const { businessCard } = useContext(ImageContext);

  const checkTitle = () => {
    if (incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_TWELVE_INCOME_GUARANTORS;
    }
    if (incomeTotalizer && !hasJoinGuarantor) {
      return SPTitle.STEP_ELEVEN_INCOME;
    }
    if (!incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_NINE_GUARANTORS;
    }
    return SPTitle.STEP_EIGHT;
  };

  useEffect(() => {
    dispatch(
      getReferralAgencyOptionsAction({
        ...(!!spStepEight.p_referral_agencies.sale_agent_id && {
          sale_agent_id: spStepEight.p_referral_agencies.sale_agent_id,
        }),
        ...(!!spStepEight.p_referral_agencies.store_id && {
          store_id: spStepEight.p_referral_agencies.store_id,
        }),
      })
    );
  }, [spStepEight.p_referral_agencies]);

  return (
    <Stack>
      {spStepEight && (
        <Stack>
          <Stack
            direction="row"
            sx={{ px: 4, py: 1, mb: '1px', bgcolor: 'sp_primary_100_main' }}
          >
            <Typography
              variant="sp_step_text"
              color="main_white"
              sx={{ mt: 1 }}
            >
              STEP
            </Typography>
            <Typography variant="sp_label_text" color="main_white">
              {checkTitle()}
            </Typography>
          </Stack>

          {spStepEight.p_referral_agencies.has_business_card ===
          SPBusinessCardType.YES ? (
            <SPFormItem
              label="担当者の名刺"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px' }}
              required
            >
              {businessCard.length
                ? businessCard.map((image, index) =>
                    (image instanceof File ? true : !image.hiden) ? (
                      <ImageItem
                        key={index}
                        fileName={image.name}
                        src={image instanceof File ? image : image.url}
                        reload={reload}
                        display={image instanceof File ? true : !image.hiden}
                        type={
                          image instanceof File
                            ? image.type
                            : getFileExtFromUrl(image.url)
                        }
                      />
                    ) : (
                      <Typography
                        variant="sp_value_text"
                        color="bg_radio_avatar"
                        textAlign="center"
                      >
                        {TEXT_DOCUMENT_EMPTY}
                      </Typography>
                    )
                  )
                : 'ー'}
            </SPFormItem>
          ) : (
            <Stack>
              <SPFormItem
                label={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="sp_unit_select_text" color="b_333">
                      提携先企業
                    </Typography>
                    <Typography
                      variant="sp_unit_select_text"
                      color="b_333"
                      sx={{ fontSize: 12 }}
                    >
                      （不動産会社・住宅メーカー等）
                    </Typography>
                  </Stack>
                }
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {spStepEight.p_referral_agencies.sale_agent_id
                    ? agenciesOptions.find(
                        (item) =>
                          item.value ===
                          spStepEight.p_referral_agencies.sale_agent_id
                      )?.label
                    : 'ー'}
                </Typography>
              </SPFormItem>
              <SPFormItem
                label="エリア"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {spStepEight.p_referral_agencies.store_id
                    ? storesOptions.find(
                        (item) =>
                          item.value ===
                          spStepEight.p_referral_agencies.store_id
                      )?.label
                    : 'ー'}
                </Typography>
              </SPFormItem>
              <SPFormItem
                label="展示場"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {spStepEight.p_referral_agencies.exhibition_id
                    ? exhibitionsOptions.find(
                        (item) =>
                          item.value ===
                          spStepEight.p_referral_agencies.exhibition_id
                      )?.label
                    : 'ー'}
                </Typography>
              </SPFormItem>
              {/*<SPFormItem*/}
              {/*  label="担当者名"*/}
              {/*  labelSx={{ p: '3px 16px' }}*/}
              {/*  childrenSx={{ p: '12px 24px 20px' }}*/}
              {/*  required*/}
              {/*>*/}
              {/*  <Typography variant="sp_value_text" color="b_333">*/}
              {/*    {spStepEight.p_referral_agencies.s_sale_person_id*/}
              {/*      ? salePersonsOptions.find(*/}
              {/*          (item) =>*/}
              {/*            item.value ===*/}
              {/*            spStepEight.p_referral_agencies.s_sale_person_id*/}
              {/*        )?.label*/}
              {/*      : 'ー'}*/}
              {/*  </Typography>*/}
              {/*</SPFormItem>*/}
              <SPFormItem
                label="担当者名"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {!!spStepEight.p_referral_agencies.sale_person_name_input
                    ? spStepEight.p_referral_agencies.sale_person_name_input
                    : 'ー'}
                </Typography>
              </SPFormItem>
              <SPFormItem
                label="電話番号"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {!!spStepEight.p_referral_agencies.phone_number
                    ? spStepEight.p_referral_agencies.phone_number
                    : 'ー'}
                </Typography>
              </SPFormItem>
            </Stack>
          )}

          {showEditButton && (
            <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
              <Stack
                sx={{
                  paddingRight: 0,
                  paddingBottom: 0,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderBottom: 'none',
                }}
              >
                <SPButton
                  sx={{
                    py: 1,
                    px: 3,
                    borderRadius: '10px',
                    borderWidth: 2,
                    bgcolor: 'sp_primary_40',
                    border: (theme) =>
                      `1px solid ${theme?.palette?.sp_primary_100_main}`,
                  }}
                  variant="outlined"
                  fullWidth
                  onClick={() => navigate(routeNames.SPStepEight.path)}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Icons.SPPencil />
                    <Typography
                      variant="sp_contentButton_text"
                      color="sp_primary_100_main"
                    >
                      {`STEP${checkTitle().slice(0, 2)} を修正する`}
                    </Typography>
                  </Stack>
                </SPButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
