import { FC } from 'react';
import {
  Stack,
  Typography,
  Container,
  Card,
  CardContent,
  Avatar,
} from '@mui/material';
import { Icons } from 'components';
import { LandingLayout } from 'containers';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';

const InputTimePage: FC = () => {
  const navigate = useNavigate();
  return (
    <LandingLayout
      header={{}}
      footer={{
        left: {
          onClick: () => navigate(routeNames.Consent.path),
        },
        right: {
          children: '次へ進む',
          onClick: () => navigate(routeNames.Material.path),
        },
      }}
    >
      <Stack
        sx={{ bgcolor: 'bg_off' }}
        alignItems="center"
        justifyContent="center"
      >
        <Container sx={{ px: { mobile: 4, tablet: 10 }, mt: 7 }}>
          <Card
            elevation={0}
            sx={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
          >
            <CardContent>
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{ borderRadius: '5px', py: 16 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ mb: 8 }}
                  spacing={2}
                >
                  <Icons.WatchLater sx={{ width: 40, height: 40 }} />
                  <Typography variant="h2" color="normal_text">
                    入力時間の目安
                  </Typography>
                </Stack>
                <Avatar
                  variant="square"
                  sx={{
                    width: { mobile: 280, tablet: 471 },
                    height: 204,
                    bgcolor: 'pale_grey',
                    borderRadius: '20px',
                  }}
                >
                  <Typography
                    variant="h2"
                    color="normal_text"
                    sx={{ fontSize: 24 }}
                  >
                    イメージ
                  </Typography>
                </Avatar>
                <Stack spacing={1} sx={{ mt: 8 }}>
                  <Typography
                    variant="text_note_modal"
                    color="normal_text"
                    lineHeight="50.4px"
                  >
                    お申込時の物件情報やお客さま情報の入力には、
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="modalTitle"
                      color="h_blugreen"
                      lineHeight="50.4px"
                    >
                      15分～20分程度
                    </Typography>
                    <Typography
                      variant="text_note_modal"
                      color="normal_text"
                      lineHeight="50.4px"
                    >
                      のお時間が必要です。
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Container>
      </Stack>
    </LandingLayout>
  );
};

export default InputTimePage;
