import {
  ApplicantDetailType,
  BorrowingStatusType,
  BuyingandSellingScheduleType,
  CoOwnerType,
  CurrentResidenceType,
  DispatchType,
  FinalTaxReturnType,
  IdentityVerificationType,
  OccupationDetailType,
  OccupationType,
  PositionType,
  ReasonsForNeedingHousingType,
  SexType,
  SourceOfIncomeType,
} from 'constant';
import { AdminPreliminaries } from 'services';

const DEFAULT_DATA_FILE = [{ id: '', filename: '', url: '' }];

export const APPLICANT_PEOPLE_ATTRIBUTE: AdminPreliminaries['p_application_header']['p_applicant_people_attributes'][number] =
  {
    id: '',
    applicant_detail_type: ApplicantDetailType.DEFAULT,
    has_join_guarantor: CoOwnerType.DEFAULT,
    last_name_kanji: '',
    first_name_kanji: '',
    last_name_kana: '',
    first_name_kana: '',
    sex: '',
    name_relationship_to_applicant: '',
    birthday: '',
    nationality: '',
    mobile_phone_number: '',
    home_phone_number: '',
    postal_code: '',
    prefecture_kanji: '',
    city_kanji: '',
    district_kanji: '',
    other_address_kanji: '',
    owner_email: '',
    occupation: OccupationType.DEFAULT,
    other_occupation: '',
    industry: '',
    other_industry: '',
    occupation_detail: OccupationDetailType.DEFAULT,
    other_occupation_detail: '',
    office_name_kanji: '',
    department: '',
    office_phone_number: '',
    office_postal_code: '',
    office_prefecture_kanji: '',
    office_city_kanji: '',
    office_district_kanji: '',
    number_of_employee: '',
    employment_started_date: '',
    last_year_income: '',
    bonus_income: '',
    two_years_ago_income: '',
    tax_return: FinalTaxReturnType.DEFAULT,
    other_type_tax_return: '',
    transfer_office: DispatchType.DEFAULT,
    transfer_office_name_kanji: '',
    transfer_office_name_kana: '',
    transfer_office_phone_number: '',
    transfer_office_postal_code: '',
    transfer_office_prefecture_kanji: '',
    transfer_office_city_kanji: '',
    transfer_office_district_kanji: '',
    maternity_paternity_leave_status: '',
    maternity_paternity_leave_start_time: '',
    maternity_paternity_leave_end_time: '',
    nursing_leave_status: '',
    lived_length_year_num: '',
    lived_length_month_num: '',
    current_residence: CurrentResidenceType.DEFAULT,
    current_residence_floor_area: '',
    owner_full_name: '',
    owner_relationship: '',
    buyingand_selling_schedule_type: BuyingandSellingScheduleType.DEFAULT,
    other_buyingand_selling_schedule_type: '',
    scheduled_time_sell_house: '',
    expected_house_selling_price: '',
    current_home_loan: '',
    reason_acquire_home: ReasonsForNeedingHousingType.DEFAULT,
    other_reason_acquire_home: '',
    borrowing_status: BorrowingStatusType.DEFAULT,
    land_rent_to_be_paid: '',
    house_rent_to_be_paid: '',
    identity_verification: IdentityVerificationType.DEFAULT,
    emergency_contact_number: '',
    prefecture_kana: '',
    city_kana: '',
    district_kana: '',
    other_address_kana: '',
    emplmt_form_code: '',
    office_name_kana: '',
    position: PositionType.DEFAULT,
    headquarters_location: '',
    listed_division: '',
    office_establishment_date: '',
    capital_stock: '',
    other_office_address_kanji: '',
    office_prefecture_kana: '',
    office_city_kana: '',
    office_district_kana: '',
    other_office_address_kana: '',
    relationship_to_applicant: '',
    other_relationship_to_applicant: '',
    _destroy: false,
    income_source: [SourceOfIncomeType.DEFAULT],
    main_income_source: SourceOfIncomeType.DEFAULT,
    type_tax_return: [],
    personal_file_upload: {
      driver_license_front_image: DEFAULT_DATA_FILE,
      driver_license_back_image: DEFAULT_DATA_FILE,
      card_number_front_image: DEFAULT_DATA_FILE,
      // card_number_back_image: DEFAULT_DATA_FILE,
      resident_register_front_image: DEFAULT_DATA_FILE,
      resident_register_back_image: DEFAULT_DATA_FILE,
      insurance_file: DEFAULT_DATA_FILE,
      insurance_file_back_image: DEFAULT_DATA_FILE,
      first_withholding_slip_file: DEFAULT_DATA_FILE,
      second_withholding_slip_file: DEFAULT_DATA_FILE,
      first_income_file: DEFAULT_DATA_FILE,
      second_income_file: DEFAULT_DATA_FILE,
      third_income_file: DEFAULT_DATA_FILE,
      financial_statement_1_file: DEFAULT_DATA_FILE,
      financial_statement_2_file: DEFAULT_DATA_FILE,
      financial_statement_3_file: DEFAULT_DATA_FILE,
      other_document_file: DEFAULT_DATA_FILE,
      employment_agreement_file: DEFAULT_DATA_FILE,
      business_tax_return_1_file: DEFAULT_DATA_FILE,
      business_tax_return_2_file: DEFAULT_DATA_FILE,
      business_tax_return_3_file: DEFAULT_DATA_FILE,
      residence_file: DEFAULT_DATA_FILE,
      residence_file_back_image: DEFAULT_DATA_FILE,
    },
  };

export const JOIN_GUARANTORS_ATTRIBUTE = {
  id: '',
  last_name_kanji: '',
  first_name_kanji: '',
  last_name_kana: '',
  first_name_kana: '',
  sex: SexType.DEFAULT,
  guarantor_relationship_name: '',
  birthday: '',
  mobile_phone_number: '',
  home_phone_number: '',
  postal_code: '',
  prefecture_kanji: '',
  city_kanji: '',
  district_kanji: '',
  other_address_kanji: '',
  guarantor_relationship_to_applicant: '',
  other_relationship_to_applicant: '',
  emergency_contact_number: '',
  prefecture_kana: '',
  city_kana: '',
  district_kana: '',
  other_address_kana: '',
  owner_email: '',
  _destroy: false,
};

export enum FILES_UPLOAD_OPTIONS {
  DRIVER_LICENSE = 'driver_license',
  CARD_NUMBER = 'card_number',
  RESIDENT_REGISTER = 'resident_register',
}
