import { RootState } from 'types';

export const spStepEightSelector = (state: RootState) => ({
  p_referral_agencies: state.sp_step_eight.form.p_referral_agencies,
  agenciesOptions: state.sp_step_eight.agenciesOptions,
  storesOptions: state.sp_step_eight.storesOptions,
  exhibitionsOptions: state.sp_step_eight.exhibitionsOptions,
  salePersonsOptions: state.sp_step_eight.salePersonsOptions,
  p_referral_agency_id: state.sp_step_eight.p_referral_agency_id,
});
