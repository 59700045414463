import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { loanTax } from 'assets';
import {
  Button,
  CheckboxButton,
  CheckboxMultipleButton,
  FormItem,
  Icons,
  InputDate,
  InputDateTime,
  InputField,
  InputSelect,
  InputSelectProps,
  RadioGroupButton,
  UlStyle,
} from 'components';
import {
  ApplicantDetailType,
  ApplicantType,
  CoOwnerType,
  LandAdvanceType,
  LoanType,
  Placeholder,
  SupplierType,
  joinGuarantor,
  joinGuarantorCount,
  landAdvance,
} from 'constant';
import { StepLayout } from 'containers';
import { handleNewSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import { useAppDispatch, useAppView, usePrevious } from 'hooks';
import { dayjs } from 'libs';
import { get, isEqual } from 'lodash';
import { routeNames } from 'navigations/routes';
import {
  convertSynthesisSelector,
  isEditingSelector,
  isInitialValidSynthesisSelector,
  stepSynthesisSelector,
} from 'pages/StepSynthesisInformation/selectors';
import { saveForm } from 'pages/StepSynthesisInformation/slice';
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PostalCode } from 'services';
import { PBankAttributes, StepSynthesisForm } from 'types';
import {
  convertDynamicOptions,
  convertManToYen,
  convertToFullWidth,
  flattenToLodashFormat,
  zeroPad,
} from 'utils';
import { dynamicOptionsSelectors, masterBankSelectors } from './selectors';
import { getZipCloudAddress } from './thunk';
import { validationSchema } from './validationSchema';

type RadioItems = {
  value: string;
  label: string | string[];
  src?: string;
  icon?: ReactNode;
};

const StepOneRequiredInformationPage: FC = () => {
  const dispatch = useAppDispatch();
  const initialValues = useSelector(stepSynthesisSelector);
  const isInitialValid = useSelector(isInitialValidSynthesisSelector);
  const masterBank = useSelector(masterBankSelectors);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const convertedFields = useSelector(convertSynthesisSelector);
  const isEditing = useSelector(isEditingSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const stepOneB = useRef<HTMLDivElement>(null);
  const stepOneC = useRef<HTMLDivElement>(null);
  const stepThreeD = useRef<HTMLDivElement>(null);
  const stepThreeDOther = useRef<HTMLDivElement>(null);

  const { state } = useAppView<string, ApplicantType>();

  useEffect(() => {
    switch (state?.label) {
      case 'お借入れについて':
        switch (state.note) {
          case ApplicantType.PAIR:
            stepOneC.current?.scrollIntoView({ behavior: 'smooth' });
            break;
          default:
            stepOneB.current?.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'お客様について':
        switch (state.note) {
          case ApplicantType.PAIR:
            stepThreeDOther.current?.scrollIntoView({ behavior: 'smooth' });
            break;
          default:
            stepThreeD.current?.scrollIntoView({ behavior: 'smooth' });
        }
        break;
    }
  }, [state]);

  const convertCurrency = useCallback((values: StepSynthesisForm) => {
    const [
      temporary_desired_loan_amount,
      halfyear_bonus,
      p_land_advance_plan_desired_loan_amount,
      p_land_advance_plan_halfyear_bonus,
      pair_temporary_desired_loan_amount,
      pair_halfyear_bonus,
    ] = convertManToYen([
      values.main_p_applicant_person.p_borrowing_detail_attributes
        .temporary_desired_loan_amount,
      values.main_p_applicant_person.p_borrowing_detail_attributes
        .halfyear_bonus,
      values.main_p_applicant_person.p_land_advance_plan_attributes
        .desired_loan_amount,
      values.main_p_applicant_person.p_land_advance_plan_attributes
        .halfyear_bonus,
      values.pair_p_applicant_person.p_borrowing_detail_attributes
        .temporary_desired_loan_amount,
      values.pair_p_applicant_person.p_borrowing_detail_attributes
        .halfyear_bonus,
    ]);
    return {
      ...values,
      main_p_applicant_person: {
        ...values.main_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...values.main_p_applicant_person.p_borrowing_detail_attributes,
          temporary_desired_loan_amount,
          halfyear_bonus,
        },
        p_land_advance_plan_attributes: {
          ...values.main_p_applicant_person.p_land_advance_plan_attributes,
          desired_loan_amount: p_land_advance_plan_desired_loan_amount,
          halfyear_bonus: p_land_advance_plan_halfyear_bonus,
        },
      },
      pair_p_applicant_person: {
        ...values.pair_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...values.pair_p_applicant_person.p_borrowing_detail_attributes,
          temporary_desired_loan_amount: pair_temporary_desired_loan_amount,
          halfyear_bonus: pair_halfyear_bonus,
        },
      },
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      main_p_applicant_person: {
        ...initialValues.main_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...initialValues.main_p_applicant_person
            .p_borrowing_detail_attributes,
          temporary_desired_loan_amount:
            convertedFields.temporary_desired_loan_amount,
          halfyear_bonus: convertedFields.halfyear_bonus,
        },
        p_land_advance_plan_attributes: {
          ...initialValues.main_p_applicant_person
            .p_land_advance_plan_attributes,
          desired_loan_amount:
            convertedFields.p_land_advance_plan_desired_loan_amount,
          halfyear_bonus: convertedFields.p_land_advance_plan_halfyear_bonus,
        },
      },
      pair_p_applicant_person: {
        ...initialValues.pair_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...initialValues.pair_p_applicant_person
            .p_borrowing_detail_attributes,
          temporary_desired_loan_amount:
            convertedFields.pair_temporary_desired_loan_amount,
          halfyear_bonus: convertedFields.pair_halfyear_bonus,
        },
      },
      confirm_self_entered: false,
    },
    validationSchema,
    isInitialValid,
    async onSubmit(values, { setSubmitting }) {
      dispatch(saveForm(convertCurrency(values)));
      setSubmitting(false);
      navigate(routeNames.StepSynthesisInformation.path);
    },
  });

  const onSaveDraft = async () => {
    await dispatch(
      handleNewSaveDraft({
        ...convertCurrency(formik.values),
        current_path: location.pathname,
      })
    );
    return;
  };

  useEffect(() => {
    switch (formik.values.p_application_header.loan_type) {
      case LoanType.TWO:
        formik.setFieldValue(
          'pair_p_applicant_person.applicant_detail_type',
          ApplicantDetailType.PAIR
        );
        break;
      case LoanType.THREE:
        formik.setFieldValue(
          'pair_p_applicant_person.applicant_detail_type',
          ApplicantDetailType.JOINTDEBT
        );
        break;
      case LoanType.FOUR:
        formik.setFieldValue(
          'pair_p_applicant_person.applicant_detail_type',
          ApplicantDetailType.JOINTGUARANTOR
        );
        break;
      default:
        formik.setFieldValue(
          'pair_p_applicant_person.applicant_detail_type',
          ApplicantDetailType.DEFAULT
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldValue, formik.values.p_application_header.loan_type]);

  const handleZipCodeChange = async (name: string) => {
    formik.setFieldTouched(`${name}.postal_code`);
    const inputValue = get(formik.values, `${name}.postal_code`);
    if (!isNaN(+inputValue) && inputValue.length === 7) {
      const result = await dispatch(getZipCloudAddress(inputValue));
      if (getZipCloudAddress.fulfilled.match(result)) {
        if (!result.payload.results) {
          toast.error('住所が取得できませんでした。再度入力してください。');
          formik.setFieldValue(`${name}.prefecture_kanji`, '');
          formik.setFieldValue(`${name}.city_kanji`, '');
          formik.setFieldValue(`${name}.district_kanji`, '');
          formik.setFieldValue(`${name}.prefecture_kana`, '');
          formik.setFieldValue(`${name}.city_kana`, '');
          formik.setFieldValue(`${name}.district_kana`, '');
          return;
        }
        setHomeAddress(result.payload.results[0], name);
      }
    }
  };

  const setHomeAddress = (postal: PostalCode, name: string) => {
    formik.setFieldValue(`${name}.prefecture_kanji`, postal.address1);
    formik.setFieldValue(`${name}.city_kanji`, postal.address2);
    formik.setFieldValue(`${name}.district_kanji`, postal.address3);
    formik.setFieldValue(
      `${name}.prefecture_kana`,
      convertToFullWidth(postal.kana1)
    );
    formik.setFieldValue(`${name}.city_kana`, convertToFullWidth(postal.kana2));
    formik.setFieldValue(
      `${name}.district_kana`,
      convertToFullWidth(postal.kana3)
    );
  };

  const age = useMemo(() => {
    const monthDiff = dayjs().diff(
      formik.values.main_p_applicant_person.birthday,
      'month'
    );
    const [year] = formik.values?.main_p_applicant_person.birthday?.split('/');
    if (
      !formik.values.main_p_applicant_person.birthday ||
      monthDiff < 0 ||
      !year
    ) {
      return '';
    }
    return Math.floor(monthDiff / 12);
  }, [formik.values.main_p_applicant_person.birthday]);

  const ageOther = useMemo(() => {
    const monthDiff = dayjs().diff(
      formik.values.pair_p_applicant_person.birthday,
      'month'
    );
    const [year] = formik.values?.pair_p_applicant_person.birthday?.split('/');
    if (
      !formik.values.pair_p_applicant_person.birthday ||
      monthDiff < 0 ||
      !year
    ) {
      return '';
    }
    return Math.floor(monthDiff / 12);
  }, [formik.values.pair_p_applicant_person.birthday]);

  useEffect(() => {
    const selectedBanks = masterBank.filter((bank) =>
      formik.values.p_application_header.master_bank_ids.includes(bank.id)
    );
    const selectedCodes = selectedBanks.map((bank) => bank.code);

    const p_application_banks_attributes: PBankAttributes[] = selectedBanks.map(
      (bank) => ({
        s_master_bank_id: bank.id,
        name: bank.name,
      })
    );

    formik.setFieldValue('master_bank_codes', selectedCodes);

    if (isEditing) {
      const editedBankSelected: PBankAttributes[] =
        formik.values.p_application_header.p_application_banks_attributes
          .filter((p_bank) => !!p_bank.id)
          .map((p_bank) => ({
            ...p_bank,
            _destroy: true,
          }));

      const edited_p_application_banks_attributes: PBankAttributes[] =
        editedBankSelected.concat(p_application_banks_attributes);

      formik.setFieldValue(
        'p_application_header.p_application_banks_attributes',
        edited_p_application_banks_attributes
      );
    } else {
      formik.setFieldValue(
        'p_application_header.p_application_banks_attributes',
        p_application_banks_attributes
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.p_application_header.master_bank_ids,
    masterBank,
    isEditing,
  ]);

  useEffect(() => {
    if (formik.values.p_application_header.loan_type === LoanType.TWO) {
      formik.setFieldValue(
        'pair_p_applicant_person.has_land_advance_plan',
        formik.values.p_application_header.has_land_advance_plan
      );
    } else {
      formik.setFieldValue(
        'pair_p_applicant_person.has_land_advance_plan',
        LandAdvanceType.DEFAULT
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.p_application_header.has_land_advance_plan,
    formik.values.p_application_header.loan_type,
  ]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  const loanTypeOptions = useMemo(() => {
    const value = dynamicOptions.loan_type?.value.map((option) => {
      let icon;
      switch (option.option_code) {
        case 1:
          icon = <Icons.Alone />;
          break;
        case 2:
          icon = <Icons.Pair />;
          break;
        case 3:
        case 4:
          icon = <Icons.TotalIncome />;
          break;
        default:
          break;
      }
      return {
        value: option.option_code.toString(),
        label: option.option_name,
        icon,
      };
    });
    return value;
  }, [dynamicOptions.loan_type]);

  const loanTargetOptions = useMemo(() => {
    const value = dynamicOptions.loan_target?.value.map((option) => {
      let icon;
      switch (option.option_code) {
        case 1:
          icon = <Icons.House />;
          break;
        case 2:
          icon = <Icons.SecondHouse />;
          break;
        case 3:
          icon = <Icons.Condominium />;
          break;
        case 4:
          icon = <Icons.SecondApartment />;
          break;
        case 5:
          icon = <Icons.NewBuilding />;
          break;
        case 6:
          icon = <Icons.NewLandBuilding />;
          break;
        case 7:
          icon = <Icons.Refinancing />;
          break;
        case 8:
          icon = <Icons.Renovation />;
          break;
        case 9:
          icon = <Icons.Renovation />;
          break;
        default:
          break;
      }
      return {
        value: option.option_code.toString(),
        label: option.option_name,
        icon,
      };
    });
    return value;
  }, [dynamicOptions.loan_target]);

  const sexOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.sex),
    [dynamicOptions.sex]
  );

  const repaymentOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.repayment_method),
    [dynamicOptions.repayment_method]
  );

  const relationshipToApplicantOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.relationship_to_applicant),
    [dynamicOptions.relationship_to_applicant]
  );

  const guarantorRelationshipOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.guarantor_relationship_to_applicant),
    [dynamicOptions.guarantor_relationship_to_applicant]
  );

  const prevLoanTarget = usePrevious(
    formik.values.p_application_header.loan_target
  );
  const prevBanks = usePrevious(
    formik.values.p_application_header.master_bank_ids
  );

  useEffect(() => {
    if (prevLoanTarget !== formik.values.p_application_header.loan_target) {
      formik.setFieldValue('p_application_header.land_purchase_price', '');
      formik.setFieldValue('p_application_header.house_purchase_price', '');
      formik.setFieldValue('p_application_header.accessory_cost', '');
      formik.setFieldValue('p_application_header.additional_cost', '');
      formik.setFieldValue('p_application_header.refinancing_loan_balance', '');
      formik.setFieldValue(
        'p_application_header.other_require_funds_breakdown',
        ''
      );
      formik.setFieldValue('p_application_header.house_upgrade_cost', '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      !isEqual(prevBanks, formik.values.p_application_header.master_bank_ids)
    ) {
      formik.setFieldValue('p_application_header.saving_amount', '');
      formik.setFieldValue('p_application_header.relative_donation_amount', '');
      formik.setFieldValue('p_application_header.loan_amount', '');
      formik.setFieldValue('p_application_header.pair_loan_amount', '');
      formik.setFieldValue(
        'p_application_header.other_procurement_breakdown',
        ''
      );
      formik.setFieldValue('p_application_header.deposit_savings_1', '');
      formik.setFieldValue('p_application_header.deposit_savings_2', '');
      formik.setFieldValue('p_application_header.real_estate_sale_price', '');
      formik.setFieldValue('p_application_header.other_saving_amount', '');
      formik.setFieldValue('main_p_applicant_person.last_year_income', '');
      formik.setFieldValue('main_p_applicant_person.bonus_income', '');
      formik.setFieldValue('main_p_applicant_person.business_income', '');
      formik.setFieldValue('main_p_applicant_person.pension_income', '');
      formik.setFieldValue('main_p_applicant_person.solar_power_income', '');
      formik.setFieldValue(
        'main_p_applicant_person.total_last_year_income',
        ''
      );
      formik.setFieldValue('main_p_applicant_person.two_years_ago_income', '');
      formik.setFieldValue('pair_p_applicant_person.last_year_income', '');
      formik.setFieldValue('pair_p_applicant_person.bonus_income', '');
      formik.setFieldValue('pair_p_applicant_person.business_income', '');
      formik.setFieldValue('pair_p_applicant_person.pension_income', '');
      formik.setFieldValue('pair_p_applicant_person.solar_power_income', '');
      formik.setFieldValue(
        'pair_p_applicant_person.total_last_year_income',
        ''
      );
      formik.setFieldValue('pair_p_applicant_person.two_years_ago_income', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.master_bank_ids]);

  useEffect(() => {
    if (isEditing) {
      formik.setFieldValue(
        'main_p_applicant_person.confirmation_email',
        formik.values.main_p_applicant_person.owner_email
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (
      formik.values.main_p_applicant_person.has_join_guarantor ===
      CoOwnerType.NONE
    ) {
      formik.setFieldValue(
        'main_p_applicant_person.join_guarantor_count',
        SupplierType.DEFAULT
      );
      if (isEditing) {
        const join_guarantors = [
          ...formik.values.main_p_applicant_person.p_join_guarantors_attributes,
        ]
          .filter((guarantor) => !!guarantor.id)
          .map((guarantor) => ({ ...guarantor, _destroy: true }));
        formik.setFieldValue(
          'main_p_applicant_person.p_join_guarantors_attributes',
          join_guarantors
        );
      } else {
        formik.setFieldValue(
          'main_p_applicant_person.p_join_guarantors_attributes',
          []
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, formik.values.main_p_applicant_person.has_join_guarantor]);

  return (
    <StepLayout
      footer={{
        left: {
          onClick: () => {
            dispatch(saveForm(convertCurrency(formik.values)));
            navigate(routeNames.Confirmation.path);
          },
        },
        right: {
          children: '次へすすむ',
          onClick: () => formik.handleSubmit(),
        },
      }}
      onSaveDraft={onSaveDraft}
    >
      <FormikProvider value={formik}>
        <Stack justifyContent="center">
          <Typography
            variant="leafletOption"
            color="normal_text"
            sx={{
              letterSpacing: '0.2px',
              lineHeight: '28px',
              mt: 9,
              mb: '10px',
            }}
          >
            申込人ご本人様に入力していただく項目
          </Typography>
          <FormItem divider label="申込日兼同意日" required>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 4 }}>
                <InputDateTime
                  yearOptions={secondYearOptions}
                  monthOptions={monthOptions}
                  name="p_application_header.loan_apply_date"
                  checkRequired
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem divider label="借入希望日" required>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 4 }}>
                <InputDateTime
                  yearOptions={secondYearOptions}
                  monthOptions={monthOptions}
                  name="p_application_header.loan_desired_borrowing_date"
                  checkRequired
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem divider label="入居予定年月" required>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 4 }}>
                <InputDate
                  name="p_application_header.scheduled_date_moving"
                  yearOptions={secondYearOptions}
                  checkRequired
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem
            divider
            label="送付する金融機関を選択してください。（複数選択可）"
            required
            help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
          >
            <CheckboxMultipleButton
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                px: 12,
              }}
              itemPerRow={1}
              limitItem={2}
              name="p_application_header.master_bank_ids"
              options={masterBank.slice(0, 5)}
            />
          </FormItem>

          <FormItem
            sx={{ mt: 7 }}
            divider
            label={dynamicOptions.loan_type?.field_name_ja ?? 'お借入れ形態'}
            help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
            required
            note={notes.loan_type}
          >
            <RadioGroupButton
              name="p_application_header.loan_type"
              options={loanTypeOptions}
              itemPerRow={4}
            />
          </FormItem>

          <FormItem
            divider
            label={dynamicOptions.loan_target?.field_name_ja ?? '資金使途'}
            help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
            required
          >
            <RadioGroupButton
              name="p_application_header.loan_target"
              options={loanTargetOptions}
              itemPerRow={3}
            />
          </FormItem>
          <Stack ref={stepThreeD}>
            <FormItem
              sx={{ mt: 8 }}
              divider
              label="お名前（漢字）（全角）"
              required
              note={notes.fullName}
            >
              <Grid
                container
                spacing={7}
                direction={{
                  mobile: 'column',
                  tablet: 'row',
                  desktop: 'row',
                }}
              >
                <Grid item {...{ mobile: 6, md: 4 }}>
                  <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
                    姓
                  </Typography>
                  <InputField
                    name="main_p_applicant_person.last_name_kanji"
                    placeholder={Placeholder.LAST_NAME_KANJI}
                  />
                </Grid>
                <Grid item {...{ mobile: 6, md: 4 }}>
                  <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
                    名
                  </Typography>
                  <InputField
                    name="main_p_applicant_person.first_name_kanji"
                    placeholder={Placeholder.FIRST_NAME_KANJI}
                  />
                </Grid>
              </Grid>
            </FormItem>
          </Stack>

          <FormItem divider label="お名前（フリガナ）（全角カナ）" required>
            <Grid
              container
              spacing={7}
              direction={{
                mobile: 'column',
                tablet: 'row',
                desktop: 'row',
              }}
            >
              <Grid item {...{ mobile: 6, md: 4 }}>
                <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
                  姓
                </Typography>
                <InputField
                  convertKatakana
                  name="main_p_applicant_person.last_name_kana"
                  placeholder={Placeholder.LAST_NAME_KANA}
                  convertFullWidth
                />
              </Grid>
              <Grid item {...{ mobile: 6, md: 4 }}>
                <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
                  名
                </Typography>
                <InputField
                  convertKatakana
                  name="main_p_applicant_person.first_name_kana"
                  placeholder={Placeholder.FIRST_NAME_KANA}
                  convertFullWidth
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem
            divider
            label={dynamicOptions.sex?.field_name_ja ?? '性別'}
            required
          >
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="main_p_applicant_person.sex"
              options={sexOptions}
            />
          </FormItem>

          <Stack
            direction={{ mobile: 'column', tablet: 'row' }}
            justifyContent="center"
            alignItems={{ mobile: 'baseline', tablet: 'center' }}
            spacing={{ mobile: 2, tablet: 8 }}
            sx={{ mb: { mobile: 8, tablet: 0 } }}
          >
            <FormItem label="生年月日" required>
              <Grid container>
                <Grid item {...{ mobile: 12, md: 4 }}>
                  <InputDateTime
                    yearOptions={yearOptions}
                    monthOptions={monthOptions}
                    name="main_p_applicant_person.birthday"
                    checkRequired
                  />
                </Grid>
              </Grid>
            </FormItem>

            <Stack flex={2}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: { mobile: 0, tablet: 15 },
                  ml: { mobile: 0, tablet: 0 },
                }}
              >
                <Typography variant="text_age" color="normal_text">
                  （ {age + ' '}
                  <Typography
                    variant="landing_submit_button_title"
                    color="normal_text"
                  >
                    歳
                  </Typography>
                  ）
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider
            sx={{
              width: '100%',
              height: 2,
              backgroundImage: (theme) =>
                `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
              backgroundSize: '6px 9px',
              backgroundRepeat: 'repeat-x',
              borderBottomWidth: 'inherit',
            }}
          />

          <FormItem
            divider
            label="電話番号（半角数字）"
            required
            note={notes.phoneNumber}
          >
            <Typography sx={{ mb: 8 }} variant="h3" color="normal_text">
              携帯電話番号
            </Typography>
            <Grid container spacing={7}>
              <Grid item {...{ mobile: 12, md: 8 }}>
                <InputField
                  name="main_p_applicant_person.mobile_phone_number"
                  placeholder={Placeholder.PHONE_NUMBER}
                  numeric
                  convertHalfWidth
                />
              </Grid>
            </Grid>
            <Typography sx={{ mt: 10, mb: 8 }} variant="h3" color="normal_text">
              固定電話番号
            </Typography>
            <Grid container spacing={7}>
              <Grid item {...{ mobile: 12, md: 8 }}>
                <InputField
                  name="main_p_applicant_person.home_phone_number"
                  placeholder={Placeholder.PHONE_NUMBER}
                  numeric
                  convertHalfWidth
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem label="郵便番号" divider note={notes.postCode} required>
            <Grid container spacing={7}>
              <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                <InputField
                  name="main_p_applicant_person.postal_code"
                  placeholder={Placeholder.POSTAL_CODE}
                  numeric
                  convertHalfWidth
                />
              </Grid>
              <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                <Button
                  sx={{
                    width: { mobile: '100%', tablet: 187 },
                    minHeight: 50,
                    height: 50,
                    bgcolor: 'h_blugreen',
                    borderRadius: '4px',
                  }}
                  onClick={() => handleZipCodeChange('main_p_applicant_person')}
                >
                  <Typography
                    variant="text_button_step_required"
                    color="main_white"
                  >
                    郵便番号で検索
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </FormItem>

          <FormItem divider label="住所（漢字）" required note={notes.address}>
            <Stack spacing={4}>
              {formik.values.main_p_applicant_person.prefecture_kanji && (
                <Typography
                  variant="text_input_code"
                  color="normal_text"
                  sx={{ mt: '-10px' }}
                >
                  {formik.values.main_p_applicant_person.prefecture_kanji +
                    formik.values.main_p_applicant_person.city_kanji +
                    formik.values.main_p_applicant_person.district_kanji}
                </Typography>
              )}
              <InputField
                name="main_p_applicant_person.other_address_kanji"
                placeholder={Placeholder.ADDRESS_KANJI}
              />
            </Stack>
          </FormItem>

          <FormItem
            divider
            label="住所（フリガナ）"
            required
            note={notes.address}
          >
            <Stack spacing={4}>
              {formik.values.main_p_applicant_person.prefecture_kana && (
                <Typography
                  variant="text_input_code"
                  color="normal_text"
                  sx={{ mt: '-10px' }}
                >
                  {formik.values.main_p_applicant_person.prefecture_kana +
                    formik.values.main_p_applicant_person.city_kana +
                    formik.values.main_p_applicant_person.district_kana}
                </Typography>
              )}
              <InputField
                name="main_p_applicant_person.other_address_kana"
                placeholder={Placeholder.ADDRESS_KANA}
                convertKatakana
                convertFullWidth
              />
            </Stack>
          </FormItem>

          <FormItem
            divider
            label="メールアドレス"
            note={
              <UlStyle>
                <li>
                  <Typography variant="text_note" color="normal_text">
                    {notes.email}
                  </Typography>
                </li>
              </UlStyle>
            }
            required
          >
            <Grid container>
              <Grid item {...{ mobile: 12, md: 8 }}>
                <InputField
                  name="main_p_applicant_person.owner_email"
                  placeholder="ー"
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem divider label="メールアドレス（確認用）" required>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 8 }}>
                <InputField
                  name="main_p_applicant_person.confirmation_email"
                  placeholder="ー"
                />
              </Grid>
            </Grid>
          </FormItem>

          {formik.values.p_application_header.has_land_advance_plan ===
            LandAdvanceType.YES && (
            <Stack
              direction="column"
              spacing={1}
              sx={{
                bgcolor: '#ECEDF8',
                mt: 10,
                px: { mobile: 4, tablet: 5 },
                pt: { mobile: 5, tablet: 5 },
                pb: { mobile: 8, tablet: 6 },
              }}
            >
              <Typography variant="textButtonTopPage" color="normal_text">
                「1本目融資」について入力して下さい
              </Typography>
              <Typography variant="text_placeholder" color="normal_text">
                ※土地先行プランをご希望の場合は、「１本目融資」「２本目融資」に分けて「お借入内容」をご記入ください。借入期間・返済方法は同一とさせていただきます。
              </Typography>
            </Stack>
          )}

          <Stack ref={stepOneB}>
            <FormItem
              divider
              label="お借入希望額"
              required
              note={notes.desired_loan_amount}
            >
              <Stack direction="row">
                <InputField
                  sx={{ width: { mobile: '100%', tablet: 300 } }}
                  name="main_p_applicant_person.p_borrowing_detail_attributes.temporary_desired_loan_amount"
                  align="right"
                  placeholder="0"
                  unit="万円"
                  numeric
                  useNumberFormat
                />
              </Stack>
            </FormItem>
          </Stack>

          <FormItem
            divider
            label="内ボーナス払い（半年毎増額返済金額）"
            required
            note={notes.halfyear_bonus}
            explain={
              <Stack sx={{ mt: 7 }}>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  spacing="25px"
                  sx={{
                    bgcolor: 'main_white',
                    py: 8,
                    px: { mobile: 4, tablet: 7 },
                    borderRadius: '10px',
                  }}
                >
                  <Button
                    sx={{
                      bgcolor: 'h_blugreen',
                      py: 3,
                      pl: { mobile: 5, tablet: 5 },
                      pr: { mobile: 5, tablet: 9 },
                      borderRadius: '10px',
                    }}
                  >
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      spacing={8}
                      alignItems={{ mobile: 'center' }}
                    >
                      <Avatar
                        sx={{ borderRadius: 0, width: 114, height: 81 }}
                        src={loanTax}
                      >
                        <Typography variant="text_title" lineHeight="42px">
                          イメージ
                        </Typography>
                      </Avatar>
                      <Stack justifyContent="center">
                        <Typography
                          variant="text_accordion_status"
                          color="main_white"
                          lineHeight="36px"
                        >
                          住宅ローンシミュレーション
                        </Typography>
                      </Stack>
                    </Stack>
                  </Button>
                  <Stack>
                    <Typography variant="textstyle" lineHeight="24px">
                      住宅ローンを借りると、
                      <br />
                      <Typography variant="checkbox_button_R">
                        毎月いくらの返済なの？
                        <br />
                        総返済額は？
                        <br />
                      </Typography>
                      そんな疑問にお答えします！
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            }
          >
            <Stack direction="row">
              <InputField
                sx={{ width: { mobile: '100%', tablet: 300 } }}
                name="main_p_applicant_person.p_borrowing_detail_attributes.halfyear_bonus"
                align="right"
                placeholder="0"
                unit="万円"
                numeric
                useNumberFormat
              />
            </Stack>
          </FormItem>

          <FormItem
            label="お借入期間"
            divider
            required
            help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
            note={
              <Stack>
                <Stack>
                  <Typography variant="text_note" color="normal_text">
                    {notes.loan_term}
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ pt: 1 }}>
                  <Typography variant="text_note" color="normal_text">
                    ※借り換えの場合は
                    <IconButton
                      sx={{
                        color: 'Icon_color',
                        p: 0,
                        mt: -1,
                      }}
                    >
                      <Icons.Question sx={{ width: 25, height: 25 }} />
                    </IconButton>
                    をご覧ください。
                  </Typography>
                </Stack>
              </Stack>
            }
          >
            <Stack
              direction={{
                mobile: 'column',
                tablet: 'row',
                desktop: 'row',
              }}
              spacing={{ mobile: 5, tablet: 10, desktop: 10 }}
            >
              <Stack direction="row" spacing={4} alignItems="center">
                <InputField
                  sx={{ width: { tablet: 120, desktop: 120 } }}
                  name="main_p_applicant_person.p_borrowing_detail_attributes.loan_term"
                  align="right"
                  placeholder="0"
                  unit="年"
                  numeric
                  placement="top-start"
                  convertHalfWidth
                />
              </Stack>
            </Stack>
          </FormItem>

          <FormItem
            label="返済方法"
            required
            divider
            help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
          >
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="main_p_applicant_person.p_borrowing_detail_attributes.repayment_method"
              options={repaymentOptions}
            />
          </FormItem>

          <FormItem
            label="土地先行プラン希望"
            note={notes.request}
            required
            divider
          >
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="main_p_applicant_person.has_land_advance_plan"
              options={landAdvanceOptions}
            />
          </FormItem>
          {/* BEGIN MAIN PERSON LAND ADVANCE PLAN */}
          {formik.values.p_application_header.has_land_advance_plan ===
            LandAdvanceType.YES && (
            <>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  bgcolor: '#ECEDF8',
                  mt: 10,
                  px: { mobile: 4, tablet: 5 },
                  pt: { mobile: 5, tablet: 5 },
                  pb: { mobile: 8, tablet: 6 },
                }}
              >
                <Typography variant="textButtonTopPage" color="normal_text">
                  「2本目融資」について入力して下さい
                </Typography>
                <Typography variant="text_placeholder" color="normal_text">
                  ※土地先行プランをご希望の場合は、「１本目融資」「２本目融資」に分けて「お借入内容」をご記入ください。借入期間・返済方法は同一とさせていただきます。
                </Typography>
              </Stack>

              <FormItem
                divider
                label="お借入希望額"
                required
                note={notes.desired_loan_amount}
              >
                <Stack direction="row">
                  <InputField
                    sx={{ width: { mobile: '100%', tablet: 300 } }}
                    name="main_p_applicant_person.p_land_advance_plan_attributes.desired_loan_amount"
                    align="right"
                    placeholder="0"
                    unit="万円"
                    numeric
                    useNumberFormat
                  />
                </Stack>
              </FormItem>

              <FormItem
                divider
                label="内ボーナス払い（半年毎増額返済金額）"
                required
                note={notes.halfyear_bonus}
              >
                <Stack direction="row">
                  <InputField
                    sx={{ width: { mobile: '100%', tablet: 300 } }}
                    name="main_p_applicant_person.p_land_advance_plan_attributes.halfyear_bonus"
                    align="right"
                    placeholder="0"
                    unit="万円"
                    numeric
                    useNumberFormat
                  />
                </Stack>
              </FormItem>

              <FormItem divider label="借入希望日" required>
                <Grid container>
                  <Grid item {...{ mobile: 12, md: 4 }}>
                    <InputDateTime
                      yearOptions={secondYearOptions}
                      monthOptions={monthOptions}
                      name="main_p_applicant_person.p_land_advance_plan_attributes.loan_desired_borrowing_date"
                      checkRequired
                    />
                  </Grid>
                </Grid>
              </FormItem>

              <FormItem
                label="お借入期間"
                divider
                required
                note={notes.second_loan_term}
                help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
              >
                <Stack
                  direction={{
                    mobile: 'column',
                    tablet: 'row',
                    desktop: 'row',
                  }}
                  spacing={{ mobile: 5, tablet: 10, desktop: 10 }}
                >
                  <Stack direction="row" spacing={4} alignItems="center">
                    <InputField
                      sx={{ width: { tablet: 120, desktop: 120 } }}
                      name="main_p_applicant_person.p_land_advance_plan_attributes.loan_term"
                      align="right"
                      placeholder="0"
                      unit="年"
                      numeric
                      placement="top-start"
                      readOnly
                    />
                  </Stack>
                </Stack>
              </FormItem>

              <FormItem
                label="返済方法"
                required
                divider
                note={notes.second_repayment_method}
              >
                <RadioGroupButton
                  itemPerRow={3}
                  type="noImage"
                  name="main_p_applicant_person.p_land_advance_plan_attributes.repayment_method"
                  options={repaymentOptions}
                  readOnly
                />
              </FormItem>
            </>
          )}
          {/* END MAIN PERSON LAND ADVANCE PLAN */}
          <FormItem
            label=" 担保提供者（共有者）の有無"
            required
            note={notes.join_guarantor}
          >
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="main_p_applicant_person.has_join_guarantor"
              options={coOwnerOptions}
            />
          </FormItem>

          {formik.values.main_p_applicant_person.has_join_guarantor ===
            CoOwnerType.CANBE && (
            <Stack>
              <Divider
                sx={{
                  width: '100%',
                  height: 1,
                  backgroundImage: (theme) =>
                    `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
                  backgroundSize: '6px 9px',
                  backgroundRepeat: 'repeat-x',
                  borderBottomWidth: 'inherit',
                }}
              />

              <FieldArray
                name="main_p_applicant_person.p_join_guarantors_attributes"
                render={(arrayHelpers) => (
                  <Stack>
                    <FormItem
                      required
                      divider
                      label="担保提供者（共有者）の人数"
                    >
                      <Grid container>
                        <Grid item {...{ mobile: 12, md: 5 }}>
                          <Stack
                            sx={{ maxWidth: { mobile: '100%', tablet: 187 } }}
                          >
                            <InputSelect
                              sx={{ minHeight: 50, height: 50 }}
                              name="main_p_applicant_person.join_guarantor_count"
                              options={supplier0ptions}
                              placeholder="ー"
                              unit="人"
                              onChange={(e) => {
                                const value = Number(e.target.value);
                                const join_guarantor_count = Number(
                                  formik.values.main_p_applicant_person
                                    .join_guarantor_count
                                );
                                if (value > join_guarantor_count) {
                                  for (
                                    let i = join_guarantor_count;
                                    i < value;
                                    i++
                                  ) {
                                    arrayHelpers.push({
                                      last_name_kanji: '',
                                      first_name_kanji: '',
                                      last_name_kana: '',
                                      first_name_kana: '',
                                      sex: '',
                                      guarantor_relationship_to_applicant: '',
                                      birthday: '',
                                      mobile_phone_number: '',
                                      home_phone_number: '',
                                      postal_code: '',
                                      prefecture_kanji: '',
                                      city_kanji: '',
                                      district_kanji: '',
                                      other_address_kanji: '',
                                      prefecture_kana: '',
                                      city_kana: '',
                                      district_kana: '',
                                      other_address_kana: '',
                                    });
                                  }
                                } else {
                                  for (
                                    let i = join_guarantor_count;
                                    i > value;
                                    i--
                                  ) {
                                    if (
                                      isEditing &&
                                      !!formik.values.main_p_applicant_person
                                        .p_join_guarantors_attributes[i - 1].id
                                    ) {
                                      formik.setFieldValue(
                                        `main_p_applicant_person.p_join_guarantors_attributes[${
                                          i - 1
                                        }]._destroy`,
                                        true
                                      );
                                    } else {
                                      arrayHelpers.remove(i - 1);
                                    }
                                  }
                                }
                              }}
                              checkRequired
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </FormItem>
                    {formik.values.main_p_applicant_person.p_join_guarantors_attributes
                      .filter((guarantor) => !guarantor._destroy)
                      .map((joinGuarantor, index) => (
                        <Stack key={index}>
                          <Stack>
                            <Stack
                              bgcolor="pale_blue"
                              sx={{
                                width: { mobile: '100%' },
                                mt: 10,
                                py: 5,
                                pl: 5,
                                borderRadius: '5px',
                              }}
                            >
                              <Typography
                                variant="textButtonTopPage"
                                color="normal_text"
                              >
                                下記は「担保提供者」についての入力項目です。
                              </Typography>
                            </Stack>
                          </Stack>
                          <FormItem
                            sx={{ mt: 8 }}
                            divider
                            label="お名前"
                            required
                            note={notes.fullName}
                          >
                            <Grid
                              container
                              spacing={7}
                              direction={{ mobile: 'column', tablet: 'row' }}
                            >
                              <Grid item {...{ mobile: 4 }}>
                                <Typography
                                  sx={{ mb: 1 }}
                                  variant="h3"
                                  color="normal_text"
                                >
                                  姓
                                </Typography>
                                <InputField
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].last_name_kanji`}
                                  placeholder="太郎"
                                />
                              </Grid>
                              <Grid item {...{ mobile: 4 }}>
                                <Typography
                                  sx={{ mb: 1 }}
                                  variant="h3"
                                  color="normal_text"
                                >
                                  名
                                </Typography>
                                <InputField
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].first_name_kanji`}
                                  placeholder="未来"
                                />
                              </Grid>
                            </Grid>
                          </FormItem>

                          <FormItem divider label="お名前（フリガナ）" required>
                            <Grid
                              container
                              spacing={7}
                              direction={{
                                mobile: 'column',
                                tablet: 'row',
                                desktop: 'row',
                              }}
                            >
                              <Grid item {...{ mobile: 6, md: 4 }}>
                                <Typography
                                  sx={{ mb: 1 }}
                                  variant="h3"
                                  color="normal_text"
                                >
                                  姓
                                </Typography>
                                <InputField
                                  convertKatakana
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].last_name_kana`}
                                  placeholder="タロウ"
                                  convertFullWidth
                                />
                              </Grid>
                              <Grid item {...{ mobile: 6, md: 4 }}>
                                <Typography
                                  sx={{ mb: 1 }}
                                  variant="h3"
                                  color="normal_text"
                                >
                                  名
                                </Typography>
                                <InputField
                                  convertKatakana
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].first_name_kana`}
                                  placeholder="ミライ"
                                  convertFullWidth
                                />
                              </Grid>
                            </Grid>
                          </FormItem>

                          <FormItem divider label="性別" required>
                            <RadioGroupButton
                              itemPerRow={3}
                              type="noImage"
                              name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].sex`}
                              options={sexOptions}
                            />
                          </FormItem>

                          <FormItem required divider label="続柄">
                            <Grid container>
                              <Grid item {...{ mobile: 12, md: 5 }}>
                                <Stack
                                  sx={{
                                    maxWidth: { mobile: '100%', tablet: 157 },
                                  }}
                                >
                                  <InputSelect
                                    sx={{ minHeight: 50, height: 50 }}
                                    name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].guarantor_relationship_to_applicant`}
                                    options={guarantorRelationshipOptions}
                                    placeholder="ー"
                                    checkRequired
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                          </FormItem>

                          <FormItem
                            divider
                            label="生年月日"
                            required
                            note={notes.birthday}
                          >
                            <Grid container>
                              <Grid item {...{ mobile: 12, md: 4 }}>
                                <InputDateTime
                                  yearOptions={yearOptions}
                                  monthOptions={monthOptions}
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].birthday`}
                                  checkRequired
                                />
                              </Grid>
                            </Grid>
                          </FormItem>

                          <FormItem
                            divider
                            label="電話番号"
                            required
                            note={notes.phoneNumber}
                          >
                            <Typography
                              sx={{ mb: 8 }}
                              variant="h3"
                              color="normal_text"
                            >
                              携帯電話番号
                            </Typography>
                            <Grid container spacing={7}>
                              <Grid item {...{ mobile: 12, md: 8 }}>
                                <InputField
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].mobile_phone_number`}
                                  placeholder={Placeholder.PHONE_NUMBER}
                                  numeric
                                  convertHalfWidth
                                />
                              </Grid>
                            </Grid>
                            <Typography
                              sx={{ mt: 10, mb: 8 }}
                              variant="h3"
                              color="normal_text"
                            >
                              固定電話番号
                            </Typography>
                            <Grid container spacing={7}>
                              <Grid item {...{ mobile: 12, md: 8 }}>
                                <InputField
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].home_phone_number`}
                                  placeholder={Placeholder.PHONE_NUMBER}
                                  numeric
                                  convertHalfWidth
                                />
                              </Grid>
                            </Grid>
                          </FormItem>
                          <FormItem
                            label="郵便番号"
                            divider
                            note={notes.postCode}
                            required
                          >
                            <Grid container spacing={7}>
                              <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                                <InputField
                                  name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].postal_code`}
                                  placeholder={Placeholder.POSTAL_CODE}
                                  numeric
                                  convertHalfWidth
                                />
                              </Grid>
                              <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                                <Button
                                  sx={{
                                    width: { mobile: '100%', tablet: 187 },
                                    minHeight: 50,
                                    height: 50,
                                    bgcolor: 'h_blugreen',
                                    borderRadius: '4px',
                                  }}
                                  onClick={() =>
                                    handleZipCodeChange(
                                      `main_p_applicant_person.p_join_guarantors_attributes[${index}]`
                                    )
                                  }
                                >
                                  <Typography
                                    variant="text_button_step_required"
                                    color="main_white"
                                  >
                                    郵便番号で検索
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </FormItem>

                          <FormItem
                            divider
                            label="住所（漢字）"
                            required
                            note={notes.address}
                          >
                            <Stack spacing={4}>
                              {formik.values.main_p_applicant_person
                                .p_join_guarantors_attributes[index]
                                .prefecture_kanji && (
                                <Typography
                                  variant="text_input_code"
                                  color="normal_text"
                                  sx={{ mt: '-10px' }}
                                >
                                  {formik.values.main_p_applicant_person
                                    .p_join_guarantors_attributes[index]
                                    .prefecture_kanji +
                                    formik.values.main_p_applicant_person
                                      .p_join_guarantors_attributes[index]
                                      .city_kanji +
                                    formik.values.main_p_applicant_person
                                      .p_join_guarantors_attributes[index]
                                      .district_kanji}
                                </Typography>
                              )}
                              <InputField
                                name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].other_address_kanji`}
                                placeholder={Placeholder.ADDRESS_KANJI}
                              />
                            </Stack>
                          </FormItem>

                          <FormItem
                            divider
                            label="住所（フリガナ）"
                            required
                            note={notes.address}
                          >
                            <Stack spacing={4}>
                              {formik.values.main_p_applicant_person
                                .p_join_guarantors_attributes[index]
                                .prefecture_kana && (
                                <Typography
                                  variant="text_input_code"
                                  color="normal_text"
                                  sx={{ mt: '-10px' }}
                                >
                                  {formik.values.main_p_applicant_person
                                    .p_join_guarantors_attributes[index]
                                    .prefecture_kana +
                                    formik.values.main_p_applicant_person
                                      .p_join_guarantors_attributes[index]
                                      .city_kana +
                                    formik.values.main_p_applicant_person
                                      .p_join_guarantors_attributes[index]
                                      .district_kana}
                                </Typography>
                              )}
                              <InputField
                                name={`main_p_applicant_person.p_join_guarantors_attributes[${index}].other_address_kana`}
                                placeholder={Placeholder.ADDRESS_KANA}
                                convertKatakana
                                convertFullWidth
                              />
                            </Stack>
                          </FormItem>
                        </Stack>
                      ))}
                  </Stack>
                )}
              />
            </Stack>
          )}

          {formik.values.p_application_header.loan_type !== LoanType.ONE &&
            formik.values.p_application_header.loan_type !==
              LoanType.DEFAULT && (
              <>
                <Stack>
                  <Stack
                    bgcolor="pale_blue"
                    sx={{
                      width: { mobile: '100%' },
                      mt: 24,
                      py: 5,
                      pl: 5,
                      borderRadius: '5px',
                    }}
                  >
                    <Typography variant="textButtonTopPage" color="normal_text">
                      {formik.values.p_application_header.loan_type ===
                      LoanType.TWO
                        ? '「ペアローンの申込人」'
                        : '「収入合算」'}
                      の方について入力してください。
                    </Typography>
                  </Stack>
                </Stack>

                <Stack ref={stepThreeDOther}>
                  <FormItem
                    sx={{ mt: 8 }}
                    divider
                    label="お名前（漢字）（全角）"
                    required
                    note={notes.fullName}
                  >
                    <Grid
                      container
                      spacing={7}
                      direction={{
                        mobile: 'column',
                        tablet: 'row',
                        desktop: 'row',
                      }}
                    >
                      <Grid item {...{ mobile: 6, md: 4 }}>
                        <Typography
                          sx={{ mb: 1 }}
                          variant="h3"
                          color="normal_text"
                        >
                          姓
                        </Typography>
                        <InputField
                          name="pair_p_applicant_person.last_name_kanji"
                          placeholder={Placeholder.LAST_NAME_KANJI}
                        />
                      </Grid>
                      <Grid item {...{ mobile: 6, md: 4 }}>
                        <Typography
                          sx={{ mb: 1 }}
                          variant="h3"
                          color="normal_text"
                        >
                          名
                        </Typography>
                        <InputField
                          name="pair_p_applicant_person.first_name_kanji"
                          placeholder={Placeholder.FIRST_NAME_KANJI}
                        />
                      </Grid>
                    </Grid>
                  </FormItem>
                </Stack>

                <FormItem
                  divider
                  label="お名前（フリガナ）（全角カナ）"
                  required
                >
                  <Grid
                    container
                    spacing={7}
                    direction={{
                      mobile: 'column',
                      tablet: 'row',
                      desktop: 'row',
                    }}
                  >
                    <Grid item {...{ mobile: 6, md: 4 }}>
                      <Typography
                        sx={{ mb: 1 }}
                        variant="h3"
                        color="normal_text"
                      >
                        姓
                      </Typography>
                      <InputField
                        convertKatakana
                        name="pair_p_applicant_person.last_name_kana"
                        placeholder={Placeholder.LAST_NAME_KANA}
                        convertFullWidth
                      />
                    </Grid>
                    <Grid item {...{ mobile: 6, md: 4 }}>
                      <Typography
                        sx={{ mb: 1 }}
                        variant="h3"
                        color="normal_text"
                      >
                        名
                      </Typography>
                      <InputField
                        convertKatakana
                        name="pair_p_applicant_person.first_name_kana"
                        placeholder={Placeholder.FIRST_NAME_KANA}
                        convertFullWidth
                      />
                    </Grid>
                  </Grid>
                </FormItem>

                <FormItem divider label="性別" required>
                  <RadioGroupButton
                    itemPerRow={3}
                    type="noImage"
                    name="pair_p_applicant_person.sex"
                    options={sexOptions}
                  />
                </FormItem>

                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  justifyContent="center"
                  alignItems={{ mobile: 'baseline', tablet: 'center' }}
                  spacing={{ mobile: 2, tablet: 8 }}
                  sx={{ mb: { mobile: 8, tablet: 0 } }}
                >
                  <FormItem label="生年月日" required>
                    <Grid container>
                      <Grid item {...{ mobile: 12, md: 4 }}>
                        <InputDateTime
                          yearOptions={yearOptions}
                          monthOptions={monthOptions}
                          name="pair_p_applicant_person.birthday"
                          checkRequired
                        />
                      </Grid>
                    </Grid>
                  </FormItem>

                  <Stack flex={2}>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        mt: { mobile: 0, tablet: 15 },
                        ml: { mobile: 0, tablet: 0 },
                      }}
                    >
                      <Typography variant="text_age" color="normal_text">
                        （ {ageOther + ' '}
                        <Typography
                          variant="landing_submit_button_title"
                          color="normal_text"
                        >
                          歳
                        </Typography>
                        ）
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider
                  sx={{
                    width: '100%',
                    height: 2,
                    backgroundImage: (theme) =>
                      `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
                    backgroundSize: '6px 9px',
                    backgroundRepeat: 'repeat-x',
                    borderBottomWidth: 'inherit',
                  }}
                />

                <FormItem required divider label="続柄">
                  <Grid container>
                    <Grid item {...{ mobile: 12, md: 5 }}>
                      <Stack sx={{ maxWidth: { mobile: '100%', tablet: 187 } }}>
                        <InputSelect
                          sx={{ minHeight: 50, height: 50 }}
                          name="pair_p_applicant_person.relationship_to_applicant"
                          options={relationshipToApplicantOptions}
                          placeholder="ー"
                          checkRequired
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </FormItem>

                <FormItem
                  divider
                  label="電話番号（半角数字）"
                  required
                  note={notes.phoneNumber}
                >
                  <Typography sx={{ mb: 8 }} variant="h3" color="normal_text">
                    携帯電話番号
                  </Typography>
                  <Grid container spacing={7}>
                    <Grid item {...{ mobile: 12, md: 8 }}>
                      <InputField
                        name="pair_p_applicant_person.mobile_phone_number"
                        placeholder={Placeholder.PHONE_NUMBER}
                        numeric
                        convertHalfWidth
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    sx={{ mt: 10, mb: 8 }}
                    variant="h3"
                    color="normal_text"
                  >
                    固定電話番号
                  </Typography>
                  <Grid container spacing={7}>
                    <Grid item {...{ mobile: 12, md: 8 }}>
                      <InputField
                        name="pair_p_applicant_person.home_phone_number"
                        placeholder={Placeholder.PHONE_NUMBER}
                        numeric
                        convertHalfWidth
                      />
                    </Grid>
                  </Grid>
                </FormItem>

                <FormItem
                  label="郵便番号"
                  divider
                  note={notes.postCode}
                  required
                >
                  <Grid container spacing={7}>
                    <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                      <InputField
                        name="pair_p_applicant_person.postal_code"
                        placeholder={Placeholder.POSTAL_CODE}
                        numeric
                        convertHalfWidth
                      />
                    </Grid>
                    <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                      <Button
                        sx={{
                          width: { mobile: '100%', tablet: 187 },
                          minHeight: 50,
                          height: 50,
                          bgcolor: 'h_blugreen',
                          borderRadius: '4px',
                        }}
                        onClick={() =>
                          handleZipCodeChange('pair_p_applicant_person')
                        }
                      >
                        <Typography
                          variant="text_button_step_required"
                          color="main_white"
                        >
                          郵便番号で検索
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </FormItem>

                <FormItem
                  divider
                  label="住所（漢字）"
                  required
                  note={notes.address}
                >
                  <Stack spacing={4}>
                    {formik.values.pair_p_applicant_person.prefecture_kanji && (
                      <Typography
                        variant="text_input_code"
                        color="normal_text"
                        sx={{ mt: '-10px' }}
                      >
                        {formik.values.pair_p_applicant_person
                          .prefecture_kanji +
                          formik.values.pair_p_applicant_person.city_kanji +
                          formik.values.pair_p_applicant_person.district_kanji}
                      </Typography>
                    )}
                    <InputField
                      name="pair_p_applicant_person.other_address_kanji"
                      placeholder={Placeholder.ADDRESS_KANJI}
                    />
                  </Stack>
                </FormItem>

                <FormItem
                  divider
                  label="住所（フリガナ）"
                  required
                  note={notes.address}
                >
                  <Stack spacing={4}>
                    {formik.values.pair_p_applicant_person.prefecture_kana && (
                      <Typography
                        variant="text_input_code"
                        color="normal_text"
                        sx={{ mt: '-10px' }}
                      >
                        {formik.values.pair_p_applicant_person.prefecture_kana +
                          formik.values.pair_p_applicant_person.city_kana +
                          formik.values.pair_p_applicant_person.district_kana}
                      </Typography>
                    )}
                    <InputField
                      name="pair_p_applicant_person.other_address_kana"
                      placeholder={Placeholder.ADDRESS_KANA}
                      convertKatakana
                      convertFullWidth
                    />
                  </Stack>
                </FormItem>

                <FormItem
                  divider
                  label="メールアドレス"
                  note={
                    <UlStyle>
                      <li>
                        <Typography variant="text_note" color="normal_text">
                          {notes.email}
                        </Typography>
                      </li>
                    </UlStyle>
                  }
                  required
                >
                  <Grid container>
                    <Grid item {...{ mobile: 12, md: 8 }}>
                      <InputField
                        name="pair_p_applicant_person.owner_email"
                        placeholder="ー"
                      />
                    </Grid>
                  </Grid>
                </FormItem>

                <FormItem divider label="メールアドレス（確認用）" required>
                  <Grid container>
                    <Grid item {...{ mobile: 12, md: 8 }}>
                      <InputField
                        name="pair_p_applicant_person.confirmation_email"
                        placeholder="ー"
                      />
                    </Grid>
                  </Grid>
                </FormItem>

                {formik.values.p_application_header.loan_type ===
                  LoanType.TWO && (
                  <>
                    <Stack ref={stepOneC}>
                      <FormItem
                        divider
                        label="お借入希望額"
                        required
                        note={notes.desired_loan_amount}
                      >
                        <Stack direction="row">
                          <InputField
                            sx={{ width: { mobile: '100%', tablet: 300 } }}
                            name="pair_p_applicant_person.p_borrowing_detail_attributes.temporary_desired_loan_amount"
                            align="right"
                            placeholder="0"
                            unit="万円"
                            numeric
                            useNumberFormat
                          />
                        </Stack>
                      </FormItem>
                    </Stack>

                    <FormItem
                      divider
                      label="内ボーナス払い（半年毎増額返済金額）"
                      required
                      note={notes.halfyear_bonus}
                      explain={
                        <Stack sx={{ mt: 7 }}>
                          <Stack
                            direction={{ mobile: 'column', tablet: 'row' }}
                            spacing="25px"
                            sx={{
                              bgcolor: 'main_white',
                              py: 8,
                              px: { mobile: 4, tablet: 7 },
                              borderRadius: '10px',
                            }}
                          >
                            <Button
                              sx={{
                                bgcolor: 'h_blugreen',
                                py: 3,
                                pl: { mobile: 5, tablet: 5 },
                                pr: { mobile: 5, tablet: 9 },
                                borderRadius: '10px',
                              }}
                            >
                              <Stack
                                direction={{ mobile: 'column', tablet: 'row' }}
                                spacing={8}
                                alignItems={{ mobile: 'center' }}
                              >
                                <Avatar
                                  sx={{
                                    borderRadius: 0,
                                    width: 114,
                                    height: 81,
                                  }}
                                  src={loanTax}
                                >
                                  <Typography
                                    variant="text_title"
                                    lineHeight="42px"
                                  >
                                    イメージ
                                  </Typography>
                                </Avatar>
                                <Stack justifyContent="center">
                                  <Typography
                                    variant="text_accordion_status"
                                    color="main_white"
                                    lineHeight="36px"
                                  >
                                    住宅ローンシミュレーション
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Button>
                            <Stack>
                              <Typography variant="textstyle" lineHeight="24px">
                                住宅ローンを借りると、
                                <br />
                                <Typography variant="checkbox_button_R">
                                  毎月いくらの返済なの？
                                  <br />
                                  総返済額は？
                                  <br />
                                </Typography>
                                そんな疑問にお答えします！
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      }
                    >
                      <Stack direction="row">
                        <InputField
                          sx={{ width: { mobile: '100%', tablet: 300 } }}
                          name="pair_p_applicant_person.p_borrowing_detail_attributes.halfyear_bonus"
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem
                      label="お借入期間"
                      divider
                      required
                      help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
                      note={
                        <Stack>
                          <Stack>
                            <Typography variant="text_note" color="normal_text">
                              {notes.loan_term}
                            </Typography>
                          </Stack>
                          <Stack direction="row" sx={{ pt: 1 }}>
                            <Typography variant="text_note" color="normal_text">
                              ※借り換えの場合は
                            </Typography>
                            <IconButton
                              sx={{
                                color: 'Icon_color',
                                p: 0,
                                mt: -1,
                              }}
                            >
                              <Icons.Question sx={{ width: 25, height: 25 }} />
                            </IconButton>
                            <Typography variant="text_note" color="normal_text">
                              をご覧ください。
                            </Typography>
                          </Stack>
                        </Stack>
                      }
                    >
                      <Stack
                        direction={{
                          mobile: 'column',
                          tablet: 'row',
                          desktop: 'row',
                        }}
                        spacing={{ mobile: 5, tablet: 10, desktop: 10 }}
                      >
                        <Stack direction="row" spacing={4} alignItems="center">
                          <InputField
                            sx={{ width: { tablet: 120, desktop: 120 } }}
                            name="pair_p_applicant_person.p_borrowing_detail_attributes.loan_term"
                            align="right"
                            placeholder="0"
                            unit="年"
                            numeric
                            placement="top-start"
                            convertHalfWidth
                          />
                        </Stack>
                      </Stack>
                    </FormItem>

                    <FormItem
                      label="返済方法"
                      required
                      divider
                      help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
                    >
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name="pair_p_applicant_person.p_borrowing_detail_attributes.repayment_method"
                        options={repaymentOptions}
                      />
                    </FormItem>
                  </>
                )}
              </>
            )}

          <Stack alignItems="center" sx={{ mt: 37, mb: 11 }} spacing={9}>
            <Typography variant="h3" color="normal_text">
              「申込人ご本人」様に入力していただいた事のご確認
            </Typography>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 12 }}>
                <CheckboxButton
                  name="confirm_self_entered"
                  fullWidth
                  sx={{
                    minHeight: 60,
                    width: { tablet: 400 },
                    m: '0 auto',
                    justifyContent: 'left',
                  }}
                  startIconStyle={{ left: 14, top: 19 }}
                >
                  <Stack direction="row" sx={{ pl: 12 }}>
                    <Typography
                      variant="checkbox_button_R"
                      fontSize={{ mobile: 15, tablet: 20 }}
                    >
                      「申込人ご本人」が入力しました
                    </Typography>
                  </Stack>
                </CheckboxButton>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </FormikProvider>
    </StepLayout>
  );
};

export default StepOneRequiredInformationPage;

const notes = {
  loan_type:
    '※「ペアローン」「収入合算（持分あり）」「収入合算（持分なし）」を選択した場合、日本住宅ローンでは「連帯債務」のお借入としてお取り扱いいたします。',
  fullName:
    '※外国籍のかたは、在留カード通りに記入ください。本入力欄にフルネームが入力しきれない場合は、\n本欄にはお名前を途中までご入力いただき、下記の特記事項欄にフルネームをご入力ください。\n※お名前の漢字が外字等で変換できない場合は常用漢字でご入力ください。',
  phoneNumber:
    '※携帯電話番号か固定電話番号のどちらかに必ず入力してください。\n※ハイフンを抜かしてご入力ください。',
  birthday:
    '※原則として購入する物件の売買代金や請負工事代金の支払日がお借入れ希望日となります。',
  email:
    'お申込み完了や事前審査結果等のメールを送信させていただきます。ご本人さま以外の方がご覧になる可能性のあるメールアドレスは入力しないでください。\nwebmaster@milibank.co.jpからのメールを受信できるよう事前に設定してください。',
  postCode: '※ハイフンを抜かしてご入力ください。',
  address:
    '※自動入力された住所の続き（番地・建物名・部屋番号など）を入力してください。\n（例:６－１泉マンション１０８）',
  desired_loan_amount: '※借入金額は10万円単位となります。',
  halfyear_bonus:
    '※毎月の返済に加えて半年毎（6ヵ月毎）に増額返済額をお支払いいただくボーナス払い（半年毎増額返済）を選択する場合は金額を記入してください。',
  loan_term: '※新築の場合は1年以上35年以内になります。',
  request:
    '土地購入と建物新築など2回に分けて融資実行を希望 される場合のみ「有」をご選択ください。',
  join_guarantor:
    '※申込人・ペアローンの申込人・収入合算者・連帯債務者以外で、土地または建物に持分を持つ共有者は、担保提供者となっていただきます。',
  second_loan_term:
    '※「2本目融資」のお借入期間は「1本目融資」と同一になります。',
  second_repayment_method:
    '※「2本目融資」の返済方法は「1本目融資」と同一になります。',
};

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(63), (_, index) => {
    const year = zeroPad(dayjs().year() - 18 - index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);

const secondYearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(6), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);

const coOwnerOptions: Array<RadioItems> = [
  {
    value: CoOwnerType.CANBE,
    label: joinGuarantor[CoOwnerType.CANBE],
  },
  {
    value: CoOwnerType.NONE,
    label: joinGuarantor[CoOwnerType.NONE],
  },
];

const supplier0ptions: InputSelectProps['options'] = [
  {
    label: joinGuarantorCount[SupplierType.ONE],
    value: joinGuarantorCount[SupplierType.ONE],
  },
  {
    label: joinGuarantorCount[SupplierType.TWO],
    value: joinGuarantorCount[SupplierType.TWO],
  },
  {
    label: joinGuarantorCount[SupplierType.THREE],
    value: joinGuarantorCount[SupplierType.THREE],
  },
];

const landAdvanceOptions: Array<RadioItems> = [
  {
    value: LandAdvanceType.YES,
    label: landAdvance[LandAdvanceType.YES],
  },
  {
    value: LandAdvanceType.NO,
    label: landAdvance[LandAdvanceType.NO],
  },
];
