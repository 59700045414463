import { Stack, SxProps, Typography } from '@mui/material';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useMatchedRoute } from 'hooks';
import { SPSaveDraftButton } from 'containers/SPSaveDraftButton';
import { Icons } from 'components';
import { useSelector } from 'react-redux';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { routeNames } from 'navigations/routes';
import { isMobile } from 'react-device-detect';
import { SPHeader, SPFooter, SPFooterProps } from './components';

type LayoutProps = PropsWithChildren<{
  footer?: SPFooterProps;
  hasHeader?: boolean;
  hasFooter?: boolean;
  hasStepBar?: boolean;
  hasMenu?: boolean;
  onSaveDraft?: () => void;
  title?: string;
  hasModalSaveDraft?: boolean;
  sx?: SxProps;
  footerLogo?: boolean;
  hasJoinGuarantor?: boolean;
  hasTotalIncome?: boolean;
  onSaveForm?: () => void;
  isAgreement?: boolean;
}>;

export const SPStepLayout: FC<LayoutProps> = ({
  children,
  footer,
  hasHeader = true,
  hasFooter = true,
  hasStepBar = true,
  hasMenu = true,
  onSaveDraft,
  hasModalSaveDraft = true,
  sx,
  footerLogo,
  hasJoinGuarantor = false,
  hasTotalIncome = false,
  onSaveForm,
  isAgreement = false,
}) => {
  const currentRoute = useMatchedRoute();
  const { application_number } = useSelector(spStepTenSelector);

  const checkCurrentStep = useMemo(() => {
    if (hasTotalIncome && hasJoinGuarantor) {
      switch (currentRoute?.path) {
        case routeNames.SPStepFourCollateralProvider.path:
          return 6;
        case routeNames.SPStepFour.path:
          return 7;
        case routeNames.SPStepFive.path:
          return 8;
        case routeNames.SPStepSix.path:
          return 9;
        case routeNames.SPStepSeven.path:
          return 10;
        case routeNames.SPStepSevenIncomeTotalizer.path:
          return 11;
        case routeNames.SPStepEight.path:
          return 12;
        case routeNames.SPStepNine.path:
          return 13;
        default:
          break;
      }
    }
    if (hasTotalIncome) {
      switch (currentRoute?.path) {
        case routeNames.SPStepFour.path:
          return 6;
        case routeNames.SPStepFive.path:
          return 7;
        case routeNames.SPStepSix.path:
          return 8;
        case routeNames.SPStepSeven.path:
          return 9;
        case routeNames.SPStepEight.path:
          return 11;
        case routeNames.SPStepNine.path:
          return 12;
        default:
          break;
      }
    }
    if (hasJoinGuarantor) {
      switch (currentRoute?.path) {
        case routeNames.SPStepFour.path:
          return 5;
        case routeNames.SPStepFive.path:
          return 6;
        case routeNames.SPStepSix.path:
          return 7;
        case routeNames.SPStepSeven.path:
          return 8;
        case routeNames.SPStepEight.path:
          return 9;
        case routeNames.SPStepNine.path:
          return 10;
        default:
          break;
      }
    }
    return currentRoute?.stepNumber;
  }, [
    currentRoute?.path,
    currentRoute?.stepNumber,
    hasJoinGuarantor,
    hasTotalIncome,
  ]);

  if (!currentRoute) return null;
  return (
    <Stack
      sx={{
        '@media (orientation: landscape)': {
          maxWidth: isMobile ? 'none' : 480,
        },
        maxWidth: 480,
        m: '0 auto',
        position: 'relative',
      }}
    >
      {hasHeader && (
        <>
          <SPHeader
            isAgreement={isAgreement}
            hasMenu={hasMenu}
            hasStepBar={hasStepBar}
            currentStep={checkCurrentStep ?? 0}
            hasJoinGuarantor={hasJoinGuarantor}
            hasTotalIncome={hasTotalIncome}
            onSaveForm={onSaveForm}
          />
          <Stack height={hasStepBar ? 105 : 41} />
        </>
      )}
      <Stack sx={{ bgcolor: 'bg_off' }}>
        <Stack
          sx={{
            bgcolor: 'sp_bg_gray',
            height: '100%',
            flex: 1,
            ...sx,
          }}
        >
          {children}
        </Stack>
      </Stack>
      {hasModalSaveDraft && !application_number && (
        <SPSaveDraftButton onSaveDraft={onSaveDraft} />
      )}
      <Stack height={hasFooter ? 72 : 0} />
      {hasFooter && <SPFooter {...footer} />}
      {!hasFooter && footerLogo && (
        <Stack bgcolor="sp_bg_gray" p={6} spacing={3}>
          <Icons.SPMilize sx={{ width: 148, height: 32 }} />
          <Typography
            color="sp_logo_icon"
            variant="sp_footer_bank_name"
            sx={{ opacity: 0.6 }}
          >
            © 2023 みらいバンク Inc.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
