import { FC, useState } from 'react';
import { Avatar, Modal, Stack, Typography } from '@mui/material';
import 'react-html5-camera-photo/build/css/index.css';
import { Icons, SPDeleteModal } from 'components';
import { ScrollMode, Viewer, Worker } from '@react-pdf-viewer/core';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Button } from '../../atoms';

export type SPImageDetailModalProps = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  src: string;
  type: string;
};

export const SPImageDetailModal: FC<SPImageDetailModalProps> = ({
  open,
  onClose,
  onDelete,
  src,
  type,
}) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const handleDelete = () => {
    onClose();
    setOpenDelete(false);
    onDelete();
  };

  const scrollModePluginInstance = scrollModePlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;
  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
    >
      <Stack
        sx={{
          width: '100%',
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'main_white',
          px: 8,
          py: 8,
        }}
        alignItems="center"
        spacing={8}
      >
        {type.includes('pdf') ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 344, height: 413, ml: 8 }}
          >
            <Worker workerUrl="/pdf.worker.bundle.js">
              <Viewer
                fileUrl={src}
                plugins={[
                  scrollModePluginInstance,
                  pageNavigationPluginInstance,
                ]}
                scrollMode={ScrollMode.Page}
              />
            </Worker>
            <Stack>
              <GoToPreviousPage />
              <GoToNextPage />
            </Stack>
          </Stack>
        ) : (
          <Avatar
            variant="square"
            src={src}
            sx={{
              width: '100%',
              height: 246,
              '.MuiAvatar-img': {
                objectFit: 'contain',
              },
            }}
          />
        )}
        <Stack
          spacing={6}
          sx={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translate(-50%,0%)',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              width: '240px',
              minHeight: '40px',
              height: '40px',
              bgcolor: 'main_white',
              borderRadius: '14px',
              position: 'relative',
              border: (theme) =>
                `1px solid ${theme?.palette?.sp_primary_100_main}`,
              boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
            }}
            onClick={onClose}
          >
            <Icons.SPBack
              sx={{
                position: 'absolute',
                left: 0,
              }}
            />
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
              textAlign="center"
              width="100%"
            >
              もどる
            </Typography>
          </Stack>
          <Button
            sx={{
              width: '240px',
              minHeight: '40px',
              height: '40px',
              bgcolor: 'main_white',
              borderRadius: '14px',
              border: (theme) =>
                `1px solid ${theme?.palette?.sp_primary_100_main}`,
              boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
            }}
            onClick={() => setOpenDelete(true)}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Icons.SPTrash />
              <Typography
                variant="SP_multiple_checkbox_label"
                color="sp_primary_100_main"
              >
                この画像を削除する
              </Typography>
            </Stack>
          </Button>
        </Stack>
        <SPDeleteModal
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          onDelete={handleDelete}
        />
      </Stack>
    </Modal>
  );
};
