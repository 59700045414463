import { Close as CloseIcon } from '@mui/icons-material';
import { Modal as MuiModal, Stack, Typography } from '@mui/material';
import { Button } from 'components/atoms';
import { FC, createRef, useImperativeHandle, useState } from 'react';

type AdminModalEditFormRef = {
  open: () => // title: string
  // errorsUpdateForm: ErrorsUpdateForm[],
  // id: string
  // isManager: boolean,
  // dataUpdate?: EditAdminPreliminariesRequest
  void;
  close: () => void;
};

export const adminModalEditFormRef = createRef<AdminModalEditFormRef>();

export const AdminModalEditForm: FC = () => {
  // const [title, setTitle] = useState<string>('');
  // const [id, setId] = useState<string>('');
  // const [dataUpdate, setDataUpdate] = useState<EditAdminPreliminariesRequest>();
  // const [errorsUpdateForm, setErrorsUpdateForm] = useState<ErrorsUpdateForm[]>(
  // []
  // );
  const [open, setOpen] = useState<boolean>(false);
  // const dynamicOptions = useSelector(adminDynamicOptionsSelectors);

  useImperativeHandle(adminModalEditFormRef, () => ({
    open() {
      // setTitle(title);
      // setId(id);
      // setDataUpdate(dataUpdate);
      // setErrorsUpdateForm(errorsUpdateForm);
      setOpen(true);
    },
    close() {
      setOpen(false);
      // setTitle('');
      // setId('');
      // setErrorsUpdateForm([]);
    },
  }));

  const onClose = () => {
    window.location.reload();
    setOpen(false);
  };

  // const onOverUpdate = async () => {
  //   const newValue = {
  //     ...dataUpdate,
  //     p_application_header: {
  //       ...dataUpdate?.p_application_header,
  //       sbi_id: sbiId,
  //       p_application_banks_attributes:
  //         dataUpdate?.p_application_header?.p_application_banks_attributes?.find(
  //           (bank) => !bank.id || !!bank._destroy
  //         ) ?? [],
  //     },
  //   };
  //   const result = await dispatch(
  //     // @ts-ignore
  //     editAdminPreliminary({
  //       id: id,
  //       ...newValue,
  //     })
  //   );
  //   setOpen(false);
  //   if (editAdminPreliminary.fulfilled.match(result)) {
  //     toast.success('申込内容を更新しました。');
  //   }
  // };

  // const getLabel = (tableName: string, keyField: string) => {
  //   const stepObject = FIELD_VALIDATE_SP.find((object) =>
  //     object.fields.find(
  //       (field) => field.source === tableName && keyField === field.key
  //     )
  //   );
  //   const valueObject = stepObject?.fields.find(
  //     (field) => field.key === keyField
  //   );
  //   return valueObject;
  // };

  return (
    <MuiModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={onClose}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '28%',
          minWidth: 350,
          backgroundColor: 'main_white',
          '@media (orientation: landscape)': {
            minWidth: 'auto',
            maxHeight: '75vh',
          },
          borderRadius: 2,
          paddingBottom: 8,
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <CloseIcon
            onClick={onClose}
            fontSize="medium"
            sx={{
              color: 'b_333',
              cursor: 'pointer',
              pb: 2,
              pt: 2,
              pr: 2,
              width: 20,
              height: 20,
            }}
          />
        </Stack>

        <Stack alignItems="center">
          <Typography
            variant="text_note"
            color="b_333"
            sx={{ textAlign: 'center', px: 3 }}
          >
            {
              'データ更新がありました。\n最新データを確認した上で修正してください。'
            }
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ mt: 5, px: 5 }}>
          <Button
            onClick={onClose}
            sx={{
              bgcolor: 'white',
              boxShadow: 'none',
              width: '150px',
              height: '36px',
              borderRadius: '2px',
              minHeight: '36px',
              border: '1px solid #6B70F0',
            }}
          >
            <Typography variant="button01" color="sp_primary_100_main">
              とじる
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </MuiModal>
  );
};
