import {
  BusinessBorrowingType,
  FundsPairOffType,
  MESSAGE_REGEX,
  SP_FIELD_ERROR_MESSAGE,
  SPBorrowingType,
  SPLoanPurposeType,
  SPScheduledLoanPayoffType,
} from 'constant';
import { yup, regex } from 'libs';
import { messageOverMaxNumber } from 'utils';
import { SPBorrowingAttribute } from 'types';

export const validationSchema = yup.object().shape(
  {
    p_applicant_people_attributes: yup.array().of(
      yup.object().shape(
        {
          borrowing_status: yup.string(),
          land_rent_to_be_paid: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999999, messageOverMaxNumber(99999999)),
          house_rent_to_be_paid: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999999, messageOverMaxNumber(99999999)),
          land_rent_to_be_paid_borrower: yup.string(),
          house_rent_to_be_paid_borrower: yup.string(),
        },
        []
      )
    ),
    p_borrowings_attributes: yup.array(
      yup.object().shape({
        borrower: yup.string(),
        borrowing_type: yup
          .string()
          .when(['_destroy', 'self_input'], {
            is: (_destroy: boolean, self_input: boolean) =>
              !_destroy && self_input,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
          })
          .label(SP_FIELD_ERROR_MESSAGE.borrowing_type),
        self_input: yup.boolean(),
        lender: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_from_housing_finance_agency: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_start_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_CARD_CONTRACT_DATE,
            //   test(value) {
            //     if (!value || !this.parent.card_expiry_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(
            //       dayjs(this.parent.card_expiry_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_LOAN_DATE,
            //   test(value) {
            //     if (!value || !this.parent.loan_deadline_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(
            //       dayjs(this.parent.loan_deadline_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SPECIFY_IN_THE_PAST_PERIOD,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(dayjs(), 'month');
            //   },
            // })
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.CARD_LOAN,
              then: (self) => self.label('当初カード契約年月'),
              otherwise: (self) => self.label('当初借入年月'),
            }),
        }),
        loan_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        current_balance_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        annual_repayment_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        loan_deadline_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_LOAN_DATE,
            //   test(value) {
            //     if (!value || !this.parent.loan_start_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(
            //       dayjs(this.parent.loan_start_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type !== SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.CARD_LOAN,
              then: (self) => self.label('当初カード契約年月'),
              otherwise: (self) => self.label('当初借入年月'),
            }),
        }),
        scheduled_loan_payoff: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type !== SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_loan_purpose),
        }),
        other_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            // .matches(
            //   regex.kanjiFullwidthHaveNumber,
            //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            // )
            .when('loan_purpose', {
              is: (loan_purpose: SPLoanPurposeType) =>
                loan_purpose === SPLoanPurposeType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        business_borrowing_type: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_LOAN_PURPOSE),
          }),
        }),
        specific_loan_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('business_borrowing_type', {
              is: (business_borrowing_type: BusinessBorrowingType) =>
                business_borrowing_type === BusinessBorrowingType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_category: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        card_expiry_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_CARD_CONTRACT_DATE,
            //   test(value) {
            //     if (!value || !this.parent.loan_start_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(
            //       dayjs(this.parent.loan_start_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SPECIFY_IN_THE_FUTURE_PERIOD,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_card_expiry_date),
        }),
        rental_room_number: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        common_housing: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        estate_mortgage: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input &&
              borrowing_type !== SPBorrowingType.CARD_LOAN &&
              borrowing_type !== SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        expected_repayment_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when('scheduled_loan_payoff', {
              is: (scheduled_loan_payoff: SPScheduledLoanPayoffType) =>
                scheduled_loan_payoff ===
                SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_expected_repayment_date),
        }),
      })
    ),
    completely_repayment_type: yup.string(),
    other_complete_repayment_type: yup
      .string()
      .when('p_borrowings_attributes', {
        is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
          p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .when('completely_repayment_type', {
            is: (completely_repayment_type: FundsPairOffType) =>
              completely_repayment_type === FundsPairOffType.OTHERS,
            then: yup.string().required(MESSAGE_REGEX.REQUIRED),
          }),
      }),
    refund_content: yup.string().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .string()
        .max(20)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
    }),
    refund_amount: yup.number().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(99999, messageOverMaxNumber(99999)),
    }),
  },
  []
);

export const incomeTotalValidationSchema = yup.object().shape(
  {
    p_applicant_people_attributes: yup.array().of(
      yup.object().shape(
        {
          borrowing_status: yup.string(),
        },
        []
      )
    ),
    p_borrowings_attributes: yup.array(
      yup.object().shape({
        borrower: yup.string(),
        borrowing_type: yup
          .string()
          .when(['_destroy', 'self_input'], {
            is: (_destroy: boolean, self_input: boolean) =>
              !_destroy && self_input,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
          })
          .label(SP_FIELD_ERROR_MESSAGE.borrowing_type),
        self_input: yup.boolean(),
        lender: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_from_housing_finance_agency: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_start_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_LOAN_DATE,
            //   test(value) {
            //     if (!value || !this.parent.loan_deadline_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(
            //       dayjs(this.parent.loan_deadline_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_CARD_CONTRACT_DATE,
            //   test(value) {
            //     if (!value || !this.parent.card_expiry_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(
            //       dayjs(this.parent.card_expiry_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SPECIFY_IN_THE_PAST_PERIOD,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(dayjs(), 'month');
            //   },
            // })
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.CARD_LOAN,
              then: (self) => self.label('当初カード契約年月'),
              otherwise: (self) => self.label('当初借入年月'),
            }),
        }),
        loan_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        current_balance_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        annual_repayment_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        loan_deadline_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_LOAN_DATE,
            //   test(value) {
            //     if (!value || !this.parent.loan_start_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(
            //       dayjs(this.parent.loan_start_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type !== SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.HOUSING_LOAN,
              then: (self) => self.label('最終期限'),
              otherwise: (self) => self.label('最終返済年月'),
            }),
        }),
        scheduled_loan_payoff: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type !== SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_purpose: yup
          .string()
          .when('_destroy', {
            is: (_destroy: boolean) => !_destroy,
            then: yup.string().when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            }),
          })
          .label(SP_FIELD_ERROR_MESSAGE.borrowing_loan_purpose),
        other_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            // .matches(
            //   regex.kanjiFullwidthHaveNumber,
            //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            // )
            .when('loan_purpose', {
              is: (loan_purpose: SPLoanPurposeType) =>
                loan_purpose === SPLoanPurposeType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        business_borrowing_type: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_LOAN_PURPOSE),
          }),
        }),
        specific_loan_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('business_borrowing_type', {
              is: (business_borrowing_type: BusinessBorrowingType) =>
                business_borrowing_type === BusinessBorrowingType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_category: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        card_expiry_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message:
            //     MESSAGE_REGEX.SPECIFY_YEAR_MONTH_OF_LOAN_AFTER_ORIGINAL_CARD_CONTRACT_DATE,
            //   test(value) {
            //     if (!value || this.parent.loan_start_date) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(
            //       dayjs(this.parent.loan_start_date),
            //       'month'
            //     );
            //   },
            // })
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SPECIFY_IN_THE_FUTURE_PERIOD,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
                .label(SP_FIELD_ERROR_MESSAGE.borrowing_card_expiry_date),
            }),
        }),
        rental_room_number: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        common_housing: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        estate_mortgage: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input &&
              borrowing_type !== SPBorrowingType.CARD_LOAN &&
              borrowing_type !== SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        expected_repayment_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when('scheduled_loan_payoff', {
              is: (scheduled_loan_payoff: SPScheduledLoanPayoffType) =>
                scheduled_loan_payoff ===
                SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_expected_repayment_date),
        }),
      })
    ),
    completely_repayment_type: yup.string(),
    other_complete_repayment_type: yup
      .string()
      .when('p_borrowings_attributes', {
        is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
          p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .when('completely_repayment_type', {
            is: (completely_repayment_type: FundsPairOffType) =>
              completely_repayment_type === FundsPairOffType.OTHERS,
            then: yup.string().required(MESSAGE_REGEX.REQUIRED),
          }),
      }),
    refund_content: yup.string().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .string()
        .max(20)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
    }),
    refund_amount: yup.number().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(99999, messageOverMaxNumber(99999)),
    }),
    land_rent_to_be_paid: yup
      .number()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .integer()
      .min(0)
      .max(99999, messageOverMaxNumber(99999)),
    house_rent_to_be_paid: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(99999, messageOverMaxNumber(99999)),
    land_rent_to_be_paid_borrower: yup.string().when('land_rent_to_be_paid', {
      is: (land_rent_to_be_paid: number, _destroy: boolean) =>
        !!land_rent_to_be_paid && !_destroy,
      then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
    }),
    house_rent_to_be_paid_borrower: yup
      .string()
      .when(['house_rent_to_be_paid', '_destroy'], {
        is: (house_rent_to_be_paid: number, _destroy: boolean) =>
          !!house_rent_to_be_paid && !_destroy,
        then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
      }),
  },
  []
);

// key MCJ
export const validationSchemaMCJ = yup.object().shape(
  {
    p_applicant_people_attributes: yup.array().of(
      yup.object().shape(
        {
          borrowing_status: yup.string(),
          land_rent_to_be_paid: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999999, messageOverMaxNumber(99999999)),
          // .test({
          //   message: MESSAGE_REGEX.REQUIRED,
          //   test(value, testContext) {
          //     if (!value) {
          //       const check_required =
          //         // @ts-ignore
          //         testContext.from[1].value.p_borrowings_attributes.some(
          //           (borrowing: SPBorrowingAttribute) =>
          //             borrowing.self_input &&
          //             borrowing.scheduled_loan_payoff ===
          //               SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
          //         );
          //       return !check_required;
          //     }
          //     return true;
          //   },
          // }),
          house_rent_to_be_paid: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999999, messageOverMaxNumber(99999999)),
          // .test({
          //   message: MESSAGE_REGEX.REQUIRED,
          //   test(value, testContext) {
          //     if (!value) {
          //       const check_required =
          //         // @ts-ignore
          //         testContext.from[1].value.p_borrowings_attributes.some(
          //           (borrowing: SPBorrowingAttribute) =>
          //             borrowing.self_input &&
          //             borrowing.scheduled_loan_payoff ===
          //               SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
          //         );
          //       return !check_required;
          //     }
          //     return true;
          //   },
          // }),
          land_rent_to_be_paid_borrower: yup.string(),
          house_rent_to_be_paid_borrower: yup.string(),
        },
        []
      )
    ),
    p_borrowings_attributes: yup.array(
      yup.object().shape({
        borrower: yup.string(),
        borrowing_type: yup
          .string()
          .when(['_destroy', 'self_input'], {
            is: (_destroy: boolean, self_input: boolean) =>
              !_destroy && self_input,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
          })
          .label(SP_FIELD_ERROR_MESSAGE.borrowing_type),
        self_input: yup.boolean(),
        lender: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_from_housing_finance_agency: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_start_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.CARD_LOAN,
              then: (self) => self.label('当初カード契約年月'),
              otherwise: (self) => self.label('当初借入年月'),
            }),
        }),
        loan_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        current_balance_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        annual_repayment_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        loan_deadline_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type !== SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.HOUSING_LOAN,
              then: (self) => self.label('最終期限'),
              otherwise: (self) => self.label('最終返済年月'),
            }),
        }),
        scheduled_loan_payoff: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type !== SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_loan_purpose),
        }),
        other_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            // .matches(
            //   regex.kanjiFullwidthHaveNumber,
            //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            // )
            .when('loan_purpose', {
              is: (loan_purpose: SPLoanPurposeType) =>
                loan_purpose === SPLoanPurposeType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        business_borrowing_type: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_LOAN_PURPOSE),
          }),
        }),
        specific_loan_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('business_borrowing_type', {
              is: (business_borrowing_type: BusinessBorrowingType) =>
                business_borrowing_type === BusinessBorrowingType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_category: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        card_expiry_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_REQUIRED),
          }),
        }),
        rental_room_number: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
              then: yup
                .number()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_card_expiry_date),
        }),
        common_housing: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        estate_mortgage: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input &&
              borrowing_type !== SPBorrowingType.CARD_LOAN &&
              borrowing_type !== SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        expected_repayment_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when('scheduled_loan_payoff', {
              is: (scheduled_loan_payoff: SPScheduledLoanPayoffType) =>
                scheduled_loan_payoff ===
                SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_expected_repayment_date),
        }),
      })
    ),
    completely_repayment_type: yup.string().when(['p_borrowings_attributes'], {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some(
          (borrowing) =>
            borrowing.scheduled_loan_payoff ===
              SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY &&
            !borrowing._destroy
        ),
      then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
    }),
    other_complete_repayment_type: yup
      .string()
      .when('p_borrowings_attributes', {
        is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
          p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .when('completely_repayment_type', {
            is: (completely_repayment_type: FundsPairOffType) =>
              completely_repayment_type === FundsPairOffType.OTHERS,
            then: yup.string().required(MESSAGE_REGEX.REQUIRED),
          }),
      }),
    refund_content: yup.string().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .string()
        .max(20)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
      // .when('p_borrowings_attributes', {
      //   is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
      //     p_borrowings_attributes.some((borrowing) => borrowing.self_input),
      //   then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
      // }),
    }),
    refund_amount: yup.number().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(99999, messageOverMaxNumber(99999)),
      // .when('p_borrowings_attributes', {
      //   is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
      //     p_borrowings_attributes.some((borrowing) => borrowing.self_input),
      //   then: yup.number().required(MESSAGE_REGEX.REQUIRED),
      // }),
    }),
  },
  []
);

export const incomeTotalValidationSchemaMCJ = yup.object().shape(
  {
    p_applicant_people_attributes: yup.array().of(
      yup.object().shape(
        {
          borrowing_status: yup.string(),
        },
        []
      )
    ),
    p_borrowings_attributes: yup.array(
      yup.object().shape({
        borrower: yup.string(),
        borrowing_type: yup
          .string()
          .when(['_destroy', 'self_input'], {
            is: (_destroy: boolean, self_input: boolean) =>
              !_destroy && self_input,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
          })
          .label(SP_FIELD_ERROR_MESSAGE.borrowing_type),
        self_input: yup.boolean(),
        lender: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_from_housing_finance_agency: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_start_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrBefore(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.CARD_LOAN,
              then: (self) => self.label('当初カード契約年月'),
              otherwise: (self) => self.label('当初借入年月'),
            }),
        }),
        loan_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        current_balance_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        annual_repayment_amount: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(99999, messageOverMaxNumber(99999))
            .when('self_input', {
              is: (self_input: boolean) => self_input,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        loan_deadline_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type !== SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.HOUSING_LOAN,
              then: (self) => self.label('最終期限'),
              otherwise: (self) => self.label('最終返済年月'),
            }),
        }),
        scheduled_loan_payoff: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type !== SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        loan_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_loan_purpose),
        }),
        other_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            // .matches(
            //   regex.kanjiFullwidthHaveNumber,
            //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            // )
            .when('loan_purpose', {
              is: (loan_purpose: SPLoanPurposeType) =>
                loan_purpose === SPLoanPurposeType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        business_borrowing_type: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.BUSINESS_LOAN,
            then: yup.string().required(MESSAGE_REGEX.DROPDOWN_LOAN_PURPOSE),
          }),
        }),
        specific_loan_purpose: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
            )
            .when('business_borrowing_type', {
              is: (business_borrowing_type: BusinessBorrowingType) =>
                business_borrowing_type === BusinessBorrowingType.OTHERS,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        borrowing_category: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        card_expiry_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_card_expiry_date),
        }),
        rental_room_number: yup.number().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .when(['self_input', 'borrowing_type'], {
              is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
                self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
              then: yup.number().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        common_housing: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input && borrowing_type === SPBorrowingType.APARTMENT_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        estate_mortgage: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup.string().when(['self_input', 'borrowing_type'], {
            is: (self_input: boolean, borrowing_type: SPBorrowingType) =>
              self_input &&
              borrowing_type !== SPBorrowingType.CARD_LOAN &&
              borrowing_type !== SPBorrowingType.HOUSING_LOAN,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        }),
        expected_repayment_date: yup.string().when('_destroy', {
          is: (_destroy: boolean) => !_destroy,
          then: yup
            .string()
            // .test({
            //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            //   test(value) {
            //     if (!value) return true;
            //     const [year, month] = value.split('/');
            //     const endTime = dayjs(`${year}-${month}`);
            //
            //     return endTime.isSameOrAfter(dayjs(), 'month');
            //   },
            // })
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month] = value ? value.split('/') : ['', ''];
                return (!!year && !!month) || (!year && !month);
              }
            )
            .when('scheduled_loan_payoff', {
              is: (scheduled_loan_payoff: SPScheduledLoanPayoffType) =>
                scheduled_loan_payoff ===
                SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY,
              then: yup
                .string()
                .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED),
            })
            .label(SP_FIELD_ERROR_MESSAGE.borrowing_expected_repayment_date),
        }),
      })
    ),
    completely_repayment_type: yup.string().when(['p_borrowings_attributes'], {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some(
          (borrowing) =>
            borrowing.scheduled_loan_payoff ===
              SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY &&
            !borrowing._destroy
        ),
      then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
    }),
    other_complete_repayment_type: yup
      .string()
      .when('p_borrowings_attributes', {
        is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
          p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .when('completely_repayment_type', {
            is: (completely_repayment_type: FundsPairOffType) =>
              completely_repayment_type === FundsPairOffType.OTHERS,
            then: yup.string().required(MESSAGE_REGEX.REQUIRED),
          }),
      }),
    refund_content: yup.string().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .string()
        .max(20)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
      // .when('p_borrowings_attributes', {
      //   is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
      //     p_borrowings_attributes.some((borrowing) => borrowing.self_input),
      //   then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
      // }),
    }),
    refund_amount: yup.number().when('p_borrowings_attributes', {
      is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
        p_borrowings_attributes.some((borrowing) => !borrowing._destroy),
      then: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(99999, messageOverMaxNumber(99999)),
      // .when('p_borrowings_attributes', {
      //   is: (p_borrowings_attributes: SPBorrowingAttribute[]) =>
      //     p_borrowings_attributes.some((borrowing) => borrowing.self_input),
      //   then: yup.number().required(MESSAGE_REGEX.REQUIRED),
      // }),
    }),
    land_rent_to_be_paid: yup
      .number()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .integer()
      .min(0)
      .max(99999, messageOverMaxNumber(99999)),
    // .test({
    //   message: MESSAGE_REGEX.REQUIRED,
    //   test(value) {
    //     if (!value) {
    //       const check_required = this.parent.p_borrowings_attributes.some(
    //         (borrowing: SPBorrowingAttribute) =>
    //           borrowing.self_input &&
    //           borrowing.scheduled_loan_payoff ===
    //             SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
    //       );
    //       return !check_required;
    //     }
    //     return true;
    //   },
    // }),
    house_rent_to_be_paid: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(99999, messageOverMaxNumber(99999)),
    // .test({
    //   message: MESSAGE_REGEX.REQUIRED,
    //   test(value) {
    //     if (!value) {
    //       const check_required = this.parent.p_borrowings_attributes.some(
    //         (borrowing: SPBorrowingAttribute) =>
    //           borrowing.self_input &&
    //           borrowing.scheduled_loan_payoff ===
    //             SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
    //       );
    //       return !check_required;
    //     }
    //     return true;
    //   },
    // }),
    land_rent_to_be_paid_borrower: yup.string().when('land_rent_to_be_paid', {
      is: (land_rent_to_be_paid: number, _destroy: boolean) =>
        !!land_rent_to_be_paid && !_destroy,
      then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
    }),
    house_rent_to_be_paid_borrower: yup
      .string()
      .when(['house_rent_to_be_paid', '_destroy'], {
        is: (house_rent_to_be_paid: number, _destroy: boolean) =>
          !!house_rent_to_be_paid && !_destroy,
        then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
      }),
  },
  []
);
