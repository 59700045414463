import { FC } from 'react';
import { Grid, Stack } from '@mui/material';
import { InputField, FormItem } from 'components';
import { Placeholder } from 'constant';

const StepOnePageG: FC = () => {
  return (
    <Stack justifyContent="center">
      <FormItem label="提携会社（不動産会社・住宅メーカー等）" divider>
        <InputField
          name="p_referral_agency.name_kanji"
          placeholder="株式会社◯◯ホーム"
        />
      </FormItem>

      <FormItem label="支店名" divider>
        <InputField
          name="p_referral_agency.store_name_kanji"
          placeholder="田町支店"
        />
      </FormItem>

      <FormItem label="担当者名" divider>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="p_referral_agency.manager_name_kanji"
            placeholder="山本"
          />
        </Stack>
      </FormItem>

      <FormItem label="販売代理会社（該当する場合）" divider>
        <InputField
          name="p_referral_agency.sale_agent_name_kanji"
          placeholder="株式会社◯◯ホーム"
        />
      </FormItem>

      <FormItem divider label="担当者直通番号" note={notes.phone_number}>
        <Grid container spacing={7}>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              name="p_referral_agency.phone_number_2"
              placeholder={Placeholder.PHONE_NUMBER}
            />
          </Grid>
        </Grid>
      </FormItem>

      <FormItem divider label="担当者携帯番号" note={notes.phone_number}>
        <Grid container spacing={7}>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              name="p_referral_agency.phone_number"
              placeholder={Placeholder.PHONE_NUMBER}
            />
          </Grid>
        </Grid>
      </FormItem>

      <FormItem label="担当者メールアドレス">
        <Grid container spacing={7}>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              name="p_referral_agency.manager_email"
              placeholder="ー"
            />
          </Grid>
        </Grid>
      </FormItem>
    </Stack>
  );
};

export default StepOnePageG;

const notes = {
  phone_number: '※ハイフンを抜かしてご入力ください。',
};
