import axios, { AxiosError } from 'axios';
import { config } from 'configs';
import { Error, errorMsg } from 'constant';
import { set } from 'lodash';
import { toast } from 'react-toastify';
import { store } from 'store';
import { StandardError } from 'types';
import { tokenInvalidLogout } from 'containers/AuthModal/thunk';
import { managerTokenInvalidLogout } from 'pages/Login/thunk';
import { salePersonTokenInvalidLogout } from '../pages/SalePersonLogin/thunk';
import mockAdapter from './mockAdapter';

const instance = axios.create({
  baseURL: config.serverUrl,
  timeout: 1000 * 60 * 60,
  headers: { 'Content-Type': 'application/json' },
});

function handleOnReject(error: Error | AxiosError) {
  const state = store.getState();
  const errors = (error as AxiosError<StandardError>).response?.data?.errors;
  if (
    (typeof errors !== 'string' &&
      errors?.some((err) => err.key === Error.LOAN_TYPE_INVALID)) ||
    (typeof errors === 'string' && errors === Error.LOAN_TYPE_INVALID)
  ) {
    toast.error(errorMsg[Error.LOAN_TYPE_INVALID], {
      toastId: Error.LOAN_TYPE_INVALID,
    });
    return Promise.reject(error);
  }
  if (
    typeof errors !== 'string' &&
    errors?.some((err) => err.key === Error.TOKEN_INVALID)
  ) {
    if (
      state.auth.isLoggedIn &&
      state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(managerTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      state.auth.salePersonId
    ) {
      store.dispatch(salePersonTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(tokenInvalidLogout());
    }
    toast.error(errorMsg[Error.TOKEN_INVALID], {
      toastId: Error.TOKEN_INVALID,
    });
    return Promise.reject(error);
  }
  if (
    typeof errors !== 'string' &&
    errors?.some((err) => err.key === Error.TOKEN_EXPIRED)
  ) {
    if (
      state.auth.isLoggedIn &&
      state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(managerTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      state.auth.salePersonId
    ) {
      store.dispatch(salePersonTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(tokenInvalidLogout());
    }
    toast.error(errorMsg[Error.TOKEN_EXPIRED], {
      toastId: Error.TOKEN_EXPIRED,
    });
    return Promise.reject(error);
  }
  if (
    typeof errors !== 'string' &&
    errors?.some((err) => err.key === Error.MUST_LOGIN)
  ) {
    if (
      state.auth.isLoggedIn &&
      state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(managerTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      state.auth.salePersonId
    ) {
      store.dispatch(salePersonTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(tokenInvalidLogout());
    }
    toast.error(errorMsg[Error.MUST_LOGIN], {
      toastId: Error.MUST_LOGIN,
    });
    return Promise.reject(error);
  }
  if (
    typeof errors !== 'string' &&
    errors?.some((err) => err.key === Error.TOKEN_REVOKED)
  ) {
    if (
      state.auth.isLoggedIn &&
      state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(managerTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      state.auth.salePersonId
    ) {
      store.dispatch(salePersonTokenInvalidLogout());
    } else if (
      state.auth.isLoggedIn &&
      !state.auth.managerId &&
      !state.auth.salePersonId
    ) {
      store.dispatch(tokenInvalidLogout());
    }
    toast.error(errorMsg[Error.TOKEN_REVOKED], {
      toastId: Error.TOKEN_REVOKED,
    });
    return Promise.reject(error);
  }
  if (error instanceof AxiosError && error.response?.status === 302) {
    toast.error(
      'サーバーへの接続に失敗しました。ログアウトして、もう一度試してください。'
    );
    return Promise.reject(error);
  }
  if (error instanceof AxiosError) {
    try {
      const errorSerializable = JSON.parse(
        JSON.stringify(error?.response?.data)
      );
      return Promise.reject(errorSerializable);
    } catch (e) {}
    return Promise.reject(error);
  }
  return Promise.reject(error);
}

instance.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const token = state.auth.accessToken;

    if (token) {
      set(config, 'headers.Authorization', `Bearer ${token}`);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => response, handleOnReject);

const fakeInstance = axios.create({});

mockAdapter(fakeInstance);

const internalInstance = axios.create({
  baseURL: config.serverUrl,
  timeout: 1000 * 60,
  headers: { 'Content-Type': 'application/json' },
});

const externalInstance = axios.create({
  baseURL: config.externalServerUrl,
  timeout: 1000 * 60,
  headers: { 'Content-Type': 'application/json' },
});

internalInstance.interceptors.response.use(
  (response) => response,
  handleOnReject
);
externalInstance.interceptors.response.use(
  (response) => response,
  handleOnReject
);

export { instance, fakeInstance, internalInstance, externalInstance };
