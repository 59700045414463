import { FC } from 'react';
import { Stack, Container } from '@mui/material';
import { Accordions } from 'components';
import { useNavigate } from 'react-router-dom';
import { LandingLayout } from 'containers';
import { routeNames } from 'navigations/routes';

const ApplicationFlowPage: FC = () => {
  const navigate = useNavigate();

  return (
    <LandingLayout
      header={{ title: 'お申込みの流れ' }}
      footer={{
        left: {
          onClick: () => navigate(routeNames.Material.path),
        },
        right: {
          children: '次へ進む',
          onClick: () => navigate(routeNames.Confirmation.path),
        },
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          mb: 10,
          bgcolor: 'bg_off',
        }}
      >
        <Container sx={{ px: { mobile: 4, tablet: 10 }, pt: 4, pb: 10 }}>
          <Accordions summaries={summaries} defaultActive={0} />
        </Container>
      </Stack>
    </LandingLayout>
  );
};

export default ApplicationFlowPage;

const summaries = [
  {
    status: '事前審査',
    description: `Web上で事前審査のお申込み`,
    note: '今ここです！',
    steps: [
      {
        status: 'お客様：住宅ローンネット仮審査のお申し込み',
        description: `申込画面から必要事項をご入力の上、お申し込みください。ご入力いただいたメールアドレスに受付確認のメールをお送りしますのでご確認ください。`,
        note: '※ペアローンの場合はそれぞれのお申し込みが必要となります。',
        bgColor: 'pale_blue',
      },
      {
        status: '当社：受付完了',
        description: `完了時に銀行より返答時間の目安をお伝えします`,
      },
      {
        status: '当社：資料ご送付',
        description: `必要資料のアップロードをお願いします`,
      },
      {
        status: '当社：仮審査結果のご連絡',
        title: '完了時に銀行より返答時間の目安をお伝えします。',
        description: `原則、ご入力いただいた内容で仮審査を進めさせていただきます。\n仮審査の結果およびその後のお手続きにつきましては、お取引希望店の担当者または住宅ローン専門部署の担当者からお電話もしくは書面などにてご案内申し上げます。`,
        note: '※お申込内容についてご入力いただきましたメールアドレス、電話番号に当社からご連絡させていただく場合がございます。また、書類のご提出などをお願いする場合もございますのであらかじめご了承ください。\n※本サービスの混雑状況により、ご連絡まで日数がかかる場合もございます。\n※一定期間ご連絡が取れない場合には、お申し込みをキャンセルされたものとしてお取り扱いさせていただきます。',
      },
      {
        status: 'お客様：審査終了',
        description: `マイページで審査結果を確認することができます`,
        bgColor: 'pale_blue',
      },
      {
        status: '当社：ご連絡',
        description: `ご登録のLINEアカウントに審査結果を送付します`,
      },
    ],
  },
  {
    status: 'お客様：住宅ローン正式お申し込み',
    description: `正式申込へお進みいただけるお客さまへ、お取引希望店の担当者または住宅ローン専\n門部署の担当者から、必要書類をご案内申し上げます。`,
    stepNumber: 7,
    bgColor: 'pale_blue',
  },
  {
    status: '当社：正式審査',
    description: `借入申込書ならびに必要書類をご提出いただいた後、当社にて正式審査に入ります。\n正式審査終了後、お取引希望店の担当者または住宅ローン専門部署の担当者から審査\n結果およびその後のご契約手続きにつきまして、ご案内申し上げます。`,
    stepNumber: 8,
  },
  {
    status: 'お客様：住宅ローンご契約',
    description: `住宅ローンのご契約、お借り入れに必要な手続きを承ります。`,
    stepNumber: 9,
    bgColor: 'pale_blue',
  },
  {
    status: 'お客様：住宅ローンお借り入れ',
    description: `ご登録のメールアドレスに審査結果を送付します`,
    stepNumber: 10,
    bgColor: 'pale_blue',
  },
  {
    status: 'お客様：住宅ローンご返済開始',
    stepNumber: 11,
    bgColor: 'pale_blue',
    align: 'center',
  },
];
