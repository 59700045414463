import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Stack, SxProps, Typography } from '@mui/material';
import { Button, ButtonProps } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { isReactNode } from 'utils';
export type FooterProps = PropsWithChildren<{
  left?: ReactNode | ButtonProps | false;
  right?: ReactNode | ButtonProps | false;
  sxIconRight?: SxProps;
}>;

export const Footer: FC<FooterProps> = ({
  left,
  children,
  right,
  sxIconRight,
}) => {
  return (
    <Stack
      sx={{
        backgroundColor: 'footer_bg',
        zIndex: (theme) => theme.zIndex.drawer - 2,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Stack direction="row" sx={{ p: '5px 20px 11px 11px' }}>
        <Stack flex={1}>
          {isReactNode(left) && left}
          {left !== false && !isReactNode(left) && (
            <Button
              {...left}
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
            >
              <Typography
                sx={{ fontSize: 16, whiteSpace: 'nowrap' }}
                variant="button_title"
                color="black"
              >
                もどる
              </Typography>
            </Button>
          )}
        </Stack>
        <Stack flex={2} alignItems="center">
          {children}
        </Stack>
        <Stack flex={1} alignItems="flex-end">
          {isReactNode(right) && right}
          {right !== false && !isReactNode(right) && (
            <Button
              {...right}
              sx={{
                pl: 8,
                pr: 8,
                py: 2,
                minHeight: 44,
                height: 44,
                width: { mobile: 150, tablet: 247 },
                bgcolor: 'blue08',
                ...right?.sx,
                '&.MuiButton-root .MuiTypography-root': {
                  color: 'main_white',
                },
                '&.Mui-disabled .MuiTypography-root': {
                  opacity: { mobile: 1, desktop: 0.8 },
                },
                whiteSpace: 'nowrap',
              }}
              endIcon={
                <ArrowForwardIos sx={{ color: 'h_blugreen', ...sxIconRight }} />
              }
            >
              <Typography variant="button_title">
                {right.children ?? '次へすすむ'}
              </Typography>
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
