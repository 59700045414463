import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPCheckPassword: FC<SvgIconProps> = ({
  sx,
  ...props
}: SvgIconProps) => {
  const id = useMemo<string>(
    () => Math.round(Math.random() * 10000000).toString(),
    []
  );
  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 20 16" {...props}>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath={`url(#${id})`}>
          <path
            d="M19.8781 15.5531C20.0125 15.6406 20.0313 15.7969 19.9469 15.8781C19.8594 16.0125 19.7031 16.0313 19.5938 15.9469L0.0944443 0.445632C-0.0136339 0.359694 -0.0316183 0.202538 0.0543192 0.0944443C0.140225 -0.0136339 0.297507 -0.0316183 0.405632 0.0543193L19.8781 15.5531ZM18.9219 8.38438C18.6219 9.10313 18.1094 10.0344 17.3906 10.9688L16.9719 10.6594C17.6875 9.76251 18.1781 8.84688 18.4625 8.19376C18.5125 8.06876 18.5125 7.93126 18.4625 7.80626C18.0219 6.72188 17.0813 5.18751 15.6781 3.88438C14.2688 2.57532 12.3625 1.47188 9.97188 1.47188C8.68438 1.47188 7.50938 1.83407 6.48126 2.35626L6.05313 2.01719C7.18438 1.40376 8.50626 0.971882 10 0.971882C12.525 0.971882 14.5469 2.15126 16.0188 3.51876C17.4813 4.87501 18.4594 6.47188 18.9219 7.61563C19.025 7.86251 19.025 8.13751 18.9219 8.38438ZM2.60813 5.00313L3.00063 5.34063C2.31251 6.23751 1.82219 7.12501 1.53844 7.80626C1.48719 7.93126 1.48719 8.06876 1.53844 8.19376C1.97938 9.25001 2.91813 10.7844 4.32188 12.1156C5.73126 13.425 7.63751 14.5 10 14.5C11.3156 14.5 12.4906 14.1656 13.5188 13.6438L13.9469 13.9844C12.8156 14.5969 11.4938 15 9.97188 15C7.47501 15 5.45313 13.85 3.98126 12.4813C2.51938 11.0969 1.54188 9.50001 1.07688 8.38438C0.974382 8.13751 0.974382 7.86251 1.07688 7.61563C1.37688 6.89688 1.88938 5.96563 2.60813 5.00313ZM6.50001 8.10626L7.04688 8.53438C7.30001 9.90938 8.52501 11 10 11C10.0563 11 10.1094 10.9719 10.1625 10.9969L10.7094 11.4281C10.4813 11.475 10.2438 11.5 10 11.5C8.10313 11.5 6.55626 9.99063 6.50001 8.10626ZM9.97188 4.47188C11.8969 4.47188 13.4438 6.00938 13.4969 7.89376L12.9531 7.46251C12.7 6.06251 11.475 4.97188 9.97188 4.97188C9.94376 4.97188 9.89063 5.00001 9.83751 5.00313L9.29063 4.57188C9.51876 4.52501 9.75626 4.47188 9.97188 4.47188Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id={id}>
            <rect width="20" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
