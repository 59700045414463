import { FC } from 'react';
import { Stack, Typography, Dialog, IconButton, Divider } from '@mui/material';
import { Close } from '@mui/icons-material';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'hooks';
import {
  createContactsInformation,
  getManagerContacts,
} from 'pages/ManagerContactInformations/thunk';

import { theme } from 'styles';
import { yup } from 'libs';
import { useSelector } from 'react-redux';
import { managerIdSelector } from 'containers/AuthModal/selectors';
import { selectedPreliminarySelectors } from 'pages/ManagerPreliminaries/selectors';
import { Button, InputField, InputSelect } from 'components/atoms';

type ContactDetailProps = {
  open: boolean;
  onClose: () => void;
};

export const ContactInformationModal: FC<ContactDetailProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const managerId = useSelector(managerIdSelector);
  const { id, applicant_id } = useSelector(selectedPreliminarySelectors);

  const formik = useFormik({
    initialValues: { subject: '', problem_contact: '', content: '' },
    validationSchema: yup.object({}),
    async onSubmit(values) {
      const result = await dispatch(
        createContactsInformation({
          id,
          applicant_id: applicant_id,
          contact_information: {
            subject: values.subject,
            problem_contact: values.problem_contact,
            content: values.content,
            sender_type: 'Manager',
            sender_id: managerId,
          },
        })
      );
      if (createContactsInformation.fulfilled.match(result)) {
        toast.success('途中保存されているデータを成功に取得しました。');
        dispatch(getManagerContacts({ id, applicant_id }));
      } else {
        toast.error('エラーが発生しました。');
      }
      onClose();
    },
  });

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          width: '40%',
          m: 'auto',
          '&& .MuiDialog-paper': { minWidth: 660, minHeight: 450 },
        }}
      >
        <Stack
          sx={{ borderBottom: (theme) => `1px solid ${theme?.palette?.gray}` }}
        >
          <Stack direction="row">
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '84px',
                borderRight: (theme) => `1px solid ${theme?.palette?.gray}`,
              }}
            >
              <Typography
                variant="title_application_item"
                color="contact_title_text"
              >
                件名
              </Typography>
              <Divider
                sx={{
                  color: 'gray',
                  right: 1,
                  top: '25%',
                  position: 'absolute',
                  height: '50%',
                }}
                orientation="vertical"
              />
            </Stack>
            <Stack
              spacing={2}
              justifyContent="center"
              sx={{ pt: 1, pb: 3, pl: 3 }}
            >
              <Stack sx={{ width: 250 }}>
                <InputSelect
                  name="problem_contact"
                  sx={{
                    minHeight: 30,
                    height: 30,
                    '&&&&& fieldset': {
                      borderWidth: '1px',
                      borderRadius: 0,
                    },
                    '&&&&& .MuiTypography-input_style': {
                      fontSize: '10px',
                      lineHeight: '15px',
                    },
                    '&&&&& .MuiTypography-placeHolder_style': {
                      fontSize: '10px',
                      lineHeight: '15px',
                    },
                  }}
                  options={[
                    { value: '1', label: '記載内容についてA' },
                    { value: '2', label: '記載内容についてB' },
                    { value: '3', label: '記載内容についてC' },
                  ]}
                />
              </Stack>
              <Stack sx={{ width: 554 }}>
                <InputField
                  name="subject"
                  placeholder="件名"
                  sx={{
                    '.MuiInputBase-input': {
                      ...theme.typography.text_placeholder,
                      minHeight: 30,
                      height: 30,
                      p: 0,
                      pl: 3,
                    },
                    '&&&&& MuiInputBase-input': {
                      boxShadow: 'none',
                    },
                    '&&& MuiInputBase-input': {
                      borderRadius: 0,
                    },
                    '&&&&&&&&& fieldset': {
                      borderWidth: 1,
                      borderRadius: 0,
                    },
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              p: 0,
              right: 0,
              top: -1,
              color: 'normal_text',
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <Stack>
          <InputField
            multiline
            name="content"
            placeholder="連絡する事項を入力してください。"
            InputProps={{
              sx: {
                bgcolor: 'bg_off',
                'textarea::placeholder': {
                  color: 'off_text',
                  ...theme.typography.text_placeholder,
                },

                '&& .MuiInputBase-input': {
                  ...theme.typography.text_placeholder,
                  width: '100%',
                  height: '305px !important',
                  borderRadius: 0,
                  boxShadow: 'none',
                },
                '&&&&&&& .MuiInputBase-input': {
                  borderRadius: 0,
                },

                '&&&&&&&&& fieldset': {
                  borderWidth: 0,
                  borderRadius: 0,
                },
                p: 0,
                height: '321px',
                borderRadius: 0,
              },
            }}
          />
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="flex-end"
          sx={{
            bgcolor: 'footer_bg',
            height: 44,
            pr: 4,
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
          }}
        >
          <Button
            sx={{
              bgcolor: 'h_blugreen',
              width: 93,
              minHeight: 25,
              height: 25,
              border: (theme) => `1px solid ${theme?.palette?.h_blugreen}`,
              borderRadius: 1,
            }}
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            <Typography variant="required_chip_label" color="main_white">
              メール送信
            </Typography>
          </Button>
        </Stack>
      </Dialog>
    </FormikProvider>
  );
};
