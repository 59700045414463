import { Close as CloseIcon } from '@mui/icons-material';
import { Modal as MuiModal, Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import {
  AdminPreliminaryItem,
  ERROR_KEY,
  FIELDS_OBJECT_VALIDATE,
  FIELDS_VALIDATE,
} from 'constant';
import { uniq } from 'lodash';
import { FC, Fragment, PropsWithChildren, useCallback, useMemo } from 'react';
import { ErrorsCheckStatusInternal } from 'services';
import { getFieldError } from 'utils';

type ModalProps = PropsWithChildren<{
  currentIds?: string[];
  isInterfaceErrorStatus: boolean;
  open: boolean;
  errorsCheckStatusInternal?: ErrorsCheckStatusInternal[];
  isInternal: boolean;
  isSuccess: boolean;
  textErrorMessage: string[];
  jsonErrorMessage: string;
  onClose: () => void;
  showParent?: boolean;
  parents: { label: string; values: AdminPreliminaryItem[] }[][];
}>;

export const ModalConfirmLinkToBank: FC<ModalProps> = ({
  currentIds,
  isInterfaceErrorStatus,
  open,
  errorsCheckStatusInternal,
  isInternal,
  isSuccess,
  textErrorMessage,
  jsonErrorMessage,
  onClose,
  parents,
  showParent,
}) => {
  const convertFieldError = useCallback(
    ({ field, resource, key }: ErrorsCheckStatusInternal) => {
      let dataField = Object.keys(FIELDS_VALIDATE).find(
        (data) => data === `${resource}.${field}`
      );
      if (!dataField) {
        dataField = Object.keys(FIELDS_VALIDATE).find((data) => data === field);
      }
      if (dataField && FIELDS_VALIDATE[dataField]) {
        return key === 'wrong_format'
          ? `${FIELDS_VALIDATE[dataField]} 入力されたフォマットが正しくありません。`
          : FIELDS_VALIDATE[dataField];
      }
      return getFieldError(field);
    },
    []
  );

  const isFieldObject = useCallback((field: string) => {
    return FIELDS_OBJECT_VALIDATE.findIndex((data) => data === field) >= 0;
  }, []);

  const handleConvertErrorMessage = useCallback(
    (fieldName: string, item: ErrorsCheckStatusInternal) => {
      if (!fieldName) return '';
      return `${fieldName}${ERROR_KEY[item.key] ?? ''}`;
    },
    []
  );

  const mapppppp = useMemo(() => {
    let arr: { label: string; child?: (string | null)[] }[] = [];
    const showPrefix = parents?.length === 2;

    currentIds?.forEach((id, index) => {
      arr = arr.concat(
        parents[index].map(({ label, values }) => {
          const subErrorsCheckStatusInternal =
            errorsCheckStatusInternal?.filter((o) => {
              if (o.resource === 'p_application_header' && !index) {
                return true;
              }
              if (index) {
                return o.id === id;
              }
              return o.id !== currentIds?.[1];
            });
          return {
            label: `${
              showPrefix ? (index === 0 ? '申込人（A）＿' : '収入合算者＿') : ''
            }${label}`,
            child: uniq(
              values
                ?.map((v) => {
                  if (!v.name_en) {
                    return null;
                  }
                  const test = subErrorsCheckStatusInternal?.find((error) => {
                    if (isFieldObject(error.field)) {
                      return false;
                    }
                    if (error.resource === 'p_application_header') {
                      return (
                        v.name_en.includes(error.field) ||
                        v.subField?.name_en.includes(error.field)
                      );
                    }
                    return (
                      checkField(v.name_en) ||
                      checkField(v.subField?.subFieldName) ||
                      checkField(v.subField?.name_en) ||
                      checkField(v.subField?.subFieldName)
                    );

                    function checkField(field?: string) {
                      if (!field) return false;
                      const lastField = field.split('.').pop();
                      return (
                        lastField === error.field &&
                        field.includes(mapp[error.resource])
                      );
                    }
                  });
                  if (!test) {
                    return null;
                  }
                  return handleConvertErrorMessage(
                    convertFieldError(test),
                    test
                  );
                })
                .filter((n) => !!n)
            ),
          };
        })
      );
    });
    return arr;
  }, [
    currentIds,
    parents,
    errorsCheckStatusInternal,
    isFieldObject,
    convertFieldError,
  ]);

  return (
    <MuiModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={onClose}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 485,
          minHeight: 140,
          backgroundColor: 'main_white',
          borderRadius: 2,
          paddingBottom: 8,
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <CloseIcon
            onClick={onClose}
            fontSize="medium"
            sx={{
              color: 'b_333',
              cursor: 'pointer',
              pb: 2,
              pt: 2,
              pr: 2,
              width: 20,
              height: 20,
            }}
          />
        </Stack>

        <Stack alignItems="center">
          {isInterfaceErrorStatus ? (
            <Stack sx={{ width: '90%' }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ textAlign: 'center', display: 'block' }}
              >
                <Icons.SPCircleExclamation
                  sx={{ width: 16, height: 16, paddingTop: '5px' }}
                />
                <Typography
                  variant="textstyle"
                  sx={{
                    whiteSpace: 'break-spaces',
                    textAlign: 'center',
                    color: 'sp_secondary_01',
                  }}
                >
                  {`データ連携は完了できませんでした。\nもう一度データ連携を行なってください。`}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <>
              {!isSuccess && isInternal && (
                <Stack sx={{ width: '90%' }}>
                  <Stack direction="row" spacing={1} justifyContent="center">
                    <Icons.SPCircleExclamation
                      sx={{ width: 16, height: 16, paddingTop: '5px' }}
                    />
                    <Typography
                      variant="textstyle"
                      sx={{ color: 'sp_secondary_01' }}
                    >
                      {showParent
                        ? 'エラーが発生しました。\n以下の項目をご確認ください。'
                        : 'エラーが発生しました。(Internal)'}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      maxHeight: '300px',
                      overflowX: 'auto',
                      paddingTop: '15px',
                    }}
                  >
                    {showParent &&
                      mapppppp.map(
                        ({ label, child }) =>
                          !!child?.length && (
                            <Stack key={label} width="80%" alignSelf="center">
                              <Typography
                                variant="textstyle"
                                sx={{ color: 'sp_secondary_01' }}
                              >
                                {label}:
                              </Typography>
                              {child.map((text, index) => (
                                <Typography
                                  key={index}
                                  variant="textstyle"
                                  sx={{ color: 'sp_secondary_01', pl: 5 }}
                                >
                                  {text}
                                </Typography>
                              ))}
                            </Stack>
                          )
                      )}
                    {!showParent &&
                      errorsCheckStatusInternal?.map((error, index) => (
                        <Typography
                          key={index}
                          variant="textstyle"
                          sx={{ color: 'sp_secondary_01' }}
                        >
                          {!isFieldObject(error.field) &&
                            convertFieldError(error)}
                        </Typography>
                      ))}
                  </Stack>
                </Stack>
              )}
              {!isSuccess && !isInternal && (
                <Stack sx={{ width: '80%' }}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      textAlign: 'center',
                      justifyContent: 'center',
                      maxHeight: 300,
                      overflowX: 'auto',
                    }}
                    // alignItems="center"
                  >
                    <Icons.SPCircleExclamation
                      sx={{
                        width: 16,
                        height: 16,
                        paddingTop: '5px',
                        alignSelf: 'center',
                      }}
                    />
                    <Stack>
                      <Typography
                        variant="textstyle"
                        align="left"
                        sx={{ color: 'sp_secondary_01', fontSize: '15px' }}
                      >
                        エラーが発生しました。
                        {!!textErrorMessage.length && (
                          <>
                            {textErrorMessage?.map((item, index) => (
                              <Fragment key={index}>
                                <br />「{item}」
                              </Fragment>
                            ))}
                            <br />
                          </>
                        )}
                        {!!jsonErrorMessage && (
                          <Typography
                            component="pre"
                            variant="textstyle"
                            sx={{
                              color: 'sp_secondary_01',
                              fontSize: '15px',
                            }}
                          >
                            {jsonErrorMessage}
                          </Typography>
                        )}
                        もう一度データ連携を行なってください。
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              {isSuccess && (
                <Stack direction="row" spacing={1}>
                  <Typography variant="textstyle" sx={{ color: 'b_333' }}>
                    データ連携が完了しました。
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>
        <Stack
          justifyContent="center"
          direction="row"
          spacing={3}
          sx={{
            pt: 10,
          }}
        >
          <Button
            sx={{
              bgcolor: 'white',
              boxShadow: 'none',
              width: '270px',
              height: '36px',
              borderRadius: '2px',
              minHeight: '36px',
              border: (theme) =>
                `1px solid ${theme?.palette?.sp_primary_100_main}`,
            }}
            onClick={onClose}
          >
            <Typography variant="button01" color="sp_primary_100_main">
              閉じる
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </MuiModal>
  );
};

const mapp: Record<string, string> = {
  p_applicant_person: 'p_applicant_people_attributes',
  p_join_guarantor: 'p_join_guarantors_attributes',
  p_borrowing_detail: 'p_borrowing_details_attributes',
  p_borrowing: 'p_borrowings_attributes',
  p_resident: 'p_residents_attributes',
};
