import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SecondHouse: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 76 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="76"
      height="62"
      viewBox="0 0 76 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38.1252 10.9053L60.7808 26.7685H15.4697L38.1252 10.9053Z" />
      <path d="M12.8984 26.4902V60.9997H64.106V26.4902" />
      <path
        d="M12.8984 26.4902V60.9997H64.106V26.4902"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M34.4023 60.9936V32.9424H22.224V60.9936H34.4023Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M54.0495 32.9482H41.8711V45.1485H54.0495V32.9482Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M47.9609 32.9482V45.1399"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M41.8711 39.0439H54.0495"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M70.764 33.0606L38.1377 9.71171L5.50278 33.0606L1.86719 27.9565L38.1377 2L74.4082 27.9565L70.764 33.0606Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
    </svg>
  </SvgIcon>
);
