import { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { isReactNode } from 'utils';
import { AuthPopover, Icons } from 'components';
import { useAppDispatch, useMatchedRoute } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { theme } from 'styles';
import { logout } from 'containers/AuthModal/thunk';
import { clearSalePerson } from '../../../../libs';

export type HeaderProps = PropsWithChildren<{
  left?: ReactNode | LeftHeaderProps | false;
  right?: ReactNode | RightHeaderProps | false;
}>;

type LeftHeaderProps = {
  title?: string;
};

type RightHeaderProps = {
  userName?: string;
};

export const Header: FC<HeaderProps> = ({ left, right, children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentRoute = useMatchedRoute();
  const [open, setOpen] = useState(false);

  const handleLogout = useCallback(async () => {
    await dispatch(logout());
    clearSalePerson();
    navigate(routeNames.Login.path);
  }, [dispatch, navigate]);

  if (!currentRoute) return null;

  return (
    <Stack
      direction="row"
      bgcolor="bg_off"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'main_white',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderTop: '1px solid',
        borderColor: 'gray',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
      }}
    >
      <Stack flex={1}>
        {isReactNode(left) && left}
        {left !== false && !isReactNode(left) && (
          <Stack direction="row" alignItems="center">
            <Link href="/">
              <Box sx={{ px: { mobile: 4, tablet: 8 }, pt: 3, pb: 2 }}>
                <Icons.LogoNew />
              </Box>
            </Link>
            <Divider
              orientation="vertical"
              sx={{
                height: 30,
                borderColor: 'gray',
              }}
            />
            <Stack sx={{ whiteSpace: 'nowrap', ml: 8 }}>
              <Typography variant="button_title" color="normal_text">
                {left.title}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack flex={2} alignItems="center">
        {children}
      </Stack>
      <Stack flex={1} direction="row" justifyContent="flex-end" sx={{ pr: 4 }}>
        {isReactNode(right) && right}
        {right !== false && !isReactNode(right) && (
          <AuthPopover
            sx={{ top: 20 }}
            content={
              <>
                <Stack
                  sx={{
                    padding: 3,
                    flex: 1,
                    width: 'auto',
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    borderBottom: (theme) =>
                      `1px solid ${theme?.palette?.line}`,
                    '&:hover': {
                      backgroundColor: theme?.palette?.bg_disabled,
                    },
                  }}
                  onClick={() => {
                    navigate(routeNames.UpdatePassword.path);
                  }}
                >
                  パスワード変更
                </Stack>
                <Stack
                  sx={{
                    padding: 3,
                    flex: 1,
                    width: 'auto',
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    '&:hover': {
                      backgroundColor: theme?.palette?.bg_disabled,
                    },
                  }}
                  onClick={handleLogout}
                >
                  ログアウト
                </Stack>
              </>
            }
            open={open}
            placement="bottom-start"
            contentProps={{
              width: 220,
              height: 89,
              padding: 0,
              color: 'normal_text',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => setOpen((prevState) => !prevState)}
            >
              <Icons.User />
              <Icons.Select
                sx={{
                  width: 10,
                  height: 10,
                }}
              />
            </Stack>
          </AuthPopover>
        )}
      </Stack>
    </Stack>
  );
};
