import { LoanType, StatusResult } from 'constant';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { useCallback } from 'react';
import { SPGetPreliminaryData } from 'types';
import { useAppDispatch } from './useAppDispatch';

export const useCheckCurrentStep = () => {
  const dispatch = useAppDispatch();

  const checkCurrentStep = useCallback(
    (data: SPGetPreliminaryData, status_result: StatusResult | null) => {
      const loan_type = data.loan_type;
      const has_join_guarantor =
        data.p_applicant_people_attributes[0].has_join_guarantor;
      const incomeTotalizer = Boolean(
        loan_type === LoanType.THREE || loan_type === LoanType.FOUR
      );
      const hasJoinGuarantor = Boolean(has_join_guarantor === '1');

      if (incomeTotalizer && hasJoinGuarantor) {
        dispatch(setCurrentStep('14'));
      } else if (incomeTotalizer && !hasJoinGuarantor) {
        dispatch(setCurrentStep('13'));
      } else if (!incomeTotalizer && hasJoinGuarantor) {
        dispatch(setCurrentStep('11'));
      } else {
        dispatch(setCurrentStep('10'));
      }
    },
    [dispatch]
  );
  return checkCurrentStep;
};
