import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Application } from 'types';
import { getSavedApplications } from './thunk';

export const applicationAdapter = createEntityAdapter<Application>({
  selectId: (application) => application.id,
});

const slice = createSlice({
  name: 'applications',
  initialState: applicationAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSavedApplications.fulfilled, (state, action) => {
      applicationAdapter.setAll(state, action.payload.data);
    });
  },
});

export default slice.reducer;
