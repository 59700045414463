import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Question: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 30 30" {...props}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 22.5H16.25V20H13.75V22.5ZM15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25ZM15 7.5C12.2375 7.5 10 9.7375 10 12.5H12.5C12.5 11.125 13.625 10 15 10C16.375 10 17.5 11.125 17.5 12.5C17.5 15 13.75 14.6875 13.75 18.75H16.25C16.25 15.9375 20 15.625 20 12.5C20 9.7375 17.7625 7.5 15 7.5Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
