import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  CheckSendEmailTokenError,
  CheckSendEmailTokenRequest,
  CheckSendEmailTokenResponse,
  SendEmailError,
  SendEmailRequest,
  SendEmailResponse,
  SPSignUpError,
  SPSignUpRequest,
  SPSignUpResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const sendEmail = createAsyncThunk<
  SendEmailResponse,
  SendEmailRequest,
  ThunkAPI<SendEmailError>
>('spAuth/sendEmail', async (data, { rejectWithValue }) => {
  try {
    return await authService.sendEmail(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendEmailToken = createAsyncThunk<
  CheckSendEmailTokenResponse,
  CheckSendEmailTokenRequest,
  ThunkAPI<CheckSendEmailTokenError>
>('spAuth/checkSendEmailToken', async (user, { rejectWithValue }) => {
  try {
    return await authService.sendEmailToken(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const spSignUp = createAsyncThunk<
  SPSignUpResponse,
  SPSignUpRequest,
  ThunkAPI<SPSignUpError>
>('spAuth/signUp', async (user, { rejectWithValue }) => {
  try {
    return await authService.spSignUp(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});
