import { Backdrop, CircularProgress } from '@mui/material';
import { FC } from 'react';

type LoadingProps = {
  open: boolean;
};

export const Loading: FC<LoadingProps> = ({ open }) => {
  return (
    <Backdrop
      open={open}
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress />
    </Backdrop>
  );
};
