import { Stack, Typography } from '@mui/material';
import {
  joinGuarantor,
  landAdvance,
  LandAdvanceType,
  loanTargetZero,
  LoanTargetZeroType,
  LoanType,
  PurposeType,
  SPTitle,
} from 'constant';
import { Icons, SPButton, SPFormItem } from 'components';
import { routeNames } from 'navigations/routes';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  convertDynamicOptions,
  convertYenToMan,
  formatJapanDate,
  formatJapanDateTine,
  numberWithCommas,
} from 'utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import kanjidate from 'kanjidate';
import { spStepOneSelector } from 'pages/SPStepOne/selectors';
import { masterBankSelectors } from 'pages/SPStartScreen/selectors';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';

type SPStepOneInfoProps = {
  showEditButton?: boolean;
};

export const SPStepOneInfo: FC<SPStepOneInfoProps> = ({
  showEditButton = true,
}) => {
  const navigate = useNavigate();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const spStepOneValues = useSelector(spStepOneSelector);
  const spStepTenValues = useSelector(spStepTenSelector);
  const masterBank = useSelector(masterBankSelectors);
  const location = useLocation();

  const loanTargetOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.loan_target),
    [dynamicOptions.loan_target]
  );

  const loanTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.loan_type),
    [dynamicOptions.loan_type]
  );

  const desiredMonthlyBonusOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.desired_monthly_bonus),
    [dynamicOptions.desired_monthly_bonus]
  );

  const repaymentOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.repayment_method),
    [dynamicOptions.repayment_method]
  );

  const getDateValue = (value: string) => {
    const [year, month] = value.split('/');
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return `${year}（${kanjiDateG2}${kanjiDateN}）年${+month}月`;
  };

  const {
    scheduled_date_moving,
    master_bank_ids,
    loan_target,
    loan_target_zero,
    has_land_advance_plan,
    loan_type,
    pair_loan_applicant_last_name,
    pair_loan_applicant_first_name,
    pair_loan_relationship_name,
    is_home_loan_plus,
  } = spStepOneValues.p_application_header;

  const { created_at } = spStepTenValues;

  const createdAt = formatJapanDateTine(created_at, 'YYYY/MM/DD');
  const createdAtHourJa = formatJapanDateTine(created_at, 'HH:mm');

  //TODO: Reopen loan_term_month_num
  const {
    loan_desired_borrowing_date,
    temporary_desired_loan_amount,
    halfyear_bonus,
    desired_monthly_bonus,
    loan_term_year_num,
    // loan_term_month_num,
    repayment_method,
  } = spStepOneValues.p_application_header.p_borrowing_details_attributes[0];

  const loanDesiredBorrowingDate =
    spStepOneValues.p_application_header?.p_borrowing_details_attributes?.[1]
      ?.loan_desired_borrowing_date;

  const temporaryDesiredLoanAmount =
    spStepOneValues.p_application_header?.p_borrowing_details_attributes?.[1]
      ?.temporary_desired_loan_amount;

  const halfyearBonus =
    spStepOneValues.p_application_header?.p_borrowing_details_attributes?.[1]
      ?.halfyear_bonus;

  const masterBankIds = spStepOneValues.p_application_header.master_bank_ids;

  const { has_join_guarantor } =
    spStepOneValues.p_application_header.p_applicant_people_attributes;

  return (
    <Stack>
      <Stack>
        <Stack
          direction="row"
          sx={{ px: 4, py: 1, bgcolor: 'sp_primary_100_main' }}
        >
          <Typography variant="sp_step_text" color="main_white" sx={{ mt: 1 }}>
            STEP
          </Typography>
          <Typography variant="sp_label_text" color="main_white">
            {SPTitle.STEP_ONE}
          </Typography>
        </Stack>
        {location.pathname === routeNames.SPConfirmApplicationDetail.path && (
          <SPFormItem
            label="申込日時"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {formatJapanDate(createdAt, true)} {createdAtHourJa}
            </Typography>
          </SPFormItem>
        )}
        <SPFormItem
          label="入居予定年月"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {scheduled_date_moving ? (
            <Typography variant="sp_value_text" color="b_333">
              {getDateValue(scheduled_date_moving)}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        <SPFormItem
          label="仮審査を申し込む金融機関"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {master_bank_ids ? (
            <Typography
              variant="sp_value_text"
              color="b_333"
              whiteSpace="break-spaces"
            >
              {masterBank
                .filter((bank) => masterBankIds.includes(bank.id))
                .map((bank) => bank.name)
                .join(',')
                .replace(/,/g, '\n\n')}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        {spStepOneValues.p_application_header.loan_target_zero ===
        LoanTargetZeroType.ZERO ? (
          <Stack>
            <SPFormItem
              label="お借入の目的"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Typography variant="sp_value_text" color="b_333">
                {loanTargetZero[loan_target_zero] ?? 'ー'}
              </Typography>
            </SPFormItem>
            <SPFormItem
              label="資金の使いみち"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {loanTargetOptions.find((e) => e.value === loan_target) ? (
                <Typography variant="sp_value_text" color="b_333">
                  {
                    loanTargetOptions.find((e) => e.value === loan_target)
                      ?.label
                  }
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </SPFormItem>
          </Stack>
        ) : (
          <SPFormItem
            label="お借入の目的"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            {loanTargetOptions.find((e) => e.value === loan_target) ? (
              <Typography variant="sp_value_text" color="b_333">
                {loanTargetOptions.find((e) => e.value === loan_target)?.label}
              </Typography>
            ) : (
              <Typography variant="sp_value_text" color="b_333">
                ー
              </Typography>
            )}
          </SPFormItem>
        )}

        {loan_target === PurposeType.SIX ? (
          <SPFormItem
            label="土地先行プランをご希望ですか？"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {landAdvance[has_land_advance_plan]}
            </Typography>
          </SPFormItem>
        ) : (
          []
        )}

        <SPFormItem
          label="お借入形態"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography variant="sp_value_text" color="b_333">
            {loanTypeOptions.find((e) => e.value === loan_type) ? (
              <Typography variant="sp_value_text" color="b_333">
                {loanTypeOptions.find((e) => e.value === loan_type)?.label}
              </Typography>
            ) : (
              <Typography variant="sp_value_text" color="b_333">
                ー
              </Typography>
            )}
          </Typography>
        </SPFormItem>

        {loan_type === LoanType.TWO ? (
          <SPFormItem
            label="ペアローンのお相手について"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Stack direction="row">
              <Typography variant="sp_value_text" color="b_333">
                〈お名前〉
              </Typography>
              {pair_loan_applicant_last_name &&
              pair_loan_applicant_last_name ? (
                <Typography variant="sp_value_text" color="b_333">
                  {pair_loan_applicant_last_name}{' '}
                  {pair_loan_applicant_first_name}
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </Stack>
            <Typography variant="sp_value_text" color="b_333">
              〈続柄〉
              {pair_loan_relationship_name ? pair_loan_relationship_name : 'ー'}
            </Typography>
          </SPFormItem>
        ) : (
          []
        )}

        <SPFormItem
          label={
            has_land_advance_plan === LandAdvanceType.YES
              ? `お借入内容\n1回目の融資`
              : 'お借入内容'
          }
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Stack spacing={1}>
            {
              <Typography variant="sp_value_text" color="b_333">
                〈お借入希望日〉
                {loan_desired_borrowing_date
                  ? formatJapanDate(loan_desired_borrowing_date, true)
                  : 'ー'}
              </Typography>
            }
            <Typography variant="sp_value_text" color="b_333">
              〈お借入希望額〉
              {temporary_desired_loan_amount
                ? numberWithCommas(
                    +convertYenToMan([temporary_desired_loan_amount])
                  )
                : 'ー'}
              万円
            </Typography>
            <Typography variant="sp_value_text" color="b_333">
              〈うち、ボーナス返済分〉
              {halfyear_bonus
                ? numberWithCommas(+convertYenToMan([halfyear_bonus]))
                : 'ー'}
              万円
            </Typography>
            <Typography variant="sp_value_text" color="b_333">
              〈ボーナス返済月〉
              {desiredMonthlyBonusOptions.find(
                (e) => e.value === desired_monthly_bonus
              )
                ? desiredMonthlyBonusOptions.find(
                    (e) => e.value === desired_monthly_bonus
                  )?.label
                : 'ー'}
            </Typography>
            <Typography variant="sp_value_text" color="b_333">
              〈お借入期間〉
              {loan_term_year_num ? +loan_term_year_num : 'ー'}年
            </Typography>
            <Typography variant="sp_value_text" color="b_333">
              〈お借入期間〉
              {repaymentOptions.find((e) => e.value === repayment_method)
                ? repaymentOptions.find((e) => e.value === repayment_method)
                    ?.label
                : 'ー'}
            </Typography>
          </Stack>
        </SPFormItem>

        {has_land_advance_plan === LandAdvanceType.YES ? (
          <SPFormItem
            label="お借入内容 2回目の融資"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Stack spacing={1}>
              <Typography variant="sp_value_text" color="b_333">
                〈お借入希望日〉
                {loanDesiredBorrowingDate
                  ? formatJapanDate(loanDesiredBorrowingDate, true)
                  : 'ー'}
              </Typography>
              <Typography variant="sp_value_text" color="b_333">
                〈お借入希望額〉
                {temporaryDesiredLoanAmount
                  ? numberWithCommas(
                      +convertYenToMan([temporaryDesiredLoanAmount])
                    )
                  : 'ー'}
                万円
              </Typography>
              <Typography variant="sp_value_text" color="b_333">
                〈うち、ボーナス返済分〉
                {halfyearBonus
                  ? numberWithCommas(+convertYenToMan([halfyearBonus]))
                  : 'ー'}
                万円
              </Typography>
            </Stack>
          </SPFormItem>
        ) : (
          []
        )}

        <SPFormItem
          label="担保提供者がいらっしゃいますか？"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography variant="sp_value_text" color="b_333">
            {has_join_guarantor ? joinGuarantor[has_join_guarantor] : 'ー'}
          </Typography>
        </SPFormItem>

        <SPFormItem
          label="住信SBIネット銀行の「住宅ローンプラス」を申し込みますか？"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography variant="sp_value_text" color="b_333">
            {is_home_loan_plus ? '申し込む' : '申し込まない'}
          </Typography>
        </SPFormItem>

        <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
          <Stack
            sx={{
              paddingRight: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: 'none',
            }}
          >
            {showEditButton && (
              <SPButton
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: '10px',
                  borderWidth: 2,
                  bgcolor: 'sp_primary_40',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
                variant="outlined"
                fullWidth
                onClick={() =>
                  navigate(routeNames.SPStepOne.path, {
                    state: { pathName: window.location.pathname },
                  })
                }
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icons.SPPencil />
                  <Typography
                    variant="sp_contentButton_text"
                    color="sp_primary_100_main"
                  >
                    STEP01 を修正する
                  </Typography>
                </Stack>
              </SPButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
