import { Close as CloseIcon } from '@mui/icons-material';
import { Modal as MuiModal, Stack, Typography } from '@mui/material';
import { Button } from 'components/atoms';
import { FC, createRef, useImperativeHandle, useState } from 'react';

type AdminModalUpdateDetailRef = {
  open: () => void;
  close: () => void;
};

export const adminModalUpdateDetailRef = createRef<AdminModalUpdateDetailRef>();

export const ModalUpdateDetailForm: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  useImperativeHandle(adminModalUpdateDetailRef, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    },
  }));

  const onClose = () => {
    window.location.reload();
    setOpen(false);
  };

  return (
    <MuiModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={onClose}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '28%',
          minWidth: 350,
          backgroundColor: 'main_white',
          '@media (orientation: landscape)': {
            minWidth: 'auto',
            maxHeight: '75vh',
          },
          borderRadius: 2,
          paddingBottom: 8,
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <CloseIcon
            onClick={onClose}
            fontSize="medium"
            sx={{
              color: 'b_333',
              cursor: 'pointer',
              pb: 2,
              pt: 2,
              pr: 2,
              width: 20,
              height: 20,
            }}
          />
        </Stack>

        <Stack alignItems="center">
          <Typography
            variant="text_note"
            color="b_333"
            sx={{ textAlign: 'center', px: 3 }}
          >
            {'こちらのファイルが削除されました。\n再度ご確認ください。'}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ mt: 5, px: 5 }}>
          <Button
            onClick={onClose}
            sx={{
              bgcolor: 'white',
              boxShadow: 'none',
              width: '150px',
              height: '36px',
              borderRadius: '2px',
              minHeight: '36px',
              border: '1px solid #6B70F0',
            }}
          >
            <Typography variant="button01" color="sp_primary_100_main">
              閉じる
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </MuiModal>
  );
};
