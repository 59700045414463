import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPQuestion: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 17 17" {...props}>
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.5C3.58125 0.5 0 4.08125 0 8.5C0 12.9187 3.58125 16.5 8 16.5C12.4187 16.5 16 12.9187 16 8.5C16 4.08125 12.4187 0.5 8 0.5ZM8 15.5C4.14062 15.5 1 12.3594 1 8.5C1 4.64062 4.14062 1.5 8 1.5C11.8594 1.5 15 4.64062 15 8.5C15 12.3594 11.8594 15.5 8 15.5ZM7.5 11.25C7.08594 11.25 6.75 11.5859 6.75 12C6.75 12.4141 7.08594 12.75 7.5 12.75C7.91406 12.75 8.25 12.4141 8.25 12C8.25 11.5859 7.91563 11.25 7.5 11.25ZM8.925 4.5H7.075C5.93125 4.5 5 5.43125 5 6.575V6.875C5 7.15137 5.22363 7.375 5.5 7.375C5.77637 7.375 6 7.15 6 6.875V6.575C6 5.98125 6.48125 5.5 7.075 5.5H8.92656C9.51875 5.5 10 5.98125 10 6.575C10 6.965 9.78809 7.325 9.46688 7.50375L7.57812 8.44688C7.22188 8.64688 7 9.02187 7 9.43125V10C7 10.275 7.225 10.5 7.5 10.5C7.775 10.5 8 10.275 8 10V9.43125C8 9.38584 8.02442 9.34384 8.04494 9.33212L9.93275 8.38775C10.5906 8.02188 11 7.32812 11 6.575C11 5.43125 10.0688 4.5 8.925 4.5Z"
        fill="#6B70F0"
      />
    </svg>
  </SvgIcon>
);
