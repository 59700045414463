import { MESSAGE_REGEX } from 'constant';
import { regex, yup } from 'libs';

export const emailValidationSchema = yup.object({
  email: yup
    .string()
    .max(128)
    .matches(regex.email, MESSAGE_REGEX.EMAIL_SP)
    .required()
    .label('登録メールアドレス'),
});

export const passwordValidationSchema = yup.object({
  password: yup
    .string()
    .required()
    .min(8, '8文字以上のパスワードを入力してください。')
    .max(20, 'パスワードは20桁以内で設定ください。')
    .matches(
      regex.password,
      'パスワードは大文字、小文字、数字をそれぞれ1つ以上で設定してください。'
    )
    .label('パスワード'),
  password_confirmation: yup
    .string()
    .required()
    .when('password', {
      is: (val: string) => !!val?.length,
      then: yup
        .string()
        .oneOf(
          [yup.ref('password')],
          'パスせんワードとパスワード（確認用）が一致していま。'
        ),
    })
    .min(8, '8文字以上のパスワードを入力してください。')
    .label('パスワード（確認用）'),
});
