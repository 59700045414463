import {
  Card,
  CardHeader,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { AdminEditModal, Button, Icons, InputFileAdmin } from 'components';
import { FilesUploadOption } from 'pages/AdminEditPreliminary';
import { FILES_UPLOAD_OPTIONS } from 'pages/AdminEditPreliminary/constant';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  logEditedItem,
  UploadedFileKeys,
  UploadedFilesManager,
} from 'services';
import { getFileExtFromUrl } from 'utils';
import { getLogEditedPreliminary } from 'pages/AdminEditPreliminary/thunk';
import { useAppDispatch } from 'hooks';
import { getLogEditedSalePersonPreliminary } from '../../../pages/SalePersonEditPreliminary/thunk';

interface DocumentUploadItemProps {
  title?: string;
  name?: string;
  multiple: boolean;
  options: {
    personId?: string;
    headerId?: string;
    trackable_type?: string;
    isHasLog?: boolean;
    jp_name: string;
    en_name: keyof UploadedFileKeys;
    files?: (File | UploadedFilesManager)[];
    isRequired?: boolean;
  }[];
  files?: File[];
  isLoadingFiles?: boolean;
  onChange?: (
    file: (File | UploadedFilesManager)[],
    id: string,
    name?: keyof UploadedFileKeys
  ) => void;
  setOpenPreview: (name: string, src: string) => void;
  isDisplay: boolean;
  editable: boolean;
  filesUploadOption?: FilesUploadOption;
  handleRadioChange?: (option: FilesUploadOption) => void;
  isSalePerson?: boolean;
}

export const DocumentUploadItem: FC<DocumentUploadItemProps> = ({
  title,
  name,
  multiple,
  options,
  onChange,
  setOpenPreview,
  isDisplay,
  editable,
  isSalePerson,
  filesUploadOption,
  handleRadioChange,
}) => {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [logEditedInformation, setLogEditedInformation] =
    useState<logEditedItem[]>();

  const handleOpenLogEdited = useCallback(
    async (option: {
      personId?: string;
      headerId?: string;
      trackable_type?: string;
      isHasLog?: boolean;
      jp_name: string;
      en_name: keyof UploadedFileKeys;
      files?: (File | UploadedFilesManager)[];
      isRequired?: boolean;
    }) => {
      if (isSalePerson) {
        const result = await dispatch(
          getLogEditedSalePersonPreliminary({
            id: option.headerId,
            trackable_type: option.trackable_type,
            trackable_id: option.personId,
            column: option.en_name,
          })
        );
        if (getLogEditedSalePersonPreliminary.fulfilled.match(result)) {
          setLogEditedInformation(result.payload.data.value);
        }
        return;
      }
      const result = await dispatch(
        getLogEditedPreliminary({
          id: option.headerId,
          trackable_type: option.trackable_type,
          trackable_id: option.personId,
          column: option.en_name,
        })
      );
      if (getLogEditedPreliminary.fulfilled.match(result)) {
        setLogEditedInformation(result.payload.data.value);
      }
    },
    [dispatch, isSalePerson]
  );
  const optionAfterSelect = useMemo(() => {
    if (filesUploadOption) {
      return options.filter((option) =>
        option.en_name.includes(filesUploadOption)
      );
    }
    return options;
  }, [filesUploadOption, options]);

  const handleChangeFiles = useCallback(
    (files: File[], enName: keyof UploadedFileKeys) => {
      if (multiple) {
        const filesUpdated = options[0].files
          ? [...options[0].files].concat(files)
          : files;
        onChange && onChange(filesUpdated, '', enName);
      } else {
        onChange && onChange(files, '', enName);
      }
    },
    [multiple, onChange, options]
  );

  const onRemoveFile = useCallback(
    (enName: keyof UploadedFileKeys, index: number, id: string) => {
      if (options[0].files) {
        const filesUpload = [...options[0].files];
        filesUpload.splice(index, 1);
        onChange && onChange(filesUpload, id, enName);
      }
    },
    [onChange, options]
  );

  return (
    <Stack flex={1} display={isDisplay ? 'flex' : 'none'}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ paddingLeft: 5 }}
        >
          <Typography
            variant="title_avatar_card"
            sx={{ color: 'sp_primary_100_main' }}
          >
            {name}
          </Typography>
          <Typography
            variant="title_upload_file"
            sx={{ alignSelf: 'center', color: 'b_333' }}
          >
            {title}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={options.length > 1 ? 'row' : 'column'}>
        {options.length > 1 && (
          <Stack
            flex={1}
            sx={{
              width: '50%',
              ml: '44px',
              color: 'b_333',
              wordBreak: 'break-all',
            }}
          >
            <FormControl>
              <RadioGroup
                name="radio-buttons-group-file-option"
                value={filesUploadOption}
                onChange={(option) =>
                  handleRadioChange?.(option.target.value as FilesUploadOption)
                }
              >
                {RADIO_OPTIONS.map(({ label, value }) => (
                  <FormControlLabel
                    key={label}
                    value={value}
                    control={
                      <Radio
                        size="small"
                        icon={
                          <Icons.PulldownCheck sx={{ width: 16, height: 16 }} />
                        }
                        checkedIcon={
                          <Icons.PulldownChecked
                            sx={{ width: 16, height: 16 }}
                          />
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="option_upload_file"
                        sx={{ color: 'b_333', width: 'auto' }}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Stack>
        )}
        <Stack flex={1} sx={{ width: options.length > 1 ? '50%' : '100%' }}>
          {optionAfterSelect?.map((item, index) => (
            <Stack key={index}>
              <Stack
                direction="row"
                sx={{ position: 'relative', paddingBottom: '10px' }}
                justifyContent="space-between"
              >
                {options.length === 1 && (
                  <Stack flex={1} justifyContent="center">
                    <Typography
                      variant="option_upload_file"
                      sx={{
                        pl: '44px',
                        color: 'b_333',
                        wordBreak: 'break-all',
                      }}
                    >
                      {item.jp_name}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  flex={1}
                  direction="row"
                  sx={{
                    backgroundColor: 'bg_off',
                    p: 1,
                    borderRadius: '2px',
                    position: 'relative',
                    minWidth: 0,
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="flex-start"
                    flex={1}
                    spacing={1}
                    sx={{
                      paddingTop: '2px',
                      minHeight: '35px',
                      width: 'calc(100% - 145px)',
                    }}
                  >
                    {item.files?.map(
                      (file: UploadedFilesManager | File, index) => {
                        if (file instanceof File)
                          return (
                            <FileItem
                              index={index}
                              id=""
                              key={index}
                              name={file.name}
                              src={URL.createObjectURL(file)}
                              field_en={item.en_name}
                              handleRemoveDocument={onRemoveFile}
                              setOpenPreview={setOpenPreview}
                              editable={editable}
                            />
                          );
                        return (
                          <FileItem
                            index={index}
                            key={index}
                            name={file.filename}
                            src={file.url}
                            field_en={item.en_name}
                            id={file.id}
                            handleRemoveDocument={onRemoveFile}
                            setOpenPreview={setOpenPreview}
                            editable={editable}
                          />
                        );
                      }
                    )}
                  </Stack>
                  {item.isHasLog && (
                    <Icons.EditField
                      fill={'#333333'}
                      onClick={() => {
                        handleOpenLogEdited(item);
                        setOpenModal(true);
                      }}
                      sx={{ cursor: 'pointer', mt: '6px' }}
                    />
                  )}
                  <InputFileAdmin
                    disabled={!editable}
                    name={item.en_name}
                    sx={{ p: 0 }}
                    multiple={multiple}
                    onChange={(acceptedFiles) =>
                      handleChangeFiles(acceptedFiles, item.en_name)
                    }
                  >
                    <Button
                      disabled={!editable}
                      sx={{
                        minHeight: 36,
                        width: 121,
                        backgroundColor: 'main_white',
                        border: '1px solid #6B70F0',
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'sp_primary_100_main',
                          color: 'main_white',
                          '& .MuiTypography-root': {
                            color: 'main_white',
                          },
                        },
                      }}
                    >
                      <Typography
                        variant="pdf_title"
                        sx={{ color: 'sp_primary_100_main' }}
                      >
                        ファイルを選択
                      </Typography>
                    </Button>
                  </InputFileAdmin>
                  {logEditedInformation && (
                    <AdminEditModal
                      fieldNameEn={item.en_name}
                      fieldNameJP={item.jp_name}
                      open={openModal}
                      rows={[]}
                      onClose={() => {
                        setOpenModal((prevState) => !prevState);
                        setLogEditedInformation([]);
                      }}
                      tableRows={logEditedInformation}
                    />
                  )}
                </Stack>
              </Stack>
              {editable &&
                item.isRequired &&
                (!item.files || item.files.length === 0) &&
                (item.en_name === 'residence_file' ||
                  item.en_name === 'residence_file_back_image') && (
                  <Stack
                    sx={{
                      textAlign: 'right',
                      paddingBottom: '3px',
                      color: 'sp_secondary_01',
                    }}
                  >
                    本人の国籍は外国籍なので、在留カードをアップロードしてください。
                  </Stack>
                )}
              {editable &&
                item.isRequired &&
                (!item.files || item.files.length === 0) &&
                (item.en_name === 'driver_license_front_image' ||
                  item.en_name === 'driver_license_back_image' ||
                  item.en_name === 'card_number_front_image' ||
                  item.en_name === 'resident_register_front_image' ||
                  item.en_name === 'resident_register_back_image') && (
                  <Stack
                    sx={{
                      textAlign: 'right',
                      paddingBottom: '3px',
                      color: 'sp_secondary_01',
                    }}
                  >
                    この書類は必須な書類なので、アップロードしてください。
                  </Stack>
                )}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

type FileItemProps = {
  index: number;
  src: string;
  name: string;
  field_en?: keyof UploadedFileKeys;
  handleRemoveDocument?: (
    formikName: keyof UploadedFileKeys,
    index: number,
    id: string
  ) => void;
  setOpenPreview: (name: string, src: string) => void;
  editable: boolean;
  id: string;
};

const FileItem: FC<FileItemProps> = ({
  index,
  src,
  name,
  field_en,
  handleRemoveDocument,
  setOpenPreview,
  editable,
  id,
}) => {
  return (
    <Stack
      flex={1}
      key={index}
      direction="row"
      alignItems="center"
      sx={{
        minWidth: 0,
        overflow: 'hidden',
        pl: '10px',
        width: 'calc(100% - 10px)',
      }}
    >
      {editable && !FILES_REQUIRED.find((field) => field === field_en) && (
        <Icons.Cancel
          sx={{ width: 15, height: 15, mt: 1 }}
          onClick={() => {
            if (!!field_en && !!handleRemoveDocument)
              return handleRemoveDocument(field_en, index, id);
          }}
        />
      )}
      {getFileExtFromUrl(name).includes('pdf') ? (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#6B70F0',
          }}
        >
          {name}
        </Typography>
      ) : (
        <CustomWidthTooltip
          sx={{ bgcolor: 'main_white' }}
          title={
            <Card>
              <CardHeader
                title={name}
                titleTypographyProps={{
                  variant: 'title_card_preview',
                  color: 'b_333',
                }}
                subheader={<Divider />}
                sx={{
                  '&.MuiCardHeader-root': {
                    p: 0,
                    pt: '14px',
                    textAlign: 'center',
                  },
                  '& .MuiCardHeader-title': {
                    pb: '14px',
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{
                  bgcolor: 'main_white',
                  borderRadius: 5,
                  m: 'auto',
                  pt: 2,
                  height: 325,
                  width: 512,
                }}
                image={src}
              />
            </Card>
          }
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: '#6B70F0',
            }}
          >
            {name}
          </Typography>
        </CustomWidthTooltip>
      )}
    </Stack>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  transform: 'translate(-50%, -50%) !important',
  left: '50% !important',
  top: '50% !important',
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    backgroundColor: 'main_white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '2px',
    display: '',
  },
});

const FILES_REQUIRED = [
  'driver_license_front_image',
  'driver_license_back_image',
  'card_number_front_image',
  'card_number_back_image',
  'resident_register_front_image',
  'resident_register_back_image',
];

const RADIO_OPTIONS = [
  { label: '運転免許書', value: FILES_UPLOAD_OPTIONS.DRIVER_LICENSE },
  { label: 'マイナンバーカード', value: FILES_UPLOAD_OPTIONS.CARD_NUMBER },
  {
    label: '住民基本台帳カード',
    value: FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER,
  },
];
