import { z } from 'zod';
import {
  ApplicantDetailType,
  BorrowerType,
  BorrowingCategoryType,
  BorrowingFromHousingFinanceAgencyType,
  BorrowingStatusType,
  BusinessBorrowingType,
  BuyingandSellingScheduleType,
  ChildType,
  CollateralType,
  CommonHousingType,
  CoOwnerType,
  CurrentResidenceType,
  DispatchType,
  EstateMortgageType,
  FileATaxReturnType,
  FinalTaxReturnType,
  FinanceAgencyType,
  Flat35ApplicablePlanType,
  Flat35ApplicationType,
  FundsPairOffType,
  HasLandAdvancePlanType,
  JointOwnershipDivision,
  LoanPurposeType,
  LoanTargetPropertyType,
  LoanType,
  MaintenanceType,
  NationalityType,
  OccupationDetailType,
  OneRoofType,
  PersonOccupancyType,
  PlannedCohabitantType,
  PlanningAreaType,
  PositionType,
  PreliminaryStatusType,
  PurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
  RegionType,
  RelationshipToApplicantType,
  RelationshipType,
  RepaymentType,
  ResidentType,
  ScheduledLoanPayoffType,
  SexType,
  SourceOfIncomeType,
  SPBorrowingType,
} from 'constant';

export const salePersonPreliminariesRequestSchema = z.object({
  id: z.string(),
});

export const filesUploadSchema = z.object({
  id: z.string(),
  filename: z.string(),
  url: z.string(),
});

export const salePersonPreliminariesResponseSchema = z.object({
  success: z.boolean(),
  p_application_header: z.object({
    id: z.string(),
    user_id: z.string().optional(),
    edit_file_upload: z.number(),
    loan_apply_date: z.string(),
    created_at: z.string(),
    current_path: z.array(z.any()),
    relationship_to_applicant: z
      .nativeEnum(RelationshipToApplicantType)
      .optional(),
    application_number: z.string(),
    scheduled_date_moving: z.string(),
    name_master_banks: z.array(z.string()),
    master_bank_ids: z.array(z.number()).optional(),
    loan_type: z.nativeEnum(LoanType),
    loan_target: z.nativeEnum(PurposeType),
    pair_loan_applicant_first_name: z.string().optional(),
    pair_loan_applicant_last_name: z.string().optional(),
    pair_loan_relationship: z.string().optional(),
    pair_loan_relationship_name: z.string().optional(),
    loan_target_zero: z.nativeEnum(PurposeType).optional(),
    loan_target_default: z.nativeEnum(PurposeType).optional(),
    is_home_loan_plus: z.boolean(),
    has_land_advance_plan: z.nativeEnum(HasLandAdvancePlanType).optional(),
    preliminary_status: z.nativeEnum(PreliminaryStatusType).optional(),
    examination_file: z.object({
      enable: z.boolean(),
      path: z.string(),
      filename: z.string(),
    }),
    edit_main_statuses: z.boolean(),
    property_information_url: z.string(),
    person_occupancy: z.nativeEnum(PersonOccupancyType),
    children_number: z.union([z.string(), z.number()]),
    siblings_number: z.union([z.string(), z.number()]),
    other_people_number: z.union([z.string(), z.number()]),
    housemate_number: z.string(),
    business_ability: z
      .array(z.union([z.nativeEnum(LoanTargetPropertyType), z.string()]))
      .optional(),
    collateral_prefecture: z.string(),
    collateral_city: z.string(),
    collateral_lot_number: z.string(),
    additional_cost: z.string(),
    require_funds_breakdown_mortgage: z.string(),
    deposit_savings_1: z.string(),
    real_estate_sale_price: z.string(),
    other_saving_amount: z.string(),
    other_relationship: z.string().optional(),
    relative_donation_amount: z.string(),
    loan_amount: z.string(),
    other_procurement_breakdown: z.string(),
    other_procurement_breakdown_content: z.string().optional(),
    s_sale_person_id: z.string(),
    p_referral_agency_id: z.string(),
    planned_cohabitant: z.array(z.nativeEnum(PlannedCohabitantType)),
    property_postal_code: z.string(),
    collateral_address_kana: z.string(),
    collateral_type: z.nativeEnum(CollateralType),
    acquisition_time_of_the_land: z.string(),
    joint_ownership_division: z.nativeEnum(JointOwnershipDivision),
    building_ratio_numerator: z.string(),
    building_ratio_denominator: z.string(),
    land_ratio_numerator: z.string(),
    land_ratio_denominator: z.string(),
    land_price: z.string(),
    linking_id: z.string(),
    building_price: z.string(),
    land_and_building_price: z.string(),
    saving_amount: z.string(),
    amount_any_loans: z.string(),
    amount_others: z.string(),
    property_information_file_index: z.array(filesUploadSchema),
    manager_property_information_file_index: z.array(filesUploadSchema),
    business_card_index: z.array(filesUploadSchema),
    p_application_banks_attributes: z.array(
      z.object({
        id: z.string(),
        s_master_bank_id: z.string(),
        name: z.string(),
        _destroy: z.boolean(),
      })
    ),
    p_applicant_people_attributes: z.array(
      z.object({
        id: z.string(),
        applicant_detail_type: z.nativeEnum(ApplicantDetailType).optional(),
        has_join_guarantor: z.nativeEnum(CoOwnerType).optional(),
        last_name_kanji: z.string().optional(),
        first_name_kanji: z.string().optional(),
        full_name_kanji: z.string().optional(),
        last_name_kana: z.string().optional(),
        first_name_kana: z.string().optional(),
        sex: z.string().optional(),
        has_log_fields: z.array(z.string()),
        name_relationship_to_applicant: z.string().optional(),
        birthday: z.string().optional(),
        nationality: z.string().optional(),
        mobile_phone_number: z.string().optional(),
        home_phone_number: z.string().optional(),
        postal_code: z.string().optional(),
        prefecture_kanji: z.string().optional(),
        city_kanji: z.string().optional(),
        district_kanji: z.string().optional(),
        other_address_kanji: z.string().optional(),
        owner_email: z.string().optional(),
        occupation: z.string().optional(),
        other_occupation: z.string().optional(),
        industry: z.string().optional(),
        other_industry: z.string().optional(),
        occupation_detail: z.nativeEnum(OccupationDetailType).optional(),
        other_occupation_detail: z.string().optional(),
        office_name_kanji: z.string().optional(),
        department: z.string().optional(),
        office_phone_number: z.string().optional(),
        office_postal_code: z.string().optional(),
        office_prefecture_kanji: z.string().optional(),
        office_city_kanji: z.string().optional(),
        office_district_kanji: z.string().optional(),
        number_of_employee: z.string().optional(),
        employment_started_date: z.string().optional(),
        last_year_income: z.string().optional(),
        bonus_income: z.string().optional(),
        two_years_ago_income: z.string().optional(),
        tax_return: z.nativeEnum(FinalTaxReturnType).optional(),
        other_type_tax_return: z.string().optional(),
        transfer_office: z.nativeEnum(DispatchType).optional(),
        transfer_office_name_kanji: z.string().optional(),
        transfer_office_name_kana: z.string().optional(),
        transfer_office_phone_number: z.string().optional(),
        transfer_office_postal_code: z.string().optional(),
        transfer_office_prefecture_kanji: z.string().optional(),
        transfer_office_city_kanji: z.string().optional(),
        transfer_office_district_kanji: z.string().optional(),
        transfer_office_other_address_kanji: z.string().optional(),
        maternity_paternity_leave_status: z.string().optional(),
        maternity_paternity_leave_start_time: z.string().optional(),
        maternity_paternity_leave_end_time: z.string().optional(),
        nursing_leave_status: z.string().optional(),
        lived_length_year_num: z.string().optional(),
        lived_length_month_num: z.string().optional(),
        current_residence: z.nativeEnum(CurrentResidenceType).optional(),
        current_residence_floor_area: z.string().optional(),
        owner_full_name: z.string().optional(),
        owner_relationship: z.string().optional(),
        buyingand_selling_schedule_type: z
          .nativeEnum(BuyingandSellingScheduleType)
          .optional(),
        other_buyingand_selling_schedule_type: z.string().optional(),
        scheduled_time_sell_house: z.string().optional(),
        expected_house_selling_price: z.string().optional(),
        current_home_loan: z.string().optional(),
        reason_acquire_home: z
          .nativeEnum(ReasonsForNeedingHousingType)
          .optional(),
        other_reason_acquire_home: z.string().optional(),
        borrowing_status: z.nativeEnum(BorrowingStatusType).optional(),
        land_rent_to_be_paid: z.string().optional(),
        house_rent_to_be_paid: z.string().optional(),
        identity_verification: z.string().optional(),
        emergency_contact_number: z.string().optional(),
        prefecture_kana: z.string().optional(),
        city_kana: z.string().optional(),
        district_kana: z.string().optional(),
        other_address_kana: z.string().optional(),
        emplmt_form_code: z.string().optional(),
        office_name_kana: z.string().optional(),
        position: z.nativeEnum(PositionType).optional(),
        headquarters_location: z.string().optional(),
        listed_division: z.string().optional(),
        office_establishment_date: z.string().optional(),
        capital_stock: z.string().optional(),
        other_office_address_kanji: z.string().optional(),
        office_prefecture_kana: z.string().optional(),
        office_city_kana: z.string().optional(),
        office_district_kana: z.string().optional(),
        other_office_address_kana: z.string().optional(),
        relationship_to_applicant: z
          .nativeEnum(RelationshipToApplicantType)
          .optional(),
        other_relationship_to_applicant: z.string().optional(),
        _destroy: z.boolean().optional(),
        income_source: z.array(z.nativeEnum(SourceOfIncomeType)),
        main_income_source: z.nativeEnum(SourceOfIncomeType),
        type_tax_return: z.array(z.nativeEnum(FileATaxReturnType)),
        personal_file_upload: z.object({
          driver_license_front_image: z.array(filesUploadSchema),
          driver_license_back_image: z.array(filesUploadSchema),
          card_number_front_image: z.array(filesUploadSchema),
          // card_number_back_image: z.array(filesUploadSchema),
          resident_register_front_image: z.array(filesUploadSchema),
          resident_register_back_image: z.array(filesUploadSchema),
          insurance_file: z.array(filesUploadSchema),
          insurance_file_back_image: z.array(filesUploadSchema),
          first_withholding_slip_file: z.array(filesUploadSchema),
          second_withholding_slip_file: z.array(filesUploadSchema),
          first_income_file: z.array(filesUploadSchema),
          second_income_file: z.array(filesUploadSchema),
          third_income_file: z.array(filesUploadSchema),
          financial_statement_1_file: z.array(filesUploadSchema),
          financial_statement_2_file: z.array(filesUploadSchema),
          financial_statement_3_file: z.array(filesUploadSchema),
          other_document_file: z.array(filesUploadSchema),
          employment_agreement_file: z.array(filesUploadSchema),
          business_tax_return_1_file: z.array(filesUploadSchema),
          business_tax_return_2_file: z.array(filesUploadSchema),
          business_tax_return_3_file: z.array(filesUploadSchema),
          residence_file: z.array(filesUploadSchema),
          residence_file_back_image: z.array(filesUploadSchema),
        }),
      })
    ),
    p_borrowing_details_attributes: z.array(
      z.object({
        id: z.string(),
        loan_desired_borrowing_date: z.string().optional(),
        temporary_desired_loan_amount: z.string().optional(),
        halfyear_bonus: z.string().optional(),
        desired_monthly_bonus: z.string().optional(),
        loan_term_year_num: z.string(),
        loan_term_month_num: z.string(),
        repayment_method: z.nativeEnum(RepaymentType).optional(),
        borrowing_detail_type: z.string().optional(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean().optional(),
      })
    ),
    p_join_guarantors_attributes: z.array(
      z.object({
        last_name_kanji: z.string(),
        first_name_kanji: z.string(),
        last_name_kana: z.string(),
        first_name_kana: z.string(),
        sex: z.nativeEnum(SexType),
        guarantor_relationship_name: z.string(),
        has_log_fields: z.array(z.string()),
        birthday: z.string(),
        mobile_phone_number: z.string(),
        home_phone_number: z.string(),
        postal_code: z.string(),
        prefecture_kanji: z.string(),
        city_kanji: z.string(),
        district_kanji: z.string(),
        other_address_kanji: z.string(),
        id: z.string(),
        guarantor_relationship_to_applicant: z.string(),
        other_relationship_to_applicant: z.string(),
        emergency_contact_number: z.string().optional(),
        prefecture_kana: z.string(),
        city_kana: z.string(),
        district_kana: z.string(),
        other_address_kana: z.string(),
        owner_email: z.string(),
        _destroy: z.boolean(),
      })
    ),
    p_borrowings_attributes: z.array(
      z.object({
        id: z.string(),
        has_log_fields: z.array(z.string()),
        borrowing_type: z.nativeEnum(SPBorrowingType).optional(),
        borrower: z.nativeEnum(BorrowerType).optional(),
        lender: z.string().optional(),
        borrowing_from_housing_finance_agency: z
          .nativeEnum(BorrowingFromHousingFinanceAgencyType)
          .optional(),
        loan_purpose: z.nativeEnum(LoanPurposeType).optional(),
        business_borrowing_type: z.nativeEnum(BusinessBorrowingType).optional(),
        other_purpose: z.string().optional(),
        specific_loan_purpose: z.string(),
        borrowing_category: z.nativeEnum(BorrowingCategoryType).optional(),
        loan_start_date: z.string().optional(),
        loan_amount: z.string().optional(),
        current_balance_amount: z.string().optional(),
        annual_repayment_amount: z.string().optional(),
        card_expiry_date: z.string().optional(),
        scheduled_loan_payoff: z.nativeEnum(ScheduledLoanPayoffType).optional(),
        loan_deadline_date: z.string().optional(),
        expected_repayment_date: z.string().optional(),
        rental_room_number: z.string().optional(),
        common_housing: z.nativeEnum(CommonHousingType).optional(),
        estate_mortgage: z.nativeEnum(EstateMortgageType).optional(),
        _destroy: z.boolean().optional(),
        include_in_examination: z.string().optional(),
        repayment_schedule_image_index: z.array(filesUploadSchema),
      })
    ),
    p_residents_attributes: z.array(z.any()),
    sale_person_information: z.object({
      has_log_fields: z.array(z.string()),
      list_agencies: z.array(
        z.object({
          id: z.string(),
          sale_agent_id: z.string(),
          sale_agent_name_kanji: z.string(),
        })
      ),
      list_stores: z.array(
        z.object({
          id: z.string(),
          store_id: z.string(),
          store_name_kanji: z.string(),
        })
      ),
      list_exhibitions: z.array(
        z.object({
          id: z.string(),
          exhibition_id: z.string(),
          exhibition_hall: z.string(),
        })
      ),
      list_sale_persons: z.array(
        z.object({
          id: z.string(),
          value: z.string(),
        })
      ),
      sale_person_name: z.string().optional(),
      sale_person_email: z.string().optional(),
      sale_person_phone: z.string().optional(),
      // sale_person_phone_number: z.string().optional(),
      active_store_id: z.string().optional(),
      active_exhibition_id: z.string().optional(),
      active_agency_id: z.string().optional(),
      select_agency: z.boolean().optional(),
      select_exhibitions: z.boolean().optional(),
      select_stores: z.boolean().optional(),
    }),
    sale_person_phone_number: z.string().optional(),
    sale_person_name_input: z.string().optional(),
    editable: z.boolean(),
    main_statuses: z.string(),
    // provisional_results: z.array(
    //   z.object({
    //     key: z.string(),
    //     value: z.number(),
    //     active: z.boolean(),
    //   })
    // ),
    public_status: z.array(
      z.object({
        text: z.string(),
        active: z.boolean(),
      })
    ),
    collateral_land_area: z.string().optional(),
    occupied_area: z.string().optional(),
    collateral_total_floor_area: z.string().optional(),
    house_purchase_price: z.string().optional(),
    land_purchase_price: z.string().optional(),
    purchase_purpose: z.string().optional(),
    accessory_cost: z.string().optional(),
    refinancing_loan_balance: z.string().optional(),
    house_upgrade_cost: z.string().optional(),
    pair_loan_amount: z.string().optional(),
    non_resident_reason: z.string().optional(),
    condominium_name: z.string().optional(),
    land_ownership: z.string().optional(),
    planning_area: z.nativeEnum(PlanningAreaType).optional(),
    other_planning_area: z.string().optional(),
    rebuilding_reason: z.nativeEnum(RebuildingReasonType).optional(),
    other_rebuilding_reason: z.string().optional(),
    flat_35_applicable_plan: z.nativeEnum(Flat35ApplicablePlanType).optional(),
    maintenance_type: z.nativeEnum(MaintenanceType).optional(),
    region_type: z.nativeEnum(RegionType).optional(),
    flat_35_application: z.nativeEnum(Flat35ApplicationType).optional(),
    completely_repayment_type: z.nativeEnum(FundsPairOffType).optional(),
    other_complete_repayment_type: z.string().optional(),
    refund_content: z.string().optional(),
    refund_amount: z.string().optional(),
    has_log_fields: z.array(z.string()),
  }),
});

export const salePersonPreliminariesSchema = z.object({
  p_application_header: z.object({
    id: z.string(),
    user_id: z.string().optional(),
    loan_apply_date: z.string(),
    created_at: z.string(),
    current_path: z.array(z.any()),
    edit_file_upload: z.number(),
    relationship_to_applicant: z
      .nativeEnum(RelationshipToApplicantType)
      .optional(),
    application_number: z.string(),
    scheduled_date_moving: z.string(),
    name_master_banks: z.array(z.string()),
    master_bank_ids: z.array(z.number()).optional(),
    loan_type: z.nativeEnum(LoanType),
    loan_target: z.nativeEnum(PurposeType),
    pair_loan_applicant_first_name: z.string().optional(),
    pair_loan_applicant_last_name: z.string().optional(),
    pair_loan_relationship: z.string().optional(),
    pair_loan_relationship_name: z.string().optional(),
    loan_target_zero: z.nativeEnum(PurposeType).optional(),
    loan_target_default: z.nativeEnum(PurposeType).optional(),
    is_home_loan_plus: z.boolean(),
    has_land_advance_plan: z.nativeEnum(HasLandAdvancePlanType).optional(),
    preliminary_status: z.nativeEnum(PreliminaryStatusType).optional(),
    edit_main_statuses: z.boolean(),
    property_information_url: z.string(),
    person_occupancy: z.nativeEnum(PersonOccupancyType),
    children_number: z.union([z.string(), z.number()]),
    siblings_number: z.union([z.string(), z.number()]),
    other_people_number: z.union([z.string(), z.number()]),
    housemate_number: z.string(),
    examination_file: z.object({
      enable: z.boolean(),
      path: z.string(),
      filename: z.string(),
    }),
    business_ability: z
      .array(z.union([z.nativeEnum(LoanTargetPropertyType), z.string()]))
      .optional(),
    collateral_prefecture: z.string(),
    collateral_city: z.string(),
    collateral_lot_number: z.string(),
    additional_cost: z.string(),
    require_funds_breakdown_mortgage: z.string(),
    deposit_savings_1: z.string(),
    real_estate_sale_price: z.string(),
    other_saving_amount: z.string(),
    other_relationship: z.string().optional(),
    relative_donation_amount: z.string(),
    loan_amount: z.string(),
    other_procurement_breakdown: z.string(),
    other_procurement_breakdown_content: z.string().optional(),
    s_sale_person_id: z.string(),
    p_referral_agency_id: z.string(),
    planned_cohabitant: z.array(z.nativeEnum(PlannedCohabitantType)),
    property_postal_code: z.string(),
    collateral_address_kana: z.string(),
    collateral_type: z.nativeEnum(CollateralType),
    acquisition_time_of_the_land: z.string(),
    joint_ownership_division: z.nativeEnum(JointOwnershipDivision),
    building_ratio_numerator: z.string(),
    building_ratio_denominator: z.string(),
    land_ratio_numerator: z.string(),
    land_ratio_denominator: z.string(),
    land_price: z.string(),
    linking_id: z.string(),
    building_price: z.string(),
    land_and_building_price: z.string(),
    saving_amount: z.string(),
    amount_any_loans: z.string(),
    amount_others: z.string(),
    property_information_file_index: z.array(filesUploadSchema),
    manager_property_information_file_index: z.array(filesUploadSchema),
    business_card_index: z.array(filesUploadSchema),
    p_application_banks_attributes: z.array(
      z.object({
        id: z.string(),
        s_master_bank_id: z.string(),
        name: z.string(),
        _destroy: z.boolean(),
      })
    ),
    p_applicant_people_attributes: z.array(
      z.object({
        id: z.string(),
        applicant_detail_type: z.nativeEnum(ApplicantDetailType).optional(),
        has_join_guarantor: z.nativeEnum(CoOwnerType).optional(),
        last_name_kanji: z.string().optional(),
        first_name_kanji: z.string().optional(),
        full_name_kanji: z.string().optional(),
        last_name_kana: z.string().optional(),
        first_name_kana: z.string().optional(),
        sex: z.string().optional(),
        name_relationship_to_applicant: z.string().optional(),
        birthday: z.string().optional(),
        nationality: z.string().optional(),
        mobile_phone_number: z.string().optional(),
        home_phone_number: z.string().optional(),
        postal_code: z.string().optional(),
        prefecture_kanji: z.string().optional(),
        city_kanji: z.string().optional(),
        district_kanji: z.string().optional(),
        other_address_kanji: z.string().optional(),
        owner_email: z.string().optional(),
        occupation: z.string().optional(),
        other_occupation: z.string().optional(),
        industry: z.string().optional(),
        other_industry: z.string().optional(),
        occupation_detail: z.nativeEnum(OccupationDetailType).optional(),
        other_occupation_detail: z.string().optional(),
        office_name_kanji: z.string().optional(),
        department: z.string().optional(),
        office_phone_number: z.string().optional(),
        office_postal_code: z.string().optional(),
        office_prefecture_kanji: z.string().optional(),
        office_city_kanji: z.string().optional(),
        office_district_kanji: z.string().optional(),
        number_of_employee: z.string().optional(),
        employment_started_date: z.string().optional(),
        last_year_income: z.string().optional(),
        bonus_income: z.string().optional(),
        two_years_ago_income: z.string().optional(),
        tax_return: z.nativeEnum(FinalTaxReturnType).optional(),
        other_type_tax_return: z.string().optional(),
        transfer_office: z.nativeEnum(DispatchType).optional(),
        transfer_office_name_kanji: z.string().optional(),
        transfer_office_name_kana: z.string().optional(),
        transfer_office_phone_number: z.string().optional(),
        transfer_office_postal_code: z.string().optional(),
        transfer_office_prefecture_kanji: z.string().optional(),
        transfer_office_city_kanji: z.string().optional(),
        transfer_office_district_kanji: z.string().optional(),
        transfer_office_other_address_kanji: z.string().optional(),
        maternity_paternity_leave_status: z.string().optional(),
        maternity_paternity_leave_start_time: z.string().optional(),
        maternity_paternity_leave_end_time: z.string().optional(),
        nursing_leave_status: z.string().optional(),
        lived_length_year_num: z.string().optional(),
        lived_length_month_num: z.string().optional(),
        current_residence: z.nativeEnum(CurrentResidenceType).optional(),
        current_residence_floor_area: z.string().optional(),
        owner_full_name: z.string().optional(),
        owner_relationship: z.string().optional(),
        buyingand_selling_schedule_type: z
          .nativeEnum(BuyingandSellingScheduleType)
          .optional(),
        other_buyingand_selling_schedule_type: z.string().optional(),
        scheduled_time_sell_house: z.string().optional(),
        expected_house_selling_price: z.string().optional(),
        current_home_loan: z.string().optional(),
        reason_acquire_home: z
          .nativeEnum(ReasonsForNeedingHousingType)
          .optional(),
        other_reason_acquire_home: z.string().optional(),
        borrowing_status: z.nativeEnum(BorrowingStatusType).optional(),
        land_rent_to_be_paid: z.string().optional(),
        house_rent_to_be_paid: z.string().optional(),
        identity_verification: z.string().optional(),
        emergency_contact_number: z.string().optional(),
        prefecture_kana: z.string().optional(),
        city_kana: z.string().optional(),
        district_kana: z.string().optional(),
        other_address_kana: z.string().optional(),
        emplmt_form_code: z.string().optional(),
        office_name_kana: z.string().optional(),
        position: z.nativeEnum(PositionType).optional(),
        headquarters_location: z.string().optional(),
        listed_division: z.string().optional(),
        office_establishment_date: z.string().optional(),
        capital_stock: z.string().optional(),
        other_office_address_kanji: z.string().optional(),
        office_prefecture_kana: z.string().optional(),
        office_city_kana: z.string().optional(),
        office_district_kana: z.string().optional(),
        other_office_address_kana: z.string().optional(),
        relationship_to_applicant: z
          .nativeEnum(RelationshipToApplicantType)
          .optional(),
        other_relationship_to_applicant: z.string().optional(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean().optional(),
        income_source: z.array(z.nativeEnum(SourceOfIncomeType)),
        main_income_source: z.nativeEnum(SourceOfIncomeType),
        type_tax_return: z.array(z.nativeEnum(FileATaxReturnType)),
        personal_file_upload: z.object({
          driver_license_front_image: z.array(filesUploadSchema),
          driver_license_back_image: z.array(filesUploadSchema),
          card_number_front_image: z.array(filesUploadSchema),
          // card_number_back_image: z.array(filesUploadSchema),
          resident_register_front_image: z.array(filesUploadSchema),
          resident_register_back_image: z.array(filesUploadSchema),
          insurance_file: z.array(filesUploadSchema),
          insurance_file_back_image: z.array(filesUploadSchema),
          first_withholding_slip_file: z.array(filesUploadSchema),
          second_withholding_slip_file: z.array(filesUploadSchema),
          first_income_file: z.array(filesUploadSchema),
          second_income_file: z.array(filesUploadSchema),
          third_income_file: z.array(filesUploadSchema),
          financial_statement_1_file: z.array(filesUploadSchema),
          financial_statement_2_file: z.array(filesUploadSchema),
          financial_statement_3_file: z.array(filesUploadSchema),
          other_document_file: z.array(filesUploadSchema),
          employment_agreement_file: z.array(filesUploadSchema),
          business_tax_return_1_file: z.array(filesUploadSchema),
          business_tax_return_2_file: z.array(filesUploadSchema),
          business_tax_return_3_file: z.array(filesUploadSchema),
          residence_file: z.array(filesUploadSchema),
          residence_file_back_image: z.array(filesUploadSchema),
        }),
      })
    ),
    p_borrowing_details_attributes: z.array(
      z.object({
        id: z.string(),
        loan_desired_borrowing_date: z.string(),
        temporary_desired_loan_amount: z.string(),
        halfyear_bonus: z.string(),
        desired_monthly_bonus: z.string(),
        loan_term_year_num: z.string(),
        loan_term_month_num: z.string(),
        repayment_method: z.nativeEnum(RepaymentType),
        borrowing_detail_type: z.string(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean(),
      })
    ),
    p_join_guarantors_attributes: z.array(
      z.object({
        last_name_kanji: z.string(),
        first_name_kanji: z.string(),
        last_name_kana: z.string(),
        first_name_kana: z.string(),
        sex: z.nativeEnum(SexType),
        guarantor_relationship_name: z.string(),
        birthday: z.string(),
        mobile_phone_number: z.string(),
        home_phone_number: z.string(),
        postal_code: z.string(),
        prefecture_kanji: z.string(),
        city_kanji: z.string(),
        district_kanji: z.string(),
        other_address_kanji: z.string(),
        id: z.string(),
        guarantor_relationship_to_applicant: z.string(),
        other_relationship_to_applicant: z.string(),
        emergency_contact_number: z.string().optional(),
        prefecture_kana: z.string(),
        city_kana: z.string(),
        district_kana: z.string(),
        other_address_kana: z.string(),
        owner_email: z.string(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean(),
      })
    ),
    p_borrowings_attributes: z.array(
      z.object({
        id: z.string(),
        borrowing_type: z.nativeEnum(SPBorrowingType).optional(),
        borrower: z.nativeEnum(BorrowerType).optional(),
        lender: z.string().optional(),
        borrowing_from_housing_finance_agency: z
          .nativeEnum(BorrowingFromHousingFinanceAgencyType)
          .optional(),
        loan_purpose: z.nativeEnum(LoanPurposeType).optional(),
        business_borrowing_type: z.nativeEnum(BusinessBorrowingType).optional(),
        other_purpose: z.string().optional(),
        specific_loan_purpose: z.string(),
        borrowing_category: z.nativeEnum(BorrowingCategoryType).optional(),
        loan_start_date: z.string().optional(),
        loan_amount: z.string().optional(),
        current_balance_amount: z.string().optional(),
        annual_repayment_amount: z.string().optional(),
        card_expiry_date: z.string().optional(),
        scheduled_loan_payoff: z.nativeEnum(ScheduledLoanPayoffType).optional(),
        loan_deadline_date: z.string().optional(),
        expected_repayment_date: z.string().optional(),
        rental_room_number: z.string().optional(),
        common_housing: z.nativeEnum(CommonHousingType).optional(),
        estate_mortgage: z.nativeEnum(EstateMortgageType).optional(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean().optional(),
        include_in_examination: z.string().optional(),
        repayment_schedule_image_index: z.array(filesUploadSchema),
      })
    ),
    p_residents_attributes: z.array(
      z.object({
        last_name_kanji: z.string(),
        first_name_kanji: z.string(),
        last_name_kana: z.string(),
        first_name_kana: z.string(),
        relationship_name: z.string(),
        nationality: z.nativeEnum(NationalityType),
        birthday: z.string(),
        loan_from_japan_housing_finance_agency: z.nativeEnum(FinanceAgencyType),
        home_phone_number: z.string(),
        postal_code: z.string(),
        prefecture_kanji: z.string(),
        city_kanji: z.string(),
        district_kanji: z.string(),
        other_address_kanji: z.string(),
        relationship: z.nativeEnum(RelationshipType),
        other_relationship: z.nativeEnum(ChildType),
        sex: z.nativeEnum(SexType),
        full_name_kanji: z.string(),
        full_name_kana: z.string(),
        resident_type: z.nativeEnum(ResidentType).optional(),
        one_roof: z.nativeEnum(OneRoofType).optional(),
      })
    ),
    sale_person_information: z.object({
      has_log_fields: z.array(z.string()),
      list_agencies: z.array(
        z.object({
          id: z.string(),
          sale_agent_id: z.string(),
          sale_agent_name_kanji: z.string(),
        })
      ),
      list_stores: z.array(
        z.object({
          id: z.string(),
          store_id: z.string(),
          store_name_kanji: z.string(),
        })
      ),
      list_exhibitions: z.array(
        z.object({
          id: z.string(),
          exhibition_id: z.string(),
          exhibition_hall: z.string(),
        })
      ),
      list_sale_persons: z.array(
        z.object({
          id: z.string(),
          value: z.string(),
        })
      ),
      sale_person_name: z.string().optional().nullable(),
      sale_person_email: z.string().optional().nullable(),
      sale_person_phone: z.string().optional().nullable(),
      active_store_id: z.string().optional().nullable(),
      active_exhibition_id: z.string().optional().nullable(),
      active_agency_id: z.string().optional().nullable(),
      select_agency: z.boolean().optional(),
      select_exhibitions: z.boolean().optional(),
      select_stores: z.boolean().optional(),
    }),
    sale_person_phone_number: z.string().optional(),
    sale_person_name_input: z.string(),
    editable: z.boolean(),
    main_statuses: z.string(),
    public_status: z.array(
      z.object({
        text: z.string(),
        active: z.boolean(),
      })
    ),
    collateral_land_area: z.string().optional(),
    occupied_area: z.string().optional(),
    collateral_total_floor_area: z.string().optional(),
    house_purchase_price: z.string().optional(),
    purchase_purpose: z.string().optional(),
    land_purchase_price: z.string().optional(),
    accessory_cost: z.string().optional(),
    refinancing_loan_balance: z.string().optional(),
    house_upgrade_cost: z.string().optional(),
    pair_loan_amount: z.string().optional(),
    non_resident_reason: z.string().optional(),
    condominium_name: z.string().optional(),
    land_ownership: z.string().optional(),
    planning_area: z.nativeEnum(PlanningAreaType).optional(),
    other_planning_area: z.string().optional(),
    rebuilding_reason: z.nativeEnum(RebuildingReasonType).optional(),
    other_rebuilding_reason: z.string().optional(),
    flat_35_applicable_plan: z.nativeEnum(Flat35ApplicablePlanType).optional(),
    maintenance_type: z.nativeEnum(MaintenanceType).optional(),
    region_type: z.nativeEnum(RegionType).optional(),
    flat_35_application: z.nativeEnum(Flat35ApplicationType).optional(),
    completely_repayment_type: z.nativeEnum(FundsPairOffType).optional(),
    other_complete_repayment_type: z.string().optional(),
    refund_content: z.string().optional(),
    refund_amount: z.string().optional(),
    has_log_fields: z.array(z.string()),
    exhibition_hall: z.string().optional(),
    sale_agent_name_kanji: z.string().optional(),
    store_name_kanji: z.string().optional(),
  }),
});

export const editSalePersonPreliminariesRequestSchema = z.object({
  id: z.string(),
  p_application_header: z
    .object({
      id: z.string(),
      loan_apply_date: z.string(),
      relationship_to_applicant: z
        .nativeEnum(RelationshipToApplicantType)
        .optional(),
      application_number: z.string(),
      scheduled_date_moving: z.string(),
      master_bank_ids: z.array(z.number()),
      loan_type: z.nativeEnum(LoanType),
      loan_target: z.nativeEnum(PurposeType),
      pair_loan_applicant_first_name: z.string().optional(),
      pair_loan_applicant_last_name: z.string().optional(),
      pair_loan_relationship: z.string().optional(),
      pair_loan_relationship_name: z.string().optional(),
      loan_target_zero: z.nativeEnum(PurposeType).optional(),
      loan_target_default: z.nativeEnum(PurposeType).optional(),
      is_home_loan_plus: z.number(),
      has_land_advance_plan: z.nativeEnum(HasLandAdvancePlanType).optional(),
      preliminary_status: z.nativeEnum(PreliminaryStatusType).optional(),
      edit_main_statuses: z.boolean(),
      property_information_url: z.string(),
      person_occupancy: z.nativeEnum(PersonOccupancyType),
      children_number: z.union([z.string(), z.number()]),
      siblings_number: z.union([z.string(), z.number()]),
      other_people_number: z.union([z.string(), z.number()]),
      housemate_number: z.string(),
      business_ability: z
        .array(z.union([z.nativeEnum(LoanTargetPropertyType), z.string()]))
        .optional(),
      collateral_prefecture: z.string(),
      collateral_city: z.string(),
      collateral_lot_number: z.string(),
      additional_cost: z.string(),
      require_funds_breakdown_mortgage: z.string(),
      deposit_savings_1: z.string(),
      real_estate_sale_price: z.string(),
      other_saving_amount: z.string(),
      relative_donation_amount: z.string(),
      loan_amount: z.string(),
      other_procurement_breakdown: z.string(),
      other_procurement_breakdown_content: z.string().optional(),
      s_sale_person_id: z.string(),
      p_referral_agency_id: z.string(),
      planned_cohabitant: z.array(z.nativeEnum(PlannedCohabitantType)),
      property_postal_code: z.string(),
      collateral_address_kana: z.string(),
      collateral_type: z.nativeEnum(CollateralType),
      acquisition_time_of_the_land: z.string(),
      joint_ownership_division: z.nativeEnum(JointOwnershipDivision),
      building_ratio_numerator: z.string(),
      building_ratio_denominator: z.string(),
      land_ratio_numerator: z.string(),
      land_ratio_denominator: z.string(),
      land_price: z.string(),
      building_price: z.string(),
      land_and_building_price: z.string(),
      saving_amount: z.string(),
      amount_any_loans: z.string(),
      amount_others: z.string(),
      p_applicant_people_attributes: z.array(
        z.object({
          id: z.string(),
          applicant_detail_type: z.nativeEnum(ApplicantDetailType),
          has_join_guarantor: z.nativeEnum(CoOwnerType),
          last_name_kanji: z.string(),
          first_name_kanji: z.string(),
          last_name_kana: z.string(),
          first_name_kana: z.string(),
          sex: z.string(),
          name_relationship_to_applicant: z.string(),
          birthday: z.string(),
          nationality: z.string(),
          mobile_phone_number: z.string(),
          home_phone_number: z.string(),
          postal_code: z.string(),
          prefecture_kanji: z.string(),
          city_kanji: z.string(),
          district_kanji: z.string(),
          other_address_kanji: z.string(),
          owner_email: z.string(),
          occupation: z.string(),
          other_occupation: z.string(),
          industry: z.string(),
          other_industry: z.string(),
          occupation_detail: z.nativeEnum(OccupationDetailType),
          other_occupation_detail: z.string(),
          office_name_kanji: z.string(),
          department: z.string(),
          office_phone_number: z.string(),
          office_postal_code: z.string(),
          office_prefecture_kanji: z.string(),
          office_city_kanji: z.string(),
          office_district_kanji: z.string(),
          number_of_employee: z.string(),
          employment_started_date: z.string(),
          last_year_income: z.string(),
          bonus_income: z.string(),
          two_years_ago_income: z.string(),
          tax_return: z.nativeEnum(FinalTaxReturnType),
          other_type_tax_return: z.string(),
          transfer_office: z.nativeEnum(DispatchType),
          transfer_office_name_kanji: z.string(),
          transfer_office_name_kana: z.string(),
          transfer_office_phone_number: z.string(),
          transfer_office_postal_code: z.string(),
          transfer_office_prefecture_kanji: z.string(),
          transfer_office_city_kanji: z.string(),
          transfer_office_district_kanji: z.string(),
          transfer_office_other_address_kanji: z.string(),
          maternity_paternity_leave_status: z.string(),
          maternity_paternity_leave_start_time: z.string(),
          maternity_paternity_leave_end_time: z.string(),
          nursing_leave_status: z.string(),
          lived_length_year_num: z.string(),
          lived_length_month_num: z.string(),
          current_residence: z.nativeEnum(CurrentResidenceType),
          current_residence_floor_area: z.string(),
          owner_full_name: z.string(),
          owner_relationship: z.string(),
          buyingand_selling_schedule_type: z.nativeEnum(
            BuyingandSellingScheduleType
          ),
          other_buyingand_selling_schedule_type: z.string(),
          scheduled_time_sell_house: z.string(),
          expected_house_selling_price: z.string(),
          current_home_loan: z.string(),
          reason_acquire_home: z.nativeEnum(ReasonsForNeedingHousingType),
          other_reason_acquire_home: z.string(),
          borrowing_status: z.nativeEnum(BorrowingStatusType),
          land_rent_to_be_paid: z.string(),
          house_rent_to_be_paid: z.string(),
          identity_verification: z.string(),
          emergency_contact_number: z.string().optional(),
          prefecture_kana: z.string(),
          city_kana: z.string(),
          district_kana: z.string(),
          other_address_kana: z.string(),
          emplmt_form_code: z.string(),
          office_name_kana: z.string(),
          position: z.nativeEnum(PositionType),
          headquarters_location: z.string(),
          listed_division: z.string(),
          office_establishment_date: z.string(),
          capital_stock: z.string(),
          other_office_address_kanji: z.string(),
          office_prefecture_kana: z.string(),
          office_city_kana: z.string(),
          office_district_kana: z.string(),
          other_office_address_kana: z.string(),
          relationship_to_applicant: z
            .nativeEnum(RelationshipToApplicantType)
            .optional(),
          other_relationship_to_applicant: z.string(),
          _destroy: z.boolean(),
          income_source: z.array(z.nativeEnum(SourceOfIncomeType)),
          main_income_source: z.nativeEnum(SourceOfIncomeType),
          type_tax_return: z.array(z.nativeEnum(FileATaxReturnType)),
        })
      ),
      p_borrowing_details_attributes: z.array(
        z.object({
          id: z.string(),
          loan_desired_borrowing_date: z.string(),
          temporary_desired_loan_amount: z.string(),
          halfyear_bonus: z.string(),
          desired_monthly_bonus: z.string(),
          loan_term_year_num: z.string(),
          loan_term_month_num: z.string(),
          repayment_method: z.nativeEnum(RepaymentType),
          borrowing_detail_type: z.string(),
          _destroy: z.boolean(),
        })
      ),
      p_join_guarantors_attributes: z.array(
        z.object({
          last_name_kanji: z.string(),
          first_name_kanji: z.string(),
          last_name_kana: z.string(),
          first_name_kana: z.string(),
          sex: z.nativeEnum(SexType),
          guarantor_relationship_name: z.string(),
          birthday: z.string(),
          mobile_phone_number: z.string(),
          home_phone_number: z.string(),
          postal_code: z.string(),
          prefecture_kanji: z.string(),
          city_kanji: z.string(),
          district_kanji: z.string(),
          other_address_kanji: z.string(),
          id: z.string(),
          guarantor_relationship_to_applicant: z.string(),
          other_relationship_to_applicant: z.string(),
          emergency_contact_number: z.string().optional(),
          prefecture_kana: z.string(),
          city_kana: z.string(),
          district_kana: z.string(),
          other_address_kana: z.string(),
          owner_email: z.string(),
          _destroy: z.boolean(),
        })
      ),
      p_borrowings_attributes: z.array(
        z.object({
          id: z.string(),
          borrowing_type: z.nativeEnum(SPBorrowingType),
          borrower: z.nativeEnum(BorrowerType),
          lender: z.string(),
          borrowing_from_housing_finance_agency: z.nativeEnum(
            BorrowingFromHousingFinanceAgencyType
          ),
          loan_purpose: z.nativeEnum(LoanPurposeType),
          business_borrowing_type: z
            .nativeEnum(BusinessBorrowingType)
            .optional(),
          other_purpose: z.string(),
          specific_loan_purpose: z.string(),
          borrowing_category: z.nativeEnum(BorrowingCategoryType),
          loan_start_date: z.string(),
          loan_amount: z.string(),
          current_balance_amount: z.string(),
          annual_repayment_amount: z.string(),
          card_expiry_date: z.string(),
          scheduled_loan_payoff: z.nativeEnum(ScheduledLoanPayoffType),
          loan_deadline_date: z.string(),
          expected_repayment_date: z.string(),
          rental_room_number: z.string(),
          common_housing: z.nativeEnum(CommonHousingType),
          estate_mortgage: z.nativeEnum(EstateMortgageType),
          _destroy: z.boolean(),
          include_in_examination: z.string(),
          repayment_schedule_image_index: z.array(filesUploadSchema),
        })
      ),
      p_residents_attributes: z.array(z.any()),
      sale_person_information: z.object({
        list_agencies: z.array(
          z.object({
            id: z.string(),
            sale_agent_id: z.string(),
            sale_agent_name_kanji: z.string(),
          })
        ),
        list_stores: z.array(
          z.object({
            id: z.string(),
            store_id: z.string(),
            store_name_kanji: z.string(),
          })
        ),
        list_exhibitions: z.array(
          z.object({
            id: z.string(),
            exhibition_id: z.string(),
            exhibition_hall: z.string(),
          })
        ),
        list_sale_persons: z.array(
          z.object({
            id: z.string(),
            value: z.string(),
          })
        ),
        sale_person_name: z.string().optional(),
        sale_person_email: z.string().optional(),
        sale_person_phone: z.string().optional(),
        // sale_person_phone_number: z.string().optional(),
        active_store_id: z.string().optional(),
        active_exhibition_id: z.string().optional(),
        active_agency_id: z.string().optional(),
        select_agency: z.boolean().optional(),
        select_exhibitions: z.boolean().optional(),
        select_stores: z.boolean().optional(),
      }),
      sale_person_phone_number: z.string().optional(),
      examination_file: z.object({}),
      editable: z.boolean(),
      main_statuses: z.string(),
      // provisional_results: z.array(
      //   z.object({
      //     key: z.string(),
      //     value: z.number(),
      //     active: z.boolean(),
      //   })
      // ),
      public_status: z.array(
        z.object({
          text: z.string(),
          active: z.boolean(),
        })
      ),
      collateral_land_area: z.string().optional(),
      occupied_area: z.string().optional(),
      collateral_total_floor_area: z.string().optional(),
      house_purchase_price: z.string().optional(),
      non_resident_reason: z.string().optional(),
      land_purchase_price: z.string().optional(),
      purchase_purpose: z.string().optional(),
      accessory_cost: z.string().optional(),
      refinancing_loan_balance: z.string().optional(),
      house_upgrade_cost: z.string().optional(),
      pair_loan_amount: z.string().optional(),
      condominium_name: z.string().optional(),
      land_ownership: z.string().optional(),
      planning_area: z.nativeEnum(PlanningAreaType).optional(),
      other_planning_area: z.string().optional(),
      rebuilding_reason: z.nativeEnum(RebuildingReasonType).optional(),
      other_rebuilding_reason: z.string().optional(),
      flat_35_applicable_plan: z
        .nativeEnum(Flat35ApplicablePlanType)
        .optional(),
      maintenance_type: z.nativeEnum(MaintenanceType).optional(),
      region_type: z.nativeEnum(RegionType).optional(),
      flat_35_application: z.nativeEnum(Flat35ApplicationType).optional(),
      completely_repayment_type: z.nativeEnum(FundsPairOffType).optional(),
      other_complete_repayment_type: z.string().optional(),
      refund_content: z.string().optional(),
      refund_amount: z.string().optional(),
      has_log_fields: z.array(z.string()),
    })
    .optional(),
});

export const editSalePersonPreliminariesResponseSchema = z.object({
  success: z.boolean(),
  p_application_header: z.object({
    id: z.string(),
    user_id: z.string().optional(),
    sale_person_name_input: z.string(),
    edit_file_upload: z.number(),
    loan_apply_date: z.string(),
    created_at: z.string(),
    current_path: z.array(z.any()),
    relationship_to_applicant: z
      .nativeEnum(RelationshipToApplicantType)
      .optional(),
    application_number: z.string(),
    scheduled_date_moving: z.string(),
    name_master_banks: z.array(z.string()),
    master_bank_ids: z.array(z.number()),
    loan_type: z.nativeEnum(LoanType),
    loan_target: z.nativeEnum(PurposeType),
    pair_loan_applicant_first_name: z.string().optional(),
    pair_loan_applicant_last_name: z.string().optional(),
    pair_loan_relationship: z.string().optional(),
    pair_loan_relationship_name: z.string().optional(),
    loan_target_zero: z.nativeEnum(PurposeType).optional(),
    loan_target_default: z.nativeEnum(PurposeType).optional(),
    is_home_loan_plus: z.boolean(),
    has_land_advance_plan: z.nativeEnum(HasLandAdvancePlanType).optional(),
    preliminary_status: z.nativeEnum(PreliminaryStatusType).optional(),
    examination_file: z.object({
      enable: z.boolean(),
      path: z.string(),
      filename: z.string(),
    }),
    edit_main_statuses: z.boolean(),
    property_information_url: z.string(),
    person_occupancy: z.nativeEnum(PersonOccupancyType),
    children_number: z.union([z.string(), z.number()]),
    siblings_number: z.union([z.string(), z.number()]),
    other_people_number: z.union([z.string(), z.number()]),
    housemate_number: z.string(),
    business_ability: z
      .array(z.union([z.nativeEnum(LoanTargetPropertyType), z.string()]))
      .optional(),
    collateral_prefecture: z.string(),
    collateral_city: z.string(),
    collateral_lot_number: z.string(),
    additional_cost: z.string(),
    require_funds_breakdown_mortgage: z.string(),
    deposit_savings_1: z.string(),
    real_estate_sale_price: z.string(),
    other_saving_amount: z.string(),
    other_relationship: z.string().optional(),
    relative_donation_amount: z.string(),
    loan_amount: z.string(),
    other_procurement_breakdown: z.string(),
    other_procurement_breakdown_content: z.string().optional(),
    s_sale_person_id: z.string(),
    p_referral_agency_id: z.string(),
    planned_cohabitant: z.array(z.nativeEnum(PlannedCohabitantType)),
    property_postal_code: z.string(),
    collateral_address_kana: z.string(),
    collateral_type: z.nativeEnum(CollateralType),
    acquisition_time_of_the_land: z.string(),
    joint_ownership_division: z.nativeEnum(JointOwnershipDivision),
    building_ratio_numerator: z.string(),
    building_ratio_denominator: z.string(),
    land_ratio_numerator: z.string(),
    land_ratio_denominator: z.string(),
    land_price: z.string(),
    linking_id: z.string(),
    building_price: z.string(),
    land_and_building_price: z.string(),
    saving_amount: z.string(),
    amount_any_loans: z.string(),
    amount_others: z.string(),
    property_information_file_index: z.array(filesUploadSchema),
    manager_property_information_file_index: z.array(filesUploadSchema),
    business_card_index: z.array(filesUploadSchema),
    p_application_banks_attributes: z.array(
      z.object({
        id: z.string(),
        s_master_bank_id: z.string(),
        name: z.string(),
        _destroy: z.boolean(),
      })
    ),
    p_applicant_people_attributes: z.array(
      z.object({
        id: z.string(),
        applicant_detail_type: z.nativeEnum(ApplicantDetailType),
        has_join_guarantor: z.nativeEnum(CoOwnerType),
        last_name_kanji: z.string(),
        first_name_kanji: z.string(),
        full_name_kanji: z.string(),
        last_name_kana: z.string(),
        first_name_kana: z.string(),
        sex: z.string(),
        name_relationship_to_applicant: z.string(),
        birthday: z.string(),
        nationality: z.string(),
        mobile_phone_number: z.string(),
        home_phone_number: z.string(),
        postal_code: z.string(),
        prefecture_kanji: z.string(),
        city_kanji: z.string(),
        district_kanji: z.string(),
        other_address_kanji: z.string(),
        owner_email: z.string(),
        occupation: z.string(),
        other_occupation: z.string(),
        industry: z.string(),
        other_industry: z.string(),
        occupation_detail: z.nativeEnum(OccupationDetailType),
        other_occupation_detail: z.string(),
        office_name_kanji: z.string(),
        department: z.string(),
        office_phone_number: z.string(),
        office_postal_code: z.string(),
        office_prefecture_kanji: z.string(),
        office_city_kanji: z.string(),
        office_district_kanji: z.string(),
        number_of_employee: z.string(),
        employment_started_date: z.string(),
        last_year_income: z.string(),
        bonus_income: z.string(),
        two_years_ago_income: z.string(),
        tax_return: z.nativeEnum(FinalTaxReturnType),
        other_type_tax_return: z.string(),
        transfer_office: z.nativeEnum(DispatchType),
        transfer_office_name_kanji: z.string(),
        transfer_office_name_kana: z.string(),
        transfer_office_phone_number: z.string(),
        transfer_office_postal_code: z.string(),
        transfer_office_prefecture_kanji: z.string(),
        transfer_office_city_kanji: z.string(),
        transfer_office_district_kanji: z.string(),
        transfer_office_other_address_kanji: z.string(),
        maternity_paternity_leave_status: z.string(),
        maternity_paternity_leave_start_time: z.string(),
        maternity_paternity_leave_end_time: z.string(),
        nursing_leave_status: z.string(),
        lived_length_year_num: z.string(),
        lived_length_month_num: z.string(),
        current_residence: z.nativeEnum(CurrentResidenceType),
        current_residence_floor_area: z.string(),
        owner_full_name: z.string(),
        owner_relationship: z.string(),
        buyingand_selling_schedule_type: z.nativeEnum(
          BuyingandSellingScheduleType
        ),
        other_buyingand_selling_schedule_type: z.string(),
        scheduled_time_sell_house: z.string(),
        expected_house_selling_price: z.string(),
        current_home_loan: z.string(),
        reason_acquire_home: z.nativeEnum(ReasonsForNeedingHousingType),
        other_reason_acquire_home: z.string(),
        borrowing_status: z.nativeEnum(BorrowingStatusType),
        land_rent_to_be_paid: z.string(),
        house_rent_to_be_paid: z.string(),
        identity_verification: z.string(),
        emergency_contact_number: z.string().optional(),
        prefecture_kana: z.string(),
        city_kana: z.string(),
        district_kana: z.string(),
        other_address_kana: z.string(),
        emplmt_form_code: z.string(),
        office_name_kana: z.string(),
        position: z.nativeEnum(PositionType),
        headquarters_location: z.string(),
        listed_division: z.string(),
        office_establishment_date: z.string(),
        capital_stock: z.string(),
        other_office_address_kanji: z.string(),
        office_prefecture_kana: z.string(),
        office_city_kana: z.string(),
        office_district_kana: z.string(),
        other_office_address_kana: z.string(),
        relationship_to_applicant: z
          .nativeEnum(RelationshipToApplicantType)
          .optional(),
        other_relationship_to_applicant: z.string(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean(),
        income_source: z.array(z.nativeEnum(SourceOfIncomeType)),
        main_income_source: z.nativeEnum(SourceOfIncomeType),
        type_tax_return: z.array(z.nativeEnum(FileATaxReturnType)),
        personal_file_upload: z.object({
          driver_license_front_image: z.array(filesUploadSchema),
          driver_license_back_image: z.array(filesUploadSchema),
          card_number_front_image: z.array(filesUploadSchema),
          // card_number_back_image: z.array(filesUploadSchema),
          resident_register_front_image: z.array(filesUploadSchema),
          resident_register_back_image: z.array(filesUploadSchema),
          insurance_file: z.array(filesUploadSchema),
          insurance_file_back_image: z.array(filesUploadSchema),
          first_withholding_slip_file: z.array(filesUploadSchema),
          second_withholding_slip_file: z.array(filesUploadSchema),
          first_income_file: z.array(filesUploadSchema),
          second_income_file: z.array(filesUploadSchema),
          third_income_file: z.array(filesUploadSchema),
          financial_statement_1_file: z.array(filesUploadSchema),
          financial_statement_2_file: z.array(filesUploadSchema),
          financial_statement_3_file: z.array(filesUploadSchema),
          other_document_file: z.array(filesUploadSchema),
          employment_agreement_file: z.array(filesUploadSchema),
          business_tax_return_1_file: z.array(filesUploadSchema),
          business_tax_return_2_file: z.array(filesUploadSchema),
          business_tax_return_3_file: z.array(filesUploadSchema),
          residence_file: z.array(filesUploadSchema),
          residence_file_back_image: z.array(filesUploadSchema),
        }),
      })
    ),
    p_borrowing_details_attributes: z.array(
      z.object({
        id: z.string(),
        loan_desired_borrowing_date: z.string(),
        temporary_desired_loan_amount: z.string(),
        halfyear_bonus: z.string(),
        desired_monthly_bonus: z.string(),
        loan_term_year_num: z.string(),
        loan_term_month_num: z.string(),
        repayment_method: z.nativeEnum(RepaymentType),
        borrowing_detail_type: z.string(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean(),
      })
    ),
    p_join_guarantors_attributes: z.array(
      z.object({
        last_name_kanji: z.string(),
        first_name_kanji: z.string(),
        last_name_kana: z.string(),
        first_name_kana: z.string(),
        sex: z.nativeEnum(SexType),
        guarantor_relationship_name: z.string(),
        birthday: z.string(),
        mobile_phone_number: z.string(),
        home_phone_number: z.string(),
        postal_code: z.string(),
        prefecture_kanji: z.string(),
        city_kanji: z.string(),
        district_kanji: z.string(),
        other_address_kanji: z.string(),
        id: z.string(),
        guarantor_relationship_to_applicant: z.string(),
        other_relationship_to_applicant: z.string(),
        emergency_contact_number: z.string().optional(),
        prefecture_kana: z.string(),
        city_kana: z.string(),
        district_kana: z.string(),
        other_address_kana: z.string(),
        owner_email: z.string(),
        has_log_fields: z.array(z.string()),
        _destroy: z.boolean(),
      })
    ),
    p_borrowings_attributes: z.array(
      z.object({
        id: z.string(),
        borrowing_type: z.nativeEnum(SPBorrowingType),
        borrower: z.nativeEnum(BorrowerType),
        lender: z.string(),
        borrowing_from_housing_finance_agency: z.nativeEnum(
          BorrowingFromHousingFinanceAgencyType
        ),
        loan_purpose: z.nativeEnum(LoanPurposeType),
        business_borrowing_type: z.nativeEnum(BusinessBorrowingType).optional(),
        other_purpose: z.string(),
        specific_loan_purpose: z.string(),
        borrowing_category: z.nativeEnum(BorrowingCategoryType),
        loan_start_date: z.string(),
        loan_amount: z.string(),
        current_balance_amount: z.string(),
        annual_repayment_amount: z.string(),
        card_expiry_date: z.string(),
        scheduled_loan_payoff: z.nativeEnum(ScheduledLoanPayoffType),
        loan_deadline_date: z.string(),
        expected_repayment_date: z.string(),
        rental_room_number: z.string(),
        common_housing: z.nativeEnum(CommonHousingType),
        estate_mortgage: z.nativeEnum(EstateMortgageType),
        _destroy: z.boolean(),
        has_log_fields: z.array(z.string()),
        include_in_examination: z.string(),
        repayment_schedule_image_index: z.array(filesUploadSchema),
      })
    ),
    p_residents_attributes: z.array(z.any()),
    sale_person_information: z.object({
      has_log_fields: z.array(z.string()),
      list_agencies: z.array(
        z.object({
          id: z.string(),
          sale_agent_id: z.string(),
          sale_agent_name_kanji: z.string(),
        })
      ),
      list_stores: z.array(
        z.object({
          id: z.string(),
          store_id: z.string(),
          store_name_kanji: z.string(),
        })
      ),
      list_exhibitions: z.array(
        z.object({
          id: z.string(),
          exhibition_id: z.string(),
          exhibition_hall: z.string(),
        })
      ),
      list_sale_persons: z.array(
        z.object({
          id: z.string(),
          value: z.string(),
        })
      ),
      sale_person_name: z.string().optional().nullable(),
      sale_person_email: z.string().optional().nullable(),
      sale_person_phone: z.string().optional().nullable(),
      // sale_person_phone_number: z.string().optional().nullable(),
      active_store_id: z.string().optional().nullable(),
      active_exhibition_id: z.string().optional().nullable(),
      active_agency_id: z.string().optional().nullable(),
      select_agency: z.boolean().optional(),
      select_exhibitions: z.boolean().optional(),
      select_stores: z.boolean().optional(),
    }),
    sale_person_phone_number: z.string().optional(),
    editable: z.boolean(),
    main_statuses: z.string(),
    // provisional_results: z.array(
    //   z.object({
    //     key: z.string(),
    //     value: z.number(),
    //     active: z.boolean(),
    //   })
    // ),
    public_status: z.array(
      z.object({
        text: z.string(),
        active: z.boolean(),
      })
    ),
    collateral_land_area: z.string(),
    occupied_area: z.string(),
    collateral_total_floor_area: z.string(),
    house_purchase_price: z.string().optional(),
    land_purchase_price: z.string().optional(),
    purchase_purpose: z.string().optional(),
    accessory_cost: z.string().optional(),
    refinancing_loan_balance: z.string().optional(),
    house_upgrade_cost: z.string().optional(),
    pair_loan_amount: z.string().optional(),
    non_resident_reason: z.string().optional(),
    condominium_name: z.string().optional(),
    land_ownership: z.string().optional(),
    planning_area: z.nativeEnum(PlanningAreaType).optional(),
    other_planning_area: z.string().optional(),
    rebuilding_reason: z.nativeEnum(RebuildingReasonType).optional(),
    other_rebuilding_reason: z.string().optional(),
    flat_35_applicable_plan: z.nativeEnum(Flat35ApplicablePlanType).optional(),
    maintenance_type: z.nativeEnum(MaintenanceType).optional(),
    region_type: z.nativeEnum(RegionType).optional(),
    flat_35_application: z.nativeEnum(Flat35ApplicationType).optional(),
    completely_repayment_type: z.nativeEnum(FundsPairOffType).optional(),
    other_complete_repayment_type: z.string().optional(),
    refund_content: z.string().optional(),
    refund_amount: z.string().optional(),
    has_log_fields: z.array(z.string()),
  }),
});

export type SalePersonPreliminariesResponse = z.infer<
  typeof salePersonPreliminariesSchema
  // typeof salePersonPreliminariesResponseSchema
>;
export type SalePersonPreliminariesRequest = z.infer<
  typeof salePersonPreliminariesRequestSchema
>;

export type SalePersonPreliminaries = z.infer<
  typeof salePersonPreliminariesSchema
>;
export type SalePersonPreliminariesError = {};

export type EditSalePersonPreliminariesRequest = z.infer<
  typeof editSalePersonPreliminariesRequestSchema
>;

export type EditSalePersonPreliminariesResponse = z.infer<
  typeof editSalePersonPreliminariesResponseSchema
>;
