import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPSaveImageModal: FC<ModalProps> = ({ open, onClose }) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      disableBackDropClick
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title=""
      footer={
        <Stack
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          spacing={6}
        >
          <Typography variant="sp_modal_note" color="b_333">
            アップロードした書類にエラーがあります。再度アップロードしてください。
          </Typography>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};
