import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PDFDownload: FC<SvgIconProps> = (props) => (
  <SvgIcon
    sx={{ width: 13, height: 13 }}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7435 12.0098C14.7366 11.4508 14.3156 11.0206 13.7704 10.9953C13.2229 10.97 12.7559 11.3634 12.6961 11.904C12.6846 12.0121 12.68 12.1202 12.68 12.2283C12.68 12.6125 12.68 12.9967 12.6777 13.3832C12.6777 13.4614 12.6547 13.5396 12.6432 13.6155C12.5857 13.627 12.5512 13.6385 12.5167 13.6385C9.31905 13.6385 6.12143 13.6339 2.92382 13.6316C2.91232 13.6316 2.90081 13.627 2.89161 13.6224C2.88241 13.6178 2.87322 13.6063 2.84101 13.5787C2.83641 13.4844 2.82721 13.3671 2.82721 13.2497C2.82721 12.8403 2.83871 12.4308 2.8226 12.0236C2.795 11.2967 2.13938 10.832 1.46535 11.0505C1.04897 11.1862 0.763716 11.5589 0.759115 12.0374C0.747613 12.9139 0.747613 13.7926 0.759115 14.6691C0.763716 15.0855 0.963844 15.4006 1.33882 15.5985C1.41013 15.6353 1.63327 15.6468 1.69768 15.6468C5.83848 15.6468 9.5813 15.6261 13.7221 15.6261C13.8003 15.6261 14.1109 15.6353 14.1914 15.5893C14.5433 15.3868 14.7412 15.0878 14.7435 14.6806C14.7504 13.7903 14.755 12.9001 14.7435 12.0121V12.0098Z"
        fill="#E54E75"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.98662 8.63513C5.08393 9.73244 6.17894 10.8298 7.27624 11.9248C7.33606 11.9846 7.39128 12.0467 7.45799 12.0996C7.64432 12.2514 7.86287 12.2491 8.0492 12.095C8.09521 12.0582 8.13661 12.0122 8.18032 11.9708C9.31213 10.8413 10.4416 9.70944 11.5735 8.57762C11.6333 8.51781 11.6931 8.458 11.7368 8.38668C11.8679 8.17274 11.746 7.90359 11.4999 7.85758C11.4055 7.84148 11.3089 7.84378 11.2123 7.84378C10.8396 7.84378 10.467 7.84378 10.0943 7.84378C9.98846 7.84378 9.88265 7.83458 9.72622 7.82768C9.72162 7.65745 9.71702 7.52632 9.71702 7.39519C9.71702 5.39841 9.71702 3.40393 9.71702 1.40715C9.71702 1.27602 9.71932 1.1403 9.70322 1.01147C9.65491 0.668709 9.37194 0.399558 9.02918 0.35815C8.93486 0.346648 8.83594 0.351249 8.74162 0.351249C8.14121 0.351249 7.5385 0.351249 6.93809 0.351249C6.80696 0.351249 6.67353 0.346646 6.5424 0.355847C6.12832 0.381152 5.82467 0.684815 5.79476 1.09429C5.78786 1.20241 5.79247 1.31054 5.79247 1.41866C5.79247 3.41544 5.79247 5.40992 5.79247 7.4067C5.79247 7.53552 5.81777 7.66895 5.75335 7.81847C5.66824 7.82768 5.57622 7.84378 5.4819 7.84378C5.09773 7.84608 4.71125 7.84378 4.32708 7.84378C4.23046 7.84378 4.13384 7.83918 4.03952 7.85299C3.75197 7.89209 3.62545 8.18425 3.79568 8.4212C3.85089 8.49941 3.92451 8.56382 3.99122 8.63283L3.98662 8.63513Z"
        fill="#E54E75"
      />
    </svg>
  </SvgIcon>
);
