import { AdminPreliminaryItem, CONTACT_INFORMATION } from 'constant';
import { SalePersonPreliminaries } from 'services';

export const buildContactInformation = (
  values?: SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  // if (!!values) {
  //   const salePersonRole = values.current_path[0];
  //   const hasH = salePersonRole.includes('H');
  //   const hasC = salePersonRole.includes('C');
  //   const hasB = salePersonRole.includes('B');
  //   const hasO = salePersonRole.includes('O');
  //   const hasE = salePersonRole.includes('E');
  //
  //   return CONTACT_INFORMATION.map((row) => {
  //     if (row.name_en === 'sale_person_information.active_agency_id') {
  //       return {
  //         ...row,
  //         dynamicType: hasC || hasB || hasO || hasE ? null : row.dynamicType,
  //       };
  //     }
  //     if (row.name_en === 'sale_person_information.active_store_id') {
  //       return {
  //         ...row,
  //         dynamicType: hasB || hasO || hasE ? null : row.dynamicType,
  //       };
  //     }
  //     if (row.name_en === 'sale_person_information.active_exhibition_id') {
  //       return {
  //         ...row,
  //         dynamicType: hasE ? null : row.dynamicType,
  //       };
  //     }
  //     return row;
  //   });
  // } else {
  return CONTACT_INFORMATION;
  // }
};
