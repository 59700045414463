import { useContext, FC, useCallback } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { NumpadContext } from 'context';

export type NumPadProps = {};

export const NumPad: FC<NumPadProps> = () => {
  const { setName, numPadOpen, setNumPadOpen, value, setValue } =
    useContext(NumpadContext);

  const handleClose = useCallback(() => {
    setNumPadOpen(!numPadOpen);
  }, [numPadOpen, setNumPadOpen]);

  const onButtonPress = (v: number | string) => {
    setValue(value?.concat(v.toString()) ?? '');
  };

  const handleClear = useCallback(() => {
    setValue('');
  }, [setValue]);

  const handleDelete = useCallback(() => {
    setValue(value.substring(0, value.length - 1));
  }, [setValue, value]);

  const handleSubmit = useCallback(() => {
    setName('');
  }, [setName]);

  return (
    <Stack className="numpad">
      <Stack
        sx={{
          position: 'fixed',
          bottom: 72,
          right: 9,
          maxWidth: 244,
          maxHeight: 295,
          borderRadius: '10px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          bgcolor: 'main_white',
          zIndex: 1,
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            bgcolor: 'h_blugreen',
            position: 'relative',
            py: 3,
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px',
            borderBottom: '1px solid gray',
          }}
        >
          <Stack>
            <Typography variant="titleNumPad" color="main_white">
              数字入力
            </Typography>
          </Stack>
          <Stack
            onClick={handleClose}
            sx={{ position: 'absolute', right: '19.5px' }}
          >
            <Icons.Close fontSize="small" sx={{ color: 'main_white' }} />
          </Stack>
        </Stack>
        <Grid container sx={{ flexWrap: 'inherit' }}>
          <Grid item xs={9}>
            <Grid container spacing="10px" sx={{ py: 2, px: '6px' }}>
              {numbers.map((number, index) => (
                <Grid key={index} item xs={12}>
                  <Grid container spacing="10px">
                    {number.map((item) => (
                      <Grid key={item} item>
                        <Button
                          sx={{
                            ...numpadStyle,
                            width: item === 0 ? 110 : 50,
                            minWidth: item === 0 ? 110 : 50,
                          }}
                          onClick={() => onButtonPress(item)}
                        >
                          <Typography variant="titleNumPad" color="main_white">
                            {item}
                          </Typography>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={3} sx={{ mt: 2, mr: '6px' }}>
            <Grid container direction="column" spacing="10px">
              <Grid item>
                <Button sx={numpadStyle} onClick={handleDelete}>
                  <Icons.Delete />
                </Button>
              </Grid>
              <Grid item>
                <Button sx={numpadStyle} onClick={handleClear}>
                  <Stack justifyContent="center" alignItems="center">
                    <Typography
                      variant="drawerButtonText"
                      color="main_white"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      すべて
                    </Typography>
                    <Icons.Delete />
                  </Stack>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    height: 110,
                    width: 50,
                    minHeight: 110,
                    minWidth: 50,
                    bgcolor: 'sh_red',
                    borderRadius: '2px',
                  }}
                  onClick={handleSubmit}
                >
                  <Typography
                    variant="titleNumPad"
                    color="main_white"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    確定
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

const numpadStyle = {
  height: 50,
  width: 50,
  minHeight: 50,
  minWidth: 50,
  bgcolor: 'blue08',
  borderRadius: '2px',
};

const numbers = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [0, '.'],
];
