import { AxiosRequestConfig } from 'axios';
import { instance } from 'libs';
import { parseErrors } from 'utils';
import {
  EditSalePersonPreliminariesRequest,
  EditSalePersonPreliminariesResponse,
  getReferralAgencyAdminOptionResponseSchema,
  getReferralAgencyOptionRequest,
  getReferralAgencyOptionRequestSchema,
  logEditedRequest,
  logEditResponse,
  logEditResponseSchema,
  ReferralAgencyAdminOptionResponse,
  SalePersonPreliminariesRequest,
  SalePersonPreliminariesResponse,
  salePersonPreliminariesResponseSchema,
} from './schemas';

export const getSalePersonPreliminaries = (
  data: SalePersonPreliminariesRequest
): Promise<SalePersonPreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/s_sale_person/preliminaries/${data.id}/edit`,
  };
  return instance(request).then(({ data }) =>
    parseErrors(salePersonPreliminariesResponseSchema.parse, data)
  );
};

export const getLogEditedSalePersonPreliminary = (
  params: logEditedRequest
): Promise<logEditResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/s_sale_person/activities',
    params: {
      id: params.id,
      trackable_id: params.trackable_id,
      trackable_type: params.trackable_type,
      column: params.column,
    },
  };
  return instance(request).then(({ data }) =>
    logEditResponseSchema.parse(data)
  );
};

export const editSalePersonPreliminaries = (
  data: EditSalePersonPreliminariesRequest
): Promise<EditSalePersonPreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/s_sale_person/preliminaries/${data.id}`,
    data,
  };
  return instance(request).then(
    ({ data }) => data
    // editSalePersonPreliminariesResponseSchema.parse(data)
  );
};

export const getReferralAgencyAdminOptionsSalePerson = (
  data: getReferralAgencyOptionRequest
): Promise<ReferralAgencyAdminOptionResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/s_sale_person/referral_agency_options`,
    params: getReferralAgencyOptionRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    getReferralAgencyAdminOptionResponseSchema.parse(data)
  );
};
