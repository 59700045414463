import { FC } from 'react';

export type SPEmailLinkProps = {
  email: string;
};

export const SPEmailLink: FC<SPEmailLinkProps> = ({ email }) => (
  <a
    style={{ textDecoration: 'none', color: '#333333' }}
    href={`mailto:${email}`}
  >
    {email}
  </a>
);
