import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}>;

export const SPDeleteModal: FC<ModalProps> = ({ open, onClose, onDelete }) => (
  <SPModal
    open={open}
    onClose={onClose}
    icon={<Icons.SPWarningModal sx={{ width: 35, height: 35 }} />}
    title={`この画像を\n本当に削除しますか？`}
    disableBackDropClick
    footer={
      <Stack spacing={6}>
        <SPButton
          sx={{
            width: '100%',
            bgcolor: 'sp_primary_100_main',
          }}
          onClick={onDelete}
        >
          <Typography variant="SP_multiple_checkbox_label" color="main_white">
            削除する
          </Typography>
        </SPButton>
        <SPButton
          sx={{
            width: '100%',
            bgcolor: 'main_white',
            border: '1px solid',
            borderColor: 'sp_primary_100_main',
          }}
          onClick={onClose}
        >
          <Typography
            variant="SP_multiple_checkbox_label"
            color="sp_primary_100_main"
          >
            キャンセル
          </Typography>
        </SPButton>
      </Stack>
    }
  />
);
