import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  AzureLoginError,
  LoginError,
  SalePersonLoginRequest,
  SalePersonLoginResponse,
} from 'services';
import { ThunkAPI } from 'types';
import { AxiosError } from 'axios';

export const salePersonLogin = createAsyncThunk<
  SalePersonLoginResponse,
  SalePersonLoginRequest,
  ThunkAPI<LoginError>
>('salePerson/login', async (salePerson, { rejectWithValue }) => {
  try {
    return await authService.salePersonLogin(salePerson);
  } catch (error) {
    const err = (error as AxiosError<LoginError>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});

export const salePersonAzureLogin = createAsyncThunk<
  SalePersonLoginResponse,
  SalePersonLoginRequest,
  ThunkAPI<AzureLoginError>
>('salePersonAzureLogin/login', async (salePerson, { rejectWithValue }) => {
  try {
    return await authService.salePersonLogin(salePerson);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const salePersonLogout = createAsyncThunk(
  'salePerson/logout',
  async (thunkAPI, { rejectWithValue }) => {
    try {
      return await authService.salePersonLogout();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const salePersonTokenInvalidLogout = createAsyncThunk(
  'salePerson/logout',
  async (thunkAPI, { rejectWithValue }) => {
    try {
      return await authService.salePersonTokenInvalidLogout();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
