import { spModalMoveToTopRef } from 'components';
import { SP_FIELDS_TITLE } from 'constant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetCurrentStatusResultResponse } from 'types';
import {
  statusResultSelector,
  statusIsUpdateResultSelector,
} from '../containers/AuthModal/selectors';
import {
  updateStatusResult,
  updateIsStatuses,
} from '../containers/AuthModal/slice';
import { spGetCurrentStatusResult } from '../pages/SPLogin/thunk';
import { spStepTenSelector } from '../pages/SPStepTen/selectors';
import { useAppDispatch } from './useAppDispatch';

export const useCheckPreliminaryStatus = () => {
  const dispatch = useAppDispatch();
  const [result, setResult] = useState<GetCurrentStatusResultResponse>();
  const { application_number } = useSelector(spStepTenSelector);
  const statusResult = useSelector(statusResultSelector);
  const isStatusResult = useSelector(statusIsUpdateResultSelector);
  const location = useLocation();

  useEffect(() => {
    if (!application_number) {
      return;
    }
    (async () => {
      const res = await dispatch(spGetCurrentStatusResult());
      if (spGetCurrentStatusResult.fulfilled.match(res)) {
        setResult(res.payload);
      }
    })();
  }, [application_number]);

  useEffect(() => {
    if (!application_number || !result) {
      return;
    }
    (async () => {
      if (statusResult === '2' && result.data?.status_result === '2') {
        await dispatch(updateIsStatuses(true));
      } else if (statusResult === '3' || result.data?.status_result === '3') {
        await dispatch(updateIsStatuses(false));
      }
      if (
        (!location.pathname.startsWith('/top') &&
          statusResult !== result.data?.status_result &&
          result.data?.status_result === '3') ||
        (location.pathname.startsWith('/top') &&
          ((statusResult === '3' &&
            isStatusResult &&
            result.data?.status_result === '2') ||
            (statusResult === '2' &&
              isStatusResult &&
              result.data?.status_result === '3')))
      ) {
        spModalMoveToTopRef.current?.open(
          'お知らせ',
          SP_FIELDS_TITLE['bank_is_conducting_a_review'],
          []
        );
      }
      await dispatch(updateStatusResult(result.data?.status_result ?? null));
    })();
  }, [
    application_number,
    location.pathname,
    statusResult,
    isStatusResult,
    result,
  ]);

  // useEffect(() => {
  //   if (!application_number) {
  //     return;
  //   }
  //   if (location.pathname !== routeNames.Top.path) {
  //     return;
  //   }
  //   dispatch(spGetSubmittedPreliminary());
  // }, [application_number, dispatch, location.pathname]);
};
