/* eslint-disable react-hooks/exhaustive-deps */
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { useCallback, useEffect } from 'react';
import { FormikContextType, FormikState } from 'formik';
import { dayjs } from 'libs/dayjs';
import { RelationshipToApplicantType } from 'constant';
import get from 'lodash/get';

type Values = AdminPreliminaries | SalePersonPreliminaries;

export const usePersonalInformation = (
  values: Values,
  setFieldValue: FormikContextType<Values>['setFieldValue'],
  touched: FormikState<Values>['touched']
) => {
  const handleResetValue = useCallback(
    (key: string, defaultValue: string | number) => {
      if (get(values, key) === undefined) return;
      return setFieldValue(key, defaultValue);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (
      !values.p_application_header.p_applicant_people_attributes?.[0]?.birthday
    )
      return;

    const {
      birthday,
      lived_length_year_num: livedLengthYearNum,
      lived_length_month_num: livedLengthMonthNum,
    } = values.p_application_header.p_applicant_people_attributes[0];

    const diffMonthBirthday = dayjs().diff(birthday, 'M');
    const diffMonth =
      convertToNumber(livedLengthYearNum) * 12 +
      convertToNumber(livedLengthMonthNum);

    if (diffMonthBirthday < diffMonth) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].lived_length_year_num',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].lived_length_month_num',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]?.birthday,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.relationship_to_applicant &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.relationship_to_applicant !== RelationshipToApplicantType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].other_relationship_to_applicant',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.relationship_to_applicant,
  ]);
};

function convertToNumber(num: number | string | undefined): number {
  if (!num) return 1;
  return isNaN(Number(num)) ? 1 : Number(num);
}
