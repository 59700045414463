import { FC, ReactElement, useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useAppDispatch, useCheckCurrentStep, useSubmittedImages } from 'hooks';
import { useSelector } from 'react-redux';
import {
  isLoggedInSelector,
  managerIdSelector,
  salePersonIdSelector,
  statusResultSelector,
} from 'containers/AuthModal/selectors';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import jwtDecode from 'jwt-decode';
import { useSaveSalePersonCode } from 'hooks';
import { spGetSubmittedPreliminary } from '../pages/SPLogin/thunk';
import { clearSalePerson } from '../libs';
import {
  stepRoutes,
  publicRoutes,
  managerRoutes,
  salepersonRoutes,
} from './routes';
import { StepsNavigation } from './StepsNavigation';

const NavigationRoot: FC = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const managerId = useSelector(managerIdSelector);
  const salePersonId = useSelector(salePersonIdSelector);
  const { application_number } = useSelector(spStepTenSelector);
  const status_result = useSelector(statusResultSelector);
  const checkCurrentStep = useCheckCurrentStep();
  const handleSubmittedImages = useSubmittedImages();
  useSaveSalePersonCode();

  useLayoutEffect(() => {
    if (!['/top', '/send-email'].includes(window.location.pathname)) return;
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    if (!token) return;
    const { sale_agent_id, store_id, exhibition_id } =
      jwtDecode<{
        sale_agent_id: string;
        store_id: string;
        exhibition_id: string;
      }>(token) || {};
    clearSalePerson(true);

    if (sale_agent_id) {
      sessionStorage.setItem('sale_agent_id', sale_agent_id ?? '');
    }
    if (store_id) {
      sessionStorage.setItem('store_id', store_id ?? '');
    }
    if (exhibition_id) {
      sessionStorage.setItem('exhibition_id', exhibition_id ?? '');
    }
  }, []);

  useEffect(() => {
    if (
      isLoggedIn &&
      !managerId &&
      !salePersonId &&
      application_number &&
      ![
        '/update-password',
        '/change-email',
        '/top',
        '/top/upload',
        '/login',
      ].includes(window.location.pathname)
    ) {
      const getEditPrimaryData = async () => {
        const submittedPreliminary = await dispatch(
          spGetSubmittedPreliminary()
        );
        if (spGetSubmittedPreliminary.fulfilled.match(submittedPreliminary)) {
          checkCurrentStep(submittedPreliminary.payload.data, status_result);
          handleSubmittedImages(submittedPreliminary.payload.data);
        }
      };
      getEditPrimaryData();
    }
  }, [
    application_number,
    checkCurrentStep,
    dispatch,
    handleSubmittedImages,
    isLoggedIn,
    managerId,
    salePersonId,
    status_result,
  ]);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<StepsNavigation role="none" />}>
            {publicRoutes.map(({ path, Element }, key) => (
              <Route key={key} path={path} element={<Element />} />
            ))}
          </Route>
          <Route element={<StepsNavigation role="user" />}>
            {stepRoutes.map(({ path, Element }, key) => (
              <Route key={key} path={path} element={<Element />} />
            ))}
          </Route>
          <Route element={<StepsNavigation role="manager" />}>
            {managerRoutes.map(({ path, Element }, key) => (
              <Route key={key} path={path} element={<Element />} />
            ))}
          </Route>
          <Route element={<StepsNavigation role="saleperson" />}>
            {salepersonRoutes.map(({ path, Element }, key) => (
              <Route key={key} path={path} element={<Element />} />
            ))}
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

type ScrollToTopProps = {
  children: ReactElement;
};
const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default NavigationRoot;
