import {
  AdminPreliminaryItem,
  borrowingStatusAdmin,
  joinGuarantor,
  finalTaxReturn,
  includeInExamination,
  isHomeLoanPlus,
  IsHomeLoanPlus,
  landAdvance,
  nationality,
  personOccupancy,
  RelationshipType,
  repayment,
  sex,
  transferOffice,
  workIndustry,
} from 'constant';
import { QuantityDependent } from 'types';
import { dayjs } from 'libs';
import kanjidate from 'kanjidate';
import { convertDateTime, convertYenToMan, formatJapanDateTine } from 'utils';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { DynamicOptions } from 'services';
import { Typography } from '@mui/material';
import { buildDataValue, DataValue } from './buildDataValue';

export const convertData = (
  fieldEn: string,
  rowValue: string,
  dynamicOptions: DynamicOptions,
  inputOptions?: AdminPreliminaryItem['options'],
  quantityDependent?: QuantityDependent
  // isSalePerson?: boolean
  // sale_person_information?: {
  //   list_agencies: {
  //     id: string;
  //     sale_agent_id: string;
  //     sale_agent_name_kanji: string;
  //   }[];
  //   list_stores: {
  //     id: string;
  //     store_id: string;
  //     store_name_kanji: string;
  //   }[];
  //   list_exhibitions: {
  //     id: string;
  //     exhibition_id: string;
  //     exhibition_hall: string;
  //   }[];
  //   list_sale_persons: {
  //     id: string;
  //     value: string;
  //   }[];
  //   sale_person_name?: string;
  //   sale_person_email?: string;
  //   sale_person_phone?: string;
  //   active_store_id?: string;
  //   has_log_fields?: string;
  //   active_exhibition_id?: string;
  //   active_agency_id?: string;
  // }
): DataValue => {
  const rowValueString = (rowValue ?? '').toString();
  const isValidateDay = dayjs(
    rowValueString,
    inputOptions?.format ?? 'YYYY/MM/DD',
    true
  ).isValid();

  const [year, month, day] = convertDateTime(rowValueString, 'YYYY/MM/DD')
    .split('/')
    .map((val) => (isNaN(Number(val)) ? 0 : Number(val)));

  const kanjiDateG2 = kanjidate.format('{G:2}', year, month, day) ?? 0;
  const kanjiDateG2Default = kanjidate.format('{G:2}', year, month, 1) ?? 0;
  const kanjiDateN1 = kanjidate.format('{N}', year, month, day) ?? 0;
  const kanjiDateN1Default = kanjidate.format('{N}', year, month, 1) ?? 0;
  const newFormatDate = `${kanjiDateG2}${kanjiDateN1}`;
  const scheduledDateDefault = `${kanjiDateG2Default}${kanjiDateN1Default}`;

  const [yearT, monthT, dayT] = formatJapanDateTine(
    rowValueString,
    'YYYY/MM/DD'
  )
    .split('/')
    .map((val) => (isNaN(Number(val)) ? 0 : Number(val)));

  const kanjiDateTimeG2 = kanjidate.format('{G:2}', year, month, day) ?? 0;
  const kanjiDatTimeN1 = kanjidate.format('{N}', year, month, day) ?? 0;

  const newFormatDateTime = `${kanjiDateTimeG2}${kanjiDatTimeN1}`;

  const data: Record<string, DataValue | Record<string, DataValue>> = {
    created_at: buildDataValue(
      isValidateDay
        ? `${yearT}（${newFormatDateTime}）年${monthT}月${dayT}日 ${dayjs(
            rowValueString
          )
            .utcOffset(0)
            .format('HH:mm')}`
        : ''
    ),
    loan_apply_date: buildDataValue(
      isValidateDay ? `${year}（${newFormatDate}）年${month}月${day}日` : ''
    ),
    loan_desired_borrowing_date: buildDataValue(
      isValidateDay ? `${year}（${newFormatDate}）年${month}月${day}日` : ''
    ),
    loan_desired_borrowing_date_2: buildDataValue(
      isValidateDay ? `${year}（${newFormatDate}）年${month}月${day}日` : ''
    ),
    scheduled_date_moving: buildDataValue(
      isValidateDay ? `${year}（${scheduledDateDefault}）年${month}月` : ''
    ),
    birthday: buildDataValue(
      isValidateDay ? `${year}（${newFormatDate}）年${month}月${day}日` : ''
    ),
    loan_start_date: buildDataValue(isValidateDay ? `${year}年${month}月` : ''),
    acquisition_time_of_the_land: buildDataValue(
      isValidateDay ? `${year}年${month}月${day}日` : ''
    ),
    office_establishment_date: buildDataValue(
      isValidateDay ? `${year}年${month}月${day}日` : ''
    ),
    loan_deadline_date: buildDataValue(
      isValidateDay ? `${year}年${month}月` : ''
    ),
    s_sale_person_id: buildDataValue(rowValueString),
    employment_started_date: buildDataValue(
      isValidateDay ? `${year}年${month}月` : ''
    ),
    expected_repayment_date: buildDataValue(
      isValidateDay ? `${year}年${month}月` : ''
    ),
    maternity_paternity_leave_start_time: buildDataValue(
      isValidateDay ? `${year}年${month}月` : ''
    ),
    maternity_paternity_leave_end_time: buildDataValue(
      isValidateDay ? `${year}年${month}月` : ''
    ),
    card_expiry_date: buildDataValue(
      isValidateDay ? `${year}年${month}月` : ''
    ),
    lived_length_month_num: buildDataValue(rowValueString, { suffix: 'ヶ月' }),
    lived_length_year_num: buildDataValue(rowValueString, { suffix: '年' }),
    scheduled_time_sell_house: buildDataValue(
      isValidateDay ? `${year}年${month}月` : ''
    ),
    capital_stock: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    temporary_desired_loan_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    temporary_desired_loan_amount_2: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    halfyear_bonus: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    halfyear_bonus_2: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    loan_term_year_num: buildDataValue(rowValue ?? '', {
      suffix: '年',
    }),
    loan_term_month_num: buildDataValue(rowValue ?? '', {
      suffix: 'ヶ月',
    }),
    has_land_advance_plan: buildDataValue(landAdvance),
    has_join_guarantor: buildDataValue(joinGuarantor),
    tax_return: buildDataValue(finalTaxReturn),
    transfer_office: buildDataValue(transferOffice),
    repayment_method: buildDataValue(repayment),
    borrowing_status_local: buildDataValue(borrowingStatusAdmin),
    is_home_loan_plus: buildDataValue({
      true: isHomeLoanPlus[IsHomeLoanPlus.YES],
      false: isHomeLoanPlus[IsHomeLoanPlus.NO],
    }),
    // loan_target_zero: buildDataValue(loanTargetZeroAdmin),
    sex: buildDataValue(sex),
    person_occupancy: buildDataValue(personOccupancy),
    include_in_examination: buildDataValue(includeInExamination),
    borrowing_status: buildDataValue(borrowingStatusAdmin),
    nationality: buildDataValue(nationality),
    postal_code: buildDataValue(rowValueString),
    industry: buildDataValue(workIndustry),
    house_purchase_price: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    additional_cost: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    require_funds_breakdown_mortgage: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    land_price: buildDataValue(convertYenToMan([rowValueString]).toString(), {
      suffix: '万円',
      isPrice: true,
      widthComma: true,
    }),
    building_price: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    land_and_building_price: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    deposit_savings_1: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    real_estate_sale_price: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    other_saving_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    saving_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円' }
    ),
    amount_any_loans: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    relative_donation_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    loan_amount: buildDataValue(convertYenToMan([rowValueString]).toString(), {
      suffix: '万円',
      isPrice: true,
      widthComma: true,
    }),
    pair_loan_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    other_procurement_breakdown: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    amount_others: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    current_balance_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    annual_repayment_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    refund_amount: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    land_purchase_price: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    refinancing_loan_balance: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    house_upgrade_cost: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    accessory_cost: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      { suffix: '万円', isPrice: true, widthComma: true }
    ),
    bonus_income: buildDataValue(convertYenToMan([rowValueString]).toString(), {
      suffix: '万円',
      isPrice: true,
      widthComma: true,
    }),
    last_year_income: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      {
        suffix: '万円',
        isPrice: true,
        widthComma: true,
      }
    ),
    two_years_ago_income: buildDataValue(
      convertYenToMan([rowValueString]).toString(),
      {
        suffix: '万円',
        isPrice: true,
        widthComma: true,
      }
    ),
    land_rent_to_be_paid: buildDataValue(rowValueString, {
      suffix: '円',
      isPrice: true,
      widthComma: true,
    }),
    house_rent_to_be_paid: buildDataValue(rowValueString, {
      suffix: '円',
      isPrice: true,
      widthComma: true,
    }),
    number_of_employee: buildDataValue(rowValueString, {
      suffix: '名',
      widthComma: true,
    }),
    collateral_land_area: buildDataValue(rowValueString, {
      suffix: 'm²',
      widthComma: true,
    }),
    occupied_area: buildDataValue(rowValueString, {
      suffix: 'm²',
      widthComma: true,
    }),
    collateral_total_floor_area: buildDataValue(rowValueString, {
      suffix: 'm²',
      widthComma: true,
    }),
    current_residence_floor_area: buildDataValue(rowValueString, {
      suffix: 'm²',
      widthComma: true,
    }),
    rental_room_number: buildDataValue(rowValueString, {
      suffix: '戸（室）',
    }),
    housemate_number: buildDataValue(rowValueString, {
      suffix: '人',
    }),
    building_ratio_numerator: buildDataValue(rowValueString, {
      widthComma: true,
    }),
    building_ratio_denominator: buildDataValue(rowValueString, {
      widthComma: true,
    }),
    land_ratio_numerator: buildDataValue(rowValueString, {
      widthComma: true,
    }),
    land_ratio_denominator: buildDataValue(rowValueString, {
      widthComma: true,
    }),
    expected_house_selling_price: buildDataValue(rowValueString, {
      widthComma: true,
      suffix: (
        <>
          万円&nbsp;
          <Typography
            variant="SP_required_chip_label"
            color="b_333"
            whiteSpace="nowrap"
          >
            ※10万円単位
          </Typography>
        </>
      ),
    }),
    // active_agency_id: buildDataValue(
    //   sale_person_information?.list_agencies.find(
    //     (item) => item.sale_agent_id === rowValue
    //   )?.sale_agent_name_kanji ?? ''
    // ),
    // active_store_id: buildDataValue(
    //   sale_person_information?.list_stores.find(
    //     (item) => item.store_id === rowValue
    //   )?.store_name_kanji ?? ''
    // ),
    // active_exhibition_id: buildDataValue(
    //   sale_person_information?.list_exhibitions.find(
    //     (item) => item.exhibition_id === rowValue
    //   )?.exhibition_hall ?? ''
    // ),
    // position: buildDataValue(rowValueString),
  };

  if (typeof get(data, `${fieldEn}.${rowValue}`) === 'object') {
    return get(data, `${fieldEn}.${rowValue}`) as DataValue;
  }

  if (typeof get(data, fieldEn) === 'object') {
    return get(data, fieldEn) as DataValue;
  }

  //end tab 5
  if (dynamicOptions.hasOwnProperty(fieldEn)) {
    const dynamicFields = dynamicOptions[fieldEn];
    const rowValues = !isArray(rowValue) ? [rowValue] : rowValue;
    const sortRowValues =
      fieldEn === 'planned_cohabitant' || fieldEn === 'business_ability'
        ? [...rowValues].sort((a, b) => Number(a) - Number(b))
        : [...rowValues];
    const formattedValue = sortRowValues
      .map((rowValue) => {
        const dynamicField = dynamicFields.value.find(
          (i) => i.option_code.toString() === rowValue.toString()
        );
        let count = '0';
        if (fieldEn === 'planned_cohabitant') {
          if (dynamicField?.option_code === +RelationshipType.CHILD) {
            count = quantityDependent?.children_number?.toString() ?? '0';
            if (count === '0' || !count) return '';
          }
          if (dynamicField?.option_code === +RelationshipType.SIBLINGS) {
            count = quantityDependent?.siblings_number?.toString() ?? '0';
            if (count === '0' || !count) return '';
          }
          if (dynamicField?.option_code === +RelationshipType.OTHERS) {
            count = quantityDependent?.other_people_number?.toString() ?? '0';
            if (count === '0' || !count) return '';
          }
        }
        return (
          (dynamicField?.option_name ?? '') +
          (count !== '0' ? `（${count}人）` : '')
        );
      })
      .filter((o) => !!o)
      .join('・');
    return {
      value: formattedValue,
      formattedValue,
    };
  }

  return { value: rowValueString, formattedValue: rowValueString };
};
