import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  ListStatusError,
  StatusRequest,
  ListStatusResponse,
  UpdateStatusResponse,
  UpdateStatusRequest,
} from 'services';
import { ThunkAPI } from 'types';

export const getListStatus = createAsyncThunk<
  ListStatusResponse,
  StatusRequest,
  ThunkAPI<ListStatusError>
>('ApplicationTable/getListStatus', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getListStatus(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateStatus = createAsyncThunk<
  UpdateStatusResponse,
  UpdateStatusRequest,
  ThunkAPI<ListStatusError>
>('ApplicationTable/updateStatus', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.updateStatus(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
