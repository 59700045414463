import { Stack, Typography } from '@mui/material';
import { SPTitle } from 'constant';
import { SPFormItem } from 'components';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { formatJapanDate } from 'utils';
import { spStepOneSelector } from '../../../SPStepOne/selectors';

export const SPLoanApplyDateInfo: FC = () => {
  const spStepOneValues = useSelector(spStepOneSelector);

  const { loan_apply_date } = spStepOneValues.p_application_header;

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{ px: 4, py: 1, bgcolor: 'sp_primary_100_main' }}
      >
        <Typography variant="sp_label_text" color="main_white">
          {SPTitle.LOAN_APPLY_DATE}
        </Typography>
      </Stack>

      <SPFormItem
        label="同意日"
        labelSx={{ p: '3px 16px' }}
        childrenSx={{ p: '12px 24px 20px' }}
        required
      >
        {!!loan_apply_date ? (
          <Typography variant="sp_value_text" color="b_333">
            {formatJapanDate(loan_apply_date, true)}
          </Typography>
        ) : (
          <Typography variant="sp_value_text" color="b_333">
            ー
          </Typography>
        )}
      </SPFormItem>
      <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }} />
    </Stack>
  );
};
