import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPMortgagePlusModal: FC<ModalProps> = ({ open, onClose }) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="住宅ローンプラスとは"
      disableBackDropClick
      sx={{ px: 0 }}
      footer={
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: 359 }}
        >
          <Stack sx={{ overflowY: 'scroll', maxHeight: '37vh' }} spacing={2}>
            <Stack sx={{ mb: 6, mx: 6 }}>
              <Typography variant="sp_change_password_note" color="b_333">
                {`「住宅ローンプラス」は住宅建築・購入資金にプラスして、個人の消費性資金をお借入れいただける住宅ローン商品です。`}
              </Typography>
            </Stack>

            {mortgagePlus.map((item, index) => (
              <Stack key={index} spacing={3} sx={{ mb: 6 }}>
                <Stack sx={{ bgcolor: 'sp_primary_40', py: 1, pl: 4 }}>
                  <Typography variant="sp_label_form_text" color="b_333">
                    {item.title}
                  </Typography>
                </Stack>
                <Stack sx={{ px: 5 }}>
                  <Typography variant="sp_change_password_note" color="b_333">
                    {item.content}
                  </Typography>
                </Stack>
                {!!item.note && (
                  <Stack sx={{ px: 5, pb: index !== 4 ? 6 : 0 }}>
                    <Stack
                      sx={{
                        bgcolor: index !== 4 ? 'sp_gray_100' : '',
                        p: 1,
                      }}
                      direction="row"
                      spacing={1}
                    >
                      <Typography
                        textAlign="left"
                        variant="annotation01"
                        color="b_333"
                      >
                        ※
                      </Typography>
                      <Typography
                        textAlign="left"
                        variant="annotation01"
                        color="b_333"
                      >
                        {item.note}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            ))}
          </Stack>

          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};

const mortgagePlus = [
  {
    title: '1. 個人の消費性資金とは',
    content:
      '①すでにお借入れいただいている無担保ローンのおまとめ（借換）資金（例：マイカーローン、教育ローン、フリーローン、カードローンなど）' +
      '\n\n' +
      '②住宅ローン借入と同時に新規借入するフリー資金（例：家電購入費用）',
    note: '投機性資金や生活資金、事業性資金にはご利用いただけません。\n',
  },
  {
    title: '2. 借入限度額',
    content:
      'つぎの①、②のいずれか低い金額が借入限度額となります。\n' +
      '\n' +
      '①500万円\n' +
      '②住宅建築・購入にかかる諸費用を除いた住宅ローン借入金額の50％以内',
    note: 'ペアローンをご利用の場合は、お二人合わせて上記金額の範囲内となります。',
  },
  {
    title: '3. ご提出いただく資料',
    content:
      '住宅ローンの正式審査申込時に、申込書に記載したお借入希望の個人消費性資金について、内容および金額がわかる資料をご提出いただきます。\n' +
      '（例：借換対象となるお借入れの返済予定表、カードローン利用明細、見積書　など）',
    note: '仮審査申込時は書類の提出は不要です。',
  },
  {
    title: `4. ローンお借入日およびお借入れ後の手\n　 続きについて`,
    content:
      '住宅ローンお借入日に住宅ローン資金と合わせてお客さまの普通預金口座に入金後、お支払先（返済先）に振込されます。\n' +
      '住宅ローン借入日から一週間以内に、領収書や完済、残高０円がわかる証明書などを銀行あてにご提出ください。',
    note: 'カードローンをおまとめされた場合は、カードローン枠をご解約いただく必要がございます。',
  },
  {
    title: '5. その他',
    content:
      '「住宅ローンプラス」はMG保証株式会社が保証する住宅ローンです。\n' +
      '・住宅ローンの適用金利が上乗せとなります。なお、上乗せ利率は審査の結果決定致します。\n' +
      '・ペアローンをご利用の場合は、おふたりとも「住宅ローンプラス」をご利用いただきます。\n' +
      '・個人消費性資金は住宅ローン減税の対象となりません。',
    note: '',
  },
];
