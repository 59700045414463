import { createAsyncThunk } from '@reduxjs/toolkit';
import { applicationsService, GetUnreadMessagesResponse } from 'services';

export const getUnreadMessages = createAsyncThunk<GetUnreadMessagesResponse>(
  'spTop/getUnreadMessages',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getUnreadMessages();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
