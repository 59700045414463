import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPSaveDraftModal: FC<ModalProps> = ({ open, onClose }) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      sx={{ minWidth: 292 }}
      icon={<Icons.SPCloudSave sx={{ width: 35, height: 25 }} />}
      disableBackDropClick
      footer={
        <Stack sx={{ width: '100%' }} alignItems="center">
          <SPButton
            sx={{
              width: 160,
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    >
      <Typography
        variant="SP_title_modal"
        color="sp_primary_100_main"
        sx={{
          textAlign: 'center',
          whiteSpace: 'break-spaces',
        }}
      >
        {`ここまでの情報を\n保存しました！`}
      </Typography>
    </SPModal>
  );
};
