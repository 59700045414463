import { Stack, Typography } from '@mui/material';
import {
  DispatchType,
  FileATaxReturnType,
  OccupationDetailType,
  OccupationType,
  SourceOfIncomeType,
  spFinalTaxReturn,
  SPTaxReturnType,
  SPTitle,
  WorkIndustryType,
} from 'constant';
import { Icons, SPButton, SPFormItem } from 'components';
import { routeNames } from 'navigations/routes';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  convertYenToMan,
  numberWithCommas,
} from 'utils';
import { useNavigate } from 'react-router-dom';
import { spFinalTaxReturnOptions } from 'pages/SPStepThree';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { spStepThreeSelector } from 'pages/SPStepThree/selectors';
import kanjidate from 'kanjidate';
import { checkMCJ } from 'pages/SPStepOne/selectors';

type SPStepThreeInfoProps = {
  showEditButton?: boolean;
};

export const SPStepThreeInfo: FC<SPStepThreeInfoProps> = ({
  showEditButton = true,
}) => {
  const navigate = useNavigate();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const isMCJ = useSelector(checkMCJ);
  const { p_applicant_people } = useSelector(spStepThreeSelector);

  const occupationOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.occupation),
    [dynamicOptions.occupation]
  );

  const industryOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.industry),
    [dynamicOptions.industry]
  );

  const occupationDetailOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.occupation_detail),
    [dynamicOptions.occupation_detail]
  );

  const incomeOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.income_source),
    [dynamicOptions.income_source]
  );

  const typeTaxReturnOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.type_tax_return),
    [dynamicOptions.type_tax_return]
  );

  const maternityStatusOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.maternity_paternity_leave_status),
    [dynamicOptions.maternity_paternity_leave_status]
  );

  const nursingCareOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nursing_leave_status),
    [dynamicOptions.nursing_leave_status]
  );

  const getDateValue = (value: string) => {
    const [year, month] = value.split('/');
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return `${year}（${kanjiDateG2}${kanjiDateN}）年${+month}月`;
  };

  return (
    <Stack>
      <Stack>
        <Stack
          direction="row"
          sx={{ px: 4, py: 1, bgcolor: 'sp_primary_100_main' }}
        >
          <Typography variant="sp_step_text" color="main_white" sx={{ mt: 1 }}>
            STEP
          </Typography>
          <Typography variant="sp_label_text" color="main_white">
            {SPTitle.STEP_THREE}
          </Typography>
        </Stack>

        <SPFormItem
          label="ご職業"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {occupationOptions.find(
            (e) => e.value === p_applicant_people.occupation
          ) ? (
            <Typography variant="sp_value_text" color="b_333">
              {
                occupationOptions.find(
                  (e) => e.value === p_applicant_people.occupation
                )?.label
              }
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        {p_applicant_people.occupation === OccupationType.OTHERS && (
          <SPFormItem
            label="その他の詳細"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {p_applicant_people.other_occupation}
            </Typography>
          </SPFormItem>
        )}

        <SPFormItem
          label="業種"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {industryOptions.find(
            (e) => e.value === p_applicant_people.industry
          ) ? (
            <Typography variant="sp_value_text" color="b_333">
              {
                industryOptions.find(
                  (e) => e.value === p_applicant_people.industry
                )?.label
              }
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        {p_applicant_people.industry === WorkIndustryType.OTHERS && (
          <SPFormItem
            label="その他の詳細"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {p_applicant_people.other_industry}
            </Typography>
          </SPFormItem>
        )}

        <SPFormItem
          label="職種"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {occupationDetailOptions.find(
            (e) => e.value === p_applicant_people.occupation_detail
          ) ? (
            <Typography variant="sp_value_text" color="b_333">
              {
                occupationDetailOptions.find(
                  (e) => e.value === p_applicant_people.occupation_detail
                )?.label
              }
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        {p_applicant_people.occupation_detail ===
          OccupationDetailType.OTHERS && (
          <SPFormItem
            label="その他の詳細"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {p_applicant_people.other_occupation_detail}
            </Typography>
          </SPFormItem>
        )}

        <SPFormItem
          label="勤務先名"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.office_name_kanji ? (
            <Typography variant="sp_value_text" color="b_333">
              {p_applicant_people.office_name_kanji}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>
        <SPFormItem
          label="所属部課"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.department ? (
            <Typography variant="sp_value_text" color="b_333">
              {p_applicant_people.department}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        <SPFormItem
          label="勤務先の電話番号"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.office_phone_number ? (
            <Typography variant="sp_value_text" color="b_333">
              {p_applicant_people.office_phone_number}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        <SPFormItem
          label="勤務先の住所"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.office_postal_code ? (
            <Stack spacing={3}>
              <Typography variant="sp_value_text" color="b_333">
                〒{p_applicant_people.office_postal_code}
              </Typography>
              <Typography variant="sp_value_text" color="b_333">
                {p_applicant_people.office_prefecture_kanji}
                {p_applicant_people.office_city_kanji}
                {p_applicant_people.office_district_kanji}
              </Typography>
              <Typography variant="sp_value_text" color="b_333">
                {p_applicant_people.other_office_address_kanji}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        <SPFormItem
          label="従業員数"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.number_of_employee ? (
            <Typography variant="sp_value_text" color="b_333">
              {numberWithCommas(p_applicant_people.number_of_employee)}名
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        <SPFormItem
          label="入社年月"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.employment_started_date ? (
            <Typography variant="sp_value_text" color="b_333">
              {getDateValue(p_applicant_people.employment_started_date)}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        <SPFormItem
          label="ご年収"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.last_year_income ||
          p_applicant_people.bonus_income ||
          p_applicant_people.two_years_ago_income ||
          p_applicant_people.income_source.length ||
          p_applicant_people.tax_return ||
          p_applicant_people.other_type_tax_return.length ? (
            <Stack spacing={3}>
              <Stack spacing={3}>
                <Typography variant="sp_value_text" color="b_333">
                  〈前年度年収〉
                </Typography>
                <Typography pl={8} variant="sp_value_text" color="b_333">
                  {p_applicant_people.last_year_income
                    ? `${numberWithCommas(
                        +convertYenToMan([p_applicant_people.last_year_income])
                      )}万円`
                    : 'ー'}
                </Typography>
              </Stack>
              {isMCJ && (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈うち、ボーナス（MCJ固有項目）〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {p_applicant_people.bonus_income
                      ? `${numberWithCommas(
                          +convertYenToMan([p_applicant_people.bonus_income])
                        )}万円`
                      : 'ー'}
                  </Typography>
                </Stack>
              )}
              {isMCJ && (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈前々年度年収（MCJ固有項目）〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {p_applicant_people.two_years_ago_income
                      ? `${numberWithCommas(
                          +convertYenToMan([
                            p_applicant_people.two_years_ago_income,
                          ])
                        )}万円`
                      : 'ー'}
                  </Typography>
                </Stack>
              )}
              <Stack spacing={3}>
                <Typography variant="sp_value_text" color="b_333">
                  〈収入源〉
                </Typography>
                {!p_applicant_people.income_source.length && (
                  <Typography variant="sp_value_text" color="b_333" pl={8}>
                    ー
                  </Typography>
                )}
                {p_applicant_people.income_source.find(
                  (e) => e === SourceOfIncomeType.SALARY_FIXED_SALARY
                ) && (
                  <Stack pl={8} direction="row">
                    <Typography variant="sp_value_text" color="b_333">
                      {
                        incomeOptions.find(
                          (e) => e.id === SourceOfIncomeType.SALARY_FIXED_SALARY
                        )?.name
                      }
                    </Typography>
                  </Stack>
                )}
                {p_applicant_people.income_source.find(
                  (e) => e === SourceOfIncomeType.SALARY_COMMISSION_PAY
                ) && (
                  <Stack pl={8} direction="row">
                    <Typography variant="sp_value_text" color="b_333">
                      {
                        incomeOptions.find(
                          (e) =>
                            e.id === SourceOfIncomeType.SALARY_COMMISSION_PAY
                        )?.name
                      }
                    </Typography>
                  </Stack>
                )}
                {p_applicant_people.income_source.find(
                  (e) => e === SourceOfIncomeType.SALARY_ANNUAL_SALARY_SYSTEM
                ) && (
                  <Stack pl={8} direction="row">
                    <Typography variant="sp_value_text" color="b_333">
                      {
                        incomeOptions.find(
                          (e) =>
                            e.id ===
                            SourceOfIncomeType.SALARY_ANNUAL_SALARY_SYSTEM
                        )?.name
                      }
                    </Typography>
                  </Stack>
                )}
                {p_applicant_people.income_source.find(
                  (e) => e === SourceOfIncomeType.BUSINESS_INCOME
                ) && (
                  <Stack pl={8} direction="row">
                    <Typography variant="sp_value_text" color="b_333">
                      {
                        incomeOptions.find(
                          (e) => e.id === SourceOfIncomeType.BUSINESS_INCOME
                        )?.name
                      }
                    </Typography>
                  </Stack>
                )}
                {p_applicant_people.income_source.find(
                  (e) => e === SourceOfIncomeType.REAL_ESTATE_INCOME
                ) && (
                  <Stack pl={8} direction="row">
                    <Typography variant="sp_value_text" color="b_333">
                      {
                        incomeOptions.find(
                          (e) => e.id === SourceOfIncomeType.REAL_ESTATE_INCOME
                        )?.name
                      }
                    </Typography>
                  </Stack>
                )}
              </Stack>
              {p_applicant_people.tax_return && (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈確定申告をしていますか？〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {
                      spFinalTaxReturnOptions.find(
                        (e) => e.value === p_applicant_people.tax_return
                      )?.label
                    }
                  </Typography>
                </Stack>
              )}
              {p_applicant_people.tax_return === SPTaxReturnType.YES &&
                p_applicant_people.type_tax_return && (
                  <Stack spacing={3}>
                    <Typography variant="sp_value_text" color="b_333">
                      〈確定申告の理由〉
                    </Typography>
                    {p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.ONE
                    ) && (
                      <Stack pl={8} direction="row">
                        <Typography variant="sp_value_text" color="b_333">
                          {
                            typeTaxReturnOptions.find(
                              (e) => e.id === FileATaxReturnType.ONE
                            )?.name
                          }
                        </Typography>
                      </Stack>
                    )}
                    {p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.TWO
                    ) && (
                      <Stack pl={8} direction="row">
                        <Typography variant="sp_value_text" color="b_333">
                          {
                            typeTaxReturnOptions.find(
                              (e) => e.id === FileATaxReturnType.TWO
                            )?.name
                          }
                        </Typography>
                      </Stack>
                    )}
                    {p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.THREE
                    ) && (
                      <Stack pl={8} direction="row">
                        <Typography variant="sp_value_text" color="b_333">
                          {
                            typeTaxReturnOptions.find(
                              (e) => e.id === FileATaxReturnType.THREE
                            )?.name
                          }
                        </Typography>
                      </Stack>
                    )}
                    {p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.FOUR
                    ) && (
                      <Stack pl={8} direction="row">
                        <Typography variant="sp_value_text" color="b_333">
                          {
                            typeTaxReturnOptions.find(
                              (e) => e.id === FileATaxReturnType.FOUR
                            )?.name
                          }
                        </Typography>
                      </Stack>
                    )}
                    {p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.FIVE
                    ) && (
                      <Stack pl={8} direction="row">
                        <Typography variant="sp_value_text" color="b_333">
                          {
                            typeTaxReturnOptions.find(
                              (e) => e.id === FileATaxReturnType.FIVE
                            )?.name
                          }
                        </Typography>
                      </Stack>
                    )}
                    {p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.SIX
                    ) && (
                      <Stack pl={8} direction="row">
                        <Typography variant="sp_value_text" color="b_333">
                          {
                            typeTaxReturnOptions.find(
                              (e) => e.id === FileATaxReturnType.SIX
                            )?.name
                          }
                        </Typography>
                      </Stack>
                    )}
                    {p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.OTHERS
                    ) && (
                      <Stack pl={8} direction="row" spacing={2}>
                        <Typography variant="sp_value_text" color="b_333">
                          {
                            typeTaxReturnOptions.find(
                              (e) => e.id === FileATaxReturnType.OTHERS
                            )?.name
                          }
                        </Typography>
                        <Typography variant="sp_value_text" color="b_333">
                          : {p_applicant_people.other_type_tax_return}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                )}
            </Stack>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        <SPFormItem
          label="現在、出向（派遣）していますか？"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.transfer_office ? (
            p_applicant_people.transfer_office === DispatchType.YES ? (
              <Stack spacing={3}>
                {p_applicant_people.transfer_office_name_kanji && (
                  <Stack spacing={3}>
                    <Typography variant="sp_value_text" color="b_333">
                      〈出向（派遣）勤務先名〉
                    </Typography>
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      {p_applicant_people.transfer_office_name_kanji}
                    </Typography>
                  </Stack>
                )}
                {p_applicant_people.transfer_office_name_kana && (
                  <Stack spacing={3}>
                    <Typography variant="sp_value_text" color="b_333">
                      〈出向（派遣）勤務先名（フリガナ）〉
                    </Typography>
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      {p_applicant_people.transfer_office_name_kana}
                    </Typography>
                  </Stack>
                )}
                {p_applicant_people.transfer_office_phone_number && (
                  <Stack spacing={3}>
                    <Typography variant="sp_value_text" color="b_333">
                      〈出向（派遣）先 電話番号〉
                    </Typography>
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      {p_applicant_people.transfer_office_phone_number}
                    </Typography>
                  </Stack>
                )}
                {p_applicant_people.transfer_office_postal_code && (
                  <Stack spacing={3}>
                    <Typography variant="sp_value_text" color="b_333">
                      〈出向（派遣）先　住所〉
                    </Typography>
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      〒{p_applicant_people.transfer_office_postal_code}
                    </Typography>
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      {p_applicant_people.transfer_office_prefecture_kanji}
                      {p_applicant_people.transfer_office_city_kanji}{' '}
                      {p_applicant_people.transfer_office_district_kanji}
                    </Typography>
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      {p_applicant_people.transfer_office_other_address_kanji}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            ) : (
              <Typography variant="sp_value_text" color="b_333">
                {spFinalTaxReturn[p_applicant_people.transfer_office]}
              </Typography>
            )
          ) : (
            'ー'
          )}
        </SPFormItem>

        <SPFormItem
          label="産休・育休の取得状況"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {p_applicant_people.maternity_paternity_leave_status ? (
            <Stack spacing={3}>
              <Typography variant="sp_value_text" color="b_333">
                {
                  maternityStatusOptions.find(
                    (e) =>
                      e.value ===
                      p_applicant_people.maternity_paternity_leave_status
                  )?.label
                }
              </Typography>
              {p_applicant_people.maternity_paternity_leave_start_time && (
                <Typography variant="sp_value_text" color="b_333">
                  〈取得開始時期〉
                  {getDateValue(
                    p_applicant_people.maternity_paternity_leave_start_time
                  )}
                </Typography>
              )}
              {p_applicant_people.maternity_paternity_leave_end_time && (
                <Typography variant="sp_value_text" color="b_333">
                  〈取得終了時期〉
                  {getDateValue(
                    p_applicant_people.maternity_paternity_leave_end_time
                  )}
                </Typography>
              )}
            </Stack>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        {isMCJ && (
          <SPFormItem
            label={
              <Stack direction="row" spacing={1}>
                <Typography variant="sp_unit_select_text" color="b_333">
                  介護休暇の取得状況
                </Typography>
                <Typography
                  variant="sp_unit_select_text"
                  color="b_333"
                  sx={{ fontSize: 12 }}
                >
                  （MCJ固有項目）
                </Typography>
              </Stack>
            }
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            {p_applicant_people.nursing_leave_status ? (
              <Typography variant="sp_value_text" color="b_333">
                {
                  nursingCareOptions.find(
                    (e) => e.value === p_applicant_people.nursing_leave_status
                  )?.label
                }
              </Typography>
            ) : (
              <Typography variant="sp_value_text" color="b_333">
                ー
              </Typography>
            )}
          </SPFormItem>
        )}

        <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
          <Stack
            sx={{
              paddingRight: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: 'none',
            }}
          >
            {showEditButton && (
              <SPButton
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: '10px',
                  borderWidth: 2,
                  bgcolor: 'sp_primary_40',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
                variant="outlined"
                fullWidth
                onClick={() => navigate(routeNames.SPStepThree.path)}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icons.SPPencil />
                  <Typography
                    variant="sp_contentButton_text"
                    color="sp_primary_100_main"
                  >
                    STEP03 を修正する
                  </Typography>
                </Stack>
              </SPButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
