import {
  FC,
  useEffect,
  useMemo,
  FocusEvent,
  ChangeEvent,
  useCallback,
  useState,
  useRef,
  Fragment,
  forwardRef,
  ForwardedRef,
  ReactNode,
} from 'react';
import { FastField, FastFieldProps } from 'formik';
import {
  Stack,
  SxProps,
  TextFieldProps,
  Typography,
  TextField,
  Chip,
} from '@mui/material';
import { isHiragana, toKatakana } from 'wanakana';
import { BasePlacement } from '@popperjs/core/lib/enums';
import { convertToFullWidth, convertToHalfWidth } from 'utils';
import { debounce } from 'lodash';
import get from 'lodash/get';
import { Icons } from 'components';
import { NumberFormat, NumberFormatDecimal } from '../NumberFormat';

export type SPInputFieldProps = TextFieldProps & {
  name: string;
  placeholder?: string;
  unit?: string | ReactNode;
  align?: 'left' | 'right';
  useNumberFormat?: boolean;
  convertKatakana?: boolean;
  toFixedNumber?: boolean;
  labelSpacing?: number;
  labelStyle?: SxProps;
  numeric?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  placement?: BasePlacement | 'top-end' | 'top-start' | 'bottom-start';
  maxLength?: number;
  convertHalfWidth?: boolean;
  convertFullWidth?: boolean;
  decimalScale?: number;
  sxPopover?: SxProps;
  showError?: boolean;
  hasPassword?: boolean;
  note?: string;
  optional?: boolean;
  autoTrim?: boolean;
};

export const SPInputFieldTemp: FC<SPInputFieldProps & FastFieldProps> = ({
  field,
  form,
  meta,
  label,
  InputProps,
  unit,
  useNumberFormat,
  convertKatakana,
  toFixedNumber,
  labelSpacing = '6px',
  labelStyle,
  numeric,
  align = 'left',
  errorMessage,
  readOnly,
  onChange,
  placement = 'top-end',
  maxLength,
  convertHalfWidth,
  convertFullWidth,
  decimalScale,
  showError = true,
  hasPassword = false,
  autoTrim = true,
  note,
  sx,
  onFocus,
  optional,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  // const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const isSuccess = useMemo(
    () => !meta.error && !props.error && meta.value,
    [meta.error, meta.value, props.error]
  );
  const [firstTouchField, setFirstTouchField] = useState(false);

  const formatNumber = useCallback(
    (number: string) => {
      if (number === '') return number;
      if (/^0*$/.test(number)) return '0';
      if (number.indexOf('.') !== -1) return number.replace(/\./g, '');
      if (toFixedNumber) return Number(number).toFixed(2);
      return Number(number);
    },
    [toFixedNumber]
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onFocus?.(e);
      setFirstTouchField(true);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      await field.onBlur(e);
      const value = autoTrim
        ? meta.value?.toString().trim()
        : meta.value?.toString();
      form.setFieldValue(props.name, value);
      if (convertHalfWidth) {
        if (isHiragana(value) && convertKatakana) {
          form.setFieldValue(props.name, convertToHalfWidth(toKatakana(value)));
        } else {
          form.setFieldValue(props.name, convertToHalfWidth(value));
        }
      }

      if (convertFullWidth) {
        form.setFieldValue(props.name, convertToFullWidth(value));
      }

      if (useNumberFormat) {
        form.setFieldValue(props.name, formatNumber(value));
      }
      props.onBlur && props.onBlur(e);
      setFirstTouchField(false);
    },
    [
      field,
      autoTrim,
      meta.value,
      form,
      props,
      convertHalfWidth,
      convertFullWidth,
      useNumberFormat,
      convertKatakana,
      formatNumber,
    ]
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      await field.onChange(event);
      onChange && onChange(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field, onChange, showPassword]
  );

  const debouncedFunction = useRef(
    debounce((error, touched, value) => {
      // @ts-ignore
      if (!!error && !!value) {
        return setOpen(!!error && !!value);
      }
      return setOpen(!!error && !!touched);
    }, 250)
  );

  const debounceError = useCallback(
    // @ts-ignore
    (error, touched, value) => {
      return debouncedFunction.current(error, touched, value);
    },
    []
  );

  useEffect(() => {
    debounceError(meta.error, meta.touched, meta.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.error, meta.touched, meta.value]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debouncedFunction.current?.cancel();
    };
  }, []);
  return (
    <Fragment>
      <Stack spacing={labelSpacing}>
        {(optional || !!label) && (
          <Stack direction="row" alignItems="center" spacing="6px">
            {optional && (
              <Chip
                label={
                  <Typography
                    variant="SP_required_chip_label"
                    color="main_white"
                  >
                    任意
                  </Typography>
                }
                sx={{
                  bgcolor: 'sp_gray_250',
                  borderRadius: 1,
                  height: 14,
                  width: 29,
                  p: 0,
                  texAlign: 'center',
                  '.MuiChip-label': { p: '1px 3px' },
                }}
              />
            )}
            {!!label && (
              <Typography
                variant="buttonAddImage"
                color="b_333"
                sx={labelStyle}
              >
                {label}
              </Typography>
            )}
          </Stack>
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack flex={1} spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextField
                sx={{
                  width: '100%',
                  maxHeight: 48,
                  '.MuiInputBase-input': (theme) => ({
                    ...theme.typography.input_style,
                    p: 4,
                    pr: hasPassword ? '35px' : 4,
                    maxHeight: 16,
                    boxShadow: 'none',
                    fontWeight: '300',
                    fontSize: 16,
                    backgroundColor: readOnly ? 'sp_gray_100' : 'main_white',
                    color: `${
                      !firstTouchField &&
                      (meta.error || props.error) &&
                      meta.touched
                        ? 'sp_secondary_01'
                        : 'normal_text'
                    }`,
                  }),
                  '&&&& fieldset': {
                    borderWidth: readOnly ? 0 : 1,
                    borderColor: `${
                      !firstTouchField &&
                      (meta.error || props.error) &&
                      meta.touched
                        ? 'sp_secondary_01'
                        : 'sp_primary_40'
                    }`,
                    boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
                  },
                  ...sx,
                  '&&&& .Mui-error': {
                    '.MuiInputBase-input': {
                      backgroundColor: 'main_white',
                      boxShadow: 'none',
                    },
                    fieldset: {
                      borderWidth: 1,
                      borderColor: 'sh_red',
                    },
                  },
                  '&&&& .Mui-success': {
                    '.MuiInputBase-input': {
                      backgroundColor: 'sp_gray_100',
                      boxShadow: 'none',
                    },
                    fieldset: {
                      border: 'none',
                      boxShadow: 'none',
                    },
                  },
                  '&&&& .Mui-focused': {
                    '.MuiInputBase-input': {
                      backgroundColor: readOnly
                        ? 'sp_primary_60'
                        : 'sp_bg_yellow',
                    },
                    fieldset: {
                      borderWidth: readOnly ? 0 : 1,
                      borderColor: 'sp_primary_100_main',
                    },
                  },
                  '&&&& .Mui-focused.Mui-success': {
                    fieldset: {
                      border: '1px solid',
                      borderColor: 'sp_primary_100_main',
                      boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
                    },
                  },
                  '&&&& .Mui-disabled': {
                    WebkitTextFillColor: '#333',
                  },
                }}
                {...props}
                InputProps={{
                  ...(useNumberFormat && {
                    inputComponent: (decimalScale
                      ? NumberFormatDecimal
                      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        NumberFormat) as any,
                  }),
                  ...InputProps,
                  type: hasPassword && !showPassword ? 'password' : props?.type,
                  sx: {
                    ...InputProps?.sx,
                    '.MuiInputBase-input': {
                      textAlign: align,
                      boxShadow: !readOnly
                        ? 'inset 0px 2px 8px rgba(0, 0, 0, 0.25)'
                        : undefined,
                      backgroundColor: !readOnly ? 'main_white' : undefined,
                      borderRadius: 1,
                    },
                    '&&&& fieldset': {
                      borderWidth: readOnly ? '1px' : undefined,
                    },
                  },
                  className: `${isSuccess && !readOnly ? 'Mui-success' : ''} `,
                  onBlur: handleBlur,
                  onFocus: handleFocus,
                  onChange: handleChange,
                }}
                inputProps={{
                  ...(numeric && {
                    inputMode: 'numeric',
                  }),
                  readOnly: readOnly,
                  maxLength: maxLength,
                }}
                name={field.name}
                value={meta.value}
              />
              {typeof unit === 'string' ? (
                <Typography
                  variant="sp_unit_text"
                  color="b_333"
                  whiteSpace="nowrap"
                >
                  {unit}
                </Typography>
              ) : (
                unit
              )}
            </Stack>
            {!firstTouchField && meta.error && open && showError && (
              <Typography variant="text_error" color="sp_secondary_01">
                ※{meta.error ?? errorMessage ?? ''}
              </Typography>
            )}
          </Stack>

          {!!note && (
            <Typography
              variant="sp_note_text"
              color="b_333"
              whiteSpace="nowrap"
            >
              {note}
            </Typography>
          )}
        </Stack>
      </Stack>
      {hasPassword &&
        (showPassword ? (
          <Icons.SPCheckPassword
            sx={{
              width: 18,
              height: 16,
              position: 'absolute',
              right: 9,
              top: 4,
              color: `${
                !firstTouchField && meta.error && meta.touched
                  ? 'sp_secondary_01'
                  : 'sp_primary_100_main'
              }`,
            }}
            onClick={() => {
              setShowPassword(false);
            }}
          />
        ) : (
          <Icons.SPUnCheckPassword
            sx={{
              width: 18,
              height: 16,
              position: 'absolute',
              right: 9,
              top: 4,
              color: `${
                !firstTouchField && meta.error && meta.touched
                  ? 'sp_secondary_01'
                  : 'sp_primary_100_main'
              }`,
            }}
            onClick={() => {
              setShowPassword(true);
            }}
          />
        ))}
    </Fragment>
  );
};

export const SPInputField = forwardRef<HTMLInputElement, SPInputFieldProps>(
  (props, inputRef) => {
    const shouldUpdate = useCallback(
      (
        nextProps: {
          inputRef: ForwardedRef<HTMLInputElement>;
          error: boolean;
          onChange?: (
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => void;
        },
        prevProps: {
          inputRef: ForwardedRef<HTMLInputElement>;
          error: boolean;
          onChange?: (
            e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => void;
        }
      ) =>
        nextProps.inputRef !== prevProps.inputRef ||
        nextProps.error !== prevProps.error ||
        nextProps.onChange !== prevProps.onChange ||
        get(nextProps, `formik.values.${props.name}`) !==
          get(prevProps, `formik.values.${props.name}`) ||
        get(nextProps, `formik.errors.${props.name}`) !==
          get(prevProps, `formik.errors.${props.name}`) ||
        get(nextProps, `formik.touched.${props.name}`) !==
          get(prevProps, `formik.touched.${props.name}`),
      [props.name]
    );
    return (
      <FastField
        name={props.name}
        shouldUpdate={shouldUpdate}
        inputRef={inputRef}
        error={props.error}
        onChange={props.onChange}
      >
        {(ffProps: FastFieldProps) => {
          return <SPInputFieldTemp {...props} {...ffProps} />;
        }}
      </FastField>
    );
  }
);
