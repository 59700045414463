export const styleScrollBar = {
  '&::-webkit-scrollbar-thumb': {
    bgcolor: 'sh_navy',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    border: 'none',
  },
};

export const styleScrollBarTable = {
  '&::-webkit-scrollbar-thumb': {
    bgcolor: 'normal_text',
    borderRadius: '10px',
    minHeight: '120px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    border: 'none',
  },
};

export const hiddenScrollBar = {
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const customProps = {
  sx: {
    fieldset: {
      borderWidth: '1px !important',
      borderColor: 'black !important',
      borderRadius: '8px !important',
    },
  },
};

export const styleScrollBarConfirm = {
  '&::-webkit-scrollbar-thumb': {
    bgcolor: 'scroll_gray',
    borderRadius: '10px',
    borderLeft: '2px solid transparent',
    borderRight: '2px solid transparent',
    backgroundClip: 'padding-box',
  },
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    bgcolor: 'bg_gray',
    width: '10px',
  },
};

export const styleScrollBarModel = {
  '&::-webkit-scrollbar-thumb': {
    bgcolor: 'close_icon',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    border: 'none',
  },
};
