import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { useSelector } from 'react-redux';
import { sex, SPTitle } from 'constant';
import { Icons, SPButton, SPFormItem } from 'components';
import { formatJapanDate } from 'utils';
import { routeNames } from 'navigations/routes';
import { useNavigate } from 'react-router-dom';
import { checkIncomeTotalizer } from 'pages/SPStepOne/selectors';

interface SPStepFourCollateralProviderInfoProps {
  showEditButton?: boolean;
}

export const SPStepFourCollateralProviderInfo: FC<
  SPStepFourCollateralProviderInfoProps
> = ({ showEditButton = true }) => {
  const navigate = useNavigate();
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const { p_join_guarantors } = useSelector(spStepCollateralProviderSelector);
  const pJoinGuarantors = p_join_guarantors.filter(
    (guarantor) => !guarantor._destroy
  );

  return (
    <Stack>
      <Stack>
        <Stack
          direction="row"
          sx={{ px: 4, py: 1, bgcolor: 'sp_primary_100_main' }}
        >
          <Typography variant="sp_step_text" color="main_white" sx={{ mt: 1 }}>
            STEP
          </Typography>
          <Typography variant="sp_label_text" color="main_white">
            {incomeTotalizer
              ? SPTitle.STEP_SIX_INCOME_GUARANTORS
              : SPTitle.STEP_FOUR_GUARANTORS}
          </Typography>
        </Stack>

        {pJoinGuarantors.map((joinGuarantors, index) => (
          <SPFormItem
            key={index}
            label={`担保提供者 ${index + 1}人目`}
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Stack spacing={3}>
              {joinGuarantors.last_name_kanji ||
              joinGuarantors.first_name_kanji ? (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈担保提供者の氏名〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {joinGuarantors.last_name_kanji}
                    {` `}
                    {joinGuarantors.first_name_kanji}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  〈担保提供者の氏名〉ー
                </Typography>
              )}
              {joinGuarantors.last_name_kana ||
              joinGuarantors.first_name_kana ? (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈担保提供者の氏名（フリガナ）〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {joinGuarantors.last_name_kana}
                    {` `}
                    {joinGuarantors.first_name_kana}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  〈担保提供者の氏名（フリガナ）〉ー
                </Typography>
              )}
              {joinGuarantors.sex ? (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈性別〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {sex[joinGuarantors.sex]}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  〈性別〉ー
                </Typography>
              )}
              {joinGuarantors.guarantor_relationship_name ? (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈続柄〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {joinGuarantors.guarantor_relationship_name}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  〈続柄〉ー
                </Typography>
              )}
              {joinGuarantors.birthday ? (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈生年月日〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {formatJapanDate(joinGuarantors.birthday, true)}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  〈生年月日〉ー
                </Typography>
              )}
              {joinGuarantors.mobile_phone_number ||
              joinGuarantors.home_phone_number ? (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈電話番号〉
                  </Typography>
                  {joinGuarantors.mobile_phone_number && (
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      〈携帯〉：{joinGuarantors.mobile_phone_number}
                    </Typography>
                  )}
                  {joinGuarantors.home_phone_number && (
                    <Typography pl={8} variant="sp_value_text" color="b_333">
                      〈自宅〉：{joinGuarantors.home_phone_number}
                    </Typography>
                  )}
                </Stack>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  〈電話番号〉ー
                </Typography>
              )}
              {joinGuarantors.postal_code ? (
                <Stack spacing={3}>
                  <Typography variant="sp_value_text" color="b_333">
                    〈担保提供者の住所〉
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    〒{joinGuarantors.postal_code}
                  </Typography>
                  <Typography pl={8} variant="sp_value_text" color="b_333">
                    {joinGuarantors.prefecture_kanji}
                    {joinGuarantors.city_kanji} {joinGuarantors.district_kanji}{' '}
                    {joinGuarantors.other_address_kanji ?? ''}
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  〈担保提供者の住所〉ー
                </Typography>
              )}
            </Stack>
          </SPFormItem>
        ))}

        <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
          <Stack
            sx={{
              paddingRight: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: 'none',
            }}
          >
            {showEditButton && (
              <SPButton
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: '10px',
                  borderWidth: 2,
                  bgcolor: 'sp_primary_40',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
                variant="outlined"
                fullWidth
                onClick={() =>
                  navigate(routeNames.SPStepFourCollateralProvider.path, {
                    state: { pathName: window.location.pathname },
                  })
                }
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icons.SPPencil />
                  <Typography
                    variant="sp_contentButton_text"
                    color="sp_primary_100_main"
                  >
                    {`STEP${
                      incomeTotalizer
                        ? SPTitle.STEP_SIX_INCOME_GUARANTORS.slice(0, 2)
                        : SPTitle.STEP_FOUR_GUARANTORS.slice(0, 2)
                    } を修正する`}
                  </Typography>
                </Stack>
              </SPButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
