import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPTool: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 23" {...props}>
      <svg
        width="25"
        height="23"
        viewBox="0 0 25 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8106_26187)">
          <path
            d="M24.4994 13.6562V14.7437C24.4994 15.0453 24.2589 15.2569 23.9594 15.2569H19.7677L17.907 19.0019C17.7087 19.4053 17.2783 19.6391 16.8355 19.6007C16.3883 19.554 16.017 19.2398 15.8989 18.8021L13.8277 11.1855L11.5124 22.0435C11.4112 22.5261 10.9927 22.8829 10.4965 22.8999H10.4594C9.97848 22.8999 9.55221 22.5771 9.4216 22.1098L7.48604 15.2874H3.43941C3.13988 15.2874 2.89941 15.0453 2.89941 14.7743V13.6562C2.89941 13.3546 3.13988 13.143 3.43941 13.143H8.29941C8.78035 13.143 9.20661 13.466 9.33723 13.9332L10.3075 17.3554L12.6464 6.35872C12.7477 5.87037 13.1736 5.51353 13.6714 5.50062C14.1692 5.48362 14.6124 5.81922 14.743 6.30333L17.2405 15.5165L18.1348 13.7459C18.3164 13.3469 18.691 13.1124 19.0994 13.1124H23.9594C24.2598 13.1124 24.4994 13.3537 24.4994 13.6562Z"
            fill="url(#paint0_linear_8106_26187)"
          />
        </g>
        <g clipPath="url(#clip1_8106_26187)">
          <path
            d="M1.8 4.14998C2.51703 4.14998 3.1 3.54417 3.1 2.79998C3.1 2.0558 2.51703 1.4502 1.8 1.4502C1.08297 1.4502 0.5 2.05601 0.5 2.79998C0.5 3.54396 1.08297 4.14998 1.8 4.14998ZM7 6.84999C6.28297 6.84999 5.7 7.4558 5.7 8.19999C5.7 8.94417 6.28297 9.54998 7 9.54998C7.71703 9.54998 8.3 8.94417 8.3 8.19999C8.3 7.4558 7.71703 6.84999 7 6.84999Z"
            fill="#FFD02C"
          />
          <path
            opacity="0.4"
            d="M8.10908 1.64795C7.85518 1.38428 7.44385 1.38428 7.18994 1.64795L0.689941 8.39795C0.436035 8.66162 0.436035 9.08877 0.689941 9.35244C0.817504 9.48449 0.98366 9.54988 1.15002 9.54988C1.31638 9.54988 1.48274 9.48396 1.60949 9.35212L8.10949 2.60213C8.36299 2.33793 8.36299 1.91162 8.10908 1.64795Z"
            fill="url(#paint1_linear_8106_26187)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_8106_26187"
            x1="6.22565"
            y1="19.3215"
            x2="19.1886"
            y2="3.06704"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8106_26187"
            x1="1.70066"
            y1="7.8841"
            x2="7.88769"
            y2="1.86581"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
          <clipPath id="clip0_8106_26187">
            <rect
              width="21.6"
              height="17.4"
              fill="white"
              transform="translate(2.8999 5.5)"
            />
          </clipPath>
          <clipPath id="clip1_8106_26187">
            <rect
              width="7.8"
              height="10.8"
              fill="white"
              transform="translate(0.5 0.0996094)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
