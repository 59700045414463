import { MESSAGE_REGEX } from 'constant';
import { regex, yup } from 'libs';

export const validationSchema = yup.object({
  email: yup
    .string()
    .matches(regex.email, MESSAGE_REGEX.EMAIL_SP)
    .required()
    .label('登録メールアドレス'),
  password: yup
    .string()
    .required()
    .min(8, '有効なパスワードを入力してください。')
    .max(20, '有効なパスワードを入力してください。')
    .matches(regex.password, '有効なパスワードを入力してください。')
    .label('パスワード'),
});
