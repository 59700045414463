import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SortDown: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 10 9" {...props}>
      <svg
        width="10"
        height="9"
        viewBox="0 0 10 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z"
          fill="currentColor"
          stroke="#6671E1"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
