import {
  CoOwnerType,
  LandAdvanceType,
  LoanType,
  MESSAGE_REGEX,
} from 'constant';
import { dayjs, regex, yup } from 'libs';
import { Context } from 'pages/StepSynthesisInformation/validationSchema';

export const validationSchema = yup.object().shape(
  {
    p_application_header: yup.object({
      loan_apply_date: yup
        .string()
        .required(MESSAGE_REGEX.REQUIRED)
        .test('one-field-missing', MESSAGE_REGEX.REQUIRED, (value) => {
          if (!value) return false;
          const [year, month, day] = value.split('/');

          return !!year && !!month && !!day;
        })
        .test(
          'time-of-the-past',
          MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
          (value) => {
            if (!value) return false;
            const [year, month, day] = value.split('/');
            const loanApplyDate = dayjs(`${year}-${month}-${day}`);
            return loanApplyDate.isSameOrAfter(dayjs(), 'date');
          }
        ),
      loan_desired_borrowing_date: yup
        .string()
        .required('お客様に該当する数値を選択してください。')
        .test(
          'one-field-missing',
          'お客様に該当する数値を選択してください。',
          (value) => {
            if (!value) return false;
            const [year, month, day] = value.split('/');

            return !!year && !!month && !!day;
          }
        )
        .test(
          'time-of-the-past',
          MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
          (value) => {
            if (!value) return false;
            const [year, month, day] = value.split('/');
            const borrowingDate = dayjs(`${year}-${month}-${day}`);
            return borrowingDate.isSameOrAfter(dayjs(), 'date');
          }
        ),
      scheduled_date_moving: yup
        .string()
        .required('お客様に該当する数値を選択してください。')
        .test(
          'one-field-missing',
          'お客様に該当する数値を選択してください。',
          (value) => {
            if (!value) return false;
            const [year, month] = value.split('/');

            return !!year && !!month;
          }
        )
        .test(
          'time-of-the-past',
          MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
          (value) => {
            if (!value) return false;

            const [year, month] = value.split('/');
            return (
              +year > dayjs().year() ||
              (+month >= dayjs().month() + 1 && +year === dayjs().year())
            );
          }
        ),
      loan_type: yup
        .string()
        .required('お客様に該当するものを選択してください。'),
      loan_target: yup
        .string()
        .required('お客様に該当するものを選択してください。'),
      master_bank_ids: yup
        .array()
        .min(1, 'お客様に該当するものを選択してください。')
        .required(),
    }),
    main_p_applicant_person: yup.object().shape(
      {
        last_name_kanji: yup.string().trim().required().max(48),
        first_name_kanji: yup.string().trim().required().max(48),
        last_name_kana: yup.string().trim().required().max(48),
        first_name_kana: yup.string().trim().required().max(48),
        birthday: yup
          .string()
          .required('お客様に該当する数値を選択してください。'),
        sex: yup.string().required('お客様に該当するものを選択してください。'),
        mobile_phone_number: yup
          .string()
          .max(11)
          .matches(regex.mobilePhone, '正しくご入力ください。')
          .when('home_phone_number', {
            is: (homePhone: string) => !homePhone || homePhone.length === 0,
            then: yup.string().required(),
          }),
        home_phone_number: yup
          .string()
          .max(11)
          .matches(regex.fixedPhones, '正しくご入力ください。')
          .when('mobile_phone_number', {
            is: (phoneNumber: string) =>
              !phoneNumber || phoneNumber.length === 0,
            then: yup.string().required(),
          }),
        postal_code: yup
          .string()
          .min(7, '正しくご入力ください。')
          .required()
          .matches(regex.zipCode, '正しくご入力ください。')
          .noWhitespace(),
        prefecture_kanji: yup.string().required().max(20),
        prefecture_kana: yup.string().required().max(20),
        city_kanji: yup.string().required().max(20),
        city_kana: yup.string().required().max(20),
        district_kanji: yup.string().required().max(40),
        district_kana: yup.string().required().max(40),
        other_address_kanji: yup.string().required().max(99),
        other_address_kana: yup.string().required().max(138),
        owner_email: yup
          .string()
          .matches(regex.email, '正しくご入力ください。')
          .required()
          .label('メールアドレス'),
        confirmation_email: yup
          .string()
          .matches(regex.email, '正しくご入力ください。')
          .required()
          .when('owner_email', {
            is: (val: string) => !!val?.length,
            then: yup
              .string()
              .oneOf(
                [yup.ref('owner_email')],
                'メールアドレスが一致しません。'
              ),
          })
          .label('メールアドレス（確認用）'),
        p_borrowing_detail_attributes: yup.object({
          loan_term: yup
            .number()
            .required()
            .typeError('正しくご入力ください。')
            .integer('正しくご入力ください。')
            .min(1, '正しくご入力ください。')
            .max(35, '最大35年まで入力できます。')
            .test({
              message: 'ご返済完了日が80歳未満となるようにご計画願います。',
              test(this: Context, value) {
                const { from } = this;

                return from && !!value
                  ? dayjs(from[2].value.loan_desired_borrowing_date).diff(
                      from[1].value.birthday,
                      'month'
                    ) +
                      +value * 12 <
                      960
                  : !!value;
              },
            }),
          temporary_desired_loan_amount: yup
            .number()
            .required()
            .typeError('半角数字でご入力ください')
            .min(10)
            .max(99999999)
            .test(
              'divisible-by-10',
              '10万円単位でご入力ください',
              (borrow) => +(borrow || 0) % 10 === 0
            ),
          halfyear_bonus: yup
            .number()
            .required()
            .typeError(
              '資金調達合計と必要資金総額が一致しません。正しくご入力ください。'
            )
            .min(10)
            .max(99999999)
            .test(
              'divisible-by-10',
              '10万円単位でご入力ください',
              (borrow) => +(borrow || 0) % 10 === 0
            ),
          repayment_method: yup
            .string()
            .required('お客様に該当するものを選択してください。'),
        }),
        has_land_advance_plan: yup
          .string()
          .required('お客様に該当するものを選択してください。'),
        p_land_advance_plan_attributes: yup
          .object()
          .when('has_land_advance_plan', {
            is: (has_land_advance_plan: LandAdvanceType) =>
              has_land_advance_plan === LandAdvanceType.YES,
            then: yup
              .object()
              .shape({
                loan_desired_borrowing_date: yup
                  .string()
                  .required('お客様に該当する数値を選択してください。')
                  .test(
                    'time-of-the-past',
                    MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
                    (value) => {
                      if (!value) return false;
                      const [year, month, day] = value.split('/');
                      const borrowingDate = dayjs(`${year}-${month}-${day}`);
                      return borrowingDate.isAfter(dayjs(), 'date');
                    }
                  ),
                loan_term: yup.number().required(),
                desired_loan_amount: yup
                  .number()
                  .required()
                  .typeError('半角数字でご入力ください')
                  .min(10)
                  .max(99999999)
                  .test(
                    'divisible-by-10',
                    '10万円単位でご入力ください',
                    (borrow) => +(borrow || 0) % 10 === 0
                  ),
                halfyear_bonus: yup
                  .number()
                  .required()
                  .typeError('半角数字でご入力ください')
                  .min(0)
                  .max(99999999)
                  .test(
                    'divisible-by-10',
                    '10万円単位でご入力ください',
                    (borrow) => +(borrow || 0) % 10 === 0
                  ),
                repayment_method: yup
                  .string()
                  .required('お客様に該当するものを選択してください。'),
              })
              .required(),
            otherwise: yup.object().shape({
              loan_desired_borrowing_date: yup.string(),
              loan_term: yup.number(),
              desired_loan_amount: yup.number(),
              halfyear_bonus: yup.string(),
              repayment_method: yup.string(),
            }),
          }),
        has_join_guarantor: yup
          .string()
          .required('お客様に該当するものを選択してください。'),
        join_guarantor_count: yup.string().when('has_join_guarantor', {
          is: (has_join_guarantor: CoOwnerType) =>
            has_join_guarantor === CoOwnerType.CANBE,
          then: yup
            .string()
            .required('お客様に該当する数値を選択してください。'),
          otherwise: yup.string(),
        }),
        p_join_guarantors_attributes: yup.array().when('has_join_guarantor', {
          is: (value: CoOwnerType) => {
            return value === CoOwnerType.CANBE;
          },
          then: yup
            .array()
            .min(1)
            .of(
              yup.object().shape(
                {
                  last_name_kanji: yup.string().trim().required().max(48),
                  first_name_kanji: yup.string().trim().required().max(48),
                  last_name_kana: yup.string().trim().required().max(48),
                  first_name_kana: yup.string().trim().required().max(48),
                  sex: yup
                    .string()
                    .required('お客様に該当するものを選択してください。'),
                  guarantor_relationship_to_applicant: yup
                    .string()
                    .required('お客様に該当する数値を選択してください。'),
                  mobile_phone_number: yup
                    .string()
                    .max(11)
                    .matches(regex.mobilePhone, '正しくご入力ください。')
                    .when('home_phone_number', {
                      is: (homePhone: string) =>
                        !homePhone || homePhone.length === 0,
                      then: yup.string().required(),
                    }),
                  home_phone_number: yup
                    .string()
                    .max(11)
                    .matches(regex.fixedPhones, '正しくご入力ください。')
                    .when('mobile_phone_number', {
                      is: (phoneNumber: string) =>
                        !phoneNumber || phoneNumber.length === 0,
                      then: yup.string().required(),
                    }),
                  postal_code: yup
                    .string()
                    .min(7, '正しくご入力ください。')
                    .required()
                    .matches(regex.zipCode, '正しくご入力ください。')
                    .noWhitespace(),
                  prefecture_kanji: yup.string().required().max(20),
                  city_kanji: yup.string().required().max(20),
                  district_kanji: yup.string().required().max(40),
                  other_address_kanji: yup.string().required().max(99),
                  prefecture_kana: yup.string().required().max(20),
                  city_kana: yup.string().required().max(20),
                  district_kana: yup.string().required().max(30),
                  other_address_kana: yup.string().required().max(138),
                },
                [['mobile_phone_number', 'home_phone_number']]
              )
            ),
          otherwise: yup.array(),
        }),
      },
      [['mobile_phone_number', 'home_phone_number']]
    ),
    pair_p_applicant_person: yup
      .object()
      .when('p_application_header.loan_type', {
        is: (loan_type: LoanType) => loan_type === LoanType.TWO,
        then: yup.object().shape(
          {
            last_name_kanji: yup.string().trim().required().max(48),
            first_name_kanji: yup.string().trim().required().max(48),
            last_name_kana: yup.string().trim().required().max(48),
            first_name_kana: yup.string().trim().required().max(48),
            birthday: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            sex: yup
              .string()
              .required('お客様に該当するものを選択してください。'),
            relationship_to_applicant: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            mobile_phone_number: yup
              .string()
              .max(11)
              .matches(regex.mobilePhone, '正しくご入力ください。')
              .when('home_phone_number', {
                is: (homePhone: string) => !homePhone || homePhone.length === 0,
                then: yup.string().required(),
              }),
            home_phone_number: yup
              .string()
              .max(11)
              .matches(regex.fixedPhones, '正しくご入力ください。')
              .when('mobile_phone_number', {
                is: (phoneNumber: string) =>
                  !phoneNumber || phoneNumber.length === 0,
                then: yup.string().required(),
              }),
            postal_code: yup
              .string()
              .min(7, '正しくご入力ください。')
              .required()
              .matches(regex.zipCode, '正しくご入力ください。')
              .noWhitespace(),
            prefecture_kanji: yup.string().required().max(20),
            prefecture_kana: yup.string().required().max(20),
            city_kanji: yup.string().required().max(20),
            city_kana: yup.string().required().max(20),
            district_kanji: yup.string().required().max(40),

            district_kana: yup.string().required().max(40),
            other_address_kanji: yup.string().required().max(99),
            other_address_kana: yup.string().required().max(138),
            // .matches(
            //   regex.kanaHalfwidthHaveNumber,
            //   'カタカナでご入力ください。'
            // ),
            owner_email: yup
              .string()
              .matches(regex.email, '正しくご入力ください。')
              .required()
              .label('メールアドレス'),
            confirmation_email: yup
              .string()
              .matches(regex.email, '正しくご入力ください。')
              .required()
              .when('owner_email', {
                is: (val: string) => !!val?.length,
                then: yup
                  .string()
                  .oneOf(
                    [yup.ref('owner_email')],
                    'メールアドレスが一致しません。'
                  ),
              })
              .label('メールアドレス（確認用）'),

            p_borrowing_detail_attributes: yup.object({
              loan_term: yup
                .number()
                .integer('正しくご入力ください。')
                .typeError('借入期間は数値でなければなりません')
                .required()
                .min(1, '正しくご入力ください。')
                .max(35, '最大35年まで入力できます。')
                .test({
                  message: 'ご返済完了日が80歳未満となるようにご計画願います。',
                  test(this: Context, value) {
                    const { from } = this;

                    return from && !!value
                      ? dayjs(from[2].value.loan_desired_borrowing_date).diff(
                          from[1].value.birthday,
                          'month'
                        ) +
                          value * 12 <
                          960
                      : !!value;
                  },
                }),
              temporary_desired_loan_amount: yup
                .number()
                .required()
                .typeError('半角数字でご入力ください')
                .min(10)
                .max(99999999)
                .test(
                  'divisible-by-10',
                  '10万円単位でご入力ください',
                  (borrow) => +(borrow || 0) % 10 === 0
                ),
              halfyear_bonus: yup
                .number()
                .required()
                .typeError(
                  '資金調達合計と必要資金総額が一致しません。正しくご入力ください。'
                )
                .min(10)
                .max(99999999)
                .test(
                  'divisible-by-10',
                  '10万円単位でご入力ください',
                  (borrow) => +(borrow || 0) % 10 === 0
                ),
              repayment_method: yup
                .string()
                .required('お客様に該当するものを選択してください。'),
            }),
          },
          [['mobile_phone_number', 'home_phone_number']]
        ),
      })
      .when('p_application_header.loan_type', {
        is: (loan_type: LoanType) =>
          loan_type === LoanType.THREE || loan_type === LoanType.FOUR,
        then: yup.object().shape(
          {
            last_name_kanji: yup.string().trim().required().max(48),
            first_name_kanji: yup.string().trim().required().max(48),
            last_name_kana: yup.string().trim().required().max(48),
            first_name_kana: yup.string().trim().required().max(48),
            birthday: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            sex: yup
              .string()
              .required('お客様に該当するものを選択してください。'),
            relationship_to_applicant: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            mobile_phone_number: yup
              .string()
              .max(11)
              .matches(regex.mobilePhone, '正しくご入力ください。')
              .when('home_phone_number', {
                is: (homePhone: string) => !homePhone || homePhone.length === 0,
                then: yup.string().required(),
              }),
            home_phone_number: yup
              .string()
              .max(11)
              .matches(regex.fixedPhones, '正しくご入力ください。')
              .when('mobile_phone_number', {
                is: (phoneNumber: string) =>
                  !phoneNumber || phoneNumber.length === 0,
                then: yup.string().required(),
              }),
            postal_code: yup
              .string()
              .min(7, '正しくご入力ください。')
              .required()
              .matches(regex.zipCode, '正しくご入力ください。')
              .noWhitespace(),
            prefecture_kanji: yup.string().required().max(20),
            prefecture_kana: yup.string().required().max(20),
            city_kanji: yup.string().required().max(20),
            city_kana: yup.string().required().max(20),
            district_kanji: yup.string().required().max(40),
            district_kana: yup.string().required().max(40),
            other_address_kanji: yup.string().required().max(99),
            other_address_kana: yup.string().required().max(138),

            owner_email: yup
              .string()
              .matches(regex.email, '正しくご入力ください。')
              .required()
              .label('メールアドレス'),
            confirmation_email: yup
              .string()
              .matches(regex.email, '正しくご入力ください。')
              .required()
              .when('owner_email', {
                is: (val: string) => !!val?.length,
                then: yup
                  .string()
                  .oneOf(
                    [yup.ref('owner_email')],
                    'メールアドレスが一致しません。'
                  ),
              })
              .label('メールアドレス（確認用）'),
          },
          [['mobile_phone_number', 'home_phone_number']]
        ),
        otherwise: yup.object(),
      }),
    confirm_self_entered: yup
      .boolean()
      .oneOf([true], 'お客様に該当するものを選択してください。')
      .required(),
  },
  []
);
