import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPCircle: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 26V20.5H14C13.725 20.5 13.5 20.275 13.5 20C13.5 19.725 13.725 19.5 14 19.5H19.5V14C19.5 13.725 19.725 13.5 20 13.5C20.275 13.5 20.5 13.725 20.5 14V19.5H26C26.275 19.5 26.5 19.725 26.5 20C26.5 20.275 26.275 20.5 26 20.5H20.5V26C20.5 26.275 20.275 26.5 20 26.5C19.725 26.5 19.5 26.275 19.5 26ZM36 20C36 28.8375 28.8375 36 20 36C11.1625 36 4 28.8375 4 20C4 11.1625 11.1625 4 20 4C28.8375 4 36 11.1625 36 20ZM20 5C11.7188 5 5 11.7188 5 20C5 28.2812 11.7188 35 20 35C28.2812 35 35 28.2812 35 20C35 11.7188 28.2812 5 20 5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
