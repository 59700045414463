import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const AcceptIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 20,
  height: 20,
  border: `1px solid ${theme?.palette?.sp_primary_40}`,
  boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
}));

const AcceptCheckedIcon = styled(AcceptIcon)({
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  backgroundColor: '#6B70F0',
});

export const PostalCodeCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      checkedIcon={<AcceptCheckedIcon />}
      icon={<AcceptIcon />}
      {...props}
    />
  );
};
