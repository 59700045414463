import {
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { SPStepLayout } from 'containers';
import { Stack, Typography } from '@mui/material';
import { FormikProvider, getIn, useFormik } from 'formik';
import {
  InputSelectProps,
  SPCheckboxMultipleButton,
  SPFormItem,
  SPImage,
  SPInputArea,
  SPInputDate,
  SPInputDateTime,
  SPInputField,
  SPInputPhoneNumber,
  SPInputSelect,
  SPInputZipCode,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPSaveImageModal,
  SPUploadImageItem,
} from 'components';
import {
  checkingExistFile,
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  convertImage,
  convertToFullWidth,
  convertToHalfWidth,
  flattenToLodashFormat,
  kanaMap1,
  numberWithCommas,
  supportDvh,
  zeroPad,
} from 'utils';
import { useSelector } from 'react-redux';
import { routeNames } from 'navigations/routes';
import { ImageContext } from 'context';
import kanjidate from 'kanjidate';
import {
  BorrowerIncomeType,
  buyingandSellingScheduleType,
  BuyingandSellingScheduleType,
  ChildType,
  currentHomeLoan,
  CurrentHomeLoanType,
  CurrentResidenceType,
  financeAgency,
  FinanceAgencyType,
  Flat35ApplicablePlanType,
  Flat35ApplicationType,
  GeneralIncomeConfirmation,
  LandOwnershipType,
  MaintenanceType,
  NationalityType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  personOccupancy,
  PersonOccupancyType,
  PlannedCohabitantType,
  PlanningAreaType,
  PREFECTURES,
  PurchasePurposeType,
  PurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
  RegionType,
} from 'constant';
import { dayjs } from 'libs/dayjs';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { spCurrentStepSelector } from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { get, update } from 'lodash';
import { PostalCode } from 'services';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { isMobile } from 'react-device-detect';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { dynamicOptionsSelectors } from '../SPStartScreen/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  checkMCJ,
  spStepOneSelector,
} from '../SPStepOne/selectors';
import { spStepTwoSelector } from '../SPStepTwo/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import { spStepSixSelector } from '../SPStepSix/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepFiveSelector } from '../SPStepFive/selectors';
import { getZipCloudAddress } from '../StepRequiredInformation/thunk';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepEightSelector } from '../SPStepEight/selectors';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { spStepFourSelector } from './selectors';
import { PostalCodeCheckbox } from './components/PostalCodeCheckbox';
import { validationSchema } from './validationSchema';
import {
  ResidentsAttributeType,
  saveForm,
  saveResident,
  saveSameAddress,
} from './slice';

type RadioItems = {
  value: string;
  label: string;
};

type SelectProcurementType = {
  children_number: string;
  siblings_number: string;
  other_people_number: string;
  planned_cohabitant: PlannedCohabitantType[];
};

const SPStepFourPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialValues = useSelector(spStepFourSelector);
  const currentStep = useSelector(spCurrentStepSelector);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const spStepOneValues = useSelector(spStepOneSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const [showErrZipCode, setShowErrZipCode] = useState<boolean>(false);
  const isMCJ = useSelector(checkMCJ);
  const [checked, setChecked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isFocusArea, setIsFocusArea] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const { application_number } = useSelector(spStepTenSelector);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);

  const { updateFormOverride } = useUpdateForm();
  useCheckPreliminaryStatus();
  useGoBackTop();

  const {
    businessCard,
    residenceFile,
    residenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    propertyInformationFile,
    setPropertyInformationFile,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    repaymentScheduleImage,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);

  const {
    p_application_header,
    p_residents_attributes,
    same_address_as_applicant,
    ...spStepFourInitialValues
  } = initialValues;

  const {
    collateral_land_area,
    collateral_total_floor_area,
    occupied_area,
    p_applicant_people_attributes,
    ...pApplicationHeader
  } = p_application_header;

  const { current_residence_floor_area, ...pApplicantPeopleAttributes } =
    p_applicant_people_attributes;

  const formik = useFormik({
    initialValues: {
      p_application_header: {
        collateral_land_area: collateral_land_area
          ? numberWithCommas(collateral_land_area)
          : '',
        collateral_total_floor_area: collateral_total_floor_area
          ? numberWithCommas(collateral_total_floor_area)
          : '',
        occupied_area: occupied_area ? numberWithCommas(occupied_area) : '',
        p_applicant_people_attributes: {
          current_residence_floor_area: current_residence_floor_area
            ? numberWithCommas(current_residence_floor_area)
            : '',
          ...pApplicantPeopleAttributes,
        },
        ...pApplicationHeader,
      },
      ...spStepFourInitialValues,
      p_residents_for_submit: [...(initialValues.p_residents_attributes || [])],
    },
    enableReinitialize: true,
    validationSchema: validationSchema(
      spStepOneValues.p_application_header.loan_target === PurposeType.THREE ||
        spStepOneValues.p_application_header.loan_target === PurposeType.FOUR
    ),
    async onSubmit(values, { setSubmitting }) {
      const {
        collateral_land_area,
        collateral_total_floor_area,
        occupied_area,
        ...formApplicationHeader
      } = values.p_application_header;
      const { current_residence_floor_area, ...formApplicantPeopleAttributes } =
        values.p_application_header.p_applicant_people_attributes;
      const submitForm = {
        p_application_header: {
          ...formApplicationHeader,
          collateral_land_area: updateSecondCode(
            collateral_land_area.replaceAll(',', '')
          ),
          collateral_total_floor_area: updateSecondCode(
            collateral_total_floor_area.replaceAll(',', '')
          ),
          occupied_area: updateSecondCode(occupied_area.replaceAll(',', '')),
          p_applicant_people_attributes: [
            {
              ...formApplicantPeopleAttributes,
              current_residence_floor_area:
                current_residence_floor_area.replaceAll(',', ''),
            },
          ],
          p_residents_attributes:
            values.p_application_header.housemate_number.length === 0 ||
            values.p_application_header.person_occupancy !==
              PersonOccupancyType.NO ||
            !isMCJ
              ? []
              : [values.p_application_header.p_residents_attributes],
        },
      };
      await dispatch(saveForm(submitForm));
      await dispatch(
        saveResident(handleCheckResident(values.p_residents_for_submit))
      );
      await dispatch(saveSameAddress(checked));
      if (incomeTotalizer && +currentStep < 7) {
        dispatch(setCurrentStep('7'));
      }
      if (incomeTotalizer && hasJoinGuarantor && +currentStep < 8) {
        dispatch(setCurrentStep('8'));
      }
      if (hasJoinGuarantor && +currentStep < 6) {
        dispatch(setCurrentStep('6'));
      }
      if (+currentStep < 5) dispatch(setCurrentStep('5'));
      setSubmitting(false);

      // const collateralTotalFloorAreaStr0 =
      //   formik.values.p_application_header.collateral_total_floor_area;
      // const collateralTotalFloorAreaStr =
      //   collateralTotalFloorAreaStr0 === '.0' ||
      //   collateralTotalFloorAreaStr0 === '.00'
      //     ? ''
      //     : collateralTotalFloorAreaStr0;
      // const collateralTotalFloorArea = collateralTotalFloorAreaStr
      //   ? parseFloat(collateralTotalFloorAreaStr.replace(/[^0-9.-]+/g, ''))
      //   : 0;
      // const collateralLandAreaStr0 =
      //   formik.values.p_application_header.collateral_land_area;
      // const collateralLandAreaStr =
      //   collateralLandAreaStr0 === '.0' || collateralLandAreaStr0 === '.00'
      //     ? ''
      //     : collateralLandAreaStr0;
      // const collateralLandArea = collateralLandAreaStr
      //   ? parseFloat(collateralLandAreaStr.replace(/[^0-9.-]+/g, ''))
      //   : 0;
      // const occupiedAreaStr0 = formik.values.p_application_header.occupied_area;
      // const occupiedAreaStr =
      //   occupiedAreaStr0 === '.0' || occupiedAreaStr0 === '.00'
      //     ? ''
      //     : occupiedAreaStr0;
      // const occupiedArea = occupiedAreaStr
      //   ? parseFloat(occupiedAreaStr.replace(/[^0-9.-]+/g, ''))
      //   : 0;

      // const isAllArealEmpty =
      //   (!collateralLandAreaStr &&
      //     !collateralTotalFloorAreaStr &&
      //     !occupiedAreaStr) ||
      //   (isAreaFormatted(collateralTotalFloorAreaStr) &&
      //     !occupiedAreaStr &&
      //     !collateralLandAreaStr) ||
      //   (isAreaFormatted(collateralLandAreaStr) &&
      //     !collateralTotalFloorAreaStr) ||
      //   (isAreaFormatted(occupiedAreaStr) && !collateralTotalFloorAreaStr);

      // if (
      //   isAllArealEmpty ||
      //   (collateralTotalFloorAreaStr &&
      //     collateralLandAreaStr &&
      //     isAreaFormatted(collateralTotalFloorAreaStr) &&
      //     isAreaFormatted(collateralLandAreaStr) &&
      //     collateralTotalFloorArea !== 0 &&
      //     collateralLandArea !== 0) ||
      //   (collateralTotalFloorAreaStr &&
      //     occupiedAreaStr &&
      //     isAreaFormatted(collateralTotalFloorAreaStr) &&
      //     isAreaFormatted(occupiedAreaStr) &&
      //     collateralTotalFloorArea !== 0 &&
      //     occupiedArea !== 0 &&
      //     collateralTotalFloorArea <= occupiedArea)
      // ) {
      //   navigate(routeNames.SPStepFive.path);
      // }
      if (!!application_number) {
        setIsDisabledButton(true);
        updateFormOverride(
          {
            stepFourData: submitForm,
            imageContext: imageContext,
          },
          setIsDisabledButton,
          setOpenImageModal,
          setReset
        );
      } else {
        navigate(routeNames.SPStepFive.path);
      }
    },
  });

  const btnSubmit = useMemo(() => {
    return !!application_number ? '保存' : '次へ';
  }, [application_number]);

  const handleFillDataResident = (
    residents: ResidentsAttributeType[],
    planned_cohabitant: PlannedCohabitantType,
    number?: string
  ) => {
    const newResident = {
      last_name_kanji: '',
      first_name_kanji: '',
      last_name_kana: '',
      first_name_kana: '',
      relationship_name: '',
      relationship: planned_cohabitant.toString(),
      nationality: NationalityType.DEFAULT,
      birthday: '',
      loan_from_japan_housing_finance_agency: FinanceAgencyType.DEFAULT,
      postal_code: '',
      prefecture_kanji: '',
      city_kanji: '',
      district_kanji: '',
      prefecture_kana: '',
      city_kana: '',
      district_kana: '',
      other_address_kanji: '',
      home_phone_number: '',
    };
    const selectedResidents = residents.filter((item) => !item._destroy);
    const pResidentAttributes: ResidentsAttributeType[] = residents.filter(
      (item) => item._destroy
    );
    if (!!number) {
      // planned_cohabitant === CHILD || BROTHERS_AND_SISTERS || OTHERS
      if (+number >= selectedResidents.length) {
        selectedResidents.forEach((item) => pResidentAttributes.push(item));
        for (let i = 0; i < +number - selectedResidents.length; i++) {
          pResidentAttributes.push(newResident);
        }
      } else {
        // Delete resident
        selectedResidents.forEach((item, index) => {
          if (index + 1 <= +number) {
            pResidentAttributes.push(item);
          } else {
            if (!!item.id) {
              pResidentAttributes.push({ ...item, _destroy: true });
            }
          }
        });
      }
    } else {
      // planned_cohabitant !== CHILD || BROTHERS_AND_SISTERS || OTHERS
      if (!selectedResidents.length) {
        // Add new resident
        pResidentAttributes.push(newResident);
      } else {
        pResidentAttributes.push({ ...selectedResidents[0] });
      }
    }
    return pResidentAttributes;
  };

  const handleCheckResident = (residents: ResidentsAttributeType[]) => {
    const pResidents: ResidentsAttributeType[] = [];

    // Uncheck all option planned_cohabitant
    if (formik.values.p_application_header.planned_cohabitant.length === 0) {
      residents.forEach((item) => {
        if (!!item.id) {
          pResidents.push({ ...item, _destroy: true });
        }
      });
    }

    // Uncheck option planned_cohabitant
    residents.forEach((item) => {
      if (
        !formik.values.p_application_header.planned_cohabitant.find(
          (cohabit) => item.relationship === cohabit
        ) &&
        !!item.id &&
        formik.values.p_application_header.planned_cohabitant.length !== 0
      ) {
        pResidents.push({ ...item, _destroy: true });
      }
    });

    // Add new + change select option
    formik.values.p_application_header.planned_cohabitant.forEach(
      (item: PlannedCohabitantType) => {
        switch (item) {
          // Case OTHER
          case PlannedCohabitantType.OTHERS:
            const otherResidents: ResidentsAttributeType[] =
              handleFillDataResident(
                residents.filter(
                  (item) => item.relationship === PlannedCohabitantType.OTHERS
                ),
                PlannedCohabitantType.OTHERS,
                other_people_number
              );
            otherResidents.forEach((res: ResidentsAttributeType) =>
              pResidents.push(res)
            );
            break;
          // Case CHILD
          case PlannedCohabitantType.CHILD:
            const childResidents: ResidentsAttributeType[] =
              handleFillDataResident(
                residents.filter(
                  (item) => item.relationship === PlannedCohabitantType.CHILD
                ),
                PlannedCohabitantType.CHILD,
                children_number
              );
            childResidents.forEach((res: ResidentsAttributeType) =>
              pResidents.push(res)
            );
            // Case BROTHERS_AND_SISTERS
            break;
          case PlannedCohabitantType.BROTHERS_AND_SISTERS:
            const brotherSisResidents: ResidentsAttributeType[] =
              handleFillDataResident(
                residents.filter(
                  (item) =>
                    item.relationship ===
                    PlannedCohabitantType.BROTHERS_AND_SISTERS
                ),
                PlannedCohabitantType.BROTHERS_AND_SISTERS,
                siblings_number
              );
            brotherSisResidents.forEach((res: ResidentsAttributeType) =>
              pResidents.push(res)
            );
            break;
          // Case FIANCE
          case PlannedCohabitantType.FIANCE:
            const fianceResidents: ResidentsAttributeType[] =
              handleFillDataResident(
                residents.filter(
                  (item) => item.relationship === PlannedCohabitantType.FIANCE
                ),
                PlannedCohabitantType.FIANCE
              );
            fianceResidents.forEach((res: ResidentsAttributeType) =>
              pResidents.push(res)
            );
            break;
          // Case FATHER
          case PlannedCohabitantType.FATHER:
            const fatherResidents: ResidentsAttributeType[] =
              handleFillDataResident(
                residents.filter(
                  (item) => item.relationship === PlannedCohabitantType.FATHER
                ),
                PlannedCohabitantType.FATHER
              );
            fatherResidents.forEach((res: ResidentsAttributeType) =>
              pResidents.push(res)
            );
            break;
          // Case MOTHER
          case PlannedCohabitantType.MOTHER:
            const motherResidents: ResidentsAttributeType[] =
              handleFillDataResident(
                residents.filter(
                  (item) => item.relationship === PlannedCohabitantType.MOTHER
                ),
                PlannedCohabitantType.MOTHER
              );
            motherResidents.forEach((res: ResidentsAttributeType) =>
              pResidents.push(res)
            );
            break;
          // Case SPOUSE
          case PlannedCohabitantType.SPOUSE:
            const spouseResidents: ResidentsAttributeType[] =
              handleFillDataResident(
                residents.filter(
                  (item) => item.relationship === PlannedCohabitantType.SPOUSE
                ),
                PlannedCohabitantType.SPOUSE
              );
            spouseResidents.forEach((res: ResidentsAttributeType) =>
              pResidents.push(res)
            );
            break;
          default:
            break;
        }
      }
    );
    const returnPResidents = pResidents.filter((item) => !!item._destroy);
    pResidents
      .filter((item) => !item._destroy)
      .forEach((item, index) => {
        if (index < 6) {
          returnPResidents.push(item);
        }
      });
    return returnPResidents;
  };

  const isAreaFormatted = (area: string) => {
    const [firstCode, secondCode] = area ? area.split('.') : ['', ''];
    return (
      (!!firstCode && firstCode !== '0') ||
      (firstCode === '0' &&
        !!secondCode &&
        secondCode !== '0' &&
        secondCode !== '00') ||
      !area
    );
  };

  const updateSecondCode = (area: string) => {
    if (!area) return '';
    const [firstCode, secondCode] = area ? area.split('.') : ['', ''];
    if (!firstCode && (secondCode === '0' || secondCode === '00')) return '';
    return !secondCode ? `${firstCode}.00` : area;
  };

  const checkErrArea = useCallback(() => {
    const collateralTotalFloorAreaStr =
      formik.values.p_application_header.collateral_total_floor_area;
    const collateralLandAreaStr =
      formik.values.p_application_header.collateral_land_area;
    const occupiedAreaStr = formik.values.p_application_header.occupied_area;

    const collateralTotalFloorArea = collateralTotalFloorAreaStr
      ? parseFloat(collateralTotalFloorAreaStr.replace(/[^0-9.-]+/g, ''))
      : 0;
    const collateralLandArea = collateralLandAreaStr
      ? parseFloat(collateralLandAreaStr.replace(/[^0-9.-]+/g, ''))
      : 0;
    const occupiedArea = occupiedAreaStr
      ? parseFloat(occupiedAreaStr.replace(/[^0-9.-]+/g, ''))
      : 0;
    if (collateralLandArea === 0 && occupiedArea === 0) return false;

    const result =
      (collateralTotalFloorAreaStr &&
        collateralLandAreaStr &&
        isAreaFormatted(collateralTotalFloorAreaStr) &&
        isAreaFormatted(collateralLandAreaStr) &&
        collateralTotalFloorArea > collateralLandArea) ||
      (collateralTotalFloorAreaStr &&
        occupiedAreaStr &&
        isAreaFormatted(collateralTotalFloorAreaStr) &&
        isAreaFormatted(occupiedAreaStr) &&
        collateralTotalFloorArea > occupiedArea);
    return result;
  }, [
    formik.values.p_application_header.collateral_land_area,
    formik.values.p_application_header.collateral_total_floor_area,
    formik.values.p_application_header.occupied_area,
  ]);

  useEffect(() => {
    if (
      formik.values.p_application_header.housemate_number.length === 0 ||
      formik.values.p_application_header.person_occupancy !==
        PersonOccupancyType.NO ||
      !isMCJ
    ) {
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.last_name_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.first_name_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.last_name_kana',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.first_name_kana',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.relationship_name',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.nationality',
        NationalityType.DEFAULT
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.birthday',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.loan_from_japan_housing_finance_agency',
        FinanceAgencyType.DEFAULT
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.postal_code',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.prefecture_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.city_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.district_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.other_address_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.home_phone_number',
        ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMCJ,
    formik.values.p_application_header.person_occupancy,
    formik.setFieldValue,
    formik.values.p_application_header.housemate_number,
  ]);

  useEffect(() => {
    if (!isMCJ) {
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.current_residence_floor_area',
        ''
      );
      formik.setFieldValue(
        'p_application_header.land_ownership',
        LandOwnershipType.DEFAULT
      );
      formik.setFieldValue(
        'p_application_header.purchase_purpose',
        PurchasePurposeType.DEFAULT
      );
      formik.setFieldValue(
        'p_application_header.planning_area',
        PlanningAreaType.DEFAULT
      );
      formik.setFieldValue(
        'p_application_header.flat_35_applicable_plan',
        Flat35ApplicablePlanType.DEFAULT
      );
      formik.setFieldValue(
        'p_application_header.maintenance_type',
        MaintenanceType.DEFAULT
      );
      formik.setFieldValue(
        'p_application_header.region_type',
        RegionType.DEFAULT
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMCJ, formik.setFieldValue]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          let selector = `[name="${errorKey}"]`;
          if (errorKey.includes('business_ability')) {
            selector = `[name="p_application_header.business_ability"]`;
          }
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });
        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  useEffect(() => {
    if (isMCJ) {
      setChecked(same_address_as_applicant);
    }
  }, [same_address_as_applicant, isMCJ]);

  useEffect(() => {
    if (checked) {
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.postal_code',
        spStepTwoValues.p_applicant_people.postal_code
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.prefecture_kanji',
        spStepTwoValues.p_applicant_people.prefecture_kanji
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.city_kanji',
        spStepTwoValues.p_applicant_people.city_kanji
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.district_kanji',
        spStepTwoValues.p_applicant_people.district_kanji
      );
    } else {
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.postal_code',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.prefecture_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.city_kanji',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_residents_attributes.district_kanji',
        ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, spStepTwoValues.p_applicant_people]);

  // Reset field values when selecting loan_target !== PurposeType.THREE and loan_target !== PurposeType.FOUR
  useEffect(() => {
    if (
      spStepOneValues.p_application_header.loan_target !== PurposeType.THREE &&
      spStepOneValues.p_application_header.loan_target !== PurposeType.FOUR
    ) {
      formik.setFieldValue('p_application_header.condominium_name', '');
      formik.setFieldValue('p_application_header.occupied_area', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spStepOneValues.p_application_header.loan_target]);

  // Reset field values when selecting loan_target === PurposeType.THREE or loan_target === PurposeType.FOUR
  useEffect(() => {
    if (
      spStepOneValues.p_application_header.loan_target === PurposeType.THREE ||
      spStepOneValues.p_application_header.loan_target === PurposeType.FOUR
    ) {
      formik.setFieldValue('p_application_header.collateral_land_area', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spStepOneValues.p_application_header.loan_target]);

  const {
    children_number,
    siblings_number,
    other_people_number,
    planned_cohabitant,
  } = formik.values.p_application_header;

  // Sum the fields in planned_cohabitant
  useEffect(() => {
    const singleCohabitant = planned_cohabitant.filter(
      (cohabitant) =>
        cohabitant !== PlannedCohabitantType.CHILD &&
        cohabitant !== PlannedCohabitantType.BROTHERS_AND_SISTERS &&
        cohabitant !== PlannedCohabitantType.OTHERS
    ).length;

    let housemate_number =
      singleCohabitant +
      +calculateTotal({
        children_number,
        siblings_number,
        other_people_number,
        planned_cohabitant,
      });

    formik.setFieldValue(
      'p_application_header.housemate_number',
      !housemate_number ? '' : housemate_number
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    children_number,
    siblings_number,
    other_people_number,
    formik.setFieldValue,
    planned_cohabitant,
  ]);

  useEffect(() => {
    if (
      formik.values.p_application_header.flat_35_applicable_plan ===
        Flat35ApplicablePlanType.PLAN_A &&
      formik.values.p_application_header.maintenance_type ===
        MaintenanceType.LONGTERM_SUPERIOR_HOUSING
    ) {
      formik.setFieldValue(
        'p_application_header.flat_35_application',
        Flat35ApplicationType.DURABILITY
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.p_application_header.flat_35_applicable_plan,
    formik.values.p_application_header.maintenance_type,
  ]);

  const setHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      postal_code: postal.zipcode.slice(0, 3) + '-' + postal.zipcode.slice(3),
      prefecture_kanji: postal.address1,
      district_kanji: postal.address3,
      city_kanji: postal.address2,
      prefecture_kana: convertToFullWidth(postal.kana1),
      district_kana: convertToFullWidth(postal.kana3),
      city_kana: convertToFullWidth(postal.kana2),
      other_address_kanji: '',
    });
  };

  const handleZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        setShowErrZipCode(true);
        await formik.setFieldValue(
          'p_application_header.p_residents_attributes.prefecture_kanji',
          ''
        );
        await formik.setFieldValue(
          'p_application_header.p_residents_attributes.city_kanji',
          ''
        );
        await formik.setFieldValue(
          'p_application_header.p_residents_attributes.district_kanji',
          ''
        );
        await formik.setFieldValue(
          'p_application_header.p_residents_attributes.prefecture_kana',
          ''
        );
        await formik.setFieldValue(
          'p_application_header.p_residents_attributes.city_kana',
          ''
        );
        await formik.setFieldValue(
          'p_application_header.p_residents_attributes.district_kana',
          ''
        );
        return;
      }
      await setHomeAddress(
        result.payload.results[0],
        'p_application_header.p_residents_attributes'
      );
    }
  };

  const age = useMemo(() => {
    const date = new Date(spStepTwoValues?.p_applicant_people.birthday);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (dayjs().month() + 1 < month) {
      return dayjs().year() - year - 1;
    } else {
      return dayjs().year() - year;
    }
  }, [spStepTwoValues.p_applicant_people.birthday]);

  const yearNum =
    formik.values.p_application_header.p_applicant_people_attributes
      .lived_length_year_num;

  const birthdayMonth = useMemo(() => {
    const date = new Date(spStepTwoValues?.p_applicant_people.birthday);
    const month = date.getMonth() + 1;
    if (+yearNum === age && dayjs().month() + 1 >= month) {
      return Math.abs(dayjs().month() + 1 - +month) + 1;
    }
    if (+yearNum === age && dayjs().month() + 1 < month) {
      return Math.abs(12 - +month + dayjs().month() + 1) + 1;
    }
    return 12;
  }, [age, spStepTwoValues?.p_applicant_people.birthday, yearNum]);

  const yearNumOptions: InputSelectProps['options'] = useMemo(
    () =>
      [{ value: '', label: '年' }].concat(
        Array.from(Array(age), (_, index) => {
          return {
            value: zeroPad(index + 1),
            label: (index + 1).toString(),
          };
        })
      ),
    [age]
  );

  const monthOptions: InputSelectProps['options'] = useMemo(
    () =>
      [{ value: '', label: '月' }].concat(
        Array.from(Array(birthdayMonth), (_, index) => ({
          value: zeroPad(index),
          label: index.toString(),
        }))
      ),
    [birthdayMonth]
  );

  const currentResidenceOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.current_residence),
    [dynamicOptions.current_residence]
  );

  const reasonAcquireHomeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.reason_acquire_home),
    [dynamicOptions.reason_acquire_home]
  );

  const plannedCohabitantOptions = useMemo(
    () =>
      convertDynamicMultipleOptions(dynamicOptions.planned_cohabitant).map(
        (planned_cohabitant, index) => {
          if (index === 1 || index === 4 || index === 6)
            return {
              ...planned_cohabitant,
              hasSelect: true,
            };
          return planned_cohabitant;
        }
      ),
    [dynamicOptions.planned_cohabitant]
  );

  const businessAbilityOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.business_ability),
    [dynamicOptions.business_ability]
  );

  const nationalityOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nationality),
    [dynamicOptions.nationality]
  );

  const landOwnershipOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.land_ownership),
    [dynamicOptions.land_ownership]
  );

  const purchasePurposeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.purchase_purpose),
    [dynamicOptions.purchase_purpose]
  );

  const planningAreaOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.planning_area),
    [dynamicOptions.planning_area]
  );

  const rebuildingReasonOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.rebuilding_reason),
    [dynamicOptions.rebuilding_reason]
  );

  const flat35ApplicablePlanOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.flat_35_applicable_plan),
    [dynamicOptions.flat_35_applicable_plan]
  );

  const maintenanceOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.maintenance_type),
    [dynamicOptions.maintenance_type]
  );

  const regionOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.region_type),
    [dynamicOptions.region_type]
  );

  const flat35ApplicationOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.flat_35_application),
    [dynamicOptions.flat_35_application]
  );

  useEffect(() => {
    if (
      formik.values.p_application_header.planning_area ===
      PlanningAreaType.OTHERS
    ) {
      formik.setFieldValue(
        'p_application_header.rebuilding_reason',
        RebuildingReasonType.DEFAULT
      );
    }
  }, []);

  // Reset field values when selecting buyingand_selling_schedule_type !== OTHERS
  const handleChangeBuyingandSellingScheduleType = (value: unknown) => {
    if (value !== BuyingandSellingScheduleType.OTHERS) {
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.other_buyingand_selling_schedule_type',
        ''
      );
    }
    if (value !== BuyingandSellingScheduleType.RENT) {
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.scheduled_time_sell_house',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.expected_house_selling_price',
        BuyingandSellingScheduleType.DEFAULT
      );
    }
  };

  // Reset field values when selecting current_residence !== '4' or !== '5'
  const handleChangeCurrentResidence = (value: unknown) => {
    if (
      value !==
      CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS
    ) {
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.owner_full_name',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.owner_relationship',
        ''
      );
    }
    if (value !== CurrentResidenceType.PRIVATE_APARTMENT) {
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.buyingand_selling_schedule_type',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.other_buyingand_selling_schedule_type',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.scheduled_time_sell_house',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.expected_house_selling_price',
        ''
      );
      formik.setFieldValue(
        'p_application_header.p_applicant_people_attributes.current_home_loan',
        CurrentHomeLoanType.DEFAULT
      );
    }
  };

  // Reset field values when planning_cohabitant is selected without others or CHILD or BROTHERS_AND_SISTERS
  useEffect(() => {
    if (
      !formik.values.p_application_header.planned_cohabitant.includes(
        PlannedCohabitantType.OTHERS
      )
    ) {
      formik.setFieldValue('p_application_header.other_relationship', '');
      formik.setFieldValue(
        'p_application_header.other_people_number',
        ChildType.DEFAULT
      );
    }
    if (
      !formik.values.p_application_header.planned_cohabitant.includes(
        PlannedCohabitantType.CHILD
      )
    ) {
      formik.setFieldValue(
        'p_application_header.children_number',
        ChildType.DEFAULT
      );
    }
    if (
      !formik.values.p_application_header.planned_cohabitant.includes(
        PlannedCohabitantType.BROTHERS_AND_SISTERS
      )
    ) {
      formik.setFieldValue(
        'p_application_header.siblings_number',
        ChildType.DEFAULT
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.planned_cohabitant]);

  const handleChangePlanningArea = (value: unknown) => {
    if (value !== PlanningAreaType.OTHERS) {
      formik.setFieldValue('p_application_header.other_planning_area', '');
    }
  };

  const handleChangeRebuildingReason = (value: unknown) => {
    if (value !== RebuildingReasonType.OTHERS) {
      formik.setFieldValue('p_application_header.other_rebuilding_reason', '');
    }
  };

  useEffect(() => {
    let newPropertyInformationFile: (SPImage | File)[] = [];
    if (
      !!propertyInformationFile &&
      propertyInformationFile.length === 0 &&
      !!p_application_header.property_information_file &&
      !!p_application_header.property_information_file?.length
    ) {
      p_application_header.property_information_file.forEach((file) => {
        if (!file.isDelete) {
          return newPropertyInformationFile.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!propertyInformationFile && !!propertyInformationFile.length) {
      newPropertyInformationFile = [...propertyInformationFile];
    } else {
      p_application_header.property_information_file.forEach((file) => {
        if (!file.isDelete) {
          return newPropertyInformationFile.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setPropertyInformationFile(newPropertyInformationFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);
    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });

    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });

    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/

    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      collateral_land_area,
      collateral_total_floor_area,
      occupied_area,
      ...restApplicationHeader
    } = formik.values.p_application_header;

    const { current_residence_floor_area, ...pApplicantPeopleAttributes } =
      formik.values.p_application_header.p_applicant_people_attributes;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    data.append(
      `draft[p_application_header][collateral_land_area]`,
      collateral_land_area.replaceAll(',', '')
    );
    data.append(
      `draft[p_application_header][collateral_total_floor_area]`,
      collateral_total_floor_area.replaceAll(',', '')
    );
    data.append(
      `draft[p_application_header][occupied_area]`,
      occupied_area.replaceAll(',', '')
    );

    Object.keys(pApplicantPeopleAttributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(pApplicantPeopleAttributes, key) ?? ''
      );
    });
    data.append(
      `draft[p_applicant_people][0][current_residence_floor_area]`,
      current_residence_floor_area.replaceAll(',', '')
    );

    data.append(
      `draft[p_residents]`,
      JSON.stringify([
        formik.values.p_application_header.p_residents_attributes,
      ])
    );

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    // /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    if (spSaveDraft.rejected.match(result)) {
      setReset(reset + 1);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement;
    let transformedValue = value;
    Object.entries(kanaMap1).forEach(([key, replacement]) => {
      transformedValue = transformedValue.replaceAll(key, replacement);
    });
    formik.setFieldValue(name, transformedValue);
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={async () => {
          const {
            collateral_land_area,
            collateral_total_floor_area,
            occupied_area,
            ...formApplicationHeader
          } = formik.values.p_application_header;
          const {
            current_residence_floor_area,
            ...formApplicantPeopleAttributes
          } = formik.values.p_application_header.p_applicant_people_attributes;
          const submitForm = {
            p_application_header: {
              ...formApplicationHeader,
              collateral_land_area: collateral_land_area.replaceAll(',', ''),
              collateral_total_floor_area:
                collateral_total_floor_area.replaceAll(',', ''),
              occupied_area: occupied_area.replaceAll(',', ''),
              p_applicant_people_attributes: [
                {
                  ...formApplicantPeopleAttributes,
                  current_residence_floor_area:
                    current_residence_floor_area.replaceAll(',', ''),
                },
              ],
              p_residents_attributes: [
                formik.values.p_application_header.p_residents_attributes,
              ],
            },
          };
          await dispatch(saveForm(submitForm));
          await dispatch(
            saveResident(
              handleCheckResident(formik.values.p_residents_for_submit)
            )
          );
        }}
        footer={{
          left: {
            onClick: async () => {
              const {
                collateral_land_area,
                collateral_total_floor_area,
                occupied_area,
                ...formApplicationHeader
              } = formik.values.p_application_header;
              const {
                current_residence_floor_area,
                ...formApplicantPeopleAttributes
              } =
                formik.values.p_application_header
                  .p_applicant_people_attributes;
              const submitForm = {
                p_application_header: {
                  ...formApplicationHeader,
                  collateral_land_area: collateral_land_area.replaceAll(
                    ',',
                    ''
                  ),
                  collateral_total_floor_area:
                    collateral_total_floor_area.replaceAll(',', ''),
                  occupied_area: occupied_area.replaceAll(',', ''),
                  p_applicant_people_attributes: [
                    {
                      ...formApplicantPeopleAttributes,
                      current_residence_floor_area:
                        current_residence_floor_area.replaceAll(',', ''),
                    },
                  ],
                  p_residents_attributes: [
                    formik.values.p_application_header.p_residents_attributes,
                  ],
                },
              };
              await dispatch(saveForm(submitForm));
              await dispatch(
                saveResident(
                  handleCheckResident(formik.values.p_residents_for_submit)
                )
              );
              if (!!application_number) {
                navigate(-1);
              } else {
                if (incomeTotalizer && !hasJoinGuarantor)
                  return navigate(routeNames.SPStepFiveIncomeTotalizer.path);
                if (hasJoinGuarantor)
                  return navigate(routeNames.SPStepFourCollateralProvider.path);
                navigate(routeNames.SPStepThree.path);
              }
            },
          },

          right: {
            children: btnSubmit,
            onClick: () => {
              formik.handleSubmit();
            },
            disabled: isDisabledButton,
          },
        }}
        onSaveDraft={onSaveDraft}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack sx={{ py: 9 }} justifyContent="center" alignItems="center">
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >
              現在のお住まいと
            </Typography>
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >
              ご購入物件について
            </Typography>
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >
              教えてください。
            </Typography>
          </Stack>
        </Stack>

        {/*TODO: applicant_detail_type === ApplicantDetailType.MAIN &&*/}
        <SPFormItem required label="現在のお住まいの居住年数">
          <Stack direction="row" spacing={1}>
            <Stack>
              <SPInputSelect
                sx={{
                  minHeight: 48,
                  height: 48,
                  width: 72,
                  textAlign: 'center',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: '12px',
                  },
                  '$$& .MuiInputBase-input': {},
                }}
                iconSx={{ right: 0 }}
                name="p_application_header.p_applicant_people_attributes.lived_length_year_num"
                options={yearNumOptions}
                placeholder="--"
                unit="年"
                checkRequired
              />
            </Stack>
            <Stack>
              <SPInputSelect
                sx={{
                  minHeight: 48,
                  height: 48,
                  width: 72,
                  textAlign: 'center',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pt: '12px',
                  },
                }}
                iconSx={{ right: 0 }}
                name="p_application_header.p_applicant_people_attributes.lived_length_month_num"
                options={monthOptions}
                placeholder="--"
                unit="ヶ月"
                checkRequired
                useFastField={false}
              />
            </Stack>
          </Stack>
        </SPFormItem>

        <SPFormItem required label="現在のお住まいの種類">
          <Stack spacing={3}>
            {spStepOneValues.p_application_header.loan_target ===
              PurposeType.SEVEN ||
            spStepOneValues.p_application_header.loan_target ===
              PurposeType.EIGHT ? (
              <SPInputSelect
                name="p_application_header.p_applicant_people_attributes.current_residence"
                options={currentResidenceOptions}
                disableOptions={currentResidenceOptions.slice(0, 4)}
                placeholder="選択してください"
                onChange={(event) =>
                  handleChangeCurrentResidence(event.target.value)
                }
              />
            ) : (
              <SPInputSelect
                name="p_application_header.p_applicant_people_attributes.current_residence"
                options={currentResidenceOptions}
                placeholder="選択してください"
                onChange={(event) =>
                  handleChangeCurrentResidence(event.target.value)
                }
              />
            )}
            {formik.values.p_application_header.p_applicant_people_attributes
              .current_residence ===
              CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS && (
              <Stack
                sx={{
                  borderRadius: 2,
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
              >
                <SPFormItem
                  required
                  label="所有者の氏名"
                  labelSx={{
                    borderTopRightRadius: '9px',
                    borderTopLeftRadius: '9px',
                  }}
                >
                  <SPInputField
                    name="p_application_header.p_applicant_people_attributes.owner_full_name"
                    placeholder="例：山田 太郎"
                    convertFullWidth
                  />
                </SPFormItem>

                <SPFormItem
                  required
                  label="続柄"
                  childrenSx={{
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                >
                  <SPInputField
                    name="p_application_header.p_applicant_people_attributes.owner_relationship"
                    placeholder="例：父"
                    convertFullWidth
                  />
                </SPFormItem>
              </Stack>
            )}

            {formik.values.p_application_header.p_applicant_people_attributes
              .current_residence === CurrentResidenceType.PRIVATE_APARTMENT && (
              <Stack
                sx={{
                  borderRadius: 2,
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
              >
                <SPFormItem
                  required
                  label="持ち家の処分方法"
                  labelSx={{
                    borderTopRightRadius: '9px',
                    borderTopLeftRadius: '9px',
                  }}
                >
                  <Stack spacing={4}>
                    <SPRadioGroupButton
                      name="p_application_header.p_applicant_people_attributes.buyingand_selling_schedule_type"
                      options={buyingandSellingScheduleTypeOptions}
                      onChange={(checked, value) =>
                        handleChangeBuyingandSellingScheduleType(value)
                      }
                      handleReClickOption={() => {
                        formik.setFieldValue(
                          'p_application_header.p_applicant_people_attributes.other_buyingand_selling_schedule_type',
                          ''
                        );
                      }}
                    />
                    {formik.values.p_application_header
                      .p_applicant_people_attributes
                      .buyingand_selling_schedule_type ===
                      BuyingandSellingScheduleType.OTHERS && (
                      <Stack spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="SP_form_item_note" color="b_333">
                            ※
                          </Typography>
                          <Typography variant="SP_form_item_note" color="b_333">
                            その他の方は詳細を入力ください。
                          </Typography>
                        </Stack>
                        <SPInputField
                          name="p_application_header.p_applicant_people_attributes.other_buyingand_selling_schedule_type"
                          placeholder="入力してください"
                          convertFullWidth
                        />
                      </Stack>
                    )}
                  </Stack>
                </SPFormItem>

                {formik.values.p_application_header
                  .p_applicant_people_attributes
                  .buyingand_selling_schedule_type ===
                  BuyingandSellingScheduleType.SALE && (
                  <>
                    <SPFormItem required label="売却予定時期">
                      <SPInputDate
                        name="p_application_header.p_applicant_people_attributes.scheduled_time_sell_house"
                        selectWidth="163px"
                        yearOptions={scheduledTimeOfSaleOptions}
                      />
                    </SPFormItem>

                    <SPFormItem required label="売却予定価格">
                      <Stack direction="row" alignItems="center">
                        <SPInputField
                          name="p_application_header.p_applicant_people_attributes.expected_house_selling_price"
                          unit={
                            <Stack
                              direction="row"
                              alignItems="flex-end"
                              spacing={1}
                            >
                              <Typography
                                variant="sp_unit_text"
                                color="b_333"
                                whiteSpace="nowrap"
                              >
                                万円
                              </Typography>
                              <Typography
                                variant="sp_note_text"
                                color="b_333"
                                whiteSpace="nowrap"
                              >
                                ※10万円単位
                              </Typography>
                            </Stack>
                          }
                          placeholder="0"
                          align="right"
                          sx={{ width: '156px' }}
                          numeric
                          useNumberFormat
                          toFixedNumber={false}
                          maxLength={5}
                        />
                      </Stack>
                    </SPFormItem>
                  </>
                )}

                <SPFormItem
                  required
                  label="ローン残高"
                  childrenSx={{
                    borderBottomRightRadius: 8,
                    borderBottomLeftRadius: 8,
                  }}
                >
                  <SPRadioGroupButton
                    name="p_application_header.p_applicant_people_attributes.current_home_loan"
                    options={currentHomeLoanOptions}
                    display="row"
                  />
                </SPFormItem>
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        {isMCJ && (
          <SPFormItem
            required
            label="現在のお住まいの床面積"
            note="(MCJ固有項目)"
          >
            <SPInputArea
              name="p_application_header.p_applicant_people_attributes.current_residence_floor_area"
              unit="㎡"
            />
          </SPFormItem>
        )}
        <SPFormItem
          label="新しい住居の物件情報"
          note="※物件情報の画像、またはURLのいずれかがあれば添付してください。"
        >
          <Stack
            sx={{
              borderRadius: 2,
              border: (theme) =>
                `1px solid ${theme?.palette?.sp_primary_100_main}`,
            }}
          >
            <SPFormItem
              required
              label="物件情報の画像添付"
              labelSx={{
                borderTopRightRadius: '9px',
                borderTopLeftRadius: '9px',
              }}
            >
              <Stack direction="row" alignItems="flex-start">
                <Typography variant="sp_note_text" color="b_333">
                  ※
                </Typography>
                <Typography variant="sp_note_text" color="b_333">
                  {`ご購入される土地・建物の 所在地・面積・価格などが
                    記載されたチラシやパンフレット等があればアップ
                    ロードしてください。`}
                </Typography>
              </Stack>

              <SPUploadImageItem
                variant="imageOnly"
                images={propertyInformationFile}
                setImages={setPropertyInformationFile}
                reload={reset}
                handleDeleteImage={(id) => {
                  const property_information_file = convertImage(
                    formik.values.p_application_header
                      .property_information_file,
                    id
                  );
                  formik.setFieldValue(
                    'p_application_header.property_information_file',
                    property_information_file
                  );
                }}
              />
            </SPFormItem>

            <SPFormItem
              required
              label={
                <Stack>
                  <Typography variant="sp_note_text" color="b_333">
                    ※チラシ等がない場合
                  </Typography>
                  <Typography variant="SP_form_item_label" color="b_333">
                    物件情報が掲載されたURL添付
                  </Typography>
                </Stack>
              }
              childrenSx={{
                borderBottomRightRadius: '9px',
                borderBottomLeftRadius: '9px',
              }}
            >
              <SPInputField
                name="p_application_header.property_information_url"
                placeholder="例：http://xxxxxx.com"
              />
            </SPFormItem>
          </Stack>
        </SPFormItem>
        {spStepOneValues.p_application_header.loan_target !==
          PurposeType.SEVEN && (
          <SPFormItem required label="新しい住居を必要とする理由">
            <Stack spacing={3}>
              <SPInputSelect
                name="p_application_header.p_applicant_people_attributes.reason_acquire_home"
                options={reasonAcquireHomeOptions}
                placeholder="選択してください"
                onChange={(event) => {
                  if (
                    event.target.value !== ReasonsForNeedingHousingType.OTHERS
                  ) {
                    formik.setFieldValue(
                      'p_application_header.p_applicant_people_attributes.other_reason_acquire_home',
                      ''
                    );
                  }
                }}
              />

              {formik.values.p_application_header.p_applicant_people_attributes
                .reason_acquire_home ===
                ReasonsForNeedingHousingType.OTHERS && (
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="SP_form_item_note" color="b_333">
                      ※
                    </Typography>
                    <Typography variant="SP_form_item_note" color="b_333">
                      その他の方は詳細を入力ください。
                    </Typography>
                  </Stack>
                  <SPInputField
                    name="p_application_header.p_applicant_people_attributes.other_reason_acquire_home"
                    placeholder="入力してください"
                    convertFullWidth
                  />
                </Stack>
              )}

              <Stack direction="row" spacing={1}>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※
                </Typography>
                <Typography variant="SP_form_item_note" color="b_333">
                  ご本人またはご家族の居住用が融資対象で、セカンドハウス等は対象外になります。
                </Typography>
              </Stack>
            </Stack>
          </SPFormItem>
        )}

        <SPFormItem required label="新しい住居に、あなたは居住しますか？">
          <Stack spacing={3}>
            <SPRadioGroupButton
              name="p_application_header.person_occupancy"
              options={personOccupancyOptions}
              display="row"
              onChange={(checked, value) => {
                if (value !== PersonOccupancyType.NO) {
                  formik.setFieldValue(
                    'p_application_header.non_resident_reason',
                    ''
                  );
                }
              }}
            />
            {formik.values.p_application_header.person_occupancy ===
              PersonOccupancyType.NO && (
              <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    「いいえ」の方は理由を入力ください。
                  </Typography>
                </Stack>
                <SPInputField
                  name="p_application_header.non_resident_reason"
                  placeholder="入力してください"
                  convertFullWidth
                />
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem
          required
          label="あなた以外の入居予定者"
          note="※該当する方のみお答えください。"
          noteType="under"
        >
          <SPCheckboxMultipleButton
            name="p_application_header.planned_cohabitant"
            options={plannedCohabitantOptions.filter((option) => {
              if (
                formik.values.p_application_header.planned_cohabitant.includes(
                  PlannedCohabitantType.SPOUSE
                )
              )
                return option.id !== '6';
              if (
                formik.values.p_application_header.planned_cohabitant.includes(
                  PlannedCohabitantType.FIANCE
                )
              )
                return option.id !== '1';
              return option;
            })}
            isSelect
            children={
              <Stack spacing={3}>
                {formik.values.p_application_header.planned_cohabitant.includes(
                  PlannedCohabitantType.OTHERS
                ) && (
                  <Stack spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Typography variant="sp_note_text" color="b_333">
                        その他の方は続柄をご入力ください。
                      </Typography>
                    </Stack>
                    <SPInputField
                      name="p_application_header.other_relationship"
                      placeholder="続柄を入力ください"
                      convertFullWidth
                    />
                  </Stack>
                )}
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  justifyContent="flex-end"
                  sx={{
                    borderTopWidth: 3,
                    borderTopStyle: 'double',
                    borderTopColor: 'sp_primary_60',
                    pt: '10px',
                    pl: '4px',
                  }}
                >
                  <Typography variant="sp_unit_select_text" color="sp_gray_2">
                    ご本人を除き、合計
                  </Typography>
                  <SPInputField
                    name="p_application_header.housemate_number"
                    placeholder="0"
                    align="right"
                    unit="人"
                    sx={{ width: '62px' }}
                    readOnly
                  />
                </Stack>
              </Stack>
            }
          />
        </SPFormItem>
        {isMCJ &&
          formik.values.p_application_header.person_occupancy ===
            PersonOccupancyType.NO &&
          formik.values.p_application_header.housemate_number.length !== 0 && (
            <Stack
              sx={{
                borderRadius: 2,
                border: (theme) =>
                  `1px solid ${theme?.palette?.sp_primary_100_main}`,
                mb: 8,
                mx: 4,
              }}
            >
              <Stack
                sx={{
                  bgcolor: 'sp_primary_100_main',
                  px: 3,
                  py: 1,
                  borderTopLeftRadius: '7px',
                  borderTopRightRadius: '7px',
                }}
              >
                <Stack direction="row" alignItems="flex-end" spacing={1}>
                  <Typography variant="sp_label_form_text" color="main_white">
                    ご入居予定者の情報
                  </Typography>
                  <Typography variant="SP_form_item_note" color="main_white">
                    (MCJ固有項目)
                  </Typography>
                </Stack>
                <Typography variant="SP_form_item_note" color="main_white">
                  ※複数人該当の場合は代表者を入力してください。
                </Typography>
              </Stack>
              <SPFormItem required label="入居予定者の氏名 ">
                <Stack spacing={2}>
                  <SPInputField
                    name="p_application_header.p_residents_attributes.last_name_kanji"
                    placeholder="姓"
                    convertFullWidth
                  />
                  <SPInputField
                    name="p_application_header.p_residents_attributes.first_name_kanji"
                    placeholder="名"
                    convertFullWidth
                  />
                </Stack>
              </SPFormItem>
              <SPFormItem required label="入居予定者の氏名（フリガナ）">
                <Stack spacing={2}>
                  <SPInputField
                    name="p_application_header.p_residents_attributes.last_name_kana"
                    placeholder="セイ"
                    convertFullWidth
                  />
                  <SPInputField
                    name="p_application_header.p_residents_attributes.first_name_kana"
                    placeholder="メイ"
                    convertFullWidth
                  />
                </Stack>
              </SPFormItem>
              <SPFormItem required label="続柄">
                <SPInputField
                  name="p_application_header.p_residents_attributes.relationship_name"
                  placeholder="例：父"
                  convertFullWidth
                />
              </SPFormItem>

              <SPFormItem required label="国籍">
                <SPRadioGroupButton
                  name="p_application_header.p_residents_attributes.nationality"
                  display="row"
                  options={nationalityOptions}
                />
              </SPFormItem>

              <SPFormItem required label="生年月日">
                <SPInputDateTime
                  name="p_application_header.p_residents_attributes.birthday"
                  yearOptions={yearOptions}
                />
              </SPFormItem>

              <SPFormItem
                required
                label="住宅金融支援機構（旧：公庫）からの融資の有無"
              >
                <SPRadioGroupButton
                  name="p_application_header.p_residents_attributes.loan_from_japan_housing_finance_agency"
                  display="row"
                  options={loanFromJapanHousingFinanceAgencyOptions}
                />
              </SPFormItem>

              <SPFormItem required label="電話番号">
                <Stack spacing={1}>
                  <SPInputPhoneNumber
                    name="p_application_header.p_residents_attributes.home_phone_number"
                    type="homePhone"
                  />
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※ 半角数字でご入力ください。
                  </Typography>
                </Stack>
              </SPFormItem>

              <SPFormItem
                required
                label="住所"
                childrenSx={{
                  borderBottomRightRadius: '9px',
                  borderBottomLeftRadius: '9px',
                }}
              >
                <Stack spacing={4}>
                  <Stack direction="row" alignItems="center">
                    <PostalCodeCheckbox
                      checked={checked}
                      onChange={() => setChecked((prev) => !prev)}
                    />
                    <Typography
                      variant="SP_multiple_checkbox_label"
                      color="sp_primary_100_main"
                    >
                      申込者本人と同じ住所
                    </Typography>
                  </Stack>

                  <Stack spacing={1}>
                    <SPInputZipCode
                      name="p_application_header.p_residents_attributes.postal_code"
                      label="郵便番号"
                      note="※ 入力すると自動的に住所が表示されます。"
                      handleFocusZipCode={() => {
                        setChecked(false);
                        setShowErrZipCode(false);
                      }}
                      handleInputZipcode={(values) => {
                        if (
                          values?.length === 8 &&
                          values === convertToHalfWidth(values)
                        ) {
                          return handleZipCodeChange(values);
                        }
                      }}
                    />
                    {showErrZipCode && (
                      <Typography
                        variant="text_error"
                        color="sp_secondary_01"
                        whiteSpace="nowrap"
                      >
                        ※住所が取得できませんでした。再度入力してください。
                      </Typography>
                    )}
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="SP_label_field" color="b_333">
                      都道府県
                    </Typography>
                    <SPInputSelect
                      name="p_application_header.p_residents_attributes.prefecture_kanji"
                      options={PREFECTURES}
                      placeholder="----"
                      sx={{
                        '@media (orientation: landscape)': {
                          textAlign: isMobile ? 'left' : 'center',
                          width: isMobile ? '100%' : '110px',
                        },
                        width: '110px',
                        textAlign: 'center',
                      }}
                    />
                  </Stack>

                  <SPInputField
                    name="p_application_header.p_residents_attributes.city_kanji"
                    placeholder="例：港区"
                    label="市区郡　（例：港区）"
                    convertFullWidth
                  />

                  <SPInputField
                    name="p_application_header.p_residents_attributes.district_kanji"
                    placeholder="例：芝浦４丁目"
                    label="町村丁目（例：芝浦４丁目）"
                    convertFullWidth
                  />

                  <SPInputField
                    name="p_application_header.p_residents_attributes.other_address_kanji"
                    placeholder="例：12-38　キャナルゲート芝浦605号室"
                    label="丁目以下・建物名・部屋番号（例：12-38　キャナルゲート芝浦605号室）"
                    convertFullWidth
                    labelStyle={{ lineHeight: '20px' }}
                    // onBlur={(event) => handleBlur(event)}
                  />
                </Stack>
              </SPFormItem>
            </Stack>
          )}
        <SPFormItem
          label={
            <Stack>
              <Typography variant="SP_form_item_label" color="b_333">
                新しい住居（融資対象物件）の事業性
              </Typography>
              <Typography variant="SP_form_item_note" color="b_333">
                ※該当する方のみお答えください。
              </Typography>
            </Stack>
          }
        >
          <Stack spacing={1}>
            <SPCheckboxMultipleButton
              sx={{
                width: '100%',
                justifyContent: 'flex-start',
                px: 12,
              }}
              name="p_application_header.business_ability"
              options={businessAbilityOptions.filter((option) => {
                return option.id;
              })}
            />
            <Stack direction="row" spacing={1}>
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Typography variant="SP_form_item_note" color="b_333">
                事業用との併用物件の場合は居宅部分が延床面積の1/2以上あることが必要です。
              </Typography>
            </Stack>
          </Stack>
        </SPFormItem>
        <SPFormItem label="ご購入物件の所在地">
          <Stack spacing={6}>
            <Stack spacing={3}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※
                </Typography>
                <Typography variant="SP_form_item_note" color="b_333">
                  登記簿上の表記でご入力ください。
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="flex-start" spacing={1}>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※
                </Typography>
                <Typography variant="SP_form_item_note" color="b_333">
                  住信SBIネット銀行は借地・共有仮換地・保留地・離島にある物件はお取扱いできません。
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="SP_label_field" color="b_333">
                都道府県
              </Typography>
              <SPInputSelect
                name="p_application_header.collateral_prefecture"
                placeholder="----"
                options={PREFECTURES}
                sx={{
                  '@media (orientation: landscape)': {
                    textAlign: isMobile ? 'left' : 'center',
                    width: isMobile ? '100%' : '110px',
                  },
                  width: '110px',
                  textAlign: 'center',
                }}
              />
            </Stack>

            <SPInputField
              label="市区町村郡"
              name="p_application_header.collateral_city"
              placeholder="港区芝浦"
              convertFullWidth
            />

            <SPInputField
              label="以下地番"
              name="p_application_header.collateral_lot_number"
              placeholder="４丁目１２−３８"
              convertFullWidth
            />

            {(spStepOneValues.p_application_header.loan_target ===
              PurposeType.THREE ||
              spStepOneValues.p_application_header.loan_target ===
                PurposeType.FOUR) && (
              <SPInputField
                label="丁目以下"
                name="p_application_header.condominium_name"
                placeholder="４−１２−３８ 建物名 部屋番号"
                convertFullWidth
                labelStyle={{ lineHeight: '20px' }}
              />
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem label="ご購入物件の面積">
          {spStepOneValues.p_application_header.loan_target ===
            PurposeType.THREE ||
          spStepOneValues.p_application_header.loan_target ===
            PurposeType.FOUR ? (
            <Stack sx={{ mb: 6 }}>
              <SPInputArea
                name="p_application_header.occupied_area"
                label="専有面積"
                unit="㎡"
                onFocus={() => setIsFocusArea(true)}
                onBlur={() => setIsFocusArea(false)}
              />
            </Stack>
          ) : (
            <Stack sx={{ mb: 6 }}>
              <SPInputArea
                name="p_application_header.collateral_land_area"
                label="土地の敷地面積"
                unit="㎡"
                onFocus={() => setIsFocusArea(true)}
                onBlur={() => setIsFocusArea(false)}
              />
            </Stack>
          )}
          <SPInputArea
            name="p_application_header.collateral_total_floor_area"
            label={
              spStepOneValues.p_application_header.loan_target ===
                PurposeType.THREE ||
              spStepOneValues.p_application_header.loan_target ===
                PurposeType.FOUR
                ? 'マンション全体の延べ床面積'
                : '建物の延べ床面積'
            }
            unit="㎡"
            onFocus={() => setIsFocusArea(true)}
            onBlur={() => setIsFocusArea(false)}
          />
        </SPFormItem>
        {isMCJ && (
          <>
            <SPFormItem
              label={
                <Stack>
                  <Typography variant="SP_form_item_label" color="b_333">
                    ご購入物件の土地権利
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※該当する方のみお答えください。(MCJ固有項目)
                  </Typography>
                </Stack>
              }
            >
              <SPRadioGroupButton
                name="p_application_header.land_ownership"
                options={landOwnershipOptions}
              />
            </SPFormItem>

            <SPFormItem
              label={
                <Stack>
                  <Typography variant="SP_form_item_label" color="b_333">
                    買戻・保留地・仮換地
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※該当する方のみお答えください。(MCJ固有項目)
                  </Typography>
                </Stack>
              }
            >
              <SPRadioGroupButton
                name="p_application_header.purchase_purpose"
                options={purchasePurposeOptions}
              />
            </SPFormItem>

            <SPFormItem
              label={
                <Stack>
                  <Stack direction="row" alignItems="flex-end">
                    <Typography variant="SP_form_item_label" color="b_333">
                      都市計画区域等
                    </Typography>
                    <Typography variant="SP_form_item_note" color="b_333">
                      (MCJ固有項目)
                    </Typography>
                  </Stack>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※「市街化区域」以外の方のみお答えください。
                  </Typography>
                </Stack>
              }
            >
              <SPRadioGroupButton
                name="p_application_header.planning_area"
                options={planningAreaOptions}
                onChange={(checked, value) => handleChangePlanningArea(value)}
                handleReClickOption={() => {
                  formik.setFieldValue(
                    'p_application_header.other_planning_area',
                    ''
                  );
                }}
              />
            </SPFormItem>

            {formik.values.p_application_header.planning_area ===
              PlanningAreaType.OTHERS && (
              <Stack sx={{ mx: 4, mb: 8 }}>
                <SPInputField
                  name="p_application_header.other_planning_area"
                  label="※ その他の方は詳細を入力ください。"
                  placeholder="入力してください"
                  convertFullWidth
                />
              </Stack>
            )}

            {(formik.values.p_application_header.planning_area ===
              PlanningAreaType.URBANIZED_AREA ||
              formik.values.p_application_header.planning_area ===
                PlanningAreaType.URBANIZATION_CONTROL_AREA) && (
              <>
                <SPFormItem
                  label={
                    <Stack>
                      <Typography variant="SP_form_item_label" color="b_333">
                        上記に該当する場合の「再建築理由」を教えてください。
                      </Typography>
                      <Typography variant="SP_form_item_note" color="b_333">
                        ※該当する方のみお答えください。(MCJ固有項目)
                      </Typography>
                    </Stack>
                  }
                >
                  <SPRadioGroupButton
                    name="p_application_header.rebuilding_reason"
                    options={rebuildingReasonOptions}
                    onChange={(checked, value) =>
                      handleChangeRebuildingReason(value)
                    }
                    handleReClickOption={() => {
                      formik.setFieldValue(
                        'p_application_header.other_rebuilding_reason',
                        ''
                      );
                    }}
                  />
                </SPFormItem>

                {formik.values.p_application_header.rebuilding_reason ===
                  RebuildingReasonType.OTHERS && (
                  <Stack sx={{ mx: 4, mb: 8 }}>
                    <SPInputField
                      name="p_application_header.other_rebuilding_reason"
                      label="※ その他の方は詳細を入力ください。"
                      placeholder="入力してください"
                      convertFullWidth
                    />
                  </Stack>
                )}
              </>
            )}

            <SPFormItem
              label={
                <Stack>
                  <Typography variant="SP_form_item_label" color="b_333">
                    フラット35S（優良住宅取得支援制度）対象項目
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※該当する方のみお答えください。(MCJ固有項目)
                  </Typography>
                </Stack>
              }
            >
              <SPRadioGroupButton
                name="p_application_header.flat_35_applicable_plan"
                options={flat35ApplicablePlanOptions}
              />
            </SPFormItem>

            <SPFormItem
              label={
                <Stack>
                  <Typography variant="SP_form_item_label" color="b_333">
                    維持保全型
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※該当する方のみお答えください。(MCJ固有項目)
                  </Typography>
                </Stack>
              }
            >
              <SPRadioGroupButton
                name="p_application_header.maintenance_type"
                options={maintenanceOptions}
              />
            </SPFormItem>

            {formik.values.p_application_header.flat_35_applicable_plan ===
              Flat35ApplicablePlanType.PLAN_A && (
              <SPFormItem
                label="フラット35S（優良住宅取得支援制度）対象項目②"
                note="※該当する方のみお答えください。(MCJ固有項目)"
              >
                <SPRadioGroupButton
                  name="p_application_header.flat_35_application"
                  options={flat35ApplicationOptions}
                />
              </SPFormItem>
            )}

            <SPFormItem
              label={
                <Stack>
                  <Typography variant="SP_form_item_label" color="b_333">
                    地域連携型・地方移住支援型
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※該当する方のみお答えください。(MCJ固有項目)
                  </Typography>
                </Stack>
              }
            >
              <SPRadioGroupButton
                name="p_application_header.region_type"
                options={regionOptions}
              />
            </SPFormItem>
          </>
        )}
        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
          }}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepFourPage;

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(48), (_, index) => {
    const year = zeroPad(dayjs().year() - 18 - index);
    const startDay = year === '1989' ? 8 : 1;
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, startDay);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, startDay);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);

const scheduledTimeOfSaleOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);

const personOccupancyOptions: Array<RadioItems> = [
  {
    value: PersonOccupancyType.YES,
    label: personOccupancy[PersonOccupancyType.YES],
  },
  {
    value: PersonOccupancyType.NO,
    label: personOccupancy[PersonOccupancyType.NO],
  },
];

const currentHomeLoanOptions: Array<RadioItems> = [
  {
    value: CurrentHomeLoanType.YES,
    label: currentHomeLoan[CurrentHomeLoanType.YES],
  },
  {
    value: CurrentHomeLoanType.NO,
    label: currentHomeLoan[CurrentHomeLoanType.NO],
  },
];

const loanFromJapanHousingFinanceAgencyOptions: Array<RadioItems> = [
  {
    value: FinanceAgencyType.YES,
    label: financeAgency[FinanceAgencyType.YES],
  },
  {
    value: FinanceAgencyType.NO,
    label: financeAgency[FinanceAgencyType.NO],
  },
];

const buyingandSellingScheduleTypeOptions: Array<RadioItems> = [
  {
    value: BuyingandSellingScheduleType.SALE,
    label: buyingandSellingScheduleType[BuyingandSellingScheduleType.SALE],
  },
  {
    value: BuyingandSellingScheduleType.RENT,
    label: buyingandSellingScheduleType[BuyingandSellingScheduleType.RENT],
  },
  {
    value: BuyingandSellingScheduleType.OTHERS,
    label: buyingandSellingScheduleType[BuyingandSellingScheduleType.OTHERS],
  },
];

const calculateTotal = ({
  children_number,
  siblings_number,
  other_people_number,
  planned_cohabitant,
}: SelectProcurementType) => {
  return (
    (planned_cohabitant.find((e) => e === PlannedCohabitantType.CHILD)
      ? +children_number
      : 0) +
      (planned_cohabitant.find(
        (e) => e === PlannedCohabitantType.BROTHERS_AND_SISTERS
      )
        ? +siblings_number
        : 0) +
      (planned_cohabitant.find((e) => e === PlannedCohabitantType.OTHERS)
        ? +other_people_number
        : 0) || ''
  );
};
