import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPInfoModal: FC<SvgIconProps> = (props) => {
  const id = useMemo<string>(
    () => Math.round(Math.random() * 10000000).toString(),
    []
  );
  return (
    <SvgIcon viewBox="0 0 36 36" {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.0635 0.500244H4.81348C2.40381 0.500244 0.438477 2.46558 0.438477 4.81372V24.4397C0.438477 26.8487 2.40381 28.7532 4.81348 28.7532H11.376V34.4947C11.376 34.9781 11.7806 35.3157 12.2086 35.3157C12.3705 35.3157 12.5358 35.2658 12.6836 35.158L21.2216 28.7534H31.0654C33.4751 28.7534 35.4404 26.788 35.4404 24.4399V4.81344C35.4385 2.46598 33.5312 0.500244 31.0635 0.500244ZM34.3447 24.5009C34.3447 26.3097 32.8729 27.7815 31.0635 27.7815H20.8574L12.4697 34.076V27.7815H4.81348C3.004 27.7815 1.53223 26.3097 1.53223 24.5009V4.87496C1.53223 3.06617 3.004 1.5944 4.81348 1.5944H31.0635C32.8729 1.5944 34.3447 3.06617 34.3447 4.87496V24.5009ZM22.375 12.4697C22.9781 12.4697 23.4674 11.9785 23.4674 11.3759C23.4674 10.7734 22.9767 10.3437 22.3736 10.3437C21.7706 10.3437 21.2799 10.8345 21.2799 11.3759C21.2799 11.9174 21.7734 12.4697 22.375 12.4697ZM24.0908 17.7265C22.8371 19.9168 20.504 21.2785 18.0027 21.2785C15.5015 21.2785 13.167 19.9209 11.9092 17.7265C11.7586 17.4627 11.4232 17.3741 11.1634 17.5236C10.9043 17.6718 10.8154 18.0068 10.9658 18.2666C12.413 20.7979 15.1111 22.3715 17.9453 22.3715C20.7795 22.3715 23.5337 20.7979 24.9795 18.2666C25.1301 18.0049 25.0393 17.6707 24.7765 17.5215C24.5138 17.3722 24.2344 17.4668 24.0908 17.7265ZM13.625 12.4697C14.2281 12.4697 14.7167 11.9785 14.7167 11.3759C14.7167 10.7734 14.2266 10.2822 13.5635 10.2822C12.9604 10.2822 12.4697 10.7729 12.4697 11.3144C12.4697 11.8559 13.0234 12.4697 13.625 12.4697Z"
          fill={`url(#${id})`}
        />
        <defs>
          <linearGradient
            id={id}
            x1="5.82851"
            y1="28.1555"
            x2="32.4297"
            y2="1.14195"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
