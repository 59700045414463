import { forwardRef } from 'react';
import RNumberFormat from 'react-number-format';

type NumberFormatProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  decimalScale?: number;
};

export const NumberFormat = forwardRef<
  RNumberFormat<string | number>,
  NumberFormatProps
>(({ onChange, ...props }, ref) => (
  <RNumberFormat
    {...props}
    getInputRef={ref}
    onValueChange={(values) => {
      onChange({
        target: {
          name: props.name,
          value: values.value,
        },
      });
    }}
    isNumericString
    thousandSeparator
    fixedDecimalScale
    allowNegative={false}
    allowLeadingZeros={false}
  />
));

export const NumberFormatDecimal = forwardRef<
  RNumberFormat<string | number>,
  NumberFormatProps
>(({ decimalScale = 2, ...props }, ref) => (
  <NumberFormat ref={ref} {...props} decimalScale={decimalScale} />
));
