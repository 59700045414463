import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  LoanType,
  OccupationDetailType,
  OccupationType,
  LeaveType,
  DispatchType,
  dispatch,
  jobChange,
  JobChangeType,
  finalTaxReturn,
  PlannedCohabitantType,
  child,
  LandAdvanceType,
  LoanBalanceType,
  ApplicantType,
  PlanningAreaType,
  RebuildingReasonType,
  CODEBANK,
  PurposeType,
  financeAgency,
  ApplicantDetailType,
  Title,
  BorrowingType,
  LoanPurposeType,
  FileATaxReturnType,
  WorkIndustryType,
  ResidentStatusType,
  landAdvance,
  joinGuarantor,
  FinalTaxReturnType,
  FundsPairOffType,
  ScheduledLoanPayoffType,
  estateMortgage,
  commonHousing,
  borrowingFromHousingFinanceAgency,
  borrowingCategory,
  scheduledCompletionOfBorrowing,
} from 'constant';
import {
  FormData as FormikFormData,
  GetFormDataRequest,
  GetFormDataRequestResponse,
  PBorrowingAttribute,
  PBorrowingAttribute2,
  PBorrowingAttribute3,
  PBorrowingAttribute4,
  PBorrowingAttributeSBI,
  SubmitFormError,
  SubmitFormResponse,
  ThunkAPI,
  UpdatePreliminariesError,
  UpdatePreliminariesRequest,
  UpdatePreliminariesResponse,
} from 'types';
import {
  containsOnly,
  convertYenToMan,
  findMultipleOptionName,
  findOptionName,
  formatDate,
  formatJapanBirthday,
  formatJapanDate,
  handleFormatDateDiff,
} from 'utils';
import { routeNames } from 'navigations/routes';
import { applicationsService } from 'services';

export const getFormData = createAsyncThunk<
  GetFormDataRequestResponse,
  GetFormDataRequest,
  ThunkAPI
>('step04/getFormData', (_, { getState }) => {
  const { step_synthesis, dynamic_options, masterBank } = getState();
  const isMCJ = step_synthesis.form.master_bank_codes.includes(CODEBANK.MCJ);

  const {
    loan_type,
    loan_target,
    sex,
    repayment_method,
    nationality,
    industry,
    prev_company_industry,
    occupation,
    occupation_detail,
    income_source,
    type_tax_return,
    maternity_paternity_leave_status,
    nursing_leave_status,
    current_residence,
    reason_acquire_home,
    business_ability,
    resident_status,
    planned_cohabitant,
    desired_monthly_bonus,
    land_ownership,
    purchase_purpose,
    planning_area,
    rebuilding_reason,
    flat_35_applicable_plan,
    maintenance_type,
    region_type,
    relationship,
    relationship_to_applicant,
    guarantor_relationship_to_applicant,
    borrower,
    loan_purpose,
    scheduled_loan_payoff,
    completely_repayment_type,
    repayment_borrowing_method,
    business_borrowing_type,
  } = dynamic_options.form;

  const optionArray = [
    {
      field: loan_type,
      formikField: step_synthesis.form.p_application_header.loan_type,
    },
    {
      field: loan_target,
      formikField: step_synthesis.form.p_application_header.loan_target,
    },
    {
      field: sex,
      formikField: step_synthesis.form.main_p_applicant_person.sex,
    },
    {
      field: sex,
      formikField: step_synthesis.form.pair_p_applicant_person.sex,
    },
    {
      field: repayment_method,
      formikField:
        step_synthesis.form.main_p_applicant_person
          .p_borrowing_detail_attributes.repayment_method,
    },
    {
      field: repayment_method,
      formikField:
        step_synthesis.form.pair_p_applicant_person
          .p_borrowing_detail_attributes.repayment_method,
    },
    {
      field: nationality,
      formikField: step_synthesis.form.main_p_applicant_person.nationality,
    },
    {
      field: nationality,
      formikField: step_synthesis.form.pair_p_applicant_person.nationality,
    },
    {
      field: industry,
      formikField: step_synthesis.form.main_p_applicant_person.industry,
    },
    {
      field: industry,
      formikField: step_synthesis.form.pair_p_applicant_person.industry,
    },
    {
      field: prev_company_industry,
      formikField:
        step_synthesis.form.main_p_applicant_person.prev_company_industry,
    },
    {
      field: prev_company_industry,
      formikField:
        step_synthesis.form.pair_p_applicant_person.prev_company_industry,
    },
    {
      field: occupation,
      formikField: step_synthesis.form.main_p_applicant_person.occupation,
    },
    {
      field: occupation,
      formikField: step_synthesis.form.pair_p_applicant_person.occupation,
    },
    {
      field: occupation_detail,
      formikField:
        step_synthesis.form.main_p_applicant_person.occupation_detail,
    },
    {
      field: occupation_detail,
      formikField:
        step_synthesis.form.pair_p_applicant_person.occupation_detail,
    },
    {
      field: maternity_paternity_leave_status,
      formikField:
        step_synthesis.form.main_p_applicant_person
          .maternity_paternity_leave_status,
    },
    {
      field: maternity_paternity_leave_status,
      formikField:
        step_synthesis.form.pair_p_applicant_person
          .maternity_paternity_leave_status,
    },
    {
      field: nursing_leave_status,
      formikField:
        step_synthesis.form.main_p_applicant_person.nursing_leave_status,
    },
    {
      field: nursing_leave_status,
      formikField:
        step_synthesis.form.pair_p_applicant_person.nursing_leave_status,
    },
    {
      field: current_residence,
      formikField:
        step_synthesis.form.main_p_applicant_person.current_residence,
    },
    {
      field: current_residence,
      formikField:
        step_synthesis.form.pair_p_applicant_person.current_residence,
    },
    {
      field: reason_acquire_home,
      formikField:
        step_synthesis.form.main_p_applicant_person.reason_acquire_home,
    },
    {
      field: reason_acquire_home,
      formikField:
        step_synthesis.form.pair_p_applicant_person.reason_acquire_home,
    },
    {
      field: business_ability,
      formikField: step_synthesis.form.main_p_applicant_person.business_ability,
    },
    {
      field: business_ability,
      formikField: step_synthesis.form.pair_p_applicant_person.business_ability,
    },
    {
      field: resident_status,
      formikField: step_synthesis.form.main_p_applicant_person.resident_status,
    },
    {
      field: resident_status,
      formikField: step_synthesis.form.pair_p_applicant_person.resident_status,
    },
    {
      field: desired_monthly_bonus,
      formikField:
        step_synthesis.form.main_p_applicant_person
          .p_borrowing_detail_attributes.desired_monthly_bonus,
    },
    {
      field: desired_monthly_bonus,
      formikField:
        step_synthesis.form.pair_p_applicant_person
          .p_borrowing_detail_attributes.desired_monthly_bonus,
    },
    {
      field: land_ownership,
      formikField: step_synthesis.form.p_application_header.land_ownership,
    },
    {
      field: purchase_purpose,
      formikField: step_synthesis.form.p_application_header.purchase_purpose,
    },
    {
      field: planning_area,
      formikField: step_synthesis.form.p_application_header.planning_area,
    },
    {
      field: rebuilding_reason,
      formikField: step_synthesis.form.p_application_header.rebuilding_reason,
    },
    {
      field: flat_35_applicable_plan,
      formikField:
        step_synthesis.form.p_application_header.flat_35_applicable_plan,
    },
    {
      field: maintenance_type,
      formikField: step_synthesis.form.p_application_header.maintenance_type,
    },
    {
      field: region_type,
      formikField: step_synthesis.form.p_application_header.region_type,
    },
    {
      field: relationship,
      formikField:
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .relationship,
    },
    {
      field: relationship,
      formikField:
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .relationship,
    },
    {
      field: nationality,
      formikField:
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .nationality,
    },
    {
      field: nationality,
      formikField:
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .nationality,
    },
    {
      field: relationship_to_applicant,
      formikField:
        step_synthesis.form.pair_p_applicant_person.relationship_to_applicant,
    },
  ];

  const sexGuarantors =
    step_synthesis.form.main_p_applicant_person.p_join_guarantors_attributes.map(
      (guarantor) => findOptionName(sex, guarantor.sex)
    );

  const relationshipGuarantors =
    step_synthesis.form.main_p_applicant_person.p_join_guarantors_attributes.map(
      (guarantor) =>
        findOptionName(
          guarantor_relationship_to_applicant,
          guarantor.guarantor_relationship_to_applicant
        )
    );

  const [
    loanType,
    loanTarget,
    mainSex,
    pairSex,
    mainRepayment,
    pairRepayment,
    mainNationality,
    pairNationality,
    mainIndustry,
    pairIndustry,
    mainPrevIndustry,
    pairPrevIndustry,
    mainOccupation,
    pairOccupation,
    mainOccupationDetail,
    pairOccupationDetail,
    mainMaternityStatus,
    pairMaternityStatus,
    mainNursingStatus,
    pairNursingStatus,
    mainCurrentResidence,
    pairCurrentResidence,
    mainReasonAcquireHome,
    pairReasonAcquireHome,
    mainBusinessAbility,
    pairBusinessAbility,
    mainResidentStatus,
    pairResidentStatus,
    mainDesiredMonthlyBonus,
    pairDesiredMonthlyBonus,
    landOwnership,
    purchasePurpose,
    planningArea,
    rebuildingReason,
    flat35ApplicablePlan,
    maintenance,
    region,
    mainRelationship,
    pairRelationship,
    mainResidentsNationality,
    pairResidentNationality,
    relationshipToApplicant,
  ] = optionArray.map((opt) => findOptionName(opt.field, opt.formikField));

  const multipleOptionArray = [
    {
      field: income_source,
      formikField: step_synthesis.form.main_p_applicant_person.income_source,
    },
    {
      field: income_source,
      formikField: step_synthesis.form.pair_p_applicant_person.income_source,
    },
    {
      field: type_tax_return,
      formikField: step_synthesis.form.main_p_applicant_person.type_tax_return,
    },
    {
      field: type_tax_return,
      formikField: step_synthesis.form.pair_p_applicant_person.type_tax_return,
    },
    {
      field: planned_cohabitant,
      formikField:
        step_synthesis.form.main_p_applicant_person.planned_cohabitant,
    },
    {
      field: planned_cohabitant,
      formikField:
        step_synthesis.form.pair_p_applicant_person.planned_cohabitant,
    },
    {
      field: completely_repayment_type,
      formikField:
        step_synthesis.form.p_application_header.completely_repayment_type,
    },
  ];
  const [
    mainIncomeSource,
    pairIncomeSource,
    mainTypeTaxReturn,
    pairTypeTaxReturn,
    mainPlannedCohabitant,
    pairPlannedCohabitant,
    completelyRepaymentType,
  ] = multipleOptionArray.map((opt) =>
    findMultipleOptionName(opt.field, opt.formikField)
  );

  const checkLabel = (index: number) =>
    step_synthesis.form.main_p_applicant_person.p_borrowings_attributes
      .length === 1
      ? ''
      : `${index + 1}本目融資`;

  const checkJoinGuarantorLabel = (index: number) =>
    step_synthesis.form.main_p_applicant_person.p_join_guarantors_attributes
      .length === 1
      ? ''
      : `${index + 1}担保提供者`;

  const checkLoanNumber = (name: string, index: number) => {
    switch (index) {
      case 0:
        return `${
          step_synthesis.form.p_application_header.has_land_advance_plan ===
          LandAdvanceType.YES
            ? '1本目融資'
            : ''
        }${name}`;
      case 1:
        return `2本目融資${name}`;
      default:
        return name;
    }
  };

  const mainJoinGuarantors = () => {
    return step_synthesis.form.main_p_applicant_person.p_join_guarantors_attributes
      .filter((guarantor) => !guarantor._destroy)
      .map((guarantor, index) => [
        {
          name: `${checkJoinGuarantorLabel(index)} お名前（漢字）（全角）`,
          value: `${guarantor.last_name_kanji} ${guarantor.first_name_kanji}`,
        },
        {
          name: `${checkJoinGuarantorLabel(
            index
          )} お名前（フリガナ）（全角カナ）`,
          value: `${guarantor.last_name_kana} ${guarantor.first_name_kana}`,
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 性別`,
          value: sexGuarantors[index]?.option_name ?? 'ー',
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 続柄`,
          value: relationshipGuarantors[index]?.option_name ?? 'ー',
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 生年月日`,
          value: formatJapanBirthday(guarantor.birthday) || 'ー',
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 携帯電話番号`,
          value: guarantor.mobile_phone_number?.toString() || 'ー',
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 固定電話番号`,
          value: guarantor.home_phone_number?.toString() || 'ー',
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 郵便番号`,
          value: guarantor.postal_code,
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 都道府県（漢字）`,
          value:
            guarantor.prefecture_kanji +
            guarantor.city_kanji +
            guarantor.district_kanji +
            guarantor.other_address_kanji,
        },
        {
          name: `${checkJoinGuarantorLabel(index)} 都道府県（フリガナ）`,
          value:
            guarantor.prefecture_kana +
            guarantor.city_kana +
            guarantor.district_kana +
            guarantor.other_address_kana,
        },
      ])
      .flat();
  };

  const requiredInformation = () => {
    return [
      {
        label: Title.REQUIRED,
        data: [
          {
            name: '申込日兼同意日',
            value: formatJapanDate(
              step_synthesis.form.p_application_header.loan_apply_date
            ),
          },
          {
            name: '借入希望日',
            value: formatJapanDate(
              step_synthesis.form.p_application_header
                .loan_desired_borrowing_date
            ),
          },
          {
            name: '入居予定年月',
            value: formatJapanDate(
              step_synthesis.form.p_application_header.scheduled_date_moving
            ),
          },
          {
            name: '送付する金融機関を選択してください。（複数選択可）',
            value: masterBank.form
              .filter((bank) =>
                step_synthesis.form.p_application_header.master_bank_ids.includes(
                  bank.id
                )
              )
              .map((bank) => bank.name)
              .join(','),
          },
          {
            name: 'お借入れ形態',
            value: loanType?.option_name ?? 'ー',
          },
          {
            name: '資金使途',
            value: loanTarget?.option_name ?? 'ー',
          },
          ...mainJoinGuarantors(),
        ],
        path: routeNames.StepRequiredInformation.path,
        contentButton: '編集',
      },
    ];
  };

  const mainInformation = () => [
    {
      label: Title.INFORMATION,
      data: [
        {
          name: 'お名前',
          value: `${step_synthesis.form.main_p_applicant_person.last_name_kanji}  ${step_synthesis.form.main_p_applicant_person.first_name_kanji}`,
        },
        {
          name: 'お名前（フリガナ）',
          value: `${step_synthesis.form.main_p_applicant_person.last_name_kana} ${step_synthesis.form.main_p_applicant_person.first_name_kana}`,
        },
        {
          name: '性別',
          value: mainSex?.option_name ?? 'ー',
        },
        {
          name: '生年月日',
          value:
            formatJapanBirthday(
              step_synthesis.form.main_p_applicant_person.birthday
            ) || 'ー',
        },
        {
          name: '現在の国籍',
          value: mainNationality?.option_name ?? 'ー',
        },
        {
          name: '携帯電話番号',
          value:
            step_synthesis.form.main_p_applicant_person.mobile_phone_number?.toString() ||
            'ー',
        },
        {
          name: '固定電話番号',
          value:
            step_synthesis.form.main_p_applicant_person.home_phone_number?.toString() ||
            'ー',
        },
        {
          name: '郵便番号',
          value: step_synthesis.form.main_p_applicant_person.postal_code,
        },
        {
          name: '都道府県（漢字）',
          value:
            step_synthesis.form.main_p_applicant_person.prefecture_kanji +
            step_synthesis.form.main_p_applicant_person.city_kanji +
            step_synthesis.form.main_p_applicant_person.district_kanji +
            step_synthesis.form.main_p_applicant_person.other_address_kanji,
        },
        {
          name: '都道府県（フリガナ）',
          value:
            step_synthesis.form.main_p_applicant_person.prefecture_kana +
            step_synthesis.form.main_p_applicant_person.city_kana +
            step_synthesis.form.main_p_applicant_person.district_kana +
            step_synthesis.form.main_p_applicant_person.other_address_kana,
        },
        {
          name: 'メールアドレス',
          value: step_synthesis.form.main_p_applicant_person.owner_email,
        },
        {
          name: 'メールアドレス（確認用）',
          value: step_synthesis.form.main_p_applicant_person.confirmation_email,
        },
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const pairInformation = () => {
    if (step_synthesis.form.p_application_header.loan_type !== LoanType.ONE)
      return [
        {
          label: Title.INFORMATION,
          note: ApplicantType.PAIR,
          data: [
            {
              name: 'お名前',
              value: `${step_synthesis.form.pair_p_applicant_person.last_name_kanji}  ${step_synthesis.form.pair_p_applicant_person.first_name_kanji}`,
            },
            {
              name: 'お名前（フリガナ）',
              value: `${step_synthesis.form.pair_p_applicant_person.last_name_kana} ${step_synthesis.form.pair_p_applicant_person.first_name_kana}`,
            },
            {
              name: '性別',
              value: pairSex?.option_name ?? 'ー',
            },
            {
              name: '生年月日',
              value:
                formatJapanBirthday(
                  step_synthesis.form.pair_p_applicant_person.birthday
                ) || 'ー',
            },
            {
              name: '続柄',
              value: relationshipToApplicant?.option_name ?? 'ー',
            },
            {
              name: '現在の国籍',
              value: pairNationality?.option_name ?? 'ー',
            },
            {
              name: '携帯電話番号',
              value:
                step_synthesis.form.pair_p_applicant_person.mobile_phone_number?.toString() ||
                'ー',
            },
            {
              name: '固定電話番号',
              value:
                step_synthesis.form.pair_p_applicant_person.home_phone_number?.toString() ||
                'ー',
            },
            {
              name: '郵便番号',
              value:
                step_synthesis.form.pair_p_applicant_person.postal_code || 'ー',
            },
            {
              name: '都道府県（漢字）',
              value:
                step_synthesis.form.pair_p_applicant_person.prefecture_kanji +
                step_synthesis.form.pair_p_applicant_person.city_kanji +
                step_synthesis.form.pair_p_applicant_person.district_kanji +
                step_synthesis.form.pair_p_applicant_person.other_address_kanji,
            },
            {
              name: '都道府県（フリガナ）',
              value:
                step_synthesis.form.pair_p_applicant_person.prefecture_kana +
                step_synthesis.form.pair_p_applicant_person.city_kana +
                step_synthesis.form.pair_p_applicant_person.district_kana +
                step_synthesis.form.pair_p_applicant_person.other_address_kana,
            },
            {
              name: 'メールアドレス',
              value: step_synthesis.form.pair_p_applicant_person.owner_email,
            },
            {
              name: 'メールアドレス（確認用）',
              value:
                step_synthesis.form.pair_p_applicant_person.confirmation_email,
            },
          ],
          path: routeNames.StepSynthesisInformation.path,
          contentButton: '編集',
        },
      ];
    return [];
  };

  const monthlyPaymentMCJ = () => {
    const monthlyPaymentBorrowings =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.filter(
        (borrow) =>
          borrow.borrowing_type === BorrowingType.MONTHLY_PAYMENT &&
          !borrow._destroy
      );
    const mainMonthlyPaymentLoanPurpose = monthlyPaymentBorrowings.map(
      (borrowing) =>
        'loan_purpose' in borrowing
          ? findOptionName(loan_purpose, borrowing.loan_purpose)
          : { option_name: '' }
    );

    const mainMonthlyPaymentBorrower = monthlyPaymentBorrowings.map(
      (borrowing) => findOptionName(borrower, borrowing.borrower)
    );

    const mainMonthlyPaymentScheduledLoanPayoff = monthlyPaymentBorrowings.map(
      (borrowing) =>
        'scheduled_loan_payoff' in borrowing
          ? findOptionName(
              scheduled_loan_payoff,
              borrowing.scheduled_loan_payoff
            )
          : { option_name: '' }
    );

    return step_synthesis.form.main_p_applicant_person.monthly_payment ===
      LoanBalanceType.YES
      ? monthlyPaymentBorrowings
          .map((borrow, index) => [
            {
              name: `${checkLabel(index)} 資金使途（目的）`,
              value: mainMonthlyPaymentLoanPurpose[index]?.option_name ?? 'ー',
            },
            ...((borrow as PBorrowingAttribute).loan_purpose ===
            LoanPurposeType.OTHERS
              ? [
                  {
                    name: `${checkLabel(
                      index
                    )} 資金使途（その他）を選択した場合は具体的内容を記入してください`,
                    value:
                      (borrow as PBorrowingAttribute).other_purpose || 'ー',
                  },
                ]
              : []),
            {
              name: `${checkLabel(index)} 借入先（金融機関名）`,
              value: (borrow as PBorrowingAttribute).lender || 'ー',
            },
            {
              name: `${checkLabel(index)} 借入名義人`,
              value: mainMonthlyPaymentBorrower[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 当初借入額`,
              value: (borrow as PBorrowingAttribute).loan_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute).loan_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 現在の残高`,
              value: (borrow as PBorrowingAttribute).current_balance_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute).current_balance_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 住宅金融支援機構からの借入`,
              value:
                borrowingFromHousingFinanceAgency[
                  (borrow as PBorrowingAttribute)
                    .borrowing_from_housing_finance_agency
                ] || 'ー',
            },
            {
              name: `${checkLabel(index)} 年間返済額`,
              value: (borrow as PBorrowingAttribute).annual_repayment_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute).annual_repayment_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 当初借入年月`,
              value: formatJapanDate(borrow.loan_start_date) || 'ー',
            },
            {
              name: `${checkLabel(index)} 最終期限`,
              value:
                ('loan_deadline_date' in borrow &&
                  formatJapanDate(borrow.loan_deadline_date)) ||
                'ー',
            },
            {
              name: `${checkLabel(index)} 担保`,
              value:
                scheduledCompletionOfBorrowing[
                  (borrow as PBorrowingAttribute).guaranteed_status
                ] || 'ー',
            },
            {
              name: `${checkLabel(index)} 契約手続き前までの完済（予定）有無`,
              value:
                mainMonthlyPaymentScheduledLoanPayoff[index]?.option_name ??
                'ー',
            },
            {
              name: `${checkLabel(index)} 完済（予定）年月`,
              value:
                formatJapanDate(
                  (borrow as PBorrowingAttribute).expected_repayment_date
                ) || 'ー',
            },
          ])
          .flat()
      : [];
  };

  const cardLoanMCJ = () => {
    const cardLoanBorrowings =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.filter(
        (borrow) =>
          borrow.borrowing_type === BorrowingType.CARD_LOAN && !borrow._destroy
      );

    const mainCardLoanBorrower = cardLoanBorrowings.map((borrowing) =>
      findOptionName(borrower, borrowing.borrower)
    );

    const mainCardLoanLoanPurpose =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.map(
        (borrowing) =>
          'loan_purpose' in borrowing
            ? findOptionName(loan_purpose, borrowing.loan_purpose)
            : { option_name: '' }
      );

    const mainCardLoanScheduledLoanPayoff = cardLoanBorrowings.map(
      (borrowing) =>
        'scheduled_loan_payoff' in borrowing
          ? findOptionName(
              scheduled_loan_payoff,
              borrowing.scheduled_loan_payoff
            )
          : { option_name: '' }
    );

    return step_synthesis.form.main_p_applicant_person.card_loan ===
      LoanBalanceType.YES
      ? cardLoanBorrowings
          .map((borrow, index) => [
            {
              name: `${checkLabel(index)} 借入先（金融機関名）`,
              value: (borrow as PBorrowingAttribute2).lender || 'ー',
            },
            {
              name: `${checkLabel(index)} 借入名義人`,
              value: mainCardLoanBorrower[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 借入区分`,
              value:
                borrowingCategory[
                  (borrow as PBorrowingAttribute2).borrowing_category
                ] || 'ー',
            },
            {
              name: `${checkLabel(index)} 資金使途（目的）`,
              value: mainCardLoanLoanPurpose[index]?.option_name ?? 'ー',
            },
            ...((borrow as PBorrowingAttribute2).loan_purpose ===
            LoanPurposeType.OTHERS
              ? [
                  {
                    name: `${checkLabel(
                      index
                    )} 資金使途（その他）を選択した場合は具体的内容を記入してください`,
                    value:
                      (borrow as PBorrowingAttribute2).other_purpose || 'ー',
                  },
                ]
              : []),
            {
              name: `${checkLabel(index)} カード契約年月日`,
              value: formatJapanDate(borrow.loan_start_date) || 'ー',
            },
            {
              name: `${checkLabel(index)} 借入限度額`,
              value: (borrow as PBorrowingAttribute2).loan_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute2).loan_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 現在の残高`,
              value: (borrow as PBorrowingAttribute2).current_balance_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute2).current_balance_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 年間返済額`,
              value: (borrow as PBorrowingAttribute2).annual_repayment_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute2).annual_repayment_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 契約手続き前までの完済（予定）有無`,
              value:
                mainCardLoanScheduledLoanPayoff[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 完済（予定）年月`,
              value:
                formatJapanDate(
                  (borrow as PBorrowingAttribute2).expected_repayment_date
                ) || 'ー',
            },
          ])
          .flat()
      : [];
  };

  const rentedHouseMCJ = () => {
    const rentedHouseBorrowings =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.filter(
        (borrow) =>
          borrow.borrowing_type === BorrowingType.RENTED_HOUSE &&
          !borrow._destroy
      );

    const mainRentedHouseBorrower = rentedHouseBorrowings.map((borrowing) =>
      findOptionName(borrower, borrowing.borrower)
    );

    const mainCardLoanScheduledLoanPayoff = rentedHouseBorrowings.map(
      (borrowing) =>
        'scheduled_loan_payoff' in borrowing
          ? findOptionName(
              scheduled_loan_payoff,
              borrowing.scheduled_loan_payoff
            )
          : { option_name: '' }
    );

    return step_synthesis.form.main_p_applicant_person.rented_house ===
      LoanBalanceType.YES
      ? rentedHouseBorrowings
          .map((borrow, index) => [
            {
              name: `${checkLabel(index)} 借入先（金融機関名）`,
              value: (borrow as PBorrowingAttribute3).lender || 'ー',
            },
            {
              name: `${checkLabel(index)} 借入名義人`,
              value: mainRentedHouseBorrower[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 当初借入日`,
              value: formatJapanDate(borrow.loan_start_date) || 'ー',
            },
            {
              name: `${checkLabel(index)} 当初借入金額`,
              value: (borrow as PBorrowingAttribute3).loan_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute3).loan_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 現在の残高`,
              value: (borrow as PBorrowingAttribute3).current_balance_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute3).current_balance_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 年間返済額`,
              value: (borrow as PBorrowingAttribute3).annual_repayment_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute3).annual_repayment_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 最終返済年月`,
              value:
                ('loan_deadline_date' in borrow &&
                  formatJapanDate(borrow.loan_deadline_date)) ||
                'ー',
            },
            {
              name: `${checkLabel(index)} 賃貸戸（室）数`,
              value:
                (borrow as PBorrowingAttribute3).rental_room_number || 'ー',
            },
            {
              name: `${checkLabel(index)} 共同住宅`,
              value:
                commonHousing[
                  (borrow as PBorrowingAttribute3).common_housing
                ] ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 不動産担保設定`,
              value:
                estateMortgage[
                  (borrow as PBorrowingAttribute3).estate_mortgage
                ] ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 契約手続き前までの完済（予定）有無`,
              value:
                mainCardLoanScheduledLoanPayoff[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 完済（予定）年月`,
              value:
                formatJapanDate(
                  (borrow as PBorrowingAttribute3).expected_repayment_date
                ) || 'ー',
            },
          ])
          .flat()
      : [];
  };

  const businessLoanMCJ = () => {
    const businessLoanBorrowings =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.filter(
        (borrow) =>
          borrow.borrowing_type === BorrowingType.BUSINESS_LOAN &&
          !borrow._destroy
      );
    const mainBusinessLoanBorrower = businessLoanBorrowings.map((borrowing) =>
      findOptionName(borrower, borrowing.borrower)
    );

    const mainBusinessLoanRepaymentMethod = businessLoanBorrowings.map(
      (borrowing) =>
        'repayment_borrowing_method' in borrowing
          ? findOptionName(
              repayment_borrowing_method,
              borrowing.repayment_borrowing_method
            )
          : { option_name: '' }
    );

    const mainBusinessLoanBorrowingType = businessLoanBorrowings.map(
      (borrowing) =>
        'business_borrowing_type' in borrowing
          ? findOptionName(
              business_borrowing_type,
              borrowing.business_borrowing_type
            )
          : { option_name: '' }
    );
    return step_synthesis.form.main_p_applicant_person.business_loan ===
      LoanBalanceType.YES
      ? businessLoanBorrowings
          .map((borrow, index) => [
            {
              name: `${checkLabel(index)} 借入先（金融機関名）`,
              value: (borrow as PBorrowingAttribute4).lender || 'ー',
            },
            {
              name: `${checkLabel(index)} 借入名義人`,
              value: mainBusinessLoanBorrower[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 借入金種類`,
              value: mainBusinessLoanBorrowingType[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 借入金用途（具体的に）`,
              value:
                (borrow as PBorrowingAttribute4).specific_loan_purpose || 'ー',
            },
            {
              name: `${checkLabel(index)} 当初借入日`,
              value:
                handleFormatDateDiff(
                  (borrow as PBorrowingAttribute4).loan_start_date
                ) || 'ー',
            },
            {
              name: ` ${checkLabel(index)} 当初借入金額`,
              value: (borrow as PBorrowingAttribute4).loan_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute4).loan_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 現在の残高`,
              value: (borrow as PBorrowingAttribute4).current_balance_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute4).current_balance_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 年間返済額`,
              value: (borrow as PBorrowingAttribute4).annual_repayment_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttribute4).annual_repayment_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 不動産担保設定`,
              value:
                estateMortgage[
                  (borrow as PBorrowingAttribute4).estate_mortgage
                ] || 'ー',
            },
            {
              name: `${checkLabel(index)} 返済方法`,
              value: `${
                mainBusinessLoanRepaymentMethod[index]?.option_name ?? 'ー'
              }\n${(borrow as PBorrowingAttribute4).installment_repayment}`,
            },
          ])
          .flat()
      : [];
  };

  const sbiBorrowings = () => {
    const mainSBIBorrower =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.map(
        (borrowing) => findOptionName(borrower, borrowing.borrower)
      );

    const mainSBILoanPurpose =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.map(
        (borrowing) =>
          'loan_purpose' in borrowing
            ? findOptionName(loan_purpose, borrowing.loan_purpose)
            : { option_name: '' }
      );

    const mainSBIScheduledLoanPayoff =
      step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.map(
        (borrowing) =>
          'scheduled_loan_payoff' in borrowing
            ? findOptionName(
                scheduled_loan_payoff,
                borrowing.scheduled_loan_payoff
              )
            : { option_name: '' }
      );

    return step_synthesis.form.main_p_applicant_person.has_borrowings ===
      LoanBalanceType.YES
      ? step_synthesis.form.main_p_applicant_person.p_borrowings_attributes
          .filter((borrow) => !borrow._destroy)
          .map((borrow, index) => [
            {
              name: `${checkLabel(index)} 資金使途（目的）`,
              value: mainSBILoanPurpose[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 借入先（金融機関名）`,
              value: borrow.lender || 'ー',
            },
            {
              name: ` ${checkLabel(index)} 借入名義人`,
              value: mainSBIBorrower[index]?.option_name ?? 'ー',
            },
            {
              name: `${checkLabel(index)} 当初借入額`,
              value: borrow.loan_amount
                ? `${convertYenToMan([borrow.loan_amount])[0]}万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 現在の残高`,
              value: (borrow as PBorrowingAttributeSBI).current_balance_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttributeSBI).current_balance_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            {
              name: `${checkLabel(index)} 年間返済額`,
              value: (borrow as PBorrowingAttributeSBI).annual_repayment_amount
                ? `${
                    convertYenToMan([
                      (borrow as PBorrowingAttributeSBI)
                        .annual_repayment_amount,
                    ])[0]
                  }万円`
                : 'ー',
            },
            ...((borrow as PBorrowingAttributeSBI).loan_purpose ===
            LoanPurposeType.OTHERS
              ? [
                  {
                    name: `${checkLabel(
                      index
                    )} 資金使途（その他）を選択した場合は具体的内容を記入してください`,
                    value:
                      (borrow as PBorrowingAttributeSBI).other_purpose || 'ー',
                  },
                ]
              : []),
            {
              name: `${checkLabel(index)} 当初借入年月`,
              value: formatJapanDate(borrow.loan_start_date) || 'ー',
            },
            {
              name: `${checkLabel(index)} 最終期限`,
              value:
                ('loan_deadline_date' in borrow &&
                  formatJapanDate(borrow.loan_deadline_date)) ||
                'ー',
            },
            {
              name: `${checkLabel(index)} お借入までの完済予定`,
              value: mainSBIScheduledLoanPayoff[index]?.option_name ?? 'ー',
            },
          ])
          .flat()
      : [];
  };

  const mainBorrowings = () => [
    {
      label: Title.CURRENT_LOAN,
      data: [
        ...(!isMCJ ? sbiBorrowings() : []),
        ...(isMCJ ? monthlyPaymentMCJ() : []),
        ...(isMCJ ? cardLoanMCJ() : []),
        ...(isMCJ ? rentedHouseMCJ() : []),
        ...(isMCJ ? businessLoanMCJ() : []),
        ...(isMCJ
          ? step_synthesis.form.main_p_applicant_person.p_borrowings_attributes.some(
              (e) =>
                'scheduled_loan_payoff' in e &&
                [
                  ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID,
                  ScheduledLoanPayoffType.SCHEDULED_TO_BE_PAID_OFF,
                ].includes(e.scheduled_loan_payoff)
            )
            ? [
                {
                  name: '完済原資（複数選択可）',
                  value: completelyRepaymentType
                    .map(
                      (completely_repayment_type) =>
                        completely_repayment_type.option_name
                    )
                    .join(','),
                },
                ...(step_synthesis.form.p_application_header.completely_repayment_type.includes(
                  FundsPairOffType.OTHERS
                )
                  ? [
                      {
                        name: '完済原資（その他）を選択した場合は具体的な内容を記入してください',
                        value:
                          step_synthesis.form.p_application_header
                            .other_complete_repayment_type,
                      },
                    ]
                  : []),
                {
                  name: '完済原資の内容（金融機関・貯金種類など）',
                  value:
                    step_synthesis.form.p_application_header.refund_content,
                },
                {
                  name: '完済原資の金額合計',
                  value: `${step_synthesis.form.p_application_header.refund_amount}万円`,
                },
              ]
            : []
          : []),
        ...(isMCJ
          ? [
              {
                name: '支払地代（今回の住宅取得後も継続するもの）',
                value: `${step_synthesis.form.p_application_header.house_rent_to_be_paid}円`,
              },
              {
                name: '支払家賃（今回の住宅取得後も継続するもの',
                value: `${step_synthesis.form.p_application_header.land_rent_to_be_paid}円`,
              },
            ]
          : []),
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const mainLastYearIncomeMCJ = () => [
    {
      name: '前年度収入',
      value: `${step_synthesis.form.main_p_applicant_person.last_year_income}円`,
    },
    {
      name: '内、ボーナス',
      value: `${step_synthesis.form.main_p_applicant_person.bonus_income}円`,
    },
    {
      name: '事業所得等',
      value: `${step_synthesis.form.main_p_applicant_person.business_income}円`,
    },
    {
      name: '年金収入等',
      value: `${step_synthesis.form.main_p_applicant_person.pension_income}円`,
    },
    {
      name: '太陽光売買収入',
      value: `${step_synthesis.form.main_p_applicant_person.solar_power_income}円`,
    },
    {
      name: '合計前年度収入',
      value: `${step_synthesis.form.main_p_applicant_person.total_last_year_income}円`,
    },
    {
      name: '前々年度収入',
      value: `${step_synthesis.form.main_p_applicant_person.two_years_ago_income}円`,
    },
  ];

  const pairLastYearIncomeMCJ = () => [
    {
      name: '前年度収入',
      value: `${step_synthesis.form.pair_p_applicant_person.last_year_income}円`,
    },
    {
      name: '内、ボーナス',
      value: `${step_synthesis.form.pair_p_applicant_person.bonus_income}円`,
    },
    {
      name: '事業所得等',
      value: `${step_synthesis.form.pair_p_applicant_person.business_income}円`,
    },
    {
      name: '年金収入等',
      value: `${step_synthesis.form.pair_p_applicant_person.pension_income}円`,
    },
    {
      name: '太陽光売買収入',
      value: `${step_synthesis.form.pair_p_applicant_person.solar_power_income}円`,
    },
    {
      name: '合計前年度収入',
      value: `${step_synthesis.form.pair_p_applicant_person.total_last_year_income}円`,
    },
    {
      name: '前々年度収入',
      value: `${step_synthesis.form.pair_p_applicant_person.two_years_ago_income}円`,
    },
  ];

  const mainEmployment = () => [
    {
      label: Title.EMPLOYMENT,
      data: [
        {
          name: '勤務先名',
          value:
            step_synthesis.form.main_p_applicant_person.office_name_kanji ||
            'ー',
        },
        {
          name: '勤務先名（フリガナ）',
          value:
            step_synthesis.form.main_p_applicant_person.office_name_kana ||
            'ー',
        },
        {
          name: '勤務先郵便番号',
          value:
            step_synthesis.form.main_p_applicant_person.office_postal_code ||
            'ー',
        },
        {
          name: '都道府県（漢字）',
          value:
            step_synthesis.form.main_p_applicant_person
              .office_prefecture_kanji +
              step_synthesis.form.main_p_applicant_person.office_city_kanji +
              step_synthesis.form.main_p_applicant_person
                .office_district_kanji +
              step_synthesis.form.main_p_applicant_person
                .other_office_address_kanji || 'ー',
        },
        {
          name: '都道府県（フリガナ）',
          value:
            step_synthesis.form.main_p_applicant_person.office_prefecture_kana +
              step_synthesis.form.main_p_applicant_person.office_city_kana +
              step_synthesis.form.main_p_applicant_person.office_district_kana +
              step_synthesis.form.main_p_applicant_person
                .other_office_address_kana || 'ー',
        },
        {
          name: '勤務先　電話番号',
          value:
            step_synthesis.form.main_p_applicant_person.office_phone_number,
        },
        {
          name: '勤務先入社年月',
          value: `${formatDate(
            step_synthesis.form.main_p_applicant_person.employment_started_date
          )}  (${handleFormatDateDiff(
            step_synthesis.form.main_p_applicant_person.employment_started_date
          )})`,
        },
        {
          name: '勤務先従業員数',
          value: `${step_synthesis.form.main_p_applicant_person.number_of_employee}名`,
        },
        {
          name: '部署・事業所',
          value: step_synthesis.form.main_p_applicant_person.department,
        },
        {
          name: '業種',
          value: mainIndustry?.option_name ?? 'ー',
        },
        ...(step_synthesis.form.main_p_applicant_person.industry ===
        WorkIndustryType.OTHERS
          ? [
              {
                name: '業種（その他）',
                value:
                  step_synthesis.form.main_p_applicant_person.other_industry,
              },
            ]
          : []),
        {
          name: '職業',
          value: mainOccupation?.option_name ?? 'ー',
        },
        ...(step_synthesis.form.main_p_applicant_person.occupation ===
        OccupationType.OTHERS
          ? [
              {
                name: '職業（その他）',
                value:
                  step_synthesis.form.main_p_applicant_person.other_occupation,
              },
            ]
          : []),
        {
          name: '職種',
          value: mainOccupationDetail?.option_name ?? 'ー',
        },
        ...(step_synthesis.form.main_p_applicant_person.occupation_detail ===
        OccupationDetailType.OTHERS
          ? [
              {
                name: '職種（その他）',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .other_occupation_detail,
              },
            ]
          : []),
        ...(containsOnly([CODEBANK.SBI], step_synthesis.form.master_bank_codes)
          ? [
              {
                name: '前年度収入について',
                value:
                  `${step_synthesis.form.main_p_applicant_person.total_last_year_income}円` ||
                  'ー',
              },
            ]
          : []),
        {
          name: '収入源',
          value:
            mainIncomeSource.map((income) => income.option_name).join(',') ||
            'ー',
        },
        {
          name: '確定申告の有無について',
          value:
            finalTaxReturn[
              step_synthesis.form.main_p_applicant_person.tax_return
            ],
        },
        ...(step_synthesis.form.main_p_applicant_person.tax_return ===
        FinalTaxReturnType.YES
          ? [
              {
                name: '確定申告の有無について（「有」の場合その理由をお選びください）',
                value: mainTypeTaxReturn
                  .map((type_tax_return) => type_tax_return.option_name)
                  .join(','),
              },
            ]
          : []),
        {
          name: '産休・育休取得状況',
          value: mainMaternityStatus?.option_name ?? 'ー',
        },
        ...(step_synthesis.form.main_p_applicant_person
          .maternity_paternity_leave_status ===
          LeaveType.SCHEDULED_ACQUISITION ||
        step_synthesis.form.main_p_applicant_person
          .maternity_paternity_leave_status === LeaveType.ACQUIRING
          ? [
              {
                name: '産休・育休取得期間',
                value: `${formatJapanDate(
                  step_synthesis.form.main_p_applicant_person
                    .maternity_paternity_leave_start_time
                )}\n${formatJapanDate(
                  step_synthesis.form.main_p_applicant_person
                    .maternity_paternity_leave_end_time
                )}`,
              },
              {
                name: '復帰（予定）時期',
                value: formatJapanDate(
                  step_synthesis.form.main_p_applicant_person.back_to_work_date
                ),
              },
            ]
          : []),
        ...(isMCJ ? mainLastYearIncomeMCJ() : []),

        ...(step_synthesis.form.main_p_applicant_person.type_tax_return.includes(
          FileATaxReturnType.OTHERS
        )
          ? [
              {
                name: '確定申告「有」で「その他」をお選びの方',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .other_type_tax_return,
              },
            ]
          : []),
        ...(isMCJ
          ? [
              {
                name: '介護休取得状況',
                value: mainNursingStatus?.option_name ?? 'ー',
              },
            ]
          : []),
        {
          name: '出向（派遣）の有無',
          value:
            dispatch[
              step_synthesis.form.main_p_applicant_person.transfer_office
            ],
        },
        ...(step_synthesis.form.main_p_applicant_person.transfer_office ===
        DispatchType.YES
          ? [
              {
                name: '出向（派遣）先勤務先名',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .transfer_office_name_kanji,
              },
              {
                name: '出向（派遣）先勤務先名（フリガナ）',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .transfer_office_name_kana,
              },
              {
                name: '出向（派遣）先電話番号',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .transfer_office_phone_number,
              },
              {
                name: '出向（派遣）先勤務先郵便番号',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .transfer_office_postal_code,
              },
              {
                name: '出向（派遣）先勤務先住所',
                value: `${step_synthesis.form.main_p_applicant_person.transfer_office_prefecture_kanji} ${step_synthesis.form.main_p_applicant_person.transfer_office_city_kanji} ${step_synthesis.form.main_p_applicant_person.transfer_office_district_kanji} ${step_synthesis.form.main_p_applicant_person.transfer_office_other_address_kanji}`,
              },
              {
                name: '出向（派遣）先勤務先住所　（フリガナ）',
                value: `${step_synthesis.form.main_p_applicant_person.transfer_office_prefecture_kana} ${step_synthesis.form.main_p_applicant_person.transfer_office_city_kana} ${step_synthesis.form.main_p_applicant_person.transfer_office_district_kana} ${step_synthesis.form.main_p_applicant_person.transfer_office_other_address_kana}`,
              },
            ]
          : []),
        {
          name: '転職の有無',
          value:
            jobChange[step_synthesis.form.main_p_applicant_person.job_change],
        },
        ...(step_synthesis.form.main_p_applicant_person.job_change ===
        JobChangeType.YES
          ? [
              {
                name: '前勤務先名',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .job_change_company_name_kanji,
              },
              {
                name: '前勤務先名（フリガナ）',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .job_change_company_name_kana,
              },
              {
                name: '前勤務先勤続年月',
                value: `${step_synthesis.form.main_p_applicant_person.prev_company_year_num}年 ${step_synthesis.form.main_p_applicant_person.prev_company_month_num}ヶ月`,
              },
              {
                name: '前勤務先業種',
                value: mainPrevIndustry?.option_name ?? 'ー',
              },
              ...(step_synthesis.form.main_p_applicant_person
                .prev_company_industry === WorkIndustryType.OTHERS
                ? [
                    {
                      name: '前勤務先業種 (その他)',
                      value:
                        step_synthesis.form.main_p_applicant_person
                          .other_prev_company_industry,
                    },
                  ]
                : []),
            ]
          : []),
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const pairEmployment = () => {
    if (step_synthesis.form.p_application_header.loan_type !== LoanType.ONE)
      return [
        {
          label: Title.EMPLOYMENT,
          note: ApplicantType.PAIR,
          data: [
            {
              name: '勤務先名',
              value:
                step_synthesis.form.pair_p_applicant_person.office_name_kanji ||
                'ー',
            },
            {
              name: '勤務先名（フリガナ）',
              value:
                step_synthesis.form.pair_p_applicant_person.office_name_kana ||
                'ー',
            },
            {
              name: '勤務先郵便番号',
              value:
                step_synthesis.form.pair_p_applicant_person
                  .office_postal_code || 'ー',
            },
            {
              name: '都道府県（漢字）',
              value:
                step_synthesis.form.pair_p_applicant_person
                  .office_prefecture_kanji +
                  step_synthesis.form.pair_p_applicant_person
                    .office_city_kanji +
                  step_synthesis.form.pair_p_applicant_person
                    .office_district_kanji +
                  step_synthesis.form.pair_p_applicant_person
                    .other_office_address_kanji || 'ー',
            },
            {
              name: '都道府県（フリガナ）',
              value:
                step_synthesis.form.pair_p_applicant_person
                  .office_prefecture_kana +
                  step_synthesis.form.pair_p_applicant_person.office_city_kana +
                  step_synthesis.form.pair_p_applicant_person
                    .office_district_kana +
                  step_synthesis.form.pair_p_applicant_person
                    .other_office_address_kana || 'ー',
            },
            {
              name: '勤務先　電話番号',
              value:
                step_synthesis.form.pair_p_applicant_person.office_phone_number,
            },
            {
              name: '勤務先入社年月',
              value: `${formatDate(
                step_synthesis.form.pair_p_applicant_person
                  .employment_started_date
              )}  (${handleFormatDateDiff(
                step_synthesis.form.pair_p_applicant_person
                  .employment_started_date
              )})`,
            },
            {
              name: '勤務先従業員数',
              value: `${step_synthesis.form.pair_p_applicant_person.number_of_employee}名`,
            },
            {
              name: '部署・事業所',
              value: step_synthesis.form.pair_p_applicant_person.department,
            },
            {
              name: '業種',
              value: pairIndustry?.option_name ?? 'ー',
            },
            ...(step_synthesis.form.pair_p_applicant_person.industry ===
            WorkIndustryType.OTHERS
              ? [
                  {
                    name: '業種（その他）',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .other_industry,
                  },
                ]
              : []),
            {
              name: '職業',
              value: pairOccupation?.option_name ?? 'ー',
            },
            ...(step_synthesis.form.pair_p_applicant_person.occupation ===
            OccupationType.OTHERS
              ? [
                  {
                    name: '職業（その他）',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .other_occupation,
                  },
                ]
              : []),
            {
              name: '職種',
              value: pairOccupationDetail?.option_name ?? 'ー',
            },
            ...(step_synthesis.form.pair_p_applicant_person
              .occupation_detail === OccupationDetailType.OTHERS
              ? [
                  {
                    name: '職種（その他）',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .other_occupation_detail,
                  },
                ]
              : []),
            ...(containsOnly(
              [CODEBANK.SBI],
              step_synthesis.form.master_bank_codes
            )
              ? [
                  {
                    name: '前年度収入について',
                    value:
                      `${step_synthesis.form.pair_p_applicant_person.total_last_year_income}円` ||
                      'ー',
                  },
                ]
              : []),
            {
              name: '収入源',
              value:
                pairIncomeSource
                  .map((income) => income.option_name)
                  .join(',') || 'ー',
            },
            {
              name: '確定申告の有無について',
              value:
                finalTaxReturn[
                  step_synthesis.form.pair_p_applicant_person.tax_return
                ],
            },
            ...(step_synthesis.form.pair_p_applicant_person.tax_return ===
            FinalTaxReturnType.YES
              ? [
                  {
                    name: '確定申告の有無について（「有」の場合その理由をお選びください）',
                    value: pairTypeTaxReturn
                      .map((type_tax_return) => type_tax_return.option_name)
                      .join(','),
                  },
                ]
              : []),

            ...(isMCJ ? pairLastYearIncomeMCJ() : []),
            ...(step_synthesis.form.pair_p_applicant_person.type_tax_return.includes(
              FileATaxReturnType.OTHERS
            )
              ? [
                  {
                    name: '確定申告「有」で「その他」をお選びの方',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .other_type_tax_return,
                  },
                ]
              : []),
            {
              name: '産休・育休取得状況',
              value: pairMaternityStatus?.option_name ?? 'ー',
            },
            ...(step_synthesis.form.pair_p_applicant_person
              .maternity_paternity_leave_status ===
              LeaveType.SCHEDULED_ACQUISITION ||
            step_synthesis.form.pair_p_applicant_person
              .maternity_paternity_leave_status === LeaveType.ACQUIRING
              ? [
                  {
                    name: '産休・育休取得期間',
                    value: `${formatJapanDate(
                      step_synthesis.form.pair_p_applicant_person
                        .maternity_paternity_leave_start_time
                    )}\n${formatJapanDate(
                      step_synthesis.form.pair_p_applicant_person
                        .maternity_paternity_leave_end_time
                    )}`,
                  },
                  {
                    name: '復帰（予定）時期',
                    value: formatJapanDate(
                      step_synthesis.form.pair_p_applicant_person
                        .back_to_work_date
                    ),
                  },
                ]
              : []),
            ...(isMCJ
              ? [
                  {
                    name: '介護休取得状況',
                    value: pairNursingStatus?.option_name ?? 'ー',
                  },
                ]
              : []),
            {
              name: '出向（派遣）の有無',
              value:
                dispatch[
                  step_synthesis.form.pair_p_applicant_person.transfer_office
                ],
            },
            ...(step_synthesis.form.pair_p_applicant_person.transfer_office ===
            DispatchType.YES
              ? [
                  {
                    name: '出向（派遣）先勤務先名',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .transfer_office_name_kanji,
                  },
                  {
                    name: '出向（派遣）先勤務先名（フリガナ）',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .transfer_office_name_kana,
                  },
                  {
                    name: '出向（派遣）先電話番号',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .transfer_office_phone_number,
                  },
                  {
                    name: '出向（派遣）先勤務先郵便番号',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .transfer_office_postal_code,
                  },
                  {
                    name: '出向（派遣）先勤務先住所',
                    value: `${step_synthesis.form.pair_p_applicant_person.transfer_office_prefecture_kanji} ${step_synthesis.form.pair_p_applicant_person.transfer_office_city_kanji} ${step_synthesis.form.pair_p_applicant_person.transfer_office_district_kanji}  ${step_synthesis.form.pair_p_applicant_person.transfer_office_other_address_kanji}`,
                  },
                  {
                    name: '出向（派遣）先勤務先住所　（フリガナ）',
                    value: `${step_synthesis.form.pair_p_applicant_person.transfer_office_prefecture_kana} ${step_synthesis.form.pair_p_applicant_person.transfer_office_city_kana} ${step_synthesis.form.pair_p_applicant_person.transfer_office_district_kana} ${step_synthesis.form.pair_p_applicant_person.transfer_office_other_address_kana}`,
                  },
                ]
              : []),
            {
              name: '転職の有無',
              value:
                jobChange[
                  step_synthesis.form.pair_p_applicant_person.job_change
                ],
            },
            ...(step_synthesis.form.pair_p_applicant_person.job_change ===
            JobChangeType.YES
              ? [
                  {
                    name: '前勤務先名',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .job_change_company_name_kanji,
                  },
                  {
                    name: '前勤務先名（フリガナ）',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .job_change_company_name_kana,
                  },
                  {
                    name: '前勤務先勤続年月',
                    value: `${step_synthesis.form.pair_p_applicant_person.prev_company_year_num}年 ${step_synthesis.form.pair_p_applicant_person.prev_company_month_num}ヶ月`,
                  },
                  {
                    name: '前勤務先業種',
                    value: pairPrevIndustry?.option_name ?? 'ー',
                  },
                  ...(step_synthesis.form.pair_p_applicant_person
                    .prev_company_industry === WorkIndustryType.OTHERS
                    ? [
                        {
                          name: '前勤務先業種 (その他)',
                          value:
                            step_synthesis.form.pair_p_applicant_person
                              .other_prev_company_industry,
                        },
                      ]
                    : []),
                ]
              : []),
          ],
          path: routeNames.StepSynthesisInformation.path,
          contentButton: '編集',
        },
      ];
    return [];
  };

  const mainResidents = () => [
    {
      label: Title.RESIDENCE,
      data: [
        {
          name: '現居の居住年数',
          value: `${step_synthesis.form.main_p_applicant_person.lived_length_year_num}年 ${step_synthesis.form.main_p_applicant_person.lived_length_month_num}ヶ月`,
        },
        {
          name: '現在の住居',
          value: mainCurrentResidence?.option_name ?? 'ー',
        },
        {
          name: '住宅を必要とする理由',
          value: mainReasonAcquireHome?.option_name ?? 'ー',
        },
        {
          name: '融資対象物件の事業性',
          value: mainBusinessAbility?.option_name ?? 'ー',
        },
        {
          name: '取得する物件の居住区分',
          value: mainResidentStatus?.option_name ?? 'ー',
        },
        ...(step_synthesis.form.main_p_applicant_person.resident_status ===
        ResidentStatusType.THE_PERSON_DOES_NOT_RESIDE_OTHER
          ? [
              {
                name: '本人が居住しない理由',
                value:
                  step_synthesis.form.main_p_applicant_person
                    .non_resident_reason,
              },
            ]
          : []),
        {
          name: '居住予定者',
          value: mainPlannedCohabitant
            .map((planned_cohabitant) => planned_cohabitant.option_name)
            .join(','),
        },
        ...(step_synthesis.form.main_p_applicant_person.planned_cohabitant.includes(
          PlannedCohabitantType.CHILD
        )
          ? [
              {
                name: '子供',
                value:
                  child[
                    step_synthesis.form.main_p_applicant_person.children_number
                  ] ?? 'ー',
              },
            ]
          : []),
        ...(step_synthesis.form.main_p_applicant_person.planned_cohabitant.includes(
          PlannedCohabitantType.BROTHERS_AND_SISTERS
        )
          ? [
              {
                name: '兄弟姉妹',
                value:
                  child[
                    step_synthesis.form.main_p_applicant_person.siblings_number
                  ] ?? 'ー',
              },
            ]
          : []),
        ...(step_synthesis.form.main_p_applicant_person.planned_cohabitant.includes(
          PlannedCohabitantType.OTHERS
        )
          ? [
              {
                name: 'その他',
                value:
                  `${
                    step_synthesis.form.main_p_applicant_person
                      .other_relationship
                  } ${
                    child[
                      step_synthesis.form.main_p_applicant_person
                        .other_people_number
                    ]
                  }` ?? 'ー',
              },
            ]
          : []),
        {
          name: '居住予定者合計人数（本人を除く）',
          value: step_synthesis.form.main_p_applicant_person.housemate_number,
        },
        ...(isMCJ &&
        step_synthesis.form.main_p_applicant_person.applicant_detail_type ===
          ApplicantDetailType.MAIN
          ? mainRelatives()
          : []),
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const pairResidents = () => {
    if (step_synthesis.form.p_application_header.loan_type !== LoanType.ONE)
      return [
        {
          label: Title.RESIDENCE,
          note: ApplicantType.PAIR,
          data: [
            {
              name: '現居の居住年数',
              value: `${step_synthesis.form.pair_p_applicant_person.lived_length_year_num}年 ${step_synthesis.form.pair_p_applicant_person.lived_length_month_num}ヶ月`,
            },
            {
              name: '現在の住居',
              value: pairCurrentResidence?.option_name ?? 'ー',
            },
            {
              name: '住宅を必要とする理由',
              value: pairReasonAcquireHome?.option_name ?? 'ー',
            },
            {
              name: '融資対象物件の事業性',
              value: pairBusinessAbility?.option_name ?? 'ー',
            },
            {
              name: '取得する物件の居住区分',
              value: pairResidentStatus?.option_name ?? 'ー',
            },
            ...(step_synthesis.form.pair_p_applicant_person.resident_status ===
            ResidentStatusType.THE_PERSON_DOES_NOT_RESIDE_OTHER
              ? [
                  {
                    name: '本人が居住しない理由',
                    value:
                      step_synthesis.form.pair_p_applicant_person
                        .non_resident_reason,
                  },
                ]
              : []),
            {
              name: '居住予定者',
              value: pairPlannedCohabitant
                .map((planned_cohabitant) => planned_cohabitant.option_name)
                .join(','),
            },
            ...(step_synthesis.form.pair_p_applicant_person.planned_cohabitant.includes(
              PlannedCohabitantType.CHILD
            )
              ? [
                  {
                    name: '子供',
                    value:
                      child[
                        step_synthesis.form.pair_p_applicant_person
                          .children_number
                      ] ?? 'ー',
                  },
                ]
              : []),
            ...(step_synthesis.form.pair_p_applicant_person.planned_cohabitant.includes(
              PlannedCohabitantType.BROTHERS_AND_SISTERS
            )
              ? [
                  {
                    name: '兄弟姉妹',
                    value:
                      child[
                        step_synthesis.form.pair_p_applicant_person
                          .siblings_number
                      ] ?? 'ー',
                  },
                ]
              : []),
            ...(step_synthesis.form.pair_p_applicant_person.planned_cohabitant.includes(
              PlannedCohabitantType.OTHERS
            )
              ? [
                  {
                    name: 'その他',
                    value:
                      `${
                        step_synthesis.form.pair_p_applicant_person
                          .other_relationship
                      } ${
                        child[
                          step_synthesis.form.pair_p_applicant_person
                            .other_people_number
                        ]
                      }` ?? 'ー',
                  },
                ]
              : []),
            {
              name: '同居予定者合計人数（本人を除く）',
              value:
                step_synthesis.form.pair_p_applicant_person.housemate_number,
            },
            ...(isMCJ &&
            step_synthesis.form.pair_p_applicant_person
              .applicant_detail_type === ApplicantDetailType.PAIR
              ? pairRelatives()
              : []),
          ],
          path: routeNames.StepSynthesisInformation.path,
          contentButton: '編集',
        },
      ];
    return [];
  };

  const mainRelatives = () => [
    {
      name: 'お名前（漢字）（全角）',
      value: `${step_synthesis.form.main_p_applicant_person.p_resident_attributes.last_name_kanji} ${step_synthesis.form.main_p_applicant_person.p_resident_attributes.first_name_kanji}`,
    },
    {
      name: 'お名前（フリガナ）（全角カナ）',
      value: `${step_synthesis.form.main_p_applicant_person.p_resident_attributes.last_name_kana} ${step_synthesis.form.main_p_applicant_person.p_resident_attributes.first_name_kana}`,
    },
    {
      name: '申込人本人との関係（その他）',
      value: mainRelationship?.option_name ?? 'ー',
    },
    {
      name: '現在の国籍',
      value: mainResidentsNationality?.option_name ?? 'ー',
    },
    {
      name: '生年月日',
      value:
        formatJapanBirthday(
          step_synthesis.form.main_p_applicant_person.p_resident_attributes
            .birthday
        ) || 'ー',
    },
    {
      name: '携帯電話番号',
      value:
        step_synthesis.form.main_p_applicant_person.p_resident_attributes.mobile_phone_number?.toString() ||
        'ー',
    },
    {
      name: '固定電話番号',
      value:
        step_synthesis.form.main_p_applicant_person.p_resident_attributes.home_phone_number?.toString() ||
        'ー',
    },
    {
      name: '郵便番号',
      value:
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .postal_code,
    },
    {
      name: '都道府県（漢字）',
      value:
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .prefecture_kanji +
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .city_kanji +
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .district_kanji +
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .other_address_kanji,
    },
    {
      name: '都道府県（フリガナ）',
      value:
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .prefecture_kana +
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .city_kana +
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .district_kana +
        step_synthesis.form.main_p_applicant_person.p_resident_attributes
          .other_address_kana,
    },
    {
      name: '住宅金融支援機構（旧：公庫）からの融資の有無',
      value:
        financeAgency[
          step_synthesis.form.main_p_applicant_person.p_resident_attributes
            .loan_from_japan_housing_finance_agency
        ],
    },
  ];

  const pairRelatives = () => [
    {
      name: 'お名前（漢字）（全角）',
      value: `${step_synthesis.form.pair_p_applicant_person.p_resident_attributes.last_name_kanji} ${step_synthesis.form.pair_p_applicant_person.p_resident_attributes.first_name_kanji}`,
    },
    {
      name: 'お名前（フリガナ）（全角カナ）',
      value: `${step_synthesis.form.pair_p_applicant_person.p_resident_attributes.last_name_kana} ${step_synthesis.form.pair_p_applicant_person.p_resident_attributes.first_name_kana}`,
    },
    {
      name: '申込人本人との関係（その他）',
      value: pairRelationship?.option_name ?? 'ー',
    },
    {
      name: '現在の国籍',
      value: pairResidentNationality?.option_name ?? 'ー',
    },
    {
      name: '生年月日',
      value:
        formatJapanBirthday(
          step_synthesis.form.pair_p_applicant_person.p_resident_attributes
            .birthday
        ) || 'ー',
    },
    {
      name: '携帯電話番号',
      value:
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes.mobile_phone_number?.toString() ||
        'ー',
    },
    {
      name: '固定電話番号',
      value:
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes.home_phone_number?.toString() ||
        'ー',
    },
    {
      name: '郵便番号',
      value:
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .postal_code,
    },
    {
      name: '都道府県（漢字）',
      value:
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .prefecture_kanji +
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .city_kanji +
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .district_kanji +
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .other_address_kanji,
    },
    {
      name: '都道府県（フリガナ）',
      value:
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .prefecture_kana +
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .city_kana +
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .district_kana +
        step_synthesis.form.pair_p_applicant_person.p_resident_attributes
          .other_address_kana,
    },
    {
      name: '住宅金融支援機構（旧：公庫）からの融資の有無',
      value:
        financeAgency[
          step_synthesis.form.pair_p_applicant_person.p_resident_attributes
            .loan_from_japan_housing_finance_agency
        ],
    },
  ];

  const propertyMCJ = () => [
    {
      name: '土地権利',
      value: landOwnership?.option_name ?? 'ー',
    },
    {
      name: '買戻・保留地・仮換地',
      value: purchasePurpose?.option_name ?? 'ー',
    },
    {
      name: '都市計画区域等',
      value: planningArea?.option_name ?? 'ー',
    },
    ...(step_synthesis.form.p_application_header.planning_area ===
    PlanningAreaType.OTHERS
      ? [
          {
            name: '都市計画区域等（その他）',
            value: step_synthesis.form.p_application_header.other_planning_area,
          },
        ]
      : []),
    ...(step_synthesis.form.p_application_header.planning_area ===
      PlanningAreaType.URBANIZATION_CONTROL_AREA ||
    step_synthesis.form.p_application_header.planning_area ===
      PlanningAreaType.OUTSIDE_CITY_PLANNING_AREA
      ? [
          {
            name: '再建築理由',
            value: rebuildingReason?.option_name ?? 'ー',
          },
        ]
      : []),
    ...(step_synthesis.form.p_application_header.rebuilding_reason ===
    RebuildingReasonType.OTHERS
      ? [
          {
            name: '再建築理由（その他）',
            value:
              step_synthesis.form.p_application_header.other_rebuilding_reason,
          },
        ]
      : []),
    {
      name: '売買契約（予定）日',
      value:
        formatJapanDate(
          step_synthesis.form.p_application_header.purchase_contract_date
        ) || 'ー',
    },
    {
      name: 'フラット35S（優良住宅取得支援制度）対象項目１',
      value: flat35ApplicablePlan?.option_name ?? 'ー',
    },
    {
      name: '維持保全型',
      value: maintenance?.option_name ?? 'ー',
    },
    {
      name: '地域連携型・地方移住支援型',
      value: region?.option_name ?? 'ー',
    },
  ];

  const mainProperty = () => [
    {
      label: Title.PROPERTY,
      data: [
        {
          name: '登記簿上の所在地（必ず番地まで正確にご入力ください）',
          value: step_synthesis.form.p_application_header.collateral_address
            ? step_synthesis.form.p_application_header.collateral_address
            : 'ー',
        },
        {
          name: 'マンション名（物件名）',
          value: step_synthesis.form.p_application_header.mansion_name_kanji
            ? step_synthesis.form.p_application_header.mansion_name_kanji
            : 'ー',
        },
        ...(step_synthesis.form.p_application_header.loan_target !==
          PurposeType.THREE &&
        step_synthesis.form.p_application_header.loan_target !==
          PurposeType.FOUR
          ? [
              {
                name: '土地',
                value: `${
                  step_synthesis.form.p_application_header.collateral_land_area
                    ? step_synthesis.form.p_application_header
                        .collateral_land_area + '㎡'
                    : 'ー'
                }`,
              },
              {
                name: '建物',
                value: `${
                  step_synthesis.form.p_application_header.occupied_area
                    ? step_synthesis.form.p_application_header.occupied_area +
                      '㎡'
                    : 'ー'
                }`,
              },
            ]
          : [
              {
                name: '専有面積　',
                value: `${
                  step_synthesis.form.p_application_header.collateral_floor_area
                    ? step_synthesis.form.p_application_header
                        .collateral_floor_area + '㎡'
                    : 'ー'
                }`,
              },
              {
                name: 'マンション全体の延床面積',
                value: `${
                  step_synthesis.form.p_application_header
                    .collateral_total_floor_area
                    ? step_synthesis.form.p_application_header
                        .collateral_total_floor_area + '㎡'
                    : 'ー'
                }`,
              },
            ]),
        ...(isMCJ ? propertyMCJ() : []),
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const mainBorrowingDetail = () => [
    {
      label: Title.BORROWING,
      data: [
        {
          name: checkLoanNumber('お借入れ希望額', 0),
          value: `${
            convertYenToMan([
              step_synthesis.form.main_p_applicant_person
                .p_borrowing_detail_attributes.temporary_desired_loan_amount,
            ])[0]
          }万円`,
        },
        ...(isMCJ
          ? [
              {
                name: checkLoanNumber('ボーナス併用払い', 0),
                value: mainDesiredMonthlyBonus?.option_name ?? 'ー',
              },
            ]
          : []),
        {
          name: checkLoanNumber('内ボーナス払い（半年毎増額返済金額）', 0),
          value: `${
            convertYenToMan([
              step_synthesis.form.main_p_applicant_person
                .p_borrowing_detail_attributes.halfyear_bonus,
            ])[0]
          }万円`,
        },
        {
          name: checkLoanNumber('お借入期間', 0),
          value: `${step_synthesis.form.main_p_applicant_person.p_borrowing_detail_attributes.loan_term}年`,
        },
        {
          name: checkLoanNumber('返済方法', 0),
          value: mainRepayment?.option_name ?? 'ー',
        },
        ...(step_synthesis.form.p_application_header.has_land_advance_plan ===
        LandAdvanceType.YES
          ? [
              {
                name: checkLoanNumber('お借入希望額', 1),
                value:
                  `${
                    convertYenToMan([
                      step_synthesis.form.main_p_applicant_person
                        .p_land_advance_plan_attributes.desired_loan_amount,
                    ])[0]
                  }万円` || 'ー',
              },
              {
                name: checkLoanNumber(
                  '内ボーナス払い（半年毎増額返済金額）',
                  1
                ),
                value:
                  `${
                    convertYenToMan([
                      step_synthesis.form.main_p_applicant_person
                        .p_land_advance_plan_attributes.halfyear_bonus,
                    ])[0]
                  }万円` || 'ー',
              },
              {
                name: checkLoanNumber('借入希望日', 1),
                value:
                  formatJapanDate(
                    step_synthesis.form.main_p_applicant_person
                      .p_land_advance_plan_attributes
                      .loan_desired_borrowing_date
                  ) || 'ー',
              },
              {
                name: checkLoanNumber('お借入期間', 1),
                value:
                  `${step_synthesis.form.main_p_applicant_person.p_land_advance_plan_attributes.loan_term}年` ||
                  'ー',
              },
            ]
          : []),
        {
          name: '土地先行プラン希望',
          value:
            landAdvance[
              step_synthesis.form.p_application_header.has_land_advance_plan
            ],
        },
        {
          name: '担保提供者（共有者）の有無',
          value:
            joinGuarantor[
              step_synthesis.form.main_p_applicant_person.has_join_guarantor
            ],
        },
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const pairBorrowingDetail = () => {
    if (step_synthesis.form.p_application_header.loan_type !== LoanType.ONE)
      return [
        {
          label: Title.BORROWING,
          data: [
            {
              name: 'お借入れ希望額',
              value: `${
                convertYenToMan([
                  step_synthesis.form.pair_p_applicant_person
                    .p_borrowing_detail_attributes
                    .temporary_desired_loan_amount,
                ])[0]
              }万円`,
            },
            ...(isMCJ
              ? [
                  {
                    name: 'ボーナス併用払い',
                    value: pairDesiredMonthlyBonus?.option_name ?? 'ー',
                  },
                ]
              : []),
            {
              name: '内ボーナス払い（半年毎増額返済金額）',
              value: `${
                convertYenToMan([
                  step_synthesis.form.pair_p_applicant_person
                    .p_borrowing_detail_attributes.halfyear_bonus,
                ])[0]
              }万円`,
            },
            {
              name: 'お借入期間',
              value: `${step_synthesis.form.pair_p_applicant_person.p_borrowing_detail_attributes.loan_term}年`,
            },
            {
              name: '返済方法',
              value: pairRepayment?.option_name ?? 'ー',
            },
            {
              name: '土地先行プラン希望',
              value:
                landAdvance[
                  step_synthesis.form.p_application_header.has_land_advance_plan
                ],
            },
          ],
          path: routeNames.StepSynthesisInformation.path,
          contentButton: '編集',
        },
      ];
    return [];
  };

  const requiredFundsBreakdown = () => [
    {
      label: Title.FUNDS_BREAKDOWN,
      data: [
        ...(step_synthesis.form.p_application_header.loan_target ===
        PurposeType.SIX
          ? [
              {
                name: '土地（戸建）',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header
                      .land_purchase_price,
                  ])[0] || 0
                }万円`,
              },
            ]
          : []),
        ...(step_synthesis.form.p_application_header.loan_target ===
          PurposeType.ONE ||
        step_synthesis.form.p_application_header.loan_target ===
          PurposeType.TWO ||
        step_synthesis.form.p_application_header.loan_target ===
          PurposeType.FIVE ||
        step_synthesis.form.p_application_header.loan_target === PurposeType.SIX
          ? [
              {
                name: '建物（戸建）または建売住宅',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header
                      .house_purchase_price,
                  ])[0] || 0
                }万円`,
              },
            ]
          : []),
        ...(step_synthesis.form.p_application_header.loan_target ===
          PurposeType.THREE ||
        step_synthesis.form.p_application_header.loan_target ===
          PurposeType.FOUR
          ? [
              {
                name: 'マンション',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header
                      .house_purchase_price,
                  ])[0] || 0
                }万円`,
              },
            ]
          : []),
        ...(step_synthesis.form.p_application_header.loan_target ===
          PurposeType.FIVE ||
        step_synthesis.form.p_application_header.loan_target === PurposeType.SIX
          ? [
              {
                name: '付帯設備',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header.accessory_cost,
                  ])[0] || 0
                }万円`,
              },
            ]
          : []),
        {
          name: '諸経費等',
          value: `${
            convertYenToMan([
              step_synthesis.form.p_application_header.additional_cost,
            ])[0] || 0
          }万円`,
        },
        ...(step_synthesis.form.p_application_header.loan_target ===
          PurposeType.SEVEN ||
        step_synthesis.form.p_application_header.loan_target ===
          PurposeType.EIGHT ||
        step_synthesis.form.p_application_header.loan_target ===
          PurposeType.NINE
          ? [
              {
                name: '借換対象ローン残債',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header
                      .refinancing_loan_balance,
                  ])[0] || 0
                }万円`,
              },
            ]
          : []),
        ...(step_synthesis.form.p_application_header.loan_target ===
          PurposeType.EIGHT ||
        step_synthesis.form.p_application_header.loan_target ===
          PurposeType.NINE
          ? [
              {
                name: '増改築',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header.house_upgrade_cost,
                  ])[0] || 0
                }万円`,
              },
            ]
          : []),
        {
          name: 'その他',
          value: `${
            step_synthesis.form.p_application_header
              .other_require_funds_breakdown_content || 'ー'
          } \n ${
            convertYenToMan([
              step_synthesis.form.p_application_header
                .other_require_funds_breakdown,
            ])[0] || 0
          }万円`,
        },
        {
          name: '合計',
          value: `${
            convertYenToMan([
              step_synthesis.form.p_application_header.total_require_funds,
            ])[0] || 0
          }万円`,
        },
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const procurementBreakdown = () => [
    {
      label: Title.PROCUREMENT_BREAKDOWN,
      note: ApplicantType.MAIN,
      data: [
        ...(isMCJ
          ? [
              {
                name: '預貯金（金融機関1）',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header.deposit_savings_1,
                  ])[0] || 0
                }万円`,
              },
              {
                name: '預貯金（金融機関2）',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header.deposit_savings_2,
                  ])[0] || 0
                }万円`,
              },
              {
                name: '不動産売却代金',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header
                      .real_estate_sale_price,
                  ])[0] || 0
                }万円`,
              },
              {
                name: 'その他（有価証券等）',
                value: `${
                  convertYenToMan([
                    step_synthesis.form.p_application_header
                      .other_saving_amount,
                  ])[0] || 0
                }万円`,
              },
            ]
          : []),
        {
          name: '自己資金',
          value: `${
            convertYenToMan([
              step_synthesis.form.p_application_header.saving_amount,
            ])[0] || 0
          }万円`,
        },
        {
          name: '親族からの贈与',
          value: `${
            convertYenToMan([
              step_synthesis.form.p_application_header.relative_donation_amount,
            ])[0] || 0
          }万円`,
        },
        {
          name: '本件ローン',
          value: `${
            convertYenToMan([
              step_synthesis.form.p_application_header.loan_amount,
            ])[0] || 0
          }万円`,
        },
        {
          name: 'ペアローン',
          value: `${
            convertYenToMan([
              step_synthesis.form.p_application_header.pair_loan_amount,
            ])[0] || 0
          }万円`,
        },
        {
          name: 'その他',
          value: `${
            step_synthesis.form.p_application_header
              .other_procurement_breakdown_content
          } \n ${
            convertYenToMan([
              step_synthesis.form.p_application_header
                .other_procurement_breakdown,
            ])[0] || 0
          }万円`,
        },
        {
          name: '合計',
          value: `${
            convertYenToMan([
              step_synthesis.form.p_application_header.total_procurement,
            ])[0] || 0
          }万円`,
        },
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const partnerCompanies = () => [
    {
      label: Title.PARTNER_COMPANIES,
      data: [
        {
          name: '自己資金',
          value: step_synthesis.form.p_referral_agency.name_kanji || 'ー',
        },
        {
          name: '支店名',
          value: step_synthesis.form.p_referral_agency.store_name_kanji || 'ー',
        },
        {
          name: '担当者名',
          value:
            step_synthesis.form.p_referral_agency.manager_name_kanji || 'ー',
        },
        {
          name: '販売代理会社（該当する場合）',
          value:
            step_synthesis.form.p_referral_agency.sale_agent_name_kanji || 'ー',
        },
        {
          name: '担当者直通番号',
          value: step_synthesis.form.p_referral_agency.phone_number_2 || 'ー',
        },
        {
          name: '担当者携帯番号',
          value: step_synthesis.form.p_referral_agency.phone_number || 'ー',
        },
        {
          name: '担当者メールアドレス',
          value: step_synthesis.form.p_referral_agency.manager_email || 'ー',
        },
      ],
      path: routeNames.StepSynthesisInformation.path,
      contentButton: '編集',
    },
  ];

  const mainData: FormikFormData[] = [
    ...requiredInformation(),
    ...mainInformation(),
    ...mainEmployment(),
    ...mainResidents(),
    ...mainBorrowingDetail(),
    ...mainProperty(),
  ];

  const pairData: FormikFormData[] = [
    ...pairInformation(),
    ...pairEmployment(),
    ...pairResidents(),
    ...pairBorrowingDetail(),
  ];

  if (step_synthesis.form.p_application_header.loan_type !== LoanType.ONE) {
    pairData.push(
      ...requiredFundsBreakdown(),
      ...procurementBreakdown(),
      ...mainBorrowings(),
      ...partnerCompanies()
    );
  } else {
    mainData.push(
      ...requiredFundsBreakdown(),
      ...procurementBreakdown(),
      ...mainBorrowings(),
      ...partnerCompanies()
    );
  }

  return {
    success: true,
    data: {
      main: mainData,
      pair: pairData,
    },
  };
});

export const submitFormData = createAsyncThunk<
  SubmitFormResponse,
  FormData,
  ThunkAPI<SubmitFormError>
>('SubmitFormButton/SubmitForm', async (data, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.submitForm(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUploadedFiles = createAsyncThunk<
  UpdatePreliminariesResponse,
  UpdatePreliminariesRequest,
  ThunkAPI<UpdatePreliminariesError>
>('Preliminaries/UpdateUploadedFiles', async (data, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.updateUploadedFiles(
      data
    );
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
