import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowDown: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 9" {...props}>
      <svg
        width="17"
        height="9"
        viewBox="0 0 17 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 1.16675L8.5 7.00008L1.5 1.16675"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};
