import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Home: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.64286 17.1142H5.87143V10.4H11.1286V17.1142H15.3571V6.82852L8.5 1.62852L1.64286 6.82852V17.1142ZM0.5 18.2571V6.25709L8.5 0.199951L16.5 6.25709V18.2571H9.98571V11.5428H7.01429V18.2571H0.5Z"
        fill="#6B70F0"
      />
    </svg>
  </SvgIcon>
);
