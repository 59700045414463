import {
  ClipboardEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Link, Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { SPArrowForward } from 'components/atoms/icons';
import {
  Button,
  Icons,
  SPFormItem,
  SPImage,
  SPInputField,
  SPLoginCompleteModal,
} from 'components';
import { theme } from 'styles';
import { useAppDispatch, useCheckCurrentStep, useSubmittedImages } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { ImageContext } from 'context';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { getCurrentPath } from 'utils';
import { SPGetDraftData } from 'types';
import { spCurrentStepSelector } from 'containers/AuthModal/selectors';
import { useSelector } from 'react-redux';
import {
  resetAuth,
  setCurrentStep,
  setFirstTimeLogin,
} from 'containers/AuthModal/slice';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
} from 'pages/SPStepOne/selectors';
import { autoFillEmail } from 'pages/SPStepTwo/slice';
import { addStatus } from 'pages/SPStepFourIncomeTotalizer/slice';
import { GeneralIncomeConfirmation } from 'constant';
import { LoginError } from 'services';
import { dayjs } from 'libs/dayjs';
import { saveLoginEmail } from '../SPAccountInformation/slice';
import {
  getDynamicOptions,
  getMasterBanks,
} from '../StepSynthesisInformation/thunk';
import { validationSchema } from './validationSchema';
import { spGetSubmittedPreliminary, spLogin } from './thunk';

const SPLoginPage: FC = () => {
  const dispatch = useAppDispatch();
  const currentStep = useSelector(spCurrentStepSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const {
    setResidenceFile,
    setResidenceFileBackImage,
    setResidenceIncomeFile,
    setResidenceIncomeFileBackImage,
    setPropertyInformationFile,
    setRepaymentScheduleImage,
    setDriverLicenseBackImage,
    setDriverLicenseFrontImage,
    setCardNumberBackImage,
    setCardNumberFrontImage,
    setInsuranceFile,
    setInsuranceFileBackImage,
    setFinancialStatement1File,
    setFinancialStatement2File,
    setFinancialStatement3File,
    setFirstWithholdingSlipFile,
    setSecondWithholdingSlipFile,
    setOtherDocumentFile,
    setEmploymentAgreementFile,
    setBusinessTaxReturn1File,
    setBusinessTaxReturn2File,
    setBusinessTaxReturn3File,
    setFirstIncomeFile,
    setSecondIncomeFile,
    setThirdIncomeFile,
    setResidentRegisterFrontImage,
    setResidentRegisterBackImage,
    setDriverLicenseBackImage2,
    setDriverLicenseFrontImage2,
    setCardNumberBackImage2,
    setCardNumberFrontImage2,
    setInsuranceFile2,
    setInsuranceFileBackImage2,
    setFinancialStatement1File2,
    setFinancialStatement2File2,
    setFinancialStatement3File2,
    setFirstWithholdingSlipFile2,
    setSecondWithholdingSlipFile2,
    setOtherDocumentFile2,
    setEmploymentAgreementFile2,
    setBusinessTaxReturn1File2,
    setBusinessTaxReturn2File2,
    setBusinessTaxReturn3File2,
    setFirstIncomeFile2,
    setSecondIncomeFile2,
    setThirdIncomeFile2,
    setResidentRegisterFrontImage2,
    setResidentRegisterBackImage2,
    setBusinessCard,
  } = useContext(ImageContext);
  const [openSuccessLogin, setOpenSuccessLogin] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const checkCurrentStep = useCheckCurrentStep();
  const handleSubmittedImages = useSubmittedImages();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    async onSubmit(user) {
      dispatch(resetAuth());
      const result = await dispatch(
        spLogin({
          user: { email: user.email, password: user.password },
        })
      );
      if (!spLogin.fulfilled.match(result)) {
        const errorResponse = result.payload as LoginError;
        if (errorResponse.errors.every((e) => e.key !== 'locked')) {
          setErrorMessage('メールアドレスまたはパスワードが正しくありません。');
          setError(true);
        } else {
          setErrorMessage(
            `ログイン失敗でアカウントがロックされました。\nアカウントロックの解除は、ログイン画面の「パスワードを忘れた方はこちらから設定をお願いします」からお進みください。`
          );
          setError(true);
        }
      }
      if (spLogin.fulfilled.match(result)) {
        dispatch(getMasterBanks());
        dispatch(getDynamicOptions());
        dispatch(saveLoginEmail(user.email));
        if (
          !result.payload.data.draft_exits &&
          !result.payload.data.master_bank_codes
        ) {
          dispatch(autoFillEmail(user.email));
          dispatch(
            addStatus({
              firstElement: undefined,
              secondElement: undefined,
              isReseted: true,
            })
          );
          navigate(routeNames.StartPage.path, { replace: true });
          return navigate(routeNames.SPAgreement.path);
        }
        if (
          result.payload.data.draft_exits &&
          !result.payload.data.application_number
        ) {
          const result = await dispatch(spGetDraft());
          if (spGetDraft.fulfilled.match(result)) {
            const draftData = result.payload.data;
            dispatch(setCurrentStep(draftData.current_step));
            dispatch(
              addStatus({
                firstElement:
                  draftData.p_application_header.general_income_confirmation ===
                  GeneralIncomeConfirmation.CONFIRM
                    ? true
                    : undefined,
                secondElement:
                  draftData.p_application_header.general_income_confirmation ===
                  GeneralIncomeConfirmation.CONFIRM
                    ? true
                    : undefined,
                isReseted: !draftData.p_application_header
                  .general_income_confirmation
                  ? true
                  : draftData.p_application_header
                      .general_income_confirmation ===
                    GeneralIncomeConfirmation.NOT_CONFIRM,
              })
            );
            handleDraftImages(draftData);
            setOpenSuccessLogin(true);
          }
        }
        //user has submitted preliminary
        if (!!result.payload.data.application_number) {
          const submittedPreliminary = await dispatch(
            spGetSubmittedPreliminary()
          );
          if (spGetSubmittedPreliminary.fulfilled.match(submittedPreliminary)) {
            const preliminaryData = submittedPreliminary.payload.data;
            dispatch(
              addStatus({
                firstElement:
                  preliminaryData.general_income_confirmation ===
                  GeneralIncomeConfirmation.CONFIRM
                    ? true
                    : undefined,
                secondElement:
                  preliminaryData.general_income_confirmation ===
                  GeneralIncomeConfirmation.CONFIRM
                    ? true
                    : undefined,
                isReseted:
                  preliminaryData.general_income_confirmation ===
                  GeneralIncomeConfirmation.NOT_CONFIRM,
              })
            );
            checkCurrentStep(
              preliminaryData,
              result.payload.data.status_result
            );
            handleSubmittedImages(preliminaryData);
            navigate(routeNames.Top.path, {
              state: { timeCallAPi: dayjs().unix() },
            });
          }
        }
      }
    },
  });

  /* STEP 7 */

  const handleDraftImages = useCallback(
    (data: SPGetDraftData) => {
      if (data.p_applicant_people[0].residence_file?.length) {
        setResidenceFile(
          data.p_applicant_people[0].residence_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].residence_file_back_image?.length) {
        setResidenceFileBackImage(
          data.p_applicant_people[0].residence_file_back_image.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_application_header.property_information_file?.length) {
        setPropertyInformationFile(
          data.p_application_header.property_information_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_application_header.business_card?.length) {
        setBusinessCard(
          data.p_application_header.business_card.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }

      if (data.p_applicant_people[0].insurance_file?.length) {
        setInsuranceFile(
          data.p_applicant_people[0].insurance_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].insurance_file_back_image?.length) {
        setInsuranceFileBackImage(
          data.p_applicant_people[0].insurance_file_back_image.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].financial_statement_1_file?.length) {
        setFinancialStatement1File(
          data.p_applicant_people[0].financial_statement_1_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].financial_statement_2_file?.length) {
        setFinancialStatement2File(
          data.p_applicant_people[0].financial_statement_2_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].financial_statement_3_file?.length) {
        setFinancialStatement3File(
          data.p_applicant_people[0].financial_statement_3_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].first_withholding_slip_file?.length) {
        setFirstWithholdingSlipFile(
          data.p_applicant_people[0].first_withholding_slip_file.map(
            (file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            })
          )
        );
      }
      if (data.p_applicant_people[0].second_withholding_slip_file?.length) {
        setSecondWithholdingSlipFile(
          data.p_applicant_people[0].second_withholding_slip_file.map(
            (file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            })
          )
        );
      }
      if (data.p_applicant_people[0].other_document_file?.length) {
        setOtherDocumentFile(
          data.p_applicant_people[0].other_document_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].employment_agreement_file?.length) {
        setEmploymentAgreementFile(
          data.p_applicant_people[0].employment_agreement_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].business_tax_return_1_file?.length) {
        setBusinessTaxReturn1File(
          data.p_applicant_people[0].business_tax_return_1_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].business_tax_return_2_file?.length) {
        setBusinessTaxReturn2File(
          data.p_applicant_people[0].business_tax_return_2_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].business_tax_return_3_file?.length) {
        setBusinessTaxReturn3File(
          data.p_applicant_people[0].business_tax_return_3_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].first_income_file?.length) {
        setFirstIncomeFile(
          data.p_applicant_people[0].first_income_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].second_income_file?.length) {
        setSecondIncomeFile(
          data.p_applicant_people[0].second_income_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].third_income_file?.length) {
        setThirdIncomeFile(
          data.p_applicant_people[0].third_income_file.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
      if (data.p_applicant_people[0].driver_license_front_image) {
        setDriverLicenseFrontImage([
          {
            id: data.p_applicant_people[0].driver_license_front_image.id,
            url: data.p_applicant_people[0].driver_license_front_image.url,
            name: data.p_applicant_people[0].driver_license_front_image
              .filename,
            hiden: data.p_applicant_people[0].driver_license_front_image.hiden,
          },
        ]);
      }
      if (data.p_applicant_people[0].driver_license_back_image) {
        setDriverLicenseBackImage([
          {
            id: data.p_applicant_people[0].driver_license_back_image.id,
            url: data.p_applicant_people[0].driver_license_back_image.url,
            name: data.p_applicant_people[0].driver_license_back_image.filename,
            hiden: data.p_applicant_people[0].driver_license_back_image.hiden,
          },
        ]);
      }
      if (data.p_applicant_people[0].card_number_front_image) {
        setCardNumberFrontImage([
          {
            id: data.p_applicant_people[0].card_number_front_image.id,
            url: data.p_applicant_people[0].card_number_front_image.url,
            name: data.p_applicant_people[0].card_number_front_image.filename,
            hiden: data.p_applicant_people[0].card_number_front_image.hiden,
          },
        ]);
      }
      // if (data.p_applicant_people[0].card_number_back_image) {
      //   setCardNumberBackImage([
      //     {
      //       id: data.p_applicant_people[0].card_number_back_image.id,
      //       url: data.p_applicant_people[0].card_number_back_image.url,
      //       name: data.p_applicant_people[0].card_number_back_image.filename,
      //       hiden: data.p_applicant_people[0].card_number_back_image.hiden,
      //     },
      //   ]);
      // }
      if (data.p_applicant_people[0].resident_register_front_image) {
        setResidentRegisterFrontImage([
          {
            id: data.p_applicant_people[0].resident_register_front_image.id,
            url: data.p_applicant_people[0].resident_register_front_image.url,
            name: data.p_applicant_people[0].resident_register_front_image
              .filename,
            hiden:
              data.p_applicant_people[0].resident_register_front_image.hiden,
          },
        ]);
      }
      if (data.p_applicant_people[0].resident_register_back_image) {
        setResidentRegisterBackImage([
          {
            id: data.p_applicant_people[0].resident_register_back_image.id,
            url: data.p_applicant_people[0].resident_register_back_image.url,
            name: data.p_applicant_people[0].resident_register_back_image
              .filename,
            hiden:
              data.p_applicant_people[0].resident_register_back_image.hiden,
          },
        ]);
      }

      /* STEP 7 INCOME TOTAL */
      if (!!data.p_applicant_people[1]) {
        if (data.p_applicant_people[1].insurance_file?.length) {
          setInsuranceFile2(
            data.p_applicant_people[1].insurance_file.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        }
        if (data.p_applicant_people[1].insurance_file_back_image?.length) {
          setInsuranceFileBackImage2(
            data.p_applicant_people[1].insurance_file_back_image.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].financial_statement_1_file?.length) {
          setFinancialStatement1File2(
            data.p_applicant_people[1].financial_statement_1_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].financial_statement_2_file?.length) {
          setFinancialStatement2File2(
            data.p_applicant_people[1].financial_statement_2_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].financial_statement_3_file?.length) {
          setFinancialStatement3File2(
            data.p_applicant_people[1].financial_statement_3_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].first_withholding_slip_file?.length) {
          setFirstWithholdingSlipFile2(
            data.p_applicant_people[1].first_withholding_slip_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].second_withholding_slip_file?.length) {
          setSecondWithholdingSlipFile2(
            data.p_applicant_people[1].second_withholding_slip_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].other_document_file?.length) {
          setOtherDocumentFile2(
            data.p_applicant_people[1].other_document_file.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        }
        if (data.p_applicant_people[1].employment_agreement_file?.length) {
          setEmploymentAgreementFile2(
            data.p_applicant_people[1].employment_agreement_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].business_tax_return_1_file?.length) {
          setBusinessTaxReturn1File2(
            data.p_applicant_people[1].business_tax_return_1_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].business_tax_return_2_file?.length) {
          setBusinessTaxReturn2File2(
            data.p_applicant_people[1].business_tax_return_2_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].business_tax_return_3_file?.length) {
          setBusinessTaxReturn3File2(
            data.p_applicant_people[1].business_tax_return_3_file.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
        if (data.p_applicant_people[1].first_income_file?.length) {
          setFirstIncomeFile2(
            data.p_applicant_people[1].first_income_file.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        }
        if (data.p_applicant_people[1].second_income_file?.length) {
          setSecondIncomeFile2(
            data.p_applicant_people[1].second_income_file.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        }
        if (data.p_applicant_people[1].third_income_file?.length) {
          setThirdIncomeFile2(
            data.p_applicant_people[1].third_income_file.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        }
        if (data.p_applicant_people[1].driver_license_front_image) {
          setDriverLicenseFrontImage2([
            {
              id: data.p_applicant_people[1].driver_license_front_image.id,
              url: data.p_applicant_people[1].driver_license_front_image.url,
              name: data.p_applicant_people[1].driver_license_front_image
                .filename,
              hiden:
                data.p_applicant_people[1].driver_license_front_image.hiden,
            },
          ]);
        }
        if (data.p_applicant_people[1].driver_license_back_image) {
          setDriverLicenseBackImage2([
            {
              id: data.p_applicant_people[1].driver_license_back_image.id,
              url: data.p_applicant_people[1].driver_license_back_image.url,
              name: data.p_applicant_people[1].driver_license_back_image
                .filename,
              hiden: data.p_applicant_people[1].driver_license_back_image.hiden,
            },
          ]);
        }
        if (data.p_applicant_people[1].card_number_front_image) {
          setCardNumberFrontImage2([
            {
              id: data.p_applicant_people[1].card_number_front_image.id,
              url: data.p_applicant_people[1].card_number_front_image.url,
              name: data.p_applicant_people[1].card_number_front_image.filename,
              hiden: data.p_applicant_people[1].card_number_front_image.hiden,
            },
          ]);
        }
        // if (data.p_applicant_people[1].card_number_back_image) {
        //   setCardNumberBackImage2([
        //     {
        //       id: data.p_applicant_people[1].card_number_back_image.id,
        //       url: data.p_applicant_people[1].card_number_back_image.url,
        //       name: data.p_applicant_people[1].card_number_back_image.filename,
        //       hiden: data.p_applicant_people[1].card_number_back_image.hiden,
        //     },
        //   ]);
        // }
        if (data.p_applicant_people[1].resident_register_front_image) {
          setResidentRegisterFrontImage2([
            {
              id: data.p_applicant_people[1].resident_register_front_image.id,
              url: data.p_applicant_people[1].resident_register_front_image.url,
              name: data.p_applicant_people[1].resident_register_front_image
                .filename,
              hiden:
                data.p_applicant_people[1].resident_register_front_image.hiden,
            },
          ]);
        }
        if (data.p_applicant_people[1].resident_register_back_image) {
          setResidentRegisterBackImage2([
            {
              id: data.p_applicant_people[1].resident_register_back_image.id,
              url: data.p_applicant_people[1].resident_register_back_image.url,
              name: data.p_applicant_people[1].resident_register_back_image
                .filename,
              hiden:
                data.p_applicant_people[1].resident_register_back_image.hiden,
            },
          ]);
        }
        if (data.p_applicant_people[1].residence_file?.length) {
          setResidenceIncomeFile(
            data.p_applicant_people[1].residence_file.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        }
        if (data.p_applicant_people[1].residence_file_back_image?.length) {
          setResidenceIncomeFileBackImage(
            data.p_applicant_people[1].residence_file_back_image.map(
              (file) => ({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              })
            )
          );
        }
      }

      let repaymentScheduleImage: (SPImage | File)[][] = [];

      data.p_borrowings.forEach((borrowing) => {
        if (
          !!borrowing.repayment_schedule_image &&
          !!borrowing.repayment_schedule_image?.length
        ) {
          repaymentScheduleImage.push(
            borrowing.repayment_schedule_image.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        } else {
          repaymentScheduleImage.push([]);
        }
      });

      setRepaymentScheduleImage(repaymentScheduleImage);
    },
    [
      setRepaymentScheduleImage,
      setResidenceFile,
      setResidenceFileBackImage,
      setPropertyInformationFile,
      setBusinessCard,
      setInsuranceFile,
      setFinancialStatement1File,
      setFinancialStatement2File,
      setFinancialStatement3File,
      setFirstWithholdingSlipFile,
      setSecondWithholdingSlipFile,
      setOtherDocumentFile,
      setEmploymentAgreementFile,
      setBusinessTaxReturn1File,
      setBusinessTaxReturn2File,
      setBusinessTaxReturn3File,
      setFirstIncomeFile,
      setSecondIncomeFile,
      setThirdIncomeFile,
      setDriverLicenseFrontImage,
      setDriverLicenseBackImage,
      setCardNumberFrontImage,
      setCardNumberBackImage,
      setResidentRegisterFrontImage,
      setResidentRegisterBackImage,
      setInsuranceFile2,
      setFinancialStatement1File2,
      setFinancialStatement2File2,
      setFinancialStatement3File2,
      setFirstWithholdingSlipFile2,
      setSecondWithholdingSlipFile2,
      setOtherDocumentFile2,
      setEmploymentAgreementFile2,
      setBusinessTaxReturn1File2,
      setBusinessTaxReturn2File2,
      setBusinessTaxReturn3File2,
      setFirstIncomeFile2,
      setSecondIncomeFile2,
      setThirdIncomeFile2,
      setDriverLicenseFrontImage2,
      setDriverLicenseBackImage2,
      setCardNumberFrontImage2,
      setCardNumberBackImage2,
      setResidentRegisterFrontImage2,
      setResidentRegisterBackImage2,
      setResidenceIncomeFile,
      setResidenceIncomeFileBackImage,
    ]
  );

  const onLoginEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const onPreventPassword = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasMenu={false}
        hasStepBar={false}
        hasModalSaveDraft={false}
        hasFooter={false}
        footerLogo
        sx={{ minHeight: 'calc(100vh - 162px)' }}
      >
        <Stack sx={{ width: '100%' }} onKeyDown={onLoginEnter}>
          <Stack sx={{ bgcolor: 'bg_off' }}>
            <Stack textAlign="center" sx={{ mt: 7 }}>
              <Typography
                variant="SP_title_email_screen"
                color="sp_primary_100_main"
              >
                ログイン
              </Typography>
            </Stack>
            {error && (
              <Stack
                direction="row"
                alignItems="center"
                textAlign="left"
                sx={{
                  mt: 7,
                  bgcolor: 'sp_secondary_pink_light',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_secondary_01}`,
                  borderRadius: '8px',
                  mx: 4,
                  px: 4,
                  py: 2,
                }}
                spacing={3}
              >
                <Icons.SPWarning />
                <Typography
                  variant="drawerText"
                  color="sp_secondary_01"
                  sx={{
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {errorMessage}
                </Typography>
              </Stack>
            )}
            <Stack justifyContent="center" sx={{ mt: 7, flex: 1 }}>
              <SPFormItem required label="メールアドレス">
                <SPInputField
                  name="email"
                  type="email"
                  placeholder="例：sample@sample.co.jp"
                  InputProps={{
                    sx: {
                      '&& .MuiInputBase-input': {
                        borderRadius: '5px',
                        ...theme.typography.text_placeholder,
                      },
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: '1px',
                          borderColor: 'black',
                        },
                    },
                  }}
                  maxLength={128}
                />
              </SPFormItem>
              <SPFormItem
                required
                label="パスワード"
                childrenSx={{ pb: '24px' }}
              >
                <Stack sx={{ position: 'relative' }} spacing={3}>
                  <SPInputField
                    name="password"
                    InputProps={{
                      sx: {
                        '&& .MuiInputBase-input': {
                          borderRadius: '5px',
                          ...theme.typography.text_placeholder,
                        },
                        '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                          {
                            borderWidth: '1px',
                            borderColor: 'black',
                          },
                      },
                    }}
                    autoTrim={false}
                    hasPassword
                    onCut={onPreventPassword}
                    onCopy={onPreventPassword}
                    onPaste={onPreventPassword}
                  />
                </Stack>
              </SPFormItem>
              <Stack spacing={7}>
                <Stack alignItems="center">
                  <Button
                    disabled={
                      formik.isSubmitting || !(formik.isValid && formik.dirty)
                    }
                    sx={{
                      bgcolor: 'sp_primary_100_main',
                      width: '327px',
                      minHeight: '54px',
                      boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                      borderRadius: '14px',
                      height: '54px',
                      '&.Mui-disabled': {
                        bgcolor: 'sp_primary_100_main',
                        opacity: 0.15,
                      },
                    }}
                    endIcon={
                      <SPArrowForward
                        sx={{
                          color: 'main_white',
                          position: 'absolute',
                          right: '22px',
                          bottom: '17px',
                        }}
                      />
                    }
                    onClick={() => formik.handleSubmit()}
                  >
                    <Typography
                      variant="SP_multiple_checkbox_label"
                      color="main_white"
                    >
                      ログイン
                    </Typography>
                  </Button>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Link
                    href={routeNames.ResetPassword.path}
                    variant="SP_multiple_checkbox_label"
                    color="sp_primary_100_main"
                    sx={{ textDecoration: 'none' }}
                  >
                    パスワードをお忘れの方
                    <Icons.SPArrowForwardCircle
                      sx={{
                        verticalAlign: 'middle',
                        marginTop: '-2px',
                        paddingTop: '2px',
                      }}
                    />
                  </Link>
                </Stack>

                <Stack alignItems="center">
                  <Button
                    sx={{
                      bgcolor: 'main_white',
                      width: '327px',
                      minHeight: '54px',
                      boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                      borderRadius: '14px',
                      height: '54px',
                      border: (theme) =>
                        `1px solid ${theme?.palette?.sp_primary_100_main}`,
                    }}
                    onClick={() => navigate(routeNames.SPScreenEmail.path)}
                  >
                    <Typography
                      variant="SP_multiple_checkbox_label"
                      color="sp_primary_100_main"
                    >
                      初めての方は会員登録
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <SPLoginCompleteModal
            open={openSuccessLogin}
            handleCallDraft={() => {
              let current_path = getCurrentPath(
                currentStep,
                hasJoinGuarantor,
                incomeTotalizer
              );
              if (current_path) {
                dispatch(setFirstTimeLogin(true));
                return navigate(current_path);
              }
            }}
            onClose={() => {
              setOpenSuccessLogin(false);
              dispatch(setFirstTimeLogin(true));
              navigate(routeNames.Top.path);
            }}
          />
        </Stack>
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPLoginPage;
