import { FC, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { hiddenScrollBar } from 'styles/toolkit';
import { Icons } from 'components';
import { situation, SituationType } from 'constant';

export type StepProps = {
  hasError?: boolean;
  hasBgColor?: boolean;
  status: SituationType;
};

export const StepAdmin: FC<StepProps> = ({ hasError, hasBgColor, status }) => {
  const getStyles = useCallback(
    (step: SituationType) => {
      const isFirstStep = [
        SituationType.APPLICATION,
        SituationType.APPLICATION_COMPLETED,
        SituationType.DOCUMENT_CHECK,
        SituationType.EXAMINATION_START,
      ].includes(step);
      if (hasError && isFirstStep) {
        return {
          borderColor: hasBgColor ? 'red_gray' : 'footer_bg',
          color: 'transparent',
          iconColor: 'line_gray',
          textColor: 'normal_text',
        };
      }
      if (hasError && !isFirstStep) {
        return {
          borderColor: 'sh_red',
          color: 'red_005',
          iconColor: 'sh_red',
          textColor: 'sh_red',
        };
      }
      if (step === status && isFirstStep) {
        return {
          borderColor: 'h_blugreen',
          color: 'blue07',
          iconColor: 'line_gray',
          textColor: hasBgColor ? 'h_blugreen' : 'blue03',
        };
      }
      if (status === SituationType.EXAMINATION_COMPLETED && step === status) {
        return {
          borderColor: 'green_003',
          color: 'green_005',
          iconColor: 'green_003',
          textColor: 'green_003',
        };
      }
      if (status === SituationType.EXAMINATION_COMPLETED && step !== status) {
        return {
          borderColor: hasBgColor ? 'green_gray' : 'footer_bg',
          color: 'green_005',
          iconColor: 'line_gray',
          textColor: 'normal_text',
        };
      }
      if (status === SituationType.CONTACT_ALREADY && isFirstStep) {
        return {
          borderColor: hasBgColor ? 'green_gray' : 'footer_bg',
          color: 'transparent',
          iconColor: 'line_gray',
          textColor: 'normal_text',
        };
      }
      if (status === SituationType.CONTACT_ALREADY && !isFirstStep) {
        return {
          borderColor: 'green_003',
          color: 'green_005',
          iconColor: 'green_003',
          textColor: 'green_003',
        };
      }
      return {
        borderColor: 'footer_bg',
        color: 'main_white',
        iconColor: 'line_gray',
        textColor: 'normal_text',
      };
    },
    [hasError, hasBgColor, status]
  );

  return (
    <Stack
      direction="row"
      spacing="3px"
      sx={{
        flex: 1,
        bgcolor: 'transparent',
        overflowX: { mobile: 'scroll', desktop: 'hidden' },
        overflowY: 'hidden',
        ...hiddenScrollBar,
      }}
    >
      {steps.map((step, index) => {
        const { borderColor, color, textColor, iconColor } = getStyles(step);
        return (
          <Stack
            key={index}
            sx={{ position: 'relative' }}
            justifyContent="center"
          >
            <Icons.AdminStep
              secondColor={borderColor}
              sx={{
                color,
                bgcolor: 'transparent',
              }}
            />
            <Stack
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
              }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {step === SituationType.EXAMINATION_COMPLETED && (
                <Icons.Circle sx={{ mt: '-2px', ml: -2, color: iconColor }} />
              )}
              {step === SituationType.CONTACT_ALREADY && (
                <Icons.CheckEmail
                  sx={{ mt: -1, color: iconColor }}
                  secondColor={color}
                />
              )}
              <Typography variant="text_step_admin" sx={{ color: textColor }}>
                {situation[step]}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

const steps = [
  SituationType.APPLICATION,
  SituationType.APPLICATION_COMPLETED,
  SituationType.DOCUMENT_CHECK,
  SituationType.EXAMINATION_START,
  SituationType.EXAMINATION_COMPLETED,
  SituationType.CONTACT_ALREADY,
];
