import { z } from 'zod';

export const memoItemSchema = z.object({
  id: z.string(),
  manager_name: z.string(),
  created_at: z.string(),
  memo: z.string(),
});

export const getMemoRequestSchema = z.object({
  id: z.string(),
  order_key: z.string().optional(),
  order_sort: z.string().optional(),
  page: z.number().optional(),
});

export const getMemoResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(memoItemSchema),
  total: z.number(),
});

export const createMemoResponseSchema = z.object({
  success: z.boolean(),
});

export const updateMemoResponseSchema = z.object({
  success: z.boolean(),
});

export const memoDetailResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    id: z.string(),
    created_at: z.string(),
    manager_name: z.string(),
    memo: z.string(),
  }),
});

export const createMemoRequestSchema = z.object({
  id: z.string(),
  memo: z.object({
    memo: z.string(),
  }),
});

export const updateMemoRequestSchema = z.object({
  preliminaryId: z.string(),
  memoId: z.string(),
  memo: z
    .object({
      memo: z.string(),
    })
    .optional(),
});

export type MemoItem = z.infer<typeof memoItemSchema>;
export type GetMemoRequest = z.infer<typeof getMemoRequestSchema>;
export type GetMemoResponse = z.infer<typeof getMemoResponseSchema>;
export type CreateMemoRequest = z.infer<typeof createMemoRequestSchema>;
export type UpdateMemoRequest = z.infer<typeof updateMemoRequestSchema>;
export type UpdateMemoResponse = z.infer<typeof updateMemoResponseSchema>;
export type CreateMemoResponse = z.infer<typeof createMemoResponseSchema>;
export type MemoDetailResponse = z.infer<typeof memoDetailResponseSchema>;
export type MemoError = {};
