import { FC } from 'react';
import { Stack, SxProps, Modal, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from 'components/atoms';

interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  handleOk: () => void;
  handleCancel: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = (props) => {
  return (
    <Modal
      sx={{
        ...props?.sx,
      }}
      disableAutoFocus
      {...props}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'main_white',
          width: { desktop: 430 },
          minHeight: 190,
        }}
      >
        <Stack direction="column">
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <CloseIcon
              onClick={props.onClose}
              fontSize="medium"
              sx={{
                color: 'b_333',
                cursor: 'pointer',
                pb: 2,
                pt: 5,
                pr: 5,
                width: 20,
                height: 20,
              }}
            />
          </Stack>
          <Stack sx={{ px: 5 }}>
            <Typography
              variant="heading01"
              color="b_333"
              sx={{ textAlign: 'center' }}
            >
              このタブを離れてもよろしいですか?
            </Typography>
            <Typography
              variant="heading01"
              color="b_333"
              sx={{ textAlign: 'center' }}
            >
              行った変更が保存されない可能性があります。
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: 5, px: 5 }}
          >
            <Button
              onClick={props.handleOk}
              sx={{
                bgcolor: 'white',
                boxShadow: 'none',
                width: '150px',
                height: '36px',
                borderRadius: '2px',
                minHeight: '36px',
                border: '1px solid #6B70F0',
              }}
            >
              <Typography variant="button01" color="sp_primary_100_main">
                OK
              </Typography>
            </Button>
            <Button
              onClick={props.handleCancel}
              sx={{
                bgcolor: 'white',
                boxShadow: 'none',
                width: '150px',
                height: '36px',
                borderRadius: '2px',
                minHeight: '36px',
                border: '1px solid #6B70F0',
              }}
            >
              <Typography variant="button01" color="sp_primary_100_main">
                キャンセル
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
