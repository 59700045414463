import { FC, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Container,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { NoiseControlOff } from '@mui/icons-material';
import { CheckboxButton, Icons } from 'components';
import { yup } from 'libs';
import { useNavigate } from 'react-router-dom';
import { LandingLayout } from 'containers';
import { routeNames } from 'navigations/routes';

const ConsentPage: FC = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      accept: false,
      no_join_guarantor: false,
    },
    validationSchema: yup.object().shape(
      {
        accept: yup.boolean().when('no_join_guarantor', {
          is: (no_join_guarantor: boolean) => !no_join_guarantor,
          then: yup.boolean().oneOf([true]),
        }),
        no_join_guarantor: yup.boolean().when('accept', {
          is: (accept: boolean) => !accept,
          then: yup.boolean().oneOf([true]),
        }),
      },
      [['accept', 'no_join_guarantor']]
    ),
    onSubmit() {},
  });

  useEffect(() => {
    if (formik.values.accept) {
      formik.setFieldValue('no_join_guarantor', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.accept]);

  useEffect(() => {
    if (formik.values.no_join_guarantor) {
      formik.setFieldValue('accept', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.no_join_guarantor]);

  return (
    <LandingLayout
      header={{ title: '注意事項をよくお読みください' }}
      footer={{
        left: false,
        right: {
          sx: { width: 367 },
          children: '住宅ローン事前審査お申込みへ進む',
          disabled: !(formik.isValid && formik.dirty),
          onClick: () => {
            navigate(routeNames.InputTime.path);
          },
        },
      }}
    >
      <FormikProvider value={formik}>
        <Stack alignItems="center" bgcolor="bg_off">
          <Container sx={{ px: { mobile: 4, tablet: 10 } }}>
            <Stack sx={{ mt: 9, my: 7 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: {
                    mobile: 'flex-start',
                    tablet: 'center',
                    desktop: 'center',
                  },
                  justifyContent: 'left',
                }}
              >
                <Icons.Error sx={{ mt: { mobile: '5px', tablet: 0 } }} />
                <Typography
                  variant="title_warning"
                  color="attention"
                  sx={{ pl: 1 }}
                >
                  連帯保証人の同意事項
                </Typography>
              </Box>
              <List sx={{ width: '100%' }}>
                {notes.map((item, index) => (
                  <ListItem key={index} dense alignItems="flex-start">
                    <ListItemIcon
                      sx={{
                        minWidth: { mobile: 19, desktop: 38 },
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NoiseControlOff sx={{ color: 'normal_text' }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="liststyle" color="normal_text">
                        {item}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Stack>
            <Stack alignItems="center" justifyContent="center">
              <Stack
                justifyContent="center"
                sx={{ mb: 5 }}
                direction="row"
                spacing={{ mobile: 5, tablet: '90px' }}
              >
                <Stack
                  sx={{
                    '@media (orientation: landscape)': {
                      minWidth: { mobile: 165, tablet: 240, md: 300 },
                    },
                    minWidth: { mobile: 165, tablet: 300 },
                  }}
                  flex={1}
                >
                  <CheckboxButton
                    name="accept"
                    fullWidth
                    sx={{ bgcolor: 'sh_red', minHeight: 50 }}
                    startIconStyle={{ left: 14, top: 14 }}
                    radioButton
                  >
                    <Typography variant="checkbox_button_R" color="main_white">
                      同意する
                    </Typography>
                  </CheckboxButton>
                </Stack>
                <Stack
                  sx={{
                    '@media (orientation: landscape)': {
                      minWidth: { mobile: 165, tablet: 240, md: 300 },
                    },
                    minWidth: { mobile: 165, tablet: 300 },
                  }}
                  flex={1}
                >
                  <CheckboxButton
                    name="no_join_guarantor"
                    fullWidth
                    sx={{ bgcolor: 'sh_red', minHeight: 50 }}
                    startIconStyle={{ left: 14, top: 14 }}
                    radioButton
                  >
                    <Typography
                      variant="checkbox_button_R"
                      color="main_white"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      連帯保証人なし
                    </Typography>
                  </CheckboxButton>
                </Stack>
              </Stack>
              <Typography variant="title_sub_button" color="attention" mb={15}>
                必ずお申込人ご本人さまがご入力ください。
              </Typography>
            </Stack>
          </Container>
        </Stack>
      </FormikProvider>
    </LandingLayout>
  );
};

const notes: string[] = [
  '私、連帯保証人予定者は、条件付承諾となった場合は、他の借入の解約または返済等の条件についても持込会社に通知することに同意します。',
];

export default ConsentPage;
