import { Stack, Typography } from '@mui/material';
import { Button, InputFileAdmin } from 'components';
import { FC } from 'react';

interface SelectPdfFileProps {
  disabled?: boolean;
  onChange: (file: File[]) => void;
}

export const SelectPdfFile: FC<SelectPdfFileProps> = ({
  disabled,
  onChange,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: 613,
        height: 200,
        m: 'auto',
        border: '1px dashed #6B70F0',
      }}
    >
      <Stack direction="column" spacing={5}>
        <InputFileAdmin
          disabled={disabled}
          name="main_personal_information_file"
          sx={{ p: 0 }}
          onChange={onChange}
          accept={{ 'application/pdf': [] }}
        >
          <Button
            disabled={disabled}
            sx={{
              py: '9px',
              px: '118px',
              backgroundColor: 'main_white',
              border: '1px solid #6B70F0',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'sp_primary_100_main',
                color: 'main_white',
                '& .MuiTypography-root': {
                  color: 'main_white',
                },
              },
            }}
          >
            <Typography variant="pdf_title" color="sp_primary_100_main">
              PDFファイルを選択
            </Typography>
          </Button>
        </InputFileAdmin>
        <Typography variant="pdf_description" color="b_333">
          審査結果のPDFをこのエリアにドロップするかアップロードしてください
        </Typography>
      </Stack>
    </Stack>
  );
};
