import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CheckDatabaseSyncRequest,
  CheckDatabaseSyncResponse,
  CreateLogDownloadRequest,
  CreateLogDownloadResponse,
  RootState,
  SubmitFormError,
  ThunkAPI,
  UpdateFilesPreliminariesRequest,
  UpdatePreliminariesError,
  UpdatePreliminariesRequest,
  UpdatePreliminariesResponse,
} from 'types';
import {
  AdminPreliminariesRequest,
  AdminPreliminariesResponse,
  AdminPreliminariesError,
  logEditedRequest,
  logEditResponse,
  logEditedError,
  adminPreliminary,
  GetUploadedFilesManagerRequest,
  GetUploadedFilesError,
  applicationsService,
  ChangeStatusRequest,
  ChangeStatusError,
  GetViewUploadedFilesResponse,
  AdminGetExaminationResponse,
  AdminGetExaminationRequest,
  AdminUpdateExaminationResponse,
  EditAdminPreliminariesResponse,
  EditAdminPreliminariesError,
  EditAdminPreliminariesRequest,
  CheckUpdateStatusResponse,
  CheckUpdateStatusRequest,
  externalBank,
  UpdateStatusSBIResponse,
  UpdateStatusSBIRequest,
  CheckUpdateStatusError,
  getReferralAgencyOptionRequest,
  getReferralAgencyOptionError,
  ReferralAgencyAdminOptionResponse,
  UpdateApproverConfirmationResponse,
  UpdateApproverConfirmationRequest,
} from 'services';
import { AxiosError } from 'axios';
import { getReferralAgencyAdminOptions } from 'services/adminPreliminary';
import FileSaver from 'file-saver';
import { dayjs } from 'libs';
import { INTERFACE_ERROR_STATUS } from 'constant';
import { downloadFile, zeroPad } from '../../utils';

export const getPersonalInformations = createAsyncThunk<
  AdminPreliminariesResponse,
  AdminPreliminariesRequest,
  ThunkAPI<AdminPreliminariesError>
>(
  'AdminPreliminaries/getPersonalInformations',
  async (data, { rejectWithValue }) => {
    try {
      return await adminPreliminary.getAdminPreliminaries(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLinkPersonalInformations = createAsyncThunk<
  AdminPreliminariesResponse,
  AdminPreliminariesRequest,
  ThunkAPI<AdminPreliminariesError>
>(
  'AdminPreliminaries/getLinkPersonalInformations',
  async (data, { rejectWithValue }) => {
    try {
      return await adminPreliminary.getAdminPreliminaries(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAdminPreliminary = createAsyncThunk<
  EditAdminPreliminariesResponse,
  EditAdminPreliminariesRequest,
  ThunkAPI<EditAdminPreliminariesError>
>(
  'AdminPreliminaries/editAdminPreliminaries',
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminPreliminary.editAdminPreliminaries(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdminExamination = createAsyncThunk<
  AdminGetExaminationResponse,
  AdminGetExaminationRequest,
  ThunkAPI<AdminPreliminariesError>
>('AdminPreliminaries/getExamination', async (data, { rejectWithValue }) => {
  try {
    return await adminPreliminary.getAdminExamination(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAdminExamination = createAsyncThunk<
  AdminUpdateExaminationResponse,
  UpdatePreliminariesRequest,
  ThunkAPI<AdminPreliminariesError>
>('AdminPreliminaries/updateExamination', async (data, { rejectWithValue }) => {
  try {
    return await adminPreliminary.updateAdminExamination(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUploadedFilesManager = createAsyncThunk<
  GetViewUploadedFilesResponse,
  GetUploadedFilesManagerRequest,
  ThunkAPI<GetUploadedFilesError>
>('AdminPreliminaries/getUploadedFiles', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getUploadedFilesManager(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUploadedFilesManager = createAsyncThunk<
  UpdatePreliminariesResponse,
  UpdateFilesPreliminariesRequest,
  ThunkAPI<UpdatePreliminariesError>
>(
  'AdminPreliminaries/updateUploadedFiles',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } =
        await applicationsService.updateUploadedFilesManager(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLogEditedPreliminary = createAsyncThunk<
  logEditResponse,
  logEditedRequest,
  ThunkAPI<logEditedError>
>(
  'AdminPreliminaries/getLogEditedPreliminary',
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminPreliminary.getLogEditedPreliminary(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatusPreliminary = createAsyncThunk<
  AdminPreliminariesResponse,
  ChangeStatusRequest,
  ThunkAPI<ChangeStatusError>
>('AdminPreliminaries/changeStatus', async (data, { rejectWithValue }) => {
  try {
    return await adminPreliminary.changeStatusManagerPreliminary(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateApproverConfirmation = createAsyncThunk<
  UpdateApproverConfirmationResponse,
  UpdateApproverConfirmationRequest,
  ThunkAPI<ChangeStatusError>
>(
  'AdminPreliminaries/updateApproverConfirmation',
  async (data, { rejectWithValue }) => {
    try {
      return await adminPreliminary.updateApproverConfirmation(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkUpdateStatus = createAsyncThunk<
  CheckUpdateStatusResponse,
  CheckUpdateStatusRequest,
  ThunkAPI<CheckUpdateStatusError>
>('AdminPreliminaries/checkUpdateStatus', async (data, { rejectWithValue }) => {
  try {
    return await adminPreliminary.checkUpdateStatus(data);
  } catch (error) {
    const err = (error as AxiosError<CheckUpdateStatusResponse>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});

export const updateStatusSBI = createAsyncThunk<
  UpdateStatusSBIResponse,
  UpdateStatusSBIRequest,
  ThunkAPI<CheckUpdateStatusError>
>('AdminPreliminaries/updateStatusSBI', async (data, { rejectWithValue }) => {
  try {
    return await externalBank.updateStatusSBI(data);
  } catch (error) {
    const err = (error as AxiosError<UpdateStatusSBIResponse>).response?.data;
    if (err) return err;
    return rejectWithValue(error);
  }
});

export const getReferralAgencyOptions = createAsyncThunk<
  ReferralAgencyAdminOptionResponse,
  getReferralAgencyOptionRequest,
  ThunkAPI<getReferralAgencyOptionError>
>('Admin/getReferralAgencyOptionsAction', async (data, { rejectWithValue }) => {
  try {
    return await getReferralAgencyAdminOptions(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getExportRawData = createAsyncThunk<
  BlobPart,
  {
    id: string;
    applicationNumber: string;
    lastNameKanji: string;
    firstNameKanji: string;
  }
>(
  'AdminPreliminaries/exportRawData',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      if (!state.auth.accessToken) {
        return rejectWithValue(new Error('Not logged in'));
      }

      const response = await applicationsService.getExportRawDataManager(
        data.id,
        state.auth.accessToken
      );

      if (!response.ok) {
        return rejectWithValue(new Error('見つかりません。'));
      }
      await FileSaver.saveAs(
        await response.blob(),
        `${data.applicationNumber}_${data.lastNameKanji}${data.firstNameKanji}様_申込内容のローデータ.xlsx`
      );
      return response.blob();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkDatabaseSyncManager = createAsyncThunk<
  CheckDatabaseSyncResponse,
  CheckDatabaseSyncRequest,
  ThunkAPI<SubmitFormError>
>('Manager/CheckDatabaseSync', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.checkDatabaseSyncManager(data);
    return response;
  } catch (error) {
    const err = (error as AxiosError<SubmitFormError>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});

export const createDownloadLogManager = createAsyncThunk<
  CreateLogDownloadResponse,
  CreateLogDownloadRequest,
  ThunkAPI<SubmitFormError>
>('Manager/CreateDownloadLog', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.createDownloadLogManager(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createDownloadLogSalePerson = createAsyncThunk<
  CreateLogDownloadResponse,
  CreateLogDownloadRequest,
  ThunkAPI<SubmitFormError>
>('SalePerson/CreateDownloadLog', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.createDownloadLogSalePerson(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInterfaceErrorStatus = createAsyncThunk<
  {
    interface_error_status: INTERFACE_ERROR_STATUS;
    id: string;
  },
  {
    interface_error_status: INTERFACE_ERROR_STATUS;
    id: string;
  },
  ThunkAPI<SubmitFormError>
>(
  'AdminPreliminaries/updateInterfaceErrorStatus',
  async (data, { rejectWithValue }) => {
    try {
      await applicationsService.updateInterfaceErrorStatus(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadLog = createAsyncThunk<
  BlobPart,
  { from_date: string; to_date: string }
>(
  'AdminPreliminaries/downloadLog',
  async (data, { rejectWithValue, getState }) => {
    try {
      const startDate = dayjs(data.from_date).format('YYYYMMDD');
      const endDate = dayjs(data.to_date).format('YYYYMMDD');
      const isValidStartDate = dayjs(data.from_date).isValid();
      const isValidEndDate = dayjs(data.to_date).isValid();
      const state = getState() as RootState;
      if (!state.auth.accessToken) {
        return rejectWithValue(new Error('Not logged in'));
      }

      const response = await applicationsService.getLogs(
        state.auth.accessToken,
        data.from_date,
        data.to_date
      );

      if (!response.ok) {
        return rejectWithValue(new Error('見つかりません。'));
      }
      if (isValidStartDate && isValidEndDate) {
        await FileSaver.saveAs(
          await response.blob(),
          `監視ログ_${startDate}~${endDate}.xlsx`
        );
      }
      if (isValidStartDate && !isValidEndDate) {
        await FileSaver.saveAs(
          await response.blob(),
          `監視ログ_${startDate}からのログ.xlsx`
        );
      }
      if (!isValidStartDate && isValidEndDate) {
        await FileSaver.saveAs(
          await response.blob(),
          `監視ログ_${endDate}までのログ.xlsx`
        );
      }
      if (!isValidStartDate && !isValidEndDate) {
        await FileSaver.saveAs(
          await response.blob(),
          `監視ログ_${dayjs().format('YYYYMMDD')}までの全てログ.xlsx`
        );
      }
      return response.blob();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadPreliminaryImage = createAsyncThunk<
  void,
  {
    applicationNumber: string;
    lastNameKanji: string;
    firstNameKanji: string;
    number?: number;
    filename: string;
    path: string;
    type: string;
  }
>(
  'AdminPreliminaries/downloadPreliminaryImage',
  async (data, { rejectWithValue }) => {
    try {
      const ext = getFileExtension(data.filename) ?? 'jpg';
      const filename = `${data.applicationNumber}_${data.lastNameKanji}${
        data.firstNameKanji
      }_${data.type}${data.number ? zeroPad(data.number) : ''}.${ext}`;
      await downloadFile(data.path, filename);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export function getFileExtension(filename: string): string {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
}
