import { z } from 'zod';

export const archiveFilesManagerResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(
    z.object({
      id: z.string(),
      file_name: z.array(z.string()),
      size: z.number(),
      note: z.string(),
      company_name: z.string(),
      name_kanji: z.string(),
      created_at: z.string(),
    })
  ),
});

export const archiveSortFilesManagerRequestSchema = z.object({
  file_name: z.array(z.string()).optional(),
  create_date: z.object({ from: z.string(), to: z.string() }).optional(),
  sale_person_names: z.array(z.string()).optional(),
  sale_agent_names: z.array(z.string()).optional(),
  format_file: z.array(z.string()).optional(),
  page: z.number().optional(),
  order_key: z.string(),
  order_sort: z.string(),
});

export const archiveFilesSalePersonResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(
    z.object({
      id: z.string(),
      file_name: z.array(z.string()),
      size: z.number(),
      note: z.string(),
      company_name: z.string(),
      name_kanji: z.string(),
      created_at: z.string(),
    })
  ),
});
export const archiveFilesManagerRequestSchema = z.object({
  file_name: z.array(z.string()).optional(),
  create_date: z.object({ from: z.string(), to: z.string() }).optional(),
  sale_person_names: z.array(z.string()).optional(),
  sale_agent_names: z.array(z.string()).optional(),
  format_file: z.array(z.string()).optional(),
  page: z.number().optional(),
  order_key: z.string().optional(),
  order_sort: z.string().optional(),
});

export const uploadDocumentSalePersonResponseSchema = z.object({
  success: z.boolean(),
});
export const archiveFileEditResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    file_name: z.array(
      z.object({
        id: z.string(),
        filename: z.string(),
        int_id: z.number(),
        url: z.string(),
      })
    ),
  }),
});

export const archiveSortEditFilesManagerResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    file_name: z.array(
      z.object({
        id: z.string(),
        filename: z.string(),
        int_id: z.number(),
        url: z.string(),
      })
    ),
  }),
});

export const archiveEditSortFilesManagerRequestSchema = z.object({
  id: z.string(),
  order_key: z.string(),
  order_sort: z.string(),
});

export const archiveFileDeleteResponseSchema = z.object({
  success: z.boolean(),
});

export type ArchiveFilesManagerResponse = z.infer<
  typeof archiveFilesManagerResponseSchema
>;
export type ManagerSortArchiveFileItemResponse = z.infer<
  typeof archiveFilesManagerResponseSchema
>;
export type ManagerSortArchiveFileItemRequest = z.infer<
  typeof archiveSortFilesManagerRequestSchema
>;
export type ManagerEditSortArchiveFileItemResponse = z.infer<
  typeof archiveSortEditFilesManagerResponseSchema
>;
export type ManagerEditSortArchiveFileItemRequest = z.infer<
  typeof archiveEditSortFilesManagerRequestSchema
>;
export type ArchiveFilesSalePersonResponse = z.infer<
  typeof archiveFilesSalePersonResponseSchema
>;
export type ArchiveFilesManagerRequest = z.infer<
  typeof archiveFilesManagerRequestSchema
>;
export type UploadDocumentSalePersonResponse = z.infer<
  typeof uploadDocumentSalePersonResponseSchema
>;
export type UploadDocumentNoteManagerResponse = {
  success: boolean;
};
export type ArchiveFileEditResponse = z.infer<
  typeof archiveFileEditResponseSchema
>;
export type ArchiveFileDeleteResponse = z.infer<
  typeof archiveFileDeleteResponseSchema
>;

export type GetArchiveFilesError = {};
export type UploadDocumentSalePersonError = {};
