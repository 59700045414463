import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { Button, ButtonProps } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { isReactNode } from 'utils';

export type FooterProps = PropsWithChildren<{
  left?: ReactNode | ButtonProps | false;
  right?: ReactNode | ButtonProps | false;
}>;

export const Footer: FC<FooterProps> = ({ left, right }) => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          p: '5px 20px 11px 11px',
          backgroundColor: 'footer_bg',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Stack flex={1}>
          {isReactNode(left) && left}
          {left !== false && !isReactNode(left) && (
            <Button
              {...left}
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
            >
              <Typography
                sx={{ fontSize: 16 }}
                variant="button_title"
                color="black"
              >
                戻る
              </Typography>
            </Button>
          )}
        </Stack>

        <Stack flex={1} alignItems="flex-end">
          {isReactNode(right) && right}
          {right !== false && !isReactNode(right) && (
            <Button
              {...right}
              sx={{
                pl: 8,
                pr: { tablet: 8, md: 12 },
                py: 2,
                minHeight: 44,
                height: 44,
                width: { mobile: 150, tablet: 247 },
                bgcolor: 'blue08',
                ...right?.sx,
                '&.MuiButton-root .MuiTypography-root': {
                  color: 'main_white',
                },
                '&.Mui-disabled .MuiTypography-root': {
                  opacity: { mobile: 1, desktop: 0.8 },
                },
                whiteSpace: 'nowrap',
              }}
              endIcon={<ArrowForwardIos sx={{ color: 'h_blugreen' }} />}
            >
              <Typography variant="button_title">{right.children}</Typography>
            </Button>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
