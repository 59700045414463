import { z } from 'zod';

export const masterBankSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
});

export const masterBankResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(masterBankSchema),
});

export type MasterBank = z.infer<typeof masterBankSchema>;
export type GetMasterBankResponse = z.infer<typeof masterBankResponseSchema>;
export type GetMasterBankError = {};
