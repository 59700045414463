import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Icons, SPButton } from 'components';
import { useSelector } from 'react-redux';
import { examinationFileSelector } from 'containers/AuthModal/selectors';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { supportDvh } from 'utils';

const SPExaminationResult: FC = () => {
  const navigate = useNavigate();
  const examinationFile = useSelector(examinationFileSelector);

  const scrollModePluginInstance = scrollModePlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  return (
    <SPStepLayout
      hasStepBar={false}
      hasModalSaveDraft={false}
      footer={{
        left: false,
        right: (
          <SPButton
            sx={{
              width: '100%',
              color: 'sp_primary_100_main',
              border: '1px solid',
              borderColor: 'sp_primary_100_main',
              bgcolor: 'main_white',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={() => navigate(routeNames.Top.path)}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              TOPへ
            </Typography>
          </SPButton>
        ),
        sxContainerRight: {
          mx: 8,
        },
      }}
    >
      <Stack
        sx={{
          width: '100%',
          minHeight: supportDvh('calc(100dvh - 113px)'),
          bgcolor: 'sp_bg_gray',
        }}
      >
        <Stack textAlign="center" sx={{ my: 7 }}>
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
          >
            審査結果
          </Typography>

          <Stack sx={{ px: 4, pt: 7 }} alignItems="center">
            <Stack
              flex={1}
              direction="row"
              justifyContent="center"
              sx={{ width: '100%', height: '100%' }}
            >
              {!!examinationFile?.path && (
                <Worker workerUrl="/pdf.worker.bundle.js">
                  <Viewer
                    fileUrl={examinationFile.path}
                    plugins={[
                      scrollModePluginInstance,
                      pageNavigationPluginInstance,
                    ]}
                    defaultScale={SpecialZoomLevel.PageWidth}
                  />
                </Worker>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </SPStepLayout>
  );
};

export default SPExaminationResult;
