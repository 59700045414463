import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Link: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 27 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <svg
      width="27"
      height="15"
      viewBox="0 0 27 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 7.22222C0.5 3.23194 3.73194 0 7.72222 0H11.6944C11.8931 0 12.0556 0.1625 12.0556 0.361111C12.0556 0.559722 11.8931 0.722222 11.6944 0.722222H7.72222C4.13368 0.722222 1.22222 3.63368 1.22222 7.22222C1.22222 10.8108 4.13368 13.7222 7.72222 13.7222H11.6944C11.8931 13.7222 12.0556 13.8847 12.0556 14.0833C12.0556 14.2819 11.8931 14.4444 11.6944 14.4444H7.72222C3.73194 14.4444 0.5 11.2125 0.5 7.22222ZM26.5 7.22222C26.5 11.2125 23.2681 14.4444 19.2778 14.4444H15.3056C15.1069 14.4444 14.9444 14.2819 14.9444 14.0833C14.9444 13.8847 15.1069 13.7222 15.3056 13.7222H19.2778C22.8663 13.7222 25.7778 10.8108 25.7778 7.22222C25.7778 3.63368 22.8663 0.722222 19.2778 0.722222H15.3056C15.1069 0.722222 14.9444 0.559722 14.9444 0.361111C14.9444 0.1625 15.1069 0 15.3056 0H19.2778C23.2681 0 26.5 3.23194 26.5 7.22222ZM6.63889 6.86111H20.3611C20.5597 6.86111 20.7222 7.02361 20.7222 7.22222C20.7222 7.42083 20.5597 7.58333 20.3611 7.58333H6.63889C6.44028 7.58333 6.27778 7.42083 6.27778 7.22222C6.27778 7.02361 6.44028 6.86111 6.63889 6.86111Z"
        fill="#6B70F0"
      />
    </svg>
  </SvgIcon>
);
