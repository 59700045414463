import { FC } from 'react';
import { Avatar, Box, Card, Stack, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LandingLayout } from 'containers';
import { Icons } from 'components';
import { routeNames } from 'navigations/routes';
import {
  imgA,
  imgB,
  imgB2,
  imgC,
  imgD1st,
  imgD2nd,
  imgD3rd,
  imgE,
  imgF,
  imgG1,
  imgG2,
} from 'assets';

const MaterialPage: FC = () => {
  const navigate = useNavigate();

  return (
    <LandingLayout
      header={{ title: '次の資料をお手元にご準備ください' }}
      footer={{
        left: {
          onClick: () => navigate(routeNames.InputTime.path),
        },
        right: {
          children: '次へ進む',
          onClick: () => navigate(routeNames.ApplicationFlow.path),
        },
      }}
    >
      <Stack alignItems="center" sx={{ pb: 18, bgcolor: 'bg_off' }}>
        <Container sx={{ px: { mobile: 4, tablet: 10 }, pt: 5 }}>
          <Box sx={{ mb: 7, textAlign: 'left' }}>
            <Typography variant="textstyle" color="normal_text">
              お手元にご準備いただく資料がございますので、必ず次の項目をお読みになり入力画面へお進みください。
            </Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 6, mx: { mobile: 5, tablet: 0 } }}
          >
            <Avatar
              variant="square"
              sx={{
                width: { mobile: 100, tablet: 175 },
                height: 76,
                bgcolor: 'pale_gray',
              }}
            >
              <Typography variant="text_title" color="normal_text">
                イラスト
              </Typography>
            </Avatar>
            <Stack
              direction="row"
              spacing={5}
              alignItems="center"
              sx={{ ml: 8 }}
            >
              <Icons.Save sx={{ color: 'attention' }} />
              <Typography variant="text_button" sx={{ color: 'attention' }}>
                入力情報は保存することが可能です。
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ mb: 10 }}>
            <Card
              sx={{
                borderRadius: '5px',
                boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
              }}
              elevation={0}
            >
              <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 1,
                    margin: '0 auto',
                    bgcolor: 'bg_off',
                    p: '22px 0px 29px 0px',
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      ml: { mobile: '20px', tablet: '31px' },
                      mr: { mobile: '20px', tablet: 0 },
                    }}
                  >
                    <Stack
                      spacing={7}
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                    >
                      <Typography variant="title_avatar_card" color="blue03">
                        A
                      </Typography>
                      <Typography
                        variant="text_avatar_card"
                        color="normal_text"
                      >
                        運転免許証 または 住民基本台帳カード または
                        マイナンバーカード
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ mobile: 7, tablet: 13 }}
                      sx={{ mt: 7 }}
                    >
                      <Avatar
                        variant="square"
                        src={imgA}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Typography variant="textstyle" color="normal_text">
                        {`運転免許証・住民基本台帳カードの場合：表面 + 裏面（裏面に記載事項がある場合）\nマイナンバーカードの場合：表面のみ`}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 1,
                    margin: '0 auto',
                    bgcolor: 'bg_off',
                    p: '22px 0px 29px 0px',
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      ml: { mobile: '20px', tablet: '31px' },
                      mr: { mobile: '20px', tablet: 0 },
                    }}
                  >
                    <Stack spacing={7} direction="row" alignItems="center">
                      <Typography variant="title_avatar_card" color="blue03">
                        B
                      </Typography>
                      <Typography
                        variant="text_avatar_card"
                        color="normal_text"
                      >
                        土地・建物に関する資料
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ mobile: 7, tablet: 13 }}
                      sx={{ mt: 7 }}
                    >
                      <Avatar
                        variant="square"
                        src={imgB}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Typography variant="textstyle" color="normal_text">
                        ご購入予定の土地・建物に関するチラシ・パンフレットなど
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ mobile: 7, tablet: 13 }}
                      sx={{ mt: 7 }}
                    >
                      <Avatar
                        variant="square"
                        src={imgB2}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Typography variant="textstyle" color="normal_text">
                        提携会社（不動産会社・住宅メーカー等）さまにご用意いただく物件書類（登記簿謄本等）
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 1,
                    margin: '0 auto',
                    bgcolor: 'bg_off',
                    p: '22px 0px 29px 0px',
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      ml: { mobile: '20px', tablet: '31px' },
                      mr: { mobile: '20px', tablet: 0 },
                    }}
                  >
                    <Stack spacing={7} direction="row" alignItems="center">
                      <Typography variant="title_avatar_card" color="blue03">
                        C
                      </Typography>
                      <Typography
                        variant="text_avatar_card"
                        color="normal_text"
                      >
                        健康保険証
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ mobile: 7, tablet: 13 }}
                      sx={{ mt: 7 }}
                    >
                      <Avatar
                        variant="square"
                        src={imgC}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Typography variant="textstyle" color="normal_text">
                        勤務先の在籍確認・入社年月日確認のためにご提出いただきます。
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 1,
                    margin: '0 auto',
                    bgcolor: 'bg_off',
                    p: '22px 0px 29px 0px',
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      ml: { mobile: '20px', tablet: '31px' },
                      mr: { mobile: '20px', tablet: 0 },
                    }}
                  >
                    <Stack spacing={7} direction="row" alignItems="center">
                      <Typography variant="title_avatar_card" color="blue03">
                        D
                      </Typography>
                      <Typography
                        variant="text_avatar_card"
                        color="normal_text"
                      >
                        収入に関する資料
                      </Typography>
                    </Stack>
                    <Stack
                      spacing={1}
                      sx={{ mt: 7, mb: 10, pr: { mobile: 2, tablet: 10 } }}
                    >
                      <Stack
                        direction="row"
                        alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                        spacing={1}
                      >
                        <Typography variant="text_upload_file">1.</Typography>
                        <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                          <Typography
                            variant="text_upload_file"
                            color="normal_text"
                          >
                            一般企業や官公庁に勤務する給与所得者の方は
                          </Typography>
                          <Typography variant="h4">
                            「源泉徴収票（前年度１期分）」
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                        spacing={1}
                      >
                        <Typography variant="text_upload_file">2.</Typography>
                        <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                          <Typography
                            variant="text_upload_file"
                            color="normal_text"
                          >
                            給与所得者以外の個人事業主（フリーランス）の方は
                          </Typography>
                          <Typography variant="h4">
                            「確定申告書３期分」
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Typography variant="text_upload_file">3.</Typography>
                        <Stack direction="column">
                          <Typography
                            variant="text_upload_file"
                            color="normal_text"
                          >
                            給与所得者であるが、給与所得以外（不動産・事業所得）の所得があるなどの理由により確定申告を
                            行なっている方（各種控除のための申告は除く）は
                          </Typography>
                          <Typography variant="h4">
                            「確定申告書３期分」
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                        spacing={1}
                      >
                        <Typography variant="text_upload_file">4.</Typography>
                        <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                          <Typography
                            variant="text_upload_file"
                            color="normal_text"
                          >
                            会社役員の方は「源泉徴収票」と
                          </Typography>
                          <Typography variant="h4" color="normal_text">
                            「源泉徴収票」
                          </Typography>
                          <Typography
                            variant="text_upload_file"
                            color="normal_text"
                          >
                            と
                          </Typography>
                          <Typography variant="h4">
                            「法人決算書３期分」
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                        spacing={1}
                      >
                        <Typography variant="text_upload_file">5.</Typography>
                        <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                          <Typography
                            variant="text_upload_file"
                            color="normal_text"
                          >
                            親族が経営する企業に勤務する方は
                          </Typography>
                          <Typography variant="h4">「源泉徴収票」</Typography>
                          <Typography
                            variant="text_upload_file"
                            color="normal_text"
                          >
                            と
                          </Typography>
                          <Typography variant="h4">
                            「法人決算書３期分」
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={{ mobile: 6, tablet: 14 }}>
                      <Avatar
                        variant="square"
                        src={imgD1st}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Avatar
                        variant="square"
                        src={imgD2nd}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Avatar
                        variant="square"
                        src={imgD3rd}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 1,
                    margin: '0 auto',
                    bgcolor: 'bg_off',
                    p: '22px 0px 29px 0px',
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      ml: { mobile: '20px', tablet: '31px' },
                      mr: { mobile: '20px', tablet: 0 },
                    }}
                  >
                    <Stack
                      spacing={7}
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                    >
                      <Typography variant="title_avatar_card" color="blue03">
                        E
                      </Typography>
                      <Typography
                        variant="text_avatar_card"
                        color="normal_text"
                      >
                        提携会社（不動産会社・住宅メーカー等）の担当者名刺
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ mobile: 7, tablet: 13 }}
                      sx={{ mt: 7 }}
                    >
                      <Avatar
                        variant="square"
                        src={imgE}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Typography variant="textstyle" color="normal_text">
                        「提携会社（不動産会社・住宅メーカー等）について」の各項目にご記入いただいた場合は提出不要です。
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 1,
                    margin: '0 auto',
                    bgcolor: 'bg_off',
                    p: '22px 0px 29px 0px',
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      ml: { mobile: '20px', tablet: '31px' },
                      mr: { mobile: '20px', tablet: 0 },
                    }}
                  >
                    <Stack
                      spacing={7}
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                    >
                      <Typography variant="title_avatar_card" color="blue03">
                        F
                      </Typography>
                      <Typography
                        variant="text_avatar_card"
                        color="normal_text"
                      >
                        外国籍の方の永住権に関する資料
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ mobile: 7, tablet: 13 }}
                      sx={{ mt: 7 }}
                    >
                      <Avatar
                        variant="square"
                        src={imgF}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Typography variant="textstyle" color="normal_text">
                        永住権証明書、または特別永住権証明書など
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>

              <Stack sx={{ bgcolor: 'bg_off', my: 5, mx: 5 }}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 1,
                    margin: '0 auto',
                    bgcolor: 'bg_off',
                    p: '22px 0px 29px 0px',
                    width: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      ml: { mobile: '20px', tablet: '31px' },
                      mr: { mobile: '20px', tablet: 0 },
                    }}
                  >
                    <Stack
                      spacing={7}
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                    >
                      <Typography variant="title_avatar_card" color="blue03">
                        G
                      </Typography>
                      <Typography
                        variant="text_avatar_card"
                        color="normal_text"
                      >
                        現在ご利用中のローンの返済予定表・利用明細等
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={{ mobile: 7, tablet: 13 }}
                      sx={{ mt: 7 }}
                    >
                      <Avatar
                        variant="square"
                        src={imgG1}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Avatar
                        variant="square"
                        src={imgG2}
                        sx={{
                          width: 73,
                          height: 102,
                          '.MuiAvatar-img': {
                            objectFit: 'contain',
                          },
                        }}
                      />
                      <Typography variant="textstyle" color="normal_text">
                        永住権証明書、または特別永住権証明書など
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Card>
          </Stack>
        </Container>
      </Stack>
    </LandingLayout>
  );
};

export default MaterialPage;
