import { Stack, Typography, Box } from '@mui/material';
import { RESULT_STATUS } from 'constant';
import { FC, useCallback } from 'react';
import { SalePersonPreliminariesItem } from 'services';

type ResultStatusChartProps = {
  data: SalePersonPreliminariesItem;
};

export const ResultStatusChart: FC<ResultStatusChartProps> = ({ data }) => {
  const resultStatusColor = useCallback(
    (index: string, statusResult: string, underReviewStatus: number) => {
      if (
        (statusResult === '0' && index === '0') ||
        ((statusResult === '1' || statusResult === '2') && index === '1')
      ) {
        return 'arrow_blue_lite';
      }
      if (statusResult === '3' && index === '2') {
        return 'arrow_orange_lite';
      }

      if ((statusResult === '5' || statusResult === '6') && index === '3') {
        return 'arrow_green_lite';
      }

      if (statusResult === '4' && index === '2') {
        return 'arrow_orange_lite';
      }
      return 'bg_off';
    },
    []
  );

  const textStatusSalePerson = useCallback(
    (
      index: string,
      statusResult: string,
      underReviewStatus: number,
      statusName: string
    ) => {
      const statusColor = resultStatusColor(
        index,
        statusResult,
        underReviewStatus
      );
      if (
        (index === '2' && statusColor === 'arrow_orange_lite') ||
        (index === '2' && statusColor === 'arrow_blue_lite')
      ) {
        return (
          <>
            {statusName} (
            <Box component="span" sx={{ color: 'main_white' }}>
              {underReviewStatus > 0
                ? underReviewStatus - 1
                : underReviewStatus}
            </Box>
            /5)
          </>
        );
      }
      return statusName;
    },
    [resultStatusColor]
  );

  const checkClipPath = (index: number) => {
    const space = '16px';
    if (!index)
      return `polygon(calc(100% - ${space}) 0%, 100% 50%, calc(100% - ${space}) 100%, 0% 100%, 0% 50%, 0% 0%)`;

    return `polygon(calc(100% - ${space}) 0%, 100% 50%, calc(100% - ${space}) 100%, 0% 100%, ${space} 50%, 0% 0%)`;
  };

  return (
    <Stack direction="row" alignItems="center">
      {RESULT_STATUS.map((status, index) => {
        const bgcolor = resultStatusColor(
          index.toString(),
          data.status_result,
          Number(data.under_review_status)
        );
        return (
          <Stack
            key={index}
            direction="row"
            flex={index === 2 ? 1.5 : 1}
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'relative',
              minHeight: 38,
              bgcolor: bgcolor !== 'bg_off' ? bgcolor : 'gray',
              mr: '-14px',
              clipPath: checkClipPath(index),
              '&:last-child': {
                mr: '-4px',
              },
            }}
          >
            <Stack
              sx={{
                position: 'absolute',
                top: '1px',
                left: '2px',
                right: '1px',
                bottom: '1px',
                bgcolor,
                clipPath: checkClipPath(index),
              }}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="text_status_sale_person_item"
                color={
                  index === 2 &&
                  (bgcolor === 'arrow_orange_lite' ||
                    bgcolor === 'arrow_blue_lite')
                    ? 'main_white'
                    : 'b_333'
                }
                whiteSpace="nowrap"
                fontWeight={bgcolor !== 'bg_off' ? 500 : 300}
              >
                {textStatusSalePerson(
                  index.toString(),
                  data.status_result,
                  Number(data.under_review_status),
                  status.name
                )}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};
