import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SPStepEightForm } from 'types';
import { logout } from 'containers/AuthModal/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { SPBusinessCardType } from 'constant';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { convertImageResponse } from 'utils';
import { deleteUser } from '../SPUnsubcribed/thunk';
import { getReferralAgencyOptionsAction } from './thunk';

export type RadioItems = {
  id: string;
  value: string;
  label: string;
};

export type InitialState = {
  form: SPStepEightForm;
  agenciesOptions: RadioItems[];
  storesOptions: RadioItems[];
  exhibitionsOptions: RadioItems[];
  salePersonsOptions: { value: string; label: string }[];
  p_referral_agency_id: string;
};

export const initialState: InitialState = {
  form: {
    p_referral_agencies: {
      has_business_card: SPBusinessCardType.DEFAULT,
      business_card: [],
      sale_agent_id: '',
      store_id: '',
      exhibition_id: '',
      s_sale_person_id: '',
      sale_person_name_input: '',
      phone_number: '',
    },
  },
  agenciesOptions: [],
  storesOptions: [],
  exhibitionsOptions: [],
  salePersonsOptions: [],
  p_referral_agency_id: '',
};

const spStepEightSlice = createSlice({
  name: 'spStepEight',
  initialState,
  reducers: {
    saveForm: (
      state,
      action: PayloadAction<SPStepEightForm & { p_referral_agency_id?: string }>
    ) => {
      const { p_referral_agency_id, ...form } = action.payload;
      state.form = form;
      if (p_referral_agency_id) {
        state.p_referral_agency_id = p_referral_agency_id;
      }
    },
    saveReferralAgencyId: (state, action: PayloadAction<string>) => {
      state.p_referral_agency_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_referral_agency, p_application_header } = action.payload.data;
      state.form.p_referral_agencies = {
        ...state.form.p_referral_agencies,
        ...p_referral_agency,
        sale_person_name_input:
          p_application_header.sale_person_name_input ??
          p_referral_agency?.sale_person_name_input ??
          '',
        business_card: !!p_application_header.business_card
          ? convertImageResponse(p_application_header.business_card)
          : [],
      };
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const {
        header_file_upload,
        sale_person_information,
        s_sale_person_id,
        sale_person_phone_number,
        sale_person_name_input,
      } = action.payload.data;
      const {
        list_agencies,
        list_stores,
        list_exhibitions,
        list_sale_persons,
        active_agency_id,
        active_exhibition_id,
        active_store_id,
      } = sale_person_information;

      state.agenciesOptions = [
        ...list_agencies.map((agency) => {
          return {
            id: agency.id,
            value: agency.sale_agent_id,
            label: agency.sale_agent_name_kanji,
          };
        }),
      ];
      state.storesOptions = [
        ...list_stores.map((store) => {
          return {
            id: store.id,
            value: store.store_id,
            label: store.store_name_kanji,
          };
        }),
      ];
      state.exhibitionsOptions = [
        ...list_exhibitions.map((exhibition) => {
          return {
            id: exhibition.id,
            value: exhibition.exhibition_id,
            label: exhibition.exhibition_hall,
          };
        }),
      ];
      state.salePersonsOptions = [
        ...list_sale_persons.map((salePerson) => {
          return {
            value: salePerson.id,
            label: salePerson.value,
          };
        }),
      ];

      const p_referral_agency = {
        sale_agent_id:
          list_agencies.find((agent) => agent.id === active_agency_id)
            ?.sale_agent_id ?? '',
        store_id:
          list_stores.find((store) => store.id === active_store_id)?.store_id ??
          '',
        exhibition_id:
          list_exhibitions.find(
            (exhibition) => exhibition.id === active_exhibition_id
          )?.exhibition_id ?? '',
      };
      state.form.p_referral_agencies = {
        ...state.form.p_referral_agencies,
        has_business_card: !header_file_upload.business_card.length
          ? SPBusinessCardType.NO
          : SPBusinessCardType.YES,
        business_card: convertImageResponse(header_file_upload.business_card),
        ...p_referral_agency,
        s_sale_person_id,
        phone_number: sale_person_phone_number ?? '',
        sale_person_name_input: sale_person_name_input ?? '',
      };
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const {
        header_file_upload,
        sale_person_information,
        s_sale_person_id,
        sale_person_phone_number,
        sale_person_name_input,
      } = action.payload.data;
      const {
        list_agencies,
        list_stores,
        list_exhibitions,
        list_sale_persons,
        active_agency_id,
        active_exhibition_id,
        active_store_id,
      } = sale_person_information;

      state.agenciesOptions = [
        ...list_agencies.map((agency) => {
          return {
            id: agency.id,
            value: agency.sale_agent_id,
            label: agency.sale_agent_name_kanji,
          };
        }),
      ];
      state.storesOptions = [
        ...list_stores.map((store) => {
          return {
            id: store.id,
            value: store.store_id,
            label: store.store_name_kanji,
          };
        }),
      ];
      state.exhibitionsOptions = [
        ...list_exhibitions.map((exhibition) => {
          return {
            id: exhibition.id,
            value: exhibition.exhibition_id,
            label: exhibition.exhibition_hall,
          };
        }),
      ];
      state.salePersonsOptions = [
        ...list_sale_persons.map((salePerson) => {
          return {
            value: salePerson.id,
            label: salePerson.value,
          };
        }),
      ];

      const p_referral_agency = {
        sale_agent_id:
          list_agencies.find((agent) => agent.id === active_agency_id)
            ?.sale_agent_id ?? '',
        store_id:
          list_stores.find((store) => store.id === active_store_id)?.store_id ??
          '',
        exhibition_id:
          list_exhibitions.find(
            (exhibition) => exhibition.id === active_exhibition_id
          )?.exhibition_id ?? '',
      };
      state.form.p_referral_agencies = {
        ...state.form.p_referral_agencies,
        has_business_card: !header_file_upload.business_card.length
          ? SPBusinessCardType.NO
          : SPBusinessCardType.YES,
        business_card: convertImageResponse(header_file_upload.business_card),
        ...p_referral_agency,
        s_sale_person_id,
        phone_number: sale_person_phone_number ?? '',
        sale_person_name_input: sale_person_name_input ?? '',
      };
    });
    builder.addCase(
      getReferralAgencyOptionsAction.fulfilled,
      (state, action) => {
        const agenciesResponse: RadioItems[] = [];
        const storesResponse: RadioItems[] = [];
        const exhibitionsResponse: RadioItems[] = [];
        const salePersonsResponse: { value: string; label: string }[] = [];
        action.payload.data.agencies.forEach(
          (agency: { id: string; sale_agent_id: string; value: string }) => {
            agenciesResponse.push({
              id: agency.id,
              value: agency.sale_agent_id.toString(),
              label: agency.value,
            });
          }
        );
        action.payload.data.stores.forEach(
          (store: { id: string; store_id: string; value: string }) => {
            storesResponse.push({
              id: store.id,
              value: store.store_id.toString(),
              label: store.value,
            });
          }
        );
        action.payload.data.exhibitions.forEach(
          (exhibition: {
            id: string;
            exhibition_id: string;
            value: string;
          }) => {
            exhibitionsResponse.push({
              id: exhibition.id,
              value: exhibition.exhibition_id.toString(),
              label: exhibition.value,
            });
          }
        );
        action.payload.data.sale_persons.forEach(
          (sale_person: { id: string; value: string }) => {
            salePersonsResponse.push({
              value: sale_person.id.toString(),
              label: sale_person.value,
            });
          }
        );
        state.agenciesOptions = agenciesResponse;
        state.storesOptions = storesResponse;
        state.exhibitionsOptions = exhibitionsResponse;
        state.salePersonsOptions = salePersonsResponse;
      }
    );
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const { saveForm, saveReferralAgencyId } = spStepEightSlice.actions;
export default spStepEightSlice.reducer;
