import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepLayout } from 'containers';
import { routeNames } from 'navigations/routes';
import { FormikProvider, useFormik } from 'formik';
import { yup } from 'libs';
import { Grid, Link, Stack, Typography } from '@mui/material';
import { CheckboxButton, RadioGroupButton } from 'components';
import { consent, ConsentType } from 'constant';
import { config } from 'configs';

type RadioItems = {
  value: string;
  label: string;
};

type AcceptProps = {
  name: string;
  title: string;
  buttonText: string;
  onClick: () => void;
};

const AcceptButton: FC<AcceptProps> = ({
  name,
  title,
  buttonText,
  onClick,
}) => {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Stack sx={{ mt: 22, mb: 10, width: { tablet: 672 } }} direction="row">
        <Typography
          sx={{
            bgcolor: 'h_blugreen',
            border: '1px solid',
            borderColor: 'scroll_gray',
            borderRadius: '3px',
            whiteSpace: 'nowrap',
            height: 24,
            px: 2,
            mr: 2,
          }}
          variant="button_title"
          color="main_white"
        >
          PDF
        </Typography>
        <Link
          href={config.termConditionUrl}
          target="_blank"
          component="a"
          variant="title_sub_button"
          color="h_blugreen"
          onClick={onClick}
        >
          <Typography
            variant="h3"
            color="h_blugreen"
            fontWeight={{ mobile: 'bold', tablet: 600 }}
          >
            {title}
          </Typography>
        </Link>
      </Stack>
      <Grid container>
        <Grid item {...{ mobile: 12, md: 12 }}>
          <CheckboxButton
            name={name}
            fullWidth
            sx={{
              minHeight: 60,
              width: { tablet: 672 },
              m: '0 auto',
              justifyContent: 'left',
            }}
            startIconStyle={{ left: 14, top: 19 }}
          >
            <Stack direction="row" sx={{ pl: { tablet: 12, mobile: 0 } }}>
              <Typography
                variant="checkbox_button_R"
                fontSize={{ mobile: 15, tablet: 20 }}
              >
                {buttonText}
              </Typography>
            </Stack>
          </CheckboxButton>
        </Grid>
      </Grid>
    </Stack>
  );
};

const ConfirmationPage: FC = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      acceptPersonInfo: false,
      acceptNotAntiSocial: false,
      acceptBank: false,
      consent: ConsentType.DEFAULT,
    },
    validationSchema: yup.object().shape({
      acceptPersonInfo: yup
        .boolean()
        .oneOf([true], 'こちらにチェックする必要があります。'),
      acceptNotAntiSocial: yup
        .boolean()
        .oneOf([true], 'こちらにチェックする必要があります。'),
      acceptBank: yup
        .boolean()
        .oneOf([true], 'こちらにチェックする必要があります。'),
      consent: yup
        .string()
        .required()
        .oneOf(
          [ConsentType.AGREE, ConsentType.DISAGREE],
          'こちらにチェックする必要があります。'
        ),
    }),
    onSubmit(values, { setSubmitting }) {
      setSubmitting(false);
      navigate(routeNames.StepRequiredInformation.path);
    },
  });

  useEffect(() => {
    if (
      !formik.values.acceptPersonInfo ||
      !formik.values.acceptNotAntiSocial ||
      !formik.values.acceptBank
    ) {
      formik.setFieldValue('consent', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.acceptPersonInfo,
    formik.values.acceptNotAntiSocial,
    formik.values.acceptBank,
  ]);

  useEffect(() => {
    if (formik.values.consent === ConsentType.DISAGREE) {
      navigate(routeNames.ApplicationFlow.path);
    }
  }, [formik.values.consent, navigate]);

  return (
    <StepLayout
      hasModalSaveDraft={false}
      footer={{
        left: {
          onClick: () => {
            navigate(routeNames.ApplicationFlow.path);
          },
        },
        right: {
          onClick: () => formik.handleSubmit(),
        },
      }}
    >
      <FormikProvider value={formik}>
        <Stack sx={{ mt: 11 }} direction="row">
          <Typography
            variant="h3"
            color="normal_text"
            sx={{
              whiteSpace: 'nowrap',
              fontWeight: { mobile: 'bold', tablet: 600 },
            }}
          >
            以下
          </Typography>
          <Typography
            sx={{
              bgcolor: 'h_blugreen',
              border: '1px solid',
              borderColor: 'scroll_gray',
              borderRadius: '3px',
              whiteSpace: 'nowrap',
              height: 24,
              px: 2,
              mx: 2,
            }}
            variant="button_title"
            color="main_white"
          >
            PDF
          </Typography>
          <Typography
            variant="h3"
            color="normal_text"
            sx={{
              whiteSpace: 'nowrap',
              fontWeight: { mobile: 'bold', tablet: 600 },
            }}
          >
            をよくお読みください。
          </Typography>
        </Stack>
        <AcceptButton
          name="acceptPersonInfo"
          title="個人情報の取扱い等に関する同意"
          buttonText="「個人情報の取扱い等に関する同意」を確認しました"
          onClick={() => formik.setFieldValue('acceptPersonInfo', true)}
        />

        <AcceptButton
          name="acceptNotAntiSocial"
          title="反社会的勢力ではないことの表明・確約"
          buttonText="「反社会的勢力ではないことの表明・確約」を確認しました"
          onClick={() => formik.setFieldValue('acceptNotAntiSocial', true)}
        />

        <AcceptButton
          name="acceptBank"
          title="銀行代理業にかかる確認"
          buttonText="「銀行代理業にかかる確認」を確認しました"
          onClick={() => formik.setFieldValue('acceptBank', true)}
        />

        <Stack sx={{ mt: 32, mb: 11, textAlign: 'center' }}>
          <Typography
            variant="h3"
            color="normal_text"
            fontWeight={{ mobile: 'bold', tablet: 600 }}
          >
            「個人情報の取扱い等に関する同意書」
          </Typography>
          <Typography
            variant="h3"
            color="normal_text"
            fontWeight={{ mobile: 'bold', tablet: 600 }}
          >
            「反社会的勢力ではないことの表明・確約」
          </Typography>
          <Typography
            variant="h3"
            color="normal_text"
            fontWeight={{ mobile: 'bold', tablet: 600 }}
          >
            「銀行代理業にかかる確認」
          </Typography>
          <Typography
            variant="h3"
            color="normal_text"
            fontWeight={{ mobile: 'bold', tablet: 600 }}
          >
            について申込人およびペアローンの申込人／連帯保証人予定者は
          </Typography>
        </Stack>
        <Stack sx={{ mb: 26 }}>
          <RadioGroupButton
            itemPerRow={3}
            type="noImage"
            name="consent"
            options={consentOptions}
            justifyContent="center"
            disabled={
              !formik.values.acceptPersonInfo ||
              !formik.values.acceptNotAntiSocial ||
              !formik.values.acceptBank
            }
            showError={
              formik.values.acceptPersonInfo &&
              formik.values.acceptNotAntiSocial &&
              formik.values.acceptBank
            }
          />
        </Stack>
      </FormikProvider>
    </StepLayout>
  );
};

export default ConfirmationPage;

const consentOptions: Array<RadioItems> = [
  {
    value: ConsentType.DISAGREE,
    label: consent[ConsentType.DISAGREE],
  },
  {
    value: ConsentType.AGREE,
    label: consent[ConsentType.AGREE],
  },
];
