import { AdminPreliminaryItem, LoanType, PERSONAL_INFORMATION } from 'constant';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { dayjs } from 'libs';

export const buildPersonalInformation = (
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  return PERSONAL_INFORMATION.filter((field) => {
    if (
      field.name_en ===
      'p_applicant_people_attributes[0].relationship_to_applicant'
    ) {
      return LoanType.ONE !== values.loan_type;
    }
    return true;
  }).map((row) => {
    const { loan_desired_borrowing_date } =
      values.p_borrowing_details_attributes[0];
    if (row.name_en === `p_applicant_people_attributes[0].birthday`) {
      return {
        ...row,
        options: {
          ...row.options,
          minDate: dayjs(loan_desired_borrowing_date || undefined)
            .subtract(65, 'y')
            .toString(),
          maxDate: dayjs(loan_desired_borrowing_date || undefined)
            .subtract(18, 'y')
            .toString(),
        },
      };
    }
    return { ...row };
  });
};
