import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPAirPlaneButton: FC<SvgIconProps> = (sx, props) => {
  return (
    <SvgIcon {...sx} viewBox="0 0 24 25" {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.9232 23.3826L13.5701 21.1513L11.8029 24.2404C11.4654 24.831 10.7717 25.1217 10.1154 24.9482C9.45915 24.7748 8.99978 24.1795 8.99978 23.4998V19.2482L0.923215 15.8826C0.393528 15.6623 0.0372777 15.1654 0.00446524 14.5935C-0.0283473 14.0217 0.262278 13.4826 0.759153 13.1967L1.12947 13.8482C0.881028 13.9888 0.735715 14.2607 0.754465 14.5467C0.773215 14.8326 0.95134 15.081 1.21384 15.1935L9.13103 18.4935L22.481 1.74978C22.3592 1.75446 22.2373 1.78728 22.1248 1.84821L1.12478 13.8482L0.754465 13.1967L21.7545 1.19665C22.1435 0.976337 22.5982 0.938837 23.006 1.08884C23.1232 1.13102 23.2404 1.19196 23.3482 1.26227C23.4092 1.30446 23.4654 1.34665 23.517 1.39352C23.5592 1.43102 23.5967 1.46852 23.6295 1.51071C23.9154 1.83884 24.0513 2.28415 23.981 2.72477L20.981 22.2248C20.9107 22.6795 20.6342 23.0779 20.231 23.3029C19.8279 23.5279 19.3451 23.556 18.9185 23.3779L18.9232 23.3826ZM9.84822 18.7888L13.856 20.4576C13.856 20.4576 13.856 20.4576 13.8607 20.4576L19.2138 22.6888C19.4248 22.7779 19.6685 22.7638 19.8701 22.6513C20.0717 22.5388 20.2076 22.3373 20.2451 22.1123L23.2451 2.61228C23.2732 2.43884 23.2357 2.2654 23.1513 2.12009L9.84822 18.7888ZM9.74978 19.5623V23.4998C9.74978 23.842 9.97947 24.1373 10.3076 24.2263C10.6357 24.3154 10.9826 24.1701 11.1513 23.8748L12.8717 20.8654L9.74978 19.5623Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
