import { AxiosRequestConfig } from 'axios';
import { parseErrors } from 'utils';
import { instance } from 'libs';
import { UpdatePreliminariesRequest } from 'types';
import {
  AdminPreliminariesResponse,
  AdminPreliminariesRequest,
  adminPreliminariesResponseSchema,
  logEditedRequest,
  logEditResponse,
  logEditResponseSchema,
  ChangeStatusRequest,
  EditAdminPreliminariesResponse,
  EditAdminPreliminariesRequest,
  AdminGetExaminationRequest,
  AdminGetExaminationResponse,
  adminGetExaminationResponseSchema,
  AdminUpdateExaminationResponse,
  adminUpdateExaminationResponseSchema,
  CheckUpdateStatusRequest,
  CheckUpdateStatusResponse,
  checkUpdateStatusResponse,
  getReferralAgencyOptionRequest,
  ReferralAgencyAdminOptionResponse,
  getReferralAgencyOptionRequestSchema,
  getReferralAgencyAdminOptionResponseSchema,
  UpdateApproverConfirmationRequest,
  UpdateApproverConfirmationResponse,
  updateApproverConfirmationSchema,
} from './schemas';

export const getAdminPreliminaries = (
  data: AdminPreliminariesRequest
): Promise<AdminPreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/preliminaries/${data.id}/edit`,
  };
  return instance(request).then(({ data }) =>
    parseErrors(adminPreliminariesResponseSchema.parse, data)
  );
};

export const editAdminPreliminaries = (
  data: EditAdminPreliminariesRequest
): Promise<EditAdminPreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/preliminaries/${data.id}`,
    data,
  };
  return instance(request).then(
    ({ data }) => data
    // editAdminPreliminariesResponseSchema.parse(data)
  );
};

export const getAdminExamination = (
  data: AdminGetExaminationRequest
): Promise<AdminGetExaminationResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/preliminaries/${data.id}/edit`,
    params: {
      id: data.id,
    },
  };
  return instance(request).then(({ data }) =>
    adminGetExaminationResponseSchema.parse(data)
  );
};

export const updateAdminExamination = (
  data: UpdatePreliminariesRequest
): Promise<AdminUpdateExaminationResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/examination_results/${data.id}`,
    data: data.data,
  };
  return instance(request).then(({ data }) =>
    adminUpdateExaminationResponseSchema.parse(data)
  );
};

export const getLogEditedPreliminary = (
  params: logEditedRequest
): Promise<logEditResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/manager/activities',
    params: {
      id: params.id,
      trackable_id: params.trackable_id,
      trackable_type: params.trackable_type,
      column: params.column,
    },
  };
  return instance(request).then(({ data }) =>
    logEditResponseSchema.parse(data)
  );
};

export const changeStatusManagerPreliminary = (
  data: ChangeStatusRequest
): Promise<AdminPreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/manager/update_status',
    data: {
      id: data.id,
      status_result: data.status_result,
    },
  };
  return instance(request).then(({ data }) =>
    adminPreliminariesResponseSchema.parse(data)
  );
};

export const updateApproverConfirmation = (
  data: UpdateApproverConfirmationRequest
): Promise<UpdateApproverConfirmationResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/manager/update_approver_confirmation',
    data: {
      id: data.id,
      approver_confirmation: data.approver_confirmation,
    },
  };
  return instance(request).then(({ data }) =>
    updateApproverConfirmationSchema.parse(data)
  );
};

export const checkUpdateStatus = (
  data: CheckUpdateStatusRequest
): Promise<CheckUpdateStatusResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/check_update_status`,
    data: {
      id: data.id,
      status_result: data.status_result,
    },
  };
  return instance(request).then(({ data }) =>
    checkUpdateStatusResponse.parse(data)
  );
};

export const getReferralAgencyAdminOptions = (
  data: getReferralAgencyOptionRequest
): Promise<ReferralAgencyAdminOptionResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/referral_agency_options`,
    params: getReferralAgencyOptionRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    getReferralAgencyAdminOptionResponseSchema.parse(data)
  );
};
