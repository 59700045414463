import { FC, useCallback, useState } from 'react';
import { ArrowBackIos, ArrowForwardIos, List } from '@mui/icons-material';
import { Drawer, Stack, Typography } from '@mui/material';
import { Button } from 'components';
import { hiddenScrollBar } from 'styles/toolkit';

export const DrawerMenu: FC = () => {
  const [open, setOpen] = useState(false);

  const handleToggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };

  const getStyles = useCallback((disabled?: boolean, active?: boolean) => {
    if (disabled) {
      return {
        bgcolor: 'main_white',
        borderColor: 'off_text',
        color: 'off_text',
      };
    }
    return active
      ? {
          bgcolor: 'h_blugreen',
          borderColor: 'h_blugreen',
          color: 'main_white',
        }
      : {
          bgcolor: 'main_white',
          borderColor: 'h_blugreen',
          color: 'h_blugreen',
        };
  }, []);

  return (
    <Stack>
      {!open && (
        <Button
          sx={{
            bgcolor: 'blue07',
            minHeight: 40,
            minWidth: 40,
            borderRadius: '2px',
            zIndex: 1,
            p: 0,
            height: 40,
            boxShadow: 'none',
          }}
          onClick={handleToggleDrawer}
        >
          <Stack
            display={{ mobile: 'none', tablet: 'flex' }}
            justifyContent="center"
            alignItems="center"
          >
            <List sx={{ color: 'h_blugreen', width: 24, height: 24 }} />
            <Typography variant="buttonDrawer" color="h_blugreen">
              MENU
            </Typography>
          </Stack>
          <Stack
            display={{ tablet: 'none' }}
            justifyContent="center"
            alignItems="center"
          >
            <ArrowForwardIos
              sx={{ color: 'h_blugreen', fontSize: 18, pl: 1 }}
            />
          </Stack>
        </Button>
      )}
      <Drawer anchor="left" open={open} onClose={handleToggleDrawer}>
        <Stack
          sx={{
            height: '100%',
            width: 160,
          }}
        >
          <Stack
            sx={{
              py: 3,
              height: 90,
              borderBottom: '1px solid',
              borderColor: 'gray',
              position: 'relative',
            }}
            alignItems="end"
            justifyContent="center"
            bgcolor="main_white"
          >
            <Button
              sx={{
                bgcolor: 'blue07',
                borderRadius: '2px',
                minHeight: 44,
                minWidth: 44,
                p: '6px 0 0 0',
                boxShadow: 'none',
                position: 'absolute',
                bottom: 9,
                right: 0,
                mr: '2px',
              }}
              onClick={handleToggleDrawer}
            >
              <Stack justifyContent="center" alignItems="center">
                <ArrowBackIos
                  sx={{ color: 'h_blugreen', fontSize: 18, pl: 1 }}
                />
                <Typography variant="buttonDrawer" color="h_blugreen">
                  閉じる
                </Typography>
              </Stack>
            </Button>
          </Stack>
          <Stack
            sx={{
              bgcolor: 'main_white',
              height: '100%',
              px: 2,
              pb: 3,
              overflowY: 'auto',
              ...hiddenScrollBar,
            }}
          >
            {menuOptions.map((menuOption, index) => (
              <Stack key={index} my={3} spacing={2} alignItems="center">
                <Typography variant="drawerText">{menuOption.label}</Typography>
                {menuOption.options.map((option, index) => {
                  const { bgcolor, color, borderColor } = getStyles(
                    option.disabled,
                    option.active
                  );
                  return (
                    <Button
                      key={index}
                      fullWidth
                      disabled={option.disabled}
                      href={option.href}
                      sx={{
                        minHeight: 46,
                        '&&&': {
                          bgcolor,
                        },
                        px: 0,
                        boxShadow: 'none',
                        borderRadius: 1,
                        border: '2px solid',
                        borderColor,
                      }}
                    >
                      <Typography variant="drawerButtonText" sx={{ color }}>
                        {option.title}
                      </Typography>
                    </Button>
                  );
                })}
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
};

const menuOptions: {
  label: string;
  options: {
    active?: boolean;
    title: string;
    disabled?: boolean;
    href?: string;
  }[];
}[] = [
  {
    label: '住宅ローン審査',
    options: [
      {
        active: true,
        title: '事前審査',
        href: '/',
      },
      {
        disabled: true,
        title: '本審査',
      },
    ],
  },
  {
    label: 'ご契約中の方へ',
    options: [
      {
        disabled: true,
        active: true,
        title: 'ご契約手続き',
      },
    ],
  },
  {
    label: 'ツール',
    options: [
      {
        disabled: true,
        title: 'ご契約・返済状況',
      },
      {
        disabled: true,
        title: '繰り上げ返済',
      },
      {
        disabled: true,
        title: '金利切替',
      },
      {
        title: '予約状況',
      },
      {
        title: 'シミュレーション',
      },
    ],
  },
];
