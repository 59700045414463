import { FC } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';

export type StatusItemWithPhaseId = {
  key: string;
  value: number;
  active: boolean;
  phaseId: string;
};

export type StatusItemsWithPhaseIdProps = {
  data: StatusItemWithPhaseId[];
  onClick: () => void;
  active: boolean;
};

export const StatusItems: FC<StatusItemsWithPhaseIdProps> = ({
  data,
  onClick,
  active,
}) => (
  <>
    {data.map((item, index) => (
      <TableRow
        key={index}
        sx={{
          bgcolor: 'main_white',
          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor: data.length - 1 === index ? 'b_333' : 'bg_gray',
          alignItems: 'center',
          height: 24,
        }}
      >
        {index === 0 && (
          <TableCell
            sx={{
              textAlign: 'center',
              width: 113,
              padding: '2px 0',
              borderRight: '1px solid',
              borderColor: data.length - 1 === index ? 'b_333' : 'bg_gray',
              borderBottom: 'none',
              position: 'relative',
              flexDirection: 'row',
              // cursor: 'pointer',
              bgcolor: active ? 'sp_primary_100_main' : undefined,
              pointerEvents: active ? 'none' : 'unset',
            }}
            // onClick={onClick}
            rowSpan={data.length}
          >
            <Typography variant="text_manager_name" color="main_white">
              ▼
            </Typography>
          </TableCell>
        )}
        <TableCell
          sx={{
            width: 70,
            padding: '2px 0',
            textAlign: 'center',
            borderRight: '1px solid',
            borderRightColor: 'bg_gray',
            borderBottomColor: data.length - 1 === index ? 'b_333' : 'bg_gray',
            position: 'relative',
            flexDirection: 'row',
          }}
        >
          <Typography variant="text_manager_name" color="b_333">
            {item.value}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            width: 130,
            padding: '2px 0',
            pl: '10px',
            textAlign: 'left',
            borderRight: '1px solid',
            borderRightColor: 'bg_gray',
            borderBottomColor: data.length - 1 === index ? 'b_333' : 'bg_gray',
            position: 'relative',
            flexDirection: 'row',
          }}
        >
          <Typography variant="text_manager_name" color="b_333">
            {item.phaseId}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            height: '100%',
            flex: 1,
            pl: '10px',
            pt: 0,
            pb: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            borderBottomColor: data.length - 1 === index ? 'b_333' : 'bg_gray',
            position: 'relative',
            flexDirection: 'row',
          }}
        >
          <Typography variant="text_manager_name" color="b_333">
            {item.key}
          </Typography>
        </TableCell>
      </TableRow>
    ))}
  </>
);
