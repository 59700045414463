import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  CreateMemoRequest,
  CreateMemoResponse,
  GetMemoRequest,
  GetMemoResponse,
  MemoDetailResponse,
  MemoError,
  UpdateMemoRequest,
  UpdateMemoResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const getMemos = createAsyncThunk<
  GetMemoResponse,
  GetMemoRequest,
  ThunkAPI<MemoError>
>('Memos/getMemos', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getMemos(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createMemo = createAsyncThunk<
  CreateMemoResponse,
  CreateMemoRequest
>('Memos/createMemo', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.createMemo(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateMemo = createAsyncThunk<
  UpdateMemoResponse,
  UpdateMemoRequest
>('Memos/updateMemo', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.updateMemo(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const memoDetail = createAsyncThunk<
  MemoDetailResponse,
  UpdateMemoRequest
>('Memos/getMemoDetail', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getMemoDetail(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
