import {
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Stack,
  Typography,
  IconButton,
  TableCell,
  Divider,
  styled,
} from '@mui/material';
import { Icons, ApplicantItems } from 'components';
import { FC, MouseEvent, useMemo } from 'react';
import { ApplicantItem } from 'services';
import { HeadCellProps, OrderType, TableProps } from 'types';

export const ApplicantTable: FC<TableProps<ApplicantItem>> = ({
  onRequestSort,
  order,
  orderBy,
  rows,
  isAdmin,
}) => {
  const headCells: HeadCellProps<ApplicantItem>[] = useMemo(
    () => [
      {
        id: 'proposalNumber',
        label: '案件番号',
        style: { justifyContent: 'flex-end', width: 140 },
      },
      {
        id: 'bankName',
        label: '銀行名',
        style: { flex: 1, width: 54 },
      },
      {
        id: 'applicationDate',
        label: '申込日時',
        style: { width: 98 },
      },
      {
        id: 'situation',
        label: 'クイック',
        style: { width: 77 },
      },
      {
        id: 'endDate',
        label: '審査完了予測',
        style: { width: 149 },
      },
      {
        id: 'loanAmount',
        label: '融資金額',
        smallLabel: '（万円）',
        style: { width: 110 },
      },
      {
        id: 'interestRate',
        label: '金利',
        style: { width: 59 },
      },
      {
        id: 'condition',
        label: '条件',
        style: { width: 60 },
      },
      {
        id: 'bankClerk',
        label: '銀行担当者',
        style: { width: 204, justifyContent: 'flex-start' },
      },
    ],
    []
  );
  const createSortHandler =
    (property: keyof ApplicantItem) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <TableHead sx={{ display: 'flex', minWidth: 1130 }}>
        <TableRow
          sx={{
            display: 'flex',
            flex: 1,
            boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            pr: isAdmin ? '113px' : 0,
          }}
        >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sx={{
                ...headerCellStyle,
                ...headCell.style,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  px:
                    headCell.id === 'proposalNumber' ||
                    headCell.id === 'bankClerk'
                      ? 2
                      : 0,
                }}
              >
                <Typography
                  variant="table_header_text"
                  sx={{ whiteSpace: 'nowrap' }}
                  color="normal_text"
                >
                  {headCell.label}
                </Typography>
                {headCell.smallLabel && (
                  <Typography
                    variant="table_small_text"
                    sx={{ whiteSpace: 'nowrap' }}
                    color="normal_text"
                  >
                    {headCell.smallLabel}
                  </Typography>
                )}
                <TableSort
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                />
              </Stack>
              {(headCell !== headCells[headCells.length - 1] || isAdmin) && (
                <DividerStyled orientation="vertical" />
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <Stack
        sx={{
          px: '7px',
          bgcolor: 'bg_off',
          minWidth: 1116,
          overflowX: 'auto',
          height: 'calc(100vh - 142px)',
        }}
      >
        <ApplicantItems data={rows} isAdmin={isAdmin} />
      </Stack>
    </TableContainer>
  );
};

type TableSortProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const TableSort: FC<TableSortProps> = ({ active, direction, onClick }) => (
  <IconButton sx={{ p: 1 }} onClick={onClick}>
    {direction === 'asc' ? (
      <Icons.SortUp
        sx={{
          width: 10,
          height: 10,
          color: active ? 'h_blugreen' : 'transparent',
        }}
      />
    ) : (
      <Icons.SortDown sx={{ width: 10, height: 10 }} />
    )}
  </IconButton>
);

const headerCellStyle = {
  height: 40,
  p: 0,
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  border: 'none',
};

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));
