import { MESSAGE_REGEX, SP_FIELD_ERROR_MESSAGE } from 'constant';
import { regex, yup } from 'libs';

export const validationSchema = (loanTermYearNum: string) => {
  return yup.object().shape(
    {
      p_applicant_people: yup.object().shape(
        {
          last_name_kanji: yup
            .string()
            .trim()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(48)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
          first_name_kanji: yup
            .string()
            .trim()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(48)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
          last_name_kana: yup
            .string()
            .trim()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(48)
            .matches(
              regex.kanaHalfwidthHaveSpace,
              '全角のカタカナが入力可能です。'
            ),
          first_name_kana: yup
            .string()
            .trim()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(48)
            .matches(
              regex.kanaHalfwidthHaveSpace,
              '全角カタカナが入力可能です。'
            ),
          sex: yup.string(),
          birthday: yup
            .string()
            .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month, day] = value
                  ? value.split('/')
                  : ['', '', ''];
                return (
                  (!!year && !!month && !!day) || (!year && !month && !day)
                );
              }
            )
            .label(SP_FIELD_ERROR_MESSAGE.applicant_people_birthday),
          // .test(
          //   'sum-validation',
          //   MESSAGE_REGEX.LOAN_TERM_LIMIT,
          //   function (value) {
          //     const birthdayDayjs = dayjs(value, 'YYYY/MM/DD');
          //     const birthdayMonth = birthdayDayjs.diff(
          //       dayjs().format('YYYY-MM-DD'),
          //       'year'
          //     );
          //     if (+loanTermYearNum + Math.abs(birthdayMonth) > 80)
          //       return false;
          //     return true;
          //   }
          // )
          nationality: yup.string(),
          mobile_phone_number: yup
            .string()
            // .min(
            //   VALIDATE_MAX.MOBILE_PHONE,
            //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_SP
            // )
            // .noZeroPhoneNumber()
            .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER)
            .when('home_phone_number', {
              is: (homePhone: string) => !homePhone || homePhone.length === 0,
              then: yup.string().required(),
            }),
          home_phone_number: yup
            .string()
            // .noZeroPhoneNumber()
            // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_SP)
            .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER)
            .when('mobile_phone_number', {
              is: (phoneNumber: string) =>
                !phoneNumber || phoneNumber.length === 0,
              then: yup.string().required(),
            }),
          postal_code: yup
            .string()
            .min(8, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
            .matches(regex.zipCode, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
            .noWhitespace(),
          prefecture_kanji: yup
            .string()
            .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
            .max(8)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH)
            .label(SP_FIELD_ERROR_MESSAGE.applicant_people_prefecture_kanji),
          city_kanji: yup
            .string()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(20)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
          district_kanji: yup
            .string()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.ADDRESS_KANJI
            ),
          other_address_kanji: yup
            .string()
            .max(99)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.ADDRESS_KANJI
            )
            .required(MESSAGE_REGEX.REQUIRED),
          owner_email: yup
            .string()
            .max(128)
            .matches(regex.email, MESSAGE_REGEX.EMAIL_SP)
            .label('メールアドレス'),
        },
        [['mobile_phone_number', 'home_phone_number']]
      ),
    },
    []
  );
};
