import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import {
  Button,
  Icons,
  SPImageDetailModal,
  SPUploadImageModal,
} from 'components';
import {
  checkIfImageExists,
  checkIfPDFExists,
  dataUrlToFile,
  getFileExtFromUrl,
} from 'utils';
import { isEqual, uniqueId } from 'lodash';
import { pdf } from 'assets';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { IMAGE_TYPES } from 'react-html5-camera-photo';

export type SPImage = {
  id: string;
  url: string;
  name: string;
  hiden?: boolean;
};
type SPUploadImageItemProps = {
  title?: string;
  variant?: 'vertical' | 'horizontal' | 'imageOnly';
  images: (File | SPImage)[];
  setImages: (images: (File | SPImage)[]) => void;
  disable?: boolean;
  uploadMulti?: boolean;
  handleDeleteImage?: (idDelete: string) => void;
  reload?: number;
};

export const SPUploadImageItem: FC<SPUploadImageItemProps> = ({
  images,
  setImages,
  variant = 'horizontal',
  disable = false,
  uploadMulti = true,
  handleDeleteImage,
  reload,
  ...props
}) => {
  const [openModal, setOpenModal] = useState(false);
  const containerImagesRef = useRef<HTMLDivElement | null>(null);
  const handleChangeFiles = useCallback(
    (files: File[]) => {
      setImages([
        ...images.filter((image) => {
          if (uploadMulti) return true;
          return image instanceof File ? true : !image.hiden;
        }),
        ...files,
      ]);
      setOpenModal(false);
    },
    [images, setImages, uploadMulti]
  );

  const handleDelete = useCallback(
    (image: File | SPImage) => {
      if (!(image instanceof File) && handleDeleteImage) {
        handleDeleteImage(image.id);
      }
      return setImages(images.filter((file) => !isEqual(file, image)));
    },
    [handleDeleteImage, images, setImages]
  );
  useEffect(() => {
    if (containerImagesRef.current && images) {
      containerImagesRef.current.scrollLeft =
        containerImagesRef.current.scrollWidth;
    }
  }, [images]);

  const imagesShow = useMemo(
    () =>
      images?.filter((image) => (image instanceof File ? true : !image.hiden)),
    [images]
  );

  const renderImage = useCallback(() => {
    if (!imagesShow?.length)
      return (
        <Button
          onClick={() => setOpenModal(true)}
          sx={{
            bgcolor: 'sp_primary_100_main',
            pb: 3,
            pt: 5,
            borderRadius: '14px',
            width: '136px',
            '&.Mui-disabled': {
              bgcolor: 'sp_primary_20',
            },
          }}
          disabled={disable}
        >
          <Stack justifyContent="center" alignItems="center" spacing={4}>
            <Icons.Camera sx={{ width: '36px', height: '31px' }} />
            <Typography
              variant="upload_image_text"
              color="main_white"
              sx={{ whiteSpace: 'nowrap' }}
            >
              画像アップロード
            </Typography>
          </Stack>
        </Button>
      );

    if (variant === 'vertical')
      return (
        <Grid
          container
          justifyContent="center"
          spacing={3}
          sx={{ position: 'relative' }}
        >
          {imagesShow?.map((image, index) => (
            <Grid key={index} item xs={6}>
              <ImageItem
                src={image instanceof File ? image : image.url}
                reload={reload}
                onDelete={() => handleDelete(image)}
                fileName={image.name}
                type={
                  image instanceof File
                    ? image.type
                    : getFileExtFromUrl(image.url)
                }
              />
            </Grid>
          ))}
          {imagesShow.length % 2 !== 0 && (
            <Grid item xs={6} sx={{ width: 136, height: 100 }} />
          )}
        </Grid>
      );

    return (
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        pr={variant === 'imageOnly' ? 0 : 2}
        ref={containerImagesRef}
      >
        <Stack direction="row" sx={{ overflow: 'auto' }} spacing={1}>
          {imagesShow?.map((image, index) => (
            <ImageItem
              key={index}
              fileName={image.name}
              src={image instanceof File ? image : image.url}
              reload={reload}
              onDelete={() => handleDelete(image)}
              type={
                image instanceof File
                  ? image.type
                  : getFileExtFromUrl(image.url)
              }
            />
          ))}
        </Stack>
        {uploadMulti && (
          <Stack
            sx={{
              width: '90px',
              height: '88px',
              boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
              borderRadius: '14px',
            }}
          >
            <Button
              sx={{
                borderRadius: '14px',
                width: '90px',
                height: '88px',
                bgcolor: 'main_white',
                border: (theme) => `1px solid ${theme?.palette?.sp_primary_40}`,
                boxShadow: 0,
              }}
              onClick={() => setOpenModal(true)}
            >
              <Stack justifyContent="center" alignItems="center">
                <Icons.SPCircle
                  sx={{
                    width: '32px',
                    height: '32px',
                    color: 'sp_primary_100_main',
                  }}
                />
                <Typography
                  sx={{ whiteSpace: 'nowrap' }}
                  color="sp_primary_100_main"
                >
                  画像追加
                </Typography>
              </Stack>
            </Button>
          </Stack>
        )}
      </Stack>
    );
  }, [imagesShow, disable, variant, uploadMulti, reload, handleDelete]);

  if (variant === 'imageOnly')
    return (
      <Stack
        sx={{
          pt: 3,
          ml: uploadMulti ? 0 : window.innerWidth <= 332 ? 1 : 3,
          width: uploadMulti ? 'auto' : '136px',
          overflowX: 'auto',
        }}
      >
        {renderImage()}

        <SPUploadImageModal
          open={openModal}
          onChange={handleChangeFiles}
          onClose={() => setOpenModal(false)}
          multiple={uploadMulti}
          onTakePhoto={async (dataUri) => {
            const file = await dataUrlToFile(
              dataUri,
              `${uniqueId('photo-')}.${IMAGE_TYPES.JPG}`
            );
            setImages([...images, file]);
            setOpenModal(false);
          }}
        />
      </Stack>
    );

  return (
    <Stack
      textAlign="center"
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        p: '18px 0px 16px 0px',
        cursor: 'pointer',
      }}
    >
      {variant === 'vertical' ? (
        <Stack sx={{ width: '100%' }} alignItems="center" spacing={8}>
          <Stack
            sx={{
              width: '100%',
              borderRadius: 2,
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
              pb: 3,
              bgcolor: 'main_white',
            }}
          >
            {!!props.title && (
              <Stack
                sx={{
                  bgcolor: 'sp_primary_40',
                  borderTopRightRadius: '14px',
                  borderTopLeftRadius: '14px',
                  py: 1,
                }}
              >
                <Typography variant="upload_image_title" color="b_333">
                  {props.title}
                </Typography>
              </Stack>
            )}
            <Stack sx={{ pt: 3, ml: 3 }}>
              {renderImage()}

              <SPUploadImageModal
                open={openModal}
                onChange={handleChangeFiles}
                onClose={() => setOpenModal(false)}
                onTakePhoto={async (dataUri) => {
                  const file = await dataUrlToFile(
                    dataUri,
                    `${uniqueId('photo-')}.${IMAGE_TYPES.JPG}`
                  );
                  setImages([...images, file]);
                  setOpenModal(false);
                }}
              />
            </Stack>
          </Stack>
          {variant === 'vertical' && !!images.length && (
            <Stack sx={{ width: '90px', height: '88px' }}>
              <Button
                sx={{
                  borderRadius: '14px',
                  width: '90px',
                  height: '88px',
                  bgcolor: 'main_white',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_40}`,
                }}
                onClick={() => setOpenModal(true)}
              >
                <Stack justifyContent="center" alignItems="center" spacing={2}>
                  <Icons.SPCircle
                    sx={{ width: 40, height: 40, color: 'sp_primary_100_main' }}
                  />
                  <Typography
                    sx={{ whiteSpace: 'nowrap' }}
                    color="sp_primary_100_main"
                  >
                    画像追加
                  </Typography>
                </Stack>
              </Button>
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack
          sx={{
            width: '100%',
            border: (theme) =>
              `1px solid ${theme?.palette?.sp_primary_100_main}`,
            borderRadius: '14px',
            pb: 3,
          }}
        >
          {!!props.title && (
            <Stack
              sx={{
                bgcolor: 'sp_primary_40',
                borderTopRightRadius: '14px',
                borderTopLeftRadius: '14px',
                py: 1,
              }}
            >
              <Typography variant="upload_image_title" color="b_333">
                {props.title}
              </Typography>
            </Stack>
          )}
          <Stack sx={{ width: 'auto', ml: 3, overflowX: 'auto', pt: 3 }}>
            {renderImage()}
            <SPUploadImageModal
              open={openModal}
              onChange={handleChangeFiles}
              onClose={() => setOpenModal(false)}
              onTakePhoto={async (dataUri) => {
                const file = await dataUrlToFile(
                  dataUri,
                  `${uniqueId('photo-')}.${IMAGE_TYPES.JPG}`
                );
                setImages([...images, file]);
                setOpenModal(false);
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

type ImageItemProps = {
  src: string | File;
  fileName: string;
  onDelete: () => void;
  type: string;
  reload?: number;
};

const ImageItem: FC<ImageItemProps> = ({
  src,
  fileName,
  onDelete,
  type,
  reload,
}) => {
  const [openImageDetail, setOpenImageDetail] = useState<boolean>(false);
  const [image, setImage] = useState('');
  const [active, setActive] = useState<boolean>(true);

  useEffect(() => {
    if (type.includes('pdf')) {
      if (src instanceof File) {
        checkIfPDFExists(URL.createObjectURL(src)).then(setActive);
      } else {
        checkIfPDFExists(src).then(setActive);
      }
    } else {
      checkIfImageExists(src).then(setActive);
    }
  }, [src, type, reload]);
  return (
    <Stack direction="column" alignItems="center">
      <Button
        sx={{ bgcolor: 'bg_off', border: 'none', boxShadow: 0, p: 0 }}
        onClick={() => {
          setOpenImageDetail(true);
          if (src instanceof File) {
            setImage(URL.createObjectURL(src));
          } else {
            setImage(src);
          }
        }}
      >
        <Avatar
          variant="square"
          src={
            type.includes('pdf')
              ? pdf
              : src instanceof File
              ? URL.createObjectURL(src)
              : src
          }
          sx={{
            width: active ? 136 : 132,
            height: active ? 100 : 96,
            '.MuiAvatar-img': {
              objectFit: type.includes('pdf') ? 'contain' : 'cover',
            },
            borderWidth: active ? 0 : 1,
            borderRadius: active ? 0 : '2px',
            borderStyle: 'solid',
            borderColor: active ? '' : 'error.main',
          }}
        >
          <PersonOffIcon />
        </Avatar>
      </Button>
      <Typography
        pt={1}
        variant="upload_image_title"
        color="b_333"
        sx={{ width: 135, textAlign: 'center', lineBreak: 'anywhere' }}
      >
        {type.includes('pdf') ? fileName : ''}
      </Typography>
      {!active && (
        <Typography
          maxWidth="136px"
          variant="text_error"
          color="sp_secondary_01"
        >
          ※アップロードした書類にエラーがあります。再度アップロードしてください。
        </Typography>
      )}

      <SPImageDetailModal
        open={openImageDetail}
        onClose={() => setOpenImageDetail((prevState) => !prevState)}
        onDelete={onDelete}
        src={image}
        type={type}
      />
    </Stack>
  );
};
