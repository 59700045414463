import { AdminPreliminaryItem } from 'constant';
import { SalePersonPreliminaries } from 'services';
import {
  buildFinancingPlan,
  convertToNumber,
  getValueDisplay,
} from '../../AdminEditPreliminary/utils';

export const buildFinancingPlanSalePerson = (
  values: SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  return buildFinancingPlan(values)
    .filter(({ required, name_en }) => {
      if (name_en === 'require_funds_breakdown_mortgage') {
        return values.is_home_loan_plus;
      }
      return !required;
    })
    .map((row) => {
      if (row.name_en === 'total_price_1') {
        return {
          ...row,
          defaultValue: getValueDisplay(
            convertToNumber(values.house_purchase_price) +
              convertToNumber(values.land_purchase_price) +
              convertToNumber(values.additional_cost) +
              convertToNumber(values.require_funds_breakdown_mortgage) +
              convertToNumber(values.accessory_cost) +
              convertToNumber(values.refinancing_loan_balance) +
              convertToNumber(values.house_upgrade_cost)
          ),
        };
      }
      if (row.name_en === 'total_price_2') {
        return {
          ...row,
          defaultValue: getValueDisplay(
            convertToNumber(values.deposit_savings_1) +
              convertToNumber(values.real_estate_sale_price) +
              convertToNumber(values.other_saving_amount) +
              convertToNumber(values.relative_donation_amount) +
              convertToNumber(values.loan_amount) +
              convertToNumber(values.pair_loan_amount) +
              convertToNumber(values.other_procurement_breakdown)
          ),
        };
      }
      return row;
    });
};
