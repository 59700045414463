import { FC, useCallback, useState } from 'react';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { AuthPopover, Icons, Steps } from 'components';
import { steps } from 'constant';
import { useAppDispatch } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from 'containers/AuthModal/thunk';
import { routeNames } from 'navigations/routes';
import { theme } from 'styles';
import { clearSalePerson } from '../../../../libs';

interface HeaderProps {
  currentStep: number;
  title?: string;
  isEditing?: boolean;
}

export const Header: FC<HeaderProps> = ({ currentStep, title, isEditing }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleLogout = useCallback(async () => {
    await dispatch(logout());
    clearSalePerson();
    navigate(routeNames.Login.path);
  }, [dispatch, navigate]);

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'main_white',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        borderTop: '1px solid',
        borderColor: 'gray',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
      }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Link href="/">
        <Box sx={{ px: { mobile: 4, tablet: 8 }, pt: 3, pb: 2 }}>
          <Icons.LogoNew />
        </Box>
      </Link>
      <Divider
        orientation="vertical"
        sx={{
          height: 30,
          borderColor: 'gray',
        }}
      />
      {isEditing ? (
        <>
          <Stack sx={{ whiteSpace: 'nowrap', ml: 8 }}>
            <Typography variant="button_title" color="normal_text">
              {title}
            </Typography>
          </Stack>
          <Stack
            flex={1}
            direction="row"
            justifyContent="flex-end"
            sx={{ pr: 4 }}
          >
            <AuthPopover
              sx={{ top: 20 }}
              content={
                <>
                  <Stack
                    sx={{
                      right: '-10px',
                      padding: 3,
                      flex: 1,
                      width: 'auto',
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      borderBottom: (theme) =>
                        `1px solid ${theme?.palette?.line}`,
                      '&:hover': {
                        backgroundColor: theme?.palette?.bg_disabled,
                      },
                    }}
                    onClick={() => {
                      navigate(routeNames.UpdatePassword.path);
                    }}
                  >
                    パスワード変更
                  </Stack>
                  <Stack
                    sx={{
                      padding: 3,
                      flex: 1,
                      width: 'auto',
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      '&:hover': {
                        backgroundColor: theme?.palette?.bg_disabled,
                      },
                    }}
                    onClick={handleLogout}
                  >
                    ログアウト
                  </Stack>
                </>
              }
              open={open}
              placement="bottom-start"
              contentProps={{
                width: 230,
                height: 89,
                padding: 0,
                color: 'normal_text',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                onClick={() => setOpen((prevState) => !prevState)}
              >
                <Icons.User />
                <Icons.Select
                  sx={{
                    width: 10,
                    height: 10,
                  }}
                />
              </Stack>
            </AuthPopover>
          </Stack>
        </>
      ) : (
        <Stack
          direction="row"
          spacing={{ mobile: 3, tablet: 7 }}
          alignItems="center"
          sx={{
            overflowX: 'auto',
            pl: { mobile: 3, tablet: 7 },
          }}
        >
          <Typography variant="text_steps" color="h_blugreen">
            事前審査
          </Typography>
          <Steps steps={steps} currentStep={currentStep} />
        </Stack>
      )}
    </Stack>
  );
};
