import React, { FC } from 'react';
import {
  FormHelperText,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { zeroPad } from 'utils';
import { dayjs } from 'libs/dayjs';
import { epochYear } from 'constant';
import { Close as CloseIcon } from '@mui/icons-material';
import { theme } from 'styles';
import { Button, InputDateTime, InputSelectProps } from '../../atoms';
import { validationSchema } from './validationSchema';

interface FilterModalItemProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: { from_date: string; to_date: string }) => void;
}

export const DownloadCsvModal: FC<FilterModalItemProps> = ({
  onClose,
  onSubmit,
  open,
}) => {
  const formik = useFormik({
    initialValues: {
      from_date: '',
      to_date: '',
    },
    validationSchema,
    async onSubmit(values, form) {
      await onSubmit(values);
      onClose();
      form.resetForm({});
      form.setSubmitting(false);
    },
  });

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={() => onClose()}
    >
      <FormikProvider value={formik}>
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'main_white',
            width: { mobile: '90%', desktop: 485 },
            height: 300,
          }}
        >
          <Stack
            direction="row"
            sx={{
              height: 54,
              borderBottom: `1px solid ${theme?.palette?.line}`,
            }}
          >
            <Stack flex={1}></Stack>
            <Stack flex={2} justifyContent="center">
              <Typography
                variant="required_chip_label"
                fontSize={16}
                sx={{ alignSelf: 'center', color: 'b_333' }}
              >
                監視ログダウンロード
              </Typography>
            </Stack>
            <Stack flex={1} justifyContent="center">
              <Stack
                sx={{
                  position: 'absolute',
                  right: 10,
                }}
              >
                <IconButton sx={{ p: 0 }} onClick={onClose}>
                  <CloseIcon fontSize="small" sx={{ color: 'close_icon' }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
          <Stack px={12} spacing={6} pt={6}>
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                width: { mobile: '100%', tablet: 200, desktop: '100%' },
              }}
            >
              <InputDateTime
                yearOptions={yearCurrentOptions}
                monthOptions={monthOptions}
                name="from_date"
                hasError={!!formik.errors.from_date}
                selectSx={{
                  height: 30,
                  width: 62,
                  backgroundColor: 'unset',
                  '&&&.Mui-success fieldset': {
                    borderWidth: 1,
                    borderColor: 'gray',
                  },
                  '&&&& fieldset': {
                    borderWidth: 1,
                  },
                  '&&&&& .MuiTypography-input_style': {
                    fontSize: '15px',
                    fontWeight: '300',
                    lineHeight: '15px',
                  },
                  '&&&&& .MuiTypography-placeHolder_style': {
                    fontSize: '10px',
                    lineHeight: '15px',
                  },
                  '&&&& .MuiSvgIcon-fontSizeMedium': {
                    width: 10,
                    height: 10,
                    top: 'calc(50% - 4px)',
                    right: '6px',
                  },
                  '&&&&& .MuiInputBase-input': {
                    padding: 1,
                  },
                }}
                unitSx={{ fontSize: 12 }}
              />
              <Typography
                variant="text_dashboard"
                color="normal_text"
                sx={{
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                  marginLeft: '4px',
                }}
              >
                から
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                width: { mobile: '100%', tablet: 200, desktop: '100%' },
              }}
            >
              <InputDateTime
                yearOptions={yearCurrentOptions}
                monthOptions={monthOptions}
                name="to_date"
                hasError={!!formik.errors.to_date}
                selectSx={{
                  height: 30,
                  width: 62,
                  backgroundColor: 'unset',
                  '&&&.Mui-success fieldset': {
                    borderWidth: 1,
                    borderColor: 'gray',
                  },
                  '&&&& fieldset': {
                    borderWidth: 1,
                  },
                  '&&&&& .MuiTypography-input_style': {
                    fontSize: '15px',
                    fontWeight: '300',
                    lineHeight: '15px',
                  },
                  '&&&&& .MuiTypography-placeHolder_style': {
                    fontSize: '10px',
                    lineHeight: '15px',
                  },
                  '&&&& .MuiSvgIcon-fontSizeMedium': {
                    width: 10,
                    height: 10,
                    top: 'calc(50% - 4px)',
                    right: '6px',
                  },
                  '&&&&& .MuiInputBase-input': {
                    padding: 1,
                  },
                }}
                unitSx={{ fontSize: 12 }}
              />
              <Typography
                variant="text_dashboard"
                color="normal_text"
                sx={{
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                  marginLeft: '4px',
                }}
              >
                まで
              </Typography>
            </Stack>
            {formik.errors.to_date === 'validateDateDiff' && (
              <FormHelperText error>
                <Typography
                  variant="text_dashboard"
                  align="center"
                  sx={{ pl: 5 }}
                >
                  終了日は開始日以降の日を指定してください。
                </Typography>
              </FormHelperText>
            )}
          </Stack>
          <Stack
            pt={6}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                bgcolor: 'white',
                boxShadow: 'none',
                width: '200px',
                height: '36px',
                borderRadius: '2px',
                minHeight: '36px',
                border: '1px solid #6B70F0',
              }}
              disabled={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              <Typography variant="button01" color="sp_primary_100_main">
                ダウンロード
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </FormikProvider>
    </Modal>
  );
};

const yearCurrentOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() - index);
    return {
      value: year,
      label: `${year} (${epochYear[+year]})`,
    };
  })
);

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: String(zeroPad(index + 1)),
    label: String(zeroPad(index + 1)),
  }))
);
