import { Close as CloseIcon } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  Modal,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { checkboxIcon, unCheckIcon } from 'assets';
import {
  Button,
  FilterModalItem,
  InputDateTime,
  InputSelectProps,
  InputField,
} from 'components';
import { FormikProvider, useFormik } from 'formik';
import { dayjs } from 'libs/dayjs';
import { masterDataSelectors } from 'pages/ManagerPreliminaries/selectors';
import { ChangeEvent, FC, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { styleScrollBarModel, theme } from 'styles';
import { convertToFullWidth, zeroPad } from 'utils';
import kanjidate from 'kanjidate';
import { validationSchema } from './validationSchema';

export type UploadDocumentSearchFilterData = {
  file_name: string[];
  create_date: { from: string; to: string };
  sale_person_names: string[];
  sale_agent_names: string[];
  format_file: string[];
};

interface UploadDocumentSearchProps {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  updateDataFilter?: (dataFilter: UploadDocumentSearchFilterData) => void;
}

export const UploadDocumentSearch: FC<UploadDocumentSearchProps> = (props) => {
  // TODO: get actual data: managers, partner
  const masterData = useSelector(masterDataSelectors);

  type ClearDateTimeHandle = React.ElementRef<typeof InputDateTime>;
  const registrationFromDateRef = useRef<ClearDateTimeHandle>(null);
  const registrationToDateRef = useRef<ClearDateTimeHandle>(null);

  const location = useLocation();
  const isManager = location.pathname.startsWith('/manager/', 0);

  const formik = useFormik({
    initialValues: {
      file_name: '',
      registration_date_from: '',
      registration_date_to: '',
      partners: [],
      managers: [],
      extensions: [],
      search: '',
    },
    validationSchema,
    async onSubmit(values) {
      props.onClose();
      const dataFilter: UploadDocumentSearchFilterData = {
        file_name: [values.file_name],
        create_date: {
          from: values.registration_date_from,
          to: values.registration_date_to,
        },
        sale_agent_names: values.partners,
        sale_person_names: values.managers,
        format_file: values.extensions,
      };
      !!props.updateDataFilter && props.updateDataFilter(dataFilter);
    },
  });

  const handleChangeFileName = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.setFieldValue(`file_name`, event.target.value);
  };

  const handleChangeManagerName = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.setFieldValue(`search`, event.target.value);
  };

  const handleClear = async () => {
    formik.resetForm();
    registrationFromDateRef.current?.clear();
    registrationToDateRef.current?.clear();
  };

  const handlePartnerClick = (value: string) => {
    if (!!formik.values.partners.find((e: string) => e === value)) {
      const partner = formik.values.partners.filter((e: string) => e !== value);
      formik.setFieldValue(`partners`, partner);
    } else {
      const partner = [...formik.values.partners, value];
      formik.setFieldValue(`partners`, partner);
    }
  };

  const handleExtensionsClick = (value: string) => {
    if (!!formik.values.extensions.find((e: string) => e === value)) {
      const extension = formik.values.extensions.filter(
        (e: string) => e !== value
      );
      formik.setFieldValue(`extensions`, extension);
    } else {
      const extension = [...formik.values.extensions, value];
      formik.setFieldValue(`extensions`, extension);
    }
  };

  const handleManagerClick = (value: string) => {
    if (!!formik.values.managers.find((e: string) => e === value)) {
      const manager = formik.values.managers.filter((e: string) => e !== value);
      formik.setFieldValue(`managers`, manager);
    } else {
      const manager = [...formik.values.managers, value];
      formik.setFieldValue(`managers`, manager);
    }
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props?.sx,
      }}
      disableAutoFocus
      {...props}
    >
      <FormikProvider value={formik}>
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'main_white',
            '@media (orientation: landscape)': {
              height: '90vh',
            },
            width: { mobile: '90%', desktop: 485 },
            maxHeight: '60vh',
          }}
        >
          <Stack
            direction="row"
            sx={{
              height: 54,
              borderBottom: `1px solid ${theme?.palette?.line}`,
            }}
          >
            <Stack
              flex={1}
              sx={{
                justifyContent: 'center',
                pl: 3,
                color: 'clear_button_blue',
              }}
            >
              <Typography
                onClick={handleClear}
                variant="table_small_text"
                fontSize={12}
                sx={{ color: 'sp_primary_100_main', cursor: 'pointer' }}
              >
                すべてクリア
              </Typography>
            </Stack>
            <Stack flex={2} justifyContent="center">
              <Typography
                variant="required_chip_label"
                fontSize={16}
                sx={{ alignSelf: 'center', color: 'b_333' }}
              >
                アップロード書類検索
              </Typography>
            </Stack>
            <Stack flex={1} justifyContent="center">
              <Stack
                sx={{
                  position: 'absolute',
                  right: 10,
                }}
              >
                <IconButton sx={{ p: 0 }} onClick={props.onClose}>
                  <CloseIcon fontSize="small" sx={{ color: 'close_icon' }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            pt={5}
            sx={{
              height: '100%',
              overflowY: 'scroll',
              paddingRight: '5px',
              backgroundColor: 'bg_off',
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            spacing={5}
          >
            <Stack
              px={{ tablet: 5, mobile: 5 }}
              sx={{
                overflowY: 'scroll',
                ...styleScrollBarModel,
              }}
              spacing={1}
            >
              <Stack
                spacing={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: 'main_white',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '2px',
                  justifyItems: 'center',
                  width: '100%',
                }}
              >
                <Stack
                  spacing={-5}
                  sx={{
                    height: '112px',
                    justifyContent: 'space-around',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="required_chip_label"
                    px={17}
                    fontSize={14}
                  >
                    ファイル名
                  </Typography>
                  <InputField
                    name="file_name"
                    placeholder="入力してください"
                    sx={{
                      paddingLeft: 17,
                      paddingRight: 17,
                      height: 44,
                      '.MuiInputBase-input': {
                        ...theme.typography.input_modal_filter,
                      },
                      '&&&& fieldset': {
                        borderWidth: 0,
                        borderBottomWidth: 1,
                        borderColor: 'b_333',
                        boxShadow: 'none',
                        borderRadius: 0,
                      },
                      '&&&& .Mui-success': {
                        '.MuiInputBase-input': {
                          backgroundColor: 'main_white',
                          boxShadow: 'none',
                        },
                        fieldset: {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'b_333',
                          boxShadow: 'none',
                        },
                      },
                      '&&&& .Mui-focused': {
                        '.MuiInputBase-input': {
                          backgroundColor: 'main_white',
                          color: 'b_333',
                        },
                        fieldset: {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'b_333',
                        },
                      },
                      '&&&& .Mui-focused.Mui-success': {
                        fieldset: {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'b_333',
                        },
                      },
                    }}
                    InputProps={{
                      autoComplete: 'off',
                      onChange: handleChangeFileName,
                    }}
                  />
                </Stack>
              </Stack>

              <FilterModalItem
                name=""
                title="登録日"
                conditionExpanded={
                  formik.values.registration_date_from !== '' ||
                  formik.values.registration_date_to !== ''
                }
              >
                <Stack px={12} spacing={2}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                      width: { mobile: '100%', tablet: 200, desktop: '100%' },
                    }}
                  >
                    <InputDateTime
                      yearOptions={yearCurrentOptionsFrom}
                      monthOptions={monthOptions}
                      name="registration_date_from"
                      ref={registrationFromDateRef}
                      selectSx={{
                        height: 30,
                        width: 62,
                        backgroundColor: 'unset',
                        '&&&.Mui-success fieldset': {
                          borderWidth: 1,
                          borderColor: 'gray',
                        },
                        '&&&& fieldset': {
                          borderWidth: 1,
                        },
                        '&&&&& .MuiTypography-input_style': {
                          fontSize: '15px',
                          fontWeight: '300',
                          lineHeight: '15px',
                        },
                        '&&&&& .MuiTypography-placeHolder_style': {
                          fontSize: '10px',
                          lineHeight: '15px',
                        },
                        '&&&& .MuiSvgIcon-fontSizeMedium': {
                          width: 10,
                          height: 10,
                          top: 'calc(50% - 4px)',
                          right: '6px',
                        },
                        '&&&&& .MuiInputBase-input': {
                          padding: 1,
                        },
                      }}
                      unitSx={{ fontSize: 12 }}
                    />
                    <Typography
                      variant="text_dashboard"
                      color="normal_text"
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                        marginLeft: '4px',
                      }}
                    >
                      から
                    </Typography>
                  </Stack>
                  <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                      width: { mobile: '100%', tablet: 200, desktop: '100%' },
                    }}
                  >
                    <InputDateTime
                      yearOptions={yearCurrentOptionsTo}
                      monthOptions={monthOptions}
                      name="registration_date_to"
                      ref={registrationToDateRef}
                      selectSx={{
                        height: 30,
                        width: 62,
                        backgroundColor: 'unset',
                        '&&&.Mui-success fieldset': {
                          borderWidth: 1,
                          borderColor: 'gray',
                        },
                        '&&&& fieldset': {
                          borderWidth: 1,
                        },
                        '&&&&& .MuiTypography-input_style': {
                          fontSize: '15px',
                          fontWeight: '300',
                          lineHeight: '15px',
                        },
                        '&&&&& .MuiTypography-placeHolder_style': {
                          fontSize: '10px',
                          lineHeight: '15px',
                        },
                        '&&&& .MuiSvgIcon-fontSizeMedium': {
                          width: 10,
                          height: 10,
                          top: 'calc(50% - 4px)',
                          right: '6px',
                        },
                        '&&&&& .MuiInputBase-input': {
                          padding: 1,
                        },
                      }}
                      unitSx={{ fontSize: 12 }}
                    />
                    <Typography
                      variant="text_dashboard"
                      color="normal_text"
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                        marginLeft: '4px',
                      }}
                    >
                      まで
                    </Typography>
                  </Stack>
                </Stack>
              </FilterModalItem>

              {isManager && (
                <>
                  <FilterModalItem
                    name="partner"
                    title="提携先"
                    conditionExpanded={formik.values.partners.length > 0}
                  >
                    <Stack
                      sx={{ maxHeight: '125px', overflow: 'auto' }}
                      spacing={3}
                    >
                      {masterData.sale_agent_names.map((opt, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          alignItems="center"
                          px={17}
                          spacing={1}
                          onClick={() => handlePartnerClick(opt)}
                        >
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="main_white"
                            height={13}
                            width={13}
                            border={`1px solid ${theme?.palette?.h_blugreen}`}
                            borderRadius={1}
                          >
                            <img
                              src={
                                formik.values.partners.find(
                                  (e: string) => opt === e
                                )
                                  ? checkboxIcon
                                  : unCheckIcon
                              }
                              alt=""
                              height={7}
                              width={9}
                            />
                          </Stack>
                          <Typography
                            variant="required_chip_label"
                            fontSize={14}
                            fontWeight={300}
                            sx={{ lineHeight: '20px', pt: '2px' }}
                          >
                            {opt}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </FilterModalItem>

                  <FilterModalItem
                    name="manager"
                    title="担当者"
                    conditionExpanded={formik.values.managers.length > 0}
                  >
                    <Stack spacing={3}>
                      <Stack
                        sx={{
                          height: 'auto',
                        }}
                      >
                        <InputField
                          name="search"
                          placeholder="キーワードを入力"
                          convertFullWidth
                          sx={{
                            paddingLeft: 15,
                            height: 44,
                            width: '70%',
                            '.MuiInputBase-input': {
                              ...theme.typography.input_modal_filter,
                            },
                            '&&&& fieldset': {
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderColor: 'b_333',
                              boxShadow: 'none',
                              borderRadius: 0,
                            },
                            '&&&& .Mui-success': {
                              '.MuiInputBase-input': {
                                backgroundColor: 'main_white',
                                boxShadow: 'none',
                              },
                              fieldset: {
                                borderWidth: 0,
                                borderBottomWidth: 1,
                                borderColor: 'b_333',
                                boxShadow: 'none',
                              },
                            },
                            '&&&& .Mui-focused': {
                              '.MuiInputBase-input': {
                                backgroundColor: 'main_white',
                                color: 'b_333',
                              },
                              fieldset: {
                                borderWidth: 0,
                                borderBottomWidth: 1,
                                borderColor: 'b_333',
                              },
                            },
                            '&&&& .Mui-focused.Mui-success': {
                              fieldset: {
                                borderWidth: 0,
                                borderBottomWidth: 1,
                                borderColor: 'b_333',
                              },
                            },
                          }}
                          InputProps={{
                            autoComplete: 'off',
                            onChange: handleChangeManagerName,
                          }}
                        />
                      </Stack>
                      <Stack px={10}>
                        <Divider />
                      </Stack>
                      <Stack
                        sx={{ height: '125px', overflow: 'auto' }}
                        spacing={3}
                      >
                        {masterData.sale_person_names
                          .filter((opt) =>
                            formik.values.search
                              ? opt.includes(formik.values.search) ||
                                convertToFullWidth(opt).includes(
                                  formik.values.search
                                )
                              : opt
                          )
                          .map((opt, index) => (
                            <Stack
                              key={index}
                              direction="row"
                              alignItems="center"
                              px={17}
                              spacing={1}
                              onClick={() => handleManagerClick(opt)}
                            >
                              <Stack
                                justifyContent="center"
                                alignItems="center"
                                bgcolor="main_white"
                                height={13}
                                width={13}
                                border={`1px solid ${theme?.palette?.h_blugreen}`}
                                borderRadius={1}
                              >
                                <img
                                  src={
                                    formik.values.managers.find(
                                      (e: string) => opt === e
                                    )
                                      ? checkboxIcon
                                      : unCheckIcon
                                  }
                                  alt=""
                                  height={7}
                                  width={9}
                                />
                              </Stack>
                              <Typography
                                variant="required_chip_label"
                                fontSize={14}
                                fontWeight={300}
                                sx={{ lineHeight: '20px', pt: '2px' }}
                              >
                                {opt}
                              </Typography>
                            </Stack>
                          ))}
                      </Stack>
                    </Stack>
                  </FilterModalItem>
                </>
              )}
              <FilterModalItem
                name="extension"
                title="拡張子"
                conditionExpanded={formik.values.extensions.length > 0}
              >
                <Stack
                  sx={{ maxHeight: '125px', overflow: 'auto' }}
                  spacing={3}
                >
                  {extensionOptions.map((opt, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      px={17}
                      spacing={1}
                      onClick={() => handleExtensionsClick(opt.value)}
                    >
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="main_white"
                        height={13}
                        width={13}
                        border={`1px solid ${theme?.palette?.h_blugreen}`}
                        borderRadius={1}
                      >
                        <img
                          src={
                            formik.values.extensions.find(
                              (e: string) => opt.value === e
                            )
                              ? checkboxIcon
                              : unCheckIcon
                          }
                          alt=""
                          height={7}
                          width={9}
                        />
                      </Stack>
                      <Typography
                        variant="required_chip_label"
                        fontSize={14}
                        fontWeight={300}
                        sx={{ lineHeight: '20px', pt: '2px' }}
                      >
                        {opt.label}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </FilterModalItem>
            </Stack>
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  bgcolor: 'white',
                  boxShadow: 'none',
                  width: '200px',
                  height: '36px',
                  borderRadius: '2px',
                  minHeight: '36px',
                  border: '1px solid #6B70F0',
                  my: 8,
                }}
                onClick={() => formik.handleSubmit()}
              >
                <Typography variant="button01" color="sp_primary_100_main">
                  検索
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
    </Modal>
  );
};

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: String(zeroPad(index + 1)),
    label: String(zeroPad(index + 1)),
  }))
);

// registration from
const yearCurrentOptionsFrom: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(dayjs().year() - 1899), (_, index) => {
    const year = zeroPad(dayjs().year() - index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year} （${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);

// registration - to
const yearCurrentOptionsTo: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(dayjs().year() - 1899), (_, index) => {
    const year = zeroPad(dayjs().year() - index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year} （${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);

const extensionOptions = [
  {
    value: 'pdf',
    label: 'pdf',
  },
  {
    value: 'png',
    label: 'png',
  },
  {
    value: 'jpg',
    label: 'jpg',
  },
  {
    value: 'jpeg',
    label: 'jpeg',
  },
];
