import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { hiddenScrollBar } from 'styles/toolkit';
import { Icons } from '..';

export type Step = {
  label: string;
};

type StepsProps = {
  currentStep: number;
  steps: Step[];
};

export const Steps: FC<StepsProps> = (props) => (
  <Stack
    id="steps"
    direction="row"
    spacing="3px"
    sx={{
      flex: 1,
      overflowX: { mobile: 'scroll', desktop: 'hidden' },
      overflowY: 'hidden',
      ...hiddenScrollBar,
    }}
  >
    {props.steps.map((step, index) => (
      <Stack
        key={index}
        sx={{
          position: 'relative',
          justifyContent: 'center',
        }}
      >
        <Icons.Step
          sx={{
            color: props.currentStep === index ? 'h_blugreen' : 'main_white',
          }}
        />
        <Typography
          variant="text_step"
          sx={{
            position: 'absolute',
            color: props.currentStep === index ? 'main_white' : 'off_text',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 100,
            whiteSpace: 'nowrap',
          }}
        >
          {step.label}
        </Typography>
      </Stack>
    ))}
  </Stack>
);
