import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Renovation: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 129 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="129"
      height="60"
      viewBox="0 0 129 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.1129 44.9677V59H53.8871C54.9161 59 55.7581 58.1581 55.7581 57.129V29.0645H59.5V22.5161L32.0623 1.4771C31.6132 1.17774 31.08 1 30.5 1C29.92 1 29.3868 1.17774 28.9377 1.4771L1.5 22.5161V29.0645H5.24194V57.129C5.24194 58.1581 6.08387 59 7.1129 59H24.8871V44.9677"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5352 5.67748V1.93555H55.761V13.1614"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2431 45.3141C39.1263 43.7331 42.6624 39.1493 42.6624 33.7422C42.6624 29.0554 40.0057 24.9861 36.114 22.9561V32.8067L33.3076 35.6132H27.6947L24.8882 32.8067V22.9561C20.9966 24.9861 18.3398 29.0461 18.3398 33.7422C18.3398 39.1493 21.876 43.7331 26.7592 45.3141"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.207 56.3005C75.997 56.3005 74.207 54.5105 74.207 52.3005C74.207 52.0805 74.237 51.8705 74.267 51.6505L76.317 39.4005C76.547 38.3905 77.167 37.5006 78.047 36.9406L78.187 36.8705L83.157 34.6705C82.997 33.0905 82.507 30.8705 81.607 30.0105L82.987 28.5605C84.957 30.4305 85.187 34.7705 85.207 35.2505L85.237 35.9305L79.077 38.6505C78.637 38.9505 78.337 39.4205 78.247 39.9505L78.207 40.1705L76.247 51.9706C76.227 52.0706 76.207 52.1805 76.207 52.3005C76.207 53.4005 77.107 54.3005 78.207 54.3005C78.377 54.3005 78.557 54.2705 78.767 54.2005L89.717 50.2206C90.627 49.9405 91.197 49.1805 91.197 48.3005C91.197 47.2005 90.297 46.3005 89.197 46.3005C89.027 46.3005 88.847 46.3305 88.627 46.4005L79.777 49.6105L79.097 47.7305L88.007 44.5005C88.467 44.3605 88.847 44.3005 89.207 44.3005C91.417 44.3005 93.207 46.0905 93.207 48.3005C93.207 50.0705 92.057 51.6005 90.357 52.1205L79.397 56.1005C78.937 56.2405 78.567 56.3005 78.207 56.3005Z"
        fill="currentColor"
      />
      <path
        d="M98.2066 58.3008H96.2066V40.3008H102.207C102.907 40.3008 103.537 39.9408 103.907 39.3308L109.917 29.3208C110.107 29.0008 110.207 28.6508 110.207 28.3008C110.207 27.2008 109.307 26.3008 108.207 26.3008C107.507 26.3008 106.867 26.6708 106.497 27.2808L101.087 36.3008H91.4866L90.2266 32.5208L92.1266 31.8908L92.9266 34.3008H99.9566L104.787 26.2508C105.517 25.0308 106.797 24.3008 108.207 24.3008C110.417 24.3008 112.207 26.0908 112.207 28.3008C112.207 29.0108 112.007 29.7208 111.627 30.3508L105.617 40.3708C104.877 41.5808 103.607 42.3008 102.207 42.3008H98.2066V58.3008Z"
        fill="currentColor"
      />
      <path
        d="M82.3736 58.5106L81.6836 54.0006L83.6636 53.6906L84.0336 56.0906L91.0836 54.5206L90.4936 50.3806L92.4736 50.1006L93.3236 56.0806L82.3736 58.5106Z"
        fill="currentColor"
      />
      <path
        d="M80.7655 47.9508L80.1055 42.5208L91.0455 40.0908L91.8355 45.6508L89.8555 45.9308L89.3655 42.5108L82.3055 44.0808L82.7555 47.7008L80.7655 47.9508Z"
        fill="currentColor"
      />
      <path
        d="M81.927 30.3408L79.547 27.9608C78.037 26.4508 77.207 24.4408 77.207 22.3008C77.207 17.8908 80.797 14.3008 85.207 14.3008C86.497 14.3008 87.377 14.6008 88.157 14.8708C88.827 15.1008 89.397 15.3008 90.207 15.3008H92.207C93.307 15.3008 94.207 16.2008 94.207 17.3008C94.207 20.8708 84.887 29.9808 82.417 30.2808L81.937 30.3408H81.927ZM85.207 16.3008C81.897 16.3008 79.207 18.9908 79.207 22.3008C79.207 23.9008 79.827 25.4108 80.967 26.5408L82.577 28.1508C84.857 27.0108 92.207 19.6408 92.207 17.3008H90.207C89.067 17.3008 88.237 17.0108 87.497 16.7608C86.777 16.5108 86.157 16.3008 85.207 16.3008Z"
        fill="currentColor"
      />
      <path
        d="M83.207 22.3008H81.207C81.207 20.0908 82.997 18.3008 85.207 18.3008V20.3008C84.107 20.3008 83.207 21.2008 83.207 22.3008Z"
        fill="currentColor"
      />
      <path
        d="M88.207 38.3008C85.357 38.3008 83.207 37.0108 83.207 35.3008H85.207C85.207 35.5508 86.267 36.3008 88.207 36.3008C90.147 36.3008 91.207 35.5508 91.207 35.3008H93.207C93.207 37.0108 91.057 38.3008 88.207 38.3008Z"
        fill="currentColor"
      />
      <path
        d="M125.207 36.3008H123.207V38.3008H125.207V36.3008Z"
        fill="currentColor"
      />
      <path
        d="M125.207 32.3008H123.207V34.3008H125.207V32.3008Z"
        fill="currentColor"
      />
      <path
        d="M125.207 30.3008H123.207V10.3008H75.207V27.3008H73.207V8.30078H125.207V30.3008Z"
        fill="currentColor"
      />
      <path
        d="M75.207 29.3008H73.207V31.3008H75.207V29.3008Z"
        fill="currentColor"
      />
      <path
        d="M82.9518 1.13395L81.0625 1.79004L83.7853 9.63074L85.6746 8.97466L82.9518 1.13395Z"
        fill="currentColor"
      />
      <path
        d="M94.1556 1.04913L92.2383 1.61816L94.614 9.62306L96.5313 9.05402L94.1556 1.04913Z"
        fill="currentColor"
      />
      <path
        d="M126.561 1.01004L123.277 8.91504L125.124 9.68234L128.408 1.77734L126.561 1.01004Z"
        fill="currentColor"
      />
      <path
        d="M71.8472 1.00971L70 1.77637L73.2813 9.68248L75.1285 8.91583L71.8472 1.00971Z"
        fill="currentColor"
      />
      <path
        d="M115.413 1.09852L112.73 9.00586L114.624 9.64841L117.307 1.74107L115.413 1.09852Z"
        fill="currentColor"
      />
      <path
        d="M104.24 1.00036L101.844 8.99902L103.76 9.57308L106.156 1.57441L104.24 1.00036Z"
        fill="currentColor"
      />
      <path
        d="M90.207 33.3005H88.207V31.3005H90.207C92.417 31.3005 94.207 29.5105 94.207 27.3005C94.207 26.7005 94.057 26.1005 93.767 25.5205L91.207 20.3705L92.997 19.4805L95.547 24.6305C95.977 25.4905 96.197 26.3905 96.197 27.3005C96.197 30.6105 93.507 33.3005 90.197 33.3005H90.207Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
