import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import {
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  Avatar,
  Grid,
  SxProps,
} from '@mui/material';
import { FastField, FastFieldProps, useField } from 'formik';
import { RadioGroup } from 'formik-mui';
import { Icons } from 'components';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { Popover } from '../../Popover';

type RadioItems = {
  value: string;
  label: string | string[];
  src?: string;
  sx?: SxProps;
  icon?: ReactNode;
};

type RadioGroupButtonProps = {
  name: string;
  options: Array<RadioItems>;
  type?: 'image' | 'noImage';
  itemPerRow?: number;
  justifyContent?: 'left' | 'center' | 'right';
  disabled?: boolean;
  onChange?: (checked: boolean, value: string) => void;
  readOnly?: boolean;
  showError?: boolean;
};

type RadioButtonProps = {
  name: string;
  error: boolean;
  option: RadioItems;
  checked: boolean;
  type: RadioGroupButtonProps['type'];
  disabled: boolean;
  onChange?: (checked: boolean, value: string) => void;
  readOnly: boolean;
};

type CheckedItemProps = {
  option: RadioItems;
  checked: boolean;
  type: RadioGroupButtonProps['type'];
  disabled: boolean;
  readOnly: boolean;
};

export const RadioGroupButton: FC<RadioGroupButtonProps> = ({
  name,
  options,
  type = 'image',
  itemPerRow = 4,
  justifyContent = 'left',
  disabled = false,
  onChange,
  readOnly = false,
  showError = true,
}) => {
  const [, meta] = useField(name);
  const { error, touched } = meta;

  const shouldUpdate = useCallback(
    (
      nextProps: {
        disabled: boolean;
        readOnly: boolean;
        error: boolean;
        touched: boolean;
        onChange?: (checked: boolean, value: string) => void;
      },
      props: {
        disabled: boolean;
        readOnly: boolean;
        error: boolean;
        touched: boolean;
        onChange?: (checked: boolean, value: string) => void;
      }
    ) =>
      nextProps.disabled !== props.disabled ||
      nextProps.readOnly !== props.readOnly ||
      nextProps.error !== props.error ||
      nextProps.touched !== props.touched ||
      nextProps.onChange !== props.onChange ||
      get(nextProps, `formik.values.${name}`) !==
        get(props, `formik.values.${name}`),
    [name]
  );

  return (
    <FastField
      name={name}
      disabled={disabled}
      readOnly={readOnly}
      error={error}
      touched={touched}
      shouldUpdate={shouldUpdate}
      onChange={onChange}
    >
      {({ field, form, meta }: FastFieldProps) => (
        <RadioGroup field={field} form={form} meta={meta}>
          <Grid container spacing={7} justifyContent={justifyContent}>
            {options.map((option) => (
              <Grid
                key={option.value}
                item
                {...{ mobile: 12, tablet: 6, md: 12 / itemPerRow }}
              >
                <Popover
                  content={error ?? ''}
                  contentProps={{ color: 'main_white' }}
                  open={
                    touched &&
                    !!error &&
                    options.indexOf(option) === 0 &&
                    showError
                  }
                  placement="top-end"
                  arrowSx={{
                    borderColor: 'sh_red',
                    bgcolor: 'sh_red',
                  }}
                  sx={{
                    top: -5,
                    right: 0,
                    maxWidth: 250,
                    borderColor: 'sh_red',
                    bgcolor: 'sh_red',
                  }}
                >
                  <FormControlLabel
                    sx={{ width: '100%', height: '100%', mx: 0 }}
                    value={name}
                    control={
                      <RadioButton
                        name={name}
                        error={!!error && touched}
                        onChange={onChange}
                        option={option}
                        checked={field.value === option.value}
                        type={type}
                        disabled={disabled}
                        readOnly={readOnly}
                      />
                    }
                    label=""
                  />
                </Popover>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      )}
    </FastField>
  );
};

const RadioButton: FC<RadioButtonProps> = ({
  name,
  error,
  option,
  checked,
  type,
  disabled,
  onChange,
  readOnly,
}) => {
  const IconElement = useMemo(
    () => (
      <CheckedItemMemo
        option={option}
        checked={checked}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
      />
    ),
    [checked, disabled, option, type, readOnly]
  );

  return (
    <Radio
      name={name}
      disabled={disabled || readOnly}
      onChange={(event, checked) =>
        onChange && !readOnly && onChange(checked, option.value)
      }
      checkedIcon={IconElement}
      icon={IconElement}
      value={option.value}
      sx={{
        flex: 1,
        borderRadius: '5px',
        boxShadow: 3,
        minHeight: type !== 'noImage' ? '100%' : 60,
        '.MuiSvgIcon-root': {
          position: 'absolute',
          left: type !== 'noImage' ? 5 : 8,
          top: type !== 'noImage' ? 4 : 18,
        },
        '&, &:hover': {
          bgcolor: checked ? 'h_blugreen' : error ? 'bg_error' : 'main_white',
          color: checked ? 'main_white' : 'normal_text',
        },
        '&:hover': {
          opacity: { mobile: 1, desktop: 0.8 },
        },
        '&.Mui-disabled': {
          bgcolor: checked
            ? 'h_blugreen'
            : disabled
            ? 'bg_disabled'
            : 'transparent',
          '.MuiTypography-root': {
            color: checked || disabled ? 'main_white' : 'off_text',
          },
        },
        alignItems: type !== 'noImage' ? 'start' : 'center',
      }}
    />
  );
};

const CheckedItem: FC<CheckedItemProps> = ({
  option,
  checked,
  type,
  disabled,
  readOnly,
}) => {
  return (
    <Stack flex={1}>
      {checked ? (
        <Icons.Checked
          sx={{ zIndex: 1, bgcolor: 'main_white', borderRadius: '50%' }}
        />
      ) : disabled || readOnly ? (
        <Icons.RadioDisabled />
      ) : (
        <Icons.UnChecked />
      )}
      <Stack sx={type !== 'noImage' ? { mb: 2 } : undefined}>
        {type !== 'noImage' && (
          <Stack
            sx={{ height: 72 }}
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              variant="square"
              src={option.src}
              sx={{
                height: '100%',
                width: '100%',
                bgcolor: 'transparent',
                '& .MuiSvgIcon-root': {
                  position: 'relative',
                  top: 0,
                  left: 0,
                  width: '9em',
                  height: '3em',
                },
                color: checked ? 'white' : 'black',
              }}
              children={option.icon}
            />
          </Stack>
        )}
      </Stack>
      <Stack
        alignItems="flex-end"
        justifyContent="center"
        flex={1}
        direction="row"
        sx={{ ml: 3 }}
      >
        {isArray(option.label) ? (
          <>
            <Typography
              variant="radio_group_button_title"
              color={
                checked ? 'main_white' : readOnly ? 'off_text' : 'normal_text'
              }
              fontWeight={checked ? 700 : 500}
              sx={{
                whiteSpace: 'pre',
                ...option.sx,
              }}
            >
              {option.label[0]}
            </Typography>
            <Typography
              variant="radio_group_button_title"
              color={
                checked ? 'main_white' : readOnly ? 'off_text' : 'normal_text'
              }
              fontWeight={checked ? 700 : 500}
              sx={{
                whiteSpace: 'pre',
                fontSize: 15,
                lineHeight: '21px',
                ...option.sx,
              }}
            >
              {option.label[1]}
            </Typography>
          </>
        ) : (
          <Typography
            variant="radio_group_button_title"
            color={
              checked ? 'main_white' : readOnly ? 'off_text' : 'normal_text'
            }
            fontWeight={checked ? 700 : 500}
            sx={{
              whiteSpace: 'pre',
              ...option.sx,
            }}
          >
            {option.label}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const CheckedItemMemo = memo(CheckedItem, isEqual);
