import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';

export type StepLayoutContextType = {
  openHelp: boolean;
  setOpenHelp: Dispatch<SetStateAction<boolean>>;
  showStepOneFields: boolean;
  setShowStepOneFields: Dispatch<SetStateAction<boolean>>;
  showStepTwoFields: boolean;
  setShowStepTwoFields: Dispatch<SetStateAction<boolean>>;
  showStepThreeFields: boolean;
  setShowStepThreeFields: Dispatch<SetStateAction<boolean>>;
  showStepThreeOtherFields: boolean;
  setShowStepThreeOtherFields: Dispatch<SetStateAction<boolean>>;
  showStepOneCFields: boolean;
  setShowStepOneCFields: Dispatch<SetStateAction<boolean>>;
};

type StepLayoutProviderProps = PropsWithChildren<{}>;

export const StepLayoutContext = createContext<StepLayoutContextType>({
  openHelp: false,
  setOpenHelp() {},
  showStepOneFields: false,
  setShowStepOneFields() {},
  showStepTwoFields: false,
  setShowStepTwoFields() {},
  showStepThreeFields: false,
  setShowStepThreeFields() {},
  showStepThreeOtherFields: false,
  setShowStepThreeOtherFields() {},
  showStepOneCFields: false,
  setShowStepOneCFields() {},
});

export const StepLayoutProvider: FC<StepLayoutProviderProps> = ({
  children,
}) => {
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [showStepOneFields, setShowStepOneFields] = useState<boolean>(false);
  const [showStepTwoFields, setShowStepTwoFields] = useState<boolean>(false);
  const [showStepThreeFields, setShowStepThreeFields] =
    useState<boolean>(false);
  const [showStepThreeOtherFields, setShowStepThreeOtherFields] =
    useState<boolean>(false);
  const [showStepOneCFields, setShowStepOneCFields] = useState<boolean>(false);

  return (
    <StepLayoutContext.Provider
      value={{
        openHelp,
        setOpenHelp,
        showStepOneFields,
        setShowStepOneFields,
        showStepTwoFields,
        setShowStepTwoFields,
        showStepThreeFields,
        setShowStepThreeFields,
        showStepThreeOtherFields,
        setShowStepThreeOtherFields,
        showStepOneCFields,
        setShowStepOneCFields,
      }}
    >
      {children}
    </StepLayoutContext.Provider>
  );
};
