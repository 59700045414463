import React, { FC, useMemo, useState } from 'react';
import { Stack, styled, Typography, useAutocomplete } from '@mui/material';
import { AuthPopover, Icons } from 'components';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { DropDownItems } from 'pages/AdminEditPreliminary/slice';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { PulldownBtn } from '../PulldownBtn';
import { salePersonPreliminariesSelector } from '../../../SalePersonEditPreliminary/selectors';

export type AutocompleteSalePersonProps = {
  name?: string;
  value: string;
  options: DropDownItems[];
  onChange: (value: string) => void;
};

export const AutocompleteSalePerson: FC<AutocompleteSalePersonProps> = ({
  name,
  value,
  options,
  onChange,
}) => {
  const salePerson = useSelector(salePersonPreliminariesSelector);
  const [open, setOpen] = useState<boolean>(false);

  const hiddenSelect = useMemo(() => {
    if (!salePerson.p_application_header.user_id) return false;
    if (name === 's_sale_person_id') {
      return !options.length;
    }
    return options.length <= 1;
  }, [options, salePerson.p_application_header.user_id, name]);

  if (hiddenSelect) {
    return <PulldownBtn isEmpty />;
  }

  return (
    <AuthPopover
      arrow={false}
      sx={{
        width: 146,
        border: (theme) => `1px solid ${theme?.palette?.line}`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        overflow: 'hidden',
      }}
      content={
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Stack sx={{ textAlign: 'left', maxHeight: 300, overflow: 'auto' }}>
            <Select
              value={value}
              options={options}
              onChange={onChange}
              onClose={() => setOpen(false)}
            />
          </Stack>
        </ClickAwayListener>
      }
      open={open}
      placement="bottom-start"
      contentProps={{
        height: 'auto',
        padding: 0,
        color: 'required_chip_label',
      }}
    >
      <Stack direction="row" alignItems="center">
        <PulldownBtn
          open={open}
          onClick={() => setOpen((prevState) => !prevState)}
        />
      </Stack>
    </AuthPopover>
  );
};

type SelectProps = {
  value: AutocompleteSalePersonProps['value'];
  options: AutocompleteSalePersonProps['options'];
  onChange: AutocompleteSalePersonProps['onChange'];
  onClose: () => void;
};
const Select: FC<SelectProps> = ({ value, options, onChange, onClose }) => {
  const { getRootProps, getInputProps, getListboxProps, groupedOptions } =
    useAutocomplete<DropDownItems>({
      id: 'auto-complete-sale-person',
      options: options.map((opt) => ({ ...opt, id: opt.value, _id: opt.id })),
      open: true,
      openOnFocus: true,
      autoComplete: true,
    });

  return (
    <>
      <Stack
        {...getRootProps}
        direction="row"
        justifyContent="center"
        sx={{ borderBottom: '1px solid #DBD9D9', bgcolor: 'main_white' }}
      >
        <SearchIcon
          sx={{
            color: 'sp_primary_100_main',
            fontSize: 14,
            pt: '2px',
            pl: '4px',
          }}
        />
        <Input {...getInputProps()} autoFocus />
      </Stack>
      {!!groupedOptions.length ? (
        <Listbox {...getListboxProps()} sx={{ listStyleType: 'none' }}>
          {groupedOptions.map((item, index) => (
            <Stack
              component="li"
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                px: 1,
                py: 1,
                borderBottom:
                  options.length - 1 !== index ? '1px solid #DBD9D9' : 'none',
                cursor: 'pointer',
                backgroundColor: 'main_white',
              }}
              onClick={() => {
                //@ts-ignore
                onChange(item.value);
                onClose();
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {
                  //@ts-ignore
                  String(item.value) === String(value) ||
                  //@ts-ignore
                  String(item._id) === String(value) ? (
                    <Icons.PulldownChecked sx={{ width: 16, height: 16 }} />
                  ) : (
                    <Icons.PulldownCheck sx={{ width: 16, height: 16 }} />
                  )
                }
                <Typography
                  variant="text_dashboard"
                  color="b_333"
                  sx={{ pl: 2 }}
                >
                  {
                    //@ts-ignore
                    item.label
                  }
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Listbox>
      ) : null}
    </>
  );
};

const Input = styled('input')(() => ({
  width: 128,
  outline: 'none',
  border: 'none',
}));

const Listbox = styled('ul')(() => ({
  width: 146,
  margin: 0,
  padding: 0,
  zIndex: 1,
  listStyle: 'none',
}));
