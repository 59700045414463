import {
  AdminPreliminaryItem,
  FINANCING_PLAN,
  LoanType,
  PurposeType,
} from 'constant';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { convertYenToMan, numberWithCommas } from 'utils';

export const showLoanTarget = {
  house_purchase_price: [
    PurposeType.ONE,
    PurposeType.TWO,
    PurposeType.THREE,
    PurposeType.FOUR,
    PurposeType.FIVE,
    PurposeType.SIX,
  ],
  accessory_cost: [PurposeType.FIVE, PurposeType.SIX],
  land_purchase_price: [PurposeType.SIX],
  refinancing_loan_balance: [PurposeType.SEVEN, PurposeType.EIGHT],
  house_upgrade_cost: [PurposeType.EIGHT],
  pair_loan_amount: [PurposeType.TWO],
};

export const buildFinancingPlan = (
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  return FINANCING_PLAN.filter(({ name_en }) => {
    if (name_en === 'require_funds_breakdown_mortgage') {
      return values.is_home_loan_plus;
    }
    if (name_en === 'house_purchase_price') {
      return showLoanTarget.house_purchase_price.includes(values.loan_target);
    }
    if (name_en === 'accessory_cost') {
      return showLoanTarget.accessory_cost.includes(values.loan_target);
    }
    if (name_en === 'land_purchase_price') {
      return showLoanTarget.land_purchase_price.includes(values.loan_target);
    }
    if (name_en === 'refinancing_loan_balance') {
      return showLoanTarget.refinancing_loan_balance.includes(
        values.loan_target
      );
    }
    if (name_en === 'house_upgrade_cost') {
      return showLoanTarget.house_upgrade_cost.includes(values.loan_target);
    }
    if (name_en === 'pair_loan_amount') {
      return values.loan_type === LoanType.TWO;
    }
    if (name_en === 'other_procurement_breakdown_content') {
      return convertToNumber(values.other_procurement_breakdown) > 0;
    }
    return true;
  }).map((row) => {
    if (row.name_en === 'total_price_1') {
      return {
        ...row,
        defaultValue: getValueDisplay(
          convertToNumber(values.house_purchase_price) +
            convertToNumber(values.land_purchase_price) +
            convertToNumber(values.additional_cost) +
            convertToNumber(values.require_funds_breakdown_mortgage) +
            convertToNumber(values.accessory_cost) +
            convertToNumber(values.refinancing_loan_balance) +
            convertToNumber(values.house_upgrade_cost)
        ),
      };
    }
    if (row.name_en === 'total_price_2') {
      return {
        ...row,
        defaultValue: getValueDisplay(
          convertToNumber(values.deposit_savings_1) +
            convertToNumber(values.real_estate_sale_price) +
            convertToNumber(values.other_saving_amount) +
            convertToNumber(values.relative_donation_amount) +
            convertToNumber(values.loan_amount) +
            convertToNumber(values.pair_loan_amount) +
            convertToNumber(values.other_procurement_breakdown) +
            convertToNumber(values.amount_others) +
            convertToNumber(values.amount_any_loans)
        ),
      };
    }

    // TODO: 預貯金＋不動産売却代金＋有価証券売却など＝自己資金
    // if (row.name_en === 'saving_amount') {
    //   return {
    //     ...row,
    //     defaultValue: getValueDisplay(
    //       convertToNumber(values.deposit_savings_1) +
    //         convertToNumber(values.real_estate_sale_price) +
    //         convertToNumber(values.other_saving_amount)
    //     ),
    //   };
    // }
    return row;
  });
};

export function convertToNumber(value?: string, defaultValue = 0): number {
  return isNaN(Number(value)) ? defaultValue : Number(value);
}

export function getValueDisplay(number: number) {
  return `${numberWithCommas(
    convertYenToMan([number.toString()]).toString()
  )}万円`;
}
