import { z } from 'zod';
import { salePerson, store } from './salePerson';

export const managerItem = z.object({
  id: z.string(),
  name_kanji: z.string(),
  active: z.boolean(),
});

export const managerPreliminariesItemSchema = z.object({
  id: z.string(),
  applicant_id: z.string(),
  user_id: z.string(),
  applicant_name: z.string(),
  application_number: z.string(),
  bank_name: z.string(),
  created_at: z.string(),
  loan_desired_borrowing_date: z.string(),
  manager_name_kanji: z.string(),
  sale_person_name_kanji: z.string(),
  store_name_kanji: z.string(),
  temporary_desired_loan_amount: z.string(),
  status_result: z.string(),
  exhibition_hall: z.string(),
  provisional_result: z.string(),
  list_managers: z.array(managerItem),
  loan_type: z.string(),
  loan_apply_date: z.string(),
  list_sale_people: z.array(salePerson),
  list_store: z.array(store),
  p_referral_agency_path: z.string(),
  application_status_lists: z.array(
    z.object({
      key: z.string(),
      value: z.number(),
      active: z.boolean(),
      enable: z.boolean(),
    })
  ),
  unread_messages: z.string().optional(),
  linking: z.string(),
});

export const managerAssignSalePersonRequestSchema = z.object({
  id: z.string(),
  s_sale_person_id: z.string(),
});

export const managerAssignSalePersonResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(managerPreliminariesItemSchema),
});

export const managerAssignStoreNameRequestSchema = z.object({
  id: z.string(),
  p_referral_agency_id: z.string(),
});

export const managerAssignStoreNameResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(managerPreliminariesItemSchema),
});

export const managerCurrentPreliminariesItemRequestSchema = z.object({
  preliminary_status: z.string().optional(),
  master_bank_codes: z.array(z.string()).optional(),
  applicant_name: z.string().optional(),
  apply_date_range: z
    .object({
      from: z.string(),
      to: z.string(),
    })
    .optional(),
  provisional_results: z.array(z.number()).optional(),
  loan_amount_range: z
    .object({
      from: z.string(),
      to: z.string(),
    })
    .optional(),
  branch_names: z.array(z.string()).optional(),
  office_names: z.array(z.string()).optional(),
  exhibition_hall_names: z.array(z.string()).optional(),
  sale_person_names: z.array(z.string()).optional(),
  execution_date_range: z
    .object({
      from: z.string(),
      to: z.string(),
    })
    .optional(),
  order_key: z.string().optional(),
  order_sort: z.string().optional(),
  page: z.number().optional(),
});

export const masterData = z.object({
  branch_names: z.array(z.string()),
  exhibition_hall_names: z.array(z.string()),
  office_names: z.array(z.string()),
  sale_person_names: z.array(z.string()),
  sale_agent_names: z.array(z.string()),
});

export const managerCurrentPreliminariesItemResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(managerPreliminariesItemSchema),
  master_data: masterData,
  total: z.number(),
});

export const StatusSchema = z.object({
  key: z.string(),
  value: z.number(),
  active: z.boolean(),
});

export const StatusRequestSchema = z.object({
  id: z.string(),
});

export const UpdateStatusRequestSchema = z.object({
  id: z.string(),
  under_review_status: z.string(),
});

export const ListStatusResponseSchema = z.object({
  success: z.boolean(),
  under_review_status: z.number().nullable(),
  full_name_kanji: z.string().nullable(),
});

export const UpdateStatusResponseSchema = z.object({
  success: z.boolean(),
});

export const assignManagerRequestSchema = z.object({
  id: z.string(),
  manager_id: z.string(),
});

export const updateApplicationStatusRequestSchema = z.object({
  id: z.string(),
  application_status: z.string(),
});

export const linkPairLoanRequestSchema = z.object({
  id: z.string(),
  linking_number: z.string(),
});

export const unlinkPairLoanRequestSchema = z.object({
  id: z.string(),
});

export const linkUnLinkPairLoanResponseSchema = z.object({
  success: z.boolean(),
});

export const assignManagerResponseSchema = z.object({
  success: z.boolean(),
});

export const updateApplicationStatusResponseSchema = z.object({
  success: z.boolean(),
});

export type ManagerPreliminariesItem = z.infer<
  typeof managerPreliminariesItemSchema
>;
export type ManagerCurrentPreliminariesItemRequest = z.infer<
  typeof managerCurrentPreliminariesItemRequestSchema
>;
export type ManagerCurrentPreliminariesItemResponse = z.infer<
  typeof managerCurrentPreliminariesItemResponseSchema
>;
export type MasterData = z.infer<typeof masterData>;

export type StatusItem = z.infer<typeof StatusSchema>;
export type ListStatusResponse = z.infer<typeof ListStatusResponseSchema>;
export type UpdateStatusResponse = z.infer<typeof UpdateStatusResponseSchema>;
export type StatusRequest = z.infer<typeof StatusRequestSchema>;
export type UpdateStatusRequest = z.infer<typeof UpdateStatusRequestSchema>;
export type UpdateApplicationStatusRequest = z.infer<
  typeof updateApplicationStatusRequestSchema
>;
export type UpdateApplicationStatusResponse = z.infer<
  typeof updateApplicationStatusResponseSchema
>;
export type ListStatusError = {};

export type AssignManagerRequest = z.infer<typeof assignManagerRequestSchema>;
export type AssignManagerResponse = z.infer<typeof assignManagerResponseSchema>;
export type AssignManagerError = {};

export type LinkPairLoanRequest = z.infer<typeof linkPairLoanRequestSchema>;
export type UnLinkPairLoanRequest = z.infer<typeof unlinkPairLoanRequestSchema>;
export type LinkUnLinkPairLoanResponse = z.infer<
  typeof linkUnLinkPairLoanResponseSchema
>;

export type LinkPairLoanError = {};

export const saveSalePersonRequestSchema = z.object({
  user_id: z.number(),
  user: z.object({
    sale_agent_id: z.string().optional(),
    store_id: z.string().optional(),
    exhibition_id: z.string().optional(),
  }),
});
export const saveSalePersonResponseSchema = z.object({
  success: z.boolean(),
});

export type SaveSalePersonRequest = z.infer<typeof saveSalePersonRequestSchema>;
export type SaveSalePersonResponse = z.infer<
  typeof saveSalePersonResponseSchema
>;
export type SaveSalePersonError = {};

export const getSalePersonRequestSchema = z.object({
  user_id: z.number(),
});
export const getSalePersonResponseSchema = z.object({
  data: z.object({
    sale_agent_id: z.string().optional(),
    store_id: z.string().optional(),
    exhibition_id: z.string().optional(),
  }),
});

export type GetSalePersonRequest = z.infer<typeof getSalePersonRequestSchema>;
export type GetSalePersonResponse = z.infer<typeof getSalePersonResponseSchema>;
export type GetSalePersonError = {};

export type ManagerAssignSalePersonRequest = z.infer<
  typeof managerAssignSalePersonRequestSchema
>;

export type ManagerAssignSalePersonResponse = z.infer<
  typeof managerAssignSalePersonResponseSchema
>;

export type ManagerAssignStoreNameRequest = z.infer<
  typeof managerAssignStoreNameRequestSchema
>;

export type ManagerAssignStoreNameResponse = z.infer<
  typeof managerAssignStoreNameResponseSchema
>;

export type ManagerAssignSalePersonError = {};

export type ManagerAssignStoreNameError = {};
