import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPArrowForward: FC<SvgIconProps> = ({
  sx,
  ...props
}: SvgIconProps) => (
  <SvgIcon sx={{ ...sx }} viewBox="0 0 17 16" {...props}>
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.31641 4.6662L10.6497 7.99953L7.31641 11.3329"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
