import { FormikProvider, useFormik } from 'formik';
import {
  Box,
  Checkbox,
  Divider,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import numeral from 'numeral';
import { FC } from 'react';
import { yup } from 'libs';
import { theme } from 'styles';
import { condition, SituationType } from 'constant';
import { Button, Icons, InputField, StepAdmin } from 'components';
import { checkAfterDate } from 'utils';
import { ApplicantItem as ApplicantItemType } from 'services';

interface ApplicantItemProps {
  data: ApplicantItemType;
  index: number;
  isAdmin?: boolean;
}

export type ApplicantItemForm = {
  id: number;
  field: string;
};

export const ApplicantItem: FC<ApplicantItemProps> = ({
  data,
  index,
  isAdmin,
}) => {
  const formik = useFormik<ApplicantItemForm>({
    initialValues: {
      id: data.id,
      field: '',
    },
    validationSchema: yup.object({
      field: yup.string().required(),
    }),
    onSubmit(values) {
      //TODO handle submit
    },
  });

  return (
    <Stack
      key={data.id}
      direction="row"
      sx={{
        my: 3,
        minWidth: 952,
      }}
    >
      <FormikProvider value={formik}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            bgcolor:
              checkAfterDate(data.startDate, data.endDate) || data.quick
                ? 'main_white'
                : 'sh_red',
            height: 36,
            width: 36,
            borderRadius: '4px',
            boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            mt: 1,
            mr: '6px',
            color:
              checkAfterDate(data.startDate, data.endDate) || data.quick
                ? 'normal_text'
                : 'main_white',
          }}
        >
          <Typography variant="text_applicant_item">{index + 1}</Typography>
        </Stack>
        <Stack sx={{ position: 'relative', flex: 1 }}>
          <TableContainer
            sx={{
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            }}
          >
            <Table>
              <TableBody sx={{ bgcolor: 'main_white' }}>
                <TableRow>
                  <TableCellStyled sx={{ width: 91 }}>
                    <Typography variant="number_applicant_item">
                      {data.proposalNumber}
                    </Typography>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled flex={1}>
                    <Typography variant="name_text">{data.bankName}</Typography>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 98 }}>
                    <Typography variant="date_applicant_item">
                      {data.applicationDate}
                    </Typography>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 77 }}>
                    <Typography variant="quick_text">
                      {data.situation === SituationType.CONTACT_ALREADY
                        ? 'ON'
                        : 'OFF'}
                    </Typography>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 149 }}>
                    <Typography variant="name_text">{data.endDate}</Typography>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 110 }}>
                    <Typography variant="number_applicant_item">
                      {numeral(data.loanAmount).format('0,0')}
                    </Typography>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 59 }}>
                    <Typography variant="number_applicant_item">
                      {data.interestRate}
                    </Typography>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 60 }}>
                    <Typography variant="name_text" flex={1}>
                      {condition[data.condition]}
                    </Typography>
                    <DividerStyled orientation="vertical" flex={1} />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 157, textAlign: 'left' }}>
                    <Typography variant="name_text" paddingLeft={3}>
                      {data.bankClerk}
                    </Typography>
                  </TableCellStyled>
                </TableRow>
                <TableCell colSpan={9} sx={{ border: 'none', py: 0 }}>
                  <Divider
                    sx={{
                      border: (theme) => `1px dashed ${theme?.palette?.gray}`,
                      pl: 2,
                      pr: 5,
                    }}
                  />
                </TableCell>

                <TableRow>
                  <TableCell colSpan={9} sx={{ px: 0, borderBottom: 'none' }}>
                    <Stack direction="row" alignItems="center" sx={{ px: 5 }}>
                      <Stack
                        spacing={2}
                        alignItems="center"
                        sx={{
                          pt: 2,
                          bgcolor: 'bg_gray',
                          width: 143,
                          height: 113,
                          borderRadius: '4px',
                          color: data.quick ? 'gray' : 'normal_text',
                        }}
                      >
                        <Typography variant="text_applican_item_content">
                          担当営業
                        </Typography>
                        <Stack
                          sx={{
                            py: isAdmin ? 0 : 6,
                            color: data.quick ? 'normal_text' : 'blue03',
                          }}
                        >
                          <Typography variant="text_applican_item_label">
                            {data.userName}
                          </Typography>
                        </Stack>
                        {isAdmin &&
                          (data.quick ? (
                            <Stack sx={{ pt: isAdmin ? 5 : 0 }}>
                              <Button
                                variant="outlined"
                                sx={{
                                  width: 132,
                                  minHeight: 35,
                                  height: 35,
                                  p: 0,
                                  bgcolor: 'main_white',
                                  border: (theme) =>
                                    `1px solid ${theme?.palette?.h_blugreen}`,
                                }}
                              >
                                <Typography
                                  variant="text_applicant_item_button"
                                  color="blue03"
                                >
                                  営業選択・連絡
                                </Typography>
                              </Button>
                            </Stack>
                          ) : (
                            <Stack
                              sx={{ pt: isAdmin ? 5 : 0 }}
                              direction="row"
                              spacing={1}
                              justifyContent="space-around"
                            >
                              <Button
                                variant="outlined"
                                sx={{
                                  width: 60,
                                  minHeight: 35,
                                  height: 35,
                                  p: 0,
                                  bgcolor: 'main_white',
                                  border: (theme) =>
                                    `1px solid ${theme?.palette?.h_blugreen}`,
                                }}
                              >
                                <Typography
                                  variant="text_applicant_item_button"
                                  color="blue03"
                                >
                                  連絡
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                sx={{
                                  width: 60,
                                  minHeight: 35,
                                  height: 35,
                                  p: 0,
                                  bgcolor: 'main_white',
                                  border: (theme) =>
                                    `1px solid ${theme?.palette?.h_blugreen}`,
                                }}
                              >
                                <Typography
                                  variant="text_applicant_item_button"
                                  color="blue03"
                                >
                                  変更
                                </Typography>
                              </Button>
                            </Stack>
                          ))}
                      </Stack>

                      <Stack spacing={3} alignItems="center" sx={{ px: 5 }}>
                        <StepAdmin status={data.situation} />
                        <Stack
                          spacing={5}
                          direction="row"
                          sx={{ width: '100%' }}
                        >
                          <Stack
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              ml: 4,
                              color:
                                checkAfterDate(data.startDate, data.endDate) ||
                                data.quick
                                  ? 'Icon_color'
                                  : 'sh_red',
                            }}
                          >
                            <Typography variant="text_examination_date">
                              審査期日
                            </Typography>
                            <Typography
                              variant="examination_date"
                              fontWeight={
                                checkAfterDate(data.startDate, data.endDate) ||
                                data.quick
                                  ? 400
                                  : 600
                              }
                            >
                              {data.startDate}
                            </Typography>
                          </Stack>
                          <Stack flex={1} sx={{ width: 473, p: 0 }}>
                            <InputField
                              name={data.name}
                              multiline
                              placeholder="メモ"
                              rows={4}
                              InputProps={{
                                sx: {
                                  bgcolor: 'bg_off',
                                  'textarea::placeholder': {
                                    color: 'off_text',
                                    ...theme.typography.text_placeholder,
                                  },
                                  '&& .MuiInputBase-input': {
                                    p: 1,
                                    ...theme.typography.text_placeholder,
                                    height: '70px !important',
                                  },
                                  height: 74,
                                  px: '7px',
                                  py: '7px',
                                },
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack spacing={4} justifyContent="center">
                        <ButtonStyled
                          variant="outlined"
                          //TODO handle on click
                          onClick={() => {}}
                        >
                          <Typography
                            variant="text_applicant_item_button"
                            color="blue03"
                          >
                            個人へ連絡
                          </Typography>
                        </ButtonStyled>
                        <ButtonStyled
                          variant="outlined"
                          onClick={() => formik.handleSubmit()}
                        >
                          <Typography
                            variant="text_applicant_item_button"
                            color="blue03"
                          >
                            保存
                          </Typography>
                        </ButtonStyled>
                      </Stack>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              position: 'absolute',
              right: -40,
              bgcolor: 'main_white',
              width: 40,
              height: 42,
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
          >
            <Checkbox
              icon={<Icons.BoxUnchecked />}
              checkedIcon={<Icons.BoxChecked />}
              sx={{ color: 'gray', size: 22, py: '10px', pr: 2 }}
            />
          </Box>
        </Stack>
        <Box sx={{ mt: 12 }}>
          <Button variant="text" sx={{ minWidth: 36, minHeight: 36 }}>
            <Icons.Trash />
          </Button>
        </Box>
      </FormikProvider>
    </Stack>
  );
};

const TableCellStyled = styled(TableCell)(() => ({
  padding: '12px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  width: 119,
  minHeight: 35,
  height: 35,
  padding: 0,
  backgroundColor: theme?.palette?.main_white,
  border: `1px solid ${theme?.palette?.h_blugreen}`,
  filter: 'drop-shadow(0px 2px 8px rgba(59, 118, 129, 0.15))',
}));
