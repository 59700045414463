import { Stack, Typography } from '@mui/material';
import {
  Button,
  Icons,
  ManagerDocumentUploadTable,
  SalePersonDocumentUploadTable,
} from 'components';
import { AdminLayout } from 'containers';
import {
  FC,
  useMemo,
  useState,
  useCallback,
  useEffect,
  MouseEvent,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { OrderType } from 'types';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import {
  getListArchiveFileManager,
  getListArchiveFileSalePerson,
  sortArchiveFileManagerItems,
  sortArchiveFileSalePersonItems,
} from './thunk';
import { managerDocumentUploadSelector } from './selectors';
import { ArchiveFileAdminType, ArchiveFileSalePersonType } from './slice';
import {
  UploadDocumentSearch,
  UploadDocumentSearchFilterData,
} from './components/UploadDocumentSearch';

const initialValuesFilter: UploadDocumentSearchFilterData = {
  file_name: [],
  create_date: { from: '', to: '' },
  sale_person_names: [],
  sale_agent_names: [],
  format_file: [],
};

const ManagerDocumentUploadPage: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { manager, sale_person, total } = useSelector(
    managerDocumentUploadSelector
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);
  const [orderManager, setOrderManager] = useState<OrderType>();
  const [orderManagerBy, setOrderManagerBy] =
    useState<keyof ArchiveFileAdminType>();
  const [orderSalePerson, setOrderSalePerson] = useState<OrderType>();
  const [orderSalePersonBy, setOrderSalePersonBy] =
    useState<keyof ArchiveFileSalePersonType>();
  const [managerSortList, setManagerSortList] = useState<
    ArchiveFileAdminType[]
  >([]);
  const [salePersonSortList, setSalePersonSortList] = useState<
    ArchiveFileSalePersonType[]
  >([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [dataFilter, setDataFilter] =
    useState<UploadDocumentSearchFilterData>(initialValuesFilter);
  const [paginationManager, setPaginationManager] = useState({
    page: 1,
    maxPage: 1,
    fetching: false,
  });
  const [paginationSalePerson, setPaginationSalePerson] = useState({
    page: 1,
    maxPage: 1,
    fetching: false,
  });

  const isSalePerson = useMemo(() => {
    return !location.pathname.startsWith('/manager');
  }, [location.pathname]);

  const getDocumentUploadFileList = useCallback(async () => {
    if (isSalePerson) {
      await dispatch(getListArchiveFileSalePerson({})).then(() =>
        setLoading(false)
      );
    } else {
      await dispatch(getListArchiveFileManager({})).then(() =>
        setLoading(false)
      );
    }
  }, [dispatch, isSalePerson]);

  useEffect(() => {
    setLoading(true);
    getDocumentUploadFileList();
  }, [getDocumentUploadFileList, reset]);

  useEffect(() => {
    if (isSalePerson) {
      setPaginationSalePerson((prev) => ({
        ...prev,
        page: 1,
        maxPage: Math.ceil(total / 20),
      }));
    } else {
      setPaginationManager((prev) => ({
        ...prev,
        page: 1,
        maxPage: Math.ceil(total / 20),
      }));
    }
  }, [isSalePerson, total]);

  const handleManagerRequestSort = useCallback(
    async (
      event: MouseEvent<unknown>,
      property: keyof ArchiveFileAdminType
    ) => {
      const isAsc = orderManagerBy === property && orderManager === 'asc';
      setOrderManager(isAsc ? 'desc' : 'asc');
      setOrderManagerBy(property);
      const result = await dispatch(
        sortArchiveFileManagerItems({
          ...dataFilter,
          order_key: property === 'id' ? 'created_at' : property,
          order_sort: isAsc ? 'asc' : 'desc',
        })
      );
      if (sortArchiveFileManagerItems.fulfilled.match(result)) {
        setManagerSortList(result.payload.data);
      }
    },
    [dataFilter, dispatch, orderManager, orderManagerBy]
  );

  const handleSalePersonRequestSort = useCallback(
    async (
      event: MouseEvent<unknown>,
      property: keyof ArchiveFileSalePersonType
    ) => {
      const isAsc = orderSalePersonBy === property && orderSalePerson === 'asc';
      setOrderSalePerson(isAsc ? 'desc' : 'asc');
      setOrderSalePersonBy(property);
      const result = await dispatch(
        sortArchiveFileSalePersonItems({
          ...dataFilter,
          order_key: property === 'id' ? 'created_at' : property,
          order_sort: isAsc ? 'asc' : 'desc',
        })
      );
      if (sortArchiveFileSalePersonItems.fulfilled.match(result)) {
        setSalePersonSortList(result.payload.data);
      }
    },
    [dataFilter, dispatch, orderSalePerson, orderSalePersonBy]
  );

  const handleChangeManagerPage = useCallback(
    async (
      page: number,
      dataFilterInFirst?: UploadDocumentSearchFilterData
    ) => {
      const isAsc = orderManager === 'asc';
      setPaginationManager((prev) => ({
        ...prev,
        page: page,
        fetching: true,
      }));

      const dataFilterRequest = !!dataFilterInFirst
        ? dataFilterInFirst
        : dataFilter;
      const result = await dispatch(
        getListArchiveFileManager({
          ...dataFilterRequest,
          page: page,
          order_key: orderManagerBy ?? '',
          order_sort: !!orderManager ? (!isAsc ? 'asc' : 'desc') : '',
        })
      );
      if (getListArchiveFileManager.fulfilled.match(result)) {
        setManagerSortList(result.payload.data);
      }
      setPaginationManager((prev) => ({ ...prev, fetching: false }));
    },
    [dataFilter, dispatch, orderManager, orderManagerBy]
  );

  const handleChangeSalePersonPage = useCallback(
    async (
      page: number,
      dataFilterInFirst?: UploadDocumentSearchFilterData
    ) => {
      const isAsc = orderSalePerson === 'asc';
      setPaginationSalePerson((prev) => ({
        ...prev,
        page: page,
        fetching: true,
      }));

      const dataFilterRequest = !!dataFilterInFirst
        ? dataFilterInFirst
        : dataFilter;
      const result = await dispatch(
        getListArchiveFileSalePerson({
          ...dataFilterRequest,
          page: page,
          order_key: orderSalePersonBy ?? '',
          order_sort: !!orderSalePerson ? (!isAsc ? 'asc' : 'desc') : '',
        })
      );
      if (getListArchiveFileSalePerson.fulfilled.match(result)) {
        setSalePersonSortList(result.payload.data);
      }
      setPaginationSalePerson((prev) => ({ ...prev, fetching: false }));
    },
    [dataFilter, dispatch, orderSalePerson, orderSalePersonBy]
  );

  const updateManagerDataFilter = useCallback(
    async (dataFilter: UploadDocumentSearchFilterData) => {
      setDataFilter(dataFilter);
      setLoading(true);
      await handleChangeManagerPage(1, dataFilter);
      setLoading(false);
      setShowFilter(false);
    },
    [handleChangeManagerPage]
  );

  const updateSalePersonDataFilter = useCallback(
    async (dataFilter: UploadDocumentSearchFilterData) => {
      setDataFilter(dataFilter);
      setLoading(true);
      await handleChangeSalePersonPage(1, dataFilter);
      setLoading(false);
      setShowFilter(false);
    },
    [handleChangeSalePersonPage]
  );

  const handleFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const handleResetSearchData = async () => {
    if (isSalePerson) {
      const isAsc = orderSalePerson === 'asc';
      const result = await dispatch(
        getListArchiveFileSalePerson({
          ...dataFilter,
          page: paginationSalePerson.page,
          order_key: orderSalePersonBy ?? '',
          order_sort: !!orderSalePerson ? (!isAsc ? 'asc' : 'desc') : '',
        })
      );
      if (getListArchiveFileSalePerson.fulfilled.match(result)) {
        setSalePersonSortList(result.payload.data);
      }
    } else {
      const isAsc = orderManager === 'asc';
      const result = await dispatch(
        getListArchiveFileManager({
          ...dataFilter,
          page: paginationManager.page,
          order_key: orderManagerBy ?? '',
          order_sort: !!orderManager ? (!isAsc ? 'asc' : 'desc') : '',
        })
      );
      if (getListArchiveFileManager.fulfilled.match(result)) {
        setManagerSortList(result.payload.data);
      }
    }
  };

  useInfiniteScroll(
    isSalePerson
      ? {
          callback: (page) => handleChangeSalePersonPage(page),
          pagination: paginationSalePerson,
          id: 'document-upload-table-body',
        }
      : {
          callback: (page) => handleChangeManagerPage(page),
          pagination: paginationManager,
          id: 'document-upload-table-body',
        }
  );

  return (
    <>
      <AdminLayout
        loading={loading}
        hasDrawer={false}
        header={{
          left: { mainTitle: 'アップロード書類一覧' },
          children: <Stack direction="row" spacing={2}></Stack>,
          right: {
            contentPopover: (
              <Button
                sx={{
                  py: 0,
                  flex: 1,
                  width: '100%',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  minHeight: 42,
                  height: 42,
                  boxShadow: 'none',
                  bgcolor: 'main_white',
                  justifyContent: 'flex-start',
                  px: '10px',
                  '&.MuiButton-root:hover': {
                    bgcolor: 'sp_primary_100_main',
                    '& .MuiTypography-root': {
                      color: 'main_white',
                    },
                  },
                }}
                onClick={() => {}}
              >
                <Typography variant="auth_popover_text" color="b_333">
                  管理画面をエクスポート
                </Typography>
                <Icons.Excel sx={{ color: 'attention', ml: '9px' }} />
              </Button>
            ),
          },
          showIconHome: true,
        }}
        footer={{
          left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
          right: (
            <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />
          ),
          bgcolor: 'footer_bg',
        }}
      >
        <Stack sx={{ pb: '29px' }}>
          {isSalePerson ? (
            <SalePersonDocumentUploadTable
              onRequestSort={handleSalePersonRequestSort}
              onFilter={handleFilter}
              order={orderSalePerson}
              orderBy={orderSalePersonBy}
              rows={
                salePersonSortList.length ? salePersonSortList : sale_person
              }
              fetching={paginationSalePerson.fetching}
              onReset={setReset}
              onResetSearchData={handleResetSearchData}
            />
          ) : (
            <ManagerDocumentUploadTable
              onRequestSort={handleManagerRequestSort}
              onFilter={handleFilter}
              order={orderManager}
              orderBy={orderManagerBy}
              rows={managerSortList.length ? managerSortList : manager}
              fetching={paginationManager.fetching}
              onReset={setReset}
              onResetSearchData={handleResetSearchData}
            />
          )}
        </Stack>
      </AdminLayout>
      <UploadDocumentSearch
        open={showFilter}
        onClose={() => setShowFilter(false)}
        updateDataFilter={
          isSalePerson ? updateSalePersonDataFilter : updateManagerDataFilter
        }
      />
    </>
  );
};

export default ManagerDocumentUploadPage;
