import { FC, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { Icons, SPMenu, SPStepBar, SPSteps } from 'components';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'containers/AuthModal/selectors';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';

interface HeaderProps {
  currentStep: number;
  title?: string;
  isEditing?: boolean;
  hasMenu: boolean;
  hasStepBar: boolean;
  hasJoinGuarantor: boolean;
  hasTotalIncome: boolean;
  onSaveForm?: () => void;
  isAgreement?: boolean;
}

export const SPHeader: FC<HeaderProps> = ({
  currentStep,
  hasMenu,
  hasStepBar,
  hasJoinGuarantor,
  hasTotalIncome,
  onSaveForm,
  isAgreement,
}) => {
  const navigate = useNavigate();
  const { application_number } = useSelector(spStepTenSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const pathName = window.location.pathname;

  const spSteps: SPSteps[] = useMemo(
    () => [
      {
        label: 'お借入の\nご希望',
      },
      {
        label: 'あなたの\n情報',
      },
      {
        label: 'あなたの\nご職業',
      },
      ...(hasTotalIncome
        ? [
            {
              label: '収入\n合算者',
            },
            {
              label: '収入合算\n者の職業',
            },
          ]
        : []),
      ...(hasJoinGuarantor
        ? [
            {
              label: '担保\n提供者',
            },
          ]
        : []),
      {
        label: 'お住まい',
      },
      {
        label: '現在の\n借入状況',
      },
      {
        label: '資金計画',
      },
      {
        label: '書類添付',
      },
      ...(hasTotalIncome
        ? [
            {
              label: '収入合算\n者の書類',
            },
          ]
        : []),
      {
        label: '担当者\n情報',
      },
      {
        label: '入力内容\nご確認',
      },
      {
        label: '仮審査\n申込完了',
      },
    ],
    [hasJoinGuarantor, hasTotalIncome]
  );

  return (
    <Stack
      sx={{
        position: 'fixed',
        zIndex: 2,
        top: 0,
        left: '50% -240px',
        right: '50% -240px',
        '@media (orientation: landscape)': {
          maxWidth: isMobile ? 'none' : 480,
        },
        maxWidth: 480,
        width: '100%',
        backgroundColor: 'main_white',
        borderTop: '1px solid',
        borderColor: 'gray',
        boxShadow: hasStepBar ? '0px 2px 2px rgba(0, 0, 0, 0.15)' : '',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box
          onClick={() => {
            if (!isAgreement) {
              if (isLoggedIn) {
                if (onSaveForm) {
                  onSaveForm();
                }
                if (!!application_number) {
                  if (
                    window.confirm(
                      'トップページへ遷移します。\nご入力いただいた情報をまだ保存していない場合、破棄しますが、宜しいでしょうか。'
                    )
                  ) {
                    navigate(routeNames.Top.path, {
                      state: { pathName: window.location.pathname },
                    });
                  }
                } else if (routeNames.Top.path === pathName) {
                  navigate(0);
                } else {
                  navigate(routeNames.Top.path, {
                    state: { pathName: window.location.pathname },
                  });
                }
              } else {
                navigate(routeNames.StartPage.path);
              }
            }
          }}
          sx={{ px: 4, py: 3 }}
        >
          <Icons.SPHeaderLogo sx={{ width: 171, height: 17 }} />
        </Box>
        {hasMenu && (
          <Stack sx={{ px: 3 }}>
            <SPMenu onSaveForm={onSaveForm} />
          </Stack>
        )}
      </Stack>
      {hasStepBar && (
        <SPStepBar
          steps={spSteps}
          currentStep={currentStep}
          hasJoinGuarantor={hasJoinGuarantor}
          hasTotalIncome={hasTotalIncome}
        />
      )}
    </Stack>
  );
};
