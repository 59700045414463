import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SPStepIncomeTotalizerForm } from 'types';
import { logout } from 'containers/AuthModal/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { NationalityType, SexType } from 'constant';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { convertImageResponse } from 'utils';
import { deleteUser } from '../SPUnsubcribed/thunk';
import { spGetSubmittedPreliminary } from '../SPLogin/thunk';

export type InitialState = {
  form: SPStepIncomeTotalizerForm;
  status: {
    firstElement: boolean | undefined;
    secondElement: boolean | undefined;
    isReseted: boolean;
  };
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      p_applicant_people_attributes: [
        {
          last_name_kanji: '',
          first_name_kanji: '',
          last_name_kana: '',
          first_name_kana: '',
          sex: SexType.DEFAULT,
          name_relationship_to_applicant: '',
          nationality: NationalityType.DEFAULT,
          birthday: '',
          mobile_phone_number: '',
          home_phone_number: '',
          postal_code: '',
          prefecture_kanji: '',
          city_kanji: '',
          district_kanji: '',
          prefecture_kana: '',
          city_kana: '',
          district_kana: '',
          other_address_kanji: '',
          other_address_kana: '',
          residence_file: [],
          residence_file_back_image: [],
        },
      ],
    },
  },
  status: {
    firstElement: undefined,
    secondElement: undefined,
    isReseted: false,
  },
};

const spStepIncomeTotalizerSlice = createSlice({
  name: 'spStepIncomeTotalizer',
  initialState,
  reducers: {
    addStatus: (
      state,
      action: PayloadAction<{
        firstElement: boolean | undefined;
        secondElement: boolean | undefined;
        isReseted: boolean;
      }>
    ) => {
      state.status = { ...action.payload };
    },
    saveForm: (state, action: PayloadAction<SPStepIncomeTotalizerForm>) => {
      state.form = {
        ...action.payload,
      };
    },
    resetStepFourIncomeForm: (state) => {
      state.form.p_application_header.p_applicant_people_attributes =
        initialState.form.p_application_header.p_applicant_people_attributes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_applicant_people } = action.payload.data;
      if (!!p_applicant_people[1]) {
        const {
          last_name_kanji,
          first_name_kanji,
          last_name_kana,
          first_name_kana,
          sex,
          name_relationship_to_applicant,
          nationality,
          birthday,
          mobile_phone_number,
          home_phone_number,
          postal_code,
          prefecture_kanji,
          city_kanji,
          district_kanji,
          prefecture_kana,
          city_kana,
          district_kana,
          other_address_kanji,
          other_address_kana,
          residence_file,
          residence_file_back_image,
        } = p_applicant_people[1];
        state.form.p_application_header.p_applicant_people_attributes = [
          {
            ...state.form.p_application_header.p_applicant_people_attributes[0],
            last_name_kanji,
            first_name_kanji,
            last_name_kana,
            first_name_kana,
            sex,
            name_relationship_to_applicant,
            nationality,
            birthday,
            mobile_phone_number,
            home_phone_number,
            postal_code,
            prefecture_kanji,
            city_kanji,
            district_kanji,
            prefecture_kana,
            city_kana,
            district_kana,
            other_address_kanji,
            other_address_kana,
            ...(!!residence_file ? convertImageResponse(residence_file) : []),
            ...(!!residence_file_back_image
              ? convertImageResponse(residence_file_back_image)
              : []),
          },
        ];
      }
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const { p_applicant_people_attributes } = action.payload.data;
      if (!!p_applicant_people_attributes[1]) {
        const {
          id,
          last_name_kanji,
          first_name_kanji,
          last_name_kana,
          first_name_kana,
          sex,
          birthday,
          name_relationship_to_applicant,
          nationality,
          mobile_phone_number,
          home_phone_number,
          postal_code,
          prefecture_kanji,
          city_kanji,
          district_kanji,
          prefecture_kana,
          city_kana,
          district_kana,
          other_address_kanji,
          other_address_kana,
          personal_file_upload,
        } = p_applicant_people_attributes[1];

        state.form.p_application_header = {
          id,
          p_applicant_people_attributes: [
            {
              ...state.form.p_application_header
                .p_applicant_people_attributes[0],
              last_name_kanji,
              first_name_kanji,
              last_name_kana,
              first_name_kana,
              sex,
              birthday,
              name_relationship_to_applicant,
              nationality,
              mobile_phone_number,
              home_phone_number,
              postal_code,
              prefecture_kanji,
              city_kanji,
              district_kanji,
              prefecture_kana,
              city_kana,
              district_kana,
              other_address_kanji,
              other_address_kana,
              residence_file: convertImageResponse(
                personal_file_upload.residence_file
              ),
              residence_file_back_image: convertImageResponse(
                personal_file_upload.residence_file_back_image
              ),
            },
          ],
        };
      } else {
        state.form.p_application_header = {
          p_applicant_people_attributes:
            initialState.form.p_application_header
              .p_applicant_people_attributes,
        };
      }
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const { p_applicant_people_attributes } = action.payload.data;
      if (!!p_applicant_people_attributes[1]) {
        const {
          id,
          last_name_kanji,
          first_name_kanji,
          last_name_kana,
          first_name_kana,
          sex,
          birthday,
          name_relationship_to_applicant,
          nationality,
          mobile_phone_number,
          home_phone_number,
          postal_code,
          prefecture_kanji,
          city_kanji,
          district_kanji,
          prefecture_kana,
          city_kana,
          district_kana,
          other_address_kanji,
          other_address_kana,
          personal_file_upload,
        } = p_applicant_people_attributes[1];

        state.form.p_application_header = {
          id,
          p_applicant_people_attributes: [
            {
              ...state.form.p_application_header
                .p_applicant_people_attributes[0],
              last_name_kanji,
              first_name_kanji,
              last_name_kana,
              first_name_kana,
              sex,
              birthday,
              name_relationship_to_applicant,
              nationality,
              mobile_phone_number,
              home_phone_number,
              postal_code,
              prefecture_kanji,
              city_kanji,
              district_kanji,
              prefecture_kana,
              city_kana,
              district_kana,
              other_address_kanji,
              other_address_kana,
              residence_file: convertImageResponse(
                personal_file_upload.residence_file
              ),
              residence_file_back_image: convertImageResponse(
                personal_file_upload.residence_file_back_image
              ),
            },
          ],
        };
      } else {
        state.form.p_application_header = {
          p_applicant_people_attributes:
            initialState.form.p_application_header
              .p_applicant_people_attributes,
        };
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const { saveForm, resetStepFourIncomeForm, addStatus } =
  spStepIncomeTotalizerSlice.actions;
export default spStepIncomeTotalizerSlice.reducer;
