import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { buildIncomeTotalizer } from '../../AdminEditPreliminary/utils';

export const buildIncomeTotalizerSalePerson = (
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header']
) => {
  return buildIncomeTotalizer(values)
    .filter(({ required, name_en }) => {
      if (
        name_en ===
        'p_applicant_people_attributes[1].name_relationship_to_applicant'
      ) {
        return true;
      }
      return !required;
    })
    .map((row) => {
      if (
        row.name_en ===
        'p_applicant_people_attributes[1].name_relationship_to_applicant'
      ) {
        return {
          ...row,
          required: false,
          subField: undefined,
        };
      }

      return row;
    });
};
