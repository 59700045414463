import { IconButton, Link, Stack, Typography } from '@mui/material';
import {
  Icons,
  InputSelectProps,
  SPButton,
  SPFormItem,
  SPImage,
  SPInputDateTime,
  SPInputField,
  SPInputPhoneNumber,
  SPInputSelect,
  SPInputZipCode,
  SPModal,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPSaveImageModal,
  SPUploadImageItem,
} from 'components';
import { config } from 'configs';
import {
  BorrowerIncomeType,
  GeneralIncomeConfirmation,
  IdentityVerificationType,
  LoanType,
  NationalityType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PREFECTURES,
} from 'constant';
import { SPStepLayout } from 'containers';
import {
  isIncomeTotalizerInitSelector,
  isJoinGuarantorInitSelector,
  spCurrentStepSelector,
} from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import { FormikProvider, getIn, useFormik } from 'formik';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import kanjidate from 'kanjidate';
import { dayjs } from 'libs';
import { get, isEmpty, update } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostalCode } from 'services';
import {
  checkingExistFile,
  convertDynamicOptions,
  convertImage,
  convertToFullWidth,
  convertToHalfWidth,
  flattenToLodashFormat,
  kanaMap1,
  supportDvh,
  zeroPad,
} from 'utils';
import { dynamicOptionsSelectors } from '../SPStartScreen/selectors';
import { spStepEightSelector } from '../SPStepEight/selectors';
import { spStepFiveSelector } from '../SPStepFive/selectors';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneSelector,
} from '../SPStepOne/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepSixSelector } from '../SPStepSix/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import { spStepTwoSelector } from '../SPStepTwo/selectors';
import { getZipCloudAddress } from '../StepRequiredInformation/thunk';
import { Consent } from '../SPAgreementScreen/Consent';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { spStepTwoIncomeTotalSelector } from './selectors';
import { addStatus, saveForm } from './slice';
import { validationSchema } from './validationSchema';

const SPStepFourIncomeTotalizerPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const birthdayRef = useRef<HTMLDivElement>(null);
  const refConsent = useRef<HTMLDivElement>(null);
  const refConfirmation = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const initialValues = useSelector(spStepTwoIncomeTotalSelector);
  const currentStep = useSelector(spCurrentStepSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const { application_number } = useSelector(spStepTenSelector);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const isJoinGuarantorInit = useSelector(isJoinGuarantorInitSelector);
  const isIncomeTotalizerInit = useSelector(isIncomeTotalizerInitSelector);

  const location = useLocation();

  useCheckPreliminaryStatus();
  useGoBackTop();

  const {
    residenceFile,
    residenceFileBackImage,
    repaymentScheduleImage,
    businessCard,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    residenceIncomeFile,
    setResidenceIncomeFile,
    residenceIncomeFileBackImage,
    setResidenceIncomeFileBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [open, setOpen] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [postalCodeError, setPostalCodeError] = useState<string>('');
  const [consentRef, setConsentRef] = useState<boolean>(false);
  const [confirmationRef, setConfirmationRef] = useState<boolean>(false);
  const { updateFormOverride } = useUpdateForm();
  const [consents, setConsents] = useState<(boolean | undefined)[]>([
    undefined,
    undefined,
  ]);
  const [errorResidenceIncomeFile, setErrorResidenceIncomeFile] =
    useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const urls = [config.dummyPdfUrl, config.termConditionUrl];

  const loanTermYearNum =
    spStepOneValues.p_application_header.p_borrowing_details_attributes[0]
      .loan_term_year_num;

  // @ts-ignore
  const previousPathName = !!location.state ? location.state.pathName : '';

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(loanTermYearNum),
    async onSubmit(values, { setSubmitting }) {
      if (
        initialValues.p_applicant_people.postal_code &&
        values.p_applicant_people.postal_code !==
          initialValues.p_applicant_people.postal_code
      ) {
        values.p_applicant_people.other_address_kana = '';
      }
      await dispatch(
        saveForm({
          p_application_header: {
            p_applicant_people_attributes: [values.p_applicant_people],
          },
        })
      );
      dispatch(
        addStatus({
          firstElement: true,
          secondElement: true,
          isReseted: false,
        })
      );
      if (isSubmit) {
        setIsDisabledButton(true);
        updateFormOverride(
          {
            stepTwoIncomeData: {
              p_application_header: {
                p_applicant_people_attributes: [values.p_applicant_people],
              },
            },
            spStepTwoStatusIsReseted: false,
            imageContext: imageContext,
          },
          setIsDisabledButton,
          setOpenImageModal,
          setReset
        );
        return;
      }

      if (
        !!application_number &&
        spStepSevenIncomeValues.p_applicant_people.identity_verification ===
          IdentityVerificationType.DEFAULT &&
        incomeTotalizer
      ) {
        return navigate(routeNames.SPStepFiveIncomeTotalizer.path);
      }

      dispatch(setCurrentStep('5'));
      setSubmitting(false);
      navigate(routeNames.SPStepFiveIncomeTotalizer.path);
      // }
    },
  });

  useEffect(() => {
    if (application_number !== '') {
      setConsents([true, true]);
    }
  }, [application_number]);

  // const age = useMemo(() => {
  //   const monthDiff = dayjs().diff(
  //     formik.values.p_applicant_people.birthday,
  //     'month'
  //   );
  //   const [year] = formik.values?.p_applicant_people.birthday?.split('/');
  //   if (!formik.values.p_applicant_people.birthday || monthDiff < 0 || !year)
  //     return '';
  //   return Math.floor(monthDiff / 12);
  // }, [formik.values.p_applicant_people.birthday]);

  // const loanTermLimit = useMemo(
  //   () => +loanTermYearNum + +age > 80,
  //   [loanTermYearNum, age]
  // );

  const isSubmit = useMemo(() => {
    return (
      !!application_number &&
      (previousPathName === routeNames.Top.path ||
        previousPathName === routeNames.SPStepNine.path ||
        (isJoinGuarantorInit === hasJoinGuarantor &&
          isIncomeTotalizerInit === incomeTotalizer))
    );
  }, [
    application_number,
    hasJoinGuarantor,
    incomeTotalizer,
    isIncomeTotalizerInit,
    isJoinGuarantorInit,
    previousPathName,
  ]);

  const isStepFourJoin = useMemo(() => {
    return (
      spStepOneValues.p_application_header.loan_type === LoanType.THREE ||
      spStepOneValues.p_application_header.loan_type === LoanType.FOUR
    );
  }, [spStepOneValues.p_application_header.loan_type]);

  useEffect(() => {
    if (!!application_number && !incomeTotalizer) {
      navigate(routeNames.SPStepOne.path);
    }
  }, [application_number, incomeTotalizer, navigate]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  const setHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      postal_code: postal.zipcode.slice(0, 3) + '-' + postal.zipcode.slice(3),
      prefecture_kanji: postal.address1,
      district_kanji: postal.address3,
      city_kanji: postal.address2,
      prefecture_kana: convertToFullWidth(postal.kana1),
      district_kana: convertToFullWidth(postal.kana3),
      city_kana: convertToFullWidth(postal.kana2),
      other_address_kanji: '',
    });
    await formik.setFieldTouched(`${name}.other_address_kanji`, false, false);
  };
  const handleZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        setPostalCodeError(
          '住所が取得できませんでした。再度入力してください。'
        );
        await formik.setFieldValue(`p_applicant_people.prefecture_kanji`, '');
        await formik.setFieldValue(`p_applicant_people.city_kanji`, '');
        await formik.setFieldValue(`p_applicant_people.district_kanji`, '');
        await formik.setFieldValue(`p_applicant_people.prefecture_kana`, '');
        await formik.setFieldValue(`p_applicant_people.city_kana`, '');
        await formik.setFieldValue(`p_applicant_people.district_kana`, '');
        return;
      }
      await setHomeAddress(result.payload.results[0], `p_applicant_people`);
      setPostalCodeError('');
    }
  };

  const sexOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.sex),
    [dynamicOptions.sex]
  );

  const nationalityOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nationality),
    [dynamicOptions.nationality]
  );

  useEffect(() => {
    let newResidenceIncomeFileImage: (SPImage | File)[] = [];
    if (
      !!residenceIncomeFile &&
      residenceIncomeFile.length === 0 &&
      !!formik.values.p_applicant_people.residence_file &&
      !!formik.values.p_applicant_people.residence_file?.length
    ) {
      formik.values.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          return newResidenceIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!residenceIncomeFile && !!residenceIncomeFile.length) {
      newResidenceIncomeFileImage = [...residenceIncomeFile];
    } else {
      formik.values.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          return newResidenceIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setResidenceIncomeFile(newResidenceIncomeFileImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newResidenceIncomeFileBackImage: (SPImage | File)[] = [];
    if (
      !!residenceIncomeFileBackImage &&
      residenceIncomeFileBackImage.length === 0 &&
      !!formik.values.p_applicant_people.residence_file_back_image &&
      !!formik.values.p_applicant_people.residence_file_back_image?.length
    ) {
      formik.values.p_applicant_people.residence_file_back_image?.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidenceIncomeFileBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residenceIncomeFileBackImage &&
      !!residenceIncomeFileBackImage.length
    ) {
      newResidenceIncomeFileBackImage = [...residenceIncomeFileBackImage];
    } else {
      formik.values.p_applicant_people.residence_file_back_image?.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidenceIncomeFileBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidenceIncomeFileBackImage(newResidenceIncomeFileBackImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      formik.values?.p_applicant_people?.nationality ===
        NationalityType.FOREIGN_NATIONALITY &&
      !!residenceIncomeFile.length &&
      !!residenceIncomeFileBackImage.length
    ) {
      setErrorResidenceIncomeFile(false);
    }
  }, [
    residenceIncomeFile.length,
    residenceIncomeFileBackImage.length,
    formik.values?.p_applicant_people?.nationality,
  ]);

  const checkRequiredResidenceIncomeFile = useMemo(() => {
    if (
      formik.values?.p_applicant_people?.nationality ===
      NationalityType.FOREIGN_NATIONALITY
    )
      return (
        !!residenceIncomeFile.length && !!residenceIncomeFileBackImage.length
      );
  }, [
    residenceIncomeFile.length,
    residenceIncomeFileBackImage.length,
    formik.values?.p_applicant_people?.nationality,
  ]);

  const handleSubmit = useCallback(() => {
    if (
      formik.values.p_applicant_people.nationality !==
        NationalityType.FOREIGN_NATIONALITY ||
      (formik.values.p_applicant_people.nationality ===
        NationalityType.FOREIGN_NATIONALITY &&
        !!residenceIncomeFile.length &&
        !!residenceIncomeFileBackImage.length)
    ) {
      return formik.handleSubmit();
    }
    if (!checkRequiredResidenceIncomeFile) {
      setErrorResidenceIncomeFile(true);
      imageRef.current?.scrollIntoView({ behavior: 'smooth' });
      birthdayRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    residenceIncomeFile.length,
    checkRequiredResidenceIncomeFile,
    formik,
    residenceIncomeFileBackImage.length,
  ]);

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);

    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });
    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });
    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = formik.values.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });
      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/
    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      consents.every((consent) => consent === true)
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    if (spSaveDraft.rejected.match(result)) {
      setReset(reset + 1);
    }
    return;
  };

  useEffect(() => {
    if (!!application_number && !isStepFourJoin) {
      setConsents((prevState) => {
        const cloneState = [...prevState].slice(0);
        cloneState[0] = undefined;
        cloneState[1] = undefined;
        return cloneState;
      });
      setConsentRef(false);
      setConfirmationRef(false);
    } else {
      setConsents((prevState) => {
        const cloneState = [...prevState].slice(0);
        cloneState[0] = initialValues.status?.firstElement;
        cloneState[1] = initialValues.status?.secondElement;
        return cloneState;
      });
      setConsentRef(initialValues.status?.firstElement === true);
      setConfirmationRef(initialValues.status?.secondElement === true);
    }
  }, [
    application_number,
    dispatch,
    initialValues.status,
    isStepFourJoin,
    spStepOneValues.p_application_header.loan_type,
  ]);

  const handleScrollConsent = () => {
    if (refConsent.current) {
      const isScrolledToBottom =
        Math.abs(
          refConsent.current.scrollHeight -
            refConsent.current.scrollTop -
            refConsent.current.clientHeight
        ) <= 10;
      if (isScrolledToBottom) {
        setConsentRef(true);
      }
    }
  };

  const handleScrollConfirmation = () => {
    if (refConfirmation.current) {
      const isScrolledToBottom =
        Math.abs(
          refConfirmation.current.scrollHeight -
            refConfirmation.current.scrollTop -
            refConfirmation.current.clientHeight
        ) <= 10;
      if (isScrolledToBottom) {
        setConfirmationRef(isScrolledToBottom);
      }
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement;
    let transformedValue = value;
    Object.entries(kanaMap1).forEach(([key, replacement]) => {
      transformedValue = transformedValue.replaceAll(key, replacement);
    });
    formik.setFieldValue(name, transformedValue);
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={() =>
          dispatch(
            saveForm({
              p_application_header: {
                p_applicant_people_attributes: [
                  formik.values.p_applicant_people,
                ],
              },
            })
          )
        }
        footer={{
          left: {
            onClick: () => {
              dispatch(
                saveForm({
                  p_application_header: {
                    p_applicant_people_attributes: [
                      formik.values.p_applicant_people,
                    ],
                  },
                })
              );
              addStatus({
                firstElement: undefined,
                secondElement: undefined,
                isReseted: !consents.every((consent) => consent === true),
              });
              if (
                !!previousPathName &&
                previousPathName === routeNames.SPStepOne.path
              ) {
                navigate(routeNames.SPStepOne.path, {
                  state: { pathName: window.location.pathname },
                });
              } else {
                if (!!application_number) {
                  navigate(-1);
                } else {
                  navigate(routeNames.SPStepThree.path);
                }
              }
            },
          },
          right: {
            children: isSubmit ? '保存' : '次へ',
            // disabled: !(
            //   consents.every((consent) => consent === true) ||
            //   (!!application_number && !initialValues.status.isReseted)
            // ),
            onClick: handleSubmit,
            disabled: isDisabledButton,
          },
        }}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
        onSaveDraft={onSaveDraft}
      >
        <Stack sx={{ width: '100%', pb: 2 }}>
          <Stack
            sx={{ pt: 9, py: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >{`収入合算者について\n教えてください。`}</Typography>
          </Stack>
          <IconButton
            sx={{
              color: 'sp_primary_100_main',
              p: '0 2px',
              direction: 'row',
            }}
            onClick={() => setOpenModal(true)}
          >
            <Icons.SPQuestion sx={{ width: 16, height: 16 }} />
            <Typography
              variant="SP_form_item_help"
              color="sp_primary_100_main"
              sx={{
                textDecoration: 'underline',
              }}
            >
              収入合算とは？
            </Typography>
          </IconButton>
          <Stack sx={{ p: '24px 24px 0 24px' }}>
            {consents.some((consent) => consent === false) && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{
                  p: '12px 18px 12px 18px',
                  bgcolor: 'sp_secondary_pink_light',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_secondary_01}`,
                  borderRadius: '8px',
                }}
              >
                <Icons.SPWarning />
                <Typography variant="drawerText" color="sp_secondary_01">
                  同意いただけない場合、本サービスをご利用いただけません。
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        {initialValues.status?.isReseted && (
          <Stack>
            <Stack
              sx={{
                borderRadius: 2,
                border: () => `1px solid #A9AFEA`,
                p: '12px',
                margin: '0 16px 32px 16px',
              }}
            >
              <Stack>
                <Typography
                  variant="SP_form_item_label"
                  sx={{
                    color: '#6B70F0',
                    fontWeight: 500,
                    textAlign: 'center',
                    fontSize: '16px',
                    letterSpacing: '0.6',
                  }}
                >
                  連帯保証人予定者（収入合算者）の方へ
                </Typography>
                <Typography
                  variant="textstyle"
                  sx={{
                    whiteSpace: 'break-spaces',
                    color: '#333333',
                    fontWeight: 300,
                    textAlign: 'center',
                    letterSpacing: '0.6',
                    fontSize: '14px',
                    mt: '8px',
                    lineHeight: '24px',
                  }}
                >
                  {`下記2つの同意事項をよくお読みいただき \n 必ず連帯保証人予定者が選択してください。`}
                </Typography>
              </Stack>
            </Stack>
            {consents.map((consent, index) => (
              <Stack spacing={4} sx={{ mb: 10 }} key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  bgcolor="sp_primary_40"
                  spacing={2}
                  sx={{ p: '5px 16px 5px 16px' }}
                >
                  <Typography
                    sx={{ textDecorationColor: 'sp_primary_100_main' }}
                    color="b_333"
                    variant="sp_label_form_text"
                  >
                    {!index
                      ? '個人情報の取扱いに関する同意書 兼 表明および確約書'
                      : '銀行代理業にかかる確認書　兼　個人情報の取扱い等に関する同意書'}
                  </Typography>
                </Stack>
                <Stack sx={{ px: 4 }}>
                  <Stack
                    sx={{
                      border: '1px solid #EEF0FF',
                      boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
                      bgcolor: 'main_white',
                      borderRadius: '4px',
                      height: '272px',
                      gap: '10px',
                      overflow: 'auto',
                    }}
                    onScroll={
                      !index ? handleScrollConsent : handleScrollConfirmation
                    }
                    ref={!index ? refConsent : refConfirmation}
                  >
                    <Consent url={urls[index]} />
                  </Stack>
                </Stack>
                <Stack sx={{ pr: 4 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Icons.SPPdfLite />
                    <Link
                      href={urls[index]}
                      target="_blank"
                      component="a"
                      sx={{ textDecorationColor: 'sp_primary_100_main' }}
                      color="sp_primary_100_main"
                      variant="sp_agreement_text"
                      onClick={() =>
                        !index ? setConsentRef(true) : setConfirmationRef(true)
                      }
                    >
                      全文を見る
                    </Link>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{ px: 4 }}
                >
                  <Stack spacing={1}>
                    <SPButton
                      sx={{
                        ':hover': {
                          boxShadow: 'none',
                        },
                        width: '168px',
                        height: '48px',
                        bgcolor: `${consent ? 'sp_primary_20' : 'main_white'}`,
                        border: (theme) =>
                          `1px solid ${
                            consent
                              ? theme?.palette?.sp_primary_100_main
                              : theme?.palette?.sp_primary_40
                          }`,
                        boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                        borderRadius: '14px',
                        '&.Mui-disabled': { opacity: 0.5 },
                      }}
                      onClick={() =>
                        setConsents((prevState) => {
                          const cloneState = [...prevState].slice(0);
                          cloneState[index] = true;
                          return cloneState;
                        })
                      }
                      disabled={index === 0 ? !consentRef : !confirmationRef}
                    >
                      <Typography
                        variant="SP_multiple_checkbox_label"
                        color="sp_primary_100_main"
                      >
                        同意する
                      </Typography>
                    </SPButton>
                    {consent === false && (
                      <Typography variant="drawerText" color="sp_secondary_01">
                        ※ご確認ください
                      </Typography>
                    )}
                  </Stack>
                  <SPButton
                    sx={{
                      ':hover': { boxShadow: 'none' },
                      width: '168px',
                      height: '48px',
                      bgcolor: `${
                        consent === false ? 'sp_primary_20' : 'main_white'
                      }`,
                      border: (theme) =>
                        `1px solid ${
                          consent === false
                            ? theme?.palette?.sp_primary_100_main
                            : theme?.palette?.sp_primary_40
                        }`,
                      boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                      borderRadius: '14px',
                      '&.Mui-disabled': { opacity: 0.5 },
                    }}
                    onClick={() =>
                      setConsents((prevState) => {
                        const cloneState = [...prevState].slice(0);
                        cloneState[index] = false;
                        return cloneState;
                      })
                    }
                    disabled={!index ? !consentRef : !confirmationRef}
                  >
                    <Typography
                      variant="SP_multiple_checkbox_label"
                      color="sp_primary_100_main"
                    >
                      同意しない
                    </Typography>
                  </SPButton>
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
        {(consents.every((consent) => consent === true) ||
          (!!application_number && !initialValues.status?.isReseted) ||
          (!!application_number &&
            initialValues.status?.isReseted &&
            !isStepFourJoin)) && (
          <>
            <SPFormItem
              label="収入合算者のお名前"
              labelSx={{
                p: '4px 16px',
              }}
              required
            >
              <Stack spacing={3}>
                <SPInputField
                  name="p_applicant_people.last_name_kanji"
                  placeholder="姓"
                  convertFullWidth
                />
                <SPInputField
                  name="p_applicant_people.first_name_kanji"
                  placeholder="名"
                  convertFullWidth
                />
                <Stack direction="row" spacing={1}>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    外国籍のかたは、在留カード通りに入力ください。
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※
                  </Typography>
                  <Typography variant="SP_form_item_note" color="b_333">
                    お名前の漢字が外字等で変換できない場合は常用漢字でご入力ください。
                  </Typography>
                </Stack>
              </Stack>
            </SPFormItem>
            <SPFormItem
              label="収入合算者のお名前（フリガナ）"
              labelSx={{ p: '4px 16px' }}
              required
            >
              <Stack spacing={`10px`} ref={birthdayRef}>
                <SPInputField
                  name="p_applicant_people.last_name_kana"
                  placeholder="セイ"
                  onBlur={(event) => handleBlur(event)}
                />
                <SPInputField
                  name="p_applicant_people.first_name_kana"
                  placeholder="メイ"
                  onBlur={(event) => handleBlur(event)}
                />
              </Stack>
            </SPFormItem>
            <SPFormItem label="性別" labelSx={{ p: '4px 16px' }} required>
              <SPRadioGroupButton
                name="p_applicant_people.sex"
                options={sexOptions}
                display="row"
              />
            </SPFormItem>
            {spStepOneValues.p_application_header.loan_type ===
              LoanType.THREE && (
              <SPFormItem label="続柄" labelSx={{ p: '4px 16px' }} required>
                <SPInputField
                  name="p_applicant_people.name_relationship_to_applicant"
                  placeholder="例：父"
                  convertFullWidth
                />
              </SPFormItem>
            )}
            <SPFormItem label="生年月日" labelSx={{ p: '4px 16px' }} required>
              <Stack spacing="6px" ref={imageRef}>
                <SPInputDateTime
                  name="p_applicant_people.birthday"
                  yearOptions={yearOptions}
                  checkRequired
                />
              </Stack>
            </SPFormItem>
            <SPFormItem label="現在の国籍" labelSx={{ p: '4px 16px' }} required>
              <Stack>
                <SPRadioGroupButton
                  name="p_applicant_people.nationality"
                  options={nationalityOptions}
                  display="row"
                  onChange={(checked, value) => {
                    if (value !== NationalityType.FOREIGN_NATIONALITY) {
                      setResidenceIncomeFile([]);
                      setResidenceIncomeFileBackImage([]);
                    }
                  }}
                />
                {formik.values.p_applicant_people.nationality ===
                  NationalityType.FOREIGN_NATIONALITY && (
                  <Stack
                    sx={{
                      mt: 3,
                      borderRadius: '8px',
                      border: (theme) =>
                        `1px solid ${theme?.palette?.h_blugreen}`,
                    }}
                  >
                    <Stack
                      sx={{
                        bgcolor: 'sp_primary_40',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        p: '4px 12px 4px 12px',
                      }}
                    >
                      <Typography
                        variant="upload_image_title"
                        color="normal_text"
                      >
                        在留カードまたは特別永住者証明書を添付してください。
                      </Typography>
                    </Stack>
                    {errorResidenceIncomeFile && (
                      <Typography
                        variant="text_error"
                        color="sp_secondary_01"
                        sx={{ ml: 4, mt: 1 }}
                      >
                        ※外国籍の場合、在留カードまたは特別永住者証明書を添付することは必須です。
                      </Typography>
                    )}
                    <Stack direction="row" spacing={1} sx={{ py: 3 }}>
                      <Stack>
                        <Typography
                          pl={3}
                          variant="SP_label_field"
                          color="b_333"
                        >
                          〈表面〉
                        </Typography>
                        <SPUploadImageItem
                          variant="imageOnly"
                          images={residenceIncomeFile}
                          setImages={setResidenceIncomeFile}
                          reload={reset}
                          uploadMulti={false}
                          handleDeleteImage={(id) => {
                            const residence_file = convertImage(
                              formik.values.p_applicant_people.residence_file,
                              id
                            );
                            formik.setFieldValue(
                              'p_applicant_people.residence_file',
                              residence_file
                            );
                          }}
                        />
                      </Stack>
                      <Stack>
                        <Typography
                          pl={3}
                          variant="SP_label_field"
                          color="b_333"
                        >
                          〈裏面〉
                        </Typography>
                        <SPUploadImageItem
                          variant="imageOnly"
                          images={residenceIncomeFileBackImage}
                          setImages={setResidenceIncomeFileBackImage}
                          reload={reset}
                          uploadMulti={false}
                          handleDeleteImage={(id) => {
                            const residence_file_back_image = convertImage(
                              formik.values.p_applicant_people
                                .residence_file_back_image,
                              id
                            );
                            formik.setFieldValue(
                              'p_applicant_people.residence_file_back_image',
                              residence_file_back_image
                            );
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </SPFormItem>
            <SPFormItem label="電話番号" labelSx={{ p: '4px 16px' }} required>
              <Stack spacing={3}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="sp_unit_select_text" color="b_333">
                    携帯
                  </Typography>
                  <SPInputPhoneNumber
                    name="p_applicant_people.mobile_phone_number"
                    type="mobilePhone"
                  />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="sp_unit_select_text" color="b_333">
                    自宅
                  </Typography>
                  <SPInputPhoneNumber
                    name="p_applicant_people.home_phone_number"
                    type="homePhone"
                  />
                </Stack>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※半角数字でご入力ください。
                </Typography>
              </Stack>
            </SPFormItem>
            <SPFormItem
              label="現住所"
              labelSx={{ p: '4px 16px' }}
              childrenSx={{ p: '8px 24px' }}
              required
            >
              <Stack spacing={6}>
                <Stack spacing={`6px`}>
                  <SPInputZipCode
                    name="p_applicant_people.postal_code"
                    label="郵便番号"
                    handleFocusZipCode={() => setPostalCodeError('')}
                    handleInputZipcode={(values) => {
                      if (
                        values?.length === 8 &&
                        values === convertToHalfWidth(values)
                      ) {
                        return handleZipCodeChange(values);
                      }
                    }}
                  />
                  {postalCodeError && (
                    <Typography variant="text_error" color="sp_secondary_01">
                      ※{postalCodeError}
                    </Typography>
                  )}
                  <Typography variant="SP_form_item_note" color="b_333">
                    ※入力すると自動的に住所が表示されます。
                  </Typography>
                </Stack>
                <SPInputSelect
                  name="p_applicant_people.prefecture_kanji"
                  placeholder="----"
                  label="都道府県"
                  options={PREFECTURES}
                  sx={{ maxWidth: '110px', textAlign: 'center' }}
                  onChange={() => {
                    if (formik.values.p_applicant_people.prefecture_kana) {
                      formik.setFieldValue(
                        'p_applicant_people.prefecture_kana',
                        ''
                      );
                    }
                  }}
                />
                <SPInputField
                  name="p_applicant_people.city_kanji"
                  placeholder="例：港区"
                  label="市区郡　（例：港区）"
                  convertFullWidth
                  onChange={() => {
                    if (formik.values.p_applicant_people.city_kana) {
                      formik.setFieldValue('p_applicant_people.city_kana', '');
                    }
                  }}
                />
                <SPInputField
                  name="p_applicant_people.district_kanji"
                  placeholder="例：芝浦４丁目"
                  label="町村丁目（例：芝浦４丁目）"
                  convertFullWidth
                  onChange={() => {
                    if (formik.values.p_applicant_people.district_kana) {
                      formik.setFieldValue(
                        'p_applicant_people.district_kana',
                        ''
                      );
                    }
                  }}
                />
                <SPInputField
                  name="p_applicant_people.other_address_kanji"
                  placeholder="例：12-38　キャナルゲート芝浦605号室"
                  label="丁目以下・建物名・部屋番号（例：12-38　キャナルゲート芝浦605号室）"
                  convertFullWidth
                  labelStyle={{ lineHeight: '20px' }}
                  onChange={() => {
                    if (formik.values.p_applicant_people.other_address_kana) {
                      formik.setFieldValue(
                        'p_applicant_people.other_address_kana',
                        ''
                      );
                    }
                  }}
                />
              </Stack>
            </SPFormItem>

            <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
            <SPSaveImageModal
              open={openImageModal}
              onClose={() => {
                setOpenImageModal(false);
              }}
            />
          </>
        )}

        <SPModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
          title="収入合算とは？"
          disableBackDropClick
          sxPadding="2px"
          footer={
            <Stack
              spacing={6}
              justifyContent="center"
              alignItems="center"
              sx={{ minWidth: 359 }}
            >
              <Stack
                sx={{ overflowY: 'scroll', maxHeight: '37vh', px: 6 }}
                spacing={5}
              >
                <Typography variant="sp_change_password_note" color="b_333">
                  申込者本人の収入だけでは希望条件を満たせない場合等に、原則として同居している家族（収入合算者）の収入を合算するお申込方法です。収入合算者を連帯保証人とさせていただきます。
                </Typography>
                <Typography
                  variant="sp_change_password_note"
                  color="b_333"
                >{`●収入合算者に指定できるかたは、次の条件を全て満たす個人のお客さまです。\n・借入時の年齢が満18歳以上満65歳以下のかた\n・安定継続した収入があること\n・申込者の親（配偶者の親を含む）・子・配偶者（婚約者も含む）・兄弟（配偶者の親を含む）などの親族で、申込者と同居（予定も含む）のかた`}</Typography>
                <Typography
                  variant="sp_change_password_note"
                  color="b_333"
                >{`●収入合算できる金額は、収入合算者の収入の50%を限度とします。\n※ ペアローンの相手を収入合算者にはできません。\n※ 婚約者と収入合算する場合は、原則としてお借入日より前に入籍いただく必要があります。`}</Typography>
                <Typography variant="sp_change_password_note" color="b_333" />
                <Typography variant="sp_change_password_note" color="b_333">
                  {`●具体例：夫婦で3000万円のお借入れのお申込みをする場合\n・主債務者：夫\n・連帯保証人（収入合算者）：妻\n・借入金額：3000万円\n・団体信用生命保険：夫のみ加入\n・住宅ローン控除の対象：夫のみ\n・住宅ローン契約は一つ。\n※団体信用生命保険への加入は主債務者のかたのみとなるので（この場合は夫）、連帯保証人のかたが死亡したり保険対象の高度障害などになった場合でも保険金が支払われません。\n※連帯保証人のかたは、住宅ローン控除を受けることができません。`}
                </Typography>
              </Stack>
              <SPButton
                sx={{
                  width: '160px',
                  height: '40px',
                  bgcolor: 'sp_primary_40',
                }}
                endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
                onClick={() => setOpenModal(false)}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="sp_primary_100_main"
                >
                  とじる
                </Typography>
              </SPButton>
            </Stack>
          }
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepFourIncomeTotalizerPage;

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(48), (_, index) => {
    const year = zeroPad(dayjs().year() - 18 - index);
    const startDay = year === '1989' ? 8 : 1;
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, startDay);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, startDay);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);
