import { yup } from 'libs';

export const validationSchema = yup.object().shape(
  {
    year: yup.string().when(['month'], {
      is: (month: string) => !!month,
      then: yup.string(),
    }),
    month: yup.string().when(['year'], {
      is: (year: string) => !!year,
      then: yup.string(),
    }),
  },
  [['year', 'month']]
);
