import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPUnchecked: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_i_11348_26637)">
          <path
            d="M2 4.85714C2 3.27902 3.27902 2 4.85714 2H19.1429C20.7188 2 22 3.27902 22 4.85714V19.1429C22 20.7188 20.7188 22 19.1429 22H4.85714C3.27902 22 2 20.7188 2 19.1429V4.85714ZM2.71429 4.85714V19.1429C2.71429 20.3259 3.67366 21.2857 4.85714 21.2857H19.1429C20.3259 21.2857 21.2857 20.3259 21.2857 19.1429V4.85714C21.2857 3.67366 20.3259 2.71429 19.1429 2.71429H4.85714C3.67366 2.71429 2.71429 3.67366 2.71429 4.85714Z"
            fill="#E4E7FF"
          />
          <path
            d="M2.71429 19.1429V4.85714C2.71429 3.67366 3.67366 2.71429 4.85714 2.71429H19.1429C20.3259 2.71429 21.2857 3.67366 21.2857 4.85714V19.1429C21.2857 20.3259 20.3259 21.2857 19.1429 21.2857H4.85714C3.67366 21.2857 2.71429 20.3259 2.71429 19.1429Z"
            fill="white"
          />
          <path
            d="M4.85714 1.95C3.2514 1.95 1.95 3.2514 1.95 4.85714V19.1429C1.95 20.7463 3.25138 22.05 4.85714 22.05H19.1429C20.7464 22.05 22.05 20.7464 22.05 19.1429V4.85714C22.05 3.25138 20.7463 1.95 19.1429 1.95H4.85714Z"
            stroke="#E4E7FF"
            strokeWidth="0.1"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_11348_26637"
            x="0"
            y="0"
            width="24"
            height="28"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.17099 0 0 0 0 0.209946 0 0 0 0 0.6125 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_11348_26637"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};
