import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  AssignSalePersonRequest,
  AssignSalePersonResponse,
  AssignStoreNameError,
  AssignStoreNameRequest,
  AssignStoreNameResponse,
  SalePersonCurrentPreliminariesItemResponse,
  SalePersonPreliminariesError,
  SalePersonPreliminariesItemRequest,
} from 'services';
import { RootState, ThunkAPI } from 'types';
import { AssignSalePersonError } from 'services';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

export const getCurrentSalePersonPreliminariesItems = createAsyncThunk<
  SalePersonCurrentPreliminariesItemResponse,
  SalePersonPreliminariesItemRequest,
  ThunkAPI<SalePersonPreliminariesError>
>('Preliminaries/GetPreliminaries', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.getCurrentSalePersonPreliminariesItems(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const assignSalePersons = createAsyncThunk<
  AssignSalePersonResponse,
  AssignSalePersonRequest,
  ThunkAPI<AssignSalePersonError>
>('Preliminaries/assignSalePerson', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.assignSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const assignStoreName = createAsyncThunk<
  AssignStoreNameResponse,
  AssignStoreNameRequest,
  ThunkAPI<AssignStoreNameError>
>('Preliminaries/assignStoreName', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.assignStoreName(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getExportExcelSalePerson = createAsyncThunk<
  BlobPart,
  SalePersonPreliminariesItemRequest,
  ThunkAPI<SalePersonPreliminariesError>
>(
  'ExportExcel/ExportExcelSalePerson',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      if (!state.auth.accessToken) {
        return rejectWithValue(new Error('Not logged in'));
      }
      const response = await applicationsService.getExportExcelSalePerson(
        data,
        state.auth.accessToken
      );
      await FileSaver.saveAs(
        await response.blob(),
        `${dayjs().format('YYYYMMDDhhmmss')}_管理画面をエクスポート.xlsx`
      );
      return response.blob();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
