import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import {
  Stack,
  TableBody as MuiTableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
  IconButton,
} from '@mui/material';
import { borrowingsAttributeDefaultValue, Icons } from 'components';
import { useAppDispatch, usePrevious } from 'hooks';
import {
  convertManToYen,
  convertToFullWidth,
  convertToHalfWidth,
  convertYenToMan,
  numberWithCommas,
} from 'utils';
import {
  AdminPreliminaryItem,
  borrowingStatusAdmin,
  BorrowingStatusAdminType,
  CoOwnerType,
  DispatchType,
  finalTaxReturn,
  FinalTaxReturnType,
  hasLandAdvancePlan,
  HasLandAdvancePlanType,
  includeInExamination,
  IncludeInExaminationType,
  isHomeLoanPlus,
  IsHomeLoanPlus,
  loanTargetAdmin,
  LoanTargetAdminType,
  personOccupancy,
  PersonOccupancyType,
  PREFECTURES,
  RelationshipType,
  transferOffice,
  Flat35ApplicablePlanType,
  MaintenanceType,
  Flat35ApplicationType,
  joinGuarantor,
  BorrowingStatusType,
  RelationshipToApplicantType,
  PlannedCohabitantType,
  PurposeType,
  LoanType,
  NationalityType,
} from 'constant';
import {
  AdminPreliminaries,
  getReferralAgencyOptionRequest,
  PostalCode,
  SalePersonPreliminaries,
} from 'services';
import { useSelector } from 'react-redux';
import { QuantityDependent } from 'types';
import { styled } from '@mui/material/styles';
import { adminDynamicOptionsSelectors } from 'pages/SPStartScreen/selectors';
import { getZipCloudAddress } from 'pages/StepRequiredInformation/thunk';
import { getReferralAgencyOptions } from 'pages/AdminEditPreliminary/thunk';
import {
  AutocompleteSalePerson,
  PulldownBorrowingStatusSelect,
  PulldownBtn,
  PulldownDate,
  PulldownLoanTarget,
  PulldownNumber,
  PulldownPermanent,
  PulldownSBI,
  PulldownSelect,
  PulldownSelectDistrict,
  PulldownSelectMultiple,
} from 'pages/AdminEditPreliminary/components';
import { toast } from 'react-toastify';
import {
  adminLinkPreliminariesSelector,
  adminPreliminariesSelector,
  salePersonAdminOptionsSelector,
  salePersonAdminOptionsLinkSelector,
} from 'pages/AdminEditPreliminary/selectors';
import {
  salePersonAdminOptionsForSalePersonSelector,
  salePersonAdminOptionsForSalePersonLinkSelector,
  salePersonLinkPreliminariesSelector,
  salePersonPreliminariesSelector,
} from 'pages/SalePersonEditPreliminary/selectors';
import { useLocation } from 'react-router-dom';
import { getReferralAgencyOptionsSalePerson } from 'pages/SalePersonEditPreliminary/thunk';
import { PulldownLivedYear } from 'pages/AdminEditPreliminary/components/PulldownLivedYear';
import { modalConfirmRef } from '../ModalConfirmRef';
import { AutosizeInput } from './AutosizeInput';
import { convertData } from './utils/convertData';
import { InputDate } from './InputDate';

export type TableBodyProps = {
  rows: AdminPreliminaryItem[];
  parentTabIndex?: string;
  parentKey: string;
  quantityDependent?: QuantityDependent;
  onChange: (value: unknown, path: string) => void;
  onEditField: (fieldName: string, nameEn: string) => void;
  editable?: boolean;
  noRequired?: boolean;
  isLinked: boolean;
  onRemoveBody?: () => void;
  yearLength?: number;
  isTotalIncome?: boolean;
  indexJoinGuarantor?: number;
};

export const TableBody: FC<TableBodyProps> = ({
  rows,
  parentTabIndex,
  parentKey,
  quantityDependent,
  onChange,
  onEditField,
  editable,
  noRequired,
  isLinked,
  onRemoveBody,
  yearLength,
  isTotalIncome,
  indexJoinGuarantor,
}) => {
  const formik = useFormikContext<AdminPreliminaries & boolean>();
  const formikSalePerson = useFormikContext<
    SalePersonPreliminaries & boolean
  >();
  const dynamicOptions = useSelector(adminDynamicOptionsSelectors);
  const salePersonInformation = useSelector(adminPreliminariesSelector);
  const salePersonLinkInformation = useSelector(adminLinkPreliminariesSelector);
  const salePersonInformationForalePerson = useSelector(
    salePersonPreliminariesSelector
  );
  const salePersonLinkInformationForalePerson = useSelector(
    salePersonLinkPreliminariesSelector
  );
  const dispatch = useAppDispatch();
  const [saleAgentId, setSaleAgentId] = useState<string>();
  const [storeId, setStoreId] = useState<string>();
  const listSalePerson = useSelector(
    isLinked
      ? salePersonAdminOptionsLinkSelector
      : salePersonAdminOptionsSelector
  );
  const listSalePersonForSalePerson = useSelector(
    isLinked
      ? salePersonAdminOptionsForSalePersonLinkSelector
      : salePersonAdminOptionsForSalePersonSelector
  );

  const indexApplicantPeople = useMemo(
    () => (isTotalIncome ? 1 : 0),
    [isTotalIncome]
  );

  const location = useLocation();

  // setup sale person
  useEffect(() => {
    const activeAgencyId =
      formik.values.p_application_header.sale_person_information
        .active_agency_id;

    setSaleAgentId(
      listSalePerson.agenciesAdminOptions.find(
        (item) => item.id === activeAgencyId
      )?.value ??
        (activeAgencyId || '')
    );
  }, [
    formik.values.p_application_header.sale_person_information.active_agency_id,
    listSalePerson.agenciesAdminOptions,
  ]);

  useEffect(() => {
    const activeStoreId =
      formik.values.p_application_header.sale_person_information
        .active_store_id;
    setStoreId(
      listSalePerson.storeAdminOptions.find((item) => item.id === activeStoreId)
        ?.value ??
        (activeStoreId || '')
    );
  }, [
    formik.values.p_application_header.sale_person_information.active_store_id,
    listSalePerson.storeAdminOptions,
  ]);

  const setHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      prefecture_kanji: postal.address1,
      city_kanji: postal.address2,
      district_kanji: postal.address3,
      prefecture_kana: convertToFullWidth(postal.kana1),
      city_kana: convertToFullWidth(postal.kana2),
      district_kana: convertToFullWidth(postal.kana3),
      other_address_kanji: '',
      other_address_kana: '',
    });
    await formik.setTouched({
      ...formik.touched,
      [`${name.replace('p_application_header.', '')}.prefecture_kanji`]: true,
      [`${name.replace('p_application_header.', '')}.district_kanji`]: true,
      [`${name.replace('p_application_header.', '')}.city_kanji`]: true,
      [`${name.replace('p_application_header.', '')}.prefecture_kana`]: true,
      [`${name.replace('p_application_header.', '')}.city_kana`]: true,
      [`${name.replace('p_application_header.', '')}.district_kana`]: true,
      [`${name.replace('p_application_header.', '')}.other_address_kanji`]:
        true,
      [`${name.replace('p_application_header.', '')}.other_address_kana`]: true,
    });
  };
  const setOfficeHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      office_prefecture_kanji: postal.address1,
      office_city_kanji: postal.address2,
      office_district_kanji: postal.address3,
      office_prefecture_kana: convertToFullWidth(postal.kana1),
      office_city_kana: convertToFullWidth(postal.kana2),
      office_district_kana: convertToFullWidth(postal.kana3),
      other_office_address_kanji: '',
      other_office_address_kana: '',
    });
    await formik.setTouched({
      ...formik.touched,
      [`${name.replace('p_application_header.', '')}.office_prefecture_kanji`]:
        true,
      [`${name.replace('p_application_header.', '')}.office_city_kanji`]: true,
      [`${name.replace('p_application_header.', '')}.office_district_kanji`]:
        true,
      [`${name.replace('p_application_header.', '')}.office_prefecture_kana`]:
        true,
      [`${name.replace('p_application_header.', '')}.office_city_kana`]: true,
      [`${name.replace('p_application_header.', '')}.office_district_kana`]:
        true,
      [`${name.replace(
        'p_application_header.',
        ''
      )}.other_office_address_kanji`]: true,
      [`${name.replace(
        'p_application_header.',
        ''
      )}.other_office_address_kana`]: true,
    });
  };

  const setTransferOfficeHomeAddress = async (
    postal: PostalCode,
    name: string
  ) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      transfer_office_prefecture_kanji: postal.address1,
      transfer_office_city_kanji: postal.address2,
      transfer_office_district_kanji: postal.address3,
    });
    await formik.setTouched({
      ...formik.touched,
      [`${name.replace(
        'p_application_header.',
        ''
      )}.transfer_office_prefecture_kanji`]: true,
      [`${name.replace(
        'p_application_header.',
        ''
      )}.transfer_office_city_kanji`]: true,
      [`${name.replace(
        'p_application_header.',
        ''
      )}.transfer_office_district_kanji`]: true,
    });
  };

  const setJoinGuarantorHomeAddress = async (
    postal: PostalCode,
    name: string
  ) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      prefecture_kanji: postal.address1,
      city_kanji: postal.address2,
      district_kanji: postal.address3,
      prefecture_kana: convertToFullWidth(postal.kana1),
      city_kana: convertToFullWidth(postal.kana2),
      district_kana: convertToFullWidth(postal.kana3),
      other_address_kanji: '',
      other_address_kana: '',
    });
    await formik.setTouched({
      ...formik.touched,
      [`${name.replace('p_application_header.', '')}.prefecture_kanji`]: true,
      [`${name.replace('p_application_header.', '')}.city_kanji`]: true,
      [`${name.replace('p_application_header.', '')}.district_kanji`]: true,
      [`${name.replace('p_application_header.', '')}.prefecture_kana`]: true,
      [`${name.replace('p_application_header.', '')}.city_kana`]: true,
      [`${name.replace('p_application_header.', '')}.district_kana`]: true,
      [`${name.replace('p_application_header.', '')}.other_address_kanji`]:
        true,
      [`${name.replace('p_application_header.', '')}.other_address_kana`]: true,
    });
  };

  const handleZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        toast.error('住所が取得できませんでした。再度入力してください。');
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].prefecture_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].city_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].district_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].prefecture_kana`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].city_kana`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].district_kana`,
          ''
        );
        return;
      }
      setHomeAddress(
        result.payload.results[0],
        `p_application_header.p_applicant_people_attributes[${indexApplicantPeople}]`
      );
    }
  };

  const handleGetReferralAgencyOptions = useCallback(
    async (data: getReferralAgencyOptionRequest) => {
      const result = await dispatch(getReferralAgencyOptions(data));
      if (getReferralAgencyOptions.rejected.match(result)) {
        return;
      }
      if (
        result.payload.data.list_sale_persons.find(
          (salePerson) =>
            formik.values.p_application_header.s_sale_person_id ===
            salePerson.id
        )
      ) {
        return;
      }
      formik.setFieldValue('p_application_header.s_sale_person_id', '');
    },
    [dispatch, formik]
  );

  const handleGetReferralAgencyOptionsSalePerson = useCallback(
    async (data: getReferralAgencyOptionRequest) => {
      const result = await dispatch(getReferralAgencyOptionsSalePerson(data));
      if (getReferralAgencyOptionsSalePerson.rejected.match(result)) {
        return;
      }
      if (
        result.payload.data.list_sale_persons.find(
          (salePerson) =>
            formik.values.p_application_header.s_sale_person_id ===
            salePerson.id
        )
      ) {
        return;
      }
      formik.setFieldValue('p_application_header.s_sale_person_id', '');
    },
    [dispatch, formik]
  );

  const handleOfficeZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        toast.error('住所が取得できませんでした。再度入力してください。');
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].office_prefecture_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].office_city_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].office_district_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].office_prefecture_kana`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].office_city_kana`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].office_district_kana`,
          ''
        );

        return;
      }
      setOfficeHomeAddress(
        result.payload.results[0],
        `p_application_header.p_applicant_people_attributes[${indexApplicantPeople}]`
      );
    }
  };

  const handleTransferOfficeZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        toast.error('住所が取得できませんでした。再度入力してください。');
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].transfer_office_prefecture_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].transfer_office_city_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_applicant_people_attributes[${indexApplicantPeople}].transfer_office_district_kanji`,
          ''
        );

        return;
      }
      setTransferOfficeHomeAddress(
        result.payload.results[0],
        `p_application_header.p_applicant_people_attributes[${indexApplicantPeople}]`
      );
    }
  };

  const handleJoinGuarantorZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        toast.error('住所が取得できませんでした。再度入力してください。');
        formik.setFieldValue(
          `p_join_guarantors_attributes[${indexJoinGuarantor}].prefecture_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_join_guarantors_attributes[${indexJoinGuarantor}].city_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_join_guarantors_attributes[${indexJoinGuarantor}].district_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_join_guarantors_attributes[${indexJoinGuarantor}].prefecture_kana`,
          ''
        );
        formik.setFieldValue(
          `p_join_guarantors_attributes[${indexJoinGuarantor}].city_kana`,
          ''
        );
        formik.setFieldValue(
          `p_join_guarantors_attributes[${indexJoinGuarantor}].district_kana`,
          ''
        );
        return;
      }
      setJoinGuarantorHomeAddress(
        result.payload.results[0],
        `p_application_header.p_join_guarantors_attributes[${indexJoinGuarantor}]`
      );
    }
  };

  const handleResidentsAttributeZipCodeChange = async (
    value: string,
    index: number
  ) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        toast.error('住所が取得できませんでした。再度入力してください。');
        formik.setFieldValue(
          `p_residents_attributes[${index}].prefecture_kanji`,
          ''
        );
        formik.setFieldValue(`p_residents_attributes[${index}].city_kanji`, '');
        formik.setFieldValue(
          `p_residents_attributes[${index}].district_kanji`,
          ''
        );
        formik.setFieldValue(
          `p_residents_attributes[${index}].prefecture_kana`,
          ''
        );
        formik.setFieldValue(`p_residents_attributes[${index}].city_kana`, '');
        formik.setFieldValue(
          `p_residents_attributes[${index}].district_kana`,
          ''
        );
        return;
      }
      setHomeAddress(
        result.payload.results[0],
        `p_application_header.p_residents_attributes[${index}]`
      );
    }
  };

  const postal_code =
    formik.values.p_application_header.p_applicant_people_attributes?.[
      indexApplicantPeople
    ]?.postal_code ?? '';

  const office_postal_code =
    formik.values.p_application_header.p_applicant_people_attributes?.[
      indexApplicantPeople
    ]?.office_postal_code ?? '';

  const postal_code_join_guarantor =
    indexJoinGuarantor !== undefined
      ? formik.values.p_application_header.p_join_guarantors_attributes?.[
          indexJoinGuarantor
        ]?.postal_code
      : '';

  const transfer_office_postal_code =
    formik.values.p_application_header.p_applicant_people_attributes?.[
      indexApplicantPeople
    ]?.transfer_office_postal_code ?? '';

  // const property_postal_code =
  //   formik.values.p_application_header.property_postal_code ?? '';

  const isManager = useMemo(() => {
    return location.pathname.startsWith('/manager/', 0);
  }, [location.pathname]);

  useEffect(() => {
    if (
      postal_code.length === 8 &&
      formik.touched.hasOwnProperty(
        `p_applicant_people_attributes[${indexApplicantPeople}].postal_code`
      )
    ) {
      handleZipCodeChange(postal_code);
    }
    if (
      office_postal_code.length === 8 &&
      formik.touched.hasOwnProperty(
        `p_applicant_people_attributes[${indexApplicantPeople}].office_postal_code`
      )
    ) {
      handleOfficeZipCodeChange(office_postal_code);
    }
    //TODO: SonDc fix this
    if (
      indexJoinGuarantor !== undefined &&
      postal_code_join_guarantor?.length === 8 &&
      formik.touched.hasOwnProperty(
        `p_join_guarantors_attributes[${indexJoinGuarantor}].postal_code`
      )
    ) {
      handleJoinGuarantorZipCodeChange(postal_code_join_guarantor);
    }
    if (
      transfer_office_postal_code.length === 8 &&
      formik.touched.hasOwnProperty(
        `p_applicant_people_attributes[${indexApplicantPeople}].transfer_office_postal_code`
      )
    ) {
      handleTransferOfficeZipCodeChange(transfer_office_postal_code);
    }
    // if (
    //   property_postal_code.length === 8 &&
    //   formik.touched.hasOwnProperty('property_postal_code')
    // ) {
    //   handleZipCodeChangeResidence(property_postal_code);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    postal_code,
    office_postal_code,
    postal_code_join_guarantor,
    transfer_office_postal_code,
    // property_postal_code,
  ]);

  const resident_postal_codes =
    formik.values.p_application_header.p_residents_attributes.map(
      (resident) => resident.postal_code
    );
  const prevResidentPostalCodes = usePrevious(resident_postal_codes);
  useEffect(() => {
    resident_postal_codes
      .filter((o) => !!o)
      .forEach((postal_code, index) => {
        if (!!prevResidentPostalCodes) {
          if (
            postal_code.length === 8 &&
            formik.touched.hasOwnProperty(
              `p_residents_attributes[${index}].postal_code`
            ) &&
            prevResidentPostalCodes[index] !== postal_code
          ) {
            handleResidentsAttributeZipCodeChange(postal_code, index);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.p_residents_attributes]);

  const flat_35_applicable_plan =
    formik.values.p_application_header.flat_35_applicable_plan;
  const maintenance_type = formik.values.p_application_header.maintenance_type;

  useEffect(() => {
    if (
      flat_35_applicable_plan === Flat35ApplicablePlanType.PLAN_A &&
      maintenance_type === MaintenanceType.LONGTERM_SUPERIOR_HOUSING
    ) {
      formik.setFieldValue(
        'p_application_header.flat_35_application',
        Flat35ApplicationType.DURABILITY
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flat_35_applicable_plan, maintenance_type]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isManYen = useCallback((isPrice: boolean | undefined, suffix: any) => {
    return isPrice && suffix === '万円';
  }, []);

  const getIsLinked = useCallback(
    (isLinked: boolean, isManager: boolean) => {
      if (isLinked) {
        if (!isManager) {
          return salePersonLinkInformationForalePerson?.p_application_header
            ?.sale_person_information;
        }
        return salePersonLinkInformation?.p_application_header
          ?.sale_person_information;
      }
      if (!isManager) {
        return salePersonInformationForalePerson?.p_application_header
          ?.sale_person_information;
      }
      return salePersonInformation?.p_application_header
        ?.sale_person_information;
    },
    [
      salePersonInformation?.p_application_header?.sale_person_information,
      salePersonInformationForalePerson?.p_application_header
        ?.sale_person_information,
      salePersonLinkInformation?.p_application_header?.sale_person_information,
      salePersonLinkInformationForalePerson?.p_application_header
        ?.sale_person_information,
    ]
  );

  const getValues = useCallback(
    (row?: Partial<AdminPreliminaryItem>) => {
      if (!row)
        return {
          currentValue: '',
          lastedFieldName: '',
          formattedValue: '',
          suffix: '',
          prefix: '',
          value: '',
          isPrice: false,
        };
      const currentValue = get(
        formik.values,
        `${parentKey}.${row.name_en}`,
        ''
      );
      const lastedFieldName =
        row.name_en?.split('.').slice(-1).toString() ?? '';
      const data = convertData(
        lastedFieldName,
        currentValue,
        dynamicOptions,
        row.options,
        quantityDependent
        // !isManager,
        // salePersonInformationForalePerson?.p_application_header
        //   ?.sale_person_information,
        // // @ts-ignore
        // getIsLinked(isLinked, isManager)
      );
      return { ...data, lastedFieldName, currentValue };
    },
    [
      dynamicOptions,
      formik.values,
      getIsLinked,
      isLinked,
      isManager,
      parentKey,
      quantityDependent,
    ]
  );

  const isHasLog = useCallback(
    (fieldName: string) => {
      if (fieldName.includes('p_borrowing_details_attributes')) {
        const index = fieldName.split('.')[0].slice(-2, -1);
        return (
          formik.values.p_application_header.p_borrowing_details_attributes[
            Number(index)
          ] &&
          formik.values.p_application_header.p_borrowing_details_attributes?.[
            Number(index)
          ]?.has_log_fields?.find((field) => field === fieldName.split('.')[1])
        );
      }
      if (fieldName.includes('p_applicant_people_attributes')) {
        const index = fieldName.split('.')[0].slice(-2, -1);
        return (
          formik.values.p_application_header.p_applicant_people_attributes[
            Number(index)
          ] &&
          formik.values.p_application_header.p_applicant_people_attributes?.[
            Number(index)
          ]?.has_log_fields?.find((field) => field === fieldName.split('.')[1])
        );
      }
      if (fieldName.includes('p_join_guarantors_attributes')) {
        const index = fieldName.split('.')[0].slice(-2, -1);
        return (
          formik.values.p_application_header.p_join_guarantors_attributes[
            Number(index)
          ] &&
          formik.values.p_application_header.p_join_guarantors_attributes?.[
            Number(index)
          ]?.has_log_fields?.find((field) => field === fieldName.split('.')[1])
        );
      }
      if (fieldName.includes('p_residents_attributes')) {
        const index = fieldName.split('.')[0].slice(-2, -1);
        return (
          formik.values.p_application_header.p_residents_attributes[
            Number(index)
          ] &&
          formik.values.p_application_header.p_residents_attributes?.[
            Number(index)
          ]?.has_log_fields?.find((field) => field === fieldName.split('.')[1])
        );
      }
      if (fieldName.includes('p_borrowings_attributes')) {
        const index = fieldName.split('.')[0].slice(-2, -1);
        return (
          formik.values.p_application_header.p_borrowings_attributes[
            Number(index)
          ] &&
          formik.values.p_application_header.p_borrowings_attributes?.[
            Number(index)
          ]?.has_log_fields?.find((field) => field === fieldName.split('.')[1])
        );
      }
      if (fieldName.includes('sale_person_information')) {
        return (
          (fieldName.split('.')[1] === 'active_agency_id' &&
            formik.values.p_application_header.sale_person_information.has_log_fields?.includes(
              'sale_agent_id'
            )) ||
          (fieldName.split('.')[1] === 'active_store_id' &&
            formik.values.p_application_header.sale_person_information.has_log_fields?.includes(
              'store_id'
            )) ||
          (fieldName.split('.')[1] === 'active_exhibition_id' &&
            formik.values.p_application_header.sale_person_information.has_log_fields?.includes(
              'exhibition_id'
            ))
        );
      }

      return (
        formik.values.p_application_header.has_log_fields?.indexOf(fieldName) >
        -1
      );
    },
    [
      formik.values.p_application_header.has_log_fields,
      formik.values.p_application_header.p_applicant_people_attributes,
      formik.values.p_application_header.p_borrowing_details_attributes,
      formik.values.p_application_header.p_borrowings_attributes,
      formik.values.p_application_header.p_join_guarantors_attributes,
      formik.values.p_application_header.p_residents_attributes,
      formik.values.p_application_header.sale_person_information.has_log_fields,
    ]
  );

  const getFieldLogSalePerson = useCallback((fieldName: string) => {
    if (fieldName === 'sale_person_phone_number')
      return 'sale_person_phone_number';
    if (fieldName === 'sale_person_name_input') return 'sale_person_name_input';
    if (!fieldName.includes('sale_person_information')) return;
    if (fieldName.split('.')[1] === 'active_agency_id') return 'sale_agent_id';
    if (fieldName.split('.')[1] === 'active_store_id') return 'store_id';
    if (fieldName.split('.')[1] === 'active_exhibition_id')
      return 'exhibition_id';
  }, []);

  const loadErrorMessage = useCallback(
    (row: AdminPreliminaryItem) => {
      if (
        row.dynamicType === 'selectDistrict' &&
        get(formik.touched, `${row?.name_en}`)
      ) {
        return '';
      }
      if (row.dynamicType === 'input' || row.dynamicType === 'inputNumber') {
        return !get(formik.touched, `${row.name_en}`)
          ? get(formik.errors, `${parentKey}.${row.name_en}`)
          : '';
      } else if (row.subField === undefined) {
        return get(formik.errors, `${parentKey}.${row.name_en}`);
      }
    },
    [formik, parentKey]
  );

  const loadErrorSubFieldMessage = useCallback(
    (row: AdminPreliminaryItem) => {
      if (row.subField?.dynamicType === 'input') {
        return !get(formik.touched, `${row.subField?.name_en}`)
          ? get(formik.errors, `${parentKey}.${row.subField?.name_en}`)
          : '';
      }
      if (
        row.subField?.name_en ===
        'p_applicant_people_attributes[1].relationship_to_applicant'
      ) {
        return get(
          formik.errors,
          'p_application_header.p_applicant_people_attributes[1].other_relationship_to_applicant'
        );
      }

      if (
        !get(formik.touched, `${row.subFieldName}`) &&
        get(formik.errors, `${parentKey}.${row.subFieldName}`)
      ) {
        return get(formik.errors, `${parentKey}.${row.subFieldName}`);
      }

      if (row.subField?.dynamicType === 'select') {
        if (
          !get(formik.touched, `${row.subField?.name_en}`) &&
          get(formik.errors, `${parentKey}.${row.subField?.name_en}`)
        ) {
          return get(
            formik.errors,
            `${parentKey}.${row.subField?.subFieldName}`
          );
        }

        if (
          !get(formik.touched, `${row.subField?.subFieldName}`) &&
          get(formik.errors, `${parentKey}.${row.subField?.subFieldName}`)
        ) {
          return get(
            formik.errors,
            `${parentKey}.${row.subField?.subFieldName}`
          );
        }

        return '';
      }
      return get(formik.errors, `${parentKey}.${row.subField?.name_en}`);
    },
    [formik, parentKey]
  );

  return (
    <MuiTableBody sx={{ position: 'relative' }}>
      {onRemoveBody && editable && (
        <tr>
          <td>
            <IconButton
              onClick={onRemoveBody}
              sx={{
                position: 'absolute',
                top: 4,
                right: 4,
                zIndex: 2,
                width: 20,
                height: 20,
                bgcolor: 'sp_secondary_01',
                '&:hover': {
                  bgcolor: 'sp_secondary_01',
                  opacity: 0.8,
                },
              }}
            >
              <Icons.Close sx={{ fontSize: 10, color: 'white' }} />
            </IconButton>
          </td>
        </tr>
      )}
      {rows.slice(2).map((row) => {
        const {
          currentValue,
          lastedFieldName,
          formattedValue,
          suffix,
          prefix,
          value,
          isPrice,
          widthComma,
        } = getValues(row);
        const {
          currentValue: subCurrentValue,
          formattedValue: subFormattedValue,
          lastedFieldName: subLastedFieldName,
        } = getValues(row.subField);

        const checkOptions = (field: string) => {
          switch (field) {
            case 'person_occupancy':
              return personOccupancyOptions;
            case 'borrowing_status':
              return borrowingStatusOptions;
            case 'include_in_examination':
              return includeInExaminationOptions;
            case 'has_land_advance_plan':
              return hasLandAdvancePlanOptions;
            case 'has_join_guarantor':
              return hasJoinGuarantorOptions;
            case 'tax_return':
              return taxReturnOptions;
            case 'is_home_loan_plus':
              return isHomeLoanPlusOptions;
            case 'loan_target':
              return loanTargetOptions;
            case 'transfer_office':
              return transferOfficeOptions;
            default:
              return [];
          }
        };

        const renderResidenceOtherRelationship = (fieldName: string) => {
          if (fieldName.includes('p_residents_attributes')) {
            const index = fieldName.split('.')[0].slice(-2, -1);
            if (
              !(
                lastedFieldName === 'relationship' &&
                currentValue?.includes(RelationshipType.OTHERS)
              )
            ) {
              return null;
            }
            return (
              <AutosizeInput
                id={`${parentKey}.p_residents_attributes[${index}].other_relationship`}
                data-path={`${parentKey}.p_residents_attributes[${index}].other_relationship`}
                disabled={!editable}
                value={get(
                  formik.values,
                  `${parentKey}.p_residents_attributes[${index}].other_relationship`,
                  ''
                )}
                sx={{
                  border: '1px solid #C8CDCF',
                  borderRadius: 1,
                  minWidth: 100,
                }}
                onFocus={() => {
                  formik.setTouched({
                    ...formik.touched,
                    [`p_residents_attributes[${index}].other_relationship`]:
                      true,
                  });
                }}
                onBlur={() => {
                  formik.setTouched({
                    ...formik.touched,
                    [`p_residents_attributes[${index}].other_relationship`]:
                      false,
                  });
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const inputValue = e.target.value ?? '';
                  onChange(
                    convertToFullWidth(inputValue),
                    `p_residents_attributes[${index}].other_relationship`
                  );
                }}
              />
            );
          }
        };
        const renderPlannedCohabitant = () => {
          let dynamicValues = get(
            dynamicOptions,
            `${row.dynamicOptionField}.value`,
            []
          );
          if (row.dynamicOptionField === 'planned_cohabitant') {
            if (currentValue.includes(PlannedCohabitantType.SPOUSE)) {
              // @ts-ignore
              return dynamicValues.filter((item) => item.option_code !== 6);
            }
            if (currentValue.includes(PlannedCohabitantType.FIANCE)) {
              // @ts-ignore
              return dynamicValues.filter((item) => item.option_code !== 1);
            }
          }
          return dynamicValues;
        };

        return (
          <StyledTableRow key={row.name_en}>
            <StyledTableCell sx={{ width: 32, bgcolor: 'bg_off' }}>
              <Typography
                variant="h3"
                color={row.condition ? '#333333' : ''}
                sx={{ width: 20 }}
              >
                {row.condition === 'require_sp' && '○'}
                {row.condition === 'require' && '○'}
                {row.condition === 'require_condition' && '△'}
              </Typography>
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: 328, color: 'b_333' }}>
              <label htmlFor={row.name_en}>{row.name_ja}</label>
            </StyledTableCell>
            <StyledTableCell
              sx={{
                bgcolor:
                  !noRequired && row.required ? 'sp_secondary_01' : 'bg_off',
                width: 42,
              }}
            >
              {!isHasLog(row.name_en) || parentTabIndex === '2' ? (
                <Icons.EditField fill={'none'} />
              ) : (
                <Icons.EditField
                  fill={row.required ? '#FFFFFF' : '#333333'}
                  onClick={() => {
                    if (
                      (rows.length === 10 || rows.length === 9) &&
                      row.name_en !== 's_sale_person_id'
                    ) {
                      onEditField(
                        // @ts-ignore
                        getFieldLogSalePerson(row.name_en),
                        row.name_en
                      );
                    } else {
                      onEditField(lastedFieldName, row.name_en);
                    }
                  }}
                  sx={{ cursor: 'pointer' }}
                />
              )}
            </StyledTableCell>
            <StyledTableCell
              align="left"
              sx={{ position: 'relative', width: '100%', p: '10px' }}
            >
              <Stack
                component="label"
                direction="row"
                alignItems="center"
                htmlFor={row.name_en}
              >
                <Stack flex={1} direction="row" alignItems="center" spacing={3}>
                  {row.dynamicType === 'selectPermanent' &&
                    row.dynamicOptionField && (
                      <>
                        {editable ? (
                          <PulldownPermanent
                            requireSelect={!!row.requireSelect}
                            value={currentValue}
                            options={checkOptions(row.dynamicOptionField)}
                            onChange={(newValue) => {
                              onChange(newValue, row.name_en);
                              formik.setFieldValue('isEdited', true);
                              setTimeout(() => {
                                formik.setTouched({
                                  ...formik.touched,
                                  [row.name_en]: true,
                                });
                              }, 0);
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}
                        <Typography variant="text_step_admin" id={row.name_en}>
                          {formattedValue}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'inputDate' && (
                    <>
                      {editable ? (
                        <>
                          <PulldownBtn isEmpty />
                          <InputDate
                            value={currentValue}
                            onFocus={() => {
                              setTimeout(() => {
                                formik.setTouched({
                                  ...formik.touched,
                                  [row.name_en]: true,
                                });
                              }, 0);
                            }}
                            onChange={(inputValue) => {
                              formik.setFieldValue('isEdited', true);
                              onChange(inputValue, row.name_en);
                            }}
                            onBlur={() => {
                              formik.setTouched({
                                ...formik.touched,
                                [row.name_en]: false,
                              });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <PulldownBtn isEmpty />
                          <Typography
                            variant="text_step_admin"
                            id={row.name_en}
                          >
                            {formattedValue}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                  {row.dynamicType === 'selectLoanTarget' &&
                    row.name_en === 'loan_target_zero' && (
                      <>
                        {editable ? (
                          <PulldownLoanTarget
                            value={currentValue}
                            //@ts-ignore
                            options={get(
                              dynamicOptions,
                              `${row.dynamicOptionField}.value`,
                              []
                            )}
                            onChange={(newValue) => {
                              formik.setFieldValue('isEdited', true);
                              if (newValue === PurposeType.ZERO) {
                                formik.setFieldValue(
                                  'p_application_header.loan_target',
                                  formik.values.p_application_header
                                    .loan_target_default
                                );
                              } else {
                                formik.setFieldValue('isEdited', true);
                                formik.setFieldValue(
                                  'p_application_header.loan_target',
                                  newValue
                                );
                              }
                              onChange(newValue, row.name_en);
                              setTimeout(() => {
                                formik.setTouched({
                                  ...formik.touched,
                                  [row.name_en]: true,
                                });
                              }, 0);
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}
                        <Typography variant="text_step_admin" id={row.name_en}>
                          {formattedValue}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectSBI' && row.dynamicOptionField && (
                    <>
                      {editable ? (
                        <PulldownSBI
                          value={currentValue}
                          options={checkOptions(row.dynamicOptionField)}
                          onChange={(newValue) => {
                            onChange(newValue, row.name_en);
                            formik.setFieldValue('isEdited', true);
                            setTimeout(() => {
                              formik.setTouched({
                                ...formik.touched,
                                [row.name_en]: true,
                              });
                            }, 0);
                          }}
                        />
                      ) : (
                        <PulldownBtn isEmpty />
                      )}

                      <Typography variant="text_step_admin" id={row.name_en}>
                        {formattedValue}
                      </Typography>
                    </>
                  )}
                  {row.dynamicType === 'selectSalePerson' &&
                    row.dynamicOptionField === 'sale_agent_name_kanji' && (
                      <>
                        {editable ? (
                          <AutocompleteSalePerson
                            value={currentValue}
                            name={lastedFieldName}
                            options={
                              isManager
                                ? listSalePerson.agenciesAdminOptions.filter(
                                    (item) => item.value !== ''
                                  )
                                : listSalePersonForSalePerson.agenciesAdminOptions.filter(
                                    (item) => item.value !== ''
                                  )
                            }
                            onChange={(newValue) => {
                              if (currentValue !== newValue) {
                                formik.setFieldValue('isEdited', true);
                                if (isManager) {
                                  handleGetReferralAgencyOptions({
                                    sale_agent_id: !!newValue
                                      ? newValue
                                      : undefined,
                                  });
                                } else {
                                  handleGetReferralAgencyOptionsSalePerson({
                                    sale_agent_id: !!newValue
                                      ? newValue
                                      : undefined,
                                  });
                                }
                                setSaleAgentId(newValue);
                                formik.setFieldValue(
                                  'p_application_header.sale_agent_name_kanji',
                                  newValue
                                );
                                formik.setFieldValue(
                                  'p_application_header.store_name_kanji',
                                  ''
                                );
                                formik.setFieldValue(
                                  'p_application_header.exhibition_hall',
                                  ''
                                );
                                formik.setFieldValue(
                                  'p_application_header.sale_person_information.active_store_id',
                                  ''
                                );
                                formik.setFieldValue(
                                  'p_application_header.sale_person_information.active_exhibition_id',
                                  ''
                                );
                                onChange(newValue, row.name_en);
                                setTimeout(() => {
                                  formik.setTouched({
                                    ...formik.touched,
                                    [row.name_en]: true,
                                  });
                                }, 0);
                              }
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}

                        <Typography variant="text_step_admin" id={row.name_en}>
                          {getIsLinked(isLinked, isManager).list_agencies.find(
                            (item) => item.id === formattedValue
                          )?.sale_agent_name_kanji ??
                            (isManager
                              ? listSalePerson.agenciesAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label
                              : listSalePersonForSalePerson.agenciesAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label)}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectSalePerson' &&
                    row.dynamicOptionField === 'store_name_kanji' && (
                      <>
                        {editable ? (
                          <AutocompleteSalePerson
                            value={currentValue}
                            name={lastedFieldName}
                            options={
                              isManager
                                ? listSalePerson.storeAdminOptions
                                : listSalePersonForSalePerson.storeAdminOptions
                            }
                            onChange={(newValue) => {
                              if (currentValue !== newValue) {
                                formik.setFieldValue('isEdited', true);
                                if (isManager) {
                                  handleGetReferralAgencyOptions({
                                    sale_agent_id: saleAgentId,
                                    store_id: !!newValue ? newValue : undefined,
                                  });
                                } else {
                                  handleGetReferralAgencyOptionsSalePerson({
                                    sale_agent_id: saleAgentId,
                                    store_id: !!newValue ? newValue : undefined,
                                  });
                                }
                                setStoreId(newValue);
                                formik.setFieldValue(
                                  'p_application_header.store_name_kanji',
                                  newValue
                                );
                                formik.setFieldValue(
                                  'p_application_header.exhibition_hall',
                                  ''
                                );
                                formik.setFieldValue(
                                  'p_application_header.sale_person_information.active_exhibition_id',
                                  ''
                                );
                                onChange(newValue, row.name_en);
                                setTimeout(() => {
                                  formik.setTouched({
                                    ...formik.touched,
                                    [row.name_en]: true,
                                  });
                                }, 0);
                              }
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}

                        <Typography variant="text_step_admin" id={row.name_en}>
                          {getIsLinked(isLinked, isManager).list_stores.find(
                            (item) => item.id === formattedValue
                          )?.store_name_kanji ??
                            (isManager
                              ? listSalePerson.storeAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label
                              : listSalePersonForSalePerson.storeAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label)}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectSalePerson' &&
                    row.dynamicOptionField === 'exhibition_hall' && (
                      <>
                        {editable ? (
                          <AutocompleteSalePerson
                            value={currentValue}
                            name={lastedFieldName}
                            options={
                              isManager
                                ? listSalePerson.exhibitionsAdminOptions
                                : listSalePersonForSalePerson.exhibitionsAdminOptions
                            }
                            onChange={(newValue) => {
                              if (currentValue !== newValue) {
                                formik.setFieldValue('isEdited', true);
                                if (isManager) {
                                  handleGetReferralAgencyOptions({
                                    sale_agent_id: saleAgentId,
                                    store_id: storeId,
                                    exhibition_id: !!newValue
                                      ? newValue
                                      : undefined,
                                  });
                                } else {
                                  handleGetReferralAgencyOptionsSalePerson({
                                    sale_agent_id: saleAgentId,
                                    store_id: storeId,
                                    exhibition_id: !!newValue
                                      ? newValue
                                      : undefined,
                                  });
                                }
                                formik.setFieldValue(
                                  'p_application_header.exhibition_hall',
                                  newValue
                                );
                                onChange(newValue, row.name_en);
                                setTimeout(() => {
                                  formik.setTouched({
                                    ...formik.touched,
                                    [row.name_en]: true,
                                  });
                                }, 0);
                              }
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}

                        <Typography variant="text_step_admin" id={row.name_en}>
                          {getIsLinked(
                            isLinked,
                            isManager
                          ).list_exhibitions.find(
                            (item) => item.id === formattedValue
                          )?.exhibition_hall ??
                            (isManager
                              ? listSalePerson.exhibitionsAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label
                              : listSalePersonForSalePerson.exhibitionsAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label)}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectSalePerson' &&
                    row.dynamicOptionField === 's_sale_person_id' && (
                      <>
                        {editable ? (
                          <AutocompleteSalePerson
                            value={currentValue}
                            name={lastedFieldName}
                            options={
                              isManager
                                ? listSalePerson.salePersonAdminOptions
                                : listSalePersonForSalePerson.salePersonAdminOptions
                            }
                            onChange={(newValue) => {
                              if (currentValue !== newValue) {
                                formik.setFieldValue('isEdited', true);
                                formik.setFieldValue(
                                  'p_application_header.s_sale_person_id',
                                  newValue
                                );
                                onChange(newValue, row.name_en);
                                setTimeout(() => {
                                  formik.setTouched({
                                    ...formik.touched,
                                    [row.name_en]: true,
                                  });
                                }, 0);
                              }
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}

                        <Typography variant="text_step_admin" id={row.name_en}>
                          {getIsLinked(
                            isLinked,
                            isManager
                          ).list_sale_persons.find(
                            (item) => item.id === formattedValue
                          )?.value ??
                            (isManager
                              ? listSalePerson.salePersonAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label
                              : listSalePersonForSalePerson.salePersonAdminOptions.find(
                                  (item) => item.value === formattedValue
                                )?.label)}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectNumber' &&
                    (row.dynamicOptionField === 'loan_term_month_num' ||
                      row.dynamicOptionField === 'loan_term_year_num') && (
                      <>
                        {editable ? (
                          <PulldownNumber
                            value={currentValue}
                            selectYear={
                              row.dynamicOptionField === 'loan_term_year_num'
                            }
                            dynamicOptionHidden={row.dynamicOptionHidden}
                            onChange={(newValue) => {
                              formik.setFieldValue('isEdited', true);
                              onChange(newValue, row.name_en);
                              setTimeout(() => {
                                formik.setTouched({
                                  [row.name_en]: true,
                                });
                              }, 0);
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}

                        <Typography variant="text_step_admin" id={row.name_en}>
                          {formattedValue}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectDistrict' &&
                    row.dynamicOptionField && (
                      <>
                        {editable ? (
                          <PulldownSelectDistrict
                            value={currentValue}
                            options={PREFECTURES}
                            onChange={(newValue) => {
                              formik.setFieldValue('isEdited', true);
                              onChange(newValue, row.name_en);
                              setTimeout(() => {
                                formik.setTouched({
                                  [row.name_en]: true,
                                });
                              }, 0);
                            }}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}

                        <Typography variant="text_step_admin" id={row.name_en}>
                          {formattedValue}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectLivedYear' && (
                    <>
                      {editable ? (
                        <PulldownLivedYear
                          value={currentValue}
                          yearLength={yearLength}
                          onChange={(newValue) => {
                            formik.setFieldValue('isEdited', true);
                            onChange(newValue, row.name_en);
                            setTimeout(() => {
                              formik.setTouched({
                                [row.name_en]: true,
                              });
                            }, 0);
                          }}
                        />
                      ) : (
                        <PulldownBtn isEmpty />
                      )}

                      <Typography variant="text_step_admin" id={row.name_en}>
                        {formattedValue}
                      </Typography>
                    </>
                  )}
                  {row.dynamicType === 'select' && row.dynamicOptionField && (
                    <>
                      {editable ? (
                        <PulldownSelect
                          dynamicValue={currentValue}
                          onChange={(newValue) => {
                            if (
                              !isManager &&
                              row.dynamicOptionField === 'loan_type' &&
                              !formikSalePerson.values.p_application_header
                                .edit_file_upload
                            ) {
                              if (
                                newValue === LoanType.THREE ||
                                newValue === LoanType.FOUR
                              ) {
                                modalConfirmRef.current?.open(
                                  '注意',
                                  'この操作を実行する権限がありません。'
                                );
                                return;
                              }
                            }
                            if (
                              !isManager &&
                              row.dynamicOptionField === 'nationality' &&
                              !formikSalePerson.values.p_application_header
                                .edit_file_upload
                            ) {
                              if (
                                newValue === NationalityType.FOREIGN_NATIONALITY
                              ) {
                                modalConfirmRef.current?.open(
                                  '注意',
                                  'この操作を実行する権限がありません。'
                                );
                                return;
                              }
                            }
                            formik.setFieldValue('isEdited', true);
                            setTimeout(() => {
                              formik.setTouched({
                                ...formik.touched,
                                [row.name_en]: true,
                              });
                            }, 0);
                            onChange(newValue, row.name_en);
                          }}
                          //@ts-ignore
                          options={get(
                            dynamicOptions,
                            `${row.dynamicOptionField}.value`
                          )}
                          //@ts-ignore
                          disableOptions={
                            (formik.values.p_application_header.loan_target ===
                              PurposeType.SEVEN ||
                              formik.values.p_application_header.loan_target ===
                                PurposeType.EIGHT) &&
                            get(
                              dynamicOptions,
                              `${row.dynamicOptionField}.value`
                              //@ts-ignore
                            ).slice(0, 4)
                          }
                          isDisabledOption={
                            row.dynamicOptionField === 'current_residence' &&
                            (formik.values.p_application_header.loan_target ===
                              PurposeType.SEVEN ||
                              formik.values.p_application_header.loan_target ===
                                PurposeType.EIGHT)
                          }
                        />
                      ) : (
                        <PulldownBtn isEmpty />
                      )}

                      <Typography variant="text_step_admin" id={row.name_en}>
                        {formattedValue || row.defaultValue}
                      </Typography>

                      {renderResidenceOtherRelationship(row.name_en)}
                    </>
                  )}
                  {row.dynamicType === 'selectBorrowingStatus' &&
                    row.dynamicOptionField && (
                      <>
                        {editable ? (
                          <PulldownBorrowingStatusSelect
                            defaultValue={
                              get(
                                formik.values,
                                `${parentKey}.p_borrowings_attributes`,
                                []
                              )?.length > 0
                            }
                            onChange={(newValue) => {
                              formik.setFieldValue('isEdited', true);
                              setTimeout(() => {
                                formik.setTouched({
                                  ...formik.touched,
                                  [row.name_en]: true,
                                });
                              }, 0);

                              if (newValue !== BorrowingStatusType.YES) {
                                onChange([], 'p_borrowings_attributes');
                                return;
                              }
                              onChange(
                                [borrowingsAttributeDefaultValue],
                                'p_borrowings_attributes'
                              );
                            }}
                            //@ts-ignore
                            options={
                              get(
                                dynamicOptions,
                                `${row.dynamicOptionField}.value`
                              ) ?? []
                            }
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}
                        <Typography variant="text_step_admin" id={row.name_en}>
                          {get(
                            formik.values,
                            `${parentKey}.p_borrowings_attributes`,
                            []
                          )?.length
                            ? borrowingStatusAdmin[BorrowingStatusType.YES]
                            : borrowingStatusAdmin[BorrowingStatusType.NO]}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'selectMulti' && row.dynamicOptionField && (
                    <>
                      {editable ? (
                        <PulldownSelectMultiple
                          dynamicField={row.dynamicOptionField}
                          values={currentValue}
                          onChange={(newValue, path) => {
                            formik.setFieldValue('isEdited', true);
                            if (
                              row.dynamicOptionField === 'type_tax_return' ||
                              row.dynamicOptionField === 'income_source'
                            ) {
                              // @ts-ignore
                              onChange(newValue.sort(), path ?? row.name_en);
                            } else {
                              onChange(newValue, path ?? row.name_en);
                            }
                            setTimeout(() => {
                              formik.setTouched({
                                [row.name_en]: true,
                              });
                            }, 0);
                          }}
                          //@ts-ignore
                          options={renderPlannedCohabitant()}
                          onChangeCount={onChange}
                          quantityDependent={quantityDependent}
                        />
                      ) : (
                        <PulldownBtn isEmpty />
                      )}

                      <Stack direction="row" spacing={3}>
                        <Typography variant="text_step_admin" id={row.name_en}>
                          {formattedValue}
                          {lastedFieldName === 'planned_cohabitant' &&
                            currentValue?.includes(RelationshipType.OTHERS) &&
                            !editable &&
                            get(
                              formik.values,
                              `${parentKey}.other_relationship`,
                              ''
                            )}
                        </Typography>
                        {lastedFieldName === 'planned_cohabitant' &&
                          currentValue?.includes(RelationshipType.OTHERS) && (
                            <AutosizeInput
                              id={`${parentKey}.other_relationship`}
                              data-path={`${parentKey}.other_relationship`}
                              disabled={!editable}
                              value={get(
                                formik.values,
                                `${parentKey}.other_relationship`,
                                ''
                              )}
                              sx={{
                                border: '1px solid #C8CDCF',
                                borderRadius: 1,
                                minWidth: 100,
                              }}
                              onFocus={() => {
                                setTimeout(() => {
                                  if (row.subField?.name_en) {
                                    formik.setTouched({
                                      ...formik.touched,
                                      [`${parentKey}.other_relationship`]: true,
                                    });
                                  }
                                }, 0);
                              }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                formik.setFieldValue('isEdited', true);
                                const inputValue = e.target.value ?? '';
                                onChange(
                                  convertToFullWidth(inputValue),
                                  'other_relationship'
                                );
                              }}
                              onBlur={() => {
                                if (row.subField?.name_en) {
                                  formik.setTouched({
                                    ...formik.touched,
                                    [`${parentKey}.other_relationship`]: true,
                                  });
                                }
                              }}
                            />
                          )}
                      </Stack>
                    </>
                  )}
                  {row.dynamicType === 'date' && (
                    <>
                      {editable ? (
                        <PulldownDate
                          dynamicValue={currentValue}
                          onChange={(newValue) => {
                            formik.setFieldValue('isEdited', true);
                            onChange(newValue, row.name_en);
                            setTimeout(() => {
                              formik.setTouched({
                                ...formik.touched,
                                [row.name_en]: true,
                              });
                            }, 0);
                          }}
                          options={row.options}
                        />
                      ) : (
                        <PulldownBtn isEmpty />
                      )}
                      <Typography variant="text_step_admin" id={row.name_en}>
                        {formattedValue}
                      </Typography>
                    </>
                  )}
                  {row.dynamicType === 'dateTime' &&
                    row.dynamicOptionField === 'scheduled_date_moving' && (
                      <>
                        {editable ? (
                          <PulldownDate
                            dynamicValue={currentValue}
                            onChange={(newValue) => {
                              formik.setFieldValue('isEdited', true);
                              onChange(newValue, row.name_en);
                              setTimeout(() => {
                                formik.setTouched({
                                  ...formik.touched,
                                  [row.name_en]: true,
                                });
                              }, 0);
                            }}
                            options={row.options}
                          />
                        ) : (
                          <PulldownBtn isEmpty />
                        )}
                        <Typography variant="text_step_admin" id={row.name_en}>
                          {formattedValue}
                        </Typography>
                      </>
                    )}
                  {row.dynamicType === 'input' && (
                    <>
                      <PulldownBtn isEmpty />
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ width: '100%' }}
                      >
                        {prefix && value && (
                          <Typography variant="text_step_admin">
                            {prefix}
                          </Typography>
                        )}
                        <AutosizeInput
                          id={row.name_en}
                          longText={true}
                          value={value}
                          disabled={!editable}
                          onFocus={() => {
                            setTimeout(() => {
                              formik.setTouched({
                                ...formik.touched,
                                [row.name_en]: true,
                              });
                            }, 0);
                          }}
                          onChange={async (
                            e: ChangeEvent<HTMLInputElement>
                          ) => {
                            await formik.setFieldValue('isEdited', true);
                            const inputValue = e.target.value.trim() ?? '';
                            if (!row.name_en) return;

                            const newInputValue = widthComma
                              ? inputValue
                                  .replaceAll('.', '')
                                  .replaceAll(',', '')
                              : inputValue;
                            const newValue = row.isConvertFullWith
                              ? convertToFullWidth(newInputValue)
                              : newInputValue;
                            onChange(newValue, row.name_en);
                          }}
                          onBlur={() => {
                            formik.setTouched({
                              ...formik.touched,
                              [row.name_en]: false,
                            });
                          }}
                        />
                        {suffix && value && (
                          <Typography variant="text_step_admin">
                            {suffix}
                          </Typography>
                        )}
                      </Stack>
                    </>
                  )}
                  {row.dynamicType === 'inputNumber' && (
                    <>
                      <PulldownBtn isEmpty />
                      <Stack direction="row" alignItems="center">
                        {prefix && value && (
                          <Typography variant="text_step_admin">
                            {prefix}
                          </Typography>
                        )}
                        <AutosizeInput
                          maxWidth={row.max}
                          isPrice={isPrice}
                          id={row.name_en}
                          value={
                            widthComma
                              ? numberWithCommas(value, isPrice)
                              : value
                          }
                          disabled={!editable}
                          onFocus={() => {
                            setTimeout(() => {
                              formik.setTouched({
                                ...formik.touched,
                                [row.name_en]: true,
                              });
                            }, 0);
                          }}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue('isEdited', true);
                            if (!row.name_en) return;
                            //[HOMELOAN-2374]
                            const inputValue =
                              convertToHalfWidth(e.target.value.trim()) ?? '';

                            // remove comma
                            const newInputValue = widthComma
                              ? inputValue.replaceAll(',', '')
                              : inputValue;
                            const newValueYen = isManYen(isPrice, suffix)
                              ? convertManToYen([newInputValue]).toString()
                              : newInputValue;
                            onChange(newValueYen, row.name_en);
                          }}
                          onBlur={() => {
                            formik.setTouched({
                              ...formik.touched,
                              [row.name_en]: false,
                            });
                          }}
                        />
                        {suffix && value && (
                          <Typography variant="text_step_admin">
                            {suffix}
                          </Typography>
                        )}
                      </Stack>
                    </>
                  )}
                  {row.dynamicType === null && (
                    <>
                      <PulldownBtn isEmpty />
                      {isPrice ? (
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="text_step_admin" align="left">
                            {formattedValue}
                            {convertYenToMan([
                              formattedValue ||
                                row.defaultValue?.toString() ||
                                '0',
                            ])}
                          </Typography>
                          <Typography variant="text_step_admin">
                            {suffix}
                          </Typography>
                        </Stack>
                      ) : (
                        <Typography variant="text_step_admin" align="left">
                          {formattedValue || row.defaultValue}
                        </Typography>
                      )}
                      {row.subField &&
                        row.subField.name_en === 'other_relationship' && (
                          <>
                            {get(
                              formik.values,
                              `${parentKey}.other_relationship`,
                              ''
                            )}
                          </>
                        )}
                    </>
                  )}
                </Stack>
                {row.subField && (
                  <Stack
                    flex={1}
                    direction="row"
                    alignItems="center"
                    spacing={3}
                  >
                    {row.subField.dynamicType === 'select' &&
                      row.subField.dynamicOptionField && (
                        <>
                          {editable ? (
                            <PulldownSelect
                              dynamicValue={subCurrentValue}
                              onChange={(newValue) => {
                                formik.setFieldValue('isEdited', true);
                                onChange(newValue, row.subField?.name_en ?? '');

                                setTimeout(() => {
                                  formik.setTouched({
                                    ...formik.touched,
                                    [row.name_en]: true,
                                  });
                                }, 0);
                              }}
                              //@ts-ignore
                              options={get(
                                dynamicOptions,
                                `${row.subField.dynamicOptionField}.value`,
                                []
                              )}
                            />
                          ) : (
                            <PulldownBtn isEmpty />
                          )}

                          <Typography
                            variant="text_step_admin"
                            id={row.subField.name_en}
                          >
                            {row.subField.name_en === 'pair_loan_relationship'
                              ? dynamicOptions.relationship_to_applicant?.value?.find(
                                  (item) =>
                                    item.option_code.toString() ===
                                    subFormattedValue
                                )?.option_name
                              : subFormattedValue}
                          </Typography>

                          {subLastedFieldName === 'relationship' &&
                            subCurrentValue?.includes(
                              RelationshipType.OTHERS
                            ) && (
                              <AutosizeInput
                                id={`${parentKey}.p_residents_attributes[0].other_relationship`}
                                data-path={`${parentKey}.p_residents_attributes[0].other_relationship`}
                                disabled={!editable}
                                value={get(
                                  formik.values,
                                  `${parentKey}.p_residents_attributes[0].other_relationship`,
                                  ''
                                )}
                                sx={{
                                  border: '1px solid #C8CDCF',
                                  borderRadius: 1,
                                  minWidth: 100,
                                }}
                                onFocus={() => {
                                  setTimeout(() => {
                                    if (row.subField?.name_en) {
                                      formik.setTouched({
                                        ...formik.touched,
                                        [`${parentKey}.p_residents_attributes[0].other_relationship`]:
                                          true,
                                      });
                                    }
                                  }, 0);
                                }}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  formik.setFieldValue('isEdited', true);
                                  const inputValue =
                                    e.target.value.trim() ?? '';
                                  onChange(
                                    convertToFullWidth(inputValue),
                                    'p_residents_attributes[0].other_relationship'
                                  );
                                }}
                                onBlur={() => {
                                  if (row.subField?.name_en) {
                                    formik.setTouched({
                                      ...formik.touched,
                                      [`${parentKey}.p_residents_attributes[0].other_relationship`]:
                                        true,
                                    });
                                  }
                                }}
                              />
                            )}

                          {subLastedFieldName === 'relationship_to_applicant' &&
                            subCurrentValue?.includes(
                              RelationshipToApplicantType.OTHERS
                            ) && (
                              <AutosizeInput
                                id={`${parentKey}.p_applicant_people_attributes[1].other_relationship_to_applicant`}
                                data-path={`${parentKey}.p_applicant_people_attributes[1].other_relationship_to_applicant`}
                                disabled={!editable}
                                value={get(
                                  formik.values,
                                  `${parentKey}.p_applicant_people_attributes[1].other_relationship_to_applicant`,
                                  ''
                                )}
                                sx={{
                                  border: '1px solid #C8CDCF',
                                  borderRadius: 1,
                                  minWidth: 100,
                                }}
                                onFocus={() => {
                                  setTimeout(() => {
                                    if (row.subField?.name_en) {
                                      formik.setTouched({
                                        ...formik.touched,
                                        [`${parentKey}.p_applicant_people_attributes[1].other_relationship_to_applicant`]:
                                          true,
                                      });
                                    }
                                  }, 0);
                                }}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  formik.setFieldValue('isEdited', true);
                                  const inputValue =
                                    e.target.value.trim() ?? '';
                                  onChange(
                                    convertToFullWidth(inputValue),
                                    'p_applicant_people_attributes[1]other_relationship_to_applicant'
                                  );
                                }}
                                onBlur={() => {
                                  if (row.subField?.name_en) {
                                    formik.setTouched({
                                      ...formik.touched,
                                      [`${parentKey}.p_applicant_people_attributes[1].other_relationship_to_applicant`]:
                                        true,
                                    });
                                  }
                                }}
                              />
                            )}

                          {subLastedFieldName ===
                            'guarantor_relationship_to_applicant' &&
                            subCurrentValue?.includes(
                              RelationshipToApplicantType.OTHERS
                            ) && (
                              <AutosizeInput
                                id={`${parentKey}.p_join_guarantors_attributes[${indexJoinGuarantor}].other_relationship_to_applicant`}
                                data-path={`${parentKey}.p_join_guarantors_attributes[${indexJoinGuarantor}].other_relationship_to_applicant`}
                                disabled={!editable}
                                value={get(
                                  formik.values,
                                  `${parentKey}.p_join_guarantors_attributes[${indexJoinGuarantor}].other_relationship_to_applicant`,
                                  ''
                                )}
                                sx={{
                                  border: '1px solid #C8CDCF',
                                  borderRadius: 1,
                                  minWidth: 100,
                                }}
                                onFocus={() => {
                                  setTimeout(() => {
                                    if (row.subField?.name_en) {
                                      formik.setTouched({
                                        ...formik.touched,
                                        [`${parentKey}.p_join_guarantors_attributes[${indexJoinGuarantor}].other_relationship_to_applicant`]:
                                          true,
                                      });
                                    }
                                  }, 0);
                                }}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  formik.setFieldValue('isEdited', true);
                                  const inputValue =
                                    e.target.value.trim() ?? '';
                                  onChange(
                                    convertToFullWidth(inputValue),
                                    `p_join_guarantors_attributes[${indexJoinGuarantor}]other_relationship_to_applicant`
                                  );
                                }}
                                onBlur={() => {
                                  if (row.subField?.name_en) {
                                    formik.setTouched({
                                      ...formik.touched,
                                      [`${parentKey}.p_join_guarantors_attributes[${indexJoinGuarantor}].other_relationship_to_applicant`]:
                                        true,
                                    });
                                  }
                                }}
                              />
                            )}
                        </>
                      )}
                  </Stack>
                )}
                <Stack>
                  {editable && (
                    <Typography
                      variant="text_step_admin"
                      color="sp_secondary_01"
                    >
                      {loadErrorMessage(row)}
                      {!loadErrorMessage(row) && loadErrorSubFieldMessage(row)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </MuiTableBody>
  );
};

export const TableBodyEmpty = () => (
  <MuiTableBody>
    <StyledTableRow>
      <StyledTableCell
        sx={{
          width: 20,
          height: 15,
          bgcolor: '#F8F8F8',
          [`&&.${tableCellClasses.body}`]: { py: 0 },
        }}
      >
        <Typography variant="h3" sx={{ width: 20 }} />
      </StyledTableCell>
      <StyledTableCell
        sx={{
          minWidth: 328,
          height: 15,
          bgcolor: '#F8F8F8',
          [`&&.${tableCellClasses.body}`]: { py: 0 },
        }}
      />
      <StyledTableCell
        sx={{
          bgcolor: 'bg_off',
          width: 42,
          height: 15,
          [`&&.${tableCellClasses.body}`]: { py: 0 },
        }}
      />
      <StyledTableCell
        align="left"
        sx={{
          position: 'relative',
          width: '100%',
          height: 15,
          [`&&.${tableCellClasses.body}`]: { py: 0 },
          bgcolor: '#F8F8F8',
        }}
      />
    </StyledTableRow>
  </MuiTableBody>
);

export const TableBodyAdd: FC<PropsWithChildren<{}>> = ({ children }) => (
  <MuiTableBody>
    <StyledTableRow
      sx={{ '&& td.MuiTableCell-body': { border: 'none', px: 0 } }}
    >
      <StyledTableCell colspan={4}>{children}</StyledTableCell>
    </StyledTableRow>
  </MuiTableBody>
);

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child th': {
    border: '1px solid',
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#333333',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '24px',
    borderRadius: 'none',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#333333',
    fontSize: 12,
    padding: 10,
    lineHeight: '14px',
  },
}));

const personOccupancyOptions: Array<{ value: string; label: string }> = [
  {
    value: PersonOccupancyType.YES,
    label: personOccupancy[PersonOccupancyType.YES],
  },
  {
    value: PersonOccupancyType.NO,
    label: personOccupancy[PersonOccupancyType.NO],
  },
];

const includeInExaminationOptions: Array<{ value: string; label: string }> = [
  {
    value: PersonOccupancyType.YES,
    label: includeInExamination[IncludeInExaminationType.YES],
  },
  {
    value: PersonOccupancyType.NO,
    label: includeInExamination[IncludeInExaminationType.NO],
  },
];

const borrowingStatusOptions: Array<{ value: string; label: string }> = [
  {
    value: BorrowingStatusAdminType.YES,
    label: borrowingStatusAdmin[BorrowingStatusAdminType.YES],
  },
  {
    value: BorrowingStatusAdminType.NO,
    label: borrowingStatusAdmin[BorrowingStatusAdminType.NO],
  },
];

const hasLandAdvancePlanOptions: Array<{ value: string; label: string }> = [
  {
    value: HasLandAdvancePlanType.YES,
    label: hasLandAdvancePlan[HasLandAdvancePlanType.YES],
  },
  {
    value: HasLandAdvancePlanType.NO,
    label: hasLandAdvancePlan[HasLandAdvancePlanType.NO],
  },
];

const hasJoinGuarantorOptions: Array<{ value: string; label: string }> = [
  {
    value: CoOwnerType.CANBE,
    label: joinGuarantor[CoOwnerType.CANBE],
  },
  {
    value: CoOwnerType.NONE,
    label: joinGuarantor[CoOwnerType.NONE],
  },
];

const taxReturnOptions: Array<{ value: string; label: string }> = [
  {
    value: FinalTaxReturnType.YES,
    label: finalTaxReturn[FinalTaxReturnType.YES],
  },
  {
    value: FinalTaxReturnType.NOTHING,
    label: finalTaxReturn[FinalTaxReturnType.NOTHING],
  },
];

const isHomeLoanPlusOptions: Array<{ value: boolean; label: string }> = [
  {
    value: !!IsHomeLoanPlus.YES,
    label: isHomeLoanPlus[IsHomeLoanPlus.YES],
  },
  {
    value: !!IsHomeLoanPlus.NO,
    label: isHomeLoanPlus[IsHomeLoanPlus.NO],
  },
];

const loanTargetOptions: Array<{ value: string; label: string }> = [
  {
    value: LoanTargetAdminType.ONE,
    label: loanTargetAdmin[LoanTargetAdminType.ONE],
  },
  {
    value: LoanTargetAdminType.TWO,
    label: loanTargetAdmin[LoanTargetAdminType.TWO],
  },
  {
    value: LoanTargetAdminType.THREE,
    label: loanTargetAdmin[LoanTargetAdminType.THREE],
  },
  {
    value: LoanTargetAdminType.FOUR,
    label: loanTargetAdmin[LoanTargetAdminType.FOUR],
  },
  {
    value: LoanTargetAdminType.FIVE,
    label: loanTargetAdmin[LoanTargetAdminType.FIVE],
  },
  {
    value: LoanTargetAdminType.SIX,
    label: loanTargetAdmin[LoanTargetAdminType.SIX],
  },
];

const transferOfficeOptions: Array<{ value: string; label: string }> = [
  {
    value: DispatchType.YES,
    label: transferOffice[DispatchType.YES],
  },
  {
    value: DispatchType.NOTHING,
    label: transferOffice[DispatchType.NOTHING],
  },
];
