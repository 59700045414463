import { Add, InboxOutlined } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  CardMedia,
  Divider,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { Button, InputFileAdmin } from 'components';
import { FC, Dispatch, SetStateAction } from 'react';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, TableProps } from 'types';
import { getFileExtFromUrl } from 'utils';
import { ACCEPT_TYPE } from 'constant';

type SalePersonUploadNewDocumentTableProps = {
  setImageFile: Dispatch<SetStateAction<File[]>>;
  setDataRows: Dispatch<
    SetStateAction<
      {
        index: number;
        src: string;
        name: string;
      }[]
    >
  >;
  onSubmit: () => void;
  isUploadFile: boolean;
};

export const SalePersonUploadNewDocumentTable: FC<
  TableProps<UploadNewDocumentFileItemProps> &
    SalePersonUploadNewDocumentTableProps
> = ({
  onRequestSort,
  onFilter,
  order,
  orderBy,
  rows,
  headCells,
  setImageFile,
  setDataRows,
  onSubmit,
  isUploadFile,
}) => {
  if (!rows.length) {
    return (
      <Stack height="85vh" bgcolor="main_white">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 613,
            height: 200,
            m: 'auto',
            border: '1px dashed #6B70F0',
          }}
        >
          <Stack direction="column" spacing={5}>
            <InputFileAdmin
              name="main_personal_information_file"
              sx={{ p: 0 }}
              onChange={(file: File[]) => {
                const dataImage = file.map((image, index) => {
                  return {
                    index: index,
                    src: URL.createObjectURL(image),
                    name: image.name,
                  };
                });
                setImageFile(file);
                setDataRows(dataImage);
              }}
              accept={ACCEPT_TYPE}
            >
              <Button
                sx={{
                  py: '9px',
                  px: '118px',
                  backgroundColor: 'main_white',
                  border: '1px solid #6B70F0',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'sp_primary_100_main',
                    color: 'main_white',
                    '& .MuiTypography-root': {
                      color: 'main_white',
                    },
                  },
                }}
              >
                <Typography variant="pdf_title" color="sp_primary_100_main">
                  ファイルを選択
                </Typography>
              </Button>
            </InputFileAdmin>
            <Typography variant="pdf_description" color="b_333">
              ファイルをこのエリアにドロップするか「ファイルを選択」からアップロードしてください
            </Typography>
            <Typography
              variant="pdf_description"
              sx={{ alignSelf: 'center' }}
              color="b_333"
            >
              ※アップロード可能拡張子：png、jpg、jpeg、pdf
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack spacing={3}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 'none', height: '85vh', bgcolor: 'main_white' }}
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{
                display: 'flex',
                minWidth: 1024,
                p: '10px',
                bgcolor: 'bg_pale_gray',
              }}
            >
              <TableRow
                sx={{
                  display: 'flex',
                  bgcolor: 'main_white',
                  flex: 1,
                  boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
                }}
              >
                {(headCells ? headCells : defaultDetailHeadCells).map(
                  (headCell, index) => (
                    <TableCell
                      key={headCell.id}
                      sx={{
                        ...headerCellStyle,
                        ...headCell.style,
                      }}
                    >
                      <Typography
                        sx={{
                          direction: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          variant="table_header_text"
                          sx={{ whiteSpace: 'nowrap' }}
                          color="b_333"
                        >
                          {headCell.label}
                        </Typography>
                        {headCell.smallLabel && (
                          <Typography
                            variant="table_small_text"
                            sx={{ whiteSpace: 'nowrap' }}
                            color="b_333"
                          >
                            {headCell.smallLabel}
                          </Typography>
                        )}
                      </Typography>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 && (
                <TableRow>
                  <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                    <Stack
                      sx={{
                        bgcolor: 'bg_off',
                        overflowX: 'auto',
                        height: 'auto',
                        maxHeight: ` calc(100vh - 130px)`,
                        flex: 1,
                        ...styleScrollBarTable,
                      }}
                      id="preliminary-table-body"
                    >
                      <SalePersonDocumentUploadDetailItems
                        data={rows}
                        handleDelete={(index) => {
                          setImageFile((file) =>
                            file.filter(
                              (item, fileIndex) => fileIndex !== index
                            )
                          );
                          setDataRows((dataImage) =>
                            dataImage.filter(
                              (item, dataImageIndex) => dataImageIndex !== index
                            )
                          );
                          return;
                        }}
                      />
                    </Stack>
                  </Stack>
                </TableRow>
              )}
              {rows.length === 0 && (
                <Stack
                  sx={{
                    display: 'flex',
                    height: 'calc(100vh - 110px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#d8d8d8',
                    background: '#f8f8f8',
                  }}
                >
                  <InboxOutlined
                    sx={{
                      width: 80,
                      height: 70,
                      marginRight: '5px',
                    }}
                  />
                  <Typography sx={{ color: '#7d7d7d' }}>
                    案件がありません。
                  </Typography>
                </Stack>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row-reverse" width="100%" spacing={3}>
          <Button
            sx={{
              width: 140,
              minHeight: 36,
              height: 36,
              bgcolor: 'sh_red',
              borderRadius: '2px',
              mr: 4,
            }}
            onClick={() => {
              if (!isUploadFile) {
                onSubmit();
              }
            }}
          >
            <Typography variant="button01">保存</Typography>
          </Button>
          <InputFileAdmin
            name="main_personal_information_file"
            sx={{ p: 0 }}
            onChange={(file: File[]) => {
              if (!isUploadFile) {
                const dataImage = file.map((image, index) => {
                  return {
                    index: index,
                    src: URL.createObjectURL(image),
                    name: image.name,
                  };
                });
                setImageFile((prev) => [...prev, ...file]);
                setDataRows((prev) => [...prev, ...dataImage]);
              }
            }}
            accept={ACCEPT_TYPE}
          >
            <Button
              sx={{
                width: 150,
                minHeight: 36,
                height: 36,
                color: 'light_blue',
                borderColor: 'light_blue',
                bgcolor: 'main_white',
                border: '1px solid',
                borderRadius: '2px',
              }}
            >
              <Add sx={{ color: 'light_blue', width: 20, height: 20 }} />
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '0.875rem',
                  lineHeight: 1.75,
                }}
              >
                ファイル追加
              </Typography>
            </Button>
          </InputFileAdmin>
        </Stack>
      </Stack>
    );
  }
};

export const SalePersonDocumentUploadDetailItems: FC<{
  data: UploadNewDocumentFileItemProps[];
  handleDelete: (index: number) => void;
}> = ({ data, handleDelete }) => {
  return (
    <Stack>
      {data.map((item, index) => (
        <Stack
          key={index}
          sx={{
            bgcolor: 'main_white',
            p: 2,
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              bgcolor: 'main_white',
              borderBottom: '1px solid',
              borderColor: 'bg_gray',
              alignItems: 'center',
            }}
          >
            <TableCellStyled sx={{ width: 80 }}>
              <Typography variant="text_preliminarie_item" color="b_333">
                {index + 1}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 500,
              }}
            >
              <UploadNewDocumentFileItem
                index={index}
                name={item.name}
                src={item.src}
              />
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 420,
              }}
            >
              <Stack direction="row" spacing={4} pl={4}>
                <Button
                  sx={{
                    width: 124,
                    minHeight: 30,
                    height: 30,
                    bgcolor: 'sh_red',
                    borderRadius: '2px',
                    fontWeight: '600',
                    fontSize: ' 12px',
                    lineHeight: '18px',
                    letterSpacing: '5%',
                  }}
                  onClick={() => handleDelete(index)}
                >
                  <Typography variant="button01">削除</Typography>
                </Button>
              </Stack>
            </TableCellStyled>
          </TableRow>
        </Stack>
      ))}
    </Stack>
  );
};

type UploadNewDocumentFileItemProps = {
  index: number;
  src: string;
  name: string;
};

const UploadNewDocumentFileItem: FC<UploadNewDocumentFileItemProps> = ({
  index,
  src,
  name,
}) => (
  <Stack
    flex={1}
    key={index}
    direction="row"
    alignItems="center"
    sx={{
      minWidth: 0,
      overflow: 'hidden',
      pl: '10px',
      width: 'calc(100% - 10px)',
    }}
  >
    {getFileExtFromUrl(name).includes('pdf') ? (
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#6B70F0',
          textDecoration: 'underline',
        }}
      >
        {name}
      </Typography>
    ) : (
      <CustomWidthTooltip
        sx={{ bgcolor: 'main_white' }}
        title={
          <Card>
            <CardHeader
              title={name}
              titleTypographyProps={{
                variant: 'title_card_preview',
                color: 'b_333',
              }}
              subheader={<Divider />}
              sx={{
                '&.MuiCardHeader-root': {
                  p: 0,
                  pt: '14px',
                  textAlign: 'center',
                },
                '& .MuiCardHeader-title': {
                  pb: '14px',
                },
              }}
            />
            <CardMedia
              component="img"
              sx={{
                bgcolor: 'main_white',
                borderRadius: 5,
                m: 'auto',
                pt: 2,
                height: 325,
                width: 512,
              }}
              image={src}
            />
          </Card>
        }
      >
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#6B70F0',
            textDecoration: 'underline',
          }}
        >
          {name}
        </Typography>
      </CustomWidthTooltip>
    )}
  </Stack>
);

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  transform: 'translate(-50%, -50%) !important',
  left: '50% !important',
  top: '50% !important',
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    backgroundColor: 'main_white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '2px',
  },
});

const TableCellStyled = styled(TableCell)(() => ({
  padding: '10px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));

const defaultDetailHeadCells: HeadCellProps<UploadNewDocumentFileItemProps>[] =
  [
    {
      id: 'index',
      label: '番号',
      style: { width: 80 },
    },
    {
      id: 'name',
      label: 'ファイル名',
      style: {
        justifyContent: 'center',
        width: 500,
      },
    },
  ];

const headerCellStyle = {
  height: 40,
  p: 0,
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  border: 'none',
};
