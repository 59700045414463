import {
  SPImage,
  adminModalEditFormRef,
  spModalKeyNotFoundRef,
  spModalMoveToTopRef,
} from 'components';
import {
  BorrowerIncomeType,
  CoOwnerType,
  GeneralIncomeConfirmation,
  IdentityVerificationType,
  LoanType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PlannedCohabitantType,
  SBI_NAME,
  SP_FIELDS_TABLE,
  SP_FIELDS_TITLE,
} from 'constant';
import { ImageContextType } from 'context';
import { get, set } from 'lodash';
import { checkDatabaseSyncManager } from 'pages/AdminEditPreliminary/thunk';
import { spStepEightSelector } from 'pages/SPStepEight/selectors';
import { spStepFiveSelector } from 'pages/SPStepFive/selectors';
import { spStepThreeIncomeTotalSelector } from 'pages/SPStepFiveIncomeTotalizer/selectors';
import { spStepFourSelector } from 'pages/SPStepFour/selectors';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { spStepTwoIncomeTotalSelector } from 'pages/SPStepFourIncomeTotalizer/selectors';
import { addStatus } from 'pages/SPStepFourIncomeTotalizer/slice';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import {
  checkIncomeTotalizer,
  spStepOneApplicantPeopleSelector,
  spStepOneSelector,
} from 'pages/SPStepOne/selectors';
import { spStepSevenSelector } from 'pages/SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from 'pages/SPStepSevenIncomeTotalizer/selectors';
import { spStepSixSelector } from 'pages/SPStepSix/selectors';
import { spStepThreeSelector } from 'pages/SPStepThree/selectors';
import { spStepTwoSelector } from 'pages/SPStepTwo/selectors';
import { checkDatabaseSyncSalePerson } from 'pages/SalePersonEditPreliminary/thunk';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  SPStepCollateralProviderForm,
  SPStepEightForm,
  SPStepFiveForm,
  SPStepFourForm,
  SPStepIncomeTotalizerForm,
  SPStepOneForm,
  SPStepSevenForm,
  SPStepSevenIncomeTotalizerForm,
  SPStepSixForm,
  SPStepThreeForm,
  SPStepTwoForm,
} from 'types';
import { CheckUpdateStatusResponse } from 'services';
import { checkingExistFile } from 'utils';
import { spStepTenSelector } from '../pages/SPStepTen/selectors';
import { spGetCurrentStatusResult } from '../pages/SPLogin/thunk';
import {
  updateIsStatuses,
  updateStatusResult,
} from '../containers/AuthModal/slice';
import { statusResultSelector } from '../containers/AuthModal/selectors';
import { useAppDispatch } from './useAppDispatch';

export const useUpdateForm = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const { application_number } = useSelector(spStepTenSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const spStepOneApplicantPeople = useSelector(
    spStepOneApplicantPeopleSelector
  );
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepSevenIncomeTotalValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepEightValues = useSelector(spStepEightSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const statusResult = useSelector(statusResultSelector);
  // const sbiId = useSelector(sbiIdSelector);

  // const checkUpdateForm = async (
  //   id: string,
  //   edit_time: string,
  //   sbi_id: string,
  //   imageContext: ImageContextType
  // ) => {
  //   const result = await dispatch(checkDatabaseSync({ id, edit_time, sbi_id }));
  //   if (checkDatabaseSync.fulfilled.match(result)) {
  //     return true;
  //   }
  //   if (checkDatabaseSync.rejected.match(result)) {
  //     const errorResponse = result.payload as SubmitFormErrors;
  //     if (!!errorResponse) {
  //       spModalEditFormRef.current?.open(
  //         '申込内容修正完了',
  //         errorResponse.data,
  //         imageContext
  //       );
  //     }
  //   }
  // };

  const checkUpdateFormManager = async (
    id: string,
    edit_time: string,
    sbi_id: string
    // dataUpdate: EditAdminPreliminariesRequest
  ) => {
    const result = await dispatch(
      checkDatabaseSyncManager({ id, edit_time, sbi_id })
    );
    if (checkDatabaseSyncManager.fulfilled.match(result)) {
      return true;
    }
    if (checkDatabaseSyncManager.rejected.match(result)) {
      const errorResponse = result.payload as CheckUpdateStatusResponse;
      if (
        !!errorResponse.errors &&
        errorResponse.errors.find((error) => error.key === 'token_invalid')
      )
        return;
      adminModalEditFormRef.current?.open();
      throw new Error('reload');
    }
  };

  const checkUpdateFormSalePerson = async (
    id: string,
    edit_time: string,
    sbi_id: string
  ) => {
    const result = await dispatch(
      checkDatabaseSyncSalePerson({ id, edit_time, sbi_id })
    );
    if (checkDatabaseSyncSalePerson.fulfilled.match(result)) {
      return true;
    }
    if (checkDatabaseSyncSalePerson.rejected.match(result)) {
      const errorResponse = result.payload as CheckUpdateStatusResponse;
      if (
        !!errorResponse.errors &&
        errorResponse.errors.find((error) => error.key === 'token_invalid')
      )
        return;
      adminModalEditFormRef.current?.open();
      throw new Error('reload');
    }
  };

  const updateFormOverride = useCallback(
    (
      paramData: {
        stepOneData?: SPStepOneForm;
        stepTwoData?: SPStepTwoForm;
        stepThreeData?: SPStepThreeForm;
        stepFourData?: SPStepFourForm;
        stepFiveData?: SPStepFiveForm;
        stepSixData?: SPStepSixForm;
        stepSevenData?: SPStepSevenForm;
        stepEightData?: SPStepEightForm & { p_referral_agency_id?: string };
        stepTwoIncomeData?: SPStepIncomeTotalizerForm;
        spStepTwoStatusIsReseted?: boolean;
        stepThreeIncomeData?: SPStepThreeForm;
        stepCollateralProvider?: SPStepCollateralProviderForm;
        stepSevenIncomeData?: SPStepSevenIncomeTotalizerForm;
        imageContext: ImageContextType;
      },
      DisabledButton: Dispatch<SetStateAction<boolean>>,
      SetImageModal: Dispatch<SetStateAction<boolean>>,
      SetReset?: Dispatch<SetStateAction<number>>
    ) => {
      (async () => {
        const data = new FormData();

        const {
          insuranceFile,
          insuranceFileBackImage,
          financialStatement1File,
          financialStatement2File,
          financialStatement3File,
          firstWithholdingSlipFile,
          secondWithholdingSlipFile,
          otherDocumentFile,
          employmentAgreementFile,
          businessTaxReturn1File,
          businessTaxReturn2File,
          businessTaxReturn3File,
          firstIncomeFile,
          secondIncomeFile,
          thirdIncomeFile,
          repaymentScheduleImage,
          residenceFile,
          residenceFileBackImage,
          residenceIncomeFile,
          residenceIncomeFileBackImage,
          businessCard,
          driverLicenseFrontImage,
          driverLicenseBackImage,
          cardNumberFrontImage,
          cardNumberBackImage,
          residentRegisterFrontImage,
          residentRegisterBackImage,
          propertyInformationFile,
          insuranceFile2,
          insuranceFileBackImage2,
          financialStatement1File2,
          financialStatement2File2,
          financialStatement3File2,
          firstWithholdingSlipFile2,
          secondWithholdingSlipFile2,
          otherDocumentFile2,
          employmentAgreementFile2,
          businessTaxReturn1File2,
          businessTaxReturn2File2,
          businessTaxReturn3File2,
          firstIncomeFile2,
          secondIncomeFile2,
          thirdIncomeFile2,
          driverLicenseFrontImage2,
          driverLicenseBackImage2,
          cardNumberFrontImage2,
          cardNumberBackImage2,
          residentRegisterFrontImage2,
          residentRegisterBackImage2,
        } = paramData.imageContext;

        const {
          p_applicant_people_attributes,
          p_borrowing_details_attributes,
          p_application_banks_attributes,
          master_bank_ids,
          ...restStepOneApplicationHeader
        } = !!paramData.stepOneData
          ? paramData.stepOneData.p_application_header
          : spStepOneValues.p_application_header;

        const p_applicant_people_attributes1 = Array.isArray(
          p_applicant_people_attributes
        )
          ? p_applicant_people_attributes[0]
          : p_applicant_people_attributes;

        const stepTwoApplicantPeople = !!paramData.stepTwoData
          ? paramData.stepTwoData.p_application_header
              .p_applicant_people_attributes[0]
          : spStepTwoValues.p_applicant_people;
        const stepThreeApplicantPeople = !!paramData.stepThreeData
          ? paramData.stepThreeData.p_application_header
              .p_applicant_people_attributes[0]
          : spStepThreeValues.p_applicant_people;
        const {
          p_applicant_people_attributes: stepFourApplicantPeople,
          p_residents_attributes,
          property_information_file,
          business_ability,
          ...restStepFourApplicationHeader
        } = !!paramData.stepFourData
          ? paramData.stepFourData.p_application_header
          : spStepFourValues.p_application_header;
        const p_residents_attributes_key_edit = !!paramData.stepFourData
          ? paramData.stepFourData.p_application_header.p_residents_attributes
          : spStepFourValues.p_residents_attributes;

        const { p_join_guarantors } = !!paramData.stepCollateralProvider
          ? paramData.stepCollateralProvider.p_application_header
          : spStepCollateralProviderValues;

        const {
          p_borrowings_attributes,
          p_applicant_people_attributes: stepFiveApplicantPeople,
          ...restStepFiveApplicationHeader
        } = !!paramData.stepFiveData
          ? paramData.stepFiveData.p_application_header
          : spStepFiveValues;
        const {
          other_procurement_breakdown_content,
          ...stepSixApplicationHeader
        } = !!paramData.stepSixData
          ? paramData.stepSixData.p_application_header
          : spStepSixValues.p_application_header;
        let checkEmptyValueStepSix = {};
        Object.keys(stepSixApplicationHeader).forEach((key) => {
          if (key === 'require_funds_breakdown_mortgage') {
            if (
              (!!paramData.stepOneData &&
                !paramData.stepOneData.p_application_header
                  .is_home_loan_plus) ||
              !spStepOneValues.p_application_header.is_home_loan_plus
            ) {
              set(checkEmptyValueStepSix, key, '0');
            } else {
              set(
                checkEmptyValueStepSix,
                key,
                !!get(stepSixApplicationHeader, key)
                  ? get(stepSixApplicationHeader, key)
                  : '0'
              );
            }
          } else {
            set(
              checkEmptyValueStepSix,
              key,
              !!get(stepSixApplicationHeader, key)
                ? get(stepSixApplicationHeader, key)
                : '0'
            );
          }
        });

        const { identity_verification, ...spStepSevenImageValues } =
          !!paramData.stepSevenData
            ? paramData.stepSevenData.p_application_header
                .p_applicant_people_attributes[0]
            : spStepSevenValues.p_applicant_people;
        const {
          business_card,
          // sale_agent_id,
          // store_id,
          // exhibition_id,
          phone_number,
          ...restReferralAgencies
        } = !!paramData.stepEightData
          ? paramData.stepEightData.p_referral_agencies
          : spStepEightValues.p_referral_agencies;

        /* INCOME TOTAL VALUES */
        const stepTwoIncomeTotalApplicantPeople = !!paramData.stepTwoIncomeData
          ? paramData.stepTwoIncomeData.p_application_header
              .p_applicant_people_attributes[0]
          : spStepTwoIncomeTotalValues.p_applicant_people;
        const p_applicant_people_id = !!paramData.stepTwoIncomeData
          ? paramData.stepTwoIncomeData.p_application_header?.id
          : spStepTwoIncomeTotalValues.p_applicant_people_id;
        const stepThreeIncomeTotalApplicantPeople =
          !!paramData.stepThreeIncomeData
            ? paramData.stepThreeIncomeData.p_application_header
                .p_applicant_people_attributes[0]
            : spStepThreeIncomeTotalValues.p_applicant_people;
        const incomeTotalizerData = !!paramData.stepOneData
          ? paramData.stepOneData.p_application_header.loan_type ===
              LoanType.THREE ||
            paramData.stepOneData.p_application_header.loan_type ===
              LoanType.FOUR
          : incomeTotalizer;
        const stepFourApplicantPeopleData = Array.isArray(
          stepFourApplicantPeople
        )
          ? stepFourApplicantPeople[0]
          : stepFourApplicantPeople;
        const spStepsValues = incomeTotalizerData
          ? {
              p_application_header: {
                ...restStepOneApplicationHeader,
                ...restStepFourApplicationHeader,
                ...restStepFiveApplicationHeader,
                ...checkEmptyValueStepSix,
                saving_amount: (
                  +stepSixApplicationHeader.deposit_savings_1 +
                  +stepSixApplicationHeader.real_estate_sale_price +
                  +stepSixApplicationHeader.other_saving_amount
                ).toString(),
                other_procurement_breakdown_content,
                p_applicant_people_attributes: [
                  {
                    ...p_applicant_people_attributes1,
                    ...stepTwoApplicantPeople,
                    ...stepThreeApplicantPeople,
                    ...stepFourApplicantPeopleData,
                    ...stepFiveApplicantPeople[0],
                    identity_verification,
                  },
                  {
                    ...(!!application_number &&
                      incomeTotalizerData && {
                        ...spStepOneApplicantPeople[1],
                      }),
                    ...stepTwoIncomeTotalApplicantPeople,
                    ...stepThreeIncomeTotalApplicantPeople,
                    ...stepFiveApplicantPeople[1],
                    identity_verification: !!paramData.stepSevenIncomeData
                      ? paramData.stepSevenIncomeData.p_application_header
                          .p_applicant_people_attributes[0]
                          .identity_verification
                      : spStepSevenIncomeTotalValues.p_applicant_people
                          .identity_verification,
                  },
                ],
                p_borrowing_details_attributes: [
                  ...p_borrowing_details_attributes,
                ],
                p_residents_attributes: !!application_number
                  ? [...p_residents_attributes_key_edit]
                  : [p_residents_attributes],
                p_borrowings_attributes,
                p_join_guarantors:
                  (!!paramData.stepOneData &&
                    paramData.stepOneData.p_application_header
                      .p_applicant_people_attributes[0].has_join_guarantor ===
                      CoOwnerType.NONE &&
                    !application_number) ||
                  (!paramData.stepOneData &&
                    spStepOneValues.p_application_header
                      .p_applicant_people_attributes.has_join_guarantor ===
                      CoOwnerType.NONE &&
                    !application_number)
                    ? []
                    : p_join_guarantors,
              },
            }
          : {
              p_application_header: {
                ...restStepOneApplicationHeader,
                ...restStepFourApplicationHeader,
                ...restStepFiveApplicationHeader,
                ...checkEmptyValueStepSix,
                saving_amount: (
                  +stepSixApplicationHeader.deposit_savings_1 +
                  +stepSixApplicationHeader.real_estate_sale_price +
                  +stepSixApplicationHeader.other_saving_amount
                ).toString(),
                other_procurement_breakdown_content,
                p_applicant_people_attributes: [
                  {
                    ...p_applicant_people_attributes1,
                    ...stepTwoApplicantPeople,
                    ...stepThreeApplicantPeople,
                    ...stepFourApplicantPeopleData,
                    ...stepFiveApplicantPeople[0],
                    identity_verification,
                  },
                ],
                p_borrowing_details_attributes: [
                  ...p_borrowing_details_attributes,
                ],
                p_residents_attributes: !!application_number
                  ? [...p_residents_attributes_key_edit]
                  : [p_residents_attributes],
                p_borrowings_attributes,
                p_join_guarantors:
                  (!!paramData.stepOneData
                    ? paramData.stepOneData.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor ===
                      CoOwnerType.NONE
                    : spStepOneValues.p_application_header
                        .p_applicant_people_attributes.has_join_guarantor ===
                      CoOwnerType.NONE) && !application_number
                    ? []
                    : p_join_guarantors,
              },
            };

        const {
          p_applicant_people_attributes: applicantPeople,
          p_borrowing_details_attributes: borrowingDetails,
          p_residents_attributes: residents,
          p_borrowings_attributes: borrowings,
          p_join_guarantors: joinGuarantors,
          planned_cohabitant,
          land_rent_to_be_paid_borrower,
          land_rent_to_be_paid,
          house_rent_to_be_paid_borrower,
          house_rent_to_be_paid,
          children_number,
          siblings_number,
          other_people_number,
          housemate_number,
          ...restApplicationHeader
        } = spStepsValues.p_application_header;

        if (!planned_cohabitant.length) {
          // data.append(`p_application_header[planned_cohabitant][]`, '');
          // data.append(`p_application_header[housemate_number]`, '0');
        } else {
          planned_cohabitant.forEach((plannedCohabitant) => {
            if (plannedCohabitant !== '') {
              data.append(
                `p_application_header[planned_cohabitant][]`,
                plannedCohabitant.toString()
              );
            }
          });
          data.append(
            `p_application_header[housemate_number]`,
            housemate_number
          );
        }

        if (paramData.spStepTwoStatusIsReseted !== undefined) {
          data.append(
            `p_application_header[general_income_confirmation]`,
            paramData.spStepTwoStatusIsReseted
              ? GeneralIncomeConfirmation.NOT_CONFIRM
              : GeneralIncomeConfirmation.CONFIRM
          );
        } else {
          data.append(
            `p_application_header[general_income_confirmation]`,
            spStepTwoIncomeTotalValues.status?.isReseted
              ? GeneralIncomeConfirmation.NOT_CONFIRM
              : GeneralIncomeConfirmation.CONFIRM
          );
        }

        data.append(
          `p_application_header[children_number]`,
          planned_cohabitant.find(
            (plannedCohabitant) =>
              plannedCohabitant === PlannedCohabitantType.CHILD
          )
            ? children_number
            : ''
        );

        data.append(
          `p_application_header[siblings_number]`,
          planned_cohabitant.find(
            (plannedCohabitant) =>
              plannedCohabitant === PlannedCohabitantType.BROTHERS_AND_SISTERS
          )
            ? siblings_number
            : ''
        );

        data.append(
          `p_application_header[other_people_number]`,
          planned_cohabitant.find(
            (plannedCohabitant) =>
              plannedCohabitant === PlannedCohabitantType.OTHERS
          )
            ? other_people_number
            : ''
        );

        if (!business_ability.length) {
          data.append(`p_application_header[business_ability][]`, '');
        } else {
          business_ability.forEach((businessAbility) => {
            data.append(
              `p_application_header[business_ability][]`,
              businessAbility.toString()
            );
          });
        }

        Object.keys(restApplicationHeader).forEach((key) => {
          data.append(
            `p_application_header[${key}]`,
            get(restApplicationHeader, key) ?? ''
          );
        });

        switch (land_rent_to_be_paid_borrower) {
          case BorrowerIncomeType.APPLICANT_SELF:
            data.append(
              `p_application_header[p_applicant_people_attributes][0][land_rent_to_be_paid]`,
              land_rent_to_be_paid
            );
            break;
          case BorrowerIncomeType.INCOME_TOTAL:
            data.append(
              `p_application_header[p_applicant_people_attributes][1][land_rent_to_be_paid]`,
              land_rent_to_be_paid
            );
            break;
        }

        switch (house_rent_to_be_paid_borrower) {
          case BorrowerIncomeType.APPLICANT_SELF:
            data.append(
              `p_application_header[p_applicant_people_attributes][0][house_rent_to_be_paid]`,
              house_rent_to_be_paid
            );
            break;
          case BorrowerIncomeType.INCOME_TOTAL:
            data.append(
              `p_application_header[p_applicant_people_attributes][1][house_rent_to_be_paid]`,
              house_rent_to_be_paid
            );
            break;
        }

        if (incomeTotalizerData) {
          applicantPeople
            .map((person) => {
              const {
                land_rent_to_be_paid,
                house_rent_to_be_paid,
                ...restPerson
              } = person;
              return restPerson;
            })
            .forEach((person, index) => {
              const {
                residence_file,
                income_source,
                type_tax_return,
                maternity_paternity_leave_start_time,
                maternity_paternity_leave_end_time,
                ...restFirstApplicantPeople
              } = person;
              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][applicant_detail_type]`,
                index.toString()
              );
              if (!income_source.length) {
                data.append(
                  `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
                  ''
                );
              } else {
                income_source.forEach((id) => {
                  data.append(
                    `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
                    id
                  );
                });
              }
              if (!type_tax_return.length) {
                data.append(
                  `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
                  ''
                );
              } else {
                type_tax_return.forEach((id) => {
                  data.append(
                    `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
                    id
                  );
                });
              }

              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_start_time]`,
                !!maternity_paternity_leave_start_time
                  ? `${maternity_paternity_leave_start_time}/01`
                  : maternity_paternity_leave_start_time
              );
              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_end_time]`,
                !!maternity_paternity_leave_end_time
                  ? `${maternity_paternity_leave_end_time}/01`
                  : maternity_paternity_leave_end_time
              );

              Object.keys(restFirstApplicantPeople).forEach((key) => {
                data.append(
                  `p_application_header[p_applicant_people_attributes][${index}][${key}]`,
                  get(restFirstApplicantPeople, key) ?? ''
                );
              });
            });
        } else {
          applicantPeople.forEach((person, index) => {
            const {
              residence_file,
              income_source,
              type_tax_return,
              maternity_paternity_leave_start_time,
              maternity_paternity_leave_end_time,
              ...restFirstApplicantPeople
            } = person;
            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][applicant_detail_type]`,
              index.toString()
            );
            if (!income_source.length) {
              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
                ''
              );
            } else {
              income_source.forEach((id) => {
                data.append(
                  `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
                  id
                );
              });
            }
            if (!type_tax_return.length) {
              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
                ''
              );
            } else {
              type_tax_return.forEach((id) => {
                data.append(
                  `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
                  id
                );
              });
            }

            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_start_time]`,
              `${maternity_paternity_leave_start_time}/01`
            );
            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_end_time]`,
              `${maternity_paternity_leave_end_time}/01`
            );

            Object.keys(restFirstApplicantPeople).forEach((key) => {
              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][${key}]`,
                get(restFirstApplicantPeople, key) ?? ''
              );
            });
          });
          if (!!p_applicant_people_id) {
            data.append(
              `p_application_header[p_applicant_people_attributes][1][id]`,
              p_applicant_people_id
            );
            data.append(
              `p_application_header[p_applicant_people_attributes][1][_destroy]`,
              'true'
            );
          }
        }

        borrowingDetails.forEach((borrowingDetail, index) => {
          data.append(
            `p_application_header[p_borrowing_details_attributes][${index}][borrowing_detail_type]`,
            (index + 1).toString()
          );
          Object.keys(borrowingDetail).forEach((key) => {
            data.append(
              `p_application_header[p_borrowing_details_attributes][${index}][${key}]`,
              get(borrowingDetail, key) ?? ''
            );
          });
        });

        residents.forEach((resident, index) => {
          Object.keys(resident).forEach((key) => {
            data.append(
              `p_application_header[p_residents_attributes][${index}][${key}]`,
              get(resident, key) ?? ''
            );
          });
        });

        const indexes = borrowings
          .filter((borrowing) => !borrowing._destroy)
          .map((borrowing) => borrowings.indexOf(borrowing));

        borrowings.forEach((borrowing, index) => {
          const { repayment_schedule_image, ...restBorrowing } = borrowing;
          //CASE UPDATE SUBMITTED PRELIMINARY STEP 5
          if (
            !!application_number &&
            !!repayment_schedule_image &&
            !borrowing._destroy
          ) {
            const repaymentScheduleImageIds = repaymentScheduleImage[
              indexes.indexOf(index)
            ]
              .map((file) => {
                if (file instanceof File) return '';
                return file.id;
              })
              .filter((id) => !!id);

            const repaymentScheduleImageRemovedIds = repayment_schedule_image
              .filter((file) => repaymentScheduleImageIds.includes(file.id))
              .map((file) => file.id);

            if (
              repaymentScheduleImageRemovedIds.length === 0 &&
              !repaymentScheduleImage[indexes.indexOf(index)].length
            ) {
              data.append(
                `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
                ''
              );
            } else {
              repaymentScheduleImageRemovedIds.forEach((file) => {
                data.append(
                  `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
                  file
                );
              });
            }
          }
          repaymentScheduleImage[indexes.indexOf(index)]?.forEach(
            (file, idx) => {
              if (file instanceof File) {
                return data.append(
                  `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
                  file
                );
              } else {
                if (!application_number) {
                  return data.append(
                    `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
                    file.id
                  );
                }
              }
            }
          );
          Object.keys(restBorrowing).forEach((key) => {
            data.append(
              `p_application_header[p_borrowings_attributes][${index}][${key}]`,
              get(borrowing, key) ?? ''
            );
          });
        });
        if (
          !!paramData.stepOneData &&
          paramData.stepOneData.p_application_header
            .p_applicant_people_attributes[0].has_join_guarantor ===
            CoOwnerType.NONE
        ) {
          const filterGuarantors = joinGuarantors.filter(
            (guarantor) => !!guarantor.id
          );
          const joinGuarantorsRemove = filterGuarantors.map((guarantor) => {
            const { _destroy, ...restGuarantor } = guarantor;
            return {
              ...restGuarantor,
              _destroy: true,
            };
          });
          joinGuarantorsRemove.forEach((guarantor, index) => {
            Object.keys(guarantor).forEach((key) => {
              data.append(
                `p_application_header[p_join_guarantors_attributes][${index}][${key}]`,
                get(guarantor, key) ?? ''
              );
            });
          });
        } else {
          joinGuarantors.forEach((guarantor, index) => {
            Object.keys(guarantor).forEach((key) => {
              data.append(
                `p_application_header[p_join_guarantors_attributes][${index}][${key}]`,
                get(guarantor, key) ?? ''
              );
            });
          });
        }

        const editBanks = p_application_banks_attributes.map((bank) => {
          if (master_bank_ids.includes(bank.s_master_bank_id)) return bank;
          return {
            ...bank,
            _destroy: true,
          };
        });

        if (!!application_number) {
          master_bank_ids.forEach((bankId) =>
            data.append(`p_application_header[master_bank_ids][]`, bankId)
          );
          const pApplicationBanksIds = p_application_banks_attributes.map(
            (bank) => bank.s_master_bank_id
          );
          const editNewBanks = master_bank_ids.filter(
            (bankId) => !pApplicationBanksIds.includes(bankId)
          );
          let idx = 0;
          editBanks.forEach((bank, index) => {
            Object.keys(bank).forEach((key) => {
              if (!bank.id || !!bank._destroy)
                data.append(
                  `p_application_header[p_application_banks_attributes][${index}][${key}]`,
                  get(bank, key) ?? ''
                );
            });
            idx++;
          });
          if (!!editNewBanks.length) {
            editNewBanks.forEach((bankId, index) => {
              data.append(
                `p_application_header[p_application_banks_attributes][${
                  idx + index
                }][s_master_bank_id]`,
                bankId
              );
            });
          }
        } else {
          master_bank_ids.forEach((bankId, index) => {
            data.append(
              `p_application_header[p_application_banks_attributes][${index}][s_master_bank_id]`,
              bankId
            );
          });
        }

        Object.keys(restReferralAgencies).forEach((key) => {
          data.append(
            `p_application_header[${key}]`,
            get(restReferralAgencies, key) ?? ''
          );
        });

        data.append(
          `p_application_header[sale_person_phone_number]`,
          phone_number ?? ''
        );

        if (paramData.stepEightData) {
          data.append(
            `p_application_header[p_referral_agency_id]`,
            paramData.stepEightData?.p_referral_agency_id ?? ''
          );
        } else if (spStepEightValues.p_referral_agency_id) {
          data.append(
            `p_application_header[p_referral_agency_id]`,
            spStepEightValues.p_referral_agency_id ?? ''
          );
        }

        const identityVerificationStepSeven = !!paramData.stepSevenData
          ? paramData.stepSevenData.p_application_header
              .p_applicant_people_attributes[0].identity_verification
          : spStepSevenValues.p_applicant_people.identity_verification;
        const imageStepSeven = {
          insurance_file: insuranceFile,
          insurance_file_back_image: insuranceFileBackImage,
          financial_statement_1_file: financialStatement1File,
          financial_statement_2_file: financialStatement2File,
          financial_statement_3_file: financialStatement3File,
          first_withholding_slip_file: firstWithholdingSlipFile,
          second_withholding_slip_file: secondWithholdingSlipFile,
          other_document_file: otherDocumentFile,
          employment_agreement_file: employmentAgreementFile,
          business_tax_return_1_file: businessTaxReturn1File,
          business_tax_return_2_file: businessTaxReturn2File,
          business_tax_return_3_file: businessTaxReturn3File,
          first_income_file: firstIncomeFile,
          second_income_file: secondIncomeFile,
          third_income_file: thirdIncomeFile,
          driver_license_front_image:
            identityVerificationStepSeven ===
            IdentityVerificationType.DRIVER_LICENSE
              ? driverLicenseFrontImage
              : [],
          driver_license_back_image:
            identityVerificationStepSeven ===
            IdentityVerificationType.DRIVER_LICENSE
              ? driverLicenseBackImage
              : [],
          card_number_front_image:
            identityVerificationStepSeven ===
            IdentityVerificationType.MY_NUMBER_CARD
              ? cardNumberFrontImage
              : [],
          card_number_back_image:
            identityVerificationStepSeven ===
            IdentityVerificationType.MY_NUMBER_CARD
              ? cardNumberBackImage
              : [],
          resident_register_front_image:
            identityVerificationStepSeven ===
            IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
              ? residentRegisterFrontImage
              : [],
          resident_register_back_image:
            identityVerificationStepSeven ===
            IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
              ? residentRegisterBackImage
              : [],
        };

        const identityVerificationStepSevenIncomeTotal =
          !!paramData.stepSevenIncomeData
            ? paramData.stepSevenIncomeData.p_application_header
                .p_applicant_people_attributes[0].identity_verification
            : spStepSevenIncomeTotalValues.p_applicant_people
                .identity_verification;

        const imageStepSevenIncomeTotal = {
          insurance_file: insuranceFile2,
          insurance_file_back_image: insuranceFileBackImage2,
          financial_statement_1_file: financialStatement1File2,
          financial_statement_2_file: financialStatement2File2,
          financial_statement_3_file: financialStatement3File2,
          first_withholding_slip_file: firstWithholdingSlipFile2,
          second_withholding_slip_file: secondWithholdingSlipFile2,
          other_document_file: otherDocumentFile2,
          employment_agreement_file: employmentAgreementFile2,
          business_tax_return_1_file: businessTaxReturn1File2,
          business_tax_return_2_file: businessTaxReturn2File2,
          business_tax_return_3_file: businessTaxReturn3File2,
          first_income_file: firstIncomeFile2,
          second_income_file: secondIncomeFile2,
          third_income_file: thirdIncomeFile2,
          driver_license_front_image:
            identityVerificationStepSevenIncomeTotal ===
            IdentityVerificationType.DRIVER_LICENSE
              ? driverLicenseFrontImage2
              : [],
          driver_license_back_image:
            identityVerificationStepSevenIncomeTotal ===
            IdentityVerificationType.DRIVER_LICENSE
              ? driverLicenseBackImage2
              : [],
          card_number_front_image:
            identityVerificationStepSevenIncomeTotal ===
            IdentityVerificationType.MY_NUMBER_CARD
              ? cardNumberFrontImage2
              : [],
          card_number_back_image:
            identityVerificationStepSevenIncomeTotal ===
            IdentityVerificationType.MY_NUMBER_CARD
              ? cardNumberBackImage2
              : [],
          resident_register_front_image:
            identityVerificationStepSevenIncomeTotal ===
            IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
              ? residentRegisterFrontImage2
              : [],
          resident_register_back_image:
            identityVerificationStepSevenIncomeTotal ===
            IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
              ? residentRegisterBackImage2
              : [],
        };

        //CASE UPDATE SUBMITTED PRELIMINARY
        if (!!application_number) {
          //STEP 2 IMAGES - CASE 1 PERSON
          const residenceIds = residenceFile
            .map((file) => {
              if (file instanceof File) return '';
              return file.id;
            })
            .filter((id) => !!id);

          const residenceRemovedIds = stepTwoApplicantPeople.residence_file
            .filter((file) => residenceIds.includes(file.id))
            .map((file) => file.id);

          if (residenceRemovedIds.length === 0 && !residenceFile.length) {
            data.append(
              `p_application_header[p_applicant_people_attributes][0][residence_file][]`,
              ''
            );
          } else {
            residenceRemovedIds.forEach((file) => {
              data.append(
                `p_application_header[p_applicant_people_attributes][0][residence_file][]`,
                file
              );
            });
          }

          const residenceBackIds = residenceFileBackImage
            .map((file) => {
              if (file instanceof File) return '';
              return file.id;
            })
            .filter((id) => !!id);

          const residenceBackRemovedIds =
            stepTwoApplicantPeople.residence_file_back_image
              .filter((file) => residenceBackIds.includes(file.id))
              .map((file) => file.id);

          if (
            residenceBackRemovedIds.length === 0 &&
            !residenceFileBackImage.length
          ) {
            data.append(
              `p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]`,
              ''
            );
          } else {
            residenceBackRemovedIds.forEach((file) => {
              data.append(
                `p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]`,
                file
              );
            });
          }

          //STEP 2 IMAGES - CASE 2 PERSON
          const residenceIncomeIds = residenceIncomeFile
            .map((file) => {
              if (file instanceof File) return '';
              return file.id;
            })
            .filter((id) => !!id);

          const residenceIncomeRemovedIds =
            stepTwoIncomeTotalApplicantPeople.residence_file
              .filter((file) => residenceIncomeIds.includes(file.id))
              .map((file) => file.id);

          if (
            residenceIncomeRemovedIds.length === 0 &&
            !residenceIncomeFile.length
          ) {
            data.append(
              `p_application_header[p_applicant_people_attributes][1][residence_file][]`,
              ''
            );
          } else {
            residenceIncomeRemovedIds.forEach((file) => {
              data.append(
                `p_application_header[p_applicant_people_attributes][1][residence_file][]`,
                file
              );
            });
          }

          const residenceIncomeBackIds = residenceIncomeFileBackImage
            .map((file) => {
              if (file instanceof File) return '';
              return file.id;
            })
            .filter((id) => !!id);

          const residenceIncomeBackRemovedIds =
            stepTwoIncomeTotalApplicantPeople.residence_file_back_image
              .filter((file) => residenceIncomeBackIds.includes(file.id))
              .map((file) => file.id);

          if (
            residenceIncomeBackRemovedIds.length === 0 &&
            !residenceIncomeFileBackImage.length
          ) {
            data.append(
              `p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]`,
              ''
            );
          } else {
            residenceIncomeBackRemovedIds.forEach((file) => {
              data.append(
                `p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]`,
                file
              );
            });
          }

          //STEP 4 IMAGES
          const propertyInformationFileIds = propertyInformationFile
            .map((file) => {
              if (file instanceof File) return '';
              return file.id;
            })
            .filter((id) => !!id);

          const propertyInformationFileRemovedIds = property_information_file
            .filter((file) => propertyInformationFileIds.includes(file.id))
            .map((file) => file.id);

          if (
            propertyInformationFileRemovedIds.length === 0 &&
            !propertyInformationFile.length
          ) {
            data.append(
              `p_application_header[property_information_file][]`,
              ''
            );
          } else {
            propertyInformationFileRemovedIds.forEach((file) => {
              data.append(
                `p_application_header[property_information_file][]`,
                file
              );
            });
          }

          //STEP 7 IMAGES
          Object.keys(imageStepSeven).forEach((key) => {
            const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
              (item) => item === key
            );
            const imageStepSevenIds = get(imageStepSeven, key, []).map(
              (file: SPImage | File) => {
                if (file instanceof File) return '';
                return file.id;
              }
            );

            const imageStepSevenRemovedIds = get(
              spStepSevenImageValues,
              key,
              []
            )
              .filter((file: SPImage) => imageStepSevenIds.includes(file.id))
              .map((file: SPImage) => file.id);
            if (
              imageStepSevenRemovedIds.length === 0 &&
              // @ts-ignore
              imageStepSeven[key].length === 0
            ) {
              isNotUploadMulti
                ? data.append(
                    `p_application_header[p_applicant_people_attributes][0][${key}]`,
                    ''
                  )
                : data.append(
                    `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                    ''
                  );
            } else {
              imageStepSevenRemovedIds.forEach((file: string) => {
                isNotUploadMulti
                  ? data.append(
                      `p_application_header[p_applicant_people_attributes][0][${key}]`,
                      file
                    )
                  : data.append(
                      `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                      file
                    );
              });
            }
          });
          //STEP 7 INCOME TOTAL IMAGES
          if (incomeTotalizerData) {
            Object.keys(imageStepSevenIncomeTotal).forEach((key) => {
              const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
                (item) => item === key
              );
              const imageStepSevenIncomeIds = get(
                imageStepSevenIncomeTotal,
                key,
                []
              ).map((file: SPImage | File) => {
                if (file instanceof File) return '';
                return file.id;
              });

              const { identity_verification, ...spStepSevenIncomeImageValues } =
                spStepSevenIncomeTotalValues.p_applicant_people;

              const imageStepSevenIncomeRemovedIds = get(
                spStepSevenIncomeImageValues,
                key,
                []
              )
                .filter((file: SPImage) =>
                  imageStepSevenIncomeIds.includes(file.id)
                )
                .map((file: SPImage) => file.id);

              if (
                imageStepSevenIncomeRemovedIds.length === 0 &&
                // @ts-ignore
                imageStepSevenIncomeTotal[key].length === 0
              ) {
                isNotUploadMulti
                  ? data.append(
                      `p_application_header[p_applicant_people_attributes][1][${key}]`,
                      ''
                    )
                  : data.append(
                      `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                      ''
                    );
              } else {
                imageStepSevenIncomeRemovedIds.forEach((file: string) => {
                  isNotUploadMulti
                    ? data.append(
                        `p_application_header[p_applicant_people_attributes][1][${key}]`,
                        file
                      )
                    : data.append(
                        `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                        file
                      );
                });
              }
            });
          }

          //STEP 8 IMAGES
          const businessCardIds = businessCard
            .map((file) => {
              if (file instanceof File) return '';
              return file.id;
            })
            .filter((id) => !!id);

          const businessCardRemovedIds = business_card
            ?.filter((file) => businessCardIds.includes(file.id))
            .map((file) => file.id);

          if (businessCardRemovedIds.length === 0 && !businessCard.length) {
            data.append(`p_application_header[business_card][]`, '');
          } else {
            businessCardRemovedIds.forEach((file) => {
              data.append(`p_application_header[business_card][]`, file);
            });
          }
        }
        //STEP 2 IMAGES
        residenceFile.forEach((file) => {
          if (file instanceof File) {
            return data.append(
              'p_application_header[p_applicant_people_attributes][0][residence_file][]',
              file
            );
          } else {
            if (!application_number) {
              return data.append(
                'p_application_header[p_applicant_people_attributes][0][residence_file][]',
                file.id
              );
            }
          }
        });

        residenceFileBackImage.forEach((file) => {
          if (file instanceof File) {
            return data.append(
              'p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]',
              file
            );
          } else {
            if (!application_number) {
              return data.append(
                'p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]',
                file.id
              );
            }
          }
        });

        //STEP 2 INCOME IMAGES
        residenceIncomeFile.forEach((file) => {
          if (file instanceof File) {
            return data.append(
              'p_application_header[p_applicant_people_attributes][1][residence_file][]',
              file
            );
          } else {
            if (!application_number) {
              return data.append(
                'p_application_header[p_applicant_people_attributes][1][residence_file][]',
                file.id
              );
            }
          }
        });

        residenceIncomeFileBackImage.forEach((file) => {
          if (file instanceof File) {
            return data.append(
              'p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]',
              file
            );
          } else {
            if (!application_number) {
              return data.append(
                'p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]',
                file.id
              );
            }
          }
        });

        //STEP 4 IMAGES
        propertyInformationFile.forEach((file) => {
          if (file instanceof File) {
            return data.append(
              'p_application_header[property_information_file][]',
              file
            );
          } else {
            if (!application_number) {
              return data.append(
                'p_application_header[property_information_file][]',
                file.id
              );
            }
          }
        });

        //STEP 7 IMAGES
        Object.keys(imageStepSeven).forEach((key) => {
          get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
            const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
              (item) => item === key
            );
            if (file instanceof File) {
              isNotUploadMulti
                ? data.append(
                    `p_application_header[p_applicant_people_attributes][0][${key}]`,
                    file
                  )
                : data.append(
                    `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                    file
                  );
            } else {
              if (!application_number) {
                isNotUploadMulti
                  ? data.append(
                      `p_application_header[p_applicant_people_attributes][0][${key}]`,
                      file.id
                    )
                  : data.append(
                      `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                      file.id
                    );
              }
            }
          });
        });
        if (incomeTotalizerData) {
          Object.keys(imageStepSevenIncomeTotal).forEach((key) => {
            get(imageStepSevenIncomeTotal, key, []).forEach(
              (file: File | SPImage) => {
                const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
                  (item) => item === key
                );
                if (file instanceof File) {
                  isNotUploadMulti
                    ? data.append(
                        `p_application_header[p_applicant_people_attributes][1][${key}]`,
                        file
                      )
                    : data.append(
                        `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                        file
                      );
                } else {
                  if (!application_number) {
                    isNotUploadMulti
                      ? data.append(
                          `p_application_header[p_applicant_people_attributes][1][${key}]`,
                          file.id
                        )
                      : data.append(
                          `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                          file.id
                        );
                  }
                }
              }
            );
          });
        }
        //STEP 8 IMAGES
        businessCard.forEach((file) => {
          if (file instanceof File) {
            return data.append('p_application_header[business_card][]', file);
          } else {
            if (!application_number) {
              return data.append(
                'p_application_header[business_card][]',
                file.id
              );
            }
          }
        });

        //hack remove residence_file_back_image
        data.delete(
          'p_application_header[p_applicant_people_attributes][0][residence_file_back_image]'
        );
        data.delete(
          'p_application_header[p_applicant_people_attributes][1][residence_file_back_image]'
        );

        if (!!paramData.stepOneData) {
          data.append(
            `p_application_header[loan_type]`,
            paramData.stepOneData.p_application_header.loan_type
          );
          // override of stp five
          data.append(
            `p_application_header[is_home_loan_plus]`,
            String(paramData.stepOneData.p_application_header.is_home_loan_plus)
          );
        }

        const sbiId = p_application_banks_attributes.find(
          (bank) => bank.name === SBI_NAME
        )?.id;

        if (!!sbiId) data.append(`p_application_header[sbi_id]`, sbiId);

        const checkListImages: Array<File | SPImage> = [
          ...insuranceFile,
          ...insuranceFileBackImage,
          ...financialStatement1File,
          ...financialStatement2File,
          ...financialStatement3File,
          ...firstWithholdingSlipFile,
          ...secondWithholdingSlipFile,
          ...otherDocumentFile,
          ...employmentAgreementFile,
          ...businessTaxReturn1File,
          ...businessTaxReturn2File,
          ...businessTaxReturn3File,
          ...firstIncomeFile,
          ...secondIncomeFile,
          ...thirdIncomeFile,
          ...residenceFile,
          ...residenceFileBackImage,
          ...residenceIncomeFile,
          ...residenceIncomeFileBackImage,
          ...businessCard,
          ...driverLicenseFrontImage,
          ...driverLicenseBackImage,
          ...cardNumberFrontImage,
          ...cardNumberBackImage,
          ...residentRegisterFrontImage,
          ...residentRegisterBackImage,
          ...propertyInformationFile,
          ...insuranceFile2,
          ...insuranceFileBackImage2,
          ...financialStatement1File2,
          ...financialStatement2File2,
          ...financialStatement3File2,
          ...firstWithholdingSlipFile2,
          ...secondWithholdingSlipFile2,
          ...otherDocumentFile2,
          ...employmentAgreementFile2,
          ...businessTaxReturn1File2,
          ...businessTaxReturn2File2,
          ...businessTaxReturn3File2,
          ...firstIncomeFile2,
          ...secondIncomeFile2,
          ...thirdIncomeFile2,
          ...driverLicenseFrontImage2,
          ...driverLicenseBackImage2,
          ...cardNumberFrontImage2,
          ...cardNumberBackImage2,
          ...residentRegisterFrontImage2,
          ...residentRegisterBackImage2,
        ];

        repaymentScheduleImage.forEach((repaymentSchedule) => {
          repaymentSchedule.forEach((image) => {
            checkListImages.push(image);
          });
        });

        const checkFiles = await checkingExistFile(checkListImages);
        if (!checkFiles) {
          SetImageModal(true);
          DisabledButton(false);
          if (!!SetReset) {
            SetReset(Math.random() * 10);
          }
        }

        const result = await dispatch(updatePreliminary(data));
        if (updatePreliminary.fulfilled.match(result)) {
          dispatch(
            addStatus({
              firstElement:
                result.payload.data.general_income_confirmation ===
                GeneralIncomeConfirmation.CONFIRM
                  ? true
                  : undefined,
              secondElement:
                result.payload.data.general_income_confirmation ===
                GeneralIncomeConfirmation.CONFIRM
                  ? true
                  : undefined,
              isReseted:
                result.payload.data.general_income_confirmation ===
                GeneralIncomeConfirmation.NOT_CONFIRM,
            })
          );
          DisabledButton(false);
          spModalMoveToTopRef.current?.open(
            '申込内容修正完了',
            '申し込みの内容を修正しました。',
            [],
            true
          );
        }
        if (updatePreliminary.rejected.match(result)) {
          const errorResponse = result.payload as CheckUpdateStatusResponse;
          const isErrorKeyNotFound = errorResponse.errors?.find(
            (error) =>
              SP_FIELDS_TABLE.includes(error.field) && error.key === 'not_found'
          );

          if (!!isErrorKeyNotFound) {
            spModalKeyNotFoundRef.current?.open();
          } else {
            const errorsField: string[] | undefined = errorResponse.errors?.map(
              (e) => {
                // @ts-ignore
                return SP_FIELDS_TITLE[e.field];
              }
            );
            if (
              errorResponse.errors?.some((e) => e.field === 'preliminary_past')
            ) {
              return (window.location.href = '/top');
            }
            if (errorsField) {
              DisabledButton(false);
              if (
                errorsField.includes(
                  SP_FIELDS_TITLE['bank_is_conducting_a_review']
                )
              ) {
                const result = await dispatch(spGetCurrentStatusResult());
                if (spGetCurrentStatusResult.fulfilled.match(result)) {
                  if (
                    statusResult === '2' &&
                    result.payload.data?.status_result === '2'
                  ) {
                    await dispatch(updateIsStatuses(true));
                  } else if (
                    statusResult === '3' ||
                    result.payload.data?.status_result === '3'
                  ) {
                    await dispatch(updateIsStatuses(false));
                  }
                  spModalMoveToTopRef.current?.open(
                    'お知らせ',
                    SP_FIELDS_TITLE['bank_is_conducting_a_review'],
                    []
                  );
                  await dispatch(
                    updateStatusResult(
                      result.payload.data?.status_result ?? null
                    )
                  );
                }
              } else {
                spModalMoveToTopRef.current?.open(
                  '申込内容修正完了',
                  '',
                  errorsField
                );
              }
            }
          }
        }
      })();
    },
    [
      application_number,
      dispatch,
      incomeTotalizer,
      spStepCollateralProviderValues,
      spStepEightValues.p_referral_agencies,
      spStepEightValues.p_referral_agency_id,
      spStepFiveValues,
      spStepFourValues.p_application_header,
      spStepFourValues.p_residents_attributes,
      spStepOneApplicantPeople,
      spStepOneValues.p_application_header,
      spStepSevenIncomeTotalValues.p_applicant_people,
      spStepSevenValues.p_applicant_people,
      spStepSixValues.p_application_header,
      spStepThreeIncomeTotalValues.p_applicant_people,
      spStepThreeValues.p_applicant_people,
      spStepTwoIncomeTotalValues.p_applicant_people,
      spStepTwoIncomeTotalValues.p_applicant_people_id,
      spStepTwoIncomeTotalValues.status?.isReseted,
      spStepTwoValues.p_applicant_people,
    ]
  );
  return {
    updateFormOverride,
    checkUpdateFormManager,
    checkUpdateFormSalePerson,
  };
};
