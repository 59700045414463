import { FC, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { hiddenScrollBar } from 'styles/toolkit';
import { Icons } from 'components';
import { statusNote, StatusType } from 'constant';

export type StatusProps = {
  currentStatus?: string;
};

export const StatusStep: FC<StatusProps> = ({ currentStatus }) => {
  const getStyles = useCallback(
    (step: StatusType) => {
      if (step === currentStatus) {
        return {
          borderColor: '#6671E1',
          color: 'blue07',
          iconColor: 'line_gray',
        };
      }

      return {
        borderColor: '#EEEEEE',
        color: 'main_white',
        iconColor: 'line_gray',
      };
    },
    [currentStatus]
  );

  return (
    <Stack
      direction="row"
      spacing="3px"
      sx={{
        flex: 1,
        bgcolor: 'transparent',
        ...hiddenScrollBar,
      }}
    >
      {steps.map((step, index) => {
        const { borderColor, color } = getStyles(step);

        return (
          <Stack
            key={index}
            sx={{ position: 'relative' }}
            justifyContent="center"
          >
            <Icons.StatusStep
              sx={{
                color,
                bgcolor: 'transparent',
                stroke: borderColor,
              }}
            />
            <Typography
              variant="status_note"
              color="a5"
              sx={{
                position: 'absolute',
                top: '-35px',
                left: '50%',
                transform: 'translate(-50%, 0)',
              }}
            >
              {statusNote[step]}
            </Typography>
            <Stack
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
              }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Typography
                variant="text_step_admin"
                color="normal_text"
                sx={{
                  ...(step === StatusType.NOTIFICATION_EXAMINATION_RESULTS &&
                    step === currentStatus && {
                      textDecoration: 'underline',
                      color: 'link_text',
                    }),
                }}
              >
                {step}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

const steps = [
  StatusType.DOCUMENT_CHECK,
  StatusType.DOCUMENT_DEFICIENCIES_IN_PROGRESS,
  StatusType.UNDER_REVIEW,
  StatusType.NOTIFICATION_EXAMINATION_RESULTS,
];
