import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  GetUploadedFilesError,
  GetUploadedFilesManagerRequest,
  GetViewUploadedFilesResponse,
  ReferralAgencyAdminOptionResponse,
  applicationsService,
  getReferralAgencyOptionError,
  getReferralAgencyOptionRequest,
  logEditResponse,
  logEditedError,
  logEditedRequest,
  salePersonPreliminary,
} from 'services';
import { getReferralAgencyAdminOptionsSalePerson } from 'services/salePersonPreliminary';
import {
  EditSalePersonPreliminariesRequest,
  EditSalePersonPreliminariesResponse,
  SalePersonPreliminariesError,
  SalePersonPreliminariesRequest,
  SalePersonPreliminariesResponse,
} from 'services/schemas/salePersonPreliminary';
import {
  CheckDatabaseSyncRequest,
  CheckDatabaseSyncResponse,
  SubmitFormError,
  ThunkAPI,
  UpdateFilesPreliminariesRequest,
  UpdatePreliminariesError,
  UpdatePreliminariesResponse,
} from 'types';

export const getPersonalInformationsSalePerson = createAsyncThunk<
  SalePersonPreliminariesResponse,
  SalePersonPreliminariesRequest,
  ThunkAPI<SalePersonPreliminariesError>
>(
  'SalePersonPreliminaries/getPersonalInformation',
  async (data, { rejectWithValue }) => {
    try {
      return await salePersonPreliminary.getSalePersonPreliminaries(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLinkPersonalInformations = createAsyncThunk<
  SalePersonPreliminariesResponse,
  SalePersonPreliminariesRequest,
  ThunkAPI<SalePersonPreliminariesError>
>(
  'SalePersonPreliminaries/getLinkPersonalInformations',
  async (data, { rejectWithValue }) => {
    try {
      return await salePersonPreliminary.getSalePersonPreliminaries(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUploadedFilesSalePerson = createAsyncThunk<
  GetViewUploadedFilesResponse,
  GetUploadedFilesManagerRequest,
  ThunkAPI<GetUploadedFilesError>
>(
  'SalePersonPreliminaries/getUploadedFiles',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getUploadedFilesSalePerson(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUploadedFilesSalePerson = createAsyncThunk<
  UpdatePreliminariesResponse,
  UpdateFilesPreliminariesRequest,
  ThunkAPI<UpdatePreliminariesError>
>(
  'SalePersonPreliminary/updateUploadedFiles',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } =
        await applicationsService.updateUploadedFilesSalePerson(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLogEditedSalePersonPreliminary = createAsyncThunk<
  logEditResponse,
  logEditedRequest,
  ThunkAPI<logEditedError>
>(
  'AdminPreliminaries/getLogEditedSalePersonPreliminary',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await salePersonPreliminary.getLogEditedSalePersonPreliminary(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editSalePersonPreliminary = createAsyncThunk<
  EditSalePersonPreliminariesResponse,
  EditSalePersonPreliminariesRequest,
  ThunkAPI<SalePersonPreliminariesError>
>(
  'AdminPreliminaries/editSalePersonPreliminary',
  async (data, { rejectWithValue }) => {
    try {
      const response = await salePersonPreliminary.editSalePersonPreliminaries(
        data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getReferralAgencyOptionsSalePerson = createAsyncThunk<
  ReferralAgencyAdminOptionResponse,
  getReferralAgencyOptionRequest,
  ThunkAPI<getReferralAgencyOptionError>
>('Admin/getReferralAgencyOptionsAction', async (data, { rejectWithValue }) => {
  try {
    return await getReferralAgencyAdminOptionsSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const checkDatabaseSyncSalePerson = createAsyncThunk<
  CheckDatabaseSyncResponse,
  CheckDatabaseSyncRequest,
  ThunkAPI<SubmitFormError>
>('SalePerson/CheckDatabaseSync', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.checkDatabaseSyncSalePerson(data);
    return response;
  } catch (error) {
    const err = (error as AxiosError<SubmitFormError>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});
