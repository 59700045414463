import { FC, useCallback, useMemo, useState } from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FastField, FastFieldProps, useField } from 'formik';
import { Button, Icons } from 'components';
import { get, isEqual, xor } from 'lodash';
import { Popover } from '../../Popover';

export type CheckboxMultipleButtonProps = Omit<ButtonProps, 'form'> & {
  name: string;
  options: { name: string; id: number | string; note?: string }[];
  limitItem?: number;
  itemPerRow?: number;
};

export const CheckboxMultipleButtonTemp: FC<
  FastFieldProps<unknown[]> & CheckboxMultipleButtonProps
> = ({
  field,
  meta,
  form,
  name,
  options,
  limitItem,
  itemPerRow = 3,
  children,
  sx = {},
  ...props
}) => {
  const [isShowed, setIsShowed] = useState(false);

  const showedOptions = useMemo(
    () => (isShowed ? options : options.slice(0, limitItem)),
    [isShowed, limitItem, options]
  );

  const handleSelect = useCallback(
    (opt: { name: string; id: number | string }) => {
      form.setFieldValue(name, xor(field.value, [opt.id]));
    },
    [field.value, form, name]
  );

  const getStyles = useCallback(
    (opt: number | string) => {
      return field.value.includes(opt)
        ? {
            bgcolor: 'h_blugreen',
            color: 'main_white',
            fontWeight: '700',
            startIcon: <Icons.Checked />,
          }
        : {
            bgcolor: !!meta.error && meta.touched ? 'bg_error' : 'main_white',
            color: 'normal_text',
            fontWeight: '400',
            startIcon: <Icons.UnChecked />,
          };
    },
    [field.value, meta.error, meta.touched]
  );

  return (
    <Stack spacing={7}>
      <Grid container spacing={7}>
        <input type="hidden" name={field.name} />
        {showedOptions.map((opt, idx) => {
          const { color, bgcolor, fontWeight, startIcon } = getStyles(opt.id);
          return (
            <Grid
              key={idx}
              item
              {...{ mobile: 12, md: isShowed ? 12 : 12 / itemPerRow }}
            >
              <Popover
                content={meta.error ?? ''}
                contentProps={{ color: 'main_white' }}
                open={
                  meta.touched && !!meta.error && options.indexOf(opt) === 0
                }
                placement="top-end"
                arrowSx={{
                  borderColor: 'sh_red',
                  bgcolor: 'sh_red',
                }}
                sx={{
                  top: -5,
                  right: 0,
                  maxWidth: 250,
                  borderColor: 'sh_red',
                  bgcolor: 'sh_red',
                }}
              >
                <MuiButton
                  onClick={() => handleSelect(opt)}
                  {...props}
                  sx={{
                    borderRadius: 1,
                    minHeight: 60,
                    boxShadow: 3,
                    bgcolor,
                    color,
                    ...sx,
                    '&:hover': {
                      // @ts-ignore
                      color: sx?.color ?? color,
                      // @ts-ignore
                      bgcolor: sx?.bgcolor ?? bgcolor,
                      opacity: { mobile: 1, desktop: 0.8 },
                    },
                    '.MuiButton-startIcon': {
                      position: 'absolute',
                      left: 16,
                      top: 20,
                    },
                  }}
                >
                  <Stack direction="row">
                    <Stack sx={{ position: 'absolute', top: 17, left: 8 }}>
                      {startIcon}
                    </Stack>
                    <Stack
                      justifyContent="center"
                      spacing={3}
                      direction="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="checkbox_button_title"
                        sx={{
                          textTransform: 'none',
                          fontWeight,
                        }}
                      >
                        {opt.name}
                      </Typography>
                      {!isShowed && !!opt.note && (
                        <Typography
                          variant="text_note"
                          sx={{
                            textAlign: 'left',
                            color,
                            px: 3,
                            display: { mobile: 'none', tablet: 'block' },
                          }}
                        >
                          {opt.note}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </MuiButton>
              </Popover>
            </Grid>
          );
        })}
      </Grid>
      {limitItem && (
        <Button
          sx={{
            minHeight: 60,
            bgcolor: 'main_white',
            width: '100%',
            borderRadius: 1,
          }}
          onClick={() => setIsShowed((prevIsShowed) => !prevIsShowed)}
        >
          <Stack direction="row" alignItems="center" spacing={6}>
            <Typography variant="checkbox_button_title" color="normal_text">
              その他の銀行
            </Typography>
            {!isShowed ? (
              <Icons.Plus sx={{ width: 16, height: 16 }} />
            ) : (
              <Icons.Minus sx={{ width: 16, height: 16 }} />
            )}
          </Stack>
        </Button>
      )}
    </Stack>
  );
};

export const CheckboxMultipleButton: FC<CheckboxMultipleButtonProps> = ({
  name,
  options,
  ...props
}) => {
  const [, meta] = useField(name);
  const { error, touched } = meta;

  const shouldUpdate = useCallback(
    (
      nextProps: {
        options: {
          name: string;
          id: number | string;
          note?: string;
        }[];
        error: boolean;
        touched: boolean;
      },
      currentProps: {
        options: {
          name: string;
          id: number | string;
          note?: string;
        }[];
        error: boolean;
        touched: boolean;
      }
    ) =>
      !isEqual(nextProps.options, currentProps.options) ||
      nextProps.error !== currentProps.error ||
      nextProps.touched !== currentProps.touched ||
      get(nextProps, `formik.values.${name}`) !==
        get(currentProps, `formik.values.${name}`),
    [name]
  );
  return (
    <FastField
      name={name}
      shouldUpdate={shouldUpdate}
      options={options}
      error={!!error}
      touched={!!touched}
    >
      {({ form, field, meta }: FastFieldProps<unknown[]>) => (
        <CheckboxMultipleButtonTemp
          {...props}
          name={name}
          options={options}
          form={form}
          field={field}
          meta={meta}
        />
      )}
    </FastField>
  );
};
