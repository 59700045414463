import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPCombinedIncomeModal: FC<ModalProps> = ({ open, onClose }) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="収入合算とは？"
      disableBackDropClick
      sxPadding="2px"
      footer={
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: 359 }}
        >
          <Stack
            sx={{ overflowY: 'scroll', maxHeight: '37vh', px: 6 }}
            spacing={2}
          >
            <Typography variant="sp_change_password_note" color="b_333">
              {`申込者本人の収入だけでは希望条件を満たせない場合等に、原則として同居している家族（収入合算者）の収入を合算するお申込方法です。収入合算者を連帯保証人とさせていただきます。`}
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              {`●収入合算者に指定できるかたは、次の条件を全て満たす個人のお客さまです。\n・借入時の年齢が満18歳以上満65歳以下のかた\n・安定継続した収入があること\n・申込者の親（配偶者の親を含む）・子・配偶者（婚約者も含む）・兄弟（配偶者の親を含む）などの親族で、申込者と同居（予定も含む）のかた`}
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              {`●収入合算できる金額は、収入合算者の収入の50%を限度とします。\n※ ペアローンの相手を収入合算者にはできません。\n※ 婚約者と収入合算する場合は、原則としてお借入日より前に入籍いただく必要があります。`}
            </Typography>{' '}
            <Typography variant="sp_change_password_note" color="b_333">
              {`●具体例：夫婦で3000万円のお借入れのお申込みをする場合\n・主債務者：夫\n・連帯保証人（収入合算者）：妻\n・借入金額：3000万円\n・団体信用生命保険：夫のみ加入\n・住宅ローン控除の対象：夫のみ\n・住宅ローン契約は一つ。`}
            </Typography>
            <Typography variant="sp_change_password_note" color="b_333">
              {`※団体信用生命保険への加入は主債務者のかたのみとなるので（この場合は夫）、連帯保証人のかたが死亡したり保険対象の高度障害などになった場合でも保険金が支払われません。\n※連帯保証人のかたは、住宅ローン控除を受けることができません。`}
            </Typography>
          </Stack>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};
