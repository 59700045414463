import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  CheckResetTokenError,
  CheckResetTokenRequest,
  CheckResetTokenResponse,
  ResetPasswordError,
  ResetPasswordResponse,
  ResetPasswordSalePersonRequest,
  UpdatePasswordError,
  UpdatePasswordResponse,
  UpdatePasswordSalePersonRequest,
} from 'services';
import { ThunkAPI } from 'types';

export const resetPasswordSalePerson = createAsyncThunk<
  ResetPasswordResponse,
  ResetPasswordSalePersonRequest,
  ThunkAPI<ResetPasswordError>
>(
  'authModal/resetPasswordSalePerson',
  async (salePerson, { rejectWithValue }) => {
    try {
      return await authService.resetPasswordSalePerson(salePerson);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkResetTokenSalePerson = createAsyncThunk<
  CheckResetTokenResponse,
  CheckResetTokenRequest,
  ThunkAPI<CheckResetTokenError>
>('authModal/checkResetTokenSalePerson', async (user, { rejectWithValue }) => {
  try {
    return await authService.checkResetTokenSalePerson(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePasswordSalePerson = createAsyncThunk<
  UpdatePasswordResponse,
  UpdatePasswordSalePersonRequest,
  ThunkAPI<UpdatePasswordError>
>('authModal/updatePasswordSalePerson', async (user, { rejectWithValue }) => {
  try {
    return await authService.updatePasswordSalePerson(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});
