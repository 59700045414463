import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep09Checked: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="19"
        viewBox="0 0 24 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.39032 4.76719C3.25297 4.90312 3.03407 4.9125 2.885 4.78594L0.635005 2.91094C0.475817 2.78062 0.454348 2.54391 0.586911 2.385C0.71938 2.22562 0.956098 2.20453 1.115 2.33672L3.10204 3.99375L6.98282 0.109687C7.13282 -0.0365625 7.36719 -0.0365625 7.51719 0.109687C7.6625 0.256406 7.6625 0.493594 7.51719 0.640312L3.39032 4.76719ZM3.39032 12.2672C3.25297 12.4031 3.03407 12.4125 2.885 12.2859L0.635005 10.4109C0.475817 10.2797 0.454348 10.0453 0.586911 9.88594C0.71938 9.72656 0.956098 9.70312 1.115 9.83906L3.10204 11.4938L6.98282 7.60781C7.13282 7.4625 7.36719 7.4625 7.51719 7.60781C7.6625 7.75781 7.6625 7.99219 7.51719 8.14219L3.39032 12.2672ZM9.5 2.58281C9.5 2.41781 9.66875 2.20781 9.875 2.20781H23.375C23.5813 2.20781 23.75 2.41781 23.75 2.58281C23.75 2.83125 23.5813 2.95781 23.375 2.95781H9.875C9.66875 2.95781 9.5 2.83125 9.5 2.58281ZM9.5 10.0828C9.5 9.91875 9.66875 9.70781 9.875 9.70781H23.375C23.5813 9.70781 23.75 9.91875 23.75 10.0828C23.75 10.3313 23.5813 10.4578 23.375 10.4578H9.875C9.66875 10.4578 9.5 10.3313 9.5 10.0828ZM8 17.625C8 17.4188 8.16875 17.25 8.375 17.25H23.375C23.5813 17.25 23.75 17.4188 23.75 17.625C23.75 17.8312 23.5813 18 23.375 18H8.375C8.16875 18 8 17.8312 8 17.625ZM3.125 18.375C2.71063 18.375 2.375 18.0375 2.375 17.625C2.375 17.2125 2.71063 16.875 3.125 16.875C3.53938 16.875 3.875 17.2125 3.875 17.625C3.875 18.0375 3.53938 18.375 3.125 18.375Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
