import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Trash: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="-2 -2 17 15" {...props}>
      <svg
        width="12"
        height="12"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.87451 15.9995C2.42451 15.9995 2.04118 15.8412 1.72451 15.5245C1.40785 15.2078 1.24951 14.8245 1.24951 14.3745V1.99949H0.249512V0.999487H4.24951V0.224487H10.2495V0.999487H14.2495V1.99949H13.2495V14.3745C13.2495 14.8412 13.0955 15.2288 12.7875 15.5375C12.4788 15.8455 12.0912 15.9995 11.6245 15.9995H2.87451ZM12.2495 1.99949H2.24951V14.3745C2.24951 14.5578 2.30785 14.7078 2.42451 14.8245C2.54118 14.9412 2.69118 14.9995 2.87451 14.9995H11.6245C11.7912 14.9995 11.9368 14.9368 12.0615 14.8115C12.1868 14.6868 12.2495 14.5412 12.2495 14.3745V1.99949ZM5.04951 12.9995H6.04951V3.99949H5.04951V12.9995ZM8.44951 12.9995H9.44951V3.99949H8.44951V12.9995ZM2.24951 1.99949V14.9995V14.3745V1.99949Z"
          fill="#E54E75"
        />
      </svg>
    </SvgIcon>
  );
};
