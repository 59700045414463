import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import {
  InputField,
  Button,
  FormItem,
  RadioGroupButton,
  InputDate,
  UlStyle,
  InputSelect,
  InputDateTime,
  InputSelectProps,
  CheckboxMultipleButton,
} from 'components';
import { useAppDispatch } from 'hooks';
import { dayjs } from 'libs';
import {
  OccupationType,
  LeaveType,
  OccupationDetailType,
  DispatchType,
  JobChangeType,
  FinalTaxReturnType,
  finalTaxReturn,
  FileATaxReturnType,
  dispatch,
  jobChange,
  WorkIndustryType,
  Placeholder,
} from 'constant';
import {
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  convertToFullWidth,
  zeroPad,
} from 'utils';

import { StepSynthesisForm } from 'types';
import { debounce, get } from 'lodash';
import { toast } from 'react-toastify';
import { PostalCode } from 'services';
import { useSelector } from 'react-redux';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { getZipCloudAddress } from '../../StepRequiredInformation/thunk';
import { checkMCJ } from '../selectors';

type RadioItems = {
  value: string;
  label: string;
};

type InputProcurementType = {
  last_year_income: string;
  bonus_income: string;
  pension_income: string;
  solar_power_income: string;
  business_income: string;
};

const StepThreePageEOther: FC = () => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext<StepSynthesisForm>();

  const isMCJ = useSelector(checkMCJ);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);

  useEffect(() => {
    if (
      values.pair_p_applicant_person.maternity_paternity_leave_status ===
        LeaveType.IT_HAS_BEEN_ACQUIRED ||
      values.pair_p_applicant_person.maternity_paternity_leave_status ===
        LeaveType.NOT_APPLICABLE
    ) {
      setFieldValue(
        'pair_p_applicant_person.maternity_paternity_leave_start_time',
        ''
      );
      setFieldValue(
        'pair_p_applicant_person.maternity_paternity_leave_end_time',
        ''
      );
      setFieldValue('pair_p_applicant_person.back_to_work_date', '');
    }
    if (
      values.pair_p_applicant_person.transfer_office === DispatchType.NOTHING
    ) {
      setFieldValue('pair_p_applicant_person.transfer_office_name_kanji', '');
      setFieldValue('pair_p_applicant_person.transfer_office_name_kana', '');
      setFieldValue('pair_p_applicant_person.transfer_office_phone_number', '');
      setFieldValue('pair_p_applicant_person.transfer_office_postal_code', '');
      setFieldValue(
        'pair_p_applicant_person.transfer_office_prefecture_kanji',
        ''
      );
      setFieldValue('pair_p_applicant_person.transfer_office_city_kanji', '');
      setFieldValue(
        'pair_p_applicant_person.transfer_office_district_kanji',
        ''
      );
      setFieldValue(
        'pair_p_applicant_person.transfer_office_other_address_kanji',
        ''
      );
      setFieldValue(
        'pair_p_applicant_person.transfer_office_prefecture_kana',
        ''
      );
      setFieldValue('pair_p_applicant_person.transfer_office_city_kana', '');
      setFieldValue(
        'pair_p_applicant_person.transfer_office_district_kana',
        ''
      );
      setFieldValue(
        'pair_p_applicant_person.transfer_office_other_address_kana',
        ''
      );
    }
    if (values.pair_p_applicant_person.job_change === JobChangeType.NOTHING) {
      setFieldValue(
        'pair_p_applicant_person.job_change_company_name_kanji',
        ''
      );
      setFieldValue('pair_p_applicant_person.job_change_company_name_kana', '');
      setFieldValue('pair_p_applicant_person.prev_company_year_num', '');
      setFieldValue('pair_p_applicant_person.prev_company_month_num', '');
      setFieldValue('pair_p_applicant_person.prev_company_industry', '');
    }
    if (
      !values.pair_p_applicant_person.type_tax_return.includes(
        FileATaxReturnType.OTHERS
      )
    ) {
      setFieldValue('pair_p_applicant_person.other_type_tax_return', '');
    }
    if (
      values.pair_p_applicant_person.prev_company_industry !==
      WorkIndustryType.OTHERS
    ) {
      setFieldValue('pair_p_applicant_person.other_prev_company_industry', '');
    }
    if (
      values.pair_p_applicant_person.occupation_detail !==
      OccupationDetailType.OTHERS
    ) {
      setFieldValue('pair_p_applicant_person.other_occupation_detail', '');
    }
    if (values.pair_p_applicant_person.occupation !== OccupationType.OTHERS) {
      setFieldValue('pair_p_applicant_person.other_occupation', '');
    }
    if (values.pair_p_applicant_person.industry !== WorkIndustryType.OTHERS) {
      setFieldValue('pair_p_applicant_person.other_industry', '');
    }
  }, [
    values.pair_p_applicant_person.maternity_paternity_leave_status,
    values.pair_p_applicant_person.transfer_office,
    values.pair_p_applicant_person.job_change,
    values.pair_p_applicant_person.type_tax_return,
    values.pair_p_applicant_person.prev_company_industry,
    values.pair_p_applicant_person.occupation_detail,
    values.pair_p_applicant_person.occupation,
    values.pair_p_applicant_person.industry,
    setFieldValue,
  ]);

  useEffect(() => {
    if (
      values.pair_p_applicant_person.tax_return === FinalTaxReturnType.NOTHING
    ) {
      setFieldValue('pair_p_applicant_person.type_tax_return', []);
    }
  }, [setFieldValue, values.pair_p_applicant_person.tax_return]);

  const {
    last_year_income,
    bonus_income,
    pension_income,
    solar_power_income,
    business_income,
  } = values.pair_p_applicant_person;

  const debouncedFunction = useRef(
    debounce((total) => {
      setFieldValue('pair_p_applicant_person.total_last_year_income', total);
    }, 250)
  );

  const debouncedSetFieldValue = useCallback(
    // @ts-ignore
    (total) => {
      return debouncedFunction.current(total);
    },
    []
  );

  useEffect(() => {
    if (isMCJ) {
      const params: InputProcurementType = {
        last_year_income,
        bonus_income,
        pension_income,
        solar_power_income,
        business_income,
      };
      debouncedSetFieldValue(calculateTotal(params));
    }
  }, [
    last_year_income,
    bonus_income,
    pension_income,
    solar_power_income,
    business_income,
    debouncedSetFieldValue,
    isMCJ,
  ]);

  const { yearOfService, monthOfService } = useMemo(() => {
    const monthDiff = dayjs().diff(
      values.pair_p_applicant_person.employment_started_date,
      'month'
    );
    const [year, month] =
      values?.pair_p_applicant_person.employment_started_date?.split('/') || [
        '',
        '',
      ];
    if (
      !values.pair_p_applicant_person.employment_started_date ||
      monthDiff < 0 ||
      !year ||
      !month
    ) {
      return { yearOfService: 0, monthOfService: 0 };
    }
    return {
      yearOfService: Math.floor(monthDiff / 12),
      monthOfService: monthDiff % 12,
    };
  }, [values.pair_p_applicant_person.employment_started_date]);

  const handleZipCodeChange = async (name: string) => {
    const inputValue = get(values, `${name}.office_postal_code`);
    if (!isNaN(+inputValue) && inputValue.length === 7) {
      const result = await dispatch(getZipCloudAddress(inputValue));
      if (getZipCloudAddress.fulfilled.match(result)) {
        if (!result.payload.results) {
          toast.error('住所が取得できませんでした。再度入力してください。');
          setFieldValue(`${name}.office_prefecture_kanji`, '');
          setFieldValue(`${name}.office_city_kanji`, '');
          setFieldValue(`${name}.office_district_kanji`, '');
          setFieldValue(`${name}.office_prefecture_kana`, '');
          setFieldValue(`${name}.office_city_kana`, '');
          setFieldValue(`${name}.office_district_kana`, '');
          return;
        }
        setWorkAddress(result.payload.results[0], name);
      }
    }
  };

  const setWorkAddress = (postal: PostalCode, name: string) => {
    setFieldValue(`${name}.office_prefecture_kanji`, postal.address1);
    setFieldValue(`${name}.office_city_kanji`, postal.address2);
    setFieldValue(`${name}.office_district_kanji`, postal.address3);
    setFieldValue(
      `${name}.office_prefecture_kana`,
      convertToFullWidth(postal.kana1)
    );
    setFieldValue(`${name}.office_city_kana`, convertToFullWidth(postal.kana2));
    setFieldValue(
      `${name}.office_district_kana`,
      convertToFullWidth(postal.kana3)
    );
  };

  const handleSecondedZipCodeChange = async (name: string) => {
    const inputValue = get(values, `${name}.transfer_office_postal_code`);
    if (!isNaN(+inputValue) && inputValue.length === 7) {
      const result = await dispatch(getZipCloudAddress(inputValue));
      if (getZipCloudAddress.fulfilled.match(result)) {
        if (!result.payload.results) {
          toast.error('住所が取得できませんでした。再度入力してください。');
          setFieldValue(`${name}.transfer_office_prefecture_kanji`, '');
          setFieldValue(`${name}.transfer_office_city_kanji`, '');
          setFieldValue(`${name}.transfer_office_district_kanji`, '');
          setFieldValue(`${name}.transfer_office_prefecture_kana`, '');
          setFieldValue(`${name}.transfer_office_city_kana`, '');
          setFieldValue(`${name}.transfer_office_district_kana`, '');
          return;
        }
        setSecondedWorkAddress(result.payload.results[0], name);
      }
    }
  };

  const setSecondedWorkAddress = (postal: PostalCode, name: string) => {
    setFieldValue(`${name}.transfer_office_prefecture_kanji`, postal.address1);
    setFieldValue(`${name}.transfer_office_city_kanji`, postal.address2);
    setFieldValue(`${name}.transfer_office_district_kanji`, postal.address3);
    setFieldValue(
      `${name}.transfer_office_prefecture_kana`,
      convertToFullWidth(postal.kana1)
    );
    setFieldValue(
      `${name}.transfer_office_city_kana`,
      convertToFullWidth(postal.kana2)
    );
    setFieldValue(
      `${name}.transfer_office_district_kana`,
      convertToFullWidth(postal.kana3)
    );
  };

  const industryOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.industry),
    [dynamicOptions.industry]
  );

  const prevIndustryOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.prev_company_industry),
    [dynamicOptions.prev_company_industry]
  );

  const occupationOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.occupation),
    [dynamicOptions.occupation]
  );

  const occupationDetailOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.occupation_detail),
    [dynamicOptions.occupation_detail]
  );

  const incomeOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.income_source),
    [dynamicOptions.income_source]
  );

  const typeTaxReturnOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.type_tax_return),
    [dynamicOptions.type_tax_return]
  );

  const maternityStatusOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.maternity_paternity_leave_status),
    [dynamicOptions.maternity_paternity_leave_status]
  );

  const nursingCareOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nursing_leave_status),
    [dynamicOptions.nursing_leave_status]
  );

  return (
    <Stack justifyContent="center">
      <FormItem label="勤務先名" divider>
        <InputField
          name="pair_p_applicant_person.office_name_kanji"
          placeholder={Placeholder.OFFICE_NAME_KANJI}
        />
      </FormItem>

      <FormItem label="勤務先名（フリガナ）" divider>
        <InputField
          name="pair_p_applicant_person.office_name_kana"
          placeholder={Placeholder.OFFICE_NAME_KANA}
        />
      </FormItem>

      <FormItem label="勤務先郵便番号" divider note={notes.zipCode}>
        <Grid container spacing={7}>
          <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
            <InputField
              name="pair_p_applicant_person.office_postal_code"
              placeholder={Placeholder.POSTAL_CODE}
              numeric
            />
          </Grid>
          <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
            <Button
              sx={{
                width: { mobile: '100%', tablet: 187 },
                minHeight: 50,
                height: 50,
                bgcolor: 'h_blugreen',
                borderRadius: '4px',
              }}
              onClick={() => handleZipCodeChange('pair_p_applicant_person')}
            >
              <Typography
                variant="text_button_step_required"
                color="main_white"
              >
                郵便番号で検索
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </FormItem>

      <FormItem divider label="住所（漢字）" note={notes.office_address_kanji}>
        <Stack spacing={4}>
          {values.pair_p_applicant_person.office_prefecture_kanji && (
            <Typography
              variant="text_input_code"
              color="normal_text"
              sx={{ mt: '-10px' }}
            >
              {values.pair_p_applicant_person.office_prefecture_kanji +
                values.pair_p_applicant_person.office_city_kanji +
                values.pair_p_applicant_person.office_district_kanji}
            </Typography>
          )}
          <InputField
            name="pair_p_applicant_person.other_office_address_kanji"
            placeholder={Placeholder.ADDRESS_KANJI}
          />
        </Stack>
      </FormItem>

      <FormItem
        divider
        label="住所（フリガナ）"
        note={notes.office_address_kana}
      >
        <Stack spacing={4}>
          {values.pair_p_applicant_person.office_prefecture_kana && (
            <Typography
              variant="text_input_code"
              color="normal_text"
              sx={{ mt: '-10px' }}
            >
              {values.pair_p_applicant_person.office_prefecture_kana +
                values.pair_p_applicant_person.office_city_kana +
                values.pair_p_applicant_person.office_district_kana}
            </Typography>
          )}
          <InputField
            name="pair_p_applicant_person.other_office_address_kana"
            placeholder={Placeholder.ADDRESS_KANA}
            convertKatakana
            convertFullWidth
          />
        </Stack>
      </FormItem>

      <FormItem
        label="勤務先電話番号"
        required
        divider
        note={notes.workPhoneNumber}
      >
        <Grid container>
          <Grid item {...{ mobile: 12, md: 8 }}>
            <InputField
              name="pair_p_applicant_person.office_phone_number"
              placeholder={Placeholder.PHONE_NUMBER}
              numeric
            />
          </Grid>
        </Grid>
      </FormItem>

      <Stack
        direction={{ mobile: 'column', tablet: 'row' }}
        justifyContent="center"
        alignItems={{ mobile: 'baseline', tablet: 'center' }}
        spacing={8}
        sx={{ mb: { mobile: 8, tablet: 0 } }}
      >
        <Stack flex={3} maxWidth={{ mobile: 300, tablet: 500 }}>
          <FormItem
            label="勤務先入社年月"
            note={
              <UlStyle>
                <li>
                  <Typography variant="text_note" color="normal_text">
                    {notes.dateJoinCompany}
                  </Typography>
                </li>
              </UlStyle>
            }
            required
          >
            <Stack pr={{ md: 14 }}>
              <InputDate
                isSelect
                name="pair_p_applicant_person.employment_started_date"
                checkRequired
              />
            </Stack>
          </FormItem>
        </Stack>

        <Stack flex={2}>
          <Stack
            justifyContent="center"
            alignItems="center"
            direction={{ mobile: 'column', tablet: 'row' }}
            spacing={5}
            sx={{
              mb: 4,
              ml: { mobile: 5, tablet: 0 },
              height: { mobile: 90, tablet: 60 },
              bgcolor: 'main_white',
              borderRadius: '5px',
            }}
          >
            <Typography fontSize={18} variant="h2">
              勤続年数
            </Typography>
            <Stack sx={{ pt: 1 }} direction="row" spacing={4}>
              <Stack direction="row" spacing={3}>
                <Typography variant="total_text">{yearOfService}</Typography>
                <Typography fontSize={18} variant="h2">
                  年
                </Typography>
              </Stack>
              <Stack direction="row" spacing={3}>
                <Typography variant="total_text">{monthOfService}</Typography>
                <Typography fontSize={18} variant="h2">
                  ヶ月
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography
            variant="text_note"
            color="normal_text"
            sx={{ ml: { mobile: 5, tablet: 0 } }}
          >
            {notes.totalYears}
          </Typography>
        </Stack>
      </Stack>

      <Divider
        sx={{
          width: '100%',
          height: 2,
          backgroundImage: (theme) =>
            `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
          backgroundSize: '6px 9px',
          backgroundRepeat: 'repeat-x',
          borderBottomWidth: 'inherit',
        }}
      />

      <FormItem label="勤務先従業員数" required divider>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 213 } }}
            name="pair_p_applicant_person.number_of_employee"
            align="left"
            placeholder="0"
            unit="名"
            numeric
            useNumberFormat
          />
        </Stack>
      </FormItem>

      <FormItem label="部署・事業所" required divider>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 451 } }}
            name="pair_p_applicant_person.department"
            align="left"
            placeholder="例：管理部"
          />
        </Stack>
      </FormItem>

      <FormItem required divider label="業種" note={notes.industry}>
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.industry"
          options={industryOptions}
        />
      </FormItem>

      {values.pair_p_applicant_person.industry === WorkIndustryType.OTHERS && (
        <FormItem required divider label="業種（その他）">
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 719 } }}
              name="pair_p_applicant_person.other_industry"
              placeholder="ー"
            />
          </Stack>
        </FormItem>
      )}

      <FormItem label="職業" required note={notes.profession} divider>
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.occupation"
          options={occupationOptions}
        />
      </FormItem>

      {values.pair_p_applicant_person.occupation === OccupationType.OTHERS && (
        <FormItem required divider label="職業（その他）">
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 719 } }}
              name="pair_p_applicant_person.other_occupation"
              placeholder="ー"
            />
          </Stack>
        </FormItem>
      )}

      <FormItem label="職種" required note={notes.profession} divider>
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.occupation_detail"
          options={occupationDetailOptions}
        />
      </FormItem>

      {values.pair_p_applicant_person.occupation_detail ===
        OccupationDetailType.OTHERS && (
        <FormItem required divider label="職種（その他）">
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 719 } }}
              name="pair_p_applicant_person.other_occupation_detail"
              placeholder="ー"
            />
          </Stack>
        </FormItem>
      )}

      {isMCJ ? (
        <FormItem label="前年度収入について" required divider>
          <Stack spacing={9}>
            <Stack direction="row" spacing={12}>
              <Stack spacing={5}>
                <Typography variant="h3" color="normal_text">
                  前年度収入
                </Typography>
                <InputField
                  sx={{ width: { mobile: '100%', tablet: 250 } }}
                  name="pair_p_applicant_person.last_year_income"
                  align="right"
                  placeholder="0"
                  unit="円"
                  numeric
                  useNumberFormat
                />
              </Stack>
              <Stack spacing={5}>
                <Typography variant="h3" color="normal_text">
                  内、ボーナス
                </Typography>
                <InputField
                  sx={{ width: { mobile: '100%', tablet: 250 } }}
                  name="pair_p_applicant_person.bonus_income"
                  align="right"
                  placeholder="0"
                  unit="円"
                  numeric
                  useNumberFormat
                />
              </Stack>
            </Stack>
            <Stack spacing={5}>
              <Typography variant="h3" color="normal_text">
                事業所得等
              </Typography>
              <Stack direction="row">
                <InputField
                  sx={{ width: { mobile: '100%', tablet: 250 } }}
                  name="pair_p_applicant_person.business_income"
                  align="right"
                  placeholder="0"
                  unit="円"
                  numeric
                  useNumberFormat
                />
              </Stack>
            </Stack>
            <Stack spacing={5}>
              <Typography variant="h3" color="normal_text">
                年金収入等
              </Typography>
              <Stack direction="row">
                <InputField
                  sx={{ width: { mobile: '100%', tablet: 250 } }}
                  name="pair_p_applicant_person.pension_income"
                  align="right"
                  placeholder="0"
                  unit="円"
                  numeric
                  useNumberFormat
                />
              </Stack>
            </Stack>
            <Stack spacing={5}>
              <Typography variant="h3" color="normal_text">
                太陽光売買収入
              </Typography>
              <Stack direction="row">
                <InputField
                  sx={{ width: { mobile: '100%', tablet: 250 } }}
                  name="pair_p_applicant_person.solar_power_income"
                  align="right"
                  placeholder="0"
                  unit="円"
                  numeric
                  useNumberFormat
                />
              </Stack>
            </Stack>
            <Stack spacing={5}>
              <Typography variant="h3" color="normal_text">
                合計前年度収入
              </Typography>
              <Stack direction="row">
                <InputField
                  sx={{ width: { mobile: '100%', tablet: 250 } }}
                  name="pair_p_applicant_person.total_last_year_income"
                  align="right"
                  placeholder="0"
                  unit="円"
                  readOnly
                  numeric
                  useNumberFormat
                />
              </Stack>
            </Stack>
          </Stack>
        </FormItem>
      ) : (
        <FormItem label="前年度収入について" required divider>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 250 } }}
              name="pair_p_applicant_person.total_last_year_income"
              align="right"
              placeholder="0"
              unit="円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      {isMCJ && (
        <FormItem label="前々年度収入" required divider>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="pair_p_applicant_person.two_years_ago_income"
              align="right"
              placeholder="0"
              unit="円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      <FormItem label="収入源（複数選択可）" divider>
        <CheckboxMultipleButton
          sx={{
            width: '100%',
            justifyContent: 'center',
          }}
          itemPerRow={3}
          name="pair_p_applicant_person.income_source"
          options={incomeOptions}
        />
      </FormItem>

      <FormItem
        required
        divider
        label="確定申告の有無について"
        note={notes.finalTaxReturn}
      >
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.tax_return"
          options={finalTaxReturnOptions}
        />
      </FormItem>

      {values.pair_p_applicant_person.tax_return === FinalTaxReturnType.YES && (
        <FormItem
          label="確定申告の有無について（「有」の場合その理由をお選びください）"
          required
          divider
        >
          <CheckboxMultipleButton
            sx={{
              width: '100%',
              justifyContent: 'center',
            }}
            itemPerRow={3}
            name="pair_p_applicant_person.type_tax_return"
            options={typeTaxReturnOptions}
          />
        </FormItem>
      )}

      {values.pair_p_applicant_person.type_tax_return.includes(
        FileATaxReturnType.OTHERS
      ) && (
        <FormItem
          label="確定申告「有」で「その他」をお選びの方"
          required
          divider
        >
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 451 } }}
              name="pair_p_applicant_person.other_type_tax_return"
              align="left"
              placeholder="ー"
            />
          </Stack>
        </FormItem>
      )}
      <FormItem
        label="産休・育休取得状況"
        required
        divider
        note={notes.onLeave}
      >
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.maternity_paternity_leave_status"
          options={maternityStatusOptions}
        />
      </FormItem>

      {(values.pair_p_applicant_person.maternity_paternity_leave_status ===
        LeaveType.SCHEDULED_ACQUISITION ||
        values.pair_p_applicant_person.maternity_paternity_leave_status ===
          LeaveType.ACQUIRING) && (
        <Stack>
          <Divider
            sx={{
              width: '100%',
              height: 1,
              backgroundImage: (theme) =>
                `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
              backgroundSize: '6px 9px',
              backgroundRepeat: 'repeat-x',
              borderBottomWidth: 'inherit',
            }}
          />
          <FormItem required label="産休・育休取得期間" divider>
            <Stack spacing={9}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ width: { mobile: '100%', tablet: 200 } }}
              >
                <InputDateTime
                  yearOptions={yearOptions}
                  monthOptions={monthOptions}
                  name="pair_p_applicant_person.maternity_paternity_leave_start_time"
                  checkRequired
                />
                <Typography
                  variant="textstyle_date_time"
                  color="normal_text"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  から
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ width: { mobile: '100%', tablet: 200 } }}
              >
                <InputDateTime
                  yearOptions={yearOptions}
                  monthOptions={monthOptions}
                  name="pair_p_applicant_person.maternity_paternity_leave_end_time"
                  checkRequired
                />
                <Typography
                  variant="textstyle_date_time"
                  color="normal_text"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  まで
                </Typography>
              </Stack>
            </Stack>
          </FormItem>

          <FormItem required label="復帰（予定）時期" divider>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 4 }}>
                <InputDateTime
                  yearOptions={yearOptions}
                  monthOptions={monthOptions}
                  name="pair_p_applicant_person.back_to_work_date"
                  checkRequired
                />
              </Grid>
            </Grid>
          </FormItem>
        </Stack>
      )}

      {isMCJ && (
        <FormItem
          label="介護休取得状況"
          note={notes.nursing_leave_status}
          required
          divider
        >
          <RadioGroupButton
            itemPerRow={3}
            type="noImage"
            name="pair_p_applicant_person.nursing_leave_status"
            options={nursingCareOptions}
          />
        </FormItem>
      )}

      <FormItem required divider label="出向（派遣）の有無">
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.transfer_office"
          options={dispatchOptions}
        />
      </FormItem>

      {values.pair_p_applicant_person.transfer_office === DispatchType.YES && (
        <Stack>
          <Divider
            sx={{
              width: '100%',
              height: 1,
              backgroundImage: (theme) =>
                `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
              backgroundSize: '6px 9px',
              backgroundRepeat: 'repeat-x',
              borderBottomWidth: 'inherit',
            }}
          />
          <FormItem required divider label="出向（派遣）先勤務先名">
            <InputField
              name="pair_p_applicant_person.transfer_office_name_kanji"
              placeholder="株式会社MILIZE"
            />
          </FormItem>

          <FormItem required divider label="出向（派遣）先勤務先名（フリガナ）">
            <InputField
              name="pair_p_applicant_person.transfer_office_name_kana"
              placeholder="カブシキガイシャミライズ"
              convertFullWidth
            />
          </FormItem>

          <FormItem
            label="出向（派遣）先電話番号"
            required
            divider
            note={notes.zipCode}
          >
            <Grid container>
              <Grid item {...{ mobile: 12, md: 8 }}>
                <InputField
                  name="pair_p_applicant_person.transfer_office_phone_number"
                  placeholder={Placeholder.PHONE_NUMBER}
                  numeric
                />
              </Grid>
            </Grid>
          </FormItem>

          <FormItem
            required
            label="出向（派遣）先勤務先郵便番号"
            divider
            note={notes.zipCode}
          >
            <Grid container spacing={7}>
              <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                <InputField
                  name="pair_p_applicant_person.transfer_office_postal_code"
                  placeholder={Placeholder.POSTAL_CODE}
                  numeric
                />
              </Grid>
              <Grid item {...{ mobile: 12, tablet: 6, md: 4 }}>
                <Button
                  sx={{
                    width: { mobile: '100%', tablet: 187 },
                    minHeight: 50,
                    height: 50,
                    bgcolor: 'h_blugreen',
                    borderRadius: '4px',
                  }}
                  onClick={() =>
                    handleSecondedZipCodeChange('pair_p_applicant_person')
                  }
                >
                  <Typography
                    variant="text_button_step_required"
                    color="main_white"
                  >
                    郵便番号で検索
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </FormItem>

          <FormItem
            required
            divider
            label="出向（派遣）先勤務先住所"
            note={notes.office_address_kanji}
          >
            <Stack spacing={4}>
              {values.pair_p_applicant_person
                .transfer_office_prefecture_kanji && (
                <Typography
                  variant="text_input_code"
                  color="normal_text"
                  sx={{ mt: '-10px' }}
                >
                  {values.pair_p_applicant_person
                    .transfer_office_prefecture_kanji +
                    values.pair_p_applicant_person.transfer_office_city_kanji +
                    values.pair_p_applicant_person
                      .transfer_office_district_kanji}
                </Typography>
              )}
              <InputField
                name="pair_p_applicant_person.transfer_office_other_address_kanji"
                placeholder={Placeholder.TRANSFER_OFFFICE_ADDRESS_KANJI}
              />
            </Stack>
          </FormItem>

          <FormItem
            divider
            required
            label="出向（派遣）先勤務先住所　（フリガナ）"
            note={notes.office_address_kana}
          >
            <Stack spacing={4}>
              {values.pair_p_applicant_person
                .transfer_office_prefecture_kana && (
                <Typography
                  variant="text_input_code"
                  color="normal_text"
                  sx={{ mt: '-10px' }}
                >
                  {values.pair_p_applicant_person
                    .transfer_office_prefecture_kana +
                    values.pair_p_applicant_person.transfer_office_city_kana +
                    values.pair_p_applicant_person
                      .transfer_office_district_kana}
                </Typography>
              )}
              <InputField
                name="pair_p_applicant_person.transfer_office_other_address_kana"
                placeholder={Placeholder.TRANSFER_OFFFICE_ADDRESS_KANA}
                convertKatakana
                convertFullWidth
              />
            </Stack>
          </FormItem>
        </Stack>
      )}
      <FormItem required divider label="転職の有無">
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.job_change"
          options={jobChangeOptions}
        />
      </FormItem>
      {values.pair_p_applicant_person.job_change === JobChangeType.YES && (
        <Stack>
          <Divider
            sx={{
              width: '100%',
              height: 1,
              backgroundImage: (theme) =>
                `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
              backgroundSize: '6px 9px',
              backgroundRepeat: 'repeat-x',
              borderBottomWidth: 'inherit',
            }}
          />
          <FormItem required divider label="前勤務先名">
            <InputField
              name="pair_p_applicant_person.job_change_company_name_kanji"
              placeholder="株式会社MILIZE"
            />
          </FormItem>
          <FormItem required divider label="前勤務先名（フリガナ）">
            <InputField
              name="pair_p_applicant_person.job_change_company_name_kana"
              placeholder="カブシキガイシャミライズ"
              convertFullWidth
            />
          </FormItem>
          <FormItem required divider label="前勤務先勤続年月">
            <Stack direction="row" spacing={1}>
              <Stack>
                <InputSelect
                  sx={{ minHeight: 50, height: 50, width: 130 }}
                  name="pair_p_applicant_person.prev_company_year_num"
                  options={yearNumOptions}
                  placeholder="0"
                  unit="年"
                  showError={false}
                  checkRequired
                />
              </Stack>

              <Stack>
                <InputSelect
                  sx={{ minHeight: 50, height: 50, width: 130 }}
                  name="pair_p_applicant_person.prev_company_month_num"
                  options={monthOptions}
                  placeholder="0"
                  unit="ヶ月"
                  checkRequired
                />
              </Stack>
            </Stack>
          </FormItem>
          <FormItem required label="前勤務先業種" note={notes.industry}>
            <RadioGroupButton
              itemPerRow={3}
              type="noImage"
              name="pair_p_applicant_person.prev_company_industry"
              options={prevIndustryOptions}
            />
          </FormItem>
        </Stack>
      )}
      {values.pair_p_applicant_person.prev_company_industry ===
        WorkIndustryType.OTHERS && (
        <FormItem label="前勤務先業種 (その他)" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 451 } }}
              name="pair_p_applicant_person.other_prev_company_industry"
              placeholder="ー"
            />
          </Stack>
        </FormItem>
      )}
    </Stack>
  );
};

export default StepThreePageEOther;

const calculateTotal = ({
  last_year_income,
  bonus_income,
  pension_income,
  solar_power_income,
  business_income,
}: InputProcurementType) => {
  return (
    +last_year_income +
      +bonus_income +
      +pension_income +
      +solar_power_income +
      +business_income || ''
  );
};

const finalTaxReturnOptions: Array<RadioItems> = [
  {
    value: FinalTaxReturnType.YES,
    label: finalTaxReturn[FinalTaxReturnType.YES],
  },
  {
    value: FinalTaxReturnType.NOTHING,
    label: finalTaxReturn[FinalTaxReturnType.NOTHING],
  },
];

const dispatchOptions: Array<RadioItems> = [
  {
    value: DispatchType.YES,
    label: dispatch[DispatchType.YES],
  },
  {
    value: DispatchType.NOTHING,
    label: dispatch[DispatchType.NOTHING],
  },
];

const jobChangeOptions: Array<RadioItems> = [
  {
    value: JobChangeType.YES,
    label: jobChange[JobChangeType.YES],
  },
  {
    value: JobChangeType.NOTHING,
    label: jobChange[JobChangeType.NOTHING],
  },
];

const notes = {
  profession:
    '※安定継続収入がある方がご利用頂けます。\n（年金収入のみの方はご利用頂けません。）',
  occupation:
    '「勤務先を検索」ボタンを押してお勤め先を選択してください。\n入力後は「お勤め先を修正」ボタンをから変更可能です。',
  zipCode: '※ハイフンを抜かしてご入力ください。',
  workApartment:
    '住所での丁目・番地・号につきましては、漢字ではなく、数字・記号でご入力ください。\n　（例）×１丁目５番地６号\n　　　　○１－５－６',
  workPhoneNumber: '※ハイフンを抜かしてご入力ください。',
  dateJoinCompany:
    '保険証記載の資格取得日の年月をご入力ください。資格取得日と実際の勤務開始日が異なる場合、\n在籍証明書等で実際の勤務開始日を確認させていただくことがございます。',
  totalYears: '※申込日起算の勤続年数になります',
  remarks: [
    'ここまでの入力項目以外に、特に伝えるべき内容がある場合は、こちらにご入力ください。',
    'お名前欄にフルネームでの入力ができなかった場合は、こちらにフルネームをご入力ください。',
    '本項目にご入力のある場合は、詳細な内容を確認するため、当行担当者よりご連絡させていただきます。',
  ],
  onLeave: '※該当する場合にご選択ください。',
  office_address_kanji:
    '※自動入力された住所の続き（番地・建物名・部屋番号など）を入力してください。\n' +
    '（例:６－１泉マンション１０８）',
  office_address_kana:
    '※自動入力された住所の続き（番地・建物名・部屋番号など）を入力してください。\n' +
    '（例:６－１泉マンション１０８）',
  industry:
    '※安定継続収入がある方がご利用頂けます。\n' +
    '（年金収入のみの方はご利用頂けません。）',
  finalTaxReturn:
    '※確定申告をご自身で行った場合確定申告書及び法人決算書のご提出が必須となります。',
  nursing_leave_status: '※該当する場合にご選択ください。',
};

const yearNumOptions: InputSelectProps['options'] = [
  { value: '', label: '年' },
].concat(
  Array.from(Array(99), (_, index) => {
    return {
      value: zeroPad(index + 1),
      label: zeroPad(index + 1),
    };
  })
);

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(6), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);
