import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Minus: FC<SvgIconProps> = (props) => (
  <SvgIcon
    sx={{ width: 13, height: 13 }}
    viewBox="0 0 16 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="16"
      height="2"
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="1" x2="16" y2="1" stroke="#333333" />
    </svg>
  </SvgIcon>
);
