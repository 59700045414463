import { ConditionType, SituationType } from 'constant';
import { z } from 'zod';

export const applicationItemSchema = z.object({
  id: z.number(),
  proposalNumber: z.string(),
  application: z.string(),
  bankName: z.string(),
  applicant: z.string(),
  situation: z.nativeEnum(SituationType),
  loanAmount: z.string(),
  interestRate: z.string(),
  condition: z.nativeEnum(ConditionType),
  bankClerk: z.string(),
  salesTaro: z.string().optional(),
  applicationDateTime: z.string(),
  examinationDate: z.string(),
  hasError: z.boolean().optional(),
});

export const BusinessesSchema = z.object({
  id: z.number(),
  branch: z.string(),
  affiliation: z.string(),
  fullName: z.string(),
});

export const applicationResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(applicationItemSchema),
});

export const applicantItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  proposalNumber: z.string(),
  bankName: z.string(),
  applicationDate: z.string(),
  quick: z.boolean(),
  endDate: z.string(),
  loanAmount: z.number(),
  interestRate: z.string(),
  condition: z.nativeEnum(ConditionType),
  bankClerk: z.string(),
  userName: z.string(),
  startDate: z.string(),
  situation: z.nativeEnum(SituationType),
});

export const applicantRequestSchema = z.object({
  id: z.number(),
});

export const applicantResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(applicantItemSchema),
});

export const businessesResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(BusinessesSchema),
});

export const deleteBusinessesResponseSchema = z.object({
  success: z.boolean(),
  data: BusinessesSchema,
});

export const businessesRequestSchema = z.object({
  id: z.number(),
});

export type ApplicationItem = z.infer<typeof applicationItemSchema>;
export type ApplicationResponse = z.infer<typeof applicationResponseSchema>;
export type Businesses = z.infer<typeof BusinessesSchema>;
export type BusinessesResponse = z.infer<typeof businessesResponseSchema>;
export type BusinessesRequest = z.infer<typeof businessesRequestSchema>;
export type DeleteBusinessesResponse = z.infer<
  typeof deleteBusinessesResponseSchema
>;
export type ApplicantItem = z.infer<typeof applicantItemSchema>;
export type ApplicantRequest = z.infer<typeof applicantRequestSchema>;
export type ApplicantResponse = z.infer<typeof applicantResponseSchema>;
export type ApplicantError = {};
export type ApplicationError = {};
export type BusinessesError = {};
