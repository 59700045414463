import {
  AdminPreliminaryItem,
  CODEBANK,
  MANAGER_ROLE,
  PersonOccupancyType,
  PlannedCohabitantType,
  RelationshipType,
  ResidentType,
} from 'constant';
import { AdminPreliminaries, DynamicOption } from 'services';
import dayjs from 'dayjs';
import { makeArrayFromNumber } from './buildResidentsAttributes';

export function buildPersonalRows(
  rows: AdminPreliminaryItem[],
  data: AdminPreliminaries['p_application_header'],
  plannedCohabitantDynamic: DynamicOption,
  managerRole: string
): AdminPreliminaryItem[] {
  const {
    name_master_banks: nameMasterBanks,
    p_residents_attributes: residentsAttributes,
    person_occupancy: personOccupancy,
  } = data;

  const length = getHousemateNumber(plannedCohabitantDynamic, data);
  const isMCJ = nameMasterBanks?.includes(CODEBANK.MCJ);
  const isUserTyped = !!residentsAttributes.find(
    ({ resident_type }) => resident_type === ResidentType.USER
  );

  const newResidentsAttributes = buildOtherPlannedCohabitant(
    length > 6 ? 6 : length,
    isUserTyped,
    isMCJ,
    personOccupancy,
    managerRole
  );
  //remove old
  const newRows = rows.filter(
    ({ name_en }) => !name_en.includes('p_residents_attributes')
  );
  const index = newRows.findIndex(
    (row) => row.name_en === 'planned_cohabitant'
  );
  //add new
  newRows.splice(index + 1, 0, ...newResidentsAttributes);
  return newRows;
}

export function getHousemateNumber(
  plannedCohabitantDynamic: DynamicOption,
  {
    planned_cohabitant: plannedCohabitant,
    siblings_number: siblingsNumber,
    children_number: childrenNumber,
    other_people_number: otherPeopleNumber,
  }: Pick<
    AdminPreliminaries['p_application_header'],
    | 'planned_cohabitant'
    | 'siblings_number'
    | 'children_number'
    | 'other_people_number'
  >
): number {
  let length: number = 0;
  plannedCohabitantDynamic.value.forEach(({ option_code }) => {
    const optionCode = option_code.toString() as PlannedCohabitantType;
    if (!plannedCohabitant.includes(optionCode)) {
      return;
    }
    if (+optionCode === +RelationshipType.CHILD) {
      length += +childrenNumber || 0;
      return;
    }
    if (+optionCode === +RelationshipType.SIBLINGS) {
      length += +siblingsNumber || 0;
      return;
    }
    if (+optionCode === +RelationshipType.OTHERS) {
      length += +otherPeopleNumber || 0;
      return;
    }
    length += 1;
  });
  return length;
}

function buildOtherPlannedCohabitant(
  length: number,
  isUserTyped: boolean,
  isMCJ: boolean,
  personOccupancy: PersonOccupancyType,
  managerRole: string
): AdminPreliminaryItem[] {
  let newData: AdminPreliminaryItem[] = [];
  makeArrayFromNumber(length).forEach((_, index) => {
    const extendsArray: AdminPreliminaryItem[] = [
      {
        name_ja: `入居家族${
          index + 1
        } 住宅金融支援機構（旧：公庫）からの融資の有無`,
        name_en: `p_residents_attributes[${index}].loan_from_japan_housing_finance_agency`,
        dynamicType: 'select',
        dynamicOptionField: 'loan_from_japan_housing_finance_agency',
      },
      {
        name_ja: `入居家族${index + 1} 郵便番号`,
        name_en: `p_residents_attributes[${index}].postal_code`,
        dynamicType: 'inputNumber',
      },
      {
        name_ja: `入居家族${index + 1} 都道府県`,
        name_en: `p_residents_attributes[${index}].prefecture_kanji`,
        dynamicType: 'selectDistrict',
        dynamicOptionField: 'prefecture_kanji',
      },
      {
        name_ja: `入居家族${index + 1} 市区郡`,
        name_en: `p_residents_attributes[${index}].city_kanji`,
        dynamicType: 'input',
        isConvertFullWith: true,
      },
      {
        name_ja: `入居家族${index + 1} 町村丁目`,
        name_en: `p_residents_attributes[${index}].district_kanji`,
        dynamicType: 'input',
        isConvertFullWith: true,
      },
      {
        name_ja: `入居家族${index + 1} 丁目以下・建物名・部屋番号`,
        name_en: `p_residents_attributes[${index}].other_address_kanji`,
        dynamicType: 'input',
        isConvertFullWith: true,
      },
      {
        name_ja: `入居家族${index + 1} 電話番号`,
        name_en: `p_residents_attributes[${index}].home_phone_number`,
        dynamicType: 'inputNumber',
      },
      {
        name_ja: `入居家族${index + 1} 国籍`,
        name_en: `p_residents_attributes[${index}].nationality`,
        dynamicType: 'select',
        dynamicOptionField: 'nationality',
      },
    ];
    newData = [
      ...newData,
      {
        name_ja: `入居家族${index + 1} 現在申込人との同居有無`,
        name_en: `p_residents_attributes[${index}].one_roof`,
        required: true,
        dynamicType: 'select',
        dynamicOptionField: 'one_roof',
      },
      {
        name_ja: `入居家族${index + 1} 姓　漢字`,
        name_en: `p_residents_attributes[${index}].last_name_kanji`,
        condition: 'require_condition',
        required:
          index === 0
            ? !isMCJ && personOccupancy !== PersonOccupancyType.NO && length > 0
            : true,
        dynamicType: 'input',
        isConvertFullWith: true,
      },
      {
        name_ja: `入居家族${index + 1} 名　漢字`,
        name_en: `p_residents_attributes[${index}].first_name_kanji`,
        condition: 'require_condition',
        required:
          index === 0
            ? !isMCJ && personOccupancy !== PersonOccupancyType.NO && length > 0
            : true,
        dynamicType: 'input',
        isConvertFullWith: true,
      },
      {
        name_ja: `入居家族${index + 1} 姓　カナ`,
        name_en: `p_residents_attributes[${index}].last_name_kana`,
        condition: 'require_condition',
        required:
          index === 0
            ? !isMCJ && personOccupancy !== PersonOccupancyType.NO && length > 0
            : true,
        dynamicType: 'input',
        isConvertFullWith: true,
      },
      {
        name_ja: `入居家族${index + 1} 名　カナ`,
        name_en: `p_residents_attributes[${index}].first_name_kana`,
        condition: 'require_condition',
        required:
          index === 0
            ? !isMCJ && personOccupancy !== PersonOccupancyType.NO && length > 0
            : true,
        dynamicType: 'input',
        isConvertFullWith: true,
      },
      {
        name_ja: `入居家族${index + 1} 性別`,
        name_en: `p_residents_attributes[${index}].sex`,
        required: true,
        dynamicType: 'select',
        dynamicOptionField: 'sex',
      },
      {
        name_ja: `入居家族${index + 1} 続柄`,
        name_en: `p_residents_attributes[${index}].${
          index === 0 && isMCJ && personOccupancy === PersonOccupancyType.NO
            ? 'relationship_name'
            : 'relationship'
        }`,
        condition: 'require_condition',
        dynamicType:
          index === 0 && isMCJ && personOccupancy === PersonOccupancyType.NO
            ? 'input'
            : 'select',
        isConvertFullWith:
          index === 0 && isMCJ && personOccupancy === PersonOccupancyType.NO,
        dynamicOptionField:
          index === 0 && isMCJ && personOccupancy === PersonOccupancyType.NO
            ? undefined
            : 'relationship',
        subFieldName: `p_residents_attributes[${index}].other_relationship`,
        subField:
          index === 0 &&
          isMCJ &&
          personOccupancy === PersonOccupancyType.NO &&
          managerRole === MANAGER_ROLE.ADMIN
            ? {
                name_en: `p_residents_attributes[${index}].relationship`,
                dynamicType: 'select',
                dynamicOptionField: 'relationship',
                subFieldName: `p_residents_attributes[${index}].other_relationship`,
              }
            : undefined,
      },
      {
        name_ja: `入居家族${index + 1} 生年月日`,
        name_en: `p_residents_attributes[${index}].birthday`,
        required:
          index === 0
            ? !isMCJ && personOccupancy !== PersonOccupancyType.NO && length > 0
            : true,
        dynamicType: 'date',
        options: {
          minDate: dayjs().subtract(100, 'y').toString(),
          maxDate: dayjs().toString(),
        },
      },
      ...(index === 0 ? extendsArray : []),
    ].filter(({ name_en }) => {
      if (
        [
          `p_residents_attributes[${index}].nationality`,
          `p_residents_attributes[${index}].loan_from_japan_housing_finance_agency`,
          `p_residents_attributes[${index}].home_phone_number`,
          `p_residents_attributes[${index}].postal_code`,
          `p_residents_attributes[${index}].prefecture_kanji`,
          `p_residents_attributes[${index}].city_kanji`,
          `p_residents_attributes[${index}].district_kanji`,
          `p_residents_attributes[${index}].other_address_kanji`,
        ].includes(name_en) &&
        !(isMCJ && personOccupancy === PersonOccupancyType.NO)
      ) {
        return false;
      }
      return true;
    }) as AdminPreliminaryItem[];
  });
  return newData;
}
