import {
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Stack,
  Typography,
  IconButton,
  TableCell,
  Divider,
} from '@mui/material';
import { ContactInformationItems, Icons } from 'components';
import { FC, MouseEvent } from 'react';
import { ContactItem } from 'services';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, OrderType, TableProps } from 'types';

export const ContactInformationTable: FC<TableProps<ContactItem>> = ({
  onRequestSort,
  order,
  orderBy,
  rows,
  isAdmin,
}) => {
  const createSortHandler =
    (property: keyof ContactItem) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <TableHead
        sx={{
          display: 'flex',
          minWidth: 1135,
          boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
        }}
      >
        <TableRow
          sx={{
            display: 'flex',
            flex: 1,
            marginBottom: '10px',
            boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            pr: isAdmin ? '114px' : 0,
          }}
        >
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sx={{
                height: 40,
                p: 0,
                alignItems: 'center',
                textAlign: 'center',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                border: 'none',
                ...headCell.style,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="table_header_text"
                  sx={{ whiteSpace: 'nowrap' }}
                  color="normal_text"
                >
                  {headCell.label}
                </Typography>
                {headCell.smallLabel && (
                  <Typography
                    variant="table_small_text"
                    sx={{ whiteSpace: 'nowrap' }}
                    color="normal_text"
                  >
                    {headCell.smallLabel}
                  </Typography>
                )}
                <SortTable
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                />
              </Stack>
              {(headCell !== headCells[headCells.length - 1] || isAdmin) && (
                <Divider
                  sx={{
                    color: 'gray',
                    right: 0,
                    top: '25%',
                    position: 'absolute',
                    height: '50%',
                  }}
                  orientation="vertical"
                />
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <Stack
        sx={{
          bgcolor: 'bg_off',
          minWidth: 1116,
          overflowX: 'auto',
          height: 'calc(100vh - 109px)',
          ...styleScrollBarTable,
        }}
      >
        <ContactInformationItems data={rows} isAdmin={isAdmin} />
      </Stack>
    </TableContainer>
  );
};

type SortTableProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const SortTable: FC<SortTableProps> = ({ active, direction, onClick }) => (
  <IconButton sx={{ p: 1 }} onClick={onClick}>
    {direction === 'asc' ? (
      <Icons.SortUp
        sx={{
          width: 10,
          height: 10,
          color: active ? 'h_blugreen' : 'transparent',
        }}
      />
    ) : (
      <Icons.SortDown sx={{ width: 10, height: 10 }} />
    )}
  </IconButton>
);

const headCells: HeadCellProps<ContactItem>[] = [
  {
    id: 'created_at',
    label: '日付',
    style: { width: 97 },
  },
  {
    id: 'sender',
    label: '連絡者',
    style: { width: 180 },
  },
  {
    id: 'content',
    label: '連絡内容',
    style: { flex: 1, justifyContent: 'center' },
  },
];
