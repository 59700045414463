import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep04Checked: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="27"
        height="24"
        viewBox="0 0 27 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.54195 12.2615L0.541624 12.2617L0.546233 12.2668C0.69718 12.432 0.958655 12.4474 1.12852 12.2609L3.27251 10.4531V20.25C3.27251 21.8003 4.56704 23.0579 6.16081 23.0579H20.3129C21.9044 23.0579 23.2012 21.8003 23.2012 20.25V10.4531L25.3451 12.2609C25.5086 12.4403 25.7763 12.4403 25.8915 12.2632C25.9799 12.1669 26.0262 12.0645 26.0255 11.9636C26.0248 11.8604 25.975 11.7691 25.8919 11.6982C25.8919 11.6982 25.8919 11.6982 25.8919 11.6982L13.5092 1.0427C13.5091 1.04265 13.5091 1.04261 13.509 1.04256C13.3546 0.908598 13.119 0.90862 12.9646 1.04263C12.9645 1.04265 12.9645 1.04268 12.9645 1.0427L0.581684 11.6981C0.581652 11.6981 0.58162 11.6982 0.581588 11.6982C0.498132 11.7693 0.449507 11.8595 0.442882 11.9602C0.436314 12.0601 0.471432 12.1633 0.54195 12.2615ZM9.64093 15.0938V22.2546H6.16081C5.01924 22.2546 4.0959 21.3551 4.0959 20.25V9.74492L13.2368 1.87947L22.3778 9.74492V20.25C22.3778 21.3551 21.4544 22.2546 20.3129 22.2546H16.8327V15.0938C16.8327 14.4887 16.332 14.0046 15.7134 14.0046H10.7602C10.1416 14.0046 9.64093 14.4887 9.64093 15.0938ZM16.0094 15.0938V22.2546H10.4643V15.0938C10.4643 14.9382 10.596 14.8079 10.7602 14.8079H15.7134C15.8776 14.8079 16.0094 14.9382 16.0094 15.0938Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.115789"
        />
      </svg>
    </SvgIcon>
  );
};
