import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { useCallback, useEffect, useMemo } from 'react';
import { FormikContextType, FormikState } from 'formik';
import {
  ApplicantDetailType,
  BuyingandSellingScheduleType,
  CODEBANK,
  CurrentResidenceType,
  Flat35ApplicablePlanType,
  MaintenanceType,
  PersonOccupancyType,
  PlannedCohabitantType,
  PlanningAreaType,
  PurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
  RelationshipType,
} from 'constant';
import get from 'lodash/get';
import { dayjs } from 'libs/dayjs';
import { getYearLength } from '../utils/getYearLenght';

type Values = AdminPreliminaries | SalePersonPreliminaries;

export const usePersonalResidents = (
  values: Values,
  setFieldValue: FormikContextType<Values>['setFieldValue'],
  touched: FormikState<Values>['touched']
) => {
  const isMCJ = useMemo(
    () => values.p_application_header.name_master_banks?.includes(CODEBANK.MCJ),
    [values.p_application_header.name_master_banks]
  );

  const handleResetValue = useCallback(
    (key: string, defaultValue: string | number | []) => {
      if (get(values, key) === undefined) return;
      return setFieldValue(key, defaultValue);
    },
    [setFieldValue, values]
  );

  useEffect(() => {
    if (
      ![PurposeType.THREE, PurposeType.FOUR].includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.condominium_name', '');
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (!isMCJ) {
      handleResetValue('p_application_header.land_ownership', '');
      handleResetValue('p_application_header.purchase_purpose', '');
      handleResetValue('p_application_header.planning_area', '');
      handleResetValue('p_application_header.flat_35_applicable_plan', '');
      handleResetValue('p_application_header.maintenance_type', '');
      handleResetValue('p_application_header.region_type', '');
      handleResetValue('p_application_header.other_planning_area', '');
    }
  }, [isMCJ]);

  useEffect(() => {
    if (
      !(
        isMCJ &&
        values.p_application_header.planning_area === PlanningAreaType.OTHERS
      )
    ) {
      handleResetValue('p_application_header.other_planning_area', '');
    }
  }, [isMCJ, values.p_application_header.planning_area]);

  useEffect(() => {
    if (
      !(
        isMCJ &&
        [
          PlanningAreaType.URBANIZED_AREA,
          PlanningAreaType.URBANIZATION_CONTROL_AREA,
        ].includes(
          values.p_application_header.planning_area ?? PlanningAreaType.DEFAULT
        )
      )
    ) {
      handleResetValue('p_application_header.rebuilding_reason', '');
    }
  }, [isMCJ, values.p_application_header.planning_area]);

  useEffect(() => {
    if (
      !(
        isMCJ &&
        values.p_application_header.rebuilding_reason ===
          RebuildingReasonType.OTHERS
      )
    ) {
      handleResetValue('p_application_header.other_rebuilding_reason', '');
    }
  }, [isMCJ, values.p_application_header.rebuilding_reason]);

  useEffect(() => {
    if (
      values.p_application_header.flat_35_applicable_plan ===
        Flat35ApplicablePlanType.PLAN_A &&
      values.p_application_header.maintenance_type ===
        MaintenanceType.PRELIMINARY_CERTIFIED_APARTMENT
    ) {
      handleResetValue('p_application_header.flat_35_application', '1');
    }
    if (
      !(
        isMCJ &&
        values.p_application_header.flat_35_applicable_plan ===
          Flat35ApplicablePlanType.PLAN_A
      )
    ) {
      handleResetValue('p_application_header.flat_35_application', '');
    }
  }, [
    isMCJ,
    values.p_application_header.flat_35_applicable_plan,
    values.p_application_header.maintenance_type,
  ]);

  useEffect(() => {
    if (
      [PurposeType.THREE, PurposeType.FOUR].includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.collateral_land_area', '');
    }
  }, [handleResetValue, touched, values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      ![PurposeType.THREE, PurposeType.FOUR].includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.occupied_area', '');
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes?.[0]
        ?.applicant_detail_type !== ApplicantDetailType.MAIN
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].lived_length_month_num',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.lived_length_month_num &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.applicant_detail_type !== ApplicantDetailType.MAIN
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].lived_length_month_num',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
  ]);

  useEffect(() => {
    if (values.p_application_header.loan_target === PurposeType.SEVEN) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].reason_acquire_home',
        ''
      );
    }
    if (
      values.p_application_header.loan_target === PurposeType.SEVEN &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.reason_acquire_home
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].reason_acquire_home',
        ''
      );
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      values.p_application_header.non_resident_reason &&
      values.p_application_header.person_occupancy !== PersonOccupancyType.NO
    ) {
      handleResetValue('p_application_header.non_resident_reason', '');
    }
  }, [values.p_application_header.person_occupancy]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes?.[0]
        ?.reason_acquire_home !== ReasonsForNeedingHousingType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].other_reason_acquire_home',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.other_reason_acquire_home &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.reason_acquire_home !== ReasonsForNeedingHousingType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].other_reason_acquire_home',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.reason_acquire_home,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.reason_acquire_home,
  ]);

  useEffect(() => {
    if (
      !(
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.applicant_detail_type === ApplicantDetailType.MAIN
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].buyingand_selling_schedule_type',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.buyingand_selling_schedule_type &&
      !(
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.applicant_detail_type === ApplicantDetailType.MAIN
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].buyingand_selling_schedule_type',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
  ]);

  useEffect(() => {
    if (
      !(
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.applicant_detail_type === ApplicantDetailType.MAIN &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.buyingand_selling_schedule_type ===
          BuyingandSellingScheduleType.OTHERS
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].other_buyingand_selling_schedule_type',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.other_buyingand_selling_schedule_type &&
      !(
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.applicant_detail_type === ApplicantDetailType.MAIN &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.buyingand_selling_schedule_type ===
          BuyingandSellingScheduleType.OTHERS
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].other_buyingand_selling_schedule_type',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.buyingand_selling_schedule_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.buyingand_selling_schedule_type,
  ]);

  useEffect(() => {
    if (
      !(
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.applicant_detail_type === ApplicantDetailType.MAIN &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.buyingand_selling_schedule_type ===
          BuyingandSellingScheduleType.SALE
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].expected_house_selling_price',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.expected_house_selling_price &&
      !(
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.applicant_detail_type === ApplicantDetailType.MAIN &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.buyingand_selling_schedule_type ===
          BuyingandSellingScheduleType.SALE
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].expected_house_selling_price',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.buyingand_selling_schedule_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.buyingand_selling_schedule_type,
  ]);

  useEffect(() => {
    if (!isMCJ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].current_residence_floor_area',
        ''
      );
    }
  }, [handleResetValue, isMCJ, touched]);

  useEffect(() => {
    if (
      !isMCJ &&
      !!values.p_application_header.p_applicant_people_attributes?.[1]
        ?.current_residence_floor_area
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].current_residence_floor_area',
        ''
      );
    }
  }, [
    isMCJ,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.current_residence_floor_area,
  ]);

  useEffect(() => {
    if (
      !(
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.current_residence ===
          CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.applicant_detail_type === ApplicantDetailType.MAIN
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].owner_full_name',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.owner_full_name &&
      !(
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.current_residence ===
          CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.applicant_detail_type === ApplicantDetailType.MAIN
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].owner_full_name',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
  ]);

  useEffect(() => {
    if (
      !(
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.current_residence ===
          CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.applicant_detail_type === ApplicantDetailType.MAIN
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].owner_relationship',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.owner_relationship &&
      !(
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.current_residence ===
          CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.applicant_detail_type === ApplicantDetailType.MAIN
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].owner_relationship',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
  ]);

  useEffect(() => {
    if (
      !(
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.applicant_detail_type === ApplicantDetailType.MAIN &&
        values.p_application_header.p_applicant_people_attributes?.[0]
          ?.buyingand_selling_schedule_type ===
          BuyingandSellingScheduleType.SALE
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].scheduled_time_sell_house',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.scheduled_time_sell_house &&
      !(
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.applicant_detail_type === ApplicantDetailType.MAIN &&
        values.p_application_header.p_applicant_people_attributes?.[1]
          ?.buyingand_selling_schedule_type ===
          BuyingandSellingScheduleType.SALE
      )
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].scheduled_time_sell_house',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.buyingand_selling_schedule_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.current_residence,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.buyingand_selling_schedule_type,
  ]);

  useEffect(() => {
    if (
      values.p_application_header.p_applicant_people_attributes?.[0]
        ?.applicant_detail_type !== ApplicantDetailType.MAIN
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].current_home_loan',
        ''
      );
    }
    if (
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.current_home_loan &&
      values.p_application_header.p_applicant_people_attributes?.[1]
        ?.applicant_detail_type !== ApplicantDetailType.MAIN
    ) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[1].current_home_loan',
        ''
      );
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.applicant_detail_type,
    values.p_application_header.p_applicant_people_attributes?.[1]
      ?.applicant_detail_type,
  ]);

  useEffect(() => {
    const { birthday, lived_length_month_num, lived_length_year_num } =
      values.p_application_header.p_applicant_people_attributes?.[0] || {};
    if (!lived_length_year_num) {
      return;
    }
    if (!birthday) return;
    if (getYearLength(birthday) !== +lived_length_year_num) return;
    const diffMonth = dayjs().diff(birthday, 'M', true);
    if (+(lived_length_month_num || 0) > Math.floor(diffMonth % 12)) {
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].lived_length_month_num',
        ''
      );
      return;
    }
  }, [
    values.p_application_header.p_applicant_people_attributes?.[0]
      ?.lived_length_year_num,
  ]);

  useEffect(() => {
    if (
      isMCJ &&
      [PersonOccupancyType.NO, PersonOccupancyType.DEFAULT].includes(
        values.p_application_header.person_occupancy
      )
    ) {
      handleResetValue(
        'p_application_header.p_residents_attributes[0].loan_from_japan_housing_finance_agency',
        ''
      );
      handleResetValue(
        'p_application_header.p_residents_attributes[0].postal_code',
        ''
      );
      handleResetValue(
        'p_application_header.p_residents_attributes[0].prefecture_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_residents_attributes[0].city_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_residents_attributes[0].district_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_residents_attributes[0].other_address_kanji',
        ''
      );
      handleResetValue(
        'p_application_header.p_residents_attributes[0].home_phone_number',
        ''
      );
      handleResetValue(
        'p_application_header.p_residents_attributes[0].nationality',
        '1'
      );
      return;
    }
    if (!values.p_application_header.p_residents_attributes.length) return;
  }, [isMCJ, values.p_application_header.person_occupancy]);

  useEffect(() => {
    if (!values.p_application_header.p_residents_attributes.length) return;
    if (
      !(
        values.p_application_header.p_residents_attributes[0].relationship ===
        RelationshipType.OTHERS
      )
    ) {
      handleResetValue(
        'p_application_header.p_residents_attributes[0].other_relationship',
        ''
      );
    }
  }, [values.p_application_header.p_residents_attributes?.[0]?.relationship]);

  useEffect(() => {
    if (!values.p_application_header.planned_cohabitant.length) return;
    if (
      !values.p_application_header.planned_cohabitant.includes(
        PlannedCohabitantType.OTHERS
      )
    ) {
      handleResetValue('p_application_header.other_relationship', '');
    }
  }, [values.p_application_header.planned_cohabitant]);
};
