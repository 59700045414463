import {
  ClipboardEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { AdminInput, Button, Icons } from 'components';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getDynamicOptions,
  getMasterBanks,
} from 'pages/StepSynthesisInformation/thunk';
import { adminBackground, logoCompany } from 'assets';
import { routeNames } from 'navigations/routes';
import { config } from 'configs';
import { Loading } from 'containers/AdminLayout';
import { LoginError, AzureLoginError } from 'services';
import { validationSchema } from './validationSchema';
import { salePersonAzureLogin, salePersonLogin } from './thunk';

const SalePersonLoginPage: FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [azureErrorMessage, setAzureErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorField, setErrorField] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const azureCode = query.get('code');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    async onSubmit(user) {
      const result = await dispatch(
        salePersonLogin({
          s_sale_person: {
            email: user.email,
            password: user.password,
          },
        })
      );
      if (!salePersonLogin.fulfilled.match(result)) {
        const errorResponse = result.payload as LoginError;
        if (errorResponse.errors.some((e) => e.key !== 'locked')) {
          setErrorMessage('メールアドレスまたはパスワードが正しくありません。');
          setErrorField(true);
        } else {
          setErrorMessage(
            `ログイン失敗でアカウントがロックされました。\nアカウントロックの解除は、ログイン画面の「パスワードを忘れた方はこちらから設定をお願いします」からお進みください。`
          );
          setErrorField(true);
        }
      }
      if (salePersonLogin.fulfilled.match(result)) {
        dispatch(getMasterBanks());
        dispatch(getDynamicOptions());
        navigate(routeNames.SalePersonPreliminaries.path);
      }
    },
  });

  const loginByAzureAction = useCallback(
    async (code: string) => {
      setLoading(true);
      const result = await dispatch(
        salePersonAzureLogin({ s_sale_person: { access_token: code } })
      );
      if (salePersonAzureLogin.fulfilled.match(result)) {
        dispatch(getMasterBanks());
        dispatch(getDynamicOptions());
        setLoading(false);
        navigate(routeNames.SalePersonPreliminaries.path);
      }
      if (!salePersonAzureLogin.fulfilled.match(result)) {
        const errorResponse = result.payload as LoginError | AzureLoginError;
        if (
          errorResponse.errors === 'account_azure_is_logged_in' ||
          (typeof errorResponse.errors !== 'string' &&
            errorResponse.errors.some(
              (e) => e.key === 'account_azure_is_logged_in'
            ))
        ) {
          return setAzureErrorMessage(
            'エラーが発生しました。\nAzureADに登録されているメールアドレスを取得できません。'
          );
        }
        if (
          errorResponse.errors === 'account_not_found' ||
          (typeof errorResponse.errors !== 'string' &&
            errorResponse.errors.some((e) => e.key === 'account_not_found'))
        ) {
          return setAzureErrorMessage(
            'エラーが発生しました。\nシステムにあなたのメールアドレスが登録されていません。管理者にお問い合わせください。'
          );
        }
        return setAzureErrorMessage(
          'システムの内部エラーが発生しました。しばらく経ってから再度お試しください。'
        );
      }
    },
    [dispatch, navigate]
  );

  // Azure Login
  useLayoutEffect(() => {
    if (!!azureCode) {
      loginByAzureAction(azureCode);
    }
  }, [azureCode, loginByAzureAction]);

  useEffect(() => {
    const query = new URLSearchParams(location.hash.replace(/^#/g, '?'));
    const token = query.get('access_token');
    if (!token) {
      return;
    }
    (async () => {
      setLoading(true);
      const result = await dispatch(
        salePersonLogin({
          s_sale_person: {
            access_token: token,
          },
        })
      );
      if (!salePersonLogin.fulfilled.match(result)) {
        setErrorMessage(
          'このユーザーはシステムに存在しません。\n再度ご確認ください。'
        );
        setLoading(false);
      }
      if (salePersonLogin.fulfilled.match(result)) {
        navigate(routeNames.SalePersonPreliminaries.path);
      }
    })();
  }, []);

  const clearError = useCallback(() => {
    setErrorMessage('');
    setErrorField(false);
  }, []);

  const onLoginEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const onPreventPassword = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return !azureCode ? (
    <FormikProvider value={formik}>
      {loading && <Loading />}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={loading ? 'calc(100vh - 4px)' : '100vh'}
        sx={{
          backgroundImage: `url(${adminBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          sx={{
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            bgcolor: 'main_white',
            borderRadius: '5px',
            width: '430px',
          }}
          onKeyPress={onLoginEnter}
        >
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              mt: 10,
            }}
          >
            <Avatar
              src={logoCompany}
              variant="square"
              sx={{ height: '64px', width: '272px' }}
            />
            <Typography variant="heading01" color="b_333" mt={5}>
              ログイン
            </Typography>
          </Stack>
          {!!errorMessage && (
            <Stack
              sx={{ mt: 5, px: 5 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Icons.CircleExclamation
                sx={{
                  width: 16,
                  height: 16,
                  marginRight: 1,
                }}
              />
              <Typography
                variant="upload_image_text"
                color="sp_secondary_01"
                sx={{
                  fontSize: 13,
                  lineHeight: '16px',
                  letterSpacing: 1.5,
                  whiteSpace: 'break-spaces',
                }}
              >
                {errorMessage}
              </Typography>
            </Stack>
          )}
          <Stack
            sx={{
              mt: 3,
              p: '0px 40px',
            }}
          >
            <Stack
              sx={{
                justifyContent: 'center',
                pt: '5px',
              }}
            >
              <Stack>
                <Typography variant="headline_input" color="b_333">
                  メールアドレス
                </Typography>
              </Stack>
              <Stack sx={{ mt: '4px', maxWidth: 350 }}>
                <AdminInput
                  name="email"
                  type="email"
                  placeholder="入力してください"
                  InputProps={{
                    sx: {
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'sp_secondary_01',
                        },
                    },
                  }}
                  onFocus={clearError}
                  error={
                    errorField ||
                    (formik.touched.email && !!formik.errors.email)
                  }
                />
              </Stack>
              <Stack sx={{ mt: '20px' }}>
                <Typography variant="headline_input" color="b_333">
                  パスワード
                </Typography>
              </Stack>
              <Stack sx={{ mt: '4px', maxWidth: 350 }}>
                <AdminInput
                  name="password"
                  placeholder="入力してください"
                  InputProps={{
                    sx: {
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'sp_secondary_01',
                        },
                    },
                  }}
                  onFocus={clearError}
                  onCut={onPreventPassword}
                  onCopy={onPreventPassword}
                  onPaste={onPreventPassword}
                  error={
                    errorField ||
                    (formik.touched.password && !!formik.errors.password)
                  }
                  disabled={false}
                  hasPassword
                />
              </Stack>
              <Stack
                sx={{ mt: '40px' }}
                direction="row"
                justifyContent="center"
              >
                <Button
                  disabled={formik.isSubmitting}
                  sx={{
                    bgcolor: 'white',
                    boxShadow: 'none',
                    width: '200px',
                    height: '36px',
                    borderRadius: '2px',
                    minHeight: '36px',
                    border: '1px solid #6B70F0',
                  }}
                  onClick={() => formik.handleSubmit()}
                >
                  <Typography variant="button01" color="sp_primary_100_main">
                    ログイン
                  </Typography>
                </Button>
              </Stack>
              <Stack
                sx={{
                  mt: 5,
                  mb: '10px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="table_header_text"
                  color="b_333"
                  component="p"
                  sx={{
                    lineHeight: '18px',
                  }}
                >
                  パスワードを忘れた方は
                  <Typography
                    onClick={() => {
                      if (
                        location.pathname === routeNames.LoginSalePerson.path
                      ) {
                        navigate(routeNames.ResetPasswordSalePerson.path);
                      }
                    }}
                    color="h_blugreen"
                    component="span"
                    variant="table_header_text"
                    sx={{
                      lineHeight: '18px',
                      fontWeight: 600,
                      cursor: 'pointer',
                    }}
                  >
                    こちらから再設定
                  </Typography>
                  をお願いします
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Link
                  href={config.termServiceUrl}
                  target="_blank"
                  component="a"
                  sx={{ textDecoration: 'none', mb: 10 }}
                  color="sp_primary_100_main"
                  variant="term_service_text"
                >
                  利用規約
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </FormikProvider>
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw"
    >
      {!azureErrorMessage ? (
        <Backdrop
          open={true}
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            bgcolor: 'main_white',
          }}
        >
          <CircularProgress />
        </Backdrop>
      ) : (
        <Typography
          variant="total_text"
          color="sp_secondary_01"
          sx={{
            textAlign: 'center',
            fontSize: 30,
            lineHeight: 1.2,
            whiteSpace: 'break-spaces',
          }}
        >
          {azureErrorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default SalePersonLoginPage;
