import { Stack, StackProps, SxProps, Typography } from '@mui/material';
import { ButtonProps, Icons, SPButton } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { isReactNode } from 'utils';
import { isMobile } from 'react-device-detect';

export type SPFooterProps = PropsWithChildren<{
  left?: ReactNode | StackProps | false;
  right?: ReactNode | ButtonProps | false;
  sxIconRight?: SxProps;
  sxContainerRight?: SxProps;
}>;

export const SPFooter: FC<SPFooterProps> = ({
  left,
  right,
  sxContainerRight,
}) => (
  <Stack
    sx={{
      '@media (orientation: landscape)': {
        maxWidth: isMobile ? 'none' : 480,
      },
      backgroundColor: 'main_white',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: (theme) => theme.zIndex.drawer - 2,
      boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.05)',
      maxWidth: 480,
      mx: 'auto',
    }}
  >
    <Stack direction="row" sx={{ px: 6, py: 4 }} spacing={3}>
      {left !== false && (
        <Stack justifyContent="center" alignItems="flex-end">
          {isReactNode(left) ? (
            left
          ) : (
            <Stack {...left} direction="row" spacing={1}>
              <Icons.ArrowBack />
              <Typography
                sx={{ fontSize: 16, whiteSpace: 'nowrap' }}
                variant="sp_primary_button"
                color="sp_primary_100_main"
              >
                もどる
              </Typography>
            </Stack>
          )}
        </Stack>
      )}
      {right !== false && (
        <Stack
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{ ...sxContainerRight }}
        >
          {isReactNode(right) ? (
            right
          ) : (
            <SPButton
              {...right}
              sx={{
                width: left ? '100%' : '70%',
                bgcolor: 'sp_primary_100_main',
              }}
              endIcon={<Icons.ArrowForward stroke="white" />}
              disabled={right?.disabled}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="main_white"
              >
                {right.children ?? '次へ'}
              </Typography>
            </SPButton>
          )}
        </Stack>
      )}
    </Stack>
  </Stack>
);
