import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  NewSaveDraftResponse,
  SaveDraftResponse,
  SPGetDraftResponse,
  StepSynthesisForm,
  ThunkAPI,
} from 'types';
import { applicationsService } from 'services';
import { toast } from 'react-toastify';

export const saveDraft = createAsyncThunk<SaveDraftResponse, string>(
  'SaveDraftButton/SaveDraft',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } = await applicationsService.saveDraft(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSavedDraft = createAsyncThunk<SaveDraftResponse>(
  'SaveDraftButton/GetSavedDraft',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } = await applicationsService.getSavedDraft();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNewSavedDraft = createAsyncThunk<NewSaveDraftResponse>(
  'SaveDraftButton/GetNewSavedDraft',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } = await applicationsService.getNewSavedDraft();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const handleNewSaveDraft = createAsyncThunk<
  void,
  StepSynthesisForm & { current_path: string },
  ThunkAPI<Error>
>('SaveDraftButton/onSaveDraft', async (params, { getState, dispatch }) => {
  const result = await dispatch(
    saveDraft(
      JSON.stringify({
        draft: params,
      })
    )
  );
  if (saveDraft.fulfilled.match(result)) {
    toast.success('成功に途中保存しました。');
  } else {
    toast.error('エラーが発生しました。');
  }
  return;
});

export const spGetDraft = createAsyncThunk<SPGetDraftResponse>(
  'SaveDraftButton/SPGetDraft',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } = await applicationsService.spGetDraft();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const spSaveDraft = createAsyncThunk<
  SaveDraftResponse,
  FormData,
  ThunkAPI<Error>
>(
  'SaveDraftButton/SPSaveDraft',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { data: response } = await applicationsService.spSaveDraft(data);
      dispatch(spGetDraft());
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
