import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import { ContactItem } from 'services';
import { getContacts } from './thunk';

interface InitialState {
  contacts: EntityState<ContactItem>;
}

export const contactAdapter = createEntityAdapter<ContactItem>({});

export const initialState: InitialState = {
  contacts: contactAdapter.getInitialState(),
};

const contactSlice = createSlice({
  name: 'contactInformation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContacts.fulfilled, (state, action) => {
      contactAdapter.setAll(state.contacts, action.payload.data);
    });
  },
});

export default contactSlice.reducer;
