import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowUp: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 9" {...props}>
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.851562 8L6.74337 2L12.6352 8"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    </SvgIcon>
  );
};
