import { FC } from 'react';
import {
  Stack,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';
import { Close } from '@mui/icons-material';
import { ContactItem } from 'services';

type ContactDetailProps = {
  open: boolean;
  onClose: () => void;
  contactItem: ContactItem;
};

export const ContactDetailModal: FC<ContactDetailProps> = ({
  open,
  onClose,
  contactItem,
}) => {
  return (
    <Dialog
      open={open}
      sx={{
        width: '40%',
        m: 'auto',
        '&& .MuiDialog-paper': { minWidth: 660, minHeight: 450 },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="title_application_item"
            color="contact_title_text"
          >
            連絡者：{contactItem.sender}
          </Typography>
          <Typography
            variant="title_text_contact_detail"
            color="contact_title_text"
          >
            {dayjs(contactItem.created_at).format('YYYY/MM/DD HH:mm:ss')}
          </Typography>
        </Stack>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            p: 0,
            right: 0,
            top: -1,
            color: 'gray',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ py: 4, px: 6 }}>
        <DialogContentText>
          <Stack direction="column">
            <Box
              sx={{
                mb: 4,
              }}
            >
              <Typography
                variant="text_content_contact_detail"
                color="normal_text"
                sx={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: `${contactItem.content}`,
                }}
              />
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
