import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  borrowerIncome,
  borrowingFromHousingFinanceAgency,
  borrowingStatus,
  CODEBANK,
  commonHousing,
  estateMortgage,
  FundsPairOffType,
  LoanType,
  NationalityType,
  SPBorrowingType,
  SPScheduledLoanPayoffType,
  SPTitle,
} from 'constant';

import {
  GetSPFormDataRequest,
  GetSPFormDataRequestResponse,
  SPFormData as FormikFormData,
  SPGetPreliminaryResponse,
  SubmitFormError,
  SubmitFormResponse,
  ThunkAPI,
  SubmitFormErrors,
  CheckDatabaseSyncResponse,
  CheckDatabaseSyncRequest,
} from 'types';
import {
  convertYenToMan,
  findOptionName,
  formatJapanDate,
  numberWithCommas,
} from 'utils';
import { routeNames } from 'navigations/routes';
import { applicationsService } from 'services';
import { AxiosError } from 'axios';
import { clearSalePerson } from '../../libs';

export const getSPFormData = createAsyncThunk<
  GetSPFormDataRequestResponse,
  GetSPFormDataRequest,
  ThunkAPI
>('stepNine/getFormData', (_, { getState }) => {
  const {
    sp_step_one,
    sp_step_two,
    sp_step_four_income_total,
    sp_step_four,
    sp_step_five,
    dynamic_options,
  } = getState();
  const isMCJ = sp_step_one.form.master_bank_codes.includes(CODEBANK.MCJ);
  const incomeTotalizer =
    sp_step_one.form.p_application_header.loan_type === LoanType.THREE ||
    sp_step_one.form.p_application_header.loan_type === LoanType.FOUR;

  const hasJoinGuarantor =
    !!+sp_step_one.form.p_application_header.p_applicant_people_attributes[0]
      .has_join_guarantor;

  const {
    loan_type,
    loan_target,
    repayment_method,
    sex,
    nationality,
    borrower,
    borrowing_type,
    loan_purpose,
    borrowing_category,
    scheduled_loan_payoff,
    current_residence,
    reason_acquire_home,
    land_ownership,
    purchase_purpose,
    planning_area,
    rebuilding_reason,
    flat_35_applicable_plan,
    maintenance_type,
    region_type,
    desired_monthly_bonus,
    completely_repayment_type,
    flat_35_application,
  } = dynamic_options.form;

  const optionArray = [
    {
      field: loan_type,
      formikField: sp_step_one.form.p_application_header.loan_type,
    },
    {
      field: loan_target,
      formikField: sp_step_one.form.p_application_header.loan_target,
    },
    {
      field: repayment_method,
      formikField:
        sp_step_one.form.p_application_header.p_borrowing_details_attributes[0]
          .repayment_method,
    },
    {
      field: sex,
      formikField:
        sp_step_two.form.p_application_header.p_applicant_people_attributes[0]
          .sex,
    },
    {
      field: nationality,
      formikField:
        sp_step_two.form.p_application_header.p_applicant_people_attributes[0]
          .nationality,
    },
    {
      field: nationality,
      formikField:
        sp_step_four_income_total.form.p_application_header
          .p_applicant_people_attributes[0].nationality,
    },
    {
      field: current_residence,
      formikField:
        sp_step_four.form.p_application_header.p_applicant_people_attributes[0]
          .current_residence,
    },
    {
      field: reason_acquire_home,
      formikField:
        sp_step_four.form.p_application_header.p_applicant_people_attributes[0]
          .reason_acquire_home,
    },
    {
      field: land_ownership,
      formikField: sp_step_four.form.p_application_header.land_ownership,
    },
    {
      field: purchase_purpose,
      formikField: sp_step_four.form.p_application_header.purchase_purpose,
    },
    {
      field: planning_area,
      formikField: sp_step_four.form.p_application_header.planning_area,
    },
    {
      field: rebuilding_reason,
      formikField: sp_step_four.form.p_application_header.rebuilding_reason,
    },
    {
      field: flat_35_applicable_plan,
      formikField:
        sp_step_four.form.p_application_header.flat_35_applicable_plan,
    },
    {
      field: maintenance_type,
      formikField: sp_step_four.form.p_application_header.maintenance_type,
    },
    {
      field: region_type,
      formikField: sp_step_four.form.p_application_header.region_type,
    },
    {
      field: desired_monthly_bonus,
      formikField:
        sp_step_one.form.p_application_header.p_borrowing_details_attributes[0]
          .desired_monthly_bonus,
    },
    {
      field: nationality,
      formikField:
        sp_step_four.form.p_application_header.p_residents_attributes[0]
          .nationality,
    },
    {
      field: completely_repayment_type,
      formikField:
        sp_step_five.form.p_application_header.completely_repayment_type,
    },
    {
      field: flat_35_application,
      formikField: sp_step_four.form.p_application_header.flat_35_application,
    },
  ];

  const [mainSex, pairNationality, completelyRepaymentType] = optionArray.map(
    (opt) => findOptionName(opt.field, opt.formikField)
  );

  const stepFourIncomeTotalizer = () => {
    const {
      last_name_kanji,
      first_name_kanji,
      last_name_kana,
      first_name_kana,
      name_relationship_to_applicant,
      birthday,
      mobile_phone_number,
      home_phone_number,
      postal_code,
      prefecture_kanji,
      city_kanji,
      district_kanji,
      other_address_kanji,
    } =
      sp_step_four_income_total.form.p_application_header
        .p_applicant_people_attributes[0];
    let showMobileNumber = '';
    let showHomeNumber = '';
    if (!!mobile_phone_number)
      showMobileNumber += `〈携帯〉${mobile_phone_number}`;
    if (!!home_phone_number) showHomeNumber += `〈自宅〉${home_phone_number}`;
    if (!!mobile_phone_number && !!home_phone_number)
      showMobileNumber += '\n\n';
    if (incomeTotalizer)
      return [
        {
          label: SPTitle.STEP_FOUR_INCOME,
          data: [
            {
              name: '収入合算者のお名前',
              value: `${last_name_kanji} ${first_name_kanji}\n\n${last_name_kana} ${first_name_kana}`,
            },
            {
              name: '性別',
              value: mainSex?.option_name ?? 'ー',
            },

            ...(sp_step_one.form.p_application_header.loan_type ===
            LoanType.THREE
              ? [
                  {
                    name: '続柄',
                    value: name_relationship_to_applicant
                      ? name_relationship_to_applicant
                      : 'ー',
                  },
                ]
              : []),
            {
              name: '生年月日',
              value: formatJapanDate(birthday) ?? 'ー',
            },
            {
              name: '現在の国籍',
              value: pairNationality?.option_name ?? 'ー',
              hasImage:
                pairNationality?.option_code.toString() ===
                NationalityType.FOREIGN_NATIONALITY,
              indexArrayImages: 0,
            },
            {
              name: '電話番号',
              value:
                showMobileNumber || showHomeNumber
                  ? showMobileNumber + showHomeNumber
                  : 'ー',
            },
            {
              name: '現住所',
              value: `〒${postal_code}\n\n${
                prefecture_kanji + city_kanji + district_kanji
              }${other_address_kanji ?? ''}`,
            },
          ],
          path: routeNames.SPStepFourIncomeTotalizer.path,
          contentButton: 'STEP04：を修正する',
        },
      ];
    return [];
  };

  const stepFive = () => {
    const { borrowing_status, land_rent_to_be_paid, house_rent_to_be_paid } =
      sp_step_five.form.p_application_header.p_applicant_people_attributes[0];
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_amount,
      refund_content,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid: total_income_land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid: total_income_house_rent_to_be_paid,
    } = sp_step_five.form.p_application_header;

    const borrowings = p_borrowings_attributes
      .filter((borrowing) => !borrowing._destroy)
      .map((borrowing, index) => {
        const value = [];

        if (incomeTotalizer) {
          value.push(
            `〈借入名義人〉${
              findOptionName(borrower, borrowing.borrower)?.option_name ??
              '申込人本人'
            }`
          );
        }

        value.push(
          `〈借入種類〉${
            findOptionName(borrowing_type, borrowing.borrowing_type)
              ?.option_name ?? 'ー'
          }`
        );

        if (!!borrowing.lender) value.push(`〈借入先〉${borrowing.lender}`);
        if (!!borrowing.loan_purpose)
          value.push(
            `〈お借入の目的〉${
              findOptionName(loan_purpose, borrowing.loan_purpose)
                ?.option_name ?? 'ー'
            }`
          );
        if (!!borrowing.other_purpose)
          value.push(`〈お借入の目的〉${borrowing.other_purpose}`);
        if (!!borrowing.business_borrowing_type)
          value.push(
            `〈お借入の目的〉${
              findOptionName(loan_purpose, borrowing.business_borrowing_type)
                ?.option_name ?? 'ー'
            }`
          );
        if (!!borrowing.specific_loan_purpose)
          value.push(`〈お借入の目的〉${borrowing.specific_loan_purpose}`);
        if (!!borrowing.borrowing_category)
          value.push(
            `〈借入区分〉${
              findOptionName(borrowing_category, borrowing.borrowing_category)
                ?.option_name ?? 'ー'
            }`
          );
        if (!!borrowing.borrowing_from_housing_finance_agency)
          value.push(
            `〈住宅金融支援機構からの借入ですか？〉${
              borrowingFromHousingFinanceAgency[
                borrowing.borrowing_from_housing_finance_agency
              ]
            }`
          );
        if (!!borrowing.loan_start_date)
          value.push(
            `〈${
              borrowing.borrowing_type === SPBorrowingType.CARD_LOAN
                ? '当初カード契約年月'
                : '当初借入年月'
            }〉${formatJapanDate(borrowing.loan_start_date, true)}`
          );
        if (!!borrowing.loan_amount)
          value.push(
            `〈${
              borrowing.borrowing_type === SPBorrowingType.CARD_LOAN
                ? '借入限度額'
                : '当初借入額'
            }〉${numberWithCommas(
              +convertYenToMan([borrowing.loan_amount])[0]
            )}万円`
          );
        if (!!borrowing.current_balance_amount)
          value.push(
            `〈現在の残高〉${numberWithCommas(
              +convertYenToMan([borrowing.current_balance_amount])[0]
            )}万円`
          );
        if (!!borrowing.annual_repayment_amount)
          value.push(
            `〈年間返済額〉${numberWithCommas(
              +convertYenToMan([borrowing.annual_repayment_amount])[0]
            )}万円`
          );
        if (!!borrowing.card_expiry_date)
          value.push(
            `〈カード有効期限〉${formatJapanDate(
              borrowing.card_expiry_date,
              true
            )}`
          );
        if (!!borrowing.loan_deadline_date)
          value.push(
            `〈${
              borrowing.borrowing_type === SPBorrowingType.APARTMENT_LOAN
                ? '最終返済年月'
                : '最終期限'
            }〉${formatJapanDate(borrowing.loan_deadline_date, true)}`
          );
        if (!!borrowing.rental_room_number)
          value.push(
            `〈賃貸戸（室）数〉${borrowing.rental_room_number}戸（室）`
          );
        if (!!borrowing.common_housing)
          value.push(`〈共同住宅〉${commonHousing[borrowing.common_housing]}`);
        if (!!borrowing.estate_mortgage)
          value.push(
            `〈不動産担保設定〉${estateMortgage[borrowing.estate_mortgage]}`
          );
        if (!!borrowing.scheduled_loan_payoff)
          value.push(
            `〈今回のお借入までに完済の予定はありますか？〉${
              findOptionName(
                scheduled_loan_payoff,
                borrowing.scheduled_loan_payoff
              )?.option_name ?? 'ー'
            }`
          );
        if (!!borrowing.expected_repayment_date)
          value.push(
            `〈完済（予定）年月〉${formatJapanDate(
              borrowing.expected_repayment_date,
              true
            )}`
          );
        const string = value.join('\n\n');
        return {
          name: `お借入${index + 1}件目`,
          value: string,
          hasImage: true,
          indexArrayImages: index,
        };
      });

    let landHouseValue = '';
    if (incomeTotalizer) {
      landHouseValue = `支払地代\n\n〈支払いをしている方〉${
        borrowerIncome[land_rent_to_be_paid_borrower]
      }\n\n〈月間の支払金額〉${numberWithCommas(
        +total_income_land_rent_to_be_paid
      )}円\n\n支払家賃\n\n〈支払いをしている方〉${
        borrowerIncome[house_rent_to_be_paid_borrower]
      }\n\n〈月間の支払金額〉${numberWithCommas(
        +total_income_house_rent_to_be_paid
      )}円\n\n`;
    }

    const checkTitle = () => {
      if (incomeTotalizer && hasJoinGuarantor) {
        return SPTitle.STEP_EIGHT_INCOME_GUARANTORS;
      }
      if (incomeTotalizer && !hasJoinGuarantor) {
        return SPTitle.STEP_SEVEN_INCOME;
      }
      if (!incomeTotalizer && hasJoinGuarantor) {
        return SPTitle.STEP_SIX_GUARANTORS;
      }
      return SPTitle.STEP_FIVE;
    };

    return [
      {
        label: checkTitle(),
        data: [
          {
            name: 'あなたや連帯保証人予定者に、現在お借入はありますか？',
            value: borrowingStatus[borrowing_status]
              ? `${borrowingStatus[borrowing_status]}`
              : 'ー',
          },
          ...borrowings,
          ...(isMCJ &&
          p_borrowings_attributes.some(
            (borrowing) =>
              borrowing.scheduled_loan_payoff ===
              SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
          )
            ? [
                {
                  name: '完済原資',
                  value: `〈完済原資の種類〉${
                    completelyRepaymentType?.option_name
                  } ${
                    completely_repayment_type === FundsPairOffType.OTHERS
                      ? `（${other_complete_repayment_type}）`
                      : ''
                  }\n\n〈完済原資の内容〉${refund_content}\n\n〈完済原資の金額〉${numberWithCommas(
                    +convertYenToMan([refund_amount])[0]
                  )}万円`,
                },
                {
                  name: '今回の住宅取得後も継続する支払地代・支払家賃',
                  value: incomeTotalizer
                    ? landHouseValue
                    : `〈支払地代〉${numberWithCommas(
                        +land_rent_to_be_paid
                      )}円\n\n〈支払家賃〉${numberWithCommas(
                        +house_rent_to_be_paid
                      )}円\n\n`,
                },
              ]
            : []),
        ],
        path: routeNames.SPStepFive.path,
        contentButton: `STEP${checkTitle().slice(0, 2)} を修正する`,
      },
    ];
  };

  const mainData: FormikFormData[] = [...stepFive()];

  const incomeTotalData: FormikFormData[] = [...stepFourIncomeTotalizer()];

  return {
    success: true,
    data: {
      main: mainData,
      incomeTotal: incomeTotalData,
    },
  };
});

export const submitFormData = createAsyncThunk<
  SubmitFormResponse,
  FormData,
  ThunkAPI<SubmitFormError>
>('SubmitFormButton/SubmitForm', async (data, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.submitForm(data);
    clearSalePerson();
    return response;
  } catch (error) {
    const err = (error as AxiosError<SubmitFormError>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});

export const updatePreliminary = createAsyncThunk<
  SPGetPreliminaryResponse,
  FormData,
  ThunkAPI<SubmitFormError>
>('SubmitFormButton/UpdatePreliminary', async (data, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.updatePreliminary(
      data
    );
    clearSalePerson();
    return response;
  } catch (error) {
    const err = (error as AxiosError<SubmitFormError | SubmitFormErrors>)
      .response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});

export const checkDatabaseSync = createAsyncThunk<
  CheckDatabaseSyncResponse,
  CheckDatabaseSyncRequest,
  ThunkAPI<SubmitFormError>
>('SP/CheckDatabaseSync', async (data, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.checkDatabaseSync(
      data
    );
    return response;
  } catch (error) {
    const err = (error as AxiosError<SubmitFormError>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});
