import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NationalityType, SexType } from 'constant';
import { SPStepTwoForm } from 'types';
import { deleteUser } from 'pages/SPUnsubcribed/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { logout } from 'containers/AuthModal/thunk';
import { convertImageResponse } from 'utils';

export type InitialState = {
  form: SPStepTwoForm;
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      p_applicant_people_attributes: [
        {
          last_name_kanji: '',
          first_name_kanji: '',
          last_name_kana: '',
          first_name_kana: '',
          sex: SexType.DEFAULT,
          birthday: '',
          nationality: NationalityType.DEFAULT,
          mobile_phone_number: '',
          home_phone_number: '',
          postal_code: '',
          prefecture_kanji: '',
          city_kanji: '',
          district_kanji: '',
          other_address_kanji: '',
          other_address_kana: '',
          prefecture_kana: '',
          city_kana: '',
          district_kana: '',
          owner_email: '',
          residence_file: [],
          residence_file_back_image: [],
        },
      ],
    },
  },
};

const spStepTwoSlice = createSlice({
  name: 'stepTwo',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<SPStepTwoForm>) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    autoFillEmail: (state, action: PayloadAction<string>) => {
      state.form.p_application_header.p_applicant_people_attributes[0].owner_email =
        action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_applicant_people } = action.payload.data;
      const {
        last_name_kanji,
        first_name_kanji,
        last_name_kana,
        first_name_kana,
        sex,
        birthday,
        nationality,
        mobile_phone_number,
        home_phone_number,
        postal_code,
        prefecture_kanji,
        city_kanji,
        district_kanji,
        prefecture_kana,
        city_kana,
        district_kana,
        other_address_kanji,
        other_address_kana,
        owner_email,
        residence_file,
        residence_file_back_image,
      } = p_applicant_people[0];
      state.form.p_application_header.p_applicant_people_attributes = [
        {
          ...state.form.p_application_header.p_applicant_people_attributes[0],
          last_name_kanji,
          first_name_kanji,
          last_name_kana,
          first_name_kana,
          sex,
          birthday,
          nationality,
          mobile_phone_number,
          home_phone_number,
          postal_code,
          prefecture_kanji,
          city_kanji,
          district_kanji,
          prefecture_kana,
          city_kana,
          district_kana,
          other_address_kanji,
          other_address_kana,
          owner_email,
          ...(!!residence_file ? convertImageResponse(residence_file) : []),
          ...(!!residence_file_back_image
            ? convertImageResponse(residence_file_back_image)
            : []),
        },
      ];
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const { p_applicant_people_attributes } = action.payload.data;

      const {
        last_name_kanji,
        first_name_kanji,
        last_name_kana,
        first_name_kana,
        sex,
        birthday,
        nationality,
        mobile_phone_number,
        home_phone_number,
        postal_code,
        prefecture_kanji,
        city_kanji,
        district_kanji,
        other_address_kanji,
        other_address_kana,
        prefecture_kana,
        city_kana,
        district_kana,
        owner_email,
        personal_file_upload,
      } = p_applicant_people_attributes[0];

      state.form.p_application_header.p_applicant_people_attributes = [
        {
          ...state.form.p_application_header.p_applicant_people_attributes[0],
          last_name_kanji,
          first_name_kanji,
          last_name_kana,
          first_name_kana,
          sex,
          birthday,
          nationality,
          mobile_phone_number,
          home_phone_number,
          postal_code,
          prefecture_kanji,
          city_kanji,
          district_kanji,
          prefecture_kana,
          city_kana,
          district_kana,
          other_address_kanji,
          other_address_kana,
          owner_email,
          residence_file: convertImageResponse(
            personal_file_upload.residence_file
          ),
          residence_file_back_image: convertImageResponse(
            personal_file_upload.residence_file_back_image
          ),
        },
      ];
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const { p_applicant_people_attributes } = action.payload.data;

      const {
        last_name_kanji,
        first_name_kanji,
        last_name_kana,
        first_name_kana,
        sex,
        birthday,
        nationality,
        mobile_phone_number,
        home_phone_number,
        postal_code,
        prefecture_kanji,
        city_kanji,
        district_kanji,
        prefecture_kana,
        city_kana,
        district_kana,
        other_address_kanji,
        other_address_kana,
        owner_email,
        personal_file_upload,
      } = p_applicant_people_attributes[0];

      state.form.p_application_header.p_applicant_people_attributes = [
        {
          ...state.form.p_application_header.p_applicant_people_attributes[0],
          last_name_kanji,
          first_name_kanji,
          last_name_kana,
          first_name_kana,
          sex,
          birthday,
          nationality,
          mobile_phone_number,
          home_phone_number,
          postal_code,
          prefecture_kanji,
          city_kanji,
          district_kanji,
          prefecture_kana,
          city_kana,
          district_kana,
          other_address_kanji,
          other_address_kana,
          owner_email,
          residence_file: convertImageResponse(
            personal_file_upload.residence_file
          ),
          residence_file_back_image: convertImageResponse(
            personal_file_upload.residence_file_back_image
          ),
        },
      ];
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const { saveForm, autoFillEmail } = spStepTwoSlice.actions;

export default spStepTwoSlice.reducer;
