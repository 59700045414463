import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPDelete: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 19" {...props}>
      <svg
        width="16"
        height="19"
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.31406 2.75L5.30547 1.16094C5.56211 0.749785 6.01211 0.5 6.49727 0.5H9.75273C10.2379 0.5 10.6879 0.749785 10.9445 1.16094L11.9359 2.75H15.7188C15.8734 2.75 16 2.87586 16 3.03125C16 3.18664 15.8734 3.3125 15.7188 3.3125H0.53125C0.37593 3.3125 0.25 3.18664 0.25 3.03125C0.25 2.87586 0.37593 2.75 0.53125 2.75H4.31406ZM4.975 2.75H11.275L10.4664 1.45906C10.3117 1.21227 10.041 1.0625 9.75273 1.0625H6.49727C6.20898 1.0625 5.93828 1.21227 5.78359 1.45906L4.975 2.75ZM1.65625 4.4375C1.81164 4.4375 1.9375 4.56406 1.9375 4.71875V15.9688C1.9375 17.0551 2.81887 17.9375 3.90625 17.9375H12.3438C13.4301 17.9375 14.3125 17.0551 14.3125 15.9688V4.71875C14.3125 4.56406 14.4391 4.4375 14.5938 4.4375C14.7484 4.4375 14.875 4.56406 14.875 4.71875V15.9688C14.875 17.368 13.743 18.5 12.3438 18.5H3.90625C2.50844 18.5 1.375 17.368 1.375 15.9688V4.71875C1.375 4.56406 1.50086 4.4375 1.65625 4.4375ZM5.03125 15.125C5.03125 15.2797 4.90469 15.4062 4.75 15.4062C4.59531 15.4062 4.46875 15.2797 4.46875 15.125V6.125C4.46875 5.97031 4.59531 5.84375 4.75 5.84375C4.90469 5.84375 5.03125 5.97031 5.03125 6.125V15.125ZM8.40625 15.125C8.40625 15.2797 8.27969 15.4062 8.125 15.4062C7.97031 15.4062 7.84375 15.2797 7.84375 15.125V6.125C7.84375 5.97031 7.97031 5.84375 8.125 5.84375C8.27969 5.84375 8.40625 5.97031 8.40625 6.125V15.125ZM11.7812 15.125C11.7812 15.2797 11.6547 15.4062 11.5 15.4062C11.3453 15.4062 11.2188 15.2797 11.2188 15.125V6.125C11.2188 5.97031 11.3453 5.84375 11.5 5.84375C11.6547 5.84375 11.7812 5.97031 11.7812 6.125V15.125Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
