import { Stack } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useContext } from 'react';
import { ModalSaveInfoContext } from 'context';
import { ModalSaveInfo } from 'components';
import { GroupButtonSaveInfo } from 'containers';
import { Footer, FooterProps, Header, HeaderProps } from './components';

type LayoutProps = PropsWithChildren<{
  header: HeaderProps;
  footer?: FooterProps;
  hasFooter?: boolean;
}>;

export const LandingLayout: FC<LayoutProps> = ({
  footer,
  children,
  header,
  hasFooter = true,
}) => {
  const { openRecallSave, setRecallSave } = useContext(ModalSaveInfoContext);

  const handleClose = useCallback(() => {
    setRecallSave(!openRecallSave);
  }, [openRecallSave, setRecallSave]);

  return (
    <Stack>
      <GroupButtonSaveInfo callSave />
      <ModalSaveInfo
        open={openRecallSave}
        handleClose={handleClose}
        forgotPassword
        title="から"
        titleLine="保存情報を呼出す方はこちら"
        titleButton="情報を呼び出す"
        titleModal="保存情報を呼出す"
      />
      <Header {...header} />
      <Stack>{children}</Stack>
      {hasFooter && <Footer {...footer} />}
    </Stack>
  );
};
