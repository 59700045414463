import { z } from 'zod';

const errorsSchema = z.object({
  table: z.string(),
  field: z.string(),
  comment: z.string(),
});

export const updateStatusSBIResponseSchema = z.object({
  message: z.string().optional(),
  detail: z.string().optional(),
  erorr_position: z.number().optional(),
  erorrs: z.array(errorsSchema).optional(),
});

export const updateStatusSBIRequestSchema = z.object({
  application_header_id: z.string(),
});

export type UpdateStatusSBIRequest = z.infer<
  typeof updateStatusSBIRequestSchema
>;

export type UpdateStatusSBIResponse = z.infer<
  typeof updateStatusSBIResponseSchema
>;
