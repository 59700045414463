import {
  AdminPreliminaryItem,
  ApplicantDetailType,
  CODEBANK,
  DispatchType,
  FileATaxReturnType,
  FinalTaxReturnType,
  LeaveType,
  OccupationDetailType,
  OccupationType,
  PERSONAL_OCCUPATION,
  WorkIndustryType,
} from 'constant';
import dayjs from 'dayjs';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';

export const buildPersonalOccupation = (
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header'],
  index = 0
): AdminPreliminaryItem[] => {
  if (!values.p_applicant_people_attributes[index]) {
    return [];
  }
  const isMCJ = values.name_master_banks?.includes(CODEBANK.MCJ);
  if (!values.p_applicant_people_attributes[index]) {
    return [];
  }
  return PERSONAL_OCCUPATION.map((row) => {
    let condition = row.condition;
    if (index === 1) {
      condition = undefined;
    }
    if (
      [
        'p_applicant_people_attributes[0].other_occupation',
        'p_applicant_people_attributes[0].other_industry',
        'p_applicant_people_attributes[0].other_occupation_detail',
      ].includes(row.name_en)
    ) {
      condition = 'require_condition';
    }
    return {
      ...row,
      name_en: row.name_en.replace(
        'p_applicant_people_attributes[0]',
        `p_applicant_people_attributes[${index}]`
      ),
      condition,
    };
  })
    .filter(({ name_en }) => {
      switch (name_en) {
        case `p_applicant_people_attributes[${index}].other_occupation`:
          return (
            OccupationType.OTHERS ===
            values.p_applicant_people_attributes[index]?.occupation
          );
        case `p_applicant_people_attributes[${index}].other_industry`:
          return (
            WorkIndustryType.OTHERS ===
            values.p_applicant_people_attributes[index]?.industry
          );
        case `p_applicant_people_attributes[${index}].other_occupation_detail`:
          return (
            OccupationDetailType.OTHERS ===
            values.p_applicant_people_attributes[index]?.occupation_detail
          );
        case `p_applicant_people_attributes[${index}].type_tax_return`:
          return (
            FinalTaxReturnType.YES ===
            values.p_applicant_people_attributes[index]?.tax_return
          );
        case `p_applicant_people_attributes[${index}].other_type_tax_return`:
          return values.p_applicant_people_attributes?.[
            index
          ]?.type_tax_return?.includes(FileATaxReturnType.OTHERS);
        case `p_applicant_people_attributes[${index}].current_home_loan`:
          return (
            values.p_applicant_people_attributes[index]
              .applicant_detail_type === ApplicantDetailType.MAIN
          );
        case `p_applicant_people_attributes[${index}].transfer_office_name_kanji`:
        case `p_applicant_people_attributes[${index}].transfer_office_name_kana`:
        case `p_applicant_people_attributes[${index}].transfer_office_phone_number`:
        case `p_applicant_people_attributes[${index}].transfer_office_postal_code`:
        case `p_applicant_people_attributes[${index}].transfer_office_prefecture_kanji`:
        case `p_applicant_people_attributes[${index}].transfer_office_city_kanji`:
        case `p_applicant_people_attributes[${index}].transfer_office_district_kanji`:
        case `p_applicant_people_attributes[${index}].transfer_office_other_address_kanji`:
          return (
            values.p_applicant_people_attributes[index]?.transfer_office ===
            DispatchType.YES
          );
        case `p_applicant_people_attributes[${index}].two_years_ago_income`:
        case `p_applicant_people_attributes[${index}].nursing_leave_status`:
        case `p_applicant_people_attributes[${index}].bonus_income`:
          return isMCJ;
        case `p_applicant_people_attributes[${index}].maternity_paternity_leave_start_time`:
        case `p_applicant_people_attributes[${index}].maternity_paternity_leave_end_time`:
          return [
            LeaveType.ACQUIRING,
            LeaveType.IT_HAS_BEEN_ACQUIRED,
            LeaveType.SCHEDULED_ACQUISITION,
          ].includes(
            // @ts-ignore
            values.p_applicant_people_attributes[index]
              ?.maternity_paternity_leave_status
          );
      }

      return true;
    })
    .map((row) => {
      const { loan_desired_borrowing_date } =
        values.p_borrowing_details_attributes[0];
      if (
        row.name_en ===
        `p_applicant_people_attributes[${index}].maternity_paternity_leave_start_time`
      ) {
        switch (
          values.p_applicant_people_attributes[index]
            .maternity_paternity_leave_status
        ) {
          case LeaveType.SCHEDULED_ACQUISITION:
            return {
              ...row,
              options: {
                ...row.options,
                minDate: dayjs(
                  loan_desired_borrowing_date || undefined
                ).toString(),
                maxDate: dayjs(loan_desired_borrowing_date || undefined)
                  .add(4, 'y')
                  .toString(),
              },
            };
          case LeaveType.ACQUIRING:
            return {
              ...row,
              options: {
                ...row.options,
                minDate: dayjs(loan_desired_borrowing_date || undefined)
                  .subtract(4, 'y')
                  .toString(),
                maxDate: dayjs(
                  loan_desired_borrowing_date || undefined
                ).toString(),
              },
            };
          case LeaveType.IT_HAS_BEEN_ACQUIRED:
            return {
              ...row,
              options: {
                ...row.options,
                minDate: dayjs(loan_desired_borrowing_date || undefined)
                  .subtract(4, 'y')
                  .toString(),
                maxDate: dayjs(loan_desired_borrowing_date || undefined)
                  .subtract(1, 'M')
                  .toString(),
              },
            };
          default:
            return { ...row };
        }
      }

      if (
        row.name_en ===
        `p_applicant_people_attributes[${index}].maternity_paternity_leave_end_time`
      ) {
        switch (
          values.p_applicant_people_attributes[index]
            .maternity_paternity_leave_status
        ) {
          case LeaveType.SCHEDULED_ACQUISITION:
            return {
              ...row,
              options: {
                ...row.options,
                minDate: dayjs(
                  loan_desired_borrowing_date || undefined
                ).toString(),
                maxDate: dayjs(loan_desired_borrowing_date || undefined)
                  .add(4, 'y')
                  .toString(),
              },
            };
          case LeaveType.ACQUIRING:
            return {
              ...row,
              options: {
                ...row.options,
                minDate: dayjs(
                  loan_desired_borrowing_date || undefined
                ).toString(),
                maxDate: dayjs(loan_desired_borrowing_date || undefined)
                  .add(4, 'y')
                  .toString(),
              },
            };
          case LeaveType.IT_HAS_BEEN_ACQUIRED:
            return {
              ...row,
              options: {
                ...row.options,
                minDate: dayjs(loan_desired_borrowing_date || undefined)
                  .subtract(4, 'y')
                  .toString(),
                maxDate: dayjs(
                  loan_desired_borrowing_date || undefined
                ).toString(),
              },
            };
          default:
            return { ...row };
        }
      }

      return { ...row };
    });
};
