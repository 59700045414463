import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPWarningGradient: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 43 37" {...props}>
      <svg
        width="43"
        height="37"
        viewBox="0 0 43 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.514 23.6052C21.8751 23.6052 22.1707 23.3106 22.1707 22.9495V9.83549C22.1707 9.47436 21.8751 9.17979 21.514 9.17979C21.1528 9.17979 20.8593 9.47436 20.8593 9.83549V22.9495C20.8583 23.3101 21.1533 23.6052 21.514 23.6052ZM42.0292 31.5555L24.5466 1.72121C23.8745 0.573737 22.6942 0 21.514 0C20.3419 0 19.1534 0.573737 18.4076 1.72121L0.933197 31.5555C-0.33886 33.8423 1.33875 36.7192 4.03465 36.7192H38.9998C41.6767 36.7192 43.3733 33.8505 42.0292 31.5555ZM40.9063 34.3177C40.5128 34.998 39.7998 35.4078 38.9965 35.4078H4.03383C3.2315 35.4078 2.51835 34.9994 2.12657 34.3152C1.74315 33.6462 1.74618 32.8817 2.13505 32.2186L19.6124 2.38511C20.0059 1.71301 20.7189 1.3114 21.514 1.3114C22.3089 1.3114 23.0172 1.71195 23.4106 2.38429L40.8932 32.2186C41.2833 32.8833 41.2833 33.6456 40.9063 34.3177ZM21.5222 27.5394C20.7991 27.5394 20.2108 28.1277 20.2108 28.8508C20.2108 29.5738 20.7958 30.1622 21.5189 30.1622C22.2419 30.1622 22.8336 29.5738 22.8336 28.8508C22.8336 28.1277 22.2434 27.5394 21.5222 27.5394Z"
          fill="url(#paint0_linear_9219_27994)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_9219_27994"
            x1="6.96768"
            y1="29.1675"
            x2="34.7367"
            y2="-2.91573"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
