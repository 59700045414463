import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPNotiChatBubble: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 12" {...props}>
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.838835 0.838899L18.163 0.485346C18.163 0.485346 18.163 0.485346 0.485281 18.163C3.37401 10.7605 3.50061 6.9562 0.838835 0.838899Z"
          fill="#E54E75"
        />
      </svg>
    </SvgIcon>
  );
};
