import { yup, dayjs } from 'libs';

export const validationSchema = yup.object().shape({
  from_date: yup
    .string()
    .test('validateDate', '正しく選択してください。', (value) => {
      if (!value) return true;
      return dayjs(value, 'YYYY/MM/DD', true).isValid();
    }),
  to_date: yup
    .string()
    .test('validateDate', '正しく選択してください。', (value, context) => {
      if (!value) return true;
      return dayjs(value, 'YYYY/MM/DD', true).isValid();
    })
    .when('from_date', {
      is: (from_date: string) => !!from_date,
      then: yup
        .string()
        .test('validateDateDiff', 'validateDateDiff', (value, context) => {
          if (!value) return true;
          return dayjs(value).unix() >= dayjs(context.parent.from_date).unix();
        }),
    }),
});
