import { ChangeEvent, FC, useRef } from 'react';
import {
  Stack,
  SxProps,
  Modal,
  Typography,
  TextField,
  Divider,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FormikProvider, useFormik } from 'formik';
import { styleScrollBarModel, theme } from 'styles';
import {
  Button,
  FilterModalItem,
  InputDateTime,
  InputField,
  InputSelectProps,
  SPInputSelectNormal,
} from 'components';
import { checkboxIcon, unCheckIcon } from 'assets';
import { convertToFullWidth, formatCurrency, zeroPad } from 'utils';
import { dayjs } from 'libs/dayjs';
import { FILTER_MODAL_TYPE, PreliminaryStatus, epochYear } from 'constant';
import { useSelector } from 'react-redux';
import { masterBankSelectors } from 'pages/StepRequiredInformation/selectors';
import { masterDataSelectors } from 'pages/ManagerPreliminaries/selectors';
import {
  ManagerCurrentPreliminariesItemRequest,
  SalePersonPreliminariesItem,
} from 'services';
import { validationSchema } from './validationSchema';

interface FilterCurrentModalProps {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  type: FILTER_MODAL_TYPE;
  updateDataFilter: (
    dataFilter:
      | ManagerCurrentPreliminariesItemRequest
      | SalePersonPreliminariesItem
  ) => void;
}

export const FilterCurrentModal: FC<FilterCurrentModalProps> = (props) => {
  const masterBank = useSelector(masterBankSelectors);
  const masterData = useSelector(masterDataSelectors);

  type ClearDateTimeHandle = React.ElementRef<typeof InputDateTime>;
  const applicationFromDateRef = useRef<ClearDateTimeHandle>(null);
  const applicationToDateRef = useRef<ClearDateTimeHandle>(null);
  const executionFromDateRef = useRef<ClearDateTimeHandle>(null);
  const executionToDateRef = useRef<ClearDateTimeHandle>(null);

  const formik = useFormik({
    initialValues: {
      application_bank: [],
      applicant_name: '',
      application_from_date: '',
      application_to_date: '',
      execution_from_date: '',
      execution_to_date: '',
      application_amount_from: '',
      application_amount_to: '',
      examination_outcome: [],
      branch_names: [],
      office_names: [],
      exhibition_hall_names: [],
      sale_person_names: [],
      search: '',
    },
    validationSchema,
    async onSubmit(values) {
      props.onClose();
      const dataFilter:
        | ManagerCurrentPreliminariesItemRequest
        | SalePersonPreliminariesItem = {
        preliminary_status:
          props.type === FILTER_MODAL_TYPE.PAST
            ? PreliminaryStatus.PAST_PRELIMINARY
            : '',
        master_bank_codes: values.application_bank,
        applicant_name: convertToFullWidth(values.applicant_name),
        provisional_results: values.examination_outcome,
        ...((values.application_from_date || values.application_to_date) && {
          apply_date_range: {
            from: values.application_from_date,
            to: values.application_to_date,
          },
        }),
        ...((values.execution_from_date || values.execution_to_date) && {
          execution_date_range: {
            from: values.execution_from_date,
            to: values.execution_to_date,
          },
        }),
        ...((values.application_amount_from ||
          values.application_amount_to) && {
          loan_amount_range: {
            from: values.application_amount_from,
            to: values.application_amount_to,
          },
        }),
        branch_names: values.branch_names,
        office_names: values.office_names,
        exhibition_hall_names: values.exhibition_hall_names,
        sale_person_names: values.sale_person_names,
        // examination_outcome: values.examination_outcome,
      };
      props.updateDataFilter(dataFilter);
      // if (location.pathname.startsWith('/manager')) {
      //   await dispatch(sortCurrentPreliminariesItems(dataFilter));
      // } else {
      //   await dispatch(getCurrentSalePersonPreliminariesItems(dataFilter));
      // }
    },
  });

  const handleChangeApplicantName = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.setFieldValue(`applicant_name`, event.target.value);
  };

  const handleChangeManagerName = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.setFieldValue(`search`, event.target.value);
  };

  const handleClear = async () => {
    formik.resetForm();
    applicationFromDateRef.current?.clear();
    applicationToDateRef.current?.clear();
    executionFromDateRef.current?.clear();
    executionToDateRef.current?.clear();
  };

  const handleApplicationBankClick = (value: string) => {
    if (!!formik.values.application_bank.find((e: string) => e === value)) {
      const applicationBank = formik.values.application_bank.filter(
        (e: string) => e !== value
      );
      formik.setFieldValue(`application_bank`, applicationBank);
    } else {
      const applicationBank = [...formik.values.application_bank, value];
      formik.setFieldValue(`application_bank`, applicationBank);
    }
  };

  const handleExaminationOutcomeClick = (value: string) => {
    if (!!formik.values.examination_outcome.find((e: string) => e === value)) {
      const examinationOutcome = formik.values.examination_outcome.filter(
        (e: string) => e !== value
      );
      formik.setFieldValue(`examination_outcome`, examinationOutcome);
    } else {
      const examinationOutcome = [...formik.values.examination_outcome, value];
      formik.setFieldValue(`examination_outcome`, examinationOutcome);
    }
  };

  const handleBranchClick = (value: string) => {
    if (!!formik.values.branch_names.find((e: string) => e === value)) {
      const branch = formik.values.branch_names.filter(
        (e: string) => e !== value
      );
      formik.setFieldValue(`branch_names`, branch);
    } else {
      const branch = [...formik.values.branch_names, value];
      formik.setFieldValue(`branch_names`, branch);
    }
  };

  const handleBusinessOfficeClick = (value: string) => {
    if (!!formik.values.office_names.find((e: string) => e === value)) {
      const businessOffice = formik.values.office_names.filter(
        (e: string) => e !== value
      );
      formik.setFieldValue(`office_names`, businessOffice);
    } else {
      const businessOffice = [...formik.values.office_names, value];
      formik.setFieldValue(`office_names`, businessOffice);
    }
  };

  const handleExhibitionHallClick = (value: string) => {
    if (
      !!formik.values.exhibition_hall_names.find((e: string) => e === value)
    ) {
      const exhibitionHall = formik.values.exhibition_hall_names.filter(
        (e: string) => e !== value
      );
      formik.setFieldValue(`exhibition_hall_names`, exhibitionHall);
    } else {
      const exhibitionHall = [...formik.values.exhibition_hall_names, value];
      formik.setFieldValue(`exhibition_hall_names`, exhibitionHall);
    }
  };

  const handleManagerClick = (value: string) => {
    if (!!formik.values.sale_person_names.find((e: string) => e === value)) {
      const manager = formik.values.sale_person_names.filter(
        (e: string) => e !== value
      );
      formik.setFieldValue(`sale_person_names`, manager);
    } else {
      const manager = [...formik.values.sale_person_names, value];
      formik.setFieldValue(`sale_person_names`, manager);
    }
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...props?.sx,
      }}
      disableAutoFocus
      {...props}
    >
      <FormikProvider value={formik}>
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'main_white',
            '@media (orientation: landscape)': {
              height: '90vh',
            },
            width: { mobile: '90%', desktop: 485 },
            maxHeight: '85vh',
          }}
        >
          <Stack
            direction="row"
            sx={{
              height: 54,
              borderBottom: `1px solid ${theme?.palette?.line}`,
            }}
          >
            <Stack
              flex={1}
              sx={{
                justifyContent: 'center',
                pl: 3,
                color: 'clear_button_blue',
              }}
            >
              <Typography
                onClick={handleClear}
                variant="table_small_text"
                fontSize={12}
                sx={{ color: 'sp_primary_100_main', cursor: 'pointer' }}
              >
                すべてクリア
              </Typography>
            </Stack>
            <Stack flex={2} justifyContent="center">
              <Typography
                variant="required_chip_label"
                fontSize={16}
                sx={{ alignSelf: 'center', color: 'b_333' }}
              >
                {props.type === FILTER_MODAL_TYPE.PREVIEW
                  ? '仮審査中の案件の絞り込み'
                  : '過去の案件の絞り込み'}
              </Typography>
            </Stack>
            <Stack flex={1} justifyContent="center">
              <Stack
                sx={{
                  position: 'absolute',
                  right: 10,
                }}
              >
                <IconButton sx={{ p: 0 }} onClick={props.onClose}>
                  <CloseIcon fontSize="small" sx={{ color: 'close_icon' }} />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            pt={5}
            sx={{
              height: '100%',
              overflowY: 'scroll',
              paddingRight: '5px',
              backgroundColor: 'bg_off',
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            spacing={5}
          >
            <Stack
              px={{ tablet: 5, mobile: 5 }}
              sx={{
                overflowY: 'scroll',
                ...styleScrollBarModel,
              }}
              spacing={1}
            >
              <FilterModalItem
                name="application_bank"
                title="申込銀行"
                conditionExpanded={formik.values.application_bank.length > 0}
              >
                <Stack spacing={3} bgcolor="bg_off" px={14}>
                  {masterBank.map((opt, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      px={3}
                      spacing={1}
                      onClick={() => handleApplicationBankClick(opt.code)}
                    >
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="main_white"
                        height={13}
                        width={13}
                        border={`1px solid ${theme?.palette?.h_blugreen}`}
                        borderRadius={1}
                      >
                        <img
                          src={
                            formik.values.application_bank.find(
                              (e: string) => opt.code === e
                            )
                              ? checkboxIcon
                              : unCheckIcon
                          }
                          alt=""
                          height={7}
                          width={9}
                        />
                      </Stack>
                      <Typography
                        variant="required_chip_label"
                        fontSize={14}
                        fontWeight={300}
                        sx={{ lineHeight: '20px', pt: '2px' }}
                      >
                        {opt.name}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </FilterModalItem>

              <Stack
                spacing={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  bgcolor: 'main_white',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '2px',
                  justifyItems: 'center',
                  width: '100%',
                }}
              >
                <Stack
                  spacing={-5}
                  sx={{
                    height: '112px',
                    justifyContent: 'space-around',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="required_chip_label"
                    px={17}
                    fontSize={14}
                  >
                    申込人
                  </Typography>
                  <InputField
                    name="applicant_name"
                    placeholder="入力してください"
                    convertFullWidth
                    sx={{
                      paddingLeft: 17,
                      paddingRight: 17,
                      height: 44,
                      '.MuiInputBase-input': {
                        ...theme.typography.input_modal_filter,
                      },
                      '&&&& fieldset': {
                        borderWidth: 0,
                        borderBottomWidth: 1,
                        borderColor: 'b_333',
                        boxShadow: 'none',
                        borderRadius: 0,
                      },
                      '&&&& .Mui-success': {
                        '.MuiInputBase-input': {
                          backgroundColor: 'main_white',
                          boxShadow: 'none',
                        },
                        fieldset: {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'b_333',
                          boxShadow: 'none',
                        },
                      },
                      '&&&& .Mui-focused': {
                        '.MuiInputBase-input': {
                          backgroundColor: 'main_white',
                          color: 'b_333',
                        },
                        fieldset: {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'b_333',
                        },
                      },
                      '&&&& .Mui-focused.Mui-success': {
                        fieldset: {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'b_333',
                        },
                      },
                    }}
                    InputProps={{
                      autoComplete: 'off',
                      onChange: handleChangeApplicantName,
                    }}
                  />
                </Stack>
              </Stack>

              <FilterModalItem
                name=""
                title="申込日時"
                conditionExpanded={
                  formik.values.application_from_date !== '' ||
                  formik.values.application_to_date !== ''
                }
              >
                <Stack px={12} spacing={2}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                      width: { mobile: '100%', tablet: 200, desktop: '100%' },
                    }}
                  >
                    <InputDateTime
                      yearOptions={yearCurrentOptionsFromApplicant}
                      monthOptions={monthOptions}
                      name="application_from_date"
                      ref={applicationFromDateRef}
                      selectSx={{
                        height: 30,
                        width: 62,
                        backgroundColor: 'unset',
                        '&&&.Mui-success fieldset': {
                          borderWidth: 1,
                          borderColor: 'gray',
                        },
                        '&&&& fieldset': {
                          borderWidth: 1,
                        },
                        '&&&&& .MuiTypography-input_style': {
                          fontSize: '15px',
                          fontWeight: '300',
                          lineHeight: '15px',
                        },
                        '&&&&& .MuiTypography-placeHolder_style': {
                          fontSize: '10px',
                          lineHeight: '15px',
                        },
                        '&&&& .MuiSvgIcon-fontSizeMedium': {
                          width: 10,
                          height: 10,
                          top: 'calc(50% - 4px)',
                          right: '6px',
                        },
                        '&&&&& .MuiInputBase-input': {
                          padding: 1,
                        },
                      }}
                      unitSx={{ fontSize: 12 }}
                    />
                    <Typography
                      variant="text_dashboard"
                      color="normal_text"
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                        marginLeft: '4px',
                      }}
                    >
                      から
                    </Typography>
                  </Stack>
                  <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                      width: { mobile: '100%', tablet: 200, desktop: '100%' },
                    }}
                  >
                    <InputDateTime
                      yearOptions={yearCurrentOptionsToApplicant}
                      monthOptions={monthOptions}
                      name="application_to_date"
                      ref={applicationToDateRef}
                      selectSx={{
                        height: 30,
                        width: 62,
                        backgroundColor: 'unset',
                        '&&&.Mui-success fieldset': {
                          borderWidth: 1,
                          borderColor: 'gray',
                        },
                        '&&&& fieldset': {
                          borderWidth: 1,
                        },
                        '&&&&& .MuiTypography-input_style': {
                          fontSize: '15px',
                          fontWeight: '300',
                          lineHeight: '15px',
                        },
                        '&&&&& .MuiTypography-placeHolder_style': {
                          fontSize: '10px',
                          lineHeight: '15px',
                        },
                        '&&&& .MuiSvgIcon-fontSizeMedium': {
                          width: 10,
                          height: 10,
                          top: 'calc(50% - 4px)',
                          right: '6px',
                        },
                        '&&&&& .MuiInputBase-input': {
                          padding: 1,
                        },
                      }}
                      unitSx={{ fontSize: 12 }}
                    />
                    <Typography
                      variant="text_dashboard"
                      color="normal_text"
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                        marginLeft: '4px',
                      }}
                    >
                      まで
                    </Typography>
                  </Stack>
                </Stack>
              </FilterModalItem>

              <FilterModalItem
                name=""
                title="実行予定日"
                conditionExpanded={
                  formik.values.execution_from_date !== '' ||
                  formik.values.execution_to_date !== ''
                }
              >
                <Stack spacing={2} px={12}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                      width: { mobile: '100%', tablet: 200, desktop: '100%' },
                    }}
                  >
                    <InputDateTime
                      yearOptions={
                        props.type === FILTER_MODAL_TYPE.PREVIEW
                          ? yearCurrentOptions
                          : yearPastOptions
                      }
                      monthOptions={monthOptions}
                      name="execution_from_date"
                      ref={executionFromDateRef}
                      selectSx={{
                        height: 30,
                        width: 62,
                        backgroundColor: 'unset',
                        '&&&.Mui-success fieldset': {
                          borderWidth: 1,
                          borderColor: 'gray',
                        },
                        '&&&& fieldset': {
                          borderWidth: 1,
                        },
                        '&&&&& .MuiTypography-input_style': {
                          fontSize: '15px',
                          fontWeight: '300',
                          lineHeight: '15px',
                        },
                        '&&&&& .MuiTypography-placeHolder_style': {
                          fontSize: '10px',
                          lineHeight: '15px',
                        },
                        '&&&& .MuiSvgIcon-fontSizeMedium': {
                          width: 10,
                          height: 10,
                          top: 'calc(50% - 4px)',
                          right: '6px',
                        },
                        '&&&&& .MuiInputBase-input': {
                          padding: 1,
                        },
                      }}
                      unitSx={{ fontSize: 12 }}
                    />
                    <Typography
                      variant="text_dashboard"
                      color="normal_text"
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                        marginLeft: '4px',
                      }}
                    >
                      から
                    </Typography>
                  </Stack>
                  <Stack
                    alignItems="center"
                    direction="row"
                    sx={{
                      width: { mobile: '100%', tablet: 200, desktop: '100%' },
                    }}
                  >
                    <InputDateTime
                      yearOptions={
                        props.type === FILTER_MODAL_TYPE.PREVIEW
                          ? yearCurrentOptions
                          : yearPastOptions
                      }
                      monthOptions={monthOptions}
                      name="execution_to_date"
                      ref={executionToDateRef}
                      selectSx={{
                        height: 30,
                        width: 62,
                        backgroundColor: 'unset',
                        '&&&.Mui-success fieldset': {
                          borderWidth: 1,
                          borderColor: 'gray',
                        },
                        '&&&& fieldset': {
                          borderWidth: 1,
                        },
                        '&&&&& .MuiTypography-input_style': {
                          fontSize: '15px',
                          fontWeight: '300',
                          lineHeight: '15px',
                        },
                        '&&&&& .MuiTypography-placeHolder_style': {
                          fontSize: '10px',
                          lineHeight: '15px',
                        },
                        '&&&& .MuiSvgIcon-fontSizeMedium': {
                          width: 10,
                          height: 10,
                          top: 'calc(50% - 4px)',
                          right: '6px',
                        },
                        '&&&&& .MuiInputBase-input': {
                          padding: 1,
                        },
                      }}
                      unitSx={{ fontSize: 12 }}
                    />
                    <Typography
                      variant="text_dashboard"
                      color="normal_text"
                      sx={{
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                        marginLeft: '4px',
                      }}
                    >
                      まで
                    </Typography>
                  </Stack>
                </Stack>
              </FilterModalItem>

              {props.type === FILTER_MODAL_TYPE.PREVIEW && (
                <FilterModalItem
                  name=""
                  title="申込金額"
                  conditionExpanded={formik.values.application_amount_to !== ''}
                >
                  <Stack direction="row" px={17} spacing={3}>
                    <Stack direction="row" spacing={1}>
                      <SPInputSelectNormal
                        options={amountOptions}
                        value={formik.values.application_amount_from}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'application_amount_from',
                            e.target.value
                          );
                          if (
                            // @ts-ignore
                            +e.target.value >
                            +formik.values.application_amount_to
                          ) {
                            formik.setFieldValue('application_amount_to', '');
                          }
                        }}
                        sx={{
                          height: 30,
                          width: 100,
                          bgcolor: 'unset',
                          borderRadius: '4px 4px 0 0',
                          '&&&.Mui-success fieldset': {
                            borderWidth: 1,
                            borderColor: 'gray',
                          },
                          '&&&& fieldset': {
                            borderWidth: 1,
                          },
                          '&&&&& .MuiTypography-input_style': {
                            fontSize: '15px',
                            lineHeight: '15px',
                            fontWeight: '300',
                          },
                          '&&&&& .MuiTypography-placeHolder_style': {
                            fontSize: '10px',
                            lineHeight: '15px',
                          },
                          '&&&& .MuiSvgIcon-fontSizeMedium': {
                            width: 10,
                            height: 10,
                            top: 'calc(40% - 4px)',
                            right: '6px',
                          },
                          '&&&&& .MuiInputBase-input': {
                            padding: 1,
                          },
                        }}
                        placeholder="ー"
                        variant="standard"
                      />
                      <Typography
                        variant="text_dashboard"
                        color="normal_text"
                        sx={{
                          whiteSpace: 'nowrap',
                          pt: '7px',
                          fontSize: 12,
                          marginLeft: '2px',
                          alignSelf: 'center',
                        }}
                      >
                        万円から
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <SPInputSelectNormal
                        options={amountOptions.filter(
                          (amount) =>
                            +amount.value >=
                            +formik.values.application_amount_from
                        )}
                        value={formik.values.application_amount_to}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'application_amount_to',
                            e.target.value
                          )
                        }
                        sx={{
                          height: 30,
                          width: 100,
                          bgcolor: 'unset',
                          borderRadius: '4px 4px 0 0',
                          '&&&.Mui-success fieldset': {
                            borderWidth: 1,
                            borderColor: 'gray',
                          },
                          '&&&& fieldset': {
                            borderWidth: 1,
                          },
                          '&&&&& .MuiTypography-input_style': {
                            fontSize: '15px',
                            lineHeight: '15px',
                            fontWeight: '300',
                          },
                          '&&&&& .MuiTypography-placeHolder_style': {
                            fontSize: '10px',
                            lineHeight: '15px',
                          },
                          '&&&& .MuiSvgIcon-fontSizeMedium': {
                            width: 10,
                            height: 10,
                            top: 'calc(40% - 4px)',
                            right: '6px',
                          },
                          '&&&&& .MuiInputBase-input': {
                            padding: 1,
                          },
                        }}
                        placeholder="ー"
                        variant="standard"
                      />
                      <Typography
                        variant="text_dashboard"
                        color="normal_text"
                        sx={{
                          whiteSpace: 'nowrap',
                          pt: '7px',
                          fontSize: 12,
                          alignSelf: 'center',
                        }}
                      >
                        万円まで
                      </Typography>
                    </Stack>
                  </Stack>
                </FilterModalItem>
              )}

              {props.type === FILTER_MODAL_TYPE.PAST && (
                <FilterModalItem
                  name="examination_outcome"
                  title="仮審査結果"
                  conditionExpanded={
                    formik.values.examination_outcome.length > 0
                  }
                >
                  <Stack
                    sx={{ maxHeight: '125px', overflow: 'auto' }}
                    spacing={3}
                  >
                    {examinationOutcomeOptions.map((opt, index) => (
                      <Stack
                        key={index}
                        direction="row"
                        alignItems="center"
                        px={17}
                        spacing={1}
                        onClick={() => handleExaminationOutcomeClick(opt)}
                      >
                        <Stack
                          justifyContent="center"
                          alignItems="center"
                          bgcolor="main_white"
                          height={13}
                          width={13}
                          border={`1px solid ${theme?.palette?.h_blugreen}`}
                          borderRadius={1}
                        >
                          <img
                            src={
                              formik.values.examination_outcome.find(
                                (e: string) => opt === e
                              )
                                ? checkboxIcon
                                : unCheckIcon
                            }
                            alt=""
                            height={7}
                            width={9}
                          />
                        </Stack>
                        <Typography
                          variant="required_chip_label"
                          sx={{ lineHeight: '20px', pt: '2px' }}
                        >
                          {opt}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </FilterModalItem>
              )}

              <FilterModalItem
                name="branch"
                title="支店"
                conditionExpanded={formik.values.branch_names.length > 0}
              >
                <Stack
                  sx={{ maxHeight: '125px', overflow: 'auto' }}
                  spacing={3}
                >
                  {masterData.branch_names.map((opt, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      px={17}
                      spacing={1}
                      onClick={() => handleBranchClick(opt)}
                    >
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="main_white"
                        height={13}
                        width={13}
                        border={`1px solid ${theme?.palette?.h_blugreen}`}
                        borderRadius={1}
                      >
                        <img
                          src={
                            formik.values.branch_names.find(
                              (e: string) => opt === e
                            )
                              ? checkboxIcon
                              : unCheckIcon
                          }
                          alt=""
                          height={7}
                          width={9}
                        />
                      </Stack>
                      <Typography
                        variant="required_chip_label"
                        fontSize={14}
                        fontWeight={300}
                        sx={{ lineHeight: '20px', pt: '2px' }}
                      >
                        {opt}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </FilterModalItem>

              <FilterModalItem
                name="business_office"
                title="営業所"
                conditionExpanded={formik.values.office_names.length > 0}
              >
                <Stack
                  sx={{ maxHeight: '125px', overflow: 'auto' }}
                  spacing={3}
                >
                  {masterData.office_names.map((opt, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      px={17}
                      spacing={1}
                      onClick={() => handleBusinessOfficeClick(opt)}
                    >
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="main_white"
                        height={13}
                        width={13}
                        border={`1px solid ${theme?.palette?.h_blugreen}`}
                        borderRadius={1}
                      >
                        <img
                          src={
                            formik.values.office_names.find(
                              (e: string) => opt === e
                            )
                              ? checkboxIcon
                              : unCheckIcon
                          }
                          alt=""
                          height={7}
                          width={9}
                        />
                      </Stack>
                      <Typography
                        variant="required_chip_label"
                        fontSize={14}
                        fontWeight={300}
                        sx={{ lineHeight: '20px', pt: '2px' }}
                      >
                        {opt}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </FilterModalItem>

              <FilterModalItem
                name="exhibition_hall"
                title="展示場"
                conditionExpanded={
                  formik.values.exhibition_hall_names.length > 0
                }
              >
                <Stack
                  sx={{ maxHeight: '125px', overflow: 'auto' }}
                  spacing={3}
                >
                  {masterData.exhibition_hall_names.map((opt, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      alignItems="center"
                      px={17}
                      spacing={1}
                      onClick={() => handleExhibitionHallClick(opt)}
                    >
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="main_white"
                        height={13}
                        width={13}
                        border={`1px solid ${theme?.palette?.h_blugreen}`}
                        borderRadius={1}
                      >
                        <img
                          src={
                            formik.values.exhibition_hall_names.find(
                              (e: string) => opt === e
                            )
                              ? checkboxIcon
                              : unCheckIcon
                          }
                          alt=""
                          height={7}
                          width={9}
                        />
                      </Stack>
                      <Typography
                        variant="required_chip_label"
                        fontSize={14}
                        fontWeight={300}
                        sx={{ lineHeight: '20px', pt: '2px' }}
                      >
                        {opt}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </FilterModalItem>

              <FilterModalItem
                name="manager"
                title="営業担当者"
                conditionExpanded={formik.values.sale_person_names.length > 0}
              >
                <Stack spacing={3}>
                  <Stack
                    sx={{
                      height: 'auto',
                    }}
                  >
                    <TextField
                      name="search"
                      placeholder="キーワードを入力"
                      onChange={formik.handleChange}
                      sx={{
                        paddingLeft: 15,
                        height: 44,
                        width: '70%',
                        '.MuiInputBase-input': {
                          ...theme.typography.input_modal_filter,
                        },
                        '&&&& fieldset': {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'b_333',
                          boxShadow: 'none',
                          borderRadius: 0,
                        },
                        '&&&& .Mui-success': {
                          '.MuiInputBase-input': {
                            backgroundColor: 'main_white',
                            boxShadow: 'none',
                          },
                          fieldset: {
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderColor: 'b_333',
                            boxShadow: 'none',
                          },
                        },
                        '&&&& .Mui-focused': {
                          '.MuiInputBase-input': {
                            backgroundColor: 'main_white',
                            color: 'b_333',
                          },
                          fieldset: {
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderColor: 'b_333',
                          },
                        },
                        '&&&& .Mui-focused.Mui-success': {
                          fieldset: {
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderColor: 'b_333',
                          },
                        },
                      }}
                      InputProps={{
                        autoComplete: 'off',
                        onChange: handleChangeManagerName,
                      }}
                      value={formik.values.search}
                    />
                  </Stack>
                  <Stack px={10}>
                    <Divider />
                  </Stack>
                  <Stack sx={{ height: '125px', overflow: 'auto' }} spacing={3}>
                    {masterData.sale_person_names
                      .filter((opt) =>
                        formik.values.search
                          ? opt.includes(formik.values.search)
                          : opt
                      )
                      .map((opt, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          alignItems="center"
                          px={17}
                          spacing={1}
                          onClick={() => handleManagerClick(opt)}
                        >
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="main_white"
                            height={13}
                            width={13}
                            border={`1px solid ${theme?.palette?.h_blugreen}`}
                            borderRadius={1}
                          >
                            <img
                              src={
                                formik.values.sale_person_names.find(
                                  (e: string) => opt === e
                                )
                                  ? checkboxIcon
                                  : unCheckIcon
                              }
                              alt=""
                              height={7}
                              width={9}
                            />
                          </Stack>
                          <Typography
                            variant="required_chip_label"
                            fontSize={14}
                            fontWeight={300}
                            sx={{ lineHeight: '20px', pt: '2px' }}
                          >
                            {opt}
                          </Typography>
                        </Stack>
                      ))}
                  </Stack>
                </Stack>
              </FilterModalItem>
            </Stack>
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  bgcolor: 'white',
                  boxShadow: 'none',
                  width: '200px',
                  height: '36px',
                  borderRadius: '2px',
                  minHeight: '36px',
                  border: '1px solid #6B70F0',
                  my: 8,
                }}
                onClick={() => formik.handleSubmit()}
              >
                <Typography variant="button01" color="sp_primary_100_main">
                  検索
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormikProvider>
    </Modal>
  );
};

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: String(zeroPad(index + 1)),
    label: String(zeroPad(index + 1)),
  }))
);

// 申込日時 - current - from
const yearCurrentOptionsFromApplicant: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() - 4 + index);
    return {
      value: year,
      label: `${year} (${epochYear[+year]})`,
    };
  })
);

// 申込日時 - current - to
const yearCurrentOptionsToApplicant: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(9), (_, index) => {
    const year = zeroPad(dayjs().year() - 4 + index);
    return {
      value: year,
      label: `${year} (${epochYear[+year]})`,
    };
  })
);

// 実行予定日
const yearCurrentOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    return {
      value: year,
      label: `${year} (${epochYear[+year]})`,
    };
  })
);

const yearPastOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(9), (_, index) => {
    const year = zeroPad(dayjs().year() - 4 + index);
    return {
      value: year,
      label: `${year} (${epochYear[+year]})`,
    };
  })
);

const amountOptions: InputSelectProps['options'] = Array.from(
  Array(40),
  (_, index) => ({
    value: zeroPad((index + 1) * 500 * 10000),
    label: formatCurrency(+zeroPad((index + 1) * 500)),
  })
);

const examinationOutcomeOptions = ['承認実行', '否決', '承認取消'];
