import { FC, Fragment, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  isMCJ: boolean;
}>;

export const SPSelectPairLoanModal: FC<ModalProps> = ({
  open,
  onClose,
  isMCJ = false,
}) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title={`ペアローンを\nお申し込みの方へ`}
      disableBackDropClick
      footer={
        <Stack spacing={6} justifyContent="center" alignItems="center">
          <Stack spacing={4}>
            <Stack
              sx={{
                flex: 1,
                height: 0,
                border: '1px dashed',
                borderColor: 'sp_primary_60',
              }}
            />
            <Stack direction="row" spacing={2} alignItems="center">
              <Icons.SPWarningGradient sx={{ width: 21, height: 18 }} />
              <Typography variant="sp_change_password_note" color="b_333">
                ペアローンを組むお相手も、別途仮審査申込が必要です。
              </Typography>
            </Stack>
            <Stack
              sx={{
                flex: 1,
                height: 0,
                border: '1px dashed',
                borderColor: 'sp_primary_60',
              }}
            />
            {isMCJ && (
              <Fragment>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Icons.SPWarningGradient sx={{ width: 21, height: 18 }} />
                  <Typography variant="sp_change_password_note" color="b_333">
                    ペアローンは住信SBIネット銀行でご利用いただけます。日本住宅ローンではペアローンをお取り扱いしておりませんのでご了承ください。
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flex: 1,
                    height: 0,
                    border: '1px dashed',
                    borderColor: 'sp_primary_60',
                  }}
                />
              </Fragment>
            )}
          </Stack>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};
