import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { spLogin } from 'pages/SPLogin/thunk';
import { spSignUp } from 'pages/SPScreenEmail/thunk';
import { getUnreadMessages } from 'pages/SPTop/thunk';

import { MessageItem } from 'services';
import { createMessage, getMessages } from './thunk';

interface InitialState {
  messages: EntityState<MessageItem>;
  totalUnreadMessages: number;
}

export const messageAdapter = createEntityAdapter<MessageItem>({});

export const initialState: InitialState = {
  messages: messageAdapter.getInitialState(),
  totalUnreadMessages: 0,
};

const messageSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    readMessages: (state) => {
      state.totalUnreadMessages = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spSignUp.fulfilled, (state, action) => {
      state.totalUnreadMessages = +action.payload.data.total_unread_messages;
    });
    builder.addCase(spLogin.fulfilled, (state, action) => {
      state.totalUnreadMessages = +action.payload.data.total_unread_messages;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      messageAdapter.setAll(state.messages, action.payload.data);
    });
    builder.addCase(createMessage.fulfilled, (state, action) => {
      messageAdapter.addOne(state.messages, action.payload.data);
    });
    builder.addCase(getUnreadMessages.fulfilled, (state, action) => {
      state.totalUnreadMessages = action.payload.total_unread_messages;
    });
  },
});

export const { readMessages } = messageSlice.actions;
export default messageSlice.reducer;
