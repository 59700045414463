import { FC, useCallback, useEffect, useState } from 'react';
import {
  styled,
  Container,
  Stack,
  Typography,
  TableRow,
  TableBody,
  Table,
  TableCell,
  tableCellClasses,
  TableContainer,
} from '@mui/material';
import { Button } from 'components';
import { useAppDispatch } from 'hooks';
import { FormData as FormikData } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepLayout } from 'containers';
import { routeNames } from 'navigations/routes';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { handleNewSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { getFormData } from '../thunk';
import { updatePreliminaries } from '../../Preliminaries/thunk';
import { currentPremilinarySelectors } from '../../Preliminaries/selectors';
import {
  editableSelector,
  isEditingSelector,
  stepSynthesisSelector,
} from '../../StepSynthesisInformation/selectors';

const StepFourAPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedPreliminary = useSelector(currentPremilinarySelectors);
  const location = useLocation();
  const values = useSelector(stepSynthesisSelector);
  const isEditing = useSelector(isEditingSelector);
  const editable = useSelector(editableSelector);
  const [mainData, setMainData] = useState<FormikData[]>([]);
  const [pairData, setPairData] = useState<FormikData[]>([]);

  useEffect(() => {
    const getData = async () => {
      const result = await dispatch(getFormData({}));
      if (getFormData.fulfilled.match(result)) {
        setMainData(result.payload.data.main);
        setPairData(result.payload.data.pair);
      }
    };
    getData();
  }, [dispatch]);

  const handleUpdatePreliminaries = useCallback(
    async (id: string) => {
      const data = new FormData();

      data.append(
        'p_application_header',
        JSON.stringify(values.p_application_header)
      );
      data.append(
        'main_p_applicant_person',
        JSON.stringify(values.main_p_applicant_person)
      );
      data.append(
        'pair_p_applicant_person',
        JSON.stringify(values.pair_p_applicant_person)
      );
      data.append(
        'master_bank_codes',
        JSON.stringify(values.master_bank_codes)
      );
      data.append(
        'p_referral_agency',
        JSON.stringify(values.p_referral_agency)
      );
      data.append(
        'master_bank_codes',
        JSON.stringify(values.master_bank_codes)
      );

      const result = await dispatch(updatePreliminaries({ id, data }));
      if (updatePreliminaries.fulfilled.match(result)) {
        toast.success('フォームを送信しました');
        navigate(routeNames.Dashboard.path);
      } else {
        toast.error('エラーが発生しました。');
      }
    },
    [
      dispatch,
      navigate,
      values.main_p_applicant_person,
      values.master_bank_codes,
      values.p_application_header,
      values.p_referral_agency,
      values.pair_p_applicant_person,
    ]
  );

  const onSaveDraft = async () => {
    await dispatch(
      handleNewSaveDraft({
        ...values,
        current_path: location.pathname,
      })
    );
    return;
  };

  return (
    <StepLayout
      title="申込内容確認"
      isEditing={isEditing}
      footer={{
        left: {
          onClick: () => {
            if (isEditing && !editable) {
              return navigate(routeNames.Dashboard.path);
            }
            navigate(routeNames.StepSynthesisInformation.path);
          },
        },
        right: {
          sx: {
            ...(isEditing && {
              bgcolor: 'sh_red',
            }),
          },
          disabled: !editable && isEditing,
          children: isEditing ? '修正内容を保存する' : '次へすすむ',
          onClick: () => {
            if (isEditing) {
              handleUpdatePreliminaries(selectedPreliminary.id);
            } else {
              navigate(routeNames.StepFourB.path);
            }
          },
        },
        sxIconRight: {
          ...(isEditing && {
            color: 'main_white',
          }),
        },
      }}
      onSaveDraft={onSaveDraft}
    >
      <Container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TableContainer sx={{ overflowX: 'hidden' }}>
          <Table>
            {mainData.map((table, index) => (
              <TableBody key={index}>
                <TableCell
                  colSpan={2}
                  sx={{ borderBottom: 'none', px: 0, pt: 9 }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ pb: 10, width: '100%' }}
                    spacing={5}
                  >
                    <Typography variant="h2" color="normal_text">
                      {table.label}
                    </Typography>
                  </Stack>
                </TableCell>
                {table.data.map((item) => (
                  <StyledTableRow key={item.name}>
                    <StyledTableCell sx={{ pl: 8, width: '40%' }}>
                      <Typography variant="h4" sx={{ color: 'Icon_color' }}>
                        {item.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ width: '60%' }}>
                      <Typography variant="textstyle_L" color="normal_text">
                        {item.value}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none' }} />
                  <TableCell
                    sx={{
                      paddingRight: 0,
                      paddingBottom: 0,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      borderBottom: 'none',
                    }}
                  >
                    <Stack key={index}>
                      <Button
                        sx={{
                          py: 0,
                          width: { mobile: 80, tablet: 100 },
                          minHeight: { mobile: 40, tablet: 50 },
                          height: { mobile: 40, tablet: 50 },
                          borderRadius: '5px',
                          borderWidth: 2,
                        }}
                        variant="outlined"
                        fullWidth
                        onClick={() =>
                          navigate(table.path, {
                            state: {
                              label: table.label,
                              note: table.note,
                            },
                          })
                        }
                      >
                        <Typography
                          variant="button_title_review"
                          color="normal_text"
                        >
                          {table.contentButton}
                        </Typography>
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
          {pairData.length > 0 && (
            <>
              <Stack
                bgcolor="pale_blue"
                sx={{
                  width: { mobile: '100%' },
                  mt: '250px',
                  py: 5,
                  pl: 5,
                  borderRadius: '5px',
                }}
              >
                <Typography variant="textButtonTopPage" color="normal_text">
                  「ペアローンの申込人」の方について入力してください。
                </Typography>
              </Stack>
              <Table>
                {pairData.map((table, index) => (
                  <TableBody key={index}>
                    <TableCell
                      colSpan={2}
                      sx={{ borderBottom: 'none', px: 0, pt: 9 }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pb: 10, width: '100%' }}
                        spacing={5}
                      >
                        <Typography variant="h2" color="normal_text">
                          {table.label}
                        </Typography>
                      </Stack>
                    </TableCell>
                    {table.data.map((item) => (
                      <StyledTableRow key={item.name}>
                        <StyledTableCell sx={{ pl: 8, width: '40%' }}>
                          <Typography variant="h4" sx={{ color: 'Icon_color' }}>
                            {item.name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ width: '60%' }}>
                          <Typography variant="textstyle_L" color="normal_text">
                            {item.value}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell
                        sx={{
                          paddingRight: 0,
                          paddingBottom: 0,
                          display: 'flex',
                          justifyContent: 'flex-end',
                          borderBottom: 'none',
                        }}
                      >
                        <Stack key={index}>
                          <Button
                            sx={{
                              py: 0,
                              width: { mobile: 80, tablet: 100 },
                              minHeight: { mobile: 40, tablet: 50 },
                              height: { mobile: 40, tablet: 50 },
                              borderRadius: '5px',
                              borderWidth: 2,
                            }}
                            variant="outlined"
                            fullWidth
                            onClick={() =>
                              navigate(table.path, {
                                state: {
                                  label: table.label,
                                  note: table.note,
                                },
                              })
                            }
                          >
                            <Typography
                              variant="button_title_review"
                              color="normal_text"
                            >
                              {table.contentButton}
                            </Typography>
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </>
          )}
        </TableContainer>
      </Container>
      {isEditing && !editable && (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            bgcolor: 'footer_popup_bg',
            position: 'fixed',
            bottom: 60,
            width: '100%',
            height: 62,
            ml: '-72px',
          }}
        >
          <Typography variant="popover_footer_text">
            ※現在審査中のため操作することができません。
          </Typography>
        </Stack>
      )}
    </StepLayout>
  );
};

export default StepFourAPage;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: 130,
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottomWidth: 2,
  borderBottomStyle: 'dashed',
  borderBottomColor: theme.palette.line,
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.bg_off,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.main_white,
  },
  '&:nth-last-of-type(1)': {
    border: 'none',
  },
}));
