import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPWarning: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 28 25" {...props}>
      <svg
        width="28"
        height="25"
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0093 15.7368C14.2501 15.7368 14.4472 15.5404 14.4472 15.2997V6.55699C14.4472 6.31624 14.2501 6.11986 14.0093 6.11986C13.7686 6.11986 13.5729 6.31624 13.5729 6.55699V15.2997C13.5722 15.5401 13.7689 15.7368 14.0093 15.7368ZM27.6861 21.037L16.031 1.14747C15.583 0.382491 14.7961 0 14.0093 0C13.2279 0 12.4356 0.382491 11.9384 1.14747L0.288798 21.037C-0.55924 22.5615 0.559165 24.4794 2.35644 24.4794H25.6665C27.4511 24.4794 28.5822 22.567 27.6861 21.037ZM26.9375 22.8784C26.6752 23.332 26.1999 23.6052 25.6644 23.6052H2.35589C1.821 23.6052 1.34557 23.333 1.08438 22.8768C0.828766 22.4308 0.830788 21.9211 1.09004 21.4791L12.7416 1.59007C13.0039 1.14201 13.4793 0.874266 14.0093 0.874266C14.5393 0.874266 15.0114 1.1413 15.2737 1.58952L26.9288 21.4791C27.1889 21.9222 27.1889 22.4304 26.9375 22.8784ZM14.0148 18.3596C13.5327 18.3596 13.1405 18.7518 13.1405 19.2338C13.1405 19.7159 13.5305 20.1081 14.0126 20.1081C14.4946 20.1081 14.889 19.7159 14.889 19.2338C14.889 18.7518 14.4956 18.3596 14.0148 18.3596Z"
          fill="#E54E75"
        />
      </svg>
    </SvgIcon>
  );
};
