import { PBorrowingAttribute, RootState } from 'types';
import { isEqual, unset, cloneDeep } from 'lodash';
import { createSelector } from 'reselect';
import { convertYenToMan } from 'utils';
import { CODEBANK } from 'constant';
import { initialState } from './slice';

export const stepSynthesisSelector = (state: RootState) =>
  state.step_synthesis.form;

export const editableSelector = (state: RootState) =>
  state.step_synthesis.editable;

export const isEditingSelector = (state: RootState) =>
  state.step_synthesis.isEditing;

export const masterBank = (state: RootState) => state.masterBank.form;

export const isInitialValidSynthesisSelector = createSelector(
  stepSynthesisSelector,
  (input) => {
    const cloneInput = cloneDeep(input);
    const cloneInitialState = cloneDeep(initialState.form);
    unset(cloneInput, 'p_application_header.master_bank_ids');
    unset(cloneInitialState, 'p_application_header.master_bank_ids');

    return !isEqual(cloneInput, cloneInitialState);
  }
);

export const convertSynthesisSelector = createSelector(
  stepSynthesisSelector,
  (input) => {
    const p_borrowings_attributes =
      input.main_p_applicant_person.p_borrowings_attributes?.map(
        (borrowing) => {
          const [loan_amount, current_balance_amount, annual_repayment_amount] =
            convertYenToMan([
              borrowing.loan_amount,
              borrowing.current_balance_amount,
              (borrowing as PBorrowingAttribute).annual_repayment_amount,
            ]);
          return {
            ...borrowing,
            loan_amount,
            current_balance_amount,
            ...(annual_repayment_amount && {
              annual_repayment_amount,
            }),
          };
        }
      );
    const [
      land_purchase_price,
      house_purchase_price,
      accessory_cost,
      additional_cost,
      refinancing_loan_balance,
      other_require_funds_breakdown,
      temporary_desired_loan_amount,
      saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
      halfyear_bonus,
      p_land_advance_plan_desired_loan_amount,
      p_land_advance_plan_halfyear_bonus,
      pair_temporary_desired_loan_amount,
      pair_halfyear_bonus,
      house_upgrade_cost,
      deposit_savings_1,
      deposit_savings_2,
      real_estate_sale_price,
      other_saving_amount,
      total_require_funds,
      total_procurement,
    ] = convertYenToMan([
      input.p_application_header.land_purchase_price,
      input.p_application_header.house_purchase_price,
      input.p_application_header.accessory_cost,
      input.p_application_header.additional_cost,
      input.p_application_header.refinancing_loan_balance,
      input.p_application_header.other_require_funds_breakdown,
      input.main_p_applicant_person.p_borrowing_detail_attributes
        .temporary_desired_loan_amount,
      input.p_application_header.saving_amount,
      input.p_application_header.relative_donation_amount,
      input.p_application_header.loan_amount,
      input.p_application_header.pair_loan_amount,
      input.p_application_header.other_procurement_breakdown,
      input.main_p_applicant_person.p_borrowing_detail_attributes
        .halfyear_bonus,
      input.main_p_applicant_person.p_land_advance_plan_attributes
        .desired_loan_amount,
      input.main_p_applicant_person.p_land_advance_plan_attributes
        .halfyear_bonus,
      input.pair_p_applicant_person.p_borrowing_detail_attributes
        .temporary_desired_loan_amount,
      input.pair_p_applicant_person.p_borrowing_detail_attributes
        .halfyear_bonus,
      input.p_application_header.house_upgrade_cost,
      input.p_application_header.deposit_savings_1,
      input.p_application_header.deposit_savings_2,
      input.p_application_header.real_estate_sale_price,
      input.p_application_header.other_saving_amount,
      input.p_application_header.total_require_funds,
      input.p_application_header.total_procurement,
    ]);
    return {
      land_purchase_price,
      house_purchase_price,
      accessory_cost,
      additional_cost,
      refinancing_loan_balance,
      other_require_funds_breakdown,
      temporary_desired_loan_amount,
      saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
      halfyear_bonus,
      p_land_advance_plan_desired_loan_amount,
      p_land_advance_plan_halfyear_bonus,
      pair_temporary_desired_loan_amount,
      pair_halfyear_bonus,
      p_borrowings_attributes,
      house_upgrade_cost,
      deposit_savings_1,
      deposit_savings_2,
      real_estate_sale_price,
      other_saving_amount,
      total_require_funds,
      total_procurement,
    };
  }
);

export const checkMCJ = createSelector(stepSynthesisSelector, (input) => {
  const selectedCodes = input.master_bank_codes;
  return selectedCodes.includes(CODEBANK.MCJ);
});

export const checkSBI = createSelector(stepSynthesisSelector, (input) => {
  const selectedCodes = input.master_bank_codes;
  return selectedCodes.includes(CODEBANK.SBI);
});
