import { Location, useLocation } from 'react-router';

export interface LocationWithState<T> extends Location {
  state: { from?: T };
}

export const useAppLocation = <T>(): LocationWithState<T> =>
  useLocation() as LocationWithState<T>;

export interface ViewWithState<T, S> extends Location {
  state: { label?: T; note?: S; applicant_id?: T; preliminaries_id?: T };
}

export const useAppView = <T, S>(): ViewWithState<T, S> =>
  useLocation() as ViewWithState<T, S>;
