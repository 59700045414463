import { AdminPreliminaryItem, FundsPairOffType } from 'constant';
import { SalePersonPreliminaries } from 'services';
import { buildCurrentBorrowing } from '../../AdminEditPreliminary/utils';

export const buildCurrentBorrowingSalePerson = (
  managerRole: string,
  values: SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[][] => {
  return buildCurrentBorrowing(managerRole, values).map((row, index) =>
    row.filter(({ name_en, required }) => {
      if (required) return false;
      if (name_en === 'other_complete_repayment_type') {
        return values.completely_repayment_type === FundsPairOffType.OTHERS;
      }
      if (
        name_en === `p_borrowings_attributes[${index}].estate_mortgage` ||
        name_en === `p_borrowings_attributes[${index}].common_housing`
      ) {
        return false;
      }
      return true;
    })
  );
};
