import { StatusResult } from 'constant';
import { z } from 'zod';

export const loginRequestSchema = z.object({
  user: z.object({
    email: z.string(),
    password: z.string(),
  }),
});
export const adminLoginRequestSchema = z.object({
  manager: z.object({
    email: z.string(),
    password: z.string(),
  }),
});

export const salePersonLoginRequestSchema = z.object({
  s_sale_person: z.object({
    email: z.string().optional(),
    password: z.string().optional(),
    access_token: z.string().optional(),
  }),
});

export const preliminariesSchema = z.object({
  id: z.string(),
  applicant_ids: z.array(z.string()),
  applicant_name: z.array(z.string()),
  application_number: z.string(),
  bank_name: z.array(z.string()),
  created_at: z.string(),
  master_bank_codes: z.array(z.string()),
  store_name_kanji: z.string(),
  temporary_desired_loan_amount: z.array(z.string()),
});

export const loginResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    id: z.number(),
    access_token: z.string(),
    disable_pdf: z.boolean().nullable(),
    display_pdf: z.string().nullable(),
    draft_exits: z.boolean().optional(),
    master_bank_codes: z.array(z.string()).nullable(),
    status_result: z.nativeEnum(StatusResult).nullable(),
    total_unread_messages: z.string(),
    application_number: z.string().nullable(),
  }),
});

export const adminLoginSchema = z.object({
  id: z.string(),
  applicant_ids: z.array(z.string()),
  applicant_name: z.array(z.string()),
  application_number: z.string(),
  bank_name: z.array(z.string()),
  created_at: z.string(),
  loan_desired_borrowing_date: z.string(),
  manager_name_kanji: z.string(),
  sale_person_name_kanji: z.string(),
  store_name_kanji: z.string(),
  temporary_desired_loan_amount: z.array(z.string()),
});

export const adminLoginResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    access_token: z.string(),
    manager_id: z.string(),
    manager_name: z.string(),
    manager_role: z.string().optional(),
    preliminaries: z.array(adminLoginSchema).optional(),
  }),
});

export const salePersonLoginResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    access_token: z.string(),
    sale_person_id: z.string(),
    sale_person_name: z.string(),
    preliminaries: z.array(adminLoginSchema).optional(),
  }),
});

export const errorSchema = z.object({
  errors: z.array(
    z.object({
      field: z.string(),
      key: z.string(),
      resource: z.string(),
    })
  ),
});

export const errorAzureSchema = z.object({
  errors: z.string(),
  success: z.boolean(),
});

export const errorEmailSchema = z.object({
  success: z.boolean(),
  error: z.number(),
});

export const errorUpdateNewEmailSchema = z.object({
  success: z.boolean(),
  errors: z.number(),
});

export const signUpRequestSchema = z.object({
  user: z.object({
    email: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
});

export const signUpResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    access_token: z.string(),
  }),
});

export const resetPasswordRequestSchema = z.object({
  user: z.object({
    email: z.string(),
  }),
});

export const sendEmailRequestSchema = z.object({
  email: z.string(),
  path: z
    .object({
      sale_agent_id: z.string().optional(),
      store_id: z.string().optional(),
      exhibition_id: z.string().optional(),
    })
    .optional(),
});

export const resetPasswordManagerRequestSchema = z.object({
  manager: z.object({
    email: z.string(),
  }),
});

export const resetPasswordSalePersonRequestSchema = z.object({
  s_sale_person: z.object({
    email: z.string(),
  }),
});

export const resetPasswordResponseSchema = z.object({
  success: z.boolean(),
});

export const sendEmailResponseSchema = z.object({
  token: z.string(),
});

export const checkResetTokenRequestSchema = z.object({
  reset_password_token: z.string(),
});

export const checkSendEmailTokenRequestSchema = z.object({ token: z.string() });

export const checkResetTokenResponseSchema = z.object({
  success: z.boolean(),
});

export const checkSendEmailTokenResponseSchema = z.object({
  success: z.boolean(),
  email: z.string(),
});

export const updatePasswordRequestSchema = z.object({
  user: z.object({
    reset_password_token: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
});

export const spSignUpRequestSchema = z.object({
  user: z.object({
    email: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
  token: z.string(),
});

export const updatePasswordManagerRequestSchema = z.object({
  manager: z.object({
    reset_password_token: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
});

export const updatePasswordSalePersonRequestSchema = z.object({
  s_sale_person: z.object({
    reset_password_token: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
});

export const updatePasswordResponseSchema = z.object({
  success: z.boolean(),
});

export const spSignUpResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    access_token: z.string(),
    id: z.number(),
    total_unread_messages: z.string(),
  }),
});

export const changePasswordRequestSchema = z.object({
  user: z.object({
    current_password: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
});
export const changeEmailRequestSchema = z.object({
  current_email: z.string(),
  new_email: z.string(),
  email_confirmation: z.string(),
});
export const updateNewEmailRequestSchema = z.object({
  email_token: z.string(),
});

export const changePasswordManagerRequestSchema = z.object({
  manager: z.object({
    current_password: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
});

export const changePasswordSalePersonRequestSchema = z.object({
  s_sale_person: z.object({
    current_password: z.string(),
    password: z.string(),
    password_confirmation: z.string(),
  }),
});

export const changePasswordResponseSchema = z.object({
  success: z.boolean(),
});
export const changeEmailResponseSchema = z.object({
  token: z.string(),
});
export const updateNewEmailResponseSchema = z.object({
  success: z.boolean(),
  email: z.string(),
});

export type LoginRequest = z.infer<typeof loginRequestSchema>;
export type AdminLoginRequest = z.infer<typeof adminLoginRequestSchema>;
export type SalePersonLoginRequest = z.infer<
  typeof salePersonLoginRequestSchema
>;
export type LoginResponse = z.infer<typeof loginResponseSchema>;
export type AdminLoginResponse = z.infer<typeof adminLoginResponseSchema>;
export type SalePersonLoginResponse = z.infer<
  typeof salePersonLoginResponseSchema
>;
export type AdminLogin = z.infer<typeof adminLoginSchema>;
export type LoginError = z.infer<typeof errorSchema>;
export type AzureLoginError = z.infer<
  typeof errorAzureSchema | typeof errorSchema
>;
export type SignUpRequest = z.infer<typeof signUpRequestSchema>;
export type SignUpResponse = z.infer<typeof signUpResponseSchema>;
export type SignUpError = {};
export type ResetPasswordRequest = z.infer<typeof resetPasswordRequestSchema>;
export type SendEmailRequest = z.infer<typeof sendEmailRequestSchema>;
export type ResetPasswordManagerRequest = z.infer<
  typeof resetPasswordManagerRequestSchema
>;
export type ResetPasswordSalePersonRequest = z.infer<
  typeof resetPasswordSalePersonRequestSchema
>;
export type ResetPasswordResponse = z.infer<typeof resetPasswordResponseSchema>;
export type SendEmailResponse = z.infer<typeof sendEmailResponseSchema>;
export type ResetPasswordError = {};
export type SendEmailError = {};
export type CheckResetTokenRequest = z.infer<
  typeof checkResetTokenRequestSchema
>;
export type CheckSendEmailTokenRequest = z.infer<
  typeof checkSendEmailTokenRequestSchema
>;
export type CheckResetTokenResponse = z.infer<
  typeof checkResetTokenResponseSchema
>;
export type CheckSendEmailTokenResponse = z.infer<
  typeof checkSendEmailTokenResponseSchema
>;
export type CheckResetTokenError = {};
export type CheckSendEmailTokenError = {};
export type UpdatePasswordRequest = z.infer<typeof updatePasswordRequestSchema>;
export type SPSignUpRequest = z.infer<typeof spSignUpRequestSchema>;
export type UpdatePasswordManagerRequest = z.infer<
  typeof updatePasswordManagerRequestSchema
>;
export type UpdatePasswordSalePersonRequest = z.infer<
  typeof updatePasswordSalePersonRequestSchema
>;
export type UpdatePasswordResponse = z.infer<
  typeof updatePasswordResponseSchema
>;
export type SPSignUpResponse = z.infer<typeof spSignUpResponseSchema>;
export type UpdatePasswordError = {};
export type SPSignUpError = {};

export type Preliminaries = z.infer<typeof preliminariesSchema>;
export type ChangePasswordRequest = z.infer<typeof changePasswordRequestSchema>;
export type ChangeEmailRequest = z.infer<typeof changeEmailRequestSchema>;
export type UpdateNewEmailRequest = z.infer<typeof updateNewEmailRequestSchema>;
export type ChangePasswordManagerRequest = z.infer<
  typeof changePasswordManagerRequestSchema
>;
export type ChangePasswordSalePersonRequest = z.infer<
  typeof changePasswordSalePersonRequestSchema
>;
export type ChangePasswordResponse = z.infer<
  typeof changePasswordResponseSchema
>;
export type ChangeEmailResponse = z.infer<typeof changeEmailResponseSchema>;
export type UpdateNewEmailResponse = z.infer<
  typeof updateNewEmailResponseSchema
>;

export type ChangePasswordError = z.infer<typeof errorSchema>;
export type ChangeEmailError = z.infer<typeof errorEmailSchema>;
export type UpdateNewEmailError = z.infer<typeof errorUpdateNewEmailSchema>;
