import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPTriangleDown: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 19 9" {...props}>
      <svg
        width="19"
        height="9"
        viewBox="0 0 19 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 9L18.5933 0.75H0.406734L9.5 9Z"
          fill="url(#paint0_linear_6976_59042)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6976_59042"
            x1="2.23384"
            y1="0.262264"
            x2="9.10422"
            y2="13.5108"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
