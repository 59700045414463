import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton } from 'components';
import { SPStepLayout, SPChat } from 'containers';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { DisplayPdfStatus, StatusResult } from 'constant';
import { useSelector } from 'react-redux';
import {
  checkMCJ,
  displayPdfSelector,
  draftExitsSelector,
  spCurrentStepSelector,
  statusResultSelector,
} from 'containers/AuthModal/selectors';
import {
  useAppDispatch,
  useCheckCurrentStep,
  useSubmittedImages,
  useCheckCorrectable,
  useCheckPreliminaryStatus,
} from 'hooks';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
} from 'pages/SPStepOne/selectors';
import { supportDvh, zeroPad } from 'utils';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { toast } from 'react-toastify';
import { dayjs } from 'libs/dayjs';

type TopItem = {
  stepTitle: string;
  stepIcon: ReactNode;
  stepPath: string;
};

interface SPTopItemProps extends TopItem {
  currentStep: string;
  isCompleted: boolean;
  correctableStatus: boolean;
  stepNumber: string;
}

const SPTopPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const draft_exits = useSelector(draftExitsSelector);
  const displayPdf = useSelector(displayPdfSelector);
  const status_result = useSelector(statusResultSelector);
  const currentStepSelector = useSelector(spCurrentStepSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const { application_number } = useSelector(spStepTenSelector);
  const checkCurrentStep = useCheckCurrentStep();
  const handleSubmittedImages = useSubmittedImages();
  const correctableStatus = useCheckCorrectable();

  useEffect(() => {
    if (location.search.length > 0) {
      navigate(routeNames.Top.path);
    }
  }, [location.search.length, navigate]);

  useCheckPreliminaryStatus();

  const currentStep = useMemo(() => {
    // case form not submit
    if (Number(currentStepSelector) <= 8) return currentStepSelector;
    if (!application_number) {
      return currentStepSelector;
    }
    // case form submitted
    let result = 9;
    if (incomeTotalizer && hasJoinGuarantor) {
      result = 13;
    } else if (incomeTotalizer && !hasJoinGuarantor) {
      result = 12;
    } else if (!incomeTotalizer && hasJoinGuarantor) {
      result = 10;
    }
    return String(result);
  }, [
    currentStepSelector,
    hasJoinGuarantor,
    incomeTotalizer,
    application_number,
  ]);

  const isMCJ = useSelector(checkMCJ);
  const checkStepCompleted = useCallback(
    (stepNumber: number) => {
      let step = stepNumber;
      if (
        !correctableStatus ||
        location.pathname === routeNames.TopUpload.path
      ) {
        switch (stepNumber) {
          case 1:
            if (hasJoinGuarantor && incomeTotalizer) {
              step = 13;
              break;
            }
            if (incomeTotalizer) {
              step = 12;
              break;
            }
            if (hasJoinGuarantor) {
              step = 10;
              break;
            }
            step = 9;
            break;
          case 2:
            if (hasJoinGuarantor && incomeTotalizer) {
              step = 13;
              break;
            }
            if (incomeTotalizer) {
              step = 12;
              break;
            }
            if (hasJoinGuarantor) {
              step = 11;
              break;
            }
            step = 10;
            break;
          case 3:
            if (hasJoinGuarantor && incomeTotalizer) {
              step = 15;
              break;
            }
            if (incomeTotalizer) {
              step = 14;
              break;
            }
            if (hasJoinGuarantor) {
              step = 12;
              break;
            }
            step = 11;
            break;
          default:
            break;
        }
      }
      return +currentStep > step;
    },
    [
      correctableStatus,
      location.pathname,
      currentStep,
      hasJoinGuarantor,
      incomeTotalizer,
    ]
  );

  const checkStepNumber = useCallback(
    (stepNumber: number) => {
      if (
        !correctableStatus ||
        location.pathname === routeNames.TopUpload.path
      ) {
        switch (stepNumber) {
          case 1:
            if (hasJoinGuarantor && incomeTotalizer) return 13;
            if (incomeTotalizer) return 12;
            if (hasJoinGuarantor) return 10;
            return 9;
          case 2:
            if (hasJoinGuarantor && incomeTotalizer) return 14;
            if (incomeTotalizer) return 13;
            if (hasJoinGuarantor) return 11;
            return 10;
          case 3:
            if (hasJoinGuarantor && incomeTotalizer) return 15;
            if (incomeTotalizer) return 14;
            if (hasJoinGuarantor) return 12;
            return 11;
          default:
            break;
        }
      }
      return stepNumber;
    },
    [correctableStatus, hasJoinGuarantor, incomeTotalizer, location.pathname]
  );

  const topItems = useMemo(() => {
    const defaultTopItems: TopItem[] = [
      {
        stepTitle: 'お借入のご希望',
        stepIcon: <Icons.SPStep01 />,
        stepPath: routeNames.SPStepOne.path,
      },
      {
        stepTitle: 'あなたの情報',
        stepIcon: <Icons.SPStep02 />,
        stepPath: routeNames.SPStepTwo.path,
      },
      {
        stepTitle: 'あなたのご職業',
        stepIcon: <Icons.SPStep03 />,
        stepPath: routeNames.SPStepThree.path,
      },
      ...(incomeTotalizer
        ? [
            {
              stepTitle: '収入合算者',
              stepIcon: <Icons.SPStep02 />,
              stepPath: routeNames.SPStepFourIncomeTotalizer.path,
            },
            {
              stepTitle: '収入合算者の職業',
              stepIcon: <Icons.SPStep03 />,
              stepPath: routeNames.SPStepFiveIncomeTotalizer.path,
            },
          ]
        : []),
      ...(hasJoinGuarantor
        ? [
            {
              stepTitle: '担保提供者',
              stepIcon: <Icons.SPStep02 />,
              stepPath: routeNames.SPStepFourCollateralProvider.path,
            },
          ]
        : []),
      {
        stepTitle: 'お住まい',
        stepIcon: <Icons.SPStep04 />,
        stepPath: routeNames.SPStepFour.path,
      },
      {
        stepTitle: '現在のお借入状況',
        stepIcon: <Icons.SPStep05 />,
        stepPath: routeNames.SPStepFive.path,
      },
      {
        stepTitle: '資金計画',
        stepIcon: <Icons.SPStep06 />,
        stepPath: routeNames.SPStepSix.path,
      },
      {
        stepTitle: '書類添付',
        stepIcon: <Icons.SPStep07 />,
        stepPath: routeNames.SPStepSeven.path,
      },
      ...(incomeTotalizer
        ? [
            {
              stepTitle: '収入合算者の書類',
              stepIcon: <Icons.SPStep07 />,
              stepPath: routeNames.SPStepSevenIncomeTotalizer.path,
            },
          ]
        : []),
      {
        stepTitle: '担当者情報',
        stepIcon: <Icons.SPStep08 />,
        stepPath: routeNames.SPStepEight.path,
      },
      {
        stepTitle: '入力内容確認',
        stepIcon: <Icons.SPStep09 />,
        stepPath: routeNames.SPStepNine.path,
      },
      {
        stepTitle: '仮審査申込',
        stepIcon: <Icons.SPStep10 />,
        stepPath: !!application_number
          ? routeNames.SPConfirmApplicationDetail.path
          : routeNames.SPStepTen.path,
      },
      {
        stepTitle: '仮審査結果',
        stepIcon: <Icons.SPStep11 />,
        stepPath: routeNames.SPExaminationResult.path,
      },
      {
        stepTitle: '日本住宅ローン用\nPDF出力',
        stepIcon: <Icons.SPStep12 />,
        stepPath: routeNames.Top.path,
      },
    ];
    const hasSubmitTopItems: TopItem[] = [
      {
        stepTitle: 'お借入のご希望',
        stepIcon: <Icons.SPStep01 />,
        stepPath: routeNames.SPStepOne.path,
      },
      {
        stepTitle: 'あなたの情報',
        stepIcon: <Icons.SPStep02 />,
        stepPath: routeNames.SPStepTwo.path,
      },
      {
        stepTitle: 'あなたのご職業',
        stepIcon: <Icons.SPStep03 />,
        stepPath: routeNames.SPStepThree.path,
      },
      ...(incomeTotalizer
        ? [
            {
              stepTitle: '収入合算者',
              stepIcon: <Icons.SPStep02 />,
              stepPath: routeNames.SPStepFourIncomeTotalizer.path,
            },
            {
              stepTitle: '収入合算者の職業',
              stepIcon: <Icons.SPStep03 />,
              stepPath: routeNames.SPStepFiveIncomeTotalizer.path,
            },
          ]
        : []),
      ...(hasJoinGuarantor
        ? [
            {
              stepTitle: '担保提供者',
              stepIcon: <Icons.SPStep02 />,
              stepPath: routeNames.SPStepFourCollateralProvider.path,
            },
          ]
        : []),
      {
        stepTitle: 'お住まい',
        stepIcon: <Icons.SPStep04 />,
        stepPath: routeNames.SPStepFour.path,
      },
      {
        stepTitle: '現在のお借入状況',
        stepIcon: <Icons.SPStep05 />,
        stepPath: routeNames.SPStepFive.path,
      },
      {
        stepTitle: '資金計画',
        stepIcon: <Icons.SPStep06 />,
        stepPath: routeNames.SPStepSix.path,
      },
      {
        stepTitle: '書類添付',
        stepIcon: <Icons.SPStep07 />,
        stepPath: routeNames.SPStepSeven.path,
      },
      ...(incomeTotalizer
        ? [
            {
              stepTitle: '収入合算者の書類',
              stepIcon: <Icons.SPStep07 />,
              stepPath: routeNames.SPStepSevenIncomeTotalizer.path,
            },
          ]
        : []),
      {
        stepTitle: '担当者情報',
        stepIcon: <Icons.SPStep08 />,
        stepPath: routeNames.SPStepEight.path,
      },
      {
        stepTitle: '仮審査申込',
        stepIcon: <Icons.SPStep10 />,
        stepPath: !!application_number
          ? routeNames.SPConfirmApplicationDetail.path
          : routeNames.SPStepTen.path,
      },
      {
        stepTitle: '仮審査結果',
        stepIcon: <Icons.SPStep11 />,
        stepPath: routeNames.SPExaminationResult.path,
      },
      {
        stepTitle: '日本住宅ローン用\nPDF出力',
        stepIcon: <Icons.SPStep12 />,
        stepPath: routeNames.Top.path,
      },
    ];
    if (!application_number) {
      if (
        (!correctableStatus ||
          location.pathname === routeNames.TopUpload.path) &&
        isMCJ
      ) {
        if (incomeTotalizer && hasJoinGuarantor)
          return defaultTopItems.slice(13);
        if (incomeTotalizer) return defaultTopItems.slice(12);
        if (hasJoinGuarantor) return defaultTopItems.slice(10);
        return defaultTopItems.slice(9);
      }
      if (
        !correctableStatus ||
        location.pathname === routeNames.TopUpload.path
      ) {
        if (incomeTotalizer && hasJoinGuarantor)
          return defaultTopItems.slice(13, 15);
        if (incomeTotalizer) return defaultTopItems.slice(12, 14);
        if (hasJoinGuarantor) return defaultTopItems.slice(10, 12);
        return defaultTopItems.slice(9, 11);
      }
      if (incomeTotalizer && hasJoinGuarantor)
        return defaultTopItems.slice(0, 15);
      if (incomeTotalizer) return defaultTopItems.slice(0, 14);
      if (hasJoinGuarantor) return defaultTopItems.slice(0, 12);
      return defaultTopItems.slice(0, 11);
    } else {
      if (
        (!correctableStatus ||
          location.pathname === routeNames.TopUpload.path) &&
        isMCJ
      ) {
        if (incomeTotalizer && hasJoinGuarantor)
          return hasSubmitTopItems.slice(12);
        if (incomeTotalizer) return hasSubmitTopItems.slice(11);
        if (hasJoinGuarantor) return hasSubmitTopItems.slice(9);
        return hasSubmitTopItems.slice(8);
      }
      if (
        !correctableStatus ||
        location.pathname === routeNames.TopUpload.path
      ) {
        if (incomeTotalizer && hasJoinGuarantor)
          return hasSubmitTopItems.slice(12, 14);
        if (incomeTotalizer) return hasSubmitTopItems.slice(11, 13);
        if (hasJoinGuarantor) return hasSubmitTopItems.slice(9, 11);
        return hasSubmitTopItems.slice(8, 10);
      }
      if (incomeTotalizer && hasJoinGuarantor)
        return hasSubmitTopItems.slice(0, 14);
      if (incomeTotalizer) return hasSubmitTopItems.slice(0, 13);
      if (hasJoinGuarantor) return hasSubmitTopItems.slice(0, 11);
      return hasSubmitTopItems.slice(0, 10);
    }
  }, [
    application_number,
    correctableStatus,
    hasJoinGuarantor,
    incomeTotalizer,
    isMCJ,
    location.pathname,
  ]);

  const topItemsDisplayPdf = useMemo(() => {
    if (displayPdf === DisplayPdfStatus.NO) {
      return [...topItems].filter(
        (item) => item.stepPath !== routeNames.SPExaminationResult.path
      );
    }
    return [...topItems];
  }, [displayPdf, topItems]);

  const titleTop = useMemo(() => {
    if (status_result === StatusResult.DISCLOSURE_RESULTS_TO_APPLICANTS)
      return `審査結果が届きました\nご確認ください`;
    if (!correctableStatus) {
      // if (!status_result || +status_result < +StatusResult.APPROVAL) {
      //   return '仮審査の結果につきましては、\n提携先企業からお知らせいたします。';
      // }
      return `仮審査の結果につきましては、\n提携先企業からお知らせいたします。`;
    }
    if (!!application_number) return `お申込完了しています`;
    if (draft_exits) return `前回の続きから\n入力しましょう！`;
    return `STEPに沿って入力してください\n途中保存もできます！`;
  }, [application_number, correctableStatus, draft_exits, status_result]);

  const getSubmittedPreliminary = useCallback(async () => {
    const submittedPreliminary = await dispatch(spGetSubmittedPreliminary());
    if (spGetSubmittedPreliminary.fulfilled.match(submittedPreliminary)) {
      checkCurrentStep(submittedPreliminary.payload.data, status_result);
      handleSubmittedImages(submittedPreliminary.payload.data);
    }
  }, [checkCurrentStep, dispatch, handleSubmittedImages, status_result]);

  useEffect(() => {
    // @ts-ignore
    if (dayjs().subtract(5, 's').unix() - location?.state?.timeCallAPi < 0) {
      return;
    }
    // dispatch(getMasterBanks());
    // dispatch(getDynamicOptions());

    if (!!application_number) {
      //Get latest submitted preliminary will overwrite current data
      getSubmittedPreliminary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application_number, dispatch, getSubmittedPreliminary]);

  return (
    <SPStepLayout
      hasStepBar={false}
      hasModalSaveDraft={false}
      hasFooter={false}
    >
      <Stack
        sx={{
          width: '100%',
          minHeight: supportDvh('calc(100dvh - 41px)'),
          background: 'linear-gradient(180deg, #F9F1F7 0%, #B8D3F3 100%)',
        }}
      >
        <Stack textAlign="center" sx={{ my: 7 }}>
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
          >
            住宅ローン仮審査
          </Typography>
        </Stack>
        {location.pathname === routeNames.TopUpload.path ? (
          <Stack alignItems="center">
            <Stack
              alignItems="center"
              textAlign="center"
              sx={{
                bgcolor: 'main_white',
                border: (theme) =>
                  `1px solid ${theme?.palette?.sp_secondary_01}`,
                borderRadius: '8px',
                px: 4,
                pt: 4,
                pb: 3,
                minWidth: 240,
              }}
              spacing={3}
            >
              <Icons.SPWarning />
              <Typography
                variant="button_title"
                color="sp_secondary_01"
                sx={{
                  whiteSpace: 'break-spaces',
                  lineHeight: '20.8px',
                  letterSpacing: '0.6px',
                }}
              >
                {`書類に不備がございました\n再度アップロードをお願いします`}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                bgcolor: 'main_white',
                borderRadius: '14px',
                px: '18.5px',
                py: 3,
                textAlign: 'center',
                minWidth: 240,
              }}
              spacing={3}
            >
              <Icons.SPInfoModal sx={{ width: 28, height: 28 }} />
              <Typography
                variant="button_title"
                color="sp_primary_100_main"
                sx={{
                  whiteSpace: 'break-spaces',
                  lineHeight: '20.8px',
                  letterSpacing: '0.6px',
                }}
              >
                {titleTop}
              </Typography>
            </Stack>
          </Stack>
        )}
        <Stack
          justifyContent="flex-start"
          sx={{ mt: 7, width: '100%', flex: 1 }}
        >
          {topItemsDisplayPdf.map((topItem, index) => (
            <Fragment key={index}>
              <SPTopItem
                currentStep={currentStep}
                stepIcon={topItem.stepIcon}
                stepNumber={zeroPad(checkStepNumber(index + 1))}
                stepTitle={topItem.stepTitle}
                stepPath={topItem.stepPath}
                isCompleted={checkStepCompleted(index + 1)}
                correctableStatus={correctableStatus}
              />
              {index !== topItemsDisplayPdf.length - 1 && (
                <Stack alignItems="center" sx={{ mb: '-14px' }}>
                  <Icons.SPTriangleDown />
                </Stack>
              )}
            </Fragment>
          ))}
        </Stack>
        <Stack
          sx={{
            px: 6,
            pt: 12,
            pb: 22,
          }}
          spacing={3}
        >
          <Icons.SPMilize sx={{ width: 148, height: 32 }} />
          <Typography
            color="sp_logo_icon"
            variant="sp_footer_bank_name"
            sx={{ opacity: 0.6 }}
          >
            © 2023 みらいバンク Inc.
          </Typography>
        </Stack>
      </Stack>
      <SPChat />
    </SPStepLayout>
  );
};

export default SPTopPage;

const SPTopItem: FC<SPTopItemProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const status_result = useSelector(statusResultSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const { application_number } = useSelector(spStepTenSelector);
  const handleSubmittedImages = useSubmittedImages();

  const textButton = useMemo(() => {
    if (location.pathname === routeNames.TopUpload.path) {
      if (!!application_number) {
        switch (props.stepNumber) {
          case '09':
            return '書類の再提出';
          case '10':
            if (hasJoinGuarantor && !incomeTotalizer) return '書類の再提出';
            break;
          case '11':
            return 'ダウンロード';
          case '12':
            if (hasJoinGuarantor && !incomeTotalizer) return 'ダウンロード';
            if (incomeTotalizer && !hasJoinGuarantor) return '書類の再提出';
            break;
          case '13':
            if (hasJoinGuarantor && incomeTotalizer) return '書類の再提出';
            break;
          case '14':
            if (!hasJoinGuarantor && incomeTotalizer) return 'ダウンロード';
            break;
          case '15':
            if (hasJoinGuarantor && incomeTotalizer) return 'ダウンロード';
            break;
          default:
            return '審査結果';
        }
      } else {
        switch (props.stepNumber) {
          case '10':
            return '書類の再提出';
          case '11':
            if (hasJoinGuarantor) return '書類の再提出';
            break;
          case '12':
            return 'ダウンロード';
          case '13':
            if (hasJoinGuarantor) return 'ダウンロード';
            if (incomeTotalizer) return '書類の再提出';
            break;
          case '14':
            if (hasJoinGuarantor && incomeTotalizer) return '書類の再提出';
            break;
          case '15':
            if (!hasJoinGuarantor && incomeTotalizer) return 'ダウンロード';
            break;
          case '16':
            if (hasJoinGuarantor && incomeTotalizer) return 'ダウンロード';
            break;
          default:
            return '審査結果';
        }
      }
    }
    if (+props.currentStep === +props.stepNumber) {
      if (!!application_number) {
        switch (props.stepNumber) {
          case '08':
            if (incomeTotalizer) return '入力する';
            return '確認する';
          case '09':
            if (incomeTotalizer) return '入力する';
            return '申込内容確認';
          case '10':
            if (hasJoinGuarantor) return '申込内容確認';
            break;
          case '12':
            if (incomeTotalizer) return '申込内容確認';
            break;
          case '13':
            if (hasJoinGuarantor && incomeTotalizer) return '申込内容確認';
            break;
          default:
            return '入力する';
        }
      } else {
        switch (props.stepNumber) {
          case '09':
            if (incomeTotalizer) return '入力する';
            return '確認する';
          case '10':
            if (incomeTotalizer) return '入力する';
            return '申込内容確認';
          case '11':
            if (hasJoinGuarantor) return '申込内容確認';
            break;
          case '13':
            if (incomeTotalizer) return '申込内容確認';
            break;
          case '14':
            if (hasJoinGuarantor && incomeTotalizer) return '申込内容確認';
            break;
          default:
            return '入力する';
        }
      }
    }
    if (props.isCompleted) return '修正する';
    if (!props.correctableStatus) {
      if (!!application_number) {
        switch (props.stepNumber) {
          case '10':
            return '審査結果';
          case '11':
            if (hasJoinGuarantor) return '審査結果';
            return 'ダウンロード';
          case '12':
            if (hasJoinGuarantor) return 'ダウンロード';
            break;
          case '13':
            if (incomeTotalizer) return '審査結果';
            break;
          case '14':
            if (hasJoinGuarantor && incomeTotalizer) return '審査結果';
            return 'ダウンロード';
          case '15':
            return 'ダウンロード';
          default:
            return '---';
        }
      } else {
        switch (props.stepNumber) {
          case '11':
            return '審査結果';
          case '12':
            if (hasJoinGuarantor) return '審査結果';
            return 'ダウンロード';
          case '13':
            if (hasJoinGuarantor) return 'ダウンロード';
            break;
          case '14':
            if (incomeTotalizer) return '審査結果';
            break;
          case '15':
            if (hasJoinGuarantor && incomeTotalizer) return '審査結果';
            return 'ダウンロード';
          case '16':
            return 'ダウンロード';
          default:
            return '---';
        }
      }
    }
    return '---';
  }, [
    location.pathname,
    props.currentStep,
    props.stepNumber,
    props.isCompleted,
    props.correctableStatus,
    application_number,
    hasJoinGuarantor,
    incomeTotalizer,
  ]);
  const renderButton = useCallback(() => {
    if (
      (location.pathname === routeNames.TopUpload.path &&
        !application_number &&
        (props.stepNumber === '10' ||
          (hasJoinGuarantor && props.stepNumber === '11') ||
          (incomeTotalizer && props.stepNumber === '13') ||
          (hasJoinGuarantor &&
            incomeTotalizer &&
            props.stepNumber === '14'))) ||
      (location.pathname === routeNames.TopUpload.path &&
        !!application_number &&
        (props.stepNumber === '09' ||
          (hasJoinGuarantor && props.stepNumber === '10') ||
          (incomeTotalizer && props.stepNumber === '12') ||
          (hasJoinGuarantor && incomeTotalizer && props.stepNumber === '13')))
    )
      return (
        <SPButton
          sx={{
            bgcolor: 'main_white',
            border: '1px solid',
            borderColor: 'sp_secondary_01',
            borderRadius: '14px',
            width: 114,
            minHeight: 40,
            px: 6,
            whiteSpace: 'nowrap',
            color: 'sp_secondary_01',
          }}
          onClick={() => navigate(routeNames.SPDocumentUpload.path)}
        >
          <Typography variant="SP_multiple_checkbox_label">
            {textButton}
          </Typography>
        </SPButton>
      );

    if (
      +props.currentStep === +props.stepNumber ||
      ((props.stepNumber === '11' ||
        props.stepNumber === '12' ||
        props.stepTitle === '仮審査結果' ||
        props.stepNumber === '14' ||
        props.stepNumber === '15') &&
        status_result === StatusResult.DISCLOSURE_RESULTS_TO_APPLICANTS)
    )
      return (
        <SPButton
          sx={{
            bgcolor: 'sp_primary_100_main',
            borderRadius: '14px',
            width: 114,
            minHeight: 40,
            px: 6,
            whiteSpace: 'nowrap',
          }}
          onClick={() => navigate(props.stepPath)}
        >
          <Typography variant="SP_multiple_checkbox_label">
            {textButton}
          </Typography>
        </SPButton>
      );

    if (props.isCompleted)
      return (
        <SPButton
          sx={{
            bgcolor: 'sp_primary_20',
            color: 'sp_primary_100_main',
            borderRadius: '14px',
            border: '1px solid',
            borderColor: 'sp_primary_40',
            boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
            minWidth: 114,
            minHeight: 40,
            px: 6,
          }}
          onClick={() => {
            if (props.stepTitle === '日本住宅ローン用\nPDF出力') {
              //TODO: no spec for this button
              return toast.error('この機能はまだ使用できません。');
            }
            // dispatch(spGetSubmittedPreliminary());
            const getEditPrimaryData = async () => {
              const submittedPreliminary = await dispatch(
                spGetSubmittedPreliminary()
              );
              if (
                spGetSubmittedPreliminary.fulfilled.match(submittedPreliminary)
              ) {
                handleSubmittedImages(submittedPreliminary.payload.data);
              }
            };
            getEditPrimaryData();
            navigate(props.stepPath, {
              state: { pathName: window.location.pathname },
            });
          }}
        >
          <Typography variant="SP_multiple_checkbox_label">
            {textButton}
          </Typography>
        </SPButton>
      );

    return (
      <SPButton
        disabled
        sx={{
          borderRadius: '14px',
          width: 114,
          minHeight: 40,
          px: 6,
          whiteSpace: 'nowrap',
          '&.Mui-disabled': {
            bgcolor: 'sp_primary_100_main',
            opacity: 0.1,
            color: 'main_white',
          },
        }}
      >
        {textButton}
      </SPButton>
    );
  }, [
    application_number,
    dispatch,
    hasJoinGuarantor,
    incomeTotalizer,
    location.pathname,
    navigate,
    props.currentStep,
    props.isCompleted,
    props.stepNumber,
    props.stepPath,
    props.stepTitle,
    status_result,
    textButton,
    handleSubmittedImages,
  ]);

  return (
    <Stack sx={{ px: 4 }}>
      <Typography
        variant="sp_step"
        color="sp_primary_100_main"
        fontSize={16}
        sx={{ mb: '-3px' }}
      >
        STEP{' '}
        <Typography variant="sp_step" color="sp_primary_100_main" fontSize={24}>
          {props.stepNumber}
        </Typography>
      </Typography>
      <Stack
        direction="row"
        sx={{
          borderTop: '1px solid',
          borderColor: 'sp_primary_100_main',
          bgcolor: props.isCompleted ? 'sp_primary_20' : 'main_white',
          borderRadius: '0px 0px 4px 4px',
          px: 3,
          height: 64,
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          {props.isCompleted ? <Icons.SPCheckedCircle /> : props.stepIcon}

          <Typography
            variant="SP_multiple_checkbox_label"
            color="sp_primary_100_main"
            sx={{ whiteSpace: 'break-spaces' }}
          >
            {props.stepTitle}
          </Typography>
        </Stack>
        {renderButton()}
      </Stack>
    </Stack>
  );
};
