import { AxiosRequestConfig } from 'axios';
import { instance, internalInstance } from 'libs';
import { store } from '../store';
import {
  LoginRequest,
  LoginResponse,
  SignUpRequest,
  SignUpResponse,
  loginRequestSchema,
  loginResponseSchema,
  signUpRequestSchema,
  signUpResponseSchema,
  ResetPasswordRequest,
  ResetPasswordResponse,
  resetPasswordRequestSchema,
  resetPasswordResponseSchema,
  CheckResetTokenRequest,
  CheckResetTokenResponse,
  checkResetTokenRequestSchema,
  checkResetTokenResponseSchema,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
  updatePasswordRequestSchema,
  updatePasswordResponseSchema,
  AdminLoginRequest,
  AdminLoginResponse,
  adminLoginResponseSchema,
  adminLoginRequestSchema,
  ChangePasswordRequest,
  ChangePasswordResponse,
  changePasswordRequestSchema,
  changePasswordResponseSchema,
  ChangePasswordManagerRequest,
  changePasswordManagerRequestSchema,
  resetPasswordManagerRequestSchema,
  ResetPasswordManagerRequest,
  UpdatePasswordManagerRequest,
  updatePasswordManagerRequestSchema,
  SalePersonLoginRequest,
  salePersonLoginRequestSchema,
  SalePersonLoginResponse,
  salePersonLoginResponseSchema,
  SendEmailRequest,
  SendEmailResponse,
  sendEmailRequestSchema,
  sendEmailResponseSchema,
  CheckSendEmailTokenRequest,
  CheckSendEmailTokenResponse,
  checkSendEmailTokenRequestSchema,
  checkSendEmailTokenResponseSchema,
  SPSignUpRequest,
  SPSignUpResponse,
  spSignUpRequestSchema,
  spSignUpResponseSchema,
  changeEmailRequestSchema,
  changeEmailResponseSchema,
  ChangeEmailRequest,
  ChangeEmailResponse,
  UpdateNewEmailRequest,
  UpdateNewEmailResponse,
  updateNewEmailResponseSchema,
  updateNewEmailRequestSchema,
  ResetPasswordSalePersonRequest,
  resetPasswordSalePersonRequestSchema,
  UpdatePasswordSalePersonRequest,
  updatePasswordSalePersonRequestSchema,
  ChangePasswordSalePersonRequest,
  changePasswordSalePersonRequestSchema,
} from './schemas';

export const login = (data: LoginRequest): Promise<LoginResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/users/login',
    data: JSON.stringify(loginRequestSchema.parse(data)),
  };
  return internalInstance(request).then(({ data }) =>
    loginResponseSchema.parse(data)
  );
};

export const managerLogin = (
  data: AdminLoginRequest
): Promise<AdminLoginResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/managers/login',
    data: JSON.stringify(adminLoginRequestSchema.parse(data)),
  };
  return internalInstance(request).then(({ data }) =>
    adminLoginResponseSchema.parse(data)
  );
};

export const managerLogout = (): Promise<{}> => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: '/managers/logout',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return internalInstance(request);
};

export const managerTokenInvalidLogout = (): Promise<{}> => {
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: '/managers/logout',
  };
  return internalInstance(request);
};

export const salePersonLogin = (
  data: SalePersonLoginRequest
): Promise<SalePersonLoginResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/sale_people/login',
    data: JSON.stringify(salePersonLoginRequestSchema.parse(data)),
  };
  return internalInstance(request).then(({ data }) =>
    salePersonLoginResponseSchema.parse(data)
  );
};

export const salePersonLogout = (): Promise<{}> => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: '/sale_people/logout',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return internalInstance(request);
};

export const salePersonTokenInvalidLogout = (): Promise<{}> => {
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: '/sale_people/logout',
  };
  return internalInstance(request);
};

export const signUp = (data: SignUpRequest): Promise<SignUpResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/users/signup',
    data: signUpRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    signUpResponseSchema.parse(data)
  );
};

export const logout = (): Promise<{}> => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: '/users/logout',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return internalInstance(request);
};

export const tokenInvalidLogout = (): Promise<{}> => {
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: '/users/logout',
  };
  return internalInstance(request);
};

export const resetPassword = (
  data: ResetPasswordRequest
): Promise<ResetPasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/users/reset_password',
    data: resetPasswordRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    resetPasswordResponseSchema.parse(data)
  );
};

export const sendEmail = (
  data: SendEmailRequest
): Promise<SendEmailResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/user/verify_emails',
    data: sendEmailRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    sendEmailResponseSchema.parse(data)
  );
};

export const resetPasswordManager = (
  data: ResetPasswordManagerRequest
): Promise<ResetPasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/managers/reset_password',
    data: resetPasswordManagerRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    resetPasswordResponseSchema.parse(data)
  );
};

export const resetPasswordSalePerson = (
  data: ResetPasswordSalePersonRequest
): Promise<ResetPasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/sale_people/reset_password',
    data: resetPasswordSalePersonRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    resetPasswordResponseSchema.parse(data)
  );
};

export const checkResetToken = (
  data: CheckResetTokenRequest
): Promise<CheckResetTokenResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/users/reset_password/edit',
    params: checkResetTokenRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    checkResetTokenResponseSchema.parse(data)
  );
};

export const sendEmailToken = (
  data: CheckSendEmailTokenRequest
): Promise<CheckSendEmailTokenResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/verify_emails?token=${data.token}`,
    params: checkSendEmailTokenRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    checkSendEmailTokenResponseSchema.parse(data)
  );
};

export const checkResetTokenManager = (
  data: CheckResetTokenRequest
): Promise<CheckResetTokenResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/managers/reset_password/edit',
    params: checkResetTokenRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    checkResetTokenResponseSchema.parse(data)
  );
};

export const checkResetTokenSalePerson = (
  data: CheckResetTokenRequest
): Promise<CheckResetTokenResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/sale_people/reset_password/edit',
    params: checkResetTokenRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    checkResetTokenResponseSchema.parse(data)
  );
};

export const updatePassword = (
  data: UpdatePasswordRequest
): Promise<UpdatePasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PUT',
    url: '/users/reset_password',
    data: updatePasswordRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    updatePasswordResponseSchema.parse(data)
  );
};

export const spSignUp = (data: SPSignUpRequest): Promise<SPSignUpResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/users/signup',
    data: spSignUpRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    spSignUpResponseSchema.parse(data)
  );
};

export const updatePasswordManager = (
  data: UpdatePasswordManagerRequest
): Promise<UpdatePasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PUT',
    url: '/managers/reset_password',
    data: updatePasswordManagerRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    updatePasswordResponseSchema.parse(data)
  );
};

export const updatePasswordSalePerson = (
  data: UpdatePasswordSalePersonRequest
): Promise<UpdatePasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PUT',
    url: '/sale_people/reset_password',
    data: updatePasswordSalePersonRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    updatePasswordResponseSchema.parse(data)
  );
};

export const changePassword = (
  data: ChangePasswordRequest
): Promise<ChangePasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/user/change_password',
    data: changePasswordRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    changePasswordResponseSchema.parse(data)
  );
};

export const changePasswordManager = (
  data: ChangePasswordManagerRequest
): Promise<ChangePasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/manager/change_password',
    data: changePasswordManagerRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    changePasswordResponseSchema.parse(data)
  );
};

export const changePasswordSalePerson = (
  data: ChangePasswordSalePersonRequest
): Promise<ChangePasswordResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/s_sale_person/change_password',
    data: changePasswordSalePersonRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    changePasswordResponseSchema.parse(data)
  );
};

export const sendEmailUpdateEmail = (
  data: ChangeEmailRequest
): Promise<ChangeEmailResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/user/change_emails',
    data: changeEmailRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    changeEmailResponseSchema.parse(data)
  );
};

export const updateNewEmail = (
  data: UpdateNewEmailRequest
): Promise<UpdateNewEmailResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/change_emails`,
    params: updateNewEmailRequestSchema.parse(data),
  };
  return internalInstance(request).then(({ data }) =>
    updateNewEmailResponseSchema.parse(data)
  );
};

export const deleteUser = async (): Promise<void> => {
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: '/users/signup',
  };
  await instance(request);
};
