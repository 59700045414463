import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { UserLayout } from 'containers';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components';
import { Stack, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks';
import { ContactItemManager } from 'services';
import { OrderType } from 'types';
import { useSelector } from 'react-redux';
import { ArrowBackIos } from '@mui/icons-material';
import { routeNames } from 'navigations/routes';
import { selectedPreliminarySelectors } from '../ManagerPreliminaries/selectors';
import { unsetPremilinary } from '../ManagerPreliminaries/slice';
import { getManagerContacts, sortManagerContacts } from './thunk';
import { managerContactSelectors } from './selectors';
import { ManagerContactInformationItems } from './components/ContactInformationItems';

const ManagerContactInformationsPage: FC = () => {
  const dispatch = useAppDispatch();
  const managerContacts = useSelector(managerContactSelectors);
  const { id, applicant_id } = useSelector(selectedPreliminarySelectors);
  const [order, setOrder] = useState<OrderType>('desc');
  const [orderBy, setOrderBy] = useState<keyof ContactItemManager>('id');
  const navigate = useNavigate();

  useEffect(() => {
    if (!!id && !!applicant_id) {
      dispatch(getManagerContacts({ id, applicant_id }));
    }
  }, [applicant_id, dispatch, id]);

  const admin = false;

  const handleRequestSort = useCallback(
    (event: MouseEvent<unknown>, property: keyof ContactItemManager) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);

      dispatch(
        sortManagerContacts({
          id,
          applicant_id,
          order_key: property,
          order_sort: isAsc ? 'desc' : 'asc',
        })
      );
    },
    [applicant_id, dispatch, id, order, orderBy]
  );

  return (
    <UserLayout
      header={{
        left: { title: '連絡事項一覧' },
      }}
      footer={{
        left: (
          <Stack direction="row" sx={{ p: '5px 20px 11px 11px' }}>
            <Button
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
              onClick={() => {
                navigate(routeNames.ManagerPreliminaries.path);
                dispatch(unsetPremilinary());
              }}
            >
              <Typography variant="button_title" color="normal_text">
                もどる
              </Typography>
            </Button>
          </Stack>
        ),
        right: false,
        bgcolor: 'footer_bg',
      }}
      hasDrawer={false}
    >
      <Stack spacing={6} sx={{ pb: '29px' }}>
        <ManagerContactInformationItems
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          rows={managerContacts}
          isAdmin={admin}
        />
      </Stack>
    </UserLayout>
  );
};

export default ManagerContactInformationsPage;
