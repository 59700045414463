import { Stack, Typography } from '@mui/material';
import { Icons, SPButton } from 'components/atoms';
import { SP_FIELDS_TITLE } from 'constant';
import { useAppDispatch } from 'hooks';
import { FC, createRef, useImperativeHandle, useState } from 'react';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { SPModal } from '../SPModal';

type SPModalMoveToTopRef = {
  open: (
    title: string,
    message: string,
    messageErrors: string[],
    isNotMoveTop?: boolean
  ) => void;
  close: () => void;
};

export const spModalMoveToTopRef = createRef<SPModalMoveToTopRef>();

export const SPModalMoveToTop: FC = () => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [messageErrors, setMessageErrors] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [isNotMoveTop, setIsNotMoveTop] = useState<boolean>(false);

  useImperativeHandle(spModalMoveToTopRef, () => ({
    open(title, message, messageErrors, isNotMoveTop) {
      setTitle(title);
      setMessage(message);
      setMessageErrors(messageErrors);
      setOpen(true);
      !!isNotMoveTop && setIsNotMoveTop(isNotMoveTop);
    },
    close() {
      setOpen(false);
      setTitle('');
      setMessage('');
      setMessageErrors([]);
      setIsNotMoveTop(false);
    },
  }));

  const onModalButtonClick = () => {
    setOpen(false);
  };

  return (
    <SPModal
      open={open}
      onClose={async () => {
        const result = await dispatch(spGetSubmittedPreliminary());
        if (spGetSubmittedPreliminary.fulfilled.match(result)) {
          setOpen(false);
        }
      }}
      disableBackDropClick
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title={title}
      footer={
        <Stack textAlign="center" spacing={6}>
          <Typography variant="sp_modal_note" color="b_333">
            {message}
          </Typography>
          {messageErrors.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              textAlign="center"
              sx={{
                mt: 7,
                bgcolor: 'sp_secondary_pink_light',
                border: (theme) =>
                  `1px solid ${theme?.palette?.sp_secondary_01}`,
                borderRadius: '8px',
                mx: 4,
                px: 4,
                py: 2,
              }}
              spacing={3}
            >
              <Icons.SPWarning />
              <Stack textAlign="left" spacing={1}>
                {!messageErrors.find(
                  (e) => e === SP_FIELDS_TITLE.bank_is_conducting_a_review
                ) && (
                  <Typography variant="drawerText" color="sp_secondary_01">
                    以下の項目は必須項目です。
                  </Typography>
                )}
                <Typography variant="drawerText" color="sp_secondary_01">
                  {messageErrors?.map((message: string, index: number) => {
                    return index === messageErrors.length - 1
                      ? message
                      : !!message && `${message}, `;
                  })}
                </Typography>
              </Stack>
            </Stack>
          )}
          {!!isNotMoveTop && isNotMoveTop ? (
            <SPButton
              sx={{
                width: '100%',
                bgcolor: 'sp_primary_100_main',
              }}
              onClick={async () => {
                const result = await dispatch(spGetSubmittedPreliminary());
                if (spGetSubmittedPreliminary.fulfilled.match(result)) {
                  setOpen(false);
                }
              }}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="main_white"
              >
                閉じる
              </Typography>
            </SPButton>
          ) : (
            <SPButton
              sx={{
                width: '100%',
                bgcolor: 'sp_primary_100_main',
              }}
              endIcon={<Icons.ArrowForward stroke="white" />}
              onClick={onModalButtonClick}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="main_white"
              >
                閉じる
              </Typography>
            </SPButton>
          )}
        </Stack>
      }
    />
  );
};
