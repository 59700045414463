import { AdminPreliminaryItem } from 'constant';
import { SalePersonPreliminaries } from 'services';
import { buildPersonalResidence } from '../../AdminEditPreliminary/utils';

export const buildPersonalResidenceSalePerson = (
  managerRole: string,
  rows: AdminPreliminaryItem[],
  values: SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  return buildPersonalResidence(managerRole, rows, values);
};
