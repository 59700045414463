import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {}

const initialState: InitialState = {};

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {},
});

// export const {} = slice.actions;

export default landingSlice.reducer;
