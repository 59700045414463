import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStepChecked: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 14C8.41925 14 9.32951 13.8189 10.1788 13.4672C11.0281 13.1154 11.7997 12.5998 12.4497 11.9497C13.0998 11.2997 13.6154 10.5281 13.9672 9.67878C14.3189 8.82951 14.5 7.91925 14.5 7C14.5 6.08075 14.3189 5.1705 13.9672 4.32122C13.6154 3.47194 13.0998 2.70026 12.4497 2.05025C11.7997 1.40024 11.0281 0.884626 10.1788 0.532843C9.32951 0.18106 8.41925 -1.36979e-08 7.5 0C6.58075 -1.36979e-08 5.6705 0.18106 4.82122 0.532843C3.97194 0.884626 3.20026 1.40024 2.55025 2.05025C1.90024 2.70026 1.38463 3.47194 1.03284 4.32122C0.68106 5.1705 0.5 6.08075 0.5 7C0.5 7.91925 0.68106 8.82951 1.03284 9.67878C1.38463 10.5281 1.90024 11.2997 2.55025 11.9497C3.20026 12.5998 3.97194 13.1154 4.82122 13.4672C5.6705 13.8189 6.58075 14 7.5 14V14ZM10.7715 5.99375L7.27153 9.49375C6.97014 9.79514 6.47431 9.79514 6.17292 9.49375L4.22847 7.54931C3.92708 7.24792 3.92708 6.75208 4.22847 6.4507C4.52986 6.14931 5.02569 6.14931 5.32708 6.4507L6.72222 7.84583L9.67292 4.89514C9.97431 4.59375 10.4701 4.59375 10.7715 4.89514C11.0729 5.19653 11.0729 5.69236 10.7715 5.99375V5.99375Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
