import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAPI } from 'types';
import {
  authService,
  LoginRequest,
  LoginResponse,
  SignUpRequest,
  SignUpResponse,
  LoginError,
  SignUpError,
  PublicHolidayResponse,
  PublicHolidayRequest,
  publicHolidayService,
} from 'services';

export const login = createAsyncThunk<
  LoginResponse,
  LoginRequest,
  ThunkAPI<LoginError>
>('authModal/login', async (user, { rejectWithValue }) => {
  try {
    return await authService.login(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const signUp = createAsyncThunk<
  SignUpResponse,
  SignUpRequest,
  ThunkAPI<SignUpError>
>('authModal/signUp', async (user, { rejectWithValue }) => {
  try {
    return await authService.signUp(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const logout = createAsyncThunk(
  'authModal/logout',
  async (thunkAPI, { rejectWithValue }) => {
    try {
      return await authService.logout();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const tokenInvalidLogout = createAsyncThunk(
  'authModal/logout',
  async (thunkAPI, { rejectWithValue }) => {
    try {
      return await authService.tokenInvalidLogout();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPublicHolidays = createAsyncThunk<
  Record<number, PublicHolidayResponse[]>,
  PublicHolidayRequest,
  ThunkAPI<Error>
>('PublicHoliday', async (params, { rejectWithValue }) => {
  try {
    const { data: response } = await publicHolidayService.getPublicHolidays(
      params
    );
    return {
      [params.year]: [
        ...response,
        makeNewYearDate(`${params.year}-01-01`),
        makeNewYearDate(`${params.year}-01-02`),
        makeNewYearDate(`${params.year}-01-03`),
        makeNewYearDate(`${params.year}-12-31`),
      ],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

function makeNewYearDate(date: string): PublicHolidayResponse {
  return {
    counties: [],
    countryCode: 'JP',
    date,
    fixed: false,
    global: true,
    launchYear: 0,
    localName: '元日',
    name: "New Year's Day",
    type: ['Public'],
  };
}
