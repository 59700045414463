import { Link, Stack, Typography } from '@mui/material';
import {
  SPFormItem,
  SPImage,
  SPInputPhoneNumber,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPUploadImageItem,
  SPInputSelectNormal,
  Icons,
  SPInputField,
  SPSaveImageModal,
} from 'components';
import { config } from 'configs';
import {
  BorrowerIncomeType,
  GeneralIncomeConfirmation,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  SPBusinessCardType,
  spBusinessCard,
} from 'constant';
import { SPStepLayout } from 'containers';
import {
  accessTokenSelector,
  spCurrentStepSelector,
  userDataSelector,
} from 'containers/AuthModal/selectors';
import {
  checkingExistFile,
  convertImage,
  flattenToLodashFormat,
  getUserIdFromToken,
  supportDvh,
} from 'utils';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import { FormikProvider, getIn, useFormik } from 'formik';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import { get, isEmpty, update } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { spStepFiveSelector } from '../SPStepFive/selectors';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneSelector,
} from '../SPStepOne/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepSixSelector } from '../SPStepSix/selectors';
import { spStepTenSelector } from '../SPStepTen/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import { spStepTwoSelector } from '../SPStepTwo/selectors';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { spStepEightSelector } from './selectors';
import { saveForm, saveReferralAgencyId } from './slice';
import { getReferralAgencyOptionsAction } from './thunk';
import { validationSchema } from './validationSchema';

const SPStepEightPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    agenciesOptions,
    storesOptions,
    exhibitionsOptions,
    p_referral_agencies,
  } = useSelector(spStepEightSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const currentStep = useSelector(spCurrentStepSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const { application_number } = useSelector(spStepTenSelector);
  const accessToken = useSelector(accessTokenSelector);
  const { draftExits } = useSelector(userDataSelector);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const { updateFormOverride } = useUpdateForm();

  const {
    businessCard,
    setBusinessCard,
    residenceFile,
    residenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    repaymentScheduleImage,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [open, setOpen] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [readOnlyCompany, setReadOnlyCompany] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);

  const formik = useFormik({
    initialValues: p_referral_agencies,
    enableReinitialize: true,
    validationSchema,
    async onSubmit(values, { setSubmitting }) {
      const p_referral_agency_id = getReferralIdAction();
      await dispatch(
        saveForm({
          p_referral_agency_id,
          p_referral_agencies: {
            ...values,
          },
        })
      );
      if (p_referral_agency_id) {
        dispatch(saveReferralAgencyId(p_referral_agency_id));
      }
      if (incomeTotalizer && +currentStep < 12) {
        dispatch(setCurrentStep('12'));
      }
      if (incomeTotalizer && hasJoinGuarantor && +currentStep < 13) {
        dispatch(setCurrentStep('13'));
      }
      if (hasJoinGuarantor && +currentStep < 10) {
        dispatch(setCurrentStep('10'));
      }
      if (+currentStep < 9) dispatch(setCurrentStep('9'));
      setSubmitting(false);
      if (!!application_number) {
        setIsDisabledButton(true);
        updateFormOverride(
          {
            stepEightData: {
              p_referral_agency_id,
              p_referral_agencies: {
                ...values,
              },
            },
            imageContext: imageContext,
          },
          setIsDisabledButton,
          setOpenImageModal,
          setReset
        );
      } else {
        navigate(routeNames.SPStepNine.path);
      }
    },
  });

  const btnSubmit = useMemo(() => {
    return !!application_number ? '保存' : '次へ';
  }, [application_number]);

  const urls = [config.dummyPdfUrl, config.termConditionUrl];

  const getReferralIdAction = () => {
    const { sale_agent_id, store_id, exhibition_id } = formik.values;
    if (sale_agent_id && !store_id && !exhibition_id) {
      return agenciesOptions.find((item) => item.value === sale_agent_id)?.id;
    }
    if (store_id && !exhibition_id) {
      return storesOptions.find((item) => item.value === store_id)?.id;
    }
    if (!!exhibition_id)
      return exhibitionsOptions.find((item) => item.value === exhibition_id)
        ?.id;
    return '';
  };

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  const getReferralAgencyOption = useCallback(async () => {
    await dispatch(
      getReferralAgencyOptionsAction({
        ...(!!formik.values.sale_agent_id && {
          sale_agent_id: formik.values.sale_agent_id,
        }),
        ...(!!formik.values.store_id && {
          store_id: formik.values.store_id,
        }),
      })
    );
  }, [dispatch, formik.values.sale_agent_id, formik.values.store_id]);

  const handleFillDataQrcode = useCallback(async () => {
    const agenciesId = sessionStorage.getItem('sale_agent_id');
    const storesId = sessionStorage.getItem('store_id');
    const exhibitionId = sessionStorage.getItem('exhibition_id');

    if (agenciesId) {
      setReadOnlyCompany(true);
    }

    if (
      p_referral_agencies.sale_agent_id ||
      p_referral_agencies.store_id ||
      p_referral_agencies.exhibition_id
    ) {
      return;
    }

    if (agenciesId && storesId && exhibitionId) {
      const result = await dispatch(
        getReferralAgencyOptionsAction({
          sale_agent_id: agenciesId,
          store_id: storesId,
          exhibition_id: exhibitionId,
        })
      );
      if (getReferralAgencyOptionsAction.fulfilled.match(result)) {
        await formik.setFieldValue('sale_agent_id', agenciesId);
        await formik.setFieldValue('store_id', storesId);
        await formik.setFieldValue('exhibition_id', exhibitionId);
      }
      return;
    }
    if (agenciesId && storesId) {
      const result = await dispatch(
        getReferralAgencyOptionsAction({
          sale_agent_id: agenciesId,
          store_id: storesId,
        })
      );
      if (getReferralAgencyOptionsAction.fulfilled.match(result)) {
        await formik.setFieldValue('sale_agent_id', agenciesId);
        await formik.setFieldValue('store_id', storesId);
      }
      return;
    }
    if (agenciesId) {
      const result = await dispatch(
        getReferralAgencyOptionsAction({
          sale_agent_id: agenciesId,
        })
      );
      if (getReferralAgencyOptionsAction.fulfilled.match(result)) {
        await formik.setFieldValue('sale_agent_id', agenciesId);
      }
    }
  }, [
    dispatch,
    formik,
    p_referral_agencies.exhibition_id,
    p_referral_agencies.sale_agent_id,
    p_referral_agencies.store_id,
  ]);

  useEffect(() => {
    if (application_number) {
      return;
    }
    handleFillDataQrcode();
  }, [application_number]);

  useEffect(() => {
    if (application_number || draftExits) {
      setReadOnlyCompany(p_referral_agencies.sale_agent_id !== '');
    }
  }, [application_number, draftExits, p_referral_agencies.sale_agent_id]);

  useEffect(() => {
    const agenciesId = sessionStorage.getItem('sale_agent_id');
    if (agenciesId) {
      formik.setFieldValue('has_business_card', SPBusinessCardType.NO);
    }
  }, []);

  useEffect(() => {
    getReferralAgencyOption();
  }, [getReferralAgencyOption]);

  useEffect(() => {
    let newBusinessCardImage: (SPImage | File)[] = [];
    if (
      !!businessCard &&
      businessCard.length === 0 &&
      !!p_referral_agencies.business_card &&
      !!p_referral_agencies.business_card?.length
    ) {
      p_referral_agencies.business_card.forEach((file) => {
        if (!file.isDelete) {
          newBusinessCardImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!businessCard && !!businessCard.length) {
      newBusinessCardImage = [...businessCard];
    } else {
      p_referral_agencies.business_card.forEach((file) => {
        if (!file.isDelete) {
          newBusinessCardImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setBusinessCard(newBusinessCardImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p_referral_agencies.business_card]);

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);

    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });
    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });
    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/
    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEightValues } = formik.values;
    Object.keys(spStepEightValues).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEightValues, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    if (spSaveDraft.rejected.match(result)) {
      setReset(reset + 1);
    }
    return;
  };

  const checkPDFDisplay = () => {
    const checked = localStorage.getItem(
      `has_choose_business_card_${getUserIdFromToken(accessToken || '')}`
    );
    return !!checked;
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        onSaveForm={() =>
          dispatch(
            saveForm({
              p_referral_agencies: {
                ...formik.values,
              },
            })
          )
        }
        footer={{
          left: {
            onClick: async () => {
              await dispatch(
                saveForm({
                  p_referral_agencies: {
                    ...formik.values,
                  },
                })
              );
              if (!!application_number) {
                navigate(-1);
              } else {
                if (incomeTotalizer)
                  return navigate(routeNames.SPStepSevenIncomeTotalizer.path);
                navigate(routeNames.SPStepSeven.path);
              }
            },
          },
          right: {
            children: btnSubmit,
            onClick: () => {
              formik.handleSubmit();
            },
            disabled: isDisabledButton,
          },
        }}
        onSaveDraft={onSaveDraft}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack sx={{ py: 9 }} justifyContent="center" alignItems="center">
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >{`提携先企業（住宅メーカー・\n不動産会社等）の\n担当者を教えてください`}</Typography>
          </Stack>
          {formik.values.has_business_card === SPBusinessCardType.DEFAULT &&
            !checkPDFDisplay() &&
            !application_number && (
              <Stack sx={{ px: 6, pb: 8 }} spacing={4}>
                <Typography
                  variant="SP_form_item_note"
                  color="b_333"
                  sx={{ whiteSpace: 'break-spaces' }}
                >{`本件住宅ローンの借入申込に関わる事務（個人情報の受け渡しを含む）は提携先企業が行います。\n\nまた、みらいバンクは審査終了や審査結果の連絡を提携先企業に対して行います。\n\n詳しくは、既に同意いただいている以下の書類をよくお読みください。`}</Typography>
                {dummyPdf.map((item, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    key={index}
                  >
                    <Icons.SPPdfLite sx={{ width: '15px', height: '20px' }} />
                    <Link
                      href={urls[index]}
                      target="_blank"
                      component="a"
                      sx={{ textDecorationColor: 'sp_primary_100_main' }}
                      color="sp_primary_100_main"
                      variant="SP_input_error_text"
                      whiteSpace={index ? 'normal' : 'nowrap'}
                    >
                      {item.label}
                    </Link>
                  </Stack>
                ))}
              </Stack>
            )}
        </Stack>
        <SPFormItem
          label="担当者の名刺はありますか？"
          note="※名刺添付で入力を省略できます"
          childrenSx={{ p: '12px 16px 8px' }}
          required
        >
          <Stack spacing={3}>
            <SPRadioGroupButton
              name="has_business_card"
              options={spBusinessCardOptions}
              display="row"
              sx={{ height: 50 }}
              onChange={() => {
                if (!checkPDFDisplay() && !application_number) {
                  localStorage.setItem(
                    `has_choose_business_card_${getUserIdFromToken(
                      accessToken || ''
                    )}`,
                    'true'
                  );
                }
              }}
            />
            {formik.values.has_business_card === SPBusinessCardType.YES && (
              <SPUploadImageItem
                title="営業担当者の名刺をアップロードしてください。"
                images={businessCard ?? []}
                setImages={setBusinessCard}
                uploadMulti={false}
                reload={reset}
                handleDeleteImage={(id) => {
                  const business_card = convertImage(
                    formik.values.business_card,
                    id
                  );
                  formik.setFieldValue('business_card', business_card);
                }}
              />
            )}
            {formik.values.has_business_card === SPBusinessCardType.NO && (
              <Stack
                sx={{
                  borderRadius: 2,
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
              >
                <Stack
                  sx={{
                    p: '8px 16px 8px 16px',
                    bgcolor: 'sp_primary_100_main',
                    borderRadius: '7px 7px 0 0',
                  }}
                >
                  <Typography variant="SP_form_item_label" color="main_white">
                    担当者情報
                  </Typography>
                </Stack>
                <SPFormItem
                  label={
                    <Stack direction="row" spacing={1}>
                      <Typography variant="sp_unit_select_text" color="b_333">
                        提携先企業
                      </Typography>
                      <Typography
                        variant="sp_unit_select_text"
                        color="b_333"
                        sx={{ fontSize: 12 }}
                      >
                        （不動産会社・住宅メーカー等）
                      </Typography>
                    </Stack>
                  }
                  childrenSx={{ p: '12px 8px' }}
                  required
                >
                  <SPInputSelectNormal
                    placeholder="選択してください"
                    options={agenciesOptions.filter(
                      (item) => item.value !== ''
                    )}
                    readOnly={readOnlyCompany}
                    value={formik.values.sale_agent_id}
                    onChange={(e) => {
                      formik.setFieldValue('sale_agent_id', e.target.value);
                      formik.setFieldValue('store_id', '');
                      formik.setFieldValue('exhibition_id', '');
                      formik.setFieldValue('s_sale_person_id', '');
                    }}
                  />
                </SPFormItem>
                <SPFormItem
                  label="エリア"
                  childrenSx={{ p: '12px 8px' }}
                  required
                >
                  <SPInputSelectNormal
                    placeholder="選択してください"
                    options={storesOptions}
                    value={formik.values.store_id}
                    onChange={(e) => {
                      formik.setFieldValue('store_id', e.target.value);
                      formik.setFieldValue('exhibition_id', '');
                      formik.setFieldValue('s_sale_person_id', '');
                    }}
                  />
                </SPFormItem>
                <SPFormItem
                  label="展示場"
                  childrenSx={{ p: '12px 8px' }}
                  required
                >
                  <SPInputSelectNormal
                    placeholder="選択してください"
                    options={exhibitionsOptions}
                    value={formik.values.exhibition_id}
                    onChange={(e) => {
                      formik.setFieldValue('exhibition_id', e.target.value);
                      formik.setFieldValue('s_sale_person_id', '');
                    }}
                  />
                </SPFormItem>
                <SPFormItem
                  label="担当者名"
                  childrenSx={{ p: '12px 8px' }}
                  required
                >
                  <SPInputField
                    name="sale_person_name_input"
                    placeholder="例：○○さん"
                    onChange={(e) =>
                      formik.setFieldValue(
                        'sale_person_name_input',
                        e.target.value
                      )
                    }
                    convertFullWidth
                  />
                  {/*<SPInputSelectNormal*/}
                  {/*  placeholder="例：○○さん"*/}
                  {/*  options={salePersonsOptions}*/}
                  {/*  value={formik.values.s_sale_person_id}*/}
                  {/*  onChange={(e) =>*/}
                  {/*    formik.setFieldValue('s_sale_person_id', e.target.value)*/}
                  {/*  }*/}
                  {/*/>*/}
                </SPFormItem>
                <SPFormItem
                  label="携帯電話番号"
                  childrenSx={{ borderRadius: '0 0 8px 8px', p: '12px 8px' }}
                  required
                >
                  <Stack spacing={`6px`}>
                    <SPInputPhoneNumber
                      name="phone_number"
                      type="mobilePhone"
                    />
                    <Typography variant="SP_form_item_note" color="b_333">
                      ※半角数字でご入力ください。
                    </Typography>
                  </Stack>
                </SPFormItem>
              </Stack>
            )}
          </Stack>
        </SPFormItem>

        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
          }}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepEightPage;
type RadioItems = {
  value: string;
  label: string;
};

const spBusinessCardOptions: Array<RadioItems> = [
  {
    value: SPBusinessCardType.YES,
    label: spBusinessCard[SPBusinessCardType.YES],
  },
  {
    value: SPBusinessCardType.NO,
    label: spBusinessCard[SPBusinessCardType.NO],
  },
];

const dummyPdf = [
  { label: '個人情報の取扱いに関する同意書 兼 表明および確約書' },
  { label: '銀行代理業にかかる確認書　兼　個人情報の取扱い等に関する同意書' },
];
