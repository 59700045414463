import { AdminPreliminaryItem } from 'constant';
import { SalePersonPreliminaries } from 'services';
import { buildBorrowingDetail } from '../../AdminEditPreliminary/utils';

export const buildBorrowingDetailSalePerson = (
  values: SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  return buildBorrowingDetail(values).map((borrowingDetail) => {
    if (borrowingDetail.name_en === 'pair_loan_relationship_name') {
      return {
        ...borrowingDetail,
        required: false,
        subField: undefined,
      };
    }
    return borrowingDetail;
  });
};
