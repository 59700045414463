import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  number: number;
  title: string;
  description: string;
  note?: string | ReactNode;
  showArrow: boolean;
}

export const Procedure: FC<Props> = (props) => {
  return (
    <>
      <Grid item container>
        <Grid
          item
          container
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent={{ mobile: 'center' }}
          {...{ mobile: 'auto', flex: { mobile: 1, tablet: 0.5, md: 'none' } }}
        >
          <Stack
            sx={{
              borderRadius: 1.25,
              pt: 8,
              pb: 6,
              paddingLeft: '41px',
              paddingRight: '41px',
            }}
            bgcolor="pale_blue"
            alignItems="center"
          >
            <Typography
              variant="title_step_number"
              color="h_blugreen"
              sx={{
                lineHeight: '24px',
              }}
            >
              {props.number}
            </Typography>
            <Box
              sx={{
                width: 186,
                height: 107,
                bgcolor: 'pale_gray',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4,
              }}
            >
              <Typography variant="text_title" color="normal_text">
                イラスト
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid
          item
          container
          flex={{
            mobile: 'auto',
            tablet: 1.5,
            md: 1,
          }}
          ml={{
            md: 13,
            tablet: 6,
            mobile: 1,
          }}
          mt={{
            md: 0,
            tablet: 0,
            mobile: 4,
          }}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h3" color="normal_text">
            {props.title}
          </Typography>
          <Typography variant="textstyle" color="normal_text" sx={{ mt: 5 }}>
            {props.description}
          </Typography>
          {props.note && (
            <Stack sx={{ mt: 5 }}>
              {typeof props.note === 'string' ? (
                <Typography variant="style_guard" color="normal_text">
                  {props.note}
                </Typography>
              ) : (
                props.note
              )}
            </Stack>
          )}
        </Grid>
      </Grid>

      <Box
        sx={{ width: 268, height: 50 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {props.showArrow && (
          <ArrowForwardIos
            sx={{
              transform: 'rotate(90deg)',
              color: 'h_blugreen',
            }}
          />
        )}
      </Box>
    </>
  );
};
