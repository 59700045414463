import { FC, useMemo } from 'react';
import { Stack, Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button, InputField, InputSelect } from 'components';
import { FormikProvider, useFormik } from 'formik';
import { UserLayout } from 'containers';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { theme } from 'styles';
import { routeNames } from 'navigations/routes';
import { ArrowBackIos } from '@mui/icons-material';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { convertDynamicOptions } from 'utils';
import { currentPremilinarySelectors } from 'pages/Preliminaries/selectors';
import { Placeholder } from 'constant';
import { validationSchema } from './validationSchema';
import { createInquiry } from './thunk';

const Inquiry: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const { applicant_id, applicant_name } = useSelector(
    currentPremilinarySelectors
  );

  const formik = useFormik({
    initialValues: {
      applicant_first_name_kanji: '',
      applicant_last_name_kanji: '',
      applicant_id: '',
      inquiry_category: '',
      content: '',
    },
    validationSchema,
    async onSubmit(values, { resetForm }) {
      const result = await dispatch(
        createInquiry({
          applicant_id: values.applicant_id,
          applicant_name_kanji: (
            values.applicant_first_name_kanji +
            ' ' +
            values.applicant_last_name_kanji
          ).trim(),
          inquiry: {
            inquiry_category: values.inquiry_category,
            content: values.content,
          },
        })
      );
      if (createInquiry.fulfilled.match(result)) {
        toast.success('お問い合わせ内容を送信しました。');
        resetForm();
      }
    },
  });

  const inquiryCategoryOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.inquiry_category),
    [dynamicOptions.inquiry_category]
  );

  const applicantOptions = useMemo(
    () => [
      {
        value: applicant_id,
        label: applicant_name,
      },
    ],
    [applicant_id, applicant_name]
  );

  return (
    <UserLayout
      header={{
        left: { title: 'お問合せ' },
      }}
      footer={{
        left: (
          <Stack direction="row" sx={{ p: '5px 20px 11px 11px' }}>
            <Button
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
              onClick={() => navigate(routeNames.Dashboard.path)}
            >
              <Typography variant="button_title" color="normal_text">
                もどる
              </Typography>
            </Button>
          </Stack>
        ),
        right: false,
        bgcolor: 'footer_bg',
      }}
    >
      <FormikProvider value={formik}>
        <Stack sx={{ pt: '35px', pl: 18 }} direction="column">
          <Typography variant="h3" sx={{ pb: '30px' }} color="normal_text">
            お問合せ
          </Typography>
          <Typography variant="text_contact_inquiry" color="normal_text">
            お電話でのお問合せの場合は下記電話番号からお問い合わせください。
          </Typography>
          <Stack direction="row" sx={{ pt: 3, mb: '42px' }} alignItems="center">
            <Typography
              variant="text_contact_inquiry"
              sx={{ mr: 2 }}
              color="normal_text"
            >
              電話番号:
            </Typography>
            <Typography variant="text_contact_inquiry" color="phone_number">
              03-0000-0000
            </Typography>
          </Stack>
          <Grid container>
            <Grid item {...{ mobile: 12, md: 5 }}>
              <Stack sx={{ maxWidth: { mobile: '100%', tablet: 250 } }}>
                <InputSelect
                  name="inquiry_category"
                  checkRequired
                  placeholder="ご職業について"
                  options={inquiryCategoryOptions}
                  sx={{
                    width: 250,
                    height: 50,
                    '& .MuiTypography-placeHolder_style': {
                      color: 'black',
                    },
                    '&& .MuiSelect-select': {
                      textOverflow: 'ellipsis',
                    },
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Typography
            variant="text_contact_inquiry"
            color="normal_text"
            sx={{ mt: 9, mb: 9 }}
          >
            お問合せされる方のお名前
          </Typography>
          {!!applicant_id ? (
            <Grid container>
              <Grid item {...{ mobile: 12, md: 5 }}>
                <Stack sx={{ maxWidth: { mobile: '100%', tablet: 275 } }}>
                  <InputSelect
                    name="applicant_id"
                    checkRequired
                    placeholder="山田 太郎"
                    unit="様"
                    options={applicantOptions}
                    sx={{
                      width: 250,
                      height: 50,
                      '& .MuiTypography-placeHolder_style': {
                        color: 'black',
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={7}
              direction={{
                mobile: 'column',
                tablet: 'row',
                desktop: 'row',
              }}
            >
              <Grid item {...{ mobile: 6, md: 4 }}>
                <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
                  姓
                </Typography>
                <InputField
                  name="applicant_last_name_kanji"
                  placeholder={Placeholder.LAST_NAME_KANJI}
                />
              </Grid>
              <Grid item {...{ mobile: 6, md: 4 }}>
                <Typography sx={{ mb: 1 }} variant="h3" color="normal_text">
                  名
                </Typography>
                <InputField
                  name="applicant_first_name_kanji"
                  placeholder={Placeholder.FIRST_NAME_KANJI}
                />
              </Grid>
            </Grid>
          )}
          <Box sx={{ mt: 13 }}>
            <Grid container>
              <Grid item {...{ mobile: 12, md: 12 }}>
                <Stack sx={{ maxWidth: { mobile: '100%' } }}>
                  <InputField
                    multiline
                    name="content"
                    rows={10}
                    sxPopover={{ right: '20px' }}
                    sx={{
                      pr: 18,
                      '& .MuiInputBase-multiline': {
                        padding: '1.5px 1px',
                      },
                    }}
                    placeholder="お問合せ内容をここに入力してください。"
                    InputProps={{
                      sx: {
                        bgcolor: 'bg_off',
                        'textarea::placeholder': {
                          color: 'off_text',
                          ...theme.typography.text_placeholder,
                        },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Button
            disabled={formik.isSubmitting}
            sx={{
              width: 426,
              height: 55,
              backgroundColor: 'sh_red',
              bgcolor: 'sh_red',
              mt: 13,
              mx: 'auto',
              mb: 9,
            }}
            onClick={() => formik.handleSubmit()}
          >
            <Typography color="main_white">送信する</Typography>
          </Button>
          {!!applicant_id ? (
            <>
              <Typography
                variant="text_contact_inquiry_name_user"
                color="normal_text"
                sx={{ mx: 'auto', width: 886, mb: 5 }}
              >
                メールでお問合せの場合はご不明点を下記からお選びの上、内容を入力して送信してください。
              </Typography>
              <Typography
                variant="text_contact_inquiry_name_user"
                color="normal_text"
                sx={{ mx: 'auto', width: 886, mb: 17 }}
              >
                お問合せされる方が「申込人ご本人様に入力していただく項目」で登録いただいたメールアドレス
                へご送付いたします。
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="text_contact_inquiry_name_user"
                color="normal_text"
                sx={{ mx: 'auto', width: 886, mb: 5 }}
              >
                メールでお問合せの場合はご不明点を下記からお選びの上、内容を入力して送信してください。
              </Typography>
              <Typography
                variant="text_contact_inquiry_name_user"
                color="normal_text"
                sx={{ mx: 'auto', width: 886, mb: 17 }}
              >
                「新規登録」で登録いただいたメールアドレスへご送付いたします。
              </Typography>
            </>
          )}
        </Stack>
      </FormikProvider>
    </UserLayout>
  );
};

export default Inquiry;
