import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const House: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 72 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="72"
      height="69"
      viewBox="0 0 72 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.6172 39.0764V32.7529"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M9.9375 32.541V55.8509H27.9202V33.9931H41.6476V55.8509H49.9467"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M67.6328 28.7L56.6154 19.4469V1.0084H48.3754V12.524L34.6563 1L1.67969 28.7L6.98163 35.0234L34.6563 11.7811L62.3309 35.0234L67.6328 28.7Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M51.9609 57.7171V64.2432L51.9778 68.0001L67.2842 67.9748V63.7788L67.2673 57.7002L51.9694 57.7255L51.9609 57.7171Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M59.619 48.0252C62.0927 48.0252 64.0936 46.0244 64.0936 43.5507C64.0936 41.077 62.0927 39.0762 59.619 39.0762C57.1454 39.0762 55.1445 41.077 55.1445 43.5507C55.1445 46.0244 57.1454 48.0252 59.619 48.0252Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M54.3203 57.7171L54.3372 54.416"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.2774 64.5887L70.2829 61.1695L68.7041 52.651C68.7041 52.651 68.6957 52.6256 68.6872 52.6087C68.4762 51.469 67.995 50.5994 66.3149 49.561C65.1077 48.818 62.1105 48.0244 59.62 48.0244C57.1294 48.0244 54.1324 48.818 52.9251 49.561C51.245 50.5994 50.7637 51.469 50.5527 52.6087C50.5527 52.6256 50.5442 52.6341 50.5358 52.651L48.957 61.1695L51.9626 64.5887"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M64.9271 57.7171L64.9102 54.416"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6454 33.9932H27.918V55.8509H41.6454V33.9932Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
