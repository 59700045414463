import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  CheckResetTokenError,
  CheckResetTokenRequest,
  CheckResetTokenResponse,
  ResetPasswordError,
  ResetPasswordManagerRequest,
  ResetPasswordResponse,
  UpdatePasswordError,
  UpdatePasswordManagerRequest,
  UpdatePasswordResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const resetPasswordManager = createAsyncThunk<
  ResetPasswordResponse,
  ResetPasswordManagerRequest,
  ThunkAPI<ResetPasswordError>
>('authModal/resetPasswordManager', async (manager, { rejectWithValue }) => {
  try {
    return await authService.resetPasswordManager(manager);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const checkResetTokenManager = createAsyncThunk<
  CheckResetTokenResponse,
  CheckResetTokenRequest,
  ThunkAPI<CheckResetTokenError>
>('authModal/checkResetTokenManager', async (user, { rejectWithValue }) => {
  try {
    return await authService.checkResetTokenManager(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePasswordManager = createAsyncThunk<
  UpdatePasswordResponse,
  UpdatePasswordManagerRequest,
  ThunkAPI<UpdatePasswordError>
>('authModal/updatePasswordManager', async (user, { rejectWithValue }) => {
  try {
    return await authService.updatePasswordManager(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});
