import { Divider, Stack } from '@mui/material';
import { NumPad, ModalSaveInfo } from 'components';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useMatchedRoute } from 'hooks';
import { DrawerMenu, GroupButtonSaveInfo } from 'containers';
import {
  ModalSaveInfoContext,
  NumpadContext,
  StepLayoutContext,
} from 'context';
import { routeNames } from 'navigations/routes';
import { useLocation } from 'react-router-dom';
import { Header, Footer, FooterProps } from './components';

type LayoutProps = PropsWithChildren<{
  footer?: FooterProps;
  hasNumPad?: boolean;
  hasModalSaveDraft?: boolean;
  hasModalGetDraft?: boolean;
  hasFooter?: boolean;
  onSaveDraft?: () => void;
  title?: string;
  isEditing?: boolean;
}>;

export const StepLayout: FC<LayoutProps> = ({
  children,
  footer,
  hasNumPad,
  hasModalSaveDraft = true,
  hasModalGetDraft = false,
  hasFooter = true,
  onSaveDraft,
  title,
  isEditing,
}) => {
  const location = useLocation();
  const { openRecallSave, setRecallSave, openSave, setSave } =
    useContext(ModalSaveInfoContext);
  const { numPadOpen, setNumPadOpen } = useContext(NumpadContext);
  const { openHelp } = useContext(StepLayoutContext);
  const currentRoute = useMatchedRoute();

  useEffect(() => {
    setNumPadOpen(false);
  }, [location, setNumPadOpen]);

  const handleRecallSave = useCallback(() => {
    setRecallSave(!openRecallSave);
  }, [openRecallSave, setRecallSave]);

  const handleSave = useCallback(() => {
    setSave(!openSave);
  }, [openSave, setSave]);

  if (!currentRoute) return null;
  return (
    <Stack>
      <Header
        currentStep={currentRoute.stepNumber ?? 0}
        title={title}
        isEditing={isEditing}
      />
      <Stack height={40} />
      <Stack sx={{ position: 'relative', bgcolor: 'bg_off' }}>
        {currentRoute.path !== routeNames.StepFourB.path &&
          currentRoute.path !== routeNames.Complete.path && (
            <Stack
              sx={{
                height: '100%',
                width: { mobile: 0, tablet: 44 },
                bgcolor: 'main_white',
                position: 'fixed',
                left: 0,
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
              }}
            >
              <Stack sx={{ px: '2px', py: '10px' }}>
                <DrawerMenu />
              </Stack>
              <Divider sx={{ borderColor: 'gray' }} />
            </Stack>
          )}
        <Stack
          sx={{
            px: {
              mobile:
                currentRoute.path === routeNames.Complete.path ||
                currentRoute.path === routeNames.StepFourB.path
                  ? 0
                  : 4,
              tablet:
                currentRoute.path === routeNames.Complete.path ||
                currentRoute.path === routeNames.StepFourB.path
                  ? 0
                  : 18,
            },
            bgcolor: 'bg_off',
            height: '100%',
          }}
        >
          {children}
        </Stack>
      </Stack>
      <GroupButtonSaveInfo
        callSave={hasModalGetDraft}
        save={hasModalSaveDraft}
        numpad={hasNumPad && !numPadOpen}
        onSaveDraft={onSaveDraft}
      />
      <ModalSaveInfo
        open={openRecallSave}
        handleClose={handleRecallSave}
        forgotPassword
        title="から"
        titleLine="保存情報を呼出す方はこちら"
        titleButton="情報を呼び出す"
        titleModal="保存情報を呼出す"
      />
      <ModalSaveInfo
        open={openSave}
        handleClose={handleSave}
        title="へ"
        titleLine="情報を保存する方はこちら"
        titleModal="情報を保存する"
        titleButton="メールアドレスとパスワードで保存"
        onSaveDraft={onSaveDraft}
      />
      {numPadOpen && <NumPad />}
      <Stack
        height={openHelp ? 120 : 60}
        sx={{
          bgcolor:
            currentRoute.path === routeNames.Complete.path
              ? 'main_white'
              : 'bg_off',
        }}
      />
      {hasFooter && <Footer {...footer} />}
    </Stack>
  );
};
