import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PulldownChecked: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 10 10" {...props}>
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5"
        cy="5"
        r="4.75"
        fill="#6671E1"
        stroke="#747A83"
        strokeWidth="0.5"
      />
      <path
        d="M2.5 5.22763L4.31818 7.04581L7.72727 3.63672"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
