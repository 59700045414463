import { FC } from 'react';
import { Stack, Typography, Dialog } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'hooks';
import { Button, InputSelect } from 'components';
import { useSelector } from 'react-redux';
import { usersSelectors } from 'pages/ScreenMessages/selectors';
import { getMessageDetailManager } from 'pages/ScreenMessages/thunk';
import { setConversationDetail } from 'pages/ScreenMessages/slice';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { validationSchema } from './validationSchema';

type CreateMessageModalProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateMessageModal: FC<CreateMessageModalProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useSelector(usersSelectors.selectAll);

  const formik = useFormik({
    initialValues: { id: '', name: '' },
    validationSchema,
    async onSubmit(values) {
      const result = await dispatch(getMessageDetailManager(values.id));
      if (getMessageDetailManager.fulfilled.match(result)) {
        dispatch(
          setConversationDetail({
            conversationId: values.id,
            conversationName: values.name,
          })
        );
        navigate(routeNames.ManagerMessageDetail.path);
      }
      onClose();
    },
  });

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          width: '40%',
          m: 'auto',
          overflowY: 'visible',
          '&& .MuiDialog-paper': { minWidth: 400, minHeight: 330 },
          borderRadius: '8px',
        }}
        PaperProps={{
          sx: {
            overflowY: 'visible',
          },
        }}
      >
        <Stack
          sx={{
            textAlign: 'center',
            marginTop: 8,
          }}
        >
          <Typography variant="text_applicant_item" color="sp_primary_100_main">
            メッセージ新規作成
          </Typography>
        </Stack>
        <Stack
          justifyContent="center"
          sx={{
            width: 343,
            position: 'absolute',
            top: 82,
            left: 32,
          }}
        >
          <Typography
            variant="text_applicant_item"
            color="b_333"
            sx={{ fontSize: 16 }}
          >
            顧客名
          </Typography>
          <InputSelect
            options={users.map((user) => ({
              label: user.user_name,
              value: user.id,
            }))}
            placeholder="選択してください"
            name="id"
            stroke="#6B70F0"
            checkRequired
            sx={{
              textAlign: 'left',
              height: 48,
              '& .MuiTypography-placeHolder_style': {
                color: 'bg_radio_avatar',
                fontWeight: 300,
                fontSize: 16,
              },
              '&&&.Mui-success fieldset': {
                borderWidth: 1,
                borderRadius: 0,
                borderRight: 0,
                borderLeft: 0,
                borderTop: 0,
                borderColor: 'b_333',
              },
              '&&&& fieldset': {
                borderWidth: 1,
                borderColor: 'sp_blue_50',
              },
              '&&&&& .MuiTypography-input_style': {
                fontSize: '13px',
                fontFamily: 'Noto Sans JP',
                fontWeight: 400,
                lineHeight: '19px',
                color: 'sp_primary_100_main',
              },
            }}
            onChange={(e) => {
              let findUserName = users.find(
                (user) => user.id === e.target.value
              );
              if (!!findUserName) {
                formik.setFieldValue('name', findUserName.user_name);
              }
            }}
            error={formik.touched.id && !!formik.errors.id}
          />
          <Typography
            sx={{
              fontSize: 14,
            }}
            variant="text_applicant_item"
            color="sp_secondary_01"
          >
            {formik.errors.id}
          </Typography>
        </Stack>
        <Stack
          sx={{
            pr: 4,
            marginTop: '130px',
            width: '100%',
            direction: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              bgcolor: 'sp_primary_100_main',
              width: 148,
              height: 40,
            }}
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            <Typography
              sx={{
                fontSize: 14,
              }}
              variant="text_applicant_item"
              color="main_white"
            >
              新規作成
            </Typography>
          </Button>
          <Button
            sx={{
              bgcolor: 'main_white',
              width: 148,
              height: 40,
              marginTop: 5,
              border: '1px solid',
              borderColor: 'sp_primary_100_main',
            }}
            disabled={formik.isSubmitting}
            onClick={onClose}
          >
            <Typography
              sx={{
                fontSize: 14,
              }}
              variant="text_applicant_item"
              color="sp_primary_100_main"
            >
              キャンセル
            </Typography>
          </Button>
        </Stack>
      </Dialog>
    </FormikProvider>
  );
};
