import { AdminPreliminaryItem, GUARANTEE_PROVIDER } from 'constant';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { dayjs } from '../../../libs/dayjs';

export const buildGuaranteeProvider = (
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[][] => {
  return values.p_join_guarantors_attributes.map((_, index) =>
    GUARANTEE_PROVIDER.map((row) => {
      if (row.name_en === 'birthday') {
        const { loan_desired_borrowing_date } =
          values.p_borrowing_details_attributes[0];
        return {
          ...row,
          options: {
            ...row.options,
            minDate: dayjs(loan_desired_borrowing_date || undefined)
              .subtract(100, 'y')
              .toString(),
            maxDate: dayjs(loan_desired_borrowing_date || undefined)
              .subtract(18, 'y')
              .toString(),
          },
          name_en: `p_join_guarantors_attributes[${index}].birthday`,
          subField: row.subField
            ? {
                ...row.subField,
                name_en: `p_join_guarantors_attributes[${index}].${row.subField.name_en}`,
                subFieldName: `p_join_guarantors_attributes[${index}].${row.subField.subFieldName}`,
              }
            : undefined,
        };
      }
      return {
        ...row,
        name_en: `p_join_guarantors_attributes[${index}].${row.name_en}`,
        subField: row.subField
          ? {
              ...row.subField,
              name_en: `p_join_guarantors_attributes[${index}].${row.subField.name_en}`,
              subFieldName: `p_join_guarantors_attributes[${index}].${row.subField.subFieldName}`,
            }
          : undefined,
      };
    })
  );
};
