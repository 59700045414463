import { FC } from 'react';
import { UserLayout } from 'containers';
import { Button, Icons } from 'components';
import { Avatar, Stack, Typography } from '@mui/material';
import { routeNames } from 'navigations/routes';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { currentPremilinarySelectors } from 'pages/Preliminaries/selectors';
import { useSelector } from 'react-redux';
import { accept, refuse } from 'assets';

const ResultPage: FC = () => {
  let success = true;
  const navigate = useNavigate();
  const selectedPreliminary = useSelector(currentPremilinarySelectors);

  return (
    <UserLayout
      header={{
        left: {
          title: '審査結果',
        },
      }}
      footer={{
        left: (
          <Stack direction="row" sx={{ p: '5px 20px 11px 11px' }}>
            <Button
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
              onClick={() => navigate(routeNames.Dashboard.path)}
            >
              <Typography variant="button_title" color="normal_text">
                もどる
              </Typography>
            </Button>
          </Stack>
        ),
        right: false,
        bgcolor: 'footer_bg',
      }}
    >
      <Stack sx={{ ml: 15, mr: 6, pb: '29px', height: 'calc(100vh - 142px)' }}>
        <Stack
          sx={{
            bgcolor: 'main_white',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '5px',
            mt: 5,
            pb: 18,
            height: '100%',
          }}
        >
          <Stack sx={{ px: 13, pt: 8 }} spacing={13}>
            <Stack direction="row-reverse">
              <Icons.LogoHeader sx={{ width: 120, color: 'h_blugreen' }} />
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={8}
            >
              <Stack direction="row" alignItems="center" spacing={4}>
                <Avatar sx={{ width: 40, height: 40, bgcolor: 'h_blugreen' }}>
                  <Typography sx={{ fontSize: 14 }}>ICON</Typography>
                </Avatar>
                <Typography sx={{ pt: 2 }} variant="h4" color="Icon_color">
                  受付番号
                </Typography>
              </Stack>
              <Typography
                sx={{ pt: 2 }}
                variant="total_text"
                color="normal_text"
              >
                {selectedPreliminary.application_number}
              </Typography>
              <Typography
                sx={{ pt: 2 }}
                variant="text_input_code"
                color="normal_text"
              >
                審査結果のご連絡
              </Typography>
            </Stack>
            <Stack direction="row" spacing={12}>
              <Stack flex={3} spacing={3}>
                <Stack px={5} pb={5} spacing={4}>
                  <Typography variant="h3" color="normal_text">
                    みらいバンクからのXXXXXXXX
                  </Typography>
                  <Typography variant="liststyle" color="normal_text">
                    ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。審査結果の説明が入ります。
                  </Typography>
                </Stack>
                <Button
                  sx={{
                    py: 1,
                    minHeight: 30,
                    borderRadius: 1,
                    border: '1px dashed',
                    borderColor: 'Icon_color',
                  }}
                  variant="text"
                >
                  <Typography variant="reason_text" color="normal_text">
                    ご融資条件
                  </Typography>
                </Button>
                {acceptNotes.map((note, index) => (
                  <Stack key={index} px={5} direction="row" spacing={1}>
                    <Typography variant="liststyle" color="normal_text">
                      {index + 1}.
                    </Typography>
                    <Typography variant="liststyle" color="normal_text">
                      {note}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
              <Stack
                flex={2}
                sx={{
                  borderRadius: 1,
                  bgcolor: success ? 'green_005' : 'red_005',
                }}
                justifyContent="center"
                alignItems="center"
                spacing={6}
              >
                <Avatar
                  src={success ? accept : refuse}
                  sx={{ height: 78, width: 78 }}
                  variant="square"
                />
                <Typography
                  variant="text_input_code"
                  color={success ? 'green_003' : 'sh_red'}
                >
                  ご融資可能
                </Typography>
                <Typography
                  sx={{ px: 6, letterSpacing: '1px' }}
                  variant="textstyle"
                  color="normal_text"
                >
                  条件XXXXXXをご選択により融資の実行が可能となります。
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </UserLayout>
  );
};

export default ResultPage;

const acceptNotes = [
  'こに審査結果の説明が入ります。ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。',
  'こに審査結果の説明が入ります。ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。ここに審査結果の説明が入ります。',
];
