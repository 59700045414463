import { AdminPreliminaryItem } from 'constant';
import { SalePersonPreliminaries } from 'services';
import { buildPersonalOccupation } from '../../AdminEditPreliminary/utils';

export const buildPersonalOccupationSalePerson = (
  values: SalePersonPreliminaries['p_application_header'],
  index = 0
): AdminPreliminaryItem[] => {
  return buildPersonalOccupation(values, index).filter(
    ({ required }) => !required
  );
};
