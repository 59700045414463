import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Select: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 9 5" {...props}>
      <svg
        width="9"
        height="5"
        viewBox="0 0 9 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 0.5L4.5 4L8 0.5" stroke="#444444" strokeWidth="0.83" />
      </svg>
    </SvgIcon>
  );
};
