import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';
import {
  Button,
  Icons,
  SPFormItem,
  SPInputField,
  SPRegistrationCompleteModal,
} from 'components';
import { Link } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { theme } from 'styles';
import { SPArrowForward } from 'components/atoms/icons';
import { SPStepLayout } from 'containers';
import NotifyScreenTemplate from 'components/template/NotifyScreenTemplate';
import { regex } from 'libs';
import { useAppDispatch } from 'hooks';
import SPUpdatePasswordTemplate from 'components/template/SPUpdatePasswordTemplate';
import { routeNames } from 'navigations/routes';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'containers/AuthModal/selectors';
import { autoFillEmail } from 'pages/SPStepTwo/slice';
import { config } from 'configs';
import { SingleError } from 'types';
import { Error, errorMsg } from 'constant';
import { setFirstTimeLogin } from 'containers/AuthModal/slice';
import { addStatus } from 'pages/SPStepFourIncomeTotalizer/slice';
import { supportDvh } from 'utils';
import { saveLoginEmail } from '../SPAccountInformation/slice';
import {
  getDynamicOptions,
  getMasterBanks,
} from '../StepSynthesisInformation/thunk';
import {
  emailValidationSchema,
  passwordValidationSchema,
} from './validationSchema';
import { sendEmail, sendEmailToken, spSignUp } from './thunk';
import { AcceptCheckbox } from './components/AcceptCheckbox';

const SPScreenEmailPage: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('email_token');
  const [loading, setLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [openWarningSignUp, setOpenWarningSignUp] = useState<boolean>(false);
  const [openSuccessSignUp, setOpenSuccessSignUp] = useState<boolean>(false);
  const [openExpiredNotify, setOpenExpiredNotify] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [textWarning, setTextWarning] = useState<string>('');
  const tokenEmail = useSelector(tokenSelector);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: token ? passwordValidationSchema : emailValidationSchema,
    async onSubmit(user) {
      if (token) {
        const result = await dispatch(
          spSignUp({
            user: {
              email: user.email,
              password: user.password,
              password_confirmation: user.password_confirmation,
            },
            token,
          })
        );
        if (spSignUp.fulfilled.match(result)) {
          dispatch(saveLoginEmail(user.email));
          dispatch(autoFillEmail(user.email));
          dispatch(getMasterBanks());
          dispatch(getDynamicOptions());
          return setOpenSuccessSignUp(true);
        }
        return setOpenWarningSignUp(true);
      }

      const path = {
        sale_agent_id: sessionStorage.getItem('sale_agent_id') || undefined,
        store_id: sessionStorage.getItem('store_id') || undefined,
        exhibition_id: sessionStorage.getItem('exhibition_id') || undefined,
      };
      const result = await dispatch(
        sendEmail({
          email: user.email,
          path:
            path.sale_agent_id === undefined &&
            path.store_id === undefined &&
            path.exhibition_id === undefined
              ? undefined
              : path,
        })
      );
      if (sendEmail.fulfilled.match(result)) {
        return setEmailSent(true);
      }
      if (sendEmail.rejected.match(result)) {
        const error = result.payload;
        if ((error as SingleError).error === Error.DUPLICATE) {
          setOpenWarning(true);
          return setTextWarning(errorMsg[Error.DUPLICATE]);
        }
      }
      setOpenWarning(true);
      setTextWarning('サーバーとの通信に失敗しました。再度お試しください。');
    },
  });

  const checkSendEmailToken = useCallback(async () => {
    if (token) {
      setLoading(true);
      const result = await dispatch(sendEmailToken({ token }));
      if (sendEmailToken.fulfilled.match(result)) {
        formik.setFieldValue('email', result.payload.email);
      } else {
        setOpenExpiredNotify(true);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, token]);

  useLayoutEffect(() => {
    checkSendEmailToken();
  }, [checkSendEmailToken]);

  if (loading) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasMenu={false}
        hasStepBar={false}
        hasModalSaveDraft={false}
        hasFooter={false}
        footerLogo
        sx={{ minHeight: supportDvh('calc(100dvh - 158px)') }}
      >
        {openExpiredNotify ? (
          <NotifyScreenTemplate
            title={`メールの有効期限が\n切れています`}
            icon={
              <Icons.SPWarningModal sx={{ width: 42, height: 36, pb: 4 }} />
            }
            buttonTitle="新規登録はこちら"
            onClick={() => setOpenExpiredNotify(true)}
          >
            <Stack
              sx={{
                bgcolor: 'main_white',
                borderRadius: '14px',
                width: '100% - 64px',
                p: 6,
                mx: 8,
              }}
              spacing={6}
            >
              <Typography variant="sp_change_password_note" color="b_333">
                {`メール受信後、一定時間内での\n操作が確認できなかったため\n新規登録が完了しておりません。`}
              </Typography>
              <Typography variant="sp_change_password_note" color="b_333">
                {`恐れ入りますが、\n再度、新規登録の手続きを\nお願いいたします。`}
              </Typography>
            </Stack>
          </NotifyScreenTemplate>
        ) : (
          <Stack sx={{ width: '100%' }}>
            {token && !emailSent && (
              <SPUpdatePasswordTemplate
                open={openWarningSignUp}
                close={() => setOpenWarningSignUp(false)}
                title="パスワード登録"
                formOption={[
                  {
                    formTitle: 'パスワード',
                    formName: 'password',
                    formChildren: (
                      <Stack spacing={3} mt={1}>
                        <Typography variant="SP_password_note" color="b_333">
                          パスワードの条件
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Checkbox
                            checked={
                              formik.values.password.length >= 8 &&
                              formik.values.password.length <= 20
                            }
                            sx={{
                              m: 0,
                              width: 12,
                              height: 12,
                              color: 'sp_primary_40',
                              '&.Mui-checked': {
                                color: 'sp_secondary_01',
                              },
                            }}
                          />

                          <Typography
                            variant="table_header_text"
                            color={
                              (formik.values.password.length > 0 &&
                                formik.values.password.length < 8) ||
                              formik.values.password.length > 20
                                ? 'sp_secondary_01'
                                : 'b_333'
                            }
                          >
                            8文字以上20文字以下
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Checkbox
                            checked={
                              !!formik.values.password.match(regex.password)
                            }
                            sx={{
                              width: 12,
                              height: 12,
                              color: 'sp_primary_40',
                              '&.Mui-checked': {
                                color: 'sp_secondary_01',
                              },
                            }}
                          />
                          <Typography
                            variant="table_header_text"
                            color={
                              !formik.values.password.match(regex.password) &&
                              formik.values.password
                                ? 'sp_secondary_01'
                                : 'b_333'
                            }
                          >
                            大文字英字・小文字英字・数字の3種混在
                          </Typography>
                        </Stack>
                      </Stack>
                    ),
                    hasPassword: true,
                    showError: true,
                    autoTrim: false,
                  },
                  {
                    formTitle: 'パスワード（確認用）',
                    formName: 'password_confirmation',
                    hasPassword: true,
                    autoTrim: false,
                  },
                ]}
                onSubmit={formik.handleSubmit}
                disabledButton={
                  formik.isSubmitting ||
                  !(
                    formik.isValid &&
                    formik.dirty &&
                    formik.values.password.length >= 8 &&
                    formik.values.password.length <= 20
                  )
                }
                buttonTitle="登録する"
              />
            )}

            {!token && emailSent && (
              <NotifyScreenTemplate
                title="メールを送信しました"
                icon={
                  <Icons.SPAirPlane sx={{ width: 36, height: 37, pb: 4 }} />
                }
              >
                <Stack
                  sx={{
                    bgcolor: 'main_white',
                    borderRadius: '14px',
                    width: '100% - 64px',
                    p: 6,
                    mx: 8,
                  }}
                >
                  <Typography variant="sp_change_password_note" color="b_333">
                    {`メールに記載されている\n URLにアクセスし\n新規登録を完了してください。`}
                  </Typography>
                </Stack>
              </NotifyScreenTemplate>
            )}
            {!token && !emailSent && (
              <>
                <Stack sx={{ bgcolor: 'bg_off' }}>
                  <Stack textAlign="center" sx={{ mt: 7 }}>
                    <Typography
                      variant="SP_title_email_screen"
                      color="sp_primary_100_main"
                    >
                      新規登録
                    </Typography>
                  </Stack>
                  {openWarning && tokenEmail && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      textAlign="center"
                      sx={{
                        mt: 7,
                        bgcolor: 'sp_secondary_pink_light',
                        border: (theme) =>
                          `1px solid ${theme?.palette?.sp_secondary_01}`,
                        borderRadius: '8px',
                        mx: 4,
                        px: 4,
                        py: 2,
                      }}
                      spacing={3}
                    >
                      <Icons.SPWarning />
                      <Typography
                        variant="drawerText"
                        color="sp_secondary_01"
                        textAlign="left"
                      >
                        サーバーとの通信に失敗しました。再度お試しください。
                      </Typography>
                    </Stack>
                  )}

                  {openWarning && !tokenEmail && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      textAlign="center"
                      sx={{
                        mt: 7,
                        bgcolor: 'sp_secondary_pink_light',
                        border: (theme) =>
                          `1px solid ${theme?.palette?.sp_secondary_01}`,
                        borderRadius: '8px',
                        mx: 4,
                        px: 4,
                        py: 2,
                      }}
                      spacing={3}
                    >
                      <Icons.SPWarning />
                      <Typography
                        variant="drawerText"
                        color="sp_secondary_01"
                        textAlign="left"
                      >
                        {textWarning}
                      </Typography>
                    </Stack>
                  )}
                  <Stack justifyContent="center" sx={{ mt: 7, mx: 0 }}>
                    <SPFormItem required label="メールアドレス">
                      <Stack sx={{ mt: 3 }} spacing={7}>
                        <SPInputField
                          name="email"
                          type="email"
                          placeholder="例：sample@sample.co.jp"
                          InputProps={{
                            sx: {
                              '&& .MuiInputBase-input': {
                                borderRadius: '5px',
                                ...theme.typography.text_placeholder,
                              },
                              '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                                {
                                  borderWidth: '1px',
                                  borderColor: 'black',
                                },
                            },
                          }}
                          onFocus={() => {
                            setOpenWarning(false);
                            setTextWarning('');
                          }}
                        />

                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <AcceptCheckbox
                            onChange={() =>
                              setChecked((prevState) => !prevState)
                            }
                          />
                          <Link
                            href={config.termServiceUrl}
                            target="_blank"
                            component="a"
                            variant="buttonAddImage"
                            color="sp_primary_100_main"
                            sx={{
                              textDecorationColor: 'sp_primary_100_main',
                            }}
                          >
                            利用規約
                          </Link>
                          <Typography variant="buttonAddImage" color="b_333">
                            に同意する
                          </Typography>
                        </Stack>

                        <Stack>
                          <Typography variant="SP_form_item_note" color="b_333">
                            ※入力したメールアドレス宛に仮登録メールが届きます。
                          </Typography>
                          <Typography variant="SP_form_item_note" color="b_333">
                            メール受信制限をされている方は info@milibank.co.jp
                          </Typography>
                          <Typography variant="SP_form_item_note" color="b_333">
                            からのメール受信を許可してください。
                          </Typography>
                        </Stack>
                      </Stack>
                    </SPFormItem>

                    <Stack alignItems="center" spacing={6}>
                      <Button
                        disabled={
                          formik.isSubmitting ||
                          !(formik.isValid && formik.dirty) ||
                          !checked
                        }
                        sx={{
                          bgcolor: 'sp_primary_100_main',
                          width: '327px',
                          minHeight: '54px',
                          boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                          borderRadius: '14px',
                          height: '54px',
                          '&.Mui-disabled': {
                            bgcolor: 'sp_primary_100_main',
                            opacity: 0.15,
                          },
                        }}
                        endIcon={
                          <SPArrowForward
                            sx={{
                              color: 'main_white',
                              position: 'absolute',
                              right: '22px',
                              bottom: '17px',
                            }}
                          />
                        }
                        onClick={() => formik.handleSubmit()}
                      >
                        <Typography
                          variant="SP_multiple_checkbox_label"
                          color="main_white"
                        >
                          登録する
                        </Typography>
                      </Button>
                      <Stack direction="row" alignItems="center">
                        <Link
                          href={routeNames.Login.path}
                          variant="SP_multiple_checkbox_label"
                          color="sp_primary_100_main"
                          sx={{ textDecoration: 'none' }}
                        >
                          既にアカウントをお持ちの方
                          <Icons.SPArrowForwardCircle
                            sx={{
                              verticalAlign: 'middle',
                              marginTop: '-2px',
                            }}
                          />
                        </Link>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </>
            )}

            <SPRegistrationCompleteModal
              open={openSuccessSignUp}
              onClose={() => {
                setOpenSuccessSignUp(false);
                dispatch(setFirstTimeLogin(true));
                dispatch(
                  addStatus({
                    firstElement: undefined,
                    secondElement: undefined,
                    isReseted: true,
                  })
                );
                navigate(routeNames.StartPage.path, { replace: true });
                navigate(routeNames.SPAgreement.path);
              }}
            />
          </Stack>
        )}
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPScreenEmailPage;
