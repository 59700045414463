import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPCheckedCircle: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 25.5C14.6415 25.5 16.267 25.1767 17.7835 24.5485C19.3001 23.9203 20.6781 22.9996 21.8388 21.8388C22.9996 20.6781 23.9203 19.3001 24.5485 17.7835C25.1767 16.267 25.5 14.6415 25.5 13C25.5 11.3585 25.1767 9.73303 24.5485 8.21646C23.9203 6.69989 22.9996 5.3219 21.8388 4.16116C20.6781 3.00043 19.3001 2.07969 17.7835 1.45151C16.267 0.823322 14.6415 0.5 13 0.5C11.3585 0.5 9.73303 0.823322 8.21646 1.45151C6.69989 2.07969 5.3219 3.00043 4.16117 4.16116C3.00043 5.3219 2.07969 6.69989 1.4515 8.21646C0.823321 9.73303 0.5 11.3585 0.5 13C0.5 14.6415 0.823321 16.267 1.4515 17.7835C2.07969 19.3001 3.00043 20.6781 4.16117 21.8388C5.3219 22.9996 6.69989 23.9203 8.21646 24.5485C9.73303 25.1767 11.3585 25.5 13 25.5V25.5ZM18.842 11.2031L12.592 17.4531C12.0538 17.9913 11.1684 17.9913 10.6302 17.4531L7.15799 13.9809C6.61979 13.4427 6.61979 12.5573 7.15799 12.0191C7.69618 11.4809 8.5816 11.4809 9.11979 12.0191L11.6111 14.5104L16.8802 9.24132C17.4184 8.70313 18.3038 8.70313 18.842 9.24132C19.3802 9.77951 19.3802 10.6649 18.842 11.2031V11.2031Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
