import { FC, MouseEvent, useEffect, useState } from 'react';
import { UserLayout } from 'containers';
import { useNavigate } from 'react-router-dom';
import { Button, ContactInformationTable } from 'components';
import { Stack, Typography } from '@mui/material';
import { useAppDispatch } from 'hooks';
import { ContactItem } from 'services';
import { OrderType } from 'types';
import { useSelector } from 'react-redux';
import { ArrowBackIos } from '@mui/icons-material';
import { routeNames } from 'navigations/routes';
import { getContacts } from './thunk';
import { contactSelectors } from './selectors';

const ContactInformationsPage: FC = () => {
  const dispatch = useAppDispatch();
  const contacts = useSelector(contactSelectors.selectAll);
  const [order, setOrder] = useState<OrderType>('desc');
  const [orderBy, setOrderBy] = useState<keyof ContactItem>('sender');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const admin = false;

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof ContactItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <UserLayout
      header={{
        left: { title: '連絡事項一覧' },
      }}
      footer={{
        left: (
          <Stack direction="row" sx={{ p: '5px 20px 11px 11px' }}>
            <Button
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
              onClick={() => navigate(routeNames.Dashboard.path)}
            >
              <Typography variant="button_title" color="normal_text">
                もどる
              </Typography>
            </Button>
          </Stack>
        ),
        right: false,
        bgcolor: 'footer_bg',
      }}
    >
      <Stack sx={{ pl: 11, pb: '29px' }}>
        <ContactInformationTable
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          rows={contacts}
          isAdmin={admin}
        />
      </Stack>
    </UserLayout>
  );
};

export default ContactInformationsPage;
