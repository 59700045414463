import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Folder: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.75 3.5H12.75L10.1906 0.939219C9.90937 0.657969 9.525 0.5 9.12656 0.5H2.25C1.00734 0.5 0 1.50734 0 2.75V19.25C0 20.4922 1.00734 21.5 2.25 21.5H21.75C22.9927 21.5 24 20.4927 24 19.25V5.75C24 4.50781 22.9922 3.5 21.75 3.5ZM23.25 19.25C23.25 20.0783 22.5783 20.75 21.75 20.75H2.25C1.42172 20.75 0.75 20.0783 0.75 19.25V2.75C0.75 1.92172 1.42172 1.25 2.25 1.25H8.81719C9.21502 1.25 9.59672 1.40802 9.87797 1.68931L12.4406 4.25H21.75C22.5783 4.25 23.25 4.92172 23.25 5.75V19.25Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
