import React, { FC, ReactElement, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { theme } from 'styles';

interface FilterModalItemProps {
  children: ReactElement;
  name: string;
  title: string;
  conditionExpanded?: boolean;
}

export const FilterModalItem: FC<FilterModalItemProps> = (props) => {
  const [expandedState, setExpandedState] = React.useState<boolean>(false);

  useEffect(() => {
    if (!props.conditionExpanded) {
      return;
    }
    setExpandedState(true);
  }, [props.conditionExpanded]);

  const onChange = (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpandedState((prevs) => !prevs);
  };

  return (
    <Stack>
      <Accordion
        expanded={expandedState}
        disableGutters
        sx={{
          marginTop: '-2px',
          '&:last-of-type': {
            borderRadius: 0,
          },
          '&:first-of-type': {
            borderRadius: 0,
          },
          '& .MuiAccordionDetails-root': {
            padding: '10px 0 12px',
          },
          border: `1px solid ${theme?.palette?.border_gray}`,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          bgcolor: 'bg_off',
        }}
        onChange={onChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            height: 56,
            '& .MuiAccordionSummary-content': {
              margin: '8px 0',
            },
            justifyContent: 'center',
            bgcolor: 'main_white',
            px: 17,
          }}
        >
          <Typography variant="required_chip_label" color="b_333" fontSize={14}>
            {props.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </Stack>
  );
};
