import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  authService,
  GetSalePersonError,
  GetSalePersonRequest,
  GetSalePersonResponse,
  LoginError,
  LoginRequest,
  LoginResponse,
  SaveSalePersonError,
  SaveSalePersonRequest,
  SaveSalePersonResponse,
} from 'services';
import {
  GetCurrentStatusResultResponse,
  SPGetPreliminaryResponse,
  ThunkAPI,
} from 'types';
import { AxiosError } from 'axios';

export const spLogin = createAsyncThunk<
  LoginResponse,
  LoginRequest,
  ThunkAPI<LoginError>
>('spAuth/spLogin', async (user, { rejectWithValue }) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const err = (error as AxiosError<LoginError>).response?.data;
    if (err) return rejectWithValue(err);
    return rejectWithValue(error);
  }
});

export const spGetSubmittedPreliminary = createAsyncThunk<
  SPGetPreliminaryResponse,
  void,
  ThunkAPI<LoginError>
>('spAuth/getSubmittedPreliminary', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.getSubmittedPreliminary();
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const spGetCurrentStatusResult = createAsyncThunk<
  GetCurrentStatusResultResponse,
  void,
  ThunkAPI<LoginError>
  //@ts-ignore
>('spAuth/spGetPreliminaryStatus', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.getCurrentStatusResult();
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const saveSalePerson = createAsyncThunk<
  SaveSalePersonResponse,
  SaveSalePersonRequest,
  ThunkAPI<SaveSalePersonError>
>('spAuth/saveSalePerson', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.saveSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSalePerson = createAsyncThunk<
  GetSalePersonResponse,
  GetSalePersonRequest,
  ThunkAPI<GetSalePersonError>
>('spAuth/getSalePerson', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
