import { AxiosRequestConfig } from 'axios';
import { instance } from 'libs';
import {
  getReferralAgencyOptionRequest,
  getReferralAgencyOptionRequestSchema,
  getReferralAgencyOptionResponse,
  getReferralAgencyOptionResponseSchema,
} from './schemas';

export const getReferralAgencyOptions = (
  data: getReferralAgencyOptionRequest
): Promise<getReferralAgencyOptionResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/referral_agency_options`,
    params: getReferralAgencyOptionRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    getReferralAgencyOptionResponseSchema.parse(data)
  );
};
