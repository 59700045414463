import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  ContactDetailManagerRequest,
  ContactDetailManagerResponse,
  ContactError,
  ContactResponse,
  CreateContactRequest,
  CreateContactResponse,
  ManagerContactRequest,
} from 'services';
import { ThunkAPI } from 'types';

export const getManagerContacts = createAsyncThunk<
  ContactResponse,
  ManagerContactRequest
>(
  'ManagerContactInformations/getManagerContactInformations',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getManagerContacts(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sortManagerContacts = createAsyncThunk<
  ContactResponse,
  ManagerContactRequest
>(
  'ManagerContactInformations/getManagerContactInformations',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getManagerContacts(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createContactsInformation = createAsyncThunk<
  CreateContactResponse,
  CreateContactRequest
>(
  'ContactsInformation/createContactsInformation',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.createContactsInformation(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getContactDetailManager = createAsyncThunk<
  ContactDetailManagerResponse,
  ContactDetailManagerRequest,
  ThunkAPI<ContactError>
>(
  'ContactInformations/getContactDetailManager',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getContactDetailManager(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
