import { createRef, FC, useImperativeHandle, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { Modal as MuiModal, Stack, Typography } from '@mui/material';
import { Button } from 'components/atoms';

type ModalErrorRef = {
  open: (title: string, message: string, haveButton?: boolean) => void;
  close: () => void;
};

export const modalErrorRef = createRef<ModalErrorRef>();

export const ModalError: FC = () => {
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [haveButton, setHaveButton] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  useImperativeHandle(modalErrorRef, () => ({
    open(title, message, haveButton) {
      setTitle(title);
      setMessage(message);
      !!haveButton && setHaveButton(haveButton);
      setOpen(true);
    },
    close() {
      setOpen(false);
      setTitle('');
      setHaveButton(false);
      setMessage('');
    },
  }));

  const onReload = () => {
    window.location.reload();
    setOpen(false);
  };

  return (
    <MuiModal
      sx={{
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={() => setOpen(false)}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'main_white',
          p: '12px 12px 16px 16px',
          minWidth: 200,
          maxWidth: 500,
          minHeight: 150,
          width: 'calc(100% - 80px)',
        }}
        spacing={5}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack flex={1} justifyContent="center" alignItems="center">
            <Typography
              variant="h4"
              color="normal_text"
              sx={{ paddingTop: '8px' }}
            >
              {title}
            </Typography>
          </Stack>
          <Stack sx={{ position: 'absolute', right: 14 }}>
            {!haveButton && (
              <IconButton sx={{ p: 0 }} onClick={() => setOpen(false)}>
                <CloseIcon fontSize="medium" sx={{ color: 'h_blugreen' }} />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <Stack sx={{ maxHeight: 400, overflowY: 'auto' }}>
          <Typography
            variant="text_step_admin"
            align="left"
            color="sp_secondary_01"
            sx={{
              whiteSpace: 'break-spaces',
              fontSize: '16px',
              height: `${!!haveButton && haveButton ? 100 : 150}`,
              marginTop: 0,
            }}
          >
            {message}
          </Typography>
          {!!haveButton && haveButton && (
            <Stack flex={1} alignItems="center">
              <Button
                sx={{
                  bgcolor: 'white',
                  boxShadow: 'none',
                  width: '150px',
                  height: '36px',
                  borderRadius: '2px',
                  minHeight: '36px',
                  border: '1px solid #6B70F0',
                }}
                onClick={() => onReload()}
              >
                <Typography variant="button01" color="sp_primary_100_main">
                  リフレッシュする
                </Typography>
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </MuiModal>
  );
};
