import { yup } from 'libs';

export const validationSchema = yup.object().shape(
  {
    firstCode: yup.string().when('secondCode', {
      is: (secondCode: string) =>
        !!secondCode &&
        secondCode.length > 0 &&
        secondCode !== '0' &&
        secondCode !== '00',
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
  },
  [['firstCode', 'secondCode']]
);
