import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { AdminLayout } from 'containers';
import { FC, useCallback, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { SalePersonUploadNewDocumentTable } from 'components/molecules/SalePersonUploadNewDocumentTable';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { toast } from 'react-toastify';
import { uploadNewDocumentSalePerson } from '../ManagerDocumentUpload/thunk';

const SalePersonUploadNewDocumentPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState<File[]>([]);
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const [dataRows, setDataRows] = useState<
    { index: number; src: string; name: string }[]
  >([]);

  const onSubmit = useCallback(async () => {
    setUploadFile(true);
    const data = new FormData();
    imageFile.forEach((file) => {
      data.append('upload_files[]', file);
    });
    const result = await dispatch(uploadNewDocumentSalePerson(data));
    if (uploadNewDocumentSalePerson.fulfilled.match(result)) {
      toast.success('ファイルをアップロードしました。');
      setUploadFile(false);
      navigate(routeNames.SalePersonDocumentUpload.path);
    }
    if (uploadNewDocumentSalePerson.rejected.match(result)) {
      toast.error('エラーが発生しました。');
      setUploadFile(false);
    }
  }, [imageFile, dispatch, navigate]);

  return (
    <>
      <AdminLayout
        hasDrawer={false}
        header={{
          left: {
            mainTitle: (
              <Stack
                direction="row"
                spacing={2}
                onClick={() =>
                  navigate(routeNames.SalePersonDocumentUpload.path)
                }
              >
                <Typography
                  variant="text_steps"
                  color="sp_primary_100_main"
                  sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                >
                  アップロード書類一覧
                </Typography>
                <Icons.ArrowRight sx={{ width: 16, height: 26 }} />
                <Typography variant="text_steps" sx={{ whiteSpace: 'nowrap' }}>
                  アップロード
                </Typography>
              </Stack>
            ),
          },
          children: <Stack direction="row" spacing={2}></Stack>,
          right: {
            contentPopover: (
              <Button
                sx={{
                  py: 0,
                  flex: 1,
                  width: '100%',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  minHeight: 42,
                  height: 42,
                  boxShadow: 'none',
                  bgcolor: 'main_white',
                  justifyContent: 'flex-start',
                  px: '10px',
                  '&.MuiButton-root:hover': {
                    bgcolor: 'sp_primary_100_main',
                    '& .MuiTypography-root': {
                      color: 'main_white',
                    },
                  },
                }}
                onClick={() => {}}
              >
                <Typography variant="auth_popover_text" color="b_333">
                  管理画面をエクスポート
                </Typography>
                <Icons.Excel sx={{ color: 'attention', ml: '9px' }} />
              </Button>
            ),
          },
          showIconHome: false,
        }}
        footer={{
          left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
          right: (
            <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />
          ),
          bgcolor: 'footer_bg',
        }}
      >
        <Stack sx={{ pb: '29px' }}>
          <SalePersonUploadNewDocumentTable
            onRequestSort={() => {}}
            onFilter={() => {}}
            order="asc"
            orderBy={'desc'}
            rows={dataRows}
            setImageFile={setImageFile}
            setDataRows={setDataRows}
            onSubmit={onSubmit}
            isUploadFile={uploadFile}
          />
        </Stack>
      </AdminLayout>
    </>
  );
};

export default SalePersonUploadNewDocumentPage;
