import { FC, useMemo } from 'react';
import AuthCode, { AuthCodeProps } from 'react-auth-code-input';
import { styled } from '@mui/material';
import { FastField, FastFieldProps, useField } from 'formik';

export type InputCodeProps = {
  name: string;
  placeholder: number;
  length: number;
  readOnly?: boolean;
};

export const InputCodeTemp: FC<InputCodeProps & FastFieldProps> = ({
  placeholder = 0,
  length = 12,
  readOnly,
  ...props
}) => {
  const [field, meta, form] = useField(props.name);

  const isError = useMemo(() => meta.error, [meta.error]);
  const isSuccess = useMemo(
    () => !meta.error && meta.value,
    [meta.error, meta.value]
  );

  return (
    <AuthCodeStyled
      {...field}
      disabled={readOnly}
      onChange={(newValue: number) => form.setValue(newValue)}
      containerClassName={[
        isError ? 'InputCode-error' : '',
        isSuccess && !readOnly ? 'InputCode-success' : '',
      ].join(' ')}
      placeholder={placeholder}
      allowedCharacters="numeric"
      length={length}
    />
  );
};

const AuthCodeStyled = styled(
  ({
    className,
    containerClassName = '',
    ...props
  }: AuthCodeProps & { className?: string }) => (
    <AuthCode
      {...props}
      inputClassName="input"
      containerClassName={[className, containerClassName].join(' ')}
    />
  )
)(({ theme }) => ({
  '& .input': {
    width: 30,
    height: 44,
    border: `3px solid ${theme?.palette?.line}`,
    padding: 0,
    margin: '2px',
    borderRadius: '4px',
    textAlign: 'center',
    outline: 'none',
    ...theme.typography.text_input_code,
  },
  '&.InputCode-error .input': {
    '&:placeholder-shown': {
      backgroundColor: theme?.palette.bg_error,
    },
  },
  '&.InputCode-error .input, &.InputCode-success .input': {
    '&:not(:placeholder-shown)': {
      borderColor: theme?.palette.h_blugreen,
    },
  },
  'input:disabled': {
    borderWidth: '1px',
    margin: '4px',
  },
}));

export const InputCode: FC<InputCodeProps> = (props) => {
  return (
    <FastField name={props.name}>
      {(ffProps: FastFieldProps) => <InputCodeTemp {...props} {...ffProps} />}
    </FastField>
  );
};
