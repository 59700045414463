import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userDataSelector } from '../containers/AuthModal/selectors';
import { getSalePerson, saveSalePerson } from '../pages/SPLogin/thunk';
import { spStepTenSelector } from '../pages/SPStepTen/selectors';
import { useAppDispatch } from './useAppDispatch';

export const useSaveSalePersonCode = () => {
  const dispatch = useAppDispatch();
  const { application_number } = useSelector(spStepTenSelector);
  const { id } = useSelector(userDataSelector);

  useEffect(() => {
    (async () => {
      if (!!application_number) {
        return;
      }
      const user = {
        sale_agent_id: sessionStorage.getItem('sale_agent_id') || undefined,
        store_id: sessionStorage.getItem('store_id') || undefined,
        exhibition_id: sessionStorage.getItem('exhibition_id') || undefined,
      };
      if (
        (user.sale_agent_id === undefined &&
          user.store_id === undefined &&
          user.exhibition_id === undefined) ||
        !id
      ) {
        return;
      }
      const result = await dispatch(saveSalePerson({ user_id: id, user }));
      if (saveSalePerson.fulfilled.match(result)) {
        sessionStorage.setItem('clear_sale_person', 'true');
      }
    })();
  }, [id, application_number, dispatch]);

  useEffect(() => {
    (async () => {
      if (!!application_number || !id) {
        return;
      }
      const user = {
        sale_agent_id: sessionStorage.getItem('sale_agent_id') || undefined,
        store_id: sessionStorage.getItem('store_id') || undefined,
        exhibition_id: sessionStorage.getItem('exhibition_id') || undefined,
      };
      if (
        !(
          user.sale_agent_id === undefined &&
          user.store_id === undefined &&
          user.exhibition_id === undefined
        )
      ) {
        return;
      }
      const result = await dispatch(getSalePerson({ user_id: id }));
      if (getSalePerson.fulfilled.match(result)) {
        if (result.payload.data.sale_agent_id) {
          sessionStorage.setItem(
            'sale_agent_id',
            result.payload.data.sale_agent_id
          );
        }
        if (result.payload.data.store_id) {
          sessionStorage.setItem('store_id', result.payload.data.store_id);
        }
        if (result.payload.data.exhibition_id) {
          sessionStorage.setItem(
            'exhibition_id',
            result.payload.data.exhibition_id
          );
        }
      }
    })();
  }, [dispatch, id, application_number]);
};
