import { FC, KeyboardEvent } from 'react';
import { Stack, SxProps, Modal, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from 'components/atoms';

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
  onLogOut: () => void;
  sx?: SxProps;
}

export const LogoutModal: FC<ChangePasswordModalProps> = (props) => {
  const onEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      props.onLogOut();
    }
  };
  return (
    <Modal
      sx={{
        ...props?.sx,
      }}
      disableAutoFocus
      {...props}
    >
      <Stack
        onKeyPress={onEnter}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          minWidth: 320,
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'main_white',
          width: { desktop: 430 },
          minHeight: 190,
        }}
      >
        <Stack direction="column">
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <CloseIcon
              onClick={props.onClose}
              fontSize="medium"
              sx={{
                color: 'b_333',
                cursor: 'pointer',
                pb: 2,
                pt: 5,
                pr: 5,
                width: 20,
                height: 20,
              }}
            />
          </Stack>
          <Typography
            variant="heading01"
            color="b_333"
            sx={{ textAlign: 'center' }}
          >
            ログアウトします。
          </Typography>
          <Typography
            variant="heading01"
            color="b_333"
            sx={{ textAlign: 'center' }}
          >
            よろしいですか？
          </Typography>
          <Button
            onClick={props.onLogOut}
            sx={{
              bgcolor: 'white',
              boxShadow: 'none',
              width: '200px',
              height: '36px',
              borderRadius: '2px',
              minHeight: '36px',
              border: '1px solid #6B70F0',
              mx: 'auto',
              mt: 5,
            }}
          >
            <Typography variant="button01" color="sp_primary_100_main">
              ログアウト
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
