import { FC } from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

export type SPButtonProps = MuiButtonProps & {
  startIconStyle?: {
    left: number;
    bottom: number;
  };
};

export const SPButton: FC<SPButtonProps> = ({
  children,
  startIconStyle,
  ...props
}) => (
  <MuiButton
    {...props}
    sx={{
      borderRadius: 4,
      minHeight: 40,
      position: 'relative',
      boxShadow: 'none',
      '&:hover': {
        // @ts-ignore
        color: props?.sx?.color,
        // @ts-ignore
        bgcolor: props?.sx?.bgcolor,
        opacity: 1,
        boxShadow: 'none',
      },
      '.MuiButton-startIcon': {
        ...startIconStyle,
      },
      '.MuiButton-endIcon': {
        position: 'absolute',
        right: 8,
      },
      '&.Mui-disabled': {
        bgcolor: 'sp_primary_100_main',
        opacity: 0.15,
        color: 'main_white',
      },
      ...props.sx,
    }}
  >
    {children}
  </MuiButton>
);
