import { TableContainer, Paper, Stack } from '@mui/material';
import { PreliminarieItems } from 'components';
import { FC } from 'react';

export const PreliminarieTable: FC = () => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Stack
        sx={{
          px: '7px',
          bgcolor: 'bg_off',
          minWidth: 1116,
          overflowX: 'auto',
          height: 'calc(100vh - 142px)',
        }}
      >
        <PreliminarieItems />
      </Stack>
    </TableContainer>
  );
};
