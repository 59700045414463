import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { useCallback, useEffect, useMemo } from 'react';
import { FormikContextType, FormikState } from 'formik';
import {
  CODEBANK,
  FundsPairOffType,
  SPBorrowingType,
  SPScheduledLoanPayoffType,
} from 'constant';
import { get } from 'lodash';

type Values = AdminPreliminaries | SalePersonPreliminaries;

export const useCurrentBorrowing = (
  values: Values,
  setFieldValue: FormikContextType<Values>['setFieldValue'],
  touched: FormikState<Values>['touched']
) => {
  const isMCJ = useMemo(
    () => values.p_application_header.name_master_banks?.includes(CODEBANK.MCJ),
    [values.p_application_header.name_master_banks]
  );

  const handleResetValue = useCallback(
    (key: string, defaultValue: string | number) => {
      if (defaultValue === get(values, key) || get(values, key) === undefined) {
        return;
      }
      return setFieldValue(key, defaultValue);
    },
    [setFieldValue, values]
  );
  useEffect(() => {
    if (
      values.p_application_header.completely_repayment_type !==
      FundsPairOffType.OTHERS
    ) {
      handleResetValue(
        'p_application_header.other_complete_repayment_type',
        ''
      );
    }
  }, [values.p_application_header.completely_repayment_type]);

  useResetOneRow(0, values, touched, handleResetValue);
  useResetOneRow(1, values, touched, handleResetValue);
  useResetOneRow(2, values, touched, handleResetValue);
  useResetOneRow(3, values, touched, handleResetValue);
  useResetOneRow(4, values, touched, handleResetValue);
  useResetOneRow(5, values, touched, handleResetValue);
  useResetOneRow(6, values, touched, handleResetValue);
  useResetOneRow(7, values, touched, handleResetValue);

  useResetSubArray(
    isMCJ,
    values.p_application_header,
    touched,
    handleResetValue
  );
};

function useResetOneRow(
  index: number,
  values: Values,
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useBorrowingFromHousingFinanceAgency(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useLoanPurpose(index, values.p_application_header, touched, handleResetValue);
  useOtherLoanPurpose(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useBorrowingCategory(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useCardExpiryDate(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useRentalRoomNumber(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useCommonHousing(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useScheduledLoanPayoff(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useLoanDeadlineDate(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useExpectedRepaymentDate(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useBorrowingStatus(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
  useEstateMortgage(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
}

function useBorrowingStatus(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (!values.p_borrowings_attributes?.length) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].borrowing_status`,
        '0'
      );
    }
  }, [handleResetValue, index, touched, values.p_borrowings_attributes]);
}

function useBorrowingFromHousingFinanceAgency(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![SPBorrowingType.HOUSING_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].borrowing_from_housing_finance_agency`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useLoanPurpose(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![SPBorrowingType.CARD_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].loan_purpose`,
        ''
      );
    }
    if (
      ![SPBorrowingType.BUSINESS_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].business_borrowing_type`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useOtherLoanPurpose(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![SPBorrowingType.CARD_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].other_purpose`,
        ''
      );
    }
    if (
      ![SPBorrowingType.BUSINESS_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].specific_loan_purpose`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useBorrowingCategory(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![SPBorrowingType.CARD_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].borrowing_category`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useCardExpiryDate(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![SPBorrowingType.CARD_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].card_expiry_date`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useRentalRoomNumber(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![SPBorrowingType.APARTMENT_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].rental_room_number`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useCommonHousing(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![SPBorrowingType.APARTMENT_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].common_housing`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useScheduledLoanPayoff(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      [SPBorrowingType.BUSINESS_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].scheduled_loan_payoff`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useLoanDeadlineDate(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      [SPBorrowingType.CARD_LOAN].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].loan_deadline_date`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useExpectedRepaymentDate(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      !(
        ![SPBorrowingType.BUSINESS_LOAN].includes(
          values.p_borrowings_attributes?.[index]?.borrowing_type ??
            SPBorrowingType.DEFAULT
        ) &&
        values.p_borrowings_attributes?.[index]?.scheduled_loan_payoff ===
          SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].expected_repayment_date`,
        ''
      );
    }
  }, [
    values.p_borrowings_attributes?.[index]?.borrowing_type,
    values.p_borrowings_attributes?.[index]?.scheduled_loan_payoff,
  ]);
}

function useEstateMortgage(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_borrowings_attributes?.[index]) return;
    if (
      ![
        SPBorrowingType.APARTMENT_LOAN,
        SPBorrowingType.BUSINESS_LOAN,
        SPBorrowingType.CAR_LOAN,
        SPBorrowingType.EDUCATION_LOAN,
        SPBorrowingType.LOAN_FOR_LIVING_EXPENSES,
        SPBorrowingType.OTHERS,
      ].includes(
        values.p_borrowings_attributes?.[index]?.borrowing_type ??
          SPBorrowingType.DEFAULT
      )
    ) {
      handleResetValue(
        `p_application_header.p_borrowings_attributes[${index}].estate_mortgage`,
        ''
      );
    }
  }, [values.p_borrowings_attributes?.[index]?.borrowing_type]);
}

function useResetSubArray(
  isMCJ: boolean,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  const isScheduleToBeFullyRepaid = useMemo<boolean>(
    () =>
      values.p_borrowings_attributes.some(
        ({ scheduled_loan_payoff }) =>
          scheduled_loan_payoff === SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
      ),
    [values.p_borrowings_attributes]
  );

  useEffect(() => {
    if (!values.p_borrowings_attributes?.length) return;

    if (!(isMCJ && isScheduleToBeFullyRepaid)) {
      handleResetValue('p_application_header.completely_repayment_type', '');
      handleResetValue(
        'p_application_header.other_complete_repayment_type',
        ''
      );
      handleResetValue('p_application_header.refund_content', '');
      handleResetValue('p_application_header.refund_amount', '0');
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].land_rent_to_be_paid',
        ''
      );
      handleResetValue(
        'p_application_header.p_applicant_people_attributes[0].house_rent_to_be_paid',
        ''
      );
    }
  }, [isMCJ, isScheduleToBeFullyRepaid]);
}
