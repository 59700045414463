import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPMilize: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 148 32" {...props}>
      <svg
        width="148"
        height="32"
        viewBox="0 0 148 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M42.0427 24.6742C45.4544 23.4472 46.4822 21.4615 47.1764 18.5879C45.8903 18.0929 44.2006 17.5763 42.4194 17.4202C41.3485 21.8436 40.4767 24.6742 37.4847 24.6742C35.5798 24.6742 34.1914 23.4042 34.1914 21.284C34.1914 20.3099 34.5466 16.048 41.031 15.7897C41.3108 14.3636 41.5637 12.5986 41.6875 11.1887L35.4829 11.2479V9.52051H43.6893C43.431 11.5008 43.3718 11.9367 42.7153 15.8435C44.3781 15.9834 45.6912 16.3009 47.4939 16.8928C47.7899 14.9502 47.8921 13.5403 47.9298 12.6309L49.8132 12.7708C49.6733 14.1968 49.5173 15.9242 49.2159 17.5117C49.2751 17.5332 50.4267 17.9691 51.6536 18.6418L51.0563 20.4283C50.163 19.8956 49.8294 19.7126 48.8338 19.2767C48.3603 21.2194 47.3486 24.5289 43.1243 26.0356L42.0319 24.6688L42.0427 24.6742ZM37.8614 17.9153C36.5753 18.5503 35.7574 19.8364 35.7574 21.1871C35.7574 21.5638 35.8542 23.1082 37.361 23.1082C39.1476 23.1082 39.6803 21.623 40.7296 17.318C39.5619 17.3556 38.7278 17.474 37.856 17.9153H37.8614Z"
          fill="#0B1F65"
        />
        <path
          d="M57.5079 12.1466C57.0505 13.6533 56.8945 14.8049 56.8945 17.7162C58.9178 16.1126 61.1349 15.2785 63.7125 15.2785C68.3135 15.2785 69.1637 18.2328 69.1637 19.8795C69.1637 24.6365 64.5843 25.7074 60.3385 25.7074C58.5142 25.7074 57.3465 25.5513 56.5339 25.4275L56.3348 23.684C57.664 23.9208 58.8748 24.0392 60.1986 24.0392C62.9322 24.0392 65.3161 23.5064 66.5054 22.1934C67.0597 21.5961 67.2803 20.6651 67.2803 19.8526C67.2803 18.3081 66.231 16.8767 63.6103 16.8767C60.9896 16.8767 58.299 18.0068 56.9859 19.7342C56.9267 19.9333 56.9052 19.9925 56.8891 20.0893L55.124 20.0301C55.124 19.3575 55.1832 16.3816 55.2047 15.7897C55.2855 13.9439 55.463 12.8569 55.7213 11.8237L57.5079 12.1412V12.1466ZM66.091 12.1251C63.734 11.1726 60.8174 10.7959 58.1429 10.699L58.4604 8.99316C60.1663 9.07388 63.5565 9.25146 66.5915 10.2416L66.0964 12.1251H66.091Z"
          fill="#0B1F65"
        />
        <path
          d="M74.6799 10.1834C74.5239 11.3727 74.2656 13.1754 74.2656 15.8553C74.2656 18.928 74.5024 23.2706 76.7248 23.2706C77.6181 23.2706 78.6459 22.4581 80.0181 18.8903L81.4872 19.7244C80.8307 21.5702 79.9213 23.233 78.8127 24.3415C78.3392 24.8151 77.602 25.294 76.5903 25.294C73.3777 25.294 72.4844 20.8545 72.4844 15.6992C72.4844 13.5359 72.6835 11.378 72.8019 10.0273L74.6853 10.1834H74.6799ZM86.8147 23.4052C86.8524 22.8886 86.8954 22.2966 86.8954 21.3818C86.8954 16.205 85.351 13.353 83.2846 11.6686L84.6514 10.6193C87.0084 12.5996 89.0533 15.3978 88.7735 23.3298L86.8093 23.4106L86.8147 23.4052Z"
          fill="#0B1F65"
        />
        <path
          d="M98.6696 10.2421C97.6955 18.8091 94.9403 23.2109 93.6327 25.2881L91.8892 24.3571C93.5358 21.801 95.8336 17.9319 96.7861 9.96225L98.6696 10.2421ZM106.758 24.9329C105.983 20.2566 104.637 15.6556 102.001 10.6564L103.669 9.86539C104.939 12.2062 107.554 17.3992 108.722 24.4594L106.758 24.9329ZM106.203 8.67613C106.736 9.39184 107.414 10.4035 108.264 12.3838L106.914 13.1749C106.279 11.5712 105.864 10.7587 104.949 9.38646L106.198 8.67075L106.203 8.67613ZM108.921 7.46534C109.733 8.55774 110.25 9.54789 110.96 11.173L109.61 11.9641C109.012 10.3981 108.56 9.54789 107.646 8.17567L108.915 7.45996L108.921 7.46534Z"
          fill="#0B1F65"
        />
        <path
          d="M119.291 14.7994C118.123 14.0084 116.041 12.8353 113.183 11.942L114.076 10.3545C117.585 11.4038 119.764 12.8137 120.281 13.1312L119.291 14.7994ZM113.603 23.4041C123.634 22.8713 126.588 15.8488 127.955 11.4092L129.499 12.5608C128.547 15.3752 125.377 24.6364 113.958 25.3897L113.603 23.4094V23.4041Z"
          fill="#0B1F65"
        />
        <path
          d="M132.851 24.2606C136.839 23.3888 143.636 21.0856 144.965 11.7922H137.926C136.618 13.8532 135.133 15.5967 132.733 17.2057L131.603 15.855C133.508 14.5689 135.391 12.82 136.737 10.1455H147.047C146.175 20.1977 140.563 24.379 133.605 25.9449L132.851 24.2606Z"
          fill="#0B1F65"
        />
        <path
          d="M15.6855 3.17637C15.7286 3.53154 15.7824 3.8867 15.8201 4.24186C15.8846 4.8069 15.8739 4.80152 16.342 4.47864C17.0039 4.01047 17.6712 3.55306 18.3385 3.09566C18.473 3.00417 18.5376 2.9396 18.4569 2.75125C18.2739 2.32613 18.2524 1.87411 18.4461 1.43823C18.7259 0.808618 19.3287 0.598748 19.9529 0.889337C20.7063 1.23912 21.0937 2.38533 20.7063 3.10642C20.3188 3.82751 19.4739 3.96742 18.9035 3.38086C18.7313 3.20328 18.6345 3.18176 18.4407 3.34857C17.6551 4.02123 16.9071 4.72618 16.1806 5.45265C16.0945 5.53875 16.0837 5.61947 16.1107 5.73786C16.7134 8.31548 17.7143 10.7155 19.3609 12.8142C20.5663 14.3533 22.03 15.5963 23.7251 16.5703C24.2687 16.8825 24.8337 17.1623 25.4256 17.4367C25.2373 17.5551 25.0382 17.582 24.8498 17.6358C23.6875 17.9533 22.5036 18.1632 21.2928 18.1363C19.8076 18.104 18.4461 17.7219 17.343 16.6726C16.4927 15.8708 15.9761 14.8645 15.6479 13.7613C15.1044 11.9371 15.0129 10.059 15.0882 8.17019C15.1097 7.61592 15.1474 7.06165 15.1797 6.47509C14.9214 6.66343 14.7277 6.88945 14.5232 7.09932C12.1823 9.45093 9.88991 11.8564 7.79122 14.4394C6.51586 16.0107 5.30507 17.6251 4.34183 19.4117C3.88442 20.2619 3.49158 21.1444 3.30862 22.1023C3.19023 22.7265 3.19023 23.34 3.42701 23.9373C3.43777 23.9642 3.43777 23.9965 3.45392 24.0611C2.38843 23.905 1.37137 23.6898 0.601845 22.8772C0.0152871 22.2691 -0.0439069 21.5157 0.0206683 20.7408C0.133675 19.4601 0.687945 18.3408 1.37675 17.2807C2.48529 15.5748 3.88442 14.1219 5.37503 12.7443C7.07551 11.1729 8.88899 9.73075 10.7455 8.34777C12.1877 7.28228 13.6568 6.25446 15.142 5.25354C15.2604 5.17282 15.3196 5.0921 15.3358 4.94681C15.4165 4.35487 15.5187 3.76831 15.6156 3.18176H15.6802L15.6855 3.17637Z"
          fill="#0B1F65"
        />
        <path
          d="M13.4684 9.75831C13.2854 10.4041 13.0702 11.0391 12.898 11.6794C12.6343 12.6481 12.4513 13.6274 12.4782 14.6284C12.5159 15.9791 12.9195 17.1737 14.0227 18.0347C14.8029 18.6428 15.7124 18.9441 16.6702 19.1217C17.0954 19.2024 17.5259 19.2562 17.994 19.2778C17.8218 19.4123 17.6335 19.4392 17.4667 19.4984C16.2074 19.9074 14.9321 20.2679 13.6137 20.4294C12.6989 20.537 11.784 20.5747 10.9069 20.2087C10.0513 19.8536 9.62616 19.1755 9.53467 18.2769C9.40552 17.0445 9.77683 15.9145 10.2665 14.8167C10.993 13.1969 11.9778 11.7332 13.0433 10.318C13.1832 10.1296 13.3285 9.94127 13.4684 9.75293V9.75831Z"
          fill="#0B1F65"
        />
        <path
          d="M4.04064 26.4883H4.83707C4.83707 26.4883 4.88012 26.4937 4.89626 26.5098C4.91241 26.526 4.91779 26.5475 4.91779 26.569V31.1754C4.91779 31.1754 4.91241 31.2184 4.89626 31.2346C4.88012 31.2507 4.85859 31.2561 4.83707 31.2561H4.04064C4.04064 31.2561 3.99759 31.2507 3.98145 31.2346C3.9653 31.2184 3.95992 31.1969 3.95992 31.1754V28.1726C3.95992 28.1726 3.95992 28.1457 3.94378 28.1457C3.92763 28.1457 3.92763 28.1511 3.91687 28.1672L3.19578 29.2973C3.17426 29.335 3.14197 29.3511 3.09892 29.3511H2.69532C2.65227 29.3511 2.61998 29.335 2.59846 29.2973L1.87737 28.1672C1.87737 28.1672 1.86123 28.1457 1.85046 28.1511C1.8397 28.1511 1.83432 28.1619 1.83432 28.1834V31.1807C1.83432 31.1807 1.82894 31.2238 1.81279 31.2399C1.79665 31.2561 1.77513 31.2615 1.7536 31.2615H0.962555C0.962555 31.2615 0.919505 31.2561 0.903361 31.2399C0.887217 31.2238 0.881836 31.2023 0.881836 31.1807V26.5744C0.881836 26.5744 0.887217 26.5313 0.903361 26.5152C0.919505 26.499 0.94103 26.4937 0.962555 26.4937H1.75898C1.80203 26.4937 1.82894 26.5098 1.85585 26.5475L2.87829 28.135C2.89443 28.1619 2.90519 28.1619 2.92134 28.135L3.94916 26.5475C3.97068 26.5098 4.00297 26.4937 4.04602 26.4937L4.04064 26.4883Z"
          fill="#0B1F65"
        />
        <path
          d="M6.76371 31.2346C6.76371 31.2346 6.74219 31.1969 6.74219 31.1754V26.569C6.74219 26.569 6.74757 26.526 6.76371 26.5098C6.77986 26.4937 6.80138 26.4883 6.82291 26.4883H7.61933C7.61933 26.4883 7.66238 26.4937 7.67853 26.5098C7.69467 26.526 7.70005 26.5475 7.70005 26.569V31.1754C7.70005 31.1754 7.69467 31.2184 7.67853 31.2346C7.66238 31.2507 7.64086 31.2561 7.61933 31.2561H6.82291C6.82291 31.2561 6.77986 31.2507 6.76371 31.2346Z"
          fill="#0B1F65"
        />
        <path
          d="M9.54594 31.2346C9.54594 31.2346 9.52441 31.1969 9.52441 31.1754V26.569C9.52441 26.569 9.52979 26.526 9.54594 26.5098C9.56208 26.4937 9.58361 26.4883 9.60513 26.4883H10.4016C10.4016 26.4883 10.4446 26.4937 10.4608 26.5098C10.4769 26.526 10.4823 26.5475 10.4823 26.569V30.3951C10.4823 30.3951 10.493 30.4274 10.5146 30.4274H12.7424C12.7424 30.4274 12.7855 30.4328 12.8016 30.4489C12.8177 30.465 12.8231 30.4866 12.8231 30.5081V31.17C12.8231 31.17 12.8177 31.213 12.8016 31.2292C12.7855 31.2453 12.7639 31.2507 12.7424 31.2507H9.60513C9.60513 31.2507 9.56208 31.2453 9.54594 31.2292V31.2346Z"
          fill="#0B1F65"
        />
        <path
          d="M13.9371 31.2346C13.9371 31.2346 13.9155 31.1969 13.9155 31.1754V26.569C13.9155 26.569 13.9209 26.526 13.9371 26.5098C13.9532 26.4937 13.9747 26.4883 13.9962 26.4883H14.7927C14.7927 26.4883 14.8357 26.4937 14.8519 26.5098C14.868 26.526 14.8734 26.5475 14.8734 26.569V31.1754C14.8734 31.1754 14.868 31.2184 14.8519 31.2346C14.8357 31.2507 14.8142 31.2561 14.7927 31.2561H13.9962C13.9962 31.2561 13.9532 31.2507 13.9371 31.2346Z"
          fill="#0B1F65"
        />
        <path
          d="M16.5201 31.2346C16.5201 31.2346 16.4985 31.1969 16.4985 31.1754V30.4381C16.4985 30.4005 16.5093 30.3682 16.5308 30.3359L18.7318 27.3547C18.7318 27.3547 18.7425 27.3385 18.7318 27.3278C18.7264 27.317 18.7156 27.3116 18.6995 27.3116H16.5739C16.5739 27.3116 16.5308 27.3062 16.5147 27.2901C16.4985 27.2739 16.4932 27.2524 16.4932 27.2309V26.569C16.4932 26.569 16.4985 26.526 16.5147 26.5098C16.5308 26.4937 16.5524 26.4883 16.5739 26.4883H19.7273C19.7273 26.4883 19.7704 26.4937 19.7865 26.5098C19.8026 26.526 19.808 26.5475 19.808 26.569V27.3062C19.808 27.3439 19.7973 27.3762 19.7757 27.4085L17.5748 30.3897C17.5748 30.3897 17.5748 30.4058 17.5748 30.4166C17.5748 30.4274 17.5909 30.4328 17.6017 30.4328H19.7273C19.7273 30.4328 19.7704 30.4381 19.7865 30.4543C19.8026 30.4704 19.808 30.4919 19.808 30.5135V31.1754C19.808 31.1754 19.8026 31.2184 19.7865 31.2346C19.7704 31.2507 19.7488 31.2561 19.7273 31.2561H16.5739C16.5739 31.2561 16.5308 31.2507 16.5147 31.2346H16.5201Z"
          fill="#0B1F65"
        />
        <path
          d="M24.6781 27.2901C24.6781 27.2901 24.6404 27.3116 24.6189 27.3116H22.3857C22.3857 27.3116 22.3534 27.3224 22.3534 27.3439V28.3987C22.3534 28.3987 22.3641 28.4309 22.3857 28.4309H23.8494C23.8494 28.4309 23.8924 28.4363 23.9086 28.4525C23.9247 28.4686 23.9301 28.4901 23.9301 28.5117V29.1736C23.9301 29.1736 23.9247 29.2166 23.9086 29.2327C23.8924 29.2489 23.8709 29.2543 23.8494 29.2543H22.3857C22.3857 29.2543 22.3534 29.265 22.3534 29.2866V30.3897C22.3534 30.3897 22.3641 30.422 22.3857 30.422H24.6189C24.6189 30.422 24.6619 30.4274 24.6781 30.4435C24.6942 30.4597 24.6996 30.4812 24.6996 30.5027V31.1646C24.6996 31.1646 24.6942 31.2077 24.6781 31.2238C24.6619 31.24 24.6404 31.2453 24.6189 31.2453H21.4709C21.4709 31.2453 21.4278 31.24 21.4117 31.2238C21.3955 31.2077 21.3901 31.1861 21.3901 31.1646V26.5583C21.3901 26.5583 21.3955 26.5152 21.4117 26.4991C21.4278 26.4829 21.4493 26.4775 21.4709 26.4775H24.6189C24.6189 26.4775 24.6619 26.4829 24.6781 26.4991C24.6942 26.5152 24.6996 26.5367 24.6996 26.5583V27.2202C24.6996 27.2202 24.6942 27.2632 24.6781 27.2793V27.2901Z"
          fill="#0B1F65"
        />
      </svg>
    </SvgIcon>
  );
};
