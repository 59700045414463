import {
  CODEBANK,
  CoOwnerType,
  DesiredMonthlyBonusType,
  LandAdvanceType,
  LoanTargetZeroType,
  LoanType,
  PurposeType,
  RepaymentType,
} from 'constant';
import { SPStepOneForm } from 'types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'containers/AuthModal/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { spGetSubmittedPreliminary, spLogin } from 'pages/SPLogin/thunk';
import { convertLocalTime, zeroPad } from 'utils';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { getMasterBanks } from 'pages/StepSynthesisInformation/thunk';
import { deleteUser } from '../SPUnsubcribed/thunk';

export type InitialState = {
  form: SPStepOneForm;
  status: {
    firstElement: boolean | undefined;
    secondElement: boolean | undefined;
    isReseted: boolean;
  };
  application_number: string;
};

const getLoanTargetZero = (loanTarget: PurposeType) => {
  switch (loanTarget) {
    case PurposeType.ONE:
    case PurposeType.TWO:
    case PurposeType.THREE:
    case PurposeType.FOUR:
    case PurposeType.FIVE:
    case PurposeType.SIX:
      return LoanTargetZeroType.ZERO;
    case PurposeType.SEVEN:
      return LoanTargetZeroType.SEVEN;
    case PurposeType.EIGHT:
      return LoanTargetZeroType.EIGHT;
    default:
      return LoanTargetZeroType.DEFAULT;
  }
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      loan_apply_date: '',
      is_home_loan_plus: false,
      scheduled_date_moving: '',
      loan_type: LoanType.DEFAULT,
      loan_target: PurposeType.DEFAULT,
      loan_target_zero: LoanTargetZeroType.DEFAULT,
      master_bank_ids: [],
      has_land_advance_plan: LandAdvanceType.DEFAULT,
      p_application_banks_attributes: [],
      p_borrowing_details_attributes: [
        {
          loan_desired_borrowing_date: '',
          temporary_desired_loan_amount: '',
          halfyear_bonus: '',
          desired_monthly_bonus: DesiredMonthlyBonusType.ONE,
          loan_term_year_num: '',
          loan_term_month_num: '',
          repayment_method: RepaymentType.DEFAULT,
        },
      ],
      p_applicant_people_attributes: [{ has_join_guarantor: CoOwnerType.NONE }],
      bonus_repayment_amount: '',
      bonus_times: '2',
      borrowing_period: '',
      bonus_repayment: '',
      pair_loan_applicant_last_name: '',
      pair_loan_applicant_first_name: '',
      pair_loan_relationship_name: '',
      created_at: '',
    },
    master_bank_codes: [],
  },
  status: {
    firstElement: undefined,
    secondElement: undefined,
    isReseted: false,
  },
  application_number: '',
};

const stepOneSlice = createSlice({
  name: 'stepOne',
  initialState,
  reducers: {
    addStatus: (
      state,
      action: PayloadAction<{
        firstElement: boolean | undefined;
        secondElement: boolean | undefined;
        isReseted: boolean;
      }>
    ) => {
      state.status = { ...action.payload };
    },
    saveForm: (state, action: PayloadAction<SPStepOneForm>) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const {
        p_applicant_people,
        p_application_header,
        p_borrowing_details,
        master_bank_codes,
      } = action.payload.data;
      const stepOneApplicantPeopleFields = p_applicant_people.map((data) => ({
        has_join_guarantor: data.has_join_guarantor,
      }));
      const {
        loan_apply_date,
        is_home_loan_plus,
        scheduled_date_moving,
        loan_type,
        loan_target,
        master_bank_ids,
        has_land_advance_plan,
        bonus_repayment,
        pair_loan_applicant_first_name,
        pair_loan_applicant_last_name,
        pair_loan_relationship_name,
      } = p_application_header;

      const convertIsHomeLoanPlus = is_home_loan_plus === 'true';
      state.form.p_application_header = {
        ...state.form.p_application_header,
        loan_apply_date,
        is_home_loan_plus: convertIsHomeLoanPlus,
        scheduled_date_moving,
        loan_type,
        loan_target,
        pair_loan_applicant_first_name: pair_loan_applicant_first_name ?? '',
        pair_loan_applicant_last_name: pair_loan_applicant_last_name ?? '',
        pair_loan_relationship_name: pair_loan_relationship_name ?? '',
        loan_target_zero: getLoanTargetZero(loan_target),
        ...(!!master_bank_ids && { master_bank_ids }),
        has_land_advance_plan,
        bonus_repayment,
        p_borrowing_details_attributes: p_borrowing_details,
        p_applicant_people_attributes: stepOneApplicantPeopleFields,
      };

      if (!!master_bank_codes.length) {
        state.form.master_bank_codes = master_bank_codes;
      }
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const {
        loan_apply_date,
        is_home_loan_plus,
        scheduled_date_moving,
        loan_type,
        loan_target,
        has_land_advance_plan,
        p_borrowing_details_attributes,
        p_applicant_people_attributes,
        p_application_banks_attributes,
        pair_loan_applicant_first_name,
        pair_loan_applicant_last_name,
        pair_loan_relationship_name,
        created_at,
      } = action.payload.data;

      const { master_bank_codes } = action.payload;

      const stepOneApplicantPeopleFields = p_applicant_people_attributes.map(
        (data) => ({
          id: data.id,
          has_join_guarantor: data.has_join_guarantor,
          _destroy: data._destroy,
        })
      );

      const stepOneBorrowingDetailsFields = p_borrowing_details_attributes.map(
        (data) => ({
          ...data,
          loan_term_month_num: !!data.loan_term_month_num
            ? zeroPad(+data.loan_term_month_num)
            : '',
          loan_term_year_num: !!data.loan_term_year_num
            ? zeroPad(+data.loan_term_year_num)
            : '',
        })
      );

      const stepOneMasterBankIds = p_application_banks_attributes.map(
        (data) => data.s_master_bank_id
      );

      state.form.p_application_header = {
        ...state.form.p_application_header,
        loan_apply_date,
        is_home_loan_plus,
        scheduled_date_moving,
        loan_type,
        loan_target,
        pair_loan_applicant_first_name: pair_loan_applicant_first_name ?? '',
        pair_loan_applicant_last_name: pair_loan_applicant_last_name ?? '',
        pair_loan_relationship_name: pair_loan_relationship_name ?? '',
        loan_target_zero: getLoanTargetZero(loan_target),
        has_land_advance_plan,
        p_borrowing_details_attributes: stepOneBorrowingDetailsFields,
        p_applicant_people_attributes: stepOneApplicantPeopleFields,
        master_bank_ids: stepOneMasterBankIds,
        p_application_banks_attributes,
        created_at: convertLocalTime(created_at),
      };
      state.form.master_bank_codes = master_bank_codes;
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const {
        loan_apply_date,
        is_home_loan_plus,
        scheduled_date_moving,
        loan_type,
        loan_target,
        has_land_advance_plan,
        p_borrowing_details_attributes,
        p_applicant_people_attributes,
        p_application_banks_attributes,
        pair_loan_applicant_first_name,
        pair_loan_applicant_last_name,
        pair_loan_relationship_name,
      } = action.payload.data;

      const { master_bank_codes } = action.payload;

      const stepOneApplicantPeopleFields = p_applicant_people_attributes.map(
        (data) => ({
          id: data.id,
          has_join_guarantor: data.has_join_guarantor,
          _destroy: data._destroy,
        })
      );

      const stepOneBorrowingDetailsFields = p_borrowing_details_attributes.map(
        (data) => ({
          ...data,
          loan_term_month_num: data.loan_term_month_num
            ? zeroPad(+data.loan_term_month_num)
            : '',
          loan_term_year_num: data.loan_term_year_num
            ? zeroPad(+data.loan_term_year_num)
            : '',
        })
      );

      const stepOneMasterBankIds = p_application_banks_attributes.map(
        (data) => data.s_master_bank_id
      );

      state.form.p_application_header = {
        ...state.form.p_application_header,
        loan_apply_date,
        is_home_loan_plus,
        scheduled_date_moving,
        loan_type,
        loan_target,
        pair_loan_applicant_first_name: pair_loan_applicant_first_name ?? '',
        pair_loan_applicant_last_name: pair_loan_applicant_last_name ?? '',
        pair_loan_relationship_name: pair_loan_relationship_name ?? '',
        loan_target_zero: getLoanTargetZero(loan_target),
        has_land_advance_plan,
        p_borrowing_details_attributes: stepOneBorrowingDetailsFields,
        p_applicant_people_attributes: stepOneApplicantPeopleFields,
        master_bank_ids: stepOneMasterBankIds,
        p_application_banks_attributes,
      };
      state.form.master_bank_codes = master_bank_codes;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(spLogin.fulfilled, (state, action) => {
      if (action.payload.data.application_number) {
        state.application_number = action.payload.data.application_number;
      }
    });
    builder.addCase(getMasterBanks.fulfilled, (state, action) => {
      const master_bank_ids =
        action.payload.data
          .filter((data) => data.code === CODEBANK.SBI)
          .map((data) => data.id) || [];
      if (
        !state.form.p_application_header.master_bank_ids.length &&
        !state.application_number
      ) {
        state.form.p_application_header.master_bank_ids = master_bank_ids;
      }
    });
  },
});

export const { saveForm, addStatus } = stepOneSlice.actions;

export default stepOneSlice.reducer;
