import { Stack, Typography } from '@mui/material';
import { Button, Icons, ManagerDocumentUploadDetailTable } from 'components';
import { AdminLayout } from 'containers';
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import { OrderType } from 'types';
import { routeNames } from 'navigations/routes';
import { managerDocumentUploadSelector } from '../ManagerDocumentUpload/selectors';
import {
  getEditArchiveFileManager,
  getEditArchiveFileSalePerson,
  sortEditArchiveFileManagerItems,
  sortEditArchiveFileSalePersonItems,
} from '../ManagerDocumentUpload/thunk';
import { DetailArchiveFileType } from '../ManagerDocumentUpload/slice';

type QuizParams = {
  id: string;
};

const ManagerDocumentUploadDetailPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<QuizParams>();
  const { detailManagerFile, detailSalePersonFile } = useSelector(
    managerDocumentUploadSelector
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [orderSalePerson, setOrderSalePerson] = useState<OrderType>();
  const [orderSalePersonBy, setOrderSalePersonBy] =
    useState<keyof DetailArchiveFileType>();
  const [sortSalePersonList, setSortSalePersonList] = useState<
    DetailArchiveFileType[]
  >([]);
  const [orderManager, setOrderManager] = useState<OrderType>();
  const [orderManagerBy, setOrderManagerBy] =
    useState<keyof DetailArchiveFileType>();
  const [sortManagerList, setSortManagerList] = useState<
    DetailArchiveFileType[]
  >([]);
  const [reset, setReset] = useState<number>(0);

  const isSalePerson = useMemo(() => {
    return !location.pathname.startsWith('/manager');
  }, [location.pathname]);

  const getDocumentUploadManagerDetailFile = useCallback(async () => {
    if (id) {
      const result = await dispatch(getEditArchiveFileManager({ id }));
      if (getEditArchiveFileManager.rejected.match(result)) {
        navigate(routeNames.ManagerDocumentUpload.path);
      }
    }
  }, [dispatch, id, navigate]);

  const getDocumentUploadSalePersonDetailFile = useCallback(async () => {
    if (id) {
      const result = await dispatch(getEditArchiveFileSalePerson({ id }));
      if (getEditArchiveFileSalePerson.rejected.match(result)) {
        navigate(routeNames.SalePersonDocumentUpload.path);
      }
    }
  }, [dispatch, id, navigate]);

  useEffect(() => {
    if (isSalePerson) {
      getDocumentUploadSalePersonDetailFile();
    } else {
      getDocumentUploadManagerDetailFile();
    }
  }, [
    getDocumentUploadManagerDetailFile,
    getDocumentUploadSalePersonDetailFile,
    isSalePerson,
    reset,
  ]);

  const handleRequestSortManager = useCallback(
    async (
      event: MouseEvent<unknown>,
      property: keyof DetailArchiveFileType
    ) => {
      if (!!id) {
        const isAsc = orderManagerBy === property && orderManager === 'asc';
        setOrderManager(isAsc ? 'desc' : 'asc');
        setOrderManagerBy(property);
        const result = await dispatch(
          sortEditArchiveFileManagerItems({
            id,
            order_key: property,
            order_sort: isAsc ? 'asc' : 'desc',
          })
        );
        if (sortEditArchiveFileManagerItems.fulfilled.match(result)) {
          setSortManagerList(result.payload.data.file_name);
        }
      }
    },
    [dispatch, id, orderManager, orderManagerBy]
  );

  const handleRequestSortSalePerson = useCallback(
    async (
      event: MouseEvent<unknown>,
      property: keyof DetailArchiveFileType
    ) => {
      if (!!id) {
        const isAsc =
          orderSalePersonBy === property && orderSalePerson === 'asc';
        setOrderSalePerson(isAsc ? 'desc' : 'asc');
        setOrderSalePersonBy(property);
        const result = await dispatch(
          sortEditArchiveFileSalePersonItems({
            id,
            order_key: property,
            order_sort: isAsc ? 'asc' : 'desc',
          })
        );
        if (sortEditArchiveFileSalePersonItems.fulfilled.match(result)) {
          setSortSalePersonList(result.payload.data.file_name);
        }
      }
    },
    [dispatch, id, orderSalePerson, orderSalePersonBy]
  );

  return (
    <>
      <AdminLayout
        loading={loading}
        hasDrawer={false}
        header={{
          left: {
            mainTitle: (
              <Stack
                direction="row"
                spacing={2}
                onClick={() => {
                  if (isSalePerson) {
                    navigate(routeNames.SalePersonDocumentUpload.path);
                  } else {
                    navigate(routeNames.ManagerDocumentUpload.path);
                  }
                }}
              >
                <Typography
                  variant="text_steps"
                  color="sp_primary_100_main"
                  sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                >
                  アップロード書類一覧
                </Typography>
                <Icons.ArrowRight sx={{ width: 16, height: 26 }} />
                <Typography variant="text_steps" sx={{ whiteSpace: 'nowrap' }}>
                  詳細
                </Typography>
              </Stack>
            ),
          },
          children: <Stack direction="row" spacing={2}></Stack>,
          right: {
            contentPopover: (
              <Button
                sx={{
                  py: 0,
                  flex: 1,
                  width: '100%',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  minHeight: 42,
                  height: 42,
                  boxShadow: 'none',
                  bgcolor: 'main_white',
                  justifyContent: 'flex-start',
                  px: '10px',
                  '&.MuiButton-root:hover': {
                    bgcolor: 'sp_primary_100_main',
                    '& .MuiTypography-root': {
                      color: 'main_white',
                    },
                  },
                }}
                onClick={() => {}}
              >
                <Typography variant="auth_popover_text" color="b_333">
                  管理画面をエクスポート
                </Typography>
                <Icons.Excel sx={{ color: 'attention', ml: '9px' }} />
              </Button>
            ),
          },
          showIconHome: false,
        }}
        footer={{
          left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
          right: (
            <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />
          ),
          bgcolor: 'footer_bg',
        }}
      >
        <Stack sx={{ pb: '29px' }}>
          {isSalePerson ? (
            <ManagerDocumentUploadDetailTable
              onRequestSort={handleRequestSortSalePerson}
              order={orderSalePerson}
              orderBy={orderSalePersonBy}
              rows={
                sortSalePersonList.length
                  ? sortSalePersonList
                  : detailSalePersonFile
              }
              fetching={false}
              onReset={setReset}
              changeApplicationStatus={() => {}}
              confirmUpdateLinking={() => {}}
              onAssignManager={() => {}}
            />
          ) : (
            <ManagerDocumentUploadDetailTable
              onRequestSort={handleRequestSortManager}
              order={orderManager}
              orderBy={orderManagerBy}
              rows={
                sortManagerList.length ? sortManagerList : detailManagerFile
              }
              fetching={false}
              onReset={setReset}
              changeApplicationStatus={() => {}}
              confirmUpdateLinking={() => {}}
              onAssignManager={() => {}}
            />
          )}
        </Stack>
      </AdminLayout>
    </>
  );
};

export default ManagerDocumentUploadDetailPage;
