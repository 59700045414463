import { z } from 'zod';

export const publicHolidayResponseSchema = z.object({
  date: z.string(),
  localName: z.string(),
  name: z.string(),
  countryCode: z.string(),
  fixed: z.boolean(),
  global: z.boolean(),
  counties: z.array(z.string()),
  launchYear: z.number(),
  type: z.array(z.string()),
});

export const publicHolidayRequestSchema = z.object({
  year: z.number(),
});

export type PublicHolidayRequest = z.infer<typeof publicHolidayRequestSchema>;
export type PublicHolidayResponse = z.infer<typeof publicHolidayResponseSchema>;
