import { FC, useCallback, useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { SPStepLayout } from 'containers';
import SPUpdatePasswordTemplate from 'components/template/SPUpdatePasswordTemplate';
import { Typography } from '@mui/material';
import { Icons } from 'components';
import NotifyScreenTemplate from 'components/template/NotifyScreenTemplate';
import { routeNames } from 'navigations/routes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ChangeEmailError } from 'services';
import { ChangeEmailErrorCode } from 'constant';
import { useSelector } from 'react-redux';
import { saveLoginEmail } from 'pages/SPAccountInformation/slice';
import { supportDvh } from 'utils';
import { spAccountInformationSelector } from '../SPAccountInformation/selectors';
import { validationSchema } from './validationSchema';
import { sendEmailUpdateEmailAction, updateNewEmailAction } from './thunk';

const SPUpdateEmailPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userEmail = useSelector(spAccountInformationSelector);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('email_token');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [sendEmail, setSendEmail] = useState(false);
  const [checkedToken, setCheckedToken] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: userEmail,
      new_email: '',
      new_email_confirmation: '',
    },
    validationSchema,
    async onSubmit(values) {
      const result = await dispatch(
        sendEmailUpdateEmailAction({
          current_email: values.email,
          new_email: values.new_email,
          email_confirmation: values.new_email_confirmation,
        })
      );
      if (sendEmailUpdateEmailAction.fulfilled.match(result)) {
        setErrorMessage(undefined);
        return setSendEmail(true);
      }
      if (sendEmailUpdateEmailAction.rejected.match(result)) {
        const { error } = result.payload as ChangeEmailError;
        switch (error) {
          case ChangeEmailErrorCode.UNKNOWN:
            return setErrorMessage(
              'サーバー内部でエラーが発生しました。 再度お試し下さい。'
            );
          case ChangeEmailErrorCode.CURRENT_INCORRECT:
            return setErrorMessage('現在のメールアドレスが正しくありません。');
          case ChangeEmailErrorCode.EMAIL_CONFIRM_NOT_MATCH:
            return setErrorMessage(
              'メールアドレスとメールアドレス（確認用）が一致していません。'
            );
          case ChangeEmailErrorCode.TAKEN:
            return setErrorMessage(
              '新しいメールアドレスは既に存在しています。'
            );
          case ChangeEmailErrorCode.USER_NOT_FOUND:
            return setErrorMessage('該当するユーザーが見つかりません。');
          case ChangeEmailErrorCode.TOKEN_EXPIRED:
            return setErrorMessage('有効期限が切れました。');
          case ChangeEmailErrorCode.NEW_EMAIL_IS_SAME:
            return setErrorMessage(
              '新しいメールアドレスは現在のメールアドレスと違うものをご利用ください。'
            );
        }
      }
    },
  });

  const checkChangeEmailToken = useCallback(async () => {
    if (!token) {
      return setCheckedToken(false);
    }
    const result = await dispatch(updateNewEmailAction({ email_token: token }));
    if (updateNewEmailAction.fulfilled.match(result)) {
      dispatch(saveLoginEmail(result.payload.email));
      return setCheckedToken(true);
    }
    return setCheckedToken(false);
  }, [dispatch, token]);

  useEffect(() => {
    checkChangeEmailToken();
  }, [checkChangeEmailToken, errorMessage]);

  return (
    <SPStepLayout
      hasStepBar={false}
      hasFooter={false}
      footerLogo
      hasModalSaveDraft={false}
      sx={{ minHeight: supportDvh('calc(100dvh - 158px)') }}
    >
      <FormikProvider value={formik}>
        {!checkedToken && !sendEmail && (
          <SPUpdatePasswordTemplate
            title="メールアドレス変更"
            formOption={[
              {
                formTitle: '現在のメールアドレス',
                formName: 'email',
                type: 'email',
                formPlaceholder: '例：sample@sample.co.jp',
                autoTrim: true,
              },
              {
                formTitle: '新しいメールアドレス',
                formName: 'new_email',
                type: 'new_email',
                formPlaceholder: '例：sample@sample.co.jp',
                autoTrim: true,
              },
              {
                formTitle: '新しいメールアドレス（確認用）',
                formName: 'new_email_confirmation',
                type: 'new_email_confirmation',
                formPlaceholder: '例：sample@sample.co.jp',
                autoTrim: true,
              },
            ]}
            onSubmit={formik.handleSubmit}
            disabledButton={
              formik.isSubmitting || !(formik.isValid && formik.dirty)
            }
            errorMessage={errorMessage}
            buttonTitle="確認メールを送信する"
            backButtonTitle="キャンセル"
          />
        )}

        {sendEmail && !checkedToken && (
          <NotifyScreenTemplate
            title={`新しいメールアドレスに\nメールを送信しました`}
            icon={<Icons.SPAirPlane sx={{ width: 36, height: 37, pb: 4 }} />}
          >
            <Typography variant="sp_change_password_note" color="b_333">
              {`メールに記載されている\n認証用URLにアクセスし、\nメールアドレスの変更手続きを\n完了してください。`}
            </Typography>
          </NotifyScreenTemplate>
        )}

        {checkedToken && (
          <NotifyScreenTemplate
            title="メールアドレス変更完了"
            icon={<Icons.SPInfoModal sx={{ width: 36, height: 37, pb: 4 }} />}
            buttonTitle="ログイン"
            onClick={() => {
              formik.resetForm();
              navigate(routeNames.Login.path);
            }}
          >
            <Typography variant="sp_change_password_note" px={6} color="b_333">
              {`メールアドレスの変更が完了しました。\n変更後のメールアドレスで再度ログインして\n引き続きご利用ください。`}
            </Typography>
          </NotifyScreenTemplate>
        )}
      </FormikProvider>
    </SPStepLayout>
  );
};

export default SPUpdateEmailPage;
