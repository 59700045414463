import { FC, useCallback, useContext, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { Icons, SPButton, SPModal } from 'components';
import { useNavigate } from 'react-router';
import { routeNames } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { resetAuth } from 'containers/AuthModal/slice';
import { ImageContext } from 'context';
import { deleteUser } from './thunk';

const SPUnsubcribedPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { resetImage } = useContext(ImageContext);
  const [open, setOpen] = useState<boolean>(false);

  const handleUnsubcribed = useCallback(async () => {
    const result = await dispatch(deleteUser());
    if (deleteUser.fulfilled.match(result)) {
      setOpen(true);
      resetImage();
    }
  }, [dispatch, resetImage]);

  return (
    <SPStepLayout
      hasStepBar={false}
      hasFooter={false}
      footerLogo
      hasModalSaveDraft={false}
      sx={{
        minHeight: 'calc(100vh - 158px)',
      }}
    >
      <Stack alignItems="center" sx={{ pt: 6 }}>
        <Stack
          alignItems="center"
          textAlign="center"
          sx={{
            bgcolor: 'main_white',
            border: (theme) => `1px solid ${theme?.palette?.sp_secondary_01}`,
            borderRadius: '8px',
            px: 4,
            pt: 4,
            pb: 3,
            minWidth: 240,
          }}
          spacing={3}
        >
          <Icons.SPWarning />
          <Typography
            variant="button_title"
            color="sp_secondary_01"
            sx={{
              whiteSpace: 'break-spaces',
              lineHeight: '20.8px',
              letterSpacing: '0.6px',
            }}
          >
            {`退会すると\nすべてのデータが削除され\n復元できません。`}
          </Typography>
        </Stack>
      </Stack>
      <Stack py={7} alignItems="center">
        <Typography
          variant="sp_change_password_title"
          color="sp_primary_100_main"
        >
          本当に退会しますか？
        </Typography>
      </Stack>
      <Stack sx={{ px: 6 }} spacing={6}>
        <SPButton
          sx={{ height: 54, bgcolor: 'sp_primary_100_main' }}
          endIcon={<Icons.ArrowForward stroke="white" />}
          onClick={() => navigate(routeNames.Top.path)}
        >
          <Typography variant="SP_multiple_checkbox_label" color="main_white">
            アカウントを維持する
          </Typography>
        </SPButton>
        <SPButton
          sx={{
            height: 54,
            bgcolor: 'main_white',
            border: '1px solid',
            borderColor: 'sp_primary_100_main',
          }}
          endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
          onClick={handleUnsubcribed}
        >
          <Typography
            variant="SP_multiple_checkbox_label"
            color="sp_primary_100_main"
          >
            退会する
          </Typography>
        </SPButton>
      </Stack>
      <SPModal
        open={open}
        onClose={() => {}}
        disableBackDropClick
        title="退会が完了しました"
        icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
        footer={
          <Stack alignItems="center">
            <SPButton
              sx={{
                height: 40,
                width: 160,
                bgcolor: 'sp_primary_40',
              }}
              endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
              onClick={() => {
                dispatch(resetAuth());
                navigate(routeNames.StartPage.path);
              }}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="sp_primary_100_main"
              >
                とじる
              </Typography>
            </SPButton>
          </Stack>
        }
      >
        <Typography
          variant="liststyle"
          color="b_333"
          sx={{
            textAlign: 'center',
            whiteSpace: 'break-spaces',
            letterSpacing: '0.6px',
            lineHeight: '27.2px',
          }}
        >
          {`ご利用ありがとうございました。\nまたのご利用をお待ちしております。`}
        </Typography>
      </SPModal>
    </SPStepLayout>
  );
};

export default SPUnsubcribedPage;
