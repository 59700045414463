import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { Button, Icons, InputField, SPInputField, SPModal } from 'components';
import { AdminLayout } from 'containers';
import { useAppDispatch, usePrevious } from 'hooks';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import {
  conversationIdSelector,
  conversationNameSelector,
  messagesDetailAdminSelectors,
  totalUnreadSelectors,
  userExistsSelector,
} from 'pages/ScreenMessages/selectors';
import { styleScrollBarModel, theme } from 'styles';
import { FormikProvider, useFormik } from 'formik';
import {
  createMessageManager,
  createMessageSalePerson,
  getMessageDetailManager,
  getMessageDetailSalePerson,
} from 'pages/ScreenMessages/thunk';
import { getParts } from 'utils';
import {
  validationSchema,
  validationLinkModalSchema,
} from './validationSchema';
import { AdminMessageButton } from './components/AdminMessageButton';
import { AdminMessage } from './components/AdminMessage';

const AdminMessageDetailPage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const bottomRef = useRef<HTMLDivElement>(null);
  const conversationId = useSelector(conversationIdSelector);
  const conversationName = useSelector(conversationNameSelector);
  const messages = useSelector(messagesDetailAdminSelectors.selectAll);
  const totalUnread = useSelector(totalUnreadSelectors);
  const userExists = useSelector(userExistsSelector);
  const [open, setOpen] = useState<boolean>(false);
  const [openLinkModal, setOpenLinkModal] = useState<boolean>(false);
  const [newMessageIndex, setNewMessageIndex] = useState<number>(0);
  const prevMessageCount = usePrevious(messages.length);

  const convertTextToHtmlWithLineBreaks = (text: string) => {
    let html = encodeHtmlEntities(text);
    return replaceNewlinesWithLineBreaks(html);
  };

  const convertLinkToMarkdownLink = (text: string) => {
    const regex = /\[.+\]\(.+\)/g;
    if (regex.test(text)) {
      return text;
    }
    return text
      .split(' ')
      .map((val) => {
        const regex = /\[.+\]\(.+\)/g;
        if (regex.test(val)) {
          return val;
        }
        const regexLink = /(https?:\/\/[^\s]+)/g;
        if (regexLink.test(val)) {
          const [link] = val.match(regexLink) ?? [];
          if (!link) return val;
          let domain = new URL(link);
          return val.replace(link, `[${domain.hostname}](${link})`);
        }
        return val;
      })
      .join(' ');
  };

  const encodeHtmlEntities = (text: string) => {
    let paragraph = document.createElement('span');
    paragraph.textContent = text;

    return paragraph.outerHTML;
  };

  const replaceNewlinesWithLineBreaks = (html: string) => {
    return formatLink(html.replace(/\r?\n/g, '<br>'));
  };

  const formatLink = (html: string) => {
    const links = getParts(html, '[', ')');

    let result = html;
    links.forEach((link) => {
      let reformat = `[${link})`;
      let shortenURL = getParts(reformat, '[', ']');
      let url = getParts(reformat, '(', ')');

      result = result.replace(
        reformat,
        `<a href='${url[0]}' target='_blank' rel='noopener noreferrer'>${shortenURL[0]}</a>`
      );
    });

    return result;
  };

  const formik = useFormik({
    initialValues: {
      message: '',
      displayText: '',
      url: '',
    },
    validationSchema: openLinkModal
      ? validationLinkModalSchema
      : validationSchema,
    async onSubmit(values, { setSubmitting, resetForm }) {
      if (openLinkModal) {
        handleInsertLink();
      } else {
        const data = new FormData();
        data.append(
          'content',
          convertTextToHtmlWithLineBreaks(
            convertLinkToMarkdownLink(values.message.trim())
          )
        );
        data.append('conversation_id', conversationId);

        const handleAfterSubmit = () => {
          setNewMessageIndex(0);
          setSubmitting(false);
          resetForm();
          setOpen(false);
          bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        };

        if (location.pathname === routeNames.ManagerMessageDetail.path) {
          const result = await dispatch(createMessageManager(data));
          if (createMessageManager.fulfilled.match(result)) {
            handleAfterSubmit();
          }
        } else {
          const result = await dispatch(createMessageSalePerson(data));
          if (createMessageSalePerson.fulfilled.match(result)) {
            handleAfterSubmit();
          }
        }
      }
    },
  });

  const { message, displayText, url } = formik.values;
  const handleInsertLink = useCallback(() => {
    if (!!displayText && !!url) {
      formik.setFieldValue('message', message + `[${displayText}](${url})`);
      formik.setFieldValue('displayText', '');
      formik.setFieldValue('url', '');
    }

    setOpenLinkModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayText, message, url]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!open || messages.length) {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [bottomRef, open, messages.length]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!!conversationId) {
        if (location.pathname.startsWith('/manager/', 0)) {
          dispatch(getMessageDetailManager(conversationId));
        } else {
          dispatch(getMessageDetailSalePerson(conversationId));
        }
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [conversationId, dispatch, location.pathname]);

  useEffect(() => {
    if (!newMessageIndex) {
      if (messages.length && !!totalUnread) {
        //Check case user at Top page and new message coming
        setNewMessageIndex(messages.length - totalUnread);
      }
      if (!!prevMessageCount && messages.length > prevMessageCount) {
        //Check case user at Chat screen and new message coming
        setNewMessageIndex(prevMessageCount);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, prevMessageCount, totalUnread]);

  return (
    <FormikProvider value={formik}>
      <AdminLayout
        hasDrawer={false}
        header={{
          left: {
            mainTitle: (
              <Stack direction="row" spacing={2}>
                <Link
                  component="a"
                  variant="drawerButtonText"
                  color="h_blugreen"
                  sx={{
                    textDecoration: 'none',
                    whiteSpace: 'pre',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (
                      location.pathname === routeNames.ManagerMessageDetail.path
                    ) {
                      navigate(routeNames.ManagerMessages.path);
                    } else {
                      navigate(routeNames.SalePersonMessages.path);
                    }
                  }}
                >
                  お客様とのメッセージ一覧
                </Link>
                <Icons.ArrowRight />
                <Typography
                  variant="button_title"
                  color="b_333"
                  whiteSpace="nowrap"
                >
                  {conversationName} 様
                </Typography>
              </Stack>
            ),
            colorTitle: 'b_333',
            buttonText: `${
              location.pathname === routeNames.ManagerMessages.path
                ? '新規作成'
                : ''
            }`,
          },
          right: { Icons: true, userName: '' },
          showIconMail: false,
        }}
        footer={{
          left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
          right: (
            <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />
          ),
          bgcolor: 'footer_bg',
        }}
      >
        <Stack direction="row" sx={{ height: 'calc(100vh - 72px)' }}>
          <Stack
            flex={1}
            sx={{
              py: 4,
              pl: 6,
              pr: 5,
            }}
          >
            <Stack
              sx={{
                height: '100%',
                bgcolor: 'main_white',
                borderRadius: '40px',
                boxShadow: '0px 0px 10px rgba(44, 54, 156, 0.1)',
                pr: '10px',
              }}
            >
              <Stack
                sx={{
                  pt: 12,
                  pb: '90px',
                  overflowY: 'auto',
                  ...styleScrollBarModel,
                }}
                spacing={3}
              >
                {messages.map((message, index) => {
                  if (index === newMessageIndex && !!index)
                    return (
                      <Fragment key={index}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ px: 2, pt: '14px' }}
                        >
                          <Stack
                            sx={{
                              width: '100%',
                              height: 0,
                              border: '1px dashed',
                              borderColor: 'sp_secondary_01',
                            }}
                          />

                          <Typography
                            variant="sp_12_bold_100"
                            color="sp_secondary_01"
                            sx={{ whiteSpace: 'nowrap', px: 3 }}
                          >
                            ここから未読メッセージ
                          </Typography>

                          <Stack
                            sx={{
                              width: '100%',
                              height: 0,
                              border: '1px dashed',
                              borderColor: 'sp_secondary_01',
                            }}
                          />
                        </Stack>
                        <AdminMessage
                          key={index}
                          id={message.id}
                          senderType={message.sender_type}
                          senderName={message.sender_name}
                          createdAt={message.created_at}
                          content={message.content}
                          avatar={message.avatar_company.url}
                          messageFile={message.message_file}
                        />
                      </Fragment>
                    );
                  return (
                    <AdminMessage
                      key={index}
                      id={message.id}
                      senderType={message.sender_type}
                      senderName={message.sender_name}
                      createdAt={message.created_at}
                      content={message.content}
                      avatar={message.avatar_company.url}
                      messageFile={message.message_file}
                    />
                  );
                })}
                <Stack ref={bottomRef} />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            sx={{
              py: 4,
              pr: 6,
            }}
          >
            <Stack
              sx={{
                height: '70%',
                minHeight: '350px',
                borderRadius: 1,
                border: '1px solid ',
                borderColor: 'sp_primary_40',
                boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
                bgcolor: 'main_white',
              }}
            >
              {/* message text */}
              <Stack flex={1}>
                <InputField
                  autoTrim={false}
                  multiline
                  name="message"
                  placeholder="メッセージを入力してください。"
                  rows={8}
                  readOnly={!userExists}
                  sx={{
                    overflowY: 'auto',
                    '.MuiInputBase-input': {
                      color: 'b_333',
                      border: 'none',
                      bgcolor: 'main_white',
                      boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
                      p: 4,
                      maxHeight: '200px',
                      overflowY: 'auto',
                    },
                    '&&&& .Mui-focused': {
                      '.MuiInputBase-input': {
                        border: 'none',
                      },
                      fieldset: {
                        border: 'none',
                        boxShadow: 'none',
                      },
                    },
                    '&&&& .Mui-success': {
                      '.MuiInputBase-input': {
                        border: 'none',
                      },
                      fieldset: {
                        border: 'none',
                        boxShadow: 'none',
                      },
                    },
                    '&&&& fieldset': {
                      border: 'none',
                      overflowY: 'auto',
                      boxShadow: 'none',
                    },
                  }}
                  InputProps={{
                    sx: {
                      'textarea::placeholder': {
                        color: 'bg_radio_avatar',
                        ...theme.typography.text_placeholder,
                        fontSize: 16,
                      },

                      '&& .MuiInputBase-input': {
                        ...theme.typography.text_placeholder,
                        fontSize: 16,
                        width: '100%',
                        height: '100%',
                        overflowY: 'auto',
                      },
                      p: 0,
                    },
                  }}
                />
              </Stack>
              {/* list image */}
              <Stack
                spacing={1}
                sx={{ overflowY: 'auto', mimHeight: '130px' }}
              />
              <Stack
                direction="row"
                alignItems="flex-end"
                sx={{ pl: 4, pb: 4 }}
                spacing="10px"
              >
                <AdminMessageButton
                  icon={<Icons.Link />}
                  title="リンクを挿入"
                  disabled={!userExists}
                  onClick={() => setOpenLinkModal(true)}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{ pt: '10px' }}
            >
              <Button
                sx={{ width: 148, height: 36, bgcolor: 'sp_primary_100_main' }}
                onClick={() => setOpen(true)}
                disabled={!formik.values.message.trim() || !userExists}
              >
                <Typography variant="headline_input">送信する</Typography>
              </Button>
            </Stack>
          </Stack>
          <SPModal
            open={open}
            onClose={() => setOpen(false)}
            title={`メッセージを送信します\nよろしいですか？`}
            disableBackDropClick
            sx={{
              minWidth: 265,
            }}
            sxChildren={{ overflowY: 'auto' }}
            footer={
              <Stack spacing={6} alignItems="center">
                <Button
                  sx={{
                    width: 148,
                    height: 40,
                    bgcolor: 'sp_primary_100_main',
                    borderRadius: '2px',
                  }}
                  disabled={formik.isSubmitting}
                  onClick={() => formik.handleSubmit()}
                >
                  <Typography
                    variant="SP_multiple_checkbox_label"
                    color="main_white"
                  >
                    送信する
                  </Typography>
                </Button>
                <Button
                  sx={{
                    width: 148,
                    height: 40,
                    bgcolor: 'main_white',
                    border: '1px solid',
                    borderColor: 'sp_primary_100_main',
                    borderRadius: '2px',
                  }}
                  onClick={() => setOpen(false)}
                >
                  <Typography
                    variant="SP_multiple_checkbox_label"
                    color="sp_primary_100_main"
                  >
                    キャンセル
                  </Typography>
                </Button>
              </Stack>
            }
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={3}
              sx={{
                p: '12px 18px 12px 18px',
                bgcolor: 'sp_secondary_pink_light',
                border: (theme) =>
                  `1px solid ${theme?.palette?.sp_secondary_01}`,
                borderRadius: '8px',
              }}
            >
              <Icons.SPWarning />
              <Typography
                variant="drawerText"
                color="sp_secondary_01"
                sx={{ whiteSpace: 'break-spaces' }}
              >
                {`宛先は確認しましたか？\n個人情報漏洩にご注意ください。`}
              </Typography>
            </Stack>
          </SPModal>
          <SPModal
            open={openLinkModal}
            onClose={() => setOpenLinkModal(false)}
            title="リンクを挿入"
            disableBackDropClick
            sx={{
              minWidth: 343,
            }}
            sxChildren={{ overflowY: 'auto' }}
            footer={
              <Stack spacing={6} alignItems="center">
                <Button
                  sx={{
                    width: 148,
                    height: 40,
                    bgcolor: 'sp_primary_100_main',
                    borderRadius: '2px',
                  }}
                  onClick={() => formik.handleSubmit()}
                >
                  <Typography
                    variant="SP_multiple_checkbox_label"
                    color="main_white"
                  >
                    OK
                  </Typography>
                </Button>
                <Button
                  sx={{
                    width: 148,
                    height: 40,
                    bgcolor: 'main_white',
                    border: '1px solid',
                    borderColor: 'sp_primary_100_main',
                    borderRadius: '2px',
                  }}
                  onClick={() => {
                    formik.setFieldValue('displayText', '');
                    formik.setFieldValue('url', '');
                    formik.setFieldTouched('displayText', false, false);
                    formik.setFieldTouched('url', false, false);
                    setOpenLinkModal(false);
                  }}
                >
                  <Typography
                    variant="SP_multiple_checkbox_label"
                    color="sp_primary_100_main"
                  >
                    キャンセル
                  </Typography>
                </Button>
              </Stack>
            }
          >
            <Stack spacing={6}>
              <Stack spacing={2}>
                <Typography variant="SP_form_item_label" color="b_333">
                  表示するテキスト
                </Typography>
                <SPInputField
                  name="displayText"
                  placeholder="入力してください"
                />
              </Stack>
              <Stack spacing={2}>
                <Typography variant="SP_form_item_label" color="b_333">
                  リンク先に指定するURL
                </Typography>
                <SPInputField
                  name="url"
                  placeholder="http://xxxxxx.co.jp/sample"
                  type="url"
                />
              </Stack>
            </Stack>
          </SPModal>
        </Stack>
      </AdminLayout>
    </FormikProvider>
  );
};
export default AdminMessageDetailPage;
