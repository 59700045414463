import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPArrowRight: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="16"
          height="16"
          transform="translate(16.5 16.5) rotate(180)"
          fill="white"
        />
        <circle
          cx="8.5"
          cy="8.5"
          r="8"
          transform="rotate(-90 8.5 8.5)"
          fill="#E4E7FF"
        />
        <path
          d="M7.16406 5.16602L10.4974 8.49935L7.16406 11.8327"
          stroke="#6B70F0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
