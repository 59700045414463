import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPPdfLite: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 24" {...props}>
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.125 12.375C5.36719 12.375 6.375 13.3828 6.375 14.625C6.375 15.8672 5.36719 16.875 4.125 16.875H3.375V18.75C3.375 18.9562 3.20719 19.125 3 19.125C2.79281 19.125 2.625 18.9562 2.625 18.75V12.75C2.625 12.5437 2.79281 12.375 3 12.375H4.125ZM5.625 14.625C5.625 13.7953 4.95469 13.125 4.125 13.125H3.375V16.125H4.125C4.95469 16.125 5.625 15.4547 5.625 14.625ZM7.875 12.75C7.875 12.5437 8.04375 12.375 8.25 12.375H9.375C10.4109 12.375 11.25 13.2141 11.25 14.25V17.25C11.25 18.2859 10.4109 19.125 9.375 19.125H8.25C8.04375 19.125 7.875 18.9562 7.875 18.75V12.75ZM8.625 13.125V18.375H9.375C9.99844 18.375 10.5 17.8734 10.5 17.25V14.25C10.5 13.6266 9.99844 13.125 9.375 13.125H8.625ZM15.75 12.375C15.9563 12.375 16.125 12.5437 16.125 12.75C16.125 12.9563 15.9563 13.125 15.75 13.125H13.875V15.375H15.75C15.9563 15.375 16.125 15.5437 16.125 15.75C16.125 15.9563 15.9563 16.125 15.75 16.125H13.875V18.75C13.875 18.9562 13.7063 19.125 13.5 19.125C13.2937 19.125 13.125 18.9562 13.125 18.75V12.75C13.125 12.5437 13.2937 12.375 13.5 12.375H15.75ZM9.56719 0C10.1625 0 10.7391 0.237047 11.1609 0.659063L17.3391 6.83906C17.7609 7.26094 18 7.8375 18 8.43281V21C18 22.6547 16.6547 24 15 24H3C1.34297 24 0 22.6547 0 21V3C0 1.34297 1.34297 0 3 0H9.56719ZM3 23.25H15C16.2422 23.25 17.25 22.2422 17.25 21V8.43281C17.25 8.37187 17.2453 8.31094 17.2406 8.25H11.625C10.5891 8.25 9.75 7.41094 9.75 6.375V0.76125C9.68906 0.75375 9.62813 0.75 9.56719 0.75H3C1.75734 0.75 0.75 1.75734 0.75 3V21C0.75 22.2422 1.75734 23.25 3 23.25ZM10.6266 1.18922C10.5891 1.14844 10.5047 1.11 10.5 1.07484V6.375C10.5 6.99844 11.0016 7.5 11.625 7.5H16.9266C16.8891 7.45312 16.8516 7.41094 16.8094 7.37344L10.6266 1.18922Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
