import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {
  applicationsService,
  AssignManagerError,
  AssignManagerRequest,
  AssignManagerResponse,
  ManagerCurrentPreliminariesItemResponse,
  ManagerCurrentPreliminariesItemRequest,
  UpdateApplicationStatusRequest,
  UpdateApplicationStatusResponse,
  LinkPairLoanError,
  LinkPairLoanRequest,
  LinkUnLinkPairLoanResponse,
  UnLinkPairLoanRequest,
  ManagerAssignSalePersonRequest,
  ManagerAssignSalePersonResponse,
  ManagerAssignSalePersonError,
  ManagerAssignStoreNameResponse,
  ManagerAssignStoreNameRequest,
  ManagerAssignStoreNameError,
} from 'services';
import { RootState, ThunkAPI } from 'types';
import * as FileSaver from 'file-saver';

export const getCurrentPreliminariesItems = createAsyncThunk<
  ManagerCurrentPreliminariesItemResponse,
  ManagerCurrentPreliminariesItemRequest
>('Preliminaries/GetPreliminaries', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.getCurrentPreliminariesItems(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sortCurrentPreliminariesItems = createAsyncThunk<
  ManagerCurrentPreliminariesItemResponse,
  ManagerCurrentPreliminariesItemRequest
>('Preliminaries/GetPreliminaries', async (data, { rejectWithValue }) => {
  try {
    const { data: response } =
      await applicationsService.getCurrentPreliminariesItems(data);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const assignSalePersons = createAsyncThunk<
  ManagerAssignSalePersonResponse,
  ManagerAssignSalePersonRequest,
  ThunkAPI<ManagerAssignSalePersonError>
>('Preliminaries/assignSalePerson', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.managerAssignSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const assignStoreName = createAsyncThunk<
  ManagerAssignStoreNameResponse,
  ManagerAssignStoreNameRequest,
  ThunkAPI<ManagerAssignStoreNameError>
>('Preliminaries/assignStoreName', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.managerAssignStoreName(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getExportExcel = createAsyncThunk<
  BlobPart,
  ManagerCurrentPreliminariesItemRequest
>('ExportExcel/ExportExcel', async (data, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    if (!state.auth.accessToken) {
      return rejectWithValue(new Error('Not logged in'));
    }
    const response = await applicationsService.getExportExcel(
      data,
      state.auth.accessToken
    );
    await FileSaver.saveAs(
      await response.blob(),
      `${dayjs().format('YYYYMMDDhhmmss')}_管理画面をエクスポート.xlsx`
    );
    return response.blob();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const assignManager = createAsyncThunk<
  AssignManagerResponse,
  AssignManagerRequest,
  ThunkAPI<AssignManagerError>
>('Preliminaries/assignManager', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.assignManager(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateApplicationStatus = createAsyncThunk<
  UpdateApplicationStatusResponse,
  UpdateApplicationStatusRequest,
  ThunkAPI<AssignManagerError>
>(
  'Preliminaries/updateApplicationStatus',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.updateApplicationStatus(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const linkPairLoan = createAsyncThunk<
  LinkUnLinkPairLoanResponse,
  LinkPairLoanRequest,
  ThunkAPI<LinkPairLoanError>
>('Preliminaries/linkPairLoan', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.linkPairLoan(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const unlinkPairLoan = createAsyncThunk<
  LinkUnLinkPairLoanResponse,
  UnLinkPairLoanRequest,
  ThunkAPI<LinkPairLoanError>
>('Preliminaries/unlinkPairLoan', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.unlinkPairLoan(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
