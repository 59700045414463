import { IdentityVerificationType } from 'constant';
import { SPStepSevenIncomeTotalizerForm } from 'types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'containers/AuthModal/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { convertImageResponse } from 'utils';
import { deleteUser } from '../SPUnsubcribed/thunk';

export type InitialState = {
  form: SPStepSevenIncomeTotalizerForm;
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      p_applicant_people_attributes: [
        {
          identity_verification: IdentityVerificationType.DEFAULT,
          insurance_file: [],
          insurance_file_back_image: [],
          financial_statement_1_file: [],
          financial_statement_2_file: [],
          financial_statement_3_file: [],
          first_withholding_slip_file: [],
          second_withholding_slip_file: [],
          other_document_file: [],
          employment_agreement_file: [],
          business_tax_return_1_file: [],
          business_tax_return_2_file: [],
          business_tax_return_3_file: [],
          first_income_file: [],
          second_income_file: [],
          third_income_file: [],
          driver_license_front_image: [],
          driver_license_back_image: [],
          card_number_front_image: [],
          // card_number_back_image: [],
          resident_register_front_image: [],
          resident_register_back_image: [],
        },
      ],
    },
  },
};

const stepSevenIncomeTotalizerSlice = createSlice({
  name: 'stepSevenIncomeTotal',
  initialState,
  reducers: {
    saveForm: (
      state,
      action: PayloadAction<SPStepSevenIncomeTotalizerForm>
    ) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    saveSecondWithholdingSlipFile2: (state, action) => {
      state.form.p_application_header.p_applicant_people_attributes[0].second_withholding_slip_file =
        state.form.p_application_header.p_applicant_people_attributes[0].second_withholding_slip_file.map(
          (item: {
            id: string;
            filename: string;
            url: string;
            hiden: boolean;
            isDelete: boolean;
          }) => {
            return {
              ...item,
              isDelete: true,
            };
          }
        );
    },
    resetStepSevenIncomeForm: (state) => {
      state.form = initialState.form;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_applicant_people } = action.payload.data;
      if (!!p_applicant_people[1]) {
        const {
          identity_verification,
          insurance_file,
          insurance_file_back_image,
          financial_statement_1_file,
          financial_statement_2_file,
          financial_statement_3_file,
          first_withholding_slip_file,
          second_withholding_slip_file,
          other_document_file,
          employment_agreement_file,
          business_tax_return_1_file,
          business_tax_return_2_file,
          business_tax_return_3_file,
          first_income_file,
          second_income_file,
          third_income_file,
          driver_license_front_image,
          driver_license_back_image,
          card_number_front_image,
          // card_number_back_image,
          resident_register_front_image,
          resident_register_back_image,
        } = p_applicant_people[1];
        state.form.p_application_header.p_applicant_people_attributes = [
          {
            ...state.form.p_application_header.p_applicant_people_attributes[0],
            identity_verification,
            ...(!!insurance_file ? convertImageResponse(insurance_file) : []),
            ...(!!insurance_file_back_image
              ? convertImageResponse(insurance_file_back_image)
              : []),
            ...(!!financial_statement_1_file
              ? convertImageResponse(financial_statement_1_file)
              : []),
            ...(!!financial_statement_2_file
              ? convertImageResponse(financial_statement_2_file)
              : []),
            ...(!!financial_statement_3_file
              ? convertImageResponse(financial_statement_3_file)
              : []),
            ...(!!first_withholding_slip_file
              ? convertImageResponse(first_withholding_slip_file)
              : []),
            ...(!!second_withholding_slip_file
              ? convertImageResponse(second_withholding_slip_file)
              : []),
            ...(!!other_document_file
              ? convertImageResponse(other_document_file)
              : []),
            ...(!!employment_agreement_file
              ? convertImageResponse(employment_agreement_file)
              : []),
            ...(!!business_tax_return_1_file
              ? convertImageResponse(business_tax_return_1_file)
              : []),
            ...(!!business_tax_return_2_file
              ? convertImageResponse(business_tax_return_2_file)
              : []),
            ...(!!business_tax_return_3_file
              ? convertImageResponse(business_tax_return_3_file)
              : []),
            ...(!!first_income_file
              ? convertImageResponse(first_income_file)
              : []),
            ...(!!second_income_file
              ? convertImageResponse(second_income_file)
              : []),
            ...(!!third_income_file
              ? convertImageResponse(third_income_file)
              : []),
            driver_license_front_image: !!driver_license_front_image
              ? convertImageResponse([driver_license_front_image])
              : [],
            driver_license_back_image: !!driver_license_back_image
              ? convertImageResponse([driver_license_back_image])
              : [],
            card_number_front_image: !!card_number_front_image
              ? convertImageResponse([card_number_front_image])
              : [],
            // card_number_back_image: !!card_number_back_image
            //   ? convertImageResponse([card_number_back_image])
            //   : [],
            resident_register_front_image: !!resident_register_front_image
              ? convertImageResponse([resident_register_front_image])
              : [],
            resident_register_back_image: !!resident_register_back_image
              ? convertImageResponse([resident_register_back_image])
              : [],
          },
        ];
      }
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const { p_applicant_people_attributes } = action.payload.data;
      if (!!p_applicant_people_attributes[1]) {
        const { identity_verification, personal_file_upload } =
          p_applicant_people_attributes[1];
        const {
          insurance_file,
          insurance_file_back_image,
          financial_statement_1_file,
          financial_statement_2_file,
          financial_statement_3_file,
          first_withholding_slip_file,
          second_withholding_slip_file,
          other_document_file,
          employment_agreement_file,
          business_tax_return_1_file,
          business_tax_return_2_file,
          business_tax_return_3_file,
          first_income_file,
          second_income_file,
          third_income_file,
          driver_license_front_image,
          driver_license_back_image,
          card_number_front_image,
          // card_number_back_image,
          resident_register_front_image,
          resident_register_back_image,
        } = personal_file_upload;
        state.form.p_application_header.p_applicant_people_attributes = [
          {
            ...state.form.p_application_header.p_applicant_people_attributes[0],
            identity_verification,
            insurance_file: convertImageResponse(insurance_file),
            insurance_file_back_image: convertImageResponse(
              insurance_file_back_image
            ),
            financial_statement_1_file: convertImageResponse(
              financial_statement_1_file
            ),
            financial_statement_2_file: convertImageResponse(
              financial_statement_2_file
            ),
            financial_statement_3_file: convertImageResponse(
              financial_statement_3_file
            ),
            first_withholding_slip_file: convertImageResponse(
              first_withholding_slip_file
            ),
            second_withholding_slip_file: convertImageResponse(
              second_withholding_slip_file
            ),
            other_document_file: convertImageResponse(other_document_file),
            employment_agreement_file: convertImageResponse(
              employment_agreement_file
            ),
            business_tax_return_1_file: convertImageResponse(
              business_tax_return_1_file
            ),
            business_tax_return_2_file: convertImageResponse(
              business_tax_return_2_file
            ),
            business_tax_return_3_file: convertImageResponse(
              business_tax_return_3_file
            ),
            first_income_file: convertImageResponse(first_income_file),
            second_income_file: convertImageResponse(second_income_file),
            third_income_file: convertImageResponse(third_income_file),
            driver_license_front_image: convertImageResponse(
              driver_license_front_image
            ),
            driver_license_back_image: convertImageResponse(
              driver_license_back_image
            ),
            card_number_front_image: convertImageResponse(
              card_number_front_image
            ),
            // card_number_back_image: convertImageResponse(
            //   card_number_back_image
            // ),
            resident_register_front_image: convertImageResponse(
              resident_register_front_image
            ),
            resident_register_back_image: convertImageResponse(
              resident_register_back_image
            ),
          },
        ];
      } else {
        state.form = initialState.form;
      }
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const { p_applicant_people_attributes } = action.payload.data;
      if (!!p_applicant_people_attributes[1]) {
        const { identity_verification, personal_file_upload } =
          p_applicant_people_attributes[1];
        const {
          insurance_file,
          insurance_file_back_image,
          financial_statement_1_file,
          financial_statement_2_file,
          financial_statement_3_file,
          first_withholding_slip_file,
          second_withholding_slip_file,
          other_document_file,
          employment_agreement_file,
          business_tax_return_1_file,
          business_tax_return_2_file,
          business_tax_return_3_file,
          first_income_file,
          second_income_file,
          third_income_file,
          driver_license_front_image,
          driver_license_back_image,
          card_number_front_image,
          // card_number_back_image,
          resident_register_front_image,
          resident_register_back_image,
        } = personal_file_upload;
        state.form.p_application_header.p_applicant_people_attributes = [
          {
            ...state.form.p_application_header.p_applicant_people_attributes[0],
            identity_verification,
            insurance_file: convertImageResponse(insurance_file),
            insurance_file_back_image: convertImageResponse(
              insurance_file_back_image
            ),
            financial_statement_1_file: convertImageResponse(
              financial_statement_1_file
            ),
            financial_statement_2_file: convertImageResponse(
              financial_statement_2_file
            ),
            financial_statement_3_file: convertImageResponse(
              financial_statement_3_file
            ),
            first_withholding_slip_file: convertImageResponse(
              first_withholding_slip_file
            ),
            second_withholding_slip_file: convertImageResponse(
              second_withholding_slip_file
            ),
            other_document_file: convertImageResponse(other_document_file),
            employment_agreement_file: convertImageResponse(
              employment_agreement_file
            ),
            business_tax_return_1_file: convertImageResponse(
              business_tax_return_1_file
            ),
            business_tax_return_2_file: convertImageResponse(
              business_tax_return_2_file
            ),
            business_tax_return_3_file: convertImageResponse(
              business_tax_return_3_file
            ),
            first_income_file: convertImageResponse(first_income_file),
            second_income_file: convertImageResponse(second_income_file),
            third_income_file: convertImageResponse(third_income_file),
            driver_license_front_image: convertImageResponse(
              driver_license_front_image
            ),
            driver_license_back_image: convertImageResponse(
              driver_license_back_image
            ),
            card_number_front_image: convertImageResponse(
              card_number_front_image
            ),
            // card_number_back_image: convertImageResponse(
            //   card_number_back_image
            // ),
            resident_register_front_image: convertImageResponse(
              resident_register_front_image
            ),
            resident_register_back_image: convertImageResponse(
              resident_register_back_image
            ),
          },
        ];
      } else {
        state.form = initialState.form;
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const {
  saveForm,
  resetStepSevenIncomeForm,
  saveSecondWithholdingSlipFile2,
} = stepSevenIncomeTotalizerSlice.actions;

export default stepSevenIncomeTotalizerSlice.reducer;
