import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  ReviewStatusError,
  ReviewStatusRequest,
  ReviewStatusResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const getReviewStatus = createAsyncThunk<
  ReviewStatusResponse,
  ReviewStatusRequest,
  ThunkAPI<ReviewStatusError>
>('ReviewStatus/getReviewStatus', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getReviewStatus(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
