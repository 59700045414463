import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Delete: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 25 18" {...props}>
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 1H10C9.26638 1 4.49631 5.30556 2.00703 7.59941C1.1773 8.364 1.15855 9.6561 1.9699 10.4402C4.35342 12.7436 8.90587 17 10 17H21C22.6 17 23.3333 15.3333 23.5 14.5V3.5C23.5 1.9 21.8333 1.16667 21 1Z"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M18.5 5L11 12.5" stroke="white" strokeWidth="2" />
      <path d="M18.5 12.5L11 5" stroke="white" strokeWidth="2" />
    </svg>
  </SvgIcon>
);
