import { Chip, Stack, Typography } from '@mui/material';
import {
  SPFormItem,
  SPImage,
  SPInputField,
  SPSaveDraftModal,
  SPSaveImageModal,
} from 'components';
import {
  BorrowerIncomeType,
  GeneralIncomeConfirmation,
  LoanType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PurposeType,
} from 'constant';
import { SPStepLayout } from 'containers';
import { spCurrentStepSelector } from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import { FormikProvider, getIn, useFormik } from 'formik';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import { get, isEmpty, update } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spStepFiveSelector } from 'pages/SPStepFive/selectors';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SPStepSixForm as SPStepSixFormType } from 'types';
import {
  checkingExistFile,
  convertManToYen,
  flattenToLodashFormat,
  numberWithCommas,
  supportDvh,
} from 'utils';
import { spStepEightSelector } from '../SPStepEight/selectors';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneSelector,
} from '../SPStepOne/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import { spStepTwoSelector } from '../SPStepTwo/selectors';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { convertStepSixSelector } from './selectors';
import { saveForm } from './slice';
import { validationSchema } from './validationSchema';

const SPStepSixPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const spStepOneValues = useSelector(spStepOneSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const currentStep = useSelector(spCurrentStepSelector);
  const initialValues = useSelector(convertStepSixSelector);
  const { p_application_header } = useSelector(spStepOneSelector);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const { updateFormOverride } = useUpdateForm();

  const {
    residenceFile,
    residenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    repaymentScheduleImage,
    businessCard,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [open, setOpen] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const { application_number } = useSelector(spStepTenSelector);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    async onSubmit(values, { setSubmitting }) {
      const submitForm = {
        p_application_header: convertCurrency({ p_application_header: values }),
      };
      await dispatch(saveForm(submitForm));
      if (incomeTotalizer && +currentStep < 9) {
        dispatch(setCurrentStep('9'));
      }
      if (incomeTotalizer && hasJoinGuarantor && +currentStep < 10) {
        dispatch(setCurrentStep('10'));
      }
      if (hasJoinGuarantor && +currentStep < 8) {
        dispatch(setCurrentStep('8'));
      }
      if (+currentStep < 7) dispatch(setCurrentStep('7'));
      setSubmitting(false);
      if (!!application_number) {
        setIsDisabledButton(true);
        updateFormOverride(
          {
            stepSixData: submitForm,
            imageContext: imageContext,
          },
          setIsDisabledButton,
          setOpenImageModal
        );
      } else {
        navigate(routeNames.SPStepSeven.path);
      }
    },
  });

  const btnSubmit = useMemo(() => {
    return !!application_number ? '保存' : '次へ';
  }, [application_number]);

  const convertCurrency = useCallback((values: SPStepSixFormType) => {
    const [
      house_purchase_price,
      additional_cost,
      require_funds_breakdown_mortgage,
      land_purchase_price,
      accessory_cost,
      refinancing_loan_balance,
      house_upgrade_cost,
      deposit_savings_1,
      real_estate_sale_price,
      other_saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
    ] = convertManToYen([
      values.p_application_header.house_purchase_price,
      values.p_application_header.additional_cost,
      !spStepOneValues.p_application_header.is_home_loan_plus
        ? '0'
        : values.p_application_header.require_funds_breakdown_mortgage,
      values.p_application_header.land_purchase_price,
      values.p_application_header.accessory_cost,
      values.p_application_header.refinancing_loan_balance,
      values.p_application_header.house_upgrade_cost,
      values.p_application_header.deposit_savings_1,
      values.p_application_header.real_estate_sale_price,
      values.p_application_header.other_saving_amount,
      values.p_application_header.relative_donation_amount,
      values.p_application_header.loan_amount,
      values.p_application_header.pair_loan_amount,
      values.p_application_header.other_procurement_breakdown,
    ]);

    return {
      house_purchase_price,
      additional_cost,
      require_funds_breakdown_mortgage,
      land_purchase_price,
      accessory_cost,
      refinancing_loan_balance,
      house_upgrade_cost,
      deposit_savings_1,
      real_estate_sale_price,
      other_saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
      other_procurement_breakdown_content:
        values.p_application_header.other_procurement_breakdown_content,
    };
  }, []);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  const checkStepTitle = useMemo(() => {
    if (hasJoinGuarantor && incomeTotalizer) return 'STEP07';
    if (hasJoinGuarantor) return 'STEP05';
    if (incomeTotalizer) return 'STEP06';
    return 'STEP04';
  }, [hasJoinGuarantor, incomeTotalizer]);

  const getHousePurchasePriceTitle = () => {
    switch (p_application_header.loan_target) {
      case PurposeType.ONE:
      case PurposeType.TWO:
        return '物件価格';
      case PurposeType.THREE:
      case PurposeType.FOUR:
        return 'マンション価格';
      case PurposeType.FIVE:
      case PurposeType.SIX:
        return '建物';
      default:
        return '';
    }
  };

  const getRequiredFunds = useCallback(() => {
    switch (p_application_header.loan_target) {
      case PurposeType.ONE:
      case PurposeType.TWO:
      case PurposeType.THREE:
      case PurposeType.FOUR:
        return (
          +formik.values.house_purchase_price +
          +formik.values.additional_cost +
          +(!!spStepOneValues.p_application_header.is_home_loan_plus
            ? formik.values.require_funds_breakdown_mortgage
            : 0)
        );
      case PurposeType.FIVE:
        return (
          +formik.values.house_purchase_price +
          +formik.values.additional_cost +
          +(!!spStepOneValues.p_application_header.is_home_loan_plus
            ? formik.values.require_funds_breakdown_mortgage
            : 0) +
          +formik.values.accessory_cost
        );
      case PurposeType.SIX:
        return (
          +formik.values.house_purchase_price +
          +formik.values.additional_cost +
          +(!!spStepOneValues.p_application_header.is_home_loan_plus
            ? formik.values.require_funds_breakdown_mortgage
            : 0) +
          +formik.values.land_purchase_price +
          +formik.values.accessory_cost
        );
      case PurposeType.SEVEN:
        return (
          +formik.values.additional_cost +
          +(!!spStepOneValues.p_application_header.is_home_loan_plus
            ? formik.values.require_funds_breakdown_mortgage
            : 0) +
          +formik.values.refinancing_loan_balance
        );
      case PurposeType.EIGHT:
        return (
          +formik.values.additional_cost +
          +(!!spStepOneValues.p_application_header.is_home_loan_plus
            ? formik.values.require_funds_breakdown_mortgage
            : 0) +
          +formik.values.refinancing_loan_balance +
          +formik.values.house_upgrade_cost
        );
      default:
        //loan_target === '' [HOMELOAN-2355]
        return +formik.values.additional_cost;
    }
  }, [
    formik.values.accessory_cost,
    formik.values.additional_cost,
    formik.values.house_purchase_price,
    formik.values.house_upgrade_cost,
    formik.values.land_purchase_price,
    formik.values.refinancing_loan_balance,
    formik.values.require_funds_breakdown_mortgage,
    p_application_header.loan_target,
    spStepOneValues.p_application_header.is_home_loan_plus,
  ]);

  const getRaisedFunds = useCallback(() => {
    return (
      +formik.values.deposit_savings_1 +
      +formik.values.real_estate_sale_price +
      +formik.values.other_saving_amount +
      +formik.values.relative_donation_amount +
      +formik.values.loan_amount +
      (p_application_header.loan_type === LoanType.TWO
        ? +formik.values.pair_loan_amount
        : 0) +
      +formik.values.other_procurement_breakdown
    );
  }, [
    formik.values.deposit_savings_1,
    formik.values.loan_amount,
    formik.values.other_procurement_breakdown,
    formik.values.other_saving_amount,
    formik.values.pair_loan_amount,
    formik.values.real_estate_sale_price,
    formik.values.relative_donation_amount,
    p_application_header.loan_type,
  ]);

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);

    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });

    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });

    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/
    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(
      convertCurrency({ p_application_header: formik.values })
    ).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(convertCurrency({ p_application_header: formik.values }), key) ??
          ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    return;
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={async () => {
          const submitForm = {
            p_application_header: convertCurrency({
              p_application_header: formik.values,
            }),
          };
          await dispatch(saveForm(submitForm));
        }}
        footer={{
          left: {
            onClick: async () => {
              const submitForm = {
                p_application_header: convertCurrency({
                  p_application_header: formik.values,
                }),
              };
              await dispatch(saveForm(submitForm));
              if (!!application_number) {
                navigate(-1);
              } else {
                navigate(routeNames.SPStepFive.path);
              }
            },
          },
          right: {
            children: btnSubmit,
            onClick: () => {
              formik.handleSubmit();
            },
            disabled: isDisabledButton,
          },
        }}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        onSaveDraft={onSaveDraft}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack
            sx={{ pt: 9, pb: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >{`資金計画について\n教えてください。`}</Typography>
          </Stack>
          <Stack
            justifyContent="center"
            sx={{ pb: 4 }}
            direction="row"
            spacing={1}
          >
            <Typography variant="annotation01" color="b_333">
              ※
            </Typography>
            <Stack>
              <Typography
                variant="annotation01"
                color="b_333"
                sx={{
                  whiteSpace: 'break-spaces',
                  textAlign: 'left',
                }}
              >
                必要資金の項目は
                <Chip
                  label={
                    <Typography
                      variant="SP_required_chip_label"
                      color="main_white"
                    >
                      任意
                    </Typography>
                  }
                  sx={{
                    bgcolor: 'sp_gray_250',
                    borderRadius: 1,
                    height: 14,
                    width: 29,
                    p: 0,
                    mt: -1,
                    mx: 1,
                    texAlign: 'center',
                    '.MuiChip-label': { p: '1px 3px' },
                  }}
                />
                です。未入力の場合は、みらいバンク
              </Typography>
              <Typography
                variant="annotation01"
                color="b_333"
                sx={{
                  whiteSpace: 'break-spaces',
                  textAlign: 'left',
                }}
              >
                にて補記いたしますのでご安心ください。
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <SPStepSixForm
          left={[
            p_application_header.loan_target === PurposeType.SIX
              ? {
                  label: '土地',
                  input: (
                    <SPInputField
                      align="right"
                      name="land_purchase_price"
                      unit="万円"
                      placeholder="0"
                      numeric
                      useNumberFormat
                      toFixedNumber={false}
                      maxLength={6}
                    />
                  ),
                }
              : undefined,
            p_application_header.loan_target === PurposeType.ONE ||
            p_application_header.loan_target === PurposeType.TWO ||
            p_application_header.loan_target === PurposeType.THREE ||
            p_application_header.loan_target === PurposeType.FOUR ||
            p_application_header.loan_target === PurposeType.FIVE ||
            p_application_header.loan_target === PurposeType.SIX
              ? {
                  label: getHousePurchasePriceTitle(),
                  input: (
                    <SPInputField
                      align="right"
                      name="house_purchase_price"
                      unit="万円"
                      placeholder="0"
                      numeric
                      useNumberFormat
                      toFixedNumber={false}
                      maxLength={6}
                    />
                  ),
                }
              : undefined,
            p_application_header.loan_target === PurposeType.FIVE ||
            p_application_header.loan_target === PurposeType.SIX
              ? {
                  label: '付帯設備',
                  input: (
                    <SPInputField
                      align="right"
                      name="accessory_cost"
                      unit="万円"
                      placeholder="0"
                      numeric
                      useNumberFormat
                      toFixedNumber={false}
                      maxLength={6}
                    />
                  ),
                }
              : undefined,
            p_application_header.loan_target === PurposeType.EIGHT
              ? {
                  label: '増改築費',
                  input: (
                    <SPInputField
                      align="right"
                      name="house_upgrade_cost"
                      unit="万円"
                      placeholder="0"
                      numeric
                      useNumberFormat
                      toFixedNumber={false}
                      maxLength={6}
                    />
                  ),
                }
              : undefined,
            p_application_header.loan_target === PurposeType.SEVEN ||
            p_application_header.loan_target === PurposeType.EIGHT
              ? {
                  label: '借換対象ローン残債',
                  input: (
                    <SPInputField
                      align="right"
                      name="refinancing_loan_balance"
                      unit="万円"
                      placeholder="0"
                      numeric
                      useNumberFormat
                      toFixedNumber={false}
                      maxLength={6}
                    />
                  ),
                }
              : undefined,
            {
              label: '諸費用等',
              input: (
                <SPInputField
                  align="right"
                  name="additional_cost"
                  unit="万円"
                  placeholder="0"
                  numeric
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              ),
            },
            !!spStepOneValues.p_application_header.is_home_loan_plus
              ? {
                  label: '住宅ローンプラス利用',
                  input: (
                    <SPInputField
                      align="right"
                      name="require_funds_breakdown_mortgage"
                      unit="万円"
                      placeholder="0"
                      numeric
                      useNumberFormat
                      toFixedNumber={false}
                      maxLength={6}
                    />
                  ),
                }
              : undefined,
          ]}
          right={[
            {
              label: '預貯金',
              input: (
                <SPInputField
                  align="right"
                  name="deposit_savings_1"
                  unit="万円"
                  placeholder="0"
                  numeric
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              ),
              note:
                p_application_header.loan_type === LoanType.TWO
                  ? '※お二人分'
                  : undefined,
              noteType:
                p_application_header.loan_type === LoanType.TWO
                  ? 'short'
                  : undefined,
            },
            {
              label: '不動産売却代金',
              input: (
                <SPInputField
                  align="right"
                  name="real_estate_sale_price"
                  unit="万円"
                  placeholder="0"
                  numeric
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              ),
              note:
                p_application_header.loan_type === LoanType.TWO
                  ? '※お二人分'
                  : undefined,
              noteType:
                p_application_header.loan_type === LoanType.TWO
                  ? 'short'
                  : undefined,
            },
            {
              label:
                p_application_header.loan_target === PurposeType.SEVEN ||
                p_application_header.loan_target === PurposeType.EIGHT
                  ? 'その他（有価証券等）'
                  : '有価証券売却など',
              input: (
                <SPInputField
                  align="right"
                  name="other_saving_amount"
                  unit="万円"
                  placeholder="0"
                  numeric
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              ),
              note:
                p_application_header.loan_type === LoanType.TWO
                  ? '※お二人分'
                  : undefined,
              noteType:
                p_application_header.loan_type === LoanType.TWO
                  ? 'long'
                  : undefined,
            },
            {
              label: '親族からの贈与',
              input: (
                <SPInputField
                  align="right"
                  name="relative_donation_amount"
                  unit="万円"
                  placeholder="0"
                  numeric
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              ),
              note:
                p_application_header.loan_type === LoanType.TWO
                  ? '※お二人が受ける贈与'
                  : undefined,
              noteType:
                p_application_header.loan_type === LoanType.TWO
                  ? 'long'
                  : undefined,
            },
            {
              label: '本件ローン',
              input: (
                <SPInputField
                  align="right"
                  name="loan_amount"
                  unit="万円"
                  placeholder="0"
                  numeric
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              ),
            },
            p_application_header.loan_type === LoanType.TWO
              ? {
                  label: 'ペアローン',
                  input: (
                    <SPInputField
                      align="right"
                      name="pair_loan_amount"
                      unit="万円"
                      placeholder="0"
                      numeric
                      useNumberFormat
                      toFixedNumber={false}
                      maxLength={6}
                    />
                  ),
                }
              : undefined,
            {
              label: 'その他',
              input: (
                <Stack spacing={1}>
                  <SPInputField
                    align="right"
                    name="other_procurement_breakdown"
                    unit="万円"
                    placeholder="0"
                    numeric
                    useNumberFormat
                    toFixedNumber={false}
                    maxLength={6}
                    convertFullWidth
                    onBlur={(event) => {
                      if (
                        event.target.value === '' ||
                        event.target.value === '0'
                      ) {
                        formik.setFieldValue(
                          'other_procurement_breakdown_content',
                          ''
                        );
                      }
                    }}
                  />
                  {+formik.values.other_procurement_breakdown !== 0 && (
                    <Typography variant="SP_form_item_note" color="b_333">
                      ※詳細を入力ください。
                    </Typography>
                  )}
                  {+formik.values.other_procurement_breakdown !== 0 && (
                    <SPInputField
                      name="other_procurement_breakdown_content"
                      convertFullWidth
                    />
                  )}
                </Stack>
              ),
              note:
                p_application_header.loan_type === LoanType.TWO
                  ? '※お二人分'
                  : undefined,
              noteType:
                p_application_header.loan_type === LoanType.TWO
                  ? 'short'
                  : undefined,
            },
          ]}
        />

        <Stack bgcolor="main_white" direction="row" mb={10}>
          <Stack
            flex={1}
            width="50%"
            sx={{ borderRight: '0.5px solid', borderColor: 'sp_primary_60' }}
          >
            <Stack
              sx={{
                px: 2,
                py: '2px',
                bgcolor: 'sp_primary_60',
              }}
            >
              <Typography variant="sp_step_06_form_title" color="main_white">
                必要資金　合計
              </Typography>
            </Stack>
            <Stack direction="row" p="4px 16px">
              <Stack
                justifyContent="center"
                sx={{
                  height: 48,
                  width: '100%',
                  bgcolor: 'sp_gray_100',
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="b_333"
                  sx={{
                    fontWeight: '300',
                    textAlign: 'right',
                    px: 3,
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-all',
                  }}
                >
                  {numberWithCommas(getRequiredFunds())}
                </Typography>
              </Stack>
              <Typography
                variant="sp_unit_text"
                color="b_333"
                whiteSpace="nowrap"
                sx={{ ml: 1, alignSelf: 'center' }}
              >
                万円
              </Typography>
            </Stack>
          </Stack>
          <Stack
            flex={1}
            width="50%"
            sx={{ borderLeft: '0.5px solid', borderColor: 'sp_primary_60' }}
          >
            <Stack
              sx={{
                px: 2,
                py: '2px',
                bgcolor: 'sp_primary_60',
              }}
            >
              <Typography variant="sp_step_06_form_title" color="main_white">
                調達資金　合計
              </Typography>
            </Stack>
            <Stack direction="row" p="4px 16px">
              <Stack
                justifyContent="center"
                sx={{
                  height: 48,
                  width: '100%',
                  bgcolor: 'sp_gray_100',
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="b_333"
                  sx={{
                    fontWeight: '300',
                    textAlign: 'right',
                    px: 3,
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-all',
                  }}
                >
                  {numberWithCommas(getRaisedFunds())}
                </Typography>
              </Stack>
              <Typography
                variant="sp_unit_text"
                color="b_333"
                whiteSpace="nowrap"
                sx={{ ml: 1, alignSelf: 'center' }}
              >
                万円
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepSixPage;

export type StepSixFormType =
  | {
      label: string;
      input: ReactNode;
      note?: string;
      noteType?: 'short' | 'long' | 'under';
    }
  | undefined;

interface SPStepSixFormProps {
  left: Array<StepSixFormType>;
  right: Array<StepSixFormType>;
  requiredFundChip?: boolean;
  bgcolor?: string;
}

export const SPStepSixForm: FC<SPStepSixFormProps> = ({
  right,
  left,
  requiredFundChip = false,
  bgcolor = 'sp_bg_gray',
}) => (
  <Stack bgcolor="main_white" direction="row">
    <Stack
      flex={1}
      sx={{ borderRight: '0.5px solid', borderColor: 'sp_primary_60' }}
    >
      <Stack
        sx={{
          border: (theme) => `1px solid ${theme?.palette?.sp_primary_100_main}`,
        }}
      >
        <Stack
          sx={{
            py: '2px',
            textAlign: 'center',
            bgcolor: 'sp_primary_100_main',
          }}
        >
          <Typography variant="sp_step_06_form_title" color="main_white">
            必要資金
          </Typography>
        </Stack>
      </Stack>
      {left.map(
        (form, index) =>
          form && (
            <SPFormItem
              key={index}
              label={form.label}
              labelSx={{
                bgcolor: 'sp_primary_40',
                p: '2px 8px',
                '& .MuiTypography-SP_form_item_label': { fontSize: 14 },
              }}
              childrenSx={{ p: '4px 16px', bgcolor: bgcolor }}
              note={form.note}
              noteType={form.noteType}
              required={requiredFundChip}
            >
              {form.input}
            </SPFormItem>
          )
      )}
    </Stack>

    <Stack
      flex={1}
      sx={{ borderLeft: '0.5px solid', borderColor: 'sp_primary_60' }}
    >
      <Stack
        sx={{
          border: (theme) => `1px solid ${theme?.palette?.sp_primary_100_main}`,
        }}
      >
        <Stack
          sx={{
            py: '2px',
            textAlign: 'center',
            bgcolor: 'sp_primary_100_main',
          }}
        >
          <Typography variant="sp_step_06_form_title" color="main_white">
            調達資金
          </Typography>
        </Stack>
      </Stack>
      {right.map(
        (form, index) =>
          form && (
            <SPFormItem
              key={index}
              label={form.label}
              labelSx={{
                bgcolor: 'sp_primary_40',
                p: '2px 8px',
                '& .MuiTypography-SP_form_item_label': { fontSize: 14 },
              }}
              childrenSx={{ p: '4px 16px', bgcolor: bgcolor }}
              note={form.note}
              noteType={form.noteType}
              required
            >
              {form.input}
            </SPFormItem>
          )
      )}
    </Stack>
  </Stack>
);
