import { dayjs, regex, yup } from 'libs';

import {
  ApplicantDetailType,
  DispatchType,
  FileATaxReturnType,
  JobChangeType,
  LeaveType,
  LoanType,
  OccupationDetailType,
  OccupationType,
  PlannedCohabitantType,
  PlanningAreaType,
  PurposeType,
  ReasonsForNeedingHousingType,
  WorkIndustryType,
  RebuildingReasonType,
  LoanPurposeType,
  FundsPairOffType,
  ResidentStatusType,
  ScheduledLoanPayoffType,
  CurrentLoanType,
  RepaymentBorrowingType,
  FinalTaxReturnType,
  BorrowingType,
  CODEBANK,
  RelationshipType,
  MESSAGE_REGEX,
} from 'constant';
import {
  PBorrowingAttribute,
  PBorrowingAttribute2,
  PBorrowingAttribute3,
  PBorrowingAttribute4,
  PBorrowingAttributeSBI,
} from '../../types';

export type Context = yup.TestContext<object> & {
  from?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: { [key: string]: any };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: { [key: string]: any };
  }[];
};

// @ts-ignore
export const validationSchema = yup.object().shape(
  {
    p_application_header: yup.object({
      loan_type: yup.string().required(),
      loan_target: yup.string().required(),
      land_purchase_price: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .when('loan_target', {
          is: (loan_target: PurposeType) => loan_target === PurposeType.SIX,
          then: (shema) => shema.required(),
          otherwise: (schema) => schema,
        }),
      house_purchase_price: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .when('loan_target', {
          is: (loan_target: PurposeType) =>
            loan_target !== PurposeType.SEVEN &&
            loan_target !== PurposeType.EIGHT &&
            loan_target !== PurposeType.NINE,
          then: (shema) => shema.required(),
          otherwise: (schema) => schema,
        }),
      additional_cost: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .required(),
      saving_amount: yup
        .number()
        .integer()
        .required()
        .typeError('半角数字でご入力ください')
        .min(0)
        .max(99999999),
      collateral_address: yup.string().max(99),
      mansion_name_kanji: yup.string().max(48),
      collateral_land_area: yup
        .number()
        .moreThan(4)
        .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください')
        .min(0, '正しくご入力ください。')
        .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
      occupied_area: yup
        .number()
        .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください')
        .min(0, '正しくご入力ください。')
        .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
      accessory_cost: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .when('loan_target', {
          is: (loan_target: PurposeType) =>
            loan_target === PurposeType.FIVE || loan_target === PurposeType.SIX,
          then: (shema) => shema.required(),
          otherwise: (schema) => schema,
        }),
      refinancing_loan_balance: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .when('loan_target', {
          is: (loan_target: PurposeType) =>
            loan_target === PurposeType.SEVEN ||
            loan_target === PurposeType.EIGHT,
          then: (shema) => shema.required(),
          otherwise: (schema) => schema,
        }),
      other_require_funds_breakdown_content: yup
        .string()
        .matches(regex.japanName)
        .max(128),
      other_require_funds_breakdown: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999),
      total_require_funds: yup
        .number()
        .integer()
        .required()
        .test(
          'validateTotal',
          '資金調達合計と必要資金総額が一致しません。正しくご入力ください。',
          function (this, value) {
            const { total_procurement } = this.parent;
            return value === total_procurement;
          }
        ),
      relative_donation_amount: yup
        .number()
        .min(0, '半角数字でご入力ください')
        .typeError('半角数字でご入力ください')
        .required()
        .max(99999, '半角数字でご入力ください'),
      loan_amount: yup
        .number()
        .min(0, '半角数字でご入力ください')
        .typeError('半角数字でご入力ください')
        .required()
        .max(99999, '半角数字でご入力ください'),
      pair_loan_amount: yup
        .number()
        .min(0, '半角数字でご入力ください')
        .typeError('半角数字でご入力ください')
        .required()
        .max(99999, '半角数字でご入力ください'),
      other_procurement_breakdown_content: yup
        .string()
        .required()
        .max(128)
        .matches(regex.japanName),
      other_procurement_breakdown: yup
        .number()
        .min(0, '半角数字でご入力ください')
        .typeError('半角数字でご入力ください')
        .required()
        .max(99999, '半角数字でご入力ください'),
      total_procurement: yup
        .number()
        .required()
        .test(
          'validateTotal',
          '資金調達合計と必要資金総額が一致しません。正しくご入力ください。',
          function (this, value) {
            const { total_require_funds } = this.parent;
            return value === total_require_funds;
          }
        ),
      collateral_floor_area: yup
        .number()
        .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください')
        .min(0)
        .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
      collateral_total_floor_area: yup
        .number()
        .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください')
        .min(0)
        .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
      land_ownership: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。'),
      purchase_purpose: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。'),
      planning_area: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。'),
      other_planning_area: yup.string().when('planning_area', {
        is: (planning_area: PlanningAreaType) =>
          planning_area === PlanningAreaType.OTHERS,
        then: yup.string().max(40).required(),
      }),
      rebuilding_reason: yup.string().when('planning_area', {
        is: (planning_area: PlanningAreaType) =>
          planning_area === PlanningAreaType.URBANIZATION_CONTROL_AREA ||
          planning_area === PlanningAreaType.OUTSIDE_CITY_PLANNING_AREA,
        then: yup.string().required('お客様に該当する数値を選択してください。'),
      }),
      other_rebuilding_reason: yup.string().when('rebuilding_reason', {
        is: (rebuilding_reason: RebuildingReasonType) =>
          rebuilding_reason === RebuildingReasonType.OTHERS,
        then: yup.string().max(40).matches(regex.japanName).required(),
      }),
      purchase_contract_date: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。')
        .test(
          'time-of-the-past',
          MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
          (value) => {
            if (!value) return true;
            const [year, month, day] = value.split('/');
            const borrowingDate = dayjs(`${year}-${month}-${day}`);
            return borrowingDate.isSameOrAfter(dayjs(), 'date');
          }
        )
        .test('validateDate', '正しく選択してください。', (value) => {
          if (!value) return true;
          return dayjs(value, 'YYYY/MM/DD', true).isValid();
        }),
      flat_35_applicable_plan: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。'),
      maintenance_type: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。'),
      flat_35_application: yup
        .array()
        .requiredArrayMCJ('お客様に該当する数値を選択してください。'),
      region_type: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。'),
      house_upgrade_cost: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .when('loan_target', {
          is: (loan_target: PurposeType) =>
            loan_target === PurposeType.EIGHT ||
            loan_target === PurposeType.NINE,
          then: (shema) => shema.required(),
          otherwise: (schema) => schema,
        }),
      deposit_savings_1: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .requiredNumberMCJ(),
      deposit_savings_2: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .requiredNumberMCJ(),
      real_estate_sale_price: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .requiredNumberMCJ(),
      other_saving_amount: yup
        .number()
        .integer()
        .min(0)
        .typeError('半角数字でご入力ください')
        .max(99999999)
        .requiredNumberMCJ(),
      completely_repayment_type: yup.array().when('p_borrowings_attributes', {
        is: (
          p_borrowings_attributes: Array<
            | PBorrowingAttribute
            | PBorrowingAttribute2
            | PBorrowingAttribute3
            | PBorrowingAttribute4
            | PBorrowingAttributeSBI
          >
        ) =>
          !!p_borrowings_attributes?.find(
            (borrowing) =>
              'scheduled_loan_payoff' in borrowing &&
              [
                ScheduledLoanPayoffType.SCHEDULED_TO_BE_PAID_OFF,
                ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID,
              ].includes(borrowing.scheduled_loan_payoff)
          ),
        then: yup
          .array()
          .min(1, 'お客様に該当する数値を選択してください。')
          .requiredArrayMCJ(),
      }),
      other_complete_repayment_type: yup
        .string()
        .when('completely_repayment_type', {
          is: (completely_repayment_type: FundsPairOffType[]) =>
            completely_repayment_type.includes(FundsPairOffType.OTHERS),
          then: yup.string().max(40).matches(regex.japanName).required(),
          otherwise: yup.string().max(40).matches(regex.japanName),
        }),
      refund_content: yup
        .string()
        .max(20)
        .test({
          message: 'この項目を入力してください。',
          test(this: Context, value) {
            const { from } = this;

            if (
              !!from &&
              !!(
                from[1].value.main_p_applicant_person
                  .p_borrowings_attributes as Array<
                  | PBorrowingAttribute
                  | PBorrowingAttribute2
                  | PBorrowingAttribute3
                  | PBorrowingAttribute4
                  | PBorrowingAttributeSBI
                >
              ).find(
                (borrowing) =>
                  'scheduled_loan_payoff' in borrowing &&
                  [
                    ScheduledLoanPayoffType.SCHEDULED_TO_BE_PAID_OFF,
                    ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID,
                  ].includes(borrowing.scheduled_loan_payoff)
              ) &&
              from[1].value.master_bank_codes.includes(CODEBANK.MCJ)
            ) {
              return !!value;
            }
            return true;
          },
        }),
      refund_amount: yup
        .number()
        .min(0)
        .max(99999999)
        .test({
          message: 'この項目を入力してください。',
          test(this: Context, value) {
            const { from } = this;

            if (
              !!from &&
              !!(
                from[1].value.main_p_applicant_person
                  .p_borrowings_attributes as Array<
                  | PBorrowingAttribute
                  | PBorrowingAttribute2
                  | PBorrowingAttribute3
                  | PBorrowingAttribute4
                  | PBorrowingAttributeSBI
                >
              ).find(
                (borrowing) =>
                  'scheduled_loan_payoff' in borrowing &&
                  [
                    ScheduledLoanPayoffType.SCHEDULED_TO_BE_PAID_OFF,
                    ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID,
                  ].includes(borrowing.scheduled_loan_payoff)
              ) &&
              from[1].value.master_bank_codes.includes(CODEBANK.MCJ)
            ) {
              return !!value;
            }
            return true;
          },
        }),
      house_rent_to_be_paid: yup
        .number()
        .min(0)
        .max(99999999)
        .test({
          message: 'この項目を入力してください。',
          test(this: Context, value) {
            const { from } = this;

            if (
              !!from &&
              from[1].value.master_bank_codes.includes(CODEBANK.MCJ)
            ) {
              return !!value;
            }
            return true;
          },
        }),
      land_rent_to_be_paid: yup
        .number()
        .min(0)
        .max(99999999)
        .test({
          message: 'この項目を入力してください。',
          test(this: Context, value) {
            const { from } = this;

            if (
              !!from &&
              from[1].value.master_bank_codes.includes(CODEBANK.MCJ)
            ) {
              return !!value;
            }
            return true;
          },
        }),
    }),

    main_p_applicant_person: yup.object().shape({
      housemate_number: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      nationality: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      current_residence: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      annual_repayment_amount: yup
        .number()
        .integer('半角数字でご入力ください')
        .typeError('半角数字でご入力ください')
        .min(0)
        .max(99999),
      p_borrowing_detail_attributes: yup.object({
        loan_term: yup
          .number()
          .typeError('借入期間は数値でなければなりません')
          .required('お客様に該当する数値を選択してください。')
          .max(35)
          .test({
            message: 'ご返済完了日が80歳未満となるようにご計画願います。',
            test(this: Context, value) {
              const { from } = this;

              return from && !!value
                ? dayjs(from[2].value.loan_desired_borrowing_date).diff(
                    from[1].value.birthday,
                    'month'
                  ) +
                    value * 12 <
                    960
                : !!value;
            },
          }),
        temporary_desired_loan_amount: yup
          .number()
          .required()
          .typeError('半角数字でご入力ください')
          .min(10)
          .max(99999999)
          .test(
            'divisible-by-10',
            '10万円単位でご入力ください',
            (borrow) => +(borrow || 0) % 10 === 0
          ),
        halfyear_bonus: yup
          .number()
          .required()
          .typeError(
            '資金調達合計と必要資金総額が一致しません。正しくご入力ください。'
          )
          .min(0)
          .max(99999),
        repayment_method: yup.string().required(),
        desired_monthly_bonus: yup
          .string()
          .requiredMCJ('お客様に該当する数値を選択してください。'),
      }),
      p_land_advance_plan_attributes: yup
        .object()
        .when('has_land_advance_plan', {
          is: true,
          then: yup
            .object()
            .shape({
              loan_desired_borrowing_date: yup
                .string()
                .required()
                .test(
                  'time-of-the-past',
                  MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
                  (value) => {
                    if (!value) return false;
                    const [year, month, day] = value.split('/');
                    const borrowingDate = dayjs(`${year}-${month}-${day}`);
                    return borrowingDate.isAfter(dayjs(), 'date');
                  }
                )
                .test('validateDate', '正しく選択してください。', (value) =>
                  dayjs(value, 'YYYY/MM/DD', true).isValid()
                ),
              loan_term: yup.number().required(),
              desired_loan_amount: yup
                .number()
                .required()
                .typeError('半角数字でご入力ください')
                .min(10)
                .max(99999999)
                .test(
                  'divisible-by-10',
                  '10万円単位でご入力ください',
                  (borrow) => +(borrow || 0) % 10 === 0
                ),
              halfyear_bonus: yup.string().required(),
              repayment_method: yup.string().required(),
            })
            .required(),
          otherwise: yup.object().shape({
            loan_desired_borrowing_date: yup.string(),
            loan_term: yup.number(),
            desired_loan_amount: yup.number(),
            halfyear_bonus: yup.string(),
            repayment_method: yup.string(),
          }),
        }),
      occupation: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      other_occupation: yup.string().when('occupation', {
        is: (occupation: OccupationType) =>
          occupation === OccupationType.OTHERS,
        then: yup.string().required().max(40),
        otherwise: yup.string(),
      }),
      occupation_detail: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      other_occupation_detail: yup.string().when('occupation_detail', {
        is: (occupation_detail: OccupationDetailType) =>
          occupation_detail === OccupationDetailType.OTHERS,
        then: yup.string().required().max(40),
        otherwise: yup.string(),
      }),
      last_year_income: yup
        .number()
        .min(0)
        .max(999999999999)
        .requiredNumberMCJ(),
      bonus_income: yup.number().min(0).max(999999999999).requiredNumberMCJ(),
      business_income: yup
        .number()
        .min(0)
        .max(999999999999)
        .requiredNumberMCJ(),
      pension_income: yup.number().min(0).max(999999999999).requiredNumberMCJ(),
      solar_power_income: yup
        .number()
        .min(0)
        .max(999999999999)
        .requiredNumberMCJ(),
      total_last_year_income: yup.number().min(0).max(999999999999).required(),
      two_years_ago_income: yup
        .number()
        .min(0)
        .max(999999999999)
        .requiredNumberMCJ(),
      office_name_kanji: yup.string().max(48),
      office_name_kana: yup
        .string()
        .max(48)
        .matches(regex.kanaHalfwidthHaveNumber, 'カタカナでご入力ください。'),
      office_postal_code: yup
        .string()
        .min(7, '正しくご入力ください。')
        .matches(regex.zipCode),
      office_prefecture_kanji: yup.string().max(20),
      office_prefecture_kana: yup.string().max(20),
      office_city_kanji: yup.string().max(20),
      office_city_kana: yup.string().max(20),
      office_district_kanji: yup.string().max(40),
      office_district_kana: yup.string().max(30),
      other_office_address_kanji: yup.string().max(99),
      other_office_address_kana: yup.string().max(138),
      office_phone_number: yup
        .string()
        .max(11, '正しくご入力ください。')
        .required()
        .matches(regex.fixedPhones),
      industry: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      other_industry: yup.string().when('industry', {
        is: (industry: WorkIndustryType) =>
          industry === WorkIndustryType.OTHERS,
        then: yup.string().required().max(40),
        otherwise: yup.string(),
      }),
      tax_return: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      type_tax_return: yup.array().when('tax_return', {
        is: (tax_return: FinalTaxReturnType) =>
          tax_return === FinalTaxReturnType.YES,
        then: yup
          .array()
          .min(1, 'お客様に該当する数値を選択してください。')
          .required(),
        otherwise: yup.array(),
      }),
      other_type_tax_return: yup.string().when('type_tax_return', {
        is: (type_tax_return: FileATaxReturnType[]) =>
          type_tax_return.includes(FileATaxReturnType.OTHERS),
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
      maternity_paternity_leave_status: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      maternity_paternity_leave_start_time: yup
        .string()
        .when('maternity_paternity_leave_status', {
          is: (maternity_paternity_leave_status: LeaveType) =>
            maternity_paternity_leave_status ===
            LeaveType.SCHEDULED_ACQUISITION,
          then: yup
            .string()
            .required('お客様に該当する数値を選択してください。'),
          otherwise: yup.string(),
        }),
      maternity_paternity_leave_end_time: yup
        .string()
        .when('maternity_paternity_leave_status', {
          is: (maternity_paternity_leave_status: LeaveType) =>
            maternity_paternity_leave_status ===
            LeaveType.SCHEDULED_ACQUISITION,
          then: yup
            .string()
            .required('お客様に該当する数値を選択してください。')
            .test({
              message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
              test(value) {
                if (!value) return false;
                const [year, month, day] = value.split('/');
                const endTime = dayjs(`${year}-${month}-${day}`);

                return endTime.isAfter(
                  dayjs(this.parent.maternity_paternity_leave_start_time),
                  'date'
                );
              },
            }),
          otherwise: yup.string(),
        }),
      back_to_work_date: yup.string().when('maternity_paternity_leave_status', {
        is: (maternity_paternity_leave_status: LeaveType) =>
          maternity_paternity_leave_status === LeaveType.SCHEDULED_ACQUISITION,
        then: yup
          .string()
          .required('お客様に該当する数値を選択してください。')
          .test({
            message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            test(value) {
              if (!value) return false;
              const [year, month, day] = value.split('/');
              const endTime = dayjs(`${year}-${month}-${day}`);

              return endTime.isAfter(
                dayjs(this.parent.maternity_paternity_leave_end_time),
                'date'
              );
            },
          }),
        otherwise: yup.string(),
      }),
      nursing_leave_status: yup
        .string()
        .requiredMCJ('お客様に該当する数値を選択してください。'),
      transfer_office: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      transfer_office_name_kanji: yup
        .string()
        .max(48)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      transfer_office_name_kana: yup
        .string()
        .max(48)
        .matches(regex.kanaHalfwidthHaveNumber, 'カタカナでご入力ください。')
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      transfer_office_phone_number: yup.string().when('transfer_office', {
        is: (transfer_office: DispatchType) =>
          transfer_office === DispatchType.YES,
        then: yup
          .string()
          .max(11, '正しくご入力ください。')
          .required()
          .matches(regex.fixedPhones),
        otherwise: yup.string(),
      }),
      transfer_office_postal_code: yup
        .string()
        .min(7, '正しくご入力ください。')
        .matches(regex.zipCode)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      transfer_office_prefecture_kanji: yup
        .string()
        .max(20)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
        }),
      transfer_office_city_kanji: yup
        .string()
        .max(20)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
        }),
      transfer_office_district_kanji: yup
        .string()
        .max(40)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
        }),
      transfer_office_other_address_kanji: yup
        .string()
        .max(99)

        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      transfer_office_prefecture_kana: yup
        .string()
        .max(20)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      transfer_office_city_kana: yup
        .string()
        .max(20)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      transfer_office_district_kana: yup
        .string()
        .max(30)
        .matches(regex.kanaHalfwidthHaveNumber, 'カタカナでご入力ください。')
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      transfer_office_other_address_kana: yup
        .string()
        .max(138)
        .when('transfer_office', {
          is: (transfer_office: DispatchType) =>
            transfer_office === DispatchType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      job_change: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      job_change_company_name_kanji: yup
        .string()
        .max(48)
        .when('job_change', {
          is: (job_change: JobChangeType) => job_change === JobChangeType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      job_change_company_name_kana: yup
        .string()
        .max(48)
        .matches(regex.kanaAlphabet, 'カタカナでご入力ください。')
        .when('job_change', {
          is: (job_change: JobChangeType) => job_change === JobChangeType.YES,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      prev_company_year_num: yup.string().when('job_change', {
        is: (job_change: JobChangeType) => job_change === JobChangeType.YES,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
      prev_company_month_num: yup.string().when('job_change', {
        is: (job_change: JobChangeType) => job_change === JobChangeType.YES,
        then: yup
          .string()
          .test({
            message: 'お客様に該当する数値を選択してください。',
            test() {
              return !!this.parent.prev_company_year_num;
            },
          })
          .required(),
        otherwise: yup.string(),
      }),
      prev_company_industry: yup.string().when('job_change', {
        is: (job_change: JobChangeType) => job_change === JobChangeType.YES,
        then: yup.string().required('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      other_prev_company_industry: yup.string().when('prev_company_industry', {
        is: (prev_company_industry: WorkIndustryType) =>
          prev_company_industry === WorkIndustryType.OTHERS,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
      department: yup.string().required().max(46),
      p_resident_attributes: yup.object().when('applicant_detail_type', {
        is: (applicant_detail_type: ApplicantDetailType) =>
          applicant_detail_type === ApplicantDetailType.MAIN,
        then: yup.object().shape(
          {
            last_name_kanji: yup.string().max(48).requiredMCJ(),
            first_name_kanji: yup.string().max(48).requiredMCJ(),
            last_name_kana: yup.string().max(48).requiredMCJ(),
            first_name_kana: yup.string().max(48).requiredMCJ(),
            relationship: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
            other_relationship: yup.string().when('relationship', {
              is: (relationship: RelationshipType) =>
                relationship === RelationshipType.OTHERS,
              then: yup.string().max(40).matches(regex.japanName).required(),
              otherwise: yup.string(),
            }),
            nationality: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
            birthday: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
            mobile_phone_number: yup
              .string()
              .max(11, '正しくご入力ください。')
              .matches(regex.mobilePhone)
              .when('home_phone_number', {
                is: (homePhone: string) => !homePhone || homePhone.length === 0,
                then: yup.string().requiredMCJ(),
                otherwise: yup.string(),
              }),
            home_phone_number: yup
              .string()
              .max(11, '正しくご入力ください。')
              .matches(regex.fixedPhones)
              .when('mobile_phone_number', {
                is: (phoneNumber: string) =>
                  !phoneNumber || phoneNumber.length === 0,
                then: yup.string().requiredMCJ(),
                otherwise: yup.string(),
              }),
            postal_code: yup
              .string()
              .min(7, '正しくご入力ください。')
              .matches(regex.zipCode)
              .noWhitespace()
              .requiredMCJ(),
            prefecture_kanji: yup.string().requiredMCJ().max(20),
            city_kanji: yup.string().requiredMCJ().max(20),
            district_kanji: yup.string().requiredMCJ().max(40),
            other_address_kanji: yup.string().requiredMCJ().max(99),
            prefecture_kana: yup.string().requiredMCJ().max(20),
            city_kana: yup.string().requiredMCJ().max(20),
            district_kana: yup
              .string()
              .requiredMCJ()
              .max(30)
              .matches(regex.kanaHalfwidthHaveNumber),
            other_address_kana: yup.string().requiredMCJ().max(138),
            loan_from_japan_housing_finance_agency: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
          },
          [['home_phone_number', 'mobile_phone_number']]
        ),
      }),
      employment_started_date: yup
        .string()
        .required('お客様に該当する数値を選択してください。')
        .test('validateDate', '正しく選択してください。', (value) => {
          if (!value) return false;
          const [year, month] = value.split('/');
          return !(!year || !month);
        })
        .test('not-pass-current', '無効な日付', (value) => {
          if (!value) return false;
          const [year, month] = value.split('/');
          const currentMonth = dayjs().month() + 1;
          const currentYear = dayjs().year();
          return !(+year === currentYear && +month >= currentMonth);
        }),
      lived_length_year_num: yup.string().required(),
      lived_length_month_num: yup
        .string()
        .test({
          message: 'お客様に該当する数値を選択してください。',
          test() {
            return !!this.parent.lived_length_year_num;
          },
        })
        .required('お客様に該当する数値を選択してください。'),
      reason_acquire_home: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      other_reason_acquire_home: yup.string().when('reason_acquire_home', {
        is: (reason_acquire_home: ReasonsForNeedingHousingType) =>
          reason_acquire_home === ReasonsForNeedingHousingType.OTHERS,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
      business_ability: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      resident_status: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      non_resident_reason: yup.string().when('resident_status', {
        is: (resident_status: ResidentStatusType) =>
          resident_status ===
          ResidentStatusType.THE_PERSON_DOES_NOT_RESIDE_OTHER,
        then: yup.string().max(40).matches(regex.japanName).required(),
      }),
      planned_cohabitant: yup
        .array()
        .min(1, 'お客様に該当する数値を選択してください。')
        .required(),
      children_number: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[]) =>
          planned_cohabitant.includes(PlannedCohabitantType.CHILD),
        then: yup.string().required('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      siblings_number: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[]) =>
          planned_cohabitant.includes(
            PlannedCohabitantType.BROTHERS_AND_SISTERS
          ),
        then: yup.string().required('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      other_relationship: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[]) =>
          planned_cohabitant.includes(PlannedCohabitantType.OTHERS),
        then: yup.string().max(40).matches(regex.japanName).required(),
        otherwise: yup.string(),
      }),
      other_people_number: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[]) =>
          planned_cohabitant.includes(PlannedCohabitantType.OTHERS),
        then: yup.string().required('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      has_borrowings: yup
        .string()
        .required('お客様に該当する数値を選択してください。'),
      monthly_payment: yup.string().when('has_borrowings', {
        is: (has_borrowings: CurrentLoanType) =>
          has_borrowings === CurrentLoanType.YES,
        then: yup
          .string()
          .requiredMCJ('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      card_loan: yup.string().when('has_borrowings', {
        is: (has_borrowings: CurrentLoanType) =>
          has_borrowings === CurrentLoanType.YES,
        then: yup
          .string()
          .requiredMCJ('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      rented_house: yup.string().when('has_borrowings', {
        is: (has_borrowings: CurrentLoanType) =>
          has_borrowings === CurrentLoanType.YES,
        then: yup
          .string()
          .requiredMCJ('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      business_loan: yup.string().when('has_borrowings', {
        is: (has_borrowings: CurrentLoanType) =>
          has_borrowings === CurrentLoanType.YES,
        then: yup
          .string()
          .requiredMCJ('お客様に該当する数値を選択してください。'),
        otherwise: yup.string(),
      }),
      p_borrowings_attributes: yup.array().of(
        yup.object().shape({
          loan_purpose: yup.string(),
          other_purpose: yup.string().when('loan_purpose', {
            is: (loan_purpose: LoanPurposeType) =>
              loan_purpose === LoanPurposeType.OTHERS,
            then: yup.string().max(40).matches(regex.japanName),
          }),
          lender: yup.string().max(40).matches(regex.japanName),
          borrower: yup.string(),
          loan_amount: yup.number().min(0).max(99999999),
          current_balance_amount: yup.number().min(0).max(99999999),
          borrowing_from_housing_finance_agency: yup
            .string()
            .when('borrowing_type', {
              is: (borrowing_type: BorrowingType) =>
                borrowing_type === BorrowingType.MONTHLY_PAYMENT,
              then: yup
                .string()
                .requiredMCJ('お客様に該当する数値を選択してください。'),
            }),
          annual_repayment_amount: yup.number().min(0).max(99999999),
          loan_start_date: yup.string(),
          loan_deadline_date: yup.string().test({
            message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
            test(value) {
              if (!value || !this.parent.loan_start_date) return true;
              const [year, month] = value.split('/');
              const endTime = dayjs(`${year}-${month}`);

              return endTime.isAfter(
                dayjs(this.parent.loan_start_date),
                'date'
              );
            },
          }),
          guaranteed_status: yup.string().when('borrowing_type', {
            is: (borrowing_type: BorrowingType) =>
              borrowing_type === BorrowingType.MONTHLY_PAYMENT,
            then: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
          }),
          scheduled_loan_payoff: yup.string().when('borrowing_type', {
            is: (borrowing_type: BorrowingType) =>
              borrowing_type !== BorrowingType.BUSINESS_LOAN &&
              borrowing_type !== BorrowingType.SBI_LOAN &&
              borrowing_type !== BorrowingType.DEFAULT,
            then: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
          }),
          expected_repayment_date: yup.string().when('borrowing_type', {
            is: (borrowing_type: BorrowingType) =>
              borrowing_type !== BorrowingType.BUSINESS_LOAN &&
              borrowing_type !== BorrowingType.SBI_LOAN,
            then: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
          }),
          borrowing_category: yup.string(),
          rental_room_number: yup.number().min(0),
          common_housing: yup.string().when('borrowing_type', {
            is: (borrowing_type: BorrowingType) =>
              borrowing_type === BorrowingType.RENTED_HOUSE,
            then: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
          }),
          estate_mortgage: yup.string().when('borrowing_type', {
            is: (borrowing_type: BorrowingType) =>
              borrowing_type === BorrowingType.RENTED_HOUSE ||
              borrowing_type === BorrowingType.BUSINESS_LOAN,
            then: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
          }),
          business_borrowing_type: yup.string(),
          specific_loan_purpose: yup.string().max(40).matches(regex.japanName),
          repayment_borrowing_method: yup.string().when('borrowing_type', {
            is: (borrowing_type: BorrowingType) =>
              borrowing_type === BorrowingType.BUSINESS_LOAN,
            then: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
          }),
          installment_repayment: yup
            .number()
            .when(['borrowing_type', 'repayment_borrowing_method'], {
              is: (
                borrowing_type: BorrowingType,
                repayment_borrowing_method: RepaymentBorrowingType
              ) =>
                borrowing_type === BorrowingType.BUSINESS_LOAN &&
                repayment_borrowing_method ===
                  RepaymentBorrowingType.INSTALLMENT_REPAYMENT,
              then: yup
                .number()
                .integer()
                .min(0)
                .max(99999999)
                .required()
                .typeError('正しくご入力ください。'),
              otherwise: yup.number().min(0).max(99999999),
            }),
        })
      ),
    }),

    pair_p_applicant_person: yup
      .object()
      .when('p_application_header.loan_type', {
        is: (loan_type: LoanType) =>
          loan_type !== LoanType.ONE && loan_type !== LoanType.DEFAULT,
        then: yup.object().shape(
          {
            last_name_kanji: yup.string().trim().required().max(48),
            first_name_kanji: yup.string().trim().required().max(48),
            last_name_kana: yup.string().trim().required().max(48),
            first_name_kana: yup.string().trim().required().max(48),
            birthday: yup
              .string()
              .required('お客様に該当する数値を選択してください。')
              .test('not-pass-day', '正しく選択してください。', (birthday) => {
                if (birthday) {
                  const [, month, day] = birthday?.split('/');
                  if (month === '2' && (day === '30' || day === '31')) {
                    return false;
                  }
                }
                return true;
              }),
            sex: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            nationality: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            mobile_phone_number: yup
              .string()
              .max(11, '正しくご入力ください。')
              .matches(regex.mobilePhone)
              .when('home_phone_number', {
                is: (homePhone: string) => !homePhone || homePhone.length === 0,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            home_phone_number: yup
              .string()
              .max(11, '正しくご入力ください。')
              .matches(regex.fixedPhones)
              .when('mobile_phone_number', {
                is: (phoneNumber: string) =>
                  !phoneNumber || phoneNumber.length === 0,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            current_residence: yup.string().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
            }),
            has_borrowings: yup.string().when('currentLoan', {
              is: (currentLoan: CurrentLoanType) =>
                currentLoan === CurrentLoanType.YES,
              then: yup.string().required(),
              otherwise: yup.string(),
            }),
            housemate_number: yup.string().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.MAIN ||
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup.string().min(1).max(6).required(),
              otherwise: yup.string().min(1).max(6),
            }),
            postal_code: yup
              .string()
              .min(7, '正しくご入力ください。')
              .required()
              .matches(regex.zipCode)
              .noWhitespace(),
            prefecture_kanji: yup.string().required().max(20),
            city_kanji: yup.string().required().max(20),
            district_kanji: yup.string().required().max(40),
            other_address_kanji: yup.string().required().max(99),
            prefecture_kana: yup.string().required().max(20),
            city_kana: yup.string().required().max(20),
            district_kana: yup
              .string()
              .required()
              .max(30)
              .matches(regex.kanaHalfwidthHaveNumber),
            other_address_kana: yup.string().required().max(138),
            owner_email: yup
              .string()
              .matches(regex.email)
              .required()
              .label('メールアドレス'),
            confirmation_email: yup
              .string()
              .matches(regex.email)
              .required()
              .when('owner_email', {
                is: (val: string) => !!val?.length,
                then: yup
                  .string()
                  .oneOf(
                    [yup.ref('owner_email')],
                    'メールアドレスが一致しません。'
                  ),
              })
              .label('メールアドレス（確認用）'),

            p_borrowing_detail_attributes: yup
              .object()
              .when('applicant_detail_type', {
                is: (applicant_detail_type: ApplicantDetailType) =>
                  applicant_detail_type === ApplicantDetailType.PAIR,
                then: yup.object({
                  loan_term: yup
                    .number()
                    .typeError('借入期間は数値でなければなりません')
                    .required()
                    .max(35)
                    .test({
                      message:
                        'ご返済完了日が80歳未満となるようにご計画願います。',
                      test(this: Context, value) {
                        const { from } = this;

                        return from && !!value
                          ? dayjs(
                              from[2].value.loan_desired_borrowing_date
                            ).diff(from[1].value.birthday, 'month') +
                              value * 12 <
                              960
                          : !!value;
                      },
                    }),
                  temporary_desired_loan_amount: yup
                    .number()
                    .required()
                    .typeError('半角数字でご入力ください')
                    .min(10)
                    .max(99999999)
                    .test(
                      'divisible-by-10',
                      '10万円単位でご入力ください',
                      (borrow) => +(borrow || 0) % 10 === 0
                    ),
                  halfyear_bonus: yup
                    .number()
                    .required()
                    .typeError(
                      '資金調達合計と必要資金総額が一致しません。正しくご入力ください。'
                    )
                    .min(0)
                    .max(99999),
                  repayment_method: yup.string().required(),
                  desired_monthly_bonus: yup
                    .string()
                    .requiredMCJ('お客様に該当する数値を選択してください。'),
                }),
              }),
            occupation: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            other_occupation: yup.string().when('occupation', {
              is: (occupation: OccupationType) =>
                occupation === OccupationType.OTHERS,
              then: yup.string().required().max(40),
              otherwise: yup.string(),
            }),
            occupation_detail: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            other_occupation_detail: yup.string().when('occupation_detail', {
              is: (occupation_detail: OccupationDetailType) =>
                occupation_detail === OccupationDetailType.OTHERS,
              then: yup.string().required().max(40),
              otherwise: yup.string(),
            }),
            last_year_income: yup
              .number()
              .typeError('半角数字でご入力ください')
              .min(0)
              .max(99999)
              .requiredNumberMCJ(),
            bonus_income: yup
              .number()
              .min(0)
              .max(999999999999)
              .requiredNumberMCJ(),
            business_income: yup
              .number()
              .min(0)
              .max(999999999999)
              .requiredNumberMCJ(),
            pension_income: yup
              .number()
              .min(0)
              .max(999999999999)
              .requiredNumberMCJ(),
            solar_power_income: yup
              .number()
              .min(0)
              .max(999999999999)
              .requiredNumberMCJ(),
            total_last_year_income: yup
              .number()
              .min(0)
              .max(999999999999)
              .required(),
            two_years_ago_income: yup
              .number()
              .min(0)
              .max(999999999999)
              .requiredNumberMCJ(),
            office_name_kanji: yup.string().max(48),
            office_name_kana: yup
              .string()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveNumber,
                'カタカナでご入力ください。'
              ),
            office_postal_code: yup
              .string()
              .min(7, '正しくご入力ください。')
              .matches(regex.zipCode),
            office_prefecture_kanji: yup.string().max(20),
            office_prefecture_kana: yup.string().max(20),
            office_city_kanji: yup.string().max(20),
            office_city_kana: yup.string().max(20),
            office_district_kanji: yup.string().max(40),

            office_district_kana: yup.string().max(30),
            other_office_address_kanji: yup.string().max(99),
            other_office_address_kana: yup.string().max(138),
            office_phone_number: yup
              .string()
              .max(11, '正しくご入力ください。')
              .required()
              .matches(regex.fixedPhones),
            industry: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            other_industry: yup.string().when('industry', {
              is: (industry: WorkIndustryType) =>
                industry === WorkIndustryType.OTHERS,
              then: yup.string().required().max(40),
              otherwise: yup.string(),
            }),
            tax_return: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            type_tax_return: yup.array().when('tax_return', {
              is: (tax_return: FinalTaxReturnType) =>
                tax_return === FinalTaxReturnType.YES,
              then: yup
                .array()
                .min(1, 'お客様に該当する数値を選択してください。')
                .required(),
              otherwise: yup.array(),
            }),
            other_type_tax_return: yup.string().when('type_tax_return', {
              is: (type_tax_return: FileATaxReturnType[]) =>
                type_tax_return.includes(FileATaxReturnType.OTHERS),
              then: yup.string().required(),
              otherwise: yup.string(),
            }),
            maternity_paternity_leave_status: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            maternity_paternity_leave_start_time: yup
              .string()
              .when('maternity_paternity_leave_status', {
                is: (maternity_paternity_leave_status: LeaveType) =>
                  maternity_paternity_leave_status ===
                  LeaveType.SCHEDULED_ACQUISITION,
                then: yup
                  .string()
                  .required('お客様に該当する数値を選択してください。'),
                otherwise: yup.string(),
              }),
            maternity_paternity_leave_end_time: yup
              .string()
              .when('maternity_paternity_leave_status', {
                is: (maternity_paternity_leave_status: LeaveType) =>
                  maternity_paternity_leave_status ===
                  LeaveType.SCHEDULED_ACQUISITION,
                then: yup
                  .string()
                  .required('お客様に該当する数値を選択してください。')
                  .test({
                    message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
                    test(value) {
                      if (!value) return false;
                      const [year, month, day] = value.split('/');
                      const endTime = dayjs(`${year}-${month}-${day}`);

                      return endTime.isAfter(
                        dayjs(this.parent.maternity_paternity_leave_start_time),
                        'date'
                      );
                    },
                  }),
                otherwise: yup.string(),
              }),
            back_to_work_date: yup
              .string()
              .when('maternity_paternity_leave_status', {
                is: (maternity_paternity_leave_status: LeaveType) =>
                  maternity_paternity_leave_status ===
                  LeaveType.SCHEDULED_ACQUISITION,
                then: yup
                  .string()
                  .required('お客様に該当する数値を選択してください。')
                  .test({
                    message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
                    test(value) {
                      if (!value) return false;
                      const [year, month, day] = value.split('/');
                      const endTime = dayjs(`${year}-${month}-${day}`);

                      return endTime.isAfter(
                        dayjs(this.parent.maternity_paternity_leave_end_time),
                        'date'
                      );
                    },
                  }),
                otherwise: yup.string(),
              }),
            nursing_leave_status: yup
              .string()
              .requiredMCJ('お客様に該当する数値を選択してください。'),
            transfer_office: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            transfer_office_name_kanji: yup
              .string()
              .max(48)
              .when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            transfer_office_name_kana: yup
              .string()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveNumber,
                'カタカナでご入力ください。'
              )
              .when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            transfer_office_phone_number: yup.string().when('transfer_office', {
              is: (transfer_office: DispatchType) =>
                transfer_office === DispatchType.YES,
              then: yup
                .string()
                .max(11, '正しくご入力ください。')
                .required()
                .matches(regex.fixedPhones),
              otherwise: yup.string(),
            }),
            transfer_office_postal_code: yup
              .string()
              .min(7, '正しくご入力ください。')
              .matches(regex.zipCode)
              .when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            transfer_office_other_address_kanji: yup
              .string()
              .max(48)

              .when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            transfer_office_other_address_kana: yup
              .string()
              .max(48)
              .when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            job_change: yup
              .string()
              .required('お客様に該当する数値を選択してください。'),
            job_change_company_name_kanji: yup
              .string()
              .max(48)
              .when('job_change', {
                is: (job_change: JobChangeType) =>
                  job_change === JobChangeType.YES,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            job_change_company_name_kana: yup
              .string()
              .max(48)
              .matches(regex.kanaAlphabet, 'カタカナでご入力ください。')
              .when('job_change', {
                is: (job_change: JobChangeType) =>
                  job_change === JobChangeType.YES,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            prev_company_year_num: yup.string().when('job_change', {
              is: (job_change: JobChangeType) =>
                job_change === JobChangeType.YES,
              then: yup.string().required(),
              otherwise: yup.string(),
            }),
            prev_company_month_num: yup.string().when('job_change', {
              is: (job_change: JobChangeType) =>
                job_change === JobChangeType.YES,
              then: yup
                .string()
                .test({
                  message: 'お客様に該当する数値を選択してください。',
                  test() {
                    return !!this.parent.prev_company_year_num;
                  },
                })
                .required(),
              otherwise: yup.string(),
            }),
            prev_company_industry: yup.string().when('job_change', {
              is: (job_change: JobChangeType) =>
                job_change === JobChangeType.YES,
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
              otherwise: yup.string(),
            }),
            other_prev_company_industry: yup
              .string()
              .when('prev_company_industry', {
                is: (prev_company_industry: WorkIndustryType) =>
                  prev_company_industry === WorkIndustryType.OTHERS,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            department: yup.string().required().max(46),
            p_resident_attributes: yup.object().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup.object().shape(
                {
                  last_name_kanji: yup
                    .string()
                    .max(48)

                    .requiredMCJ(),
                  first_name_kanji: yup
                    .string()
                    .max(48)

                    .requiredMCJ(),
                  last_name_kana: yup.string().max(48).requiredMCJ(),
                  first_name_kana: yup.string().max(48).requiredMCJ(),
                  relationship: yup
                    .string()
                    .requiredMCJ('お客様に該当する数値を選択してください。'),
                  other_relationship: yup.string().when('relationship', {
                    is: (relationship: RelationshipType) =>
                      relationship === RelationshipType.OTHERS,
                    then: yup
                      .string()
                      .max(40)
                      .matches(regex.japanName)
                      .required(),
                    otherwise: yup.string(),
                  }),
                  nationality: yup
                    .string()
                    .requiredMCJ('お客様に該当する数値を選択してください。'),
                  birthday: yup
                    .string()
                    .requiredMCJ('お客様に該当する数値を選択してください。'),
                  mobile_phone_number: yup
                    .string()
                    .max(11, '正しくご入力ください。')
                    .matches(regex.mobilePhone)
                    .when('home_phone_number', {
                      is: (homePhone: string) =>
                        !homePhone || homePhone.length === 0,
                      then: yup.string().requiredMCJ(),
                      otherwise: yup.string(),
                    }),
                  home_phone_number: yup
                    .string()
                    .max(11, '正しくご入力ください。')
                    .matches(regex.fixedPhones)
                    .when('mobile_phone_number', {
                      is: (phoneNumber: string) =>
                        !phoneNumber || phoneNumber.length === 0,
                      then: yup.string().requiredMCJ(),
                      otherwise: yup.string(),
                    }),
                  postal_code: yup
                    .string()
                    .min(7, '正しくご入力ください。')
                    .matches(regex.zipCode)
                    .noWhitespace()
                    .requiredMCJ(),
                  prefecture_kanji: yup.string().requiredMCJ().max(20),
                  city_kanji: yup.string().requiredMCJ().max(20),
                  district_kanji: yup.string().requiredMCJ().max(40),
                  other_address_kanji: yup.string().requiredMCJ().max(99),
                  prefecture_kana: yup.string().requiredMCJ().max(20),
                  city_kana: yup.string().requiredMCJ().max(20),
                  district_kana: yup
                    .string()
                    .requiredMCJ()
                    .max(30)
                    .matches(regex.kanaHalfwidthHaveNumber),
                  other_address_kana: yup.string().requiredMCJ().max(138),
                  loan_from_japan_housing_finance_agency: yup
                    .string()
                    .requiredMCJ('お客様に該当する数値を選択してください。'),
                },
                [['home_phone_number', 'mobile_phone_number']]
              ),
            }),
            employment_started_date: yup
              .string()
              .required('お客様に該当する数値を選択してください。')
              .test('validateDate', '正しく選択してください。', (value) => {
                if (!value) return false;
                const [year, month] = value.split('/');
                return !(!year || !month);
              })
              .test('not-pass-current', '無効な日付', (value) => {
                if (!value) return false;
                const [year, month] = value.split('/');
                const currentMonth = dayjs().month() + 1;
                const currentYear = dayjs().year();
                return !(+year === currentYear && +month >= currentMonth);
              }),
            lived_length_year_num: yup.string().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup.string().required(),
            }),
            lived_length_month_num: yup.string().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup
                .string()
                .test({
                  message: 'お客様に該当する数値を選択してください。',
                  test() {
                    return !!this.parent.lived_length_year_num;
                  },
                })
                .required('お客様に該当する数値を選択してください。'),
            }),
            reason_acquire_home: yup.string().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
            }),
            other_reason_acquire_home: yup
              .string()
              .when('reason_acquire_home', {
                is: (reason_acquire_home: ReasonsForNeedingHousingType) =>
                  reason_acquire_home === ReasonsForNeedingHousingType.OTHERS,
                then: yup.string().required(),
                otherwise: yup.string(),
              }),
            business_ability: yup.string().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
            }),
            resident_status: yup.string().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
            }),
            non_resident_reason: yup.string().when('resident_status', {
              is: (resident_status: ResidentStatusType) =>
                resident_status ===
                ResidentStatusType.THE_PERSON_DOES_NOT_RESIDE_OTHER,
              then: yup.string().max(40).matches(regex.japanName).required(),
            }),
            planned_cohabitant: yup.array().when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.PAIR,
              then: yup
                .array()
                .min(1, 'お客様に該当する数値を選択してください。')
                .required(),
            }),
            children_number: yup.string().when('planned_cohabitant', {
              is: (planned_cohabitant: PlannedCohabitantType[]) =>
                planned_cohabitant.includes(PlannedCohabitantType.CHILD),
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
              otherwise: yup.string(),
            }),
            siblings_number: yup.string().when('planned_cohabitant', {
              is: (planned_cohabitant: PlannedCohabitantType[]) =>
                planned_cohabitant.includes(
                  PlannedCohabitantType.BROTHERS_AND_SISTERS
                ),
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
              otherwise: yup.string(),
            }),
            other_relationship: yup.string().when('planned_cohabitant', {
              is: (planned_cohabitant: PlannedCohabitantType[]) =>
                planned_cohabitant.includes(PlannedCohabitantType.OTHERS),
              then: yup.string().max(40).matches(regex.japanName).required(),
              otherwise: yup.string(),
            }),
            other_people_number: yup.string().when('planned_cohabitant', {
              is: (planned_cohabitant: PlannedCohabitantType[]) =>
                planned_cohabitant.includes(PlannedCohabitantType.OTHERS),
              then: yup
                .string()
                .required('お客様に該当する数値を選択してください。'),
              otherwise: yup.string(),
            }),
          },
          [['mobile_phone_number', 'home_phone_number']]
        ),
        otherwise: yup.object(),
      }),

    p_referral_agency: yup.object().shape({
      name_kanji: yup.string().max(48),
      sale_agent_name_kanji: yup.string().max(48),
      store_name_kanji: yup.string().max(48),
      manager_name_kanji: yup.string().max(40),
      phone_number_2: yup
        .string()
        .max(11, '正しくご入力ください。')
        .matches(regex.fixedPhones),
      phone_number: yup
        .string()
        .max(11, '正しくご入力ください。')
        .matches(regex.mobilePhone),
      manager_email: yup
        .string()
        .matches(regex.email)
        .label('担当者メールアドレス'),
    }),
  },
  []
);
