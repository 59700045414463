import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep07: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="25"
        height="21"
        viewBox="0 0 25 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.25 12C7.25 9.09844 9.59844 6.75 12.5 6.75C15.4016 6.75 17.75 9.09844 17.75 12C17.75 14.9016 15.4016 17.25 12.5 17.25C9.59844 17.25 7.25 14.9016 7.25 12ZM12.5 7.5C9.97344 7.5 8 9.47344 8 12C8 14.4844 9.97344 16.5 12.5 16.5C14.9844 16.5 17 14.4844 17 12C17 9.47344 14.9844 7.5 12.5 7.5ZM17.5109 1.53844L17.9984 3H21.5C23.1547 3 24.5 4.34531 24.5 6V18C24.5 19.6547 23.1547 21 21.5 21H3.5C1.84297 21 0.5 19.6547 0.5 18V6C0.5 4.34531 1.84297 3 3.5 3H7.00156L7.48906 1.53844C7.79375 0.619687 8.65156 0 9.62188 0H15.3781C16.3484 0 17.2062 0.619687 17.5109 1.53844ZM3.5 3.75C2.25734 3.75 1.25 4.75781 1.25 6V18C1.25 19.2422 2.25734 20.25 3.5 20.25H21.5C22.7422 20.25 23.75 19.2422 23.75 18V6C23.75 4.75781 22.7422 3.75 21.5 3.75H17.4594L16.8031 1.77562C16.5969 1.16297 16.025 0.75 15.3781 0.75H9.62188C8.975 0.75 8.40312 1.16297 8.19687 1.77562L7.54062 3.75H3.5Z"
          fill="url(#paint0_linear_7977_19351)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7977_19351"
            x1="4.19582"
            y1="16.6811"
            x2="20.0791"
            y2="-1.65434"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
