import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PasswordHide: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0002 3.41673C12.3002 3.41673 14.1668 5.2834 14.1668 7.5834C14.1668 8.0084 14.0835 8.41673 13.9668 8.80007L16.5168 11.3501C17.6752 10.3251 18.5918 9.04173 19.1668 7.57506C17.7252 3.92506 14.1668 1.3334 10.0002 1.3334C8.94183 1.3334 7.92516 1.50007 6.96683 1.8084L8.77516 3.61673C9.16683 3.50006 9.57516 3.41673 10.0002 3.41673ZM2.2585 0.633398C1.9335 0.958398 1.9335 1.4834 2.2585 1.8084L3.90016 3.45006C2.55016 4.52506 1.47516 5.94173 0.833496 7.5834C2.27516 11.2417 5.8335 13.8334 10.0002 13.8334C11.2668 13.8334 12.4752 13.5834 13.5918 13.1501L15.8585 15.4167C16.1835 15.7417 16.7085 15.7417 17.0335 15.4167C17.3585 15.0917 17.3585 14.5667 17.0335 14.2417L3.44183 0.633398C3.11683 0.308398 2.5835 0.308398 2.2585 0.633398ZM10.0002 11.7501C7.70016 11.7501 5.8335 9.8834 5.8335 7.5834C5.8335 6.94173 5.9835 6.3334 6.24183 5.80006L7.55016 7.1084C7.52516 7.2584 7.50016 7.41673 7.50016 7.5834C7.50016 8.96673 8.61683 10.0834 10.0002 10.0834C10.1668 10.0834 10.3168 10.0584 10.4752 10.0251L11.7835 11.3334C11.2418 11.6001 10.6418 11.7501 10.0002 11.7501ZM12.4752 7.3084C12.3502 6.14173 11.4335 5.2334 10.2752 5.1084L12.4752 7.3084Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
