import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogoNew: FC<SvgIconProps> = (props) => (
  <SvgIcon
    sx={{
      width: 102,
      height: 17,
    }}
    viewBox="0 0 102 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="102"
      height="17"
      viewBox="0 0 102 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5912 5.72768C15.2045 6.38231 15.5096 7.29434 15.5096 8.4606V15.4486C15.5096 15.5186 15.4842 15.5789 15.4365 15.6266C15.3888 15.6743 15.3284 15.6997 15.2585 15.6997H13.3359C13.266 15.6997 13.2057 15.6743 13.158 15.6266C13.1071 15.5789 13.0849 15.5186 13.0849 15.4486V8.96588C13.0849 8.3176 12.9133 7.80597 12.5669 7.42463C12.2205 7.04329 11.7597 6.8558 11.1846 6.8558C10.6094 6.8558 10.1359 7.04647 9.77043 7.42463C9.40498 7.80597 9.22066 8.31125 9.22066 8.94363V15.4455C9.22066 15.5154 9.19524 15.5758 9.14758 15.6234C9.09991 15.6711 9.03953 15.6965 8.96962 15.6965H7.04703C6.97712 15.6965 6.91674 15.6711 6.86908 15.6234C6.81823 15.5758 6.79599 15.5154 6.79599 15.4455V8.9627C6.79599 8.31442 6.6212 7.80279 6.26847 7.42146C5.91573 7.04012 5.45177 6.85263 4.87658 6.85263C4.3427 6.85263 3.90099 7.00834 3.55778 7.31659C3.2114 7.62484 3.0112 8.04749 2.95717 8.58454V15.4455C2.95717 15.5154 2.93175 15.5758 2.88408 15.6234C2.83642 15.6711 2.77604 15.6965 2.70612 15.6965H0.764474C0.694562 15.6965 0.634184 15.6711 0.586516 15.6234C0.535671 15.5758 0.513428 15.5154 0.513428 15.4455V5.16838C0.513428 5.09847 0.538849 5.03809 0.586516 4.99042C0.634184 4.94275 0.694562 4.91733 0.764474 4.91733H2.70612C2.77604 4.91733 2.83642 4.94275 2.88408 4.99042C2.93175 5.04127 2.95717 5.09847 2.95717 5.16838V5.86432C2.95717 5.90563 2.96989 5.93423 2.99849 5.95012C3.02709 5.96283 3.05569 5.95012 3.08429 5.90881C3.39254 5.51476 3.77705 5.2224 4.23466 5.03173C4.69227 4.84106 5.20072 4.74573 5.76637 4.74573C6.45596 4.74573 7.05975 4.88555 7.58091 5.16838C8.10207 5.4512 8.50248 5.85797 8.7853 6.39184C8.82662 6.44904 8.87746 6.44904 8.93466 6.39184C9.24291 5.82937 9.67509 5.41307 10.2217 5.14613C10.7714 4.8792 11.3816 4.74573 12.0585 4.74573C13.1421 4.74573 13.9906 5.07304 14.6039 5.72768H14.5912Z"
        fill="#212121"
      />
      <path
        d="M18.1093 3.01679C17.8265 2.73396 17.6867 2.37805 17.6867 1.93951C17.6867 1.50097 17.8265 1.14505 18.1093 0.862226C18.3922 0.579399 18.7513 0.439575 19.1866 0.439575C19.622 0.439575 19.9811 0.579399 20.2639 0.862226C20.5467 1.14505 20.6866 1.50415 20.6866 1.93951C20.6866 2.37487 20.5467 2.73396 20.2639 3.01679C19.9811 3.29962 19.622 3.43944 19.1866 3.43944C18.7513 3.43944 18.3922 3.29962 18.1093 3.01679ZM17.9918 15.6296C17.9409 15.5819 17.9187 15.5215 17.9187 15.4516V5.14911C17.9187 5.0792 17.9441 5.01882 17.9918 4.97115C18.0394 4.92349 18.0998 4.89806 18.1697 4.89806H20.1114C20.1813 4.89806 20.2417 4.92349 20.2893 4.97115C20.337 5.022 20.3624 5.0792 20.3624 5.14911V15.4516C20.3624 15.5215 20.337 15.5819 20.2893 15.6296C20.2385 15.6804 20.1813 15.7027 20.1114 15.7027H18.1697C18.0998 15.7027 18.0394 15.6772 17.9918 15.6296Z"
        fill="#212121"
      />
      <path
        d="M23.0382 15.6294C22.9874 15.5818 22.9652 15.5214 22.9652 15.4515V1.17988C22.9652 1.10997 22.9906 1.04959 23.0382 1.00192C23.0859 0.954255 23.1463 0.928833 23.2162 0.928833H25.1579C25.2278 0.928833 25.2881 0.954255 25.3358 1.00192C25.3835 1.05277 25.4089 1.10997 25.4089 1.17988V15.4515C25.4089 15.5214 25.3835 15.5818 25.3358 15.6294C25.2881 15.6771 25.2278 15.7025 25.1579 15.7025H23.2162C23.1463 15.7025 23.0859 15.6771 23.0382 15.6294Z"
        fill="#212121"
      />
      <path
        d="M28.1127 3.01679C27.8299 2.73396 27.69 2.37805 27.69 1.93951C27.69 1.50097 27.8299 1.14505 28.1127 0.862226C28.3955 0.579399 28.7546 0.439575 29.19 0.439575C29.6253 0.439575 29.9844 0.579399 30.2673 0.862226C30.5501 1.14505 30.6899 1.50415 30.6899 1.93951C30.6899 2.37487 30.5501 2.73396 30.2673 3.01679C29.9844 3.29962 29.6253 3.43944 29.19 3.43944C28.7546 3.43944 28.3955 3.29962 28.1127 3.01679ZM27.9951 15.6296C27.9443 15.5819 27.922 15.5215 27.922 15.4516V5.14911C27.922 5.0792 27.9475 5.01882 27.9951 4.97115C28.0428 4.92349 28.1032 4.89806 28.1731 4.89806H30.1147C30.1846 4.89806 30.245 4.92349 30.2927 4.97115C30.3404 5.022 30.3658 5.0792 30.3658 5.14911V15.4516C30.3658 15.5215 30.3404 15.5819 30.2927 15.6296C30.2418 15.6804 30.1846 15.7027 30.1147 15.7027H28.1731C28.1032 15.7027 28.0428 15.6772 27.9951 15.6296Z"
        fill="#212121"
      />
      <path
        d="M32.3265 15.6299C32.2756 15.5823 32.2534 15.5219 32.2534 15.452V13.6565C32.2534 13.5294 32.2883 13.4309 32.3583 13.361L37.5508 7.25953C37.6207 7.17373 37.5985 7.13242 37.4873 7.13242H32.6093C32.5394 7.13242 32.479 7.107 32.4313 7.05933C32.3805 7.01166 32.3583 6.95128 32.3583 6.88137V5.1717C32.3583 5.10179 32.3837 5.04141 32.4313 4.99374C32.479 4.94608 32.5394 4.92065 32.6093 4.92065H40.2933C40.3632 4.92065 40.4236 4.94608 40.4712 4.99374C40.5189 5.04459 40.5443 5.10179 40.5443 5.1717V6.96717C40.5443 7.0784 40.503 7.17691 40.4172 7.26271L35.1166 13.3641C35.0594 13.4499 35.0816 13.4912 35.1802 13.4912H40.3537C40.4236 13.4912 40.4839 13.5167 40.5316 13.5643C40.5793 13.6152 40.6047 13.6724 40.6047 13.7423V15.452C40.6047 15.5219 40.5793 15.5823 40.5316 15.6299C40.4839 15.6776 40.4236 15.703 40.3537 15.703H32.5013C32.4313 15.703 32.371 15.6776 32.3233 15.6299H32.3265Z"
        fill="#212121"
      />
      <path
        d="M51.1269 10.2783L51.1047 10.933C51.1047 11.1014 51.0189 11.1872 50.8504 11.1872H44.2437C44.1738 11.1872 44.1389 11.2221 44.1389 11.292C44.1802 11.756 44.2437 12.0738 44.3295 12.2422C44.6664 13.2559 45.4894 13.7707 46.7987 13.7835C47.7552 13.7835 48.5147 13.3894 49.0772 12.6013C49.1344 12.5155 49.2043 12.4742 49.2869 12.4742C49.3441 12.4742 49.3918 12.4964 49.4331 12.5378L50.7424 13.615C50.8695 13.7135 50.8886 13.8248 50.8059 13.9519C50.3706 14.5716 49.789 15.0482 49.0645 15.3883C48.3399 15.7251 47.5328 15.8935 46.6462 15.8935C45.5498 15.8935 44.6155 15.6488 43.8497 15.1531C43.0838 14.6605 42.5214 13.971 42.1718 13.0843C41.8477 12.3248 41.6856 11.2698 41.6856 9.91605C41.6856 9.09935 41.7714 8.43836 41.9398 7.93309C42.2226 6.94796 42.7533 6.16939 43.5351 5.60056C44.3168 5.03173 45.2543 4.74573 46.3538 4.74573C49.1249 4.74573 50.6884 6.19482 51.0411 9.09617C51.0983 9.44891 51.1269 9.84296 51.1269 10.2783ZM45.0573 7.24985C44.7109 7.51044 44.4694 7.87271 44.3295 8.33667C44.2437 8.57501 44.1897 8.88643 44.1611 9.26459C44.1325 9.33451 44.1611 9.36946 44.2469 9.36946H48.5338C48.6037 9.36946 48.6387 9.33451 48.6387 9.26459C48.6101 8.91186 48.5688 8.64492 48.5116 8.46378C48.3844 7.95851 48.1334 7.56446 47.7616 7.28163C47.3898 6.99881 46.9195 6.85898 46.357 6.85898C45.8358 6.85898 45.4036 6.98927 45.0573 7.24985Z"
        fill="#212121"
      />
      <path
        d="M62.7011 0.200195C63.9799 0.200195 65.003 0.621446 65.7702 1.46395C66.5526 2.2914 66.9437 3.38966 66.9437 4.75873C66.9437 6.0977 66.5526 7.18092 65.7702 8.00838C65.003 8.82079 63.9799 9.22699 62.7011 9.22699H59.6771C59.617 9.22699 59.5869 9.25708 59.5869 9.31726V15.794C59.5869 15.9444 59.5117 16.0197 59.3612 16.0197H57.6461C57.4957 16.0197 57.4204 15.9444 57.4204 15.794V0.425865C57.4204 0.275419 57.4957 0.200195 57.6461 0.200195H62.7011ZM62.3852 7.48934C63.1073 7.48934 63.6865 7.24862 64.1228 6.76719C64.5742 6.27072 64.7999 5.61627 64.7999 4.80386C64.7999 3.97641 64.5742 3.31444 64.1228 2.81797C63.6865 2.32149 63.1073 2.07326 62.3852 2.07326H59.6771C59.617 2.07326 59.5869 2.10335 59.5869 2.16352V7.39907C59.5869 7.45925 59.617 7.48934 59.6771 7.48934H62.3852Z"
        fill="#6671E1"
      />
      <path
        d="M72.1129 4.35252C73.3616 4.35252 74.3621 4.70607 75.1143 5.41317C75.8665 6.10523 76.2426 7.01543 76.2426 8.14378V15.794C76.2426 15.9444 76.1674 16.0197 76.017 16.0197H74.3019C74.1514 16.0197 74.0762 15.9444 74.0762 15.794V15.1395C74.0762 15.1095 74.0612 15.0944 74.0311 15.0944C74.016 15.0794 73.9935 15.0869 73.9634 15.117C73.2864 15.8391 72.3461 16.2002 71.1425 16.2002C70.1646 16.2002 69.3296 15.9369 68.6376 15.4104C67.9455 14.8687 67.5995 14.0037 67.5995 12.8151C67.5995 11.5815 67.9982 10.6487 68.7955 10.0168C69.6079 9.36992 70.7062 9.04646 72.0903 9.04646H73.9859C74.0461 9.04646 74.0762 9.01637 74.0762 8.95619V8.32431C74.0762 7.67739 73.9032 7.16588 73.5572 6.78976C73.2262 6.41364 72.7448 6.22558 72.1129 6.22558C71.6164 6.22558 71.2027 6.35346 70.8717 6.60922C70.5558 6.84994 70.3602 7.18092 70.285 7.60217C70.2699 7.73757 70.1872 7.80527 70.0367 7.80527L68.1862 7.69244C68.126 7.69244 68.0734 7.67739 68.0283 7.6473C67.9831 7.60217 67.9681 7.54951 67.9831 7.48934C68.0734 6.55657 68.4946 5.80433 69.2469 5.23264C70.0141 4.64589 70.9695 4.35252 72.1129 4.35252ZM71.6164 14.3497C72.2784 14.3497 72.8501 14.1616 73.3315 13.7855C73.828 13.4094 74.0762 12.9054 74.0762 12.2735V10.8293C74.0762 10.7691 74.0461 10.739 73.9859 10.739H72.2483C71.5111 10.739 70.9168 10.9045 70.4655 11.2355C70.0292 11.5664 69.811 12.0479 69.811 12.6797C69.811 13.2364 69.9765 13.6576 70.3075 13.9435C70.6385 14.2143 71.0748 14.3497 71.6164 14.3497Z"
        fill="#6671E1"
      />
      <path
        d="M83.0793 4.37509C84.1775 4.37509 85.0275 4.71359 85.6293 5.3906C86.2462 6.06761 86.5546 7.00038 86.5546 8.18891V15.794C86.5546 15.9444 86.4794 16.0197 86.3289 16.0197H84.6138C84.4634 16.0197 84.3881 15.9444 84.3881 15.794V8.50485C84.3881 7.8128 84.2076 7.26367 83.8465 6.85746C83.5005 6.45125 83.0266 6.24815 82.4248 6.24815C81.808 6.24815 81.3115 6.45878 80.9354 6.88003C80.5743 7.28623 80.3938 7.83536 80.3938 8.52742V15.794C80.3938 15.9444 80.3186 16.0197 80.1681 16.0197H78.453C78.3026 16.0197 78.2274 15.9444 78.2274 15.794V4.75873C78.2274 4.60828 78.3026 4.53306 78.453 4.53306H80.1681C80.3186 4.53306 80.3938 4.60828 80.3938 4.75873V5.45831C80.3938 5.50344 80.4013 5.53353 80.4164 5.54857C80.4464 5.54857 80.4765 5.52601 80.5066 5.48087C81.0934 4.74368 81.9509 4.37509 83.0793 4.37509Z"
        fill="#6671E1"
      />
      <path
        d="M92.4897 14.3271C93.0012 14.3271 93.43 14.2068 93.776 13.9661C94.1371 13.7103 94.4079 13.3643 94.5884 12.928C94.6336 12.7926 94.7238 12.7399 94.8592 12.77L96.3938 13.1085C96.5442 13.1537 96.5969 13.2439 96.5518 13.3793C96.2659 14.267 95.7694 14.959 95.0623 15.4555C94.3552 15.952 93.4902 16.2002 92.4671 16.2002C91.2936 16.2002 90.3458 15.9068 89.6237 15.3201C88.9015 14.7333 88.4427 13.9285 88.2471 12.9054C88.1719 12.4842 88.1192 12.0403 88.0891 11.574C88.0741 11.0925 88.0666 10.4607 88.0666 9.67833C88.0666 8.91106 88.1042 8.30175 88.1794 7.85041C88.3148 6.79728 88.7586 5.95478 89.5109 5.3229C90.2631 4.67598 91.2109 4.35252 92.3543 4.35252C93.603 4.35252 94.611 4.70607 95.3783 5.41317C96.1455 6.12027 96.5743 7.05304 96.6646 8.21148C96.7097 8.723 96.7323 9.66329 96.7323 11.0324C96.7323 11.1828 96.6571 11.258 96.5066 11.258H90.3233C90.2631 11.258 90.233 11.2881 90.233 11.3483C90.233 11.7695 90.2556 12.1381 90.3007 12.4541C90.3759 13.0107 90.6091 13.4621 91.0003 13.8081C91.3914 14.1541 91.8879 14.3271 92.4897 14.3271ZM92.3769 6.22558C91.8052 6.22558 91.3313 6.3986 90.9551 6.74462C90.5941 7.09065 90.3759 7.54199 90.3007 8.09865C90.2556 8.50485 90.233 8.94867 90.233 9.4301C90.233 9.49028 90.2631 9.52037 90.3233 9.52037H94.4756C94.5358 9.52037 94.5659 9.49028 94.5659 9.4301C94.5358 8.82831 94.5057 8.39202 94.4756 8.12121C94.4004 7.56456 94.1747 7.11322 93.7986 6.76719C93.4225 6.40612 92.9486 6.22558 92.3769 6.22558Z"
        fill="#6671E1"
      />
      <path
        d="M99.1394 16.1701C98.989 16.1701 98.9138 16.0949 98.9138 15.9444V0.598878C98.9138 0.448432 98.989 0.373208 99.1394 0.373208H100.855C101.005 0.373208 101.08 0.448432 101.08 0.598878V15.9444C101.08 16.0949 101.005 16.1701 100.855 16.1701H99.1394Z"
        fill="#6671E1"
      />
    </svg>
  </SvgIcon>
);
