import { RootState } from 'types';
import { createSelector } from 'reselect';
import { DynamicOptions } from 'services';
import {
  borrowingFromHousingFinanceAgency,
  BorrowingFromHousingFinanceAgencyType,
  borrowingStatusAdmin,
  BorrowingStatusAdminType,
  commonHousing,
  CommonHousingType,
  CoOwnerType,
  currentHomeLoan,
  CurrentHomeLoanType,
  estateMortgage,
  EstateMortgageType,
  financeAgency,
  FinanceAgencyType,
  joinGuarantor,
  loanTargetZeroAdmin,
  LoanTargetZeroAdminType,
  mainIncomeSource,
  oneRoof,
  OneRoofType,
  PositionType,
  positionType,
  SourceOfIncomeType,
  SP_FIELDS_TITLE,
} from 'constant';
import { makeArrayFromNumber } from '../AdminEditPreliminary/utils';

export const masterBankSelectors = (state: RootState) => state.masterBank.form;
export const dynamicOptionsSelectors = (state: RootState) =>
  state.dynamic_options.form;

export const plannedCohabitantSelectors = createSelector(
  dynamicOptionsSelectors,
  (state: DynamicOptions) => state.planned_cohabitant
);

export const adminDynamicOptionsSelectors = createSelector(
  masterBankSelectors,
  dynamicOptionsSelectors,
  (masterBank, dynamicOptions): DynamicOptions => ({
    master_bank_ids: {
      field_name_ja: SP_FIELDS_TITLE['master_bank_ids'],
      // @ts-ignore
      value: masterBank.map(({ name, id }) => ({
        option_name: name,
        option_code: +id,
        sort_order: id,
      })),
    },
    loan_target_zero: {
      field_name_ja: SP_FIELDS_TITLE['loan_target_zero'],
      value: [
        {
          sort_order: 0,
          option_code: +LoanTargetZeroAdminType.ZERO,
          option_name: loanTargetZeroAdmin[LoanTargetZeroAdminType.ZERO],
        },
        {
          sort_order: 1,
          option_code: +LoanTargetZeroAdminType.SEVEN,
          option_name: loanTargetZeroAdmin[LoanTargetZeroAdminType.SEVEN],
        },
        {
          sort_order: 2,
          option_code: +LoanTargetZeroAdminType.EIGHT,
          option_name: loanTargetZeroAdmin[LoanTargetZeroAdminType.EIGHT],
        },
      ],
    },
    borrowing_from_housing_finance_agency: {
      field_name_ja: SP_FIELDS_TITLE['borrowing_from_housing_finance_agency'],
      value: [
        {
          option_name:
            borrowingFromHousingFinanceAgency[
              BorrowingFromHousingFinanceAgencyType.APPLICABLE
            ],
          option_code: +BorrowingFromHousingFinanceAgencyType.APPLICABLE,
          sort_order: 0,
        },
        {
          option_name:
            borrowingFromHousingFinanceAgency[
              BorrowingFromHousingFinanceAgencyType.NOT_APPLICABLE
            ],
          option_code: +BorrowingFromHousingFinanceAgencyType.NOT_APPLICABLE,
          sort_order: 0,
        },
      ],
    },
    one_roof: {
      field_name_ja: SP_FIELDS_TITLE['one_roof'],
      value: [
        {
          option_name: oneRoof[OneRoofType.NO],
          option_code: +OneRoofType.NO,
          sort_order: 0,
        },
        {
          option_name: oneRoof[OneRoofType.YES],
          option_code: +OneRoofType.YES,
          sort_order: 0,
        },
      ],
    },
    common_housing: {
      field_name_ja: SP_FIELDS_TITLE['common_housing'],
      value: [
        {
          option_name: commonHousing[CommonHousingType.APPLICABLE],
          option_code: +CommonHousingType.APPLICABLE,
          sort_order: 0,
        },
        {
          option_name: commonHousing[CommonHousingType.NOT_APPLICABLE],
          option_code: +CommonHousingType.NOT_APPLICABLE,
          sort_order: 0,
        },
      ],
    },
    estate_mortgage: {
      field_name_ja: SP_FIELDS_TITLE['estate_mortgage'],
      value: [
        {
          option_name: estateMortgage[EstateMortgageType.SECURED],
          option_code: +EstateMortgageType.SECURED,
          sort_order: 0,
        },
        {
          option_name: estateMortgage[EstateMortgageType.UNSECURED],
          option_code: +EstateMortgageType.UNSECURED,
          sort_order: 0,
        },
      ],
    },
    current_home_loan: {
      field_name_ja: SP_FIELDS_TITLE['current_home_loan'],
      value: [
        {
          option_name: currentHomeLoan[CurrentHomeLoanType.YES],
          option_code: +CurrentHomeLoanType.YES,
          sort_order: 0,
        },
        {
          option_name: currentHomeLoan[CurrentHomeLoanType.NO],
          option_code: +CurrentHomeLoanType.NO,
          sort_order: 0,
        },
      ],
    },
    loan_from_japan_housing_finance_agency: {
      field_name_ja: SP_FIELDS_TITLE['loan_from_japan_housing_finance_agency'],
      value: [
        {
          option_name: financeAgency[FinanceAgencyType.YES],
          option_code: +FinanceAgencyType.YES,
          sort_order: 0,
        },
        {
          option_name: financeAgency[FinanceAgencyType.NO],
          option_code: +FinanceAgencyType.NO,
          sort_order: 0,
        },
      ],
    },
    position: {
      field_name_ja: SP_FIELDS_TITLE['position'],
      value: [
        {
          option_name: positionType[PositionType.ONE],
          option_code: +PositionType.ONE,
          sort_order: 1,
        },
        {
          option_name: positionType[PositionType.TWO],
          option_code: +PositionType.TWO,
          sort_order: 2,
        },
        {
          option_name: positionType[PositionType.THREE],
          option_code: +PositionType.THREE,
          sort_order: 3,
        },
        {
          option_name: positionType[PositionType.FOUR],
          option_code: +PositionType.FOUR,
          sort_order: 4,
        },
        {
          option_name: positionType[PositionType.FIVE],
          option_code: +PositionType.FIVE,
          sort_order: 5,
        },
        {
          option_name: positionType[PositionType.SIX],
          option_code: +PositionType.SIX,
          sort_order: 6,
        },
        {
          option_name: positionType[PositionType.SEVEN],
          option_code: +PositionType.SEVEN,
          sort_order: 7,
        },
      ],
    },
    lived_length_month_num: {
      field_name_ja: SP_FIELDS_TITLE['lived_length_month_num'],
      value: makeArrayFromNumber(12).map((val, index) => ({
        option_name: index.toString(),
        option_code: index,
        sort_order: index,
      })),
    },
    borrowing_status: {
      field_name_ja: SP_FIELDS_TITLE['borrowing_status'],
      value: [
        {
          option_name: borrowingStatusAdmin[BorrowingStatusAdminType.NO],
          option_code: +BorrowingStatusAdminType.NO,
          sort_order: 1,
        },
        {
          option_name: borrowingStatusAdmin[BorrowingStatusAdminType.YES],
          option_code: +BorrowingStatusAdminType.YES,
          sort_order: 2,
        },
      ],
    },
    main_income_source: {
      field_name_ja: '収入源（銀行送信用）',
      value: [
        {
          option_name: mainIncomeSource[SourceOfIncomeType.SALARY_FIXED_SALARY],
          option_code: +SourceOfIncomeType.SALARY_FIXED_SALARY,
          sort_order: 1,
        },
        {
          option_name:
            mainIncomeSource[SourceOfIncomeType.SALARY_COMMISSION_PAY],
          option_code: +SourceOfIncomeType.SALARY_COMMISSION_PAY,
          sort_order: 2,
        },
        {
          option_name:
            mainIncomeSource[SourceOfIncomeType.SALARY_ANNUAL_SALARY_SYSTEM],
          option_code: +SourceOfIncomeType.SALARY_ANNUAL_SALARY_SYSTEM,
          sort_order: 3,
        },
        {
          option_name: mainIncomeSource[SourceOfIncomeType.BUSINESS_INCOME],
          option_code: +SourceOfIncomeType.BUSINESS_INCOME,
          sort_order: 4,
        },
        {
          option_name: mainIncomeSource[SourceOfIncomeType.REAL_ESTATE_INCOME],
          option_code: +SourceOfIncomeType.REAL_ESTATE_INCOME,
          sort_order: 5,
        },
      ],
    },
    has_join_guarantor: {
      field_name_ja: '担保提供者がいる方のみ、チェックをつけてください。',
      value: [
        {
          option_code: +CoOwnerType.CANBE,
          option_name: joinGuarantor[CoOwnerType.CANBE],
          sort_order: 1,
        },
        {
          option_code: +CoOwnerType.NONE,
          option_name: joinGuarantor[CoOwnerType.NONE],
          sort_order: 2,
        },
      ],
    },
    ...dynamicOptions,
  })
);
