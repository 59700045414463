import { FC, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  typographyClasses,
} from '@mui/material';
import { AuthPopover } from 'components';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import {
  calendarPickerClasses,
  pickersCalendarHeaderClasses,
  PickersDay,
  pickersDayClasses,
  pickersSlideTransitionClasses,
} from '@mui/x-date-pickers';
import { StaticDatePickerProps } from 'formik-mui-lab';
import { useAllPublicHoliday } from 'hooks';
import { PulldownBtn } from '../PulldownBtn';

export type PullDownProps = {
  dynamicValue: string;
  onChange: (value: string) => void;
  options?: {
    views?: StaticDatePickerProps['views'];
    format?: string;
    minDate?: string;
    maxDate?: string;
  };
};

export const PulldownDate: FC<PullDownProps> = ({
  dynamicValue,
  onChange,
  options,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [nextYear, setNextYear] = useState<number | undefined>(undefined);
  const publicHolidays = useAllPublicHoliday(nextYear ? +nextYear : undefined);

  useEffect(() => {
    setNextYear(+dayjs(dynamicValue || undefined).format('YYYY'));
  }, [dynamicValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ja}>
      <AuthPopover
        arrow={false}
        sx={{
          width: 146,
          border: (theme) => `1px solid ${theme?.palette?.line}`,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          overflow: 'hidden',
        }}
        content={
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <WrapperStaticDatePickerStyled>
              <StaticDatePicker
                views={options?.views}
                displayStaticWrapperAs="desktop"
                onMonthChange={(value) => {
                  if (options?.views) {
                    onChange(
                      value?.format(options?.format ?? 'YYYY/MM/DD') ?? ''
                    );
                    setOpen(false);
                  } else {
                    return;
                  }
                }}
                components={{
                  LeftArrowButton(props) {
                    return (
                      <IconButton
                        {...props}
                        onClick={() => {
                          setNextYear((prevState) =>
                            prevState ? --prevState : prevState
                          );
                          props.onClick();
                        }}
                      />
                    );
                  },
                  RightArrowButton(props) {
                    return (
                      <IconButton
                        {...props}
                        onClick={() => {
                          setNextYear((prevState) =>
                            prevState ? ++prevState : prevState
                          );
                          props.onClick();
                        }}
                      />
                    );
                  },
                }}
                value={dynamicValue ? dayjs(dynamicValue) : undefined}
                minDate={options?.minDate ? dayjs(options.minDate) : undefined}
                maxDate={options?.maxDate ? dayjs(options.maxDate) : undefined}
                onChange={(value) => {
                  onChange(
                    value?.format(options?.format ?? 'YYYY/MM/DD') ?? ''
                  );
                  setOpen(false);
                }}
                renderDay={(day, _value, DayComponentProps) => {
                  const year = day.format('YYYY');
                  const isPublicHoliday = !!publicHolidays?.[+year]?.find(
                    (o) => o.date === day.format('YYYY-MM-DD')
                  );
                  return (
                    <PickersDay
                      {...DayComponentProps}
                      className={isPublicHoliday ? 'isPublicHoliday' : ''}
                      sx={{ borderRadius: 0 }}
                    />
                  );
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField {...params} sx={{ width: 280 }} />
                )}
              />
            </WrapperStaticDatePickerStyled>
          </ClickAwayListener>
        }
        open={open}
        placement="bottom-start"
        contentProps={{
          height: 'auto',
          padding: 0,
          color: 'required_chip_label',
        }}
      >
        <Stack direction="row" alignItems="center">
          <PulldownBtn
            open={open}
            onClick={() => setOpen((prevState) => !prevState)}
          />
        </Stack>
      </AuthPopover>
    </LocalizationProvider>
  );
};

const DATE_SIZE = 20;

const WrapperStaticDatePickerStyled = styled(Box)(({ theme }) => ({
  '& .MuiCalendarOrClockPicker-root, & .MuiCalendarOrClockPicker-root > div, .MuiCalendarPicker-root, & .MuiPickerStaticWrapper-content':
    {
      minWidth: 146,
      width: 146,
      color: '#444',
    },
  '& > div > div': {
    minWidth: 146,
  },
  [`& > div > div, & > div > div > div, & > div > div > div > div, & .${calendarPickerClasses.root}`]:
    {
      width: 146,
    },
  [`& .${pickersCalendarHeaderClasses.root}`]: {
    minHeight: '34px',
    maxHeight: '34px',
    padding: 8,
    margin: 0,
  },
  [`& .${pickersCalendarHeaderClasses.labelContainer}`]: {
    maxHeight: '34px',
  },
  [`& .MuiPickersCalendarHeader-label`]: {
    fontSize: '12px',
  },
  [`& .${typographyClasses.caption}`]: {
    width: DATE_SIZE,
    margin: 0,
    height: 12,
    color: '#444',
  },
  [`& .${pickersSlideTransitionClasses.root}`]: {
    minHeight: DATE_SIZE * 6,
  },
  [`& .${pickersSlideTransitionClasses.root} [role="row"]`]: {
    margin: 0,
  },
  [`& .${pickersDayClasses.root}`]: {
    width: DATE_SIZE,
    height: DATE_SIZE,
  },
  [`& .${pickersDayClasses.dayWithMargin}`]: {
    margin: 0,
  },
  '& .MuiPickersArrowSwitcher-root .MuiButtonBase-root svg': {
    fontSize: '14px',
    color: '#444',
  },
  '& .MuiYearPicker-root button': {
    fontSize: 14,
    width: 46,
    color: '#444',
  },
  '&& .Mui-selected, .Mui-selected:hover': {
    backgroundColor: theme.palette.sp_primary_100_main,
  },
  '&& .MuiMonthPicker-root': {
    width: 140,
  },
  [`& .${typographyClasses.caption}:nth-child(6n+1), .${typographyClasses.caption}:nth-child(6n+7)`]:
    {
      width: DATE_SIZE,
      margin: 0,
      height: DATE_SIZE,
      backgroundColor: '#E7E7E7',
    },
  '& .MuiPickersDay-root:nth-child(6n+1), .MuiPickersDay-root:nth-child(6n+7)':
    {
      backgroundColor: '#E7E7E7',
      color: '#000',
      borderRadius: '0',
    },
  '& .MuiPickersDay-root:nth-child(6n+1):hover, .MuiPickersDay-root:nth-child(6n+7):hover':
    {
      backgroundColor: '#0000000a',
    },
  '& .MuiPickersDay-root.Mui-selected': {
    backgroundColor: theme.palette.sp_primary_100_main,
    color: '#fff',
  },
  '& .MuiPickersDay-root.isPublicHoliday:not(.Mui-selected), & .MuiPickersDay-root.isPublicHoliday:hover:not(.Mui-selected)':
    {
      backgroundColor: '#FF8F8F',
      color: '#fff',
    },
}));
