import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  ContactDetailResponse,
  ContactError,
  ContactResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const getContacts = createAsyncThunk<ContactResponse>(
  'ContactInformations/getContactInformations',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getContacts();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getContactDetail = createAsyncThunk<
  ContactDetailResponse,
  string,
  ThunkAPI<ContactError>
>('ContactInformations/getContactDetail', async (id, { rejectWithValue }) => {
  try {
    return await applicationsService.getContactDetail(id);
  } catch (error) {
    return rejectWithValue(error);
  }
});
