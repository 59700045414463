import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddFile: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.38751 6.86084H3.15317C2.93583 6.86084 2.75977 7.03703 2.75977 7.25437V7.29059C2.75977 7.50793 2.93583 7.68413 3.15317 7.68413H9.38751C9.60485 7.68413 9.78117 7.50793 9.78117 7.29059V7.25437C9.78117 7.03703 9.60485 6.86084 9.38751 6.86084Z"
          fill="#6B70F0"
        />
        <path
          d="M9.38751 5.27124H3.15317C2.93583 5.27124 2.75977 5.44743 2.75977 5.66477V5.70106C2.75977 5.91839 2.93583 6.09459 3.15317 6.09459H9.38751C9.60485 6.09459 9.78117 5.91839 9.78117 5.70106V5.66477C9.78117 5.44743 9.60485 5.27124 9.38751 5.27124Z"
          fill="#6B70F0"
        />
        <path
          d="M5.27115 10.1023H3.15317C2.93583 10.1023 2.75977 10.2785 2.75977 10.4958V10.532C2.75977 10.7494 2.93583 10.9256 3.15317 10.9256H5.27115C5.48849 10.9256 5.66456 10.7494 5.66456 10.532V10.4958C5.66456 10.2785 5.48849 10.1023 5.27115 10.1023Z"
          fill="#6B70F0"
        />
        <path
          d="M6.12547 8.50732H3.15317C2.93583 8.50732 2.75977 8.68352 2.75977 8.90086V8.93714C2.75977 9.15448 2.93583 9.33067 3.15317 9.33067H6.12547C6.34281 9.33067 6.51888 9.15448 6.51888 8.93714V8.90086C6.51888 8.68352 6.34281 8.50732 6.12547 8.50732Z"
          fill="#6B70F0"
        />
        <path
          d="M1.27831 14.1049V0.771518H8.13916V3.2932C8.13916 3.7178 8.4809 4.05954 8.9055 4.05954H11.1008V8.19676C11.3701 8.2589 11.6238 8.34175 11.8672 8.45566V3.2932C11.8672 3.1068 11.7999 2.92039 11.6705 2.78059L9.4233 0.2589C9.27832 0.0932041 9.07101 0 8.84836 0H1.27831C0.85371 0 0.511719 0.341738 0.511719 0.766335V14.0997C0.511719 14.5243 0.85371 14.866 1.27831 14.866H6.97917C6.78758 14.6278 6.6166 14.3741 6.47679 14.0997H1.27831V14.1049ZM8.9055 0.833651L11.1008 3.29839H8.9055V0.833651Z"
          fill="#6B70F0"
        />
        <path
          d="M10.1793 8.48145C8.10291 8.48145 6.41992 10.1643 6.41992 12.2407C6.41992 14.3171 8.10291 15.9999 10.1793 15.9999C12.2557 15.9999 13.9384 14.3171 13.9384 12.2407C13.9384 10.1643 12.2557 8.48145 10.1793 8.48145ZM11.8725 12.717H10.6503V13.939H9.79094V12.717H8.56874V11.8575H9.79094V10.6355H10.6503V11.8575H11.8725V12.717Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
