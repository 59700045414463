import { yup, dayjs } from 'libs';

export const validationSchema = yup.object().shape({
  application_from_date: yup
    .string()
    .test('validateDate', '正しく選択してください。', (value) => {
      if (!value) return true;
      return dayjs(value, 'YYYY/MM/DD', true).isValid();
    }),
  application_to_date: yup
    .string()
    .test('validateDate', '正しく選択してください。', (value) => {
      if (!value) return true;
      return dayjs(value, 'YYYY/MM/DD', true).isValid();
    }),
});
