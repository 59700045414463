import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Cancel: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 15 15" {...props}>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="5.5" cy="5.5" r="5.5" fill="#D9D9D9" />
        <line
          x1="2.71389"
          y1="2.73045"
          x2="8.26972"
          y2="8.28629"
          stroke="white"
          strokeWidth="0.5"
        />
        <line
          x1="2.73143"
          y1="8.28636"
          x2="8.28727"
          y2="2.73052"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    </SvgIcon>
  );
};
