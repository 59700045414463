import { createSlice } from '@reduxjs/toolkit';
import { spGetSubmittedPreliminary, spLogin } from 'pages/SPLogin/thunk';
import { submitFormData } from 'pages/SPStepNine/thunk';
import { CompleteResponse } from 'types';

type InitialState = {
  form: CompleteResponse;
  editable: boolean;
};

export const initialState: InitialState = {
  form: {
    application_number: '',
    created_at: '',
  },
  editable: false,
};

export const spStepTenSlice = createSlice({
  name: 'spStepTen',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(submitFormData.fulfilled, (state, action) => {
      state.form = action.payload.data;
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      state.form.application_number = action.payload.data.application_number;
      state.form.created_at = action.payload.data.created_at;
      state.editable = action.payload.data.editable;
    });
    builder.addCase(spLogin.fulfilled, (state, action) => {
      if (action.payload.data.application_number) {
        state.form.application_number = action.payload.data.application_number;
      }
    });
  },
});

export default spStepTenSlice.reducer;
