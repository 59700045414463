import {
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { FC } from 'react';
import { HeadCellProps } from 'types';
import { StatusItems, StatusItemWithPhaseId } from '../StatusItems';

type StatusDatas = {
  rows: { data: StatusItemWithPhaseId[] }[];
  onClick: (value: number) => void;
  underReviewStatus: null | number;
};

export const StatusTable: FC<StatusDatas> = ({
  rows,
  onClick,
  underReviewStatus,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead sx={{ height: 36, bgcolor: 'bg_off' }}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{
                  height: 36,
                  p: 0,
                  alignItems: 'center',
                  textAlign: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  borderRight: '1px solid',
                  borderColor: 'bg_gray',
                  ...headCell.style,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="table_header_text"
                    sx={{ whiteSpace: 'nowrap' }}
                    color="b_333"
                  >
                    {headCell.label}
                  </Typography>
                  {headCell.smallLabel && (
                    <Typography
                      variant="table_small_text"
                      sx={{ whiteSpace: 'nowrap' }}
                      color="b_333"
                    >
                      {headCell.smallLabel}
                    </Typography>
                  )}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            bgcolor: 'bg_off',
            '& tr:last-child, & tr:last-child td': {
              borderBottomColor: 'bg_gray',
            },
          }}
        >
          {rows.map(({ data }, index) => (
            <StatusItems
              data={data}
              onClick={() => onClick(index + 2)}
              key={index}
              active={
                index + 1 ===
                (underReviewStatus === null ? null : underReviewStatus - 1)
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const headCells: HeadCellProps<StatusItemWithPhaseId>[] = [
  {
    id: 'active',
    label: '現況',
    style: { width: 113 },
  },
  {
    id: 'value',
    label: 'No.',
    style: { width: 70 },
  },
  {
    id: 'phaseId',
    label: 'フェーズID',
    style: { width: 130 },
  },
  {
    id: 'key',
    label: 'フェーズ名',
    style: { flex: 1, justifyContent: 'center' },
  },
];
