import { RootState } from 'types';
import {
  messagesAdminAdapter,
  messagesDetailAdapter,
  usersAdapter,
} from './slice';

export const messagesAdminSelectors = messagesAdminAdapter.getSelectors(
  (state: RootState) => state.messagesAdmin.messagesAdmin
);

export const messagesDetailAdminSelectors = messagesDetailAdapter.getSelectors(
  (state: RootState) => state.messagesAdmin.messagesDetail
);
export const usersSelectors = usersAdapter.getSelectors(
  (state: RootState) => state.messagesAdmin.users
);

export const conversationIdSelector = (state: RootState) =>
  state.messagesAdmin.conversationId;

export const conversationNameSelector = (state: RootState) =>
  state.messagesAdmin.conversationName;

export const totalUnreadSelectors = (state: RootState) =>
  state.messagesAdmin.totalUnreadMessages;

export const userExistsSelector = (state: RootState) =>
  state.messagesAdmin.userExists;

export const totalMessageSelectors = (state: RootState) =>
  state.messagesAdmin.total;
