import { useMemo, forwardRef, useImperativeHandle } from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { date, zeroPad } from 'utils';
import { InputSelect, InputSelectProps } from '../InputSelect';
import { validationSchema } from './validationSchema';
import { validationSchema2 } from './valdiationSchema2';

export type InputDateTimeProps = Pick<
  InputSelectProps,
  'disabled' | 'readOnly'
> & {
  name: string;
  yearOptions: InputSelectProps['options'];
  monthOptions: InputSelectProps['options'];
  selectWidth?: string;
  checkRequired?: boolean;
  selectSx?: SxProps;
  unitSx?: SxProps;
  hasError?: boolean;
};

type ClearHandle = {
  clear: () => void;
};

export const InputDateTime = forwardRef<ClearHandle, InputDateTimeProps>(
  (
    {
      disabled,
      readOnly,
      yearOptions,
      monthOptions,
      checkRequired,
      selectSx,
      unitSx,
      hasError,
      ...props
    },
    ref
  ) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();

    const initialValues = useMemo(() => {
      const [year = '', month = '', day = ''] = field.value
        ? field.value.split('/')
        : ['', '', ''];
      return { year, month, day };
    }, [field.value]);

    const formik = useFormik({
      initialValues,
      validationSchema: checkRequired ? validationSchema : validationSchema2,
      onSubmit(values) {
        if (!values.day && !values.month && !values.year) {
          return setFieldValue(field.name, '');
        }
        setFieldValue(
          field.name,
          `${values.year}/${zeroPad(values.month)}/${values.day}`
        );
      },
    });

    useImperativeHandle(ref, () => ({
      clear: () => {
        formik.setFieldValue('day', '');
        formik.setFieldValue('month', '');
        formik.setFieldValue('year', '');
        formik.touched.day = false;
        formik.touched.month = false;
        formik.touched.year = false;
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Stack direction="row" spacing={5}>
          <input name={field.name} type="hidden" />
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{ mr: unitSx ? 2 : '11px' }}
          >
            <InputSelect
              sx={{
                width: { mobile: 100, tablet: props.selectWidth ?? 125 },
                height: 50,
                ...selectSx,
              }}
              name="year"
              placeholder="ー"
              disabled={disabled}
              readOnly={readOnly}
              options={yearOptions}
              error={
                (!formik.values.year && !!formik.touched.year) ||
                (meta.touched && !formik.values.year) ||
                hasError
              }
              errorMessage={meta.error}
              onChange={() => formik.handleSubmit()}
              onClose={() => formik.handleSubmit()}
              unit="年"
              unitSx={unitSx}
              autoWidth
              showError={false}
              useFastField={false}
              checkRequired={checkRequired}
              variant="standard"
              renderValue={() => (
                <Typography variant="sp_unit_select_text" color="b_333">
                  {formik.values.year.substring(0, 4)}
                </Typography>
              )}
            />
          </Stack>
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{ mr: unitSx ? 2 : 3 }}
          >
            <InputSelect
              sx={{
                width: { mobile: 70, tablet: props.selectWidth ?? 125 },
                height: 50,
                ...selectSx,
              }}
              name="month"
              placeholder="ー"
              disabled={disabled}
              readOnly={readOnly}
              options={monthOptions}
              error={
                (!formik.values.month && !!formik.touched.month) ||
                (meta.touched && !formik.values.month) ||
                hasError
              }
              errorMessage={meta.error}
              onChange={() => formik.handleSubmit()}
              onClose={() => formik.handleSubmit()}
              unit="月"
              unitSx={unitSx}
              showError={false}
              autoWidth
              useFastField={false}
              checkRequired={checkRequired}
              variant="standard"
            />
          </Stack>
          <Stack
            flex={1}
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{ mr: unitSx ? 1 : 3 }}
          >
            <InputSelect
              sx={{
                width: { mobile: 70, tablet: props.selectWidth ?? 125 },
                height: 50,
                ...selectSx,
              }}
              name="day"
              placeholder="ー"
              disabled={disabled}
              readOnly={readOnly}
              options={[{ value: '', label: '日' }].concat(
                date(formik.values.year, formik.values.month)
              )}
              error={
                (!formik.values.day && !!formik.touched.day) ||
                (meta.touched && !formik.values.day) ||
                hasError
              }
              errorMessage={meta.error}
              onChange={() => formik.handleSubmit()}
              onClose={() => formik.handleSubmit()}
              unit="日"
              unitSx={unitSx}
              useFastField={false}
              autoWidth
              checkRequired={checkRequired}
              variant="standard"
            />
          </Stack>
        </Stack>
      </FormikProvider>
    );
  }
);
