import { regex, yup } from 'libs';

export const validationSchema = yup.object({
  applicant_first_name_kanji: yup.string().when('applicant_id', {
    is: (applicant_id: string) => !applicant_id,
    then: yup
      .string()
      .matches(regex.kanjiFullwidth, '漢字でご入力ください。')
      .max(48)
      .required(),
  }),
  applicant_last_name_kanji: yup.string().when('applicant_id', {
    is: (applicant_id: string) => !applicant_id,
    then: yup
      .string()
      .matches(regex.kanjiFullwidth, '漢字でご入力ください。')
      .max(48)
      .required(),
  }),
  applicant_id: yup.string(),
  inquiry_category: yup.string().required(),
  content: yup.string().required(),
});
