import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPAccountInfomation: FC<SvgIconProps> = (props) => {
  const [idOne, idTwo] = useMemo<[string, string]>(
    () => [
      Math.round(Math.random() * 10000000).toString(),
      Math.round(Math.random() * 10000000).toString(),
    ],
    []
  );
  return (
    <SvgIcon viewBox="0 0 25 20" {...props}>
      <svg
        width="25"
        height="20"
        viewBox="0 0 25 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.83415 9.83138C9.30707 9.83138 10.5008 8.63763 10.5008 7.16471C10.5008 5.6918 9.30707 4.49805 7.83415 4.49805C6.36123 4.49805 5.16748 5.6918 5.16748 7.16471C5.16748 8.63763 6.36331 9.83138 7.83415 9.83138ZM9.16748 11.1647H6.50081C4.65998 11.1647 3.16748 12.6564 3.16748 14.498C3.16748 14.8647 3.46581 15.1647 3.83415 15.1647H11.8341C12.2023 15.1647 12.5008 14.8662 12.5008 14.498C12.5008 12.6564 11.0091 11.1647 9.16748 11.1647Z"
          fill={`url(#${idOne})`}
        />
        <path
          opacity="0.4"
          d="M21.8333 0.5H3.16667C1.69375 0.5 0.5 1.69375 0.5 3.16667V16.5C0.5 17.9729 1.69375 19.1667 3.16667 19.1667H21.8333C23.3063 19.1667 24.5 17.9729 24.5 16.5V3.16667C24.5 1.69375 23.3042 0.5 21.8333 0.5ZM7.83333 4.5C9.30625 4.5 10.5 5.69375 10.5 7.16667C10.5 8.63959 9.30625 9.83334 7.83333 9.83334C6.36042 9.83334 5.16667 8.63959 5.16667 7.16667C5.16667 5.69375 6.3625 4.5 7.83333 4.5ZM11.8333 15.1667H3.83333C3.465 15.1667 3.16667 14.8667 3.16667 14.5C3.16667 12.6583 4.65917 11.1667 6.5 11.1667H9.16667C11.0075 11.1667 12.5 12.6592 12.5 14.5C12.5 14.8667 12.2 15.1667 11.8333 15.1667ZM21.1667 12.5H15.8333C15.4667 12.5 15.1667 12.2 15.1667 11.8333C15.1667 11.4667 15.4667 11.1667 15.8333 11.1667H21.1667C21.5333 11.1667 21.8333 11.4667 21.8333 11.8333C21.8333 12.2 21.5333 12.5 21.1667 12.5ZM21.1667 9.83334H15.8333C15.4667 9.83334 15.1667 9.53334 15.1667 9.16667C15.1667 8.8 15.4667 8.5 15.8333 8.5H21.1667C21.5333 8.5 21.8333 8.8 21.8333 9.16667C21.8333 9.53334 21.5333 9.83334 21.1667 9.83334ZM21.1667 7.16667H15.8333C15.4667 7.16667 15.1667 6.86667 15.1667 6.5C15.1667 6.13334 15.4667 5.83333 15.8333 5.83333H21.1667C21.5333 5.83333 21.8333 6.13334 21.8333 6.5C21.8333 6.86667 21.5333 7.16667 21.1667 7.16667Z"
          fill={`url(#${idTwo})`}
        />
        <defs>
          <linearGradient
            id={idOne}
            x1="4.60474"
            y1="12.971"
            x2="12.694"
            y2="5.82151"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
          <linearGradient
            id={idTwo}
            x1="4.19582"
            y1="15.3277"
            x2="17.9863"
            y2="-2.58193"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
