import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkAPI } from 'types';
import { dayjs } from 'libs/dayjs';

export const resetLivedLength = createAsyncThunk<
  { reset: boolean },
  { birthday: string },
  ThunkAPI
>('resetLivedLength/spStepTwo', ({ birthday }, { getState }) => {
  const birthdayDayjs = dayjs(birthday, 'YYYY/MM/DD');
  const birthdayMonth = Math.abs(
    birthdayDayjs.diff(dayjs().format('YYYY-MM-DD'), 'month')
  );

  const { sp_step_four } = getState();

  const { lived_length_year_num, lived_length_month_num } = sp_step_four?.form
    ?.p_application_header?.p_applicant_people_attributes?.[0] || {
    lived_length_year_num: 0,
    lived_length_month_num: 0,
  };

  return {
    reset:
      birthdayMonth < +lived_length_year_num * 12 + +lived_length_month_num,
  };
});
