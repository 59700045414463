import {
  ApplicantDetailType,
  BorrowingType,
  BuyingandSellingScheduleType,
  CODEBANK,
  CurrentResidenceType,
  DispatchType,
  FileATaxReturnType,
  LeaveType,
  LoanTargetZeroType,
  LoanType,
  MESSAGE_REGEX,
  OccupationDetailType,
  OccupationType,
  PersonOccupancyType,
  PlanningAreaType,
  PurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
  RelationshipToApplicantType,
  RelationshipType,
  SPBorrowingType,
  SPScheduledLoanPayoffType,
  SPTaxReturnType,
  VALIDATE_MAX,
  WorkIndustryType,
  PlannedCohabitantType,
  BANK_NOT_VALID_DAYS,
  HasLandAdvancePlanType,
  DesiredMonthlyBonusType,
  FundsPairOffType,
} from 'constant';
import { dayjs, regex, yup } from 'libs';
import { Context } from 'pages/StepSynthesisInformation/validationSchema';
import { SPBorrowingAttribute } from 'types';
import {
  convertManYenToYen,
  isBetweenDate,
  isWeekend,
  messageOverMaxManYen,
  messageOverMaxNumber,
} from 'utils';
import { PublicHolidayResponse } from 'services';
import get from 'lodash/get';

export const getValidationSchema = (
  selectTab: string,
  selectSubTab: number,
  hasJoinGuarantor: boolean,
  publicHolidays: Record<number, PublicHolidayResponse[]>,
  loan_desired_borrowing_date?: string,
  created_at?: string
) => {
  if (selectTab === '3') {
    return [tabTwoSchema(1, true), tabThreeSchema(1)][selectSubTab];
  }
  if (hasJoinGuarantor) {
    return [
      tabOneSchema(publicHolidays, created_at),
      tabTwoSchema(0),
      tabThreeSchema(0),
      tabFourSchema(0),
      tabFiveSchema(0),
      tabSixSchema(0, loan_desired_borrowing_date, created_at),
      tabSevenSchema,
      tabSalePersonInfoSchema,
    ][selectSubTab];
  } else {
    return [
      tabOneSchema(publicHolidays, created_at),
      tabTwoSchema(0),
      tabThreeSchema(0),
      tabFiveSchema(0),
      tabSixSchema(0, loan_desired_borrowing_date, created_at),
      tabSevenSchema,
      tabSalePersonInfoSchema,
    ][selectSubTab];
  }
};

// tab お借入のご希望 <=> Step 1 SP
const tabOneSchema = (
  publicHolidays: Record<number, PublicHolidayResponse[]>,
  created_at?: string
) =>
  yup.object({
    //tab 1
    p_application_header: yup.object().shape(
      {
        created_at: yup.string(),
        // .required('お客様に該当する数値を選択してください。'),
        pair_loan_applicant_first_name: yup.string().when('loan_type', {
          is: (loan_type: LoanType) => loan_type === LoanType.TWO,
          then: yup
            .string()
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH)
            .max(48),
          // .required(),
          otherwise: yup.string(),
        }),
        pair_loan_applicant_last_name: yup.string().when('loan_type', {
          is: (loan_type: LoanType) => loan_type === LoanType.TWO,
          then: yup
            .string()
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH)
            .max(48),
          // .required(),
          otherwise: yup.string(),
        }),
        pair_loan_relationship_name: yup.string().when('loan_type', {
          is: (loan_type: LoanType) => loan_type === LoanType.TWO,
          then: yup
            .string()
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH)
            .max(48),
          // .required(),
          otherwise: yup.string(),
        }),
        scheduled_date_moving: yup
          .string()
          // .required('お客様に該当する数値を選択してください。'),
          .test({
            message:
              MESSAGE_REGEX.PLEASE_SELECT_MONTH_AFTER_LOAN_DESIRED_BORROWING_DATE,
            test(value, testContext) {
              if (!!value && !!testContext) {
                return dayjs(value).isSameOrAfter(
                  dayjs(
                    // @ts-ignore
                    testContext.from[0].value.p_borrowing_details_attributes[0]
                      .loan_desired_borrowing_date || undefined
                  ),
                  'month'
                );
              }
              return true;
            },
          }),
        //TODO
        //name_master_banks
        loan_target: yup.string().when('loan_target_zero', {
          is: (loanTarget: LoanTargetZeroType) =>
            loanTarget === LoanTargetZeroType.ZERO,
          then: yup
            .string()
            .required(
              'お借入の目的で「物件の購入・建築」を選択する時は、資金の使いみちを選択する必要があります。'
            ),
          otherwise: yup.string(),
        }),
        // .required(),
        has_land_advance_plan: yup.string().when('loan_target', {
          is: (loanTarget: PurposeType) => loanTarget === PurposeType.SIX,
          then: yup
            .string()
            .required(
              '資金の使いみちで「土地を購入後に建物新築」を選択する場合、土地先行プラン希望有無は必須項目になりますので、ご選択ください。'
            ),
          otherwise: yup.string(),
        }),
        loan_type: yup.string(),
        // .required(),
        p_borrowing_details_attributes: yup.array().of(
          yup.object().shape(
            {
              desired_monthly_bonus: yup.string(),
              // .required(),
              halfyear_bonus: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .when('desired_monthly_bonus', {
                  is: (desired_monthly_bonus: DesiredMonthlyBonusType) =>
                    desired_monthly_bonus !== DesiredMonthlyBonusType.ONE,
                  then: yup
                    .number()
                    .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                    .test(
                      'zero_check',
                      '0より大きい数字をご入力ください',
                      (value) => value !== 0
                    )
                    .required(MESSAGE_REGEX.REQUIRED),
                })
                .min(
                  convertManYenToYen(0),
                  'ボーナス返済分は融資金額の50%以内で入力してください。'
                )
                .max(
                  convertManYenToYen(20000),
                  'ボーナス返済分は融資金額の50%以内で入力してください。'
                )
                .test(
                  'divisible-by-10',
                  MESSAGE_REGEX.ENTER_UNITS_100000YEN,
                  (price) => +(price || 0) % (10 * 10000) === 0
                )
                .test(
                  'test-temporary-desired-loan-amount',
                  'ボーナス返済分は融資金額の50%以内で入力してください。',
                  function (value) {
                    const { temporary_desired_loan_amount } = this.parent;
                    if (!temporary_desired_loan_amount || !value) {
                      return true;
                    }
                    return value <= temporary_desired_loan_amount / 2;
                  }
                ),
              // .required(),
              loan_desired_borrowing_date: yup
                .string()
                .test({
                  message:
                    MESSAGE_REGEX.DESIRED_LOAN_DATE_MUST_BE_MORE_THAN_APPLICATION_DATE,
                  test(value, testContext) {
                    if (!!value && !!testContext) {
                      if (
                        value ===
                          // @ts-ignore
                          testContext.from[2].value.p_application_header
                            .p_borrowing_details_attributes[0]
                            .loan_desired_borrowing_date ||
                        undefined
                      ) {
                        return dayjs(value).isAfter(dayjs(created_at), 'date');
                      }
                      return true;
                    }
                    return true;
                  },
                })
                .test({
                  message: MESSAGE_REGEX.LOAN_DESIRED_BORROWRING_DATE,
                  test(value, testContext) {
                    if (!!value && !!testContext) {
                      const borrowingDateOne =
                        // @ts-ignore
                        testContext?.from[1]?.value
                          ?.p_borrowing_details_attributes[0]
                          ?.loan_desired_borrowing_date;
                      const borrowingDateTwo =
                        // @ts-ignore
                        testContext?.from[2]?.value.p_application_header
                          .p_borrowing_details_attributes[1]
                          ?.loan_desired_borrowing_date;
                      if (
                        borrowingDateTwo &&
                        testContext.path ===
                          'p_application_header.p_borrowing_details_attributes[1].loan_desired_borrowing_date'
                      ) {
                        return dayjs(borrowingDateTwo).isAfter(
                          borrowingDateOne
                        );
                      }
                    }
                    return true;
                  },
                })
                .test(
                  'not-available-days',
                  MESSAGE_REGEX.PLEASE_SELECT_BANK_BUSINESS_DAY,
                  (value) => {
                    if (!value) return true;
                    const [year, month, day] = value.split('/');

                    if (!year || !month || !day) {
                      return true;
                    }
                    if (
                      publicHolidays[+year]?.find(
                        (o) => o.date === `${year}-${month}-${day}`
                      )
                    ) {
                      return false;
                    }
                    return (
                      !isWeekend(value) &&
                      !BANK_NOT_VALID_DAYS.includes(`${month}/${day}`)
                    );
                  }
                ),
              // .required('お客様に該当する数値を選択してください。')
              loan_term_month_num: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(11),
              // .required(),
              loan_term_year_num: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(1)
                .max(50),
              // .test(
              //   'test-loan-term-year-num',
              //   MESSAGE_REGEX.LOAN_TERM_LIMIT,
              //   (value, testContext) => {
              //     if (!!value && !!testContext) {
              //       const loanTermYearNum =
              //         testContext?.parent.loan_term_year_num;
              //       const birthday =
              //         // @ts-ignore
              //         testContext?.from[1]?.value
              //           ?.p_applicant_people_attributes[0]?.birthday;
              //       return !loanTermYearLimit(loanTermYearNum, birthday);
              //     }
              //     return true;
              //   }
              // ),
              // .required(),
              repayment_method: yup.string().required(MESSAGE_REGEX.REQUIRED),
              temporary_desired_loan_amount: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .required(MESSAGE_REGEX.REQUIRED)
                .test(
                  'divisible-by-10',
                  MESSAGE_REGEX.ENTER_UNITS_100000YEN,
                  (price) => +(price || 0) % (10 * 10000) === 0
                )
                .test(
                  'test-temporary-desired-loan-amount',
                  MESSAGE_REGEX.TEMPORARY_DESIRED_LOAN_AMOUNT,
                  (value, testContext) => {
                    const temporary_desired_loan_amountOne =
                      // @ts-ignore
                      testContext?.from[1]?.value
                        ?.p_borrowing_details_attributes[0]
                        ?.temporary_desired_loan_amount;
                    const temporary_desired_loan_amountTwo =
                      // @ts-ignore
                      testContext?.from[1]?.value
                        ?.p_borrowing_details_attributes[1]
                        ?.temporary_desired_loan_amount;
                    if (
                      temporary_desired_loan_amountOne &&
                      temporary_desired_loan_amountTwo
                    ) {
                      return (
                        convertManYenToYen(500) <=
                          +temporary_desired_loan_amountOne +
                            +temporary_desired_loan_amountTwo &&
                        +temporary_desired_loan_amountOne +
                          +temporary_desired_loan_amountTwo <=
                          convertManYenToYen(20000)
                      );
                    }
                    return true;
                  }
                )
                .test(
                  'test-temporary-desired-loan-amount',
                  MESSAGE_REGEX.ABOUT_TEMPORARY_DESIRED_LOAN_AMOUNT,
                  (value, testContext) => {
                    const hasLandAdvance =
                      // @ts-ignore
                      testContext?.from?.[1]?.value?.has_land_advance_plan;
                    const temporary_desired_loan_amountOne =
                      // @ts-ignore
                      testContext?.from?.[1]?.value
                        ?.p_borrowing_details_attributes?.[0]
                        ?.temporary_desired_loan_amount;
                    if (temporary_desired_loan_amountOne && !hasLandAdvance) {
                      return (
                        convertManYenToYen(500) <=
                          +temporary_desired_loan_amountOne &&
                        +temporary_desired_loan_amountOne <=
                          convertManYenToYen(20000)
                      );
                    }
                    if (
                      temporary_desired_loan_amountOne &&
                      hasLandAdvance === HasLandAdvancePlanType.NO
                    ) {
                      return (
                        convertManYenToYen(500) <=
                          +temporary_desired_loan_amountOne &&
                        +temporary_desired_loan_amountOne <=
                          convertManYenToYen(20000)
                      );
                    }
                    return true;
                  }
                )
                .test(
                  'test-temporary-desired-loan-amount',
                  MESSAGE_REGEX.TEMPORARY_DESIRED_LOAN_AMOUNT,
                  (value, testContext) => {
                    const hasLandAdvance =
                      // @ts-ignore
                      testContext?.from?.[1]?.value?.has_land_advance_plan;
                    const temporary_desired_loan_amountOne =
                      // @ts-ignore
                      testContext?.from?.[1]?.value
                        ?.p_borrowing_details_attributes?.[0]
                        ?.temporary_desired_loan_amount;
                    let temporary_desired_loan_amountTwo =
                      // @ts-ignore
                      testContext?.from?.[1]?.value
                        ?.p_borrowing_details_attributes?.[1]
                        ?.temporary_desired_loan_amount;
                    if (!temporary_desired_loan_amountTwo) {
                      temporary_desired_loan_amountTwo = 0;
                      if (
                        temporary_desired_loan_amountOne &&
                        hasLandAdvance === HasLandAdvancePlanType.YES
                      ) {
                        return (
                          convertManYenToYen(500) <=
                            +temporary_desired_loan_amountOne +
                              temporary_desired_loan_amountTwo &&
                          +temporary_desired_loan_amountOne +
                            temporary_desired_loan_amountTwo <=
                            convertManYenToYen(20000)
                        );
                      }
                    }
                    return true;
                  }
                ),
            },
            []
          )
        ),
        is_home_loan_plus: yup.boolean(),
      },
      []
    ),
  });

// tab あなたの情報 <=> Step 2 SP
const tabTwoSchema = (
  peopleAttributeIndex: number,
  uncheckBirthday?: boolean
) =>
  yup.object({
    //tab 2
    p_application_header: yup.object().shape({
      p_applicant_people_attributes: yup.array().validArrayByIndex(
        peopleAttributeIndex,
        yup.object().shape(
          {
            last_name_kanji: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            first_name_kanji: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            last_name_kana: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            first_name_kana: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            sex: yup.string(),
            // .required(),
            name_relationship_to_applicant: yup
              .string()
              .when('applicant_detail_type', {
                is: (applicant_detail_type: ApplicantDetailType) =>
                  applicant_detail_type !== ApplicantDetailType.MAIN,
                then: yup
                  .string()
                  .max(48)
                  .matches(
                    regex.kanjiFullwidth,
                    MESSAGE_REGEX.NAME_RELATIONSHIP_INCOME
                  ),
                // .required(),
                otherwise: yup
                  .string()
                  .max(48)
                  .matches(
                    regex.kanjiFullwidth,
                    MESSAGE_REGEX.NAME_RELATIONSHIP_INCOME
                  ),
              }),
            other_relationship_to_applicant: yup
              .string()
              .when('relationship_to_applicant', {
                is: (relationship_to_applicant: RelationshipToApplicantType) =>
                  relationship_to_applicant ===
                  RelationshipToApplicantType.OTHERS,
                then: yup.string().max(40),
                // .matches(
                //   regex.kanjiFullwidth,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH
                // ),
                // .required(),
                otherwise: yup.string().max(40),
                // .matches(
                //   regex.kanjiFullwidth,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH
                // ),
              }),
            nationality: yup.string(),
            // .required(),
            mobile_phone_number: yup
              .string()
              // .max(
              //   VALIDATE_MAX.MOBILE_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
              // )
              // .min(
              //   VALIDATE_MAX.MOBILE_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
              // )
              // .noZeroPhoneNumber()
              // .matches(regex.mobilePhone, MESSAGE_REGEX.MOBILE_PHONE_ADMIN_NEW)
              // .matches(
              //   regex.freePhones,
              //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
              // )
              .when('home_phone_number', {
                is: (homePhone: string) => !homePhone || homePhone.length === 0,
                then: yup.string().required(),
              }),
            home_phone_number: yup
              .string()
              // .max(
              //   VALIDATE_MAX.HOME_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
              // )
              // .min(
              //   VALIDATE_MIN.HOME_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
              // )
              // .noZeroPhoneNumber()
              // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_ADMIN)
              // .matches(
              //   regex.freePhones,
              //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
              // )
              .when('mobile_phone_number', {
                is: (mobiPhone: string) => !mobiPhone || mobiPhone.length === 0,
                then: yup.string().required(),
              }),
            emergency_contact_number: yup.string(),
            // .max(
            //   VALIDATE_MAX.MOBILE_PHONE,
            //   MESSAGE_REGEX.EMERGENCY_PHONE_ADMIN
            // )
            // .test(
            //   'emergency_contact_number_test',
            //   MESSAGE_REGEX.EMERGENCY_PHONE_ADMIN,
            //   (value) => {
            //     if (!value) return true;
            //     const validFixedPhone = regex.fixedPhones.test(value);
            //     const validMobilePhone = regex.mobilePhone.test(value);
            //     return validMobilePhone || validFixedPhone;
            //   }
            // ),
            // .test(
            //   'emergency_contact_number_test',
            //   MESSAGE_REGEX.EMERGENCY_PHONE_ADMIN,
            //   (value) => {
            //     if (!value) return true;
            //     const validPhone = regex.freePhones.test(value);
            //     return validPhone;
            //   }
            // ),
            // .noZeroPhoneNumber(),
            // .required(),
            birthday: yup
              .string()
              .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
              .label('生年月日'),
            // .test(
            //   'test-birthday',
            //   MESSAGE_REGEX.LOAN_TERM_LIMIT,
            //   (birthday, testContext) => {
            //     if (uncheckBirthday) return true;
            //     if (!!birthday && !!testContext) {
            //       const loanTermYearNum = get(
            //         testContext,
            //         'options.context.parent.p_borrowing_details_attributes[0].loan_term_year_num'
            //       );
            //       return !loanTermYearLimit(loanTermYearNum, birthday);
            //     }
            //     return true;
            //   }
            // ),
            postal_code: yup
              .string()
              .trim()
              .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
              .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
              .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE),
            prefecture_kanji: yup
              .string()
              .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
              .max(8)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH)
              .label('都道府県'),
            city_kanji: yup
              .string()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(20)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            district_kanji: yup
              .string()
              .max(40)
              .matches(
                regex.kanjiFullwidthHaveNumber,
                MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
              )
              .required(MESSAGE_REGEX.REQUIRED),
            other_address_kanji: yup
              .string()
              .max(99)
              .required(MESSAGE_REGEX.REQUIRED)
              .matches(
                regex.kanjiFullwidthHaveNumber,
                MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
              ),
            prefecture_kana: yup
              .string()
              .max(8)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            // .required(),
            city_kana: yup
              .string()
              // .required()
              .max(20)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            district_kana: yup
              .string()
              // .required()
              .max(30)
              .matches(
                regex.kanaHalfwidthHaveNumber,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
              ),
            other_address_kana: yup
              .string()
              .max(138)
              .matches(
                regex.kanaHalfwidthHaveNumber,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
              ),
            // .required(),
            owner_email: yup
              .string()
              .max(128)
              .matches(regex.email, MESSAGE_REGEX.EMAIL)
              // .required()
              .label('メールアドレス'),
          },
          [['mobile_phone_number', 'home_phone_number']]
        )
      ),
    }),
  });

// tab ご職業 <=> Step 3 SP
const tabThreeSchema = (peopleAttributeIndex: number) =>
  yup.object({
    // tab 3
    p_application_header: yup.object().shape(
      {
        p_applicant_people_attributes: yup.array().validArrayByIndex(
          peopleAttributeIndex,
          yup.object().shape(
            {
              occupation: yup.string(),
              // .required(),
              industry: yup.string(),
              // .required(),
              occupation_detail: yup.string(),
              // .required(),
              emplmt_form_code: yup.string(),
              // .required(),
              office_name_kanji: yup
                .string()
                .max(48)
                .matches(
                  regex.kanjiFullwidthHaveNumber,
                  MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              office_name_kana: yup
                .string()
                .max(48)
                .matches(
                  regex.kanaHalfwidthHaveNumber,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              department: yup
                .string()
                // .required()
                .max(46)
                .matches(
                  regex.kanjiFullwidthHaveNumber,
                  MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                ),
              position: yup.string(),
              // .required(),
              office_phone_number: yup.string(),
              // .max(
              //   VALIDATE_MAX.HOME_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
              // )
              // .min(
              //   VALIDATE_MIN.HOME_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
              // )
              // .noZeroPhoneNumber()
              // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_ADMIN),
              // .matches(
              //   regex.freePhones,
              //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
              // )
              // .required(),
              headquarters_location: yup
                .string()
                .max(20)
                .matches(
                  regex.kanjiFullwidthHaveNumber,
                  MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              listed_division: yup.string(),
              // .required(),
              office_establishment_date: yup
                .string()
                .test({
                  message: MESSAGE_REGEX.DATE_INVALID,
                  test(value) {
                    if (!!value) {
                      return dayjs(value, 'YYYY/MM/DD', true).isValid();
                    }
                    return true;
                  },
                })
                .test({
                  message:
                    MESSAGE_REGEX.PLEASE_SELECT_ESTABLISHMENT_DATE_BEFORE_TODAY,
                  test(value, context) {
                    if (!!value) {
                      if (!dayjs(value, 'YYYY/MM/DD', true).isValid()) {
                        return false;
                      }

                      const [year, month, day] = value.split('/');
                      const officeEstablishmentDate = dayjs(
                        `${year}/${month}/${day}`
                      );
                      const created_at = get(
                        context,
                        'options.context.parent.p_application_header.created_at'
                      );
                      return officeEstablishmentDate.isBefore(
                        dayjs(created_at).format('YYYY/MM/DD'),
                        'day'
                      );
                    }
                    return true;
                  },
                }),
              // .required(),
              capital_stock: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(
                  convertManYenToYen(VALIDATE_MAX.CAPITAL_STOCK),
                  messageOverMaxManYen(VALIDATE_MAX.CAPITAL_STOCK)
                ),
              // .required(),
              office_postal_code: yup
                .string()
                .trim()
                .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
                .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
                .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE),
              // .required(),
              office_prefecture_kanji: yup
                .string()
                .max(8)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              // .required(),
              office_city_kanji: yup
                .string()
                .max(20)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              // .required(),
              office_district_kanji: yup
                .string()
                .max(40)
                .matches(
                  regex.kanjiFullwidthHaveNumber,
                  MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              other_office_address_kanji: yup
                .string()
                .max(99)
                .matches(
                  regex.kanjiFullwidthHaveNumber,
                  MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              office_prefecture_kana: yup
                .string()
                .max(8)
                .matches(
                  regex.kanaHalfwidthHaveSpace,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
                ),
              // .required(),
              office_city_kana: yup
                .string()
                .max(20)
                .matches(
                  regex.kanaHalfwidthHaveSpace,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
                ),
              // .required(),
              office_district_kana: yup
                .string()
                .max(30)
                .matches(
                  regex.kanaHalfwidthHaveNumber,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              other_office_address_kana: yup
                .string()
                .max(138)
                .matches(
                  regex.kanaHalfwidthHaveNumber,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              number_of_employee: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(1)
                .max(9999999, messageOverMaxNumber(9999999)),
              // .required(),
              employment_started_date: yup.string().test({
                message: MESSAGE_REGEX.EMPLOYMENT_STARTED_DATE_BEFORE_TODAY,
                test(value, context) {
                  if (!value) {
                    return true;
                  }
                  const created_at = get(
                    context,
                    'options.context.parent.created_at'
                  );
                  if (!created_at) return true;
                  return isBetweenDate(
                    dayjs(created_at).subtract(49, 'y'),
                    dayjs(created_at),
                    dayjs(value, 'YYYY/MM')
                  );
                },
              }),
              // .required(),

              //MAN YEN
              last_year_income: yup
                .number()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(
                  convertManYenToYen(VALIDATE_MAX.MAN_YEN),
                  messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
                ),
              // .required(),
              //MAN YEN
              two_years_ago_income: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(
                  convertManYenToYen(VALIDATE_MAX.MAN_YEN),
                  messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
                ),
              // .requiredNumberMCJ(),

              //MAN YEN
              bonus_income: yup
                .number()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(
                  convertManYenToYen(VALIDATE_MAX.MAN_YEN),
                  messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
                ),
              // .requiredNumberMCJ(),
              income_source: yup.array().of(yup.string()),
              // .required(),
              tax_return: yup.string(),
              // .required(),
              type_tax_return: yup
                .array()
                .of(yup.string())
                .when('tax_return', {
                  is: (tax_return: SPTaxReturnType) =>
                    tax_return === SPTaxReturnType.YES,
                  then: yup
                    .array()
                    .of(yup.string())
                    .required(MESSAGE_REGEX.RADIO_REQUIRED),
                  otherwise: yup.array().of(yup.string()),
                }),
              other_type_tax_return: yup.string().when('type_tax_return', {
                is: (type_tax_return: FileATaxReturnType[] = []) =>
                  type_tax_return?.includes(FileATaxReturnType.OTHERS),
                then: yup.string().required(MESSAGE_REGEX.REQUIRED),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
                otherwise: yup.string(),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
              }),
              transfer_office: yup.string(),
              // .required(),
              transfer_office_name_kanji: yup.string().when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup
                  .string()
                  .max(48)
                  .required(MESSAGE_REGEX.REQUIRED)
                  .matches(
                    regex.kanjiFullwidthHaveNumber,
                    MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                  ),
                otherwise: yup
                  .string()
                  .max(48)
                  .matches(
                    regex.kanjiFullwidthHaveNumber,
                    MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                  ),
              }),
              transfer_office_name_kana: yup.string().when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup
                  .string()
                  .max(48)
                  .required(MESSAGE_REGEX.REQUIRED)
                  .matches(
                    regex.kanaHalfwidthHaveNumber,
                    MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
                  ),
                otherwise: yup
                  .string()
                  .max(48)
                  .matches(
                    regex.kanaHalfwidthHaveNumber,
                    MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
                  ),
              }),
              transfer_office_phone_number: yup
                .string()
                // .max(
                //   VALIDATE_MAX.HOME_PHONE,
                //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
                // )
                // .min(
                //   VALIDATE_MIN.HOME_PHONE,
                //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
                // )
                // .noZeroPhoneNumber()
                // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_ADMIN)
                // .matches(
                //   regex.freePhones,
                //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
                // )
                .when('transfer_office', {
                  is: (transfer_office: DispatchType) =>
                    transfer_office === DispatchType.YES,
                  then: yup.string().required(MESSAGE_REGEX.REQUIRED),
                }),
              transfer_office_postal_code: yup
                .string()
                .when('transfer_office', {
                  is: (transfer_office: DispatchType) =>
                    transfer_office === DispatchType.YES,
                  then: yup
                    .string()
                    .trim()
                    .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
                    .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
                    .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE)
                    .required(MESSAGE_REGEX.REQUIRED),
                  otherwise: yup.string(),
                }),
              transfer_office_prefecture_kanji: yup
                .string()
                .when('transfer_office', {
                  is: (transfer_office: DispatchType) =>
                    transfer_office === DispatchType.YES,
                  then: yup
                    .string()
                    .max(8)
                    .matches(
                      regex.kanjiFullwidth,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH
                    )
                    .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
                    .label('出向（派遣）先 都道府県'),
                  otherwise: yup
                    .string()
                    .max(8)
                    .matches(
                      regex.kanjiFullwidth,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH
                    ),
                }),
              transfer_office_city_kanji: yup.string().when('transfer_office', {
                is: (transfer_office: DispatchType) =>
                  transfer_office === DispatchType.YES,
                then: yup
                  .string()
                  .max(20)
                  .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH)
                  .required(MESSAGE_REGEX.REQUIRED),
                otherwise: yup
                  .string()
                  .max(20)
                  .matches(
                    regex.kanjiFullwidth,
                    MESSAGE_REGEX.KANJI_FULL_WIDTH
                  ),
              }),
              transfer_office_district_kanji: yup
                .string()
                .when('transfer_office', {
                  is: (transfer_office: DispatchType) =>
                    transfer_office === DispatchType.YES,
                  then: yup
                    .string()
                    .max(40)
                    .matches(
                      regex.kanjiFullwidthHaveNumber,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                    )
                    .required(MESSAGE_REGEX.REQUIRED),
                  otherwise: yup
                    .string()
                    .max(40)
                    .matches(
                      regex.kanjiFullwidthHaveNumber,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                    ),
                }),
              transfer_office_other_address_kanji: yup
                .string()
                .when('transfer_office', {
                  is: (transfer_office: DispatchType) =>
                    transfer_office === DispatchType.YES,
                  then: yup
                    .string()
                    .max(99)
                    .matches(
                      regex.kanjiFullwidthHaveNumber,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                    )
                    .required(MESSAGE_REGEX.REQUIRED),
                  otherwise: yup
                    .string()
                    .max(99)
                    .matches(
                      regex.kanjiFullwidthHaveNumber,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                    ),
                }),
              current_home_loan: yup.string().when('applicant_detail_type', {
                is: (applicant_detail_type: ApplicantDetailType) =>
                  applicant_detail_type === ApplicantDetailType.MAIN,
                then: yup.string(),
                // .required(),
                otherwise: yup.string(),
              }),
              other_occupation: yup.string().when('occupation', {
                is: (occupation: OccupationType) =>
                  occupation === OccupationType.OTHERS,
                then: yup.string().max(40).required(MESSAGE_REGEX.REQUIRED),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
                otherwise: yup.string().max(40),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
              }),
              other_industry: yup.string().when('industry', {
                is: (industry: WorkIndustryType) =>
                  industry === WorkIndustryType.OTHERS,
                then: yup.string().max(40).required(MESSAGE_REGEX.REQUIRED),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
                otherwise: yup.string().max(40),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
              }),
              other_occupation_detail: yup.string().when('occupation_detail', {
                is: (occupation_detail: OccupationDetailType) =>
                  occupation_detail === OccupationDetailType.OTHERS,
                then: yup.string().max(40).required(MESSAGE_REGEX.REQUIRED),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
                otherwise: yup.string().max(40),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
              }),
              maternity_paternity_leave_status: yup.string(),
              maternity_paternity_leave_start_time: yup
                .string()
                .when('maternity_paternity_leave_status', {
                  is: (leave_status: LeaveType) =>
                    leave_status === LeaveType.SCHEDULED_ACQUISITION,
                  then: yup
                    .string()
                    .required(MESSAGE_REGEX.REQUIRED)
                    .test({
                      message:
                        MESSAGE_REGEX.SPECIFY_A_FUTURE_TIME_TO_START_COLLECT_MATERNITY_LEAVE,
                      test(value, context) {
                        if (!value) return true;
                        const [year, month] = value.split('/');
                        const loan_desired_borrowing_date = get(
                          context,
                          'options.context.parent.p_borrowing_details_attributes[0].loan_desired_borrowing_date'
                        );
                        if (!loan_desired_borrowing_date) return true;
                        const currentMonth =
                          dayjs(loan_desired_borrowing_date).month() + 1;
                        const futureYear =
                          dayjs(loan_desired_borrowing_date).year() + 4;
                        return !(
                          futureYear - +year > 4 ||
                          (futureYear - +year === 4 && +month < currentMonth)
                        );
                      },
                    }),
                  otherwise: yup.string().test({
                    message:
                      MESSAGE_REGEX.SPECIFY_A_PAST_TIME_TO_START_COLLECT_MATERNITY_LEAVE,
                    test(value, context) {
                      if (!value) return true;
                      const [year, month] = value.split('/');
                      const loan_desired_borrowing_date = get(
                        context,
                        'options.context.parent.p_borrowing_details_attributes[0].loan_desired_borrowing_date'
                      );
                      if (!loan_desired_borrowing_date) return true;
                      const currentMonth =
                        dayjs(loan_desired_borrowing_date).month() + 1;
                      const pastYear =
                        dayjs(loan_desired_borrowing_date).year() - 4;
                      return !(
                        +year - pastYear > 4 ||
                        (+year - pastYear === 4 && +month > currentMonth)
                      );
                    },
                  }),
                }),
              maternity_paternity_leave_end_time: yup
                .string()
                .when('maternity_paternity_leave_status', {
                  is: (leave_status: LeaveType) =>
                    leave_status === LeaveType.SCHEDULED_ACQUISITION ||
                    leave_status === LeaveType.ACQUIRING,
                  then: yup
                    .string()
                    .required(MESSAGE_REGEX.REQUIRED)
                    .test({
                      message:
                        MESSAGE_REGEX.SPECIFY_A_FUTURE_TIME_TO_END_COLLECT_MATERNITY_LEAVE,
                      test(value, context) {
                        if (!value) return true;
                        const [year, month] = value.split('/');
                        const loan_desired_borrowing_date = get(
                          context,
                          'options.context.parent.p_borrowing_details_attributes[0].loan_desired_borrowing_date'
                        );
                        if (!loan_desired_borrowing_date) return true;
                        const currentMonth =
                          dayjs(loan_desired_borrowing_date).month() + 1;
                        const futureYear =
                          dayjs(loan_desired_borrowing_date).year() + 4;
                        return !(
                          futureYear - +year > 4 ||
                          (futureYear - +year === 4 && +month < currentMonth)
                        );
                      },
                    }),
                  otherwise: yup.string().test({
                    message:
                      MESSAGE_REGEX.SPECIFY_A_PAST_TIME_TO_END_COLLECT_MATERNITY_LEAVE,
                    test(value, context) {
                      if (!value) return true;
                      const [year, month] = value.split('/');
                      const loan_desired_borrowing_date = get(
                        context,
                        'options.context.parent.p_borrowing_details_attributes[0].loan_desired_borrowing_date'
                      );
                      if (!loan_desired_borrowing_date) return true;
                      const currentMonth =
                        dayjs(loan_desired_borrowing_date).month() + 1;
                      const currentYear = dayjs(
                        loan_desired_borrowing_date
                      ).year();
                      return !(
                        +year - currentYear > 0 ||
                        (+year - currentYear === 0 && +month > currentMonth)
                      );
                    },
                  }),
                })
                .test({
                  message:
                    MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_ACQUISITION_START_TIME,
                  test(value) {
                    if (
                      !value &&
                      !this.parent.maternity_paternity_leave_start_time
                    )
                      return true;
                    if (
                      !value ||
                      !this.parent.maternity_paternity_leave_start_time
                    )
                      return true;
                    const [year, month] = value.split('/');
                    const endTime = dayjs(`${year}-${month}`);
                    return endTime.isSameOrAfter(
                      dayjs(this.parent.maternity_paternity_leave_start_time),
                      'month'
                    );
                  },
                }),
              // .required(),
              nursing_leave_status: yup.string(),
              birthday: yup.string(),
              borrowing_status: yup.string(),
              buyingand_selling_schedule_type: yup
                .string()
                .when(['current_residence', 'applicant_detail_type'], {
                  is: (
                    current_residence: CurrentResidenceType,
                    applicant_detail_type: ApplicantDetailType
                  ) =>
                    current_residence ===
                      CurrentResidenceType.PRIVATE_APARTMENT &&
                    applicant_detail_type === ApplicantDetailType.MAIN,
                  then: yup.string(),
                  otherwise: yup.string(),
                }),
              city_kana: yup
                .string()
                // .required()
                .max(20)
                .matches(
                  regex.kanaHalfwidthHaveSpace,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
                ),
              city_kanji: yup
                .string()
                // .required()
                .max(20)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              current_residence: yup.string().when('applicant_detail_type', {
                is: (applicant_detail_type: ApplicantDetailType) =>
                  applicant_detail_type === ApplicantDetailType.MAIN,
                then: yup.string(),
                otherwise: yup.string(),
              }),
              current_residence_floor_area: yup
                .number()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .when('applicant_detail_type', {
                  is: (applicant_detail_type: ApplicantDetailType) =>
                    applicant_detail_type === ApplicantDetailType.MAIN,
                  then: yup
                    .number()
                    .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                    .min(0)
                    .max(9999999.99, messageOverMaxNumber(9999999.99)),
                  otherwise: yup
                    .number()
                    .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                    .min(0)
                    .max(9999999.99, messageOverMaxNumber(9999999.99)),
                }),
              district_kana: yup
                .string()
                // .required()
                .max(30)
                .matches(
                  regex.kanaHalfwidthHaveNumber,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
                ),
              district_kanji: yup
                .string()
                .max(40)
                .matches(
                  regex.kanjiFullwidthHaveNumber,
                  MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              emergency_contact_number: yup.string(),
              // .max(
              //   VALIDATE_MAX.MOBILE_PHONE,
              //   MESSAGE_REGEX.EMERGENCY_PHONE_ADMIN
              // )
              // .test(
              //   'emergency_contact_number_test',
              //   MESSAGE_REGEX.EMERGENCY_PHONE_ADMIN,
              //   (value) => {
              //     if (!value) return true;
              //     // const validFixedPhone = regex.fixedPhones.test(value);
              //     // const validMobilePhone = regex.mobilePhone.test(value);
              //     // return validMobilePhone || validFixedPhone;
              //     const validPhone = regex.freePhones.test(value);
              //     return validPhone;
              //   }
              // ),
              // .noZeroPhoneNumber(),
              // .required(),
              first_name_kana: yup
                .string()
                .trim()
                // .required()
                .max(48)
                .matches(
                  regex.kanaHalfwidthHaveSpace,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
                ),
              first_name_kanji: yup
                .string()
                .trim()
                // .required()
                .max(48)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              has_join_guarantor: yup.string(),
              home_phone_number: yup
                .string()
                // .max(
                //   VALIDATE_MAX.HOME_PHONE,
                //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
                // )
                // .min(
                //   VALIDATE_MIN.HOME_PHONE,
                //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
                // )
                // .noZeroPhoneNumber()
                // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_ADMIN)
                // .matches(
                //   regex.freePhones,
                //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
                // )
                .when('mobile_phone_number', {
                  is: (phoneNumber: string) =>
                    !phoneNumber || phoneNumber.length === 0,
                  then: yup.string(),
                  // .required(),
                }),
              mobile_phone_number: yup
                .string()
                // .max(
                //   VALIDATE_MAX.MOBILE_PHONE,
                //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
                // )
                // .min(
                //   VALIDATE_MAX.MOBILE_PHONE,
                //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
                // )
                // .noZeroPhoneNumber()
                // .matches(regex.mobilePhone, MESSAGE_REGEX.MOBILE_PHONE_ADMIN)
                // .matches(
                //   regex.freePhones,
                //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
                // )
                .when('home_phone_number', {
                  is: (homePhone: string) =>
                    !homePhone || homePhone.length === 0,
                  then: yup.string(),
                  // .required(),
                }),
              identity_verification: yup.string(),
              last_name_kana: yup
                .string()
                .trim()
                // .required()
                .max(48)
                .matches(
                  regex.kanaHalfwidthHaveSpace,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
                ),
              last_name_kanji: yup
                .string()
                .trim()
                // .required()
                .max(48)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              lived_length_month_num: yup
                .number()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .when('applicant_detail_type', {
                  is: (applicant_detail_type: ApplicantDetailType) =>
                    applicant_detail_type === ApplicantDetailType.MAIN,
                  then: yup
                    .number()
                    .integer()
                    .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                    .min(0)
                    .max(12),
                  // .required(),
                  otherwise: yup
                    .number()
                    .integer()
                    .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                    .min(0)
                    .max(12),
                }),
              lived_length_year_num: yup
                .number()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .when('applicant_detail_type', {
                  is: (applicant_detail_type: ApplicantDetailType) =>
                    applicant_detail_type === ApplicantDetailType.MAIN,
                  then: yup
                    .number()
                    .integer()
                    .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                    .min(1)
                    .max(98),
                  // .required(),
                  otherwise: yup
                    .number()
                    .integer()
                    .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                    .min(1)
                    .max(98),
                }),
              nationality: yup.string(),
              other_address_kana: yup
                .string()
                .max(138)
                .matches(
                  regex.kanaHalfwidthHaveNumber,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              other_address_kanji: yup
                .string()
                .max(99)
                .matches(
                  regex.kanjiFullwidthHaveNumber,
                  MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                ),
              // .required(),
              other_buyingand_selling_schedule_type: yup
                .string()
                .when(
                  [
                    'current_residence',
                    'buyingand_selling_schedule_type',
                    'applicant_detail_type',
                  ],
                  {
                    is: (
                      current_residence: CurrentResidenceType,
                      buyingand_selling_schedule_type: BuyingandSellingScheduleType,
                      applicant_detail_type: ApplicantDetailType
                    ) =>
                      current_residence ===
                        CurrentResidenceType.PRIVATE_APARTMENT &&
                      buyingand_selling_schedule_type ===
                        BuyingandSellingScheduleType.OTHERS &&
                      applicant_detail_type === ApplicantDetailType.MAIN,
                    then: yup
                      .string()
                      .max(40, MESSAGE_REGEX.OTHER_SELLING_SCHEDULE_TYPE_LIMIT)
                      // .matches(
                      //   regex.kanjiFullwidthHaveNumber,
                      //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                      // )
                      .required(MESSAGE_REGEX.REQUIRED),
                    otherwise: yup
                      .string()
                      .max(40, MESSAGE_REGEX.OTHER_SELLING_SCHEDULE_TYPE_LIMIT),
                    // .matches(
                    //   regex.kanjiFullwidthHaveNumber,
                    //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                    // ),
                  }
                ),
              other_reason_acquire_home: yup
                .string()
                .when('reason_acquire_home', {
                  is: (reason_acquire_home: ReasonsForNeedingHousingType) =>
                    reason_acquire_home === ReasonsForNeedingHousingType.OTHERS,
                  then: yup
                    .string()
                    .max(40)
                    // .matches(
                    //   regex.kanjiFullwidthHaveNumber,
                    //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                    // )
                    .required(MESSAGE_REGEX.REQUIRED),
                  otherwise: yup.string(),
                }),
              owner_email: yup
                .string()
                .max(128)
                .matches(regex.email, MESSAGE_REGEX.EMAIL)
                // .required()
                .label('メールアドレス'),
              owner_full_name: yup
                .string()
                .when(['current_residence', 'applicant_detail_type'], {
                  is: (
                    current_residence: CurrentResidenceType,
                    applicant_detail_type: ApplicantDetailType
                  ) =>
                    current_residence ===
                      CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
                    applicant_detail_type === ApplicantDetailType.MAIN,
                  then: yup
                    .string()
                    .matches(
                      regex.kanjiFullwidth,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH
                    ),
                  otherwise: yup
                    .string()
                    .matches(
                      regex.kanjiFullwidth,
                      MESSAGE_REGEX.KANJI_FULL_WIDTH
                    ),
                }),
              owner_relationship: yup
                .string()
                .when(['current_residence', 'applicant_detail_type'], {
                  is: (
                    current_residence: CurrentResidenceType,
                    applicant_detail_type: ApplicantDetailType
                  ) =>
                    current_residence ===
                      CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
                    applicant_detail_type === ApplicantDetailType.MAIN,
                  then: yup.string(),
                  otherwise: yup.string(),
                }),
              postal_code: yup
                .string()
                .trim()
                .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
                .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
                // .required()
                .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE),
              prefecture_kana: yup
                .string()
                .max(20)
                .matches(
                  regex.kanaHalfwidthHaveSpace,
                  MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
                ),
              // .required(),
              prefecture_kanji: yup
                .string()
                // .required()
                .max(20)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              reason_acquire_home: yup.string().when('loan_target', {
                is: (loan_target: PurposeType) =>
                  loan_target !== PurposeType.EIGHT,
                then: yup.string(),
                // .required(),
                otherwise: yup.string(),
              }),
              // reason_acquire_home: yup.string().test({
              //   message: MESSAGE_REGEX.REQUIRED,
              //   test(this: Context, value) {
              //     const { from } = this;
              //
              //     if (
              //       !!from &&
              //       from?.[1]?.value?.loan_target !== PurposeType.EIGHT
              //     ) {
              //       return !!value;
              //     }
              //     return true;
              //   },
              // }),
              relationship_to_applicant: yup
                .string()
                .when('applicant_detail_type', {
                  is: (applicant_detail_type: ApplicantDetailType) =>
                    applicant_detail_type === ApplicantDetailType.MAIN,
                  then: yup.string(),
                  otherwise: yup.string(),
                }),
              scheduled_time_sell_house: yup
                .string()
                .when(
                  [
                    'current_residence',
                    'buyingand_selling_schedule_type',
                    'applicant_detail_type',
                  ],
                  {
                    is: (
                      current_residence: CurrentResidenceType,
                      buyingand_selling_schedule_type: BuyingandSellingScheduleType,
                      applicant_detail_type: ApplicantDetailType
                    ) =>
                      current_residence ===
                        CurrentResidenceType.PRIVATE_APARTMENT &&
                      buyingand_selling_schedule_type ===
                        BuyingandSellingScheduleType.SALE &&
                      applicant_detail_type === ApplicantDetailType.MAIN,
                    then: yup.string(),
                    otherwise: yup.string(),
                  }
                ),
              sex: yup.string(),
            },
            [['mobile_phone_number', 'home_phone_number']]
          )
        ),
      },
      []
    ),
  });

// tab 担保提供者 <=> Step 4 guarantor SP
const tabFourSchema = (peopleAttributeIndex: number) =>
  yup.object({
    //tab 4
    p_application_header: yup.object().shape({
      collateral_type: yup.string(),
      // .required(),
      p_join_guarantors_attributes: yup.array().of(
        yup.object().shape(
          {
            last_name_kanji: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            first_name_kanji: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            last_name_kana: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            first_name_kana: yup
              .string()
              .trim()
              .required(MESSAGE_REGEX.REQUIRED)
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            sex: yup.string(),
            guarantor_relationship_name: yup
              .string()
              .max(40)
              // .required()
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            guarantor_relationship_to_applicant: yup.string(),
            // .required(),
            other_relationship_to_applicant: yup
              .string()
              .max(40)
              // .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH)
              .when('guarantor_relationship_to_applicant', {
                is: (relationship_to_applicant: RelationshipToApplicantType) =>
                  relationship_to_applicant ===
                  RelationshipToApplicantType.OTHERS,
                then: yup.string(),
                // .required(),
              }),
            birthday: yup
              .string()
              .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
              .label('生年月日'),
            mobile_phone_number: yup
              .string()
              // .max(
              //   VALIDATE_MAX.MOBILE_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
              // )
              // .min(
              //   VALIDATE_MAX.MOBILE_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
              // )
              // .noZeroPhoneNumber()
              // .matches(
              //   regex.mobilePhone,
              //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
              // )
              // .matches(
              //   regex.freePhones,
              //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
              // )
              .when('home_phone_number', {
                is: (homePhone: string) => !homePhone || homePhone.length === 0,
                then: yup.string().required(),
              }),
            home_phone_number: yup
              .string()
              // .max(
              //   VALIDATE_MAX.HOME_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
              // )
              // .min(
              //   VALIDATE_MIN.HOME_PHONE,
              //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
              // )
              // .noZeroPhoneNumber()
              // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_ADMIN)
              // .matches(
              //   regex.freePhones,
              //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
              // )
              .when('mobile_phone_number', {
                is: (mobiPhone: string) => !mobiPhone || mobiPhone.length === 0,
                then: yup.string().required(),
              }),
            emergency_contact_number: yup
              .string()
              // .max(
              //   VALIDATE_MAX.MOBILE_PHONE,
              //   MESSAGE_REGEX.EMERGENCY_PHONE_ADMIN
              // )
              // .test(
              //   'emergency_contact_number_test',
              //   MESSAGE_REGEX.EMERGENCY_PHONE_ADMIN,
              //   (value) => {
              //     if (!value) return true;
              //     // const validFixedPhone = regex.fixedPhones.test(value);
              //     // const validMobilePhone = regex.mobilePhone.test(value);
              //     // return validMobilePhone || validFixedPhone;
              //     const validPhone = regex.freePhones.test(value);
              //     return validPhone;
              //   }
              // )
              // .noZeroPhoneNumber()
              .when('home_phone_number', {
                is: (homePhone: string) => !homePhone || homePhone.length === 0,
                then: yup.string(),
                // .required(),
              }),
            // .required(),
            postal_code: yup
              .string()
              .trim()
              .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
              .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
              .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE),
            // .required(),
            prefecture_kanji: yup
              .string()
              .max(8)
              .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
              .label('都道府県')
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            // .required(),
            city_kanji: yup
              .string()
              .max(20)
              .required(MESSAGE_REGEX.REQUIRED)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            // .required(),
            district_kanji: yup
              .string()
              .max(40)
              .required(MESSAGE_REGEX.REQUIRED)
              .matches(
                regex.kanjiFullwidthHaveNumber,
                MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
              ),
            // .required(),
            other_address_kanji: yup
              .string()
              .max(99)
              .required(MESSAGE_REGEX.REQUIRED)
              .matches(
                regex.kanjiFullwidthHaveNumber,
                MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
              ),
            prefecture_kana: yup
              .string()
              .max(8)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            // .required(),
            city_kana: yup
              .string()
              .max(20)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            // .required(),
            district_kana: yup
              .string()
              // .required()
              .max(30)
              .matches(
                regex.kanaHalfwidthHaveNumber,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
              ),
            other_address_kana: yup
              .string()
              .max(99)
              .matches(
                regex.kanaHalfwidthHaveNumber,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
              ),
            // .required(),
            owner_email: yup
              .string()
              .max(128)
              .matches(regex.email, MESSAGE_REGEX.EMAIL)
              // .required()
              .label('メールアドレス'),
          },
          [['mobile_phone_number', 'home_phone_number']]
        )
      ),
    }),
  });

// Tab お住まい <=> Step 4 SP
const tabFiveSchema = (peopleAttributeIndex: number) =>
  yup.object({
    p_application_header: yup.object().shape({
      non_resident_reason: yup.string().when('person_occupancy', {
        is: (person_occupancy: PersonOccupancyType) =>
          person_occupancy === PersonOccupancyType.NO,
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string().max(40),
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
        // ),
      }),
      condominium_name: yup.string().when('loan_target', {
        is: (loan_target: PurposeType) =>
          loan_target === PurposeType.THREE || loan_target === PurposeType.FOUR,
        then: yup
          .string()
          .max(40)
          .matches(
            regex.kanjiFullwidthHaveNumber,
            MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
          ),
        // .required(),
        otherwise: yup
          .string()
          .max(40)
          .matches(
            regex.kanjiFullwidthHaveNumber,
            MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
          ),
      }),
      land_ownership: yup.string(),
      // .requiredMCJ(),
      purchase_purpose: yup.string(),
      // .requiredMCJ(),
      planning_area: yup.string(),
      // .requiredMCJ(),
      other_planning_area: yup.string().when('planning_area', {
        is: (planning_area: PlanningAreaType) =>
          planning_area === PlanningAreaType.OTHERS,
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string().max(40),
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
        // ),
      }),
      rebuilding_reason: yup.string(),
      // .requiredMCJ(),
      other_rebuilding_reason: yup.string().when('rebuilding_reason', {
        is: (rebuilding_reason: RebuildingReasonType) =>
          rebuilding_reason === RebuildingReasonType.OTHERS,
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string().max(40),
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
        // ),
      }),
      flat_35_applicable_plan: yup.string(),
      // .requiredMCJ(),
      maintenance_type: yup.string(),
      // .requiredMCJ(),
      region_type: yup.string(),
      // .requiredMCJ(),
      flat_35_application: yup.string(),
      // .requiredMCJ(),
      person_occupancy: yup.string(),
      // .required(),
      planned_cohabitant: yup.array().of(yup.string()),
      // .required(), //あなた以外の入居予定者
      housemate_number: yup //入居家族 現在申込人との同居有無
        .number()
        .integer()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(99),
      business_ability: yup.array().of(yup.string()),
      // business_ability: yup.array().test({
      //   message: MESSAGE_REGEX.BUSINESS_ABILITY,
      //   test(value) {
      //     if (!value) return true;
      //     if (value.length === 0) return true;
      //     return (
      //       value.length > 0 &&
      //       (value.includes(
      //         LoanTargetPropertyType.ELECTRICITY_SALE_FROM_SOLAR_POWER_GENERATION
      //       ) ||
      //         value.includes(LoanTargetPropertyType.OFFICE_STORE))
      //     );
      //   },
      // }),
      // .required(),
      property_postal_code: yup
        .string()
        .trim()
        // .required()
        .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
        .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
        .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE),
      collateral_city: yup
        .string()
        .max(20)
        .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
      // .required(),
      collateral_lot_number: yup
        .string()
        .max(20)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
      // .required(),
      collateral_address_kana: yup
        .string()
        // .required()
        .max(138)
        .matches(
          regex.kanaHalfwidthHaveNumber,
          MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_NUMBER
        ),
      collateral_type: yup.string(),
      // .required(),
      acquisition_time_of_the_land: yup.string(),
      // .required(),
      joint_ownership_division: yup.string(),
      // .required(),
      building_ratio_numerator: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(100, messageOverMaxNumber(100)),
      // .required(),
      building_ratio_denominator: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(100, messageOverMaxNumber(100)),
      // .required(),
      land_ratio_numerator: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(100, messageOverMaxNumber(100)),
      // .required(),
      land_ratio_denominator: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(100, messageOverMaxNumber(100)),
      // .required(),
      collateral_land_area: yup.number().when('loan_target', {
        is: (loan_target: PurposeType) =>
          loan_target !== PurposeType.THREE && loan_target !== PurposeType.FOUR,
        then: yup
          .number()
          .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください。')
          .min(1)
          .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
        // .test(
        //   'is-greater-than-collateral-total-floor-area',
        //   MESSAGE_REGEX.ERROR_AREA,
        //   function (value) {
        //     const { collateral_total_floor_area } = this.parent || {};
        //     return (
        //       value === undefined ||
        //       collateral_total_floor_area === undefined ||
        //       value >= collateral_total_floor_area
        //     );
        //   }
        // ),
        // .required(),
        otherwise: yup
          .number()
          .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください。')
          .min(1)
          .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
        // .test(
        //   'is-greater-than-collateral-total-floor-area',
        //   MESSAGE_REGEX.ERROR_AREA_SECOND,
        //   function (value) {
        //     const { collateral_total_floor_area } = this.parent || {};
        //     return (
        //       value === undefined ||
        //       collateral_total_floor_area === undefined ||
        //       value >= collateral_total_floor_area
        //     );
        //   }
        // ),
      }),
      collateral_total_floor_area: yup
        .number()
        .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください。')
        .min(1)
        .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
      // .required(),
      occupied_area: yup.number().when('loan_target', {
        is: (loan_target: PurposeType) =>
          loan_target === PurposeType.THREE || loan_target === PurposeType.FOUR,
        then: yup
          .number()
          .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください。')
          .min(1)
          .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
        // .test(
        //   'is-greater-than-collateral-total-floor-area',
        //   MESSAGE_REGEX.ERROR_AREA_SECOND,
        //   function (value) {
        //     const { collateral_total_floor_area } = this.parent || {};
        //     return (
        //       value === undefined ||
        //       collateral_total_floor_area === undefined ||
        //       value >= collateral_total_floor_area
        //     );
        //   }
        // ),
        // .required(),
        otherwise: yup
          .number()
          .typeError('整数部7桁以内かつ小数部2桁の数値で入力ください。')
          .min(1)
          .max(9999999.995, '整数部7桁以内かつ小数部2桁の数値で入力ください。'),
        // .test(
        //   'is-greater-than-collateral-total-floor-area',
        //   MESSAGE_REGEX.ERROR_AREA,
        //   function (value) {
        //     const { collateral_total_floor_area } = this.parent || {};
        //     return (
        //       value === undefined ||
        //       collateral_total_floor_area === undefined ||
        //       value >= collateral_total_floor_area
        //     );
        //   }
        // ),
      }),
      other_relationship: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[] = []) =>
          !!planned_cohabitant &&
          planned_cohabitant?.includes(PlannedCohabitantType.OTHERS),
        then: yup
          .string()
          .max(40)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .required(MESSAGE_REGEX.REQUIRED),
        otherwise: yup.string(),
      }),
      property_information_url: yup
        .string()
        .max(255)
        .matches(regex.url, MESSAGE_REGEX.URL_INVALID),
      p_applicant_people_attributes: yup.array().validArrayByIndex(
        peopleAttributeIndex,
        yup.object().shape({
          postal_code: yup
            .string()
            .trim()
            // .required()
            .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
            .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
            .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE),
          prefecture_kanji: yup
            .string()
            .max(8)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
          // .required(),
          lived_length_year_num: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.MAIN,
              then: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                // .min(1, MESSAGE_REGEX.GREATER_THAN_ZERO)
                .max(98),
              // .required(),
              otherwise: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                // .min(1, MESSAGE_REGEX.GREATER_THAN_ZERO)
                .max(98),
            }),
          lived_length_month_num: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.MAIN,
              then: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(12),
              // .required(),
              otherwise: yup
                .number()
                .integer()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(12),
            }),
          current_residence: yup.string().when('applicant_detail_type', {
            is: (applicant_detail_type: ApplicantDetailType) =>
              applicant_detail_type === ApplicantDetailType.MAIN,
            then: yup.string(),
            // .required(),
            otherwise: yup.string(),
          }),
          reason_acquire_home: yup.string().when('loan_target', {
            is: (loan_target: PurposeType) => loan_target !== PurposeType.EIGHT,
            then: yup.string(),
            // .required(),
            otherwise: yup.string(),
          }),
          other_reason_acquire_home: yup.string().when('reason_acquire_home', {
            is: (reason_acquire_home: ReasonsForNeedingHousingType) =>
              reason_acquire_home === ReasonsForNeedingHousingType.OTHERS,
            then: yup
              .string()
              // .matches(
              //   regex.kanjiFullwidthHaveNumber,
              //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
              // )
              .required(MESSAGE_REGEX.REQUIRED),
            otherwise: yup.string(),
          }),
          buyingand_selling_schedule_type: yup
            .string()
            .when(['current_residence', 'applicant_detail_type'], {
              is: (
                current_residence: CurrentResidenceType,
                applicant_detail_type: ApplicantDetailType
              ) =>
                current_residence === CurrentResidenceType.PRIVATE_APARTMENT &&
                applicant_detail_type === ApplicantDetailType.MAIN,
              then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
              otherwise: yup.string(),
            }),
          other_buyingand_selling_schedule_type: yup
            .string()
            .when(
              [
                'buyingand_selling_schedule_type',
                'current_residence',
                'applicant_detail_type',
              ],
              {
                is: (
                  buyingand_selling_schedule_type: BuyingandSellingScheduleType,
                  current_residence: CurrentResidenceType,
                  applicant_detail_type: ApplicantDetailType
                ) =>
                  buyingand_selling_schedule_type ===
                    BuyingandSellingScheduleType.OTHERS &&
                  current_residence ===
                    CurrentResidenceType.PRIVATE_APARTMENT &&
                  applicant_detail_type === ApplicantDetailType.MAIN,
                then: yup
                  .string()
                  .max(40, MESSAGE_REGEX.OTHER_SELLING_SCHEDULE_TYPE_LIMIT)
                  // .matches(
                  //   regex.kanjiFullwidthHaveNumber,
                  //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                  // )
                  .required(MESSAGE_REGEX.REQUIRED),
                otherwise: yup
                  .string()
                  .max(40, MESSAGE_REGEX.OTHER_SELLING_SCHEDULE_TYPE_LIMIT),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
              }
            ),
          expected_house_selling_price: yup
            .number()
            .when(
              [
                'current_residence',
                'buyingand_selling_schedule_type',
                'applicant_detail_type',
              ],
              {
                is: (
                  current_residence: CurrentResidenceType,
                  buyingand_selling_schedule_type: BuyingandSellingScheduleType,
                  applicant_detail_type: ApplicantDetailType
                ) =>
                  applicant_detail_type === ApplicantDetailType.MAIN &&
                  buyingand_selling_schedule_type ===
                    BuyingandSellingScheduleType.SALE &&
                  current_residence === CurrentResidenceType.PRIVATE_APARTMENT,
                then: yup
                  .number()
                  .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                  .min(0)
                  .max(
                    convertManYenToYen(VALIDATE_MAX.MAN_YEN),
                    messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
                  )
                  .test(
                    'divisible-by-10',
                    MESSAGE_REGEX.ENTER_UNITS_100000YEN,
                    (borrow) => +(borrow || 0) % 10 === 0
                  ),
                // .required(),
                otherwise: yup
                  .number()
                  .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                  .min(0)
                  .max(
                    convertManYenToYen(VALIDATE_MAX.MAN_YEN),
                    messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
                  )
                  .test(
                    'divisible-by-10',
                    MESSAGE_REGEX.ENTER_UNITS_100000YEN,
                    (borrow) => +(borrow || 0) % 10 === 0
                  ),
              }
            ),
          current_residence_floor_area: yup
            .number()
            .when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.MAIN,
              then: yup
                .number()
                .typeError(MESSAGE_REGEX.TYPE_NUMBER)
                .min(0)
                .max(9999999.99, messageOverMaxNumber(9999999.99)),
              // .requiredNumberMCJ(),
              otherwise: yup
                .number()
                .min(0)
                .max(9999999.99, messageOverMaxNumber(9999999.99)),
            }),
          owner_full_name: yup
            .string()
            .when(['current_residence', 'applicant_detail_type'], {
              is: (
                current_residence: CurrentResidenceType,
                applicant_detail_type: ApplicantDetailType
              ) =>
                current_residence ===
                  CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
                applicant_detail_type === ApplicantDetailType.MAIN,
              then: yup
                .string()
                .max(48)
                .required(MESSAGE_REGEX.REQUIRED)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              // .required(),
              otherwise: yup
                .string()
                .max(48)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            }),
          owner_relationship: yup
            .string()
            .when(['current_residence', 'applicant_detail_type'], {
              is: (
                current_residence: CurrentResidenceType,
                applicant_detail_type: ApplicantDetailType
              ) =>
                current_residence ===
                  CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
                applicant_detail_type === ApplicantDetailType.MAIN,
              then: yup
                .string()
                .required(MESSAGE_REGEX.REQUIRED)
                .max(48)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
              otherwise: yup
                .string()
                .max(48)
                .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            }),
          scheduled_time_sell_house: yup
            .string()
            .when(
              [
                'current_residence',
                'buyingand_selling_schedule_type',
                'applicant_detail_type',
              ],
              {
                is: (
                  current_residence: CurrentResidenceType,
                  buyingand_selling_schedule_type: BuyingandSellingScheduleType,
                  applicant_detail_type: ApplicantDetailType
                ) =>
                  current_residence ===
                    CurrentResidenceType.PRIVATE_APARTMENT &&
                  buyingand_selling_schedule_type ===
                    BuyingandSellingScheduleType.SALE &&
                  applicant_detail_type === ApplicantDetailType.MAIN,
                then: yup.string(),
                // .required(),
                otherwise: yup.string(),
              }
            ),
          current_home_loan: yup
            .string()
            .when('applicant_detail_type', {
              is: (applicant_detail_type: ApplicantDetailType) =>
                applicant_detail_type === ApplicantDetailType.MAIN,
              then: yup.string(),
              // .required(),
              otherwise: yup.string(),
            })
            .when('current_residence', {
              is: (current_residence: CurrentResidenceType) =>
                current_residence === CurrentResidenceType.PRIVATE_APARTMENT,
              then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
            }),
          city_kanji: yup
            .string()
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
          // .required(),
        })
      ),

      p_residents_attributes: yup.array().of(
        yup.object().shape(
          {
            last_name_kanji: yup
              .string()
              .trim()
              // .required()
              .max(48)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            first_name_kanji: yup
              .string()
              .trim()
              // .required()
              .max(48)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            last_name_kana: yup
              .string()
              .trim()
              // .required()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            first_name_kana: yup
              .string()
              .trim()
              // .required()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
            relationship_name: yup
              .string()
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH)
              .max(48),
            // .requiredMCJ(), /// regex co sau
            nationality: yup.string(),
            birthday: yup.string(),
            loan_from_japan_housing_finance_agency: yup.string(),
            home_phone_number: yup.string(),
            // .max(
            //   VALIDATE_MAX.HOME_PHONE,
            //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
            // )
            // .min(
            //   VALIDATE_MIN.HOME_PHONE,
            //   MESSAGE_REGEX.MAX_LENGTH_FIXED_PHONE_ADMIN
            // )
            // .noZeroPhoneNumber()
            // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_ADMIN),
            // .matches(
            //   regex.freePhones,
            //   MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH
            // ),
            postal_code: yup
              .string()
              .trim()
              .min(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
              .max(VALIDATE_MAX.POSTAL_CODE, MESSAGE_REGEX.ZIP_CODE)
              .matches(regex.zipCode, MESSAGE_REGEX.ZIP_CODE)
              .noWhitespace(),
            prefecture_kanji: yup
              .string()
              // .requiredMCJ()
              .max(20)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            city_kanji: yup
              .string()
              // .requiredMCJ()
              .max(20)
              .matches(regex.kanjiFullwidth, MESSAGE_REGEX.KANJI_FULL_WIDTH),
            district_kanji: yup
              .string()
              // .requiredMCJ()
              .max(40)
              .matches(
                regex.kanjiFullwidthHaveNumber,
                MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
              ),
            other_address_kanji: yup
              .string()
              // .requiredMCJ()
              .max(99)
              .matches(
                regex.kanjiFullwidthHaveNumber,
                MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
              ),
            relationship: yup.string(),
            other_relationship: yup
              .string()
              .when('relationship', {
                is: (relationship: RelationshipType) =>
                  relationship === RelationshipType.OTHERS,
                then: yup.string().max(40),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
                // .required(),
                otherwise: yup.string().max(40),
                // .matches(
                //   regex.kanjiFullwidthHaveNumber,
                //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
                // ),
              })
              .when('planned_cohabitant', {
                is: (planned_cohabitant: PlannedCohabitantType[]) =>
                  !!planned_cohabitant &&
                  planned_cohabitant.includes(PlannedCohabitantType.OTHERS),
                then: yup.string().required(MESSAGE_REGEX.REQUIRED),
              }),
            sex: yup.string(),
            // full_name_kanji: yup.string(),
            // full_name_kana: yup.string(),
          },
          []
        )
      ),
    }),
  });

// Tab 現在の借入状況 <=> Step 5 SP
const tabSixSchema = (
  peopleAttributeIndex: number,
  loan_desired_borrowing_date?: string,
  created_at?: string
) =>
  yup.object({
    //tab 6
    p_application_header: yup.object().shape({
      p_applicant_people_attributes: yup.array().validArrayByIndex(
        peopleAttributeIndex,
        yup.object().shape({
          land_rent_to_be_paid: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(
              convertManYenToYen(VALIDATE_MAX.MAN_YEN),
              messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
            )
            .test({
              message: MESSAGE_REGEX.REQUIRED,
              test(this: Context, value) {
                const { from } = this;
                if (
                  !!from &&
                  !!(
                    from?.[1]?.value
                      .p_borrowings_attributes as Array<SPBorrowingAttribute>
                  )?.find(
                    (borrowing) =>
                      'scheduled_loan_payoff' in borrowing &&
                      borrowing.scheduled_loan_payoff ===
                        SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
                  ) &&
                  from?.[from.length - 1]?.value?.name_master_banks.includes(
                    CODEBANK.MCJ
                  )
                ) {
                  return !!value;
                }
                return true;
              },
            }),

          house_rent_to_be_paid: yup
            .number()
            .integer()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(
              convertManYenToYen(VALIDATE_MAX.MAN_YEN),
              messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
            )
            .test({
              message: MESSAGE_REGEX.REQUIRED,
              test(this: Context, value) {
                const { from } = this;
                if (
                  !!from &&
                  !!(
                    from?.[1]?.value
                      ?.p_borrowings_attributes as Array<SPBorrowingAttribute>
                  )?.find(
                    (borrowing) =>
                      'scheduled_loan_payoff' in borrowing &&
                      borrowing.scheduled_loan_payoff ===
                        SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
                  ) &&
                  from?.[from?.length - 1]?.value?.name_master_banks.includes(
                    CODEBANK.MCJ
                  )
                ) {
                  return !!value;
                }
                return true;
              },
            }),
        })
      ),
      other_complete_repayment_type: yup
        .string()
        .max(40)
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
        // )
        .when('completely_repayment_type', {
          is: (completely_repayment_type: FundsPairOffType) =>
            completely_repayment_type === FundsPairOffType.OTHERS,
          then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        }),
      refund_content: yup
        .string()
        .max(20)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
      refund_amount: yup
        .number()
        .integer()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(
          convertManYenToYen(VALIDATE_MAX.MAN_YEN),
          messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
        ),
      p_borrowings_attributes: yup.array().of(
        yup.object().shape({
          borrowing_status: yup.string(),
          // .required(),
          borrowing_type: yup.string(),
          // .required(),
          lender: yup
            .string()
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
            ),
          // .required(),
          borrower: yup.string(),
          // .required(),
          borrowing_from_housing_finance_agency: yup.string(),
          loan_purpose: yup.string().when('borrowing_type', {
            is: (borrowing_type: BorrowingType) =>
              borrowing_type === BorrowingType.RENTED_HOUSE ||
              borrowing_type === BorrowingType.SBI_LOAN,
            then: yup.string(),
            // .required(),
            otherwise: yup.string(),
          }),
          borrowing_category: yup.string(),
          card_expiry_date: yup
            .string()
            .test({
              message: MESSAGE_REGEX.PLEASE_SPECIFY_AFTER_CREATED_AT,
              test(value) {
                if (!value || !created_at) return true;
                const [year, month] = value.split('/');
                const endTime = dayjs(`${year}-${month}`);
                return endTime.isSameOrAfter(dayjs(created_at), 'month');
              },
            })
            .when('borrowing_type', {
              is: (borrowing_type: SPBorrowingType) =>
                borrowing_type === SPBorrowingType.CARD_LOAN,
              then: yup.string(),
              // .required(),
              otherwise: yup.string(),
            })
            .label('カード有効期限'),
          loan_start_date: yup
            .string()
            .test({
              message: MESSAGE_REGEX.PLEASE_SPECIFY_BEFORE_CREATED_AT,
              test(value) {
                if (!value || !created_at) return true;
                const [year, month] = value.split('/');
                const endTime = dayjs(`${year}-${month}`);
                return endTime.isSameOrBefore(dayjs(created_at), 'month');
              },
            })
            .label('当初借入年月'),
          // .required(),
          loan_amount: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(
              convertManYenToYen(VALIDATE_MAX.MAN_YEN),
              messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
            ),
          // .required(),
          current_balance_amount: yup
            .number()
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(
              convertManYenToYen(VALIDATE_MAX.MAN_YEN),
              messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
            ),
          // .required(),
          annual_repayment_amount: yup
            .number()
            .integer(MESSAGE_REGEX.TYPE_NUMBER)
            .typeError(MESSAGE_REGEX.TYPE_NUMBER)
            .min(0)
            .max(
              convertManYenToYen(VALIDATE_MAX.MAN_YEN),
              messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
            ),
          // .required(),
          include_in_examination: yup.string(),
          // .required(),
          scheduled_loan_payoff: yup.string().when('borrowing_type', {
            is: (borrowing_type: SPBorrowingType) =>
              borrowing_type !== SPBorrowingType.BUSINESS_LOAN,
            then: yup.string(),
            // .required(),
            otherwise: yup.string(),
          }),
          loan_deadline_date: yup
            .string()
            .test({
              message: MESSAGE_REGEX.PLEASE_SPECIFY_AFTER_CREATED_AT,
              test(value) {
                if (!value || !created_at) return true;
                const [year, month] = value.split('/');
                const endTime = dayjs(`${year}-${month}`);
                return endTime.isSameOrAfter(dayjs(created_at), 'month');
              },
            })
            .label('契約期限、最終期限／最終返済年月'),
          expected_repayment_date: yup
            .string()
            .test({
              message: MESSAGE_REGEX.PLEASE_SPECIFY_AFTER_CREATED_AT,
              test(value) {
                if (!value || !created_at) return true;
                const [year, month] = value.split('/');
                const endTime = dayjs(`${year}-${month}`);
                return endTime.isSameOrAfter(dayjs(created_at), 'month');
              },
            })
            .when(['borrowing_type', 'scheduled_loan_payoff'], {
              is: (
                borrowing_type: SPBorrowingType,
                scheduled_loan_payoff: SPScheduledLoanPayoffType
              ) =>
                borrowing_type !== SPBorrowingType.BUSINESS_LOAN &&
                scheduled_loan_payoff ===
                  SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY,
              then: yup.string(),
              // .required()
              otherwise: yup.string(),
            })
            .label('完済（予定）年月'),
          rental_room_number: yup.number().when('borrowing_type', {
            is: (borrowing_type: SPBorrowingType) =>
              borrowing_type === SPBorrowingType.HOUSING_LOAN,
            then: yup.number().min(0).typeError(MESSAGE_REGEX.TYPE_NUMBER),
            // .required(),
            otherwise: yup.number().min(0).typeError(MESSAGE_REGEX.TYPE_NUMBER),
          }),
          common_housing: yup.string().when('borrowing_type', {
            is: (borrowing_type: SPBorrowingType) =>
              borrowing_type === SPBorrowingType.APARTMENT_LOAN,
            then: yup.string(),
            // .required(),
            otherwise: yup.string(),
          }),
          estate_mortgage: yup.string().when('borrowing_type', {
            is: (borrowing_type: SPBorrowingType) =>
              borrowing_type === SPBorrowingType.APARTMENT_LOAN ||
              borrowing_type === SPBorrowingType.BUSINESS_LOAN ||
              borrowing_type === SPBorrowingType.CAR_LOAN ||
              borrowing_type === SPBorrowingType.EDUCATION_LOAN ||
              borrowing_type === SPBorrowingType.LOAN_FOR_LIVING_EXPENSES ||
              borrowing_type === SPBorrowingType.OTHERS,
            then: yup.string(),
            // .required(),
            otherwise: yup.string(),
          }),
          other_purpose: yup.string().max(40),
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
          // ),
        })
      ),
    }),
  });

// tab 資金計画 <=> Step 6 SP
const tabSevenSchema = yup.object({
  //tab 7
  p_application_header: yup.object().shape({
    house_purchase_price: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    land_purchase_price: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    additional_cost: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    require_funds_breakdown_mortgage: yup
      .number()
      .integer()
      // .required()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    land_price: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    // .required(),
    building_price: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    // .required(),
    land_and_building_price: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    // .required(),
    accessory_cost: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    refinancing_loan_balance: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    house_upgrade_cost: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    total_price_1: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    deposit_savings_1: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    real_estate_sale_price: yup
      .number()
      .integer()
      // .required()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    other_saving_amount: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    saving_amount: yup
      .number()
      .integer()
      // .required()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    amount_any_loans: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    // .required(),
    relative_donation_amount: yup
      .number()
      .integer()
      // .required()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    loan_amount: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    pair_loan_amount: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    other_procurement_breakdown: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    amount_others: yup
      .number()
      .integer()
      .typeError(MESSAGE_REGEX.TYPE_NUMBER)
      .min(0)
      .max(
        convertManYenToYen(VALIDATE_MAX.MAN_YEN),
        messageOverMaxManYen(VALIDATE_MAX.MAN_YEN)
      ),
    other_procurement_breakdown_content: yup
      .string()
      .when('other_procurement_breakdown', {
        is: (other_procurement_breakdown: number) =>
          other_procurement_breakdown,
        then: yup
          .string()
          .required(MESSAGE_REGEX.REQUIRED)
          .max(128, MESSAGE_REGEX.OTHER_PROCUREMENT_LIMIT),
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.KANJI_FULL_WIDTH_HAVE_NUMBER
        // ),
        otherwise: yup.string(),
      }),
    // .required(),
  }),
});

// tab 担当者情報 <=> Step 8 SP
const tabSalePersonInfoSchema = yup.object({
  p_application_header: yup.object().shape({
    sale_person_phone_number: yup.string(),
    // .max(
    //   VALIDATE_MAX.MOBILE_PHONE,
    //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
    // )
    // .min(
    //   VALIDATE_MAX.MOBILE_PHONE,
    //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_ADMIN
    // )
    // .noZeroPhoneNumber()
    // .matches(regex.mobilePhone, MESSAGE_REGEX.MOBILE_PHONE_ADMIN),
    // .matches(regex.freePhones, MESSAGE_REGEX.MOBILE_PHONE_ADMIN_WITH_DASH),
    sale_person_information: yup.object().shape({
      sale_person_email: yup
        .string()
        .max(128)
        .matches(regex.email, MESSAGE_REGEX.EMAIL)
        .label('メールアドレス'),
    }),
  }),
});
