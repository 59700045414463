import { MESSAGE_REGEX } from 'constant';
import { regex, yup } from 'libs';

export const validationSchema = yup.object({
  email: yup
    .string()
    .max(128)
    .matches(regex.email, MESSAGE_REGEX.EMAIL_SP)
    .required()
    .label('登録メールアドレス'),
  new_email: yup
    .string()
    .max(128)
    .matches(regex.email, MESSAGE_REGEX.EMAIL_SP)
    .required()
    .label('登録メールアドレス'),
  new_email_confirmation: yup
    .string()
    .max(128)
    .matches(regex.email, MESSAGE_REGEX.EMAIL_SP)
    .required()
    .label('登録メールアドレス')
    .when('new_email', {
      is: (val: string) => !!val?.length,
      then: yup
        .string()
        .oneOf(
          [yup.ref('new_email')],
          'メールアドレスとメールアドレス（確認用）が一致していません。'
        ),
    }),
});
