import { FC, useCallback } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { draftExitsSelector } from 'containers/AuthModal/selectors';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getNewSavedDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { useAppDispatch } from 'hooks';
import { CODEBANK } from 'constant';
import { routeNames } from 'navigations/routes';
import { currentPremilinarySelectors } from 'pages/Preliminaries/selectors';
import { DashboardItem } from './DashboardItem';
import { getPreliminaries, getUploadedFiles } from './thunk';

export type Data = {
  title: string;
  onClick?: () => void;
};
type DashboardItemsProps = {
  itemPerRow?: number;
};

export const DashboardItems: FC<DashboardItemsProps> = ({ itemPerRow = 4 }) => {
  const dispatch = useAppDispatch();
  const draftExits = useSelector(draftExitsSelector);
  const selectedPreliminary = useSelector(currentPremilinarySelectors);
  const navigate = useNavigate();
  const has_result = false;
  const data: Data[] = [
    {
      title: 'お申込み内容の入力を再開',
      onClick: () => {
        handleRecallSave();
      },
    },
    {
      title: 'お申込内容の確認・修正',
      onClick: () => {
        if (!!selectedPreliminary.id) {
          handleEditPreliminaries(selectedPreliminary.id);
        }
      },
    },
    {
      title: '資料再アップロード',
      onClick: () => {
        if (!!selectedPreliminary.id) {
          handleEditUploadedFiles(selectedPreliminary.id);
        }
      },
    },
    {
      title: '審査状況',
      onClick: () => {
        navigate(routeNames.ReviewStatus.path);
      },
    },
    {
      title: '審査結果',
      onClick: () => {
        navigate(routeNames.Result.path);
      },
    },
    {
      title: `みらいバンクからの\nご連絡事項`,
      onClick: () => {
        navigate(routeNames.ContactInformations.path);
      },
    },
    {
      title: `みらいバンクへの\nお問合せ`,
      onClick: () => {
        navigate(routeNames.InquiryPage.path);
      },
    },
    { title: '日本住宅ローン出力用のPDFを出力する' },
  ];

  const handleRecallSave = useCallback(async () => {
    const result = await dispatch(getNewSavedDraft());
    if (getNewSavedDraft.fulfilled.match(result)) {
      const { current_path } = result.payload.data;
      toast.success('途中保存されているデータを成功に取得しました。');
      navigate(current_path);
    } else {
      toast.error('エラーが発生しました。');
    }
  }, [dispatch, navigate]);

  const handleEditPreliminaries = useCallback(
    async (id: string) => {
      const result = await dispatch(getPreliminaries({ id }));
      if (getPreliminaries.fulfilled.match(result)) {
        toast.success('途中保存されているデータを成功に取得しました。');
        if (result.payload.data.editable) {
          navigate(routeNames.StepSynthesisInformation.path);
        } else {
          navigate(routeNames.StepFourA.path, {
            state: {
              preliminaries_id: id,
            },
          });
        }
      } else {
        toast.error('エラーが発生しました。');
      }
    },
    [dispatch, navigate]
  );

  const handleEditUploadedFiles = useCallback(
    async (id: string) => {
      const result = await dispatch(getUploadedFiles({ id }));
      if (getUploadedFiles.fulfilled.match(result)) {
        toast.success('途中保存されているデータを成功に取得しました。');
        navigate(routeNames.StepFourB.path);
      } else {
        toast.error('エラーが発生しました。');
      }
    },
    [dispatch, navigate]
  );

  return (
    <Grid container columnSpacing={9} minHeight={400}>
      {data.map((item, index) => {
        if (
          !selectedPreliminary.id &&
          draftExits &&
          index !== 0 &&
          index !== 6
        ) {
          return null;
        }
        if (!!selectedPreliminary.id && index === 0) {
          return null;
        }
        if (
          !selectedPreliminary.master_bank_codes.includes(CODEBANK.MCJ) &&
          index === 7
        ) {
          return null;
        }
        if (!has_result && index === 4) {
          return null;
        }
        return (
          <Grid
            item
            key={index}
            {...{ mobile: 12, tablet: 6, md: 12 / itemPerRow }}
            sx={{ pb: 9 }}
            onClick={item.onClick}
          >
            <DashboardItem data={item} />
          </Grid>
        );
      })}
    </Grid>
  );
};
