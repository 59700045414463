import { RootState } from 'types';

export const spStepFourSelector = (state: RootState) => ({
  ...state.sp_step_four.form,
  same_address_as_applicant: state.sp_step_four.same_address_as_applicant,
  p_residents_attributes: state.sp_step_four.p_residents_attributes,
  p_application_header: {
    ...state.sp_step_four.form.p_application_header,
    p_applicant_people_attributes:
      state.sp_step_four.form.p_application_header
        .p_applicant_people_attributes[0],
    p_residents_attributes:
      state.sp_step_four.form.p_application_header.p_residents_attributes[0],
    master_bank_codes: state.sp_step_one.form.master_bank_codes,
    loan_type: state.sp_step_one.form.p_application_header.loan_type,
  },
});
