import { FC, useMemo } from 'react';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import {
  InputField,
  FormItem,
  Button,
  RadioGroupButton,
  Icons,
} from 'components';
import {
  landAdvance,
  LandAdvanceType,
  repayment,
  RepaymentType,
} from 'constant';
import { useSelector } from 'react-redux';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { convertDynamicOptions } from 'utils';
import { checkMCJ } from '../selectors';

type RadioItems = {
  value: string;
  label: string;
};

const StepOnePageC: FC = () => {
  const isMCJ = useSelector(checkMCJ);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);

  const desiredMonthlyBonusOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.desired_monthly_bonus),
    [dynamicOptions.desired_monthly_bonus]
  );

  return (
    <Stack justifyContent="center">
      <FormItem
        divider
        label="お借入れ希望額"
        required
        readOnly
        note={notes.desired_loan_amount}
      >
        <Stack
          direction={{
            mobile: 'column',
            tablet: 'row',
            desktop: 'row',
          }}
          spacing={8}
        >
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="pair_p_applicant_person.p_borrowing_detail_attributes.temporary_desired_loan_amount"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            readOnly
            useNumberFormat
          />
        </Stack>
      </FormItem>

      {isMCJ && (
        <FormItem required divider label="ボーナス併用払い">
          <RadioGroupButton
            itemPerRow={3}
            type="noImage"
            name="pair_p_applicant_person.p_borrowing_detail_attributes.desired_monthly_bonus"
            options={desiredMonthlyBonusOptions}
          />
        </FormItem>
      )}

      <FormItem
        divider
        label="内ボーナス払い（半年毎増額返済金額）"
        required
        readOnly
        note={notes.halfyear_bonus}
        explain={
          <Stack sx={{ mt: 7 }}>
            <Stack
              direction={{ mobile: 'column', tablet: 'row' }}
              spacing="25px"
              sx={{
                bgcolor: 'main_white',
                py: 8,
                px: { mobile: 4, tablet: 7 },
                borderRadius: '10px',
              }}
            >
              <Button
                sx={{
                  bgcolor: 'h_blugreen',
                  py: 3,
                  pl: 5,
                  pr: 9,
                  borderRadius: '10px',
                }}
              >
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  spacing={8}
                  alignItems={{ mobile: 'center' }}
                >
                  <Avatar sx={{ borderRadius: 0, width: 114, height: 81 }}>
                    <Typography variant="text_title" lineHeight="42px">
                      イメージ
                    </Typography>
                  </Avatar>
                  <Stack justifyContent="center">
                    <Typography
                      variant="text_accordion_status"
                      color="main_white"
                      lineHeight="36px"
                    >
                      住宅ローンシミュレーション
                    </Typography>
                  </Stack>
                </Stack>
              </Button>
              <Stack>
                <Typography variant="textstyle" lineHeight="24px">
                  住宅ローンを借りると、
                  <br />
                  <Typography variant="checkbox_button_R">
                    毎月いくらの返済なの？
                    <br />
                    総返済額は？
                    <br />
                  </Typography>
                  そんな疑問にお答えします！
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
      >
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="pair_p_applicant_person.p_borrowing_detail_attributes.halfyear_bonus"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            readOnly
            useNumberFormat
          />
        </Stack>
      </FormItem>

      <FormItem
        label="お借入期間"
        divider
        required
        readOnly
        help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
        note={
          <Stack>
            <Stack>
              <Typography variant="text_note" color="normal_text">
                {notes.loan_term}
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ pt: 1 }}>
              <Typography variant="text_note" color="normal_text">
                ※借り換えの場合は
              </Typography>
              <IconButton
                sx={{
                  color: 'Icon_color',
                  p: 0,
                  mt: -1,
                }}
              >
                <Icons.Question sx={{ width: 25, height: 25 }} />
              </IconButton>
              <Typography variant="text_note" color="normal_text">
                をご覧ください。
              </Typography>
            </Stack>
          </Stack>
        }
      >
        <Stack
          direction={{
            mobile: 'column',
            tablet: 'row',
            desktop: 'row',
          }}
          spacing={{ mobile: 5, tablet: 10, desktop: 10 }}
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <InputField
              sx={{ width: { tablet: 120 } }}
              name="pair_p_applicant_person.p_borrowing_detail_attributes.loan_term"
              align="right"
              placeholder="0"
              unit="年"
              numeric
              placement="top-start"
              readOnly
            />
          </Stack>
        </Stack>
      </FormItem>

      <FormItem
        label="返済方法"
        required
        readOnly
        help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
        divider
      >
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.p_borrowing_detail_attributes.repayment_method"
          options={repaymentOptions}
          readOnly
        />
      </FormItem>

      <FormItem label="土地先行プラン希望" required readOnly>
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="pair_p_applicant_person.has_land_advance_plan"
          options={landAdvanceOptions}
          readOnly
        />
      </FormItem>
    </Stack>
  );
};

export default StepOnePageC;

const notes = {
  loan_desired_borrowing_date:
    '※原則として購入する物件の売買代金や請負工事代金の支払日がお借入れ希望日となります。',
  loan_term: '※新築の場合は1年以上35年以内になります。',
  desired_loan_amount: '※借入金額は10万円単位となります。',
  halfyear_bonus:
    '※毎月の返済に加えて半年毎（6ヵ月毎）に増額返済額をお支払いいただくボーナス払い（半年毎増額返済）を選択する場合は金額を記入してください。',
  second_loan_term:
    '※「2本目融資」のお借入期間は「1本目融資」と同一になります。',
  second_repayment_method:
    '※「2本目融資」の返済方法は「1本目融資」と同一になります。',
};

const repaymentOptions: Array<RadioItems> = [
  {
    value: RepaymentType.EQUAL_PRINCIPAL_AND_INTEREST,
    label: repayment[RepaymentType.EQUAL_PRINCIPAL_AND_INTEREST],
  },
  {
    value: RepaymentType.EQUAL_PRINCIPAL,
    label: repayment[RepaymentType.EQUAL_PRINCIPAL],
  },
];

const landAdvanceOptions: Array<RadioItems> = [
  {
    value: LandAdvanceType.YES,
    label: landAdvance[LandAdvanceType.YES],
  },
  {
    value: LandAdvanceType.NO,
    label: landAdvance[LandAdvanceType.NO],
  },
];
