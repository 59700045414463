import { ChangeEvent, FC, useCallback } from 'react';
import { styled } from '@mui/system';
import InputMask from 'react-input-mask';

type InputDateProps = {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};
export const InputDate: FC<InputDateProps> = ({
  value,
  onChange,
  onFocus,
  onBlur,
}) => {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
        .replace('年', '/')
        .replace('月', '/')
        .replace('日', '');
      onChange(newValue === '//' ? '' : newValue);
    },
    [onChange]
  );

  return (
    <PatternFormatStyled
      value={value}
      alwaysShowMask
      mask="9999年99月99日"
      maskChar="_"
      onChange={handleOnChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

const PatternFormatStyled = styled(InputMask)(({ theme }) => ({
  minHeight: 24,
  marginLeft: -5,
  paddingLeft: 5,
  paddingRight: 0,
  minWidth: 12,
  wordBreak: 'break-all',
  border: 'none',
  ...theme.typography.text_step_admin,
  textAlign: 'left',
  maxWidth: 400,
  '&:disabled': {
    backgroundColor: 'transparent',
  },
}));
