import { createSlice } from '@reduxjs/toolkit';
import { MasterBankResponse } from 'types';
import { getMasterBanks } from '../thunk';

export type InitialState = {
  form: MasterBankResponse[];
};

export const initialState: InitialState = {
  form: [],
};

const masterBankSlice = createSlice({
  name: 'stepSynthesis',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMasterBanks.fulfilled, (state, action) => {
      state.form = action.payload.data;
    });
  },
});

export default masterBankSlice.reducer;
