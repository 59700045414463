import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Camera: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="36"
      height="32"
      viewBox="0 0 36 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 18C10.125 13.6477 13.6477 10.125 18 10.125C22.3523 10.125 25.875 13.6477 25.875 18C25.875 22.3523 22.3523 25.875 18 25.875C13.6477 25.875 10.125 22.3523 10.125 18ZM18 11.25C14.2102 11.25 11.25 14.2102 11.25 18C11.25 21.7266 14.2102 24.75 18 24.75C21.7266 24.75 24.75 21.7266 24.75 18C24.75 14.2102 21.7266 11.25 18 11.25ZM25.5164 2.30766L26.2477 4.5H31.5C33.982 4.5 36 6.51797 36 9V27C36 29.482 33.982 31.5 31.5 31.5H4.5C2.01445 31.5 0 29.482 0 27V9C0 6.51797 2.01445 4.5 4.5 4.5H9.75234L10.4836 2.30766C10.9406 0.929531 12.2273 0 13.6828 0H22.3172C23.7727 0 25.0594 0.929531 25.5164 2.30766ZM4.5 5.625C2.63602 5.625 1.125 7.13672 1.125 9V27C1.125 28.8633 2.63602 30.375 4.5 30.375H31.5C33.3633 30.375 34.875 28.8633 34.875 27V9C34.875 7.13672 33.3633 5.625 31.5 5.625H25.4391L24.4547 2.66344C24.1453 1.74445 23.2875 1.125 22.3172 1.125H13.6828C12.7125 1.125 11.8547 1.74445 11.5453 2.66344L10.5609 5.625H4.5Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);
