import { Stack, Typography } from '@mui/material';
import {
  InputSelectProps,
  SPFormItem,
  SPImage,
  SPInputDateTime,
  SPInputField,
  SPInputPhoneNumber,
  SPInputSelect,
  SPInputZipCode,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPSaveImageModal,
  SPUploadImageItem,
} from 'components';
import {
  BorrowerIncomeType,
  GeneralIncomeConfirmation,
  NationalityType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PREFECTURES,
} from 'constant';
import { SPStepLayout } from 'containers';
import { spCurrentStepSelector } from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import { FormikProvider, getIn, useFormik } from 'formik';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import kanjidate from 'kanjidate';
import { dayjs } from 'libs';
import { get, isEmpty, update } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spStepFiveSelector } from 'pages/SPStepFive/selectors';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneSelector,
} from 'pages/SPStepOne/selectors';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { getZipCloudAddress } from 'pages/StepRequiredInformation/thunk';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PostalCode } from 'services';
import {
  checkingExistFile,
  convertDynamicOptions,
  convertImage,
  convertToFullWidth,
  convertToHalfWidth,
  flattenToLodashFormat,
  kanaMap1,
  supportDvh,
  zeroPad,
} from 'utils';
import { spStepEightSelector } from '../SPStepEight/selectors';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepSixSelector } from '../SPStepSix/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { spStepTwoSelector } from './selectors';
import { saveForm } from './slice';
import { resetLivedLength } from './thunk';
import { validationSchema } from './validationSchema';

const SPStepTwoPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const birthdayRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const currentStep = useSelector(spCurrentStepSelector);
  const initialValues = useSelector(spStepTwoSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const { application_number } = useSelector(spStepTenSelector);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const {
    residenceFile,
    setResidenceFile,
    residenceFileBackImage,
    setResidenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    repaymentScheduleImage,
    businessCard,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [open, setOpen] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);
  const [postalCodeError, setPostalCodeError] = useState<string>('');
  const [errorResidenceFile, setErrorResidenceFile] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const loanTermYearNum =
    spStepOneValues.p_application_header.p_borrowing_details_attributes[0]
      .loan_term_year_num;
  const { updateFormOverride } = useUpdateForm();

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(loanTermYearNum),
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      //reset other_address_kana
      if (
        initialValues.p_applicant_people.postal_code &&
        values.p_applicant_people.postal_code !==
          initialValues.p_applicant_people.postal_code
      ) {
        values.p_applicant_people.other_address_kana = '';
      }
      if (
        !loanTermYearNum ||
        !age ||
        (!!loanTermYearNum && !!age && +loanTermYearNum * 12 + age <= 80 * 12)
      ) {
        dispatch(
          saveForm({
            p_application_header: {
              p_applicant_people_attributes: [values.p_applicant_people],
            },
          })
        );
        dispatch(
          resetLivedLength({ birthday: values?.p_applicant_people.birthday })
        );
        if (+currentStep < 3) dispatch(setCurrentStep('3'));
        setSubmitting(false);
        if (!!application_number) {
          setIsDisabledButton(true);
          updateFormOverride(
            {
              stepTwoData: {
                p_application_header: {
                  p_applicant_people_attributes: [values.p_applicant_people],
                },
              },
              imageContext: imageContext,
            },
            setIsDisabledButton,
            setOpenImageModal,
            setReset
          );
        } else {
          navigate(routeNames.SPStepThree.path);
        }
      } else {
        setSubmitting(false);
      }
    },
  });

  const btnSubmit = useMemo(() => {
    return !!application_number ? '保存' : '次へ';
  }, [application_number]);

  const age = dayjs().diff(formik.values?.p_applicant_people.birthday, 'year');

  // const loanTermYearLimit = useMemo(
  //   () => +loanTermYearNum + age <= 80,
  //   [loanTermYearNum, age]
  // );

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  useEffect(() => {
    if (
      formik.values.p_applicant_people.nationality ===
        NationalityType.FOREIGN_NATIONALITY &&
      !!residenceFile.length &&
      !!residenceFileBackImage.length
    ) {
      setErrorResidenceFile(false);
    }
  }, [
    residenceFile.length,
    residenceFileBackImage.length,
    formik.values.p_applicant_people.nationality,
  ]);

  const sexOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.sex),
    [dynamicOptions.sex]
  );

  const nationalityOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nationality),
    [dynamicOptions.nationality]
  );

  const setHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      prefecture_kanji: postal.address1,
      district_kanji: postal.address3,
      city_kanji: postal.address2,
      prefecture_kana: convertToFullWidth(postal.kana1),
      district_kana: convertToFullWidth(postal.kana3),
      city_kana: convertToFullWidth(postal.kana2),
      other_address_kanji: '',
    });
    await formik.setFieldTouched(`${name}.other_address_kanji`, false, false);
  };

  const handleZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        setPostalCodeError(
          '住所が取得できませんでした。再度入力してください。'
        );
        await formik.setFieldValue('p_applicant_people.prefecture_kanji', '');
        await formik.setFieldValue('p_applicant_people.city_kanji', '');
        await formik.setFieldValue('p_applicant_people.district_kanji', '');
        await formik.setFieldValue('p_applicant_people.prefecture_kana', '');
        await formik.setFieldValue('p_applicant_people.city_kana', '');
        await formik.setFieldValue('p_applicant_people.district_kana', '');
        return;
      }

      await setHomeAddress(result.payload.results[0], 'p_applicant_people');
      setPostalCodeError('');
    }
  };

  const { postal_code } = formik.values.p_applicant_people;

  useEffect(() => {
    if (
      postal_code.length === 8 &&
      postal_code === convertToHalfWidth(postal_code) &&
      formik.touched.p_applicant_people?.postal_code
    ) {
      handleZipCodeChange(postal_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postal_code]);

  useEffect(() => {
    let newResidenceFileImage: (SPImage | File)[] = [];
    if (
      !!residenceFile &&
      residenceFile.length === 0 &&
      !!formik.values.p_applicant_people.residence_file &&
      !!formik.values.p_applicant_people.residence_file?.length
    ) {
      formik.values.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          return newResidenceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!residenceFile && !!residenceFile.length) {
      newResidenceFileImage = [...residenceFile];
    } else {
      formik.values.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          return newResidenceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setResidenceFile(newResidenceFileImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newResidenceFileBackImage: (SPImage | File)[] = [];
    if (
      !!residenceFileBackImage &&
      residenceFileBackImage.length === 0 &&
      !!formik.values.p_applicant_people.residence_file_back_image &&
      !!formik.values.p_applicant_people.residence_file_back_image?.length
    ) {
      formik.values.p_applicant_people.residence_file_back_image?.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidenceFileBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!residenceFileBackImage && !!residenceFileBackImage.length) {
      newResidenceFileBackImage = [...residenceFileBackImage];
    } else {
      formik.values.p_applicant_people.residence_file_back_image?.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidenceFileBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidenceFileBackImage(newResidenceFileBackImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);

    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      formik.values.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });
    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });
    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });
      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }
    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/
    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );
    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFile2,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    if (spSaveDraft.rejected.match(result)) {
      setReset(reset + 1);
    }
    return;
  };

  const handleBackStep = useCallback(() => {
    dispatch(
      saveForm({
        p_application_header: {
          p_applicant_people_attributes: [formik.values.p_applicant_people],
        },
      })
    );
    if (!!application_number) {
      navigate(-1);
    } else {
      navigate(routeNames.SPStepOne.path);
    }
  }, [
    application_number,
    dispatch,
    formik.values.p_applicant_people,
    navigate,
  ]);

  const checkRequiredResidenceFile = useMemo(() => {
    if (
      formik.values.p_applicant_people.nationality ===
      NationalityType.FOREIGN_NATIONALITY
    )
      return !!residenceFile.length && !!residenceFileBackImage.length;
  }, [
    residenceFile.length,
    residenceFileBackImage.length,
    formik.values.p_applicant_people.nationality,
  ]);

  const handleSubmit = useCallback(() => {
    if (
      formik.values.p_applicant_people.nationality !==
        NationalityType.FOREIGN_NATIONALITY ||
      (formik.values.p_applicant_people.nationality ===
        NationalityType.FOREIGN_NATIONALITY &&
        !!residenceFile.length &&
        !!residenceFileBackImage.length)
    ) {
      return formik.handleSubmit();
    }
    if (!checkRequiredResidenceFile) {
      setErrorResidenceFile(true);
      imageRef.current?.scrollIntoView({ behavior: 'smooth' });
      birthdayRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    formik,
    residenceFile.length,
    residenceFileBackImage.length,
    checkRequiredResidenceFile,
  ]);

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement;
    let transformedValue = value;
    Object.entries(kanaMap1).forEach(([key, replacement]) => {
      transformedValue = transformedValue.replaceAll(key, replacement);
    });
    formik.setFieldValue(name, transformedValue);
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={() =>
          dispatch(
            saveForm({
              p_application_header: {
                p_applicant_people_attributes: [
                  formik.values.p_applicant_people,
                ],
              },
            })
          )
        }
        footer={{
          left: {
            onClick: handleBackStep,
          },
          right: {
            children: btnSubmit,
            onClick: handleSubmit,
            disabled: isDisabledButton,
          },
        }}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        onSaveDraft={onSaveDraft}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack sx={{ py: 9 }} justifyContent="center" alignItems="center">
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >{`あなたについて\n教えてください。`}</Typography>
          </Stack>
        </Stack>
        <SPFormItem label="お名前" required>
          <Stack spacing={3}>
            <SPInputField
              name="p_applicant_people.last_name_kanji"
              placeholder="姓"
              convertFullWidth
            />
            <SPInputField
              name="p_applicant_people.first_name_kanji"
              placeholder="名"
              convertFullWidth
            />
            <Stack direction="row" spacing={1}>
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Typography variant="SP_form_item_note" color="b_333">
                外国籍のかたは、在留カード通りに入力ください。
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Typography variant="SP_form_item_note" color="b_333">
                お名前の漢字が外字等で変換できない場合は常用漢字でご入力ください。
              </Typography>
            </Stack>
          </Stack>
        </SPFormItem>
        <SPFormItem label="お名前（フリガナ）" required>
          <Stack spacing={3} ref={birthdayRef}>
            <SPInputField
              name="p_applicant_people.last_name_kana"
              placeholder="セイ"
              onBlur={(event) => handleBlur(event)}
            />
            <SPInputField
              name="p_applicant_people.first_name_kana"
              placeholder="メイ"
              onBlur={(event) => handleBlur(event)}
            />
          </Stack>
        </SPFormItem>
        <SPFormItem label="性別" required>
          <SPRadioGroupButton
            name="p_applicant_people.sex"
            options={sexOptions}
            display="row"
          />
        </SPFormItem>
        <SPFormItem label="生年月日" required>
          <Stack spacing="6px" ref={imageRef}>
            <SPInputDateTime
              name="p_applicant_people.birthday"
              yearOptions={yearOptions}
              checkRequired
            />
            {/*{loanTermYearLimit && (*/}
            {/*  <Typography variant="SP_form_item_note" color="b_333">*/}
            {/*    ※{MESSAGE_REGEX.LOAN_TERM_LIMIT}*/}
            {/*  </Typography>*/}
            {/*)}*/}
          </Stack>
        </SPFormItem>
        <SPFormItem label="現在の国籍" required>
          <Stack>
            <SPRadioGroupButton
              name="p_applicant_people.nationality"
              options={nationalityOptions}
              display="row"
              onChange={(checked, value) => {
                if (value !== NationalityType.FOREIGN_NATIONALITY) {
                  setResidenceFile([]);
                  setResidenceFileBackImage([]);
                }
              }}
            />
            {formik.values.p_applicant_people.nationality ===
              NationalityType.FOREIGN_NATIONALITY && (
              //TODO: check delete images save draft
              <Stack
                sx={{
                  mt: 3,
                  borderRadius: '8px',
                  border: (theme) => `1px solid ${theme?.palette?.h_blugreen}`,
                }}
              >
                <Stack
                  sx={{
                    bgcolor: 'sp_primary_40',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    p: '4px 12px 4px 12px',
                  }}
                >
                  <Typography variant="upload_image_title" color="normal_text">
                    在留カードまたは特別永住者証明書を添付してください。
                  </Typography>
                </Stack>
                {errorResidenceFile && (
                  <Typography
                    variant="text_error"
                    color="sp_secondary_01"
                    sx={{ ml: 4, mt: 1 }}
                  >
                    ※外国籍の場合、在留カードまたは特別永住者証明書を添付することは必須です。
                  </Typography>
                )}
                <Stack direction="row" spacing={1} sx={{ py: 3 }}>
                  <Stack>
                    <Typography pl={3} variant="SP_label_field" color="b_333">
                      〈表面〉
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={residenceFile}
                      setImages={setResidenceFile}
                      uploadMulti={false}
                      reload={reset}
                      handleDeleteImage={(id) => {
                        const residence_file = convertImage(
                          formik.values.p_applicant_people.residence_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.residence_file',
                          residence_file
                        );
                      }}
                    />
                  </Stack>
                  <Stack>
                    <Typography pl={3} variant="SP_label_field" color="b_333">
                      〈裏面〉
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={residenceFileBackImage}
                      setImages={setResidenceFileBackImage}
                      uploadMulti={false}
                      reload={reset}
                      handleDeleteImage={(id) => {
                        const residence_file_back_image = convertImage(
                          formik.values.p_applicant_people
                            .residence_file_back_image,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.residence_file_back_image',
                          residence_file_back_image
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem label="電話番号" required>
          <Stack spacing={3}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>携帯</Typography>
              <SPInputPhoneNumber
                name="p_applicant_people.mobile_phone_number"
                type="mobilePhone"
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography>自宅</Typography>
              <SPInputPhoneNumber
                name="p_applicant_people.home_phone_number"
                type="homePhone"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Typography variant="SP_form_item_note" color="b_333">
                半角数字でご入力ください。
              </Typography>
            </Stack>
          </Stack>
        </SPFormItem>
        <SPFormItem
          label="現住所"
          required
          note="※国内にお住まいの方がご利用できます。"
          noteType="under"
        >
          <Stack spacing={6}>
            <Stack spacing="6px">
              <SPInputZipCode
                name="p_applicant_people.postal_code"
                label="郵便番号"
                handleFocusZipCode={() => setPostalCodeError('')}
              />
              {postalCodeError && (
                <Typography variant="text_error" color="sp_secondary_01">
                  ※{postalCodeError}
                </Typography>
              )}
              <Stack direction="row" spacing={1}>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※
                </Typography>
                <Typography variant="SP_form_item_note" color="b_333">
                  入力すると自動的に住所が表示されます。
                </Typography>
              </Stack>
            </Stack>
            <SPInputSelect
              name="p_applicant_people.prefecture_kanji"
              placeholder="----"
              label="都道府県"
              options={PREFECTURES}
              sx={{ maxWidth: '110px', textAlign: 'center' }}
              onChange={() => {
                if (formik.values.p_applicant_people.prefecture_kana) {
                  formik.setFieldValue(
                    'p_applicant_people.prefecture_kana',
                    ''
                  );
                }
              }}
            />
            <SPInputField
              name="p_applicant_people.city_kanji"
              placeholder="例：港区"
              label="市区郡　（例：港区）"
              convertFullWidth
              onChange={() => {
                if (formik.values.p_applicant_people.city_kana) {
                  formik.setFieldValue('p_applicant_people.city_kana', '');
                }
              }}
            />
            <SPInputField
              name="p_applicant_people.district_kanji"
              placeholder="例：芝浦４丁目"
              label="町村丁目（例：芝浦４丁目）"
              convertFullWidth
              onChange={() => {
                if (formik.values.p_applicant_people.district_kana) {
                  formik.setFieldValue('p_applicant_people.district_kana', '');
                }
              }}
            />
            <SPInputField
              name="p_applicant_people.other_address_kanji"
              placeholder="例：12-38　キャナルゲート芝浦605号室"
              label="丁目以下・建物名・部屋番号（例：12-38　キャナルゲート芝浦605号室）"
              convertFullWidth
              labelStyle={{ lineHeight: '20px' }}
              onChange={() => {
                if (formik.values.p_applicant_people.other_address_kana) {
                  formik.setFieldValue(
                    'p_applicant_people.other_address_kana',
                    ''
                  );
                }
              }}
            />
          </Stack>
        </SPFormItem>
        <SPFormItem label="ご連絡先用メールアドレス" required>
          <Stack spacing={3}>
            <SPInputField
              name="p_applicant_people.owner_email"
              placeholder="例：sample@sample.co.jp"
            />
            <Stack direction="row" spacing={1}>
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Typography
                variant="SP_form_item_note"
                color="b_333"
                sx={{ whiteSpace: 'break-spaces' }}
              >
                {`会員登録メールアドレスを表示しています。\n別途、ご連絡先用のメールアドレスを登録したい方は修正してください。`}
              </Typography>
            </Stack>
          </Stack>
        </SPFormItem>
        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
          }}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepTwoPage;

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(48), (_, index) => {
    const year = zeroPad(dayjs().year() - 18 - index);
    const startDay = year === '1989' ? 8 : 1;
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, startDay);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, startDay);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);
