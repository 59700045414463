import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { FormikProvider, useFormik } from 'formik';
import { Icons, SPButton, SPFormItem, SPInputField, SPModal } from 'components';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NotifyScreenTemplate from 'components/template/NotifyScreenTemplate';
import { regex } from 'libs';
import SPUpdatePasswordTemplate from 'components/template/SPUpdatePasswordTemplate';
import { routeNames } from 'navigations/routes';
import { theme } from 'styles';
import { supportDvh } from 'utils';
import {
  emailValidationSchema,
  passwordValidationSchema,
} from './validationSchema';
import { resetPassword, checkResetToken, updatePassword } from './thunk';

const SPChangePasswordPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('reset_password_token');
  const [loading, setLoading] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [checkedToken, setCheckedToken] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: token ? passwordValidationSchema : emailValidationSchema,
    async onSubmit(values) {
      if (token) {
        const result = await dispatch(
          updatePassword({
            user: {
              reset_password_token: token,
              password: values.password,
              password_confirmation: values.password_confirmation,
            },
          })
        );
        if (updatePassword.fulfilled.match(result)) {
          return setOpenModal(true);
        }
        return toast.error('エラーが発生しました。');
      }

      await dispatch(
        resetPassword({
          user: {
            email: values.email,
          },
        })
      );
      return setSendEmail(true);
    },
  });

  const checkChangePasswordToken = useCallback(async () => {
    if (!token) {
      return setCheckedToken(false);
    }
    setLoading(true);
    const result = await dispatch(
      checkResetToken({ reset_password_token: token })
    );
    setLoading(false);
    if (!checkResetToken.fulfilled.match(result)) {
      return setCheckedToken(false);
    }
    return setCheckedToken(true);
  }, [dispatch, token]);

  useLayoutEffect(() => {
    checkChangePasswordToken();
  }, [checkChangePasswordToken]);

  const onCloseModal = () => setOpenModal(false);
  const onModalButtonClick = () => {
    navigate(routeNames.Login.path);
  };

  if (loading) {
    return null;
  }

  return (
    <SPStepLayout
      hasStepBar={false}
      hasMenu={false}
      hasFooter={false}
      footerLogo
      hasModalSaveDraft={false}
      sx={{ minHeight: supportDvh('calc(100dvh - 158px)') }}
    >
      <FormikProvider value={formik}>
        {!token && sendEmail && (
          <NotifyScreenTemplate
            title="メールを送信しました"
            icon={<Icons.SPAirPlane sx={{ width: 36, height: 37, pb: 4 }} />}
          >
            <Stack
              sx={{
                bgcolor: 'main_white',
                borderRadius: '14px',
                width: '100% - 64px',
                p: 6,
                mx: 8,
              }}
              spacing={8}
            >
              <Typography variant="sp_change_password_note" color="b_333">
                {`メールに記載されている\nURLにアクセスし\nパスワード再設定を完了してください。`}
              </Typography>
              <Typography variant="sp_change_password_note" color="b_333">
                {`＊未登録の方には再設定用のメールは\n送信されません。`}
              </Typography>
            </Stack>
          </NotifyScreenTemplate>
        )}

        {!token && !sendEmail && (
          <Stack>
            <Stack sx={{ textAlign: 'center' }} pt={7} pb={6}>
              <Typography
                variant="sp_change_password_title"
                color="sp_primary_100_main"
                mb={7}
              >
                パスワード再設定
              </Typography>
              <Typography variant="sp_change_password_note" color="b_333">
                ご登録のメールアドレスを入力してください。
              </Typography>
              <Typography variant="sp_change_password_note" color="b_333">
                パスワード再設定用のメールをお送りします。
              </Typography>
            </Stack>
            <SPFormItem required label="メールアドレス" labelSx={{ py: 1 }}>
              <Stack spacing={6}>
                <SPInputField
                  name="email"
                  type="email"
                  placeholder="例：sample@sample.co.jp"
                  sx={{
                    '&& .MuiInputBase-input': {
                      ...theme.typography.text_placeholder,
                    },
                  }}
                />
                <SPButton
                  sx={{
                    bgcolor: 'sp_primary_100_main',
                    width: '100%',
                    minHeight: 54,
                  }}
                  endIcon={<Icons.ArrowForward stroke="white" />}
                  onClick={() => formik.handleSubmit()}
                  disabled={
                    formik.isSubmitting || !(formik.isValid && formik.dirty)
                  }
                >
                  再設定メールを送信
                </SPButton>
              </Stack>
            </SPFormItem>
          </Stack>
        )}

        {token && !checkedToken && (
          <NotifyScreenTemplate
            title={`メールの有効期限が\n切れています`}
            icon={
              <Icons.SPWarningGradient sx={{ width: 42, height: 36, pb: 4 }} />
            }
            buttonTitle="パスワードの再設定はこちら"
            onClick={() => navigate(routeNames.ResetPassword.path)}
          >
            <Stack
              sx={{
                bgcolor: 'main_white',
                borderRadius: '14px',
                width: '100% - 64px',
                p: 6,
                mx: 8,
              }}
              spacing={6}
            >
              <Typography variant="sp_change_password_note" color="b_333">
                {`メール受信後、一定時間内での\n操作が確認できなかったため\nパスワードの再設定に失敗しました。`}
              </Typography>
              <Typography variant="sp_change_password_note" color="b_333">
                {`恐れ入りますが、\n再度パスワード再設定の手続きを\nお願いいたします。`}
              </Typography>
            </Stack>
          </NotifyScreenTemplate>
        )}

        {token && checkedToken && (
          <SPUpdatePasswordTemplate
            title="パスワード再設定"
            formOption={[
              {
                formTitle: '新しいパスワード',
                formName: 'password',
                autoTrim: false,
                formChildren: (
                  <Stack spacing={3} mt={1}>
                    <Typography variant="SP_password_note" color="b_333">
                      パスワードの条件
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Checkbox
                        checked={
                          formik.values.password.length >= 8 &&
                          formik.values.password.length <= 20
                        }
                        sx={{
                          m: 0,
                          width: 12,
                          height: 12,
                          color: 'sp_primary_40',
                          '&.Mui-checked': {
                            color: 'sp_secondary_01',
                          },
                        }}
                      />

                      <Typography
                        variant="table_header_text"
                        color={
                          (formik.values.password.length > 0 &&
                            formik.values.password.length < 8) ||
                          formik.values.password.length > 20
                            ? 'sp_secondary_01'
                            : 'b_333'
                        }
                      >
                        8文字以上20文字以下
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Checkbox
                        checked={!!formik.values.password.match(regex.password)}
                        sx={{
                          width: 12,
                          height: 12,
                          color: 'sp_primary_40',
                          '&.Mui-checked': {
                            color: 'sp_secondary_01',
                          },
                        }}
                      />
                      <Typography
                        variant="table_header_text"
                        color={
                          !formik.values.password.match(regex.password) &&
                          formik.values.password
                            ? 'sp_secondary_01'
                            : 'b_333'
                        }
                      >
                        大文字英字・小文字英字・数字の3種混在
                      </Typography>
                    </Stack>
                  </Stack>
                ),
                showError: true,
                hasPassword: true,
              },
              {
                formTitle: '新しいパスワード（確認用）',
                formName: 'password_confirmation',
                autoTrim: false,
                hasPassword: true,
              },
            ]}
            onSubmit={formik.handleSubmit}
            disabledButton={
              formik.isSubmitting || !(formik.isValid && formik.dirty)
            }
            buttonTitle="変更する"
          />
        )}

        <SPModal
          open={openModal}
          onClose={onCloseModal}
          disableBackDropClick
          icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
          title="パスワード再設定完了"
          footer={
            <Stack textAlign="center" spacing={6}>
              <Typography variant="sp_modal_note" color="b_333">
                {`パスワード再設定が完了しました。\n引き続きご利用ください。`}
              </Typography>
              <SPButton
                sx={{
                  width: '100%',
                  bgcolor: 'sp_primary_100_main',
                }}
                endIcon={<Icons.ArrowForward stroke="white" />}
                onClick={onModalButtonClick}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="main_white"
                >
                  ログイン
                </Typography>
              </SPButton>
            </Stack>
          }
        />
      </FormikProvider>
    </SPStepLayout>
  );
};

export default SPChangePasswordPage;
