import {
  ClipboardEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react';
import { Stack, SxProps, Modal, Typography, Checkbox } from '@mui/material';
import { Close } from '@mui/icons-material';
import { FormikProvider, useFormik } from 'formik';
import { clearSalePerson, regex } from 'libs';
import { AdminInput, Button } from 'components/atoms';
import { useAppDispatch } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { salePersonTokenInvalidLogout } from 'pages/SalePersonLogin/thunk';
import { resetAuth } from 'containers/AuthModal/slice';
import { managerTokenInvalidLogout } from 'pages/Login/thunk';
import { changePasswordManager, changePasswordSalePerson } from './thunk';
import { validateSchema } from './validationSchema';

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
}

export const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(``);
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validateSchema,
    async onSubmit(values) {
      setErrorMessage('');
      if (location.pathname.startsWith('/manager/', 0)) {
        const resultManager = await dispatch(
          changePasswordManager({
            manager: {
              current_password: values.currentPassword,
              password: values.password,
              password_confirmation: values.passwordConfirmation,
            },
          })
        );
        if (changePasswordManager.fulfilled.match(resultManager)) {
          setSuccess(true);
        }
        if (changePasswordManager.rejected.match(resultManager)) {
          setErrorMessage(resultManager.payload as string);
        }
      } else if (location.pathname.startsWith('/sale-person/', 0)) {
        const resultSalePerson = await dispatch(
          changePasswordSalePerson({
            s_sale_person: {
              current_password: values.currentPassword,
              password: values.password,
              password_confirmation: values.passwordConfirmation,
            },
          })
        );
        if (changePasswordSalePerson.fulfilled.match(resultSalePerson)) {
          setSuccess(true);
        }
        if (changePasswordSalePerson.rejected.match(resultSalePerson)) {
          setErrorMessage(resultSalePerson.payload as string);
        }
      }
    },
  });

  const clearError = useCallback(() => setError(false), []);

  const onEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const onPreventPassword = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Modal
      sx={{
        ...props?.sx,
      }}
      disableAutoFocus
      {...props}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'main_white',
          width: { desktop: 430 },
          minHeight: success ? 169 : 559,
        }}
      >
        <FormikProvider value={formik}>
          <Stack direction="column">
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Close
                onClick={async () => {
                  if (success) {
                    if (location.pathname.startsWith('/manager/', 0)) {
                      await dispatch(managerTokenInvalidLogout());
                      clearSalePerson();
                      await dispatch(resetAuth());
                      navigate(routeNames.LoginManager.path);
                      sessionStorage.removeItem('preliminary_id');
                    }
                    if (location.pathname.startsWith('/sale-person/', 0)) {
                      await dispatch(salePersonTokenInvalidLogout());
                      clearSalePerson();
                      await dispatch(resetAuth());
                      navigate(routeNames.LoginSalePerson.path);
                      sessionStorage.removeItem('preliminary_id');
                    }
                  } else {
                    props.onClose();
                  }
                }}
                fontSize="medium"
                sx={{
                  color: 'b_333',
                  cursor: 'pointer',
                  pb: 2,
                  pt: 5,
                  pr: 5,
                  width: 20,
                  height: 20,
                }}
              />
            </Stack>
            <Typography
              variant="heading01"
              color="b_333"
              sx={{ textAlign: 'center' }}
            >
              {success ? 'パスワード変更完了' : 'パスワード変更'}
            </Typography>
            <Typography
              variant="table_header_text"
              sx={{ textAlign: 'center', color: 'sp_secondary_01', mt: 3 }}
            >
              {errorMessage}
            </Typography>
            {success ? (
              <Stack
                direction="column"
                justifyContent="center"
                sx={{ mt: 3, px: 1 }}
              >
                <Typography variant="annotation02" color="b_333">
                  パスワードの変更が完了しました。
                </Typography>
                <Typography variant="annotation02" color="b_333">
                  変更後のパスワードで再度ログインして引き続きご利用ください。
                </Typography>
              </Stack>
            ) : (
              <Stack
                sx={{
                  mt: 3,
                  p: '0px 40px',
                }}
              >
                <Stack
                  sx={{
                    justifyContent: 'center',
                    pt: '5px',
                  }}
                >
                  <Stack>
                    <Typography variant="headline_input" color="b_333">
                      現在のパスワード
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: '4px', maxWidth: 350 }}>
                    <AdminInput
                      name="currentPassword"
                      placeholder="入力してください"
                      InputProps={{
                        sx: {
                          '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                            {
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderColor: 'sp_secondary_01',
                            },
                        },
                      }}
                      onFocus={clearError}
                      onCut={onPreventPassword}
                      onCopy={onPreventPassword}
                      onPaste={onPreventPassword}
                      hasPassword
                      error={
                        error ||
                        (formik.touched.currentPassword &&
                          !!formik.errors.currentPassword)
                      }
                    />
                  </Stack>
                  <Stack sx={{ mt: '20px' }}>
                    <Typography variant="headline_input" color="b_333">
                      新しいパスワード
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: '4px', maxWidth: 350 }}>
                    <AdminInput
                      name="password"
                      placeholder="入力してください"
                      InputProps={{
                        sx: {
                          '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                            {
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderColor: 'sp_secondary_01',
                            },
                        },
                      }}
                      onFocus={clearError}
                      onCut={onPreventPassword}
                      onCopy={onPreventPassword}
                      onPaste={onPreventPassword}
                      error={
                        error ||
                        (formik.touched.password && !!formik.errors.password)
                      }
                      disabled={false}
                      hasPassword
                    />
                  </Stack>
                  <Stack sx={{ mt: '15px' }}>
                    <Typography variant="SP_password_note" color="b_333">
                      パスワードの条件
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ my: '14px' }}
                    >
                      <Checkbox
                        checked={
                          formik.values.password.length >= 8 &&
                          formik.values.password.length <= 20
                        }
                        sx={{
                          mr: '6px',
                          width: 12,
                          height: 12,
                          color: 'sp_primary_40',
                          '&.Mui-checked': {
                            color: 'sp_secondary_01',
                          },
                        }}
                      />

                      <Typography
                        variant="table_header_text"
                        color={
                          formik.values.password.length > 0 &&
                          (formik.values.password.length < 8 ||
                            formik.values.password.length > 20)
                            ? 'sp_secondary_01'
                            : 'b_333'
                        }
                      >
                        8文字以上20文字以下
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        checked={!!formik.values.password.match(regex.password)}
                        sx={{
                          mr: '6px',
                          width: 12,
                          height: 12,
                          color: 'sp_primary_40',
                          '&.Mui-checked': {
                            color: 'sp_secondary_01',
                          },
                        }}
                      />
                      <Typography
                        variant="table_header_text"
                        color={
                          !formik.values.password.match(regex.password) &&
                          formik.values.password
                            ? 'sp_secondary_01'
                            : 'b_333'
                        }
                      >
                        大文字英字・小文字英字・数字の3種混在
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="headline_input"
                      sx={{ mt: '20px' }}
                      color="b_333"
                    >
                      新しいパスワード（確認用）
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: '4px', maxWidth: 350 }} onKeyPress={onEnter}>
                    <AdminInput
                      name="passwordConfirmation"
                      placeholder="入力してください"
                      InputProps={{
                        sx: {
                          '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                            {
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderColor: 'sp_secondary_01',
                            },
                        },
                      }}
                      onFocus={clearError}
                      onCut={onPreventPassword}
                      onCopy={onPreventPassword}
                      onPaste={onPreventPassword}
                      error={
                        error ||
                        (formik.touched.passwordConfirmation &&
                          !!formik.errors.passwordConfirmation)
                      }
                      disabled={false}
                      hasPassword
                    />
                  </Stack>
                  <Stack
                    sx={{ my: '40px' }}
                    direction="row"
                    justifyContent="center"
                  >
                    <Button
                      disabled={formik.isSubmitting}
                      sx={{
                        bgcolor: 'white',
                        boxShadow: 'none',
                        width: '200px',
                        height: '36px',
                        borderRadius: '2px',
                        minHeight: '36px',
                        border: '1px solid #6B70F0',
                      }}
                      onClick={() => formik.handleSubmit()}
                    >
                      <Typography
                        variant="button01"
                        color="sp_primary_100_main"
                      >
                        変更する
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </FormikProvider>
      </Stack>
    </Modal>
  );
};
