import { Stack, Typography } from '@mui/material';
import { AuthPopover, Button, Icons } from 'components';
import { KEYS_FOR_PREVIEW_FILES } from 'constant';
import { useAppDispatch } from 'hooks';
import { get } from 'lodash';
import { routeNames } from 'navigations/routes';
import { getUploadedFilesSalePerson } from 'pages/SalePersonEditPreliminary/thunk';
import { FC, useCallback, useMemo, useState } from 'react';
import { SalePersonPreliminaries } from 'services';
import { genTitleFilePreview, getParamKeysPullDown } from 'utils';

export type PulldownDocumentsProps = {
  applicantId: string;
  preliminaryId: string;
  isDriverLicenseImage: boolean;
  isCardNumberImage: boolean;
  isIncomeTotal: boolean;
  itemPreliminary?: Omit<SalePersonPreliminaries, 'master_bank_ids'>;
  selectTab: string;
  // isFirstWithholdingSlipFile: boolean;
};

export const PulldownDocuments: FC<PulldownDocumentsProps> = ({
  applicantId,
  preliminaryId,
  isDriverLicenseImage,
  isCardNumberImage,
  isIncomeTotal,
  itemPreliminary,
  selectTab,
  // isFirstWithholdingSlipFile,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  // const [openModalEmpty, setOpenModalEmpty] = useState(false);
  // const [itemFileEmpty, setItemFileEmpty] = useState('');

  const isHasImagePreview = useMemo(() => {
    let newImagePeopleAttribute: unknown[] = [];
    let borrowingImage: unknown[] = [];
    let imageHeader: unknown[] = [];

    for (let item in itemPreliminary?.p_application_header) {
      if (
        item === 'business_card_index' &&
        // @ts-ignore
        get(itemPreliminary?.p_application_header, 'business_card_index')
          .length > 0
      ) {
        imageHeader.push(item);
      }
      if (
        item === 'property_information_file_index' &&
        // @ts-ignore
        get(
          itemPreliminary?.p_application_header,
          'property_information_file_index'
        ).length > 0
      ) {
        imageHeader.push(item);
      }
    }
    let newImageHeader = imageHeader.map((item) =>
      // @ts-ignore
      item.replace('_index', '')
    );
    itemPreliminary?.p_application_header.p_applicant_people_attributes.forEach(
      (item, index) => {
        for (let personalFileUpload in item.personal_file_upload) {
          if (
            get(item.personal_file_upload, personalFileUpload)?.length > 0 &&
            !!get(item.personal_file_upload, personalFileUpload)[0]?.id &&
            ((index === 0 && selectTab === '1') || // data tab A
              (index === 1 && selectTab === '3') || // data tab income
              (index === 0 && selectTab === '2')) // data tab B
          ) {
            newImagePeopleAttribute.push(personalFileUpload);
          }
        }
      }
    );
    itemPreliminary?.p_application_header.p_borrowings_attributes.forEach(
      (item) => {
        for (let repaymentScheduleImage in item) {
          if (
            repaymentScheduleImage === 'repayment_schedule_image_index' &&
            get(item, 'repayment_schedule_image_index').length > 0 &&
            !!get(item, 'repayment_schedule_image_index')[0]?.id
          ) {
            borrowingImage.push(repaymentScheduleImage.slice(0, 24));
          }
        }
      }
    );
    return newImageHeader.concat(borrowingImage, newImagePeopleAttribute);
  }, [itemPreliminary?.p_application_header, selectTab]);

  const onViewImages = useCallback(
    async (keys: string[], title: string) => {
      setOpen(false);
      const result = await dispatch(
        getUploadedFilesSalePerson({
          id: preliminaryId,
          applicant_id: applicantId,
        })
      );
      if (getUploadedFilesSalePerson.fulfilled.match(result)) {
        const valuesByKey = keys
          .map((key) => get(result.payload.data, key))
          .flat();

        if (keys.includes('repayment_schedule_image')) {
          // borrowing
          const isAllBorrowingEmpty = valuesByKey.every(
            (item) => item.files.length === 0
          );
          if (isAllBorrowingEmpty) {
            // setOpenModalEmpty(true);
            // setItemFileEmpty(title);
          } else {
            window.open(
              routeNames.SalePersonPreviewImage.path +
                `?applicant_id=${applicantId}` +
                `&preliminary_id=${preliminaryId}` +
                `&name=${keys}`,
              '_blank'
            );
          }
        } else if (valuesByKey.length === 0) {
          // setOpenModalEmpty(true);
          // setItemFileEmpty(title);
        } else {
          let paramKeys = getParamKeysPullDown(
            keys,
            title,
            isCardNumberImage,
            isDriverLicenseImage
            // isFirstWithholdingSlipFile
          );
          window.open(
            routeNames.SalePersonPreviewImage.path +
              `?applicant_id=${applicantId}` +
              `&preliminary_id=${preliminaryId}` +
              `&name=${paramKeys}`,
            '_blank'
          );
        }
      }
    },
    [
      applicantId,
      dispatch,
      isCardNumberImage,
      isDriverLicenseImage,
      // isFirstWithholdingSlipFile,
      preliminaryId,
    ]
  );

  return (
    <AuthPopover
      arrow={false}
      sx={{
        minWidth: 198,
        border: (theme) => `1px solid ${theme?.palette?.line}`,
        borderTopLeftRadius: open ? 0 : '4px',
        borderTopRightRadius: open ? 0 : '4px',
      }}
      content={
        <>
          <Stack sx={{ textAlign: 'left', maxHeight: 300, overflow: 'auto' }}>
            {KEYS_FOR_PREVIEW_FILES.filter((files) =>
              files.key.some((file) => {
                if (
                  // (file === 'first_withholding_slip_file' &&
                  //   !isFirstWithholdingSlipFile) ||
                  (file === 'business_card' ||
                    file === 'property_information_file' ||
                    file === 'repayment_schedule_image') &&
                  isIncomeTotal
                ) {
                  return false;
                }
                return isHasImagePreview.includes(file);
              })
            ).map((item, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  sx={{
                    py: '10px',
                    pr: '9px',
                    pl: 3,
                    borderBottom: '1px solid #DBD9D9',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: 'main_white',
                  }}
                  onClick={() => onViewImages(item.key, item.title)}
                >
                  <Stack direction="row">
                    <Typography
                      variant="text_priliminary_pulldown"
                      sx={{ color: 'sp_primary_100_main', mr: 2 }}
                    >
                      {genTitleFilePreview(item.title)}
                    </Typography>
                    <Typography
                      variant="text_priliminary_pulldown"
                      color="#444"
                    >
                      {item.title}
                    </Typography>
                  </Stack>
                  <Icons.Rectangle sx={{ color: 'close_icon' }} />
                </Stack>
              );
            })}
          </Stack>
        </>
      }
      open={open}
      placement="bottom-start"
      contentProps={{
        height: 'auto',
        padding: 0,
        color: 'required_chip_label',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <Button
          sx={{
            minHeight: 36,
            minWidth: 200,
            boxShadow: 'none',
            bgcolor: 'main_white',
            border: '1px solid #DBD9D9',
            py: '3px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottomLeftRadius: open ? 0 : '2px',
            borderBottomRightRadius: open ? 0 : '2px',
          }}
        >
          <Typography variant="text_priliminary_pulldown">
            提出書類の表示
          </Typography>
          {!open ? (
            <Icons.ArrowDown
              sx={{ width: 16, height: 16, color: 'arrow_icon' }}
            />
          ) : (
            <Icons.ArrowUp
              sx={{ width: 16, height: 16, color: 'arrow_icon' }}
            />
          )}
        </Button>
        {/*<EmptyImagesModal*/}
        {/*  open={openModalEmpty}*/}
        {/*  itemFile={itemFileEmpty}*/}
        {/*  onClose={() => setOpenModalEmpty(!openModalEmpty)}*/}
        {/*/>*/}
      </Stack>
    </AuthPopover>
  );
};
