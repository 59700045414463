import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  CheckResetTokenError,
  CheckResetTokenRequest,
  CheckResetTokenResponse,
  ResetPasswordError,
  ResetPasswordRequest,
  ResetPasswordResponse,
  UpdatePasswordError,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const resetPassword = createAsyncThunk<
  ResetPasswordResponse,
  ResetPasswordRequest,
  ThunkAPI<ResetPasswordError>
>('authModal/resetPassword', async (user, { rejectWithValue }) => {
  try {
    return await authService.resetPassword(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const checkResetToken = createAsyncThunk<
  CheckResetTokenResponse,
  CheckResetTokenRequest,
  ThunkAPI<CheckResetTokenError>
>('authModal/checkResetToken', async (user, { rejectWithValue }) => {
  try {
    return await authService.checkResetToken(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updatePassword = createAsyncThunk<
  UpdatePasswordResponse,
  UpdatePasswordRequest,
  ThunkAPI<UpdatePasswordError>
>('authModal/updatePassword', async (user, { rejectWithValue }) => {
  try {
    return await authService.updatePassword(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});
