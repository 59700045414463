import { FC, useCallback, useMemo } from 'react';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
} from '@mui/material';
import { logEditedItem } from 'services';
import { cloneDeep } from 'lodash';
import { Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { adminDynamicOptionsSelectors } from 'pages/SPStartScreen/selectors';
import { QuantityDependent } from 'types';
import { numberWithCommas } from 'utils';
import { AdminPreliminaryItem } from 'constant';
import { convertData } from '../AdminPreliminaryTable/utils/convertData';

interface AdminEditModalProps {
  open: boolean;
  onClose: () => void;
  tableRows: logEditedItem[];
  fieldNameEn: string;
  fieldNameJP?: string;
  rows: AdminPreliminaryItem[];
  quantityDependent?: QuantityDependent;
}

export const AdminEditModal: FC<AdminEditModalProps> = ({
  open,
  onClose,
  tableRows,
  fieldNameEn,
  fieldNameJP,
  rows,
  quantityDependent,
}) => {
  const dynamicOptions = useSelector(adminDynamicOptionsSelectors);
  const fieldDynamicType = rows.find(
    (e) => e.name_en === fieldNameEn
  )?.dynamicType;
  const rowData = useMemo(() => {
    const newPerson = cloneDeep(rows);
    if (newPerson?.length <= 9) {
      let flatBorrowings = newPerson.slice(1).flat();
      return flatBorrowings.find((item: { name_en: string | string[] }) =>
        item?.name_en?.includes(fieldNameEn)
      );
    } else {
      return (
        newPerson &&
        newPerson?.find((item: { name_en: string | string[] }) =>
          item?.name_en?.includes(fieldNameEn)
        )
      );
    }
  }, [rows, fieldNameEn]);

  const formatData = useCallback(
    (dataValue: string) => {
      const lastedFieldName =
        fieldNameEn?.split('.').slice(-1).toString() ?? '';
      const dataFormatted = convertData(
        lastedFieldName,
        dataValue,
        dynamicOptions,
        rowData ? rowData.options : undefined,
        quantityDependent
      );
      const dataFormatWithComma = dataFormatted.widthComma
        ? numberWithCommas(dataFormatted.formattedValue, dataFormatted.isPrice)
        : dataFormatted.formattedValue;
      return dataFormatWithComma ? dataFormatWithComma : dataValue;
    },
    [dynamicOptions, fieldNameEn, quantityDependent, rowData]
  );

  const checkField = (fieldName: string) => {
    return !(
      fieldName.includes('postal_code') ||
      fieldName.includes('property_postal_code') ||
      fieldName.includes('home_phone_number') ||
      fieldName.includes('mobile_phone_number') ||
      fieldName.includes('sale_person_phone_number') ||
      fieldName.includes('office_phone_number') ||
      fieldName.includes('transfer_office_postal_code') ||
      fieldName.includes('transfer_office_phone_number')
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        width: '40%',
        m: 'auto',
        '&& .MuiDialog-paper': { minWidth: 641, minHeight: 461 },
      }}
    >
      <DialogTitle
        sx={{
          '&.MuiDialogTitle-root': {
            p: 0,
            height: 51,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography variant="title_show_log" color="b_333">
            {!!fieldNameJP ? fieldNameJP : rowData?.name_ja}の修正履歴
          </Typography>
        </Stack>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            p: 0,
            right: 13,
            top: 13,
            color: '#666666',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ py: '10px', px: '10px' }}>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: 'none',
            '&.MuiPaper-root': {
              borderRadius: 0,
            },
          }}
        >
          <Table sx={{ boxShadow: 'none' }}>
            <TableHead
              sx={{
                '& .MuiTableCell-head': {
                  padding: '5px',
                  borderBottom: '1px solid #333333',
                  '&:last-child th': {
                    borderRight: 'none',
                  },
                },
              }}
            >
              <TableRow>
                <StyledTableCell align="center">
                  <Typography
                    variant="required_chip_label"
                    sx={{ color: 'b_333' }}
                  >
                    日時
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    variant="required_chip_label"
                    sx={{ color: 'b_333' }}
                  >
                    会社
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    variant="required_chip_label"
                    sx={{ color: 'b_333' }}
                  >
                    担当
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    variant="required_chip_label"
                    align="center"
                    sx={{ color: 'b_333' }}
                  >
                    入力内容
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center" width="25%">
                    <Typography
                      variant="required_chip_label"
                      sx={{ fontWeight: '300' }}
                    >
                      {row.created_at}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" width="13%">
                    <Typography
                      variant="required_chip_label"
                      sx={{ fontWeight: '300' }}
                    >
                      {row.owner}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" width="17%">
                    <Typography
                      variant="required_chip_label"
                      sx={{ fontWeight: '300' }}
                    >
                      {row.owner_name_kanji}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    align={
                      fieldDynamicType === 'inputNumber' &&
                      checkField(fieldNameEn)
                        ? 'right'
                        : 'left'
                    }
                  >
                    <Typography
                      variant="required_chip_label"
                      sx={{ fontWeight: '300', overflowWrap: 'anywhere' }}
                    >
                      {formatData(row.value_column)}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f8f8f8',
    color: '#444444',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    borderRight: '1px solid #cccccc',
    padding: '0 8px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    lineHeight: '24px',
    borderRight: '1px solid #cccccc',
    padding: '0 8px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  border: 0,
}));
