import { Avatar, Stack, Typography } from '@mui/material';
import { pdf } from 'assets';
import { FC, useEffect, useState } from 'react';
import { checkIfImageExists, checkIfPDFExists } from 'utils';

type ImageItemProps = {
  src: string | File;
  type: string;
  fileName: string;
  reload?: number;
  display: boolean;
};

export const ImageItem: FC<ImageItemProps> = ({
  src,
  type,
  fileName,
  reload,
  display,
}) => {
  const [active, setActive] = useState<boolean>();

  useEffect(() => {
    if (type.includes('pdf')) {
      if (src instanceof File) {
        checkIfPDFExists(URL.createObjectURL(src)).then(setActive);
      } else {
        checkIfPDFExists(src).then(setActive);
      }
    } else {
      checkIfImageExists(src).then(setActive);
    }
  }, [src, type, reload]);

  if (!display) return null;

  return (
    <Stack direction="column" alignItems="flex-start" spacing={1}>
      <Avatar
        variant="square"
        src={
          type.includes('pdf')
            ? pdf
            : src instanceof File
            ? URL.createObjectURL(src)
            : src
        }
        sx={{
          width: 136,
          height: 100,
          '.MuiAvatar-img': {
            objectFit: type.includes('pdf') ? 'contain' : 'cover',
          },
          borderWidth: active ? 0 : 1,
          borderRadius: active ? 0 : '2px',
          borderStyle: 'solid',
          borderColor: active ? '' : 'error.main',
        }}
      />
      <Typography
        pt={1}
        variant="upload_image_title"
        color="b_333"
        sx={{
          width: 135,
          textAlign: 'center',
          lineBreak: 'anywhere',
        }}
      >
        {type.includes('pdf') ? fileName : ''}
      </Typography>
      {!active && (
        <Typography variant="text_error" color="sp_secondary_01">
          ※アップロードした書類にエラーがあります。再度アップロードしてください。
        </Typography>
      )}
    </Stack>
  );
};
