import { createSlice } from '@reduxjs/toolkit';
import { getListStatus } from './thunk';

export interface InitialState {
  under_review_status: number | null;
  full_name_kanji: string | null;
}

const initialState: InitialState = {
  under_review_status: null,
  full_name_kanji: null,
};

const listStatusSlice = createSlice({
  name: 'listStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListStatus.fulfilled, (state, action) => {
      state.under_review_status = action.payload.under_review_status;
      state.full_name_kanji = action.payload.full_name_kanji;
    });
  },
});

export default listStatusSlice.reducer;
