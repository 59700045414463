import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SortDesc: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 13" {...props}>
      <svg
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.5 1.5V11.5" stroke="currentColor" />
        <path d="M2.5 3.5L4.5 1.5L6.5 3.5" stroke="currentColor" />
        <path d="M11.5 11.5L11.5 1.5" stroke={props.stroke} />
        <path d="M13.5 9.5L11.5 11.5L9.5 9.5" stroke={props.stroke} />
      </svg>
    </SvgIcon>
  );
};
