import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPMenuIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 19" {...props}>
      <svg
        width="24"
        height="19"
        viewBox="0 0 24 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.857143C0 0.383571 0.383786 0 0.857143 0H23.1429C23.6143 0 24 0.383571 24 0.857143C24 1.33071 23.6143 1.71429 23.1429 1.71429H0.857143C0.383786 1.71429 0 1.33071 0 0.857143ZM0 9.42857C0 8.95714 0.383786 8.57143 0.857143 8.57143H23.1429C23.6143 8.57143 24 8.95714 24 9.42857C24 9.9 23.6143 10.2857 23.1429 10.2857H0.857143C0.383786 10.2857 0 9.9 0 9.42857ZM23.1429 18.8571H0.857143C0.383786 18.8571 0 18.4714 0 18C0 17.5286 0.383786 17.1429 0.857143 17.1429H23.1429C23.6143 17.1429 24 17.5286 24 18C24 18.4714 23.6143 18.8571 23.1429 18.8571Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
