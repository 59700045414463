import { FC, useMemo } from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { zeroPad } from 'utils';
import { dayjs } from 'libs';
import kanjidate from 'kanjidate';
import { SPInputSelect, SPInputSelectProps } from '../SPInputSelect';
import { validationSchema } from './validationSchema';

export type SPInputDateProps = Pick<
  SPInputSelectProps,
  'disabled' | 'readOnly'
> & {
  name: string;
  selectWidth?: string;
  selectSx?: SxProps;
  unit?: string;
  yearOptions?: SPInputSelectProps['options'];
  checkRequired?: boolean;
  errorMessage?: string;
  whiteSpace?: 'nowrap' | 'normal' | 'pre';
};

export const SPInputDate: FC<SPInputDateProps> = ({
  disabled,
  readOnly,
  selectSx,
  unit = '月',
  yearOptions,
  checkRequired,
  errorMessage,
  whiteSpace = 'nowrap',
  ...props
}) => {
  const [field, meta, form] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const initialValues = useMemo(() => {
    const [year = '', month = ''] = field.value
      ? field.value.split('/')
      : ['', ''];
    return { year, month };
  }, [field.value]);
  const windowWidth = window.innerWidth;
  const years = yearOptions ?? defaultYearOptions;

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit(values, actions) {
      if (!values.month && !values.year) {
        return setFieldValue(field.name, '');
      }
      setFieldValue(field.name, `${values.year}/${values.month}`);
      actions.setSubmitting(false);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Stack direction="row" alignItems="flex-start">
        <input name={field.name} type="hidden" />
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            mr: '5px',
            maxWidth: 182,
            '@media (max-width:385px)': {
              maxWidth: 117,
            },
          }}
        >
          <SPInputSelect
            sx={{
              maxWidth: props.selectWidth ?? 52,
              '@media (max-width:385px)': {
                maxWidth: props.selectWidth ? 98 : 52,
              },
              height: 48,
              textAlign: 'center',
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '12px',
              },
              ...selectSx,
            }}
            iconSx={{ right: 0 }}
            name="year"
            placeholder={props.selectWidth ? '----' : '--'}
            disabled={disabled}
            readOnly={readOnly}
            options={years}
            error={!!meta.error && meta.touched}
            errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
            onChange={() => formik.handleSubmit()}
            onClose={async () => {
              if (!meta.error) {
                await form.setTouched(false);
              } else {
                form.setTouched(!!formik.values.month);
              }
              await formik.handleSubmit();
            }}
            unit="年"
            useFastField={false}
            showError={false}
            checkRequired={checkRequired}
            renderValue={
              !!formik.values.year
                ? () => {
                    const selectedOption = years.find(
                      (e) => e.value === formik.values.year
                    );
                    return (
                      <Typography
                        variant="sp_unit_select_text"
                        color="normal_text"
                      >
                        {windowWidth > 385 && !!selectedOption
                          ? selectedOption.label
                          : formik.values.year}
                      </Typography>
                    );
                  }
                : () => (
                    <Typography variant="placeHolder_style" color="off_text">
                      {props.selectWidth ? '----' : '--'}
                    </Typography>
                  )
            }
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <SPInputSelect
            sx={{
              width: 52,
              height: 48,
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                pt: '12px',
              },
              ...selectSx,
            }}
            iconSx={{ right: 0 }}
            name="month"
            placeholder="--"
            disabled={disabled}
            readOnly={readOnly}
            options={monthOpts}
            error={!!meta.error && meta.touched}
            errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
            onChange={() => formik.handleSubmit()}
            onClose={async () => {
              await form.setTouched(true);
              await formik.handleSubmit();
            }}
            unit={unit}
            showError={false}
            useFastField={false}
            checkRequired={checkRequired}
          />
        </Stack>
      </Stack>
      {!!meta.error && meta.touched && (
        <Stack direction="row" sx={{ mt: 2 }} spacing={`2px`}>
          <Typography variant="text_error" color="sp_secondary_01">
            ※
          </Typography>
          <Typography
            variant="text_error"
            color="sp_secondary_01"
            whiteSpace={whiteSpace}
          >
            {meta.error ?? errorMessage ?? ''}
          </Typography>
        </Stack>
      )}
    </FormikProvider>
  );
};

const monthOpts: SPInputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: (index + 1).toString(),
  }))
);

const defaultYearOptions: SPInputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(51), (_, index) => {
    const year = zeroPad(dayjs().year() - index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);
