import { MESSAGE_REGEX } from 'constant';
import { yup } from 'libs';
import { messageOverMaxNumber } from 'utils';

export const validationSchema = yup.object().shape({
  house_purchase_price: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  additional_cost: yup.number().min(0).max(99999, messageOverMaxNumber(99999)),
  require_funds_breakdown_mortgage: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  land_purchase_price: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  accessory_cost: yup.number().min(0).max(99999, messageOverMaxNumber(99999)),
  refinancing_loan_balance: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  house_upgrade_cost: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  deposit_savings_1: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  real_estate_sale_price: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  other_saving_amount: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  relative_donation_amount: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  loan_amount: yup.number().min(0).max(99999, messageOverMaxNumber(99999)),
  pair_loan_amount: yup.number().min(0).max(99999, messageOverMaxNumber(99999)),
  other_procurement_breakdown: yup
    .number()
    .min(0)
    .max(99999, messageOverMaxNumber(99999)),
  other_procurement_breakdown_content: yup
    .string()
    .when('other_procurement_breakdown', {
      is: (other_procurement_breakdown: number) => other_procurement_breakdown,
      then: yup.string().required(MESSAGE_REGEX.REQUIRED).max(128),
      // .matches(
      //   regex.kanjiFullwidthHaveNumber,
      //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
      // ),
      otherwise: yup.string(),
    }),
});
