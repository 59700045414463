import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { instance } from 'libs';
import { config } from '../configs';
import {
  ArchiveFileDeleteResponse,
  archiveFileDeleteResponseSchema,
  ArchiveFileEditResponse,
  archiveFileEditResponseSchema,
  ArchiveFilesManagerRequest,
  ArchiveFilesManagerResponse,
  archiveFilesManagerResponseSchema,
  ArchiveFilesSalePersonResponse,
  archiveFilesSalePersonResponseSchema,
  ManagerEditSortArchiveFileItemRequest,
  ManagerEditSortArchiveFileItemResponse,
  ManagerSortArchiveFileItemRequest,
  ManagerSortArchiveFileItemResponse,
  UploadDocumentNoteManagerResponse,
  UploadDocumentSalePersonResponse,
  uploadDocumentSalePersonResponseSchema,
} from './schemas/adminArchiveFile';

export const getListArchiveFileManager = (
  params: ArchiveFilesManagerRequest
): Promise<ArchiveFilesManagerResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/manager/archive_files',
    params,
  };
  return instance(request).then(({ data }) =>
    archiveFilesManagerResponseSchema.parse(data)
  );
};

export const getListArchiveFileSalePerson = (
  params: ArchiveFilesManagerRequest
): Promise<ArchiveFilesSalePersonResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/s_sale_person/archive_files',
    params,
  };
  return instance(request).then(({ data }) =>
    archiveFilesSalePersonResponseSchema.parse(data)
  );
};

export const uploadDocumentSalePerson = (
  data: FormData
): Promise<UploadDocumentSalePersonResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/s_sale_person/archive_files',
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };
  return instance(request).then(({ data }) =>
    uploadDocumentSalePersonResponseSchema.parse(data)
  );
};

export const uploadDocumentNoteSalePerson = (params: {
  data: FormData;
  id: string;
}): Promise<UploadDocumentNoteManagerResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/s_sale_person/archive_files/${params.id}`,
    data: params.data,
    headers: { 'content-type': 'multipart/form-data' },
  };
  return instance(request).then(({ data }) =>
    uploadDocumentSalePersonResponseSchema.parse(data)
  );
};
export const uploadDocumentNoteManager = (params: {
  data: FormData;
  id: string;
}): Promise<UploadDocumentNoteManagerResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/archive_files/${params.id}`,
    data: params.data,
    headers: { 'content-type': 'multipart/form-data' },
  };
  return instance(request).then(({ data }) =>
    uploadDocumentSalePersonResponseSchema.parse(data)
  );
};

export const getEditArchiveManagerFile = (
  id: string
): Promise<ArchiveFileEditResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/archive_files/${id}/edit`,
  };
  return instance(request).then(({ data }) =>
    archiveFileEditResponseSchema.parse(data)
  );
};
export const getEditArchiveSalePersonFile = (
  id: string
): Promise<ArchiveFileEditResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/s_sale_person/archive_files/${id}/edit`,
  };
  return instance(request).then(({ data }) =>
    archiveFileEditResponseSchema.parse(data)
  );
};

export const deleteManagerArchiveFile = (
  id: string
): Promise<ArchiveFileDeleteResponse> => {
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: `/manager/archive_files/${id}`,
  };
  return instance(request).then(({ data }) =>
    archiveFileDeleteResponseSchema.parse(data)
  );
};

export const deleteSalePersonArchiveFile = (
  id: string
): Promise<ArchiveFileDeleteResponse> => {
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: `/s_sale_person/archive_files/${id}`,
  };
  return instance(request).then(({ data }) =>
    archiveFileDeleteResponseSchema.parse(data)
  );
};

export const getSortArchiveFileManager = (
  params?: ManagerSortArchiveFileItemRequest
): AxiosPromise<ManagerSortArchiveFileItemResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/manager/archive_files',
    params,
  };
  return instance(request);
};

export const getEditSortArchiveFileManager = (
  params: ManagerEditSortArchiveFileItemRequest
): AxiosPromise<ManagerEditSortArchiveFileItemResponse> => {
  const { id, ...restParams } = params;
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/archive_files/${id}/edit`,
    params: restParams,
  };
  return instance(request);
};

export const getEditSortArchiveFileSalePerson = (
  params: ManagerEditSortArchiveFileItemRequest
): AxiosPromise<ManagerEditSortArchiveFileItemResponse> => {
  const { id, ...restParams } = params;
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/s_sale_person/archive_files/${id}/edit`,
    params: restParams,
  };
  return instance(request);
};

export const updateArchiveFileSalePerson = (params: {
  data: FormData;
  id: string;
}): Promise<UploadDocumentNoteManagerResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/s_sale_person/archive_files/${params.id}`,
    data: params.data,
    headers: { 'content-type': 'multipart/form-data' },
  };
  return instance(request).then(({ data }) =>
    uploadDocumentSalePersonResponseSchema.parse(data)
  );
};

export const getSortArchiveFileSalePerson = (
  params?: ManagerSortArchiveFileItemRequest
): AxiosPromise<ManagerSortArchiveFileItemResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/s_sale_person/archive_files',
    params,
  };
  return instance(request);
};

export const getDownloadZipFileManager = (
  params: { id: string },
  accessToken: string
): Promise<Response> => {
  return fetch(
    `${config.serverUrl}/manager/download_zip_file?id=${params.id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getDownloadZipFileSalePerson = (
  params: { id: string },
  accessToken: string
): Promise<Response> => {
  return fetch(
    `${config.serverUrl}/s_sale_person/download_zip_file?id=${params.id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};
