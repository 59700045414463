import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton } from '../../atoms';

interface NotifyScreenTemplateProps {
  title: string;
  icon: ReactNode;
  children: ReactNode;
  buttonTitle?: string;
  onClick?: () => void;
}

const NotifyScreenTemplate: FC<NotifyScreenTemplateProps> = ({
  title,
  icon,
  children,
  buttonTitle,
  onClick,
}) => {
  return (
    <Stack
      sx={{ textAlign: 'center', alignItems: 'center', maxWidth: 480 }}
      pt={7}
    >
      {icon}
      <Typography
        variant="sp_change_password_title"
        color="sp_primary_100_main"
        pb={7}
        sx={{
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all',
        }}
      >
        {title}
      </Typography>
      <Stack width="100%">
        {children}
        {buttonTitle && onClick && (
          <Stack p={6}>
            <SPButton
              sx={{
                bgcolor: 'sp_primary_100_main',
                color: 'main_white',
                width: '100%',
                minHeight: 54,
              }}
              endIcon={<Icons.ArrowForward stroke="white" />}
              onClick={onClick}
            >
              <Typography variant="sp_save_draft_text" color="main_white">
                {buttonTitle}
              </Typography>
            </SPButton>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default NotifyScreenTemplate;
