import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const RadioDisabled: FC<SvgIconProps> = (props) => {
  const id = useMemo<string>(
    () => Math.round(Math.random() * 10000000).toString(),
    []
  );
  return (
    <SvgIcon
      sx={{
        borderRadius: 30,
        boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 4px',
      }}
      viewBox="0 0 22 22"
      {...props}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter={`url(#${id})`}>
          <circle
            cx="13"
            cy="13"
            r="11"
            fill="white"
            fillOpacity="0.2"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id={id}
            x="0"
            y="0"
            width="30"
            height="30"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2251_4286"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2251_4286"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};
