import { StatusResult } from 'constant';
import { statusResultSelector } from 'containers/AuthModal/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { spStepTenSelector } from '../pages/SPStepTen/selectors';

export const useCheckCorrectable = () => {
  const { application_number, editable } = useSelector(spStepTenSelector);
  const status_result = useSelector(statusResultSelector);

  return useMemo(() => {
    if (application_number && !editable) return false;
    switch (status_result) {
      case StatusResult.APPROVAL:
      case StatusResult.DATA_LINKAGE_TO_BANKS:
      case StatusResult.DISCLOSURE_RESULTS_TO_PARTNER_COMPANIES:
      case StatusResult.DISCLOSURE_RESULTS_TO_APPLICANTS:
        return false;
      default:
        return true;
    }
  }, [status_result, application_number, editable]);
};
