import { RootState } from 'types';
import { createSelector } from 'reselect';
import { convertYenToMan } from 'utils';

export const spStepSixSelector = (state: RootState) => ({
  p_application_header: state.sp_step_six.form.p_application_header,
});

export const convertStepSixSelector = createSelector(
  spStepSixSelector,
  (input) => {
    const [
      house_purchase_price,
      additional_cost,
      require_funds_breakdown_mortgage,
      land_purchase_price,
      accessory_cost,
      refinancing_loan_balance,
      house_upgrade_cost,
      deposit_savings_1,
      real_estate_sale_price,
      other_saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
    ] = convertYenToMan([
      input.p_application_header.house_purchase_price,
      input.p_application_header.additional_cost,
      input.p_application_header.require_funds_breakdown_mortgage,
      input.p_application_header.land_purchase_price,
      input.p_application_header.accessory_cost,
      input.p_application_header.refinancing_loan_balance,
      input.p_application_header.house_upgrade_cost,
      input.p_application_header.deposit_savings_1,
      input.p_application_header.real_estate_sale_price,
      input.p_application_header.other_saving_amount,
      input.p_application_header.relative_donation_amount,
      input.p_application_header.loan_amount,
      input.p_application_header.pair_loan_amount,
      input.p_application_header.other_procurement_breakdown,
    ]);
    return {
      house_purchase_price,
      additional_cost,
      require_funds_breakdown_mortgage,
      land_purchase_price,
      accessory_cost,
      refinancing_loan_balance,
      house_upgrade_cost,
      deposit_savings_1,
      real_estate_sale_price,
      other_saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
      other_procurement_breakdown_content:
        input.p_application_header.other_procurement_breakdown_content,
    };
  }
);
