import { SalePersonPreliminaries } from 'services';
import { buildContactInformation } from '../../AdminEditPreliminary/utils';

export const buildContactInformationSalePerson = (
  values: SalePersonPreliminaries['p_application_header']
) => {
  return buildContactInformation(values).filter(({ name_en }) => {
    // if (name_en === 'sale_person_information.sale_person_phone') {
    //   return false;
    // }
    return true;
  });
};
