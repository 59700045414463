import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
  RotateLeft,
  RotateRight,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ScrollMode, Viewer, Worker } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { pdf } from 'assets';
import { useAppDispatch } from 'hooks';
import { get } from 'lodash';
import { getUploadedFilesSalePerson } from 'pages/SalePersonEditPreliminary/thunk';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Scroll from 'react-scroll';
import { RepaymentScheduleImage, ViewUploadedFile } from 'services';
import { theme } from 'styles';
import { genTitleFilePreview, getFileExtFromUrl } from 'utils';
import { KEYS_FOR_PREVIEW_FILES } from 'constant';

type FileBorrowing = {
  id: string;
  filename: string;
  path: string;
  updated_by: string;
  created_at: string;
  title: string;
  subTitle: string;
};

const Element = Scroll.Element;
const scroller = Scroll.scroller;
const SalePersonPreviewImage: FC = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [rotate, setRotate] = useState<number>(0);
  const [images, setImages] = useState<ViewUploadedFile[]>([
    {
      path: '',
      created_at: '',
      filename: '',
      updated_by: '',
      title: '',
      subTitle: '',
    },
  ]);
  const [selectedImage, setSelectedImage] = useState<ViewUploadedFile>({
    created_at: '',
    filename: '',
    path: '',
    updated_by: '',
    title: '',
    subTitle: '',
  });

  const [currentImageBorrowing, setCurrentImageBorrowing] =
    useState<FileBorrowing>({
      id: '',
      filename: '',
      path: '',
      created_at: '',
      updated_by: '',
      title: '',
      subTitle: '',
    });
  const [allFilesBorrowing, setAllFilesBorrowing] = useState<FileBorrowing[]>(
    []
  );

  const [isLoaded, setIsLoaded] = useState(false);

  const applicant_id = useMemo(() => {
    const applicantIdParam = query.get('applicant_id');
    return applicantIdParam ? applicantIdParam : '';
  }, [query]);

  const id = useMemo(() => {
    const preliminaryIdParam = query.get('preliminary_id');
    return preliminaryIdParam ? preliminaryIdParam : '';
  }, [query]);

  useEffect(() => {
    const getListPreliminaryImage = async () => {
      const keys = params.get('name')?.split(',');

      if (!id || !applicant_id || !keys) return;
      const result = await dispatch(
        getUploadedFilesSalePerson({ id, applicant_id })
      );

      if (getUploadedFilesSalePerson.fulfilled.match(result)) {
        const dataSalePerson = KEYS_FOR_PREVIEW_FILES.find((e) => {
          for (const key of keys) {
            if (e.key.includes(key)) {
              return true;
            }
          }
          return false;
        });

        if (keys.includes('repayment_schedule_image')) {
          const valuesKeyBorrowing = ['repayment_schedule_image'].map((key) => {
            const title =
              dataSalePerson && dataSalePerson.title
                ? dataSalePerson.title
                : '';
            const dataBorrowing = get(
              result.payload.data,
              'repayment_schedule_image'
            );
            dataBorrowing?.forEach((item, index) => {
              item.files.forEach((file) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                (file.title = title),
                  (file.subTitle = `${index + 1}件目の借入`);
              });
            });
            return dataBorrowing;
          });
          const imageBorrowings: RepaymentScheduleImage[] = valuesKeyBorrowing
            .flat()
            .filter((image) => image.files.length > 0);
          const listImageFile: FileBorrowing[] = [];
          imageBorrowings.forEach((images) =>
            images.files.forEach((image) =>
              listImageFile.push({
                id: image.id,
                updated_by: image.updated_by,
                filename: image.filename,
                created_at: image.created_at,
                path: image.path,
                title: image.title ? image.title : '',
                subTitle: image.subTitle ? image.subTitle : '',
              })
            )
          );
          if (allFilesBorrowing.length === 0) {
            setAllFilesBorrowing(listImageFile);
            setCurrentImageBorrowing(listImageFile[0]);
          }
        } else {
          const valuesByKey = keys
            .map((key) => {
              const subTitle =
                dataSalePerson &&
                dataSalePerson.subTitle &&
                dataSalePerson.subTitle.length > 0
                  ? dataSalePerson.subTitle.find((e) => e.key === key)?.title
                  : '';
              const title =
                dataSalePerson && dataSalePerson.title
                  ? dataSalePerson.title
                  : '';
              const dataDefault = get(result.payload.data, key);
              return dataDefault?.map((item: ViewUploadedFile) => ({
                ...item,
                title,
                subTitle,
              }));
            })
            .flat();
          setImages(valuesByKey);
          setSelectedImage(valuesByKey[0]);
        }
      } else {
        const error = result.payload as string;
        if (!error.includes('not_permission')) setImages([]);
      }
    };
    getListPreliminaryImage();
  }, [allFilesBorrowing.length, applicant_id, dispatch, id, params]);

  useEffect(() => {
    if (!!selectedImage?.title || !!currentImageBorrowing?.title)
      setIsLoaded(true);
  }, [currentImageBorrowing, selectedImage]);

  const handleNextImage = useCallback(() => {
    if (!selectedImage?.title) {
      return;
    }
    const itemIndex = images.findIndex((e) => e.path === selectedImage.path);
    const index = itemIndex < images.length - 1 ? itemIndex + 1 : 0;
    scroller.scrollTo(`image-${index}`, {
      duration: 300,
      smooth: true,
      containerId: 'scroll-images',
      isDynamic: true,
      horizontal: true,
    });
    setSelectedImage(images[index]);
  }, [images, selectedImage]);

  const handleNextImageBorrowing = useCallback(() => {
    if (!currentImageBorrowing?.title) {
      return;
    }
    setRotate(0);
    const indexItem = allFilesBorrowing?.findIndex((e, index) => {
      return e.id === currentImageBorrowing.id;
    });
    const index = indexItem < allFilesBorrowing.length - 1 ? indexItem + 1 : 0;
    scroller.scrollTo(`image-${index}`, {
      duration: 300,
      smooth: true,
      containerId: 'scroll-images',
      isDynamic: true,
      horizontal: true,
    });
    setCurrentImageBorrowing(allFilesBorrowing[index]);
  }, [allFilesBorrowing, currentImageBorrowing]);

  const handleBackImage = useCallback(() => {
    if (!selectedImage?.title) {
      return;
    }
    const itemIndex = images?.findIndex((e) => e.path === selectedImage.path);
    const index = itemIndex !== 0 ? itemIndex - 1 : images.length - 1;
    scroller.scrollTo(`image-${index}`, {
      duration: 300,
      smooth: true,
      containerId: 'scroll-images',
      isDynamic: true,
      horizontal: true,
    });
    setSelectedImage(images[index]);
  }, [images, selectedImage]);

  const handleBackImageBorrowing = useCallback(() => {
    if (!currentImageBorrowing?.title) {
      return;
    }
    setRotate(0);
    const itemIndex = allFilesBorrowing.findIndex(
      (e) => e.id === currentImageBorrowing.id
    );
    const index =
      itemIndex !== 0 ? itemIndex - 1 : allFilesBorrowing.length - 1;
    scroller.scrollTo(`image-${index}`, {
      duration: 300,
      smooth: true,
      containerId: 'scroll-images',
      isDynamic: true,
      horizontal: true,
    });
    setCurrentImageBorrowing(allFilesBorrowing[index]);
  }, [currentImageBorrowing, allFilesBorrowing]);

  const renderSelectedImage = useCallback(
    (imageParam: ViewUploadedFile[]) => {
      return (
        <Stack
          id="scroll-images"
          direction="row"
          sx={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
          }}
        >
          {imageParam.map((image, index) => (
            <Element key={index} name={`image-${index}`}>
              <Stack
                sx={{
                  width: 100,
                  height: 90,
                  border:
                    selectedImage?.path === image.path
                      ? `4px solid ${theme?.palette?.h_blugreen}`
                      : '',
                  padding: selectedImage?.path === image.path ? 0 : 1,
                }}
                onClick={() => {
                  setRotate(0);
                  setSelectedImage(image);
                  scroller.scrollTo(`image-${index}`, {
                    duration: 300,
                    smooth: true,
                    containerId: 'scroll-images',
                    isDynamic: true,
                    horizontal: true,
                  });
                }}
              >
                {image.filename.includes('pdf') ? (
                  <Avatar
                    variant="square"
                    src={pdf}
                    sx={{
                      width: '100px',
                      height: '100%',
                      '.MuiAvatar-img': {
                        objectFit: 'contain',
                      },
                    }}
                  />
                ) : (
                  <img
                    src={image.path}
                    height="100%"
                    width="vw"
                    alt={image.filename}
                    style={{ objectFit: 'cover' }}
                  />
                )}
              </Stack>
            </Element>
          ))}
        </Stack>
      );
    },
    [selectedImage?.path]
  );

  const renderImageBorrowing = useCallback(
    (imageBorrowingParam: FileBorrowing[]) => {
      return (
        <Stack
          id="scroll-images"
          direction="row"
          sx={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
          }}
        >
          {imageBorrowingParam.map((image, index) => (
            <Element key={index} name={`image-${index}`}>
              <Stack
                sx={{
                  width: 100,
                  height: 90,
                  border:
                    currentImageBorrowing.id === image.id
                      ? `4px solid ${theme?.palette?.h_blugreen}`
                      : '',
                  padding: currentImageBorrowing.id === image.id ? 0 : 1,
                }}
                onClick={() => {
                  setRotate(0);
                  setCurrentImageBorrowing(image);
                  scroller.scrollTo(`image-${index}`, {
                    duration: 300,
                    smooth: true,
                    containerId: 'scroll-images',
                    isDynamic: true,
                    horizontal: true,
                  });
                }}
              >
                {image?.filename.includes('pdf') ? (
                  <Avatar
                    variant="square"
                    src={pdf}
                    sx={{
                      width: '100%',
                      height: '100%',
                      '.MuiAvatar-img': {
                        objectFit: 'contain',
                      },
                    }}
                  />
                ) : (
                  <img
                    src={image.path}
                    height="100%"
                    width="vw"
                    alt={image.filename}
                    style={{ objectFit: 'cover' }}
                  />
                )}
              </Stack>
            </Element>
          ))}
        </Stack>
      );
    },
    [currentImageBorrowing]
  );

  const renderImage = useCallback((image: ViewUploadedFile) => {
    return (
      <>
        {!!image && !image.filename.includes('pdf') && (
          <img
            onLoad={() => setIsLoaded(true)}
            hidden={true}
            src={image.path}
            alt={image.filename}
            style={{ objectFit: 'cover' }}
          />
        )}
      </>
    );
  }, []);

  const renderBorrowing = useCallback((image: FileBorrowing) => {
    return (
      <>
        {!!image && !image.filename.includes('pdf') && (
          <img
            onLoad={() => setIsLoaded(true)}
            hidden={true}
            src={image.path}
            alt={image.filename}
            style={{ objectFit: 'cover' }}
          />
        )}
      </>
    );
  }, []);

  const scrollModePluginInstance = scrollModePlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;

  const renderDiffBorrowingDiv = useCallback(
    (item: ViewUploadedFile) => {
      return (
        <Stack sx={{ height: 'calc(100vh)' }}>
          <Stack direction="row" sx={{ height: 50, px: 3 }}>
            <Stack flex={1} sx={{ justifyContent: 'center' }}>
              <Typography>アップロード：{item?.updated_by}</Typography>
            </Stack>
            <Stack
              flex={2}
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="title_upload_file"
                  sx={{ alignSelf: 'center', color: 'b_333', fontSize: 16 }}
                >
                  <Typography
                    variant="title_avatar_card"
                    sx={{
                      color: 'sp_primary_100_main',
                      fontSize: 20,
                      lineHeight: '24px',
                    }}
                  >
                    {genTitleFilePreview(item?.title || '')}
                  </Typography>
                  {item?.title}&nbsp;
                </Typography>
                <Typography
                  variant="text_priliminary_pulldown"
                  sx={{ textAlign: 'center' }}
                >
                  {item?.subTitle}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              sx={{ justifyContent: 'center', alignItems: 'flex-end' }}
            >
              <Typography>{item?.created_at}</Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: 'calc(85vh - 50px)',
              alignItems: 'center',
              borderBottom: `4px solid #EAEAEA`,
            }}
          >
            <Stack
              sx={{
                position: 'absolute',
                top: '45%',
                right: 20,
                height: 32,
                width: 32,
                borderRadius: 16,
                backgroundColor: '#EAEAEA',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}
              onClick={() => handleNextImage()}
            >
              <ArrowForwardIosOutlined fontSize="inherit" />
            </Stack>
            <Stack
              sx={{
                position: 'absolute',
                top: '45%',
                left: 20,
                height: 32,
                width: 32,
                borderRadius: 16,
                backgroundColor: '#EAEAEA',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}
              onClick={() => handleBackImage()}
            >
              <ArrowBackIosNewOutlined fontSize="inherit" />
            </Stack>
            {!!item && getFileExtFromUrl(item.path).includes('pdf') ? (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '50%', height: '100%', ml: 8 }}
              >
                <Worker workerUrl="/pdf.worker.bundle.js">
                  <Viewer
                    fileUrl={item.path}
                    plugins={[
                      scrollModePluginInstance,
                      pageNavigationPluginInstance,
                    ]}
                    scrollMode={ScrollMode.Page}
                  />
                </Worker>
                <Stack>
                  <GoToPreviousPage />
                  <GoToNextPage />
                </Stack>
              </Stack>
            ) : (
              <Box
                sx={{
                  alignSelf: 'center',
                  position: 'relative',
                  height: 'calc(85vh - 50px)',
                  width: 'calc(85vh - 50px)',
                  bgcolor: 'grey.300',
                  '&:hover': {
                    '.tool': {
                      bgcolor: 'rgba(0,6,155,0.3)',
                      color: 'white',
                    },
                  },
                }}
              >
                <Stack
                  direction="row-reverse"
                  className="tool"
                  spacing={2}
                  sx={{
                    p: 1,
                    bgcolor: 'rgba(0,6,155,0.2)',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    bottom: 0,
                  }}
                >
                  <IconButton
                    onClick={() => setRotate((prevState) => --prevState)}
                  >
                    <RotateLeft sx={{ color: 'white' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => setRotate((prevState) => ++prevState)}
                  >
                    <RotateRight sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
                <Avatar
                  variant="square"
                  sx={{
                    height: '100%',
                    width: '100%',
                    transform: `rotate(${rotate * 90}deg)`,
                    img: {
                      objectFit: 'contain',
                    },
                  }}
                  src={item.path}
                  alt={item.filename}
                />
              </Box>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              height: 'calc(15vh)',
              width: '100%',
              overflowX: 'scroll',
            }}
          >
            {selectedImage && !!selectedImage?.title
              ? renderSelectedImage(images)
              : renderImageBorrowing(allFilesBorrowing)}
          </Stack>
        </Stack>
      );
    },
    [
      GoToNextPage,
      GoToPreviousPage,
      allFilesBorrowing,
      handleBackImage,
      handleNextImage,
      images,
      pageNavigationPluginInstance,
      renderImageBorrowing,
      renderSelectedImage,
      rotate,
      scrollModePluginInstance,
      selectedImage,
    ]
  );

  const renderBorrowingDiv = useCallback(
    (item: FileBorrowing) => {
      return (
        <Stack sx={{ height: 'calc(100vh)' }}>
          <Stack direction="row" sx={{ height: 50, px: 3 }}>
            <Stack flex={1} sx={{ justifyContent: 'center' }}>
              <Typography>アップロード：{item?.updated_by}</Typography>
            </Stack>
            <Stack
              flex={2}
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="title_upload_file"
                  sx={{ alignSelf: 'center', color: 'b_333', fontSize: 16 }}
                >
                  <Typography
                    variant="title_avatar_card"
                    sx={{
                      color: 'sp_primary_100_main',
                      fontSize: 20,
                      lineHeight: '24px',
                    }}
                  >
                    {genTitleFilePreview(item?.title || '')}
                  </Typography>
                  {item?.title}&nbsp;
                </Typography>
                <Typography
                  variant="text_priliminary_pulldown"
                  sx={{ textAlign: 'center' }}
                >
                  {item?.subTitle}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              sx={{ justifyContent: 'center', alignItems: 'flex-end' }}
            >
              <Typography>{item?.created_at}</Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              height: 'calc(85vh - 50px)',
              alignItems: 'center',
              borderBottom: `4px solid #EAEAEA`,
            }}
          >
            <Stack
              sx={{
                position: 'absolute',
                top: '45%',
                right: 20,
                height: 32,
                width: 32,
                borderRadius: 16,
                backgroundColor: '#EAEAEA',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}
              onClick={() => handleNextImageBorrowing()}
            >
              <ArrowForwardIosOutlined fontSize="inherit" />
            </Stack>
            <Stack
              sx={{
                position: 'absolute',
                top: '45%',
                left: 20,
                height: 32,
                width: 32,
                borderRadius: 16,
                backgroundColor: '#EAEAEA',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1,
              }}
              onClick={() => handleBackImageBorrowing()}
            >
              <ArrowBackIosNewOutlined fontSize="inherit" />
            </Stack>
            {!!item && getFileExtFromUrl(item?.path).includes('pdf') ? (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '50%', height: '100%', ml: 8 }}
              >
                <Worker workerUrl="/pdf.worker.bundle.js">
                  <Viewer
                    fileUrl={item?.path}
                    plugins={[
                      scrollModePluginInstance,
                      pageNavigationPluginInstance,
                    ]}
                    scrollMode={ScrollMode.Page}
                  />
                </Worker>
                <Stack>
                  <GoToPreviousPage />
                  <GoToNextPage />
                </Stack>
              </Stack>
            ) : (
              <Box
                sx={{
                  alignSelf: 'center',
                  position: 'relative',
                  height: 'calc(85vh - 50px)',
                  width: 'calc(85vh - 50px)',
                  bgcolor: 'grey.300',
                  '&:hover': {
                    '.tool': {
                      bgcolor: 'rgba(0,6,155,0.3)',
                      color: 'white',
                    },
                  },
                }}
              >
                <Stack
                  direction="row-reverse"
                  className="tool"
                  spacing={2}
                  sx={{
                    p: 1,
                    bgcolor: 'rgba(0,6,155,0.2)',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    bottom: 0,
                  }}
                >
                  <IconButton
                    onClick={() => setRotate((prevState) => --prevState)}
                  >
                    <RotateLeft sx={{ color: 'white' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => setRotate((prevState) => ++prevState)}
                  >
                    <RotateRight sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
                <Avatar
                  variant="square"
                  sx={{
                    height: '100%',
                    width: '100%',
                    transform: `rotate(${rotate * 90}deg)`,
                    img: {
                      objectFit: 'contain',
                    },
                  }}
                  src={item?.path}
                  alt={item?.filename}
                />
              </Box>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              height: 'calc(15vh)',
              width: '100%',
              overflowX: 'scroll',
            }}
          >
            {allFilesBorrowing.length > 0
              ? renderImageBorrowing(allFilesBorrowing)
              : renderSelectedImage(images)}
          </Stack>
        </Stack>
      );
    },
    [
      GoToNextPage,
      GoToPreviousPage,
      allFilesBorrowing,
      handleBackImageBorrowing,
      handleNextImageBorrowing,
      images,
      pageNavigationPluginInstance,
      renderImageBorrowing,
      renderSelectedImage,
      rotate,
      scrollModePluginInstance,
    ]
  );
  return (
    <>
      {!!currentImageBorrowing?.title && renderBorrowing(currentImageBorrowing)}
      {!!selectedImage?.title &&
        allFilesBorrowing.length === 0 &&
        renderImage(selectedImage)}
      {!isLoaded && (
        <Stack sx={{ top: '50%', left: '50%', position: 'fixed' }}>
          <CircularProgress sx={{ color: 'sp_primary_100_main' }} />
        </Stack>
      )}
      {isLoaded && (
        <>
          {!!selectedImage?.title
            ? renderDiffBorrowingDiv(selectedImage)
            : renderBorrowingDiv(currentImageBorrowing)}
        </>
      )}
    </>
  );
};

export default SalePersonPreviewImage;
