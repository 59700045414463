import { Divider, LinearProgress, Stack } from '@mui/material';
import { DrawerMenu } from 'containers';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChangePasswordModal, DownloadCsvModal } from 'components';
import { LogoutModal } from 'components';
import { managerLogout } from 'pages/Login/thunk';
import { useAppDispatch } from 'hooks';
import { routeNames } from 'navigations/routes';
import { salePersonLogout } from 'pages/SalePersonLogin/thunk';
import { CreateMessageModal } from 'pages/ScreenMessages/components/CreateMessageModal';
import { resetAuth } from 'containers/AuthModal/slice';
import { useSelector } from 'react-redux';
import { downloadLog } from '../../pages/AdminEditPreliminary/thunk';
import { clearSalePerson } from '../../libs';
import { isAzureLoginSelector } from '../AuthModal/selectors';
import { Footer, FooterProps, Header, HeaderProps } from './components';

type LayoutProps = PropsWithChildren<{
  loading?: boolean;
  header?: HeaderProps;
  footer?: FooterProps;
  hasFooter?: boolean;
  hasDrawer?: boolean;
}>;

export const AdminLayout: FC<LayoutProps> = ({
  loading,
  footer,
  children,
  header,
  hasFooter = true,
  hasDrawer = true,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAzureLogin = useSelector(isAzureLoginSelector);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDownload, setOpenModalDownload] = useState<boolean>(false);
  const [openModalLogout, setOpenModalLogout] = useState<boolean>(false);
  const [openModalNewMessage, setOpenModalNewMessage] =
    useState<boolean>(false);

  const handleLogout = useCallback(async () => {
    if (location.pathname.startsWith('/manager/', 0)) {
      await dispatch(managerLogout());
      clearSalePerson();
      await dispatch(resetAuth());
      navigate(routeNames.LoginManager.path);
      sessionStorage.removeItem('preliminary_id');
    } else if (location.pathname.startsWith('/sale-person/', 0)) {
      if (isAzureLogin) {
        await dispatch(salePersonLogout());
        clearSalePerson();
        await dispatch(resetAuth());
        navigate(routeNames.SalePersonLogoutAzure.path);
        sessionStorage.removeItem('preliminary_id');
      } else {
        await dispatch(salePersonLogout());
        clearSalePerson();
        await dispatch(resetAuth());
        navigate(routeNames.LoginSalePerson.path);
        sessionStorage.removeItem('preliminary_id');
      }
    }
  }, [location.pathname, dispatch, navigate, isAzureLogin]);

  const handleDownloadLog = useCallback(
    async (values: { from_date: string; to_date: string }) => {
      await dispatch(downloadLog(values));
    },
    [dispatch]
  );

  return (
    <Stack sx={{ overflow: 'auto' }}>
      {openModal && (
        <ChangePasswordModal
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
      {openModalLogout && (
        <LogoutModal
          open={openModalLogout}
          onClose={() => setOpenModalLogout(false)}
          onLogOut={handleLogout}
        />
      )}
      {openModalNewMessage && (
        <CreateMessageModal
          open={openModalNewMessage}
          onClose={() => setOpenModalNewMessage(false)}
        />
      )}

      <DownloadCsvModal
        open={openModalDownload}
        onClose={() => setOpenModalDownload(false)}
        onSubmit={handleDownloadLog}
      />

      <Header
        {...header}
        setOpenModal={(open) => setOpenModal(open)}
        setOpenModalLogout={(logout) => setOpenModalLogout(logout)}
        setOpenModalNewMessage={(newMessage) =>
          setOpenModalNewMessage(newMessage)
        }
        onDownloadLog={() => setOpenModalDownload(true)}
      />
      <Stack
        sx={{
          height: 40,
          '@media (max-width:850px)': {
            height: 57,
          },
        }}
      />
      <Stack sx={{ position: 'relative', bgcolor: 'bg_off' }}>
        {hasDrawer && (
          <Stack
            sx={{
              height: '100%',
              width: 44,
              bgcolor: 'main_white',
              position: 'fixed',
              left: 0,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            }}
          >
            <Stack sx={{ px: '2px', py: '10px' }}>
              <DrawerMenu />
            </Stack>
            <Divider sx={{ borderColor: 'gray' }} />
          </Stack>
        )}
        {loading ? <Loading /> : children}
      </Stack>
      {hasFooter && <Footer {...footer} />}
    </Stack>
  );
};

export const Loading = () => (
  <Stack sx={{ color: 'sp_primary_100_main' }}>
    <LinearProgress color="inherit" />
  </Stack>
);
