import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  CreateMessageRequest,
  CreateMessageResponse,
  GetMessageResponse,
} from 'services';

export const getMessages = createAsyncThunk<GetMessageResponse>(
  'Messages/getMessages',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getMessages();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createMessage = createAsyncThunk<
  CreateMessageResponse,
  CreateMessageRequest
>('Messages/createMessages', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.createMessage(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
