import { FC, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'containers/AuthModal/selectors';

type SaveDraftButtonProps = {
  onSaveDraft?: () => void;
};

export const SPSaveDraftButton: FC<SaveDraftButtonProps> = ({
  onSaveDraft,
}) => {
  const isLoggedIn = useSelector(isLoggedInSelector);

  const handleSave = useCallback(async () => {
    if (isLoggedIn) {
      onSaveDraft && onSaveDraft();
    } else {
      //TODO: redirect to SP Login screen
    }
  }, [isLoggedIn, onSaveDraft]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
      }}
    >
      <Button
        sx={{
          width: 78,
          height: 63,
          px: 2,
          py: 4,
          borderRadius: '20px 0 0 0',
          borderTop: '6px solid #FEFEFE',
          borderLeft: '6px solid #FEFEFE',
          background: 'linear-gradient(180deg, #F27C9B 0%, #E54E75 100%)',
          flexDirection: 'column',
        }}
        onClick={handleSave}
      >
        <Icons.SPSaveDraft />
        <Typography color="main_white" variant="sp_save_draft_text">
          保存
        </Typography>
      </Button>
    </Stack>
  );
};
