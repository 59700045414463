import { AxiosRequestConfig } from 'axios';
import { internalInstance } from 'libs';
import { masterBankResponseSchema, GetMasterBankResponse } from './schemas';

export const getMasterBanks = (): Promise<GetMasterBankResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/master_banks',
  };
  return internalInstance(request).then(({ data }) =>
    masterBankResponseSchema.parse(data)
  );
};
