import { Divider, Grid, Stack, Typography } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import {
  CheckboxButton,
  CheckboxMultipleButton,
  FormItem,
  InputDate,
  InputField,
  InputSelectProps,
  RadioGroupButton,
} from 'components';
import {
  BorrowerType,
  borrowingCategory,
  BorrowingCategoryType,
  borrowingFromHousingFinanceAgency,
  BorrowingFromHousingFinanceAgencyType,
  BorrowingType,
  BusinessBorrowingType,
  commonHousing,
  CommonHousingType,
  estateMortgage,
  EstateMortgageType,
  FundsPairOffType,
  loanbalance,
  LoanBalanceType,
  LoanPurposeType,
  RepaymentBorrowingType,
  scheduledCompletionOfBorrowing,
  ScheduledCompletionOfBorrowingType,
  ScheduledLoanPayoffType,
} from 'constant';
import {
  PBorrowingAttribute,
  PBorrowingAttribute2,
  PBorrowingAttribute3,
  PBorrowingAttribute4,
  StepSynthesisForm,
} from 'types';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  zeroPad,
} from 'utils';
import { dayjs } from 'libs/dayjs';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { isEditingSelector } from 'pages/StepSynthesisInformation/selectors';

type RadioItems = {
  value: string;
  label: string;
};

export const MCJBorrowing = () => {
  const { values, setFieldValue } = useFormikContext<StepSynthesisForm>();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const isEditing = useSelector(isEditingSelector);

  useEffect(() => {
    if (
      !values.p_application_header.completely_repayment_type.includes(
        FundsPairOffType.OTHERS
      )
    ) {
      setFieldValue('p_application_header.other_complete_repayment_type', '');
    }
  }, [values.p_application_header.completely_repayment_type, setFieldValue]);

  const checkField = (index: number) => {
    if (
      (
        values.main_p_applicant_person.p_borrowings_attributes[
          index
        ] as PBorrowingAttribute
      )?.scheduled_loan_payoff ===
      ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID
    ) {
      setFieldValue('p_application_header.completely_repayment_type', '');
      setFieldValue('p_application_header.refund_content', '');
      setFieldValue('p_application_header.refund_amount', '');
    }
  };

  const checkOther = (index: number) => {
    if (
      (
        values.main_p_applicant_person.p_borrowings_attributes[
          index
        ] as PBorrowingAttribute
      ).loan_purpose === LoanPurposeType.OTHERS
    ) {
      setFieldValue(
        `main_p_applicant_person.p_borrowings_attributes[${index}].other_purpose`,
        ''
      );
    }
  };

  const fundsPairOffOptions = useMemo(
    () =>
      convertDynamicMultipleOptions(dynamicOptions.completely_repayment_type),
    [dynamicOptions.completely_repayment_type]
  );

  const loanPurposeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.loan_purpose),
    [dynamicOptions.loan_purpose]
  );

  const loanPurposeMonthlyPaymentOptions = loanPurposeOptions.filter(
    (opt) =>
      opt.value !== LoanPurposeType.CARD_LOAN &&
      opt.value !== LoanPurposeType.HOME_APPLIANCE_PURCHASE
  );

  const loanPurposeCardLoanOptions = loanPurposeOptions.filter(
    (opt) =>
      opt.value === LoanPurposeType.LOAN_FOR_LIVING_EXPENSES ||
      opt.value === LoanPurposeType.HOME_APPLIANCE_PURCHASE ||
      opt.value === LoanPurposeType.OTHERS
  );

  const borrowerOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.borrower).filter(
        (opt) => opt.value !== BorrowerType.INCOME_ADDER
      ),
    [dynamicOptions.borrower]
  );

  const borrowerMoneyOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.borrower).filter(
        (opt) =>
          opt.value !==
          BorrowerType.PAIR_LOAN_APPLICANT_PROSPECTIVE_JOINT_GUARANTOR
      ),
    [dynamicOptions.borrower]
  );

  const scheduledLoanPayoffOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.scheduled_loan_payoff),
    [dynamicOptions.scheduled_loan_payoff]
  );

  const scheduledLoanPayoffMonthlyPaymentOptions =
    scheduledLoanPayoffOptions.filter(
      (opt) =>
        opt.value !== ScheduledLoanPayoffType.NO_PLAN_TO_REPAY_RENTED &&
        opt.value !== ScheduledLoanPayoffType.NO_PLAN_TO_REPAY_PLAN
    );

  const otherThanHousingLoanOptions = scheduledLoanPayoffOptions.filter(
    (opt) =>
      opt.value !== ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID &&
      opt.value !== ScheduledLoanPayoffType.NO_PLAN_TO_REPAY_RENTED &&
      opt.value !== ScheduledLoanPayoffType.NO_PLAN_TO_REPAY_PLAN
  );

  const scheduledLoanPayoffRentedHouseOptions =
    scheduledLoanPayoffOptions.filter(
      (opt) =>
        opt.value !== ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID &&
        opt.value !== ScheduledLoanPayoffType.PAID_OFF
    );

  const businessBorrowingOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.business_borrowing_type),
    [dynamicOptions.business_borrowing_type]
  );

  const repaymentOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.repayment_borrowing_method),
    [dynamicOptions.repayment_borrowing_method]
  );

  return (
    <Stack>
      <Divider
        sx={{
          width: '100%',
          height: 2,
          backgroundImage: (theme) =>
            `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
          backgroundSize: '6px 9px',
          backgroundRepeat: 'repeat-x',
          borderBottomWidth: 'inherit',
        }}
      />

      {/*Whether you have a loan with monthly payments*/}

      <FieldArray
        name="main_p_applicant_person.p_borrowings_attributes"
        render={(arrayHelpers) => (
          <Stack>
            <FormItem
              required
              divider
              label="毎月お支払いがあるお借入の有無"
              note={notes.has_borrowings}
            >
              <RadioGroupButton
                itemPerRow={3}
                type="noImage"
                name="main_p_applicant_person.monthly_payment"
                options={loanBalanceOptions}
                onChange={(checked, value) => {
                  if (value === LoanBalanceType.NO) {
                    const monthlyPaymentBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes
                        .filter(
                          (borrowing) =>
                            borrowing.borrowing_type ===
                              BorrowingType.MONTHLY_PAYMENT && !!borrowing.id
                        )
                        .map((borrowing) => ({ ...borrowing, _destroy: true }));
                    const otherBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes.filter(
                        (borrowing) => {
                          return (
                            borrowing.borrowing_type !==
                            BorrowingType.MONTHLY_PAYMENT
                          );
                        }
                      );
                    if (isEditing) {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        [...monthlyPaymentBorrowings, ...otherBorrowings]
                      );
                    } else {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        otherBorrowings
                      );
                    }
                  } else {
                    arrayHelpers.push({
                      borrowing_type: BorrowingType.MONTHLY_PAYMENT,
                      lender: '',
                      borrower: BorrowerType.DEFAULT,
                      loan_amount: '',
                      current_balance_amount: '',
                      annual_repayment_amount: '',
                      loan_purpose: LoanPurposeType.DEFAULT,
                      other_purpose: '',
                      guaranteed_status:
                        ScheduledCompletionOfBorrowingType.DEFAULT,
                      loan_start_date: '',
                      loan_deadline_date: '',
                      scheduled_loan_payoff: ScheduledLoanPayoffType.DEFAULT,
                      expected_repayment_date: '',
                      borrowing_from_housing_finance_agency:
                        BorrowingFromHousingFinanceAgencyType.DEFAULT,
                      has_next_same_borrowing: false,
                    });
                  }
                }}
              />
            </FormItem>

            {values.main_p_applicant_person.p_borrowings_attributes.map(
              (prepaidBorrowing, index) => {
                if (
                  prepaidBorrowing.borrowing_type !==
                    BorrowingType.MONTHLY_PAYMENT ||
                  prepaidBorrowing._destroy
                )
                  return null;
                const filters =
                  values.main_p_applicant_person.p_borrowings_attributes.filter(
                    (e, i) =>
                      i <= index &&
                      e.borrowing_type === BorrowingType.MONTHLY_PAYMENT &&
                      !e._destroy
                  );
                return (
                  <Stack key={index}>
                    <Stack
                      bgcolor="pale_blue"
                      direction="row"
                      sx={{
                        mt: 15,
                        py: 5,
                        pl: { mobile: 0, tablet: 5 },
                        borderRadius: '5px',
                      }}
                    >
                      <Typography
                        variant="textButtonTopPage"
                        color="normal_text"
                      >
                        「{filters.length}
                        件目」の借入についての入力項目です。
                      </Typography>
                    </Stack>

                    <FormItem label="資金使途（目的）" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_purpose`}
                        options={loanPurposeMonthlyPaymentOptions}
                        onChange={() => checkOther(index)}
                      />
                    </FormItem>
                    {(
                      values.main_p_applicant_person.p_borrowings_attributes[
                        index
                      ] as PBorrowingAttribute
                    ).loan_purpose === LoanPurposeType.OTHERS && (
                      <FormItem
                        divider
                        label="資金使途（その他）を選択した場合は具体的内容を記入してください"
                      >
                        <InputField
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].other_purpose`}
                          placeholder="ー"
                        />
                      </FormItem>
                    )}
                    <FormItem divider label="借入先（金融機関名）">
                      <InputField
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].lender`}
                        placeholder="◯◯銀行"
                      />
                    </FormItem>
                    <FormItem label="借入名義人" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].borrower`}
                        options={borrowerOptions}
                      />
                    </FormItem>
                    <FormItem label="当初借入額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>
                    <FormItem label="現在の残高" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].current_balance_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem
                      required
                      divider
                      label="住宅金融支援機構からの借入"
                    >
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].borrowing_from_housing_finance_agency`}
                        options={borrowingFromHousingFinanceAgencyOptions}
                      />
                    </FormItem>

                    <FormItem label="年間返済額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].annual_repayment_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem label="当初借入年月" divider>
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_start_date`}
                            isSelect
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    <FormItem label="最終期限" divider>
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_deadline_date`}
                            isSelect
                            yearOptions={yearOptions}
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    <FormItem required label="担保" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].guaranteed_status`}
                        options={scheduledCompletionOfBorrowingOptions}
                      />
                    </FormItem>

                    <FormItem
                      required
                      label="契約手続き前までの完済（予定）有無"
                      divider
                    >
                      <Stack spacing={9}>
                        {(
                          values.main_p_applicant_person
                            .p_borrowings_attributes[
                            index
                          ] as PBorrowingAttribute
                        ).loan_purpose === LoanPurposeType.DEFAULT && (
                          <Stack>
                            <RadioGroupButton
                              itemPerRow={3}
                              type="noImage"
                              name={`main_p_applicant_person.p_borrowings_attributes[${index}].scheduled_loan_payoff`}
                              options={otherThanHousingLoanOptions}
                              onChange={() => checkField(index)}
                            />
                          </Stack>
                        )}
                        {(
                          values.main_p_applicant_person
                            .p_borrowings_attributes[
                            index
                          ] as PBorrowingAttribute
                        ).loan_purpose === LoanPurposeType.HOUSING_LOAN && (
                          <Stack spacing={5}>
                            <Typography
                              variant="text_placeholder_QR"
                              color="normal_text"
                            >
                              住宅ローンの場合
                            </Typography>
                            <RadioGroupButton
                              itemPerRow={3}
                              type="noImage"
                              name={`main_p_applicant_person.p_borrowings_attributes[${index}].scheduled_loan_payoff`}
                              options={scheduledLoanPayoffMonthlyPaymentOptions}
                              onChange={() => checkField(index)}
                            />
                          </Stack>
                        )}
                        {(
                          values.main_p_applicant_person
                            .p_borrowings_attributes[
                            index
                          ] as PBorrowingAttribute
                        ).loan_purpose !== LoanPurposeType.HOUSING_LOAN &&
                          (
                            values.main_p_applicant_person
                              .p_borrowings_attributes[
                              index
                            ] as PBorrowingAttribute
                          ).loan_purpose !== LoanPurposeType.DEFAULT && (
                            <Stack spacing={5}>
                              <Typography
                                variant="text_placeholder_QR"
                                color="normal_text"
                              >
                                住宅ローン以外の場合
                              </Typography>
                              <RadioGroupButton
                                itemPerRow={3}
                                type="noImage"
                                name={`main_p_applicant_person.p_borrowings_attributes[${index}].scheduled_loan_payoff`}
                                options={otherThanHousingLoanOptions}
                                onChange={() => checkField(index)}
                              />
                            </Stack>
                          )}
                      </Stack>
                    </FormItem>

                    <FormItem required divider label="完済（予定）年月">
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].expected_repayment_date`}
                            isSelect
                            checkRequired
                            yearOptions={futureYearOptions}
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    {filters.length < 3 && (
                      <FormItem label="お借入情報の追加" divider>
                        <Grid container>
                          <Grid item {...{ mobile: 12, tablet: 6, md: 5 }}>
                            <Stack sx={{ maxWidth: 376 }}>
                              <CheckboxButton
                                name={`main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`}
                                children="もう１件追加する"
                                onClick={() => {
                                  if (
                                    prepaidBorrowing.has_next_same_borrowing
                                  ) {
                                    setFieldValue(
                                      `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                      false
                                    );
                                    for (
                                      let i =
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes.length - 1;
                                      i > index;
                                      i--
                                    ) {
                                      if (
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes[i]
                                          .borrowing_type ===
                                        BorrowingType.MONTHLY_PAYMENT
                                      ) {
                                        if (
                                          isEditing &&
                                          !!values.main_p_applicant_person
                                            .p_borrowings_attributes[i].id
                                        ) {
                                          setFieldValue(
                                            `main_p_applicant_person.p_borrowings_attributes[${i}]._destroy`,
                                            true
                                          );
                                        } else {
                                          arrayHelpers.remove(i);
                                        }
                                      }
                                    }
                                    return;
                                  }
                                  setFieldValue(
                                    `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                    true
                                  );
                                  arrayHelpers.push({
                                    borrowing_type:
                                      BorrowingType.MONTHLY_PAYMENT,
                                    lender: '',
                                    borrower: BorrowerType.DEFAULT,
                                    loan_amount: '',
                                    current_balance_amount: '',
                                    annual_repayment_amount: '',
                                    loan_purpose: LoanPurposeType.DEFAULT,
                                    other_purpose: '',
                                    guaranteed_status:
                                      ScheduledCompletionOfBorrowingType.DEFAULT,
                                    loan_start_date: '',
                                    loan_deadline_date: '',
                                    scheduled_loan_payoff:
                                      ScheduledLoanPayoffType.DEFAULT,
                                    expected_repayment_date: '',
                                    has_next_same_borrowing: false,
                                    borrowing_from_housing_finance_agency:
                                      BorrowingFromHousingFinanceAgencyType.DEFAULT,
                                  });
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </FormItem>
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        )}
      />

      {/*Borrowing by credit card or card loan*/}

      <FieldArray
        name="main_p_applicant_person.p_borrowings_attributes"
        render={(arrayHelpers) => (
          <Stack>
            <FormItem
              divider
              required
              label="クレジットカード・カードローンによるお借入の有無"
            >
              <RadioGroupButton
                itemPerRow={3}
                type="noImage"
                name="main_p_applicant_person.card_loan"
                options={loanBalanceOptions}
                onChange={(checked, value) => {
                  if (value === LoanBalanceType.NO) {
                    const cardLoanBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes
                        .filter(
                          (borrowing) =>
                            borrowing.borrowing_type ===
                              BorrowingType.CARD_LOAN && !!borrowing.id
                        )
                        .map((borrowing) => ({ ...borrowing, _destroy: true }));
                    const otherBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes.filter(
                        (borrowing) =>
                          borrowing.borrowing_type !== BorrowingType.CARD_LOAN
                      );
                    if (isEditing) {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        [...cardLoanBorrowings, ...otherBorrowings]
                      );
                    } else {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        otherBorrowings
                      );
                    }
                  } else {
                    arrayHelpers.push({
                      borrowing_type: BorrowingType.CARD_LOAN,
                      lender: '',
                      borrower: BorrowerType.DEFAULT,
                      borrowing_category: BorrowingCategoryType.DEFAULT,
                      loan_amount: '',
                      current_balance_amount: '',
                      loan_purpose: '',
                      other_purpose: '',
                      loan_start_date: '',
                      scheduled_loan_payoff: ScheduledLoanPayoffType.DEFAULT,
                      expected_repayment_date: '',
                      has_next_same_borrowing: false,
                    });
                  }
                }}
              />
            </FormItem>

            {values.main_p_applicant_person.p_borrowings_attributes.map(
              (prepaidBorrowing, index) => {
                if (
                  (prepaidBorrowing as PBorrowingAttribute2).borrowing_type !==
                    BorrowingType.CARD_LOAN ||
                  prepaidBorrowing._destroy
                )
                  return null;
                const filters =
                  values.main_p_applicant_person.p_borrowings_attributes.filter(
                    (e, i) =>
                      i <= index &&
                      (e as PBorrowingAttribute2).borrowing_type ===
                        BorrowingType.CARD_LOAN &&
                      !e._destroy
                  );
                return (
                  <Stack key={index}>
                    <Stack
                      bgcolor="pale_blue"
                      direction="row"
                      sx={{
                        mt: 15,
                        py: 5,
                        pl: { mobile: 0, tablet: 5 },
                        borderRadius: '5px',
                      }}
                    >
                      <Typography
                        variant="textButtonTopPage"
                        color="normal_text"
                      >
                        「{filters.length}
                        件目」の借入についての入力項目です。
                      </Typography>
                    </Stack>

                    <FormItem divider label="借入先（金融機関名）">
                      <InputField
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].lender`}
                        placeholder="例：ABCファイナンス"
                      />
                    </FormItem>

                    <FormItem label="借入名義人" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].borrower`}
                        options={borrowerOptions}
                      />
                    </FormItem>

                    <FormItem divider label="借入区分">
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].borrowing_category`}
                        options={borrowingCategoryOptions}
                      />
                    </FormItem>

                    <FormItem label="資金使途（目的）" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_purpose`}
                        options={loanPurposeCardLoanOptions}
                        onChange={() => checkOther(index)}
                      />
                    </FormItem>
                    {(
                      values.main_p_applicant_person.p_borrowings_attributes[
                        index
                      ] as PBorrowingAttribute
                    ).loan_purpose === LoanPurposeType.OTHERS && (
                      <FormItem
                        divider
                        label="資金使途（その他）を選択した場合は具体的内容を記入してください"
                      >
                        <InputField
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].other_purpose`}
                          placeholder="ー"
                        />
                      </FormItem>
                    )}

                    <FormItem label="カード契約年月日" divider>
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_start_date`}
                            isSelect
                            selectWidth={{ tablet: 100 }}
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    <FormItem label="借入限度額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem label="現在の残高" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].current_balance_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem label="年間返済額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].annual_repayment_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem
                      required
                      label="契約手続き前までの完済（予定）有無"
                      divider
                    >
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].scheduled_loan_payoff`}
                        options={otherThanHousingLoanOptions}
                        onChange={() => checkField(index)}
                      />
                    </FormItem>

                    <FormItem required divider label="完済（予定）年月">
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].expected_repayment_date`}
                            isSelect
                            checkRequired
                            yearOptions={futureYearOptions}
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    {filters.length < 3 && (
                      <FormItem label="お借入情報の追加" divider>
                        <Grid container>
                          <Grid item {...{ mobile: 12, tablet: 6, md: 5 }}>
                            <Stack sx={{ maxWidth: 376 }}>
                              <CheckboxButton
                                name={`main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`}
                                children="もう１件追加する"
                                onClick={() => {
                                  if (
                                    prepaidBorrowing.has_next_same_borrowing
                                  ) {
                                    setFieldValue(
                                      `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                      false
                                    );
                                    for (
                                      let i =
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes.length - 1;
                                      i > index;
                                      i--
                                    ) {
                                      if (
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes[i]
                                          .borrowing_type ===
                                        BorrowingType.CARD_LOAN
                                      ) {
                                        if (
                                          isEditing &&
                                          !!values.main_p_applicant_person
                                            .p_borrowings_attributes[i].id
                                        ) {
                                          setFieldValue(
                                            `main_p_applicant_person.p_borrowings_attributes[${i}]._destroy`,
                                            true
                                          );
                                        } else {
                                          arrayHelpers.remove(i);
                                        }
                                      }
                                    }
                                    return;
                                  }
                                  setFieldValue(
                                    `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                    true
                                  );
                                  arrayHelpers.push({
                                    borrowing_type: BorrowingType.CARD_LOAN,
                                    lender: '',
                                    borrower: BorrowerType.DEFAULT,
                                    borrowing_category:
                                      BorrowingCategoryType.DEFAULT,
                                    loan_amount: '',
                                    current_balance_amount: '',
                                    loan_purpose: '',
                                    other_purpose: '',
                                    loan_start_date: '',
                                    scheduled_loan_payoff:
                                      ScheduledLoanPayoffType.DEFAULT,
                                    expected_repayment_date: '',
                                    has_next_same_borrowing: false,
                                  });
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </FormItem>
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        )}
      />

      {/*Whether you have borrowed money for housing that is currently being rented or is scheduled to be rented*/}

      <FieldArray
        name="main_p_applicant_person.p_borrowings_attributes"
        render={(arrayHelpers) => (
          <Stack>
            <FormItem
              divider
              required
              label="賃貸中または賃貸予定の住宅に関するお借入の有無"
            >
              <RadioGroupButton
                itemPerRow={3}
                type="noImage"
                name="main_p_applicant_person.rented_house"
                options={loanBalanceOptions}
                onChange={(checked, value) => {
                  if (value === LoanBalanceType.NO) {
                    const rentedHouseBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes
                        .filter(
                          (borrowing) =>
                            borrowing.borrowing_type ===
                              BorrowingType.RENTED_HOUSE && !!borrowing.id
                        )
                        .map((borrowing) => ({ ...borrowing, _destroy: true }));
                    const otherBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes.filter(
                        (borrowing) =>
                          borrowing.borrowing_type !==
                          BorrowingType.RENTED_HOUSE
                      );
                    if (isEditing) {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        [...rentedHouseBorrowings, ...otherBorrowings]
                      );
                    } else {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        otherBorrowings
                      );
                    }
                  } else {
                    arrayHelpers.push({
                      borrowing_type: BorrowingType.RENTED_HOUSE,
                      lender: '',
                      borrower: '',
                      loan_start_date: '',
                      loan_amount: '',
                      current_balance_amount: '',
                      annual_repayment_amount: '',
                      loan_deadline_date: '',
                      rental_room_number: '',
                      common_housing: CommonHousingType.DEFAULT,
                      estate_mortgage: EstateMortgageType.DEFAULT,
                      scheduled_loan_payoff: ScheduledLoanPayoffType.DEFAULT,
                      expected_repayment_date: '',
                      has_next_same_borrowing: false,
                    });
                  }
                }}
              />
            </FormItem>

            {values.main_p_applicant_person.p_borrowings_attributes.map(
              (prepaidBorrowing, index) => {
                if (
                  (prepaidBorrowing as PBorrowingAttribute3).borrowing_type !==
                    BorrowingType.RENTED_HOUSE ||
                  prepaidBorrowing._destroy
                )
                  return null;
                const filters =
                  values.main_p_applicant_person.p_borrowings_attributes.filter(
                    (e, i) =>
                      i <= index &&
                      (e as PBorrowingAttribute3).borrowing_type ===
                        BorrowingType.RENTED_HOUSE &&
                      !e._destroy
                  );
                return (
                  <Stack key={index}>
                    <Stack
                      bgcolor="pale_blue"
                      direction="row"
                      sx={{
                        mt: 15,
                        py: 5,
                        pl: { mobile: 0, tablet: 5 },
                        borderRadius: '5px',
                      }}
                    >
                      <Typography
                        variant="textButtonTopPage"
                        color="normal_text"
                      >
                        「{filters.length}
                        件目」の借入についての入力項目です。
                      </Typography>
                    </Stack>

                    <FormItem divider label="借入先（金融機関名）">
                      <InputField
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].lender`}
                        placeholder="例：ABC銀行"
                      />
                    </FormItem>

                    <FormItem label="借入名義人" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].borrower`}
                        options={borrowerMoneyOptions}
                      />
                    </FormItem>

                    <FormItem label="当初借入日" divider>
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_start_date`}
                            isSelect
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    <FormItem label="当初借入金額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem label="現在の残高" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].current_balance_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem label="年間返済額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].annual_repayment_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem label="最終返済年月" divider>
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_deadline_date`}
                            isSelect
                            yearOptions={yearOptions}
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    <FormItem label="賃貸戸（室）数" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].rental_room_number`}
                          align="right"
                          placeholder="0"
                          unit="戸（室）"
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem required label="共同住宅" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].common_housing`}
                        options={commonHousingOptions}
                      />
                    </FormItem>

                    <FormItem required label="不動産担保設定" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].estate_mortgage`}
                        options={estateMortgageOptions}
                      />
                    </FormItem>

                    <FormItem
                      required
                      label="契約手続き前までの完済（予定）有無"
                      divider
                    >
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].scheduled_loan_payoff`}
                        options={scheduledLoanPayoffRentedHouseOptions}
                        onChange={() => checkField(index)}
                      />
                    </FormItem>

                    <FormItem required divider label="完済（予定）年月">
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].expected_repayment_date`}
                            isSelect
                            checkRequired
                            yearOptions={futureYearOptions}
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    {filters.length < 3 && (
                      <FormItem label="お借入情報の追加" divider>
                        <Grid container>
                          <Grid item {...{ mobile: 12, tablet: 6, md: 5 }}>
                            <Stack sx={{ maxWidth: 376 }}>
                              <CheckboxButton
                                name={`main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`}
                                children="もう１件追加する"
                                onClick={() => {
                                  if (
                                    prepaidBorrowing.has_next_same_borrowing
                                  ) {
                                    setFieldValue(
                                      `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                      false
                                    );
                                    for (
                                      let i =
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes.length - 1;
                                      i > index;
                                      i--
                                    ) {
                                      if (
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes[i]
                                          .borrowing_type ===
                                        BorrowingType.RENTED_HOUSE
                                      ) {
                                        if (
                                          isEditing &&
                                          !!values.main_p_applicant_person
                                            .p_borrowings_attributes[i].id
                                        ) {
                                          setFieldValue(
                                            `main_p_applicant_person.p_borrowings_attributes[${i}]._destroy`,
                                            true
                                          );
                                        } else {
                                          arrayHelpers.remove(i);
                                        }
                                      }
                                    }
                                    return;
                                  }
                                  setFieldValue(
                                    `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                    true
                                  );
                                  arrayHelpers.push({
                                    borrowing_type: BorrowingType.RENTED_HOUSE,
                                    lender: '',
                                    borrower: '',
                                    loan_start_date: '',
                                    loan_amount: '',
                                    current_balance_amount: '',
                                    annual_repayment_amount: '',
                                    loan_deadline_date: '',
                                    rental_room_number: '',
                                    common_housing: CommonHousingType.DEFAULT,
                                    estate_mortgage: EstateMortgageType.DEFAULT,
                                    scheduled_loan_payoff:
                                      ScheduledLoanPayoffType.DEFAULT,
                                    expected_repayment_date: '',
                                    has_next_same_borrowing: false,
                                  });
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </FormItem>
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        )}
      />

      {/*Existence of borrowing for business*/}

      <FieldArray
        name="main_p_applicant_person.p_borrowings_attributes"
        render={(arrayHelpers) => (
          <Stack>
            <FormItem required label="事業用のお借入の有無">
              <RadioGroupButton
                itemPerRow={3}
                type="noImage"
                name="main_p_applicant_person.business_loan"
                options={loanBalanceOptions}
                onChange={(checked, value) => {
                  if (value === LoanBalanceType.NO) {
                    const businessLoanBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes
                        .filter(
                          (borrowing) =>
                            borrowing.borrowing_type ===
                              BorrowingType.BUSINESS_LOAN && !!borrowing.id
                        )
                        .map((borrowing) => ({ ...borrowing, _destroy: true }));
                    const otherBorrowings =
                      values.main_p_applicant_person.p_borrowings_attributes.filter(
                        (borrowing) =>
                          borrowing.borrowing_type !==
                          BorrowingType.BUSINESS_LOAN
                      );
                    if (isEditing) {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        [...businessLoanBorrowings, ...otherBorrowings]
                      );
                    } else {
                      setFieldValue(
                        'main_p_applicant_person.p_borrowings_attributes',
                        otherBorrowings
                      );
                    }
                  } else {
                    arrayHelpers.push({
                      borrowing_type: BorrowingType.BUSINESS_LOAN,
                      lender: '',
                      borrower: BorrowerType.DEFAULT,
                      business_borrowing_type: BusinessBorrowingType.DEFAULT,
                      specific_loan_purpose: '',
                      loan_start_date: '',
                      loan_amount: '',
                      current_balance_amount: '',
                      annual_repayment_amount: '',
                      estate_mortgage: EstateMortgageType.DEFAULT,
                      repayment_borrowing_method:
                        RepaymentBorrowingType.DEFAULT,
                      installment_repayment: '',
                      has_next_same_borrowing: false,
                    });
                  }
                }}
              />
            </FormItem>

            {values.main_p_applicant_person.p_borrowings_attributes.map(
              (prepaidBorrowing, index) => {
                if (
                  (prepaidBorrowing as PBorrowingAttribute4).borrowing_type !==
                    BorrowingType.BUSINESS_LOAN ||
                  prepaidBorrowing._destroy
                )
                  return null;
                const filters =
                  values.main_p_applicant_person.p_borrowings_attributes.filter(
                    (e, i) =>
                      i <= index &&
                      (e as PBorrowingAttribute4).borrowing_type ===
                        BorrowingType.BUSINESS_LOAN &&
                      !e._destroy
                  );
                return (
                  <Stack key={index}>
                    <Stack
                      bgcolor="pale_blue"
                      direction="row"
                      sx={{
                        mt: 15,
                        py: 5,
                        pl: { mobile: 0, tablet: 5 },
                        borderRadius: '5px',
                      }}
                    >
                      <Typography
                        variant="textButtonTopPage"
                        color="normal_text"
                      >
                        「{filters.length}
                        件目」の借入についての入力項目です。
                      </Typography>
                    </Stack>
                    <FormItem divider label="借入先（金融機関名）">
                      <InputField
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].lender`}
                        placeholder="例：ABC銀行"
                      />
                    </FormItem>
                    <FormItem label="借入名義人" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].borrower`}
                        options={borrowerMoneyOptions}
                      />
                    </FormItem>

                    <FormItem label="借入金種類" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].business_borrowing_type`}
                        options={businessBorrowingOptions}
                      />
                    </FormItem>

                    <FormItem divider label="借入金用途（具体的に）">
                      <InputField
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].specific_loan_purpose`}
                        placeholder="例：◯◯の機材購入"
                      />
                    </FormItem>

                    <FormItem label="当初借入日" divider>
                      <Grid container>
                        <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                          <InputDate
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_start_date`}
                            isSelect
                          />
                        </Grid>
                      </Grid>
                    </FormItem>

                    <FormItem label="当初借入金額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>
                    <FormItem label="現在の残高" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].current_balance_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>
                    <FormItem label="年間返済額" divider>
                      <Stack direction="row">
                        <InputField
                          sx={{ width: 200 }}
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].annual_repayment_amount`}
                          align="right"
                          placeholder="0"
                          unit="万円"
                          numeric
                          useNumberFormat
                        />
                      </Stack>
                    </FormItem>

                    <FormItem required label="不動産担保設定" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].estate_mortgage`}
                        options={estateMortgageOptions}
                      />
                    </FormItem>

                    <FormItem required label="返済方法" divider>
                      <RadioGroupButton
                        itemPerRow={3}
                        type="noImage"
                        name={`main_p_applicant_person.p_borrowings_attributes[${index}].repayment_borrowing_method`}
                        options={repaymentOptions}
                      />
                      {(
                        values.main_p_applicant_person.p_borrowings_attributes[
                          index
                        ] as PBorrowingAttribute4
                      ).repayment_borrowing_method ===
                        RepaymentBorrowingType.INSTALLMENT_REPAYMENT && (
                        <Stack direction="row" sx={{ mt: 9 }}>
                          <InputField
                            sx={{ width: { mobile: '100%', tablet: 200 } }}
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].installment_repayment`}
                            align="left"
                            placeholder="0"
                            unit="円"
                            useNumberFormat
                          />
                        </Stack>
                      )}
                    </FormItem>
                    {filters.length < 3 && (
                      <FormItem label="お借入情報の追加" divider>
                        <Grid container>
                          <Grid item {...{ mobile: 12, tablet: 6, md: 5 }}>
                            <Stack sx={{ maxWidth: 376 }}>
                              <CheckboxButton
                                name={`main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`}
                                children="もう１件追加する"
                                onClick={() => {
                                  if (
                                    prepaidBorrowing.has_next_same_borrowing
                                  ) {
                                    setFieldValue(
                                      `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                      false
                                    );
                                    for (
                                      let i =
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes.length - 1;
                                      i > index;
                                      i--
                                    ) {
                                      if (
                                        values.main_p_applicant_person
                                          .p_borrowings_attributes[i]
                                          .borrowing_type ===
                                        BorrowingType.BUSINESS_LOAN
                                      ) {
                                        if (
                                          isEditing &&
                                          !!values.main_p_applicant_person
                                            .p_borrowings_attributes[i].id
                                        ) {
                                          setFieldValue(
                                            `main_p_applicant_person.p_borrowings_attributes[${i}]._destroy`,
                                            true
                                          );
                                        } else {
                                          arrayHelpers.remove(i);
                                        }
                                      }
                                    }
                                    return;
                                  }
                                  setFieldValue(
                                    `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                    true
                                  );
                                  arrayHelpers.push({
                                    borrowing_type: BorrowingType.BUSINESS_LOAN,
                                    lender: '',
                                    borrower: BorrowerType.DEFAULT,
                                    business_borrowing_type:
                                      BusinessBorrowingType.DEFAULT,
                                    specific_loan_purpose: '',
                                    loan_start_date: '',
                                    loan_amount: '',
                                    current_balance_amount: '',
                                    annual_repayment_amount: '',
                                    estate_mortgage: EstateMortgageType.DEFAULT,
                                    repayment_borrowing_method:
                                      RepaymentBorrowingType.DEFAULT,
                                    installment_repayment: '',
                                    has_next_same_borrowing: false,
                                  });
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </FormItem>
                    )}
                  </Stack>
                );
              }
            )}
          </Stack>
        )}
      />

      {values.main_p_applicant_person.p_borrowings_attributes.filter(
        (e) =>
          (e as PBorrowingAttribute).scheduled_loan_payoff ===
            ScheduledLoanPayoffType.SCHEDULED_TO_BE_FULLY_REPAID ||
          (e as PBorrowingAttribute).scheduled_loan_payoff ===
            ScheduledLoanPayoffType.SCHEDULED_TO_BE_PAID_OFF
      ).length > 0 && (
        <Stack>
          <FormItem required divider label="完済原資（複数選択可）">
            <CheckboxMultipleButton
              sx={{
                width: '100%',
                justifyContent: 'center',
              }}
              itemPerRow={3}
              name="p_application_header.completely_repayment_type"
              options={fundsPairOffOptions}
            />
          </FormItem>
          {values.p_application_header.completely_repayment_type.includes(
            FundsPairOffType.OTHERS
          ) && (
            <FormItem
              required
              divider
              label="完済原資（その他）を選択した場合は具体的な内容を記入してください"
            >
              <InputField
                sx={{ width: '100%' }}
                name="p_application_header.other_complete_repayment_type"
                placeholder="例：保有株式売却"
              />
            </FormItem>
          )}
          <FormItem
            required
            divider
            label="完済原資の内容（金融機関・貯金種類など）"
          >
            <InputField
              sx={{ width: '100%' }}
              name="p_application_header.refund_content"
              placeholder="例：ABC銀行定期預金500万円、保有株式売却200万円"
            />
          </FormItem>
          <FormItem required divider label="完済原資の金額合計">
            <Stack direction="row">
              <InputField
                sx={{
                  width: { mobile: '100%', tablet: 250 },
                }}
                name="p_application_header.refund_amount"
                align="right"
                placeholder="0"
                unit="万円"
                numeric
                useNumberFormat
              />
            </Stack>
          </FormItem>
        </Stack>
      )}
    </Stack>
  );
};

const notes = {
  has_borrowings:
    '例：車のローン・教育ローン・奨学金・生活費補填ローン・住宅ローン・リフォームローンなど',
  loanAmount: [
    '車のローン、奨学金などの他、カードローン・キャッシングもご記入ください。',
    '仮審査申込日から遡って3ヵ月以内に完済した借入もご記入ください。',
    '現在ご利用中のローンの返済予定表・利用明細等をアップロードいただければ以下の項目は入力不要です。',
  ],
};

const loanBalanceOptions: Array<RadioItems> = [
  {
    value: LoanBalanceType.YES,
    label: loanbalance[LoanBalanceType.YES],
  },
  {
    value: LoanBalanceType.NO,
    label: loanbalance[LoanBalanceType.NO],
  },
];

const borrowingCategoryOptions: Array<RadioItems> = [
  {
    value: BorrowingCategoryType.SHOPPING,
    label: borrowingCategory[BorrowingCategoryType.SHOPPING],
  },
  {
    value: BorrowingCategoryType.CASH_CACHING,
    label: borrowingCategory[BorrowingCategoryType.CASH_CACHING],
  },
  {
    value: BorrowingCategoryType.CARD_LOAN,
    label: borrowingCategory[BorrowingCategoryType.CARD_LOAN],
  },
];

const borrowingFromHousingFinanceAgencyOptions: Array<RadioItems> = [
  {
    value: BorrowingFromHousingFinanceAgencyType.APPLICABLE,
    label:
      borrowingFromHousingFinanceAgency[
        BorrowingFromHousingFinanceAgencyType.APPLICABLE
      ],
  },
  {
    value: BorrowingFromHousingFinanceAgencyType.NOT_APPLICABLE,
    label:
      borrowingFromHousingFinanceAgency[
        BorrowingFromHousingFinanceAgencyType.NOT_APPLICABLE
      ],
  },
];

const scheduledCompletionOfBorrowingOptions: Array<RadioItems> = [
  {
    value: ScheduledCompletionOfBorrowingType.YES,
    label:
      scheduledCompletionOfBorrowing[ScheduledCompletionOfBorrowingType.YES],
  },
  {
    value: ScheduledCompletionOfBorrowingType.NOTHING,
    label:
      scheduledCompletionOfBorrowing[
        ScheduledCompletionOfBorrowingType.NOTHING
      ],
  },
];

const commonHousingOptions: Array<RadioItems> = [
  {
    value: CommonHousingType.APPLICABLE,
    label: commonHousing[CommonHousingType.APPLICABLE],
  },
  {
    value: CommonHousingType.NOT_APPLICABLE,
    label: commonHousing[CommonHousingType.NOT_APPLICABLE],
  },
];

const estateMortgageOptions: Array<RadioItems> = [
  {
    value: EstateMortgageType.SECURED,
    label: estateMortgage[EstateMortgageType.SECURED],
  },
  {
    value: EstateMortgageType.UNSECURED,
    label: estateMortgage[EstateMortgageType.UNSECURED],
  },
];

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(34), (_, index) => {
    const year = zeroPad(dayjs().year() - (34 - index));
    return {
      value: year,
      label: `${year} `,
    };
  }),
  Array.from(Array(34), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);

const futureYearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(34), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);
