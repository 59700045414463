import { Stack, Typography } from '@mui/material';
import {
  BuyingandSellingScheduleType,
  buyingandSellingScheduleType,
  currentHomeLoan,
  CurrentResidenceType,
  financeAgency,
  Flat35ApplicablePlanType,
  LoanType,
  personOccupancy,
  PersonOccupancyType,
  PlannedCohabitantType,
  PlanningAreaType,
  PurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
  SPTitle,
  TEXT_DOCUMENT_EMPTY,
} from 'constant';
import { Icons, SPButton, SPFormItem } from 'components';
import { routeNames } from 'navigations/routes';
import { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  findMultipleOptionName,
  getFileExtFromUrl,
  numberWithCommas,
} from 'utils';
import { useNavigate } from 'react-router-dom';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { ImageContext } from 'context';
import kanjidate from 'kanjidate';
import { spStepFourSelector } from 'pages/SPStepFour/selectors';
import { checkMCJ, spStepOneSelector } from 'pages/SPStepOne/selectors';
import { ImageItem } from '../index';

type SPStepFourInfoProps = {
  showEditButton?: boolean;
  reload?: number;
};

export const SPStepFourInfo: FC<SPStepFourInfoProps> = ({
  showEditButton = true,
  reload,
}) => {
  const navigate = useNavigate();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const isMCJ = useSelector(checkMCJ);

  const currentResidenceOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.current_residence),
    [dynamicOptions.current_residence]
  );

  const reasonAcquireHomeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.reason_acquire_home),
    [dynamicOptions.reason_acquire_home]
  );

  const businessAbilityOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.business_ability),
    [dynamicOptions.business_ability]
  );

  const nationalityOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nationality),
    [dynamicOptions.nationality]
  );

  const landOwnershipOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.land_ownership),
    [dynamicOptions.land_ownership]
  );

  const purchasePurposeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.purchase_purpose),
    [dynamicOptions.purchase_purpose]
  );

  const planningAreaOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.planning_area),
    [dynamicOptions.planning_area]
  );

  const rebuildingReasonOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.rebuilding_reason),
    [dynamicOptions.rebuilding_reason]
  );

  const flat35ApplicablePlanOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.flat_35_applicable_plan),
    [dynamicOptions.flat_35_applicable_plan]
  );

  const maintenanceOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.maintenance_type),
    [dynamicOptions.maintenance_type]
  );

  const regionOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.region_type),
    [dynamicOptions.region_type]
  );

  const flat35ApplicationOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.flat_35_application),
    [dynamicOptions.flat_35_application]
  );

  const { propertyInformationFile } = useContext(ImageContext);

  const checkTitle = useMemo(() => {
    const incomeTotalizer =
      spStepOneValues.p_application_header.loan_type === LoanType.THREE ||
      spStepOneValues.p_application_header.loan_type === LoanType.FOUR;
    const hasJoinGuarantor =
      spStepOneValues.p_application_header.p_applicant_people_attributes
        .has_join_guarantor === '1';

    if (incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_SEVEN_INCOME_GUARANTORS;
    }
    if (incomeTotalizer && !hasJoinGuarantor) {
      return SPTitle.STEP_SIX_INCOME;
    }
    if (!incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_FIVE_GUARANTORS;
    }
    return SPTitle.STEP_FOUR;
  }, [
    spStepOneValues.p_application_header.loan_type,
    spStepOneValues.p_application_header.p_applicant_people_attributes
      .has_join_guarantor,
  ]);

  const getDateValue = (value: string) => {
    const [year, month] = value.split('/');
    return `${year}年${+month}ヶ月`;
  };

  const getBirthdayValue = (value: string) => {
    const [year, month] = value.split('/');
    return `${year}年${+month}月`;
  };

  const plannedCohabitantField =
    spStepFourValues.p_application_header.planned_cohabitant;

  const {
    person_occupancy,
    non_resident_reason,
    other_relationship,
    collateral_prefecture,
    collateral_city,
    collateral_lot_number,
    condominium_name,
    collateral_land_area,
    collateral_total_floor_area,
    occupied_area,
    planning_area,
    other_planning_area,
    rebuilding_reason,
    other_rebuilding_reason,
    flat_35_applicable_plan,
    property_information_url,
    children_number,
    siblings_number,
    other_people_number,
    land_ownership,
    purchase_purpose,
    maintenance_type,
    flat_35_application,
    region_type,
  } = spStepFourValues.p_application_header;

  const {
    lived_length_year_num,
    lived_length_month_num,
    current_residence,
    owner_full_name,
    owner_relationship,
    buyingand_selling_schedule_type,
    other_buyingand_selling_schedule_type,
    scheduled_time_sell_house,
    expected_house_selling_price,
    current_home_loan,
    current_residence_floor_area,
    reason_acquire_home,
    other_reason_acquire_home,
  } = spStepFourValues.p_application_header.p_applicant_people_attributes;

  const {
    last_name_kanji,
    first_name_kanji,
    last_name_kana,
    first_name_kana,
    relationship_name,
    birthday,
    loan_from_japan_housing_finance_agency,
    postal_code,
    prefecture_kanji,
    city_kanji,
    district_kanji,
    other_address_kanji,
    home_phone_number,
    nationality,
  } = spStepFourValues.p_application_header.p_residents_attributes || {};

  const { loan_target } = spStepOneValues.p_application_header;

  const { planned_cohabitant, business_ability } = dynamicOptions;

  const multipleOptionArray = [
    {
      field: planned_cohabitant,
      formikField: spStepFourValues.p_application_header.planned_cohabitant,
    },
    {
      field: business_ability,
      formikField: spStepFourValues.p_application_header.business_ability,
    },
  ];

  const [plannedCohabitant, businessAbility] = multipleOptionArray.map((opt) =>
    findMultipleOptionName(opt.field, opt.formikField)
  );

  const getPlannedCohabitantValue = (key: number) => {
    switch (key) {
      case +PlannedCohabitantType.SPOUSE:
      case +PlannedCohabitantType.FATHER:
      case +PlannedCohabitantType.MOTHER:
      case +PlannedCohabitantType.FIANCE:
        return 1;
      case +PlannedCohabitantType.CHILD:
        return children_number;
      case +PlannedCohabitantType.BROTHERS_AND_SISTERS:
        return siblings_number;
      case +PlannedCohabitantType.OTHERS:
        return other_people_number;
      default:
        return;
    }
  };

  const getDateKanjialue = (value: string) => {
    const [year, month] = value.split('/');
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return `${year}（${kanjiDateG2}${kanjiDateN}）年${+month}月`;
  };

  return (
    <Stack>
      <Stack>
        <Stack
          direction="row"
          sx={{ px: 4, py: 1, bgcolor: 'sp_primary_100_main' }}
        >
          <Typography variant="sp_step_text" color="main_white" sx={{ mt: 1 }}>
            STEP
          </Typography>
          <Typography variant="sp_label_text" color="main_white">
            {checkTitle}
          </Typography>
        </Stack>
        <SPFormItem
          label="現在のお住まいの居住年数"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Stack spacing={1} direction="row">
            {!!lived_length_year_num && !!lived_length_month_num ? (
              <>
                <Typography variant="sp_value_text" color="b_333">
                  {getDateValue(
                    `${+lived_length_year_num}/${+lived_length_month_num}`
                  )}
                </Typography>
              </>
            ) : (
              'ー'
            )}
          </Stack>
        </SPFormItem>

        <SPFormItem
          label="現在のお住まいの種類"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {currentResidenceOptions.find(
            (e) => e.value === current_residence
          ) ? (
            <Typography variant="sp_value_text" color="b_333">
              {
                currentResidenceOptions.find(
                  (e) => e.value === current_residence
                )?.label
              }
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>

        {current_residence ===
        CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS ? (
          <>
            <SPFormItem
              label="所有者の氏名"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {!!owner_full_name ? (
                <Typography variant="sp_value_text" color="b_333">
                  {owner_full_name}
                </Typography>
              ) : (
                'ー'
              )}
            </SPFormItem>

            <SPFormItem
              label="続柄"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {!!owner_relationship ? (
                <Typography variant="sp_value_text" color="b_333">
                  {owner_relationship}
                </Typography>
              ) : (
                'ー'
              )}
            </SPFormItem>
          </>
        ) : (
          []
        )}

        {current_residence === CurrentResidenceType.PRIVATE_APARTMENT ? (
          <>
            <SPFormItem
              label="持ち家の処分方法"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Typography variant="sp_value_text" color="b_333">
                {buyingand_selling_schedule_type
                  ? buyingandSellingScheduleType[
                      buyingand_selling_schedule_type
                    ]
                  : 'ー'}
              </Typography>
            </SPFormItem>
            {buyingand_selling_schedule_type ===
            BuyingandSellingScheduleType.OTHERS ? (
              <SPFormItem
                label="その他の詳細"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {other_buyingand_selling_schedule_type
                    ? other_buyingand_selling_schedule_type
                    : 'ー'}
                </Typography>
              </SPFormItem>
            ) : (
              ''
            )}

            {buyingand_selling_schedule_type ===
            BuyingandSellingScheduleType.SALE ? (
              <>
                <SPFormItem
                  label="売却予定時期"
                  labelSx={{ p: '3px 16px' }}
                  childrenSx={{ p: '12px 24px 20px' }}
                  required
                >
                  <Typography variant="sp_value_text" color="b_333">
                    {scheduled_time_sell_house
                      ? getDateKanjialue(scheduled_time_sell_house)
                      : 'ー'}
                  </Typography>
                </SPFormItem>
                <SPFormItem
                  label="売却予定価格"
                  labelSx={{ p: '3px 16px' }}
                  childrenSx={{ p: '12px 24px 20px' }}
                  required
                >
                  <Typography variant="sp_value_text" color="b_333">
                    {expected_house_selling_price
                      ? `${numberWithCommas(expected_house_selling_price)}万円`
                      : 'ー'}
                  </Typography>
                </SPFormItem>
              </>
            ) : (
              ''
            )}

            <SPFormItem
              label="ローン残高"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Typography variant="sp_value_text" color="b_333">
                {current_home_loan ? currentHomeLoan[current_home_loan] : 'ー'}
              </Typography>
            </SPFormItem>
          </>
        ) : (
          ''
        )}

        {isMCJ ? (
          <SPFormItem
            label="現在のお住まいの床面積(MCJ固有項目)"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {current_residence_floor_area
                ? numberWithCommas(current_residence_floor_area)
                : 'ー'}
            </Typography>
          </SPFormItem>
        ) : (
          ''
        )}

        <SPFormItem
          label="物件情報の画像添付"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {propertyInformationFile.length === 0 && (
            <Typography
              variant="sp_value_text"
              color="bg_radio_avatar"
              textAlign="center"
            >
              {TEXT_DOCUMENT_EMPTY}
            </Typography>
          )}
          {propertyInformationFile.map((image, index) =>
            (image instanceof File ? true : !image.hiden) ? (
              <ImageItem
                key={index}
                fileName={image.name}
                src={image instanceof File ? image : image.url}
                reload={reload}
                display={image instanceof File ? true : !image.hiden}
                type={
                  image instanceof File
                    ? image.type
                    : getFileExtFromUrl(image.url)
                }
              />
            ) : (
              <Typography
                variant="sp_value_text"
                color="bg_radio_avatar"
                textAlign="center"
              >
                {TEXT_DOCUMENT_EMPTY}
              </Typography>
            )
          )}
        </SPFormItem>

        <SPFormItem
          label="物件情報が掲載されたURL"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography
            variant="sp_value_text"
            color="b_333"
            sx={{ lineBreak: 'anywhere' }}
          >
            {property_information_url ? property_information_url : 'ー'}
          </Typography>
        </SPFormItem>

        {loan_target !== PurposeType.SEVEN ? (
          <SPFormItem
            label="新しい住居を必要とする理由"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            {reasonAcquireHomeOptions.find(
              (e) => e.value === reason_acquire_home
            ) ? (
              <Typography variant="sp_value_text" color="b_333">
                {
                  reasonAcquireHomeOptions.find(
                    (e) => e.value === reason_acquire_home
                  )?.label
                }
              </Typography>
            ) : (
              <Typography variant="sp_value_text" color="b_333">
                ー
              </Typography>
            )}
          </SPFormItem>
        ) : (
          ''
        )}

        {reason_acquire_home === ReasonsForNeedingHousingType.OTHERS ? (
          <SPFormItem
            label="その他の詳細"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {other_reason_acquire_home ? other_reason_acquire_home : 'ー'}
            </Typography>
          </SPFormItem>
        ) : (
          ''
        )}

        <SPFormItem
          label="新しい住居に、あなたは居住しますか？"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography variant="sp_value_text" color="b_333">
            {person_occupancy ? personOccupancy[person_occupancy] : 'ー'}
          </Typography>
        </SPFormItem>

        {person_occupancy === PersonOccupancyType.NO ? (
          <SPFormItem
            label="「いいえ」の方は理由を入力ください"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {non_resident_reason ? non_resident_reason : 'ー'}
            </Typography>
          </SPFormItem>
        ) : (
          ''
        )}

        <SPFormItem
          label="あなた以外の入居予定者"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography
            variant="sp_value_text"
            color="b_333"
            whiteSpace="break-spaces"
          >
            {plannedCohabitant.length
              ? plannedCohabitant
                  .map((planned_cohabitant) => {
                    if (
                      planned_cohabitant.option_code !==
                        +PlannedCohabitantType.CHILD &&
                      planned_cohabitant.option_code !==
                        +PlannedCohabitantType.BROTHERS_AND_SISTERS &&
                      planned_cohabitant.option_code !==
                        +PlannedCohabitantType.OTHERS
                    ) {
                      return `${planned_cohabitant.option_name}`;
                    } else {
                      return `${
                        planned_cohabitant.option_name
                      }（${getPlannedCohabitantValue(
                        planned_cohabitant.option_code
                      )}人）`;
                    }
                  })
                  .join(',')
                  .replace(/,/g, '\n\n')
              : 'ー'}
          </Typography>
        </SPFormItem>

        {plannedCohabitantField.includes(PlannedCohabitantType.OTHERS) ? (
          <SPFormItem
            label="その他の続柄"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {other_relationship ? other_relationship : 'ー'}
            </Typography>
          </SPFormItem>
        ) : (
          ''
        )}

        {isMCJ && person_occupancy === PersonOccupancyType.NO ? (
          <>
            <SPFormItem
              label="入居予定者の氏名"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {!!last_name_kanji && !!first_name_kanji ? (
                <Typography variant="sp_value_text" color="b_333">
                  {last_name_kanji} {first_name_kanji}
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </SPFormItem>
            <SPFormItem
              label="入居予定者の氏名（フリガナ）"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {!!last_name_kana && !!first_name_kana ? (
                <Typography variant="sp_value_text" color="b_333">
                  {last_name_kana} {first_name_kana}
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </SPFormItem>
            <SPFormItem
              label="続柄"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Typography variant="sp_value_text" color="b_333">
                {relationship_name ? relationship_name : 'ー'}
              </Typography>
            </SPFormItem>
            <SPFormItem
              label="国籍"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {nationalityOptions.find((e) => e.value === nationality) ? (
                <Typography variant="sp_value_text" color="b_333">
                  {
                    nationalityOptions.find((e) => e.value === nationality)
                      ?.label
                  }
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </SPFormItem>
            <SPFormItem
              label="生年月日"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Typography variant="sp_value_text" color="b_333">
                {birthday ? getBirthdayValue(birthday) : 'ー'}
              </Typography>
            </SPFormItem>

            <SPFormItem
              label="住宅金融支援機構（旧：公庫）からの融資の有無"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Typography variant="sp_value_text" color="b_333">
                {loan_from_japan_housing_finance_agency
                  ? financeAgency[loan_from_japan_housing_finance_agency]
                  : 'ー'}
              </Typography>
            </SPFormItem>

            <SPFormItem
              label="電話番号"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Typography variant="sp_value_text" color="b_333">
                {home_phone_number ? home_phone_number : 'ー'}
              </Typography>
            </SPFormItem>

            <SPFormItem
              label="郵便番号"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {postal_code ? (
                <Stack spacing={1}>
                  <Typography variant="sp_value_text" color="b_333">
                    {postal_code}
                  </Typography>
                  <Typography variant="sp_value_text" color="b_333">
                    {prefecture_kanji}
                    {city_kanji}
                    {district_kanji}
                    {other_address_kanji}
                  </Typography>
                </Stack>
              ) : (
                'ー'
              )}
            </SPFormItem>
          </>
        ) : (
          ''
        )}

        <SPFormItem
          label="新しい住居（融資対象物件）の事業性"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography
            variant="sp_value_text"
            color="b_333"
            whiteSpace="break-spaces"
          >
            {businessAbility.length
              ? businessAbility
                  .map((business_ability) => business_ability.option_name)
                  .join(',')
                  .replace(/,/g, '\n\n')
              : 'ー'}
          </Typography>
        </SPFormItem>

        <SPFormItem
          label="ご購入物件の所在地"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {collateral_prefecture || collateral_city || collateral_lot_number ? (
            <Typography variant="sp_value_text" color="b_333">
              {collateral_prefecture}
              {collateral_city}
              {collateral_lot_number}
            </Typography>
          ) : (
            'ー'
          )}
        </SPFormItem>

        {spStepOneValues.p_application_header.loan_target ===
          PurposeType.THREE ||
        spStepOneValues.p_application_header.loan_target ===
          PurposeType.FOUR ? (
          <SPFormItem
            label="マンション名・部屋番号"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {condominium_name ? condominium_name : ''}
            </Typography>
          </SPFormItem>
        ) : (
          ''
        )}

        {spStepOneValues.p_application_header.loan_target ===
          PurposeType.THREE ||
        spStepOneValues.p_application_header.loan_target ===
          PurposeType.FOUR ? (
          <SPFormItem
            label="ご購入物件の面積"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Stack spacing={1}>
              <Typography variant="sp_value_text" color="b_333">
                〈専有面積〉
                {occupied_area ? numberWithCommas(occupied_area) : 'ー'}㎡
              </Typography>
              <Typography variant="sp_value_text" color="b_333">
                〈マンション全体の延べ床面積〉
                {collateral_total_floor_area
                  ? numberWithCommas(collateral_total_floor_area)
                  : 'ー'}
                ㎡
              </Typography>
            </Stack>
          </SPFormItem>
        ) : (
          <SPFormItem
            label="ご購入物件の面積"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Stack spacing={1}>
              <Typography variant="sp_value_text" color="b_333">
                〈土地の敷地面積〉
                {collateral_land_area
                  ? numberWithCommas(collateral_land_area)
                  : 'ー'}
                ㎡
              </Typography>
              <Typography variant="sp_value_text" color="b_333">
                〈建物の延べ床面積〉
                {collateral_total_floor_area
                  ? numberWithCommas(collateral_total_floor_area)
                  : 'ー'}
                ㎡
              </Typography>
            </Stack>
          </SPFormItem>
        )}

        {isMCJ ? (
          <>
            <SPFormItem
              label="ご購入物件の土地権利"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Stack spacing={1}>
                {landOwnershipOptions.find(
                  (e) => e.value === land_ownership
                ) ? (
                  <Typography variant="sp_value_text" color="b_333">
                    {
                      landOwnershipOptions.find(
                        (e) => e.value === land_ownership
                      )?.label
                    }
                  </Typography>
                ) : (
                  <Typography variant="sp_value_text" color="b_333">
                    ー
                  </Typography>
                )}
              </Stack>
            </SPFormItem>
            <SPFormItem
              label="買戻・保留地・仮換地"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Stack spacing={1}>
                {purchasePurposeOptions.find(
                  (e) => e.value === purchase_purpose
                ) ? (
                  <Typography variant="sp_value_text" color="b_333">
                    {
                      purchasePurposeOptions.find(
                        (e) => e.value === purchase_purpose
                      )?.label
                    }
                  </Typography>
                ) : (
                  <Typography variant="sp_value_text" color="b_333">
                    ー
                  </Typography>
                )}
              </Stack>
            </SPFormItem>
            <SPFormItem
              label="都市計画区域等 (MCJ固有項目)"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Stack spacing={1}>
                {planningAreaOptions.find((e) => e.value === planning_area) ? (
                  <Typography variant="sp_value_text" color="b_333">
                    {
                      planningAreaOptions.find((e) => e.value === planning_area)
                        ?.label
                    }
                  </Typography>
                ) : (
                  <Typography variant="sp_value_text" color="b_333">
                    ー
                  </Typography>
                )}
              </Stack>
            </SPFormItem>
            {planning_area === PlanningAreaType.OTHERS ? (
              <SPFormItem
                label="その他の詳細"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {other_planning_area ? other_planning_area : 'ー'}
                </Typography>
              </SPFormItem>
            ) : (
              ''
            )}

            {planning_area === PlanningAreaType.URBANIZED_AREA ||
            planning_area === PlanningAreaType.URBANIZATION_CONTROL_AREA ? (
              <SPFormItem
                label="再建築理由"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                {rebuildingReasonOptions.find(
                  (e) => e.value === rebuilding_reason
                ) ? (
                  <Typography variant="sp_value_text" color="b_333">
                    {
                      rebuildingReasonOptions.find(
                        (e) => e.value === rebuilding_reason
                      )?.label
                    }
                  </Typography>
                ) : (
                  <Typography variant="sp_value_text" color="b_333">
                    ー
                  </Typography>
                )}
              </SPFormItem>
            ) : (
              ''
            )}

            {rebuilding_reason === RebuildingReasonType.OTHERS ? (
              <SPFormItem
                label="その他の詳細"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                <Typography variant="sp_value_text" color="b_333">
                  {other_rebuilding_reason ? other_rebuilding_reason : 'ー'}
                </Typography>
              </SPFormItem>
            ) : (
              ''
            )}

            <SPFormItem
              label="再建築理由"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {flat35ApplicablePlanOptions.find(
                (e) => e.value === flat_35_applicable_plan
              ) ? (
                <Typography variant="sp_value_text" color="b_333">
                  {
                    flat35ApplicablePlanOptions.find(
                      (e) => e.value === flat_35_applicable_plan
                    )?.label
                  }
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </SPFormItem>

            <SPFormItem
              label="維持保全型"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {maintenanceOptions.find((e) => e.value === maintenance_type) ? (
                <Typography variant="sp_value_text" color="b_333">
                  {
                    maintenanceOptions.find((e) => e.value === maintenance_type)
                      ?.label
                  }
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </SPFormItem>

            {flat_35_applicable_plan === Flat35ApplicablePlanType.PLAN_A ? (
              <SPFormItem
                label="フラット35S（優良住宅取得支援制度）対象項目②"
                labelSx={{ p: '3px 16px' }}
                childrenSx={{ p: '12px 24px 20px' }}
                required
              >
                {flat35ApplicationOptions.find(
                  (e) => e.value === flat_35_application
                ) ? (
                  <Typography variant="sp_value_text" color="b_333">
                    {
                      flat35ApplicationOptions.find(
                        (e) => e.value === flat_35_application
                      )?.label
                    }
                  </Typography>
                ) : (
                  <Typography variant="sp_value_text" color="b_333">
                    ー
                  </Typography>
                )}
              </SPFormItem>
            ) : (
              ''
            )}
            <SPFormItem
              label="地域連携型・地方移住支援型"
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              {regionOptions.find((e) => e.value === region_type) ? (
                <Typography variant="sp_value_text" color="b_333">
                  {regionOptions.find((e) => e.value === region_type)?.label}
                </Typography>
              ) : (
                <Typography variant="sp_value_text" color="b_333">
                  ー
                </Typography>
              )}
            </SPFormItem>
          </>
        ) : (
          ''
        )}

        <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
          <Stack
            sx={{
              paddingRight: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: 'none',
            }}
          >
            {showEditButton && (
              <SPButton
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: '10px',
                  borderWidth: 2,
                  bgcolor: 'sp_primary_40',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
                variant="outlined"
                fullWidth
                onClick={() => navigate(routeNames.SPStepFour.path)}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icons.SPPencil />
                  <Typography
                    variant="sp_contentButton_text"
                    color="sp_primary_100_main"
                  >
                    {`STEP${checkTitle.slice(0, 2)} を修正する`}
                  </Typography>
                </Stack>
              </SPButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
