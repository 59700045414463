import {
  AdminEditPreliminaryPage,
  AdminMessageDetailPage,
  AdminPreviewImagePage,
  ApplicationFlowPage,
  CompletePage,
  ConfirmationPage,
  ConsentPage,
  ContactInformationsPage,
  DashboardsPage,
  InputTimePage,
  InquiryPage,
  LoginPage,
  ManagerContactInformationsPage,
  ManagerPreliminariesPage,
  MaterialPage,
  MemoPage,
  MessagesPage,
  NotePage,
  PreliminariesPage,
  ResetPasswordManagerPage,
  ResetPasswordSalePersonPage,
  ResultPage,
  ReviewStatusPage,
  SPAccountInformationPage,
  SPAgreementPage,
  SPChangePasswordPage,
  SPConfirmApplicationDetailPage,
  SPConsentPage,
  SPDocumentUploadPage,
  SPLoginPage,
  SPScreenEmailPage,
  SPStartScreenPage,
  SPStepEightPage,
  SPStepFiveIncomeTotalizerPage,
  SPStepFivePage,
  SPStepFourCollateralProviderPage,
  SPStepFourIncomeTotalizerPage,
  SPStepFourPage,
  SPStepNinePage,
  SPStepOnePage,
  SPStepSevenIncomeTotalizerPage,
  SPStepSevenPage,
  SPStepSixPage,
  SPStepTenPage,
  SPStepThreePage,
  SPStepTwoPage,
  SPTopPage,
  SPUnsubcribedPage,
  SPUpdateEmailPage,
  SPUpdatePasswordPage,
  SalePersonEditPreliminaryPage,
  SalePersonLoginPage,
  SalePersonPreliminariesPage,
  SignUpPage,
  StatusScreenPage,
  StepFourAPage,
  StepFourBPage,
  StepRequiredInformationPage,
  StepSynthesisInformationPage,
  SPExaminationResultPage,
  ManagerDocumentUploadPage,
  ManagerDocumentUploadDetailPage,
  SalePersonUploadNewDocumentPage,
  SalePersonLogoutAzurePage,
} from 'pages';
import SalePersonPreviewImage from 'pages/SalePersonPreliminaryImage';
import { FC } from 'react';

export type RouteProps = {
  name: string;
  path: string;
  stepNumber?: number;
};

export const routeNames = {
  StartPage: {
    name: 'StartPage',
    path: '/',
  },
  Top: {
    name: 'Top',
    path: '/top',
  },
  TopUpload: {
    name: 'TopUpload',
    path: '/top/upload',
  },
  Dashboard: {
    name: 'Dashboard',
    path: '/dashboard',
  },
  StatusScreen: {
    name: 'Status',
    path: '/manager/status',
  },
  Preliminaries: {
    name: 'Preliminaries',
    path: '/preliminaries',
  },
  NotePage: {
    name: 'NotePage',
    path: '/note-page',
  },
  Consent: {
    name: 'Consent',
    path: '/consent',
  },
  InputTime: {
    name: 'InputTime',
    path: '/input-time',
  },
  Material: {
    name: 'Material',
    path: '/material',
  },
  ApplicationFlow: {
    name: 'ApplicationFlow',
    path: '/application-flow',
  },
  StepRequiredInformation: {
    name: 'StepRequiredInformation',
    path: '/step-required-information',
    stepNumber: 1,
  },
  SPStepOne: {
    name: 'SPStepOne',
    path: '/step-one',
    stepNumber: 1,
  },
  SPStepTwo: {
    name: 'SPStepTwo',
    path: '/step-two',
    stepNumber: 2,
  },
  SPStepThree: {
    name: 'SPStepThree',
    path: '/step-three',
    stepNumber: 3,
  },
  SPStepFour: {
    name: 'SPStepFour',
    path: '/step-four',
    stepNumber: 4,
  },
  SPStepFourCollateralProvider: {
    name: 'SPStepFourCollateralProvider',
    path: '/step-collateral-provider',
    stepNumber: 4,
  },
  SPStepFourIncomeTotalizer: {
    name: 'SPStepFourIncomeTotalizer',
    path: '/step-four-income-totalizer',
    stepNumber: 4,
  },
  SPStepFiveIncomeTotalizer: {
    name: 'SPStepFiveIncomeTotalizer',
    path: '/step-five-income-totalizer',
    stepNumber: 5,
  },
  SPStepSevenIncomeTotalizer: {
    name: 'SPStepSevenIncomeTotalizer',
    path: '/step-seven-income-totalizer',
    stepNumber: 10,
  },
  SPStepFive: {
    name: 'SPStepFive',
    path: '/step-five',
    stepNumber: 5,
  },
  SPStepSix: {
    name: 'SPStepSix',
    path: '/step-six',
    stepNumber: 6,
  },
  SPStepSeven: {
    name: 'SPStepSeven',
    path: '/step-seven',
    stepNumber: 7,
  },
  SPStepEight: {
    name: 'SPStepEight',
    path: '/step-eight',
    stepNumber: 8,
  },
  SPStepTen: {
    name: 'SPStepTen',
    path: '/step-ten',
    stepNumber: 10,
  },
  StepFourA: {
    name: 'StepFourA',
    path: '/step-four-a',
    stepNumber: 2,
  },
  StepFourB: {
    name: 'StepFourB',
    path: '/step-four-b',
    stepNumber: 3,
  },
  SPStepNine: {
    name: 'SPStepNine',
    path: '/step-nine',
    stepNumber: 9,
  },
  Complete: {
    name: 'Complete',
    path: '/complete',
    stepNumber: 4,
  },
  AdminApplications: {
    name: 'AdminApplications',
    path: '/admin/applications',
  },
  AdminApplicants: {
    name: 'AdminApplicants',
    path: '/admin/applicants',
  },
  AdminNewSales: {
    name: 'AdminNewSales',
    path: '/admin/new-sales',
  },
  AdminBusinesses: {
    name: 'AdminBusinesses',
    path: '/admin/businesses',
  },
  AdminEditPreliminary: {
    name: 'AdminEditPreliminary',
    path: '/manager/edit-preliminary',
  },
  SalePersonEditPreliminary: {
    name: 'SalePersonEditPreliminary',
    path: '/sale-person/edit-preliminary',
  },
  AdminPreviewImage: {
    name: 'AdminPreliminaryImage',
    path: '/manager/preliminary-image',
  },
  SalePersonPreviewImage: {
    name: 'SalePersonPreliminaryImage',
    path: '/sale-person/preliminary-image',
  },
  StepSynthesisInformation: {
    name: 'StepSynthesisInformation',
    path: '/step-synthesis-informations',
    stepNumber: 1,
  },
  Confirmation: {
    name: 'Confirmation',
    path: '/confirmation',
    stepNumber: 0,
  },
  SPAgreement: {
    name: 'SPAgreement',
    path: '/agreement',
  },
  SPConsent: {
    name: 'SPConsent',
    path: '/consents',
  },
  SignUp: {
    name: 'SignUp',
    path: '/sign-up',
  },
  SPScreenEmail: {
    name: 'SPScreenEmail',
    path: '/send-email',
  },
  Login: {
    name: 'Login',
    path: '/login',
  },
  LoginManager: {
    name: 'LoginManager',
    path: '/manager/login',
  },
  LoginSalePerson: {
    name: 'LoginSalePerson',
    path: '/sale-person/login',
  },
  SalePersonLogoutAzure: {
    name: 'SalePersonLogoutAzure',
    path: '/sale-person/logout-azure',
  },
  ResetPassword: {
    name: 'ResetPassword',
    path: '/reset-password',
  },
  ResetPasswordSalePerson: {
    name: 'ResetPasswordSalePerson',
    path: '/sale-person/reset-password',
  },
  ResetPasswordManager: {
    name: 'ResetPasswordManager',
    path: '/manager/reset-password',
  },
  ReviewStatus: {
    name: 'ReviewStatus',
    path: '/review-status',
  },
  Result: {
    name: 'Result',
    path: '/result',
  },
  ContactInformations: {
    name: 'ContactInformations',
    path: '/dashboard/contact-informations',
  },
  ManagerContactInformations: {
    name: 'ManagerContactInformations',
    path: '/manager/contact-informations',
  },
  ManagerPreliminaries: {
    name: 'ManagerPreliminaries',
    path: '/manager/preliminaries',
  },
  ManagerDocumentUpload: {
    name: 'ManagerDocumentUpload',
    path: '/manager/document-upload',
  },
  ManagerDocumentUploadDetail: {
    name: 'ManagerDocumentUploadDetail',
    path: '/manager/document-upload/:id',
  },
  SalePersonPreliminaries: {
    name: 'SalePersonPreliminaries',
    path: '/sale-person/preliminaries',
  },
  SalePersonDocumentUpload: {
    name: 'SalePersonDocumentUpload',
    path: '/sale-person/document-upload',
  },
  SalePersonDocumentUploadDetail: {
    name: 'SalePersonDocumentUploadDetail',
    path: '/sale-person/document-upload/:id',
  },
  SalePersonUploadNewDocument: {
    name: 'SalePersonUploadNewDocument',
    path: '/sale-person/upload-new-document',
  },
  InquiryPage: {
    name: 'Inquiry',
    path: '/dashboard/inquiry',
  },
  UpdatePassword: {
    name: 'UpdatePassword',
    path: '/update-password',
  },
  ChangeEmail: {
    name: 'ChangeEmail',
    path: '/change-email',
  },
  SendEmailRegister: {
    name: 'SendEmailRegister',
    path: '/send-email-register',
  },
  Memo: {
    name: 'Memo',
    path: '/manager/memo',
  },
  AccountInformation: {
    name: 'AccountInformation',
    path: '/account-information',
  },
  SPDocumentUpload: {
    name: 'SPDocumentUpload',
    path: '/upload',
  },
  SPExaminationResult: {
    name: 'SPExaminationResult',
    path: '/examination-result',
  },
  SPUnsubcribed: {
    name: 'SPUnsubcribed',
    path: '/unsubcribed',
  },
  ManagerMessages: {
    name: 'ManagerMessages',
    path: '/manager/messages',
  },
  SalePersonMessages: {
    name: 'SalePersonMessages',
    path: '/sale-person/messages',
  },
  ManagerMessageDetail: {
    name: 'ManagerMessageDetail',
    path: '/manager/message-detail',
  },
  SalePersonMessageDetail: {
    name: 'SalePersonMessageDetail',
    path: '/sale-person/message-detail',
  },
  SPConfirmApplicationDetail: {
    name: 'SPConfirmApplicationDetail',
    path: '/confirm-application-detail',
  },
};

export const publicRoutes: Array<RouteProps & { Element: FC }> = [
  {
    ...routeNames.StartPage,
    Element: SPStartScreenPage,
  },
  {
    ...routeNames.Preliminaries,
    Element: PreliminariesPage,
  },
  {
    ...routeNames.NotePage,
    Element: NotePage,
  },
  {
    ...routeNames.Consent,
    Element: ConsentPage,
  },
  {
    ...routeNames.InputTime,
    Element: InputTimePage,
  },
  {
    ...routeNames.Material,
    Element: MaterialPage,
  },
  {
    ...routeNames.ApplicationFlow,
    Element: ApplicationFlowPage,
  },
  {
    ...routeNames.SignUp,
    Element: SignUpPage,
  },
  {
    ...routeNames.SPScreenEmail,
    Element: SPScreenEmailPage,
  },
  {
    ...routeNames.Login,
    Element: SPLoginPage,
  },
  {
    ...routeNames.LoginManager,
    Element: LoginPage,
  },
  {
    ...routeNames.LoginSalePerson,
    Element: SalePersonLoginPage,
  },
  {
    ...routeNames.SalePersonLogoutAzure,
    Element: SalePersonLogoutAzurePage,
  },
  {
    ...routeNames.ResetPassword,
    Element: SPChangePasswordPage,
  },
  {
    ...routeNames.ResetPasswordManager,
    Element: ResetPasswordManagerPage,
  },
  {
    ...routeNames.ResetPasswordSalePerson,
    Element: ResetPasswordSalePersonPage,
  },
  {
    ...routeNames.ChangeEmail,
    Element: SPUpdateEmailPage,
  },
];

export const stepRoutes: Array<RouteProps & { Element: FC }> = [
  {
    ...routeNames.Top,
    Element: SPTopPage,
  },
  {
    ...routeNames.SPScreenEmail,
    Element: SPScreenEmailPage,
  },
  {
    ...routeNames.TopUpload,
    Element: SPTopPage,
  },
  {
    ...routeNames.Dashboard,
    Element: DashboardsPage,
  },
  {
    ...routeNames.Confirmation,
    Element: ConfirmationPage,
  },
  {
    ...routeNames.SPAgreement,
    Element: SPAgreementPage,
  },
  {
    ...routeNames.SPConsent,
    Element: SPConsentPage,
  },
  {
    ...routeNames.StepRequiredInformation,
    Element: StepRequiredInformationPage,
  },
  {
    ...routeNames.SPStepOne,
    Element: SPStepOnePage,
  },
  {
    ...routeNames.SPStepTwo,
    Element: SPStepTwoPage,
  },
  {
    ...routeNames.SPStepThree,
    Element: SPStepThreePage,
  },
  {
    ...routeNames.SPStepFour,
    Element: SPStepFourPage,
  },
  {
    ...routeNames.SPStepFourCollateralProvider,
    Element: SPStepFourCollateralProviderPage,
  },
  {
    ...routeNames.SPStepFourIncomeTotalizer,
    Element: SPStepFourIncomeTotalizerPage,
  },
  {
    ...routeNames.SPStepFiveIncomeTotalizer,
    Element: SPStepFiveIncomeTotalizerPage,
  },
  {
    ...routeNames.SPStepSevenIncomeTotalizer,
    Element: SPStepSevenIncomeTotalizerPage,
  },
  {
    ...routeNames.SPStepFive,
    Element: SPStepFivePage,
  },
  {
    ...routeNames.SPStepSix,
    Element: SPStepSixPage,
  },
  {
    ...routeNames.SPStepSeven,
    Element: SPStepSevenPage,
  },
  {
    ...routeNames.SPStepEight,
    Element: SPStepEightPage,
  },
  {
    ...routeNames.SPStepTen,
    Element: SPStepTenPage,
  },
  {
    ...routeNames.StepFourA,
    Element: StepFourAPage,
  },
  {
    ...routeNames.StepFourB,
    Element: StepFourBPage,
  },
  {
    ...routeNames.SPStepNine,
    Element: SPStepNinePage,
  },
  {
    ...routeNames.Complete,
    Element: CompletePage,
  },
  {
    ...routeNames.StepSynthesisInformation,
    Element: StepSynthesisInformationPage,
  },
  {
    ...routeNames.ReviewStatus,
    Element: ReviewStatusPage,
  },
  {
    ...routeNames.Result,
    Element: ResultPage,
  },
  {
    ...routeNames.ContactInformations,
    Element: ContactInformationsPage,
  },
  {
    ...routeNames.InquiryPage,
    Element: InquiryPage,
  },
  {
    ...routeNames.SendEmailRegister,
    Element: SPScreenEmailPage,
  },
  {
    ...routeNames.SPDocumentUpload,
    Element: SPDocumentUploadPage,
  },
  {
    ...routeNames.SPExaminationResult,
    Element: SPExaminationResultPage,
  },
  {
    ...routeNames.SPUnsubcribed,
    Element: SPUnsubcribedPage,
  },
  {
    ...routeNames.UpdatePassword,
    Element: SPUpdatePasswordPage,
  },
  {
    ...routeNames.ChangeEmail,
    Element: SPUpdateEmailPage,
  },
  {
    ...routeNames.SPConfirmApplicationDetail,
    Element: SPConfirmApplicationDetailPage,
  },
  {
    ...routeNames.AccountInformation,
    Element: SPAccountInformationPage,
  },
];

export const managerRoutes: Array<RouteProps & { Element: FC }> = [
  {
    ...routeNames.AdminEditPreliminary,
    Element: AdminEditPreliminaryPage,
  },
  {
    ...routeNames.AdminPreviewImage,
    Element: AdminPreviewImagePage,
  },
  {
    ...routeNames.ManagerPreliminaries,
    Element: ManagerPreliminariesPage,
  },
  {
    ...routeNames.ManagerDocumentUpload,
    Element: ManagerDocumentUploadPage,
  },
  {
    ...routeNames.ManagerDocumentUploadDetail,
    Element: ManagerDocumentUploadDetailPage,
  },
  {
    ...routeNames.ManagerContactInformations,
    Element: ManagerContactInformationsPage,
  },
  {
    ...routeNames.ManagerMessages,
    Element: MessagesPage,
  },
  {
    ...routeNames.ManagerMessageDetail,
    Element: AdminMessageDetailPage,
  },
  {
    ...routeNames.Memo,
    Element: MemoPage,
  },
  {
    ...routeNames.StatusScreen,
    Element: StatusScreenPage,
  },
];

export const salepersonRoutes: Array<RouteProps & { Element: FC }> = [
  {
    ...routeNames.SalePersonPreliminaries,
    Element: SalePersonPreliminariesPage,
  },
  {
    ...routeNames.SalePersonMessages,
    Element: MessagesPage,
  },
  {
    ...routeNames.SalePersonMessageDetail,
    Element: AdminMessageDetailPage,
  },
  {
    ...routeNames.SalePersonEditPreliminary,
    Element: SalePersonEditPreliminaryPage,
  },
  {
    ...routeNames.SalePersonPreviewImage,
    Element: SalePersonPreviewImage,
  },
  {
    ...routeNames.SalePersonDocumentUpload,
    Element: ManagerDocumentUploadPage,
  },
  {
    ...routeNames.SalePersonUploadNewDocument,
    Element: SalePersonUploadNewDocumentPage,
  },
  {
    ...routeNames.SalePersonDocumentUploadDetail,
    Element: ManagerDocumentUploadDetailPage,
  },
];
