import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  GetPropertyInfoRequest,
  GetPropertyInfoResponse,
  GetSavedApplicationResponse,
  SaveDraftResponse,
  NewSaveDraftResponse,
  SubmitFormResponse,
  NewPreliminariesResponse,
  UpdatePreliminariesResponse,
  UpdatePreliminariesRequest,
  SPGetDraftResponse,
  UpdateFilesPreliminariesRequest,
  SPGetPreliminaryResponse,
  GetCurrentStatusResultResponse,
  CheckDatabaseSyncRequest,
  CheckDatabaseSyncResponse,
  CreateLogDownloadRequest,
  CreateLogDownloadResponse,
} from 'types';
import { fakeInstance, instance, internalInstance } from 'libs';
import { INTERFACE_ERROR_STATUS, ZIPCLOUDURL } from 'constant';
import { config } from 'configs';
import {
  ContactCreateResponse,
  contactCreateResponseSchema,
  ContactDetailResponse,
  contactRequestSchema,
  ContactResponse,
  contactResponseSchema,
  CreateContactRequest,
  GetPostalCodeResponse,
  ListStatusResponse,
  ManagerCurrentPreliminariesItemResponse,
  ListStatusResponseSchema,
  ReviewStatusRequest,
  ReviewStatusResponse,
  reviewStatusResponseSchema,
  CreateInquiryResponse,
  CreateInquiryRequest,
  createInquiryRequestSchema,
  createInquiryResponseSchema,
  StatusRequest,
  AssignManagerResponse,
  AssignManagerRequest,
  assignManagerRequestSchema,
  assignManagerResponseSchema,
  ManagerCurrentPreliminariesItemRequest,
  CreateContactResponse,
  createContactResponseSchema,
  ContactDetailManagerRequest,
  ContactDetailManagerResponse,
  CreateMemoRequest,
  GetMemoRequest,
  GetMemoResponse,
  getMemoResponseSchema,
  createMemoResponseSchema,
  createMemoRequestSchema,
  CreateMemoResponse,
  ManagerContactRequest,
  GetUploadedFilesManagerRequest,
  GetMessageResponse,
  getMessageResponseSchema,
  CreateMessageResponse,
  CreateMessageRequest,
  createMessageResponseSchema,
  SalePersonCurrentPreliminariesItemResponse,
  SalePersonPreliminariesItemRequest,
  UpdateMemoRequest,
  updateMemoRequestSchema,
  UpdateMemoResponse,
  updateMemoResponseSchema,
  MemoDetailResponse,
  memoDetailResponseSchema,
  GetMessageAdminResponse,
  getMessageAdminResponseSchema,
  AssignSalePersonRequest,
  AssignSalePersonResponse,
  assignSalePersonRequestSchema,
  UpdateStatusResponse,
  UpdateStatusRequest,
  UpdateStatusResponseSchema,
  GetMessageDetailAdminResponse,
  getMessageDetailAdminResponseSchema,
  UpdateApplicationStatusRequest,
  UpdateApplicationStatusResponse,
  updateApplicationStatusRequestSchema,
  updateApplicationStatusResponseSchema,
  GetListUserResponse,
  getListUserResponseSchema,
  GetMessageAdminRequest,
  LinkPairLoanRequest,
  linkPairLoanRequestSchema,
  LinkUnLinkPairLoanResponse,
  linkUnLinkPairLoanResponseSchema,
  UnLinkPairLoanRequest,
  unlinkPairLoanRequestSchema,
  GetViewUploadedFilesResponse,
  getViewUploadedFilesResponseSchema,
  GetUploadedFilesResponse,
  getUploadedFilesResponseSchema,
  GetUnreadMessagesResponse,
  getUnreadMessagesResponseSchema,
  deleteMessageManagerSchema,
  DeleteMessageResponse,
  SaveSalePersonRequest,
  SaveSalePersonResponse,
  saveSalePersonRequestSchema,
  saveSalePersonResponseSchema,
  GetSalePersonRequest,
  GetSalePersonResponse,
  getSalePersonResponseSchema,
  AssignStoreNameRequest,
  AssignStoreNameResponse,
  assignStoreNameRequestSchema,
  ManagerAssignSalePersonRequest,
  ManagerAssignStoreNameRequest,
  ManagerAssignStoreNameResponse,
  ManagerAssignSalePersonResponse,
} from './schemas';

export const saveDraft = (data: string): AxiosPromise<SaveDraftResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/user/drafts',
    data,
  };

  return instance(request);
};

export const spSaveDraft = (
  data: FormData
): AxiosPromise<SaveDraftResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/user/drafts',
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  return instance(request);
};

export const spGetDraft = (): AxiosPromise<SPGetDraftResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/user/drafts',
  };

  return instance(request);
};

export const getSavedDraft = (): AxiosPromise<SaveDraftResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/user/drafts',
  };

  return instance(request);
};

export const getNewSavedDraft = (): AxiosPromise<NewSaveDraftResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/user/drafts',
  };

  return instance(request);
};

export const getExportExcel = (
  params: SalePersonPreliminariesItemRequest,
  accessToken: string
): Promise<Response> => {
  return fetch(
    `${config.serverUrl}/manager/preliminaries.xlsx?preliminary_status=${params.preliminary_status}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getExportExcelSalePerson = (
  params: SalePersonPreliminariesItemRequest,
  accessToken: string
): Promise<Response> => {
  return fetch(
    `${config.serverUrl}/s_sale_person/preliminaries.xlsx?preliminary_status=${params.preliminary_status}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getExportRawDataManager = (
  preliminary_id: string,
  accessToken: string
): Promise<Response> => {
  return fetch(
    `${config.serverUrl}/manager/preliminaries/${preliminary_id}.xlsx`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getLogs = (
  accessToken: string,
  startDate: string,
  endDate: string
): Promise<Response> => {
  return fetch(
    `${config.serverUrl}/manager/export_log_history.xlsx?start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getListStatus = (
  data: StatusRequest
): Promise<ListStatusResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/under_reviews/${data.id}`,
  };

  return instance(request).then(({ data }) =>
    ListStatusResponseSchema.parse(data)
  );
};

export const updateStatus = (
  data: UpdateStatusRequest
): Promise<UpdateStatusResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/under_reviews/${data.id}`,
    data: { under_review_status: data.under_review_status },
  };

  return instance(request).then(({ data }) =>
    UpdateStatusResponseSchema.parse(data)
  );
};

export const getPreliminaries = (
  id: string
): AxiosPromise<NewPreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/preliminaries/${id}/edit`,
  };

  return instance(request);
};

export const getSubmittedPreliminary =
  (): AxiosPromise<SPGetPreliminaryResponse> => {
    const request: AxiosRequestConfig = {
      method: 'GET',
      url: `/user/preliminaries/edit`,
    };

    return instance(request);
  };

export const updatePreliminaries = (
  req: UpdatePreliminariesRequest
): AxiosPromise<UpdatePreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PUT',
    url: `/user/preliminaries/${req.id}`,
    data: req.data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  return instance(request);
};

export const getClonePreliminaries = (
  id: string
): AxiosPromise<NewPreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/preliminaries/new`,
    params: { copy_id: `${id}` },
  };

  return instance(request);
};

export const getCurrentStatusResult =
  (): AxiosPromise<GetCurrentStatusResultResponse> => {
    const request: AxiosRequestConfig = {
      method: 'GET',
      url: `/user/check_current_status_result`,
    };

    return instance(request);
  };

export const getCurrentPreliminariesItems = (
  params?: ManagerCurrentPreliminariesItemRequest
): AxiosPromise<ManagerCurrentPreliminariesItemResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/manager/preliminaries.json',
    params,
  };
  return instance(request);
};

export const getCurrentSalePersonPreliminariesItems = (
  params?: SalePersonPreliminariesItemRequest
): AxiosPromise<SalePersonCurrentPreliminariesItemResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/s_sale_person/preliminaries.json',
    params,
  };
  return instance(request);
};

export const assignManager = (
  data: AssignManagerRequest
): Promise<AssignManagerResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/manager/assign_manager',
    data: assignManagerRequestSchema.parse(data),
  };

  return instance(request).then(({ data }) =>
    assignManagerResponseSchema.parse(data)
  );
};

export const saveSalePerson = (
  data: SaveSalePersonRequest
): Promise<SaveSalePersonResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/user/users/${data.user_id}`,
    data: saveSalePersonRequestSchema.parse(data),
  };

  return instance(request).then(({ data }) =>
    saveSalePersonResponseSchema.parse(data)
  );
};

export const getSalePerson = (
  data: GetSalePersonRequest
): Promise<GetSalePersonResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/users/${data.user_id}`,
  };
  return instance(request).then(({ data }) =>
    getSalePersonResponseSchema.parse(data)
  );
};

export const assignSalePerson = (
  data: AssignSalePersonRequest
): Promise<AssignSalePersonResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/s_sale_person/assign_sale_person',
    data: assignSalePersonRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) => data);
};

export const assignStoreName = (
  data: AssignStoreNameRequest
): Promise<AssignStoreNameResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/s_sale_person/assign_store_name',
    data: assignStoreNameRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) => data);
};

export const managerAssignSalePerson = (
  data: ManagerAssignSalePersonRequest
): Promise<ManagerAssignSalePersonResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/manager/assign_sale_person',
    data: assignSalePersonRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) => data);
};

export const managerAssignStoreName = (
  data: ManagerAssignStoreNameRequest
): Promise<ManagerAssignStoreNameResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/manager/assign_store_name',
    data: assignStoreNameRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) => data);
};

export const getUploadedFiles = (
  id: string
): Promise<GetUploadedFilesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/change_files/${id}/edit`,
  };

  return instance(request).then(({ data }) =>
    getUploadedFilesResponseSchema.parse(data)
  );
};

export const updateUploadedFiles = (
  req: UpdatePreliminariesRequest
): AxiosPromise<UpdatePreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/user/change_files/${req.id}`,
    data: req.data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  return instance(request);
};

export const getUploadedFilesManager = ({
  id,
  applicant_id,
}: GetUploadedFilesManagerRequest): Promise<GetViewUploadedFilesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/change_files/${id}/edit`,
    params: {
      applicant_id,
    },
  };

  return instance(request).then(({ data }) =>
    getViewUploadedFilesResponseSchema.parse(data)
  );
};

export const getUploadedFilesSalePerson = ({
  id,
  applicant_id,
}: GetUploadedFilesManagerRequest): Promise<GetViewUploadedFilesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/s_sale_person/change_files/${id}/edit`,
    params: {
      applicant_id,
    },
  };

  return instance(request).then(({ data }) =>
    getViewUploadedFilesResponseSchema.parse(data)
  );
};

export const updateUploadedFilesManager = ({
  id,
  applicant_id,
  data,
}: UpdateFilesPreliminariesRequest): AxiosPromise<UpdatePreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/change_files/${id}?applicant_id=${applicant_id}`,
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  const requestIncome: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/change_files/${id}`,
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  return instance(!!applicant_id ? request : requestIncome);
};

export const getSavedApplications =
  (): AxiosPromise<GetSavedApplicationResponse> => {
    const request: AxiosRequestConfig = {
      method: 'GET',
      url: '/savedApplication',
    };
    // fake API
    return fakeInstance(request);
    // return instance(request);
  };

export const getPropertyInfo = ({
  id,
}: GetPropertyInfoRequest): AxiosPromise<GetPropertyInfoResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/propertyInfo',
    data: { id },
  };
  // fake API
  return fakeInstance(request);
  // return instance(request);
};

export const getZipCloudAddress = (
  code: string
): AxiosPromise<GetPostalCodeResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: ZIPCLOUDURL,
    params: {
      zipcode: code,
    },
  };

  return internalInstance(request);
};

export const getReviewStatus = (
  req: ReviewStatusRequest
): Promise<ReviewStatusResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/under_reviews/${req.id}`,
    params: {
      applicant_id: req.applicant_id,
    },
  };

  return instance(request).then(({ data }) =>
    reviewStatusResponseSchema.parse(data)
  );
};

export const getContacts = (): Promise<ContactResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/user/contact_informations',
  };
  return instance(request).then(({ data }) =>
    contactResponseSchema.parse(data)
  );
};

export const getManagerContacts = (
  params: ManagerContactRequest
): Promise<ContactResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/manager/contact_informations',
    params,
  };
  return instance(request).then(({ data }) =>
    contactResponseSchema.parse(data)
  );
};

export const createContactsInformation = (
  req: CreateContactRequest
): Promise<CreateContactResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/manager/contact_informations',
    data: contactRequestSchema.parse(req),
  };

  return instance(request).then(({ data }) =>
    createContactResponseSchema.parse(data)
  );
};

export const getContactDetail = (
  id: string
): Promise<ContactDetailResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/user/contact_informations/${id}`,
  };
  return instance(request).then(({ data }) => data);
};

export const getContactDetailManager = (
  req: ContactDetailManagerRequest
): Promise<ContactDetailManagerResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/contact_informations/${req.id}`,
    params: {
      applicant_id: req.applicant_id,
      preliminary_id: req.preliminary_id,
    },
  };
  return instance(request).then(({ data }) => data);
};

export const createContactInformations = (
  data: CreateContactRequest
): Promise<ContactCreateResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/user/contact_informations',
    data: contactRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    contactCreateResponseSchema.parse(data)
  );
};

export const createInquiry = (
  data: CreateInquiryRequest
): Promise<CreateInquiryResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/user/inquiries',
    data: createInquiryRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    createInquiryResponseSchema.parse(data)
  );
};

export const getMemos = (data: GetMemoRequest): Promise<GetMemoResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/preliminaries/${data.id}/memos`,
    params: data,
  };
  return instance(request).then(({ data }) =>
    getMemoResponseSchema.parse(data)
  );
};

export const createMemo = (
  data: CreateMemoRequest
): Promise<CreateMemoResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: `/manager/preliminaries/${data.id}/memos`,
    data: createMemoRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    createMemoResponseSchema.parse(data)
  );
};

export const updateMemo = (
  data: UpdateMemoRequest
): Promise<UpdateMemoResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/preliminaries/${data.preliminaryId}/memos/${data.memoId}`,
    data: updateMemoRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    updateMemoResponseSchema.parse(data)
  );
};

export const getMemoDetail = (
  data: UpdateMemoRequest
): Promise<MemoDetailResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/preliminaries/${data.preliminaryId}/memos/${data.memoId}`,
    data: updateMemoRequestSchema.parse(data),
  };
  return instance(request).then(({ data }) =>
    memoDetailResponseSchema.parse(data)
  );
};

export const submitForm = (
  data: FormData
): AxiosPromise<SubmitFormResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: 'user/preliminaries',
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };
  return instance(request);
};

export const updatePreliminary = (
  data: FormData
): AxiosPromise<SPGetPreliminaryResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/user/preliminaries',
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  return instance(request);
};

export const getUnreadMessages = (): Promise<GetUnreadMessagesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/user/messages/total_unread_messages',
  };
  return instance(request).then(({ data }) =>
    getUnreadMessagesResponseSchema.parse(data)
  );
};
export const getMessages = (): Promise<GetMessageResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/user/messages',
  };
  return instance(request).then(({ data }) =>
    getMessageResponseSchema.parse(data)
  );
};

export const createMessage = (
  data: CreateMessageRequest
): Promise<CreateMessageResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/user/messages',
    data,
  };
  return instance(request).then(({ data }) =>
    createMessageResponseSchema.parse(data)
  );
};

export const getMessagesManager = (
  params?: GetMessageAdminRequest
): Promise<GetMessageAdminResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/manager/messages',
    params,
  };
  return instance(request).then(({ data }) =>
    getMessageAdminResponseSchema.parse(data)
  );
};

export const getMessagesSalePerson = (
  params?: GetMessageAdminRequest
): Promise<GetMessageAdminResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/s_sale_person/messages',
    params,
  };
  return instance(request).then(({ data }) =>
    getMessageAdminResponseSchema.parse(data)
  );
};

export const getMessageDetailManager = (
  id: string
): Promise<GetMessageDetailAdminResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/manager/messages/${id}`,
  };
  return instance(request).then(({ data }) =>
    getMessageDetailAdminResponseSchema.parse(data)
  );
};

export const getMessageDetailSalePerson = (
  id: string
): Promise<GetMessageDetailAdminResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `/s_sale_person/messages/${id}`,
  };
  return instance(request).then(({ data }) =>
    getMessageDetailAdminResponseSchema.parse(data)
  );
};

export const createMessageManager = (
  data: FormData
): Promise<CreateMessageResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/manager/messages',
    data,
  };
  return instance(request).then(({ data }) =>
    createMessageResponseSchema.parse(data)
  );
};

export const createMessageSalePerson = (
  data: FormData
): Promise<CreateMessageResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/s_sale_person/messages',
    data,
  };
  return instance(request).then(({ data }) =>
    createMessageResponseSchema.parse(data)
  );
};

export const deleteMessageManager = (
  id: string
): Promise<DeleteMessageResponse> => {
  const request: AxiosRequestConfig = {
    method: 'DELETE',
    url: `/manager/messages/${id}`,
  };
  return instance(request).then(({ data }) =>
    deleteMessageManagerSchema.parse({ ...data, id })
  );
};

export const getListUserManager = (): Promise<GetListUserResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/manager/list_users',
  };
  return instance(request).then(({ data }) =>
    getListUserResponseSchema.parse(data)
  );
};

export const getListUserSalePerson = (): Promise<GetListUserResponse> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: '/s_sale_person/list_users',
  };
  return instance(request).then(({ data }) =>
    getListUserResponseSchema.parse(data)
  );
};

export const updateApplicationStatus = (
  data: UpdateApplicationStatusRequest
): Promise<UpdateApplicationStatusResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/manager/update_application_status',
    data: updateApplicationStatusRequestSchema.parse(data),
  };

  return instance(request).then(({ data }) =>
    updateApplicationStatusResponseSchema.parse(data)
  );
};

export const linkPairLoan = (
  data: LinkPairLoanRequest
): Promise<LinkUnLinkPairLoanResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/manager/link_pair_loan',
    data: linkPairLoanRequestSchema.parse(data),
  };

  return instance(request).then(({ data }) =>
    linkUnLinkPairLoanResponseSchema.parse(data)
  );
};

export const unlinkPairLoan = (
  data: UnLinkPairLoanRequest
): Promise<LinkUnLinkPairLoanResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: '/manager/unlink_pair_loan',
    data: unlinkPairLoanRequestSchema.parse(data),
  };

  return instance(request).then(({ data }) =>
    linkUnLinkPairLoanResponseSchema.parse(data)
  );
};

export const updateUploadedFilesSalePerson = ({
  id,
  applicant_id,
  data,
}: UpdateFilesPreliminariesRequest): AxiosPromise<UpdatePreliminariesResponse> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/s_sale_person/change_files/${id}?applicant_id=${applicant_id}`,
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  const requestIncome: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/s_sale_person/change_files/${id}`,
    data,
    headers: { 'content-type': 'multipart/form-data' },
  };

  return instance(!!applicant_id ? request : requestIncome);
};

export const checkDatabaseSync = ({
  id,
  edit_time,
  sbi_id,
}: CheckDatabaseSyncRequest): AxiosPromise<CheckDatabaseSyncResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: `/user/check_database_sync?id=${id}&sbi_id=${sbi_id}&edit_time=${edit_time}`,
  };

  return instance(request);
};

export const checkDatabaseSyncManager = ({
  id,
  edit_time,
  sbi_id,
}: CheckDatabaseSyncRequest): AxiosPromise<CheckDatabaseSyncResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: `/manager/check_database_sync?id=${id}&sbi_id=${sbi_id}&edit_time=${edit_time}`,
  };

  return instance(request);
};

export const checkDatabaseSyncSalePerson = ({
  id,
  edit_time,
  sbi_id,
}: CheckDatabaseSyncRequest): AxiosPromise<CheckDatabaseSyncResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: `/s_sale_person/check_database_sync?id=${id}&sbi_id=${sbi_id}&edit_time=${edit_time}`,
  };

  return instance(request);
};

export const createDownloadLogManager = ({
  id,
  key,
}: CreateLogDownloadRequest): AxiosPromise<CreateLogDownloadResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: `/manager/download_pdf_log?id=${id}&key=${key}`,
  };

  return instance(request);
};

export const createDownloadLogSalePerson = ({
  id,
  key,
}: CreateLogDownloadRequest): AxiosPromise<CreateLogDownloadResponse> => {
  const request: AxiosRequestConfig = {
    method: 'POST',
    url: `/s_sale_person/download_pdf_log?id=${id}&key=${key}`,
  };

  return instance(request);
};

export const updateInterfaceErrorStatus = (data: {
  interface_error_status: INTERFACE_ERROR_STATUS;
  id: string;
}): AxiosPromise<void> => {
  const request: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/manager/update_interface_error_status`,
    data,
  };

  return instance(request);
};
