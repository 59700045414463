import { modalErrorRef, SPImage } from 'components';
import {
  BORROWING_DETAIL,
  CONTACT_INFORMATION,
  CURRENT_BORROWING,
  ERROR_MESSAGE_KEY,
  EXPIRATION_DATE,
  Error as ErrorEnum,
  FIELDS_VALIDATE,
  FINANCING_PLAN,
  GUARANTEE_PROVIDER,
  HEAD_CURRENT_BORROWING,
  INCOME_TOTALIZER,
  OTHER_ERROR,
  PERSONAL_INFORMATION,
  PERSONAL_OCCUPATION,
  PERSONAL_RESIDENCE,
  PROCUREMENT_BREAKDOWN,
  PROPERTY_DETAIL,
  REFERRAL_AGENCY,
  REQUIRED_FUNDS_BREAKDOWN,
  SUB_CURRENT_BORROWING,
} from 'constant';
import dayjs, { Dayjs } from 'dayjs';
import jwtDecode from 'jwt-decode';
import kanjidate from 'kanjidate';
import { get, uniqWith } from 'lodash';
import { routeNames } from 'navigations/routes';
import { KeyboardEvent, ReactNode, isValidElement } from 'react';
import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';
import { DynamicOption } from 'services';
import { MultipleOptionsEnum, OptionsEnum } from 'types';
import { ZodError } from 'zod';
import isEmpty from 'lodash/isEmpty';
import 'dayjs/locale/ja';

export const numberWithCommas = (
  x: number | string,
  isPrice?: boolean
): string => {
  return isPrice
    ? x
        .toString()
        .replaceAll('.', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const strippedStringWithCommas = (input: string): string =>
  input.replace(/,/g, '');

export const messageOverMaxNumber = (amount: number): string => {
  return `${numberWithCommas(amount)}以下で入力してください。`;
};
export const messageOverMaxYen = (amount: number): string => {
  return `${numberWithCommas(amount)}円以下に入力してください。`;
};
export const messageOverMaxManYen = (amount: number): string => {
  return `${numberWithCommas(amount)}万円以下に入力してください。`;
};
export const zeroPad = (num: number): string => {
  return String(num).padStart(2, '0');
};

export const formatCurrency = (num: number): string => {
  if (num >= 10000) {
    return `${~~(num / 10000)}億${
      num % 10000 !== 0 ? numberWithCommas(num % 10000) : ''
    }`;
  }
  return numberWithCommas(num);
};

export const formatJapanDateTine = (date: string, format: string) => {
  return dayjs(date).utcOffset(0).format(format);
};
export const formatJapanDate = (date: string, showEpoch: boolean = false) => {
  const [year, month, day] = date.split('/');
  let formatDate = '';
  let epoch = '';
  const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
  const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
  if (showEpoch) epoch += ` (${kanjiDateG2}${kanjiDateN})`;
  if (year) formatDate = `${year}${epoch}年`;
  if (month) formatDate += `${parseInt(month, 10)}月`;
  if (day) formatDate += `${parseInt(day, 10)}日`;
  return formatDate;
};

export const formatJapanBirthday = (date: string) => {
  const monthDiff = dayjs().diff(date, 'month');
  const [year, month, day] = date.split('/');
  let age = Math.floor(monthDiff / 12);
  let formatDate = '';
  if (year) formatDate = `${year}年`;
  if (month) formatDate += `${parseInt(month, 10)}月`;
  if (day) formatDate += `${parseInt(day, 10)}日`;
  formatDate += `（満${age}歳）`;
  return formatDate;
};

export const handleFormatDateDiff = (date: string) => {
  const monthDiff = dayjs().diff(date, 'month');
  if (!date || monthDiff < 0) return '';
  return `${Math.floor(monthDiff / 12)}年 ${monthDiff % 12}ヶ月`;
};

export const formatDate = (date: string) => {
  const [year, month] = date.split('/');
  let formatDate = '';
  if (year) formatDate += `${year}年`;
  if (month) formatDate += `${month}月`;
  return formatDate;
};

export function isReactNode(node?: {} | null): node is ReactNode {
  return isValidElement(node);
}

export const checkAfterDate = (startDate: string, endDate: string) => {
  if (!startDate) return false;
  return dayjs(endDate).diff(dayjs(startDate), 'day') >= EXPIRATION_DATE;
};

const zenHanMap: { [key: string]: string } = {
  ァ: 'ｧ',
  ア: 'ｱ',
  ィ: 'ｨ',
  イ: 'ｲ',
  ゥ: 'ｩ',
  ウ: 'ｳ',
  ェ: 'ｪ',
  エ: 'ｴ',
  ォ: 'ｫ',
  オ: 'ｵ',
  カ: 'ｶ',
  ガ: 'ｶﾞ',
  キ: 'ｷ',
  ギ: 'ｷﾞ',
  ク: 'ｸ',
  グ: 'ｸﾞ',
  ケ: 'ｹ',
  ゲ: 'ｹﾞ',
  コ: 'ｺ',
  ゴ: 'ｺﾞ',
  サ: 'ｻ',
  ザ: 'ｻﾞ',
  シ: 'ｼ',
  ジ: 'ｼﾞ',
  ス: 'ｽ',
  ズ: 'ｽﾞ',
  セ: 'ｾ',
  ゼ: 'ｾﾞ',
  ソ: 'ｿ',
  ゾ: 'ｿﾞ',
  タ: 'ﾀ',
  ダ: 'ﾀﾞ',
  チ: 'ﾁ',
  ヂ: 'ﾁﾞ',
  ッ: 'ｯ',
  ツ: 'ﾂ',
  ヅ: 'ﾂﾞ',
  テ: 'ﾃ',
  デ: 'ﾃﾞ',
  ト: 'ﾄ',
  ド: 'ﾄﾞ',
  ナ: 'ﾅ',
  ニ: 'ﾆ',
  ヌ: 'ﾇ',
  ネ: 'ﾈ',
  ノ: 'ﾉ',
  ハ: 'ﾊ',
  バ: 'ﾊﾞ',
  パ: 'ﾊﾟ',
  ヒ: 'ﾋ',
  ビ: 'ﾋﾞ',
  ピ: 'ﾋﾟ',
  フ: 'ﾌ',
  ブ: 'ﾌﾞ',
  プ: 'ﾌﾟ',
  ヘ: 'ﾍ',
  ベ: 'ﾍﾞ',
  ペ: 'ﾍﾟ',
  ホ: 'ﾎ',
  ボ: 'ﾎﾞ',
  ポ: 'ﾎﾟ',
  マ: 'ﾏ',
  ミ: 'ﾐ',
  ム: 'ﾑ',
  メ: 'ﾒ',
  モ: 'ﾓ',
  ャ: 'ｬ',
  ヤ: 'ﾔ',
  ュ: 'ｭ',
  ユ: 'ﾕ',
  ョ: 'ｮ',
  ヨ: 'ﾖ',
  ラ: 'ﾗ',
  リ: 'ﾘ',
  ル: 'ﾙ',
  レ: 'ﾚ',
  ロ: 'ﾛ',
  ヮ: '',
  ワ: 'ﾜ',
  ヲ: 'ｦ',
  ン: 'ﾝ',
  ヴ: 'ｳﾞ',
  '・': '･',
  ー: '-',
  '−': '-',
  '－': '-',
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',
};

type KanaMap = {
  [key: string]: string;
};

export const kanaMap1: KanaMap = {
  ｶﾞ: 'ガ',
  ｷﾞ: 'ギ',
  ｸﾞ: 'グ',
  ｹﾞ: 'ゲ',
  ｺﾞ: 'ゴ',
  ｻﾞ: 'ザ',
  ｼﾞ: 'ジ',
  ｽﾞ: 'ズ',
  ｾﾞ: 'ゼ',
  ｿﾞ: 'ゾ',
  ﾀﾞ: 'ダ',
  ﾁﾞ: 'ヂ',
  ﾂﾞ: 'ヅ',
  ﾃﾞ: 'デ',
  ﾄﾞ: 'ド',
  ﾊﾞ: 'バ',
  ﾋﾞ: 'ビ',
  ﾌﾞ: 'ブ',
  ﾍﾞ: 'ベ',
  ﾎﾞ: 'ボ',
  ﾊﾟ: 'パ',
  ﾋﾟ: 'ピ',
  ﾌﾟ: 'プ',
  ﾍﾟ: 'ペ',
  ﾎﾟ: 'ポ',
  ｳﾞ: 'ヴ',
  ﾜﾞ: 'ヷ',
  ｦﾞ: 'ヺ',
  ｱ: 'ア',
  ｲ: 'イ',
  ｳ: 'ウ',
  ｴ: 'エ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｷ: 'キ',
  ｸ: 'ク',
  ｹ: 'ケ',
  ｺ: 'コ',
  ｻ: 'サ',
  ｼ: 'シ',
  ｽ: 'ス',
  ｾ: 'セ',
  ｿ: 'ソ',
  ﾀ: 'タ',
  ﾁ: 'チ',
  ﾂ: 'ツ',
  ﾃ: 'テ',
  ﾄ: 'ト',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾋ: 'ヒ',
  ﾌ: 'フ',
  ﾍ: 'ヘ',
  ﾎ: 'ホ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ﾔ: 'ヤ',
  ﾕ: 'ユ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',
  ｧ: 'ァ',
  ｨ: 'ィ',
  ｩ: 'ゥ',
  ｪ: 'ェ',
  ｫ: 'ォ',
  ｯ: 'ッ',
  ｬ: 'ャ',
  ｭ: 'ュ',
  ｮ: 'ョ',
  '｡': '。',
  '､': '、',
  ｰ: 'ー',
  '-': 'ー',
  '−': 'ー',
  '－': 'ー',
  '｢': '「',
  '｣': '」',
  '･': '・',
  '0': '０',
  '1': '１',
  '2': '２',
  '3': '３',
  '4': '４',
  '5': '５',
  '6': '６',
  '7': '７',
  '8': '８',
  '9': '９',
};

const kanaMap = {
  ｶﾞ: 'ガ',
  ｷﾞ: 'ギ',
  ｸﾞ: 'グ',
  ｹﾞ: 'ゲ',
  ｺﾞ: 'ゴ',
  ｻﾞ: 'ザ',
  ｼﾞ: 'ジ',
  ｽﾞ: 'ズ',
  ｾﾞ: 'ゼ',
  ｿﾞ: 'ゾ',
  ﾀﾞ: 'ダ',
  ﾁﾞ: 'ヂ',
  ﾂﾞ: 'ヅ',
  ﾃﾞ: 'デ',
  ﾄﾞ: 'ド',
  ﾊﾞ: 'バ',
  ﾋﾞ: 'ビ',
  ﾌﾞ: 'ブ',
  ﾍﾞ: 'ベ',
  ﾎﾞ: 'ボ',
  ﾊﾟ: 'パ',
  ﾋﾟ: 'ピ',
  ﾌﾟ: 'プ',
  ﾍﾟ: 'ペ',
  ﾎﾟ: 'ポ',
  ｳﾞ: 'ヴ',
  ﾜﾞ: 'ヷ',
  ｦﾞ: 'ヺ',
  ｱ: 'ア',
  ｲ: 'イ',
  ｳ: 'ウ',
  ｴ: 'エ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｷ: 'キ',
  ｸ: 'ク',
  ｹ: 'ケ',
  ｺ: 'コ',
  ｻ: 'サ',
  ｼ: 'シ',
  ｽ: 'ス',
  ｾ: 'セ',
  ｿ: 'ソ',
  ﾀ: 'タ',
  ﾁ: 'チ',
  ﾂ: 'ツ',
  ﾃ: 'テ',
  ﾄ: 'ト',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾋ: 'ヒ',
  ﾌ: 'フ',
  ﾍ: 'ヘ',
  ﾎ: 'ホ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ﾔ: 'ヤ',
  ﾕ: 'ユ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',
  ｧ: 'ァ',
  ｨ: 'ィ',
  ｩ: 'ゥ',
  ｪ: 'ェ',
  ｫ: 'ォ',
  ｯ: 'ッ',
  ｬ: 'ャ',
  ｭ: 'ュ',
  ｮ: 'ョ',
  '｡': '。',
  '､': '、',
  ｰ: 'ー',
  '-': 'ー',
  '−': 'ー',
  '－': 'ー',
  '｢': '「',
  '｣': '」',
  '･': '・',
  '0': '０',
  '1': '１',
  '2': '２',
  '3': '３',
  '4': '４',
  '5': '５',
  '6': '６',
  '7': '７',
  '8': '８',
  '9': '９',
};

export function convertToHalfWidth(string: string) {
  let characters = getCharacters(string);
  let halfWidthString = '';
  characters.forEach((character: string) => {
    halfWidthString += mapToHankaku(character as keyof typeof zenHanMap);
  });
  return halfWidthString;
}

export function convertToFullWidth(string: string) {
  let convertString = string;
  let fullWidthString = '';
  Object.entries(kanaMap).forEach(([key, replacement]) => {
    convertString = convertString.replaceAll(key, replacement);
  });
  let characters = getCharacters(convertString);
  characters.forEach((character: string) => {
    fullWidthString += mapToZenkaku(character as keyof typeof kanaMap);
  });

  return fullWidthString;
}

export function getCharacters(string: string) {
  return string.split('');
}

export const onlyNumbers = (event: KeyboardEvent) => {
  var key = event.keyCode || event.which;
  if (key < 48 || key > 57) {
    event.preventDefault();
  }
};

export function mapToZenkaku(character: keyof typeof kanaMap) {
  if (typeof kanaMap[character] === 'undefined') {
    return character.replace(/[a-zA-Z]/g, (fullwidthChar) =>
      String.fromCharCode(fullwidthChar.charCodeAt(0) + 0xfee0)
    );
  } else {
    return kanaMap[character];
  }
}

export function mapToHankaku(character: keyof typeof zenHanMap) {
  if (typeof zenHanMap[character] === 'undefined') {
    return character;
  } else {
    return zenHanMap[character];
  }
}

export const convertManToYen = (mans: string[]): string[] =>
  mans.map((man) => {
    const manToYen =
      man === '' ? '' : isNaN(+man) ? man : (+man * 10000).toString();
    return manToYen;
  });

export const convertYenToMan = (yens: string[]): string[] =>
  yens.map((yen) => (yen && !isNaN(+yen) ? (+yen / 10000).toString() : yen));

const OBJECT_DELIMITERS = '.';
const ARRAY_START_DELIMITER = '[';
const ARRAY_END_DELIMITER = ']';

export function flattenToLodashFormat(target: {}): {} {
  const output = {} as { [key: string]: boolean };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function step(value: any, prev = '') {
    const type = Object.prototype.toString.call(value);
    const isObject = type === '[object Object]' || type === '[object Array]';
    const isArray = Array.isArray(value);

    if (!isArray && isObject) {
      Object.keys(value).forEach(function (key: string) {
        let newKey = prev ? prev + OBJECT_DELIMITERS + key : key;

        step(value[key], newKey);
      });
    } else if (isArray) {
      if (value.length === 0) {
        output[prev] = true;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value.forEach((arrayValue: any, index: number) => {
        const arrayKey =
          prev + ARRAY_START_DELIMITER + index + ARRAY_END_DELIMITER;

        step(arrayValue, arrayKey);
      });
    } else {
      output[prev] = value;
    }
  }

  step(target);

  return output;
}

export const date = (
  year: number,
  month: number
): { label: string; value: string; className?: string }[] => {
  const date = [];
  const now =
    year !== 0 && month !== 0 ? new Date(year, month, 0).getDate() : 0;
  if (now > 0) {
    for (let i = 1; i <= now; i++) {
      if (i < 10) {
        date.push({
          label: `${i}`,
          value: `0${i}`,
          className: `day-of-week-${dayjs(`${year}/${month}/${i}`).day()}`,
        });
      } else {
        date.push({
          label: `${i}`,
          value: `${i}`,
          className: `day-of-week-${dayjs(`${year}/${month}/${i}`).day()}`,
        });
      }
    }
  }
  return date;
};

export const defaultDate = (year: number, month: number) => {
  const date = [];
  const now =
    year !== 0 && month !== 0 ? new Date(year, month, 0).getDate() : 0;
  if (now > 0) {
    for (let i = 1; i <= now; i++) {
      const value = i < 10 ? `0${i}` : i;
      date.push({ label: `${i}`, value: `${value}` });
    }
  }
  return date;
};

export const convertDynamicOptions = (field: DynamicOption) =>
  field.value.map((option) => ({
    value: option.option_code.toString(),
    label: option.option_name,
  }));

export const convertDynamicMultipleOptions = (field: DynamicOption) =>
  field.value.map((option) => ({
    id: option.option_code.toString(),
    name: option.option_name,
  }));

export const findOptionName = (
  field: DynamicOption,
  formikField: OptionsEnum
) => field.value.find((val) => val.option_code.toString() === formikField);

export const findMultipleOptionName = (
  field: DynamicOption,
  formikField: MultipleOptionsEnum[]
) =>
  field.value.filter((val) =>
    formikField.includes(
      val.option_code.toString() as unknown as MultipleOptionsEnum
    )
  );

export const containsOnly = <T>(array1: Array<T>, array2: Array<T>) =>
  array2.every((elem) => array1.includes(elem));

export const getCurrentPath = (
  currentStep: string,
  hasJoinGuarantor: boolean,
  incomeTotalizer: boolean
) => {
  switch (+currentStep) {
    case 1:
      return routeNames.SPStepOne.path;
    case 2:
      return routeNames.SPStepTwo.path;
    case 3:
      return routeNames.SPStepThree.path;
    case 4:
      if (incomeTotalizer) return routeNames.SPStepFourIncomeTotalizer.path;
      if (hasJoinGuarantor) return routeNames.SPStepFourCollateralProvider.path;
      return routeNames.SPStepFour.path;
    case 5:
      if (incomeTotalizer) return routeNames.SPStepFiveIncomeTotalizer.path;
      if (hasJoinGuarantor) return routeNames.SPStepFour.path;
      return routeNames.SPStepFive.path;
    case 6:
      if (incomeTotalizer && hasJoinGuarantor)
        return routeNames.SPStepFourCollateralProvider.path;
      if (incomeTotalizer) return routeNames.SPStepFour.path;
      if (hasJoinGuarantor) return routeNames.SPStepFive.path;
      return routeNames.SPStepSix.path;
    case 7:
      if (incomeTotalizer && hasJoinGuarantor)
        return routeNames.SPStepFour.path;
      if (incomeTotalizer) return routeNames.SPStepFive.path;
      if (hasJoinGuarantor) return routeNames.SPStepSix.path;
      return routeNames.SPStepSeven.path;
    case 8:
      if (incomeTotalizer && hasJoinGuarantor)
        return routeNames.SPStepFive.path;
      if (incomeTotalizer) return routeNames.SPStepSix.path;
      if (hasJoinGuarantor) return routeNames.SPStepSeven.path;
      return routeNames.SPStepEight.path;
    case 9:
      if (incomeTotalizer && hasJoinGuarantor) return routeNames.SPStepSix.path;
      if (incomeTotalizer) return routeNames.SPStepSeven.path;
      if (hasJoinGuarantor) return routeNames.SPStepEight.path;
      return routeNames.SPStepNine.path;
    case 10:
      if (incomeTotalizer && hasJoinGuarantor)
        return routeNames.SPStepSeven.path;
      if (incomeTotalizer) return routeNames.SPStepSevenIncomeTotalizer.path;
      if (hasJoinGuarantor) return routeNames.SPStepNine.path;
      break;
    case 11:
      if (incomeTotalizer && hasJoinGuarantor)
        return routeNames.SPStepSevenIncomeTotalizer.path;
      if (incomeTotalizer) return routeNames.SPStepEight.path;
      break;
    case 12:
      if (incomeTotalizer && hasJoinGuarantor)
        return routeNames.SPStepEight.path;
      if (incomeTotalizer) return routeNames.SPStepNine.path;
      break;
    case 13:
      if (incomeTotalizer && hasJoinGuarantor)
        return routeNames.SPStepNine.path;
      break;
    default:
      break;
  }
};

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/jpg' });
}

export const getParts = (
  sentence: string,
  first: string,
  last: string
): string[] => {
  let goodParts: string[] = [];

  const allParts = sentence.split(first);

  allParts.forEach((part: string) => {
    if (part.indexOf(last) > -1) {
      const goodOne = part.split(last)[0];
      goodParts = goodParts.concat(goodOne);
    }
  });

  return goodParts;
};

export const loanTermYearLimit = (
  loanTermYearNum: string,
  birthday: string
) => {
  const age = dayjs().diff(birthday, 'year');
  return age + +loanTermYearNum > 80;
};

export const convertToMB = (fileSize: number) =>
  Math.round((fileSize / (1000 * 1000)) * 10) / 10;

export const genParamBorrowing = (index: number) => {
  return index < 0 || index > 7
    ? 'repayment_schedule_image_index__0'
    : `repayment_schedule_image_index__${index}`;
};

export function parseErrors<T>(execute: (data: T) => T, data: T) {
  try {
    return execute(data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new Error(
        error.errors
          .map(
            (error) => `${error.path.join('.')}: ${error.message.toLowerCase()}`
          )
          .join('\n')
      );
    }
    throw error;
  }
}

export function getErrorMessage(
  errors: {
    field: string;
    key: string;
    resource: string;
    options: { allow_blank: boolean; count: number };
  }[],
  typeError: string
) {
  return uniqWith(errors, (prev, next) => {
    return (
      prev.key === next.key &&
      prev.field === next.field &&
      prev.resource === next.resource
    );
  })
    .map(
      (err) =>
        ERROR_MESSAGE_KEY[`${typeError}${err.key}`]
          ?.replace('{{field}}', getFieldError(err.field))
          ?.replace('{{max_length}}', err.options?.count?.toString() || '')
      // .replace('{{number}}', '')
    )
    .filter((o) => !!o)
    .join('\n');
}

export function getFieldError(key: string): string {
  // if (SP_FIELDS_TITLE[key]) {
  //   return SP_FIELDS_TITLE[key];
  // }
  if (FIELDS_VALIDATE[key]) {
    return FIELDS_VALIDATE[key];
  }

  const item = [
    ...PERSONAL_INFORMATION,
    ...INCOME_TOTALIZER,
    ...PERSONAL_OCCUPATION,
    ...PERSONAL_RESIDENCE,
    ...BORROWING_DETAIL,
    ...PROPERTY_DETAIL,
    ...REQUIRED_FUNDS_BREAKDOWN,
    ...PROCUREMENT_BREAKDOWN,
    ...REFERRAL_AGENCY,
    ...GUARANTEE_PROVIDER,
    ...HEAD_CURRENT_BORROWING,
    ...CURRENT_BORROWING,
    ...SUB_CURRENT_BORROWING,
    ...FINANCING_PLAN,
    ...CONTACT_INFORMATION,
  ].find(({ name_en }) => {
    const keys = name_en.split('.');
    return keys[keys.length - 1] === key;
  });
  return item?.name_ja ?? key;
}

export function handleShowError(action: unknown) {
  const toastOptions: ToastOptions = {
    autoClose: false,
    className: 'admin-toast-error',
  };
  let errors = get(action, 'payload.response.data.errors', []);
  const typeError = get(action, 'type', '');
  if (
    errors?.some((err: { key: ErrorEnum }) =>
      [ErrorEnum.TOKEN_INVALID].includes(err.key)
    )
  ) {
    return;
  }
  if (
    errors?.some((err: { key: ErrorEnum }) =>
      [ErrorEnum.TOKEN_EXPIRED].includes(err.key)
    )
  ) {
    return;
  }
  if (
    errors?.some((err: { key: ErrorEnum }) =>
      [ErrorEnum.LOAN_TYPE_INVALID].includes(err.key)
    )
  ) {
    return;
  }
  errors = get(action, 'payload.errors', []);

  const error = errors?.find(
    (error: { resource: string }) => OTHER_ERROR[error.resource]
  );
  if (error) {
    toast.error(OTHER_ERROR[error.resource], { toastId: error.resource });
    return;
  }

  if (
    getErrorMessage(
      errors,
      typeError === 'Preliminaries/assignSalePerson/rejected'
        ? 'assignSalePerson_'
        : ''
    )
  ) {
    modalErrorRef.current?.open(
      'エラーが発生しました。', // note: PA bao xoa bo
      getErrorMessage(
        errors,
        typeError === 'Preliminaries/assignSalePerson/rejected'
          ? 'assignSalePerson_'
          : ''
      )
    );
    return;
  }
  const errorMessage = get(action, 'payload.message', 'エラーが発生しました。');
  toast.error(errorMessage, toastOptions);
}

export function formatTimeMessage(time: string) {
  dayjs.locale('ja');
  return dayjs(time).utcOffset(0).format('MM月DD日 (ddd) HH:mm');
}

export function convertLocalTime(time: string) {
  let date = new Date(time);
  let localDateString = date.toLocaleString();

  let localDate = new Date(localDateString);

  let year = localDate.getFullYear().toString();
  let month = (localDate.getMonth() + 1).toString().padStart(2, '0');
  let day = localDate.getDate().toString().padStart(2, '0');

  return `${year}/${month}/${day}`;
}

export function formatTimeForCheckAsync(date: Date) {
  let localDateString = date.toLocaleString();
  let localDateStringJp = dayjs(localDateString).utc();

  let year = localDateStringJp.year();
  let dayOfMonth = localDateStringJp.date().toString().padStart(2, '0');
  let month = (localDateStringJp.month() + 1).toString().padStart(2, '0');
  let hour = localDateStringJp.hour().toString().padStart(2, '0');
  let minute = localDateStringJp.minute().toString().padStart(2, '0');
  let second = localDateStringJp.second().toString().padStart(2, '0');

  return `${year}-${month}-${dayOfMonth} ${hour}:${minute}:${second}`;
}

export function convertManYenToYen(manYen: number) {
  return manYen * 10000;
}

export function getFileExtFromUrl(url: string) {
  if (!url) return '';
  const parts = url.split('/');
  const filename = parts[parts.length - 1];
  const nameParts = filename.split('.');
  return nameParts[nameParts.length - 1] ?? url;
}

export const downloadFile = (url: string, filename: string) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

export function isWeekend(dateString: string) {
  const date = new Date(dateString);
  const dayOfWeek = date.getDay();
  return dayOfWeek === 6 || dayOfWeek === 0;
}

export function convertImage(
  images: {
    id: string;
    filename: string;
    url: string;
    isDelete: boolean;
  }[],
  id: string
) {
  return images.map((image) => {
    if (image.id === id) {
      return {
        id: image.id,
        filename: image.filename,
        url: image.url,
        isDelete: true,
      };
    } else {
      return image;
    }
  });
}

export function convertImageResponse(
  images: {
    id: string;
    filename: string;
    url: string;
    hiden: boolean;
  }[]
) {
  return images.map((image) => {
    return {
      id: image.id,
      filename: image.filename,
      url: image.url,
      hiden: image.hiden,
      isDelete: false,
    };
  });
}

export function convertDateTime(time: string | undefined, format: string) {
  if (!time) {
    return 'ー';
  }
  return dayjs(time).format(format);
}

export const genTitleFilePreview = (title: string) => {
  switch (title) {
    case '本人確認書類':
      return `A`;
    case '健康保険証':
      return `B`;
    case '収入に関する書類':
      return `C`;
    case '非上場企業の役員の方の書類':
      return `D`;
    case '雇用契約に関する書類':
      return `E`;
    case '親族経営の会社等にご勤務の方に関する書類':
      return `F`;
    case '物件についての書類':
      return `G`;
    case '在留カード':
      return `H`;
    case '返済予定表・利用明細書':
      return `I`;
    case '提携会社の担当者名刺':
      return `J`;
    case 'その他':
      return `K`;
    default:
      return '';
  }
};

export function getUserIdFromToken(token: string): string {
  const data = jwtDecode<{ sub: string }>(token);
  return data?.sub || '';
}

export const getParamKeysPullDown = (
  keys: string[],
  title: string,
  isCardNumberImage: boolean,
  isDriverLicenseImage: boolean
  // isFirstWithholdingSlipFile: boolean
) => {
  let paramKeys: string[] = keys;
  if (title === '本人確認書類') {
    // A
    paramKeys = isCardNumberImage
      ? ['card_number_front_image']
      : isDriverLicenseImage
      ? ['driver_license_front_image', 'driver_license_back_image']
      : ['resident_register_front_image', 'resident_register_back_image'];
  } else if (title === '収入に関する書類') {
    // C
    paramKeys = [
      'first_withholding_slip_file',
      'second_withholding_slip_file',
      'first_income_file',
      'second_income_file',
      'third_income_file',
    ];
  }
  return paramKeys;
};

export function supportDvh(val: string) {
  return val.replace('d', CSS.supports('height: 100dvh') ? 'd' : '');
}

export function checkingExistFile(files: Array<File | SPImage>) {
  const promises = files.map((image) => {
    const type =
      image instanceof File ? image.type : getFileExtFromUrl(image.url);
    if (type.includes('pdf')) {
      if (image instanceof File) {
        return checkIfPDFExists(URL.createObjectURL(image));
      } else {
        return checkIfPDFExists(image.url);
      }
    } else {
      if (image instanceof File) {
        return checkIfImageExists(image);
      } else {
        return checkIfImageExists(image.url);
      }
    }
  });
  return Promise.all(promises).then((values) => {
    return values.every((element) => element);
  });
}

export function checkIfImageExists(url: string | File): Promise<boolean> {
  if (!(url instanceof File)) return Promise.resolve(true);
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(url);
    if (img.complete) {
      return resolve(true);
    }
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
}
export function checkIfPDFExists(url: string): Promise<boolean> {
  if (!url.startsWith('blob:')) return Promise.resolve(true);
  return fetch(url, { method: 'GET' })
    .then(() => true)
    .catch(() => false);
}

export function checkFileExisted(src: string) {
  return fetch(src, { method: 'GET' })
    .then((data) => {
      return data.ok;
    })
    .catch(() => false);
}

export function checkDateValidate(
  value: string,
  require: boolean,
  format = 'YYYY/MM/DD'
) {
  if (!require && !value) return true;
  return dayjs(value, format, true).isValid();
}

/**
 *
 * @param startedAt
 * @param endedAt
 * @param targetDate
 */
export function isBetweenDate(
  startedAt: Dayjs,
  endedAt: Dayjs,
  targetDate?: Dayjs
) {
  if (isEmpty(startedAt) || isEmpty(endedAt)) return false;
  const target = targetDate?.toDate() || dayjs().toDate();
  return (
    startedAt.startOf('month').toDate() < target &&
    endedAt.endOf('month').toDate() > target
  );
}
