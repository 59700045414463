import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Rectangle: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.02099 12.667C1.67366 12.667 1.38899 12.5557 1.16699 12.333C0.944326 12.111 0.832993 11.8263 0.832993 11.479V1.52101C0.832993 1.17367 0.944326 0.889008 1.16699 0.667008C1.38899 0.444341 1.67366 0.333008 2.02099 0.333008H6.35399V1.00001H2.02099C1.88166 1.00001 1.75999 1.05201 1.65599 1.15601C1.55199 1.26001 1.49999 1.38167 1.49999 1.52101V11.479C1.49999 11.6183 1.55199 11.74 1.65599 11.844C1.75999 11.948 1.88166 12 2.02099 12H11.979C12.1183 12 12.24 11.948 12.344 11.844C12.448 11.74 12.5 11.6183 12.5 11.479V7.14601H13.167V11.479C13.167 11.8263 13.0557 12.111 12.833 12.333C12.611 12.5557 12.3263 12.667 11.979 12.667H2.02099ZM5.10399 8.89601L4.60399 8.39601L12.021 1.00001H8.99999V0.333008H13.167V4.50001H12.5V1.47901L5.10399 8.89601Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
