import { FC, useCallback, useContext, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import {
  Icons,
  SPButton,
  SPImage,
  SPModal,
  SPUploadImageItem,
} from 'components';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { ImageContext } from 'context';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { spStepSevenSelector } from 'pages/SPStepSeven/selectors';
import { get } from 'lodash';
import { spStepOneSelector } from 'pages/SPStepOne/selectors';
import { supportDvh } from 'utils';

const SPUpload: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { otherDocumentFile, setOtherDocumentFile } = useContext(ImageContext);
  const spStepOneValues = useSelector(spStepOneSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const { application_number } = useSelector(spStepTenSelector);
  const [open, setOpen] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setOpen(false);
    navigate(routeNames.Top.path);
  }, [navigate]);

  const onSubmitForm = async () => {
    const data = new FormData();

    const { id } =
      spStepOneValues.p_application_header.p_applicant_people_attributes;
    const spStepSevenImageValues = spStepSevenValues.p_applicant_people;
    const imageStepSeven = {
      other_document_file: otherDocumentFile,
    };
    if (!!application_number && !!id) {
      data.append(
        'p_application_header[p_applicant_people_attributes][0][id]',
        id
      );
      //STEP 7 IMAGES
      Object.keys(imageStepSeven).forEach((key) => {
        const imageStepSevenIds = get(imageStepSeven, key, []).map(
          (file: SPImage | File) => {
            if (file instanceof File) return '';
            return file.id;
          }
        );

        const imageStepSevenRemovedIds = get(spStepSevenImageValues, key, [])
          .filter((file: SPImage) => !imageStepSevenIds.includes(file.id))
          .map((file: SPImage) => file.id);

        imageStepSevenRemovedIds.forEach((file: string) => {
          data.append(
            `p_application_header[p_applicant_people_attributes][0][${key}][]`,
            file
          );
        });
      });
    }

    //STEP 7 IMAGES
    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        if (file instanceof File) {
          data.append(
            `p_application_header[p_applicant_people_attributes][0][${key}][]`,
            file
          );
        }
      });
    });

    //CHECK IS UPDATE PRELIMINARY
    if (!!application_number) {
      const result = await dispatch(updatePreliminary(data));
      if (updatePreliminary.fulfilled.match(result)) {
        setOpen(true);
      }
    }
  };

  return (
    <SPStepLayout
      hasStepBar={false}
      hasModalSaveDraft={false}
      footer={{
        left: {
          onClick: () => {
            navigate(routeNames.TopUpload.path);
          },
        },
        right: {
          children: '送信する',
          onClick: () => onSubmitForm(),
        },
      }}
    >
      <Stack
        sx={{
          width: '100%',
          minHeight: supportDvh('calc(100dvh - 113px)'),
          bgcolor: 'sp_bg_gray',
        }}
      >
        <Stack textAlign="center" sx={{ my: 7 }}>
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
          >
            書類の再提出
          </Typography>

          <Stack sx={{ px: 4 }}>
            <SPUploadImageItem
              variant="vertical"
              images={otherDocumentFile}
              setImages={setOtherDocumentFile}
            />
          </Stack>
        </Stack>
      </Stack>
      <SPModal
        open={open}
        onClose={onClose}
        disableBackDropClick
        title="書類の送信完了"
        icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
        footer={
          <SPButton
            sx={{ bgcolor: 'sp_primary_100_main' }}
            onClick={onClose}
            endIcon={<Icons.ArrowForward stroke="white" />}
          >
            <Typography variant="SP_multiple_checkbox_label" color="main_white">
              TOPへ
            </Typography>
          </SPButton>
        }
      >
        <Typography
          variant="liststyle"
          color="b_333"
          sx={{
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            letterSpacing: '0.6px',
            lineHeight: '170%',
          }}
        >
          {`ありがとうございます。\n書類の送信が完了しました。\n審査結果まで\nもうしばらくお待ちください。`}
        </Typography>
      </SPModal>
    </SPStepLayout>
  );
};

export default SPUpload;
