import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPEqual: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 12 8" {...props}>
      <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0.839966V2.75997H11.44V0.839966H0ZM0 5.23997V7.15997H11.44V5.23997H0Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
