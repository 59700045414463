import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPLogoStart: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 278 245" {...props}>
      <svg
        width="278"
        height="245"
        viewBox="0 0 278 245"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_10456_232082)">
          <path
            d="M261.6 5.57999H15.93C9.89354 5.57999 5 10.4735 5 16.51V229.18C5 235.216 9.89354 240.11 15.93 240.11H261.6C267.636 240.11 272.53 235.216 272.53 229.18V16.51C272.53 10.4735 267.636 5.57999 261.6 5.57999Z"
            fill="white"
          />
        </g>
        <path
          d="M90.5107 157.76C93.7807 156.58 94.7707 154.68 95.4407 151.92C94.2007 151.44 92.5807 150.95 90.8707 150.8C89.8407 155.04 89.0007 157.77 86.1307 157.77C84.3007 157.77 82.9707 156.55 82.9707 154.52C82.9707 153.59 83.3107 149.49 89.5407 149.25C89.8107 147.88 90.0507 146.19 90.1707 144.83L84.2107 144.89V143.23H92.0907C91.8407 145.13 91.7907 145.55 91.1607 149.3C92.7607 149.43 94.0207 149.74 95.7507 150.31C96.0407 148.44 96.1307 147.09 96.1707 146.22L97.9807 146.35C97.8507 147.72 97.6907 149.38 97.4107 150.9C97.4707 150.92 98.5707 151.34 99.7507 151.99L99.1807 153.7C98.3207 153.19 98.0007 153.01 97.0507 152.6C96.5907 154.47 95.6207 157.64 91.5707 159.09L90.5207 157.78L90.5107 157.76ZM86.4907 151.27C85.2507 151.88 84.4707 153.12 84.4707 154.41C84.4707 154.77 84.5707 156.26 86.0107 156.26C87.7207 156.26 88.2407 154.83 89.2507 150.7C88.1307 150.74 87.3307 150.85 86.4907 151.27Z"
          fill="#1E1E1E"
        />
        <path
          d="M105.351 145.73C104.911 147.18 104.761 148.28 104.761 151.08C106.701 149.54 108.831 148.74 111.311 148.74C115.731 148.74 116.541 151.58 116.541 153.16C116.541 157.73 112.141 158.76 108.071 158.76C106.321 158.76 105.201 158.61 104.421 158.49L104.231 156.82C105.511 157.05 106.671 157.16 107.941 157.16C110.571 157.16 112.851 156.65 113.991 155.39C114.521 154.82 114.731 153.92 114.731 153.14C114.731 151.66 113.721 150.28 111.211 150.28C108.701 150.28 106.111 151.36 104.851 153.02C104.791 153.21 104.771 153.27 104.751 153.36L103.061 153.3C103.061 152.65 103.121 149.8 103.141 149.23C103.221 147.46 103.391 146.41 103.631 145.42L105.341 145.72L105.351 145.73ZM113.591 145.71C111.321 144.8 108.531 144.43 105.961 144.34L106.261 142.7C107.901 142.78 111.151 142.95 114.061 143.9L113.581 145.71H113.591Z"
          fill="#1E1E1E"
        />
        <path
          d="M121.84 143.85C121.69 144.99 121.44 146.72 121.44 149.29C121.44 152.24 121.67 156.41 123.8 156.41C124.66 156.41 125.65 155.63 126.96 152.2L128.37 153C127.74 154.77 126.87 156.37 125.8 157.44C125.34 157.9 124.64 158.35 123.67 158.35C120.59 158.35 119.73 154.09 119.73 149.14C119.73 147.07 119.92 144.99 120.03 143.7L121.84 143.85ZM133.49 156.55C133.53 156.06 133.57 155.48 133.57 154.61C133.57 149.64 132.09 146.9 130.11 145.28L131.42 144.27C133.69 146.17 135.65 148.86 135.38 156.47L133.5 156.55H133.49Z"
          fill="#1E1E1E"
        />
        <path
          d="M144.87 143.91C143.94 152.13 141.29 156.36 140.03 158.36L138.36 157.47C139.94 155.01 142.15 151.3 143.06 143.65L144.87 143.92V143.91ZM152.64 158.01C151.9 153.52 150.6 149.1 148.07 144.3L149.67 143.54C150.89 145.79 153.4 150.77 154.52 157.55L152.64 158.01ZM152.11 142.4C152.62 143.09 153.27 144.06 154.09 145.96L152.8 146.72C152.19 145.18 151.79 144.4 150.92 143.08L152.12 142.39L152.11 142.4ZM154.72 141.24C155.5 142.29 156 143.24 156.68 144.8L155.39 145.56C154.82 144.06 154.38 143.24 153.51 141.92L154.73 141.23L154.72 141.24Z"
          fill="#1E1E1E"
        />
        <path
          d="M164.671 148.28C163.551 147.52 161.551 146.4 158.811 145.54L159.671 144.02C163.041 145.03 165.131 146.38 165.631 146.68L164.681 148.28H164.671ZM159.211 156.54C168.841 156.03 171.681 149.29 172.991 145.02L174.471 146.12C173.561 148.82 170.511 157.71 159.551 158.44L159.211 156.54Z"
          fill="#1E1E1E"
        />
        <path
          d="M177.69 157.36C181.52 156.52 188.05 154.31 189.32 145.39H182.56C181.3 147.37 179.88 149.04 177.57 150.59L176.49 149.3C178.32 148.06 180.13 146.39 181.42 143.82H191.32C190.48 153.47 185.1 157.49 178.41 158.99L177.69 157.37V157.36Z"
          fill="#1E1E1E"
        />
        <path
          d="M149.341 46.32C149.531 47.77 149.731 49.22 149.891 50.67C150.141 52.98 150.121 52.97 152.021 51.63C154.731 49.72 157.461 47.84 160.191 45.96C160.731 45.59 161.001 45.32 160.671 44.55C159.921 42.81 159.831 40.95 160.621 39.18C161.771 36.6 164.221 35.74 166.791 36.94C169.861 38.37 171.461 43.07 169.881 46.01C168.291 48.96 164.841 49.53 162.491 47.14C161.781 46.42 161.391 46.33 160.591 47.02C157.381 49.77 154.321 52.66 151.331 55.64C150.981 55.99 150.921 56.32 151.041 56.8C153.511 67.36 157.611 77.18 164.341 85.77C169.271 92.07 175.261 97.16 182.201 101.14C184.441 102.42 186.741 103.56 189.171 104.68C188.401 105.17 187.581 105.28 186.801 105.49C182.031 106.78 177.201 107.65 172.241 107.55C166.161 107.43 160.591 105.86 156.061 101.56C152.591 98.27 150.461 94.16 149.111 89.64C146.881 82.17 146.501 74.49 146.821 66.76C146.911 64.49 147.061 62.22 147.191 59.82C146.131 60.58 145.351 61.53 144.501 62.38C134.911 72.02 125.541 81.86 116.951 92.42C111.731 98.85 106.781 105.47 102.831 112.78C100.951 116.27 99.3506 119.87 98.6106 123.8C98.1306 126.34 98.1306 128.86 99.1006 131.31C99.1506 131.43 99.1506 131.56 99.2006 131.81C94.8306 131.17 90.6706 130.3 87.5406 126.96C85.2106 124.47 84.9506 121.39 85.2306 118.21C85.6906 112.96 87.9506 108.38 90.7706 104.05C95.3206 97.07 101.051 91.11 107.131 85.48C114.091 79.04 121.511 73.14 129.121 67.49C135.011 63.12 141.031 58.93 147.121 54.83C147.601 54.51 147.841 54.16 147.921 53.56C148.261 51.14 148.681 48.74 149.071 46.33C149.161 46.33 149.251 46.31 149.341 46.3V46.32Z"
          fill="#111C57"
        />
        <path
          d="M140.271 73.27C139.531 75.92 138.641 78.5 137.931 81.14C136.861 85.1 136.111 89.1 136.221 93.22C136.371 98.75 138.031 103.63 142.551 107.17C145.741 109.67 149.471 110.9 153.401 111.62C155.141 111.94 156.901 112.16 158.831 112.26C158.111 112.82 157.351 112.93 156.661 113.16C151.501 114.83 146.291 116.32 140.881 116.96C137.141 117.4 133.391 117.55 129.801 116.06C126.291 114.6 124.571 111.82 124.171 108.15C123.631 103.11 125.161 98.48 127.171 93.99C130.141 87.36 134.171 81.36 138.531 75.57C139.111 74.8 139.691 74.03 140.271 73.26V73.27Z"
          fill="#111C57"
        />
        <path
          d="M34.9503 191.85H37.3903L34.7103 198.14H35.7703V213.02H33.5103V200.9L33.2203 201.52H30.7803L34.9503 191.84V191.85ZM46.3802 209.94H52.8203V212.08H37.6602V209.94H44.1003V205.3H39.6103V203.16H44.1003V198.55H38.3903V196.41H52.0803V198.55H46.3703V203.16H50.8603V205.3H46.3703V209.94H46.3802ZM46.7503 195H44.2003C44.0303 193.9 42.8703 192.42 42.8703 192.42H45.4203C45.4203 192.42 46.7503 194.03 46.7503 195Z"
          fill="url(#paint0_linear_10456_232082)"
        />
        <path
          d="M214.9 195.17V192.31H212.25V195.17H204.55V207.12H212.25V213.07H214.9V207.12H222.6V195.17H214.9ZM206.99 199.71V197.26H212.25V199.71H206.99ZM206.99 204.77V202.27H212.25V204.77H206.99ZM220.14 197.26V199.71H214.89V197.26H220.14ZM220.14 202.28V204.78H214.89V202.28H220.14Z"
          fill="url(#paint1_linear_10456_232082)"
        />
        <path
          d="M239.6 202.67L243.43 208.14H246.6L242.23 201.96C242.23 201.96 240.85 200.33 240.85 199.01V194.88C240.85 193.91 240.06 193.12 239.09 193.12H237.12V195.47H238.25V199C238.25 200.14 237.12 201.71 237.11 201.74L232.9 208.13H235.95L239.61 202.66L239.6 202.67Z"
          fill="url(#paint2_linear_10456_232082)"
        />
        <path
          d="M228.25 196.82L228.23 197.02H231L231.02 196.86C231.16 195.79 230.07 194.02 230.03 193.95L229.98 193.86H227.17L227.31 194.13C227.6 194.66 228.32 196.16 228.25 196.83V196.82Z"
          fill="url(#paint3_linear_10456_232082)"
        />
        <path
          d="M233.95 210.09C233.82 210.09 231.41 210 231.41 207.96V199.07H226.83V201.74H229.11V208.38L226.84 210V212.84L229.93 210.92C230.66 211.69 231.78 212.53 233.47 212.53H246.76V210.08H233.95V210.09Z"
          fill="url(#paint4_linear_10456_232082)"
        />
        <path
          d="M79.6807 193.1V211.76H95.5406V193.1H79.6807ZM82.0907 209.57V195.28H93.1006V209.57H82.0907Z"
          fill="url(#paint5_linear_10456_232082)"
        />
        <path
          d="M113.33 201.25H99.1699V203.7H113.33V201.25Z"
          fill="url(#paint6_linear_10456_232082)"
        />
        <path
          d="M128.93 193.9L128.91 194.21C128.67 198.33 127.92 201.57 126.69 203.84C125.12 206.8 121.73 208.74 118.12 209.6L117.8 209.68L118.43 212.08L118.74 212.01C122.95 211.01 126.84 208.76 128.78 205.34C130.29 202.67 131.2 198.97 131.47 194.35L131.49 194.04L128.91 193.91H128.92L128.93 193.9Z"
          fill="url(#paint7_linear_10456_232082)"
        />
        <path
          d="M120.76 197.67L121.711 195.41L121.41 195.29C119.78 194.66 118.111 194.19 116.311 193.86L115.991 193.8L115.521 196.19L115.841 196.25C117.441 196.54 118.951 196.97 120.461 197.55L120.76 197.67Z"
          fill="url(#paint8_linear_10456_232082)"
        />
        <path
          d="M196.6 202.22H185.21V210.87H182.73V213.08H199.03V210.87H196.6V202.22ZM194.3 209.93V210.87H187.52V209.93H194.3ZM194.3 207.18V208.09H187.52V207.18H194.3ZM194.3 204.43V205.34H187.52V204.43H194.3Z"
          fill="url(#paint9_linear_10456_232082)"
        />
        <path
          d="M200.33 201.08C196.86 199.51 194.52 197.63 193.39 195.49H199.62V193.52H192.03V192.02H189.73V193.52H182.16V195.49H188.31C187.07 197.58 184.03 199.88 181.7 201.03L181.41 201.17L182.43 203.09L182.72 202.95C185.07 201.79 188.18 199.54 189.72 197.45V201.29H192.02V197.5C193.96 200.34 197.74 202.29 199.33 203.03L199.62 203.17L200.61 201.22L200.32 201.08H200.33Z"
          fill="url(#paint10_linear_10456_232082)"
        />
        <path
          d="M58.4306 195.68H72.6506L73.8406 197.16L75.4206 195.12L74.1906 193.49H66.3506C66.1506 192.96 65.8806 192.43 65.5506 191.91L62.8606 192.03L63.0506 192.27C63.3406 192.63 63.6106 193.05 63.8506 193.5H56.1406V198.44H58.4406V195.71L58.4306 195.68Z"
          fill="url(#paint11_linear_10456_232082)"
        />
        <path
          d="M72.68 207.81C72.58 208.84 72.48 209.48 72.37 209.74C72.15 210.31 71.7199 210.57 71.0399 210.57H66.0999C65.5899 210.57 65.27 210.43 65.09 210.14C65 210 64.89 209.69 64.89 208.96V205.47L73.2299 205.13L73.1299 202.85L64.9 203.16V200.4C66.59 200.06 71.2799 199.24 71.3199 199.22L71.49 199.19L71.2899 197L58.5 199.11L58.81 201.35L59 201.33C59.13 201.32 61.5 200.99 62.52 200.86V203.27L55.71 203.48L55.81 205.76L62.5099 205.55V208.97C62.5099 211.51 63.75 212.85 66.09 212.85H71.03C72.48 212.85 73.5499 212.32 74.1899 211.28C74.6299 210.58 74.9099 209.49 75.0399 208.03L75.0699 207.72L72.7 207.49L72.67 207.8L72.68 207.81Z"
          fill="url(#paint12_linear_10456_232082)"
        />
        <path
          d="M154.811 211.16C154.081 210.85 153.061 209.7 152.581 209.05L150.141 205.84L154.291 200.39H154.321V200.36L154.401 200.26L154.321 200.21V197.88H144.691V194.84H154.191V192.59H142.291V200.23C142.291 200.23 142.121 202.13 141.731 203.48C141.381 204.7 140.481 206.67 140.471 206.69L140.391 206.85L142.651 208.01L142.741 207.83C142.781 207.74 143.821 205.65 144.131 204.39C144.311 203.61 144.441 202.89 144.511 202.46L147.081 205.84L144.671 209.01C144.531 209.16 143.321 210.51 142.111 211.25L141.951 211.35L143.271 213.36L143.431 213.26C145.081 212.26 146.581 210.52 146.601 210.5L148.611 207.86L150.561 210.42C150.721 210.65 152.211 212.66 153.831 213.34L154.011 213.41L155.001 211.23L154.821 211.16H154.811ZM151.201 200.41L148.601 203.83L146.021 200.43L151.201 200.4V200.41Z"
          fill="url(#paint13_linear_10456_232082)"
        />
        <path
          d="M161.22 194.89H175.35V196.56H177.76V192.92H169.51V191.84H167.06V192.92H158.82V196.56H161.22V194.89Z"
          fill="url(#paint14_linear_10456_232082)"
        />
        <path
          d="M177.74 204.03C176.42 203.58 174.85 203.05 173.82 202.44H176.98V200.18H173.38L174.56 198.32H171.76L170.96 200.18H169.11V197.58L173.39 197.27V195.28L162.19 195.91V197.89L166.85 197.65V200.17H165.01L164.2 198.52H161.36L162.55 200.17H158.8V202.43H162.27C161.24 203.04 159.67 203.57 158.35 204.02L158.17 204.08L158.94 206.15L159.12 206.09C163.06 204.75 165.01 203.03 165.59 202.43H166.84V205.42H161.01V212.87H174.93V205.42H169.1V202.43H170.49C171.07 203.02 173.02 204.75 176.96 206.09L177.14 206.15L177.91 204.08L177.73 204.02L177.74 204.03ZM166.82 209.9V211.13H163.43V209.9H166.82ZM166.82 207.35V208.36H163.43V207.35H166.82ZM169.17 208.36V207.35H172.56V208.36H169.17ZM169.17 211.14V209.91H172.56V211.14H169.17Z"
          fill="url(#paint15_linear_10456_232082)"
        />
        <path
          d="M138.33 191.85H140.76L138.09 198.14H139.15V213.02H136.88V200.9L136.6 201.52H134.16L138.33 191.84V191.85Z"
          fill="url(#paint16_linear_10456_232082)"
        />
        <defs>
          <filter
            id="filter0_d_10456_232082"
            x="0.18"
            y="0.759986"
            width="277.17"
            height="244.17"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.32549 0 0 0 0 0.180392 0 0 0 0 0.556863 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_10456_232082"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_10456_232082"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_10456_232082"
            x1="26.1603"
            y1="152.69"
            x2="65.8003"
            y2="276.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_10456_232082"
            x1="182.1"
            y1="102.78"
            x2="221.75"
            y2="226.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_10456_232082"
            x1="203.8"
            y1="95.8399"
            x2="243.451"
            y2="219.73"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_10456_232082"
            x1="197.95"
            y1="97.7189"
            x2="237.591"
            y2="221.6"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_10456_232082"
            x1="200.17"
            y1="96.9999"
            x2="239.81"
            y2="220.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_10456_232082"
            x1="67.4607"
            y1="139.47"
            x2="107.101"
            y2="263.36"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_10456_232082"
            x1="84.3599"
            y1="134.06"
            x2="124"
            y2="257.95"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_10456_232082"
            x1="101.16"
            y1="128.69"
            x2="140.8"
            y2="252.58"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_10456_232082"
            x1="97.5105"
            y1="129.85"
            x2="137.15"
            y2="253.74"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_10456_232082"
            x1="158.52"
            y1="110.33"
            x2="198.171"
            y2="234.22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_10456_232082"
            x1="162.29"
            y1="109.13"
            x2="201.93"
            y2="233.01"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_10456_232082"
            x1="49.3006"
            y1="145.28"
            x2="88.941"
            y2="269.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_10456_232082"
            x1="46.12"
            y1="146.3"
            x2="85.7599"
            y2="270.19"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_10456_232082"
            x1="122.401"
            y1="121.89"
            x2="162.04"
            y2="245.78"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_10456_232082"
            x1="142.88"
            y1="115.341"
            x2="182.52"
            y2="239.22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_10456_232082"
            x1="140.1"
            y1="116.22"
            x2="179.75"
            y2="240.11"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_10456_232082"
            x1="113.92"
            y1="124.6"
            x2="153.57"
            y2="248.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6264E6" />
            <stop offset="1" stopColor="#232D7A" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
