import { Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { FormikProvider, getIn, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  checkingExistFile,
  convertDynamicOptions,
  convertManToYen,
  flattenToLodashFormat,
  supportDvh,
  zeroPad,
} from 'utils';
import {
  Icons,
  InputSelectProps,
  SPCheckboxButton,
  SPCheckboxMultipleButton,
  SPFormItem,
  SPImage,
  SPInputDate,
  SPInputDateTime,
  SPInputField,
  SPInputSelect,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPSaveImageModal,
} from 'components';
import {
  BorrowerIncomeType,
  CODEBANK,
  CoOwnerType,
  DesiredMonthlyBonusType,
  GeneralIncomeConfirmation,
  LandAdvanceType,
  LoanTargetZeroType,
  LoanType,
  MESSAGE_REGEX,
  ModalName,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PurposeType,
  landAdvance,
  loanTargetZero,
  modalName,
} from 'constant';
import { SPStepLayout } from 'containers';
import {
  isIncomeTotalizerInitSelector,
  isJoinGuarantorInitSelector,
  publicHolidaysSelector,
  spCurrentStepSelector,
} from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import { spStepFiveSelector } from 'pages/SPStepFive/selectors';
import kanjidate from 'kanjidate';
import { dayjs } from 'libs/dayjs';
import { get, isEmpty, update } from 'lodash';
import {
  resetLandHouseBorrower,
  resetMCJStepFive,
} from 'pages/SPStepFive/slice';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import {
  addInitialCollateralProviderState,
  resetStepFourCollateralProviderForm,
} from 'pages/SPStepFourCollateralProvider/slice';
import { resetStepFourIncomeForm } from 'pages/SPStepFourIncomeTotalizer/slice';
import { resetStepSevenIncomeForm } from 'pages/SPStepSevenIncomeTotalizer/slice';
import { clearPairLoanAmount, resetStepSixForm } from 'pages/SPStepSix/slice';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { spStepTwoSelector } from 'pages/SPStepTwo/selectors';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SPStepOneForm } from 'types';
import {
  dynamicOptionsSelectors,
  masterBankSelectors,
} from '../SPStartScreen/selectors';
import { spStepEightSelector } from '../SPStepEight/selectors';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { resetStepFiveIncomeForm } from '../SPStepFiveIncomeTotalizer/slice';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepSixSelector } from '../SPStepSix/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import { resetReasonAcquireHome } from '../SPStepFour/slice';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { SPCombinedIncomeModal } from './components/SPAboutCombinedIncomeModal';
import { SPAboutLandAdvancePlanModal } from './components/SPAboutLandAdvancePlanModal';
import { SPMortgagePlusModal } from './components/SPAboutMortgagePlusModal';
import { SPAboutPairLoanModal } from './components/SPAboutPairLoanModal';
import { SPCalculatorModal } from './components/SPCalculatorModal';
import { SPCollateralProviderModal } from './components/SPCollateralProviderModal';
import { SPDifferenceModal } from './components/SPDifferenceModal';
import { SPJointGuarantorModal } from './components/SPJointGuarantorModal';
import { SPModalChangeLoanType } from './components/SPModalChangeLoanType';
import { SPSelectPairLoanModal } from './components/SPSelectPairLoanModal';
import {
  checkIncomeTotalizer,
  convertSPStepOneSelector,
  spStepOneApplicantPeopleSelector,
  spStepOneSelector,
} from './selectors';
import { saveForm } from './slice';
import { validationSchema } from './validationSchema';
import { validationSchemaSubmit } from './validationSchemaSubmit';

type RadioItems = {
  value: string;
  label: string;
};

const SPStepOnePage: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isBlurred, setIsBlurred] = useState(false);
  const loanTermRef = useRef<HTMLDivElement>(null);
  const { application_number } = useSelector(spStepTenSelector);
  const currentStep = useSelector(spCurrentStepSelector);
  const initialValues = useSelector(spStepOneSelector);
  const applicantPeople = useSelector(spStepOneApplicantPeopleSelector);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const publicHolidays = useSelector(publicHolidaysSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const convertedFields = useSelector(convertSPStepOneSelector);
  const masterBank = useSelector(masterBankSelectors);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const isJoinGuarantorInit = useSelector(isJoinGuarantorInitSelector);
  const isIncomeTotalizerInit = useSelector(isIncomeTotalizerInitSelector);

  // const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const {
    residenceFile,
    residenceIncomeFile,
    residenceFileBackImage,
    residenceIncomeFileBackImage,
    repaymentScheduleImage,
    businessCard,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
    setFinancialStatement1File2,
    setFinancialStatement2File2,
    setFinancialStatement3File2,
    setFirstWithholdingSlipFile2,
    setSecondWithholdingSlipFile2,
    setOtherDocumentFile2,
    setEmploymentAgreementFile2,
    setBusinessTaxReturn1File2,
    setBusinessTaxReturn2File2,
    setBusinessTaxReturn3File2,
    setFirstIncomeFile2,
    setSecondIncomeFile2,
    setThirdIncomeFile2,
    setDriverLicenseFrontImage2,
    setDriverLicenseBackImage2,
    setCardNumberFrontImage2,
    setCardNumberBackImage2,
    setResidentRegisterFrontImage2,
    setResidentRegisterBackImage2,
    setResidenceIncomeFile,
    setResidenceIncomeFileBackImage,
    setInsuranceFile2,
    setInsuranceFileBackImage2,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [modalHelp, setModalHelp] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [openSelectPairLoanModal, setOpenSelectPairLoanModal] =
    useState<boolean>(false);
  const { updateFormOverride } = useUpdateForm();
  const [unSelectPairLoanModal, setUnSelectPairLoanModal] = useState<{
    open: boolean;
    value: string;
  }>({ open: false, value: '' });
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const navigate = useNavigate();

  // @ts-ignore
  const previousPathName = !!location.state ? location.state.pathName : '';

  const convertCurrency = useCallback((values: SPStepOneForm) => {
    const [
      temporary_desired_loan_amount,
      halfyear_bonus,
      second_temporary_desired_loan_amount,
      second_halfyear_bonus,
    ] = convertManToYen([
      values.p_application_header.p_borrowing_details_attributes[0]
        .temporary_desired_loan_amount,
      values.p_application_header.p_borrowing_details_attributes[0]
        .halfyear_bonus,
      values.p_application_header.p_borrowing_details_attributes[1]
        ?.temporary_desired_loan_amount,
      values.p_application_header.p_borrowing_details_attributes[1]
        ?.halfyear_bonus,
    ]);

    const convertedBorrowingDetail =
      values.p_application_header.p_borrowing_details_attributes.map(
        (borrowing_detail, index) => ({
          ...borrowing_detail,
          temporary_desired_loan_amount: !index
            ? temporary_desired_loan_amount
            : second_temporary_desired_loan_amount,
          halfyear_bonus: !index ? halfyear_bonus : second_halfyear_bonus,
        })
      );
    return {
      ...values,
      p_application_header: {
        ...values.p_application_header,
        p_borrowing_details_attributes: convertedBorrowingDetail,
      },
    };
  }, []);

  const birthday = spStepTwoValues?.p_applicant_people?.birthday;

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      p_application_header: {
        ...initialValues.p_application_header,
        loan_target_zero: !!initialValues.p_application_header.loan_target_zero
          ? initialValues.p_application_header.loan_target_zero
          : LoanTargetZeroType.ZERO,
        p_borrowing_details_attributes:
          initialValues.p_application_header.p_borrowing_details_attributes.map(
            (borrowing_detail, index) => ({
              ...borrowing_detail,
              temporary_desired_loan_amount: !index
                ? convertedFields.temporary_desired_loan_amount
                : convertedFields.second_temporary_desired_loan_amount,
              halfyear_bonus: !index
                ? convertedFields.halfyear_bonus
                : convertedFields.second_halfyear_bonus,
            })
          ),
      },
    },
    enableReinitialize: true,
    validationSchema: !!application_number
      ? validationSchemaSubmit(birthday, publicHolidays)
      : validationSchema(birthday, publicHolidays),
    async onSubmit(values, { setSubmitting }) {
      let cloneApplicantPeople = [...applicantPeople];
      cloneApplicantPeople[0] =
        values.p_application_header.p_applicant_people_attributes;
      // if (
      //   !loanTermYearNum ||
      //   // !loanTermMonthNum ||
      //   (!!loanTermYearNum &&
      //     // !!loanTermMonthNum &&
      //     +totalMonthBirth + +loanTermYearNum * 12 >
      //       // + +loanTermMonthNum + 1
      //       80 * 12)
      // ) {
      //   loanTermRef.current?.scrollIntoView({ behavior: 'smooth' });
      // }

      if (
        // (!loanTermYearNum ||
        // // !loanTermMonthNum ||
        // (!!loanTermYearNum &&
        //   +totalMonthBirth + +loanTermYearNum * 12 <=
        //     // + +loanTermMonthNum + 1
        //     80 * 12)) &&
        (((formik.values.p_application_header.has_land_advance_plan !==
          LandAdvanceType.YES &&
          +loanAmount1 >= 500 &&
          +loanAmount1 <= 20000) ||
          (formik.values.p_application_header.has_land_advance_plan ===
            LandAdvanceType.YES &&
            +loanAmount1 + +loanAmount2 >= 500 &&
            +loanAmount1 + +loanAmount2 <= 20000)) &&
          ((formik.values.p_application_header.has_land_advance_plan !==
            LandAdvanceType.YES &&
            +loanAmount1 % 10 === 0) ||
            (formik.values.p_application_header.has_land_advance_plan ===
              LandAdvanceType.YES &&
              +loanAmount1 % 10 === 0 &&
              +loanAmount2 % 10 === 0))) ||
        // (!loanTermYearNum ||
        // // !loanTermMonthNum ||
        // (!!loanTermYearNum &&
        //   +totalMonthBirth + +loanTermYearNum * 12 <=
        //     // + +loanTermMonthNum + 1
        //     80 * 12)) &&
        formik.values.p_application_header.has_land_advance_plan !==
          LandAdvanceType.YES
      ) {
        if (
          !isSubmit &&
          isIncomeTotalizer &&
          !isIncomeTotalizerInit &&
          !!application_number
        ) {
          await dispatch(
            saveForm(
              convertCurrency({
                p_application_header: {
                  ...values.p_application_header,
                  p_applicant_people_attributes: cloneApplicantPeople,
                },
                master_bank_codes: values.master_bank_codes,
              })
            )
          );
          if (isHasJoinGuarantor && !isJoinGuarantorInit) {
            dispatch(addInitialCollateralProviderState());
          }
          onChangeLoanType();
          navigate(routeNames.SPStepFourIncomeTotalizer.path, {
            state: { pathName: window.location.pathname },
          });
          return;
        }

        if (
          !isSubmit &&
          isHasJoinGuarantor &&
          !isJoinGuarantorInit &&
          !!application_number
        ) {
          dispatch(addInitialCollateralProviderState());
          await dispatch(
            saveForm(
              convertCurrency({
                p_application_header: {
                  ...values.p_application_header,
                  p_applicant_people_attributes: cloneApplicantPeople,
                },
                master_bank_codes: values.master_bank_codes,
              })
            )
          );
          navigate(routeNames.SPStepFourCollateralProvider.path, {
            state: { pathName: window.location.pathname },
          });
          return;
        }

        if (isSubmit) {
          setIsDisabledButton(true);
          if (isJoinGuarantorInit !== isHasJoinGuarantor) {
            if (isHasJoinGuarantor) {
              await dispatch(addInitialCollateralProviderState());
            } else {
              await dispatch(resetStepFourCollateralProviderForm());
            }
          }
          onChangeLoanType();
          await updateFormOverride(
            {
              stepOneData: convertCurrency({
                p_application_header: {
                  ...values.p_application_header,
                  p_applicant_people_attributes: cloneApplicantPeople,
                },
                master_bank_codes: values.master_bank_codes,
              }),
              imageContext: imageContext,
            },
            setIsDisabledButton,
            setOpenImageModal
          );
          return;
        }

        await dispatch(
          saveForm(
            convertCurrency({
              p_application_header: {
                ...values.p_application_header,
                p_applicant_people_attributes: cloneApplicantPeople,
              },
              master_bank_codes: values.master_bank_codes,
            })
          )
        );

        if (!application_number) {
          checkCurrentStep();
          setSubmitting(false);
          if (isHasJoinGuarantor) {
            dispatch(addInitialCollateralProviderState());
          } else {
            dispatch(resetStepFourCollateralProviderForm());
          }
          navigate(routeNames.SPStepTwo.path);
        }
      } else {
        setSubmitting(false);
      }
    },
  });

  const isHasJoinGuarantor = useMemo(() => {
    return (
      formik.values.p_application_header.p_applicant_people_attributes
        .has_join_guarantor === CoOwnerType.CANBE
    );
  }, [
    formik.values.p_application_header.p_applicant_people_attributes
      .has_join_guarantor,
  ]);

  const isIncomeTotalizer = useMemo(() => {
    return (
      formik.values.p_application_header.loan_type === LoanType.THREE ||
      formik.values.p_application_header.loan_type === LoanType.FOUR
    );
  }, [formik.values.p_application_header.loan_type]);

  const isSubmit = useMemo(() => {
    const isSubmit =
      !!application_number &&
      ((!isIncomeTotalizer && !isHasJoinGuarantor) ||
        (isIncomeTotalizer === isIncomeTotalizerInit &&
          isHasJoinGuarantor === isJoinGuarantorInit) ||
        (isIncomeTotalizer === isIncomeTotalizerInit && !isHasJoinGuarantor) ||
        (isHasJoinGuarantor === isJoinGuarantorInit && !isIncomeTotalizer));
    return isSubmit;
  }, [
    application_number,
    isHasJoinGuarantor,
    isIncomeTotalizer,
    isIncomeTotalizerInit,
    isJoinGuarantorInit,
  ]);

  const loanAmount1 =
    formik.values.p_application_header.p_borrowing_details_attributes[0]
      .temporary_desired_loan_amount;

  const loanAmount2 =
    formik.values.p_application_header?.p_borrowing_details_attributes?.[1]
      ?.temporary_desired_loan_amount &&
    formik.values.p_application_header.p_borrowing_details_attributes[1]
      ?.temporary_desired_loan_amount;

  const checkErrorValue = useMemo(() => {
    const loanAmount =
      formik.values.p_application_header?.p_borrowing_details_attributes[0]
        ?.temporary_desired_loan_amount;
    const loanAmount1 =
      formik.values.p_application_header?.p_borrowing_details_attributes?.[1]
        ?.temporary_desired_loan_amount || '';
    if (
      !(
        +loanAmount + +loanAmount1 >= 500 && +loanAmount + +loanAmount1 <= 20000
      )
    ) {
      return true;
    }
  }, [formik.values.p_application_header.p_borrowing_details_attributes]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (
          getIn(formik.errors, key) ||
          typeof getIn(formik.errors, key) === 'string'
        ) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  useEffect(() => {
    const selectedBanks = masterBank.filter((bank) =>
      formik.values.p_application_header.master_bank_ids.includes(bank.id)
    );
    const selectedCodes = selectedBanks.map((bank) => bank.code);

    formik.setFieldValue('master_bank_codes', selectedCodes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.master_bank_ids, masterBank]);

  const loanTermYearNum =
    formik.values.p_application_header.p_borrowing_details_attributes[0]
      .loan_term_year_num;

  const totalMonthBirth = useMemo(() => {
    const month = spStepTwoValues?.p_applicant_people.birthday
      ? dayjs().diff(spStepTwoValues?.p_applicant_people.birthday, 'M')
      : '';
    return month;
  }, [spStepTwoValues.p_applicant_people.birthday]);

  const { has_land_advance_plan, p_borrowing_details_attributes } =
    formik.values.p_application_header;

  const {
    desired_monthly_bonus,
    loan_term_month_num,
    loan_term_year_num,
    repayment_method,
  } = p_borrowing_details_attributes[0];

  useEffect(() => {
    if (has_land_advance_plan === LandAdvanceType.YES) {
      formik.setFieldValue(
        'p_application_header.p_borrowing_details_attributes[1].desired_monthly_bonus',
        DesiredMonthlyBonusType.ONE
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [has_land_advance_plan, desired_monthly_bonus]);

  useEffect(() => {
    if (
      formik.values.p_application_header.has_land_advance_plan ===
      LandAdvanceType.YES
    ) {
      formik.setFieldValue(
        'p_application_header.p_borrowing_details_attributes[1].loan_term_year_num',
        loan_term_year_num
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [has_land_advance_plan, loan_term_year_num]);

  useEffect(() => {
    if (has_land_advance_plan === LandAdvanceType.YES) {
      formik.setFieldValue(
        'p_application_header.p_borrowing_details_attributes[1].loan_term_month_num',
        loan_term_month_num
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [has_land_advance_plan, loan_term_month_num]);

  useEffect(() => {
    if (has_land_advance_plan === LandAdvanceType.YES) {
      formik.setFieldValue(
        'p_application_header.p_borrowing_details_attributes[1].repayment_method',
        repayment_method
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [has_land_advance_plan, repayment_method]);

  useEffect(() => {
    if (formik.values.p_application_header.loan_target !== PurposeType.SIX) {
      formik.setFieldValue(
        'p_application_header.has_land_advance_plan',
        LandAdvanceType.DEFAULT
      );

      if (
        !!application_number &&
        !!formik.values.p_application_header
          .p_borrowing_details_attributes[1] &&
        !!formik.values.p_application_header.p_borrowing_details_attributes[1]
          .id
      ) {
        formik.setFieldValue(
          'p_application_header.p_borrowing_details_attributes[1]._destroy',
          true
        );
      } else {
        formik.setFieldValue(
          'p_application_header.p_borrowing_details_attributes',
          [
            get(
              formik.values.p_application_header,
              'p_borrowing_details_attributes[0]'
            ),
          ]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      initialValues?.status?.firstElement === true &&
      initialValues?.status?.secondElement === true
    ) {
      formik.setFieldValue(
        'p_application_header.loan_apply_date',
        dayjs().format('YYYY/MM/DD')
      );
    }
  }, [
    initialValues?.status?.firstElement,
    initialValues?.status?.secondElement,
  ]);

  const loanTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.loan_type),
    [dynamicOptions.loan_type]
  );

  const loanTargetOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.loan_target),
    [dynamicOptions.loan_target]
  );

  const desiredMonthlyBonusOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.desired_monthly_bonus),
    [dynamicOptions.desired_monthly_bonus]
  );

  const repaymentOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.repayment_method),
    [dynamicOptions.repayment_method]
  );

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);
    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = formik.values.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(formik.values.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          formik.values.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        convertCurrency({
          p_application_header: {
            ...formik.values.p_application_header,
            p_applicant_people_attributes: [
              formik.values.p_application_header.p_applicant_people_attributes,
            ],
          },
          master_bank_codes: formik.values.master_bank_codes,
        }).p_application_header.p_borrowing_details_attributes
      )
    );

    formik.values.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });
    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });
    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }
    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/

    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
  };

  const isMCJ = useMemo(
    () => formik.values.master_bank_codes.includes(CODEBANK.MCJ),
    [formik.values.master_bank_codes]
  );

  useEffect(() => {
    if (!isMCJ) {
      dispatch(resetMCJStepFive());
    }
  }, [dispatch, isMCJ]);

  const checkCurrentStep = useCallback(() => {
    if (+currentStep < 2) return dispatch(setCurrentStep('2'));
    switch (currentStep) {
      case '10':
        if (
          formik.values.p_application_header.loan_type === LoanType.THREE ||
          formik.values.p_application_header.loan_type === LoanType.FOUR
        ) {
          if (
            formik.values.p_application_header.p_applicant_people_attributes
              .has_join_guarantor
          )
            return dispatch(setCurrentStep('14'));
          return dispatch(setCurrentStep('13'));
        }
        if (
          formik.values.p_application_header.p_applicant_people_attributes
            .has_join_guarantor
        )
          return dispatch(setCurrentStep('11'));
        break;
      case '13':
        if (
          formik.values.p_application_header.loan_type === LoanType.ONE ||
          formik.values.p_application_header.loan_type === LoanType.TWO
        ) {
          if (
            formik.values.p_application_header.p_applicant_people_attributes
              .has_join_guarantor
          )
            return dispatch(setCurrentStep('11'));
          return dispatch(setCurrentStep('10'));
        }
        if (
          formik.values.p_application_header.p_applicant_people_attributes
            .has_join_guarantor
        )
          return dispatch(setCurrentStep('14'));
        break;
      case '14':
        if (
          formik.values.p_application_header.loan_type === LoanType.ONE ||
          formik.values.p_application_header.loan_type === LoanType.TWO
        ) {
          if (
            formik.values.p_application_header.p_applicant_people_attributes
              .has_join_guarantor
          )
            return dispatch(setCurrentStep('11'));
          return dispatch(setCurrentStep('10'));
        }
        if (
          !formik.values.p_application_header.p_applicant_people_attributes
            .has_join_guarantor
        )
          return dispatch(setCurrentStep('13'));
        break;
      default:
        break;
    }
  }, [
    currentStep,
    dispatch,
    formik.values.p_application_header.loan_type,
    formik.values.p_application_header.p_applicant_people_attributes
      .has_join_guarantor,
  ]);

  const onChangeLoanType = useCallback(() => {
    const loanType = formik.values.p_application_header.loan_type;
    if (loanType === LoanType.TWO) {
      dispatch(clearPairLoanAmount());
    }

    if (
      loanType !== LoanType.THREE &&
      loanType !== LoanType.FOUR &&
      isIncomeTotalizerInit
    ) {
      dispatch(resetStepFourIncomeForm());
      dispatch(resetStepFiveIncomeForm());
      dispatch(resetStepSevenIncomeForm());
      dispatch(resetLandHouseBorrower());
      setResidenceIncomeFile([]);
      setResidenceIncomeFileBackImage([]);
      setFinancialStatement1File2([]);
      setFinancialStatement2File2([]);
      setFinancialStatement3File2([]);
      setFirstWithholdingSlipFile2([]);
      setSecondWithholdingSlipFile2([]);
      setOtherDocumentFile2([]);
      setEmploymentAgreementFile2([]);
      setBusinessTaxReturn1File2([]);
      setBusinessTaxReturn2File2([]);
      setBusinessTaxReturn3File2([]);
      setFirstIncomeFile2([]);
      setSecondIncomeFile2([]);
      setThirdIncomeFile2([]);
      setDriverLicenseFrontImage2([]);
      setDriverLicenseBackImage2([]);
      setCardNumberFrontImage2([]);
      setCardNumberBackImage2([]);
      setResidentRegisterFrontImage2([]);
      setResidentRegisterBackImage2([]);
      setInsuranceFile2([]);
      setInsuranceFileBackImage2([]);
    }
  }, [
    dispatch,
    formik.values.p_application_header.loan_type,
    isIncomeTotalizerInit,
    setBusinessTaxReturn1File2,
    setBusinessTaxReturn2File2,
    setBusinessTaxReturn3File2,
    setCardNumberBackImage2,
    setCardNumberFrontImage2,
    setDriverLicenseBackImage2,
    setDriverLicenseFrontImage2,
    setEmploymentAgreementFile2,
    setFinancialStatement1File2,
    setFinancialStatement2File2,
    setFinancialStatement3File2,
    setFirstIncomeFile2,
    setFirstWithholdingSlipFile2,
    setInsuranceFile2,
    setInsuranceFileBackImage2,
    setOtherDocumentFile2,
    setResidenceIncomeFile,
    setResidenceIncomeFileBackImage,
    setResidentRegisterBackImage2,
    setResidentRegisterFrontImage2,
    setSecondIncomeFile2,
    setSecondWithholdingSlipFile2,
    setThirdIncomeFile2,
  ]);

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        onSaveForm={async () => {
          let cloneApplicantPeople = [...applicantPeople];
          cloneApplicantPeople[0] =
            formik.values.p_application_header.p_applicant_people_attributes;
          await dispatch(
            saveForm(
              convertCurrency({
                p_application_header: {
                  ...formik.values.p_application_header,
                  p_applicant_people_attributes: cloneApplicantPeople,
                },
                master_bank_codes: formik.values.master_bank_codes,
              })
            )
          );
        }}
        hasJoinGuarantor={
          !!+formik.values.p_application_header.p_applicant_people_attributes
            .has_join_guarantor
        }
        hasTotalIncome={
          formik.values.p_application_header.loan_type === LoanType.THREE ||
          formik.values.p_application_header.loan_type === LoanType.FOUR
        }
        footer={{
          left: {
            onClick: async () => {
              let cloneApplicantPeople = [...applicantPeople];
              cloneApplicantPeople[0] =
                formik.values.p_application_header.p_applicant_people_attributes;
              await dispatch(
                saveForm(
                  convertCurrency({
                    p_application_header: {
                      ...formik.values.p_application_header,
                      p_applicant_people_attributes: cloneApplicantPeople,
                    },
                    master_bank_codes: formik.values.master_bank_codes,
                  })
                )
              );
              if (previousPathName === routeNames.SPStepNine.path) {
                navigate(routeNames.SPStepNine.path);
              } else {
                navigate(routeNames.Top.path);
              }
            },
          },
          right: {
            children: isSubmit ? '保存' : '次へ',
            onClick: () => {
              formik.handleSubmit();
            },
            disabled: isDisabledButton,
          },
        }}
        onSaveDraft={onSaveDraft}
      >
        <Stack sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ px: 11, my: 9 }}
          >
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              whiteSpace="nowrap"
            >
              まずは、お借入のご希望を
            </Typography>
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
            >
              お聞かせください。
            </Typography>
          </Stack>
          <SPFormItem required label="入居予定年月">
            <SPInputDate
              name="p_application_header.scheduled_date_moving"
              selectWidth="163px"
              yearOptions={yearOptions}
            />
          </SPFormItem>
          <SPFormItem
            required
            label={
              masterBank.length > 1
                ? '借入を申し込む金融機関を選択してください'
                : '借入を申し込む金融機関'
            }
            note={masterBank.length > 1 ? '※複数選択可' : ''}
            noteType="long"
          >
            {masterBank.length > 1 ? (
              <SPCheckboxMultipleButton
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  px: 12,
                }}
                name="p_application_header.master_bank_ids"
                options={masterBank.slice(0, 5).map((bank) => {
                  if (bank.code === CODEBANK.SBI)
                    return { ...bank, readOnly: true };
                  return bank;
                })}
              />
            ) : (
              <Typography
                sx={{ pl: 4 }}
                variant="sp_unit_select_text"
                color="b_333"
              >
                {masterBank.map((item) => item.name)}
              </Typography>
            )}
          </SPFormItem>
          <SPFormItem required label="お借入の目的">
            <Stack spacing={3}>
              <SPRadioGroupButton
                name="p_application_header.loan_target_zero"
                options={loanTargetZeroOptions}
                onChange={(checked, value) => {
                  if (value === LoanTargetZeroType.ZERO) {
                    formik.setFieldValue(
                      'p_application_header.loan_target',
                      PurposeType.DEFAULT
                    );
                  }
                  if (value === LoanTargetZeroType.SEVEN) {
                    formik.setFieldValue(
                      'p_application_header.loan_target',
                      PurposeType.SEVEN
                    );
                    dispatch(resetReasonAcquireHome());
                  }
                  if (value === LoanTargetZeroType.EIGHT) {
                    formik.setFieldValue(
                      'p_application_header.loan_target',
                      PurposeType.EIGHT
                    );
                  }
                }}
                required
              />
            </Stack>
          </SPFormItem>
          {formik.values.p_application_header.loan_target_zero ===
            LoanTargetZeroType.ZERO && (
            <SPFormItem required label="資金の使いみち">
              <SPRadioGroupButton
                name="p_application_header.loan_target"
                options={loanTargetOptions.slice(0, 6)}
                onChange={() => dispatch(resetStepSixForm())}
                required={
                  formik.values.p_application_header.loan_target_zero ===
                  LoanTargetZeroType.ZERO
                }
              />
            </SPFormItem>
          )}
          {formik.values.p_application_header.loan_target ===
            PurposeType.SIX && (
            <SPFormItem
              required
              label="土地先行プランをご希望ですか？"
              note="※ 土地先行プランは住信SBIネット銀行でご利用できます。"
              help={[
                {
                  name: ModalName.ABOUT_LAND_ADVANCE_PLAN,
                  helpTitle: modalName[ModalName.ABOUT_LAND_ADVANCE_PLAN],
                },
              ]}
              onOpenHelpModal={(modalName) => setModalHelp(modalName)}
            >
              <SPRadioGroupButton
                name="p_application_header.has_land_advance_plan"
                options={landAdvancePlanOptions}
                onChange={(checked, value) => {
                  //Handle set value second borrowing detail follow land advance value
                  if (value === LandAdvanceType.YES) {
                    if (
                      !!application_number &&
                      !!formik.values.p_application_header
                        .p_borrowing_details_attributes[1] &&
                      !!formik.values.p_application_header
                        .p_borrowing_details_attributes[1].id
                    ) {
                      formik.setFieldValue(
                        'p_application_header.p_borrowing_details_attributes[1]._destroy',
                        false
                      );
                      formik.setFieldValue(
                        'p_application_header.p_borrowing_details_attributes[1]',
                        {
                          id: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[1].id'
                          ),
                          loan_desired_borrowing_date: '',
                          temporary_desired_loan_amount: '',
                          halfyear_bonus: '',
                          desired_monthly_bonus: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[1].desired_monthly_bonus'
                          ),
                          loan_term_month_num: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[1].loan_term_month_num'
                          ),
                          loan_term_year_num: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[1].loan_term_year_num'
                          ),
                          repayment_method: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[1].repayment_method'
                          ),
                          borrowing_detail_type: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[1].borrowing_detail_type'
                          ),
                          _destroy: false,
                        }
                      );
                    } else {
                      formik.setFieldValue(
                        'p_application_header.p_borrowing_details_attributes[1]',
                        {
                          loan_desired_borrowing_date: '',
                          temporary_desired_loan_amount: '',
                          halfyear_bonus: '',
                          desired_monthly_bonus: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[0].desired_monthly_bonus'
                          ),
                          loan_term_month_num: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[0].loan_term_month_num'
                          ),
                          loan_term_year_num: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[0].loan_term_year_num'
                          ),
                          repayment_method: get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[0].repayment_method'
                          ),
                        }
                      );
                    }
                  } else {
                    if (
                      !!application_number &&
                      !!formik.values.p_application_header
                        .p_borrowing_details_attributes[1] &&
                      !!formik.values.p_application_header
                        .p_borrowing_details_attributes[1].id
                    ) {
                      formik.setFieldValue(
                        'p_application_header.p_borrowing_details_attributes[1]._destroy',
                        true
                      );
                    } else {
                      formik.setFieldValue(
                        'p_application_header.p_borrowing_details_attributes',
                        [
                          get(
                            formik.values.p_application_header,
                            'p_borrowing_details_attributes[0]'
                          ),
                        ]
                      );
                    }
                  }
                }}
                required={
                  formik.values.p_application_header.loan_target ===
                  PurposeType.SIX
                }
              />
            </SPFormItem>
          )}
          <SPAboutLandAdvancePlanModal
            open={modalHelp === ModalName.ABOUT_LAND_ADVANCE_PLAN}
            onClose={() => setModalHelp('')}
          />
          <SPFormItem
            required
            label="お借入形態"
            help={[
              {
                name: ModalName.ABOUT_PAIR_LOAN,
                helpTitle: modalName[ModalName.ABOUT_PAIR_LOAN],
              },
              {
                name: ModalName.ABOUT_COMBINED_INCOME,
                helpTitle: modalName[ModalName.ABOUT_COMBINED_INCOME],
              },
            ]}
            noteType="long"
            onOpenHelpModal={(modalName) => setModalHelp(modalName)}
          >
            <SPRadioGroupButton
              name="p_application_header.loan_type"
              options={loanTypeOptions}
              onChange={(checked, value) => {
                if (value === LoanType.TWO) {
                  setOpenSelectPairLoanModal(true);
                }
                if (
                  formik.values.p_application_header.loan_type ===
                    LoanType.TWO &&
                  value !== LoanType.TWO &&
                  !!application_number
                ) {
                  setUnSelectPairLoanModal({ open: true, value: value });
                }
              }}
              required
            />
          </SPFormItem>
          {formik.values.p_application_header.loan_type === LoanType.TWO && (
            <Stack sx={{ px: 4, mb: 8 }}>
              <Stack
                sx={{
                  borderRadius: 2,
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                  bgcolor: 'sp_primary_100_main',
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ px: 3, py: 1 }}
                >
                  <Typography
                    variant="SP_form_item_label"
                    color="main_white"
                    fontSize={14}
                    lineHeight="100%"
                  >
                    ペアローンのお相手について
                  </Typography>
                </Stack>

                <SPFormItem
                  required
                  label="お名前"
                  labelSx={{ px: 3, py: 1 }}
                  sx={{ bgcolor: 'main_white' }}
                  childrenSx={{ bgcolor: 'main_white', pb: 3 }}
                >
                  <Stack spacing="10px">
                    <SPInputField
                      name="p_application_header.pair_loan_applicant_last_name"
                      placeholder="姓"
                      convertFullWidth
                    />
                    <SPInputField
                      name="p_application_header.pair_loan_applicant_first_name"
                      placeholder="名"
                      convertFullWidth
                    />
                  </Stack>
                </SPFormItem>
                <SPFormItem
                  required
                  label="続柄"
                  labelSx={{ px: 3, py: 1 }}
                  sx={{
                    bgcolor: 'main_white',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                  childrenSx={{
                    bgcolor: 'main_white',
                    pb: 3,
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                >
                  <SPInputField
                    name="p_application_header.pair_loan_relationship_name"
                    placeholder="例：妻"
                    convertFullWidth
                  />
                </SPFormItem>
              </Stack>
            </Stack>
          )}
          <SPSelectPairLoanModal
            open={openSelectPairLoanModal}
            onClose={() => setOpenSelectPairLoanModal(false)}
            isMCJ={isMCJ}
          />
          <SPJointGuarantorModal
            open={modalHelp === ModalName.JOINT_GUARANTOR}
            onClose={(consents) => {
              if (consents.some((consent) => !consent)) {
                formik.setFieldValue(
                  'p_application_header.loan_type',
                  LoanType.DEFAULT
                );
              }
              setModalHelp('');
            }}
            onSaveForm={async () => {
              let cloneApplicantPeople = [...applicantPeople];
              cloneApplicantPeople[0] =
                formik.values.p_application_header.p_applicant_people_attributes;
              await dispatch(
                saveForm(
                  convertCurrency({
                    p_application_header: {
                      ...formik.values.p_application_header,
                      p_applicant_people_attributes: cloneApplicantPeople,
                    },
                    master_bank_codes: formik.values.master_bank_codes,
                  })
                )
              );
            }}
          />
          <SPModalChangeLoanType
            open={unSelectPairLoanModal.open}
            onClick={() => {
              formik.setFieldValue(
                'p_application_header.loan_type',
                unSelectPairLoanModal.value
              );
              formik.setFieldValue(
                'p_application_header.pair_loan_applicant_last_name',
                ''
              );
              formik.setFieldValue(
                'p_application_header.pair_loan_applicant_first_name',
                ''
              );
              formik.setFieldValue(
                'p_application_header.pair_loan_relationship_name',
                ''
              );
              dispatch(clearPairLoanAmount());
              setUnSelectPairLoanModal({ open: false, value: '' });
            }}
            onClose={() => {
              formik.setFieldValue(
                'p_application_header.loan_type',
                LoanType.TWO
              );
              setUnSelectPairLoanModal({ open: false, value: '' });
            }}
          />
          <SPAboutPairLoanModal
            open={modalHelp === ModalName.ABOUT_PAIR_LOAN}
            onClose={() => setModalHelp('')}
          />
          <SPCombinedIncomeModal
            open={modalHelp === ModalName.ABOUT_COMBINED_INCOME}
            onClose={() => setModalHelp('')}
          />
          <SPFormItem required label="お借入内容">
            <Stack
              sx={{
                borderRadius: 2,
                border: (theme) =>
                  `1px solid ${theme?.palette?.sp_primary_100_main}`,
              }}
            >
              {formik.values.p_application_header.has_land_advance_plan ===
                LandAdvanceType.YES && (
                <Stack
                  sx={{
                    p: '8px 16px 8px 16px',
                    bgcolor: 'sp_primary_100_main',
                    borderTopRightRadius: '7px',
                    borderTopLeftRadius: '7px',
                  }}
                >
                  <Typography variant="SP_form_item_label" color="main_white">
                    1回目の融資
                  </Typography>
                </Stack>
              )}

              <SPFormItem
                required
                label="お借入希望日"
                labelSx={{
                  borderTopRightRadius:
                    formik.values.p_application_header.has_land_advance_plan ===
                    LandAdvanceType.YES
                      ? 0
                      : '9px',
                  borderTopLeftRadius:
                    formik.values.p_application_header.has_land_advance_plan ===
                    LandAdvanceType.YES
                      ? 0
                      : '9px',
                }}
              >
                <SPInputDateTime
                  name="p_application_header.p_borrowing_details_attributes[0].loan_desired_borrowing_date"
                  checkRequired={false}
                  yearOptions={yearOptions}
                />
              </SPFormItem>

              <SPFormItem required label="お借入希望額">
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Stack direction="row" alignItems="center">
                      <SPInputField
                        numeric
                        sx={{ width: '156px' }}
                        name="p_application_header.p_borrowing_details_attributes[0].temporary_desired_loan_amount"
                        unit={
                          <Stack
                            direction="row"
                            alignItems="flex-end"
                            spacing={1}
                          >
                            <Typography
                              variant="sp_unit_text"
                              color="b_333"
                              whiteSpace="nowrap"
                            >
                              万円
                            </Typography>
                            <Typography
                              variant="sp_note_text"
                              color="b_333"
                              whiteSpace="nowrap"
                            >
                              ※10万円単位
                            </Typography>
                          </Stack>
                        }
                        placeholder="0"
                        align="right"
                        useNumberFormat
                        toFixedNumber={false}
                        maxLength={6}
                        showError={true}
                      />
                    </Stack>
                    {formik.values.p_application_header.loan_type ===
                      LoanType.TWO && (
                      <Typography variant="SP_form_item_note" color="b_333">
                        ※
                        あなたの分の金額をご入力ください。ペアローン相手との合計金額ではございませんのでご注意ください。
                      </Typography>
                    )}
                    {formik.values.p_application_header
                      .has_land_advance_plan === LandAdvanceType.YES &&
                      !checkErrorValue && (
                        <Typography
                          variant="SP_input_error_text"
                          color={'b_333'}
                        >
                          ※{MESSAGE_REGEX.TEMPORARY_DESIRED_LOAN_AMOUNT}
                        </Typography>
                      )}
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="sp_unit_select_text" color="b_333">
                      うち、ボーナス返済分
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <SPInputField
                        sx={{ width: '156px' }}
                        name="p_application_header.p_borrowing_details_attributes[0].halfyear_bonus"
                        unit={
                          <Stack
                            direction="row"
                            alignItems="flex-end"
                            spacing={1}
                          >
                            <Typography variant="sp_unit_text" color="b_333">
                              万円
                            </Typography>
                            <Typography variant="sp_note_text" color="b_333">
                              ※10万円単位
                            </Typography>
                          </Stack>
                        }
                        onBlur={(e) => {
                          if (
                            e.target.value &&
                            formik.values.p_application_header
                              .p_borrowing_details_attributes[0]
                              .desired_monthly_bonus ===
                              DesiredMonthlyBonusType.ONE
                          ) {
                            formik.setFieldValue(
                              'p_application_header.p_borrowing_details_attributes[0].desired_monthly_bonus',
                              DesiredMonthlyBonusType.TWO
                            );
                          }
                          if (e.target.value === '' || e.target.value === '0') {
                            formik.setFieldValue(
                              'p_application_header.p_borrowing_details_attributes[0].desired_monthly_bonus',
                              DesiredMonthlyBonusType.ONE
                            );
                          }
                        }}
                        placeholder="0"
                        align="right"
                        numeric
                        useNumberFormat
                        toFixedNumber={false}
                        maxLength={6}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Icons.SPCalculator
                      sx={{ width: '16px', height: '16px' }}
                    />
                    <Typography
                      variant="sp_calcuator_text"
                      sx={{
                        textDecoration: 'underline',
                        textDecorationColor: 'sp_primary_100_main',
                      }}
                      color="sp_primary_100_main"
                      onClick={() => setModalHelp(ModalName.CALCULATOR)}
                    >
                      {modalName[ModalName.CALCULATOR]}
                    </Typography>
                  </Stack>

                  <SPCalculatorModal
                    open={modalHelp === ModalName.CALCULATOR}
                    onClose={() => setModalHelp('')}
                  />

                  <Stack spacing={1}>
                    <Typography variant="sp_unit_select_text" color="b_333">
                      ボーナス返済月
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ width: 200 }}
                    >
                      <SPInputSelect
                        placeholder="選択してください"
                        name="p_application_header.p_borrowing_details_attributes[0].desired_monthly_bonus"
                        options={desiredMonthlyBonusOptions}
                        sx={{ width: '100%' }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </SPFormItem>

              <SPFormItem required label="お借入期間">
                <Stack spacing={1} ref={loanTermRef}>
                  <Stack direction="row" alignItems="flex-start" spacing={1}>
                    <SPInputSelect
                      useFastField={false}
                      sx={{
                        minHeight: 48,
                        height: 48,
                        width: 72,
                        '& .MuiSelect-select': {
                          display: 'flex',
                          alignItems: 'center',
                          pt: '12px',
                        },
                      }}
                      iconSx={{ right: 0 }}
                      name="p_application_header.p_borrowing_details_attributes[0].loan_term_year_num"
                      options={yearNumOptions}
                      placeholder="--"
                      unit="年"
                      showError={false}
                      checkRequired
                      onBlur={() => setIsBlurred(false)}
                      onFocus={() => setIsBlurred(true)}
                      onChange={(e) => {
                        if (e.target.value !== '') {
                          formik.setFieldValue(
                            'p_application_header.p_borrowing_details_attributes[0].loan_term_month_num',
                            zeroPad(0)
                          );
                        }
                        if (
                          formik.values.p_application_header
                            .has_land_advance_plan === LandAdvanceType.YES
                        ) {
                          formik.setFieldValue(
                            'p_application_header.p_borrowing_details_attributes[1].loan_term_year_num',
                            e.target.value
                          );
                          if (e.target.value !== '') {
                            formik.setFieldValue(
                              'p_application_header.p_borrowing_details_attributes[1].loan_term_month_num',
                              zeroPad(0)
                            );
                          }
                        }
                      }}
                    />
                  </Stack>
                  {/*{loanTermYearLimit(*/}
                  {/*  formik.values.p_application_header*/}
                  {/*    .p_borrowing_details_attributes[0].loan_term_year_num,*/}
                  {/*  spStepTwoValues?.p_applicant_people.birthday*/}
                  {/*) &&*/}
                  {/*  !isBlurred && (*/}
                  {/*    <Typography variant="text_error" color="sp_secondary_01">*/}
                  {/*      ※{MESSAGE_REGEX.LOAN_TERM_LIMIT}*/}
                  {/*    </Typography>*/}
                  {/*  )}*/}
                  {!formik.values.p_application_header
                    ?.p_borrowing_details_attributes?.[0].loan_term_year_num &&
                    formik.touched.p_application_header
                      ?.p_borrowing_details_attributes?.[0]
                      ?.loan_term_year_num && (
                      <Typography variant="text_error" color="sp_secondary_01">
                        ※お借入期間を入力してください。
                      </Typography>
                    )}
                </Stack>
              </SPFormItem>

              <SPFormItem
                required
                label="返済方法"
                help={[
                  {
                    name: ModalName.DIFFERENCE,
                    helpTitle: modalName[ModalName.DIFFERENCE],
                  },
                ]}
                childrenSx={{
                  borderBottomLeftRadius: '9px',
                  borderBottomRightRadius: '9px',
                }}
                onOpenHelpModal={(modalName) => setModalHelp(modalName)}
              >
                <SPRadioGroupButton
                  name="p_application_header.p_borrowing_details_attributes[0].repayment_method"
                  options={repaymentOptions}
                  onChange={(checked, value) => {
                    if (
                      formik.values.p_application_header
                        .has_land_advance_plan === LandAdvanceType.YES
                    ) {
                      formik.setFieldValue(
                        'p_application_header.p_borrowing_details_attributes[1].repayment_method',
                        value
                      );
                    }
                  }}
                  required
                />
              </SPFormItem>
            </Stack>

            <SPDifferenceModal
              open={modalHelp === ModalName.DIFFERENCE}
              onClose={() => setModalHelp('')}
            />

            {formik.values.p_application_header.has_land_advance_plan ===
              LandAdvanceType.YES && (
              <Stack
                sx={{
                  mt: 3,
                  borderRadius: 2,
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
              >
                <Stack
                  sx={{
                    p: '8px 16px 8px 16px',
                    bgcolor: 'sp_primary_100_main',
                    borderTopRightRadius: '7px',
                    borderTopLeftRadius: '7px',
                  }}
                >
                  <Typography variant="SP_form_item_label" color="main_white">
                    2回目の融資
                  </Typography>
                </Stack>

                <SPFormItem required label="お借入希望日">
                  <Stack spacing={1}>
                    <SPInputDateTime
                      checkRequired={false}
                      name="p_application_header.p_borrowing_details_attributes[1].loan_desired_borrowing_date"
                      yearOptions={yearOptions}
                    />
                  </Stack>
                </SPFormItem>

                <SPFormItem
                  required
                  label="お借入希望額"
                  childrenSx={{
                    borderBottomRightRadius: '9px',
                    borderBottomLeftRadius: '9px',
                  }}
                >
                  <Stack spacing={3}>
                    <Stack spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <SPInputField
                          sx={{ width: '156px' }}
                          name="p_application_header.p_borrowing_details_attributes[1].temporary_desired_loan_amount"
                          unit={
                            <Stack
                              direction="row"
                              alignItems="flex-end"
                              spacing={1}
                            >
                              <Typography variant="sp_unit_text" color="b_333">
                                万円
                              </Typography>
                              <Typography variant="sp_note_text" color="b_333">
                                ※10万円単位
                              </Typography>
                            </Stack>
                          }
                          placeholder="0"
                          align="right"
                          numeric
                          useNumberFormat
                          toFixedNumber={false}
                          maxLength={6}
                          showError={true}
                        />
                      </Stack>
                      {!checkErrorValue && (
                        <Typography
                          variant="SP_input_error_text"
                          color={'b_333'}
                        >
                          ※{MESSAGE_REGEX.TEMPORARY_DESIRED_LOAN_AMOUNT}
                        </Typography>
                      )}
                    </Stack>

                    <Stack spacing={1}>
                      <Typography variant="sp_unit_select_text" color="b_333">
                        うち、ボーナス返済分
                      </Typography>
                      <Stack direction="row" alignItems="center">
                        <SPInputField
                          sx={{ width: '156px' }}
                          name="p_application_header.p_borrowing_details_attributes[1].halfyear_bonus"
                          unit={
                            <Stack
                              direction="row"
                              alignItems="flex-end"
                              spacing={1}
                            >
                              <Typography variant="sp_unit_text" color="b_333">
                                万円
                              </Typography>
                              <Typography variant="sp_note_text" color="b_333">
                                ※10万円単位
                              </Typography>
                            </Stack>
                          }
                          placeholder="0"
                          align="right"
                          numeric
                          useNumberFormat
                          toFixedNumber={false}
                          maxLength={6}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </SPFormItem>
              </Stack>
            )}
          </SPFormItem>
          <SPFormItem
            required
            label={
              <Stack>
                <Typography variant="SP_form_item_label" color="b_333">
                  担保提供者がいる方のみ、チェックをつけて
                </Typography>
                <Typography variant="SP_form_item_label" color="b_333">
                  ください。
                </Typography>
              </Stack>
            }
            help={[
              {
                name: ModalName.ABOUT_COLLATERAL_PROVIDER,
                helpTitle: modalName[ModalName.ABOUT_COLLATERAL_PROVIDER],
              },
            ]}
            onOpenHelpModal={(modalName) => setModalHelp(modalName)}
          >
            <SPCheckboxButton
              name="p_application_header.p_applicant_people_attributes.has_join_guarantor"
              useBinary
              hasBoxShadow
              // onClick={(value) => {
              //   if (value) {
              //     return dispatch(addInitialCollateralProviderState());
              //   } else {
              //     return dispatch(resetStepFourCollateralProviderForm());
              //   }
              // }}
            >
              <Typography
                variant="sp_save_draft_text"
                color="sp_primary_100_main"
              >
                いる
              </Typography>
            </SPCheckboxButton>
          </SPFormItem>
          <SPCollateralProviderModal
            open={modalHelp === ModalName.ABOUT_COLLATERAL_PROVIDER}
            onClose={() => setModalHelp('')}
          />
          <SPFormItem
            label="住信SBIネット銀行の「住宅ローンプラス」を申し込みますか？"
            help={[
              {
                name: ModalName.ABOUT_MORTGAGE_PLUS,
                helpTitle: modalName[ModalName.ABOUT_MORTGAGE_PLUS],
              },
            ]}
            onOpenHelpModal={(modalName) => setModalHelp(modalName)}
          >
            <SPCheckboxButton
              name="p_application_header.is_home_loan_plus"
              hasBoxShadow
            >
              <Typography
                variant="sp_save_draft_text"
                color="sp_primary_100_main"
              >
                申し込む
              </Typography>
            </SPCheckboxButton>
          </SPFormItem>
          <SPMortgagePlusModal
            open={modalHelp === ModalName.ABOUT_MORTGAGE_PLUS}
            onClose={() => setModalHelp('')}
          />
        </Stack>
        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepOnePage;

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);

const yearNumOptions: InputSelectProps['options'] = [
  { value: '', label: '年' },
].concat(
  Array.from(Array(50), (_, index) => {
    return {
      value: zeroPad(index + 1),
      label: (index + 1).toString(),
    };
  })
);

const landAdvancePlanOptions: Array<RadioItems> = [
  {
    value: LandAdvanceType.YES,
    label: landAdvance[LandAdvanceType.YES],
  },
  {
    value: LandAdvanceType.NO,
    label: landAdvance[LandAdvanceType.NO],
  },
];

const loanTargetZeroOptions: Array<RadioItems> = [
  {
    value: LoanTargetZeroType.ZERO,
    label: loanTargetZero[LoanTargetZeroType.ZERO],
  },
  {
    value: LoanTargetZeroType.SEVEN,
    label: loanTargetZero[LoanTargetZeroType.SEVEN],
  },
  {
    value: LoanTargetZeroType.EIGHT,
    label: loanTargetZero[LoanTargetZeroType.EIGHT],
  },
];
