import { Stack, Typography } from '@mui/material';
import {
  LoanType,
  NationalityType,
  SPTitle,
  TEXT_DOCUMENT_EMPTY,
} from 'constant';
import { Icons, SPButton, SPFormItem } from 'components';
import { routeNames } from 'navigations/routes';
import { FC, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  convertDynamicOptions,
  formatJapanDate,
  getFileExtFromUrl,
} from 'utils';
import { useNavigate } from 'react-router-dom';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { ImageContext } from 'context';
import { ImageItem } from '../ImageItems';
import { spStepTwoIncomeTotalSelector } from '../../../SPStepFourIncomeTotalizer/selectors';
import { spStepOneSelector } from '../../../SPStepOne/selectors';

type SPStepFourIncomeTotalizerInfoProps = {
  showEditButton?: boolean;
  reload?: number;
};

export const SPStepFourIncomeTotalizerInfo: FC<
  SPStepFourIncomeTotalizerInfoProps
> = ({ showEditButton = true, reload }) => {
  const navigate = useNavigate();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepOneValues = useSelector(spStepOneSelector);

  const sexOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.sex),
    [dynamicOptions.sex]
  );

  const nationalityOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nationality),
    [dynamicOptions.nationality]
  );

  const { residenceIncomeFile, residenceIncomeFileBackImage } =
    useContext(ImageContext);

  const {
    last_name_kanji,
    first_name_kanji,
    last_name_kana,
    first_name_kana,
    birthday,
    mobile_phone_number,
    home_phone_number,
    postal_code,
    prefecture_kanji,
    city_kanji,
    district_kanji,
    other_address_kanji,
    sex,
    nationality,
    name_relationship_to_applicant,
  } = spStepTwoIncomeTotalValues.p_applicant_people;

  const { loan_type } = spStepOneValues.p_application_header;

  let showMobileNumber = '';
  let showHomeNumber = '';
  if (!!mobile_phone_number)
    showMobileNumber += `〈携帯〉${mobile_phone_number}`;
  if (!!home_phone_number) showHomeNumber += `〈自宅〉${home_phone_number}`;
  if (!!mobile_phone_number && !!home_phone_number) showMobileNumber += '\n\n';

  return (
    <Stack>
      <Stack>
        <Stack
          direction="row"
          sx={{ px: 4, py: 1, bgcolor: 'sp_primary_100_main' }}
        >
          <Typography variant="sp_step_text" color="main_white" sx={{ mt: 1 }}>
            STEP
          </Typography>
          <Typography variant="sp_label_text" color="main_white">
            {SPTitle.STEP_FOUR_INCOME}
          </Typography>
        </Stack>
        <SPFormItem
          label="収入合算者のお名前"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Stack spacing={1}>
            <Typography variant="sp_value_text" color="b_333">
              {last_name_kanji} {first_name_kanji} 様
            </Typography>
            <Typography variant="sp_value_text" color="b_333">
              {last_name_kana} {first_name_kana} 様
            </Typography>
          </Stack>
        </SPFormItem>
        <SPFormItem
          label="性別"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {sexOptions.find((e) => e.value === sex) ? (
            <Typography variant="sp_value_text" color="b_333">
              {sexOptions.find((e) => e.value === sex)?.label}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>
        {loan_type === LoanType.THREE ? (
          <SPFormItem
            label="続柄"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Stack spacing={1}>
              <Typography variant="sp_value_text" color="b_333">
                {name_relationship_to_applicant
                  ? name_relationship_to_applicant
                  : 'ー'}
              </Typography>
            </Stack>
          </SPFormItem>
        ) : (
          ''
        )}
        <SPFormItem
          label="生年月日"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography variant="sp_value_text" color="b_333">
            {birthday ? formatJapanDate(birthday, true) : 'ー'}
          </Typography>
        </SPFormItem>
        <SPFormItem
          label="現在の国籍"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          {nationalityOptions.find((e) => e.value === nationality) ? (
            <Typography variant="sp_value_text" color="b_333">
              {nationalityOptions.find((e) => e.value === nationality)?.label}
            </Typography>
          ) : (
            <Typography variant="sp_value_text" color="b_333">
              ー
            </Typography>
          )}
        </SPFormItem>
        {nationality === NationalityType.FOREIGN_NATIONALITY ? (
          <SPFormItem
            label="在留カードまたは特別永住者証明書を添付してください。"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Stack spacing={2} direction="column">
              <Stack spacing={1}>
                <Typography variant="SP_label_field" color="b_333">
                  〈表面〉
                </Typography>
                {residenceIncomeFile.map((image, index) =>
                  (image instanceof File ? true : !image.hiden) ? (
                    <ImageItem
                      key={index}
                      fileName={image.name}
                      src={image instanceof File ? image : image.url}
                      reload={reload}
                      display={image instanceof File ? true : !image.hiden}
                      type={
                        image instanceof File
                          ? image.type
                          : getFileExtFromUrl(image.url)
                      }
                    />
                  ) : (
                    <Typography
                      variant="sp_value_text"
                      color="bg_radio_avatar"
                      textAlign="center"
                    >
                      {TEXT_DOCUMENT_EMPTY}
                    </Typography>
                  )
                )}
              </Stack>
              <Stack spacing={1}>
                <Typography variant="SP_label_field" color="b_333">
                  〈裏面〉
                </Typography>
                {residenceIncomeFileBackImage.map((image, index) =>
                  (image instanceof File ? true : !image.hiden) ? (
                    <ImageItem
                      key={index}
                      fileName={image.name}
                      src={image instanceof File ? image : image.url}
                      reload={reload}
                      display={image instanceof File ? true : !image.hiden}
                      type={
                        image instanceof File
                          ? image.type
                          : getFileExtFromUrl(image.url)
                      }
                    />
                  ) : (
                    <Typography
                      variant="sp_value_text"
                      color="bg_radio_avatar"
                      textAlign="center"
                    >
                      {TEXT_DOCUMENT_EMPTY}
                    </Typography>
                  )
                )}
              </Stack>
            </Stack>
          </SPFormItem>
        ) : (
          []
        )}
        <SPFormItem
          label="電話番号"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Typography variant="sp_value_text" color="b_333">
            {showMobileNumber || showHomeNumber
              ? showMobileNumber + showHomeNumber
              : 'ー'}
          </Typography>
        </SPFormItem>
        <SPFormItem
          label="現住所"
          labelSx={{ p: '3px 16px' }}
          childrenSx={{ p: '12px 24px 20px' }}
          required
        >
          <Stack spacing={1}>
            <Typography variant="sp_value_text" color="b_333">
              〒{postal_code}
            </Typography>{' '}
            <Typography variant="sp_value_text" color="b_333">
              {prefecture_kanji}
              {city_kanji}
              {district_kanji}
            </Typography>
            <Typography variant="sp_value_text" color="b_333">
              {other_address_kanji}
            </Typography>
          </Stack>
        </SPFormItem>

        <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
          <Stack
            sx={{
              paddingRight: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: 'none',
            }}
          >
            {showEditButton && (
              <SPButton
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: '10px',
                  borderWidth: 2,
                  bgcolor: 'sp_primary_40',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
                variant="outlined"
                fullWidth
                onClick={() =>
                  navigate(routeNames.SPStepFourIncomeTotalizer.path, {
                    state: { pathName: window.location.pathname },
                  })
                }
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icons.SPPencil />
                  <Typography
                    variant="sp_contentButton_text"
                    color="sp_primary_100_main"
                  >
                    STEP04 を修正する
                  </Typography>
                </Stack>
              </SPButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
