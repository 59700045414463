import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  CreateMessageResponse,
  DeleteMessageResponse,
  GetListUserResponse,
  GetMessageAdminRequest,
  GetMessageAdminResponse,
  GetMessageDetailAdminResponse,
  MessageError,
} from 'services';
import { ThunkAPI } from 'types';

export const getMessagesManager = createAsyncThunk<
  GetMessageAdminResponse,
  GetMessageAdminRequest,
  ThunkAPI<MessageError>
>('MessageManager/getMessages', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getMessagesManager(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMessagesSalePerson = createAsyncThunk<
  GetMessageAdminResponse,
  GetMessageAdminRequest,
  ThunkAPI<MessageError>
>('MessageSalePerson/getMessages', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getMessagesSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMessageDetailManager = createAsyncThunk<
  GetMessageDetailAdminResponse,
  string
>('MessageManager/getMessageDetail', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getMessageDetailManager(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMessageDetailSalePerson = createAsyncThunk<
  GetMessageDetailAdminResponse,
  string
>('MessageSalePerson/getMessageDetail', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getMessageDetailSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createMessageManager = createAsyncThunk<
  CreateMessageResponse,
  FormData
>('MessageManager/createMessages', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.createMessageManager(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createMessageSalePerson = createAsyncThunk<
  CreateMessageResponse,
  FormData
>('MessageSalePerson/createMessages', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.createMessageSalePerson(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListUserManager = createAsyncThunk<GetListUserResponse, void>(
  'MessageManager/getListUser',
  async (data, { rejectWithValue }) => {
    try {
      return await applicationsService.getListUserManager();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListUserSalePerson = createAsyncThunk<
  GetListUserResponse,
  void
>('MessageSalePerson/getListUser', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.getListUserSalePerson();
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteMessageManager = createAsyncThunk<
  DeleteMessageResponse,
  string
>('MessageManager/deleteMessages', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.deleteMessageManager(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
