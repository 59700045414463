import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetSavedApplicationRequest, GetSavedApplicationResponse } from 'types';
import { applicationsService } from 'services';

export const getSavedApplications = createAsyncThunk<
  GetSavedApplicationResponse,
  GetSavedApplicationRequest
>(
  'SavedApplicationsModal/getSavedApplications',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } =
        await applicationsService.getSavedApplications();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
