import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowForward: FC<SvgIconProps> = ({
  sx,
  ...props
}: SvgIconProps) => (
  <SvgIcon sx={{ ...sx }} viewBox="0 0 20 10" {...props}>
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66406 1.66406L4.9974 4.9974L1.66406 8.33073"
        stroke={props.stroke}
        strokeWidth={props.strokeWidth ? props.strokeWidth : '2'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
