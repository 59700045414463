import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const SalePersonLogoutAzurePage = () => {
  const location = useLocation();
  // @ts-ignore
  window.history.pushState(null, null, location.pathname);
  window.onpopstate = function (event) {
    return;
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw"
    >
      <Typography variant="total_text" color="b_333">
        ログアウトしました。
      </Typography>
    </Box>
  );
};

export default SalePersonLogoutAzurePage;
