import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';

export type ModalSaveInfoContextType = {
  openRecallSave: boolean;
  openSave: boolean;
  setRecallSave: Dispatch<SetStateAction<boolean>>;
  setSave: Dispatch<SetStateAction<boolean>>;
};

type ModalSaveInfoProviderProps = PropsWithChildren<{}>;

export const ModalSaveInfoContext = createContext<ModalSaveInfoContextType>({
  openRecallSave: false,
  setRecallSave() {},
  openSave: false,
  setSave() {},
});

export const ModalSaveInfoProvider: FC<ModalSaveInfoProviderProps> = ({
  children,
}) => {
  const [openRecallSave, setRecallSave] = useState<boolean>(false);
  const [openSave, setSave] = useState<boolean>(false);

  return (
    <ModalSaveInfoContext.Provider
      value={{
        openRecallSave,
        setRecallSave,
        openSave,
        setSave,
      }}
    >
      {children}
    </ModalSaveInfoContext.Provider>
  );
};
