import { InboxOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Icons } from 'components';
import { PreliminariesStatus } from 'constant';
import { Dispatch, FC, MouseEvent, SetStateAction } from 'react';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, OrderType, TableProps } from 'types';
import { ArchiveFileAdminType } from 'pages/ManagerDocumentUpload/slice';
import { ManagerDocumentUploadItems } from '../ManagerDocumentUploadItems';

type ManagerDocumentUploadTableProps = {
  activeTab?: string;
  fetching: boolean;
  onReset: Dispatch<SetStateAction<number>>;
  onResetSearchData: () => void;
};

export const ManagerDocumentUploadTable: FC<
  TableProps<ArchiveFileAdminType> & ManagerDocumentUploadTableProps
> = ({
  onRequestSort,
  onFilter,
  order,
  orderBy,
  rows,
  headCells,
  activeTab,
  fetching,
  onReset,
  onResetSearchData,
}) => {
  const createSortHandler =
    (property: keyof ArchiveFileAdminType) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const defaultHeadCells: HeadCellProps<ArchiveFileAdminType>[] = [
    {
      id: 'id',
      label: 'ファイルID',
      style: { width: 100 },
    },
    {
      id: 'file_name',
      label: 'ファイル名',
      style: {
        justifyContent: 'center',
        width: 350,
      },
    },
    {
      id: 'created_at',
      label: '登録日',
      style: {
        justifyContent: 'center',
        width: 120,
      },
    },
    {
      id: 'company_name',
      label: '提携先',
      style: {
        justifyContent: 'center',
        width: 220,
      },
    },
    {
      id: 'name_kanji',
      label: '担当者',
      style: {
        justifyContent: 'center',
        width: 140,
      },
    },
    {
      id: 'size',
      label: 'ファイル数',
      style: {
        justifyContent: 'center',
        width: 100,
      },
    },
    {
      id: 'note',
      label: '備考',
      style: {
        justifyContent: 'center',
        width: 178,
      },
    },
    {
      id: 'id',
      label: (
        <Stack
          direction="row"
          justifyContent="space-between"
          width="calc(100vw - 1228px)"
          position="relative"
        >
          <Icons.Filter
            sx={{
              mt: '14px',
              cursor: 'pointer',
              color: 'b_333',
              position: 'absolute',
              right: 10,
            }}
            onClick={() => {
              onFilter && onFilter();
            }}
          />
        </Stack>
      ),
      style: {
        alignItems: 'right',
      },
    },
  ];

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table aria-label="simple table">
        <TableHead
          sx={{
            display: 'flex',
            minWidth: 1024,
            p: '10px',
            bgcolor: 'bg_pale_gray',
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              bgcolor: 'main_white',
              flex: 1,
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            }}
            id="document-upload-table-body"
          >
            {(headCells ? headCells : defaultHeadCells).map(
              (headCell, index) => (
                <TableCell
                  key={headCell.id}
                  sx={{
                    ...headerCellStyle,
                    ...headCell.style,
                  }}
                >
                  <Typography
                    sx={{
                      direction: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="table_header_text"
                      sx={{ whiteSpace: 'nowrap' }}
                      color="b_333"
                    >
                      {headCell.label}
                    </Typography>
                    {headCell.smallLabel && (
                      <Typography
                        variant="table_small_text"
                        sx={{ whiteSpace: 'nowrap' }}
                        color="b_333"
                      >
                        {headCell.smallLabel}
                      </Typography>
                    )}
                    {typeof headCell.label === 'string' && (
                      <TableSort
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : undefined}
                        onClick={createSortHandler(headCell.id)}
                      />
                    )}
                  </Typography>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && (
            <TableRow>
              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                <Stack
                  sx={{
                    bgcolor: 'bg_off',
                    overflowX: 'auto',
                    height: 'auto',
                    maxHeight: ` calc(100vh - 130px)`,
                    flex: 1,
                    ...styleScrollBarTable,
                  }}
                  id="document-upload-table-body"
                >
                  <ManagerDocumentUploadItems
                    data={rows}
                    activeTab={
                      activeTab ? activeTab : PreliminariesStatus.CURRENT
                    }
                    onReset={onReset}
                    onResetSearchData={onResetSearchData}
                  />

                  {fetching && (
                    <Stack alignItems={'center'} sx={{ marginBottom: 3 }}>
                      <CircularProgress />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </TableRow>
          )}
          {!fetching && rows.length === 0 && (
            <Stack
              sx={{
                display: 'flex',
                height: 'calc(100vh - 110px)',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#d8d8d8',
                background: '#f8f8f8',
              }}
            >
              <InboxOutlined
                sx={{
                  width: 80,
                  height: 70,
                  marginRight: '5px',
                }}
              />
              <Typography sx={{ color: '#7d7d7d' }}>
                案件がありません。
              </Typography>
            </Stack>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type SortTableProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const TableSort: FC<SortTableProps> = ({ active, direction, onClick }) => {
  return (
    <IconButton sx={{ p: '2px' }} onClick={onClick}>
      {direction === undefined && (
        <Icons.SortDefault
          sx={{
            width: 16,
            height: 13,
            color: 'gray',
          }}
        />
      )}
      {direction === 'desc' && (
        <Icons.SortDesc
          sx={{
            width: 16,
            height: 13,
            color: 'sp_primary_100_main',
          }}
          stroke="#CCCCCC"
        />
      )}
      {direction === 'asc' && (
        <Icons.SortAsc
          sx={{
            width: 16,
            height: 13,
          }}
        />
      )}
    </IconButton>
  );
};

const headerCellStyle = {
  height: 40,
  p: 0,
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  border: 'none',
};
