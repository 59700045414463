import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogoHeader: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 110 18" {...props}>
      <svg
        width="110"
        height="18"
        viewBox="0 0 110 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.3983 6.04598C16.0691 6.76202 16.4028 7.7596 16.4028 9.03526V16.6788C16.4028 16.7553 16.375 16.8213 16.3229 16.8734C16.2707 16.9256 16.2047 16.9534 16.1282 16.9534H14.0253C13.9488 16.9534 13.8828 16.9256 13.8306 16.8734C13.775 16.8213 13.7507 16.7553 13.7507 16.6788V9.58793C13.7507 8.87885 13.563 8.31922 13.1841 7.90212C12.8053 7.48501 12.3012 7.27993 11.6721 7.27993C11.043 7.27993 10.5251 7.48848 10.1253 7.90212C9.72559 8.31922 9.52399 8.87189 9.52399 9.5636V16.6753C9.52399 16.7518 9.49618 16.8178 9.44405 16.87C9.39191 16.9221 9.32586 16.9499 9.24939 16.9499H7.14647C7.07 16.9499 7.00396 16.9221 6.95182 16.87C6.8962 16.8178 6.87187 16.7518 6.87187 16.6753V9.58446C6.87187 8.87537 6.6807 8.31575 6.29487 7.89864C5.90904 7.48153 5.40156 7.27645 4.77242 7.27645C4.18847 7.27645 3.70532 7.44677 3.32992 7.78393C2.95105 8.1211 2.73206 8.58339 2.67297 9.17082V16.6753C2.67297 16.7518 2.64517 16.8178 2.59303 16.87C2.54089 16.9221 2.47485 16.9499 2.39838 16.9499H0.274595C0.198125 16.9499 0.132083 16.9221 0.0799444 16.87C0.0243299 16.8178 0 16.7518 0 16.6753V5.43422C0 5.35775 0.0278058 5.29171 0.0799444 5.23957C0.132083 5.18743 0.198125 5.15962 0.274595 5.15962H2.39838C2.47485 5.15962 2.54089 5.18743 2.59303 5.23957C2.64517 5.29518 2.67297 5.35775 2.67297 5.43422V6.19544C2.67297 6.24063 2.68688 6.27191 2.71816 6.28929C2.74944 6.3032 2.78073 6.28929 2.81201 6.24411C3.14917 5.81309 3.56976 5.49331 4.07029 5.28476C4.57082 5.0762 5.12697 4.97192 5.74568 4.97192C6.49995 4.97192 7.16037 5.12486 7.73042 5.43422C8.30047 5.74358 8.73844 6.18849 9.04779 6.77245C9.09298 6.83501 9.14859 6.83501 9.21116 6.77245C9.54832 6.15721 10.021 5.70186 10.6189 5.40989C11.2202 5.11791 11.8876 4.97192 12.628 4.97192C13.8133 4.97192 14.7413 5.32994 15.4122 6.04598H15.3983Z"
          fill="#212121"
        />
        <path
          d="M19.2465 3.08093C18.9371 2.77157 18.7842 2.38227 18.7842 1.90259C18.7842 1.42292 18.9371 1.03361 19.2465 0.724259C19.5558 0.414903 19.9486 0.261963 20.4248 0.261963C20.901 0.261963 21.2938 0.414903 21.6031 0.724259C21.9125 1.03361 22.0654 1.42639 22.0654 1.90259C22.0654 2.37879 21.9125 2.77157 21.6031 3.08093C21.2938 3.39028 20.901 3.54322 20.4248 3.54322C19.9486 3.54322 19.5558 3.39028 19.2465 3.08093ZM19.1179 16.8768C19.0622 16.8247 19.0379 16.7586 19.0379 16.6822V5.41326C19.0379 5.33679 19.0657 5.27075 19.1179 5.21861C19.17 5.16647 19.236 5.13866 19.3125 5.13866H21.4363C21.5128 5.13866 21.5788 5.16647 21.6309 5.21861C21.6831 5.27422 21.7109 5.33679 21.7109 5.41326V16.6822C21.7109 16.7586 21.6831 16.8247 21.6309 16.8768C21.5753 16.9324 21.5128 16.9568 21.4363 16.9568H19.3125C19.236 16.9568 19.17 16.9289 19.1179 16.8768V16.8768Z"
          fill="#212121"
        />
        <path
          d="M24.6376 16.8767C24.5819 16.8245 24.5576 16.7585 24.5576 16.682V1.07172C24.5576 0.995246 24.5854 0.929204 24.6376 0.877065C24.6897 0.824926 24.7557 0.797119 24.8322 0.797119H26.956C27.0325 0.797119 27.0985 0.824926 27.1506 0.877065C27.2028 0.932679 27.2306 0.995246 27.2306 1.07172V16.682C27.2306 16.7585 27.2028 16.8245 27.1506 16.8767C27.0985 16.9288 27.0325 16.9566 26.956 16.9566H24.8322C24.7557 16.9566 24.6897 16.9288 24.6376 16.8767V16.8767Z"
          fill="#212121"
        />
        <path
          d="M30.1881 3.08093C29.8788 2.77157 29.7258 2.38227 29.7258 1.90259C29.7258 1.42292 29.8788 1.03361 30.1881 0.724259C30.4975 0.414903 30.8903 0.261963 31.3665 0.261963C31.8427 0.261963 32.2354 0.414903 32.5448 0.724259C32.8541 1.03361 33.0071 1.42639 33.0071 1.90259C33.0071 2.37879 32.8541 2.77157 32.5448 3.08093C32.2354 3.39028 31.8427 3.54322 31.3665 3.54322C30.8903 3.54322 30.4975 3.39028 30.1881 3.08093ZM30.0595 16.8768C30.0039 16.8247 29.9796 16.7586 29.9796 16.6822V5.41326C29.9796 5.33679 30.0074 5.27075 30.0595 5.21861C30.1117 5.16647 30.1777 5.13866 30.2542 5.13866H32.378C32.4544 5.13866 32.5205 5.16647 32.5726 5.21861C32.6247 5.27422 32.6525 5.33679 32.6525 5.41326V16.6822C32.6525 16.7586 32.6247 16.8247 32.5726 16.8768C32.517 16.9324 32.4544 16.9568 32.378 16.9568H30.2542C30.1777 16.9568 30.1117 16.9289 30.0595 16.8768V16.8768Z"
          fill="#212121"
        />
        <path
          d="M34.7972 16.8769C34.7416 16.8248 34.7173 16.7587 34.7173 16.6822V14.7184C34.7173 14.5793 34.7555 14.4716 34.832 14.3951L40.5116 7.72135C40.5881 7.62751 40.5638 7.58232 40.4421 7.58232H35.1066C35.0301 7.58232 34.9641 7.55451 34.9119 7.50237C34.8563 7.45023 34.832 7.38419 34.832 7.30772V5.43768C34.832 5.36121 34.8598 5.29517 34.9119 5.24303C34.9641 5.19089 35.0301 5.16309 35.1066 5.16309H43.5113C43.5878 5.16309 43.6538 5.19089 43.706 5.24303C43.7581 5.29865 43.7859 5.36121 43.7859 5.43768V7.40157C43.7859 7.52323 43.7407 7.63098 43.6469 7.72483L37.8491 14.3986C37.7865 14.4924 37.8108 14.5376 37.9186 14.5376H43.5774C43.6538 14.5376 43.7199 14.5654 43.772 14.6176C43.8242 14.6732 43.852 14.7357 43.852 14.8122V16.6822C43.852 16.7587 43.8242 16.8248 43.772 16.8769C43.7199 16.929 43.6538 16.9568 43.5774 16.9568H34.9884C34.9119 16.9568 34.8459 16.929 34.7938 16.8769H34.7972Z"
          fill="#212121"
        />
        <path
          d="M55.3611 11.0235L55.3368 11.7395C55.3368 11.9237 55.2429 12.0176 55.0587 12.0176H47.8323C47.7558 12.0176 47.7176 12.0558 47.7176 12.1323C47.7628 12.6398 47.8323 12.9874 47.9261 13.1716C48.2946 14.2804 49.1948 14.8435 50.6269 14.8574C51.6732 14.8574 52.5039 14.4264 53.1191 13.5644C53.1817 13.4705 53.2582 13.4253 53.3486 13.4253C53.4111 13.4253 53.4633 13.4497 53.5084 13.4949L54.9405 14.6732C55.0796 14.7809 55.1004 14.9026 55.01 15.0416C54.5338 15.7194 53.8977 16.2408 53.1052 16.6127C52.3127 16.9812 51.4299 17.1654 50.4601 17.1654C49.2609 17.1654 48.239 16.8978 47.4013 16.3555C46.5636 15.8168 45.9483 15.0625 45.566 14.0927C45.2115 13.262 45.0342 12.108 45.0342 10.6272C45.0342 9.73392 45.128 9.01093 45.3123 8.45826C45.6216 7.38073 46.2021 6.52913 47.0572 5.90694C47.9122 5.28476 48.9376 4.97192 50.1403 4.97192C53.1713 4.97192 54.8814 6.55694 55.2673 9.73044C55.3298 10.1163 55.3611 10.5473 55.3611 11.0235V11.0235ZM48.7221 7.71094C48.3432 7.99597 48.0791 8.39222 47.9261 8.8997C47.8323 9.16039 47.7732 9.50103 47.7419 9.91467C47.7106 9.99114 47.7419 10.0294 47.8358 10.0294H52.5248C52.6012 10.0294 52.6395 9.99114 52.6395 9.91467C52.6082 9.52884 52.563 9.23687 52.5004 9.03874C52.3614 8.48607 52.0868 8.05506 51.6801 7.7457C51.2734 7.43634 50.759 7.2834 50.1438 7.2834C49.5737 7.2834 49.101 7.42592 48.7221 7.71094V7.71094Z"
          fill="#212121"
        />
        <path
          d="M68.0208 0C69.4196 0 70.5386 0.460765 71.3778 1.38229C72.2335 2.28737 72.6614 3.48865 72.6614 4.98613C72.6614 6.4507 72.2335 7.63553 71.3778 8.5406C70.5386 9.42922 69.4196 9.87353 68.0208 9.87353H64.7132C64.6474 9.87353 64.6144 9.90644 64.6144 9.97226V17.0565C64.6144 17.2211 64.5322 17.3034 64.3676 17.3034H62.4916C62.3271 17.3034 62.2448 17.2211 62.2448 17.0565V0.246838C62.2448 0.0822793 62.3271 0 62.4916 0H68.0208ZM67.6752 7.97287C68.4651 7.97287 69.0987 7.70958 69.5759 7.18299C70.0696 6.63995 70.3164 5.92412 70.3164 5.0355C70.3164 4.13043 70.0696 3.40637 69.5759 2.86332C69.0987 2.32028 68.4651 2.04876 67.6752 2.04876H64.7132C64.6474 2.04876 64.6144 2.08167 64.6144 2.14749V7.87414C64.6144 7.93996 64.6474 7.97287 64.7132 7.97287H67.6752Z"
          fill="currentColor"
        />
        <path
          d="M78.3154 4.54182C79.6812 4.54182 80.7756 4.92853 81.5984 5.70196C82.4211 6.45893 82.8325 7.45451 82.8325 8.6887V17.0565C82.8325 17.2211 82.7503 17.3034 82.5857 17.3034H80.7097C80.5452 17.3034 80.4629 17.2211 80.4629 17.0565V16.3407C80.4629 16.3078 80.4464 16.2913 80.4135 16.2913C80.3971 16.2749 80.3724 16.2831 80.3395 16.316C79.599 17.1059 78.5705 17.5008 77.254 17.5008C76.1844 17.5008 75.2711 17.2128 74.5141 16.6369C73.7571 16.0445 73.3786 15.0983 73.3786 13.7983C73.3786 12.4489 73.8147 11.4286 74.6869 10.7375C75.5755 10.0299 76.7768 9.67606 78.2907 9.67606H80.3642C80.43 9.67606 80.4629 9.64314 80.4629 9.57732V8.88617C80.4629 8.17857 80.2737 7.61907 79.8952 7.20767C79.5331 6.79628 79.0066 6.59058 78.3154 6.59058C77.7724 6.59058 77.3198 6.73045 76.9578 7.0102C76.6122 7.2735 76.3983 7.63553 76.316 8.09629C76.2996 8.24439 76.2091 8.31845 76.0445 8.31845L74.0204 8.19503C73.9546 8.19503 73.897 8.17857 73.8476 8.14566C73.7983 8.09629 73.7818 8.0387 73.7983 7.97287C73.897 6.95261 74.3578 6.12981 75.1806 5.50449C76.0198 4.86271 77.0648 4.54182 78.3154 4.54182ZM77.7724 15.4768C78.4964 15.4768 79.1217 15.2711 79.6483 14.8597C80.1914 14.4483 80.4629 13.897 80.4629 13.2058V11.6261C80.4629 11.5603 80.43 11.5273 80.3642 11.5273H78.4635C77.6572 11.5273 77.0072 11.7084 76.5135 12.0704C76.0363 12.4324 75.7977 12.959 75.7977 13.6502C75.7977 14.259 75.9787 14.7198 76.3407 15.0324C76.7027 15.3286 77.18 15.4768 77.7724 15.4768Z"
          fill="currentColor"
        />
        <path
          d="M90.3104 4.56651C91.5117 4.56651 92.4415 4.93676 93.0997 5.67728C93.7744 6.41779 94.1118 7.43806 94.1118 8.73807V17.0565C94.1118 17.2211 94.0295 17.3034 93.8649 17.3034H91.9889C91.8244 17.3034 91.7421 17.2211 91.7421 17.0565V9.08364C91.7421 8.32667 91.5446 7.72603 91.1497 7.28173C90.7712 6.83742 90.2529 6.61526 89.5946 6.61526C88.9199 6.61526 88.3769 6.84564 87.9655 7.30641C87.5705 7.75072 87.3731 8.35136 87.3731 9.10833V17.0565C87.3731 17.2211 87.2908 17.3034 87.1262 17.3034H85.2503C85.0857 17.3034 85.0034 17.2211 85.0034 17.0565V4.98613C85.0034 4.82157 85.0857 4.73929 85.2503 4.73929H87.1262C87.2908 4.73929 87.3731 4.82157 87.3731 4.98613V5.75133C87.3731 5.8007 87.3813 5.83361 87.3978 5.85006C87.4307 5.85006 87.4636 5.82538 87.4965 5.77601C88.1383 4.96968 89.0763 4.56651 90.3104 4.56651Z"
          fill="currentColor"
        />
        <path
          d="M100.604 15.4521C101.163 15.4521 101.632 15.3204 102.011 15.0571C102.406 14.7774 102.702 14.3989 102.899 13.9217C102.949 13.7736 103.047 13.716 103.195 13.7489L104.874 14.1191C105.038 14.1685 105.096 14.2672 105.047 14.4153C104.734 15.3862 104.191 16.1432 103.418 16.6863C102.644 17.2293 101.698 17.5008 100.579 17.5008C99.2954 17.5008 98.2586 17.1799 97.4688 16.5382C96.6789 15.8964 96.177 15.016 95.963 13.897C95.8808 13.4362 95.8232 12.9508 95.7903 12.4406C95.7738 11.9141 95.7656 11.2229 95.7656 10.3672C95.7656 9.52795 95.8067 8.86149 95.889 8.36781C96.0371 7.2159 96.5225 6.29437 97.3453 5.60323C98.1681 4.89562 99.2048 4.54182 100.455 4.54182C101.821 4.54182 102.924 4.92853 103.763 5.70196C104.602 6.47539 105.071 7.49565 105.17 8.76275C105.219 9.32225 105.244 10.3507 105.244 11.8482C105.244 12.0128 105.162 12.0951 104.997 12.0951H98.234C98.1681 12.0951 98.1352 12.128 98.1352 12.1938C98.1352 12.6546 98.1599 13.0577 98.2093 13.4033C98.2915 14.0122 98.5466 14.5059 98.9745 14.8843C99.4023 15.2628 99.9454 15.4521 100.604 15.4521ZM100.48 6.59058C99.8549 6.59058 99.3365 6.77982 98.9251 7.15831C98.5302 7.53679 98.2915 8.03047 98.2093 8.63934C98.1599 9.08365 98.1352 9.56909 98.1352 10.0957C98.1352 10.1615 98.1681 10.1944 98.234 10.1944H102.776C102.842 10.1944 102.875 10.1615 102.875 10.0957C102.842 9.43745 102.809 8.96023 102.776 8.66402C102.693 8.05515 102.447 7.56148 102.035 7.18299C101.624 6.78805 101.106 6.59058 100.48 6.59058Z"
          fill="currentColor"
        />
        <path
          d="M107.877 17.4679C107.713 17.4679 107.63 17.3856 107.63 17.2211V0.43608C107.63 0.271521 107.713 0.189242 107.877 0.189242H109.753C109.918 0.189242 110 0.271521 110 0.43608V17.2211C110 17.3856 109.918 17.4679 109.753 17.4679H107.877Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
