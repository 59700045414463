import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import { MemoItem } from 'services';
import { getMemos } from './thunk';

interface InitialState {
  memos: EntityState<MemoItem>;
  total: number;
}

export const memoAdapter = createEntityAdapter<MemoItem>({});

export const initialState: InitialState = {
  memos: memoAdapter.getInitialState({}),
  total: 0,
};

const memoSlice = createSlice({
  name: 'memos',
  initialState,
  reducers: {
    revertMemo: (state) => {
      state.memos = memoAdapter.getInitialState({});
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMemos.fulfilled, (state, action) => {
      memoAdapter.upsertMany(state.memos, action.payload.data);
      state.total = action.payload.total;
    });
  },
});

export const { revertMemo } = memoSlice.actions;

export default memoSlice.reducer;
