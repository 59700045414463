import {
  AdminPreliminaries,
  DynamicOption,
  SalePersonPreliminaries,
} from 'services';
import {
  ChildType,
  FinanceAgencyType,
  NationalityType,
  PlannedCohabitantType,
  RelationshipType,
  ResidentType,
  SexType,
} from 'constant';
import { cloneDeep, findLastIndex, get } from 'lodash';
import { getHousemateNumber } from './buildPersonalRows';

type ResidentsAttributes =
  AdminPreliminaries['p_application_header']['p_residents_attributes'];

export function buildResidentsAttributes(
  applicationHeader:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header'],
  plannedCohabitantDynamic: DynamicOption
): ResidentsAttributes {
  const {
    p_residents_attributes: residentsAttributes,
    siblings_number: siblingsNumber,
    children_number: childrenNumber,
    other_people_number: otherPeopleNumber,
    planned_cohabitant: plannedCohabitant,
  } = applicationHeader;

  const housemateNumber = getHousemateNumber(plannedCohabitantDynamic, {
    planned_cohabitant: plannedCohabitant,
    siblings_number: siblingsNumber,
    children_number: childrenNumber,
    other_people_number: otherPeopleNumber,
  });

  if (!housemateNumber) {
    return [];
  }

  if (housemateNumber >= 6 && residentsAttributes.length >= 6) {
    return residentsAttributes;
  }

  const residentsAttributesClone = cloneDeep(residentsAttributes);
  const newResidentsAttributes: ResidentsAttributes = [];
  const isUserTyped = !!residentsAttributes?.find(
    ({ resident_type }) => resident_type === ResidentType.USER
  );
  plannedCohabitantDynamic.value.forEach(({ option_code }) => {
    if (newResidentsAttributes.length === 6) {
      return;
    }
    const optionCode = option_code.toString() as PlannedCohabitantType;
    if (!plannedCohabitant.includes(optionCode)) {
      return;
    }

    let residentsAttributesSelected: ResidentsAttributes =
      getResidentsAttributes(residentsAttributes, +optionCode);

    if (+optionCode === +RelationshipType.CHILD) {
      newResidentsAttributes.push(
        ...makeResidentsAttribute(
          residentsAttributesSelected,
          +childrenNumber || 0,
          +optionCode,
          isUserTyped
        )
      );
      return;
    }
    if (+optionCode === +RelationshipType.SIBLINGS) {
      newResidentsAttributes.push(
        ...makeResidentsAttribute(
          residentsAttributesSelected,
          +siblingsNumber || 0,
          +optionCode,
          isUserTyped
        )
      );
      return;
    }
    if (+optionCode === +RelationshipType.OTHERS) {
      newResidentsAttributes.push(
        ...makeResidentsAttribute(
          residentsAttributesSelected,
          +otherPeopleNumber || 0,
          +optionCode,
          isUserTyped
        )
      );
      return;
    }

    if (residentsAttributesSelected.length) {
      newResidentsAttributes.push(...residentsAttributesSelected);
    } else {
      newResidentsAttributes.push(
        generateResidentsAttributeDefault(+optionCode, isUserTyped)
      );
    }
  });

  const number = Math.min(housemateNumber, 6) - residentsAttributesClone.length;
  makeArrayFromNumber(number).forEach((index) => {
    const { relationship } = get(newResidentsAttributes, index, {
      relationship: RelationshipType.DEFAULT,
    });
    residentsAttributesClone.push(
      generateResidentsAttributeDefault(+relationship, isUserTyped)
    );
  });

  return residentsAttributesClone;
}

export function removeResidentsAttributes(
  residentsAttributes:
    | AdminPreliminaries['p_application_header']['p_residents_attributes']
    | SalePersonPreliminaries['p_application_header']['p_residents_attributes'],
  relationships: RelationshipType[],
  housemateNumber: number
): ResidentsAttributes {
  const length = residentsAttributes.length - housemateNumber;
  let residentsAttributesClone = cloneDeep(residentsAttributes);
  relationships.slice(0, length).forEach((relationship) => {
    const index = findLastIndex(
      residentsAttributesClone,
      (o) => relationship === o.relationship
    );
    residentsAttributesClone.splice(
      index >= 0 ? index : residentsAttributesClone.length - 1,
      1
    );
  });
  return residentsAttributesClone;
}

export function addResidentsAttributes(
  residentsAttributes:
    | AdminPreliminaries['p_application_header']['p_residents_attributes']
    | SalePersonPreliminaries['p_application_header']['p_residents_attributes'],
  relationship: RelationshipType
) {
  if (residentsAttributes.length >= 6) {
    return residentsAttributes;
  }
  return [
    ...residentsAttributes,
    generateResidentsAttributeDefault(+relationship, false),
  ];
}

function generateResidentsAttributeDefault(
  relationship: number,
  isUserTyped: boolean
): ResidentsAttributes[number] {
  return {
    relationship: isUserTyped
      ? RelationshipType.DEFAULT
      : (relationship.toString() as RelationshipType),
    last_name_kanji: '',
    first_name_kanji: '',
    last_name_kana: '',
    first_name_kana: '',
    relationship_name: '',
    nationality: NationalityType.DEFAULT,
    birthday: '',
    loan_from_japan_housing_finance_agency: FinanceAgencyType.DEFAULT,
    home_phone_number: '',
    postal_code: '',
    prefecture_kanji: '',
    city_kanji: '',
    district_kanji: '',
    other_address_kanji: '',
    other_relationship: ChildType.DEFAULT,
    sex: SexType.DEFAULT,
    full_name_kanji: '',
    full_name_kana: '',
    resident_type: ResidentType.ADMIN,
  };
}

function makeResidentsAttribute(
  residentsAttributesSelected: ResidentsAttributes,
  length: number,
  relationship: number,
  isUserTyped: boolean
): ResidentsAttributes {
  return makeArrayFromNumber(length).map((index) => {
    if (residentsAttributesSelected[index]) {
      return residentsAttributesSelected[index];
    }
    return generateResidentsAttributeDefault(relationship, isUserTyped);
  });
}

function getResidentsAttributes(
  residentsAttributes: ResidentsAttributes,
  relation: number
): ResidentsAttributes {
  return residentsAttributes.filter(
    ({ relationship }) => +relationship === +relation
  );
}

export function makeArrayFromNumber(length: number): number[] {
  return Array.from(Array(length < 0 ? 0 : length).keys());
}

export function getResidentsAttributesAfterSelect(
  currentResidentsAttributes: ResidentsAttributes,
  newResidentsAttributes: ResidentsAttributes
): ResidentsAttributes {
  let newResidentsAttributesClone = cloneDeep(newResidentsAttributes);
  return currentResidentsAttributes
    .map((cra) => {
      if (!cra.relationship) return cra;
      const index = newResidentsAttributesClone.findIndex(
        ({ relationship }) => relationship === cra.relationship
      );

      if (index < 0) {
        return undefined;
      }
      newResidentsAttributesClone.splice(index, 1);
      return cra;
    })
    .filter((o) => !!o)
    .slice(0, newResidentsAttributes.length) as ResidentsAttributes;
}
