import { createSelector } from 'reselect';
import { AdminPreliminaries } from 'services';
import { RootState } from 'types';
import { LoanType } from 'constant';
import { plannedCohabitantSelectors } from '../SPStartScreen/selectors';
import { buildResidentsAttributes, getHousemateNumber } from './utils';

export const loadingSelector = (state: RootState) =>
  state.adminPreliminaryReducer.fetching;
export const saveButtonSelector = (state: RootState) =>
  state.adminPreliminaryReducer.isDisableSaveButton;

export const adminPreliminariesSelector = (state: RootState) =>
  state.adminPreliminaryReducer.adminPreliminaries;

export const residentsAttributesOriginalSelector = createSelector(
  adminPreliminariesSelector,
  (state) => state.p_application_header.p_residents_attributes
);

export const borrowingDetailsOriginalSelector = createSelector(
  adminPreliminariesSelector,
  (state) => state.p_application_header.p_borrowing_details_attributes
);

export const joinGuarantorsAttributesOriginalSelector = createSelector(
  adminPreliminariesSelector,
  (state) => state.p_application_header.p_join_guarantors_attributes
);

export const borrowingsAttributesOriginalSelector = createSelector(
  adminPreliminariesSelector,
  (state) => state.p_application_header.p_borrowings_attributes
);

export const peopleAttributesOriginalSelector = createSelector(
  adminPreliminariesSelector,
  (state) => state.p_application_header.p_applicant_people_attributes
);

export const adminLinkPreliminariesSelector = (state: RootState) =>
  state.adminPreliminaryReducer.adminLinkPreliminaries;

export const salePersonAdminOptionsSelector = (state: RootState) => ({
  agenciesAdminOptions: state.adminPreliminaryReducer.agenciesOptions,
  storeAdminOptions: state.adminPreliminaryReducer.storesOptions,
  exhibitionsAdminOptions: state.adminPreliminaryReducer.exhibitionsOptions,
  salePersonAdminOptions: state.adminPreliminaryReducer.salePersonsOptions,
});

export const dataManagerAsyncSelector = (state: RootState) => ({
  timeGetEdit: state.adminPreliminaryReducer.timeGetEdit,
  sbiId: state.adminPreliminaryReducer.sbiId,
});

export const salePersonAdminOptionsLinkSelector = (state: RootState) => ({
  agenciesAdminOptions: state.adminPreliminaryReducer.agenciesOptionsLink,
  storeAdminOptions: state.adminPreliminaryReducer.storesOptionsLink,
  exhibitionsAdminOptions: state.adminPreliminaryReducer.exhibitionsOptionsLink,
  salePersonAdminOptions: state.adminPreliminaryReducer.salePersonsOptionsLink,
});

export const filesSelectors = (state: RootState) => ({
  files: state.adminPreliminaryReducer.files,
  filesIncomeAdder: state.adminPreliminaryReducer.filesIncomeAdder,
  filesLink: state.adminPreliminaryReducer.filesLink,
  filesBorrowing: state.adminPreliminaryReducer.filesBorrowing,
  filesHeader: state.adminPreliminaryReducer.filesHeader,
  filesLinkHeader: state.adminPreliminaryReducer.filesLinkHeader,
  filesLinkBorrowing: state.adminPreliminaryReducer.filesLinkBorrowing,
  filesRemove: state.adminPreliminaryReducer.filesRemove,
  filesIncomeAdderRemove: state.adminPreliminaryReducer.filesIncomeAdderRemove,
  filesBorrowingRemove: state.adminPreliminaryReducer.filesBorrowingRemove,
  filesHeaderRemove: state.adminPreliminaryReducer.filesHeaderRemove,
  isLoadingFiles: state.adminPreliminaryReducer.isLoadingFiles,
});

export const rebuildResidentsAttributesSelector = createSelector(
  adminPreliminariesSelector,
  plannedCohabitantSelectors,
  ({ p_application_header }, plannedCohabitantDynamic) => {
    return {
      p_residents_attributes: buildResidentsAttributes(
        p_application_header,
        plannedCohabitantDynamic
      ),
    };
  }
);

export const rebuildResidentsAttributesLinkSelector = createSelector(
  adminLinkPreliminariesSelector,
  plannedCohabitantSelectors,
  ({ p_application_header }, plannedCohabitantDynamic) => {
    return {
      p_residents_attributes: buildResidentsAttributes(
        p_application_header,
        plannedCohabitantDynamic
      ),
    };
  }
);

export const initialValuesSelector = createSelector(
  adminPreliminariesSelector,
  rebuildResidentsAttributesSelector,
  plannedCohabitantSelectors,
  (
    state: AdminPreliminaries,
    { p_residents_attributes },
    plannedCohabitantDynamic
  ) => ({
    p_application_header: {
      ...state.p_application_header,
      p_residents_attributes,
      housemate_number: getHousemateNumber(
        plannedCohabitantDynamic,
        state.p_application_header
      ).toString(),
    },
  })
);

export const initialLinkValuesSelector = createSelector(
  adminLinkPreliminariesSelector,
  rebuildResidentsAttributesLinkSelector,
  (state: AdminPreliminaries, { p_residents_attributes }) => ({
    p_application_header: {
      ...state.p_application_header,
      p_residents_attributes,
    },
  })
);

export const provisionalResults = (state: RootState) =>
  state.adminPreliminaryReducer.adminPreliminaries.p_application_header
    .provisional_results;

export const checkIncomeTotalizer = createSelector(
  adminPreliminariesSelector,
  (input) => {
    const selectedIncome = input.p_application_header.loan_type;
    return (
      selectedIncome === LoanType.THREE || selectedIncome === LoanType.FOUR
    );
  }
);

export const checkHasJoinGuarantor = createSelector(
  adminPreliminariesSelector,
  (input) => {
    return input.p_application_header.p_applicant_people_attributes[0]
      ?.has_join_guarantor;
  }
);
