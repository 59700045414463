import { FC, useCallback, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { hiddenScrollBar } from 'styles/toolkit';
import { zeroPad } from 'utils';
import { isMobile } from 'react-device-detect';
import Scroll from 'react-scroll';
import { Icons } from '..';

export type SPSteps = {
  label: string;
};

type SPStepBarProps = {
  currentStep: number;
  steps: SPSteps[];
  hasJoinGuarantor: boolean;
  hasTotalIncome: boolean;
};

const Element = Scroll.Element;
const scroller = Scroll.scroller;

export const SPStepBar: FC<SPStepBarProps> = ({
  steps,
  currentStep,
  hasJoinGuarantor,
  hasTotalIncome,
}) => {
  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(`step-${currentStep ?? 0}`, {
        duration: 300,
        smooth: true,
        containerId: 'sp-header-steps',
        isDynamic: true,
        horizontal: true,
      });
    }, 200);
  }, [currentStep]);

  const checkColor = useCallback(
    (index: number) => {
      if (currentStep === index) return 'main_white';
      if (currentStep > index) return 'sp_primary_60';
      return 'sp_primary_100_main';
    },
    [currentStep]
  );

  const checkColorIcon = useCallback(
    (index: number) => {
      if (currentStep === index) return 'main_white';
      if (currentStep > index) return 'sp_primary_60';
      return 'sp_primary_100_main';
    },
    [currentStep]
  );

  const checkBgColor = useCallback(
    (index: number) => {
      if (currentStep === index) return 'sp_primary_100_main';
      if (currentStep > index) return 'sp_primary_40';
      return 'main_white';
    },
    [currentStep]
  );

  const renderIcon = useCallback(
    (index: number) => {
      const color = checkColorIcon(index);
      switch (index) {
        case 1:
          return <Icons.SPStep01Checked sx={{ color }} />;
        case 2:
          return currentStep < index ? (
            <Icons.SPStep02 />
          ) : (
            <Icons.SPStep02Checked sx={{ color }} />
          );
        case 3:
          return currentStep < index ? (
            <Icons.SPStep03 />
          ) : (
            <Icons.SPStep03Checked sx={{ color }} />
          );
        case 4:
          if (hasJoinGuarantor || hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep02 />
            ) : (
              <Icons.SPStep02Checked sx={{ color }} />
            );
          return currentStep < index ? (
            <Icons.SPStep04 />
          ) : (
            <Icons.SPStep04Checked sx={{ color }} />
          );
        case 5:
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep03 />
            ) : (
              <Icons.SPStep03Checked sx={{ color }} />
            );
          if (hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep04 />
            ) : (
              <Icons.SPStep04Checked sx={{ color }} />
            );
          return currentStep < index ? (
            <Icons.SPStep05 />
          ) : (
            <Icons.SPStep05Checked sx={{ color }} />
          );
        case 6:
          if (hasJoinGuarantor && hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep02 />
            ) : (
              <Icons.SPStep02Checked sx={{ color }} />
            );
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep04 />
            ) : (
              <Icons.SPStep04Checked sx={{ color }} />
            );
          if (hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep05 />
            ) : (
              <Icons.SPStep05Checked sx={{ color }} />
            );
          return currentStep < index ? (
            <Icons.SPStep06 />
          ) : (
            <Icons.SPStep06Checked sx={{ color }} />
          );
        case 7:
          if (hasTotalIncome && hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep04 />
            ) : (
              <Icons.SPStep04Checked sx={{ color }} />
            );
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep05 />
            ) : (
              <Icons.SPStep05Checked sx={{ color }} />
            );
          if (hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep06 />
            ) : (
              <Icons.SPStep06Checked sx={{ color }} />
            );
          return currentStep < index ? (
            <Icons.SPStep07 />
          ) : (
            <Icons.SPStep07Checked sx={{ color }} />
          );
        case 8:
          if (hasTotalIncome && hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep05 />
            ) : (
              <Icons.SPStep05Checked sx={{ color }} />
            );
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep06 />
            ) : (
              <Icons.SPStep06Checked sx={{ color }} />
            );
          if (hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep07 />
            ) : (
              <Icons.SPStep07Checked sx={{ color }} />
            );
          return currentStep < index ? (
            <Icons.SPStep08 />
          ) : (
            <Icons.SPStep08Checked sx={{ color }} />
          );
        case 9:
          if (hasTotalIncome && hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep06 />
            ) : (
              <Icons.SPStep06Checked sx={{ color }} />
            );
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep07 />
            ) : (
              <Icons.SPStep07Checked sx={{ color }} />
            );
          if (hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep08 />
            ) : (
              <Icons.SPStep08Checked sx={{ color }} />
            );
          return currentStep < index ? (
            <Icons.SPStep09 />
          ) : (
            <Icons.SPStep09Checked sx={{ color }} />
          );
        case 10:
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep07 />
            ) : (
              <Icons.SPStep07Checked sx={{ color }} />
            );
          if (hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep09 />
            ) : (
              <Icons.SPStep09Checked sx={{ color }} />
            );
          return currentStep < index ? (
            <Icons.SPStep10 />
          ) : (
            <Icons.SPStep10Checked sx={{ color }} />
          );
        case 11:
          if (hasTotalIncome && hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep07 />
            ) : (
              <Icons.SPStep07Checked sx={{ color }} />
            );
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep08 />
            ) : (
              <Icons.SPStep08Checked sx={{ color }} />
            );
          if (hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep10 />
            ) : (
              <Icons.SPStep10Checked sx={{ color }} />
            );
          //TODO: check case total income
          return currentStep < index ? (
            <Icons.SPStep10 />
          ) : (
            <Icons.SPStep10Checked sx={{ color }} />
          );
        case 12:
          if (hasTotalIncome && hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep08 />
            ) : (
              <Icons.SPStep08Checked sx={{ color }} />
            );
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep09 />
            ) : (
              <Icons.SPStep09Checked sx={{ color }} />
            );
          return null;
        case 13:
          if (hasTotalIncome && hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep09 />
            ) : (
              <Icons.SPStep09Checked sx={{ color }} />
            );
          if (hasTotalIncome)
            return currentStep < index ? (
              <Icons.SPStep10 />
            ) : (
              <Icons.SPStep10Checked sx={{ color }} />
            );
          return null;
        case 14:
          if (hasTotalIncome && hasJoinGuarantor)
            return currentStep < index ? (
              <Icons.SPStep10 />
            ) : (
              <Icons.SPStep10Checked sx={{ color }} />
            );
          return null;
        default:
          return null;
      }
    },
    [checkColorIcon, currentStep, hasJoinGuarantor, hasTotalIncome]
  );

  const checkClipPath = useCallback(
    (index: number) => {
      if (!index) return 'polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%)';
      if (index === steps.length - 1)
        return 'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 9% 50%, 0% 0%)';
      return 'polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 9% 50%, 0% 0%)';
    },
    [steps.length]
  );

  return (
    <Stack
      id="sp-header-steps"
      direction="row"
      spacing="-12px"
      sx={{
        '@media (orientation: landscape)': {
          top: isMobile ? 46 : 42,
          width: isMobile ? 'auto' : 480,
        },
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill,minmax(25%,1fr))',
        gridAutoFlow: 'column',
        gridAutoColumns: 'minmax(25%,1fr)',
        position: 'fixed',
        top: 42,
        left: 0,
        right: 0,
        mx: 'auto',
        width: { mobile: '100%', tablet: 480 },
        zIndex: 9999,
        flex: 1,
        overflowX: { mobile: 'scroll', desktop: 'hidden' },
        overflowY: 'hidden',
        overscrollBehavior: 'none',
        ...hiddenScrollBar,
      }}
    >
      {steps.map((step, index) => (
        <Element key={step.label + index} name={`step-${index + 1}`}>
          <Stack
            sx={{
              '@media (orientation: landscape)': {
                width: isMobile ? 'calc(100% + 22px)' : 'auto',
              },
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              clipPath: checkClipPath(index),
              height: 64,
              bgcolor: currentStep > index ? 'main_white' : 'sp_primary_40',
            }}
          >
            <Stack
              sx={{
                position: 'absolute',
                top: 1,
                left: 1,
                width:
                  index === steps.length - 1
                    ? 'calc(100% - 2px)'
                    : 'calc(100% + 2px)',
                height: 62,
                justifyContent: 'space-between',
                alignItems: 'center',
                clipPath: checkClipPath(index),
                bgcolor: checkBgColor(index + 1),
              }}
              spacing={1}
            >
              <Stack
                direction="row"
                alignItems="flex-end"
                spacing={1}
                sx={{ pt: '2px' }}
              >
                {currentStep > index + 1 && (
                  <Icons.SPStepChecked sx={{ width: 15, height: 15 }} />
                )}
                <Typography variant="sp_step" color={checkColor(index + 1)}>
                  STEP{' '}
                  <Typography
                    variant="sp_step"
                    color={checkColor(index + 1)}
                    fontSize={18}
                  >
                    {zeroPad(index + 1)}
                  </Typography>
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ p: '0 5px', pb: 2 }}
                spacing={1}
              >
                {renderIcon(index + 1)}
                <Typography
                  variant="text_step"
                  sx={{
                    color: checkColor(index + 1),
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'left',
                    whiteSpace: 'break-spaces',
                    lineHeight: '120%',
                  }}
                >
                  {step.label}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Element>
      ))}
    </Stack>
  );
};
