import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Close: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L20.5 20.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 1L0.999999 20.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
