import { FC, useCallback } from 'react';
import { Divider, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { MessageItemAdmin } from 'services';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import {
  getMessageDetailManager,
  getMessageDetailSalePerson,
} from 'pages/ScreenMessages/thunk';
import { setConversationDetail } from 'pages/ScreenMessages/slice';

export type MessageAdminItemsProps = {
  data: MessageItemAdmin[];
};

export const MessagesItems: FC<MessageAdminItemsProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const convertContent = (content: string) => {
    if (content === '<span></span>') return '＜ファイルが添付されました＞';
    return content.replaceAll('</br>', '&nbsp');
  };

  const getDetailMessage = useCallback(
    async (
      conversationId: string,
      conversationName: string,
      applicationHeader: MessageItemAdmin['p_application_header']
    ) => {
      if (location.pathname.startsWith('/manager/', 0)) {
        const result = await dispatch(getMessageDetailManager(conversationId));
        if (getMessageDetailManager.fulfilled.match(result)) {
          dispatch(
            setConversationDetail({
              conversationId,
              conversationName,
              applicationHeader: {
                preliminaryId: applicationHeader.preliminary_id,
                applicantName: applicationHeader.applicant_name,
                editable: applicationHeader.editable,
              },
            })
          );
          navigate({
            pathname: routeNames.ManagerMessageDetail.path,
            search: applicationHeader.preliminary_id
              ? `?preliminary_id=${applicationHeader.preliminary_id}`
              : '',
          });
        }
      } else {
        const result = await dispatch(
          getMessageDetailSalePerson(conversationId)
        );
        if (getMessageDetailSalePerson.fulfilled.match(result)) {
          dispatch(
            setConversationDetail({
              conversationId,
              conversationName,
              applicationHeader: {
                preliminaryId: applicationHeader.preliminary_id,
                applicantName: applicationHeader.applicant_name,
                editable: applicationHeader.editable,
              },
            })
          );
          navigate({
            pathname: routeNames.SalePersonMessageDetail.path,
            search: applicationHeader.preliminary_id
              ? `?preliminary_id=${applicationHeader.preliminary_id}`
              : '',
          });
        }
      }
    },
    [dispatch, location.pathname, navigate]
  );

  return (
    <>
      <Stack sx={{ mb: '13px' }}>
        {data
          .filter((item) => Boolean(item.time_send_message))
          .map((item) => (
            <Stack
              key={item.id}
              sx={{
                minWidth: 1100,
              }}
              onClick={async () => {
                await getDetailMessage(
                  item.id,
                  item.conversation_name,
                  item.p_application_header
                );
              }}
            >
              <TableRow
                sx={{
                  bgcolor: 'main_white',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #CCCCCC',
                  alignItems: 'center',
                  display: 'flex',
                  height: 60,
                  cursor: 'pointer',
                }}
              >
                <TableCell
                  sx={{
                    textAlign: 'center',
                    width: 90,
                    padding: '10px 0',
                    borderBottom: 'none',
                    position: 'relative',
                    flexDirection: 'row',
                  }}
                >
                  {!item.viewed && (
                    <Stack
                      sx={{
                        display: 'inline-block',
                        textAlign: 'center',
                        bgcolor: 'sp_secondary_01',
                        width: '31px',
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        variant="title_application_item"
                        color="main_white"
                        sx={{
                          fontSize: 12,
                        }}
                      >
                        新着
                      </Typography>
                    </Stack>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    width: 200,
                    paddingRight: 3,
                    textAlign: 'left',
                    borderBottom: 'none',
                    position: 'relative',
                    flexDirection: 'row',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography
                    variant="title_application_item"
                    color="sp_primary_100_main"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    {item.conversation_name}
                  </Typography>
                  <Divider
                    sx={{
                      color: 'gray',
                      right: 0,
                      top: '25%',
                      position: 'absolute',
                      height: '50%',
                    }}
                    orientation="vertical"
                  />
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 170,
                    flex: 1,
                    pl: '10px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    padding: '10px',
                    textAlign: 'center',
                    borderBottom: 'none',
                    position: 'relative',
                    flexDirection: 'row',
                  }}
                >
                  <Typography variant="title_application_item" color="b_333">
                    {item.time_send_message}
                  </Typography>
                  <Divider
                    sx={{
                      color: 'gray',
                      right: 0,
                      top: '25%',
                      position: 'absolute',
                      height: '50%',
                    }}
                    orientation="vertical"
                  />
                </TableCell>
                <TableCell
                  sx={{
                    width: 201,
                    maxHeight: 40,
                    flex: 1,
                    pl: '10px',
                    padding: '10px',
                    textAlign: 'left',
                    borderBottom: 'none',
                    position: 'relative',
                    flexDirection: 'row',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography
                    variant="title_application_item"
                    color="b_333"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontStyle={
                      item.content === '<span></span>' ? 'italic' : 'normal'
                    }
                    dangerouslySetInnerHTML={{
                      __html: `${convertContent(item.content)}`,
                    }}
                  />
                </TableCell>
              </TableRow>
            </Stack>
          ))}
      </Stack>
    </>
  );
};
