import { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { keys } from 'lodash';
import { kingNames } from 'constant';
import { zeroPad } from 'utils';
import kanjidate from 'kanjidate';
import { InputSelect, InputSelectProps } from '../InputSelect';
import { validationSchema } from './validationSchema';

export type InputKanjiDateProps = Pick<
  InputSelectProps,
  'disabled' | 'readOnly'
> & {
  name: string;
  readOnly?: boolean;
};

type FormikValues = {
  kingName: string;
  yearReign: string;
  month: string;
  day: string;
};

export const InputKanjiDate: FC<InputKanjiDateProps> = ({
  disabled,
  readOnly,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const initialValues = useMemo<FormikValues>(() => {
    if (field.value.length === 0) {
      return {
        kingName: '',
        yearReign: '',
        month: '',
        day: '',
      };
    }

    const [year, month, day] = field.value.split('/');
    const { gengou, nen } = kanjidate.toGengou(year, month, day);

    return { kingName: gengou, yearReign: zeroPad(nen), month, day };
  }, [field.value]);

  const formik = useFormik<FormikValues>({
    initialValues,
    validationSchema,
    onSubmit(values, actions) {
      const year = kanjidate.fromGengou(values.kingName, +values.yearReign);
      setFieldValue(field.name, `${year}/${values.month}/${values.day}`);

      actions.setSubmitting(false);
    },
  });

  const yearOptions: InputSelectProps['options'] = useMemo(() => {
    let years = 0;
    let startNumber = 1;
    switch (formik.values.kingName) {
      case '昭和':
        years = 47;
        startNumber = 17;
        break;
      case '平成':
        years = 16;
        break;
      default:
        break;
      //TODO: check years and startNumber for other gengous
    }
    return Array.from(Array(years), (_, index) => {
      return {
        value: zeroPad(index + startNumber),
        label: zeroPad(index + startNumber),
      };
    });
  }, [formik.values.kingName]);

  return (
    <FormikProvider value={formik}>
      <Stack
        direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        spacing={3}
      >
        <Stack flex={1} direction="row" alignItems="center" spacing={3}>
          <InputSelect
            sx={{
              width: 157,
              height: 50,
            }}
            name="kingName"
            options={options}
            disabled={disabled}
            readOnly={readOnly}
            error={!formik.values.kingName && !!meta.error}
            errorMessage={meta.error}
            onChange={() => formik.handleSubmit()}
            placeholder="ー"
          />
        </Stack>
        <Stack flex={1} direction="row" alignItems="center" spacing={3}>
          <InputSelect
            sx={{
              width: 124,
              height: 50,
            }}
            name="yearReign"
            placeholder="ー"
            disabled={disabled}
            readOnly={readOnly}
            options={yearOptions}
            error={!formik.values.yearReign && !!meta.error}
            errorMessage={meta.error}
            onChange={() => formik.handleSubmit()}
            unit="年"
          />
        </Stack>
        <Stack flex={1} direction="row" alignItems="center" spacing={3}>
          <InputSelect
            sx={{
              width: 124,
              height: 50,
            }}
            name="month"
            placeholder="ー"
            disabled={disabled}
            readOnly={readOnly}
            options={monthOptions}
            error={!formik.values.month && !!meta.error}
            errorMessage={meta.error}
            onChange={() => formik.handleSubmit()}
            unit="月"
          />
        </Stack>
        <Stack flex={1} direction="row" alignItems="center" spacing={3}>
          <InputSelect
            sx={{
              width: 124,
              height: 50,
            }}
            name="day"
            placeholder="ー"
            disabled={disabled}
            readOnly={readOnly}
            options={dayOptions}
            error={!!meta.error}
            errorMessage={meta.error}
            onChange={() => formik.handleSubmit()}
            unit="日"
          />
        </Stack>
      </Stack>
    </FormikProvider>
  );
};

const options: InputSelectProps['options'] = keys(kingNames).map(
  (kingName) => ({
    value: kingName,
    label: kingName,
  })
);

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);

const dayOptions: InputSelectProps['options'] = [
  { value: '', label: '日' },
].concat(
  Array.from(Array(31), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);
