import { SPStepLayout } from 'containers';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  checkingExistFile,
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  convertManToYen,
  convertToFullWidth,
  convertToHalfWidth,
  flattenToLodashFormat,
  kanaMap1,
  supportDvh,
  zeroPad,
} from 'utils';
import { useSelector } from 'react-redux';
import { FormikProvider, getIn, useFormik } from 'formik';
import { Stack, Typography } from '@mui/material';
import {
  SPCheckboxMultipleButton,
  SPFormItem,
  SPImage,
  SPInputDate,
  SPInputField,
  SPInputPhoneNumber,
  SPInputSelect,
  SPInputSelectProps,
  SPInputZipCode,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPSaveImageModal,
} from 'components';
import {
  BorrowerIncomeType,
  DispatchType,
  FileATaxReturnType,
  GeneralIncomeConfirmation,
  LeaveType,
  NursingCareType,
  OccupationDetailType,
  OccupationType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PREFECTURES,
  SPTaxReturnType,
  WorkIndustryType,
  spFinalTaxReturn,
} from 'constant';
import {
  isJoinGuarantorInitSelector,
  spCurrentStepSelector,
} from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import kanjidate from 'kanjidate';
import { dayjs } from 'libs';
import { get, isEmpty, update } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostalCode } from 'services';
import { spStepEightSelector } from '../SPStepEight/selectors';
import { spStepFiveSelector } from '../SPStepFive/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  checkMCJ,
  spStepOneSelector,
} from '../SPStepOne/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { saveSecondWithholdingSlipFile2 } from '../SPStepSevenIncomeTotalizer/slice';
import { spStepSixSelector } from '../SPStepSix/selectors';
import { spStepTenSelector } from '../SPStepTen/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import { spStepTwoSelector } from '../SPStepTwo/selectors';
import { dynamicOptionsSelectors } from '../StepRequiredInformation/selectors';
import { getZipCloudAddress } from '../StepRequiredInformation/thunk';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import {
  convertSPStepThreeIncomeTotalSelector,
  spStepThreeIncomeTotalSelector,
} from './selectors';

import { saveForm } from './slice';
import { validationSchema } from './validationSchema';

const SPStepFiveIncomeTotalizerPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const isJoinGuarantorInit = useSelector(isJoinGuarantorInitSelector);
  const initialValues = useSelector(spStepThreeIncomeTotalSelector);
  const currentStep = useSelector(spCurrentStepSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepFourValues = useSelector(spStepFourSelector);
  const { application_number } = useSelector(spStepTenSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const convertInitialValues = useSelector(
    convertSPStepThreeIncomeTotalSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const {
    residenceFile,
    repaymentScheduleImage,
    businessCard,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    residenceIncomeFile,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
    setSecondWithholdingSlipFile2,
    residenceFileBackImage,
    residenceIncomeFileBackImage,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const isMCJ = useSelector(checkMCJ);
  const [open, setOpen] = useState(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [officePostalCodeError, setOfficePostalCodeError] =
    useState<string>('');
  const [transferOfficePostalCodeError, setTransferOfficePostalCodeError] =
    useState<string>('');

  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);

  const { updateFormOverride } = useUpdateForm();
  const location = useLocation();

  // @ts-ignore
  const previousPathName = !!location.state ? location.state.pathName : '';

  const applicantPeople = {
    ...initialValues.p_applicant_people,
    ...convertInitialValues,
  };

  const formik = useFormik({
    initialValues: {
      p_applicant_people: applicantPeople,
    },
    validationSchema,
    async onSubmit(values, { setSubmitting }) {
      if (
        initialValues.p_applicant_people.office_postal_code &&
        values.p_applicant_people.office_postal_code !==
          applicantPeople.office_postal_code
      ) {
        values.p_applicant_people.other_office_address_kana = '';
      }
      if (
        initialValues.p_applicant_people.transfer_office_postal_code &&
        values.p_applicant_people.transfer_office_postal_code !==
          applicantPeople.transfer_office_postal_code
      ) {
        values.p_applicant_people.transfer_office_other_address_kana = '';
      }
      const { type_tax_return, ...p_applicant_people } =
        values.p_applicant_people;
      const [last_year_income, bonus_income, two_years_ago_income] =
        convertManToYen([
          p_applicant_people?.last_year_income,
          p_applicant_people?.bonus_income,
          p_applicant_people?.two_years_ago_income,
        ]);
      const submitForm = {
        p_application_header: {
          p_applicant_people_attributes: [
            {
              type_tax_return: type_tax_return,
              ...p_applicant_people,
              last_year_income,
              bonus_income,
              two_years_ago_income,
            },
          ],
        },
      };

      if (isSubmit) {
        setIsDisabledButton(true);
        updateFormOverride(
          {
            stepThreeIncomeData: submitForm,
            imageContext: imageContext,
          },
          setIsDisabledButton,
          setOpenImageModal
        );
        return;
      } else {
        await dispatch(saveForm(submitForm));
        dispatch(setCurrentStep('6'));
        setSubmitting(false);
      }

      if (
        hasJoinGuarantor &&
        incomeTotalizer &&
        isJoinGuarantorInit !== hasJoinGuarantor
      ) {
        navigate(routeNames.SPStepFourCollateralProvider.path);
      } else if (incomeTotalizer && !!application_number) {
        navigate(routeNames.SPStepSevenIncomeTotalizer.path);
      } else {
        if (!!application_number) {
          updateFormOverride(
            {
              stepThreeIncomeData: submitForm,
              imageContext: imageContext,
            },
            setIsDisabledButton,
            setOpenImageModal
          );
        } else navigate(routeNames.SPStepFour.path);
      }
    },
  });

  const isSubmit = useMemo(() => {
    return (
      !!application_number &&
      (previousPathName === routeNames.Top.path ||
        previousPathName === routeNames.SPStepNine.path)
    );
  }, [application_number, previousPathName]);

  useEffect(() => {
    if (!!application_number && !incomeTotalizer) {
      navigate(routeNames.SPStepOne.path);
    }
  }, [application_number, incomeTotalizer, navigate]);

  useEffect(() => {
    if (!isMCJ) {
      formik.setFieldValue('p_applicant_people.bonus_income', '');
      formik.setFieldValue('p_applicant_people.two_years_ago_income', '');
      formik.setFieldValue('nursing_leave_status', NursingCareType.DEFAULT);
    }
  }, [isMCJ, formik.setFieldValue]);

  useEffect(() => {
    if (
      !formik.values.p_applicant_people.type_tax_return.find(
        (e) => e === FileATaxReturnType.OTHERS
      )
    ) {
      formik.setFieldValue('p_applicant_people.other_type_tax_return', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_applicant_people.type_tax_return]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  const setHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      office_postal_code:
        postal.zipcode.slice(0, 3) + '-' + postal.zipcode.slice(3),
      office_prefecture_kanji: postal.address1,
      office_district_kanji: postal.address3,
      office_city_kanji: postal.address2,
      office_prefecture_kana: convertToFullWidth(postal.kana1),
      office_district_kana: convertToFullWidth(postal.kana3),
      office_city_kana: convertToFullWidth(postal.kana2),
      other_office_address_kanji: '',
    });
    await formik.setFieldTouched(
      `${name}.other_office_address_kanji`,
      false,
      false
    );
  };
  const setTransferHomeAddress = async (postal: PostalCode, name: string) => {
    await formik.setFieldValue(name, {
      ...get(formik.values, name),
      transfer_office_postal_code:
        postal.zipcode.slice(0, 3) + '-' + postal.zipcode.slice(3),
      transfer_office_prefecture_kanji: postal.address1,
      transfer_office_district_kanji: postal.address3,
      transfer_office_city_kanji: postal.address2,
      transfer_office_prefecture_kana: convertToFullWidth(postal.kana1),
      transfer_office_district_kana: convertToFullWidth(postal.kana3),
      transfer_office_city_kana: convertToFullWidth(postal.kana2),
      transfer_office_other_address_kanji: '',
    });
    await formik.setFieldTouched(
      `${name}.transfer_office_other_address_kanji`,
      false,
      false
    );
  };

  const handleZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        setOfficePostalCodeError(
          '住所が取得できませんでした。再度入力してください。'
        );
        await formik.setFieldValue(
          'p_applicant_people.office_prefecture_kanji',
          ''
        );
        await formik.setFieldValue('p_applicant_people.office_city_kanji', '');
        await formik.setFieldValue(
          'p_applicant_people.office_district_kanji',
          ''
        );
        await formik.setFieldValue(
          'p_applicant_people.office_prefecture_kana',
          ''
        );
        await formik.setFieldValue('p_applicant_people.office_city_kana', '');
        await formik.setFieldValue(
          'p_applicant_people.office_district_kana',
          ''
        );
        return;
      }
      await setHomeAddress(result.payload.results[0], 'p_applicant_people');
      setOfficePostalCodeError('');
    }
  };

  const handleTransferZipCodeChange = async (value: string) => {
    const result = await dispatch(
      getZipCloudAddress(value.split('-').join(''))
    );
    if (getZipCloudAddress.fulfilled.match(result)) {
      if (!result.payload.results) {
        setTransferOfficePostalCodeError(
          '住所が取得できませんでした。再度入力してください。'
        );
        await formik.setFieldValue(
          'p_applicant_people.transfer_office_prefecture_kanji',
          ''
        );
        await formik.setFieldValue(
          'p_applicant_people.transfer_office_city_kanji',
          ''
        );
        await formik.setFieldValue(
          'p_applicant_people.transfer_office_district_kanji',
          ''
        );
        await formik.setFieldValue(
          'p_applicant_people.transfer_office_prefecture_kana',
          ''
        );
        await formik.setFieldValue(
          'p_applicant_people.transfer_office_city_kana',
          ''
        );
        await formik.setFieldValue(
          'p_applicant_people.transfer_office_district_kana',
          ''
        );
        return;
      }
      await setTransferHomeAddress(
        result.payload.results[0],
        'p_applicant_people'
      );
      setTransferOfficePostalCodeError('');
    }
  };

  const occupationOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.occupation),
    [dynamicOptions.occupation]
  );

  const industryOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.industry),
    [dynamicOptions.industry]
  );

  const occupationDetailOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.occupation_detail),
    [dynamicOptions.occupation_detail]
  );

  const incomeOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.income_source),
    [dynamicOptions.income_source]
  );

  const typeTaxReturnOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.type_tax_return),
    [dynamicOptions.type_tax_return]
  );

  const maternityStatusOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.maternity_paternity_leave_status),
    [dynamicOptions.maternity_paternity_leave_status]
  );

  const nursingCareOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.nursing_leave_status),
    [dynamicOptions.nursing_leave_status]
  );

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);

    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });
    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const { residence_file, ...spStepTwoIncomeValue } =
        spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        formik.values.p_applicant_people;

      const [last_year_income, bonus_income, two_years_ago_income] =
        convertManToYen([
          spStepThreeIncomeTotal?.last_year_income,
          spStepThreeIncomeTotal?.bonus_income,
          spStepThreeIncomeTotal?.two_years_ago_income,
        ]);

      const spNewStepThreeIncomeTotal = {
        ...spStepThreeValues,
        last_year_income,
        bonus_income,
        two_years_ago_income,
      };

      Object.keys(spNewStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spNewStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/
    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append('draft[p_referral_agency][business_card][]', file);
      data.append(
        `draft[p_referral_agency][avatar_company][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    return;
  };

  const setFieldTransferOffice = (values: string) => {
    if (values === DispatchType.NOTHING) {
      formik.setFieldValue('p_applicant_people.transfer_office_name_kanji', '');
      formik.setFieldValue('p_applicant_people.transfer_office_name_kana', '');
      formik.setFieldValue(
        'p_applicant_people.transfer_office_phone_number',
        ''
      );
      formik.setFieldValue(
        'p_applicant_people.transfer_office_postal_code',
        ''
      );
      formik.setFieldValue(
        'p_applicant_people.transfer_office_prefecture_kanji',
        ''
      );
      formik.setFieldValue('p_applicant_people.transfer_office_city_kanji', '');
      formik.setFieldValue(
        'p_applicant_people.transfer_office_district_kanji',
        ''
      );
      formik.setFieldValue(
        'p_applicant_people.transfer_office_prefecture_kana',
        ''
      );
      formik.setFieldValue('p_applicant_people.transfer_office_city_kana', '');
      formik.setFieldValue(
        'p_applicant_people.transfer_office_district_kana',
        ''
      );
      formik.setFieldValue(
        'p_applicant_people.transfer_office_other_address_kanji',
        ''
      );
      formik.setFieldValue(
        'p_applicant_people.transfer_office_other_address_kana',
        ''
      );
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement;
    let transformedValue = value;
    Object.entries(kanaMap1).forEach(([key, replacement]) => {
      transformedValue = transformedValue.replaceAll(key, replacement);
    });
    formik.setFieldValue(name, transformedValue);
  };

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={() => {
          const { type_tax_return, ...p_applicant_people } =
            formik.values.p_applicant_people;
          const [last_year_income, bonus_income, two_years_ago_income] =
            convertManToYen([
              p_applicant_people?.last_year_income,
              p_applicant_people?.bonus_income,
              p_applicant_people?.two_years_ago_income,
            ]);
          const submitForm = {
            p_application_header: {
              p_applicant_people_attributes: [
                {
                  type_tax_return: type_tax_return,
                  ...p_applicant_people,
                  last_year_income,
                  bonus_income,
                  two_years_ago_income,
                },
              ],
            },
          };
          dispatch(saveForm(submitForm));
        }}
        footer={{
          left: {
            onClick: async () => {
              const { type_tax_return, ...p_applicant_people } =
                formik.values.p_applicant_people;
              const [last_year_income, bonus_income, two_years_ago_income] =
                convertManToYen([
                  p_applicant_people?.last_year_income,
                  p_applicant_people?.bonus_income,
                  p_applicant_people?.two_years_ago_income,
                ]);
              const submitForm = {
                p_application_header: {
                  p_applicant_people_attributes: [
                    {
                      type_tax_return: type_tax_return,
                      ...p_applicant_people,
                      last_year_income,
                      bonus_income,
                      two_years_ago_income,
                    },
                  ],
                },
              };
              await dispatch(saveForm(submitForm));
              if (!!application_number) {
                navigate(-1);
              } else {
                navigate(routeNames.SPStepFourIncomeTotalizer.path);
              }
            },
          },
          right: {
            children: isSubmit ? '保存' : '次へ',
            onClick: () => {
              formik.handleSubmit();
            },
            disabled: isDisabledButton,
          },
        }}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        onSaveDraft={onSaveDraft}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack sx={{ py: 9 }} justifyContent="center" alignItems="center">
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >{`収入合算者のご職業について\n教えてください。`}</Typography>
          </Stack>
        </Stack>
        <SPFormItem label="ご職業" required>
          <Stack spacing={3}>
            <SPInputSelect
              name="p_applicant_people.occupation"
              placeholder="選択してください"
              options={occupationOptions}
              sx={{
                '@media (orientation: landscape)': {
                  textAlign: 'left',
                  width: '100%',
                },
              }}
              onChange={(e) => {
                if (e.target.value === OccupationType.TWELVE) {
                  formik.setFieldValue(
                    'p_applicant_people.office_phone_number',
                    ''
                  );
                }
                if (e.target.value !== OccupationType.ONE) {
                  setSecondWithholdingSlipFile2([]);
                  dispatch(saveSecondWithholdingSlipFile2({}));
                }
                if (e.target.value !== OccupationType.OTHERS) {
                  formik.setFieldValue(
                    'p_applicant_people.other_occupation',
                    ''
                  );
                }
              }}
            />
            {formik.values.p_applicant_people.occupation ===
              OccupationType.OTHERS && (
              <Stack spacing={`6px`}>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※その他の方は詳細を入力ください。
                </Typography>
                <SPInputField
                  name="p_applicant_people.other_occupation"
                  placeholder="入力してください"
                  convertFullWidth
                />
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem label="業種" required>
          <Stack spacing={3}>
            <SPInputSelect
              name="p_applicant_people.industry"
              placeholder="選択してください"
              options={industryOptions}
              sx={{
                '@media (orientation: landscape)': {
                  textAlign: 'left',
                  width: '100%',
                },
              }}
              onChange={(e) => {
                if (e.target.value !== WorkIndustryType.OTHERS) {
                  formik.setFieldValue('p_applicant_people.other_industry', '');
                }
              }}
            />
            {formik.values.p_applicant_people.industry ===
              WorkIndustryType.OTHERS && (
              <Stack spacing={`6px`}>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※その他の方は詳細を入力ください。
                </Typography>
                <SPInputField
                  name="p_applicant_people.other_industry"
                  placeholder="入力してください"
                  convertFullWidth
                />
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem label="職種" required>
          <Stack spacing={3}>
            <SPInputSelect
              name="p_applicant_people.occupation_detail"
              placeholder="選択してください"
              options={occupationDetailOptions}
              sx={{
                '@media (orientation: landscape)': {
                  textAlign: 'left',
                  width: '100%',
                },
              }}
              onChange={(e) => {
                if (e.target.value !== OccupationDetailType.OTHERS) {
                  formik.setFieldValue(
                    'p_applicant_people.other_occupation_detail',
                    ''
                  );
                }
              }}
            />
            {formik.values.p_applicant_people.occupation_detail ===
              OccupationDetailType.OTHERS && (
              <Stack spacing={`6px`}>
                <Typography variant="SP_form_item_note" color="b_333">
                  ※その他の方は詳細を入力ください。
                </Typography>
                <SPInputField
                  name="p_applicant_people.other_occupation_detail"
                  placeholder="入力してください"
                  convertFullWidth
                />
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem label="勤務先名" required>
          <SPInputField
            name="p_applicant_people.office_name_kanji"
            placeholder="例：株式会社○○○○○"
            convertFullWidth
          />
        </SPFormItem>
        <SPFormItem label="所属部課" required>
          <Stack spacing={`6px`}>
            <SPInputField
              name="p_applicant_people.department"
              placeholder="例：○○部"
              convertFullWidth
            />
            <Typography variant="SP_form_item_note" color="b_333">
              ※所属部課が無い方は「なし」とご入力ください。
            </Typography>
          </Stack>
        </SPFormItem>
        <SPFormItem label="勤務先の電話番号" required>
          <Stack spacing={`6px`}>
            <SPInputPhoneNumber
              name="p_applicant_people.office_phone_number"
              type="homePhone"
            />
            <Typography variant="SP_form_item_note" color="b_333">
              ※半角数字でご入力ください。
            </Typography>
            <Typography variant="SP_form_item_note" color="b_333">
              ※現在所属する部署の電話番号をご入力ください。
            </Typography>
          </Stack>
        </SPFormItem>
        <SPFormItem
          label="勤務先の住所"
          note="※実際に勤務している事業所の住所をご入力ください"
          required
        >
          <Stack spacing={6} px={2}>
            <Stack spacing={`6px`}>
              <SPInputZipCode
                name="p_applicant_people.office_postal_code"
                label="郵便番号"
                handleFocusZipCode={() => setOfficePostalCodeError('')}
                handleInputZipcode={(values) => {
                  if (
                    values?.length === 8 &&
                    values === convertToHalfWidth(values)
                  ) {
                    return handleZipCodeChange(values);
                  }
                }}
              />
              {officePostalCodeError && (
                <Typography variant="text_error" color="sp_secondary_01">
                  ※{officePostalCodeError}
                </Typography>
              )}
              <Typography variant="SP_form_item_note" color="b_333">
                ※入力すると自動的に住所が表示されます。
              </Typography>
            </Stack>
            <SPInputSelect
              name="p_applicant_people.office_prefecture_kanji"
              placeholder="----"
              label="都道府県"
              options={PREFECTURES}
              sx={{
                '@media (orientation: landscape)': {
                  textAlign: 'left',
                  maxWidth: '110px',
                  width: '110px',
                },
              }}
              onChange={() => {
                if (formik.values.p_applicant_people.office_prefecture_kana) {
                  formik.setFieldValue(
                    'p_applicant_people.office_prefecture_kana',
                    ''
                  );
                }
              }}
            />
            <SPInputField
              name="p_applicant_people.office_city_kanji"
              placeholder="例：港区"
              label="市区郡　（例：港区）"
              convertFullWidth
              onChange={() => {
                if (formik.values.p_applicant_people.office_city_kana) {
                  formik.setFieldValue(
                    'p_applicant_people.office_city_kana',
                    ''
                  );
                }
              }}
            />
            <SPInputField
              name="p_applicant_people.office_district_kanji"
              placeholder="例：芝浦４丁目"
              label="町村丁目（例：芝浦４丁目）"
              convertFullWidth
              onChange={() => {
                if (formik.values.p_applicant_people.office_district_kana) {
                  formik.setFieldValue(
                    'p_applicant_people.office_district_kana',
                    ''
                  );
                }
              }}
            />
            <SPInputField
              name="p_applicant_people.other_office_address_kanji"
              placeholder="例：12-38　キャナルゲート芝浦605号室"
              label="丁目以下・建物名・部屋番号（例：12-38　キャナルゲート芝浦605号室）"
              convertFullWidth
              labelStyle={{ lineHeight: '20px' }}
              onChange={() => {
                if (
                  formik.values.p_applicant_people.other_office_address_kana
                ) {
                  formik.setFieldValue(
                    'p_applicant_people.other_office_address_kana',
                    ''
                  );
                }
              }}
            />
          </Stack>
        </SPFormItem>
        <SPFormItem label="従業員数" required>
          <Stack>
            <SPInputField
              sx={{ width: '156px' }}
              name="p_applicant_people.number_of_employee"
              unit="名"
              placeholder="0"
              align="right"
              numeric
              useNumberFormat
              toFixedNumber={false}
              maxLength={10}
            />
          </Stack>
        </SPFormItem>
        <SPFormItem label="入社年月" required>
          <Stack spacing={2}>
            <SPInputDate
              name="p_applicant_people.employment_started_date"
              selectWidth="163px"
            />
          </Stack>
        </SPFormItem>
        <SPFormItem label="ご年収" required>
          <Stack
            sx={{
              borderRadius: 2,
              border: (theme) =>
                `1px solid ${theme?.palette?.sp_primary_100_main}`,
            }}
          >
            <SPFormItem
              label="前年度年収"
              required
              labelSx={{ borderRadius: '8px 8px 0 0' }}
              childrenSx={{ pb: 3 }}
            >
              <Stack pb={isMCJ ? 3 : 0}>
                <SPInputField
                  sx={{ width: '156px' }}
                  name="p_applicant_people.last_year_income"
                  unit="万円"
                  placeholder="0"
                  align="right"
                  numeric
                  convertHalfWidth
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              </Stack>
              {isMCJ && (
                <Stack direction="row" spacing={1} pb={`6px`}>
                  <Typography variant="sp_unit_select_text" color="b_333">
                    うち、ボーナス
                  </Typography>
                  <Typography
                    variant="sp_unit_select_text"
                    color="b_333"
                    sx={{ fontSize: 12 }}
                  >
                    （MCJ固有項目）
                  </Typography>
                </Stack>
              )}
              {isMCJ && (
                <SPInputField
                  sx={{ width: '156px' }}
                  name="p_applicant_people.bonus_income"
                  unit="万円"
                  placeholder="0"
                  align="right"
                  numeric
                  convertHalfWidth
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              )}
            </SPFormItem>

            {isMCJ && (
              <SPFormItem
                label={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="sp_unit_select_text" color="b_333">
                      前々年度年収
                    </Typography>
                    <Typography
                      variant="sp_unit_select_text"
                      color="b_333"
                      sx={{ fontSize: 12 }}
                    >
                      （MCJ固有項目）
                    </Typography>
                  </Stack>
                }
                required
                childrenSx={{ pb: 3 }}
              >
                <SPInputField
                  sx={{ width: '156px' }}
                  name="p_applicant_people.two_years_ago_income"
                  unit="万円"
                  placeholder="0"
                  align="right"
                  numeric
                  convertHalfWidth
                  useNumberFormat
                  toFixedNumber={false}
                  maxLength={6}
                />
              </SPFormItem>
            )}

            <SPFormItem
              label="収入源"
              note=" ※複数選択可"
              noteType="short"
              required
              childrenSx={{ pb: 3 }}
            >
              <SPCheckboxMultipleButton
                name="p_applicant_people.income_source"
                options={incomeOptions}
              />
            </SPFormItem>
            <SPFormItem
              label="確定申告をしていますか？"
              required
              childrenSx={{ pb: 3, borderRadius: '0 0 8px 8px' }}
            >
              <SPRadioGroupButton
                name="p_applicant_people.tax_return"
                options={spFinalTaxReturnOptions}
                onChange={(checked, values) => {
                  if (values === SPTaxReturnType.NO) {
                    setSecondWithholdingSlipFile2([]);
                    dispatch(saveSecondWithholdingSlipFile2({}));
                    formik.setFieldValue(
                      'p_applicant_people.type_tax_return',
                      []
                    );
                    formik.setFieldValue(
                      'p_applicant_people.other_type_tax_return',
                      ''
                    );
                  }
                }}
                display="row"
                required
              />
            </SPFormItem>
            {formik.values.p_applicant_people.tax_return ===
              SPTaxReturnType.YES && (
              <SPFormItem
                label="確定申告の理由"
                note="※複数選択可"
                noteType="short"
                required
                childrenSx={{ pb: 3, borderRadius: '0 0 8px 8px' }}
              >
                <SPCheckboxMultipleButton
                  name="p_applicant_people.type_tax_return"
                  options={typeTaxReturnOptions}
                  additional={
                    formik.values.p_applicant_people.type_tax_return.find(
                      (e) => e === FileATaxReturnType.OTHERS
                    ) ? (
                      <Stack px={2} pb={3}>
                        <SPInputField
                          name="p_applicant_people.other_type_tax_return"
                          placeholder="入力してください"
                          label={
                            <Typography
                              variant="SP_form_item_note"
                              color="b_333"
                            >
                              ※その他の方は詳細を入力ください。
                            </Typography>
                          }
                          convertFullWidth
                        />
                      </Stack>
                    ) : undefined
                  }
                />
              </SPFormItem>
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem
          label="現在、出向（派遣）していますか？"
          required
          childrenSx={{ pb: 3 }}
        >
          <Stack spacing={3}>
            <SPRadioGroupButton
              name="p_applicant_people.transfer_office"
              options={spFinalTaxReturnOptions}
              onChange={(checked, values) => setFieldTransferOffice(values)}
              display="row"
              required
            />
            {formik.values.p_applicant_people.transfer_office ===
              DispatchType.YES && (
              <Stack
                sx={{
                  borderRadius: 2,
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
              >
                <Stack
                  sx={{
                    p: '8px 16px 8px 16px',
                    bgcolor: 'sp_primary_100_main',
                    borderRadius: '7px 7px 0 0',
                  }}
                >
                  <Typography variant="SP_form_item_label" color="main_white">
                    出向（派遣）勤務先の情報
                  </Typography>
                </Stack>
                <SPFormItem
                  label="出向（派遣）勤務先名"
                  required
                  childrenSx={{ pb: 3 }}
                >
                  <SPInputField
                    name="p_applicant_people.transfer_office_name_kanji"
                    placeholder="例：株式会社○○○○○"
                    convertFullWidth
                  />
                </SPFormItem>
                <SPFormItem
                  label="出向（派遣）勤務先名（フリガナ）"
                  required
                  childrenSx={{ pb: 3 }}
                >
                  <SPInputField
                    name="p_applicant_people.transfer_office_name_kana"
                    placeholder="例：カブシキガイシャ○○○○○"
                    onBlur={(event) => handleBlur(event)}
                    // convertHalfWidth
                  />
                </SPFormItem>
                <SPFormItem
                  label="出向（派遣）先　電話番号"
                  required
                  childrenSx={{ pb: 3 }}
                >
                  <Stack spacing={`6px`}>
                    <SPInputPhoneNumber
                      name="p_applicant_people.transfer_office_phone_number"
                      type="homePhone"
                    />
                    <Typography variant="SP_form_item_note" color="b_333">
                      ※半角数字でご入力ください。
                    </Typography>
                  </Stack>
                </SPFormItem>
                <SPFormItem
                  label="出向（派遣）先　住所"
                  required
                  childrenSx={{ pb: 3, borderRadius: '0 0 8px 8px' }}
                >
                  <Stack spacing={6}>
                    <Stack spacing={`6px`}>
                      <SPInputZipCode
                        name="p_applicant_people.transfer_office_postal_code"
                        label="郵便番号"
                        handleFocusZipCode={() =>
                          setTransferOfficePostalCodeError('')
                        }
                        handleInputZipcode={(values) => {
                          if (
                            values?.length === 8 &&
                            values === convertToHalfWidth(values)
                          ) {
                            return handleTransferZipCodeChange(values);
                          }
                        }}
                      />
                      {transferOfficePostalCodeError && (
                        <Typography
                          variant="text_error"
                          color="sp_secondary_01"
                        >
                          ※{transferOfficePostalCodeError}
                        </Typography>
                      )}
                      <Typography variant="SP_form_item_note" color="b_333">
                        ※入力すると自動的に住所が表示されます。
                      </Typography>
                    </Stack>
                    <SPInputSelect
                      name="p_applicant_people.transfer_office_prefecture_kanji"
                      placeholder="----"
                      label="都道府県"
                      options={PREFECTURES}
                      sx={{
                        '@media (orientation: landscape)': {
                          textAlign: 'left',
                          width: '110px',
                        },
                      }}
                    />
                    <SPInputField
                      name="p_applicant_people.transfer_office_city_kanji"
                      placeholder="例：港区"
                      label="市区郡　（例：港区）"
                      convertFullWidth
                    />
                    <SPInputField
                      name="p_applicant_people.transfer_office_district_kanji"
                      placeholder="例：芝浦４丁目"
                      label="町村丁目（例：芝浦４丁目）"
                      convertFullWidth
                    />
                    <SPInputField
                      name="p_applicant_people.transfer_office_other_address_kanji"
                      placeholder="例：12-38　キャナルゲート芝浦605号室"
                      label="丁目以下・建物名・部屋番号（例：12-38　キャナルゲート芝浦605号室）"
                      convertFullWidth
                      labelStyle={{ lineHeight: '20px' }}
                      // onBlur={(event) => handleBlur(event)}
                    />
                  </Stack>
                </SPFormItem>
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        <SPFormItem
          label="産休・育休の取得状況"
          note="※該当する方のみお答えください。"
        >
          <Stack spacing={3}>
            <SPRadioGroupButton
              name="p_applicant_people.maternity_paternity_leave_status"
              options={maternityStatusOptions}
              onChange={() => {
                formik.setFieldValue(
                  'p_applicant_people.maternity_paternity_leave_start_time',
                  ''
                );
                formik.setFieldValue(
                  'p_applicant_people.maternity_paternity_leave_end_time',
                  ''
                );
              }}
              handleReClickOption={() => {
                formik.setFieldValue(
                  'p_applicant_people.maternity_paternity_leave_start_time',
                  ''
                );
                formik.setFieldValue(
                  'p_applicant_people.maternity_paternity_leave_end_time',
                  ''
                );
              }}
            />
            {formik.values.p_applicant_people
              .maternity_paternity_leave_status !== LeaveType.DEFAULT && (
              <Stack
                sx={{
                  borderRadius: 2,
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
              >
                <SPFormItem
                  label="取得開始時期"
                  required
                  labelSx={{ borderRadius: '8px 8px 0 0' }}
                  childrenSx={{ pb: 3 }}
                >
                  <SPInputDate
                    name="p_applicant_people.maternity_paternity_leave_start_time"
                    selectWidth="153px"
                    unit="月から"
                    checkRequired={
                      !!formik.values.p_applicant_people
                        .maternity_paternity_leave_status
                    }
                    yearOptions={
                      formik.values.p_applicant_people
                        .maternity_paternity_leave_status ===
                      LeaveType.SCHEDULED_ACQUISITION
                        ? leaveStatusUpYearOptions
                        : leaveStatusDownYearOptions
                    }
                    whiteSpace="normal"
                  />
                </SPFormItem>
                <SPFormItem
                  label="取得終了時期"
                  required
                  childrenSx={{ pb: 3, borderRadius: '0 0 8px 8px' }}
                >
                  <Stack spacing={2}>
                    <SPInputDate
                      name="p_applicant_people.maternity_paternity_leave_end_time"
                      selectWidth="153px"
                      unit="月まで"
                      checkRequired={
                        !!formik.values.p_applicant_people
                          .maternity_paternity_leave_status
                      }
                      yearOptions={
                        formik.values.p_applicant_people
                          .maternity_paternity_leave_status ===
                          LeaveType.SCHEDULED_ACQUISITION ||
                        formik.values.p_applicant_people
                          .maternity_paternity_leave_status ===
                          LeaveType.ACQUIRING
                          ? leaveStatusUpYearOptions
                          : leaveStatusDownYearOptions
                      }
                      whiteSpace="normal"
                    />
                  </Stack>
                </SPFormItem>
              </Stack>
            )}
          </Stack>
        </SPFormItem>
        {isMCJ && (
          <SPFormItem
            label={
              <Stack direction="row" spacing={1}>
                <Typography variant="sp_unit_select_text" color="b_333">
                  介護休暇の取得状況
                </Typography>
                <Typography
                  variant="sp_unit_select_text"
                  color="b_333"
                  sx={{ fontSize: 12 }}
                >
                  （MCJ固有項目）
                </Typography>
              </Stack>
            }
            note="※該当する方のみお答えください。"
          >
            <SPRadioGroupButton
              name="p_applicant_people.nursing_leave_status"
              options={nursingCareOptions}
            />
          </SPFormItem>
        )}
        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />{' '}
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepFiveIncomeTotalizerPage;

type RadioItems = {
  value: string;
  label: string;
};

export const spFinalTaxReturnOptions: Array<RadioItems> = [
  {
    value: SPTaxReturnType.YES,
    label: spFinalTaxReturn[SPTaxReturnType.YES],
  },
  {
    value: SPTaxReturnType.NO,
    label: spFinalTaxReturn[SPTaxReturnType.NO],
  },
];

const leaveStatusDownYearOptions: SPInputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() - index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);

const leaveStatusUpYearOptions: SPInputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);
