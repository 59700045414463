import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const UnChecked: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      sx={{
        borderRadius: 30,
        boxShadow: 'rgb(0 0 0 / 20%) 2px 3px 4px',
      }}
      viewBox="0 0 22 22"
      {...props}
    >
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_733_473)">
          <circle cx="11" cy="11" r="11" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_733_473"
            x="0"
            y="0"
            width="26"
            height="26"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_733_473"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_733_473"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};
