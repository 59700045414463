import { FC } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { Icons } from 'components';
import { config } from 'configs';

const SPConsentPage: FC = () => {
  const urls = [config.dummyPdfUrl, config.termConditionUrl];

  return (
    <SPStepLayout
      hasMenu
      hasStepBar={false}
      hasModalSaveDraft={false}
      hasFooter={false}
      footerLogo
      sx={{ minHeight: 'calc(100vh - 162px)' }}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ bgcolor: 'bg_off' }}>
          <Stack textAlign="center" sx={{ mt: 7, mb: 6 }}>
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
            >
              同意書・確認書等
            </Typography>
          </Stack>
          {consents.map((consent, index) => (
            <Stack key={index}>
              <Stack sx={{ p: '5px 16px 6px 16px', bgcolor: 'sp_primary_40' }}>
                <Typography variant="sp_label_form_text" color="b_333">
                  {consent.label}
                </Typography>
              </Stack>
              <Stack alignItems="center" sx={{ p: '12px 97px 24px 97px' }}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  sx={{
                    bgcolor: 'sp_primary_100_main',
                    width: '180px',
                    minHeight: '40px',
                    boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                    borderRadius: '14px',
                    height: '40px',
                    border: (theme) =>
                      `1px solid ${theme?.palette?.sp_primary_100_main}`,
                  }}
                >
                  <Icons.SPPdfConsent />
                  <Link
                    href={urls[index]}
                    target="_blank"
                    component="a"
                    variant="sp_step_06_form_title"
                    color="main_white"
                    underline="none"
                  >
                    全文を見る
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </SPStepLayout>
  );
};

export default SPConsentPage;

const consents = [
  { label: '個人情報の取扱いに関する同意書 兼 表明および確約書' },
  { label: '銀行代理業にかかる確認書　兼　個人情報の取扱い等に関する同意書' },
];
