import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
  zoomPlugin,
  RenderZoomOutProps,
  RenderZoomInProps,
} from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { downloadFile, checkFileExisted } from 'utils';
import { useAppDispatch } from 'hooks';
import { createDownloadLogSalePerson } from 'pages/AdminEditPreliminary/thunk';

interface AdminPreviewPDFProps {
  src: string;
  filename?: string;
  isExaminationEmpty?: boolean;
  onRemove?: () => void;
  isSalePerson?: boolean;
  setDisableBtnPDF?: Dispatch<SetStateAction<boolean>>;
  id?: string;
}

export const AdminPreviewPDF: FC<AdminPreviewPDFProps> = ({
  src,
  filename,
  isExaminationEmpty,
  onRemove,
  isSalePerson,
  setDisableBtnPDF,
  id,
}) => {
  const zoomPluginInstance = zoomPlugin();
  const [showFile, setShowFile] = useState<boolean>(false);
  const { ZoomIn, ZoomOut } = zoomPluginInstance;
  const dispatch = useAppDispatch();

  useEffect(() => {
    checkFileExisted(src).then(setShowFile);
  }, [src]);

  const downloadPdf = async (src: string, filename: string) => {
    downloadFile(src, filename);
    if (!!id)
      await dispatch(
        createDownloadLogSalePerson({ id: id, key: 'download_pdf' })
      );
  };

  return (
    <Stack direction="row">
      {!isExaminationEmpty && showFile ? (
        <Stack
          flex={1}
          direction="row"
          justifyContent="center"
          sx={{ width: 687, height: 550 }}
        >
          <Worker workerUrl="/pdf.worker.bundle.js">
            <Viewer fileUrl={src} plugins={[zoomPluginInstance]} />
          </Worker>
        </Stack>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 613,
            height: 550,
            m: 'auto',
          }}
        >
          <Typography
            variant="leafletOption"
            color="b_333"
            sx={{
              whiteSpace: 'break-spaces',
              textAlign: 'center',
            }}
          >
            {`現在仮審査中です。\n仮審査結果が出るまでお待ちください。`}
          </Typography>
        </Stack>
      )}
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginLeft: 1 }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          spacing={`10px`}
        >
          <ZoomIn>
            {(props: RenderZoomInProps) => (
              <Button
                sx={{
                  width: 36,
                  minWidth: 36,
                  height: 36,
                  bgcolor: 'main_white',
                  color: 'b_333',
                }}
              >
                <Icons.Plus
                  onClick={async () => {
                    const show = await checkFileExisted(src);
                    setShowFile(show);
                    props.onClick();
                    if (setDisableBtnPDF && !show) {
                      setDisableBtnPDF(true);
                    }
                  }}
                />
              </Button>
            )}
          </ZoomIn>
          <ZoomOut>
            {(props: RenderZoomOutProps) => (
              <Button
                sx={{
                  width: 36,
                  minWidth: 36,
                  height: 36,
                  bgcolor: 'main_white',
                  color: 'b_333',
                }}
              >
                <Icons.Minus
                  onClick={async () => {
                    const show = await checkFileExisted(src);
                    setShowFile(show);
                    props.onClick();
                    if (setDisableBtnPDF && !show) {
                      setDisableBtnPDF(true);
                    }
                  }}
                />
              </Button>
            )}
          </ZoomOut>
          {isSalePerson && !isExaminationEmpty && showFile && (
            <Button
              sx={{
                width: 36,
                minWidth: 36,
                height: 36,
                bgcolor: 'main_white',
                color: 'pink_text',
                border: '1px solid',
                borderColor: 'pink_text',
              }}
              onClick={async () => {
                if (!filename) {
                  return;
                }
                const show = await checkFileExisted(src);
                setShowFile(show);
                show && downloadPdf(src, filename);
                if (setDisableBtnPDF && !show) {
                  setDisableBtnPDF(true);
                }
              }}
            >
              <Icons.PDFDownload />
            </Button>
          )}
        </Stack>
        {!isSalePerson && (
          <Stack>
            <Button
              sx={{
                width: 36,
                minWidth: 36,
                height: 36,
                bgcolor: 'main_white',
              }}
            >
              <Icons.Trash onClick={onRemove} />
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
