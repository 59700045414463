import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPLogout: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 21 18" {...props}>
      <svg
        width="21"
        height="18"
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7484 7.75934L14.9089 2.91587C14.6564 2.66344 14.3172 2.52539 13.9622 2.52539C13.2247 2.52539 12.6252 3.12491 12.6252 3.86247V6.31182H7.5766C6.87847 6.31182 6.31445 6.87584 6.31445 7.57396V10.0982C6.31445 10.7964 6.87847 11.3604 7.5766 11.3604H12.6252V13.8097C12.6252 14.5473 13.2247 15.1468 13.9622 15.1468C14.3172 15.1468 14.6564 15.0048 14.9089 14.7563L19.7484 9.91287C20.0324 9.62889 20.1941 9.23841 20.1941 8.83611C20.1941 8.4338 20.0324 8.04332 19.7484 7.75934Z"
          fill="url(#paint0_linear_8106_26224)"
        />
        <path
          opacity="0.4"
          d="M7.57286 1.26214C7.57286 1.96027 7.00884 2.52429 6.31071 2.52429H3.78643C3.08831 2.52429 2.52429 3.08831 2.52429 3.78643V13.8836C2.52429 14.5817 3.08831 15.1457 3.78643 15.1457H6.31071C7.00884 15.1457 7.57286 15.7097 7.57286 16.4079C7.57286 17.106 7.00884 17.67 6.31071 17.67H3.78643C1.696 17.67 0 15.974 0 13.8836V3.78643C0 1.696 1.696 0 3.78643 0H6.31071C7.00884 0 7.57286 0.56402 7.57286 1.26214Z"
          fill="url(#paint1_linear_8106_26224)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8106_26224"
            x1="8.45181"
            y1="12.5511"
            x2="18.0435"
            y2="1.89675"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8106_26224"
            x1="1.16616"
            y1="14.036"
            x2="11.0116"
            y2="9.77393"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
