import { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { zeroPad } from 'utils';
import { dayjs } from 'libs';
import { InputSelect, InputSelectProps } from '../InputSelect';
import { InputField } from '../InputField';
import { validationSchema } from './validationSchema';
import { validationSchema2 } from './validationSchema2';

export type InputDateProps = Pick<InputSelectProps, 'disabled' | 'readOnly'> & {
  name: string;
  isSelect?: boolean;
  selectWidth?: { mobile?: string; tablet?: number };
  yearOptions?: InputSelectProps['options'];
  checkRequired?: boolean;
};

export const InputDate: FC<InputDateProps> = ({
  disabled,
  readOnly,
  isSelect,
  yearOptions,
  checkRequired,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const initialValues = useMemo(() => {
    const [year = '', month = ''] = field.value
      ? field.value.split('/')
      : ['', ''];
    return { year, month };
  }, [field.value]);

  const formik = useFormik({
    initialValues,
    validationSchema: checkRequired ? validationSchema : validationSchema2,
    onSubmit(values, actions) {
      if (!values.month && !values.year) {
        return setFieldValue(field.name, '');
      }
      setFieldValue(field.name, `${values.year}/${values.month}`);
      actions.setSubmitting(false);
    },
  });

  return (
    <FormikProvider value={formik}>
      <Stack direction="row">
        <input name={field.name} type="hidden" />
        <Stack
          direction="row"
          alignItems="center"
          sx={{ mr: { mobile: '10px', tablet: '21px' } }}
        >
          {isSelect ? (
            <InputSelect
              sx={{
                width: { mobile: 100, tablet: props.selectWidth ?? 220 },
                height: 50,
              }}
              name="year"
              placeholder="ー"
              disabled={disabled}
              readOnly={readOnly}
              options={yearOptions ?? defaultYearOptions}
              error={
                !formik.values.year && !!formik.touched.year && checkRequired
              }
              errorMessage={meta.error}
              onChange={() => formik.handleSubmit()}
              onClose={() => formik.handleSubmit()}
              unit="年"
              showError={false}
              useFastField={false}
              checkRequired={checkRequired}
            />
          ) : (
            <InputField
              sx={{ width: { mobile: 100, tablet: props.selectWidth ?? 240 } }}
              name="year"
              placeholder="ー"
              disabled={disabled}
              error={!formik.values.year && !!meta.error && meta.touched}
              errorMessage={meta.error}
              onBlur={() => formik.handleSubmit()}
              unit="年"
              numeric
            />
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          {isSelect ? (
            <InputSelect
              sx={{ width: 100, height: 50 }}
              name="month"
              placeholder="ー"
              disabled={disabled}
              readOnly={readOnly}
              options={monthOpts}
              error={
                (!formik.values.month &&
                  !!formik.touched.month &&
                  checkRequired) ||
                (!!formik.values.month && !!meta.error)
              }
              errorMessage={meta.error}
              onChange={() => formik.handleSubmit()}
              onClose={() => formik.handleSubmit()}
              unit="月"
              useFastField={false}
              checkRequired={checkRequired}
            />
          ) : (
            <InputField
              sx={{ width: 100 }}
              name="month"
              placeholder="ー"
              disabled={disabled}
              error={!formik.values.month && !!meta.error && meta.touched}
              errorMessage={meta.error}
              onBlur={() => formik.handleSubmit()}
              unit="月"
              numeric
            />
          )}
        </Stack>
      </Stack>
    </FormikProvider>
  );
};

const monthOpts: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);

const defaultYearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(52), (_, index) => {
    const year = zeroPad(dayjs().year() - index);
    return {
      value: year,
      label: `${year}`,
    };
  })
);
