import { AdminPreliminaryItem } from 'constant';
import { AdminPreliminaries, DynamicOption } from 'services';
import { buildPersonalRows } from '../../AdminEditPreliminary/utils';

export function buildPersonalRowsSalePerson(
  rows: AdminPreliminaryItem[],
  data: AdminPreliminaries['p_application_header'],
  plannedCohabitantDynamic: DynamicOption,
  managerRole: string
): AdminPreliminaryItem[] {
  return buildPersonalRows(
    rows,
    data,
    plannedCohabitantDynamic,
    managerRole
  ).filter(({ required, name_en }) => {
    if (required) {
      return false;
    }
    if (
      name_en.includes('p_residents_attributes[1]') ||
      name_en.includes('p_residents_attributes[2]') ||
      name_en.includes('p_residents_attributes[3]') ||
      name_en.includes('p_residents_attributes[4]') ||
      name_en.includes('p_residents_attributes[5]')
    ) {
      return false;
    }
    return true;
  });
}
