import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  handleCallDraft: () => void;
}>;

export const SPLoginCompleteModal: FC<ModalProps> = ({
  open,
  onClose,
  handleCallDraft,
}) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="おかえりなさい"
      disableBackDropClick
      footer={
        <Stack spacing={6}>
          <SPButton
            sx={{
              width: '100%',
              bgcolor: 'sp_primary_100_main',
            }}
            endIcon={<Icons.ArrowForward stroke="white" />}
            onClick={handleCallDraft}
          >
            <Typography variant="SP_multiple_checkbox_label" color="main_white">
              前回の続きから入力する
            </Typography>
          </SPButton>
          <SPButton
            sx={{
              width: '100%',
              border: (theme) =>
                `1px solid ${theme?.palette?.sp_primary_100_main}`,
              bgcolor: 'main_white',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              TOPへ
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};
