import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { email: string } = {
  email: '',
};

const accountInformationSlice = createSlice({
  name: 'accountInformation',
  initialState,
  reducers: {
    saveLoginEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { saveLoginEmail } = accountInformationSlice.actions;

export default accountInformationSlice.reducer;
