import { FC, ReactNode, useCallback, useMemo } from 'react';
import {
  ButtonProps,
  Grid,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { FastField, FastFieldProps, useField } from 'formik';
import { Icons, SPInputSelect } from 'components';
import { get, isEqual, xor } from 'lodash';
import { child, ChildType } from 'constant';
import { isMobile } from 'react-device-detect';

type RadioItems = {
  value: string;
  label: string;
};

type OptionType = {
  name: string;
  id: number | string;
  note?: string;
  hasSelect?: boolean;
  selectName?: string;
  readOnly?: boolean;
};

export type SPCheckboxMultipleButtonProps = Omit<ButtonProps, 'form'> & {
  name: string;
  options: OptionType[];
  isSelect?: boolean;
  select?: ReactNode | StackProps | false;
  additional?: ReactNode;
};

export const SPCheckboxMultipleButtonTemp: FC<
  FastFieldProps<unknown[]> & SPCheckboxMultipleButtonProps
> = ({
  field,
  meta,
  form,
  name,
  options,
  children,
  isSelect = false,
  additional,
}) => {
  const handleSelect = useCallback(
    (opt: OptionType) => {
      if (!opt.readOnly) {
        form.setFieldValue(name, xor(field.value, [opt.id]));
      }
    },
    [field.value, form, name]
  );

  const getStyles = useCallback(
    (opt: number | string) => {
      return field.value.includes(opt)
        ? {
            color: 'main_white',
            fontWeight: '400',
            startIcon: <Icons.SPChecked />,
          }
        : {
            color: 'normal_text',
            fontWeight: '400',
            startIcon: <Icons.SPUnchecked />,
          };
    },
    [field.value]
  );

  const checkBorderColor = useMemo(() => {
    if (!!field.value.length) return 'sp_primary_100_main';
    if (meta.touched && !!meta.error) return 'sp_secondary_01';
    return 'sp_primary_40';
  }, [meta.touched, meta.error, field.value.length]);

  return (
    <Stack
      sx={{
        '@media (orientation: landscape)': {
          maxWidth: isMobile ? '100%' : 480,
        },
        maxWidth: 480,
        bgcolor: 'main_white',
        borderRadius: 3,
        boxShadow: '0 0 15px rgba(60, 72, 196, 0.1)',
        border: `1px solid`,
        borderColor: checkBorderColor,
      }}
    >
      <Grid container spacing={isSelect ? 0 : 7} p={4}>
        <input type="hidden" name={field.name} />
        {options.map((opt, index) => {
          const { color, fontWeight, startIcon } = getStyles(opt.id);

          const checkBorderBottomWidth = () => {
            if (index !== options.length - 1) return 1;
            return 0;
          };

          return (
            <Grid key={index} item {...{ mobile: 12, md: 12 }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  position: 'relative',
                  borderBottomStyle: isSelect ? 'dashed' : 'none',
                  borderBottomWidth: checkBorderBottomWidth,
                  borderBottomColor: isSelect ? 'sp_primary_60' : 'none',
                  py: isSelect ? 5 : 0,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  onClick={() => handleSelect(opt)}
                >
                  <Stack>{startIcon}</Stack>
                  <Stack
                    justifyContent="center"
                    spacing={3}
                    direction="row"
                    alignItems="center"
                  >
                    <Typography
                      variant="SP_multiple_checkbox_label"
                      sx={{
                        textTransform: 'none',
                        fontWeight,
                        color: 'sp_primary_100_main',
                      }}
                    >
                      {opt.name}
                    </Typography>
                    {!!opt.note && (
                      <Typography
                        variant="text_note"
                        sx={{
                          textAlign: 'left',
                          color,
                          px: 3,
                          display: { mobile: 'none', tablet: 'block' },
                        }}
                      >
                        {opt.note}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                {opt.hasSelect && (
                  <Stack sx={{ position: 'absolute', right: 1 }}>
                    <SPInputSelect
                      showError={false}
                      name={checkSelectName(index)}
                      options={selectOptions}
                      placeholder="--"
                      unit="人"
                      sx={{ width: '62px' }}
                    />
                  </Stack>
                )}
              </Stack>
            </Grid>
          );
        })}
        {isSelect && <Stack sx={{ width: '100%' }}>{children}</Stack>}
      </Grid>
      {additional}
    </Stack>
  );
};

export const SPCheckboxMultipleButton: FC<SPCheckboxMultipleButtonProps> = ({
  name,
  options,
  additional,
  ...props
}) => {
  const [, meta] = useField(name);
  const { error, touched } = meta;

  const shouldUpdate = useCallback(
    (
      nextProps: {
        options: {
          name: string;
          id: number | string;
          note?: string;
        }[];
        error: boolean;
        touched: boolean;
      },
      currentProps: {
        options: {
          name: string;
          id: number | string;
          note?: string;
        }[];
        error: boolean;
        touched: boolean;
      }
    ) =>
      !isEqual(nextProps.options, currentProps.options) ||
      nextProps.error !== currentProps.error ||
      nextProps.touched !== currentProps.touched ||
      get(nextProps, `formik.values.${name}`) !==
        get(currentProps, `formik.values.${name}`),
    [name]
  );
  return (
    <FastField
      name={name}
      shouldUpdate={shouldUpdate}
      options={options}
      error={!!error}
      touched={touched}
    >
      {({ form, field, meta }: FastFieldProps<unknown[]>) => (
        <>
          <SPCheckboxMultipleButtonTemp
            {...props}
            name={name}
            options={options}
            form={form}
            field={field}
            meta={meta}
            additional={additional}
          />
          {meta.touched && !!meta.error && (
            <Typography pt={1} variant="text_error" color="sp_secondary_01">
              ※{meta.error ?? ''}
            </Typography>
          )}
        </>
      )}
    </FastField>
  );
};
const selectOptions: Array<RadioItems> = [
  {
    value: ChildType.ONE,
    label: child[ChildType.ONE],
  },
  {
    value: ChildType.TWO,
    label: child[ChildType.TWO],
  },
  {
    value: ChildType.THREE,
    label: child[ChildType.THREE],
  },
  {
    value: ChildType.FOUR,
    label: child[ChildType.FOUR],
  },
  {
    value: ChildType.FIVE,
    label: child[ChildType.FIVE],
  },
  {
    value: ChildType.SIX,
    label: child[ChildType.SIX],
  },
  {
    value: ChildType.SEVEN,
    label: child[ChildType.SEVEN],
  },
  {
    value: ChildType.EIGHT,
    label: child[ChildType.EIGHT],
  },
  {
    value: ChildType.NINE,
    label: child[ChildType.NINE],
  },
];

const checkSelectName = (index: number) => {
  if (index === 0 || index === 1) return 'p_application_header.children_number';
  if (index === 3 || index === 4) return 'p_application_header.siblings_number';
  return 'p_application_header.other_people_number';
};
