import { createSlice } from '@reduxjs/toolkit';
import { submitFormData } from 'pages/StepFour/thunk';
import { CompleteResponse } from 'types';

type InitialState = {
  form: CompleteResponse;
};

export const initialState: InitialState = {
  form: {
    application_number: '',
    created_at: '',
  },
};

export const completeSlice = createSlice({
  name: 'stepFourB',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(submitFormData.fulfilled, (state, action) => {
      state.form = action.payload.data;
    });
  },
});

export default completeSlice.reducer;
