import { AdminPreliminaryItem } from 'constant';
import { SalePersonPreliminaries } from 'services';
import { buildGuaranteeProvider } from '../../AdminEditPreliminary/utils';

export const buildGuaranteeProviderSalePerson = (
  values: SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[][] => {
  return buildGuaranteeProvider(values).map((row) =>
    row
      .map((subRow) => {
        if (
          subRow.name_en ===
          'p_join_guarantors_attributes[0].guarantor_relationship_name'
        ) {
          return {
            ...subRow,
            required: false,
            subField: undefined,
          };
        }
        return subRow;
      })
      .filter(({ required }) => !required)
  );
};
