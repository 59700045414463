import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import {
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  Grid,
  SxProps,
} from '@mui/material';
import {
  FastField,
  FastFieldProps,
  FieldInputProps,
  useField,
  useFormikContext,
} from 'formik';
import { RadioGroup } from 'formik-mui';
import { Icons } from 'components';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

type RadioItems = {
  value: string;
  label: string | string[];
  src?: string;
  sx?: SxProps;
  icon?: ReactNode;
  uploadImage?: ReactNode;
};

type SPRadioGroupButtonProps = {
  name: string;
  options: Array<RadioItems>;
  justifyContent?: 'left' | 'center' | 'right';
  disabled?: boolean;
  onChange?: (checked: boolean, value: string) => void;
  handleReClickOption?: () => void;
  readOnly?: boolean;
  showError?: boolean;
  required?: boolean;
  display?: 'row' | 'column';
  sx?: SxProps;
};

type RadioButtonProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: FieldInputProps<any>;
  required: boolean;
  error: boolean;
  option: RadioItems;
  checked: boolean;
  disabled: boolean;
  onChange?: (checked: boolean, value: string) => void;
  handleReClickOption?: () => void;
  readOnly: boolean;
  display?: 'row' | 'column';
  sx?: SxProps;
};

type CheckedItemProps = {
  option: RadioItems;
  checked: boolean;
  disabled: boolean;
  readOnly: boolean;
};

export const SPRadioGroupButton: FC<SPRadioGroupButtonProps> = ({
  name,
  options,
  justifyContent = 'left',
  disabled = false,
  onChange,
  readOnly = false,
  showError = true,
  required = false,
  display = 'column',
  sx,
  handleReClickOption,
}) => {
  const [, meta] = useField(name);
  const { error, touched } = meta;

  const shouldUpdate = useCallback(
    (
      nextProps: {
        disabled: boolean;
        readOnly: boolean;
        error: boolean;
        touched: boolean;
        onChange?: (checked: boolean, value: string) => void;
        options: RadioItems[];
      },
      props: {
        disabled: boolean;
        readOnly: boolean;
        error: boolean;
        touched: boolean;
        onChange?: (checked: boolean, value: string) => void;
        options: RadioItems[];
      }
    ) =>
      nextProps.disabled !== props.disabled ||
      nextProps.readOnly !== props.readOnly ||
      nextProps.error !== props.error ||
      nextProps.touched !== props.touched ||
      nextProps.onChange !== props.onChange ||
      !isEqual(nextProps.options, props.options) ||
      get(nextProps, `formik.values.${name}`) !==
        get(props, `formik.values.${name}`),
    [name]
  );

  return (
    <FastField
      name={name}
      disabled={disabled}
      readOnly={readOnly}
      error={error}
      touched={touched}
      shouldUpdate={shouldUpdate}
      onChange={onChange}
      options={options}
    >
      {({ field, form, meta }: FastFieldProps) => (
        <Stack spacing={1}>
          {touched && !!error && showError && (
            <Typography variant="text_error" color="sp_secondary_01">
              ※{error}
            </Typography>
          )}
          <RadioGroup field={field} form={form} meta={meta}>
            {display === 'row' ? (
              <Stack direction="row" spacing="15px">
                {options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    sx={{ width: '100%', height: '100%', mx: 0 }}
                    value={name}
                    control={
                      <RadioButton
                        name={name}
                        field={field}
                        required={required}
                        error={!!error && touched}
                        onChange={onChange}
                        option={option}
                        checked={field.value === option.value}
                        disabled={disabled}
                        readOnly={readOnly}
                        display={display}
                        sx={sx}
                        handleReClickOption={handleReClickOption}
                      />
                    }
                    label=""
                  />
                ))}
              </Stack>
            ) : (
              <Grid container spacing={3} justifyContent={justifyContent}>
                {options.map((option) => (
                  <Grid key={option.value} item {...{ mobile: 12 }}>
                    <FormControlLabel
                      sx={{ width: '100%', height: '100%', mx: 0 }}
                      value={name}
                      control={
                        <RadioButton
                          name={name}
                          field={field}
                          required={required}
                          error={!!error && touched}
                          onChange={onChange}
                          option={option}
                          checked={field.value === option.value}
                          disabled={disabled}
                          readOnly={readOnly}
                          display={display}
                          sx={sx}
                          handleReClickOption={handleReClickOption}
                        />
                      }
                      label=""
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </RadioGroup>
        </Stack>
      )}
    </FastField>
  );
};

const RadioButton: FC<RadioButtonProps> = ({
  name,
  field,
  required,
  error,
  option,
  checked,
  disabled,
  onChange,
  readOnly,
  display,
  handleReClickOption,
  sx,
}) => {
  const { setFieldValue } = useFormikContext();
  const IconElement = useMemo(
    () => (
      <CheckedItemMemo
        option={option}
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
      />
    ),
    [checked, disabled, option, readOnly]
  );

  const RowIconElement = useMemo(
    () => (
      <RowCheckedItemMemo
        option={option}
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
      />
    ),
    [checked, disabled, option, readOnly]
  );

  const checkBorderColor = useMemo(() => {
    if (checked) return 'sp_primary_100_main';
    if (checked && option.uploadImage) return 'main_white';
    if (error) return 'sp_secondary_01';
    return 'sp_primary_40';
  }, [checked, error, option.uploadImage]);

  const checkBgcolor = useMemo(() => {
    if (checked && option.uploadImage) return 'main_white';
    if (checked) return 'sp_primary_40';
    return 'main_white';
  }, [checked, option.uploadImage]);

  const checkBgcolorDisable = useMemo(() => {
    if (checked) return 'h_blugreen';
    if (disabled) return 'bg_disabled';
    return 'transparent';
  }, [checked, disabled]);

  return (
    <Stack sx={{ ...sx, width: '100%' }}>
      <Radio
        name={name}
        disabled={disabled || readOnly}
        onChange={(event, checked) =>
          onChange && !readOnly && onChange(checked, option.value)
        }
        onClick={() => {
          if (field.value === option.value && !required) {
            setFieldValue(field.name, '');
            if (!!handleReClickOption) {
              handleReClickOption();
            }
          }
        }}
        checkedIcon={display === 'row' ? RowIconElement : IconElement}
        icon={display === 'row' ? RowIconElement : IconElement}
        value={option.value}
        sx={{
          flex: 1,
          borderTopRightRadius: '14px',
          borderTopLeftRadius: '14px',
          borderBottomRightRadius: checked && option.uploadImage ? 0 : '14px',
          borderBottomLeftRadius: checked && option.uploadImage ? 0 : '14px',
          minHeight: 48,
          '.MuiSvgIcon-root': {
            position: 'relative',
          },
          '&, &:hover': {
            bgcolor: checkBgcolor,
            color: checked ? 'sp_primary_100_main' : 'normal_text',
            borderTop: '1px solid',
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderBottom: checked && option.uploadImage ? 0 : '1px solid',
            borderColor: checkBorderColor,
            boxShadow:
              checked && option.uploadImage
                ? 'none'
                : '0px 0px 15px rgba(60, 72, 196, 0.1)',
          },
          '&:hover': {
            opacity: { mobile: 1, desktop: 0.8 },
          },
          '&.Mui-disabled': {
            bgcolor: checkBgcolorDisable,
            '.MuiTypography-root': {
              color: checked || disabled ? 'main_white' : 'off_text',
            },
          },
          alignItems: 'center',
          textAlign: display === 'row' ? 'center' : 'left',
        }}
      />
      {option.uploadImage && checked && (
        <Stack
          sx={{
            flex: 1,
            bgcolor: 'main_white',
            pl: window.innerWidth <= 332 ? 0 : 2,
            pb: 3,
            borderBottomLeftRadius: '14px',
            borderBottomRightRadius: '14px',
            borderBottom: 1,
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'sp_primary_100_main',
          }}
        >
          {checked ? option.uploadImage : ''}
        </Stack>
      )}
    </Stack>
  );
};

const CheckedItem: FC<CheckedItemProps> = ({
  option,
  checked,
  disabled,
  readOnly,
}) => {
  return (
    <Stack
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      pl={option.uploadImage ? 4 : 0}
    >
      {checked ? (
        <Icons.SPRadioChecked />
      ) : disabled || readOnly ? (
        <Icons.RadioDisabled />
      ) : (
        <Icons.SPRadioUnchecked />
      )}
      <Stack
        alignItems="flex-end"
        justifyContent="flex-start"
        flex={1}
        direction="row"
        sx={{ ml: '10px' }}
      >
        {isArray(option.label) ? (
          <>
            <Typography
              variant="radio_group_button_title"
              color={
                checked ? 'main_white' : readOnly ? 'off_text' : 'normal_text'
              }
              fontWeight={checked ? 700 : 500}
              sx={{
                whiteSpace: 'pre',
                ...option.sx,
              }}
            >
              {option.label[0]}
            </Typography>
            <Typography
              variant="radio_group_button_title"
              color={
                checked ? 'main_white' : readOnly ? 'off_text' : 'normal_text'
              }
              fontWeight={checked ? 700 : 500}
              sx={{
                whiteSpace: 'pre',
                fontSize: 15,
                lineHeight: '21px',
                ...option.sx,
              }}
            >
              {option.label[1]}
            </Typography>
          </>
        ) : (
          <Typography
            variant="radio_group_button_title"
            color={readOnly ? 'off_text' : 'sp_primary_100_main'}
            fontWeight={checked ? 700 : 500}
            sx={{
              whiteSpace: 'pre',
              ...option.sx,
            }}
          >
            {option.label}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const RowCheckedItem: FC<CheckedItemProps> = ({
  option,
  checked,
  ...props
}) => {
  return (
    <Typography
      variant="radio_group_button_title"
      color="sp_primary_100_main"
      sx={{
        fontWeight: 500,
        lineHeight: '100%',
        whiteSpace: 'pre',
        ...option.sx,
      }}
    >
      {option.label}
    </Typography>
  );
};

const CheckedItemMemo = memo(CheckedItem, isEqual);
const RowCheckedItemMemo = memo(RowCheckedItem, isEqual);
