import {
  Card,
  CardHeader,
  CardMedia,
  Divider,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { AdminEditModal, Button, Icons, InputFileAdmin } from 'components';
import { FC, useCallback, useState } from 'react';
import {
  logEditedItem,
  UploadedFileKeysBorrowing,
  UploadedFilesManager,
} from 'services';
import { getFileExtFromUrl } from 'utils';
import { useAppDispatch } from '../../../hooks';
import { getLogEditedPreliminary } from '../../../pages/AdminEditPreliminary/thunk';
import { getLogEditedSalePersonPreliminary } from '../../../pages/SalePersonEditPreliminary/thunk';

interface DocumentUploadItemBorrowingProps {
  title?: string;
  name?: string;
  multiple: boolean;
  options: {
    borrowId?: string;
    headerId?: string;
    trackable_type?: string;
    isHasLog?: boolean;
    jp_name: string;
    en_name: keyof UploadedFileKeysBorrowing;
    files?: (File | UploadedFilesManager)[];
    isRequired: boolean;
  }[];
  files?: File[];
  isLoadingFiles?: boolean;
  onChange: (
    file: (File | UploadedFilesManager)[],
    id: string,
    name?: keyof UploadedFileKeysBorrowing
  ) => void;
  setOpenPreview: (name: string, src: string) => void;
  isDisplay: boolean;
  editable: boolean;
  isSalePerson?: boolean;
}

export const DocumentUploadItemBorrowing: FC<
  DocumentUploadItemBorrowingProps
> = ({
  title,
  name,
  multiple,
  options,
  onChange,
  setOpenPreview,
  isDisplay,
  editable,
  isSalePerson,
}) => {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [logEditedInformation, setLogEditedInformation] =
    useState<logEditedItem[]>();

  const handleOpenLogEdited = useCallback(
    async (
      borrowingId?: string,
      headerId?: string,
      trackable_type?: string
    ) => {
      if (isSalePerson) {
        const result = await dispatch(
          getLogEditedSalePersonPreliminary({
            id: headerId,
            trackable_type: trackable_type,
            trackable_id: borrowingId,
            column: 'repayment_schedule_image',
          })
        );
        if (getLogEditedSalePersonPreliminary.fulfilled.match(result)) {
          setLogEditedInformation(result.payload.data.value);
        }
        return;
      }
      const result = await dispatch(
        getLogEditedPreliminary({
          id: headerId,
          trackable_type: trackable_type,
          trackable_id: borrowingId,
          column: 'repayment_schedule_image',
        })
      );
      if (getLogEditedPreliminary.fulfilled.match(result)) {
        setLogEditedInformation(result.payload.data.value);
      }
    },
    [dispatch, isSalePerson]
  );
  const handleChangeFiles = useCallback(
    (files: File[], enName: keyof UploadedFileKeysBorrowing) => {
      if (multiple) {
        const filesUpdated = options[0].files
          ? [...options[0].files].concat(files)
          : files;
        onChange(filesUpdated, '', enName);
      } else {
        onChange(files, '', enName);
      }
    },
    [multiple, onChange, options]
  );

  const onRemoveFile = useCallback(
    (enName: keyof UploadedFileKeysBorrowing, index: number, id: string) => {
      if (options[0].files) {
        const filesUpload = [...options[0].files];
        filesUpload.splice(index, 1);
        onChange(filesUpload, id, enName);
      }
    },
    [onChange, options]
  );

  return (
    <Stack flex={1} display={isDisplay ? 'flex' : 'none'}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ paddingLeft: 5 }}
        >
          <Typography
            variant="title_avatar_card"
            sx={{ color: 'sp_primary_100_main' }}
          >
            {name}
          </Typography>
          <Typography
            variant="title_upload_file"
            sx={{ alignSelf: 'center', color: 'b_333' }}
          >
            {title}
          </Typography>
        </Stack>
      </Stack>
      {options?.map((item, index) => (
        <Stack key={index}>
          <Stack
            direction="row"
            sx={{ position: 'relative', paddingBottom: '10px' }}
            justifyContent="space-between"
          >
            <Stack flex={1} justifyContent="center">
              <Typography
                variant="option_upload_file"
                sx={{
                  pl: 11,
                  width: '50%',
                  color: 'b_333',
                  wordBreak: 'break-all',
                }}
              >
                {item.jp_name}
              </Typography>
            </Stack>
            <Stack
              flex={1}
              direction="row"
              sx={{
                backgroundColor: 'bg_off',
                p: 1,
                borderRadius: '2px',
                position: 'relative',
                minWidth: 0,
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                flex={1}
                spacing={1}
                sx={{
                  paddingTop: '2px',
                  minHeight: '35px',
                  width: 'calc(100% - 130px)',
                }}
              >
                {item.files?.map((file: UploadedFilesManager | File, index) => {
                  if (file instanceof File)
                    return (
                      <FileItem
                        index={index}
                        id=""
                        key={index}
                        name={file.name}
                        src={URL.createObjectURL(file)}
                        field_en={item.en_name}
                        handleRemoveDocument={onRemoveFile}
                        setOpenPreview={setOpenPreview}
                        editable={editable}
                      />
                    );
                  return (
                    <FileItem
                      index={index}
                      key={index}
                      name={file.filename}
                      src={file.url}
                      field_en={item.en_name}
                      id={file.id}
                      handleRemoveDocument={onRemoveFile}
                      setOpenPreview={setOpenPreview}
                      editable={editable}
                    />
                  );
                })}
              </Stack>
              {item.isHasLog && (
                <Icons.EditField
                  fill={'#333333'}
                  onClick={() => {
                    handleOpenLogEdited(
                      item?.borrowId,
                      item?.headerId,
                      item?.trackable_type
                    );
                    setOpenModal(true);
                  }}
                  sx={{ cursor: 'pointer', mt: '6px' }}
                />
              )}
              <InputFileAdmin
                disabled={!editable}
                name={item.en_name}
                sx={{ p: 0 }}
                multiple={multiple}
                onChange={(acceptedFiles) =>
                  handleChangeFiles(acceptedFiles, item.en_name)
                }
              >
                <Button
                  disabled={!editable}
                  sx={{
                    minHeight: 36,
                    width: 121,
                    backgroundColor: 'main_white',
                    border: '1px solid #6B70F0',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'sp_primary_100_main',
                      color: 'main_white',
                      '& .MuiTypography-root': {
                        color: 'main_white',
                      },
                    },
                  }}
                >
                  <Typography
                    variant="pdf_title"
                    sx={{ color: 'sp_primary_100_main' }}
                  >
                    ファイルを選択
                  </Typography>
                </Button>
              </InputFileAdmin>
              {logEditedInformation && (
                <AdminEditModal
                  fieldNameEn={item.en_name}
                  fieldNameJP={item.jp_name}
                  open={openModal}
                  rows={[]}
                  onClose={() => {
                    setOpenModal((prevState) => !prevState);
                    setLogEditedInformation([]);
                  }}
                  tableRows={logEditedInformation}
                />
              )}
            </Stack>
          </Stack>
          {/* {item.isRequired && (!item.files || item.files.length === 0) && (
              <Stack
                sx={{
                  textAlign: 'right',
                  paddingBottom: '3px',
                  color: 'sp_secondary_01',
                }}
              >
                この書類は必須な書類なので、アップロードしてください。
              </Stack>
            )} */}
        </Stack>
      ))}
    </Stack>
  );
};

type FileItemProps = {
  index: number;
  src: string;
  name: string;
  field_en?: keyof UploadedFileKeysBorrowing;
  handleRemoveDocument?: (
    formikName: keyof UploadedFileKeysBorrowing,
    index: number,
    id: string
  ) => void;
  setOpenPreview: (name: string, src: string) => void;
  editable: boolean;
  id: string;
};

const FileItem: FC<FileItemProps> = ({
  index,
  src,
  name,
  field_en,
  handleRemoveDocument,
  setOpenPreview,
  editable,
  id,
}) => {
  return (
    <Stack
      flex={1}
      key={index}
      direction="row"
      alignItems="center"
      sx={{
        minWidth: 0,
        overflow: 'hidden',
        pl: '10px',
        width: 'calc(100% - 10px)',
      }}
    >
      {editable && (
        <Icons.Cancel
          sx={{ width: 15, height: 15, mt: 1 }}
          onClick={() => {
            if (!!field_en && !!handleRemoveDocument)
              return handleRemoveDocument(field_en, index, id);
          }}
        />
      )}
      {getFileExtFromUrl(name).includes('pdf') ? (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#6B70F0',
          }}
        >
          {name}
        </Typography>
      ) : (
        <CustomWidthTooltip
          sx={{ bgcolor: 'main_white' }}
          title={
            <Card>
              <CardHeader
                title={name}
                titleTypographyProps={{
                  variant: 'title_card_preview',
                  color: 'b_333',
                }}
                subheader={<Divider />}
                sx={{
                  '&.MuiCardHeader-root': {
                    p: 0,
                    pt: '14px',
                    textAlign: 'center',
                  },
                  '& .MuiCardHeader-title': {
                    pb: '14px',
                  },
                }}
              />
              <CardMedia
                component="img"
                sx={{
                  bgcolor: 'main_white',
                  borderRadius: 5,
                  m: 'auto',
                  pt: 2,
                  height: 325,
                  width: 512,
                }}
                image={src}
              />
            </Card>
          }
        >
          <Typography
            onMouseOver={() => {
              setOpenPreview(name, src);
            }}
            onMouseLeave={() => {
              setOpenPreview(name, src);
            }}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: '#6B70F0',
            }}
          >
            {name}
          </Typography>
        </CustomWidthTooltip>
      )}
    </Stack>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  transform: 'translate(-50%, -50%) !important',
  left: '50% !important',
  top: '50% !important',
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    backgroundColor: 'main_white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '2px',
  },
});
