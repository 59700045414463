import { Divider, Stack } from '@mui/material';
import { DrawerMenu } from 'containers/DrawerMenu';

import { FC, PropsWithChildren } from 'react';
import { Footer, FooterProps, Header, HeaderProps } from './components';

type LayoutProps = PropsWithChildren<{
  header?: HeaderProps;
  footer?: FooterProps;
  hasFooter?: boolean;
  hasDrawer?: boolean;
}>;
export const UserLayout: FC<LayoutProps> = ({
  footer,
  children,
  header,
  hasFooter = true,
  hasDrawer = true,
}) => {
  return (
    <Stack sx={{ overflow: 'auto' }}>
      <Header {...header} />
      <Stack sx={{ height: 40 }} />
      <Stack sx={{ position: 'relative', bgcolor: 'bg_off' }}>
        {hasDrawer && (
          <Stack
            sx={{
              height: '100%',
              width: 44,
              bgcolor: 'main_white',
              position: 'fixed',
              left: 0,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            }}
          >
            <Stack sx={{ px: '2px', py: '10px' }}>
              <DrawerMenu />
            </Stack>
            <Divider sx={{ borderColor: 'gray' }} />
          </Stack>
        )}
        {children}
      </Stack>
      {hasFooter && <Footer {...footer} />}
    </Stack>
  );
};
