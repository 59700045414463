import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { instance } from 'libs';
import { PublicHolidayRequest, PublicHolidayResponse } from './schemas';

export const getPublicHolidays = ({
  year,
}: PublicHolidayRequest): AxiosPromise<PublicHolidayResponse[]> => {
  const request: AxiosRequestConfig = {
    method: 'GET',
    url: `https://date.nager.at/api/v2/publicholidays/${year}/jp`,
  };
  //@ts-ignore
  return instance(request).catch(() => ({ data: [] }));
};
