import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPExaminationOutcome: FC<SvgIconProps> = (props) => {
  const id = useMemo<string>(
    () => Math.round(Math.random() * 10000000).toString(),
    []
  );
  return (
    <SvgIcon viewBox="0 0 18 25" {...props}>
      <svg
        width="18"
        height="25"
        viewBox="0 0 18 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 0.5C1.34531 0.5 0 1.84531 0 3.5V21.5C0 23.1547 1.34531 24.5 3 24.5H15C16.6547 24.5 18 23.1547 18 21.5V8H12C11.1703 8 10.5 7.32969 10.5 6.5V0.5H3ZM12 0.5V6.5H18L12 0.5ZM14.2969 13.6719L8.29688 19.6719C7.85625 20.1125 7.14375 20.1125 6.70781 19.6719L3.70312 16.6719C3.2625 16.2312 3.2625 15.5188 3.70312 15.0828C4.14375 14.6469 4.85625 14.6422 5.29219 15.0828L7.49531 17.2859L12.7031 12.0781C13.1438 11.6375 13.8562 11.6375 14.2922 12.0781C14.7281 12.5188 14.7328 13.2312 14.2922 13.6672L14.2969 13.6719Z"
          fill={`url(#${id})`}
        />
        <defs>
          <linearGradient
            id={id}
            x1="2.77186"
            y1="19.5642"
            x2="20.4267"
            y2="6.18938"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
