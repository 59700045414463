import { FC } from 'react';
import { Avatar, Box, Container, Stack, Typography } from '@mui/material';
import { UlStyle, Icons } from 'components';
import { StepLayout } from 'containers';
import { useSelector } from 'react-redux';
import { Procedure } from './components';
import { completerSelector } from './selectors';

const Complete: FC = () => {
  const complete = useSelector(completerSelector);
  return (
    <StepLayout hasFooter={false} hasModalSaveDraft={false}>
      <Stack>
        <Stack justifyContent="center" bgcolor="bg_pale_gray">
          <Container sx={{ mx: 11, width: 'auto' }}>
            <Stack
              sx={{
                pt: 9,
                pb: 8,
              }}
            >
              <Typography variant="h2" color="normal_text">
                お申し込み完了
              </Typography>
              <Box
                sx={{
                  ml: 6,
                  mt: 4,
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-all',
                  }}
                  variant="textstyle"
                  color="normal_text"
                >
                  {`住宅ローン事前審査をお申込みいただきありがとうございました。\nご登録いただいたメールアドレスにお申込み完了のメールを送信させていただきます。\n事前審査の結果につきましては、本日以降に電子メール、電話または郵送にてお知らせいたします。\nお問い合わせ・ご照会はお申込み希望店舗にご連絡ください。`}
                </Typography>
              </Box>
            </Stack>
          </Container>
        </Stack>
        <Stack sx={{ mt: 10, mb: 22 }}>
          <Container sx={{ px: 11 }}>
            <Stack
              sx={{
                py: 9,
                border: (theme) => `3px solid ${theme?.palette?.h_blugreen}`,
                borderRadius: '5px',
              }}
              bgcolor="main_white"
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar
                    sx={{ width: 40, height: 40, bgcolor: 'h_blugreen', mr: 4 }}
                  >
                    <Typography sx={{ fontSize: 14 }}>ICON</Typography>
                  </Avatar>
                  <Typography variant="h4" color="Icon_color">
                    受付番号
                  </Typography>
                  <Box
                    sx={{
                      ml: 7,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="total_text" color="normal_text">
                      {complete.application_number}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Stack>
        <Container sx={{ px: 11, bgcolor: 'main_white' }}>
          <Stack sx={{ pt: 18 }}>
            <Typography variant="h2" color="normal_text">
              今後のお手続きの流れ
            </Typography>
            <Stack sx={{ mt: 6 }}>
              {procedures.map((item, index) => (
                <Stack key={index}>
                  <Procedure
                    {...item}
                    note={
                      item.note && (
                        <UlStyle>
                          <li>
                            <Typography
                              variant="style_guard"
                              color="normal_text"
                            >
                              {item.note}
                            </Typography>
                          </li>
                        </UlStyle>
                      )
                    }
                    showArrow={index !== procedures.length - 1}
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Container>
        <Stack justifyContent="center">
          <Container
            sx={{
              pt: 33,
              px: 11,
              bgcolor: 'main_white',
            }}
          >
            <Stack direction="row" alignItems="center">
              <Icons.Error />
              <Typography
                variant="title_warning"
                color="attention"
                sx={{ ml: '6px' }}
              >
                ご注意事項
              </Typography>
            </Stack>
            <Box sx={{ mt: 4, ml: 8 }}>
              <Typography
                sx={{
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-all',
                }}
                variant="liststyle"
                color="normal_text"
              >
                {`事前審査により承認になった場合でも、別途正式なお申し込みが必要です。 今回の事前審査でご入力頂いた内容と正式にお申込みした内容とに相違がある場合やその他条件等によっては、ご連絡した審査結果のいかんにかかわらず添いかねる場合もございますので、ご了承下さい。`}
              </Typography>
            </Box>
          </Container>
        </Stack>
      </Stack>
    </StepLayout>
  );
};

export default Complete;

const procedures = [
  {
    number: 1,
    title: 'ご本人さま・お申込内容の確認・事前審査',
    description: `ご本人さまの確認、および申込内容を確認のうえ、事前審査をします。\nご本人さまの確認ができない場合、および申込内容に不備などがある場合には、ご連絡先にお電話で確認をさせていただきますので、ご了承ください。`,
    note: `万一、ご連絡のつかない場合、およびご本人様の確認ができない場合には、お申込みを取消し
    　させていただくこともありますので、ご了承ください。\nさせていただくこともありますので、ご了承ください。`,
  },
  {
    number: 2,
    title: '審査結果のご連絡',
    description: `結果をご登録の電子メールまたは電話・SMSにてご連絡します。 ご紹介の提携業者・不動産業者様にも審査結果情報をお伝えいたします。`,
  },
  {
    number: 3,
    title: '正式申込',
    description: `事前審査が承認になりましたら、住信SBI銀行みらいバンク支店の普通預金口座を作成下さいませ。普通預金口座の作成後、正式審査書類をご郵送させて頂きます。`,
    note: '必要書類については、審査結果のご連絡の際、ご案内申し上げます。',
  },
];
