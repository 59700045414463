import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPSaveDraft: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5763 5.17547C11.8088 4.93922 12.1912 4.93922 12.4237 5.17547L15.4237 8.17547C15.66 8.40797 15.66 8.79047 15.4237 9.02297C15.1912 9.25922 14.8088 9.25922 14.5763 9.02297L12.6 7.04672V12.7992C12.6 13.1292 12.33 13.3992 12 13.3992C11.67 13.3992 11.4 13.1292 11.4 12.7992V7.04672L9.42375 9.02297C9.19125 9.25922 8.80875 9.25922 8.57625 9.02297C8.34 8.79047 8.34 8.40797 8.57625 8.17547L11.5763 5.17547ZM10.2 0.199219C12.4313 0.199219 14.4038 1.30734 15.6 3.00422C16.1438 2.74434 16.755 2.59922 17.4 2.59922C19.7213 2.59922 21.6 4.47797 21.6 6.79922C21.6 7.20047 21.5438 7.55297 21.4388 7.95422C22.9613 8.75672 24 10.358 24 12.1992C24 14.8505 21.8513 16.9992 19.2 16.9992H5.4C2.41763 16.9992 0 14.5805 0 11.5992C0 9.24422 1.50563 7.24547 3.60637 6.50297C3.76125 2.99672 6.6525 0.199219 10.2 0.199219ZM10.2 1.39922C7.2975 1.39922 4.93125 3.68672 4.80375 6.55547C4.785 7.04672 4.46625 7.44047 4.005 7.63547C2.36925 8.21297 1.2 9.76922 1.2 11.5992C1.2 13.9205 3.08025 15.7992 5.4 15.7992H19.2C21.1875 15.7992 22.8 14.1867 22.8 12.1992C22.8 10.8192 22.0238 9.61922 20.88 9.01547C20.3775 8.71547 20.13 8.16797 20.2838 7.59047C20.3588 7.36172 20.4 7.08797 20.4 6.79922C20.4 5.14172 19.0575 3.79922 17.4 3.79922C16.9388 3.79922 16.5038 3.90422 16.1138 4.08797C15.5888 4.33922 14.955 4.17047 14.6175 3.69422C13.6388 2.30372 12.0263 1.39922 10.2 1.39922Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
