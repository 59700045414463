import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
} from 'pages/SPStepOne/selectors';
import { useCheckPreliminaryStatus } from 'hooks';
import { supportDvh } from '../../utils';
import { spStepTenSelector } from './selectors';

const SPStepTenPage: FC = () => {
  const navigate = useNavigate();
  const { application_number } = useSelector(spStepTenSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);

  useCheckPreliminaryStatus();

  return (
    <SPStepLayout
      hasStepBar
      hasModalSaveDraft={false}
      footer={{
        left: false,
        right: {
          children: 'TOPへ',
          onClick: () => {
            navigate(routeNames.Top.path);
          },
        },
      }}
      sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
      hasJoinGuarantor={hasJoinGuarantor}
      hasTotalIncome={incomeTotalizer}
    >
      <Stack sx={{ width: '100%' }} alignItems="center">
        <Stack sx={{ py: 9 }} justifyContent="center" alignItems="center">
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
            sx={{
              whiteSpace: 'break-spaces',
              textAlign: 'center',
            }}
          >{`仮審査申込が完了しました。\n受付番号をお控えください。`}</Typography>
        </Stack>

        <Stack
          sx={{
            borderRadius: 2,
            border: (theme) =>
              `1px solid ${theme?.palette?.sp_primary_100_main}`,
            mb: 8,
          }}
        >
          <Stack
            sx={{
              borderRadius: '8px 8px 0 0',
              textAlign: 'center',
              justifyContent: 'center',
              height: 30,
              bgcolor: 'sp_primary_40',
            }}
          >
            <Typography
              justifyContent="center"
              variant="SP_form_item_label"
              color="b_333"
            >
              受付番号
            </Typography>
          </Stack>
          <Stack
            sx={{
              borderRadius: '0 0 8px 8px',
              textAlign: 'center',
              py: 3,
              px: 3,
            }}
          >
            <Typography
              justifyContent="center"
              variant="sp_change_password_title"
              color="b_333"
            >
              {application_number}
            </Typography>
          </Stack>
        </Stack>

        <Stack px={10} spacing={6}>
          <Typography
            justifyContent="center"
            variant="sp_change_password_note"
            color="b_333"
          >
            ご登録いただいたメールアドレスにお申込み完了のメールをお送りいたしました。
          </Typography>
          <Typography
            justifyContent="center"
            variant="sp_change_password_note"
            color="b_333"
          >
            受付番号はお問い合わせの際に必要ですので、スクリーンショットなどでお控えください。
          </Typography>
          <Typography
            justifyContent="center"
            variant="sp_change_password_note"
            color="b_333"
          >
            仮審査の結果につきましては、提携先企業からお知らせいたします。
          </Typography>
        </Stack>
      </Stack>
    </SPStepLayout>
  );
};

export default SPStepTenPage;
