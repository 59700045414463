import { Stack, Typography } from '@mui/material';
import { Icons, SPButton } from 'components/atoms';
import { FIELD_VALIDATE_SP } from 'constant';
import { ImageContextType } from 'context';
import { useAppDispatch, useUpdateForm } from 'hooks';
import { routeNames } from 'navigations/routes';
import { adminDynamicOptionsSelectors } from 'pages/SPStartScreen/selectors';
import { FC, createRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { spGetSubmittedPreliminary } from '../../../pages/SPLogin/thunk';
import { convertData } from '../AdminPreliminaryTable/utils/convertData';
import { SPModal } from '../SPModal';

export type ErrorsUpdateForm = {
  created_at: string;
  owner: string;
  owner_name_kanji: string;
  table_name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

type SPModalEditFormRef = {
  open: (
    title: string,
    errorsUpdateForm: ErrorsUpdateForm[],
    imageContext: ImageContextType
  ) => void;
  close: () => void;
};

export const spModalEditFormRef = createRef<SPModalEditFormRef>();

export const SPModalEditForm: FC = () => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>('');
  const [imageContext, setImageContext] = useState<ImageContextType>();
  const [errorsUpdateForm, setErrorsUpdateForm] = useState<ErrorsUpdateForm[]>(
    []
  );
  const [open, setOpen] = useState<boolean>(false);
  const dynamicOptions = useSelector(adminDynamicOptionsSelectors);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);

  const { updateFormOverride } = useUpdateForm();

  useImperativeHandle(spModalEditFormRef, () => ({
    open(title, errorsUpdateForm, imageContext) {
      setTitle(title);
      setErrorsUpdateForm(errorsUpdateForm);
      setImageContext(imageContext);
      setOpen(true);
    },
    close() {
      setOpen(false);
      setTitle('');
      setImageContext(undefined);
      setErrorsUpdateForm([]);
    },
  }));

  const onGetLastistData = () => {
    dispatch(spGetSubmittedPreliminary());
    window.open(routeNames.Top.path, '_self');
    setOpen(false);
  };

  const onOverUpdate = () => {
    if (!!imageContext)
      updateFormOverride(
        { imageContext: imageContext },
        setIsDisabledButton,
        setOpenImageModal
      );
    // window.open(routeNames.Top.path, '_self');
    setOpen(false);
  };

  const getLabel = (tableName: string, keyField: string) => {
    const stepObject = FIELD_VALIDATE_SP.find((object) =>
      object.fields.find(
        (field) => field.source === tableName && keyField === field.key
      )
    );
    const valueObject = stepObject?.fields.find(
      (field) => field.key === keyField
    );
    return valueObject;
  };

  return (
    <SPModal
      open={open}
      onClose={() => setOpen(false)}
      disableBackDropClick
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title={title}
      footer={
        <Stack textAlign="center" spacing={6}>
          {!!errorsUpdateForm &&
            errorsUpdateForm.map((error, index) => (
              <Stack spacing={1} key={index}>
                <Typography variant="sp_modal_note" color="b_333">
                  Create at: {error.created_at}
                </Typography>
                <Typography variant="sp_modal_note" color="b_333">
                  Owner: {error.owner}
                </Typography>
                <Typography variant="sp_modal_note" color="b_333">
                  Owner Name Kanji: {error.owner_name_kanji}
                </Typography>
                <Typography variant="sp_modal_note" color="b_333">
                  {
                    FIELD_VALIDATE_SP.find((object) =>
                      object.fields.find(
                        (field) =>
                          field.source === error.table_name &&
                          Object.getOwnPropertyNames(error.value).find(
                            (value) => value === field.key
                          )
                      )
                    )?.label
                  }
                </Typography>
                {Object.getOwnPropertyNames(error.value).map(
                  (key, index1) =>
                    !!getLabel(error.table_name, key) && (
                      <Typography
                        variant="sp_modal_note"
                        color="b_333"
                        key={index1}
                      >
                        {getLabel(error.table_name, key)?.name}:
                        {
                          convertData(
                            key,
                            error.value[`${key}`],
                            dynamicOptions
                          ).formattedValue
                        }
                      </Typography>
                    )
                )}
              </Stack>
            ))}

          <Stack direction="row" spacing={4}>
            <SPButton
              sx={{
                width: '100%',
                bgcolor: 'sp_primary_100_main',
                px: 10,
              }}
              endIcon={<Icons.ArrowForward stroke="white" />}
              onClick={onGetLastistData}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="main_white"
                whiteSpace="nowrap"
              >
                Refresh
              </Typography>
            </SPButton>
            <SPButton
              sx={{
                width: '100%',
                bgcolor: 'sp_primary_100_main',
                px: 10,
              }}
              endIcon={<Icons.ArrowForward stroke="white" />}
              onClick={onOverUpdate}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="main_white"
                whiteSpace="nowrap"
              >
                Over Update
              </Typography>
            </SPButton>
          </Stack>
        </Stack>
      }
    />
  );
};
