import { FC, useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { SPStepLayout } from 'containers';
import { useSelector } from 'react-redux';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { SPFormData as FormikData } from 'types';
import { useAppDispatch, useCheckPreliminaryStatus } from 'hooks';
import { ImageContext } from 'context';
import { routeNames } from 'navigations/routes';
import { useNavigate } from 'react-router-dom';
import { SPImage } from 'components';
import { supportDvh } from 'utils';
import { getSPFormData } from '../SPStepNine/thunk';
import {
  SPLoanApplyDateInfo,
  SPStepEightInfo,
  SPStepFiveIncomeTotalizerInfo,
  SPStepFiveInfo,
  SPStepFourIncomeTotalizerInfo,
  SPStepFourInfo,
  SPStepOneInfo,
  SPStepSevenIncomeTotalizerInfo,
  SPStepSevenInfo,
  SPStepSixInfo,
  SPStepThreeInfo,
  SPStepTwoInfo,
} from '../SPStepNine/components';
import { SPStepFourCollateralProviderInfo } from '../SPStepNine/components/SPStepFourCollateralProviderInfo';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
} from '../SPStepOne/selectors';
import { spStepTwoSelector } from '../SPStepTwo/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { spStepFiveSelector } from '../SPStepFive/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepEightSelector } from '../SPStepEight/selectors';

const SPConfirmApplicationDetailPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mainData, setMainData] = useState<FormikData[]>([]);
  // const [incomeTotalData, setIncomeTotalData] = useState<FormikData[]>([]);
  const { application_number } = useSelector(spStepTenSelector);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepSevenIncomeTotalValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepEightValues = useSelector(spStepEightSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);

  useCheckPreliminaryStatus();

  const {
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    repaymentScheduleImage,
    residenceFile,
    residenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    businessCard,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    propertyInformationFile,
    insuranceFile2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    setResidenceFile,
    setResidenceFileBackImage,
    setPropertyInformationFile,
    setRepaymentScheduleImage,
    setInsuranceFile,
    setInsuranceFileBackImage,
    setInsuranceFile2,
    setFinancialStatement1File,
    setFinancialStatement1File2,
    setFinancialStatement2File,
    setFinancialStatement2File2,
    setFinancialStatement3File,
    setFinancialStatement3File2,
    setFirstWithholdingSlipFile,
    setFirstWithholdingSlipFile2,
    setSecondWithholdingSlipFile,
    setSecondWithholdingSlipFile2,
    setEmploymentAgreementFile,
    setEmploymentAgreementFile2,
    setBusinessTaxReturn1File,
    setBusinessTaxReturn1File2,
    setBusinessTaxReturn2File,
    setBusinessTaxReturn2File2,
    setBusinessTaxReturn3File,
    setBusinessTaxReturn3File2,
    setFirstIncomeFile,
    setFirstIncomeFile2,
    setSecondIncomeFile,
    setSecondIncomeFile2,
    setThirdIncomeFile,
    setThirdIncomeFile2,
    setDriverLicenseFrontImage,
    setDriverLicenseFrontImage2,
    setDriverLicenseBackImage,
    setDriverLicenseBackImage2,
    setCardNumberFrontImage,
    setCardNumberFrontImage2,
    setCardNumberBackImage,
    setCardNumberBackImage2,
    setResidentRegisterFrontImage,
    setResidentRegisterFrontImage2,
    setResidentRegisterBackImage,
    setResidentRegisterBackImage2,
    setOtherDocumentFile2,
    setOtherDocumentFile,
    setResidenceIncomeFile,
    setResidenceIncomeFileBackImage,
    setBusinessCard,
  } = useContext(ImageContext);

  useEffect(() => {
    const getData = async () => {
      const result = await dispatch(getSPFormData({}));
      if (getSPFormData.fulfilled.match(result)) {
        setMainData(result.payload.data.main);
        // setIncomeTotalData(result.payload.data.incomeTotal);
      }
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    // Step 2
    let newResidenceFileImage: (SPImage | File)[] = [];
    if (
      !!residenceFile &&
      residenceFile.length === 0 &&
      !!spStepTwoValues.p_applicant_people.residence_file &&
      !!spStepTwoValues.p_applicant_people.residence_file?.length
    ) {
      spStepTwoValues.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          newResidenceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!residenceFile && !!residenceFile.length) {
      newResidenceFileImage = [...residenceFile];
    } else {
      spStepTwoValues.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          newResidenceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setResidenceFile(newResidenceFileImage);

    let newResidenceFileBackImage: (SPImage | File)[] = [];
    if (
      !!residenceFileBackImage &&
      residenceFileBackImage.length === 0 &&
      !!spStepTwoValues.p_applicant_people.residence_file_back_image &&
      !!spStepTwoValues.p_applicant_people.residence_file_back_image?.length
    ) {
      spStepTwoValues.p_applicant_people.residence_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidenceFileBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!residenceFileBackImage && !!residenceFileBackImage.length) {
      newResidenceFileBackImage = [...residenceFileBackImage];
    } else {
      spStepTwoValues.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          newResidenceFileBackImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setResidenceFileBackImage(newResidenceFileBackImage);

    // Step 4
    let newPropertyInformationFile: (SPImage | File)[] = [];
    if (
      !!propertyInformationFile &&
      propertyInformationFile.length === 0 &&
      !!spStepFourValues.p_application_header.property_information_file &&
      !!spStepFourValues.p_application_header.property_information_file?.length
    ) {
      spStepFourValues.p_application_header.property_information_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newPropertyInformationFile.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!propertyInformationFile && !!propertyInformationFile.length) {
      newPropertyInformationFile = [...propertyInformationFile];
    } else {
      spStepFourValues.p_application_header.property_information_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newPropertyInformationFile.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setPropertyInformationFile(newPropertyInformationFile);

    // Step 5
    let newRepaymentScheduleImage: (SPImage | File)[][] = [];

    spStepFiveValues.p_borrowings_attributes.forEach((borrowing, index) => {
      if (
        !!repaymentScheduleImage[index] &&
        repaymentScheduleImage[index].length === 0
      ) {
        if (
          !!borrowing.repayment_schedule_image &&
          !!borrowing.repayment_schedule_image?.length
        ) {
          newRepaymentScheduleImage.push(
            borrowing.repayment_schedule_image.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        } else {
          newRepaymentScheduleImage.push([]);
        }
      } else if (
        !!repaymentScheduleImage[index] &&
        !!repaymentScheduleImage[index].length
      ) {
        newRepaymentScheduleImage.push(repaymentScheduleImage[index]);
      } else {
        newRepaymentScheduleImage.push(
          borrowing.repayment_schedule_image?.map((file) => ({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          }))
        );
      }
    });
    setRepaymentScheduleImage(newRepaymentScheduleImage);

    // Step 7
    // insurance_file
    let newInsuranceFileImage: (SPImage | File)[] = [];
    if (
      !!insuranceFile &&
      insuranceFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.insurance_file &&
      !!spStepSevenValues.p_applicant_people.insurance_file?.length
    ) {
      spStepSevenValues.p_applicant_people.insurance_file.forEach((file) => {
        if (!file.isDelete) {
          newInsuranceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!insuranceFile && !!insuranceFile.length) {
      newInsuranceFileImage = [...insuranceFile];
    } else {
      spStepSevenValues.p_applicant_people.insurance_file.forEach((file) => {
        if (!file.isDelete) {
          newInsuranceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setInsuranceFile(newInsuranceFileImage);
    // insurance_file_back_image
    let newInsuranceFile1Image: (SPImage | File)[] = [];
    if (
      !!insuranceFileBackImage &&
      insuranceFileBackImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.insurance_file_back_image &&
      !!spStepSevenValues.p_applicant_people.insurance_file_back_image?.length
    ) {
      spStepSevenValues.p_applicant_people.insurance_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFile1Image.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!insuranceFileBackImage && !!insuranceFileBackImage.length) {
      newInsuranceFile1Image = [...insuranceFileBackImage];
    } else {
      spStepSevenValues.p_applicant_people.insurance_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFile1Image.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setInsuranceFileBackImage(newInsuranceFile1Image);
    // financial_statement_1_file:
    let newFinancialStatement1FileImage: (SPImage | File)[] = [];
    if (
      !!financialStatement1File &&
      financialStatement1File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.financial_statement_1_file &&
      !!spStepSevenValues.p_applicant_people.financial_statement_1_file?.length
    ) {
      spStepSevenValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!financialStatement1File && !!financialStatement1File.length) {
      newFinancialStatement1FileImage = [...financialStatement1File];
    } else {
      spStepSevenValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement1File(newFinancialStatement1FileImage);
    // financial_statement_2_file: financialStatement2File
    let newFinancialStatement2FileImage: (SPImage | File)[] = [];
    if (
      !!financialStatement2File &&
      financialStatement2File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.financial_statement_2_file &&
      !!spStepSevenValues.p_applicant_people.financial_statement_2_file?.length
    ) {
      spStepSevenValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!financialStatement2File && !!financialStatement2File.length) {
      newFinancialStatement2FileImage = [...financialStatement2File];
    } else {
      spStepSevenValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement2File(newFinancialStatement2FileImage);
    // financial_statement_3_file
    let newFinancialStatement3FileImage: (SPImage | File)[] = [];
    if (
      !!financialStatement3File &&
      financialStatement3File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.financial_statement_3_file &&
      !!spStepSevenValues.p_applicant_people.financial_statement_3_file?.length
    ) {
      spStepSevenValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!financialStatement3File && !!financialStatement3File.length) {
      newFinancialStatement3FileImage = [...financialStatement3File];
    } else {
      spStepSevenValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement3File(newFinancialStatement3FileImage);
    // first_withholding_slip_file
    let newFirstWithholdingSlipFileImage: (SPImage | File)[] = [];
    if (
      !!firstWithholdingSlipFile &&
      firstWithholdingSlipFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.first_withholding_slip_file &&
      !!spStepSevenValues.p_applicant_people.first_withholding_slip_file?.length
    ) {
      spStepSevenValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!firstWithholdingSlipFile &&
      !!firstWithholdingSlipFile.length
    ) {
      newFirstWithholdingSlipFileImage = [...firstWithholdingSlipFile];
    } else {
      spStepSevenValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFirstWithholdingSlipFile(newFirstWithholdingSlipFileImage);
    // second_withholding_slip_file
    let newSecondWithholdingSlipFileImage: (SPImage | File)[] = [];
    if (
      !!secondWithholdingSlipFile &&
      secondWithholdingSlipFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.second_withholding_slip_file &&
      !!spStepSevenValues.p_applicant_people.second_withholding_slip_file
        ?.length
    ) {
      spStepSevenValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!secondWithholdingSlipFile &&
      !!secondWithholdingSlipFile.length
    ) {
      newSecondWithholdingSlipFileImage = [...secondWithholdingSlipFile];
    } else {
      spStepSevenValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondWithholdingSlipFile(newSecondWithholdingSlipFileImage);
    // other_document_file
    let newOtherDocumentFileImage: (SPImage | File)[] = [];
    if (
      !!otherDocumentFile &&
      otherDocumentFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.other_document_file &&
      !!spStepSevenValues.p_applicant_people.other_document_file?.length
    ) {
      spStepSevenValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!otherDocumentFile && !!otherDocumentFile.length) {
      newOtherDocumentFileImage = [...otherDocumentFile];
    } else {
      spStepSevenValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setOtherDocumentFile(newOtherDocumentFileImage);
    // employment_agreement_file
    let newEmploymentAgreementFileImage: (SPImage | File)[] = [];
    if (
      !!employmentAgreementFile &&
      employmentAgreementFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.employment_agreement_file &&
      !!spStepSevenValues.p_applicant_people.employment_agreement_file?.length
    ) {
      spStepSevenValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!employmentAgreementFile && !!employmentAgreementFile.length) {
      newEmploymentAgreementFileImage = [...employmentAgreementFile];
    } else {
      spStepSevenValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setEmploymentAgreementFile(newEmploymentAgreementFileImage);
    // business_tax_return_1_file
    let newBusinessTaxReturn1FileImage: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn1File &&
      businessTaxReturn1File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_1_file &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_1_file?.length
    ) {
      spStepSevenValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn1File && !!businessTaxReturn1File.length) {
      newBusinessTaxReturn1FileImage = [...businessTaxReturn1File];
    } else {
      spStepSevenValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn1File(newBusinessTaxReturn1FileImage);
    // business_tax_return_2_file
    let newBusinessTaxReturn2FileImage: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn2File &&
      businessTaxReturn2File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_2_file &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_2_file?.length
    ) {
      spStepSevenValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn2File && !!businessTaxReturn2File.length) {
      newBusinessTaxReturn2FileImage = [...businessTaxReturn2File];
    } else {
      spStepSevenValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn2File(newBusinessTaxReturn2FileImage);
    // business_tax_return_3_file
    let newBusinessTaxReturn3FileImage: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn3File &&
      businessTaxReturn3File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_3_file &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_3_file?.length
    ) {
      spStepSevenValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn3File && !!businessTaxReturn3File.length) {
      newBusinessTaxReturn3FileImage = [...businessTaxReturn3File];
    } else {
      spStepSevenValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn3File(newBusinessTaxReturn3FileImage);
    // first_income_file
    let newFirstIncomeFileImage: (SPImage | File)[] = [];
    if (
      !!firstIncomeFile &&
      firstIncomeFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.first_income_file &&
      !!spStepSevenValues.p_applicant_people.first_income_file?.length
    ) {
      spStepSevenValues.p_applicant_people.first_income_file.forEach((file) => {
        if (!file.isDelete) {
          newFirstIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!firstIncomeFile && !!firstIncomeFile.length) {
      newFirstIncomeFileImage = [...firstIncomeFile];
    } else {
      spStepSevenValues.p_applicant_people.first_income_file.forEach((file) => {
        if (!file.isDelete) {
          newFirstIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setFirstIncomeFile(newFirstIncomeFileImage);
    // second_income_file
    let newSecondIncomeFileImage: (SPImage | File)[] = [];
    if (
      !!secondIncomeFile &&
      secondIncomeFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.second_income_file &&
      !!spStepSevenValues.p_applicant_people.second_income_file?.length
    ) {
      spStepSevenValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!secondIncomeFile && !!secondIncomeFile.length) {
      newSecondIncomeFileImage = [...secondIncomeFile];
    } else {
      spStepSevenValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondIncomeFile(newSecondIncomeFileImage);
    // third_income_file
    let newThirdIncomeFileImage: (SPImage | File)[] = [];
    if (
      !!thirdIncomeFile &&
      thirdIncomeFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.third_income_file &&
      !!spStepSevenValues.p_applicant_people.third_income_file?.length
    ) {
      spStepSevenValues.p_applicant_people.third_income_file.forEach((file) => {
        if (!file.isDelete) {
          newThirdIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!thirdIncomeFile && !!thirdIncomeFile.length) {
      newThirdIncomeFileImage = [...thirdIncomeFile];
    } else {
      spStepSevenValues.p_applicant_people.third_income_file.forEach((file) => {
        if (!file.isDelete) {
          newThirdIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setThirdIncomeFile(newThirdIncomeFileImage);
    // driver_license_front_image
    let newDriverLicenseFrontImage: (SPImage | File)[] = [];
    if (
      !!driverLicenseFrontImage &&
      driverLicenseFrontImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.driver_license_front_image &&
      !!spStepSevenValues.p_applicant_people.driver_license_front_image?.length
    ) {
      spStepSevenValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!driverLicenseFrontImage && !!driverLicenseFrontImage.length) {
      newDriverLicenseFrontImage = [...driverLicenseFrontImage];
    } else {
      spStepSevenValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseFrontImage(newDriverLicenseFrontImage);
    // driver_license_back_image
    let newDriverLicenseBackImage: (SPImage | File)[] = [];
    if (
      !!driverLicenseBackImage &&
      driverLicenseBackImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.driver_license_back_image &&
      !!spStepSevenValues.p_applicant_people.driver_license_back_image?.length
    ) {
      spStepSevenValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!driverLicenseBackImage && !!driverLicenseBackImage.length) {
      newDriverLicenseBackImage = [...driverLicenseBackImage];
    } else {
      spStepSevenValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseBackImage(newDriverLicenseBackImage);
    // card_number_front_image: cardNumberFrontImage
    let newCardNumberFrontImage: (SPImage | File)[] = [];
    if (
      !!cardNumberFrontImage &&
      cardNumberFrontImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.card_number_front_image &&
      !!spStepSevenValues.p_applicant_people.card_number_front_image?.length
    ) {
      spStepSevenValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!cardNumberFrontImage && !!cardNumberFrontImage.length) {
      newCardNumberFrontImage = [...cardNumberFrontImage];
    } else {
      spStepSevenValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setCardNumberFrontImage(newCardNumberFrontImage);
    // card_number_back_image
    // let newCardNumberBackImage: (SPImage | File)[] = [];
    // if (
    //   !!cardNumberBackImage &&
    //   cardNumberBackImage.length === 0 &&
    //   !!spStepSevenValues.p_applicant_people.card_number_back_image &&
    //   !!spStepSevenValues.p_applicant_people.card_number_back_image?.length
    // ) {
    //   spStepSevenValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // } else if (!!cardNumberBackImage && !!cardNumberBackImage.length) {
    //   newCardNumberBackImage = [...cardNumberBackImage];
    // } else {
    //   spStepSevenValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // }
    // setCardNumberBackImage(newCardNumberBackImage);
    // resident_register_front_image: residentRegisterFrontImage
    let newResidentRegisterFrontImage: (SPImage | File)[] = [];
    if (
      !!residentRegisterFrontImage &&
      residentRegisterFrontImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.resident_register_front_image &&
      !!spStepSevenValues.p_applicant_people.resident_register_front_image
        ?.length
    ) {
      spStepSevenValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterFrontImage &&
      !!residentRegisterFrontImage.length
    ) {
      newResidentRegisterFrontImage = [...residentRegisterFrontImage];
    } else {
      spStepSevenValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterFrontImage(newResidentRegisterFrontImage);
    // resident_register_back_image
    let newResidentRegisterBackImage: (SPImage | File)[] = [];
    if (
      !!residentRegisterBackImage &&
      residentRegisterBackImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.resident_register_back_image &&
      !!spStepSevenValues.p_applicant_people.resident_register_back_image
        ?.length
    ) {
      spStepSevenValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterBackImage &&
      !!residentRegisterBackImage.length
    ) {
      newResidentRegisterBackImage = [...residentRegisterBackImage];
    } else {
      spStepSevenValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterBackImage(newResidentRegisterBackImage);

    // Step 8
    let newBusinessCardImage: (SPImage | File)[] = [];
    if (
      !!businessCard &&
      businessCard.length === 0 &&
      !!spStepEightValues.p_referral_agencies.business_card &&
      !!spStepEightValues.p_referral_agencies.business_card?.length
    ) {
      spStepEightValues.p_referral_agencies.business_card.forEach((file) => {
        if (!file.isDelete) {
          newBusinessCardImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!businessCard && !!businessCard.length) {
      newBusinessCardImage = [...businessCard];
    } else {
      spStepEightValues.p_referral_agencies.business_card.forEach((file) => {
        if (!file.isDelete) {
          newBusinessCardImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setBusinessCard(newBusinessCardImage);

    if (incomeTotalizer) {
      // Step 4 income
      let newResidenceIncomeFileImage: (SPImage | File)[] = [];
      if (
        !!residenceIncomeFile &&
        residenceIncomeFile.length === 0 &&
        !!spStepTwoIncomeTotalValues.p_applicant_people.residence_file &&
        !!spStepTwoIncomeTotalValues.p_applicant_people.residence_file?.length
      ) {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      } else if (!!residenceIncomeFile && !!residenceIncomeFile.length) {
        newResidenceIncomeFileImage = [...residenceIncomeFile];
      } else {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      }
      setResidenceIncomeFile(newResidenceIncomeFileImage);

      let newResidenceIncomeFileBackImage: (SPImage | File)[] = [];
      if (
        !!residenceIncomeFileBackImage &&
        residenceIncomeFileBackImage.length === 0 &&
        !!spStepTwoIncomeTotalValues.p_applicant_people
          .residence_file_back_image &&
        !!spStepTwoIncomeTotalValues.p_applicant_people
          .residence_file_back_image?.length
      ) {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file_back_image.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileBackImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      } else if (
        !!residenceIncomeFileBackImage &&
        !!residenceIncomeFileBackImage.length
      ) {
        newResidenceIncomeFileBackImage = [...residenceIncomeFileBackImage];
      } else {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file_back_image.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileBackImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      }
      setResidenceIncomeFileBackImage(newResidenceIncomeFileBackImage);
    }
    // Step 7 income
    // insurance_file
    let newInsuranceFileImage2: (SPImage | File)[] = [];
    if (
      !!insuranceFile2 &&
      insuranceFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.insurance_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.insurance_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.insurance_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!insuranceFile2 && !!insuranceFile2.length) {
      newInsuranceFileImage2 = [...insuranceFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.insurance_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setInsuranceFile2(newInsuranceFileImage2);
    // financial_statement_1_file:
    let newFinancialStatement1FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement1File2 &&
      financialStatement1File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_1_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_1_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement1File2 &&
      !!financialStatement1File2.length
    ) {
      newFinancialStatement1FileImage2 = [...financialStatement1File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement1File2(newFinancialStatement1FileImage2);
    // financial_statement_2_file: financialStatement2File
    let newFinancialStatement2FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement2File2 &&
      financialStatement2File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_2_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_2_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement2File2 &&
      !!financialStatement2File2.length
    ) {
      newFinancialStatement2FileImage2 = [...financialStatement2File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement2File2(newFinancialStatement2FileImage2);
    // financial_statement_3_file
    let newFinancialStatement3FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement3File2 &&
      financialStatement3File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_3_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_3_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement3File2 &&
      !!financialStatement3File2.length
    ) {
      newFinancialStatement3FileImage2 = [...financialStatement3File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement3File2(newFinancialStatement3FileImage2);
    // first_withholding_slip_file
    let newFirstWithholdingSlipFileImage2: (SPImage | File)[] = [];
    if (
      !!firstWithholdingSlipFile2 &&
      firstWithholdingSlipFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .first_withholding_slip_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .first_withholding_slip_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!firstWithholdingSlipFile2 &&
      !!firstWithholdingSlipFile2.length
    ) {
      newFirstWithholdingSlipFileImage2 = [...firstWithholdingSlipFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFirstWithholdingSlipFile2(newFirstWithholdingSlipFileImage2);
    // second_withholding_slip_file
    let newSecondWithholdingSlipFileImage2: (SPImage | File)[] = [];
    if (
      !!secondWithholdingSlipFile2 &&
      secondWithholdingSlipFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .second_withholding_slip_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .second_withholding_slip_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!secondWithholdingSlipFile2 &&
      !!secondWithholdingSlipFile2.length
    ) {
      newSecondWithholdingSlipFileImage2 = [...secondWithholdingSlipFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondWithholdingSlipFile2(newSecondWithholdingSlipFileImage2);
    // other_document_file
    let newOtherDocumentFileImage2: (SPImage | File)[] = [];
    if (
      !!otherDocumentFile2 &&
      otherDocumentFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.other_document_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.other_document_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!otherDocumentFile2 && !!otherDocumentFile2.length) {
      newOtherDocumentFileImage2 = [...otherDocumentFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setOtherDocumentFile2(newOtherDocumentFileImage2);
    // employment_agreement_file
    let newEmploymentAgreementFileImage2: (SPImage | File)[] = [];
    if (
      !!employmentAgreementFile2 &&
      employmentAgreementFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .employment_agreement_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .employment_agreement_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!employmentAgreementFile2 &&
      !!employmentAgreementFile2.length
    ) {
      newEmploymentAgreementFileImage2 = [...employmentAgreementFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setEmploymentAgreementFile2(newEmploymentAgreementFileImage2);
    // business_tax_return_1_file
    let newBusinessTaxReturn1FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn1File2 &&
      businessTaxReturn1File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_1_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_1_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn1File2 && !!businessTaxReturn1File2.length) {
      newBusinessTaxReturn1FileImage2 = [...businessTaxReturn1File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn1File2(newBusinessTaxReturn1FileImage2);
    // business_tax_return_2_file
    let newBusinessTaxReturn2FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn2File2 &&
      businessTaxReturn2File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_2_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_2_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn2File2 && !!businessTaxReturn2File2.length) {
      newBusinessTaxReturn2FileImage2 = [...businessTaxReturn2File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn2File2(newBusinessTaxReturn2FileImage2);
    // business_tax_return_3_file
    let newBusinessTaxReturn3FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn3File2 &&
      businessTaxReturn3File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_3_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_3_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn3File2 && !!businessTaxReturn3File2.length) {
      newBusinessTaxReturn3FileImage2 = [...businessTaxReturn3File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn3File2(newBusinessTaxReturn3FileImage2);
    // first_income_file
    let newFirstIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!firstIncomeFile2 &&
      firstIncomeFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.first_income_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.first_income_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.first_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!firstIncomeFile2 && !!firstIncomeFile2.length) {
      newFirstIncomeFileImage2 = [...firstIncomeFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.first_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFirstIncomeFile2(newFirstIncomeFileImage2);
    // second_income_file
    let newSecondIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!secondIncomeFile2 &&
      secondIncomeFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.second_income_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.second_income_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!secondIncomeFile2 && !!secondIncomeFile2.length) {
      newSecondIncomeFileImage2 = [...secondIncomeFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondIncomeFile2(newSecondIncomeFileImage2);
    // third_income_file
    let newThirdIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!thirdIncomeFile2 &&
      thirdIncomeFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.third_income_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.third_income_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.third_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newThirdIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!thirdIncomeFile2 && !!thirdIncomeFile2.length) {
      newThirdIncomeFileImage2 = [...thirdIncomeFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.third_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newThirdIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setThirdIncomeFile2(newThirdIncomeFileImage2);
    // driver_license_front_image
    let newDriverLicenseFrontImage2: (SPImage | File)[] = [];
    if (
      !!driverLicenseFrontImage2 &&
      driverLicenseFrontImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_front_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_front_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!driverLicenseFrontImage2 &&
      !!driverLicenseFrontImage2.length
    ) {
      newDriverLicenseFrontImage2 = [...driverLicenseFrontImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseFrontImage2(newDriverLicenseFrontImage2);
    // driver_license_back_image
    let newDriverLicenseBackImage2: (SPImage | File)[] = [];
    if (
      !!driverLicenseBackImage2 &&
      driverLicenseBackImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_back_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_back_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!driverLicenseBackImage2 && !!driverLicenseBackImage2.length) {
      newDriverLicenseBackImage2 = [...driverLicenseBackImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseBackImage2(newDriverLicenseBackImage2);
    // card_number_front_image: cardNumberFrontImage
    let newCardNumberFrontImage2: (SPImage | File)[] = [];
    if (
      !!cardNumberFrontImage2 &&
      cardNumberFrontImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .card_number_front_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.card_number_front_image
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!cardNumberFrontImage2 && !!cardNumberFrontImage2.length) {
      newCardNumberFrontImage2 = [...cardNumberFrontImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setCardNumberFrontImage2(newCardNumberFrontImage2);
    // card_number_back_image
    // let newCardNumberBackImage2: (SPImage | File)[] = [];
    // if (
    //   !!cardNumberBackImage2 &&
    //   cardNumberBackImage2.length === 0 &&
    //   !!spStepSevenIncomeTotalValues.p_applicant_people
    //     .card_number_back_image &&
    //   !!spStepSevenIncomeTotalValues.p_applicant_people.card_number_back_image
    //     ?.length
    // ) {
    //   spStepSevenIncomeTotalValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage2.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // } else if (!!cardNumberBackImage2 && !!cardNumberBackImage2.length) {
    //   newCardNumberBackImage2 = [...cardNumberBackImage2];
    // } else {
    //   spStepSevenIncomeTotalValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage2.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // }
    // setCardNumberBackImage2(newCardNumberBackImage2);
    // resident_register_front_image
    let newResidentRegisterFrontImage2: (SPImage | File)[] = [];
    if (
      !!residentRegisterFrontImage2 &&
      residentRegisterFrontImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_front_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_front_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterFrontImage2 &&
      !!residentRegisterFrontImage2.length
    ) {
      newResidentRegisterFrontImage2 = [...residentRegisterFrontImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterFrontImage2(newResidentRegisterFrontImage2);
    // resident_register_back_image
    let newResidentRegisterBackImage2: (SPImage | File)[] = [];
    if (
      !!residentRegisterBackImage2 &&
      residentRegisterBackImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_back_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_back_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterBackImage2 &&
      !!residentRegisterBackImage2.length
    ) {
      newResidentRegisterBackImage2 = [...residentRegisterBackImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterBackImage2(newResidentRegisterBackImage2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SPStepLayout
      hasStepBar={false}
      hasModalSaveDraft={false}
      footer={{
        left: {
          onClick: () => {
            navigate(routeNames.Top.path);
          },
        },
        right: false,
      }}
      sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
    >
      <Stack
        sx={{
          width: '100%',
          background: 'linear-gradient(180deg, #F9F1F7 0%, #B8D3F3 100%)',
        }}
        alignItems="center"
      >
        <Stack sx={{ py: 9 }} justifyContent="center" alignItems="center">
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
            sx={{
              whiteSpace: 'break-spaces',
              textAlign: 'center',
            }}
          >{`住宅ローン仮審査\n申込内容`}</Typography>
        </Stack>

        <Stack
          sx={{
            borderRadius: 2,
            border: (theme) =>
              `1px solid ${theme?.palette?.sp_primary_100_main}`,
            mb: 8,
          }}
        >
          <Stack
            sx={{
              borderRadius: '8px 8px 0 0',
              justifyContent: 'center',
              pl: 3,
              height: 30,
              bgcolor: 'sp_primary_40',
            }}
          >
            <Typography variant="SP_form_item_label" color="b_333">
              受付番号
            </Typography>
          </Stack>
          <Stack
            sx={{
              borderRadius: '0 0 8px 8px',
              bgcolor: 'main_white',
              py: 3,
              px: 3,
            }}
          >
            <Typography variant="sp_change_password_title" color="b_333">
              {application_number}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={8} mb={10}>
          <SPLoanApplyDateInfo />
          <SPStepOneInfo showEditButton={false} />
          <SPStepTwoInfo showEditButton={false} />

          <SPStepThreeInfo showEditButton={false} />
          {incomeTotalizer && (
            <SPStepFourIncomeTotalizerInfo showEditButton={false} />
          )}
          {incomeTotalizer && (
            <SPStepFiveIncomeTotalizerInfo showEditButton={false} />
          )}
          {hasJoinGuarantor && (
            <SPStepFourCollateralProviderInfo showEditButton={false} />
          )}
          <SPStepFourInfo showEditButton={false} />
          <SPStepFiveInfo showEditButton={false} />
          <SPStepSixInfo showEditButton={false} />
          <SPStepSevenInfo showEditButton={false} />
          {incomeTotalizer && (
            <SPStepSevenIncomeTotalizerInfo showEditButton={false} />
          )}

          <SPStepEightInfo showEditButton={false} />
        </Stack>
      </Stack>
    </SPStepLayout>
  );
};

export default SPConfirmApplicationDetailPage;
