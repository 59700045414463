import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';

export default function mockAdapter(instance: AxiosInstance) {
  const mock = new MockAdapter(instance, { delayResponse: 1500 });

  mock.onPost('/signup').reply(200, {
    success: true,
    data: {
      access_token:
        'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2Iiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjUyNzYzMTY1LCJleHAiOjE2NTI3NjQwNjUsImp0aSI6IjczMDI3YTc2LTQ4N2QtNDEwNy1iNTNlLTk0MTQ3MWVjYjFjZSJ9.dRmNlvEFtnIsjai0FbpQwyyRbEv6jtWu-i6Gqu_oOmQ',
    },
  });

  mock.onPost('/login').reply(200, {
    success: true,
    data: {
      access_token:
        'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2Iiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjUyNzYzMTY1LCJleHAiOjE2NTI3NjQwNjUsImp0aSI6IjczMDI3YTc2LTQ4N2QtNDEwNy1iNTNlLTk0MTQ3MWVjYjFjZSJ9.dRmNlvEFtnIsjai0FbpQwyyRbEv6jtWu-i6Gqu_oOmQ',
    },
  });

  mock.onGet('/savedApplication').reply(200, {
    success: true,
    data: [
      {
        id: 1,
        date_create: '2022年5月24日15時36分',
        purpose_of_borrowing: '新築',
      },
      {
        id: 2,
        date_create: '2022年5月25日15時36分',
        purpose_of_borrowing: '新築',
      },
      {
        id: 3,
        date_create: '2022年5月26日15時36分',
        purpose_of_borrowing: '新築',
      },
    ],
  });

  mock.onGet('/branches').reply(200, {
    success: true,
    data: [
      { value: '横浜支店1', label: '横浜支店' },
      { value: '横浜支店2', label: '横浜支店' },
      { value: '横浜支店3', label: '横浜支店' },
      { value: '横浜支店4', label: '横浜支店' },
      { value: '横浜支店5', label: '横浜支店' },
    ],
  });
  mock.onGet('/affiliations').reply(200, {
    success: true,
    data: [
      { value: '第一部1', label: '第一部' },
      { value: '第一部2', label: '第一部' },
      { value: '第一部3', label: '第一部' },
      { value: '第一部4', label: '第一部' },
      { value: '第一部5', label: '第一部' },
    ],
  });

  mock.onGet('/masterBanks').reply(200, {
    success: true,
    data: [
      {
        value: '1',
        name: '日本住宅ローン',
        note: '※フラット35、フラット35S、フラット50をご利用の方をこちらから詳細をご確認ください。',
      },
      { value: '2', name: '住信SBIネット銀行' },
      { value: '3', name: '千葉銀行' },
      { value: '4', name: '兵庫XX銀行' },
      { value: '5', name: '釧路XX銀行' },
      { value: '6', name: 'XX銀行' },
      { value: '7', name: 'XX銀行' },
      { value: '8', name: 'auじぶん銀行' },
    ],
  });

  mock.onGet('/managerCurrentPreliminariesItems').reply(200, {
    success: true,
    data: [
      {
        id: 1,
        application_number: 'J192588',
        bank_name: '住信SB Iネット銀行',
        applicant_name: '山田 太郎',
        created_at: '22/07/23 16時',
        loan_desired_borrowing_date: '22/07/24',
        temporary_desired_loan_amount: '4,500（万円）',
        status_result: '審査中',
        provisional_result: '条件付承認',
        store_name_kanji: '秋葉原展示場',
        sale_person_name_kanji: '銀行 太郎',
        manager_name_kanji: 'test',
        list_managers: [],
      },
      {
        id: 2,
        application_number: 'J192588',
        bank_name: '住信SB Iネット銀行',
        applicant_name: '山田 太郎',
        created_at: '22/07/23 16時',
        loan_desired_borrowing_date: '22/07/24',
        temporary_desired_loan_amount: '4,500（万円）',
        status_result: '審査中',
        provisional_result: '条件付承認',
        store_name_kanji: '秋葉原展示場',
        sale_person_name_kanji: '銀行 太郎',
        manager_name_kanji: 'test',
        list_managers: [],
      },
      {
        id: 3,
        application_number: 'J192588',
        bank_name: '住信SB Iネット銀行',
        applicant_name: '山田 太郎',
        created_at: '22/07/23 16時',
        loan_desired_borrowing_date: '22/07/24',
        temporary_desired_loan_amount: '4,500（万円）',
        status_result: '審査中',
        provisional_result: '条件付承認',
        store_name_kanji: '秋葉原展示場',
        sale_person_name_kanji: '銀行 太郎',
        manager_name_kanji: 'test',
        list_managers: [],
      },
      {
        id: 4,
        application_number: 'J192588',
        bank_name: '住信SB Iネット銀行',
        applicant_name: '山田 太郎',
        created_at: '22/07/23 16時',
        loan_desired_borrowing_date: '22/07/24',
        temporary_desired_loan_amount: '4,500（万円）',
        status_result: '審査中',
        provisional_result: '条件付承認',
        store_name_kanji: '秋葉原展示場',
        sale_person_name_kanji: '銀行 太郎',
        manager_name_kanji: 'test',
        list_managers: [],
      },
      {
        id: 5,
        application_number: 'J192588',
        bank_name: '住信SB Iネット銀行',
        applicant_name: '山田 太郎',
        created_at: '22/07/23 16時',
        loan_desired_borrowing_date: '22/07/24',
        temporary_desired_loan_amount: '4,500（万円）',
        status_result: '審査中',
        provisional_result: '条件付承認',
        store_name_kanji: '秋葉原展示場',
        sale_person_name_kanji: '銀行 太郎',
        manager_name_kanji: 'test',
        list_managers: [],
      },
      {
        id: 6,
        application_number: 'J192588',
        bank_name: '住信SB Iネット銀行',
        applicant_name: '山田 太郎',
        created_at: '22/07/23 16時',
        loan_desired_borrowing_date: '22/07/24',
        temporary_desired_loan_amount: '4,500（万円）',
        status_result: '審査中',
        provisional_result: '条件付承認',
        store_name_kanji: '秋葉原展示場',
        sale_person_name_kanji: '銀行 太郎',
        manager_name_kanji: 'test',
        list_managers: [],
      },
    ],
  });

  mock.onGet('/city').reply(200, {
    success: true,
    data: [
      {
        id: 1,
        name: '東京都',
      },
    ],
  });

  mock.onGet('/workplace').reply(200, {
    success: true,
    data: [
      {
        id: 1,
        name: '株式会社MILIZE',
        address: '東京都港区芝浦4-12-38',
        building: 'キャナルゲート芝浦ビル6階',
      },
      {
        id: 2,
        name: 'MILIZES株式会社',
        address: '東京都中央区日本橋3-5-1',
        building: '日本橋茅場町ビル2階',
      },
    ],
  });

  mock.onGet('/propertyInfo').reply(200, {
    success: true,
    data: {
      collateral_address: '東京都港区芝浦',
      mansion_name_kanji: '実装',
      collateral_land_area: '100',
      occupied_area: '100',
      intended_construction_date: '1998/11',
    },
  });

  mock.onGet('/applicationData').reply(200, {
    success: true,
    data: [
      {
        id: 1,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'application',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: 'canBe',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-09-19',
      },
      {
        id: 2,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'applicationComplete',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: 'canNot',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-07-10',
      },
      {
        id: 3,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'contactAlready',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: '',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-08-29',
        hasError: false,
      },
      {
        id: 4,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'examinationComplete',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: '',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-07-19',
        hasError: true,
      },
      {
        id: 5,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'examinationComplete',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: '',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-07-19',
        hasError: false,
      },
      {
        id: 6,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'contactAlready',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: '',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-07-19',
        hasError: true,
      },
      {
        id: 7,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'contactAlready',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: '',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-07-19',
        hasError: true,
      },
      {
        id: 8,
        proposalNumber: '0124857',
        application: '営業',
        bankName: 'auじぶん銀行',
        applicant: '山田 花子',
        situation: 'contactAlready',
        loanAmount: '4,500',
        interestRate: '0.65',
        condition: '',
        bankClerk: '銀行 太郎',
        salesTaro: '営業 太郎',
        applicationDateTime: '2022-07-06 10:23:25',
        examinationDate: '2022-07-19',
        hasError: true,
      },
    ],
  });

  mock.onGet('admin/businesses').reply(200, {
    success: true,
    data: [
      {
        id: 1,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 2,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 3,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 4,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 5,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 6,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 7,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 8,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 9,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 10,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 11,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 12,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 13,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 14,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 15,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 16,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 17,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 18,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 19,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 20,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 21,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 22,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 23,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
      {
        id: 24,
        branch: '横浜支店',
        affiliation: '第一部',
        fullName: '営業 次郎',
      },
    ],
  });

  mock.onDelete('admin/businesses').reply(200, {
    success: true,
    data: {
      id: 1,
      branch: '横浜支店',
      affiliation: '第一部',
      fullName: '営業 次郎',
    },
  });

  mock.onGet('/applicantData').reply(200, {
    success: true,
    data: [
      {
        id: 1,
        name: 'name_1',
        proposalNumber: '0124857',
        bankName: 'auじぶん銀行',
        applicationDate: '2022/07/16',
        quick: false,
        endDate: '2022/07/29 14:23',
        loanAmount: 4500,
        interestRate: '0.65',
        condition: 'canBe',
        bankClerk: '自分 太郎',
        userName: '未来 三郎',
        startDate: '2022/07/24',
        situation: 'examinationStart',
      },
      {
        id: 2,
        name: 'name_2',
        proposalNumber: '0124857',
        bankName: '日本住宅ローン',
        applicationDate: '2022/07/16',
        quick: false,
        endDate: '2022/07/29 14:23',
        loanAmount: 4500,
        interestRate: '0.65',
        condition: 'canBe',
        bankClerk: '自分 太郎',
        userName: '未来 三郎',
        startDate: '2022/07/27',
        situation: 'examinationStart',
      },
      {
        id: 3,
        name: 'name_3',
        proposalNumber: '5684914',
        bankName: 'みらいバンク',
        applicationDate: '2022/05/22',
        quick: true,
        endDate: '2022/05/26 10:15',
        loanAmount: 4500,
        interestRate: '0.65',
        condition: 'canBe',
        bankClerk: '自分 太郎',
        userName: '個人申込',
        startDate: '2022/07/24',
        situation: 'contactAlready',
      },
    ],
  });

  mock.onPost('/saveDraft').reply(200, {
    success: true,
    data: JSON.stringify({
      step_1: {
        loan_type: '1',
        purpose: 'propertyPurchase/newConstruction',
        howToUseLoan: 'land/building',
        loan_desired_borrowing_date: '2024/01/05',
        loan_term: '1',
        real_estate_sale_price: '1000',
        additional_cost: '500',
        desired_loan_amount: 1400,
        saving_amount: '100',
        total_funding: '0',
        repayment_method: '0',
        combineIncome: '',
        incomeRelationship: '',
        halfyear_bonus: '100',
        pair_loan_term: '1',
        desired_pair_loan_amount: '1000',
        pair_loan_halfyear_bonus: '100',
        pair_loan_repayment_method: '1',
        master_bank_ids: ['1', '2'],
        quick_preliminary_review_flg: '1',
        memo: '',
        birthday: '1996/05/10',
      },
      step_2: {
        has_real_estate_companies_infomation: '1',
        real_estate_companies_id: '123456789098765',
        address: '東京都港区芝浦4-12-38',
        otherAddress: 'abc',
        intended_construction_date: '1998/11',
        collateral_land_area: '100',
        occupied_area: '100',
        applicable_land: ['1'],
        store_combine_home: true,
        other_applicable_item: ['1'],
      },
      step_3: {
        has_join_guarantor: '1',
        join_guarantor_count: '1',
        has_driver_license: '0',
        last_name_kanji: '実装',
        first_name_kanji: '実装',
        last_name_kana: 'ﾊﾟｿｺﾝ',
        first_name_kana: 'ﾊﾟｿｺﾝ',
        birthday: '1996/05/10',
        sex: '1',
        relationship_to_applicant: '1',
        mobile_phone_number: '07012345678',
        home_phone_number: '',
        current_residence: '',
        intended_housemate_number: '01',
        postal_code: '1000000',
        current_residence_kanji: '東京都千代田区',
        mansion_name_kanji: 'abc',
        representative_email: 'congtunggv@gmail.com',
        optional_email: 'congtunggv@gmail.com',
        has_borrowing: false,
        borrowing: [
          {
            lender: 'abc',
            current_balance_amount: '100000',
          },
        ],
        occupation: '4',
        officeName: 'milize',
        office_postal_code: '1000000',
        workAddress: '東京都千代田区',
        workApartment: 'nnn',
        office_phone_number: '0101234567',
        last_year_income: '1000',
        number_of_employee: '10',
        industry: '5',
        department: 'abc',
        position: '',
        employment_started_date: '2010/09',
        identify_person_number: '',
        loan_type: '1',
      },
      step_other_person: {
        last_name_kanji: '実装',
        first_name_kanji: '実装',
        last_name_kana: 'ﾊﾟｿｺﾝ',
        first_name_kana: 'ﾊﾟｿｺﾝ',
        birthday: '1997/04/08',
        sex: '0',
        relationship_to_applicant: '2',
        mobile_phone_number: '07012345678',
        home_phone_number: '',
        current_residence: '',
        intended_housemate_number: '02',
        postal_code: '1000000',
        current_residence_kanji: '東京都千代田区',
        mansion_name_kanji: 'ccc',
        occupation: '1',
        last_year_income: '1000',
        officeName: 'milize',
        office_postal_code: '1000000',
        workAddress: '東京都千代田区',
        workApartment: 'iii',
        office_phone_number: '0101234577',
        number_of_employee: '10',
        industry: '7',
        department: 'mmm',
        position: '',
        employment_started_date: '2016/09',
      },
      step_join_guarantor: {
        ids: ['1'],
        entities: {
          '1': {
            id: '1',
            last_name_kanji: '実装',
            first_name_kanji: '実装',
            last_name_kana: 'ﾊﾟｿｺﾝ',
            first_name_kana: 'ﾊﾟｿｺﾝ',
            relationship_to_applicant: '1',
            mobile_phone_number: '07044556677',
            home_phone_number: '',
            current_residence_kanji: 'abc',
            mansion_name_kanji: 'nvm',
            birthday: '2000/01/08',
            postal_code: '1000000',
          },
        },
      },
      current_step_path: '/step-join-guarantor?id=1',
    }),
  });

  mock.onGet('/saveDraft').reply(200, {
    success: true,
    data: JSON.stringify({
      step_1: {
        loan_type: '1',
        purpose: 'propertyPurchase/newConstruction',
        howToUseLoan: 'land/building',
        loan_desired_borrowing_date: '2024/01/05',
        loan_term: '1',
        real_estate_sale_price: '1000',
        additional_cost: '500',
        desired_loan_amount: 1400,
        saving_amount: '100',
        total_funding: '0',
        repayment_method: '0',
        combineIncome: '',
        incomeRelationship: '',
        halfyear_bonus: '100',
        pair_loan_term: '1',
        desired_pair_loan_amount: '1000',
        pair_loan_halfyear_bonus: '100',
        pair_loan_repayment_method: '1',
        master_bank_ids: ['1', '2'],
        quick_preliminary_review_flg: '1',
        memo: '',
        birthday: '1996/05/10',
      },
      step_2: {
        has_real_estate_companies_infomation: '1',
        real_estate_companies_id: '123456789098765',
        address: '東京都港区芝浦4-12-38',
        otherAddress: 'abc',
        intended_construction_date: '1998/11',
        collateral_land_area: '100',
        occupied_area: '100',
        applicable_land: ['1'],
        store_combine_home: true,
        other_applicable_item: ['1'],
      },
      step_3: {
        has_join_guarantor: '1',
        join_guarantor_count: '1',
        has_driver_license: '0',
        last_name_kanji: '実装',
        first_name_kanji: '実装',
        last_name_kana: 'ﾊﾟｿｺﾝ',
        first_name_kana: 'ﾊﾟｿｺﾝ',
        birthday: '1996/05/10',
        sex: '1',
        relationship_to_applicant: '1',
        mobile_phone_number: '07012345678',
        home_phone_number: '',
        current_residence: '',
        intended_housemate_number: '01',
        postal_code: '1000000',
        current_residence_kanji: '東京都千代田区',
        mansion_name_kanji: 'abc',
        representative_email: 'congtunggv@gmail.com',
        optional_email: 'congtunggv@gmail.com',
        has_borrowing: false,
        borrowing: [
          {
            lender: 'abc',
            current_balance_amount: '100000',
          },
        ],
        occupation: '4',
        officeName: 'milize',
        office_postal_code: '1000000',
        workAddress: '東京都千代田区',
        workApartment: 'nnn',
        office_phone_number: '0101234567',
        last_year_income: '1000',
        number_of_employee: '10',
        industry: '5',
        department: 'abc',
        position: '',
        employment_started_date: '2010/09',
        identify_person_number: '',
        loan_type: '1',
      },
      step_other_person: {
        last_name_kanji: '実装',
        first_name_kanji: '実装',
        last_name_kana: 'ﾊﾟｿｺﾝ',
        first_name_kana: 'ﾊﾟｿｺﾝ',
        birthday: '1997/04/08',
        sex: '0',
        relationship_to_applicant: '2',
        mobile_phone_number: '07012345678',
        home_phone_number: '',
        current_residence: '',
        intended_housemate_number: '02',
        postal_code: '1000000',
        current_residence_kanji: '東京都千代田区',
        mansion_name_kanji: 'ccc',
        occupation: '1',
        last_year_income: '1000',
        officeName: 'milize',
        office_postal_code: '1000000',
        workAddress: '東京都千代田区',
        workApartment: 'iii',
        office_phone_number: '0101234577',
        number_of_employee: '10',
        industry: '7',
        department: 'mmm',
        position: '',
        employment_started_date: '2016/09',
      },
      step_join_guarantor: {
        ids: ['1'],
        entities: {
          '1': {
            id: '1',
            last_name_kanji: '実装',
            first_name_kanji: '実装',
            last_name_kana: 'ﾊﾟｿｺﾝ',
            first_name_kana: 'ﾊﾟｿｺﾝ',
            relationship_to_applicant: '1',
            mobile_phone_number: '07044556677',
            home_phone_number: '',
            current_residence_kanji: 'abc',
            mansion_name_kanji: 'nvm',
            birthday: '2000/01/08',
            postal_code: '1000000',
          },
        },
      },
      current_step_path: '/step-join-guarantor?id=1',
    }),
  });
  mock.onGet('/manager').reply(200, {
    success: true,
    data: [
      {
        manager_id: 1,
        manager_type: 'SSalePerson',
        name_kanji: 'ABC...',
      },
      {
        manager_id: 2,
        manager_type: 'Manager',
        name_kanji: 'DEF...',
      },
    ],
  });

  mock.onGet('/problem_contact').reply(200, {
    success: true,
    data: [
      { field_name_ja: 'AAA', value: '1' },
      { field_name_ja: 'BBB', value: '12' },
      { field_name_ja: 'CCC', value: '13' },
    ],
  });
}
