import { Close as CloseIcon } from '@mui/icons-material';
import { Modal as MuiModal, Stack, Typography } from '@mui/material';
import { Button } from 'components';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';

type ModalProps = PropsWithChildren<{
  open: boolean;
  fileNames: string[];
  onClose: () => void;
  // confirm delete
  onConfirm: () => void;
  onReset: Dispatch<SetStateAction<number>>;
}>;

export const ModalDeleteConfirm: FC<ModalProps> = ({
  open,
  fileNames,
  onClose,
  onConfirm,
  onReset,
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const textDisplay = !confirmDelete
    ? `を削除します。よろしいですか？`
    : `を削除しました。`;

  const handleClose = () => {
    setConfirmDelete(false);
    onClose();
  };

  const handleConfirmClose = () => {
    setConfirmDelete(false);
    onReset(Math.random() * 10000000);
    onClose();
  };

  return (
    <MuiModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={confirmDelete ? handleConfirmClose : handleClose}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '27%',
          minWidth: 320,
          minHeight: 140,
          backgroundColor: 'main_white',
          '@media (orientation: landscape)': {
            minWidth: 'auto',
            maxHeight: '75vh',
          },
          borderRadius: 2,
          paddingBottom: 8,
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <CloseIcon
            onClick={confirmDelete ? handleConfirmClose : handleClose}
            fontSize="medium"
            sx={{
              color: 'b_333',
              cursor: 'pointer',
              pb: 2,
              pt: 2,
              pr: 2,
              width: 20,
              height: 20,
            }}
          />
        </Stack>

        <Stack alignItems="left" px={8} spacing={2}>
          <Typography
            variant="text_note"
            color="b_333"
            sx={{ px: 3, fontWeight: 600 }}
          >
            「
            {fileNames.map((image: string, index) => {
              return index === fileNames.length - 1 ? image : `${image}、`;
            })}
            」
          </Typography>
          <Typography variant="text_note" color="b_333" sx={{ px: 3 }}>
            {textDisplay}
          </Typography>
        </Stack>
        {confirmDelete ? (
          <Stack
            justifyContent="center"
            direction="row"
            spacing={3}
            sx={{
              pt: 10,
            }}
          >
            <Button
              sx={{
                bgcolor: 'white',
                boxShadow: 'none',
                width: '270px',
                height: '36px',
                borderRadius: '2px',
                minHeight: '36px',
                border: '1px solid #6B70F0',
              }}
              onClick={() => handleConfirmClose()}
            >
              <Typography
                variant="button01"
                color="sp_primary_100_main"
                sx={{ size: 14 }}
              >
                閉じる
              </Typography>
            </Button>
          </Stack>
        ) : (
          <Stack
            justifyContent="center"
            direction="row"
            spacing={10}
            sx={{
              pt: 10,
            }}
          >
            <Button
              sx={{
                bgcolor: 'white',
                boxShadow: 'none',
                width: '200px',
                height: '36px',
                borderRadius: '2px',
                minHeight: '36px',
                border: '1px solid #6B70F0',
              }}
              onClick={() => handleClose()}
            >
              <Typography variant="button01" color="sp_primary_100_main">
                いいえ
              </Typography>
            </Button>
            <Button
              sx={{
                bgcolor: 'sh_red',
                boxShadow: 'none',
                width: '100px',
                height: '36px',
                borderRadius: '2px',
                minHeight: '36px',
              }}
              onClick={() => {
                onConfirm();
                setConfirmDelete(true);
              }}
            >
              <Typography variant="button01" color="main_white">
                はい
              </Typography>
            </Button>
          </Stack>
        )}
      </Stack>
    </MuiModal>
  );
};
