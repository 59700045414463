import {
  Avatar,
  Grid,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { spMilize, userAvatar } from 'assets';
import { Button, Icons, SPModal } from 'components';
import { SenderType } from 'constant';
import { useAppDispatch } from 'hooks';
import { routeNames } from 'navigations/routes';
import { deleteMessageManager } from 'pages/ScreenMessages/thunk';
import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { downloadFile, formatTimeMessage, getFileExtFromUrl } from 'utils';

interface AdminMessageProps {
  id: string;
  senderType: SenderType;
  senderName: string;
  content: string;
  messageFile: { filename: string; url: string }[];
  createdAt: string;
  avatar?: string;
}

export const AdminMessage: FC<AdminMessageProps> = (props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const renderAvatar = useMemo(() => {
    switch (props.senderType) {
      case SenderType.USER:
        return userAvatar;
      case SenderType.MANAGER:
        return spMilize;
      default:
        return props.avatar;
    }
  }, [props.avatar, props.senderType]);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteMessageManager(props.id));
    setOpenDeleteModal(false);
  }, [dispatch, props.id]);

  if (
    (location.pathname === routeNames.ManagerMessageDetail.path &&
      props.senderType === SenderType.MANAGER) ||
    (location.pathname === routeNames.SalePersonMessageDetail.path &&
      props.senderType === SenderType.SALEPERSON)
  )
    return (
      <Stack spacing={4}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            pt: 4,
          }}
        >
          <Typography
            variant="sp_footer_bank_name"
            sx={{
              lineHeight: '100%',
              letterSpacing: 0.6,
              fontSize: 14,
            }}
            color="b_333"
          >
            {!!props.createdAt && formatTimeMessage(props.createdAt)}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: 'row-reverse',
          }}
        >
          <Stack alignItems="flex-end" spacing={2}>
            {props.content && props.content !== '<span></span>' && (
              <CustomTooltip
                title={
                  location.pathname.startsWith('/manager/', 0) ? (
                    <Stack
                      direction="row"
                      sx={{
                        p: '8px 14px',
                      }}
                      spacing="9.5px"
                      onClick={() => {
                        setOpenDeleteModal(true);
                      }}
                    >
                      <Icons.SPTrash sx={{ width: 17.5, height: 20 }} />
                      <Typography
                        variant="headline_input"
                        color="sp_primary_100_main"
                        lineHeight="20px"
                      >
                        削除
                      </Typography>
                    </Stack>
                  ) : (
                    ''
                  )
                }
                placement="top-end"
              >
                <Stack
                  sx={{
                    position: 'relative',
                    bgcolor: 'sp_primary_100_main',
                    px: 3,
                    py: 2,
                    maxWidth: 360,
                    borderRadius: 2,
                    mx: 5,
                  }}
                >
                  <Typography
                    variant="sp_change_password_note"
                    sx={{
                      lineHeight: '170%',
                    }}
                    color="main_white"
                    dangerouslySetInnerHTML={{
                      __html: props.content,
                    }}
                  />

                  <Stack
                    sx={{
                      position: 'absolute',
                      bottom: '-6px',
                      right: '-12px',
                    }}
                  >
                    <Icons.SPChatBubbleUser />
                  </Stack>
                </Stack>
              </CustomTooltip>
            )}
            {!!props.messageFile.length && (
              <CustomTooltip
                title={
                  <Stack
                    direction="row"
                    sx={{
                      p: '8px 14px',
                    }}
                    spacing="9.5px"
                    onClick={() => {
                      setOpenDeleteModal(true);
                    }}
                  >
                    <Icons.SPTrash sx={{ width: 17.5, height: 20 }} />
                    <Typography
                      variant="headline_input"
                      color="sp_primary_100_main"
                      lineHeight="20px"
                    >
                      削除
                    </Typography>
                  </Stack>
                }
                placement="top-end"
              >
                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={3}
                  sx={{ position: 'relative', maxWidth: '75%' }}
                >
                  {props.messageFile
                    .filter(
                      (file) => !getFileExtFromUrl(file.url).includes('pdf')
                    )
                    .map((image, index) => (
                      <Grid key={index} item xs={4} sx={{ px: 5 }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            bgcolor: 'bg_off',
                            border: 'none',
                            boxShadow: 0,
                            p: 0,
                          }}
                        >
                          <Avatar
                            variant="square"
                            src={image.url}
                            sx={{
                              width: 136,
                              height: 100,
                              '.MuiAvatar-img': {
                                objectFit: 'cover',
                              },
                            }}
                          />
                        </Stack>
                      </Grid>
                    ))}
                  {props.messageFile
                    .filter((file) =>
                      getFileExtFromUrl(file.url).includes('pdf')
                    )
                    .map((file, index) => (
                      <Stack
                        key={index}
                        sx={{
                          position: 'relative',
                          bgcolor: 'sp_primary_100_main',
                          px: 3,
                          py: 2,
                          maxWidth: 360,
                          borderRadius: 2,
                          mx: 5,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          downloadFile(file.url, file.filename);
                        }}
                      >
                        <Typography
                          variant="sp_change_password_note"
                          sx={{
                            lineHeight: '170%',
                            fontWeight: 700,
                          }}
                          color="main_white"
                        >
                          {file.filename}
                        </Typography>

                        <Stack
                          sx={{
                            position: 'absolute',
                            bottom: '-6px',
                            right: '-12px',
                          }}
                        >
                          <Icons.SPChatBubbleUser />
                        </Stack>
                      </Stack>
                    ))}
                </Grid>
              </CustomTooltip>
            )}
          </Stack>
        </Stack>
        <SPModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title={`メッセージを削除します。\nよろしいですか？`}
          disableBackDropClick
          sx={{
            minWidth: 265,
          }}
          sxChildren={{ overflowY: 'auto' }}
          footer={
            <Stack spacing={6} alignItems="center">
              <Button
                sx={{
                  width: 148,
                  height: 40,
                  bgcolor: 'sp_primary_100_main',
                  borderRadius: '2px',
                }}
                onClick={handleDelete}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="main_white"
                >
                  削除する
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 148,
                  height: 40,
                  bgcolor: 'main_white',
                  border: '1px solid',
                  borderColor: 'sp_primary_100_main',
                  borderRadius: '2px',
                }}
                onClick={() => setOpenDeleteModal(false)}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="sp_primary_100_main"
                >
                  キャンセル
                </Typography>
              </Button>
            </Stack>
          }
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{
              p: '12px 18px 12px 18px',
              bgcolor: 'sp_secondary_pink_light',
              border: (theme) => `1px solid ${theme?.palette?.sp_secondary_01}`,
              borderRadius: '8px',
            }}
          >
            <Icons.SPWarning />
            <Typography
              variant="drawerText"
              color="sp_secondary_01"
              sx={{ whiteSpace: 'break-spaces' }}
            >
              削除後は元に戻すことはできません
            </Typography>
          </Stack>
        </SPModal>
      </Stack>
    );
  return (
    <Stack>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: 4,
        }}
      >
        <Typography
          variant="sp_footer_bank_name"
          sx={{
            lineHeight: '100%',
            letterSpacing: 0.6,
            fontSize: 14,
          }}
          color="b_333"
        >
          {formatTimeMessage(props.createdAt)}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          px: 2,
          mb: '6px',
        }}
        spacing={1}
      >
        <Avatar
          variant="square"
          src={renderAvatar}
          sx={{
            width: 20,
            height: 20,
            borderRadius: '50%',
            '.MuiAvatar-img': {
              objectFit: 'contain',
            },
          }}
        />
        <Typography
          variant="sp_footer_bank_name"
          sx={{
            lineHeight: '100%',
            letterSpacing: 0.6,
          }}
        >
          {props.senderName}
        </Typography>
      </Stack>
      <Stack alignItems="flex-start">
        <Stack
          sx={{
            position: 'relative',
            bgcolor: 'sp_primary_20',
            px: 3,
            py: 2,
            maxWidth: 271,
            borderRadius: 2,
            mx: 5,
          }}
        >
          <Typography
            variant="sp_change_password_note"
            sx={{
              lineHeight: '170%',
            }}
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          />
          {!!props.messageFile.length && (
            <Stack alignItems="center" spacing={3}>
              {props.messageFile.map((image, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  sx={{
                    bgcolor: 'bg_off',
                    border: 'none',
                    boxShadow: 0,
                    p: 0,
                  }}
                >
                  <Avatar
                    variant="square"
                    src={image.url}
                    sx={{
                      width: 136,
                      height: 100,
                      '.MuiAvatar-img': {
                        objectFit: 'cover',
                      },
                    }}
                  />
                </Stack>
              ))}
            </Stack>
          )}
          <Stack sx={{ position: 'absolute', bottom: '-6px', left: '-13px' }}>
            <Icons.SPChatBubble />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const CustomTooltip = styled(
  ({ className, placement, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={{
        placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [10, -20],
            },
          },
        ],
      }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme?.palette.main_white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '2px',
    border: '1px solid',
    borderColor: theme?.palette.sp_primary_100_main,
    cursor: 'pointer',
    padding: 0,
  },
  [`& .${tooltipClasses.tooltip}:hover`]: {
    backgroundColor: theme?.palette.tooltip_hover,
  },
  [`&.${tooltipClasses.popper}`]: {
    zIndex: 2,
    backgroundColor: 'transparent',
  },
}));
