import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPImage, SPModal } from 'components';
import {
  BorrowerIncomeType,
  CoOwnerType,
  GeneralIncomeConfirmation,
  IdentityVerificationType,
  LoanType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PlannedCohabitantType,
  SP_FIELDS_TITLE,
} from 'constant';
import { SPStepLayout } from 'containers';
import { spCurrentStepSelector } from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { ImageContext } from 'context';
import { useAppDispatch, useCheckPreliminaryStatus } from 'hooks';
import { get, set } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { spStepEightSelector } from 'pages/SPStepEight/selectors';
import { spStepFiveSelector } from 'pages/SPStepFive/selectors';
import { spStepFourSelector } from 'pages/SPStepFour/selectors';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { addStatus } from 'pages/SPStepFourIncomeTotalizer/slice';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneApplicantPeopleSelector,
  spStepOneSelector,
} from 'pages/SPStepOne/selectors';
import { spStepSevenSelector } from 'pages/SPStepSeven/selectors';
import { spStepSixSelector } from 'pages/SPStepSix/selectors';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { spStepThreeSelector } from 'pages/SPStepThree/selectors';
import { spStepTwoSelector } from 'pages/SPStepTwo/selectors';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckUpdateStatusResponse } from 'services';
import { SPFormData as FormikData } from 'types';
import { checkingExistFile, supportDvh } from 'utils';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import {
  SPLoanApplyDateInfo,
  SPStepEightInfo,
  SPStepFiveIncomeTotalizerInfo,
  SPStepFourCollateralProviderInfo,
  SPStepFourIncomeTotalizerInfo,
  SPStepFourInfo,
  SPStepFiveInfo,
  SPStepOneInfo,
  SPStepSevenIncomeTotalizerInfo,
  SPStepSevenInfo,
  SPStepSixInfo,
  SPStepThreeInfo,
  SPStepTwoInfo,
} from './components';
import { getSPFormData, submitFormData, updatePreliminary } from './thunk';

const SPStepNinePage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mainData, setMainData] = useState<FormikData[]>([]);
  const [incomeTotalData, setIncomeTotalData] = useState<FormikData[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const currentStep = useSelector(spCurrentStepSelector);
  const { application_number } = useSelector(spStepTenSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const spStepOneApplicantPeople = useSelector(
    spStepOneApplicantPeopleSelector
  );
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepSevenIncomeTotalValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepEightValues = useSelector(spStepEightSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const { p_applicant_people } = useSelector(spStepThreeSelector);

  useCheckPreliminaryStatus();

  const {
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    repaymentScheduleImage,
    residenceFile,
    residenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    businessCard,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    propertyInformationFile,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    setResidenceFile,
    setResidenceFileBackImage,
    setPropertyInformationFile,
    setRepaymentScheduleImage,
    setInsuranceFile,
    setInsuranceFileBackImage,
    setInsuranceFile2,
    setInsuranceFileBackImage2,
    setFinancialStatement1File,
    setFinancialStatement1File2,
    setFinancialStatement2File,
    setFinancialStatement2File2,
    setFinancialStatement3File,
    setFinancialStatement3File2,
    setFirstWithholdingSlipFile,
    setFirstWithholdingSlipFile2,
    setSecondWithholdingSlipFile,
    setSecondWithholdingSlipFile2,
    setEmploymentAgreementFile,
    setEmploymentAgreementFile2,
    setBusinessTaxReturn1File,
    setBusinessTaxReturn1File2,
    setBusinessTaxReturn2File,
    setBusinessTaxReturn2File2,
    setBusinessTaxReturn3File,
    setBusinessTaxReturn3File2,
    setFirstIncomeFile,
    setFirstIncomeFile2,
    setSecondIncomeFile,
    setSecondIncomeFile2,
    setThirdIncomeFile,
    setThirdIncomeFile2,
    setDriverLicenseFrontImage,
    setDriverLicenseFrontImage2,
    setDriverLicenseBackImage,
    setDriverLicenseBackImage2,
    setCardNumberFrontImage,
    setCardNumberFrontImage2,
    setCardNumberBackImage,
    setCardNumberBackImage2,
    setResidentRegisterFrontImage,
    setResidentRegisterFrontImage2,
    setResidentRegisterBackImage,
    setResidentRegisterBackImage2,
    setOtherDocumentFile2,
    setOtherDocumentFile,
    setResidenceIncomeFile,
    setResidenceIncomeFileBackImage,
    setBusinessCard,
    resetImage,
  } = useContext(ImageContext);
  const [openModal, setOpenModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [messageErrors, setMessageErrors] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [reloadImage, setReloadImage] = useState(0);

  const isIncomePerson = useMemo(() => {
    return (
      spStepOneValues.p_application_header.loan_type === LoanType.THREE ||
      spStepOneValues.p_application_header.loan_type === LoanType.FOUR
    );
  }, [spStepOneValues.p_application_header.loan_type]);

  useEffect(() => {
    const getData = async () => {
      const result = await dispatch(getSPFormData({}));
      if (getSPFormData.fulfilled.match(result)) {
        setMainData(result.payload.data.main);
        setIncomeTotalData(result.payload.data.incomeTotal);
      }
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (messageErrors.length) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [messageErrors]);

  useEffect(() => {
    // Step 2
    let newResidenceFileImage: (SPImage | File)[] = [];
    if (
      !!residenceFile &&
      residenceFile.length === 0 &&
      !!spStepTwoValues.p_applicant_people.residence_file &&
      !!spStepTwoValues.p_applicant_people.residence_file?.length
    ) {
      spStepTwoValues.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          newResidenceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!residenceFile && !!residenceFile.length) {
      newResidenceFileImage = [...residenceFile];
    } else {
      spStepTwoValues.p_applicant_people.residence_file.forEach((file) => {
        if (!file.isDelete) {
          newResidenceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setResidenceFile(newResidenceFileImage);

    let newResidenceFileBackImage: (SPImage | File)[] = [];
    if (
      !!residenceFileBackImage &&
      residenceFileBackImage.length === 0 &&
      !!spStepTwoValues.p_applicant_people.residence_file_back_image &&
      !!spStepTwoValues.p_applicant_people.residence_file_back_image?.length
    ) {
      spStepTwoValues.p_applicant_people.residence_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidenceFileBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!residenceFileBackImage && !!residenceFileBackImage.length) {
      newResidenceFileBackImage = [...residenceFileBackImage];
    } else {
      spStepTwoValues.p_applicant_people.residence_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidenceFileBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidenceFileBackImage(newResidenceFileBackImage);

    // Step 4
    let newPropertyInformationFile: (SPImage | File)[] = [];
    if (
      !!propertyInformationFile &&
      propertyInformationFile.length === 0 &&
      !!spStepFourValues.p_application_header.property_information_file &&
      !!spStepFourValues.p_application_header.property_information_file?.length
    ) {
      spStepFourValues.p_application_header.property_information_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newPropertyInformationFile.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!propertyInformationFile && !!propertyInformationFile.length) {
      newPropertyInformationFile = [...propertyInformationFile];
    } else {
      spStepFourValues.p_application_header.property_information_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newPropertyInformationFile.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setPropertyInformationFile(newPropertyInformationFile);

    // Step 5
    let newRepaymentScheduleImage: (SPImage | File)[][] = [];

    spStepFiveValues.p_borrowings_attributes.forEach((borrowing, index) => {
      if (
        !!repaymentScheduleImage[index] &&
        repaymentScheduleImage[index].length === 0
      ) {
        if (
          !!borrowing.repayment_schedule_image &&
          !!borrowing.repayment_schedule_image?.length
        ) {
          const listImages = borrowing.repayment_schedule_image.filter(
            (file) => !file.isDelete
          );
          newRepaymentScheduleImage.push(
            listImages.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        } else {
          newRepaymentScheduleImage.push([]);
        }
      } else if (
        !!repaymentScheduleImage[index] &&
        !!repaymentScheduleImage[index].length
      ) {
        newRepaymentScheduleImage.push(repaymentScheduleImage[index]);
      } else {
        if (
          !!borrowing.repayment_schedule_image &&
          !!borrowing.repayment_schedule_image?.length
        ) {
          const listImages = borrowing.repayment_schedule_image.filter(
            (file) => !file.isDelete
          );
          newRepaymentScheduleImage.push(
            listImages.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        } else {
          newRepaymentScheduleImage.push([]);
        }
      }
    });
    setRepaymentScheduleImage(newRepaymentScheduleImage);

    // Step 7
    // insurance_file
    let newInsuranceFileImage: (SPImage | File)[] = [];
    if (
      !!insuranceFile &&
      insuranceFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.insurance_file &&
      !!spStepSevenValues.p_applicant_people.insurance_file?.length
    ) {
      spStepSevenValues.p_applicant_people.insurance_file.forEach((file) => {
        if (!file.isDelete) {
          newInsuranceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!insuranceFile && !!insuranceFile.length) {
      newInsuranceFileImage = [...insuranceFile];
    } else {
      spStepSevenValues.p_applicant_people.insurance_file.forEach((file) => {
        if (!file.isDelete) {
          newInsuranceFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setInsuranceFile(newInsuranceFileImage);
    // insurance_file_back_image
    let newInsuranceFile1Image: (SPImage | File)[] = [];
    if (
      !!insuranceFileBackImage &&
      insuranceFileBackImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.insurance_file_back_image &&
      !!spStepSevenValues.p_applicant_people.insurance_file_back_image?.length
    ) {
      spStepSevenValues.p_applicant_people.insurance_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFile1Image.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!insuranceFileBackImage && !!insuranceFileBackImage.length) {
      newInsuranceFile1Image = [...insuranceFileBackImage];
    } else {
      spStepSevenValues.p_applicant_people.insurance_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFile1Image.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setInsuranceFileBackImage(newInsuranceFile1Image);
    // financial_statement_1_file:
    let newFinancialStatement1FileImage: (SPImage | File)[] = [];
    if (
      !!financialStatement1File &&
      financialStatement1File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.financial_statement_1_file &&
      !!spStepSevenValues.p_applicant_people.financial_statement_1_file?.length
    ) {
      spStepSevenValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!financialStatement1File && !!financialStatement1File.length) {
      newFinancialStatement1FileImage = [...financialStatement1File];
    } else {
      spStepSevenValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement1File(newFinancialStatement1FileImage);
    // financial_statement_2_file: financialStatement2File
    let newFinancialStatement2FileImage: (SPImage | File)[] = [];
    if (
      !!financialStatement2File &&
      financialStatement2File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.financial_statement_2_file &&
      !!spStepSevenValues.p_applicant_people.financial_statement_2_file?.length
    ) {
      spStepSevenValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!financialStatement2File && !!financialStatement2File.length) {
      newFinancialStatement2FileImage = [...financialStatement2File];
    } else {
      spStepSevenValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement2File(newFinancialStatement2FileImage);
    // financial_statement_3_file
    let newFinancialStatement3FileImage: (SPImage | File)[] = [];
    if (
      !!financialStatement3File &&
      financialStatement3File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.financial_statement_3_file &&
      !!spStepSevenValues.p_applicant_people.financial_statement_3_file?.length
    ) {
      spStepSevenValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!financialStatement3File && !!financialStatement3File.length) {
      newFinancialStatement3FileImage = [...financialStatement3File];
    } else {
      spStepSevenValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement3File(newFinancialStatement3FileImage);
    // first_withholding_slip_file
    let newFirstWithholdingSlipFileImage: (SPImage | File)[] = [];
    if (
      !!firstWithholdingSlipFile &&
      firstWithholdingSlipFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.first_withholding_slip_file &&
      !!spStepSevenValues.p_applicant_people.first_withholding_slip_file?.length
    ) {
      spStepSevenValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!firstWithholdingSlipFile &&
      !!firstWithholdingSlipFile.length
    ) {
      newFirstWithholdingSlipFileImage = [...firstWithholdingSlipFile];
    } else {
      spStepSevenValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFirstWithholdingSlipFile(newFirstWithholdingSlipFileImage);
    // second_withholding_slip_file
    let newSecondWithholdingSlipFileImage: (SPImage | File)[] = [];
    if (
      !!secondWithholdingSlipFile &&
      secondWithholdingSlipFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.second_withholding_slip_file &&
      !!spStepSevenValues.p_applicant_people.second_withholding_slip_file
        ?.length
    ) {
      spStepSevenValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!secondWithholdingSlipFile &&
      !!secondWithholdingSlipFile.length
    ) {
      newSecondWithholdingSlipFileImage = [...secondWithholdingSlipFile];
    } else {
      spStepSevenValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondWithholdingSlipFile(newSecondWithholdingSlipFileImage);
    // other_document_file
    let newOtherDocumentFileImage: (SPImage | File)[] = [];
    if (
      !!otherDocumentFile &&
      otherDocumentFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.other_document_file &&
      !!spStepSevenValues.p_applicant_people.other_document_file?.length
    ) {
      spStepSevenValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!otherDocumentFile && !!otherDocumentFile.length) {
      newOtherDocumentFileImage = [...otherDocumentFile];
    } else {
      spStepSevenValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setOtherDocumentFile(newOtherDocumentFileImage);
    // employment_agreement_file
    let newEmploymentAgreementFileImage: (SPImage | File)[] = [];
    if (
      !!employmentAgreementFile &&
      employmentAgreementFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.employment_agreement_file &&
      !!spStepSevenValues.p_applicant_people.employment_agreement_file?.length
    ) {
      spStepSevenValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!employmentAgreementFile && !!employmentAgreementFile.length) {
      newEmploymentAgreementFileImage = [...employmentAgreementFile];
    } else {
      spStepSevenValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setEmploymentAgreementFile(newEmploymentAgreementFileImage);
    // business_tax_return_1_file
    let newBusinessTaxReturn1FileImage: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn1File &&
      businessTaxReturn1File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_1_file &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_1_file?.length
    ) {
      spStepSevenValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn1File && !!businessTaxReturn1File.length) {
      newBusinessTaxReturn1FileImage = [...businessTaxReturn1File];
    } else {
      spStepSevenValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn1File(newBusinessTaxReturn1FileImage);
    // business_tax_return_2_file
    let newBusinessTaxReturn2FileImage: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn2File &&
      businessTaxReturn2File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_2_file &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_2_file?.length
    ) {
      spStepSevenValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn2File && !!businessTaxReturn2File.length) {
      newBusinessTaxReturn2FileImage = [...businessTaxReturn2File];
    } else {
      spStepSevenValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn2File(newBusinessTaxReturn2FileImage);
    // business_tax_return_3_file
    let newBusinessTaxReturn3FileImage: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn3File &&
      businessTaxReturn3File.length === 0 &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_3_file &&
      !!spStepSevenValues.p_applicant_people.business_tax_return_3_file?.length
    ) {
      spStepSevenValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn3File && !!businessTaxReturn3File.length) {
      newBusinessTaxReturn3FileImage = [...businessTaxReturn3File];
    } else {
      spStepSevenValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn3File(newBusinessTaxReturn3FileImage);
    // first_income_file
    let newFirstIncomeFileImage: (SPImage | File)[] = [];
    if (
      !!firstIncomeFile &&
      firstIncomeFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.first_income_file &&
      !!spStepSevenValues.p_applicant_people.first_income_file?.length
    ) {
      spStepSevenValues.p_applicant_people.first_income_file.forEach((file) => {
        if (!file.isDelete) {
          newFirstIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!firstIncomeFile && !!firstIncomeFile.length) {
      newFirstIncomeFileImage = [...firstIncomeFile];
    } else {
      spStepSevenValues.p_applicant_people.first_income_file.forEach((file) => {
        if (!file.isDelete) {
          newFirstIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setFirstIncomeFile(newFirstIncomeFileImage);
    // second_income_file
    let newSecondIncomeFileImage: (SPImage | File)[] = [];
    if (
      !!secondIncomeFile &&
      secondIncomeFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.second_income_file &&
      !!spStepSevenValues.p_applicant_people.second_income_file?.length
    ) {
      spStepSevenValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!secondIncomeFile && !!secondIncomeFile.length) {
      newSecondIncomeFileImage = [...secondIncomeFile];
    } else {
      spStepSevenValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondIncomeFile(newSecondIncomeFileImage);
    // third_income_file
    let newThirdIncomeFileImage: (SPImage | File)[] = [];
    if (
      !!thirdIncomeFile &&
      thirdIncomeFile.length === 0 &&
      !!spStepSevenValues.p_applicant_people.third_income_file &&
      !!spStepSevenValues.p_applicant_people.third_income_file?.length
    ) {
      spStepSevenValues.p_applicant_people.third_income_file.forEach((file) => {
        if (!file.isDelete) {
          newThirdIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!thirdIncomeFile && !!thirdIncomeFile.length) {
      newThirdIncomeFileImage = [...thirdIncomeFile];
    } else {
      spStepSevenValues.p_applicant_people.third_income_file.forEach((file) => {
        if (!file.isDelete) {
          newThirdIncomeFileImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setThirdIncomeFile(newThirdIncomeFileImage);
    // driver_license_front_image
    let newDriverLicenseFrontImage: (SPImage | File)[] = [];
    if (
      !!driverLicenseFrontImage &&
      driverLicenseFrontImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.driver_license_front_image &&
      !!spStepSevenValues.p_applicant_people.driver_license_front_image?.length
    ) {
      spStepSevenValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!driverLicenseFrontImage && !!driverLicenseFrontImage.length) {
      newDriverLicenseFrontImage = [...driverLicenseFrontImage];
    } else {
      spStepSevenValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseFrontImage(newDriverLicenseFrontImage);
    // driver_license_back_image
    let newDriverLicenseBackImage: (SPImage | File)[] = [];
    if (
      !!driverLicenseBackImage &&
      driverLicenseBackImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.driver_license_back_image &&
      !!spStepSevenValues.p_applicant_people.driver_license_back_image?.length
    ) {
      spStepSevenValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!driverLicenseBackImage && !!driverLicenseBackImage.length) {
      newDriverLicenseBackImage = [...driverLicenseBackImage];
    } else {
      spStepSevenValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseBackImage(newDriverLicenseBackImage);
    // card_number_front_image: cardNumberFrontImage
    let newCardNumberFrontImage: (SPImage | File)[] = [];
    if (
      !!cardNumberFrontImage &&
      cardNumberFrontImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.card_number_front_image &&
      !!spStepSevenValues.p_applicant_people.card_number_front_image?.length
    ) {
      spStepSevenValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!cardNumberFrontImage && !!cardNumberFrontImage.length) {
      newCardNumberFrontImage = [...cardNumberFrontImage];
    } else {
      spStepSevenValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setCardNumberFrontImage(newCardNumberFrontImage);
    // card_number_back_image
    // let newCardNumberBackImage: (SPImage | File)[] = [];
    // if (
    //   !!cardNumberBackImage &&
    //   cardNumberBackImage.length === 0 &&
    //   !!spStepSevenValues.p_applicant_people.card_number_back_image &&
    //   !!spStepSevenValues.p_applicant_people.card_number_back_image?.length
    // ) {
    //   spStepSevenValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // } else if (!!cardNumberBackImage && !!cardNumberBackImage.length) {
    //   newCardNumberBackImage = [...cardNumberBackImage];
    // } else {
    //   spStepSevenValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // }
    // setCardNumberBackImage(newCardNumberBackImage);
    // resident_register_front_image: residentRegisterFrontImage
    let newResidentRegisterFrontImage: (SPImage | File)[] = [];
    if (
      !!residentRegisterFrontImage &&
      residentRegisterFrontImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.resident_register_front_image &&
      !!spStepSevenValues.p_applicant_people.resident_register_front_image
        ?.length
    ) {
      spStepSevenValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterFrontImage &&
      !!residentRegisterFrontImage.length
    ) {
      newResidentRegisterFrontImage = [...residentRegisterFrontImage];
    } else {
      spStepSevenValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterFrontImage(newResidentRegisterFrontImage);
    // resident_register_back_image
    let newResidentRegisterBackImage: (SPImage | File)[] = [];
    if (
      !!residentRegisterBackImage &&
      residentRegisterBackImage.length === 0 &&
      !!spStepSevenValues.p_applicant_people.resident_register_back_image &&
      !!spStepSevenValues.p_applicant_people.resident_register_back_image
        ?.length
    ) {
      spStepSevenValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterBackImage &&
      !!residentRegisterBackImage.length
    ) {
      newResidentRegisterBackImage = [...residentRegisterBackImage];
    } else {
      spStepSevenValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterBackImage(newResidentRegisterBackImage);

    // Step 8
    let newBusinessCardImage: (SPImage | File)[] = [];
    if (
      !!businessCard &&
      businessCard.length === 0 &&
      !!spStepEightValues.p_referral_agencies.business_card &&
      !!spStepEightValues.p_referral_agencies.business_card?.length
    ) {
      spStepEightValues.p_referral_agencies.business_card.forEach((file) => {
        if (!file.isDelete) {
          newBusinessCardImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!businessCard && !!businessCard.length) {
      newBusinessCardImage = [...businessCard];
    } else {
      spStepEightValues.p_referral_agencies.business_card.forEach((file) => {
        if (!file.isDelete) {
          newBusinessCardImage.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setBusinessCard(newBusinessCardImage);

    if (incomeTotalizer) {
      // Step 4 income
      let newResidenceIncomeFileImage: (SPImage | File)[] = [];
      if (
        !!residenceIncomeFile &&
        residenceIncomeFile.length === 0 &&
        !!spStepTwoIncomeTotalValues.p_applicant_people.residence_file &&
        !!spStepTwoIncomeTotalValues.p_applicant_people.residence_file?.length
      ) {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      } else if (!!residenceIncomeFile && !!residenceIncomeFile.length) {
        newResidenceIncomeFileImage = [...residenceIncomeFile];
      } else {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      }
      setResidenceIncomeFile(newResidenceIncomeFileImage);

      let newResidenceIncomeFileBackImage: (SPImage | File)[] = [];
      if (
        !!residenceIncomeFileBackImage &&
        residenceIncomeFileBackImage.length === 0 &&
        !!spStepTwoIncomeTotalValues.p_applicant_people
          .residence_file_back_image &&
        !!spStepTwoIncomeTotalValues.p_applicant_people
          .residence_file_back_image?.length
      ) {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file_back_image.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileBackImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      } else if (
        !!residenceIncomeFileBackImage &&
        !!residenceIncomeFileBackImage.length
      ) {
        newResidenceIncomeFileBackImage = [...residenceIncomeFileBackImage];
      } else {
        spStepTwoIncomeTotalValues.p_applicant_people.residence_file_back_image.forEach(
          (file) => {
            if (!file.isDelete) {
              newResidenceIncomeFileBackImage.push({
                id: file.id,
                url: file.url,
                name: file.filename,
                hiden: file.hiden,
              });
            }
          }
        );
      }
      setResidenceIncomeFileBackImage(newResidenceIncomeFileBackImage);
    }
    // Step 7 income
    // insurance_file
    let newInsuranceFileImage2: (SPImage | File)[] = [];
    if (
      !!insuranceFile2 &&
      insuranceFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.insurance_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.insurance_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.insurance_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!insuranceFile2 && !!insuranceFile2.length) {
      newInsuranceFileImage2 = [...insuranceFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.insurance_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setInsuranceFile2(newInsuranceFileImage2);
    // insurance_file
    let newInsuranceFileImage3: (SPImage | File)[] = [];
    if (
      !!insuranceFileBackImage2 &&
      insuranceFileBackImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .insurance_file_back_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .insurance_file_back_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.insurance_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFileImage3.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!insuranceFileBackImage2 && !!insuranceFileBackImage2.length) {
      newInsuranceFileImage3 = [...insuranceFileBackImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.insurance_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newInsuranceFileImage3.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setInsuranceFileBackImage2(newInsuranceFileImage3);
    // financial_statement_1_file:
    let newFinancialStatement1FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement1File2 &&
      financialStatement1File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_1_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_1_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement1File2 &&
      !!financialStatement1File2.length
    ) {
      newFinancialStatement1FileImage2 = [...financialStatement1File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement1File2(newFinancialStatement1FileImage2);
    // financial_statement_2_file: financialStatement2File
    let newFinancialStatement2FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement2File2 &&
      financialStatement2File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_2_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_2_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement2File2 &&
      !!financialStatement2File2.length
    ) {
      newFinancialStatement2FileImage2 = [...financialStatement2File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement2File2(newFinancialStatement2FileImage2);
    // financial_statement_3_file
    let newFinancialStatement3FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement3File2 &&
      financialStatement3File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_3_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .financial_statement_3_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement3File2 &&
      !!financialStatement3File2.length
    ) {
      newFinancialStatement3FileImage2 = [...financialStatement3File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFinancialStatement3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement3File2(newFinancialStatement3FileImage2);
    // first_withholding_slip_file
    let newFirstWithholdingSlipFileImage2: (SPImage | File)[] = [];
    if (
      !!firstWithholdingSlipFile2 &&
      firstWithholdingSlipFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .first_withholding_slip_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .first_withholding_slip_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!firstWithholdingSlipFile2 &&
      !!firstWithholdingSlipFile2.length
    ) {
      newFirstWithholdingSlipFileImage2 = [...firstWithholdingSlipFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFirstWithholdingSlipFile2(newFirstWithholdingSlipFileImage2);
    // second_withholding_slip_file
    let newSecondWithholdingSlipFileImage2: (SPImage | File)[] = [];
    if (
      !!secondWithholdingSlipFile2 &&
      secondWithholdingSlipFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .second_withholding_slip_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .second_withholding_slip_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!secondWithholdingSlipFile2 &&
      !!secondWithholdingSlipFile2.length
    ) {
      newSecondWithholdingSlipFileImage2 = [...secondWithholdingSlipFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondWithholdingSlipFile2(newSecondWithholdingSlipFileImage2);
    // other_document_file
    let newOtherDocumentFileImage2: (SPImage | File)[] = [];
    if (
      !!otherDocumentFile2 &&
      otherDocumentFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.other_document_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.other_document_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!otherDocumentFile2 && !!otherDocumentFile2.length) {
      newOtherDocumentFileImage2 = [...otherDocumentFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.other_document_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newOtherDocumentFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setOtherDocumentFile2(newOtherDocumentFileImage2);
    // employment_agreement_file
    let newEmploymentAgreementFileImage2: (SPImage | File)[] = [];
    if (
      !!employmentAgreementFile2 &&
      employmentAgreementFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .employment_agreement_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .employment_agreement_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!employmentAgreementFile2 &&
      !!employmentAgreementFile2.length
    ) {
      newEmploymentAgreementFileImage2 = [...employmentAgreementFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newEmploymentAgreementFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setEmploymentAgreementFile2(newEmploymentAgreementFileImage2);
    // business_tax_return_1_file
    let newBusinessTaxReturn1FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn1File2 &&
      businessTaxReturn1File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_1_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_1_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn1File2 && !!businessTaxReturn1File2.length) {
      newBusinessTaxReturn1FileImage2 = [...businessTaxReturn1File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn1File2(newBusinessTaxReturn1FileImage2);
    // business_tax_return_2_file
    let newBusinessTaxReturn2FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn2File2 &&
      businessTaxReturn2File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_2_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_2_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn2File2 && !!businessTaxReturn2File2.length) {
      newBusinessTaxReturn2FileImage2 = [...businessTaxReturn2File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn2File2(newBusinessTaxReturn2FileImage2);
    // business_tax_return_3_file
    let newBusinessTaxReturn3FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn3File2 &&
      businessTaxReturn3File2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_3_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .business_tax_return_3_file?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn3File2 && !!businessTaxReturn3File2.length) {
      newBusinessTaxReturn3FileImage2 = [...businessTaxReturn3File2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newBusinessTaxReturn3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn3File2(newBusinessTaxReturn3FileImage2);
    // first_income_file
    let newFirstIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!firstIncomeFile2 &&
      firstIncomeFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.first_income_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.first_income_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.first_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!firstIncomeFile2 && !!firstIncomeFile2.length) {
      newFirstIncomeFileImage2 = [...firstIncomeFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.first_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newFirstIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFirstIncomeFile2(newFirstIncomeFileImage2);
    // second_income_file
    let newSecondIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!secondIncomeFile2 &&
      secondIncomeFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.second_income_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.second_income_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!secondIncomeFile2 && !!secondIncomeFile2.length) {
      newSecondIncomeFileImage2 = [...secondIncomeFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.second_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newSecondIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondIncomeFile2(newSecondIncomeFileImage2);
    // third_income_file
    let newThirdIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!thirdIncomeFile2 &&
      thirdIncomeFile2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.third_income_file &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.third_income_file
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.third_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newThirdIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!thirdIncomeFile2 && !!thirdIncomeFile2.length) {
      newThirdIncomeFileImage2 = [...thirdIncomeFile2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.third_income_file.forEach(
        (file) => {
          if (!file.isDelete) {
            newThirdIncomeFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setThirdIncomeFile2(newThirdIncomeFileImage2);
    // driver_license_front_image
    let newDriverLicenseFrontImage2: (SPImage | File)[] = [];
    if (
      !!driverLicenseFrontImage2 &&
      driverLicenseFrontImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_front_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_front_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!driverLicenseFrontImage2 &&
      !!driverLicenseFrontImage2.length
    ) {
      newDriverLicenseFrontImage2 = [...driverLicenseFrontImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseFrontImage2(newDriverLicenseFrontImage2);
    // driver_license_back_image
    let newDriverLicenseBackImage2: (SPImage | File)[] = [];
    if (
      !!driverLicenseBackImage2 &&
      driverLicenseBackImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_back_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .driver_license_back_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!driverLicenseBackImage2 && !!driverLicenseBackImage2.length) {
      newDriverLicenseBackImage2 = [...driverLicenseBackImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newDriverLicenseBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseBackImage2(newDriverLicenseBackImage2);
    // card_number_front_image: cardNumberFrontImage
    let newCardNumberFrontImage2: (SPImage | File)[] = [];
    if (
      !!cardNumberFrontImage2 &&
      cardNumberFrontImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .card_number_front_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people.card_number_front_image
        ?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!cardNumberFrontImage2 && !!cardNumberFrontImage2.length) {
      newCardNumberFrontImage2 = [...cardNumberFrontImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newCardNumberFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setCardNumberFrontImage2(newCardNumberFrontImage2);
    // card_number_back_image
    // let newCardNumberBackImage2: (SPImage | File)[] = [];
    // if (
    //   !!cardNumberBackImage2 &&
    //   cardNumberBackImage2.length === 0 &&
    //   !!spStepSevenIncomeTotalValues.p_applicant_people
    //     .card_number_back_image &&
    //   !!spStepSevenIncomeTotalValues.p_applicant_people.card_number_back_image
    //     ?.length
    // ) {
    //   spStepSevenIncomeTotalValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage2.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // } else if (!!cardNumberBackImage2 && !!cardNumberBackImage2.length) {
    //   newCardNumberBackImage2 = [...cardNumberBackImage2];
    // } else {
    //   spStepSevenIncomeTotalValues.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         newCardNumberBackImage2.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // }
    // setCardNumberBackImage2(newCardNumberBackImage2);
    // resident_register_front_image
    let newResidentRegisterFrontImage2: (SPImage | File)[] = [];
    if (
      !!residentRegisterFrontImage2 &&
      residentRegisterFrontImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_front_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_front_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterFrontImage2 &&
      !!residentRegisterFrontImage2.length
    ) {
      newResidentRegisterFrontImage2 = [...residentRegisterFrontImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterFrontImage2(newResidentRegisterFrontImage2);
    // resident_register_back_image
    let newResidentRegisterBackImage2: (SPImage | File)[] = [];
    if (
      !!residentRegisterBackImage2 &&
      residentRegisterBackImage2.length === 0 &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_back_image &&
      !!spStepSevenIncomeTotalValues.p_applicant_people
        .resident_register_back_image?.length
    ) {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterBackImage2 &&
      !!residentRegisterBackImage2.length
    ) {
      newResidentRegisterBackImage2 = [...residentRegisterBackImage2];
    } else {
      spStepSevenIncomeTotalValues.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            newResidentRegisterBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterBackImage2(newResidentRegisterBackImage2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = async () => {
    const data = new FormData();

    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      p_application_banks_attributes,
      master_bank_ids,
      ...restStepOneApplicationHeader
    } = spStepOneValues.p_application_header;

    const stepTwoApplicantPeople = spStepTwoValues.p_applicant_people;
    const stepThreeApplicantPeople = spStepThreeValues.p_applicant_people;
    const {
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      business_ability,
      ...restStepFourApplicationHeader
    } = spStepFourValues.p_application_header;
    const p_residents_attributes_key_edit =
      spStepFourValues.p_residents_attributes;

    const { p_join_guarantors } = spStepCollateralProviderValues;

    const {
      p_borrowings_attributes,
      p_applicant_people_attributes: stepFiveApplicantPeople,
      ...restStepFiveApplicationHeader
    } = spStepFiveValues;
    const { other_procurement_breakdown_content, ...stepSixApplicationHeader } =
      spStepSixValues.p_application_header;
    let checkEmptyValueStepSix = {};
    Object.keys(stepSixApplicationHeader).forEach((key) => {
      if (key === 'require_funds_breakdown_mortgage') {
        if (!spStepOneValues.p_application_header.is_home_loan_plus) {
          set(checkEmptyValueStepSix, key, '0');
        } else {
          set(
            checkEmptyValueStepSix,
            key,
            !!get(stepSixApplicationHeader, key)
              ? get(stepSixApplicationHeader, key)
              : '0'
          );
        }
      } else {
        set(
          checkEmptyValueStepSix,
          key,
          !!get(stepSixApplicationHeader, key)
            ? get(stepSixApplicationHeader, key)
            : '0'
        );
      }
    });

    const { identity_verification, ...spStepSevenImageValues } =
      spStepSevenValues.p_applicant_people;
    const {
      business_card,
      // sale_agent_id,
      // store_id,
      // exhibition_id,
      phone_number,
      // sale_person_name_input,
      ...restReferralAgencies
    } = spStepEightValues.p_referral_agencies;

    /* INCOME TOTAL VALUES */
    const stepTwoIncomeTotalApplicantPeople =
      spStepTwoIncomeTotalValues.p_applicant_people;
    const p_applicant_people_id =
      spStepTwoIncomeTotalValues.p_applicant_people_id;
    const stepThreeIncomeTotalApplicantPeople =
      spStepThreeIncomeTotalValues.p_applicant_people;
    const spStepSevenIncomeTotalApplicantPeople =
      spStepSevenIncomeTotalValues.p_applicant_people;

    const spStepsValues = incomeTotalizer
      ? {
          p_application_header: {
            ...restStepOneApplicationHeader,
            ...restStepFourApplicationHeader,
            ...restStepFiveApplicationHeader,
            ...checkEmptyValueStepSix,
            saving_amount: (
              +spStepSixValues.p_application_header.deposit_savings_1 +
              +spStepSixValues.p_application_header.real_estate_sale_price +
              +spStepSixValues.p_application_header.other_saving_amount
            ).toString(),
            other_procurement_breakdown_content,
            p_applicant_people_attributes: [
              {
                ...p_applicant_people_attributes,
                ...stepTwoApplicantPeople,
                ...stepThreeApplicantPeople,
                ...stepFourApplicantPeople,
                ...stepFiveApplicantPeople[0],
                identity_verification,
              },
              {
                ...(!!application_number &&
                  incomeTotalizer && {
                    ...spStepOneApplicantPeople[1],
                  }),
                ...stepTwoIncomeTotalApplicantPeople,
                ...stepThreeIncomeTotalApplicantPeople,
                ...stepFiveApplicantPeople[1],
                identity_verification:
                  spStepSevenIncomeTotalApplicantPeople.identity_verification,
              },
            ],
            p_borrowing_details_attributes: [...p_borrowing_details_attributes],
            p_residents_attributes: !!application_number
              ? [...p_residents_attributes_key_edit].filter((o) => !!o)
              : [p_residents_attributes].filter((o) => !!o),
            p_borrowings_attributes,
            p_join_guarantors:
              p_applicant_people_attributes.has_join_guarantor ===
                CoOwnerType.NONE && !application_number
                ? []
                : p_join_guarantors,
          },
        }
      : {
          p_application_header: {
            ...restStepOneApplicationHeader,
            ...restStepFourApplicationHeader,
            ...restStepFiveApplicationHeader,
            ...checkEmptyValueStepSix,
            saving_amount: (
              +spStepSixValues.p_application_header.deposit_savings_1 +
              +spStepSixValues.p_application_header.real_estate_sale_price +
              +spStepSixValues.p_application_header.other_saving_amount
            ).toString(),
            other_procurement_breakdown_content,
            p_applicant_people_attributes: [
              {
                ...p_applicant_people_attributes,
                ...stepTwoApplicantPeople,
                ...stepThreeApplicantPeople,
                ...stepFourApplicantPeople,
                ...stepFiveApplicantPeople[0],
                identity_verification,
              },
            ],
            p_borrowing_details_attributes: [...p_borrowing_details_attributes],
            p_residents_attributes: !!application_number
              ? [...p_residents_attributes_key_edit].filter((o) => !!o)
              : [p_residents_attributes].filter((o) => !!o),
            p_borrowings_attributes,
            p_join_guarantors:
              p_applicant_people_attributes.has_join_guarantor ===
                CoOwnerType.NONE && !application_number
                ? []
                : p_join_guarantors,
          },
        };

    const {
      p_applicant_people_attributes: applicantPeople,
      p_borrowing_details_attributes: borrowingDetails,
      p_residents_attributes: residents,
      p_borrowings_attributes: borrowings,
      p_join_guarantors: joinGuarantors,
      planned_cohabitant,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
      children_number,
      siblings_number,
      other_people_number,
      housemate_number,
      ...restApplicationHeader
    } = spStepsValues.p_application_header;

    if (!planned_cohabitant.length) {
      data.append(`p_application_header[planned_cohabitant][]`, '');
      data.append(`p_application_header[housemate_number]`, '0');
    } else {
      planned_cohabitant.forEach((plannedCohabitant) => {
        if (plannedCohabitant !== '') {
          data.append(
            `p_application_header[planned_cohabitant][]`,
            plannedCohabitant.toString()
          );
        }
      });
      data.append(`p_application_header[housemate_number]`, housemate_number);
    }

    data.append(
      `p_application_header[general_income_confirmation]`,
      spStepTwoIncomeTotalValues.status?.isReseted
        ? GeneralIncomeConfirmation.NOT_CONFIRM
        : GeneralIncomeConfirmation.CONFIRM
    );

    data.append(
      `p_application_header[children_number]`,
      planned_cohabitant.find(
        (plannedCohabitant) => plannedCohabitant === PlannedCohabitantType.CHILD
      )
        ? children_number
        : ''
    );

    data.append(
      `p_application_header[siblings_number]`,
      planned_cohabitant.find(
        (plannedCohabitant) =>
          plannedCohabitant === PlannedCohabitantType.BROTHERS_AND_SISTERS
      )
        ? siblings_number
        : ''
    );

    data.append(
      `p_application_header[other_people_number]`,
      planned_cohabitant.find(
        (plannedCohabitant) =>
          plannedCohabitant === PlannedCohabitantType.OTHERS
      )
        ? other_people_number
        : ''
    );

    if (!business_ability.length) {
      data.append(`p_application_header[business_ability][]`, '');
    } else {
      business_ability.forEach((businessAbility) => {
        data.append(
          `p_application_header[business_ability][]`,
          businessAbility.toString()
        );
      });
    }

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `p_application_header[${key}]`,
        get(restApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `p_application_header[p_applicant_people_attributes][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `p_application_header[p_applicant_people_attributes][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `p_application_header[p_applicant_people_attributes][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `p_application_header[p_applicant_people_attributes][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      applicantPeople
        .map((person) => {
          const { land_rent_to_be_paid, house_rent_to_be_paid, ...restPerson } =
            person;
          return restPerson;
        })
        .forEach((person, index) => {
          const {
            residence_file,
            income_source,
            type_tax_return,
            maternity_paternity_leave_start_time,
            maternity_paternity_leave_end_time,
            ...restFirstApplicantPeople
          } = person;
          data.append(
            `p_application_header[p_applicant_people_attributes][${index}][applicant_detail_type]`,
            index.toString()
          );
          if (!income_source.length) {
            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
              ''
            );
          } else {
            income_source.forEach((id) => {
              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
                id
              );
            });
          }
          if (!type_tax_return.length) {
            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
              ''
            );
          } else {
            type_tax_return.forEach((id) => {
              data.append(
                `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
                id
              );
            });
          }

          data.append(
            `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_start_time]`,
            !!maternity_paternity_leave_start_time
              ? `${maternity_paternity_leave_start_time}/01`
              : maternity_paternity_leave_start_time
          );
          data.append(
            `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_end_time]`,
            !!maternity_paternity_leave_end_time
              ? `${maternity_paternity_leave_end_time}/01`
              : maternity_paternity_leave_end_time
          );

          Object.keys(restFirstApplicantPeople).forEach((key) => {
            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][${key}]`,
              get(restFirstApplicantPeople, key) ?? ''
            );
          });
        });
    } else {
      applicantPeople.forEach((person, index) => {
        const {
          residence_file,
          income_source,
          type_tax_return,
          maternity_paternity_leave_start_time,
          maternity_paternity_leave_end_time,
          ...restFirstApplicantPeople
        } = person;
        data.append(
          `p_application_header[p_applicant_people_attributes][${index}][applicant_detail_type]`,
          index.toString()
        );
        if (!income_source.length) {
          data.append(
            `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
            ''
          );
        } else {
          income_source.forEach((id) => {
            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][income_source][]`,
              id
            );
          });
        }
        if (!type_tax_return.length) {
          data.append(
            `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
            ''
          );
        } else {
          type_tax_return.forEach((id) => {
            data.append(
              `p_application_header[p_applicant_people_attributes][${index}][type_tax_return][]`,
              id
            );
          });
        }

        data.append(
          `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_start_time]`,
          `${maternity_paternity_leave_start_time}/01`
        );
        data.append(
          `p_application_header[p_applicant_people_attributes][${index}][maternity_paternity_leave_end_time]`,
          `${maternity_paternity_leave_end_time}/01`
        );

        Object.keys(restFirstApplicantPeople).forEach((key) => {
          data.append(
            `p_application_header[p_applicant_people_attributes][${index}][${key}]`,
            get(restFirstApplicantPeople, key) ?? ''
          );
        });
      });
      if (!!p_applicant_people_id) {
        data.append(
          `p_application_header[p_applicant_people_attributes][1][id]`,
          p_applicant_people_id
        );
        data.append(
          `p_application_header[p_applicant_people_attributes][1][_destroy]`,
          'true'
        );
      }
    }

    borrowingDetails.forEach((borrowingDetail, index) => {
      data.append(
        `p_application_header[p_borrowing_details_attributes][${index}][borrowing_detail_type]`,
        (index + 1).toString()
      );
      Object.keys(borrowingDetail).forEach((key) => {
        data.append(
          `p_application_header[p_borrowing_details_attributes][${index}][${key}]`,
          get(borrowingDetail, key) ?? ''
        );
      });
    });

    residents.forEach((resident, index) => {
      Object.keys(resident).forEach((key) => {
        data.append(
          `p_application_header[p_residents_attributes][${index}][${key}]`,
          get(resident, key) ?? ''
        );
      });
    });

    const indexes = borrowings
      .filter((borrowing) => !borrowing._destroy)
      .map((borrowing) => borrowings.indexOf(borrowing));

    borrowings.forEach((borrowing, index) => {
      const { repayment_schedule_image, ...restBorrowing } = borrowing;
      //CASE UPDATE SUBMITTED PRELIMINARY STEP 5
      if (
        !!application_number &&
        !!repayment_schedule_image &&
        !borrowing._destroy
      ) {
        const repaymentScheduleImageIds = repaymentScheduleImage[
          indexes.indexOf(index)
        ]
          .map((file) => {
            if (file instanceof File) return '';
            return file.id;
          })
          .filter((id) => !!id);

        const repaymentScheduleImageRemovedIds = repayment_schedule_image
          .filter((file) => repaymentScheduleImageIds.includes(file.id))
          .map((file) => file.id);

        if (
          repaymentScheduleImageRemovedIds.length === 0 &&
          !repaymentScheduleImage[indexes.indexOf(index)].length
        ) {
          data.append(
            `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
            ''
          );
        } else {
          repaymentScheduleImageRemovedIds.forEach((file) => {
            data.append(
              `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
              file
            );
          });
        }
      }
      repaymentScheduleImage[indexes.indexOf(index)]?.forEach((file, idx) => {
        if (file instanceof File) {
          return data.append(
            `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
            file
          );
        } else {
          if (!application_number) {
            return data.append(
              `p_application_header[p_borrowings_attributes][${index}][repayment_schedule_image][]`,
              file.id
            );
          }
        }
      });
      Object.keys(restBorrowing).forEach((key) => {
        data.append(
          `p_application_header[p_borrowings_attributes][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    joinGuarantors.forEach((guarantor, index) => {
      Object.keys(guarantor).forEach((key) => {
        data.append(
          `p_application_header[p_join_guarantors_attributes][${index}][${key}]`,
          get(guarantor, key) ?? ''
        );
      });
    });

    const editBanks = p_application_banks_attributes.map((bank) => {
      if (master_bank_ids.includes(bank.s_master_bank_id)) return bank;
      return {
        ...bank,
        _destroy: true,
      };
    });

    if (!!application_number) {
      master_bank_ids.forEach((bankId) =>
        data.append(`p_application_header[master_bank_ids][]`, bankId)
      );
      const pApplicationBanksIds = p_application_banks_attributes.map(
        (bank) => bank.s_master_bank_id
      );
      const editNewBanks = master_bank_ids.filter(
        (bankId) => !pApplicationBanksIds.includes(bankId)
      );
      let idx = 0;
      editBanks.forEach((bank, index) => {
        Object.keys(bank).forEach((key) => {
          data.append(
            `p_application_header[p_application_banks_attributes][${index}][${key}]`,
            get(bank, key) ?? ''
          );
        });
        idx++;
      });
      if (!!editNewBanks.length) {
        editNewBanks.forEach((bankId, index) => {
          data.append(
            `p_application_header[p_application_banks_attributes][${
              idx + index
            }][s_master_bank_id]`,
            bankId
          );
        });
      }
    } else {
      master_bank_ids.forEach((bankId, index) => {
        data.append(
          `p_application_header[p_application_banks_attributes][${index}][s_master_bank_id]`,
          bankId
        );
      });
    }

    Object.keys(restReferralAgencies).forEach((key) => {
      data.append(
        `p_application_header[${key}]`,
        get(restReferralAgencies, key) ?? ''
      );
    });

    data.append(
      `p_application_header[sale_person_phone_number]`,
      phone_number ?? ''
    );

    if (spStepEightValues.p_referral_agency_id) {
      data.append(
        `p_application_header[p_referral_agency_id]`,
        spStepEightValues.p_referral_agency_id ?? ''
      );
    }

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image:
        spStepSevenValues.p_applicant_people.identity_verification ===
        IdentityVerificationType.DRIVER_LICENSE
          ? driverLicenseFrontImage
          : [],
      driver_license_back_image:
        spStepSevenValues.p_applicant_people.identity_verification ===
        IdentityVerificationType.DRIVER_LICENSE
          ? driverLicenseBackImage
          : [],
      card_number_front_image:
        spStepSevenValues.p_applicant_people.identity_verification ===
        IdentityVerificationType.MY_NUMBER_CARD
          ? cardNumberFrontImage
          : [],
      // card_number_back_image:
      //   spStepSevenValues.p_applicant_people.identity_verification ===
      //   IdentityVerificationType.MY_NUMBER_CARD
      //     ? cardNumberBackImage
      //     : [],
      resident_register_front_image:
        spStepSevenValues.p_applicant_people.identity_verification ===
        IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
          ? residentRegisterFrontImage
          : [],
      resident_register_back_image:
        spStepSevenValues.p_applicant_people.identity_verification ===
        IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
          ? residentRegisterBackImage
          : [],
    };

    const imageStepSevenIncomeTotal = {
      insurance_file: insuranceFile2,
      insurance_file_back_image: insuranceFileBackImage2,
      financial_statement_1_file: financialStatement1File2,
      financial_statement_2_file: financialStatement2File2,
      financial_statement_3_file: financialStatement3File2,
      first_withholding_slip_file: firstWithholdingSlipFile2,
      second_withholding_slip_file: secondWithholdingSlipFile2,
      other_document_file: otherDocumentFile2,
      employment_agreement_file: employmentAgreementFile2,
      business_tax_return_1_file: businessTaxReturn1File2,
      business_tax_return_2_file: businessTaxReturn2File2,
      business_tax_return_3_file: businessTaxReturn3File2,
      first_income_file: firstIncomeFile2,
      second_income_file: secondIncomeFile2,
      third_income_file: thirdIncomeFile2,
      driver_license_front_image:
        spStepSevenIncomeTotalValues.p_applicant_people
          .identity_verification === IdentityVerificationType.DRIVER_LICENSE
          ? driverLicenseFrontImage2
          : [],
      driver_license_back_image:
        spStepSevenIncomeTotalValues.p_applicant_people
          .identity_verification === IdentityVerificationType.DRIVER_LICENSE
          ? driverLicenseBackImage2
          : [],
      card_number_front_image:
        spStepSevenIncomeTotalValues.p_applicant_people
          .identity_verification === IdentityVerificationType.MY_NUMBER_CARD
          ? cardNumberFrontImage2
          : [],
      // card_number_back_image:
      //   spStepSevenIncomeTotalValues.p_applicant_people
      //     .identity_verification === IdentityVerificationType.MY_NUMBER_CARD
      //     ? cardNumberBackImage2
      //     : [],
      resident_register_front_image:
        spStepSevenIncomeTotalValues.p_applicant_people
          .identity_verification ===
        IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
          ? residentRegisterFrontImage2
          : [],
      resident_register_back_image:
        spStepSevenIncomeTotalValues.p_applicant_people
          .identity_verification ===
        IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
          ? residentRegisterBackImage2
          : [],
    };

    //CASE UPDATE SUBMITTED PRELIMINARY
    if (!!application_number) {
      //STEP 2 IMAGES - CASE 1 PERSON
      const residenceIds = residenceFile
        .map((file) => {
          if (file instanceof File) return '';
          return file.id;
        })
        .filter((id) => !!id);

      const residenceRemovedIds = stepTwoApplicantPeople.residence_file
        .filter((file) => residenceIds.includes(file.id))
        .map((file) => file.id);

      if (residenceRemovedIds.length === 0 && !residenceFile.length) {
        data.append(
          `p_application_header[p_applicant_people_attributes][0][residence_file][]`,
          ''
        );
      } else {
        residenceRemovedIds.forEach((file) => {
          data.append(
            `p_application_header[p_applicant_people_attributes][0][residence_file][]`,
            file
          );
        });
      }

      const residenceBackIds = residenceFileBackImage
        .map((file) => {
          if (file instanceof File) return '';
          return file.id;
        })
        .filter((id) => !!id);

      const residenceBackRemovedIds =
        stepTwoApplicantPeople.residence_file_back_image
          .filter((file) => residenceBackIds.includes(file.id))
          .map((file) => file.id);

      if (
        residenceBackRemovedIds.length === 0 &&
        !residenceFileBackImage.length
      ) {
        data.append(
          `p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]`,
          ''
        );
      } else {
        residenceBackRemovedIds.forEach((file) => {
          data.append(
            `p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]`,
            file
          );
        });
      }

      //STEP 2 IMAGES - CASE 2 PERSON
      const residenceIncomeIds = residenceIncomeFile
        .map((file) => {
          if (file instanceof File) return '';
          return file.id;
        })
        .filter((id) => !!id);

      const residenceIncomeRemovedIds =
        stepTwoIncomeTotalApplicantPeople.residence_file
          .filter((file) => residenceIncomeIds.includes(file.id))
          .map((file) => file.id);

      if (
        residenceIncomeRemovedIds.length === 0 &&
        !residenceIncomeFile.length
      ) {
        data.append(
          `p_application_header[p_applicant_people_attributes][1][residence_file][]`,
          ''
        );
      } else {
        residenceIncomeRemovedIds.forEach((file) => {
          data.append(
            `p_application_header[p_applicant_people_attributes][1][residence_file][]`,
            file
          );
        });
      }

      const residenceIncomeBackIds = residenceIncomeFileBackImage
        .map((file) => {
          if (file instanceof File) return '';
          return file.id;
        })
        .filter((id) => !!id);

      const residenceIncomeBackRemovedIds =
        stepTwoIncomeTotalApplicantPeople.residence_file_back_image
          .filter((file) => residenceIncomeBackIds.includes(file.id))
          .map((file) => file.id);

      if (
        residenceIncomeBackRemovedIds.length === 0 &&
        !residenceIncomeFileBackImage.length
      ) {
        data.append(
          `p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]`,
          ''
        );
      } else {
        residenceIncomeBackRemovedIds.forEach((file) => {
          data.append(
            `p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]`,
            file
          );
        });
      }

      //STEP 4 IMAGES
      const propertyInformationFileIds = propertyInformationFile
        .map((file) => {
          if (file instanceof File) return '';
          return file.id;
        })
        .filter((id) => !!id);

      const propertyInformationFileRemovedIds = property_information_file
        .filter((file) => propertyInformationFileIds.includes(file.id))
        .map((file) => file.id);

      if (
        propertyInformationFileRemovedIds.length === 0 &&
        !propertyInformationFile.length
      ) {
        data.append(`p_application_header[property_information_file][]`, '');
      } else {
        propertyInformationFileRemovedIds.forEach((file) => {
          data.append(
            `p_application_header[property_information_file][]`,
            file
          );
        });
      }

      //STEP 7 IMAGES
      Object.keys(imageStepSeven).forEach((key) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        const imageStepSevenIds = get(imageStepSeven, key, []).map(
          (file: SPImage | File) => {
            if (file instanceof File) return '';
            return file.id;
          }
        );

        const imageStepSevenRemovedIds = get(spStepSevenImageValues, key, [])
          .filter((file: SPImage) => imageStepSevenIds.includes(file.id))
          .map((file: SPImage) => file.id);
        if (
          imageStepSevenRemovedIds.length === 0 &&
          // @ts-ignore
          imageStepSeven[key].length === 0
        ) {
          isNotUploadMulti
            ? data.append(
                `p_application_header[p_applicant_people_attributes][0][${key}]`,
                ''
              )
            : data.append(
                `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                ''
              );
        } else {
          imageStepSevenRemovedIds.forEach((file: string) => {
            isNotUploadMulti
              ? data.append(
                  `p_application_header[p_applicant_people_attributes][0][${key}]`,
                  file
                )
              : data.append(
                  `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                  file
                );
          });
        }
      });
      //STEP 7 INCOME TOTAL IMAGES
      if (incomeTotalizer) {
        Object.keys(imageStepSevenIncomeTotal).forEach((key) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          const imageStepSevenIncomeIds = get(
            imageStepSevenIncomeTotal,
            key,
            []
          ).map((file: SPImage | File) => {
            if (file instanceof File) return '';
            return file.id;
          });

          const { identity_verification, ...spStepSevenIncomeImageValues } =
            spStepSevenIncomeTotalValues.p_applicant_people;

          const imageStepSevenIncomeRemovedIds = get(
            spStepSevenIncomeImageValues,
            key,
            []
          )
            .filter((file: SPImage) =>
              imageStepSevenIncomeIds.includes(file.id)
            )
            .map((file: SPImage) => file.id);

          if (
            imageStepSevenIncomeRemovedIds.length === 0 &&
            // @ts-ignore
            imageStepSevenIncomeTotal[key].length === 0
          ) {
            isNotUploadMulti
              ? data.append(
                  `p_application_header[p_applicant_people_attributes][1][${key}]`,
                  ''
                )
              : data.append(
                  `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                  ''
                );
          } else {
            imageStepSevenIncomeRemovedIds.forEach((file: string) => {
              isNotUploadMulti
                ? data.append(
                    `p_application_header[p_applicant_people_attributes][1][${key}]`,
                    file
                  )
                : data.append(
                    `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                    file
                  );
            });
          }
        });
      }

      //STEP 8 IMAGES
      const businessCardIds = businessCard
        .map((file) => {
          if (file instanceof File) return '';
          return file.id;
        })
        .filter((id) => !!id);

      const businessCardRemovedIds = business_card
        ?.filter((file) => businessCardIds.includes(file.id))
        .map((file) => file.id);

      if (businessCardRemovedIds.length === 0 && !businessCard.length) {
        data.append(`p_application_header[business_card][]`, '');
      } else {
        businessCardRemovedIds.forEach((file) => {
          data.append(`p_application_header[business_card][]`, file);
        });
      }
    }
    //STEP 2 IMAGES
    residenceFile.forEach((file) => {
      if (file instanceof File) {
        return data.append(
          'p_application_header[p_applicant_people_attributes][0][residence_file][]',
          file
        );
      } else {
        if (!application_number) {
          return data.append(
            'p_application_header[p_applicant_people_attributes][0][residence_file][]',
            file.id
          );
        }
      }
    });

    residenceFileBackImage.forEach((file) => {
      if (file instanceof File) {
        return data.append(
          'p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]',
          file
        );
      } else {
        if (!application_number) {
          return data.append(
            'p_application_header[p_applicant_people_attributes][0][residence_file_back_image][]',
            file.id
          );
        }
      }
    });

    //STEP 2 INCOME IMAGES
    residenceIncomeFile.forEach((file) => {
      if (file instanceof File) {
        return data.append(
          'p_application_header[p_applicant_people_attributes][1][residence_file][]',
          file
        );
      } else {
        if (!application_number) {
          return data.append(
            'p_application_header[p_applicant_people_attributes][1][residence_file][]',
            file.id
          );
        }
      }
    });

    residenceIncomeFileBackImage.forEach((file) => {
      if (file instanceof File) {
        return data.append(
          'p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]',
          file
        );
      } else {
        if (!application_number) {
          return data.append(
            'p_application_header[p_applicant_people_attributes][1][residence_file_back_image][]',
            file.id
          );
        }
      }
    });

    //STEP 4 IMAGES
    propertyInformationFile.forEach((file) => {
      if (file instanceof File) {
        return data.append(
          'p_application_header[property_information_file][]',
          file
        );
      } else {
        if (!application_number) {
          return data.append(
            'p_application_header[property_information_file][]',
            file.id
          );
        }
      }
    });

    //STEP 7 IMAGES
    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(
                `p_application_header[p_applicant_people_attributes][0][${key}]`,
                file
              )
            : data.append(
                `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                file
              );
        } else {
          if (!application_number) {
            isNotUploadMulti
              ? data.append(
                  `p_application_header[p_applicant_people_attributes][0][${key}]`,
                  file.id
                )
              : data.append(
                  `p_application_header[p_applicant_people_attributes][0][${key}][]`,
                  file.id
                );
          }
        }
      });
    });
    if (incomeTotalizer) {
      Object.keys(imageStepSevenIncomeTotal).forEach((key) => {
        get(imageStepSevenIncomeTotal, key, []).forEach(
          (file: File | SPImage) => {
            const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
              (item) => item === key
            );
            if (file instanceof File) {
              isNotUploadMulti
                ? data.append(
                    `p_application_header[p_applicant_people_attributes][1][${key}]`,
                    file
                  )
                : data.append(
                    `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                    file
                  );
            } else {
              if (!application_number) {
                isNotUploadMulti
                  ? data.append(
                      `p_application_header[p_applicant_people_attributes][1][${key}]`,
                      file.id
                    )
                  : data.append(
                      `p_application_header[p_applicant_people_attributes][1][${key}][]`,
                      file.id
                    );
              }
            }
          }
        );
      });
    }
    //STEP 8 IMAGES
    businessCard.forEach((file) => {
      if (file instanceof File) {
        return data.append('p_application_header[business_card][]', file);
      } else {
        if (!application_number) {
          return data.append('p_application_header[business_card][]', file.id);
        }
      }
    });

    //hack remove residence_file_back_image
    data.delete(
      'p_application_header[p_applicant_people_attributes][0][residence_file_back_image]'
    );
    data.delete(
      'p_application_header[p_applicant_people_attributes][1][residence_file_back_image]'
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    // CHECK IS UPDATE PRELIMINARY
    if (!!application_number) {
      const result = await dispatch(updatePreliminary(data));
      if (updatePreliminary.fulfilled.match(result)) {
        dispatch(
          addStatus({
            firstElement:
              result.payload.data.general_income_confirmation ===
              GeneralIncomeConfirmation.CONFIRM
                ? true
                : undefined,
            secondElement:
              result.payload.data.general_income_confirmation ===
              GeneralIncomeConfirmation.CONFIRM
                ? true
                : undefined,
            isReseted:
              result.payload.data.general_income_confirmation ===
              GeneralIncomeConfirmation.NOT_CONFIRM,
          })
        );
        setOpenModal(true);
        resetImage();
      }
      if (updatePreliminary.rejected.match(result)) {
        const errorResponse = result.payload as CheckUpdateStatusResponse;
        const errorsField: string[] | undefined = errorResponse.errors?.map(
          (e) => {
            // @ts-ignore
            return SP_FIELDS_TITLE[e.field];
          }
        );
        if (errorsField) {
          setMessageErrors(errorsField);
        } else {
          setReloadImage(reloadImage + 1);
        }
      }
    } else {
      const result = await dispatch(submitFormData(data));
      if (submitFormData.fulfilled.match(result)) {
        resetImage();
        const submittedPreliminary = await dispatch(
          spGetSubmittedPreliminary()
        );
        if (spGetSubmittedPreliminary.fulfilled.match(submittedPreliminary)) {
          if (incomeTotalizer && +currentStep < 13) {
            dispatch(setCurrentStep('13'));
          }
          if (incomeTotalizer && hasJoinGuarantor && +currentStep < 14) {
            dispatch(setCurrentStep('14'));
          }
          if (hasJoinGuarantor && +currentStep < 11) {
            dispatch(setCurrentStep('11'));
          }
          if (+currentStep < 10) {
            dispatch(setCurrentStep('10'));
          }
          dispatch(
            addStatus({
              firstElement:
                submittedPreliminary.payload.data
                  .general_income_confirmation ===
                GeneralIncomeConfirmation.CONFIRM
                  ? true
                  : undefined,
              secondElement:
                submittedPreliminary.payload.data
                  .general_income_confirmation ===
                GeneralIncomeConfirmation.CONFIRM
                  ? true
                  : undefined,
              isReseted:
                submittedPreliminary.payload.data
                  .general_income_confirmation ===
                GeneralIncomeConfirmation.NOT_CONFIRM,
            })
          );
          navigate(routeNames.SPStepTen.path);
        }
      }

      if (submitFormData.rejected.match(result)) {
        const errorResponse = result.payload as CheckUpdateStatusResponse;
        const errorsField: string[] | undefined = errorResponse.errors?.map(
          (e) => {
            // @ts-ignore
            return SP_FIELDS_TITLE[e.field];
          }
        );
        if (errorsField) setMessageErrors(errorsField);
      }
    }
  };

  return (
    <SPStepLayout
      hasStepBar
      hasModalSaveDraft={false}
      footer={{
        left: {
          onClick: () => {
            if (!!application_number) {
              navigate(routeNames.Top.path);
            } else {
              navigate(routeNames.SPStepEight.path);
            }
          },
        },
        right: {
          children: '仮審査を申し込む',
          disabled: submitting,
          onClick: async () => {
            setSubmitting(true);
            try {
              if (
                (!!spStepTwoIncomeTotalValues.status?.firstElement &&
                  !!spStepTwoIncomeTotalValues.status?.secondElement &&
                  isIncomePerson) ||
                !isIncomePerson ||
                (!!application_number &&
                  isIncomePerson &&
                  !spStepTwoIncomeTotalValues.status?.firstElement &&
                  !spStepTwoIncomeTotalValues.status?.secondElement &&
                  !spStepTwoIncomeTotalValues.status?.isReseted)
              ) {
                await onSubmitForm();
              } else {
                setOpen(!open);
              }
            } catch (e) {}
            setSubmitting(false);
          },
        },
      }}
      hasJoinGuarantor={hasJoinGuarantor}
      hasTotalIncome={incomeTotalizer}
    >
      <SPModal
        open={open}
        onClose={() => setOpen(false)}
        icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
        title=""
        disableBackDropClick
        footer={
          <Stack spacing={6} justifyContent="center" alignItems="center">
            <Stack sx={{ overflowY: 'scroll', maxHeight: '37vh' }} spacing={2}>
              <Typography
                variant="sp_change_password_note"
                color="b_333"
                sx={{
                  textAlign: 'center',
                  whiteSpace: 'break-spaces',
                  letterSpacing: '0.6px',
                  lineHeight: '27.2px',
                }}
              >
                {`収入合算者に同意事項にまだ同意し\nていませんので、次へ進めないです。\n STEP 04へ戻して同意と必要情報を入力してください。`}
              </Typography>
            </Stack>
            <SPButton
              sx={{
                width: '200px',
                height: '40px',
                bgcolor: 'sp_primary_40',
              }}
              endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
              onClick={() => {
                navigate(routeNames.SPStepFourIncomeTotalizer.path);
                setOpen(false);
              }}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="sp_primary_100_main"
              >
                収入合算者画面へ
              </Typography>
            </SPButton>
          </Stack>
        }
      />
      <Stack
        sx={{
          bgcolor: 'main_white',
          minHeight: supportDvh('calc(100dvh - 178px)'),
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          sx={{ mt: 9, mb: 10 }}
        >
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
          >
            最後に内容を確認し
          </Typography>
          <Typography
            variant="SP_title_email_screen"
            color="sp_primary_100_main"
          >
            お申込を完了させましょう。
          </Typography>
        </Stack>
        <SPLoanApplyDateInfo />
        <SPStepOneInfo />
        <SPStepTwoInfo reload={reloadImage} />
        <SPStepThreeInfo />
        {incomeTotalizer && p_applicant_people && (
          <SPStepFourIncomeTotalizerInfo reload={reloadImage} />
        )}
        {incomeTotalizer && p_applicant_people && (
          <SPStepFiveIncomeTotalizerInfo />
        )}
        {hasJoinGuarantor && <SPStepFourCollateralProviderInfo />}
        <SPStepFourInfo reload={reloadImage} />
        <SPStepFiveInfo reload={reloadImage} />
        <SPStepSixInfo />
        <SPStepSevenInfo reload={reloadImage} />
        {incomeTotalizer && spStepSevenValues && (
          <SPStepSevenIncomeTotalizerInfo reload={reloadImage} />
        )}
        <SPStepEightInfo reload={reloadImage} />
        {messageErrors.length ? (
          <Stack
            direction="row"
            alignItems="center"
            textAlign="center"
            sx={{
              mt: 7,
              bgcolor: 'sp_secondary_pink_light',
              border: (theme) => `1px solid ${theme?.palette?.sp_secondary_01}`,
              borderRadius: '8px',
              mx: 4,
              px: 4,
              py: 2,
            }}
            spacing={3}
          >
            <Icons.SPWarning />
            <Stack textAlign="left" spacing={1}>
              {!messageErrors.find(
                (e) => e === SP_FIELDS_TITLE.bank_is_conducting_a_review
              ) && (
                <Typography variant="drawerText" color="sp_secondary_01">
                  以下の項目は必須項目です。
                </Typography>
              )}
              <Typography variant="drawerText" color="sp_secondary_01">
                {messageErrors?.map((message: string, index: number) => {
                  return index === messageErrors.length - 1
                    ? message
                    : !!message && `${message}, `;
                })}
              </Typography>
            </Stack>
          </Stack>
        ) : undefined}
        <SPModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          disableBackDropClick
          icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
          title="申込内容修正完了"
          footer={
            <Stack textAlign="center" spacing={6}>
              <Typography variant="sp_modal_note" color="b_333">
                申し込みの内容を修正しました。
              </Typography>
              <SPButton
                sx={{
                  width: '100%',
                  bgcolor: 'sp_primary_100_main',
                }}
                endIcon={<Icons.ArrowForward stroke="white" />}
                onClick={() =>
                  navigate(routeNames.Top.path, {
                    state: { pathName: window.location.pathname },
                  })
                }
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="main_white"
                >
                  TOPへ
                </Typography>
              </SPButton>
            </Stack>
          }
        />
        <SPModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          disableBackDropClick
          icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
          title="申込内容修正完了"
          footer={
            <Stack textAlign="center" spacing={6}>
              <Typography variant="sp_modal_note" color="b_333">
                申し込みの内容を修正しました。
              </Typography>
              <SPButton
                sx={{
                  width: '100%',
                  bgcolor: 'sp_primary_100_main',
                }}
                endIcon={<Icons.ArrowForward stroke="white" />}
                onClick={() =>
                  navigate(routeNames.Top.path, {
                    state: { pathName: window.location.pathname },
                  })
                }
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="main_white"
                >
                  TOPへ
                </Typography>
              </SPButton>
            </Stack>
          }
        />
        <SPModal
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          disableBackDropClick
          icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
          title=""
          footer={
            <Stack
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <Typography variant="sp_modal_note" color="b_333">
                アップロードした書類にエラーがあります。再度アップロードしてください。
              </Typography>
              <SPButton
                sx={{
                  width: '160px',
                  height: '40px',
                  bgcolor: 'sp_primary_40',
                }}
                endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
                onClick={() => setOpenImageModal(false)}
              >
                <Typography
                  variant="SP_multiple_checkbox_label"
                  color="sp_primary_100_main"
                >
                  とじる
                </Typography>
              </SPButton>
            </Stack>
          }
        />
      </Stack>
    </SPStepLayout>
  );
};

export default SPStepNinePage;
