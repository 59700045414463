import { routeNames, RouteProps } from 'navigations/routes';
import { useMemo } from 'react';
import { values } from 'lodash';
import { matchPath, useLocation } from 'react-router-dom';

export const useMatchedRoute = (): RouteProps | undefined => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      values(routeNames).find((route) =>
        matchPath({ path: route.path }, pathname)
      ),
    [pathname]
  );
};
