import { FC, ReactNode, PropsWithChildren, useState, useMemo } from 'react';
import {
  Chip,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Icons, Popover } from 'components';

export type FormItemProps = PropsWithChildren<{
  required?: boolean;
  label: string;
  note?: string | ReactNode;
  divider?: boolean;
  readOnly?: boolean;
  help?: string;
  explain?: ReactNode;
  sx?: SxProps<Theme>;
}>;

export const FormItem: FC<FormItemProps> = ({
  divider,
  required,
  readOnly,
  label,
  note,
  help,
  children,
  explain,
  sx,
}) => {
  return (
    <Stack sx={{ mt: 10, ...sx }}>
      <Stack direction="row" spacing={8} alignItems="center">
        <Label
          readOnly={readOnly}
          required={required}
          label={label}
          help={help}
        />
      </Stack>
      <Stack
        sx={{
          pt: { mobile: 5, tablet: 8 },
          pb: { mobile: 8, tablet: 12 },
        }}
      >
        {children}
        {!!note && (
          <Stack pt={5}>
            {typeof note === 'string' ? (
              <Typography variant="text_note" color="normal_text">
                {note}
              </Typography>
            ) : (
              note
            )}
          </Stack>
        )}
        {explain}
      </Stack>
      {divider && (
        <Divider
          sx={{
            width: '100%',
            height: 2,
            backgroundImage: (theme) =>
              `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
            backgroundSize: '6px 9px',
            backgroundRepeat: 'repeat-x',
            borderBottomWidth: 'inherit',
          }}
        />
      )}
    </Stack>
  );
};
type LabelProps = TypographyProps & {
  label: string | ReactNode;
  required?: boolean;
  readOnly?: boolean;
  help?: string;
};

const Label: FC<LabelProps> = ({ required, readOnly, label, help }) => {
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const { bgcolor } = useMemo(() => {
    if (readOnly) {
      return { bgcolor: 'gray' };
    }
    if (required) {
      return { bgcolor: 'sh_red' };
    }
    return { bgcolor: 'Icon_color' };
  }, [readOnly, required]);
  return (
    <Stack direction="row" spacing={3} alignItems="start">
      <Chip
        label={
          <Typography variant="required_chip_label" color="main_white">
            {required ? '必須' : '任意'}
          </Typography>
        }
        sx={{
          bgcolor,
          borderRadius: 1,
          height: 20,
          width: 36,
          mt: '3px',
          '.MuiChip-label': { px: 1 },
        }}
      />
      <Typography variant="h3" color="normal_text">
        {label}
        {!!help && (
          <Popover
            sx={{ left: -66 }}
            content={help}
            open={openHelp}
            placement="top-start"
            contentProps={{ maxWidth: 212, color: 'normal_text' }}
          >
            <IconButton
              sx={{
                color: 'Icon_color',
                p: 0,
                ml: 2,
                mt: -1,
              }}
              onClick={() => setOpenHelp((prevState) => !prevState)}
            >
              <Icons.Question sx={{ width: 25, height: 25 }} />
            </IconButton>
          </Popover>
        )}
      </Typography>
    </Stack>
  );
};
