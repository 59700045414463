import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep12: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 32">
      <svg
        width="24"
        height="32"
        viewBox="0 0 24 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 16.5C7.15625 16.5 8.5 17.8438 8.5 19.5C8.5 21.1562 7.15625 22.5 5.5 22.5H4.5V25C4.5 25.275 4.27625 25.5 4 25.5C3.72375 25.5 3.5 25.275 3.5 25V17C3.5 16.725 3.72375 16.5 4 16.5H5.5ZM7.5 19.5C7.5 18.3937 6.60625 17.5 5.5 17.5H4.5V21.5H5.5C6.60625 21.5 7.5 20.6063 7.5 19.5ZM10.5 17C10.5 16.725 10.725 16.5 11 16.5H12.5C13.8813 16.5 15 17.6187 15 19V23C15 24.3813 13.8813 25.5 12.5 25.5H11C10.725 25.5 10.5 25.275 10.5 25V17ZM11.5 17.5V24.5H12.5C13.3313 24.5 14 23.8312 14 23V19C14 18.1688 13.3313 17.5 12.5 17.5H11.5ZM21 16.5C21.275 16.5 21.5 16.725 21.5 17C21.5 17.275 21.275 17.5 21 17.5H18.5V20.5H21C21.275 20.5 21.5 20.725 21.5 21C21.5 21.275 21.275 21.5 21 21.5H18.5V25C18.5 25.275 18.275 25.5 18 25.5C17.725 25.5 17.5 25.275 17.5 25V17C17.5 16.725 17.725 16.5 18 16.5H21ZM12.7563 0C13.55 0 14.3188 0.316063 14.8813 0.87875L23.1187 9.11875C23.6812 9.68125 24 10.45 24 11.2437V28C24 30.2062 22.2062 32 20 32H4C1.79063 32 0 30.2062 0 28V4C0 1.79063 1.79063 0 4 0H12.7563ZM4 31H20C21.6562 31 23 29.6562 23 28V11.2437C23 11.1625 22.9937 11.0813 22.9875 11H15.5C14.1187 11 13 9.88125 13 8.5V1.015C12.9187 1.005 12.8375 1 12.7563 1H4C2.34312 1 1 2.34312 1 4V28C1 29.6562 2.34312 31 4 31ZM14.1687 1.58563C14.1187 1.53125 14.0063 1.48 14 1.43313V8.5C14 9.33125 14.6687 10 15.5 10H22.5688C22.5188 9.9375 22.4688 9.88125 22.4125 9.83125L14.1687 1.58563Z"
          fill="url(#paint0_linear_6976_59186)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6976_59186"
            x1="3.69582"
            y1="25.4189"
            x2="27.2356"
            y2="7.58584"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
