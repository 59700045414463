import { regex, yup } from 'libs';

export const validationSchemaLogin = yup.object({
  email: yup.string().email().required().label('登録メールアドレス'),
  password: yup
    .string()
    .required()
    .min(8, '8文字以上のパスワードを入力してください。')
    .max(20, 'パスワードは20桁以内で設定ください。')
    .matches(
      regex.password,
      'パスワードは大文字、小文字、数字をそれぞれ1つ以上で設定してください。'
    )
    .label('パスワード'),
});

export const validationSchemaSignUp = yup.object({
  email: yup.string().email().required().label('登録メールアドレス'),
  confirm_email: yup
    .string()
    .email()
    .required()
    .when('email', {
      is: (val: string) => !!val?.length,
      then: yup.string().oneOf([yup.ref('email')]),
    })
    .label('登録メールアドレス（確認用）'),
  password: yup
    .string()
    .required()
    .min(8, '8文字以上のパスワードを入力してください。')
    .max(20, 'パスワードは20桁以内で設定ください。')
    .matches(
      regex.password,
      'パスワードは大文字、小文字、数字をそれぞれ1つ以上で設定してください。'
    )
    .label('パスワード'),
  password_confirmation: yup
    .string()
    .required()
    .when('password', {
      is: (val: string) => !!val?.length,
      then: yup.string().oneOf([yup.ref('password')]),
    })
    .min(8)
    .label('パスワード（確認用）'),
});
