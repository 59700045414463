import { AdminPreliminaries } from 'services';
import { get } from 'lodash';

type ResidentsAttributes =
  AdminPreliminaries['p_application_header']['p_residents_attributes'];

export const buildRemoveResidentsAttributes = (
  residentsAttributesOriginal: ResidentsAttributes,
  residentsAttributesChanged: ResidentsAttributes
): ResidentsAttributes => {
  const removedResidentsAttributes: ResidentsAttributes = [];
  residentsAttributesOriginal.forEach((row) => {
    const index = residentsAttributesChanged.findIndex(
      ({ id }) => id === row.id
    );
    if (index < 0) {
      removedResidentsAttributes.push({ ...row, _destroy: true });
    }
  });

  return [...residentsAttributesChanged, ...removedResidentsAttributes];
};

export function removeObject<T>(dataOriginal: T[], dataChanged: T[]): T[] {
  const removedData: T[] = [];
  dataOriginal.forEach((row) => {
    const index = dataChanged.findIndex(
      (data) => get(row, 'id') === get(data, 'id')
    );
    if (index < 0) {
      removedData.push({ ...row, _destroy: true });
    }
  });
  return [...dataChanged, ...removedData];
}
