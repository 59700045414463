import { FC, useCallback, useEffect, useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import { FormikProvider, getIn, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppView, useDebouncedValidate } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepLayout } from 'containers';
import { routeNames } from 'navigations/routes';
import { convertManToYen, flattenToLodashFormat } from 'utils';
import { ApplicantDetailType, ApplicantType, LoanType } from 'constant';
import { handleNewSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { PBorrowingAttribute, StepSynthesisForm } from 'types';
import { Button } from 'components';
import { validationSchema } from './validationSchema';
import {
  checkMCJ,
  convertSynthesisSelector,
  isEditingSelector,
  isInitialValidSynthesisSelector,
  stepSynthesisSelector,
} from './selectors';
import { saveForm } from './slice';
import StepOnePageB from './StepOneB';
import StepThreePageD from './StepThreeD';
import StepTwoPageB from './StepTwoB';
import StepThreePageE from './StepThreeE';
import StepThreeDOtherPage from './StepThreeDOther';
import StepThreePageEOther from './StepThreeEOther';
import StepOnePageC from './StepOneC';
import StepResidents from './StepResidents';
import StepOtherResidents from './StepOtherResidents';
import StepOnePageE from './StepOneE';
import StepOnePageF from './StepOneF';
import StepOnePageG from './StepOneG';
import StepRelativesPage from './StepRelatives';
import StepOtherRelatives from './StepOtherRelatives';
import StepCurrentLoan from './StepCurrentLoan';

const StepOneRequiredInformationPage: FC = () => {
  const dispatch = useAppDispatch();
  const initialValues = useSelector(stepSynthesisSelector);
  const isInitialValid = useSelector(isInitialValidSynthesisSelector);
  const isMCJ = useSelector(checkMCJ);
  const convertedFields = useSelector(convertSynthesisSelector);
  const isEditing = useSelector(isEditingSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const stepThreeD = useRef<HTMLDivElement>(null);
  const StepThreeE = useRef<HTMLDivElement>(null);
  const StepResident = useRef<HTMLDivElement>(null);
  const StepOneB = useRef<HTMLDivElement>(null);
  const StepOneC = useRef<HTMLDivElement>(null);
  const StepTwoB = useRef<HTMLDivElement>(null);
  const StepThreeDOther = useRef<HTMLDivElement>(null);
  const StepThreeEOther = useRef<HTMLDivElement>(null);
  const StepOtherResident = useRef<HTMLDivElement>(null);
  const StepOneE = useRef<HTMLDivElement>(null);
  const StepOneF = useRef<HTMLDivElement>(null);
  const StepOneG = useRef<HTMLDivElement>(null);

  const { state } = useAppView<string, ApplicantType>();

  useEffect(() => {
    switch (state?.label) {
      case '申込人様について':
        switch (state.note) {
          case ApplicantType.PAIR:
            StepThreeDOther.current?.scrollIntoView({ behavior: 'smooth' });
            break;
          default:
            stepThreeD.current?.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'ご職業について':
        switch (state.note) {
          case ApplicantType.PAIR:
            StepThreeEOther.current?.scrollIntoView({ behavior: 'smooth' });
            break;
          default:
            StepThreeE.current?.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case 'お住まいについて':
        switch (state.note) {
          case ApplicantType.PAIR:
            StepOtherResident.current?.scrollIntoView({ behavior: 'smooth' });
            break;
          default:
            StepResident.current?.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '物件について':
        StepTwoB.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'お借入について':
        switch (state.note) {
          case ApplicantType.PAIR:
            StepOneC.current?.scrollIntoView({ behavior: 'smooth' });
            break;
          default:
            StepOneB.current?.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '必要資金内訳（税込）':
        StepOneE.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case '調達資金内訳':
        StepOneF.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case '提携会社（不動産会社・住宅メーカー等）について':
        StepOneG.current?.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }, [state]);

  const convertCurrency = useCallback((values: StepSynthesisForm) => {
    const p_borrowings_attributes =
      values.main_p_applicant_person.p_borrowings_attributes?.map(
        (borrowing) => {
          const [loan_amount, current_balance_amount, annual_repayment_amount] =
            convertManToYen([
              borrowing.loan_amount,
              borrowing.current_balance_amount,
              (borrowing as PBorrowingAttribute).annual_repayment_amount,
            ]);
          return {
            ...borrowing,
            loan_amount,
            current_balance_amount,
            ...(annual_repayment_amount && {
              annual_repayment_amount,
            }),
          };
        }
      );

    const [
      land_purchase_price,
      house_purchase_price,
      accessory_cost,
      additional_cost,
      refinancing_loan_balance,
      other_require_funds_breakdown,
      temporary_desired_loan_amount,
      saving_amount,
      relative_donation_amount,
      loan_amount,
      pair_loan_amount,
      other_procurement_breakdown,
      halfyear_bonus,
      p_land_advance_plan_desired_loan_amount,
      p_land_advance_plan_halfyear_bonus,
      pair_temporary_desired_loan_amount,
      pair_halfyear_bonus,
      house_upgrade_cost,
      deposit_savings_1,
      deposit_savings_2,
      real_estate_sale_price,
      other_saving_amount,
      total_require_funds,
      total_procurement,
    ] = convertManToYen([
      values.p_application_header.land_purchase_price,
      values.p_application_header.house_purchase_price,
      values.p_application_header.accessory_cost,
      values.p_application_header.additional_cost,
      values.p_application_header.refinancing_loan_balance,
      values.p_application_header.other_require_funds_breakdown,
      values.main_p_applicant_person.p_borrowing_detail_attributes
        .temporary_desired_loan_amount,
      values.p_application_header.saving_amount,
      values.p_application_header.relative_donation_amount,
      values.p_application_header.loan_amount,
      values.p_application_header.pair_loan_amount,
      values.p_application_header.other_procurement_breakdown,
      values.main_p_applicant_person.p_borrowing_detail_attributes
        .halfyear_bonus,
      values.main_p_applicant_person.p_land_advance_plan_attributes
        .desired_loan_amount,
      values.main_p_applicant_person.p_land_advance_plan_attributes
        .halfyear_bonus,
      values.pair_p_applicant_person.p_borrowing_detail_attributes
        .temporary_desired_loan_amount,
      values.pair_p_applicant_person.p_borrowing_detail_attributes
        .halfyear_bonus,
      values.p_application_header.house_upgrade_cost,
      values.p_application_header.deposit_savings_1,
      values.p_application_header.deposit_savings_2,
      values.p_application_header.real_estate_sale_price,
      values.p_application_header.other_saving_amount,
      values.p_application_header.total_require_funds,
      values.p_application_header.total_procurement,
    ]);
    return {
      ...values,
      p_application_header: {
        ...values.p_application_header,
        land_purchase_price,
        house_purchase_price,
        accessory_cost,
        additional_cost,
        refinancing_loan_balance,
        other_require_funds_breakdown,
        saving_amount,
        relative_donation_amount,
        loan_amount,
        pair_loan_amount,
        other_procurement_breakdown,
        house_upgrade_cost,
        deposit_savings_1,
        deposit_savings_2,
        real_estate_sale_price,
        other_saving_amount,
        total_require_funds,
        total_procurement,
      },
      main_p_applicant_person: {
        ...values.main_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...values.main_p_applicant_person.p_borrowing_detail_attributes,
          temporary_desired_loan_amount,
          halfyear_bonus,
        },
        p_land_advance_plan_attributes: {
          ...values.main_p_applicant_person.p_land_advance_plan_attributes,
          desired_loan_amount: p_land_advance_plan_desired_loan_amount,
          halfyear_bonus: p_land_advance_plan_halfyear_bonus,
        },
        p_borrowings_attributes,
      },
      pair_p_applicant_person: {
        ...values.pair_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...values.pair_p_applicant_person.p_borrowing_detail_attributes,
          temporary_desired_loan_amount: pair_temporary_desired_loan_amount,
          halfyear_bonus: pair_halfyear_bonus,
        },
      },
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      p_application_header: {
        ...initialValues.p_application_header,
        land_purchase_price: convertedFields.land_purchase_price,
        house_purchase_price: convertedFields.house_purchase_price,
        accessory_cost: convertedFields.accessory_cost,
        additional_cost: convertedFields.additional_cost,
        refinancing_loan_balance: convertedFields.refinancing_loan_balance,
        other_require_funds_breakdown:
          convertedFields.other_require_funds_breakdown,
        saving_amount: convertedFields.saving_amount,
        relative_donation_amount: convertedFields.relative_donation_amount,
        loan_amount: convertedFields.loan_amount,
        pair_loan_amount: convertedFields.pair_loan_amount,
        other_procurement_breakdown:
          convertedFields.other_procurement_breakdown,
        house_upgrade_cost: convertedFields.house_upgrade_cost,
        deposit_savings_1: convertedFields.deposit_savings_1,
        deposit_savings_2: convertedFields.deposit_savings_2,
        real_estate_sale_price: convertedFields.real_estate_sale_price,
        other_saving_amount: convertedFields.other_saving_amount,
        total_require_funds: convertedFields.total_require_funds,
        total_procurement: convertedFields.total_procurement,
      },
      main_p_applicant_person: {
        ...initialValues.main_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...initialValues.main_p_applicant_person
            .p_borrowing_detail_attributes,
          temporary_desired_loan_amount:
            convertedFields.temporary_desired_loan_amount,
          halfyear_bonus: convertedFields.halfyear_bonus,
        },
        p_land_advance_plan_attributes: {
          ...initialValues.main_p_applicant_person
            .p_land_advance_plan_attributes,
          desired_loan_amount:
            convertedFields.p_land_advance_plan_desired_loan_amount,
          halfyear_bonus: convertedFields.p_land_advance_plan_halfyear_bonus,
        },
        p_borrowings_attributes: convertedFields.p_borrowings_attributes,
      },
      pair_p_applicant_person: {
        ...initialValues.pair_p_applicant_person,
        p_borrowing_detail_attributes: {
          ...initialValues.pair_p_applicant_person
            .p_borrowing_detail_attributes,
          temporary_desired_loan_amount:
            convertedFields.pair_temporary_desired_loan_amount,
          halfyear_bonus: convertedFields.pair_halfyear_bonus,
        },
      },
    },
    enableReinitialize: true,
    validationSchema,
    isInitialValid,
    initialTouched: {
      p_application_header: {
        saving_amount: isMCJ,
        total_require_funds: true,
        total_procurement: true,
      },
    },
    validateOnChange: false,
    async onSubmit(values, { setSubmitting }) {
      await dispatch(saveForm(convertCurrency(values)));
      setSubmitting(false);
      navigate(routeNames.StepFourA.path);
    },
  });

  useDebouncedValidate({
    validate: formik.validateForm,
    debounceTime: 200,
    values: formik.values,
  });

  const onSaveDraft = async () => {
    await dispatch(
      handleNewSaveDraft({
        ...convertCurrency(formik.values),
        current_path: location.pathname,
      })
    );
    return;
  };

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  return (
    <StepLayout
      isEditing={isEditing}
      title="申込内容修正"
      footer={{
        left: {
          onClick: () => {
            if (isEditing) {
              return navigate(routeNames.Dashboard.path);
            }
            dispatch(saveForm(convertCurrency(formik.values)));
            navigate(routeNames.StepRequiredInformation.path);
          },
        },
        right: {
          children: '次へすすむ',
          onClick: () => formik.handleSubmit(),
        },
      }}
      onSaveDraft={onSaveDraft}
    >
      <FormikProvider value={formik}>
        <Stack justifyContent="center">
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="leafletOption"
              sx={{ pt: '36px', pb: '18px' }}
              lineHeight="28px"
              ref={stepThreeD}
            >
              申込人様について
            </Typography>
            <Button
              sx={{
                py: 0,
                mt: { mobile: '18px', tablet: '36px' },
                width: { mobile: 120, tablet: 148 },
                minHeight: { mobile: 40, tablet: 50 },
                height: { mobile: 40, tablet: 50 },
                borderRadius: '5px',
                bgcolor: 'sh_red',
              }}
              fullWidth
              onClick={() =>
                navigate(routeNames.StepRequiredInformation.path, {
                  state: {
                    label: '申込人様について',
                  },
                })
              }
            >
              <Typography variant="button_title_review" color="main_white">
                修正する
              </Typography>
            </Button>
          </Stack>
          <StepThreePageD />
          <Typography
            variant="leafletOption"
            sx={{ pt: '250px', pb: '18px' }}
            lineHeight="28px"
            ref={StepThreeE}
          >
            ご職業について
          </Typography>
          <StepThreePageE />
          <Typography
            variant="leafletOption"
            sx={{ pt: '250px', pb: '18px' }}
            lineHeight="28px"
            ref={StepResident}
          >
            お住まいについて
          </Typography>
          <StepResidents />
          {isMCJ &&
            formik.values.main_p_applicant_person.applicant_detail_type ===
              ApplicantDetailType.MAIN && <StepRelativesPage />}
          <Stack
            sx={{ pt: '200px', pb: '22px' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="leafletOption"
              lineHeight="28px"
              ref={StepOneB}
            >
              お借入れについて
            </Typography>
            <Button
              sx={{
                py: 0,
                width: { mobile: 120, tablet: 148 },
                minHeight: { mobile: 40, tablet: 50 },
                height: { mobile: 40, tablet: 50 },
                borderRadius: '5px',
                bgcolor: 'sh_red',
              }}
              fullWidth
              onClick={() =>
                navigate(routeNames.StepRequiredInformation.path, {
                  state: {
                    label: 'お借入れについて',
                  },
                })
              }
            >
              <Typography variant="button_title_review" color="main_white">
                修正する
              </Typography>
            </Button>
          </Stack>
          <StepOnePageB />
          <Typography
            variant="leafletOption"
            sx={{ pt: '152px', pb: '10px' }}
            lineHeight="28px"
            ref={StepTwoB}
          >
            物件について
          </Typography>
          <StepTwoPageB />
          {formik.values.p_application_header.loan_type === LoanType.TWO && (
            <>
              <Stack>
                <Stack
                  bgcolor="pale_blue"
                  sx={{
                    width: { mobile: '100%' },
                    mt: '250px',
                    py: 5,
                    pl: 5,
                    borderRadius: '5px',
                  }}
                >
                  <Typography variant="textButtonTopPage" color="normal_text">
                    「ペアローンの申込人」の方について入力してください。
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Typography
                  variant="leafletOption"
                  sx={{ pt: '50px', pb: '18px' }}
                  lineHeight="28px"
                  ref={StepThreeDOther}
                >
                  ペアローンの申込人様について
                </Typography>
                <Button
                  sx={{
                    py: 0,
                    mt: { mobile: '18px', tablet: '36px' },
                    width: { mobile: 120, tablet: 148 },
                    minHeight: 50,
                    height: 50,
                    borderRadius: '5px',
                    bgcolor: 'sh_red',
                    whiteSpace: 'nowrap',
                  }}
                  fullWidth
                  onClick={() =>
                    navigate(routeNames.StepRequiredInformation.path, {
                      state: {
                        label: '申込人様について',
                        note: ApplicantType.PAIR,
                      },
                    })
                  }
                >
                  <Typography variant="button_title_review" color="main_white">
                    修正する
                  </Typography>
                </Button>
              </Stack>
              <StepThreeDOtherPage />
              <Typography
                variant="leafletOption"
                sx={{ pt: '250px', pb: '18px' }}
                lineHeight="28px"
                ref={StepThreeEOther}
              >
                ご職業について
              </Typography>
              <StepThreePageEOther />
              <Typography
                variant="leafletOption"
                sx={{ pt: '250px', pb: '18px' }}
                lineHeight="28px"
                ref={StepOtherResident}
              >
                お住まいについて
              </Typography>
              <StepOtherResidents />
              {isMCJ &&
                formik.values.pair_p_applicant_person.applicant_detail_type ===
                  ApplicantDetailType.PAIR && <StepOtherRelatives />}
              {formik.values.p_application_header.loan_type ===
                LoanType.TWO && (
                <>
                  <Stack
                    sx={{ pt: '200px', pb: '22px' }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="leafletOption"
                      lineHeight="28px"
                      ref={StepOneC}
                    >
                      お借入れについて
                    </Typography>
                    <Button
                      sx={{
                        py: 0,
                        width: { mobile: 120, tablet: 148 },
                        minHeight: { mobile: 40, tablet: 50 },
                        height: { mobile: 40, tablet: 50 },
                        borderRadius: '5px',
                        bgcolor: 'sh_red',
                      }}
                      fullWidth
                      onClick={() =>
                        navigate(routeNames.StepRequiredInformation.path, {
                          state: {
                            label: 'お借入れについて',
                            note: ApplicantType.PAIR,
                          },
                        })
                      }
                    >
                      <Typography
                        variant="button_title_review"
                        color="main_white"
                      >
                        修正する
                      </Typography>
                    </Button>
                  </Stack>
                  <StepOnePageC />
                </>
              )}
            </>
          )}
          {(formik.values.p_application_header.loan_type === LoanType.THREE ||
            formik.values.p_application_header.loan_type === LoanType.FOUR) && (
            <>
              <Stack>
                <Stack
                  bgcolor="pale_blue"
                  sx={{
                    width: { mobile: '100%' },
                    mt: '250px',
                    py: 5,
                    pl: 5,
                    borderRadius: '5px',
                  }}
                >
                  <Typography variant="textButtonTopPage" color="normal_text">
                    「収入合算者」の方について入力してください。
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Typography
                  variant="leafletOption"
                  sx={{ pt: '50px', pb: '18px' }}
                  lineHeight="28px"
                  ref={StepThreeDOther}
                >
                  収入合算者について
                </Typography>
                <Button
                  sx={{
                    py: 0,
                    width: { mobile: 120, tablet: 148 },
                    minHeight: { mobile: 40, tablet: 50 },
                    height: { mobile: 40, tablet: 50 },
                    borderRadius: '5px',
                    bgcolor: 'sh_red',
                  }}
                  fullWidth
                  onClick={() =>
                    navigate(routeNames.StepRequiredInformation.path, {
                      state: {
                        label: '申込人様について',
                        note: ApplicantType.PAIR,
                      },
                    })
                  }
                >
                  <Typography variant="button_title_review" color="main_white">
                    修正する
                  </Typography>
                </Button>
              </Stack>
              <StepThreeDOtherPage />
              <Typography
                variant="leafletOption"
                sx={{ pt: '250px', pb: '18px' }}
                lineHeight="28px"
                ref={StepThreeEOther}
              >
                ご職業について
              </Typography>
              <StepThreePageEOther />
            </>
          )}
          <Typography
            variant="leafletOption"
            sx={{ pt: '250px', pb: '22px' }}
            lineHeight="28px"
          >
            必要資金内訳（税込）
          </Typography>
          <StepOnePageE />
          <Typography
            variant="leafletOption"
            sx={{ pt: '250px', pb: '22px' }}
            lineHeight="28px"
            ref={StepOneE}
          >
            調達資金内訳
          </Typography>
          <StepOnePageF />
          <Typography
            variant="leafletOption"
            sx={{ pt: '250px' }}
            lineHeight="28px"
          >
            現在ご利用中のローン等について
          </Typography>
          <StepCurrentLoan />
          <Typography
            variant="leafletOption"
            sx={{ pt: '250px' }}
            lineHeight="28px"
            ref={StepOneF}
          >
            提携会社（不動産会社・住宅メーカー等）について
          </Typography>
          <Typography
            variant="textButtonTopPage"
            sx={{ pt: '50px', pb: '10px' }}
            lineHeight="28px"
            color="attention"
            ref={StepOneG}
          >
            ※以下項目にご記入いただくか、お名刺のアップロードをお願いいたします。
          </Typography>
          <StepOnePageG />
        </Stack>
      </FormikProvider>
    </StepLayout>
  );
};

export default StepOneRequiredInformationPage;
