import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPWarningModal: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 36 31" {...props}>
      <svg
        width="36"
        height="31"
        viewBox="0 0 36 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0116 19.671C18.3126 19.671 18.5589 19.4255 18.5589 19.1246V8.19624C18.5589 7.8953 18.3126 7.64983 18.0116 7.64983C17.7107 7.64983 17.4661 7.8953 17.4661 8.19624V19.1246C17.4652 19.4251 17.7111 19.671 18.0116 19.671ZM35.1076 26.2963L20.5388 1.43434C19.9787 0.478114 18.9952 0 18.0116 0C17.0349 0 16.0445 0.478114 15.423 1.43434L0.860997 26.2963C-0.19905 28.2019 1.19896 30.5993 3.44555 30.5993H32.5832C34.8139 30.5993 36.2278 28.2087 35.1076 26.2963ZM34.1719 28.5981C33.844 29.165 33.2498 29.5065 32.5805 29.5065H3.44486C2.77625 29.5065 2.18196 29.1662 1.85547 28.596C1.53596 28.0385 1.53849 27.4014 1.86254 26.8488L16.427 1.98759C16.7549 1.42751 17.3491 1.09283 18.0116 1.09283C18.6741 1.09283 19.2643 1.42662 19.5921 1.98691L34.161 26.8488C34.4861 27.4028 34.4861 28.038 34.1719 28.5981ZM18.0185 22.9495C17.4159 22.9495 16.9256 23.4397 16.9256 24.0423C16.9256 24.6449 17.4132 25.1351 18.0157 25.1351C18.6183 25.1351 19.1113 24.6449 19.1113 24.0423C19.1113 23.4397 18.6195 22.9495 18.0185 22.9495Z"
          fill="url(#paint0_linear_8106_10047)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8106_10047"
            x1="5.88973"
            y1="24.3062"
            x2="29.0306"
            y2="-2.42978"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={!!props.stopColor ? props.stopColor : '#3C48C4'} />
            <stop
              offset="0.505208"
              stopColor={!!props.stopColor ? props.stopColor : '#3C48C4'}
            />
            <stop
              offset="0.9999"
              stopColor={!!props.stopColor ? props.stopColor : '#3C48C4'}
            />
            <stop
              offset="1"
              stopColor={!!props.stopColor ? props.stopColor : '#3C48C4'}
            />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
