import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Avatar, Drawer, Grid, Stack, Typography } from '@mui/material';
import { Button, Icons, SPButton, SPInputField } from 'components';
import { hiddenScrollBar } from 'styles/toolkit';
import { spInfo, spMilize } from 'assets';
import { FormikProvider, useFormik } from 'formik';
import { SenderType } from 'constant';
import { useSelector } from 'react-redux';
import { useAppDispatch, usePrevious } from 'hooks';
import {
  downloadFile,
  formatTimeMessage,
  getFileExtFromUrl,
  getParts,
} from 'utils';
import { getUnreadMessages } from 'pages/SPTop/thunk';
import { messagesSelectors, totalUnreadSelectors } from './selectors';
import { createMessage, getMessages } from './thunk';
import { validationSchema } from './validationSchema';
import { readMessages } from './slice';

interface SPMessageProps {
  senderType: SenderType;
  senderName: string;
  content: string;
  createdAt?: string;
  avatar?: string;
  messageFile: { filename: string; url: string }[];
}
export const SPChat: FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [newMessageIndex, setNewMessageIndex] = useState<number>(0);
  const bottomRef = useRef<HTMLDivElement>(null);
  const messages = useSelector(messagesSelectors.selectAll);
  const prevMessageCount = usePrevious(messages.length);

  const totalUnread = useSelector(totalUnreadSelectors);
  const handleToggleDrawer = () => {
    if (open) {
      dispatch(readMessages());
      setNewMessageIndex(0);
    }
    setOpen((prevState) => !prevState);
  };

  const convertTextToHtmlWithLineBreaks = (text: string) => {
    let html = encodeHtmlEntities(text);
    return replaceNewlinesWithLineBreaks(html);
  };

  const convertLinkToMarkdownLink = (text: string) => {
    const regex = /\[.+\]\(.+\)/g;
    if (regex.test(text)) {
      return text;
    }
    return text
      .split(' ')
      .map((val) => {
        const regex = /\[.+\]\(.+\)/g;
        if (regex.test(val)) {
          return val;
        }
        const regexLink = /(https?:\/\/[^\s]+)/g;
        if (regexLink.test(val)) {
          const [link] = val.match(regexLink) ?? [];
          if (!link) return val;
          let domain = new URL(link);
          return val.replace(link, `[${domain.hostname}](${link})`);
        }
        return val;
      })
      .join(' ');
  };

  const encodeHtmlEntities = (text: string) => {
    let paragraph = document.createElement('span');
    paragraph.textContent = text;

    return paragraph.outerHTML;
  };

  const replaceNewlinesWithLineBreaks = (html: string) => {
    return formatLink(html.replace(/\r?\n/g, '<br>'));
  };

  const formatLink = (html: string) => {
    const links = getParts(html, '[', ')');

    let result = html;
    links.forEach((link) => {
      let reformat = `[${link})`;
      let shortenURL = getParts(reformat, '[', ']');
      let url = getParts(reformat, '(', ')');

      result = result.replace(
        reformat,
        `<a href='${url[0]}' target='_blank' rel='noopener noreferrer'>${shortenURL[0]}</a>`
      );
    });

    return result;
  };

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema,
    async onSubmit(values, { setSubmitting, resetForm }) {
      const formatMessage = convertTextToHtmlWithLineBreaks(
        convertLinkToMarkdownLink(values.message.trim())
      );
      const result = await dispatch(
        createMessage({ message: { content: formatMessage } })
      );
      if (createMessage.fulfilled.match(result)) {
        setSubmitting(false);
        resetForm();
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        setNewMessageIndex(0);
      }
    },
  });

  useEffect(() => {
    if (open) {
      dispatch(getMessages());
    }
  }, [dispatch, open]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open || messages.length) {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [bottomRef, messages.length, open]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (open) {
        dispatch(getMessages());
      } else {
        dispatch(getUnreadMessages());
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [dispatch, open]);

  useEffect(() => {
    if (
      open &&
      !newMessageIndex &&
      !!prevMessageCount &&
      prevMessageCount < messages.length
    ) {
      if (messages.length && !!totalUnread) {
        //Check case user at Top page and new message coming
        setNewMessageIndex(messages.length - totalUnread);
      }
      if (messages[messages.length - 1].sender_type !== SenderType.USER) {
        //Check case user at Chat screen and new message coming
        setNewMessageIndex(prevMessageCount);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, prevMessageCount, totalUnread, open]);

  return (
    <FormikProvider value={formik}>
      <Stack
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!!formik.values.message.trim() && !formik.isSubmitting) {
              formik.handleSubmit();
            }
          }
        }}
      >
        {!open && (
          <Fragment>
            <SPButton
              sx={{
                position: 'fixed',
                bottom: 0,
                left: '50%',
                transform: 'translate(-50%, 0)',
                background:
                  'linear-gradient(44.71deg, #3C48C4 18%, #6A75DE 56.8%, #AD92D0 94.79%, #AD92D0 94.79%)',
                borderTop: '6px solid #F1F6FD',
                borderLeft: '6px solid #F1F6FD',
                borderRight: '6px solid #F1F6FD',
                borderRadius: '20px 20px 0px 0px',
                px: 5,
                py: '10px',
                width: 272,
              }}
              onClick={handleToggleDrawer}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Avatar
                  variant="square"
                  src={spInfo}
                  sx={{
                    width: 22,
                    height: 22,
                    '.MuiAvatar-img': {
                      objectFit: 'contain',
                    },
                  }}
                />
                <Typography variant="buttonAddImage" color="main_white">
                  みらいバンクとチャット連絡
                </Typography>
              </Stack>
            </SPButton>
            {!!totalUnread && (
              <Fragment>
                <Stack
                  sx={{
                    position: 'fixed',
                    bottom: 48,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1,
                    bgcolor: 'sp_secondary_01',
                    py: 1,
                    borderRadius: '6px',
                    width: 200,
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="sp_12_bold_100" color="main_white">
                    新着メッセージが届いています！
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    position: 'fixed',
                    bottom: 40,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                  }}
                >
                  <Icons.SPNotiChatBubble />
                </Stack>
              </Fragment>
            )}
          </Fragment>
        )}
        <Drawer
          sx={{
            '.MuiDrawer-paper': {
              borderTopLeftRadius: 14,
              borderTopRightRadius: 14,
              maxWidth: 480,
              mx: 'auto',
            },
          }}
          anchor="bottom"
          open={open}
          onClose={handleToggleDrawer}
        >
          <Stack
            sx={{
              height: '100vh',
              width: '100%',
              maxWidth: 480,
              bgcolor: 'sp_bg_gray',
            }}
          >
            <Stack
              sx={{
                bgcolor: 'sp_bg_gray',
                borderRadius: '14px 14px 0px 0px',
                pt: 12,
                pb: '90px',
                ...hiddenScrollBar,
              }}
              spacing={3}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bgcolor: 'main_white',
                  boxShadow: '0px 2px 10px rgba(60, 72, 196, 0.1)',
                  py: '11px',
                  borderRadius: '14px 14px 0px 0px',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  maxWidth: 480,
                  mx: 'auto',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: 480,
                    mx: 'auto',
                  }}
                >
                  <Typography
                    variant="sp_modal_title"
                    color="sp_primary_100_main"
                  >
                    メッセージ
                  </Typography>
                  <Button
                    sx={{
                      borderRadius: '2px',
                      bgcolor: 'transparent',
                      minHeight: 44,
                      minWidth: 44,
                      position: 'absolute',
                      right: 0,
                      boxShadow: 'none',
                    }}
                    onClick={handleToggleDrawer}
                  >
                    <Stack justifyContent="center" alignItems="center">
                      <Icons.SPArrowDownCircle />
                    </Stack>
                  </Button>
                </Stack>
              </Stack>
              {messages.map((message, index) => {
                if (index === newMessageIndex && !!index)
                  return (
                    <Fragment key={index}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ px: 2, pt: '14px' }}
                      >
                        <Stack
                          sx={{
                            width: '100%',
                            height: 0,
                            border: '1px dashed',
                            borderColor: 'sp_secondary_01',
                          }}
                        />
                        <Stack
                          sx={{
                            width: '100%',
                            px: 3,
                          }}
                        >
                          <Typography
                            variant="sp_12_bold_100"
                            color="sp_secondary_01"
                            sx={{ whiteSpace: 'nowrap' }}
                          >
                            ここから未読メッセージ
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            width: '100%',
                            height: 0,
                            border: '1px dashed',
                            borderColor: 'sp_secondary_01',
                          }}
                        />
                      </Stack>
                      <SPMessage
                        key={index}
                        senderType={message.sender_type}
                        senderName={message.sender_name}
                        createdAt={formatTimeMessage(message.created_at)}
                        content={message.content}
                        avatar={message.avatar_company.url}
                        messageFile={message.message_file}
                      />
                    </Fragment>
                  );
                return (
                  <SPMessage
                    key={index}
                    senderType={message.sender_type}
                    senderName={message.sender_name}
                    createdAt={formatTimeMessage(message.created_at)}
                    content={message.content}
                    avatar={message.avatar_company.url}
                    messageFile={message.message_file}
                  />
                );
              })}
              <Stack ref={bottomRef} />
              <Stack
                direction="row"
                alignItems="flex-end"
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  px: 4,
                  py: 3,
                  bgcolor: 'main_white',
                  width: 'calc(100% - 32px)',
                  maxWidth: 480 - 32,
                  mx: 'auto',
                }}
                spacing={3}
              >
                <Stack sx={{ width: '100%' }}>
                  <SPInputField
                    name="message"
                    placeholder="メッセージを入力"
                    multiline
                    maxRows={5}
                    sx={{
                      '&.MuiTextField-root': {
                        maxHeight: 'none',
                        width: '100%',
                      },
                      '&& .MuiInputBase-multiline': {
                        padding: 0,
                        alignItems: 'flex-end',
                        maxHeight: 'none',
                        textarea: {
                          py: 3,
                        },
                      },
                      '&&& .MuiInputBase-input': {
                        maxHeight: 'none',
                      },
                    }}
                  />
                </Stack>
                <Stack sx={{ pb: 1 }}>
                  <SPButton
                    sx={{
                      minWidth: 40,
                      width: 40,
                      height: 40,
                      bgcolor: 'sp_primary_100_main',
                      boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                      borderRadius: '7px',
                    }}
                    disabled={
                      !formik.values.message.trim() || formik.isSubmitting
                    }
                    onClick={() => formik.handleSubmit()}
                  >
                    <Icons.SPAirPlaneButton />
                  </SPButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Drawer>
      </Stack>
    </FormikProvider>
  );
};

const SPMessage: FC<SPMessageProps> = (props) => {
  if (props.senderType === SenderType.USER)
    return (
      <Stack
        sx={{
          flexDirection: 'row-reverse',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            bgcolor: 'sp_primary_100_main',
            px: 3,
            py: 2,
            maxWidth: 271,
            borderRadius: 2,
            mx: 5,
          }}
        >
          <Typography
            variant="sp_change_password_note"
            sx={{
              lineHeight: '170%',
            }}
            color="main_white"
            dangerouslySetInnerHTML={{
              __html: props.content,
            }}
          />
          {!!props.messageFile.length && (
            <Grid
              container
              justifyContent="flex-end"
              spacing={3}
              sx={{ position: 'relative', px: 5, maxWidth: '75%' }}
            >
              {props.messageFile?.map((image, index) => (
                <Avatar
                  key={index}
                  variant="square"
                  src={image.url}
                  sx={{
                    width: 136,
                    height: 100,
                    '.MuiAvatar-img': {
                      objectFit: 'cover',
                    },
                  }}
                />
              ))}
            </Grid>
          )}

          <Stack sx={{ position: 'absolute', bottom: '-6px', right: '-12px' }}>
            <Icons.SPChatBubbleUser />
          </Stack>
        </Stack>
      </Stack>
    );
  return (
    <Stack>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: 4,
        }}
      >
        <Typography
          variant="sp_footer_bank_name"
          sx={{
            lineHeight: '100%',
            letterSpacing: 0.6,
          }}
          color="sp_gray_250"
        >
          {props.createdAt}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          px: 2,
          mb: '6px',
        }}
        spacing={1}
      >
        <Stack
          sx={{
            width: 20,
            height: 20,
            border: '0.75px solid #DDDDDD',
            borderRadius: '50%',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Avatar
            variant="square"
            src={
              props.senderType === SenderType.MANAGER ? spMilize : props.avatar
            }
            sx={{
              width: 14,
              height: 14,
              '.MuiAvatar-img': {
                objectFit: 'contain',
              },
            }}
          />
        </Stack>
        <Typography
          variant="sp_footer_bank_name"
          sx={{
            lineHeight: '100%',
            letterSpacing: 0.6,
          }}
        >
          {props.senderName}
        </Typography>
      </Stack>
      {props.content && props.content !== '<span></span>' && (
        <Stack flexDirection="row">
          <Stack
            sx={{
              position: 'relative',
              bgcolor: 'sp_primary_20',
              px: 3,
              py: 2,
              maxWidth: 271,
              borderRadius: 2,
              mx: 5,
            }}
          >
            <Typography
              variant="sp_change_password_note"
              sx={{
                lineHeight: '170%',
              }}
              dangerouslySetInnerHTML={{
                __html: `${props.content}`,
              }}
            />
            <Stack alignItems="center" spacing={3}>
              {props.messageFile
                .filter((file) => !getFileExtFromUrl(file.url).includes('pdf'))
                .map((image, index) => (
                  <Avatar
                    key={index}
                    variant="square"
                    src={
                      image instanceof File
                        ? URL.createObjectURL(image)
                        : image.url
                    }
                    sx={{
                      width: 136,
                      height: 100,
                      '.MuiAvatar-img': {
                        objectFit: 'cover',
                      },
                    }}
                  />
                ))}
            </Stack>

            <Stack sx={{ position: 'absolute', bottom: '-6px', left: '-13px' }}>
              <Icons.SPChatBubble />
            </Stack>
          </Stack>
        </Stack>
      )}
      {props.messageFile
        .filter((file) => getFileExtFromUrl(file.url).includes('pdf'))
        .map((file, index) => (
          <Stack
            key={index}
            sx={{
              position: 'relative',
              bgcolor: 'sp_primary_20',
              px: 3,
              py: 2,
              width: 'fit-content',
              borderRadius: 2,
              mx: 5,
              cursor: 'pointer',
            }}
            onClick={() => {
              downloadFile(file.url, file.filename);
            }}
          >
            <Typography
              variant="sp_change_password_note"
              sx={{
                lineHeight: '170%',
                fontWeight: 700,
              }}
            >
              {file.filename}
            </Typography>
            <Stack sx={{ position: 'absolute', bottom: '-6px', left: '-13px' }}>
              <Icons.SPChatBubble />
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};
