import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Mail: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 14C1.65833 14 1.271 13.846 0.963 13.538C0.654333 13.2293 0.5 12.8417 0.5 12.375V1.625C0.5 1.15833 0.654333 0.771 0.963 0.463C1.271 0.154333 1.65833 0 2.125 0H16.875C17.3417 0 17.7293 0.154333 18.038 0.463C18.346 0.771 18.5 1.15833 18.5 1.625V12.375C18.5 12.8417 18.346 13.2293 18.038 13.538C17.7293 13.846 17.3417 14 16.875 14H2.125ZM9.5 7.125L1.5 1.875V12.375C1.5 12.5583 1.55833 12.7083 1.675 12.825C1.79167 12.9417 1.94167 13 2.125 13H16.875C17.0583 13 17.2083 12.9417 17.325 12.825C17.4417 12.7083 17.5 12.5583 17.5 12.375V1.875L9.5 7.125ZM9.5 6L17.2 1H1.8L9.5 6ZM1.5 1.875V1V12.375C1.5 12.5583 1.55833 12.7083 1.675 12.825C1.79167 12.9417 1.94167 13 2.125 13H1.5V12.375V1.875Z"
        fill="#6B70F0"
      />
    </svg>
  </SvgIcon>
);
