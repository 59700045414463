import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Condominium: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 99 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="99"
      height="69"
      viewBox="0 0 99 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.2384 15.2691C86.1986 15.2691 80.92 10.1444 80.92 1C80.92 10.1444 75.6413 15.2691 68.6016 15.2691C75.6413 15.2691 80.92 20.3938 80.92 29.5383C80.92 20.3938 86.2002 15.2691 93.2384 15.2691Z"
        fill="#FFDB43"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.6198 53.3786C92.2034 53.3786 88.14 49.435 88.14 42.3984C88.14 49.435 84.0781 53.3786 78.6602 53.3786C84.0765 53.3786 88.14 57.3221 88.14 64.3587C88.14 57.3221 92.2018 53.3786 97.6198 53.3786Z"
        fill="#FFDB43"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3227 38.8075C24.6776 38.8075 17.4434 31.7855 17.4434 19.2549C17.4434 31.7855 10.2092 38.8075 0.5625 38.8075C10.2076 38.8075 17.4434 45.8295 17.4434 58.3601C17.4434 45.8295 24.6776 38.8075 34.3227 38.8075Z"
        fill="#FFDB43"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2402 15H40.3359V67.9939H51.2402H62.1507V15H51.2402Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M57.0715 50.6367H53.1562V54.552H57.0715V50.6367Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M49.3255 50.6367H45.4102V54.552H49.3255V50.6367Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M57.0715 42.6875H53.1562V46.6028H57.0715V42.6875Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M49.3255 42.6875H45.4102V46.6028H49.3255V42.6875Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M57.0715 34.7324H53.1562V38.6477H57.0715V34.7324Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M49.3255 34.7324H45.4102V38.6477H49.3255V34.7324Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M57.0715 26.8516H53.1562V30.7669H57.0715V26.8516Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M49.3255 26.8516H45.4102V30.7669H49.3255V26.8516Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M57.0715 18.9658H53.1562V22.8811H57.0715V18.9658Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M49.3255 18.9658H45.4102V22.8811H49.3255V18.9658Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M57.0685 58.5869H52.2305V67.9949H57.0685V58.5869Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M50.2482 58.5869H45.4102V67.9949H50.2482V58.5869Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M75.3096 22.8867H62.1484V68.0001H75.3096V22.8867Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M70.6849 50.6357H66.7695V54.5511H70.6849V50.6357Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M70.6849 42.6865H66.7695V46.6018H70.6849V42.6865Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M70.6849 34.7314H66.7695V38.6468H70.6849V34.7314Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M70.6849 26.7764H66.7695V30.6917H70.6849V26.7764Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M27.1669 67.9941H40.3281L40.3281 22.8808H27.1669L27.1669 67.9941Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M35.7121 50.6377H31.7969V54.553H35.7121V50.6377Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M35.7121 42.6885H31.7969V46.6038H35.7121V42.6885Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M35.7121 34.7334H31.7969V38.6487H35.7121V34.7334Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M35.7121 26.7842H31.7969V30.6995H35.7121V26.7842Z"
        stroke="currentColor"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
