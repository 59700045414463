import { FC, useCallback, useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { ModalSaveInfoContext, NumpadContext } from 'context';
import { Button } from 'components';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'containers/AuthModal/selectors';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'hooks';
import { getNewSavedDraft } from './thunk';

type GroupButtonSaveInfoProps = {
  callSave?: boolean;
  save?: boolean;
  numpad?: boolean;
  onSaveDraft?: () => void;
};

export const GroupButtonSaveInfo: FC<GroupButtonSaveInfoProps> = ({
  callSave,
  save,
  numpad,
  onSaveDraft,
}) => {
  const dispatch = useAppDispatch();
  const { openRecallSave, setRecallSave, openSave, setSave } =
    useContext(ModalSaveInfoContext);
  const { numPadOpen, setNumPadOpen } = useContext(NumpadContext);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const navigate = useNavigate();

  const handleRecallSave = useCallback(async () => {
    if (isLoggedIn) {
      const result = await dispatch(getNewSavedDraft());
      if (getNewSavedDraft.fulfilled.match(result)) {
        const { current_path } = result.payload.data;
        toast.success('途中保存されているデータを成功に取得しました。');
        navigate(current_path);
      } else {
        toast.error('エラーが発生しました。');
      }
    } else {
      setRecallSave(!openRecallSave);
    }
  }, [dispatch, isLoggedIn, navigate, openRecallSave, setRecallSave]);

  const handleSave = useCallback(async () => {
    if (isLoggedIn) {
      onSaveDraft && onSaveDraft();
    } else {
      setSave(!openSave);
      setNumPadOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSave, setSave, setNumPadOpen, onSaveDraft]);

  const toggleOpen = useCallback(() => {
    setNumPadOpen(!numPadOpen);
  }, [numPadOpen, setNumPadOpen]);

  return (
    <>
      {callSave && (
        <Button
          sx={{
            bgcolor: 'sh_red',
            width: { mobile: 60, tablet: 82 },
            minHeight: 40,
            height: { mobile: 40, tablet: 60 },
            position: 'fixed',
            right: 0,
            top: '269px',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            borderRadius: '5px 0px 0px 5px',
          }}
          onClick={handleRecallSave}
        >
          <Stack>
            <Typography
              sx={{ whiteSpace: 'nowrap' }}
              variant="modalSaveDataText"
              color="main_white"
              fontSize={14}
            >
              保存情報
            </Typography>
            <Typography
              sx={{ whiteSpace: 'nowrap' }}
              variant="modalSaveDataText"
              color="main_white"
            >
              呼出し
            </Typography>
          </Stack>
        </Button>
      )}
      {save && (
        <Stack
          direction="row"
          sx={{
            position: 'fixed',
            top: 200,
            right: 0,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Button
            sx={{
              width: { mobile: 60, tablet: 82 },
              minHeight: 40,
              height: { mobile: 40, tablet: 60 },
              px: 2,
              py: 4,
              borderRadius: '5px 0 0 5px',
              border: 'none',
              bgcolor: 'blue08',
            }}
            onClick={handleSave}
          >
            <Typography
              height="auto"
              color="main_white"
              variant="modalSaveDataText"
            >
              保存
            </Typography>
            <Typography
              sx={{ fontSize: 14, letterSpacing: '-2px' }}
              height="auto"
              color="main_white"
              variant="modalSaveDataText"
            >
              する
            </Typography>
          </Button>
        </Stack>
      )}
      {numpad && (
        <Button
          sx={{
            position: 'fixed',
            right: 0,
            top: 269,
            width: { mobile: 60, tablet: 82 },
            minHeight: 40,
            height: { mobile: 40, tablet: 60 },
            borderRadius: '5px 0px 0px 5px',
            bgcolor: 'h_blugreen',
            whiteSpace: 'nowrap',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          onClick={toggleOpen}
        >
          <Typography
            variant="title_sub_button"
            lineHeight="22px"
            color="main_white"
          >
            数字入力
          </Typography>
        </Button>
      )}
    </>
  );
};
