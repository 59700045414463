import {
  buttonUnstyledClasses,
  TabPanelUnstyled,
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
  tabUnstyledClasses,
} from '@mui/base';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
  AdminPreliminaryTable,
  AdminPreviewPDF,
  Button,
  DocumentUploadItem,
  DocumentUploadItemBorrowing,
  DocumentUploadItemHeader,
  ExaminationResultButtons,
  Icons,
  ModalChangeValue,
  modalErrorRef,
  ProvisionalResults,
  ResultsStatus,
  SelectPdfFile,
  SPImage,
} from 'components';
import { Loading } from 'components/atoms/Loading';
import {
  AdminPreliminaryItem,
  BorrowerType,
  BorrowingStatusType,
  CODEBANK,
  CoOwnerType,
  FILE_NOT_EXIST,
  INTERFACE_ERROR_STATUS,
  LoanType,
  NationalityType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PERSONAL_RESIDENCE,
  // PreliminaryStatusType,
  PROVISIONAL_RESULTS_DEFAULT,
  STATUS_LINKED_INTERFACE,
} from 'constant';
import { AdminLayout } from 'containers';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch, useUpdateForm } from 'hooks';
import get from 'lodash/get';
import { routeNames } from 'navigations/routes';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AdminPreliminaries,
  CheckUpdateStatusResponse,
  ErrorsCheckStatusInternal,
  UploadedFileKeys,
  UploadedFileKeysBorrowing,
  UploadedFileKeysHeader,
  UploadedFilesManager,
} from 'services';
import {
  checkIfImageExists,
  checkIfPDFExists,
  genParamBorrowing,
  getFileExtFromUrl,
  handleShowError,
} from 'utils';
import { publicHolidaysSelector } from '../../containers/AuthModal/selectors';
import { managerRoleSelector } from '../Login/selectors';
import { plannedCohabitantSelectors } from '../SPStartScreen/selectors';
import { buildContactInformationSalePerson } from '../SalePersonEditPreliminary/utils';
import {
  ButtonDownloadExcel,
  ConfirmModal,
  ModalConfirmLinkToBank,
  PulldownDocuments,
  UpdateSBISuccessModal,
} from './components';
import {
  APPLICANT_PEOPLE_ATTRIBUTE,
  FILES_UPLOAD_OPTIONS,
  JOIN_GUARANTORS_ATTRIBUTE,
} from './constant';
import { ResetData } from './ResetData';
import {
  adminPreliminariesSelector,
  borrowingDetailsOriginalSelector,
  borrowingsAttributesOriginalSelector,
  checkIncomeTotalizer,
  dataManagerAsyncSelector,
  filesSelectors,
  initialLinkValuesSelector,
  initialValuesSelector,
  joinGuarantorsAttributesOriginalSelector,
  loadingSelector,
  peopleAttributesOriginalSelector,
  residentsAttributesOriginalSelector,
  salePersonAdminOptionsSelector,
  saveButtonSelector,
} from './selectors';
import { defaultFiles } from './slice';
import {
  changeStatusPreliminary,
  checkUpdateStatus,
  editAdminPreliminary,
  getLinkPersonalInformations,
  getPersonalInformations,
  updateAdminExamination,
  updateApproverConfirmation,
  updateInterfaceErrorStatus,
  updateStatusSBI,
  updateUploadedFilesManager,
} from './thunk';
import {
  buildBorrowingDetail,
  buildContactInformation,
  buildCurrentBorrowing,
  buildFinancingPlan,
  buildGuaranteeProvider,
  buildIncomeTotalizer,
  buildPersonalInformation,
  buildPersonalOccupation,
  buildPersonalResidence,
  buildPersonalRows,
} from './utils';
import { removeObject } from './utils/buildRemoveResidentsAttributes';
import { getYearLength } from './utils/getYearLenght';
import { getValidationSchema } from './validationSchema';

export type Status = {
  key: string;
  value: number;
  active: boolean;
}[];

export type FilesUploadOption = FILES_UPLOAD_OPTIONS;

const AdminEditPreliminary: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useSelector(loadingSelector);
  const isDisableSaveButton = useSelector(saveButtonSelector);
  const preliminaries = useSelector(adminPreliminariesSelector);
  const plannedCohabitantDynamic = useSelector(plannedCohabitantSelectors);
  const initialValues = useSelector(initialValuesSelector);
  const initialLinkValues = useSelector(initialLinkValuesSelector);
  const hiddenSuccessRef = useRef<boolean>(false);
  const residentsAttributesOriginal = useSelector(
    residentsAttributesOriginalSelector
  );
  const borrowingDetailsOriginal = useSelector(
    borrowingDetailsOriginalSelector
  );
  const joinGuarantorsAttributesOriginal = useSelector(
    joinGuarantorsAttributesOriginalSelector
  );
  const borrowingsAttributesOriginal = useSelector(
    borrowingsAttributesOriginalSelector
  );
  const peopleAttributesOriginal = useSelector(
    peopleAttributesOriginalSelector
  );
  const incomeTotalizer = useSelector(checkIncomeTotalizer);

  const publicHolidays = useSelector(publicHolidaysSelector);

  const managerRole = useSelector(managerRoleSelector);
  const listSalePerson = useSelector(salePersonAdminOptionsSelector);
  const [selectTab, setSelectTab] = useState<string>('1');
  const [newSelectTab, setNewSelectTab] = useState<string>('1');
  const [selectSubTab, setSelectSubTab] = useState<number>(0);
  const [selectIncomeSubTab, setSelectIncomeSubTab] = useState<number>(0);
  const [tempSelectSubTab, setTempSelectSubTab] = useState<number>(0);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [examinationUrl, setExaminationUrl] = useState<string>('');
  const [examinationUrlForB, setExaminationUrlForB] = useState<string>('');
  const [updateStatusSBISuccess, setUpdateStatusSBISuccess] =
    useState<boolean>(false);
  const [filePdf, setFilePdf] = useState<File>();
  const [openModalBank, setOpenModalBank] = useState<boolean>(false);
  const [showParent, setShowParent] = useState<boolean>(false);
  const [isInterfaceErrorStatus, setIsInterfaceErrorStatus] =
    useState<boolean>(false);
  const [isInternal, setIsInternal] = useState<boolean>(false);
  const [isUpdateSuccessSBI, setIsUpdateSuccessSBI] = useState<boolean>(false);
  const [errorsCheckStatusInternal, setErrorsCheckStatusInternal] = useState<
    ErrorsCheckStatusInternal[]
  >([]);
  const [textErrorMessage, setTextErrorMessage] = useState<string[]>([]);
  const [jsonErrorMessage, setJsonErrorMessage] = useState<string>('');
  const [fullNameKanji, setFullNameKanji] =
    useState<string>('申込内容の修正・確認　');
  const [hiddenModal, setHiddenModal] = useState<boolean>(false);
  const [changeModal, setChangeModal] = useState<boolean>(false);
  const [messageChangeModal, setChangeModalMessage] = useState<string>('');
  const [guarantor, setGuarantor] = useState<boolean>(false);
  const [filesUploadOption, setFilesUploadOption] =
    useState<FilesUploadOption>();
  const isSubmittedEditRef = useRef<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataIncome, setDataIncome] = useState<any>(APPLICANT_PEOPLE_ATTRIBUTE);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataJoinGuarantor, setDataJoinGuarantor] = useState<any>([
    [JOIN_GUARANTORS_ATTRIBUTE],
  ]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loanDesiredBorrowingDate, setLoanDesiredBorrowingDate] = useState<
    string | undefined
  >(
    preliminaries.p_application_header.p_borrowing_details_attributes[0]
      .loan_desired_borrowing_date
  );
  const [isExistFile, setIsExistFile] = useState<boolean>(true);

  const {
    files,
    filesLink,
    filesHeader,
    filesLinkHeader,
    isLoadingFiles,
    filesBorrowing,
    filesLinkBorrowing,
    filesIncomeAdder,
    filesRemove,
    filesBorrowingRemove,
    filesHeaderRemove,
    filesIncomeAdderRemove,
  } = useSelector(filesSelectors);

  const { sbiId, timeGetEdit } = useSelector(dataManagerAsyncSelector);
  const { checkUpdateFormManager } = useUpdateForm();

  const handleChangeTab = (event: SyntheticEvent, newValues: string) => {
    // setSelectTab(newValues);
    // setSelectSubTab(0);
    // setSelectIncomeSubTab(0);
    setNewSelectTab(newValues);

    if (filePdf) {
      setOpenModalConfirm(true);
      return;
    }
    if (!editable) {
      setSelectTab(newValues);
      setSelectSubTab(0);
      setSelectIncomeSubTab(0);
      return;
    }
    const isEdited = !isEqual(
      initialValues.p_application_header,
      formik.values.p_application_header
    );
    if (!isEdited && !formik.values.isChangeFiles) {
      setSelectTab(newValues);
      setSelectSubTab(0);
      setSelectIncomeSubTab(0);
    } else {
      setOpenModalConfirm(true);
      // setTempSelectSubTab(newValues);
    }
  };

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const preliminaryId = useMemo(() => {
    const preliminaryIdParam = query.get('preliminary_id');
    return preliminaryIdParam ? preliminaryIdParam : '';
  }, [query]);

  const hasJoinGuarantor = useMemo(() => {
    return (
      preliminaries.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor !== '0'
    );
  }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  const provisionalResults: ProvisionalResults[] = useMemo(() => {
    return preliminaries.p_application_header.provisional_results.length > 0
      ? preliminaries.p_application_header.provisional_results
      : PROVISIONAL_RESULTS_DEFAULT;
  }, [preliminaries.p_application_header.provisional_results]);

  useEffect(() => {
    const getPersonal = async () => {
      const result = await dispatch(
        getPersonalInformations({
          id: preliminaryId,
        })
      );
      if (getPersonalInformations.fulfilled.match(result)) {
        // setPreliminaryEdit(result.payload);
        const linkingId = result.payload.p_application_header.linking_id;
        if (!!linkingId)
          dispatch(
            getLinkPersonalInformations({
              id: linkingId,
            })
          );
        const mainStatus =
          result.payload.p_application_header.main_statuses.find(
            (s) => s.active
          );
        if (
          mainStatus?.value === 3 &&
          result.payload.p_application_header.interface_error_status ===
            INTERFACE_ERROR_STATUS.HAVE_ERROR
        ) {
          setOpenModalBank(true);
        }
      }
    };
    getPersonal();
  }, [dispatch, preliminaryId]);

  useEffect(() => {
    const applicantName =
      preliminaries.p_application_header.p_applicant_people_attributes[0]
        ?.full_name_kanji;
    if (applicantName) {
      setFullNameKanji(`申込内容の修正・確認　［ ${applicantName} 様 ］`);
    }
  }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    setExaminationUrl(preliminaries.p_application_header.examination_file.path);
    setFilePdf(undefined);
  }, [preliminaries.p_application_header.examination_file.path]);

  const statusResult = useMemo(() => {
    const statusActive = preliminaries.p_application_header.main_statuses.find(
      (status) => status.active
    );
    if (statusActive) return statusActive.value;
    return 0;
  }, [preliminaries.p_application_header.main_statuses]);

  useEffect(() => {
    if (!!preliminaries.p_application_header.p_applicant_people_attributes[1]) {
      setDataIncome(
        preliminaries.p_application_header.p_applicant_people_attributes[1]
      );
    } else {
      setDataIncome(APPLICANT_PEOPLE_ATTRIBUTE);
    }
  }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    if (!!preliminaries.p_application_header.p_join_guarantors_attributes) {
      setDataJoinGuarantor(
        preliminaries.p_application_header.p_join_guarantors_attributes
      );
    } else {
      setDataJoinGuarantor([JOIN_GUARANTORS_ATTRIBUTE]);
    }
  }, [preliminaries.p_application_header.p_join_guarantors_attributes]);

  const handlePreview = useCallback((name: string, src: string) => {
    // setPreview({ name: name, src: src });
    // setHover((prevState) => !prevState);
  }, []);

  const getBorrowings = useCallback(
    (loanType: LoanType, borrowings: unknown[]) => {
      if (
        ![LoanType.THREE, LoanType.FOUR].includes(loanType) &&
        borrowings.length > 0
      ) {
        return borrowings.map((item) => ({
          // @ts-ignore
          ...item,
          borrower: BorrowerType.APPLICANT_HIMSELF_HERSELF,
        }));
      } else {
        return borrowings;
      }
    },
    []
  );

  const formik = useFormik<
    AdminPreliminaries & {
      files: UploadedFileKeys;
      filesLink: UploadedFileKeys;
      filesIncomeAdder: UploadedFileKeys;
      filesHeader: UploadedFileKeysHeader;
      filesLinkHeader: UploadedFileKeysHeader;
      filesBorrowing: UploadedFileKeysBorrowing;
      filesLinkBorrowing: UploadedFileKeysBorrowing;
      filesRemove: UploadedFileKeys;
      filesIncomeAdderRemove: UploadedFileKeys;
      filesHeaderRemove: UploadedFileKeysHeader;
      filesBorrowingRemove: UploadedFileKeysBorrowing;
      link?: Omit<AdminPreliminaries, 'master_bank_codes'>;
      isEdited: boolean;
      isChangeFiles: boolean;
    }
  >({
    //@ts-ignore
    initialValues: {
      files,
      filesLink,
      filesIncomeAdder,
      filesHeader,
      filesLinkHeader,
      filesBorrowing,
      filesLinkBorrowing,
      ...initialValues,
      link: initialLinkValues,
      filesRemove,
      filesIncomeAdderRemove,
      filesHeaderRemove,
      filesBorrowingRemove,
      isEdited: false,
      isChangeFiles: false,
    },
    validationSchema: getValidationSchema(
      selectTab,
      selectTab === '3' ? selectIncomeSubTab : selectSubTab,
      guarantor,
      publicHolidays,
      loanDesiredBorrowingDate ||
        initialValues.p_application_header.p_borrowing_details_attributes?.[0]
          ?.loan_desired_borrowing_date ||
        '',
      preliminaries.p_application_header.created_at
    ),
    validateOnChange: false,
    validateOnMount: true,
    enableReinitialize: true,
    async onSubmit(values, { resetForm }) {
      const {
        files,
        filesRemove,
        link,
        filesLinkBorrowing,
        filesBorrowing,
        filesBorrowingRemove,
        filesLinkHeader,
        filesHeader,
        filesHeaderRemove,
        filesIncomeAdder,
        filesIncomeAdderRemove,
        filesLink,
        ...restValues
      } = values;

      const scheduledDateMoving =
        values.p_application_header.scheduled_date_moving.slice(0, 7);
      const pReferralAgencyId = getReferralIdAction();
      const newApplicationPeople =
        values.p_application_header.p_applicant_people_attributes.map(
          (item, index) => {
            if (!index && selectTab === '1') {
              return {
                ...item,
                identity_verification:
                  filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
                    ? '1'
                    : filesUploadOption ===
                      FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
                    ? '2'
                    : '0',
                two_years_ago_income: isMCJ ? item.two_years_ago_income : null,
                borrowing_status: !values.p_application_header
                  .p_borrowings_attributes.length
                  ? BorrowingStatusType.NO
                  : BorrowingStatusType.YES,
              };
            } else if (index && selectTab === '3') {
              return {
                ...item,
                identity_verification:
                  filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
                    ? '1'
                    : filesUploadOption ===
                      FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
                    ? '2'
                    : '0',
              };
            } else if (!index) {
              return {
                ...item,
                two_years_ago_income: isMCJ ? item.two_years_ago_income : null,
                borrowing_status: !values.p_application_header
                  .p_borrowings_attributes.length
                  ? BorrowingStatusType.NO
                  : BorrowingStatusType.YES,
              };
            } else {
              return item;
            }
          }
        );
      const newValue = {
        ...restValues,
        p_application_header: {
          ...values.p_application_header,
          scheduled_date_moving: scheduledDateMoving,
          saving_amount: String(
            +values.p_application_header.deposit_savings_1 +
              +values.p_application_header.real_estate_sale_price +
              +values.p_application_header.other_saving_amount
          ),
          is_home_loan_plus: values.p_application_header.is_home_loan_plus
            ? 1
            : 0,
          p_referral_agency_id: pReferralAgencyId
            ? pReferralAgencyId
            : values.p_application_header.p_referral_agency_id,
          p_residents_attributes: removeObject(
            residentsAttributesOriginal,
            values.p_application_header.p_residents_attributes
          ),
          p_join_guarantors_attributes: removeObject(
            joinGuarantorsAttributesOriginal,
            values.p_application_header.p_join_guarantors_attributes
          ),
          p_borrowings_attributes: removeObject(
            borrowingsAttributesOriginal,
            getBorrowings(
              values.p_application_header.loan_type,
              values.p_application_header.p_borrowings_attributes
            )
          ),
          p_applicant_people_attributes: removeObject(
            peopleAttributesOriginal,
            newApplicationPeople
          ),
          p_borrowing_details_attributes: removeObject(
            borrowingDetailsOriginal,
            values.p_application_header.p_borrowing_details_attributes
              .length === 2
              ? [
                  values.p_application_header.p_borrowing_details_attributes[0],
                  {
                    ...values.p_application_header
                      .p_borrowing_details_attributes[1],
                    loan_term_year_num:
                      values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_year_num,
                    loan_term_month_num:
                      values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_month_num,
                    borrowing_detail_type: '2',
                    repayment_method:
                      values.p_application_header
                        .p_borrowing_details_attributes[0].repayment_method,
                  },
                ]
              : values.p_application_header.p_borrowing_details_attributes
          ),
        },
      };

      isSubmittedEditRef.current = true;
      // const loanTermYearNum =
      //   newValue.p_application_header.p_borrowing_details_attributes[0]
      //     .loan_term_year_num;
      // const birthday =
      //   newValue.p_application_header.p_applicant_people_attributes[0].birthday;
      // if (
      //   loanTermYearLimit(loanTermYearNum, birthday ? birthday : '') &&
      //   (selectSubTab === 0 || selectSubTab === 1)
      // ) {
      //   // displayed message error in TableBody (function loadErrorMessage)
      //   setFieldError(
      //     'p_application_header.p_applicant_people_attributes[0].birthday',
      //     MESSAGE_REGEX.LOAN_TERM_LIMIT
      //   );
      //   return;
      // }

      if (
        newValue.p_application_header.sale_person_information
          ?.active_agency_id === ''
      ) {
        newValue.p_application_header.p_referral_agency_id = '';
      }

      const addNewPeopleAttribute =
        values.p_application_header.p_applicant_people_attributes?.[1]?.id ===
        APPLICANT_PEOPLE_ATTRIBUTE.id;

      const addNewJoinGuarantor =
        values.p_application_header.p_join_guarantors_attributes?.[0]?.id ===
        JOIN_GUARANTORS_ATTRIBUTE.id;
      changeModalMessage();

      // tab 1 => joinGuarantor
      if (
        selectSubTab === 0 &&
        selectTab === '1' &&
        addNewJoinGuarantor &&
        !addNewPeopleAttribute
      ) {
        isSubmittedEditRef.current = false;
        return setChangeModal(true);
      }

      // tab 1 => income
      if (
        selectSubTab === 0 &&
        selectTab === '1' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        ) &&
        addNewPeopleAttribute &&
        !addNewJoinGuarantor
      ) {
        return setChangeModal(true);
      }

      if (
        selectSubTab === 0 &&
        selectTab === '1' &&
        addNewPeopleAttribute &&
        addNewJoinGuarantor
      ) {
        return setChangeModal(true);
      }

      // tab 4 joinGuarantor => income
      if (
        selectSubTab === 3 &&
        selectTab === '1' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        ) &&
        addNewPeopleAttribute &&
        addNewJoinGuarantor
      ) {
        setSelectSubTab(0);
        setSelectTab('3');
        isSubmittedEditRef.current = false;
        return window.scrollTo(0, 0);
      }

      // tab 1 income => tab 2 income
      if (
        addNewPeopleAttribute &&
        selectIncomeSubTab === 0 &&
        selectTab === '3' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        )
      ) {
        setSelectIncomeSubTab(1);
        isSubmittedEditRef.current = false;
        return window.scrollTo(0, 0);
      }

      // tab 2 income => tab 3 income
      if (
        addNewPeopleAttribute &&
        selectIncomeSubTab === 1 &&
        selectTab === '3' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        )
      ) {
        setSelectIncomeSubTab(2);
        isSubmittedEditRef.current = false;
        return window.scrollTo(0, 0);
      }

      if (
        selectTab === '1' &&
        formik.values.p_application_header.p_applicant_people_attributes[0]
          ?.nationality === NationalityType.FOREIGN_NATIONALITY &&
        (formik.values.files.residence_file.length === 0 ||
          formik.values.files.residence_file_back_image.length === 0)
      ) {
        hasJoinGuarantor ? setSelectSubTab(8) : setSelectSubTab(7);
        isSubmittedEditRef.current = false;
        return window.scrollTo(0, 0);
      }

      if (
        selectTab === '3' &&
        !!formik.values.p_application_header.p_applicant_people_attributes[1]
          ?.nationality &&
        formik.values.p_application_header.p_applicant_people_attributes[1]
          ?.nationality === NationalityType.FOREIGN_NATIONALITY &&
        (formik.values.filesIncomeAdder.residence_file.length === 0 ||
          formik.values.filesIncomeAdder.residence_file_back_image.length === 0)
      ) {
        setSelectIncomeSubTab(2);
        isSubmittedEditRef.current = false;
        return window.scrollTo(0, 0);
      }

      const isDataNewest =
        !!sbiId && !!preliminaryId && !!timeGetEdit
          ? // @ts-ignore
            await checkUpdateFormManager(preliminaryId, timeGetEdit, sbiId, {
              id: preliminaries.p_application_header.id,
              ...newValue,
            })
          : true;
      if (isDataNewest) {
        const result = await dispatch(
          // @ts-ignore
          editAdminPreliminary({
            id: preliminaries.p_application_header.id,
            ...newValue,
          })
        );

        if (editAdminPreliminary.fulfilled.match(result)) {
          const initNationalityA =
            preliminaries.p_application_header.p_applicant_people_attributes[0]
              ?.nationality;
          const nationalityA =
            newValue.p_application_header.p_applicant_people_attributes[0]
              ?.nationality;
          const initNationalityIncome =
            preliminaries.p_application_header.p_applicant_people_attributes[1]
              ?.nationality;
          const nationalityIncome =
            newValue.p_application_header.p_applicant_people_attributes[1]
              ?.nationality;
          const applicant_id =
            preliminaries.p_application_header.p_applicant_people_attributes[0]
              .id;
          const applicant_id_income =
            preliminaries.p_application_header.p_applicant_people_attributes
              .length > 1
              ? preliminaries.p_application_header
                  .p_applicant_people_attributes[1].id
              : '';
          if (
            !!nationalityA &&
            initNationalityA !== nationalityA &&
            nationalityA === NationalityType.DEFAULT &&
            (formik.values.files.residence_file.length > 0 ||
              formik.values.files.residence_file_back_image.length > 0)
          ) {
            const data = new FormData();
            data.append('residence_file[]', '');
            data.append('residence_file_back_image[]', '');
            await dispatch(
              updateUploadedFilesManager({
                id: preliminaryId,
                applicant_id,
                data,
              })
            );
          }

          if (
            !!nationalityIncome &&
            nationalityIncome !== initNationalityIncome &&
            nationalityIncome === NationalityType.DEFAULT &&
            (formik.values.filesIncomeAdder.residence_file.length > 0 ||
              formik.values.filesIncomeAdder.residence_file_back_image.length >
                0)
          ) {
            const data = new FormData();
            data.append('residence_file[]', '');
            data.append('residence_file_back_image[]', '');
            await dispatch(
              updateUploadedFilesManager({
                id: preliminaryId,
                applicant_id: applicant_id_income,
                data,
              })
            );
          }
        }
        if (editAdminPreliminary.fulfilled.match(result)) {
          !hiddenSuccessRef.current &&
            toast.success('申込内容を更新しました。');
          resetForm();
          // recall API get edit to update sbi_id
          await dispatch(
            getPersonalInformations({
              id: preliminaryId,
            })
          );
        }
      }
      isSubmittedEditRef.current = false;
    },
  });

  useEffect(() => {
    setGuarantor(
      !!formik.values.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor &&
        formik.values.p_application_header.p_applicant_people_attributes[0]
          .has_join_guarantor === CoOwnerType.CANBE
    );
  }, [formik.values.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    setLoanDesiredBorrowingDate(
      formik.values.p_application_header.p_borrowing_details_attributes[0]
        .loan_desired_borrowing_date
    );
  }, [
    formik.values.p_application_header.p_borrowing_details_attributes[0]
      .loan_desired_borrowing_date,
  ]);

  useEffect(() => {
    if (
      formik.values.p_application_header.p_applicant_people_attributes
        .length === 1
    ) {
      formik.setFieldValue(`filesIncomeAdder`, defaultFiles);
      formik.setFieldValue(`filesIncomeAdderRemove`, defaultFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.p_applicant_people_attributes]);

  console.log('--- DEBUG - formik: ', formik.errors);

  const hasJoinGuarantorForB = useMemo(() => {
    return (
      formik.values.link?.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor !== '0'
    );
  }, [formik.values.link?.p_application_header.p_applicant_people_attributes]);

  const provisionalResultsForB: ProvisionalResults[] = useMemo(() => {
    if (!formik.values.link) return PROVISIONAL_RESULTS_DEFAULT;
    return formik.values.link.p_application_header.provisional_results.length >
      0
      ? formik.values.link?.p_application_header.provisional_results
      : PROVISIONAL_RESULTS_DEFAULT;
  }, [formik.values.link]);

  useEffect(() => {
    setExaminationUrlForB(
      formik.values.link?.p_application_header?.examination_file?.path ?? ''
    );
  }, [formik.values.link?.p_application_header?.examination_file?.path]);

  const getReferralIdAction = () => {
    const { active_agency_id, active_store_id, active_exhibition_id } =
      formik.values.p_application_header.sale_person_information;
    if (active_agency_id && !active_store_id && !active_exhibition_id) {
      return listSalePerson.agenciesAdminOptions.find(
        (item) => item.value === active_agency_id
      )?.id;
    }
    if (active_store_id && !active_exhibition_id) {
      return listSalePerson.storeAdminOptions.find(
        (item) => item.value === active_store_id
      )?.id;
    }
    if (!!active_exhibition_id)
      return listSalePerson.exhibitionsAdminOptions.find(
        (item) => item.value === active_exhibition_id
      )?.id;
    return '';
  };

  const editable = useMemo<boolean>(() => {
    if (
      selectTab === '1' &&
      // preliminaries.p_application_header.preliminary_status ===
      //   PreliminaryStatusType.PROVISIONAL_PRELIMINARY &&
      selectSubTab ===
        (formik.values.p_application_header.p_applicant_people_attributes[0]
          .has_join_guarantor !== '0'
          ? 7
          : 6)
    ) {
      return true;
    }
    return (
      formik.values.p_application_header.editable &&
      formik.values.p_application_header.edit_main_statuses
    );
  }, [
    preliminaries.p_application_header.preliminary_status,
    selectSubTab,
    formik.values.p_application_header.p_applicant_people_attributes,
    formik.values.p_application_header.editable,
    formik.values.p_application_header.edit_main_statuses,
  ]);

  const isMCJ = useMemo(
    () =>
      formik.values.p_application_header.name_master_banks?.includes(
        CODEBANK.MCJ
      ),
    [formik.values.p_application_header.name_master_banks]
  );

  const handleChangePDF = useCallback((file: File[]) => {
    file?.length > 0 && setExaminationUrl(URL.createObjectURL(file[0]));
    setFilePdf(file[0]);
  }, []);

  const removePdf = useCallback(async () => {
    if (!!examinationUrl) {
      setExaminationUrl('');
      setFilePdf(undefined);
      const resultUpdateStatus = await dispatch(
        changeStatusPreliminary({
          id: preliminaryId,
          status_result: String(STATUS_LINKED_INTERFACE),
        })
      );
      const dataFile = new FormData();
      dataFile.append('examination_file', '');
      dataFile.append('provisional_result', '');
      await dispatch(
        updateAdminExamination({
          id: preliminaryId,
          data: dataFile,
        })
      );
      await dispatch(
        updateApproverConfirmation({
          id: preliminaryId,
          approver_confirmation: 0,
        })
      );
      if (changeStatusPreliminary.fulfilled.match(resultUpdateStatus)) {
        await dispatch(
          getPersonalInformations({
            id: preliminaryId,
          })
        );
      }
    }
  }, [dispatch, examinationUrl, preliminaryId]);

  useEffect(() => {
    if (!editable) return;
    formik.validateForm();
  }, [selectSubTab, selectTab, editable, selectIncomeSubTab]);

  const handleChangeSubTab = useCallback(
    (_: SyntheticEvent, newValues: number) => {
      if (filePdf) {
        setOpenModalConfirm(true);
        setTempSelectSubTab(newValues);
        return;
      }
      if (!editable) {
        setSelectSubTab(newValues);
        return;
      }
      const isEdited = !isEqual(
        initialValues.p_application_header,
        formik.values.p_application_header
      );
      if (!isEdited && !formik.values.isChangeFiles) {
        setSelectSubTab(newValues);
      } else {
        setOpenModalConfirm(true);
        setTempSelectSubTab(newValues);
      }
    },
    [
      editable,
      filePdf,
      formik.values.isChangeFiles,
      formik.values.p_application_header,
      initialValues.p_application_header,
    ]
  );

  const handleChangeIncomeSubTab = useCallback(
    (_: SyntheticEvent, newValues: number) => {
      if (filePdf) {
        setOpenModalConfirm(true);
        setTempSelectSubTab(newValues);
        return;
      }
      if (!editable) {
        setSelectIncomeSubTab(newValues);
        return;
      }
      const isEdited = !isEqual(
        initialValues.p_application_header,
        formik.values.p_application_header
      );
      if (!isEdited && !formik.values.isChangeFiles) {
        setSelectIncomeSubTab(newValues);
      } else {
        setOpenModalConfirm(true);
        setTempSelectSubTab(newValues);
      }
    },
    [
      editable,
      filePdf,
      formik.values.isChangeFiles,
      formik.values.p_application_header,
      initialValues.p_application_header,
    ]
  );

  const handleOk = () => {
    setOpenModalConfirm(false);
    const loanType = formik.values.p_application_header.loan_type;
    if (
      selectTab === '3' &&
      (loanType === LoanType.THREE || loanType === LoanType.FOUR) &&
      !incomeTotalizer
    ) {
      window.location.reload();
    } else {
      formik.values.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor &&
      tempSelectSubTab >= 4 &&
      newSelectTab !== selectTab
        ? setSelectSubTab(tempSelectSubTab - 1)
        : setSelectSubTab(tempSelectSubTab);

      setSelectTab(newSelectTab);
      newSelectTab !== selectTab
        ? setSelectIncomeSubTab(0)
        : setSelectIncomeSubTab(tempSelectSubTab);
      setFilePdf(undefined);
      if (isDriverLicenseImage) {
        setFilesUploadOption(FILES_UPLOAD_OPTIONS.DRIVER_LICENSE);
      } else if (isCardNumberImage) {
        setFilesUploadOption(FILES_UPLOAD_OPTIONS.CARD_NUMBER);
      } else if (isResidentRegisterImage) {
        setFilesUploadOption(FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER);
      }
      formik.resetForm();
    }
  };

  const handleCancel = () => {
    setNewSelectTab(selectTab);
    setOpenModalConfirm(false);
  };

  const mainStatuses = useMemo(() => {
    return preliminaries.p_application_header.main_statuses;
  }, [preliminaries]);

  const isDriverLicenseImage = useMemo(() => {
    if (selectTab === '1') {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification === '0' ||
        !preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification
      );
    } else if (selectTab === '2') {
      return (
        formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification === '0' ||
        !formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification
      );
    } else {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification === '0' ||
        !preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification
      );
    }
  }, [
    preliminaries.p_application_header.p_applicant_people_attributes,
    formik.values.link?.p_application_header.p_applicant_people_attributes,
    selectTab,
  ]);

  const isCardNumberImage = useMemo(() => {
    if (selectTab === '1') {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification === '1'
      );
    } else if (selectTab === '2') {
      return (
        formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification === '1'
      );
    } else {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification === '1'
      );
    }
  }, [
    preliminaries.p_application_header.p_applicant_people_attributes,
    formik.values.link?.p_application_header.p_applicant_people_attributes,
    selectTab,
  ]);

  const isResidentRegisterImage = useMemo(() => {
    if (selectTab === '1') {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification === '2'
      );
    } else if (selectTab === '2') {
      return (
        formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification === '2'
      );
    } else {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification === '2'
      );
    }
  }, [
    formik.values.link?.p_application_header.p_applicant_people_attributes,
    preliminaries.p_application_header.p_applicant_people_attributes,
    selectTab,
  ]);

  useEffect(() => {
    if (isDriverLicenseImage) {
      setFilesUploadOption(FILES_UPLOAD_OPTIONS.DRIVER_LICENSE);
    } else if (isCardNumberImage) {
      setFilesUploadOption(FILES_UPLOAD_OPTIONS.CARD_NUMBER);
    } else if (isResidentRegisterImage) {
      setFilesUploadOption(FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER);
    }
  }, [isCardNumberImage, isDriverLicenseImage, isResidentRegisterImage]);

  const showExaminationResultForA = useMemo(
    () => selectTab === '1' && selectSubTab === (hasJoinGuarantor ? 9 : 8),
    [selectTab, selectSubTab, hasJoinGuarantor]
  );

  const showExaminationResultForB = useMemo(
    () =>
      selectTab === '2' &&
      formik.values.link &&
      selectSubTab === (hasJoinGuarantorForB ? 9 : 8),
    [selectTab, formik.values.link, selectSubTab, hasJoinGuarantorForB]
  );

  const isTabUploadFiles = useMemo(() => {
    return (
      selectTab === '1' &&
      !!preliminaryId &&
      ((hasJoinGuarantor && selectSubTab === 8) ||
        (!hasJoinGuarantor && selectSubTab === 7))
    );
  }, [hasJoinGuarantor, preliminaryId, selectSubTab, selectTab]);

  const isTabUploadFilesIncome = useMemo(() => {
    return selectTab === '3' && selectIncomeSubTab === 2 && !!preliminaryId;
  }, [preliminaryId, selectIncomeSubTab, selectTab]);

  // add files || remove files (id !== '')
  const handleChangeFiles = useCallback(
    (
      files: (File | UploadedFilesManager)[],
      id: string,
      name?: keyof UploadedFileKeys
    ) => {
      if (!!name) {
        if (id) {
          // remove file
          const filesWithOption = formik.values.files[`${name}`];
          const fileRemove = filesWithOption.find((file) => file.id === id);
          if (fileRemove) {
            let filesRemove = [...formik.values.filesRemove[`${name}`]];
            // @ts-ignore
            filesRemove.length === 0
              ? // @ts-ignore
                (filesRemove = [fileRemove])
              : // @ts-ignore
                filesRemove.push(fileRemove);
            formik.setFieldValue(`filesRemove.${name}`, filesRemove);
          }
        }
        formik.setFieldValue(`files.${name}`, files);
      }
      formik.setFieldValue('isChangeFiles', true);
    },
    [formik]
  );

  // add files || remove files (id !== '')
  const handleChangeFilesIncomeAdder = useCallback(
    (
      files: (File | UploadedFilesManager)[],
      id: string,
      name?: keyof UploadedFileKeys
    ) => {
      if (!!name) {
        if (id) {
          // remove file
          const filesWithOption = formik.values.filesIncomeAdder[`${name}`];
          const fileRemove = filesWithOption.find((file) => file.id === id);
          if (fileRemove) {
            let filesRemove = [
              ...formik.values.filesIncomeAdderRemove[`${name}`],
            ];
            // @ts-ignore
            filesRemove.length === 0
              ? // @ts-ignore
                (filesRemove = [fileRemove])
              : // @ts-ignore
                filesRemove.push(fileRemove);
            formik.setFieldValue(`filesIncomeAdderRemove.${name}`, filesRemove);
          }
        }
        formik.setFieldValue(`filesIncomeAdder.${name}`, files);
      }
      formik.setFieldValue('isChangeFiles', true);
    },
    [formik]
  );

  const handleChangeFilesHeader = useCallback(
    (name?: keyof UploadedFileKeysHeader) =>
      (files: (File | UploadedFilesManager)[], id: string) => {
        if (!!name) {
          if (id) {
            // remove file
            const filesWithOption = formik.values.filesHeader[`${name}`];
            const fileRemove = filesWithOption.find((file) => file.id === id);
            if (fileRemove) {
              let filesRemove = [...formik.values.filesHeaderRemove[`${name}`]];
              // @ts-ignore
              filesRemove.length === 0
                ? // @ts-ignore
                  (filesRemove = [fileRemove])
                : // @ts-ignore
                  filesRemove.push(fileRemove);
              formik.setFieldValue(`filesHeaderRemove.${name}`, filesRemove);
            }
          }
          formik.setFieldValue(`filesHeader.${name}`, files);
        }
        formik.setFieldValue('isChangeFiles', true);
      },
    [formik]
  );

  const handleChangeFilesBorrowing = useCallback(
    (name: string, index: number) =>
      (files: (File | UploadedFilesManager)[], id: string) => {
        if (!!name) {
          if (id) {
            // remove file
            const filesWithOption =
              // @ts-ignore
              formik.values.filesBorrowing[`${name}__${index}`];
            // @ts-ignore
            const fileRemove = filesWithOption.find((file) => file.id === id);
            if (fileRemove) {
              let filesRemove = [
                // @ts-ignore
                ...formik.values.filesBorrowingRemove[`${name}__${index}`],
              ];
              // @ts-ignore
              filesRemove.length === 0
                ? // @ts-ignore
                  (filesRemove = [fileRemove])
                : // @ts-ignore
                  filesRemove.push(fileRemove);
              formik.setFieldValue(
                `filesBorrowingRemove.${name}__${index}`,
                filesRemove
              );
            }
          }
          formik.setFieldValue(`filesBorrowing.${name}__${index}`, files);
        }
        formik.setFieldValue('isChangeFiles', true);
      },
    [formik]
  );

  const updateStatus = useCallback(
    async (
      indexUpdate: number,
      statusUpdate: string,
      updateReset?: boolean
    ) => {
      setIsInterfaceErrorStatus(false);
      if (indexUpdate === 3) {
        // 1. Call API to check validate
        if (formik.values.isChangeFiles) {
          hiddenSuccessRef.current = true;
          // create check list image
          const imageLists: Array<File | SPImage> = [];

          // get image form header
          Object.keys(formik.values.filesHeader).forEach((key) => {
            const paramsHeaderImages = get(formik.values.filesHeader, key, []);
            paramsHeaderImages.forEach((file: File | SPImage) => {
              imageLists.push(file);
            });
          });
          // get image form borrowing
          Object.keys(formik.values.filesBorrowing).forEach((key) => {
            const paramsBorrowImages = get(
              formik.values.filesBorrowing,
              key,
              []
            );
            paramsBorrowImages.forEach((file: File | SPImage) => {
              const [keyValue, index] = key.split('_index__');
              const borrowingId =
                preliminaries.p_application_header.p_borrowings_attributes[
                  Number(index)
                ]?.id;
              if (!!borrowingId) imageLists.push(file);
            });
          });
          // get image form people 1
          Object.keys(formik.values.files).forEach((key) => {
            const paramsImages = get(formik.values.files, key, []);
            paramsImages.forEach((file: File | SPImage) => {
              imageLists.push(file);
            });
          });
          // get image form people 2
          Object.keys(formik.values.filesIncomeAdder).forEach((key) => {
            const paramsIncomeImages = get(
              formik.values.filesIncomeAdder,
              key,
              []
            );
            paramsIncomeImages.forEach((file: File | SPImage) => {
              imageLists.push(file);
            });
          });
          const promises = imageLists.map((image) => {
            const type =
              image instanceof File ? image.type : getFileExtFromUrl(image.url);
            if (type.includes('pdf')) {
              if (image instanceof File) {
                return checkIfPDFExists(URL.createObjectURL(image));
              } else {
                return checkIfPDFExists(image.url);
              }
            } else {
              if (image instanceof File) {
                return checkIfImageExists(image);
              } else {
                return checkIfImageExists(image.url);
              }
            }
          });

          Promise.all(promises).then(async (result) => {
            if (!result.every((item) => item)) {
              modalErrorRef.current?.open('', FILE_NOT_EXIST, true);
              return;
            } else {
              handleSubmitEditUploadedFiles(
                formik.values.files,
                formik.values.filesIncomeAdder,
                formik.values.filesHeader,
                formik.values.filesBorrowing,
                formik.values.p_application_header.p_applicant_people_attributes
              );
              hiddenSuccessRef.current = false;
              const resultCheckStatusInternal = await dispatch(
                checkUpdateStatus({ id: preliminaryId, status_result: '3' })
              );
              if (
                checkUpdateStatus.fulfilled.match(resultCheckStatusInternal)
              ) {
                await dispatch(
                  changeStatusPreliminary({
                    id: preliminaryId,
                    status_result: updateReset ? '2' : statusUpdate,
                  })
                );
                dispatch(
                  getPersonalInformations({
                    id: preliminaryId,
                  })
                );
              }
              if (checkUpdateStatus.rejected.match(resultCheckStatusInternal)) {
                setIsInternal(true);
                setOpenModalBank(true);
                const errorResponse =
                  resultCheckStatusInternal.payload as CheckUpdateStatusResponse;
                if (errorResponse.errors) {
                  setShowParent(true);
                  setErrorsCheckStatusInternal(errorResponse.errors);
                }
              }
              formik.resetForm();
            }
          });
        } else if (updateReset) {
          const isEdited = !isEqual(
            initialValues.p_application_header,
            formik.values.p_application_header
          );
          if (isEdited || formik.values.isChangeFiles) {
            await new Promise((resolve, reject) => {
              setTimeout(async () => {
                hiddenSuccessRef.current = true;
                await formik
                  .submitForm()
                  .finally(() => {
                    hiddenSuccessRef.current = false;
                  })
                  .then(resolve)
                  .catch(reject);
              }, 0);
            });
          }
          await dispatch(
            updateInterfaceErrorStatus({
              id: preliminaryId,
              interface_error_status: INTERFACE_ERROR_STATUS.NOT_HAVE_ERROR,
            })
          );
          await dispatch(
            changeStatusPreliminary({
              id: preliminaryId,
              status_result: updateReset ? '2' : statusUpdate,
            })
          );
          dispatch(getPersonalInformations({ id: preliminaryId }));
          formik.resetForm();
        } else {
          const isEdited = !isEqual(
            initialValues.p_application_header,
            formik.values.p_application_header
          );
          if (isEdited || formik.values.isChangeFiles) {
            await new Promise((resolve, reject) => {
              setTimeout(async () => {
                hiddenSuccessRef.current = true;
                await formik
                  .submitForm()
                  .finally(() => {
                    hiddenSuccessRef.current = false;
                  })
                  .then(resolve)
                  .catch(reject);
              }, 0);
            });
          }
          await dispatch(
            updateInterfaceErrorStatus({
              id: preliminaryId,
              interface_error_status: INTERFACE_ERROR_STATUS.NOT_HAVE_ERROR,
            })
          );
          if (isSubmittedEditRef.current) return;
          const resultCheckStatusInternal = await dispatch(
            checkUpdateStatus({ id: preliminaryId, status_result: '3' })
          );
          if (checkUpdateStatus.fulfilled.match(resultCheckStatusInternal)) {
            await dispatch(
              changeStatusPreliminary({
                id: preliminaryId,
                status_result: updateReset ? '2' : statusUpdate,
              })
            );
            dispatch(
              getPersonalInformations({
                id: preliminaryId,
              })
            );
          }
          if (checkUpdateStatus.rejected.match(resultCheckStatusInternal)) {
            setIsInternal(true);
            setOpenModalBank(true);
            const errorResponse =
              resultCheckStatusInternal.payload as CheckUpdateStatusResponse;
            if (errorResponse.errors) {
              setShowParent(true);
              setErrorsCheckStatusInternal(errorResponse.errors);
            }
          }
          formik.resetForm();
        }
      } else if (indexUpdate === 4) {
        setIsInternal(false);
        setErrorsCheckStatusInternal([]);
        // Call API interface to bank
        const resultSBI = await dispatch(
          updateStatusSBI({ application_header_id: preliminaryId })
        );
        if (updateStatusSBI.fulfilled.match(resultSBI)) {
          setIsUpdateSuccessSBI(true);
          const result = await dispatch(
            changeStatusPreliminary({
              id: preliminaryId,
              status_result: statusUpdate,
            })
          );
          if (changeStatusPreliminary.fulfilled.match(result)) {
            setUpdateStatusSBISuccess(true);
          }
          dispatch(
            getPersonalInformations({
              id: preliminaryId,
            })
          );
        }
        setTextErrorMessage([]);
        setJsonErrorMessage('');
        if (updateStatusSBI.rejected.match(resultSBI)) {
          setOpenModalBank(true);
          const erorrPosition = get(resultSBI, 'payload.erorr_position');
          const errorMessage = get(resultSBI, 'payload.erorrs');
          if (erorrPosition === 1) {
            setTextErrorMessage(
              errorMessage.map((data: { message: string }) => data.message)
            );
          } else {
            setJsonErrorMessage(JSON.stringify(errorMessage, null, 2));
          }
          setHiddenModal(true);
          await dispatch(
            updateInterfaceErrorStatus({
              id: preliminaryId,
              interface_error_status: INTERFACE_ERROR_STATUS.HAVE_ERROR,
            })
          );
        } else {
          setShowParent(false);
          setIsUpdateSuccessSBI(false);
        }
      } else {
        await dispatch(
          changeStatusPreliminary({
            id: preliminaryId,
            status_result: statusUpdate,
          })
        );
        dispatch(
          getPersonalInformations({
            id: preliminaryId,
          })
        );
      }
      setSelectTab('1');
      setSelectSubTab(0);
      setSelectIncomeSubTab(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, formik, preliminaries.p_application_header.id, preliminaryId]
  );

  useEffect(() => {
    if (
      preliminaries.p_application_header.interface_error_status ===
        INTERFACE_ERROR_STATUS.HAVE_ERROR &&
      statusResult === 3 &&
      !hiddenModal
    ) {
      setIsInterfaceErrorStatus(true);
    }
  }, [
    hiddenModal,
    preliminaries.p_application_header.interface_error_status,
    statusResult,
  ]);

  // const isTaxTargetReturnExist = (typeTaxReturn: FileATaxReturnType[]) => {
  //   if (typeTaxReturn.length === 0) return false;
  //   return (
  //     typeTaxReturn.includes(FileATaxReturnType.ONE) ||
  //     typeTaxReturn.includes(FileATaxReturnType.TWO) ||
  //     typeTaxReturn.includes(FileATaxReturnType.THREE) ||
  //     typeTaxReturn.includes(FileATaxReturnType.SIX) ||
  //     typeTaxReturn.includes(FileATaxReturnType.OTHERS)
  //   );
  // };

  // const isFirstWithholdingSlipFile = useMemo(() => {
  //   if (selectTab === '1') {
  //     const typeTaxReturnA =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .type_tax_return;
  //     const taxReturnA =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .tax_return;
  //     return (
  //       (!!taxReturnA && taxReturnA === '0') ||
  //       (!!taxReturnA &&
  //         taxReturnA === '1' &&
  //         !!typeTaxReturnA &&
  //         !isTaxTargetReturnExist(typeTaxReturnA))
  //     );
  //   }
  //   if (selectTab === '2' && !!formik.values.link) {
  //     const typeTaxReturnB =
  //       formik.values.link?.p_application_header
  //         .p_applicant_people_attributes[0].type_tax_return;
  //     const taxReturnB =
  //       formik.values.link?.p_application_header
  //         .p_applicant_people_attributes[0].tax_return;
  //     return (
  //       (!!taxReturnB && taxReturnB === '0') ||
  //       (!!taxReturnB &&
  //         taxReturnB === '1' &&
  //         !!typeTaxReturnB &&
  //         !isTaxTargetReturnExist(typeTaxReturnB))
  //     );
  //   }
  //   if (selectTab === '3') {
  //     const typeTaxReturnIncome =
  //       preliminaries.p_application_header.p_applicant_people_attributes[1]
  //         ?.type_tax_return;
  //     const taxReturnIncome =
  //       preliminaries.p_application_header.p_applicant_people_attributes[1]
  //         ?.tax_return;
  //     return (
  //       (!!taxReturnIncome && taxReturnIncome === '0') ||
  //       (!!taxReturnIncome &&
  //         taxReturnIncome === '1' &&
  //         !!typeTaxReturnIncome &&
  //         !isTaxTargetReturnExist(typeTaxReturnIncome))
  //     );
  //   }
  //   return false;
  // }, [
  //   formik.values.link,
  //   preliminaries.p_application_header.p_applicant_people_attributes,
  //   selectTab,
  // ]);

  // const isOccupationEqualOne = useCallback(() => {
  //   return (
  //     preliminaries.p_application_header.p_applicant_people_attributes[0]
  //       .occupation === '1'
  //   );
  // }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  // const isOccupationEqualOneOfOneSixSevenEight = useCallback(() => {
  //   const occupation =
  //     preliminaries.p_application_header.p_applicant_people_attributes[0]
  //       .occupation;
  //   return (
  //     occupation === '1' ||
  //     occupation === '6' ||
  //     occupation === '7' ||
  //     occupation === '8'
  //   );
  // }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  // const isOccupationEqualOneOfSixSevenEight = useCallback(() => {
  //   const occupation =
  //     preliminaries.p_application_header.p_applicant_people_attributes[0]
  //       .occupation;
  //   return occupation === '6' || occupation === '7' || occupation === '8';
  // }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  const statusResultForB = useMemo(() => {
    const statusActive =
      formik.values.link?.p_application_header.main_statuses.find(
        (status) => status.active
      );
    if (statusActive) return statusActive.value;
    return 0;
  }, [formik.values.link?.p_application_header.main_statuses]);

  const isRequiredUploadFile = useCallback(
    (fileParam: string, isUserA: boolean = false) => {
      // const typeTaxReturn =
      //   preliminaries.p_application_header.p_applicant_people_attributes[0]
      //     .type_tax_return;
      // const taxReturn =
      //   preliminaries.p_application_header.p_applicant_people_attributes[0]
      //     .tax_return;
      //

      const nationalityA = !!formik.values.p_application_header
        .p_applicant_people_attributes[0]?.nationality
        ? formik.values.p_application_header.p_applicant_people_attributes[0]
            .nationality
        : '';
      const nationalityIncomer = !!formik.values.p_application_header
        .p_applicant_people_attributes[1]?.nationality
        ? formik.values.p_application_header.p_applicant_people_attributes[1]
            .nationality
        : '';

      if (
        filesUploadOption === FILES_UPLOAD_OPTIONS.DRIVER_LICENSE &&
        (fileParam === 'driver_license_front_image' ||
          fileParam === 'driver_license_back_image')
      ) {
        if (isUserA) {
          return true;
        } else if (!isUserA) {
          return true;
        }
      }

      if (
        filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER &&
        fileParam === 'card_number_front_image'
      ) {
        if (isUserA) {
          return true;
        } else if (!isUserA) {
          return true;
        }
      }
      if (
        filesUploadOption === FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER &&
        (fileParam === 'resident_register_front_image' ||
          fileParam === 'resident_register_back_image')
      ) {
        if (isUserA) {
          return true;
        } else if (!isUserA) {
          return true;
        }
      }

      /* <-- remove required image fields in ticket HOMELOAN-2296 --> */
      if (
        (fileParam === 'residence_file' ||
          fileParam === 'residence_file_back_image') &&
        isUserA &&
        nationalityA === NationalityType.FOREIGN_NATIONALITY
      )
        return true;

      if (
        (fileParam === 'residence_file' ||
          fileParam === 'residence_file_back_image') &&
        !isUserA &&
        nationalityIncomer === NationalityType.FOREIGN_NATIONALITY
      )
        return true;
      return false;
    },
    [
      filesUploadOption,
      formik.values.p_application_header.p_applicant_people_attributes,
    ]
  );

  const isDisableButtonSubmit = useMemo(() => {
    // const isRequirePropertyInformationFile =
    //   isRequiredUploadFile('property_information_file_index') &&
    //   formik.values.filesHeader.property_information_file_index.length === 0;

    const isRequireResidenceFile =
      selectTab === '1'
        ? (!!formik.values.files.residence_file &&
            isRequiredUploadFile('residence_file', true) &&
            formik.values.files.residence_file.length === 0) ||
          (!!formik.values.files.residence_file_back_image &&
            isRequiredUploadFile('residence_file_back_image', true) &&
            formik.values.files.residence_file_back_image.length === 0)
        : false;
    const isRequireFilesA =
      selectTab === '1'
        ? (isRequiredUploadFile('card_number_front_image') &&
            formik.values.files.card_number_front_image.length === 0) ||
          (isRequiredUploadFile('driver_license_back_image') &&
            (formik.values.files.driver_license_back_image.length === 0 ||
              formik.values.files.driver_license_front_image.length === 0)) ||
          (isRequiredUploadFile('resident_register_front_image') &&
            (formik.values.files.resident_register_front_image.length === 0 ||
              formik.values.files.resident_register_back_image.length === 0))
        : false;
    return (
      formik.isSubmitting ||
      (selectTab === '1' &&
        isTabUploadFiles &&
        (isRequireResidenceFile || isRequireFilesA))
    );
  }, [
    formik.isSubmitting,
    formik.values.files.card_number_front_image.length,
    formik.values.files.driver_license_back_image.length,
    formik.values.files.driver_license_front_image.length,
    formik.values.files.residence_file,
    formik.values.files.residence_file_back_image,
    formik.values.files.resident_register_back_image.length,
    formik.values.files.resident_register_front_image.length,
    isRequiredUploadFile,
    isTabUploadFiles,
    selectTab,
  ]);

  const isDisableButtonSubmitForIncome = useMemo(() => {
    // const isRequirePropertyInformationFile =
    //   isRequiredUploadFile('property_information_file_index') &&
    //   formik.values.filesHeader.property_information_file_index.length === 0;
    const isRequireResidenceFileIncome =
      selectTab === '3'
        ? (!!formik.values.filesIncomeAdder.residence_file &&
            isRequiredUploadFile('residence_file') &&
            formik.values.filesIncomeAdder.residence_file.length === 0) ||
          (!!formik.values.filesIncomeAdder.residence_file_back_image &&
            isRequiredUploadFile('residence_file_back_image') &&
            formik.values.filesIncomeAdder.residence_file_back_image.length ===
              0)
        : false;
    const isRequireFilesAIncome =
      selectTab === '3'
        ? (isRequiredUploadFile('card_number_front_image') &&
            formik.values.filesIncomeAdder.card_number_front_image.length ===
              0) ||
          (isRequiredUploadFile('driver_license_back_image') &&
            (formik.values.filesIncomeAdder.driver_license_back_image.length ===
              0 ||
              formik.values.filesIncomeAdder.driver_license_front_image
                .length === 0)) ||
          (isRequiredUploadFile('resident_register_front_image') &&
            (formik.values.filesIncomeAdder.resident_register_front_image
              .length === 0 ||
              formik.values.filesIncomeAdder.resident_register_back_image
                .length === 0))
        : false;
    return (
      formik.isSubmitting ||
      (isTabUploadFilesIncome &&
        (isRequireResidenceFileIncome || isRequireFilesAIncome))
    );
  }, [
    formik.isSubmitting,
    formik.values.filesIncomeAdder.card_number_front_image.length,
    formik.values.filesIncomeAdder.driver_license_back_image.length,
    formik.values.filesIncomeAdder.driver_license_front_image.length,
    formik.values.filesIncomeAdder.residence_file,
    formik.values.filesIncomeAdder.residence_file_back_image,
    formik.values.filesIncomeAdder.resident_register_back_image.length,
    formik.values.filesIncomeAdder.resident_register_front_image.length,
    isRequiredUploadFile,
    isTabUploadFilesIncome,
    selectTab,
  ]);

  // submit change file
  const handleSubmitEditUploadedFiles = useCallback(
    async (
      paramsFiles: UploadedFileKeys,
      paramsFilesIncomeAdder: UploadedFileKeys,
      paramsHeaderFiles: UploadedFileKeysHeader,
      paramsFileBorrowing: UploadedFileKeysBorrowing,
      // eslint-disable-next-line
      p_applicant_people_attributes?: any
    ) => {
      const data = new FormData();

      const {
        files,
        filesRemove,
        link,
        filesLinkBorrowing,
        filesBorrowing,
        filesBorrowingRemove,
        filesLinkHeader,
        filesHeader,
        filesHeaderRemove,
        filesIncomeAdder,
        filesIncomeAdderRemove,
        filesLink,
        ...restValues
      } = formik.values;
      const scheduledDateMoving =
        formik.values.p_application_header.scheduled_date_moving.slice(0, 7);
      const pReferralAgencyId = getReferralIdAction();

      const applicant_people_1 = p_applicant_people_attributes[0];
      const { applicant_detail_type, id, ...restApplicantPeople2 } =
        !!p_applicant_people_attributes[1]
          ? p_applicant_people_attributes[1]
          : p_applicant_people_attributes[0];
      const applicant_people_2 = {
        applicant_detail_type: '1',
        change_loan_type: '1',
        ...restApplicantPeople2,
      };

      const newValue = {
        ...restValues,
        p_application_header: {
          ...formik.values.p_application_header,
          master_bank_ids: [],
          scheduled_date_moving: scheduledDateMoving,
          is_home_loan_plus: formik.values.p_application_header
            .is_home_loan_plus
            ? 1
            : 0,
          p_referral_agency_id: pReferralAgencyId
            ? pReferralAgencyId
            : formik.values.p_application_header.p_referral_agency_id,
          p_residents_attributes: removeObject(
            residentsAttributesOriginal,
            formik.values.p_application_header.p_residents_attributes
          ),
          p_join_guarantors_attributes: removeObject(
            joinGuarantorsAttributesOriginal,
            formik.values.p_application_header.p_join_guarantors_attributes
          ),
          p_borrowings_attributes: removeObject(
            borrowingsAttributesOriginal,
            getBorrowings(
              formik.values.p_application_header.loan_type,
              formik.values.p_application_header.p_borrowings_attributes
            )
          ),
          p_applicant_people_attributes: !!p_applicant_people_attributes[1]
            ? [applicant_people_1, applicant_people_2]
            : [applicant_people_1],
          p_borrowing_details_attributes: removeObject(
            borrowingDetailsOriginal,
            formik.values.p_application_header.p_borrowing_details_attributes
              .length === 2
              ? [
                  formik.values.p_application_header
                    .p_borrowing_details_attributes[0],
                  {
                    ...formik.values.p_application_header
                      .p_borrowing_details_attributes[1],
                    loan_term_year_num:
                      formik.values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_year_num,
                    loan_term_month_num:
                      formik.values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_month_num,
                    borrowing_detail_type: '2',
                    repayment_method:
                      formik.values.p_application_header
                        .p_borrowing_details_attributes[0].repayment_method,
                  },
                ]
              : formik.values.p_application_header
                  .p_borrowing_details_attributes
          ),
        },
      };

      const isDataNewest =
        !!sbiId && !!preliminaryId && !!timeGetEdit
          ? // @ts-ignore
            await checkUpdateFormManager(preliminaryId, timeGetEdit, sbiId, {
              id: preliminaries.p_application_header.id,
              ...newValue,
            })
          : true;

      const applicant_id =
        preliminaries.p_application_header.p_applicant_people_attributes[0].id;
      const applicant_id_income =
        preliminaries.p_application_header.p_applicant_people_attributes
          .length > 1
          ? preliminaries.p_application_header.p_applicant_people_attributes[1]
              .id
          : '';

      if (selectTab === '1') {
        data.append(
          'identity_verification',
          filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
            ? '1'
            : filesUploadOption === FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
            ? '2'
            : '0'
        );
      } else {
        data.append(
          'identity_verification',
          filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
            ? '1'
            : filesUploadOption === FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
            ? '2'
            : '0'
        );
      }

      // const isDataNewest =
      //   !!sbiId && !!preliminaryId && !!timeGetEdit
      //     ? await checkUpdateFormManager(preliminaryId, timeGetEdit, sbiId)
      //     : true;

      /*
        ---Logic upload new file, remove file, remove all old file same for saleperson---
        - If remove all old file, put empty with param ${keyValue}[]
        - If upload new file, put file data with param ${keyValue}[]
        - Remove apart of old file, want keep the rest of files, put id file with param ${keyValue}[]
      */
      // data file in header
      Object.keys(paramsHeaderFiles).forEach((key) => {
        const paramsHeaderImages = get(paramsHeaderFiles, key, []);
        if (paramsHeaderImages.length === 0) {
          const keyValue = key.replace('_index', '');
          return data.append(`${keyValue}[]`, '');
        }
        paramsHeaderImages.forEach((file: unknown) => {
          if (file instanceof File) {
            const keyValue = key.replace('_index', '');
            data.append(`${keyValue}[]`, file);
          } else {
            const keyValue = key.replace('_index', '');
            // @ts-ignore
            data.append(`${keyValue}[]`, file.id);
          }
        });
      });

      // data borrowing
      Object.keys(paramsFileBorrowing).forEach((key) => {
        const paramsBorrowingImages = get(paramsFileBorrowing, key, []);
        const [keyValue, index] = key.split('_index__');
        const borrowingId =
          preliminaries.p_application_header.p_borrowings_attributes[
            Number(index)
          ]?.id;
        if (paramsBorrowingImages.length === 0 && !!borrowingId) {
          data.append(`p_borrowings_attributes[${index}][id]`, borrowingId);
          data.append(`p_borrowings_attributes[${index}][${keyValue}][]`, '');
        }
        paramsBorrowingImages.forEach((file: unknown) => {
          const [keyValue, index] = key.split('_index__');
          const borrowingId =
            preliminaries.p_application_header.p_borrowings_attributes[
              Number(index)
            ]?.id;
          if (!!borrowingId) {
            if (file instanceof File) {
              data.append(`p_borrowings_attributes[${index}][id]`, borrowingId);
              data.append(
                `p_borrowings_attributes[${index}][${keyValue}][]`,
                file
              );
            } else {
              const [keyValue, index] = key.split('_index__');
              data.append(`p_borrowings_attributes[${index}][id]`, borrowingId);
              data.append(
                `p_borrowings_attributes[${index}][${keyValue}][]`,
                // @ts-ignore
                file.id
              );
            }
          }
        });
      });

      // for 申込人（A）
      if (selectTab === '1' && applicant_id) {
        // files
        Object.keys(paramsFiles).forEach((key) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          const paramsImages = get(paramsFiles, key, []);
          if (paramsImages.length === 0) {
            return isNotUploadMulti
              ? data.append(`${key}`, '')
              : data.append(`${key}[]`, '');
          }
          paramsImages.forEach((file: unknown) => {
            if (file instanceof File) {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  data.append(`${key}`, file);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                data.append(`${key}[]`, file);
              }
            } else {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  //@ts-ignore
                  data.append(`${key}`, file.id);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                //@ts-ignore
                data.append(`${key}[]`, file.id);
              }
            }
          });
        });
        if (isDataNewest) {
          const resultForA = await dispatch(
            updateUploadedFilesManager({
              id: preliminaryId,
              applicant_id,
              data,
            })
          );
          if (updateUploadedFilesManager.fulfilled.match(resultForA)) {
            await formik.submitForm();
          } else {
            handleShowError(resultForA);
          }
        }
      }

      // for 収入合算者
      if (selectTab === '3' && applicant_id_income) {
        // files
        Object.keys(paramsFilesIncomeAdder).forEach((key) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          const paramsIncomeImages = get(paramsFilesIncomeAdder, key, []);
          if (paramsIncomeImages.length === 0) {
            return isNotUploadMulti
              ? data.append(`${key}`, '')
              : data.append(`${key}[]`, '');
          }
          paramsIncomeImages.forEach((file: unknown) => {
            if (file instanceof File) {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  data.append(`${key}`, file);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                data.append(`${key}[]`, file);
              }
            } else {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  //@ts-ignore
                  data.append(`${key}`, file.id);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                //@ts-ignore
                data.append(`${key}[]`, file.id);
              }
            }
          });
        });

        if (isDataNewest) {
          const resultForIncome = await dispatch(
            updateUploadedFilesManager({
              id: preliminaryId,
              applicant_id: applicant_id_income,
              data,
            })
          );

          if (updateUploadedFilesManager.fulfilled.match(resultForIncome)) {
            await formik.submitForm();
          } else {
            handleShowError(resultForIncome);
          }
        }
      }

      // case change loan_type ==> THREE or FOUR
      if (
        selectTab === '3' &&
        !applicant_id_income &&
        !!p_applicant_people_attributes
      ) {
        // files
        Object.keys(paramsFilesIncomeAdder).forEach((key) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          const paramsIncomeImages = get(paramsFilesIncomeAdder, key, []);
          if (paramsIncomeImages.length === 0) {
            return isNotUploadMulti
              ? data.append(`${key}`, '')
              : data.append(`${key}[]`, '');
          }
          paramsIncomeImages.forEach((file: unknown) => {
            if (file instanceof File) {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  data.append(`${key}`, file);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                data.append(`${key}[]`, file);
              }
            } else {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  //@ts-ignore
                  data.append(`${key}`, file.id);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                //@ts-ignore
                data.append(`${key}[]`, file.id);
              }
            }
          });
        });

        if (isDataNewest) {
          const result = await dispatch(
            // @ts-ignore
            editAdminPreliminary({
              id: preliminaries.p_application_header.id,
              ...newValue,
            })
          );
          setSelectIncomeSubTab(0);
          if (editAdminPreliminary.fulfilled.match(result)) {
            // TODO: CR change logic select file image
            data.append(`change_loan_type`, '1');
            const resultForIncome = await dispatch(
              updateUploadedFilesManager({
                id: preliminaryId,
                data,
              })
            );
            if (updateUploadedFilesManager.fulfilled.match(resultForIncome)) {
              await dispatch(
                getPersonalInformations({
                  id: preliminaryId,
                })
              );
              if (!hiddenSuccessRef.current) {
                toast.success('申込内容を更新しました。');
              }
            } else {
              handleShowError(resultForIncome);
            }
          }
        }
      }
    },
    [
      borrowingDetailsOriginal,
      borrowingsAttributesOriginal,
      checkUpdateFormManager,
      dispatch,
      filesUploadOption,
      formik.values,
      getBorrowings,
      getReferralIdAction,
      joinGuarantorsAttributesOriginal,
      preliminaries.p_application_header.id,
      preliminaries.p_application_header.p_applicant_people_attributes,
      preliminaries.p_application_header.p_borrowings_attributes,
      preliminaryId,
      sbiId,
      selectTab,
      timeGetEdit,
    ]
  );

  const changeModalMessage = () => {
    const addNewPeopleAttribute =
      formik.values.p_application_header.p_applicant_people_attributes?.[1]
        ?.id === APPLICANT_PEOPLE_ATTRIBUTE.id;

    const addNewJoinGuarantor =
      formik.values.p_application_header.p_join_guarantors_attributes?.[0]
        ?.id === JOIN_GUARANTORS_ATTRIBUTE.id;

    // tab 1 => joinGuarantor
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      addNewJoinGuarantor &&
      !addNewPeopleAttribute
    ) {
      return setChangeModalMessage(
        `このタブのすべての項目入力は「担保提供者」タブの「保存」ボタンを押下した後に反映します。\n「担保提供者」の入力を完了してください。`
      );
    }

    // tab 1 => income
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      [LoanType.THREE, LoanType.FOUR].includes(
        formik.values.p_application_header.loan_type
      ) &&
      addNewPeopleAttribute &&
      !addNewJoinGuarantor
    ) {
      return setChangeModalMessage(
        `このタブのすべての項目入力は「収入合算者」タブの「保存」ボタンを押下した後に反映します。\n「収入合算者」の入力を完了してください。`
      );
    }
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      addNewPeopleAttribute &&
      addNewJoinGuarantor
    ) {
      return setChangeModalMessage(
        `このタブのすべての項目入力は「収入合算者」タブの「保存」ボタンを押下した後に反映します。\n「担保提供者」の入力と「収入合算者」の入力を両方完了してください。`
      );
    }
    return;
  };

  const changeModalOnClose = () => {
    const addNewPeopleAttribute =
      formik.values.p_application_header.p_applicant_people_attributes?.[1]
        ?.id === APPLICANT_PEOPLE_ATTRIBUTE.id;

    const addNewJoinGuarantor =
      formik.values.p_application_header.p_join_guarantors_attributes?.[0]
        ?.id === JOIN_GUARANTORS_ATTRIBUTE.id;

    // tab 1 => joinGuarantor
    if (selectSubTab === 0 && selectTab === '1' && addNewJoinGuarantor) {
      setSelectSubTab(3);
      window.scrollTo(0, 0);
      isSubmittedEditRef.current = false;
      return setChangeModal(false);
    }

    // tab 1 => income
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      [LoanType.THREE, LoanType.FOUR].includes(
        formik.values.p_application_header.loan_type
      ) &&
      addNewPeopleAttribute
    ) {
      setSelectTab('3');
      window.scrollTo(0, 0);
      isSubmittedEditRef.current = false;
      return setChangeModal(false);
    }
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      addNewPeopleAttribute &&
      addNewJoinGuarantor
    ) {
      setSelectSubTab(3);
      window.scrollTo(0, 0);
      isSubmittedEditRef.current = false;
      return setChangeModal(false);
    }
  };

  const genParamBorrowingFiles = useCallback(
    (index: number) => {
      return index < 0 || index > 7
        ? formik.values.filesBorrowing.repayment_schedule_image_index__0
        : // @ts-ignore
          formik.values.filesBorrowing[
            `repayment_schedule_image_index__${index}`
          ];
    },
    [formik.values.filesBorrowing]
  );

  const genParamBorrowingFilesLink = useCallback(
    (index: number) => {
      return index < 0 || index > 7
        ? formik.values.filesLinkBorrowing.repayment_schedule_image_index__0
        : // @ts-ignore
          formik.values.filesLinkBorrowing[
            `repayment_schedule_image_index__${index}`
          ];
    },
    [formik.values.filesLinkBorrowing]
  );

  const getBorrowingDetail = useCallback(
    (link = 'p_application_header') =>
      buildBorrowingDetail(get(formik.values, link)),
    [formik.values]
  );

  const getFinancingPlan = useCallback(
    (link = 'p_application_header') =>
      buildFinancingPlan(get(formik.values, link)),
    [formik.values]
  );

  const getCurrentBorrowing = useCallback(
    (link = 'p_application_header') =>
      buildCurrentBorrowing(managerRole, get(formik.values, link)),
    [formik.values, managerRole]
  );

  const getPersonalResidence = useCallback(
    (link = 'p_application_header') => {
      const newRows = buildPersonalRows(
        PERSONAL_RESIDENCE,
        get(formik.values, link),
        plannedCohabitantDynamic,
        managerRole
      );
      return buildPersonalResidence(
        managerRole,
        newRows,
        get(formik.values, link)
      );
    },
    [formik.values, managerRole, plannedCohabitantDynamic]
  );

  const getPersonalOccupation = useCallback(
    (link = 'p_application_header', index = 0) =>
      buildPersonalOccupation(get(formik.values, link), index),
    [formik.values]
  );

  const getGuaranteeProvider = useCallback(
    (link = 'p_application_header') =>
      buildGuaranteeProvider(get(formik.values, link)),
    [formik.values]
  );

  const getPersonalInformation = useCallback(
    (link = 'p_application_header') =>
      buildPersonalInformation(get(formik.values, link)),
    [formik.values]
  );

  const getContactInformation = useCallback(
    (link = 'p_application_header') =>
      buildContactInformationSalePerson(get(formik.values, link)),
    [formik.values]
  );

  const getIncomeTotalizer = useCallback(
    (link = 'p_application_header') =>
      buildIncomeTotalizer(get(formik.values, link)),
    [formik.values]
  );

  const getCurrentIds = useCallback(() => {
    const currentIds =
      formik.values.p_application_header.p_applicant_people_attributes?.map(
        ({ id }) => id
      );
    if (['3', '4'].includes(preliminaries.p_application_header.loan_type)) {
      return currentIds;
    }
    return [currentIds?.[0]];
  }, [
    formik.values.p_application_header.p_applicant_people_attributes,
    preliminaries.p_application_header.loan_type,
  ]);

  const getParents = useCallback(() => {
    const currentBorrowingSecond = getCurrentBorrowing()?.[1];
    const tabA = [
      {
        label: 'お借入のご希望',
        values: [
          ...getBorrowingDetail(),
          {
            name_ja: '',
            name_en: 'second_loan_desired_borrowing_date',
            dynamicType: null,
          },
          {
            name_ja: '',
            name_en:
              'p_borrowing_details_attributes[0].debt_payment_age_over_80_years_old',
            dynamicType: null,
          },
        ],
      },
      {
        label: 'あなたの情報',
        values: getPersonalInformation(),
      },
      {
        label: 'ご職業',
        values: getPersonalOccupation(),
      },
      {
        label: '担保提供者',
        values: getGuaranteeProvider()?.[0] || [],
      },
      {
        label: 'お住まい',
        values: getPersonalResidence(),
      },
      {
        label: '現在の借入状況',
        values: (!!currentBorrowingSecond
          ? [
              ...currentBorrowingSecond,
              {
                name_ja: '',
                name_en: 'borrowing_homeloan',
                dynamicType: null,
              },
            ]
          : [
              {
                name_ja: '',
                name_en: 'borrowing_homeloan',
                dynamicType: null,
              },
            ]) as AdminPreliminaryItem[],
      },
      {
        label: '資金計画',
        values: getFinancingPlan(),
      },
      {
        label: '担当者情報',
        values: getContactInformation(),
      },
      {
        label: '書類アップロード',
        values: [
          {
            name_ja: '在留カード 〈表面〉',
            name_en: 'p_applicant_people_attributes[0].residence_file',
            dynamicType: null,
          },
          {
            name_ja: '在留カード 〈裏面〉',
            name_en:
              'p_applicant_people_attributes[0].residence_file_back_image',
            dynamicType: null,
          },
        ],
      },
    ];
    if (['3', '4'].includes(preliminaries.p_application_header.loan_type)) {
      return [
        tabA,
        [
          {
            label: '収入合算者の情報',
            values: getIncomeTotalizer(),
          },
          {
            label: '収入合算者のご職業',
            values: getPersonalOccupation('p_application_header', 1),
          },
          {
            label: '書類アップロード',
            values: [
              {
                name_ja: '運転免許書・マイナンバーカード・ 住民基本台帳カード',
                name_en:
                  'p_applicant_people_attributes[0].identity_verification',
                dynamicType: null,
              },
              {
                name_ja:
                  '運転免許書・マイナンバーカード・住民基本台帳カード＜表面＞',
                name_en:
                  'p_applicant_people_attributes[0].driver_license_front_image',
                dynamicType: null,
              },
              {
                name_ja:
                  '運転免許書・マイナンバーカード・住民基本台帳カード＜裏面＞',
                name_en:
                  'p_applicant_people_attributes[0].driver_license_back_image',
                dynamicType: null,
              },
              {
                name_ja:
                  '運転免許書・マイナンバーカード・住民基本台帳カード＜表面＞',
                name_en:
                  'p_applicant_people_attributes[0].card_number_front_image',
                dynamicType: null,
              },
              {
                name_ja:
                  '運転免許書・マイナンバーカード・住民基本台帳カード＜表面＞',
                name_en:
                  'p_applicant_people_attributes[0].resident_register_front_image',
                dynamicType: null,
              },
              {
                name_ja:
                  '運転免許書・マイナンバーカード・住民基本台帳カード＜裏面＞',
                name_en:
                  'p_applicant_people_attributes[0].resident_register_back_image',
                dynamicType: null,
              },
              {
                name_ja: '在留カード 〈表面〉',
                name_en: 'p_applicant_people_attributes[1].residence_file',
                dynamicType: null,
              },
              {
                name_ja: '在留カード 〈裏面〉',
                name_en:
                  'p_applicant_people_attributes[1].residence_file_back_image',
                dynamicType: null,
              },
            ],
          },
        ],
      ];
    }
    return [tabA];
  }, [
    getBorrowingDetail,
    getCurrentBorrowing,
    getFinancingPlan,
    getGuaranteeProvider,
    getIncomeTotalizer,
    getPersonalInformation,
    getPersonalOccupation,
    getPersonalResidence,
    preliminaries.p_application_header.loan_type,
  ]);

  return (
    <AdminLayout
      loading={loading}
      hasDrawer={false}
      header={{
        left: { mainTitle: fullNameKanji },
        right: { Icons: true, userName: '' },
      }}
      footer={{
        left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
        right: <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />,
        bgcolor: 'footer_bg',
      }}
    >
      {editable && (
        <ResetData
          formik={formik}
          dataIncome={dataIncome}
          dataJoinGuarantor={dataJoinGuarantor}
          selectSubTab={selectSubTab}
        />
      )}
      <UpdateSBISuccessModal
        open={updateStatusSBISuccess}
        onClose={() => setUpdateStatusSBISuccess(false)}
      />
      <Stack sx={{ position: 'relative' }}>
        <Stack
          direction="row"
          sx={{
            pt: '10px',
            pb: '5px',
            pr: '34px',
            pl: '37px',
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row">
            <Stack direction="row">
              {preliminaries.p_application_header.main_statuses.length > 0 && (
                <ResultsStatus
                  editable={
                    formik.values.p_application_header.edit_main_statuses
                  }
                  isInvalid={
                    !formik.isValid ||
                    (selectTab === '1' && isDisableButtonSubmit) ||
                    (selectTab === '3' && isDisableButtonSubmitForIncome)
                  }
                  mainStatuses={mainStatuses}
                  onUpdate={updateStatus}
                  checkIsEdit={() =>
                    !isEqual(
                      initialValues.p_application_header,
                      formik.values.p_application_header
                    )
                  }
                  isChangeFiles={formik.values.isChangeFiles}
                />
              )}
              {openModalBank && (
                <ModalConfirmLinkToBank
                  open={openModalBank}
                  jsonErrorMessage={jsonErrorMessage}
                  textErrorMessage={textErrorMessage}
                  errorsCheckStatusInternal={errorsCheckStatusInternal}
                  isInternal={isInternal}
                  isSuccess={isUpdateSuccessSBI}
                  isInterfaceErrorStatus={isInterfaceErrorStatus}
                  showParent={showParent}
                  currentIds={getCurrentIds()}
                  parents={getParents() && getParents()}
                  onClose={() => setOpenModalBank(false)}
                />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center">
            <ButtonDownloadExcel
              preliminaryId={
                selectTab === '1' || selectTab === '3'
                  ? formik.values.p_application_header.id
                  : formik.values.link?.p_application_header?.id
              }
              applicationNumber={
                selectTab === '1' || selectTab === '3'
                  ? formik.values.p_application_header.application_number
                  : formik.values.link?.p_application_header?.application_number
              }
              firstNameKanji={
                selectTab === '1' || selectTab === '3'
                  ? formik.values.p_application_header
                      .p_applicant_people_attributes?.[0]?.first_name_kanji
                  : formik.values.link?.p_application_header
                      ?.p_applicant_people_attributes?.[0]?.first_name_kanji
              }
              lastNameKanji={
                selectTab === '1' || selectTab === '3'
                  ? formik.values.p_application_header
                      .p_applicant_people_attributes?.[0]?.last_name_kanji
                  : formik.values.link?.p_application_header
                      ?.p_applicant_people_attributes?.[0]?.last_name_kanji
              }
            />
            <PulldownDocuments
              itemPreliminary={
                selectTab === '1' || selectTab === '3'
                  ? preliminaries
                  : formik.values.link
              }
              preliminaryId={
                selectTab === '1' || selectTab === '3'
                  ? preliminaryId
                  : preliminaries.p_application_header.linking_id
              }
              applicantId={get(
                formik.values,
                selectTab === '1'
                  ? 'p_application_header.p_applicant_people_attributes[0].id'
                  : selectTab === '2'
                  ? 'link.p_application_header.p_applicant_people_attributes[0].id'
                  : 'p_application_header.p_applicant_people_attributes[1].id',
                ''
              )}
              isCardNumberImage={isCardNumberImage}
              isDriverLicenseImage={isDriverLicenseImage}
              isIncomeTotal={selectTab === '3'}
              selectTab={selectTab}
              // isFirstWithholdingSlipFile={isFirstWithholdingSlipFile}
            />
          </Stack>
        </Stack>
        <Divider sx={{ color: 'gray' }} />
        <FormikProvider value={formik}>
          <Box
            sx={{
              mt: '10px',
              mx: '37px',
              mb: 10,
              border: '1px solid #aaa',
              borderTop: 'none',
              borderTopLeftRadius: '5px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              position: 'relative',
            }}
          >
            {preliminaries.p_application_header.loan_type === '2' &&
              preliminaries.p_application_header.linking_id && (
                <IconButton
                  onClick={() => {
                    navigate({
                      pathname: routeNames.AdminEditPreliminary.path,
                      search: `?preliminary_id=${preliminaries.p_application_header?.linking_id}`,
                    });
                  }}
                  sx={{
                    position: 'absolute',
                    zIndex: 2,
                    left: 140,
                    top: 2,
                    color: '#6b70f0',
                  }}
                >
                  <SwapHorizIcon />
                </IconButton>
              )}
            <TabContext value={selectTab}>
              <TabList onChange={handleChangeTab}>
                <Tab
                  label="申込人（A）"
                  value="1"
                  sx={{
                    color: 'b_333',
                    '&&.Mui-selected': {
                      ...styleMuiTabSelected,
                      '&.MuiTabs-indicator': {
                        display: 'none',
                      },
                    },
                    '&.MuiTab-textColorPrimary': { ...styleMuiTabs, mr: '5px' },
                  }}
                />
                {preliminaries.p_application_header.loan_type === '2' &&
                  preliminaries.p_application_header.linking_id && (
                    <Tab
                      label="申込人（B）"
                      value="2"
                      sx={{
                        '&&.Mui-selected': {
                          ...styleMuiTabSelected,
                          '&.MuiTabs-indicator': {
                            display: 'none',
                          },
                        },
                        '&.MuiTab-textColorPrimary': {
                          ...styleMuiTabs,
                          mr: '5px',
                        },
                        '& .MuiTabs-indicator': {
                          display: 'none',
                        },
                      }}
                    />
                  )}
                {(formik.values.p_application_header.loan_type === '3' ||
                  formik.values.p_application_header.loan_type === '4') && (
                  <Tab
                    label="収入合算者"
                    value="3"
                    disabled={
                      !formik.values.p_application_header
                        .p_applicant_people_attributes[1]?.id
                    }
                    sx={{
                      '&&.Mui-selected': styleMuiTabSelected,
                      '&.MuiTab-textColorPrimary': styleMuiTabs,
                      '& .MuiTabs-indicator': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              </TabList>
              {openModalConfirm && (
                <ConfirmModal
                  open={openModalConfirm}
                  onClose={() => setOpenModalConfirm(false)}
                  handleCancel={handleCancel}
                  handleOk={handleOk}
                />
              )}
              <TabPanel
                value="1"
                sx={{ boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)', p: 0 }}
              >
                <TabWrapper value={selectSubTab} onChange={handleChangeSubTab}>
                  <TabsList>
                    <TabChildren>お借入のご希望</TabChildren>
                    <TabChildren>あなたの情報</TabChildren>
                    <TabChildren>ご職業</TabChildren>
                    {formik.values.p_application_header
                      .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0' && <TabChildren>担保提供者</TabChildren>}
                    <TabChildren>お住まい</TabChildren>
                    <TabChildren>現在の借入状況</TabChildren>
                    <TabChildren>資金計画</TabChildren>
                    <TabChildren>担当者情報</TabChildren>
                    <TabChildren>書類アップロード</TabChildren>
                    <TabChildren>審査結果</TabChildren>
                  </TabsList>

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ my: 0 }}
                  >
                    {editable &&
                      !(
                        selectTab === '2' &&
                        preliminaries.p_application_header.linking_id
                      ) &&
                      !showExaminationResultForA &&
                      !showExaminationResultForB && (
                        <Button
                          disabled={
                            (selectTab === '1' && isDisableButtonSubmit) ||
                            (selectTab === '3' &&
                              isDisableButtonSubmitForIncome) ||
                            isDisableSaveButton ||
                            submitting
                          }
                          sx={{
                            width: 204,
                            minHeight: 35,
                            bgcolor: 'sp_secondary_01',
                          }}
                          onClick={async () => {
                            setSubmitting(true);
                            try {
                              if (isTabUploadFiles || isTabUploadFilesIncome) {
                                // create check list image
                                const imageLists: Array<File | SPImage> = [];

                                // get image form header
                                Object.keys(formik.values.filesHeader).forEach(
                                  (key) => {
                                    const paramsHeaderImages = get(
                                      formik.values.filesHeader,
                                      key,
                                      []
                                    );
                                    paramsHeaderImages.forEach(
                                      (file: File | SPImage) => {
                                        imageLists.push(file);
                                      }
                                    );
                                  }
                                );
                                // get image form borrowing
                                Object.keys(
                                  formik.values.filesBorrowing
                                ).forEach((key) => {
                                  const paramsBorrowImages = get(
                                    formik.values.filesBorrowing,
                                    key,
                                    []
                                  );
                                  paramsBorrowImages.forEach(
                                    (file: File | SPImage) => {
                                      const [keyValue, index] =
                                        key.split('_index__');
                                      const borrowingId =
                                        preliminaries.p_application_header
                                          .p_borrowings_attributes[
                                          Number(index)
                                        ]?.id;
                                      if (!!borrowingId) imageLists.push(file);
                                    }
                                  );
                                });
                                // get image form people 1
                                Object.keys(formik.values.files).forEach(
                                  (key) => {
                                    const paramsImages = get(
                                      formik.values.files,
                                      key,
                                      []
                                    );
                                    paramsImages.forEach(
                                      (file: File | SPImage) => {
                                        imageLists.push(file);
                                      }
                                    );
                                  }
                                );
                                // get image form people 2
                                Object.keys(
                                  formik.values.filesIncomeAdder
                                ).forEach((key) => {
                                  const paramsIncomeImages = get(
                                    formik.values.filesIncomeAdder,
                                    key,
                                    []
                                  );
                                  paramsIncomeImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                });

                                const promises = imageLists.map((image) => {
                                  const type =
                                    image instanceof File
                                      ? image.type
                                      : getFileExtFromUrl(image.url);
                                  if (type.includes('pdf')) {
                                    if (image instanceof File) {
                                      return checkIfPDFExists(
                                        URL.createObjectURL(image)
                                      );
                                    } else {
                                      return checkIfPDFExists(image.url);
                                    }
                                  } else {
                                    if (image instanceof File) {
                                      return checkIfImageExists(image);
                                    } else {
                                      return checkIfImageExists(image.url);
                                    }
                                  }
                                });

                                Promise.all(promises).then((result) => {
                                  if (!result.every((item) => item)) {
                                    modalErrorRef.current?.open(
                                      '',
                                      FILE_NOT_EXIST,
                                      true
                                    );
                                    return;
                                  } else {
                                    handleSubmitEditUploadedFiles(
                                      formik.values.files,
                                      formik.values.filesIncomeAdder,
                                      formik.values.filesHeader,
                                      formik.values.filesBorrowing,
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes
                                    );
                                  }
                                });
                              } else {
                                await formik.submitForm();
                              }
                            } catch (e) {}
                            setSubmitting(false);
                          }}
                        >
                          保存
                        </Button>
                      )}
                  </Stack>
                  <TabPanels value={0} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 0 && preliminaryId && (
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getBorrowingDetail()}
                        parentKey="p_application_header"
                        editable={editable}
                      />
                    )}
                  </TabPanels>
                  <TabPanels value={1} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 1 && preliminaryId && (
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getPersonalInformation()}
                        parentKey="p_application_header"
                        editable={editable}
                      />
                    )}
                  </TabPanels>
                  <TabPanels value={2} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 2 && preliminaryId && (
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getPersonalOccupation()}
                        parentKey="p_application_header"
                        editable={editable}
                      />
                    )}
                  </TabPanels>
                  {formik.values.p_application_header
                    .p_applicant_people_attributes[0].has_join_guarantor !==
                    '0' && (
                    <TabPanels value={3} sx={{ width: '100%', pb: 5 }}>
                      {selectSubTab === 3 && preliminaryId && (
                        <Stack spacing={7}>
                          {getGuaranteeProvider().map((table, index) => (
                            <Stack key={index}>
                              <Typography variant="pdf_title" fontSize={15}>
                                担保提供者
                                <Typography variant="pdf_title">
                                  （{index + 1}人目）
                                </Typography>
                              </Typography>
                              <AdminPreliminaryTable
                                parentTabIndex={selectTab}
                                appraisalId={preliminaryId}
                                rows={table}
                                parentKey="p_application_header"
                                editable={editable}
                                noAdd
                                indexJoinGuarantor={index}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      )}
                    </TabPanels>
                  )}

                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 4
                        : 3
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 4
                        : 3) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={getPersonalResidence()}
                          parentKey="p_application_header"
                          quantityDependent={{
                            siblings_number:
                              formik.values.p_application_header
                                .siblings_number,
                            other_people_number:
                              formik.values.p_application_header
                                .other_people_number,
                            children_number:
                              formik.values.p_application_header
                                .children_number,
                          }}
                          editable={editable}
                          yearLength={getYearLength(
                            formik.values.p_application_header
                              .p_applicant_people_attributes?.[0]?.birthday
                          )}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 5
                        : 4
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 5
                        : 4) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={getCurrentBorrowing()}
                          parentKey="p_application_header"
                          isBorrowing
                          isArray
                          editable={editable}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 6
                        : 5
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 6
                        : 5) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={getFinancingPlan()}
                          parentKey="p_application_header"
                          editable={editable}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 7
                        : 6
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 7
                        : 6) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={buildContactInformation()}
                          parentKey="p_application_header"
                          editable={editable}
                        />
                      )}
                  </TabPanels>
                  {/* For 申込人（A） */}
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 8
                        : 7
                    }
                    sx={{
                      backgroundColor: 'main_white',
                      overflowY: 'scroll',
                      height: 'calc(100vh - 340px)',
                    }}
                  >
                    <Stack direction="row" spacing={4} sx={{ pb: '65px' }}>
                      <Stack
                        flex={1}
                        spacing={4}
                        sx={{
                          width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                        }}
                      >
                        {/* A */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={false}
                            isDisplay={true}
                            title="本人確認書類"
                            name="A"
                            setOpenPreview={handlePreview}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'driver_license_front_image'
                                  ),
                                jp_name: `運転免許書`,
                                en_name: 'driver_license_front_image',
                                isRequired: isRequiredUploadFile(
                                  'driver_license_front_image',
                                  true
                                ),
                                files:
                                  formik.values.files
                                    .driver_license_front_image,
                              },
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'driver_license_back_image'
                                  ),
                                jp_name: '運転免許書',
                                en_name: 'driver_license_back_image',
                                files:
                                  formik.values.files.driver_license_back_image,
                                isRequired: isRequiredUploadFile(
                                  'driver_license_back_image',
                                  true
                                ),
                              },
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'card_number_front_image'
                                  ),
                                jp_name: `マイナンバーカード`,
                                en_name: 'card_number_front_image',
                                isRequired: isRequiredUploadFile(
                                  'card_number_front_image',
                                  true
                                ),
                                files:
                                  formik.values.files.card_number_front_image,
                              },
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'resident_register_front_image'
                                  ),
                                jp_name: `住民基本台帳カード`,
                                en_name: 'resident_register_front_image',
                                isRequired: isRequiredUploadFile(
                                  'resident_register_front_image',
                                  true
                                ),
                                files:
                                  formik.values.files
                                    .resident_register_front_image,
                              },
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'resident_register_back_image'
                                  ),
                                jp_name: '住民基本台帳カード',
                                en_name: 'resident_register_back_image',
                                isRequired: isRequiredUploadFile(
                                  'resident_register_back_image',
                                  true
                                ),
                                files:
                                  formik.values.files
                                    .resident_register_back_image,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            filesUploadOption={filesUploadOption}
                            handleRadioChange={(option) => {
                              formik.setFieldValue('isChangeFiles', true);
                              setFilesUploadOption(option);
                            }}
                            onChange={handleChangeFiles}
                          />
                        </Stack>
                        {/* B */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={false}
                            isDisplay={true}
                            title="健康保険証"
                            name="B"
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'insurance_file'
                                  ),
                                jp_name: '健康保険証〈表面〉',
                                en_name: 'insurance_file',
                                isRequired:
                                  isRequiredUploadFile('insurance_file'),
                                files: formik.values.files.insurance_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={false}
                            isDisplay={true}
                            title=""
                            name=""
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'insurance_file_back_image'
                                  ),
                                jp_name: '健康保険証〈裏面〉',
                                en_name: 'insurance_file_back_image',
                                isRequired: isRequiredUploadFile(
                                  'insurance_file_back_image'
                                ),
                                files:
                                  formik.values.files.insurance_file_back_image,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        {/* C */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            //isFirstWithholdingSlipFile()
                            isDisplay={true}
                            title="収入に関する書類"
                            name="C"
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'first_withholding_slip_file'
                                  ),
                                jp_name: '源泉徴収票（前年度分）',
                                en_name: 'first_withholding_slip_file',
                                isRequired: isRequiredUploadFile(
                                  'first_withholding_slip_file'
                                ),
                                files:
                                  formik.values.files
                                    .first_withholding_slip_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            // title={
                            //   isFirstWithholdingSlipFile()
                            //     ? ''
                            //     : '収入に関する書類'
                            // }
                            // name={isFirstWithholdingSlipFile() ? '' : 'C'}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'second_withholding_slip_file'
                                  ),
                                jp_name: '源泉徴収票（前々年度分）',
                                en_name: 'second_withholding_slip_file',
                                isRequired: isRequiredUploadFile(
                                  'second_withholding_slip_file'
                                ),
                                files:
                                  formik.values.files
                                    .second_withholding_slip_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'first_income_file'
                                  ),
                                jp_name: '確定申告書（1期前）',
                                en_name: 'first_income_file',
                                isRequired:
                                  isRequiredUploadFile('first_income_file'),
                                files: formik.values.files.first_income_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'second_income_file'
                                  ),
                                jp_name: '確定申告書（2期前）',
                                en_name: 'second_income_file',
                                isRequired:
                                  isRequiredUploadFile('second_income_file'),
                                files: formik.values.files.second_income_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'third_income_file'
                                  ),
                                jp_name: '確定申告書（3期前）',
                                en_name: 'third_income_file',
                                isRequired:
                                  isRequiredUploadFile('third_income_file'),
                                files: formik.values.files.third_income_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        {/* D */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            title="非上場企業の役員の方の書類"
                            name="D"
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'financial_statement_1_file'
                                  ),
                                jp_name: '会社の決算報告書（1期前）',
                                en_name: 'financial_statement_1_file',
                                isRequired: isRequiredUploadFile(
                                  'financial_statement_1_file'
                                ),
                                files:
                                  formik.values.files
                                    .financial_statement_1_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'financial_statement_2_file'
                                  ),
                                jp_name: '会社の決算報告書（2期前）',
                                en_name: 'financial_statement_2_file',
                                isRequired: isRequiredUploadFile(
                                  'financial_statement_2_file'
                                ),
                                files:
                                  formik.values.files
                                    .financial_statement_2_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'financial_statement_3_file'
                                  ),
                                jp_name: '会社の決算報告書（3期前）',
                                en_name: 'financial_statement_3_file',
                                isRequired: isRequiredUploadFile(
                                  'financial_statement_3_file'
                                ),
                                files:
                                  formik.values.files
                                    .financial_statement_3_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        {/* E */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            title="雇用契約に関する書類"
                            name="E"
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'employment_agreement_file'
                                  ),
                                jp_name: '雇用契約書',
                                en_name: 'employment_agreement_file',
                                isRequired: isRequiredUploadFile(
                                  'employment_agreement_file'
                                ),
                                files:
                                  formik.values.files.employment_agreement_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                      </Stack>
                      <Stack
                        flex={1}
                        spacing={4}
                        sx={{
                          width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                        }}
                      >
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            title="親族経営の会社等にご勤務の方に関する書類"
                            name="F"
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'business_tax_return_1_file'
                                  ),
                                jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（1期前）`,
                                en_name: 'business_tax_return_1_file',
                                isRequired: isRequiredUploadFile(
                                  'business_tax_return_1_file'
                                ),
                                files:
                                  formik.values.files
                                    .business_tax_return_1_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'business_tax_return_2_file'
                                  ),
                                jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（2期前）`,
                                en_name: 'business_tax_return_2_file',
                                isRequired: isRequiredUploadFile(
                                  'business_tax_return_2_file'
                                ),
                                files:
                                  formik.values.files
                                    .business_tax_return_2_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'business_tax_return_3_file'
                                  ),
                                jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（3期前）`,
                                en_name: 'business_tax_return_3_file',
                                isRequired: isRequiredUploadFile(
                                  'business_tax_return_3_file'
                                ),
                                files:
                                  formik.values.files
                                    .business_tax_return_3_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItemHeader
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            title="物件についての書類"
                            name="G"
                            options={[
                              {
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicationHeader',
                                isHasLog:
                                  formik.values.p_application_header.has_log_fields?.includes(
                                    'property_information_file'
                                  ),
                                jp_name: 'チラシ・パンフレット',
                                en_name: 'property_information_file_index',
                                isRequired: isRequiredUploadFile(
                                  'property_information_file_index'
                                ),
                                files:
                                  formik.values.filesHeader
                                    .property_information_file_index,
                              },
                            ]}
                            onChange={handleChangeFilesHeader(
                              'property_information_file_index'
                            )}
                            setOpenPreview={handlePreview}
                          />
                          {/* HOMELOAN-2299 */}
                          {/* <DocumentUploadItemHeader
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            title=""
                            name=""
                            options={[
                              {
                                jp_name: '物件書類（登記簿謄本等）',
                                en_name:
                                  'manager_property_information_file_index',
                                isRequired: isRequiredUploadFile(
                                  'manager_property_information_file_index'
                                ),
                                files:
                                  formik.values.filesHeader
                                    .manager_property_information_file_index,
                              },
                            ]}
                            onChange={handleChangeFilesHeader(
                              'manager_property_information_file_index'
                            )}
                            setOpenPreview={handlePreview}
                          /> */}
                        </Stack>
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={false}
                            isDisplay={true}
                            setOpenPreview={handlePreview}
                            title="在留カード"
                            name="H"
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'residence_file'
                                  ),
                                jp_name: '在留カード 〈表面〉',
                                en_name: 'residence_file',
                                isRequired: isRequiredUploadFile(
                                  'residence_file',
                                  true
                                ),
                                files: formik.values.files.residence_file,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                          />
                          <DocumentUploadItem
                            editable={editable}
                            multiple={false}
                            isDisplay={true}
                            setOpenPreview={handlePreview}
                            title=""
                            name=""
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'residence_file_back_image'
                                  ),
                                jp_name: '在留カード 〈裏面〉',
                                en_name: 'residence_file_back_image',
                                isRequired: isRequiredUploadFile(
                                  'residence_file_back_image',
                                  true
                                ),
                                files:
                                  formik.values.files.residence_file_back_image,
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                          />
                        </Stack>
                        {!!preliminaries.p_application_header
                          .p_borrowings_attributes.length && (
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            {preliminaries.p_application_header.p_borrowings_attributes.map(
                              (borrowing, index) => (
                                <DocumentUploadItemBorrowing
                                  key={index}
                                  editable={editable}
                                  multiple={true}
                                  isDisplay={true}
                                  title={
                                    index === 0 ? '返済予定表・利用明細書' : ''
                                  }
                                  setOpenPreview={handlePreview}
                                  name={index === 0 ? 'I' : ''}
                                  options={[
                                    {
                                      borrowId: borrowing.id,
                                      headerId:
                                        preliminaries.p_application_header.id,
                                      trackable_type: 'PBorrowing',
                                      isHasLog:
                                        borrowing.has_log_fields?.includes(
                                          'repayment_schedule_image'
                                        ),
                                      jp_name: `${index + 1}件目の借入`,
                                      // @ts-ignore
                                      en_name: genParamBorrowing(index),
                                      isRequired: isRequiredUploadFile(
                                        'repayment_schedule_image_index'
                                      ),
                                      files: genParamBorrowingFiles(index),
                                    },
                                  ]}
                                  isLoadingFiles={isLoadingFiles}
                                  onChange={handleChangeFilesBorrowing(
                                    'repayment_schedule_image_index',
                                    index
                                  )}
                                />
                              )
                            )}
                          </Stack>
                        )}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItemHeader
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            title="提携会社の担当者名刺"
                            setOpenPreview={handlePreview}
                            name="J"
                            options={[
                              {
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicationHeader',
                                isHasLog:
                                  formik.values.p_application_header.has_log_fields?.includes(
                                    'business_card'
                                  ),
                                jp_name: '',
                                en_name: 'business_card_index',
                                isRequired: isRequiredUploadFile(
                                  'business_card_index'
                                ),
                                files:
                                  formik.values.filesHeader.business_card_index,
                              },
                            ]}
                            onChange={handleChangeFilesHeader(
                              'business_card_index'
                            )}
                          />
                        </Stack>
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            setOpenPreview={handlePreview}
                            title="その他"
                            name="K"
                            options={[
                              {
                                personId:
                                  formik.values.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'other_document_file'
                                  ),
                                jp_name: '',
                                en_name: 'other_document_file',
                                files: formik.values.files.other_document_file,
                                isRequired: isRequiredUploadFile(
                                  'other_document_file'
                                ),
                              },
                            ]}
                            isLoadingFiles={isLoadingFiles}
                            onChange={handleChangeFiles}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </TabPanels>
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 9
                        : 8
                    }
                    sx={{
                      pt: 1,
                      mt: `${examinationUrl ? '50px' : '160px'}`,
                      pb: `${examinationUrl ? '50px' : '170px'}`,
                    }}
                  >
                    {/* for 申込人（A） */}
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 9
                        : 8) && (
                      <>
                        {examinationUrl && statusResult >= 4 ? (
                          <AdminPreviewPDF
                            src={examinationUrl}
                            onRemove={removePdf}
                          />
                        ) : (
                          <SelectPdfFile
                            onChange={handleChangePDF}
                            disabled={statusResult < 4}
                          />
                        )}
                      </>
                    )}
                  </TabPanels>
                </TabWrapper>
              </TabPanel>
              <TabPanel
                value="2"
                sx={{ boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)', p: 0 }}
              >
                <TabWrapper value={selectSubTab} onChange={handleChangeSubTab}>
                  <TabsList>
                    <TabChildren>お借入のご希望</TabChildren>
                    <TabChildren>あなたの情報</TabChildren>
                    <TabChildren>ご職業</TabChildren>
                    {hasJoinGuarantorForB && (
                      <TabChildren>担保提供者</TabChildren>
                    )}
                    <TabChildren>お住まい</TabChildren>
                    <TabChildren>現在の借入状況</TabChildren>
                    <TabChildren>資金計画</TabChildren>
                    <TabChildren>担当者情報</TabChildren>
                    <TabChildren>書類アップロード</TabChildren>
                    <TabChildren>審査結果</TabChildren>
                  </TabsList>

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ my: 0 }}
                  >
                    {editable &&
                      !(
                        selectTab === '2' &&
                        preliminaries.p_application_header.linking_id
                      ) &&
                      !showExaminationResultForA &&
                      !showExaminationResultForB && (
                        <Button
                          disabled={
                            (selectTab === '1' && isDisableButtonSubmit) ||
                            (selectTab === '3' &&
                              isDisableButtonSubmitForIncome) ||
                            isDisableSaveButton
                          }
                          sx={{
                            mb: 3,
                            width: 204,
                            minHeight: 35,
                            bgcolor: 'sp_secondary_01',
                          }}
                          onClick={() => {
                            if (isTabUploadFiles || isTabUploadFilesIncome) {
                              // create check list image
                              const imageLists: Array<File | SPImage> = [];

                              // get image form header
                              Object.keys(formik.values.filesHeader).forEach(
                                (key) => {
                                  const paramsHeaderImages = get(
                                    formik.values.filesHeader,
                                    key,
                                    []
                                  );
                                  paramsHeaderImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form borrowing
                              Object.keys(formik.values.filesBorrowing).forEach(
                                (key) => {
                                  const paramsBorrowImages = get(
                                    formik.values.filesBorrowing,
                                    key,
                                    []
                                  );
                                  paramsBorrowImages.forEach(
                                    (file: File | SPImage) => {
                                      const [keyValue, index] =
                                        key.split('_index__');
                                      const borrowingId =
                                        preliminaries.p_application_header
                                          .p_borrowings_attributes[
                                          Number(index)
                                        ]?.id;
                                      if (!!borrowingId) imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 1
                              Object.keys(formik.values.files).forEach(
                                (key) => {
                                  const paramsImages = get(
                                    formik.values.files,
                                    key,
                                    []
                                  );
                                  paramsImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 2
                              Object.keys(
                                formik.values.filesIncomeAdder
                              ).forEach((key) => {
                                const paramsIncomeImages = get(
                                  formik.values.filesIncomeAdder,
                                  key,
                                  []
                                );
                                paramsIncomeImages.forEach(
                                  (file: File | SPImage) => {
                                    imageLists.push(file);
                                  }
                                );
                              });

                              const promises = imageLists.map((image) => {
                                const type =
                                  image instanceof File
                                    ? image.type
                                    : getFileExtFromUrl(image.url);
                                if (type.includes('pdf')) {
                                  if (image instanceof File) {
                                    return checkIfPDFExists(
                                      URL.createObjectURL(image)
                                    );
                                  } else {
                                    return checkIfPDFExists(image.url);
                                  }
                                } else {
                                  if (image instanceof File) {
                                    return checkIfImageExists(image);
                                  } else {
                                    return checkIfImageExists(image.url);
                                  }
                                }
                              });

                              Promise.all(promises).then((result) => {
                                if (!result.every((item) => item)) {
                                  modalErrorRef.current?.open(
                                    '',
                                    FILE_NOT_EXIST,
                                    true
                                  );
                                  return;
                                } else {
                                  handleSubmitEditUploadedFiles(
                                    formik.values.files,
                                    formik.values.filesIncomeAdder,
                                    formik.values.filesHeader,
                                    formik.values.filesBorrowing,
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes
                                  );
                                }
                              });
                            } else {
                              formik.handleSubmit();
                            }
                          }}
                        >
                          保存
                        </Button>
                      )}
                  </Stack>
                  <TabPanels value={0} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 0 &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getBorrowingDetail('link.p_application_header')}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  <TabPanels value={1} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 1 &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getPersonalInformation(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  <TabPanels value={2} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 2 &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked={true}
                          noRequired
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getPersonalOccupation(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  {hasJoinGuarantorForB && (
                    <TabPanels value={3} sx={{ width: '100%', pb: 5 }}>
                      {selectSubTab === 3 &&
                        preliminaries.p_application_header.linking_id && (
                          <Stack spacing={7}>
                            {getGuaranteeProvider(
                              'link.p_application_header'
                            ).map((table, index) => (
                              <Stack key={index}>
                                <Typography variant="pdf_title" fontSize={15}>
                                  担保提供者
                                  <Typography variant="pdf_title">
                                    （{index + 1}人目）
                                  </Typography>
                                </Typography>
                                <AdminPreliminaryTable
                                  isLinked
                                  noRequired
                                  parentTabIndex={selectTab}
                                  appraisalId={
                                    preliminaries.p_application_header
                                      .linking_id
                                  }
                                  rows={table}
                                  parentKey="link.p_application_header"
                                  indexJoinGuarantor={index}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        )}
                    </TabPanels>
                  )}
                  <TabPanels
                    value={hasJoinGuarantorForB ? 4 : 3}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 4 : 3) &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked={true}
                          noRequired
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getPersonalResidence(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                          quantityDependent={{
                            siblings_number:
                              formik.values.link?.p_application_header
                                ?.siblings_number ?? '',
                            other_people_number:
                              formik.values.link?.p_application_header
                                .other_people_number ?? '',
                            children_number:
                              formik.values.link?.p_application_header
                                .children_number ?? '',
                          }}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={hasJoinGuarantorForB ? 5 : 4}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 5 : 4) &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getCurrentBorrowing(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                          isArray
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={hasJoinGuarantorForB ? 6 : 5}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 6 : 5) &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked={true}
                          noRequired
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getFinancingPlan('link.p_application_header')}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={hasJoinGuarantorForB ? 7 : 6}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 7 : 6) && (
                      <AdminPreliminaryTable
                        isLinked={true}
                        noRequired
                        appraisalId={
                          preliminaries.p_application_header.linking_id
                        }
                        rows={buildContactInformation()}
                        parentKey="link.p_application_header"
                      />
                    )}
                  </TabPanels>
                  {/* For 申込人（B） */}
                  <TabPanels
                    value={hasJoinGuarantorForB ? 8 : 7}
                    sx={{
                      backgroundColor: 'main_white',
                      overflowY: 'scroll',
                      height: 'calc(100vh - 340px)',
                    }}
                  >
                    <Stack direction="row" spacing={4} sx={{ pb: '65px' }}>
                      <Stack
                        flex={1}
                        spacing={4}
                        sx={{
                          width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                        }}
                      >
                        {/* 申込人（B） - A */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={
                              formik.values.link?.p_application_header
                                .p_applicant_people_attributes?.[0]
                                ?.identity_verification === '0' ||
                              !formik.values.link?.p_application_header
                                .p_applicant_people_attributes?.[0]
                                ?.identity_verification
                            }
                            title="本人確認書類"
                            name="A"
                            setOpenPreview={handlePreview}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'driver_license_front_image'
                                  ),
                                jp_name: `運転免許書・マイナンバーカード・\n住民基本台帳カード＜表面＞`,
                                en_name: 'driver_license_front_image',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .driver_license_front_image,
                              },
                            ]}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={
                              formik.values.link?.p_application_header
                                .p_applicant_people_attributes?.[0]
                                ?.identity_verification === '0' ||
                              !formik.values.link?.p_application_header
                                .p_applicant_people_attributes?.[0]
                                ?.identity_verification
                            }
                            title=""
                            name=""
                            setOpenPreview={handlePreview}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'driver_license_back_image'
                                  ),
                                jp_name:
                                  '運転免許書・マイナンバーカード・\n住民基本台帳カード＜裏面＞',
                                en_name: 'driver_license_back_image',
                                files:
                                  formik.values.filesLink
                                    .driver_license_back_image,
                                isRequired: false,
                              },
                            ]}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={
                              formik.values.link?.p_application_header
                                .p_applicant_people_attributes?.[0]
                                ?.identity_verification === '1'
                            }
                            title="本人確認書類"
                            name="A"
                            setOpenPreview={handlePreview}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'card_number_front_image'
                                  ),
                                jp_name: `運転免許書・マイナンバーカード・\n住民基本台帳カード＜表面＞`,
                                en_name: 'card_number_front_image',
                                isRequired: isRequiredUploadFile(
                                  'card_number_front_image'
                                ),
                                files:
                                  formik.values.filesLink
                                    .card_number_front_image,
                              },
                            ]}
                          />
                          {/*<DocumentUploadItem*/}
                          {/*  editable={false}*/}
                          {/*  multiple={false}*/}
                          {/*  isDisplay={*/}
                          {/*    formik.values.link?.p_application_header*/}
                          {/*      .p_applicant_people_attributes?.[0]*/}
                          {/*      ?.identity_verification === '1'*/}
                          {/*  }*/}
                          {/*  title=""*/}
                          {/*  name=""*/}
                          {/*  setOpenPreview={handlePreview}*/}
                          {/*  options={[*/}
                          {/*    {*/}
                          {/*      jp_name:*/}
                          {/*        '運転免許書・マイナンバーカード・\n住民基本台帳カード＜裏面＞',*/}
                          {/*      en_name: 'card_number_back_image',*/}
                          {/*      isRequired: false,*/}
                          {/*      files:*/}
                          {/*        formik.values.filesLink*/}
                          {/*          .card_number_back_image,*/}
                          {/*    },*/}
                          {/*  ]}*/}
                          {/*/>*/}
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={
                              formik.values.link?.p_application_header
                                .p_applicant_people_attributes?.[0]
                                ?.identity_verification === '2'
                            }
                            title="本人確認書類"
                            name="A"
                            setOpenPreview={handlePreview}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'resident_register_front_image'
                                  ),
                                jp_name: `運転免許書・マイナンバーカード・\n住民基本台帳カード＜表面＞`,
                                en_name: 'resident_register_front_image',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .resident_register_front_image,
                              },
                            ]}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={
                              formik.values.link?.p_application_header
                                .p_applicant_people_attributes?.[0]
                                ?.identity_verification === '2'
                            }
                            title=""
                            name=""
                            setOpenPreview={handlePreview}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'resident_register_back_image'
                                  ),
                                jp_name:
                                  '運転免許書・マイナンバーカード・\n住民基本台帳カード＜裏面＞',
                                en_name: 'resident_register_back_image',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .resident_register_back_image,
                              },
                            ]}
                          />
                        </Stack>
                        {/* 申込人（B） - B */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={true}
                            title="健康保険証"
                            name="B"
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'insurance_file'
                                  ),
                                jp_name: '健康保険証〈表面〉',
                                en_name: 'insurance_file',
                                isRequired: false,
                                files: formik.values.filesLink.insurance_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={true}
                            title=""
                            name=""
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'insurance_file_back_image'
                                  ),
                                jp_name: '健康保険証〈裏面〉',
                                en_name: 'insurance_file_back_image',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .insurance_file_back_image,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        {/* 申込人（B） - C */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            //isFirstWithholdingSlipFile()
                            isDisplay={true}
                            title="収入に関する書類"
                            name="C"
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'first_withholding_slip_file'
                                  ),
                                jp_name: '源泉徴収票（前年度分）',
                                en_name: 'first_withholding_slip_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .first_withholding_slip_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            // title={
                            //   isFirstWithholdingSlipFile()
                            //     ? ''
                            //     : '収入に関する書類'
                            // }
                            // name={isFirstWithholdingSlipFile() ? '' : 'C'}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'second_withholding_slip_file'
                                  ),
                                jp_name: '源泉徴収票（前々年度分）',
                                en_name: 'second_withholding_slip_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .second_withholding_slip_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'first_income_file'
                                  ),
                                jp_name: '確定申告書（1期前）',
                                en_name: 'first_income_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink.first_income_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'second_income_file'
                                  ),
                                jp_name: '確定申告書（2期前）',
                                en_name: 'second_income_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink.second_income_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'third_income_file'
                                  ),
                                jp_name: '確定申告書（3期前）',
                                en_name: 'third_income_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink.third_income_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        {/* 申込人（B） - D */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            title="非上場企業の役員の方の書類"
                            name="D"
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'financial_statement_1_file'
                                  ),
                                jp_name: '会社の決算報告書（1期前）',
                                en_name: 'financial_statement_1_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .financial_statement_1_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'financial_statement_2_file'
                                  ),
                                jp_name: '会社の決算報告書（2期前）',
                                en_name: 'financial_statement_2_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .financial_statement_2_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'financial_statement_3_file'
                                  ),
                                jp_name: '会社の決算報告書（3期前）',
                                en_name: 'financial_statement_3_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .financial_statement_3_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        {/* 申込人（B）- E */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            title="雇用契約に関する書類"
                            name="E"
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'employment_agreement_file'
                                  ),
                                jp_name: '雇用契約書',
                                en_name: 'employment_agreement_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .employment_agreement_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                      </Stack>
                      <Stack
                        flex={1}
                        spacing={4}
                        sx={{
                          width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                        }}
                      >
                        {/* 申込人（B）- F */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            title="親族経営の会社等にご勤務の方に関する書類"
                            name="F"
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'business_tax_return_1_file'
                                  ),
                                jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（1期前）`,
                                en_name: 'business_tax_return_1_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .business_tax_return_1_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'business_tax_return_2_file'
                                  ),
                                jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（2期前）`,
                                en_name: 'business_tax_return_2_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .business_tax_return_2_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'business_tax_return_3_file'
                                  ),
                                jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（3期前）`,
                                en_name: 'business_tax_return_3_file',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .business_tax_return_3_file,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                        </Stack>
                        {/* 申込人（B）- G */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItemHeader
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            title="物件についての書類"
                            name="G"
                            options={[
                              {
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicationHeader',
                                isHasLog:
                                  formik.values.p_application_header.has_log_fields?.includes(
                                    'property_information_file'
                                  ),
                                jp_name: 'チラシ・パンフレット',
                                en_name: 'property_information_file_index',
                                isRequired: false,
                                files:
                                  formik.values.filesLinkHeader
                                    .property_information_file_index,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          />
                          {/* HOMELOAN-2299 */}
                          {/* <DocumentUploadItemHeader
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            title=""
                            name=""
                            options={[
                              {
                                jp_name: '物件書類（登記簿謄本等）',
                                en_name:
                                  'manager_property_information_file_index',
                                isRequired: false,
                                files:
                                  formik.values.filesLinkHeader
                                    .manager_property_information_file_index,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          /> */}
                        </Stack>
                        {/* 申込人（B）- H */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={true}
                            setOpenPreview={handlePreview}
                            title="在留カード"
                            name="H"
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'residence_file'
                                  ),
                                jp_name: '在留カード 〈表面〉',
                                en_name: 'residence_file',
                                isRequired: false,
                                files: formik.values.filesLink.residence_file,
                              },
                            ]}
                          />
                          <DocumentUploadItem
                            editable={false}
                            multiple={false}
                            isDisplay={true}
                            setOpenPreview={handlePreview}
                            title=""
                            name=""
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'residence_file_back_image'
                                  ),
                                jp_name: '在留カード 〈裏面〉',
                                en_name: 'residence_file_back_image',
                                isRequired: false,
                                files:
                                  formik.values.filesLink
                                    .residence_file_back_image,
                              },
                            ]}
                          />
                        </Stack>
                        {/* 申込人（B）- I */}
                        {!!formik.values.link?.p_application_header
                          .p_borrowings_attributes.length && (
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            {formik.values.link?.p_application_header.p_borrowings_attributes.map(
                              (borrowing, index) => (
                                <DocumentUploadItemBorrowing
                                  key={index}
                                  editable={false}
                                  multiple={true}
                                  isDisplay={true}
                                  title={
                                    index === 0 ? '返済予定表・利用明細書' : ''
                                  }
                                  setOpenPreview={handlePreview}
                                  name={index === 0 ? 'I' : ''}
                                  options={[
                                    {
                                      borrowingId: borrowing.id,
                                      headerId:
                                        formik.values.link?.p_application_header
                                          .id,
                                      trackable_type: 'PBorrowing',
                                      isHasLog:
                                        borrowing.has_log_fields?.includes(
                                          'repayment_schedule_image'
                                        ),
                                      jp_name: `${index + 1}件目の借入`,
                                      // @ts-ignore
                                      en_name: genParamBorrowing(index),
                                      isRequired: false,
                                      files: genParamBorrowingFilesLink(index),
                                    },
                                  ]}
                                />
                              )
                            )}
                          </Stack>
                        )}
                        {/* 申込人（B）- J */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItemHeader
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            title="提携会社の担当者名刺"
                            setOpenPreview={handlePreview}
                            name="J"
                            options={[
                              {
                                headerId: formik.values.p_application_header.id,
                                trackable_type: 'PApplicationHeader',
                                isHasLog:
                                  formik.values.p_application_header.has_log_fields?.includes(
                                    'business_card'
                                  ),
                                jp_name: '',
                                en_name: 'business_card_index',
                                isRequired: false,
                                files:
                                  formik.values.filesLinkHeader
                                    .business_card_index,
                              },
                            ]}
                          />
                        </Stack>
                        {/* 申込人（B）- K */}
                        <Stack
                          sx={{
                            border: (theme) =>
                              `1px solid ${theme?.palette?.bg_gray}`,
                          }}
                        >
                          <DocumentUploadItem
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            setOpenPreview={handlePreview}
                            title="その他"
                            name="K"
                            options={[
                              {
                                personId:
                                  formik.values.link?.p_application_header
                                    .p_applicant_people_attributes[0].id,
                                headerId:
                                  formik.values.link?.p_application_header.id,
                                trackable_type: 'PApplicantPerson',
                                isHasLog:
                                  formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                    'other_document_file'
                                  ),
                                jp_name: '',
                                en_name: 'other_document_file',
                                files:
                                  formik.values.filesLink.other_document_file,
                                isRequired: false,
                              },
                            ]}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </TabPanels>
                  <TabPanels
                    value={hasJoinGuarantorForB ? 9 : 8}
                    sx={{
                      pt: 1,
                      mt: `${examinationUrlForB ? '50px' : '160px'}`,
                      pb: `${examinationUrlForB ? '50px' : '170px'}`,
                    }}
                  >
                    {/* for 申込人（B） */}
                    {selectSubTab === (hasJoinGuarantorForB ? 9 : 8) && (
                      <>
                        {examinationUrlForB ? (
                          <AdminPreviewPDF src={examinationUrlForB} />
                        ) : (
                          <SelectPdfFile
                            onChange={handleChangePDF}
                            disabled={true}
                          />
                        )}
                      </>
                    )}
                  </TabPanels>
                </TabWrapper>
              </TabPanel>
              <TabPanel
                value="3"
                sx={{ boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)', p: 0 }}
              >
                <TabWrapper
                  value={selectIncomeSubTab}
                  onChange={handleChangeIncomeSubTab}
                >
                  <TabsList>
                    <TabChildren
                      disabled={
                        !formik.values.p_application_header
                          .p_applicant_people_attributes[1]?.id
                      }
                    >
                      収入合算者の情報
                    </TabChildren>
                    <TabChildren
                      disabled={
                        !formik.values.p_application_header
                          .p_applicant_people_attributes[1]?.id
                      }
                    >
                      収入合算者のご職業
                    </TabChildren>
                    <TabChildren
                      disabled={
                        !formik.values.p_application_header
                          .p_applicant_people_attributes[1]?.id
                      }
                    >
                      書類アップロード
                    </TabChildren>
                  </TabsList>

                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ my: 0 }}
                  >
                    {editable &&
                      !(
                        selectTab === '2' &&
                        preliminaries.p_application_header.linking_id
                      ) &&
                      !showExaminationResultForA &&
                      !showExaminationResultForB && (
                        <Button
                          disabled={
                            (selectTab === '1' && isDisableButtonSubmit) ||
                            (selectTab === '3' &&
                              isDisableButtonSubmitForIncome) ||
                            isDisableSaveButton
                          }
                          sx={{
                            mb: 3,
                            width: 204,
                            minHeight: 35,
                            bgcolor: 'sp_secondary_01',
                          }}
                          onClick={() => {
                            if (isTabUploadFiles || isTabUploadFilesIncome) {
                              // create check list image
                              const imageLists: Array<File | SPImage> = [];

                              // get image form header
                              Object.keys(formik.values.filesHeader).forEach(
                                (key) => {
                                  const paramsHeaderImages = get(
                                    formik.values.filesHeader,
                                    key,
                                    []
                                  );
                                  paramsHeaderImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form borrowing
                              Object.keys(formik.values.filesBorrowing).forEach(
                                (key) => {
                                  const paramsBorrowImages = get(
                                    formik.values.filesBorrowing,
                                    key,
                                    []
                                  );
                                  paramsBorrowImages.forEach(
                                    (file: File | SPImage) => {
                                      const [keyValue, index] =
                                        key.split('_index__');
                                      const borrowingId =
                                        preliminaries.p_application_header
                                          .p_borrowings_attributes[
                                          Number(index)
                                        ]?.id;
                                      if (!!borrowingId) imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 1
                              Object.keys(formik.values.files).forEach(
                                (key) => {
                                  const paramsImages = get(
                                    formik.values.files,
                                    key,
                                    []
                                  );
                                  paramsImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 2
                              Object.keys(
                                formik.values.filesIncomeAdder
                              ).forEach((key) => {
                                const paramsIncomeImages = get(
                                  formik.values.filesIncomeAdder,
                                  key,
                                  []
                                );
                                paramsIncomeImages.forEach(
                                  (file: File | SPImage) => {
                                    imageLists.push(file);
                                  }
                                );
                              });
                              const promises = imageLists.map((image) => {
                                const type =
                                  image instanceof File
                                    ? image.type
                                    : getFileExtFromUrl(image.url);
                                if (type.includes('pdf')) {
                                  if (image instanceof File) {
                                    return checkIfPDFExists(
                                      URL.createObjectURL(image)
                                    );
                                  } else {
                                    return checkIfPDFExists(image.url);
                                  }
                                } else {
                                  if (image instanceof File) {
                                    return checkIfImageExists(image);
                                  } else {
                                    return checkIfImageExists(image.url);
                                  }
                                }
                              });

                              Promise.all(promises).then((result) => {
                                if (!result.every((item) => item)) {
                                  modalErrorRef.current?.open(
                                    '',
                                    FILE_NOT_EXIST,
                                    true
                                  );
                                  return;
                                } else {
                                  handleSubmitEditUploadedFiles(
                                    formik.values.files,
                                    formik.values.filesIncomeAdder,
                                    formik.values.filesHeader,
                                    formik.values.filesBorrowing,
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes
                                  );
                                }
                              });
                            } else {
                              formik.handleSubmit();
                            }
                          }}
                        >
                          保存
                        </Button>
                      )}
                  </Stack>
                  <TabPanels value={0} sx={{ width: '100%', pb: 5 }}>
                    <AdminPreliminaryTable
                      parentTabIndex={selectTab}
                      appraisalId={preliminaryId}
                      editable={editable}
                      rows={getIncomeTotalizer()}
                      parentKey="p_application_header"
                      isTotalIncome
                    />
                  </TabPanels>
                  {formik.values.p_application_header
                    .p_applicant_people_attributes.length === 2 && (
                    <TabPanels value={1} sx={{ width: '100%', pb: 5 }}>
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getPersonalOccupation('p_application_header', 1)}
                        editable={editable}
                        parentKey="p_application_header"
                        isTotalIncome
                      />
                    </TabPanels>
                  )}
                  {formik.values.p_application_header
                    .p_applicant_people_attributes.length === 2 && (
                    <TabPanels
                      value={2}
                      sx={{
                        backgroundColor: 'main_white',
                        overflowY: 'scroll',
                        height: 'calc(100vh - 340px)',
                      }}
                    >
                      <Stack direction="row" spacing={4} sx={{ pb: '65px' }}>
                        {/* 収入合算者 */}
                        <Stack
                          flex={1}
                          spacing={4}
                          sx={{
                            width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                          }}
                        >
                          {/* A */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              title="本人確認書類"
                              name="A"
                              setOpenPreview={handlePreview}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'driver_license_front_image'
                                    ),
                                  jp_name: `運転免許書`,
                                  en_name: 'driver_license_front_image',
                                  isRequired: isRequiredUploadFile(
                                    'driver_license_front_image'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .driver_license_front_image,
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'driver_license_back_image'
                                    ),
                                  jp_name: '運転免許書',
                                  en_name: 'driver_license_back_image',
                                  files:
                                    formik.values.filesIncomeAdder
                                      .driver_license_back_image,
                                  isRequired: isRequiredUploadFile(
                                    'driver_license_back_image'
                                  ),
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'card_number_front_image'
                                    ),
                                  jp_name: `マイナンバーカード`,
                                  en_name: 'card_number_front_image',
                                  isRequired: isRequiredUploadFile(
                                    'card_number_front_image'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .card_number_front_image,
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'resident_register_front_image'
                                    ),
                                  jp_name: `住民基本台帳カード`,
                                  en_name: 'resident_register_front_image',
                                  isRequired: isRequiredUploadFile(
                                    'resident_register_front_image'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .resident_register_front_image,
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'resident_register_back_image'
                                    ),
                                  jp_name: '住民基本台帳カード',
                                  en_name: 'resident_register_back_image',
                                  isRequired: isRequiredUploadFile(
                                    'resident_register_back_image'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .resident_register_back_image,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              filesUploadOption={filesUploadOption}
                              handleRadioChange={(option) => {
                                formik.setFieldValue('isChangeFiles', true);
                                setFilesUploadOption(option);
                              }}
                              onChange={handleChangeFilesIncomeAdder}
                            />
                          </Stack>
                          {/* B */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              title="健康保険証"
                              name="B"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'insurance_file'
                                    ),
                                  jp_name: '健康保険証〈表面〉',
                                  en_name: 'insurance_file',
                                  isRequired:
                                    isRequiredUploadFile('insurance_file'),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .insurance_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              title=""
                              name=""
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'insurance_file_back_image'
                                    ),
                                  jp_name: '健康保険証〈裏面〉',
                                  en_name: 'insurance_file_back_image',
                                  isRequired: isRequiredUploadFile(
                                    'insurance_file_back_image'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .insurance_file_back_image,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* C */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              //isFirstWithholdingSlipFile()
                              isDisplay={true}
                              title="収入に関する書類"
                              name="C"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'first_withholding_slip_file'
                                    ),
                                  jp_name: '源泉徴収票（前年度分）',
                                  en_name: 'first_withholding_slip_file',
                                  isRequired: isRequiredUploadFile(
                                    'first_withholding_slip_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .first_withholding_slip_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              // title={
                              //   isFirstWithholdingSlipFile()
                              //     ? ''
                              //     : '収入に関する書類'
                              // }
                              // name={isFirstWithholdingSlipFile() ? '' : 'C'}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'second_withholding_slip_file'
                                    ),
                                  jp_name: '源泉徴収票（前々年度分）',
                                  en_name: 'second_withholding_slip_file',
                                  isRequired: isRequiredUploadFile(
                                    'second_withholding_slip_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .second_withholding_slip_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'first_income_file'
                                    ),
                                  jp_name: '確定申告書（1期前）',
                                  en_name: 'first_income_file',
                                  isRequired:
                                    isRequiredUploadFile('first_income_file'),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .first_income_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'second_income_file'
                                    ),
                                  jp_name: '確定申告書（2期前）',
                                  en_name: 'second_income_file',
                                  isRequired:
                                    isRequiredUploadFile('second_income_file'),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .second_income_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'third_income_file'
                                    ),
                                  jp_name: '確定申告書（3期前）',
                                  en_name: 'third_income_file',
                                  isRequired:
                                    isRequiredUploadFile('third_income_file'),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .third_income_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* D */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="非上場企業の役員の方の書類"
                              name="D"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'financial_statement_1_file'
                                    ),
                                  jp_name: '会社の決算報告書（1期前）',
                                  en_name: 'financial_statement_1_file',
                                  isRequired: isRequiredUploadFile(
                                    'financial_statement_1_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .financial_statement_1_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'financial_statement_2_file'
                                    ),
                                  jp_name: '会社の決算報告書（2期前）',
                                  en_name: 'financial_statement_2_file',
                                  isRequired: isRequiredUploadFile(
                                    'financial_statement_2_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .financial_statement_2_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'financial_statement_3_file'
                                    ),
                                  jp_name: '会社の決算報告書（3期前）',
                                  en_name: 'financial_statement_3_file',
                                  isRequired: isRequiredUploadFile(
                                    'financial_statement_3_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .financial_statement_3_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* E */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="雇用契約に関する書類"
                              name="E"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'employment_agreement_file'
                                    ),
                                  jp_name: '雇用契約書',
                                  en_name: 'employment_agreement_file',
                                  isRequired: isRequiredUploadFile(
                                    'employment_agreement_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .employment_agreement_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                        </Stack>
                        <Stack
                          flex={1}
                          spacing={4}
                          sx={{
                            width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                          }}
                        >
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="親族経営の会社等にご勤務の方に関する書類"
                              name="F"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'business_tax_return_1_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（1期前）`,
                                  en_name: 'business_tax_return_1_file',
                                  isRequired: isRequiredUploadFile(
                                    'business_tax_return_1_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .business_tax_return_1_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'business_tax_return_2_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（2期前）`,
                                  en_name: 'business_tax_return_2_file',
                                  isRequired: isRequiredUploadFile(
                                    'business_tax_return_2_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .business_tax_return_2_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'business_tax_return_3_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（3期前）`,
                                  en_name: 'business_tax_return_3_file',
                                  isRequired: isRequiredUploadFile(
                                    'business_tax_return_3_file'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .business_tax_return_3_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title="在留カード"
                              name="H"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'residence_file'
                                    ),
                                  jp_name: '在留カード 〈表面〉',
                                  en_name: 'residence_file',
                                  isRequired:
                                    isRequiredUploadFile('residence_file'),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .residence_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                            />
                            <DocumentUploadItem
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title=""
                              name=""
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'residence_file_back_image'
                                    ),
                                  jp_name: '在留カード 〈裏面〉',
                                  en_name: 'residence_file_back_image',
                                  isRequired: isRequiredUploadFile(
                                    'residence_file_back_image'
                                  ),
                                  files:
                                    formik.values.filesIncomeAdder
                                      .residence_file_back_image,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title="その他"
                              name="K"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[1].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                      'other_document_file'
                                    ),
                                  jp_name: '',
                                  en_name: 'other_document_file',
                                  files:
                                    formik.values.filesIncomeAdder
                                      .other_document_file,
                                  isRequired: isRequiredUploadFile(
                                    'other_document_file'
                                  ),
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFilesIncomeAdder}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </TabPanels>
                  )}
                </TabWrapper>
              </TabPanel>
            </TabContext>
            {showExaminationResultForA && (
              <ExaminationResultButtons
                isLinked={false}
                preliminaryId={preliminaryId}
                isPdfExisted={!!examinationUrl}
                filePdf={filePdf}
                statusResult={statusResult}
                provisionalResults={provisionalResults}
                publicStatuses={
                  preliminaries.p_application_header.public_status
                }
              />
            )}

            {showExaminationResultForB && (
              <ExaminationResultButtons
                isLinked={true}
                preliminaryId={preliminaryId}
                isPdfExisted={!!examinationUrlForB}
                statusResult={statusResultForB}
                provisionalResults={provisionalResultsForB}
                publicStatuses={
                  preliminaries.p_application_header.public_status
                }
              />
            )}
            <ModalChangeValue
              open={changeModal}
              message={messageChangeModal}
              onClose={changeModalOnClose}
            />
          </Box>
        </FormikProvider>
        <Loading
          open={
            (isTabUploadFiles || isTabUploadFilesIncome) && isDisableSaveButton
          }
        />
      </Stack>
    </AdminLayout>
  );
};

const styleMuiTabs = {
  fontFamily: 'Hiragino Sans',
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '24px',
  letterSpacing: 0.1,
  minWidth: 160,
  height: 38,
};

const TabWrapper = styled(TabsUnstyled)`
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
`;

const TabChildren = styled(TabUnstyled)`
  font-family: Hiragino Sans;
  color: #6b70f0;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
  background-color: #fff;
  padding: 10px 12px;
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 6px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  &.${tabUnstyledClasses.selected} {
    background: #6b70f0;
    color: white;
  }

  &.${tabUnstyledClasses} {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanels = styled(TabPanelUnstyled)`
  width: 100%;
  //height: '500px';
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
  () => `
  min-width: 400px;
  border-radius: 2px;
  margin-bottom: 16px;
  align-content: space-between;
  background-color: #EFEFEF;
  `
);

const styleMuiTabSelected = {
  color: 'b_333',
  fontFamily: 'Hiragino Sans',
  fontWeight: 500,
  height: 38,
  fontSize: 13,
  border: 'none',
  lineHeight: '24px',
  letterSpacing: 0.1,
  borderBottom: '3px solid #6B70F0',
  borderColor: '#6B70F0',
};

export default AdminEditPreliminary;
