import {
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Stack,
  Typography,
  IconButton,
  TableCell,
  Divider,
} from '@mui/material';
import { Button, Icons } from 'components';
import { FC, MouseEvent, useState } from 'react';
import { ContactItemManager } from 'services';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, OrderType, TableProps } from 'types';
import { Add } from '@mui/icons-material';
import { useAppDispatch } from 'hooks';
import { getContactDetailManager } from 'pages/ManagerContactInformations/thunk';
import { useSelector } from 'react-redux';
import { selectedPreliminarySelectors } from 'pages/ManagerPreliminaries/selectors';
import { ContactInformationModal } from '../ContactInformationModal';
import { ContactDetailModalMangager } from '../ContactDetailModalManager';

export const ManagerContactInformationItems: FC<
  TableProps<ContactItemManager>
> = ({ onRequestSort, order, orderBy, isAdmin, rows }) => {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDetail, setOpenModalDetail] = useState<boolean>(false);
  const [contactDetailManager, setContactDetailManager] =
    useState<ContactItemManager>();
  const { id, applicant_id } = useSelector(selectedPreliminarySelectors);

  const createSortHandler =
    (property: keyof ContactItemManager) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const openContactDetailManager = async (idContact: string) => {
    const result = await dispatch(
      getContactDetailManager({
        id: idContact,
        applicant_id,
        preliminary_id: id,
      })
    );
    if (getContactDetailManager.fulfilled.match(result)) {
      setOpenModalDetail(true);
      setContactDetailManager(result.payload.data);
    }
  };

  return (
    <Stack spacing={6} sx={{ bgcolor: 'main_white' }}>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <TableHead
          sx={{
            display: 'flex',
            minWidth: 1135,
            boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              marginBottom: '10px',
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
              pr: isAdmin ? '114px' : 0,
            }}
          >
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{
                  height: 40,
                  p: 0,
                  alignItems: 'center',
                  textAlign: 'center',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  border: 'none',
                  ...headCell.style,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="table_header_text"
                    sx={{ whiteSpace: 'nowrap' }}
                    color="normal_text"
                  >
                    {headCell.label}
                  </Typography>
                  {headCell.smallLabel && (
                    <Typography
                      variant="table_small_text"
                      sx={{ whiteSpace: 'nowrap' }}
                      color="normal_text"
                    >
                      {headCell.smallLabel}
                    </Typography>
                  )}
                  <SortTable
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  />
                </Stack>
                {(headCell !== headCells[headCells.length - 1] || isAdmin) && (
                  <Divider
                    sx={{
                      color: 'gray',
                      right: 0,
                      top: '25%',
                      position: 'absolute',
                      height: '50%',
                    }}
                    orientation="vertical"
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <Stack
          sx={{
            bgcolor: 'main_white',
            minWidth: 1116,
            overflowX: 'auto',
            height: 'calc(100vh - 220px)',
            ...styleScrollBarTable,
          }}
        >
          <Stack sx={{ mb: '13px' }}>
            {rows.map((item) => (
              <Stack
                key={item.id}
                sx={{ minWidth: 1100 }}
                onClick={() => {
                  openContactDetailManager(item.id);
                }}
              >
                <TableRow
                  sx={{
                    bgcolor: 'main_white',
                    justifyContent: 'space-between',
                    borderBottom: (theme) =>
                      `1px solid ${theme?.palette?.gray}`,
                    alignItems: 'center',
                    display: 'flex',
                    height: 60,
                  }}
                >
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: 97,
                      padding: '10px 0',
                      borderBottom: 'none',
                      position: 'relative',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      variant="number_applicant_item"
                      color="normal_text"
                    >
                      {item.created_at}
                    </Typography>
                    <Divider
                      sx={{
                        color: 'gray',
                        right: 0,
                        top: '25%',
                        position: 'absolute',
                        height: '50%',
                      }}
                      orientation="vertical"
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: 180,
                      padding: '10px 0',
                      textAlign: 'center',
                      borderBottom: 'none',
                      position: 'relative',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      variant="title_application_item"
                      color="normal_text"
                    >
                      {item.sender}
                    </Typography>
                    <Divider
                      sx={{
                        color: 'gray',
                        right: 0,
                        top: '25%',
                        position: 'absolute',
                        height: '50%',
                      }}
                      orientation="vertical"
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      width: isAdmin ? 163 : 201,
                      flex: 1,
                      pl: '10px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '10px 0',
                      textAlign: 'center',
                      borderBottom: 'none',
                      position: 'relative',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      variant="title_application_item"
                      color="normal_text"
                    >
                      {item.content}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Stack>
            ))}
          </Stack>
          <ContactInformationModal
            open={openModal}
            onClose={() => setOpenModal((prevState) => !prevState)}
          />
          {contactDetailManager && (
            <ContactDetailModalMangager
              open={openModalDetail}
              onClose={() => setOpenModalDetail((prevState) => !prevState)}
              ContactItemManager={contactDetailManager}
            />
          )}
        </Stack>
      </TableContainer>
      <Stack sx={{ bgcolor: 'main_white' }}>
        <Button
          sx={{
            ml: 5,
            mb: 5,
            bgcolor: 'main_white',
            width: '93px',
            minHeight: '25px',
            height: '25px',
            borderRadius: '4px',
            border: (theme) => `1px solid ${theme?.palette?.h_blugreen}`,
          }}
          startIcon={
            <Add sx={{ color: 'h_blugreen', width: 18, height: 18 }} />
          }
          startIconStyle={{ left: 7, bottom: 3 }}
          onClick={() => setOpenModal(true)}
        >
          <Typography variant="dashboard_text" color="h_blugreen">
            新規追加
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

type SortTableProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const SortTable: FC<SortTableProps> = ({ active, direction, onClick }) => (
  <IconButton sx={{ p: 1 }} onClick={onClick}>
    {direction === 'asc' ? (
      <Icons.SortUp
        sx={{
          width: 10,
          height: 10,
          color: active ? 'h_blugreen' : 'transparent',
        }}
      />
    ) : (
      <Icons.SortDown sx={{ width: 10, height: 10 }} />
    )}
  </IconButton>
);

const headCells: HeadCellProps<ContactItemManager>[] = [
  {
    id: 'created_at',
    label: '日付',
    style: { width: 97 },
  },
  {
    id: 'sender',
    label: '連絡者',
    style: { width: 180 },
  },
  {
    id: 'content',
    label: '連絡内容',
    style: { flex: 1, justifyContent: 'center' },
  },
];
