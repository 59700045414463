import {
  ChangeEvent,
  FC,
  Fragment,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { Stack, Typography } from '@mui/material';
import { SPInputField } from 'components';
import { MESSAGE_REGEX } from 'constant';
import { numberWithCommas, onlyNumbers } from 'utils';
import { validationSchema } from './validationSchema';

export type SPInputAreaProps = {
  name: string;
  label?: string;
  unit?: string;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const SPInputArea: FC<SPInputAreaProps> = ({
  label,
  unit,
  onFocus,
  onBlur,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [firstTouchField, setFirstTouchField] = useState(false);
  const initialValues = useMemo(() => {
    const [firstCode, secondCode] = field.value
      ? field.value.split('.')
      : ['', ''];
    return { firstCode, secondCode };
  }, [field.value]);

  const refOne = useRef<HTMLInputElement>(null);
  const refTwo = useRef<HTMLInputElement>(null);
  const currentIndex = useRef<number>(0);

  const areaInputs = useMemo(
    () => [
      {
        name: 'firstCode',
        ref: refOne,
        maxLength: 7,
      },
      {
        name: 'secondCode',
        ref: refTwo,
        maxLength: 2,
      },
    ],
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit() {},
  });

  const handleBackInput = useCallback(() => {
    const prevIndex = currentIndex.current - 1;

    if (prevIndex !== -1) {
      const prevInput = areaInputs?.[prevIndex]?.ref.current;
      prevInput?.focus();

      currentIndex.current = prevIndex;
    }
  }, [areaInputs]);

  const handleNextInput = useCallback(() => {
    const nextIndex = currentIndex.current + 1;

    if (nextIndex === areaInputs.length) {
      return areaInputs?.[currentIndex.current]?.ref.current?.blur();
    }
    const nextInput = areaInputs?.[nextIndex]?.ref.current;
    nextInput?.focus();

    currentIndex.current = nextIndex;
  }, [areaInputs]);

  const handleKeyPress = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (
        (e.target.value.length === 7 && e.target.name === 'firstCode') ||
        e.target.value.length === 7
      )
        handleNextInput();
      if (e.target.value.length === 0) handleBackInput();

      if (refOne.current?.value || refTwo.current?.value) {
        return setFieldValue(
          field.name,
          `${refOne.current!.value}.${refTwo.current!.value}`
        );
      }

      return setFieldValue(field.name, '');
    },
    [field.name, handleBackInput, handleNextInput, setFieldValue]
  );

  const handleBlur = useCallback(() => {
    if (!!refOne.current && !!refTwo.current) {
      const inputValue = `${numberWithCommas(
        refOne.current.value.replaceAll(',', '')
      )}.${refTwo.current.value || ''}`;
      if (!!refOne.current.value || !!refTwo.current.value)
        setFieldValue(field.name, inputValue);
    }
    setFirstTouchField(false);
    onBlur && onBlur();
  }, [onBlur, setFieldValue, field.name]);

  const handleFocusInput = useCallback(
    (e: KeyboardEvent<HTMLDivElement>, name: string) => {
      if (
        e.key !== 'Backspace' &&
        name === 'firstCode' &&
        refOne.current?.value.length === 7
      ) {
        handleNextInput();
      }
      if (e.key === 'Backspace' && refTwo.current?.value === '')
        handleBackInput();
    },
    [handleBackInput, handleNextInput]
  );

  const checkError = () => {
    if (
      !firstTouchField &&
      formik.values.secondCode > '0' &&
      formik.values.secondCode !== '0' &&
      formik.values.secondCode !== '00' &&
      !formik.values.firstCode &&
      meta.touched
    ) {
      return `※${MESSAGE_REGEX.REQUIRED}`;
    }
    if (
      (!firstTouchField && !!meta.error && meta.touched) ||
      (!firstTouchField &&
        formik.values.firstCode === '0' &&
        (!formik.values.secondCode ||
          formik.values.secondCode === '0' ||
          formik.values.secondCode === '00') &&
        meta.touched)
    ) {
      return `※${MESSAGE_REGEX.GREATER_THAN_ZERO}`;
    }
  };

  return (
    <FormikProvider value={formik}>
      <input name={field.name} type="hidden" />
      <Stack spacing={1}>
        <Stack spacing="6px">
          {!!label && (
            <Typography variant="buttonAddImage" color="normal_text">
              {label}
            </Typography>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {areaInputs.map((input, index) => (
              <Fragment key={index}>
                <Stack direction="row" alignItems="center">
                  <SPInputField
                    inputRef={input?.ref}
                    name={input.name}
                    sx={{
                      width: index ? 61 : 123,
                      '& .MuiInputBase-input': {
                        textAlign: 'center',
                      },
                    }}
                    placeholder={index ? '--' : '---'}
                    onChange={handleKeyPress}
                    onKeyDown={(e) => handleFocusInput(e, input.name)}
                    onKeyPress={(event) => onlyNumbers(event)}
                    onFocus={() => {
                      setFieldTouched(field.name);
                      currentIndex.current = index;
                      setFirstTouchField(true);
                      onFocus && onFocus();
                    }}
                    onBlur={handleBlur}
                    maxLength={input.maxLength}
                    numeric
                    error={
                      (!firstTouchField && !!meta.error && meta.touched) ||
                      (formik.values.secondCode &&
                        formik.values.secondCode !== '0' &&
                        formik.values.secondCode !== '00' &&
                        !formik.values.firstCode &&
                        meta.touched)
                    }
                    showError={false}
                    toFixedNumber
                  />
                </Stack>
                {index !== areaInputs.length - 1 && (
                  <Typography variant="text_note" color="b_333">
                    .
                  </Typography>
                )}
              </Fragment>
            ))}
            {!!unit && (
              <Typography
                variant="sp_unit_text"
                color="b_333"
                whiteSpace="nowrap"
              >
                {unit}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Typography variant="text_error" color="sp_secondary_01">
          {checkError()}
        </Typography>
      </Stack>
    </FormikProvider>
  );
};
