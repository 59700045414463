import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasterDatas } from 'services';
import { handleShowError } from 'utils';
import { APPLICATION_STATUS_LIST_DEFAULT, PreliminariesStatus } from 'constant';
import {
  assignSalePersons,
  assignStoreName,
  getCurrentSalePersonPreliminariesItems,
} from './thunk';

type SalePersonPreliminary = {
  id: string;
  application_number: string;
  bank_name: string;
  applicant_id: string;
  applicant_name: string;
  created_at: string;
  loan_desired_borrowing_date: string;
  temporary_desired_loan_amount: string;
  status_result: string;
  under_review_status: string;
  provisional_result: string;
  store_name_kanji: string;
  sale_person_name_kanji: string;
  exhibition_hall: string;
  manager_name_kanji: string;
  list_sale_people: { id: string; name_kanji: string; active: boolean }[];
  application_status_lists: {
    key: string;
    value: number;
    active: boolean;
    enable: boolean;
  }[];
  p_referral_agency_path: string;
  list_store: { id: string; store_name_kanji: string; path: string }[];
  unread_messages?: string;
  user_id: string;
  linking: string;
};

export interface InitialState {
  fetch: boolean;
  currentPreliminaries: SalePersonPreliminary[];
  inProgressPreliminaries: SalePersonPreliminary[];
  pastPreliminaries: SalePersonPreliminary[];
  masterDatas: MasterDatas;
  total: number;
  activeTab: PreliminariesStatus;
}

const defaultPreliminaries = [
  {
    id: '',
    application_number: '',
    bank_name: '',
    applicant_id: '',
    applicant_name: '',
    created_at: '',
    loan_desired_borrowing_date: '',
    temporary_desired_loan_amount: '',
    status_result: '',
    under_review_status: '',
    provisional_result: '',
    store_name_kanji: '',
    sale_person_name_kanji: '',
    exhibition_hall: '',
    p_referral_agency_path: '',
    manager_name_kanji: '',
    list_sale_people: [],
    list_store: [],
    application_status_lists: APPLICATION_STATUS_LIST_DEFAULT,
    user_id: '',
    linking: '',
    unread_messages: '',
  },
];

const initialState: InitialState = {
  fetch: false,
  currentPreliminaries: [],
  inProgressPreliminaries: defaultPreliminaries,
  pastPreliminaries: defaultPreliminaries,
  masterDatas: {
    under_review_status_lists: [],
    branch_names: [],
    exhibition_hall_names: [],
    office_names: [],
    sale_person_names: [],
  },
  total: 0,
  activeTab: PreliminariesStatus.CURRENT,
};

const salePersonPreliminariesSlice = createSlice({
  name: 'managerPreliminaries',
  initialState,
  reducers: {
    revertSalePersonPreliminary: (state) => {
      state.currentPreliminaries = [];
    },
    setActiveTab: (state, action: PayloadAction<PreliminariesStatus>) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentSalePersonPreliminariesItems.pending, (state) => {
      state.fetch = true;
    });
    builder.addCase(
      getCurrentSalePersonPreliminariesItems.fulfilled,
      (state, action) => {
        state.fetch = false;
        if (action.payload.total === 0) {
          state.currentPreliminaries = action.payload.data;
        } else {
          state.currentPreliminaries = state.currentPreliminaries.concat(
            action.payload.data
          );
        }
        state.masterDatas = action.payload.master_data;
        state.total = action.payload.total;
      }
    );
    builder.addCase(
      getCurrentSalePersonPreliminariesItems.rejected,
      (state, action) => {
        handleShowError(action);
      }
    );
    builder.addCase(assignSalePersons.rejected, (state, action) => {
      handleShowError(action);
    });
    builder.addCase(assignSalePersons.fulfilled, (state, action) => {
      const index = state.currentPreliminaries.findIndex(
        (pre) => +pre.id === +action.meta.arg.id
      );
      if (index >= 0 && action.payload.data.length) {
        state.currentPreliminaries[index] = action.payload.data[0];
      }
    });
    builder.addCase(assignStoreName.fulfilled, (state, action) => {
      const index = state.currentPreliminaries.findIndex(
        (pre) => +pre.id === +action.meta.arg.id
      );
      if (index >= 0 && action.payload.data.length) {
        state.currentPreliminaries[index] = action.payload.data[0];
      }
    });
    builder.addCase(assignStoreName.rejected, (state, action) => {
      handleShowError(action);
    });
  },
});

export const { revertSalePersonPreliminary, setActiveTab } =
  salePersonPreliminariesSlice.actions;

export default salePersonPreliminariesSlice.reducer;
