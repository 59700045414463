import {
  FC,
  useEffect,
  useMemo,
  FocusEvent,
  ChangeEvent,
  useCallback,
  useState,
  useRef,
} from 'react';
import { FastField, FastFieldProps } from 'formik';
import {
  Stack,
  SxProps,
  TextFieldProps,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import { isHiragana, toKatakana } from 'wanakana';
import { BasePlacement } from '@popperjs/core/lib/enums';
import { convertToFullWidth, convertToHalfWidth } from 'utils';
import { debounce } from 'lodash';
import get from 'lodash/get';
import { Icons } from 'components';
import { NumberFormat, NumberFormatDecimal } from '../NumberFormat';

export type AdminInputProps = TextFieldProps & {
  name: string;
  placeholder?: string;
  unit?: string;
  align?: 'left' | 'right';
  useNumberFormat?: boolean;
  convertKatakana?: boolean;
  toFixedNumber?: boolean;
  labelSpacing?: number;
  numeric?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  placement?: BasePlacement | 'top-end' | 'top-start' | 'bottom-start';
  maxLength?: number;
  convertHalfWidth?: boolean;
  showPassword?: boolean;
  convertFullWidth?: boolean;
  decimalScale?: number;
  sxPopover?: SxProps;
  hasPassword?: boolean;
  showError?: boolean;
};

export const AdminInputTemp: FC<AdminInputProps & FastFieldProps> = ({
  field,
  form,
  meta,
  label,
  InputProps,
  unit,
  useNumberFormat,
  convertKatakana,
  toFixedNumber,
  labelSpacing = 1,
  numeric,
  align = 'left',
  errorMessage,
  readOnly,
  onChange,
  placement = 'top-end',
  maxLength,
  convertHalfWidth,
  convertFullWidth,
  decimalScale,
  onFocus,
  hasPassword,
  showError = true,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [firstTouchField, setFirstTouchField] = useState(false);

  const isSuccess = useMemo(
    () => !meta.error && meta.value && !props.error,
    [meta.error, meta.value, props.error]
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      onFocus?.(e);
      setFirstTouchField(true);
    },
    [onFocus]
  );

  const formatNumber = useCallback(
    (number: string) => {
      if (number === '') return number;
      if (/^0*$/.test(number)) return '0';
      if (toFixedNumber) return Number(number).toFixed(2);
      return Number(number);
    },
    [toFixedNumber]
  );

  const handleBlur = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      await field.onBlur(e);
      const trimValue = meta.value.trim();
      form.setFieldValue(props.name, trimValue);
      if (convertHalfWidth) {
        if (isHiragana(trimValue) && convertKatakana) {
          const katakana = toKatakana(trimValue);
          form.setFieldValue(props.name, convertToHalfWidth(katakana));
        } else {
          form.setFieldValue(props.name, convertToHalfWidth(trimValue));
        }
      }
      if (convertFullWidth) {
        form.setFieldValue(props.name, convertToFullWidth(trimValue));
      }
      if (useNumberFormat) {
        form.setFieldValue(props.name, formatNumber(trimValue));
      }
      props.onBlur && props.onBlur(e);
      setFirstTouchField(false);
    },
    [
      field,
      meta.value,
      form,
      props,
      convertHalfWidth,
      convertFullWidth,
      useNumberFormat,
      convertKatakana,
      formatNumber,
    ]
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      await field.onChange(event);
      onChange && onChange(event);
    },
    [field, onChange]
  );

  const debouncedFunction = useRef(
    debounce((error, touched, value) => {
      // @ts-ignore
      if (!!error && !!value) {
        return setOpen(!!error && !!value);
      }
      return setOpen(!!error && !!touched);
    }, 250)
  );

  const debounceError = useCallback(
    // @ts-ignore
    (error, touched, value) => {
      return debouncedFunction.current(error, touched, value);
    },
    []
  );

  useEffect(() => {
    debounceError(meta.error, meta.touched, meta.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.error, meta.touched, meta.value]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debouncedFunction.current?.cancel();
    };
  }, []);

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
    >
      <Stack spacing={labelSpacing}>
        {!!label && (
          <Typography variant="h3" color="normal_text">
            {label}
          </Typography>
        )}
        <Stack direction="row" alignItems="center" spacing={3}>
          <Stack flex={1}>
            <TextField
              sx={{
                width: '100%',
                height: 44,
                '.MuiInputBase-input': {
                  fontFamily: 'Noto Sans JP',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: '34px',
                  p: '0px 15px',
                  pr: hasPassword ? '35px' : '12px',
                  boxShadow: 'none',
                  height: '44px',
                  color: 'b_333',
                },
                '&&&& fieldset': {
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderColor: 'b_333',
                  boxShadow: 'none',
                  borderRadius: 0,
                },
                ...props.sx,
                '&&&& .Mui-error': {
                  '.MuiInputBase-input': {
                    backgroundColor: '#FFEDF2',
                    boxShadow: 'none',
                    color: 'sp_secondary_01',
                  },
                  fieldset: {
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderColor: 'sp_secondary_01',
                  },
                },
                '&&&& .Mui-success': {
                  '.MuiInputBase-input': {
                    backgroundColor: 'main_white',
                    boxShadow: 'none',
                  },
                  fieldset: {
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderColor: 'b_333',
                    boxShadow: 'none',
                  },
                },
                '&&&& .Mui-focused': {
                  '.MuiInputBase-input': {
                    backgroundColor: 'main_white',
                    color: 'b_333',
                  },
                  fieldset: {
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderColor: 'b_333',
                  },
                },
                '&&&& .Mui-focused.Mui-success': {
                  fieldset: {
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderColor: 'b_333',
                  },
                },
              }}
              {...props}
              InputProps={{
                ...(useNumberFormat && {
                  inputComponent: (decimalScale
                    ? NumberFormatDecimal
                    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      NumberFormat) as any,
                }),
                ...InputProps,
                type: hasPassword && !showPassword ? 'password' : props?.type,
                sx: {
                  ...InputProps?.sx,
                  '.MuiInputBase-input': {
                    textAlign: align,
                    boxShadow: 'none',
                    backgroundColor: !readOnly ? 'main_white' : undefined,
                  },
                  '&&&& fieldset': {
                    borderWidth: 0,
                    borderBottomWidth: 1,
                  },
                },
                className: `${isSuccess && !readOnly ? 'Mui-success' : ''} `,
                onBlur: handleBlur,
                onFocus: handleFocus,
                onChange: handleChange,
              }}
              inputProps={{
                ...(numeric && {
                  inputMode: 'numeric',
                }),
                readOnly: readOnly,
                maxLength: maxLength,
              }}
              name={field.name}
              value={meta.value}
            />
            {!firstTouchField && errorMessage && (
              <Typography
                variant="SP_form_item_help"
                color="sp_secondary_01"
                sx={{
                  lineHeight: '15.6px',
                  mt: '15px',
                }}
              >
                ※{errorMessage ?? ''}
              </Typography>
            )}
            {!firstTouchField && meta.error && open && showError && (
              <Typography
                variant="SP_form_item_help"
                color="sp_secondary_01"
                sx={{
                  lineHeight: '15.6px',
                  mt: '15px',
                }}
              >
                ※{meta.error ?? errorMessage ?? ''}
              </Typography>
            )}
          </Stack>
          {!!unit && (
            <Typography
              variant="unit_text"
              color="normal_text"
              whiteSpace="nowrap"
            >
              {unit}
            </Typography>
          )}
        </Stack>
      </Stack>
      {hasPassword &&
        (!showPassword ? (
          <Box
            sx={{
              position: 'absolute',
              right: 12,
              top: 15,
            }}
            onClick={() => {
              setShowPassword(true);
            }}
          >
            <Icons.PasswordEye />
          </Box>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              right: 12,
              top: 15,
            }}
            onClick={() => {
              setShowPassword(false);
            }}
          >
            <Icons.PasswordHide sx={{ color: 'gray', width: 20, height: 20 }} />
          </Box>
        ))}
    </Stack>
  );
};

export const AdminInput: FC<AdminInputProps> = ({ showPassword, ...props }) => {
  const shouldUpdate = useCallback(
    (
      nextProps: { showPassword: boolean; error: boolean },
      prevProps: { showPassword: boolean; error: boolean }
    ) =>
      nextProps.showPassword !== prevProps.showPassword ||
      nextProps.error !== prevProps.error ||
      get(nextProps, `formik.values.${props.name}`) !==
        get(prevProps, `formik.values.${props.name}`) ||
      get(nextProps, `formik.errors.${props.name}`) !==
        get(prevProps, `formik.errors.${props.name}`) ||
      get(nextProps, `formik.touched.${props.name}`) !==
        get(prevProps, `formik.touched.${props.name}`),
    [props.name]
  );
  return (
    <FastField
      name={props.name}
      showPassword={showPassword}
      error={props.error}
      shouldUpdate={showPassword !== undefined ? shouldUpdate : undefined}
    >
      {(ffProps: FastFieldProps) => {
        return <AdminInputTemp {...props} {...ffProps} />;
      }}
    </FastField>
  );
};
