import { z } from 'zod';

export const reviewStatusRequestSchema = z.object({
  id: z.string(),
  applicant_id: z.string(),
});

export const reviewStatusSchema = z.object({
  applicant_name: z.string(),
  bank_name: z.array(z.string()),
  status_name: z.string(),
});
export const reviewStatusResponseSchema = z.object({
  success: z.boolean(),
  data: reviewStatusSchema,
});

export type ReviewStatusRequest = z.infer<typeof reviewStatusRequestSchema>;
export type ReviewStatus = z.infer<typeof reviewStatusSchema>;
export type ReviewStatusResponse = z.infer<typeof reviewStatusResponseSchema>;
export type ReviewStatusError = {};
