import {
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  Badge,
} from '@mui/material';
import { Button, Icons, PreliminaryStatuses } from 'components';
import { PROVISIONAL_RESULT, PreliminariesStatus } from 'constant';
import { useAppDispatch } from 'hooks';
import { routeNames } from 'navigations/routes';
import { DialogLinking } from 'pages/ManagerPreliminaries/components/DialogLinking';
import { setPremilinary } from 'pages/ManagerPreliminaries/slice';
import { setConversationDetail } from 'pages/ScreenMessages/slice';
import { getMessageDetailManager } from 'pages/ScreenMessages/thunk';
import { getListStatus } from 'pages/StatusScreen/thunk';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AssignSalePersonRequest,
  AssignStoreNameRequest,
  ManagerPreliminariesItem,
} from 'services';
import { convertDateTime, formatJapanDateTine, numberWithCommas } from 'utils';
import { toNumber } from 'lodash';
import { AutocompleteSalePerson } from '../SalePersonPreliminariesItems/AutocompleteSalePerson';

export type ListLinking = {
  value: string; // id
  label: string; // application_number
};

export type ManagerPreliminariesItemProps = {
  activeTab: string;
  changeApplicationStatus: (
    applicantId: string,
    applicationStatus: number
  ) => void;
  confirmUpdateLinking: (
    idLinking: string,
    idSelected: string,
    isLinking: boolean
  ) => void;
  onAssignManager: (id: string, managerId: string) => void;
  onAssignSalesPerson: (val: AssignSalePersonRequest) => void;
  onAssignStoreName: (val: AssignStoreNameRequest) => void;
  data: ManagerPreliminariesItem[];
};

export const ManagerPreliminariesItems: FC<ManagerPreliminariesItemProps> = ({
  data,
  activeTab,
  changeApplicationStatus,
  confirmUpdateLinking,
  onAssignSalesPerson,
  onAssignStoreName,
  onAssignManager,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openLinking, setOpenLinking] = useState<boolean>(false);
  const [isLinking, setIsLinking] = useState<boolean>(true);
  const [idLinking, setIdLinking] = useState<string>('');
  const [listUnlink, setListUnlink] = useState<ListLinking[]>([]);
  const [listLinked, setListLinked] = useState<ListLinking[]>([]);

  useEffect(() => {
    setListUnlink(
      [...data]
        .filter((item) => {
          return !item.linking && item.loan_type === '2';
        })
        .map((unlink) => ({
          value: unlink.id,
          label: unlink.application_number,
        }))
    );
  }, [data]);

  const handleGetListStatus = useCallback(
    (id: string, status_result: string) => {
      if (status_result === '4' || status_result === '3') {
        dispatch(getListStatus({ id }));

        navigate({
          pathname: routeNames.StatusScreen.path,
          search: `?preliminary_id=${id}`,
        });
      }
    },
    [dispatch, navigate]
  );

  const onClickLinking = useCallback(
    (id: string, linking: string) => {
      setIdLinking(id);
      if (linking) {
        setIsLinking(true);
        setListLinked(
          [...data]
            .filter((item) => {
              return item.id !== id && item.linking === linking;
            })
            .map((linked) => ({
              value: linked.id,
              label: linked.application_number,
            }))
        );
      } else {
        setIsLinking(false);
      }
      setOpenLinking(true);
    },
    [data]
  );

  const renderStatusResult = useCallback((resultStatus: string) => {
    switch (resultStatus) {
      case '0':
        return (
          <Typography
            variant="priliminary_text"
            color="b_333"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '@media (max-width: 1440px)': {
                paddingRight: '10px',
              },
            }}
          >
            <Icons.StatusResult fill="#D5D2FF" stroke="#C5D5FF" />
            書類確認中
          </Typography>
        );
      case '1':
        return (
          <Typography
            variant="priliminary_text"
            color="b_333"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '@media (max-width: 1440px)': {
                paddingRight: '10px',
              },
            }}
          >
            <Icons.StatusResult fill="#D5D2FF" stroke="#C5D5FF" />
            書類不備対応中
          </Typography>
        );
      case '2':
        return (
          <Typography
            variant="priliminary_text"
            color="b_333"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '@media (max-width: 1440px)': {
                paddingRight: '10px',
              },
            }}
          >
            <Icons.StatusResult fill="#D5D2FF" stroke="#C5D5FF" />
            書類不備対応中
          </Typography>
        );
      case '3':
        return (
          <Typography
            variant="priliminary_text"
            color="sp_primary_100_main"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '@media (max-width: 1440px)': {
                paddingRight: '10px',
              },
            }}
          >
            <Icons.StatusResult fill="#FFAD7C" stroke="#DF8550" />
            仮審査中
          </Typography>
        );
      case '4':
        return (
          <Typography
            variant="priliminary_text"
            color="sp_primary_100_main"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '@media (max-width: 1440px)': {
                paddingRight: '10px',
              },
            }}
          >
            <Icons.StatusResult fill="#FFAD7C" stroke="#DF8550" />
            仮審査中
          </Typography>
        );
      case '5':
        return (
          <Typography
            variant="priliminary_text"
            color="b_333"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '@media (max-width: 1440px)': {
                paddingRight: '10px',
              },
            }}
          >
            <Icons.StatusResult fill="#D5F5D5" stroke="#ACEAB1" />
            提携会社へ審査結果公開
          </Typography>
        );
      case '6':
        return (
          <Typography
            variant="priliminary_text"
            color="b_333"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '@media (max-width: 1440px)': {
                paddingRight: '10px',
              },
            }}
          >
            <Icons.StatusResult fill="#D5F5D5" stroke="#ACEAB1" />
            申込人へ審査結果公開
          </Typography>
        );
    }
  }, []);

  const goToEditPreliminary = useCallback(
    (data: ManagerPreliminariesItem) => {
      dispatch(setPremilinary(data));
      navigate({
        pathname: routeNames.AdminEditPreliminary.path,
        search: `?preliminary_id=${data.id}`,
      });
    },
    [dispatch, navigate]
  );

  const goToMessageDetail = useCallback(
    async (
      conversationId: string,
      conversationName: string,
      preliminaryId: string
    ) => {
      const result = await dispatch(getMessageDetailManager(conversationId));
      if (getMessageDetailManager.fulfilled.match(result)) {
        dispatch(
          setConversationDetail({
            conversationId,
            conversationName,
          })
        );
        navigate({
          pathname: routeNames.ManagerMessageDetail.path,
          search: `?user_id=${conversationId}&preliminary_id=${preliminaryId}`,
        });
      }
    },
    [dispatch, navigate]
  );
  const formatTemporaryAmount = (amount: string) => {
    return numberWithCommas(Number(amount) / 10000);
  };

  return (
    <Stack sx={{ mb: '13px' }}>
      {data.map((item, index) => (
        <Stack
          key={index}
          sx={{
            bgcolor: `${item.linking ? 'linking_loan' : 'main_white'}`,
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            p: 2,
            borderRadius: '2px',
            mt: `${
              index > 0 &&
              item.linking &&
              data[index - 1].linking === item.linking
                ? '0px'
                : '13px'
            }`,
            borderTop: (theme) =>
              index > 0 &&
              item.linking &&
              data[index - 1].linking === item.linking
                ? `4px solid ${theme?.palette?.green_spagebar_88C095}`
                : 'none',
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              bgcolor: `${item.linking ? 'linking_loan' : 'main_white'}`,
              borderBottom: '1px solid',
              borderColor: 'bg_gray',
              alignItems: 'center',
            }}
          >
            <TableCellStyled sx={{ width: 153 }}>
              <Typography variant="text_preliminarie_item" color="b_333">
                {item.application_number}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 150,
                '@media (min-width: 1441px)': {
                  flex: 1,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {item.bank_name}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 180,
                '@media (min-width: 1441px)': {
                  flex: 1,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {item.applicant_name?.[0]}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 115,
                '@media (min-width: 1441px)': {
                  width: 130,
                },
              }}
            >
              <Typography variant="priliminary_number" color="b_333">
                {formatJapanDateTine(item.created_at, 'YY/MM/DD HH時')}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 100,
                '@media (min-width: 1441px)': {
                  width: 130,
                },
              }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                variant="priliminary_number"
              >
                {convertDateTime(item.loan_desired_borrowing_date, 'YY/MM/DD')}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                textAlign: 'right',
                width: 100,
                '@media (min-width: 1441px)': {
                  width: 130,
                },
              }}
            >
              <Typography variant="priliminary_number" color="b_333">
                {formatTemporaryAmount(item.temporary_desired_loan_amount)}
              </Typography>
              <Typography
                variant="priliminary_text"
                color="normal_text"
                fontSize={7}
                sx={{
                  paddingRight: '10px',
                }}
              >
                （万円）
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 150,
                '@media (min-width: 1441px)': {
                  width: 201,
                },
              }}
            >
              <Typography
                onClick={() => {
                  handleGetListStatus(item.id, item.status_result);
                }}
              >
                {renderStatusResult(item.status_result)}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 100,
                '@media (min-width: 1441px)': {
                  width: 125,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {item.provisional_result
                  ? PROVISIONAL_RESULT[Number(item.provisional_result)]
                  : 'ー'}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 135,
                '@media (min-width: 1441px)': {
                  width: 160,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {!!item.list_store && (
                  <AutocompleteSalePerson
                    value={
                      item.list_store.find((people) => {
                        if (
                          item.p_referral_agency_path.split('/').length === 2
                        ) {
                          return people.path === item.p_referral_agency_path;
                        }
                        return (
                          item.p_referral_agency_path.startsWith(people.path) &&
                          people.path.split('/').length > 2
                        );
                      })?.id ?? ''
                    }
                    onChange={(value) => {
                      if (value) {
                        onAssignStoreName({
                          id: item.id,
                          p_referral_agency_id: value,
                        });
                      }
                    }}
                    options={item.list_store.map((val) => ({
                      label: val.store_name_kanji,
                      id: val.id,
                    }))}
                  />
                )}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 135,
                '@media (min-width: 1441px)': {
                  width: 160,
                },
              }}
            >
              <Typography variant="priliminary_text" color="b_333">
                {!!item.list_sale_people && (
                  <AutocompleteSalePerson
                    value={
                      item.list_sale_people.find((people) => people.active)
                        ?.id ?? ''
                    }
                    onChange={(value) => {
                      onAssignSalesPerson({
                        id: item.id,
                        s_sale_person_id: value,
                      });
                    }}
                    options={item.list_sale_people.map((val) => ({
                      label: val.name_kanji,
                      id: val.id,
                    }))}
                  />
                )}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                justifyContent: 'center',
                px: 1,
                width: 120,
                '@media (min-width: 1441px)': {
                  width: 140,
                },
              }}
            >
              {!!item.list_managers && (
                <AutocompleteSalePerson
                  value={
                    item.list_managers.find((manager) => manager.active)?.id ??
                    ''
                  }
                  onChange={(value) => {
                    if (value) {
                      onAssignManager(item.id, value);
                    }
                  }}
                  options={item.list_managers.map((val) => ({
                    label: val.name_kanji,
                    id: val.id,
                  }))}
                />
              )}
            </TableCellStyled>
          </TableRow>
          <Stack sx={{ mt: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center">
                <Button
                  variant="text"
                  sx={{
                    mr: '10px',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'main_white',
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => goToEditPreliminary(item)}
                >
                  <Typography
                    sx={{
                      '&.MuiTypography-root:hover': {
                        color: 'blue_08',
                      },
                    }}
                    variant="dashboard_text"
                    color="sp_primary_100_main"
                  >
                    申込内容の修正・確認
                  </Typography>
                </Button>
                <Icons.Slash sx={{ color: 'gray' }} />
                <Button
                  variant="text"
                  sx={{
                    mr: '10px',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'main_white',
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() =>
                    goToMessageDetail(
                      item.user_id,
                      item.applicant_name[0],
                      item.id
                    )
                  }
                >
                  <Badge
                    badgeContent={toNumber(item.unread_messages) || 0}
                    color="error"
                    sx={{
                      '& .BaseBadge-badge': {
                        height: 16,
                        fontSize: 10,
                        lineHeight: 12,
                        minWidth: 16,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        '&.MuiTypography-root:hover': {
                          color: 'blue_08',
                        },
                      }}
                      variant="dashboard_text"
                      color="sp_primary_100_main"
                    >
                      メッセージ確認
                    </Typography>
                  </Badge>
                </Button>
                <Icons.Slash />
                <Button
                  variant="text"
                  sx={{
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'main_white',
                      opacity: 1,
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => {
                    dispatch(setPremilinary(item));
                    navigate(
                      {
                        pathname: routeNames.Memo.path,
                        search: `?preliminary_id=${item.id}`,
                      },
                      { state: { label: item.applicant_name?.[0] } }
                    );
                  }}
                >
                  <Typography
                    sx={{
                      '&.MuiTypography-root:hover': {
                        color: 'blue_08',
                      },
                    }}
                    variant="dashboard_text"
                    color="sp_primary_100_main"
                  >
                    メモ表示
                  </Typography>
                </Button>

                {item.loan_type === '2' && <Icons.Slash />}
                {item.loan_type === '2' && (
                  <Button
                    disabled={
                      (activeTab === PreliminariesStatus.PAST ||
                        activeTab === PreliminariesStatus.INPROGRESS) &&
                      !!item.linking
                    }
                    variant="text"
                    sx={{
                      mr: '78px',
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: 'main_white',
                        opacity: 1,
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => onClickLinking(item.id, item.linking)}
                  >
                    <Typography
                      sx={{
                        '&.MuiTypography-root:hover': {
                          color: 'blue_08',
                        },
                      }}
                      variant="dashboard_text"
                      color={
                        (activeTab === PreliminariesStatus.PAST ||
                          activeTab === PreliminariesStatus.INPROGRESS) &&
                        item.linking
                          ? 'gray'
                          : 'sp_primary_100_main'
                      }
                    >
                      {item.linking ? 'ペアローン解除' : 'ペアローン紐付'}
                    </Typography>
                  </Button>
                )}
              </Stack>
              <PreliminaryStatuses
                activeTab={activeTab}
                applicantId={item.id}
                isLinking={!!item.linking}
                onClick={changeApplicationStatus}
                applicationStatusList={item.application_status_lists}
                statusResult={item.status_result}
                isManager={true}
                provisionalResult={item.provisional_result}
              />
            </Stack>
          </Stack>
        </Stack>
      ))}
      <DialogLinking
        open={openLinking}
        idLinking={idLinking}
        isLinking={isLinking}
        onClose={() => setOpenLinking(false)}
        onConfirm={confirmUpdateLinking}
        textPairOrUnPair={isLinking ? 'ペアローン解除' : 'ペアローン紐付'}
        listUnLink={listUnlink}
        listLinked={listLinked}
      />
    </Stack>
  );
};

const TableCellStyled = styled(TableCell)(() => ({
  padding: '10px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));
