import { FC, PropsWithChildren } from 'react';
import {
  useDropzone,
  DropzoneOptions,
  FileRejection,
  ErrorCode,
} from 'react-dropzone';
import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { Button } from 'components';
import { ACCEPT_TYPE, MAX_FILES, MAX_SIZE_FILE_ADMIN } from 'constant';
import { toast } from 'react-toastify';
import { convertToMB } from 'utils';

export type InputFileAdminProps = Pick<
  DropzoneOptions,
  'accept' | 'disabled' | 'multiple' | 'maxSize' | 'maxFiles'
> &
  PropsWithChildren<{
    name?: string;
    disabled?: boolean;
    onChange: (acceptedFiles: File[]) => void;
    sx?: SxProps<Theme>;
  }>;

export const InputFileAdmin: FC<InputFileAdminProps> = ({
  name,
  onChange,
  accept = ACCEPT_TYPE,
  maxSize = MAX_SIZE_FILE_ADMIN,
  maxFiles = MAX_FILES,
  sx,
  ...props
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    ...props,
    accept,
    maxSize,
    onDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
      if (fileRejections.length > 0) {
        if (
          fileRejections.find((item) =>
            item.errors.find((e) => e.code.includes(ErrorCode.FileInvalidType))
          )
        ) {
          return toast.error(
            `アップロードファイルは不正です。（ ※アップロード可能拡張子：png、jpg、jpeg、pdfです。）`
          );
        }
        if (
          fileRejections.find((item) =>
            item.errors.find((e) => e.code.includes(ErrorCode.FileTooLarge))
          )
        ) {
          return toast.error(
            `許容容量 (${convertToMB(MAX_SIZE_FILE_ADMIN)}MB) を超えています`
          );
        }
        if (
          fileRejections.find((item) =>
            item.errors.find((e) => e.code.includes(ErrorCode.FileTooSmall))
          )
        ) {
          return toast.error(`ファイルの容量は小さいすぎです。`);
        }
        if (
          fileRejections.find((item) =>
            item.errors.find((e) => e.code.includes(ErrorCode.TooManyFiles))
          )
        ) {
          return toast.error(`ファイルの数が多いすぎです。`);
        }
      }
      onChange(acceptedFiles);
    },
  });

  return (
    <Stack
      {...getRootProps()}
      textAlign="center"
      // justifyContent="center"
      alignItems="center"
      sx={{
        p: '18px 0px 16px 0px',
        cursor: 'pointer',
        ...sx,
      }}
    >
      <input {...getInputProps()} type="file" />
      {props.children ? (
        props.children
      ) : (
        <>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: { mobile: '200px', tablet: '400px' } }}
          >
            <Button
              sx={{
                minHeight: '46px',
                bgcolor: 'h_blugreen',
                mb: 3,
              }}
              fullWidth
            >
              <Typography
                variant="button_title"
                color="main_white"
                fontWeight={700}
              >
                ファイルを選択する ／ 写真を撮る
              </Typography>
            </Button>
          </Stack>
          <Stack>
            <Typography
              variant="checkbox_button_title"
              fontSize={16}
              lineHeight="24px"
              letterSpacing="-0.4px"
              color="normal_text"
            >
              撮影した画像データまたはPDFをアップロードして下さい
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};
