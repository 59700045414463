import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  ChangeEmailError,
  ChangeEmailRequest,
  ChangeEmailResponse,
  UpdateNewEmailResponse,
  UpdateNewEmailRequest,
  UpdateNewEmailError,
} from 'services';
import { ThunkAPI } from 'types';
import { AxiosError } from 'axios';

export const sendEmailUpdateEmailAction = createAsyncThunk<
  ChangeEmailResponse,
  ChangeEmailRequest,
  ThunkAPI<ChangeEmailError>
>(
  'updateEmail/sendEmailUpdateEmailAction',
  async (user, { rejectWithValue }) => {
    try {
      return await authService.sendEmailUpdateEmail(user);
    } catch (error) {
      const err = (error as AxiosError<ChangeEmailError>).response?.data;
      if (err) return rejectWithValue(err);
      return rejectWithValue(error);
    }
  }
);

export const updateNewEmailAction = createAsyncThunk<
  UpdateNewEmailResponse,
  UpdateNewEmailRequest,
  ThunkAPI<UpdateNewEmailError>
>('updateEmail/updateNewEmailAction', async (user, { rejectWithValue }) => {
  try {
    return await authService.updateNewEmail(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});
