import { InboxOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Icons } from 'components';
import { PreliminariesStatus } from 'constant';
import { Dispatch, FC, MouseEvent, SetStateAction } from 'react';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, OrderType, TableProps } from 'types';
import { DetailArchiveFileType } from 'pages/ManagerDocumentUpload/slice';
import { ManagerDocumentUploadDetailItems } from '../ManagerDocumentUploadDetailItems';

type ManagerDocumentUploadDetailTableProps = {
  activeTab?: string;
  fetching: boolean;
  changeApplicationStatus: (
    applicantId: string,
    applicationStatus: number
  ) => void;
  confirmUpdateLinking: (
    idLinking: string,
    idSelected: string,
    isLinking: boolean
  ) => void;
  onAssignManager: (id: string, manager_id: string) => void;
  onReset: Dispatch<SetStateAction<number>>;
};

export const ManagerDocumentUploadDetailTable: FC<
  TableProps<DetailArchiveFileType> & ManagerDocumentUploadDetailTableProps
> = ({
  onRequestSort,
  onFilter,
  order,
  orderBy,
  rows,
  headCells,
  activeTab,
  fetching,
  changeApplicationStatus,
  confirmUpdateLinking,
  onAssignManager,
  onReset,
}) => {
  const createSortHandler =
    (property: keyof DetailArchiveFileType) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table aria-label="simple table">
        <TableHead
          sx={{
            display: 'flex',
            minWidth: 1024,
            p: '10px',
            bgcolor: 'bg_pale_gray',
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              bgcolor: 'main_white',
              flex: 1,
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            }}
          >
            {(headCells ? headCells : defaultDetailHeadCells).map(
              (headCell, index) => (
                <TableCell
                  key={headCell.id}
                  sx={{
                    ...headerCellStyle,
                    ...headCell.style,
                  }}
                >
                  <Typography
                    sx={{
                      direction: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="table_header_text"
                      sx={{ whiteSpace: 'nowrap' }}
                      color="b_333"
                    >
                      {headCell.label}
                    </Typography>
                    {headCell.smallLabel && (
                      <Typography
                        variant="table_small_text"
                        sx={{ whiteSpace: 'nowrap' }}
                        color="b_333"
                      >
                        {headCell.smallLabel}
                      </Typography>
                    )}
                    {typeof headCell.label === 'string' && (
                      <TableSort
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : undefined}
                        onClick={createSortHandler(headCell.id)}
                      />
                    )}
                  </Typography>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && (
            <TableRow>
              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                <Stack
                  sx={{
                    bgcolor: 'bg_off',
                    overflowX: 'auto',
                    height: 'auto',
                    maxHeight: ` calc(100vh - 130px)`,
                    flex: 1,
                    ...styleScrollBarTable,
                  }}
                  id="preliminary-table-body"
                >
                  <ManagerDocumentUploadDetailItems
                    data={rows}
                    activeTab={
                      activeTab ? activeTab : PreliminariesStatus.CURRENT
                    }
                    changeApplicationStatus={changeApplicationStatus}
                    confirmUpdateLinking={confirmUpdateLinking}
                    onAssignManager={onAssignManager}
                    onReset={onReset}
                  />
                  {fetching && (
                    <Stack alignItems={'center'} sx={{ marginBottom: 3 }}>
                      <CircularProgress />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </TableRow>
          )}
          {!fetching && rows.length === 0 && (
            <Stack
              sx={{
                display: 'flex',
                height: 'calc(100vh - 110px)',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#d8d8d8',
                background: '#f8f8f8',
              }}
            >
              <InboxOutlined
                sx={{
                  width: 80,
                  height: 70,
                  marginRight: '5px',
                }}
              />
              <Typography sx={{ color: '#7d7d7d' }}>
                案件がありません。
              </Typography>
            </Stack>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type SortTableProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const TableSort: FC<SortTableProps> = ({ active, direction, onClick }) => {
  return (
    <IconButton sx={{ p: '2px' }} onClick={onClick}>
      {direction === undefined && (
        <Icons.SortDefault
          sx={{
            width: 16,
            height: 13,
            color: 'gray',
          }}
        />
      )}
      {direction === 'desc' && (
        <Icons.SortDesc
          sx={{
            width: 16,
            height: 13,
            color: 'sp_primary_100_main',
          }}
          stroke="#CCCCCC"
        />
      )}
      {direction === 'asc' && (
        <Icons.SortAsc
          sx={{
            width: 16,
            height: 13,
          }}
        />
      )}
    </IconButton>
  );
};

export type ManagerDocumentUploadDetailItem = {
  id: string;
  number: string;
  file_name: string;
  url: string;
};
const defaultDetailHeadCells: HeadCellProps<DetailArchiveFileType>[] = [
  {
    id: 'int_id',
    label: 'ファイルID',
    style: { width: 100 },
  },
  {
    id: 'filename',
    label: 'ファイル名',
    style: {
      justifyContent: 'center',
      width: 350,
    },
  },
];

const headerCellStyle = {
  height: 40,
  p: 0,
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  border: 'none',
};
