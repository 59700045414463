import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { convertYenToMan } from 'utils';

export const spStepFiveSelector = (state: RootState) => ({
  ...state.sp_step_five.form.p_application_header,
  p_applicant_people_attributes:
    state.sp_step_five.form.p_application_header.p_applicant_people_attributes,
  master_bank_codes: state.sp_step_one.form.master_bank_codes,
  is_home_loan_plus:
    state.sp_step_one.form.p_application_header.is_home_loan_plus,
  loan_type: state.sp_step_one.form.p_application_header.loan_type,
});

export const convertStepFiveSelector = createSelector(
  spStepFiveSelector,
  (input) => {
    const p_borrowings_attributes = input.p_borrowings_attributes.map(
      (borrowing) => {
        const [loan_amount, current_balance_amount, annual_repayment_amount] =
          convertYenToMan([
            borrowing.loan_amount,
            borrowing.current_balance_amount,
            borrowing.annual_repayment_amount,
          ]);
        return {
          ...borrowing,
          loan_amount,
          current_balance_amount,
          annual_repayment_amount,
        };
      }
    );

    const [refund_amount] = convertYenToMan([input.refund_amount]);

    return {
      p_borrowings_attributes,
      refund_amount,
    };
  }
);
