import { regex, yup } from 'libs';
import {
  BuyingandSellingScheduleType,
  CurrentResidenceType,
  MESSAGE_REGEX,
  PersonOccupancyType,
  PlannedCohabitantType,
  PlanningAreaType,
  RebuildingReasonType,
} from 'constant';
import { messageOverMaxYen } from 'utils';

export const validationSchema = (isIncome: boolean) =>
  yup.object().shape({
    p_residents_for_submit: yup.array(),
    p_application_header: yup.object().shape({
      non_resident_reason: yup
        .string()
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        // )
        .max(40)
        .when('person_occupancy', {
          is: (person_occupancy: PersonOccupancyType) =>
            person_occupancy === PersonOccupancyType.NO,
          then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        }),
      business_ability: yup.array().of(yup.string()),
      children_number: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[]) =>
          planned_cohabitant.includes(PlannedCohabitantType.CHILD),
        then: yup.string().required(MESSAGE_REGEX.DROPDOWN_REQUIRED),
        otherwise: yup.string(),
      }),
      siblings_number: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[]) =>
          planned_cohabitant.includes(
            PlannedCohabitantType.BROTHERS_AND_SISTERS
          ),
        then: yup.string().required(MESSAGE_REGEX.DROPDOWN_REQUIRED),
        otherwise: yup.string(),
      }),
      other_people_number: yup.string().when('planned_cohabitant', {
        is: (planned_cohabitant: PlannedCohabitantType[]) =>
          planned_cohabitant.includes(PlannedCohabitantType.OTHERS),
        then: yup.string().required(MESSAGE_REGEX.DROPDOWN_REQUIRED),
        otherwise: yup.string(),
      }),
      other_relationship: yup
        .string()
        .max(40)
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        // )
        .when('planned_cohabitant', {
          is: (planned_cohabitant: PlannedCohabitantType[]) =>
            planned_cohabitant.includes(PlannedCohabitantType.OTHERS),
          then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        }),
      housemate_number: yup
        .number()
        .typeError(MESSAGE_REGEX.TYPE_NUMBER)
        .min(0)
        .max(99),
      collateral_city: yup
        .string()
        .max(20)
        .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
      collateral_lot_number: yup
        .string()
        .max(20)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
      condominium_name: yup
        .string()
        .max(40)
        .matches(
          regex.kanjiFullwidthHaveNumber,
          MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        ),
      other_planning_area: yup
        .string()
        .max(40)
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        // )
        .when('planning_area', {
          is: (planning_area: PlanningAreaType) =>
            planning_area === PlanningAreaType.OTHERS,
          then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        }),
      other_rebuilding_reason: yup
        .string()
        .max(40)
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        // )
        .when('rebuilding_reason', {
          is: (rebuilding_reason: RebuildingReasonType) =>
            rebuilding_reason === RebuildingReasonType.OTHERS,
          then: yup.string().required(MESSAGE_REGEX.REQUIRED),
        }),
      property_information_url: yup
        .string()
        .max(255)
        .matches(regex.url, MESSAGE_REGEX.URL_INVALID),
      p_applicant_people_attributes: yup.object().shape({
        current_residence: yup.string(),
        buyingand_selling_schedule_type: yup
          .string()
          .when('current_residence', {
            is: (current_residence: CurrentResidenceType) =>
              current_residence === CurrentResidenceType.PRIVATE_APARTMENT,
            then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
          }),
        current_home_loan: yup.string().when('current_residence', {
          is: (current_residence: CurrentResidenceType) =>
            current_residence === CurrentResidenceType.PRIVATE_APARTMENT,
          then: yup.string().required(MESSAGE_REGEX.RADIO_REQUIRED),
        }),
        other_buyingand_selling_schedule_type: yup
          .string()
          .max(40, MESSAGE_REGEX.OTHER_SELLING_SCHEDULE_TYPE_LIMIT)
          // .matches(
          //   regex.kanjiFullwidthHaveNumber,
          //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
          // )
          .when('buyingand_selling_schedule_type', {
            is: (
              buyingand_selling_schedule_type: BuyingandSellingScheduleType
            ) =>
              buyingand_selling_schedule_type ===
              BuyingandSellingScheduleType.OTHERS,
            then: yup.string().required(MESSAGE_REGEX.REQUIRED),
          }),
        expected_house_selling_price: yup
          .number()
          .typeError(MESSAGE_REGEX.TYPE_NUMBER)
          .min(0)
          .max(99999999, messageOverMaxYen(99999999))
          .test(
            'divisible-by-10',
            MESSAGE_REGEX.ENTER_UNITS_100000YEN,
            (borrow) => +(borrow || 0) % 10 === 0
          ),
        other_reason_acquire_home: yup.string().max(40),
        // .matches(
        //   regex.kanjiFullwidthHaveNumber,
        //   MESSAGE_REGEX.SP_KANJI_FULL_WIDTH_HAVE_NUMBER
        // ),
        owner_full_name: yup
          .string()
          .max(96)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH)
          .when('current_residence', {
            is: (current_residence: CurrentResidenceType) =>
              current_residence ===
              CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS,
            then: yup.string().required(MESSAGE_REGEX.REQUIRED),
          }),
        owner_relationship: yup
          .string()
          .max(40)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH)
          .when('current_residence', {
            is: (current_residence: CurrentResidenceType) =>
              current_residence ===
              CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS,
            then: yup.string().required(MESSAGE_REGEX.REQUIRED),
          }),
      }),
      p_residents_attributes: yup.object().shape({
        last_name_kanji: yup
          .string()
          .trim()
          .max(48)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        first_name_kanji: yup
          .string()
          .trim()
          .max(48)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        last_name_kana: yup
          .string()
          .when(['person_occupancy', 'housemate_number'], {
            is: (
              person_occupancy: PersonOccupancyType,
              housemate_number: string
            ) =>
              person_occupancy === PersonOccupancyType.NO &&
              housemate_number.length !== 0,
            then: yup
              .string()
              .trim()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              )
              .requiredMCJ(MESSAGE_REGEX.REQUIRED),
            otherwise: yup
              .string()
              .trim()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
          }),
        first_name_kana: yup
          .string()
          .trim()
          .max(48)
          .matches(
            regex.kanaHalfwidthHaveSpace,
            MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
          )
          .when(['person_occupancy', 'housemate_number'], {
            is: (
              person_occupancy: PersonOccupancyType,
              housemate_number: string
            ) =>
              person_occupancy === PersonOccupancyType.NO &&
              housemate_number.length !== 0,
            then: yup
              .string()
              .trim()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              )
              .requiredMCJ(MESSAGE_REGEX.REQUIRED),
            otherwise: yup
              .string()
              .trim()
              .max(48)
              .matches(
                regex.kanaHalfwidthHaveSpace,
                MESSAGE_REGEX.KANA_HALF_WIDTH_HAVE_SPACE
              ),
          }),
        relationship_name: yup
          .string()
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH)
          .max(48),
        birthday: yup
          .string()
          .test(
            'one-field-missing',
            MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
            (value) => {
              const [year, month, day] = value
                ? value.split('/')
                : ['', '', ''];
              return (!!year && !!month && !!day) || (!year && !month && !day);
            }
          )
          .label('生年月日'),
        home_phone_number: yup
          .string()
          // .noZeroPhoneNumber()
          // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_SP),
          .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER),
        postal_code: yup
          .string()
          .max(8, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
          .matches(regex.zipCode, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
          .noWhitespace(),
        prefecture_kanji: yup
          .string()
          .max(8)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        city_kanji: yup
          .string()
          .max(20)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        district_kanji: yup
          .string()
          .max(40)
          .matches(regex.kanjiFullwidthHaveNumber, MESSAGE_REGEX.ADDRESS_KANJI),
        other_address_kanji: yup
          .string()
          .max(99)
          .matches(regex.kanjiFullwidthHaveNumber, MESSAGE_REGEX.ADDRESS_KANJI),
      }),
      collateral_total_floor_area: yup
        .string()
        .test(
          'is-number-collateral_total_floor_area',
          MESSAGE_REGEX.TYPE_NUMBER,
          function (value) {
            return (
              !value ||
              (!!value &&
                /^-?\d+$/.test(value?.replaceAll(',', '').replaceAll('.', '')))
            );
          }
        ),
      collateral_land_area: yup
        .string()
        .test(
          'is-number-collateral_land_area',
          MESSAGE_REGEX.TYPE_NUMBER,
          function (value) {
            return (
              !value ||
              (!!value &&
                /^-?\d+$/.test(value?.replaceAll(',', '').replaceAll('.', '')))
            );
          }
        ),
      occupied_area: yup
        .string()
        .test(
          'is-number-occupied_area',
          MESSAGE_REGEX.TYPE_NUMBER,
          function (value) {
            return (
              !value ||
              (!!value &&
                /^-?\d+$/.test(value?.replaceAll(',', '').replaceAll('.', '')))
            );
          }
        ),
    }),
  });
