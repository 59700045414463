import { FC, useMemo } from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  Stack,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { Icons } from 'components';
import { Popover } from '../../Popover';

export type CheckboxButtonProps = ButtonProps & {
  name: string;
  note?: string;
  checked?: boolean;
  readOnly?: boolean;
  startIconStyle?: {
    top: number;
    left: number;
  };
  radioButton?: boolean;
};

export const CheckboxButton: FC<CheckboxButtonProps> = ({
  name,
  children,
  note,
  checked,
  readOnly,
  sx = {},
  startIconStyle = { top: 17, left: 8 },
  disabled,
  radioButton = false,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { color, bgcolor } = useMemo(
    () =>
      meta.touched && !!meta.error
        ? { color: 'normal_text', bgcolor: 'bg_error' }
        : field.value
        ? { color: 'main_white', bgcolor: 'h_blugreen' }
        : { color: 'normal_text', bgcolor: 'main_white' },
    [field.value, meta.error, meta.touched]
  );

  const renderIcon = useMemo(() => {
    if (disabled) return <Icons.RadioDisabled />;
    if (field.value) return <Icons.Checked sx={{ width: 22, height: 22 }} />;
    return <Icons.UnChecked sx={{ width: 22, height: 22 }} />;
  }, [disabled, field.value]);

  return (
    <Stack
      sx={
        readOnly
          ? { border: '1px solid', borderRadius: 1, borderColor: 'line' }
          : {}
      }
    >
      <input name={name} type="hidden" />
      <Popover
        content={meta.error ?? ''}
        contentProps={{ color: 'main_white' }}
        open={meta.touched && !!meta.error}
        placement="top-end"
        arrowSx={{
          borderColor: 'sh_red',
          bgcolor: 'sh_red',
        }}
        sx={{
          top: -5,
          right: 0,
          maxWidth: 250,
          borderColor: 'sh_red',
          bgcolor: 'sh_red',
        }}
      >
        <MuiButton
          onClick={() => {
            if (radioButton && !!field.value) {
              return;
            }
            setFieldValue(name, !field.value);
          }}
          disabled={disabled || readOnly}
          {...props}
          sx={{
            borderRadius: 1,
            minHeight: 60,
            boxShadow: 3,
            width: '100%',
            bgcolor,
            color,
            ...sx,
            '&:hover': {
              // @ts-ignore
              color: sx?.color ?? color,
              // @ts-ignore
              bgcolor: sx?.bgcolor ?? bgcolor,
              opacity: { mobile: 1, desktop: 0.8 },
            },
            ':disabled': {
              color: readOnly ? 'normal_text' : 'main_white',
              ...(readOnly && { bgcolor: 'bg_pale_gray' }),
            },
          }}
        >
          <Stack direction="row">
            <Stack
              sx={{
                position: 'absolute',
                boxShadow: '2px 2px 4px rgba(51, 51, 51, 0.1)',
                borderRadius: '50%',
                ...startIconStyle,
              }}
            >
              {renderIcon}
            </Stack>
            <Stack justifyContent="center" flex={1}>
              <Typography
                variant="checkbox_button_title"
                fontWeight={field.value ? 700 : 400}
                sx={{ ml: { mobile: 5, tablet: 0 } }}
              >
                {children}
              </Typography>
              {!!note && (
                <Typography
                  variant="text_note"
                  color={checked ? 'main_white' : 'normal_text'}
                  sx={{
                    alignItems: 'left',
                    px: 3,
                  }}
                >
                  {note}
                </Typography>
              )}
            </Stack>
          </Stack>
        </MuiButton>
      </Popover>
    </Stack>
  );
};
