import { yup } from 'libs';
import { kingNames, KingName } from 'constant';

export const validationSchema = yup.object({
  kingName: yup.string().required('このフィールドに値を入力していません'),
  yearReign: yup
    .number()
    .required('このフィールドに値を入力していません')
    .min(0)
    .when(['kingName'], (kingName: KingName, schema) => {
      return schema.max(kingNames[kingName]?.to - kingNames[kingName]?.from);
    }),
  month: yup.number().required('このフィールドに値を入力していません'),
  day: yup.number().required('このフィールドに値を入力していません'),
});
