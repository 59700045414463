import { FC, PropsWithChildren } from 'react';
import { Stack, Typography, Modal as MuiModal } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  confirmText: string;
  onClose: () => void;
  onConfirm: () => void;
}>;

export const ModalConfirm: FC<ModalProps> = ({
  open,
  confirmText,
  onClose,
  onConfirm,
}) => (
  <MuiModal
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    disableAutoFocus
    open={open}
    onClose={onClose}
  >
    <Stack
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '25%',
        minWidth: 320,
        minHeight: 140,
        backgroundColor: 'main_white',
        '@media (orientation: landscape)': {
          minWidth: 'auto',
          maxHeight: '75vh',
        },
        borderRadius: 2,
        paddingBottom: 8,
      }}
    >
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
        <CloseIcon
          onClick={onClose}
          fontSize="medium"
          sx={{
            color: 'b_333',
            cursor: 'pointer',
            pb: 2,
            pt: 2,
            pr: 2,
            width: 20,
            height: 20,
          }}
        />
      </Stack>

      <Stack alignItems="center">
        <Typography
          variant="text_note"
          color="b_333"
          sx={{ textAlign: 'center', px: 3 }}
        >
          {confirmText}
        </Typography>
      </Stack>
      <Stack
        justifyContent="center"
        direction="row"
        spacing={3}
        sx={{
          pt: 10,
        }}
      >
        <Button
          sx={{
            bgcolor: 'white',
            boxShadow: 'none',
            width: '100px',
            height: '36px',
            borderRadius: '2px',
            minHeight: '36px',
            border: '1px solid #6B70F0',
          }}
          onClick={() => onConfirm()}
        >
          <Typography variant="button01" color="sp_primary_100_main">
            OK
          </Typography>
        </Button>
        <Button
          sx={{
            bgcolor: 'white',
            boxShadow: 'none',
            width: '100px',
            height: '36px',
            borderRadius: '2px',
            minHeight: '36px',
            border: '1px solid #6B70F0',
          }}
          onClick={() => onClose()}
        >
          <Typography variant="button01" color="sp_primary_100_main">
            キャンセル
          </Typography>
        </Button>
      </Stack>
    </Stack>
  </MuiModal>
);
