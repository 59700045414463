import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Refinancing: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 170 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="170"
      height="61"
      viewBox="0 0 170 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M168.243 26.1396C168.243 26.1396 168.24 26.1396 168.237 26.1396H113.773C113.321 26.1396 112.912 25.8591 112.754 25.4343C112.593 25.0095 112.713 24.5302 113.054 24.2334L140.286 0.271643C140.697 -0.0905475 141.313 -0.0905475 141.727 0.271643L168.894 24.1789C169.16 24.3777 169.332 24.6936 169.332 25.0531C169.332 25.6549 168.845 26.1424 168.243 26.1424V26.1396ZM116.659 23.9611H165.351L141.005 2.5401L116.659 23.9611Z"
        fill="currentColor"
      />
      <path
        d="M156.694 21.782C156.694 21.782 156.691 21.782 156.688 21.782H125.319C124.867 21.782 124.459 21.5015 124.301 21.0767C124.14 20.6518 124.26 20.1726 124.601 19.8757L140.284 6.07438C140.695 5.71219 141.31 5.71219 141.724 6.07438L157.345 19.8185C157.612 20.0173 157.783 20.3332 157.783 20.6927C157.783 21.2945 157.296 21.782 156.694 21.782ZM128.206 19.6034H153.802L141.005 8.34283L128.209 19.6034H128.206Z"
        fill="currentColor"
      />
      <path
        d="M161.701 32.6763H150.808C150.206 32.6763 149.719 32.1888 149.719 31.587V25.0512C149.719 24.4494 150.206 23.9619 150.808 23.9619C151.41 23.9619 151.897 24.4494 151.897 25.0512V30.4977H160.612V25.0512C160.612 24.4494 161.099 23.9619 161.701 23.9619C162.303 23.9619 162.79 24.4494 162.79 25.0512V31.587C162.79 32.1888 162.303 32.6763 161.701 32.6763Z"
        fill="currentColor"
      />
      <path
        d="M161.701 56.6401C161.099 56.6401 160.612 56.1527 160.612 55.5508V50.1044H151.897V55.5508C151.897 56.1527 151.41 56.6401 150.808 56.6401C150.206 56.6401 149.719 56.1527 149.719 55.5508V49.0151C149.719 48.4132 150.206 47.9258 150.808 47.9258H161.701C162.303 47.9258 162.79 48.4132 162.79 49.0151V55.5508C162.79 56.1527 162.303 56.6401 161.701 56.6401Z"
        fill="currentColor"
      />
      <path
        d="M166.06 60.9994H115.953C115.351 60.9994 114.863 60.512 114.863 59.9101V55.5502C114.863 54.9484 115.351 54.4609 115.953 54.4609H166.06C166.662 54.4609 167.149 54.9484 167.149 55.5502V59.9101C167.149 60.512 166.662 60.9994 166.06 60.9994ZM117.042 58.8208H164.971V56.6395H117.042V58.8208Z"
        fill="currentColor"
      />
      <path
        d="M159.523 50.1034C158.921 50.1034 158.434 49.6159 158.434 49.0141V31.5854C158.434 30.9836 158.921 30.4961 159.523 30.4961C160.125 30.4961 160.612 30.9836 160.612 31.5854V49.0141C160.612 49.6159 160.125 50.1034 159.523 50.1034Z"
        fill="currentColor"
      />
      <path
        d="M152.988 50.1034C152.386 50.1034 151.898 49.6159 151.898 49.0141V31.5854C151.898 30.9836 152.386 30.4961 152.988 30.4961C153.59 30.4961 154.077 30.9836 154.077 31.5854V49.0141C154.077 49.6159 153.59 50.1034 152.988 50.1034Z"
        fill="currentColor"
      />
      <path
        d="M131.205 32.6763H120.312C119.71 32.6763 119.223 32.1888 119.223 31.587V25.0512C119.223 24.4494 119.71 23.9619 120.312 23.9619C120.914 23.9619 121.401 24.4494 121.401 25.0512V30.4977H130.116V25.0512C130.116 24.4494 130.603 23.9619 131.205 23.9619C131.807 23.9619 132.294 24.4494 132.294 25.0512V31.587C132.294 32.1888 131.807 32.6763 131.205 32.6763Z"
        fill="currentColor"
      />
      <path
        d="M131.205 56.6401C130.603 56.6401 130.116 56.1527 130.116 55.5508V50.1044H121.401V55.5508C121.401 56.1527 120.914 56.6401 120.312 56.6401C119.71 56.6401 119.223 56.1527 119.223 55.5508V49.0151C119.223 48.4132 119.71 47.9258 120.312 47.9258H131.205C131.807 47.9258 132.294 48.4132 132.294 49.0151V55.5508C132.294 56.1527 131.807 56.6401 131.205 56.6401Z"
        fill="currentColor"
      />
      <path
        d="M129.023 50.1034C128.421 50.1034 127.934 49.6159 127.934 49.0141V31.5854C127.934 30.9836 128.421 30.4961 129.023 30.4961C129.625 30.4961 130.112 30.9836 130.112 31.5854V49.0141C130.112 49.6159 129.625 50.1034 129.023 50.1034Z"
        fill="currentColor"
      />
      <path
        d="M122.484 50.1034C121.882 50.1034 121.395 49.6159 121.395 49.0141V31.5854C121.395 30.9836 121.882 30.4961 122.484 30.4961C123.086 30.4961 123.573 30.9836 123.573 31.5854V49.0141C123.573 49.6159 123.086 50.1034 122.484 50.1034Z"
        fill="currentColor"
      />
      <path
        d="M141.006 47.9249C138.604 47.9249 136.648 45.9696 136.648 43.5677C136.648 42.9659 137.136 42.4784 137.738 42.4784C138.34 42.4784 138.827 42.9659 138.827 43.5677C138.827 44.7687 139.805 45.7463 141.006 45.7463C142.207 45.7463 143.184 44.7687 143.184 43.5677C143.184 42.3668 142.207 41.3892 141.006 41.3892C138.604 41.3892 136.648 39.4339 136.648 37.032C136.648 34.6301 138.604 32.6748 141.006 32.6748C143.408 32.6748 145.363 34.6301 145.363 37.032C145.363 37.6338 144.875 38.1213 144.273 38.1213C143.672 38.1213 143.184 37.6338 143.184 37.032C143.184 35.831 142.207 34.8534 141.006 34.8534C139.805 34.8534 138.827 35.831 138.827 37.032C138.827 38.2329 139.805 39.2106 141.006 39.2106C143.408 39.2106 145.363 41.1658 145.363 43.5677C145.363 45.9696 143.408 47.9249 141.006 47.9249Z"
        fill="currentColor"
      />
      <path
        d="M141.003 50.1042C140.402 50.1042 139.914 49.6168 139.914 49.015V46.8364C139.914 46.2345 140.402 45.7471 141.003 45.7471C141.605 45.7471 142.093 46.2345 142.093 46.8364V49.015C142.093 49.6168 141.605 50.1042 141.003 50.1042Z"
        fill="currentColor"
      />
      <path
        d="M141.003 34.8533C140.402 34.8533 139.914 34.3658 139.914 33.764V31.5854C139.914 30.9836 140.402 30.4961 141.003 30.4961C141.605 30.4961 142.093 30.9836 142.093 31.5854V33.764C142.093 34.3658 141.605 34.8533 141.003 34.8533Z"
        fill="currentColor"
      />
      <path
        d="M12.5764 43.182C12.3815 43.182 12.1865 43.1149 12.0314 42.9808L0.292688 32.919C0.0956451 32.7492 -0.0133602 32.4977 0.00131319 32.2378C0.0159866 31.9778 0.148043 31.7389 0.363951 31.5921L27.1953 13.1477C27.5076 12.9318 27.9269 12.9548 28.2161 13.2022L39.9548 23.2639C40.1519 23.4337 40.2609 23.6853 40.2462 23.9452C40.2315 24.2051 40.0995 24.4441 39.8836 24.5908L13.0522 43.0353C12.9097 43.1338 12.742 43.182 12.5785 43.182H12.5764ZM2.21071 32.3551L12.6309 41.287L38.0326 23.8257L27.6124 14.8938L2.21071 32.3551Z"
        fill="currentColor"
      />
      <path
        d="M26.7428 29.3645C26.4766 29.3645 26.2125 29.2366 26.051 28.9997C25.789 28.6182 25.8854 28.0963 26.2669 27.8342L30.9205 24.6417C30.7465 24.2686 30.6627 23.8556 30.6857 23.4385C30.7088 23.0214 30.8367 22.6189 31.0505 22.2688L28.2961 19.9001C27.6756 20.1139 26.9943 20.0762 26.4032 19.7974L20.7581 23.6691C20.3766 23.9311 19.8547 23.8347 19.5926 23.4511C19.3306 23.0696 19.4271 22.5476 19.8107 22.2856L25.9106 18.1016C26.225 17.8857 26.6443 17.9087 26.9314 18.1582C27.2207 18.4055 27.64 18.4286 27.9523 18.2148C28.2667 17.9989 28.686 18.0219 28.9731 18.2714L32.7987 21.5603C32.9957 21.7301 33.1026 21.9817 33.0901 22.2416C33.0754 22.5015 32.9412 22.7405 32.7274 22.8872C32.5136 23.0339 32.3795 23.275 32.3648 23.5328C32.3501 23.7907 32.4591 24.0464 32.6561 24.2141C32.8532 24.3839 32.9601 24.6354 32.9475 24.8954C32.9328 25.1553 32.7987 25.3943 32.5849 25.541L27.2228 29.2198C27.0782 29.3183 26.9126 29.3666 26.7491 29.3666L26.7428 29.3645Z"
        fill="currentColor"
      />
      <path
        d="M13.8612 38.2267C13.6662 38.2267 13.4713 38.1576 13.3141 38.0234C13.0269 37.7761 12.6056 37.753 12.2932 37.9689C11.9788 38.1827 11.5596 38.1618 11.2724 37.9123L7.44472 34.6213C7.24767 34.4515 7.14076 34.1999 7.15334 33.94C7.16801 33.6801 7.30007 33.4411 7.51598 33.2944C7.7277 33.1476 7.86394 32.9066 7.87862 32.6488C7.89329 32.3909 7.78429 32.1352 7.58724 31.9675C7.3902 31.7977 7.28332 31.5462 7.29589 31.2862C7.31057 31.0263 7.44262 30.7873 7.65853 30.6406L13.1275 26.8779C13.509 26.6159 14.031 26.7123 14.293 27.0938C14.555 27.4753 14.4586 27.9973 14.0771 28.2593L9.31661 31.5357C9.49059 31.9067 9.57444 32.3217 9.55348 32.7389C9.53042 33.156 9.40255 33.5585 9.19084 33.9086L11.9494 36.2794C12.5678 36.0656 13.2512 36.1033 13.8423 36.3821L19.5859 32.4433C19.9674 32.1813 20.4893 32.2777 20.7514 32.6613C21.0134 33.0428 20.917 33.5648 20.5334 33.8268L14.3349 38.0779C14.1924 38.1764 14.0268 38.2246 13.8612 38.2246V38.2267Z"
        fill="currentColor"
      />
      <path
        d="M29.3524 41.7231C28.8891 41.7231 28.5139 41.3479 28.5139 40.8847V31.1583L17.119 21.3481C16.7689 21.0462 16.7291 20.5159 17.0309 20.1658C17.3328 19.8158 17.861 19.7759 18.2132 20.0778L29.8995 30.1395C30.0839 30.2989 30.1909 30.5315 30.1909 30.7747V40.8867C30.1909 41.35 29.8156 41.7252 29.3524 41.7252V41.7231Z"
        fill="currentColor"
      />
      <path
        d="M22.6364 46.3348C22.1731 46.3348 21.7979 45.9596 21.7979 45.4963V35.7511L10.4197 25.9409C10.0697 25.639 10.0299 25.1087 10.3317 24.7586C10.6336 24.4085 11.1639 24.3687 11.5139 24.6706L23.1835 34.7323C23.3679 34.8916 23.4749 35.1243 23.4749 35.3675V45.4963C23.4749 45.9596 23.0996 46.3348 22.6364 46.3348Z"
        fill="currentColor"
      />
      <path
        d="M12.5765 48.2048C12.3815 48.2048 12.1866 48.1377 12.0315 48.0035L0.292747 37.9418C-0.0594137 37.6399 -0.0992528 37.1117 0.2026 36.7595C0.504452 36.4074 1.0327 36.3675 1.38486 36.6694L12.6352 46.3119L22.1666 39.7676C22.5481 39.5056 23.07 39.602 23.3321 39.9835C23.5941 40.365 23.4977 40.887 23.1161 41.149L13.0544 48.058C12.9098 48.1566 12.7442 48.2048 12.5807 48.2048H12.5765Z"
        fill="currentColor"
      />
      <path
        d="M29.347 36.6844C29.0808 36.6844 28.8167 36.5565 28.6553 36.3197C28.3932 35.9382 28.4897 35.4162 28.8712 35.1542L38.9329 28.2451C39.3144 27.9831 39.8364 28.0795 40.0984 28.461C40.3604 28.8425 40.264 29.3645 39.8825 29.6265L29.8208 36.5356C29.6761 36.6362 29.5105 36.6823 29.347 36.6823V36.6844Z"
        fill="currentColor"
      />
      <path
        d="M12.5765 53.244C12.3815 53.244 12.1866 53.177 12.0315 53.0428L0.292747 42.9811C-0.0594137 42.6792 -0.0992528 42.151 0.2026 41.7988C0.504452 41.4466 1.0327 41.4068 1.38486 41.7087L12.6352 51.3512L38.9361 33.2715C39.3177 33.0095 39.8396 33.1059 40.1016 33.4874C40.3637 33.8689 40.2672 34.3908 39.8857 34.6529L13.0544 53.0973C12.9119 53.1958 12.7442 53.244 12.5807 53.244H12.5765Z"
        fill="currentColor"
      />
      <path
        d="M13.4152 34.1537C14.3414 34.1537 15.0922 33.4029 15.0922 32.4768C15.0922 31.5506 14.3414 30.7998 13.4152 30.7998C12.4891 30.7998 11.7383 31.5506 11.7383 32.4768C11.7383 33.4029 12.4891 34.1537 13.4152 34.1537Z"
        fill="currentColor"
      />
      <path
        d="M26.8293 25.3344C27.7555 25.3344 28.5063 24.5836 28.5063 23.6574C28.5063 22.7313 27.7555 21.9805 26.8293 21.9805C25.9031 21.9805 25.1523 22.7313 25.1523 23.6574C25.1523 24.5836 25.9031 25.3344 26.8293 25.3344Z"
        fill="currentColor"
      />
      <path
        d="M37.5141 48.7184C35.3563 48.7184 33.1511 48.383 31.504 47.7539C30.6795 47.439 30.0238 47.0613 29.5818 46.6402C29.146 46.2251 28.9375 45.7893 28.9375 45.3284C28.9375 44.8676 29.146 44.4318 29.5818 44.0166C30.0238 43.5956 30.6795 43.2179 31.504 42.903C33.1511 42.2738 35.3563 41.9385 37.5141 41.9385C39.6718 41.9385 41.8771 42.2738 43.5242 42.903C44.3486 43.2179 45.0044 43.5956 45.4464 44.0166C45.8822 44.4318 46.0907 44.8676 46.0907 45.3284C46.0907 45.7893 45.8822 46.2251 45.4464 46.6402C45.0044 47.0613 44.3486 47.439 43.5242 47.7539C41.8771 48.383 39.6718 48.7184 37.5141 48.7184ZM37.5141 42.2351C35.0558 42.2351 33.0483 42.6198 31.638 43.1587C30.9351 43.4273 30.3606 43.7418 29.9519 44.0823C29.561 44.4079 29.2342 44.8329 29.2342 45.3284C29.2342 45.824 29.561 46.2489 29.9519 46.5746C30.3606 46.915 30.9351 47.2296 31.638 47.4982C33.0483 48.0371 35.0558 48.4217 37.5141 48.4217C39.9724 48.4217 41.9798 48.0371 43.3901 47.4982C44.0931 47.2296 44.6676 46.915 45.0763 46.5746C45.4672 46.2489 45.794 45.824 45.794 45.3284C45.794 44.8329 45.4672 44.4079 45.0763 44.0823C44.6676 43.7418 44.0931 43.4273 43.3901 43.1587C41.9798 42.6198 39.9724 42.2351 37.5141 42.2351Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M37.5141 52.6086C35.3563 52.6086 33.1511 52.2732 31.504 51.6441C30.6795 51.3292 30.0238 50.9515 29.5818 50.5304C29.146 50.1153 28.9375 49.6795 28.9375 49.2186C28.9375 49.1366 29.0038 49.0703 29.0858 49.0703C29.1679 49.0703 29.2342 49.1366 29.2342 49.2186C29.2342 49.7142 29.561 50.1391 29.9519 50.4648C30.3606 50.8052 30.9351 51.1198 31.638 51.3884C33.0483 51.9273 35.0558 52.3119 37.5141 52.3119C39.9724 52.3119 41.9798 51.9273 43.3901 51.3884C44.0931 51.1198 44.6676 50.8052 45.0763 50.4648C45.4672 50.1391 45.794 49.7142 45.794 49.2186C45.794 49.1366 45.8603 49.0703 45.9423 49.0703C46.0244 49.0703 46.0907 49.1366 46.0907 49.2186C46.0907 49.6795 45.8822 50.1153 45.4464 50.5304C45.0044 50.9515 44.3486 51.3292 43.5242 51.6441C41.8771 52.2732 39.6718 52.6086 37.5141 52.6086Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M37.5141 56.4989C35.3563 56.4989 33.1511 56.1635 31.504 55.5344C30.6795 55.2195 30.0238 54.8418 29.5818 54.4207C29.146 54.0055 28.9375 53.5697 28.9375 53.1089V45.329C28.9375 45.2469 29.0038 45.1807 29.0858 45.1807C29.1679 45.1807 29.2342 45.2469 29.2342 45.329V53.1089C29.2342 53.6045 29.561 54.0294 29.9519 54.3551C30.3606 54.6955 30.9351 55.01 31.638 55.2786C33.0483 55.8176 35.0558 56.2022 37.5141 56.2022C39.9724 56.2022 41.9798 55.8176 43.3901 55.2786C44.0931 55.01 44.6676 54.6955 45.0763 54.3551C45.4672 54.0294 45.794 53.6045 45.794 53.1089V45.329C45.794 45.2469 45.8603 45.1807 45.9423 45.1807C46.0244 45.1807 46.0907 45.2469 46.0907 45.329V53.1089C46.0907 53.5697 45.8822 54.0055 45.4464 54.4207C45.0044 54.8418 44.3486 55.2195 43.5242 55.5344C41.8771 56.1635 39.6718 56.4989 37.5141 56.4989Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M50.4789 33.1588C48.3212 33.1588 46.1159 32.8235 44.4689 32.1943C43.6444 31.8794 42.9886 31.5018 42.5466 31.0807C42.1108 30.6655 41.9023 30.2297 41.9023 29.7689C41.9023 29.3081 42.1108 28.8722 42.5466 28.4571C42.9886 28.036 43.6444 27.6583 44.4689 27.3434C46.1159 26.7143 48.3212 26.3789 50.4789 26.3789C52.6367 26.3789 54.8419 26.7143 56.489 27.3434C57.3135 27.6583 57.9692 28.036 58.4112 28.4571C58.847 28.8722 59.0555 29.3081 59.0555 29.7689C59.0555 30.2297 58.847 30.6655 58.4112 31.0807C57.9692 31.5018 57.3135 31.8794 56.489 32.1943C54.8419 32.8235 52.6367 33.1588 50.4789 33.1588ZM50.4789 26.6756C48.0206 26.6756 46.0132 27.0602 44.6029 27.5991C43.8999 27.8678 43.3254 28.1823 42.9167 28.5227C42.5258 28.8484 42.199 29.2733 42.199 29.7689C42.199 30.2644 42.5258 30.6894 42.9167 31.015C43.3254 31.3555 43.8999 31.67 44.6029 31.9386C46.0132 32.4775 48.0206 32.8622 50.4789 32.8622C52.9372 32.8622 54.9447 32.4775 56.355 31.9386C57.0579 31.67 57.6324 31.3555 58.0411 31.015C58.432 30.6894 58.7588 30.2644 58.7588 29.7689C58.7588 29.2733 58.432 28.8484 58.0411 28.5227C57.6324 28.1823 57.0579 27.8678 56.355 27.5991C54.9447 27.0602 52.9372 26.6756 50.4789 26.6756Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M50.4789 37.049C48.3212 37.049 46.1159 36.7137 44.4689 36.0845C43.6444 35.7696 42.9886 35.392 42.5466 34.9709C42.1108 34.5557 41.9023 34.1199 41.9023 33.6591C41.9023 33.577 41.9686 33.5107 42.0507 33.5107C42.1327 33.5107 42.199 33.577 42.199 33.6591C42.199 34.1546 42.5258 34.5796 42.9167 34.9052C43.3254 35.2457 43.8999 35.5602 44.6029 35.8288C46.0132 36.3677 48.0206 36.7524 50.4789 36.7524C52.9372 36.7524 54.9447 36.3677 56.355 35.8288C57.0579 35.5602 57.6324 35.2457 58.0411 34.9052C58.432 34.5796 58.7588 34.1546 58.7588 33.6591C58.7588 33.577 58.8251 33.5107 58.9072 33.5107C58.9892 33.5107 59.0555 33.577 59.0555 33.6591C59.0555 34.1199 58.847 34.5557 58.4112 34.9709C57.9692 35.392 57.3135 35.7696 56.489 36.0845C54.8419 36.7137 52.6367 37.049 50.4789 37.049Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M50.4789 40.9377C48.3212 40.9377 46.1159 40.6023 44.4689 39.9732C43.6444 39.6583 42.9886 39.2806 42.5466 38.8595C42.1108 38.4444 41.9023 38.0086 41.9023 37.5477C41.9023 37.4657 41.9686 37.3994 42.0507 37.3994C42.1327 37.3994 42.199 37.4657 42.199 37.5477C42.199 38.0433 42.5258 38.4682 42.9167 38.7939C43.3254 39.1343 43.8999 39.4489 44.6029 39.7175C46.0132 40.2564 48.0206 40.641 50.4789 40.641C52.9372 40.641 54.9447 40.2564 56.355 39.7175C57.0579 39.4489 57.6324 39.1343 58.0411 38.7939C58.432 38.4682 58.7588 38.0433 58.7588 37.5477C58.7588 37.4657 58.8251 37.3994 58.9072 37.3994C58.9892 37.3994 59.0555 37.4657 59.0555 37.5477C59.0555 38.0086 58.847 38.4444 58.4112 38.8595C57.9692 39.2806 57.3135 39.6583 56.489 39.9732C54.8419 40.6023 52.6367 40.9377 50.4789 40.9377Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M48.4342 44.5859L48.4344 44.5836C48.4413 44.5036 48.5133 44.4428 48.5944 44.4502L48.5954 44.4503C49.2125 44.5052 49.8465 44.5317 50.4842 44.5317C52.9425 44.5317 54.95 44.147 56.3603 43.6081C57.0632 43.3395 57.6377 43.025 58.0464 42.6845C58.4373 42.3589 58.7641 41.9339 58.7641 41.4384C58.7641 41.3563 58.8304 41.29 58.9125 41.29C58.9945 41.29 59.0608 41.3563 59.0608 41.4384C59.0608 41.8992 58.8523 42.335 58.4165 42.7502C57.9745 43.1713 57.3187 43.5489 56.4943 43.8638C54.8472 44.493 52.642 44.8283 50.4842 44.8283H50.4826C49.8358 44.8283 49.1921 44.7998 48.5676 44.7459C48.4876 44.739 48.4269 44.6671 48.4342 44.5859Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M48.5263 48.6309L48.5253 48.6308C48.4446 48.6235 48.3838 48.5514 48.3913 48.469C48.3983 48.3916 48.471 48.3278 48.555 48.3351L48.5984 47.837L48.5531 48.3349C49.1754 48.3915 49.8259 48.4196 50.485 48.4196C52.9433 48.4196 54.9508 48.035 56.3611 47.4961C57.064 47.2274 57.6385 46.9129 58.0472 46.5725C58.4381 46.2468 58.7649 45.8219 58.7649 45.3263V29.7665C58.7649 29.6844 58.8312 29.6182 58.9133 29.6182C58.9953 29.6182 59.0616 29.6844 59.0616 29.7665V45.3263C59.0616 45.7872 58.8531 46.223 58.4173 46.6381C57.9753 47.0592 57.3196 47.4369 56.4951 47.7518C54.848 48.3809 52.6428 48.7163 50.485 48.7163H50.278L50.2773 48.717C49.6784 48.7117 49.0901 48.6833 48.5263 48.6309Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M42.0507 39.6433C41.9686 39.6433 41.9023 39.577 41.9023 39.495V29.7684C41.9023 29.6864 41.9686 29.6201 42.0507 29.6201C42.1327 29.6201 42.199 29.6864 42.199 29.7684V39.495C42.199 39.577 42.1327 39.6433 42.0507 39.6433Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M85.5685 46.9941C85.3757 46.9941 85.1829 46.9299 85.0286 46.8013L71.3171 35.6607C71.1178 35.4979 71 35.2536 71 34.9965C71 34.7395 71.1157 34.4952 71.3171 34.3324L85.0286 23.1918C85.2857 22.984 85.6392 22.9411 85.937 23.0825C86.2348 23.2239 86.4255 23.526 86.4255 23.8559V29.8547H99.28C99.7535 29.8547 100.137 30.2382 100.137 30.7117V39.2814C100.137 39.7549 99.7535 40.1384 99.28 40.1384H86.4255V46.1372C86.4255 46.4671 86.2348 46.7692 85.937 46.9106C85.8192 46.9663 85.6949 46.9941 85.5685 46.9941ZM73.2153 34.9965L84.7115 44.3375V39.2814C84.7115 38.8079 85.095 38.4244 85.5685 38.4244H98.4231V31.5687H85.5685C85.095 31.5687 84.7115 31.1852 84.7115 30.7117V25.6556L73.2153 34.9965Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
