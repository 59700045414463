import { FC, useState } from 'react';
import { Divider, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { ContactItem } from 'services';
import { useAppDispatch } from 'hooks';
import { getContactDetail } from 'pages/Dashboards/ContactInformations/thunk';
import { ContactDetailModal } from '../../atoms';

export type ContactInformationItemsProps = {
  isAdmin?: boolean;
  data: ContactItem[];
};

export const ContactInformationItems: FC<ContactInformationItemsProps> = ({
  isAdmin,
  data,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [contactDetail, setContactDetail] = useState<ContactItem>();

  const openContactDetail = async (id: string) => {
    const result = await dispatch(getContactDetail(id));
    if (getContactDetail.fulfilled.match(result)) {
      setOpenModal(true);
      setContactDetail(result.payload.data);
    }
  };

  return (
    <>
      <Stack sx={{ mb: '13px' }}>
        {data.map((item) => (
          <Stack
            key={item.id}
            sx={{
              minWidth: 1100,
            }}
            onClick={() => {
              openContactDetail(item.id);
            }}
          >
            <TableRow
              sx={{
                bgcolor: 'main_white',
                justifyContent: 'space-between',
                borderBottom: (theme) => `1px solid ${theme?.palette?.gray}`,
                alignItems: 'center',
                display: 'flex',
                height: 60,
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  width: 97,
                  padding: '10px 0',
                  borderBottom: 'none',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Typography variant="number_applicant_item" color="normal_text">
                  {item.created_at}
                </Typography>
                <Divider
                  sx={{
                    color: 'gray',
                    right: 0,
                    top: '25%',
                    position: 'absolute',
                    height: '50%',
                  }}
                  orientation="vertical"
                />
              </TableCell>
              <TableCell
                sx={{
                  width: 180,
                  padding: '10px 0',
                  textAlign: 'center',
                  borderBottom: 'none',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant="title_application_item"
                  color="normal_text"
                >
                  {item.sender}
                </Typography>
                <Divider
                  sx={{
                    color: 'gray',
                    right: 0,
                    top: '25%',
                    position: 'absolute',
                    height: '50%',
                  }}
                  orientation="vertical"
                />
              </TableCell>
              <TableCell
                sx={{
                  width: isAdmin ? 163 : 201,
                  flex: 1,
                  pl: '10px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '10px 0',
                  textAlign: 'center',
                  borderBottom: 'none',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant="title_application_item"
                  color="normal_text"
                >
                  {item.content}
                </Typography>
              </TableCell>
            </TableRow>
          </Stack>
        ))}
      </Stack>
      {contactDetail && (
        <ContactDetailModal
          open={openModal}
          onClose={() => setOpenModal((prevState) => !prevState)}
          contactItem={contactDetail}
        />
      )}
    </>
  );
};
