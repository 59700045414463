import { RootState } from 'types';
import { createSelector } from 'reselect';
import { convertYenToMan } from 'utils';

export const spStepThreeSelector = (state: RootState) => ({
  p_applicant_people: {
    ...state.sp_step_three.form.p_application_header
      .p_applicant_people_attributes[0],
    created_at: state.sp_step_one.form.p_application_header.created_at,
  },
});

export const convertSPStepThreeSelector = createSelector(
  spStepThreeSelector,
  (input) => {
    const [last_year_income, bonus_income, two_years_ago_income] =
      convertYenToMan([
        input.p_applicant_people.last_year_income,
        input.p_applicant_people.bonus_income,
        input.p_applicant_people.two_years_ago_income,
      ]);
    return {
      last_year_income,
      bonus_income,
      two_years_ago_income,
    };
  }
);
