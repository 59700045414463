import { MessageItem, MessageItemAdmin, UserItem } from 'services';
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  createMessageManager,
  createMessageSalePerson,
  deleteMessageManager,
  getListUserManager,
  getListUserSalePerson,
  getMessageDetailManager,
  getMessageDetailSalePerson,
  getMessagesManager,
  getMessagesSalePerson,
} from './thunk';

interface InitialState {
  messagesAdmin: EntityState<MessageItemAdmin>;
  messagesDetail: EntityState<MessageItem>;
  conversationId: string;
  conversationName: string;
  p_application_header: {
    preliminaryId: string;
    applicantName: string;
    editable: boolean;
  };
  userExists: boolean;
  totalUnreadMessages: number;
  users: EntityState<UserItem>;
  total: number;
}

export const messagesAdminAdapter = createEntityAdapter<MessageItemAdmin>({});
export const messagesDetailAdapter = createEntityAdapter<MessageItem>({});
export const usersAdapter = createEntityAdapter<UserItem>({});

export const initialState: InitialState = {
  messagesAdmin: messagesAdminAdapter.getInitialState({}),
  messagesDetail: messagesDetailAdapter.getInitialState(),
  conversationId: '',
  conversationName: '',
  p_application_header: {
    preliminaryId: '',
    applicantName: '',
    editable: false,
  },
  userExists: true,
  totalUnreadMessages: 0,
  users: usersAdapter.getInitialState(),
  total: 0,
};

const messagesAdminSlice = createSlice({
  name: 'messagesAdmin',
  initialState,
  reducers: {
    setConversationDetail: (
      state,
      action: PayloadAction<{
        conversationId: string;
        conversationName: string;
        applicationHeader?: InitialState['p_application_header'];
      }>
    ) => {
      state.conversationId = action.payload.conversationId;
      state.conversationName = action.payload.conversationName;
      state.p_application_header = {
        preliminaryId: action.payload.applicationHeader?.preliminaryId || '',
        applicantName: action.payload.applicationHeader?.applicantName || '',
        editable: !!action.payload.applicationHeader?.editable,
      };
    },
    revertMessage: (state) => {
      state.messagesAdmin = messagesAdminAdapter.getInitialState({});
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessagesManager.fulfilled, (state, action) => {
      messagesAdminAdapter.upsertMany(state.messagesAdmin, action.payload.data);
      state.total = action.payload.total;
    });
    builder.addCase(getMessagesSalePerson.fulfilled, (state, action) => {
      messagesAdminAdapter.upsertMany(state.messagesAdmin, action.payload.data);
      state.total = action.payload.total;
    });
    builder.addCase(getMessageDetailManager.fulfilled, (state, action) => {
      messagesDetailAdapter.setAll(state.messagesDetail, action.payload.data);
      state.totalUnreadMessages = +action.payload.total_unread_messages;
      state.userExists = action.payload.user_exists;
    });
    builder.addCase(getMessageDetailSalePerson.fulfilled, (state, action) => {
      messagesDetailAdapter.setAll(state.messagesDetail, action.payload.data);
      state.totalUnreadMessages = +action.payload.total_unread_messages;
      state.userExists = action.payload.user_exists;
    });
    builder.addCase(createMessageManager.fulfilled, (state, action) => {
      messagesDetailAdapter.addOne(state.messagesDetail, action.payload.data);
    });
    builder.addCase(createMessageSalePerson.fulfilled, (state, action) => {
      messagesDetailAdapter.addOne(state.messagesDetail, action.payload.data);
    });
    builder.addCase(getListUserManager.fulfilled, (state, action) => {
      usersAdapter.setAll(state.users, action.payload.data);
    });
    builder.addCase(getListUserSalePerson.fulfilled, (state, action) => {
      usersAdapter.setAll(state.users, action.payload.data);
    });
    builder.addCase(deleteMessageManager.fulfilled, (state, action) => {
      messagesDetailAdapter.removeOne(state.messagesDetail, action.payload.id);
    });
  },
});

export const { setConversationDetail, revertMessage } =
  messagesAdminSlice.actions;

export default messagesAdminSlice.reducer;
