import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUploadedFiles } from 'components/molecules/DashboardItems/thunk';
import { LoanType } from 'constant';
import { StepFourEditForm, StepFourForm } from 'types';

type InitialState = {
  form: StepFourForm;
  editForm: StepFourEditForm;
};

export const initialState: InitialState = {
  form: {
    main_personal_information_file: [],
    main_first_pamphlet_file: [],
    main_second_pamphlet_file: [],
    main_insurance_file: [],
    main_first_income_file: [],
    main_second_income_file: [],
    main_third_income_file: [],
    main_referral_agency_file: [],
    main_residence_file: [],
    main_first_borrowing_file: [],
    main_second_borrowing_file: [],
    pair_personal_information_file: [],
    pair_first_pamphlet_file: [],
    pair_second_pamphlet_file: [],
    pair_insurance_file: [],
    pair_first_income_file: [],
    pair_second_income_file: [],
    pair_third_income_file: [],
    pair_referral_agency_file: [],
    pair_residence_file: [],
    pair_first_borrowing_file: [],
    pair_second_borrowing_file: [],
  },
  editForm: {
    loan_type: LoanType.DEFAULT,
    main_personal_information_file: [],
    main_first_pamphlet_file: [],
    main_second_pamphlet_file: [],
    main_insurance_file: [],
    main_first_income_file: [],
    main_second_income_file: [],
    main_third_income_file: [],
    main_referral_agency_file: [],
    main_residence_file: [],
    main_first_borrowing_file: [],
    main_second_borrowing_file: [],
    pair_personal_information_file: [],
    pair_first_pamphlet_file: [],
    pair_second_pamphlet_file: [],
    pair_insurance_file: [],
    pair_first_income_file: [],
    pair_second_income_file: [],
    pair_third_income_file: [],
    pair_referral_agency_file: [],
    pair_residence_file: [],
    pair_first_borrowing_file: [],
    pair_second_borrowing_file: [],
  },
};

export const stepFourSlice = createSlice({
  name: 'stepFourB',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<StepFourForm>) => {
      state.form = action.payload;
    },
    newFormUpload: (state) => {
      state.editForm = initialState.editForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUploadedFiles.fulfilled, (state, action) => {
      state.editForm = action.payload.data;
    });
  },
});

export const { saveForm, newFormUpload } = stepFourSlice.actions;

export default stepFourSlice.reducer;
