import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { useAppDispatch } from 'hooks';
import {
  createDownloadLogManager,
  getExportRawData,
} from 'pages/AdminEditPreliminary/thunk';
import { FC, useCallback } from 'react';

type ButtonDownloadExcelProps = {
  preliminaryId?: string;
  applicationNumber?: string;
  firstNameKanji?: string;
  lastNameKanji?: string;
};

export const ButtonDownloadExcel: FC<ButtonDownloadExcelProps> = ({
  preliminaryId,
  applicationNumber,
  firstNameKanji,
  lastNameKanji,
}) => {
  const dispatch = useAppDispatch();

  const handleExportRawData = useCallback(async () => {
    await dispatch(
      getExportRawData({
        id: preliminaryId ?? '',
        applicationNumber: applicationNumber ?? '',
        firstNameKanji: firstNameKanji ?? '',
        lastNameKanji: lastNameKanji ?? '',
      })
    );
    await dispatch(
      createDownloadLogManager({
        id: preliminaryId ?? '',
        key: 'download_raw_data',
      })
    );
  }, [
    dispatch,
    preliminaryId,
    lastNameKanji,
    firstNameKanji,
    applicationNumber,
  ]);

  return (
    <Button
      sx={{
        height: 36,
        bgcolor: 'main_white',
        width: 150,
        marginRight: 6,
        border: '1px solid #DBD9D9',
        boxShadow: 'none',
      }}
      onClick={handleExportRawData}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography variant="text_priliminary_pulldown">
          ダウンロード
        </Typography>
        <Icons.Excel sx={{ width: 20, height: 20 }} />
      </Stack>
    </Button>
  );
};
