import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep02: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="24"
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5006 12.0007C13.8149 12.0007 16.501 9.31414 16.501 6.00035C16.501 2.68656 13.8149 0 10.5006 0C7.18636 0 4.50026 2.68656 4.50026 6.00035C4.50026 9.31414 7.18636 12.0007 10.5006 12.0007ZM10.5006 0.750044C13.3958 0.750044 15.7509 3.10518 15.7509 6.00035C15.7509 8.89552 13.3958 11.2507 10.5006 11.2507C7.60544 11.2507 5.25031 8.8974 5.25031 6.00035C5.25031 3.10518 7.60357 0.750044 10.5006 0.750044ZM12.8773 14.2508H8.12391C3.63818 14.2508 0 17.8885 0 22.3747C0 23.272 0.727543 24 1.62478 24H19.3774C20.2746 24.0014 21.0012 23.2748 21.0012 22.3747C21.0012 17.8885 17.3635 14.2508 12.8773 14.2508ZM19.3746 23.2514H1.62525C1.14241 23.2514 0.750044 22.8576 0.750044 22.3747C0.750044 18.3104 4.05867 15.0009 8.12391 15.0009H12.8726C16.9416 15.0009 20.2512 18.3104 20.2512 22.3747C20.2512 22.8576 19.8574 23.2514 19.3746 23.2514Z"
          fill="url(#paint0_linear_7977_13239)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7977_13239"
            x1="3.23403"
            y1="19.0642"
            x2="21.4349"
            y2="2.97673"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
