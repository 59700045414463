import { z } from 'zod';

export const getReferralAgencyOptionRequestSchema = z.object({
  sale_agent_id: z.string().optional(),
  store_id: z.string().optional(),
  exhibition_id: z.string().optional(),
});

export const getReferralAgencyOptionResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    agencies: z.array(
      z.object({
        active: z.boolean(),
        id: z.string(),
        sale_agent_id: z.string(),
        value: z.string(),
      })
    ),
    stores: z.array(
      z.object({
        active: z.boolean(),
        id: z.string(),
        store_id: z.string(),
        value: z.string(),
      })
    ),
    exhibitions: z.array(
      z.object({
        active: z.boolean(),
        id: z.string(),
        exhibition_id: z.string(),
        value: z.string(),
      })
    ),
    sale_persons: z.array(
      z.object({
        id: z.string(),
        value: z.string(),
      })
    ),
  }),
});

export type getReferralAgencyOptionRequest = z.infer<
  typeof getReferralAgencyOptionRequestSchema
>;
export type getReferralAgencyOptionResponse = z.infer<
  typeof getReferralAgencyOptionResponseSchema
>;
export type getReferralAgencyOptionError = {};
