import { FC, PropsWithChildren } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';
import { principal, principalAndInterest } from 'assets';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPDifferenceModal: FC<ModalProps> = ({ open, onClose }) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="元利均等と元金均等の違い"
      disableBackDropClick
      sx={{ px: 0 }}
      footer={
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: 359 }}
        >
          <Stack sx={{ overflowY: 'scroll', maxHeight: '37vh' }} spacing={2}>
            {difference.map((item, index) => (
              <Stack key={index} spacing={3} sx={{ mb: 6 }}>
                <Stack sx={{ bgcolor: 'sp_primary_40', py: 1, pl: 4 }}>
                  <Typography variant="SP_form_item_label" color="b_333">
                    {item.title}
                  </Typography>
                </Stack>
                <Stack sx={{ px: 6 }}>
                  <Typography variant="sp_change_password_note" color="b_333">
                    {item.content}
                  </Typography>
                </Stack>
                <Stack alignItems="center">
                  <Avatar
                    variant="square"
                    src={item.src}
                    sx={{
                      width: 250,
                      height: 159,
                      '.MuiAvatar-img': {
                        objectFit: 'contain',
                      },
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>

          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};

const difference = [
  {
    title: '元利均等返済',
    content: '毎月の返済額が変わらないため返済計画が立てやすい返済方法です。',
    src: principalAndInterest,
  },
  {
    title: '元金均等返済',
    content:
      '返済開始当初の返済額が大きいですが、総返済額を抑えることができる返済方法です。',
    src: principal,
  },
];
