import { FC, ReactElement } from 'react';
import { styled } from '@mui/material';

export type UlstyleProps = {
  children: ReactElement | ReactElement[];
};

export const UlStyle: FC<UlstyleProps> = ({ children }) => (
  <UlStyled>{children}</UlStyled>
);

const UlStyled = styled('ul')(() => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  '& li': {
    paddingLeft: '1em',
  },
  '& li:before': {
    content: "'\\※'",
    display: 'inline-block',
    marginLeft: '-1em',
    width: '1em',
    color: '#444',
    fontFamily: 'Hiragino Sans',
    fontWeight: 400,
    fontSize: 14,
  },
}));
