import { useFormikContext } from 'formik';
import { StepSynthesisForm } from 'types';
import { Grid, Stack, Typography } from '@mui/material';
import {
  CheckboxMultipleButton,
  FormItem,
  InputField,
  InputSelect,
  InputSelectProps,
  RadioGroupButton,
} from 'components';
import {
  child,
  ChildType,
  PlannedCohabitantType,
  ReasonsForNeedingHousingType,
  ResidentStatusType,
} from 'constant';
import { useEffect, useMemo } from 'react';
import {
  convertDynamicMultipleOptions,
  convertDynamicOptions,
  zeroPad,
} from 'utils';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { useSelector } from 'react-redux';

type RadioItems = {
  value: string;
  label: string;
};

type SelectProcurementType = {
  children_number: string;
  siblings_number: string;
  other_people_number: string;
};

const StepResidentsPage = () => {
  const { values, setFieldValue } = useFormikContext<StepSynthesisForm>();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  useEffect(() => {
    if (
      !values.main_p_applicant_person.planned_cohabitant.includes(
        PlannedCohabitantType.CHILD
      )
    ) {
      setFieldValue('main_p_applicant_person.children_number', '');
    }
    if (
      !values.main_p_applicant_person.planned_cohabitant.includes(
        PlannedCohabitantType.BROTHERS_AND_SISTERS
      )
    ) {
      setFieldValue('main_p_applicant_person.siblings_number', '');
    }
    if (
      !values.main_p_applicant_person.planned_cohabitant.includes(
        PlannedCohabitantType.OTHERS
      )
    ) {
      setFieldValue('main_p_applicant_person.other_relationship', '');
      setFieldValue('main_p_applicant_person.other_people_number', '');
    }
    if (
      values.main_p_applicant_person.resident_status !==
      ResidentStatusType.THE_PERSON_DOES_NOT_RESIDE_OTHER
    ) {
      setFieldValue('main_p_applicant_person.non_resident_reason', '');
    }
  }, [
    values.main_p_applicant_person.current_residence,
    values.main_p_applicant_person.planned_cohabitant,
    values.main_p_applicant_person.resident_status,
    setFieldValue,
  ]);

  const {
    children_number,
    siblings_number,
    other_people_number,
    planned_cohabitant,
  } = values.main_p_applicant_person;

  useEffect(() => {
    const params: SelectProcurementType = {
      children_number,
      siblings_number,
      other_people_number,
    };

    const singleCohabitant = planned_cohabitant.filter(
      (cohabitant) =>
        cohabitant !== PlannedCohabitantType.CHILD &&
        cohabitant !== PlannedCohabitantType.BROTHERS_AND_SISTERS &&
        cohabitant !== PlannedCohabitantType.OTHERS
    ).length;

    setFieldValue(
      'main_p_applicant_person.housemate_number',
      singleCohabitant + +calculateTotal(params)
    );
  }, [
    children_number,
    siblings_number,
    other_people_number,
    setFieldValue,
    planned_cohabitant,
  ]);

  const currentResidenceOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.current_residence),
    [dynamicOptions.current_residence]
  );

  const reasonAcquireHomeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.reason_acquire_home),
    [dynamicOptions.reason_acquire_home]
  );

  const businessAbilityOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.business_ability),
    [dynamicOptions.business_ability]
  );

  const residentStatusOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.resident_status),
    [dynamicOptions.resident_status]
  );

  const plannedCohabitantOptions = useMemo(
    () => convertDynamicMultipleOptions(dynamicOptions.planned_cohabitant),
    [dynamicOptions.planned_cohabitant]
  );

  return (
    <Stack justifyContent="center">
      <FormItem required divider label="現居の居住年数">
        <Stack direction="row" spacing={1}>
          <Stack>
            <InputSelect
              sx={{ minHeight: 50, height: 50, width: 130 }}
              name="main_p_applicant_person.lived_length_year_num"
              options={yearNumOptions}
              placeholder="0"
              unit="年"
              showError={false}
              checkRequired
            />
          </Stack>

          <Stack>
            <InputSelect
              sx={{ minHeight: 50, height: 50, width: 130 }}
              name="main_p_applicant_person.lived_length_month_num"
              options={monthOptions}
              placeholder="0"
              unit="ヶ月"
              checkRequired
            />
          </Stack>
        </Stack>
      </FormItem>

      <FormItem required divider label="現在の住居">
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="main_p_applicant_person.current_residence"
          options={currentResidenceOptions}
        />
      </FormItem>

      <FormItem required divider label="住宅を必要とする理由">
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="main_p_applicant_person.reason_acquire_home"
          options={reasonAcquireHomeOptions}
        />
      </FormItem>

      {values.main_p_applicant_person.reason_acquire_home ===
        ReasonsForNeedingHousingType.OTHERS && (
        <FormItem label="その他" required divider>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 719 } }}
              name="main_p_applicant_person.other_reason_acquire_home"
              placeholder="ー"
            />
          </Stack>
        </FormItem>
      )}

      <FormItem required divider label="融資対象物件の事業性">
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="main_p_applicant_person.business_ability"
          options={businessAbilityOptions}
        />
      </FormItem>

      <FormItem label="取得する物件の居住区分" required divider>
        <RadioGroupButton
          itemPerRow={3}
          type="noImage"
          name="main_p_applicant_person.resident_status"
          options={residentStatusOptions}
        />
      </FormItem>

      {values.main_p_applicant_person.resident_status ===
        ResidentStatusType.THE_PERSON_DOES_NOT_RESIDE_OTHER && (
        <FormItem required divider label="本人が居住しない理由（その他）">
          <InputField
            name="main_p_applicant_person.non_resident_reason"
            placeholder="ー"
          />
        </FormItem>
      )}

      <FormItem required divider label="居住予定者">
        <CheckboxMultipleButton
          sx={{
            width: '100%',
            justifyContent: 'center',
          }}
          itemPerRow={3}
          name="main_p_applicant_person.planned_cohabitant"
          options={plannedCohabitantOptions}
        />
      </FormItem>

      {values.main_p_applicant_person.planned_cohabitant.includes(
        PlannedCohabitantType.CHILD
      ) && (
        <FormItem required divider label="子供">
          <Stack sx={{ maxWidth: { mobile: '100%', tablet: 187 } }}>
            <InputSelect
              sx={{ minHeight: 50, height: 50 }}
              name="main_p_applicant_person.children_number"
              options={childOptions}
              placeholder="ー"
              unit="人"
              checkRequired
            />
          </Stack>
        </FormItem>
      )}

      {values.main_p_applicant_person.planned_cohabitant.includes(
        PlannedCohabitantType.BROTHERS_AND_SISTERS
      ) && (
        <FormItem required divider label="兄弟姉妹">
          <Stack sx={{ maxWidth: { mobile: '100%', tablet: 187 } }}>
            <InputSelect
              sx={{ minHeight: 50, height: 50 }}
              name="main_p_applicant_person.siblings_number"
              options={childOptions}
              placeholder="ー"
              unit="人"
              checkRequired
            />
          </Stack>
        </FormItem>
      )}

      {values.main_p_applicant_person.planned_cohabitant.includes(
        PlannedCohabitantType.OTHERS
      ) && (
        <FormItem required divider label="その他">
          <Stack spacing={9}>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography
                variant="text_input_select"
                color="normal_text"
                sx={{ whiteSpace: 'nowrap' }}
              >
                続柄
              </Typography>
              <Grid container>
                <Grid item {...{ mobile: 12, md: 12 }}>
                  <InputField
                    name="main_p_applicant_person.other_relationship"
                    placeholder="ー"
                  />
                </Grid>
              </Grid>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={4}
              sx={{ width: { mobile: '100%', tablet: 247 } }}
            >
              <Typography variant="text_input_select" color="normal_text">
                人数
              </Typography>
              <InputSelect
                sx={{ minHeight: 50, height: 50 }}
                name="main_p_applicant_person.other_people_number"
                options={childOptions}
                placeholder="ー"
                unit="人"
                checkRequired
              />
            </Stack>
          </Stack>
        </FormItem>
      )}

      <FormItem
        required
        label="居住予定者合計人数（本人を除く）"
        note={notes.total_people}
      >
        <Stack direction="row">
          <InputField
            readOnly
            sx={{ width: { mobile: '100%', tablet: 157 } }}
            name="main_p_applicant_person.housemate_number"
            placeholder="ー"
            unit="人"
          />
        </Stack>
      </FormItem>
    </Stack>
  );
};

export default StepResidentsPage;

const notes = {
  total_people: '※対象物件に入居される人数を入力してください。',
  fullName: [
    '外国人の方等で本入力欄にフルネームが入力しきれない場合は、本欄にはお名前を途中までご入力いただき、\n下記の特記事項欄にフルネームをご入力ください。',
    'お名前の漢字が外字等で変換できない場合は常用漢字でご入力ください。',
  ],
};

const childOptions: Array<RadioItems> = [
  {
    value: ChildType.ONE,
    label: child[ChildType.ONE],
  },
  {
    value: ChildType.TWO,
    label: child[ChildType.TWO],
  },
  {
    value: ChildType.THREE,
    label: child[ChildType.THREE],
  },
  {
    value: ChildType.FOUR,
    label: child[ChildType.FOUR],
  },
  {
    value: ChildType.FIVE,
    label: child[ChildType.FIVE],
  },
  {
    value: ChildType.SIX,
    label: child[ChildType.SIX],
  },
  {
    value: ChildType.SEVEN,
    label: child[ChildType.SEVEN],
  },
  {
    value: ChildType.EIGHT,
    label: child[ChildType.EIGHT],
  },
  {
    value: ChildType.NINE,
    label: child[ChildType.NINE],
  },
];

const yearNumOptions: InputSelectProps['options'] = [
  { value: '', label: '年' },
].concat(
  Array.from(Array(99), (_, index) => {
    return {
      value: zeroPad(index + 1),
      label: zeroPad(index + 1),
    };
  })
);

const monthOptions: InputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: zeroPad(index + 1),
  }))
);

const calculateTotal = ({
  children_number,
  siblings_number,
  other_people_number,
}: SelectProcurementType) => {
  return +children_number + +siblings_number + +other_people_number || '';
};
