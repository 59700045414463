import { InboxOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { Icons } from 'components';
import { PreliminariesStatus } from 'constant';
import { FC, MouseEvent } from 'react';
import {
  AssignSalePersonRequest,
  AssignStoreNameRequest,
  ManagerPreliminariesItem,
} from 'services';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, OrderType, TableProps } from 'types';
import { ManagerPreliminariesItems } from '../ManagerPreliminarieItems';

type ManagerPreliminariesTableProps = {
  activeTab?: string;
  fetching: boolean;
  changeApplicationStatus: (
    applicantId: string,
    applicationStatus: number
  ) => void;
  confirmUpdateLinking: (
    idLinking: string,
    idSelected: string,
    isLinking: boolean
  ) => void;
  onAssignManager: (id: string, manager_id: string) => void;
  onAssignSalesPerson: (val: AssignSalePersonRequest) => void;
  onAssignStoreName: (val: AssignStoreNameRequest) => void;
};

export const ManagerPreliminariesTable: FC<
  TableProps<ManagerPreliminariesItem> & ManagerPreliminariesTableProps
> = ({
  onRequestSort,
  onFilter,
  order,
  orderBy,
  rows,
  headCells,
  activeTab,
  fetching,
  changeApplicationStatus,
  confirmUpdateLinking,
  onAssignManager,
  onAssignStoreName,
  onAssignSalesPerson,
}) => {
  const createSortHandler =
    (property: keyof ManagerPreliminariesItem) =>
    (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table aria-label="simple table">
        <TableHead sx={{ display: 'flex', minWidth: 1024 }}>
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            }}
          >
            {(headCells ? headCells : defaultHeadCells).map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{
                  ...headerCellStyle,
                  ...headCell.style,
                }}
                onClick={() => {
                  if (headCell.id === 'id') {
                    onFilter && onFilter();
                  }
                }}
              >
                <Typography
                  sx={{
                    direction: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="table_header_text"
                    sx={{ whiteSpace: 'nowrap' }}
                    color="b_333"
                  >
                    {headCell.label}
                  </Typography>
                  {headCell.smallLabel && (
                    <Typography
                      variant="table_small_text"
                      sx={{ whiteSpace: 'nowrap' }}
                      color="b_333"
                    >
                      {headCell.smallLabel}
                    </Typography>
                  )}
                  {headCell.id !== 'id' && (
                    <TableSort
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : undefined}
                      onClick={createSortHandler(headCell.id)}
                    />
                  )}
                </Typography>
                {headCell.id !== 'manager_name_kanji' &&
                  headCell.id !== 'id' && (
                    <DividerStyled orientation="vertical" />
                  )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 && (
            <TableRow>
              <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                <Stack
                  sx={{
                    px: '10px',
                    bgcolor: 'new_bg_grey',
                    overflowX: 'auto',
                    height: 'auto',
                    maxHeight: ` calc(100vh - 110px)`,
                    flex: 1,
                    ...styleScrollBarTable,
                  }}
                  id="preliminary-table-body"
                >
                  <ManagerPreliminariesItems
                    data={rows}
                    activeTab={
                      activeTab ? activeTab : PreliminariesStatus.CURRENT
                    }
                    changeApplicationStatus={changeApplicationStatus}
                    confirmUpdateLinking={confirmUpdateLinking}
                    onAssignManager={onAssignManager}
                    onAssignSalesPerson={onAssignSalesPerson}
                    onAssignStoreName={onAssignStoreName}
                  />
                  {fetching && (
                    <Stack alignItems={'center'} sx={{ marginBottom: 3 }}>
                      <CircularProgress />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </TableRow>
          )}
          {!fetching && rows.length === 0 && (
            <Stack
              sx={{
                display: 'flex',
                height: 'calc(100vh - 110px)',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#d8d8d8',
                background: '#f8f8f8',
              }}
            >
              <InboxOutlined
                sx={{
                  width: 80,
                  height: 70,
                  marginRight: '5px',
                }}
              />
              <Typography sx={{ color: '#7d7d7d' }}>
                案件がありません。
              </Typography>
            </Stack>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type SortTableProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const TableSort: FC<SortTableProps> = ({ active, direction, onClick }) => {
  return (
    <IconButton sx={{ p: '2px' }} onClick={onClick}>
      {direction === undefined && (
        <Icons.SortDefault
          sx={{
            width: 16,
            height: 13,
            color: 'gray',
          }}
        />
      )}
      {direction === 'desc' && (
        <Icons.SortDesc
          sx={{
            width: 16,
            height: 13,
            color: 'sp_primary_100_main',
          }}
          stroke="#CCCCCC"
        />
      )}
      {direction === 'asc' && (
        <Icons.SortAsc
          sx={{
            width: 16,
            height: 13,
          }}
        />
      )}
    </IconButton>
  );
};

const defaultHeadCells: HeadCellProps<ManagerPreliminariesItem>[] = [
  {
    id: 'application_number',
    label: '受付番号',
    style: { width: 171 },
  },
  {
    id: 'bank_name',
    label: '申込銀行',
    style: {
      justifyContent: 'center',
      width: 150,
      '@media (min-width: 1441px)': {
        flex: 1,
      },
    },
  },
  {
    id: 'applicant_name',
    label: '申込人',
    style: {
      justifyContent: 'center',
      width: 180,
      '@media (min-width: 1441px)': {
        flex: 1,
      },
    },
  },
  {
    id: 'created_at',
    label: '申込日時',
    style: {
      justifyContent: 'center',
      width: 115,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'loan_desired_borrowing_date',
    label: '実行予定日',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'temporary_desired_loan_amount',
    label: '申込金額',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'status_result',
    label: '進捗状況',
    style: {
      justifyContent: 'center',
      width: 150,
      '@media (min-width: 1441px)': {
        width: 201,
      },
    },
  },
  {
    id: 'provisional_result',
    label: '仮審査結果',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 120,
        pl: '5px',
      },
    },
  },
  {
    id: 'store_name_kanji',
    label: 'エリア',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 140,
      },
    },
  },
  {
    id: 'sale_person_name_kanji',
    label: '営業担当',
    style: {
      justifyContent: 'center',
      width: 120,
      '@media (min-width: 1441px)': {
        width: 140,
      },
    },
  },
  {
    id: 'manager_name_kanji',
    label: '銀代担当',
    style: {
      justifyContent: 'center',
      width: 120,
      '@media (min-width: 1441px)': {
        width: 140,
      },
    },
  },
  {
    id: 'id',
    label: <Icons.Filter sx={{ mt: 4, cursor: 'pointer', color: 'b_333' }} />,
    style: {
      pr: '11px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
];

const headerCellStyle = {
  height: 40,
  p: 0,
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  border: 'none',
};

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));
