import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SPStepSixForm } from 'types';
import { logout } from 'containers/AuthModal/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { deleteUser } from '../SPUnsubcribed/thunk';

export type InitialState = {
  form: SPStepSixForm;
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      house_purchase_price: '',
      additional_cost: '',
      require_funds_breakdown_mortgage: '',
      land_purchase_price: '',
      accessory_cost: '',
      refinancing_loan_balance: '',
      house_upgrade_cost: '',
      deposit_savings_1: '',
      real_estate_sale_price: '',
      other_saving_amount: '',
      relative_donation_amount: '',
      loan_amount: '',
      pair_loan_amount: '',
      other_procurement_breakdown: '',
      other_procurement_breakdown_content: '',
    },
  },
};

const spStepSixSlice = createSlice({
  name: 'spStepSix',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<SPStepSixForm>) => {
      state.form = {
        ...action.payload,
      };
    },
    resetStepSixForm: (state) => {
      state.form = initialState.form;
    },
    clearPairLoanAmount: (state) => {
      state.form.p_application_header = {
        ...state.form.p_application_header,
        pair_loan_amount: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_application_header } = action.payload.data;
      const {
        house_purchase_price,
        additional_cost,
        require_funds_breakdown_mortgage,
        land_purchase_price,
        accessory_cost,
        refinancing_loan_balance,
        house_upgrade_cost,
        deposit_savings_1,
        real_estate_sale_price,
        other_saving_amount,
        relative_donation_amount,
        loan_amount,
        pair_loan_amount,
        other_procurement_breakdown,
        other_procurement_breakdown_content,
      } = p_application_header;
      state.form.p_application_header = {
        ...state.form.p_application_header,
        house_purchase_price,
        additional_cost,
        require_funds_breakdown_mortgage,
        land_purchase_price,
        accessory_cost,
        refinancing_loan_balance,
        house_upgrade_cost,
        deposit_savings_1,
        real_estate_sale_price,
        other_saving_amount,
        relative_donation_amount,
        loan_amount,
        pair_loan_amount,
        other_procurement_breakdown,
        other_procurement_breakdown_content,
      };
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const {
        house_purchase_price,
        additional_cost,
        require_funds_breakdown_mortgage,
        land_purchase_price,
        accessory_cost,
        refinancing_loan_balance,
        house_upgrade_cost,
        deposit_savings_1,
        real_estate_sale_price,
        other_saving_amount,
        relative_donation_amount,
        loan_amount,
        pair_loan_amount,
        other_procurement_breakdown,
        other_procurement_breakdown_content,
      } = action.payload.data;
      state.form.p_application_header = {
        ...state.form.p_application_header,
        house_purchase_price,
        additional_cost,
        require_funds_breakdown_mortgage,
        land_purchase_price,
        accessory_cost,
        refinancing_loan_balance,
        house_upgrade_cost,
        deposit_savings_1,
        real_estate_sale_price,
        other_saving_amount,
        relative_donation_amount,
        loan_amount,
        pair_loan_amount,
        other_procurement_breakdown,
        other_procurement_breakdown_content,
      };
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const {
        house_purchase_price,
        additional_cost,
        require_funds_breakdown_mortgage,
        land_purchase_price,
        accessory_cost,
        refinancing_loan_balance,
        house_upgrade_cost,
        deposit_savings_1,
        real_estate_sale_price,
        other_saving_amount,
        relative_donation_amount,
        loan_amount,
        pair_loan_amount,
        other_procurement_breakdown,
        other_procurement_breakdown_content,
      } = action.payload.data;
      state.form.p_application_header = {
        ...state.form.p_application_header,
        house_purchase_price,
        additional_cost,
        require_funds_breakdown_mortgage,
        land_purchase_price,
        accessory_cost,
        refinancing_loan_balance,
        house_upgrade_cost,
        deposit_savings_1,
        real_estate_sale_price,
        other_saving_amount,
        relative_donation_amount,
        loan_amount,
        pair_loan_amount,
        other_procurement_breakdown,
        other_procurement_breakdown_content,
      };
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const { saveForm, resetStepSixForm, clearPairLoanAmount } =
  spStepSixSlice.actions;
export default spStepSixSlice.reducer;
