import { Stack, Typography } from '@mui/material';
import {
  Icons,
  SPButton,
  SPCheckboxButton,
  SPFormItem,
  SPImage,
  SPInputDate,
  SPInputField,
  SPInputSelect,
  SPInputSelectProps,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPSaveImageModal,
  SPUploadImageItem,
} from 'components';
import {
  BorrowerIncomeType,
  BorrowingFromHousingFinanceAgencyType,
  BorrowingStatusType,
  BusinessBorrowingType,
  CommonHousingType,
  EstateMortgageType,
  FundsPairOffType,
  GeneralIncomeConfirmation,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  SPBorrowingCategoryType,
  SPBorrowingType,
  SPLoanPurposeType,
  SPScheduledLoanPayoffType,
  borrowerIncome,
  borrowingFromHousingFinanceAgency,
  borrowingStatus,
  commonHousing,
  estateMortgage,
} from 'constant';
import { SPStepLayout } from 'containers';
import { spCurrentStepSelector } from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { ImageContext } from 'context';
import dayjs from 'dayjs';
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import kanjidate from 'kanjidate';
import { cloneDeep, get, isEmpty, update } from 'lodash';
import { routeNames } from 'navigations/routes';
import { spStepEightSelector } from 'pages/SPStepEight/selectors';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  checkMCJ,
  spStepOneSelector,
} from 'pages/SPStepOne/selectors';
import { spStepSixSelector } from 'pages/SPStepSix/selectors';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { spStepThreeSelector } from 'pages/SPStepThree/selectors';
import { spStepTwoSelector } from 'pages/SPStepTwo/selectors';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SPBorrowingAttribute, SPStepFiveForm } from 'types';
import {
  convertDynamicOptions,
  convertImage,
  convertManToYen,
  flattenToLodashFormat,
  zeroPad,
  onlyNumbers,
  supportDvh,
  checkingExistFile,
} from 'utils';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import { spStepSevenSelector } from '../SPStepSeven/selectors';
import { spStepSevenIncomeTotalizerSelector } from '../SPStepSevenIncomeTotalizer/selectors';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { convertStepFiveSelector, spStepFiveSelector } from './selectors';
import { saveForm } from './slice';
import {
  incomeTotalValidationSchema,
  incomeTotalValidationSchemaMCJ,
  validationSchema,
  validationSchemaMCJ,
} from './validationSchema';

type RadioItems = {
  value: string;
  label: string;
};

const SPStepFivePage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialValues = useSelector(spStepFiveSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepSevenIncomeValues = useSelector(
    spStepSevenIncomeTotalizerSelector
  );
  const spStepEightValues = useSelector(spStepEightSelector);
  const convertedFields = useSelector(convertStepFiveSelector);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const isMCJ = useSelector(checkMCJ);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const currentStep = useSelector(spCurrentStepSelector);
  const { application_number } = useSelector(spStepTenSelector);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const { updateFormOverride } = useUpdateForm();

  const {
    repaymentScheduleImage,
    setRepaymentScheduleImage,
    residenceFile,
    residenceFileBackImage,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    businessCard,
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
    insuranceFile2,
    insuranceFileBackImage2,
    financialStatement1File2,
    financialStatement2File2,
    financialStatement3File2,
    firstWithholdingSlipFile2,
    secondWithholdingSlipFile2,
    otherDocumentFile2,
    employmentAgreementFile2,
    businessTaxReturn1File2,
    businessTaxReturn2File2,
    businessTaxReturn3File2,
    firstIncomeFile2,
    secondIncomeFile2,
    thirdIncomeFile2,
    driverLicenseFrontImage2,
    driverLicenseBackImage2,
    cardNumberFrontImage2,
    cardNumberBackImage2,
    residentRegisterFrontImage2,
    residentRegisterBackImage2,
    propertyInformationFile,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [open, setOpen] = useState<boolean>(false);
  const [errEmptyBorrowing, setErrEmptyBorrowing] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);
  const borrowingTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.borrowing_type),
    [dynamicOptions.borrowing_type]
  );

  const loanPurposeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.loan_purpose),
    [dynamicOptions.loan_purpose]
  );
  const businessBorrowingTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.business_borrowing_type),
    [dynamicOptions.business_borrowing_type]
  );

  const borrowingCategoryOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.borrowing_category),
    [dynamicOptions.borrowing_category]
  );

  const completelyRepaymentTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.completely_repayment_type),
    [dynamicOptions.completely_repayment_type]
  );

  const convertCurrency = useCallback((values: SPStepFiveForm) => {
    const p_borrowings_attributes =
      values.p_application_header.p_borrowings_attributes.map((borrowing) => {
        const [loan_amount, current_balance_amount, annual_repayment_amount] =
          convertManToYen([
            borrowing.loan_amount,
            borrowing.current_balance_amount,
            borrowing.annual_repayment_amount,
          ]);
        return {
          ...borrowing,
          loan_amount,
          current_balance_amount,
          annual_repayment_amount,
        };
      });

    const [refund_amount] = convertManToYen([
      values.p_application_header.refund_amount,
    ]);
    return {
      p_application_header: {
        ...values.p_application_header,
        p_borrowings_attributes,
        refund_amount,
      },
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      p_borrowings_attributes: convertedFields.p_borrowings_attributes,
      refund_amount: convertedFields.refund_amount,
    },
    enableReinitialize: true,
    validationSchema: isMCJ // isMSJ is always false
      ? incomeTotalizer
        ? incomeTotalValidationSchemaMCJ
        : validationSchemaMCJ
      : incomeTotalizer
      ? incomeTotalValidationSchema
      : validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!errEmptyBorrowing) {
        const {
          p_applicant_people_attributes,
          p_borrowings_attributes,
          completely_repayment_type,
          other_complete_repayment_type,
          refund_content,
          refund_amount,
          land_rent_to_be_paid_borrower,
          land_rent_to_be_paid,
          house_rent_to_be_paid_borrower,
          house_rent_to_be_paid,
        } = values;
        dispatch(
          saveForm(
            convertCurrency({
              p_application_header: {
                p_applicant_people_attributes,
                p_borrowings_attributes,
                completely_repayment_type,
                other_complete_repayment_type,
                refund_content,
                refund_amount,
                land_rent_to_be_paid_borrower,
                land_rent_to_be_paid,
                house_rent_to_be_paid_borrower,
                house_rent_to_be_paid,
              },
            })
          )
        );
        if (incomeTotalizer && +currentStep < 8) {
          dispatch(setCurrentStep('8'));
        }
        if (incomeTotalizer && hasJoinGuarantor && +currentStep < 9) {
          dispatch(setCurrentStep('9'));
        }
        if (hasJoinGuarantor && +currentStep < 7) {
          dispatch(setCurrentStep('7'));
        }
        if (+currentStep < 6) dispatch(setCurrentStep('6'));
        setSubmitting(false);
        if (!!application_number) {
          setIsDisabledButton(true);
          updateFormOverride(
            {
              stepFiveData: convertCurrency({
                p_application_header: {
                  p_applicant_people_attributes,
                  p_borrowings_attributes,
                  completely_repayment_type,
                  other_complete_repayment_type,
                  refund_content,
                  refund_amount,
                  land_rent_to_be_paid_borrower,
                  land_rent_to_be_paid,
                  house_rent_to_be_paid_borrower,
                  house_rent_to_be_paid,
                },
              }),
              imageContext: imageContext,
            },
            setIsDisabledButton,
            setOpenImageModal,
            setReset
          );
        } else {
          navigate(routeNames.SPStepSix.path);
        }
      }
    },
  });

  const btnSubmit = useMemo(() => {
    return !!application_number ? '保存' : '次へ';
  }, [application_number]);

  const resetMCJStepFive = useCallback(() => {
    formik.setFieldValue('completely_repayment_type', FundsPairOffType.DEFAULT);
    formik.setFieldValue('other_complete_repayment_type', '');
    formik.setFieldValue('refund_content', '');
    formik.setFieldValue('refund_amount', '');
    formik.setFieldValue(
      'land_rent_to_be_paid_borrower',
      BorrowerIncomeType.DEFAULT
    );
    formik.setFieldValue('land_rent_to_be_paid', '');
    formik.setFieldValue(
      'p_applicant_people_attributes[0].land_rent_to_be_paid',
      ''
    );
    formik.setFieldValue('house_rent_to_be_paid', '');
    formik.setFieldValue(
      'house_rent_to_be_paid_borrower',
      BorrowerIncomeType.DEFAULT
    );
    formik.setFieldValue(
      'p_applicant_people_attributes[0].house_rent_to_be_paid',
      ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scheduledLoanPayoffOptions = useCallback(
    (index: number) => {
      let options = convertDynamicOptions(dynamicOptions.scheduled_loan_payoff);
      if (
        (formik.values.p_borrowings_attributes[index]?.borrowing_type ===
          SPBorrowingType.CARD_LOAN ||
          formik.values.p_borrowings_attributes[index]?.borrowing_type ===
            SPBorrowingType.CAR_LOAN ||
          formik.values.p_borrowings_attributes[index]?.borrowing_type ===
            SPBorrowingType.EDUCATION_LOAN ||
          formik.values.p_borrowings_attributes[index]?.borrowing_type ===
            SPBorrowingType.LOAN_FOR_LIVING_EXPENSES ||
          formik.values.p_borrowings_attributes[index]?.borrowing_type ===
            SPBorrowingType.OTHERS) &&
        formik.values.is_home_loan_plus
      )
        return options;
      return options.slice(0, 2);
    },
    [
      dynamicOptions.scheduled_loan_payoff,
      formik.values.is_home_loan_plus,
      formik.values.p_borrowings_attributes,
    ]
  );

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);

    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });

    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });

    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    const { income_source, type_tax_return, ...stepThreeValues } =
      spStepThreeValues.p_applicant_people;
    Object.keys(stepThreeValues).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(stepThreeValues, key) ?? ''
      );
    });

    income_source.forEach((id) => {
      data.append(`draft[p_applicant_people][0][income_source][]`, id);
    });

    type_tax_return.forEach((id) => {
      data.append(`draft[p_applicant_people][0][type_tax_return][]`, id);
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/
    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const { p_application_header } = convertCurrency({
      p_application_header: {
        p_applicant_people_attributes:
          formik.values.p_applicant_people_attributes,
        p_borrowings_attributes: formik.values.p_borrowings_attributes,
        completely_repayment_type: formik.values.completely_repayment_type,
        other_complete_repayment_type:
          formik.values.other_complete_repayment_type,
        refund_content: formik.values.refund_content,
        refund_amount: formik.values.refund_amount,
        land_rent_to_be_paid_borrower:
          formik.values.land_rent_to_be_paid_borrower,
        land_rent_to_be_paid: formik.values.land_rent_to_be_paid,
        house_rent_to_be_paid_borrower:
          formik.values.house_rent_to_be_paid_borrower,
        house_rent_to_be_paid: formik.values.house_rent_to_be_paid,
      },
    });

    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = p_application_header;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      formik.values.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      formik.values.p_applicant_people_attributes.forEach((person, index) => {
        Object.keys(person).forEach((key) => {
          data.append(
            `draft[p_applicant_people][${index}][${key}]`,
            get(person, key) ?? ''
          );
        });
      });
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } =
        spStepSevenIncomeValues.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    if (spSaveDraft.rejected.match(result)) {
      setReset(reset + 1);
    }
    return;
  };

  useEffect(() => {
    let newRepaymentScheduleImage: (SPImage | File)[][] = [];

    initialValues.p_borrowings_attributes.forEach((borrowing, index) => {
      if (
        !!repaymentScheduleImage[index] &&
        repaymentScheduleImage[index].length === 0
      ) {
        //Case push image from redux
        if (
          !!borrowing.repayment_schedule_image &&
          !!borrowing.repayment_schedule_image?.length
        ) {
          const listImages = borrowing.repayment_schedule_image.filter(
            (file) => !file.isDelete
          );
          newRepaymentScheduleImage.push(
            listImages.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        } else {
          newRepaymentScheduleImage.push([]);
        }
      } else if (
        !!repaymentScheduleImage[index] &&
        !!repaymentScheduleImage[index].length
      ) {
        newRepaymentScheduleImage.push(repaymentScheduleImage[index]); //Case push image from context
      } else {
        if (
          !!borrowing.repayment_schedule_image &&
          !!borrowing.repayment_schedule_image?.length
        ) {
          const listImages = borrowing.repayment_schedule_image.filter(
            (file) => !file.isDelete
          );
          newRepaymentScheduleImage.push(
            listImages.map((file) => ({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            }))
          );
        } else {
          newRepaymentScheduleImage.push([]);
        }
      }
    });

    setRepaymentScheduleImage(newRepaymentScheduleImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.p_borrowings_attributes, setRepaymentScheduleImage]);

  // Check if borrowing is not empty anymore
  // useEffect(() => {
  //   if (
  //     formik.values.p_borrowings_attributes.some((borrowing, index) => {
  //       if (!!borrowing.repayment_schedule_image)
  //         return (
  //           borrowing.repayment_schedule_image?.length > 0 ||
  //           borrowing.self_input
  //         );
  //       return (
  //         repaymentScheduleImage[index]?.length > 0 || borrowing.self_input
  //       );
  //     })
  //   ) {
  //     setErrEmptyBorrowing(false);
  //   }
  // }, [formik.values.p_borrowings_attributes, repaymentScheduleImage]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  useEffect(() => {
    if (incomeTotalizer) {
      if (
        formik.values.p_borrowings_attributes.every(
          (borrowing) =>
            borrowing.borrower === BorrowerIncomeType.APPLICANT_SELF
        ) &&
        formik.values.p_applicant_people_attributes[1]?.borrowing_status !==
          BorrowingStatusType.NO
      ) {
        formik.setFieldValue(
          'p_applicant_people_attributes[1].borrowing_status',
          BorrowingStatusType.NO
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_borrowings_attributes, incomeTotalizer]);

  const indexes = formik.values.p_borrowings_attributes
    .filter((borrowing) => !borrowing._destroy)
    .map((borrowing) =>
      formik.values.p_borrowings_attributes.indexOf(borrowing)
    );

  const hasNoEmptyValueBorrowing = (obj: SPBorrowingAttribute) => {
    return Object.keys(obj).some((_key) => {
      const key = _key as keyof SPBorrowingAttribute;
      return (
        key !== 'id' &&
        key !== '_destroy' &&
        key !== 'repayment_schedule_image' &&
        key !== 'borrower' &&
        key !== 'borrowing_type' &&
        key !== 'self_input' &&
        obj[key] !== ''
      );
    });
  };

  useEffect(() => {
    const borrowings = formik.values.p_borrowings_attributes;
    borrowings.forEach((borrowing, index) => {
      if (!borrowing.self_input && hasNoEmptyValueBorrowing(borrowing)) {
        formik.setFieldValue(
          `p_borrowings_attributes[${index}].self_input`,
          true
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_borrowings_attributes]);

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={() => {
          const {
            p_applicant_people_attributes,
            p_borrowings_attributes,
            completely_repayment_type,
            other_complete_repayment_type,
            refund_content,
            refund_amount,
            land_rent_to_be_paid_borrower,
            land_rent_to_be_paid,
            house_rent_to_be_paid_borrower,
            house_rent_to_be_paid,
          } = formik.values;
          dispatch(
            saveForm(
              convertCurrency({
                p_application_header: {
                  p_applicant_people_attributes,
                  p_borrowings_attributes,
                  completely_repayment_type,
                  other_complete_repayment_type,
                  refund_content,
                  refund_amount,
                  land_rent_to_be_paid_borrower,
                  land_rent_to_be_paid,
                  house_rent_to_be_paid_borrower,
                  house_rent_to_be_paid,
                },
              })
            )
          );
        }}
        footer={{
          left: {
            onClick: () => {
              const {
                p_applicant_people_attributes,
                p_borrowings_attributes,
                completely_repayment_type,
                other_complete_repayment_type,
                refund_content,
                refund_amount,
                land_rent_to_be_paid_borrower,
                land_rent_to_be_paid,
                house_rent_to_be_paid_borrower,
                house_rent_to_be_paid,
              } = formik.values;
              dispatch(
                saveForm(
                  convertCurrency({
                    p_application_header: {
                      p_applicant_people_attributes,
                      p_borrowings_attributes,
                      completely_repayment_type,
                      other_complete_repayment_type,
                      refund_content,
                      refund_amount,
                      land_rent_to_be_paid_borrower,
                      land_rent_to_be_paid,
                      house_rent_to_be_paid_borrower,
                      house_rent_to_be_paid,
                    },
                  })
                )
              );
              if (!!application_number) {
                navigate(-1);
              } else {
                navigate(routeNames.SPStepFour.path);
              }
            },
          },
          right: {
            children: btnSubmit,
            onClick: () => {
              formik.handleSubmit();
            },
            disabled: isDisabledButton,
          },
        }}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        onSaveDraft={onSaveDraft}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack sx={{ py: 9 }} justifyContent="center" alignItems="center">
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >{`現在のお借入状況について\n教えてください。`}</Typography>
          </Stack>
        </Stack>

        {errEmptyBorrowing && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{
              bgcolor: 'sp_secondary_pink_light',
              border: (theme) => `1px solid ${theme?.palette?.sp_secondary_01}`,
              borderRadius: '8px',
              mx: 4,
              mb: 8,
              p: '8px 16px 8px 16px',
            }}
          >
            <Icons.SPWarning />
            <Stack>
              <Typography variant="drawerText" color="sp_secondary_01">
                現在の借入の返済予定表・利用明細書をアップロードするか、借入の情報をご入力ください。
              </Typography>
            </Stack>
          </Stack>
        )}

        <SPFormItem
          label="あなたや連帯保証人予定者に、現在お借入はありますか？"
          required
        >
          <Stack spacing={3}>
            <SPRadioGroupButton
              name="p_applicant_people_attributes[0].borrowing_status"
              options={borrowingStatusOptions}
              display="row"
              onChange={(checked, value) => {
                const resetBorrowings = formik.values.p_borrowings_attributes
                  .filter((borrowing) => !!borrowing.id)
                  .map((borrowing) => ({ ...borrowing, _destroy: true }));
                if (value === BorrowingStatusType.NO) {
                  setErrEmptyBorrowing(false);
                  setRepaymentScheduleImage([[]]);
                  return formik.setFieldValue(
                    'p_borrowings_attributes',
                    resetBorrowings
                  );
                }
                formik.setFieldValue('p_borrowings_attributes', [
                  ...resetBorrowings,
                  {
                    borrower: BorrowerIncomeType.DEFAULT,
                    borrowing_type: SPBorrowingType.DEFAULT,
                    self_input: false,
                    lender: '',
                    borrowing_from_housing_finance_agency:
                      BorrowingFromHousingFinanceAgencyType.DEFAULT,
                    loan_start_date: '',
                    loan_amount: '',
                    current_balance_amount: '',
                    annual_repayment_amount: '',
                    loan_deadline_date: '',
                    scheduled_loan_payoff: SPScheduledLoanPayoffType.DEFAULT,
                    loan_purpose: SPLoanPurposeType.DEFAULT,
                    other_purpose: '',
                    business_borrowing_type: BusinessBorrowingType.DEFAULT,
                    specific_loan_purpose: '',
                    borrowing_category: SPBorrowingCategoryType.DEFAULT,
                    card_expiry_date: '',
                    rental_room_number: '',
                    common_housing: CommonHousingType.DEFAULT,
                    estate_mortgage: EstateMortgageType.DEFAULT,
                    expected_repayment_date: '',
                  },
                ]);
                setRepaymentScheduleImage([[]]);
              }}
              required
            />
            <Stack direction="row" spacing={1}>
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Typography variant="SP_form_item_note" color="b_333">
                住宅ローン、車のローン、奨学金などの他、カードローン、キャッシングがある場合も「有」を選択ください。
              </Typography>
            </Stack>
            {formik.values.p_applicant_people_attributes[0].borrowing_status ===
              BorrowingStatusType.YES && (
              <FieldArray
                name="p_borrowings_attributes"
                render={(arrayHelpers) => (
                  <Fragment>
                    {formik.values.p_borrowings_attributes.map(
                      (borrowing, index) => {
                        return (
                          !borrowing._destroy && (
                            <Stack
                              key={index}
                              sx={{
                                borderRadius: 2,
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.sp_primary_100_main}`,
                                bgcolor: 'sp_primary_100_main',
                                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ px: 3, py: 1 }}
                              >
                                <Typography
                                  variant="SP_form_item_label"
                                  color="main_white"
                                  fontSize={14}
                                  lineHeight="100%"
                                >
                                  {indexes.indexOf(index) + 1}件目
                                </Typography>

                                {index > 0 && (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    onClick={() => {
                                      if (!borrowing.id) {
                                        arrayHelpers.remove(index);
                                      } else {
                                        arrayHelpers.replace(index, {
                                          ...borrowing,
                                          _destroy: true,
                                        });
                                      }
                                      const cloneRepaymentScheduleImage =
                                        cloneDeep(repaymentScheduleImage);
                                      cloneRepaymentScheduleImage.splice(
                                        indexes.indexOf(index),
                                        1
                                      );
                                      setRepaymentScheduleImage(
                                        cloneRepaymentScheduleImage
                                      );
                                    }}
                                  >
                                    <Icons.SPDelete
                                      sx={{ width: 16, height: 19 }}
                                    />
                                    <Typography
                                      variant="SP_label_field"
                                      color="main_white"
                                    >
                                      削除する
                                    </Typography>
                                  </Stack>
                                )}
                              </Stack>

                              {incomeTotalizer && (
                                <SPFormItem
                                  required
                                  label="借入名義人"
                                  labelSx={{ px: 3, py: 1 }}
                                  sx={{ bgcolor: 'main_white' }}
                                  childrenSx={{
                                    bgcolor: 'main_white',
                                    py: 3,
                                    px: 2,
                                  }}
                                >
                                  <SPRadioGroupButton
                                    name={`p_borrowings_attributes[${index}].borrower`}
                                    options={borrowerOptions}
                                    onChange={(checked, value) => {
                                      if (
                                        value ===
                                        BorrowerIncomeType.INCOME_TOTAL
                                      ) {
                                        formik.setFieldValue(
                                          'p_applicant_people_attributes[1].borrowing_status',
                                          BorrowingStatusType.YES
                                        );
                                      }
                                    }}
                                    required
                                  />
                                </SPFormItem>
                              )}

                              <SPFormItem
                                required
                                label="お借入の種類は？"
                                labelSx={{ px: 3, py: 1 }}
                                sx={{ bgcolor: 'main_white' }}
                                childrenSx={{ bgcolor: 'main_white' }}
                              >
                                <SPInputSelect
                                  placeholder="選択してください"
                                  name={`p_borrowings_attributes[${index}].borrowing_type`}
                                  options={borrowingTypeOptions}
                                  sx={{ width: '100%' }}
                                  useFastField={false}
                                  onChange={(e) => {
                                    arrayHelpers.replace(index, {
                                      ...(borrowing.id && {
                                        id: borrowing.id,
                                      }),
                                      borrower: borrowing.borrower,
                                      borrowing_type: e.target.value,
                                      self_input: false,
                                      lender: '',
                                      borrowing_from_housing_finance_agency:
                                        BorrowingFromHousingFinanceAgencyType.DEFAULT,
                                      loan_start_date: '',
                                      loan_amount: '',
                                      current_balance_amount: '',
                                      annual_repayment_amount: '',
                                      loan_deadline_date: '',
                                      scheduled_loan_payoff:
                                        SPScheduledLoanPayoffType.DEFAULT,
                                      loan_purpose: SPLoanPurposeType.DEFAULT,
                                      other_purpose: '',
                                      business_borrowing_type:
                                        BusinessBorrowingType.DEFAULT,
                                      specific_loan_purpose: '',
                                      borrowing_category:
                                        SPBorrowingCategoryType.DEFAULT,
                                      card_expiry_date: '',
                                      rental_room_number: '',
                                      common_housing: CommonHousingType.DEFAULT,
                                      estate_mortgage:
                                        EstateMortgageType.DEFAULT,
                                      expected_repayment_date: '',
                                      ...(!!borrowing.repayment_schedule_image && {
                                        repayment_schedule_image:
                                          borrowing.repayment_schedule_image,
                                      }),
                                      ...(borrowing._destroy !== undefined && {
                                        _destroy: borrowing._destroy,
                                      }),
                                    });
                                  }}
                                />
                              </SPFormItem>
                              <Stack
                                sx={{ bgcolor: 'sp_primary_40', mt: -5 }}
                                justifyContent="center"
                              >
                                <Typography
                                  variant="upload_image_title"
                                  sx={{
                                    py: 1,
                                    px: 3,
                                    whiteSpace: 'break-spaces',
                                  }}
                                  color="b_333"
                                  textAlign="left"
                                >
                                  {`①返済予定表・利用明細等の画像をアップロードするか、\n②詳細を入力してください。`}
                                </Typography>
                              </Stack>
                              <Stack
                                sx={{
                                  bgcolor: 'main_white',
                                  borderBottomLeftRadius: formik.values
                                    .p_borrowings_attributes[index]?.self_input
                                    ? 0
                                    : 8,
                                  borderBottomRightRadius: formik.values
                                    .p_borrowings_attributes[index]?.self_input
                                    ? 0
                                    : 8,
                                  px: 3,
                                }}
                              >
                                <SPUploadImageItem
                                  variant="imageOnly"
                                  title={`①返済予定表・利用明細等の画像をアップロードするか、\n②詳細を入力してください。`}
                                  images={
                                    repaymentScheduleImage[
                                      indexes.indexOf(index)
                                    ] ?? []
                                  }
                                  setImages={(images) =>
                                    setRepaymentScheduleImage((prevImages) => [
                                      ...prevImages.slice(0, index),
                                      images,
                                      ...prevImages.slice(index + 1),
                                    ])
                                  }
                                  // Check cho phep chi nhap anh hoac input form
                                  // disable={
                                  //   formik.values.p_borrowings_attributes[index]
                                  //     .self_input
                                  // }
                                  reload={reset}
                                  handleDeleteImage={(id) => {
                                    const repayment_schedule_image =
                                      convertImage(
                                        formik.values.p_borrowings_attributes[
                                          index
                                        ].repayment_schedule_image,
                                        id
                                      );
                                    formik.setFieldValue(
                                      `p_borrowings_attributes[${index}].repayment_schedule_image`,
                                      repayment_schedule_image
                                    );
                                  }}
                                />

                                <SPCheckboxButton
                                  name={`p_borrowings_attributes[${index}].self_input`}
                                  noBorder
                                  sx={{
                                    borderRadius: 2,
                                    bgcolor: 'main_white',
                                    ml: -3,
                                  }}
                                  //Check cho phep chi nhap anh hoac input form
                                  // disabled={
                                  //   repaymentScheduleImage[index]?.length > 0
                                  // }
                                  disabled={
                                    formik.values.p_borrowings_attributes[index]
                                      .borrowing_type === ''
                                  }
                                  onClick={(value) => {
                                    if (!value) {
                                      arrayHelpers.replace(index, {
                                        ...borrowing,
                                        ...(borrowing.id && {
                                          id: borrowing.id,
                                        }),
                                        self_input: false,
                                        lender: '',
                                        borrowing_from_housing_finance_agency:
                                          BorrowingFromHousingFinanceAgencyType.DEFAULT,
                                        loan_start_date: '',
                                        loan_amount: '',
                                        current_balance_amount: '',
                                        annual_repayment_amount: '',
                                        loan_deadline_date: '',
                                        scheduled_loan_payoff:
                                          SPScheduledLoanPayoffType.DEFAULT,
                                        loan_purpose: SPLoanPurposeType.DEFAULT,
                                        other_purpose: '',
                                        business_borrowing_type:
                                          BusinessBorrowingType.DEFAULT,
                                        specific_loan_purpose: '',
                                        borrowing_category:
                                          SPBorrowingCategoryType.DEFAULT,
                                        card_expiry_date: '',
                                        rental_room_number: '',
                                        common_housing:
                                          CommonHousingType.DEFAULT,
                                        estate_mortgage:
                                          EstateMortgageType.DEFAULT,
                                        expected_repayment_date: '',
                                        ...(!!borrowing.repayment_schedule_image && {
                                          repayment_schedule_image:
                                            borrowing.repayment_schedule_image,
                                        }),
                                        ...(borrowing._destroy !==
                                          undefined && {
                                          _destroy: borrowing._destroy,
                                        }),
                                      });
                                    }
                                  }}
                                >
                                  <Typography
                                    variant="SP_multiple_checkbox_label"
                                    color="sp_primary_100_main"
                                  >
                                    アップロードせず、詳細入力する
                                  </Typography>
                                </SPCheckboxButton>
                              </Stack>
                              {formik.values.p_borrowings_attributes[index]
                                .self_input && (
                                <Fragment>
                                  <SPFormItem
                                    required
                                    label="借入先（金融機関）"
                                    labelSx={{ px: 3, py: 1 }}
                                    sx={{ bgcolor: 'main_white' }}
                                    childrenSx={{ bgcolor: 'main_white' }}
                                  >
                                    <SPInputField
                                      placeholder="例：○○○○銀行"
                                      name={`p_borrowings_attributes[${index}].lender`}
                                      sx={{ width: '100%' }}
                                      convertFullWidth
                                    />
                                  </SPFormItem>
                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type ===
                                    SPBorrowingType.CARD_LOAN && (
                                    <SPFormItem
                                      required
                                      label="お借入の目的"
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{ bgcolor: 'main_white' }}
                                      childrenSx={{ bgcolor: 'main_white' }}
                                    >
                                      <SPInputSelect
                                        placeholder="選択してください"
                                        name={`p_borrowings_attributes[${index}].loan_purpose`}
                                        options={loanPurposeOptions}
                                        sx={{ width: '100%' }}
                                        onChange={(e) => {
                                          if (
                                            e.target.value !==
                                            SPLoanPurposeType.OTHERS
                                          ) {
                                            formik.setFieldValue(
                                              `p_borrowings_attributes[${index}].other_purpose`,
                                              ''
                                            );
                                          }
                                        }}
                                      />
                                      {formik.values.p_borrowings_attributes[
                                        index
                                      ].loan_purpose ===
                                        SPLoanPurposeType.OTHERS && (
                                        <Stack sx={{ pt: '10px' }} spacing={1}>
                                          <Typography variant="SP_form_item_note">
                                            ※その他の方は詳細を入力ください。
                                          </Typography>
                                          <SPInputField
                                            placeholder="入力してください"
                                            name={`p_borrowings_attributes[${index}].other_purpose`}
                                            sx={{ width: '100%' }}
                                            convertFullWidth
                                          />
                                        </Stack>
                                      )}
                                    </SPFormItem>
                                  )}
                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type ===
                                    SPBorrowingType.BUSINESS_LOAN && (
                                    <SPFormItem
                                      required
                                      label="お借入の目的"
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{ bgcolor: 'main_white' }}
                                      childrenSx={{ bgcolor: 'main_white' }}
                                    >
                                      <SPInputSelect
                                        placeholder="選択してください"
                                        name={`p_borrowings_attributes[${index}].business_borrowing_type`}
                                        options={businessBorrowingTypeOptions}
                                        sx={{ width: '100%' }}
                                        onChange={(e) => {
                                          if (
                                            e.target.value !==
                                            SPLoanPurposeType.OTHERS
                                          ) {
                                            formik.setFieldValue(
                                              `p_borrowings_attributes[${index}].specific_loan_purpose`,
                                              ''
                                            );
                                          }
                                        }}
                                      />
                                      {formik.values.p_borrowings_attributes[
                                        index
                                      ].business_borrowing_type ===
                                        BusinessBorrowingType.OTHERS && (
                                        <Stack sx={{ pt: '10px' }} spacing={1}>
                                          <Typography variant="SP_form_item_note">
                                            ※その他の方は詳細を入力ください。
                                          </Typography>
                                          <SPInputField
                                            placeholder="入力してください"
                                            name={`p_borrowings_attributes[${index}].specific_loan_purpose`}
                                            sx={{ width: '100%' }}
                                            convertFullWidth
                                          />
                                        </Stack>
                                      )}
                                    </SPFormItem>
                                  )}
                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type ===
                                    SPBorrowingType.CARD_LOAN && (
                                    <SPFormItem
                                      required
                                      label="借入区分"
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{ bgcolor: 'main_white' }}
                                      childrenSx={{ bgcolor: 'main_white' }}
                                    >
                                      <SPRadioGroupButton
                                        name={`p_borrowings_attributes[${index}].borrowing_category`}
                                        options={borrowingCategoryOptions}
                                      />
                                    </SPFormItem>
                                  )}
                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type ===
                                    SPBorrowingType.HOUSING_LOAN && (
                                    <SPFormItem
                                      required
                                      label="住宅金融支援機構からの借入ですか？"
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{ bgcolor: 'main_white', mt: -5 }}
                                      childrenSx={{ bgcolor: 'main_white' }}
                                    >
                                      <SPRadioGroupButton
                                        name={`p_borrowings_attributes[${index}].borrowing_from_housing_finance_agency`}
                                        options={
                                          borrowingFromHousingFinanceAgencyOptions
                                        }
                                        display="row"
                                      />
                                    </SPFormItem>
                                  )}
                                  <SPFormItem
                                    required
                                    label={
                                      formik.values.p_borrowings_attributes[
                                        index
                                      ].borrowing_type ===
                                      SPBorrowingType.CARD_LOAN
                                        ? '当初カード契約年月'
                                        : '当初借入年月'
                                    }
                                    labelSx={{ px: 3, py: 1 }}
                                    sx={{ bgcolor: 'main_white', mt: -5 }}
                                    childrenSx={{ bgcolor: 'main_white' }}
                                  >
                                    <SPInputDate
                                      name={`p_borrowings_attributes[${index}].loan_start_date`}
                                      selectWidth="163px"
                                      checkRequired
                                      whiteSpace="normal"
                                    />
                                  </SPFormItem>
                                  <SPFormItem
                                    required
                                    label={
                                      formik.values.p_borrowings_attributes[
                                        index
                                      ].borrowing_type ===
                                      SPBorrowingType.CARD_LOAN
                                        ? '借入限度額'
                                        : '当初借入額'
                                    }
                                    labelSx={{ px: 3, py: 1 }}
                                    sx={{ bgcolor: 'main_white', mt: -5 }}
                                    childrenSx={{ bgcolor: 'main_white' }}
                                  >
                                    <Stack direction="row" alignItems="center">
                                      <SPInputField
                                        placeholder="0"
                                        name={`p_borrowings_attributes[${index}].loan_amount`}
                                        sx={{ width: '156px' }}
                                        unit="万円"
                                        align="right"
                                        numeric
                                        useNumberFormat
                                        toFixedNumber={false}
                                        maxLength={6}
                                      />
                                    </Stack>
                                  </SPFormItem>
                                  <SPFormItem
                                    required
                                    label="現在の残高"
                                    labelSx={{ px: 3, py: 1 }}
                                    sx={{ bgcolor: 'main_white', mt: -5 }}
                                    childrenSx={{ bgcolor: 'main_white' }}
                                  >
                                    <Stack direction="row" alignItems="center">
                                      <SPInputField
                                        placeholder="0"
                                        name={`p_borrowings_attributes[${index}].current_balance_amount`}
                                        sx={{ width: '156px' }}
                                        unit="万円"
                                        align="right"
                                        numeric
                                        useNumberFormat
                                        toFixedNumber={false}
                                        maxLength={6}
                                      />
                                    </Stack>
                                  </SPFormItem>
                                  <SPFormItem
                                    required
                                    label="年間返済額"
                                    labelSx={{ px: 3, py: 1 }}
                                    sx={{ bgcolor: 'main_white', mt: -5 }}
                                    childrenSx={{ bgcolor: 'main_white' }}
                                  >
                                    <Stack direction="row" alignItems="center">
                                      <SPInputField
                                        placeholder="0"
                                        name={`p_borrowings_attributes[${index}].annual_repayment_amount`}
                                        sx={{ width: '156px' }}
                                        unit="万円"
                                        align="right"
                                        numeric
                                        useNumberFormat
                                        toFixedNumber={false}
                                        maxLength={6}
                                      />
                                    </Stack>
                                  </SPFormItem>

                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type ===
                                  SPBorrowingType.CARD_LOAN ? (
                                    <SPFormItem
                                      required
                                      label="カード有効期限"
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{ bgcolor: 'main_white', mt: -5 }}
                                      childrenSx={{ bgcolor: 'main_white' }}
                                    >
                                      <SPInputDate
                                        name={`p_borrowings_attributes[${index}].card_expiry_date`}
                                        selectWidth="163px"
                                        yearOptions={loanDeadlineYearOptions}
                                        checkRequired
                                        whiteSpace="normal"
                                      />
                                    </SPFormItem>
                                  ) : (
                                    <SPFormItem
                                      required
                                      label={
                                        formik.values.p_borrowings_attributes[
                                          index
                                        ].borrowing_type ===
                                        SPBorrowingType.HOUSING_LOAN
                                          ? '最終期限'
                                          : '最終返済年月'
                                      }
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{ bgcolor: 'main_white', mt: -5 }}
                                      childrenSx={{ bgcolor: 'main_white' }}
                                    >
                                      <SPInputDate
                                        name={`p_borrowings_attributes[${index}].loan_deadline_date`}
                                        selectWidth="163px"
                                        yearOptions={loanDeadlineYearOptions}
                                        checkRequired
                                        whiteSpace="normal"
                                      />
                                    </SPFormItem>
                                  )}
                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type ===
                                    SPBorrowingType.APARTMENT_LOAN && (
                                    <Fragment>
                                      <SPFormItem
                                        required
                                        label="賃貸戸（室）数"
                                        labelSx={{ px: 3, py: 1 }}
                                        sx={{ bgcolor: 'main_white', mt: -5 }}
                                        childrenSx={{ bgcolor: 'main_white' }}
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <SPInputField
                                            placeholder="0"
                                            name={`p_borrowings_attributes[${index}].rental_room_number`}
                                            onKeyPress={(event) =>
                                              onlyNumbers(event)
                                            }
                                            sx={{ width: '156px' }}
                                            unit="戸（室）"
                                            align="right"
                                            maxLength={2}
                                            numeric
                                            useNumberFormat
                                            toFixedNumber={false}
                                          />
                                        </Stack>
                                      </SPFormItem>
                                      <SPFormItem
                                        required
                                        label="共同住宅"
                                        labelSx={{ px: 3, py: 1 }}
                                        sx={{ bgcolor: 'main_white', mt: -5 }}
                                        childrenSx={{ bgcolor: 'main_white' }}
                                      >
                                        <SPRadioGroupButton
                                          name={`p_borrowings_attributes[${index}].common_housing`}
                                          options={commonHousingOptions}
                                          display="row"
                                        />
                                      </SPFormItem>
                                    </Fragment>
                                  )}
                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type !==
                                    SPBorrowingType.HOUSING_LOAN &&
                                    formik.values.p_borrowings_attributes[index]
                                      .borrowing_type !==
                                      SPBorrowingType.CARD_LOAN && (
                                      <SPFormItem
                                        required
                                        label="不動産担保設定"
                                        labelSx={{ px: 3, py: 1 }}
                                        sx={{
                                          bgcolor: 'main_white',
                                          mt: -5,
                                          borderBottomLeftRadius: 8,
                                          borderBottomRightRadius: 8,
                                        }}
                                        childrenSx={{
                                          pb:
                                            formik.values
                                              .p_borrowings_attributes[index]
                                              .borrowing_type !==
                                              SPBorrowingType.HOUSING_LOAN &&
                                            formik.values
                                              .p_borrowings_attributes[index]
                                              .borrowing_type !==
                                              SPBorrowingType.CARD_LOAN &&
                                            formik.values
                                              .p_borrowings_attributes[index]
                                              .borrowing_type !==
                                              SPBorrowingType.BUSINESS_LOAN
                                              ? 8
                                              : 3,
                                          borderBottomLeftRadius: 8,
                                          borderBottomRightRadius: 8,
                                          bgcolor: 'main_white',
                                        }}
                                      >
                                        <SPRadioGroupButton
                                          name={`p_borrowings_attributes[${index}].estate_mortgage`}
                                          options={estateMortgageOptions}
                                          display="row"
                                        />
                                      </SPFormItem>
                                    )}
                                  {formik.values.p_borrowings_attributes[index]
                                    .borrowing_type !==
                                    SPBorrowingType.BUSINESS_LOAN && (
                                    <SPFormItem
                                      required
                                      label="今回のお借入までに完済の予定はありますか？"
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{
                                        bgcolor: 'main_white',
                                        mt: -5,
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                      }}
                                      childrenSx={{
                                        pb:
                                          formik.values.p_borrowings_attributes[
                                            index
                                          ].scheduled_loan_payoff ===
                                          SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
                                            ? 8
                                            : 3,
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                        bgcolor: 'main_white',
                                      }}
                                    >
                                      <SPRadioGroupButton
                                        name={`p_borrowings_attributes[${index}].scheduled_loan_payoff`}
                                        options={scheduledLoanPayoffOptions(
                                          index
                                        )}
                                        onChange={(checked, value) => {
                                          if (
                                            value !==
                                            SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
                                          ) {
                                            formik.setFieldValue(
                                              `p_borrowings_attributes[${index}].expected_repayment_date`,
                                              ''
                                            );
                                          }
                                          if (
                                            formik.values.p_borrowings_attributes
                                              .filter(
                                                (borrowing, idx) =>
                                                  idx !== index
                                              )
                                              .every(
                                                (borrowing) =>
                                                  borrowing.scheduled_loan_payoff !==
                                                  SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
                                              ) &&
                                            value !==
                                              SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
                                          ) {
                                            resetMCJStepFive();
                                          }
                                        }}
                                      />
                                    </SPFormItem>
                                  )}
                                  {formik.values.p_borrowings_attributes[index]
                                    .scheduled_loan_payoff ===
                                    SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY && (
                                    <SPFormItem
                                      required
                                      label="完済（予定）年月"
                                      labelSx={{ px: 3, py: 1 }}
                                      sx={{
                                        bgcolor: 'main_white',
                                        mt: -5,
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                      }}
                                      childrenSx={{
                                        borderBottomLeftRadius: 8,
                                        borderBottomRightRadius: 8,
                                        bgcolor: 'main_white',
                                      }}
                                    >
                                      <SPInputDate
                                        name={`p_borrowings_attributes[${index}].expected_repayment_date`}
                                        selectWidth="163px"
                                        yearOptions={
                                          expectedRepaymenYearOptions
                                        }
                                        checkRequired
                                      />
                                    </SPFormItem>
                                  )}
                                </Fragment>
                              )}
                            </Stack>
                          )
                        );
                      }
                    )}
                    {(formik.values.p_borrowings_attributes[
                      formik.values.p_borrowings_attributes.length - 1
                    ]?.self_input ||
                      !!repaymentScheduleImage[
                        repaymentScheduleImage.length - 1
                      ]?.length) &&
                      formik.values.p_borrowings_attributes.length < 8 && (
                        <Fragment>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                          >
                            <Typography
                              variant="SP_form_item_note"
                              color="b_333"
                            >
                              ※
                            </Typography>
                            <Typography
                              variant="SP_form_item_note"
                              color="b_333"
                            >
                              他にも借入がある場合は、追加してください。
                            </Typography>
                          </Stack>
                          <SPButton
                            sx={{ bgcolor: 'sp_primary_100_main' }}
                            onClick={() => {
                              arrayHelpers.push({
                                borrower: BorrowerIncomeType.DEFAULT,
                                borrowing_type: SPBorrowingType.DEFAULT,
                                self_input: false,
                                lender: '',
                                borrowing_from_housing_finance_agency:
                                  BorrowingFromHousingFinanceAgencyType.DEFAULT,
                                loan_start_date: '',
                                loan_amount: '',
                                current_balance_amount: '',
                                annual_repayment_amount: '',
                                loan_deadline_date: '',
                                scheduled_loan_payoff:
                                  SPScheduledLoanPayoffType.DEFAULT,
                                loan_purpose: SPLoanPurposeType.DEFAULT,
                                other_purpose: '',
                                business_borrowing_type:
                                  BusinessBorrowingType.DEFAULT,
                                specific_loan_purpose: '',
                                borrowing_category:
                                  SPBorrowingCategoryType.DEFAULT,
                                card_expiry_date: '',
                                rental_room_number: '',
                                common_housing: CommonHousingType.DEFAULT,
                                estate_mortgage: EstateMortgageType.DEFAULT,
                                expected_repayment_date: '',
                              });
                              setRepaymentScheduleImage((prevImages) => [
                                ...prevImages,
                                [],
                              ]);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Icons.SPCircle
                                sx={{
                                  width: '32px',
                                  height: '32px',
                                  color: 'main_white',
                                }}
                              />
                              <Typography variant="SP_multiple_checkbox_label">
                                もう1件追加する
                              </Typography>
                            </Stack>
                          </SPButton>
                        </Fragment>
                      )}
                  </Fragment>
                )}
              />
            )}
          </Stack>
        </SPFormItem>
        {isMCJ &&
          formik.values.p_applicant_people_attributes[0].borrowing_status ===
            BorrowingStatusType.YES &&
          formik.values.p_borrowings_attributes.some(
            (borrowing) =>
              borrowing.scheduled_loan_payoff ===
                SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY &&
              !borrowing._destroy
          ) && (
            <Fragment>
              <SPFormItem
                label={
                  <Typography
                    justifyContent="center"
                    variant="SP_form_item_label"
                    color="b_333"
                  >
                    完済予定のお借入がある場合の完済原資について教えてください。
                    <Typography variant="sp_note_text" color="b_333">
                      （MCJ固有項目）
                    </Typography>
                  </Typography>
                }
                required
              >
                <Stack sx={{ px: 2, pt: 3 }} spacing={6}>
                  <Stack spacing="10px">
                    <Typography variant="buttonAddImage" color="b_333">
                      完済原資の種類
                    </Typography>
                    <SPRadioGroupButton
                      name="completely_repayment_type"
                      options={completelyRepaymentTypeOptions}
                      onChange={(checked, values) => {
                        if (values !== FundsPairOffType.OTHERS) {
                          formik.setFieldValue(
                            'other_complete_repayment_type',
                            ''
                          );
                        }
                      }}
                      handleReClickOption={() => {
                        formik.setFieldValue(
                          'other_complete_repayment_type',
                          ''
                        );
                      }}
                    />
                    {formik.values.completely_repayment_type ===
                      FundsPairOffType.OTHERS && (
                      <Stack sx={{ pt: '10px' }} spacing={1}>
                        <Typography variant="SP_form_item_note">
                          ※その他の方は詳細を入力ください。
                        </Typography>
                        <SPInputField
                          placeholder="入力してください"
                          name="other_complete_repayment_type"
                          sx={{ width: '100%' }}
                          convertFullWidth
                        />
                      </Stack>
                    )}
                  </Stack>

                  <Stack spacing="10px">
                    <Stack direction="row" spacing={2}>
                      <Typography variant="buttonAddImage" color="b_333">
                        完済原資の内容
                      </Typography>
                      <Typography variant="sp_note_text" color="b_333">
                        ※金融機関・預貯金種類など
                      </Typography>
                    </Stack>
                    <SPInputField
                      placeholder="例：〇〇○○銀行 普通預金"
                      name="refund_content"
                      sx={{ width: '100%' }}
                      convertFullWidth
                    />
                  </Stack>
                  <Stack spacing="10px">
                    <Stack direction="row" spacing={2}>
                      <Typography variant="buttonAddImage" color="b_333">
                        完済原資の金額
                      </Typography>
                      <Typography variant="sp_note_text" color="b_333">
                        ※金融機関・預貯金種類など
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <SPInputField
                        placeholder="0"
                        name="refund_amount"
                        sx={{ width: '156px' }}
                        unit="万円"
                        align="right"
                        numeric
                        useNumberFormat
                        toFixedNumber={false}
                        maxLength={5}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </SPFormItem>
              <SPFormItem
                label={
                  <Typography
                    justifyContent="center"
                    variant="SP_form_item_label"
                    color="b_333"
                  >
                    今回の住宅取得後も継続する支払地代・支払家賃があれば記入してください。
                    <Typography variant="sp_note_text" color="b_333">
                      （MCJ固有項目）
                    </Typography>
                  </Typography>
                }
              >
                <Stack
                  sx={{
                    borderRadius: 2,
                    border: (theme) =>
                      `1px solid ${theme?.palette?.sp_primary_100_main}`,
                    bgcolor: 'sp_primary_100_main',
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <SPFormItem
                    label="支払地代"
                    sx={{
                      bgcolor: 'main_white',
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    }}
                    labelSx={{
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    }}
                    required
                  >
                    <Stack spacing={6}>
                      {incomeTotalizer ? (
                        <Fragment>
                          <Stack spacing={`10px`}>
                            <Typography variant="buttonAddImage" color="b_333">
                              支払いをしている方
                            </Typography>
                            <SPRadioGroupButton
                              name="land_rent_to_be_paid_borrower"
                              options={borrowerOptions}
                              required
                            />
                          </Stack>
                          <SPInputField
                            placeholder="0"
                            name="land_rent_to_be_paid"
                            label="月間の支払金額"
                            sx={{ width: '156px' }}
                            unit="円"
                            align="right"
                            numeric
                            useNumberFormat
                            toFixedNumber={false}
                            maxLength={10}
                          />
                        </Fragment>
                      ) : (
                        <SPInputField
                          placeholder="0"
                          name="p_applicant_people_attributes[0].land_rent_to_be_paid"
                          label="月間の支払金額"
                          sx={{ width: '156px' }}
                          unit="円"
                          align="right"
                          numeric
                          useNumberFormat
                          toFixedNumber={false}
                          maxLength={10}
                        />
                      )}
                    </Stack>
                  </SPFormItem>
                  <SPFormItem
                    label="支払家賃"
                    sx={{
                      bgcolor: 'main_white',
                      mt: -5,
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                    childrenSx={{
                      pb: 3,
                      bgcolor: 'main_white',
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                    required
                  >
                    <Stack spacing={6}>
                      {incomeTotalizer ? (
                        <Fragment>
                          <Stack spacing={`10px`}>
                            <Typography variant="buttonAddImage" color="b_333">
                              支払いをしている方
                            </Typography>
                            <SPRadioGroupButton
                              name="house_rent_to_be_paid_borrower"
                              options={borrowerOptions}
                              required
                            />
                          </Stack>
                          <SPInputField
                            placeholder="0"
                            name="house_rent_to_be_paid"
                            label="月間の支払金額"
                            sx={{ width: '156px' }}
                            unit="円"
                            align="right"
                            numeric
                            useNumberFormat
                            toFixedNumber={false}
                            maxLength={10}
                          />
                        </Fragment>
                      ) : (
                        <SPInputField
                          placeholder="0"
                          name="p_applicant_people_attributes[0].house_rent_to_be_paid"
                          label="月間の支払金額"
                          sx={{ width: '156px' }}
                          unit="円"
                          align="right"
                          numeric
                          useNumberFormat
                          toFixedNumber={false}
                          maxLength={10}
                        />
                      )}
                    </Stack>
                  </SPFormItem>
                </Stack>
              </SPFormItem>
            </Fragment>
          )}
        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
          }}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepFivePage;

const borrowingStatusOptions: Array<RadioItems> = [
  {
    value: BorrowingStatusType.YES,
    label: borrowingStatus[BorrowingStatusType.YES],
  },
  {
    value: BorrowingStatusType.NO,
    label: borrowingStatus[BorrowingStatusType.NO],
  },
];

export const borrowingFromHousingFinanceAgencyOptions: Array<RadioItems> = [
  {
    value: BorrowingFromHousingFinanceAgencyType.APPLICABLE,
    label:
      borrowingFromHousingFinanceAgency[
        BorrowingFromHousingFinanceAgencyType.APPLICABLE
      ],
  },
  {
    value: BorrowingFromHousingFinanceAgencyType.NOT_APPLICABLE,
    label:
      borrowingFromHousingFinanceAgency[
        BorrowingFromHousingFinanceAgencyType.NOT_APPLICABLE
      ],
  },
];

const commonHousingOptions: Array<RadioItems> = [
  {
    value: CommonHousingType.APPLICABLE,
    label: commonHousing[CommonHousingType.APPLICABLE],
  },
  {
    value: CommonHousingType.NOT_APPLICABLE,
    label: commonHousing[CommonHousingType.NOT_APPLICABLE],
  },
];

const estateMortgageOptions: Array<RadioItems> = [
  {
    value: EstateMortgageType.SECURED,
    label: estateMortgage[EstateMortgageType.SECURED],
  },
  {
    value: EstateMortgageType.UNSECURED,
    label: estateMortgage[EstateMortgageType.UNSECURED],
  },
];
export const borrowerOptions: Array<RadioItems> = [
  {
    value: BorrowerIncomeType.APPLICANT_SELF,
    label: borrowerIncome[BorrowerIncomeType.APPLICANT_SELF],
  },
  {
    value: BorrowerIncomeType.INCOME_TOTAL,
    label: borrowerIncome[BorrowerIncomeType.INCOME_TOTAL],
  },
];

const loanDeadlineYearOptions: SPInputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(51), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);

const expectedRepaymenYearOptions: SPInputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(5), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    const kanjiDateG2 = kanjidate.format('{G:2}', +year, 1, 1);
    const kanjiDateN = kanjidate.format('{N}', +year, 1, 1);
    return {
      value: year,
      label: `${year}（${kanjiDateG2}${kanjiDateN}）`,
    };
  })
);
