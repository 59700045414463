import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { useCallback, useEffect } from 'react';
import { FormikContextType, FormikState } from 'formik';
import { LoanType } from 'constant';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import { convertToNumber, showLoanTarget } from '../utils';

type Values = AdminPreliminaries | SalePersonPreliminaries;

export const useFinancingPlan = (
  values: Values,
  setFieldValue: FormikContextType<Values>['setFieldValue'],
  touched: FormikState<Values>['touched'],
  isFinancingPlan: boolean
) => {
  const location = useLocation();
  const handleResetValue = useCallback(
    (key: string, defaultValue: string | number | undefined) => {
      if (get(values, key) === undefined) return;
      return setFieldValue(key, defaultValue);
    },
    [setFieldValue, values]
  );

  useEffect(() => {
    if (
      !showLoanTarget.house_purchase_price.includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.house_purchase_price', '0');
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (!values.p_application_header.is_home_loan_plus) {
      handleResetValue(
        'p_application_header.require_funds_breakdown_mortgage',
        '0'
      );
    }
  }, [values.p_application_header.is_home_loan_plus]);

  useEffect(() => {
    if (
      !showLoanTarget.land_purchase_price.includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.land_purchase_price', '0');
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      !showLoanTarget.accessory_cost.includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.accessory_cost', '0');
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      !showLoanTarget.refinancing_loan_balance.includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.refinancing_loan_balance', '0');
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (
      !showLoanTarget.house_upgrade_cost.includes(
        values.p_application_header.loan_target
      )
    ) {
      handleResetValue('p_application_header.house_upgrade_cost', '0');
    }
  }, [values.p_application_header.loan_target]);

  useEffect(() => {
    if (values.p_application_header.loan_type !== LoanType.TWO) {
      handleResetValue('p_application_header.pair_loan_amount', '');
    }
  }, [values.p_application_header.loan_type]);

  useEffect(() => {
    if (isFinancingPlan && location.pathname.startsWith('/manager')) {
      handleResetValue(
        'p_application_header.saving_amount',
        String(
          convertToNumber(values.p_application_header.deposit_savings_1) +
            convertToNumber(
              values.p_application_header.real_estate_sale_price
            ) +
            convertToNumber(values.p_application_header.other_saving_amount)
        )
      );
    }
  }, [
    values.p_application_header.deposit_savings_1,
    values.p_application_header.real_estate_sale_price,
    values.p_application_header.other_saving_amount,
    isFinancingPlan,
    handleResetValue,
    location.pathname,
  ]);
};
