import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep10Checked: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="23"
        height="22"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3699 13.9949C10.2324 14.1281 10.0176 14.1281 9.88008 13.9949L7.13008 11.2449C6.99688 11.1074 6.99688 10.8926 7.13008 10.7551C7.26758 10.6219 7.48242 10.6219 7.61992 10.7551L10.125 13.2645L15.3801 8.00508C15.5176 7.87188 15.7324 7.87188 15.8699 8.00508C16.0031 8.14258 16.0031 8.35742 15.8699 8.49492L10.3699 13.9949ZM22.5 11C22.5 17.0758 17.5758 22 11.5 22C5.42422 22 0.5 17.0758 0.5 11C0.5 4.92422 5.42422 0 11.5 0C17.5758 0 22.5 4.92422 22.5 11ZM11.5 0.6875C5.80664 0.6875 1.1875 5.30664 1.1875 11C1.1875 16.6934 5.80664 21.3125 11.5 21.3125C17.1934 21.3125 21.8125 16.6934 21.8125 11C21.8125 5.30664 17.1934 0.6875 11.5 0.6875Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
