import { RootState } from 'types';
import { createSelector } from 'reselect';
import { convertYenToMan } from 'utils';
import { CODEBANK, LoanType } from 'constant';

export const spStepOneSelector = (state: RootState) => ({
  ...state.sp_step_one.form,
  p_application_header: {
    ...state.sp_step_one.form.p_application_header,
    p_applicant_people_attributes:
      state.sp_step_one.form.p_application_header
        .p_applicant_people_attributes[0],
  },
  status: state.sp_step_one.status,
});

export const spStepOneApplicantPeopleSelector = (state: RootState) =>
  state.sp_step_one.form.p_application_header.p_applicant_people_attributes;

export const convertSPStepOneSelector = createSelector(
  spStepOneSelector,
  (input) => {
    const [
      temporary_desired_loan_amount,
      halfyear_bonus,
      second_temporary_desired_loan_amount,
      second_halfyear_bonus,
    ] = convertYenToMan([
      input.p_application_header.p_borrowing_details_attributes[0]
        ?.temporary_desired_loan_amount,
      input.p_application_header.p_borrowing_details_attributes[0]
        ?.halfyear_bonus,
      input.p_application_header.p_borrowing_details_attributes[1]
        ?.temporary_desired_loan_amount,
      input.p_application_header.p_borrowing_details_attributes[1]
        ?.halfyear_bonus,
    ]);
    return {
      temporary_desired_loan_amount,
      halfyear_bonus,
      second_temporary_desired_loan_amount,
      second_halfyear_bonus,
    };
  }
);

export const checkMCJ = createSelector(spStepOneSelector, (input) => {
  const selectedCodes = input.master_bank_codes;
  return selectedCodes.includes(CODEBANK.MCJ);
});

export const checkIncomeTotalizer = createSelector(
  spStepOneSelector,
  (input) => {
    const selectedIncome = input.p_application_header.loan_type;
    return (
      selectedIncome === LoanType.THREE || selectedIncome === LoanType.FOUR
    );
  }
);

export const checkHasJoinGuarantor = createSelector(
  spStepOneSelector,
  (input) =>
    !!+input.p_application_header.p_applicant_people_attributes
      .has_join_guarantor
);
