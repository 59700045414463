import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { SPStepLayout } from 'containers';
import { ArrowForwardIos } from '@mui/icons-material';
import { theme } from 'styles';
import { background } from 'assets';
import { supportDvh } from 'utils';

const SPStartScreenPage: FC = () => {
  const navigate = useNavigate();

  return (
    <SPStepLayout
      hasHeader={false}
      hasMenu={false}
      hasFooter={false}
      hasStepBar={false}
      hasModalSaveDraft={false}
      sx={{ minHeight: supportDvh('100dvh') }}
    >
      <Stack
        sx={{
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: supportDvh('calc(100dvh - 158px)') }}
        >
          <Icons.SPLogoStart sx={{ width: 278, height: 245 }} />
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.05)',
            bgcolor: 'main_white',
            py: 4,
            px: 6,
          }}
          spacing={4}
        >
          <Button
            onClick={() => {
              navigate(routeNames.SPScreenEmail.path);
            }}
            sx={{
              bgcolor: 'sp_primary_100_main',
              boxShadow: '0 0px 15px rgba(60, 72, 196, 0.1)',
              borderRadius: '14px',
              minHeight: '54px',
              height: '54px',
              width: '327px',
            }}
          >
            <Typography variant="sp_save_draft_text" color="main_white">
              新規登録
            </Typography>
            <ArrowForwardIos
              sx={{
                color: 'main_white',
                position: 'absolute',
                right: '22px',
                bottom: '22px',
                fontSize: '11px',
              }}
            />
          </Button>
          <Button
            onClick={() => {
              navigate(routeNames.Login.path);
            }}
            sx={{
              bgcolor: 'main_white',
              boxShadow: '0 0px 15px rgba(60, 72, 196, 0.1)',
              border: () => `1px solid ${theme?.palette?.sp_primary_100_main}`,
              borderRadius: '14px',
              minHeight: '54px',
              height: '54px',
              width: '327px',
            }}
          >
            <Typography
              variant="sp_save_draft_text"
              color="sp_primary_100_main"
            >
              ログイン
            </Typography>
            <ArrowForwardIos
              sx={{
                color: 'sp_primary_100_main',
                position: 'absolute',
                right: '22px',
                bottom: '22px',
                fontSize: '11px',
              }}
            />
          </Button>
        </Stack>
      </Stack>
    </SPStepLayout>
  );
};

export default SPStartScreenPage;
