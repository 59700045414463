import { Stack, Typography } from '@mui/material';
import { Button, Icons, SalePersonPreliminariesTable } from 'components';
import {
  FILTER_MODAL_TYPE,
  PreliminariesStatus,
  PreliminaryStatus,
  ROWS_PER_PAGE,
} from 'constant';
import { AdminLayout, FilterCurrentModal } from 'containers';
import { useAppDispatch } from 'hooks';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  AssignSalePersonRequest,
  AssignStoreNameRequest,
  SalePersonPreliminariesItem,
  SalePersonPreliminariesItemRequest,
} from 'services';
import { HeadCellProps, OrderType } from 'types';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { isAzureLoginSelector } from 'containers/AuthModal/selectors';
import {
  activeTabSelector,
  currentSalePersonPreliminariesSelectors,
  totalSalePersonPreliminarySelectors,
} from './selectors';
import { revertSalePersonPreliminary, setActiveTab } from './slice';
import {
  assignSalePersons,
  assignStoreName,
  getCurrentSalePersonPreliminariesItems,
  getExportExcelSalePerson,
} from './thunk';

const initialValuesFilterSalePerson: SalePersonPreliminariesItemRequest = {
  preliminary_status: '',
  master_bank_codes: [],
  applicant_name: '',
  apply_date_range: {
    from: '',
    to: '',
  },
  loan_amount_range: {
    from: '',
    to: '',
  },
  branch_names: [],
  office_names: [],
  exhibition_hall_names: [],
  sale_person_names: [],
};

const SalePersonPreliminariesPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAzureLogin = useSelector(isAzureLoginSelector);
  const activeTab = useSelector(activeTabSelector);
  const [order, setOrder] = useState<OrderType>('');
  const [orderBy, setOrderBy] = useState<keyof SalePersonPreliminariesItem>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [dataFilter, setDataFilter] =
    useState<SalePersonPreliminariesItemRequest>(initialValuesFilterSalePerson);

  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: 1,
    fetching: false,
  });
  const currentPreliminary = useSelector(
    currentSalePersonPreliminariesSelectors
  );
  const totalPreliminary = useSelector(totalSalePersonPreliminarySelectors);

  const preliminaryStatus = useMemo(() => {
    if (activeTab === PreliminariesStatus.INPROGRESS) {
      return PreliminaryStatus.PROGRESS_PRELIMINARY;
    } else if (activeTab === PreliminariesStatus.PAST) {
      return PreliminaryStatus.PAST_PRELIMINARY;
    }
    return PreliminaryStatus.PROVISIONAL_PRELIMINARY;
  }, [activeTab]);

  useLayoutEffect(() => {
    (async () => {
      dispatch(revertSalePersonPreliminary());
      setLoading(true);
      await dispatch(
        getCurrentSalePersonPreliminariesItems({
          preliminary_status:
            preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
        })
      );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
      maxPage: Math.ceil(totalPreliminary / ROWS_PER_PAGE),
    }));
  }, [totalPreliminary]);

  const handleGetSaleCurrentPreliminaries = useCallback(async () => {
    dispatch(revertSalePersonPreliminary());
    setLoading(true);
    await dispatch(setActiveTab(PreliminariesStatus.CURRENT));
    setDataFilter({});
    setOrderBy(undefined);
    setOrder('');
    await dispatch(
      getCurrentSalePersonPreliminariesItems({
        preliminary_status: PreliminaryStatus.PROVISIONAL_PRELIMINARY,
      })
    );
    setLoading(false);
  }, [dispatch]);

  const handleGetSaleInProgressPreliminaries = useCallback(async () => {
    dispatch(revertSalePersonPreliminary());
    setLoading(true);
    await dispatch(setActiveTab(PreliminariesStatus.INPROGRESS));
    setDataFilter({});
    setOrderBy(undefined);
    setOrder('');
    await dispatch(
      getCurrentSalePersonPreliminariesItems({
        preliminary_status: PreliminaryStatus.PROGRESS_PRELIMINARY,
      })
    );
    setLoading(false);
  }, [dispatch]);

  const handleGetSalePastPreliminaries = useCallback(async () => {
    dispatch(revertSalePersonPreliminary());
    setLoading(true);
    await dispatch(setActiveTab(PreliminariesStatus.PAST));
    setDataFilter({});
    setOrderBy(undefined);
    setOrder('');
    await dispatch(
      getCurrentSalePersonPreliminariesItems({
        preliminary_status: PreliminaryStatus.PAST_PRELIMINARY,
      })
    );
    setLoading(false);
  }, [dispatch]);

  const handleRequestSort = useCallback(
    async (
      event: MouseEvent<unknown>,
      property: keyof SalePersonPreliminariesItem
    ) => {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      setOrderBy(property);
      dispatch(revertSalePersonPreliminary());
      setLoading(true);
      setPagination((prev) => ({
        ...prev,
        page: 1,
        fetching: true,
      }));

      await dispatch(
        getCurrentSalePersonPreliminariesItems({
          ...dataFilter,
          preliminary_status:
            preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
          order_key: property,
          order_sort: newOrder,
        })
      );
      setOrder(newOrder);
      setPagination((prev) => ({ ...prev, fetching: false }));
      setLoading(false);
    },
    [dataFilter, dispatch, order, preliminaryStatus]
  );

  const handleFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const handleExportExcel = useCallback(() => {
    dispatch(
      getExportExcelSalePerson({
        preliminary_status:
          preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
      })
    );
  }, [dispatch, preliminaryStatus]);

  const onAssignSalesPerson = useCallback(
    async ({ id, s_sale_person_id }: AssignSalePersonRequest) => {
      const result = await dispatch(
        assignSalePersons({ id, s_sale_person_id })
      );
      if (assignSalePersons.fulfilled.match(result)) {
        toast.success('この申し込みに担当者をアサインしました。');
      }
    },
    [dispatch]
  );

  const onAssignStoreName = useCallback(
    async ({ id, p_referral_agency_id }: AssignStoreNameRequest) => {
      const result = await dispatch(
        assignStoreName({ id, p_referral_agency_id })
      );
      if (assignStoreName.fulfilled.match(result)) {
        toast.success('エリアを変更しました。');
      }
    },
    [dispatch]
  );

  const handleChangePage = useCallback(
    async (
      page: number,
      dataFilterInFirst?: SalePersonPreliminariesItemRequest
    ) => {
      setPagination((prev) => ({
        ...prev,
        page: page,
        fetching: true,
      }));
      const dataFilterRequest = !!dataFilterInFirst
        ? dataFilterInFirst
        : dataFilter;
      await dispatch(
        getCurrentSalePersonPreliminariesItems({
          ...dataFilterRequest,
          preliminary_status:
            preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
          page: page,
          order_key: orderBy,
          order_sort: order,
        })
      );
      setPagination((prev) => ({ ...prev, fetching: false }));
    },
    [dataFilter, dispatch, order, orderBy, preliminaryStatus]
  );

  const updateDataFilter = useCallback(
    async (dataFilter: SalePersonPreliminariesItemRequest) => {
      setLoading(true);
      setDataFilter(dataFilter);
      await dispatch(revertSalePersonPreliminary());
      await handleChangePage(1, dataFilter);
      setLoading(false);
      setShowFilter(false);
    },
    [dispatch, handleChangePage]
  );

  useInfiniteScroll({
    callback: (page) => handleChangePage(page),
    pagination: pagination,
    id: 'preliminary-table-body',
  });

  return (
    <>
      <AdminLayout
        loading={loading}
        hasDrawer={false}
        header={{
          left: {
            mainTitle: (
              <Stack direction="row" spacing={4}>
                <Typography
                  variant="text_steps"
                  color={'normal_text'}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  申込一覧
                </Typography>
                <Stack
                  spacing={1}
                  alignItems="center"
                  direction="row"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(routeNames.SalePersonDocumentUpload.path)
                  }
                >
                  <Icons.AddFile sx={{ width: 14, height: 16 }} />
                  <Typography
                    variant="text_steps"
                    color={'sp_primary_100_main'}
                  >
                    書類アップロード
                  </Typography>
                </Stack>
              </Stack>
            ),
          },
          children: (
            <Stack direction="row" spacing={2}>
              <Button
                disabled={loading}
                variant="text"
                sx={{
                  borderRadius: 0,
                  pl: 10,
                  pr: 10,
                  color: (theme) => `${theme.palette.sp_primary_100_main}`,
                  boxShadow: 'none',
                  borderBottom:
                    activeTab === PreliminariesStatus.CURRENT
                      ? (theme) =>
                          `3px solid ${theme.palette.sp_primary_100_main}`
                      : 'none',
                }}
                onClick={handleGetSaleCurrentPreliminaries}
              >
                <Typography
                  variant="style_btn_header"
                  color={
                    activeTab === PreliminariesStatus.CURRENT
                      ? 'b_333'
                      : 'sp_primary_100_main'
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight:
                      activeTab === PreliminariesStatus.CURRENT ? 600 : 400,
                    textWrap: 'nowrap',
                  }}
                >
                  仮審査中の案件
                </Typography>
              </Button>
              <Button
                disabled={loading}
                variant="text"
                sx={{
                  borderRadius: 0,
                  pl: 10,
                  pr: 10,
                  color: (theme) => `${theme.palette.sp_primary_100_main}`,
                  boxShadow: 'none',
                  borderBottom:
                    activeTab === PreliminariesStatus.INPROGRESS
                      ? (theme) =>
                          `3px solid ${theme.palette.sp_primary_100_main}`
                      : 'none',
                }}
                onClick={handleGetSaleInProgressPreliminaries}
              >
                <Typography
                  variant="style_btn_header"
                  color={
                    activeTab === PreliminariesStatus.INPROGRESS
                      ? 'b_333'
                      : 'sp_primary_100_main'
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight:
                      activeTab === PreliminariesStatus.INPROGRESS ? 600 : 400,
                    textWrap: 'nowrap',
                  }}
                >
                  本審査中の案件
                </Typography>
              </Button>
              <Button
                disabled={loading}
                variant="text"
                sx={{
                  borderRadius: 0,
                  pl: 10,
                  pr: 10,
                  color: (theme) => `${theme.palette.sp_primary_100_main}`,
                  boxShadow: 'none',
                  borderBottom:
                    activeTab === PreliminariesStatus.PAST
                      ? (theme) =>
                          `3px solid ${theme.palette.sp_primary_100_main}`
                      : 'none',
                }}
                onClick={handleGetSalePastPreliminaries}
              >
                <Typography
                  variant="style_btn_header"
                  color={
                    activeTab === PreliminariesStatus.PAST
                      ? 'b_333'
                      : 'sp_primary_100_main'
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight:
                      activeTab === PreliminariesStatus.PAST ? 600 : 400,
                    textWrap: 'nowrap',
                  }}
                >
                  過去の案件
                </Typography>
              </Button>
            </Stack>
          ),
          right: {
            contentPopover: (
              <Button
                sx={{
                  py: 0,
                  flex: 1,
                  width: '100%',
                  borderTopLeftRadius: isAzureLogin ? 4 : 0,
                  borderTopRightRadius: isAzureLogin ? 4 : 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  minHeight: 42,
                  height: 42,
                  boxShadow: 'none',
                  bgcolor: 'main_white',
                  justifyContent: 'flex-start',
                  px: '10px',
                  '&.MuiButton-root:hover': {
                    bgcolor: 'sp_primary_100_main',
                    '& .MuiTypography-root': {
                      color: 'main_white',
                    },
                  },
                }}
                onClick={handleExportExcel}
              >
                <Typography variant="auth_popover_text" color="b_333">
                  管理画面をエクスポート
                </Typography>
                <Icons.Excel sx={{ color: 'attention', ml: '9px' }} />
              </Button>
            ),
          },
          showIconHome: false,
        }}
        footer={{
          left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
          right: (
            <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />
          ),
          bgcolor: 'footer_bg',
        }}
      >
        <Stack sx={{ pb: '29px' }}>
          {activeTab === PreliminariesStatus.CURRENT && (
            <SalePersonPreliminariesTable
              onRequestSort={handleRequestSort}
              onFilter={handleFilter}
              order={order}
              orderBy={orderBy}
              rows={currentPreliminary}
              fetching={pagination.fetching}
              onAssignSalesPerson={onAssignSalesPerson}
              onAssignStoreName={onAssignStoreName}
            />
          )}
          {activeTab === PreliminariesStatus.INPROGRESS && (
            <SalePersonPreliminariesTable
              onRequestSort={handleRequestSort}
              onFilter={handleFilter}
              order={order}
              orderBy={orderBy}
              rows={currentPreliminary}
              headCells={headCells}
              fetching={pagination.fetching}
              onAssignSalesPerson={onAssignSalesPerson}
              onAssignStoreName={onAssignStoreName}
            />
          )}
          {activeTab === PreliminariesStatus.PAST && (
            <SalePersonPreliminariesTable
              onRequestSort={handleRequestSort}
              onFilter={handleFilter}
              order={order}
              orderBy={orderBy}
              rows={currentPreliminary}
              fetching={pagination.fetching}
              onAssignSalesPerson={onAssignSalesPerson}
              onAssignStoreName={onAssignStoreName}
            />
          )}
        </Stack>
      </AdminLayout>
      {activeTab === PreliminariesStatus.CURRENT && (
        <FilterCurrentModal
          open={showFilter}
          updateDataFilter={updateDataFilter}
          onClose={() => setShowFilter(false)}
          type={FILTER_MODAL_TYPE.PREVIEW}
        />
      )}
      {activeTab === PreliminariesStatus.PAST && (
        <FilterCurrentModal
          open={showFilter}
          updateDataFilter={updateDataFilter}
          onClose={() => setShowFilter(false)}
          type={FILTER_MODAL_TYPE.PAST}
        />
      )}
    </>
  );
};

export default SalePersonPreliminariesPage;

const headCells: HeadCellProps<SalePersonPreliminariesItem>[] = [
  {
    id: 'application_number',
    label: '受付番号',
    style: { width: 171 },
  },
  {
    id: 'bank_name',
    label: '申込銀行',
    style: {
      justifyContent: 'center',
      width: 180,
      '@media (min-width: 1441px)': { flex: 1 },
    },
  },
  {
    id: 'applicant_name',
    label: '申込人',
    style: {
      justifyContent: 'center',
      width: 200,
      '@media (min-width: 1441px)': { flex: 1 },
    },
  },
  {
    id: 'created_at',
    label: '申込日時',
    style: {
      justifyContent: 'center',
      width: 115,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'loan_desired_borrowing_date',
    label: '実行予定日',
    style: {
      justifyContent: 'center',
      width: 115,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'temporary_desired_loan_amount',
    label: '申込金額',
    style: {
      justifyContent: 'center',
      width: 115,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'provisional_result',
    label: '仮審査結果',
    style: {
      justifyContent: 'center',
      width: 115,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'store_name_kanji',
    label: 'エリア',
    style: {
      justifyContent: 'center',
      width: 135,
      '@media (min-width: 1441px)': {
        width: 160,
      },
    },
  },
  {
    id: 'sale_person_name_kanji',
    label: '営業担当',
    style: {
      justifyContent: 'center',
      width: 135,
      '@media (min-width: 1441px)': {
        width: 160,
      },
    },
  },
  {
    id: 'manager_name_kanji',
    label: '銀代担当',
    style: {
      justifyContent: 'center',
      width: 135,
      '@media (min-width: 1441px)': {
        width: 160,
      },
    },
  },
  {
    id: 'id',
    label: (
      <Icons.Filter
        sx={{
          mt: 4,
          ml: '1px',
          cursor: 'pointer',
          color: 'b_333',
          visibility: 'hidden',
        }}
      />
    ),
    style: {
      pr: '2px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
];
