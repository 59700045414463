import { FC } from 'react';
import { Stack, Typography, Dialog } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'hooks';
import { theme } from 'styles';
import { yup } from 'libs';
import { Button, Icons, InputField } from 'components';
import { createMemo, getMemos, updateMemo } from 'pages/Memo/thunk';
import { useSelector } from 'react-redux';
import { selectedPreliminarySelectors } from 'pages/ManagerPreliminaries/selectors';
import { MemoItem } from 'services';

type MemoModalProps = {
  open: boolean;
  onClose: () => void;
  memo?: MemoItem;
};

export const MemoModal: FC<MemoModalProps> = ({ open, onClose, memo }) => {
  const dispatch = useAppDispatch();
  const { id } = useSelector(selectedPreliminarySelectors);

  const formik = useFormik({
    initialValues: { memo: memo?.memo ?? '' },
    enableReinitialize: true,
    validationSchema: yup.object({}),
    async onSubmit(values) {
      if (memo) {
        const resultUpdate = await dispatch(
          updateMemo({
            preliminaryId: id,
            memoId: memo.id,
            memo: {
              memo: values.memo,
            },
          })
        );
        if (updateMemo.fulfilled.match(resultUpdate)) {
          dispatch(
            getMemos({
              id: id,
            })
          );
          onClose();
          return toast.success('メモの内容を更新しました。');
        } else {
          toast.error('エラーが発生しました。');
        }
      } else {
        const resultCreate = await dispatch(
          createMemo({
            id: id,
            memo: {
              memo: values.memo,
            },
          })
        );
        if (createMemo.fulfilled.match(resultCreate)) {
          dispatch(
            getMemos({
              id: id,
            })
          );
          formik.resetForm();
          onClose();
          return toast.success('メモを作成しました。');
        } else {
          return toast.error('エラーが発生しました。');
        }
      }
    },
  });

  const onCloseDialog = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        onClose={onCloseDialog}
        sx={{
          width: '40%',
          m: 'auto',
          overflowY: 'visible',
          '&& .MuiDialog-paper': {
            minWidth: 660,
            minHeight: 450,
            boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
          },
        }}
        PaperProps={{
          sx: {
            overflowY: 'visible',
          },
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            height: 50,
            borderBottom: '1px solid',
            borderColor: 'gray',
          }}
        >
          <Typography
            variant="heading01"
            color="b_333"
            sx={{ textAlign: 'center', pt: 3 }}
          >
            {memo ? 'メモ詳細' : 'メモ作成'}
          </Typography>
          <Icons.Close
            onClick={onCloseDialog}
            sx={{
              width: '12px',
              height: '12px',
              position: 'absolute',
              top: 18,
              right: 16,
            }}
          />
        </Stack>
        <Stack p={`10px 8px`}>
          <InputField
            multiline
            rows={13}
            name="memo"
            placeholder="連絡する事項を入力してください。"
            InputProps={{
              sx: {
                bgcolor: 'bg_off',
                'textarea::placeholder': {
                  color: 'off_text',
                  ...theme.typography.text_placeholder,
                },
                '&& .MuiInputBase-input': {
                  ...theme.typography.text_placeholder,
                  width: '100%',
                },
                '&&&&&&& .MuiInputBase-input': {
                  borderRadius: 0,
                  height: '390px',
                },
                '&&&&&&&&& fieldset': {
                  borderWidth: 0,
                },
                p: 0,
                height: '100%',
                borderRadius: 0,
              },
            }}
          />
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            bgcolor: 'footer_bg',
            height: 64,
            borderRadius: 1,
          }}
        >
          <Button
            sx={{
              bgcolor: 'main_white',
              width: 200,
              minHeight: 36,
              height: 36,
              border: (theme) => `1px solid ${theme?.palette?.h_blugreen}`,
              borderRadius: 1,
            }}
            disabled={formik.isSubmitting || !formik.values.memo.trim()}
            onClick={() => formik.handleSubmit()}
          >
            <Typography
              variant="annotation01"
              color="sp_primary_100_main"
              sx={{ letterSpacing: '5%', fontWeight: '600' }}
            >
              保存
            </Typography>
          </Button>
        </Stack>
      </Dialog>
    </FormikProvider>
  );
};
