import { MESSAGE_REGEX } from 'constant';
import { regex, yup } from 'libs';

export const resetPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .matches(regex.email, MESSAGE_REGEX.EMAIL)
    .max(128, '128文字以内でご入力ください。')
    .required()
    .label('登録メールアドレス'),
});

export const updatePasswordValidationSchema = yup.object({
  password: yup
    .string()
    .required()
    .min(8)
    .max(20, 'パスワードは20桁以内で設定ください。')
    .matches(
      regex.password,
      'パスワードは大文字、小文字、数字をそれぞれ1つ以上で設定してください。'
    )
    .label('パスワード'),
  password_confirmation: yup
    .string()
    .required()
    .when('password', {
      is: (val: string) => !!val?.length,
      then: yup
        .string()
        .oneOf(
          [yup.ref('password')],
          '新しいパスワードと確認用パスワードが一致しません。'
        ),
    })
    .min(8)
    .label('パスワード（確認用）'),
});

export const nullSchema = yup.object().shape({});
