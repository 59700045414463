import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPBack: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 17" {...props}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.33594 5.37061L6.0026 8.70394L9.33594 12.0373"
          stroke="#6B70F0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
