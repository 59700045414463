import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Drawer, Stack, Typography } from '@mui/material';
import { Button, Icons, SPButton, SPModal } from 'components';
import { hiddenScrollBar } from 'styles/toolkit';
import { useAppDispatch } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from 'containers/AuthModal/thunk';
import { routeNames } from 'navigations/routes';
import { useSelector } from 'react-redux';
import {
  checkMCJ,
  disablePdfSelector,
  displayPdfSelector,
  statusResultSelector,
} from 'containers/AuthModal/selectors';
import { resetAuth } from 'containers/AuthModal/slice';
import { ImageContext } from 'context';
import { isMobile } from 'react-device-detect';
import { spStepTenSelector } from 'pages/SPStepTen/selectors';
import { toast } from 'react-toastify';
import { DisplayPdfStatus, StatusResult } from 'constant';
import { clearSalePerson } from 'libs';

type MenuItem = {
  label: string;
  icon?: ReactNode;
  desc?: string;
  disabled?: boolean | null;
  href?: string;
  show?: boolean;
  onClick?: () => void;
};

export const SPMenu: FC<{
  onSaveForm?: () => void;
}> = ({ onSaveForm }) => {
  const [open, setOpen] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const status_result = useSelector(statusResultSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const disablePdf = useSelector(disablePdfSelector);
  const displayPdf = useSelector(displayPdfSelector);
  const isMCJ = useSelector(checkMCJ);
  const { application_number } = useSelector(spStepTenSelector);
  const handleToggleDrawer = () => {
    setOpen((prevState) => !prevState);
  };

  const menuOptions = useMemo<MenuItem[]>(
    () => [
      {
        label: '審査結果',
        icon: <Icons.SPExaminationOutcome />,
        desc: '審査結果はこちらからご覧いただけます',
        href: routeNames.SPExaminationResult.path,
        show:
          (displayPdf === DisplayPdfStatus.YES ||
            displayPdf === DisplayPdfStatus.DEFAULT) &&
          status_result === StatusResult.DISCLOSURE_RESULTS_TO_APPLICANTS,
        onClick: onSaveForm,
      },
      {
        label: '同意書・確認書等',
        icon: <Icons.SPMenuPdf />,
        desc: 'ご同意いただいている確認書等',
        href: routeNames.SPConsent.path,
        show: true,
        onClick: onSaveForm,
      },

      {
        label: '日本住宅ローン用PDF',
        icon: <Icons.SPPdf />,
        desc: '必要に応じてダウンロードしてください',
        show: !!application_number && isMCJ,
        onClick: () => {
          toast.error('この機能はまだ使用できません。');
          if (onSaveForm) {
            onSaveForm();
          }
        },
      },
      {
        label: 'お役立ちツール',
        icon: <Icons.SPTool />,
        desc: '住宅購入時に役立つツールが満載！',
        show: false,
        onClick: () => {
          toast.error('この機能はまだ使用できません。');
          if (onSaveForm) {
            onSaveForm();
          }
        },
      },
      {
        label: 'アカウント情報',
        icon: <Icons.SPAccountInfomation />,
        desc: 'メールアドレス等の変更や退会',
        show: true,
        onClick: () => {
          if (location.pathname === routeNames.AccountInformation.path) {
            return setOpen(false);
          }
          if (onSaveForm) {
            onSaveForm();
          }
          navigate(routeNames.AccountInformation.path);
        },
      },
      {
        label: 'ログアウト',
        icon: <Icons.SPLogout />,
        show: true,
        onClick: () => {
          setOpenLogout(true);
        },
      },
    ],
    [
      application_number,
      disablePdf,
      displayPdf,
      isMCJ,
      location.pathname,
      navigate,
      onSaveForm,
    ]
  );

  return (
    <Stack>
      <SPLogoutModal open={openLogout} onClose={() => setOpenLogout(false)} />
      {!open && <Icons.SPMenuIcon onClick={handleToggleDrawer} />}
      <Drawer
        anchor="right"
        open={open}
        onClose={handleToggleDrawer}
        sx={{
          '@media (orientation: landscape)': {
            maxWidth: isMobile ? 'none' : 480,
            left: isMobile ? '0' : '50%',
            transform: isMobile ? 'none' : 'translate(-51.5%, 0)',
            '&. MuiBackdrop-root': {
              left: isMobile ? 0 : '50%',
              transform: isMobile ? 'none' : 'translate(-51.5%, 0)',
            },
          },
          maxWidth: 480,
          left: { tablet: '50%' },
          width: '100%',
          transform: { tablet: 'translate(-51.5%, 0)' },
          '&. MuiBackdrop-root': {
            left: { tablet: '50%' },
            transform: { tablet: 'translate(-51.5%, 0)' },
          },
          '& .MuiDrawer-root': {
            position: 'absolute',
          },
          '& .MuiPaper-root': {
            position: 'absolute',
          },
        }}
      >
        <Stack
          sx={{
            height: '100%',
            width: '100vw',
            '@media (orientation: landscape)': {
              maxWidth: isMobile ? 'none' : 480,
            },
            maxWidth: 480,
          }}
        >
          <Stack
            sx={{
              background: 'linear-gradient(180deg, #F9F1F7 0%, #B8D3F3 100%)',
              height: '100%',
              px: 4,
              overflowY: 'auto',
              ...hiddenScrollBar,
            }}
          >
            <Button
              sx={{
                borderRadius: '2px',
                bgcolor: 'transparent',
                minHeight: 44,
                minWidth: 44,
                boxShadow: 'none',
                position: 'absolute',
                top: 0,
                right: 0,
              }}
              onClick={handleToggleDrawer}
            >
              <Stack justifyContent="center" alignItems="center">
                <Icons.Close sx={{ color: 'h_blugreen', fontSize: 18 }} />
              </Stack>
            </Button>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                pt: 15,
                pb: 7,
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Barlow',
                  fontSize: 26,
                  fontWeight: 500,
                  lineHeight: '26px',
                  letterSpacing: 0.6,
                }}
                color="sp_primary_100_main"
              >
                MENU
              </Typography>
            </Stack>
            <Stack flex={1} spacing={4}>
              {menuOptions.map(
                (menuOption, index) =>
                  menuOption.show && (
                    <Button
                      key={index}
                      fullWidth
                      disabled={
                        menuOption.disabled === null
                          ? !menuOption.disabled
                          : !!menuOption.disabled
                      }
                      href={menuOption.href}
                      sx={{
                        minHeight: 46,
                        '&&&': {
                          bgcolor: 'main_white',
                        },
                        p: 0,
                        boxShadow: 'none',
                        borderRadius: '14px',
                        opacity:
                          menuOption.disabled === null || !!menuOption.disabled
                            ? 0.5
                            : 1,
                      }}
                      onClick={menuOption.onClick}
                    >
                      <Stack
                        sx={{
                          px: 4,
                          pt: 3,
                          pb: 4,
                          width: '100%',
                        }}
                        spacing={2}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {menuOption.icon}
                            <Typography
                              variant="drawerText"
                              color="sp_primary_100_main"
                              fontSize={20}
                            >
                              {menuOption.label}
                            </Typography>
                          </Stack>
                          <Icons.SPArrowRight sx={{ width: 16, height: 16 }} />
                        </Stack>
                        {!!menuOption.desc && (
                          <>
                            <Stack
                              sx={{
                                border: '1px dashed',
                                borderColor: 'sp_primary_60',
                              }}
                            />
                            <Typography
                              variant="drawerButtonText"
                              color="sp_primary_100_main"
                              fontSize={14}
                              fontWeight={300}
                              textAlign="left"
                            >
                              {menuOption.desc}
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </Button>
                  )
              )}
            </Stack>

            <Stack sx={{ py: 6 }} spacing={3}>
              <Icons.SPMilize sx={{ width: 148, height: 32 }} />
              <Typography variant="SP_form_item_note" color="b_333">
                お電話でのお問合せは{' '}
                <a href="tel:0120-609-861">
                  <Typography
                    variant="SP_form_item_note"
                    sx={{
                      fontWeight: 500,
                      color: 'sp_primary_100_main',
                      textDecoration: 'underline',
                    }}
                  >
                    0120-609-861
                  </Typography>
                </a>{' '}
                まで
              </Typography>
              <Typography
                variant="SP_form_item_note"
                sx={{
                  whiteSpace: 'break-spaces',
                }}
                color="b_333"
              >
                {`営業時間：10:00~19:00\n休業日　：火曜・水曜日及び年末年始`}
              </Typography>
              <Typography variant="SP_form_item_note" color="sp_logo_icon">
                © 2023 みらいバンク Inc.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
};

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

const SPLogoutModal: FC<ModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { resetImage } = useContext(ImageContext);
  const handleLogout = useCallback(async () => {
    await dispatch(logout());
    clearSalePerson();
    dispatch(resetAuth());
    resetImage();
    navigate(routeNames.StartPage.path);
  }, [dispatch, navigate, resetImage]);

  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title={`ログアウトします。\nよろしいですか？`}
      disableBackDropClick
      footer={
        <>
          <SPButton
            sx={{
              width: '100%',
              bgcolor: 'sp_primary_100_main',
            }}
            endIcon={<Icons.ArrowForward stroke="white" />}
            onClick={handleLogout}
          >
            <Typography variant="SP_multiple_checkbox_label" color="main_white">
              ログアウト
            </Typography>
          </SPButton>
          <SPButton
            sx={{
              width: '100%',
              bgcolor: 'main_white',
              border: '1px solid',
              borderColor: 'sp_primary_100_main',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              もどる
            </Typography>
          </SPButton>
        </>
      }
    />
  );
};
