import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CoOwnerType,
  LoanType,
  MANAGER_ROLE,
  SBI_NAME,
  StatusResult,
} from 'constant';
import { managerLogin, managerLogout } from 'pages/Login/thunk';
import {
  salePersonAzureLogin,
  salePersonLogin,
  salePersonLogout,
} from 'pages/SalePersonLogin/thunk';
import { spGetSubmittedPreliminary, spLogin } from 'pages/SPLogin/thunk';
import { sendEmail, spSignUp } from 'pages/SPScreenEmail/thunk';
import { Preliminaries, PublicHolidayResponse } from 'services';
import { formatTimeForCheckAsync } from 'utils';
import { spGetDraft } from '../GroupButtonSaveInfo/thunk';
import { getPublicHolidays, logout } from './thunk';

type InitialState = {
  id: null | number;
  accessToken: string | null;
  isLoggedIn: boolean;
  draftExits?: boolean;
  managerId: string;
  managerRole: string;
  preliminaries?: Preliminaries[];
  preliminaryId?: string;
  timeGetEdit?: string;
  sbiId: string | undefined;
  isJoinGuarantorInit: boolean;
  isIncomeTotalizerInit: boolean;
  disablePdf: boolean | null;
  displayPdf: string | null;
  master_bank_codes: string[] | null;
  status_result: StatusResult | null;
  salePersonName: string;
  salePersonId: string;
  managerName: string;
  spCurrentStep: string;
  token: string | null;
  examinationFile: {
    id: string;
    filename: string;
    path: string;
  } | null;
  firstTimeLogin: boolean;
  publicHolidays: Record<number, PublicHolidayResponse[]>;
  isStatuses: boolean;
  isAzureLogin: boolean;
};

const initialState: InitialState = {
  id: null,
  accessToken: null,
  isLoggedIn: false,
  draftExits: false,
  managerId: '',
  disablePdf: null,
  displayPdf: null,
  preliminaryId: '',
  timeGetEdit: '',
  master_bank_codes: null,
  status_result: null,
  salePersonName: '',
  salePersonId: '',
  managerName: '',
  managerRole: '',
  spCurrentStep: '1',
  token: null,
  examinationFile: null,
  firstTimeLogin: false,
  publicHolidays: {},
  sbiId: '',
  isJoinGuarantorInit: false,
  isIncomeTotalizerInit: false,
  isStatuses: true,
  isAzureLogin: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => {},
    setCurrentStep: (state, action: PayloadAction<string>) => {
      state.spCurrentStep = action.payload;
    },
    setFirstTimeLogin: (state, action: PayloadAction<boolean>) => {
      state.firstTimeLogin = action.payload;
    },
    updateStatusResult: (state, action: PayloadAction<StatusResult | null>) => {
      state.status_result = action.payload;
    },
    updateIsStatuses: (state, action: PayloadAction<boolean>) => {
      state.isStatuses = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spSignUp.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action.payload.data.access_token;
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.token = action.payload.token;
    });
    builder.addCase(spLogin.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.accessToken = action.payload.data.access_token;
      state.draftExits = action.payload.data.draft_exits;
      state.disablePdf = action.payload.data.disable_pdf;
      state.displayPdf = action.payload.data.display_pdf;
      state.master_bank_codes = action.payload.data.master_bank_codes;
      state.status_result = action.payload.data.status_result;
      state.id = action.payload.data.id;
    });
    builder.addCase(managerLogin.fulfilled, (state, action) => {
      state.accessToken = action.payload.data.access_token;
      state.managerName = action.payload.data.manager_name;
      state.managerRole = action.payload.data.manager_role
        ? action.payload.data.manager_role
        : MANAGER_ROLE.DEFAULT;
      state.managerId = action.payload.data.manager_id;
      state.salePersonName = '';
      state.salePersonId = '';
      state.isLoggedIn = true;
    });
    builder.addCase(salePersonLogin.fulfilled, (state, action) => {
      state.accessToken = action.payload.data.access_token;
      state.salePersonName = action.payload.data.sale_person_name;
      state.salePersonId = action.payload.data.sale_person_id;
      state.managerName = '';
      state.managerId = '';
      state.managerRole = MANAGER_ROLE.DEFAULT;
      state.isLoggedIn = true;
    });
    builder.addCase(salePersonAzureLogin.fulfilled, (state, action) => {
      state.accessToken = action.payload.data.access_token;
      state.salePersonName = action.payload.data.sale_person_name;
      state.isAzureLogin = true;
      state.salePersonId = action.payload.data.sale_person_id;
      state.managerName = '';
      state.managerId = '';
      state.managerRole = MANAGER_ROLE.DEFAULT;
      state.isLoggedIn = true;
    });
    builder.addCase(managerLogout.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.salePersonName = '';
      state.salePersonId = '';
      state.managerName = '';
      state.managerId = '';
      state.managerRole = MANAGER_ROLE.DEFAULT;
      state.accessToken = null;
      state.draftExits = false;
      state.preliminaryId = '';
      state.id = null;
    });
    builder.addCase(salePersonLogout.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.salePersonName = '';
      state.salePersonId = '';
      state.managerName = '';
      state.managerId = '';
      state.managerRole = MANAGER_ROLE.DEFAULT;
      state.accessToken = null;
      state.draftExits = false;
      state.preliminaryId = '';
      state.id = null;
      state.isAzureLogin = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoggedIn = false;
      state.salePersonName = '';
      state.salePersonId = '';
      state.managerName = '';
      state.managerId = '';
      state.managerRole = MANAGER_ROLE.DEFAULT;
      state.accessToken = null;
      state.draftExits = false;
      state.preliminaryId = '';
      state.id = null;
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      state.examinationFile = action.payload.data.examination_file;
      state.disablePdf = action.payload.data.disable_pdf;
      state.displayPdf = action.payload.data.display_pdf;
      state.master_bank_codes = action.payload.master_bank_codes;
      state.preliminaryId = action.payload.data.id;
      state.timeGetEdit = formatTimeForCheckAsync(new Date());
      state.sbiId = action.payload.data.p_application_banks_attributes.find(
        (bank) => bank.name === SBI_NAME
      )?.id;
      state.isJoinGuarantorInit =
        action.payload.data.p_applicant_people_attributes[0]
          .has_join_guarantor === CoOwnerType.CANBE;
      state.isIncomeTotalizerInit =
        action.payload.data.loan_type === LoanType.THREE ||
        action.payload.data.loan_type === LoanType.FOUR;
    });
    builder.addCase(getPublicHolidays.fulfilled, (state, action) => {
      state.publicHolidays = { ...state.publicHolidays, ...action.payload };
    });
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      state.draftExits = !!action.payload.data?.id;
    });
  },
});

export const {
  resetAuth,
  setCurrentStep,
  setFirstTimeLogin,
  updateStatusResult,
  updateIsStatuses,
} = authSlice.actions;
export default authSlice.reducer;
