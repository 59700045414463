import { useEffect } from 'react';

type InfiniteScrollProps = {
  callback: (page: number) => Promise<void>;
  pagination: {
    page: number;
    maxPage: number;
    fetching: boolean;
  };
  id?: string;
};

export const useInfiniteScroll = ({
  callback,
  pagination,
  id = '',
}: InfiniteScrollProps) => {
  useEffect(() => {
    if (id) {
      const scrollElement = document.getElementById(id);
      const handleScrollElement = () => {
        if (
          scrollElement &&
          scrollElement.scrollTop + scrollElement.clientHeight >=
            scrollElement.scrollHeight &&
          pagination.page < pagination.maxPage &&
          !pagination.fetching
        ) {
          callback(pagination.page + 1);
        }
      };
      if (scrollElement) {
        scrollElement.addEventListener('scroll', handleScrollElement);
        return () => {
          scrollElement.removeEventListener('scroll', handleScrollElement);
        };
      }
    }

    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        pagination.page < pagination.maxPage &&
        !pagination.fetching
      ) {
        callback(pagination.page + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [callback, id, pagination.fetching, pagination.maxPage, pagination.page]);
};
