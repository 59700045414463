import {
  AdminPreliminaryItem,
  ApplicantDetailType,
  BuyingandSellingScheduleType,
  CODEBANK,
  CurrentResidenceType,
  Flat35ApplicablePlanType,
  MANAGER_ROLE,
  PersonOccupancyType,
  PlanningAreaType,
  PurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
} from 'constant';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { dayjs } from 'libs/dayjs';
import { getYearLength } from './getYearLenght';

export const buildPersonalResidence = (
  managerRole: string,
  rows: AdminPreliminaryItem[],
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  const isMCJ = values.name_master_banks?.includes(CODEBANK.MCJ);
  return rows
    .filter(({ name_en }) => {
      const peopleAttributes = values.p_applicant_people_attributes?.[0] || {};
      if (name_en === 'condominium_name') {
        return [PurposeType.THREE, PurposeType.FOUR].includes(
          values.loan_target
        );
      }
      if (
        [
          'land_ownership',
          'purchase_purpose',
          'planning_area',
          'flat_35_applicable_plan',
          'maintenance_type',
          'region_type',
        ].includes(name_en)
      ) {
        return isMCJ;
      }
      if (name_en === 'other_planning_area') {
        return isMCJ && values.planning_area === PlanningAreaType.OTHERS;
      }
      if (name_en === 'rebuilding_reason') {
        return (
          isMCJ &&
          [
            PlanningAreaType.URBANIZED_AREA,
            PlanningAreaType.URBANIZATION_CONTROL_AREA,
          ].includes(values.planning_area ?? PlanningAreaType.DEFAULT)
        );
      }
      if (name_en === 'other_rebuilding_reason') {
        return (
          isMCJ && values.rebuilding_reason === RebuildingReasonType.OTHERS
        );
      }
      if (name_en === 'flat_35_application') {
        return (
          isMCJ &&
          values.flat_35_applicable_plan === Flat35ApplicablePlanType.PLAN_A
        );
      }
      if (name_en === 'collateral_land_area') {
        return ![PurposeType.THREE, PurposeType.FOUR].includes(
          values.loan_target
        );
      }
      if (name_en === 'occupied_area') {
        return [PurposeType.THREE, PurposeType.FOUR].includes(
          values.loan_target
        );
      }
      if (name_en === 'land_ownership') {
        return isMCJ;
      }
      if (name_en === 'purchase_purpose') {
        return isMCJ;
      }
      if (name_en === 'planning_area') {
        return isMCJ;
      }
      if (name_en === 'other_planning_area') {
        return isMCJ;
      }
      if (
        name_en === 'p_applicant_people_attributes[0].lived_length_month_num'
      ) {
        return (
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN
        );
      }
      if (name_en === 'p_applicant_people_attributes[0].reason_acquire_home') {
        return values.loan_target !== PurposeType.SEVEN;
      }
      if (name_en === 'non_resident_reason') {
        return values.person_occupancy === PersonOccupancyType.NO;
      }
      if (
        name_en === 'p_applicant_people_attributes[0].other_reason_acquire_home'
      ) {
        return (
          peopleAttributes.reason_acquire_home ===
          ReasonsForNeedingHousingType.OTHERS
        );
      }
      if (
        name_en ===
        'p_applicant_people_attributes[0].buyingand_selling_schedule_type'
      ) {
        return (
          peopleAttributes.current_residence ===
            CurrentResidenceType.PRIVATE_APARTMENT &&
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN
        );
      }
      if (
        name_en ===
        'p_applicant_people_attributes[0].other_buyingand_selling_schedule_type'
      ) {
        return (
          peopleAttributes.current_residence ===
            CurrentResidenceType.PRIVATE_APARTMENT &&
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN &&
          peopleAttributes.buyingand_selling_schedule_type ===
            BuyingandSellingScheduleType.OTHERS
        );
      }
      if (
        name_en ===
        'p_applicant_people_attributes[0].expected_house_selling_price'
      ) {
        return (
          peopleAttributes.current_residence ===
            CurrentResidenceType.PRIVATE_APARTMENT &&
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN &&
          peopleAttributes.buyingand_selling_schedule_type ===
            BuyingandSellingScheduleType.SALE
        );
      }
      if (
        name_en ===
        'p_applicant_people_attributes[0].current_residence_floor_area'
      ) {
        return isMCJ;
      }
      if (name_en === 'p_applicant_people_attributes[0].owner_full_name') {
        return (
          peopleAttributes.current_residence ===
            CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN
        );
      }
      if (name_en === 'p_applicant_people_attributes[0].owner_relationship') {
        return (
          peopleAttributes.current_residence ===
            CurrentResidenceType.RENTAL_HOUSING_FOR_INSTITUTIONS_PUBLIC_CORPORATIONS_PUBLIC_CORPORATIONS &&
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN
        );
      }
      if (
        name_en === 'p_applicant_people_attributes[0].scheduled_time_sell_house'
      ) {
        return (
          peopleAttributes.current_residence ===
            CurrentResidenceType.PRIVATE_APARTMENT &&
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN &&
          peopleAttributes.buyingand_selling_schedule_type ===
            BuyingandSellingScheduleType.SALE
        );
      }
      if (name_en === 'p_applicant_people_attributes[0].current_home_loan') {
        return (
          peopleAttributes.applicant_detail_type === ApplicantDetailType.MAIN &&
          peopleAttributes.current_residence ===
            CurrentResidenceType.PRIVATE_APARTMENT
        );
      }
      if (
        name_en === 'p_residents_attributes[0].relationship' ||
        name_en === 'p_residents_attributes[0].relationship_name'
      ) {
        return managerRole === MANAGER_ROLE.ADMIN;
      }
      return true;
    })
    .map((row) => {
      if (row.name_en === 'non_resident_reason') {
        return {
          ...row,
          required:
            [PersonOccupancyType.NO].includes(values.person_occupancy) &&
            managerRole === MANAGER_ROLE.ADMIN,
        };
      }
      // if (
      //   row.name_en === 'p_applicant_people_attributes[0].current_residence'
      // ) {
      //   return {
      //     ...row,
      //     dynamicOptionHidden(subIndex) {
      //       return (
      //         [0, 3, 4, 5, 6, 7].includes(subIndex) &&
      //         values.loan_target === PurposeType.SEVEN
      //       );
      //     },
      //   };
      // }
      if (row.name_en === 'collateral_total_floor_area') {
        return {
          ...row,
          name_ja: [PurposeType.THREE, PurposeType.FOUR].includes(
            values.loan_target
          )
            ? 'マンション全体の延べ床面積'
            : '建物の延べ床面積',
        };
      }
      if (
        row.name_en ===
        'p_applicant_people_attributes[0].lived_length_month_num'
      ) {
        return {
          ...row,
          dynamicOptionHidden(index) {
            return getDynamicOptionHidden(
              index,
              values.p_applicant_people_attributes?.[0]?.birthday,
              values.p_applicant_people_attributes?.[0]?.lived_length_year_num
            );
          },
        };
      }
      return row;
    });
};

export function getDynamicOptionHidden(
  index: number,
  birthday: string | undefined,
  livedLengthYearNum: string | undefined
): boolean {
  if (!birthday) {
    return index > 0;
  }
  const yearLength = getYearLength(birthday);
  if (!livedLengthYearNum || +livedLengthYearNum !== yearLength) {
    return false;
  }
  const monthDiff = dayjs().diff(birthday, 'M', true);
  return Math.floor(monthDiff % 12) < index;
}
