import { FC, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPRegistrationCompleteModal: FC<ModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="新規登録完了"
      disableBackDropClick
      footer={
        <SPButton
          sx={{
            width: '100%',
            bgcolor: 'sp_primary_100_main',
          }}
          endIcon={<Icons.ArrowForward stroke="white" />}
          onClick={onClose}
        >
          <Typography variant="SP_multiple_checkbox_label" color="main_white">
            次へ
          </Typography>
        </SPButton>
      }
    >
      <Typography
        variant="liststyle"
        color="b_333"
        sx={{
          textAlign: 'center',
          whiteSpace: 'break-spaces',
          letterSpacing: '0.6px',
          lineHeight: '27.2px',
        }}
      >
        {`新規登録が完了しました。\n早速サービスをご利用ください。`}
      </Typography>
    </SPModal>
  );
};
