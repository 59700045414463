import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SPStepThreeForm } from 'types';
import { logout } from 'containers/AuthModal/thunk';
import {
  DispatchType,
  LeaveType,
  NursingCareType,
  OccupationDetailType,
  OccupationType,
  SPTaxReturnType,
  WorkIndustryType,
} from 'constant';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { dayjs } from 'libs';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { deleteUser } from '../SPUnsubcribed/thunk';

export type InitialState = {
  form: SPStepThreeForm;
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      p_applicant_people_attributes: [
        {
          occupation: OccupationType.DEFAULT,
          other_occupation: '',
          industry: WorkIndustryType.DEFAULT,
          other_industry: '',
          occupation_detail: OccupationDetailType.DEFAULT,
          other_occupation_detail: '',
          office_name_kanji: '',
          department: '',
          office_phone_number: '',
          office_postal_code: '',
          office_prefecture_kanji: '',
          office_city_kanji: '',
          office_district_kanji: '',
          office_prefecture_kana: '',
          office_city_kana: '',
          office_district_kana: '',
          number_of_employee: '',
          employment_started_date: '',
          last_year_income: '',
          bonus_income: '',
          two_years_ago_income: '',
          income_source: [],
          tax_return: SPTaxReturnType.DEFAULT,
          type_tax_return: [],
          other_type_tax_return: '',
          transfer_office: DispatchType.DEFAULT,
          transfer_office_name_kanji: '',
          transfer_office_name_kana: '',
          transfer_office_phone_number: '',
          transfer_office_postal_code: '',
          transfer_office_prefecture_kanji: '',
          transfer_office_city_kanji: '',
          transfer_office_district_kanji: '',
          transfer_office_prefecture_kana: '',
          transfer_office_city_kana: '',
          transfer_office_district_kana: '',
          transfer_office_other_address_kanji: '',
          transfer_office_other_address_kana: '',
          maternity_paternity_leave_status: LeaveType.DEFAULT,
          maternity_paternity_leave_start_time: '',
          maternity_paternity_leave_end_time: '',
          nursing_leave_status: NursingCareType.DEFAULT,
          other_office_address_kanji: '',
          other_office_address_kana: '',
        },
      ],
    },
  },
};

const spStepThreeSlice = createSlice({
  name: 'spStepThree',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<SPStepThreeForm>) => {
      state.form = {
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const {
        income_source,
        type_tax_return,
        occupation,
        other_occupation,
        industry,
        other_industry,
        occupation_detail,
        other_occupation_detail,
        office_name_kanji,
        department,
        office_phone_number,
        office_postal_code,
        office_prefecture_kanji,
        office_city_kanji,
        office_district_kanji,
        office_prefecture_kana,
        office_city_kana,
        office_district_kana,
        other_office_address_kanji,
        other_office_address_kana,
        number_of_employee,
        employment_started_date,
        last_year_income,
        bonus_income,
        two_years_ago_income,
        tax_return,
        other_type_tax_return,
        transfer_office,
        transfer_office_name_kanji,
        transfer_office_name_kana,
        transfer_office_phone_number,
        transfer_office_postal_code,
        transfer_office_prefecture_kanji,
        transfer_office_city_kanji,
        transfer_office_district_kanji,
        transfer_office_prefecture_kana,
        transfer_office_city_kana,
        transfer_office_district_kana,
        transfer_office_other_address_kanji,
        transfer_office_other_address_kana,
        maternity_paternity_leave_status,
        maternity_paternity_leave_start_time,
        maternity_paternity_leave_end_time,
        nursing_leave_status,
      } = action.payload.data.p_applicant_people[0];
      state.form.p_application_header.p_applicant_people_attributes = [
        {
          ...state.form.p_application_header.p_applicant_people_attributes[0],
          ...(!!income_source && { income_source }),
          ...(!!type_tax_return && { type_tax_return }),
          occupation,
          other_occupation,
          industry,
          other_industry,
          occupation_detail,
          other_occupation_detail,
          office_name_kanji,
          department,
          office_phone_number,
          office_postal_code,
          office_prefecture_kanji,
          office_city_kanji,
          office_district_kanji,
          office_prefecture_kana,
          office_city_kana,
          office_district_kana,
          other_office_address_kanji,
          other_office_address_kana,
          number_of_employee,
          employment_started_date,
          last_year_income,
          bonus_income,
          two_years_ago_income,
          tax_return,
          other_type_tax_return,
          transfer_office,
          transfer_office_name_kanji,
          transfer_office_name_kana,
          transfer_office_phone_number,
          transfer_office_postal_code,
          transfer_office_prefecture_kanji,
          transfer_office_city_kanji,
          transfer_office_district_kanji,
          transfer_office_prefecture_kana,
          transfer_office_city_kana,
          transfer_office_district_kana,
          transfer_office_other_address_kanji,
          transfer_office_other_address_kana,
          maternity_paternity_leave_status,
          maternity_paternity_leave_start_time,
          maternity_paternity_leave_end_time,
          nursing_leave_status,
        },
      ];
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const {
        income_source,
        type_tax_return,
        occupation,
        other_occupation,
        industry,
        other_industry,
        occupation_detail,
        other_occupation_detail,
        office_name_kanji,
        department,
        office_phone_number,
        office_postal_code,
        office_prefecture_kanji,
        office_city_kanji,
        office_district_kanji,
        office_prefecture_kana,
        office_city_kana,
        office_district_kana,
        other_office_address_kanji,
        other_office_address_kana,
        number_of_employee,
        employment_started_date,
        last_year_income,
        bonus_income,
        two_years_ago_income,
        tax_return,
        other_type_tax_return,
        transfer_office,
        transfer_office_name_kanji,
        transfer_office_name_kana,
        transfer_office_phone_number,
        transfer_office_postal_code,
        transfer_office_prefecture_kanji,
        transfer_office_city_kanji,
        transfer_office_district_kanji,
        transfer_office_prefecture_kana,
        transfer_office_city_kana,
        transfer_office_district_kana,
        transfer_office_other_address_kanji,
        transfer_office_other_address_kana,
        maternity_paternity_leave_status,
        maternity_paternity_leave_start_time,
        maternity_paternity_leave_end_time,
        nursing_leave_status,
      } = action.payload.data.p_applicant_people_attributes[0];

      state.form.p_application_header.p_applicant_people_attributes = [
        {
          ...state.form.p_application_header.p_applicant_people_attributes[0],
          ...(!!income_source && { income_source }),
          ...(!!type_tax_return && { type_tax_return }),
          occupation,
          other_occupation,
          industry,
          other_industry,
          occupation_detail,
          other_occupation_detail,
          office_name_kanji,
          department,
          office_phone_number,
          office_postal_code,
          office_prefecture_kanji,
          office_city_kanji,
          office_district_kanji,
          office_prefecture_kana,
          office_city_kana,
          office_district_kana,
          other_office_address_kanji,
          other_office_address_kana,
          number_of_employee,
          employment_started_date,
          last_year_income,
          bonus_income,
          two_years_ago_income,
          tax_return,
          other_type_tax_return,
          transfer_office,
          transfer_office_name_kanji,
          transfer_office_name_kana,
          transfer_office_phone_number,
          transfer_office_postal_code,
          transfer_office_prefecture_kanji,
          transfer_office_city_kanji,
          transfer_office_district_kanji,
          transfer_office_prefecture_kana,
          transfer_office_city_kana,
          transfer_office_district_kana,
          transfer_office_other_address_kanji,
          transfer_office_other_address_kana,
          maternity_paternity_leave_status,
          maternity_paternity_leave_start_time:
            !!maternity_paternity_leave_start_time
              ? dayjs(maternity_paternity_leave_start_time).format('YYYY/MM')
              : maternity_paternity_leave_start_time,
          maternity_paternity_leave_end_time:
            !!maternity_paternity_leave_end_time
              ? dayjs(maternity_paternity_leave_end_time).format('YYYY/MM')
              : maternity_paternity_leave_end_time,
          nursing_leave_status,
        },
      ];
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const {
        income_source,
        type_tax_return,
        occupation,
        other_occupation,
        industry,
        other_industry,
        occupation_detail,
        other_occupation_detail,
        office_name_kanji,
        department,
        office_phone_number,
        office_postal_code,
        office_prefecture_kanji,
        office_city_kanji,
        office_district_kanji,
        office_prefecture_kana,
        office_city_kana,
        office_district_kana,
        other_office_address_kanji,
        other_office_address_kana,
        number_of_employee,
        employment_started_date,
        last_year_income,
        bonus_income,
        two_years_ago_income,
        tax_return,
        other_type_tax_return,
        transfer_office,
        transfer_office_name_kanji,
        transfer_office_name_kana,
        transfer_office_phone_number,
        transfer_office_postal_code,
        transfer_office_prefecture_kanji,
        transfer_office_city_kanji,
        transfer_office_district_kanji,
        transfer_office_prefecture_kana,
        transfer_office_city_kana,
        transfer_office_district_kana,
        transfer_office_other_address_kanji,
        transfer_office_other_address_kana,
        maternity_paternity_leave_status,
        maternity_paternity_leave_start_time,
        maternity_paternity_leave_end_time,
        nursing_leave_status,
      } = action.payload.data.p_applicant_people_attributes[0];

      state.form.p_application_header.p_applicant_people_attributes = [
        {
          ...state.form.p_application_header.p_applicant_people_attributes[0],
          ...(!!income_source && { income_source }),
          ...(!!type_tax_return && { type_tax_return }),
          occupation,
          other_occupation,
          industry,
          other_industry,
          occupation_detail,
          other_occupation_detail,
          office_name_kanji,
          department,
          office_phone_number,
          office_postal_code,
          office_prefecture_kanji,
          office_city_kanji,
          office_district_kanji,
          office_prefecture_kana,
          office_city_kana,
          office_district_kana,
          other_office_address_kanji,
          other_office_address_kana,
          number_of_employee,
          employment_started_date,
          last_year_income,
          bonus_income,
          two_years_ago_income,
          tax_return,
          other_type_tax_return,
          transfer_office,
          transfer_office_name_kanji,
          transfer_office_name_kana,
          transfer_office_phone_number,
          transfer_office_postal_code,
          transfer_office_prefecture_kanji,
          transfer_office_city_kanji,
          transfer_office_district_kanji,
          transfer_office_prefecture_kana,
          transfer_office_city_kana,
          transfer_office_district_kana,
          transfer_office_other_address_kanji,
          transfer_office_other_address_kana,
          maternity_paternity_leave_status,
          maternity_paternity_leave_start_time:
            !!maternity_paternity_leave_start_time
              ? dayjs(maternity_paternity_leave_start_time).format('YYYY/MM')
              : maternity_paternity_leave_start_time,
          maternity_paternity_leave_end_time:
            !!maternity_paternity_leave_end_time
              ? dayjs(maternity_paternity_leave_end_time).format('YYYY/MM')
              : maternity_paternity_leave_end_time,
          nursing_leave_status,
        },
      ];
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const { saveForm } = spStepThreeSlice.actions;
export default spStepThreeSlice.reducer;
