import { regex, yup } from 'libs';
import { MESSAGE_REGEX, SPBusinessCardType } from 'constant';

export const validationSchema = yup.object().shape({
  has_business_card: yup.string(),
  sale_agent_id: yup.string(),
  store_id: yup.string(),
  exhibition_id: yup.string(),
  s_sale_person_id: yup.string(),
  sale_person_name_input: yup
    .string()
    .trim()
    .max(48)
    .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
  phone_number: yup
    .string()
    // .min(VALIDATE_MAX.MOBILE_PHONE, MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_SP)
    // .noZeroPhoneNumber()
    // .matches(regex.mobilePhone, MESSAGE_REGEX.MOBILE_PHONE_SP)
    .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER)
    .when('business_card', {
      is: (business_card: SPBusinessCardType) =>
        business_card === SPBusinessCardType.NO,
      then: yup.string().required(MESSAGE_REGEX.REQUIRED),
    }),
});
