import { FC, PropsWithChildren, useState } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';
import { theme } from 'styles';
import { config } from 'configs';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: (consents: (boolean | undefined)[]) => void;
  onSaveForm?: () => void;
}>;

export const SPJointGuarantorModal: FC<ModalProps> = ({
  open,
  onClose,
  onSaveForm,
}) => {
  const [consents, setConsents] = useState<(boolean | undefined)[]>([
    undefined,
    undefined,
  ]);

  const urls = [config.dummyPdfUrl, config.termConditionUrl];

  const closeModal = () => {
    onClose(consents);
    setConsents((prevState) => {
      const cloneState = [...prevState].slice(0);
      cloneState[0] = undefined;
      cloneState[1] = undefined;
      return cloneState;
    });
  };

  const [viewed, setViewed] = useState<boolean[]>([false, false]);

  return (
    <SPModal
      open={open}
      onClose={() => onClose(consents)}
      icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
      title="連帯保証人予定者（収入合算者）の方へ"
      disableBackDropClick
      sx={{
        px: 0,
        ...theme.typography.SP_form_item_label,
      }}
      footer={
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minWidth: 359 }}
        >
          <Stack spacing={7}>
            {consents.some((consent) => consent === false) && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{
                  p: '12px 18px 12px 18px',
                  bgcolor: 'sp_secondary_pink_light',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_secondary_01}`,
                  borderRadius: '8px',
                  mx: 4,
                }}
              >
                <Icons.SPWarning />
                <Typography variant="drawerText" color="sp_secondary_01">
                  同意いただけない場合、本サービスをご利用いただけません。
                </Typography>
              </Stack>
            )}
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="sp_change_password_note" color="b_333">
                下記2つの同意事項をよくお読みいただき
              </Typography>
              <Typography variant="sp_change_password_note" color="b_333">
                必ず連帯保証人予定者が選択してください。
              </Typography>
            </Stack>
          </Stack>

          {consents.map((consent, index) => (
            <Stack spacing={4} sx={{ mt: 7, width: '100%' }} key={index}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                bgcolor="sp_primary_40"
                spacing={2}
                sx={{ p: '10px 16px 10px 16px' }}
              >
                <Icons.SPPdfLite />
                <Link
                  href={urls[index]}
                  component="a"
                  target="_blank"
                  sx={{ textDecorationColor: 'sp_primary_100_main' }}
                  color="sp_primary_100_main"
                  variant="sp_agreement_text"
                  onClick={() => {
                    setViewed((prevState) => {
                      const cloneState = [...prevState].slice(0);
                      cloneState[index] = true;
                      return cloneState;
                    });
                    onSaveForm && onSaveForm();
                  }}
                >
                  {!index
                    ? '個人情報の取扱いに関する同意書 兼 表明および確約書'
                    : '銀行代理業にかかる確認書　兼　個人情報の取扱い等に関する同意書'}
                </Link>
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={3}
                sx={{ px: 4 }}
              >
                <Stack spacing={1}>
                  <SPButton
                    sx={{
                      ':hover': {
                        boxShadow: 'none',
                      },
                      width: '160px',
                      height: '48px',
                      bgcolor: consent ? 'sp_primary_20' : 'main_white',
                      border: (theme) =>
                        consent
                          ? `1px solid ${theme?.palette?.sp_primary_100_main}`
                          : `1px solid ${theme?.palette?.sp_primary_40}`,
                      boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                      borderRadius: '14px',
                      '&.Mui-disabled': {
                        opacity: 0.5,
                      },
                    }}
                    disabled={!viewed[index]}
                    onClick={() =>
                      setConsents((prevState) => {
                        const cloneState = [...prevState].slice(0);
                        cloneState[index] = true;
                        return cloneState;
                      })
                    }
                  >
                    <Typography
                      variant="SP_multiple_checkbox_label"
                      color="sp_primary_100_main"
                    >
                      同意する
                    </Typography>
                  </SPButton>
                  {consent === false && (
                    <Typography variant="drawerText" color="sp_secondary_01">
                      ※ご確認ください
                    </Typography>
                  )}
                </Stack>
                <SPButton
                  sx={{
                    ':hover': { boxShadow: 'none' },
                    width: '160px',
                    height: '48px',
                    bgcolor: consent === false ? 'sp_primary_20' : 'main_white',
                    border: (theme) =>
                      consent === false
                        ? `1px solid ${theme?.palette?.sp_primary_100_main}`
                        : `1px solid ${theme?.palette?.sp_primary_40}`,
                    boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
                    borderRadius: '14px',
                    '&.Mui-disabled': {
                      opacity: 0.5,
                    },
                  }}
                  disabled={!viewed[index]}
                  onClick={() =>
                    setConsents((prevState) => {
                      const cloneState = [...prevState].slice(0);
                      cloneState[index] = false;
                      return cloneState;
                    })
                  }
                >
                  <Typography
                    variant="SP_multiple_checkbox_label"
                    color="sp_primary_100_main"
                  >
                    同意しない
                  </Typography>
                </SPButton>
              </Stack>
            </Stack>
          ))}
          <Stack sx={{ px: 15, mt: 8 }}>
            <SPButton
              sx={{
                px: 26,
                bgcolor: 'sp_primary_100_main',
              }}
              endIcon={<Icons.ArrowForward stroke="white" />}
              onClick={closeModal}
              disabled={consents.some((consent) => !consent)}
            >
              <Typography
                variant="SP_multiple_checkbox_label"
                color="main_white"
              >
                次へ
              </Typography>
            </SPButton>
          </Stack>
        </Stack>
      }
    />
  );
};
