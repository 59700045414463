import { FC, useEffect, useState } from 'react';
import { UserLayout } from 'containers';
import { Button, StatusStep } from 'components';
import {
  Divider,
  Paper,
  Stack,
  styled,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { currentPremilinarySelectors } from 'pages/Preliminaries/selectors';
import { ArrowBackIos } from '@mui/icons-material';
import { routeNames } from 'navigations/routes';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ReviewStatus } from 'services';
import { getReviewStatus } from './thunk';

const ReviewStatusPage: FC = () => {
  const navigate = useNavigate();
  return (
    <UserLayout
      header={{
        left: {
          title: '審査状況',
        },
      }}
      footer={{
        left: (
          <Stack direction="row" sx={{ p: '5px 20px 11px 11px' }}>
            <Button
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
              onClick={() => navigate(routeNames.Dashboard.path)}
            >
              <Typography variant="button_title" color="normal_text">
                もどる
              </Typography>
            </Button>
          </Stack>
        ),
        right: false,
        bgcolor: 'footer_bg',
      }}
    >
      <Stack sx={{ ml: 15, mr: 6, pb: '29px' }}>
        <StatusItem />
      </Stack>
    </UserLayout>
  );
};

export default ReviewStatusPage;

const StatusItem: FC = () => {
  const dispatch = useAppDispatch();
  const { id, applicant_id } = useSelector(currentPremilinarySelectors);
  const [reviewStatus, setReviewStatus] = useState<ReviewStatus>();

  useEffect(() => {
    const getUserReviewStatus = async () => {
      const result = await dispatch(getReviewStatus({ id, applicant_id }));

      if (getReviewStatus.fulfilled.match(result)) {
        setReviewStatus(result.payload.data);
      }
    };

    getUserReviewStatus();
  }, [applicant_id, dispatch, id]);

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Stack
        sx={{
          px: '7px',
          bgcolor: 'bg_off',
          minWidth: 952,
          overflowX: 'auto',
          height: 'calc(100vh - 142px)',
        }}
      >
        <Stack sx={{ mb: '13px' }}>
          <Stack direction="row" spacing={3}>
            <Stack
              spacing={2}
              sx={{
                width: '100%',
                bgcolor: 'main_white',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                borderRadius: '6px',
                mt: '13px',
                p: '33px 7px 10px 7px',
              }}
            >
              <TableRow
                sx={{
                  bgcolor: 'main_white',
                  boxShadow: '0px 1px 2px rgba(51, 51, 51, 0.15)',
                  border: (theme) => `1px solid ${theme?.palette?.footer_bg}`,
                  borderRadius: '1px',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <TableCellStyled sx={{ width: 80 }}>
                  <Stack direction="column">
                    <Typography
                      variant="title_application_item"
                      color="normal_text"
                    >
                      {reviewStatus?.applicant_name}
                    </Typography>
                  </Stack>

                  <DividerStyled orientation="vertical" />
                </TableCellStyled>
                <TableCellStyled sx={{ flex: 1 }}>
                  <Typography
                    variant="title_application_item"
                    color="normal_text"
                  >
                    {reviewStatus?.bank_name[0]}
                  </Typography>
                  <DividerStyled orientation="vertical" />
                </TableCellStyled>
                <TableCellStyled sx={{ flex: 2, px: '15px' }}>
                  <StatusStep currentStatus={reviewStatus?.status_name} />
                </TableCellStyled>
              </TableRow>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </TableContainer>
  );
};

const TableCellStyled = styled(TableCell)(() => ({
  padding: '10px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));
