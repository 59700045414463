import { FC, useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Button } from 'components';
import { PreliminariesStatus } from 'constant';
import { ModalConfirm } from '..';

export type ApplicationStatus = {
  key: string;
  value: number;
  active: boolean;
  enable: boolean;
};

export type PreliminaryStatusesProps = {
  isManager?: boolean;
  applicantId?: string;
  activeTab?: string;
  applicationStatusList: ApplicationStatus[];
  onClick?: (applicantId: string, applicationStatus: number) => void;
  isLinking?: boolean;
  statusResult?: string;
  provisionalResult?: string;
};

export const PreliminaryStatuses: FC<PreliminaryStatusesProps> = ({
  activeTab,
  applicantId,
  applicationStatusList,
  onClick,
  isLinking,
  isManager,
  statusResult,
  provisionalResult,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>();
  const onChangeStatus = (index: number) => {
    if (onClick && applicantId) {
      setOpen(false);
      onClick(applicantId, index);
    }
  };

  const setBgColor = (statusActive: boolean) => {
    if (statusActive) return 'sp_primary_100_main';
    return isLinking ? 'linking_loan' : 'main_white';
  };

  // const isDisable = useCallback(
  //   (active: boolean, index: number) => {
  //     if (active) return true;
  //     if (
  //       (statusResult === '4' ||
  //         statusResult === '5' ||
  //         statusResult === '6') &&
  //       provisionalResult
  //     ) {
  //       if (
  //         activeTab === PreliminariesStatus.CURRENT &&
  //         (index === 0 || index === 1 || index === 4 || index === 5)
  //       ) {
  //         return false;
  //       }
  //       if (
  //         activeTab === PreliminariesStatus.INPROGRESS &&
  //         (index === 2 || index === 3)
  //       )
  //         return false;
  //       if (
  //         activeTab === PreliminariesStatus.PAST &&
  //         (index === 0 ||
  //           index === 2 ||
  //           index === 3 ||
  //           index === 4 ||
  //           index === 5)
  //       )
  //         return false;
  //     }
  //     return true;
  //   },
  //   [activeTab, provisionalResult, statusResult]
  // );

  const getWarningMessage = useCallback(
    (index: number | undefined) => {
      if (activeTab === PreliminariesStatus.CURRENT && index === 1) {
        return '本件は仮審査中の案件から本審査中の案件に変更しますが、宜しいでしょうか。';
      }
      if (
        activeTab === PreliminariesStatus.CURRENT &&
        index !== undefined &&
        [0, 4, 5].includes(index)
      ) {
        return '本件は仮審査中の案件から過去の案件に変更しますが、宜しいでしょうか。';
      }
      if (
        activeTab === PreliminariesStatus.INPROGRESS &&
        index !== undefined &&
        [2, 3].includes(index)
      ) {
        return '本件は本審査中の案件から過去の案件に変更しますが、宜しいでしょうか。';
      }
      return '';
    },
    [activeTab]
  );

  return (
    <Stack direction="row" justifyContent="flex-end">
      {!isManager && (
        <Typography
          variant="dashboard_text"
          color="b_333"
          sx={{ paddingTop: '6px' }}
        >
          仮審査後の状況
        </Typography>
      )}
      {applicationStatusList.map((status, index) => (
        <Button
          key={index}
          variant="text"
          onClick={() => {
            if (activeTab === PreliminariesStatus.PAST) {
              onChangeStatus(index);
            } else {
              setOpen(true);
              setIndex(index);
            }
          }}
          disabled={!status.enable || status.active}
          sx={{
            ml: 10,
            mr: 2,
            bgcolor: `${setBgColor(status.active)}`,
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'green_bg_6b70f01a',
              opacity: 1,
            },
          }}
        >
          <Typography
            variant="dashboard_text"
            color={`${status.active ? 'main_white' : 'sp_primary_100_main'}`}
          >
            {status.key}
          </Typography>
        </Button>
      ))}
      <ModalConfirm
        open={open}
        confirmText={getWarningMessage(index)}
        onClose={() => setOpen(false)}
        onConfirm={() => index !== undefined && onChangeStatus(index)}
      />
    </Stack>
  );
};
