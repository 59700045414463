import {
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  TextareaAutosize,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  TextareaAutosizeProps,
} from '@mui/material';
import { adminModalUpdateDetailRef, Button } from 'components';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArchiveFileAdminType,
  updateManagerNote,
} from 'pages/ManagerDocumentUpload/slice';
import { ModalDeleteConfirm } from 'pages/ManagerDocumentUpload/components/ModalDeleteConfirm';
import { Close as CloseIcon } from '@mui/icons-material';
import { deleteManagerArchiveFile } from 'services/adminArchiveFile';
import {
  getDownloadManagerDocumentUploadZip,
  getEditArchiveFileManager,
  updateDocumentNoteManager,
} from 'pages/ManagerDocumentUpload/thunk';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useAppDispatch } from 'hooks';
import { formatJapanDateTine } from 'utils';

export type ManagerDocumentUploadItemProps = {
  activeTab: string;
  data: ArchiveFileAdminType[];
  onReset: Dispatch<SetStateAction<number>>;
  onResetSearchData: () => void;
};

export const ManagerDocumentUploadItems: FC<ManagerDocumentUploadItemProps> = ({
  data,
  onReset,
  onResetSearchData,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [openTooltip, setOpenTooltip] = useState<{
    index: number;
    open: boolean;
  }>({
    index: -1,
    open: false,
  });
  const [deleteItem, setDeleteItem] = useState<
    ArchiveFileAdminType | undefined
  >();
  const handleConfirmDelete = async () => {
    if (!!deleteItem) {
      await deleteManagerArchiveFile(deleteItem.id);
    }
  };

  const handleEditNote = useCallback(
    async (index: number, item: ArchiveFileAdminType) => {
      if (item.note === keyword) {
        setOpenTooltip({ index: -1, open: false });
        return;
      }
      const dataNote = new FormData();
      dataNote.append('note', keyword);
      const result = await dispatch(
        updateDocumentNoteManager({ data: dataNote, id: item.id })
      );
      if (updateDocumentNoteManager.fulfilled.match(result)) {
        setOpenTooltip({ index: -1, open: false });
        dispatch(
          updateManagerNote({
            id: data[index].id,
            note: keyword,
          })
        );
        onResetSearchData();
      }
    },
    [data, dispatch, onResetSearchData, keyword]
  );

  return (
    <Stack>
      {data.map((item, index) => (
        <Stack
          key={index}
          sx={{
            bgcolor: 'main_white',
            p: 2,
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              bgcolor: 'main_white',
              borderBottom: '1px solid',
              borderColor: 'bg_gray',
              alignItems: 'center',
            }}
          >
            <TableCellStyled sx={{ width: 100 }}>
              <Typography
                variant="text_preliminarie_item"
                fontSize={14}
                color="b_333"
              >
                {item.id}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                justifyContent: 'center',
                textAlign: 'left',
                width: 350,
              }}
            >
              <Stack width={350}>
                <Typography
                  px={2}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  variant="document_upload_text"
                  color="b_333"
                >
                  {item.file_name.map((image: string, imageIndex) => {
                    return imageIndex === item.file_name.length - 1
                      ? image
                      : `${image}、`;
                  })}
                </Typography>
              </Stack>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 120,
              }}
            >
              <Typography
                px={1}
                variant="priliminary_number"
                fontSize={14}
                color="b_333"
              >
                {formatJapanDateTine(item.created_at, 'YY/MM/DD HH:mm')}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 220,
                textAlign: 'left',
              }}
            >
              <Typography px={1} variant="document_upload_text">
                {item.company_name}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                textAlign: 'left',
                width: 140,
              }}
            >
              <Typography px={1} variant="document_upload_text" color="b_333">
                {item.name_kanji}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 100,
              }}
            >
              <Typography variant="document_upload_text" color="b_333">
                {item.size}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 178,
                px: '10px',
              }}
            >
              <Stack>
                <CustomWidthTooltip
                  open={openTooltip.index === index && openTooltip.open}
                  placement="bottom-start"
                  title={
                    <ClickAwayListener
                      onClickAway={() => handleEditNote(index, item)}
                    >
                      <Stack
                        bgcolor="main_white"
                        sx={{ marginTop: '-15px', height: '100%' }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="button01"
                            sx={{ fontSize: 14 }}
                            color="b_333"
                          >
                            備考
                          </Typography>
                          <CloseIcon
                            onClick={() => handleEditNote(index, item)}
                            fontSize="medium"
                            sx={{
                              color: 'b_333',
                              cursor: 'pointer',
                              pb: 2,
                              pt: 2,
                              pr: 2,
                              width: 20,
                              height: 20,
                            }}
                          />
                        </Stack>
                        <Divider />
                        <Stack sx={{ height: '100%', pr: 2 }}>
                          <CustomTextareaAutosize
                            style={{
                              border: 'none',
                              overflow: 'hidden',
                              resize: 'none',
                              width: '100%',
                              height: '100%',
                              padding: 4,
                              fontFamily: 'Hiragino Sans',
                              fontSize: 12,
                              fontWeight: '300',
                              color: '#333333',
                            }}
                            autoFocus
                            // @ts-ignore
                            onFocus={(e) => {
                              e.currentTarget.setSelectionRange(
                                e.currentTarget.value.length,
                                e.currentTarget.value.length
                              );
                              setKeyword(item.note);
                            }}
                            defaultValue={item.note}
                            onBlur={(event: {
                              target: { value: SetStateAction<string> };
                            }) => {
                              setKeyword(event.target.value);
                            }}
                            placeholder="備考を入力して下さい"
                          />
                        </Stack>
                      </Stack>
                    </ClickAwayListener>
                  }
                >
                  <Stack
                    height={38}
                    width={158}
                    justifyContent="center"
                    sx={{ borderBottom: '1px solid', borderColor: 'black' }}
                    onClick={() => {
                      if (openTooltip.index === -1) {
                        setOpenTooltip({ index: index, open: true });
                      }
                    }}
                  >
                    {!!item.note ? (
                      <Typography
                        variant="document_upload_text"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: 'left',
                          px: 1,
                        }}
                        color="b_333"
                      >
                        {item.note}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontFamily: 'Hiragino Sans',
                          fontSize: 12,
                          fontWeight: '300',
                          textAlign: 'left',
                          px: 1,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        color="#C4C4C4"
                      >
                        備考を入力して下さい
                      </Typography>
                    )}
                  </Stack>
                </CustomWidthTooltip>
              </Stack>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                width: 420,
              }}
            >
              <Stack direction="row" spacing={4} pl={4}>
                <Button
                  sx={{
                    width: 124,
                    minHeight: 30,
                    height: 30,
                    color: 'black',
                    borderColor: 'black',
                    bgcolor: 'main_white',
                    border: '1px solid',
                    borderRadius: '2px',
                  }}
                  onClick={async () => {
                    const result = await dispatch(
                      getEditArchiveFileManager({ id: item.id })
                    );
                    if (getEditArchiveFileManager.fulfilled.match(result)) {
                      navigate(`/manager/document-upload/${item.id}`);
                    }
                    if (getEditArchiveFileManager.rejected.match(result)) {
                      adminModalUpdateDetailRef.current?.open();
                    }
                  }}
                >
                  <Typography variant="button01">詳細</Typography>
                </Button>
                <Button
                  sx={{
                    width: 124,
                    minHeight: 30,
                    height: 30,
                    color: 'sp_primary_100_main',
                    borderColor: 'sp_primary_100_main',
                    bgcolor: 'main_white',
                    border: '1px solid',
                    borderRadius: '2px',
                  }}
                  onClick={async () => {
                    await dispatch(
                      getDownloadManagerDocumentUploadZip({
                        id: item.id,
                        company_name: item.company_name,
                        name: item.name_kanji,
                      })
                    );
                  }}
                >
                  <Typography variant="button01">ダウンロード</Typography>
                </Button>
              </Stack>
            </TableCellStyled>
          </TableRow>
        </Stack>
      ))}
      {!!deleteItem && (
        <ModalDeleteConfirm
          open={open}
          fileNames={deleteItem.file_name}
          onClose={() => {
            setOpen(false);
            setDeleteItem(undefined);
          }}
          onConfirm={handleConfirmDelete}
          onReset={onReset}
        />
      )}
    </Stack>
  );
};

const TableCellStyled = styled(TableCell)(() => ({
  padding: '10px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  backgroundColor: 'white',
  [`& .${tooltipClasses.tooltip}`]: {
    width: 700,
    maxWidth: 500,
    height: 150,
    backgroundColor: 'white',
  },
  [`&.${tooltipClasses.popper}`]: {
    backgroundColor: 'white',
    border: '1px solid #CCCCCC',
  },
  '&[data-popper-placement="top-start"] > div': {
    marginTop: 15,
  },
});
const CustomTextareaAutosize = styled((props: TextareaAutosizeProps) => (
  <TextareaAutosize {...props} />
))({
  '::-webkit-input-placeholder': {
    color: '#C4C4C4',
  },
  '&:focus-visible': {
    outline: '#3a87d3 auto 1px',
  },
});
