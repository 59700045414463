import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPFormItem } from 'components';
import {
  borrowerIncome,
  borrowingStatus,
  BorrowingStatusType,
  BusinessBorrowingType,
  commonHousing,
  estateMortgage,
  FundsPairOffType,
  SPBorrowingType,
  SPLoanPurposeType,
  SPScheduledLoanPayoffType,
  SPTitle,
} from 'constant';
import { routeNames } from 'navigations/routes';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { spStepFiveSelector } from 'pages/SPStepFive/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  checkMCJ,
} from 'pages/SPStepOne/selectors';
import {
  convertDynamicOptions,
  convertYenToMan,
  formatJapanDate,
  getFileExtFromUrl,
  numberWithCommas,
} from 'utils';
import {
  borrowerOptions,
  borrowingFromHousingFinanceAgencyOptions,
} from 'pages/SPStepFive';
import { SPBorrowingAttribute } from 'types';
import { ImageContext } from 'context';
import { ImageItem } from '../ImageItems';

type SPStepTwoInfoProps = {
  showEditButton?: boolean;
  reload?: number;
};

export const SPStepFiveInfo: FC<SPStepTwoInfoProps> = ({
  showEditButton = true,
  reload,
}) => {
  const navigate = useNavigate();
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const isMCJ = useSelector(checkMCJ);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const { repaymentScheduleImage } = useContext(ImageContext);

  const borrowingTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.borrowing_type),
    [dynamicOptions.borrowing_type]
  );

  const loanPurposeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.loan_purpose),
    [dynamicOptions.loan_purpose]
  );
  const businessBorrowingTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.business_borrowing_type),
    [dynamicOptions.business_borrowing_type]
  );

  const borrowingCategoryOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.borrowing_category),
    [dynamicOptions.borrowing_category]
  );

  const completelyRepaymentTypeOptions = useMemo(
    () => convertDynamicOptions(dynamicOptions.completely_repayment_type),
    [dynamicOptions.completely_repayment_type]
  );

  const scheduledLoanPayoffOptions = useCallback(
    (borrowing: SPBorrowingAttribute) => {
      let options = convertDynamicOptions(dynamicOptions.scheduled_loan_payoff);
      if (
        (borrowing.borrowing_type === SPBorrowingType.CARD_LOAN ||
          borrowing.borrowing_type === SPBorrowingType.CAR_LOAN ||
          borrowing.borrowing_type === SPBorrowingType.EDUCATION_LOAN ||
          borrowing.borrowing_type ===
            SPBorrowingType.LOAN_FOR_LIVING_EXPENSES ||
          borrowing.borrowing_type === SPBorrowingType.OTHERS) &&
        spStepFiveValues.is_home_loan_plus
      )
        return options;
      return options.slice(0, 2);
    },
    [dynamicOptions.scheduled_loan_payoff, spStepFiveValues.is_home_loan_plus]
  );

  const { borrowing_status, land_rent_to_be_paid, house_rent_to_be_paid } =
    spStepFiveValues.p_applicant_people_attributes[0];
  const {
    p_borrowings_attributes,
    completely_repayment_type,
    other_complete_repayment_type,
    refund_amount,
    refund_content,
    land_rent_to_be_paid_borrower,
    land_rent_to_be_paid: total_income_land_rent_to_be_paid,
    house_rent_to_be_paid_borrower,
    house_rent_to_be_paid: total_income_house_rent_to_be_paid,
  } = spStepFiveValues;

  const borrowings = p_borrowings_attributes.filter(
    (borrowing) => !borrowing._destroy
  );

  const checkTitle = () => {
    if (incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_EIGHT_INCOME_GUARANTORS;
    }
    if (incomeTotalizer && !hasJoinGuarantor) {
      return SPTitle.STEP_SEVEN_INCOME;
    }
    if (!incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_SIX_GUARANTORS;
    }
    return SPTitle.STEP_FIVE;
  };

  let landHouseValue = '';
  if (incomeTotalizer) {
    landHouseValue = `支払地代\n\n〈支払いをしている方〉${
      borrowerIncome[land_rent_to_be_paid_borrower]
    }\n\n〈月間の支払金額〉${numberWithCommas(
      +total_income_land_rent_to_be_paid
    )}円\n\n支払家賃\n\n〈支払いをしている方〉${
      borrowerIncome[house_rent_to_be_paid_borrower]
    }\n\n〈月間の支払金額〉${numberWithCommas(
      +total_income_house_rent_to_be_paid
    )}円\n\n`;
  }

  return (
    <Stack>
      <Stack>
        <Stack
          direction="row"
          sx={{ px: 4, py: 1, bgcolor: 'sp_primary_100_main' }}
        >
          <Typography variant="sp_step_text" color="main_white" sx={{ mt: 1 }}>
            STEP
          </Typography>
          <Typography variant="sp_label_text" color="main_white">
            {checkTitle()}
          </Typography>
        </Stack>
        <Stack>
          <SPFormItem
            label="あなたや連帯保証人予定者に、現在お借入はありますか？"
            labelSx={{ p: '3px 16px' }}
            childrenSx={{ p: '12px 24px 20px' }}
            required
          >
            <Typography variant="sp_value_text" color="b_333">
              {`${borrowingStatus[borrowing_status] ?? 'ー'}`}
            </Typography>
          </SPFormItem>

          {borrowings.map((borrowing, index) => (
            <SPFormItem
              label={`お借入${index + 1}件目`}
              labelSx={{ p: '3px 16px' }}
              childrenSx={{ p: '12px 24px 20px' }}
              required
            >
              <Stack key={index} spacing={3}>
                {incomeTotalizer && (
                  <Typography
                    variant="sp_value_text"
                    color="b_333"
                    sx={{
                      whiteSpace: 'break-spaces',
                      wordBreak: 'break-all',
                    }}
                  >
                    {`〈借入名義人〉${
                      borrowerOptions.find(
                        (e) => e.value === borrowing.borrower
                      )?.label ?? '申込人本人'
                    }`}
                  </Typography>
                )}

                <Typography variant="sp_value_text" color="b_333">
                  {`〈借入種類〉${
                    borrowingTypeOptions.find(
                      (e) => e.value === borrowing.borrowing_type
                    )?.label ?? 'ー'
                  }`}
                </Typography>

                {repaymentScheduleImage[index]?.map((image, idx) => (
                  <Stack
                    key={idx}
                    direction="column"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    {(image instanceof File ? true : !image.hiden) && (
                      <ImageItem
                        key={idx}
                        fileName={image.name}
                        src={image instanceof File ? image : image.url}
                        reload={reload}
                        display={image instanceof File ? true : !image.hiden}
                        type={
                          image instanceof File
                            ? image.type
                            : getFileExtFromUrl(image.url)
                        }
                      />
                    )}
                  </Stack>
                ))}

                <Stack spacing={3}>
                  {!!borrowing.lender && (
                    <Typography
                      variant="sp_value_text"
                      color="b_333"
                      sx={{
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-all',
                      }}
                    >
                      {`〈借入先〉${borrowing.lender ?? 'ー'}`}
                    </Typography>
                  )}
                  {!!borrowing.loan_purpose &&
                    borrowing.borrowing_type === SPBorrowingType.CARD_LOAN && (
                      <Stack spacing={3}>
                        <Typography variant="sp_value_text" color="b_333">
                          {`〈お借入の目的〉${
                            loanPurposeOptions.find(
                              (e) => e.value === borrowing.loan_purpose
                            )?.label ?? 'ー'
                          }`}
                        </Typography>
                        {borrowing.loan_purpose ===
                          SPLoanPurposeType.OTHERS && (
                          <Typography variant="sp_value_text" color="b_333">
                            {`〈お借入の目的〉${borrowing.other_purpose}`}
                          </Typography>
                        )}
                      </Stack>
                    )}
                  {!!borrowing.business_borrowing_type &&
                    borrowing.borrowing_type ===
                      SPBorrowingType.BUSINESS_LOAN && (
                      <Stack spacing={3}>
                        <Typography variant="sp_value_text" color="b_333">
                          {`〈お借入の目的〉${
                            businessBorrowingTypeOptions.find(
                              (e) =>
                                e.value === borrowing.business_borrowing_type
                            )?.label ?? 'ー'
                          }`}
                        </Typography>
                        {borrowing.business_borrowing_type ===
                          BusinessBorrowingType.OTHERS && (
                          <Typography variant="sp_value_text" color="b_333">
                            {`〈お借入の目的〉${borrowing.specific_loan_purpose}`}
                          </Typography>
                        )}
                      </Stack>
                    )}
                  {!!borrowing.borrowing_category &&
                    borrowing.borrowing_type === SPBorrowingType.CARD_LOAN && (
                      <Typography variant="sp_value_text" color="b_333">
                        {`〈借入区分〉${
                          borrowingCategoryOptions.find(
                            (e) => e.value === borrowing.borrowing_category
                          )?.label ?? 'ー'
                        }`}
                      </Typography>
                    )}
                  {!!borrowing.borrowing_from_housing_finance_agency &&
                    borrowing.borrowing_type ===
                      SPBorrowingType.HOUSING_LOAN && (
                      <Typography variant="sp_value_text" color="b_333">
                        {`〈住宅金融支援機構からの借入ですか？〉${
                          borrowingFromHousingFinanceAgencyOptions.find(
                            (e) =>
                              e.value ===
                              borrowing.borrowing_from_housing_finance_agency
                          )?.label ?? 'ー'
                        }`}
                      </Typography>
                    )}
                  {!!borrowing.loan_start_date && (
                    <Typography variant="sp_value_text" color="b_333">
                      {`〈${
                        borrowing.borrowing_type === SPBorrowingType.CARD_LOAN
                          ? '当初カード契約年月'
                          : '当初借入年月'
                      }〉${formatJapanDate(borrowing.loan_start_date, true)}`}
                    </Typography>
                  )}
                  {!!borrowing.loan_amount && (
                    <Typography variant="sp_value_text" color="b_333">
                      {`〈${
                        borrowing.borrowing_type === SPBorrowingType.CARD_LOAN
                          ? '借入限度額'
                          : '当初借入額'
                      }〉${numberWithCommas(
                        +convertYenToMan([borrowing.loan_amount])[0]
                      )}万円`}
                    </Typography>
                  )}
                  {!!borrowing.current_balance_amount && (
                    <Typography variant="sp_value_text" color="b_333">
                      {`〈現在の残高〉${numberWithCommas(
                        +convertYenToMan([borrowing.current_balance_amount])[0]
                      )}万円`}
                    </Typography>
                  )}
                  {!!borrowing.annual_repayment_amount && (
                    <Typography variant="sp_value_text" color="b_333">
                      {`〈年間返済額〉${numberWithCommas(
                        +convertYenToMan([borrowing.annual_repayment_amount])[0]
                      )}万円`}
                    </Typography>
                  )}
                  {!!borrowing.card_expiry_date &&
                    borrowing.borrowing_type === SPBorrowingType.CARD_LOAN && (
                      <Typography variant="sp_value_text" color="b_333">
                        {`〈カード有効期限〉${formatJapanDate(
                          borrowing.card_expiry_date,
                          true
                        )}`}
                      </Typography>
                    )}
                  {!!borrowing.loan_deadline_date &&
                    borrowing.borrowing_type !== SPBorrowingType.CARD_LOAN && (
                      <Typography variant="sp_value_text" color="b_333">
                        {`〈${
                          borrowing.borrowing_type ===
                          SPBorrowingType.APARTMENT_LOAN
                            ? '最終返済年月'
                            : '最終期限'
                        }〉${formatJapanDate(
                          borrowing.loan_deadline_date,
                          true
                        )}`}
                      </Typography>
                    )}
                  {borrowing.borrowing_type ===
                    SPBorrowingType.APARTMENT_LOAN && (
                    <Stack spacing={3}>
                      {!!borrowing.rental_room_number && (
                        <Typography variant="sp_value_text" color="b_333">
                          {`〈賃貸戸（室）数〉${borrowing.rental_room_number}戸（室）`}
                        </Typography>
                      )}
                      {!!borrowing.common_housing && (
                        <Typography variant="sp_value_text" color="b_333">
                          {`〈共同住宅〉${
                            commonHousing[borrowing.common_housing] ?? 'ー'
                          }`}
                        </Typography>
                      )}
                    </Stack>
                  )}
                  {!!borrowing.estate_mortgage &&
                    borrowing.borrowing_type !== SPBorrowingType.HOUSING_LOAN &&
                    borrowing.borrowing_type !== SPBorrowingType.CARD_LOAN && (
                      <Typography variant="sp_value_text" color="b_333">
                        {`〈不動産担保設定〉${
                          estateMortgage[borrowing.estate_mortgage] ?? 'ー'
                        }`}
                      </Typography>
                    )}
                  {!!borrowing.scheduled_loan_payoff &&
                    borrowing.borrowing_type !==
                      SPBorrowingType.BUSINESS_LOAN && (
                      <Stack spacing={2}>
                        <Typography variant="sp_value_text" color="b_333">
                          {`〈今回のお借入までに完済の予定はありますか？〉`}
                        </Typography>
                        <Typography
                          pl={8}
                          variant="sp_value_text"
                          color="b_333"
                        >
                          {`${
                            scheduledLoanPayoffOptions(borrowing).find(
                              (e) => e.value === borrowing.scheduled_loan_payoff
                            )?.label ?? 'ー'
                          }`}
                        </Typography>
                      </Stack>
                    )}
                  {!!borrowing.expected_repayment_date &&
                    borrowing.scheduled_loan_payoff ===
                      SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY && (
                      <Typography variant="sp_value_text" color="b_333">
                        {`〈完済（予定）年月〉${formatJapanDate(
                          borrowing.expected_repayment_date,
                          true
                        )}`}
                      </Typography>
                    )}
                </Stack>
              </Stack>
            </SPFormItem>
          ))}

          {isMCJ &&
            spStepFiveValues.p_applicant_people_attributes[0]
              .borrowing_status === BorrowingStatusType.YES &&
            borrowings.some(
              (borrowing) =>
                borrowing.scheduled_loan_payoff ===
                  SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY &&
                !borrowing._destroy
            ) && (
              <Stack spacing={3}>
                <SPFormItem
                  label="完済原資"
                  labelSx={{ p: '3px 16px' }}
                  childrenSx={{ p: '12px 24px 20px' }}
                  required
                >
                  <Typography
                    variant="sp_value_text"
                    color="b_333"
                    sx={{
                      whiteSpace: 'break-spaces',
                      wordBreak: 'break-all',
                    }}
                  >
                    {`〈完済原資の種類〉${
                      completelyRepaymentTypeOptions.find(
                        (e) => e.value === completely_repayment_type
                      )?.label ?? 'ー'
                    } ${
                      completely_repayment_type === FundsPairOffType.OTHERS
                        ? `（${other_complete_repayment_type}）`
                        : ''
                    }\n\n〈完済原資の内容〉${refund_content}\n\n〈完済原資の金額〉${numberWithCommas(
                      +convertYenToMan([refund_amount])[0]
                    )}万円`}
                  </Typography>
                </SPFormItem>

                <SPFormItem
                  label="今回の住宅取得後も継続する支払地代・支払家賃"
                  labelSx={{ p: '3px 16px' }}
                  childrenSx={{ p: '12px 24px 20px' }}
                  required
                >
                  <Typography
                    variant="sp_value_text"
                    color="b_333"
                    sx={{
                      whiteSpace: 'break-spaces',
                      wordBreak: 'break-all',
                    }}
                  >
                    {incomeTotalizer
                      ? landHouseValue
                      : `〈支払地代〉${numberWithCommas(
                          +land_rent_to_be_paid
                        )}円\n\n〈支払家賃〉${numberWithCommas(
                          +house_rent_to_be_paid
                        )}円\n\n`}
                  </Typography>
                </SPFormItem>
              </Stack>
            )}
        </Stack>

        <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
          <Stack
            sx={{
              paddingRight: 0,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: 'none',
            }}
          >
            {showEditButton && (
              <SPButton
                sx={{
                  py: 1,
                  px: 3,
                  borderRadius: '10px',
                  borderWidth: 2,
                  bgcolor: 'sp_primary_40',
                  border: (theme) =>
                    `1px solid ${theme?.palette?.sp_primary_100_main}`,
                }}
                variant="outlined"
                fullWidth
                onClick={() =>
                  navigate(routeNames.SPStepFive.path, {
                    state: { pathName: window.location.pathname },
                  })
                }
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Icons.SPPencil />
                  <Typography
                    variant="sp_contentButton_text"
                    color="sp_primary_100_main"
                  >
                    {`STEP${checkTitle().slice(0, 2)} を修正する`}
                  </Typography>
                </Stack>
              </SPButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
