import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { FC } from 'react';

export type ResultStatus = {
  text: string;
  active: boolean;
};
interface ExaminationResultProps {
  resultStatus: ResultStatus[];
  isActiveButton?: boolean;
}

export const ExaminationResult: FC<ExaminationResultProps> = ({
  resultStatus,
  isActiveButton,
}) => {
  return (
    <Stack
      justifyContent="end"
      alignItems="center"
      direction="row"
      sx={{ mt: 5 }}
    >
      {resultStatus.map((item, index) => (
        <Stack direction="row" alignItems="center" spacing={2} key={index}>
          <Button
            disabled={!item.active && isActiveButton}
            sx={{
              ml: 2,
              bgcolor:
                item.active && !isActiveButton ? 'sp_secondary_01' : 'gray_09',
              px: 14,
              py: '9px',
              boxShadow: 'none',
              pointerEvents: 'none',
            }}
          >
            <Typography variant="pdf_title" sx={{ color: 'main_white' }}>
              {item.text}
            </Typography>
          </Button>
          {index !== resultStatus.length - 1 && <Icons.ArrowRight />}
        </Stack>
      ))}
    </Stack>
  );
};
