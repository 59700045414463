import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PublicHolidayResponse } from 'services';
import { getPublicHolidays } from '../containers/AuthModal/thunk';
import { publicHolidaysSelector } from '../containers/AuthModal/selectors';
import { useAppDispatch } from './useAppDispatch';

export const usePublicHoliday = (
  year?: number,
  yearLength = 1
): PublicHolidayResponse[] => {
  const dispatch = useAppDispatch();
  const publicHolidays = useSelector(publicHolidaysSelector);

  useEffect(() => {
    if (!year) return;
    Array.from(Array(yearLength), (_, index) => {
      if (publicHolidays?.[year + index]) return;
      dispatch(getPublicHolidays({ year: +year + index }));
    });
  }, [year]);

  if (!year || !publicHolidays) {
    return [];
  }

  return publicHolidays?.[year] || [];
};

export const useAllPublicHoliday = (
  year?: number,
  yearLength = 1
): Record<number, PublicHolidayResponse[]> => {
  const dispatch = useAppDispatch();
  const publicHolidays = useSelector(publicHolidaysSelector);

  useEffect(() => {
    if (!year) return;
    Array.from(Array(yearLength), (_, index) => {
      if (publicHolidays?.[year + index]) return;
      dispatch(getPublicHolidays({ year: +year + index }));
    });
  }, [year]);

  return publicHolidays || {};
};
