import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Filter: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.5H0.0972222M0.0972222 0.5H14L8.16667 6.33333V14.6944L5.93056 12.4583V6.33333L0.0972222 0.5Z"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
