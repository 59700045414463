import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CoOwnerType,
  CurrentResidenceType,
  FamilyType,
  LeaveType,
  LoanBalanceType,
  LoanType,
  NationalityType,
  OccupationDetailType,
  OccupationType,
  PurposeType,
  RelationshipType,
  RepaymentType,
  SexType,
  SupplierType,
  WorkIndustryType,
  ApplicantDetailType,
  FinalTaxReturnType,
  DispatchType,
  JobChangeType,
  ReasonsForNeedingHousingType,
  LoanTargetPropertyType,
  ChildType,
  LandAdvanceType,
  DesiredMonthlyBonusType,
  ResidentStatusType,
  NursingCareType,
  LandOwnershipType,
  PurchasePurposeType,
  PlanningAreaType,
  RebuildingReasonType,
  Flat35ApplicablePlanType,
  MaintenanceType,
  RegionType,
  FinanceAgencyType,
  RelationshipToApplicantType,
} from 'constant';
import { getNewSavedDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { StepSynthesisForm } from 'types';
import {
  getPreliminaries,
  getUploadedFiles,
} from 'components/molecules/DashboardItems/thunk';
import { deleteUser } from 'pages/SPUnsubcribed/thunk';
import { updatePreliminaries } from '../Preliminaries/thunk';
import { logout } from '../../containers/AuthModal/thunk';
import { managerLogout } from '../Login/thunk';

export type InitialState = {
  form: StepSynthesisForm;
  editable: boolean;
  isEditing?: boolean;
};

export const initialPLandAdvancePlan = {
  loan_desired_borrowing_date: '',
  loan_term: '',
  desired_loan_amount: '',
  halfyear_bonus: '',
  repayment_method: RepaymentType.DEFAULT,
};

export const initialStatePair = {
  last_name_kanji: '',
  first_name_kanji: '',
  last_name_kana: '',
  first_name_kana: '',
  nationality: NationalityType.DEFAULT,
  birthday: '',
  relationship_to_applicant: RelationshipToApplicantType.DEFAULT,
  sex: SexType.DEFAULT,
  mobile_phone_number: '',
  home_phone_number: '',
  owner_email: '',
  confirmation_email: '',
  current_residence: CurrentResidenceType.DEFAULT,
  other_handle_method: '',
  monthly_payment: LoanBalanceType.DEFAULT,
  card_loan: LoanBalanceType.DEFAULT,
  rented_house: LoanBalanceType.DEFAULT,
  business_loan: LoanBalanceType.DEFAULT,
  postal_code: '',
  prefecture_kanji: '',
  city_kanji: '',
  district_kanji: '',
  prefecture_kana: '',
  city_kana: '',
  district_kana: '',
  name_other_address_kanji: '',
  name_other_address_kana: '',
  other_address_kanji: '',
  other_address_kana: '',
  occupation: OccupationType.DEFAULT,
  occupation_detail: OccupationDetailType.DEFAULT,
  bonus_income: '',
  last_year_income: '',
  pension_income: '',
  solar_power_income: '',
  total_last_year_income: '',
  business_income: '',
  office_name_kanji: '',
  office_name_kana: '',
  maternity_paternity_leave_status: LeaveType.DEFAULT,
  maternity_paternity_leave_start_time: '',
  maternity_paternity_leave_end_time: '',
  back_to_work_date: '',
  nursing_leave_status: NursingCareType.DEFAULT,
  office_prefecture_kanji: '',
  office_city_kanji: '',
  office_district_kanji: '',
  other_office_address_kanji: '',
  office_prefecture_kana: '',
  office_city_kana: '',
  office_district_kana: '',
  other_office_address_kana: '',
  transfer_office_postal_code: '',
  office_postal_code: '',
  office_phone_number: '',
  number_of_employee: '',
  industry: WorkIndustryType.DEFAULT,
  other_industry: '',
  employment_started_date: '',
  transfer_office_prefecture_kana: '',
  transfer_office_city_kana: '',
  transfer_office_district_kana: '',
  transfer_office_prefecture_kanji: '',
  transfer_office_city_kanji: '',
  transfer_office_other_address_kanji: '',
  transfer_office_other_address_kana: '',
  transfer_office_district_kanji: '',
  transfer_office: DispatchType.DEFAULT,
  transfer_office_name_kanji: '',
  transfer_office_name_kana: '',
  transfer_office_phone_number: '',
  job_change: JobChangeType.DEFAULT,
  job_change_company_name_kanji: '',
  job_change_company_name_kana: '',
  prev_company_year_num: '',
  prev_company_month_num: '',
  prev_company_industry: WorkIndustryType.DEFAULT,
  other_prev_company_industry: '',
  two_years_ago_income: '',
  other_occupation: '',
  other_occupation_detail: '',
  tax_return: FinalTaxReturnType.DEFAULT,
  type_tax_return: [],
  other_type_tax_return: '',
  income_source: [],
  department: '',
  p_borrowing_detail_attributes: {
    loan_term: '',
    temporary_desired_loan_amount: '',
    halfyear_bonus: '',
    repayment_method: RepaymentType.DEFAULT,
    desired_monthly_bonus: DesiredMonthlyBonusType.DEFAULT,
  },
  has_land_advance_plan: LandAdvanceType.DEFAULT,
  p_borrowings_attributes: [],
  applicant_detail_type: ApplicantDetailType.DEFAULT,
  p_resident_attributes: {
    last_name_kanji: '',
    first_name_kanji: '',
    last_name_kana: '',
    first_name_kana: '',
    relationship: RelationshipType.DEFAULT,
    other_relationship: '',
    nationality: NationalityType.DEFAULT,
    birthday: '',
    mobile_phone_number: '',
    home_phone_number: '',
    postal_code: '',
    prefecture_kanji: '',
    city_kanji: '',
    district_kanji: '',
    other_address_kanji: '',
    prefecture_kana: '',
    city_kana: '',
    district_kana: '',
    other_address_kana: '',
    loan_from_japan_housing_finance_agency: FinanceAgencyType.DEFAULT,
  },
  lived_length_year_num: '',
  lived_length_month_num: '',
  reason_acquire_home: ReasonsForNeedingHousingType.DEFAULT,
  other_reason_acquire_home: '',
  business_ability: LoanTargetPropertyType.DEFAULT,
  resident_status: ResidentStatusType.DEFAULT,
  non_resident_reason: '',
  planned_cohabitant: [],
  children_number: ChildType.DEFAULT,
  siblings_number: ChildType.DEFAULT,
  other_relationship: ChildType.DEFAULT,
  other_people_number: ChildType.DEFAULT,
  total_people: ChildType.DEFAULT,
  housemate_number: FamilyType.DEFAULT,
  has_borrowings: LoanBalanceType.DEFAULT,
};
export const initialState: InitialState = {
  form: {
    p_application_header: {
      loan_apply_date: '',
      loan_desired_borrowing_date: '',
      scheduled_date_moving: '',
      loan_type: LoanType.DEFAULT,
      loan_target: PurposeType.DEFAULT,
      additional_cost: '',
      saving_amount: '',
      land_purchase_price: '',
      house_purchase_price: '',
      collateral_address: '',
      mansion_name_kanji: '',
      collateral_land_area: '',
      occupied_area: '',
      accessory_cost: '',
      refinancing_loan_balance: '',
      other_require_funds_breakdown_content: '',
      other_require_funds_breakdown: '',
      relative_donation_amount: '',
      loan_amount: '',
      pair_loan_amount: '',
      other_procurement_breakdown_content: '',
      other_procurement_breakdown: '',
      collateral_floor_area: '',
      collateral_total_floor_area: '',
      land_ownership: LandOwnershipType.DEFAULT,
      purchase_purpose: PurchasePurposeType.DEFAULT,
      planning_area: PlanningAreaType.DEFAULT,
      other_planning_area: '',
      rebuilding_reason: RebuildingReasonType.DEFAULT,
      other_rebuilding_reason: '',
      purchase_contract_date: '',
      flat_35_applicable_plan: Flat35ApplicablePlanType.DEFAULT,
      maintenance_type: MaintenanceType.DEFAULT,
      flat_35_application: [],
      region_type: RegionType.DEFAULT,
      house_upgrade_cost: '',
      deposit_savings_1: '',
      deposit_savings_2: '',
      real_estate_sale_price: '',
      other_saving_amount: '',
      total_require_funds: '',
      total_procurement: '',
      p_application_banks_attributes: [],
      completely_repayment_type: [],
      other_complete_repayment_type: '',
      refund_content: '',
      refund_amount: '',
      house_rent_to_be_paid: '',
      land_rent_to_be_paid: '',
      master_bank_ids: [],
      has_land_advance_plan: LandAdvanceType.DEFAULT,
    },
    main_p_applicant_person: {
      last_name_kanji: '',
      first_name_kanji: '',
      last_name_kana: '',
      first_name_kana: '',
      nationality: NationalityType.DEFAULT,
      birthday: '',
      sex: SexType.DEFAULT,
      mobile_phone_number: '',
      home_phone_number: '',
      owner_email: '',
      confirmation_email: '',
      current_residence: CurrentResidenceType.DEFAULT,
      other_handle_method: '',
      monthly_payment: LoanBalanceType.DEFAULT,
      card_loan: LoanBalanceType.DEFAULT,
      rented_house: LoanBalanceType.DEFAULT,
      business_loan: LoanBalanceType.DEFAULT,
      postal_code: '',
      prefecture_kanji: '',
      city_kanji: '',
      district_kanji: '',
      other_address_kanji: '',
      prefecture_kana: '',
      city_kana: '',
      district_kana: '',
      other_address_kana: '',
      occupation: OccupationType.DEFAULT,
      occupation_detail: OccupationDetailType.DEFAULT,
      office_name_kanji: '',
      office_name_kana: '',
      maternity_paternity_leave_status: LeaveType.DEFAULT,
      maternity_paternity_leave_start_time: '',
      maternity_paternity_leave_end_time: '',
      back_to_work_date: '',
      nursing_leave_status: NursingCareType.DEFAULT,
      office_prefecture_kanji: '',
      other_office_address_kanji: '',
      office_city_kanji: '',
      office_district_kanji: '',
      office_prefecture_kana: '',
      office_city_kana: '',
      office_district_kana: '',
      other_office_address_kana: '',
      office_postal_code: '',
      office_phone_number: '',
      number_of_employee: '',
      industry: WorkIndustryType.DEFAULT,
      other_industry: '',
      employment_started_date: '',
      tax_return: FinalTaxReturnType.DEFAULT,
      type_tax_return: [],
      other_type_tax_return: '',
      income_source: [],
      transfer_office: DispatchType.DEFAULT,
      transfer_office_name_kanji: '',
      transfer_office_name_kana: '',
      transfer_office_phone_number: '',
      job_change: JobChangeType.DEFAULT,
      job_change_company_name_kanji: '',
      job_change_company_name_kana: '',
      prev_company_year_num: '',
      prev_company_month_num: '',
      prev_company_industry: WorkIndustryType.DEFAULT,
      other_prev_company_industry: '',
      transfer_office_postal_code: '',
      transfer_office_prefecture_kana: '',
      transfer_office_city_kana: '',
      transfer_office_district_kana: '',
      transfer_office_prefecture_kanji: '',
      transfer_office_city_kanji: '',
      transfer_office_other_address_kanji: '',
      transfer_office_other_address_kana: '',
      transfer_office_district_kanji: '',
      other_occupation: '',
      other_occupation_detail: '',
      last_year_income: '',
      bonus_income: '',
      pension_income: '',
      solar_power_income: '',
      total_last_year_income: '',
      business_income: '',
      two_years_ago_income: '',
      department: '',
      p_borrowing_detail_attributes: {
        loan_term: '',
        temporary_desired_loan_amount: '',
        halfyear_bonus: '',
        repayment_method: RepaymentType.DEFAULT,
        desired_monthly_bonus: DesiredMonthlyBonusType.DEFAULT,
      },
      p_land_advance_plan_attributes: initialPLandAdvancePlan,
      p_borrowings_attributes: [],
      has_join_guarantor: CoOwnerType.DEFAULT,
      join_guarantor_count: SupplierType.DEFAULT,
      p_join_guarantors_attributes: [],
      applicant_detail_type: ApplicantDetailType.MAIN,
      p_resident_attributes: {
        last_name_kanji: '',
        first_name_kanji: '',
        last_name_kana: '',
        first_name_kana: '',
        relationship: RelationshipType.DEFAULT,
        other_relationship: '',
        nationality: NationalityType.DEFAULT,
        birthday: '',
        mobile_phone_number: '',
        home_phone_number: '',
        postal_code: '',
        prefecture_kanji: '',
        city_kanji: '',
        district_kanji: '',
        other_address_kanji: '',
        prefecture_kana: '',
        city_kana: '',
        district_kana: '',
        other_address_kana: '',
        loan_from_japan_housing_finance_agency: FinanceAgencyType.DEFAULT,
      },
      lived_length_year_num: '',
      lived_length_month_num: '',
      reason_acquire_home: ReasonsForNeedingHousingType.DEFAULT,
      other_reason_acquire_home: '',
      business_ability: LoanTargetPropertyType.DEFAULT,
      resident_status: ResidentStatusType.DEFAULT,
      non_resident_reason: '',
      planned_cohabitant: [],
      children_number: ChildType.DEFAULT,
      siblings_number: ChildType.DEFAULT,
      other_relationship: ChildType.DEFAULT,
      other_people_number: ChildType.DEFAULT,
      total_people: ChildType.DEFAULT,
      housemate_number: FamilyType.DEFAULT,
      has_borrowings: LoanBalanceType.DEFAULT,
    },
    pair_p_applicant_person: initialStatePair,
    p_referral_agency: {
      name_kanji: '',
      sale_agent_name_kanji: '',
      store_name_kanji: '',
      manager_name_kanji: '',
      phone_number_2: '',
      phone_number: '',
      manager_email: '',
    },
    master_bank_codes: [],
  },
  editable: false,
  isEditing: false,
};

const stepSynthesisSlice = createSlice({
  name: 'stepSynthesis',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<StepSynthesisForm>) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    newForm: (state) => {
      state.editable = false;
      state.isEditing = false;
      state.form = initialState.form;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewSavedDraft.fulfilled, (state, action) => {
      const { id, ...data } = action.payload.data;
      state.form = {
        ...state.form,
        ...data,
      };
    });
    builder.addCase(getPreliminaries.fulfilled, (state, action) => {
      const { editable, ...data } = action.payload.data;
      state.editable = editable;
      state.isEditing = true;
      state.form = {
        ...state.form,
        ...data,
      };
    });
    builder.addCase(getUploadedFiles.fulfilled, (state, action) => {
      state.isEditing = true;
    });
    builder.addCase(updatePreliminaries.fulfilled, (state) => {
      state.editable = false;
      state.isEditing = false;
    });
    builder.addCase(managerLogout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
      state.editable = false;
      state.isEditing = false;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
      state.editable = false;
      state.isEditing = false;
    });
  },
});

export const { saveForm, newForm } = stepSynthesisSlice.actions;

export default stepSynthesisSlice.reducer;
