import { FC, useCallback, useMemo } from 'react';
import { Container, Card, Avatar, Stack, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StepLayout } from 'containers';
import { FormikProvider, useFormik } from 'formik';
import { Button, InputFile, UlStyleImages } from 'components';
import { routeNames } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { StepFourEditForm, StepFourForm } from 'types';
import {
  imgA,
  imgB,
  imgB2,
  imgC,
  imgD1st,
  imgD2nd,
  imgD3rd,
  imgE,
  imgF,
  imgG1,
  imgG2,
  imgStepOne,
  imgStepThree,
  imgStepTwo,
} from 'assets';
import {
  isEditingSelector,
  stepSynthesisSelector,
} from 'pages/StepSynthesisInformation/selectors';
import { toast } from 'react-toastify';
import { LoanBalanceType, LoanType, NationalityType } from 'constant';
import { UploadedFiles } from 'services';
import { currentPremilinarySelectors } from 'pages/Preliminaries/selectors';
import { initialStatePair } from 'pages/StepSynthesisInformation/slice';
import { stepFourEditSelector, stepFourSelector } from '../selectors';
import { submitFormData, updateUploadedFiles } from '../thunk';
import { validationSchema } from './validationSchema';

type ImageItemProps = {
  src: string;
  name?: string;
};

const ImageItem: FC<ImageItemProps> = ({ src, name }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: { mobile: '100px', tablet: '70px' },
        pb: { mobile: '20px' },
      }}
    >
      <Avatar
        variant="square"
        src={src}
        sx={{
          width: 73,
          height: 102,
          '.MuiAvatar-img': {
            objectFit: 'contain',
          },
          mr: '14px',
        }}
      />
      <Typography>{name}</Typography>
    </Stack>
  );
};

type CheckFormType = {
  loan_type: LoanType;
  main_p_applicant_person: {
    has_borrowings: LoanBalanceType;
    nationality: NationalityType;
  };
  pair_p_applicant_person: {
    has_borrowings: LoanBalanceType;
    nationality: NationalityType;
  };
};

type EditFilesType = {
  editFiles: StepFourEditForm;
};

const StepFourBPage: FC = () => {
  const initialValues = useSelector(stepFourSelector);
  const stepFourEdit = useSelector(stepFourEditSelector);
  const stepSynthesis = useSelector(stepSynthesisSelector);
  const isEditing = useSelector(isEditingSelector);
  const selectedPreliminary = useSelector(currentPremilinarySelectors);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loan_type } = stepSynthesis.p_application_header;

  const formik = useFormik<StepFourForm & CheckFormType & EditFilesType>({
    initialValues: {
      ...initialValues,
      loan_type,
      main_p_applicant_person: {
        has_borrowings: stepSynthesis.main_p_applicant_person.has_borrowings,
        nationality: stepSynthesis.main_p_applicant_person.nationality,
      },
      pair_p_applicant_person: {
        has_borrowings: stepSynthesis.pair_p_applicant_person.has_borrowings,
        nationality: stepSynthesis.pair_p_applicant_person.nationality,
      },
      editFiles: stepFourEdit,
    },
    validationSchema,
    async onSubmit(values) {
      handleSubmitFormData(values);
    },
  });
  const handleSubmitFormData = async (params: StepFourForm) => {
    const data = new FormData();

    data.append(
      'p_application_header',
      JSON.stringify(stepSynthesis.p_application_header)
    );
    data.append(
      'main_p_applicant_person',
      JSON.stringify(stepSynthesis.main_p_applicant_person)
    );

    if (loan_type !== LoanType.ONE) {
      data.append(
        'pair_p_applicant_person',
        JSON.stringify(stepSynthesis.pair_p_applicant_person)
      );
    } else {
      data.append('pair_p_applicant_person', JSON.stringify(initialStatePair));
    }
    data.append(
      'master_bank_codes',
      JSON.stringify(stepSynthesis.master_bank_codes)
    );
    data.append(
      'p_referral_agency',
      JSON.stringify(stepSynthesis.p_referral_agency)
    );
    data.append(
      'master_bank_codes',
      JSON.stringify(stepSynthesis.master_bank_codes)
    );

    params.main_personal_information_file.forEach((file) => {
      data.append('main_personal_information_file[]', file);
    });
    params.main_first_pamphlet_file.forEach((file) =>
      data.append('main_first_pamphlet_file[]', file)
    );
    params.main_second_pamphlet_file.forEach((file) =>
      data.append('main_second_pamphlet_file[]', file)
    );
    params.main_insurance_file.forEach((file) =>
      data.append('main_insurance_file[]', file)
    );
    params.main_first_income_file.forEach((file) =>
      data.append('main_first_income_file[]', file)
    );
    params.main_second_income_file.forEach((file) =>
      data.append('main_second_income_file[]', file)
    );
    params.main_third_income_file.forEach((file) =>
      data.append('main_third_income_file[]', file)
    );
    params.main_referral_agency_file.forEach((file) =>
      data.append('main_referral_agency_file[]', file)
    );
    params.main_residence_file.forEach((file) =>
      data.append('main_residence_file[]', file)
    );
    params.main_first_borrowing_file.forEach((file) =>
      data.append('main_first_borrowing_file[]', file)
    );
    params.main_second_borrowing_file.forEach((file) =>
      data.append('main_second_borrowing_file[]', file)
    );
    params.pair_personal_information_file.forEach((file) => {
      data.append('pair_personal_information_file[]', file);
    });
    params.pair_first_pamphlet_file.forEach((file) =>
      data.append('pair_first_pamphlet_file[]', file)
    );
    params.pair_second_pamphlet_file.forEach((file) =>
      data.append('pair_second_pamphlet_file[]', file)
    );
    params.pair_insurance_file.forEach((file) =>
      data.append('pair_insurance_file[]', file)
    );
    params.pair_first_income_file.forEach((file) =>
      data.append('pair_first_income_file[]', file)
    );
    params.pair_second_income_file.forEach((file) =>
      data.append('pair_second_income_file[]', file)
    );
    params.pair_third_income_file.forEach((file) =>
      data.append('pair_third_income_file[]', file)
    );
    params.pair_referral_agency_file.forEach((file) =>
      data.append('pair_referral_agency_file[]', file)
    );
    params.pair_residence_file.forEach((file) =>
      data.append('pair_residence_file[]', file)
    );
    params.pair_first_borrowing_file.forEach((file) =>
      data.append('pair_first_borrowing_file[]', file)
    );
    params.pair_second_borrowing_file.forEach((file) =>
      data.append('pair_second_borrowing_file[]', file)
    );

    const result = await dispatch(submitFormData(data));
    if (submitFormData.fulfilled.match(result)) {
      toast.success('フォームを送信しました');
      navigate(routeNames.Complete.path);
    } else {
      toast.error('エラーが発生しました。');
    }
  };

  const handleChangeFiles = useCallback(
    (name: keyof StepFourForm | keyof StepFourEditForm) => (files: File[]) => {
      if (isEditing) {
        formik.setFieldValue(`editFiles.${name}`, files);
      } else {
        formik.setFieldValue(name, files);
      }
    },
    [formik, isEditing]
  );

  const handleSubmitEditUploadedFiles = async (
    params: StepFourEditForm,
    id: string
  ) => {
    const data = new FormData();

    params.main_personal_information_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_personal_information_file[]', file);
      } else {
        data.append('main_personal_information_file[]', JSON.stringify(file));
      }
    });
    params.main_first_pamphlet_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_first_pamphlet_file[]', file);
      } else {
        data.append('main_first_pamphlet_file[]', JSON.stringify(file));
      }
    });
    params.main_second_pamphlet_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_second_pamphlet_file[]', file);
      } else {
        data.append('main_second_pamphlet_file[]', JSON.stringify(file));
      }
    });
    params.main_insurance_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_insurance_file[]', file);
      } else {
        data.append('main_insurance_file[]', JSON.stringify(file));
      }
    });
    params.main_first_income_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_first_income_file[]', file);
      } else {
        data.append('main_first_income_file[]', JSON.stringify(file));
      }
    });
    params.main_second_income_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_second_income_file[]', file);
      } else {
        data.append('main_second_income_file[]', JSON.stringify(file));
      }
    });
    params.main_third_income_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_third_income_file[]', file);
      } else {
        data.append('main_third_income_file[]', JSON.stringify(file));
      }
    });
    params.main_referral_agency_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_referral_agency_file[]', file);
      } else {
        data.append('main_referral_agency_file[]', JSON.stringify(file));
      }
    });
    params.main_residence_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_residence_file[]', file);
      } else {
        data.append('main_residence_file[]', JSON.stringify(file));
      }
    });
    params.main_first_borrowing_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_first_borrowing_file[]', file);
      } else {
        data.append('main_first_borrowing_file[]', JSON.stringify(file));
      }
    });
    params.main_second_borrowing_file.forEach((file) => {
      if (file instanceof File) {
        data.append('main_second_borrowing_file[]', file);
      } else {
        data.append('main_second_borrowing_file[]', JSON.stringify(file));
      }
    });
    params.pair_personal_information_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_personal_information_file[]', file);
      } else {
        data.append('pair_personal_information_file[]', JSON.stringify(file));
      }
    });
    params.pair_first_pamphlet_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_first_pamphlet_file[]', file);
      } else {
        data.append('pair_first_pamphlet_file[]', JSON.stringify(file));
      }
    });
    params.pair_second_pamphlet_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_second_pamphlet_file[]', file);
      } else {
        data.append('pair_second_pamphlet_file[]', JSON.stringify(file));
      }
    });
    params.pair_insurance_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_insurance_file[]', file);
      } else {
        data.append('pair_insurance_file[]', JSON.stringify(file));
      }
    });
    params.pair_first_income_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_first_income_file[]', file);
      } else {
        data.append('pair_first_income_file[]', JSON.stringify(file));
      }
    });
    params.pair_second_income_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_second_income_file[]', file);
      } else {
        data.append('pair_second_income_file[]', JSON.stringify(file));
      }
    });
    params.pair_third_income_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_third_income_file[]', file);
      } else {
        data.append('pair_third_income_file[]', JSON.stringify(file));
      }
    });
    params.pair_referral_agency_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_referral_agency_file[]', file);
      } else {
        data.append('pair_referral_agency_file[]', JSON.stringify(file));
      }
    });
    params.pair_residence_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_residence_file[]', file);
      } else {
        data.append('pair_residence_file[]', JSON.stringify(file));
      }
    });
    params.pair_first_borrowing_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_first_borrowing_file[]', file);
      } else {
        data.append('pair_first_borrowing_file[]', JSON.stringify(file));
      }
    });
    params.pair_second_borrowing_file.forEach((file) => {
      if (file instanceof File) {
        data.append('pair_second_borrowing_file[]', file);
      } else {
        data.append('pair_second_borrowing_file[]', JSON.stringify(file));
      }
    });

    const result = await dispatch(updateUploadedFiles({ id, data }));
    if (updateUploadedFiles.fulfilled.match(result)) {
      toast.success('フォームを送信しました');
      navigate(routeNames.Dashboard.path);
    } else {
      toast.error('エラーが発生しました。');
    }
  };

  const checkDisable = useMemo(() => {
    if (isEditing) {
      return false;
    } else {
      return !(formik.isValid && formik.dirty);
    }
  }, [isEditing, formik.isValid, formik.dirty]);

  return (
    <StepLayout
      title="資料再アップロード"
      isEditing={isEditing}
      hasModalSaveDraft={false}
      footer={{
        left: {
          onClick: () => {
            if (isEditing) {
              return navigate(routeNames.Dashboard.path);
            }
            navigate(routeNames.StepFourA.path);
          },
        },
        right: false,
      }}
    >
      <FormikProvider value={formik}>
        <Container sx={{ px: 11 }}>
          <Stack alignItems="center" sx={{ my: 6 }}>
            <Typography variant="h2" color="normal_text">
              スマートフォン・タブレット・PC等で撮影した画像データ・PDFのアップロード方法
            </Typography>
          </Stack>

          <Card
            elevation={0}
            sx={{
              borderRadius: '5px',
              margin: '0 auto',
              width: '100%',
              bgcolor: 'main_white',
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
            }}
          >
            {/*STEP1*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack
                  sx={{
                    ml: { mobile: '20px', tablet: '30px' },
                    mr: { mobile: '20px', tablet: 0 },
                  }}
                >
                  <Stack
                    direction={{ mobile: 'column', tablet: 'row' }}
                    alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                    spacing={7}
                  >
                    <Typography variant="title_avatar_card" color="blue03">
                      STEP1
                    </Typography>
                    <Typography variant="h3" color="normal_text">
                      スマートフォン・タブレット・PC等で写真を撮影　
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7 }}>
                    <Typography variant="liststyle">
                      提出書類をスマートフォン・タブレット・PC等で撮影し、保存してください。
                    </Typography>
                    <Typography variant="liststyle">
                      手ブレ等により不鮮明な部分がないか、撮影後の画像で記載項目が判断できることをご確認ください。
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ mobile: 'column', tablet: 'row' }}
                    alignItems="center"
                    spacing={4}
                    sx={{ mt: 7 }}
                  >
                    <Stack spacing={1}>
                      <Typography variant="liststyle" color="normal_text">
                        撮影のポイント
                      </Typography>
                      <UlStyleImages>
                        {notes.shootingPoint.map((note, index) => (
                          <li key={index}>
                            <Typography variant="text_note" color="normal_text">
                              {note}
                            </Typography>
                          </li>
                        ))}
                      </UlStyleImages>
                    </Stack>
                    <Avatar
                      variant="square"
                      src={imgStepOne}
                      sx={{
                        width: { mobile: 209, tablet: 509 },
                        height: 209,
                        '.MuiAvatar-img': {
                          objectFit: 'contain',
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>
            {/*STEP-2*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack
                  sx={{
                    ml: { mobile: '20px', tablet: '30px' },
                    mr: { mobile: '20px', tablet: 0 },
                  }}
                >
                  <Stack
                    direction={{ mobile: 'column', tablet: 'row' }}
                    alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                    spacing={7}
                  >
                    <Typography variant="title_avatar_card" color="blue03">
                      STEP2
                    </Typography>
                    <Typography variant="h3" color="normal_text">
                      撮影した画像をアップロード
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7, mb: 5 }}>
                    <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                      <Typography variant="liststyle" color="normal_text">
                        アップロード画面より、
                      </Typography>
                      <Typography variant="textstyle_XL" color="blue03">
                        STEP1
                      </Typography>
                      <Typography variant="liststyle" color="normal_text">
                        で撮影した写真をアップロードしてください。
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                      spacing={2}
                    >
                      <Typography variant="liststyle" color="normal_text">
                        下記図内の
                      </Typography>
                      <Stack
                        alignItems="center"
                        sx={{
                          bgcolor: 'blue03',
                          borderRadius: 1,
                          width: 196,
                          height: 23,
                        }}
                      >
                        <Typography color="main_white">
                          ファイルを選択する
                        </Typography>
                      </Stack>
                      <Typography variant="liststyle" color="normal_text">
                        ボタンを押して撮影した写真を選択して下さい。
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                      spacing={2}
                    >
                      <Typography variant="liststyle" color="normal_text">
                        または、PCの場合はドラッグ&ドロップで下記図の
                      </Typography>
                      <Box
                        sx={{
                          width: 142,
                          height: 15,
                          border: '1px dashed',
                          borderColor: 'blue03',
                        }}
                      />
                      <Typography variant="liststyle" color="normal_text">
                        枠内に置くこともできます。
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack sx={{ mr: { mobile: 5, tablet: 12 } }}>
                    <Avatar
                      variant="square"
                      src={imgStepTwo}
                      sx={{
                        width: '100%',
                        height: 162,
                        '.MuiAvatar-img': {
                          objectFit: 'contain',
                        },
                      }}
                    />
                  </Stack>
                  <Stack
                    sx={{ mt: 7, mr: { mobile: 5, tablet: 0 } }}
                    spacing={1}
                  >
                    <Typography variant="h4" color="normal_text">
                      アップロード可能なファイル形式
                    </Typography>
                    <Stack
                      sx={{
                        bgcolor: 'main_white',
                        p: '10px 2px 12px 14px',
                        width: { mobile: '100%', tablet: 302 },
                      }}
                    >
                      <Typography>JPEG（.jpg｜.jpeg）／ PDF（.pdf）</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Stack>

            {/*STEP-3*/}
            <Stack sx={{ bgcolor: 'bg_off', my: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack
                  sx={{
                    ml: { mobile: '20px', tablet: '30px' },
                    mr: { mobile: '20px', tablet: 0 },
                  }}
                >
                  <Stack
                    direction={{ mobile: 'column', tablet: 'row' }}
                    alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                    spacing={7}
                  >
                    <Typography variant="title_avatar_card" color="blue03">
                      STEP3
                    </Typography>
                    <Typography variant="h3" color="normal_text">
                      アップロード完了
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7, mb: 5 }}>
                    <Typography variant="liststyle" color="normal_text">
                      こちらの画面が表示されたら、アップロード完了となります。、
                    </Typography>
                  </Stack>
                  <Stack sx={{ mr: { mobile: 1, tablet: 12 } }}>
                    <Avatar
                      variant="square"
                      src={imgStepThree}
                      sx={{
                        width: '100%',
                        height: 658,
                        '.MuiAvatar-img': {
                          objectFit: 'contain',
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Card>

          <Stack sx={{ my: 13 }} spacing={5}>
            <Typography variant="title_warning" color="normal_text">
              次の資料に関する画像データまたはPDFのアップロードをお願いします
            </Typography>
            <Typography variant="liststyle">
              ※以下の資料を撮影した画像データまたはPDFをmilize
              Panelにアップロードしてください。
            </Typography>
          </Stack>

          <Card
            elevation={0}
            sx={{
              borderRadius: '5px',
              margin: '0 auto',
              width: '100%',
              bgcolor: 'main_white',
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
              mb: 20,
            }}
          >
            {/*IMAGES-A*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                  <Stack spacing={7} direction="row" alignItems="center">
                    <Typography variant="title_avatar_card" color="blue03">
                      A
                    </Typography>
                    <Typography variant="text_avatar_card" color="normal_text">
                      運転免許証 または 住民基本台帳カード または
                      マイナンバーカード
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7 }}>
                    <Typography variant="textstyle" color="normal_text">
                      {`運転免許証・住民基本台帳カードの場合：表面 + 裏面（裏面に記載事項がある場合）\nマイナンバーカードの場合：表面のみ`}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgA} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_personal_information_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_personal_information_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_personal_information_file"
                      onChange={handleChangeFiles(
                        'main_personal_information_file'
                      )}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>

            {/*IMAGES-B*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                  <Stack spacing={7} direction="row" alignItems="center">
                    <Typography variant="title_avatar_card" color="blue03">
                      B
                    </Typography>
                    <Typography variant="text_avatar_card" color="normal_text">
                      土地・建物に関する資料
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7 }}>
                    <Typography variant="textstyle" color="normal_text">
                      ご購入予定の土地・建物に関するチラシ・パンフレットなど
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgB} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_first_pamphlet_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_first_pamphlet_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_first_pamphlet_file"
                      onChange={handleChangeFiles('main_first_pamphlet_file')}
                    />
                  </Stack>
                </Stack>

                <Stack sx={{ mt: 10, ml: { mobile: '20px', tablet: '31px' } }}>
                  <Typography variant="textstyle" color="normal_text">
                    提携会社（不動産会社・住宅メーカー等）さまにご用意いただく物件書類（登記簿謄本等）
                  </Typography>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgB2} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_second_pamphlet_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_second_pamphlet_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_second_pamphlet_file"
                      onChange={handleChangeFiles('main_second_pamphlet_file')}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>

            {/*IMAGES-C*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                  <Stack spacing={7} direction="row" alignItems="center">
                    <Typography variant="title_avatar_card" color="blue03">
                      C
                    </Typography>
                    <Typography variant="text_avatar_card" color="normal_text">
                      健康保険証
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7 }}>
                    <Typography variant="textstyle" color="normal_text">
                      勤務先の在籍確認・入社年月日確認のためにご提出いただきます。
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgC} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_insurance_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_insurance_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_insurance_file"
                      onChange={handleChangeFiles('main_insurance_file')}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>

            {/*IMGAGES-D*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                  <Stack spacing={7} direction="row" alignItems="center">
                    <Typography variant="title_avatar_card" color="blue03">
                      D
                    </Typography>
                    <Typography variant="text_avatar_card" color="normal_text">
                      収入に関する資料
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    sx={{ mt: 7, mb: 17, pr: { mobile: 2, tablet: 10 } }}
                  >
                    <Stack
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                      spacing={1}
                    >
                      <Typography variant="text_upload_file">1.</Typography>
                      <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                        <Typography
                          variant="text_upload_file"
                          color="normal_text"
                        >
                          一般企業や官公庁に勤務する給与所得者の方は
                        </Typography>
                        <Typography variant="h4">
                          「源泉徴収票（前年度１期分）」
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                      spacing={1}
                    >
                      <Typography variant="text_upload_file">2.</Typography>
                      <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                        <Typography
                          variant="text_upload_file"
                          color="normal_text"
                        >
                          給与所得者以外の個人事業主（フリーランス）の方は
                        </Typography>
                        <Typography variant="h4">
                          「確定申告書３期分」
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography variant="text_upload_file">3.</Typography>
                      <Stack direction="column">
                        <Typography
                          variant="text_upload_file"
                          color="normal_text"
                        >
                          給与所得者であるが、給与所得以外（不動産・事業所得）の所得があるなどの理由により確定申告を
                          行なっている方（各種控除のための申告は除く）は
                        </Typography>
                        <Typography variant="h4">
                          「確定申告書３期分」
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                      spacing={1}
                    >
                      <Typography variant="text_upload_file">4.</Typography>
                      <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                        <Typography
                          variant="text_upload_file"
                          color="normal_text"
                        >
                          会社役員の方は「源泉徴収票」と
                        </Typography>
                        <Typography variant="h4" color="normal_text">
                          「源泉徴収票」
                        </Typography>
                        <Typography
                          variant="text_upload_file"
                          color="normal_text"
                        >
                          と
                        </Typography>
                        <Typography variant="h4">
                          「法人決算書３期分」
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems={{ mobile: 'flex-start', tablet: 'center' }}
                      spacing={1}
                    >
                      <Typography variant="text_upload_file">5.</Typography>
                      <Stack direction={{ mobile: 'column', tablet: 'row' }}>
                        <Typography
                          variant="text_upload_file"
                          color="normal_text"
                        >
                          親族が経営する企業に勤務する方は
                        </Typography>
                        <Typography variant="h4">「源泉徴収票」</Typography>
                        <Typography
                          variant="text_upload_file"
                          color="normal_text"
                        >
                          と
                        </Typography>
                        <Typography variant="h4">
                          「法人決算書３期分」
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgD1st} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_first_income_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_first_income_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_first_income_file"
                      onChange={handleChangeFiles('main_first_income_file')}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '50px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgD2nd} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_second_income_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_second_income_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_second_income_file"
                      onChange={handleChangeFiles('main_second_income_file')}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '50px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgD3rd} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_third_income_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_third_income_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_third_income_file"
                      onChange={handleChangeFiles('main_third_income_file')}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>

            {/*IMAGES-E*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                  <Stack spacing={7} direction="row" alignItems="center">
                    <Typography variant="title_avatar_card" color="blue03">
                      E
                    </Typography>
                    <Typography variant="text_avatar_card" color="normal_text">
                      提携会社（不動産会社・住宅メーカー等）の担当者名刺
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7 }}>
                    <Typography variant="textstyle" color="normal_text">
                      「提携会社（不動産会社・住宅メーカー等）について」の各項目にご記入いただいた場合は提出不要です。
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgE} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_referral_agency_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_referral_agency_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_referral_agency_file"
                      onChange={handleChangeFiles('main_referral_agency_file')}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>

            {/*IMAGES-F*/}
            <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                  <Stack spacing={7} direction="row" alignItems="center">
                    <Typography variant="title_avatar_card" color="blue03">
                      F
                    </Typography>
                    <Typography variant="text_avatar_card" color="normal_text">
                      外国籍の方の永住権に関する資料
                    </Typography>
                  </Stack>
                  <Stack sx={{ mt: 7 }}>
                    <Typography variant="textstyle" color="normal_text">
                      永住権証明書、または特別永住権証明書など
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgF} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_residence_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_residence_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_residence_file"
                      onChange={handleChangeFiles('main_residence_file')}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>

            {/*IMAGES-G*/}
            <Stack sx={{ bgcolor: 'bg_off', my: 5, mx: 5 }}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 1,
                  margin: '0 auto',
                  bgcolor: 'bg_off',
                  p: '22px 0px 54px 0px',
                  width: '100%',
                }}
              >
                <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                  <Stack spacing={7} direction="row" alignItems="center">
                    <Typography variant="title_avatar_card" color="blue03">
                      G
                    </Typography>
                    <Typography variant="text_avatar_card" color="normal_text">
                      現在ご利用中のローンの返済予定表・利用明細等
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: 17, mb: 14, width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgG1} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_first_borrowing_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_first_borrowing_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_first_borrowing_file"
                      onChange={handleChangeFiles('main_first_borrowing_file')}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction={{ mobile: 'column', tablet: 'row' }}
                  sx={{ mt: '11px', width: '100%' }}
                >
                  <Stack>
                    <ImageItem src={imgG2} />
                  </Stack>
                  <Stack
                    sx={{
                      flex: { tablet: 1 },
                      mr: '41px',
                      width: { mobile: '100%' },
                      borderWidth: 1,
                      borderColor: 'h_blugreen',
                      borderStyle: 'dashed',
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: '5px',
                        mx: '15px',
                        mt: '15px',
                        mb: '30px',
                      }}
                    >
                      {isEditing
                        ? formik.values.editFiles.main_second_borrowing_file?.map(
                            (image: UploadedFiles | File) => {
                              if (image instanceof File)
                                return (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                );
                              return (
                                <ImageItem
                                  key={image.filename}
                                  name={image.filename}
                                  src={image.path}
                                />
                              );
                            }
                          )
                        : formik.values.main_second_borrowing_file?.map(
                            (image: File) => (
                              <ImageItem
                                key={image.name}
                                name={image.name}
                                src={URL.createObjectURL(image)}
                              />
                            )
                          )}
                    </Stack>
                    <InputFile
                      name="main_second_borrowing_file"
                      onChange={handleChangeFiles('main_second_borrowing_file')}
                    />
                  </Stack>
                </Stack>
              </Card>
            </Stack>
            {/*BUTTON-SUBMIT*/}
            {(stepSynthesis.p_application_header.loan_type === LoanType.ONE ||
              stepFourEdit.loan_type === LoanType.ONE) && (
              <Stack
                alignItems="center"
                spacing={12}
                sx={{ mb: 13, mx: 4, mt: '50px' }}
              >
                <Button
                  disabled={checkDisable}
                  sx={{
                    bgcolor: 'sh_red',
                    width: { mobile: '100%', tablet: 426, desktop: 426 },
                    minHeight: 55,
                  }}
                  onClick={() => {
                    if (isEditing && !!selectedPreliminary.id) {
                      handleSubmitEditUploadedFiles(
                        formik.values.editFiles,
                        selectedPreliminary.id
                      );
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                >
                  <Typography variant="button_title_review" color="main_white">
                    {isEditing
                      ? '住信SBIネット銀行へ送信する'
                      : ' 住信SBIネット銀行へ申込む'}
                  </Typography>
                </Button>
              </Stack>
            )}
          </Card>

          {((stepSynthesis.p_application_header.loan_type !== LoanType.ONE &&
            stepSynthesis.p_application_header.loan_type !==
              LoanType.DEFAULT) ||
            (isEditing && stepFourEdit.loan_type !== LoanType.ONE)) && (
            <>
              <Stack
                sx={{
                  bgcolor: 'pale_blue',
                  py: '21px',
                  pl: '20px',
                  borderRadius: '5px',
                }}
              >
                <Typography variant="textButtonTopPage">
                  「ペアローンの申込人」の方に関する資料をアップロードしてください。
                </Typography>
              </Stack>
              <Stack sx={{ mt: 7, mb: 13 }}>
                <Typography variant="liststyle">
                  ※以下の資料を撮影した画像データまたはPDFをmilize
                  Panelにアップロードしてください。
                </Typography>
              </Stack>
              <Card
                elevation={0}
                sx={{
                  borderRadius: '5px',
                  margin: '0 auto',
                  width: '100%',
                  bgcolor: 'main_white',
                  boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
                  mb: 20,
                }}
              >
                {/*IMAGES-A*/}
                <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 1,
                      margin: '0 auto',
                      bgcolor: 'bg_off',
                      p: '22px 0px 54px 0px',
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                      <Stack spacing={7} direction="row" alignItems="center">
                        <Typography variant="title_avatar_card" color="blue03">
                          A
                        </Typography>
                        <Typography
                          variant="text_avatar_card"
                          color="normal_text"
                        >
                          運転免許証 または 住民基本台帳カード または
                          マイナンバーカード
                        </Typography>
                      </Stack>
                      <Stack sx={{ mt: 7 }}>
                        <Typography variant="textstyle" color="normal_text">
                          {`運転免許証・住民基本台帳カードの場合：表面 + 裏面（裏面に記載事項がある場合）\nマイナンバーカードの場合：表面のみ`}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgA} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_personal_information_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_personal_information_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_personal_information_file"
                          onChange={handleChangeFiles(
                            'pair_personal_information_file'
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Stack>

                {/*IMAGES-B*/}
                <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 1,
                      margin: '0 auto',
                      bgcolor: 'bg_off',
                      p: '22px 0px 54px 0px',
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                      <Stack spacing={7} direction="row" alignItems="center">
                        <Typography variant="title_avatar_card" color="blue03">
                          B
                        </Typography>
                        <Typography
                          variant="text_avatar_card"
                          color="normal_text"
                        >
                          土地・建物に関する資料
                        </Typography>
                      </Stack>
                      <Stack sx={{ mt: 7 }}>
                        <Typography variant="textstyle" color="normal_text">
                          ご購入予定の土地・建物に関するチラシ・パンフレットなど
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgB} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_first_pamphlet_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_first_pamphlet_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_first_pamphlet_file"
                          onChange={handleChangeFiles(
                            'pair_first_pamphlet_file'
                          )}
                        />
                      </Stack>
                    </Stack>

                    <Stack
                      sx={{ mt: 10, ml: { mobile: '20px', tablet: '31px' } }}
                    >
                      <Typography variant="textstyle" color="normal_text">
                        提携会社（不動産会社・住宅メーカー等）さまにご用意いただく物件書類（登記簿謄本等）
                      </Typography>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgB2} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_second_pamphlet_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_second_pamphlet_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_second_pamphlet_file"
                          onChange={handleChangeFiles(
                            'pair_second_pamphlet_file'
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Stack>

                {/*IMAGES-C*/}
                <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 1,
                      margin: '0 auto',
                      bgcolor: 'bg_off',
                      p: '22px 0px 54px 0px',
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                      <Stack spacing={7} direction="row" alignItems="center">
                        <Typography variant="title_avatar_card" color="blue03">
                          C
                        </Typography>
                        <Typography
                          variant="text_avatar_card"
                          color="normal_text"
                        >
                          健康保険証
                        </Typography>
                      </Stack>
                      <Stack sx={{ mt: 7 }}>
                        <Typography variant="textstyle" color="normal_text">
                          勤務先の在籍確認・入社年月日確認のためにご提出いただきます。
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgC} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_insurance_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_insurance_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_insurance_file"
                          onChange={handleChangeFiles('pair_insurance_file')}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Stack>

                {/*IMGAGES-D*/}
                <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 1,
                      margin: '0 auto',
                      bgcolor: 'bg_off',
                      p: '22px 0px 54px 0px',
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                      <Stack spacing={7} direction="row" alignItems="center">
                        <Typography variant="title_avatar_card" color="blue03">
                          D
                        </Typography>
                        <Typography
                          variant="text_avatar_card"
                          color="normal_text"
                        >
                          収入に関する資料
                        </Typography>
                      </Stack>
                      <Stack
                        spacing={1}
                        sx={{ mt: 7, mb: 17, pr: { mobile: 2, tablet: 10 } }}
                      >
                        <Stack
                          direction="row"
                          alignItems={{
                            mobile: 'flex-start',
                            tablet: 'center',
                          }}
                          spacing={1}
                        >
                          <Typography variant="text_upload_file">1.</Typography>
                          <Stack
                            direction={{ mobile: 'column', tablet: 'row' }}
                          >
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              一般企業や官公庁に勤務する給与所得者の方は
                            </Typography>
                            <Typography variant="h4">
                              「源泉徴収票（前年度１期分）」
                            </Typography>
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              のご提出が必須となります。
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems={{
                            mobile: 'flex-start',
                            tablet: 'center',
                          }}
                          spacing={1}
                        >
                          <Typography variant="text_upload_file">2.</Typography>
                          <Stack
                            direction={{ mobile: 'column', tablet: 'row' }}
                          >
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              給与所得者以外の個人事業主（フリーランス）の方は
                            </Typography>
                            <Typography variant="h4">
                              「確定申告書３期分」
                            </Typography>
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              のご提出が必須となります。
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Typography variant="text_upload_file">3.</Typography>
                          <Stack direction="column">
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              給与所得者であるが、給与所得以外（不動産・事業所得）の所得があるなどの理由により確定申告を
                              行なっている方（各種控除のための申告は除く）は
                            </Typography>
                            <Typography variant="h4">
                              「確定申告書３期分」
                            </Typography>
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              のご提出が必須となります。
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems={{
                            mobile: 'flex-start',
                            tablet: 'center',
                          }}
                          spacing={1}
                        >
                          <Typography variant="text_upload_file">4.</Typography>
                          <Stack
                            direction={{ mobile: 'column', tablet: 'row' }}
                          >
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              会社役員の方は「源泉徴収票」と
                            </Typography>
                            <Typography variant="h4" color="normal_text">
                              「源泉徴収票」
                            </Typography>
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              と
                            </Typography>
                            <Typography variant="h4">
                              「法人決算書３期分」
                            </Typography>
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              のご提出が必須となります。
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack
                          direction="row"
                          alignItems={{
                            mobile: 'flex-start',
                            tablet: 'center',
                          }}
                          spacing={1}
                        >
                          <Typography variant="text_upload_file">5.</Typography>
                          <Stack
                            direction={{ mobile: 'column', tablet: 'row' }}
                          >
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              親族が経営する企業に勤務する方は
                            </Typography>
                            <Typography variant="h4">「源泉徴収票」</Typography>
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              と
                            </Typography>
                            <Typography variant="h4">
                              「法人決算書３期分」
                            </Typography>
                            <Typography
                              variant="text_upload_file"
                              color="normal_text"
                            >
                              のご提出が必須となります。
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgD1st} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_first_income_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_first_income_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_first_income_file"
                          onChange={handleChangeFiles('pair_first_income_file')}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '50px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgD2nd} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_second_income_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_second_income_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_second_income_file"
                          onChange={handleChangeFiles(
                            'pair_second_income_file'
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '50px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgD3rd} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_third_income_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_third_income_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_third_income_file"
                          onChange={handleChangeFiles('pair_third_income_file')}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Stack>

                {/*IMAGES-E*/}
                <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 1,
                      margin: '0 auto',
                      bgcolor: 'bg_off',
                      p: '22px 0px 54px 0px',
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                      <Stack spacing={7} direction="row" alignItems="center">
                        <Typography variant="title_avatar_card" color="blue03">
                          E
                        </Typography>
                        <Typography
                          variant="text_avatar_card"
                          color="normal_text"
                        >
                          提携会社（不動産会社・住宅メーカー等）の担当者名刺
                        </Typography>
                      </Stack>
                      <Stack sx={{ mt: 7 }}>
                        <Typography variant="textstyle" color="normal_text">
                          「提携会社（不動産会社・住宅メーカー等）について」の各項目にご記入いただいた場合は提出不要です。
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgE} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_referral_agency_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_referral_agency_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_referral_agency_file"
                          onChange={handleChangeFiles(
                            'pair_referral_agency_file'
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Stack>

                {/*IMAGES-F*/}
                <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 1,
                      margin: '0 auto',
                      bgcolor: 'bg_off',
                      p: '22px 0px 54px 0px',
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                      <Stack spacing={7} direction="row" alignItems="center">
                        <Typography variant="title_avatar_card" color="blue03">
                          F
                        </Typography>
                        <Typography
                          variant="text_avatar_card"
                          color="normal_text"
                        >
                          外国籍の方の永住権に関する資料
                        </Typography>
                      </Stack>
                      <Stack sx={{ mt: 7 }}>
                        <Typography variant="textstyle" color="normal_text">
                          永住権証明書、または特別永住権証明書など
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgF} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_residence_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_residence_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_residence_file"
                          onChange={handleChangeFiles('pair_residence_file')}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Stack>

                {/*IMAGES-G*/}
                <Stack sx={{ bgcolor: 'bg_off', mt: 5, mx: 5 }}>
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 1,
                      margin: '0 auto',
                      bgcolor: 'bg_off',
                      p: '22px 0px 54px 0px',
                      width: '100%',
                    }}
                  >
                    <Stack sx={{ ml: { mobile: '20px', tablet: '31px' } }}>
                      <Stack spacing={7} direction="row" alignItems="center">
                        <Typography variant="title_avatar_card" color="blue03">
                          G
                        </Typography>
                        <Typography
                          variant="text_avatar_card"
                          color="normal_text"
                        >
                          現在ご利用中のローンの返済予定表・利用明細等
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: 17, mb: 14, width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgG1} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_first_borrowing_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_first_borrowing_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_first_borrowing_file"
                          onChange={handleChangeFiles(
                            'pair_first_borrowing_file'
                          )}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ mobile: 'column', tablet: 'row' }}
                      sx={{ mt: '11px', width: '100%' }}
                    >
                      <Stack>
                        <ImageItem src={imgG2} />
                      </Stack>
                      <Stack
                        sx={{
                          flex: { tablet: 1 },
                          mr: '41px',
                          width: { mobile: '100%' },
                          borderWidth: 1,
                          borderColor: 'h_blugreen',
                          borderStyle: 'dashed',
                        }}
                      >
                        <Stack
                          justifyContent="center"
                          sx={{
                            bgcolor: 'main_white',
                            borderRadius: '5px',
                            mx: '15px',
                            mt: '15px',
                            mb: '30px',
                          }}
                        >
                          {isEditing
                            ? formik.values.editFiles.pair_second_borrowing_file?.map(
                                (image: UploadedFiles | File) => {
                                  if (image instanceof File)
                                    return (
                                      <ImageItem
                                        key={image.name}
                                        name={image.name}
                                        src={URL.createObjectURL(image)}
                                      />
                                    );
                                  return (
                                    <ImageItem
                                      key={image.filename}
                                      name={image.filename}
                                      src={image.path}
                                    />
                                  );
                                }
                              )
                            : formik.values.pair_second_borrowing_file?.map(
                                (image: File) => (
                                  <ImageItem
                                    key={image.name}
                                    name={image.name}
                                    src={URL.createObjectURL(image)}
                                  />
                                )
                              )}
                        </Stack>
                        <InputFile
                          name="pair_second_borrowing_file"
                          onChange={handleChangeFiles(
                            'pair_second_borrowing_file'
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Stack>
                {/*BUTTON-SUBMIT*/}

                <Stack
                  alignItems="center"
                  spacing={12}
                  sx={{ mb: 13, mx: 4, mt: '50px' }}
                >
                  <Button
                    disabled={checkDisable}
                    sx={{
                      bgcolor: 'sh_red',
                      width: {
                        mobile: '100%',
                        tablet: 426,
                        desktop: 426,
                      },
                      minHeight: 55,
                    }}
                    onClick={() => {
                      if (isEditing && !!selectedPreliminary.id) {
                        handleSubmitEditUploadedFiles(
                          formik.values.editFiles,
                          selectedPreliminary.id
                        );
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                  >
                    <Typography
                      variant="button_title_review"
                      color="main_white"
                    >
                      {isEditing
                        ? '住信SBIネット銀行へ送信する'
                        : ' 住信SBIネット銀行へ申込む'}
                    </Typography>
                  </Button>
                </Stack>
              </Card>
            </>
          )}
        </Container>
      </FormikProvider>
    </StepLayout>
  );
};

export default StepFourBPage;

const notes = {
  shootingPoint: [
    '写真のピントを合わせる',
    '影が入らないようにする',
    '明るい場所で撮影',
    'フラッシュはOFFにして光を反射させない',
    '照明が反射する時は壁に立てかける',
    '正面の角度から撮影',
    '画像を加工しない',
  ],
};
