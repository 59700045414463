import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { UserLayout } from 'containers';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { Button, DashboardItems } from 'components';
import { useSelector } from 'react-redux';
import { currentPremilinarySelectors } from 'pages/Preliminaries/selectors';

const DashboardsPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useSelector(currentPremilinarySelectors);

  return (
    <UserLayout
      header={{
        left: {
          title: 'マイページ',
        },
      }}
      hasFooter={!!id}
      footer={{
        left: (
          <Stack direction="row" sx={{ p: '5px 20px 11px 11px' }}>
            <Button
              variant="back"
              sx={{ minHeight: 44 }}
              startIcon={<ArrowBackIos sx={{ color: 'h_blugreen' }} />}
              startIconStyle={{ left: 27, bottom: 11 }}
              onClick={() => navigate(routeNames.Preliminaries.path)}
            >
              <Typography variant="button_title" color="normal_text">
                もどる
              </Typography>
            </Button>
          </Stack>
        ),
        right: false,
        bgcolor: 'footer_bg',
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mx: 18, mt: 6 }}
      >
        <DashboardItems itemPerRow={3} />
      </Box>
    </UserLayout>
  );
};

export default DashboardsPage;
