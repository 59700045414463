import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowBack: FC<SvgIconProps> = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon sx={{ ...sx, borderRadius: 12 }} viewBox="0 0 20 20" {...props}>
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" fill="#E4E7FF" stroke="#6B70F0" />
      <path
        d="M11.3359 6.7998L8.0026 10.1331L11.3359 13.4665"
        stroke="#6B70F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
