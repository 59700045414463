import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const Step: FC<SvgIconProps> = ({ sx, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      sx={{
        width: '128px',
        height: '29px',
        ...sx,
      }}
      viewBox="0 0 128 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="128"
        height="29"
        viewBox="0 0 128 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 24.5V5C1 2 2.66667 1 4.5 1H118.5C120.5 1 121.5 2 122 3C122.373 3.74642 125.097 10.0643 126.678 13.75C126.885 14.2314 126.895 14.7715 126.704 15.2593C125.449 18.4682 123.238 24.0247 122.5 25.5C121.7 27.1 120 28 118.5 28H4.5C1.5 28 1 26.5 1 24.5Z"
          fill="currentColor"
        />
        <path
          d="M122.5 25.5L122.947 25.7236V25.7236L122.5 25.5ZM126.704 15.2593L126.238 15.0772L126.238 15.0772L126.704 15.2593ZM126.678 13.75L126.219 13.9472V13.9472L126.678 13.75ZM122 3L122.447 2.77639V2.77639L122 3ZM0.5 5V24.5H1.5V5H0.5ZM0.5 24.5C0.5 25.5157 0.620229 26.5444 1.23457 27.3123C1.86953 28.1061 2.92661 28.5 4.5 28.5V27.5C3.07339 27.5 2.38047 27.1439 2.01543 26.6877C1.62977 26.2056 1.5 25.4843 1.5 24.5H0.5ZM4.5 28.5H118.5V27.5H4.5V28.5ZM118.5 28.5C120.177 28.5 122.057 27.5039 122.947 25.7236L122.053 25.2764C121.343 26.6961 119.823 27.5 118.5 27.5V28.5ZM122.947 25.7236C123.698 24.2223 125.92 18.637 127.17 15.4414L126.238 15.0772C124.978 18.2993 122.777 23.8271 122.053 25.2764L122.947 25.7236ZM127.138 13.5529C125.562 9.88041 122.829 3.54005 122.447 2.77639L121.553 3.22361C121.917 3.95279 124.632 10.2482 126.219 13.9472L127.138 13.5529ZM122.447 2.77639C122.168 2.21708 121.74 1.64255 121.09 1.20897C120.436 0.77331 119.587 0.5 118.5 0.5V1.5C119.413 1.5 120.064 1.72669 120.535 2.04103C121.01 2.35745 121.332 2.78292 121.553 3.22361L122.447 2.77639ZM118.5 0.5H4.5V1.5H118.5V0.5ZM4.5 0.5C3.4975 0.5 2.48129 0.773925 1.71479 1.5157C0.944156 2.26147 0.5 3.40824 0.5 5H1.5C1.5 3.59176 1.88918 2.73853 2.41021 2.2343C2.93538 1.72608 3.66916 1.5 4.5 1.5V0.5ZM127.17 15.4414C127.408 14.8307 127.396 14.1543 127.138 13.5529L126.219 13.9472C126.374 14.3084 126.381 14.7123 126.238 15.0772L127.17 15.4414Z"
          fill="#6671E1"
        />
      </svg>
    </SvgIcon>
  );
};
