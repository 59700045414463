import {
  AdminPreliminaryItem,
  BORROWING_DETAIL,
  HasLandAdvancePlanType,
  LoanType,
  PurposeType,
} from 'constant';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { dayjs } from 'libs/dayjs';

export const buildBorrowingDetail = (
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header']
): AdminPreliminaryItem[] => {
  return BORROWING_DETAIL.filter(({ name_en }, index) => {
    if (name_en === 'has_land_advance_plan') {
      return [PurposeType.SIX].includes(values.loan_target);
    }
    if (name_en === 'loan_target' && !!values.loan_target_zero) {
      return [PurposeType.ZERO].includes(values.loan_target_zero);
    } else if (name_en === 'loan_target' && !values.loan_target) {
      return false;
    }
    if (
      name_en ===
        'p_borrowing_details_attributes[1].loan_desired_borrowing_date' ||
      name_en ===
        'p_borrowing_details_attributes[1].temporary_desired_loan_amount' ||
      name_en === 'p_borrowing_details_attributes[1].halfyear_bonus'
    ) {
      return values.has_land_advance_plan === HasLandAdvancePlanType.YES;
    }
    // if (name_en === 'p_borrowing_details_attributes[0].loan_term_month_num') {
    //   return (
    //     values.p_borrowing_details_attributes[0].loan_term_year_num !== '35'
    //   );
    // }
    if (
      name_en === 'pair_loan_applicant_first_name' ||
      name_en === 'pair_loan_applicant_last_name' ||
      name_en === 'pair_loan_relationship_name'
    ) {
      return [LoanType.TWO].includes(values.loan_type);
    }

    return true;
  }).map((row, index) => {
    if (
      row.name_en ===
      'p_borrowing_details_attributes[0].loan_desired_borrowing_date'
    ) {
      return {
        ...row,
        options: {
          minDate: dayjs(values.created_at || undefined).toString(),
          maxDate: dayjs(values.created_at || undefined)
            .add(4, 'y')
            .toString(),
        },
      };
    }
    if (row.name_en === 'scheduled_date_moving') {
      return {
        ...row,
        options: {
          ...row.options,
          minDate: dayjs(
            values.p_borrowing_details_attributes[0]
              .loan_desired_borrowing_date || undefined
          ).toString(),
          maxDate: dayjs(
            values.p_borrowing_details_attributes[0]
              .loan_desired_borrowing_date || undefined
          )
            .add(4, 'y')
            .toString(),
        },
      };
    }
    // if (
    //   row.name_en === 'p_borrowing_details_attributes[0].loan_term_month_num'
    // ) {
    //   const monthDiffBirthday = dayjs().diff(
    //     values.p_applicant_people_attributes?.[0]?.birthday,
    //     'M'
    //   );
    //   const totalMonth =
    //     monthDiffBirthday +
    //     +values.p_borrowing_details_attributes?.[0]?.loan_term_year_num * 12;
    //   return {
    //     ...row,
    //     dynamicType: 80 * 12 - totalMonth <= 0 ? null : 'selectNumber',
    //     dynamicOptionHidden(index) {
    //       return getDynamicOptionHiddenMonth(
    //         index,
    //         values.p_applicant_people_attributes?.[0]?.birthday,
    //         values.p_borrowing_details_attributes?.[0]?.loan_term_year_num
    //       );
    //     },
    //   };
    // }

    return row;
  });
};

function getDynamicOptionHiddenMonth(
  month: number,
  birthday: string | undefined,
  loanTermYearNum: string
): boolean {
  if (!birthday) {
    return month > 0;
  }
  const monthDiffBirthday = dayjs().diff(birthday, 'M');
  const totalMonth = monthDiffBirthday + Number(loanTermYearNum) * 12;
  return 80 * 12 - totalMonth <= month;
}
