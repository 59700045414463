import { RootState } from 'types';
import { PreliminariesStatus } from 'constant';

export const loadingSelector = (state: RootState) =>
  state.salePersonPreliminaries.fetch;

export const currentSalePersonPreliminariesSelectors = (state: RootState) =>
  state.salePersonPreliminaries.currentPreliminaries;

export const totalSalePersonPreliminarySelectors = (state: RootState) =>
  state.salePersonPreliminaries.total;

export const activeTabSelector = (state: RootState) =>
  state.managerPreliminaries.activeTab || PreliminariesStatus.CURRENT;
