import { FC, useEffect, useRef, useCallback } from 'react';
import { Stack } from '@mui/material';
import { InputField, FormItem } from 'components';
import { useFormikContext } from 'formik';
import { StepSynthesisForm } from 'types';
import { PurposeType } from 'constant';
import { debounce } from 'lodash';

type InputFundingType = {
  land_purchase_price: string;
  house_purchase_price: string;
  accessory_cost: string;
  additional_cost: string;
  refinancing_loan_balance: string;
  other_require_funds_breakdown: string;
  house_upgrade_cost: string;
};

const StepOnePageE: FC = () => {
  const { values, setFieldValue } = useFormikContext<StepSynthesisForm>();
  const {
    land_purchase_price,
    house_purchase_price,
    accessory_cost,
    additional_cost,
    refinancing_loan_balance,
    other_require_funds_breakdown,
    house_upgrade_cost,
  } = values.p_application_header;

  const debouncedFunction = useRef(
    debounce((total) => {
      setFieldValue('p_application_header.total_require_funds', total);
    }, 250)
  );

  const debouncedSetFieldValue = useCallback(
    // @ts-ignore
    (total) => {
      return debouncedFunction.current(total);
    },
    []
  );

  useEffect(() => {
    const params: InputFundingType = {
      land_purchase_price,
      house_purchase_price,
      accessory_cost,
      additional_cost,
      refinancing_loan_balance,
      other_require_funds_breakdown,
      house_upgrade_cost,
    };
    debouncedSetFieldValue(calculateTotal(params));
  }, [
    land_purchase_price,
    house_purchase_price,
    accessory_cost,
    additional_cost,
    refinancing_loan_balance,
    other_require_funds_breakdown,
    house_upgrade_cost,
    debouncedSetFieldValue,
  ]);

  return (
    <Stack justifyContent="center">
      {values.p_application_header.loan_target === PurposeType.SIX && (
        <FormItem divider label="土地（戸建）" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.land_purchase_price"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      {(values.p_application_header.loan_target === PurposeType.ONE ||
        values.p_application_header.loan_target === PurposeType.TWO ||
        values.p_application_header.loan_target === PurposeType.FIVE ||
        values.p_application_header.loan_target === PurposeType.SIX) && (
        <FormItem divider label="建物（戸建）または建売住宅" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.house_purchase_price"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      {(values.p_application_header.loan_target === PurposeType.THREE ||
        values.p_application_header.loan_target === PurposeType.FOUR) && (
        <FormItem divider label="マンション" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.house_purchase_price"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      {(values.p_application_header.loan_target === PurposeType.FIVE ||
        values.p_application_header.loan_target === PurposeType.SIX) && (
        <FormItem divider label="付帯設備" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.accessory_cost"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      <FormItem
        divider
        label="諸経費等"
        required
        help="ここに説明が入りますここに説明が入りますここに説明が入りますここに説明が入ります。"
        note={notes.additional_cost}
      >
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="p_application_header.additional_cost"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            useNumberFormat
          />
        </Stack>
      </FormItem>

      {(values.p_application_header.loan_target === PurposeType.SEVEN ||
        values.p_application_header.loan_target === PurposeType.EIGHT ||
        values.p_application_header.loan_target === PurposeType.NINE) && (
        <FormItem divider label="借換対象ローン残債" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.refinancing_loan_balance"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      {(values.p_application_header.loan_target === PurposeType.EIGHT ||
        values.p_application_header.loan_target === PurposeType.NINE) && (
        <FormItem divider label="増改築" required>
          <Stack direction="row">
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.house_upgrade_cost"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </FormItem>
      )}

      <FormItem label="その他">
        <Stack spacing="35px" sx={{ mb: '57px' }}>
          <InputField
            name="p_application_header.other_require_funds_breakdown_content"
            placeholder="ー"
          />
          <Stack width={{ mobile: '100%', tablet: 300 }}>
            <InputField
              sx={{ width: { mobile: '100%', tablet: 300 } }}
              name="p_application_header.other_require_funds_breakdown"
              align="right"
              placeholder="0"
              unit="万円"
              numeric
              useNumberFormat
            />
          </Stack>
        </Stack>
      </FormItem>

      <FormItem label="合計" required>
        <Stack direction="row">
          <InputField
            sx={{ width: { mobile: '100%', tablet: 300 } }}
            name="p_application_header.total_require_funds"
            align="right"
            placeholder="0"
            unit="万円"
            numeric
            useNumberFormat
            readOnly
          />
        </Stack>
      </FormItem>
    </Stack>
  );
};

export default StepOnePageE;

const calculateTotal = ({
  land_purchase_price,
  house_purchase_price,
  accessory_cost,
  additional_cost,
  refinancing_loan_balance,
  other_require_funds_breakdown,
  house_upgrade_cost,
}: InputFundingType) => {
  return (
    +land_purchase_price +
      +house_purchase_price +
      +accessory_cost +
      +additional_cost +
      +refinancing_loan_balance +
      +other_require_funds_breakdown +
      +house_upgrade_cost || 0
  );
};

const notes = {
  additional_cost:
    '※登記費用、印紙代、当社事務取扱手数料などをご記入ください。',
};
