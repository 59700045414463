import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPCircleExclamation: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.5 0C4.08125 0 0.5 3.58125 0.5 8C0.5 12.4187 4.08125 16 8.5 16C12.9187 16 16.5 12.4187 16.5 8C16.5 3.58125 12.9187 0 8.5 0ZM8.5 14.5C4.91563 14.5 2 11.5841 2 8C2 4.41594 4.91563 1.5 8.5 1.5C12.0844 1.5 15 4.41594 15 8C15 11.5841 12.0844 14.5 8.5 14.5ZM8.5 9.5C8.91406 9.5 9.25 9.16406 9.25 8.75V4.75C9.25 4.3375 8.91562 4 8.5 4C8.08437 4 7.75 4.3375 7.75 4.75V8.75C7.75 9.16562 8.0875 9.5 8.5 9.5ZM8.5 10.5344C7.9575 10.5344 7.5175 10.9744 7.5175 11.5169C7.51875 12.0594 7.95625 12.5 8.5 12.5C9.04375 12.5 9.4825 12.06 9.4825 11.5175C9.48125 10.975 9.04375 10.5344 8.5 10.5344Z"
          fill="#E54E75"
        />
      </svg>
    </SvgIcon>
  );
};
