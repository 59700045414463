import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep08: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="22"
        height="25"
        viewBox="0 0 22 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.09184 6.17157C5.09184 2.76322 7.8326 0 11.2143 0C14.596 0 17.3367 2.76322 17.3367 6.17157C17.3367 9.5804 14.596 12.3431 11.2143 12.3431C7.8326 12.3431 5.09184 9.5804 5.09184 6.17157ZM16.5714 6.17157C16.5714 3.18896 14.1751 0.771446 11.2143 0.771446C8.25351 0.771446 5.85715 3.18896 5.85715 6.17157C5.85715 9.1561 8.25351 11.5717 11.2143 11.5717C14.1751 11.5717 16.5714 9.1561 16.5714 6.17157ZM8.96142 14.8648C9.02839 14.7394 9.15753 14.6575 9.30103 14.6575H13.1276C13.228 14.6575 13.4002 14.7394 13.4672 14.8648C13.5341 14.995 13.5246 15.1493 13.4385 15.265L11.6591 17.777L12.7688 20.0142L15.0217 15.4723C18.9008 15.8773 21.9286 19.1849 21.9286 23.2061C21.9286 24.0209 21.2685 24.6429 20.4602 24.6429H1.96939C1.15769 24.6429 0.5 24.0209 0.5 23.2061C0.5 19.1849 3.52679 15.8773 7.40689 15.4723L9.65977 20.0142L10.7695 17.777L8.99012 15.265C8.90403 15.1493 8.89446 14.995 8.96142 14.8648ZM10.0424 15.4289L11.2143 17.0779L12.3862 15.4289H10.0424ZM6.97163 16.3113C3.72625 16.9043 1.26531 19.7635 1.26531 23.2061C1.26531 23.5966 1.58052 23.8714 1.96939 23.8714H10.7408L6.97163 16.3113ZM10.0903 20.8724L11.2143 23.1434L12.3383 20.8724L11.2143 18.6063L10.0903 20.8724ZM21.1633 23.2061C21.1633 19.7635 18.7 16.9043 15.457 16.3113L11.6878 23.8714H20.4602C20.8476 23.8714 21.1633 23.5966 21.1633 23.2061Z"
          fill="url(#paint0_linear_7977_26407)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_7977_26407"
            x1="3.79984"
            y1="19.5748"
            x2="22.4734"
            y2="3.17302"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
