import { RootState } from 'types';

export const spStepTwoIncomeTotalSelector = (state: RootState) => ({
  p_applicant_people:
    state.sp_step_four_income_total.form.p_application_header
      ?.p_applicant_people_attributes[0],
  loan_type: state.sp_step_one.form.p_application_header.loan_type,
  p_applicant_people_id:
    state.sp_step_four_income_total.form.p_application_header?.id,
  status: state.sp_step_four_income_total.status,
});
