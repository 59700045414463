import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPUnCheckPassword: FC<SvgIconProps> = ({
  sx,
  ...props
}: SvgIconProps) => (
  <SvgIcon sx={{ ...sx }} viewBox="0 0 18 16" {...props}>
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8359_27055)">
        <path
          d="M12.5 8C12.5 9.93437 10.9344 11.5 9 11.5C7.06563 11.5 5.5 9.93437 5.5 8C5.5 6.06562 7.06563 4.5 9 4.5C10.9344 4.5 12.5 6.06562 12.5 8ZM9 5C7.31563 5 6 6.31562 6 8C6 9.65625 7.31563 11 9 11C10.6563 11 12 9.65625 12 8C12 6.31562 10.6563 5 9 5ZM15.0188 3.51875C16.4813 4.875 17.4594 6.47187 17.9219 7.61562C18.025 7.8625 18.025 8.1375 17.9219 8.38437C17.4594 9.5 16.4813 11.0969 15.0188 12.4812C13.5469 13.85 11.525 15 9 15C6.475 15 4.45313 13.85 2.98188 12.4812C1.51938 11.0969 0.541879 9.5 0.0769102 8.38437C-0.0256367 8.1375 -0.0256367 7.8625 0.0769102 7.61562C0.541879 6.47187 1.51938 4.875 2.98188 3.51875C4.45313 2.15125 6.475 1 9 1C11.525 1 13.5469 2.15125 15.0188 3.51875ZM0.538441 7.80625C0.487191 7.93125 0.487191 8.06875 0.538441 8.19375C0.979379 9.25 1.91813 10.7844 3.32188 12.1156C4.73125 13.425 6.6375 14.5 9 14.5C11.3625 14.5 13.2688 13.425 14.6781 12.1156C16.0813 10.7844 17.0219 9.25 17.4625 8.19375C17.5125 8.06875 17.5125 7.93125 17.4625 7.80625C17.0219 6.72187 16.0813 5.1875 14.6781 3.88437C13.2688 2.57531 11.3625 1.5 9 1.5C6.6375 1.5 4.73125 2.57531 3.32188 3.88437C1.91813 5.1875 0.979379 6.72187 0.538441 7.80625Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8359_27055">
          <rect width="18" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
