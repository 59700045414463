import { FC, useMemo } from 'react';
import { ButtonProps, Stack, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { Icons } from 'components';
import { isMobile } from 'react-device-detect';

export type SPCheckboxButtonProps = ButtonProps & {
  name: string;
  note?: string;
  checked?: boolean;
  readOnly?: boolean;
  radioButton?: boolean;
  noBorder?: boolean;
  checkbox?: boolean;
  useBinary?: boolean;
  hasBoxShadow?: boolean;
  onClick?: (value: boolean) => void;
};

export const SPCheckboxButton: FC<SPCheckboxButtonProps> = ({
  name,
  children,
  note,
  checked,
  readOnly,
  sx = {},
  disabled,
  radioButton = false,
  noBorder = false,
  checkbox = false,
  useBinary = false,
  hasBoxShadow,
  onClick,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const renderIcon = useMemo(() => {
    if ((!useBinary && field.value) || (useBinary && !!+field.value))
      return <Icons.SPChecked sx={{ width: 24, height: 24 }} />;
    return hasBoxShadow ? (
      <Stack
        sx={{
          boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
          width: 18,
          height: 18,
          borderRadius: 1,
          border: '0.1px solid',
          borderColor: 'sp_primary_40',
        }}
      />
    ) : (
      <Icons.SPUnchecked sx={{ width: 24, height: 24 }} />
    );
  }, [field.value, useBinary, hasBoxShadow]);

  const checkBorderColor = useMemo(() => {
    if ((!!field.value && !useBinary) || (useBinary && !!+field.value))
      return 'sp_primary_100_main';
    if (meta.touched && !!meta.error) return 'sp_secondary_01';
    return 'sp_primary_40';
  }, [field.value, useBinary, meta.touched, meta.error]);

  if (noBorder)
    return (
      <Stack
        sx={{
          '@media (orientation: landscape)': {
            maxWidth: isMobile ? '100%' : 480,
          },
          maxWidth: 480,
          ...(readOnly && {
            border: '1px solid',
            borderColor: 'line',
          }),
        }}
      >
        <input name={name} type="hidden" />
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => {
            if ((radioButton && !!field.value) || disabled) {
              return;
            }
            setFieldValue(name, !field.value);
            onClick && onClick(!field.value);
          }}
          sx={{
            width: '100%',
            bgcolor: 'sp_bg_gray',
            boxShadow: 0,
            opacity: disabled ? 0.5 : 1,
            ...sx,
          }}
          spacing={3}
        >
          <Stack
            sx={{
              ml: checkbox ? 0 : '18px',
              my: '18px',
            }}
          >
            {renderIcon}
          </Stack>
          <Stack justifyContent="center">
            {children}

            {!!note && (
              <Typography
                variant="text_note"
                color={checked ? 'main_white' : 'normal_text'}
                sx={{ alignItems: 'left', px: 3 }}
              >
                {note}
              </Typography>
            )}
          </Stack>
        </Stack>
        {meta.touched && !!meta.error && (
          <Typography variant="text_error" color="sp_secondary_01">
            ※{meta.error ?? ''}
          </Typography>
        )}
      </Stack>
    );

  return (
    <Stack
      sx={{
        '@media (orientation: landscape)': {
          maxWidth: isMobile ? '100%' : 480,
        },
        maxWidth: 480,
        ...(readOnly && {
          border: '1px solid',
          borderColor: 'line',
        }),
      }}
    >
      <input name={field.name} type="hidden" />
      <Stack
        onClick={() => {
          if ((radioButton && !!field.value) || disabled) {
            return;
          }
          if (useBinary) {
            onClick && onClick(!+field.value);
            return setFieldValue(name, !!+field.value ? '0' : '1');
          }
          setFieldValue(name, !field.value);
        }}
        sx={{
          minHeight: 60,
          width: '100%',
          bgcolor: 'sp_bg_gray',
          boxShadow: 0,
          opacity: disabled ? 0.5 : 1,
          ...sx,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            bgcolor: 'main_white',
            borderRadius: '14px',
            justifyContent: 'flex-start',
            boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
            border: '1px solid',
            borderColor: checkBorderColor,
          }}
          spacing={3}
        >
          <Stack
            sx={{
              ml: '14px',
              my: 4,
            }}
          >
            {renderIcon}
          </Stack>
          <Stack justifyContent="center">
            {children}
            {!!note && (
              <Typography
                variant="text_note"
                color={checked ? 'main_white' : 'normal_text'}
                sx={{ alignItems: 'left', px: 3 }}
              >
                {note}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      {meta.touched && !!meta.error && (
        <Typography variant="text_error" color="sp_secondary_01">
          ※{meta.error}
        </Typography>
      )}
    </Stack>
  );
};
