import { FC } from 'react';
import { Box } from '@mui/material';
import { Button, Icons } from 'components';

export type PulldownBtnProps = {
  open?: boolean;
  isEmpty?: boolean;
  onClick?: () => void;
};

export const PulldownBtn: FC<PulldownBtnProps> = ({
  open,
  isEmpty,
  onClick,
}) => {
  if (isEmpty) {
    return <Box sx={{ width: 20, height: 20 }} />;
  }
  return (
    <Button
      sx={{
        height: 20,
        minHeight: 20,
        width: 20,
        minWidth: open ? 148 : 20,
        boxShadow: 'none',
        bgcolor: 'main_white',
        border: (theme) => `1px solid ${theme?.palette?.line}`,
        display: 'flex',
        justifyContent: open ? 'flex-end' : 'center',
        alignItems: 'center',
        borderBottomLeftRadius: open ? 0 : 4,
        borderBottomRightRadius: open ? 0 : 4,
        borderBottom: (theme) =>
          open ? 'none' : `1px solid ${theme?.palette?.line}`,
        p: 0,
        '&:hover': {
          boxShadow: 'none',
        },
      }}
      onClick={onClick}
    >
      {!open ? (
        <Icons.ArrowDown
          sx={{ width: 8, height: 8, color: 'arrow_icon', mr: open ? 1 : 0 }}
        />
      ) : (
        <Icons.ArrowUp
          sx={{ width: 8, height: 8, color: 'arrow_icon', mr: open ? 1 : 0 }}
        />
      )}
    </Button>
  );
};
