import { FC } from 'react';
import { Stack } from '@mui/material';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
  ScrollMode,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

export const Consent: FC<{ url: string }> = ({ url }) => {
  const zoomPluginInstance = zoomPlugin();

  return (
    <Stack
      spacing={2}
      sx={{
        bgcolor: 'white',
        '.rpv-core__page-layer, .rpv-core__inner-container, .rpv-core__viewer, .rpv-core__inner-pages':
          {
            overflow: 'hidden',
          },
        '.rpv-core__page-layer::after': {
          display: 'none',
        },
        '.rpv-core__canvas-layer': {
          left: '47%',
          transform: 'translateX(-50%)',
        },
      }}
    >
      <Worker workerUrl="/pdf.worker.bundle.js">
        <Viewer
          fileUrl={url}
          plugins={[zoomPluginInstance]}
          defaultScale={SpecialZoomLevel.PageWidth}
          scrollMode={ScrollMode.Vertical}
        />
      </Worker>
    </Stack>
  );
};
