import { regex, yup } from 'libs';

export const validationSchema = yup.object().shape({
  message: yup.string(),
});

export const validationLinkModalSchema = yup.object().shape({
  message: yup.string(),
  displayText: yup.string().required(),
  url: yup.string().required().matches(regex.url),
});
