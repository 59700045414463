import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, ThunkAPI } from 'types';
import {
  ArchiveFileEditResponse,
  ArchiveFilesManagerResponse,
  ArchiveFilesManagerRequest,
  ArchiveFilesSalePersonResponse,
  GetArchiveFilesError,
  ManagerEditSortArchiveFileItemRequest,
  ManagerEditSortArchiveFileItemResponse,
  ManagerSortArchiveFileItemRequest,
  ManagerSortArchiveFileItemResponse,
  UploadDocumentSalePersonError,
  UploadDocumentSalePersonResponse,
  UploadDocumentNoteManagerResponse,
} from 'services/schemas/adminArchiveFile';
import { adminArchiveFile } from 'services';
import * as FileSaver from 'file-saver';
import dayjs from 'dayjs';
import { adminModalUpdateDetailRef } from 'components';

export const getListArchiveFileManager = createAsyncThunk<
  ArchiveFilesManagerResponse,
  ArchiveFilesManagerRequest,
  ThunkAPI<GetArchiveFilesError>
>('AdminArchiveFile/getListArchiveFiles', async (data, { rejectWithValue }) => {
  try {
    return await adminArchiveFile.getListArchiveFileManager(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getEditArchiveFileManager = createAsyncThunk<
  ArchiveFileEditResponse,
  { id: string },
  ThunkAPI<GetArchiveFilesError>
>('AdminArchiveFile/getEditArchiveFiles', async (data, { rejectWithValue }) => {
  try {
    return await adminArchiveFile.getEditArchiveManagerFile(data.id);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getEditArchiveFileSalePerson = createAsyncThunk<
  ArchiveFileEditResponse,
  { id: string },
  ThunkAPI<GetArchiveFilesError>
>(
  'AdminArchiveFile/getEditArchiveFileSalePerson',
  async (data, { rejectWithValue }) => {
    try {
      return await adminArchiveFile.getEditArchiveSalePersonFile(data.id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sortArchiveFileManagerItems = createAsyncThunk<
  ManagerSortArchiveFileItemResponse,
  ManagerSortArchiveFileItemRequest
>(
  'AdminArchiveFile/sortArchiveFileManagerItems',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } =
        await adminArchiveFile.getSortArchiveFileManager(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sortEditArchiveFileManagerItems = createAsyncThunk<
  ManagerEditSortArchiveFileItemResponse,
  ManagerEditSortArchiveFileItemRequest
>(
  'AdminArchiveFile/sortArchiveFileManagerItems',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } =
        await adminArchiveFile.getEditSortArchiveFileManager(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sortEditArchiveFileSalePersonItems = createAsyncThunk<
  ManagerEditSortArchiveFileItemResponse,
  ManagerEditSortArchiveFileItemRequest
>(
  'AdminArchiveFile/sortEditArchiveFileSalePersonItems',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } =
        await adminArchiveFile.getEditSortArchiveFileSalePerson(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListArchiveFileSalePerson = createAsyncThunk<
  ArchiveFilesSalePersonResponse,
  ArchiveFilesManagerRequest,
  ThunkAPI<GetArchiveFilesError>
>(
  'AdminArchiveFile/getListArchiveFileSalePerson',
  async (data, { rejectWithValue }) => {
    try {
      return await adminArchiveFile.getListArchiveFileSalePerson(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadNewDocumentSalePerson = createAsyncThunk<
  UploadDocumentSalePersonResponse,
  FormData,
  ThunkAPI<UploadDocumentSalePersonError>
>(
  'AdminArchiveFile/uploadNewDocumentSalePerson',
  async (data, { rejectWithValue }) => {
    try {
      return await adminArchiveFile.uploadDocumentSalePerson(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDocumentNoteManager = createAsyncThunk<
  UploadDocumentNoteManagerResponse,
  { data: FormData; id: string },
  ThunkAPI<UploadDocumentSalePersonError>
>(
  'AdminArchiveFile/updateDocumentNoteManager',
  async (data, { rejectWithValue }) => {
    try {
      return await adminArchiveFile.uploadDocumentNoteManager(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateDocumentNoteSalePerson = createAsyncThunk<
  UploadDocumentNoteManagerResponse,
  { data: FormData; id: string },
  ThunkAPI<UploadDocumentSalePersonError>
>(
  'AdminArchiveFile/updateDocumentNoteSalePerson',
  async (data, { rejectWithValue }) => {
    try {
      return await adminArchiveFile.uploadDocumentNoteSalePerson(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateArchiveFileSalePerson = createAsyncThunk<
  UploadDocumentNoteManagerResponse,
  { data: FormData; id: string },
  ThunkAPI<UploadDocumentSalePersonError>
>(
  'AdminArchiveFile/updateArchiveFileSalePerson',
  async (data, { rejectWithValue }) => {
    try {
      return await adminArchiveFile.updateArchiveFileSalePerson(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sortArchiveFileSalePersonItems = createAsyncThunk<
  ManagerSortArchiveFileItemResponse,
  ManagerSortArchiveFileItemRequest
>(
  'AdminArchiveFile/sortArchiveFileSalePersonItems',
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } =
        await adminArchiveFile.getSortArchiveFileSalePerson(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDownloadManagerDocumentUploadZip = createAsyncThunk<
  BlobPart,
  { id: string; company_name: string; name: string }
>(
  'AdminArchiveFile/getDownloadDocumentUploadZip',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      if (!state.auth.accessToken) {
        return rejectWithValue(new Error('Not logged in'));
      }
      const response = await adminArchiveFile.getDownloadZipFileManager(
        {
          id: data.id,
        },
        state.auth.accessToken
      );
      if (response.status === 200) {
        FileSaver.saveAs(
          await response.blob(),
          `${data.company_name}_${data.name}アップロード資料_${dayjs().format(
            'YYYYMMDDhhmmss'
          )}.zip`
        );
      } else {
        adminModalUpdateDetailRef.current?.open();
      }
      return response.blob();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDownloadSalePersonDocumentUploadZip = createAsyncThunk<
  BlobPart,
  { id: string; company_name: string; name: string }
>(
  'AdminArchiveFile/getDownloadDocumentUploadZip',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      if (!state.auth.accessToken) {
        return rejectWithValue(new Error('Not logged in'));
      }
      const response = await adminArchiveFile.getDownloadZipFileSalePerson(
        {
          id: data.id,
        },
        state.auth.accessToken
      );

      if (response.status === 204) {
        FileSaver.saveAs(
          await response.blob(),
          `${data.company_name}_${data.name}アップロード資料_${dayjs().format(
            'YYYYMMDDhhmmss'
          )}.zip`
        );
      } else {
        adminModalUpdateDetailRef.current?.open();
      }
      return response.blob();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
