import { FC } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Modal as MuiModal, Stack, Typography } from '@mui/material';
import { Button } from '../../atoms';

type ModalChangeValueProps = {
  open: boolean;
  message: string;
  onClose: () => void;
};

export const ModalChangeValue: FC<ModalChangeValueProps> = ({
  open,
  message,
  onClose,
}) => {
  return (
    <MuiModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={onClose}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '25%',
          minWidth: 330,
          minHeight: 140,
          backgroundColor: 'main_white',
          '@media (orientation: landscape)': {
            minWidth: 'auto',
            maxHeight: '75vh',
          },
          borderRadius: 2,
          paddingBottom: 8,
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <CloseIcon
            onClick={onClose}
            fontSize="medium"
            sx={{
              color: 'b_333',
              cursor: 'pointer',
              pb: 2,
              pt: 2,
              pr: 2,
              width: 20,
              height: 20,
            }}
          />
        </Stack>

        <Stack alignItems="center" spacing={3}>
          <Typography
            variant="text_note"
            color="b_333"
            sx={{ textAlign: 'center', px: 3 }}
          >
            {message}
          </Typography>
        </Stack>
        <Stack
          justifyContent="center"
          direction="row"
          spacing={3}
          sx={{
            pt: 5,
          }}
        >
          <Button
            sx={{
              bgcolor: 'white',
              boxShadow: 'none',
              width: '100px',
              height: '36px',
              borderRadius: '2px',
              minHeight: '36px',
              border: '1px solid #6B70F0',
            }}
            onClick={onClose}
          >
            <Typography variant="button01" color="sp_primary_100_main">
              閉じる
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </MuiModal>
  );
};
