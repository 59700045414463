import { MESSAGE_REGEX, SP_FIELD_ERROR_MESSAGE } from 'constant';
import { regex, yup } from 'libs';

export const validationSchema = yup.object().shape({
  p_join_guarantors: yup.array(
    yup.object().shape(
      {
        last_name_kanji: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(48)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        }),
        first_name_kanji: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(48)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        }),
        last_name_kana: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .max(48)
            .matches(regex.kanaHalfwidthHaveSpace, MESSAGE_REGEX.NAME_KANA)
            .required(MESSAGE_REGEX.REQUIRED),
        }),
        first_name_kana: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .max(48)
            .matches(regex.kanaHalfwidthHaveSpace, MESSAGE_REGEX.NAME_KANA)
            .required(MESSAGE_REGEX.REQUIRED),
        }),
        sex: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup.string(),
        }),
        guarantor_relationship_name: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .max(48)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        }),
        birthday: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
            .test(
              'one-field-missing',
              MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
              (value) => {
                const [year, month, day] = value
                  ? value.split('/')
                  : ['', '', ''];
                return (
                  (!!year && !!month && !!day) || (!year && !month && !day)
                );
              }
            )
            .label(SP_FIELD_ERROR_MESSAGE.join_guarantor_birthday),
        }),
        mobile_phone_number: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            // .min(
            //   VALIDATE_MAX.MOBILE_PHONE,
            //   MESSAGE_REGEX.MAX_LENGTH_MOBILE_PHONE_SP
            // )
            // .noZeroPhoneNumber()
            // .matches(regex.mobilePhone, MESSAGE_REGEX.MOBILE_PHONE_SP)
            .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER)
            .when('home_phone_number', {
              is: (homePhone: string) => !homePhone || homePhone.length === 0,
              then: yup.string().required(MESSAGE_REGEX.REQUIRED),
            }),
        }),
        home_phone_number: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            // .noZeroPhoneNumber()
            // .matches(regex.fixedPhones, MESSAGE_REGEX.FIXED_PHONE_SP)
            .matches(regex.freePhones, MESSAGE_REGEX.TYPE_NUMBER)
            .when('mobile_phone_number', {
              is: (phoneNumber: string) =>
                !phoneNumber || phoneNumber.length === 0,
              then: yup.string().required(),
            }),
        }),
        postal_code: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .min(8, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS)
            .matches(regex.zipCode, MESSAGE_REGEX.ENTER_ZIPCODE_SEVEN_DIGITS),
        }),
        prefecture_kanji: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
            .max(8)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH)
            .label(SP_FIELD_ERROR_MESSAGE.join_guarantor_prefecture_kanji),
        }),
        city_kanji: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(20)
            .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        }),
        district_kanji: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .required(MESSAGE_REGEX.REQUIRED)
            .max(40)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.ADDRESS_KANJI
            ),
        }),
        other_address_kanji: yup.string().when('_destroy', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup
            .string()
            .max(99)
            .matches(
              regex.kanjiFullwidthHaveNumber,
              MESSAGE_REGEX.ADDRESS_KANJI
            )
            .required(MESSAGE_REGEX.REQUIRED),
        }),
      },
      [['mobile_phone_number', 'home_phone_number']]
    )
  ),
});
