import { createSlice } from '@reduxjs/toolkit';
import {
  ApplicantDetailType,
  BorrowerType,
  BorrowingCategoryType,
  BorrowingFromHousingFinanceAgencyType,
  BorrowingStatusType,
  BusinessBorrowingType,
  BuyingandSellingScheduleType,
  ChildType,
  CoOwnerType,
  CollateralType,
  CommonHousingType,
  CurrentResidenceType,
  DispatchType,
  EstateMortgageType,
  FinalTaxReturnType,
  FinanceAgencyType,
  Flat35ApplicablePlanType,
  Flat35ApplicationType,
  FundsPairOffType,
  HasLandAdvancePlanType,
  INTERFACE_ERROR_STATUS,
  JointOwnershipDivision,
  LandOwnershipType,
  LoanPurposeType,
  LoanType,
  MaintenanceType,
  NationalityType,
  OccupationDetailType,
  OccupationType,
  OneRoofType,
  PersonOccupancyType,
  PlanningAreaType,
  PositionType,
  PurposeType,
  ReasonsForNeedingHousingType,
  RebuildingReasonType,
  RegionType,
  RelationshipToApplicantType,
  RelationshipType,
  RepaymentType,
  SBI_NAME,
  SPBorrowingType,
  SPScheduledLoanPayoffType,
  SexType,
  SourceOfIncomeType,
  PreliminaryStatusType,
} from 'constant';
import { cloneDeep } from 'lodash';
import {
  AdminPreliminaries,
  UploadedFileKeys,
  UploadedFileKeysBorrowing,
  UploadedFileKeysHeader,
} from 'services';
import { formatTimeForCheckAsync, handleShowError } from 'utils';
import {
  editAdminPreliminary,
  getAdminExamination,
  getLinkPersonalInformations,
  getPersonalInformations,
  getReferralAgencyOptions,
  getUploadedFilesManager,
  updateInterfaceErrorStatus,
  updateUploadedFilesManager,
} from './thunk';

export type DropDownItems = {
  value: string;
  label: string;
  id?: string;
  active?: boolean;
};
interface InitialState {
  fetching: boolean;
  isDisableSaveButton: boolean;
  adminPreliminaries: AdminPreliminaries;
  adminLinkPreliminaries: AdminPreliminaries;
  filesLink: UploadedFileKeys;
  files: UploadedFileKeys;
  filesIncomeAdder: UploadedFileKeys;
  filesHeader: UploadedFileKeysHeader;
  filesBorrowing: UploadedFileKeysBorrowing;
  filesLinkBorrowing: UploadedFileKeysBorrowing;
  filesLinkHeader: UploadedFileKeysHeader;
  filesRemove: UploadedFileKeys;
  filesIncomeAdderRemove: UploadedFileKeys;
  filesHeaderRemove: UploadedFileKeysHeader;
  filesBorrowingRemove: UploadedFileKeysBorrowing;
  isLoadingFiles: boolean;
  agenciesOptions: DropDownItems[];
  storesOptions: DropDownItems[];
  exhibitionsOptions: DropDownItems[];
  salePersonsOptions: DropDownItems[];
  agenciesOptionsLink: DropDownItems[];
  storesOptionsLink: DropDownItems[];
  exhibitionsOptionsLink: DropDownItems[];
  salePersonsOptionsLink: DropDownItems[];
  timeGetEdit?: string;
  sbiId: string | undefined;
}

const defaultDataFile = [{ id: '', filename: '', url: '', hiden: false }];

export const defaultFiles = {
  driver_license_front_image: [],
  driver_license_back_image: [],
  card_number_front_image: [],
  // card_number_back_image: [],
  resident_register_front_image: [],
  resident_register_back_image: [],
  insurance_file: [],
  insurance_file_back_image: [],
  first_withholding_slip_file: [],
  second_withholding_slip_file: [],
  first_income_file: [],
  second_income_file: [],
  third_income_file: [],
  financial_statement_1_file: [],
  financial_statement_2_file: [],
  financial_statement_3_file: [],
  other_document_file: [],
  employment_agreement_file: [],
  business_tax_return_1_file: [],
  business_tax_return_2_file: [],
  business_tax_return_3_file: [],
  residence_file: [],
  residence_file_back_image: [],
};

const defaultFilesHeader = {
  property_information_file_index: [],
  manager_property_information_file_index: [],
  business_card_index: [],
};

const defaultFilesBorrowing = {
  repayment_schedule_image_index__0: [],
  repayment_schedule_image_index__1: [],
  repayment_schedule_image_index__2: [],
  repayment_schedule_image_index__3: [],
  repayment_schedule_image_index__4: [],
  repayment_schedule_image_index__5: [],
  repayment_schedule_image_index__6: [],
  repayment_schedule_image_index__7: [],
};

export const initialState: InitialState = {
  fetching: false,
  isDisableSaveButton: false,
  adminPreliminaries: {
    p_application_header: {
      id: '',
      user_id: '',
      sale_person_name_input: '',
      loan_apply_date: '',
      scheduled_date_moving: '',
      created_at: '',
      application_number: '',
      relationship_to_applicant: RelationshipToApplicantType.DEFAULT,
      name_master_banks: [],
      master_bank_ids: [],
      loan_type: LoanType.DEFAULT,
      loan_target: PurposeType.DEFAULT,
      loan_target_zero: PurposeType.DEFAULT,
      loan_target_default: PurposeType.DEFAULT,
      is_home_loan_plus: false,
      has_land_advance_plan: HasLandAdvancePlanType.DEFAULT,
      preliminary_status: PreliminaryStatusType.DEFAULT,
      examination_file: {
        id: '',
        enable: false,
        filename: '',
        path: '',
      },
      edit_main_statuses: false,
      property_information_url: '',
      person_occupancy: PersonOccupancyType.DEFAULT,
      children_number: '',
      siblings_number: '',
      other_people_number: '',
      housemate_number: '0',
      business_ability: [],
      collateral_prefecture: '',
      collateral_city: '',
      collateral_lot_number: '',
      additional_cost: '',
      require_funds_breakdown_mortgage: '',
      deposit_savings_1: '',
      real_estate_sale_price: '',
      other_saving_amount: '',
      // other_relationship: '',
      relative_donation_amount: '',
      loan_amount: '',
      other_procurement_breakdown: '',
      other_procurement_breakdown_content: '',
      s_sale_person_id: '',
      p_referral_agency_id: '',
      planned_cohabitant: [],
      property_postal_code: '',
      collateral_address_kana: '',
      collateral_type: CollateralType.DEFAULT,
      acquisition_time_of_the_land: '',
      joint_ownership_division: JointOwnershipDivision.DEFAULT,
      building_ratio_numerator: '',
      building_ratio_denominator: '',
      land_ratio_numerator: '',
      land_ratio_denominator: '',
      land_price: '',
      linking_id: '',
      building_price: '',
      land_and_building_price: '',
      saving_amount: '',
      amount_any_loans: '',
      amount_others: '',
      property_information_file_index: defaultDataFile,
      manager_property_information_file_index: defaultDataFile,
      business_card_index: defaultDataFile,
      p_application_banks_attributes: [
        {
          id: '',
          s_master_bank_id: '',
          name: '',
          _destroy: false,
        },
      ],
      p_applicant_people_attributes: [
        {
          id: '',
          applicant_detail_type: ApplicantDetailType.DEFAULT,
          has_join_guarantor: CoOwnerType.DEFAULT,
          last_name_kanji: '',
          first_name_kanji: '',
          last_name_kana: '',
          first_name_kana: '',
          sex: '',
          name_relationship_to_applicant: '',
          birthday: '',
          nationality: '',
          mobile_phone_number: '',
          home_phone_number: '',
          postal_code: '',
          prefecture_kanji: '',
          city_kanji: '',
          district_kanji: '',
          other_address_kanji: '',
          owner_email: '',
          occupation: OccupationType.DEFAULT,
          other_occupation: '',
          industry: '',
          other_industry: '',
          occupation_detail: OccupationDetailType.DEFAULT,
          other_occupation_detail: '',
          office_name_kanji: '',
          department: '',
          office_phone_number: '',
          office_postal_code: '',
          office_prefecture_kanji: '',
          office_city_kanji: '',
          office_district_kanji: '',
          number_of_employee: '',
          employment_started_date: '',
          last_year_income: '',
          bonus_income: '',
          two_years_ago_income: '',
          tax_return: FinalTaxReturnType.DEFAULT,
          other_type_tax_return: '',
          transfer_office: DispatchType.DEFAULT,
          transfer_office_name_kanji: '',
          transfer_office_name_kana: '',
          transfer_office_phone_number: '',
          transfer_office_postal_code: '',
          transfer_office_prefecture_kanji: '',
          transfer_office_city_kanji: '',
          transfer_office_district_kanji: '',
          transfer_office_other_address_kanji: '',
          maternity_paternity_leave_status: '',
          maternity_paternity_leave_start_time: '',
          maternity_paternity_leave_end_time: '',
          nursing_leave_status: '',
          lived_length_year_num: '',
          lived_length_month_num: '',
          current_residence: CurrentResidenceType.DEFAULT,
          current_residence_floor_area: '',
          owner_full_name: '',
          owner_relationship: '',
          buyingand_selling_schedule_type: BuyingandSellingScheduleType.DEFAULT,
          other_buyingand_selling_schedule_type: '',
          scheduled_time_sell_house: '',
          expected_house_selling_price: '',
          current_home_loan: '',
          reason_acquire_home: ReasonsForNeedingHousingType.DEFAULT,
          other_reason_acquire_home: '',
          borrowing_status: BorrowingStatusType.DEFAULT,
          land_rent_to_be_paid: '',
          house_rent_to_be_paid: '',
          identity_verification: '',
          emergency_contact_number: '',
          prefecture_kana: '',
          city_kana: '',
          district_kana: '',
          other_address_kana: '',
          emplmt_form_code: '',
          office_name_kana: '',
          position: PositionType.DEFAULT,
          headquarters_location: '',
          listed_division: '',
          office_establishment_date: '',
          capital_stock: '',
          other_office_address_kanji: '',
          office_prefecture_kana: '',
          office_city_kana: '',
          office_district_kana: '',
          other_office_address_kana: '',
          relationship_to_applicant: '',
          other_relationship_to_applicant: '',
          _destroy: false,
          income_source: [],
          main_income_source: SourceOfIncomeType.DEFAULT,
          type_tax_return: [],
          personal_file_upload: {
            driver_license_front_image: defaultDataFile,
            driver_license_back_image: defaultDataFile,
            card_number_front_image: defaultDataFile,
            // card_number_back_image: defaultDataFile,
            resident_register_front_image: defaultDataFile,
            resident_register_back_image: defaultDataFile,
            insurance_file: defaultDataFile,
            insurance_file_back_image: defaultDataFile,
            first_withholding_slip_file: defaultDataFile,
            second_withholding_slip_file: defaultDataFile,
            first_income_file: defaultDataFile,
            second_income_file: defaultDataFile,
            third_income_file: defaultDataFile,
            financial_statement_1_file: defaultDataFile,
            financial_statement_2_file: defaultDataFile,
            financial_statement_3_file: defaultDataFile,
            other_document_file: defaultDataFile,
            employment_agreement_file: defaultDataFile,
            business_tax_return_1_file: defaultDataFile,
            business_tax_return_2_file: defaultDataFile,
            business_tax_return_3_file: defaultDataFile,
            residence_file: defaultDataFile,
            residence_file_back_image: defaultDataFile,
          },
        },
      ],
      p_borrowing_details_attributes: [
        {
          id: '',
          loan_desired_borrowing_date: '',
          temporary_desired_loan_amount: '',
          halfyear_bonus: '',
          desired_monthly_bonus: '',
          loan_term_year_num: '',
          loan_term_month_num: '',
          repayment_method: RepaymentType.DEFAULT,
          borrowing_detail_type: '',
          _destroy: false,
        },
      ],

      p_join_guarantors_attributes: [],
      p_borrowings_attributes: [
        {
          id: '',
          borrowing_type: SPBorrowingType.DEFAULT,
          borrower: BorrowerType.DEFAULT,
          lender: '',
          borrowing_from_housing_finance_agency:
            BorrowingFromHousingFinanceAgencyType.DEFAULT,
          loan_purpose: LoanPurposeType.DEFAULT,
          business_borrowing_type: BusinessBorrowingType.DEFAULT,
          other_purpose: '',
          specific_loan_purpose: '',
          borrowing_category: BorrowingCategoryType.DEFAULT,
          loan_start_date: '',
          loan_amount: '',
          current_balance_amount: '',
          annual_repayment_amount: '',
          card_expiry_date: '',
          scheduled_loan_payoff: SPScheduledLoanPayoffType.DEFAULT,
          loan_deadline_date: '',
          expected_repayment_date: '',
          rental_room_number: '',
          common_housing: CommonHousingType.DEFAULT,
          estate_mortgage: EstateMortgageType.DEFAULT,
          _destroy: false,
          include_in_examination: '',
          repayment_schedule_image_index: [],
        },
      ],
      p_residents_attributes: [],
      sale_person_information: {
        list_agencies: [
          {
            id: '',
            sale_agent_id: '',
            sale_agent_name_kanji: '',
          },
        ],
        list_stores: [{ id: '', store_id: '', store_name_kanji: '' }],
        list_exhibitions: [
          {
            id: '',
            exhibition_id: '',
            exhibition_hall: '',
          },
        ],
        list_sale_persons: [
          {
            id: '',
            value: '',
          },
        ],
        sale_person_name: '',
        sale_person_email: '',
        sale_person_phone: '',
        active_store_id: '',
        active_exhibition_id: '',
        active_agency_id: '',
      },
      sale_person_phone_number: '',
      editable: true,
      main_statuses: [],
      provisional_results: [],
      public_status: [],
      collateral_land_area: '',
      occupied_area: '',
      house_purchase_price: '',
      accessory_cost: '',
      land_purchase_price: '',
      house_upgrade_cost: '',
      pair_loan_amount: '',
      refinancing_loan_balance: '',
      planning_area: PlanningAreaType.DEFAULT,
      condominium_name: '',
      land_ownership: LandOwnershipType.DEFAULT,
      non_resident_reason: '',
      other_planning_area: '',
      flat_35_application: Flat35ApplicationType.DEFAULT,
      flat_35_applicable_plan: Flat35ApplicablePlanType.DEFAULT,
      maintenance_type: MaintenanceType.DEFAULT,
      other_rebuilding_reason: '',
      rebuilding_reason: RebuildingReasonType.DEFAULT,
      region_type: RegionType.DEFAULT,
      completely_repayment_type: FundsPairOffType.DEFAULT,
      other_complete_repayment_type: '',
      refund_amount: '',
      refund_content: '',
      has_log_fields: [''],
      exhibition_hall: '',
      sale_agent_name_kanji: '',
      store_name_kanji: '',
      interface_error_status: INTERFACE_ERROR_STATUS.NOT_HAVE_ERROR,
    },
  },
  adminLinkPreliminaries: {
    p_application_header: {
      id: '',
      user_id: '',
      sale_person_name_input: '',
      loan_apply_date: '',
      created_at: '',
      scheduled_date_moving: '',
      name_master_banks: [],
      master_bank_ids: [],
      application_number: '',
      relationship_to_applicant: RelationshipToApplicantType.DEFAULT,
      loan_type: LoanType.DEFAULT,
      loan_target: PurposeType.DEFAULT,
      loan_target_zero: PurposeType.DEFAULT,
      loan_target_default: PurposeType.DEFAULT,
      is_home_loan_plus: false,
      has_land_advance_plan: HasLandAdvancePlanType.DEFAULT,
      preliminary_status: PreliminaryStatusType.DEFAULT,
      examination_file: {
        id: '',
        enable: false,
        filename: '',
        path: '',
      },
      edit_main_statuses: false,
      property_information_url: '',
      person_occupancy: PersonOccupancyType.DEFAULT,
      children_number: '',
      siblings_number: '',
      other_people_number: '',
      housemate_number: '0',
      business_ability: [],
      collateral_prefecture: '',
      collateral_city: '',
      collateral_lot_number: '',
      additional_cost: '',
      require_funds_breakdown_mortgage: '',
      deposit_savings_1: '',
      real_estate_sale_price: '',
      other_saving_amount: '',
      // other_relationship: '',
      relative_donation_amount: '',
      loan_amount: '',
      other_procurement_breakdown: '',
      other_procurement_breakdown_content: '',
      s_sale_person_id: '',
      p_referral_agency_id: '',
      planned_cohabitant: [],
      property_postal_code: '',
      collateral_address_kana: '',
      collateral_type: CollateralType.DEFAULT,
      acquisition_time_of_the_land: '',
      joint_ownership_division: JointOwnershipDivision.DEFAULT,
      building_ratio_numerator: '',
      building_ratio_denominator: '',
      land_ratio_numerator: '',
      land_ratio_denominator: '',
      land_price: '',
      linking_id: '',
      building_price: '',
      land_and_building_price: '',
      saving_amount: '',
      amount_any_loans: '',
      amount_others: '',
      business_card_index: defaultDataFile,
      manager_property_information_file_index: defaultDataFile,
      property_information_file_index: defaultDataFile,
      p_application_banks_attributes: [
        {
          id: '',
          s_master_bank_id: '',
          name: '',
          _destroy: false,
        },
      ],
      p_applicant_people_attributes: [
        {
          id: '',
          applicant_detail_type: ApplicantDetailType.DEFAULT,
          has_join_guarantor: CoOwnerType.DEFAULT,
          last_name_kanji: '',
          first_name_kanji: '',
          last_name_kana: '',
          first_name_kana: '',
          sex: '',
          name_relationship_to_applicant: '',
          birthday: '',
          nationality: '',
          mobile_phone_number: '',
          home_phone_number: '',
          postal_code: '',
          prefecture_kanji: '',
          city_kanji: '',
          district_kanji: '',
          other_address_kanji: '',
          owner_email: '',
          occupation: OccupationType.DEFAULT,
          other_occupation: '',
          industry: '',
          other_industry: '',
          occupation_detail: OccupationDetailType.DEFAULT,
          other_occupation_detail: '',
          office_name_kanji: '',
          department: '',
          office_phone_number: '',
          office_postal_code: '',
          office_prefecture_kanji: '',
          office_city_kanji: '',
          office_district_kanji: '',
          number_of_employee: '',
          employment_started_date: '',
          last_year_income: '',
          bonus_income: '',
          two_years_ago_income: '',
          tax_return: FinalTaxReturnType.DEFAULT,
          other_type_tax_return: '',
          transfer_office: DispatchType.DEFAULT,
          transfer_office_name_kanji: '',
          transfer_office_name_kana: '',
          transfer_office_phone_number: '',
          transfer_office_postal_code: '',
          transfer_office_prefecture_kanji: '',
          transfer_office_city_kanji: '',
          transfer_office_district_kanji: '',
          maternity_paternity_leave_status: '',
          maternity_paternity_leave_start_time: '',
          maternity_paternity_leave_end_time: '',
          nursing_leave_status: '',
          lived_length_year_num: '',
          lived_length_month_num: '',
          current_residence: CurrentResidenceType.DEFAULT,
          current_residence_floor_area: '',
          owner_full_name: '',
          owner_relationship: '',
          buyingand_selling_schedule_type: BuyingandSellingScheduleType.DEFAULT,
          other_buyingand_selling_schedule_type: '',
          scheduled_time_sell_house: '',
          expected_house_selling_price: '',
          current_home_loan: '',
          reason_acquire_home: ReasonsForNeedingHousingType.DEFAULT,
          other_reason_acquire_home: '',
          borrowing_status: BorrowingStatusType.DEFAULT,
          land_rent_to_be_paid: '',
          house_rent_to_be_paid: '',
          identity_verification: '',
          emergency_contact_number: '',
          prefecture_kana: '',
          city_kana: '',
          district_kana: '',
          other_address_kana: '',
          emplmt_form_code: '',
          office_name_kana: '',
          position: PositionType.DEFAULT,
          headquarters_location: '',
          listed_division: '',
          office_establishment_date: '',
          capital_stock: '',
          other_office_address_kanji: '',
          office_prefecture_kana: '',
          office_city_kana: '',
          office_district_kana: '',
          other_office_address_kana: '',
          relationship_to_applicant: '',
          other_relationship_to_applicant: '',
          _destroy: false,
          income_source: [],
          main_income_source: SourceOfIncomeType.DEFAULT,
          type_tax_return: [],
          personal_file_upload: {
            driver_license_front_image: defaultDataFile,
            driver_license_back_image: defaultDataFile,
            card_number_front_image: defaultDataFile,
            // card_number_back_image: defaultDataFile,
            resident_register_front_image: defaultDataFile,
            resident_register_back_image: defaultDataFile,
            insurance_file: defaultDataFile,
            insurance_file_back_image: defaultDataFile,
            first_withholding_slip_file: defaultDataFile,
            second_withholding_slip_file: defaultDataFile,
            first_income_file: defaultDataFile,
            second_income_file: defaultDataFile,
            third_income_file: defaultDataFile,
            financial_statement_1_file: defaultDataFile,
            financial_statement_2_file: defaultDataFile,
            financial_statement_3_file: defaultDataFile,
            other_document_file: defaultDataFile,
            employment_agreement_file: defaultDataFile,
            business_tax_return_1_file: defaultDataFile,
            business_tax_return_2_file: defaultDataFile,
            business_tax_return_3_file: defaultDataFile,
            residence_file: defaultDataFile,
            residence_file_back_image: defaultDataFile,
          },
        },
      ],
      p_borrowing_details_attributes: [
        {
          id: '',
          loan_desired_borrowing_date: '',
          temporary_desired_loan_amount: '',
          halfyear_bonus: '',
          desired_monthly_bonus: '',
          loan_term_year_num: '',
          loan_term_month_num: '',
          repayment_method: RepaymentType.DEFAULT,
          borrowing_detail_type: '',
          _destroy: false,
        },
      ],
      p_join_guarantors_attributes: [],
      p_borrowings_attributes: [
        {
          id: '',
          borrowing_type: SPBorrowingType.DEFAULT,
          borrower: BorrowerType.DEFAULT,
          lender: '',
          borrowing_from_housing_finance_agency:
            BorrowingFromHousingFinanceAgencyType.DEFAULT,
          loan_purpose: LoanPurposeType.DEFAULT,
          business_borrowing_type: BusinessBorrowingType.DEFAULT,
          other_purpose: '',
          specific_loan_purpose: '',
          borrowing_category: BorrowingCategoryType.DEFAULT,
          loan_start_date: '',
          loan_amount: '',
          current_balance_amount: '',
          annual_repayment_amount: '',
          card_expiry_date: '',
          scheduled_loan_payoff: SPScheduledLoanPayoffType.DEFAULT,
          loan_deadline_date: '',
          expected_repayment_date: '',
          rental_room_number: '',
          common_housing: CommonHousingType.DEFAULT,
          estate_mortgage: EstateMortgageType.DEFAULT,
          _destroy: false,
          include_in_examination: '',
          repayment_schedule_image_index: [],
        },
      ],
      p_residents_attributes: [
        {
          last_name_kanji: '',
          first_name_kanji: '',
          last_name_kana: '',
          first_name_kana: '',
          relationship_name: '',
          nationality: NationalityType.DEFAULT,
          birthday: '',
          loan_from_japan_housing_finance_agency: FinanceAgencyType.DEFAULT,
          home_phone_number: '',
          postal_code: '',
          prefecture_kanji: '',
          city_kanji: '',
          district_kanji: '',
          other_address_kanji: '',
          relationship: RelationshipType.DEFAULT,
          other_relationship: ChildType.DEFAULT,
          sex: SexType.DEFAULT,
          full_name_kanji: '',
          full_name_kana: '',
          one_roof: OneRoofType.DEFAULT,
        },
      ],
      sale_person_information: {
        list_agencies: [
          {
            id: '',
            sale_agent_id: '',
            sale_agent_name_kanji: '',
          },
        ],
        list_stores: [{ id: '', store_id: '', store_name_kanji: '' }],
        list_exhibitions: [
          {
            id: '',
            exhibition_id: '',
            exhibition_hall: '',
          },
        ],
        list_sale_persons: [
          {
            id: '',
            value: '',
          },
        ],
        sale_person_name: '',
        sale_person_email: '',
        sale_person_phone: '',
        active_store_id: '',
        active_exhibition_id: '',
        active_agency_id: '',
      },
      sale_person_phone_number: '',
      editable: true,
      main_statuses: [],
      provisional_results: [],
      public_status: [],
      collateral_land_area: '',
      occupied_area: '',
      house_purchase_price: '',
      land_purchase_price: '',
      accessory_cost: '',
      refinancing_loan_balance: '',
      house_upgrade_cost: '',
      pair_loan_amount: '',
      planning_area: PlanningAreaType.DEFAULT,
      condominium_name: '',
      land_ownership: LandOwnershipType.DEFAULT,
      non_resident_reason: '',
      other_planning_area: '',
      flat_35_application: Flat35ApplicationType.DEFAULT,
      flat_35_applicable_plan: Flat35ApplicablePlanType.DEFAULT,
      maintenance_type: MaintenanceType.DEFAULT,
      other_rebuilding_reason: '',
      rebuilding_reason: RebuildingReasonType.DEFAULT,
      region_type: RegionType.DEFAULT,
      completely_repayment_type: FundsPairOffType.DEFAULT,
      other_complete_repayment_type: '',
      refund_amount: '',
      refund_content: '',
      has_log_fields: [''],
      exhibition_hall: '',
      sale_agent_name_kanji: '',
      store_name_kanji: '',
      interface_error_status: INTERFACE_ERROR_STATUS.NOT_HAVE_ERROR,
    },
  },
  files: defaultFiles,
  filesIncomeAdder: defaultFiles,
  filesLink: defaultFiles,
  filesHeader: defaultFilesHeader,
  filesBorrowing: defaultFilesBorrowing,
  filesLinkHeader: defaultFilesHeader,
  filesLinkBorrowing: defaultFilesBorrowing,
  filesRemove: defaultFiles,
  filesIncomeAdderRemove: defaultFiles,
  filesHeaderRemove: defaultFilesHeader,
  filesBorrowingRemove: defaultFilesBorrowing,
  isLoadingFiles: false,
  agenciesOptions: [],
  storesOptions: [],
  exhibitionsOptions: [],
  salePersonsOptions: [],
  agenciesOptionsLink: [],
  storesOptionsLink: [],
  exhibitionsOptionsLink: [],
  salePersonsOptionsLink: [],
  timeGetEdit: '',
  sbiId: '',
};

const adminPreliminarySlice = createSlice({
  name: 'adminPreliminarySlice',
  initialState: cloneDeep(initialState),
  reducers: {
    clearAllFiles: (state) => {
      state.files = defaultFiles;
      state.filesIncomeAdder = defaultFiles;
      state.filesLink = defaultFiles;
      state.filesHeader = defaultFilesHeader;
      state.filesLinkHeader = defaultFilesHeader;
      state.filesBorrowing = defaultFilesBorrowing;
      state.filesLinkBorrowing = defaultFilesBorrowing;
      state.filesRemove = defaultFiles;
      state.filesIncomeAdderRemove = defaultFiles;
      state.filesHeaderRemove = defaultFilesHeader;
      state.filesBorrowingRemove = defaultFilesBorrowing;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPersonalInformations.pending, (state) => {
      state.fetching = true;
      state.adminPreliminaries = cloneDeep(initialState.adminPreliminaries);
    });
    builder.addCase(getPersonalInformations.fulfilled, (state, action) => {
      state.fetching = false;
      state.isDisableSaveButton = false;
      const loanTarget = action.payload.p_application_header.loan_target;
      state.adminPreliminaries = action.payload;
      state.timeGetEdit = formatTimeForCheckAsync(new Date());
      state.sbiId =
        action.payload.p_application_header.p_application_banks_attributes.find(
          (bank) => bank.name === SBI_NAME
        )?.id;
      if (loanTarget === PurposeType.DEFAULT) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.DEFAULT;
      }
      if (
        loanTarget !== PurposeType.SEVEN &&
        loanTarget !== PurposeType.EIGHT &&
        loanTarget !== PurposeType.DEFAULT
      ) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.ZERO;
      }
      if (loanTarget === PurposeType.SEVEN) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.SEVEN;
      }
      if (loanTarget === PurposeType.EIGHT) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.EIGHT;
      }
      state.adminPreliminaries.p_application_header.loan_target_default =
        PurposeType.DEFAULT;

      const hasJoinGuarantor =
        action.payload.p_application_header.p_applicant_people_attributes[0]
          .has_join_guarantor;
      const pJoinGuarantor =
        action.payload.p_application_header.p_join_guarantors_attributes;
      if (
        hasJoinGuarantor === CoOwnerType.CANBE &&
        pJoinGuarantor.length === 0
      ) {
        state.adminPreliminaries.p_application_header.p_join_guarantors_attributes =
          [
            {
              id: '',
              last_name_kanji: '',
              first_name_kanji: '',
              last_name_kana: '',
              first_name_kana: '',
              sex: SexType.DEFAULT,
              guarantor_relationship_name: '',
              birthday: '',
              mobile_phone_number: '',
              home_phone_number: '',
              postal_code: '',
              prefecture_kanji: '',
              city_kanji: '',
              district_kanji: '',
              other_address_kanji: '',
              guarantor_relationship_to_applicant: '',
              other_relationship_to_applicant: '',
              emergency_contact_number: '',
              prefecture_kana: '',
              city_kana: '',
              district_kana: '',
              other_address_kana: '',
              owner_email: '',
              _destroy: false,
            },
          ];
      }

      action.payload.p_application_header.p_applicant_people_attributes.forEach(
        (data, index) => {
          const dataFiles = data.personal_file_upload;
          Object.keys(dataFiles).forEach((key) => {
            // @ts-ignore
            if (!dataFiles[`${key}`]?.[0]?.url) dataFiles[`${key}`] = [];
          });

          if (index === 0) {
            state.files = dataFiles;
          }
          if (index === 1) {
            state.filesIncomeAdder = dataFiles;
          }
        }
      );

      state.filesHeader = {
        business_card_index:
          action.payload.p_application_header.business_card_index,
        manager_property_information_file_index:
          action.payload.p_application_header
            .manager_property_information_file_index,
        property_information_file_index:
          action.payload.p_application_header.property_information_file_index,
      };
      const dataFilesBorrowing =
        action.payload.p_application_header.p_borrowings_attributes;
      dataFilesBorrowing.forEach((data, index) => {
        if (index < 0 || index > 7) {
          state.filesBorrowing.repayment_schedule_image_index__0 =
            data.repayment_schedule_image_index;
        } else {
          // @ts-ignore
          state.filesBorrowing[`repayment_schedule_image_index__${index}`] =
            data.repayment_schedule_image_index;
        }
      });

      const { sale_person_information } = action.payload.p_application_header;
      const agenciesResponse: DropDownItems[] = [];
      const storesResponse: DropDownItems[] = [];
      const exhibitionsResponse: DropDownItems[] = [];
      const salePersonsResponse: DropDownItems[] = [];
      sale_person_information.list_agencies.forEach((agency) => {
        agenciesResponse.push({
          id: agency.id,
          value: agency.sale_agent_id.toString(),
          label: agency.sale_agent_name_kanji,
          active: sale_person_information.active_agency_id === agency.id,
        });
      });
      sale_person_information.list_stores.forEach((store) => {
        storesResponse.push({
          id: store.id,
          value: store.store_id.toString(),
          label: store.store_name_kanji,
          active: sale_person_information.active_store_id === store.id,
        });
      });
      sale_person_information.list_exhibitions.forEach((exhibition) => {
        exhibitionsResponse.push({
          id: exhibition.id,
          value: exhibition.exhibition_id.toString(),
          label: exhibition.exhibition_hall,
          active:
            sale_person_information.active_exhibition_id === exhibition.id,
        });
      });
      sale_person_information.list_sale_persons.forEach((sale_person) => {
        salePersonsResponse.push({
          value: sale_person.id.toString(),
          label: sale_person.value,
        });
      });

      state.agenciesOptions = agenciesResponse;
      state.storesOptions = storesResponse;
      state.exhibitionsOptions = exhibitionsResponse;
      state.salePersonsOptions = salePersonsResponse;

      state.adminPreliminaries.p_application_header.sale_person_information.active_agency_id =
        sale_person_information.list_agencies.find(
          (item) => item.id === sale_person_information.active_agency_id
        )?.sale_agent_id;

      state.adminPreliminaries.p_application_header.sale_person_information.active_store_id =
        sale_person_information.list_stores.find(
          (item) => item.id === sale_person_information.active_store_id
        )?.store_id;

      state.adminPreliminaries.p_application_header.sale_person_information.active_exhibition_id =
        sale_person_information.list_exhibitions.find(
          (item) => item.id === sale_person_information.active_exhibition_id
        )?.exhibition_id;
    });
    builder.addCase(getPersonalInformations.rejected, (state, action) => {
      handleShowError(action);
    });
    builder.addCase(getUploadedFilesManager.rejected, (state, action) => {
      handleShowError(action);
    });
    builder.addCase(getLinkPersonalInformations.pending, (state) => {
      state.adminLinkPreliminaries = cloneDeep(
        initialState.adminLinkPreliminaries
      );
    });
    builder.addCase(getLinkPersonalInformations.fulfilled, (state, action) => {
      state.adminLinkPreliminaries = action.payload;

      const loanTarget = action.payload.p_application_header.loan_target;
      if (loanTarget === PurposeType.DEFAULT) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.DEFAULT;
      }
      if (
        loanTarget !== PurposeType.SEVEN &&
        loanTarget !== PurposeType.EIGHT
      ) {
        state.adminLinkPreliminaries.p_application_header.loan_target_zero =
          PurposeType.ZERO;
      }
      if (loanTarget === PurposeType.SEVEN) {
        state.adminLinkPreliminaries.p_application_header.loan_target_zero =
          PurposeType.SEVEN;
      }
      if (loanTarget === PurposeType.EIGHT) {
        state.adminLinkPreliminaries.p_application_header.loan_target_zero =
          PurposeType.EIGHT;
      }
      state.adminLinkPreliminaries.p_application_header.loan_target_default =
        PurposeType.DEFAULT;

      const hasJoinGuarantorLink =
        action.payload.p_application_header.p_applicant_people_attributes?.[1]
          ?.has_join_guarantor;
      const pJoinGuarantorLink =
        action.payload.p_application_header.p_join_guarantors_attributes;
      if (
        hasJoinGuarantorLink === CoOwnerType.CANBE &&
        pJoinGuarantorLink.length === 0
      ) {
        state.adminLinkPreliminaries.p_application_header.p_join_guarantors_attributes =
          [
            {
              id: '',
              last_name_kanji: '',
              first_name_kanji: '',
              last_name_kana: '',
              first_name_kana: '',
              sex: SexType.DEFAULT,
              guarantor_relationship_name: '',
              birthday: '',
              mobile_phone_number: '',
              home_phone_number: '',
              postal_code: '',
              prefecture_kanji: '',
              city_kanji: '',
              district_kanji: '',
              other_address_kanji: '',
              guarantor_relationship_to_applicant: '',
              other_relationship_to_applicant: '',
              emergency_contact_number: '',
              prefecture_kana: '',
              city_kana: '',
              district_kana: '',
              other_address_kana: '',
              owner_email: '',
              _destroy: false,
            },
          ];
      }

      const personalLinkFileUpload =
        action.payload.p_application_header.p_applicant_people_attributes[0]
          .personal_file_upload;
      Object.keys(personalLinkFileUpload).forEach((key) => {
        // @ts-ignore
        if (!personalLinkFileUpload[`${key}`]?.[0]?.url)
          // @ts-ignore
          personalLinkFileUpload[`${key}`] = [];
      });

      state.filesLink = personalLinkFileUpload;
      state.filesLinkHeader = {
        business_card_index:
          action.payload.p_application_header.business_card_index,
        manager_property_information_file_index:
          action.payload.p_application_header
            .manager_property_information_file_index,
        property_information_file_index:
          action.payload.p_application_header.property_information_file_index,
      };

      const dataFilesLinkBorrowing =
        action.payload.p_application_header.p_borrowings_attributes;
      dataFilesLinkBorrowing.forEach((data, index) => {
        if (index < 0 || index > 7) {
          state.filesLinkBorrowing.repayment_schedule_image_index__0 =
            data.repayment_schedule_image_index;
        } else {
          // @ts-ignore
          state.filesLinkBorrowing[`repayment_schedule_image_index__${index}`] =
            data.repayment_schedule_image_index;
        }
      });
      const { sale_person_information } = action.payload.p_application_header;
      const agenciesResponse: DropDownItems[] = [];
      const storesResponse: DropDownItems[] = [];
      const exhibitionsResponse: DropDownItems[] = [];
      const salePersonsResponse: DropDownItems[] = [];
      sale_person_information.list_agencies.forEach((agency) => {
        agenciesResponse.push({
          id: agency.id,
          value: agency.sale_agent_id.toString(),
          label: agency.sale_agent_name_kanji,
          active: sale_person_information.active_agency_id === agency.id,
        });
      });
      sale_person_information.list_stores.forEach((store) => {
        storesResponse.push({
          id: store.id,
          value: store.store_id.toString(),
          label: store.store_name_kanji,
          active: sale_person_information.active_store_id === store.id,
        });
      });
      sale_person_information.list_exhibitions.forEach((exhibition) => {
        exhibitionsResponse.push({
          id: exhibition.id,
          value: exhibition.exhibition_id.toString(),
          label: exhibition.exhibition_hall,
          active:
            sale_person_information.active_exhibition_id === exhibition.id,
        });
      });
      sale_person_information.list_sale_persons.forEach((sale_person) => {
        salePersonsResponse.push({
          value: sale_person.id.toString(),
          label: sale_person.value,
        });
      });

      state.agenciesOptionsLink = agenciesResponse;
      state.storesOptionsLink = storesResponse;
      state.exhibitionsOptionsLink = exhibitionsResponse;
      state.salePersonsOptionsLink = salePersonsResponse;

      state.adminLinkPreliminaries.p_application_header.sale_person_information.active_agency_id =
        sale_person_information.list_agencies.find(
          (item) => item.id === sale_person_information.active_agency_id
        )?.sale_agent_id;

      state.adminLinkPreliminaries.p_application_header.sale_person_information.active_store_id =
        sale_person_information.list_stores.find(
          (item) => item.id === sale_person_information.active_store_id
        )?.store_id;

      state.adminLinkPreliminaries.p_application_header.sale_person_information.active_exhibition_id =
        sale_person_information.list_exhibitions.find(
          (item) => item.id === sale_person_information.active_exhibition_id
        )?.exhibition_id;
    });
    builder.addCase(getLinkPersonalInformations.rejected, (state, action) => {
      handleShowError(action);
    });
    builder.addCase(getUploadedFilesManager.pending, (state, action) => {
      state.isLoadingFiles = true;
    });
    builder.addCase(editAdminPreliminary.fulfilled, (state, action) => {
      state.adminPreliminaries = action.payload;
      state.timeGetEdit = formatTimeForCheckAsync(new Date());
      state.sbiId =
        action.payload.p_application_header.p_application_banks_attributes.find(
          (bank) => bank.name === SBI_NAME
        )?.id;
      const loanTarget = action.payload.p_application_header.loan_target;
      if (loanTarget === PurposeType.DEFAULT) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.DEFAULT;
      }
      if (
        loanTarget !== PurposeType.SEVEN &&
        loanTarget !== PurposeType.EIGHT &&
        loanTarget !== PurposeType.DEFAULT
      ) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.ZERO;
      }
      if (loanTarget === PurposeType.SEVEN) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.SEVEN;
      }
      if (loanTarget === PurposeType.EIGHT) {
        state.adminPreliminaries.p_application_header.loan_target_zero =
          PurposeType.EIGHT;
      }

      state.adminPreliminaries.p_application_header.loan_target_default =
        PurposeType.DEFAULT;

      action.payload.p_application_header.p_applicant_people_attributes.forEach(
        (data, index) => {
          const dataFiles = data.personal_file_upload;
          Object.keys(dataFiles).forEach((key) => {
            // @ts-ignore
            if (!dataFiles[`${key}`]?.[0]?.url) dataFiles[`${key}`] = [];
          });

          if (index === 0) {
            state.files = dataFiles;
          }
          if (index === 1) {
            state.filesIncomeAdder = dataFiles;
          }
        }
      );

      state.filesHeader = {
        business_card_index:
          action.payload.p_application_header.business_card_index,
        manager_property_information_file_index:
          action.payload.p_application_header
            .manager_property_information_file_index,
        property_information_file_index:
          action.payload.p_application_header.property_information_file_index,
      };

      const dataFilesBorrowing =
        action.payload.p_application_header.p_borrowings_attributes;
      dataFilesBorrowing.forEach((data, index) => {
        if (index < 0 || index > 7) {
          state.filesBorrowing.repayment_schedule_image_index__0 =
            data.repayment_schedule_image_index;
        } else {
          // @ts-ignore
          state.filesBorrowing[`repayment_schedule_image_index__${index}`] =
            data.repayment_schedule_image_index;
        }
      });
    });
    builder.addCase(editAdminPreliminary.rejected, (state, action) => {
      handleShowError(action);
    });
    builder.addCase(getReferralAgencyOptions.fulfilled, (state, action) => {
      const agenciesResponse: DropDownItems[] = [];
      const storesResponse: DropDownItems[] = [];
      const exhibitionsResponse: DropDownItems[] = [];
      const salePersonsResponse: DropDownItems[] = [];
      action.payload.data.list_agencies.forEach(
        (agency: {
          id: string;
          sale_agent_id: string;
          value: string;
          active: boolean;
        }) => {
          agenciesResponse.push({
            id: agency.id,
            value: agency.sale_agent_id.toString(),
            label: agency.value,
            active: agency.active,
          });
        }
      );
      action.payload.data.list_stores.forEach(
        (store: {
          id: string;
          store_id: string;
          value: string;
          active: boolean;
        }) => {
          storesResponse.push({
            id: store.id,
            value: store.store_id.toString(),
            label: store.value,
            active: store.active,
          });
        }
      );
      action.payload.data.list_exhibitions.forEach(
        (exhibition: {
          id: string;
          exhibition_id: string;
          value: string;
          active: boolean;
        }) => {
          exhibitionsResponse.push({
            id: exhibition.id,
            value: exhibition.exhibition_id.toString(),
            label: exhibition.value,
            active: exhibition.active,
          });
        }
      );
      action.payload.data.list_sale_persons.forEach(
        (sale_person: { id: string; value: string }) => {
          salePersonsResponse.push({
            value: sale_person.id.toString(),
            label: sale_person.value,
          });
        }
      );
      state.agenciesOptions = agenciesResponse;
      state.storesOptions = storesResponse;
      state.exhibitionsOptions = exhibitionsResponse;
      state.salePersonsOptions = salePersonsResponse;
    });
    builder.addCase(getAdminExamination.fulfilled, (state, action) => {
      state.adminPreliminaries.p_application_header.examination_file.filename =
        action.payload.p_application_header.examination_file.filename;
    });
    builder.addCase(updateUploadedFilesManager.pending, (state) => {
      state.isDisableSaveButton = true;
    });
    builder.addCase(updateUploadedFilesManager.fulfilled, (state) => {
      state.isDisableSaveButton = false;
    });
    builder.addCase(updateUploadedFilesManager.rejected, (state) => {
      state.isDisableSaveButton = false;
    });
    builder.addCase(updateInterfaceErrorStatus.fulfilled, (state, action) => {
      state.adminPreliminaries.p_application_header.interface_error_status =
        action.payload.interface_error_status;
    });
  },
});

export const { clearAllFiles } = adminPreliminarySlice.actions;

export default adminPreliminarySlice.reducer;
