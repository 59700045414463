import { z } from 'zod';

export const salePerson = z.object({
  id: z.string(),
  name_kanji: z.string(),
  active: z.boolean(),
});

export const store = z.object({
  id: z.string(),
  store_name_kanji: z.string(),
  path: z.string(),
});

export const salePersonPreliminariesItemSchema = z.object({
  id: z.string(),
  application_number: z.string(),
  bank_name: z.string(),
  applicant_id: z.string(),
  applicant_name: z.string(),
  created_at: z.string(),
  loan_apply_date: z.string().optional(),
  loan_desired_borrowing_date: z.string(),
  temporary_desired_loan_amount: z.string(),
  status_result: z.string(),
  under_review_status: z.string(),
  provisional_result: z.string(),
  store_name_kanji: z.string(),
  sale_person_name_kanji: z.string(),
  exhibition_hall: z.string(),
  manager_name_kanji: z.string(),
  list_sale_people: z.array(salePerson),
  list_store: z.array(store),
  application_status_lists: z.array(
    z.object({
      key: z.string(),
      value: z.number(),
      active: z.boolean(),
      enable: z.boolean(),
    })
  ),
  p_referral_agency_path: z.string(),
  unread_messages: z.string().optional(),
  user_id: z.string(),
  linking: z.string(),
});

export const masterDatas = z.object({
  under_review_status_lists: z.array(z.string()),
  branch_names: z.array(z.string()),
  exhibition_hall_names: z.array(z.string()),
  office_names: z.array(z.string()),
  sale_person_names: z.array(z.string()),
});

export const salePersonCurrentPreliminariesItemResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(salePersonPreliminariesItemSchema),
  master_data: masterDatas,
  total: z.number(),
});

export const salePersonPreliminariesItemRequestSchema = z.object({
  preliminary_status: z.string().optional(),
  master_bank_codes: z.array(z.string()).optional(),
  applicant_name: z.string().optional(),
  apply_date_range: z
    .object({
      from: z.string(),
      to: z.string(),
    })
    .optional(),
  loan_amount_range: z
    .object({
      from: z.string(),
      to: z.string(),
    })
    .optional(),
  branch_names: z.array(z.string()).optional(),
  office_names: z.array(z.string()).optional(),
  exhibition_hall_names: z.array(z.string()).optional(),
  sale_person_names: z.array(z.string()).optional(),
  execution_date_range: z
    .object({
      from: z.string(),
      to: z.string(),
    })
    .optional(),
  order_key: z.string().optional(),
  order_sort: z.string().optional(),
  page: z.number().optional(),
});

export const assignSalePersonRequestSchema = z.object({
  id: z.string(),
  s_sale_person_id: z.string(),
});

export const assignSalePersonResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(salePersonPreliminariesItemSchema),
});

export const assignStoreNameRequestSchema = z.object({
  id: z.string(),
  p_referral_agency_id: z.string(),
});

export const assignStoreNameResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(salePersonPreliminariesItemSchema),
});

export type SalePersonPreliminariesItem = z.infer<
  typeof salePersonPreliminariesItemSchema
>;

export type SalePersonCurrentPreliminariesItemResponse = z.infer<
  typeof salePersonCurrentPreliminariesItemResponseSchema
>;

export type SalePersonPreliminariesItemRequest = z.infer<
  typeof salePersonPreliminariesItemRequestSchema
>;

export type AssignSalePersonRequest = z.infer<
  typeof assignSalePersonRequestSchema
>;

export type AssignSalePersonResponse = z.infer<
  typeof assignSalePersonResponseSchema
>;

export type AssignStoreNameRequest = z.infer<
  typeof assignStoreNameRequestSchema
>;

export type AssignStoreNameResponse = z.infer<
  typeof assignStoreNameResponseSchema
>;

export type AssignSalePersonError = {};

export type AssignStoreNameError = {};

export type MasterDatas = z.infer<typeof masterDatas>;
