import {
  AdminPreliminaryItem,
  borrowingStatusAdmin,
  BorrowingStatusAdminType,
  BusinessBorrowingType,
  CODEBANK,
  CURRENT_BORROWING,
  FundsPairOffType,
  HEAD_CURRENT_BORROWING,
  LoanPurposeType,
  LoanType,
  SPBorrowingType,
  SPScheduledLoanPayoffType,
  SUB_CURRENT_BORROWING,
} from 'constant';
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';

export const buildCurrentBorrowing = (
  managerRole: string,
  values:
    | AdminPreliminaries['p_application_header']
    | SalePersonPreliminaries['p_application_header'],
  adminPreliminaryItems = CURRENT_BORROWING,
  subAdminPreliminaryItems = SUB_CURRENT_BORROWING
): AdminPreliminaryItem[][] => {
  const isMCJ = values.name_master_banks?.includes(CODEBANK.MCJ);
  if (!values.p_borrowings_attributes?.length) {
    return [
      HEAD_CURRENT_BORROWING.map((row) => {
        if (row.name_en === 'borrowing_status_local') {
          return {
            ...row,
            defaultValue: borrowingStatusAdmin[BorrowingStatusAdminType.NO],
          };
        }
        return row;
      }),
    ];
  }
  const showSubArray =
    isMCJ &&
    values.p_borrowings_attributes.some(
      ({ scheduled_loan_payoff }) =>
        scheduled_loan_payoff === SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
    );

  const subArray = (showSubArray ? subAdminPreliminaryItems : []).filter(
    ({ name_en }) => {
      if (name_en === 'other_complete_repayment_type') {
        return values.completely_repayment_type === FundsPairOffType.OTHERS;
      }
      return true;
    }
  );

  return [
    HEAD_CURRENT_BORROWING,
    ...makeArray(values.p_borrowings_attributes.length).map((_, index) => {
      return adminPreliminaryItems
        .filter(({ name_en }) => {
          if (name_en === 'borrower') {
            return [LoanType.THREE, LoanType.FOUR].includes(values.loan_type);
          }
          if (name_en === 'borrowing_from_housing_finance_agency') {
            return [SPBorrowingType.HOUSING_LOAN].includes(
              values.p_borrowings_attributes[index]?.borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'loan_purpose') {
            return [SPBorrowingType.CARD_LOAN].includes(
              values.p_borrowings_attributes[index]?.borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'business_borrowing_type') {
            return [SPBorrowingType.BUSINESS_LOAN].includes(
              values.p_borrowings_attributes[index]?.borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'other_purpose') {
            return [LoanPurposeType.OTHERS].includes(
              values.p_borrowings_attributes[index]?.loan_purpose ??
                LoanPurposeType.DEFAULT
            );
          }
          if (name_en === 'specific_loan_purpose') {
            return [BusinessBorrowingType.OTHERS].includes(
              values.p_borrowings_attributes[index].business_borrowing_type ??
                BusinessBorrowingType.DEFAULT
            );
          }
          if (name_en === 'borrowing_category') {
            return [SPBorrowingType.CARD_LOAN].includes(
              values.p_borrowings_attributes[index].borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'card_expiry_date') {
            return [SPBorrowingType.CARD_LOAN].includes(
              values.p_borrowings_attributes[index].borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'rental_room_number') {
            return [SPBorrowingType.APARTMENT_LOAN].includes(
              values.p_borrowings_attributes[index].borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'common_housing') {
            return [SPBorrowingType.APARTMENT_LOAN].includes(
              values.p_borrowings_attributes[index].borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'scheduled_loan_payoff') {
            return ![SPBorrowingType.BUSINESS_LOAN].includes(
              values.p_borrowings_attributes[index].borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'loan_deadline_date') {
            return ![SPBorrowingType.CARD_LOAN].includes(
              values.p_borrowings_attributes[index].borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          if (name_en === 'expected_repayment_date') {
            return (
              ![SPBorrowingType.BUSINESS_LOAN].includes(
                values.p_borrowings_attributes[index].borrowing_type ??
                  SPBorrowingType.DEFAULT
              ) &&
              values.p_borrowings_attributes[index].scheduled_loan_payoff ===
                SPScheduledLoanPayoffType.SCHEDULED_TO_REPAY
            );
          }
          if (name_en === 'estate_mortgage') {
            return [
              SPBorrowingType.APARTMENT_LOAN,
              SPBorrowingType.BUSINESS_LOAN,
              SPBorrowingType.CAR_LOAN,
              SPBorrowingType.EDUCATION_LOAN,
              SPBorrowingType.LOAN_FOR_LIVING_EXPENSES,
              SPBorrowingType.OTHERS,
            ].includes(
              values.p_borrowings_attributes[index].borrowing_type ??
                SPBorrowingType.DEFAULT
            );
          }
          return true;
        })
        .map((row) => {
          if (row.name_en === 'borrowing_status') {
            return {
              ...row,
              name_en: `p_borrowings_attributes[${index}].${row.name_en}`,
              defaultValue: borrowingStatusAdmin[BorrowingStatusAdminType.YES],
            };
          }
          if (row.name_en === 'borrower') {
            return {
              ...row,
              name_en: `p_borrowings_attributes[${index}].${row.name_en}`,
              required: false,
              //[HOMELOAN-2639]
              // [LoanType.THREE, LoanType.FOUR].includes(values.loan_type) &&
              // managerRole === MANAGER_ROLE.ADMIN,
            };
          }

          if (row.name_en === 'scheduled_loan_payoff') {
            return {
              ...row,
              name_en: `p_borrowings_attributes[${index}].${row.name_en}`,
              dynamicOptionHidden(optionIndex: number) {
                const borrowingType =
                  values.p_borrowings_attributes[index]?.borrowing_type;
                return (
                  optionIndex === 2 &&
                  (values.is_home_loan_plus !== true ||
                    (values.is_home_loan_plus === true &&
                      borrowingType !== SPBorrowingType.CARD_LOAN &&
                      borrowingType !== SPBorrowingType.CAR_LOAN &&
                      borrowingType !== SPBorrowingType.EDUCATION_LOAN &&
                      borrowingType !==
                        SPBorrowingType.LOAN_FOR_LIVING_EXPENSES &&
                      borrowingType !== SPBorrowingType.OTHERS))
                );
              },
            };
          }
          if (row.name_en === 'common_housing') {
            return {
              ...row,
              name_en: `p_borrowings_attributes[${index}].${row.name_en}`,
              // required: [SPBorrowingType.APARTMENT_LOAN].includes(
              //   values.p_borrowings_attributes[index].borrowing_type ??
              //     SPBorrowingType.DEFAULT
              // ),
            };
          }
          if (row.name_en === 'loan_amount') {
            return {
              ...row,
              name_en: `p_borrowings_attributes[${index}].${row.name_en}`,
              name_ja:
                values.p_borrowings_attributes[index].borrowing_type ===
                SPBorrowingType.CARD_LOAN
                  ? '借入限度額'
                  : '当初借入額',
            };
          }
          if (row.name_en === 'loan_start_date') {
            return {
              ...row,
              name_en: `p_borrowings_attributes[${index}].${row.name_en}`,
              name_ja:
                values.p_borrowings_attributes[index].borrowing_type ===
                SPBorrowingType.CARD_LOAN
                  ? '当初カード契約年月'
                  : '当初借入年月',
            };
          }
          return {
            ...row,
            name_en: `p_borrowings_attributes[${index}].${row.name_en}`,
          };
        });
    }),
    subArray,
  ].filter((arr) => !!arr.length);
};

function makeArray(index: number): number[] {
  return Array.from(Array(index).keys());
}
