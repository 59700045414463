import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applicationsService,
  CreateInquiryError,
  CreateInquiryRequest,
  CreateInquiryResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const createInquiry = createAsyncThunk<
  CreateInquiryResponse,
  CreateInquiryRequest,
  ThunkAPI<CreateInquiryError>
>('ContactInformations/getContactDetail', async (data, { rejectWithValue }) => {
  try {
    return await applicationsService.createInquiry(data);
  } catch (error) {
    return rejectWithValue(error);
  }
});
