import { FC, useMemo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPCalculator: FC<SvgIconProps> = (props) => {
  const id = useMemo<string>(
    () => Math.round(Math.random() * 10000000).toString(),
    []
  );
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.5C0 0.671875 0.671875 0 1.5 0H5.5C6.32812 0 7 0.671875 7 1.5V5.5C7 6.32812 6.32812 7 5.5 7H1.5C0.671875 7 0 6.32812 0 5.5V1.5ZM9 1.5C9 0.671875 9.67188 0 10.5 0H14.5C15.3281 0 16 0.671875 16 1.5V5.5C16 6.32812 15.3281 7 14.5 7H10.5C9.67188 7 9 6.32812 9 5.5V1.5ZM10.5 9H14.5C15.3281 9 16 9.67188 16 10.5V14.5C16 15.3281 15.3281 16 14.5 16H10.5C9.67188 16 9 15.3281 9 14.5V10.5C9 9.67188 9.67188 9 10.5 9ZM0 10.5C0 9.67188 0.671875 9 1.5 9H5.5C6.32812 9 7 9.67188 7 10.5V14.5C7 15.3281 6.32812 16 5.5 16H1.5C0.671875 16 0 15.3281 0 14.5V10.5ZM13 2C13 1.725 12.775 1.5 12.5 1.5C12.225 1.5 12 1.725 12 2V3H11C10.725 3 10.5 3.225 10.5 3.5C10.5 3.775 10.725 4 11 4H12V5C12 5.275 12.225 5.5 12.5 5.5C12.775 5.5 13 5.275 13 5V4H14C14.275 4 14.5 3.775 14.5 3.5C14.5 3.225 14.275 3 14 3H13V2ZM2 3C1.725 3 1.5 3.225 1.5 3.5C1.5 3.775 1.725 4 2 4H5C5.275 4 5.5 3.775 5.5 3.5C5.5 3.225 5.275 3 5 3H2ZM2.60313 10.8969C2.40938 10.7031 2.09063 10.7031 1.89688 10.8969C1.70312 11.0906 1.70312 11.4094 1.89688 11.6031L2.79375 12.5L1.89688 13.3969C1.70312 13.5906 1.70312 13.9094 1.89688 14.1031C2.09063 14.2969 2.40938 14.2969 2.60313 14.1031L3.5 13.2063L4.39687 14.1031C4.59062 14.2969 4.90938 14.2969 5.10313 14.1031C5.29688 13.9094 5.29688 13.5906 5.10313 13.3969L4.20625 12.5L5.10313 11.6031C5.29688 11.4094 5.29688 11.0906 5.10313 10.8969C4.90938 10.7031 4.59062 10.7031 4.39687 10.8969L3.5 11.7937L2.60313 10.8969ZM11 11C10.725 11 10.5 11.225 10.5 11.5C10.5 11.775 10.725 12 11 12H14C14.275 12 14.5 11.775 14.5 11.5C14.5 11.225 14.275 11 14 11H11ZM11 13C10.725 13 10.5 13.225 10.5 13.5C10.5 13.775 10.725 14 11 14H14C14.275 14 14.5 13.775 14.5 13.5C14.5 13.225 14.275 13 14 13H11Z"
          fill={`url(#${id})`}
        />
        <defs>
          <linearGradient
            id={id}
            x1="2.46388"
            y1="12.7094"
            x2="14.6897"
            y2="0.360209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
