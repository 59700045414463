import { Stack } from '@mui/material';
import { FC } from 'react';
import { SalePersonPreliminariesItem } from 'services';

type ListStatusChartProps = {
  data: SalePersonPreliminariesItem;
};

export const ListStatusChart: FC<ListStatusChartProps> = ({ data }) => {
  return (
    <Stack direction="row" justifyContent="center" sx={{ mt: '9px' }}>
      <Stack flex={1} />
      <Stack flex={1} />
      <Stack
        direction="row"
        flex={1.5}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        {Array.from({ length: 5 }, (_, i) => i).map((index) => {
          return (
            <Stack
              key={index}
              sx={{
                width: 65,
                height: 6,
                bgcolor:
                  Number(data.under_review_status) === index + 2 &&
                  data.status_result === '4'
                    ? 'sp_primary_100_main'
                    : 'gray_09',
              }}
            />
          );
        })}
      </Stack>
      <Stack flex={1} />
    </Stack>
  );
};
