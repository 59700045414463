import {
  LinkProps as RouterLinkProps,
  Link as RouterLink,
} from 'react-router-dom';
import { forwardRef } from 'react';

export const Link = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>(({ href, ...props }, ref) => {
  return <RouterLink ref={ref} to={href} {...props} />;
});
