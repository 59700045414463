import { FC, useMemo, useState } from 'react';
import { Stack, SxProps, Typography, GlobalStyles } from '@mui/material';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { date, defaultDate, zeroPad } from 'utils';
import { usePublicHoliday } from 'hooks';
import { SPInputSelect, SPInputSelectProps } from '../SPInputSelect';
import { validationSchema } from './validationSchema';

export type SPInputDateTimeProps = Pick<
  SPInputSelectProps,
  'disabled' | 'readOnly'
> & {
  name: string;
  yearOptions: SPInputSelectProps['options'];
  checkRequired?: boolean;
  selectSx?: SxProps;
  unitSx?: SxProps;
  errorMessage?: string;
};

export const SPInputDateTime: FC<SPInputDateTimeProps> = ({
  disabled,
  readOnly,
  yearOptions,
  checkRequired,
  selectSx,
  unitSx,
  errorMessage,
  ...props
}) => {
  const [field, meta, form] = useField(props.name);
  const [year, setYear] = useState<string>('');
  const { setFieldValue } = useFormikContext();
  const initialValues = useMemo(() => {
    const [year = '', month = '', day = ''] = field.value
      ? field.value.split('/')
      : ['', '', ''];
    return { year, month, day };
  }, [field.value]);

  const publicHolidays = usePublicHoliday(+year || +initialValues.year);
  const windowWidth = window.innerWidth;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      if (!values.day && !values.month && !values.year) {
        return setFieldValue(field.name, '');
      }
      setFieldValue(field.name, `${values.year}/${values.month}/${values.day}`);
    },
  });

  const dayOptions = useMemo(() => {
    return [
      { value: '', label: '日', className: undefined },
      ...date(formik.values.year, formik.values.month),
    ].map((d) => {
      if (
        publicHolidays.find(
          (p) =>
            p.date === `${formik.values.year}-${formik.values.month}-${d.value}`
        )
      ) {
        return {
          ...d,
          className: `${d.className ?? ''} is-public-holiday`,
        };
      }
      return d;
    });
  }, [publicHolidays, formik.values.year, formik.values.month]);

  return (
    <FormikProvider value={formik}>
      <GlobalStyles
        styles={{
          '.day-of-week-0, .day-of-week-6': {
            backgroundColor: '#E7E7E7',
          },
          '.is-public-holiday, .is-public-holiday:hover': {
            backgroundColor: field.name.includes('loan_desired_borrowing_date')
              ? '#E7E7E7'
              : '#FF8F8F',
          },
        }}
      />
      <Stack direction="row" alignItems="flex-start" {...props}>
        <input name={field.name} type="hidden" />
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          sx={{
            mr: '5px',
            maxWidth: 182,
            '@media (max-width:385px)': {
              maxWidth: 117,
            },
          }}
        >
          <SPInputSelect
            sx={{
              maxWidth: 163,
              '@media (max-width:385px)': {
                maxWidth: 98,
              },
              height: 48,
              textAlign: 'center',
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '12px',
              },
              ...selectSx,
            }}
            iconSx={{ right: 0 }}
            name="year"
            placeholder="----"
            disabled={disabled}
            readOnly={readOnly}
            options={yearOptions}
            error={!!meta.error && meta.touched}
            errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
            onChange={(event) => {
              setYear(event.target.value as string);
              formik.handleSubmit();
            }}
            onClose={async () => {
              if (!meta.error) {
                await form.setTouched(false);
              } else {
                form.setTouched(!!formik.values.day);
              }
              await formik.handleSubmit();
            }}
            unit="年"
            unitSx={unitSx}
            autoWidth
            showError={false}
            useFastField={false}
            checkRequired={checkRequired}
            renderValue={
              !!formik.values.year
                ? () => {
                    const selectedOption = yearOptions.find(
                      (e) => e.value === formik.values.year
                    );
                    return (
                      <Typography
                        variant="sp_unit_select_text"
                        color="normal_text"
                      >
                        {windowWidth > 385 && !!selectedOption
                          ? selectedOption.label
                          : formik.values.year}
                      </Typography>
                    );
                  }
                : () => (
                    <Typography variant="placeHolder_style" color="off_text">
                      ----
                    </Typography>
                  )
            }
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          sx={{ mr: '5px' }}
        >
          <SPInputSelect
            sx={{
              width: 52,
              height: 48,
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                pt: '12px',
              },
              ...selectSx,
            }}
            iconSx={{ right: 0 }}
            name="month"
            placeholder="--"
            disabled={disabled}
            readOnly={readOnly}
            options={monthOpts}
            error={!!meta.error && meta.touched}
            errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
            onChange={() => formik.handleSubmit()}
            onClose={async () => {
              if (!meta.error) {
                await form.setTouched(false);
              } else {
                form.setTouched(!!formik.values.day);
              }
              await formik.handleSubmit();
            }}
            unit="月"
            unitSx={unitSx}
            autoWidth
            showError={false}
            useFastField={false}
            checkRequired={checkRequired}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          sx={{ mr: '5px' }}
        >
          <SPInputSelect
            sx={{
              width: 52,
              height: 48,
              '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                pt: '12px',
              },
              ...selectSx,
            }}
            iconSx={{ right: 0 }}
            name="day"
            placeholder="--"
            disabled={disabled}
            readOnly={readOnly}
            options={
              field.name.includes('loan_desired_borrowing_date')
                ? dayOptions
                : [{ value: '', label: '日' }].concat(
                    defaultDate(formik.values.year, formik.values.month)
                  )
            }
            error={!!meta.error && meta.touched}
            errorMessage={!!meta.error && meta.touched ? meta.error : undefined}
            onChange={() => formik.handleSubmit()}
            onClose={async () => {
              await form.setTouched(true);
              await formik.handleSubmit();
            }}
            unit="日"
            unitSx={unitSx}
            useFastField={false}
            autoWidth
            showError={false}
            checkRequired={checkRequired}
          />
        </Stack>
      </Stack>
      {!!meta.error && meta.touched && (
        <Typography
          variant="text_error"
          color="sp_secondary_01"
          whiteSpace="break-spaces"
          sx={{ mt: 2 }}
        >
          ※{meta.error ?? errorMessage ?? ''}
        </Typography>
      )}
    </FormikProvider>
  );
};

const monthOpts: SPInputSelectProps['options'] = [
  { value: '', label: '月' },
].concat(
  Array.from(Array(12), (_, index) => ({
    value: zeroPad(index + 1),
    label: (index + 1).toString(),
  }))
);
