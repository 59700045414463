import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPRadioUnchecked: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 26 26" {...props}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_i_7866_11878)">
          <rect x="1" y="1" width="24" height="24" rx="12" fill="white" />
        </g>
        <rect x="1" y="1" width="24" height="24" rx="12" stroke="#E4E7FF" />
        <defs>
          <filter
            id="filter0_i_7866_11878"
            x="0.5"
            y="0.5"
            width="25"
            height="29"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_7866_11878"
            />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};
