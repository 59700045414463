import { FC, useState, useCallback, Fragment, useMemo } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
} from '@mui/material';
import { AdminEditModal, Button, ModalConfirm } from 'components';
import { TableEditProps } from 'types';
import { AdminPreliminaries, logEditedItem } from 'services';
import { useAppDispatch } from 'hooks';
import { getLogEditedPreliminary } from 'pages/AdminEditPreliminary/thunk';
import { FieldArray, useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import lodash from 'lodash';
import {
  AdminPreliminaryItem,
  BorrowingFromHousingFinanceAgencyType,
  CommonHousingType,
  EstateMortgageType,
  SPBorrowingType,
  BorrowerType,
  LoanPurposeType,
  BorrowingCategoryType,
  OBJECTS_TABLE,
  TRACKABLE_TYPE,
  CODEBANK,
  SPScheduledLoanPayoffType,
  BusinessBorrowingType,
} from 'constant';
import { Add } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { getLogEditedSalePersonPreliminary } from 'pages/SalePersonEditPreliminary/thunk';
import {
  StyledTableCell,
  TableBody,
  TableBodyAdd,
  TableBodyEmpty,
} from './TableBody';

type TrackableLog = {
  trackableType: string;
  trackableId: string;
};

export const AdminPreliminaryTable: FC<
  TableEditProps<AdminPreliminaryItem>
> = ({
  rows,
  appraisalId,
  parentTabIndex,
  parentKey,
  quantityDependent,
  isArray,
  editable,
  noRequired,
  noAdd,
  isLinked,
  isBorrowing,
  yearLength,
  isTotalIncome,
  indexJoinGuarantor,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState<{ state: boolean; index: number }>({
    state: false,
    index: 0,
  });
  const dispatch = useAppDispatch();
  const [logEditedInformation, setLogEditedInformation] =
    useState<logEditedItem[]>();
  const [fieldNameEn, setFieldNameEn] = useState<string>();

  const location = useLocation();
  const formik = useFormikContext<AdminPreliminaries>();

  const handleChange = useCallback(
    (value: unknown, path: string) => {
      formik.setFieldValue(path, value);
    },
    [formik]
  );

  const isMCJ = useMemo(
    () =>
      formik.values.p_application_header.name_master_banks?.includes(
        CODEBANK.MCJ
      ),
    [formik.values.p_application_header.name_master_banks]
  );

  const getTrackableLog = useCallback(
    (nameEn: string) => {
      const trackableLog: TrackableLog = {
        trackableId: formik.values.p_application_header.id,
        trackableType: TRACKABLE_TYPE.p_application_header,
      };

      if (nameEn.includes(OBJECTS_TABLE.p_applicant_people_attributes)) {
        trackableLog.trackableType =
          TRACKABLE_TYPE.p_applicant_people_attributes;
        const index = nameEn.split('.')[0].slice(-2, -1);
        trackableLog.trackableId =
          formik.values.p_application_header.p_applicant_people_attributes[
            Number(index)
          ]?.id;
      } else if (
        nameEn.includes(OBJECTS_TABLE.p_borrowing_details_attributes)
      ) {
        trackableLog.trackableType =
          TRACKABLE_TYPE.p_borrowing_details_attributes;
        const index = nameEn.split('.')[0].slice(-2, -1);
        trackableLog.trackableId =
          formik.values.p_application_header.p_borrowing_details_attributes[
            Number(index)
          ]?.id;
      } else if (nameEn.includes(OBJECTS_TABLE.p_borrowings_attributes)) {
        trackableLog.trackableType = TRACKABLE_TYPE.p_borrowings_attributes;
        const index = nameEn.split('.')[0].slice(-2, -1);
        trackableLog.trackableId =
          formik.values.p_application_header.p_borrowings_attributes[
            Number(index)
          ]?.id;
      } else if (nameEn.includes(OBJECTS_TABLE.p_join_guarantors_attributes)) {
        trackableLog.trackableType =
          TRACKABLE_TYPE.p_join_guarantors_attributes;
        const index = nameEn.split('.')[0].slice(-2, -1);
        trackableLog.trackableId =
          formik.values.p_application_header.p_join_guarantors_attributes[
            Number(index)
          ]?.id;
      } else if (nameEn.includes(OBJECTS_TABLE.p_residents_attributes)) {
        trackableLog.trackableType = TRACKABLE_TYPE.p_residents_attributes;
        const index = nameEn.split('.')[0].slice(-2, -1);
        const residentsId =
          formik.values.p_application_header.p_residents_attributes[
            Number(index)
          ]?.id;
        trackableLog.trackableId = residentsId ? residentsId : '';
      }

      return trackableLog;
    },
    [
      formik.values.p_application_header.id,
      formik.values.p_application_header.p_applicant_people_attributes,
      formik.values.p_application_header.p_borrowing_details_attributes,
      formik.values.p_application_header.p_borrowings_attributes,
      formik.values.p_application_header.p_join_guarantors_attributes,
      formik.values.p_application_header.p_residents_attributes,
    ]
  );

  const handleOpenLogEdited = useCallback(
    async (item: string, nameEn: string) => {
      setFieldNameEn(nameEn);
      const trackableLog = getTrackableLog(nameEn);

      if (location.pathname.startsWith('/manager/', 0)) {
        const result = await dispatch(
          getLogEditedPreliminary({
            id: appraisalId,
            trackable_type: trackableLog.trackableType,
            trackable_id: trackableLog.trackableId,
            column: item,
          })
        );
        if (getLogEditedPreliminary.fulfilled.match(result)) {
          setLogEditedInformation(result.payload.data.value);
        }
      } else if (location.pathname.startsWith('/sale-person/', 0)) {
        const result = await dispatch(
          getLogEditedSalePersonPreliminary({
            id: appraisalId,
            trackable_type: trackableLog.trackableType,
            trackable_id: trackableLog.trackableId,
            column: item,
          })
        );
        if (getLogEditedSalePersonPreliminary.fulfilled.match(result)) {
          setLogEditedInformation(result.payload.data.value);
        }
      }
    },
    [appraisalId, dispatch, getTrackableLog, location.pathname]
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: 'none',
        maxHeight: 'calc(100vh - 360px)',
      }}
    >
      <Table stickyHeader sx={{ borderRadius: 0, border: 'none' }}>
        <TableHead
          sx={{
            '& .MuiTableCell-head': {
              padding: '5px',
              zIndex: 3,
              borderBottom: (theme) => `1px solid ${theme.palette.b_333}`,
            },
          }}
        >
          <TableRow>
            <StyledTableCell sx={{ width: 32 }} align="center" />
            <StyledTableCell align="center" sx={{ minWidth: 328 }}>
              入力項目
            </StyledTableCell>
            <StyledTableCell sx={{ width: 42 }} align="center" />
            <StyledTableCell align="center" sx={{ width: '100%' }}>
              入力内容
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {isArray && (
          <FieldArray
            name={`${parentKey}.p_borrowings_attributes`}
            render={(arrayHelpers) => {
              const checkTableRow = () => {
                if (
                  isMCJ &&
                  formik.values.p_application_header.p_borrowings_attributes
                    .length > 0 &&
                  formik.values.p_application_header.p_borrowings_attributes[0]
                    .scheduled_loan_payoff !==
                    SPScheduledLoanPayoffType.NOT_SCHEDULED_TO_REPAY
                )
                  return rows.length <= 9;
                return rows.length <= 8;
              };

              return (
                <>
                  {rows.map((row: unknown, index: number) => {
                    const handleRemoveBody =
                      (lodash.isArray(row) ? row : []).some(
                        ({ name_en }: { name_en: string }) =>
                          [
                            'head_current_borrowing',
                            'sub_current_borrowing',
                          ].includes(name_en)
                      ) || !isBorrowing
                        ? undefined
                        : () => setOpen({ state: true, index: index });
                    return (
                      <Fragment key={index}>
                        <TableBody
                          isTotalIncome={isTotalIncome}
                          noRequired={noRequired}
                          onChange={(value, path) =>
                            handleChange(value, `${parentKey}.${path}`)
                          }
                          quantityDependent={quantityDependent}
                          parentKey={parentKey}
                          //@ts-ignore
                          rows={row}
                          onEditField={(fieldName, nameEn) => {
                            handleOpenLogEdited(fieldName, nameEn);
                            setOpenModal(true);
                          }}
                          editable={editable}
                          onRemoveBody={handleRemoveBody}
                          yearLength={yearLength}
                        />
                        {rows.length - 1 > index && <TableBodyEmpty />}
                        <ModalConfirm
                          open={open.state && open.index === index}
                          confirmText="対象データを削除しますが、よろしいでしょうか。"
                          onClose={() => setOpen({ state: false, index: 0 })}
                          onConfirm={() => {
                            arrayHelpers.remove(index - 1);
                            setOpen({ state: false, index: 0 });
                          }}
                        />
                      </Fragment>
                    );
                  })}
                  {editable && !noAdd && checkTableRow() && (
                    <>
                      <TableBodyEmpty />
                      <TableBodyAdd>
                        <Button
                          sx={{
                            mt: '30px',
                            bgcolor: 'main_white',
                            width: 100,
                            height: 25,
                            minHeight: 25,
                            borderRadius: 1,
                            border: (theme) =>
                              `1px solid ${theme?.palette?.h_blugreen}`,
                          }}
                          startIcon={
                            <Add
                              sx={{
                                color: 'h_blugreen',
                                width: 18,
                                height: 18,
                              }}
                            />
                          }
                          startIconStyle={{ left: 12, bottom: 3 }}
                          onClick={() => {
                            arrayHelpers.push(
                              cloneDeep(borrowingsAttributeDefaultValue)
                            );
                          }}
                        >
                          <Typography
                            variant="dashboard_text"
                            color="h_blugreen"
                            sx={{ pl: 2 }}
                          >
                            新規作成
                          </Typography>
                        </Button>
                      </TableBodyAdd>
                    </>
                  )}
                </>
              );
            }}
          />
        )}
        {!isArray && (
          <TableBody
            isLinked={isLinked ? isLinked : false}
            parentTabIndex={parentTabIndex}
            noRequired={noRequired}
            onChange={(value, path) =>
              handleChange(value, `${parentKey}.${path}`)
            }
            quantityDependent={quantityDependent}
            parentKey={parentKey}
            rows={rows as AdminPreliminaryItem[]}
            onEditField={(fieldName, nameEn) => {
              handleOpenLogEdited(fieldName, nameEn);
              setOpenModal(true);
            }}
            editable={editable}
            yearLength={yearLength}
            isTotalIncome={isTotalIncome}
            indexJoinGuarantor={indexJoinGuarantor}
          />
        )}
        {fieldNameEn && logEditedInformation && (
          <AdminEditModal
            fieldNameEn={fieldNameEn}
            open={openModal}
            rows={rows as AdminPreliminaryItem[]}
            onClose={() => {
              setOpenModal((prevState) => !prevState);
              setLogEditedInformation([]);
            }}
            tableRows={logEditedInformation}
            quantityDependent={quantityDependent}
          />
        )}
      </Table>
    </TableContainer>
  );
};

export const borrowingsAttributeDefaultValue: Omit<
  AdminPreliminaries['p_application_header']['p_borrowings_attributes'][number],
  'id'
> = {
  borrowing_type: SPBorrowingType.DEFAULT,
  borrower: BorrowerType.DEFAULT,
  lender: '',
  borrowing_from_housing_finance_agency:
    BorrowingFromHousingFinanceAgencyType.DEFAULT,
  loan_purpose: LoanPurposeType.DEFAULT,
  business_borrowing_type: BusinessBorrowingType.DEFAULT,
  other_purpose: '',
  specific_loan_purpose: '',
  borrowing_category: BorrowingCategoryType.DEFAULT,
  loan_start_date: '',
  loan_amount: '',
  current_balance_amount: '',
  annual_repayment_amount: '',
  card_expiry_date: '',
  scheduled_loan_payoff: SPScheduledLoanPayoffType.DEFAULT,
  loan_deadline_date: '',
  expected_repayment_date: '',
  rental_room_number: '',
  common_housing: CommonHousingType.DEFAULT,
  estate_mortgage: EstateMortgageType.DEFAULT,
  include_in_examination: '',
  has_log_fields: [],
  repayment_schedule_image_index: [],
  _destroy: false,
};
