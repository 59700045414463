import { yup } from 'libs';

export const validationSchema2 = yup.object().shape(
  {
    year: yup.string().when(['month', 'day'], {
      is: (month: string, day: string) => !!month || !!day,
      then: yup.string().required(),
    }),
    month: yup.string().when(['year', 'day'], {
      is: (year: string, day: string) => !!year || !!day,
      then: yup.string().required(),
    }),
    day: yup.string().when(['year', ' month'], {
      is: (year: string, month: string) => !!year || !!month,
      then: yup.string().required(),
    }),
  },
  [
    ['year', 'month'],
    ['year', 'day'],
    ['month', 'day'],
  ]
);
