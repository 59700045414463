import { Stack, Typography } from '@mui/material';
import { Button, Icons, ManagerPreliminariesTable } from 'components';
import {
  FILTER_MODAL_TYPE,
  PreliminariesStatus,
  PreliminaryStatus,
  ROWS_PER_PAGE,
} from 'constant';
import { AdminLayout, FilterCurrentModal } from 'containers';
import { useAppDispatch } from 'hooks';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  AssignSalePersonRequest,
  AssignStoreNameRequest,
  ManagerCurrentPreliminariesItemRequest,
  ManagerPreliminariesItem,
} from 'services';
import { HeadCellProps, OrderType } from 'types';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import {
  activeTabSelector,
  currentPreliminariesSelectors,
  totalPreliminarySelectors,
} from './selectors';
import { revertPreliminary, setActiveTab } from './slice';
import {
  assignManager,
  getCurrentPreliminariesItems,
  getExportExcel,
  linkPairLoan,
  sortCurrentPreliminariesItems,
  unlinkPairLoan,
  updateApplicationStatus,
  assignSalePersons,
  assignStoreName,
} from './thunk';

const initialValuesFilter: ManagerCurrentPreliminariesItemRequest = {
  preliminary_status: '',
  master_bank_codes: [],
  applicant_name: '',
  provisional_results: [],
  apply_date_range: {
    from: '',
    to: '',
  },
  loan_amount_range: {
    from: '',
    to: '',
  },
  branch_names: [],
  office_names: [],
  exhibition_hall_names: [],
  sale_person_names: [],
};

const ManagerPreliminariesPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const currentPreliminary = useSelector(currentPreliminariesSelectors);
  const totalPreliminary = useSelector(totalPreliminarySelectors);
  const activeTab = useSelector(activeTabSelector);
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: 1,
    fetching: false,
  });
  const [order, setOrder] = useState<OrderType>('');
  const [orderBy, setOrderBy] = useState<keyof ManagerPreliminariesItem>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [dataFilter, setDataFilter] =
    useState<ManagerCurrentPreliminariesItemRequest>(initialValuesFilter);

  const preliminaryStatus = useMemo(() => {
    if (activeTab === PreliminariesStatus.INPROGRESS) {
      return PreliminaryStatus.PROGRESS_PRELIMINARY;
    } else if (activeTab === PreliminariesStatus.PAST) {
      return PreliminaryStatus.PAST_PRELIMINARY;
    }
    return PreliminaryStatus.PROVISIONAL_PRELIMINARY;
  }, [activeTab]);

  const handleExportExcel = useCallback(() => {
    dispatch(
      getExportExcel({
        preliminary_status:
          preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
      })
    );
  }, [dispatch, preliminaryStatus]);

  useLayoutEffect(() => {
    (async () => {
      dispatch(revertPreliminary());
      setLoading(true);
      await dispatch(
        getCurrentPreliminariesItems({
          preliminary_status:
            preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
        })
      );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
      maxPage: Math.ceil(totalPreliminary / ROWS_PER_PAGE),
    }));
  }, [totalPreliminary]);

  const handleGetCurrentPreliminaries = async () => {
    dispatch(revertPreliminary());
    setLoading(true);
    await dispatch(setActiveTab(PreliminariesStatus.CURRENT));
    setDataFilter({});
    setOrderBy(undefined);
    setOrder('');
    await dispatch(
      getCurrentPreliminariesItems({
        preliminary_status: PreliminaryStatus.PROVISIONAL_PRELIMINARY,
      })
    );
    setLoading(false);
  };
  const handleGetInProgressPreliminaries = async () => {
    dispatch(revertPreliminary());
    setLoading(true);
    await dispatch(setActiveTab(PreliminariesStatus.INPROGRESS));
    setDataFilter({});
    setOrderBy(undefined);
    setOrder('');
    await dispatch(
      getCurrentPreliminariesItems({
        preliminary_status: PreliminaryStatus.PROGRESS_PRELIMINARY,
      })
    );
    setLoading(false);
  };

  const handleGetPastPreliminaries = async () => {
    dispatch(revertPreliminary());
    setLoading(true);
    await dispatch(setActiveTab(PreliminariesStatus.PAST));
    setDataFilter({});
    setOrderBy(undefined);
    setOrder('');
    await dispatch(
      getCurrentPreliminariesItems({
        preliminary_status: PreliminaryStatus.PAST_PRELIMINARY,
      })
    );
    setLoading(false);
  };

  const handleChangePage = useCallback(
    async (
      page: number,
      dataFilterInFirst?: ManagerCurrentPreliminariesItemRequest
    ) => {
      setPagination((prev) => ({
        ...prev,
        page: page,
        fetching: true,
      }));

      const dataFilterRequest = !!dataFilterInFirst
        ? dataFilterInFirst
        : dataFilter;
      await dispatch(
        getCurrentPreliminariesItems({
          ...dataFilterRequest,
          preliminary_status:
            preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
          page: page,
          order_key: orderBy,
          order_sort: order,
        })
      );
      setPagination((prev) => ({ ...prev, fetching: false }));
    },
    [dataFilter, dispatch, order, orderBy, preliminaryStatus]
  );

  const handleRequestSort = useCallback(
    async (
      event: MouseEvent<unknown>,
      property: keyof ManagerPreliminariesItem
    ) => {
      dispatch(revertPreliminary());
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      setOrderBy(property);
      setLoading(true);
      setPagination((prev) => ({
        ...prev,
        page: 1,
        fetching: true,
      }));
      await dispatch(
        sortCurrentPreliminariesItems({
          ...dataFilter,
          preliminary_status:
            preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
          order_key: property,
          order_sort: newOrder,
        })
      );
      setOrder(newOrder);
      setPagination((prev) => ({ ...prev, fetching: false }));
      setLoading(false);
    },
    [dataFilter, dispatch, order, preliminaryStatus]
  );

  const updateDataFilter = useCallback(
    async (dataFilter: ManagerCurrentPreliminariesItemRequest) => {
      setDataFilter(dataFilter);
      setLoading(true);
      await dispatch(revertPreliminary());
      await handleChangePage(1, dataFilter);
      setLoading(false);
      setShowFilter(false);
    },
    [dispatch, handleChangePage]
  );

  const handleFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const changeApplicationStatus = useCallback(
    async (applicantId: string, applicationStatus: number) => {
      const result = await dispatch(
        updateApplicationStatus({
          id: applicantId,
          application_status: String(applicationStatus),
        })
      );
      if (updateApplicationStatus.fulfilled.match(result)) {
        setLoading(true);
        dispatch(revertPreliminary());
        await dispatch(
          getCurrentPreliminariesItems({
            preliminary_status:
              preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
          })
        );
        setLoading(false);
        return toast.success('更新しました。');
      }
      return toast.error('エラーが発生しました。');
    },
    [dispatch, preliminaryStatus]
  );

  const confirmUpdateLinking = useCallback(
    async (idLinking: string, idSelected: string, isLinking: boolean) => {
      setLoading(true);
      if (isLinking) {
        const result = await dispatch(
          unlinkPairLoan({
            id: idLinking,
          })
        );
        if (unlinkPairLoan.fulfilled.match(result)) {
          toast.success('ペアローンの紐付きを解除しました。');
        } else toast.error('エラーが発生しました。');
      } else {
        const result = await dispatch(
          linkPairLoan({
            id: idLinking,
            linking_number: idSelected,
          })
        );
        if (linkPairLoan.fulfilled.match(result)) {
          toast.success('ペアローンの紐付きをしました。');
        }
      }
      dispatch(revertPreliminary());
      await dispatch(
        getCurrentPreliminariesItems({
          preliminary_status:
            preliminaryStatus ?? PreliminaryStatus.PROVISIONAL_PRELIMINARY,
        })
      );
      setLoading(false);
    },
    [dispatch, preliminaryStatus]
  );

  const onAssignManager = useCallback(
    async (id: string, manager_id: string) => {
      const result = await dispatch(assignManager({ id, manager_id }));

      if (assignManager.fulfilled.match(result)) {
        toast.success('この申し込みに担当者をアサインしました。');
      } else {
        toast.error('エラーが発生しました。');
      }
    },
    [dispatch]
  );

  const onAssignSalesPerson = useCallback(
    async ({ id, s_sale_person_id }: AssignSalePersonRequest) => {
      const result = await dispatch(
        assignSalePersons({ id, s_sale_person_id })
      );
      if (assignSalePersons.fulfilled.match(result)) {
        toast.success('この申し込みに担当者をアサインしました。');
      }
    },
    [dispatch]
  );

  const onAssignStoreName = useCallback(
    async ({ id, p_referral_agency_id }: AssignStoreNameRequest) => {
      const result = await dispatch(
        assignStoreName({ id, p_referral_agency_id })
      );
      if (assignStoreName.fulfilled.match(result)) {
        toast.success('エリアを変更しました。');
      }
    },
    [dispatch]
  );

  useInfiniteScroll({
    callback: (page) => handleChangePage(page),
    pagination: pagination,
    id: 'preliminary-table-body',
  });

  return (
    <>
      <AdminLayout
        loading={loading}
        hasDrawer={false}
        header={{
          left: {
            mainTitle: (
              <Stack direction="row" spacing={4}>
                <Typography
                  variant="text_steps"
                  color={'normal_text'}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  申込一覧
                </Typography>
                <Stack
                  spacing={1}
                  alignItems="center"
                  direction="row"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(routeNames.ManagerDocumentUpload.path)
                  }
                >
                  <Icons.AddFile sx={{ width: 14, height: 16 }} />
                  <Typography
                    variant="text_steps"
                    color={'sp_primary_100_main'}
                  >
                    書類アップロード
                  </Typography>
                </Stack>
              </Stack>
            ),
          },
          children: (
            <Stack direction="row" spacing={2}>
              <Button
                disabled={loading}
                variant="text"
                sx={{
                  borderRadius: 0,
                  pl: 10,
                  pr: 10,
                  color: '#6B70F0',
                  boxShadow: 'none',
                  borderBottom:
                    activeTab === PreliminariesStatus.CURRENT
                      ? '3px solid #6B70F0'
                      : 'none',
                }}
                onClick={handleGetCurrentPreliminaries}
              >
                <Typography
                  variant="style_btn_header"
                  color={
                    activeTab === PreliminariesStatus.CURRENT
                      ? 'b_333'
                      : 'sp_primary_100_main'
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight:
                      activeTab === PreliminariesStatus.CURRENT ? 600 : 400,
                    textWrap: 'nowrap',
                  }}
                >
                  仮審査中の案件
                </Typography>
              </Button>
              <Button
                disabled={loading}
                variant="text"
                sx={{
                  borderRadius: 0,
                  pl: 10,
                  pr: 10,
                  color: '#6B70F0',
                  boxShadow: 'none',
                  borderBottom:
                    activeTab === PreliminariesStatus.INPROGRESS
                      ? '3px solid #6B70F0'
                      : 'none',
                }}
                onClick={handleGetInProgressPreliminaries}
              >
                <Typography
                  variant="style_btn_header"
                  color={
                    activeTab === PreliminariesStatus.INPROGRESS
                      ? 'b_333'
                      : 'sp_primary_100_main'
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight:
                      activeTab === PreliminariesStatus.INPROGRESS ? 600 : 400,
                    textWrap: 'nowrap',
                  }}
                >
                  本審査中の案件
                </Typography>
              </Button>
              <Button
                disabled={loading}
                variant="text"
                sx={{
                  borderRadius: 0,
                  pl: 10,
                  pr: 10,
                  color: '#6B70F0',
                  boxShadow: 'none',
                  borderBottom:
                    activeTab === PreliminariesStatus.PAST
                      ? '3px solid #6B70F0'
                      : 'none',
                }}
                onClick={handleGetPastPreliminaries}
              >
                <Typography
                  variant="style_btn_header"
                  color={
                    activeTab === PreliminariesStatus.PAST
                      ? 'b_333'
                      : 'sp_primary_100_main'
                  }
                  sx={{
                    fontSize: 16,
                    fontWeight:
                      activeTab === PreliminariesStatus.PAST ? 600 : 400,
                    textWrap: 'nowrap',
                  }}
                >
                  過去の案件
                </Typography>
              </Button>
            </Stack>
          ),
          right: {
            contentPopover: (
              <Button
                sx={{
                  py: 0,
                  flex: 1,
                  width: '100%',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  minHeight: 42,
                  height: 42,
                  boxShadow: 'none',
                  bgcolor: 'main_white',
                  justifyContent: 'flex-start',
                  px: '10px',
                  '&.MuiButton-root:hover': {
                    bgcolor: 'sp_primary_100_main',
                    '& .MuiTypography-root': {
                      color: 'main_white',
                    },
                  },
                }}
                onClick={handleExportExcel}
              >
                <Typography variant="auth_popover_text" color="b_333">
                  管理画面をエクスポート
                </Typography>
                <Icons.Excel sx={{ color: 'attention', ml: '9px' }} />
              </Button>
            ),
          },
          showIconHome: false,
        }}
        footer={{
          left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
          right: (
            <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />
          ),
          bgcolor: 'footer_bg',
        }}
      >
        <Stack sx={{ pb: '29px' }}>
          {activeTab === PreliminariesStatus.CURRENT && (
            <ManagerPreliminariesTable
              onRequestSort={handleRequestSort}
              onFilter={handleFilter}
              order={order}
              orderBy={orderBy}
              rows={currentPreliminary}
              activeTab={activeTab}
              fetching={pagination.fetching}
              changeApplicationStatus={changeApplicationStatus}
              confirmUpdateLinking={confirmUpdateLinking}
              onAssignManager={onAssignManager}
              onAssignSalesPerson={onAssignSalesPerson}
              onAssignStoreName={onAssignStoreName}
            />
          )}
          {activeTab === PreliminariesStatus.INPROGRESS && (
            <ManagerPreliminariesTable
              onRequestSort={handleRequestSort}
              onFilter={handleFilter}
              order={order}
              orderBy={orderBy}
              rows={currentPreliminary}
              headCells={headCellsInprogress}
              activeTab={activeTab}
              fetching={pagination.fetching}
              changeApplicationStatus={changeApplicationStatus}
              confirmUpdateLinking={confirmUpdateLinking}
              onAssignManager={onAssignManager}
              onAssignSalesPerson={onAssignSalesPerson}
              onAssignStoreName={onAssignStoreName}
            />
          )}
          {activeTab === PreliminariesStatus.PAST && (
            <ManagerPreliminariesTable
              onRequestSort={handleRequestSort}
              onFilter={handleFilter}
              order={order}
              orderBy={orderBy}
              rows={currentPreliminary}
              activeTab={activeTab}
              fetching={pagination.fetching}
              changeApplicationStatus={changeApplicationStatus}
              confirmUpdateLinking={confirmUpdateLinking}
              onAssignManager={onAssignManager}
              onAssignSalesPerson={onAssignSalesPerson}
              onAssignStoreName={onAssignStoreName}
            />
          )}
        </Stack>
      </AdminLayout>
      {activeTab === PreliminariesStatus.CURRENT && (
        <FilterCurrentModal
          open={showFilter}
          onClose={() => setShowFilter(false)}
          updateDataFilter={updateDataFilter}
          type={FILTER_MODAL_TYPE.PREVIEW}
        />
      )}
      {activeTab === PreliminariesStatus.PAST && (
        <FilterCurrentModal
          open={showFilter}
          onClose={() => setShowFilter(false)}
          updateDataFilter={updateDataFilter}
          type={FILTER_MODAL_TYPE.PAST}
        />
      )}
    </>
  );
};

export default ManagerPreliminariesPage;

const headCellsInprogress: HeadCellProps<ManagerPreliminariesItem>[] = [
  {
    id: 'application_number',
    label: '受付番号',
    style: { width: 170 },
  },
  {
    id: 'bank_name',
    label: '申込銀行',
    style: {
      justifyContent: 'center',
      width: 150,
      '@media (min-width: 1441px)': { flex: 1 },
    },
  },
  {
    id: 'applicant_name',
    label: '申込人',
    style: {
      justifyContent: 'center',
      width: 180,
      '@media (min-width: 1441px)': { flex: 1 },
    },
  },
  {
    id: 'created_at',
    label: '申込日時',
    style: {
      justifyContent: 'center',
      width: 115,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'loan_desired_borrowing_date',
    label: '実行予定日',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'temporary_desired_loan_amount',
    label: '申込金額',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 130,
      },
    },
  },
  {
    id: 'status_result',
    label: '進捗状況',
    style: {
      justifyContent: 'center',
      width: 150,
      '@media (min-width: 1441px)': {
        width: 201,
      },
    },
  },
  {
    id: 'provisional_result',
    label: '仮審査結果',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 120,
        pl: '5px',
      },
    },
  },
  {
    id: 'store_name_kanji',
    label: 'エリア',
    style: {
      justifyContent: 'center',
      width: 100,
      '@media (min-width: 1441px)': {
        width: 140,
      },
    },
  },
  {
    id: 'sale_person_name_kanji',
    label: '営業担当',
    style: {
      justifyContent: 'center',
      width: 120,
      '@media (min-width: 1441px)': {
        width: 140,
      },
    },
  },
  {
    id: 'manager_name_kanji',
    label: '銀代担当',
    style: {
      justifyContent: 'center',
      width: 120,
      '@media (min-width: 1441px)': {
        width: 140,
      },
    },
  },
  {
    id: 'id',
    label: (
      <Icons.Filter
        sx={{
          mt: 4,
          ml: '1px',
          cursor: 'pointer',
          color: 'b_333',
          visibility: 'hidden',
        }}
      />
    ),
    style: {
      pr: '2px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
];
