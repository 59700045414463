import { regex, yup } from 'libs';
import {
  BANK_NOT_VALID_DAYS,
  DesiredMonthlyBonusType,
  LandAdvanceType,
  LoanTargetZeroType,
  MESSAGE_REGEX,
  PurposeType,
  SP_FIELD_ERROR_MESSAGE,
} from 'constant';
import { isWeekend } from 'utils';
import { PublicHolidayResponse } from 'services';

export const validationSchemaSubmit = (
  birthday: string,
  publicHolidays: Record<number, PublicHolidayResponse[]>
) => {
  return yup.object().shape({
    p_application_header: yup.object().shape(
      {
        bonus_times: yup
          .string()
          .nullable()
          .test(
            'is-greater-than-2',
            '年間のボーナス回数は最大2回まで入力してください。',
            (value) => {
              return (
                (value !== null &&
                  value !== undefined &&
                  parseFloat(value) <= 2 &&
                  parseFloat(value) > 0) ||
                !value
              );
            }
          ),
        borrowing_period: yup
          .string()
          .nullable()
          .test(
            'is-greater-than-35',
            '借入期間は50年以内で入力してください。',
            (value) => {
              return (
                (value !== null &&
                  value !== undefined &&
                  parseFloat(value) <= 50 &&
                  parseFloat(value) > 0) ||
                !value
              );
            }
          ),
        // loan_apply_date: yup
        //   .string()
        //   .test(
        //     'one-field-missing',
        //     'お客様に該当する数値を選択してください。',
        //     (value) => {
        //       const [year, month, day] = value
        //         ? value.split('/')
        //         : ['', '', ''];
        //       return (!!year && !!month && !!day) || (!year && !month && !day);
        //     }
        //   )
        //   .test({
        //     message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
        //     test(value) {
        //       if (!!value)
        //         return dayjs(value).isSameOrAfter(
        //           dayjs(this.parent.created_at),
        //           'date'
        //         );
        //       return true;
        //     },
        //   })
        //   .test({
        //     message:
        //       MESSAGE_REGEX.APPLICATION_DATE_MUST_BE_LESS_THAN_DESIRED_LOAN_DATE,
        //     test(value) {
        //       if (!!value) {
        //         const item = this.parent.p_borrowing_details_attributes;
        //         if (!!item.length) {
        //           if (!!item[0].loan_desired_borrowing_date) {
        //             return dayjs(value).isBefore(
        //               dayjs(item[0].loan_desired_borrowing_date),
        //               'date'
        //             );
        //           }
        //         }
        //         return true;
        //       }
        //       return true;
        //     },
        //   }),
        scheduled_date_moving: yup
          .string()
          // .test({
          //   message: MESSAGE_REGEX.PLEASE_SELECT_MONTH_AFTER_TODAY,
          //   test(value) {
          //     if (!!value && !!this.parent.loan_apply_date) {
          //       const [year, month] = value?.split('/');
          //       const [yearCreated, monthCreated] =
          //         this.parent.created_at.split('/');
          //
          //       return dayjs(`${year}-${month}`).isSameOrAfter(
          //         dayjs(`${yearCreated}-${monthCreated}`),
          //         'date'
          //       );
          //     }
          //     return true;
          //   },
          // })
          // .test({
          //   message: MESSAGE_REGEX.PLEASE_SELECT_MONTH_AFTER_TODAY,
          //   test(value) {
          //     if (!!value && !!this.parent.loan_apply_date) {
          //       const [year, month] = value?.split('/');
          //       const endTime = dayjs(`${year}-${month}`);
          //       return endTime.isAfter(
          //         dayjs(this.parent.loan_apply_date),
          //         'date'
          //       );
          //     }
          //     return true;
          //   },
          // })
          .test(
            'one-field-missing',
            MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
            (value) => {
              const [year, month] = value ? value.split('/') : ['', ''];
              return (!!year && !!month) || (!year && !month);
            }
          )
          .label('入居予定年月'),
        master_bank_ids: yup
          .array()
          .min(1, 'お客様に該当するものを選択してください。'),
        loan_target_zero: yup.string(),
        loan_target: yup.string().when('loan_target_zero', {
          is: (loanTargetZero: LoanTargetZeroType) =>
            loanTargetZero === LoanTargetZeroType.ZERO,
          then: yup.string().required('どれか1つを選択してください。'),
          otherwise: yup.string(),
        }),
        has_land_advance_plan: yup.string().when('loan_target', {
          is: (loan_target: PurposeType) => loan_target === PurposeType.SIX,
          then: yup.string().required('どれか1つを選択してください。'),
          otherwise: yup.string(),
        }),
        loan_type: yup.string(),
        is_home_loan_plus: yup.string(),
        p_borrowing_details_attributes: yup.array(
          yup.object().shape({
            loan_desired_borrowing_date: yup
              .string()
              .test(
                'one-field-missing',
                MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED,
                (value) => {
                  if (!value) return true;
                  const [year, month, day] = value.split('/');

                  return !!year && !!month && !!day;
                }
              )
              // .test({
              //   message: MESSAGE_REGEX.PLEASE_SELECT_A_DATE_AFTER_TODAY,
              //   test(value, testContext) {
              //     if (!!value && !!testContext) {
              //       const [year, month, day] = value?.split('/');
              //       const endTime = dayjs(`${year}-${month}-${day}`);
              //       return endTime.isAfter(dayjs(), 'date');
              //     }
              //     return true;
              //   },
              // })
              .test(
                'not-available-days',
                MESSAGE_REGEX.PLEASE_SELECT_BANK_BUSINESS_DAY,
                (value) => {
                  if (!value) return true;
                  const [year, month, day] = value.split('/');

                  if (!year || !month || !day) {
                    return true;
                  }
                  if (
                    publicHolidays[+year]?.find(
                      (o) => o.date === `${year}-${month}-${day}`
                    )
                  ) {
                    return false;
                  }
                  return (
                    !isWeekend(value) &&
                    !BANK_NOT_VALID_DAYS.includes(`${month}/${day}`)
                  );
                }
              )
              .label('お借入希望日'),
            // .test(
            //   'loan_desired_borrowing_date_larger_than_one',
            //   MESSAGE_REGEX.LOAN_DESIRED_BORROWRING_DATE,
            //   (value, testContext) => {
            //     if (!!value && !!testContext) {
            //       const borrowingDetails =
            //         // @ts-ignore
            //         testContext.from[1]?.value
            //           .p_borrowing_details_attributes;
            //       const firstLoanDate =
            //         borrowingDetails[0]?.loan_desired_borrowing_date;
            //       const secondLoanDate =
            //         borrowingDetails[1]?.loan_desired_borrowing_date;
            //       if (!!firstLoanDate && !!secondLoanDate) {
            //         return dayjs(secondLoanDate).isAfter(
            //           dayjs(firstLoanDate),
            //           'date'
            //         );
            //       }
            //       return true;
            //     }
            //     return true;
            //   }
            // ),
            temporary_desired_loan_amount: yup
              .number()
              .when('_destroy', {
                is: (_destroy: boolean) => !_destroy,
                then: yup.number().required(MESSAGE_REGEX.REQUIRED),
              })
              .typeError(MESSAGE_REGEX.TYPE_NUMBER)
              .test(
                'divisible-by-10',
                MESSAGE_REGEX.ENTER_UNITS_100000YEN,
                (borrow) => +(borrow || 0) % 10 === 0
              )
              .test({
                message: MESSAGE_REGEX.ABOUT_TEMPORARY_DESIRED_LOAN_AMOUNT,
                test(value, testContext) {
                  if (!!value && !!testContext) {
                    const borrowingDetails =
                      // @ts-ignore
                      testContext.from[1]?.value
                        ?.p_borrowing_details_attributes;
                    const firstLoan =
                      borrowingDetails[0]?.temporary_desired_loan_amount;
                    const secondLoan =
                      !!borrowingDetails[1] && !borrowingDetails[1]._destroy
                        ? borrowingDetails[1].temporary_desired_loan_amount
                        : 0;
                    if (!!firstLoan && !!secondLoan) {
                      return true;
                    }
                    if (
                      !!firstLoan &&
                      // @ts-ignore
                      testContext.from[1]?.value.has_land_advance_plan !==
                        LandAdvanceType.YES
                    ) {
                      return 500 <= +firstLoan && +firstLoan <= 20000;
                    }
                  }
                  return true;
                },
              })
              .test({
                message: MESSAGE_REGEX.TEMPORARY_DESIRED_LOAN_AMOUNT,
                test(value, testContext) {
                  if (!!value && !!testContext) {
                    const borrowingDetails =
                      // @ts-ignore
                      testContext.from[1]?.value
                        ?.p_borrowing_details_attributes;
                    const firstLoan =
                      borrowingDetails[0]?.temporary_desired_loan_amount;
                    const secondLoan =
                      !!borrowingDetails[1] && !borrowingDetails[1]._destroy
                        ? borrowingDetails[1].temporary_desired_loan_amount
                        : 0;
                    if (!!firstLoan && !!secondLoan) {
                      const totalLoan = +firstLoan + +secondLoan;
                      return 500 <= totalLoan && totalLoan <= 20000;
                    }
                    if (!!firstLoan) {
                      return 500 <= +firstLoan && +firstLoan <= 20000;
                    } else if (!!secondLoan) {
                      return 500 <= +secondLoan && +secondLoan <= 20000;
                    }
                  }
                  return true;
                },
              }),
            halfyear_bonus: yup
              .number()
              .typeError(
                '資金調達合計と必要資金総額が一致しません。正しくご入力ください。'
              )
              .when(['desired_monthly_bonus', '_destroy'], {
                is: (
                  desired_monthly_bonus: DesiredMonthlyBonusType,
                  _destroy: boolean
                ) =>
                  desired_monthly_bonus !== DesiredMonthlyBonusType.ONE &&
                  !_destroy,
                then: yup
                  .number()
                  .test(
                    'zero_check',
                    '0より大きい数字をご入力ください',
                    (value) => value !== 0
                  )
                  .required(MESSAGE_REGEX.REQUIRED),
              })
              .min(0, 'ボーナス返済分は融資金額の50%以内で入力してください。')
              .max(
                20000,
                'ボーナス返済分は融資金額の50%以内で入力してください。'
              )
              .test(
                'divisible-by-10',
                MESSAGE_REGEX.ENTER_UNITS_100000YEN,
                (borrow) => +(borrow || 0) % 10 === 0
              )
              .test(
                'test-temporary-desired-loan-amount',
                'ボーナス返済分は融資金額の50%以内で入力してください。',
                function (value) {
                  const { temporary_desired_loan_amount } = this.parent;
                  if (!temporary_desired_loan_amount || !value) {
                    return true;
                  }
                  return value <= temporary_desired_loan_amount / 2;
                }
              ),
            repayment_method: yup
              .string()
              .required(MESSAGE_REGEX.RADIO_REQUIRED),
            desired_monthly_bonus: yup.string(),
            loan_term_year_num: yup
              .string()
              // .test(
              //   'sum-validation',
              //   MESSAGE_REGEX.LOAN_TERM_LIMIT,
              //   function (value) {
              //     const birthdayDayjs = dayjs(birthday, 'YYYY/MM/DD');
              //     const birthdayMonth = birthdayDayjs.diff(
              //       dayjs().format('YYYY-MM-DD'),
              //       'month'
              //     );
              //     return (
              //       Number(value) * 12 + Math.abs(birthdayMonth) <= 80 * 12
              //     );
              //   }
              // )
              .required(MESSAGE_REGEX.DROPDOWN_SELECT_REQUIRED)
              .label(
                SP_FIELD_ERROR_MESSAGE.borrowing_detail_loan_term_year_num
              ),
            loan_term_month_num: yup.string(),
          })
        ),
        // .test(
        //   'loan_desired_borrowing_date_larger_than_one',
        //   '２回目の融資のお借入希望日は１回目の融資のお借入希望日以降で選択してください。',
        //   // @ts-ignore
        //   (value) => {
        //     const firstDate = value?.[0]?.loan_desired_borrowing_date;
        //     const secondDate = value?.[1]?.loan_desired_borrowing_date;
        //
        //     if (firstDate && secondDate) {
        //       return secondDate >= firstDate;
        //     }
        //     return true;
        //   }
        // ),
        p_applicant_people_attributes: yup.object().shape({
          has_join_guarantor: yup.string(),
        }),
        pair_loan_applicant_last_name: yup
          .string()
          .max(48)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        pair_loan_applicant_first_name: yup
          .string()
          .max(48)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
        pair_loan_relationship_name: yup
          .string()
          .max(48)
          .matches(regex.kanjiFullwidth, MESSAGE_REGEX.SP_KANJI_FULL_WIDTH),
      },
      [['loan_target', 'loan_target_zero']]
    ),
  });
};
