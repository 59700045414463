import {
  ChangeEvent,
  FC,
  Fragment,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { Stack, Typography } from '@mui/material';
import { SPInputField } from 'components';
import { convertToHalfWidth, onlyNumbers } from 'utils';
import { validationSchema } from './validationSchema';

export type SPInputZipCodeProps = {
  name: string;
  label: string;
  note?: string;
  handleInputZipcode?: (values: string) => void;
  handleFocusZipCode?: () => void;
};

export const SPInputZipCode: FC<SPInputZipCodeProps> = ({
  label,
  note,
  handleInputZipcode,
  handleFocusZipCode,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const initialValues = useMemo(() => {
    const [firstCode = '', secondCode = ''] = field.value
      ? field.value.split('-')
      : ['', ''];
    return { firstCode, secondCode };
  }, [field.value]);

  const refOne = useRef<HTMLInputElement>(null);
  const refTwo = useRef<HTMLInputElement>(null);
  const currentIndex = useRef<number>(0);

  const [firstTouchField, setFirstTouchField] = useState(false);

  const zipCodeInputs = useMemo(
    () => [
      {
        name: 'firstCode',
        ref: refOne,
        maxLength: 3,
      },
      {
        name: 'secondCode',
        ref: refTwo,
        maxLength: 4,
      },
    ],
    []
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit() {},
  });

  const handleBackInput = useCallback(() => {
    const prevIndex = currentIndex.current - 1;

    if (prevIndex !== -1) {
      const prevInput = zipCodeInputs?.[prevIndex]?.ref.current;
      prevInput?.focus();

      currentIndex.current = prevIndex;
    }
  }, [zipCodeInputs]);

  const handleNextInput = useCallback(() => {
    const nextIndex = currentIndex.current + 1;

    if (nextIndex === zipCodeInputs.length) {
      return zipCodeInputs?.[currentIndex.current]?.ref.current?.blur();
    }
    const nextInput = zipCodeInputs?.[nextIndex]?.ref.current;
    nextInput?.focus();

    currentIndex.current = nextIndex;
  }, [zipCodeInputs]);

  const handleKeyPress = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (
        (e.target.value.length === 3 && e.target.name === 'firstCode') ||
        e.target.value.length === 4
      )
        handleNextInput();
      if (e.target.value.length === 0) handleBackInput();

      if (refOne.current?.value || refTwo.current?.value) {
        return setFieldValue(
          field.name,
          `${refOne.current!.value}-${refTwo.current!.value}`
        );
      }

      return setFieldValue(field.name, '');
    },
    [field.name, handleBackInput, handleNextInput, setFieldValue]
  );

  const handleBlur = useCallback(() => {
    if (!!refOne.current && !!refTwo.current) {
      if (!!refOne.current.value || !!refTwo.current.value) {
        setFieldValue(
          field.name,
          `${convertToHalfWidth(refOne.current.value)}-${convertToHalfWidth(
            refTwo.current.value
          )}`
        );
        if (handleInputZipcode) {
          handleInputZipcode(
            `${convertToHalfWidth(refOne.current.value)}-${convertToHalfWidth(
              refTwo.current.value
            )}`
          );
        }
      }
    }
    setFirstTouchField(false);
  }, [field.name, handleInputZipcode, setFieldValue]);

  const handleFocusInput = useCallback(
    (e: KeyboardEvent<HTMLDivElement>, name: string) => {
      if (
        e.key !== 'Backspace' &&
        name === 'firstCode' &&
        refOne.current?.value.length === 3
      ) {
        handleNextInput();
      }
      if (handleFocusZipCode) {
        handleFocusZipCode();
      }
      if (e.key === 'Backspace' && refTwo.current?.value === '')
        handleBackInput();
    },
    [handleBackInput, handleFocusZipCode, handleNextInput]
  );

  return (
    <FormikProvider value={formik}>
      <input name={field.name} type="hidden" />
      <Stack spacing={1}>
        <Stack spacing="6px">
          {!!label && (
            <Typography variant="buttonAddImage" color="b_333">
              {label}
            </Typography>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {zipCodeInputs.map((input, index) => (
              <Fragment key={index}>
                <Stack direction="row" alignItems="center">
                  <SPInputField
                    inputRef={input.ref}
                    name={input.name}
                    sx={{ width: index ? 76 : 65 }}
                    placeholder={'0'.repeat(input.maxLength).toString()}
                    onChange={handleKeyPress}
                    onKeyDown={(e) => handleFocusInput(e, input.name)}
                    onKeyPress={(event) => onlyNumbers(event)}
                    onFocus={() => {
                      setFieldTouched(field.name);
                      setFirstTouchField(true);
                      currentIndex.current = index;
                    }}
                    onBlur={handleBlur}
                    maxLength={input.maxLength}
                    numeric
                    error={!firstTouchField && !!meta.error && meta.touched}
                  />
                </Stack>
                {index !== zipCodeInputs.length - 1 && (
                  <Typography variant="text_note" color="b_333">
                    -
                  </Typography>
                )}
              </Fragment>
            ))}
          </Stack>
          {!!note && (
            <Typography variant="sp_note_text" color="b_333">
              {note}
            </Typography>
          )}
        </Stack>
        {!firstTouchField && meta.error && meta.touched && (
          <Typography variant="text_error" color="sp_secondary_01">
            ※{meta.error}
          </Typography>
        )}
      </Stack>
    </FormikProvider>
  );
};
