import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPModal } from 'components';
import { theme } from 'styles';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  onClick: () => void;
}>;

export const SPModalChangeLoanType: FC<ModalProps> = ({
  open,
  onClose,
  onClick,
}) => (
  <SPModal
    open={open}
    onClose={onClose}
    icon={<Icons.SPInfoModal sx={{ width: 35, height: 35 }} />}
    title="確認"
    disableBackDropClick
    sx={{
      px: 0,
      ...theme.typography.SP_form_item_label,
    }}
    footer={
      <Stack alignItems="center" justifyContent="center" sx={{ minWidth: 359 }}>
        <Stack spacing={7} mb={7}>
          <Stack px={6} alignItems="center" justifyContent="center">
            <Typography
              variant="sp_change_password_note"
              textAlign="center"
              color="b_333"
            >
              {`借入状態にペアローンを外しましたら、\nペアローンに関して登録された情報が削除されますが、よろしいでしょうか。`}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={3}>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              キャンセル
            </Typography>
          </SPButton>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_100_main',
            }}
            onClick={onClick}
          >
            <Typography variant="SP_multiple_checkbox_label" color="main_white">
              OK
            </Typography>
          </SPButton>
        </Stack>
      </Stack>
    }
  />
);
