import { CODEBANK } from 'constant';
import { createSelector } from 'reselect';
import { RootState } from 'types';

export const isLoggedInSelector = (state: RootState) => state.auth.isLoggedIn;
export const isFirstTimeLoginSelector = (state: RootState) =>
  state.auth.firstTimeLogin;
export const isAzureLoginSelector = (state: RootState) =>
  state.auth.isAzureLogin;

export const accessTokenSelector = (state: RootState) => state.auth.accessToken;

export const tokenSelector = (state: RootState) => state.auth.token;

export const draftExitsSelector = (state: RootState) => state.auth.draftExits;

export const managerIdSelector = (state: RootState) => state.auth.managerId;

export const salePersonIdSelector = (state: RootState) =>
  state.auth.salePersonId;

export const preliminariesSelector = (state: RootState) =>
  state.auth.preliminaries;

export const disablePdfSelector = (state: RootState) => state.auth.disablePdf;

export const displayPdfSelector = (state: RootState) => state.auth.displayPdf;

export const masterBankCodesSelector = (state: RootState) =>
  state.auth.master_bank_codes;

export const statusResultSelector = (state: RootState) =>
  state.auth.status_result;

export const statusIsUpdateResultSelector = (state: RootState) =>
  state.auth.isStatuses;

export const checkMCJ = createSelector(masterBankCodesSelector, (input) => {
  return !!input?.includes(CODEBANK.MCJ);
});

export const spCurrentStepSelector = (state: RootState) =>
  state.auth.spCurrentStep;

export const examinationFileSelector = (state: RootState) =>
  state.auth.examinationFile;

export const publicHolidaysSelector = (state: RootState) =>
  state.auth.publicHolidays;

export const preliminaryIdSelector = (state: RootState) =>
  state.auth.preliminaryId;
export const timeGetEditSelector = (state: RootState) => state.auth.timeGetEdit;
export const sbiIdSelector = (state: RootState) => state.auth.sbiId;
export const isJoinGuarantorInitSelector = (state: RootState) =>
  state.auth.isJoinGuarantorInit;
export const isIncomeTotalizerInitSelector = (state: RootState) =>
  state.auth.isIncomeTotalizerInit;

export const userDataSelector = (state: RootState) => state.auth;
