import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  NewPreliminariesRequest,
  NewPreliminariesResponse,
  ThunkAPI,
} from 'types';
import {
  applicationsService,
  GetUploadedFilesError,
  GetUploadedFilesRequest,
  GetUploadedFilesResponse,
} from 'services';

export const getPreliminaries = createAsyncThunk<
  NewPreliminariesResponse,
  NewPreliminariesRequest
>('Preliminaries/GetPreliminaries', async (id, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.getPreliminaries(
      id.id
    );
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getClonePreliminaries = createAsyncThunk<
  NewPreliminariesResponse,
  NewPreliminariesRequest
>('Preliminaries/GetPreliminaries', async (id, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.getClonePreliminaries(
      id.id
    );
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUploadedFiles = createAsyncThunk<
  GetUploadedFilesResponse,
  GetUploadedFilesRequest,
  ThunkAPI<GetUploadedFilesError>
>('Preliminaries/GetUploadedFiles', async (id, { rejectWithValue }) => {
  try {
    return await applicationsService.getUploadedFiles(id.id);
  } catch (error) {
    return rejectWithValue(error);
  }
});
