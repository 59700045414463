import { FC, PropsWithChildren, ReactNode } from 'react';
import { Modal as MuiModal, Stack, SxProps, Typography } from '@mui/material';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  sx?: SxProps;
  sxChildren?: SxProps;
  title?: string;
  icon?: ReactNode;
  disableBackDropClick?: boolean;
  footer?: ReactNode;
  sxPadding?: string;
}>;

export const SPModal: FC<ModalProps> = ({
  open,
  onClose,
  sx,
  sxChildren,
  children,
  title,
  icon,
  disableBackDropClick = false,
  footer,
  sxPadding,
}) => (
  <MuiModal
    sx={{
      overflowY: 'scroll',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    disableAutoFocus
    open={open}
    onClose={(e, reason) => {
      if (reason === 'backdropClick' && disableBackDropClick) return;
      onClose();
    }}
  >
    <Stack
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { desktop: 430 },
        minWidth: '75%',
        maxHeight: 744,
        backgroundColor: 'main_white',
        '@media (orientation: landscape)': {
          minWidth: 'auto',
          maxHeight: '75vh',
        },
        borderRadius: 2,
        py: 8,
        px: sxPadding ?? 8,
        ...sx,
      }}
      spacing={6}
    >
      <Stack alignItems="center" spacing={4}>
        {icon}
        {!!title && (
          <Typography
            variant="sp_modal_title"
            color="sp_primary_100_main"
            sx={{ whiteSpace: 'break-spaces', textAlign: 'center', ...sx }}
          >
            {title}
          </Typography>
        )}
      </Stack>
      <Stack
        sx={{
          overflowY: 'auto',
          ...sxChildren,
        }}
        spacing={6}
      >
        {!!children && (
          <Stack
            sx={{
              flex: 1,
              overflow: 'auto',
            }}
          >
            {children}
          </Stack>
        )}
        {footer}
      </Stack>
    </Stack>
  </MuiModal>
);
