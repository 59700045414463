import {
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';

export type SPInputSelectNormalProps = SelectProps & {
  options: { value: string | number; label: string }[];
  placeholder?: string;
};

export const SPInputSelectNormal: FC<SPInputSelectNormalProps> = ({
  options,
  placeholder,
  ...props
}) => {
  const renderIconComponent = useCallback(
    () => (
      <KeyboardArrowDown
        sx={{
          width: 15,
          height: 20,
          position: 'absolute',
          right: 5,
          top: 'calc(50% - 10px)',
          pointerEvents: 'none',
          color: 'sp_primary_100_main',
        }}
      />
    ),
    []
  );

  const renderValue = useMemo(
    () =>
      !!props.value
        ? undefined
        : () => (
            <Typography variant="placeHolder_style" color="off_text">
              {placeholder}
            </Typography>
          ),
    [props.value, placeholder]
  );

  return (
    <Stack flex={1} spacing={1}>
      <Stack flex={1} direction="row" alignItems="center" spacing={1}>
        <Select
          {...props}
          sx={{
            width: '480px',
            minHeight: '48px',
            height: '48px',
            '&&&& fieldset': {
              border: '1px solid',
              boxShadow: 'inset 0px 4px 6px rgba(44, 54, 156, 0.1)',
              borderColor: 'sp_primary_40',
            },
            '& .MuiInputBase-input': {
              p: 3,
              '&:focus': {
                backgroundColor: 'sp_bg_yellow',
                boxShadow: '0px 0px 15px rgba(60, 72, 196, 0.1)',
              },
            },
            '& .MuiSelect-select': {
              pt: '13px',
            },
            '&&&&& .MuiTypography-input_style': {
              fontFamily: 'Hiragino Sans',
              fontWeight: 300,
              fontSize: 16,
              lineHeight: '16px',
              letterSpacing: 0.6,
            },
            '& .MuiTypography-placeHolder_style': {
              color: 'bg_radio_avatar',
              fontFamily: 'Hiragino Sans',
              fontSize: 16,
              fontWeight: 400,
              lineHeight: '16px',
              letterSpacing: 0.6,
            },
            ...props.sx,
          }}
          displayEmpty
          renderValue={renderValue}
          IconComponent={renderIconComponent}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography variant="input_style" color="normal_text">
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};
