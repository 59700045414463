import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPTrash: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 22" {...props}>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6976_59876)">
          <path
            d="M4.96719 2.75L6.17891 0.807812C6.49258 0.305293 7.04258 0 7.63555 0H11.6145C12.2074 0 12.7574 0.305293 13.0711 0.807812L14.2828 2.75H18.9062C19.0953 2.75 19.25 2.90383 19.25 3.09375C19.25 3.28367 19.0953 3.4375 18.9062 3.4375H0.34375C0.153914 3.4375 0 3.28367 0 3.09375C0 2.90383 0.153914 2.75 0.34375 2.75H4.96719ZM5.775 2.75H13.475L12.4867 1.17219C12.2977 0.870547 11.9668 0.6875 11.6145 0.6875H7.63555C7.2832 0.6875 6.95234 0.870547 6.76328 1.17219L5.775 2.75ZM1.71875 4.8125C1.90867 4.8125 2.0625 4.96719 2.0625 5.15625V18.9062C2.0625 20.234 3.13973 21.3125 4.46875 21.3125H14.7812C16.109 21.3125 17.1875 20.234 17.1875 18.9062V5.15625C17.1875 4.96719 17.3422 4.8125 17.5312 4.8125C17.7203 4.8125 17.875 4.96719 17.875 5.15625V18.9062C17.875 20.6164 16.4914 22 14.7812 22H4.46875C2.76031 22 1.375 20.6164 1.375 18.9062V5.15625C1.375 4.96719 1.52883 4.8125 1.71875 4.8125ZM5.84375 17.875C5.84375 18.0641 5.68906 18.2188 5.5 18.2188C5.31094 18.2188 5.15625 18.0641 5.15625 17.875V6.875C5.15625 6.68594 5.31094 6.53125 5.5 6.53125C5.68906 6.53125 5.84375 6.68594 5.84375 6.875V17.875ZM9.96875 17.875C9.96875 18.0641 9.81406 18.2188 9.625 18.2188C9.43594 18.2188 9.28125 18.0641 9.28125 17.875V6.875C9.28125 6.68594 9.43594 6.53125 9.625 6.53125C9.81406 6.53125 9.96875 6.68594 9.96875 6.875V17.875ZM14.0938 17.875C14.0938 18.0641 13.9391 18.2188 13.75 18.2188C13.5609 18.2188 13.4062 18.0641 13.4062 17.875V6.875C13.4062 6.68594 13.5609 6.53125 13.75 6.53125C13.9391 6.53125 14.0938 6.68594 14.0938 6.875V17.875Z"
            fill="url(#paint0_linear_6976_59876)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_6976_59876"
            x1="2.96435"
            y1="17.4755"
            x2="19.6484"
            y2="2.72964"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
          <clipPath id="clip0_6976_59876">
            <rect width="19.25" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
