import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { LoanType, PurposeType, SPTitle } from 'constant';
import { Icons, SPButton } from 'components';
import { routeNames } from 'navigations/routes';
import { convertStepSixSelector } from 'pages/SPStepSix/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneSelector,
} from 'pages/SPStepOne/selectors';
import { SPStepSixForm, StepSixFormType } from 'pages/SPStepSix';
import NumericFormat from 'react-number-format';
import { numberWithCommas } from 'utils';

type SPStepSixInfoProps = {
  showEditButton?: boolean;
};
export const SPStepSixInfo: FC<SPStepSixInfoProps> = ({
  showEditButton = true,
}) => {
  const navigate = useNavigate();
  const spStepSix = useSelector(convertStepSixSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const { p_application_header } = useSelector(spStepOneSelector);

  const housePurchasePriceTitle = () => {
    switch (p_application_header.loan_target) {
      case PurposeType.ONE:
      case PurposeType.TWO:
        return '物件価格';
      case PurposeType.THREE:
      case PurposeType.FOUR:
        return 'マンション価格';
      case PurposeType.FIVE:
      case PurposeType.SIX:
        return '建物';
      default:
        return '';
    }
  };

  const getRequiredFunds = useCallback(() => {
    switch (p_application_header.loan_target) {
      case PurposeType.ONE:
      case PurposeType.TWO:
      case PurposeType.THREE:
      case PurposeType.FOUR:
        return (
          +spStepSix.house_purchase_price +
          +spStepSix.additional_cost +
          +spStepSix.require_funds_breakdown_mortgage
        );
      case PurposeType.FIVE:
        return (
          +spStepSix.house_purchase_price +
          +spStepSix.additional_cost +
          +spStepSix.require_funds_breakdown_mortgage +
          +spStepSix.accessory_cost
        );
      case PurposeType.SIX:
        return (
          +spStepSix.house_purchase_price +
          +spStepSix.additional_cost +
          +spStepSix.require_funds_breakdown_mortgage +
          +spStepSix.land_purchase_price +
          +spStepSix.accessory_cost
        );
      case PurposeType.SEVEN:
        return (
          +spStepSix.additional_cost +
          +spStepSix.require_funds_breakdown_mortgage +
          +spStepSix.refinancing_loan_balance
        );
      case PurposeType.EIGHT:
        return (
          +spStepSix.additional_cost +
          +spStepSix.require_funds_breakdown_mortgage +
          +spStepSix.refinancing_loan_balance +
          +spStepSix.house_upgrade_cost
        );
      default:
        return 0;
    }
  }, [
    p_application_header.loan_target,
    spStepSix.accessory_cost,
    spStepSix.additional_cost,
    spStepSix.house_purchase_price,
    spStepSix.house_upgrade_cost,
    spStepSix.land_purchase_price,
    spStepSix.refinancing_loan_balance,
    spStepSix.require_funds_breakdown_mortgage,
  ]);

  const getRaisedFunds = useCallback(() => {
    return (
      +spStepSix.deposit_savings_1 +
      +spStepSix.real_estate_sale_price +
      +spStepSix.other_saving_amount +
      +spStepSix.relative_donation_amount +
      +spStepSix.loan_amount +
      (p_application_header.loan_type === LoanType.TWO
        ? +spStepSix.pair_loan_amount
        : 0) +
      +spStepSix.other_procurement_breakdown
    );
  }, [
    p_application_header.loan_type,
    spStepSix.deposit_savings_1,
    spStepSix.loan_amount,
    spStepSix.other_procurement_breakdown,
    spStepSix.other_saving_amount,
    spStepSix.pair_loan_amount,
    spStepSix.real_estate_sale_price,
    spStepSix.relative_donation_amount,
  ]);

  const left: StepSixFormType[] = [
    p_application_header.loan_target === PurposeType.SIX
      ? {
          label: '土地',
          input: (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.land_purchase_price ? (
                <NumericFormat
                  displayType="text"
                  value={spStepSix.land_purchase_price}
                  isNumericString
                  thousandSeparator
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              ) : (
                '--'
              )}
              万円
            </Typography>
          ),
        }
      : undefined,
    p_application_header.loan_target === PurposeType.ONE ||
    p_application_header.loan_target === PurposeType.TWO ||
    p_application_header.loan_target === PurposeType.THREE ||
    p_application_header.loan_target === PurposeType.FOUR ||
    p_application_header.loan_target === PurposeType.FIVE ||
    p_application_header.loan_target === PurposeType.SIX
      ? {
          label: housePurchasePriceTitle(),
          input: (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.house_purchase_price ? (
                <NumericFormat
                  displayType="text"
                  value={spStepSix.house_purchase_price}
                  isNumericString
                  thousandSeparator
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              ) : (
                '--'
              )}
              万円
            </Typography>
          ),
        }
      : undefined,
    p_application_header.loan_target === PurposeType.FIVE ||
    p_application_header.loan_target === PurposeType.SIX
      ? {
          label: '付帯設備',
          input: (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.accessory_cost ? (
                <NumericFormat
                  displayType="text"
                  value={spStepSix.accessory_cost}
                  isNumericString
                  thousandSeparator
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              ) : (
                '--'
              )}
              万円
            </Typography>
          ),
        }
      : undefined,
    p_application_header.loan_target === PurposeType.EIGHT
      ? {
          label: '増改築費',
          input: (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.house_upgrade_cost ? (
                <NumericFormat
                  displayType="text"
                  value={spStepSix.house_upgrade_cost}
                  isNumericString
                  thousandSeparator
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              ) : (
                '--'
              )}
              万円
            </Typography>
          ),
        }
      : undefined,
    p_application_header.loan_target === PurposeType.SEVEN ||
    p_application_header.loan_target === PurposeType.EIGHT
      ? {
          label: '借換対象ローン残債',
          input: (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.refinancing_loan_balance ? (
                <NumericFormat
                  displayType="text"
                  value={spStepSix.refinancing_loan_balance}
                  isNumericString
                  thousandSeparator
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              ) : (
                '--'
              )}
              万円
            </Typography>
          ),
        }
      : undefined,
    {
      label: '諸費用等',
      input: (
        <Typography variant="sp_step_09_form_title" color="b_333">
          {spStepSix.additional_cost ? (
            <NumericFormat
              displayType="text"
              value={spStepSix.additional_cost}
              isNumericString
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              allowLeadingZeros={false}
            />
          ) : (
            '--'
          )}
          万円
        </Typography>
      ),
    },
    !!p_application_header.is_home_loan_plus
      ? {
          label: '住宅ローンプラス利用',
          input: (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.require_funds_breakdown_mortgage ? (
                <NumericFormat
                  displayType="text"
                  value={spStepSix.require_funds_breakdown_mortgage}
                  isNumericString
                  thousandSeparator
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              ) : (
                '--'
              )}
              万円
            </Typography>
          ),
        }
      : undefined,
  ];

  const right: StepSixFormType[] = [
    {
      label: '預貯金',
      input: (
        <Typography variant="sp_step_09_form_title" color="b_333">
          {spStepSix.deposit_savings_1 ? (
            <NumericFormat
              displayType="text"
              value={spStepSix.deposit_savings_1}
              isNumericString
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              allowLeadingZeros={false}
            />
          ) : (
            '--'
          )}
          万円
        </Typography>
      ),
    },
    {
      label: '不動産売却代金',
      input: (
        <Typography variant="sp_step_09_form_title" color="b_333">
          {spStepSix.real_estate_sale_price ? (
            <NumericFormat
              displayType="text"
              value={spStepSix.real_estate_sale_price}
              isNumericString
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              allowLeadingZeros={false}
            />
          ) : (
            '--'
          )}
          万円
        </Typography>
      ),
    },
    {
      label:
        p_application_header.loan_target === PurposeType.SEVEN ||
        p_application_header.loan_target === PurposeType.EIGHT
          ? 'その他（有価証券等）'
          : '有価証券売却など',
      input: (
        <Typography variant="sp_step_09_form_title" color="b_333">
          {spStepSix.other_saving_amount ? (
            <NumericFormat
              displayType="text"
              value={spStepSix.other_saving_amount}
              isNumericString
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              allowLeadingZeros={false}
            />
          ) : (
            '--'
          )}
          万円
        </Typography>
      ),
    },
    {
      label: '親族からの贈与',
      input: (
        <Typography variant="sp_step_09_form_title" color="b_333">
          {spStepSix.relative_donation_amount ? (
            <NumericFormat
              displayType="text"
              value={spStepSix.relative_donation_amount}
              isNumericString
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              allowLeadingZeros={false}
            />
          ) : (
            '--'
          )}
          万円
        </Typography>
      ),
    },
    {
      label: '本件ローン',
      input: (
        <Typography variant="sp_step_09_form_title" color="b_333">
          {spStepSix.loan_amount ? (
            <NumericFormat
              displayType="text"
              value={spStepSix.loan_amount}
              isNumericString
              thousandSeparator
              fixedDecimalScale
              allowNegative={false}
              allowLeadingZeros={false}
            />
          ) : (
            '--'
          )}
          万円
        </Typography>
      ),
    },
    p_application_header.loan_type === LoanType.TWO
      ? {
          label: 'ペアローン',
          input: (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.pair_loan_amount ? (
                <NumericFormat
                  displayType="text"
                  value={spStepSix.pair_loan_amount}
                  isNumericString
                  thousandSeparator
                  fixedDecimalScale
                  allowNegative={false}
                  allowLeadingZeros={false}
                />
              ) : (
                '--'
              )}
              万円
            </Typography>
          ),
        }
      : undefined,
    {
      label: 'その他',
      input: (
        <Stack spacing={1}>
          <Typography variant="sp_step_09_form_title" color="b_333">
            {spStepSix.other_procurement_breakdown ? (
              <NumericFormat
                displayType="text"
                value={spStepSix.other_procurement_breakdown}
                isNumericString
                thousandSeparator
                fixedDecimalScale
                allowNegative={false}
                allowLeadingZeros={false}
              />
            ) : (
              '--'
            )}
            万円
          </Typography>
          {+spStepSix.other_procurement_breakdown !== 0 && (
            <Typography variant="sp_step_09_form_title" color="b_333">
              {spStepSix.other_procurement_breakdown_content}
            </Typography>
          )}
        </Stack>
      ),
    },
  ];

  const checkTitle = () => {
    if (incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_NINE_INCOME_GUARANTORS;
    }
    if (incomeTotalizer && !hasJoinGuarantor) {
      return SPTitle.STEP_EIGHT_INCOME;
    }
    if (!incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_SEVEN_GUARANTORS;
    }
    return SPTitle.STEP_SIX;
  };

  return (
    <Stack>
      {spStepSix && (
        <Stack>
          <Stack
            direction="row"
            sx={{ px: 4, py: 1, mb: '1px', bgcolor: 'sp_primary_100_main' }}
          >
            <Typography
              variant="sp_step_text"
              color="main_white"
              sx={{ mt: 1 }}
            >
              STEP
            </Typography>
            <Typography variant="sp_label_text" color="main_white">
              {checkTitle()}
            </Typography>
          </Stack>

          <SPStepSixForm
            left={left}
            right={right}
            requiredFundChip
            bgcolor="main_white"
          />

          <Stack bgcolor="main_white" direction="row">
            <Stack
              flex={1}
              sx={{ borderRight: '0.5px solid', borderColor: 'sp_primary_60' }}
            >
              <Stack
                sx={{
                  px: 2,
                  py: '2px',
                  bgcolor: 'sp_primary_60',
                }}
              >
                <Typography variant="sp_step_06_form_title" color="main_white">
                  必要資金　合計
                </Typography>
              </Stack>
              <Stack p="4px 16px">
                <Typography variant="sp_step_09_form_title" color="b_333">
                  {numberWithCommas(getRequiredFunds())}
                  万円
                </Typography>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              sx={{ borderLeft: '0.5px solid', borderColor: 'sp_primary_60' }}
            >
              <Stack
                sx={{
                  px: 2,
                  py: '2px',
                  bgcolor: 'sp_primary_60',
                }}
              >
                <Typography variant="sp_step_06_form_title" color="main_white">
                  調達資金　合計
                </Typography>
              </Stack>
              <Stack p="4px 16px">
                <Typography variant="sp_step_09_form_title" color="b_333">
                  {numberWithCommas(getRaisedFunds())}
                  万円
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {showEditButton && (
            <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
              <Stack
                sx={{
                  paddingRight: 0,
                  paddingBottom: 0,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderBottom: 'none',
                }}
              >
                <SPButton
                  sx={{
                    py: 1,
                    px: 3,
                    borderRadius: '10px',
                    borderWidth: 2,
                    bgcolor: 'sp_primary_40',
                    border: (theme) =>
                      `1px solid ${theme?.palette?.sp_primary_100_main}`,
                  }}
                  variant="outlined"
                  fullWidth
                  onClick={() => navigate(routeNames.SPStepSix.path)}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Icons.SPPencil />
                    <Typography
                      variant="sp_contentButton_text"
                      color="sp_primary_100_main"
                    >
                      {`STEP${checkTitle().slice(0, 2)} を修正する`}
                    </Typography>
                  </Stack>
                </SPButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
