import React from 'react';
import ReactDOM from 'react-dom/client';
import NavigationRoot from 'navigations';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { theme } from 'styles';
import { store, persistor } from 'store';
import {
  ImageProvider,
  ModalSaveInfoProvider,
  NumpadProvider,
  StepLayoutProvider,
} from 'context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import {
  ModalError,
  ModalConfirmR,
  SPModalEditForm,
  AdminModalEditForm,
  SPModalKeyNotFound,
  SPModalMoveToTop,
  ModalUpdateDetailForm,
} from './components';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            body: {
              margin: 0,
              overflowX: 'hidden',
              backgroundColor: `${theme?.palette?.bg_off}`,
              overscrollBehaviorY: 'none',
            },
          }}
        />
        <ModalError />
        <ModalConfirmR />
        <SPModalMoveToTop />
        <SPModalKeyNotFound />
        <SPModalEditForm />
        <AdminModalEditForm />
        <ModalUpdateDetailForm />
        <NumpadProvider>
          <ModalSaveInfoProvider>
            <StepLayoutProvider>
              <ImageProvider>
                <ToastContainer autoClose={5 * 1000} />
                <NavigationRoot />
              </ImageProvider>
            </StepLayoutProvider>
          </ModalSaveInfoProvider>
        </NumpadProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
