import { z } from 'zod';

export const postalCodeSchema = z.object({
  address1: z.number(),
  address2: z.string(),
  address3: z.string().optional(),
  kana1: z.string(),
  kana2: z.string(),
  kana3: z.string(),
  prefCode: z.string(),
  zipcode: z.string(),
});

export const postalCodeResponseSchema = z.object({
  message: z.string().or(z.null()),
  results: z.array(postalCodeSchema),
  status: z.number(),
});

export type PostalCode = z.infer<typeof postalCodeSchema>;
export type GetPostalCodeResponse = z.infer<typeof postalCodeResponseSchema>;
export type GetPostalCodeError = {};
