import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getEditArchiveFileManager,
  getEditArchiveFileSalePerson,
  getListArchiveFileManager,
  getListArchiveFileSalePerson,
} from './thunk';

export type ArchiveFileAdminType = {
  id: string;
  file_name: string[];
  size: number;
  note: string;
  company_name: string;
  name_kanji: string;
  created_at: string;
};

export type ArchiveFileSalePersonType = {
  id: string;
  file_name: string[];
  size: number;
  note: string;
  company_name: string;
  name_kanji: string;
  created_at: string;
};

export type DetailArchiveFileType = {
  id: string;
  filename: string;
  int_id: number;
  url: string;
};

export type InitialState = {
  manager: ArchiveFileAdminType[];
  detailManagerFile: DetailArchiveFileType[];
  detailSalePersonFile: DetailArchiveFileType[];
  sale_person: ArchiveFileSalePersonType[];
  total: number;
};

export const initialState: InitialState = {
  manager: [],
  detailManagerFile: [],
  detailSalePersonFile: [],
  sale_person: [],
  total: 0,
};

const managerDocumentUploadSlice = createSlice({
  name: 'managerDocumentUpload',
  initialState,
  reducers: {
    updateManagerNote: (
      state,
      action: PayloadAction<{ id: string; note: string }>
    ) => {
      const index = state.manager.findIndex(
        (item) => item.id === action.payload.id
      );
      state.manager = [
        ...state.manager.slice(0, index),
        { ...state.manager[index], note: action.payload.note },
        ...state.manager.slice(index + 1),
      ];
    },
    updateSalePersonNote: (
      state,
      action: PayloadAction<{ id: string; note: string }>
    ) => {
      const index = state.sale_person.findIndex(
        (item) => item.id === action.payload.id
      );
      state.sale_person = [
        ...state.sale_person.slice(0, index),
        { ...state.sale_person[index], note: action.payload.note },
        ...state.sale_person.slice(index + 1),
      ];
      // state.manager = initialState.form;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListArchiveFileManager.fulfilled, (state, action) => {
      state.manager = action.payload.data;
      state.total = action.payload.data.length;
    });
    builder.addCase(getListArchiveFileSalePerson.fulfilled, (state, action) => {
      state.sale_person = action.payload.data;
      state.total = action.payload.data.length;
    });
    builder.addCase(getEditArchiveFileManager.fulfilled, (state, action) => {
      state.detailManagerFile = action.payload.data.file_name;
    });
    builder.addCase(getEditArchiveFileSalePerson.fulfilled, (state, action) => {
      state.detailSalePersonFile = action.payload.data.file_name;
    });
  },
});

export const { updateManagerNote, updateSalePersonNote } =
  managerDocumentUploadSlice.actions;

export default managerDocumentUploadSlice.reducer;
