import { FC, useCallback } from 'react';
import {
  Divider,
  Stack,
  styled,
  SxProps,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { useSelector } from 'react-redux';
import {
  draftExitsSelector,
  preliminariesSelector,
} from 'containers/AuthModal/selectors';
import { Button } from 'components';
import { Add } from '@mui/icons-material';
import { useAppDispatch } from 'hooks';
import { setPremilinary, unsetPremilinary } from 'pages/Preliminaries/slice';
import { toast } from 'react-toastify';
import { currentPremilinarySelectors } from 'pages/Preliminaries/selectors';
import { newForm } from 'pages/StepSynthesisInformation/slice';
import { newFormUpload } from 'pages/StepFour/slice';
import { getClonePreliminaries } from '../DashboardItems/thunk';

export const PreliminarieItems: FC = () => {
  const preliminaries = useSelector(preliminariesSelector);
  const draftExits = useSelector(draftExitsSelector);
  const selectedPreliminary = useSelector(currentPremilinarySelectors);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClonePreliminaries = useCallback(
    async (id: string) => {
      const result = await dispatch(getClonePreliminaries({ id }));
      if (getClonePreliminaries.fulfilled.match(result)) {
        toast.success('途中保存されているデータを成功に取得しました。');
        navigate(routeNames.StepRequiredInformation.path);
      } else {
        toast.error('エラーが発生しました。');
      }
    },
    [dispatch, navigate]
  );

  const handleInputNewPreliminary = useCallback(() => {
    dispatch(newForm());
    dispatch(newFormUpload());
    navigate(routeNames.StepRequiredInformation.path);
  }, [dispatch, navigate]);

  return (
    <Stack sx={{ mb: '13px', pr: '50px' }}>
      <Stack direction="row" spacing={3}>
        <Stack
          spacing={2}
          sx={{
            width: '100%',
            bgcolor: 'main_white',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            p: 2,
            borderRadius: '6px',
            mt: '13px',
          }}
        >
          {preliminaries?.map((preliminaries, index) =>
            preliminaries.applicant_name.map((user, i) => (
              <TableRow
                key={i}
                sx={{
                  position: 'relative',
                  bgcolor: 'main_white',
                  boxShadow: '0px 1px 2px rgba(51, 51, 51, 0.15)',
                  border: (theme) => `1px solid ${theme?.palette?.footer_bg}`,
                  borderRadius: '1px',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Stack
                  direction="row"
                  sx={{ width: '100%' }}
                  onClick={() => {
                    const {
                      applicant_ids,
                      temporary_desired_loan_amount,
                      ...restPreliminaries
                    } = preliminaries;
                    dispatch(
                      setPremilinary({
                        ...restPreliminaries,
                        applicant_id: applicant_ids[i],
                        applicant_name: user,
                        temporary_desired_loan_amount:
                          temporary_desired_loan_amount[i],
                      })
                    );
                    navigate(routeNames.Dashboard.path);
                  }}
                >
                  <TableCellStyled sx={{ width: 94, textAlign: 'right' }}>
                    <Stack height="100%" justifyContent="center">
                      <Typography
                        variant="number_applicant_item"
                        color="normal_text"
                        paddingRight={4}
                      >
                        {preliminaries.application_number}
                      </Typography>
                    </Stack>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 80 }}>
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        variant="title_application_item"
                        color="normal_text"
                      >
                        {user}
                      </Typography>
                    </Stack>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 141, flex: 1 }}>
                    <Stack height="100%" justifyContent="center">
                      <Typography
                        variant="title_application_item"
                        color="normal_text"
                      >
                        住信SBIネット銀行
                      </Typography>
                    </Stack>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 100 }}>
                    <Stack height="100%" justifyContent="center">
                      <Typography
                        variant="title_application_item"
                        color="normal_text"
                      >
                        {preliminaries.created_at}
                      </Typography>
                    </Stack>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 119 }}>
                    <Stack direction="column" justifyContent="center">
                      <Typography
                        key={index}
                        variant="title_application_item"
                        color="normal_text"
                      >
                        {preliminaries.temporary_desired_loan_amount[i]}
                      </Typography>
                    </Stack>
                    <DividerStyled orientation="vertical" />
                  </TableCellStyled>
                  <TableCellStyled sx={{ width: 110, flex: 1 }}>
                    <Stack height="100%" justifyContent="center">
                      <Typography
                        variant="number_applicant_item"
                        color="normal_text"
                      >
                        {preliminaries.store_name_kanji}
                      </Typography>
                    </Stack>
                  </TableCellStyled>
                </Stack>
                <Button
                  sx={addButtonStyle}
                  onClick={() => {
                    if (selectedPreliminary.id) {
                      handleClonePreliminaries(selectedPreliminary.id);
                    }
                  }}
                >
                  <Add sx={{ color: 'h_blugreen', width: 18, height: 18 }} />
                </Button>
              </TableRow>
            ))
          )}
          {draftExits && (
            <TableRow
              sx={{
                position: 'relative',
                bgcolor: 'main_white',
                boxShadow: '0px 1px 2px rgba(51, 51, 51, 0.15)',
                borderRadius: '1px',
                border: (theme) => `1px solid ${theme?.palette?.footer_bg}`,
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Stack
                onClick={() => {
                  navigate(routeNames.Dashboard.path);
                  dispatch(unsetPremilinary());
                }}
              >
                <TableCellStyled sx={{ borderBottom: 'none', pl: 3 }}>
                  <Typography
                    variant="title_application_item"
                    color="normal_text"
                  >
                    申込入力中
                  </Typography>
                </TableCellStyled>
              </Stack>
              <Button sx={addButtonStyle}>
                <Add sx={{ color: 'h_blugreen', width: 18, height: 18 }} />
              </Button>
            </TableRow>
          )}
        </Stack>
      </Stack>

      <Button
        sx={{
          mt: 5,
          bgcolor: 'main_white',
          width: '93px',
          minHeight: '25px',
          height: '25px',
          borderRadius: '4px',
          border: (theme) => `1px solid ${theme?.palette?.h_blugreen}`,
        }}
        startIcon={<Add sx={{ color: 'h_blugreen', width: 18, height: 18 }} />}
        startIconStyle={{ left: 7, bottom: 3 }}
        onClick={handleInputNewPreliminary}
      >
        <Typography variant="dashboard_text" color="h_blugreen">
          新規申込み
        </Typography>
      </Button>
    </Stack>
  );
};

const TableCellStyled = styled(TableCell)(() => ({
  padding: '10px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));

const addButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  right: '-46px',
  top: '50%',
  transform: 'translate(0, -50%)',
  p: 0,
  bgcolor: 'main_white',
  color: 'h_blugreen',
  minWidth: '25px',
  width: '25px',
  minHeight: '25px',
  height: '25px',
  borderRadius: '4px',
  border: (theme) => `1px solid ${theme?.palette?.h_blugreen}`,
};
