import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPArrowForwardCircle: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="9.5"
          transform="matrix(-1 0 0 1 20 0)"
          fill="#E4E7FF"
          stroke="#6B70F0"
        />
        <path
          d="M8.66406 6.7998L11.9974 10.1331L8.66406 13.4665"
          stroke="#6B70F0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
