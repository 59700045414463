import {
  Divider,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  Card,
  CardHeader,
  CardMedia,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  Link,
} from '@mui/material';
import { adminModalUpdateDetailRef, Button } from 'components';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { checkFileExisted, downloadFile, getFileExtFromUrl } from 'utils';
import { DetailArchiveFileType } from 'pages/ManagerDocumentUpload/slice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ModalDeleteConfirm } from 'pages/ManagerDocumentUpload/components/ModalDeleteConfirm';
import { updateArchiveFileSalePerson } from 'pages/ManagerDocumentUpload/thunk';
import { useAppDispatch } from 'hooks';
import { deleteSalePersonArchiveFile } from 'services/adminArchiveFile';
import { routeNames } from 'navigations/routes';

export type ManagerDocumentUploadDetailItemProps = {
  activeTab: string;
  changeApplicationStatus: (
    applicantId: string,
    applicationStatus: number
  ) => void;
  confirmUpdateLinking: (
    idLinking: string,
    idSelected: string,
    isLinking: boolean
  ) => void;
  onAssignManager: (id: string, managerId: string) => void;
  data: DetailArchiveFileType[];
  onReset: Dispatch<SetStateAction<number>>;
};

type QuizParams = {
  id: string;
};

export const ManagerDocumentUploadDetailItems: FC<
  ManagerDocumentUploadDetailItemProps
> = ({ data, onReset }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<QuizParams>();
  const [open, setOpen] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<
    DetailArchiveFileType | undefined
  >();

  const isSalePerson = useMemo(() => {
    return !location.pathname.startsWith('/manager');
  }, [location.pathname]);
  const downloadPdf = async (src: string, filename: string) => {
    downloadFile(src, filename);
  };

  const handleConfirmDelete = async () => {
    if (!!deleteItem && id) {
      const dataDelete = new FormData();
      if (data.length > 1) {
        data.forEach((item) => {
          if (item.id !== deleteItem.id) {
            dataDelete.append('upload_files[]', item.id);
          }
        });
        const result = await dispatch(
          updateArchiveFileSalePerson({ data: dataDelete, id: id })
        );
        if (updateArchiveFileSalePerson.fulfilled.match(result)) {
          toast.success('ファイルを削除しました。');
          onReset(Math.random() * 10000000);
        }
        if (updateArchiveFileSalePerson.rejected.match(result)) {
          toast.error('エラーが発生しました。');
        }
      }
      if (data.length === 1) {
        await deleteSalePersonArchiveFile(id).then((res) => {
          if (res.success) {
            toast.success('ファイルを削除しました。');
            navigate(routeNames.SalePersonDocumentUpload.path);
          } else {
            toast.error('エラーが発生しました。');
          }
        });
      }
    }
  };

  return (
    <Stack>
      {data.map((item, index) => (
        <Stack
          key={index}
          sx={{
            bgcolor: 'main_white',
            p: 2,
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              bgcolor: 'main_white',
              borderBottom: '1px solid',
              borderColor: 'bg_gray',
              alignItems: 'center',
            }}
          >
            <TableCellStyled sx={{ width: 100 }}>
              <Typography
                variant="text_preliminarie_item"
                fontSize={14}
                color="b_333"
              >
                {item.int_id}
              </Typography>
              <DividerStyled orientation="vertical" />
            </TableCellStyled>

            <TableCellStyled
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 350,
              }}
            >
              <DocumentUploadFileItem
                index={index}
                name={item.filename}
                src={item.url}
              />
              <DividerStyled orientation="vertical" />
            </TableCellStyled>
            <TableCellStyled
              sx={{
                width: 420,
              }}
            >
              <Stack direction="row" spacing={4} pl={4}>
                <Button
                  sx={{
                    width: 124,
                    minHeight: 30,
                    height: 30,
                    color: 'sp_primary_100_main',
                    borderColor: 'sp_primary_100_main',
                    bgcolor: 'main_white',
                    border: '1px solid',
                    borderRadius: '2px',
                  }}
                  onClick={async () => {
                    if (!item.filename) {
                      return;
                    }
                    const show = await checkFileExisted(item.url);
                    if (show) {
                      downloadPdf(item.url, item.filename);
                    } else {
                      adminModalUpdateDetailRef.current?.open();
                    }
                  }}
                >
                  <Typography variant="button01">ダウンロード</Typography>
                </Button>
                {isSalePerson && (
                  <Button
                    sx={{
                      width: 124,
                      minHeight: 30,
                      height: 30,
                      bgcolor: 'sh_red',
                      borderRadius: '2px',
                    }}
                    onClick={() => {
                      setOpen(true);
                      setDeleteItem(item);
                    }}
                  >
                    <Typography variant="button01">削除</Typography>
                  </Button>
                )}
              </Stack>
            </TableCellStyled>
          </TableRow>
        </Stack>
      ))}
      {!!deleteItem && (
        <ModalDeleteConfirm
          open={open}
          fileNames={[deleteItem.filename]}
          onClose={() => {
            setOpen(false);
            setDeleteItem(undefined);
          }}
          onReset={onReset}
          onConfirm={handleConfirmDelete}
        />
      )}
    </Stack>
  );
};

type DocumentUploadFileItemProps = { index: number; src: string; name: string };

const DocumentUploadFileItem: FC<DocumentUploadFileItemProps> = ({
  index,
  src,
  name,
}) => (
  <Stack
    flex={1}
    key={index}
    direction="row"
    alignItems="center"
    sx={{
      minWidth: 0,
      overflow: 'hidden',
      pl: '10px',
      width: 'calc(100% - 10px)',
    }}
  >
    {getFileExtFromUrl(name).includes('pdf') ? (
      <Link
        href={src}
        target="_blank"
        component="a"
        variant="sp_step_06_form_title"
        color="#6B70F0"
        underline="always"
      >
        {name}
      </Link>
    ) : (
      <CustomWidthTooltip
        sx={{ bgcolor: 'main_white' }}
        title={
          <Card>
            <CardHeader
              title={name}
              titleTypographyProps={{
                variant: 'title_card_preview',
                color: 'b_333',
              }}
              subheader={<Divider />}
              sx={{
                '&.MuiCardHeader-root': {
                  p: 0,
                  pt: '14px',
                  textAlign: 'center',
                },
                '& .MuiCardHeader-title': {
                  pb: '14px',
                },
              }}
            />
            <CardMedia
              component="img"
              sx={{
                bgcolor: 'main_white',
                borderRadius: 5,
                m: 'auto',
                pt: 2,
                height: 325,
                width: 512,
              }}
              image={src}
            />
          </Card>
        }
      >
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#6B70F0',
            textDecoration: 'underline',
          }}
        >
          {name}
        </Typography>
      </CustomWidthTooltip>
    )}
  </Stack>
);

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  transform: 'translate(-50%, -50%) !important',
  left: '50% !important',
  top: '50% !important',
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    backgroundColor: 'main_white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '2px',
  },
});

const TableCellStyled = styled(TableCell)(() => ({
  padding: '10px 0',
  textAlign: 'center',
  borderBottom: 'none',
  position: 'relative',
  flexDirection: 'row',
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  color: theme?.palette?.gray,
  right: 0,
  top: '25%',
  position: 'absolute',
  height: '50%',
}));
