import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdatePreliminariesRequest, UpdatePreliminariesResponse } from 'types';
import { applicationsService } from 'services';

export const updatePreliminaries = createAsyncThunk<
  UpdatePreliminariesResponse,
  UpdatePreliminariesRequest
>('preliminaries/updatePreliminaries', async (data, { rejectWithValue }) => {
  try {
    const { data: response } = await applicationsService.updatePreliminaries(
      data
    );
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
