import { dayjs, yup } from 'libs';

export const validationSchema = yup.object().shape({
  registration_date_from: yup
    .string()
    .test('validateDate', '正しく選択してください。', (value) => {
      if (!value) return true;
      return dayjs(value, 'YYYY/MM/DD', true).isValid();
    }),
  registration_date_to: yup
    .string()
    .test('validateDate', '正しく選択してください。', (value) => {
      if (!value) return true;
      return dayjs(value, 'YYYY/MM/DD', true).isValid();
    }),
  // .test(
  //   'date_before_start_date',
  //   '終了日は開始日以降の日を指定してください。',
  //   (value) => {
  //     if (!value) return true;
  //     const [year, month, day] = value.split('/');
  //     const endTime = dayjs(`${year}-${month}-${day}`);
  //     return endTime.isSameOrAfter(
  //       // @ts-ignore
  //       this.parent.registration_date_from,
  //       'date'
  //     );
  //   }
  // ),
  file_name: yup.string(),
  partners: yup.array(),
  managers: yup.array(),
  extensions: yup.array(),
  search: yup.string(),
});
