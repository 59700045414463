import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BorrowerIncomeType,
  BorrowingStatusType,
  FundsPairOffType,
} from 'constant';
import { SPStepFiveForm } from 'types';
import { deleteUser } from 'pages/SPUnsubcribed/thunk';
import { spGetDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { logout } from 'containers/AuthModal/thunk';
import { spGetSubmittedPreliminary } from 'pages/SPLogin/thunk';
import { updatePreliminary } from 'pages/SPStepNine/thunk';
import { convertImageResponse } from 'utils';

export type InitialState = {
  form: SPStepFiveForm;
};

export const initialState: InitialState = {
  form: {
    p_application_header: {
      p_applicant_people_attributes: [
        {
          borrowing_status: BorrowingStatusType.DEFAULT,
          land_rent_to_be_paid: '',
          house_rent_to_be_paid: '',
        },
      ],
      p_borrowings_attributes: [],
      completely_repayment_type: FundsPairOffType.DEFAULT,
      other_complete_repayment_type: '',
      refund_content: '',
      refund_amount: '',
      land_rent_to_be_paid_borrower: BorrowerIncomeType.DEFAULT,
      land_rent_to_be_paid: '',
      house_rent_to_be_paid_borrower: BorrowerIncomeType.DEFAULT,
      house_rent_to_be_paid: '',
    },
  },
};

const spStepFiveSlice = createSlice({
  name: 'stepSynthesis',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<SPStepFiveForm>) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    resetLandHouseBorrower: (state) => {
      const mainPerson = {
        ...state.form.p_application_header.p_applicant_people_attributes[0],
        land_rent_to_be_paid: '',
        house_rent_to_be_paid: '',
      };
      state.form.p_application_header = {
        ...state.form.p_application_header,
        p_applicant_people_attributes: [mainPerson],
        land_rent_to_be_paid_borrower: BorrowerIncomeType.DEFAULT,
        house_rent_to_be_paid_borrower: BorrowerIncomeType.DEFAULT,
      };
    },
    resetMCJStepFive: (state) => {
      state.form.p_application_header = {
        ...state.form.p_application_header,
        p_applicant_people_attributes:
          state.form.p_application_header.p_applicant_people_attributes.map(
            (people) => ({
              ...people,
              land_rent_to_be_paid: '',
              house_rent_to_be_paid: '',
            })
          ),
        completely_repayment_type: FundsPairOffType.DEFAULT,
        other_complete_repayment_type: '',
        refund_content: '',
        refund_amount: '',
        land_rent_to_be_paid_borrower: BorrowerIncomeType.DEFAULT,
        land_rent_to_be_paid: '',
        house_rent_to_be_paid_borrower: BorrowerIncomeType.DEFAULT,
        house_rent_to_be_paid: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(spGetDraft.fulfilled, (state, action) => {
      const { p_applicant_people, p_application_header, p_borrowings } =
        action.payload.data;
      let land_rent_to_be_paid_borrower = BorrowerIncomeType.DEFAULT;
      let land_rent_to_be_paid = '';
      let house_rent_to_be_paid_borrower = BorrowerIncomeType.DEFAULT;
      let house_rent_to_be_paid = '';
      const stepFiveApplicantPeopleFields = p_applicant_people.map(
        (data, index) => {
          switch (index) {
            case 0:
              if (!!data.land_rent_to_be_paid) {
                land_rent_to_be_paid_borrower =
                  BorrowerIncomeType.APPLICANT_SELF;
                land_rent_to_be_paid = data.land_rent_to_be_paid;
              }
              if (!!data.house_rent_to_be_paid) {
                house_rent_to_be_paid_borrower =
                  BorrowerIncomeType.APPLICANT_SELF;
                house_rent_to_be_paid = data.house_rent_to_be_paid;
              }
              break;
            case 1:
              if (!!data.land_rent_to_be_paid) {
                land_rent_to_be_paid_borrower = BorrowerIncomeType.INCOME_TOTAL;
                land_rent_to_be_paid = data.land_rent_to_be_paid;
              }

              if (!!data.house_rent_to_be_paid) {
                house_rent_to_be_paid_borrower =
                  BorrowerIncomeType.INCOME_TOTAL;
                house_rent_to_be_paid = data.house_rent_to_be_paid;
              }
              break;
          }
          return {
            borrowing_status: !!data.borrowing_status
              ? data.borrowing_status
              : BorrowingStatusType.DEFAULT,
            land_rent_to_be_paid: data.land_rent_to_be_paid ?? '',
            house_rent_to_be_paid: data.house_rent_to_be_paid ?? '',
          };
        }
      );
      const {
        completely_repayment_type,
        other_complete_repayment_type,
        refund_content,
        refund_amount,
      } = p_application_header;

      state.form.p_application_header = {
        ...state.form.p_application_header,
        completely_repayment_type,
        other_complete_repayment_type,
        refund_content,
        refund_amount,
        land_rent_to_be_paid_borrower,
        land_rent_to_be_paid,
        house_rent_to_be_paid_borrower,
        house_rent_to_be_paid,
        p_borrowings_attributes: p_borrowings.map((borrowing) => {
          const { repayment_schedule_image, ...restBorrowing } = borrowing;
          return {
            ...restBorrowing,
            repayment_schedule_image: !!repayment_schedule_image
              ? convertImageResponse(repayment_schedule_image)
              : [],
            self_input: borrowing.self_input === 'true',
          };
        }),
        p_applicant_people_attributes: stepFiveApplicantPeopleFields,
      };
    });
    builder.addCase(spGetSubmittedPreliminary.fulfilled, (state, action) => {
      const {
        completely_repayment_type,
        other_complete_repayment_type,
        refund_content,
        refund_amount,
        p_applicant_people_attributes,
        p_borrowings_attributes,
      } = action.payload.data;
      let land_rent_to_be_paid_borrower = BorrowerIncomeType.DEFAULT;
      let land_rent_to_be_paid = '';
      let house_rent_to_be_paid_borrower = BorrowerIncomeType.DEFAULT;
      let house_rent_to_be_paid = '';
      const stepFiveApplicantPeopleFields = p_applicant_people_attributes.map(
        (data, index) => {
          switch (index) {
            case 0:
              if (!!data.land_rent_to_be_paid) {
                land_rent_to_be_paid_borrower =
                  BorrowerIncomeType.APPLICANT_SELF;
                land_rent_to_be_paid = data.land_rent_to_be_paid;
              }
              if (!!data.house_rent_to_be_paid) {
                house_rent_to_be_paid_borrower =
                  BorrowerIncomeType.APPLICANT_SELF;
                house_rent_to_be_paid = data.house_rent_to_be_paid;
              }
              break;
            case 1:
              if (!!data.land_rent_to_be_paid) {
                land_rent_to_be_paid_borrower = BorrowerIncomeType.INCOME_TOTAL;
                land_rent_to_be_paid = data.land_rent_to_be_paid;
              }

              if (!!data.house_rent_to_be_paid) {
                house_rent_to_be_paid_borrower =
                  BorrowerIncomeType.INCOME_TOTAL;
                house_rent_to_be_paid = data.house_rent_to_be_paid;
              }
              break;
          }

          return {
            borrowing_status: data.borrowing_status,
            land_rent_to_be_paid: data.land_rent_to_be_paid,
            house_rent_to_be_paid: data.house_rent_to_be_paid,
          };
        }
      );

      state.form.p_application_header = {
        ...state.form.p_application_header,
        completely_repayment_type,
        other_complete_repayment_type,
        refund_content,
        refund_amount,
        land_rent_to_be_paid_borrower,
        land_rent_to_be_paid,
        house_rent_to_be_paid_borrower,
        house_rent_to_be_paid,
        p_borrowings_attributes: p_borrowings_attributes.map((borrowing) => {
          const { repayment_schedule_image, ...restBorrowing } = borrowing;
          return {
            ...restBorrowing,
            repayment_schedule_image: !!repayment_schedule_image
              ? convertImageResponse(repayment_schedule_image)
              : [],
            self_input: false,
          };
        }),
        p_applicant_people_attributes: stepFiveApplicantPeopleFields,
      };
    });
    builder.addCase(updatePreliminary.fulfilled, (state, action) => {
      const {
        completely_repayment_type,
        other_complete_repayment_type,
        refund_content,
        refund_amount,
        p_applicant_people_attributes,
        p_borrowings_attributes,
      } = action.payload.data;
      let land_rent_to_be_paid_borrower = BorrowerIncomeType.DEFAULT;
      let land_rent_to_be_paid = '';
      let house_rent_to_be_paid_borrower = BorrowerIncomeType.DEFAULT;
      let house_rent_to_be_paid = '';
      const stepFiveApplicantPeopleFields = p_applicant_people_attributes.map(
        (data, index) => {
          switch (index) {
            case 0:
              if (!!data.land_rent_to_be_paid) {
                land_rent_to_be_paid_borrower =
                  BorrowerIncomeType.APPLICANT_SELF;
                land_rent_to_be_paid = data.land_rent_to_be_paid;
              }
              if (!!data.house_rent_to_be_paid) {
                house_rent_to_be_paid_borrower =
                  BorrowerIncomeType.APPLICANT_SELF;
                house_rent_to_be_paid = data.house_rent_to_be_paid;
              }
              break;
            case 1:
              if (!!data.land_rent_to_be_paid) {
                land_rent_to_be_paid_borrower = BorrowerIncomeType.INCOME_TOTAL;
                land_rent_to_be_paid = data.land_rent_to_be_paid;
              }

              if (!!data.house_rent_to_be_paid) {
                house_rent_to_be_paid_borrower =
                  BorrowerIncomeType.INCOME_TOTAL;
                house_rent_to_be_paid = data.house_rent_to_be_paid;
              }
              break;
          }

          return {
            borrowing_status: data.borrowing_status,
            land_rent_to_be_paid: data.land_rent_to_be_paid,
            house_rent_to_be_paid: data.house_rent_to_be_paid,
          };
        }
      );

      state.form.p_application_header = {
        ...state.form.p_application_header,
        completely_repayment_type,
        other_complete_repayment_type,
        refund_content,
        refund_amount,
        land_rent_to_be_paid_borrower,
        land_rent_to_be_paid,
        house_rent_to_be_paid_borrower,
        house_rent_to_be_paid,
        p_borrowings_attributes: p_borrowings_attributes.map((borrowing) => {
          const { repayment_schedule_image, ...restBorrowing } = borrowing;
          return {
            ...restBorrowing,
            repayment_schedule_image: !!repayment_schedule_image
              ? convertImageResponse(repayment_schedule_image)
              : [],
            self_input: false,
          };
        }),
        p_applicant_people_attributes: stepFiveApplicantPeopleFields,
      };
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.form = initialState.form;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.form = initialState.form;
    });
  },
});

export const { saveForm, resetLandHouseBorrower, resetMCJStepFive } =
  spStepFiveSlice.actions;

export default spStepFiveSlice.reducer;
