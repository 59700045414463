import { createSlice } from '@reduxjs/toolkit';
import { getManagerContacts } from './thunk';

interface InitialState {
  form: {
    id: string;
    sender: string;
    created_at: string;
    content: string;
  }[];
}

export const initialState: InitialState = {
  form: [{ id: '', sender: '', created_at: '', content: '' }],
};

export const ManagerContactSlice = createSlice({
  name: 'managerContract',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getManagerContacts.fulfilled, (state, action) => {
      state.form = action.payload.data;
    });
  },
});

export default ManagerContactSlice.reducer;
