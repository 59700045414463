/* eslint-disable react-hooks/exhaustive-deps */
import { AdminPreliminaries, SalePersonPreliminaries } from 'services';
import { useCallback, useEffect } from 'react';
import { FormikContextType, FormikState } from 'formik';
import { RelationshipToApplicantType } from 'constant';
import get from 'lodash/get';

type Values = AdminPreliminaries | SalePersonPreliminaries;

export const useGuaranteeProvider = (
  values: Values,
  setFieldValue: FormikContextType<Values>['setFieldValue'],
  touched: FormikState<Values>['touched']
) => {
  const handleResetValue = useCallback(
    (key: string, defaultValue: string | number) => {
      if (get(values, key) === undefined) return;
      return setFieldValue(key, defaultValue);
    },
    [setFieldValue]
  );

  useResetOneRow(0, values, touched, handleResetValue);
  useResetOneRow(1, values, touched, handleResetValue);
  useResetOneRow(2, values, touched, handleResetValue);
};

function useResetOneRow(
  index: number,
  values: Values,
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useOtherRelationshipToApplicant(
    index,
    values.p_application_header,
    touched,
    handleResetValue
  );
}

function useOtherRelationshipToApplicant(
  index: number,
  values: Values['p_application_header'],
  touched: FormikState<Values>['touched'],
  handleResetValue: (key: string, defaultValue: string | number) => void
) {
  useEffect(() => {
    if (!values.p_join_guarantors_attributes?.[index]) return;
    if (
      values.p_join_guarantors_attributes?.[index]
        ?.guarantor_relationship_to_applicant &&
      values.p_join_guarantors_attributes?.[index]
        ?.guarantor_relationship_to_applicant !==
        RelationshipToApplicantType.OTHERS
    ) {
      handleResetValue(
        `p_application_header.p_join_guarantors_attributes[${index}].other_relationship_to_applicant`,
        ''
      );
    }
  }, [
    values.p_join_guarantors_attributes?.[index]
      ?.guarantor_relationship_to_applicant,
  ]);
}
