import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Stack,
  Typography,
  Modal as MuiModal,
  Divider,
  Select,
  MenuItem,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Icons, ListLinking } from 'components';

type DialogLinkingProps = PropsWithChildren<{
  open: boolean;
  idLinking: string;
  isLinking: boolean;
  textPairOrUnPair: string;
  listUnLink: ListLinking[];
  listLinked: ListLinking[];
  onClose: () => void;
  onConfirm: (
    idLinking: string,
    idSelected: string,
    isLinking: boolean
  ) => void;
}>;

export const DialogLinking: FC<DialogLinkingProps> = ({
  open,
  idLinking,
  isLinking,
  listUnLink,
  listLinked,
  onClose,
  onConfirm,
  textPairOrUnPair,
}) => {
  const [idSelected, setIdSelected] = useState<string>('');

  const listCanLinked = useMemo(() => {
    return listUnLink.filter((item) => {
      return item.value !== idLinking;
    });
  }, [idLinking, listUnLink]);

  useEffect(() => {
    isLinking && listLinked[0]
      ? setIdSelected(listLinked[0].value)
      : setIdSelected('');
  }, [isLinking, listLinked]);

  const renderIconComponent = useCallback(
    () => (
      <Icons.ArrowDown
        sx={{
          width: 16,
          height: 16,
          position: 'absolute',
          right: 12,
          top: 'calc(50% - 8px)',
          pointerEvents: 'none',
        }}
      />
    ),
    []
  );

  const onClickConfirm = useCallback(() => {
    onClose();
    onConfirm(idLinking, idSelected, isLinking);
    setIdSelected('');
  }, [idLinking, idSelected, isLinking, onClose, onConfirm]);

  const onCloseModal = () => {
    setIdSelected('');
    onClose();
  };

  return (
    <MuiModal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      disableAutoFocus
      open={open}
      onClose={onCloseModal}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 485,
          height: 232,
          backgroundColor: 'main_white',
          '@media (orientation: landscape)': {
            minWidth: 'auto',
            maxHeight: '75vh',
          },
          borderRadius: 2,
          paddingBottom: 8,
        }}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
          <Stack sx={{ width: '100%' }}>
            <Typography
              variant="text_note"
              color="b_333"
              sx={{ textAlign: 'center' }}
            >
              {textPairOrUnPair}
            </Typography>
          </Stack>
          <CloseIcon
            onClick={onCloseModal}
            fontSize="medium"
            sx={{
              color: 'b_333',
              cursor: 'pointer',
              pt: 4,
              pr: 3,
              width: 20,
              height: 20,
            }}
          />
        </Stack>
        <Divider sx={{ mt: 4 }} />
        <Stack
          justifyContent="center"
          direction="row"
          spacing={3}
          sx={{
            pt: 15,
            width: '100%',
          }}
        >
          <Typography sx={{ width: '20%', textAlign: 'left', pr: 15, pt: 1 }}>
            紐付受付番号
          </Typography>
          <Select
            IconComponent={renderIconComponent}
            onChange={(e) => setIdSelected(String(e.target.value))}
            defaultValue={isLinking && listLinked[0] ? listLinked[0].value : ''}
            sx={{
              height: 30,
              minWidth: 120,
              bgcolor: 'unset',
              textAlign: 'left',
              minHeight: 36,
              marginLeft: 0,
              '& .MuiTypography-placeHolder_style': {
                color: 'black',
                fontSize: 16,
              },
              '&&&.Mui-success fieldset': {
                borderWidth: '2px solid',
                borderRight: 'none',
                borderLeft: 'none',
                borderTop: 'none',
                borderColor: 'b_333',
                borderRadius: 0,
              },
              '&&&& fieldset': {
                borderWidth: 1,
                borderRight: 'none',
                borderLeft: 'none',
                borderTop: 'none',
                borderColor: 'b_333',
                borderRadius: 0,
              },
              '&&&&& .MuiTypography-input_style': {
                fontSize: '15px',
                lineHeight: '15px',
                fontWeight: '300',
              },
            }}
          >
            {isLinking &&
              listLinked.map((link) => (
                <MenuItem key={link.value} value={link.value}>
                  <Typography variant="input_style" color="normal_text">
                    {link.label}
                  </Typography>
                </MenuItem>
              ))}
            {!isLinking &&
              listCanLinked.map((link) => (
                <MenuItem key={link.value} value={link.value}>
                  <Typography variant="input_style" color="normal_text">
                    {link.label}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
        </Stack>
        <Stack
          justifyContent="center"
          direction="row"
          spacing={3}
          sx={{
            pt: 10,
          }}
        >
          <Button
            disabled={!idSelected}
            sx={{
              bgcolor: 'white',
              boxShadow: 'none',
              width: '270px',
              height: '36px',
              borderRadius: '2px',
              minHeight: '36px',
              border: '1px solid #6B70F0',
            }}
            onClick={onClickConfirm}
          >
            <Typography variant="button01" color="sp_primary_100_main">
              {textPairOrUnPair}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </MuiModal>
  );
};
