import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPMultiply: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 11 11" {...props}>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.74 6.84L9.5 10.6L10.86 9.26L7.08 5.5L10.86 1.72L9.5 0.38L5.74 4.14L1.96 0.38L0.62 1.72L4.4 5.5L0.64 9.24L1.98 10.58L5.74 6.84Z"
          fill="#6B70F0"
        />
      </svg>
    </SvgIcon>
  );
};
