import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPChatBubble: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 25 12" {...props}>
      <svg
        width="25"
        height="12"
        viewBox="0 0 25 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 0L25 12C25 12 25 12 0 12C7.27703 8.80825 10.0566 6.20774 12.5 0Z"
          fill="#EEF0FF"
        />
      </svg>
    </SvgIcon>
  );
};
