import {
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Icons } from 'components';
import { FC, MouseEvent } from 'react';
import { MessageItemAdmin } from 'services';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, OrderType, TableProps } from 'types';
import { MessagesItems } from '../MessageItems';

export const MessagesTable: FC<TableProps<MessageItemAdmin>> = ({
  onRequestSort,
  order,
  orderBy,
  rows,
}) => {
  const createSortHandler =
    (property: keyof MessageItemAdmin) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table>
        <TableHead
          sx={{
            display: 'flex',
            minWidth: 1024,
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              marginTop: 1,
              boxShadow: '0px 2px 8px rgba(59, 118, 129, 0.15)',
              bgcolor: 'bg_off',
              height: 40,
            }}
          >
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{
                  ...headerCellStyle,
                  ...headCell.style,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="table_header_text"
                    sx={{ whiteSpace: 'nowrap' }}
                    color="b_333"
                  >
                    {headCell.label}
                  </Typography>
                  {headCell.smallLabel && (
                    <Typography
                      variant="table_small_text"
                      sx={{ whiteSpace: 'nowrap' }}
                      color="b_333"
                    >
                      {headCell.smallLabel}
                    </Typography>
                  )}
                  {headCell !== headCells[headCells.length - 1] &&
                    headCell.id !== 'id' && (
                      <TableSort
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : undefined}
                        onClick={createSortHandler(headCell.id)}
                      />
                    )}
                </Stack>
                {headCell !== headCells[headCells.length - 1] &&
                  headCell.id !== 'id' && (
                    <Divider
                      sx={{
                        color: 'gray',
                        right: 0,
                        top: '25%',
                        position: 'absolute',
                        height: '50%',
                      }}
                      orientation="vertical"
                    />
                  )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            bgcolor: 'bg_off',
            minWidth: 1116,
            overflowX: 'auto',
            ...styleScrollBarTable,
          }}
        >
          <MessagesItems data={rows} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type SortTableProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const TableSort: FC<SortTableProps> = ({ active, direction, onClick }) => {
  return (
    <IconButton sx={{ p: '2px' }} onClick={onClick}>
      {direction === undefined && (
        <Icons.SortDefault
          sx={{
            width: 16,
            height: 13,
            color: 'gray',
          }}
        />
      )}
      {direction === 'desc' && (
        <Icons.SortDesc
          sx={{
            width: 16,
            height: 13,
            color: 'sp_primary_100_main',
          }}
          stroke="#CCCCCC"
        />
      )}
      {direction === 'asc' && (
        <Icons.SortAsc
          sx={{
            width: 16,
            height: 13,
          }}
        />
      )}
    </IconButton>
  );
};

const headCells: HeadCellProps<MessageItemAdmin>[] = [
  {
    id: 'id',
    label: '',
    style: { width: 92 },
  },
  {
    id: 'conversation_name',
    label: '申込人',
    style: { width: 226 },
  },
  {
    id: 'time_send_message',
    label: '日付',
    style: { width: 190 },
  },
  {
    id: 'content',
    label: '連絡内容',
    style: { flex: 1, justifyContent: 'center' },
  },
];

const headerCellStyle = {
  height: 40,
  p: 0,
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  border: 'none',
};
