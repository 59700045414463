import { Add } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { Button, Icons } from 'components';
import { ROWS_PER_PAGE } from 'constant';
import { AdminLayout } from 'containers';
import { useAppDispatch, useAppView } from 'hooks';
import { selectedPreliminarySelectors } from 'pages/ManagerPreliminaries/selectors';
import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ManagerPreliminariesItem, MemoItem } from 'services';
import { OrderType } from 'types';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { MemoModal } from './components/MemoModal';
import { MemoTable } from './components/MemoTable';
import { memoSelectors, totalMemoSelectors } from './selectors';
import { revertMemo } from './slice';
import { getMemos } from './thunk';

const MemoPage: FC = () => {
  const dispatch = useAppDispatch();
  const memos = useSelector(memoSelectors.selectAll);
  const totalMemo = useSelector(totalMemoSelectors);
  const [pagination, setPagination] = useState({
    page: 1,
    maxPage: Math.ceil(totalMemo / ROWS_PER_PAGE),
    fetching: false,
  });
  const { id } = useSelector(selectedPreliminarySelectors);
  const [order, setOrder] = useState<OrderType>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<keyof MemoItem>();
  const { state } = useAppView<string, ManagerPreliminariesItem>();

  useEffect(() => {
    dispatch(revertMemo());
    if (!!id) {
      dispatch(getMemos({ id: id }));
    }
  }, [dispatch, id]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof MemoItem
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    dispatch(
      getMemos({
        id: id,
        order_key: property,
        order_sort: isAsc ? 'asc' : 'desc',
      })
    );
  };

  const handleChangePage = useCallback(
    async (page: number) => {
      setPagination((prev) => ({
        ...prev,
        page: page,
        fetching: true,
      }));
      const isAsc = order === 'asc';
      await dispatch(
        getMemos({
          id: id,
          order_key: orderBy,
          order_sort: isAsc ? 'asc' : 'desc',
          page: page,
        })
      );
      setPagination((prev) => ({ ...prev, fetching: false }));
    },
    [dispatch, id, order, orderBy]
  );

  useInfiniteScroll({
    callback: (page) => handleChangePage(page),
    pagination: pagination,
    id: 'memo-table-body',
  });

  return (
    <AdminLayout
      hasDrawer={false}
      header={{
        left: (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              px: 5,
              py: 2,
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                variant="text_header_memo"
                color="normal_text"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {`メモ一覧　お客様名：${state.label}様`}
              </Typography>
            </Stack>
            <Button
              sx={{
                ml: 3,
                pl: 7,
                pr: 3,
                bgcolor: 'main_white',
                minWidth: 95,
                minHeight: '25px',
                height: '25px',
                borderRadius: '4px',
                border: (theme) => `1px solid ${theme?.palette?.h_blugreen}`,
                boxShadow: 'none',
                '&: hover': {
                  boxShadow: 'none',
                },
              }}
              startIcon={
                <Add sx={{ color: 'h_blugreen', width: 18, height: 18 }} />
              }
              startIconStyle={{ left: 12, bottom: 3 }}
              onClick={() => setOpenModal(true)}
            >
              <Typography variant="dashboard_text" color="h_blugreen">
                新規追加
              </Typography>
            </Button>
          </Stack>
        ),
        right: {
          contentPopover: null,
        },
      }}
      footer={{
        left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
        right: <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />,
        bgcolor: 'footer_bg',
      }}
    >
      <Stack sx={{ pb: '29px' }}>
        <MemoTable
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy ?? 'decs'}
          rows={memos}
          fetching={pagination.fetching}
        />
      </Stack>
      <MemoModal
        open={openModal}
        onClose={() => setOpenModal((prevState) => !prevState)}
      />
    </AdminLayout>
  );
};

export default MemoPage;
