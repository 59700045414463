import { LoanType } from 'constant';
import { z } from 'zod';

export const uploadedFileSchema = z.object({
  filename: z.string(),
  path: z.string(),
});

export const uploadedFileManagerSchema = z.object({
  id: z.string(),
  filename: z.string(),
  url: z.string(),
  hiden: z.boolean().optional(),
});

export const viewUploadedFileSchema = z.object({
  created_at: z.string(),
  updated_by: z.string(),
  path: z.string(),
  filename: z.string(),
  title: z.string().optional(),
  subTitle: z.string().optional(),
  index: z.number().optional(),
});

export const uploadedFileKeysManagerSchema = z.object({
  driver_license_front_image: z.array(uploadedFileManagerSchema), // identity_verification = 0
  driver_license_back_image: z.array(uploadedFileManagerSchema), // identity_verification = 0
  card_number_front_image: z.array(uploadedFileManagerSchema), // identity_verification = 1
  // card_number_back_image: z.array(uploadedFileManagerSchema), // identity_verification = 1
  resident_register_front_image: z.array(uploadedFileManagerSchema), // identity_verification = 2
  resident_register_back_image: z.array(uploadedFileManagerSchema), // identity_verification = 2
  insurance_file: z.array(uploadedFileManagerSchema),
  insurance_file_back_image: z.array(uploadedFileManagerSchema),
  employment_agreement_file: z.array(uploadedFileManagerSchema),
  first_withholding_slip_file: z.array(uploadedFileManagerSchema),
  second_withholding_slip_file: z.array(uploadedFileManagerSchema),
  first_income_file: z.array(uploadedFileManagerSchema),
  second_income_file: z.array(uploadedFileManagerSchema),
  third_income_file: z.array(uploadedFileManagerSchema),
  other_document_file: z.array(uploadedFileManagerSchema),
  financial_statement_1_file: z.array(uploadedFileManagerSchema),
  financial_statement_2_file: z.array(uploadedFileManagerSchema),
  financial_statement_3_file: z.array(uploadedFileManagerSchema),
  business_tax_return_1_file: z.array(uploadedFileManagerSchema),
  business_tax_return_2_file: z.array(uploadedFileManagerSchema),
  business_tax_return_3_file: z.array(uploadedFileManagerSchema),
  residence_file: z.array(uploadedFileManagerSchema),
  residence_file_back_image: z.array(uploadedFileManagerSchema),
});

export const uploadedFileKeysHeaderSchema = z.object({
  property_information_file_index: z.array(uploadedFileManagerSchema),
  manager_property_information_file_index: z.array(uploadedFileManagerSchema),
  business_card_index: z.array(uploadedFileManagerSchema),
});

export const repaymentScheduleImageItem = z.object({
  borrowing_id: z.number(),
  files: z.array(
    z.object({
      id: z.string(),
      filename: z.string(),
      path: z.string(),
      updated_by: z.string(),
      created_at: z.string(),
      title: z.string().optional(),
      subTitle: z.string().optional(),
    })
  ),
});
export const uploadedFileKeysBorrowingSchema = z.object({
  repayment_schedule_image_index__0: z.array(uploadedFileManagerSchema),
  repayment_schedule_image_index__1: z.array(uploadedFileManagerSchema),
  repayment_schedule_image_index__2: z.array(uploadedFileManagerSchema),
  repayment_schedule_image_index__3: z.array(uploadedFileManagerSchema),
  repayment_schedule_image_index__4: z.array(uploadedFileManagerSchema),
  repayment_schedule_image_index__5: z.array(uploadedFileManagerSchema),
  repayment_schedule_image_index__6: z.array(uploadedFileManagerSchema),
  repayment_schedule_image_index__7: z.array(uploadedFileManagerSchema),
});

export const viewUploadedFilesResponseSchema = z.object({
  driver_license_front_image: z.array(viewUploadedFileSchema), // identity_verification = 0
  driver_license_back_image: z.array(viewUploadedFileSchema), // identity_verification = 0
  card_number_front_image: z.array(viewUploadedFileSchema), // identity_verification = 1
  // card_number_back_image: z.array(viewUploadedFileSchema), // identity_verification = 1
  resident_register_front_image: z.array(viewUploadedFileSchema), // identity_verification = 2
  resident_register_back_image: z.array(viewUploadedFileSchema), // identity_verification = 2
  insurance_file: z.array(viewUploadedFileSchema),
  insurance_file_back_image: z.array(viewUploadedFileSchema),
  employment_agreement_file: z.array(viewUploadedFileSchema),
  first_withholding_slip_file: z.array(viewUploadedFileSchema),
  second_withholding_slip_file: z.array(viewUploadedFileSchema),
  first_income_file: z.array(viewUploadedFileSchema),
  second_income_file: z.array(viewUploadedFileSchema),
  third_income_file: z.array(viewUploadedFileSchema),
  other_document_file: z.array(viewUploadedFileSchema),
  financial_statement_1_file: z.array(viewUploadedFileSchema),
  financial_statement_2_file: z.array(viewUploadedFileSchema),
  financial_statement_3_file: z.array(viewUploadedFileSchema),
  business_tax_return_1_file: z.array(viewUploadedFileSchema),
  business_tax_return_2_file: z.array(viewUploadedFileSchema),
  business_tax_return_3_file: z.array(viewUploadedFileSchema),
  property_information_file: z.array(viewUploadedFileSchema),
  manager_property_information_file: z.array(viewUploadedFileSchema),
  residence_file: z.array(viewUploadedFileSchema),
  residence_file_back_image: z.array(viewUploadedFileSchema),
  repayment_schedule_image: z.array(repaymentScheduleImageItem),
  business_card: z.array(viewUploadedFileSchema),
});

export const getUploadedFilesRequestSchema = z.object({
  id: z.string(),
});

export const getUploadedFilesManagerRequestSchema = z.object({
  id: z.string(),
  applicant_id: z.string(),
});

export const getUploadedFilesResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    loan_type: z.nativeEnum(LoanType),
    main_first_borrowing_file: z.array(uploadedFileSchema),
    main_first_income_file: z.array(uploadedFileSchema),
    main_first_pamphlet_file: z.array(uploadedFileSchema),
    main_insurance_file: z.array(uploadedFileSchema),
    main_personal_information_file: z.array(uploadedFileSchema),
    main_referral_agency_file: z.array(uploadedFileSchema),
    main_residence_file: z.array(uploadedFileSchema),
    main_second_borrowing_file: z.array(uploadedFileSchema),
    main_second_income_file: z.array(uploadedFileSchema),
    main_second_pamphlet_file: z.array(uploadedFileSchema),
    main_third_income_file: z.array(uploadedFileSchema),
    pair_first_borrowing_file: z.array(uploadedFileSchema),
    pair_first_income_file: z.array(uploadedFileSchema),
    pair_first_pamphlet_file: z.array(uploadedFileSchema),
    pair_insurance_file: z.array(uploadedFileSchema),
    pair_personal_information_file: z.array(uploadedFileSchema),
    pair_referral_agency_file: z.array(uploadedFileSchema),
    pair_residence_file: z.array(uploadedFileSchema),
    pair_second_borrowing_file: z.array(uploadedFileSchema),
    pair_second_income_file: z.array(uploadedFileSchema),
    pair_second_pamphlet_file: z.array(uploadedFileSchema),
    pair_third_income_file: z.array(uploadedFileSchema),
  }),
});

export const getUploadedFilesManagerResponseSchema = z.object({
  success: z.boolean(),
  data: uploadedFileKeysManagerSchema,
});

export type UploadedFiles = z.infer<typeof uploadedFileSchema>;
export type UploadedFilesManager = z.infer<typeof uploadedFileManagerSchema>;
export type UploadedFileKeys = z.infer<typeof uploadedFileKeysManagerSchema>;
export type UploadedFileKeysHeader = z.infer<
  typeof uploadedFileKeysHeaderSchema
>;
export type UploadedFileKeysBorrowing = z.infer<
  typeof uploadedFileKeysBorrowingSchema
>;
export type ViewUploadedFile = z.infer<typeof viewUploadedFileSchema>;

export type RepaymentScheduleImage = z.infer<typeof repaymentScheduleImageItem>;
export type GetUploadedFilesRequest = z.infer<
  typeof getUploadedFilesRequestSchema
>;
export type GetUploadedFilesManagerRequest = z.infer<
  typeof getUploadedFilesManagerRequestSchema
>;
export type GetUploadedFilesResponse = z.infer<
  typeof getUploadedFilesResponseSchema
>;
export type GetUploadedFilesManagerResponse = z.infer<
  typeof getUploadedFilesManagerResponseSchema
>;
export const getViewUploadedFilesResponseSchema = z.object({
  success: z.boolean(),
  data: viewUploadedFilesResponseSchema,
});
export type GetViewUploadedFilesResponse = z.infer<
  typeof getViewUploadedFilesResponseSchema
>;

export type GetUploadedFilesError = {};
