import { FC, useState, useCallback } from 'react';
import { Divider, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { dayjs } from 'libs';
import { MemoItem } from 'services';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { selectedPreliminarySelectors } from 'pages/ManagerPreliminaries/selectors';
import { memoDetail } from 'pages/Memo/thunk';
import { MemoModal } from '../MemoModal';

export type MemoItemsProps = {
  data: MemoItem[];
};

export const MemoItems: FC<MemoItemsProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const [detailMemo, setDetailMemo] = useState<MemoItem>();
  const [open, setOpen] = useState<boolean>(false);
  const { id } = useSelector(selectedPreliminarySelectors);
  const getDetailMemo = useCallback(
    async (preliminaryId: string, memoId: string) => {
      const result = await dispatch(
        memoDetail({ preliminaryId: preliminaryId, memoId: memoId })
      );
      if (memoDetail.fulfilled.match(result)) {
        setDetailMemo(result.payload.data);
        setOpen(true);
      }
    },
    [dispatch]
  );
  return (
    <>
      <Stack sx={{ mb: '13px' }}>
        {data.map((item) => (
          <Stack
            key={item.id}
            sx={{
              minWidth: 1100,
            }}
            onClick={() => getDetailMemo(id, item.id)}
          >
            <TableRow
              sx={{
                bgcolor: 'main_white',
                justifyContent: 'space-between',
                borderBottom: '1px solid #CCCCCC',
                alignItems: 'center',
                display: 'flex',
                cursor: 'pointer',
                height: 60,
                '&: hover': {
                  bgcolor: 'current_blue',
                },
              }}
            >
              <TableCell
                sx={{
                  textAlign: 'center',
                  width: 97,
                  padding: '10px 0',
                  borderBottom: 'none',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Typography variant="text_memo_list" color="b_333">
                  {dayjs(item.created_at).format('MM/DD')}
                </Typography>
                <Divider
                  sx={{
                    color: 'gray',
                    right: 0,
                    top: '25%',
                    position: 'absolute',
                    height: '50%',
                  }}
                  orientation="vertical"
                />
              </TableCell>
              <TableCell
                sx={{
                  width: 180,
                  padding: '10px 0',
                  textAlign: 'center',
                  borderBottom: 'none',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Typography variant="title_application_item" color="b_333">
                  {item.manager_name}
                </Typography>
                <Divider
                  sx={{
                    color: 'gray',
                    right: 0,
                    top: '25%',
                    position: 'absolute',
                    height: '50%',
                  }}
                  orientation="vertical"
                />
              </TableCell>
              <TableCell
                sx={{
                  width: 201,
                  flex: 1,
                  pl: '10px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  padding: '10px',
                  textAlign: 'left',
                  borderBottom: 'none',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Typography variant="text_memo_list" color="b_333">
                  {item.memo}
                </Typography>
              </TableCell>
            </TableRow>
          </Stack>
        ))}
      </Stack>
      <MemoModal open={open} onClose={() => setOpen(false)} memo={detailMemo} />
    </>
  );
};
