import {
  ClipboardEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { AdminInput, Button, Icons } from 'components';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import {
  getDynamicOptions,
  getMasterBanks,
} from 'pages/StepSynthesisInformation/thunk';
import { adminBackground, logoCompany } from 'assets';
import { LoginError } from 'services';
import { validationSchema } from './validationSchema';
import { managerLogin } from './thunk';

const LoginPage: FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    async onSubmit(user) {
      const result = await dispatch(
        managerLogin({
          manager: { email: user.email, password: user.password },
        })
      );
      if (!managerLogin.fulfilled.match(result)) {
        const errorResponse = result.payload as LoginError;
        if (errorResponse.errors.every((e) => e.key !== 'locked')) {
          setErrorMessage('メールアドレスまたはパスワードが正しくありません。');
          setError(true);
        } else {
          setErrorMessage(
            `ログイン失敗でアカウントがロックされました。\nアカウントロックの解除は、ログイン画面の「パスワードを忘れた方はこちらから設定をお願いします」からお進みください。`
          );
          setError(true);
        }
      }
      if (managerLogin.fulfilled.match(result)) {
        dispatch(getMasterBanks());
        dispatch(getDynamicOptions());
        const preliminaryId = sessionStorage.getItem('preliminary_id');
        if (preliminaryId) {
          navigate(
            `${routeNames.AdminEditPreliminary.path}?preliminary_id=${preliminaryId}`
          );
        } else {
          navigate(routeNames.ManagerPreliminaries.path);
        }
        sessionStorage.removeItem('preliminary_id');
      }
    },
  });

  const clearError = useCallback(() => setError(false), []);

  const onLoginEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const onPreventPassword = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <FormikProvider value={formik}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: `url(${adminBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Stack
          sx={{
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            bgcolor: 'main_white',
            borderRadius: '5px',
            width: '430px',
          }}
          onKeyPress={onLoginEnter}
        >
          <Stack
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              mt: 10,
            }}
          >
            <Avatar
              src={logoCompany}
              variant="square"
              sx={{ height: '64px', width: '272px' }}
            />
            <Typography variant="heading01" mt={5}>
              ログイン
            </Typography>
          </Stack>
          {error && (
            <Stack
              sx={{ mt: 5, px: 5 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Icons.CircleExclamation
                sx={{
                  width: 16,
                  height: 16,
                  marginRight: 1,
                }}
              />
              <Typography
                variant="upload_image_text"
                color="sp_secondary_01"
                sx={{
                  fontSize: 13,
                  lineHeight: '16px',
                  letterSpacing: 1.5,
                  whiteSpace: 'break-spaces',
                }}
              >
                {errorMessage}
              </Typography>
            </Stack>
          )}
          <Stack
            sx={{
              mt: 5,
              p: '0px 40px',
            }}
          >
            <Stack
              sx={{
                justifyContent: 'center',
                pt: '5px',
              }}
            >
              <Stack>
                <Typography variant="headline_input">メールアドレス</Typography>
              </Stack>
              <Stack sx={{ mt: '4px', maxWidth: 350 }}>
                <AdminInput
                  name="email"
                  type="email"
                  placeholder="入力してください"
                  InputProps={{
                    sx: {
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'sp_secondary_01',
                        },
                    },
                  }}
                  onFocus={clearError}
                  error={
                    error || (formik.touched.email && !!formik.errors.email)
                  }
                />
              </Stack>
              <Stack sx={{ mt: '20px' }}>
                <Typography variant="headline_input">パスワード</Typography>
              </Stack>
              <Stack sx={{ mt: '4px', maxWidth: 350 }}>
                <AdminInput
                  name="password"
                  placeholder="入力してください"
                  InputProps={{
                    sx: {
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: 'sp_secondary_01',
                        },
                    },
                  }}
                  onFocus={clearError}
                  error={
                    error ||
                    (formik.touched.password && !!formik.errors.password)
                  }
                  disabled={false}
                  hasPassword
                  onCut={onPreventPassword}
                  onCopy={onPreventPassword}
                  onPaste={onPreventPassword}
                />
              </Stack>
              <Stack
                sx={{ mt: '40px' }}
                direction="row"
                justifyContent="center"
              >
                <Button
                  disabled={formik.isSubmitting}
                  sx={{
                    bgcolor: 'white',
                    boxShadow: 'none',
                    width: '200px',
                    height: '36px',
                    borderRadius: '2px',
                    minHeight: '36px',
                    border: '1px solid #6B70F0',
                  }}
                  onClick={() => formik.handleSubmit()}
                >
                  <Typography variant="button01" color="sp_primary_100_main">
                    ログイン
                  </Typography>
                </Button>
              </Stack>
              <Stack
                sx={{
                  mt: 5,
                  mb: 10,
                  textAlign: 'center',
                }}
              >
                <Typography
                  variant="table_header_text"
                  color="b_333"
                  component="p"
                  sx={{
                    lineHeight: '18px',
                  }}
                >
                  パスワードを忘れた方は
                  <Typography
                    onClick={() => {
                      if (location.pathname === '/login') {
                        navigate(routeNames.ResetPassword.path);
                      } else {
                        navigate(routeNames.ResetPasswordManager.path);
                      }
                    }}
                    color="h_blugreen"
                    component="span"
                    variant="table_header_text"
                    sx={{
                      lineHeight: '18px',
                      fontWeight: 600,
                      cursor: 'pointer',
                    }}
                  >
                    こちらから設定
                  </Typography>
                  をお願いします
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </FormikProvider>
  );
};

export default LoginPage;
