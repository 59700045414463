import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Slash: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="-2 -4 16 16" {...props}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15 0.5L0.5 15" stroke="#444444" />
      </svg>
    </SvgIcon>
  );
};
