import { FC } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { Icons } from 'components';

export type HeaderProps = {
  title?: string;
};
export const Header: FC<HeaderProps> = ({ title }) => (
  <Stack bgcolor="bg_off">
    <Container sx={{ px: { mobile: 4, tablet: 10 }, pt: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="text_note_landing" color="normal_text">
          {title}
        </Typography>
        <Icons.LogoHeader sx={{ width: 120, color: 'h_blugreen' }} />
      </Stack>
    </Container>
  </Stack>
);
