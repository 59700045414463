import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SPStep06: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.89062 5.64375L6.21406 1.76344C5.66094 1.01719 6.2375 0 7.14219 0H17.8578C18.7625 0 19.2969 1.01719 18.7859 1.76344L16.0672 5.64375C16.3016 5.76563 16.5125 5.86406 16.7328 6.05625C19.5734 8.00625 24.5 12.3234 24.5 19.5C24.5 21.9844 22.4844 24 20 24H5C2.51469 24 0.5 21.9844 0.5 19.5C0.5 12.3234 5.42656 8.00625 8.26719 6.05625C8.4875 5.86406 8.69844 5.76563 8.89062 5.64375ZM9.29375 6.27656C6.67813 7.95 1.25 12.1688 1.25 19.5C1.25 21.5719 2.92906 23.25 5 23.25H20C22.0719 23.25 23.75 21.5719 23.75 19.5C23.75 12.1688 18.3219 7.95 15.7062 6.27656L15.275 5.95781H9.725L9.29375 6.27656ZM15.4672 5.20781L18.1672 1.33781C18.3359 1.08891 18.1578 0.707813 17.8578 0.707813H7.14219C6.84219 0.707813 6.66406 1.08891 6.83281 1.33781L9.53281 5.20781H15.4672Z"
          fill="url(#paint0_linear_6976_58999)"
        />
        <path
          d="M13.0997 14.525L16.5414 10.1096L16.5415 10.1094C16.6433 9.97835 16.6094 9.79601 16.4743 9.70371C16.344 9.61312 16.1568 9.63902 16.0585 9.76548M13.0997 14.525L16.0586 9.76535L16.0585 9.76548M13.0997 14.525H15.5C15.659 14.525 15.8 14.6481 15.8 14.8125C15.8 14.9768 15.659 15.1 15.5 15.1H12.8V16.775H15.5C15.659 16.775 15.8 16.8981 15.8 17.0625C15.8 17.2268 15.659 17.35 15.5 17.35H12.8V20.0625C12.8 20.2268 12.659 20.35 12.5 20.35C12.3409 20.35 12.2 20.2268 12.2 20.0625V17.35H9.4775C9.40446 17.35 9.33196 17.3166 9.2788 17.2703C9.22509 17.2235 9.1775 17.1512 9.1775 17.0625C9.1775 16.9737 9.22509 16.9014 9.2788 16.8546C9.33196 16.8083 9.40446 16.775 9.4775 16.775H12.2V15.1H9.4775C9.40446 15.1 9.33196 15.0666 9.2788 15.0203C9.22509 14.9735 9.1775 14.9012 9.1775 14.8125C9.1775 14.7237 9.22509 14.6514 9.2788 14.6046C9.33196 14.5583 9.40446 14.525 9.4775 14.525H11.9003M13.0997 14.525H11.9003M16.0585 9.76548L12.5 14.331L8.94036 9.76534L8.94035 9.76533C8.84163 9.63873 8.65691 9.61342 8.52544 9.70358L8.52543 9.70359C8.39045 9.79618 8.35784 9.97879 8.45962 10.1095L8.45964 10.1095L11.9003 14.525M16.0585 9.76548L11.9003 14.525"
          fill="url(#paint1_linear_6976_58999)"
          stroke="url(#paint2_linear_6976_58999)"
          strokeWidth="0.2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_6976_58999"
            x1="4.19582"
            y1="19.0642"
            x2="22.5346"
            y2="0.540314"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_6976_58999"
            x1="9.73204"
            y1="18.0905"
            x2="17.4883"
            y2="12.1208"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_6976_58999"
            x1="9.73204"
            y1="18.0905"
            x2="17.4883"
            y2="12.1208"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3C48C4" />
            <stop offset="0.505208" stopColor="#6A75DE" />
            <stop offset="0.9999" stopColor="#AD92D0" />
            <stop offset="1" stopColor="#AD92D0" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
