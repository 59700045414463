import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const NewBuilding: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 150 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="150"
      height="69"
      viewBox="0 0 150 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M144.238 15.2691C137.199 15.2691 131.92 10.1444 131.92 1C131.92 10.1444 126.641 15.2691 119.602 15.2691C126.641 15.2691 131.92 20.3938 131.92 29.5383C131.92 20.3938 137.2 15.2691 144.238 15.2691Z"
        fill="#FFDB43"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.62 53.3786C143.203 53.3786 139.14 49.435 139.14 42.3984C139.14 49.435 135.078 53.3786 129.66 53.3786C135.076 53.3786 139.14 57.3221 139.14 64.3587C139.14 57.3221 143.202 53.3786 148.62 53.3786Z"
        fill="#FFDB43"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37 13.5C32.7148 13.5 29.5 10.4472 29.5 5C29.5 10.4472 26.2864 13.5 22 13.5C26.2852 13.5 29.5 16.5528 29.5 22C29.5 16.5528 32.7136 13.5 37 13.5Z"
        fill="#FFDB43"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.4401 19.9957C68.0237 19.9957 63.9603 16.0522 63.9603 9.01562C63.9603 16.0522 59.8984 19.9957 54.4805 19.9957C59.8968 19.9957 63.9603 23.9393 63.9603 30.9759C63.9603 23.9393 68.0221 19.9957 73.4401 19.9957Z"
        fill="#FFDB43"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7602 45.5526C25.1151 45.5526 17.8809 38.5306 17.8809 26C17.8809 38.5306 10.6467 45.5526 1 45.5526C10.6451 45.5526 17.8809 52.5746 17.8809 65.1052C17.8809 52.5746 25.1151 45.5526 34.7602 45.5526Z"
        fill="#FFDB43"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.24 15H91.3359V67.9939H102.24H113.151V15H102.24Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M108.072 50.6367H104.156V54.552H108.072V50.6367Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M100.325 50.6367H96.4102V54.552H100.325V50.6367Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M108.072 42.6875H104.156V46.6028H108.072V42.6875Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M100.325 42.6875H96.4102V46.6028H100.325V42.6875Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M108.072 34.7324H104.156V38.6477H108.072V34.7324Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M100.325 34.7324H96.4102V38.6477H100.325V34.7324Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M108.072 26.8516H104.156V30.7669H108.072V26.8516Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M100.325 26.8516H96.4102V30.7669H100.325V26.8516Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M108.072 18.9658H104.156V22.8811H108.072V18.9658Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M100.325 18.9658H96.4102V22.8811H100.325V18.9658Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M108.069 58.5869H103.23V67.9949H108.069V58.5869Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M101.248 58.5869H96.4102V67.9949H101.248V58.5869Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M126.31 22.8867H113.148V68.0001H126.31V22.8867Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M121.685 50.6357H117.77V54.5511H121.685V50.6357Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M121.685 42.6865H117.77V46.6018H121.685V42.6865Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M121.685 34.7314H117.77V38.6468H121.685V34.7314Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M121.685 26.7764H117.77V30.6917H121.685V26.7764Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M78.1669 67.9941H91.3281V22.8808H78.1669V67.9941Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M86.7121 50.6377H82.7969V54.553H86.7121V50.6377Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M86.7121 42.6885H82.7969V46.6038H86.7121V42.6885Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M86.7121 34.7334H82.7969V38.6487H86.7121V34.7334Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M86.7121 26.7842H82.7969V30.6995H86.7121V26.7842Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M44.6657 30.6416L61.5613 42.4718H27.77L44.6657 30.6416Z"
        fill="white"
      />
      <path d="M25.8516 42.2637V67.9995H64.0402V42.2637" fill="white" />
      <path
        d="M25.8516 42.2637V67.9995H64.0402V42.2637"
        stroke="#444444"
        strokeWidth="1.7"
        strokeLinejoin="round"
      />
      <path
        d="M41.8867 67.9947V47.0752H32.8046V67.9947H41.8867Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M56.5392 47.0801H47.457V56.1785H56.5392V47.0801Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M51.9961 47.0801V56.1722"
        stroke="#444444"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M47.457 51.626H56.5392"
        stroke="#444444"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
      <path
        d="M69.0056 47.1638L44.6742 29.7511L20.3363 47.1638L17.625 43.3574L44.6742 24L71.7233 43.3574L69.0056 47.1638Z"
        fill="white"
        stroke="#444444"
        strokeWidth="1.7"
        strokeMiterlimit="10"
      />
    </svg>
  </SvgIcon>
);
