import { FC } from 'react';
import { UserLayout } from 'containers';
import { PreliminarieTable } from 'components';
import { Stack } from '@mui/material';

const PreliminariesPage: FC = () => {
  return (
    <UserLayout
      header={{
        left: {
          title: 'お申込みの選択',
        },
      }}
      hasFooter={false}
      hasDrawer={false}
    >
      <Stack sx={{ ml: 4, pb: '29px' }}>
        <PreliminarieTable />
      </Stack>
    </UserLayout>
  );
};

export default PreliminariesPage;
