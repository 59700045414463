import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Icons, SPButton, SPInputField, SPModal } from 'components';
import { useFormikContext } from 'formik';
import { SPStepOneForm } from 'types';
import { onlyNumbers } from 'utils';

type InputProcurementType = {
  bonus_repayment_amount: string;
  bonus_times: string;
  borrowing_period: string;
};

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
}>;

export const SPCalculatorModal: FC<ModalProps> = ({ open, onClose }) => {
  const [isFocusBonusTime, setIsFocusBonusTime] = useState<boolean>(false);
  const [isFocusBorrowingperiod, setIsFocusBorrowingperiod] =
    useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<SPStepOneForm>();

  const { bonus_repayment_amount, bonus_times, borrowing_period } =
    values.p_application_header;

  useEffect(() => {
    setFieldValue(
      'p_application_header.bonus_repayment',
      +calculateEqual({ bonus_repayment_amount, bonus_times, borrowing_period })
    );
  }, [bonus_repayment_amount, bonus_times, borrowing_period, setFieldValue]);

  const bonusTimesLimit = useMemo(() => {
    if (
      !!values.p_application_header.bonus_times &&
      +values.p_application_header.bonus_times > 2 &&
      !isFocusBonusTime
    )
      return '※年間のボーナス回数は最大2回まで入力してください。';
    if (
      !!values.p_application_header.bonus_times &&
      +values.p_application_header.bonus_times === 0 &&
      !isFocusBonusTime
    )
      return '※年間のボーナス回数は0回で入力しくないでください。';
  }, [values.p_application_header.bonus_times, isFocusBonusTime]);

  const borrowingPeriodLimit = useMemo(() => {
    if (
      !!values.p_application_header.borrowing_period &&
      +values.p_application_header.borrowing_period > 50 &&
      !isFocusBorrowingperiod
    )
      return '※借入期間は50年以内で入力してください。';
    if (
      !!values.p_application_header.borrowing_period &&
      +values.p_application_header.borrowing_period === 0 &&
      !isFocusBorrowingperiod
    )
      return '※借入時間は1年〜50年で入力してください。';
  }, [values.p_application_header.borrowing_period, isFocusBorrowingperiod]);

  return (
    <SPModal
      open={open}
      onClose={onClose}
      icon={<Icons.SPCalculator sx={{ width: 35, height: 35 }} />}
      title="ボーナス返済分を試算しましょう"
      disableBackDropClick
      sxPadding="2px"
      footer={
        <Stack
          spacing={6}
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: 359 }}
        >
          <Stack sx={{ overflowY: 'scroll', maxHeight: '37vh' }} spacing={2}>
            <Stack alignItems="center" sx={{ mb: 6 }}>
              <Typography
                variant="sp_change_password_note"
                color="b_333"
                whiteSpace="nowrap"
              >
                {`※ボーナス返済分は融資金額の50%以内です。`}
              </Typography>
            </Stack>
            <Stack
              sx={{
                bgcolor: 'sp_primary_20',
                borderRadius: '8px',
                p: 3,
              }}
              spacing={3}
            >
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <Stack spacing={1}>
                  <Stack>
                    <Typography variant="upload_image_title" color="b_333">
                      1回あたりの
                    </Typography>
                    <Typography variant="upload_image_title" color="b_333">
                      ボーナス返済額
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SPInputField
                      name="p_application_header.bonus_repayment_amount"
                      sx={{ minWidth: 80, width: 80 }}
                      placeholder="0"
                      align="right"
                      numeric
                      maxLength={4}
                      useNumberFormat
                      toFixedNumber={false}
                      onKeyPress={(event) => onlyNumbers(event)}
                    />
                    <Typography
                      variant="sp_unit_select_text"
                      whiteSpace="nowrap"
                      color="b_333"
                    >
                      万円
                    </Typography>
                    <Icons.SPMultiply sx={{ width: '14px', height: '26px' }} />
                  </Stack>
                </Stack>
                <Stack sx={{ ml: 2 }} spacing={1}>
                  <Stack>
                    <Typography variant="upload_image_title" color="b_333">
                      年間の
                    </Typography>
                    <Typography variant="upload_image_title" color="b_333">
                      ボーナス回数
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SPInputField
                      name="p_application_header.bonus_times"
                      sx={{ minWidth: 54, width: 54 }}
                      placeholder="0"
                      align="right"
                      numeric
                      readOnly
                      maxLength={2}
                      showError={false}
                      onKeyPress={(event) => onlyNumbers(event)}
                      onFocus={() => setIsFocusBonusTime(true)}
                      onBlur={() => setIsFocusBonusTime(false)}
                    />
                    <Typography variant="sp_unit_select_text" color="b_333">
                      回
                    </Typography>
                    <Icons.SPMultiply sx={{ width: '14px', height: '26px' }} />
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="upload_image_title" color="b_333">
                    借入期間
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SPInputField
                      name="p_application_header.borrowing_period"
                      sx={{ minWidth: 54, width: 54 }}
                      placeholder="0"
                      align="right"
                      numeric
                      maxLength={2}
                      showError={false}
                      onKeyPress={(event) => onlyNumbers(event)}
                      onFocus={() => setIsFocusBorrowingperiod(true)}
                      onBlur={() => setIsFocusBorrowingperiod(false)}
                    />
                    <Typography variant="sp_unit_select_text" color="b_333">
                      年
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Typography variant="text_error" color="sp_secondary_01">
                {bonusTimesLimit}
              </Typography>
              <Typography variant="text_error" color="sp_secondary_01">
                {borrowingPeriodLimit}
              </Typography>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Icons.SPEqual sx={{ width: '12px', height: '7px' }} />
                <Typography
                  variant="buttonAddImage"
                  color="sp_primary_100_main"
                >
                  ボーナス返済分
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <SPInputField
                    name="p_application_header.bonus_repayment"
                    sx={{
                      minWidth: 105,
                      width: 105,
                      bgcolor: 'sp_primary_60',
                      borderRadius: 1,
                      '.MuiInputBase-input': {
                        p: 4,
                        maxHeight: 16,
                        fontWeight: '300',
                        fontSize: 16,
                        backgroundColor: 'sp_primary_60',
                        color: 'main_white',
                      },
                      '&&&& fieldset': {
                        borderWidth: 0,
                        boxShadow: 'none',
                      },
                    }}
                    placeholder="0"
                    align="right"
                    readOnly
                    numeric
                    useNumberFormat
                    toFixedNumber={false}
                  />
                  <Typography variant="sp_unit_select_text" color="b_333">
                    万円
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" sx={{ px: 4 }}>
            <Typography variant="sp_note_text" color="b_333">
              （例）
            </Typography>
            <Stack>
              <Typography variant="sp_note_text" color="b_333">
                1回あたりのボーナス返済額15万円（年2回）
              </Typography>
              <Typography variant="sp_note_text" color="b_333">
                借入期間30年の場合
              </Typography>
              <Typography variant="sp_note_text" color="b_333">
                15万円×2回×30年＝ボーナス返済900万円
              </Typography>
            </Stack>
          </Stack>
          <SPButton
            sx={{
              width: '160px',
              height: '40px',
              bgcolor: 'sp_primary_40',
            }}
            endIcon={<Icons.ArrowForward stroke="#6B70F0" />}
            onClick={onClose}
          >
            <Typography
              variant="SP_multiple_checkbox_label"
              color="sp_primary_100_main"
            >
              とじる
            </Typography>
          </SPButton>
        </Stack>
      }
    />
  );
};

const calculateEqual = ({
  bonus_repayment_amount,
  bonus_times,
  borrowing_period,
}: InputProcurementType) => {
  return +bonus_repayment_amount * +bonus_times * +borrowing_period || '';
};
