import { FC, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageContext } from 'context';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { spStepSevenSelector } from 'pages/SPStepSeven/selectors';
import {
  FileATaxReturnType,
  IdentityVerificationType,
  OccupationType,
  SPTaxReturnType,
  SPTitle,
  TEXT_DOCUMENT_EMPTY,
} from 'constant';
import { Icons, SPButton, SPFormItem } from 'components';
import { spStepThreeSelector } from 'pages/SPStepThree/selectors';
import { routeNames } from 'navigations/routes';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
} from 'pages/SPStepOne/selectors';
import { getFileExtFromUrl } from 'utils';
import { ImageItem } from '../ImageItems';

type SPStepSevenInfoProps = {
  showEditButton?: boolean;
  reload?: number;
};
export const SPStepSevenInfo: FC<SPStepSevenInfoProps> = ({
  showEditButton = true,
  reload,
}) => {
  const navigate = useNavigate();
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const {
    insuranceFile,
    insuranceFileBackImage,
    financialStatement1File,
    financialStatement2File,
    financialStatement3File,
    firstWithholdingSlipFile,
    secondWithholdingSlipFile,
    otherDocumentFile,
    employmentAgreementFile,
    businessTaxReturn1File,
    businessTaxReturn2File,
    businessTaxReturn3File,
    firstIncomeFile,
    secondIncomeFile,
    thirdIncomeFile,
    driverLicenseFrontImage,
    driverLicenseBackImage,
    cardNumberFrontImage,
    cardNumberBackImage,
    residentRegisterFrontImage,
    residentRegisterBackImage,
  } = useContext(ImageContext);

  const { tax_return, type_tax_return, occupation } =
    spStepThreeValues.p_applicant_people;

  const isPlanA = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.NO ||
      tax_return === SPTaxReturnType.DEFAULT ||
      (tax_return === SPTaxReturnType.YES &&
        type_tax_return.some(
          (typeTaxReturn) =>
            typeTaxReturn === FileATaxReturnType.FOUR ||
            typeTaxReturn === FileATaxReturnType.FIVE
        ))
    );
  }, [tax_return, type_tax_return]);

  const isFirstWithholdingSlipFile = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.NO ||
      tax_return === SPTaxReturnType.DEFAULT ||
      (tax_return === SPTaxReturnType.YES &&
        type_tax_return.every(
          (typeTaxReturn) =>
            typeTaxReturn !== FileATaxReturnType.ONE &&
            typeTaxReturn !== FileATaxReturnType.TWO &&
            typeTaxReturn !== FileATaxReturnType.THREE &&
            typeTaxReturn !== FileATaxReturnType.SIX &&
            typeTaxReturn !== FileATaxReturnType.OTHERS
        ))
    );
  }, [tax_return, type_tax_return]);

  const isSecondWithholdingSlipFile = useMemo(() => {
    return (
      (tax_return === SPTaxReturnType.NO ||
        tax_return === SPTaxReturnType.DEFAULT ||
        (tax_return === SPTaxReturnType.YES &&
          type_tax_return.every(
            (typeTaxReturn) =>
              typeTaxReturn !== FileATaxReturnType.ONE &&
              typeTaxReturn !== FileATaxReturnType.TWO &&
              typeTaxReturn !== FileATaxReturnType.THREE &&
              typeTaxReturn !== FileATaxReturnType.SIX &&
              typeTaxReturn !== FileATaxReturnType.OTHERS
          ))) &&
      occupation === OccupationType.ONE
    );
  }, [tax_return, type_tax_return, occupation]);

  const isFinancialStatementfile = useMemo(() => {
    return occupation === OccupationType.ONE;
  }, [occupation]);

  const isEmploymentAgreementFile = useMemo(() => {
    return (
      (tax_return === SPTaxReturnType.NO ||
        tax_return === SPTaxReturnType.DEFAULT ||
        (tax_return === SPTaxReturnType.YES &&
          type_tax_return.every(
            (typeTaxReturn) =>
              typeTaxReturn !== FileATaxReturnType.ONE &&
              typeTaxReturn !== FileATaxReturnType.TWO &&
              typeTaxReturn !== FileATaxReturnType.THREE &&
              typeTaxReturn !== FileATaxReturnType.SIX &&
              typeTaxReturn !== FileATaxReturnType.OTHERS
          ))) &&
      (occupation === OccupationType.SIX ||
        occupation === OccupationType.SEVEN ||
        occupation === OccupationType.EIGHT)
    );
  }, [tax_return, type_tax_return, occupation]);

  const isBusinessTaxReturnFilePlanA = useMemo(() => {
    return (
      (tax_return === SPTaxReturnType.NO ||
        tax_return === SPTaxReturnType.DEFAULT ||
        (tax_return === SPTaxReturnType.YES &&
          type_tax_return.every(
            (typeTaxReturn) =>
              typeTaxReturn !== FileATaxReturnType.ONE &&
              typeTaxReturn !== FileATaxReturnType.TWO &&
              typeTaxReturn !== FileATaxReturnType.THREE &&
              typeTaxReturn !== FileATaxReturnType.SIX &&
              typeTaxReturn !== FileATaxReturnType.OTHERS
          ))) &&
      occupation !== OccupationType.ONE &&
      occupation !== OccupationType.SIX &&
      occupation !== OccupationType.SEVEN &&
      occupation !== OccupationType.EIGHT
    );
  }, [tax_return, type_tax_return, occupation]);

  const isPlanB = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.YES &&
      type_tax_return.some(
        (typeTaxReturn) =>
          typeTaxReturn === FileATaxReturnType.ONE ||
          typeTaxReturn === FileATaxReturnType.TWO ||
          typeTaxReturn === FileATaxReturnType.THREE ||
          typeTaxReturn === FileATaxReturnType.SIX ||
          typeTaxReturn === FileATaxReturnType.OTHERS
      )
    );
  }, [tax_return, type_tax_return]);

  const isIncomeFile = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.YES &&
      type_tax_return.some(
        (typeTaxReturn) =>
          typeTaxReturn === FileATaxReturnType.ONE ||
          typeTaxReturn === FileATaxReturnType.TWO ||
          typeTaxReturn === FileATaxReturnType.THREE ||
          typeTaxReturn === FileATaxReturnType.SIX ||
          typeTaxReturn === FileATaxReturnType.OTHERS
      )
    );
  }, [tax_return, type_tax_return]);

  const isBusinessTaxReturnFilePlanB = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.YES &&
      type_tax_return.some(
        (typeTaxReturn) =>
          typeTaxReturn === FileATaxReturnType.ONE ||
          typeTaxReturn === FileATaxReturnType.TWO ||
          typeTaxReturn === FileATaxReturnType.THREE ||
          typeTaxReturn === FileATaxReturnType.SIX ||
          typeTaxReturn === FileATaxReturnType.OTHERS
      ) &&
      occupation !== OccupationType.ONE &&
      occupation !== OccupationType.FIVE &&
      occupation !== OccupationType.TEN
    );
  }, [tax_return, type_tax_return, occupation]);

  const checkTitle = () => {
    if (incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_TEN_INCOME_GUARANTORS;
    }
    if (incomeTotalizer && !hasJoinGuarantor) {
      return SPTitle.STEP_NINE_INCOME;
    }
    if (!incomeTotalizer && hasJoinGuarantor) {
      return SPTitle.STEP_EIGHT_GUARANTORS;
    }
    return SPTitle.STEP_SEVEN;
  };

  return (
    <Stack>
      {spStepSevenValues && (
        <Stack>
          <Stack
            direction="row"
            sx={{ px: 4, py: 1, mb: '1px', bgcolor: 'sp_primary_100_main' }}
          >
            <Typography
              variant="sp_step_text"
              color="main_white"
              sx={{ mt: 1 }}
            >
              STEP
            </Typography>
            <Typography variant="sp_label_text" color="main_white">
              {checkTitle()}
            </Typography>
          </Stack>

          <SPFormItem label="本人確認書類" required>
            {spStepSevenValues.p_applicant_people.identity_verification ===
              IdentityVerificationType.DRIVER_LICENSE && (
              <Stack spacing={3}>
                {(driverLicenseFrontImage.length > 0 ||
                  driverLicenseBackImage.length > 0) && (
                  <Typography variant="sp_unit_select_text" color="b_333">
                    運転免許証
                  </Typography>
                )}
                {driverLicenseFrontImage.length === 0 &&
                  driverLicenseBackImage.length === 0 && (
                    <Typography
                      variant="sp_value_text"
                      color="bg_radio_avatar"
                      textAlign="center"
                    >
                      {TEXT_DOCUMENT_EMPTY}
                    </Typography>
                  )}
                <Stack direction="column" spacing={1}>
                  <Typography variant="SP_label_field" color="b_333">
                    〈表面〉
                  </Typography>
                  {driverLicenseFrontImage.map((image, index) => (
                    <Stack spacing={1}>
                      {(image instanceof File ? true : !image.hiden) ? (
                        <ImageItem
                          key={index}
                          fileName={image.name}
                          src={image instanceof File ? image : image.url}
                          reload={reload}
                          display={image instanceof File ? true : !image.hiden}
                          type={
                            image instanceof File
                              ? image.type
                              : getFileExtFromUrl(image.url)
                          }
                        />
                      ) : (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                    </Stack>
                  ))}
                  <Typography variant="SP_label_field" color="b_333">
                    〈裏面〉
                  </Typography>
                  {driverLicenseBackImage.map((image, index) => (
                    <Stack spacing={1}>
                      {(image instanceof File ? true : !image.hiden) ? (
                        <ImageItem
                          key={index}
                          fileName={image.name}
                          src={image instanceof File ? image : image.url}
                          reload={reload}
                          display={image instanceof File ? true : !image.hiden}
                          type={
                            image instanceof File
                              ? image.type
                              : getFileExtFromUrl(image.url)
                          }
                        />
                      ) : (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}

            {spStepSevenValues.p_applicant_people.identity_verification ===
              IdentityVerificationType.MY_NUMBER_CARD && (
              <Stack spacing={3}>
                <Typography variant="sp_unit_select_text" color="b_333">
                  マイナンバーカード
                </Typography>
                {cardNumberFrontImage.length === 0 &&
                  cardNumberBackImage.length === 0 && (
                    <Typography
                      variant="sp_value_text"
                      color="bg_radio_avatar"
                      textAlign="center"
                    >
                      {TEXT_DOCUMENT_EMPTY}
                    </Typography>
                  )}
                <Stack direction="column" spacing={1}>
                  <Stack spacing={1}>
                    {/* <Typography variant="SP_label_field" color="b_333">
                      〈表面〉
                    </Typography> */}
                    {cardNumberFrontImage.map((image, index) =>
                      (image instanceof File ? true : !image.hiden) ? (
                        <ImageItem
                          key={index}
                          fileName={image.name}
                          src={image instanceof File ? image : image.url}
                          reload={reload}
                          display={image instanceof File ? true : !image.hiden}
                          type={
                            image instanceof File
                              ? image.type
                              : getFileExtFromUrl(image.url)
                          }
                        />
                      ) : (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )
                    )}
                  </Stack>

                  <Stack spacing={1}>
                    {/* <Typography variant="SP_label_field" color="b_333">
                      〈裏面〉
                    </Typography> */}
                    {cardNumberBackImage.map((image, index) =>
                      (image instanceof File ? true : !image.hiden) ? (
                        <ImageItem
                          key={index}
                          fileName={image.name}
                          src={image instanceof File ? image : image.url}
                          reload={reload}
                          display={image instanceof File ? true : !image.hiden}
                          type={
                            image instanceof File
                              ? image.type
                              : getFileExtFromUrl(image.url)
                          }
                        />
                      ) : (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}

            {spStepSevenValues.p_applicant_people.identity_verification ===
              IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD && (
              <Stack spacing={3}>
                <Typography variant="sp_unit_select_text" color="b_333">
                  住民基本台帳カード
                </Typography>
                {residentRegisterFrontImage.length === 0 &&
                  residentRegisterBackImage.length === 0 && (
                    <Typography
                      variant="sp_value_text"
                      color="bg_radio_avatar"
                      textAlign="center"
                    >
                      {TEXT_DOCUMENT_EMPTY}
                    </Typography>
                  )}
                <Stack direction="column" spacing={1}>
                  <Stack spacing={1}>
                    <Typography variant="SP_label_field" color="b_333">
                      〈表面〉
                    </Typography>
                    {residentRegisterFrontImage.map((image, index) =>
                      (image instanceof File ? true : !image.hiden) ? (
                        <ImageItem
                          key={index}
                          fileName={image.name}
                          src={image instanceof File ? image : image.url}
                          reload={reload}
                          display={image instanceof File ? true : !image.hiden}
                          type={
                            image instanceof File
                              ? image.type
                              : getFileExtFromUrl(image.url)
                          }
                        />
                      ) : (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )
                    )}
                  </Stack>
                  <Stack spacing={1}>
                    <Typography variant="SP_label_field" color="b_333">
                      〈裏面〉
                    </Typography>
                    {residentRegisterBackImage.map((image, index) =>
                      (image instanceof File ? true : !image.hiden) ? (
                        <ImageItem
                          key={index}
                          fileName={image.name}
                          src={image instanceof File ? image : image.url}
                          reload={reload}
                          display={image instanceof File ? true : !image.hiden}
                          type={
                            image instanceof File
                              ? image.type
                              : getFileExtFromUrl(image.url)
                          }
                        />
                      ) : (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </SPFormItem>

          {/*form 1*/}

          <SPFormItem label="健康保険証" required>
            <Stack spacing={1}>
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Typography variant="SP_label_field" color="b_333">
                    〈表面〉
                  </Typography>
                  {insuranceFile.length === 0 && (
                    <Typography
                      variant="sp_value_text"
                      color="bg_radio_avatar"
                      textAlign="center"
                    >
                      {TEXT_DOCUMENT_EMPTY}
                    </Typography>
                  )}
                  {insuranceFile.map((image, index) =>
                    (image instanceof File ? true : !image.hiden) ? (
                      <ImageItem
                        key={index}
                        fileName={image.name}
                        src={image instanceof File ? image : image.url}
                        reload={reload}
                        display={image instanceof File ? true : !image.hiden}
                        type={
                          image instanceof File
                            ? image.type
                            : getFileExtFromUrl(image.url)
                        }
                      />
                    ) : (
                      <Typography
                        variant="sp_value_text"
                        color="bg_radio_avatar"
                        textAlign="center"
                      >
                        {TEXT_DOCUMENT_EMPTY}
                      </Typography>
                    )
                  )}
                </Stack>
              </Stack>
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Typography variant="SP_label_field" color="b_333">
                    〈裏面〉
                  </Typography>
                  {insuranceFileBackImage.length === 0 && (
                    <Typography
                      variant="sp_value_text"
                      color="bg_radio_avatar"
                      textAlign="center"
                    >
                      {TEXT_DOCUMENT_EMPTY}
                    </Typography>
                  )}
                  {insuranceFileBackImage.map((image, index) =>
                    (image instanceof File ? true : !image.hiden) ? (
                      <ImageItem
                        key={index}
                        fileName={image.name}
                        src={image instanceof File ? image : image.url}
                        reload={reload}
                        display={image instanceof File ? true : !image.hiden}
                        type={
                          image instanceof File
                            ? image.type
                            : getFileExtFromUrl(image.url)
                        }
                      />
                    ) : (
                      <Typography
                        variant="sp_value_text"
                        color="bg_radio_avatar"
                        textAlign="center"
                      >
                        {TEXT_DOCUMENT_EMPTY}
                      </Typography>
                    )
                  )}
                </Stack>
              </Stack>
            </Stack>
          </SPFormItem>

          {/*PlanA*/}
          {isPlanA && !isPlanB && (
            <Stack>
              {isFirstWithholdingSlipFile && (
                <SPFormItem required label="収入に関する書類">
                  <Stack spacing={3}>
                    <Stack spacing={3}>
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        源泉徴収票（前年度分）
                      </Typography>
                      {firstWithholdingSlipFile.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {firstWithholdingSlipFile.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    {isSecondWithholdingSlipFile && (
                      <Stack spacing={3}>
                        <Typography
                          variant="SP_label_field"
                          color="b_333"
                          sx={{ mt: 3 }}
                        >
                          源泉徴収票（前々年度分）
                        </Typography>
                        {secondWithholdingSlipFile.length === 0 && (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )}
                        {secondWithholdingSlipFile.map((image, index) =>
                          (image instanceof File ? true : !image.hiden) ? (
                            <ImageItem
                              key={index}
                              fileName={image.name}
                              src={image instanceof File ? image : image.url}
                              reload={reload}
                              display={
                                image instanceof File ? true : !image.hiden
                              }
                              type={
                                image instanceof File
                                  ? image.type
                                  : getFileExtFromUrl(image.url)
                              }
                            />
                          ) : (
                            <Typography
                              variant="sp_value_text"
                              color="bg_radio_avatar"
                              textAlign="center"
                            >
                              {TEXT_DOCUMENT_EMPTY}
                            </Typography>
                          )
                        )}
                      </Stack>
                    )}
                  </Stack>
                </SPFormItem>
              )}

              {isFinancialStatementfile && (
                <SPFormItem
                  required
                  label={
                    <Typography variant="SP_form_item_label" color="b_333">
                      {`非上場企業の役員の方は\n下記の書類も添付してください。`}
                    </Typography>
                  }
                >
                  <Stack spacing={3}>
                    <Stack spacing={3}>
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書（1期前）
                      </Typography>
                      {financialStatement1File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {financialStatement1File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書（2期前）
                      </Typography>
                      {financialStatement2File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {financialStatement2File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書（3期前）
                      </Typography>
                      {financialStatement3File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {financialStatement3File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>
                  </Stack>
                </SPFormItem>
              )}

              {isEmploymentAgreementFile && (
                <SPFormItem required label="雇用契約に関する書類">
                  <Stack spacing={3}>
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      雇用契約書
                    </Typography>
                    {employmentAgreementFile.length === 0 && (
                      <Typography
                        variant="sp_value_text"
                        color="bg_radio_avatar"
                        textAlign="center"
                      >
                        {TEXT_DOCUMENT_EMPTY}
                      </Typography>
                    )}
                    {employmentAgreementFile.map((image, index) =>
                      (image instanceof File ? true : !image.hiden) ? (
                        <ImageItem
                          key={index}
                          fileName={image.name}
                          src={image instanceof File ? image : image.url}
                          reload={reload}
                          display={image instanceof File ? true : !image.hiden}
                          type={
                            image instanceof File
                              ? image.type
                              : getFileExtFromUrl(image.url)
                          }
                        />
                      ) : (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )
                    )}
                  </Stack>
                </SPFormItem>
              )}

              {isBusinessTaxReturnFilePlanA && (
                <SPFormItem
                  required
                  label={
                    <Stack>
                      <Typography variant="SP_form_item_label" color="b_333">
                        親族経営の会社等にご勤務の方は
                      </Typography>
                      <Typography variant="SP_form_item_label" color="b_333">
                        下記の書類も添付してください。
                      </Typography>
                    </Stack>
                  }
                >
                  <Stack spacing={3}>
                    <Stack spacing={3}>
                      <Stack>
                        <Typography
                          variant="sp_name_text"
                          color="b_333"
                          sx={{ mt: 3 }}
                        >
                          会社の決算報告書
                        </Typography>
                        <Typography variant="sp_name_text" color="b_333">
                          または経営する親族の確定申告書（1期前）
                        </Typography>
                      </Stack>
                      {businessTaxReturn1File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {businessTaxReturn1File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Stack>
                        <Typography
                          variant="sp_name_text"
                          color="b_333"
                          sx={{ mt: 3 }}
                        >
                          会社の決算報告書
                        </Typography>
                        <Typography variant="sp_name_text" color="b_333">
                          または経営する親族の確定申告書（2期前）
                        </Typography>
                      </Stack>
                      {businessTaxReturn2File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {businessTaxReturn2File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Stack>
                        <Typography
                          variant="sp_name_text"
                          color="b_333"
                          sx={{ mt: 3 }}
                        >
                          会社の決算報告書
                        </Typography>
                        <Typography variant="sp_name_text" color="b_333">
                          または経営する親族の確定申告書（3期前）
                        </Typography>
                      </Stack>
                      {businessTaxReturn3File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {businessTaxReturn3File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>
                  </Stack>
                </SPFormItem>
              )}
            </Stack>
          )}

          {/*Plan B*/}
          {isPlanB && (
            <Stack>
              {isIncomeFile && (
                <SPFormItem required label="収入に関する書類">
                  <Stack spacing={3}>
                    <Stack spacing={3}>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        確定申告書（1期前）
                      </Typography>
                      {firstIncomeFile.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {firstIncomeFile.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        確定申告書（2期前）
                      </Typography>
                      {secondIncomeFile.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {secondIncomeFile.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        確定申告書（3期前）
                      </Typography>
                      {thirdIncomeFile.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {thirdIncomeFile.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>
                  </Stack>
                </SPFormItem>
              )}

              {isFinancialStatementfile && (
                <SPFormItem
                  required
                  label={
                    <Typography variant="SP_form_item_label" color="b_333">
                      {`非上場企業の役員の方は\n下記の書類も添付してください。`}
                    </Typography>
                  }
                >
                  <Stack spacing={3}>
                    <Stack spacing={3}>
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書（1期前）
                      </Typography>
                      {financialStatement1File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {financialStatement1File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書（2期前）
                      </Typography>
                      {financialStatement2File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {financialStatement2File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書（3期前）
                      </Typography>
                      {financialStatement3File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {financialStatement3File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>
                  </Stack>
                </SPFormItem>
              )}

              {isBusinessTaxReturnFilePlanB && (
                <SPFormItem
                  required
                  label={
                    <Stack>
                      <Typography variant="SP_form_item_label" color="b_333">
                        親族経営の会社等にご勤務の方は
                      </Typography>
                      <Typography variant="SP_form_item_label" color="b_333">
                        下記の書類も添付してください。
                      </Typography>
                    </Stack>
                  }
                >
                  <Stack spacing={3}>
                    <Stack spacing={3}>
                      <Stack>
                        <Typography
                          variant="sp_name_text"
                          color="b_333"
                          sx={{ mt: 3 }}
                        >
                          会社の決算報告書
                        </Typography>
                        <Typography variant="sp_name_text" color="b_333">
                          または経営する親族の確定申告書（1期前）
                        </Typography>
                      </Stack>
                      {businessTaxReturn1File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {businessTaxReturn1File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Stack>
                        <Typography
                          variant="sp_name_text"
                          color="b_333"
                          sx={{ mt: 3 }}
                        >
                          会社の決算報告書
                        </Typography>
                        <Typography variant="sp_name_text" color="b_333">
                          または経営する親族の確定申告書（2期前）
                        </Typography>
                      </Stack>
                      {businessTaxReturn2File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {businessTaxReturn2File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>

                    <Stack spacing={3}>
                      <Stack>
                        <Typography
                          variant="sp_name_text"
                          color="b_333"
                          sx={{ mt: 3 }}
                        >
                          会社の決算報告書
                        </Typography>
                        <Typography variant="sp_name_text" color="b_333">
                          または経営する親族の確定申告書（3期前）
                        </Typography>
                      </Stack>
                      {businessTaxReturn3File.length === 0 && (
                        <Typography
                          variant="sp_value_text"
                          color="bg_radio_avatar"
                          textAlign="center"
                        >
                          {TEXT_DOCUMENT_EMPTY}
                        </Typography>
                      )}
                      {businessTaxReturn3File.map((image, index) =>
                        (image instanceof File ? true : !image.hiden) ? (
                          <ImageItem
                            key={index}
                            fileName={image.name}
                            src={image instanceof File ? image : image.url}
                            reload={reload}
                            display={
                              image instanceof File ? true : !image.hiden
                            }
                            type={
                              image instanceof File
                                ? image.type
                                : getFileExtFromUrl(image.url)
                            }
                          />
                        ) : (
                          <Typography
                            variant="sp_value_text"
                            color="bg_radio_avatar"
                            textAlign="center"
                          >
                            {TEXT_DOCUMENT_EMPTY}
                          </Typography>
                        )
                      )}
                    </Stack>
                  </Stack>
                </SPFormItem>
              )}
            </Stack>
          )}

          {/*form 6*/}
          <SPFormItem label="その他の書類" required>
            {otherDocumentFile.length === 0 && (
              <Typography
                variant="sp_value_text"
                color="bg_radio_avatar"
                textAlign="center"
              >
                {TEXT_DOCUMENT_EMPTY}
              </Typography>
            )}
            {otherDocumentFile.map((image, index) =>
              (image instanceof File ? true : !image.hiden) ? (
                <ImageItem
                  key={index}
                  fileName={image.name}
                  src={image instanceof File ? image : image.url}
                  reload={reload}
                  display={image instanceof File ? true : !image.hiden}
                  type={
                    image instanceof File
                      ? image.type
                      : getFileExtFromUrl(image.url)
                  }
                />
              ) : (
                <Typography
                  variant="sp_value_text"
                  color="bg_radio_avatar"
                  textAlign="center"
                >
                  {TEXT_DOCUMENT_EMPTY}
                </Typography>
              )
            )}
          </SPFormItem>

          {showEditButton && (
            <Stack sx={{ bgcolor: 'bg_off', px: 23, pt: 4, pb: 8 }}>
              <Stack
                sx={{
                  paddingRight: 0,
                  paddingBottom: 0,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  borderBottom: 'none',
                }}
              >
                <SPButton
                  sx={{
                    py: 1,
                    px: 3,
                    borderRadius: '10px',
                    borderWidth: 2,
                    bgcolor: 'sp_primary_40',
                    border: (theme) =>
                      `1px solid ${theme?.palette?.sp_primary_100_main}`,
                  }}
                  variant="outlined"
                  fullWidth
                  onClick={() =>
                    navigate(routeNames.SPStepSeven.path, {
                      state: { pathName: window.location.pathname },
                    })
                  }
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Icons.SPPencil />
                    <Typography
                      variant="sp_contentButton_text"
                      color="sp_primary_100_main"
                    >
                      {`STEP${checkTitle().slice(0, 2)} を修正する`}
                    </Typography>
                  </Stack>
                </SPButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};
