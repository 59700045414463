import { SenderType } from 'constant';
import { z } from 'zod';

export const messageItemAdminSchema = z.object({
  id: z.string(),
  conversation_name: z.string(),
  time_send_message: z.string(),
  content: z.string(),
  viewed: z.boolean(),
  p_application_header: z.object({
    preliminary_id: z.string(),
    applicant_name: z.string(),
    editable: z.boolean(),
  }),
});

export const messageItemSchema = z.object({
  id: z.string(),
  sender_id: z.string(),
  sender_type: z.nativeEnum(SenderType),
  sender_name: z.string(),
  avatar_company: z.object({
    filename: z.string(),
    url: z.string(),
  }),
  content: z.string(),
  message_file: z.array(
    z.object({
      filename: z.string(),
      url: z.string(),
    })
  ),
  created_at: z.string(),
});

export const userItemSchema = z.object({
  id: z.string(),
  user_name: z.string(),
});

export const getMessageAdminRequestSchema = z.object({
  order_key: z.string().optional(),
  order_sort: z.string().optional(),
  page: z.number().optional(),
});

export const getMessageAdminResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(messageItemAdminSchema),
  total: z.number(),
});

export const getMessageResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(messageItemSchema),
});

export const createMessageResponseSchema = z.object({
  data: messageItemSchema,
  success: z.boolean(),
});

export const getMessageDetailAdminResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(messageItemSchema),
  total_unread_messages: z.string(),
  user_exists: z.boolean(),
});

export const createMessageRequestSchema = z.object({
  message: z.object({
    content: z.string(),
  }),
});

export const getListUserResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(userItemSchema),
});

export const getUnreadMessagesResponseSchema = z.object({
  success: z.boolean(),
  total_unread_messages: z.number(),
});

export const deleteMessageManagerSchema = z.object({
  success: z.boolean(),
  id: z.string(),
});

export type MessageItemAdmin = z.infer<typeof messageItemAdminSchema>;
export type MessageItem = z.infer<typeof messageItemSchema>;
export type UserItem = z.infer<typeof userItemSchema>;

export type GetMessageAdminRequest = z.infer<
  typeof getMessageAdminRequestSchema
>;
export type GetMessageAdminResponse = z.infer<
  typeof getMessageAdminResponseSchema
>;
export type GetMessageDetailAdminResponse = z.infer<
  typeof getMessageDetailAdminResponseSchema
>;
export type GetMessageResponse = z.infer<typeof getMessageResponseSchema>;
export type CreateMessageRequest = z.infer<typeof createMessageRequestSchema>;
export type CreateMessageResponse = z.infer<typeof createMessageResponseSchema>;
export type GetListUserResponse = z.infer<typeof getListUserResponseSchema>;
export type GetUnreadMessagesResponse = z.infer<
  typeof getUnreadMessagesResponseSchema
>;
export type DeleteMessageResponse = z.infer<typeof deleteMessageManagerSchema>;
export type MessageError = {};
