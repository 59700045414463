import { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import { PBorrowingAttributeSBI, StepSynthesisForm } from 'types';
import {
  BorrowerType,
  BorrowingType,
  loanbalance,
  LoanBalanceType,
  LoanPurposeType,
  ScheduledLoanPayoffType,
} from 'constant';
import {
  CheckboxButton,
  FormItem,
  InputDate,
  InputField,
  InputSelectProps,
  RadioGroupButton,
  UlStyle,
} from 'components';
import { useSelector } from 'react-redux';
import { dynamicOptionsSelectors } from 'pages/StepRequiredInformation/selectors';
import { convertDynamicOptions, zeroPad } from 'utils';
import { dayjs } from 'libs/dayjs';
import { checkMCJ, isEditingSelector } from '../selectors';
import { MCJBorrowing } from './MCJBorrowing';

type RadioItems = {
  value: string;
  label: string;
};

const StepCurrentLoan: FC = () => {
  const { values, setFieldValue } = useFormikContext<StepSynthesisForm>();
  const isMCJ = useSelector(checkMCJ);
  const dynamicOptions = useSelector(dynamicOptionsSelectors);
  const isEditing = useSelector(isEditingSelector);

  const loanPurposeOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.loan_purpose).filter(
        (opt) =>
          opt.value !== LoanPurposeType.LOAN_FOR_LIVING_EXPENSES &&
          opt.value !== LoanPurposeType.HOME_APPLIANCE_PURCHASE
      ),
    [dynamicOptions.loan_purpose]
  );

  const scheduledLoanPayoffOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.scheduled_loan_payoff).filter(
        (opt) =>
          opt.value === ScheduledLoanPayoffType.SCHEDULED_TO_BE_PAID_OFF ||
          opt.value === ScheduledLoanPayoffType.NO_PLANS_TO_PAY_OFF
      ),
    [dynamicOptions.scheduled_loan_payoff]
  );

  const borrowerOptions = useMemo(
    () =>
      convertDynamicOptions(dynamicOptions.borrower).filter(
        (opt) => opt.value !== BorrowerType.INCOME_ADDER
      ),
    [dynamicOptions.borrower]
  );

  const checkFilterBorrowing = useCallback(() => {
    if (values.main_p_applicant_person.has_borrowings === LoanBalanceType.YES) {
      const SBIBorrowings =
        values.main_p_applicant_person.p_borrowings_attributes.filter(
          (borrowing) => borrowing.borrowing_type === BorrowingType.SBI_LOAN
        );
      if (!isMCJ && SBIBorrowings.length === 0) {
        const initialSBIBorrowing = {
          borrowing_type: BorrowingType.SBI_LOAN,
          lender: '',
          borrower: BorrowerType.DEFAULT,
          loan_amount: '',
          current_balance_amount: '',
          loan_purpose: '',
          annual_repayment_amount: '',
          loan_start_date: '',
          loan_deadline_date: '',
          scheduled_loan_payoff: ScheduledLoanPayoffType.DEFAULT,
          has_next_same_borrowing: false,
        };
        if (isEditing) {
          return setFieldValue(
            'main_p_applicant_person.p_borrowings_attributes',
            [
              ...values.main_p_applicant_person.p_borrowings_attributes
                .filter(
                  (borrowing) =>
                    !!borrowing.id &&
                    borrowing.borrowing_type !== BorrowingType.SBI_LOAN
                )
                .map((borrowing) => ({ ...borrowing, _destroy: true })),
              initialSBIBorrowing,
            ]
          );
        }
        return setFieldValue(
          'main_p_applicant_person.p_borrowings_attributes',
          [initialSBIBorrowing]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMCJ, setFieldValue, values.main_p_applicant_person.has_borrowings]);

  const handleChangeBorrowingOptions = useCallback(
    (check: boolean, value: string) => {
      if (value === LoanBalanceType.NO) {
        if (isEditing) {
          setFieldValue(
            'main_p_applicant_person.p_borrowings_attributes',
            values.main_p_applicant_person.p_borrowings_attributes
              .filter((borrowing) => !!borrowing.id)
              .map((borrowing) => ({ ...borrowing, _destroy: true }))
          );
        } else {
          setFieldValue('main_p_applicant_person.p_borrowings_attributes', []);
        }
        setFieldValue(
          'main_p_applicant_person.monthly_payment',
          LoanBalanceType.DEFAULT
        );
        setFieldValue(
          'main_p_applicant_person.card_loan',
          LoanBalanceType.DEFAULT
        );
        setFieldValue(
          'main_p_applicant_person.rented_house',
          LoanBalanceType.DEFAULT
        );
        setFieldValue(
          'main_p_applicant_person.business_loan',
          LoanBalanceType.DEFAULT
        );
      }
    },
    [
      isEditing,
      setFieldValue,
      values.main_p_applicant_person.p_borrowings_attributes,
    ]
  );

  useEffect(() => {
    if (!isMCJ) {
      const SBIBorrowings =
        values.main_p_applicant_person.p_borrowings_attributes.filter(
          (borrowing) => borrowing.borrowing_type === BorrowingType.SBI_LOAN
        );

      if (isEditing) {
        setFieldValue('main_p_applicant_person.p_borrowings_attributes', [
          ...SBIBorrowings,
          ...values.main_p_applicant_person.p_borrowings_attributes
            .filter(
              (borrowing) =>
                !!borrowing.id &&
                borrowing.borrowing_type !== BorrowingType.SBI_LOAN
            )
            .map((borrowing) => ({ ...borrowing, _destroy: true })),
        ]);
      } else {
        setFieldValue(
          'main_p_applicant_person.p_borrowings_attributes',
          SBIBorrowings
        );
      }

      setFieldValue(
        'main_p_applicant_person.monthly_payment',
        LoanBalanceType.DEFAULT
      );
      setFieldValue(
        'main_p_applicant_person.card_loan',
        LoanBalanceType.DEFAULT
      );
      setFieldValue(
        'main_p_applicant_person.rented_house',
        LoanBalanceType.DEFAULT
      );
      setFieldValue(
        'main_p_applicant_person.business_loan',
        LoanBalanceType.DEFAULT
      );
    } else {
      const MCJBorrowings =
        values.main_p_applicant_person.p_borrowings_attributes.filter(
          (borrowing) => borrowing.borrowing_type !== BorrowingType.SBI_LOAN
        );
      if (isEditing) {
        setFieldValue('main_p_applicant_person.p_borrowings_attributes', [
          ...MCJBorrowings,
          ...values.main_p_applicant_person.p_borrowings_attributes
            .filter(
              (borrowing) =>
                !!borrowing.id &&
                borrowing.borrowing_type === BorrowingType.SBI_LOAN
            )
            .map((borrowing) => ({ ...borrowing, _destroy: true })),
        ]);
      } else {
        setFieldValue(
          'main_p_applicant_person.p_borrowings_attributes',
          MCJBorrowings
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMCJ, setFieldValue]);

  useEffect(() => {
    checkFilterBorrowing();
  }, [checkFilterBorrowing]);

  return (
    <Stack justifyContent="center">
      <FieldArray
        name="main_p_applicant_person.p_borrowings_attributes"
        render={(arrayHelpers) => (
          <Stack>
            <FormItem
              required
              label="現在ご利用中のローン"
              note={notes.currentLoan}
            >
              <RadioGroupButton
                itemPerRow={3}
                type="noImage"
                name="main_p_applicant_person.has_borrowings"
                options={loanBalanceOptions}
                onChange={handleChangeBorrowingOptions}
              />
            </FormItem>

            {values.main_p_applicant_person.has_borrowings ===
              LoanBalanceType.YES &&
              isMCJ && <MCJBorrowing />}

            {!isMCJ &&
              values.main_p_applicant_person.has_borrowings ===
                LoanBalanceType.YES &&
              values.main_p_applicant_person.p_borrowings_attributes.map(
                (prepaidBorrowing, index) => {
                  if (
                    prepaidBorrowing.borrowing_type !== BorrowingType.SBI_LOAN
                  )
                    return null;
                  const filters =
                    values.main_p_applicant_person.p_borrowings_attributes.filter(
                      (e, i) =>
                        i <= index &&
                        e.borrowing_type === BorrowingType.SBI_LOAN
                    );
                  return (
                    <Stack key={index}>
                      <Divider
                        sx={{
                          width: '100%',
                          height: 2,
                          backgroundImage: (theme) =>
                            `linear-gradient(to right, ${theme?.palette?.line_gray} 0%, ${theme?.palette?.line_gray} 25%, transparent 30%)`,
                          backgroundSize: '6px 9px',
                          backgroundRepeat: 'repeat-x',
                          borderBottomWidth: 'inherit',
                        }}
                      />
                      <Stack
                        bgcolor="pale_blue"
                        direction="row"
                        sx={{
                          mt: 15,
                          py: 5,
                          pl: { mobile: 0, tablet: 5 },
                          borderRadius: '5px',
                        }}
                      >
                        <Typography
                          variant="textButtonTopPage"
                          color="normal_text"
                        >
                          「{filters.length}件目」の借入についての入力項目です。
                        </Typography>
                      </Stack>
                      <Stack sx={{ mt: 5 }}>
                        <UlStyle>
                          {notes.loanAmount.map((note, index) => (
                            <li key={index}>
                              <Typography
                                variant="text_note"
                                color="normal_text"
                              >
                                {note}
                              </Typography>
                            </li>
                          ))}
                        </UlStyle>
                      </Stack>

                      <FormItem label="資金使途（目的）" divider>
                        <RadioGroupButton
                          itemPerRow={3}
                          type="noImage"
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_purpose`}
                          options={loanPurposeOptions}
                        />
                      </FormItem>
                      {(
                        values.main_p_applicant_person.p_borrowings_attributes[
                          index
                        ] as PBorrowingAttributeSBI
                      ).loan_purpose === LoanPurposeType.OTHERS && (
                        <FormItem
                          divider
                          label="資金使途（その他）を選択した場合は具体的内容を記入してください"
                        >
                          <InputField
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].other_purpose`}
                            placeholder="ー"
                          />
                        </FormItem>
                      )}
                      <FormItem divider label="借入先（金融機関名）">
                        <InputField
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].lender`}
                          placeholder="◯◯銀行"
                        />
                      </FormItem>
                      <FormItem label="借入名義人" divider>
                        <RadioGroupButton
                          itemPerRow={3}
                          type="noImage"
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].borrower`}
                          options={borrowerOptions}
                        />
                      </FormItem>
                      <FormItem label="当初借入額" divider>
                        <Stack direction="row">
                          <InputField
                            sx={{ width: 200 }}
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_amount`}
                            align="right"
                            placeholder="0"
                            unit="万円"
                            numeric
                            useNumberFormat
                          />
                        </Stack>
                      </FormItem>
                      <FormItem label="現在の残高" divider>
                        <Stack direction="row">
                          <InputField
                            sx={{ width: 200 }}
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].current_balance_amount`}
                            align="right"
                            placeholder="0"
                            unit="万円"
                            numeric
                            useNumberFormat
                          />
                        </Stack>
                      </FormItem>
                      <FormItem label="年間返済額" divider>
                        <Stack direction="row">
                          <InputField
                            sx={{ width: 200 }}
                            name={`main_p_applicant_person.p_borrowings_attributes[${index}].annual_repayment_amount`}
                            align="right"
                            placeholder="0"
                            unit="万円"
                            numeric
                            useNumberFormat
                          />
                        </Stack>
                      </FormItem>

                      <FormItem label="当初借入年月" divider>
                        <Grid container>
                          <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                            <InputDate
                              name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_start_date`}
                              isSelect
                            />
                          </Grid>
                        </Grid>
                      </FormItem>
                      <FormItem label="最終期限" divider>
                        <Grid container>
                          <Grid item width={{ mobile: '100%', tablet: 'auto' }}>
                            <InputDate
                              name={`main_p_applicant_person.p_borrowings_attributes[${index}].loan_deadline_date`}
                              isSelect
                              yearOptions={yearOptions}
                            />
                          </Grid>
                        </Grid>
                      </FormItem>
                      <FormItem label="お借入までの完済予定" divider>
                        <RadioGroupButton
                          itemPerRow={3}
                          type="noImage"
                          name={`main_p_applicant_person.p_borrowings_attributes[${index}].scheduled_loan_payoff`}
                          options={scheduledLoanPayoffOptions}
                        />
                      </FormItem>
                      {filters.length < 3 && (
                        <FormItem label="事前完済借入を追加">
                          <Grid container>
                            <Grid item {...{ mobile: 12, tablet: 6, md: 5 }}>
                              <Stack sx={{ maxWidth: 376 }}>
                                <CheckboxButton
                                  name={`main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`}
                                  children="もう１件追加する"
                                  onClick={() => {
                                    if (
                                      prepaidBorrowing.has_next_same_borrowing
                                    ) {
                                      setFieldValue(
                                        `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                        false
                                      );
                                      for (
                                        let i =
                                          values.main_p_applicant_person
                                            .p_borrowings_attributes.length - 1;
                                        i > index;
                                        i--
                                      ) {
                                        if (
                                          values.main_p_applicant_person
                                            .p_borrowings_attributes[i]
                                            .borrowing_type ===
                                          BorrowingType.SBI_LOAN
                                        ) {
                                          if (
                                            isEditing &&
                                            !!values.main_p_applicant_person
                                              .p_borrowings_attributes[i].id
                                          ) {
                                            setFieldValue(
                                              `main_p_applicant_person.p_borrowings_attributes[${i}]._destroy`,
                                              true
                                            );
                                          } else {
                                            arrayHelpers.remove(i);
                                          }
                                        }
                                      }
                                      return;
                                    }
                                    setFieldValue(
                                      `main_p_applicant_person.p_borrowings_attributes[${index}].has_next_same_borrowing`,
                                      true
                                    );
                                    arrayHelpers.push({
                                      borrowing_type: BorrowingType.SBI_LOAN,
                                      lender: '',
                                      borrower: BorrowerType.DEFAULT,
                                      loan_amount: '',
                                      current_balance_amount: '',
                                      loan_purpose: '',
                                      annual_repayment_amount: '',
                                      loan_start_date: '',
                                      loan_deadline_date: '',
                                      scheduled_loan_payoff:
                                        ScheduledLoanPayoffType.DEFAULT,
                                      has_next_same_borrowing: false,
                                    });
                                  }}
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                        </FormItem>
                      )}
                    </Stack>
                  );
                }
              )}

            {isMCJ && (
              <>
                <FormItem
                  required
                  divider
                  label="支払地代（今回の住宅取得後も継続するもの）"
                >
                  <Stack direction="row">
                    <InputField
                      sx={{ width: { mobile: '100%', tablet: 250 } }}
                      name="p_application_header.house_rent_to_be_paid"
                      align="right"
                      placeholder="0"
                      unit="円"
                      numeric
                      useNumberFormat
                    />
                  </Stack>
                </FormItem>
                <FormItem
                  required
                  label="支払家賃（今回の住宅取得後も継続するもの）"
                >
                  <Stack direction="row">
                    <InputField
                      sx={{ width: { mobile: '100%', tablet: 250 } }}
                      name="p_application_header.land_rent_to_be_paid"
                      align="right"
                      placeholder="0"
                      unit="円"
                      numeric
                      useNumberFormat
                    />
                  </Stack>
                </FormItem>
              </>
            )}
          </Stack>
        )}
      />
    </Stack>
  );
};

export default StepCurrentLoan;

const notes = {
  currentLoan:
    '※現在ご利用中の住宅ローン・車のローン・教育ローン・奨学金・カードローン・キャッシング等がある場合は「有」を選択してください。',
  has_borrowings:
    '例：車のローン・教育ローン・奨学金・生活費補填ローン・住宅ローン・リフォームローンなど',
  loanAmount: [
    '車のローン、奨学金などの他、カードローン・キャッシングもご記入ください。',
    '仮審査申込日から遡って3ヵ月以内に完済した借入もご記入ください。',
    '現在ご利用中のローンの返済予定表・利用明細等をアップロードいただければ以下の項目は入力不要です。',
  ],
};

const loanBalanceOptions: Array<RadioItems> = [
  {
    value: LoanBalanceType.YES,
    label: loanbalance[LoanBalanceType.YES],
  },
  {
    value: LoanBalanceType.NO,
    label: loanbalance[LoanBalanceType.NO],
  },
];

const yearOptions: InputSelectProps['options'] = [
  { value: '', label: '西暦' },
].concat(
  Array.from(Array(34), (_, index) => {
    const year = zeroPad(dayjs().year() - (34 - index));
    return {
      value: year,
      label: `${year} `,
    };
  }),
  Array.from(Array(34), (_, index) => {
    const year = zeroPad(dayjs().year() + index);
    return {
      value: year,
      label: `${year} `,
    };
  })
);
