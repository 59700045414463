import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Modal as MuiModal, Stack, Typography } from '@mui/material';
import { Button, Icons, InputField } from 'components';
import { FormikProvider, useFormik } from 'formik';
import { yup } from 'libs';
import { useAppDispatch } from 'hooks';
import { login } from 'containers/AuthModal/thunk';
import { ModalSaveInfoContext } from 'context';
import { useNavigate } from 'react-router-dom';
import { getNewSavedDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { toast } from 'react-toastify';
import { routeNames } from 'navigations/routes';

type ModalProps = PropsWithChildren<{
  open: boolean;
  handleClose: () => void;
  forgotPassword?: boolean;
  titleButton?: string;
  titleModal?: string;
  title?: string;
  titleLine?: string;
  onSaveDraft?: () => void;
}>;

export const ModalSaveInfo: FC<ModalProps> = ({
  open,
  handleClose,
  forgotPassword,
  titleButton,
  titleModal,
  title,
  titleLine,
  onSaveDraft,
}) => {
  const { openSave, openRecallSave } = useContext(ModalSaveInfoContext);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSaveDraft = useCallback(async () => {
    onSaveDraft && onSaveDraft();
    handleClose();
  }, [handleClose, onSaveDraft]);

  const handleGetDraft = useCallback(async () => {
    const result = await dispatch(getNewSavedDraft());
    if (getNewSavedDraft.fulfilled.match(result)) {
      toast.success('途中保存されているデータを成功に取得しました。');
      navigate(routeNames.StepSynthesisInformation.path);
      handleClose();
    } else {
      toast.error('エラーが発生しました。');
    }
  }, [dispatch, handleClose, navigate]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().required().min(8),
    }),
    async onSubmit(user) {
      const result = await dispatch(login({ user }));
      if (login.fulfilled.match(result)) {
        if (openSave) {
          handleSaveDraft();
        } else if (openRecallSave) {
          handleGetDraft();
        }
      }
    },
  });

  if (!open) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <MuiModal open={open} onClose={handleClose} disableAutoFocus>
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Stack
            sx={{
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
              bgcolor: 'main_white',
              borderRadius: '5px',
              width: '940px',
              '@media (orientation: portrait)': {
                width: { mobile: '300px', tablet: '800px' },
                py: { mobile: 6, tablet: 0 },
              },
            }}
          >
            <Stack alignItems="flex-end" sx={{ mr: '41px', mt: '27px' }}>
              <Icons.LogoNew />
            </Stack>
            <Stack textAlign="center" sx={{ mt: { mobile: 5, tablet: 0 } }}>
              <Typography variant="text_input_code">{titleModal}</Typography>
            </Stack>
            <Stack
              sx={{
                p: '30px 68px 56px 72px',
                '@media (orientation: portrait)': {
                  p: '30px 20px 56px 20px',
                },
              }}
              direction={{ mobile: 'column', tablet: 'row' }}
            >
              <Stack
                flex={1}
                alignItems="center"
                sx={{
                  width: { mobile: 200, tablet: 370 },
                  height: 360,
                  bgcolor: 'main_white',
                  borderRadius: '10px',
                  border: (theme) => `1px solid ${theme?.palette?.gray}`,
                  px: 8,
                  pb: { mobile: 10, tablet: 0 },
                }}
              >
                <Stack sx={{ mt: { mobile: 10, tablet: 22 } }}>
                  <Stack direction="row" justifyContent="center">
                    <Typography variant="textButtonTopPage" color="line_green">
                      LINE
                    </Typography>
                    <Typography variant="textButtonTopPage" color="normal_text">
                      {title}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="textButtonTopPage"
                    color="normal_text"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {titleLine}
                  </Typography>
                </Stack>
                <Stack sx={{ mt: { mobile: 10, tablet: 20 }, width: '100%' }}>
                  <Button sx={{ bgcolor: 'line_green' }}>
                    <Typography variant="textButtonTopPage" color="main_white">
                      LINEログイン
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
              <Stack
                flex={1}
                sx={{
                  mt: { mobile: 5, tablet: 0 },
                  pl: '80px',
                  '@media (orientation: portrait)': {
                    pl: { mobile: 0, tablet: '20px' },
                  },
                }}
              >
                <Stack>
                  <Typography variant="textstyle" lineHeight="24px">
                    メールアドレス
                  </Typography>
                </Stack>
                <Stack sx={{ mt: '9px' }}>
                  <InputField
                    name="email"
                    type="email"
                    InputProps={{
                      sx: {
                        '&& .MuiInputBase-input': { borderRadius: '5px' },
                        '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                          {
                            borderWidth: '1px',
                            borderColor: 'black',
                          },
                      },
                    }}
                  />
                </Stack>
                <Stack sx={{ mt: '11px' }}>
                  <Typography variant="textstyle" lineHeight="24px">
                    パスワード
                  </Typography>
                </Stack>
                <Stack sx={{ mt: '10px', position: 'relative' }}>
                  <InputField
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      sx: {
                        '&& .MuiInputBase-input': { borderRadius: '5px' },
                        '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                          {
                            borderWidth: '1px',
                            borderColor: 'black',
                          },
                      },
                    }}
                    showPassword={showPassword}
                  />
                  <Icons.CheckPassword
                    sx={{
                      position: 'absolute',
                      right: 19,
                      top: { mobile: 7, tablet: 13 },
                      color: showPassword ? 'Icon_color' : 'main_white',
                    }}
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                </Stack>
                <Stack sx={{ mt: '42px' }}>
                  <Button
                    disabled={
                      !(formik.isValid && formik.dirty) || formik.isSubmitting
                    }
                    sx={{ bgcolor: 'sh_red' }}
                    onClick={() => formik.handleSubmit()}
                  >
                    <Typography variant="textButtonTopPage" color="main_white">
                      {titleButton}
                    </Typography>
                  </Button>
                </Stack>
                {forgotPassword && (
                  <Stack sx={{ mt: '31px', textAlign: 'center' }}>
                    <Typography
                      variant="datetime_content"
                      color="h_blugreen"
                      sx={{
                        whiteSpace: 'nowrap',
                        '@media (orientation: portrait)': {
                          letterSpacing: -1,
                        },
                      }}
                    >
                      パスワードを忘れた方は変更をお願いします
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </MuiModal>
    </FormikProvider>
  );
};
