import { ChangeEvent, FC, useEffect, useState } from 'react';
import RAutosizeInput from 'react-input-autosize';
import TextareaAutosize from 'react-textarea-autosize';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import { strippedStringWithCommas } from 'utils';

export type AutosizeInputProps = {
  id?: string;
  value: string | number;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
  disabled?: boolean;
  type?: string;
  maxWidth?: number;
  isPrice?: boolean;
  longText?: boolean;
};

export const AutosizeInput: FC<AutosizeInputProps> = ({
  value,
  onFocus,
  onBlur,
  onChange,
  maxWidth,
  isPrice,
  longText,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState<string | number>('');

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <RAutosizeInputStyled
      value={currentValue}
      onFocus={onFocus}
      onBlur={async (event: ChangeEvent<HTMLInputElement>) => {
        await onChange(event);
        onBlur && onBlur();
        setCurrentValue((prevState) => prevState.toString().trim());
      }}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        if (
          (!!isPrice && event.target.value.includes('.')) ||
          (!!maxWidth &&
            strippedStringWithCommas(event.target.value).length > maxWidth)
        ) {
          return event.preventDefault();
        }
        setCurrentValue(event.target.value);
      }}
      autoComplete="off"
      {...props}
      longText={longText}
    />
  );
};

const RAutosizeInputStyled = styled(
  ({
    className,
    longText,
    ...props
  }: {
    className?: string;
    longText?: boolean;
  }) =>
    longText ? (
      <TextareaAutosize
        style={{
          border: 'none',
          overflow: 'hidden',
          resize: 'none',
          width: '100%',
          padding: 4,
          fontFamily: 'Hiragino Sans',
          fontSize: 12,
          fontWeight: '300',
          color: '#333333',
          backgroundColor: 'transparent',
        }}
        {...props}
      />
    ) : (
      <RAutosizeInput {...props} inputClassName={className} />
    )
)(({ theme }) => ({
  minHeight: 24,
  marginLeft: -5,
  paddingLeft: 5,
  paddingRight: 0,
  minWidth: 12,
  wordBreak: 'break-all',
  border: 'none',
  ...theme.typography.text_step_admin,
  textAlign: 'left',
  maxWidth: 400,
  '&:disabled': {
    backgroundColor: 'transparent',
  },
}));
