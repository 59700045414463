import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const StatusStep: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    sx={{
      width: '169px',
      height: '25px',
      ...sx,
    }}
    viewBox="0 0 169 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="169"
      height="25"
      viewBox="0 0 169 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 21.3889V4.05556C1 1.38889 4.68421 0.5 8.73684 0.5H150.211C154.632 0.5 156.842 1.38889 157.947 2.27778C158.715 2.89477 163.975 7.79441 167.52 11.1127C168.335 11.8756 168.365 13.157 167.584 13.9553C164.753 16.8518 160.543 21.0794 159.053 22.2778C157.284 23.7 153.526 24.5 150.211 24.5H8.73684C2.10526 24.5 1 23.1667 1 21.3889Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
