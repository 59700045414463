import { Stack, Typography } from '@mui/material';
import {
  Icons,
  SPEmailLink,
  SPFormItem,
  SPImage,
  SPRadioGroupButton,
  SPSaveDraftModal,
  SPUploadImageItem,
  SPSaveImageModal,
} from 'components';
import {
  BorrowerIncomeType,
  FileATaxReturnType,
  GeneralIncomeConfirmation,
  IdentityVerificationType,
  OccupationType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  SPTaxReturnType,
  identityVerification,
} from 'constant';
import { SPStepLayout } from 'containers';
import { useSelector } from 'react-redux';
import { ImageContext } from 'context';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch,
  useCheckPreliminaryStatus,
  useUpdateForm,
} from 'hooks';
import { routeNames } from 'navigations/routes';
import { get, update, isEmpty } from 'lodash';
import { spCurrentStepSelector } from 'containers/AuthModal/selectors';
import { setCurrentStep } from 'containers/AuthModal/slice';
import { spSaveDraft } from 'containers/GroupButtonSaveInfo/thunk';
import { FormikProvider, getIn, useFormik } from 'formik';
import { spStepCollateralProviderSelector } from 'pages/SPStepFourCollateralProvider/selectors';
import { spStepSevenSelector } from 'pages/SPStepSeven/selectors';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  checkingExistFile,
  convertImage,
  flattenToLodashFormat,
  supportDvh,
} from 'utils';
import { spStepEightSelector } from '../SPStepEight/selectors';
import { spStepFiveSelector } from '../SPStepFive/selectors';
import { spStepThreeIncomeTotalSelector } from '../SPStepFiveIncomeTotalizer/selectors';
import { spStepFourSelector } from '../SPStepFour/selectors';
import { spStepTwoIncomeTotalSelector } from '../SPStepFourIncomeTotalizer/selectors';
import { spStepThreeSelector } from '../SPStepThree/selectors';
import {
  checkHasJoinGuarantor,
  checkIncomeTotalizer,
  spStepOneSelector,
} from '../SPStepOne/selectors';
import { spStepSixSelector } from '../SPStepSix/selectors';
import { spStepTenSelector } from '../SPStepTen/selectors';
import { spStepTwoSelector } from '../SPStepTwo/selectors';
import { useGoBackTop } from '../../hooks/useGoBackTop';
import { spStepSevenIncomeTotalizerSelector } from './selectors';
import { saveForm } from './slice';
import { validationSchema } from './validationSchema';

type RadioItems = {
  value: string;
  label: string;
  uploadImage?: ReactNode;
};

const SPStepSevenIncomeTotalizerPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const imageRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const initialValues = useSelector(spStepSevenIncomeTotalizerSelector);
  const spStepOneValues = useSelector(spStepOneSelector);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);
  const hasJoinGuarantor = useSelector(checkHasJoinGuarantor);
  const spStepTwoValues = useSelector(spStepTwoSelector);
  const spStepTwoIncomeTotalValues = useSelector(spStepTwoIncomeTotalSelector);
  const spStepThreeValues = useSelector(spStepThreeSelector);
  const spStepThreeIncomeTotalValues = useSelector(
    spStepThreeIncomeTotalSelector
  );
  const spStepFourValues = useSelector(spStepFourSelector);
  const spStepCollateralProviderValues = useSelector(
    spStepCollateralProviderSelector
  );
  const spStepFiveValues = useSelector(spStepFiveSelector);
  const spStepSixValues = useSelector(spStepSixSelector);
  const spStepSevenValues = useSelector(spStepSevenSelector);
  const spStepEightValues = useSelector(spStepEightSelector);
  const { application_number } = useSelector(spStepTenSelector);
  const currentStep = useSelector(spCurrentStepSelector);

  useCheckPreliminaryStatus();
  useGoBackTop();

  const {
    insuranceFile,
    insuranceFileBackImage,
    insuranceFile2,
    setInsuranceFile2,
    insuranceFileBackImage2,
    setInsuranceFileBackImage2,
    financialStatement1File,
    financialStatement1File2,
    setFinancialStatement1File2,
    financialStatement2File,
    financialStatement2File2,
    setFinancialStatement2File2,
    financialStatement3File,
    financialStatement3File2,
    setFinancialStatement3File2,
    firstWithholdingSlipFile,
    firstWithholdingSlipFile2,
    setFirstWithholdingSlipFile2,
    secondWithholdingSlipFile,
    secondWithholdingSlipFile2,
    setSecondWithholdingSlipFile2,
    otherDocumentFile,
    otherDocumentFile2,
    setOtherDocumentFile2,
    employmentAgreementFile,
    employmentAgreementFile2,
    setEmploymentAgreementFile2,
    businessTaxReturn1File,
    businessTaxReturn1File2,
    setBusinessTaxReturn1File2,
    businessTaxReturn2File,
    businessTaxReturn2File2,
    setBusinessTaxReturn2File2,
    businessTaxReturn3File,
    businessTaxReturn3File2,
    setBusinessTaxReturn3File2,
    firstIncomeFile,
    firstIncomeFile2,
    setFirstIncomeFile2,
    secondIncomeFile,
    secondIncomeFile2,
    setSecondIncomeFile2,
    thirdIncomeFile,
    thirdIncomeFile2,
    setThirdIncomeFile2,
    repaymentScheduleImage,
    residenceFile,
    residenceFileBackImage,
    businessCard,
    driverLicenseFrontImage,
    driverLicenseFrontImage2,
    setDriverLicenseFrontImage2,
    driverLicenseBackImage,
    driverLicenseBackImage2,
    setDriverLicenseBackImage2,
    cardNumberFrontImage,
    cardNumberFrontImage2,
    setCardNumberFrontImage2,
    cardNumberBackImage,
    cardNumberBackImage2,
    setCardNumberBackImage2,
    residentRegisterFrontImage,
    residentRegisterFrontImage2,
    setResidentRegisterFrontImage2,
    residentRegisterBackImage,
    residentRegisterBackImage2,
    setResidentRegisterBackImage2,
    residenceIncomeFile,
    residenceIncomeFileBackImage,
    propertyInformationFile,
  } = useContext(ImageContext);
  const imageContext = useContext(ImageContext);
  const [errorDriverLicense, setErrorDriverLicense] = useState<boolean>(false);
  const [errorCardNumber, setErrorCardNumber] = useState<boolean>(false);
  const [errorResidentRegister, setErrorResidentRegister] =
    useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const { updateFormOverride } = useUpdateForm();
  const [openImageModal, setOpenImageModal] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      dispatch(
        saveForm({
          p_application_header: {
            p_applicant_people_attributes: [{ ...values.p_applicant_people }],
          },
        })
      );
      if (errorDriverLicense || errorResidentRegister || errorCardNumber) {
        imageRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      if (incomeTotalizer && +currentStep < 11) {
        dispatch(setCurrentStep('11'));
      }
      if (incomeTotalizer && hasJoinGuarantor && +currentStep < 12) {
        dispatch(setCurrentStep('12'));
      }
      setSubmitting(false);
      if (isSubmit) {
        updateFormOverride(
          {
            stepSevenIncomeData: {
              p_application_header: {
                p_applicant_people_attributes: [
                  { ...values.p_applicant_people },
                ],
              },
            },
            imageContext: imageContext,
          },
          setIsDisabledButton,
          setOpenImageModal,
          setReset
        );
      } else navigate(routeNames.SPStepEight.path);
    },
  });

  const isSubmit = useMemo(() => {
    return !!application_number;
  }, [application_number]);

  useEffect(() => {
    if (!!application_number && !incomeTotalizer) {
      navigate(routeNames.SPStepOne.path);
    }
  }, [application_number, incomeTotalizer, navigate]);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const flattedTouched = flattenToLodashFormat(formik.touched);

      const errorNames = Object.keys(flattedTouched).reduce((prev, key) => {
        if (getIn(formik.errors, key)) {
          prev.push(key);
        }

        return prev;
      }, [] as string[]);

      if (errorNames.length && typeof document !== 'undefined') {
        let errorElement:
          | HTMLInputElement
          | HTMLSelectElement
          | HTMLTextAreaElement
          | null;

        errorNames.forEach((errorKey) => {
          const selector = `[name="${errorKey}"]`;
          if (!errorElement) {
            errorElement = document.querySelector(selector);
            return;
          }
        });

        setTimeout(() => {
          if (errorElement) {
            if (errorElement.type === 'hidden') {
              errorElement.parentElement?.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            } else {
              errorElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          }
        }, 100);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors, formik.touched]);

  const identityVerificationOption: Array<RadioItems> = useMemo(
    () => [
      {
        value: IdentityVerificationType.DRIVER_LICENSE,
        label: identityVerification[IdentityVerificationType.DRIVER_LICENSE],
        uploadImage: (
          <Stack spacing={2}>
            {errorDriverLicense && (
              <Typography variant="text_error" color="sp_secondary_01">
                ※書類をアップロードしてください。
              </Typography>
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={window.innerWidth <= 332 ? 1 : 3}
              sx={{ maxWidth: '341px' }}
            >
              <Stack spacing={2} sx={{ width: '136px' }}>
                <Typography pl={3} variant="SP_label_field" color="b_333">
                  〈表面〉
                </Typography>
                <Stack sx={{ width: '100%' }}>
                  <SPUploadImageItem
                    variant="imageOnly"
                    images={driverLicenseFrontImage2}
                    setImages={setDriverLicenseFrontImage2}
                    reload={reset}
                    uploadMulti={false}
                    handleDeleteImage={(id) => {
                      const driver_license_front_image = convertImage(
                        formik.values.p_applicant_people
                          .driver_license_front_image,
                        id
                      );
                      formik.setFieldValue(
                        'p_applicant_people.driver_license_front_image',
                        driver_license_front_image
                      );
                    }}
                  />
                </Stack>
              </Stack>

              <Stack spacing={2} sx={{ width: '136px' }}>
                <Typography pl={3} variant="SP_label_field" color="b_333">
                  〈裏面〉
                </Typography>
                <Stack sx={{ width: '100%' }}>
                  <SPUploadImageItem
                    variant="imageOnly"
                    images={driverLicenseBackImage2}
                    setImages={setDriverLicenseBackImage2}
                    reload={reset}
                    uploadMulti={false}
                    handleDeleteImage={(id) => {
                      const driver_license_back_image = convertImage(
                        formik.values.p_applicant_people
                          .driver_license_back_image,
                        id
                      );
                      formik.setFieldValue(
                        'p_applicant_people.driver_license_back_image',
                        driver_license_back_image
                      );
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ),
      },
      {
        value: IdentityVerificationType.MY_NUMBER_CARD,
        label: identityVerification[IdentityVerificationType.MY_NUMBER_CARD],
        uploadImage: (
          <Stack ml={window.innerWidth <= 332 ? 1 : 2} spacing={3}>
            {errorCardNumber && (
              <Typography variant="text_error" color="sp_secondary_01">
                ※書類をアップロードしてください。
              </Typography>
            )}

            <Stack
              direction="row"
              alignItems="center"
              spacing={window.innerWidth <= 332 ? 1 : 3}
            >
              <Stack spacing={2} sx={{ width: '136px' }}>
                <Typography variant="SP_label_field" color="b_333">
                  〈表面〉
                </Typography>
                <SPUploadImageItem
                  variant="imageOnly"
                  images={cardNumberFrontImage2}
                  setImages={setCardNumberFrontImage2}
                  reload={reset}
                  uploadMulti={false}
                  handleDeleteImage={(id) => {
                    const card_number_front_image = convertImage(
                      formik.values.p_applicant_people.card_number_front_image,
                      id
                    );
                    formik.setFieldValue(
                      'p_applicant_people.card_number_front_image',
                      card_number_front_image
                    );
                  }}
                />
              </Stack>

              {/* <Stack spacing={2} sx={{ width: '136px' }}>
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="SP_label_field"
                    color="b_333"
                    whiteSpace="nowrap"
                  >
                    〈裏面〉
                  </Typography>
                </Stack>
                <SPUploadImageItem
                  variant="imageOnly"
                  images={cardNumberBackImage2}
                  setImages={setCardNumberBackImage2}
                  reload={reset}uploadMulti={false}
                  handleDeleteImage={(id) => {
                    const card_number_back_image = convertImage(
                      formik.values.p_applicant_people.card_number_back_image,
                      id
                    );
                    formik.setFieldValue(
                      'p_applicant_people.card_number_back_image',
                      card_number_back_image
                    );
                  }}
                />
              </Stack> */}
            </Stack>
          </Stack>
        ),
      },
      {
        value: IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD,
        label:
          identityVerification[
            IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
          ],
        uploadImage: (
          <Stack ml={window.innerWidth <= 332 ? 1 : 2} spacing={3}>
            {errorResidentRegister && (
              <Typography variant="text_error" color="sp_secondary_01">
                ※書類をアップロードしてください。
              </Typography>
            )}
            {/* [HOMELOAN-2592] */}
            {/* <Stack
              direction="row"
              alignItems="flex-start"
              spacing={1}
              marginLeft={window.innerWidth <= 332 ? '10px' : '12px'}
            >
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Stack>
                <Typography
                  variant="SP_form_item_note"
                  color="b_333"
                  sx={{ fontSize: window.innerWidth <= 332 ? '10px' : null }}
                >
                  原則、顔写真付きの表面のみ添付してください。
                </Typography>
                <Typography
                  variant="SP_form_item_note"
                  color="b_333"
                  sx={{ fontSize: window.innerWidth <= 332 ? '10px' : null }}
                >
                  裏面に住所変更履歴などの記載がある場合、裏面も
                </Typography>
                <Typography
                  variant="SP_form_item_note"
                  color="b_333"
                  sx={{ fontSize: window.innerWidth <= 332 ? '10px' : null }}
                >
                  添付してください。
                </Typography>
              </Stack>
            </Stack> */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={window.innerWidth <= 332 ? 1 : 3}
            >
              <Stack spacing={2} sx={{ width: '136px' }}>
                <Typography variant="SP_label_field" color="b_333">
                  〈表面〉
                </Typography>
                <SPUploadImageItem
                  variant="imageOnly"
                  images={residentRegisterFrontImage2}
                  setImages={setResidentRegisterFrontImage2}
                  reload={reset}
                  uploadMulti={false}
                  handleDeleteImage={(id) => {
                    const resident_register_front_image = convertImage(
                      formik.values.p_applicant_people
                        .resident_register_front_image,
                      id
                    );
                    formik.setFieldValue(
                      'p_applicant_people.resident_register_front_image',
                      resident_register_front_image
                    );
                  }}
                />
              </Stack>

              <Stack spacing={2} sx={{ width: '136px' }}>
                <Typography variant="SP_label_field" color="b_333">
                  〈裏面〉
                </Typography>
                <SPUploadImageItem
                  variant="imageOnly"
                  images={residentRegisterBackImage2}
                  setImages={setResidentRegisterBackImage2}
                  reload={reset}
                  uploadMulti={false}
                  handleDeleteImage={(id) => {
                    const resident_register_back_image = convertImage(
                      formik.values.p_applicant_people
                        .resident_register_back_image,
                      id
                    );
                    formik.setFieldValue(
                      'p_applicant_people.resident_register_back_image',
                      resident_register_back_image
                    );
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        ),
      },
    ],
    [
      errorDriverLicense,
      driverLicenseFrontImage2,
      setDriverLicenseFrontImage2,
      driverLicenseBackImage2,
      setDriverLicenseBackImage2,
      errorCardNumber,
      cardNumberFrontImage2,
      setCardNumberFrontImage2,
      errorResidentRegister,
      residentRegisterFrontImage2,
      setResidentRegisterFrontImage2,
      residentRegisterBackImage2,
      setResidentRegisterBackImage2,
      formik,
    ]
  );

  const { tax_return, type_tax_return, occupation } =
    spStepThreeIncomeTotalValues.p_applicant_people;

  const isFirstWithholdingSlipFile = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.NO ||
      tax_return === SPTaxReturnType.DEFAULT ||
      (tax_return === SPTaxReturnType.YES &&
        type_tax_return.every(
          (typeTaxReturn) =>
            typeTaxReturn !== FileATaxReturnType.ONE &&
            typeTaxReturn !== FileATaxReturnType.TWO &&
            typeTaxReturn !== FileATaxReturnType.THREE &&
            typeTaxReturn !== FileATaxReturnType.SIX &&
            typeTaxReturn !== FileATaxReturnType.OTHERS
        ))
    );
  }, [tax_return, type_tax_return]);

  const isSecondWithholdingSlipFile = useMemo(() => {
    return (
      (tax_return === SPTaxReturnType.NO ||
        tax_return === SPTaxReturnType.DEFAULT ||
        (tax_return === SPTaxReturnType.YES &&
          type_tax_return.every(
            (typeTaxReturn) =>
              typeTaxReturn !== FileATaxReturnType.ONE &&
              typeTaxReturn !== FileATaxReturnType.TWO &&
              typeTaxReturn !== FileATaxReturnType.THREE &&
              typeTaxReturn !== FileATaxReturnType.SIX &&
              typeTaxReturn !== FileATaxReturnType.OTHERS
          ))) &&
      occupation === OccupationType.ONE
    );
  }, [tax_return, type_tax_return, occupation]);

  const isEmploymentAgreementFile = useMemo(() => {
    return (
      (tax_return === SPTaxReturnType.NO ||
        tax_return === SPTaxReturnType.DEFAULT ||
        (tax_return === SPTaxReturnType.YES &&
          type_tax_return.every(
            (typeTaxReturn) =>
              typeTaxReturn !== FileATaxReturnType.ONE &&
              typeTaxReturn !== FileATaxReturnType.TWO &&
              typeTaxReturn !== FileATaxReturnType.THREE &&
              typeTaxReturn !== FileATaxReturnType.SIX &&
              typeTaxReturn !== FileATaxReturnType.OTHERS
          ))) &&
      (occupation === OccupationType.SIX ||
        occupation === OccupationType.SEVEN ||
        occupation === OccupationType.EIGHT)
    );
  }, [tax_return, type_tax_return, occupation]);

  const isFinancialStatementfile = useMemo(() => {
    return occupation === OccupationType.ONE;
  }, [occupation]);

  const isBusinessTaxReturnFilePlanB = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.YES &&
      type_tax_return.some(
        (typeTaxReturn) =>
          typeTaxReturn === FileATaxReturnType.ONE ||
          typeTaxReturn === FileATaxReturnType.TWO ||
          typeTaxReturn === FileATaxReturnType.THREE ||
          typeTaxReturn === FileATaxReturnType.SIX ||
          typeTaxReturn === FileATaxReturnType.OTHERS
      ) &&
      occupation !== OccupationType.ONE &&
      occupation !== OccupationType.FIVE &&
      occupation !== OccupationType.TEN
    );
  }, [tax_return, type_tax_return, occupation]);

  const isBusinessTaxReturnFilePlanA = useMemo(() => {
    return (
      (tax_return === SPTaxReturnType.NO ||
        tax_return === SPTaxReturnType.DEFAULT ||
        (tax_return === SPTaxReturnType.YES &&
          type_tax_return.every(
            (typeTaxReturn) =>
              typeTaxReturn !== FileATaxReturnType.ONE &&
              typeTaxReturn !== FileATaxReturnType.TWO &&
              typeTaxReturn !== FileATaxReturnType.THREE &&
              typeTaxReturn !== FileATaxReturnType.SIX &&
              typeTaxReturn !== FileATaxReturnType.OTHERS
          ))) &&
      occupation !== OccupationType.ONE &&
      occupation !== OccupationType.SIX &&
      occupation !== OccupationType.SEVEN &&
      occupation !== OccupationType.EIGHT
    );
  }, [tax_return, type_tax_return, occupation]);

  const isIncomeFile = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.YES &&
      type_tax_return.some(
        (typeTaxReturn) =>
          typeTaxReturn === FileATaxReturnType.ONE ||
          typeTaxReturn === FileATaxReturnType.TWO ||
          typeTaxReturn === FileATaxReturnType.THREE ||
          typeTaxReturn === FileATaxReturnType.SIX ||
          typeTaxReturn === FileATaxReturnType.OTHERS
      )
    );
  }, [tax_return, type_tax_return]);

  const isPlanA = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.NO ||
      tax_return === SPTaxReturnType.DEFAULT ||
      (tax_return === SPTaxReturnType.YES &&
        type_tax_return.some(
          (typeTaxReturn) =>
            typeTaxReturn === FileATaxReturnType.FOUR ||
            typeTaxReturn === FileATaxReturnType.FIVE
        ))
    );
  }, [tax_return, type_tax_return]);

  const isPlanB = useMemo(() => {
    return (
      tax_return === SPTaxReturnType.YES &&
      type_tax_return.some(
        (typeTaxReturn) =>
          typeTaxReturn === FileATaxReturnType.ONE ||
          typeTaxReturn === FileATaxReturnType.TWO ||
          typeTaxReturn === FileATaxReturnType.THREE ||
          typeTaxReturn === FileATaxReturnType.SIX ||
          typeTaxReturn === FileATaxReturnType.OTHERS
      )
    );
  }, [tax_return, type_tax_return]);

  useEffect(() => {
    // insurance_file
    let newInsuranceFileImage2: (SPImage | File)[] = [];
    if (
      !!insuranceFile2 &&
      insuranceFile2.length === 0 &&
      !!formik.values.p_applicant_people.insurance_file &&
      !!formik.values.p_applicant_people.insurance_file?.length
    ) {
      formik.values.p_applicant_people.insurance_file.forEach((file) => {
        if (!file.isDelete) {
          return newInsuranceFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!insuranceFile2 && !!insuranceFile2.length) {
      newInsuranceFileImage2 = [...insuranceFile2];
    } else {
      formik.values.p_applicant_people.insurance_file.forEach((file) => {
        if (!file.isDelete) {
          return newInsuranceFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setInsuranceFile2(newInsuranceFileImage2);
    // insurance_file_back_image
    let newInsuranceFileImage3: (SPImage | File)[] = [];
    if (
      !!insuranceFileBackImage2 &&
      insuranceFileBackImage2.length === 0 &&
      !!formik.values.p_applicant_people.insurance_file_back_image &&
      !!formik.values.p_applicant_people.insurance_file_back_image?.length
    ) {
      formik.values.p_applicant_people.insurance_file_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newInsuranceFileImage3.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!insuranceFileBackImage2 && !!insuranceFileBackImage2.length) {
      newInsuranceFileImage3 = [...insuranceFileBackImage2];
    } else {
      formik.values.p_applicant_people.insurance_file_back_image?.forEach(
        (file) => {
          if (!file.isDelete) {
            return newInsuranceFileImage3.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setInsuranceFileBackImage2(newInsuranceFileImage3);
    // financial_statement_1_file:
    let newFinancialStatement1FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement1File2 &&
      financialStatement1File2.length === 0 &&
      !!formik.values.p_applicant_people.financial_statement_1_file &&
      !!formik.values.p_applicant_people.financial_statement_1_file?.length
    ) {
      formik.values.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFinancialStatement1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement1File2 &&
      !!financialStatement1File2.length
    ) {
      newFinancialStatement1FileImage2 = [...financialStatement1File2];
    } else {
      formik.values.p_applicant_people.financial_statement_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFinancialStatement1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement1File2(newFinancialStatement1FileImage2);
    // financial_statement_2_file: financialStatement2File
    let newFinancialStatement2FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement2File2 &&
      financialStatement2File2.length === 0 &&
      !!formik.values.p_applicant_people.financial_statement_2_file &&
      !!formik.values.p_applicant_people.financial_statement_2_file?.length
    ) {
      formik.values.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFinancialStatement2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement2File2 &&
      !!financialStatement2File2.length
    ) {
      newFinancialStatement2FileImage2 = [...financialStatement2File2];
    } else {
      formik.values.p_applicant_people.financial_statement_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFinancialStatement2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement2File2(newFinancialStatement2FileImage2);
    // financial_statement_3_file
    let newFinancialStatement3FileImage2: (SPImage | File)[] = [];
    if (
      !!financialStatement3File2 &&
      financialStatement3File2.length === 0 &&
      !!formik.values.p_applicant_people.financial_statement_3_file &&
      !!formik.values.p_applicant_people.financial_statement_3_file?.length
    ) {
      formik.values.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFinancialStatement3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!financialStatement3File2 &&
      !!financialStatement3File2.length
    ) {
      newFinancialStatement3FileImage2 = [...financialStatement3File2];
    } else {
      formik.values.p_applicant_people.financial_statement_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFinancialStatement3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFinancialStatement3File2(newFinancialStatement3FileImage2);
    // first_withholding_slip_file
    let newFirstWithholdingSlipFileImage2: (SPImage | File)[] = [];
    if (
      !!firstWithholdingSlipFile2 &&
      firstWithholdingSlipFile2.length === 0 &&
      !!formik.values.p_applicant_people.first_withholding_slip_file &&
      !!formik.values.p_applicant_people.first_withholding_slip_file?.length
    ) {
      formik.values.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFirstWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!firstWithholdingSlipFile2 &&
      !!firstWithholdingSlipFile2.length
    ) {
      newFirstWithholdingSlipFileImage2 = [...firstWithholdingSlipFile2];
    } else {
      formik.values.p_applicant_people.first_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newFirstWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setFirstWithholdingSlipFile2(newFirstWithholdingSlipFileImage2);
    // second_withholding_slip_file
    let newSecondWithholdingSlipFileImage2: (SPImage | File)[] = [];
    if (
      !!secondWithholdingSlipFile2 &&
      secondWithholdingSlipFile2.length === 0 &&
      !!formik.values.p_applicant_people.second_withholding_slip_file &&
      !!formik.values.p_applicant_people.second_withholding_slip_file?.length
    ) {
      formik.values.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newSecondWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!secondWithholdingSlipFile2 &&
      !!secondWithholdingSlipFile2.length
    ) {
      newSecondWithholdingSlipFileImage2 = [...secondWithholdingSlipFile2];
    } else {
      formik.values.p_applicant_people.second_withholding_slip_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newSecondWithholdingSlipFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setSecondWithholdingSlipFile2(newSecondWithholdingSlipFileImage2);
    // other_document_file
    let newOtherDocumentFileImage2: (SPImage | File)[] = [];
    if (
      !!otherDocumentFile2 &&
      otherDocumentFile2.length === 0 &&
      !!formik.values.p_applicant_people.other_document_file &&
      !!formik.values.p_applicant_people.other_document_file?.length
    ) {
      formik.values.p_applicant_people.other_document_file.forEach((file) => {
        if (!file.isDelete) {
          return newOtherDocumentFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!otherDocumentFile2 && !!otherDocumentFile2.length) {
      newOtherDocumentFileImage2 = [...otherDocumentFile2];
    } else {
      formik.values.p_applicant_people.other_document_file.forEach((file) => {
        if (!file.isDelete) {
          return newOtherDocumentFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setOtherDocumentFile2(newOtherDocumentFileImage2);
    // employment_agreement_file
    let newEmploymentAgreementFileImage2: (SPImage | File)[] = [];
    if (
      !!employmentAgreementFile2 &&
      employmentAgreementFile2.length === 0 &&
      !!formik.values.p_applicant_people.employment_agreement_file &&
      !!formik.values.p_applicant_people.employment_agreement_file?.length
    ) {
      formik.values.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newEmploymentAgreementFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!employmentAgreementFile2 &&
      !!employmentAgreementFile2.length
    ) {
      newEmploymentAgreementFileImage2 = [...employmentAgreementFile2];
    } else {
      formik.values.p_applicant_people.employment_agreement_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newEmploymentAgreementFileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setEmploymentAgreementFile2(newEmploymentAgreementFileImage2);
    // business_tax_return_1_file
    let newBusinessTaxReturn1FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn1File2 &&
      businessTaxReturn1File2.length === 0 &&
      !!formik.values.p_applicant_people.business_tax_return_1_file &&
      !!formik.values.p_applicant_people.business_tax_return_1_file?.length
    ) {
      formik.values.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newBusinessTaxReturn1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn1File2 && !!businessTaxReturn1File2.length) {
      newBusinessTaxReturn1FileImage2 = [...businessTaxReturn1File2];
    } else {
      formik.values.p_applicant_people.business_tax_return_1_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newBusinessTaxReturn1FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn1File2(newBusinessTaxReturn1FileImage2);
    // business_tax_return_2_file
    let newBusinessTaxReturn2FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn2File2 &&
      businessTaxReturn2File2.length === 0 &&
      !!formik.values.p_applicant_people.business_tax_return_2_file &&
      !!formik.values.p_applicant_people.business_tax_return_2_file?.length
    ) {
      formik.values.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newBusinessTaxReturn2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn2File2 && !!businessTaxReturn2File2.length) {
      newBusinessTaxReturn2FileImage2 = [...businessTaxReturn2File2];
    } else {
      formik.values.p_applicant_people.business_tax_return_2_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newBusinessTaxReturn2FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn2File2(newBusinessTaxReturn2FileImage2);
    // business_tax_return_3_file
    let newBusinessTaxReturn3FileImage2: (SPImage | File)[] = [];
    if (
      !!businessTaxReturn3File2 &&
      businessTaxReturn3File2.length === 0 &&
      !!formik.values.p_applicant_people.business_tax_return_3_file &&
      !!formik.values.p_applicant_people.business_tax_return_3_file?.length
    ) {
      formik.values.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newBusinessTaxReturn3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!businessTaxReturn3File2 && !!businessTaxReturn3File2.length) {
      newBusinessTaxReturn3FileImage2 = [...businessTaxReturn3File2];
    } else {
      formik.values.p_applicant_people.business_tax_return_3_file.forEach(
        (file) => {
          if (!file.isDelete) {
            return newBusinessTaxReturn3FileImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setBusinessTaxReturn3File2(newBusinessTaxReturn3FileImage2);
    // first_income_file
    let newFirstIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!firstIncomeFile2 &&
      firstIncomeFile2.length === 0 &&
      !!formik.values.p_applicant_people.first_income_file &&
      !!formik.values.p_applicant_people.first_income_file?.length
    ) {
      formik.values.p_applicant_people.first_income_file.forEach((file) => {
        if (!file.isDelete) {
          return newFirstIncomeFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!firstIncomeFile2 && !!firstIncomeFile2.length) {
      newFirstIncomeFileImage2 = [...firstIncomeFile2];
    } else {
      formik.values.p_applicant_people.first_income_file.forEach((file) => {
        if (!file.isDelete) {
          return newFirstIncomeFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setFirstIncomeFile2(newFirstIncomeFileImage2);
    // second_income_file
    let newSecondIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!secondIncomeFile2 &&
      secondIncomeFile2.length === 0 &&
      !!formik.values.p_applicant_people.second_income_file &&
      !!formik.values.p_applicant_people.second_income_file?.length
    ) {
      formik.values.p_applicant_people.second_income_file.forEach((file) => {
        if (!file.isDelete) {
          return newSecondIncomeFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!secondIncomeFile2 && !!secondIncomeFile2.length) {
      newSecondIncomeFileImage2 = [...secondIncomeFile2];
    } else {
      formik.values.p_applicant_people.second_income_file.forEach((file) => {
        if (!file.isDelete) {
          return newSecondIncomeFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setSecondIncomeFile2(newSecondIncomeFileImage2);
    // third_income_file
    let newThirdIncomeFileImage2: (SPImage | File)[] = [];
    if (
      !!thirdIncomeFile2 &&
      thirdIncomeFile2.length === 0 &&
      !!formik.values.p_applicant_people.third_income_file &&
      !!formik.values.p_applicant_people.third_income_file?.length
    ) {
      formik.values.p_applicant_people.third_income_file.forEach((file) => {
        if (!file.isDelete) {
          return newThirdIncomeFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    } else if (!!thirdIncomeFile2 && !!thirdIncomeFile2.length) {
      newThirdIncomeFileImage2 = [...thirdIncomeFile2];
    } else {
      formik.values.p_applicant_people.third_income_file.forEach((file) => {
        if (!file.isDelete) {
          return newThirdIncomeFileImage2.push({
            id: file.id,
            url: file.url,
            name: file.filename,
            hiden: file.hiden,
          });
        }
      });
    }
    setThirdIncomeFile2(newThirdIncomeFileImage2);
    // driver_license_front_image
    let newDriverLicenseFrontImage2: (SPImage | File)[] = [];
    if (
      !!driverLicenseFrontImage2 &&
      driverLicenseFrontImage2.length === 0 &&
      !!formik.values.p_applicant_people.driver_license_front_image &&
      !!formik.values.p_applicant_people.driver_license_front_image?.length
    ) {
      formik.values.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newDriverLicenseFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!driverLicenseFrontImage2 &&
      !!driverLicenseFrontImage2.length
    ) {
      newDriverLicenseFrontImage2 = [...driverLicenseFrontImage2];
    } else {
      formik.values.p_applicant_people.driver_license_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newDriverLicenseFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseFrontImage2(newDriverLicenseFrontImage2);
    // driver_license_back_image
    let newDriverLicenseBackImage2: (SPImage | File)[] = [];
    if (
      !!driverLicenseBackImage2 &&
      driverLicenseBackImage2.length === 0 &&
      !!formik.values.p_applicant_people.driver_license_back_image &&
      !!formik.values.p_applicant_people.driver_license_back_image?.length
    ) {
      formik.values.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newDriverLicenseBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!driverLicenseBackImage2 && !!driverLicenseBackImage2.length) {
      newDriverLicenseBackImage2 = [...driverLicenseBackImage2];
    } else {
      formik.values.p_applicant_people.driver_license_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newDriverLicenseBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setDriverLicenseBackImage2(newDriverLicenseBackImage2);
    // card_number_front_image: cardNumberFrontImage
    let newCardNumberFrontImage2: (SPImage | File)[] = [];
    if (
      !!cardNumberFrontImage2 &&
      cardNumberFrontImage2.length === 0 &&
      !!formik.values.p_applicant_people.card_number_front_image &&
      !!formik.values.p_applicant_people.card_number_front_image?.length
    ) {
      formik.values.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newCardNumberFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (!!cardNumberFrontImage2 && !!cardNumberFrontImage2.length) {
      newCardNumberFrontImage2 = [...cardNumberFrontImage2];
    } else {
      formik.values.p_applicant_people.card_number_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newCardNumberFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setCardNumberFrontImage2(newCardNumberFrontImage2);
    // card_number_back_image
    // let newCardNumberBackImage2: (SPImage | File)[] = [];
    // if (
    //   !!cardNumberBackImage2 &&
    //   cardNumberBackImage2.length === 0 &&
    //   !!formik.values.p_applicant_people.card_number_back_image &&
    //   !!formik.values.p_applicant_people.card_number_back_image?.length
    // ) {
    //   formik.values.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         return newCardNumberBackImage2.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // } else if (!!cardNumberBackImage2 && !!cardNumberBackImage2.length) {
    //   newCardNumberBackImage2 = [...cardNumberBackImage2];
    // } else {
    //   formik.values.p_applicant_people.card_number_back_image.forEach(
    //     (file) => {
    //       if (!file.isDelete) {
    //         return newCardNumberBackImage2.push({
    //           id: file.id,
    //           url: file.url,
    //           name: file.filename,
    //           hiden: file.hiden,
    //         });
    //       }
    //     }
    //   );
    // }
    // setCardNumberBackImage2(newCardNumberBackImage2);
    // resident_register_front_image
    let newResidentRegisterFrontImage2: (SPImage | File)[] = [];
    if (
      !!residentRegisterFrontImage2 &&
      residentRegisterFrontImage2.length === 0 &&
      !!formik.values.p_applicant_people.resident_register_front_image &&
      !!formik.values.p_applicant_people.resident_register_front_image?.length
    ) {
      formik.values.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidentRegisterFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterFrontImage2 &&
      !!residentRegisterFrontImage2.length
    ) {
      newResidentRegisterFrontImage2 = [...residentRegisterFrontImage2];
    } else {
      formik.values.p_applicant_people.resident_register_front_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidentRegisterFrontImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterFrontImage2(newResidentRegisterFrontImage2);
    // resident_register_back_image
    let newResidentRegisterBackImage2: (SPImage | File)[] = [];
    if (
      !!residentRegisterBackImage2 &&
      residentRegisterBackImage2.length === 0 &&
      !!formik.values.p_applicant_people.resident_register_back_image &&
      !!formik.values.p_applicant_people.resident_register_back_image?.length
    ) {
      formik.values.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidentRegisterBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    } else if (
      !!residentRegisterBackImage2 &&
      !!residentRegisterBackImage2.length
    ) {
      newResidentRegisterBackImage2 = [...residentRegisterBackImage2];
    } else {
      formik.values.p_applicant_people.resident_register_back_image.forEach(
        (file) => {
          if (!file.isDelete) {
            return newResidentRegisterBackImage2.push({
              id: file.id,
              url: file.url,
              name: file.filename,
              hiden: file.hiden,
            });
          }
        }
      );
    }
    setResidentRegisterBackImage2(newResidentRegisterBackImage2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveDraft = async () => {
    const data = new FormData();

    data.append('draft[current_step]', currentStep);

    /*---GET DATA FROM STEP 1---*/
    const {
      p_applicant_people_attributes,
      p_borrowing_details_attributes,
      master_bank_ids,
      ...restFields
    } = spStepOneValues.p_application_header;

    Object.keys(restFields).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepOneValues.p_application_header, key) ?? ''
      );
    });

    Object.keys(p_applicant_people_attributes).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepOneValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    data.append(
      'draft[p_borrowing_details]',
      JSON.stringify(
        spStepOneValues.p_application_header.p_borrowing_details_attributes
      )
    );

    spStepOneValues.p_application_header.master_bank_ids.forEach((id) => {
      data.append(`draft[p_application_header][master_bank_ids][]`, id);
    });

    /*---GET DATA FROM STEP 2---*/
    const { residence_file, residence_file_back_image, ...spStepTwoValue } =
      spStepTwoValues.p_applicant_people;
    Object.keys(spStepTwoValue).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepTwoValue, key) ?? ''
      );
    });

    residenceFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file][]`,
        file.id.toString()
      );
    });

    residenceFileBackImage.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_applicant_people][0][residence_file_back_image][]',
          file
        );
      data.append(
        `draft[p_applicant_people][0][residence_file_back_image][]`,
        file.id.toString()
      );
    });

    /*---GET DATA FROM STEP 3---*/
    Object.keys(spStepThreeValues.p_applicant_people).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(spStepThreeValues.p_applicant_people, key) ?? ''
      );
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 4 INCOME TOTAL---*/
      const {
        residence_file,
        residence_file_back_image,
        ...spStepTwoIncomeValue
      } = spStepTwoIncomeTotalValues.p_applicant_people;
      Object.keys(spStepTwoIncomeValue).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepTwoIncomeValue, key) ?? ''
        );
      });

      residenceIncomeFile.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file][]`,
          file.id.toString()
        );
      });

      residenceIncomeFileBackImage.forEach((file) => {
        if (file instanceof File)
          return data.append(
            'draft[p_applicant_people][1][residence_file_back_image][]',
            file
          );
        data.append(
          `draft[p_applicant_people][1][residence_file_back_image][]`,
          file.id.toString()
        );
      });

      /*---GET DATA FROM STEP 5 INCOME TOTAL---*/
      const { income_source, type_tax_return, ...spStepThreeIncomeTotal } =
        spStepThreeIncomeTotalValues.p_applicant_people;
      Object.keys(spStepThreeIncomeTotal).forEach((key) => {
        data.append(
          `draft[p_applicant_people][1][${key}]`,
          getIn(spStepThreeIncomeTotal, key) ?? ''
        );
      });

      income_source.forEach((id) => {
        data.append(`draft[p_applicant_people][1][income_source][]`, id);
      });

      type_tax_return.forEach((id) => {
        data.append(`draft[p_applicant_people][1][type_tax_return][]`, id);
      });
    }

    /*---GET DATA FROM STEP 4 COLLATERAL PROVIDER---*/
    data.append(
      `draft[p_join_guarantors]`,
      JSON.stringify(spStepCollateralProviderValues.p_join_guarantors)
    );

    /*---GET DATA FROM STEP 4---*/
    const {
      planned_cohabitant,
      business_ability,
      p_applicant_people_attributes: stepFourApplicantPeople,
      p_residents_attributes,
      property_information_file,
      loan_type,
      ...restApplicationHeader
    } = spStepFourValues.p_application_header;

    business_ability.forEach((businessAbility) => {
      data.append(
        `draft[p_application_header][business_ability][]`,
        businessAbility.toString()
      );
    });

    planned_cohabitant.forEach((plannedCohabitant) => {
      data.append(
        `draft[p_application_header][planned_cohabitant][]`,
        plannedCohabitant.toString()
      );
    });

    propertyInformationFile.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][property_information_file][]',
          file
        );
      data.append(
        `draft[p_application_header][property_information_file][]`,
        file.id.toString()
      );
    });

    Object.keys(restApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(restApplicationHeader, key) ?? ''
      );
    });

    Object.keys(
      spStepFourValues.p_application_header.p_applicant_people_attributes
    ).forEach((key) => {
      data.append(
        `draft[p_applicant_people][0][${key}]`,
        getIn(
          spStepFourValues.p_application_header.p_applicant_people_attributes,
          key
        ) ?? ''
      );
    });

    if (
      !isEmpty(spStepFourValues.p_application_header.p_residents_attributes)
    ) {
      data.append(
        `draft[p_residents]`,
        JSON.stringify([
          spStepFourValues.p_application_header.p_residents_attributes,
        ])
      );
    } else {
      data.append(`draft[p_residents]`, JSON.stringify([]));
    }

    /*---GET DATA FROM STEP 5---*/
    const {
      p_borrowings_attributes,
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
      land_rent_to_be_paid_borrower,
      land_rent_to_be_paid,
      house_rent_to_be_paid_borrower,
      house_rent_to_be_paid,
    } = spStepFiveValues;

    const stepFiveApplicationHeader = {
      completely_repayment_type,
      other_complete_repayment_type,
      refund_content,
      refund_amount,
    };

    Object.keys(stepFiveApplicationHeader).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        get(stepFiveApplicationHeader, key) ?? ''
      );
    });

    switch (land_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][land_rent_to_be_paid]`,
          land_rent_to_be_paid
        );
        break;
    }

    switch (house_rent_to_be_paid_borrower) {
      case BorrowerIncomeType.APPLICANT_SELF:
        data.append(
          `draft[p_applicant_people][0][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
      case BorrowerIncomeType.INCOME_TOTAL:
        data.append(
          `draft[p_applicant_people][1][house_rent_to_be_paid]`,
          house_rent_to_be_paid
        );
        break;
    }

    if (incomeTotalizer) {
      spStepFiveValues.p_applicant_people_attributes
        .map((person) => ({ borrowing_status: person.borrowing_status }))
        .forEach((person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        });
    } else {
      spStepFiveValues.p_applicant_people_attributes.forEach(
        (person, index) => {
          Object.keys(person).forEach((key) => {
            data.append(
              `draft[p_applicant_people][${index}][${key}]`,
              get(person, key) ?? ''
            );
          });
        }
      );
    }

    const checkBorrowingInput = p_borrowings_attributes.map((borrowing) => {
      if (borrowing.self_input) return borrowing;
      const {
        self_input,
        borrowing_type,
        repayment_schedule_image,
        borrower,
        ...restBorrowing
      } = borrowing;
      Object.keys(restBorrowing).forEach((key) => {
        update(restBorrowing, key, () => {
          return '';
        });
      });

      return {
        self_input,
        borrowing_type,
        borrower,
        ...restBorrowing,
      };
    });

    checkBorrowingInput.forEach((borrowing, index) => {
      Object.keys(borrowing).forEach((key) => {
        data.append(
          `draft[p_borrowings][${index}][${key}]`,
          get(borrowing, key) ?? ''
        );
      });
    });

    repaymentScheduleImage.forEach((files, index) => {
      files.forEach((file) => {
        if (file instanceof File)
          return data.append(
            `draft[p_borrowings][${index}][repayment_schedule_image][]`,
            file
          );
        data.append(
          `draft[p_borrowings][${index}][repayment_schedule_image][]`,
          file.id.toString()
        );
      });
    });

    /*---GET DATA FROM STEP 6---*/
    Object.keys(spStepSixValues.p_application_header).forEach((key) => {
      data.append(
        `draft[p_application_header][${key}]`,
        getIn(spStepSixValues.p_application_header, key) ?? ''
      );
    });

    /*---GET DATA FROM STEP 7---*/
    const { identity_verification } = spStepSevenValues.p_applicant_people;
    data.append(
      `draft[p_applicant_people][0][identity_verification]`,
      identity_verification
    );

    const imageStepSeven = {
      insurance_file: insuranceFile,
      insurance_file_back_image: insuranceFileBackImage,
      financial_statement_1_file: financialStatement1File,
      financial_statement_2_file: financialStatement2File,
      financial_statement_3_file: financialStatement3File,
      first_withholding_slip_file: firstWithholdingSlipFile,
      second_withholding_slip_file: secondWithholdingSlipFile,
      other_document_file: otherDocumentFile,
      employment_agreement_file: employmentAgreementFile,
      business_tax_return_1_file: businessTaxReturn1File,
      business_tax_return_2_file: businessTaxReturn2File,
      business_tax_return_3_file: businessTaxReturn3File,
      first_income_file: firstIncomeFile,
      second_income_file: secondIncomeFile,
      third_income_file: thirdIncomeFile,
      driver_license_front_image: driverLicenseFrontImage,
      driver_license_back_image: driverLicenseBackImage,
      card_number_front_image: cardNumberFrontImage,
      // card_number_back_image: cardNumberBackImage,
      resident_register_front_image: residentRegisterFrontImage,
      resident_register_back_image: residentRegisterBackImage,
    };

    Object.keys(imageStepSeven).forEach((key) => {
      get(imageStepSeven, key, []).forEach((file: File | SPImage) => {
        const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
          (item) => item === key
        );
        if (file instanceof File) {
          isNotUploadMulti
            ? data.append(`draft[p_applicant_people][0][${key}]`, file)
            : data.append(`draft[p_applicant_people][0][${key}][]`, file);
        } else {
          isNotUploadMulti
            ? data.append(
                `draft[p_applicant_people][0][${key}]`,
                file.id.toString()
              )
            : data.append(
                `draft[p_applicant_people][0][${key}][]`,
                file.id.toString()
              );
        }
      });
    });

    if (incomeTotalizer) {
      /*---GET DATA FROM STEP 7 INCOME TOTAL---*/
      const { identity_verification } = formik.values.p_applicant_people;
      data.append(
        `draft[p_applicant_people][1][identity_verification]`,
        identity_verification
      );

      const imageStepSevenIncome = {
        insurance_file: insuranceFile2,
        insurance_file_back_image: insuranceFileBackImage2,
        financial_statement_1_file: financialStatement1File2,
        financial_statement_2_file: financialStatement2File2,
        financial_statement_3_file: financialStatement3File2,
        first_withholding_slip_file: firstWithholdingSlipFile2,
        second_withholding_slip_file: secondWithholdingSlipFile2,
        other_document_file: otherDocumentFile2,
        employment_agreement_file: employmentAgreementFile2,
        business_tax_return_1_file: businessTaxReturn1File2,
        business_tax_return_2_file: businessTaxReturn2File2,
        business_tax_return_3_file: businessTaxReturn3File2,
        first_income_file: firstIncomeFile2,
        second_income_file: secondIncomeFile2,
        third_income_file: thirdIncomeFile2,
        driver_license_front_image: driverLicenseFrontImage2,
        driver_license_back_image: driverLicenseBackImage2,
        card_number_front_image: cardNumberFrontImage2,
        // card_number_back_image: cardNumberBackImage2,
        resident_register_front_image: residentRegisterFrontImage2,
        resident_register_back_image: residentRegisterBackImage2,
      };

      Object.keys(imageStepSevenIncome).forEach((key) => {
        get(imageStepSevenIncome, key, []).forEach((file: File | SPImage) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          if (file instanceof File) {
            isNotUploadMulti
              ? data.append(`draft[p_applicant_people][1][${key}]`, file)
              : data.append(`draft[p_applicant_people][1][${key}][]`, file);
          } else {
            isNotUploadMulti
              ? data.append(
                  `draft[p_applicant_people][1][${key}]`,
                  file.id.toString()
                )
              : data.append(
                  `draft[p_applicant_people][1][${key}][]`,
                  file.id.toString()
                );
          }
        });
      });
    }

    /*---GET DATA FROM STEP 8---*/
    const { business_card, ...spStepEight } =
      spStepEightValues.p_referral_agencies;
    Object.keys(spStepEight).forEach((key) => {
      data.append(
        `draft[p_referral_agency][${key}]`,
        getIn(spStepEight, key) ?? ''
      );
    });
    businessCard.forEach((file) => {
      if (file instanceof File)
        return data.append(
          'draft[p_application_header][business_card][]',
          file
        );
      data.append(
        `draft[p_application_header][business_card][]`,
        file.id.toString()
      );
    });

    data.append(
      'draft[p_application_header][general_income_confirmation]',
      spStepTwoIncomeTotalValues.status.firstElement === true &&
        spStepTwoIncomeTotalValues.status.secondElement === true
        ? GeneralIncomeConfirmation.CONFIRM
        : GeneralIncomeConfirmation.NOT_CONFIRM
    );

    const checkListImages: Array<File | SPImage> = [
      ...insuranceFile,
      ...insuranceFileBackImage,
      ...financialStatement1File,
      ...financialStatement2File,
      ...financialStatement3File,
      ...firstWithholdingSlipFile,
      ...secondWithholdingSlipFile,
      ...otherDocumentFile,
      ...employmentAgreementFile,
      ...businessTaxReturn1File,
      ...businessTaxReturn2File,
      ...businessTaxReturn3File,
      ...firstIncomeFile,
      ...secondIncomeFile,
      ...thirdIncomeFile,
      ...residenceFile,
      ...residenceFileBackImage,
      ...residenceIncomeFile,
      ...residenceIncomeFileBackImage,
      ...businessCard,
      ...driverLicenseFrontImage,
      ...driverLicenseBackImage,
      ...cardNumberFrontImage,
      ...cardNumberBackImage,
      ...residentRegisterFrontImage,
      ...residentRegisterBackImage,
      ...propertyInformationFile,
      ...insuranceFile2,
      ...insuranceFileBackImage2,
      ...financialStatement1File2,
      ...financialStatement2File2,
      ...financialStatement3File2,
      ...firstWithholdingSlipFile2,
      ...secondWithholdingSlipFile2,
      ...otherDocumentFile2,
      ...employmentAgreementFile2,
      ...businessTaxReturn1File2,
      ...businessTaxReturn2File2,
      ...businessTaxReturn3File2,
      ...firstIncomeFile2,
      ...secondIncomeFile2,
      ...thirdIncomeFile2,
      ...driverLicenseFrontImage2,
      ...driverLicenseBackImage2,
      ...cardNumberFrontImage2,
      ...cardNumberBackImage2,
      ...residentRegisterFrontImage2,
      ...residentRegisterBackImage2,
    ];

    repaymentScheduleImage.forEach((repaymentSchedule) => {
      repaymentSchedule.forEach((image) => {
        checkListImages.push(image);
      });
    });

    const checkFiles = await checkingExistFile(checkListImages);
    if (!checkFiles) {
      setOpenImageModal(true);
    }

    /*---DISPATCH ACTION SAVE DRAFT---*/
    const result = await dispatch(spSaveDraft(data));
    if (spSaveDraft.fulfilled.match(result)) {
      setOpen(true);
    }
    if (spSaveDraft.rejected.match(result)) {
      setReset(reset + 1);
    }
    return;
  };

  const checkRequiredDriverLicense = useMemo(() => {
    if (
      formik.values.p_applicant_people.identity_verification ===
      IdentityVerificationType.DRIVER_LICENSE
    )
      return (
        !!driverLicenseFrontImage2.length && !!driverLicenseBackImage2.length
      );
    return true;
  }, [
    driverLicenseBackImage2.length,
    driverLicenseFrontImage2.length,
    formik.values.p_applicant_people.identity_verification,
  ]);

  const checkRequiredCardNumber = useMemo(() => {
    if (
      formik.values.p_applicant_people.identity_verification ===
      IdentityVerificationType.MY_NUMBER_CARD
    )
      return !!cardNumberFrontImage2.length;
    return true;
  }, [
    formik.values.p_applicant_people.identity_verification,
    cardNumberFrontImage2.length,
  ]);

  const checkRequiredResidentRegister = useMemo(() => {
    if (
      formik.values.p_applicant_people.identity_verification ===
      IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD
    )
      return (
        !!residentRegisterFrontImage2.length &&
        !!residentRegisterBackImage2.length
      );
    return true;
  }, [
    formik.values.p_applicant_people.identity_verification,
    residentRegisterBackImage2.length,
    residentRegisterFrontImage2.length,
  ]);

  useEffect(() => {
    if (
      formik.values.p_applicant_people.identity_verification ===
        IdentityVerificationType.DRIVER_LICENSE &&
      !!driverLicenseBackImage2.length &&
      !!driverLicenseFrontImage2.length
    ) {
      setErrorDriverLicense(false);
    }
  }, [
    driverLicenseBackImage2.length,
    driverLicenseFrontImage2.length,
    formik.values.p_applicant_people.identity_verification,
  ]);

  useEffect(() => {
    if (
      formik.values.p_applicant_people.identity_verification ===
        IdentityVerificationType.MY_NUMBER_CARD &&
      !!cardNumberFrontImage2.length
    ) {
      setErrorCardNumber(false);
    }
  }, [
    cardNumberFrontImage2.length,
    formik.values.p_applicant_people.identity_verification,
  ]);

  useEffect(() => {
    if (
      formik.values.p_applicant_people.identity_verification ===
        IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD &&
      !!residentRegisterFrontImage2.length &&
      !!residentRegisterBackImage2.length
    ) {
      setErrorResidentRegister(false);
    }
  }, [
    formik.values.p_applicant_people.identity_verification,
    residentRegisterBackImage2.length,
    residentRegisterFrontImage2.length,
  ]);

  const handleSubmit = useCallback(() => {
    if (
      !checkRequiredDriverLicense ||
      !checkRequiredCardNumber ||
      !checkRequiredResidentRegister
    ) {
      imageRef.current?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
    // setIsDisabledButton(false);
    if (!checkRequiredDriverLicense) return setErrorDriverLicense(true);
    if (!checkRequiredCardNumber) return setErrorCardNumber(true);
    if (!checkRequiredResidentRegister) return setErrorResidentRegister(true);
    if (!!application_number && !formik.dirty) {
      setIsDisabledButton(true);
    }
    formik.handleSubmit();
  }, [
    application_number,
    checkRequiredCardNumber,
    checkRequiredDriverLicense,
    checkRequiredResidentRegister,
    formik,
  ]);

  const handleChangeIdentityVerification = useCallback(
    (checked: boolean, value: string) => {
      switch (value) {
        case IdentityVerificationType.DRIVER_LICENSE:
          if (errorCardNumber) setErrorCardNumber(false);
          if (errorResidentRegister) setErrorResidentRegister(false);
          break;
        case IdentityVerificationType.MY_NUMBER_CARD:
          if (errorDriverLicense) setErrorDriverLicense(false);
          if (errorResidentRegister) setErrorResidentRegister(false);
          break;
        case IdentityVerificationType.BASIC_RESIDENT_REGISTER_CARD:
          if (errorCardNumber) setErrorCardNumber(false);
          if (errorDriverLicense) setErrorDriverLicense(false);
          break;
        default:
          break;
      }
    },
    [errorCardNumber, errorDriverLicense, errorResidentRegister]
  );

  return (
    <FormikProvider value={formik}>
      <SPStepLayout
        hasStepBar
        hasModalSaveDraft
        onSaveForm={() =>
          dispatch(
            saveForm({
              p_application_header: {
                p_applicant_people_attributes: [
                  formik.values.p_applicant_people,
                ],
              },
            })
          )
        }
        footer={{
          left: {
            onClick: () => {
              dispatch(
                saveForm({
                  p_application_header: {
                    p_applicant_people_attributes: [
                      formik.values.p_applicant_people,
                    ],
                  },
                })
              );
              if (!!application_number) {
                navigate(-1);
              } else {
                navigate(routeNames.SPStepSeven.path);
              }
            },
          },
          right: {
            children: isSubmit ? '保存' : '次へ',
            onClick: handleSubmit,
            disabled: formik.isSubmitting,
          },
        }}
        sx={{ minHeight: supportDvh('calc(100dvh - 178px)') }}
        onSaveDraft={onSaveDraft}
        hasJoinGuarantor={hasJoinGuarantor}
        hasTotalIncome={incomeTotalizer}
      >
        <Stack sx={{ width: '100%' }}>
          <Stack
            sx={{ pt: 9, pb: 4 }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >
              本人確認書類など
            </Typography>
            <Typography
              variant="SP_title_email_screen"
              color="sp_primary_100_main"
              sx={{
                whiteSpace: 'break-spaces',
                textAlign: 'center',
              }}
            >
              書類を添付しましょう
            </Typography>
            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={1}
              sx={{ mx: 4, mt: 5 }}
            >
              <Typography variant="SP_form_item_note" color="b_333">
                ※
              </Typography>
              <Stack>
                <Typography
                  variant="SP_form_item_note"
                  color="b_333"
                  whiteSpace="nowrap"
                >
                  審査状況により他の資料提出をお願いすることがあります
                </Typography>
                <Typography variant="SP_form_item_note" color="b_333">
                  のでご了承ください。
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <SPFormItem
          required
          label="本人確認書類"
          note="※どれか一つ選択してください"
        >
          <Stack ref={imageRef}>
            <SPRadioGroupButton
              name="p_applicant_people.identity_verification"
              options={identityVerificationOption}
              onChange={handleChangeIdentityVerification}
              required
            />
          </Stack>
        </SPFormItem>
        <SPFormItem required label="健康保険証">
          <Stack
            spacing={1}
            direction="row"
            sx={{
              bgcolor: 'main_white',
              borderRadius: 2,
              p: 3,
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Stack>
              <Typography variant="SP_label_field" color="b_333">
                〈表面〉
              </Typography>
              <SPUploadImageItem
                variant="imageOnly"
                images={insuranceFile2}
                setImages={setInsuranceFile2}
                reload={reset}
                uploadMulti={false}
                handleDeleteImage={(id) => {
                  const insurance_file = convertImage(
                    formik.values.p_applicant_people.insurance_file,
                    id
                  );
                  formik.setFieldValue(
                    'p_applicant_people.insurance_file',
                    insurance_file
                  );
                }}
              />
            </Stack>
            <Stack>
              <Typography variant="SP_label_field" color="b_333">
                〈裏面〉
              </Typography>
              <SPUploadImageItem
                variant="imageOnly"
                images={insuranceFileBackImage2}
                setImages={setInsuranceFileBackImage2}
                reload={reset}
                uploadMulti={false}
                handleDeleteImage={(id) => {
                  const insurance_file_back_image = convertImage(
                    formik.values.p_applicant_people.insurance_file_back_image,
                    id
                  );
                  formik.setFieldValue(
                    'p_applicant_people.insurance_file_back_image',
                    insurance_file_back_image
                  );
                }}
              />
            </Stack>
          </Stack>
        </SPFormItem>

        {/*Plan A*/}
        {isPlanA && !isPlanB && (
          <Stack>
            {isFirstWithholdingSlipFile && (
              <SPFormItem required label="収入に関する書類">
                <Stack spacing={3}>
                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      源泉徴収票（前年度分）
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={firstWithholdingSlipFile2}
                      reload={reset}
                      setImages={setFirstWithholdingSlipFile2}
                      handleDeleteImage={(id) => {
                        const first_withholding_slip_file = convertImage(
                          formik.values.p_applicant_people
                            .first_withholding_slip_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.first_withholding_slip_file',
                          first_withholding_slip_file
                        );
                      }}
                    />
                  </Stack>

                  {isSecondWithholdingSlipFile && (
                    <Stack
                      sx={{
                        bgcolor: 'main_white',
                        borderRadius: 2,
                        pb: 3,
                        pr: 3,
                        pl: 3,
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                      }}
                    >
                      <Typography
                        variant="SP_label_field"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        源泉徴収票（前々年度分）
                      </Typography>
                      <SPUploadImageItem
                        variant="imageOnly"
                        images={secondWithholdingSlipFile2}
                        reload={reset}
                        setImages={setSecondWithholdingSlipFile2}
                        handleDeleteImage={(id) => {
                          const second_withholding_slip_file = convertImage(
                            formik.values.p_applicant_people
                              .second_withholding_slip_file,
                            id
                          );
                          formik.setFieldValue(
                            'p_applicant_people.second_withholding_slip_file',
                            second_withholding_slip_file
                          );
                        }}
                      />
                    </Stack>
                  )}
                </Stack>
              </SPFormItem>
            )}

            {isFinancialStatementfile && (
              <SPFormItem
                required
                label={
                  <Stack>
                    <Typography variant="SP_form_item_label" color="b_333">
                      非上場企業の役員の方は
                    </Typography>
                    <Typography variant="SP_form_item_label" color="b_333">
                      下記の書類も添付してください。
                    </Typography>
                  </Stack>
                }
              >
                <Stack spacing={3}>
                  <Stack spacing={3}>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Stack>
                        <Typography variant="sp_note_text" color="b_333">
                          枚数が多い場合はPDFファイルを
                          <Stack flexDirection="row" alignItems="center">
                            <Typography variant="sp_note_text" color="b_333">
                              <SPEmailLink email="ssnbmbk_info@ssnbagent.netbk.co.jp" />{' '}
                              へメール送付してください。
                            </Typography>
                          </Stack>
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography variant="sp_note_text" color="b_333">
                      ※ 3期分無い場合は、直近のみで結構です。
                    </Typography>
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      会社の決算報告書（1期前）
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={financialStatement1File2}
                      reload={reset}
                      setImages={setFinancialStatement1File2}
                      handleDeleteImage={(id) => {
                        const financial_statement_1_file = convertImage(
                          formik.values.p_applicant_people
                            .financial_statement_1_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.financial_statement_1_file',
                          financial_statement_1_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      会社の決算報告書（2期前）
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={financialStatement2File2}
                      reload={reset}
                      setImages={setFinancialStatement2File2}
                      handleDeleteImage={(id) => {
                        const financial_statement_2_file = convertImage(
                          formik.values.p_applicant_people
                            .financial_statement_2_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.financial_statement_2_file',
                          financial_statement_2_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      会社の決算報告書（3期前）
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={financialStatement3File2}
                      reload={reset}
                      setImages={setFinancialStatement3File2}
                      handleDeleteImage={(id) => {
                        const financial_statement_3_file = convertImage(
                          formik.values.p_applicant_people
                            .financial_statement_3_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.financial_statement_3_file',
                          financial_statement_3_file
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </SPFormItem>
            )}

            {isEmploymentAgreementFile && (
              <SPFormItem required label="雇用契約に関する書類">
                <Stack
                  sx={{
                    bgcolor: 'main_white',
                    borderRadius: 2,
                    pb: 3,
                    pr: 3,
                    pl: 3,
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <Typography
                    variant="SP_label_field"
                    color="b_333"
                    sx={{ mt: 3 }}
                  >
                    雇用契約書
                  </Typography>
                  <SPUploadImageItem
                    variant="imageOnly"
                    images={employmentAgreementFile2}
                    reload={reset}
                    setImages={setEmploymentAgreementFile2}
                    handleDeleteImage={(id) => {
                      const employment_agreement_file = convertImage(
                        formik.values.p_applicant_people
                          .employment_agreement_file,
                        id
                      );
                      formik.setFieldValue(
                        'p_applicant_people.employment_agreement_file',
                        employment_agreement_file
                      );
                    }}
                  />
                </Stack>
              </SPFormItem>
            )}

            {isBusinessTaxReturnFilePlanA && (
              <SPFormItem
                required
                label={
                  <Stack>
                    <Typography variant="SP_form_item_label" color="b_333">
                      親族経営の会社等にご勤務の方は
                    </Typography>
                    <Typography variant="SP_form_item_label" color="b_333">
                      下記の書類も添付してください。
                    </Typography>
                  </Stack>
                }
              >
                <Stack spacing={3}>
                  <Stack spacing={3}>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Stack>
                        <Typography variant="sp_note_text" color="b_333">
                          枚数が多い場合はPDFファイルを
                          <Stack flexDirection="row" alignItems="center">
                            <Typography variant="sp_note_text" color="b_333">
                              <SPEmailLink email="ssnbmbk_info@ssnbagent.netbk.co.jp" />{' '}
                              へメール送付してください。
                            </Typography>
                          </Stack>
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Typography variant="sp_note_text" color="b_333">
                        3期分無い場合は、直近のみで結構です。
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書
                      </Typography>
                      <Typography variant="sp_name_text" color="b_333">
                        または経営する親族の確定申告書（1期前）
                      </Typography>
                    </Stack>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={businessTaxReturn1File2}
                      reload={reset}
                      setImages={setBusinessTaxReturn1File2}
                      handleDeleteImage={(id) => {
                        const business_tax_return_1_file = convertImage(
                          formik.values.p_applicant_people
                            .business_tax_return_1_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.business_tax_return_1_file',
                          business_tax_return_1_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書
                      </Typography>
                      <Typography variant="sp_name_text" color="b_333">
                        または経営する親族の確定申告書（2期前）
                      </Typography>
                    </Stack>

                    <SPUploadImageItem
                      variant="imageOnly"
                      images={businessTaxReturn2File2}
                      reload={reset}
                      setImages={setBusinessTaxReturn2File2}
                      handleDeleteImage={(id) => {
                        const business_tax_return_2_file = convertImage(
                          formik.values.p_applicant_people
                            .business_tax_return_2_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.business_tax_return_2_file',
                          business_tax_return_2_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書
                      </Typography>
                      <Typography variant="sp_name_text" color="b_333">
                        または経営する親族の確定申告書（3期前）
                      </Typography>
                    </Stack>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={businessTaxReturn3File2}
                      reload={reset}
                      setImages={setBusinessTaxReturn3File2}
                      handleDeleteImage={(id) => {
                        const business_tax_return_3_file = convertImage(
                          formik.values.p_applicant_people
                            .business_tax_return_3_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.business_tax_return_3_file',
                          business_tax_return_3_file
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </SPFormItem>
            )}
          </Stack>
        )}

        {/*Plan B*/}
        {isPlanB && (
          <Stack>
            {isIncomeFile && (
              <SPFormItem required label="収入に関する書類">
                <Stack spacing={3}>
                  <Stack spacing={3}>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Stack>
                        <Typography variant="sp_note_text" color="b_333">
                          枚数が多い場合はPDFファイルを
                          <Stack flexDirection="row" alignItems="center">
                            <Typography variant="sp_note_text" color="b_333">
                              <SPEmailLink email="ssnbmbk_info@ssnbagent.netbk.co.jp" />{' '}
                              へメール送付してください。
                            </Typography>
                          </Stack>
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="SP_form_item_note" color="b_333">
                        ※
                      </Typography>
                      <Typography variant="SP_form_item_note" color="b_333">
                        3期分無い場合は、直近のみで結構です。
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        確定申告書（1期前）
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Icons.SPCircleExclamation
                          sx={{ width: 16, height: 16 }}
                        />
                        <Typography
                          variant="SP_form_item_help"
                          color="sp_secondary_01"
                        >
                          個人番号を隠した画像を添付してください
                        </Typography>
                      </Stack>
                    </Stack>

                    <SPUploadImageItem
                      variant="imageOnly"
                      images={firstIncomeFile2}
                      reload={reset}
                      setImages={setFirstIncomeFile2}
                      handleDeleteImage={(id) => {
                        const first_income_file = convertImage(
                          formik.values.p_applicant_people.first_income_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.first_income_file',
                          first_income_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        確定申告書（2期前）
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Icons.SPCircleExclamation
                          sx={{ width: 16, height: 16 }}
                        />
                        <Typography
                          variant="SP_form_item_help"
                          color="sp_secondary_01"
                        >
                          個人番号を隠した画像を添付してください
                        </Typography>
                      </Stack>
                    </Stack>

                    <SPUploadImageItem
                      variant="imageOnly"
                      images={secondIncomeFile2}
                      reload={reset}
                      setImages={setSecondIncomeFile2}
                      handleDeleteImage={(id) => {
                        const second_income_file = convertImage(
                          formik.values.p_applicant_people.second_income_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.second_income_file',
                          second_income_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack spacing={2}>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        確定申告書（3期前）
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Icons.SPCircleExclamation
                          sx={{ width: 16, height: 16 }}
                        />
                        <Typography
                          variant="SP_form_item_help"
                          color="sp_secondary_01"
                        >
                          個人番号を隠した画像を添付してください
                        </Typography>
                      </Stack>
                    </Stack>

                    <SPUploadImageItem
                      variant="imageOnly"
                      images={thirdIncomeFile2}
                      reload={reset}
                      setImages={setThirdIncomeFile2}
                      handleDeleteImage={(id) => {
                        const third_income_file = convertImage(
                          formik.values.p_applicant_people.third_income_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.third_income_file',
                          third_income_file
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </SPFormItem>
            )}

            {isFinancialStatementfile && (
              <SPFormItem
                required
                label={
                  <Stack>
                    <Typography variant="SP_form_item_label" color="b_333">
                      非上場企業の役員の方は
                    </Typography>
                    <Typography variant="SP_form_item_label" color="b_333">
                      下記の書類も添付してください。
                    </Typography>
                  </Stack>
                }
              >
                <Stack spacing={3}>
                  <Stack spacing={3}>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Stack>
                        <Typography variant="sp_note_text" color="b_333">
                          枚数が多い場合はPDFファイルを
                          <Stack flexDirection="row" alignItems="center">
                            <Typography variant="sp_note_text" color="b_333">
                              <SPEmailLink email="ssnbmbk_info@ssnbagent.netbk.co.jp" />{' '}
                              へメール送付してください。
                            </Typography>
                          </Stack>
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography variant="sp_note_text" color="b_333">
                      ※ 3期分無い場合は、直近のみで結構です。
                    </Typography>
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      会社の決算報告書（1期前）
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={financialStatement1File2}
                      reload={reset}
                      setImages={setFinancialStatement1File2}
                      handleDeleteImage={(id) => {
                        const financial_statement_1_file = convertImage(
                          formik.values.p_applicant_people
                            .financial_statement_1_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.financial_statement_1_file',
                          financial_statement_1_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      会社の決算報告書（2期前）
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={financialStatement2File2}
                      reload={reset}
                      setImages={setFinancialStatement2File2}
                      handleDeleteImage={(id) => {
                        const financial_statement_2_file = convertImage(
                          formik.values.p_applicant_people
                            .financial_statement_2_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.financial_statement_2_file',
                          financial_statement_2_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Typography
                      variant="SP_label_field"
                      color="b_333"
                      sx={{ mt: 3 }}
                    >
                      会社の決算報告書（3期前）
                    </Typography>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={financialStatement3File2}
                      reload={reset}
                      setImages={setFinancialStatement3File2}
                      handleDeleteImage={(id) => {
                        const financial_statement_3_file = convertImage(
                          formik.values.p_applicant_people
                            .financial_statement_3_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.financial_statement_3_file',
                          financial_statement_3_file
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </SPFormItem>
            )}

            {isBusinessTaxReturnFilePlanB && (
              <SPFormItem
                required
                label={
                  <Stack>
                    <Typography variant="SP_form_item_label" color="b_333">
                      親族経営の会社等にご勤務の方は
                    </Typography>
                    <Typography variant="SP_form_item_label" color="b_333">
                      下記の書類も添付してください。
                    </Typography>
                  </Stack>
                }
              >
                <Stack spacing={3}>
                  <Stack spacing={3}>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Stack>
                        <Typography variant="sp_note_text" color="b_333">
                          枚数が多い場合はPDFファイルを
                          <Stack flexDirection="row" alignItems="center">
                            <Typography variant="sp_note_text" color="b_333">
                              <SPEmailLink email="ssnbmbk_info@ssnbagent.netbk.co.jp" />{' '}
                              へメール送付してください。
                            </Typography>
                          </Stack>
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="sp_note_text" color="b_333">
                        ※
                      </Typography>
                      <Typography variant="sp_note_text" color="b_333">
                        3期分無い場合は、直近のみで結構です。
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書
                      </Typography>
                      <Typography variant="sp_name_text" color="b_333">
                        または経営する親族の確定申告書（1期前）
                      </Typography>
                    </Stack>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={businessTaxReturn1File2}
                      reload={reset}
                      setImages={setBusinessTaxReturn1File2}
                      handleDeleteImage={(id) => {
                        const business_tax_return_1_file = convertImage(
                          formik.values.p_applicant_people
                            .business_tax_return_1_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.business_tax_return_1_file',
                          business_tax_return_1_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書
                      </Typography>
                      <Typography variant="sp_name_text" color="b_333">
                        または経営する親族の確定申告書（2期前）
                      </Typography>
                    </Stack>

                    <SPUploadImageItem
                      variant="imageOnly"
                      images={businessTaxReturn2File2}
                      reload={reset}
                      setImages={setBusinessTaxReturn2File2}
                      handleDeleteImage={(id) => {
                        const business_tax_return_2_file = convertImage(
                          formik.values.p_applicant_people
                            .business_tax_return_2_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.business_tax_return_2_file',
                          business_tax_return_2_file
                        );
                      }}
                    />
                  </Stack>

                  <Stack
                    sx={{
                      bgcolor: 'main_white',
                      borderRadius: 2,
                      pb: 3,
                      pr: 3,
                      pl: 3,
                      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <Stack>
                      <Typography
                        variant="sp_name_text"
                        color="b_333"
                        sx={{ mt: 3 }}
                      >
                        会社の決算報告書
                      </Typography>
                      <Typography variant="sp_name_text" color="b_333">
                        または経営する親族の確定申告書（3期前）
                      </Typography>
                    </Stack>
                    <SPUploadImageItem
                      variant="imageOnly"
                      images={businessTaxReturn3File2}
                      reload={reset}
                      setImages={setBusinessTaxReturn3File2}
                      handleDeleteImage={(id) => {
                        const business_tax_return_3_file = convertImage(
                          formik.values.p_applicant_people
                            .business_tax_return_3_file,
                          id
                        );
                        formik.setFieldValue(
                          'p_applicant_people.business_tax_return_3_file',
                          business_tax_return_3_file
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </SPFormItem>
            )}
          </Stack>
        )}
        <SPFormItem label="その他の書類">
          <Stack spacing={3}>
            <Stack direction="row" alignItems="flex-start" spacing={1}>
              <Typography variant="sp_note_text" color="b_333">
                ※
              </Typography>
              <Stack>
                <Typography variant="sp_note_text" color="b_333">
                  契約社員・派遣社員・嘱託の方は雇用契約書を添付してください。
                </Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{
                bgcolor: 'main_white',
                borderRadius: 2,
                pb: 3,
                pr: 3,
                pl: 3,
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
              }}
            >
              <SPUploadImageItem
                variant="imageOnly"
                images={otherDocumentFile2}
                reload={reset}
                setImages={setOtherDocumentFile2}
                handleDeleteImage={(id) => {
                  const other_document_file = convertImage(
                    formik.values.p_applicant_people.other_document_file,
                    id
                  );
                  formik.setFieldValue(
                    'p_applicant_people.other_document_file',
                    other_document_file
                  );
                }}
              />
            </Stack>
          </Stack>
        </SPFormItem>
        <SPSaveDraftModal open={open} onClose={() => setOpen(false)} />
        <SPSaveImageModal
          open={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
          }}
        />
      </SPStepLayout>
    </FormikProvider>
  );
};

export default SPStepSevenIncomeTotalizerPage;
