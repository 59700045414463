import { z } from 'zod';

export const contactItemSchema = z.object({
  id: z.string(),
  sender: z.string(),
  content: z.string(),
  created_at: z.string(),
});

export const contactItemManagerSchema = z.object({
  id: z.string(),
  sender: z.string(),
  content: z.string(),
  created_at: z.string(),
});

export const contactResponseSchema = z.object({
  success: z.boolean(),
  data: z.array(contactItemSchema),
});

export const createContactResponseSchema = z.object({
  success: z.boolean(),
});

export const managerContactRequestSchema = z.object({
  id: z.string(),
  applicant_id: z.string(),
  sender: z.string().optional(),
  content: z.string().optional(),
  created_at: z.string().optional(),
  order_key: z.string().optional(),
  order_sort: z.string().optional(),
});

export const contactCreateResponseSchema = z.object({
  success: z.boolean(),
});

export const contactDetailResponseSchema = z.object({
  success: z.boolean(),
  data: contactItemSchema,
});

export const contactDetailManagerResponseSchema = z.object({
  success: z.boolean(),
  data: contactItemManagerSchema,
});

export const contactDetailManagerRequestSchema = z.object({
  id: z.string(),
  applicant_id: z.string(),
  preliminary_id: z.string(),
});

export const contactRequestSchema = z.object({
  id: z.string(),
  applicant_id: z.string(),
  contact_information: z.object({
    sender_id: z.string(),
    sender_type: z.string(),
    subject: z.string(),
    problem_contact: z.string(),
    content: z.string(),
  }),
});

export type ContactItem = z.infer<typeof contactItemSchema>;
export type ContactItemManager = z.infer<typeof contactItemManagerSchema>;
export type ContactDetailResponse = z.infer<typeof contactDetailResponseSchema>;
export type ContactDetailManagerResponse = z.infer<
  typeof contactDetailManagerResponseSchema
>;
export type ContactDetailManagerRequest = z.infer<
  typeof contactDetailManagerRequestSchema
>;
export type ContactResponse = z.infer<typeof contactResponseSchema>;
export type ManagerContactRequest = z.infer<typeof managerContactRequestSchema>;
export type CreateContactRequest = z.infer<typeof contactRequestSchema>;
export type CreateContactResponse = z.infer<typeof createContactResponseSchema>;
export type ContactCreateResponse = z.infer<typeof contactCreateResponseSchema>;
export type ContactError = {};
