import {
  TabPanelUnstyled,
  TabUnstyled,
  TabsListUnstyled,
  TabsUnstyled,
  buttonUnstyledClasses,
  tabUnstyledClasses,
} from '@mui/base';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
  AdminPreliminaryTable,
  AdminPreviewPDF,
  Button,
  DocumentUploadItem,
  DocumentUploadItemBorrowing,
  DocumentUploadItemHeader,
  Icons,
  ModalChangeValue,
  modalErrorRef,
  SPImage,
} from 'components';
import { Loading } from 'components/atoms/Loading';
import {
  BorrowerType,
  BorrowingStatusType,
  CODEBANK,
  CoOwnerType,
  FILE_NOT_EXIST,
  LoanType,
  NationalityType,
  PARAMS_FILE_UPLOAD_ONE_FILE,
  PERSONAL_RESIDENCE,
  // PreliminaryStatusType,
} from 'constant';
import { AdminLayout } from 'containers';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch, useUpdateForm } from 'hooks';
import get from 'lodash/get';
import { routeNames } from 'navigations/routes';
import { defaultFiles } from 'pages/AdminEditPreliminary/slice';
import { plannedCohabitantSelectors } from 'pages/SPStartScreen/selectors';
import {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  SalePersonPreliminaries,
  UploadedFileKeys,
  UploadedFileKeysBorrowing,
  UploadedFileKeysHeader,
  UploadedFilesManager,
} from 'services';
import {
  checkIfImageExists,
  checkIfPDFExists,
  genParamBorrowing,
  getFileExtFromUrl,
  handleShowError,
} from 'utils';
import { FilesUploadOption } from 'pages/AdminEditPreliminary';
import { publicHolidaysSelector } from '../../containers/AuthModal/selectors';
import { ResetData } from '../AdminEditPreliminary/ResetData';
import { removeObject } from '../AdminEditPreliminary/utils/buildRemoveResidentsAttributes';
import { getYearLength } from '../AdminEditPreliminary/utils/getYearLenght';
import { managerRoleSelector } from '../Login/selectors';
import {
  APPLICANT_PEOPLE_ATTRIBUTE,
  FILES_UPLOAD_OPTIONS,
  JOIN_GUARANTORS_ATTRIBUTE,
} from '../AdminEditPreliminary/constant';
import {
  ConfirmModal,
  ExaminationResult,
  PulldownDocuments,
} from './components';
import {
  borrowingDetailsSalePersonOriginalSelector,
  borrowingsAttributesOriginalSelector,
  checkIncomeTotalizer,
  dataSalePersonAsyncSelector,
  filesSelectors,
  initialLinkValuesSelector,
  initialValuesSelector,
  joinGuarantorsAttributesOriginalSelector,
  loadingSelector,
  peopleAttributesOriginalSelector,
  publicStatus,
  residentsAttributesOriginalSelector,
  salePersonAdminOptionsForSalePersonSelector,
  salePersonPreliminariesSelector,
  saveButtonSelector,
} from './selectors';
import {
  editSalePersonPreliminary,
  getLinkPersonalInformations,
  getPersonalInformationsSalePerson,
  updateUploadedFilesSalePerson,
} from './thunk';
import {
  buildBorrowingDetailSalePerson,
  buildContactInformationSalePerson,
  buildCurrentBorrowingSalePerson,
  buildFinancingPlanSalePerson,
  buildGuaranteeProviderSalePerson,
  buildIncomeTotalizerSalePerson,
  buildPersonalInformationSalePerson,
  buildPersonalOccupationSalePerson,
  buildPersonalResidenceSalePerson,
  buildPersonalRowsSalePerson,
} from './utils';
import { getValidationSchema } from './validationSchema';

export type ImagePreview = {
  name: string;
  src: string;
};

const SalePersonEditPreliminary: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useSelector(loadingSelector);
  const isDisableSaveButton = useSelector(saveButtonSelector);
  const managerRole = useSelector(managerRoleSelector);
  const preliminaries = useSelector(salePersonPreliminariesSelector);
  const [disableBtnPDF, setDisableBtnPDF] = useState<boolean>(false);
  const initialValues = useSelector(initialValuesSelector);
  const initialLinkValues = useSelector(initialLinkValuesSelector);
  const joinGuarantorsAttributesOriginal = useSelector(
    joinGuarantorsAttributesOriginalSelector
  );
  const borrowingDetailSalepersonOriginal = useSelector(
    borrowingDetailsSalePersonOriginalSelector
  );
  const residentsAttributesOriginal = useSelector(
    residentsAttributesOriginalSelector
  );
  const borrowingsAttributesOriginal = useSelector(
    borrowingsAttributesOriginalSelector
  );
  const peopleAttributesOriginal = useSelector(
    peopleAttributesOriginalSelector
  );
  const listSalePerson = useSelector(
    salePersonAdminOptionsForSalePersonSelector
  );
  const publicHolidays = useSelector(publicHolidaysSelector);
  const plannedCohabitantDynamic = useSelector(plannedCohabitantSelectors);
  const incomeTotalizer = useSelector(checkIncomeTotalizer);

  const [selectTab, setSelectTab] = useState<string>('1');
  const [newSelectTab, setNewSelectTab] = useState<string>('1');
  const [selectSubTab, setSelectSubTab] = useState<number>(0);
  const [selectIncomeSubTab, setSelectIncomeSubTab] = useState<number>(0);
  const [examinationUrl, setExaminationUrl] = useState<string>('');
  const [examinationFileName, setExaminationFileName] = useState<string>('');
  const [examinationUrlForB, setExaminationUrlForB] = useState<string>('');
  const [examinationFileNameForB, setExaminationFileNameForB] =
    useState<string>('');
  const [tempSelectSubTab, setTempSelectSubTab] = useState<number>(0);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  // const [preliminaryEdit, setPreliminaryEdit] =
  //   useState<Omit<SalePersonPreliminaries, 'master_bank_ids'>>();
  const [fullNameKanji, setFullNameKanji] =
    useState<string>('申込内容の修正・確認　');
  const [guarantor, setGuarantor] = useState<boolean>(false);
  const [changeModal, setChangeModal] = useState<boolean>(false);
  const [messageChangeModal, setChangeModalMessage] = useState<string>('');
  const [filesUploadOption, setFilesUploadOption] =
    useState<FilesUploadOption>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataIncome, setDataIncome] = useState<any>(APPLICANT_PEOPLE_ATTRIBUTE);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataJoinGuarantor, setDataJoinGuarantor] = useState<any>([
    [JOIN_GUARANTORS_ATTRIBUTE],
  ]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loanDesiredBorrowingDate, setLoanDesiredBorrowingDate] = useState<
    string | undefined
  >(
    preliminaries.p_application_header.p_borrowing_details_attributes[0]
      .loan_desired_borrowing_date
  );
  const [isExistFile, setIsExistFile] = useState<boolean>(true);

  const {
    files,
    filesLink,
    filesHeader,
    filesLinkHeader,
    isLoadingFiles,
    filesBorrowing,
    filesLinkBorrowing,
    filesIncomeAdder,
    filesRemove,
    filesBorrowingRemove,
    filesHeaderRemove,
    filesIncomeAdderRemove,
  } = useSelector(filesSelectors);
  const resultStatusSelector = useSelector(publicStatus);
  const { sbiId, timeGetEdit } = useSelector(dataSalePersonAsyncSelector);
  const { checkUpdateFormSalePerson } = useUpdateForm();

  const handleChangeTab = (event: SyntheticEvent, newValues: string) => {
    // setSelectTab(newValues);
    // setSelectSubTab(0);
    // setSelectIncomeSubTab(0);
    setNewSelectTab(newValues);
    if (!editable) {
      setSelectTab(newValues);
      setSelectSubTab(0);
      setSelectIncomeSubTab(0);
      return;
    }
    const isEdited = !isEqual(
      initialValues.p_application_header,
      formik.values.p_application_header
    );
    if (!isEdited && !formik.values.isChangeFiles) {
      setSelectTab(newValues);
      setSelectSubTab(0);
      setSelectIncomeSubTab(0);
    } else {
      setOpenModalConfirm(true);
      // setTempSelectSubTab(newValues);
    }
  };

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const preliminaryId = useMemo(() => {
    const preliminaryIdParam = query.get('preliminary_id');
    return preliminaryIdParam ? preliminaryIdParam : '';
  }, [query]);

  const hasJoinGuarantor = useMemo(() => {
    return (
      preliminaries.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor !== '0'
    );
  }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    const getPersonal = async () => {
      const result = await dispatch(
        getPersonalInformationsSalePerson({
          id: preliminaryId,
        })
      );

      if (getPersonalInformationsSalePerson.rejected.match(result)) {
        navigate(routeNames.SalePersonPreliminaries.path);
      }
      if (getPersonalInformationsSalePerson.fulfilled.match(result)) {
        // setPreliminaryEdit(result.payload);
        const linkingId = result.payload.p_application_header.linking_id;
        if (!!linkingId)
          dispatch(
            getLinkPersonalInformations({
              id: linkingId,
            })
          );
      }
    };

    getPersonal();
  }, [dispatch, preliminaryId, navigate]);

  useEffect(() => {
    const applicantName =
      preliminaries.p_application_header.p_applicant_people_attributes[0]
        ?.full_name_kanji;
    if (applicantName) {
      setFullNameKanji(`申込内容の修正・確認　［ ${applicantName} 様 ］`);
    }
  }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    setExaminationUrl(preliminaries.p_application_header.examination_file.path);
    setExaminationFileName(
      preliminaries.p_application_header.examination_file.filename
    );
  }, [
    preliminaries.p_application_header.examination_file.filename,
    preliminaries.p_application_header.examination_file.path,
  ]);

  useEffect(() => {
    if (!!preliminaries.p_application_header.p_applicant_people_attributes[1]) {
      setDataIncome(
        preliminaries.p_application_header.p_applicant_people_attributes[1]
      );
    } else {
      setDataIncome(APPLICANT_PEOPLE_ATTRIBUTE);
    }
  }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    if (!!preliminaries.p_application_header.p_join_guarantors_attributes) {
      setDataJoinGuarantor(
        preliminaries.p_application_header.p_join_guarantors_attributes
      );
    } else {
      setDataJoinGuarantor([JOIN_GUARANTORS_ATTRIBUTE]);
    }
  }, [preliminaries.p_application_header.p_join_guarantors_attributes]);

  const handlePreview = useCallback((name: string, src: string) => {
    // setPreview({ name: name, src: src });
    // setHover((prevState) => !prevState);
  }, []);

  const getBorrowingSalePersons = useCallback(
    (loanType: LoanType, borrowings: unknown[]) => {
      if (
        ![LoanType.THREE, LoanType.FOUR].includes(loanType) &&
        borrowings.length > 0
      ) {
        return borrowings.map((item) => ({
          // @ts-ignore
          ...item,
          borrower: BorrowerType.APPLICANT_HIMSELF_HERSELF,
        }));
      } else {
        return borrowings;
      }
    },
    []
  );

  const formik = useFormik<
    SalePersonPreliminaries & {
      files: UploadedFileKeys;
      filesLink: UploadedFileKeys;
      filesIncomeAdder: UploadedFileKeys;
      filesHeader: UploadedFileKeysHeader;
      filesLinkHeader: UploadedFileKeysHeader;
      filesBorrowing: UploadedFileKeysBorrowing;
      filesLinkBorrowing: UploadedFileKeysBorrowing;
      filesRemove: UploadedFileKeys;
      filesIncomeAdderRemove: UploadedFileKeys;
      filesHeaderRemove: UploadedFileKeysHeader;
      filesBorrowingRemove: UploadedFileKeysBorrowing;
      link?: Omit<SalePersonPreliminaries, 'master_bank_codes'>;
      isEdited: boolean;
      isChangeFiles: boolean;
    }
  >({
    //@ts-ignore
    initialValues: {
      files,
      filesLink,
      filesIncomeAdder,
      filesHeader,
      filesLinkHeader,
      filesBorrowing,
      filesLinkBorrowing,
      ...initialValues,
      link: initialLinkValues,
      filesRemove,
      filesIncomeAdderRemove,
      filesHeaderRemove,
      filesBorrowingRemove,
      isEdited: false,
      isChangeFiles: false,
    },
    validationSchema: getValidationSchema(
      selectTab,
      selectTab === '3' ? selectIncomeSubTab : selectSubTab,
      guarantor,
      publicHolidays,
      loanDesiredBorrowingDate ||
        initialValues.p_application_header.p_borrowing_details_attributes?.[0]
          .loan_desired_borrowing_date ||
        '',
      preliminaries.p_application_header.created_at
    ),
    validateOnChange: false,
    validateOnMount: true,
    enableReinitialize: true,
    async onSubmit(values, { resetForm, setFieldError }) {
      const {
        files,
        filesRemove,
        link,
        filesLinkBorrowing,
        filesBorrowing,
        filesBorrowingRemove,
        filesLinkHeader,
        filesHeader,
        filesHeaderRemove,
        filesIncomeAdder,
        filesIncomeAdderRemove,
        filesLink,
        ...restValues
      } = values;
      const scheduledDateMoving =
        values.p_application_header.scheduled_date_moving.slice(0, 7);
      const pReferralAgencyId = getReferralIdAction();
      const newApplicationPeople =
        values.p_application_header.p_applicant_people_attributes.map(
          (item, index) => {
            if (!index && selectTab === '1') {
              return {
                ...item,
                identity_verification:
                  filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
                    ? '1'
                    : filesUploadOption ===
                      FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
                    ? '2'
                    : '0',
                two_years_ago_income: isMCJ ? item.two_years_ago_income : null,
                borrowing_status: !values.p_application_header
                  .p_borrowings_attributes.length
                  ? BorrowingStatusType.NO
                  : BorrowingStatusType.YES,
              };
            } else if (index && selectTab === '3') {
              return {
                ...item,
                identity_verification:
                  filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
                    ? '1'
                    : filesUploadOption ===
                      FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
                    ? '2'
                    : '0',
              };
            } else if (!index) {
              return {
                ...item,
                two_years_ago_income: isMCJ ? item.two_years_ago_income : null,
                borrowing_status: !values.p_application_header
                  .p_borrowings_attributes.length
                  ? BorrowingStatusType.NO
                  : BorrowingStatusType.YES,
              };
            } else {
              return item;
            }
          }
        );
      const newValues = {
        ...restValues,
        p_application_header: {
          ...values.p_application_header,
          scheduled_date_moving: scheduledDateMoving,
          is_home_loan_plus: values.p_application_header.is_home_loan_plus
            ? 1
            : 0,
          p_referral_agency_id: pReferralAgencyId
            ? pReferralAgencyId
            : values.p_application_header.p_referral_agency_id,
          p_join_guarantors_attributes: removeObject(
            joinGuarantorsAttributesOriginal,
            values.p_application_header.p_join_guarantors_attributes
          ),
          p_residents_attributes: removeObject(
            residentsAttributesOriginal,
            formik.values.p_application_header.p_residents_attributes
          ),
          p_borrowings_attributes: removeObject(
            borrowingsAttributesOriginal,
            getBorrowingSalePersons(
              values.p_application_header.loan_type,
              values.p_application_header.p_borrowings_attributes
            )
          ),
          p_applicant_people_attributes: removeObject(
            peopleAttributesOriginal,
            newApplicationPeople
          ),
          p_borrowing_details_attributes: removeObject(
            borrowingDetailSalepersonOriginal,
            values.p_application_header.p_borrowing_details_attributes
              .length === 2
              ? [
                  values.p_application_header.p_borrowing_details_attributes[0],
                  {
                    ...values.p_application_header
                      .p_borrowing_details_attributes[1],
                    loan_term_year_num:
                      values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_year_num,
                    loan_term_month_num:
                      values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_month_num,
                    borrowing_detail_type: '2',
                    repayment_method:
                      values.p_application_header
                        .p_borrowing_details_attributes[0].repayment_method,
                  },
                ]
              : values.p_application_header.p_borrowing_details_attributes
          ),
        },
      };

      // const loanTermYearNum =
      //   newValues.p_application_header.p_borrowing_details_attributes[0]
      //     .loan_term_year_num;
      // const birthday =
      //   newValues.p_application_header.p_applicant_people_attributes[0]
      //     .birthday;
      // if (
      //   loanTermYearLimit(loanTermYearNum, birthday ? birthday : '') &&
      //   (selectSubTab === 0 || selectSubTab === 1)
      // ) {
      //   // displayed message error in TableBody (function loadErrorMessage)
      //   setFieldError(
      //     'p_application_header.p_applicant_people_attributes[0].birthday',
      //     MESSAGE_REGEX.LOAN_TERM_LIMIT
      //   );
      //   return;
      // }

      if (
        newValues.p_application_header.sale_person_information
          ?.active_agency_id === ''
      ) {
        newValues.p_application_header.p_referral_agency_id = '';
      }

      const addNewPeopleAttribute =
        values.p_application_header.p_applicant_people_attributes?.[1]?.id ===
        APPLICANT_PEOPLE_ATTRIBUTE.id;

      const addNewJoinGuarantor =
        values.p_application_header.p_join_guarantors_attributes?.[0]?.id ===
        JOIN_GUARANTORS_ATTRIBUTE.id;

      changeModalMessage();

      // tab 1 => joinGuarantor
      if (
        selectSubTab === 0 &&
        selectTab === '1' &&
        addNewJoinGuarantor &&
        !addNewPeopleAttribute
      ) {
        return setChangeModal(true);
      }

      // tab 1 => income
      if (
        selectSubTab === 0 &&
        selectTab === '1' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        ) &&
        addNewPeopleAttribute &&
        !addNewJoinGuarantor
      ) {
        return setChangeModal(true);
      }

      if (
        selectSubTab === 0 &&
        selectTab === '1' &&
        addNewPeopleAttribute &&
        addNewJoinGuarantor
      ) {
        return setChangeModal(true);
      }

      // tab 4 joinGuarantor => income
      if (
        selectSubTab === 3 &&
        selectTab === '1' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        ) &&
        addNewPeopleAttribute &&
        addNewJoinGuarantor
      ) {
        setSelectSubTab(0);
        setSelectTab('3');
        return window.scrollTo(0, 0);
      }

      // tab 1 income => tab 2 income
      if (
        addNewPeopleAttribute &&
        selectIncomeSubTab === 0 &&
        selectTab === '3' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        )
      ) {
        setSelectIncomeSubTab(1);
        return window.scrollTo(0, 0);
      }

      // tab 2 income => tab 3 income
      if (
        addNewPeopleAttribute &&
        selectIncomeSubTab === 1 &&
        selectTab === '3' &&
        [LoanType.THREE, LoanType.FOUR].includes(
          formik.values.p_application_header.loan_type
        )
      ) {
        setSelectIncomeSubTab(2);
        return window.scrollTo(0, 0);
      }

      if (
        selectTab === '1' &&
        !!formik.values.p_application_header.p_applicant_people_attributes[0]
          ?.nationality &&
        formik.values.p_application_header.p_applicant_people_attributes[0]
          ?.nationality === NationalityType.FOREIGN_NATIONALITY &&
        (formik.values.files.residence_file.length === 0 ||
          formik.values.files.residence_file_back_image.length === 0)
      ) {
        hasJoinGuarantor ? setSelectSubTab(8) : setSelectSubTab(7);
        return window.scrollTo(0, 0);
      }

      if (
        selectTab === '3' &&
        !!formik.values.p_application_header.p_applicant_people_attributes[1]
          ?.nationality &&
        formik.values.p_application_header.p_applicant_people_attributes[1]
          ?.nationality === NationalityType.FOREIGN_NATIONALITY &&
        (formik.values.filesIncomeAdder.residence_file.length === 0 ||
          formik.values.filesIncomeAdder.residence_file_back_image.length === 0)
      ) {
        setSelectIncomeSubTab(2);
        return window.scrollTo(0, 0);
      }

      const isDataNewest =
        !!sbiId && !!preliminaryId && !!timeGetEdit
          ? await checkUpdateFormSalePerson(preliminaryId, timeGetEdit, sbiId)
          : true;

      if (isDataNewest) {
        const result = await dispatch(
          // @ts-ignore
          editSalePersonPreliminary({
            id: preliminaries.p_application_header.id,
            ...newValues,
          })
        );
        if (editSalePersonPreliminary.fulfilled.match(result)) {
          toast.success('申込内容を更新しました。');
          const initNationalityA =
            preliminaries.p_application_header.p_applicant_people_attributes[0]
              ?.nationality;
          const nationalityA =
            newValues.p_application_header.p_applicant_people_attributes[0]
              ?.nationality;
          const initNationalityIncome =
            preliminaries.p_application_header.p_applicant_people_attributes[1]
              ?.nationality;
          const nationalityIncome =
            newValues.p_application_header.p_applicant_people_attributes[1]
              ?.nationality;
          const applicant_id =
            preliminaries.p_application_header.p_applicant_people_attributes[0]
              .id;
          const applicant_id_income =
            preliminaries.p_application_header.p_applicant_people_attributes
              .length > 1
              ? preliminaries.p_application_header
                  .p_applicant_people_attributes[1].id
              : '';
          if (
            !!nationalityA &&
            initNationalityA !== nationalityA &&
            nationalityA === NationalityType.DEFAULT &&
            (formik.values.files.residence_file.length > 0 ||
              formik.values.files.residence_file_back_image.length > 0)
          ) {
            const data = new FormData();
            data.append('residence_file[]', '');
            data.append('residence_file_back_image[]', '');
            await dispatch(
              updateUploadedFilesSalePerson({
                id: preliminaryId,
                applicant_id,
                data,
              })
            );
          }

          if (
            !!nationalityIncome &&
            nationalityIncome !== initNationalityIncome &&
            nationalityIncome === NationalityType.DEFAULT &&
            (formik.values.filesIncomeAdder.residence_file.length > 0 ||
              formik.values.filesIncomeAdder.residence_file_back_image.length >
                0)
          ) {
            const data = new FormData();
            data.append('residence_file[]', '');
            data.append('residence_file_back_image[]', '');
            await dispatch(
              updateUploadedFilesSalePerson({
                id: preliminaryId,
                applicant_id: applicant_id_income,
                data,
              })
            );
          }
          resetForm();
          // recall API get edit to update sbi_id
          await dispatch(
            getPersonalInformationsSalePerson({
              id: preliminaryId,
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    setGuarantor(
      !!formik.values.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor &&
        formik.values.p_application_header.p_applicant_people_attributes[0]
          .has_join_guarantor === CoOwnerType.CANBE
    );
  }, [formik.values.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    setLoanDesiredBorrowingDate(
      formik.values.p_application_header.p_borrowing_details_attributes[0]
        .loan_desired_borrowing_date
    );
  }, [formik.values.p_application_header.p_borrowing_details_attributes]);

  useEffect(() => {
    if (
      formik.values.p_application_header.p_applicant_people_attributes
        .length === 1
    ) {
      formik.setFieldValue(`filesIncomeAdder`, defaultFiles);
      formik.setFieldValue(`filesIncomeAdderRemove`, defaultFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.p_application_header.p_applicant_people_attributes]);

  const hasJoinGuarantorForB = useMemo(() => {
    return (
      formik.values.link?.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor !== '0'
    );
  }, [formik.values.link?.p_application_header.p_applicant_people_attributes]);

  useEffect(() => {
    if (formik.values.link) {
      setExaminationUrlForB(
        formik.values.link.p_application_header.examination_file.path
      );
      setExaminationFileNameForB(
        formik.values.link.p_application_header.examination_file.filename
      );
    } else {
      setExaminationUrlForB('');
      setExaminationFileNameForB('');
    }
  }, [formik.values.link]);

  const editable = useMemo<boolean>(() => {
    if (
      selectTab === '1' &&
      // preliminaries.p_application_header.preliminary_status ===
      //   PreliminaryStatusType.PROVISIONAL_PRELIMINARY &&
      selectSubTab ===
        (formik.values.p_application_header.p_applicant_people_attributes[0]
          .has_join_guarantor !== '0'
          ? 7
          : 6)
    ) {
      return true;
    }
    return (
      formik.values.p_application_header.editable &&
      formik.values.p_application_header.edit_main_statuses
    );
  }, [
    preliminaries.p_application_header.preliminary_status,
    selectSubTab,
    selectTab,
    formik.values.p_application_header.p_applicant_people_attributes,
    formik.values.p_application_header.editable,
    formik.values.p_application_header.edit_main_statuses,
  ]);

  const getReferralIdAction = () => {
    const { active_agency_id, active_store_id, active_exhibition_id } =
      formik.values.p_application_header.sale_person_information;
    if (active_agency_id && !active_store_id && !active_exhibition_id) {
      return listSalePerson.agenciesAdminOptions.find(
        (item) => item.value === active_agency_id
      )?.id;
    }
    if (active_store_id && !active_exhibition_id) {
      return listSalePerson.storeAdminOptions.find(
        (item) => item.value === active_store_id
      )?.id;
    }
    if (!!active_exhibition_id)
      return listSalePerson.exhibitionsAdminOptions.find(
        (item) => item.value === active_exhibition_id
      )?.id;
    return '';
  };

  const isMCJ = useMemo(
    () =>
      formik.values.p_application_header.name_master_banks?.includes(
        CODEBANK.MCJ
      ),
    [formik.values.p_application_header.name_master_banks]
  );

  const isDriverLicenseImage = useMemo(() => {
    if (selectTab === '1') {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification === '0' ||
        !preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification
      );
    } else if (selectTab === '2') {
      return (
        formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification === '0' ||
        !formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification
      );
    } else {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification === '0' ||
        !preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification
      );
    }
  }, [
    preliminaries.p_application_header.p_applicant_people_attributes,
    formik.values.link?.p_application_header.p_applicant_people_attributes,
    selectTab,
  ]);

  const isCardNumberImage = useMemo(() => {
    if (selectTab === '1') {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification === '1'
      );
    } else if (selectTab === '2') {
      return (
        formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification === '1'
      );
    } else {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification === '1'
      );
    }
  }, [
    preliminaries.p_application_header.p_applicant_people_attributes,
    formik.values.link?.p_application_header.p_applicant_people_attributes,
    selectTab,
  ]);

  const isResidentRegisterImage = useMemo(() => {
    if (selectTab === '1') {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[0]
          ?.identity_verification === '2'
      );
    } else if (selectTab === '2') {
      return (
        formik.values.link?.p_application_header
          .p_applicant_people_attributes?.[0]?.identity_verification === '2'
      );
    } else {
      return (
        preliminaries.p_application_header.p_applicant_people_attributes?.[1]
          ?.identity_verification === '2'
      );
    }
  }, [
    formik.values.link?.p_application_header.p_applicant_people_attributes,
    preliminaries.p_application_header.p_applicant_people_attributes,
    selectTab,
  ]);

  useEffect(() => {
    if (isDriverLicenseImage) {
      setFilesUploadOption(FILES_UPLOAD_OPTIONS.DRIVER_LICENSE);
    } else if (isCardNumberImage) {
      setFilesUploadOption(FILES_UPLOAD_OPTIONS.CARD_NUMBER);
    } else if (isResidentRegisterImage) {
      setFilesUploadOption(FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER);
    }
  }, [isCardNumberImage, isDriverLicenseImage, isResidentRegisterImage]);

  const handleChangeFiles = useCallback(
    (
      files: (File | UploadedFilesManager)[],
      id: string,
      name?: keyof UploadedFileKeys
    ) => {
      if (!!name) {
        if (id) {
          // remove file
          const filesWithOption = formik.values.files[`${name}`];
          const fileRemove = filesWithOption.find((file) => file.id === id);
          if (fileRemove) {
            let filesRemove = [...formik.values.filesRemove[`${name}`]];
            // @ts-ignore
            filesRemove.length === 0
              ? // @ts-ignore
                (filesRemove = [fileRemove])
              : // @ts-ignore
                filesRemove.push(fileRemove);
            formik.setFieldValue(`filesRemove.${name}`, filesRemove);
          }
        }
        formik.setFieldValue(`files.${name}`, files);
      }
      formik.setFieldValue('isChangeFiles', true);
    },
    [formik]
  );

  useEffect(() => {
    if (!editable) return;
    formik.validateForm();
  }, [selectSubTab, selectTab, editable, selectIncomeSubTab]);

  const handleChangeSubTab = useCallback(
    (event: SyntheticEvent, newValues: number) => {
      if (!editable) {
        setSelectSubTab(newValues);
        return;
      }
      const isEdited = !isEqual(
        initialValues.p_application_header,
        formik.values.p_application_header
      );
      if (!formik.values.isChangeFiles && !isEdited) {
        setSelectSubTab(newValues);
      } else {
        setOpenModalConfirm(true);
        setTempSelectSubTab(newValues);
      }
    },
    [
      editable,
      formik.values.isChangeFiles,
      formik.values.p_application_header,
      initialValues.p_application_header,
    ]
  );

  const handleChangeIncomeSubTab = useCallback(
    (event: SyntheticEvent, newValues: number) => {
      if (!editable) {
        setSelectIncomeSubTab(newValues);
        return;
      }
      const isEdited = !isEqual(
        initialValues.p_application_header,
        formik.values.p_application_header
      );
      if (!formik.values.isChangeFiles && !isEdited) {
        setSelectIncomeSubTab(newValues);
      } else {
        setOpenModalConfirm(true);
        setTempSelectSubTab(newValues);
      }
    },
    [
      editable,
      formik.values.isChangeFiles,
      formik.values.p_application_header,
      initialValues.p_application_header,
    ]
  );

  const handleOk = () => {
    setOpenModalConfirm(false);
    const loanType = formik.values.p_application_header.loan_type;
    if (
      selectTab === '3' &&
      (loanType === LoanType.THREE || loanType === LoanType.FOUR) &&
      !incomeTotalizer
    ) {
      window.location.reload();
    } else {
      formik.values.p_application_header.p_applicant_people_attributes[0]
        .has_join_guarantor &&
      tempSelectSubTab >= 4 &&
      newSelectTab !== selectTab
        ? setSelectSubTab(tempSelectSubTab - 1)
        : setSelectSubTab(tempSelectSubTab);

      setSelectTab(newSelectTab);
      newSelectTab !== selectTab
        ? setSelectIncomeSubTab(0)
        : setSelectIncomeSubTab(tempSelectSubTab);
      if (isDriverLicenseImage) {
        setFilesUploadOption(FILES_UPLOAD_OPTIONS.DRIVER_LICENSE);
      } else if (isCardNumberImage) {
        setFilesUploadOption(FILES_UPLOAD_OPTIONS.CARD_NUMBER);
      } else if (isResidentRegisterImage) {
        setFilesUploadOption(FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER);
      }
      formik.resetForm();
    }
  };

  const handleCancel = () => {
    setNewSelectTab(selectTab);
    setOpenModalConfirm(false);
  };

  const isTabUploadFiles = useMemo(() => {
    return (
      selectTab === '1' &&
      !!preliminaryId &&
      ((hasJoinGuarantor && selectSubTab === 8) ||
        (!hasJoinGuarantor && selectSubTab === 7))
    );
  }, [hasJoinGuarantor, preliminaryId, selectSubTab, selectTab]);

  const isTabUploadFilesIncome = useMemo(() => {
    return selectTab === '3' && selectIncomeSubTab === 2 && !!preliminaryId;
  }, [preliminaryId, selectIncomeSubTab, selectTab]);

  // add files || remove files (id !== '')
  const handleChangeFilesIncomeAdder = useCallback(
    (
      files: (File | UploadedFilesManager)[],
      id: string,
      name?: keyof UploadedFileKeys
    ) => {
      if (!!name) {
        if (id) {
          // remove file
          const filesWithOption = formik.values.filesIncomeAdder[`${name}`];
          const fileRemove = filesWithOption.find((file) => file.id === id);
          if (fileRemove) {
            let filesRemove = [
              ...formik.values.filesIncomeAdderRemove[`${name}`],
            ];
            // @ts-ignore
            filesRemove.length === 0
              ? // @ts-ignore
                (filesRemove = [fileRemove])
              : // @ts-ignore
                filesRemove.push(fileRemove);
            formik.setFieldValue(`filesIncomeAdderRemove.${name}`, filesRemove);
          }
        }
        formik.setFieldValue(`filesIncomeAdder.${name}`, files);
      }
      formik.setFieldValue('isChangeFiles', true);
    },
    [formik]
  );

  const handleChangeFilesHeader = useCallback(
    (name?: keyof UploadedFileKeysHeader) =>
      (files: (File | UploadedFilesManager)[], id: string) => {
        if (!!name) {
          if (id) {
            // remove file
            const filesWithOption = formik.values.filesHeader[`${name}`];
            const fileRemove = filesWithOption.find((file) => file.id === id);
            if (fileRemove) {
              let filesRemove = [...formik.values.filesHeaderRemove[`${name}`]];
              // @ts-ignore
              filesRemove.length === 0
                ? // @ts-ignore
                  (filesRemove = [fileRemove])
                : // @ts-ignore
                  filesRemove.push(fileRemove);
              formik.setFieldValue(`filesHeaderRemove.${name}`, filesRemove);
            }
          }
          formik.setFieldValue(`filesHeader.${name}`, files);
        }
        formik.setFieldValue('isChangeFiles', true);
      },
    [formik]
  );

  const handleChangeFilesBorrowing = useCallback(
    (name: string, index: number) =>
      (files: (File | UploadedFilesManager)[], id: string) => {
        if (!!name) {
          if (id) {
            // remove file
            const filesWithOption =
              // @ts-ignore
              formik.values.filesBorrowing[`${name}__${index}`];
            // @ts-ignore
            const fileRemove = filesWithOption.find((file) => file.id === id);
            if (fileRemove) {
              let filesRemove = [
                // @ts-ignore
                ...formik.values.filesBorrowingRemove[`${name}__${index}`],
              ];
              // @ts-ignore
              filesRemove.length === 0
                ? // @ts-ignore
                  (filesRemove = [fileRemove])
                : // @ts-ignore
                  filesRemove.push(fileRemove);
              formik.setFieldValue(
                `filesBorrowingRemove.${name}__${index}`,
                filesRemove
              );
            }
          }
          formik.setFieldValue(`filesBorrowing.${name}__${index}`, files);
        }
        formik.setFieldValue('isChangeFiles', true);
      },
    [formik]
  );

  // const isTaxTargetReturnExist = (typeTaxReturn: FileATaxReturnType[]) => {
  //   if (typeTaxReturn.length === 0) return false;
  //   return (
  //     typeTaxReturn.includes(FileATaxReturnType.ONE) ||
  //     typeTaxReturn.includes(FileATaxReturnType.TWO) ||
  //     typeTaxReturn.includes(FileATaxReturnType.THREE) ||
  //     typeTaxReturn.includes(FileATaxReturnType.SIX) ||
  //     typeTaxReturn.includes(FileATaxReturnType.OTHERS)
  //   );
  // };

  // const isFirstWithholdingSlipFile = useMemo(() => {
  //   if (selectTab === '1') {
  //     const typeTaxReturnA =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .type_tax_return;
  //     const taxReturnA =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .tax_return;
  //     return (
  //       taxReturnA === '0' ||
  //       (taxReturnA === '1' && !isTaxTargetReturnExist(typeTaxReturnA))
  //     );
  //   }
  //   if (selectTab === '2') {
  //     const typeTaxReturnB =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .type_tax_return;
  //     const taxReturnB =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .tax_return;
  //     return (
  //       taxReturnB === '0' ||
  //       (taxReturnB === '1' && !isTaxTargetReturnExist(typeTaxReturnB))
  //     );
  //   }
  //   if (selectTab === '3') {
  //     const typeTaxReturnIncome =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .type_tax_return;
  //     const taxReturnIncome =
  //       preliminaries.p_application_header.p_applicant_people_attributes[0]
  //         .tax_return;
  //     return (
  //       taxReturnIncome === '0' ||
  //       (taxReturnIncome === '1' &&
  //         !isTaxTargetReturnExist(typeTaxReturnIncome))
  //     );
  //   }
  //   return false;
  // }, [
  //   preliminaries.p_application_header.p_applicant_people_attributes,
  //   selectTab,
  // ]);
  // const isOccupationEqualOne = useCallback(() => {
  //   return (
  //     preliminaries.p_application_header.p_applicant_people_attributes[0]
  //       .occupation === '1'
  //   );
  // }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  // const isOccupationEqualOneOfOneSixSevenEight = useCallback(() => {
  //   const occupation =
  //     preliminaries.p_application_header.p_applicant_people_attributes[0]
  //       .occupation;
  //   return (
  //     occupation === '1' ||
  //     occupation === '6' ||
  //     occupation === '7' ||
  //     occupation === '8'
  //   );
  // }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  // const isOccupationEqualOneOfSixSevenEight = useCallback(() => {
  //   const occupation =
  //     preliminaries.p_application_header.p_applicant_people_attributes[0]
  //       .occupation;
  //   return occupation === '6' || occupation === '7' || occupation === '8';
  // }, [preliminaries.p_application_header.p_applicant_people_attributes]);

  const statusResult = useMemo(() => {
    return preliminaries.p_application_header.main_statuses;
  }, [preliminaries.p_application_header.main_statuses]);

  const isRequiredUploadFile = useCallback(
    (fileParam: string, isUserA: boolean = false) => {
      // const typeTaxReturn =
      //   preliminaries.p_application_header.p_applicant_people_attributes[0]
      //     .type_tax_return;
      // const taxReturn =
      //   preliminaries.p_application_header.p_applicant_people_attributes[0]
      //     .tax_return;
      const nationalityA = !!formik.values.p_application_header
        .p_applicant_people_attributes[0]?.nationality
        ? formik.values.p_application_header.p_applicant_people_attributes[0]
            .nationality
        : '';
      const nationalityIncomer = !!formik.values.p_application_header
        .p_applicant_people_attributes[1]?.nationality
        ? formik.values.p_application_header.p_applicant_people_attributes[1]
            .nationality
        : '';

      if (
        filesUploadOption === FILES_UPLOAD_OPTIONS.DRIVER_LICENSE &&
        (fileParam === 'driver_license_front_image' ||
          fileParam === 'driver_license_back_image')
      ) {
        if (isUserA) {
          return true;
        } else if (!isUserA) {
          return true;
        }
      }

      if (
        filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER &&
        fileParam === 'card_number_front_image'
      ) {
        if (isUserA) {
          return true;
        } else if (!isUserA) {
          return true;
        }
      }
      if (
        filesUploadOption === FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER &&
        (fileParam === 'resident_register_front_image' ||
          fileParam === 'resident_register_back_image')
      ) {
        if (isUserA) {
          return true;
        } else if (!isUserA) {
          return true;
        }
      }
      // if (
      //   fileParam === 'property_information_file_index' &&
      //   formik.values.p_application_header.property_information_url === ''
      // )
      //   return true;

      // if (!statusResult) return false; // 銀行へデータ連携 not true

      /* <-- remove required image fields in ticket HOMELOAN-2296 --> */
      if (
        (fileParam === 'residence_file' ||
          fileParam === 'residence_file_back_image') &&
        isUserA &&
        nationalityA === NationalityType.FOREIGN_NATIONALITY
      )
        return true;

      if (
        (fileParam === 'residence_file' ||
          fileParam === 'residence_file_back_image') &&
        !isUserA &&
        nationalityIncomer === NationalityType.FOREIGN_NATIONALITY
      )
        return true;
      return false;
    },
    [
      filesUploadOption,
      formik.values.p_application_header.p_applicant_people_attributes,
    ]
  );
  // submit change file
  const handleSubmitEditUploadedFiles = useCallback(
    async (
      paramsFiles: UploadedFileKeys,
      paramsFilesIncomeAdder: UploadedFileKeys,
      paramsHeaderFiles: UploadedFileKeysHeader,
      paramsFileBorrowing: UploadedFileKeysBorrowing,
      // eslint-disable-next-line
      p_applicant_people_attributes?: any
    ) => {
      const data = new FormData();

      const {
        files,
        filesRemove,
        link,
        filesLinkBorrowing,
        filesBorrowing,
        filesBorrowingRemove,
        filesLinkHeader,
        filesHeader,
        filesHeaderRemove,
        filesIncomeAdder,
        filesIncomeAdderRemove,
        filesLink,
        ...restValues
      } = formik.values;
      const scheduledDateMoving =
        formik.values.p_application_header.scheduled_date_moving.slice(0, 7);
      const pReferralAgencyId = getReferralIdAction();

      const applicant_people_1 = p_applicant_people_attributes[0];
      const { applicant_detail_type, id, ...restApplicantPeople2 } =
        !!p_applicant_people_attributes[1]
          ? p_applicant_people_attributes[1]
          : p_applicant_people_attributes[0];
      const applicant_people_2 = {
        applicant_detail_type: '1',
        change_loan_type: '1',
        ...restApplicantPeople2,
      };

      const newValues = {
        ...restValues,
        p_application_header: {
          ...formik.values.p_application_header,
          master_bank_ids: [],
          scheduled_date_moving: scheduledDateMoving,
          is_home_loan_plus: formik.values.p_application_header
            .is_home_loan_plus
            ? 1
            : 0,
          p_referral_agency_id: pReferralAgencyId
            ? pReferralAgencyId
            : formik.values.p_application_header.p_referral_agency_id,
          p_residents_attributes: removeObject(
            residentsAttributesOriginal,
            formik.values.p_application_header.p_residents_attributes
          ),
          p_join_guarantors_attributes: removeObject(
            joinGuarantorsAttributesOriginal,
            formik.values.p_application_header.p_join_guarantors_attributes
          ),
          p_borrowings_attributes: removeObject(
            borrowingsAttributesOriginal,
            getBorrowingSalePersons(
              formik.values.p_application_header.loan_type,
              formik.values.p_application_header.p_borrowings_attributes
            )
          ),
          p_applicant_people_attributes: !!p_applicant_people_attributes[1]
            ? [applicant_people_1, applicant_people_2]
            : [applicant_people_1],
          p_borrowing_details_attributes: removeObject(
            borrowingDetailSalepersonOriginal,
            formik.values.p_application_header.p_borrowing_details_attributes
              .length === 2
              ? [
                  formik.values.p_application_header
                    .p_borrowing_details_attributes[0],
                  {
                    ...formik.values.p_application_header
                      .p_borrowing_details_attributes[1],
                    loan_term_year_num:
                      formik.values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_year_num,
                    loan_term_month_num:
                      formik.values.p_application_header
                        .p_borrowing_details_attributes[0].loan_term_month_num,
                    borrowing_detail_type: '2',
                    repayment_method:
                      formik.values.p_application_header
                        .p_borrowing_details_attributes[0].repayment_method,
                  },
                ]
              : formik.values.p_application_header
                  .p_borrowing_details_attributes
          ),
        },
      };

      const isDataNewest =
        !!sbiId && !!preliminaryId && !!timeGetEdit
          ? // @ts-ignore
            await checkUpdateFormSalePerson(preliminaryId, timeGetEdit, sbiId, {
              id: preliminaries.p_application_header.id,
              ...newValues,
            })
          : true;

      const applicant_id =
        preliminaries.p_application_header.p_applicant_people_attributes[0].id;
      const applicant_id_income =
        preliminaries.p_application_header.p_applicant_people_attributes
          .length > 1
          ? preliminaries.p_application_header.p_applicant_people_attributes[1]
              .id
          : '';

      if (selectTab === '1') {
        data.append(
          'identity_verification',
          filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
            ? '1'
            : filesUploadOption === FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
            ? '2'
            : '0'
        );
      } else {
        data.append(
          'identity_verification',
          filesUploadOption === FILES_UPLOAD_OPTIONS.CARD_NUMBER
            ? '1'
            : filesUploadOption === FILES_UPLOAD_OPTIONS.RESIDENT_REGISTER
            ? '2'
            : '0'
        );
      }

      // data file in header
      Object.keys(paramsHeaderFiles).forEach((key) => {
        const paramsHeaderImages = get(paramsHeaderFiles, key, []);
        if (paramsHeaderImages.length === 0) {
          const keyValue = key.replace('_index', '');
          return data.append(`${keyValue}[]`, '');
        }
        paramsHeaderImages.forEach((file: unknown) => {
          if (file instanceof File) {
            const keyValue = key.replace('_index', '');
            data.append(`${keyValue}[]`, file);
          } else {
            const keyValue = key.replace('_index', '');
            // @ts-ignore
            data.append(`${keyValue}[]`, file.id);
          }
        });
      });

      // data borrowing
      Object.keys(paramsFileBorrowing).forEach((key) => {
        const paramsBorrowingImages = get(paramsFileBorrowing, key, []);
        const [keyValue, index] = key.split('_index__');
        const borrowingId =
          preliminaries.p_application_header.p_borrowings_attributes[
            Number(index)
          ]?.id;
        if (paramsBorrowingImages.length === 0 && !!borrowingId) {
          data.append(`p_borrowings_attributes[${index}][id]`, borrowingId);
          data.append(`p_borrowings_attributes[${index}][${keyValue}][]`, '');
        }
        paramsBorrowingImages.forEach((file: unknown) => {
          const [keyValue, index] = key.split('_index__');
          const borrowingId =
            preliminaries.p_application_header.p_borrowings_attributes[
              Number(index)
            ]?.id;
          if (!!borrowingId) {
            if (file instanceof File) {
              data.append(`p_borrowings_attributes[${index}][id]`, borrowingId);
              data.append(
                `p_borrowings_attributes[${index}][${keyValue}][]`,
                file
              );
            } else {
              const [keyValue, index] = key.split('_index__');
              data.append(`p_borrowings_attributes[${index}][id]`, borrowingId);
              data.append(
                `p_borrowings_attributes[${index}][${keyValue}][]`,
                // @ts-ignore
                file.id
              );
            }
          }
        });
      });

      // for 申込人（A）
      if (selectTab === '1' && applicant_id) {
        // files
        Object.keys(paramsFiles).forEach((key) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          const paramsImages = get(paramsFiles, key, []);
          if (paramsImages.length === 0) {
            return isNotUploadMulti
              ? data.append(`${key}`, '')
              : data.append(`${key}[]`, '');
          }
          paramsImages.forEach((file: unknown) => {
            if (file instanceof File) {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  data.append(`${key}`, file);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                data.append(`${key}[]`, file);
              }
            } else {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  //@ts-ignore
                  data.append(`${key}`, file.id);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                //@ts-ignore
                data.append(`${key}[]`, file.id);
              }
            }
          });
        });
        if (isDataNewest) {
          const resultForA = await dispatch(
            updateUploadedFilesSalePerson({
              id: preliminaryId,
              applicant_id,
              data,
            })
          );
          if (updateUploadedFilesSalePerson.fulfilled.match(resultForA)) {
            await formik.submitForm();
          } else {
            handleShowError(resultForA);
          }
        }
      }

      // for 収入合算者
      if (selectTab === '3' && applicant_id_income) {
        // files
        Object.keys(paramsFilesIncomeAdder).forEach((key) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          const paramsIncomeImages = get(paramsFilesIncomeAdder, key, []);
          if (paramsIncomeImages.length === 0) {
            return isNotUploadMulti
              ? data.append(`${key}`, '')
              : data.append(`${key}[]`, '');
          }
          paramsIncomeImages.forEach((file: unknown) => {
            if (file instanceof File) {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  data.append(`${key}`, file);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                data.append(`${key}[]`, file);
              }
            } else {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  //@ts-ignore
                  data.append(`${key}`, file.id);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                //@ts-ignore
                data.append(`${key}[]`, file.id);
              }
            }
          });
        });

        if (isDataNewest) {
          const resultForIncome = await dispatch(
            updateUploadedFilesSalePerson({
              id: preliminaryId,
              applicant_id: applicant_id_income,
              data,
            })
          );
          if (updateUploadedFilesSalePerson.fulfilled.match(resultForIncome)) {
            await formik.submitForm();
          } else {
            handleShowError(resultForIncome);
          }
        }
      }

      // case change loan_type ==> THREE or FOUR
      if (
        selectTab === '3' &&
        !applicant_id_income &&
        !!p_applicant_people_attributes
      ) {
        // files
        Object.keys(paramsFilesIncomeAdder).forEach((key) => {
          const isNotUploadMulti = PARAMS_FILE_UPLOAD_ONE_FILE.find(
            (item) => item === key
          );
          const paramsIncomeImages = get(paramsFilesIncomeAdder, key, []);
          if (paramsIncomeImages.length === 0) {
            return isNotUploadMulti
              ? data.append(`${key}`, '')
              : data.append(`${key}[]`, '');
          }
          paramsIncomeImages.forEach((file: unknown) => {
            if (file instanceof File) {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  data.append(`${key}`, file);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                data.append(`${key}[]`, file);
              }
            } else {
              if (isNotUploadMulti) {
                if (key.includes(filesUploadOption!)) {
                  //@ts-ignore
                  data.append(`${key}`, file.id);
                } else {
                  data.append(`${key}`, '');
                }
              } else {
                //@ts-ignore
                data.append(`${key}[]`, file.id);
              }
            }
          });
        });

        if (isDataNewest) {
          const result = await dispatch(
            // @ts-ignore
            editSalePersonPreliminary({
              id: preliminaries.p_application_header.id,
              ...newValues,
            })
          );
          setSelectIncomeSubTab(0);
          if (editSalePersonPreliminary.fulfilled.match(result)) {
            // TODO: CR change logic select file image
            data.append(`change_loan_type`, '1');
            const resultForIncome = await dispatch(
              updateUploadedFilesSalePerson({
                id: preliminaryId,
                data,
              })
            );

            if (
              updateUploadedFilesSalePerson.fulfilled.match(resultForIncome)
            ) {
              await dispatch(
                getPersonalInformationsSalePerson({
                  id: preliminaryId,
                })
              );
              toast.success('申込内容を更新しました。');
            } else {
              handleShowError(resultForIncome);
            }
          }
        }
      }
    },
    [
      borrowingDetailSalepersonOriginal,
      borrowingsAttributesOriginal,
      checkUpdateFormSalePerson,
      dispatch,
      filesUploadOption,
      formik.values,
      getBorrowingSalePersons,
      getReferralIdAction,
      joinGuarantorsAttributesOriginal,
      preliminaries.p_application_header.id,
      preliminaries.p_application_header.p_applicant_people_attributes,
      preliminaries.p_application_header.p_borrowings_attributes,
      preliminaryId,
      sbiId,
      selectTab,
      timeGetEdit,
      isExistFile,
    ]
  );

  const isMessageIncomeSubTab = useMemo(() => {
    if (!!initialValues.p_application_header.edit_file_upload) {
      return selectIncomeSubTab !== 2;
    } else {
      return true;
    }
  }, [initialValues.p_application_header.edit_file_upload, selectIncomeSubTab]);

  const isDisableButtonSubmit = useMemo(() => {
    // const isRequirePropertyInformationFile =
    //   isRequiredUploadFile('property_information_file_index') &&
    //   formik.values.filesHeader.property_information_file_index.length === 0;
    const isRequireResidenceFile =
      selectTab === '1'
        ? (!!formik.values.files.residence_file &&
            isRequiredUploadFile('residence_file', true) &&
            formik.values.files.residence_file.length === 0) ||
          (!!formik.values.files.residence_file_back_image &&
            isRequiredUploadFile('residence_file_back_image', true) &&
            formik.values.files.residence_file_back_image.length === 0)
        : false;
    const isRequireFilesA =
      selectTab === '1'
        ? (isRequiredUploadFile('card_number_front_image') &&
            formik.values.files.card_number_front_image.length === 0) ||
          (isRequiredUploadFile('driver_license_back_image') &&
            (formik.values.files.driver_license_back_image.length === 0 ||
              formik.values.files.driver_license_front_image.length === 0)) ||
          (isRequiredUploadFile('resident_register_front_image') &&
            (formik.values.files.resident_register_front_image.length === 0 ||
              formik.values.files.resident_register_back_image.length === 0))
        : false;
    return (
      formik.isSubmitting ||
      (selectTab === '1' &&
        isTabUploadFiles &&
        (isRequireResidenceFile || isRequireFilesA))
    );
  }, [
    formik.isSubmitting,
    formik.values.files.card_number_front_image.length,
    formik.values.files.driver_license_back_image.length,
    formik.values.files.driver_license_front_image.length,
    formik.values.files.residence_file,
    formik.values.files.residence_file_back_image,
    formik.values.files.resident_register_back_image.length,
    formik.values.files.resident_register_front_image.length,
    isRequiredUploadFile,
    isTabUploadFiles,
    selectTab,
  ]);

  const isDisableButtonSubmitForIncome = useMemo(() => {
    const isRequireResidenceFileIncome =
      selectTab === '3'
        ? (!!formik.values.filesIncomeAdder.residence_file &&
            isRequiredUploadFile('residence_file') &&
            formik.values.filesIncomeAdder.residence_file.length === 0) ||
          (!!formik.values.filesIncomeAdder.residence_file_back_image &&
            isRequiredUploadFile('residence_file_back_image') &&
            formik.values.filesIncomeAdder.residence_file_back_image.length ===
              0)
        : false;
    const isRequireFilesAIncome =
      selectTab === '3'
        ? (isRequiredUploadFile('card_number_front_image') &&
            formik.values.filesIncomeAdder.card_number_front_image.length ===
              0) ||
          (isRequiredUploadFile('driver_license_back_image') &&
            (formik.values.filesIncomeAdder.driver_license_back_image.length ===
              0 ||
              formik.values.filesIncomeAdder.driver_license_front_image
                .length === 0)) ||
          (isRequiredUploadFile('resident_register_front_image') &&
            (formik.values.filesIncomeAdder.resident_register_front_image
              .length === 0 ||
              formik.values.filesIncomeAdder.resident_register_back_image
                .length === 0))
        : false;
    return (
      formik.isSubmitting ||
      (isTabUploadFilesIncome &&
        (isRequireResidenceFileIncome || isRequireFilesAIncome))
    );
  }, [
    formik.isSubmitting,
    formik.values.filesIncomeAdder.card_number_front_image.length,
    formik.values.filesIncomeAdder.driver_license_back_image.length,
    formik.values.filesIncomeAdder.driver_license_front_image.length,
    formik.values.filesIncomeAdder.residence_file,
    formik.values.filesIncomeAdder.residence_file_back_image,
    formik.values.filesIncomeAdder.resident_register_back_image.length,
    formik.values.filesIncomeAdder.resident_register_front_image.length,
    isRequiredUploadFile,
    isTabUploadFilesIncome,
    selectTab,
  ]);

  const changeModalMessage = () => {
    const addNewPeopleAttribute =
      formik.values.p_application_header.p_applicant_people_attributes?.[1]
        ?.id === APPLICANT_PEOPLE_ATTRIBUTE.id;

    const addNewJoinGuarantor =
      formik.values.p_application_header.p_join_guarantors_attributes?.[0]
        ?.id === JOIN_GUARANTORS_ATTRIBUTE.id;

    // tab 1 => joinGuarantor
    if (selectSubTab === 0 && selectTab === '1' && addNewJoinGuarantor) {
      return setChangeModalMessage(
        `このタブのすべての項目入力は「担保提供者」タブの「保存」ボタンを押下した後に反映します。\n「担保提供者」の入力を完了してください。`
      );
    }

    // tab 1 => income
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      [LoanType.THREE, LoanType.FOUR].includes(
        formik.values.p_application_header.loan_type
      ) &&
      addNewPeopleAttribute
    ) {
      return setChangeModalMessage(
        `このタブのすべての項目入力は「収入合算者」タブの「保存」ボタンを押下した後に反映します。\n「収入合算者」の入力を完了してください。`
      );
    }
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      addNewPeopleAttribute &&
      addNewJoinGuarantor
    ) {
      return setChangeModalMessage(
        `このタブのすべての項目入力は「収入合算者」タブの「保存」ボタンを押下した後に反映します。\n「担保提供者」の入力と「収入合算者」の入力を両方完了してください。`
      );
    }
    return;
  };

  const changeModalOnClose = () => {
    const addNewPeopleAttribute =
      formik.values.p_application_header.p_applicant_people_attributes?.[1]
        ?.id === APPLICANT_PEOPLE_ATTRIBUTE.id;

    const addNewJoinGuarantor =
      formik.values.p_application_header.p_join_guarantors_attributes?.[0]
        ?.id === JOIN_GUARANTORS_ATTRIBUTE.id;

    // tab 1 => joinGuarantor
    if (selectSubTab === 0 && selectTab === '1' && addNewJoinGuarantor) {
      setSelectSubTab(3);
      window.scrollTo(0, 0);
      return setChangeModal(false);
    }

    // tab 1 => income
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      [LoanType.THREE, LoanType.FOUR].includes(
        formik.values.p_application_header.loan_type
      ) &&
      addNewPeopleAttribute
    ) {
      setSelectTab('3');
      window.scrollTo(0, 0);
      return setChangeModal(false);
    }
    if (
      selectSubTab === 0 &&
      selectTab === '1' &&
      addNewPeopleAttribute &&
      addNewJoinGuarantor
    ) {
      setSelectSubTab(3);
      window.scrollTo(0, 0);
      return setChangeModal(false);
    }
  };

  const genParamBorrowingFiles = useCallback(
    (index: number) => {
      return index < 0 || index > 7
        ? formik.values.filesBorrowing.repayment_schedule_image_index__0
        : // @ts-ignore
          formik.values.filesBorrowing[
            `repayment_schedule_image_index__${index}`
          ];
    },
    [formik.values.filesBorrowing]
  );

  const genParamBorrowingFilesLink = useCallback(
    (index: number) => {
      return index < 0 || index > 7
        ? formik.values.filesLinkBorrowing.repayment_schedule_image_index__0
        : // @ts-ignore
          formik.values.filesLinkBorrowing[
            `repayment_schedule_image_index__${index}`
          ];
    },
    [formik.values.filesLinkBorrowing]
  );

  const resultIndex = useMemo<number>(() => {
    let index = 9;
    if (!hasJoinGuarantor) {
      index -= 1;
    }
    if (!initialValues.p_application_header.edit_file_upload) {
      index -= 1;
    }
    return index;
  }, [hasJoinGuarantor, initialValues.p_application_header.edit_file_upload]);

  const resultIndexB = useMemo<number>(() => {
    let index = 9;
    if (!hasJoinGuarantorForB) {
      index -= 1;
    }
    if (!formik.values.link?.p_application_header?.edit_file_upload) {
      index -= 1;
    }
    return index;
  }, [
    hasJoinGuarantorForB,
    formik.values.link?.p_application_header?.edit_file_upload,
  ]);

  const showExaminationResultForA = useMemo(
    () => selectTab === '1' && selectSubTab === resultIndex,
    [resultIndex, selectSubTab, selectTab]
  );

  const showExaminationResultForB = useMemo(
    () =>
      selectTab === '2' && formik.values.link && selectSubTab === resultIndexB,
    [formik.values.link, resultIndexB, selectSubTab, selectTab]
  );

  const getBorrowingDetail = useCallback(
    (link = 'p_application_header') =>
      buildBorrowingDetailSalePerson(get(formik.values, link)),
    [formik.values]
  );

  const getFinancingPlan = useCallback(
    (link = 'p_application_header') =>
      buildFinancingPlanSalePerson(get(formik.values, link)),
    [formik.values]
  );

  const getCurrentBorrowing = useCallback(
    (link = 'p_application_header') =>
      buildCurrentBorrowingSalePerson(managerRole, get(formik.values, link)),
    [formik.values, managerRole]
  );

  const getPersonalResidence = useCallback(
    (link = 'p_application_header') => {
      const newRows = buildPersonalRowsSalePerson(
        PERSONAL_RESIDENCE.filter((row) => !row.required),
        get(formik.values, link),
        plannedCohabitantDynamic,
        managerRole
      );
      return buildPersonalResidenceSalePerson(
        managerRole,
        newRows,
        get(formik.values, link)
      );
    },
    [formik.values, managerRole, plannedCohabitantDynamic]
  );

  const getPersonalOccupation = useCallback(
    (link = 'p_application_header', index = 0) =>
      buildPersonalOccupationSalePerson(get(formik.values, link), index),
    [formik.values]
  );

  const getGuaranteeProvider = useCallback(
    (link = 'p_application_header') =>
      buildGuaranteeProviderSalePerson(get(formik.values, link)),
    [formik.values]
  );

  const getPersonalInformation = useCallback(
    (link = 'p_application_header') =>
      buildPersonalInformationSalePerson(get(formik.values, link)),
    [formik.values]
  );

  const getContactInformation = useCallback(
    (link = 'p_application_header') =>
      buildContactInformationSalePerson(get(formik.values, link)),
    [formik.values]
  );

  return (
    <AdminLayout
      loading={loading}
      hasDrawer={false}
      header={{
        left: { mainTitle: fullNameKanji },
        right: { Icons: true, userName: '' },
      }}
      footer={{
        left: <Icons.SPService sx={{ width: 131, pl: 5 }} />,
        right: <Icons.SPMilize sx={{ width: 70, height: 14, py: 2, pr: 5 }} />,
        bgcolor: 'footer_bg',
      }}
    >
      {editable && (
        <ResetData
          formik={formik}
          dataIncome={dataIncome}
          dataJoinGuarantor={dataJoinGuarantor}
          selectSubTab={selectSubTab}
        />
      )}
      <Stack sx={{ position: 'relative' }}>
        <Stack
          direction="row"
          sx={{
            pt: '10px',
            pb: '5px',
            pr: '34px',
            pl: '37px',
          }}
          alignItems="center"
          justifyContent={statusResult ? 'space-between' : 'end'}
        >
          {statusResult && (
            <Stack direction="row" spacing={1}>
              {isMessageIncomeSubTab &&
                selectSubTab !== 7 &&
                selectSubTab !== 8 &&
                !(
                  // preliminaries.p_application_header.preliminary_status ===
                  //   PreliminaryStatusType.PROVISIONAL_PRELIMINARY &&
                  (
                    selectTab === '1' &&
                    selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 7
                        : 6)
                  )
                ) && (
                  <>
                    <Icons.SPCircleExclamation
                      sx={{ width: 16, height: 16, paddingTop: '5px' }}
                    />
                    <Typography
                      variant="textstyle"
                      sx={{ color: 'sp_secondary_01' }}
                    >
                      {statusResult}
                    </Typography>
                  </>
                )}
            </Stack>
          )}
          {!!formik.values.p_application_header.edit_file_upload && (
            <PulldownDocuments
              itemPreliminary={
                selectTab === '1' || selectTab === '3'
                  ? preliminaries
                  : formik.values.link
              }
              preliminaryId={
                selectTab === '1' || selectTab === '3'
                  ? preliminaryId
                  : preliminaries.p_application_header.linking_id
              }
              applicantId={get(
                formik.values,
                selectTab === '1'
                  ? 'p_application_header.p_applicant_people_attributes[0].id'
                  : selectTab === '2'
                  ? 'link.p_application_header.p_applicant_people_attributes[0].id'
                  : 'p_application_header.p_applicant_people_attributes[1].id',
                ''
              )}
              isCardNumberImage={isCardNumberImage}
              isDriverLicenseImage={isDriverLicenseImage}
              isIncomeTotal={selectTab === '3'}
              selectTab={selectTab}
              // isFirstWithholdingSlipFile={isFirstWithholdingSlipFile}
            />
          )}
        </Stack>
        <Divider sx={{ color: 'gray' }} />
        <FormikProvider value={formik}>
          <Box
            sx={{
              mt: '10px',
              mx: '37px',
              mb: 10,
              border: '1px solid #aaa',
              borderTop: 'none',
              borderTopLeftRadius: '5px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              position: 'relative',
            }}
          >
            {preliminaries.p_application_header.loan_type === '2' &&
              preliminaries.p_application_header.linking_id && (
                <IconButton
                  disabled={!formik.values.link?.p_application_header?.id}
                  onClick={() => {
                    navigate({
                      pathname: routeNames.SalePersonEditPreliminary.path,
                      search: `?preliminary_id=${preliminaries.p_application_header?.linking_id}`,
                    });
                  }}
                  sx={{
                    position: 'absolute',
                    zIndex: 2,
                    left: 140,
                    top: 2,
                    color: '#6b70f0',
                  }}
                >
                  <SwapHorizIcon />
                </IconButton>
              )}
            <TabContext value={selectTab}>
              <TabList onChange={handleChangeTab}>
                <Tab
                  label="申込人（A）"
                  value="1"
                  sx={{
                    color: 'b_333',
                    '&&.Mui-selected': {
                      ...styleMuiTabSelected,
                      '&.MuiTabs-indicator': {
                        display: 'none',
                      },
                    },
                    '&.MuiTab-textColorPrimary': { ...styleMuiTabs, mr: '5px' },
                  }}
                />
                {preliminaries.p_application_header.loan_type === '2' &&
                  preliminaries.p_application_header.linking_id && (
                    <Tab
                      label="申込人（B）"
                      value="2"
                      disabled={!formik.values.link?.p_application_header?.id}
                      sx={{
                        '&&.Mui-selected': {
                          ...styleMuiTabSelected,
                          '&.MuiTabs-indicator': {
                            display: 'none',
                          },
                        },
                        '&.MuiTab-textColorPrimary': {
                          ...styleMuiTabs,
                          mr: '5px',
                        },
                        '& .MuiTabs-indicator': {
                          display: 'none',
                        },
                      }}
                    />
                  )}
                {(formik.values.p_application_header.loan_type === '3' ||
                  formik.values.p_application_header.loan_type === '4') && (
                  <Tab
                    label="収入合算者"
                    value="3"
                    disabled={
                      !formik.values.p_application_header
                        .p_applicant_people_attributes[1]?.id
                    }
                    sx={{
                      '&&.Mui-selected': styleMuiTabSelected,
                      '&.MuiTab-textColorPrimary': styleMuiTabs,
                      '& .MuiTabs-indicator': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              </TabList>
              {openModalConfirm && (
                <ConfirmModal
                  open={openModalConfirm}
                  onClose={() => setOpenModalConfirm(false)}
                  handleCancel={handleCancel}
                  handleOk={handleOk}
                />
              )}
              <TabPanel
                value="1"
                sx={{ boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)', p: 0 }}
              >
                <TabWrapper value={selectSubTab} onChange={handleChangeSubTab}>
                  <TabsList>
                    <TabChildren>お借入のご希望</TabChildren>
                    <TabChildren>あなたの情報</TabChildren>
                    <TabChildren>ご職業</TabChildren>
                    {formik.values.p_application_header
                      .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0' && <TabChildren>担保提供者</TabChildren>}
                    <TabChildren>お住まい</TabChildren>
                    <TabChildren>現在の借入状況</TabChildren>
                    <TabChildren>資金計画</TabChildren>
                    <TabChildren>担当者情報</TabChildren>
                    {!!initialValues.p_application_header.edit_file_upload && (
                      <TabChildren>書類アップロード</TabChildren>
                    )}
                    <TabChildren>審査結果</TabChildren>
                  </TabsList>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ my: 0 }}
                  >
                    {editable &&
                      !(
                        selectTab === '2' &&
                        preliminaries.p_application_header.linking_id
                      ) &&
                      !showExaminationResultForA &&
                      !showExaminationResultForB && (
                        <Button
                          disabled={
                            (selectTab === '1' && isDisableButtonSubmit) ||
                            (selectTab === '3' &&
                              isDisableButtonSubmitForIncome) ||
                            isDisableSaveButton ||
                            submitting
                          }
                          sx={{
                            width: 204,
                            minHeight: 35,
                            bgcolor: 'sp_secondary_01',
                          }}
                          onClick={async () => {
                            setSubmitting(true);
                            try {
                              if (isTabUploadFiles || isTabUploadFilesIncome) {
                                // create check list image
                                const imageLists: Array<File | SPImage> = [];

                                // get image form header
                                Object.keys(formik.values.filesHeader).forEach(
                                  (key) => {
                                    const paramsHeaderImages = get(
                                      formik.values.filesHeader,
                                      key,
                                      []
                                    );
                                    paramsHeaderImages.forEach(
                                      (file: File | SPImage) => {
                                        imageLists.push(file);
                                      }
                                    );
                                  }
                                );
                                // get image form borrowing
                                Object.keys(
                                  formik.values.filesBorrowing
                                ).forEach((key) => {
                                  const paramsBorrowImages = get(
                                    formik.values.filesBorrowing,
                                    key,
                                    []
                                  );
                                  paramsBorrowImages.forEach(
                                    (file: File | SPImage) => {
                                      const [keyValue, index] =
                                        key.split('_index__');
                                      const borrowingId =
                                        preliminaries.p_application_header
                                          .p_borrowings_attributes[
                                          Number(index)
                                        ]?.id;
                                      if (!!borrowingId) imageLists.push(file);
                                    }
                                  );
                                });
                                // get image form people 1
                                Object.keys(formik.values.files).forEach(
                                  (key) => {
                                    const paramsImages = get(
                                      formik.values.files,
                                      key,
                                      []
                                    );
                                    paramsImages.forEach(
                                      (file: File | SPImage) => {
                                        imageLists.push(file);
                                      }
                                    );
                                  }
                                );
                                // get image form people 2
                                Object.keys(
                                  formik.values.filesIncomeAdder
                                ).forEach((key) => {
                                  const paramsIncomeImages = get(
                                    formik.values.filesIncomeAdder,
                                    key,
                                    []
                                  );
                                  paramsIncomeImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                });
                                const promises = imageLists.map((image) => {
                                  const type =
                                    image instanceof File
                                      ? image.type
                                      : getFileExtFromUrl(image.url);
                                  if (type.includes('pdf')) {
                                    if (image instanceof File) {
                                      return checkIfPDFExists(
                                        URL.createObjectURL(image)
                                      );
                                    } else {
                                      return checkIfPDFExists(image.url);
                                    }
                                  } else {
                                    if (image instanceof File) {
                                      return checkIfImageExists(image);
                                    } else {
                                      return checkIfImageExists(image.url);
                                    }
                                  }
                                });

                                Promise.all(promises).then((result) => {
                                  if (!result.every((item) => item)) {
                                    modalErrorRef.current?.open(
                                      '',
                                      FILE_NOT_EXIST,
                                      true
                                    );
                                    return;
                                  } else {
                                    handleSubmitEditUploadedFiles(
                                      formik.values.files,
                                      formik.values.filesIncomeAdder,
                                      formik.values.filesHeader,
                                      formik.values.filesBorrowing,
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes
                                    );
                                  }
                                });
                              } else {
                                await formik.submitForm();
                              }
                            } catch (e) {}
                            setSubmitting(false);
                          }}
                        >
                          保存
                        </Button>
                      )}
                  </Stack>
                  <TabPanels value={0} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 0 && preliminaryId && (
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getBorrowingDetail()}
                        parentKey="p_application_header"
                        editable={editable}
                      />
                    )}
                  </TabPanels>
                  <TabPanels value={1} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 1 && preliminaryId && (
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getPersonalInformation()}
                        parentKey="p_application_header"
                        editable={editable}
                      />
                    )}
                  </TabPanels>
                  <TabPanels value={2} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 2 && preliminaryId && (
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getPersonalOccupation()}
                        parentKey="p_application_header"
                        editable={editable}
                      />
                    )}
                  </TabPanels>
                  {formik.values.p_application_header
                    .p_applicant_people_attributes[0].has_join_guarantor !==
                    '0' && (
                    <TabPanels value={3} sx={{ width: '100%', pb: 5 }}>
                      {selectSubTab === 3 && preliminaryId && (
                        <Stack spacing={7}>
                          {getGuaranteeProvider().map((table, index) => (
                            <Stack key={index}>
                              <Typography variant="pdf_title" fontSize={15}>
                                担保提供者
                                <Typography variant="pdf_title">
                                  （{index + 1}人目）
                                </Typography>
                              </Typography>
                              <AdminPreliminaryTable
                                parentTabIndex={selectTab}
                                appraisalId={preliminaryId}
                                rows={table}
                                parentKey="p_application_header"
                                editable={editable}
                                noAdd
                                indexJoinGuarantor={index}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      )}
                    </TabPanels>
                  )}
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 4
                        : 3
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 4
                        : 3) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={getPersonalResidence()}
                          parentKey="p_application_header"
                          quantityDependent={{
                            siblings_number:
                              formik.values.p_application_header
                                .siblings_number,
                            other_people_number:
                              formik.values.p_application_header
                                .other_people_number,
                            children_number:
                              formik.values.p_application_header
                                .children_number,
                          }}
                          editable={editable}
                          yearLength={getYearLength(
                            formik.values.p_application_header
                              .p_applicant_people_attributes?.[0]?.birthday
                          )}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 5
                        : 4
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 5
                        : 4) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={getCurrentBorrowing()}
                          parentKey="p_application_header"
                          isBorrowing
                          isArray
                          editable={editable}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 6
                        : 5
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 6
                        : 5) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={getFinancingPlan()}
                          parentKey="p_application_header"
                          editable={editable}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={
                      formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 7
                        : 6
                    }
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab ===
                      (formik.values.p_application_header
                        .p_applicant_people_attributes[0].has_join_guarantor !==
                      '0'
                        ? 7
                        : 6) &&
                      preliminaryId && (
                        <AdminPreliminaryTable
                          parentTabIndex={selectTab}
                          appraisalId={preliminaryId}
                          rows={getContactInformation()}
                          parentKey="p_application_header"
                          editable={editable}
                        />
                      )}
                  </TabPanels>
                  {/* For 申込人（A） */}
                  {!!initialValues.p_application_header.edit_file_upload && (
                    <TabPanels
                      value={
                        formik.values.p_application_header
                          .p_applicant_people_attributes[0]
                          .has_join_guarantor !== '0'
                          ? 8
                          : 7
                      }
                      sx={{
                        backgroundColor: 'main_white',
                        overflowY: 'scroll',
                        height: 'calc(100vh - 340px)',
                      }}
                    >
                      <Stack direction="row" spacing={4} sx={{ pb: '65px' }}>
                        <Stack
                          flex={1}
                          spacing={4}
                          sx={{
                            width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                          }}
                        >
                          {/* A */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              title="本人確認書類"
                              name="A"
                              setOpenPreview={handlePreview}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'driver_license_front_image'
                                    ),
                                  jp_name: `運転免許書`,
                                  en_name: 'driver_license_front_image',
                                  isRequired: isRequiredUploadFile(
                                    'driver_license_front_image',
                                    true
                                  ),
                                  files:
                                    formik.values.files
                                      .driver_license_front_image,
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'driver_license_back_image'
                                    ),
                                  jp_name: '運転免許書',
                                  en_name: 'driver_license_back_image',
                                  files:
                                    formik.values.files
                                      .driver_license_back_image,
                                  isRequired: isRequiredUploadFile(
                                    'driver_license_back_image',
                                    true
                                  ),
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'card_number_front_image'
                                    ),
                                  jp_name: `マイナンバーカード`,
                                  en_name: 'card_number_front_image',
                                  isRequired: isRequiredUploadFile(
                                    'card_number_front_image',
                                    true
                                  ),
                                  files:
                                    formik.values.files.card_number_front_image,
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'resident_register_front_image'
                                    ),
                                  jp_name: `住民基本台帳カード`,
                                  en_name: 'resident_register_front_image',
                                  isRequired: isRequiredUploadFile(
                                    'resident_register_front_image',
                                    true
                                  ),
                                  files:
                                    formik.values.files
                                      .resident_register_front_image,
                                },
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'resident_register_back_image'
                                    ),
                                  jp_name: '住民基本台帳カード',
                                  en_name: 'resident_register_back_image',
                                  isRequired: isRequiredUploadFile(
                                    'resident_register_back_image',
                                    true
                                  ),
                                  files:
                                    formik.values.files
                                      .resident_register_back_image,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              filesUploadOption={filesUploadOption}
                              handleRadioChange={(option) => {
                                formik.setFieldValue('isChangeFiles', true);
                                setFilesUploadOption(option);
                              }}
                              onChange={handleChangeFiles}
                            />
                          </Stack>
                          {/* B */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              title="健康保険証"
                              name="B"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'insurance_file'
                                    ),
                                  jp_name: '健康保険証〈表面〉',
                                  en_name: 'insurance_file',
                                  isRequired:
                                    isRequiredUploadFile('insurance_file'),
                                  files: formik.values.files.insurance_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={false}
                              isDisplay={true}
                              title=""
                              name=""
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'insurance_file_back_image'
                                    ),
                                  jp_name: '健康保険証〈裏面〉',
                                  en_name: 'insurance_file_back_image',
                                  isRequired: isRequiredUploadFile(
                                    'insurance_file_back_image'
                                  ),
                                  files:
                                    formik.values.files
                                      .insurance_file_back_image,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* C */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              //isFirstWithholdingSlipFile()
                              isDisplay={true}
                              title="収入に関する書類"
                              name="C"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'first_withholding_slip_file'
                                    ),
                                  jp_name: '源泉徴収票（前年度分）',
                                  en_name: 'first_withholding_slip_file',
                                  isRequired: isRequiredUploadFile(
                                    'first_withholding_slip_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .first_withholding_slip_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              // title={
                              //   isFirstWithholdingSlipFile()
                              //     ? ''
                              //     : '収入に関する書類'
                              // }
                              // name={isFirstWithholdingSlipFile() ? '' : 'C'}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'second_withholding_slip_file'
                                    ),
                                  jp_name: '源泉徴収票（前々年度分）',
                                  en_name: 'second_withholding_slip_file',
                                  isRequired: isRequiredUploadFile(
                                    'second_withholding_slip_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .second_withholding_slip_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'first_income_file'
                                    ),
                                  jp_name: '確定申告書（1期前）',
                                  en_name: 'first_income_file',
                                  isRequired:
                                    isRequiredUploadFile('first_income_file'),
                                  files: formik.values.files.first_income_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'second_income_file'
                                    ),
                                  jp_name: '確定申告書（2期前）',
                                  en_name: 'second_income_file',
                                  isRequired:
                                    isRequiredUploadFile('second_income_file'),
                                  files: formik.values.files.second_income_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'third_income_file'
                                    ),
                                  jp_name: '確定申告書（3期前）',
                                  en_name: 'third_income_file',
                                  isRequired:
                                    isRequiredUploadFile('third_income_file'),
                                  files: formik.values.files.third_income_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* D */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="非上場企業の役員の方の書類"
                              name="D"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'financial_statement_1_file'
                                    ),
                                  jp_name: '会社の決算報告書（1期前）',
                                  en_name: 'financial_statement_1_file',
                                  isRequired: isRequiredUploadFile(
                                    'financial_statement_1_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .financial_statement_1_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'financial_statement_2_file'
                                    ),
                                  jp_name: '会社の決算報告書（2期前）',
                                  en_name: 'financial_statement_2_file',
                                  isRequired: isRequiredUploadFile(
                                    'financial_statement_2_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .financial_statement_2_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'financial_statement_3_file'
                                    ),
                                  jp_name: '会社の決算報告書（3期前）',
                                  en_name: 'financial_statement_3_file',
                                  isRequired: isRequiredUploadFile(
                                    'financial_statement_3_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .financial_statement_3_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* E */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="雇用契約に関する書類"
                              name="E"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'employment_agreement_file'
                                    ),
                                  jp_name: '雇用契約書',
                                  en_name: 'employment_agreement_file',
                                  isRequired: isRequiredUploadFile(
                                    'employment_agreement_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .employment_agreement_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                        </Stack>
                        <Stack
                          flex={1}
                          spacing={4}
                          sx={{
                            width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                          }}
                        >
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="親族経営の会社等にご勤務の方に関する書類"
                              name="F"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'business_tax_return_1_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（1期前）`,
                                  en_name: 'business_tax_return_1_file',
                                  isRequired: isRequiredUploadFile(
                                    'business_tax_return_1_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .business_tax_return_1_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'business_tax_return_2_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（2期前）`,
                                  en_name: 'business_tax_return_2_file',
                                  isRequired: isRequiredUploadFile(
                                    'business_tax_return_2_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .business_tax_return_2_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'business_tax_return_3_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（3期前）`,
                                  en_name: 'business_tax_return_3_file',
                                  isRequired: isRequiredUploadFile(
                                    'business_tax_return_3_file'
                                  ),
                                  files:
                                    formik.values.files
                                      .business_tax_return_3_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItemHeader
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="物件についての書類"
                              name="G"
                              options={[
                                {
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicationHeader',
                                  isHasLog:
                                    formik.values.p_application_header.has_log_fields?.includes(
                                      'property_information_file'
                                    ),
                                  jp_name: 'チラシ・パンフレット',
                                  en_name: 'property_information_file_index',
                                  isRequired: isRequiredUploadFile(
                                    'property_information_file_index'
                                  ),
                                  files:
                                    formik.values.filesHeader
                                      .property_information_file_index,
                                },
                              ]}
                              onChange={handleChangeFilesHeader(
                                'property_information_file_index'
                              )}
                              setOpenPreview={handlePreview}
                            />
                            {/* HOMELOAN-2299 */}
                            {/* <DocumentUploadItemHeader
                            editable={editable}
                            multiple={true}
                            isDisplay={true}
                            title=""
                            name=""
                            options={[
                              {
                                jp_name: '物件書類（登記簿謄本等）',
                                en_name:
                                  'manager_property_information_file_index',
                                isRequired: isRequiredUploadFile(
                                  'manager_property_information_file_index'
                                ),
                                files:
                                  formik.values.filesHeader
                                    .manager_property_information_file_index,
                              },
                            ]}
                            onChange={handleChangeFilesHeader(
                              'manager_property_information_file_index'
                            )}
                            setOpenPreview={handlePreview}
                          /> */}
                          </Stack>
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title="在留カード"
                              name="H"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'residence_file'
                                    ),
                                  jp_name: '在留カード 〈表面〉',
                                  en_name: 'residence_file',
                                  isRequired: isRequiredUploadFile(
                                    'residence_file',
                                    true
                                  ),
                                  files: formik.values.files.residence_file,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              name=""
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'residence_file_back_image'
                                    ),
                                  jp_name: '在留カード 〈裏面〉',
                                  en_name: 'residence_file_back_image',
                                  isRequired: isRequiredUploadFile(
                                    'residence_file_back_image',
                                    true
                                  ),
                                  files:
                                    formik.values.files
                                      .residence_file_back_image,
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                            />
                          </Stack>
                          {!!preliminaries.p_application_header
                            .p_borrowings_attributes.length && (
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              {preliminaries.p_application_header.p_borrowings_attributes.map(
                                (borrowing, index) => (
                                  <DocumentUploadItemBorrowing
                                    isSalePerson
                                    key={index}
                                    editable={editable}
                                    multiple={true}
                                    isDisplay={true}
                                    title={
                                      index === 0
                                        ? '返済予定表・利用明細書'
                                        : ''
                                    }
                                    setOpenPreview={handlePreview}
                                    name={index === 0 ? 'I' : ''}
                                    options={[
                                      {
                                        borrowId: borrowing.id,
                                        headerId:
                                          preliminaries.p_application_header.id,
                                        trackable_type: 'PBorrowing',
                                        isHasLog:
                                          borrowing.has_log_fields?.includes(
                                            'repayment_schedule_image'
                                          ),
                                        jp_name: `${index + 1}件目の借入`,
                                        // @ts-ignore
                                        en_name: genParamBorrowing(index),
                                        isRequired: isRequiredUploadFile(
                                          'repayment_schedule_image_index'
                                        ),
                                        files: genParamBorrowingFiles(index),
                                      },
                                    ]}
                                    isLoadingFiles={isLoadingFiles}
                                    onChange={handleChangeFilesBorrowing(
                                      'repayment_schedule_image_index',
                                      index
                                    )}
                                  />
                                )
                              )}
                            </Stack>
                          )}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItemHeader
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              title="提携会社の担当者名刺"
                              setOpenPreview={handlePreview}
                              name="J"
                              options={[
                                {
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicationHeader',
                                  isHasLog:
                                    formik.values.p_application_header.has_log_fields?.includes(
                                      'business_card'
                                    ),
                                  jp_name: '',
                                  en_name: 'business_card_index',
                                  isRequired: isRequiredUploadFile(
                                    'business_card_index'
                                  ),
                                  files:
                                    formik.values.filesHeader
                                      .business_card_index,
                                },
                              ]}
                              onChange={handleChangeFilesHeader(
                                'business_card_index'
                              )}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={editable}
                              multiple={true}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title="その他"
                              name="K"
                              options={[
                                {
                                  personId:
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'other_document_file'
                                    ),
                                  jp_name: '',
                                  en_name: 'other_document_file',
                                  files:
                                    formik.values.files.other_document_file,
                                  isRequired: isRequiredUploadFile(
                                    'other_document_file'
                                  ),
                                },
                              ]}
                              isLoadingFiles={isLoadingFiles}
                              onChange={handleChangeFiles}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </TabPanels>
                  )}
                  <TabPanels
                    value={resultIndex}
                    sx={{
                      pt: 1,
                      my: '50px',
                    }}
                  >
                    {/* for 申込人（A） */}
                    {selectSubTab === resultIndex && (
                      <>
                        {examinationUrl ? (
                          <AdminPreviewPDF
                            src={examinationUrl}
                            filename={examinationFileName}
                            isSalePerson={true}
                            setDisableBtnPDF={setDisableBtnPDF}
                            id={preliminaryId}
                          />
                        ) : (
                          <AdminPreviewPDF
                            src={examinationUrl}
                            filename={examinationFileName}
                            isSalePerson={true}
                            isExaminationEmpty
                          />
                        )}
                      </>
                    )}
                  </TabPanels>
                </TabWrapper>
              </TabPanel>
              <TabPanel
                value="2"
                sx={{ boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)', p: 0 }}
              >
                <TabWrapper value={selectSubTab} onChange={handleChangeSubTab}>
                  <TabsList>
                    <TabChildren>お借入のご希望</TabChildren>
                    <TabChildren>あなたの情報</TabChildren>
                    <TabChildren>ご職業</TabChildren>
                    {hasJoinGuarantorForB && (
                      <TabChildren>担保提供者</TabChildren>
                    )}
                    <TabChildren>お住まい</TabChildren>
                    <TabChildren>現在の借入状況</TabChildren>
                    <TabChildren>資金計画</TabChildren>
                    <TabChildren>担当者情報</TabChildren>
                    {!!formik.values.link?.p_application_header
                      ?.edit_file_upload && (
                      <TabChildren>書類アップロード</TabChildren>
                    )}
                    <TabChildren>審査結果</TabChildren>
                  </TabsList>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ my: 0 }}
                  >
                    {editable &&
                      !(
                        selectTab === '2' &&
                        preliminaries.p_application_header.linking_id
                      ) &&
                      !showExaminationResultForA &&
                      !showExaminationResultForB && (
                        <Button
                          disabled={
                            (selectTab === '1' && isDisableButtonSubmit) ||
                            (selectTab === '3' &&
                              isDisableButtonSubmitForIncome) ||
                            isDisableSaveButton
                          }
                          sx={{
                            mt: '30px',
                            mb: 5,
                            width: 204,
                            minHeight: 35,
                            bgcolor: 'sp_secondary_01',
                          }}
                          onClick={() => {
                            if (isTabUploadFiles || isTabUploadFilesIncome) {
                              // create check list image
                              const imageLists: Array<File | SPImage> = [];

                              // get image form header
                              Object.keys(formik.values.filesHeader).forEach(
                                (key) => {
                                  const paramsHeaderImages = get(
                                    formik.values.filesHeader,
                                    key,
                                    []
                                  );
                                  paramsHeaderImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form borrowing
                              Object.keys(formik.values.filesBorrowing).forEach(
                                (key) => {
                                  const paramsBorrowImages = get(
                                    formik.values.filesBorrowing,
                                    key,
                                    []
                                  );
                                  paramsBorrowImages.forEach(
                                    (file: File | SPImage) => {
                                      const [keyValue, index] =
                                        key.split('_index__');
                                      const borrowingId =
                                        preliminaries.p_application_header
                                          .p_borrowings_attributes[
                                          Number(index)
                                        ]?.id;
                                      if (!!borrowingId) imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 1
                              Object.keys(formik.values.files).forEach(
                                (key) => {
                                  const paramsImages = get(
                                    formik.values.files,
                                    key,
                                    []
                                  );
                                  paramsImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 2
                              Object.keys(
                                formik.values.filesIncomeAdder
                              ).forEach((key) => {
                                const paramsIncomeImages = get(
                                  formik.values.filesIncomeAdder,
                                  key,
                                  []
                                );
                                paramsIncomeImages.forEach(
                                  (file: File | SPImage) => {
                                    imageLists.push(file);
                                  }
                                );
                              });
                              const promises = imageLists.map((image) => {
                                const type =
                                  image instanceof File
                                    ? image.type
                                    : getFileExtFromUrl(image.url);
                                if (type.includes('pdf')) {
                                  if (image instanceof File) {
                                    return checkIfPDFExists(
                                      URL.createObjectURL(image)
                                    );
                                  } else {
                                    return checkIfPDFExists(image.url);
                                  }
                                } else {
                                  if (image instanceof File) {
                                    return checkIfImageExists(image);
                                  } else {
                                    return checkIfImageExists(image.url);
                                  }
                                }
                              });

                              Promise.all(promises).then((result) => {
                                if (!result.every((item) => item)) {
                                  modalErrorRef.current?.open(
                                    '',
                                    FILE_NOT_EXIST,
                                    true
                                  );
                                  return;
                                } else {
                                  handleSubmitEditUploadedFiles(
                                    formik.values.files,
                                    formik.values.filesIncomeAdder,
                                    formik.values.filesHeader,
                                    formik.values.filesBorrowing,
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes
                                  );
                                }
                              });
                            } else {
                              formik.handleSubmit();
                            }
                          }}
                        >
                          保存
                        </Button>
                      )}
                  </Stack>
                  <TabPanels value={0} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 0 &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          parentTabIndex={selectTab}
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getBorrowingDetail('link.p_application_header')}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  <TabPanels value={1} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 1 &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          parentTabIndex={selectTab}
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getPersonalInformation(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  <TabPanels value={2} sx={{ width: '100%', pb: 5 }}>
                    {selectSubTab === 2 &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          parentTabIndex={selectTab}
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getPersonalOccupation(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  {hasJoinGuarantorForB && (
                    <TabPanels value={3} sx={{ width: '100%', pb: 5 }}>
                      {selectSubTab === 3 &&
                        preliminaries.p_application_header.linking_id && (
                          <Stack spacing={7}>
                            {getGuaranteeProvider(
                              'link.p_application_header'
                            ).map((table, index) => (
                              <Stack key={index}>
                                <Typography variant="pdf_title" fontSize={15}>
                                  担保提供者
                                  <Typography variant="pdf_title">
                                    （{index + 1}人目）
                                  </Typography>
                                </Typography>
                                <AdminPreliminaryTable
                                  isLinked
                                  noRequired
                                  parentTabIndex={selectTab}
                                  appraisalId={
                                    preliminaries.p_application_header
                                      .linking_id
                                  }
                                  rows={table}
                                  parentKey="link.p_application_header"
                                  indexJoinGuarantor={index}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        )}
                    </TabPanels>
                  )}
                  <TabPanels
                    value={hasJoinGuarantorForB ? 4 : 3}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 4 : 3) &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          parentTabIndex={selectTab}
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getPersonalResidence(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                          quantityDependent={{
                            siblings_number:
                              formik.values.link?.p_application_header
                                .siblings_number ?? '',
                            other_people_number:
                              formik.values.link?.p_application_header
                                .other_people_number ?? '',
                            children_number:
                              formik.values.link?.p_application_header
                                .children_number ?? '',
                          }}
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={hasJoinGuarantorForB ? 5 : 4}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 5 : 4) &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          parentTabIndex={selectTab}
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getCurrentBorrowing(
                            'link.p_application_header'
                          )}
                          parentKey="link.p_application_header"
                          isArray
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={hasJoinGuarantorForB ? 6 : 5}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 6 : 5) &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          parentTabIndex={selectTab}
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getFinancingPlan('link.p_application_header')}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  <TabPanels
                    value={hasJoinGuarantorForB ? 7 : 6}
                    sx={{ width: '100%', pb: 5 }}
                  >
                    {selectSubTab === (hasJoinGuarantorForB ? 7 : 6) &&
                      preliminaries.p_application_header.linking_id && (
                        <AdminPreliminaryTable
                          isLinked
                          noRequired
                          parentTabIndex={selectTab}
                          appraisalId={
                            preliminaries.p_application_header.linking_id
                          }
                          rows={getContactInformation()}
                          parentKey="link.p_application_header"
                        />
                      )}
                  </TabPanels>
                  {/* For 申込人（B） */}
                  {!!initialValues.p_application_header.edit_file_upload && (
                    <TabPanels
                      value={hasJoinGuarantorForB ? 8 : 7}
                      sx={{
                        backgroundColor: 'main_white',
                        overflowY: 'scroll',
                        height: 'calc(100vh - 340px)',
                      }}
                    >
                      <Stack direction="row" spacing={4} sx={{ pb: '65px' }}>
                        <Stack
                          flex={1}
                          spacing={4}
                          sx={{
                            width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                          }}
                        >
                          {/* 申込人（B） - A */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={false}
                              isDisplay={
                                formik.values.link?.p_application_header
                                  .p_applicant_people_attributes?.[0]
                                  ?.identity_verification === '0' ||
                                !formik.values.link?.p_application_header
                                  .p_applicant_people_attributes?.[0]
                                  ?.identity_verification
                              }
                              title="本人確認書類"
                              name="A"
                              setOpenPreview={handlePreview}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'driver_license_front_image'
                                    ),
                                  jp_name: `運転免許書・マイナンバーカード・\n住民基本台帳カード＜表面＞`,
                                  en_name: 'driver_license_front_image',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .driver_license_front_image,
                                },
                              ]}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={false}
                              isDisplay={
                                formik.values.link?.p_application_header
                                  .p_applicant_people_attributes?.[0]
                                  ?.identity_verification === '0' ||
                                !formik.values.link?.p_application_header
                                  .p_applicant_people_attributes?.[0]
                                  ?.identity_verification
                              }
                              title=""
                              name=""
                              setOpenPreview={handlePreview}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'driver_license_back_image'
                                    ),
                                  jp_name:
                                    '運転免許書・マイナンバーカード・\n住民基本台帳カード＜裏面＞',
                                  en_name: 'driver_license_back_image',
                                  files:
                                    formik.values.filesLink
                                      .driver_license_back_image,
                                  isRequired: false,
                                },
                              ]}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={false}
                              isDisplay={
                                formik.values.link?.p_application_header
                                  .p_applicant_people_attributes?.[0]
                                  ?.identity_verification === '1'
                              }
                              title="本人確認書類"
                              name="A"
                              setOpenPreview={handlePreview}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'card_number_front_image'
                                    ),
                                  jp_name: `運転免許書・マイナンバーカード・\n住民基本台帳カード＜表面＞`,
                                  en_name: 'card_number_front_image',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .card_number_front_image,
                                },
                              ]}
                            />
                            {/*<DocumentUploadItem*/}
                            {/*  editable={false}*/}
                            {/*  multiple={false}*/}
                            {/*  isDisplay={*/}
                            {/*    formik.values.link?.p_application_header*/}
                            {/*      .p_applicant_people_attributes?.[0]*/}
                            {/*      ?.identity_verification === '1'*/}
                            {/*  }*/}
                            {/*  title=""*/}
                            {/*  name=""*/}
                            {/*  setOpenPreview={handlePreview}*/}
                            {/*  options={[*/}
                            {/*    {*/}
                            {/*      jp_name:*/}
                            {/*        '運転免許書・マイナンバーカード・\n住民基本台帳カード＜裏面＞',*/}
                            {/*      en_name: 'card_number_back_image',*/}
                            {/*      isRequired: false,*/}
                            {/*      files:*/}
                            {/*        formik.values.filesLink*/}
                            {/*          .card_number_back_image,*/}
                            {/*    },*/}
                            {/*  ]}*/}
                            {/*/>*/}
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={false}
                              isDisplay={
                                formik.values.link?.p_application_header
                                  .p_applicant_people_attributes?.[0]
                                  ?.identity_verification === '2'
                              }
                              title="本人確認書類"
                              name="A"
                              setOpenPreview={handlePreview}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'resident_register_front_image'
                                    ),
                                  jp_name: `運転免許書・マイナンバーカード・\n住民基本台帳カード＜表面＞`,
                                  en_name: 'resident_register_front_image',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .resident_register_front_image,
                                },
                              ]}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={false}
                              isDisplay={
                                formik.values.link?.p_application_header
                                  .p_applicant_people_attributes?.[0]
                                  ?.identity_verification === '2'
                              }
                              title=""
                              name=""
                              setOpenPreview={handlePreview}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'resident_register_back_image'
                                    ),
                                  jp_name:
                                    '運転免許書・マイナンバーカード・\n住民基本台帳カード＜裏面＞',
                                  en_name: 'resident_register_back_image',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .resident_register_back_image,
                                },
                              ]}
                            />
                          </Stack>
                          {/* 申込人（B） - B */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={false}
                              isDisplay={true}
                              title="健康保険証"
                              name="B"
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'insurance_file'
                                    ),
                                  jp_name: '健康保険証〈表面〉',
                                  en_name: 'insurance_file',
                                  isRequired: false,
                                  files: formik.values.filesLink.insurance_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={false}
                              isDisplay={true}
                              title=""
                              name=""
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'insurance_file_back_image'
                                    ),
                                  jp_name: '健康保険証〈裏面〉',
                                  en_name: 'insurance_file_back_image',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .insurance_file_back_image,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* 申込人（B） - C */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              //isFirstWithholdingSlipFile()
                              isDisplay={true}
                              title="収入に関する書類"
                              name="C"
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'first_withholding_slip_file'
                                    ),
                                  jp_name: '源泉徴収票（前年度分）',
                                  en_name: 'first_withholding_slip_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .first_withholding_slip_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              // title={
                              //   isFirstWithholdingSlipFile()
                              //     ? ''
                              //     : '収入に関する書類'
                              // }
                              // name={isFirstWithholdingSlipFile() ? '' : 'C'}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'second_withholding_slip_file'
                                    ),
                                  jp_name: '源泉徴収票（前々年度分）',
                                  en_name: 'second_withholding_slip_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .second_withholding_slip_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'first_income_file'
                                    ),
                                  jp_name: '確定申告書（1期前）',
                                  en_name: 'first_income_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink.first_income_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'second_income_file'
                                    ),
                                  jp_name: '確定申告書（2期前）',
                                  en_name: 'second_income_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink.second_income_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'third_income_file'
                                    ),
                                  jp_name: '確定申告書（3期前）',
                                  en_name: 'third_income_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink.third_income_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* 申込人（B） - D */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              title="非上場企業の役員の方の書類"
                              name="D"
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'financial_statement_1_file'
                                    ),
                                  jp_name: '会社の決算報告書（1期前）',
                                  en_name: 'financial_statement_1_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .financial_statement_1_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'financial_statement_2_file'
                                    ),
                                  jp_name: '会社の決算報告書（2期前）',
                                  en_name: 'financial_statement_2_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .financial_statement_2_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'financial_statement_3_file'
                                    ),
                                  jp_name: '会社の決算報告書（3期前）',
                                  en_name: 'financial_statement_3_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .financial_statement_3_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* 申込人（B）- E */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              title="雇用契約に関する書類"
                              name="E"
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'employment_agreement_file'
                                    ),
                                  jp_name: '雇用契約書',
                                  en_name: 'employment_agreement_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .employment_agreement_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                        </Stack>
                        <Stack
                          flex={1}
                          spacing={4}
                          sx={{
                            width: (theme) => `calc(50% - ${theme.spacing(2)})`,
                          }}
                        >
                          {/* 申込人（B）- F */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              title="親族経営の会社等にご勤務の方に関する書類"
                              name="F"
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'business_tax_return_1_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（1期前）`,
                                  en_name: 'business_tax_return_1_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .business_tax_return_1_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'business_tax_return_2_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（2期前）`,
                                  en_name: 'business_tax_return_2_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .business_tax_return_2_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'business_tax_return_3_file'
                                    ),
                                  jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（3期前）`,
                                  en_name: 'business_tax_return_3_file',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .business_tax_return_3_file,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                          </Stack>
                          {/* 申込人（B）- G */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItemHeader
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              title="物件についての書類"
                              name="G"
                              options={[
                                {
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicationHeader',
                                  isHasLog:
                                    formik.values.p_application_header.has_log_fields?.includes(
                                      'business_card'
                                    ),
                                  jp_name: 'チラシ・パンフレット',
                                  en_name: 'property_information_file_index',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLinkHeader
                                      .property_information_file_index,
                                },
                              ]}
                              setOpenPreview={handlePreview}
                            />
                            {/* HOMELOAN-2299 */}
                            {/* <DocumentUploadItemHeader
                            editable={false}
                            multiple={true}
                            isDisplay={true}
                            title=""
                            name=""
                            options={[
                              {
                                jp_name: '物件書類（登記簿謄本等）',
                                en_name:
                                  'manager_property_information_file_index',
                                isRequired: false,
                                files:
                                  formik.values.filesLinkHeader
                                    .manager_property_information_file_index,
                              },
                            ]}
                            setOpenPreview={handlePreview}
                          /> */}
                          </Stack>
                          {/* 申込人（B）- H */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title="在留カード"
                              name="H"
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'residence_file'
                                    ),
                                  jp_name: '在留カード 〈表面〉',
                                  en_name: 'residence_file',
                                  isRequired: false,
                                  files: formik.values.filesLink.residence_file,
                                },
                              ]}
                            />
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title=""
                              name=""
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'residence_file_back_image'
                                    ),
                                  jp_name: '在留カード 〈裏面〉',
                                  en_name: 'residence_file_back_image',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLink
                                      .residence_file_back_image,
                                },
                              ]}
                            />
                          </Stack>
                          {/* 申込人（B）- I */}
                          {!!formik.values.link?.p_application_header
                            .p_borrowings_attributes.length && (
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              {formik.values.link?.p_application_header.p_borrowings_attributes.map(
                                (borrowing, index) => (
                                  <DocumentUploadItemBorrowing
                                    isSalePerson
                                    key={index}
                                    editable={false}
                                    multiple={true}
                                    isDisplay={true}
                                    title={
                                      index === 0
                                        ? '返済予定表・利用明細書'
                                        : ''
                                    }
                                    setOpenPreview={handlePreview}
                                    name={index === 0 ? 'I' : ''}
                                    options={[
                                      {
                                        borrowId: borrowing.id,
                                        headerId:
                                          preliminaries.p_application_header.id,
                                        trackable_type: 'PBorrowing',
                                        isHasLog:
                                          borrowing.has_log_fields?.includes(
                                            'repayment_schedule_image'
                                          ),
                                        jp_name: `${index + 1}件目の借入`,
                                        // @ts-ignore
                                        en_name: genParamBorrowing(index),
                                        isRequired: false,
                                        files:
                                          genParamBorrowingFilesLink(index),
                                      },
                                    ]}
                                  />
                                )
                              )}
                            </Stack>
                          )}
                          {/* 申込人（B）- J */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItemHeader
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              title="提携会社の担当者名刺"
                              setOpenPreview={handlePreview}
                              name="J"
                              options={[
                                {
                                  headerId:
                                    formik.values.p_application_header.id,
                                  trackable_type: 'PApplicationHeader',
                                  isHasLog:
                                    formik.values.p_application_header.has_log_fields?.includes(
                                      'business_card'
                                    ),
                                  jp_name: '',
                                  en_name: 'business_card_index',
                                  isRequired: false,
                                  files:
                                    formik.values.filesLinkHeader
                                      .business_card_index,
                                },
                              ]}
                            />
                          </Stack>
                          {/* 申込人（B）- K */}
                          <Stack
                            sx={{
                              border: (theme) =>
                                `1px solid ${theme?.palette?.bg_gray}`,
                            }}
                          >
                            <DocumentUploadItem
                              isSalePerson
                              editable={false}
                              multiple={true}
                              isDisplay={true}
                              setOpenPreview={handlePreview}
                              title="その他"
                              name="K"
                              options={[
                                {
                                  personId:
                                    formik.values.link?.p_application_header
                                      .p_applicant_people_attributes[0].id,
                                  headerId:
                                    formik.values.link?.p_application_header.id,
                                  trackable_type: 'PApplicantPerson',
                                  isHasLog:
                                    formik.values.link?.p_application_header.p_applicant_people_attributes[0].has_log_fields?.includes(
                                      'other_document_file'
                                    ),
                                  jp_name: '',
                                  en_name: 'other_document_file',
                                  files:
                                    formik.values.filesLink.other_document_file,
                                  isRequired: false,
                                },
                              ]}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </TabPanels>
                  )}
                  <TabPanels
                    value={resultIndexB}
                    sx={{
                      pt: 1,
                      my: '50px',
                    }}
                  >
                    {/* for 申込人（B） */}
                    {selectSubTab === resultIndexB && (
                      <>
                        {examinationUrlForB ? (
                          <AdminPreviewPDF
                            src={examinationUrlForB}
                            filename={examinationFileNameForB}
                            isSalePerson={true}
                            id={formik.values.link?.p_application_header.id}
                          />
                        ) : (
                          <AdminPreviewPDF
                            src={examinationUrlForB}
                            filename={examinationFileNameForB}
                            isSalePerson={true}
                            isExaminationEmpty
                          />
                        )}
                      </>
                    )}
                  </TabPanels>
                </TabWrapper>
              </TabPanel>
              <TabPanel
                value="3"
                sx={{ boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)', p: 0 }}
              >
                <TabWrapper
                  value={selectIncomeSubTab}
                  onChange={handleChangeIncomeSubTab}
                >
                  <TabsList>
                    <TabChildren
                      disabled={
                        !formik.values.p_application_header
                          .p_applicant_people_attributes[1]?.id
                      }
                    >
                      収入合算者の情報
                    </TabChildren>
                    <TabChildren
                      disabled={
                        !formik.values.p_application_header
                          .p_applicant_people_attributes[1]?.id
                      }
                    >
                      収入合算者のご職業
                    </TabChildren>
                    {!!initialValues.p_application_header.edit_file_upload && (
                      <TabChildren
                        disabled={
                          !formik.values.p_application_header
                            .p_applicant_people_attributes[1]?.id
                        }
                      >
                        書類アップロード
                      </TabChildren>
                    )}
                  </TabsList>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    sx={{ my: 0 }}
                  >
                    {editable &&
                      !(
                        selectTab === '2' &&
                        preliminaries.p_application_header.linking_id
                      ) &&
                      !showExaminationResultForA &&
                      !showExaminationResultForB && (
                        <Button
                          disabled={
                            (selectTab === '1' && isDisableButtonSubmit) ||
                            (selectTab === '3' &&
                              isDisableButtonSubmitForIncome) ||
                            isDisableSaveButton
                          }
                          sx={{
                            mt: '30px',
                            mb: 5,
                            width: 204,
                            minHeight: 35,
                            bgcolor: 'sp_secondary_01',
                          }}
                          onClick={() => {
                            if (isTabUploadFiles || isTabUploadFilesIncome) {
                              // create check list image
                              const imageLists: Array<File | SPImage> = [];

                              // get image form header
                              Object.keys(formik.values.filesHeader).forEach(
                                (key) => {
                                  const paramsHeaderImages = get(
                                    formik.values.filesHeader,
                                    key,
                                    []
                                  );
                                  paramsHeaderImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form borrowing
                              Object.keys(formik.values.filesBorrowing).forEach(
                                (key) => {
                                  const paramsBorrowImages = get(
                                    formik.values.filesBorrowing,
                                    key,
                                    []
                                  );
                                  paramsBorrowImages.forEach(
                                    (file: File | SPImage) => {
                                      const [keyValue, index] =
                                        key.split('_index__');
                                      const borrowingId =
                                        preliminaries.p_application_header
                                          .p_borrowings_attributes[
                                          Number(index)
                                        ]?.id;
                                      if (!!borrowingId) imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 1
                              Object.keys(formik.values.files).forEach(
                                (key) => {
                                  const paramsImages = get(
                                    formik.values.files,
                                    key,
                                    []
                                  );
                                  paramsImages.forEach(
                                    (file: File | SPImage) => {
                                      imageLists.push(file);
                                    }
                                  );
                                }
                              );
                              // get image form people 2
                              Object.keys(
                                formik.values.filesIncomeAdder
                              ).forEach((key) => {
                                const paramsIncomeImages = get(
                                  formik.values.filesIncomeAdder,
                                  key,
                                  []
                                );
                                paramsIncomeImages.forEach(
                                  (file: File | SPImage) => {
                                    imageLists.push(file);
                                  }
                                );
                              });
                              const promises = imageLists.map((image) => {
                                const type =
                                  image instanceof File
                                    ? image.type
                                    : getFileExtFromUrl(image.url);
                                if (type.includes('pdf')) {
                                  if (image instanceof File) {
                                    return checkIfPDFExists(
                                      URL.createObjectURL(image)
                                    );
                                  } else {
                                    return checkIfPDFExists(image.url);
                                  }
                                } else {
                                  if (image instanceof File) {
                                    return checkIfImageExists(image);
                                  } else {
                                    return checkIfImageExists(image.url);
                                  }
                                }
                              });

                              Promise.all(promises).then((result) => {
                                if (!result.every((item) => item)) {
                                  modalErrorRef.current?.open(
                                    '',
                                    FILE_NOT_EXIST,
                                    true
                                  );
                                  return;
                                } else {
                                  handleSubmitEditUploadedFiles(
                                    formik.values.files,
                                    formik.values.filesIncomeAdder,
                                    formik.values.filesHeader,
                                    formik.values.filesBorrowing,
                                    formik.values.p_application_header
                                      .p_applicant_people_attributes
                                  );
                                }
                              });
                            } else {
                              formik.handleSubmit();
                            }
                          }}
                        >
                          保存
                        </Button>
                      )}
                  </Stack>
                  <TabPanels value={0} sx={{ width: '100%', pb: 5 }}>
                    <AdminPreliminaryTable
                      parentTabIndex={selectTab}
                      appraisalId={preliminaryId}
                      editable={editable}
                      rows={buildIncomeTotalizerSalePerson(
                        get(formik.values, 'p_application_header')
                      )}
                      parentKey="p_application_header"
                      isTotalIncome
                    />
                  </TabPanels>
                  {formik.values.p_application_header
                    .p_applicant_people_attributes.length === 2 && (
                    <TabPanels value={1} sx={{ width: '100%', pb: 5 }}>
                      <AdminPreliminaryTable
                        parentTabIndex={selectTab}
                        appraisalId={preliminaryId}
                        rows={getPersonalOccupation('p_application_header', 1)}
                        editable={editable}
                        parentKey="p_application_header"
                        isTotalIncome
                      />
                    </TabPanels>
                  )}
                  {formik.values.p_application_header
                    .p_applicant_people_attributes.length === 2 &&
                    initialValues.p_application_header.edit_file_upload && (
                      <TabPanels
                        value={2}
                        sx={{
                          backgroundColor: 'main_white',
                          overflowY: 'scroll',
                          height: 'calc(100vh - 340px)',
                        }}
                      >
                        <Stack direction="row" spacing={4} sx={{ pb: '65px' }}>
                          {/* 収入合算者 */}
                          <Stack
                            flex={1}
                            spacing={4}
                            sx={{
                              width: (theme) =>
                                `calc(50% - ${theme.spacing(2)})`,
                            }}
                          >
                            {/* A */}
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={false}
                                isDisplay={true}
                                title="本人確認書類"
                                name="A"
                                setOpenPreview={handlePreview}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'driver_license_front_image'
                                      ),
                                    jp_name: `運転免許書`,
                                    en_name: 'driver_license_front_image',
                                    isRequired: isRequiredUploadFile(
                                      'driver_license_front_image'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .driver_license_front_image,
                                  },
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'driver_license_back_image'
                                      ),
                                    jp_name: '運転免許書',
                                    en_name: 'driver_license_back_image',
                                    files:
                                      formik.values.filesIncomeAdder
                                        .driver_license_back_image,
                                    isRequired: isRequiredUploadFile(
                                      'driver_license_back_image'
                                    ),
                                  },
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'card_number_front_image'
                                      ),
                                    jp_name: `マイナンバーカード`,
                                    en_name: 'card_number_front_image',
                                    isRequired: isRequiredUploadFile(
                                      'card_number_front_image'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .card_number_front_image,
                                  },
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'resident_register_front_image'
                                      ),
                                    jp_name: `住民基本台帳カード`,
                                    en_name: 'resident_register_front_image',
                                    isRequired: isRequiredUploadFile(
                                      'resident_register_front_image'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .resident_register_front_image,
                                  },
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'resident_register_back_image'
                                      ),
                                    jp_name: '住民基本台帳カード',
                                    en_name: 'resident_register_back_image',
                                    isRequired: isRequiredUploadFile(
                                      'resident_register_back_image'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .resident_register_back_image,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                filesUploadOption={filesUploadOption}
                                handleRadioChange={(option) => {
                                  formik.setFieldValue('isChangeFiles', true);
                                  setFilesUploadOption(option);
                                }}
                                onChange={handleChangeFilesIncomeAdder}
                              />
                            </Stack>
                            {/* B */}
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={false}
                                isDisplay={true}
                                title="健康保険証"
                                name="B"
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'insurance_file'
                                      ),
                                    jp_name: '健康保険証〈表面〉',
                                    en_name: 'insurance_file',
                                    isRequired:
                                      isRequiredUploadFile('insurance_file'),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .insurance_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={false}
                                isDisplay={true}
                                title=""
                                name=""
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'insurance_file_back_image'
                                      ),
                                    jp_name: '健康保険証〈裏面〉',
                                    en_name: 'insurance_file_back_image',
                                    isRequired: isRequiredUploadFile(
                                      'insurance_file_back_image'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .insurance_file_back_image,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                            </Stack>
                            {/* C */}
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                //isFirstWithholdingSlipFile()
                                isDisplay={true}
                                title="収入に関する書類"
                                name="C"
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'first_withholding_slip_file'
                                      ),
                                    jp_name: '源泉徴収票（前年度分）',
                                    en_name: 'first_withholding_slip_file',
                                    isRequired: isRequiredUploadFile(
                                      'first_withholding_slip_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .first_withholding_slip_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                // title={
                                //   isFirstWithholdingSlipFile()
                                //     ? ''
                                //     : '収入に関する書類'
                                // }
                                // name={isFirstWithholdingSlipFile() ? '' : 'C'}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'second_withholding_slip_file'
                                      ),
                                    jp_name: '源泉徴収票（前々年度分）',
                                    en_name: 'second_withholding_slip_file',
                                    isRequired: isRequiredUploadFile(
                                      'second_withholding_slip_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .second_withholding_slip_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'first_income_file'
                                      ),
                                    jp_name: '確定申告書（1期前）',
                                    en_name: 'first_income_file',
                                    isRequired:
                                      isRequiredUploadFile('first_income_file'),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .first_income_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'second_income_file'
                                      ),
                                    jp_name: '確定申告書（2期前）',
                                    en_name: 'second_income_file',
                                    isRequired:
                                      isRequiredUploadFile(
                                        'second_income_file'
                                      ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .second_income_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'third_income_file'
                                      ),
                                    jp_name: '確定申告書（3期前）',
                                    en_name: 'third_income_file',
                                    isRequired:
                                      isRequiredUploadFile('third_income_file'),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .third_income_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                            </Stack>
                            {/* D */}
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                title="非上場企業の役員の方の書類"
                                name="D"
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'financial_statement_1_file'
                                      ),
                                    jp_name: '会社の決算報告書（1期前）',
                                    en_name: 'financial_statement_1_file',
                                    isRequired: isRequiredUploadFile(
                                      'financial_statement_1_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .financial_statement_1_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'financial_statement_2_file'
                                      ),
                                    jp_name: '会社の決算報告書（2期前）',
                                    en_name: 'financial_statement_2_file',
                                    isRequired: isRequiredUploadFile(
                                      'financial_statement_2_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .financial_statement_2_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'financial_statement_3_file'
                                      ),
                                    jp_name: '会社の決算報告書（3期前）',
                                    en_name: 'financial_statement_3_file',
                                    isRequired: isRequiredUploadFile(
                                      'financial_statement_3_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .financial_statement_3_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                            </Stack>
                            {/* E */}
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                title="雇用契約に関する書類"
                                name="E"
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'employment_agreement_file'
                                      ),
                                    jp_name: '雇用契約書',
                                    en_name: 'employment_agreement_file',
                                    isRequired: isRequiredUploadFile(
                                      'employment_agreement_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .employment_agreement_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                            </Stack>
                          </Stack>
                          <Stack
                            flex={1}
                            spacing={4}
                            sx={{
                              width: (theme) =>
                                `calc(50% - ${theme.spacing(2)})`,
                            }}
                          >
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                title="親族経営の会社等にご勤務の方に関する書類"
                                name="F"
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'business_tax_return_1_file'
                                      ),
                                    jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（1期前）`,
                                    en_name: 'business_tax_return_1_file',
                                    isRequired: isRequiredUploadFile(
                                      'business_tax_return_1_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .business_tax_return_1_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'business_tax_return_2_file'
                                      ),
                                    jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（2期前）`,
                                    en_name: 'business_tax_return_2_file',
                                    isRequired: isRequiredUploadFile(
                                      'business_tax_return_2_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .business_tax_return_2_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'business_tax_return_3_file'
                                      ),
                                    jp_name: `会社の決算報告書\n
                                または経営する親族の確定申告書（3期前）`,
                                    en_name: 'business_tax_return_3_file',
                                    isRequired: isRequiredUploadFile(
                                      'business_tax_return_3_file'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .business_tax_return_3_file,
                                  },
                                ]}
                                isLoadingFiles={isLoadingFiles}
                                onChange={handleChangeFilesIncomeAdder}
                                setOpenPreview={handlePreview}
                              />
                            </Stack>
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                setOpenPreview={handlePreview}
                                title="在留カード"
                                name="H"
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'residence_file'
                                      ),
                                    jp_name: '在留カード 〈表面〉',
                                    en_name: 'residence_file',
                                    isRequired:
                                      isRequiredUploadFile('residence_file'),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .residence_file,
                                  },
                                ]}
                                onChange={handleChangeFilesIncomeAdder}
                              />
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                setOpenPreview={handlePreview}
                                title=""
                                name=""
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'residence_file_back_image'
                                      ),
                                    jp_name: '在留カード 〈裏面〉',
                                    en_name: 'residence_file_back_image',
                                    isRequired: isRequiredUploadFile(
                                      'residence_file_back_image'
                                    ),
                                    files:
                                      formik.values.filesIncomeAdder
                                        .residence_file_back_image,
                                  },
                                ]}
                                onChange={handleChangeFilesIncomeAdder}
                              />
                            </Stack>
                            <Stack
                              sx={{
                                border: (theme) =>
                                  `1px solid ${theme?.palette?.bg_gray}`,
                              }}
                            >
                              <DocumentUploadItem
                                isSalePerson
                                editable={editable}
                                multiple={true}
                                isDisplay={true}
                                setOpenPreview={handlePreview}
                                title="その他"
                                name="K"
                                options={[
                                  {
                                    personId:
                                      formik.values.p_application_header
                                        .p_applicant_people_attributes[1].id,
                                    headerId:
                                      formik.values.p_application_header.id,
                                    trackable_type: 'PApplicantPerson',
                                    isHasLog:
                                      formik.values.p_application_header.p_applicant_people_attributes[1].has_log_fields?.includes(
                                        'other_document_file'
                                      ),
                                    jp_name: '',
                                    en_name: 'other_document_file',
                                    files:
                                      formik.values.filesIncomeAdder
                                        .other_document_file,
                                    isRequired: isRequiredUploadFile(
                                      'other_document_file'
                                    ),
                                  },
                                ]}
                                onChange={handleChangeFilesIncomeAdder}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </TabPanels>
                    )}
                </TabWrapper>
              </TabPanel>
            </TabContext>
            {showExaminationResultForA && (
              <ExaminationResult
                resultStatus={resultStatusSelector}
                isActiveButton={disableBtnPDF}
              />
            )}
            {showExaminationResultForB && formik.values.link && (
              <ExaminationResult
                resultStatus={
                  formik.values.link?.p_application_header.public_status
                }
                isActiveButton={disableBtnPDF}
              />
            )}
            <ModalChangeValue
              open={changeModal}
              message={messageChangeModal}
              onClose={changeModalOnClose}
            />
          </Box>
        </FormikProvider>
        <Loading
          open={
            (isTabUploadFiles || isTabUploadFilesIncome) && isDisableSaveButton
          }
        />
      </Stack>
    </AdminLayout>
  );
};

const styleMuiTabs = {
  fontFamily: 'Hiragino Sans',
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '24px',
  letterSpacing: 0.1,
  minWidth: 160,
};

const TabWrapper = styled(TabsUnstyled)`
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
`;

const TabChildren = styled(TabUnstyled)`
  font-family: Hiragino Sans;
  color: #6b70f0;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
  background-color: #fff;
  padding: 10px 12px;
  margin-bottom: 6px;
  margin-top: 6px;
  margin-left: 6px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  &.${tabUnstyledClasses.selected} {
    background: #6b70f0;
    color: white;
  }

  &.${tabUnstyledClasses} {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanels = styled(TabPanelUnstyled)`
  width: 100%;
  //height: '500px';
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
  () => `
  min-width: 400px;
  border-radius: 2px;
  margin-bottom: 16px;
  align-content: space-between;
  background-color: #EFEFEF;
  `
);

const styleMuiTabSelected = {
  color: 'b_333',
  fontFamily: 'Hiragino Sans',
  fontWeight: 500,
  fontSize: 13,
  border: 'none',
  lineHeight: '24px',
  letterSpacing: 0.1,
  borderBottom: '3px solid #6B70F0',
  borderColor: '#6B70F0',
};

export default SalePersonEditPreliminary;
