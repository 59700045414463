import { FC, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Button, Icons, InputField } from 'components';
import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'navigations/routes';
import { toast } from 'react-toastify';
import { validationSchema } from './validationSchema';
import { signUp } from './thunk';

const SignUpPage: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema,
    async onSubmit(user) {
      const result = await dispatch(signUp({ user }));
      if (signUp.fulfilled.match(result)) {
        navigate(routeNames.NotePage.path);
      } else {
        toast.error('エラーが発生しました。');
      }
    },
  });
  return (
    <FormikProvider value={formik}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Stack
          sx={{
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            bgcolor: 'main_white',
            borderRadius: '5px',
            width: '100%',
            mx: 10,
          }}
        >
          <Stack alignItems="flex-end" sx={{ mr: '41px', mt: '27px' }}>
            <Icons.LogoNew />
          </Stack>
          <Stack textAlign="center" sx={{ mt: { mobile: 5, tablet: 0 } }}>
            <Typography variant="text_input_code">新規登録</Typography>
          </Stack>
          <Stack
            sx={{
              p: '30px 68px 56px 72px',
              '@media (orientation: portrait)': {
                p: '30px 20px 56px 20px',
              },
              justifyContent: 'center',
            }}
            direction={{ mobile: 'column', tablet: 'row' }}
          >
            <Stack
              sx={{
                mt: { mobile: 5, tablet: 0 },
                justifyContent: 'center',
              }}
            >
              <Stack>
                <Typography variant="textstyle" lineHeight="24px">
                  メールアドレス
                </Typography>
              </Stack>
              <Stack sx={{ mt: '9px', maxWidth: 350 }}>
                <InputField
                  name="email"
                  type="email"
                  InputProps={{
                    sx: {
                      '&& .MuiInputBase-input': { borderRadius: '5px' },
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: '1px',
                          borderColor: 'black',
                        },
                    },
                  }}
                />
              </Stack>
              <Stack sx={{ mt: '11px' }}>
                <Typography variant="textstyle" lineHeight="24px">
                  パスワード
                </Typography>
              </Stack>
              <Stack sx={{ mt: '10px', position: 'relative', maxWidth: 350 }}>
                <InputField
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    sx: {
                      '&& .MuiInputBase-input': { borderRadius: '5px' },
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: '1px',
                          borderColor: 'black',
                        },
                    },
                  }}
                  showPassword={showPassword}
                />
                <Icons.CheckPassword
                  sx={{
                    position: 'absolute',
                    right: 19,
                    top: { mobile: 7, tablet: 13 },
                    color: showPassword ? 'Icon_color' : 'main_white',
                  }}
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              </Stack>
              <Stack sx={{ mt: '11px' }}>
                <Typography variant="textstyle" lineHeight="24px">
                  パスワード（確認用）
                </Typography>
              </Stack>
              <Stack sx={{ mt: '10px', position: 'relative', maxWidth: 350 }}>
                <InputField
                  name="password_confirmation"
                  type={showPasswordConfirm ? 'text' : 'password'}
                  InputProps={{
                    sx: {
                      '&& .MuiInputBase-input': { borderRadius: '5px' },
                      '&&&&&&&&.Mui-error fieldset,&&&.MuiInputBase-root fieldset':
                        {
                          borderWidth: '1px',
                          borderColor: 'black',
                        },
                    },
                  }}
                  showPassword={showPasswordConfirm}
                />
                <Icons.CheckPassword
                  sx={{
                    position: 'absolute',
                    right: 19,
                    top: { mobile: 7, tablet: 13 },
                    color: showPasswordConfirm ? 'Icon_color' : 'main_white',
                  }}
                  onClick={() =>
                    setShowPasswordConfirm((prevState) => !prevState)
                  }
                />
              </Stack>
              <Stack sx={{ mt: '42px', maxWidth: 350 }}>
                <Button
                  disabled={formik.isSubmitting}
                  sx={{ bgcolor: 'sh_red' }}
                  onClick={() => formik.handleSubmit()}
                >
                  <Typography variant="textButtonTopPage" color="main_white">
                    次へ
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </FormikProvider>
  );
};

export default SignUpPage;
