import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'containers/AuthModal/thunk';

export type Preliminary = {
  id: string;
  applicant_id: string;
  applicant_name: string;
  application_number: string;
  bank_name: string[];
  store_name_kanji: string;
  created_at: string;
  master_bank_codes: string[];
  temporary_desired_loan_amount: string;
};

export interface InitialState {
  currentPreliminary: Preliminary;
}

const defaultPreliminary = {
  id: '',
  applicant_id: '',
  applicant_name: '',
  application_number: '',
  bank_name: [''],
  store_name_kanji: '',
  created_at: '',
  master_bank_codes: [''],
  temporary_desired_loan_amount: '',
};

const initialState: InitialState = {
  currentPreliminary: defaultPreliminary,
};

export const preliminarySlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setPremilinary: (state, action: PayloadAction<Preliminary>) => {
      state.currentPreliminary = action.payload;
    },
    unsetPremilinary: (state) => {
      state.currentPreliminary = defaultPreliminary;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.currentPreliminary = defaultPreliminary;
    });
  },
});

export const { setPremilinary, unsetPremilinary } = preliminarySlice.actions;

export default preliminarySlice.reducer;
