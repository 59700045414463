import { RootState } from 'types';
import { PreliminariesStatus } from 'constant';

export const loadingSelector = (state: RootState) =>
  state.managerPreliminaries.fetching;

export const currentPreliminariesSelectors = (state: RootState) =>
  state.managerPreliminaries.currentPreliminaries;

export const selectedPreliminarySelectors = (state: RootState) =>
  state.managerPreliminaries.selected;

export const masterDataSelectors = (state: RootState) =>
  state.managerPreliminaries.masterData;

export const totalPreliminarySelectors = (state: RootState) =>
  state.managerPreliminaries.total;

export const activeTabSelector = (state: RootState) =>
  state.managerPreliminaries.activeTab || PreliminariesStatus.CURRENT;
