import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditField: FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="-3 -9 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="14"
      height="3"
      viewBox="0 0 14 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0.192C1.316 0.192 0.752 0.756 0.752 1.44C0.752 2.124 1.316 2.688 2 2.688C2.684 2.688 3.248 2.124 3.248 1.44C3.248 0.744 2.696 0.192 2 0.192ZM7 0.192C6.316 0.192 5.752 0.756 5.752 1.44C5.752 2.124 6.316 2.688 7 2.688C7.684 2.688 8.248 2.124 8.248 1.44C8.248 0.744 7.696 0.192 7 0.192ZM12 0.192C11.316 0.192 10.752 0.756 10.752 1.44C10.752 2.124 11.316 2.688 12 2.688C12.684 2.688 13.248 2.124 13.248 1.44C13.248 0.744 12.696 0.192 12 0.192Z"
        fill={props.fill}
      />
    </svg>
  </SvgIcon>
);
