import { CODEBANK, MESSAGE_REGEX } from 'constant';
// @ts-ignore
import { Context } from 'pages/StepSynthesisInformation/validationSchema';
import * as yup from 'yup';
import locale from 'yup/lib/locale';
import { AnyObject } from 'yup/es/types';

const regex = {
  kanjiFullwidth:
    /^[ぁ-んァ-ー一-龥ａ-ｚ\s　Ａ-ＺⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ¬¦＇＂㈱№℡∵纊褜鍈銈蓜俉炻昱棈鋹曻彅丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏塚增墲夋奓奛奝奣妤妺孖寀甯寘寬尞岦岺峵崧嵓﨑嵂嵭嶸嶹巐弡弴彧德忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻桒柀栁桄棏﨓楨﨔榘槢樰橫橆橳橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵濵瀅瀇瀨炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珉珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠緖繒罇羡羽茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞都鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒隆﨩隝隯霳霻靃靍靏靑靕顗顥飯飼餧館馞驎髙髜魵魲鮏鮱鮻鰀鵰鵫鶴鸙黑ヶ]+$/,
  kanjiFullwidthHaveNumber:
    /^[ぁ-んァ-ー一-龥ａ-ｚ\s　Ａ-Ｚ\s　０-９\s　\ー\－・− \s　\-ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ¬¦＇＂㈱№℡∵纊褜鍈銈蓜俉炻昱棈鋹曻彅丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏塚增墲夋奓奛奝奣妤妺孖寀甯寘寬尞岦岺峵崧嵓﨑嵂嵭嶸嶹巐弡弴彧德忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻桒柀栁桄棏﨓楨﨔榘槢樰橫橆橳橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵濵瀅瀇瀨炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珉珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠緖繒罇羡羽茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞都鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒隆﨩隝隯霳霻靃靍靏靑靕顗顥飯飼餧館馞驎髙髜魵魲鮏鮱鮻鰀鵰鵫鶴鸙黑ヶ]+$/,
  kanaHalfwidthHaveSpace: /^[ァ-ー\s　]+$/,
  kanaHalfwidthHaveNumber: /^[ァ-ーａ-ｚ\s　Ａ-Ｚ\s　０-９ \s　]+$/,
  kanaAlphabet: /^[ァ-ン ﾞ－・ーa-zA-Z0-9０-９]+$/,
  secondJapanName: /^[ぁ-んァ-ン一-龥]+$/,
  japanName:
    /^[ぁ-んァ-ン一-龥a-zA-Zａ-ｚＡ-ＺⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ¬¦＇＂㈱№℡∵纊褜鍈銈蓜俉炻昱棈鋹曻彅丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏塚增墲夋奓奛奝奣妤妺孖寀甯寘寬尞岦岺峵崧嵓﨑嵂嵭嶸嶹巐弡弴彧德忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻桒柀栁桄棏﨓楨﨔榘槢樰橫橆橳橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵濵瀅瀇瀨炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珉珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠緖繒罇羡羽茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞都鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒隆﨩隝隯霳霻靃靍靏靑靕顗顥飯飼餧館馞驎髙髜魵魲鮏鮱鮻鰀鵰鵫鶴鸙黑]+$/,
  mobilePhone: /(090|080|070)-\d{4}-\d{4}/,
  freePhones: /^[0-9０-９()\-\s]+$/,
  fixedPhones:
    /^(((\d{2}-\d{4})|(\d{3}-\d{3})|(\d{4}-\d{2})|(\d{5}-\d{1}))-\d{4})|(\d{4}-\d{3}-\d{3})$/,
  emergencyPhone: /^(090|080|070|0\d{1})-\d{4}-\d{4}$/,
  email:
    /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/,
  password:
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9!"#$%&'()\-^@;:,.\/=~|{+*}<>?_]/,
  zipCode: /^\d{3}[-]\d{4}$/,
  url: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
};

function noWhitespace(this: yup.StringSchema) {
  return this.test(
    'no-whiteSpace',
    '正しくご入力ください。',
    (value: string | undefined) => !value?.includes(' ')
  );
}

function requiredMCJ(this: yup.StringSchema, msg?: string) {
  return this.test({
    message: msg ?? MESSAGE_REGEX.REQUIRED,
    test(this: Context, value) {
      const { from } = this;

      return from &&
        from[from.length - 1].value.master_bank_codes?.includes(CODEBANK.MCJ)
        ? !!value
        : true;
    },
  });
}

function requiredNumberMCJ(this: yup.NumberSchema) {
  return this.test({
    message: MESSAGE_REGEX.REQUIRED,
    test(this: Context, value) {
      const { from } = this;

      return from &&
        from[from.length - 1].value.master_bank_codes?.includes(CODEBANK.MCJ)
        ? !!value
        : true;
    },
  });
}

function requiredArrayMCJ(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  this: yup.ArraySchema<any, any, any, any>,
  msg?: string
) {
  return this.test({
    message: msg ?? MESSAGE_REGEX.REQUIRED,
    test(this: Context, value) {
      const { from } = this;

      return from &&
        from[from.length - 1].value.master_bank_codes?.includes(CODEBANK.MCJ)
        ? !!value.length
        : true;
    },
  });
}

function noZeroPhoneNumber(this: yup.StringSchema) {
  return this.test({
    message: MESSAGE_REGEX.NO_ALL_ZEROS_PHONE_NUMBER,
    test(this: Context, value) {
      if (!value) return true;
      const allZerosPhoneNumber = [
        '00-0000-0000',
        '000-000-0000',
        '000-0000-0000',
        '0000-00-0000',
        '0000-000-000',
      ];

      return !allZerosPhoneNumber.includes(value);
    },
  });
}

yup.setLocale({
  mixed: {
    ...locale.mixed,
    required: MESSAGE_REGEX.REQUIRED,
  },
  string: {
    ...locale.string,
    max: '${max}文字以内で入力してください。',
    min: '${min}文字でご入力ください。',
    matches: '正しくご入力ください。',
  },
  number: {
    ...locale.number,
    max: '${max}万円以下ご入力ください。',
    min: '${min}より大きい数値で入力ください。',
    integer: '正しくご入力ください。',
  },
});

function validArrayByIndex(index: number, schema: yup.ObjectSchema<{}>) {
  //@ts-ignore
  return this.test({
    async test(values: unknown[], context: AnyObject) {
      for (let i = 0; i < values.length; i++) {
        if (i === index) {
          try {
            return await schema.validate(values[i], {
              abortEarly: false,
              context,
            });
          } catch (e) {
            console.warn('validate_error:', e);
            const error = e as yup.ValidationError;
            return new yup.ValidationError(
              error?.inner?.map((err) =>
                this.createError({
                  path: `${this.path}.${i}.${err.path}`,
                  message: err.message,
                })
              )
            );
          }
        }
      }
      return true;
    },
  });
}

yup.addMethod(yup.string, 'noWhitespace', noWhitespace);
yup.addMethod(yup.string, 'requiredMCJ', requiredMCJ);
yup.addMethod(yup.number, 'requiredNumberMCJ', requiredNumberMCJ);
yup.addMethod(yup.array, 'requiredArrayMCJ', requiredArrayMCJ);
yup.addMethod(yup.array, 'validArrayByIndex', validArrayByIndex);
yup.addMethod(yup.string, 'noZeroPhoneNumber', noZeroPhoneNumber);
export { yup, regex };
