import { yup } from 'libs';
import { LoanBalanceType, LoanType, NationalityType } from 'constant';

export const validationSchema = yup.object().shape({
  loan_type: yup.string(),
  main_personal_information_file: yup.array().min(1).required(),
  main_first_pamphlet_file: yup.array().min(1).required(),
  main_insurance_file: yup.array().min(1).required(),
  main_first_income_file: yup.array().min(1).required(),
  main_second_income_file: yup.array().min(1).required(),
  main_third_income_file: yup.array().min(1).required(),
  main_residence_file: yup.array().when('main_p_applicant_person.nationality', {
    is: (nationality: NationalityType) =>
      nationality === NationalityType.FOREIGN_NATIONALITY,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  main_first_borrowing_file: yup
    .array()
    .when('main_p_applicant_person.has_borrowings', {
      is: (has_borrowings: LoanBalanceType) =>
        has_borrowings === LoanBalanceType.YES,
      then: yup.array().min(1).required(),
      otherwise: yup.array(),
    }),
  main_second_borrowing_file: yup
    .array()
    .when('main_p_applicant_person.has_borrowings', {
      is: (has_borrowings: LoanBalanceType) =>
        has_borrowings === LoanBalanceType.YES,
      then: yup.array().min(1).required(),
      otherwise: yup.array(),
    }),
  pair_personal_information_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_first_pamphlet_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_insurance_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_first_income_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_second_income_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_third_income_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_residence_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_first_borrowing_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
  pair_second_borrowing_file: yup.array().when('loan_type', {
    is: (loan_type: LoanType) => loan_type === LoanType.TWO,
    then: yup.array().min(1).required(),
    otherwise: yup.array(),
  }),
});
