import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  authService,
  SignUpError,
  SignUpRequest,
  SignUpResponse,
} from 'services';
import { ThunkAPI } from 'types';

export const signUp = createAsyncThunk<
  SignUpResponse,
  SignUpRequest,
  ThunkAPI<SignUpError>
>('authModal/signUp', async (user, { rejectWithValue }) => {
  try {
    return await authService.signUp(user);
  } catch (error) {
    return rejectWithValue(error);
  }
});
