import { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { AuthPopover, Icons } from 'components';
import xor from 'lodash/xor';
import { QuantityDependent } from 'types';
import { ID_BANK, PlannedCohabitantType, RelationshipType } from 'constant';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { AdminPreliminaries } from 'services';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import isArray from 'lodash/isArray';
import { PulldownBtn } from '../PulldownBtn';
import { plannedCohabitantSelectors } from '../../../SPStartScreen/selectors';
import {
  getHousemateNumber,
  removeResidentsAttributes,
  addResidentsAttributes,
  makeArrayFromNumber,
} from '../../utils';
import { ConfirmEditModal } from '../ConfirmEditModal';

export type PulldownSelectMultipleProps = {
  values: (string | number)[];
  dynamicField: string;
  quantityDependent?: QuantityDependent;
  options: { option_code: number; option_name: string }[];
  onChange: (values: unknown, path?: string) => void;
  onChangeCount: (value: string, path: string) => void;
};

export const PulldownSelectMultiple: FC<PulldownSelectMultipleProps> = ({
  values,
  dynamicField,
  options,
  quantityDependent,
  onChange,
  onChangeCount,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmEdit, setOpenConfirmEdit] = useState<boolean>(false);
  const formik = useFormikContext<AdminPreliminaries>();
  const plannedCohabitantDynamic = useSelector(plannedCohabitantSelectors);

  const plannedCohabitant = useCallback(
    (item: RelationshipType) => {
      return (
        dynamicField === 'planned_cohabitant' &&
        [
          RelationshipType.SIBLINGS,
          RelationshipType.CHILD,
          RelationshipType.OTHERS,
        ].includes(item)
      );
    },
    [dynamicField]
  );

  const renderDependent = useCallback(
    (item: RelationshipType) => {
      switch (item.toString()) {
        case RelationshipType.SIBLINGS:
          return quantityDependent?.siblings_number || '0';
        case RelationshipType.CHILD:
          return quantityDependent?.children_number || '0';
        case RelationshipType.OTHERS:
          return quantityDependent?.other_people_number || '0';
      }
    },
    [quantityDependent]
  );

  const handleChangeCount = useCallback(
    (event: ChangeEvent<unknown>, optionCode: number, step: number) => {
      event.stopPropagation();
      let count;
      let path;

      if (optionCode.toString() === RelationshipType.SIBLINGS) {
        count = quantityDependent?.siblings_number;
        path = 'siblings_number';
      }

      if (optionCode.toString() === RelationshipType.CHILD) {
        count = quantityDependent?.children_number;
        path = 'children_number';
      }

      if (optionCode.toString() === RelationshipType.OTHERS) {
        count = quantityDependent?.other_people_number;
        path = 'other_people_number';
      }
      if (isNaN(Number(count)) || !path || Number(count) + step < 1) {
        return;
      }
      if (step === 1) {
        if (!isNaN(Number(count)) && Number(count) >= 9) {
          return;
        }
      }

      onChangeCount((Number(count) + step).toString(), path);
      if (!values.includes(optionCode.toString())) {
        return;
      }
      const housemateNumber = getHousemateNumber(plannedCohabitantDynamic, {
        ...formik.values.p_application_header,
        [path]: Number(count) + step,
      });
      const plannedCohabitants =
        step > 0
          ? addResidentsAttributes(
              formik.values.p_application_header.p_residents_attributes,
              optionCode.toString() as RelationshipType
            )
          : removeResidentsAttributes(
              formik.values.p_application_header.p_residents_attributes,
              [optionCode.toString() as RelationshipType],
              housemateNumber
            );
      onChange(plannedCohabitants, 'p_residents_attributes');
      onChange(housemateNumber.toString(), 'housemate_number');
    },
    [
      onChangeCount,
      values,
      formik.values.p_application_header,
      plannedCohabitantDynamic,
      onChange,
      quantityDependent?.siblings_number,
      quantityDependent?.children_number,
      quantityDependent?.other_people_number,
    ]
  );

  const handleOnChange = useCallback(
    (optionCode: number | string) => {
      if (
        dynamicField === 'master_bank_ids' &&
        optionCode.toString() === ID_BANK.SBI &&
        values.includes(+ID_BANK.SBI)
      ) {
        return;
      }
      let newValues;
      // check field business_ability CR
      // if (dynamicField === 'business_ability') {
      //   if (
      //     values.includes(LoanTargetPropertyType.RENT) &&
      //     (optionCode === LoanTargetPropertyType.OFFICE_STORE ||
      //       optionCode ===
      //         LoanTargetPropertyType.ELECTRICITY_SALE_FROM_SOLAR_POWER_GENERATION)
      //   ) {
      //     return;
      //   }
      //   if (
      //     (values.includes(LoanTargetPropertyType.OFFICE_STORE) ||
      //       values.includes(
      //         LoanTargetPropertyType.ELECTRICITY_SALE_FROM_SOLAR_POWER_GENERATION
      //       )) &&
      //     optionCode === LoanTargetPropertyType.RENT
      //   ) {
      //     return;
      //   }
      // }
      if (dynamicField === 'planned_cohabitant') {
        newValues = xor(values, [optionCode.toString()]);
        // if (
        //   !newValues.length &&
        //   !window.confirm(
        //     '入居予定者の人数を0に変更すれば、保存された入居予定者の情報が削除されますが、よろしいでしょうか。'
        //   )
        // ) {
        //   return;
        // }
        // const index = values.findIndex((o) => o === optionCode.toString());
        // if (
        //   index >= 0 &&
        //   newValues.length &&
        //   !window.confirm(
        //     '選択肢を外しましたら、登録した入居予定者の明細が削除されますが、よろしいでしょうか。'
        //   )
        // ) {
        //   return;
        // }
        let otherPeopleNumber =
          formik.values.p_application_header.other_people_number;
        let siblingsNumber = formik.values.p_application_header.siblings_number;
        let childrenNumber = formik.values.p_application_header.children_number;
        const checked = newValues.includes(optionCode.toString());

        let permission = [optionCode.toString() as RelationshipType];

        if (optionCode.toString() === PlannedCohabitantType.OTHERS) {
          if (!checked) {
            permission = makeArrayFromNumber(+otherPeopleNumber).map(
              () => RelationshipType.OTHERS
            );
            otherPeopleNumber = '';
          } else if (+otherPeopleNumber === 0 || otherPeopleNumber === '') {
            otherPeopleNumber = 1;
          }
        }
        if (optionCode.toString() === PlannedCohabitantType.CHILD) {
          if (!checked) {
            permission = makeArrayFromNumber(+childrenNumber).map(
              () => RelationshipType.CHILD
            );
            childrenNumber = '';
          } else if (+childrenNumber === 0 || childrenNumber === '') {
            childrenNumber = 1;
          }
        }
        if (
          optionCode.toString() === PlannedCohabitantType.BROTHERS_AND_SISTERS
        ) {
          if (!checked) {
            permission = makeArrayFromNumber(+siblingsNumber).map(
              () => RelationshipType.SIBLINGS
            );
            siblingsNumber = '';
          } else if (+siblingsNumber === 0 || siblingsNumber === '') {
            siblingsNumber = 1;
          }
        }

        const housemateNumber = getHousemateNumber(plannedCohabitantDynamic, {
          ...formik.values.p_application_header,
          other_people_number: otherPeopleNumber,
          siblings_number: siblingsNumber,
          children_number: childrenNumber,
          planned_cohabitant: newValues.map(
            (num) => num.toString() as PlannedCohabitantType
          ),
        });

        const residentsAttributes = checked
          ? addResidentsAttributes(
              formik.values.p_application_header.p_residents_attributes,
              permission[0]
            )
          : removeResidentsAttributes(
              formik.values.p_application_header.p_residents_attributes,
              permission,
              housemateNumber
            );

        onChange(residentsAttributes, 'p_residents_attributes');
        onChange(housemateNumber.toString(), 'housemate_number');
        onChange(otherPeopleNumber, 'other_people_number');
        onChange(siblingsNumber, 'siblings_number');
        onChange(childrenNumber, 'children_number');
      } else {
        newValues = xor(values, [optionCode]);
      }
      onChange(newValues);
    },
    [
      dynamicField,
      formik.values.p_application_header,
      onChange,
      plannedCohabitantDynamic,
      values,
    ]
  );

  const handleOpenPulldown = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <Fragment>
      <AuthPopover
        arrow={false}
        sx={{
          width: 146,
          border: (theme) => `1px solid ${theme?.palette?.line}`,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          overflow: 'hidden',
        }}
        content={
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Stack sx={{ textAlign: 'left', maxHeight: 300, overflow: 'auto' }}>
              {options.map((item, index) => {
                const checked = !!(isArray(values) ? values : [])
                  ?.map((value) => value.toString())
                  ?.includes(item.option_code.toString());
                return (
                  <Stack
                    key={index}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      px: 1,
                      py: 1,
                      borderBottom:
                        options.length - 1 !== index
                          ? '1px solid #DBD9D9'
                          : 'none',
                      cursor: 'pointer',
                      backgroundColor: 'main_white',
                    }}
                    onClick={() => {
                      if (
                        dynamicField === 'type_tax_return' ||
                        dynamicField === 'income_source' ||
                        dynamicField === 'business_ability'
                      ) {
                        handleOnChange(item.option_code.toString());
                      } else {
                        handleOnChange(item.option_code);
                      }
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {checked ? (
                        <Icons.PulldownChecked sx={{ width: 16, height: 16 }} />
                      ) : (
                        <Icons.PulldownCheck sx={{ width: 16, height: 16 }} />
                      )}
                      <Typography
                        variant="text_dashboard"
                        color="#444"
                        sx={{ pl: 2 }}
                      >
                        {item.option_name}
                      </Typography>
                    </Stack>
                    {plannedCohabitant(
                      item.option_code.toString() as RelationshipType
                    ) && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Icons.Minus
                          sx={{
                            width: 11,
                            height: 11,
                            border: '1px solid',
                            borderColor: (theme) => `${theme.palette.gray}`,
                            borderRadius: 1,
                            p: '2px',
                            mr: 1,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (!checked) {
                              return;
                            }
                            // if (
                            //   !window.confirm(
                            //     '選択肢を外しましたら、登録した入居予定者の明細が削除されますが、よろしいでしょうか。'
                            //   )
                            // ) {
                            //   return;
                            // }
                            handleChangeCount(e, item.option_code, -1);
                          }}
                        />
                        {renderDependent(
                          item.option_code.toString() as RelationshipType
                        )}
                        <Icons.Plus
                          sx={{
                            width: 11,
                            height: 11,
                            border: '1px solid',
                            borderColor: (theme) => `${theme.palette.gray}`,
                            borderRadius: 1,
                            p: '2px',
                            ml: 1,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            checked &&
                              handleChangeCount(e, item.option_code, 1);
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                );
              })}
            </Stack>
          </ClickAwayListener>
        }
        open={open}
        placement="bottom-start"
        contentProps={{
          height: 'auto',
          padding: 0,
          color: 'required_chip_label',
        }}
      >
        <Stack direction="row" alignItems="center">
          <PulldownBtn open={open} onClick={handleOpenPulldown} />
        </Stack>
      </AuthPopover>
      <ConfirmEditModal
        open={openConfirmEdit}
        onClose={() => setOpenConfirmEdit(false)}
        handleOk={() => {
          setOpen(true);
          setOpenConfirmEdit(false);
        }}
        handleCancel={() => {
          setOpenConfirmEdit(false);
        }}
      />
    </Fragment>
  );
};
