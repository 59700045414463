import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Icons } from 'components';
import { FC, MouseEvent } from 'react';
import { MemoItem } from 'services';
import { styleScrollBarTable } from 'styles/toolkit';
import { HeadCellProps, OrderType, TableProps } from 'types';
import { MemoItems } from '../MemoItems';

export const MemoTable: FC<TableProps<MemoItem> & { fetching: boolean }> = ({
  onRequestSort,
  order,
  orderBy,
  rows,
  fetching,
}) => {
  const createSortHandler =
    (property: keyof MemoItem) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table aria-label="simple table">
        <TableHead
          sx={{
            display: 'flex',
            minWidth: 1135,
            height: 40,
            bgcolor: 'bg_off',
            '&.MuiButtonBase-root': {
              display: 'none',
            },
          }}
        >
          <TableRow
            sx={{
              display: 'flex',
              flex: 1,
              pr: 0,
              '&.MuiButtonBase-root': {
                display: 'none',
              },
            }}
          >
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{
                  height: 40,
                  p: 0,
                  alignItems: 'center',
                  textAlign: 'center',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  border: 'none',
                  ...headCell.style,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="table_header_text"
                    sx={{ whiteSpace: 'nowrap' }}
                    color="b_333"
                  >
                    {headCell.label}
                  </Typography>
                  {headCell.smallLabel && (
                    <Typography
                      variant="table_small_text"
                      sx={{ whiteSpace: 'nowrap' }}
                      color="b_333"
                    >
                      {headCell.smallLabel}
                    </Typography>
                  )}
                  {headCell !== headCells[headCells.length - 1] && (
                    <SortTable
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : undefined}
                      onClick={createSortHandler(headCell.id)}
                    />
                  )}
                </Stack>
                {headCell !== headCells[headCells.length - 1] && (
                  <Divider
                    sx={{
                      color: 'gray',
                      right: 0,
                      top: '25%',
                      position: 'absolute',
                      height: '50%',
                    }}
                    orientation="vertical"
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <Stack
          sx={{
            bgcolor: 'bg_off',
            marginRight: '5px',
            overflowX: 'auto',
            height: `calc(100vh - 112px)`,
            ...styleScrollBarTable,
          }}
          id="memo-table-body"
        >
          <MemoItems data={rows} />
          {fetching && (
            <Stack alignItems={'center'} sx={{ marginBottom: 10 }}>
              <CircularProgress />
            </Stack>
          )}
        </Stack>
      </Table>
    </TableContainer>
  );
};

type SortTableProps = {
  active: boolean;
  direction: OrderType;
  onClick: (event: MouseEvent<unknown>) => void;
};

const SortTable: FC<SortTableProps> = ({ direction, onClick }) => (
  <IconButton sx={{ p: 1 }} onClick={onClick}>
    {direction === undefined && (
      <Icons.SortDefault
        sx={{
          width: 16,
          height: 13,
          color: 'gray',
        }}
      />
    )}
    {direction === 'desc' && (
      <Icons.SortDesc
        sx={{
          width: 16,
          height: 13,
          color: 'sp_primary_100_main',
        }}
        stroke="#CCCCCC"
      />
    )}
    {direction === 'asc' && (
      <Icons.SortAsc
        sx={{
          width: 16,
          height: 13,
        }}
      />
    )}
  </IconButton>
);

const headCells: HeadCellProps<MemoItem>[] = [
  {
    id: 'created_at',
    label: '日付',
    style: { width: 97 },
  },
  {
    id: 'manager_name',
    label: 'メモ作成者',
    style: { width: 180 },
  },
  {
    id: 'memo',
    label: 'メモ内容',
    style: { flex: 1, justifyContent: 'center' },
  },
];
