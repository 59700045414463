import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckPassword: FC<SvgIconProps> = (props) => (
  <SvgIcon
    sx={{
      width: 29,
      height: 18,
    }}
    viewBox="0 0 29 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <svg
      width="29"
      height="18"
      viewBox="0 0 29 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1053 9C27.1053 9 20.7895 17 14.0526 17C7.31579 17 1 9 1 9C1 9 6.89474 1 14.0526 1C21.2105 1 26.6842 8.57895 27.1053 9Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle
        cx="14.3333"
        cy="9.00008"
        r="4.33333"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
